import './crossword.css';
import React, { useState, useRef, useCallback, useEffect } from "react";
import {Link} from 'react-router-dom';
import {CrosswordProvider, DirectionClues, CrosswordGrid} from '@jaredreisinger/react-crossword';
import {Howl, Howler} from 'howler';
import { crosswordDataEnglish, crosswordDataHindi } from "./constants";
import UserInfo from './userInfo';

function CrosswordGame() {
  const crossword = useRef();

  const [language, setLanguage] = useState("English");
  const [showUserInfo, setShowUserInfo] = useState(false);

  const SuccessSound = new Howl({
    src: ['sounds/trumpet.mp3'],
  });

  const KeySound = new Howl({
    src: ['sounds/keystroke.mp3'],
    volume: 0.3
  });

  useEffect(() => {
    Howler.stop();
  },[]);

  useEffect(() => {
    Howler.stop();
  },[language]);

  const handleKeyPress = () => {
    KeySound.play();
  }

  const reset = useCallback((event) => {
    crossword.current.reset();
  }, []);

  const fillAllAnswers = useCallback((event) => {
    if (localStorage.getItem("user")) {
      crossword.current.fillAllAnswers();
    } else {
      setShowUserInfo(true);
    }
  }, []);
  
  const isCrosswordCorrect = useCallback((event) => {
      crossword.current.isCrosswordCorrect();
  }, []);

  const crosswordCorrect = () => {
    if(isCrosswordCorrect()) {

    }
    // alert(isCrosswordCorrect())
  }

  const changeLanguage = () => {
    if (language === "English") {
      setLanguage("Hindi");
    } else {
      setLanguage("English");
    }
  }

  const onUserInfoSuccess = () => {
    setShowUserInfo(false);
  }

  return (
    <div className='page'>
      <h1 className='heading display-2 text-uppercase mt-2'>
        <Link className="nav-link" to="/">
          Crossword
        </Link>
        <button className="btn btn-danger ms-3" onClick={reset} disabled={showUserInfo}>Reset</button>
        <button className="btn btn-success ms-3" onClick={fillAllAnswers} disabled={showUserInfo}>Show Answers</button>
      </h1>
    {
      showUserInfo ?
      <UserInfo
        onSuccess={onUserInfoSuccess}
        page="Crossword Game"
      /> :
      <div className='naman' onKeyDown={handleKeyPress}>
        <CrosswordProvider
          data={language === "English" ? crosswordDataEnglish : crosswordDataHindi}
          ref={crossword}
            onCrosswordCorrect={crosswordCorrect}
            // onCorrect={() => SuccessSound.play()}
            theme={{
              allowNonSquare :true,
              columnBreakpoint: '768px',
              gridBackground: '#203864',
              cellBackground: '#96B93D',
              cellBorder: '#fff',
              textColor: '#fff',
              numberColor: '#000',
              focusBackground: '#c3db86',
              highlightBackground: '#76a305',
            }}
        >
          <div className='crosswordDiv'>
            <div style={{ width: '10em'}}>
              <CrosswordGrid />
            </div>
            <div className='clues'>
              <DirectionClues direction="across" />
              <DirectionClues direction="down" />
              <div className='mt-2'>
                Change language to <span className='language' onClick={changeLanguage}>{language === "English" ? "Hindi" : "English"}</span>?
              </div>
            </div>
          </div>
        </CrosswordProvider>
      </div>
    }
    </div>
  )
}

export default CrosswordGame