export const TeamNames = [
  "Bulls & Bears",
  "Portfolio Power",
  "Market Masters",
  "Risk Takers",
  "Wealth Wizards",
  "ROI Rangers",
  "Dividend Dynamos",
  "Capital Crusaders",
  "Alpha Alliance",
  "Equity Envoys",
  "Cashflow Commanders",
  "Growth Gurus",
  "Option Outlaws",
  "Stock Surge",
  "Crypto Crushers",
  "Hedge Heroes",
  "Bond Bandits",
  "Yield Yodas",
  "Asset Avengers",
  "IPO Invaders",
  "Venture Vortex",
  "Bulls in the Balance",
  "Coin Conquerors",
  "Derivative Daredevils",
  "Index Infiltrators",
  "Market Mavericks",
  "Fiscal Foxes",
  "Commodities Command",
  "Diversification Dynamo",
  "REIT Renegades",
  "Future Fusion",
  "Fibonacci Force",
  "P/E Predators",
  "Cash Kings",
  "Short Sprints",
  "Long Haul Legends",
  "Trade Titans",
  "Buyback Brigade",
  "Speculation Spartans",
  "Mergers & Marauders",
  "Tactical Traders",
  "Capital Captains",
  "Yield Yielders",
  "Bullion Battalion",
  "Macro Maestros",
  "Quota Quest",
  "Investment Instinct",
  "Yield Yarners",
  "Crypto Commandos",
  "Acumen Avengers",
  ]

  export const crosswordDataEnglish = {
    across : {
      1 : {
        clue: `With time by my side, I quietly gather. Interest upon interest, can you guess who I am?`,
        answer: "COMPOUNDING",
        row: 0,
        col: 4
      },
      2 : {
        clue: `Mid in my span, not too small,
        not too large, Potential steady,
        in the market
        I stand?`,
        answer: "MIDCAP",
        row: 4,
        col: 20
      },
      3 : {
        clue: `I am like a protector in a plan, defending against life's troubles. What am I? I make sure your life is safe and secure.`,
        answer: "INSURANCE",
        row: 7,
        col: 11
      },
      5 : {
        clue: `I'm not flashy like stocks, nor a coin you can toss. I'm a promise on paper, marked in markets. What am I? Steady and true, earning interest for you.`,
        answer: "BOND",
        row: 11,
        col: 2
      },
      6 : {
        clue: `I am a mix of big numbers, like a gathering of giants. I go up and down in the market's matrix. I capture trends like a snapshot, a deep measure. What am I? The place where market insights are found.`,
        answer: "INDEX",
        row: 18,
        col: 7
      },
      7 : {
        clue: `I'm the regulator of keeping money safe and secure. Who am I? Just forr letters, well-known.`,
        answer: "SEBI",
        row: 16,
        col: 23
      },
      9 : {
        clue: `I'm big and powerful, ruling the market. With strong uppercase force, I generate wealth.`,
        answer: "LARGECAP",
        row: 5,
        col: 0
      },
      17 : {
        clue: `Small in size, but with big potential, what am I in the market's dance?`,
        answer: "SMALLCAP",
        row: 9,
        col: 19
      },
      18 : {
        clue: `In a piggy or a bank, I find
        my way, or a rainy
         day, what am I,
         do you say?`,
        answer: "SAVINGS",
        row: 14,
        col: 4
      },
      20 : {
        clue: `I 'm the wild heartbeat in the market's
        song, prices in a tempest, a rhythm
        strong. In chaos and calm,
        where do I belong?`,
        answer: "VOLATILITY",
        row: 2,
        col: 12
      },
      22 : {
        clue: `If money grew on tress what would be everyone's favourite season?`,
        answer: "FALL",
        row: 16,
        col: 9
      },
      23 : {
        clue: `I'm the possibility of lossing money on an investment. What am i?`,
        answer: "RISK",
        row: 4,
        col: 10
      },
    },
    down : {
      1 : {
        clue: `I'M A Paper Currency, Lacking A Face, In Lines And Numbers, My Value You Trace?`,
        answer: "CHEQUE",
        row: 0,
        col: 4
      },
      4 : {
        clue: `I am a golden charm kept safe in secure places. What am I? A treasure that lasts forever, so pure.`,
        answer: "GOLD",
        row: 5,
        col: 3
      },
      8 : {
        clue: `I am a diverse collection, where investments come together. What am I? A quick financial snapshot in just one line.`,
        answer: "PORTFOLIO",
        row: 9,
        col: 26
      },
      10 : {
        clue: `I'm not your home, but I hold money in position. ATMs and tellers, where do you find me?`,
        answer: "BANK",
        row: 6,
        col: 16
      },
      11 : {
        clue: `Digits in a dance, no card, just
        a code, tap and transfer,
        in a seamless mode?`,
        answer: "UPI",
        row: 4,
        col: 7
      },
      12 : {
        clue: `What can u spend but cant buy?`,
        answer: "TIME",
        row: 2,
        col: 20
      },
      13 : {
        clue: `I grow without roots, a money seed to sow. Guess what I am, where fortunes may flow?`,
        answer: "INVESTING",
        row: 0,
        col: 12
      },
      14 : {
        clue: `Invest in me smartly, I trim your taxes. A fund for savings, find me on a whim.`,
        answer: "ELSS",
        row: 7,
        col: 19
      },
      15 : {
        clue: `I'm not a game, but a future
        embrace, save wisely for
        me, in life's final
       phase?`,
        answer: "RETIREMENT",
        row: 15,
        col: 24
      },
      16 : {
        clue: `I'm a way to learn, where young minds take off. In a room with desks, I make brains shine bright.`,
        answer: "CHILDEDUCATION",
        row: 5,
        col: 5
      },
      19 : {
        clue: `I'm a piece of a company, a
        tiny part to hold, in the
         market's dance, my
         value unfolds?`,
        answer: "SHARE",
        row: 14,
        col: 10
      },
      21 : {
        clue: `I take a piece of what you earn with pride, the burden in April, guess who's on your side?`,
        answer: "TAX",
        row: 2,
        col: 16
      },
    }
  }

  export const crosswordDataHindi = {
    across : {
      1 : {
        clue: `Samaay mere saath, main dheere dheere ikattha hota hoon. Byaj par byaj, kya tum mujhe pehchaan sakte ho?`,
        answer: "COMPOUNDING",
        row: 0,
        col: 4
      },
      2 : {
        clue: `Meri lambai beech mein hai, na bahut chhoti,
        na bahut badi, Sambhavna sthir hai,
        bazaar mein
        main khada hoon.`,
        answer: "MIDCAP",
        row: 4,
        col: 20
      },
      3 : {
        clue: `Main ek surakshaak hai ek yojna mein, jeevan ke musibaton se bachaane waala. Main sunishchit karta hoon ki tumhara jeevan surakshit aur surakshit hai.`,
        answer: "INSURANCE",
        row: 7,
        col: 11
      },
      5 : {
        clue: `Main taiz nahi hoon jaise stocks, aur na hi ek sikka jise tum phek sakte ho. Main ek kagaz par wada hoon, bazaar mein darj kiya gaya. Main sthir aur sach hoon, tumhare liye byaj kama raha hoon.`,
        answer: "BOND",
        row: 11,
        col: 2
      },
      6 : {
        clue: `Main bade sankhyon ka mishran hoon, jaise rajaon ka samuh. Main bazar ke matrix mein upar neeche jaata hoon. Main trends ko ek tasveer ki tarah pakad leta hoon, ek gehri moolyaankan. Main kaun hoon? Vah sthal jahan bazaar ke drishtikon paye jaate hain. Main ek stock market index hoon.`,
        answer: "INDEX",
        row: 18,
        col: 7
      },
      7 : {
        clue: `Main dhan ko surakshit aur surakshit rakhhne ka adhyaksh hoon. Main kaun hoon? Bas char  aksharon mein, prasiddh.`,
        answer: "SEBI",
        row: 16,
        col: 23
      },
      9 : {
        clue: `Main bada aur shaktishaali hoon, bazar par raaj karne waala. Majboot uppercase bal ke saath, main dhan utpaadit karta hoon. Main kaun hoon?`,
        answer: "LARGECAP",
        row: 5,
        col: 0
      },
      17 : {
        clue: `Bazaar ke naach mein, main chhote akar mein hoon, lekin bade potential ke saath. Main hoon????`,
        answer: "SMALLCAP",
        row: 9,
        col: 19
      },
      18 : {
        clue: `Ek piggy bank ya bank mein, main apna raasta dhoond leta hoon, bure din main kam ata hoon main kya hoon, kya tum bata sakte ho?`,
        answer: "SAVINGS",
        row: 14,
        col: 4
      },
      20 : {
        clue: `Main hoon bazaar ke geet mein ek anokha dhadkan, daamon mein toofan, ek mazboot lay. Khaamoshi aur shanti mein, main kahan hoon?`,
        answer: "VOLATILITY",
        row: 2,
        col: 12
      },
      22 : {
        clue: `Agar paise pedon par ugaate, toh sabka pasandida mausam shayad fasal ka hota?`,
        answer: "FALL",
        row: 16,
        col: 9
      },
      23 : {
        clue: `Tum loss karne ki sambhavna ko darshata hai. Kon hoon main?`,
        answer: "RISK",
        row: 4,
        col: 10
      },
    },
    down : {
      1 : {
        clue: `Main ek kagaz ka note hoon, Bina chehre ke,
        Rekhayein aur sankhyaon mein, Mera moolya tum kheenchte ho.`,
        answer: "CHEQUE",
        row: 0,
        col: 4
      },
      4 : {
        clue: `Main ek chamkdar metal  hoon, surakshit jagahon mein rakha gaya. Main ek khazana hoon jo hamesha ke liye bana rahega, itna pavitra.`,
        answer: "GOLD",
        row: 5,
        col: 3
      },
      8 : {
        clue: `Main ek vividh sangrah hoon, jahan nivesh milte hain. Main kaun hoon? Ek tezi se arthik tasvir sirf ek rekha mein.`,
        answer: "PORTFOLIO",
        row: 9,
        col: 26
      },
      10 : {
        clue: `Main tumhara ghar nahi hoon, lekin main dhan ko safe mein rakhta hoon. ATM aur tellers, mujhe kahan milte hain? Main ek bank hoon.`,
        answer: "BANK",
        row: 6,
        col: 16
      },
      11 : {
        clue: `Ankho ka ek naach, koi card nahi, sirf ek code,
        Chhoone aur bhejne mein, ek beintehaan mode?`,
        answer: "UPI",
        row: 4,
        col: 7
      },
      12 : {
        clue: `Samaay mere saath, main dheere dheere ikattha hota hoon. Byaj par byaj, kya tum mujhe pehchaan sakte ho?`,
        answer: "TIME",
        row: 2,
        col: 20
      },
      13 : {
        clue: `Main bena jado k badhta huu ,kya huu mai jo app k leye wealyh create karta huu?`,
        answer: "INVESTING",
        row: 0,
        col: 12
      },
      14 : {
        clue: `Mujh mein chaturai se nivesh karo, main tumhare kar kataout ko kam karta hoon. Bachat ke liye ek funds, mujhe kisi bhi wakt dhoondh sakte ho. Main hoon kon ?`,
        answer: "ELSS",
        row: 7,
        col: 19
      },
      15 : {
        clue: `Main ek khel nahi hoon, balki ek bhavishya ka gale lagaana hoon, jeevan ke antim charan mein mujhe buddhimat se bachao.`,
        answer: "RETIREMENT",
        row: 15,
        col: 24
      },
      16 : {
        clue: `Main ek tarika hoon sikne ka, jahan chhote dimaag udne lagte hain. Ek kaksha mein, benchon ke saath, main dimaag badhata hoon.`,
        answer: "CHILDEDUCATION",
        row: 5,
        col: 5
      },
      19 : {
        clue: `Main ek company ka ek tukda hoon, ek chhota hissa pakadne ke liye, bazaar ke demad and supply par mera moolya khulta hai.`,
        answer: "SHARE",
        row: 14,
        col: 10
      },
      21 : {
        clue: `Main hoon "tax". Main tumhare kamai ka ek hissa le leta hoon, jise tum garv se kamaate ho. April mein bojh, kya tumhe pata hai kaun tumhari taraf hai?`,
        answer: "TAX",
        row: 2,
        col: 16
      },
    }
  }

  