import './Invest.css';
import { stockData } from './data';
import { useEffect, useState, useRef } from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {equity, debt, gold, other} from './constants1';
import {TeamNames} from './constants';
import { numberToWords } from "amount-to-words";
import {Howl, Howler} from 'howler';
import { XAxis, YAxis, Tooltip, Legend, LineChart, Line } from 'recharts';
import SlotCounter from 'react-slot-counter';
import userEvent from '@testing-library/user-event';
import UserInfo from './userInfo';

function App() {

  const initialYearData = {
    "equity": 0,
      "debt": 0,
      "gold": 0,
      "pb": 0,
      "pe": 0,
      "yield": 0,
  }

  const [currPage, setCurrPage] = useState(0);
  const [teamLabel, setTeamLabel] = useState("Team");
  const [investedAmount, setInvestedAmount] = useState(100000);
  const [teamSize, setTeamSize] = useState(0);
  const [teamInfo, setTeamInfo] = useState([]);
  const [currYear, setCurrYear] = useState(initialYearData);
  const [prevYear, setPrevYear] = useState(initialYearData);
  const [nextYear, setNextYear] = useState(initialYearData);
  const [rates, setRates] = useState({});
  const [roundNumber, setRoundNumber] = useState(1);
  const [historyData, setHistoryData] = useState({});
  const [selectedTeam, setSelectedTeam] = useState(-1);
  const [temp, setTemp] = useState("/gifs/joystick.gif");

  const counterRef = useRef(null);

  const handleRefresh = () => {
    counterRef.current?.startAnimation();
  };

  const graphColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink', 'orangered', 'brown', 'purple', 'black'];
  const teamIcons = [...Array(10).keys()].map(val => `/gifs/player${val+1}.gif`);

  const LobbySound = new Howl({
    src: ['sounds/lobby.mp3'],
    loop: true,
    volume: 0.5,
  });

  const SuccessSound = new Howl({
    src: ['sounds/trumpet.mp3'],
  });

  const KeySound = new Howl({
    src: ['sounds/keystroke.mp3'],
    volume: 0.3
  });

  const SlotSound = new Howl({
    src: ['sounds/slot.m4a'],
    volume: 0.5
  });

  const ScribbleSound = new Howl({
    src: ['sounds/scribble.mp3'],
    volume: 2
  });

  const reset = () => {
    if (window.confirm('This action will clear entire game data and the game will start from the beginning. Are you sure you want to reset?')) {
      Howler.stop();
      setCurrPage(0);
      setTeamLabel("Team");
      setInvestedAmount(100000);
      setTeamSize(0);
      setTeamInfo([]);
      setRates({});
      setRoundNumber(1);
      setHistoryData({});
      setSelectedTeam(-1);
    }
  }

  const makeData = () => {
    let data = {};
    equity.forEach(item => {
      let date = moment(item.Date).format('YYYY-MM-DD');
      data[date] = {
        equity : +item.Close
      }
    })
    debt.forEach(item => {
      let date = moment(item.Date).format('YYYY-MM-DD');
      let temp = data[date] || {};
      data[date] = {
        ...temp,
        debt : +item.Price
      }
    })
    gold.forEach(item => {
      let date = moment(item.Date).format('YYYY-MM-DD');
      let temp = data[date] || {};
      data[date] = {
        ...temp,
        gold : +item.Rate
      }
    })
    other.forEach(item => {
      let date = moment(item.Date).format('YYYY-MM-DD');
      let temp = data[date] || {};
      data[date] = {
        ...temp,
        pb : +item.PB,
        pe : +item.PE,
        yield : +item.Div
      }
    })
    let prevDate = "1999-01-04";
    let finalData = {};
    finalData[prevDate] = data[prevDate];
    while (prevDate < "2024-01-01") {
      let currDate = moment(prevDate).add(1,'days').format('YYYY-MM-DD');
      finalData[currDate] = {
        ...finalData[prevDate],
        ...data[currDate],
        date : currDate
      };
      prevDate = currDate;
    }
    console.log(JSON.stringify(finalData))
  }

  useEffect(() => {
    // makeData();
    // let arr = Object.keys(stockData)
    // console.log(arr.findIndex(date => date === "2000-01-01"))
    // console.log(arr.findIndex(date => date === "2022-12-31"))
    // getRandomData();
    // LobbySound.play();
    return () => Howler.stop();
  },[])

  useEffect(() => {
    switch (currPage) {
      case 1: LobbySound.play(); break;
      case 2: getRandomData(); break;
      case 3: localStorage.getItem("user") && SuccessSound.play(); break;
      case 4: ScribbleSound.play(); break;
      default: ;
    }
  }, [currPage])

  useEffect(() => {
    setInitialTeamInfo();
  }, [teamSize])

  const setInitialTeamInfo = () => {
    let icons = [], i=0;
    let teams = [...teamInfo];
    while(icons.length < teamSize){
      let randomNumber = getRandomInRange(0, 9)
      let name = teamIcons[randomNumber];
      if(icons.indexOf(name) === -1 && teams.every(team => team.teamIcon !== name)) {
        icons.push(name);
      }
    }
    if (teams.length < teamSize) {
      while (teams.length < teamSize) {
        teams.push({
          teamName: "",
          teamIcon: icons[i++],
          investedAmount: 100000,
          equity: 0,
          debt: 0,
          gold: 0,
          totalInputShare: 100,
        })
      }
    } else {
      teams.length = teamSize;
    }
    console.log(teams)
    setTeamInfo(teams);
  }

  const getRandomInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const fixDecimal = (num) => {
    return +num.toFixed(2);
  }

  const getRandomData = () => {
    SlotSound.play();
    handleRefresh();
    let min = 362, max = 8762;
    let randomNumber = getRandomInRange(min, max);
    let date = Object.keys(stockData)[randomNumber];
    // let date = "2015-06-18";
    setCurrYear(stockData[date]);
    setPrevYear(stockData[moment(date).subtract(1,'months').format('YYYY-MM-DD')]);
    setNextYear(stockData[moment(date).add(1,'years').format('YYYY-MM-DD')]);
    console.log("date", date, moment(date).subtract(1,'months').format('YYYY-MM-DD'), moment(date).add(1,'years').format('YYYY-MM-DD'))
    console.log("StockData", stockData[date], stockData[moment(date).subtract(1,'months').format('YYYY-MM-DD')], stockData[moment(date).add(1,'years').format('YYYY-MM-DD')]);
  }

  const handleKeyPress = () => {
    KeySound.play();
  }

  const randomTeamNames = () => {
    let nameArray = [];
    while(nameArray.length < teamInfo.length){
      let randomNumber = getRandomInRange(0, 49)
      let name = TeamNames[randomNumber];
      if(nameArray.indexOf(name) === -1) {
        nameArray.push(name);
      }
    }
    let teams = teamInfo.map((team, index) => {
      team.teamName = nameArray[index];
      return team;
    })
    setTeamInfo(teams);
  }

  const updateInvestedValue = (val) => {
    let teams = teamInfo.map(team => {
      team.investedAmount = +val;
      return team;
    })
    setInvestedAmount(+val)
    setTeamInfo(teams);
  }

  const updateTeam = (index, key, val) => {
    let newTeamInfo = [...teamInfo];
    newTeamInfo[index][key] = val;
    console.log(newTeamInfo)
    setTeamInfo(newTeamInfo)
  }

  const updateTotalInputShare = (index) => {
    let newTeamInfo = [...teamInfo];
    let team = newTeamInfo[index]
    let sum = +team.equity + +team.debt + +team.gold;
    newTeamInfo[index].totalInputShare = sum;
    setTeamInfo(newTeamInfo);
  }

  const setRandomShare = (index) => {
    let newTeamInfo = [...teamInfo];
    newTeamInfo[index].equity = getRandomInRange(0,100);
    newTeamInfo[index].debt = getRandomInRange(0 , 100 - newTeamInfo[index].equity);
    newTeamInfo[index].gold = 100 - (newTeamInfo[index].equity + newTeamInfo[index].debt);
    setTeamInfo(newTeamInfo);
  }

  const getStandardDeviation = (arr) => {
    const n = arr.length;
    const mean = arr.reduce((a, b) => a + b) / n;
    return Math.sqrt((arr.map(x => (x - mean)**2).reduce((a, b) => a + b))/ (n-1))
  }

  const calculateReturn = () => {
    let equityRate = (nextYear.equity-currYear.equity)/currYear.equity*100;
    let debtRate = currYear.debt + currYear.debt - nextYear.debt;
    let goldRate = (nextYear.gold-currYear.gold)/currYear.gold*100;
    let equityArr = [], debtArr = [], goldArr = [];
    let date = currYear.date;
    while (date <= nextYear.date) {
      equityArr.push(+stockData[date].equity);
      debtArr.push(+stockData[date].debt);
      goldArr.push(+stockData[date].gold);
      date = moment(date).add(1, 'days').format("YYYY-MM-DD");
    }
    console.log("Arr", debtArr)
    let equitySD = getStandardDeviation(equityArr);
    let debtSD = getStandardDeviation(debtArr);
    let goldSD = getStandardDeviation(goldArr);
    console.log("sd", equitySD, debtSD, goldSD)
    // let history = historyData.slice();
    let newHistory = {};
    // console.log("History1", historyData, history)
    let teams = teamInfo.map((team, index) => {
      //Equity
      team.equityShare = +(team.investedAmount*team.equity/100).toFixed(2);
      team.equityReturn = +((team.equityShare*equityRate)/100 + team.equityShare).toFixed(2);
      team.equityProfit = +(team.equityReturn-team.equityShare).toFixed(0);
      console.log("equity share", equityRate, team.equityShare, team.equityReturn);
      //Equity
      team.debtShare = +(team.investedAmount*team.debt/100).toFixed(2);
      team.debtReturn = +((team.debtShare*debtRate)/100 + team.debtShare).toFixed(2);
      team.debtProfit = +(team.debtReturn-team.debtShare).toFixed(0);
      console.log("debt share", debtRate, team.debtShare, team.debtReturn);
      //Gold
      team.goldShare = +(team.investedAmount*team.gold/100).toFixed(2);
      team.goldReturn = +((team.goldShare*goldRate)/100 + team.goldShare).toFixed(2);
      team.goldProfit = +(team.goldReturn-team.goldShare).toFixed(0);
      console.log("gold share", goldRate, team.goldShare, team.goldReturn);

      team.absoluteReturn = +(team.equityReturn + team.debtReturn + team.goldReturn).toFixed(2);
      team.roi = +(((team.absoluteReturn/investedAmount)**(1/roundNumber) - 1)*100).toFixed(2);
      team.sd = +(((equitySD*team.equity)/100 + (debtSD*team.debt)/100 + (goldSD*team.gold)/100)/300).toFixed(2);
      // console.log(team);
      // let arr = (history[index] || []).slice();
      
      return team;
    })
    setRates({
      equityRate : equityRate,
      debtRate : debtRate,
      goldRate : goldRate
    });
    // teams.sort((a,b) => b.absoluteReturn - a.absoluteReturn)
    // if (roundNumber === 1) {
      for (let i = 0; i < teams.length; i++) {
        let arr = (historyData[i] || []);
        console.log("naman", i, arr)
        arr.push({...teams[i], round : `Round ${roundNumber}`});
        console.log("naman1", i, arr)
        // historyData[i] = arr;
        setHistoryData((prevState) => ({
          ...prevState,
          [i] : arr
        }))
      // }
    }
    
    setTeamInfo(teams);
    console.log("History2", historyData, newHistory)
    // setHistoryData(newHistory);
    if (localStorage.getItem("user")) {
      setCurrPage(3);
    } else {
      setCurrPage(2.5);
    }
  }

  const onUserInfoSuccess = () => {
    setCurrPage(3);
  }

  const investAgain = () => {
    let teams = teamInfo.map(team => {
      team.investedAmount = team.absoluteReturn;
      return team;
    })
    setTeamInfo(teams);
    setRoundNumber(prevState => prevState + 1);
    // getRandomData();
    setCurrPage(2);
  }

  const formattedText = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1,-6);
  }

  const getRoiGraphData = () => {
    let data = [];
    Object.values(historyData).forEach((team, index) => {
      team.forEach((round, i) => {
        if(!data[i]) {
          data[i] = {
            round : `Round ${i + 1}`
          }
        }
        data[i][round.teamName] = round.roi;
      })
    })
    console.log(data)
    return data;
  }

  const TeamIcon = ({src}) => {
    return (
      <img src={src} alt='' className='iconGif'></img>
    )
  }

  const ResetButton = () => {
    return (
      <button className='btn btn-danger btn-lg me-5' onClick={reset}>
        Reset <i className="bi bi-boxes"></i>
      </button>
    )
  }

  const BackButton = () => {
    return (
      <button className='btn btn-secondary btn-lg me-5' onClick={() => setCurrPage(currPage-1)}>
        <i className="bi bi-arrow-left"></i> Back
      </button>
    )
  }

  const Counter = ({value}) => {
    return (
      <SlotCounter value={value} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={4}/>
    )
  }

  return (
    <div className="App w-100">
      <div className={currPage > 0 ? `logo` : "big-logo"}>
        <Link className="nav-link" to="/">
          <img className='logoImg' src='/green_logo.png' alt='Investmyfunds.in'></img>
        </Link>
      </div>
      <header className="App-header" onKeyPress={handleKeyPress}>
        {/* Landing Page */}
        {
          currPage === 0 ?
          <div className='page0'>
            <div className='form-group'>
              <h1 className='text-uppercase mt-2' style={{width: "1000px"}}>The &nbsp;immersive &nbsp;investing &nbsp;Experience</h1>
              <h2 className='mt-5'>Are you ready to invest with us through a game?</h2>
              <button className='btn btn-lg jump mt-4' onClick={() => setCurrPage(1)}>Invest My Funds!</button>
            </div>
          </div> : <></>
        }

        {/* First Page */}

        {
          currPage === 1 ?
          <div className='page1'>
            <div className='row'>
              <div className='form-group'>
                <h4>How many investers/teams do we have?</h4>
                <div className='input-group'>
                  <select value={teamSize} onChange={(e) => setTeamSize(e.target.value)} className='form-control form-select'>
                    <option value={0} disabled={true}>--Select--</option>
                    {
                      [...Array(10).keys()].map(val => {
                        return <option key={val} value={val+1}>{val+1}</option>
                      })
                    }
                  </select>
                  <div className='input-group-append'>
                    <select value={teamLabel} onChange={(e) => setTeamLabel(e.target.value)} className='form-control form-select'>
                      <option value="Team">Teams</option>
                      <option value="Investor">Investors</option>
                      <option value="Player">Players</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='form-group'>
                <h4>Enter the amount you want to invest</h4>
                <div className='input-group'>
                  <div className="input-group-prepend">
                    <span className="input-group-text">₹</span>
                  </div>
                  <input type='number' className='form-control' value={investedAmount} onChange={(e) => updateInvestedValue(+e.target.value)}></input>
                </div>
                <div className="fine-print">{numberToWords(investedAmount)}</div>
              </div>
            </div>
            {
              teamInfo.length ? 
                <>
                  <div className='row appear'>
                    <div className='form-group'>
                      <h4>{teamLabel}s
                        <button className='btn btn-outline-light btn-sm ms-3' onClick={randomTeamNames} data-toggle="tooltip" data-placement="top" title={`Generate random ${teamLabel} names`}>
                          <span className='spin'><i className="bi bi-shuffle"></i></span>
                        </button>
                      </h4>
                    </div>
                    {
                      teamInfo.map((team, index) => {
                        return <div className={teamInfo.length%2 === 0 ? 'col-md-6' : 'col-md-12'} key={index}>
                          <div className='teamIcon'>
                            <TeamIcon src={team.teamIcon}/>
                            <div className='input-group form-group'>
                              <div className="input-group-prepend">
                                <span className="input-group-text">{teamLabel} {index+1}</span>
                              </div>
                              <input type='text' className='form-control' value={team.teamName} onChange={(e) => updateTeam(index, "teamName", e.target.value)} placeholder={`Enter ${teamLabel} Name...`}></input>
                            </div>
                          </div>
                        </div>
                      })
                    }
                  </div>
                  <div className='form-row'>
                    <ResetButton/>
                    <button className='btn btn-warning btn-lg' disabled={teamInfo.find(team => !team.teamName)} onClick={() => {setCurrPage(2);Howler.stop();}}>Let's Play!</button>
                  </div>
                  <div className='jump'>
                    
                  </div>
                </>
              : <></>
            }
          </div> : <></>
        }

        {/* SECOND PAGE */}

        {
          currPage === 2 ?
          <div className='page2'>
            <h2 onClick={() => {console.log("refresh"); handleRefresh();}}>Round {roundNumber}</h2>
            <div className='row'>
              {/* <h3>
                Market Data
                <button className='btn btn-warning btn-sm ms-3' onClick={getRandomData} data-toggle="tooltip" data-placement="top" title="Regenerate Data">
                  <div className='spin'><i className="bi bi-arrow-clockwise" ></i></div>
                </button>
              </h3> */}
              <div style={{display: "flex"}}>
              <table className='marketDataTable'>
                <tbody>
                  <tr style={{opacity : 0.5}} className='marketDataTableRow'>
                    <td>
                      <SlotCounter value={+prevYear.equity} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                    <td>
                      <SlotCounter value={+prevYear.debt} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                    <td>
                      <SlotCounter value={+prevYear.gold.toFixed(2)} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                    <td>
                      <SlotCounter value={+prevYear.pe} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                    <td>
                      <SlotCounter value={+prevYear.pb} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                    <td>
                      <SlotCounter value={+prevYear.yield} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                  </tr>
                  <tr className='marketDataTableRow' style={{fontWeight : 700}}>
                    <td>
                      <SlotCounter value={+currYear.equity} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                    <td>
                      <SlotCounter value={+currYear.debt} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                    <td>
                      <SlotCounter value={+currYear.gold.toFixed(2)} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                    <td>
                      <SlotCounter value={+currYear.pe} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                    <td>
                      <SlotCounter value={+currYear.pb} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                    <td>
                      <SlotCounter value={+currYear.yield} direction="bottom-up" ref={counterRef} animateUnchanged={true} autoAnimationStart={false} duration={2}/>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Equity
                    </th>
                    <th>
                      Debt
                    </th>
                    <th>
                      Gold
                    </th>
                    <th>
                      PE
                    </th>
                    <th>
                      PB
                    </th>
                    <th>
                      Dividend Yield
                    </th>
                  </tr>
                </tbody>
              </table>
              <img src={"/gifs/joystick.gif"} alt='' className='joystickGif' onClick={getRandomData}></img>
              </div>
            </div>

            <div className='form-row'>
              
            </div>
          
            <div className='row'>
              <table className='rateInputTable'>
                <thead>
                  <tr>
                    <th scope='col'></th>
                    <th scope='col'></th>
                    <th scope='col'>
                      Equity
                    </th>
                    <th scope='col'>
                      Debt
                    </th>
                    <th scope='col'>
                      Gold
                    </th>
                    <th scope='col'>
                      &nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    teamInfo.map((team, index) => {
                      return (
                        <tr key={index}>
                          <th><TeamIcon src={team.teamIcon}/></th>
                          <th className='form-group' scope="row">{team.teamName}</th>
                          <td>
                            <div className='input-group'>
                              <input className={`form-control ${team.totalInputShare === 100 ? '' : 'is-invalid'}`} type='number' value={team.equity} onChange={(e) => {updateTeam(index, "equity", e.target.value); updateTotalInputShare(index)}}></input>
                              <div className="input-group-append">
                                <span className="input-group-text form-control">%</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='input-group'>
                              <input className={`form-control ${team.totalInputShare === 100 ? '' : 'is-invalid'}`} type='number' value={team.debt} onChange={(e) => {updateTeam(index, "debt", e.target.value); updateTotalInputShare(index)}}></input>
                              <div className="input-group-append">
                                <span className="input-group-text form-control">%</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='input-group'>
                              <input className={`form-control ${team.totalInputShare === 100 ? '' : 'is-invalid'}`} type='number' value={team.gold} onChange={(e) => {updateTeam(index, "gold", e.target.value); updateTotalInputShare(index)}}></input>
                              <div className="input-group-append">
                                <span className="input-group-text form-control">%</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <button className='form-group btn btn-outline-light' onClick={() => setRandomShare(index)} data-toggle="tooltip" data-placement="top" title="Randomly distribute shares">
                              <i className="bi bi-shuffle"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className='form-row'>
              {/* <BackButton/> */}
              <ResetButton/>
              <button className='btn btn-warning btn-lg' onClick={calculateReturn} disabled={teamInfo.find(team => (+team.equity + +team.debt + +team.gold !== 100))}>
                <i className="bi bi-graph-up-arrow"></i> Invest! 
              </button>
            </div>
          </div> : <></>
        }

        {/* USER INFO PAGE */}

        {
          currPage === 2.5 ?
          <UserInfo
            onSuccess={onUserInfoSuccess}
            page="Crossword Game"
          />
          : <></>
        }

        {/* THIRD PAGE */}

        {
          currPage === 3 ?
            <div className='page3'>
              <h2>Round {roundNumber} Results!</h2>
              <div className='row'>
                <table className='table table-hover'>
                  <thead className='thead-light'>
                    <tr className='table-primary'>
                      <th>
                        
                      </th>
                      <th></th>
                      <th>
                        {teamLabel}s
                      </th>
                      <th>
                        Investment Value
                      </th>
                      <th>
                        Market Value
                      </th>
                      <th>
                        Absolute Return
                      </th>
                      <th>
                        ROI
                      </th>
                      <th>
                        Equity
                      </th>
                      <th>
                        Profit/Loss Debt
                      </th>
                      <th>
                        Gold
                      </th>
                      {/* <th>
                        Risk
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      teamInfo.slice().sort((a,b) => +b.absoluteReturn - +a.absoluteReturn).map((team, index) => {
                        return (
                          <tr key={index}>
                            <td className='text-start'>
                              {index + 1} <i className='bi bi-award-fill award'></i>
                            </td>
                            <td><TeamIcon src={team.teamIcon}/></td>
                            <td className='form-group'>{team.teamName}</td>
                            <td>
                              {team.investedAmount.toFixed(0)}
                            </td>
                            <td>
                              {team.absoluteReturn.toFixed(0)}
                            </td>
                            <td>
                              {(team.absoluteReturn-team.investedAmount).toFixed(0)}
                            </td>
                            <td>
                              {team.roi}%
                            </td>
                            <td>
                              {team.equityProfit}
                            </td>
                            <td>
                              {team.debtProfit}
                            </td>
                            <td>
                              {team.goldProfit}
                            </td>
                            {/* <td>
                              {team.sd}%
                            </td> */}
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
              <div className='row'>
                <table className='ratesTable'>
                  <tbody>
                    <tr className=''>
                      <th style={{backgroundColor: graphColors[0]}}>Equity</th>
                      <th style={{backgroundColor: graphColors[0]}}>{rates.equityRate.toFixed(2)}%</th>
                      <th style={{backgroundColor: graphColors[1]}}>Debt</th>
                      <th style={{backgroundColor: graphColors[1]}}>{rates.debtRate.toFixed(2)}%</th>
                      <th style={{backgroundColor: graphColors[2]}}>Gold</th>
                      <th style={{backgroundColor: graphColors[2]}}>{rates.goldRate.toFixed(2)}%</th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='form-row'>
                <BackButton/>
                <ResetButton/>
                <button className='btn btn-primary btn-lg me-5' onClick={investAgain}>
                  Invest Again <i className="bi bi-arrow-counterclockwise App-logo"></i>
                </button>
                <button className='btn btn-success btn-lg' onClick={() => setCurrPage(4)}>
                  View Chart <i className="bi bi-bar-chart-line-fill App-logo"></i>
                </button>
              </div>
            </div>
          : <></>
        }

        {/* PAGE FOUR */}

        {
          currPage === 4 ?
          <div className='page4'>
            <div className='row teamSelectRow'>
              <div className='form-group'>
                <h4>Select {teamLabel} Name</h4>
                <select value={selectedTeam} onChange={(e) => {setSelectedTeam(e.target.value); ScribbleSound.play()}} className='form-control form-select'>
                  <option value={-1} className='bg-secondary'>--- Aggregated ROI ---</option>
                  {
                    teamInfo.map((team, index) => {
                      return <option key={index} value={index}>{team.teamName}</option>
                    })
                  }
                </select>
              </div>
            </div>
            <div className='row '>
              {
                historyData[selectedTeam] ? (
                  <LineChart width={1000} height={500} data={historyData[selectedTeam]} >
                    <XAxis dataKey="round" tick={{stroke: 'white', fill: 'white'}} axisLine={{stroke: 'white'}}/>
                    <YAxis tick={{stroke: 'white', fill: 'white'}} axisLine={{stroke: 'white'}}/>
                    <Tooltip formatter={(value, entry, index) => [value, formattedText(entry)]} contentStyle={{textAlign : "left"}} />
                    <Legend verticalAlign="top" formatter={(value, entry, index) => formattedText(value)}/>
                    <Line dataKey="equityProfit" type="monotone" stroke={graphColors[0]} strokeWidth={3}/>
                    <Line dataKey="debtProfit" type="monotone" stroke={graphColors[1]} strokeWidth={3}/>
                    <Line dataKey="goldProfit" type="monotone" stroke={graphColors[2]} strokeWidth={3}/>
                  </LineChart>
                ) : 
                <LineChart width={1000} height={500} data={getRoiGraphData()} >
                    <XAxis dataKey="round" tick={{stroke: 'white', fill: 'white'}} axisLine={{stroke: 'white'}}/>
                    <YAxis tick={{stroke: 'white', fill: 'white'}} axisLine={{stroke: 'white'}}/>
                    <Tooltip contentStyle={{textAlign : "left"}} itemSorter={(item) => item.value * -1}/>
                    <Legend verticalAlign="top" />
                    {
                      teamInfo.map((team, index) => {
                        return <Line key={index} dataKey={team.teamName} type="monotone" stroke={graphColors[index]} strokeWidth={3}/>
                      })
                    }
                  </LineChart>
              }
            </div>
            <div className='form-row'>
              <BackButton/>
              <ResetButton/>
            </div>
          </div> : <></>
        }
      </header>
    </div>
  );
}

export default App;
