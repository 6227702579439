export const stockData = {
  "1999-01-04": {
      "equity": 897.8,
      "debt": 12.225,
      "gold": 4326.369072,
      "pb": 2.08,
      "pe": 11.72,
      "yield": 1.81
  },
  "1999-01-05": {
      "equity": 907.2,
      "debt": 12.221,
      "gold": 4305.582109,
      "pb": 2.11,
      "pe": 11.84,
      "yield": 1.79,
      "date": "1999-01-05"
  },
  "1999-01-06": {
      "equity": 928.25,
      "debt": 12.218,
      "gold": 4304.791972,
      "pb": 2.16,
      "pe": 12.11,
      "yield": 1.75,
      "date": "1999-01-06"
  },
  "1999-01-07": {
      "equity": 954.7,
      "debt": 12.219,
      "gold": 4327.55075,
      "pb": 2.22,
      "pe": 12.46,
      "yield": 1.71,
      "date": "1999-01-07"
  },
  "1999-01-08": {
      "equity": 990.05,
      "debt": 12.219,
      "gold": 4362.549604,
      "pb": 2.3,
      "pe": 12.92,
      "yield": 1.64,
      "date": "1999-01-08"
  },
  "1999-01-09": {
      "equity": 990.05,
      "debt": 12.219,
      "gold": 4362.549604,
      "pb": 2.3,
      "pe": 12.92,
      "yield": 1.64,
      "date": "1999-01-09"
  },
  "1999-01-10": {
      "equity": 990.05,
      "debt": 12.219,
      "gold": 4362.549604,
      "pb": 2.3,
      "pe": 12.92,
      "yield": 1.64,
      "date": "1999-01-10"
  },
  "1999-01-11": {
      "equity": 985.6,
      "debt": 12.22,
      "gold": 4364.718954,
      "pb": 2.29,
      "pe": 12.86,
      "yield": 1.65,
      "date": "1999-01-11"
  },
  "1999-01-12": {
      "equity": 964.45,
      "debt": 12.22,
      "gold": 4373.646802,
      "pb": 2.24,
      "pe": 12.59,
      "yield": 1.69,
      "date": "1999-01-12"
  },
  "1999-01-13": {
      "equity": 961.2,
      "debt": 12.22,
      "gold": 4318.502972,
      "pb": 2.23,
      "pe": 12.54,
      "yield": 1.69,
      "date": "1999-01-13"
  },
  "1999-01-14": {
      "equity": 954.75,
      "debt": 12.234,
      "gold": 4283.056139,
      "pb": 2.22,
      "pe": 12.46,
      "yield": 1.7,
      "date": "1999-01-14"
  },
  "1999-01-15": {
      "equity": 934.3,
      "debt": 12.225,
      "gold": 4284.297783,
      "pb": 2.17,
      "pe": 12.19,
      "yield": 1.74,
      "date": "1999-01-15"
  },
  "1999-01-16": {
      "equity": 934.3,
      "debt": 12.225,
      "gold": 4284.297783,
      "pb": 2.17,
      "pe": 12.19,
      "yield": 1.74,
      "date": "1999-01-16"
  },
  "1999-01-17": {
      "equity": 934.3,
      "debt": 12.225,
      "gold": 4284.297783,
      "pb": 2.17,
      "pe": 12.19,
      "yield": 1.74,
      "date": "1999-01-17"
  },
  "1999-01-18": {
      "equity": 944.9,
      "debt": 12.218,
      "gold": 4301.79721,
      "pb": 2.19,
      "pe": 12.33,
      "yield": 1.72,
      "date": "1999-01-18"
  },
  "1999-01-19": {
      "equity": 924.1,
      "debt": 12.22,
      "gold": 4308.054816,
      "pb": 2.15,
      "pe": 12.06,
      "yield": 1.76,
      "date": "1999-01-19"
  },
  "1999-01-20": {
      "equity": 924.1,
      "debt": 12.22,
      "gold": 4286.054428,
      "pb": 2.15,
      "pe": 12.06,
      "yield": 1.76,
      "date": "1999-01-20"
  },
  "1999-01-21": {
      "equity": 967.2,
      "debt": 12.219,
      "gold": 4303.0318,
      "pb": 2.29,
      "pe": 12.84,
      "yield": 1.65,
      "date": "1999-01-21"
  },
  "1999-01-22": {
      "equity": 957.05,
      "debt": 12.22,
      "gold": 4313.790367,
      "pb": 2.26,
      "pe": 12.71,
      "yield": 1.67,
      "date": "1999-01-22"
  },
  "1999-01-23": {
      "equity": 957.05,
      "debt": 12.22,
      "gold": 4313.790367,
      "pb": 2.26,
      "pe": 12.71,
      "yield": 1.67,
      "date": "1999-01-23"
  },
  "1999-01-24": {
      "equity": 957.05,
      "debt": 12.22,
      "gold": 4313.790367,
      "pb": 2.26,
      "pe": 12.71,
      "yield": 1.67,
      "date": "1999-01-24"
  },
  "1999-01-25": {
      "equity": 949.8,
      "debt": 12.219,
      "gold": 4292.051006,
      "pb": 2.24,
      "pe": 12.61,
      "yield": 1.68,
      "date": "1999-01-25"
  },
  "1999-01-26": {
      "equity": 949.8,
      "debt": 12.219,
      "gold": 4308.054816,
      "pb": 2.24,
      "pe": 12.61,
      "yield": 1.68,
      "date": "1999-01-26"
  },
  "1999-01-27": {
      "equity": 971.3,
      "debt": 12.219,
      "gold": 4283.546447,
      "pb": 2.29,
      "pe": 12.9,
      "yield": 1.65,
      "date": "1999-01-27"
  },
  "1999-01-28": {
      "equity": 955.1,
      "debt": 12.219,
      "gold": 4232.085222,
      "pb": 2.26,
      "pe": 12.68,
      "yield": 1.68,
      "date": "1999-01-28"
  },
  "1999-01-29": {
      "equity": 966.2,
      "debt": 12.219,
      "gold": 4281.299494,
      "pb": 2.28,
      "pe": 12.83,
      "yield": 1.66,
      "date": "1999-01-29"
  },
  "1999-01-30": {
      "equity": 966.2,
      "debt": 12.219,
      "gold": 4281.299494,
      "pb": 2.28,
      "pe": 12.83,
      "yield": 1.66,
      "date": "1999-01-30"
  },
  "1999-01-31": {
      "equity": 966.2,
      "debt": 12.219,
      "gold": 4281.299494,
      "pb": 2.28,
      "pe": 12.83,
      "yield": 1.66,
      "date": "1999-01-31"
  },
  "1999-02-01": {
      "equity": 940.15,
      "debt": 12.219,
      "gold": 4303.0318,
      "pb": 2.22,
      "pe": 12.48,
      "yield": 1.7,
      "date": "1999-02-01"
  },
  "1999-02-02": {
      "equity": 931.2,
      "debt": 12.221,
      "gold": 4342.750313,
      "pb": 2.2,
      "pe": 12.36,
      "yield": 1.72,
      "date": "1999-02-02"
  },
  "1999-02-03": {
      "equity": 952.4,
      "debt": 12.22,
      "gold": 4305.719678,
      "pb": 2.25,
      "pe": 12.64,
      "yield": 1.69,
      "date": "1999-02-03"
  },
  "1999-02-04": {
      "equity": 939.7,
      "debt": 12.221,
      "gold": 4302.241662,
      "pb": 2.22,
      "pe": 12.48,
      "yield": 1.71,
      "date": "1999-02-04"
  },
  "1999-02-05": {
      "equity": 936.3,
      "debt": 12.22,
      "gold": 4338.662058,
      "pb": 2.21,
      "pe": 12.43,
      "yield": 1.71,
      "date": "1999-02-05"
  },
  "1999-02-06": {
      "equity": 936.3,
      "debt": 12.22,
      "gold": 4338.662058,
      "pb": 2.21,
      "pe": 12.43,
      "yield": 1.71,
      "date": "1999-02-06"
  },
  "1999-02-07": {
      "equity": 936.3,
      "debt": 12.22,
      "gold": 4338.662058,
      "pb": 2.21,
      "pe": 12.43,
      "yield": 1.71,
      "date": "1999-02-07"
  },
  "1999-02-08": {
      "equity": 916.5,
      "debt": 12.22,
      "gold": 4325.183866,
      "pb": 2.17,
      "pe": 12.17,
      "yield": 1.75,
      "date": "1999-02-08"
  },
  "1999-02-09": {
      "equity": 924.75,
      "debt": 12.22,
      "gold": 4306.202931,
      "pb": 2.18,
      "pe": 12.28,
      "yield": 1.73,
      "date": "1999-02-09"
  },
  "1999-02-10": {
      "equity": 959.45,
      "debt": 12.22,
      "gold": 4296.449673,
      "pb": 2.27,
      "pe": 12.74,
      "yield": 1.67,
      "date": "1999-02-10"
  },
  "1999-02-11": {
      "equity": 960.05,
      "debt": 12.222,
      "gold": 4304.971869,
      "pb": 2.27,
      "pe": 12.75,
      "yield": 1.67,
      "date": "1999-02-11"
  },
  "1999-02-12": {
      "equity": 970.25,
      "debt": 12.223,
      "gold": 4302.460361,
      "pb": 2.29,
      "pe": 12.88,
      "yield": 1.62,
      "date": "1999-02-12"
  },
  "1999-02-13": {
      "equity": 970.25,
      "debt": 12.223,
      "gold": 4302.460361,
      "pb": 2.29,
      "pe": 12.88,
      "yield": 1.62,
      "date": "1999-02-13"
  },
  "1999-02-14": {
      "equity": 970.25,
      "debt": 12.223,
      "gold": 4302.460361,
      "pb": 2.29,
      "pe": 12.88,
      "yield": 1.62,
      "date": "1999-02-14"
  },
  "1999-02-15": {
      "equity": 954.55,
      "debt": 12.222,
      "gold": 4343.152437,
      "pb": 2.26,
      "pe": 12.67,
      "yield": 1.64,
      "date": "1999-02-15"
  },
  "1999-02-16": {
      "equity": 958.9,
      "debt": 12.223,
      "gold": 4317.268382,
      "pb": 2.27,
      "pe": 12.73,
      "yield": 1.63,
      "date": "1999-02-16"
  },
  "1999-02-17": {
      "equity": 969.05,
      "debt": 12.223,
      "gold": 4277.306478,
      "pb": 2.29,
      "pe": 12.87,
      "yield": 1.64,
      "date": "1999-02-17"
  },
  "1999-02-18": {
      "equity": 973.45,
      "debt": 12.223,
      "gold": 4277.267677,
      "pb": 2.3,
      "pe": 12.92,
      "yield": 1.63,
      "date": "1999-02-18"
  },
  "1999-02-19": {
      "equity": 976.3,
      "debt": 12.225,
      "gold": 4295.193919,
      "pb": 2.31,
      "pe": 12.96,
      "yield": 1.63,
      "date": "1999-02-19"
  },
  "1999-02-20": {
      "equity": 976.3,
      "debt": 12.225,
      "gold": 4295.193919,
      "pb": 2.31,
      "pe": 12.96,
      "yield": 1.63,
      "date": "1999-02-20"
  },
  "1999-02-21": {
      "equity": 976.3,
      "debt": 12.225,
      "gold": 4295.193919,
      "pb": 2.31,
      "pe": 12.96,
      "yield": 1.63,
      "date": "1999-02-21"
  },
  "1999-02-22": {
      "equity": 953.85,
      "debt": 12.226,
      "gold": 4296.424981,
      "pb": 2.25,
      "pe": 12.66,
      "yield": 1.66,
      "date": "1999-02-22"
  },
  "1999-02-23": {
      "equity": 949.65,
      "debt": 12.224,
      "gold": 4290.220286,
      "pb": 2.24,
      "pe": 12.61,
      "yield": 1.67,
      "date": "1999-02-23"
  },
  "1999-02-24": {
      "equity": 957.65,
      "debt": 12.223,
      "gold": 4278.960828,
      "pb": 2.26,
      "pe": 12.72,
      "yield": 1.66,
      "date": "1999-02-24"
  },
  "1999-02-25": {
      "equity": 954.3,
      "debt": 12.223,
      "gold": 4309.109508,
      "pb": 2.26,
      "pe": 12.68,
      "yield": 1.66,
      "date": "1999-02-25"
  },
  "1999-02-26": {
      "equity": 941.2,
      "debt": 12.223,
      "gold": 4300.559093,
      "pb": 2.23,
      "pe": 12.51,
      "yield": 1.66,
      "date": "1999-02-26"
  },
  "1999-02-27": {
      "equity": 981.3,
      "debt": 12.223,
      "gold": 4300.559093,
      "pb": 2.32,
      "pe": 13.04,
      "yield": 1.59,
      "date": "1999-02-27"
  },
  "1999-02-28": {
      "equity": 981.3,
      "debt": 12.223,
      "gold": 4300.559093,
      "pb": 2.32,
      "pe": 13.04,
      "yield": 1.59,
      "date": "1999-02-28"
  },
  "1999-03-01": {
      "equity": 1015.8,
      "debt": 12.227,
      "gold": 4301.2681,
      "pb": 2.4,
      "pe": 13.5,
      "yield": 1.54,
      "date": "1999-03-01"
  },
  "1999-03-02": {
      "equity": 1015.8,
      "debt": 12.227,
      "gold": 4291.528951,
      "pb": 2.4,
      "pe": 13.5,
      "yield": 1.54,
      "date": "1999-03-02"
  },
  "1999-03-03": {
      "equity": 1051.8,
      "debt": 12.034,
      "gold": 4300.329812,
      "pb": 2.49,
      "pe": 13.98,
      "yield": 1.49,
      "date": "1999-03-03"
  },
  "1999-03-04": {
      "equity": 1041.2,
      "debt": 12.052,
      "gold": 4299.059948,
      "pb": 2.46,
      "pe": 13.83,
      "yield": 1.5,
      "date": "1999-03-04"
  },
  "1999-03-05": {
      "equity": 1054.4,
      "debt": 12.061,
      "gold": 4317.053211,
      "pb": 2.49,
      "pe": 14.01,
      "yield": 1.48,
      "date": "1999-03-05"
  },
  "1999-03-06": {
      "equity": 1054.4,
      "debt": 12.061,
      "gold": 4317.053211,
      "pb": 2.49,
      "pe": 14.01,
      "yield": 1.48,
      "date": "1999-03-06"
  },
  "1999-03-07": {
      "equity": 1054.4,
      "debt": 12.061,
      "gold": 4317.053211,
      "pb": 2.49,
      "pe": 14.01,
      "yield": 1.48,
      "date": "1999-03-07"
  },
  "1999-03-08": {
      "equity": 1072.1,
      "debt": 11.989,
      "gold": 4326.051606,
      "pb": 2.53,
      "pe": 14.25,
      "yield": 1.46,
      "date": "1999-03-08"
  },
  "1999-03-09": {
      "equity": 1078.3,
      "debt": 11.999,
      "gold": 4381.149579,
      "pb": 2.55,
      "pe": 14.33,
      "yield": 1.45,
      "date": "1999-03-09"
  },
  "1999-03-10": {
      "equity": 1078.8,
      "debt": 11.993,
      "gold": 4362.810632,
      "pb": 2.55,
      "pe": 14.35,
      "yield": 1.46,
      "date": "1999-03-10"
  },
  "1999-03-11": {
      "equity": 1059.6,
      "debt": 11.993,
      "gold": 4407.59449,
      "pb": 2.51,
      "pe": 14.09,
      "yield": 1.48,
      "date": "1999-03-11"
  },
  "1999-03-12": {
      "equity": 1072.6,
      "debt": 11.998,
      "gold": 4405.34401,
      "pb": 2.54,
      "pe": 14.26,
      "yield": 1.37,
      "date": "1999-03-12"
  },
  "1999-03-13": {
      "equity": 1072.6,
      "debt": 11.998,
      "gold": 4405.34401,
      "pb": 2.54,
      "pe": 14.26,
      "yield": 1.37,
      "date": "1999-03-13"
  },
  "1999-03-14": {
      "equity": 1072.6,
      "debt": 11.998,
      "gold": 4405.34401,
      "pb": 2.54,
      "pe": 14.26,
      "yield": 1.37,
      "date": "1999-03-14"
  },
  "1999-03-15": {
      "equity": 1073.9,
      "debt": 12.015,
      "gold": 4359.579534,
      "pb": 2.54,
      "pe": 14.28,
      "yield": 1.37,
      "date": "1999-03-15"
  },
  "1999-03-16": {
      "equity": 1053.1,
      "debt": 12.01,
      "gold": 4289.430149,
      "pb": 2.49,
      "pe": 14,
      "yield": 1.39,
      "date": "1999-03-16"
  },
  "1999-03-17": {
      "equity": 1060.6,
      "debt": 12.001,
      "gold": 4248.060812,
      "pb": 2.51,
      "pe": 14.1,
      "yield": 1.51,
      "date": "1999-03-17"
  },
  "1999-03-18": {
      "equity": 1074.6,
      "debt": 12.001,
      "gold": 4255.295508,
      "pb": 2.54,
      "pe": 14.29,
      "yield": 1.49,
      "date": "1999-03-18"
  },
  "1999-03-19": {
      "equity": 1062.9,
      "debt": 11.995,
      "gold": 4239.326972,
      "pb": 2.52,
      "pe": 14.13,
      "yield": 1.5,
      "date": "1999-03-19"
  },
  "1999-03-20": {
      "equity": 1060.3,
      "debt": 11.995,
      "gold": 4239.326972,
      "pb": 2.51,
      "pe": 14.1,
      "yield": 1.5,
      "date": "1999-03-20"
  },
  "1999-03-21": {
      "equity": 1060.3,
      "debt": 11.995,
      "gold": 4239.326972,
      "pb": 2.51,
      "pe": 14.1,
      "yield": 1.5,
      "date": "1999-03-21"
  },
  "1999-03-22": {
      "equity": 1063,
      "debt": 11.987,
      "gold": 4254.029172,
      "pb": 2.52,
      "pe": 14.13,
      "yield": 1.5,
      "date": "1999-03-22"
  },
  "1999-03-23": {
      "equity": 1072.9,
      "debt": 11.987,
      "gold": 4262.999347,
      "pb": 2.54,
      "pe": 14.27,
      "yield": 1.49,
      "date": "1999-03-23"
  },
  "1999-03-24": {
      "equity": 1062.8,
      "debt": 11.985,
      "gold": 4254.300781,
      "pb": 2.51,
      "pe": 14.13,
      "yield": 1.51,
      "date": "1999-03-24"
  },
  "1999-03-25": {
      "equity": 1054.9,
      "debt": 11.985,
      "gold": 4243.813824,
      "pb": 2.5,
      "pe": 14.03,
      "yield": 1.52,
      "date": "1999-03-25"
  },
  "1999-03-26": {
      "equity": 1041.2,
      "debt": 12.004,
      "gold": 4217.640523,
      "pb": 2.46,
      "pe": 13.85,
      "yield": 1.53,
      "date": "1999-03-26"
  },
  "1999-03-27": {
      "equity": 1041.2,
      "debt": 12.004,
      "gold": 4217.640523,
      "pb": 2.46,
      "pe": 13.85,
      "yield": 1.53,
      "date": "1999-03-27"
  },
  "1999-03-28": {
      "equity": 1041.2,
      "debt": 12.004,
      "gold": 4217.640523,
      "pb": 2.46,
      "pe": 13.85,
      "yield": 1.53,
      "date": "1999-03-28"
  },
  "1999-03-29": {
      "equity": 1041.2,
      "debt": 12.004,
      "gold": 4193.950511,
      "pb": 2.46,
      "pe": 13.85,
      "yield": 1.53,
      "date": "1999-03-29"
  },
  "1999-03-30": {
      "equity": 1054.6,
      "debt": 12.004,
      "gold": 4191.439002,
      "pb": 2.5,
      "pe": 14.02,
      "yield": 1.51,
      "date": "1999-03-30"
  },
  "1999-03-31": {
      "equity": 1078,
      "debt": 11.994,
      "gold": 4187.696432,
      "pb": 2.84,
      "pe": 16.53,
      "yield": 1.48,
      "date": "1999-03-31"
  },
  "1999-04-01": {
      "equity": 1063.4,
      "debt": 11.994,
      "gold": 4196.6913,
      "pb": 2.8,
      "pe": 16.31,
      "yield": 1.5,
      "date": "1999-04-01"
  },
  "1999-04-02": {
      "equity": 1063.4,
      "debt": 11.994,
      "gold": 4196.6913,
      "pb": 2.8,
      "pe": 16.31,
      "yield": 1.5,
      "date": "1999-04-02"
  },
  "1999-04-03": {
      "equity": 1063.4,
      "debt": 11.994,
      "gold": 4196.6913,
      "pb": 2.8,
      "pe": 16.31,
      "yield": 1.5,
      "date": "1999-04-03"
  },
  "1999-04-04": {
      "equity": 1063.4,
      "debt": 11.994,
      "gold": 4196.6913,
      "pb": 2.8,
      "pe": 16.31,
      "yield": 1.5,
      "date": "1999-04-04"
  },
  "1999-04-05": {
      "equity": 1011.4,
      "debt": 11.941,
      "gold": 4196.6913,
      "pb": 2.66,
      "pe": 15.51,
      "yield": 1.56,
      "date": "1999-04-05"
  },
  "1999-04-06": {
      "equity": 1024.2,
      "debt": 11.903,
      "gold": 4187.597665,
      "pb": 2.7,
      "pe": 15.71,
      "yield": 1.54,
      "date": "1999-04-06"
  },
  "1999-04-07": {
      "equity": 1031,
      "debt": 11.887,
      "gold": 4218.652886,
      "pb": 2.72,
      "pe": 15.81,
      "yield": 1.54,
      "date": "1999-04-07"
  },
  "1999-04-08": {
      "equity": 1020.3,
      "debt": 11.965,
      "gold": 4221.404258,
      "pb": 2.69,
      "pe": 15.65,
      "yield": 1.55,
      "date": "1999-04-08"
  },
  "1999-04-09": {
      "equity": 993.4,
      "debt": 11.95,
      "gold": 4237.48567,
      "pb": 2.62,
      "pe": 15.24,
      "yield": 1.6,
      "date": "1999-04-09"
  },
  "1999-04-10": {
      "equity": 993.4,
      "debt": 11.95,
      "gold": 4237.48567,
      "pb": 2.62,
      "pe": 15.24,
      "yield": 1.6,
      "date": "1999-04-10"
  },
  "1999-04-11": {
      "equity": 993.4,
      "debt": 11.95,
      "gold": 4237.48567,
      "pb": 2.62,
      "pe": 15.24,
      "yield": 1.6,
      "date": "1999-04-11"
  },
  "1999-04-12": {
      "equity": 998.2,
      "debt": 11.93,
      "gold": 4255.03448,
      "pb": 2.63,
      "pe": 15.31,
      "yield": 1.59,
      "date": "1999-04-12"
  },
  "1999-04-13": {
      "equity": 994.25,
      "debt": 11.932,
      "gold": 4262.487875,
      "pb": 2.62,
      "pe": 15.25,
      "yield": 1.59,
      "date": "1999-04-13"
  },
  "1999-04-14": {
      "equity": 994.25,
      "debt": 11.932,
      "gold": 4280.354151,
      "pb": 2.62,
      "pe": 15.25,
      "yield": 1.59,
      "date": "1999-04-14"
  },
  "1999-04-15": {
      "equity": 968.6,
      "debt": 11.931,
      "gold": 4245.115434,
      "pb": 2.56,
      "pe": 14.87,
      "yield": 1.63,
      "date": "1999-04-15"
  },
  "1999-04-16": {
      "equity": 1044.4,
      "debt": 11.931,
      "gold": 4278.10367,
      "pb": 2.76,
      "pe": 16.04,
      "yield": 1.38,
      "date": "1999-04-16"
  },
  "1999-04-17": {
      "equity": 966.95,
      "debt": 11.931,
      "gold": 4278.10367,
      "pb": 2.55,
      "pe": 14.85,
      "yield": 1.49,
      "date": "1999-04-17"
  },
  "1999-04-18": {
      "equity": 966.95,
      "debt": 11.931,
      "gold": 4278.10367,
      "pb": 2.55,
      "pe": 14.85,
      "yield": 1.49,
      "date": "1999-04-18"
  },
  "1999-04-19": {
      "equity": 993.9,
      "debt": 11.941,
      "gold": 4291.913438,
      "pb": 2.62,
      "pe": 15.26,
      "yield": 1.45,
      "date": "1999-04-19"
  },
  "1999-04-20": {
      "equity": 995.4,
      "debt": 11.941,
      "gold": 4291.913438,
      "pb": 2.63,
      "pe": 15.29,
      "yield": 1.45,
      "date": "1999-04-20"
  },
  "1999-04-21": {
      "equity": 996.65,
      "debt": 11.936,
      "gold": 4285.112612,
      "pb": 2.63,
      "pe": 15.31,
      "yield": 1.45,
      "date": "1999-04-21"
  },
  "1999-04-22": {
      "equity": 981.65,
      "debt": 11.932,
      "gold": 4267.817775,
      "pb": 2.59,
      "pe": 15.08,
      "yield": 1.47,
      "date": "1999-04-22"
  },
  "1999-04-23": {
      "equity": 982,
      "debt": 11.939,
      "gold": 4286.096757,
      "pb": 2.59,
      "pe": 15.08,
      "yield": 1.46,
      "date": "1999-04-23"
  },
  "1999-04-24": {
      "equity": 982,
      "debt": 11.939,
      "gold": 4286.096757,
      "pb": 2.59,
      "pe": 15.08,
      "yield": 1.46,
      "date": "1999-04-24"
  },
  "1999-04-25": {
      "equity": 982,
      "debt": 11.939,
      "gold": 4286.096757,
      "pb": 2.59,
      "pe": 15.08,
      "yield": 1.46,
      "date": "1999-04-25"
  },
  "1999-04-26": {
      "equity": 931.35,
      "debt": 11.926,
      "gold": 4276.033087,
      "pb": 2.46,
      "pe": 14.3,
      "yield": 1.54,
      "date": "1999-04-26"
  },
  "1999-04-27": {
      "equity": 931.35,
      "debt": 11.926,
      "gold": 4251.866876,
      "pb": 2.46,
      "pe": 14.3,
      "yield": 1.54,
      "date": "1999-04-27"
  },
  "1999-04-28": {
      "equity": 943.5,
      "debt": 11.917,
      "gold": 4270.505653,
      "pb": 2.49,
      "pe": 14.49,
      "yield": 1.52,
      "date": "1999-04-28"
  },
  "1999-04-29": {
      "equity": 966.6,
      "debt": 11.886,
      "gold": 4283.345385,
      "pb": 2.55,
      "pe": 14.84,
      "yield": 1.48,
      "date": "1999-04-29"
  },
  "1999-04-30": {
      "equity": 978.2,
      "debt": 11.886,
      "gold": 4315.554066,
      "pb": 2.58,
      "pe": 15.02,
      "yield": 1.25,
      "date": "1999-04-30"
  },
  "1999-05-01": {
      "equity": 978.2,
      "debt": 11.886,
      "gold": 4315.554066,
      "pb": 2.58,
      "pe": 15.02,
      "yield": 1.25,
      "date": "1999-05-01"
  },
  "1999-05-02": {
      "equity": 978.2,
      "debt": 11.886,
      "gold": 4315.554066,
      "pb": 2.58,
      "pe": 15.02,
      "yield": 1.25,
      "date": "1999-05-02"
  },
  "1999-05-03": {
      "equity": 970.75,
      "debt": 11.885,
      "gold": 4315.554066,
      "pb": 2.56,
      "pe": 14.95,
      "yield": 1.25,
      "date": "1999-05-03"
  },
  "1999-05-04": {
      "equity": 1008.3,
      "debt": 11.875,
      "gold": 4305.733787,
      "pb": 2.66,
      "pe": 15.53,
      "yield": 1.21,
      "date": "1999-05-04"
  },
  "1999-05-05": {
      "equity": 1019.5,
      "debt": 11.873,
      "gold": 4315.307148,
      "pb": 2.69,
      "pe": 15.73,
      "yield": 1.35,
      "date": "1999-05-05"
  },
  "1999-05-06": {
      "equity": 1046.9,
      "debt": 11.855,
      "gold": 4342.408155,
      "pb": 2.76,
      "pe": 16.15,
      "yield": 1.31,
      "date": "1999-05-06"
  },
  "1999-05-07": {
      "equity": 1062.9,
      "debt": 11.864,
      "gold": 4272.946613,
      "pb": 2.81,
      "pe": 16.4,
      "yield": 1.29,
      "date": "1999-05-07"
  },
  "1999-05-08": {
      "equity": 1062.9,
      "debt": 11.864,
      "gold": 4272.946613,
      "pb": 2.81,
      "pe": 16.4,
      "yield": 1.29,
      "date": "1999-05-08"
  },
  "1999-05-09": {
      "equity": 1062.9,
      "debt": 11.864,
      "gold": 4272.946613,
      "pb": 2.81,
      "pe": 16.4,
      "yield": 1.29,
      "date": "1999-05-09"
  },
  "1999-05-10": {
      "equity": 1094.2,
      "debt": 11.858,
      "gold": 4233.087003,
      "pb": 2.89,
      "pe": 16.88,
      "yield": 1.26,
      "date": "1999-05-10"
  },
  "1999-05-11": {
      "equity": 1116,
      "debt": 11.863,
      "gold": 4194.451401,
      "pb": 2.95,
      "pe": 17.21,
      "yield": 1.23,
      "date": "1999-05-11"
  },
  "1999-05-12": {
      "equity": 1110.9,
      "debt": 11.851,
      "gold": 4190.176194,
      "pb": 2.93,
      "pe": 17.14,
      "yield": 1.24,
      "date": "1999-05-12"
  },
  "1999-05-13": {
      "equity": 1148.1,
      "debt": 11.848,
      "gold": 4196.941745,
      "pb": 3.03,
      "pe": 17.71,
      "yield": 1.2,
      "date": "1999-05-13"
  },
  "1999-05-14": {
      "equity": 1165.3,
      "debt": 11.86,
      "gold": 4179.957318,
      "pb": 3.08,
      "pe": 17.98,
      "yield": 1.05,
      "date": "1999-05-14"
  },
  "1999-05-15": {
      "equity": 1165.3,
      "debt": 11.86,
      "gold": 4179.957318,
      "pb": 3.08,
      "pe": 17.98,
      "yield": 1.05,
      "date": "1999-05-15"
  },
  "1999-05-16": {
      "equity": 1165.3,
      "debt": 11.86,
      "gold": 4179.957318,
      "pb": 3.08,
      "pe": 17.98,
      "yield": 1.05,
      "date": "1999-05-16"
  },
  "1999-05-17": {
      "equity": 1151.9,
      "debt": 11.854,
      "gold": 4161.537241,
      "pb": 3.04,
      "pe": 17.77,
      "yield": 1.07,
      "date": "1999-05-17"
  },
  "1999-05-18": {
      "equity": 1160.1,
      "debt": 11.847,
      "gold": 4144.312951,
      "pb": 3.06,
      "pe": 17.9,
      "yield": 1.06,
      "date": "1999-05-18"
  },
  "1999-05-19": {
      "equity": 1180.2,
      "debt": 11.835,
      "gold": 4116.883896,
      "pb": 3.12,
      "pe": 18.22,
      "yield": 1.05,
      "date": "1999-05-19"
  },
  "1999-05-20": {
      "equity": 1166.9,
      "debt": 11.828,
      "gold": 4141.72384,
      "pb": 3.08,
      "pe": 18.01,
      "yield": 1.06,
      "date": "1999-05-20"
  },
  "1999-05-21": {
      "equity": 1145.8,
      "debt": 11.784,
      "gold": 4148.030829,
      "pb": 3.03,
      "pe": 17.69,
      "yield": 1.05,
      "date": "1999-05-21"
  },
  "1999-05-22": {
      "equity": 1145.8,
      "debt": 11.784,
      "gold": 4148.030829,
      "pb": 3.03,
      "pe": 17.69,
      "yield": 1.05,
      "date": "1999-05-22"
  },
  "1999-05-23": {
      "equity": 1145.8,
      "debt": 11.784,
      "gold": 4148.030829,
      "pb": 3.03,
      "pe": 17.69,
      "yield": 1.05,
      "date": "1999-05-23"
  },
  "1999-05-24": {
      "equity": 1151.6,
      "debt": 11.757,
      "gold": 4107.317589,
      "pb": 3.01,
      "pe": 18.08,
      "yield": 1.04,
      "date": "1999-05-24"
  },
  "1999-05-25": {
      "equity": 1160.2,
      "debt": 11.719,
      "gold": 4114.347696,
      "pb": 3.03,
      "pe": 18.16,
      "yield": 1.03,
      "date": "1999-05-25"
  },
  "1999-05-26": {
      "equity": 1135.5,
      "debt": 11.718,
      "gold": 4074.417538,
      "pb": 3.1,
      "pe": 18.28,
      "yield": 1.3,
      "date": "1999-05-26"
  },
  "1999-05-27": {
      "equity": 1091.4,
      "debt": 11.792,
      "gold": 4066.39976,
      "pb": 2.96,
      "pe": 17.32,
      "yield": 1.35,
      "date": "1999-05-27"
  },
  "1999-05-28": {
      "equity": 1081.5,
      "debt": 11.772,
      "gold": 4102.153477,
      "pb": 2.93,
      "pe": 17.2,
      "yield": 1.28,
      "date": "1999-05-28"
  },
  "1999-05-29": {
      "equity": 1081.5,
      "debt": 11.772,
      "gold": 4102.153477,
      "pb": 2.93,
      "pe": 17.2,
      "yield": 1.28,
      "date": "1999-05-29"
  },
  "1999-05-30": {
      "equity": 1081.5,
      "debt": 11.772,
      "gold": 4102.153477,
      "pb": 2.93,
      "pe": 17.2,
      "yield": 1.28,
      "date": "1999-05-30"
  },
  "1999-05-31": {
      "equity": 1132.3,
      "debt": 11.7,
      "gold": 4102.153477,
      "pb": 3.05,
      "pe": 18.01,
      "yield": 1.23,
      "date": "1999-05-31"
  },
  "1999-06-01": {
      "equity": 1123.8,
      "debt": 11.714,
      "gold": 4023.309053,
      "pb": 3.02,
      "pe": 18.25,
      "yield": 1.24,
      "date": "1999-06-01"
  },
  "1999-06-02": {
      "equity": 1125.1,
      "debt": 11.706,
      "gold": 4043.030741,
      "pb": 3.05,
      "pe": 18.27,
      "yield": 1.24,
      "date": "1999-06-02"
  },
  "1999-06-03": {
      "equity": 1136.6,
      "debt": 11.704,
      "gold": 3997.841232,
      "pb": 3.08,
      "pe": 18.75,
      "yield": 1.23,
      "date": "1999-06-03"
  },
  "1999-06-04": {
      "equity": 1155,
      "debt": 11.718,
      "gold": 4039.291698,
      "pb": 3.02,
      "pe": 17.45,
      "yield": 1.18,
      "date": "1999-06-04"
  },
  "1999-06-05": {
      "equity": 1155,
      "debt": 11.718,
      "gold": 4039.291698,
      "pb": 3.02,
      "pe": 17.45,
      "yield": 1.18,
      "date": "1999-06-05"
  },
  "1999-06-06": {
      "equity": 1155,
      "debt": 11.718,
      "gold": 4039.291698,
      "pb": 3.02,
      "pe": 17.45,
      "yield": 1.18,
      "date": "1999-06-06"
  },
  "1999-06-07": {
      "equity": 1156.6,
      "debt": 11.721,
      "gold": 4038.304027,
      "pb": 3.03,
      "pe": 17.48,
      "yield": 1.17,
      "date": "1999-06-07"
  },
  "1999-06-08": {
      "equity": 1178.9,
      "debt": 11.724,
      "gold": 4002.959488,
      "pb": 3.09,
      "pe": 17.82,
      "yield": 1.15,
      "date": "1999-06-08"
  },
  "1999-06-09": {
      "equity": 1163.2,
      "debt": 11.724,
      "gold": 3982.285402,
      "pb": 3.05,
      "pe": 17.58,
      "yield": 1.2,
      "date": "1999-06-09"
  },
  "1999-06-10": {
      "equity": 1162.6,
      "debt": 11.722,
      "gold": 3919.289582,
      "pb": 3.04,
      "pe": 17.57,
      "yield": 1.17,
      "date": "1999-06-10"
  },
  "1999-06-11": {
      "equity": 1141.9,
      "debt": 11.739,
      "gold": 3937.896612,
      "pb": 2.99,
      "pe": 17.25,
      "yield": 1.14,
      "date": "1999-06-11"
  },
  "1999-06-12": {
      "equity": 1141.9,
      "debt": 11.739,
      "gold": 3937.896612,
      "pb": 2.99,
      "pe": 17.25,
      "yield": 1.14,
      "date": "1999-06-12"
  },
  "1999-06-13": {
      "equity": 1141.9,
      "debt": 11.739,
      "gold": 3937.896612,
      "pb": 2.99,
      "pe": 17.25,
      "yield": 1.14,
      "date": "1999-06-13"
  },
  "1999-06-14": {
      "equity": 1126.9,
      "debt": 11.751,
      "gold": 3949.477063,
      "pb": 2.95,
      "pe": 17.03,
      "yield": 1.16,
      "date": "1999-06-14"
  },
  "1999-06-15": {
      "equity": 1120.5,
      "debt": 11.765,
      "gold": 3947.484083,
      "pb": 2.93,
      "pe": 16.93,
      "yield": 1.17,
      "date": "1999-06-15"
  },
  "1999-06-16": {
      "equity": 1148.1,
      "debt": 11.763,
      "gold": 3942.016614,
      "pb": 3.01,
      "pe": 17.35,
      "yield": 1.22,
      "date": "1999-06-16"
  },
  "1999-06-17": {
      "equity": 1176.3,
      "debt": 11.76,
      "gold": 3943.54045,
      "pb": 3.08,
      "pe": 17.78,
      "yield": 1.19,
      "date": "1999-06-17"
  },
  "1999-06-18": {
      "equity": 1175.1,
      "debt": 11.765,
      "gold": 3965.688989,
      "pb": 3.08,
      "pe": 17.76,
      "yield": 0.88,
      "date": "1999-06-18"
  },
  "1999-06-19": {
      "equity": 1175.1,
      "debt": 11.765,
      "gold": 3965.688989,
      "pb": 3.08,
      "pe": 17.76,
      "yield": 0.88,
      "date": "1999-06-19"
  },
  "1999-06-20": {
      "equity": 1175.1,
      "debt": 11.765,
      "gold": 3965.688989,
      "pb": 3.08,
      "pe": 17.76,
      "yield": 0.88,
      "date": "1999-06-20"
  },
  "1999-06-21": {
      "equity": 1202.3,
      "debt": 11.763,
      "gold": 3945.364116,
      "pb": 3.15,
      "pe": 18.17,
      "yield": 0.89,
      "date": "1999-06-21"
  },
  "1999-06-22": {
      "equity": 1214.3,
      "debt": 11.763,
      "gold": 3949.022029,
      "pb": 3.18,
      "pe": 18.35,
      "yield": 0.85,
      "date": "1999-06-22"
  },
  "1999-06-23": {
      "equity": 1193.1,
      "debt": 11.761,
      "gold": 3947.184254,
      "pb": 3.12,
      "pe": 18.03,
      "yield": 0.97,
      "date": "1999-06-23"
  },
  "1999-06-24": {
      "equity": 1181.3,
      "debt": 11.766,
      "gold": 3984.137286,
      "pb": 3.09,
      "pe": 17.85,
      "yield": 0.98,
      "date": "1999-06-24"
  },
  "1999-06-25": {
      "equity": 1177,
      "debt": 11.79,
      "gold": 3990.553625,
      "pb": 3.08,
      "pe": 17.79,
      "yield": 0.97,
      "date": "1999-06-25"
  },
  "1999-06-26": {
      "equity": 1177,
      "debt": 11.79,
      "gold": 3990.553625,
      "pb": 3.08,
      "pe": 17.79,
      "yield": 0.97,
      "date": "1999-06-26"
  },
  "1999-06-27": {
      "equity": 1177,
      "debt": 11.79,
      "gold": 3990.553625,
      "pb": 3.08,
      "pe": 17.79,
      "yield": 0.97,
      "date": "1999-06-27"
  },
  "1999-06-28": {
      "equity": 1192.2,
      "debt": 11.816,
      "gold": 3975.272933,
      "pb": 3.12,
      "pe": 18.02,
      "yield": 0.95,
      "date": "1999-06-28"
  },
  "1999-06-29": {
      "equity": 1191.3,
      "debt": 11.851,
      "gold": 3992.564243,
      "pb": 3.12,
      "pe": 18,
      "yield": 0.95,
      "date": "1999-06-29"
  },
  "1999-06-30": {
      "equity": 1187.7,
      "debt": 11.851,
      "gold": 3998.687808,
      "pb": 3.11,
      "pe": 17.91,
      "yield": 1.22,
      "date": "1999-06-30"
  },
  "1999-07-01": {
      "equity": 1183.2,
      "debt": 11.868,
      "gold": 4023.926348,
      "pb": 3.1,
      "pe": 17.84,
      "yield": 1.22,
      "date": "1999-07-01"
  },
  "1999-07-02": {
      "equity": 1197.8,
      "debt": 11.868,
      "gold": 4024.250869,
      "pb": 3.14,
      "pe": 18.06,
      "yield": 1.2,
      "date": "1999-07-02"
  },
  "1999-07-03": {
      "equity": 1197.8,
      "debt": 11.868,
      "gold": 4024.250869,
      "pb": 3.14,
      "pe": 18.06,
      "yield": 1.2,
      "date": "1999-07-03"
  },
  "1999-07-04": {
      "equity": 1197.8,
      "debt": 11.868,
      "gold": 4024.250869,
      "pb": 3.14,
      "pe": 18.06,
      "yield": 1.2,
      "date": "1999-07-04"
  },
  "1999-07-05": {
      "equity": 1230.2,
      "debt": 11.818,
      "gold": 4022.557717,
      "pb": 3.22,
      "pe": 18.55,
      "yield": 1.17,
      "date": "1999-07-05"
  },
  "1999-07-06": {
      "equity": 1241.2,
      "debt": 11.816,
      "gold": 3989.618865,
      "pb": 3.25,
      "pe": 18.72,
      "yield": 1.16,
      "date": "1999-07-06"
  },
  "1999-07-07": {
      "equity": 1243.7,
      "debt": 11.816,
      "gold": 3906.329918,
      "pb": 3.26,
      "pe": 18.75,
      "yield": 1.2,
      "date": "1999-07-07"
  },
  "1999-07-08": {
      "equity": 1244,
      "debt": 11.819,
      "gold": 3926.108044,
      "pb": 3.26,
      "pe": 18.76,
      "yield": 1.2,
      "date": "1999-07-08"
  },
  "1999-07-09": {
      "equity": 1254,
      "debt": 11.807,
      "gold": 3928.044586,
      "pb": 3.28,
      "pe": 18.91,
      "yield": 1.18,
      "date": "1999-07-09"
  },
  "1999-07-10": {
      "equity": 1254,
      "debt": 11.807,
      "gold": 3928.044586,
      "pb": 3.28,
      "pe": 18.91,
      "yield": 1.18,
      "date": "1999-07-10"
  },
  "1999-07-11": {
      "equity": 1254,
      "debt": 11.807,
      "gold": 3928.044586,
      "pb": 3.28,
      "pe": 18.91,
      "yield": 1.18,
      "date": "1999-07-11"
  },
  "1999-07-12": {
      "equity": 1315.3,
      "debt": 11.767,
      "gold": 3904.94365,
      "pb": 3.44,
      "pe": 19.83,
      "yield": 1.13,
      "date": "1999-07-12"
  },
  "1999-07-13": {
      "equity": 1321.4,
      "debt": 11.772,
      "gold": 3877.045451,
      "pb": 3.46,
      "pe": 19.92,
      "yield": 1.12,
      "date": "1999-07-13"
  },
  "1999-07-14": {
      "equity": 1335.1,
      "debt": 11.767,
      "gold": 3899.433852,
      "pb": 3.5,
      "pe": 20.18,
      "yield": 1.18,
      "date": "1999-07-14"
  },
  "1999-07-15": {
      "equity": 1317.7,
      "debt": 11.883,
      "gold": 3872.329318,
      "pb": 3.46,
      "pe": 19.92,
      "yield": 1.19,
      "date": "1999-07-15"
  },
  "1999-07-16": {
      "equity": 1324.2,
      "debt": 11.878,
      "gold": 3888.576518,
      "pb": 3.47,
      "pe": 20.02,
      "yield": 1.13,
      "date": "1999-07-16"
  },
  "1999-07-17": {
      "equity": 1324.2,
      "debt": 11.878,
      "gold": 3888.576518,
      "pb": 3.47,
      "pe": 20.02,
      "yield": 1.13,
      "date": "1999-07-17"
  },
  "1999-07-18": {
      "equity": 1324.2,
      "debt": 11.878,
      "gold": 3888.576518,
      "pb": 3.47,
      "pe": 20.02,
      "yield": 1.13,
      "date": "1999-07-18"
  },
  "1999-07-19": {
      "equity": 1349.6,
      "debt": 11.856,
      "gold": 3860.494894,
      "pb": 3.54,
      "pe": 20.4,
      "yield": 1.11,
      "date": "1999-07-19"
  },
  "1999-07-20": {
      "equity": 1314.7,
      "debt": 11.853,
      "gold": 3856.198522,
      "pb": 3.45,
      "pe": 19.87,
      "yield": 1.14,
      "date": "1999-07-20"
  },
  "1999-07-21": {
      "equity": 1340.1,
      "debt": 11.853,
      "gold": 3856.01157,
      "pb": 3.52,
      "pe": 20.26,
      "yield": 1.14,
      "date": "1999-07-21"
  },
  "1999-07-22": {
      "equity": 1349.2,
      "debt": 11.725,
      "gold": 3895.035186,
      "pb": 3.54,
      "pe": 20.39,
      "yield": 1.13,
      "date": "1999-07-22"
  },
  "1999-07-23": {
      "equity": 1342.9,
      "debt": 11.729,
      "gold": 3891.003369,
      "pb": 3.55,
      "pe": 20.46,
      "yield": 1.06,
      "date": "1999-07-23"
  },
  "1999-07-24": {
      "equity": 1342.9,
      "debt": 11.729,
      "gold": 3891.003369,
      "pb": 3.55,
      "pe": 20.46,
      "yield": 1.06,
      "date": "1999-07-24"
  },
  "1999-07-25": {
      "equity": 1342.9,
      "debt": 11.729,
      "gold": 3891.003369,
      "pb": 3.55,
      "pe": 20.46,
      "yield": 1.06,
      "date": "1999-07-25"
  },
  "1999-07-26": {
      "equity": 1326.1,
      "debt": 11.73,
      "gold": 3869.408632,
      "pb": 3.5,
      "pe": 20.18,
      "yield": 1.07,
      "date": "1999-07-26"
  },
  "1999-07-27": {
      "equity": 1309.8,
      "debt": 11.723,
      "gold": 3883.006755,
      "pb": 3.46,
      "pe": 19.94,
      "yield": 1.08,
      "date": "1999-07-27"
  },
  "1999-07-28": {
      "equity": 1320.1,
      "debt": 11.711,
      "gold": 3880.847987,
      "pb": 3.49,
      "pe": 20.09,
      "yield": 1.07,
      "date": "1999-07-28"
  },
  "1999-07-29": {
      "equity": 1325.7,
      "debt": 11.696,
      "gold": 3887.140867,
      "pb": 3.5,
      "pe": 20.18,
      "yield": 1.07,
      "date": "1999-07-29"
  },
  "1999-07-30": {
      "equity": 1310.1,
      "debt": 11.673,
      "gold": 3924.450167,
      "pb": 3.46,
      "pe": 19.94,
      "yield": 1.08,
      "date": "1999-07-30"
  },
  "1999-07-31": {
      "equity": 1310.1,
      "debt": 11.673,
      "gold": 3924.450167,
      "pb": 3.46,
      "pe": 19.94,
      "yield": 1.08,
      "date": "1999-07-31"
  },
  "1999-08-01": {
      "equity": 1310.1,
      "debt": 11.673,
      "gold": 3924.450167,
      "pb": 3.46,
      "pe": 19.94,
      "yield": 1.08,
      "date": "1999-08-01"
  },
  "1999-08-02": {
      "equity": 1289.6,
      "debt": 11.616,
      "gold": 3901.476217,
      "pb": 3.41,
      "pe": 19.63,
      "yield": 1.1,
      "date": "1999-08-02"
  },
  "1999-08-03": {
      "equity": 1285,
      "debt": 11.585,
      "gold": 3910.428755,
      "pb": 3.39,
      "pe": 19.53,
      "yield": 1.1,
      "date": "1999-08-03"
  },
  "1999-08-04": {
      "equity": 1326.4,
      "debt": 11.517,
      "gold": 3939.321681,
      "pb": 3.5,
      "pe": 20.18,
      "yield": 1.07,
      "date": "1999-08-04"
  },
  "1999-08-05": {
      "equity": 1323.3,
      "debt": 11.467,
      "gold": 3923.811707,
      "pb": 3.49,
      "pe": 20.13,
      "yield": 1.07,
      "date": "1999-08-05"
  },
  "1999-08-06": {
      "equity": 1316.8,
      "debt": 11.488,
      "gold": 3914.668689,
      "pb": 3.48,
      "pe": 20.03,
      "yield": 1.07,
      "date": "1999-08-06"
  },
  "1999-08-07": {
      "equity": 1316.8,
      "debt": 11.488,
      "gold": 3914.668689,
      "pb": 3.48,
      "pe": 20.03,
      "yield": 1.07,
      "date": "1999-08-07"
  },
  "1999-08-08": {
      "equity": 1316.8,
      "debt": 11.488,
      "gold": 3914.668689,
      "pb": 3.48,
      "pe": 20.03,
      "yield": 1.07,
      "date": "1999-08-08"
  },
  "1999-08-09": {
      "equity": 1327.7,
      "debt": 11.501,
      "gold": 3927.328524,
      "pb": 3.51,
      "pe": 20.2,
      "yield": 1.06,
      "date": "1999-08-09"
  },
  "1999-08-10": {
      "equity": 1309.7,
      "debt": 11.547,
      "gold": 3937.110002,
      "pb": 3.44,
      "pe": 19.83,
      "yield": 1.07,
      "date": "1999-08-10"
  },
  "1999-08-11": {
      "equity": 1315.3,
      "debt": 11.582,
      "gold": 3941.695621,
      "pb": 3.45,
      "pe": 19.92,
      "yield": 1.1,
      "date": "1999-08-11"
  },
  "1999-08-12": {
      "equity": 1309.5,
      "debt": 11.633,
      "gold": 3991.640064,
      "pb": 3.44,
      "pe": 19.83,
      "yield": 1.1,
      "date": "1999-08-12"
  },
  "1999-08-13": {
      "equity": 1291.8,
      "debt": 11.556,
      "gold": 3997.470855,
      "pb": 3.39,
      "pe": 19.56,
      "yield": 1.12,
      "date": "1999-08-13"
  },
  "1999-08-14": {
      "equity": 1291.8,
      "debt": 11.556,
      "gold": 3997.470855,
      "pb": 3.39,
      "pe": 19.56,
      "yield": 1.12,
      "date": "1999-08-14"
  },
  "1999-08-15": {
      "equity": 1291.8,
      "debt": 11.556,
      "gold": 3997.470855,
      "pb": 3.39,
      "pe": 19.56,
      "yield": 1.12,
      "date": "1999-08-15"
  },
  "1999-08-16": {
      "equity": 1309.9,
      "debt": 11.52,
      "gold": 3970.666149,
      "pb": 3.44,
      "pe": 19.83,
      "yield": 1.1,
      "date": "1999-08-16"
  },
  "1999-08-17": {
      "equity": 1322.7,
      "debt": 11.525,
      "gold": 3966.606113,
      "pb": 3.47,
      "pe": 20.03,
      "yield": 1.09,
      "date": "1999-08-17"
  },
  "1999-08-18": {
      "equity": 1363.3,
      "debt": 11.522,
      "gold": 4005.816681,
      "pb": 3.58,
      "pe": 20.64,
      "yield": 1.06,
      "date": "1999-08-18"
  },
  "1999-08-19": {
      "equity": 1345,
      "debt": 11.52,
      "gold": 3966.867141,
      "pb": 3.53,
      "pe": 20.37,
      "yield": 1.07,
      "date": "1999-08-19"
  },
  "1999-08-20": {
      "equity": 1347.3,
      "debt": 11.534,
      "gold": 3974.849645,
      "pb": 3.54,
      "pe": 20.4,
      "yield": 1.01,
      "date": "1999-08-20"
  },
  "1999-08-21": {
      "equity": 1347.3,
      "debt": 11.534,
      "gold": 3974.849645,
      "pb": 3.54,
      "pe": 20.4,
      "yield": 1.01,
      "date": "1999-08-21"
  },
  "1999-08-22": {
      "equity": 1347.3,
      "debt": 11.534,
      "gold": 3974.849645,
      "pb": 3.54,
      "pe": 20.4,
      "yield": 1.01,
      "date": "1999-08-22"
  },
  "1999-08-23": {
      "equity": 1369.7,
      "debt": 11.529,
      "gold": 3952.609393,
      "pb": 3.6,
      "pe": 20.74,
      "yield": 0.99,
      "date": "1999-08-23"
  },
  "1999-08-24": {
      "equity": 1382.6,
      "debt": 11.542,
      "gold": 3901.836011,
      "pb": 3.63,
      "pe": 20.94,
      "yield": 0.98,
      "date": "1999-08-24"
  },
  "1999-08-25": {
      "equity": 1371.6,
      "debt": 11.56,
      "gold": 3890.953985,
      "pb": 3.6,
      "pe": 20.79,
      "yield": 1.09,
      "date": "1999-08-25"
  },
  "1999-08-26": {
      "equity": 1402.5,
      "debt": 11.561,
      "gold": 3885.719325,
      "pb": 3.68,
      "pe": 21.25,
      "yield": 1.07,
      "date": "1999-08-26"
  },
  "1999-08-27": {
      "equity": 1417.5,
      "debt": 11.563,
      "gold": 3911.307078,
      "pb": 3.72,
      "pe": 21.48,
      "yield": 1.06,
      "date": "1999-08-27"
  },
  "1999-08-28": {
      "equity": 1417.5,
      "debt": 11.563,
      "gold": 3911.307078,
      "pb": 3.72,
      "pe": 21.48,
      "yield": 1.06,
      "date": "1999-08-28"
  },
  "1999-08-29": {
      "equity": 1417.5,
      "debt": 11.563,
      "gold": 3911.307078,
      "pb": 3.72,
      "pe": 21.48,
      "yield": 1.06,
      "date": "1999-08-29"
  },
  "1999-08-30": {
      "equity": 1422.6,
      "debt": 11.596,
      "gold": 3911.307078,
      "pb": 3.74,
      "pe": 21.56,
      "yield": 1.05,
      "date": "1999-08-30"
  },
  "1999-08-31": {
      "equity": 1412,
      "debt": 11.588,
      "gold": 3907.751459,
      "pb": 3.71,
      "pe": 21.4,
      "yield": 1.06,
      "date": "1999-08-31"
  },
  "1999-09-01": {
      "equity": 1410.7,
      "debt": 11.617,
      "gold": 3921.250816,
      "pb": 3.71,
      "pe": 21.38,
      "yield": 1.06,
      "date": "1999-09-01"
  },
  "1999-09-02": {
      "equity": 1375.8,
      "debt": 11.643,
      "gold": 3893.708884,
      "pb": 3.61,
      "pe": 20.85,
      "yield": 1.09,
      "date": "1999-09-02"
  },
  "1999-09-03": {
      "equity": 1374.9,
      "debt": 11.62,
      "gold": 3918.227835,
      "pb": 3.61,
      "pe": 20.84,
      "yield": 1.08,
      "date": "1999-09-03"
  },
  "1999-09-04": {
      "equity": 1374.9,
      "debt": 11.62,
      "gold": 3918.227835,
      "pb": 3.61,
      "pe": 20.84,
      "yield": 1.08,
      "date": "1999-09-04"
  },
  "1999-09-05": {
      "equity": 1374.9,
      "debt": 11.62,
      "gold": 3918.227835,
      "pb": 3.61,
      "pe": 20.84,
      "yield": 1.08,
      "date": "1999-09-05"
  },
  "1999-09-06": {
      "equity": 1390.2,
      "debt": 11.66,
      "gold": 3908.986049,
      "pb": 3.65,
      "pe": 21.07,
      "yield": 1.07,
      "date": "1999-09-06"
  },
  "1999-09-07": {
      "equity": 1383.2,
      "debt": 11.665,
      "gold": 3911.906736,
      "pb": 3.63,
      "pe": 20.96,
      "yield": 1.08,
      "date": "1999-09-07"
  },
  "1999-09-08": {
      "equity": 1399.5,
      "debt": 11.629,
      "gold": 3931.272156,
      "pb": 3.87,
      "pe": 21.73,
      "yield": 1.04,
      "date": "1999-09-08"
  },
  "1999-09-09": {
      "equity": 1396.9,
      "debt": 11.617,
      "gold": 3945.822678,
      "pb": 3.86,
      "pe": 21.69,
      "yield": 1.05,
      "date": "1999-09-09"
  },
  "1999-09-10": {
      "equity": 1391.1,
      "debt": 11.61,
      "gold": 3932.986472,
      "pb": 3.85,
      "pe": 21.6,
      "yield": 1.05,
      "date": "1999-09-10"
  },
  "1999-09-11": {
      "equity": 1391.1,
      "debt": 11.61,
      "gold": 3932.986472,
      "pb": 3.85,
      "pe": 21.6,
      "yield": 1.05,
      "date": "1999-09-11"
  },
  "1999-09-12": {
      "equity": 1391.1,
      "debt": 11.61,
      "gold": 3932.986472,
      "pb": 3.85,
      "pe": 21.6,
      "yield": 1.05,
      "date": "1999-09-12"
  },
  "1999-09-13": {
      "equity": 1391.1,
      "debt": 11.61,
      "gold": 3942.295279,
      "pb": 3.85,
      "pe": 21.6,
      "yield": 1.05,
      "date": "1999-09-13"
  },
  "1999-09-14": {
      "equity": 1370.5,
      "debt": 11.612,
      "gold": 3943.607471,
      "pb": 3.79,
      "pe": 21.28,
      "yield": 1.07,
      "date": "1999-09-14"
  },
  "1999-09-15": {
      "equity": 1374.7,
      "debt": 11.614,
      "gold": 3954.933949,
      "pb": 3.8,
      "pe": 21.34,
      "yield": 1.06,
      "date": "1999-09-15"
  },
  "1999-09-16": {
      "equity": 1351.2,
      "debt": 11.598,
      "gold": 3929.822395,
      "pb": 3.74,
      "pe": 20.98,
      "yield": 1.08,
      "date": "1999-09-16"
  },
  "1999-09-17": {
      "equity": 1359.7,
      "debt": 11.574,
      "gold": 3919.437733,
      "pb": 3.76,
      "pe": 21.11,
      "yield": 1.08,
      "date": "1999-09-17"
  },
  "1999-09-18": {
      "equity": 1359.7,
      "debt": 11.574,
      "gold": 3919.437733,
      "pb": 3.76,
      "pe": 21.11,
      "yield": 1.08,
      "date": "1999-09-18"
  },
  "1999-09-19": {
      "equity": 1359.7,
      "debt": 11.574,
      "gold": 3919.437733,
      "pb": 3.76,
      "pe": 21.11,
      "yield": 1.08,
      "date": "1999-09-19"
  },
  "1999-09-20": {
      "equity": 1357.8,
      "debt": 11.54,
      "gold": 3923.674139,
      "pb": 3.76,
      "pe": 21.06,
      "yield": 1.08,
      "date": "1999-09-20"
  },
  "1999-09-21": {
      "equity": 1350.6,
      "debt": 11.549,
      "gold": 3923.039207,
      "pb": 3.74,
      "pe": 20.95,
      "yield": 1.08,
      "date": "1999-09-21"
  },
  "1999-09-22": {
      "equity": 1386.4,
      "debt": 11.561,
      "gold": 4038.508616,
      "pb": 3.84,
      "pe": 21.51,
      "yield": 1.06,
      "date": "1999-09-22"
  },
  "1999-09-23": {
      "equity": 1398,
      "debt": 11.562,
      "gold": 4051.182561,
      "pb": 3.87,
      "pe": 21.68,
      "yield": 1.05,
      "date": "1999-09-23"
  },
  "1999-09-24": {
      "equity": 1412.2,
      "debt": 11.552,
      "gold": 4085.140831,
      "pb": 3.91,
      "pe": 21.91,
      "yield": 1.04,
      "date": "1999-09-24"
  },
  "1999-09-25": {
      "equity": 1412.2,
      "debt": 11.552,
      "gold": 4085.140831,
      "pb": 3.91,
      "pe": 21.91,
      "yield": 1.04,
      "date": "1999-09-25"
  },
  "1999-09-26": {
      "equity": 1412.2,
      "debt": 11.552,
      "gold": 4085.140831,
      "pb": 3.91,
      "pe": 21.91,
      "yield": 1.04,
      "date": "1999-09-26"
  },
  "1999-09-27": {
      "equity": 1389.5,
      "debt": 11.556,
      "gold": 4333.388596,
      "pb": 3.84,
      "pe": 21.55,
      "yield": 1.06,
      "date": "1999-09-27"
  },
  "1999-09-28": {
      "equity": 1379.1,
      "debt": 11.552,
      "gold": 4434.145223,
      "pb": 3.82,
      "pe": 21.39,
      "yield": 1.06,
      "date": "1999-09-28"
  },
  "1999-09-29": {
      "equity": 1415.3,
      "debt": 11.634,
      "gold": 4882.491755,
      "pb": 3.92,
      "pe": 21.99,
      "yield": 1.04,
      "date": "1999-09-29"
  },
  "1999-09-30": {
      "equity": 1413.1,
      "debt": 11.634,
      "gold": 4674.45634,
      "pb": 3.92,
      "pe": 21.96,
      "yield": 1.04,
      "date": "1999-09-30"
  },
  "1999-10-01": {
      "equity": 1403.2,
      "debt": 11.627,
      "gold": 4662.11397,
      "pb": 3.89,
      "pe": 21.81,
      "yield": 1.09,
      "date": "1999-10-01"
  },
  "1999-10-02": {
      "equity": 1403.2,
      "debt": 11.627,
      "gold": 4662.11397,
      "pb": 3.89,
      "pe": 21.81,
      "yield": 1.09,
      "date": "1999-10-02"
  },
  "1999-10-03": {
      "equity": 1403.2,
      "debt": 11.627,
      "gold": 4662.11397,
      "pb": 3.89,
      "pe": 21.81,
      "yield": 1.09,
      "date": "1999-10-03"
  },
  "1999-10-04": {
      "equity": 1372.3,
      "debt": 11.641,
      "gold": 4796.747738,
      "pb": 3.8,
      "pe": 21.33,
      "yield": 0.93,
      "date": "1999-10-04"
  },
  "1999-10-05": {
      "equity": 1378.1,
      "debt": 11.638,
      "gold": 5021.5771,
      "pb": 3.82,
      "pe": 21.42,
      "yield": 1.11,
      "date": "1999-10-05"
  },
  "1999-10-06": {
      "equity": 1392.7,
      "debt": 11.638,
      "gold": 4996.031676,
      "pb": 3.88,
      "pe": 21.73,
      "yield": 1.1,
      "date": "1999-10-06"
  },
  "1999-10-07": {
      "equity": 1469.7,
      "debt": 11.567,
      "gold": 4886.294291,
      "pb": 4.09,
      "pe": 22.94,
      "yield": 1.04,
      "date": "1999-10-07"
  },
  "1999-10-08": {
      "equity": 1479.2,
      "debt": 11.567,
      "gold": 4947.212473,
      "pb": 4.12,
      "pe": 23.08,
      "yield": 1.03,
      "date": "1999-10-08"
  },
  "1999-10-09": {
      "equity": 1479.2,
      "debt": 11.567,
      "gold": 4947.212473,
      "pb": 4.12,
      "pe": 23.08,
      "yield": 1.03,
      "date": "1999-10-09"
  },
  "1999-10-10": {
      "equity": 1479.2,
      "debt": 11.567,
      "gold": 4947.212473,
      "pb": 4.12,
      "pe": 23.08,
      "yield": 1.03,
      "date": "1999-10-10"
  },
  "1999-10-11": {
      "equity": 1484,
      "debt": 11.544,
      "gold": 4861.045168,
      "pb": 4.13,
      "pe": 23.16,
      "yield": 1.03,
      "date": "1999-10-11"
  },
  "1999-10-12": {
      "equity": 1483.8,
      "debt": 11.542,
      "gold": 4960.990494,
      "pb": 4.13,
      "pe": 23.16,
      "yield": 1.03,
      "date": "1999-10-12"
  },
  "1999-10-13": {
      "equity": 1496.3,
      "debt": 11.574,
      "gold": 4914.421771,
      "pb": 4.17,
      "pe": 23.35,
      "yield": 1.02,
      "date": "1999-10-13"
  },
  "1999-10-14": {
      "equity": 1505.2,
      "debt": 11.566,
      "gold": 4945.110143,
      "pb": 4.19,
      "pe": 23.49,
      "yield": 1.01,
      "date": "1999-10-14"
  },
  "1999-10-15": {
      "equity": 1454.5,
      "debt": 11.591,
      "gold": 4844.417009,
      "pb": 4.04,
      "pe": 22.65,
      "yield": 1.05,
      "date": "1999-10-15"
  },
  "1999-10-16": {
      "equity": 1454.5,
      "debt": 11.591,
      "gold": 4844.417009,
      "pb": 4.04,
      "pe": 22.65,
      "yield": 1.05,
      "date": "1999-10-16"
  },
  "1999-10-17": {
      "equity": 1454.5,
      "debt": 11.591,
      "gold": 4844.417009,
      "pb": 4.04,
      "pe": 22.65,
      "yield": 1.05,
      "date": "1999-10-17"
  },
  "1999-10-18": {
      "equity": 1444,
      "debt": 11.565,
      "gold": 4862.315032,
      "pb": 4.01,
      "pe": 22.48,
      "yield": 1.06,
      "date": "1999-10-18"
  },
  "1999-10-19": {
      "equity": 1444,
      "debt": 11.565,
      "gold": 4732.362123,
      "pb": 4.01,
      "pe": 22.48,
      "yield": 1.06,
      "date": "1999-10-19"
  },
  "1999-10-20": {
      "equity": 1477.6,
      "debt": 11.561,
      "gold": 4743.028978,
      "pb": 4.11,
      "pe": 23.01,
      "yield": 1.03,
      "date": "1999-10-20"
  },
  "1999-10-21": {
      "equity": 1453.3,
      "debt": 11.557,
      "gold": 4679.835623,
      "pb": 4.04,
      "pe": 22.63,
      "yield": 1.05,
      "date": "1999-10-21"
  },
  "1999-10-22": {
      "equity": 1430.2,
      "debt": 11.54,
      "gold": 4642.427556,
      "pb": 3.98,
      "pe": 22.27,
      "yield": 1.07,
      "date": "1999-10-22"
  },
  "1999-10-23": {
      "equity": 1449.8,
      "debt": 11.54,
      "gold": 4642.427556,
      "pb": 4.03,
      "pe": 22.57,
      "yield": 1.05,
      "date": "1999-10-23"
  },
  "1999-10-24": {
      "equity": 1449.8,
      "debt": 11.54,
      "gold": 4642.427556,
      "pb": 4.03,
      "pe": 22.57,
      "yield": 1.05,
      "date": "1999-10-24"
  },
  "1999-10-25": {
      "equity": 1426.2,
      "debt": 11.529,
      "gold": 4627.548987,
      "pb": 3.96,
      "pe": 22.21,
      "yield": 1.07,
      "date": "1999-10-25"
  },
  "1999-10-26": {
      "equity": 1414.3,
      "debt": 11.532,
      "gold": 4564.443817,
      "pb": 3.93,
      "pe": 22.02,
      "yield": 1.08,
      "date": "1999-10-26"
  },
  "1999-10-27": {
      "equity": 1411.4,
      "debt": 11.533,
      "gold": 4442.635673,
      "pb": 3.92,
      "pe": 21.99,
      "yield": 1.08,
      "date": "1999-10-27"
  },
  "1999-10-28": {
      "equity": 1367.7,
      "debt": 11.533,
      "gold": 4605.527434,
      "pb": 3.8,
      "pe": 21.31,
      "yield": 1.12,
      "date": "1999-10-28"
  },
  "1999-10-29": {
      "equity": 1325.4,
      "debt": 11.521,
      "gold": 4578.23242,
      "pb": 3.69,
      "pe": 20.65,
      "yield": 1.15,
      "date": "1999-10-29"
  },
  "1999-10-30": {
      "equity": 1325.4,
      "debt": 11.521,
      "gold": 4578.23242,
      "pb": 3.69,
      "pe": 20.65,
      "yield": 1.15,
      "date": "1999-10-30"
  },
  "1999-10-31": {
      "equity": 1325.4,
      "debt": 11.521,
      "gold": 4578.23242,
      "pb": 3.69,
      "pe": 20.65,
      "yield": 1.15,
      "date": "1999-10-31"
  },
  "1999-11-01": {
      "equity": 1270,
      "debt": 11.513,
      "gold": 4496.587241,
      "pb": 3.53,
      "pe": 19.78,
      "yield": 1.2,
      "date": "1999-11-01"
  },
  "1999-11-02": {
      "equity": 1332.2,
      "debt": 11.51,
      "gold": 4474.057744,
      "pb": 3.7,
      "pe": 20.75,
      "yield": 1.15,
      "date": "1999-11-02"
  },
  "1999-11-03": {
      "equity": 1326.4,
      "debt": 11.504,
      "gold": 4469.461543,
      "pb": 3.69,
      "pe": 20.66,
      "yield": 1.17,
      "date": "1999-11-03"
  },
  "1999-11-04": {
      "equity": 1336.8,
      "debt": 11.499,
      "gold": 4478.153054,
      "pb": 3.72,
      "pe": 20.83,
      "yield": 1.16,
      "date": "1999-11-04"
  },
  "1999-11-05": {
      "equity": 1364.5,
      "debt": 11.497,
      "gold": 4475.454594,
      "pb": 3.79,
      "pe": 21.26,
      "yield": 1.14,
      "date": "1999-11-05"
  },
  "1999-11-06": {
      "equity": 1364.5,
      "debt": 11.497,
      "gold": 4475.454594,
      "pb": 3.79,
      "pe": 21.26,
      "yield": 1.14,
      "date": "1999-11-06"
  },
  "1999-11-07": {
      "equity": 1369.6,
      "debt": 11.497,
      "gold": 4475.454594,
      "pb": 3.81,
      "pe": 21.34,
      "yield": 1.14,
      "date": "1999-11-07"
  },
  "1999-11-08": {
      "equity": 1369.6,
      "debt": 11.497,
      "gold": 4428.353234,
      "pb": 3.81,
      "pe": 21.34,
      "yield": 1.14,
      "date": "1999-11-08"
  },
  "1999-11-09": {
      "equity": 1371.2,
      "debt": 11.497,
      "gold": 4462.830032,
      "pb": 3.81,
      "pe": 21.36,
      "yield": 1.14,
      "date": "1999-11-09"
  },
  "1999-11-10": {
      "equity": 1389.1,
      "debt": 11.497,
      "gold": 4472.022434,
      "pb": 3.86,
      "pe": 21.64,
      "yield": 1.12,
      "date": "1999-11-10"
  },
  "1999-11-11": {
      "equity": 1389.6,
      "debt": 11.46,
      "gold": 4534.743117,
      "pb": 3.86,
      "pe": 21.65,
      "yield": 1.12,
      "date": "1999-11-11"
  },
  "1999-11-12": {
      "equity": 1373.5,
      "debt": 11.442,
      "gold": 4485.546482,
      "pb": 3.82,
      "pe": 21.41,
      "yield": 1.14,
      "date": "1999-11-12"
  },
  "1999-11-13": {
      "equity": 1373.5,
      "debt": 11.442,
      "gold": 4485.546482,
      "pb": 3.82,
      "pe": 21.41,
      "yield": 1.14,
      "date": "1999-11-13"
  },
  "1999-11-14": {
      "equity": 1373.5,
      "debt": 11.442,
      "gold": 4485.546482,
      "pb": 3.82,
      "pe": 21.41,
      "yield": 1.14,
      "date": "1999-11-14"
  },
  "1999-11-15": {
      "equity": 1362.7,
      "debt": 11.428,
      "gold": 4483.377132,
      "pb": 3.79,
      "pe": 21.24,
      "yield": 1.14,
      "date": "1999-11-15"
  },
  "1999-11-16": {
      "equity": 1357.7,
      "debt": 11.412,
      "gold": 4471.747297,
      "pb": 3.78,
      "pe": 21.16,
      "yield": 1.15,
      "date": "1999-11-16"
  },
  "1999-11-17": {
      "equity": 1352.2,
      "debt": 11.412,
      "gold": 4523.540098,
      "pb": 3.77,
      "pe": 21.1,
      "yield": 1.15,
      "date": "1999-11-17"
  },
  "1999-11-18": {
      "equity": 1364.2,
      "debt": 11.415,
      "gold": 4497.532584,
      "pb": 3.8,
      "pe": 21.28,
      "yield": 1.14,
      "date": "1999-11-18"
  },
  "1999-11-19": {
      "equity": 1361.8,
      "debt": 11.425,
      "gold": 4494.777686,
      "pb": 3.79,
      "pe": 21.25,
      "yield": 1.14,
      "date": "1999-11-19"
  },
  "1999-11-20": {
      "equity": 1361.8,
      "debt": 11.425,
      "gold": 4494.777686,
      "pb": 3.79,
      "pe": 21.25,
      "yield": 1.14,
      "date": "1999-11-20"
  },
  "1999-11-21": {
      "equity": 1361.8,
      "debt": 11.425,
      "gold": 4494.777686,
      "pb": 3.79,
      "pe": 21.25,
      "yield": 1.14,
      "date": "1999-11-21"
  },
  "1999-11-22": {
      "equity": 1375.2,
      "debt": 11.428,
      "gold": 4507.98074,
      "pb": 3.83,
      "pe": 21.46,
      "yield": 1.13,
      "date": "1999-11-22"
  },
  "1999-11-23": {
      "equity": 1375.2,
      "debt": 11.428,
      "gold": 4543.723875,
      "pb": 3.83,
      "pe": 21.46,
      "yield": 1.13,
      "date": "1999-11-23"
  },
  "1999-11-24": {
      "equity": 1394.9,
      "debt": 11.407,
      "gold": 4562.912926,
      "pb": 3.89,
      "pe": 21.76,
      "yield": 1.12,
      "date": "1999-11-24"
  },
  "1999-11-25": {
      "equity": 1408.6,
      "debt": 11.392,
      "gold": 4569.184642,
      "pb": 3.92,
      "pe": 21.98,
      "yield": 1.11,
      "date": "1999-11-25"
  },
  "1999-11-26": {
      "equity": 1399.6,
      "debt": 11.386,
      "gold": 4553.477134,
      "pb": 3.9,
      "pe": 21.84,
      "yield": 1.11,
      "date": "1999-11-26"
  },
  "1999-11-27": {
      "equity": 1399.6,
      "debt": 11.386,
      "gold": 4553.477134,
      "pb": 3.9,
      "pe": 21.84,
      "yield": 1.11,
      "date": "1999-11-27"
  },
  "1999-11-28": {
      "equity": 1399.6,
      "debt": 11.386,
      "gold": 4553.477134,
      "pb": 3.9,
      "pe": 21.84,
      "yield": 1.11,
      "date": "1999-11-28"
  },
  "1999-11-29": {
      "equity": 1384.6,
      "debt": 11.384,
      "gold": 4486.523572,
      "pb": 3.86,
      "pe": 21.6,
      "yield": 1.12,
      "date": "1999-11-29"
  },
  "1999-11-30": {
      "equity": 1376.1,
      "debt": 11.384,
      "gold": 4472.004797,
      "pb": 3.83,
      "pe": 21.47,
      "yield": 1.13,
      "date": "1999-11-30"
  },
  "1999-12-01": {
      "equity": 1388.7,
      "debt": 11.365,
      "gold": 4465.489691,
      "pb": 3.87,
      "pe": 21.69,
      "yield": 1.12,
      "date": "1999-12-01"
  },
  "1999-12-02": {
      "equity": 1408.8,
      "debt": 11.323,
      "gold": 4432.695462,
      "pb": 3.93,
      "pe": 22.01,
      "yield": 1.1,
      "date": "1999-12-02"
  },
  "1999-12-03": {
      "equity": 1418,
      "debt": 11.268,
      "gold": 4304.213478,
      "pb": 3.95,
      "pe": 22.15,
      "yield": 1.09,
      "date": "1999-12-03"
  },
  "1999-12-04": {
      "equity": 1418,
      "debt": 11.268,
      "gold": 4304.213478,
      "pb": 3.95,
      "pe": 22.15,
      "yield": 1.09,
      "date": "1999-12-04"
  },
  "1999-12-05": {
      "equity": 1418,
      "debt": 11.268,
      "gold": 4304.213478,
      "pb": 3.95,
      "pe": 22.15,
      "yield": 1.09,
      "date": "1999-12-05"
  },
  "1999-12-06": {
      "equity": 1442.8,
      "debt": 11.194,
      "gold": 4246.723928,
      "pb": 4.02,
      "pe": 22.54,
      "yield": 1.08,
      "date": "1999-12-06"
  },
  "1999-12-07": {
      "equity": 1445.1,
      "debt": 11.271,
      "gold": 4296.223919,
      "pb": 4.03,
      "pe": 22.58,
      "yield": 1.07,
      "date": "1999-12-07"
  },
  "1999-12-08": {
      "equity": 1438.6,
      "debt": 11.383,
      "gold": 4345.759184,
      "pb": 4.18,
      "pe": 23.41,
      "yield": 1.04,
      "date": "1999-12-08"
  },
  "1999-12-09": {
      "equity": 1447.5,
      "debt": 11.391,
      "gold": 4286.043846,
      "pb": 4.2,
      "pe": 23.56,
      "yield": 1.03,
      "date": "1999-12-09"
  },
  "1999-12-10": {
      "equity": 1439.7,
      "debt": 11.434,
      "gold": 4270.625584,
      "pb": 4.18,
      "pe": 23.43,
      "yield": 1.03,
      "date": "1999-12-10"
  },
  "1999-12-11": {
      "equity": 1439.7,
      "debt": 11.434,
      "gold": 4270.625584,
      "pb": 4.18,
      "pe": 23.43,
      "yield": 1.03,
      "date": "1999-12-11"
  },
  "1999-12-12": {
      "equity": 1439.7,
      "debt": 11.434,
      "gold": 4270.625584,
      "pb": 4.18,
      "pe": 23.43,
      "yield": 1.03,
      "date": "1999-12-12"
  },
  "1999-12-13": {
      "equity": 1427.6,
      "debt": 11.352,
      "gold": 4295.991111,
      "pb": 4.15,
      "pe": 23.23,
      "yield": 1.04,
      "date": "1999-12-13"
  },
  "1999-12-14": {
      "equity": 1398.2,
      "debt": 11.359,
      "gold": 4306.908411,
      "pb": 4.06,
      "pe": 22.75,
      "yield": 1.07,
      "date": "1999-12-14"
  },
  "1999-12-15": {
      "equity": 1401.4,
      "debt": 11.368,
      "gold": 4299.994709,
      "pb": 4.07,
      "pe": 22.8,
      "yield": 1.08,
      "date": "1999-12-15"
  },
  "1999-12-16": {
      "equity": 1419.7,
      "debt": 11.368,
      "gold": 4299.712517,
      "pb": 4.12,
      "pe": 23.1,
      "yield": 1.06,
      "date": "1999-12-16"
  },
  "1999-12-17": {
      "equity": 1419.3,
      "debt": 11.35,
      "gold": 4342.623327,
      "pb": 4.12,
      "pe": 23.1,
      "yield": 1.06,
      "date": "1999-12-17"
  },
  "1999-12-18": {
      "equity": 1419.3,
      "debt": 11.35,
      "gold": 4342.623327,
      "pb": 4.12,
      "pe": 23.1,
      "yield": 1.06,
      "date": "1999-12-18"
  },
  "1999-12-19": {
      "equity": 1419.3,
      "debt": 11.35,
      "gold": 4342.623327,
      "pb": 4.12,
      "pe": 23.1,
      "yield": 1.06,
      "date": "1999-12-19"
  },
  "1999-12-20": {
      "equity": 1422.4,
      "debt": 11.319,
      "gold": 4364.842413,
      "pb": 4.13,
      "pe": 23.15,
      "yield": 1.06,
      "date": "1999-12-20"
  },
  "1999-12-21": {
      "equity": 1421.7,
      "debt": 11.303,
      "gold": 4370.71906,
      "pb": 4.13,
      "pe": 23.13,
      "yield": 1.06,
      "date": "1999-12-21"
  },
  "1999-12-22": {
      "equity": 1481.7,
      "debt": 11.298,
      "gold": 4404.289317,
      "pb": 4.3,
      "pe": 24.11,
      "yield": 1.02,
      "date": "1999-12-22"
  },
  "1999-12-23": {
      "equity": 1488.3,
      "debt": 11.294,
      "gold": 4400.130514,
      "pb": 4.32,
      "pe": 24.22,
      "yield": 1.02,
      "date": "1999-12-23"
  },
  "1999-12-24": {
      "equity": 1459,
      "debt": 11.274,
      "gold": 4419.100866,
      "pb": 4.24,
      "pe": 23.74,
      "yield": 1.04,
      "date": "1999-12-24"
  },
  "1999-12-25": {
      "equity": 1459,
      "debt": 11.274,
      "gold": 4419.100866,
      "pb": 4.24,
      "pe": 23.74,
      "yield": 1.04,
      "date": "1999-12-25"
  },
  "1999-12-26": {
      "equity": 1459,
      "debt": 11.274,
      "gold": 4419.100866,
      "pb": 4.24,
      "pe": 23.74,
      "yield": 1.04,
      "date": "1999-12-26"
  },
  "1999-12-27": {
      "equity": 1432.1,
      "debt": 11.278,
      "gold": 4419.100866,
      "pb": 4.16,
      "pe": 23.3,
      "yield": 1.06,
      "date": "1999-12-27"
  },
  "1999-12-28": {
      "equity": 1442.2,
      "debt": 11.263,
      "gold": 4419.100866,
      "pb": 4.19,
      "pe": 23.47,
      "yield": 1.05,
      "date": "1999-12-28"
  },
  "1999-12-29": {
      "equity": 1476.3,
      "debt": 11.211,
      "gold": 4466.071712,
      "pb": 4.29,
      "pe": 24.02,
      "yield": 1.03,
      "date": "1999-12-29"
  },
  "1999-12-30": {
      "equity": 1480.4,
      "debt": 11.197,
      "gold": 4464.65017,
      "pb": 4.3,
      "pe": 24.09,
      "yield": 1.02,
      "date": "1999-12-30"
  },
  "1999-12-31": {
      "equity": 1480.4,
      "debt": 11.197,
      "gold": 4464.65017,
      "pb": 4.3,
      "pe": 24.09,
      "yield": 1.02,
      "date": "1999-12-31"
  },
  "2000-01-01": {
      "equity": 1480.4,
      "debt": 11.197,
      "gold": 4464.65017,
      "pb": 4.3,
      "pe": 24.09,
      "yield": 1.02,
      "date": "2000-01-01"
  },
  "2000-01-02": {
      "equity": 1480.4,
      "debt": 11.197,
      "gold": 4464.65017,
      "pb": 4.3,
      "pe": 24.09,
      "yield": 1.02,
      "date": "2000-01-02"
  },
  "2000-01-03": {
      "equity": 1592.2,
      "debt": 11.171,
      "gold": 4464.65017,
      "pb": 4.63,
      "pe": 25.91,
      "yield": 0.95,
      "date": "2000-01-03"
  },
  "2000-01-04": {
      "equity": 1638.7,
      "debt": 11.195,
      "gold": 4333.049966,
      "pb": 4.76,
      "pe": 26.67,
      "yield": 0.92,
      "date": "2000-01-04"
  },
  "2000-01-05": {
      "equity": 1595.8,
      "debt": 11.233,
      "gold": 4330.337396,
      "pb": 4.64,
      "pe": 25.97,
      "yield": 0.95,
      "date": "2000-01-05"
  },
  "2000-01-06": {
      "equity": 1617.6,
      "debt": 11.331,
      "gold": 4306.291116,
      "pb": 4.7,
      "pe": 26.32,
      "yield": 0.94,
      "date": "2000-01-06"
  },
  "2000-01-07": {
      "equity": 1613.3,
      "debt": 11.34,
      "gold": 4332.531438,
      "pb": 4.69,
      "pe": 26.25,
      "yield": 0.94,
      "date": "2000-01-07"
  },
  "2000-01-08": {
      "equity": 1613.3,
      "debt": 11.34,
      "gold": 4332.531438,
      "pb": 4.69,
      "pe": 26.25,
      "yield": 0.94,
      "date": "2000-01-08"
  },
  "2000-01-09": {
      "equity": 1613.3,
      "debt": 11.34,
      "gold": 4332.531438,
      "pb": 4.69,
      "pe": 26.25,
      "yield": 0.94,
      "date": "2000-01-09"
  },
  "2000-01-10": {
      "equity": 1632.9,
      "debt": 11.275,
      "gold": 4324.443112,
      "pb": 4.74,
      "pe": 26.57,
      "yield": 0.93,
      "date": "2000-01-10"
  },
  "2000-01-11": {
      "equity": 1572.5,
      "debt": 11.271,
      "gold": 4325.19092,
      "pb": 4.57,
      "pe": 25.59,
      "yield": 0.96,
      "date": "2000-01-11"
  },
  "2000-01-12": {
      "equity": 1624.8,
      "debt": 11.283,
      "gold": 4334.379795,
      "pb": 4.72,
      "pe": 26.44,
      "yield": 0.93,
      "date": "2000-01-12"
  },
  "2000-01-13": {
      "equity": 1621.4,
      "debt": 11.286,
      "gold": 4336.700824,
      "pb": 4.71,
      "pe": 26.38,
      "yield": 0.93,
      "date": "2000-01-13"
  },
  "2000-01-14": {
      "equity": 1622.7,
      "debt": 11.275,
      "gold": 4362.055768,
      "pb": 4.71,
      "pe": 26.41,
      "yield": 0.93,
      "date": "2000-01-14"
  },
  "2000-01-15": {
      "equity": 1622.7,
      "debt": 11.275,
      "gold": 4362.055768,
      "pb": 4.71,
      "pe": 26.41,
      "yield": 0.93,
      "date": "2000-01-15"
  },
  "2000-01-16": {
      "equity": 1622.7,
      "debt": 11.275,
      "gold": 4362.055768,
      "pb": 4.71,
      "pe": 26.41,
      "yield": 0.93,
      "date": "2000-01-16"
  },
  "2000-01-17": {
      "equity": 1611.6,
      "debt": 11.065,
      "gold": 4375.579816,
      "pb": 4.68,
      "pe": 26.22,
      "yield": 0.94,
      "date": "2000-01-17"
  },
  "2000-01-18": {
      "equity": 1606.7,
      "debt": 11.037,
      "gold": 4412.610452,
      "pb": 4.67,
      "pe": 26.15,
      "yield": 0.94,
      "date": "2000-01-18"
  },
  "2000-01-19": {
      "equity": 1634.8,
      "debt": 11.034,
      "gold": 4436.744916,
      "pb": 4.75,
      "pe": 26.6,
      "yield": 0.93,
      "date": "2000-01-19"
  },
  "2000-01-20": {
      "equity": 1601.1,
      "debt": 10.94,
      "gold": 4436.039436,
      "pb": 4.65,
      "pe": 26.05,
      "yield": 0.95,
      "date": "2000-01-20"
  },
  "2000-01-21": {
      "equity": 1620.6,
      "debt": 10.932,
      "gold": 4421.827545,
      "pb": 4.71,
      "pe": 26.37,
      "yield": 0.93,
      "date": "2000-01-21"
  },
  "2000-01-22": {
      "equity": 1620.6,
      "debt": 10.932,
      "gold": 4421.827545,
      "pb": 4.71,
      "pe": 26.37,
      "yield": 0.93,
      "date": "2000-01-22"
  },
  "2000-01-23": {
      "equity": 1620.6,
      "debt": 10.932,
      "gold": 4421.827545,
      "pb": 4.71,
      "pe": 26.37,
      "yield": 0.93,
      "date": "2000-01-23"
  },
  "2000-01-24": {
      "equity": 1613.6,
      "debt": 10.947,
      "gold": 4424.882273,
      "pb": 4.69,
      "pe": 26.26,
      "yield": 0.94,
      "date": "2000-01-24"
  },
  "2000-01-25": {
      "equity": 1586.4,
      "debt": 10.982,
      "gold": 4442.085398,
      "pb": 4.61,
      "pe": 25.81,
      "yield": 0.95,
      "date": "2000-01-25"
  },
  "2000-01-26": {
      "equity": 1586.4,
      "debt": 10.982,
      "gold": 4382.109032,
      "pb": 4.61,
      "pe": 25.81,
      "yield": 0.95,
      "date": "2000-01-26"
  },
  "2000-01-27": {
      "equity": 1603.9,
      "debt": 10.978,
      "gold": 4407.227641,
      "pb": 4.66,
      "pe": 26.1,
      "yield": 0.94,
      "date": "2000-01-27"
  },
  "2000-01-28": {
      "equity": 1599.1,
      "debt": 10.919,
      "gold": 4404.871338,
      "pb": 4.65,
      "pe": 26.02,
      "yield": 0.95,
      "date": "2000-01-28"
  },
  "2000-01-29": {
      "equity": 1599.1,
      "debt": 10.919,
      "gold": 4404.871338,
      "pb": 4.65,
      "pe": 26.02,
      "yield": 0.95,
      "date": "2000-01-29"
  },
  "2000-01-30": {
      "equity": 1599.1,
      "debt": 10.919,
      "gold": 4404.871338,
      "pb": 4.65,
      "pe": 26.02,
      "yield": 0.95,
      "date": "2000-01-30"
  },
  "2000-01-31": {
      "equity": 1546.2,
      "debt": 10.897,
      "gold": 4360.023986,
      "pb": 4.49,
      "pe": 25.16,
      "yield": 0.98,
      "date": "2000-01-31"
  },
  "2000-02-01": {
      "equity": 1549.5,
      "debt": 10.862,
      "gold": 4361.63248,
      "pb": 4.51,
      "pe": 25.13,
      "yield": 0.98,
      "date": "2000-02-01"
  },
  "2000-02-02": {
      "equity": 1588,
      "debt": 10.789,
      "gold": 4354.923367,
      "pb": 4.62,
      "pe": 25.75,
      "yield": 0.95,
      "date": "2000-02-02"
  },
  "2000-02-03": {
      "equity": 1597.9,
      "debt": 10.702,
      "gold": 4390.864036,
      "pb": 4.65,
      "pe": 25.91,
      "yield": 0.95,
      "date": "2000-02-03"
  },
  "2000-02-04": {
      "equity": 1599.7,
      "debt": 10.647,
      "gold": 4425.143301,
      "pb": 4.65,
      "pe": 25.94,
      "yield": 0.94,
      "date": "2000-02-04"
  },
  "2000-02-05": {
      "equity": 1599.7,
      "debt": 10.647,
      "gold": 4425.143301,
      "pb": 4.65,
      "pe": 25.94,
      "yield": 0.94,
      "date": "2000-02-05"
  },
  "2000-02-06": {
      "equity": 1599.7,
      "debt": 10.647,
      "gold": 4425.143301,
      "pb": 4.65,
      "pe": 25.94,
      "yield": 0.94,
      "date": "2000-02-06"
  },
  "2000-02-07": {
      "equity": 1636.6,
      "debt": 10.615,
      "gold": 4873.04538,
      "pb": 4.76,
      "pe": 26.54,
      "yield": 0.92,
      "date": "2000-02-07"
  },
  "2000-02-08": {
      "equity": 1662.7,
      "debt": 10.592,
      "gold": 4662.551368,
      "pb": 4.83,
      "pe": 26.96,
      "yield": 0.91,
      "date": "2000-02-08"
  },
  "2000-02-09": {
      "equity": 1689.6,
      "debt": 10.476,
      "gold": 4708.813207,
      "pb": 4.91,
      "pe": 27.4,
      "yield": 0.93,
      "date": "2000-02-09"
  },
  "2000-02-10": {
      "equity": 1711.2,
      "debt": 10.468,
      "gold": 4793.446093,
      "pb": 4.98,
      "pe": 27.75,
      "yield": 0.92,
      "date": "2000-02-10"
  },
  "2000-02-11": {
      "equity": 1756,
      "debt": 10.381,
      "gold": 4874.576271,
      "pb": 5.11,
      "pe": 28.47,
      "yield": 0.9,
      "date": "2000-02-11"
  },
  "2000-02-12": {
      "equity": 1756,
      "debt": 10.381,
      "gold": 4874.576271,
      "pb": 5.11,
      "pe": 28.47,
      "yield": 0.9,
      "date": "2000-02-12"
  },
  "2000-02-13": {
      "equity": 1756,
      "debt": 10.381,
      "gold": 4874.576271,
      "pb": 5.11,
      "pe": 28.47,
      "yield": 0.9,
      "date": "2000-02-13"
  },
  "2000-02-14": {
      "equity": 1744.5,
      "debt": 10.196,
      "gold": 4749.769837,
      "pb": 5.07,
      "pe": 28.29,
      "yield": 0.9,
      "date": "2000-02-14"
  },
  "2000-02-15": {
      "equity": 1702.5,
      "debt": 10.451,
      "gold": 4723.120337,
      "pb": 4.93,
      "pe": 27.19,
      "yield": 0.92,
      "date": "2000-02-15"
  },
  "2000-02-16": {
      "equity": 1711.1,
      "debt": 10.474,
      "gold": 4624.709431,
      "pb": 4.95,
      "pe": 27.32,
      "yield": 0.92,
      "date": "2000-02-16"
  },
  "2000-02-17": {
      "equity": 1742.1,
      "debt": 10.519,
      "gold": 4714.552285,
      "pb": 5.04,
      "pe": 27.82,
      "yield": 0.9,
      "date": "2000-02-17"
  },
  "2000-02-18": {
      "equity": 1717.8,
      "debt": 10.481,
      "gold": 4653.771671,
      "pb": 4.97,
      "pe": 27.43,
      "yield": 0.9,
      "date": "2000-02-18"
  },
  "2000-02-19": {
      "equity": 1717.8,
      "debt": 10.481,
      "gold": 4653.771671,
      "pb": 4.97,
      "pe": 27.43,
      "yield": 0.9,
      "date": "2000-02-19"
  },
  "2000-02-20": {
      "equity": 1717.8,
      "debt": 10.481,
      "gold": 4653.771671,
      "pb": 4.97,
      "pe": 27.43,
      "yield": 0.9,
      "date": "2000-02-20"
  },
  "2000-02-21": {
      "equity": 1753.5,
      "debt": 10.437,
      "gold": 4702.485053,
      "pb": 5.08,
      "pe": 28,
      "yield": 0.89,
      "date": "2000-02-21"
  },
  "2000-02-22": {
      "equity": 1739,
      "debt": 10.441,
      "gold": 4699.582003,
      "pb": 5.03,
      "pe": 27.77,
      "yield": 0.89,
      "date": "2000-02-22"
  },
  "2000-02-23": {
      "equity": 1696.4,
      "debt": 10.436,
      "gold": 4697.811249,
      "pb": 4.91,
      "pe": 27.09,
      "yield": 0.92,
      "date": "2000-02-23"
  },
  "2000-02-24": {
      "equity": 1732,
      "debt": 10.445,
      "gold": 4613.88737,
      "pb": 5.01,
      "pe": 27.66,
      "yield": 0.9,
      "date": "2000-02-24"
  },
  "2000-02-25": {
      "equity": 1710.4,
      "debt": 10.443,
      "gold": 4572.04889,
      "pb": 4.95,
      "pe": 27.29,
      "yield": 0.91,
      "date": "2000-02-25"
  },
  "2000-02-26": {
      "equity": 1710.4,
      "debt": 10.443,
      "gold": 4572.04889,
      "pb": 4.95,
      "pe": 27.29,
      "yield": 0.91,
      "date": "2000-02-26"
  },
  "2000-02-27": {
      "equity": 1710.4,
      "debt": 10.443,
      "gold": 4572.04889,
      "pb": 4.95,
      "pe": 27.29,
      "yield": 0.91,
      "date": "2000-02-27"
  },
  "2000-02-28": {
      "equity": 1722.5,
      "debt": 10.437,
      "gold": 4503.62793,
      "pb": 4.98,
      "pe": 27.49,
      "yield": 0.9,
      "date": "2000-02-28"
  },
  "2000-02-29": {
      "equity": 1654.8,
      "debt": 10.405,
      "gold": 4525.709448,
      "pb": 4.79,
      "pe": 26.36,
      "yield": 0.94,
      "date": "2000-02-29"
  },
  "2000-03-01": {
      "equity": 1712.7,
      "debt": 10.445,
      "gold": 4517.197834,
      "pb": 4.96,
      "pe": 27.35,
      "yield": 0.91,
      "date": "2000-03-01"
  },
  "2000-03-02": {
      "equity": 1696.5,
      "debt": 10.653,
      "gold": 4445.051941,
      "pb": 4.92,
      "pe": 27.09,
      "yield": 0.91,
      "date": "2000-03-02"
  },
  "2000-03-03": {
      "equity": 1656,
      "debt": 10.762,
      "gold": 4449.789238,
      "pb": 4.8,
      "pe": 26.47,
      "yield": 0.94,
      "date": "2000-03-03"
  },
  "2000-03-04": {
      "equity": 1656,
      "debt": 10.762,
      "gold": 4449.789238,
      "pb": 4.8,
      "pe": 26.47,
      "yield": 0.94,
      "date": "2000-03-04"
  },
  "2000-03-05": {
      "equity": 1656,
      "debt": 10.762,
      "gold": 4449.789238,
      "pb": 4.8,
      "pe": 26.47,
      "yield": 0.94,
      "date": "2000-03-05"
  },
  "2000-03-06": {
      "equity": 1688.5,
      "debt": 10.831,
      "gold": 4448.762765,
      "pb": 4.89,
      "pe": 26.99,
      "yield": 0.92,
      "date": "2000-03-06"
  },
  "2000-03-07": {
      "equity": 1702.7,
      "debt": 10.815,
      "gold": 4435.683169,
      "pb": 4.94,
      "pe": 27.22,
      "yield": 0.91,
      "date": "2000-03-07"
  },
  "2000-03-08": {
      "equity": 1666.3,
      "debt": 10.768,
      "gold": 4502.336902,
      "pb": 4.83,
      "pe": 26.64,
      "yield": 1.03,
      "date": "2000-03-08"
  },
  "2000-03-09": {
      "equity": 1646.2,
      "debt": 10.658,
      "gold": 4442.371118,
      "pb": 4.77,
      "pe": 26.32,
      "yield": 1.04,
      "date": "2000-03-09"
  },
  "2000-03-10": {
      "equity": 1602.7,
      "debt": 10.65,
      "gold": 4490.382546,
      "pb": 4.65,
      "pe": 25.62,
      "yield": 1.07,
      "date": "2000-03-10"
  },
  "2000-03-11": {
      "equity": 1602.7,
      "debt": 10.634,
      "gold": 4490.382546,
      "pb": 4.65,
      "pe": 25.62,
      "yield": 1.07,
      "date": "2000-03-11"
  },
  "2000-03-12": {
      "equity": 1602.7,
      "debt": 10.634,
      "gold": 4490.382546,
      "pb": 4.65,
      "pe": 25.62,
      "yield": 1.07,
      "date": "2000-03-12"
  },
  "2000-03-13": {
      "equity": 1560.7,
      "debt": 10.727,
      "gold": 4455.426022,
      "pb": 4.52,
      "pe": 24.95,
      "yield": 1.1,
      "date": "2000-03-13"
  },
  "2000-03-14": {
      "equity": 1567,
      "debt": 10.805,
      "gold": 4464.022293,
      "pb": 4.54,
      "pe": 25.05,
      "yield": 1.09,
      "date": "2000-03-14"
  },
  "2000-03-15": {
      "equity": 1620.1,
      "debt": 10.797,
      "gold": 4440.050089,
      "pb": 4.7,
      "pe": 25.9,
      "yield": 1.06,
      "date": "2000-03-15"
  },
  "2000-03-16": {
      "equity": 1562.2,
      "debt": 10.781,
      "gold": 4432.892996,
      "pb": 4.53,
      "pe": 24.97,
      "yield": 1.09,
      "date": "2000-03-16"
  },
  "2000-03-17": {
      "equity": 1562.2,
      "debt": 10.781,
      "gold": 4386.807527,
      "pb": 4.53,
      "pe": 24.97,
      "yield": 1.09,
      "date": "2000-03-17"
  },
  "2000-03-18": {
      "equity": 1562.2,
      "debt": 10.772,
      "gold": 4386.807527,
      "pb": 4.53,
      "pe": 24.97,
      "yield": 1.09,
      "date": "2000-03-18"
  },
  "2000-03-19": {
      "equity": 1562.2,
      "debt": 10.772,
      "gold": 4386.807527,
      "pb": 4.53,
      "pe": 24.97,
      "yield": 1.09,
      "date": "2000-03-19"
  },
  "2000-03-20": {
      "equity": 1562.2,
      "debt": 10.772,
      "gold": 4378.32766,
      "pb": 4.53,
      "pe": 24.97,
      "yield": 1.09,
      "date": "2000-03-20"
  },
  "2000-03-21": {
      "equity": 1556.6,
      "debt": 10.827,
      "gold": 4396.543149,
      "pb": 4.51,
      "pe": 24.89,
      "yield": 1.02,
      "date": "2000-03-21"
  },
  "2000-03-22": {
      "equity": 1589.6,
      "debt": 10.842,
      "gold": 4452.858075,
      "pb": 4.61,
      "pe": 25.41,
      "yield": 1.03,
      "date": "2000-03-22"
  },
  "2000-03-23": {
      "equity": 1553.4,
      "debt": 10.89,
      "gold": 4404.698496,
      "pb": 4.5,
      "pe": 24.83,
      "yield": 1.06,
      "date": "2000-03-23"
  },
  "2000-03-24": {
      "equity": 1569.5,
      "debt": 10.897,
      "gold": 4385.005027,
      "pb": 4.55,
      "pe": 25.09,
      "yield": 1.05,
      "date": "2000-03-24"
  },
  "2000-03-25": {
      "equity": 1569.5,
      "debt": 10.897,
      "gold": 4385.005027,
      "pb": 4.55,
      "pe": 25.09,
      "yield": 1.05,
      "date": "2000-03-25"
  },
  "2000-03-26": {
      "equity": 1569.5,
      "debt": 10.897,
      "gold": 4385.005027,
      "pb": 4.55,
      "pe": 25.09,
      "yield": 1.05,
      "date": "2000-03-26"
  },
  "2000-03-27": {
      "equity": 1562.9,
      "debt": 11.016,
      "gold": 4374.15122,
      "pb": 4.53,
      "pe": 24.99,
      "yield": 1.05,
      "date": "2000-03-27"
  },
  "2000-03-28": {
      "equity": 1568.6,
      "debt": 11.064,
      "gold": 4313.215401,
      "pb": 4.55,
      "pe": 25.08,
      "yield": 1.04,
      "date": "2000-03-28"
  },
  "2000-03-29": {
      "equity": 1558.2,
      "debt": 10.944,
      "gold": 4291.670047,
      "pb": 4.55,
      "pe": 25.08,
      "yield": 1.04,
      "date": "2000-03-29"
  },
  "2000-03-30": {
      "equity": 1549.5,
      "debt": 10.912,
      "gold": 4255.73996,
      "pb": 4.52,
      "pe": 24.94,
      "yield": 1.05,
      "date": "2000-03-30"
  },
  "2000-03-31": {
      "equity": 1528.4,
      "debt": 10.756,
      "gold": 4243.679783,
      "pb": 4.46,
      "pe": 24.6,
      "yield": 1.06,
      "date": "2000-03-31"
  },
  "2000-04-01": {
      "equity": 1528.4,
      "debt": 10.756,
      "gold": 4243.679783,
      "pb": 4.46,
      "pe": 24.6,
      "yield": 1.06,
      "date": "2000-04-01"
  },
  "2000-04-02": {
      "equity": 1528.4,
      "debt": 10.756,
      "gold": 4243.679783,
      "pb": 4.46,
      "pe": 24.6,
      "yield": 1.06,
      "date": "2000-04-02"
  },
  "2000-04-03": {
      "equity": 1534.7,
      "debt": 10.457,
      "gold": 4288.975114,
      "pb": 4.48,
      "pe": 24.7,
      "yield": 1.06,
      "date": "2000-04-03"
  },
  "2000-04-04": {
      "equity": 1428.1,
      "debt": 10.449,
      "gold": 4258.706503,
      "pb": 4.17,
      "pe": 22.99,
      "yield": 1.14,
      "date": "2000-04-04"
  },
  "2000-04-05": {
      "equity": 1434.6,
      "debt": 10.449,
      "gold": 4356.422512,
      "pb": 4.19,
      "pe": 23.09,
      "yield": 1.51,
      "date": "2000-04-05"
  },
  "2000-04-06": {
      "equity": 1452.9,
      "debt": 10.472,
      "gold": 4293.116281,
      "pb": 4.24,
      "pe": 23.39,
      "yield": 1.49,
      "date": "2000-04-06"
  },
  "2000-04-07": {
      "equity": 1557.1,
      "debt": 10.448,
      "gold": 4307.948994,
      "pb": 4.54,
      "pe": 25.06,
      "yield": 1.39,
      "date": "2000-04-07"
  },
  "2000-04-08": {
      "equity": 1557.1,
      "debt": 10.386,
      "gold": 4307.948994,
      "pb": 4.54,
      "pe": 25.06,
      "yield": 1.39,
      "date": "2000-04-08"
  },
  "2000-04-09": {
      "equity": 1557.1,
      "debt": 10.386,
      "gold": 4307.948994,
      "pb": 4.54,
      "pe": 25.06,
      "yield": 1.39,
      "date": "2000-04-09"
  },
  "2000-04-10": {
      "equity": 1613,
      "debt": 10.31,
      "gold": 4333.289829,
      "pb": 4.71,
      "pe": 25.96,
      "yield": 1.34,
      "date": "2000-04-10"
  },
  "2000-04-11": {
      "equity": 1624.6,
      "debt": 10.257,
      "gold": 4329.663662,
      "pb": 4.71,
      "pe": 25.72,
      "yield": 1.33,
      "date": "2000-04-11"
  },
  "2000-04-12": {
      "equity": 1592.7,
      "debt": 10.257,
      "gold": 4314.047867,
      "pb": 4.62,
      "pe": 25.21,
      "yield": 1.47,
      "date": "2000-04-12"
  },
  "2000-04-13": {
      "equity": 1518.6,
      "debt": 10.271,
      "gold": 4332.245719,
      "pb": 4.38,
      "pe": 23.98,
      "yield": 1.54,
      "date": "2000-04-13"
  },
  "2000-04-14": {
      "equity": 1518.6,
      "debt": 10.271,
      "gold": 4322.23496,
      "pb": 4.38,
      "pe": 23.98,
      "yield": 1.54,
      "date": "2000-04-14"
  },
  "2000-04-15": {
      "equity": 1518.6,
      "debt": 10.271,
      "gold": 4322.23496,
      "pb": 4.38,
      "pe": 23.98,
      "yield": 1.54,
      "date": "2000-04-15"
  },
  "2000-04-16": {
      "equity": 1518.6,
      "debt": 10.271,
      "gold": 4322.23496,
      "pb": 4.38,
      "pe": 23.98,
      "yield": 1.54,
      "date": "2000-04-16"
  },
  "2000-04-17": {
      "equity": 1443.5,
      "debt": 10.292,
      "gold": 4366.676661,
      "pb": 3.91,
      "pe": 21.74,
      "yield": 1.62,
      "date": "2000-04-17"
  },
  "2000-04-18": {
      "equity": 1414.8,
      "debt": 10.315,
      "gold": 4340.492778,
      "pb": 3.84,
      "pe": 21.31,
      "yield": 1.66,
      "date": "2000-04-18"
  },
  "2000-04-19": {
      "equity": 1404.9,
      "debt": 10.315,
      "gold": 4333.836576,
      "pb": 3.8,
      "pe": 21.07,
      "yield": 1.73,
      "date": "2000-04-19"
  },
  "2000-04-20": {
      "equity": 1415.6,
      "debt": 10.314,
      "gold": 4320.319582,
      "pb": 3.83,
      "pe": 21.23,
      "yield": 1.71,
      "date": "2000-04-20"
  },
  "2000-04-21": {
      "equity": 1415.6,
      "debt": 10.314,
      "gold": 4320.319582,
      "pb": 3.83,
      "pe": 21.23,
      "yield": 1.71,
      "date": "2000-04-21"
  },
  "2000-04-22": {
      "equity": 1415.6,
      "debt": 10.313,
      "gold": 4320.319582,
      "pb": 3.83,
      "pe": 21.23,
      "yield": 1.71,
      "date": "2000-04-22"
  },
  "2000-04-23": {
      "equity": 1415.6,
      "debt": 10.313,
      "gold": 4320.319582,
      "pb": 3.83,
      "pe": 21.23,
      "yield": 1.71,
      "date": "2000-04-23"
  },
  "2000-04-24": {
      "equity": 1388,
      "debt": 10.336,
      "gold": 4320.319582,
      "pb": 3.76,
      "pe": 20.82,
      "yield": 1.75,
      "date": "2000-04-24"
  },
  "2000-04-25": {
      "equity": 1359.4,
      "debt": 10.346,
      "gold": 4311.187146,
      "pb": 3.68,
      "pe": 20.39,
      "yield": 1.78,
      "date": "2000-04-25"
  },
  "2000-04-26": {
      "equity": 1436.1,
      "debt": 10.343,
      "gold": 4277.733293,
      "pb": 3.89,
      "pe": 21.54,
      "yield": 1.73,
      "date": "2000-04-26"
  },
  "2000-04-27": {
      "equity": 1416.9,
      "debt": 10.344,
      "gold": 4251.507081,
      "pb": 3.84,
      "pe": 21.25,
      "yield": 1.75,
      "date": "2000-04-27"
  },
  "2000-04-28": {
      "equity": 1406.5,
      "debt": 10.342,
      "gold": 4248.018484,
      "pb": 3.81,
      "pe": 21.1,
      "yield": 1.77,
      "date": "2000-04-28"
  },
  "2000-04-29": {
      "equity": 1406.5,
      "debt": 10.364,
      "gold": 4248.018484,
      "pb": 3.81,
      "pe": 21.1,
      "yield": 1.77,
      "date": "2000-04-29"
  },
  "2000-04-30": {
      "equity": 1406.5,
      "debt": 10.364,
      "gold": 4248.018484,
      "pb": 3.81,
      "pe": 21.1,
      "yield": 1.77,
      "date": "2000-04-30"
  },
  "2000-05-01": {
      "equity": 1406.5,
      "debt": 10.364,
      "gold": 4248.018484,
      "pb": 3.81,
      "pe": 21.1,
      "yield": 1.77,
      "date": "2000-05-01"
  },
  "2000-05-02": {
      "equity": 1333.4,
      "debt": 10.41,
      "gold": 4236.385121,
      "pb": 3.61,
      "pe": 20,
      "yield": 1.86,
      "date": "2000-05-02"
  },
  "2000-05-03": {
      "equity": 1316,
      "debt": 10.436,
      "gold": 4275.701511,
      "pb": 3.56,
      "pe": 19.74,
      "yield": 1.91,
      "date": "2000-05-03"
  },
  "2000-05-04": {
      "equity": 1380.5,
      "debt": 10.494,
      "gold": 4270.593838,
      "pb": 3.74,
      "pe": 20.71,
      "yield": 1.82,
      "date": "2000-05-04"
  },
  "2000-05-05": {
      "equity": 1422.4,
      "debt": 10.432,
      "gold": 4318.143177,
      "pb": 3.85,
      "pe": 21.33,
      "yield": 1.77,
      "date": "2000-05-05"
  },
  "2000-05-06": {
      "equity": 1422.4,
      "debt": 10.408,
      "gold": 4318.143177,
      "pb": 3.85,
      "pe": 21.33,
      "yield": 1.77,
      "date": "2000-05-06"
  },
  "2000-05-07": {
      "equity": 1422.4,
      "debt": 10.408,
      "gold": 4318.143177,
      "pb": 3.85,
      "pe": 21.33,
      "yield": 1.77,
      "date": "2000-05-07"
  },
  "2000-05-08": {
      "equity": 1365,
      "debt": 10.505,
      "gold": 4271.045345,
      "pb": 3.7,
      "pe": 20.47,
      "yield": 1.74,
      "date": "2000-05-08"
  },
  "2000-05-09": {
      "equity": 1378.5,
      "debt": 10.514,
      "gold": 4267.853049,
      "pb": 3.73,
      "pe": 20.68,
      "yield": 1.72,
      "date": "2000-05-09"
  },
  "2000-05-10": {
      "equity": 1363.1,
      "debt": 10.535,
      "gold": 4316.509991,
      "pb": 3.71,
      "pe": 20.57,
      "yield": 1.74,
      "date": "2000-05-10"
  },
  "2000-05-11": {
      "equity": 1304.5,
      "debt": 10.527,
      "gold": 4290.643574,
      "pb": 3.55,
      "pe": 19.68,
      "yield": 1.81,
      "date": "2000-05-11"
  },
  "2000-05-12": {
      "equity": 1282.8,
      "debt": 10.52,
      "gold": 4279.472301,
      "pb": 3.49,
      "pe": 19.36,
      "yield": 1.84,
      "date": "2000-05-12"
  },
  "2000-05-13": {
      "equity": 1282.8,
      "debt": 10.519,
      "gold": 4279.472301,
      "pb": 3.49,
      "pe": 19.36,
      "yield": 1.84,
      "date": "2000-05-13"
  },
  "2000-05-14": {
      "equity": 1282.8,
      "debt": 10.519,
      "gold": 4279.472301,
      "pb": 3.49,
      "pe": 19.36,
      "yield": 1.84,
      "date": "2000-05-14"
  },
  "2000-05-15": {
      "equity": 1299.2,
      "debt": 10.524,
      "gold": 4297.201009,
      "pb": 3.52,
      "pe": 19.54,
      "yield": 1.82,
      "date": "2000-05-15"
  },
  "2000-05-16": {
      "equity": 1306.8,
      "debt": 10.527,
      "gold": 4275.613327,
      "pb": 3.54,
      "pe": 19.66,
      "yield": 1.81,
      "date": "2000-05-16"
  },
  "2000-05-17": {
      "equity": 1311.6,
      "debt": 10.526,
      "gold": 4259.521332,
      "pb": 3.7,
      "pe": 19.88,
      "yield": 1.79,
      "date": "2000-05-17"
  },
  "2000-05-18": {
      "equity": 1293.4,
      "debt": 10.526,
      "gold": 4227.429055,
      "pb": 3.64,
      "pe": 19.44,
      "yield": 1.81,
      "date": "2000-05-18"
  },
  "2000-05-19": {
      "equity": 1268,
      "debt": 10.541,
      "gold": 4246.822695,
      "pb": 3.57,
      "pe": 19.05,
      "yield": 1.85,
      "date": "2000-05-19"
  },
  "2000-05-20": {
      "equity": 1268,
      "debt": 10.541,
      "gold": 4246.822695,
      "pb": 3.57,
      "pe": 19.05,
      "yield": 1.85,
      "date": "2000-05-20"
  },
  "2000-05-21": {
      "equity": 1268,
      "debt": 10.541,
      "gold": 4246.822695,
      "pb": 3.57,
      "pe": 19.05,
      "yield": 1.85,
      "date": "2000-05-21"
  },
  "2000-05-22": {
      "equity": 1233,
      "debt": 10.556,
      "gold": 4251.665814,
      "pb": 3.47,
      "pe": 18.53,
      "yield": 1.89,
      "date": "2000-05-22"
  },
  "2000-05-23": {
      "equity": 1224.4,
      "debt": 10.571,
      "gold": 4274.364627,
      "pb": 3.45,
      "pe": 18.4,
      "yield": 1.91,
      "date": "2000-05-23"
  },
  "2000-05-24": {
      "equity": 1235.9,
      "debt": 10.686,
      "gold": 4266.537329,
      "pb": 4.09,
      "pe": 20.69,
      "yield": 1.8,
      "date": "2000-05-24"
  },
  "2000-05-25": {
      "equity": 1247.6,
      "debt": 10.778,
      "gold": 4243.372899,
      "pb": 4.13,
      "pe": 20.89,
      "yield": 1.79,
      "date": "2000-05-25"
  },
  "2000-05-26": {
      "equity": 1275.3,
      "debt": 10.895,
      "gold": 4235.693751,
      "pb": 4.22,
      "pe": 21.35,
      "yield": 1.75,
      "date": "2000-05-26"
  },
  "2000-05-27": {
      "equity": 1275.3,
      "debt": 10.898,
      "gold": 4235.693751,
      "pb": 4.22,
      "pe": 21.35,
      "yield": 1.75,
      "date": "2000-05-27"
  },
  "2000-05-28": {
      "equity": 1275.3,
      "debt": 10.898,
      "gold": 4235.693751,
      "pb": 4.22,
      "pe": 21.35,
      "yield": 1.75,
      "date": "2000-05-28"
  },
  "2000-05-29": {
      "equity": 1311,
      "debt": 10.864,
      "gold": 4235.693751,
      "pb": 4.34,
      "pe": 21.95,
      "yield": 1.5,
      "date": "2000-05-29"
  },
  "2000-05-30": {
      "equity": 1344.8,
      "debt": 10.879,
      "gold": 4315.462354,
      "pb": 4.57,
      "pe": 22.34,
      "yield": 1.47,
      "date": "2000-05-30"
  },
  "2000-05-31": {
      "equity": 1380.4,
      "debt": 10.827,
      "gold": 4280.689254,
      "pb": 4.7,
      "pe": 22.96,
      "yield": 1.43,
      "date": "2000-05-31"
  },
  "2000-06-01": {
      "equity": 1349,
      "debt": 10.793,
      "gold": 4290.301416,
      "pb": 4.52,
      "pe": 22.46,
      "yield": 1.46,
      "date": "2000-06-01"
  },
  "2000-06-02": {
      "equity": 1389.2,
      "debt": 10.801,
      "gold": 4296.992892,
      "pb": 4.65,
      "pe": 23.13,
      "yield": 1.42,
      "date": "2000-06-02"
  },
  "2000-06-03": {
      "equity": 1389.2,
      "debt": 10.839,
      "gold": 4296.992892,
      "pb": 4.65,
      "pe": 23.13,
      "yield": 1.42,
      "date": "2000-06-03"
  },
  "2000-06-04": {
      "equity": 1389.2,
      "debt": 10.839,
      "gold": 4296.992892,
      "pb": 4.65,
      "pe": 23.13,
      "yield": 1.42,
      "date": "2000-06-04"
  },
  "2000-06-05": {
      "equity": 1404.6,
      "debt": 10.839,
      "gold": 4436.730807,
      "pb": 4.71,
      "pe": 23.38,
      "yield": 1.39,
      "date": "2000-06-05"
  },
  "2000-06-06": {
      "equity": 1421.7,
      "debt": 10.871,
      "gold": 4488.065045,
      "pb": 4.76,
      "pe": 23.67,
      "yield": 1.38,
      "date": "2000-06-06"
  },
  "2000-06-07": {
      "equity": 1430.3,
      "debt": 11.012,
      "gold": 4530.044622,
      "pb": 4.78,
      "pe": 23.7,
      "yield": 1.39,
      "date": "2000-06-07"
  },
  "2000-06-08": {
      "equity": 1463.6,
      "debt": 11.052,
      "gold": 4502.848375,
      "pb": 4.89,
      "pe": 24.25,
      "yield": 1.36,
      "date": "2000-06-08"
  },
  "2000-06-09": {
      "equity": 1467.2,
      "debt": 11.124,
      "gold": 4502.633203,
      "pb": 4.9,
      "pe": 24.31,
      "yield": 1.36,
      "date": "2000-06-09"
  },
  "2000-06-10": {
      "equity": 1467.2,
      "debt": 11.108,
      "gold": 4502.633203,
      "pb": 4.9,
      "pe": 24.31,
      "yield": 1.36,
      "date": "2000-06-10"
  },
  "2000-06-11": {
      "equity": 1467.2,
      "debt": 11.108,
      "gold": 4502.633203,
      "pb": 4.9,
      "pe": 24.31,
      "yield": 1.36,
      "date": "2000-06-11"
  },
  "2000-06-12": {
      "equity": 1440.4,
      "debt": 11.092,
      "gold": 4491.31378,
      "pb": 4.81,
      "pe": 23.87,
      "yield": 1.36,
      "date": "2000-06-12"
  },
  "2000-06-13": {
      "equity": 1432.9,
      "debt": 11.124,
      "gold": 4625.037479,
      "pb": 4.79,
      "pe": 23.74,
      "yield": 1.36,
      "date": "2000-06-13"
  },
  "2000-06-14": {
      "equity": 1435.5,
      "debt": 11.14,
      "gold": 4512.305332,
      "pb": 4.77,
      "pe": 23.79,
      "yield": 1.38,
      "date": "2000-06-14"
  },
  "2000-06-15": {
      "equity": 1445.2,
      "debt": 11.14,
      "gold": 4559.166828,
      "pb": 4.81,
      "pe": 23.95,
      "yield": 1.37,
      "date": "2000-06-15"
  },
  "2000-06-16": {
      "equity": 1477.3,
      "debt": 11.148,
      "gold": 4577.29766,
      "pb": 4.91,
      "pe": 24.48,
      "yield": 1.34,
      "date": "2000-06-16"
  },
  "2000-06-17": {
      "equity": 1477.3,
      "debt": 11.107,
      "gold": 4577.29766,
      "pb": 4.91,
      "pe": 24.48,
      "yield": 1.34,
      "date": "2000-06-17"
  },
  "2000-06-18": {
      "equity": 1477.3,
      "debt": 11.107,
      "gold": 4577.29766,
      "pb": 4.91,
      "pe": 24.48,
      "yield": 1.34,
      "date": "2000-06-18"
  },
  "2000-06-19": {
      "equity": 1498.7,
      "debt": 11.107,
      "gold": 4538.037708,
      "pb": 4.98,
      "pe": 24.84,
      "yield": 1.27,
      "date": "2000-06-19"
  },
  "2000-06-20": {
      "equity": 1507.1,
      "debt": 11.091,
      "gold": 4509.74444,
      "pb": 5.01,
      "pe": 24.98,
      "yield": 1.26,
      "date": "2000-06-20"
  },
  "2000-06-21": {
      "equity": 1475.1,
      "debt": 11.075,
      "gold": 4515.420025,
      "pb": 4.91,
      "pe": 24.45,
      "yield": 1.29,
      "date": "2000-06-21"
  },
  "2000-06-22": {
      "equity": 1488.2,
      "debt": 11.056,
      "gold": 4507.038925,
      "pb": 4.95,
      "pe": 24.67,
      "yield": 1.28,
      "date": "2000-06-22"
  },
  "2000-06-23": {
      "equity": 1472.2,
      "debt": 11.064,
      "gold": 4496.830632,
      "pb": 4.89,
      "pe": 22.95,
      "yield": 1.29,
      "date": "2000-06-23"
  },
  "2000-06-24": {
      "equity": 1472.2,
      "debt": 11.064,
      "gold": 4496.830632,
      "pb": 4.89,
      "pe": 22.95,
      "yield": 1.29,
      "date": "2000-06-24"
  },
  "2000-06-25": {
      "equity": 1472.2,
      "debt": 11.064,
      "gold": 4496.830632,
      "pb": 4.89,
      "pe": 22.95,
      "yield": 1.29,
      "date": "2000-06-25"
  },
  "2000-06-26": {
      "equity": 1451.6,
      "debt": 11.067,
      "gold": 4458.202085,
      "pb": 4.82,
      "pe": 22.63,
      "yield": 1.23,
      "date": "2000-06-26"
  },
  "2000-06-27": {
      "equity": 1454.3,
      "debt": 11.075,
      "gold": 4477.105416,
      "pb": 4.83,
      "pe": 22.67,
      "yield": 1.23,
      "date": "2000-06-27"
  },
  "2000-06-28": {
      "equity": 1470,
      "debt": 11.067,
      "gold": 4510.975502,
      "pb": 4.88,
      "pe": 22.91,
      "yield": 1.27,
      "date": "2000-06-28"
  },
  "2000-06-29": {
      "equity": 1492.3,
      "debt": 11.059,
      "gold": 4582.736909,
      "pb": 4.96,
      "pe": 23.26,
      "yield": 1.25,
      "date": "2000-06-29"
  },
  "2000-06-30": {
      "equity": 1471.4,
      "debt": 11.059,
      "gold": 4557.635937,
      "pb": 4.89,
      "pe": 22.94,
      "yield": 1.26,
      "date": "2000-06-30"
  },
  "2000-07-01": {
      "equity": 1471.4,
      "debt": 11.046,
      "gold": 4557.635937,
      "pb": 4.89,
      "pe": 22.94,
      "yield": 1.26,
      "date": "2000-07-01"
  },
  "2000-07-02": {
      "equity": 1471.4,
      "debt": 11.046,
      "gold": 4557.635937,
      "pb": 4.89,
      "pe": 22.94,
      "yield": 1.26,
      "date": "2000-07-02"
  },
  "2000-07-03": {
      "equity": 1495.2,
      "debt": 11.043,
      "gold": 4538.771407,
      "pb": 4.97,
      "pe": 23.31,
      "yield": 1.09,
      "date": "2000-07-03"
  },
  "2000-07-04": {
      "equity": 1511.3,
      "debt": 10.971,
      "gold": 4534.326884,
      "pb": 5.02,
      "pe": 23.56,
      "yield": 1.08,
      "date": "2000-07-04"
  },
  "2000-07-05": {
      "equity": 1526,
      "debt": 10.962,
      "gold": 4535.032364,
      "pb": 4.86,
      "pe": 23.5,
      "yield": 1.11,
      "date": "2000-07-05"
  },
  "2000-07-06": {
      "equity": 1516.8,
      "debt": 10.978,
      "gold": 4505.575054,
      "pb": 4.83,
      "pe": 23.35,
      "yield": 1.11,
      "date": "2000-07-06"
  },
  "2000-07-07": {
      "equity": 1517.6,
      "debt": 10.978,
      "gold": 4482.833912,
      "pb": 4.83,
      "pe": 23.37,
      "yield": 1.11,
      "date": "2000-07-07"
  },
  "2000-07-08": {
      "equity": 1517.6,
      "debt": 10.977,
      "gold": 4482.833912,
      "pb": 4.83,
      "pe": 23.37,
      "yield": 1.11,
      "date": "2000-07-08"
  },
  "2000-07-09": {
      "equity": 1517.6,
      "debt": 10.977,
      "gold": 4482.833912,
      "pb": 4.83,
      "pe": 23.37,
      "yield": 1.11,
      "date": "2000-07-09"
  },
  "2000-07-10": {
      "equity": 1509.6,
      "debt": 10.961,
      "gold": 4478.396444,
      "pb": 4.81,
      "pe": 23.24,
      "yield": 1.08,
      "date": "2000-07-10"
  },
  "2000-07-11": {
      "equity": 1518.5,
      "debt": 10.96,
      "gold": 4469.253426,
      "pb": 4.84,
      "pe": 23.38,
      "yield": 1.08,
      "date": "2000-07-11"
  },
  "2000-07-12": {
      "equity": 1533.3,
      "debt": 10.968,
      "gold": 4459.080407,
      "pb": 4.88,
      "pe": 23.61,
      "yield": 1.07,
      "date": "2000-07-12"
  },
  "2000-07-13": {
      "equity": 1522.6,
      "debt": 10.968,
      "gold": 4425.637136,
      "pb": 4.85,
      "pe": 23.44,
      "yield": 1.08,
      "date": "2000-07-13"
  },
  "2000-07-14": {
      "equity": 1509.7,
      "debt": 10.944,
      "gold": 4425.143301,
      "pb": 4.81,
      "pe": 23.25,
      "yield": 1.09,
      "date": "2000-07-14"
  },
  "2000-07-15": {
      "equity": 1509.7,
      "debt": 10.943,
      "gold": 4425.143301,
      "pb": 4.81,
      "pe": 23.25,
      "yield": 1.09,
      "date": "2000-07-15"
  },
  "2000-07-16": {
      "equity": 1509.7,
      "debt": 10.943,
      "gold": 4425.143301,
      "pb": 4.81,
      "pe": 23.25,
      "yield": 1.09,
      "date": "2000-07-16"
  },
  "2000-07-17": {
      "equity": 1479.6,
      "debt": 10.927,
      "gold": 4454.230233,
      "pb": 4.71,
      "pe": 22.78,
      "yield": 1.06,
      "date": "2000-07-17"
  },
  "2000-07-18": {
      "equity": 1463.1,
      "debt": 10.887,
      "gold": 4461.045168,
      "pb": 4.66,
      "pe": 22.53,
      "yield": 1.07,
      "date": "2000-07-18"
  },
  "2000-07-19": {
      "equity": 1435.8,
      "debt": 10.902,
      "gold": 4450.731053,
      "pb": 4.57,
      "pe": 22.11,
      "yield": 1.09,
      "date": "2000-07-19"
  },
  "2000-07-20": {
      "equity": 1424.2,
      "debt": 10.918,
      "gold": 4403.590892,
      "pb": 4.54,
      "pe": 21.93,
      "yield": 1.1,
      "date": "2000-07-20"
  },
  "2000-07-21": {
      "equity": 1397.2,
      "debt": 11.477,
      "gold": 4451.066156,
      "pb": 4.45,
      "pe": 21.51,
      "yield": 1.12,
      "date": "2000-07-21"
  },
  "2000-07-22": {
      "equity": 1397.2,
      "debt": 11.377,
      "gold": 4451.066156,
      "pb": 4.45,
      "pe": 21.51,
      "yield": 1.12,
      "date": "2000-07-22"
  },
  "2000-07-23": {
      "equity": 1397.2,
      "debt": 11.377,
      "gold": 4451.066156,
      "pb": 4.45,
      "pe": 21.51,
      "yield": 1.12,
      "date": "2000-07-23"
  },
  "2000-07-24": {
      "equity": 1317.7,
      "debt": 11.501,
      "gold": 4420.127339,
      "pb": 4.25,
      "pe": 20.47,
      "yield": 1.17,
      "date": "2000-07-24"
  },
  "2000-07-25": {
      "equity": 1348.9,
      "debt": 11.41,
      "gold": 4415.093741,
      "pb": 4.35,
      "pe": 20.96,
      "yield": 1.14,
      "date": "2000-07-25"
  },
  "2000-07-26": {
      "equity": 1318.2,
      "debt": 11.303,
      "gold": 4432.57553,
      "pb": 4.25,
      "pe": 20.48,
      "yield": 1.19,
      "date": "2000-07-26"
  },
  "2000-07-27": {
      "equity": 1338,
      "debt": 11.294,
      "gold": 4433.365668,
      "pb": 4.32,
      "pe": 20.79,
      "yield": 1.18,
      "date": "2000-07-27"
  },
  "2000-07-28": {
      "equity": 1333.8,
      "debt": 11.31,
      "gold": 4407.887264,
      "pb": 4.3,
      "pe": 20.72,
      "yield": 1.18,
      "date": "2000-07-28"
  },
  "2000-07-29": {
      "equity": 1333.8,
      "debt": 11.304,
      "gold": 4407.887264,
      "pb": 4.3,
      "pe": 20.72,
      "yield": 1.18,
      "date": "2000-07-29"
  },
  "2000-07-30": {
      "equity": 1333.8,
      "debt": 11.304,
      "gold": 4407.887264,
      "pb": 4.3,
      "pe": 20.72,
      "yield": 1.18,
      "date": "2000-07-30"
  },
  "2000-07-31": {
      "equity": 1332.8,
      "debt": 11.354,
      "gold": 4407.167675,
      "pb": 4.3,
      "pe": 20.71,
      "yield": 1.18,
      "date": "2000-07-31"
  },
  "2000-08-01": {
      "equity": 1326.8,
      "debt": 11.359,
      "gold": 4416.825694,
      "pb": 4.29,
      "pe": 20.58,
      "yield": 1.19,
      "date": "2000-08-01"
  },
  "2000-08-02": {
      "equity": 1331.9,
      "debt": 11.367,
      "gold": 4434.476799,
      "pb": 4.31,
      "pe": 20.66,
      "yield": 1.18,
      "date": "2000-08-02"
  },
  "2000-08-03": {
      "equity": 1321.2,
      "debt": 11.375,
      "gold": 4425.989876,
      "pb": 4.27,
      "pe": 20.49,
      "yield": 1.19,
      "date": "2000-08-03"
  },
  "2000-08-04": {
      "equity": 1318.5,
      "debt": 11.441,
      "gold": 4379.625743,
      "pb": 4.27,
      "pe": 20.45,
      "yield": 1.19,
      "date": "2000-08-04"
  },
  "2000-08-05": {
      "equity": 1318.5,
      "debt": 11.465,
      "gold": 4379.625743,
      "pb": 4.27,
      "pe": 20.45,
      "yield": 1.19,
      "date": "2000-08-05"
  },
  "2000-08-06": {
      "equity": 1318.5,
      "debt": 11.465,
      "gold": 4379.625743,
      "pb": 4.27,
      "pe": 20.45,
      "yield": 1.19,
      "date": "2000-08-06"
  },
  "2000-08-07": {
      "equity": 1310.8,
      "debt": 11.498,
      "gold": 4396.33856,
      "pb": 4.24,
      "pe": 20.33,
      "yield": 1.2,
      "date": "2000-08-07"
  },
  "2000-08-08": {
      "equity": 1345.6,
      "debt": 11.506,
      "gold": 4420.564737,
      "pb": 4.35,
      "pe": 20.87,
      "yield": 1.17,
      "date": "2000-08-08"
  },
  "2000-08-09": {
      "equity": 1344.9,
      "debt": 11.49,
      "gold": 4381.851532,
      "pb": 4.35,
      "pe": 20.86,
      "yield": 1.18,
      "date": "2000-08-09"
  },
  "2000-08-10": {
      "equity": 1328,
      "debt": 11.548,
      "gold": 4406.366955,
      "pb": 4.22,
      "pe": 20.09,
      "yield": 1.2,
      "date": "2000-08-10"
  },
  "2000-08-11": {
      "equity": 1310.7,
      "debt": 11.548,
      "gold": 4412.871479,
      "pb": 4.15,
      "pe": 19.74,
      "yield": 1.21,
      "date": "2000-08-11"
  },
  "2000-08-12": {
      "equity": 1310.7,
      "debt": 11.572,
      "gold": 4412.871479,
      "pb": 4.15,
      "pe": 19.74,
      "yield": 1.21,
      "date": "2000-08-12"
  },
  "2000-08-13": {
      "equity": 1310.7,
      "debt": 11.572,
      "gold": 4412.871479,
      "pb": 4.15,
      "pe": 19.74,
      "yield": 1.21,
      "date": "2000-08-13"
  },
  "2000-08-14": {
      "equity": 1317.9,
      "debt": 11.406,
      "gold": 4444.19831,
      "pb": 4.18,
      "pe": 19.85,
      "yield": 1.18,
      "date": "2000-08-14"
  },
  "2000-08-15": {
      "equity": 1317.9,
      "debt": 11.406,
      "gold": 4430.646043,
      "pb": 4.18,
      "pe": 19.85,
      "yield": 1.18,
      "date": "2000-08-15"
  },
  "2000-08-16": {
      "equity": 1351.4,
      "debt": 11.438,
      "gold": 4443.549269,
      "pb": 4.28,
      "pe": 20.35,
      "yield": 1.16,
      "date": "2000-08-16"
  },
  "2000-08-17": {
      "equity": 1341.4,
      "debt": 11.48,
      "gold": 4489.22556,
      "pb": 4.25,
      "pe": 20.2,
      "yield": 1.17,
      "date": "2000-08-17"
  },
  "2000-08-18": {
      "equity": 1358,
      "debt": 11.496,
      "gold": 4479.204219,
      "pb": 4.3,
      "pe": 20.45,
      "yield": 1.16,
      "date": "2000-08-18"
  },
  "2000-08-19": {
      "equity": 1358,
      "debt": 11.454,
      "gold": 4479.204219,
      "pb": 4.3,
      "pe": 20.45,
      "yield": 1.16,
      "date": "2000-08-19"
  },
  "2000-08-20": {
      "equity": 1358,
      "debt": 11.454,
      "gold": 4479.204219,
      "pb": 4.3,
      "pe": 20.45,
      "yield": 1.16,
      "date": "2000-08-20"
  },
  "2000-08-21": {
      "equity": 1370.2,
      "debt": 11.454,
      "gold": 4467.084076,
      "pb": 4.34,
      "pe": 20.62,
      "yield": 1.15,
      "date": "2000-08-21"
  },
  "2000-08-22": {
      "equity": 1381.2,
      "debt": 11.504,
      "gold": 4417.905078,
      "pb": 4.37,
      "pe": 20.78,
      "yield": 1.14,
      "date": "2000-08-22"
  },
  "2000-08-23": {
      "equity": 1386,
      "debt": 11.52,
      "gold": 4400.119932,
      "pb": 4.39,
      "pe": 20.87,
      "yield": 1.13,
      "date": "2000-08-23"
  },
  "2000-08-24": {
      "equity": 1386.9,
      "debt": 11.503,
      "gold": 4404.36692,
      "pb": 4.4,
      "pe": 20.89,
      "yield": 1.13,
      "date": "2000-08-24"
  },
  "2000-08-25": {
      "equity": 1381.2,
      "debt": 11.478,
      "gold": 4430.564913,
      "pb": 4.38,
      "pe": 20.8,
      "yield": 1.14,
      "date": "2000-08-25"
  },
  "2000-08-26": {
      "equity": 1381.2,
      "debt": 11.478,
      "gold": 4430.564913,
      "pb": 4.38,
      "pe": 20.8,
      "yield": 1.14,
      "date": "2000-08-26"
  },
  "2000-08-27": {
      "equity": 1381.2,
      "debt": 11.478,
      "gold": 4430.564913,
      "pb": 4.38,
      "pe": 20.8,
      "yield": 1.14,
      "date": "2000-08-27"
  },
  "2000-08-28": {
      "equity": 1369.8,
      "debt": 11.469,
      "gold": 4430.564913,
      "pb": 4.34,
      "pe": 20.63,
      "yield": 1.06,
      "date": "2000-08-28"
  },
  "2000-08-29": {
      "equity": 1367.7,
      "debt": 11.461,
      "gold": 4426.603644,
      "pb": 4.33,
      "pe": 20.58,
      "yield": 1.06,
      "date": "2000-08-29"
  },
  "2000-08-30": {
      "equity": 1375.9,
      "debt": 11.494,
      "gold": 4403.985961,
      "pb": 4.36,
      "pe": 20.7,
      "yield": 1.08,
      "date": "2000-08-30"
  },
  "2000-08-31": {
      "equity": 1394.1,
      "debt": 11.505,
      "gold": 4442.434611,
      "pb": 4.42,
      "pe": 20.97,
      "yield": 1.06,
      "date": "2000-08-31"
  },
  "2000-09-01": {
      "equity": 1394.1,
      "debt": 11.505,
      "gold": 4465.19339,
      "pb": 4.42,
      "pe": 20.97,
      "yield": 1.06,
      "date": "2000-09-01"
  },
  "2000-09-02": {
      "equity": 1394.1,
      "debt": 11.493,
      "gold": 4465.19339,
      "pb": 4.42,
      "pe": 20.97,
      "yield": 1.06,
      "date": "2000-09-02"
  },
  "2000-09-03": {
      "equity": 1394.1,
      "debt": 11.493,
      "gold": 4465.19339,
      "pb": 4.42,
      "pe": 20.97,
      "yield": 1.06,
      "date": "2000-09-03"
  },
  "2000-09-04": {
      "equity": 1427.7,
      "debt": 11.468,
      "gold": 4466.76661,
      "pb": 4.66,
      "pe": 21.69,
      "yield": 1.04,
      "date": "2000-09-04"
  },
  "2000-09-05": {
      "equity": 1428.2,
      "debt": 11.501,
      "gold": 4454.840473,
      "pb": 4.66,
      "pe": 21.7,
      "yield": 1.04,
      "date": "2000-09-05"
  },
  "2000-09-06": {
      "equity": 1435.3,
      "debt": 11.521,
      "gold": 4431.340941,
      "pb": 4.69,
      "pe": 21.81,
      "yield": 1.12,
      "date": "2000-09-06"
  },
  "2000-09-07": {
      "equity": 1439.7,
      "debt": 11.493,
      "gold": 4390.38431,
      "pb": 4.7,
      "pe": 21.88,
      "yield": 1.11,
      "date": "2000-09-07"
  },
  "2000-09-08": {
      "equity": 1450,
      "debt": 11.493,
      "gold": 4414.441172,
      "pb": 4.73,
      "pe": 22.03,
      "yield": 1.11,
      "date": "2000-09-08"
  },
  "2000-09-09": {
      "equity": 1450,
      "debt": 11.497,
      "gold": 4414.441172,
      "pb": 4.73,
      "pe": 22.03,
      "yield": 1.11,
      "date": "2000-09-09"
  },
  "2000-09-10": {
      "equity": 1450,
      "debt": 11.497,
      "gold": 4414.441172,
      "pb": 4.73,
      "pe": 22.03,
      "yield": 1.11,
      "date": "2000-09-10"
  },
  "2000-09-11": {
      "equity": 1456.3,
      "debt": 11.484,
      "gold": 4386.804,
      "pb": 4.75,
      "pe": 22.13,
      "yield": 1.1,
      "date": "2000-09-11"
  },
  "2000-09-12": {
      "equity": 1467.6,
      "debt": 11.464,
      "gold": 4390.856982,
      "pb": 4.79,
      "pe": 22.3,
      "yield": 1.09,
      "date": "2000-09-12"
  },
  "2000-09-13": {
      "equity": 1456.1,
      "debt": 11.484,
      "gold": 4391.946948,
      "pb": 4.75,
      "pe": 22.13,
      "yield": 1.1,
      "date": "2000-09-13"
  },
  "2000-09-14": {
      "equity": 1445.3,
      "debt": 11.5,
      "gold": 4393.745921,
      "pb": 4.72,
      "pe": 21.96,
      "yield": 1.11,
      "date": "2000-09-14"
  },
  "2000-09-15": {
      "equity": 1417.2,
      "debt": 11.567,
      "gold": 4408.024833,
      "pb": 4.63,
      "pe": 21.53,
      "yield": 1.13,
      "date": "2000-09-15"
  },
  "2000-09-16": {
      "equity": 1417.2,
      "debt": 11.592,
      "gold": 4408.024833,
      "pb": 4.63,
      "pe": 21.53,
      "yield": 1.13,
      "date": "2000-09-16"
  },
  "2000-09-17": {
      "equity": 1417.2,
      "debt": 11.592,
      "gold": 4408.024833,
      "pb": 4.63,
      "pe": 21.53,
      "yield": 1.13,
      "date": "2000-09-17"
  },
  "2000-09-18": {
      "equity": 1354.3,
      "debt": 11.643,
      "gold": 4420.783435,
      "pb": 4.42,
      "pe": 20.58,
      "yield": 1.18,
      "date": "2000-09-18"
  },
  "2000-09-19": {
      "equity": 1317,
      "debt": 11.677,
      "gold": 4449.46119,
      "pb": 4.3,
      "pe": 20.01,
      "yield": 1.22,
      "date": "2000-09-19"
  },
  "2000-09-20": {
      "equity": 1342.9,
      "debt": 11.643,
      "gold": 4426.610699,
      "pb": 4.38,
      "pe": 20.4,
      "yield": 1.2,
      "date": "2000-09-20"
  },
  "2000-09-21": {
      "equity": 1329.8,
      "debt": 11.55,
      "gold": 4365.83714,
      "pb": 4.34,
      "pe": 20.21,
      "yield": 1.21,
      "date": "2000-09-21"
  },
  "2000-09-22": {
      "equity": 1266.4,
      "debt": 11.507,
      "gold": 4438.374575,
      "pb": 4.13,
      "pe": 19.24,
      "yield": 1.27,
      "date": "2000-09-22"
  },
  "2000-09-23": {
      "equity": 1266.4,
      "debt": 11.507,
      "gold": 4438.374575,
      "pb": 4.13,
      "pe": 19.24,
      "yield": 1.27,
      "date": "2000-09-23"
  },
  "2000-09-24": {
      "equity": 1266.4,
      "debt": 11.507,
      "gold": 4438.374575,
      "pb": 4.13,
      "pe": 19.24,
      "yield": 1.27,
      "date": "2000-09-24"
  },
  "2000-09-25": {
      "equity": 1292.5,
      "debt": 11.507,
      "gold": 4440.078308,
      "pb": 4.22,
      "pe": 19.64,
      "yield": 1.24,
      "date": "2000-09-25"
  },
  "2000-09-26": {
      "equity": 1267.9,
      "debt": 11.507,
      "gold": 4455.443659,
      "pb": 4.14,
      "pe": 19.26,
      "yield": 1.26,
      "date": "2000-09-26"
  },
  "2000-09-27": {
      "equity": 1292.5,
      "debt": 11.549,
      "gold": 4465.373287,
      "pb": 4.22,
      "pe": 19.64,
      "yield": 1.24,
      "date": "2000-09-27"
  },
  "2000-09-28": {
      "equity": 1266.4,
      "debt": 11.54,
      "gold": 4504.700259,
      "pb": 4.13,
      "pe": 19.24,
      "yield": 1.26,
      "date": "2000-09-28"
  },
  "2000-09-29": {
      "equity": 1271.6,
      "debt": 11.54,
      "gold": 4450.93917,
      "pb": 4.15,
      "pe": 19.32,
      "yield": 1.26,
      "date": "2000-09-29"
  },
  "2000-09-30": {
      "equity": 1271.6,
      "debt": 11.54,
      "gold": 4450.93917,
      "pb": 4.15,
      "pe": 19.32,
      "yield": 1.26,
      "date": "2000-09-30"
  },
  "2000-10-01": {
      "equity": 1271.6,
      "debt": 11.54,
      "gold": 4450.93917,
      "pb": 4.15,
      "pe": 19.32,
      "yield": 1.26,
      "date": "2000-10-01"
  },
  "2000-10-02": {
      "equity": 1271.6,
      "debt": 11.54,
      "gold": 4453.221397,
      "pb": 4.15,
      "pe": 19.32,
      "yield": 1.26,
      "date": "2000-10-02"
  },
  "2000-10-03": {
      "equity": 1282,
      "debt": 11.548,
      "gold": 4422.374292,
      "pb": 4.19,
      "pe": 19.48,
      "yield": 1.25,
      "date": "2000-10-03"
  },
  "2000-10-04": {
      "equity": 1297.8,
      "debt": 11.565,
      "gold": 4407.386374,
      "pb": 4.24,
      "pe": 19.72,
      "yield": 1.23,
      "date": "2000-10-04"
  },
  "2000-10-05": {
      "equity": 1284.7,
      "debt": 11.641,
      "gold": 4410.352916,
      "pb": 4.19,
      "pe": 19.52,
      "yield": 1.25,
      "date": "2000-10-05"
  },
  "2000-10-06": {
      "equity": 1285,
      "debt": 11.599,
      "gold": 4403.086474,
      "pb": 4.2,
      "pe": 19.52,
      "yield": 1.25,
      "date": "2000-10-06"
  },
  "2000-10-07": {
      "equity": 1285,
      "debt": 11.599,
      "gold": 4403.086474,
      "pb": 4.2,
      "pe": 19.52,
      "yield": 1.25,
      "date": "2000-10-07"
  },
  "2000-10-08": {
      "equity": 1285,
      "debt": 11.599,
      "gold": 4403.086474,
      "pb": 4.2,
      "pe": 19.52,
      "yield": 1.25,
      "date": "2000-10-08"
  },
  "2000-10-09": {
      "equity": 1267.3,
      "debt": 11.59,
      "gold": 4393.428456,
      "pb": 4.14,
      "pe": 19.26,
      "yield": 1.26,
      "date": "2000-10-09"
  },
  "2000-10-10": {
      "equity": 1238.9,
      "debt": 11.607,
      "gold": 4396.479656,
      "pb": 4.04,
      "pe": 18.83,
      "yield": 1.29,
      "date": "2000-10-10"
  },
  "2000-10-11": {
      "equity": 1201.9,
      "debt": 11.675,
      "gold": 4460.043387,
      "pb": 3.92,
      "pe": 18.26,
      "yield": 1.33,
      "date": "2000-10-11"
  },
  "2000-10-12": {
      "equity": 1206.2,
      "debt": 11.649,
      "gold": 4417.174906,
      "pb": 3.94,
      "pe": 18.33,
      "yield": 1.33,
      "date": "2000-10-12"
  },
  "2000-10-13": {
      "equity": 1176.7,
      "debt": 11.717,
      "gold": 4511.469338,
      "pb": 3.83,
      "pe": 18.01,
      "yield": 1.36,
      "date": "2000-10-13"
  },
  "2000-10-14": {
      "equity": 1176.7,
      "debt": 11.7,
      "gold": 4511.469338,
      "pb": 3.83,
      "pe": 18.01,
      "yield": 1.36,
      "date": "2000-10-14"
  },
  "2000-10-15": {
      "equity": 1176.7,
      "debt": 11.7,
      "gold": 4511.469338,
      "pb": 3.83,
      "pe": 18.01,
      "yield": 1.36,
      "date": "2000-10-15"
  },
  "2000-10-16": {
      "equity": 1175.4,
      "debt": 11.681,
      "gold": 4446.441736,
      "pb": 3.83,
      "pe": 17.78,
      "yield": 1.36,
      "date": "2000-10-16"
  },
  "2000-10-17": {
      "equity": 1158,
      "debt": 11.662,
      "gold": 4418.585866,
      "pb": 3.77,
      "pe": 17.51,
      "yield": 1.38,
      "date": "2000-10-17"
  },
  "2000-10-18": {
      "equity": 1136,
      "debt": 11.666,
      "gold": 4433.559675,
      "pb": 3.7,
      "pe": 17.18,
      "yield": 1.41,
      "date": "2000-10-18"
  },
  "2000-10-19": {
      "equity": 1166.1,
      "debt": 11.664,
      "gold": 4421.344292,
      "pb": 3.8,
      "pe": 17.64,
      "yield": 1.37,
      "date": "2000-10-19"
  },
  "2000-10-20": {
      "equity": 1172,
      "debt": 11.652,
      "gold": 4439.365774,
      "pb": 3.82,
      "pe": 17.72,
      "yield": 1.37,
      "date": "2000-10-20"
  },
  "2000-10-21": {
      "equity": 1172,
      "debt": 11.648,
      "gold": 4439.365774,
      "pb": 3.82,
      "pe": 17.72,
      "yield": 1.37,
      "date": "2000-10-21"
  },
  "2000-10-22": {
      "equity": 1172,
      "debt": 11.648,
      "gold": 4439.365774,
      "pb": 3.82,
      "pe": 17.72,
      "yield": 1.37,
      "date": "2000-10-22"
  },
  "2000-10-23": {
      "equity": 1143.9,
      "debt": 11.647,
      "gold": 4438.628547,
      "pb": 3.73,
      "pe": 17.3,
      "yield": 1.4,
      "date": "2000-10-23"
  },
  "2000-10-24": {
      "equity": 1152.4,
      "debt": 11.631,
      "gold": 4427.104534,
      "pb": 3.75,
      "pe": 17.43,
      "yield": 1.39,
      "date": "2000-10-24"
  },
  "2000-10-25": {
      "equity": 1183.9,
      "debt": 11.611,
      "gold": 4426.903473,
      "pb": 3.86,
      "pe": 17.9,
      "yield": 1.36,
      "date": "2000-10-25"
  },
  "2000-10-26": {
      "equity": 1186.3,
      "debt": 11.611,
      "gold": 4388.514789,
      "pb": 3.86,
      "pe": 17.94,
      "yield": 1.36,
      "date": "2000-10-26"
  },
  "2000-10-27": {
      "equity": 1178.7,
      "debt": 11.623,
      "gold": 4387.322528,
      "pb": 3.84,
      "pe": 17.83,
      "yield": 1.36,
      "date": "2000-10-27"
  },
  "2000-10-28": {
      "equity": 1178.7,
      "debt": 11.623,
      "gold": 4387.322528,
      "pb": 3.84,
      "pe": 17.83,
      "yield": 1.36,
      "date": "2000-10-28"
  },
  "2000-10-29": {
      "equity": 1178.7,
      "debt": 11.623,
      "gold": 4387.322528,
      "pb": 3.84,
      "pe": 17.83,
      "yield": 1.36,
      "date": "2000-10-29"
  },
  "2000-10-30": {
      "equity": 1167.1,
      "debt": 11.604,
      "gold": 4373.696185,
      "pb": 3.8,
      "pe": 17.65,
      "yield": 1.37,
      "date": "2000-10-30"
  },
  "2000-10-31": {
      "equity": 1172.7,
      "debt": 11.6,
      "gold": 4340.912538,
      "pb": 3.82,
      "pe": 17.74,
      "yield": 1.37,
      "date": "2000-10-31"
  },
  "2000-11-01": {
      "equity": 1200.8,
      "debt": 11.588,
      "gold": 4391.894037,
      "pb": 3.91,
      "pe": 18.16,
      "yield": 1.34,
      "date": "2000-11-01"
  },
  "2000-11-02": {
      "equity": 1224.8,
      "debt": 11.537,
      "gold": 4380.031394,
      "pb": 3.99,
      "pe": 18.52,
      "yield": 1.31,
      "date": "2000-11-02"
  },
  "2000-11-03": {
      "equity": 1242,
      "debt": 11.428,
      "gold": 4381.699854,
      "pb": 4.04,
      "pe": 18.78,
      "yield": 1.29,
      "date": "2000-11-03"
  },
  "2000-11-04": {
      "equity": 1242,
      "debt": 11.419,
      "gold": 4381.699854,
      "pb": 4.04,
      "pe": 18.78,
      "yield": 1.29,
      "date": "2000-11-04"
  },
  "2000-11-05": {
      "equity": 1242,
      "debt": 11.419,
      "gold": 4381.699854,
      "pb": 4.04,
      "pe": 18.78,
      "yield": 1.29,
      "date": "2000-11-05"
  },
  "2000-11-06": {
      "equity": 1240.2,
      "debt": 11.37,
      "gold": 4368.313374,
      "pb": 4.04,
      "pe": 18.76,
      "yield": 1.27,
      "date": "2000-11-06"
  },
  "2000-11-07": {
      "equity": 1246.7,
      "debt": 11.282,
      "gold": 4347.247747,
      "pb": 4.06,
      "pe": 18.85,
      "yield": 1.27,
      "date": "2000-11-07"
  },
  "2000-11-08": {
      "equity": 1266.8,
      "debt": 11.26,
      "gold": 4362.52844,
      "pb": 4.13,
      "pe": 19.17,
      "yield": 1.25,
      "date": "2000-11-08"
  },
  "2000-11-09": {
      "equity": 1269.9,
      "debt": 11.269,
      "gold": 4356.133265,
      "pb": 4.14,
      "pe": 19.21,
      "yield": 1.25,
      "date": "2000-11-09"
  },
  "2000-11-10": {
      "equity": 1239.5,
      "debt": 11.262,
      "gold": 4370.609711,
      "pb": 4.04,
      "pe": 18.75,
      "yield": 1.28,
      "date": "2000-11-10"
  },
  "2000-11-11": {
      "equity": 1239.5,
      "debt": 11.262,
      "gold": 4370.609711,
      "pb": 4.04,
      "pe": 18.75,
      "yield": 1.28,
      "date": "2000-11-11"
  },
  "2000-11-12": {
      "equity": 1239.5,
      "debt": 11.262,
      "gold": 4370.609711,
      "pb": 4.04,
      "pe": 18.75,
      "yield": 1.28,
      "date": "2000-11-12"
  },
  "2000-11-13": {
      "equity": 1208,
      "debt": 11.262,
      "gold": 4361.258576,
      "pb": 3.94,
      "pe": 18.28,
      "yield": 1.31,
      "date": "2000-11-13"
  },
  "2000-11-14": {
      "equity": 1242.8,
      "debt": 11.263,
      "gold": 4363.628988,
      "pb": 4.05,
      "pe": 18.8,
      "yield": 1.27,
      "date": "2000-11-14"
  },
  "2000-11-15": {
      "equity": 1247,
      "debt": 11.251,
      "gold": 4374.130055,
      "pb": 4.07,
      "pe": 18.87,
      "yield": 1.27,
      "date": "2000-11-15"
  },
  "2000-11-16": {
      "equity": 1233.2,
      "debt": 11.233,
      "gold": 4385.029718,
      "pb": 4.02,
      "pe": 18.66,
      "yield": 1.29,
      "date": "2000-11-16"
  },
  "2000-11-17": {
      "equity": 1236,
      "debt": 11.271,
      "gold": 4396.952327,
      "pb": 4.03,
      "pe": 18.7,
      "yield": 1.28,
      "date": "2000-11-17"
  },
  "2000-11-18": {
      "equity": 1236,
      "debt": 11.27,
      "gold": 4396.952327,
      "pb": 4.03,
      "pe": 18.7,
      "yield": 1.28,
      "date": "2000-11-18"
  },
  "2000-11-19": {
      "equity": 1236,
      "debt": 11.27,
      "gold": 4396.952327,
      "pb": 4.03,
      "pe": 18.7,
      "yield": 1.28,
      "date": "2000-11-19"
  },
  "2000-11-20": {
      "equity": 1237.6,
      "debt": 11.29,
      "gold": 4384.719307,
      "pb": 4.03,
      "pe": 18.72,
      "yield": 1.28,
      "date": "2000-11-20"
  },
  "2000-11-21": {
      "equity": 1235,
      "debt": 11.297,
      "gold": 4410.882026,
      "pb": 4.03,
      "pe": 18.68,
      "yield": 1.28,
      "date": "2000-11-21"
  },
  "2000-11-22": {
      "equity": 1222.3,
      "debt": 11.311,
      "gold": 4400.497363,
      "pb": 3.98,
      "pe": 18.49,
      "yield": 1.3,
      "date": "2000-11-22"
  },
  "2000-11-23": {
      "equity": 1216,
      "debt": 11.335,
      "gold": 4391.742359,
      "pb": 3.96,
      "pe": 18.4,
      "yield": 1.31,
      "date": "2000-11-23"
  },
  "2000-11-24": {
      "equity": 1225.2,
      "debt": 11.354,
      "gold": 4394.003422,
      "pb": 3.99,
      "pe": 18.54,
      "yield": 1.3,
      "date": "2000-11-24"
  },
  "2000-11-25": {
      "equity": 1225.2,
      "debt": 11.363,
      "gold": 4394.003422,
      "pb": 3.99,
      "pe": 18.54,
      "yield": 1.3,
      "date": "2000-11-25"
  },
  "2000-11-26": {
      "equity": 1225.2,
      "debt": 11.363,
      "gold": 4394.003422,
      "pb": 3.99,
      "pe": 18.54,
      "yield": 1.3,
      "date": "2000-11-26"
  },
  "2000-11-27": {
      "equity": 1252.9,
      "debt": 11.389,
      "gold": 4398.246883,
      "pb": 4.08,
      "pe": 18.96,
      "yield": 1.27,
      "date": "2000-11-27"
  },
  "2000-11-28": {
      "equity": 1272.7,
      "debt": 11.375,
      "gold": 4459.517805,
      "pb": 4.15,
      "pe": 19.26,
      "yield": 1.25,
      "date": "2000-11-28"
  },
  "2000-11-29": {
      "equity": 1264.7,
      "debt": 11.363,
      "gold": 4447.355333,
      "pb": 4.13,
      "pe": 19.17,
      "yield": 1.25,
      "date": "2000-11-29"
  },
  "2000-11-30": {
      "equity": 1268.1,
      "debt": 11.348,
      "gold": 4413.82035,
      "pb": 4.14,
      "pe": 19.22,
      "yield": 1.25,
      "date": "2000-11-30"
  },
  "2000-12-01": {
      "equity": 1276.2,
      "debt": 11.329,
      "gold": 4482.770419,
      "pb": 4.17,
      "pe": 19.34,
      "yield": 1.24,
      "date": "2000-12-01"
  },
  "2000-12-02": {
      "equity": 1276.2,
      "debt": 11.332,
      "gold": 4482.770419,
      "pb": 4.17,
      "pe": 19.34,
      "yield": 1.24,
      "date": "2000-12-02"
  },
  "2000-12-03": {
      "equity": 1276.2,
      "debt": 11.332,
      "gold": 4482.770419,
      "pb": 4.17,
      "pe": 19.34,
      "yield": 1.24,
      "date": "2000-12-03"
  },
  "2000-12-04": {
      "equity": 1275.6,
      "debt": 11.334,
      "gold": 4478.452883,
      "pb": 4.17,
      "pe": 19.34,
      "yield": 1.24,
      "date": "2000-12-04"
  },
  "2000-12-05": {
      "equity": 1284.6,
      "debt": 11.324,
      "gold": 4477.694492,
      "pb": 4.2,
      "pe": 19.47,
      "yield": 1.23,
      "date": "2000-12-05"
  },
  "2000-12-06": {
      "equity": 1298.5,
      "debt": 11.27,
      "gold": 4466.600822,
      "pb": 4.24,
      "pe": 19.68,
      "yield": 1.22,
      "date": "2000-12-06"
  },
  "2000-12-07": {
      "equity": 1302.5,
      "debt": 11.215,
      "gold": 4538.016544,
      "pb": 4.25,
      "pe": 19.74,
      "yield": 1.22,
      "date": "2000-12-07"
  },
  "2000-12-08": {
      "equity": 1313.7,
      "debt": 11.183,
      "gold": 4508.322898,
      "pb": 4.29,
      "pe": 19.91,
      "yield": 1.21,
      "date": "2000-12-08"
  },
  "2000-12-09": {
      "equity": 1313.7,
      "debt": 11.094,
      "gold": 4508.322898,
      "pb": 4.29,
      "pe": 19.91,
      "yield": 1.21,
      "date": "2000-12-09"
  },
  "2000-12-10": {
      "equity": 1313.7,
      "debt": 11.094,
      "gold": 4508.322898,
      "pb": 4.29,
      "pe": 19.91,
      "yield": 1.21,
      "date": "2000-12-10"
  },
  "2000-12-11": {
      "equity": 1332.1,
      "debt": 11.098,
      "gold": 4495.126898,
      "pb": 4.35,
      "pe": 20.19,
      "yield": 1.19,
      "date": "2000-12-11"
  },
  "2000-12-12": {
      "equity": 1333.3,
      "debt": 11.098,
      "gold": 4469.429796,
      "pb": 4.36,
      "pe": 20.21,
      "yield": 1.19,
      "date": "2000-12-12"
  },
  "2000-12-13": {
      "equity": 1354.3,
      "debt": 11.103,
      "gold": 4453.062664,
      "pb": 4.43,
      "pe": 20.54,
      "yield": 1.17,
      "date": "2000-12-13"
  },
  "2000-12-14": {
      "equity": 1349.3,
      "debt": 11.096,
      "gold": 4447.986737,
      "pb": 4.41,
      "pe": 20.47,
      "yield": 1.18,
      "date": "2000-12-14"
  },
  "2000-12-15": {
      "equity": 1312.6,
      "debt": 11.077,
      "gold": 4457.884619,
      "pb": 4.29,
      "pe": 19.91,
      "yield": 1.21,
      "date": "2000-12-15"
  },
  "2000-12-16": {
      "equity": 1312.6,
      "debt": 11.062,
      "gold": 4457.884619,
      "pb": 4.29,
      "pe": 19.91,
      "yield": 1.21,
      "date": "2000-12-16"
  },
  "2000-12-17": {
      "equity": 1312.6,
      "debt": 11.062,
      "gold": 4457.884619,
      "pb": 4.29,
      "pe": 19.91,
      "yield": 1.21,
      "date": "2000-12-17"
  },
  "2000-12-18": {
      "equity": 1317.6,
      "debt": 10.971,
      "gold": 4462.614861,
      "pb": 4.31,
      "pe": 19.98,
      "yield": 1.19,
      "date": "2000-12-18"
  },
  "2000-12-19": {
      "equity": 1310.5,
      "debt": 10.932,
      "gold": 4437.210533,
      "pb": 4.28,
      "pe": 19.88,
      "yield": 1.2,
      "date": "2000-12-19"
  },
  "2000-12-20": {
      "equity": 1295.2,
      "debt": 10.954,
      "gold": 4451.944479,
      "pb": 4.24,
      "pe": 19.68,
      "yield": 1.21,
      "date": "2000-12-20"
  },
  "2000-12-21": {
      "equity": 1277.4,
      "debt": 10.998,
      "gold": 4496.393234,
      "pb": 4.18,
      "pe": 19.41,
      "yield": 1.23,
      "date": "2000-12-21"
  },
  "2000-12-22": {
      "equity": 1242,
      "debt": 10.971,
      "gold": 4509.384645,
      "pb": 4.07,
      "pe": 18.87,
      "yield": 1.26,
      "date": "2000-12-22"
  },
  "2000-12-23": {
      "equity": 1242,
      "debt": 10.966,
      "gold": 4509.384645,
      "pb": 4.07,
      "pe": 18.87,
      "yield": 1.26,
      "date": "2000-12-23"
  },
  "2000-12-24": {
      "equity": 1242,
      "debt": 10.966,
      "gold": 4509.384645,
      "pb": 4.07,
      "pe": 18.87,
      "yield": 1.26,
      "date": "2000-12-24"
  },
  "2000-12-25": {
      "equity": 1242,
      "debt": 10.966,
      "gold": 4509.384645,
      "pb": 4.07,
      "pe": 18.87,
      "yield": 1.26,
      "date": "2000-12-25"
  },
  "2000-12-26": {
      "equity": 1212,
      "debt": 10.98,
      "gold": 4509.384645,
      "pb": 3.97,
      "pe": 18.42,
      "yield": 1.29,
      "date": "2000-12-26"
  },
  "2000-12-27": {
      "equity": 1228.3,
      "debt": 10.92,
      "gold": 4528.026949,
      "pb": 4.02,
      "pe": 18.67,
      "yield": 1.27,
      "date": "2000-12-27"
  },
  "2000-12-28": {
      "equity": 1248.9,
      "debt": 10.92,
      "gold": 4522.989823,
      "pb": 4.09,
      "pe": 18.98,
      "yield": 1.25,
      "date": "2000-12-28"
  },
  "2000-12-29": {
      "equity": 1263.5,
      "debt": 10.894,
      "gold": 4489.426621,
      "pb": 4.14,
      "pe": 19.2,
      "yield": 1.24,
      "date": "2000-12-29"
  },
  "2000-12-30": {
      "equity": 1263.5,
      "debt": 10.884,
      "gold": 4489.426621,
      "pb": 4.14,
      "pe": 19.2,
      "yield": 1.24,
      "date": "2000-12-30"
  },
  "2000-12-31": {
      "equity": 1263.5,
      "debt": 10.884,
      "gold": 4489.426621,
      "pb": 4.14,
      "pe": 19.2,
      "yield": 1.24,
      "date": "2000-12-31"
  },
  "2001-01-01": {
      "equity": 1254.3,
      "debt": 10.803,
      "gold": 4489.426621,
      "pb": 4.11,
      "pe": 19.06,
      "yield": 1.25,
      "date": "2001-01-01"
  },
  "2001-01-02": {
      "equity": 1271.8,
      "debt": 10.818,
      "gold": 4493.342034,
      "pb": 4.16,
      "pe": 19.33,
      "yield": 1.23,
      "date": "2001-01-02"
  },
  "2001-01-03": {
      "equity": 1291.2,
      "debt": 10.795,
      "gold": 4432.173407,
      "pb": 4.23,
      "pe": 19.62,
      "yield": 1.22,
      "date": "2001-01-03"
  },
  "2001-01-04": {
      "equity": 1307.6,
      "debt": 10.687,
      "gold": 4429.474947,
      "pb": 4.28,
      "pe": 19.87,
      "yield": 1.2,
      "date": "2001-01-04"
  },
  "2001-01-05": {
      "equity": 1327.2,
      "debt": 10.674,
      "gold": 4416.169597,
      "pb": 4.35,
      "pe": 20.17,
      "yield": 1.18,
      "date": "2001-01-05"
  },
  "2001-01-06": {
      "equity": 1327.2,
      "debt": 10.682,
      "gold": 4416.169597,
      "pb": 4.35,
      "pe": 20.17,
      "yield": 1.18,
      "date": "2001-01-06"
  },
  "2001-01-07": {
      "equity": 1327.2,
      "debt": 10.682,
      "gold": 4416.169597,
      "pb": 4.35,
      "pe": 20.17,
      "yield": 1.18,
      "date": "2001-01-07"
  },
  "2001-01-08": {
      "equity": 1309.2,
      "debt": 10.662,
      "gold": 4422.864601,
      "pb": 4.29,
      "pe": 19.9,
      "yield": 1.2,
      "date": "2001-01-08"
  },
  "2001-01-09": {
      "equity": 1311.6,
      "debt": 10.68,
      "gold": 4403.548563,
      "pb": 4.3,
      "pe": 19.93,
      "yield": 1.2,
      "date": "2001-01-09"
  },
  "2001-01-10": {
      "equity": 1287.3,
      "debt": 10.695,
      "gold": 4385.809274,
      "pb": 4.22,
      "pe": 19.56,
      "yield": 1.22,
      "date": "2001-01-10"
  },
  "2001-01-11": {
      "equity": 1280.4,
      "debt": 10.699,
      "gold": 4355.032717,
      "pb": 4.19,
      "pe": 19.46,
      "yield": 1.23,
      "date": "2001-01-11"
  },
  "2001-01-12": {
      "equity": 1286.7,
      "debt": 10.652,
      "gold": 4329.145135,
      "pb": 4.21,
      "pe": 19.55,
      "yield": 1.22,
      "date": "2001-01-12"
  },
  "2001-01-13": {
      "equity": 1286.7,
      "debt": 10.633,
      "gold": 4329.145135,
      "pb": 4.21,
      "pe": 19.55,
      "yield": 1.22,
      "date": "2001-01-13"
  },
  "2001-01-14": {
      "equity": 1286.7,
      "debt": 10.633,
      "gold": 4329.145135,
      "pb": 4.21,
      "pe": 19.55,
      "yield": 1.22,
      "date": "2001-01-14"
  },
  "2001-01-15": {
      "equity": 1286.7,
      "debt": 10.616,
      "gold": 4324.217358,
      "pb": 4.33,
      "pe": 20.85,
      "yield": 1.22,
      "date": "2001-01-15"
  },
  "2001-01-16": {
      "equity": 1293,
      "debt": 10.629,
      "gold": 4337.970687,
      "pb": 4.35,
      "pe": 20.95,
      "yield": 1.21,
      "date": "2001-01-16"
  },
  "2001-01-17": {
      "equity": 1297.9,
      "debt": 10.614,
      "gold": 4309.903173,
      "pb": 4.36,
      "pe": 21.03,
      "yield": 1.21,
      "date": "2001-01-17"
  },
  "2001-01-18": {
      "equity": 1305.9,
      "debt": 10.565,
      "gold": 4323.025097,
      "pb": 4.39,
      "pe": 21.16,
      "yield": 1.2,
      "date": "2001-01-18"
  },
  "2001-01-19": {
      "equity": 1329.1,
      "debt": 10.531,
      "gold": 4347.223055,
      "pb": 4.47,
      "pe": 21.53,
      "yield": 1.18,
      "date": "2001-01-19"
  },
  "2001-01-20": {
      "equity": 1329.1,
      "debt": 10.482,
      "gold": 4347.223055,
      "pb": 4.47,
      "pe": 21.53,
      "yield": 1.18,
      "date": "2001-01-20"
  },
  "2001-01-21": {
      "equity": 1329.1,
      "debt": 10.482,
      "gold": 4347.223055,
      "pb": 4.47,
      "pe": 21.53,
      "yield": 1.18,
      "date": "2001-01-21"
  },
  "2001-01-22": {
      "equity": 1348,
      "debt": 10.457,
      "gold": 4352.009736,
      "pb": 4.53,
      "pe": 21.84,
      "yield": 1.17,
      "date": "2001-01-22"
  },
  "2001-01-23": {
      "equity": 1355.2,
      "debt": 10.425,
      "gold": 4366.479127,
      "pb": 4.56,
      "pe": 21.96,
      "yield": 1.16,
      "date": "2001-01-23"
  },
  "2001-01-24": {
      "equity": 1365.9,
      "debt": 10.421,
      "gold": 4345.46641,
      "pb": 4.59,
      "pe": 22.13,
      "yield": 1.15,
      "date": "2001-01-24"
  },
  "2001-01-25": {
      "equity": 1370.1,
      "debt": 10.473,
      "gold": 4322.742906,
      "pb": 4.61,
      "pe": 22.2,
      "yield": 1.15,
      "date": "2001-01-25"
  },
  "2001-01-26": {
      "equity": 1370.1,
      "debt": 10.473,
      "gold": 4336.57031,
      "pb": 4.61,
      "pe": 22.2,
      "yield": 1.15,
      "date": "2001-01-26"
  },
  "2001-01-27": {
      "equity": 1370.1,
      "debt": 10.441,
      "gold": 4336.57031,
      "pb": 4.61,
      "pe": 22.2,
      "yield": 1.15,
      "date": "2001-01-27"
  },
  "2001-01-28": {
      "equity": 1370.1,
      "debt": 10.441,
      "gold": 4336.57031,
      "pb": 4.61,
      "pe": 22.2,
      "yield": 1.15,
      "date": "2001-01-28"
  },
  "2001-01-29": {
      "equity": 1342,
      "debt": 10.53,
      "gold": 4315.007319,
      "pb": 4.51,
      "pe": 21.74,
      "yield": 1.17,
      "date": "2001-01-29"
  },
  "2001-01-30": {
      "equity": 1379.7,
      "debt": 10.481,
      "gold": 4313.423517,
      "pb": 4.64,
      "pe": 22.35,
      "yield": 1.14,
      "date": "2001-01-30"
  },
  "2001-01-31": {
      "equity": 1371.7,
      "debt": 10.424,
      "gold": 4356.930457,
      "pb": 4.61,
      "pe": 22.22,
      "yield": 1.15,
      "date": "2001-01-31"
  },
  "2001-02-01": {
      "equity": 1359.1,
      "debt": 10.464,
      "gold": 4367.569093,
      "pb": 4.57,
      "pe": 22.02,
      "yield": 1.16,
      "date": "2001-02-01"
  },
  "2001-02-02": {
      "equity": 1378.8,
      "debt": 10.464,
      "gold": 4387.336637,
      "pb": 4.64,
      "pe": 22.34,
      "yield": 1.14,
      "date": "2001-02-02"
  },
  "2001-02-03": {
      "equity": 1378.8,
      "debt": 10.457,
      "gold": 4387.336637,
      "pb": 4.64,
      "pe": 22.34,
      "yield": 1.14,
      "date": "2001-02-03"
  },
  "2001-02-04": {
      "equity": 1378.8,
      "debt": 10.457,
      "gold": 4387.336637,
      "pb": 4.64,
      "pe": 22.34,
      "yield": 1.14,
      "date": "2001-02-04"
  },
  "2001-02-05": {
      "equity": 1382.6,
      "debt": 10.569,
      "gold": 4358.076862,
      "pb": 4.65,
      "pe": 22.4,
      "yield": 1.14,
      "date": "2001-02-05"
  },
  "2001-02-06": {
      "equity": 1387.1,
      "debt": 10.511,
      "gold": 4328.058696,
      "pb": 4.66,
      "pe": 22.47,
      "yield": 1.13,
      "date": "2001-02-06"
  },
  "2001-02-07": {
      "equity": 1370.8,
      "debt": 10.434,
      "gold": 4305.236424,
      "pb": 4.61,
      "pe": 22.22,
      "yield": 1.15,
      "date": "2001-02-07"
  },
  "2001-02-08": {
      "equity": 1395.5,
      "debt": 10.336,
      "gold": 4312.030195,
      "pb": 4.69,
      "pe": 22.62,
      "yield": 1.13,
      "date": "2001-02-08"
  },
  "2001-02-09": {
      "equity": 1405.7,
      "debt": 10.298,
      "gold": 4269.380412,
      "pb": 4.73,
      "pe": 22.78,
      "yield": 1.12,
      "date": "2001-02-09"
  },
  "2001-02-10": {
      "equity": 1405.7,
      "debt": 10.312,
      "gold": 4269.380412,
      "pb": 4.73,
      "pe": 22.78,
      "yield": 1.12,
      "date": "2001-02-10"
  },
  "2001-02-11": {
      "equity": 1405.7,
      "debt": 10.312,
      "gold": 4269.380412,
      "pb": 4.73,
      "pe": 22.78,
      "yield": 1.12,
      "date": "2001-02-11"
  },
  "2001-02-12": {
      "equity": 1402.2,
      "debt": 10.312,
      "gold": 4277.436992,
      "pb": 4.71,
      "pe": 22.73,
      "yield": 1.08,
      "date": "2001-02-12"
  },
  "2001-02-13": {
      "equity": 1391.2,
      "debt": 10.321,
      "gold": 4294.301487,
      "pb": 4.68,
      "pe": 22.55,
      "yield": 1.09,
      "date": "2001-02-13"
  },
  "2001-02-14": {
      "equity": 1393.3,
      "debt": 10.349,
      "gold": 4291.811143,
      "pb": 4.54,
      "pe": 20.89,
      "yield": 1.09,
      "date": "2001-02-14"
  },
  "2001-02-15": {
      "equity": 1416.7,
      "debt": 10.501,
      "gold": 4261.457874,
      "pb": 4.61,
      "pe": 21.24,
      "yield": 1.07,
      "date": "2001-02-15"
  },
  "2001-02-16": {
      "equity": 1381.3,
      "debt": 10.326,
      "gold": 4213.665144,
      "pb": 4.5,
      "pe": 20.71,
      "yield": 1.09,
      "date": "2001-02-16"
  },
  "2001-02-17": {
      "equity": 1381.3,
      "debt": 10.272,
      "gold": 4213.665144,
      "pb": 4.5,
      "pe": 20.71,
      "yield": 1.09,
      "date": "2001-02-17"
  },
  "2001-02-18": {
      "equity": 1381.3,
      "debt": 10.272,
      "gold": 4213.665144,
      "pb": 4.5,
      "pe": 20.71,
      "yield": 1.09,
      "date": "2001-02-18"
  },
  "2001-02-19": {
      "equity": 1384.8,
      "debt": 10.272,
      "gold": 4251.147287,
      "pb": 4.51,
      "pe": 20.76,
      "yield": 1.09,
      "date": "2001-02-19"
  },
  "2001-02-20": {
      "equity": 1383.8,
      "debt": 10.268,
      "gold": 4244.028995,
      "pb": 4.51,
      "pe": 20.73,
      "yield": 1.09,
      "date": "2001-02-20"
  },
  "2001-02-21": {
      "equity": 1370.1,
      "debt": 10.268,
      "gold": 4230.776557,
      "pb": 4.46,
      "pe": 20.52,
      "yield": 1.1,
      "date": "2001-02-21"
  },
  "2001-02-22": {
      "equity": 1355.1,
      "debt": 10.282,
      "gold": 4238.646184,
      "pb": 4.41,
      "pe": 20.3,
      "yield": 1.12,
      "date": "2001-02-22"
  },
  "2001-02-23": {
      "equity": 1320.4,
      "debt": 10.303,
      "gold": 4273.433394,
      "pb": 4.3,
      "pe": 19.78,
      "yield": 1.15,
      "date": "2001-02-23"
  },
  "2001-02-24": {
      "equity": 1320.4,
      "debt": 10.316,
      "gold": 4273.433394,
      "pb": 4.3,
      "pe": 19.78,
      "yield": 1.15,
      "date": "2001-02-24"
  },
  "2001-02-25": {
      "equity": 1320.4,
      "debt": 10.316,
      "gold": 4273.433394,
      "pb": 4.3,
      "pe": 19.78,
      "yield": 1.15,
      "date": "2001-02-25"
  },
  "2001-02-26": {
      "equity": 1312.4,
      "debt": 10.311,
      "gold": 4323.067426,
      "pb": 4.28,
      "pe": 19.66,
      "yield": 1.15,
      "date": "2001-02-26"
  },
  "2001-02-27": {
      "equity": 1295.5,
      "debt": 10.26,
      "gold": 4411.195965,
      "pb": 4.22,
      "pe": 19.41,
      "yield": 1.17,
      "date": "2001-02-27"
  },
  "2001-02-28": {
      "equity": 1351.4,
      "debt": 10.078,
      "gold": 4351.311311,
      "pb": 4.4,
      "pe": 20.24,
      "yield": 1.12,
      "date": "2001-02-28"
  },
  "2001-03-01": {
      "equity": 1358,
      "debt": 9.994,
      "gold": 4378.754475,
      "pb": 4.42,
      "pe": 20.34,
      "yield": 1.11,
      "date": "2001-03-01"
  },
  "2001-03-02": {
      "equity": 1306.3,
      "debt": 10.06,
      "gold": 4324.217358,
      "pb": 4.26,
      "pe": 19.57,
      "yield": 1.16,
      "date": "2001-03-02"
  },
  "2001-03-03": {
      "equity": 1306.3,
      "debt": 10.126,
      "gold": 4324.217358,
      "pb": 4.26,
      "pe": 19.57,
      "yield": 1.16,
      "date": "2001-03-03"
  },
  "2001-03-04": {
      "equity": 1306.3,
      "debt": 10.126,
      "gold": 4324.217358,
      "pb": 4.26,
      "pe": 19.57,
      "yield": 1.16,
      "date": "2001-03-04"
  },
  "2001-03-05": {
      "equity": 1271.4,
      "debt": 10.309,
      "gold": 4298.20279,
      "pb": 4.14,
      "pe": 19.04,
      "yield": 1.19,
      "date": "2001-03-05"
  },
  "2001-03-06": {
      "equity": 1271.4,
      "debt": 10.309,
      "gold": 4308.661528,
      "pb": 4.14,
      "pe": 19.04,
      "yield": 1.19,
      "date": "2001-03-06"
  },
  "2001-03-07": {
      "equity": 1290.5,
      "debt": 10.133,
      "gold": 4292.816452,
      "pb": 4.21,
      "pe": 19.35,
      "yield": 1.17,
      "date": "2001-03-07"
  },
  "2001-03-08": {
      "equity": 1292.8,
      "debt": 10.155,
      "gold": 4325.998695,
      "pb": 4.21,
      "pe": 19.38,
      "yield": 1.17,
      "date": "2001-03-08"
  },
  "2001-03-09": {
      "equity": 1254.7,
      "debt": 10.192,
      "gold": 4411.795622,
      "pb": 4.09,
      "pe": 18.81,
      "yield": 1.08,
      "date": "2001-03-09"
  },
  "2001-03-10": {
      "equity": 1254.7,
      "debt": 10.192,
      "gold": 4411.795622,
      "pb": 4.09,
      "pe": 18.81,
      "yield": 1.08,
      "date": "2001-03-10"
  },
  "2001-03-11": {
      "equity": 1254.7,
      "debt": 10.192,
      "gold": 4411.795622,
      "pb": 4.09,
      "pe": 18.81,
      "yield": 1.08,
      "date": "2001-03-11"
  },
  "2001-03-12": {
      "equity": 1197.9,
      "debt": 10.199,
      "gold": 4443.051906,
      "pb": 3.91,
      "pe": 17.96,
      "yield": 1.13,
      "date": "2001-03-12"
  },
  "2001-03-13": {
      "equity": 1124.7,
      "debt": 10.349,
      "gold": 4432.586113,
      "pb": 3.67,
      "pe": 16.86,
      "yield": 1.2,
      "date": "2001-03-13"
  },
  "2001-03-14": {
      "equity": 1194.2,
      "debt": 10.417,
      "gold": 4401.446234,
      "pb": 3.89,
      "pe": 17.9,
      "yield": 1.13,
      "date": "2001-03-14"
  },
  "2001-03-15": {
      "equity": 1217.1,
      "debt": 10.423,
      "gold": 4321.885748,
      "pb": 3.97,
      "pe": 18.25,
      "yield": 1.11,
      "date": "2001-03-15"
  },
  "2001-03-16": {
      "equity": 1193.5,
      "debt": 10.44,
      "gold": 4286.721106,
      "pb": 3.89,
      "pe": 17.89,
      "yield": 1.14,
      "date": "2001-03-16"
  },
  "2001-03-17": {
      "equity": 1193.5,
      "debt": 10.424,
      "gold": 4286.721106,
      "pb": 3.89,
      "pe": 17.89,
      "yield": 1.14,
      "date": "2001-03-17"
  },
  "2001-03-18": {
      "equity": 1193.5,
      "debt": 10.424,
      "gold": 4286.721106,
      "pb": 3.89,
      "pe": 17.89,
      "yield": 1.14,
      "date": "2001-03-18"
  },
  "2001-03-19": {
      "equity": 1186.7,
      "debt": 10.439,
      "gold": 4290.745869,
      "pb": 3.87,
      "pe": 17.79,
      "yield": 1.14,
      "date": "2001-03-19"
  },
  "2001-03-20": {
      "equity": 1170.9,
      "debt": 10.416,
      "gold": 4325.564825,
      "pb": 3.82,
      "pe": 17.55,
      "yield": 1.16,
      "date": "2001-03-20"
  },
  "2001-03-21": {
      "equity": 1207.1,
      "debt": 10.416,
      "gold": 4301.814847,
      "pb": 3.94,
      "pe": 18.1,
      "yield": 1.12,
      "date": "2001-03-21"
  },
  "2001-03-22": {
      "equity": 1187.5,
      "debt": 10.439,
      "gold": 4320.033863,
      "pb": 3.87,
      "pe": 17.8,
      "yield": 1.14,
      "date": "2001-03-22"
  },
  "2001-03-23": {
      "equity": 1161.3,
      "debt": 10.386,
      "gold": 4320.393658,
      "pb": 3.79,
      "pe": 17.41,
      "yield": 1.12,
      "date": "2001-03-23"
  },
  "2001-03-24": {
      "equity": 1161.3,
      "debt": 10.408,
      "gold": 4320.393658,
      "pb": 3.79,
      "pe": 17.41,
      "yield": 1.12,
      "date": "2001-03-24"
  },
  "2001-03-25": {
      "equity": 1161.3,
      "debt": 10.408,
      "gold": 4320.393658,
      "pb": 3.79,
      "pe": 17.41,
      "yield": 1.12,
      "date": "2001-03-25"
  },
  "2001-03-26": {
      "equity": 1161.5,
      "debt": 10.408,
      "gold": 4299.77601,
      "pb": 3.79,
      "pe": 17.41,
      "yield": 1.12,
      "date": "2001-03-26"
  },
  "2001-03-27": {
      "equity": 1177.7,
      "debt": 10.408,
      "gold": 4321.550645,
      "pb": 3.84,
      "pe": 17.65,
      "yield": 1.1,
      "date": "2001-03-27"
  },
  "2001-03-28": {
      "equity": 1206.2,
      "debt": 10.506,
      "gold": 4294.904672,
      "pb": 3.94,
      "pe": 18.07,
      "yield": 1.08,
      "date": "2001-03-28"
  },
  "2001-03-29": {
      "equity": 1195.1,
      "debt": 10.491,
      "gold": 4279.60987,
      "pb": 3.9,
      "pe": 17.91,
      "yield": 1.09,
      "date": "2001-03-29"
  },
  "2001-03-30": {
      "equity": 1148.2,
      "debt": 10.37,
      "gold": 4259.553079,
      "pb": 3.75,
      "pe": 17.21,
      "yield": 1.13,
      "date": "2001-03-30"
  },
  "2001-03-31": {
      "equity": 1148.2,
      "debt": 10.333,
      "gold": 4259.553079,
      "pb": 3.75,
      "pe": 17.21,
      "yield": 1.13,
      "date": "2001-03-31"
  },
  "2001-04-01": {
      "equity": 1148.2,
      "debt": 10.333,
      "gold": 4259.553079,
      "pb": 3.75,
      "pe": 17.21,
      "yield": 1.13,
      "date": "2001-04-01"
  },
  "2001-04-02": {
      "equity": 1138.1,
      "debt": 10.333,
      "gold": 4236.702587,
      "pb": 3.72,
      "pe": 17.05,
      "yield": 1.14,
      "date": "2001-04-02"
  },
  "2001-04-03": {
      "equity": 1149.2,
      "debt": 10.408,
      "gold": 4226.751795,
      "pb": 3.75,
      "pe": 17.22,
      "yield": 1.13,
      "date": "2001-04-03"
  },
  "2001-04-04": {
      "equity": 1136.6,
      "debt": 10.438,
      "gold": 4260.18801,
      "pb": 3.72,
      "pe": 17.07,
      "yield": 1.29,
      "date": "2001-04-04"
  },
  "2001-04-05": {
      "equity": 1136.6,
      "debt": 10.438,
      "gold": 4241.475158,
      "pb": 3.72,
      "pe": 17.07,
      "yield": 1.29,
      "date": "2001-04-05"
  },
  "2001-04-06": {
      "equity": 1139.6,
      "debt": 10.438,
      "gold": 4238.469814,
      "pb": 3.73,
      "pe": 17.11,
      "yield": 0.84,
      "date": "2001-04-06"
  },
  "2001-04-07": {
      "equity": 1139.6,
      "debt": 10.415,
      "gold": 4238.469814,
      "pb": 3.73,
      "pe": 17.11,
      "yield": 0.84,
      "date": "2001-04-07"
  },
  "2001-04-08": {
      "equity": 1139.6,
      "debt": 10.415,
      "gold": 4238.469814,
      "pb": 3.73,
      "pe": 17.11,
      "yield": 0.84,
      "date": "2001-04-08"
  },
  "2001-04-09": {
      "equity": 1128.3,
      "debt": 10.421,
      "gold": 4284.449461,
      "pb": 3.69,
      "pe": 16.94,
      "yield": 0.85,
      "date": "2001-04-09"
  },
  "2001-04-10": {
      "equity": 1103,
      "debt": 10.317,
      "gold": 4221.22436,
      "pb": 3.56,
      "pe": 15.94,
      "yield": 0.87,
      "date": "2001-04-10"
  },
  "2001-04-11": {
      "equity": 1066.8,
      "debt": 10.347,
      "gold": 4256.978077,
      "pb": 3.45,
      "pe": 15.41,
      "yield": 0.9,
      "date": "2001-04-11"
  },
  "2001-04-12": {
      "equity": 1024.9,
      "debt": 10.359,
      "gold": 4287.722888,
      "pb": 3.3,
      "pe": 14.73,
      "yield": 0.94,
      "date": "2001-04-12"
  },
  "2001-04-13": {
      "equity": 1024.9,
      "debt": 10.359,
      "gold": 4287.722888,
      "pb": 3.3,
      "pe": 14.73,
      "yield": 0.94,
      "date": "2001-04-13"
  },
  "2001-04-14": {
      "equity": 1024.9,
      "debt": 10.359,
      "gold": 4287.722888,
      "pb": 3.3,
      "pe": 14.73,
      "yield": 0.94,
      "date": "2001-04-14"
  },
  "2001-04-15": {
      "equity": 1024.9,
      "debt": 10.359,
      "gold": 4287.722888,
      "pb": 3.3,
      "pe": 14.73,
      "yield": 0.94,
      "date": "2001-04-15"
  },
  "2001-04-16": {
      "equity": 1044.6,
      "debt": 10.384,
      "gold": 4287.722888,
      "pb": 3.37,
      "pe": 15.02,
      "yield": 0.76,
      "date": "2001-04-16"
  },
  "2001-04-17": {
      "equity": 1067,
      "debt": 10.369,
      "gold": 4332.71839,
      "pb": 3.44,
      "pe": 15.34,
      "yield": 0.74,
      "date": "2001-04-17"
  },
  "2001-04-18": {
      "equity": 1103.4,
      "debt": 10.331,
      "gold": 4299.296284,
      "pb": 3.56,
      "pe": 15.86,
      "yield": 0.72,
      "date": "2001-04-18"
  },
  "2001-04-19": {
      "equity": 1144.4,
      "debt": 10.331,
      "gold": 4319.035609,
      "pb": 3.69,
      "pe": 16.45,
      "yield": 0.69,
      "date": "2001-04-19"
  },
  "2001-04-20": {
      "equity": 1144,
      "debt": 10.316,
      "gold": 4362.404981,
      "pb": 3.69,
      "pe": 16.45,
      "yield": 0.62,
      "date": "2001-04-20"
  },
  "2001-04-21": {
      "equity": 1144,
      "debt": 10.293,
      "gold": 4362.404981,
      "pb": 3.69,
      "pe": 16.45,
      "yield": 0.62,
      "date": "2001-04-21"
  },
  "2001-04-22": {
      "equity": 1144,
      "debt": 10.293,
      "gold": 4362.404981,
      "pb": 3.69,
      "pe": 16.45,
      "yield": 0.62,
      "date": "2001-04-22"
  },
  "2001-04-23": {
      "equity": 1149.7,
      "debt": 10.271,
      "gold": 4339.550962,
      "pb": 3.71,
      "pe": 16.53,
      "yield": 0.62,
      "date": "2001-04-23"
  },
  "2001-04-24": {
      "equity": 1146.3,
      "debt": 10.212,
      "gold": 4353.325455,
      "pb": 3.69,
      "pe": 16.48,
      "yield": 0.62,
      "date": "2001-04-24"
  },
  "2001-04-25": {
      "equity": 1155.3,
      "debt": 10.208,
      "gold": 4361.537241,
      "pb": 3.72,
      "pe": 16.61,
      "yield": 0.63,
      "date": "2001-04-25"
  },
  "2001-04-26": {
      "equity": 1143.7,
      "debt": 10.16,
      "gold": 4334.46798,
      "pb": 3.66,
      "pe": 16.38,
      "yield": 0.64,
      "date": "2001-04-26"
  },
  "2001-04-27": {
      "equity": 1101.3,
      "debt": 10.1,
      "gold": 4370.264026,
      "pb": 3.15,
      "pe": 14.44,
      "yield": 0.61,
      "date": "2001-04-27"
  },
  "2001-04-28": {
      "equity": 1101.3,
      "debt": 10.13,
      "gold": 4370.264026,
      "pb": 3.15,
      "pe": 14.44,
      "yield": 0.61,
      "date": "2001-04-28"
  },
  "2001-04-29": {
      "equity": 1101.3,
      "debt": 10.13,
      "gold": 4370.264026,
      "pb": 3.15,
      "pe": 14.44,
      "yield": 0.61,
      "date": "2001-04-29"
  },
  "2001-04-30": {
      "equity": 1125.2,
      "debt": 10.125,
      "gold": 4351.879222,
      "pb": 3.21,
      "pe": 14.57,
      "yield": 0.6,
      "date": "2001-04-30"
  },
  "2001-05-01": {
      "equity": 1125.2,
      "debt": 10.125,
      "gold": 4356.732923,
      "pb": 3.21,
      "pe": 14.57,
      "yield": 0.6,
      "date": "2001-05-01"
  },
  "2001-05-02": {
      "equity": 1137.2,
      "debt": 10.115,
      "gold": 4377.018995,
      "pb": 3.23,
      "pe": 14.63,
      "yield": 0.62,
      "date": "2001-05-02"
  },
  "2001-05-03": {
      "equity": 1122,
      "debt": 10.103,
      "gold": 4381.971463,
      "pb": 3.19,
      "pe": 15.01,
      "yield": 0.63,
      "date": "2001-05-03"
  },
  "2001-05-04": {
      "equity": 1130,
      "debt": 10.076,
      "gold": 4390.232632,
      "pb": 3.13,
      "pe": 15.4,
      "yield": 0.6,
      "date": "2001-05-04"
  },
  "2001-05-05": {
      "equity": 1130,
      "debt": 10.085,
      "gold": 4390.232632,
      "pb": 3.13,
      "pe": 15.4,
      "yield": 0.6,
      "date": "2001-05-05"
  },
  "2001-05-06": {
      "equity": 1130,
      "debt": 10.085,
      "gold": 4390.232632,
      "pb": 3.13,
      "pe": 15.4,
      "yield": 0.6,
      "date": "2001-05-06"
  },
  "2001-05-07": {
      "equity": 1139.2,
      "debt": 10.085,
      "gold": 4390.232632,
      "pb": 3.15,
      "pe": 15.46,
      "yield": 0.59,
      "date": "2001-05-07"
  },
  "2001-05-08": {
      "equity": 1148.9,
      "debt": 10.107,
      "gold": 4386.927459,
      "pb": 3.17,
      "pe": 15.6,
      "yield": 0.59,
      "date": "2001-05-08"
  },
  "2001-05-09": {
      "equity": 1149.2,
      "debt": 10.096,
      "gold": 4380.320641,
      "pb": 3.17,
      "pe": 15.6,
      "yield": 0.61,
      "date": "2001-05-09"
  },
  "2001-05-10": {
      "equity": 1144.9,
      "debt": 10.064,
      "gold": 4452.544137,
      "pb": 3.16,
      "pe": 15.55,
      "yield": 0.61,
      "date": "2001-05-10"
  },
  "2001-05-11": {
      "equity": 1140.5,
      "debt": 10.033,
      "gold": 4447.951463,
      "pb": 3.15,
      "pe": 15.49,
      "yield": 0.61,
      "date": "2001-05-11"
  },
  "2001-05-12": {
      "equity": 1140.5,
      "debt": 10.044,
      "gold": 4447.951463,
      "pb": 3.15,
      "pe": 15.49,
      "yield": 0.61,
      "date": "2001-05-12"
  },
  "2001-05-13": {
      "equity": 1140.5,
      "debt": 10.044,
      "gold": 4447.951463,
      "pb": 3.15,
      "pe": 15.49,
      "yield": 0.61,
      "date": "2001-05-13"
  },
  "2001-05-14": {
      "equity": 1140.8,
      "debt": 10.047,
      "gold": 4433.418579,
      "pb": 3.15,
      "pe": 15.49,
      "yield": 0.61,
      "date": "2001-05-14"
  },
  "2001-05-15": {
      "equity": 1145.3,
      "debt": 10.031,
      "gold": 4442.995467,
      "pb": 3.16,
      "pe": 15.55,
      "yield": 0.61,
      "date": "2001-05-15"
  },
  "2001-05-16": {
      "equity": 1151.1,
      "debt": 9.98,
      "gold": 4458.974585,
      "pb": 3.18,
      "pe": 15.63,
      "yield": 0.61,
      "date": "2001-05-16"
  },
  "2001-05-17": {
      "equity": 1174.9,
      "debt": 9.945,
      "gold": 4500.968271,
      "pb": 3.25,
      "pe": 15.95,
      "yield": 0.6,
      "date": "2001-05-17"
  },
  "2001-05-18": {
      "equity": 1172.8,
      "debt": 9.955,
      "gold": 4504.365156,
      "pb": 3.24,
      "pe": 15.92,
      "yield": 0.6,
      "date": "2001-05-18"
  },
  "2001-05-19": {
      "equity": 1172.8,
      "debt": 9.957,
      "gold": 4504.365156,
      "pb": 3.24,
      "pe": 15.92,
      "yield": 0.6,
      "date": "2001-05-19"
  },
  "2001-05-20": {
      "equity": 1172.8,
      "debt": 9.957,
      "gold": 4504.365156,
      "pb": 3.24,
      "pe": 15.92,
      "yield": 0.6,
      "date": "2001-05-20"
  },
  "2001-05-21": {
      "equity": 1169.4,
      "debt": 9.966,
      "gold": 4777.946701,
      "pb": 3.23,
      "pe": 15.98,
      "yield": 0.6,
      "date": "2001-05-21"
  },
  "2001-05-22": {
      "equity": 1168.1,
      "debt": 9.976,
      "gold": 4688.647066,
      "pb": 3.22,
      "pe": 15.96,
      "yield": 0.6,
      "date": "2001-05-22"
  },
  "2001-05-23": {
      "equity": 1179.1,
      "debt": 9.967,
      "gold": 4702.880121,
      "pb": 3.25,
      "pe": 16.11,
      "yield": 0.73,
      "date": "2001-05-23"
  },
  "2001-05-24": {
      "equity": 1181.8,
      "debt": 9.947,
      "gold": 4696.753029,
      "pb": 3.24,
      "pe": 16.13,
      "yield": 0.73,
      "date": "2001-05-24"
  },
  "2001-05-25": {
      "equity": 1174.9,
      "debt": 9.917,
      "gold": 4649.369477,
      "pb": 3.22,
      "pe": 16.15,
      "yield": 0.73,
      "date": "2001-05-25"
  },
  "2001-05-26": {
      "equity": 1174.9,
      "debt": 9.877,
      "gold": 4649.369477,
      "pb": 3.22,
      "pe": 16.15,
      "yield": 0.73,
      "date": "2001-05-26"
  },
  "2001-05-27": {
      "equity": 1174.9,
      "debt": 9.877,
      "gold": 4649.369477,
      "pb": 3.22,
      "pe": 16.15,
      "yield": 0.73,
      "date": "2001-05-27"
  },
  "2001-05-28": {
      "equity": 1193.2,
      "debt": 9.812,
      "gold": 4649.369477,
      "pb": 3.27,
      "pe": 16.4,
      "yield": 0.72,
      "date": "2001-05-28"
  },
  "2001-05-29": {
      "equity": 1198.4,
      "debt": 9.812,
      "gold": 4582.61345,
      "pb": 3.28,
      "pe": 16.47,
      "yield": 0.72,
      "date": "2001-05-29"
  },
  "2001-05-30": {
      "equity": 1177.5,
      "debt": 9.786,
      "gold": 4533.402706,
      "pb": 3.08,
      "pe": 15.98,
      "yield": 0.8,
      "date": "2001-05-30"
  },
  "2001-05-31": {
      "equity": 1167.9,
      "debt": 9.762,
      "gold": 4411.612198,
      "pb": 3.04,
      "pe": 15.8,
      "yield": 0.81,
      "date": "2001-05-31"
  },
  "2001-06-01": {
      "equity": 1148,
      "debt": 9.804,
      "gold": 4405.555654,
      "pb": 2.95,
      "pe": 15.47,
      "yield": 0.82,
      "date": "2001-06-01"
  },
  "2001-06-02": {
      "equity": 1148,
      "debt": 9.853,
      "gold": 4405.555654,
      "pb": 2.95,
      "pe": 15.47,
      "yield": 0.82,
      "date": "2001-06-02"
  },
  "2001-06-03": {
      "equity": 1148,
      "debt": 9.853,
      "gold": 4405.555654,
      "pb": 2.95,
      "pe": 15.47,
      "yield": 0.82,
      "date": "2001-06-03"
  },
  "2001-06-04": {
      "equity": 1127.2,
      "debt": 9.929,
      "gold": 4415.506446,
      "pb": 2.89,
      "pe": 15.25,
      "yield": 0.84,
      "date": "2001-06-04"
  },
  "2001-06-05": {
      "equity": 1115.6,
      "debt": 9.931,
      "gold": 4413.379425,
      "pb": 2.86,
      "pe": 15.08,
      "yield": 0.85,
      "date": "2001-06-05"
  },
  "2001-06-06": {
      "equity": 1115.7,
      "debt": 9.885,
      "gold": 4423.351382,
      "pb": 2.87,
      "pe": 15.12,
      "yield": 0.85,
      "date": "2001-06-06"
  },
  "2001-06-07": {
      "equity": 1112.3,
      "debt": 9.918,
      "gold": 4411.280622,
      "pb": 2.86,
      "pe": 15.07,
      "yield": 0.85,
      "date": "2001-06-07"
  },
  "2001-06-08": {
      "equity": 1126.6,
      "debt": 9.89,
      "gold": 4428.794159,
      "pb": 2.89,
      "pe": 15.26,
      "yield": 0.81,
      "date": "2001-06-08"
  },
  "2001-06-09": {
      "equity": 1126.6,
      "debt": 9.822,
      "gold": 4428.794159,
      "pb": 2.89,
      "pe": 15.26,
      "yield": 0.81,
      "date": "2001-06-09"
  },
  "2001-06-10": {
      "equity": 1126.6,
      "debt": 9.822,
      "gold": 4428.794159,
      "pb": 2.89,
      "pe": 15.26,
      "yield": 0.81,
      "date": "2001-06-10"
  },
  "2001-06-11": {
      "equity": 1131.1,
      "debt": 9.819,
      "gold": 4521.861056,
      "pb": 2.91,
      "pe": 15.32,
      "yield": 0.81,
      "date": "2001-06-11"
  },
  "2001-06-12": {
      "equity": 1127.1,
      "debt": 9.822,
      "gold": 4444.290023,
      "pb": 2.9,
      "pe": 15.27,
      "yield": 0.81,
      "date": "2001-06-12"
  },
  "2001-06-13": {
      "equity": 1129,
      "debt": 9.76,
      "gold": 4509.945502,
      "pb": 2.9,
      "pe": 15.3,
      "yield": 0.91,
      "date": "2001-06-13"
  },
  "2001-06-14": {
      "equity": 1112.7,
      "debt": 9.758,
      "gold": 4510.344098,
      "pb": 2.88,
      "pe": 15.54,
      "yield": 0.92,
      "date": "2001-06-14"
  },
  "2001-06-15": {
      "equity": 1087.7,
      "debt": 9.75,
      "gold": 4548.387097,
      "pb": 2.81,
      "pe": 15.19,
      "yield": 0.92,
      "date": "2001-06-15"
  },
  "2001-06-16": {
      "equity": 1087.7,
      "debt": 9.751,
      "gold": 4548.387097,
      "pb": 2.81,
      "pe": 15.19,
      "yield": 0.92,
      "date": "2001-06-16"
  },
  "2001-06-17": {
      "equity": 1087.7,
      "debt": 9.751,
      "gold": 4548.387097,
      "pb": 2.81,
      "pe": 15.19,
      "yield": 0.92,
      "date": "2001-06-17"
  },
  "2001-06-18": {
      "equity": 1078.3,
      "debt": 9.722,
      "gold": 4498.266283,
      "pb": 2.75,
      "pe": 15.07,
      "yield": 0.92,
      "date": "2001-06-18"
  },
  "2001-06-19": {
      "equity": 1096.6,
      "debt": 9.669,
      "gold": 4536.92305,
      "pb": 2.79,
      "pe": 15.35,
      "yield": 0.91,
      "date": "2001-06-19"
  },
  "2001-06-20": {
      "equity": 1097.6,
      "debt": 9.638,
      "gold": 4512.213619,
      "pb": 2.44,
      "pe": 15.62,
      "yield": 0.97,
      "date": "2001-06-20"
  },
  "2001-06-21": {
      "equity": 1095.2,
      "debt": 9.624,
      "gold": 4516.838039,
      "pb": 2.43,
      "pe": 15.59,
      "yield": 0.97,
      "date": "2001-06-21"
  },
  "2001-06-22": {
      "equity": 1087.6,
      "debt": 9.632,
      "gold": 4529.321505,
      "pb": 2.42,
      "pe": 15.48,
      "yield": 0.97,
      "date": "2001-06-22"
  },
  "2001-06-23": {
      "equity": 1087.6,
      "debt": 9.613,
      "gold": 4529.321505,
      "pb": 2.42,
      "pe": 15.48,
      "yield": 0.97,
      "date": "2001-06-23"
  },
  "2001-06-24": {
      "equity": 1087.6,
      "debt": 9.613,
      "gold": 4529.321505,
      "pb": 2.42,
      "pe": 15.48,
      "yield": 0.97,
      "date": "2001-06-24"
  },
  "2001-06-25": {
      "equity": 1067,
      "debt": 9.574,
      "gold": 4528.626607,
      "pb": 2.37,
      "pe": 15.19,
      "yield": 0.99,
      "date": "2001-06-25"
  },
  "2001-06-26": {
      "equity": 1096.6,
      "debt": 9.509,
      "gold": 4542.030724,
      "pb": 2.44,
      "pe": 15.61,
      "yield": 0.97,
      "date": "2001-06-26"
  },
  "2001-06-27": {
      "equity": 1096.1,
      "debt": 9.525,
      "gold": 4574.768514,
      "pb": 2.43,
      "pe": 15.59,
      "yield": 0.97,
      "date": "2001-06-27"
  },
  "2001-06-28": {
      "equity": 1094,
      "debt": 9.512,
      "gold": 4501.264573,
      "pb": 2.43,
      "pe": 15.56,
      "yield": 0.97,
      "date": "2001-06-28"
  },
  "2001-06-29": {
      "equity": 1107.9,
      "debt": 9.522,
      "gold": 4478.421136,
      "pb": 2.46,
      "pe": 15.76,
      "yield": 0.89,
      "date": "2001-06-29"
  },
  "2001-06-30": {
      "equity": 1107.9,
      "debt": 9.501,
      "gold": 4478.421136,
      "pb": 2.46,
      "pe": 15.76,
      "yield": 0.89,
      "date": "2001-06-30"
  },
  "2001-07-01": {
      "equity": 1107.9,
      "debt": 9.501,
      "gold": 4478.421136,
      "pb": 2.46,
      "pe": 15.76,
      "yield": 0.89,
      "date": "2001-07-01"
  },
  "2001-07-02": {
      "equity": 1100.7,
      "debt": 9.543,
      "gold": 4473.687367,
      "pb": 2.44,
      "pe": 15.65,
      "yield": 1.02,
      "date": "2001-07-02"
  },
  "2001-07-03": {
      "equity": 1069.8,
      "debt": 9.557,
      "gold": 4464.427944,
      "pb": 2.37,
      "pe": 15.21,
      "yield": 1.05,
      "date": "2001-07-03"
  },
  "2001-07-04": {
      "equity": 1067.9,
      "debt": 9.503,
      "gold": 4446.865024,
      "pb": 2.37,
      "pe": 15.19,
      "yield": 1.08,
      "date": "2001-07-04"
  },
  "2001-07-05": {
      "equity": 1069.7,
      "debt": 9.438,
      "gold": 4447.919716,
      "pb": 2.37,
      "pe": 15.21,
      "yield": 1.08,
      "date": "2001-07-05"
  },
  "2001-07-06": {
      "equity": 1065.1,
      "debt": 9.407,
      "gold": 4409.319388,
      "pb": 2.36,
      "pe": 15.15,
      "yield": 1.12,
      "date": "2001-07-06"
  },
  "2001-07-07": {
      "equity": 1065.1,
      "debt": 9.393,
      "gold": 4409.319388,
      "pb": 2.36,
      "pe": 15.15,
      "yield": 1.12,
      "date": "2001-07-07"
  },
  "2001-07-08": {
      "equity": 1065.1,
      "debt": 9.393,
      "gold": 4409.319388,
      "pb": 2.36,
      "pe": 15.15,
      "yield": 1.12,
      "date": "2001-07-08"
  },
  "2001-07-09": {
      "equity": 1059.5,
      "debt": 9.385,
      "gold": 4437.224642,
      "pb": 2.35,
      "pe": 15.07,
      "yield": 1.16,
      "date": "2001-07-09"
  },
  "2001-07-10": {
      "equity": 1072,
      "debt": 9.329,
      "gold": 4443.044851,
      "pb": 2.38,
      "pe": 15.25,
      "yield": 1.15,
      "date": "2001-07-10"
  },
  "2001-07-11": {
      "equity": 1083.6,
      "debt": 9.258,
      "gold": 4433.64786,
      "pb": 2.4,
      "pe": 15.41,
      "yield": 1.14,
      "date": "2001-07-11"
  },
  "2001-07-12": {
      "equity": 1105.5,
      "debt": 9.455,
      "gold": 4450.279546,
      "pb": 2.45,
      "pe": 15.72,
      "yield": 1.11,
      "date": "2001-07-12"
  },
  "2001-07-13": {
      "equity": 1110.4,
      "debt": 9.483,
      "gold": 4432.092277,
      "pb": 2.46,
      "pe": 15.79,
      "yield": 1.11,
      "date": "2001-07-13"
  },
  "2001-07-14": {
      "equity": 1110.4,
      "debt": 9.395,
      "gold": 4432.092277,
      "pb": 2.46,
      "pe": 15.79,
      "yield": 1.11,
      "date": "2001-07-14"
  },
  "2001-07-15": {
      "equity": 1110.4,
      "debt": 9.395,
      "gold": 4432.092277,
      "pb": 2.46,
      "pe": 15.79,
      "yield": 1.11,
      "date": "2001-07-15"
  },
  "2001-07-16": {
      "equity": 1105.5,
      "debt": 9.44,
      "gold": 4456.819344,
      "pb": 2.45,
      "pe": 15.72,
      "yield": 1.11,
      "date": "2001-07-16"
  },
  "2001-07-17": {
      "equity": 1103.1,
      "debt": 9.447,
      "gold": 4435.813683,
      "pb": 2.45,
      "pe": 15.69,
      "yield": 1.12,
      "date": "2001-07-17"
  },
  "2001-07-18": {
      "equity": 1091.9,
      "debt": 9.42,
      "gold": 4458.008078,
      "pb": 2.42,
      "pe": 15.53,
      "yield": 1.13,
      "date": "2001-07-18"
  },
  "2001-07-19": {
      "equity": 1085.9,
      "debt": 9.372,
      "gold": 4513.462319,
      "pb": 2.41,
      "pe": 15.44,
      "yield": 1.13,
      "date": "2001-07-19"
  },
  "2001-07-20": {
      "equity": 1077.7,
      "debt": 9.343,
      "gold": 4490.311998,
      "pb": 2.39,
      "pe": 15.33,
      "yield": 1.16,
      "date": "2001-07-20"
  },
  "2001-07-21": {
      "equity": 1077.7,
      "debt": 9.338,
      "gold": 4490.311998,
      "pb": 2.39,
      "pe": 15.33,
      "yield": 1.16,
      "date": "2001-07-21"
  },
  "2001-07-22": {
      "equity": 1077.7,
      "debt": 9.338,
      "gold": 4490.311998,
      "pb": 2.39,
      "pe": 15.33,
      "yield": 1.16,
      "date": "2001-07-22"
  },
  "2001-07-23": {
      "equity": 1070.6,
      "debt": 9.345,
      "gold": 4473.807298,
      "pb": 2.38,
      "pe": 15.23,
      "yield": 1.17,
      "date": "2001-07-23"
  },
  "2001-07-24": {
      "equity": 1072.5,
      "debt": 9.317,
      "gold": 4467.990617,
      "pb": 2.38,
      "pe": 15.25,
      "yield": 1.17,
      "date": "2001-07-24"
  },
  "2001-07-25": {
      "equity": 1064.2,
      "debt": 9.353,
      "gold": 4476.657437,
      "pb": 2.36,
      "pe": 15.13,
      "yield": 1.18,
      "date": "2001-07-25"
  },
  "2001-07-26": {
      "equity": 1053.4,
      "debt": 9.331,
      "gold": 4443.626872,
      "pb": 2.33,
      "pe": 14.93,
      "yield": 1.19,
      "date": "2001-07-26"
  },
  "2001-07-27": {
      "equity": 1051.7,
      "debt": 9.338,
      "gold": 4420.127339,
      "pb": 2.33,
      "pe": 14.9,
      "yield": 1.15,
      "date": "2001-07-27"
  },
  "2001-07-28": {
      "equity": 1051.7,
      "debt": 9.331,
      "gold": 4420.127339,
      "pb": 2.33,
      "pe": 14.9,
      "yield": 1.15,
      "date": "2001-07-28"
  },
  "2001-07-29": {
      "equity": 1051.7,
      "debt": 9.331,
      "gold": 4420.127339,
      "pb": 2.33,
      "pe": 14.9,
      "yield": 1.15,
      "date": "2001-07-29"
  },
  "2001-07-30": {
      "equity": 1061.4,
      "debt": 9.331,
      "gold": 4430.212173,
      "pb": 2.35,
      "pe": 15.04,
      "yield": 1.18,
      "date": "2001-07-30"
  },
  "2001-07-31": {
      "equity": 1072.8,
      "debt": 9.363,
      "gold": 4415.139597,
      "pb": 2.37,
      "pe": 15.15,
      "yield": 1.17,
      "date": "2001-07-31"
  },
  "2001-08-01": {
      "equity": 1063.1,
      "debt": 9.358,
      "gold": 4448.999101,
      "pb": 2.35,
      "pe": 15.01,
      "yield": 1.18,
      "date": "2001-08-01"
  },
  "2001-08-02": {
      "equity": 1066,
      "debt": 9.356,
      "gold": 4456.604173,
      "pb": 2.36,
      "pe": 15.05,
      "yield": 1.2,
      "date": "2001-08-02"
  },
  "2001-08-03": {
      "equity": 1074.6,
      "debt": 9.348,
      "gold": 4446.145435,
      "pb": 2.38,
      "pe": 15.18,
      "yield": 1.19,
      "date": "2001-08-03"
  },
  "2001-08-04": {
      "equity": 1074.6,
      "debt": 9.328,
      "gold": 4446.145435,
      "pb": 2.38,
      "pe": 15.18,
      "yield": 1.19,
      "date": "2001-08-04"
  },
  "2001-08-05": {
      "equity": 1074.6,
      "debt": 9.328,
      "gold": 4446.145435,
      "pb": 2.38,
      "pe": 15.18,
      "yield": 1.19,
      "date": "2001-08-05"
  },
  "2001-08-06": {
      "equity": 1075.2,
      "debt": 9.304,
      "gold": 4451.376567,
      "pb": 2.38,
      "pe": 15.18,
      "yield": 1.21,
      "date": "2001-08-06"
  },
  "2001-08-07": {
      "equity": 1072.1,
      "debt": 9.315,
      "gold": 4432.377996,
      "pb": 2.37,
      "pe": 15.14,
      "yield": 1.23,
      "date": "2001-08-07"
  },
  "2001-08-08": {
      "equity": 1068,
      "debt": 9.33,
      "gold": 4429.993474,
      "pb": 2.36,
      "pe": 15.08,
      "yield": 1.23,
      "date": "2001-08-08"
  },
  "2001-08-09": {
      "equity": 1070.6,
      "debt": 9.309,
      "gold": 4472.498633,
      "pb": 2.37,
      "pe": 15.12,
      "yield": 1.23,
      "date": "2001-08-09"
  },
  "2001-08-10": {
      "equity": 1071.1,
      "debt": 9.267,
      "gold": 4544.344697,
      "pb": 2.4,
      "pe": 15.31,
      "yield": 1.2,
      "date": "2001-08-10"
  },
  "2001-08-11": {
      "equity": 1071.1,
      "debt": 9.242,
      "gold": 4544.344697,
      "pb": 2.4,
      "pe": 15.31,
      "yield": 1.2,
      "date": "2001-08-11"
  },
  "2001-08-12": {
      "equity": 1071.1,
      "debt": 9.242,
      "gold": 4544.344697,
      "pb": 2.4,
      "pe": 15.31,
      "yield": 1.2,
      "date": "2001-08-12"
  },
  "2001-08-13": {
      "equity": 1063,
      "debt": 9.246,
      "gold": 4572.951904,
      "pb": 2.38,
      "pe": 15.19,
      "yield": 1.21,
      "date": "2001-08-13"
  },
  "2001-08-14": {
      "equity": 1075.5,
      "debt": 9.251,
      "gold": 4555.360765,
      "pb": 2.41,
      "pe": 15.37,
      "yield": 1.19,
      "date": "2001-08-14"
  },
  "2001-08-15": {
      "equity": 1075.5,
      "debt": 9.251,
      "gold": 4593.728284,
      "pb": 2.41,
      "pe": 15.37,
      "yield": 1.19,
      "date": "2001-08-15"
  },
  "2001-08-16": {
      "equity": 1078.9,
      "debt": 9.251,
      "gold": 4571.981869,
      "pb": 2.42,
      "pe": 15.42,
      "yield": 1.19,
      "date": "2001-08-16"
  },
  "2001-08-17": {
      "equity": 1069.2,
      "debt": 9.222,
      "gold": 4592.066879,
      "pb": 2.39,
      "pe": 15.28,
      "yield": 1.19,
      "date": "2001-08-17"
  },
  "2001-08-18": {
      "equity": 1069.2,
      "debt": 9.198,
      "gold": 4592.066879,
      "pb": 2.39,
      "pe": 15.28,
      "yield": 1.19,
      "date": "2001-08-18"
  },
  "2001-08-19": {
      "equity": 1069.2,
      "debt": 9.198,
      "gold": 4592.066879,
      "pb": 2.39,
      "pe": 15.28,
      "yield": 1.19,
      "date": "2001-08-19"
  },
  "2001-08-20": {
      "equity": 1063.7,
      "debt": 9.189,
      "gold": 4617.492372,
      "pb": 2.38,
      "pe": 15.21,
      "yield": 1.2,
      "date": "2001-08-20"
  },
  "2001-08-21": {
      "equity": 1068.7,
      "debt": 9.189,
      "gold": 4602.236371,
      "pb": 2.39,
      "pe": 15.28,
      "yield": 1.32,
      "date": "2001-08-21"
  },
  "2001-08-22": {
      "equity": 1068.7,
      "debt": 9.189,
      "gold": 4609.178292,
      "pb": 2.39,
      "pe": 15.28,
      "yield": 1.32,
      "date": "2001-08-22"
  },
  "2001-08-23": {
      "equity": 1071.5,
      "debt": 9.159,
      "gold": 4559.653609,
      "pb": 2.4,
      "pe": 15.32,
      "yield": 1.32,
      "date": "2001-08-23"
  },
  "2001-08-24": {
      "equity": 1069.1,
      "debt": 9.087,
      "gold": 4573.780843,
      "pb": 2.39,
      "pe": 15.28,
      "yield": 1.34,
      "date": "2001-08-24"
  },
  "2001-08-25": {
      "equity": 1069.1,
      "debt": 9.064,
      "gold": 4573.780843,
      "pb": 2.39,
      "pe": 15.28,
      "yield": 1.34,
      "date": "2001-08-25"
  },
  "2001-08-26": {
      "equity": 1069.1,
      "debt": 9.064,
      "gold": 4573.780843,
      "pb": 2.39,
      "pe": 15.28,
      "yield": 1.34,
      "date": "2001-08-26"
  },
  "2001-08-27": {
      "equity": 1072.5,
      "debt": 9.078,
      "gold": 4573.780843,
      "pb": 2.41,
      "pe": 15.37,
      "yield": 1.33,
      "date": "2001-08-27"
  },
  "2001-08-28": {
      "equity": 1070.6,
      "debt": 9.07,
      "gold": 4516.686361,
      "pb": 2.4,
      "pe": 15.35,
      "yield": 1.34,
      "date": "2001-08-28"
  },
  "2001-08-29": {
      "equity": 1067.4,
      "debt": 9.102,
      "gold": 4535.550892,
      "pb": 2.39,
      "pe": 15.3,
      "yield": 1.37,
      "date": "2001-08-29"
  },
  "2001-08-30": {
      "equity": 1064.1,
      "debt": 9.102,
      "gold": 4546.61987,
      "pb": 2.39,
      "pe": 15.25,
      "yield": 1.37,
      "date": "2001-08-30"
  },
  "2001-08-31": {
      "equity": 1053.7,
      "debt": 9.114,
      "gold": 4576.620399,
      "pb": 2.36,
      "pe": 15.1,
      "yield": 1.35,
      "date": "2001-08-31"
  },
  "2001-09-01": {
      "equity": 1053.7,
      "debt": 9.145,
      "gold": 4576.620399,
      "pb": 2.36,
      "pe": 15.1,
      "yield": 1.35,
      "date": "2001-09-01"
  },
  "2001-09-02": {
      "equity": 1053.7,
      "debt": 9.145,
      "gold": 4576.620399,
      "pb": 2.36,
      "pe": 15.1,
      "yield": 1.35,
      "date": "2001-09-02"
  },
  "2001-09-03": {
      "equity": 1048,
      "debt": 9.12,
      "gold": 4549.596995,
      "pb": 2.35,
      "pe": 15.02,
      "yield": 1.38,
      "date": "2001-09-03"
  },
  "2001-09-04": {
      "equity": 1048.2,
      "debt": 9.098,
      "gold": 4526.53486,
      "pb": 2.35,
      "pe": 15.02,
      "yield": 1.38,
      "date": "2001-09-04"
  },
  "2001-09-05": {
      "equity": 1045,
      "debt": 9.13,
      "gold": 4518.83102,
      "pb": 2.34,
      "pe": 14.98,
      "yield": 1.42,
      "date": "2001-09-05"
  },
  "2001-09-06": {
      "equity": 1036.1,
      "debt": 9.136,
      "gold": 4527.176846,
      "pb": 2.32,
      "pe": 14.85,
      "yield": 1.54,
      "date": "2001-09-06"
  },
  "2001-09-07": {
      "equity": 1035.2,
      "debt": 9.175,
      "gold": 4549.688707,
      "pb": 2.32,
      "pe": 14.84,
      "yield": 1.45,
      "date": "2001-09-07"
  },
  "2001-09-08": {
      "equity": 1035.2,
      "debt": 9.165,
      "gold": 4549.688707,
      "pb": 2.32,
      "pe": 14.84,
      "yield": 1.45,
      "date": "2001-09-08"
  },
  "2001-09-09": {
      "equity": 1035.2,
      "debt": 9.165,
      "gold": 4549.688707,
      "pb": 2.32,
      "pe": 14.84,
      "yield": 1.45,
      "date": "2001-09-09"
  },
  "2001-09-10": {
      "equity": 1033.4,
      "debt": 9.181,
      "gold": 4561.124535,
      "pb": 2.32,
      "pe": 14.81,
      "yield": 1.45,
      "date": "2001-09-10"
  },
  "2001-09-11": {
      "equity": 1023.4,
      "debt": 9.128,
      "gold": 4538.729078,
      "pb": 2.3,
      "pe": 14.67,
      "yield": 1.47,
      "date": "2001-09-11"
  },
  "2001-09-12": {
      "equity": 982.2,
      "debt": 9.179,
      "gold": 4676.653909,
      "pb": 2.21,
      "pe": 14.14,
      "yield": 1.52,
      "date": "2001-09-12"
  },
  "2001-09-13": {
      "equity": 971.7,
      "debt": 9.246,
      "gold": 4672.202332,
      "pb": 2.19,
      "pe": 13.99,
      "yield": 1.54,
      "date": "2001-09-13"
  },
  "2001-09-14": {
      "equity": 919.7,
      "debt": 9.464,
      "gold": 4798.451472,
      "pb": 2.07,
      "pe": 13.24,
      "yield": 1.62,
      "date": "2001-09-14"
  },
  "2001-09-15": {
      "equity": 919.7,
      "debt": 9.825,
      "gold": 4798.451472,
      "pb": 2.07,
      "pe": 13.24,
      "yield": 1.62,
      "date": "2001-09-15"
  },
  "2001-09-16": {
      "equity": 919.7,
      "debt": 9.825,
      "gold": 4798.451472,
      "pb": 2.07,
      "pe": 13.24,
      "yield": 1.62,
      "date": "2001-09-16"
  },
  "2001-09-17": {
      "equity": 872.25,
      "debt": 9.628,
      "gold": 4908.083035,
      "pb": 1.97,
      "pe": 12.56,
      "yield": 1.71,
      "date": "2001-09-17"
  },
  "2001-09-18": {
      "equity": 900.2,
      "debt": 9.442,
      "gold": 4892.372,
      "pb": 2.03,
      "pe": 12.96,
      "yield": 1.66,
      "date": "2001-09-18"
  },
  "2001-09-19": {
      "equity": 912.2,
      "debt": 9.377,
      "gold": 4900.664915,
      "pb": 2.06,
      "pe": 13.13,
      "yield": 1.65,
      "date": "2001-09-19"
  },
  "2001-09-20": {
      "equity": 898.8,
      "debt": 9.519,
      "gold": 4878.138944,
      "pb": 2.03,
      "pe": 12.94,
      "yield": 1.67,
      "date": "2001-09-20"
  },
  "2001-09-21": {
      "equity": 854.2,
      "debt": 9.714,
      "gold": 4933.691952,
      "pb": 1.92,
      "pe": 12.3,
      "yield": 1.75,
      "date": "2001-09-21"
  },
  "2001-09-22": {
      "equity": 854.2,
      "debt": 9.468,
      "gold": 4933.691952,
      "pb": 1.92,
      "pe": 12.3,
      "yield": 1.75,
      "date": "2001-09-22"
  },
  "2001-09-23": {
      "equity": 854.2,
      "debt": 9.468,
      "gold": 4933.691952,
      "pb": 1.92,
      "pe": 12.3,
      "yield": 1.75,
      "date": "2001-09-23"
  },
  "2001-09-24": {
      "equity": 869.05,
      "debt": 9.341,
      "gold": 4893.994603,
      "pb": 1.96,
      "pe": 12.51,
      "yield": 1.72,
      "date": "2001-09-24"
  },
  "2001-09-25": {
      "equity": 861.4,
      "debt": 9.316,
      "gold": 4862.734793,
      "pb": 1.94,
      "pe": 12.4,
      "yield": 1.74,
      "date": "2001-09-25"
  },
  "2001-09-26": {
      "equity": 873.7,
      "debt": 9.302,
      "gold": 4924.372564,
      "pb": 1.97,
      "pe": 12.58,
      "yield": 1.72,
      "date": "2001-09-26"
  },
  "2001-09-27": {
      "equity": 890,
      "debt": 9.22,
      "gold": 4916.407697,
      "pb": 2.01,
      "pe": 12.81,
      "yield": 1.68,
      "date": "2001-09-27"
  },
  "2001-09-28": {
      "equity": 913.85,
      "debt": 9.116,
      "gold": 4910.167728,
      "pb": 2.06,
      "pe": 13.16,
      "yield": 1.64,
      "date": "2001-09-28"
  },
  "2001-09-29": {
      "equity": 913.85,
      "debt": 9.122,
      "gold": 4910.167728,
      "pb": 2.06,
      "pe": 13.16,
      "yield": 1.64,
      "date": "2001-09-29"
  },
  "2001-09-30": {
      "equity": 913.85,
      "debt": 9.122,
      "gold": 4910.167728,
      "pb": 2.06,
      "pe": 13.16,
      "yield": 1.64,
      "date": "2001-09-30"
  },
  "2001-10-01": {
      "equity": 910.1,
      "debt": 9.204,
      "gold": 4936.535036,
      "pb": 2.05,
      "pe": 13.1,
      "yield": 1.65,
      "date": "2001-10-01"
  },
  "2001-10-02": {
      "equity": 910.1,
      "debt": 9.204,
      "gold": 4926.711229,
      "pb": 2.05,
      "pe": 13.1,
      "yield": 1.65,
      "date": "2001-10-02"
  },
  "2001-10-03": {
      "equity": 899.65,
      "debt": 9.114,
      "gold": 4900.597894,
      "pb": 2.03,
      "pe": 12.95,
      "yield": 1.67,
      "date": "2001-10-03"
  },
  "2001-10-04": {
      "equity": 911.65,
      "debt": 9.082,
      "gold": 4893.197411,
      "pb": 2.05,
      "pe": 13.13,
      "yield": 1.64,
      "date": "2001-10-04"
  },
  "2001-10-05": {
      "equity": 914.6,
      "debt": 9.113,
      "gold": 4937.229934,
      "pb": 2.06,
      "pe": 13.17,
      "yield": 1.64,
      "date": "2001-10-05"
  },
  "2001-10-06": {
      "equity": 914.6,
      "debt": 9.117,
      "gold": 4937.229934,
      "pb": 2.06,
      "pe": 13.17,
      "yield": 1.64,
      "date": "2001-10-06"
  },
  "2001-10-07": {
      "equity": 914.6,
      "debt": 9.117,
      "gold": 4937.229934,
      "pb": 2.06,
      "pe": 13.17,
      "yield": 1.64,
      "date": "2001-10-07"
  },
  "2001-10-08": {
      "equity": 901.95,
      "debt": 9.174,
      "gold": 4971.823136,
      "pb": 2.03,
      "pe": 12.98,
      "yield": 1.67,
      "date": "2001-10-08"
  },
  "2001-10-09": {
      "equity": 912.7,
      "debt": 9.112,
      "gold": 4941.600381,
      "pb": 2.06,
      "pe": 13.14,
      "yield": 1.65,
      "date": "2001-10-09"
  },
  "2001-10-10": {
      "equity": 940.35,
      "debt": 9.119,
      "gold": 4854.921604,
      "pb": 2.12,
      "pe": 13.54,
      "yield": 1.6,
      "date": "2001-10-10"
  },
  "2001-10-11": {
      "equity": 954.9,
      "debt": 9.109,
      "gold": 4785.096739,
      "pb": 2.15,
      "pe": 13.75,
      "yield": 1.58,
      "date": "2001-10-11"
  },
  "2001-10-12": {
      "equity": 960.4,
      "debt": 9.139,
      "gold": 4792.073934,
      "pb": 2.16,
      "pe": 13.83,
      "yield": 1.57,
      "date": "2001-10-12"
  },
  "2001-10-13": {
      "equity": 960.4,
      "debt": 9.128,
      "gold": 4792.073934,
      "pb": 2.16,
      "pe": 13.83,
      "yield": 1.57,
      "date": "2001-10-13"
  },
  "2001-10-14": {
      "equity": 960.4,
      "debt": 9.128,
      "gold": 4792.073934,
      "pb": 2.16,
      "pe": 13.83,
      "yield": 1.57,
      "date": "2001-10-14"
  },
  "2001-10-15": {
      "equity": 963.4,
      "debt": 9.151,
      "gold": 4807.319353,
      "pb": 2.17,
      "pe": 13.87,
      "yield": 1.57,
      "date": "2001-10-15"
  },
  "2001-10-16": {
      "equity": 971.25,
      "debt": 9.124,
      "gold": 4761.988748,
      "pb": 2.19,
      "pe": 13.98,
      "yield": 1.55,
      "date": "2001-10-16"
  },
  "2001-10-17": {
      "equity": 986.25,
      "debt": 9.11,
      "gold": 4769.258717,
      "pb": 2.22,
      "pe": 14.2,
      "yield": 1.53,
      "date": "2001-10-17"
  },
  "2001-10-18": {
      "equity": 972.05,
      "debt": 9.123,
      "gold": 4782.553484,
      "pb": 2.19,
      "pe": 13.99,
      "yield": 1.55,
      "date": "2001-10-18"
  },
  "2001-10-19": {
      "equity": 976.65,
      "debt": 9.119,
      "gold": 4722.746433,
      "pb": 2.2,
      "pe": 14.16,
      "yield": 1.54,
      "date": "2001-10-19"
  },
  "2001-10-20": {
      "equity": 976.65,
      "debt": 9.117,
      "gold": 4722.746433,
      "pb": 2.2,
      "pe": 14.16,
      "yield": 1.54,
      "date": "2001-10-20"
  },
  "2001-10-21": {
      "equity": 976.65,
      "debt": 9.117,
      "gold": 4722.746433,
      "pb": 2.2,
      "pe": 14.16,
      "yield": 1.54,
      "date": "2001-10-21"
  },
  "2001-10-22": {
      "equity": 976.4,
      "debt": 8.956,
      "gold": 4699.34214,
      "pb": 2.2,
      "pe": 14.15,
      "yield": 1.56,
      "date": "2001-10-22"
  },
  "2001-10-23": {
      "equity": 993.2,
      "debt": 8.887,
      "gold": 4653.99037,
      "pb": 2.24,
      "pe": 14.4,
      "yield": 1.54,
      "date": "2001-10-23"
  },
  "2001-10-24": {
      "equity": 991.2,
      "debt": 8.901,
      "gold": 4678.558705,
      "pb": 2.23,
      "pe": 14.38,
      "yield": 1.54,
      "date": "2001-10-24"
  },
  "2001-10-25": {
      "equity": 983.2,
      "debt": 8.904,
      "gold": 4663.062841,
      "pb": 2.22,
      "pe": 14.26,
      "yield": 1.59,
      "date": "2001-10-25"
  },
  "2001-10-26": {
      "equity": 983.2,
      "debt": 8.904,
      "gold": 4689.670012,
      "pb": 2.22,
      "pe": 14.26,
      "yield": 1.59,
      "date": "2001-10-26"
  },
  "2001-10-27": {
      "equity": 983.2,
      "debt": 8.883,
      "gold": 4689.670012,
      "pb": 2.22,
      "pe": 14.26,
      "yield": 1.59,
      "date": "2001-10-27"
  },
  "2001-10-28": {
      "equity": 983.2,
      "debt": 8.883,
      "gold": 4689.670012,
      "pb": 2.22,
      "pe": 14.26,
      "yield": 1.59,
      "date": "2001-10-28"
  },
  "2001-10-29": {
      "equity": 977.45,
      "debt": 8.87,
      "gold": 4698.943544,
      "pb": 2.2,
      "pe": 14.18,
      "yield": 1.59,
      "date": "2001-10-29"
  },
  "2001-10-30": {
      "equity": 963.1,
      "debt": 8.837,
      "gold": 4742.235313,
      "pb": 2.17,
      "pe": 13.99,
      "yield": 1.62,
      "date": "2001-10-30"
  },
  "2001-10-31": {
      "equity": 971.9,
      "debt": 8.8,
      "gold": 4756.415457,
      "pb": 2.19,
      "pe": 13.79,
      "yield": 1.61,
      "date": "2001-10-31"
  },
  "2001-11-01": {
      "equity": 994,
      "debt": 8.83,
      "gold": 4731.51202,
      "pb": 2.24,
      "pe": 14.11,
      "yield": 1.57,
      "date": "2001-11-01"
  },
  "2001-11-02": {
      "equity": 997.6,
      "debt": 8.769,
      "gold": 4743.222985,
      "pb": 2.25,
      "pe": 14.16,
      "yield": 1.56,
      "date": "2001-11-02"
  },
  "2001-11-03": {
      "equity": 997.6,
      "debt": 8.761,
      "gold": 4743.222985,
      "pb": 2.25,
      "pe": 14.16,
      "yield": 1.56,
      "date": "2001-11-03"
  },
  "2001-11-04": {
      "equity": 997.6,
      "debt": 8.761,
      "gold": 4743.222985,
      "pb": 2.25,
      "pe": 14.16,
      "yield": 1.56,
      "date": "2001-11-04"
  },
  "2001-11-05": {
      "equity": 991.05,
      "debt": 8.795,
      "gold": 4720.587665,
      "pb": 2.24,
      "pe": 14.06,
      "yield": 1.57,
      "date": "2001-11-05"
  },
  "2001-11-06": {
      "equity": 1001.9,
      "debt": 8.756,
      "gold": 4721.215542,
      "pb": 2.26,
      "pe": 14.22,
      "yield": 1.56,
      "date": "2001-11-06"
  },
  "2001-11-07": {
      "equity": 987.5,
      "debt": 8.721,
      "gold": 4748.302439,
      "pb": 2.25,
      "pe": 14.01,
      "yield": 1.56,
      "date": "2001-11-07"
  },
  "2001-11-08": {
      "equity": 997.7,
      "debt": 8.735,
      "gold": 4745.692164,
      "pb": 2.27,
      "pe": 14.16,
      "yield": 1.55,
      "date": "2001-11-08"
  },
  "2001-11-09": {
      "equity": 1004,
      "debt": 8.705,
      "gold": 4695.109261,
      "pb": 2.28,
      "pe": 14.55,
      "yield": 1.56,
      "date": "2001-11-09"
  },
  "2001-11-10": {
      "equity": 1004,
      "debt": 8.673,
      "gold": 4695.109261,
      "pb": 2.28,
      "pe": 14.55,
      "yield": 1.56,
      "date": "2001-11-10"
  },
  "2001-11-11": {
      "equity": 1004,
      "debt": 8.673,
      "gold": 4695.109261,
      "pb": 2.28,
      "pe": 14.55,
      "yield": 1.56,
      "date": "2001-11-11"
  },
  "2001-11-12": {
      "equity": 1010.9,
      "debt": 8.624,
      "gold": 4700.90125,
      "pb": 2.27,
      "pe": 14.65,
      "yield": 1.56,
      "date": "2001-11-12"
  },
  "2001-11-13": {
      "equity": 1005.4,
      "debt": 8.637,
      "gold": 4706.911938,
      "pb": 2.29,
      "pe": 14.57,
      "yield": 1.55,
      "date": "2001-11-13"
  },
  "2001-11-14": {
      "equity": 1015.8,
      "debt": 8.637,
      "gold": 4705.21526,
      "pb": 2.34,
      "pe": 14.72,
      "yield": 1.52,
      "date": "2001-11-14"
  },
  "2001-11-15": {
      "equity": 1035.7,
      "debt": 8.636,
      "gold": 4671.997742,
      "pb": 2.41,
      "pe": 15.01,
      "yield": 1.47,
      "date": "2001-11-15"
  },
  "2001-11-16": {
      "equity": 1035.7,
      "debt": 8.636,
      "gold": 4662.688936,
      "pb": 2.41,
      "pe": 15.01,
      "yield": 1.47,
      "date": "2001-11-16"
  },
  "2001-11-17": {
      "equity": 1035.7,
      "debt": 8.547,
      "gold": 4662.688936,
      "pb": 2.41,
      "pe": 15.01,
      "yield": 1.47,
      "date": "2001-11-17"
  },
  "2001-11-18": {
      "equity": 1035.7,
      "debt": 8.547,
      "gold": 4662.688936,
      "pb": 2.41,
      "pe": 15.01,
      "yield": 1.47,
      "date": "2001-11-18"
  },
  "2001-11-19": {
      "equity": 1068.1,
      "debt": 8.454,
      "gold": 4638.268753,
      "pb": 2.37,
      "pe": 15.48,
      "yield": 1.5,
      "date": "2001-11-19"
  },
  "2001-11-20": {
      "equity": 1050.2,
      "debt": 8.368,
      "gold": 4633.072894,
      "pb": 2.38,
      "pe": 15.22,
      "yield": 1.49,
      "date": "2001-11-20"
  },
  "2001-11-21": {
      "equity": 1056.3,
      "debt": 8.315,
      "gold": 4622.917512,
      "pb": 2.4,
      "pe": 15.31,
      "yield": 1.48,
      "date": "2001-11-21"
  },
  "2001-11-22": {
      "equity": 1062.4,
      "debt": 8.191,
      "gold": 4628.099261,
      "pb": 2.39,
      "pe": 15.4,
      "yield": 1.48,
      "date": "2001-11-22"
  },
  "2001-11-23": {
      "equity": 1059,
      "debt": 8.159,
      "gold": 4616.127269,
      "pb": 2.44,
      "pe": 15.35,
      "yield": 1.45,
      "date": "2001-11-23"
  },
  "2001-11-24": {
      "equity": 1059,
      "debt": 8.138,
      "gold": 4616.127269,
      "pb": 2.44,
      "pe": 15.35,
      "yield": 1.45,
      "date": "2001-11-24"
  },
  "2001-11-25": {
      "equity": 1059,
      "debt": 8.138,
      "gold": 4616.127269,
      "pb": 2.44,
      "pe": 15.35,
      "yield": 1.45,
      "date": "2001-11-25"
  },
  "2001-11-26": {
      "equity": 1080.6,
      "debt": 8.112,
      "gold": 4623.15032,
      "pb": 2.43,
      "pe": 15.66,
      "yield": 1.46,
      "date": "2001-11-26"
  },
  "2001-11-27": {
      "equity": 1076,
      "debt": 7.996,
      "gold": 4630.413235,
      "pb": 2.42,
      "pe": 15.59,
      "yield": 1.46,
      "date": "2001-11-27"
  },
  "2001-11-28": {
      "equity": 1070.8,
      "debt": 7.881,
      "gold": 4648.910916,
      "pb": 2.41,
      "pe": 15.52,
      "yield": 1.47,
      "date": "2001-11-28"
  },
  "2001-11-29": {
      "equity": 1067.1,
      "debt": 7.88,
      "gold": 4647.817422,
      "pb": 2.4,
      "pe": 15.46,
      "yield": 1.47,
      "date": "2001-11-29"
  },
  "2001-11-30": {
      "equity": 1067.1,
      "debt": 7.88,
      "gold": 4644.304132,
      "pb": 2.4,
      "pe": 15.46,
      "yield": 1.47,
      "date": "2001-11-30"
  },
  "2001-12-01": {
      "equity": 1067.1,
      "debt": 7.877,
      "gold": 4644.304132,
      "pb": 2.4,
      "pe": 15.46,
      "yield": 1.47,
      "date": "2001-12-01"
  },
  "2001-12-02": {
      "equity": 1067.1,
      "debt": 7.877,
      "gold": 4644.304132,
      "pb": 2.4,
      "pe": 15.46,
      "yield": 1.47,
      "date": "2001-12-02"
  },
  "2001-12-03": {
      "equity": 1065.4,
      "debt": 7.88,
      "gold": 4648.748655,
      "pb": 2.43,
      "pe": 15.44,
      "yield": 1.46,
      "date": "2001-12-03"
  },
  "2001-12-04": {
      "equity": 1077.7,
      "debt": 7.775,
      "gold": 4670.703187,
      "pb": 2.49,
      "pe": 15.62,
      "yield": 1.42,
      "date": "2001-12-04"
  },
  "2001-12-05": {
      "equity": 1104.5,
      "debt": 7.791,
      "gold": 4658.166811,
      "pb": 2.51,
      "pe": 16.01,
      "yield": 1.41,
      "date": "2001-12-05"
  },
  "2001-12-06": {
      "equity": 1110.4,
      "debt": 7.794,
      "gold": 4626.427274,
      "pb": 2.51,
      "pe": 16.09,
      "yield": 1.41,
      "date": "2001-12-06"
  },
  "2001-12-07": {
      "equity": 1112.3,
      "debt": 8.157,
      "gold": 4633.520873,
      "pb": 2.52,
      "pe": 16.12,
      "yield": 1.41,
      "date": "2001-12-07"
  },
  "2001-12-08": {
      "equity": 1112.3,
      "debt": 8.084,
      "gold": 4633.520873,
      "pb": 2.52,
      "pe": 16.12,
      "yield": 1.41,
      "date": "2001-12-08"
  },
  "2001-12-09": {
      "equity": 1112.3,
      "debt": 8.084,
      "gold": 4633.520873,
      "pb": 2.52,
      "pe": 16.12,
      "yield": 1.41,
      "date": "2001-12-09"
  },
  "2001-12-10": {
      "equity": 1115.2,
      "debt": 8.071,
      "gold": 4612.321205,
      "pb": 2.5,
      "pe": 16.16,
      "yield": 1.41,
      "date": "2001-12-10"
  },
  "2001-12-11": {
      "equity": 1110.2,
      "debt": 8.248,
      "gold": 4596.299758,
      "pb": 2.5,
      "pe": 16.09,
      "yield": 1.42,
      "date": "2001-12-11"
  },
  "2001-12-12": {
      "equity": 1107.6,
      "debt": 8.006,
      "gold": 4609.188875,
      "pb": 2.48,
      "pe": 16.05,
      "yield": 1.43,
      "date": "2001-12-12"
  },
  "2001-12-13": {
      "equity": 1098.7,
      "debt": 8.018,
      "gold": 4624.377855,
      "pb": 2.45,
      "pe": 15.92,
      "yield": 1.44,
      "date": "2001-12-13"
  },
  "2001-12-14": {
      "equity": 1087.8,
      "debt": 7.938,
      "gold": 4635.20697,
      "pb": 2.44,
      "pe": 15.76,
      "yield": 1.44,
      "date": "2001-12-14"
  },
  "2001-12-15": {
      "equity": 1087.8,
      "debt": 7.989,
      "gold": 4635.20697,
      "pb": 2.44,
      "pe": 15.76,
      "yield": 1.44,
      "date": "2001-12-15"
  },
  "2001-12-16": {
      "equity": 1087.8,
      "debt": 7.989,
      "gold": 4635.20697,
      "pb": 2.44,
      "pe": 15.76,
      "yield": 1.44,
      "date": "2001-12-16"
  },
  "2001-12-17": {
      "equity": 1087.8,
      "debt": 7.989,
      "gold": 4686.428332,
      "pb": 2.44,
      "pe": 15.76,
      "yield": 1.44,
      "date": "2001-12-17"
  },
  "2001-12-18": {
      "equity": 1082.3,
      "debt": 8.073,
      "gold": 4704.139403,
      "pb": 2.39,
      "pe": 15.68,
      "yield": 1.47,
      "date": "2001-12-18"
  },
  "2001-12-19": {
      "equity": 1060.7,
      "debt": 8.008,
      "gold": 4700.065257,
      "pb": 2.4,
      "pe": 15.37,
      "yield": 1.47,
      "date": "2001-12-19"
  },
  "2001-12-20": {
      "equity": 1062,
      "debt": 8.011,
      "gold": 4657.355509,
      "pb": 2.37,
      "pe": 15.39,
      "yield": 1.49,
      "date": "2001-12-20"
  },
  "2001-12-21": {
      "equity": 1050.8,
      "debt": 8.058,
      "gold": 4685.938024,
      "pb": 2.37,
      "pe": 15.23,
      "yield": 1.49,
      "date": "2001-12-21"
  },
  "2001-12-22": {
      "equity": 1050.8,
      "debt": 8.195,
      "gold": 4685.938024,
      "pb": 2.37,
      "pe": 15.23,
      "yield": 1.49,
      "date": "2001-12-22"
  },
  "2001-12-23": {
      "equity": 1050.8,
      "debt": 8.195,
      "gold": 4685.938024,
      "pb": 2.37,
      "pe": 15.23,
      "yield": 1.49,
      "date": "2001-12-23"
  },
  "2001-12-24": {
      "equity": 1048.5,
      "debt": 8.199,
      "gold": 4693.557206,
      "pb": 2.33,
      "pe": 15.19,
      "yield": 1.51,
      "date": "2001-12-24"
  },
  "2001-12-25": {
      "equity": 1048.5,
      "debt": 8.199,
      "gold": 4693.557206,
      "pb": 2.33,
      "pe": 15.19,
      "yield": 1.51,
      "date": "2001-12-25"
  },
  "2001-12-26": {
      "equity": 1034.2,
      "debt": 8.342,
      "gold": 4693.557206,
      "pb": 2.3,
      "pe": 14.99,
      "yield": 1.53,
      "date": "2001-12-26"
  },
  "2001-12-27": {
      "equity": 1020,
      "debt": 8.266,
      "gold": 4724.972222,
      "pb": 2.33,
      "pe": 14.78,
      "yield": 1.51,
      "date": "2001-12-27"
  },
  "2001-12-28": {
      "equity": 1033.8,
      "debt": 8.203,
      "gold": 4726.136263,
      "pb": 2.39,
      "pe": 14.98,
      "yield": 1.48,
      "date": "2001-12-28"
  },
  "2001-12-29": {
      "equity": 1033.8,
      "debt": 8.048,
      "gold": 4726.136263,
      "pb": 2.39,
      "pe": 14.98,
      "yield": 1.48,
      "date": "2001-12-29"
  },
  "2001-12-30": {
      "equity": 1033.8,
      "debt": 8.048,
      "gold": 4726.136263,
      "pb": 2.39,
      "pe": 14.98,
      "yield": 1.48,
      "date": "2001-12-30"
  },
  "2001-12-31": {
      "equity": 1059,
      "debt": 7.939,
      "gold": 4705.45865,
      "pb": 2.38,
      "pe": 15.35,
      "yield": 1.48,
      "date": "2001-12-31"
  },
  "2002-01-01": {
      "equity": 1055.3,
      "debt": 7.911,
      "gold": 4705.45865,
      "pb": 2.39,
      "pe": 15.29,
      "yield": 1.47,
      "date": "2002-01-01"
  },
  "2002-01-02": {
      "equity": 1060.7,
      "debt": 7.89,
      "gold": 4733.670788,
      "pb": 2.42,
      "pe": 15.37,
      "yield": 1.46,
      "date": "2002-01-02"
  },
  "2002-01-03": {
      "equity": 1072.2,
      "debt": 7.906,
      "gold": 4744.013122,
      "pb": 2.47,
      "pe": 15.54,
      "yield": 1.42,
      "date": "2002-01-03"
  },
  "2002-01-04": {
      "equity": 1096.2,
      "debt": 7.912,
      "gold": 4749.120796,
      "pb": 2.48,
      "pe": 15.89,
      "yield": 1.41,
      "date": "2002-01-04"
  },
  "2002-01-05": {
      "equity": 1096.2,
      "debt": 7.907,
      "gold": 4749.120796,
      "pb": 2.48,
      "pe": 15.89,
      "yield": 1.41,
      "date": "2002-01-05"
  },
  "2002-01-06": {
      "equity": 1096.2,
      "debt": 7.907,
      "gold": 4749.120796,
      "pb": 2.48,
      "pe": 15.89,
      "yield": 1.41,
      "date": "2002-01-06"
  },
  "2002-01-07": {
      "equity": 1100.1,
      "debt": 7.907,
      "gold": 4760.052206,
      "pb": 2.5,
      "pe": 15.94,
      "yield": 1.4,
      "date": "2002-01-07"
  },
  "2002-01-08": {
      "equity": 1109.9,
      "debt": 7.894,
      "gold": 4737.335755,
      "pb": 2.49,
      "pe": 16.09,
      "yield": 1.41,
      "date": "2002-01-08"
  },
  "2002-01-09": {
      "equity": 1102.8,
      "debt": 7.91,
      "gold": 4760.85998,
      "pb": 2.48,
      "pe": 15.98,
      "yield": 1.42,
      "date": "2002-01-09"
  },
  "2002-01-10": {
      "equity": 1098.2,
      "debt": 7.908,
      "gold": 4896.297995,
      "pb": 2.46,
      "pe": 15.91,
      "yield": 1.44,
      "date": "2002-01-10"
  },
  "2002-01-11": {
      "equity": 1088.5,
      "debt": 7.956,
      "gold": 4894.52724,
      "pb": 2.5,
      "pe": 15.78,
      "yield": 1.41,
      "date": "2002-01-11"
  },
  "2002-01-12": {
      "equity": 1088.5,
      "debt": 7.954,
      "gold": 4894.52724,
      "pb": 2.5,
      "pe": 15.78,
      "yield": 1.41,
      "date": "2002-01-12"
  },
  "2002-01-13": {
      "equity": 1088.5,
      "debt": 7.954,
      "gold": 4894.52724,
      "pb": 2.5,
      "pe": 15.78,
      "yield": 1.41,
      "date": "2002-01-13"
  },
  "2002-01-14": {
      "equity": 1109.8,
      "debt": 7.908,
      "gold": 4884.544701,
      "pb": 2.47,
      "pe": 16.08,
      "yield": 1.43,
      "date": "2002-01-14"
  },
  "2002-01-15": {
      "equity": 1094.1,
      "debt": 7.909,
      "gold": 4834.547347,
      "pb": 2.46,
      "pe": 15.61,
      "yield": 1.43,
      "date": "2002-01-15"
  },
  "2002-01-16": {
      "equity": 1090.3,
      "debt": 7.889,
      "gold": 4905.395157,
      "pb": 2.82,
      "pe": 15.56,
      "yield": 1.23,
      "date": "2002-01-16"
  },
  "2002-01-17": {
      "equity": 1109.2,
      "debt": 7.895,
      "gold": 4857.242632,
      "pb": 2.78,
      "pe": 17.57,
      "yield": 1.25,
      "date": "2002-01-17"
  },
  "2002-01-18": {
      "equity": 1093.1,
      "debt": 7.847,
      "gold": 4828.490802,
      "pb": 2.83,
      "pe": 17.32,
      "yield": 1.25,
      "date": "2002-01-18"
  },
  "2002-01-19": {
      "equity": 1093.1,
      "debt": 7.811,
      "gold": 4828.490802,
      "pb": 2.83,
      "pe": 17.32,
      "yield": 1.25,
      "date": "2002-01-19"
  },
  "2002-01-20": {
      "equity": 1093.1,
      "debt": 7.811,
      "gold": 4828.490802,
      "pb": 2.83,
      "pe": 17.32,
      "yield": 1.25,
      "date": "2002-01-20"
  },
  "2002-01-21": {
      "equity": 1091.3,
      "debt": 7.788,
      "gold": 4810.120108,
      "pb": 2.83,
      "pe": 17.29,
      "yield": 1.25,
      "date": "2002-01-21"
  },
  "2002-01-22": {
      "equity": 1092.8,
      "debt": 7.763,
      "gold": 4825.030424,
      "pb": 2.83,
      "pe": 17.31,
      "yield": 1.25,
      "date": "2002-01-22"
  },
  "2002-01-23": {
      "equity": 1089.4,
      "debt": 7.771,
      "gold": 4795.230956,
      "pb": 2.81,
      "pe": 17.26,
      "yield": 1.26,
      "date": "2002-01-23"
  },
  "2002-01-24": {
      "equity": 1085.3,
      "debt": 7.774,
      "gold": 4758.697684,
      "pb": 2.79,
      "pe": 17.19,
      "yield": 1.26,
      "date": "2002-01-24"
  },
  "2002-01-25": {
      "equity": 1080.1,
      "debt": 7.769,
      "gold": 4759.81587,
      "pb": 2.76,
      "pe": 17.06,
      "yield": 1.27,
      "date": "2002-01-25"
  },
  "2002-01-26": {
      "equity": 1080.1,
      "debt": 7.769,
      "gold": 4759.81587,
      "pb": 2.76,
      "pe": 17.06,
      "yield": 1.27,
      "date": "2002-01-26"
  },
  "2002-01-27": {
      "equity": 1080.1,
      "debt": 7.769,
      "gold": 4759.81587,
      "pb": 2.76,
      "pe": 17.06,
      "yield": 1.27,
      "date": "2002-01-27"
  },
  "2002-01-28": {
      "equity": 1071.3,
      "debt": 7.733,
      "gold": 4764.800085,
      "pb": 2.76,
      "pe": 16.93,
      "yield": 1.27,
      "date": "2002-01-28"
  },
  "2002-01-29": {
      "equity": 1071.6,
      "debt": 7.702,
      "gold": 4746.200109,
      "pb": 2.75,
      "pe": 16.93,
      "yield": 1.27,
      "date": "2002-01-29"
  },
  "2002-01-30": {
      "equity": 1067.4,
      "debt": 7.673,
      "gold": 4808.938429,
      "pb": 2.77,
      "pe": 16.87,
      "yield": 1.26,
      "date": "2002-01-30"
  },
  "2002-01-31": {
      "equity": 1075.4,
      "debt": 7.669,
      "gold": 4817.855694,
      "pb": 2.79,
      "pe": 16.99,
      "yield": 1.26,
      "date": "2002-01-31"
  },
  "2002-02-01": {
      "equity": 1081.6,
      "debt": 7.652,
      "gold": 4826.910528,
      "pb": 2.79,
      "pe": 17.42,
      "yield": 1.26,
      "date": "2002-02-01"
  },
  "2002-02-02": {
      "equity": 1081.6,
      "debt": 7.634,
      "gold": 4826.910528,
      "pb": 2.79,
      "pe": 17.42,
      "yield": 1.26,
      "date": "2002-02-02"
  },
  "2002-02-03": {
      "equity": 1081.6,
      "debt": 7.634,
      "gold": 4826.910528,
      "pb": 2.79,
      "pe": 17.42,
      "yield": 1.26,
      "date": "2002-02-03"
  },
  "2002-02-04": {
      "equity": 1076.9,
      "debt": 7.57,
      "gold": 4901.028237,
      "pb": 2.78,
      "pe": 17.34,
      "yield": 1.26,
      "date": "2002-02-04"
  },
  "2002-02-05": {
      "equity": 1074.2,
      "debt": 7.579,
      "gold": 4980.07725,
      "pb": 2.77,
      "pe": 17.3,
      "yield": 1.27,
      "date": "2002-02-05"
  },
  "2002-02-06": {
      "equity": 1113.1,
      "debt": 7.564,
      "gold": 5108.150056,
      "pb": 2.87,
      "pe": 17.92,
      "yield": 1.35,
      "date": "2002-02-06"
  },
  "2002-02-07": {
      "equity": 1110.4,
      "debt": 7.461,
      "gold": 5159.011623,
      "pb": 2.86,
      "pe": 17.88,
      "yield": 1.35,
      "date": "2002-02-07"
  },
  "2002-02-08": {
      "equity": 1123.7,
      "debt": 7.388,
      "gold": 5241.679747,
      "pb": 2.9,
      "pe": 18.09,
      "yield": 1.33,
      "date": "2002-02-08"
  },
  "2002-02-09": {
      "equity": 1123.7,
      "debt": 7.307,
      "gold": 5241.679747,
      "pb": 2.9,
      "pe": 18.09,
      "yield": 1.33,
      "date": "2002-02-09"
  },
  "2002-02-10": {
      "equity": 1123.7,
      "debt": 7.307,
      "gold": 5241.679747,
      "pb": 2.9,
      "pe": 18.09,
      "yield": 1.33,
      "date": "2002-02-10"
  },
  "2002-02-11": {
      "equity": 1131.5,
      "debt": 7.313,
      "gold": 5181.957354,
      "pb": 2.92,
      "pe": 18.22,
      "yield": 1.33,
      "date": "2002-02-11"
  },
  "2002-02-12": {
      "equity": 1129.5,
      "debt": 7.27,
      "gold": 5131.998801,
      "pb": 2.91,
      "pe": 18.19,
      "yield": 1.33,
      "date": "2002-02-12"
  },
  "2002-02-13": {
      "equity": 1135.1,
      "debt": 7.214,
      "gold": 5127.899963,
      "pb": 2.93,
      "pe": 18.28,
      "yield": 1.32,
      "date": "2002-02-13"
  },
  "2002-02-14": {
      "equity": 1150,
      "debt": 7.212,
      "gold": 5120.365439,
      "pb": 2.96,
      "pe": 18.52,
      "yield": 1.3,
      "date": "2002-02-14"
  },
  "2002-02-15": {
      "equity": 1159.9,
      "debt": 7.269,
      "gold": 5157.752341,
      "pb": 2.99,
      "pe": 18.68,
      "yield": 1.29,
      "date": "2002-02-15"
  },
  "2002-02-16": {
      "equity": 1159.9,
      "debt": 7.238,
      "gold": 5157.752341,
      "pb": 2.99,
      "pe": 18.68,
      "yield": 1.29,
      "date": "2002-02-16"
  },
  "2002-02-17": {
      "equity": 1159.9,
      "debt": 7.238,
      "gold": 5157.752341,
      "pb": 2.99,
      "pe": 18.68,
      "yield": 1.29,
      "date": "2002-02-17"
  },
  "2002-02-18": {
      "equity": 1172.8,
      "debt": 7.22,
      "gold": 5125.130955,
      "pb": 3.02,
      "pe": 18.88,
      "yield": 1.28,
      "date": "2002-02-18"
  },
  "2002-02-19": {
      "equity": 1158.9,
      "debt": 7.236,
      "gold": 5101.571456,
      "pb": 2.99,
      "pe": 18.66,
      "yield": 1.29,
      "date": "2002-02-19"
  },
  "2002-02-20": {
      "equity": 1145.9,
      "debt": 7.313,
      "gold": 5045.073105,
      "pb": 2.95,
      "pe": 18.44,
      "yield": 1.31,
      "date": "2002-02-20"
  },
  "2002-02-21": {
      "equity": 1149.8,
      "debt": 7.437,
      "gold": 4998.934725,
      "pb": 2.96,
      "pe": 18.5,
      "yield": 1.3,
      "date": "2002-02-21"
  },
  "2002-02-22": {
      "equity": 1163.5,
      "debt": 7.383,
      "gold": 5046.794476,
      "pb": 3,
      "pe": 18.72,
      "yield": 1.29,
      "date": "2002-02-22"
  },
  "2002-02-23": {
      "equity": 1163.5,
      "debt": 7.383,
      "gold": 5046.794476,
      "pb": 3,
      "pe": 18.72,
      "yield": 1.29,
      "date": "2002-02-23"
  },
  "2002-02-24": {
      "equity": 1163.5,
      "debt": 7.383,
      "gold": 5046.794476,
      "pb": 3,
      "pe": 18.72,
      "yield": 1.29,
      "date": "2002-02-24"
  },
  "2002-02-25": {
      "equity": 1165.4,
      "debt": 7.465,
      "gold": 5025.534842,
      "pb": 3,
      "pe": 18.76,
      "yield": 1.3,
      "date": "2002-02-25"
  },
  "2002-02-26": {
      "equity": 1189.4,
      "debt": 7.242,
      "gold": 5035.866594,
      "pb": 3.07,
      "pe": 19.14,
      "yield": 1.27,
      "date": "2002-02-26"
  },
  "2002-02-27": {
      "equity": 1189.2,
      "debt": 7.297,
      "gold": 5144.672746,
      "pb": 3.07,
      "pe": 19.14,
      "yield": 1.27,
      "date": "2002-02-27"
  },
  "2002-02-28": {
      "equity": 1142,
      "debt": 7.627,
      "gold": 5098.449708,
      "pb": 2.94,
      "pe": 18.38,
      "yield": 1.33,
      "date": "2002-02-28"
  },
  "2002-03-01": {
      "equity": 1178,
      "debt": 7.589,
      "gold": 5083.990899,
      "pb": 3.04,
      "pe": 18.96,
      "yield": 1.29,
      "date": "2002-03-01"
  },
  "2002-03-02": {
      "equity": 1178,
      "debt": 7.448,
      "gold": 5083.990899,
      "pb": 3.04,
      "pe": 18.96,
      "yield": 1.29,
      "date": "2002-03-02"
  },
  "2002-03-03": {
      "equity": 1178,
      "debt": 7.448,
      "gold": 5083.990899,
      "pb": 3.04,
      "pe": 18.96,
      "yield": 1.29,
      "date": "2002-03-03"
  },
  "2002-03-04": {
      "equity": 1177.3,
      "debt": 7.31,
      "gold": 5145.801513,
      "pb": 3.03,
      "pe": 18.97,
      "yield": 1.29,
      "date": "2002-03-04"
  },
  "2002-03-05": {
      "equity": 1178.5,
      "debt": 7.351,
      "gold": 5095.645426,
      "pb": 3.03,
      "pe": 18.99,
      "yield": 1.28,
      "date": "2002-03-05"
  },
  "2002-03-06": {
      "equity": 1172.6,
      "debt": 7.483,
      "gold": 5054.914549,
      "pb": 3.02,
      "pe": 18.79,
      "yield": 1.29,
      "date": "2002-03-06"
  },
  "2002-03-07": {
      "equity": 1193,
      "debt": 7.525,
      "gold": 5021.256107,
      "pb": 3.07,
      "pe": 19.11,
      "yield": 1.27,
      "date": "2002-03-07"
  },
  "2002-03-08": {
      "equity": 1187.6,
      "debt": 7.621,
      "gold": 4972.271116,
      "pb": 3.06,
      "pe": 19.03,
      "yield": 1.27,
      "date": "2002-03-08"
  },
  "2002-03-09": {
      "equity": 1187.6,
      "debt": 7.579,
      "gold": 4972.271116,
      "pb": 3.06,
      "pe": 19.03,
      "yield": 1.27,
      "date": "2002-03-09"
  },
  "2002-03-10": {
      "equity": 1187.6,
      "debt": 7.579,
      "gold": 4972.271116,
      "pb": 3.06,
      "pe": 19.03,
      "yield": 1.27,
      "date": "2002-03-10"
  },
  "2002-03-11": {
      "equity": 1167.8,
      "debt": 7.565,
      "gold": 5014.381206,
      "pb": 3.01,
      "pe": 18.71,
      "yield": 1.3,
      "date": "2002-03-11"
  },
  "2002-03-12": {
      "equity": 1150.4,
      "debt": 7.558,
      "gold": 5018.677578,
      "pb": 2.96,
      "pe": 18.43,
      "yield": 1.32,
      "date": "2002-03-12"
  },
  "2002-03-13": {
      "equity": 1157,
      "debt": 7.618,
      "gold": 5049.274238,
      "pb": 2.98,
      "pe": 18.54,
      "yield": 1.31,
      "date": "2002-03-13"
  },
  "2002-03-14": {
      "equity": 1159.4,
      "debt": 7.612,
      "gold": 5030.709536,
      "pb": 2.99,
      "pe": 18.58,
      "yield": 1.31,
      "date": "2002-03-14"
  },
  "2002-03-15": {
      "equity": 1169.7,
      "debt": 7.399,
      "gold": 5000.465617,
      "pb": 3.01,
      "pe": 18.74,
      "yield": 1.29,
      "date": "2002-03-15"
  },
  "2002-03-16": {
      "equity": 1169.7,
      "debt": 7.404,
      "gold": 5000.465617,
      "pb": 3.01,
      "pe": 18.74,
      "yield": 1.29,
      "date": "2002-03-16"
  },
  "2002-03-17": {
      "equity": 1169.7,
      "debt": 7.404,
      "gold": 5000.465617,
      "pb": 3.01,
      "pe": 18.74,
      "yield": 1.29,
      "date": "2002-03-17"
  },
  "2002-03-18": {
      "equity": 1169.3,
      "debt": 7.488,
      "gold": 5000.465617,
      "pb": 3.01,
      "pe": 18.73,
      "yield": 1.29,
      "date": "2002-03-18"
  },
  "2002-03-19": {
      "equity": 1152.1,
      "debt": 7.475,
      "gold": 5039.30228,
      "pb": 2.97,
      "pe": 18.46,
      "yield": 1.31,
      "date": "2002-03-19"
  },
  "2002-03-20": {
      "equity": 1155.6,
      "debt": 7.493,
      "gold": 5030.709536,
      "pb": 2.98,
      "pe": 18.51,
      "yield": 1.31,
      "date": "2002-03-20"
  },
  "2002-03-21": {
      "equity": 1144.2,
      "debt": 7.462,
      "gold": 5025.809979,
      "pb": 2.95,
      "pe": 18.33,
      "yield": 1.32,
      "date": "2002-03-21"
  },
  "2002-03-22": {
      "equity": 1138.4,
      "debt": 7.462,
      "gold": 5052.216088,
      "pb": 2.93,
      "pe": 18.24,
      "yield": 1.33,
      "date": "2002-03-22"
  },
  "2002-03-23": {
      "equity": 1138.4,
      "debt": 7.45,
      "gold": 5052.216088,
      "pb": 2.93,
      "pe": 18.24,
      "yield": 1.33,
      "date": "2002-03-23"
  },
  "2002-03-24": {
      "equity": 1138.4,
      "debt": 7.45,
      "gold": 5052.216088,
      "pb": 2.93,
      "pe": 18.24,
      "yield": 1.33,
      "date": "2002-03-24"
  },
  "2002-03-25": {
      "equity": 1138.4,
      "debt": 7.45,
      "gold": 5119.271945,
      "pb": 2.93,
      "pe": 18.24,
      "yield": 1.33,
      "date": "2002-03-25"
  },
  "2002-03-26": {
      "equity": 1123,
      "debt": 7.453,
      "gold": 5107.381083,
      "pb": 2.89,
      "pe": 17.99,
      "yield": 1.35,
      "date": "2002-03-26"
  },
  "2002-03-27": {
      "equity": 1123.3,
      "debt": 7.459,
      "gold": 5124.749996,
      "pb": 2.89,
      "pe": 18,
      "yield": 1.35,
      "date": "2002-03-27"
  },
  "2002-03-28": {
      "equity": 1129.5,
      "debt": 7.428,
      "gold": 5213.61576,
      "pb": 2.91,
      "pe": 18.1,
      "yield": 1.34,
      "date": "2002-03-28"
  },
  "2002-03-29": {
      "equity": 1129.5,
      "debt": 7.428,
      "gold": 5213.61576,
      "pb": 2.91,
      "pe": 18.1,
      "yield": 1.34,
      "date": "2002-03-29"
  },
  "2002-03-30": {
      "equity": 1129.5,
      "debt": 7.359,
      "gold": 5213.61576,
      "pb": 2.91,
      "pe": 18.1,
      "yield": 1.34,
      "date": "2002-03-30"
  },
  "2002-03-31": {
      "equity": 1129.5,
      "debt": 7.359,
      "gold": 5213.61576,
      "pb": 2.91,
      "pe": 18.1,
      "yield": 1.34,
      "date": "2002-03-31"
  },
  "2002-04-01": {
      "equity": 1138.9,
      "debt": 7.241,
      "gold": 5213.61576,
      "pb": 2.93,
      "pe": 18.25,
      "yield": 1.33,
      "date": "2002-04-01"
  },
  "2002-04-02": {
      "equity": 1136.9,
      "debt": 7.243,
      "gold": 5211.862643,
      "pb": 2.93,
      "pe": 18.22,
      "yield": 1.33,
      "date": "2002-04-02"
  },
  "2002-04-03": {
      "equity": 1123.5,
      "debt": 7.21,
      "gold": 5260.075134,
      "pb": 2.89,
      "pe": 18,
      "yield": 1.35,
      "date": "2002-04-03"
  },
  "2002-04-04": {
      "equity": 1145.9,
      "debt": 7.143,
      "gold": 5196.313868,
      "pb": 2.95,
      "pe": 18.36,
      "yield": 1.33,
      "date": "2002-04-04"
  },
  "2002-04-05": {
      "equity": 1141.9,
      "debt": 7.161,
      "gold": 5181.597559,
      "pb": 2.94,
      "pe": 18.3,
      "yield": 1.21,
      "date": "2002-04-05"
  },
  "2002-04-06": {
      "equity": 1141.9,
      "debt": 7.154,
      "gold": 5181.597559,
      "pb": 2.94,
      "pe": 18.3,
      "yield": 1.21,
      "date": "2002-04-06"
  },
  "2002-04-07": {
      "equity": 1141.9,
      "debt": 7.154,
      "gold": 5181.597559,
      "pb": 2.94,
      "pe": 18.3,
      "yield": 1.21,
      "date": "2002-04-07"
  },
  "2002-04-08": {
      "equity": 1135.2,
      "debt": 7.201,
      "gold": 5149.346549,
      "pb": 2.9,
      "pe": 17.91,
      "yield": 1.38,
      "date": "2002-04-08"
  },
  "2002-04-09": {
      "equity": 1126.7,
      "debt": 7.254,
      "gold": 5163.149262,
      "pb": 2.88,
      "pe": 17.78,
      "yield": 1.39,
      "date": "2002-04-09"
  },
  "2002-04-10": {
      "equity": 1138.5,
      "debt": 7.299,
      "gold": 5136.221097,
      "pb": 2.91,
      "pe": 18.01,
      "yield": 1.37,
      "date": "2002-04-10"
  },
  "2002-04-11": {
      "equity": 1143.6,
      "debt": 7.256,
      "gold": 5206.275243,
      "pb": 2.93,
      "pe": 18.09,
      "yield": 1.36,
      "date": "2002-04-11"
  },
  "2002-04-12": {
      "equity": 1146.5,
      "debt": 7.266,
      "gold": 5203.686132,
      "pb": 2.93,
      "pe": 18.14,
      "yield": 1.36,
      "date": "2002-04-12"
  },
  "2002-04-13": {
      "equity": 1146.5,
      "debt": 7.262,
      "gold": 5203.686132,
      "pb": 2.93,
      "pe": 18.14,
      "yield": 1.36,
      "date": "2002-04-13"
  },
  "2002-04-14": {
      "equity": 1146.5,
      "debt": 7.262,
      "gold": 5203.686132,
      "pb": 2.93,
      "pe": 18.14,
      "yield": 1.36,
      "date": "2002-04-14"
  },
  "2002-04-15": {
      "equity": 1134.1,
      "debt": 7.302,
      "gold": 5210.532814,
      "pb": 2.9,
      "pe": 17.94,
      "yield": 1.37,
      "date": "2002-04-15"
  },
  "2002-04-16": {
      "equity": 1118.7,
      "debt": 7.279,
      "gold": 5166.122859,
      "pb": 2.86,
      "pe": 17.7,
      "yield": 1.39,
      "date": "2002-04-16"
  },
  "2002-04-17": {
      "equity": 1125.1,
      "debt": 7.299,
      "gold": 5179.929099,
      "pb": 2.88,
      "pe": 17.8,
      "yield": 1.38,
      "date": "2002-04-17"
  },
  "2002-04-18": {
      "equity": 1129,
      "debt": 7.302,
      "gold": 5214.899734,
      "pb": 2.94,
      "pe": 18.49,
      "yield": 1.38,
      "date": "2002-04-18"
  },
  "2002-04-19": {
      "equity": 1100.3,
      "debt": 7.335,
      "gold": 5239.051835,
      "pb": 2.86,
      "pe": 18.02,
      "yield": 1.42,
      "date": "2002-04-19"
  },
  "2002-04-20": {
      "equity": 1100.3,
      "debt": 7.324,
      "gold": 5239.051835,
      "pb": 2.86,
      "pe": 18.02,
      "yield": 1.42,
      "date": "2002-04-20"
  },
  "2002-04-21": {
      "equity": 1100.3,
      "debt": 7.324,
      "gold": 5239.051835,
      "pb": 2.86,
      "pe": 18.02,
      "yield": 1.42,
      "date": "2002-04-21"
  },
  "2002-04-22": {
      "equity": 1104.1,
      "debt": 7.348,
      "gold": 5234.617894,
      "pb": 2.87,
      "pe": 18.02,
      "yield": 1.41,
      "date": "2002-04-22"
  },
  "2002-04-23": {
      "equity": 1106,
      "debt": 7.305,
      "gold": 5241.450466,
      "pb": 2.88,
      "pe": 18.05,
      "yield": 1.41,
      "date": "2002-04-23"
  },
  "2002-04-24": {
      "equity": 1110.6,
      "debt": 7.289,
      "gold": 5262.519621,
      "pb": 2.86,
      "pe": 18.16,
      "yield": 1.4,
      "date": "2002-04-24"
  },
  "2002-04-25": {
      "equity": 1094.3,
      "debt": 7.289,
      "gold": 5270.734934,
      "pb": 2.82,
      "pe": 17.85,
      "yield": 1.42,
      "date": "2002-04-25"
  },
  "2002-04-26": {
      "equity": 1097.4,
      "debt": 7.294,
      "gold": 5314.788621,
      "pb": 2.83,
      "pe": 17.9,
      "yield": 1.41,
      "date": "2002-04-26"
  },
  "2002-04-27": {
      "equity": 1097.4,
      "debt": 7.268,
      "gold": 5314.788621,
      "pb": 2.83,
      "pe": 17.9,
      "yield": 1.41,
      "date": "2002-04-27"
  },
  "2002-04-28": {
      "equity": 1097.4,
      "debt": 7.268,
      "gold": 5314.788621,
      "pb": 2.83,
      "pe": 17.9,
      "yield": 1.41,
      "date": "2002-04-28"
  },
  "2002-04-29": {
      "equity": 1074.2,
      "debt": 7.338,
      "gold": 5366.613168,
      "pb": 2.8,
      "pe": 17.69,
      "yield": 1.44,
      "date": "2002-04-29"
  },
  "2002-04-30": {
      "equity": 1084.5,
      "debt": 7.41,
      "gold": 5341.974285,
      "pb": 2.83,
      "pe": 17.86,
      "yield": 1.42,
      "date": "2002-04-30"
  },
  "2002-05-01": {
      "equity": 1084.5,
      "debt": 7.41,
      "gold": 5321.667049,
      "pb": 2.83,
      "pe": 17.86,
      "yield": 1.42,
      "date": "2002-05-01"
  },
  "2002-05-02": {
      "equity": 1093.3,
      "debt": 7.443,
      "gold": 5318.213725,
      "pb": 2.93,
      "pe": 18.08,
      "yield": 1.41,
      "date": "2002-05-02"
  },
  "2002-05-03": {
      "equity": 1096.9,
      "debt": 7.541,
      "gold": 5336.062364,
      "pb": 2.8,
      "pe": 18.04,
      "yield": 1.38,
      "date": "2002-05-03"
  },
  "2002-05-04": {
      "equity": 1096.9,
      "debt": 7.584,
      "gold": 5336.062364,
      "pb": 2.8,
      "pe": 18.04,
      "yield": 1.38,
      "date": "2002-05-04"
  },
  "2002-05-05": {
      "equity": 1096.9,
      "debt": 7.584,
      "gold": 5336.062364,
      "pb": 2.8,
      "pe": 18.04,
      "yield": 1.38,
      "date": "2002-05-05"
  },
  "2002-05-06": {
      "equity": 1100.9,
      "debt": 7.467,
      "gold": 5336.062364,
      "pb": 2.81,
      "pe": 18.1,
      "yield": 1.38,
      "date": "2002-05-06"
  },
  "2002-05-07": {
      "equity": 1110.7,
      "debt": 7.436,
      "gold": 5400.063493,
      "pb": 2.84,
      "pe": 18.26,
      "yield": 1.39,
      "date": "2002-05-07"
  },
  "2002-05-08": {
      "equity": 1117.6,
      "debt": 7.515,
      "gold": 5368.193443,
      "pb": 2.86,
      "pe": 18.38,
      "yield": 1.38,
      "date": "2002-05-08"
  },
  "2002-05-09": {
      "equity": 1127.6,
      "debt": 7.576,
      "gold": 5332.700753,
      "pb": 2.88,
      "pe": 18.54,
      "yield": 1.37,
      "date": "2002-05-09"
  },
  "2002-05-10": {
      "equity": 1116.4,
      "debt": 7.718,
      "gold": 5368.80721,
      "pb": 2.85,
      "pe": 18.36,
      "yield": 1.36,
      "date": "2002-05-10"
  },
  "2002-05-11": {
      "equity": 1116.4,
      "debt": 7.704,
      "gold": 5368.80721,
      "pb": 2.85,
      "pe": 18.36,
      "yield": 1.36,
      "date": "2002-05-11"
  },
  "2002-05-12": {
      "equity": 1116.4,
      "debt": 7.704,
      "gold": 5368.80721,
      "pb": 2.85,
      "pe": 18.36,
      "yield": 1.36,
      "date": "2002-05-12"
  },
  "2002-05-13": {
      "equity": 1119.6,
      "debt": 7.661,
      "gold": 5386.874548,
      "pb": 2.86,
      "pe": 18.16,
      "yield": 1.36,
      "date": "2002-05-13"
  },
  "2002-05-14": {
      "equity": 1115.1,
      "debt": 7.785,
      "gold": 5356.708231,
      "pb": 2.84,
      "pe": 17.99,
      "yield": 1.36,
      "date": "2002-05-14"
  },
  "2002-05-15": {
      "equity": 1107.8,
      "debt": 7.841,
      "gold": 5317.621122,
      "pb": 2.82,
      "pe": 17.87,
      "yield": 1.37,
      "date": "2002-05-15"
  },
  "2002-05-16": {
      "equity": 1092.8,
      "debt": 7.872,
      "gold": 5345.515794,
      "pb": 2.78,
      "pe": 17.63,
      "yield": 1.39,
      "date": "2002-05-16"
  },
  "2002-05-17": {
      "equity": 1090.6,
      "debt": 7.999,
      "gold": 5369.900704,
      "pb": 2.81,
      "pe": 17.49,
      "yield": 1.39,
      "date": "2002-05-17"
  },
  "2002-05-18": {
      "equity": 1090.6,
      "debt": 8.094,
      "gold": 5369.900704,
      "pb": 2.81,
      "pe": 17.49,
      "yield": 1.39,
      "date": "2002-05-18"
  },
  "2002-05-19": {
      "equity": 1090.6,
      "debt": 8.094,
      "gold": 5369.900704,
      "pb": 2.81,
      "pe": 17.49,
      "yield": 1.39,
      "date": "2002-05-19"
  },
  "2002-05-20": {
      "equity": 1074.3,
      "debt": 8.166,
      "gold": 5393.079243,
      "pb": 2.76,
      "pe": 17.23,
      "yield": 1.41,
      "date": "2002-05-20"
  },
  "2002-05-21": {
      "equity": 1049.2,
      "debt": 8.198,
      "gold": 5443.489303,
      "pb": 2.7,
      "pe": 16.82,
      "yield": 1.47,
      "date": "2002-05-21"
  },
  "2002-05-22": {
      "equity": 1045.3,
      "debt": 8.035,
      "gold": 5498.467345,
      "pb": 2.73,
      "pe": 16.6,
      "yield": 1.47,
      "date": "2002-05-22"
  },
  "2002-05-23": {
      "equity": 1026.7,
      "debt": 7.926,
      "gold": 5471.722605,
      "pb": 2.68,
      "pe": 16.31,
      "yield": 1.5,
      "date": "2002-05-23"
  },
  "2002-05-24": {
      "equity": 1067,
      "debt": 7.78,
      "gold": 5545.953191,
      "pb": 2.79,
      "pe": 16.95,
      "yield": 1.32,
      "date": "2002-05-24"
  },
  "2002-05-25": {
      "equity": 1067,
      "debt": 7.78,
      "gold": 5545.953191,
      "pb": 2.79,
      "pe": 16.95,
      "yield": 1.32,
      "date": "2002-05-25"
  },
  "2002-05-26": {
      "equity": 1067,
      "debt": 7.78,
      "gold": 5545.953191,
      "pb": 2.79,
      "pe": 16.95,
      "yield": 1.32,
      "date": "2002-05-26"
  },
  "2002-05-27": {
      "equity": 1062.7,
      "debt": 7.848,
      "gold": 5541.395792,
      "pb": 2.78,
      "pe": 16.88,
      "yield": 1.33,
      "date": "2002-05-27"
  },
  "2002-05-28": {
      "equity": 1038.2,
      "debt": 7.715,
      "gold": 5552.323674,
      "pb": 2.71,
      "pe": 16.49,
      "yield": 1.41,
      "date": "2002-05-28"
  },
  "2002-05-29": {
      "equity": 1041.6,
      "debt": 7.677,
      "gold": 5617.972098,
      "pb": 2.72,
      "pe": 16.54,
      "yield": 1.44,
      "date": "2002-05-29"
  },
  "2002-05-30": {
      "equity": 1032.1,
      "debt": 7.739,
      "gold": 5610.762095,
      "pb": 2.81,
      "pe": 16.72,
      "yield": 1.48,
      "date": "2002-05-30"
  },
  "2002-05-31": {
      "equity": 1028.8,
      "debt": 7.723,
      "gold": 5661.468456,
      "pb": 3.07,
      "pe": 16.19,
      "yield": 1.39,
      "date": "2002-05-31"
  },
  "2002-06-01": {
      "equity": 1028.8,
      "debt": 7.738,
      "gold": 5661.468456,
      "pb": 3.07,
      "pe": 16.19,
      "yield": 1.39,
      "date": "2002-06-01"
  },
  "2002-06-02": {
      "equity": 1028.8,
      "debt": 7.738,
      "gold": 5661.468456,
      "pb": 3.07,
      "pe": 16.19,
      "yield": 1.39,
      "date": "2002-06-02"
  },
  "2002-06-03": {
      "equity": 1039.7,
      "debt": 7.713,
      "gold": 5661.468456,
      "pb": 3.11,
      "pe": 16.39,
      "yield": 1.39,
      "date": "2002-06-03"
  },
  "2002-06-04": {
      "equity": 1045.4,
      "debt": 7.775,
      "gold": 5661.468456,
      "pb": 3.13,
      "pe": 16.48,
      "yield": 1.38,
      "date": "2002-06-04"
  },
  "2002-06-05": {
      "equity": 1064.2,
      "debt": 7.691,
      "gold": 5609.312334,
      "pb": 3.19,
      "pe": 16.78,
      "yield": 1.36,
      "date": "2002-06-05"
  },
  "2002-06-06": {
      "equity": 1064.3,
      "debt": 7.705,
      "gold": 5577.668036,
      "pb": 3.21,
      "pe": 16.42,
      "yield": 1.36,
      "date": "2002-06-06"
  },
  "2002-06-07": {
      "equity": 1048.8,
      "debt": 7.75,
      "gold": 5647.051976,
      "pb": 3.16,
      "pe": 16.18,
      "yield": 1.38,
      "date": "2002-06-07"
  },
  "2002-06-08": {
      "equity": 1048.8,
      "debt": 7.716,
      "gold": 5647.051976,
      "pb": 3.16,
      "pe": 16.18,
      "yield": 1.38,
      "date": "2002-06-08"
  },
  "2002-06-09": {
      "equity": 1048.8,
      "debt": 7.716,
      "gold": 5647.051976,
      "pb": 3.16,
      "pe": 16.18,
      "yield": 1.38,
      "date": "2002-06-09"
  },
  "2002-06-10": {
      "equity": 1069.9,
      "debt": 7.646,
      "gold": 5581.692799,
      "pb": 3.23,
      "pe": 16.51,
      "yield": 1.35,
      "date": "2002-06-10"
  },
  "2002-06-11": {
      "equity": 1097,
      "debt": 7.59,
      "gold": 5510.866153,
      "pb": 3.31,
      "pe": 16.93,
      "yield": 1.35,
      "date": "2002-06-11"
  },
  "2002-06-12": {
      "equity": 1092.8,
      "debt": 7.617,
      "gold": 5519.240198,
      "pb": 3.3,
      "pe": 16.87,
      "yield": 1.39,
      "date": "2002-06-12"
  },
  "2002-06-13": {
      "equity": 1082.8,
      "debt": 7.649,
      "gold": 5488.357819,
      "pb": 3.27,
      "pe": 16.72,
      "yield": 1.4,
      "date": "2002-06-13"
  },
  "2002-06-14": {
      "equity": 1085.7,
      "debt": 7.639,
      "gold": 5525.776469,
      "pb": 3.3,
      "pe": 16.74,
      "yield": 1.31,
      "date": "2002-06-14"
  },
  "2002-06-15": {
      "equity": 1085.7,
      "debt": 7.627,
      "gold": 5525.776469,
      "pb": 3.3,
      "pe": 16.74,
      "yield": 1.31,
      "date": "2002-06-15"
  },
  "2002-06-16": {
      "equity": 1085.7,
      "debt": 7.627,
      "gold": 5525.776469,
      "pb": 3.3,
      "pe": 16.74,
      "yield": 1.31,
      "date": "2002-06-16"
  },
  "2002-06-17": {
      "equity": 1088.9,
      "debt": 7.669,
      "gold": 5494.968165,
      "pb": 3.32,
      "pe": 16.8,
      "yield": 1.31,
      "date": "2002-06-17"
  },
  "2002-06-18": {
      "equity": 1074.9,
      "debt": 7.686,
      "gold": 5493.892309,
      "pb": 3.28,
      "pe": 16.58,
      "yield": 1.33,
      "date": "2002-06-18"
  },
  "2002-06-19": {
      "equity": 1062.9,
      "debt": 7.644,
      "gold": 5554.422477,
      "pb": 3.24,
      "pe": 16.4,
      "yield": 1.35,
      "date": "2002-06-19"
  },
  "2002-06-20": {
      "equity": 1070,
      "debt": 7.624,
      "gold": 5537.16644,
      "pb": 3.24,
      "pe": 15.86,
      "yield": 1.36,
      "date": "2002-06-20"
  },
  "2002-06-21": {
      "equity": 1062.5,
      "debt": 7.651,
      "gold": 5582.899169,
      "pb": 3.22,
      "pe": 15.75,
      "yield": 1.31,
      "date": "2002-06-21"
  },
  "2002-06-22": {
      "equity": 1062.5,
      "debt": 7.635,
      "gold": 5582.899169,
      "pb": 3.22,
      "pe": 15.75,
      "yield": 1.31,
      "date": "2002-06-22"
  },
  "2002-06-23": {
      "equity": 1062.5,
      "debt": 7.635,
      "gold": 5582.899169,
      "pb": 3.22,
      "pe": 15.75,
      "yield": 1.31,
      "date": "2002-06-23"
  },
  "2002-06-24": {
      "equity": 1061.8,
      "debt": 7.654,
      "gold": 5627.478439,
      "pb": 3.22,
      "pe": 15.74,
      "yield": 1.32,
      "date": "2002-06-24"
  },
  "2002-06-25": {
      "equity": 1055.4,
      "debt": 7.647,
      "gold": 5573.71735,
      "pb": 3.2,
      "pe": 15.64,
      "yield": 1.32,
      "date": "2002-06-25"
  },
  "2002-06-26": {
      "equity": 1044.2,
      "debt": 7.599,
      "gold": 5591.576571,
      "pb": 3.17,
      "pe": 15.48,
      "yield": 1.35,
      "date": "2002-06-26"
  },
  "2002-06-27": {
      "equity": 1048.5,
      "debt": 7.412,
      "gold": 5504.552109,
      "pb": 3.18,
      "pe": 15.56,
      "yield": 1.35,
      "date": "2002-06-27"
  },
  "2002-06-28": {
      "equity": 1057.8,
      "debt": 7.485,
      "gold": 5501.035292,
      "pb": 3.2,
      "pe": 15.73,
      "yield": 1.34,
      "date": "2002-06-28"
  },
  "2002-06-29": {
      "equity": 1057.8,
      "debt": 7.485,
      "gold": 5501.035292,
      "pb": 3.2,
      "pe": 15.73,
      "yield": 1.34,
      "date": "2002-06-29"
  },
  "2002-06-30": {
      "equity": 1057.8,
      "debt": 7.485,
      "gold": 5501.035292,
      "pb": 3.2,
      "pe": 15.73,
      "yield": 1.34,
      "date": "2002-06-30"
  },
  "2002-07-01": {
      "equity": 1068.9,
      "debt": 7.459,
      "gold": 5380.796839,
      "pb": 2.67,
      "pe": 15.9,
      "yield": 2.01,
      "date": "2002-07-01"
  },
  "2002-07-02": {
      "equity": 1068,
      "debt": 7.427,
      "gold": 5390.585372,
      "pb": 2.66,
      "pe": 15.88,
      "yield": 2.01,
      "date": "2002-07-02"
  },
  "2002-07-03": {
      "equity": 1069.9,
      "debt": 7.421,
      "gold": 5385.858657,
      "pb": 2.67,
      "pe": 15.91,
      "yield": 2.01,
      "date": "2002-07-03"
  },
  "2002-07-04": {
      "equity": 1070.5,
      "debt": 7.434,
      "gold": 5352.838674,
      "pb": 2.67,
      "pe": 15.92,
      "yield": 2.01,
      "date": "2002-07-04"
  },
  "2002-07-05": {
      "equity": 1073.8,
      "debt": 7.422,
      "gold": 5350.803365,
      "pb": 2.68,
      "pe": 15.97,
      "yield": 2,
      "date": "2002-07-05"
  },
  "2002-07-06": {
      "equity": 1073.8,
      "debt": 7.401,
      "gold": 5350.803365,
      "pb": 2.68,
      "pe": 15.97,
      "yield": 2,
      "date": "2002-07-06"
  },
  "2002-07-07": {
      "equity": 1073.8,
      "debt": 7.401,
      "gold": 5350.803365,
      "pb": 2.68,
      "pe": 15.97,
      "yield": 2,
      "date": "2002-07-07"
  },
  "2002-07-08": {
      "equity": 1082,
      "debt": 7.337,
      "gold": 5389.548317,
      "pb": 2.7,
      "pe": 16.09,
      "yield": 1.99,
      "date": "2002-07-08"
  },
  "2002-07-09": {
      "equity": 1080.3,
      "debt": 7.34,
      "gold": 5377.872626,
      "pb": 2.69,
      "pe": 16.07,
      "yield": 1.99,
      "date": "2002-07-09"
  },
  "2002-07-10": {
      "equity": 1071.7,
      "debt": 7.362,
      "gold": 5438.670876,
      "pb": 2.67,
      "pe": 15.94,
      "yield": 2.01,
      "date": "2002-07-10"
  },
  "2002-07-11": {
      "equity": 1056.6,
      "debt": 7.343,
      "gold": 5410.938465,
      "pb": 2.64,
      "pe": 15.71,
      "yield": 2.03,
      "date": "2002-07-11"
  },
  "2002-07-12": {
      "equity": 1058.2,
      "debt": 7.39,
      "gold": 5445.348242,
      "pb": 2.64,
      "pe": 15.74,
      "yield": 2.03,
      "date": "2002-07-12"
  },
  "2002-07-13": {
      "equity": 1058.2,
      "debt": 7.386,
      "gold": 5445.348242,
      "pb": 2.64,
      "pe": 15.74,
      "yield": 2.03,
      "date": "2002-07-13"
  },
  "2002-07-14": {
      "equity": 1058.2,
      "debt": 7.386,
      "gold": 5445.348242,
      "pb": 2.64,
      "pe": 15.74,
      "yield": 2.03,
      "date": "2002-07-14"
  },
  "2002-07-15": {
      "equity": 1048,
      "debt": 7.376,
      "gold": 5449.46119,
      "pb": 2.61,
      "pe": 15.59,
      "yield": 2.05,
      "date": "2002-07-15"
  },
  "2002-07-16": {
      "equity": 1035.9,
      "debt": 7.377,
      "gold": 5514.975573,
      "pb": 2.58,
      "pe": 15.41,
      "yield": 2.08,
      "date": "2002-07-16"
  },
  "2002-07-17": {
      "equity": 1032.5,
      "debt": 7.354,
      "gold": 5466.854795,
      "pb": 2.58,
      "pe": 15.36,
      "yield": 2.08,
      "date": "2002-07-17"
  },
  "2002-07-18": {
      "equity": 1041.3,
      "debt": 7.331,
      "gold": 5447.069613,
      "pb": 2.6,
      "pe": 15.49,
      "yield": 2.06,
      "date": "2002-07-18"
  },
  "2002-07-19": {
      "equity": 1035.9,
      "debt": 7.33,
      "gold": 5486.438914,
      "pb": 2.58,
      "pe": 15.41,
      "yield": 2.08,
      "date": "2002-07-19"
  },
  "2002-07-20": {
      "equity": 1035.9,
      "debt": 7.332,
      "gold": 5486.438914,
      "pb": 2.58,
      "pe": 15.41,
      "yield": 2.08,
      "date": "2002-07-20"
  },
  "2002-07-21": {
      "equity": 1035.9,
      "debt": 7.332,
      "gold": 5486.438914,
      "pb": 2.58,
      "pe": 15.41,
      "yield": 2.08,
      "date": "2002-07-21"
  },
  "2002-07-22": {
      "equity": 1012,
      "debt": 7.335,
      "gold": 5565.24101,
      "pb": 2.52,
      "pe": 15.05,
      "yield": 2.12,
      "date": "2002-07-22"
  },
  "2002-07-23": {
      "equity": 1021.9,
      "debt": 7.338,
      "gold": 5501.123477,
      "pb": 2.55,
      "pe": 15.2,
      "yield": 2.1,
      "date": "2002-07-23"
  },
  "2002-07-24": {
      "equity": 1004,
      "debt": 7.332,
      "gold": 5385.498862,
      "pb": 2.5,
      "pe": 14.93,
      "yield": 2.14,
      "date": "2002-07-24"
  },
  "2002-07-25": {
      "equity": 1001.5,
      "debt": 7.332,
      "gold": 5325.779996,
      "pb": 2.5,
      "pe": 14.9,
      "yield": 2.15,
      "date": "2002-07-25"
  },
  "2002-07-26": {
      "equity": 973.5,
      "debt": 7.3,
      "gold": 5299.631387,
      "pb": 2.43,
      "pe": 14.48,
      "yield": 2.21,
      "date": "2002-07-26"
  },
  "2002-07-27": {
      "equity": 973.5,
      "debt": 7.272,
      "gold": 5299.631387,
      "pb": 2.43,
      "pe": 14.48,
      "yield": 2.21,
      "date": "2002-07-27"
  },
  "2002-07-28": {
      "equity": 973.5,
      "debt": 7.272,
      "gold": 5299.631387,
      "pb": 2.43,
      "pe": 14.48,
      "yield": 2.21,
      "date": "2002-07-28"
  },
  "2002-07-29": {
      "equity": 971.65,
      "debt": 7.322,
      "gold": 5211.27004,
      "pb": 2.42,
      "pe": 14.45,
      "yield": 2.21,
      "date": "2002-07-29"
  },
  "2002-07-30": {
      "equity": 960.65,
      "debt": 7.318,
      "gold": 5231.584331,
      "pb": 2.4,
      "pe": 14.29,
      "yield": 2.24,
      "date": "2002-07-30"
  },
  "2002-07-31": {
      "equity": 958.9,
      "debt": 7.308,
      "gold": 5243.700947,
      "pb": 2.39,
      "pe": 14.26,
      "yield": 2.24,
      "date": "2002-07-31"
  },
  "2002-08-01": {
      "equity": 957.7,
      "debt": 7.28,
      "gold": 5159.925219,
      "pb": 2.39,
      "pe": 14.24,
      "yield": 2.25,
      "date": "2002-08-01"
  },
  "2002-08-02": {
      "equity": 954.75,
      "debt": 7.264,
      "gold": 5260.325579,
      "pb": 2.38,
      "pe": 14.2,
      "yield": 2.25,
      "date": "2002-08-02"
  },
  "2002-08-03": {
      "equity": 954.75,
      "debt": 7.224,
      "gold": 5260.325579,
      "pb": 2.38,
      "pe": 14.2,
      "yield": 2.25,
      "date": "2002-08-03"
  },
  "2002-08-04": {
      "equity": 954.75,
      "debt": 7.224,
      "gold": 5260.325579,
      "pb": 2.38,
      "pe": 14.2,
      "yield": 2.25,
      "date": "2002-08-04"
  },
  "2002-08-05": {
      "equity": 963.25,
      "debt": 7.195,
      "gold": 5277.57456,
      "pb": 2.4,
      "pe": 14.33,
      "yield": 2.23,
      "date": "2002-08-05"
  },
  "2002-08-06": {
      "equity": 966.65,
      "debt": 7.201,
      "gold": 5270.565618,
      "pb": 2.41,
      "pe": 14.38,
      "yield": 2.22,
      "date": "2002-08-06"
  },
  "2002-08-07": {
      "equity": 969.1,
      "debt": 7.167,
      "gold": 5283.179598,
      "pb": 2.42,
      "pe": 14.41,
      "yield": 2.22,
      "date": "2002-08-07"
  },
  "2002-08-08": {
      "equity": 953.55,
      "debt": 7.184,
      "gold": 5371.576218,
      "pb": 2.88,
      "pe": 14.19,
      "yield": 2.17,
      "date": "2002-08-08"
  },
  "2002-08-09": {
      "equity": 961.95,
      "debt": 7.228,
      "gold": 5312.435845,
      "pb": 2.9,
      "pe": 14.31,
      "yield": 2.15,
      "date": "2002-08-09"
  },
  "2002-08-10": {
      "equity": 961.95,
      "debt": 7.221,
      "gold": 5312.435845,
      "pb": 2.9,
      "pe": 14.31,
      "yield": 2.15,
      "date": "2002-08-10"
  },
  "2002-08-11": {
      "equity": 961.95,
      "debt": 7.221,
      "gold": 5312.435845,
      "pb": 2.9,
      "pe": 14.31,
      "yield": 2.15,
      "date": "2002-08-11"
  },
  "2002-08-12": {
      "equity": 969.85,
      "debt": 7.195,
      "gold": 5423.196176,
      "pb": 2.93,
      "pe": 14.43,
      "yield": 2.13,
      "date": "2002-08-12"
  },
  "2002-08-13": {
      "equity": 976.05,
      "debt": 7.199,
      "gold": 5395.506094,
      "pb": 2.95,
      "pe": 14.52,
      "yield": 2.12,
      "date": "2002-08-13"
  },
  "2002-08-14": {
      "equity": 969.65,
      "debt": 7.215,
      "gold": 5421.524189,
      "pb": 2.93,
      "pe": 14.43,
      "yield": 2.16,
      "date": "2002-08-14"
  },
  "2002-08-15": {
      "equity": 969.65,
      "debt": 7.215,
      "gold": 5361.216247,
      "pb": 2.93,
      "pe": 14.43,
      "yield": 2.16,
      "date": "2002-08-15"
  },
  "2002-08-16": {
      "equity": 979.25,
      "debt": 7.202,
      "gold": 5379.622216,
      "pb": 2.96,
      "pe": 14.57,
      "yield": 2.16,
      "date": "2002-08-16"
  },
  "2002-08-17": {
      "equity": 979.25,
      "debt": 7.192,
      "gold": 5379.622216,
      "pb": 2.96,
      "pe": 14.57,
      "yield": 2.16,
      "date": "2002-08-17"
  },
  "2002-08-18": {
      "equity": 979.25,
      "debt": 7.192,
      "gold": 5379.622216,
      "pb": 2.96,
      "pe": 14.57,
      "yield": 2.16,
      "date": "2002-08-18"
  },
  "2002-08-19": {
      "equity": 979.85,
      "debt": 7.176,
      "gold": 5365.286866,
      "pb": 2.96,
      "pe": 14.58,
      "yield": 2.16,
      "date": "2002-08-19"
  },
  "2002-08-20": {
      "equity": 988.55,
      "debt": 7.168,
      "gold": 5271.066509,
      "pb": 2.99,
      "pe": 14.71,
      "yield": 2.14,
      "date": "2002-08-20"
  },
  "2002-08-21": {
      "equity": 988.45,
      "debt": 7.178,
      "gold": 5279.024321,
      "pb": 2.98,
      "pe": 14.71,
      "yield": 2.14,
      "date": "2002-08-21"
  },
  "2002-08-22": {
      "equity": 985.7,
      "debt": 7.177,
      "gold": 5247.577559,
      "pb": 2.98,
      "pe": 14.66,
      "yield": 2.04,
      "date": "2002-08-22"
  },
  "2002-08-23": {
      "equity": 995.2,
      "debt": 7.18,
      "gold": 5236.445087,
      "pb": 3.01,
      "pe": 14.81,
      "yield": 2.02,
      "date": "2002-08-23"
  },
  "2002-08-24": {
      "equity": 995.2,
      "debt": 7.176,
      "gold": 5236.445087,
      "pb": 3.01,
      "pe": 14.81,
      "yield": 2.02,
      "date": "2002-08-24"
  },
  "2002-08-25": {
      "equity": 995.2,
      "debt": 7.176,
      "gold": 5236.445087,
      "pb": 3.01,
      "pe": 14.81,
      "yield": 2.02,
      "date": "2002-08-25"
  },
  "2002-08-26": {
      "equity": 998.85,
      "debt": 7.176,
      "gold": 5236.445087,
      "pb": 3.02,
      "pe": 14.86,
      "yield": 1.98,
      "date": "2002-08-26"
  },
  "2002-08-27": {
      "equity": 987.7,
      "debt": 7.174,
      "gold": 5293.726521,
      "pb": 2.99,
      "pe": 14.7,
      "yield": 2.01,
      "date": "2002-08-27"
  },
  "2002-08-28": {
      "equity": 985.7,
      "debt": 7.172,
      "gold": 5318.051465,
      "pb": 2.98,
      "pe": 14.67,
      "yield": 2.01,
      "date": "2002-08-28"
  },
  "2002-08-29": {
      "equity": 987.25,
      "debt": 7.151,
      "gold": 5316.206635,
      "pb": 2.99,
      "pe": 14.69,
      "yield": 2.02,
      "date": "2002-08-29"
  },
  "2002-08-30": {
      "equity": 1010.6,
      "debt": 7.159,
      "gold": 5345.053705,
      "pb": 3.06,
      "pe": 15.04,
      "yield": 1.95,
      "date": "2002-08-30"
  },
  "2002-08-31": {
      "equity": 1010.6,
      "debt": 7.161,
      "gold": 5345.053705,
      "pb": 3.06,
      "pe": 15.04,
      "yield": 1.95,
      "date": "2002-08-31"
  },
  "2002-09-01": {
      "equity": 1010.6,
      "debt": 7.161,
      "gold": 5345.053705,
      "pb": 3.06,
      "pe": 15.04,
      "yield": 1.95,
      "date": "2002-09-01"
  },
  "2002-09-02": {
      "equity": 1013.5,
      "debt": 7.173,
      "gold": 5339.184113,
      "pb": 3.07,
      "pe": 15.08,
      "yield": 1.95,
      "date": "2002-09-02"
  },
  "2002-09-03": {
      "equity": 1001.1,
      "debt": 7.161,
      "gold": 5360.006349,
      "pb": 3.03,
      "pe": 14.9,
      "yield": 1.97,
      "date": "2002-09-03"
  },
  "2002-09-04": {
      "equity": 1006.9,
      "debt": 7.14,
      "gold": 5344.122471,
      "pb": 3.05,
      "pe": 14.98,
      "yield": 1.94,
      "date": "2002-09-04"
  },
  "2002-09-05": {
      "equity": 1008.6,
      "debt": 7.13,
      "gold": 5406.72675,
      "pb": 3.05,
      "pe": 15.01,
      "yield": 1.97,
      "date": "2002-09-05"
  },
  "2002-09-06": {
      "equity": 995.2,
      "debt": 7.177,
      "gold": 5448.127833,
      "pb": 3.01,
      "pe": 14.81,
      "yield": 1.96,
      "date": "2002-09-06"
  },
  "2002-09-07": {
      "equity": 995.2,
      "debt": 7.177,
      "gold": 5448.127833,
      "pb": 3.01,
      "pe": 14.81,
      "yield": 1.96,
      "date": "2002-09-07"
  },
  "2002-09-08": {
      "equity": 995.2,
      "debt": 7.177,
      "gold": 5448.127833,
      "pb": 3.01,
      "pe": 14.81,
      "yield": 1.96,
      "date": "2002-09-08"
  },
  "2002-09-09": {
      "equity": 998.55,
      "debt": 7.183,
      "gold": 5511.592797,
      "pb": 3.02,
      "pe": 14.86,
      "yield": 1.84,
      "date": "2002-09-09"
  },
  "2002-09-10": {
      "equity": 998.55,
      "debt": 7.183,
      "gold": 5444.611016,
      "pb": 3.02,
      "pe": 14.86,
      "yield": 1.84,
      "date": "2002-09-10"
  },
  "2002-09-11": {
      "equity": 998.85,
      "debt": 7.17,
      "gold": 5420.173195,
      "pb": 3.02,
      "pe": 14.86,
      "yield": 1.84,
      "date": "2002-09-11"
  },
  "2002-09-12": {
      "equity": 1001.6,
      "debt": 7.163,
      "gold": 5435.259881,
      "pb": 2.5,
      "pe": 14.9,
      "yield": 2.15,
      "date": "2002-09-12"
  },
  "2002-09-13": {
      "equity": 992,
      "debt": 7.167,
      "gold": 5439.873719,
      "pb": 2.48,
      "pe": 14.76,
      "yield": 2.17,
      "date": "2002-09-13"
  },
  "2002-09-14": {
      "equity": 992,
      "debt": 7.175,
      "gold": 5439.873719,
      "pb": 2.48,
      "pe": 14.76,
      "yield": 2.17,
      "date": "2002-09-14"
  },
  "2002-09-15": {
      "equity": 992,
      "debt": 7.175,
      "gold": 5439.873719,
      "pb": 2.48,
      "pe": 14.76,
      "yield": 2.17,
      "date": "2002-09-15"
  },
  "2002-09-16": {
      "equity": 985.75,
      "debt": 7.191,
      "gold": 5405.453359,
      "pb": 2.46,
      "pe": 14.67,
      "yield": 2.18,
      "date": "2002-09-16"
  },
  "2002-09-17": {
      "equity": 994.9,
      "debt": 7.167,
      "gold": 5383.551738,
      "pb": 2.48,
      "pe": 14.8,
      "yield": 2.16,
      "date": "2002-09-17"
  },
  "2002-09-18": {
      "equity": 983.6,
      "debt": 7.164,
      "gold": 5422.822272,
      "pb": 2.45,
      "pe": 14.63,
      "yield": 2.18,
      "date": "2002-09-18"
  },
  "2002-09-19": {
      "equity": 976.05,
      "debt": 7.164,
      "gold": 5467.489726,
      "pb": 2.44,
      "pe": 14.52,
      "yield": 2.2,
      "date": "2002-09-19"
  },
  "2002-09-20": {
      "equity": 969.6,
      "debt": 7.175,
      "gold": 5490.266142,
      "pb": 2.42,
      "pe": 14.43,
      "yield": 2.22,
      "date": "2002-09-20"
  },
  "2002-09-21": {
      "equity": 969.6,
      "debt": 7.168,
      "gold": 5490.266142,
      "pb": 2.42,
      "pe": 14.43,
      "yield": 2.22,
      "date": "2002-09-21"
  },
  "2002-09-22": {
      "equity": 969.6,
      "debt": 7.168,
      "gold": 5490.266142,
      "pb": 2.42,
      "pe": 14.43,
      "yield": 2.22,
      "date": "2002-09-22"
  },
  "2002-09-23": {
      "equity": 970.3,
      "debt": 7.171,
      "gold": 5520.996843,
      "pb": 2.42,
      "pe": 14.44,
      "yield": 2.21,
      "date": "2002-09-23"
  },
  "2002-09-24": {
      "equity": 966.2,
      "debt": 7.177,
      "gold": 5518.435951,
      "pb": 2.41,
      "pe": 14.38,
      "yield": 2.22,
      "date": "2002-09-24"
  },
  "2002-09-25": {
      "equity": 970.05,
      "debt": 7.187,
      "gold": 5553.44186,
      "pb": 2.42,
      "pe": 14.43,
      "yield": 2.22,
      "date": "2002-09-25"
  },
  "2002-09-26": {
      "equity": 969.9,
      "debt": 7.18,
      "gold": 5502.220498,
      "pb": 2.42,
      "pe": 14.43,
      "yield": 2.22,
      "date": "2002-09-26"
  },
  "2002-09-27": {
      "equity": 976.45,
      "debt": 7.165,
      "gold": 5462.974656,
      "pb": 2.44,
      "pe": 14.53,
      "yield": 2.2,
      "date": "2002-09-27"
  },
  "2002-09-28": {
      "equity": 976.45,
      "debt": 7.168,
      "gold": 5462.974656,
      "pb": 2.44,
      "pe": 14.53,
      "yield": 2.2,
      "date": "2002-09-28"
  },
  "2002-09-29": {
      "equity": 976.45,
      "debt": 7.168,
      "gold": 5462.974656,
      "pb": 2.44,
      "pe": 14.53,
      "yield": 2.2,
      "date": "2002-09-29"
  },
  "2002-09-30": {
      "equity": 963.15,
      "debt": 7.168,
      "gold": 5501.366867,
      "pb": 2.4,
      "pe": 14.33,
      "yield": 2.23,
      "date": "2002-09-30"
  },
  "2002-10-01": {
      "equity": 955.2,
      "debt": 7.143,
      "gold": 5501.934778,
      "pb": 2.38,
      "pe": 14.21,
      "yield": 2.25,
      "date": "2002-10-01"
  },
  "2002-10-02": {
      "equity": 955.2,
      "debt": 7.143,
      "gold": 5463.849451,
      "pb": 2.38,
      "pe": 14.21,
      "yield": 2.25,
      "date": "2002-10-02"
  },
  "2002-10-03": {
      "equity": 948.2,
      "debt": 7.135,
      "gold": 5504.777862,
      "pb": 2.37,
      "pe": 14.11,
      "yield": 2.27,
      "date": "2002-10-03"
  },
  "2002-10-04": {
      "equity": 948.2,
      "debt": 7.125,
      "gold": 5486.86573,
      "pb": 2.37,
      "pe": 14.11,
      "yield": 2.27,
      "date": "2002-10-04"
  },
  "2002-10-05": {
      "equity": 948.2,
      "debt": 7.127,
      "gold": 5486.86573,
      "pb": 2.37,
      "pe": 14.11,
      "yield": 2.27,
      "date": "2002-10-05"
  },
  "2002-10-06": {
      "equity": 948.2,
      "debt": 7.127,
      "gold": 5486.86573,
      "pb": 2.37,
      "pe": 14.11,
      "yield": 2.27,
      "date": "2002-10-06"
  },
  "2002-10-07": {
      "equity": 954.75,
      "debt": 7.107,
      "gold": 5499.377414,
      "pb": 2.38,
      "pe": 14.21,
      "yield": 2.25,
      "date": "2002-10-07"
  },
  "2002-10-08": {
      "equity": 960.8,
      "debt": 7.09,
      "gold": 5470.660858,
      "pb": 2.4,
      "pe": 14.3,
      "yield": 2.24,
      "date": "2002-10-08"
  },
  "2002-10-09": {
      "equity": 954.75,
      "debt": 7.094,
      "gold": 5433.189298,
      "pb": 2.38,
      "pe": 14.21,
      "yield": 2.25,
      "date": "2002-10-09"
  },
  "2002-10-10": {
      "equity": 958.45,
      "debt": 7.087,
      "gold": 5449.066121,
      "pb": 2.38,
      "pe": 14.22,
      "yield": 2.25,
      "date": "2002-10-10"
  },
  "2002-10-11": {
      "equity": 971.05,
      "debt": 7.087,
      "gold": 5405.894284,
      "pb": 2.42,
      "pe": 14.4,
      "yield": 2.22,
      "date": "2002-10-11"
  },
  "2002-10-12": {
      "equity": 971.05,
      "debt": 7.078,
      "gold": 5405.894284,
      "pb": 2.42,
      "pe": 14.4,
      "yield": 2.22,
      "date": "2002-10-12"
  },
  "2002-10-13": {
      "equity": 971.05,
      "debt": 7.078,
      "gold": 5405.894284,
      "pb": 2.42,
      "pe": 14.4,
      "yield": 2.22,
      "date": "2002-10-13"
  },
  "2002-10-14": {
      "equity": 972.45,
      "debt": 7.083,
      "gold": 5421.799326,
      "pb": 2.42,
      "pe": 14.42,
      "yield": 2.22,
      "date": "2002-10-14"
  },
  "2002-10-15": {
      "equity": 972.45,
      "debt": 7.083,
      "gold": 5411.033704,
      "pb": 2.42,
      "pe": 14.42,
      "yield": 2.22,
      "date": "2002-10-15"
  },
  "2002-10-16": {
      "equity": 973.6,
      "debt": 7.085,
      "gold": 5359.692411,
      "pb": 2.42,
      "pe": 14.44,
      "yield": 2.21,
      "date": "2002-10-16"
  },
  "2002-10-17": {
      "equity": 973.3,
      "debt": 7.071,
      "gold": 5339.762606,
      "pb": 2.42,
      "pe": 14.44,
      "yield": 2.21,
      "date": "2002-10-17"
  },
  "2002-10-18": {
      "equity": 971.65,
      "debt": 7.035,
      "gold": 5332.683116,
      "pb": 2.42,
      "pe": 14.41,
      "yield": 2.22,
      "date": "2002-10-18"
  },
  "2002-10-19": {
      "equity": 971.65,
      "debt": 7.034,
      "gold": 5332.683116,
      "pb": 2.42,
      "pe": 14.41,
      "yield": 2.22,
      "date": "2002-10-19"
  },
  "2002-10-20": {
      "equity": 971.65,
      "debt": 7.034,
      "gold": 5332.683116,
      "pb": 2.42,
      "pe": 14.41,
      "yield": 2.22,
      "date": "2002-10-20"
  },
  "2002-10-21": {
      "equity": 967.35,
      "debt": 7.039,
      "gold": 5345.029013,
      "pb": 2.41,
      "pe": 14.35,
      "yield": 2.23,
      "date": "2002-10-21"
  },
  "2002-10-22": {
      "equity": 962.5,
      "debt": 7.049,
      "gold": 5317.381259,
      "pb": 2.39,
      "pe": 14.28,
      "yield": 2.24,
      "date": "2002-10-22"
  },
  "2002-10-23": {
      "equity": 957.35,
      "debt": 7.049,
      "gold": 5349.452371,
      "pb": 2.38,
      "pe": 14.2,
      "yield": 2.25,
      "date": "2002-10-23"
  },
  "2002-10-24": {
      "equity": 946.9,
      "debt": 7.051,
      "gold": 5310.19242,
      "pb": 2.36,
      "pe": 14.05,
      "yield": 2.28,
      "date": "2002-10-24"
  },
  "2002-10-25": {
      "equity": 932.2,
      "debt": 7.059,
      "gold": 5327.370853,
      "pb": 2.32,
      "pe": 13.83,
      "yield": 2.31,
      "date": "2002-10-25"
  },
  "2002-10-26": {
      "equity": 932.2,
      "debt": 7.054,
      "gold": 5327.370853,
      "pb": 2.32,
      "pe": 13.83,
      "yield": 2.31,
      "date": "2002-10-26"
  },
  "2002-10-27": {
      "equity": 932.2,
      "debt": 7.054,
      "gold": 5327.370853,
      "pb": 2.32,
      "pe": 13.83,
      "yield": 2.31,
      "date": "2002-10-27"
  },
  "2002-10-28": {
      "equity": 922.7,
      "debt": 7.056,
      "gold": 5352.164941,
      "pb": 2.35,
      "pe": 14.03,
      "yield": 2.28,
      "date": "2002-10-28"
  },
  "2002-10-29": {
      "equity": 936.9,
      "debt": 6.978,
      "gold": 5397.573149,
      "pb": 2.39,
      "pe": 14.25,
      "yield": 2.24,
      "date": "2002-10-29"
  },
  "2002-10-30": {
      "equity": 937.75,
      "debt": 6.932,
      "gold": 5405.809626,
      "pb": 2.39,
      "pe": 14.26,
      "yield": 2.24,
      "date": "2002-10-30"
  },
  "2002-10-31": {
      "equity": 951.4,
      "debt": 6.925,
      "gold": 5396.458491,
      "pb": 2.43,
      "pe": 14.47,
      "yield": 2.21,
      "date": "2002-10-31"
  },
  "2002-11-01": {
      "equity": 951.45,
      "debt": 6.92,
      "gold": 5435.150532,
      "pb": 2.43,
      "pe": 14.47,
      "yield": 2.21,
      "date": "2002-11-01"
  },
  "2002-11-02": {
      "equity": 951.45,
      "debt": 6.87,
      "gold": 5435.150532,
      "pb": 2.43,
      "pe": 14.47,
      "yield": 2.21,
      "date": "2002-11-02"
  },
  "2002-11-03": {
      "equity": 951.45,
      "debt": 6.87,
      "gold": 5435.150532,
      "pb": 2.43,
      "pe": 14.47,
      "yield": 2.21,
      "date": "2002-11-03"
  },
  "2002-11-04": {
      "equity": 962.1,
      "debt": 6.87,
      "gold": 5408.158874,
      "pb": 2.45,
      "pe": 14.63,
      "yield": 2.19,
      "date": "2002-11-04"
  },
  "2002-11-05": {
      "equity": 962.3,
      "debt": 6.863,
      "gold": 5436.018272,
      "pb": 2.45,
      "pe": 14.63,
      "yield": 2.19,
      "date": "2002-11-05"
  },
  "2002-11-06": {
      "equity": 962.3,
      "debt": 6.863,
      "gold": 5412.716274,
      "pb": 2.45,
      "pe": 14.63,
      "yield": 2.19,
      "date": "2002-11-06"
  },
  "2002-11-07": {
      "equity": 960.7,
      "debt": 6.813,
      "gold": 5445.708037,
      "pb": 2.45,
      "pe": 14.61,
      "yield": 2.19,
      "date": "2002-11-07"
  },
  "2002-11-08": {
      "equity": 956.95,
      "debt": 6.83,
      "gold": 5491.980458,
      "pb": 2.44,
      "pe": 14.55,
      "yield": 2.2,
      "date": "2002-11-08"
  },
  "2002-11-09": {
      "equity": 956.95,
      "debt": 6.832,
      "gold": 5491.980458,
      "pb": 2.44,
      "pe": 14.55,
      "yield": 2.2,
      "date": "2002-11-09"
  },
  "2002-11-10": {
      "equity": 956.95,
      "debt": 6.832,
      "gold": 5491.980458,
      "pb": 2.44,
      "pe": 14.55,
      "yield": 2.2,
      "date": "2002-11-10"
  },
  "2002-11-11": {
      "equity": 954.05,
      "debt": 6.803,
      "gold": 5462.1951,
      "pb": 2.43,
      "pe": 14.51,
      "yield": 2.2,
      "date": "2002-11-11"
  },
  "2002-11-12": {
      "equity": 959.85,
      "debt": 6.794,
      "gold": 5481.479391,
      "pb": 2.45,
      "pe": 14.6,
      "yield": 2.19,
      "date": "2002-11-12"
  },
  "2002-11-13": {
      "equity": 962.65,
      "debt": 6.763,
      "gold": 5504.188786,
      "pb": 2.46,
      "pe": 14.64,
      "yield": 2.18,
      "date": "2002-11-13"
  },
  "2002-11-14": {
      "equity": 971.9,
      "debt": 6.681,
      "gold": 5423.037443,
      "pb": 2.48,
      "pe": 14.78,
      "yield": 2.16,
      "date": "2002-11-14"
  },
  "2002-11-15": {
      "equity": 990.35,
      "debt": 6.623,
      "gold": 5415.439426,
      "pb": 2.53,
      "pe": 15.06,
      "yield": 2.12,
      "date": "2002-11-15"
  },
  "2002-11-16": {
      "equity": 990.35,
      "debt": 6.618,
      "gold": 5415.439426,
      "pb": 2.53,
      "pe": 15.06,
      "yield": 2.12,
      "date": "2002-11-16"
  },
  "2002-11-17": {
      "equity": 990.35,
      "debt": 6.618,
      "gold": 5415.439426,
      "pb": 2.53,
      "pe": 15.06,
      "yield": 2.12,
      "date": "2002-11-17"
  },
  "2002-11-18": {
      "equity": 996.85,
      "debt": 6.597,
      "gold": 5437.8243,
      "pb": 2.54,
      "pe": 15.16,
      "yield": 2.11,
      "date": "2002-11-18"
  },
  "2002-11-19": {
      "equity": 996.85,
      "debt": 6.597,
      "gold": 5436.695533,
      "pb": 2.54,
      "pe": 15.16,
      "yield": 2.11,
      "date": "2002-11-19"
  },
  "2002-11-20": {
      "equity": 1001.6,
      "debt": 6.587,
      "gold": 5425.34789,
      "pb": 2.55,
      "pe": 15.23,
      "yield": 2.1,
      "date": "2002-11-20"
  },
  "2002-11-21": {
      "equity": 1008.7,
      "debt": 6.531,
      "gold": 5411.770931,
      "pb": 2.57,
      "pe": 15.34,
      "yield": 2.08,
      "date": "2002-11-21"
  },
  "2002-11-22": {
      "equity": 1020.1,
      "debt": 6.441,
      "gold": 5409.157128,
      "pb": 2.23,
      "pe": 13.84,
      "yield": 2.57,
      "date": "2002-11-22"
  },
  "2002-11-23": {
      "equity": 1020.1,
      "debt": 6.404,
      "gold": 5409.157128,
      "pb": 2.23,
      "pe": 13.84,
      "yield": 2.57,
      "date": "2002-11-23"
  },
  "2002-11-24": {
      "equity": 1020.1,
      "debt": 6.404,
      "gold": 5409.157128,
      "pb": 2.23,
      "pe": 13.84,
      "yield": 2.57,
      "date": "2002-11-24"
  },
  "2002-11-25": {
      "equity": 1026.2,
      "debt": 6.424,
      "gold": 5432.159297,
      "pb": 2.24,
      "pe": 13.92,
      "yield": 2.55,
      "date": "2002-11-25"
  },
  "2002-11-26": {
      "equity": 1036.1,
      "debt": 6.409,
      "gold": 5407.217059,
      "pb": 2.27,
      "pe": 14.05,
      "yield": 2.53,
      "date": "2002-11-26"
  },
  "2002-11-27": {
      "equity": 1031.1,
      "debt": 6.372,
      "gold": 5413.986137,
      "pb": 2.26,
      "pe": 13.98,
      "yield": 2.54,
      "date": "2002-11-27"
  },
  "2002-11-28": {
      "equity": 1049.7,
      "debt": 6.369,
      "gold": 5403.149967,
      "pb": 2.3,
      "pe": 14.24,
      "yield": 2.5,
      "date": "2002-11-28"
  },
  "2002-11-29": {
      "equity": 1050.1,
      "debt": 6.454,
      "gold": 5420.448332,
      "pb": 2.3,
      "pe": 14.24,
      "yield": 2.49,
      "date": "2002-11-29"
  },
  "2002-11-30": {
      "equity": 1050.1,
      "debt": 6.425,
      "gold": 5420.448332,
      "pb": 2.3,
      "pe": 14.24,
      "yield": 2.49,
      "date": "2002-11-30"
  },
  "2002-12-01": {
      "equity": 1050.1,
      "debt": 6.425,
      "gold": 5420.448332,
      "pb": 2.3,
      "pe": 14.24,
      "yield": 2.49,
      "date": "2002-12-01"
  },
  "2002-12-02": {
      "equity": 1067.9,
      "debt": 6.412,
      "gold": 5404.268153,
      "pb": 2.34,
      "pe": 14.48,
      "yield": 2.45,
      "date": "2002-12-02"
  },
  "2002-12-03": {
      "equity": 1055,
      "debt": 6.388,
      "gold": 5407.717949,
      "pb": 2.31,
      "pe": 14.31,
      "yield": 2.48,
      "date": "2002-12-03"
  },
  "2002-12-04": {
      "equity": 1036.4,
      "debt": 6.367,
      "gold": 5453.126157,
      "pb": 2.27,
      "pe": 14.06,
      "yield": 2.53,
      "date": "2002-12-04"
  },
  "2002-12-05": {
      "equity": 1045.9,
      "debt": 6.37,
      "gold": 5480.340041,
      "pb": 2.29,
      "pe": 14.19,
      "yield": 2.5,
      "date": "2002-12-05"
  },
  "2002-12-06": {
      "equity": 1069.8,
      "debt": 6.376,
      "gold": 5533.639041,
      "pb": 2.34,
      "pe": 14.51,
      "yield": 2.45,
      "date": "2002-12-06"
  },
  "2002-12-07": {
      "equity": 1069.8,
      "debt": 6.376,
      "gold": 5533.639041,
      "pb": 2.34,
      "pe": 14.51,
      "yield": 2.45,
      "date": "2002-12-07"
  },
  "2002-12-08": {
      "equity": 1069.8,
      "debt": 6.376,
      "gold": 5533.639041,
      "pb": 2.34,
      "pe": 14.51,
      "yield": 2.45,
      "date": "2002-12-08"
  },
  "2002-12-09": {
      "equity": 1058.6,
      "debt": 6.396,
      "gold": 5531.346232,
      "pb": 2.32,
      "pe": 14.36,
      "yield": 2.47,
      "date": "2002-12-09"
  },
  "2002-12-10": {
      "equity": 1063.7,
      "debt": 6.415,
      "gold": 5541.247641,
      "pb": 2.33,
      "pe": 14.43,
      "yield": 2.46,
      "date": "2002-12-10"
  },
  "2002-12-11": {
      "equity": 1069.7,
      "debt": 6.408,
      "gold": 5499.278647,
      "pb": 2.34,
      "pe": 14.51,
      "yield": 2.45,
      "date": "2002-12-11"
  },
  "2002-12-12": {
      "equity": 1077,
      "debt": 6.376,
      "gold": 5543.498122,
      "pb": 2.36,
      "pe": 14.61,
      "yield": 2.43,
      "date": "2002-12-12"
  },
  "2002-12-13": {
      "equity": 1086.2,
      "debt": 6.362,
      "gold": 5668.424487,
      "pb": 2.38,
      "pe": 14.73,
      "yield": 2.41,
      "date": "2002-12-13"
  },
  "2002-12-14": {
      "equity": 1086.2,
      "debt": 6.36,
      "gold": 5668.424487,
      "pb": 2.38,
      "pe": 14.73,
      "yield": 2.41,
      "date": "2002-12-14"
  },
  "2002-12-15": {
      "equity": 1086.2,
      "debt": 6.36,
      "gold": 5668.424487,
      "pb": 2.38,
      "pe": 14.73,
      "yield": 2.41,
      "date": "2002-12-15"
  },
  "2002-12-16": {
      "equity": 1078.4,
      "debt": 6.34,
      "gold": 5657.052153,
      "pb": 2.36,
      "pe": 14.63,
      "yield": 2.43,
      "date": "2002-12-16"
  },
  "2002-12-17": {
      "equity": 1073.2,
      "debt": 6.323,
      "gold": 5788.684104,
      "pb": 2.35,
      "pe": 14.56,
      "yield": 2.44,
      "date": "2002-12-17"
  },
  "2002-12-18": {
      "equity": 1077.9,
      "debt": 6.333,
      "gold": 5720.439514,
      "pb": 2.36,
      "pe": 14.62,
      "yield": 2.43,
      "date": "2002-12-18"
  },
  "2002-12-19": {
      "equity": 1076,
      "debt": 6.33,
      "gold": 5864.438526,
      "pb": 2.35,
      "pe": 14.59,
      "yield": 2.43,
      "date": "2002-12-19"
  },
  "2002-12-20": {
      "equity": 1079.3,
      "debt": 6.312,
      "gold": 5808.596272,
      "pb": 2.36,
      "pe": 14.64,
      "yield": 2.43,
      "date": "2002-12-20"
  },
  "2002-12-21": {
      "equity": 1079.3,
      "debt": 6.277,
      "gold": 5808.596272,
      "pb": 2.36,
      "pe": 14.64,
      "yield": 2.43,
      "date": "2002-12-21"
  },
  "2002-12-22": {
      "equity": 1079.3,
      "debt": 6.277,
      "gold": 5808.596272,
      "pb": 2.36,
      "pe": 14.64,
      "yield": 2.43,
      "date": "2002-12-22"
  },
  "2002-12-23": {
      "equity": 1076,
      "debt": 6.297,
      "gold": 5824.568335,
      "pb": 2.35,
      "pe": 14.59,
      "yield": 2.43,
      "date": "2002-12-23"
  },
  "2002-12-24": {
      "equity": 1085,
      "debt": 6.281,
      "gold": 5843.200056,
      "pb": 2.37,
      "pe": 14.72,
      "yield": 2.41,
      "date": "2002-12-24"
  },
  "2002-12-25": {
      "equity": 1085,
      "debt": 6.281,
      "gold": 5843.200056,
      "pb": 2.37,
      "pe": 14.72,
      "yield": 2.41,
      "date": "2002-12-25"
  },
  "2002-12-26": {
      "equity": 1094.8,
      "debt": 6.253,
      "gold": 5843.200056,
      "pb": 2.39,
      "pe": 14.85,
      "yield": 2.39,
      "date": "2002-12-26"
  },
  "2002-12-27": {
      "equity": 1098.4,
      "debt": 6.215,
      "gold": 5894.1745,
      "pb": 2.4,
      "pe": 14.9,
      "yield": 2.39,
      "date": "2002-12-27"
  },
  "2002-12-28": {
      "equity": 1098.4,
      "debt": 6.207,
      "gold": 5894.1745,
      "pb": 2.4,
      "pe": 14.9,
      "yield": 2.39,
      "date": "2002-12-28"
  },
  "2002-12-29": {
      "equity": 1098.4,
      "debt": 6.207,
      "gold": 5894.1745,
      "pb": 2.4,
      "pe": 14.9,
      "yield": 2.39,
      "date": "2002-12-29"
  },
  "2002-12-30": {
      "equity": 1091.9,
      "debt": 6.112,
      "gold": 5892.643609,
      "pb": 2.39,
      "pe": 14.81,
      "yield": 2.4,
      "date": "2002-12-30"
  },
  "2002-12-31": {
      "equity": 1093.5,
      "debt": 6.083,
      "gold": 5800.253973,
      "pb": 2.39,
      "pe": 14.83,
      "yield": 2.4,
      "date": "2002-12-31"
  },
  "2003-01-01": {
      "equity": 1100.1,
      "debt": 6.032,
      "gold": 5800.253973,
      "pb": 2.41,
      "pe": 14.92,
      "yield": 2.38,
      "date": "2003-01-01"
  },
  "2003-01-02": {
      "equity": 1093,
      "debt": 6.006,
      "gold": 5796.984074,
      "pb": 2.39,
      "pe": 14.82,
      "yield": 2.4,
      "date": "2003-01-02"
  },
  "2003-01-03": {
      "equity": 1089.6,
      "debt": 6.046,
      "gold": 5825.048061,
      "pb": 2.38,
      "pe": 14.78,
      "yield": 2.4,
      "date": "2003-01-03"
  },
  "2003-01-04": {
      "equity": 1089.6,
      "debt": 6.056,
      "gold": 5825.048061,
      "pb": 2.38,
      "pe": 14.78,
      "yield": 2.4,
      "date": "2003-01-04"
  },
  "2003-01-05": {
      "equity": 1089.6,
      "debt": 6.056,
      "gold": 5825.048061,
      "pb": 2.38,
      "pe": 14.78,
      "yield": 2.4,
      "date": "2003-01-05"
  },
  "2003-01-06": {
      "equity": 1084.3,
      "debt": 6.017,
      "gold": 6028.056932,
      "pb": 2.37,
      "pe": 14.71,
      "yield": 2.42,
      "date": "2003-01-06"
  },
  "2003-01-07": {
      "equity": 1081.8,
      "debt": 6.016,
      "gold": 5900.943579,
      "pb": 2.37,
      "pe": 14.67,
      "yield": 2.42,
      "date": "2003-01-07"
  },
  "2003-01-08": {
      "equity": 1089.3,
      "debt": 6.024,
      "gold": 5866.720753,
      "pb": 2.38,
      "pe": 14.77,
      "yield": 2.41,
      "date": "2003-01-08"
  },
  "2003-01-09": {
      "equity": 1097.3,
      "debt": 6.043,
      "gold": 5994.536059,
      "pb": 2.4,
      "pe": 14.88,
      "yield": 2.39,
      "date": "2003-01-09"
  },
  "2003-01-10": {
      "equity": 1080.2,
      "debt": 6.014,
      "gold": 5975.00485,
      "pb": 2.36,
      "pe": 14.65,
      "yield": 2.43,
      "date": "2003-01-10"
  },
  "2003-01-11": {
      "equity": 1080.2,
      "debt": 6.009,
      "gold": 5975.00485,
      "pb": 2.36,
      "pe": 14.65,
      "yield": 2.43,
      "date": "2003-01-11"
  },
  "2003-01-12": {
      "equity": 1080.2,
      "debt": 6.009,
      "gold": 5975.00485,
      "pb": 2.36,
      "pe": 14.65,
      "yield": 2.43,
      "date": "2003-01-12"
  },
  "2003-01-13": {
      "equity": 1073.7,
      "debt": 5.992,
      "gold": 5956.496587,
      "pb": 2.35,
      "pe": 14.56,
      "yield": 2.44,
      "date": "2003-01-13"
  },
  "2003-01-14": {
      "equity": 1078.9,
      "debt": 5.934,
      "gold": 5977.668036,
      "pb": 2.36,
      "pe": 14.63,
      "yield": 2.43,
      "date": "2003-01-14"
  },
  "2003-01-15": {
      "equity": 1085,
      "debt": 5.945,
      "gold": 5951.374804,
      "pb": 2.37,
      "pe": 14.72,
      "yield": 2.41,
      "date": "2003-01-15"
  },
  "2003-01-16": {
      "equity": 1088.3,
      "debt": 5.933,
      "gold": 5932.376232,
      "pb": 2.38,
      "pe": 14.76,
      "yield": 2.41,
      "date": "2003-01-16"
  },
  "2003-01-17": {
      "equity": 1086.5,
      "debt": 5.886,
      "gold": 6056.339618,
      "pb": 2.38,
      "pe": 14.74,
      "yield": 2.41,
      "date": "2003-01-17"
  },
  "2003-01-18": {
      "equity": 1086.5,
      "debt": 5.864,
      "gold": 6056.339618,
      "pb": 2.38,
      "pe": 14.74,
      "yield": 2.41,
      "date": "2003-01-18"
  },
  "2003-01-19": {
      "equity": 1086.5,
      "debt": 5.864,
      "gold": 6056.339618,
      "pb": 2.38,
      "pe": 14.74,
      "yield": 2.41,
      "date": "2003-01-19"
  },
  "2003-01-20": {
      "equity": 1076.3,
      "debt": 5.872,
      "gold": 6017.957989,
      "pb": 2.35,
      "pe": 14.6,
      "yield": 2.43,
      "date": "2003-01-20"
  },
  "2003-01-21": {
      "equity": 1077.9,
      "debt": 5.866,
      "gold": 6010.592779,
      "pb": 2.36,
      "pe": 14.62,
      "yield": 2.43,
      "date": "2003-01-21"
  },
  "2003-01-22": {
      "equity": 1082.9,
      "debt": 5.858,
      "gold": 6070.607947,
      "pb": 2.37,
      "pe": 14.69,
      "yield": 2.42,
      "date": "2003-01-22"
  },
  "2003-01-23": {
      "equity": 1070.9,
      "debt": 5.887,
      "gold": 6141.607436,
      "pb": 2.34,
      "pe": 14.52,
      "yield": 2.45,
      "date": "2003-01-23"
  },
  "2003-01-24": {
      "equity": 1056,
      "debt": 5.955,
      "gold": 6146.785658,
      "pb": 2.31,
      "pe": 14.32,
      "yield": 2.48,
      "date": "2003-01-24"
  },
  "2003-01-25": {
      "equity": 1056,
      "debt": 5.975,
      "gold": 6146.785658,
      "pb": 2.31,
      "pe": 14.32,
      "yield": 2.48,
      "date": "2003-01-25"
  },
  "2003-01-26": {
      "equity": 1056,
      "debt": 5.975,
      "gold": 6146.785658,
      "pb": 2.31,
      "pe": 14.32,
      "yield": 2.48,
      "date": "2003-01-26"
  },
  "2003-01-27": {
      "equity": 1037.6,
      "debt": 6.076,
      "gold": 6265.778938,
      "pb": 2.27,
      "pe": 14.07,
      "yield": 2.52,
      "date": "2003-01-27"
  },
  "2003-01-28": {
      "equity": 1046.2,
      "debt": 6.199,
      "gold": 6192.274996,
      "pb": 2.29,
      "pe": 14.19,
      "yield": 2.5,
      "date": "2003-01-28"
  },
  "2003-01-29": {
      "equity": 1037.2,
      "debt": 6.086,
      "gold": 6236.681423,
      "pb": 2.27,
      "pe": 14.07,
      "yield": 2.53,
      "date": "2003-01-29"
  },
  "2003-01-30": {
      "equity": 1034.6,
      "debt": 6.294,
      "gold": 6121.173213,
      "pb": 2.26,
      "pe": 14.03,
      "yield": 2.53,
      "date": "2003-01-30"
  },
  "2003-01-31": {
      "equity": 1041.8,
      "debt": 6.323,
      "gold": 6243.806769,
      "pb": 2.28,
      "pe": 14.13,
      "yield": 2.51,
      "date": "2003-01-31"
  },
  "2003-02-01": {
      "equity": 1041.8,
      "debt": 6.145,
      "gold": 6243.806769,
      "pb": 2.28,
      "pe": 14.13,
      "yield": 2.51,
      "date": "2003-02-01"
  },
  "2003-02-02": {
      "equity": 1041.8,
      "debt": 6.145,
      "gold": 6243.806769,
      "pb": 2.28,
      "pe": 14.13,
      "yield": 2.51,
      "date": "2003-02-02"
  },
  "2003-02-03": {
      "equity": 1055.3,
      "debt": 6.184,
      "gold": 6221.04799,
      "pb": 2.31,
      "pe": 14.31,
      "yield": 2.48,
      "date": "2003-02-03"
  },
  "2003-02-04": {
      "equity": 1054.8,
      "debt": 6.218,
      "gold": 6317.028519,
      "pb": 2.31,
      "pe": 14.31,
      "yield": 2.48,
      "date": "2003-02-04"
  },
  "2003-02-05": {
      "equity": 1047.4,
      "debt": 6.251,
      "gold": 6485.363058,
      "pb": 2.29,
      "pe": 14.21,
      "yield": 2.5,
      "date": "2003-02-05"
  },
  "2003-02-06": {
      "equity": 1063.6,
      "debt": 6.279,
      "gold": 6271.514489,
      "pb": 2.33,
      "pe": 14.42,
      "yield": 2.46,
      "date": "2003-02-06"
  },
  "2003-02-07": {
      "equity": 1057.5,
      "debt": 6.411,
      "gold": 6234.762518,
      "pb": 2.31,
      "pe": 14.34,
      "yield": 2.48,
      "date": "2003-02-07"
  },
  "2003-02-08": {
      "equity": 1057.5,
      "debt": 6.566,
      "gold": 6234.762518,
      "pb": 2.31,
      "pe": 14.34,
      "yield": 2.48,
      "date": "2003-02-08"
  },
  "2003-02-09": {
      "equity": 1057.5,
      "debt": 6.566,
      "gold": 6234.762518,
      "pb": 2.31,
      "pe": 14.34,
      "yield": 2.48,
      "date": "2003-02-09"
  },
  "2003-02-10": {
      "equity": 1048.6,
      "debt": 6.438,
      "gold": 6254.297254,
      "pb": 2.29,
      "pe": 14.22,
      "yield": 2.5,
      "date": "2003-02-10"
  },
  "2003-02-11": {
      "equity": 1048,
      "debt": 6.53,
      "gold": 6096.618988,
      "pb": 2.29,
      "pe": 14.21,
      "yield": 2.5,
      "date": "2003-02-11"
  },
  "2003-02-12": {
      "equity": 1044.4,
      "debt": 6.653,
      "gold": 6132.02702,
      "pb": 2.28,
      "pe": 14.17,
      "yield": 2.51,
      "date": "2003-02-12"
  },
  "2003-02-13": {
      "equity": 1044.4,
      "debt": 6.653,
      "gold": 5982.370059,
      "pb": 2.28,
      "pe": 14.17,
      "yield": 2.51,
      "date": "2003-02-13"
  },
  "2003-02-14": {
      "equity": 1036,
      "debt": 6.699,
      "gold": 6014.261274,
      "pb": 2.27,
      "pe": 14.05,
      "yield": 2.53,
      "date": "2003-02-14"
  },
  "2003-02-15": {
      "equity": 1036,
      "debt": 6.539,
      "gold": 6014.261274,
      "pb": 2.27,
      "pe": 14.05,
      "yield": 2.53,
      "date": "2003-02-15"
  },
  "2003-02-16": {
      "equity": 1036,
      "debt": 6.539,
      "gold": 6014.261274,
      "pb": 2.27,
      "pe": 14.05,
      "yield": 2.53,
      "date": "2003-02-16"
  },
  "2003-02-17": {
      "equity": 1058.2,
      "debt": 6.324,
      "gold": 5837.055327,
      "pb": 2.31,
      "pe": 14.35,
      "yield": 2.48,
      "date": "2003-02-17"
  },
  "2003-02-18": {
      "equity": 1059.3,
      "debt": 6.416,
      "gold": 5841.577453,
      "pb": 2.32,
      "pe": 14.37,
      "yield": 2.47,
      "date": "2003-02-18"
  },
  "2003-02-19": {
      "equity": 1064.3,
      "debt": 6.52,
      "gold": 5841.880809,
      "pb": 2.33,
      "pe": 14.43,
      "yield": 2.46,
      "date": "2003-02-19"
  },
  "2003-02-20": {
      "equity": 1065.6,
      "debt": 6.543,
      "gold": 5908.121836,
      "pb": 2.33,
      "pe": 14.45,
      "yield": 2.46,
      "date": "2003-02-20"
  },
  "2003-02-21": {
      "equity": 1066.1,
      "debt": 6.613,
      "gold": 5959.417274,
      "pb": 2.33,
      "pe": 14.46,
      "yield": 2.46,
      "date": "2003-02-21"
  },
  "2003-02-22": {
      "equity": 1066.1,
      "debt": 6.629,
      "gold": 5959.417274,
      "pb": 2.33,
      "pe": 14.46,
      "yield": 2.46,
      "date": "2003-02-22"
  },
  "2003-02-23": {
      "equity": 1066.1,
      "debt": 6.629,
      "gold": 5959.417274,
      "pb": 2.33,
      "pe": 14.46,
      "yield": 2.46,
      "date": "2003-02-23"
  },
  "2003-02-24": {
      "equity": 1070.1,
      "debt": 6.649,
      "gold": 5942.76795,
      "pb": 2.34,
      "pe": 14.51,
      "yield": 2.45,
      "date": "2003-02-24"
  },
  "2003-02-25": {
      "equity": 1055.5,
      "debt": 6.517,
      "gold": 6044.639235,
      "pb": 2.31,
      "pe": 14.32,
      "yield": 2.48,
      "date": "2003-02-25"
  },
  "2003-02-26": {
      "equity": 1049.6,
      "debt": 6.429,
      "gold": 5931.275684,
      "pb": 2.3,
      "pe": 14.24,
      "yield": 2.5,
      "date": "2003-02-26"
  },
  "2003-02-27": {
      "equity": 1052.9,
      "debt": 6.471,
      "gold": 5954.422477,
      "pb": 2.3,
      "pe": 14.28,
      "yield": 2.49,
      "date": "2003-02-27"
  },
  "2003-02-28": {
      "equity": 1063.4,
      "debt": 5.966,
      "gold": 5847.612833,
      "pb": 2.33,
      "pe": 14.42,
      "yield": 2.46,
      "date": "2003-02-28"
  },
  "2003-03-01": {
      "equity": 1063.4,
      "debt": 5.9,
      "gold": 5847.612833,
      "pb": 2.33,
      "pe": 14.42,
      "yield": 2.46,
      "date": "2003-03-01"
  },
  "2003-03-02": {
      "equity": 1063.4,
      "debt": 5.9,
      "gold": 5847.612833,
      "pb": 2.33,
      "pe": 14.42,
      "yield": 2.46,
      "date": "2003-03-02"
  },
  "2003-03-03": {
      "equity": 1058.8,
      "debt": 6.115,
      "gold": 5856.766433,
      "pb": 2.32,
      "pe": 14.36,
      "yield": 2.47,
      "date": "2003-03-03"
  },
  "2003-03-04": {
      "equity": 1046.6,
      "debt": 6.205,
      "gold": 5923.63181,
      "pb": 2.3,
      "pe": 14.24,
      "yield": 2.5,
      "date": "2003-03-04"
  },
  "2003-03-05": {
      "equity": 1040.7,
      "debt": 6.291,
      "gold": 5956.581245,
      "pb": 2.28,
      "pe": 14.16,
      "yield": 2.51,
      "date": "2003-03-05"
  },
  "2003-03-06": {
      "equity": 1031.2,
      "debt": 6.241,
      "gold": 5934.330411,
      "pb": 2.26,
      "pe": 14.03,
      "yield": 2.53,
      "date": "2003-03-06"
  },
  "2003-03-07": {
      "equity": 1017.1,
      "debt": 6.425,
      "gold": 5994.066915,
      "pb": 2.23,
      "pe": 13.84,
      "yield": 2.57,
      "date": "2003-03-07"
  },
  "2003-03-08": {
      "equity": 1017.1,
      "debt": 6.444,
      "gold": 5994.066915,
      "pb": 2.23,
      "pe": 13.84,
      "yield": 2.57,
      "date": "2003-03-08"
  },
  "2003-03-09": {
      "equity": 1017.1,
      "debt": 6.444,
      "gold": 5994.066915,
      "pb": 2.23,
      "pe": 13.84,
      "yield": 2.57,
      "date": "2003-03-09"
  },
  "2003-03-10": {
      "equity": 1006.7,
      "debt": 6.479,
      "gold": 5934.302192,
      "pb": 2.21,
      "pe": 13.7,
      "yield": 2.59,
      "date": "2003-03-10"
  },
  "2003-03-11": {
      "equity": 1014.5,
      "debt": 6.414,
      "gold": 5937.632057,
      "pb": 2.23,
      "pe": 13.81,
      "yield": 2.57,
      "date": "2003-03-11"
  },
  "2003-03-12": {
      "equity": 1001.7,
      "debt": 6.362,
      "gold": 5866.629041,
      "pb": 2.2,
      "pe": 13.63,
      "yield": 2.61,
      "date": "2003-03-12"
  },
  "2003-03-13": {
      "equity": 999.65,
      "debt": 6.373,
      "gold": 5764.690735,
      "pb": 2.19,
      "pe": 13.6,
      "yield": 2.61,
      "date": "2003-03-13"
  },
  "2003-03-14": {
      "equity": 999.65,
      "debt": 6.373,
      "gold": 5639.693822,
      "pb": 2.19,
      "pe": 13.6,
      "yield": 2.61,
      "date": "2003-03-14"
  },
  "2003-03-15": {
      "equity": 999.65,
      "debt": 6.33,
      "gold": 5639.693822,
      "pb": 2.19,
      "pe": 13.6,
      "yield": 2.61,
      "date": "2003-03-15"
  },
  "2003-03-16": {
      "equity": 999.65,
      "debt": 6.33,
      "gold": 5639.693822,
      "pb": 2.19,
      "pe": 13.6,
      "yield": 2.61,
      "date": "2003-03-16"
  },
  "2003-03-17": {
      "equity": 993,
      "debt": 6.464,
      "gold": 5725.243831,
      "pb": 2.18,
      "pe": 13.51,
      "yield": 2.63,
      "date": "2003-03-17"
  },
  "2003-03-18": {
      "equity": 993,
      "debt": 6.464,
      "gold": 5630.790667,
      "pb": 2.18,
      "pe": 13.51,
      "yield": 2.63,
      "date": "2003-03-18"
  },
  "2003-03-19": {
      "equity": 1003.9,
      "debt": 6.457,
      "gold": 5704.365156,
      "pb": 2.2,
      "pe": 13.71,
      "yield": 2.85,
      "date": "2003-03-19"
  },
  "2003-03-20": {
      "equity": 1025.2,
      "debt": 6.45,
      "gold": 5649.753964,
      "pb": 2.25,
      "pe": 14,
      "yield": 2.79,
      "date": "2003-03-20"
  },
  "2003-03-21": {
      "equity": 1030.5,
      "debt": 6.281,
      "gold": 5592.451366,
      "pb": 2.26,
      "pe": 14.08,
      "yield": 2.78,
      "date": "2003-03-21"
  },
  "2003-03-22": {
      "equity": 1037.1,
      "debt": 6.239,
      "gold": 5592.451366,
      "pb": 2.28,
      "pe": 14.17,
      "yield": 2.76,
      "date": "2003-03-22"
  },
  "2003-03-23": {
      "equity": 1037.1,
      "debt": 6.239,
      "gold": 5592.451366,
      "pb": 2.28,
      "pe": 14.17,
      "yield": 2.76,
      "date": "2003-03-23"
  },
  "2003-03-24": {
      "equity": 1013.9,
      "debt": 6.386,
      "gold": 5550.052029,
      "pb": 2.23,
      "pe": 13.85,
      "yield": 2.83,
      "date": "2003-03-24"
  },
  "2003-03-25": {
      "equity": 1011.3,
      "debt": 6.36,
      "gold": 5592.384345,
      "pb": 2.22,
      "pe": 13.81,
      "yield": 2.83,
      "date": "2003-03-25"
  },
  "2003-03-26": {
      "equity": 1013.8,
      "debt": 6.348,
      "gold": 5533.540274,
      "pb": 2.23,
      "pe": 13.85,
      "yield": 2.83,
      "date": "2003-03-26"
  },
  "2003-03-27": {
      "equity": 1002.7,
      "debt": 6.289,
      "gold": 5589.615337,
      "pb": 2.2,
      "pe": 13.69,
      "yield": 2.86,
      "date": "2003-03-27"
  },
  "2003-03-28": {
      "equity": 1000.6,
      "debt": 6.312,
      "gold": 5537.720242,
      "pb": 2.2,
      "pe": 13.67,
      "yield": 2.86,
      "date": "2003-03-28"
  },
  "2003-03-29": {
      "equity": 1000.6,
      "debt": 6.264,
      "gold": 5537.720242,
      "pb": 2.2,
      "pe": 13.67,
      "yield": 2.86,
      "date": "2003-03-29"
  },
  "2003-03-30": {
      "equity": 1000.6,
      "debt": 6.264,
      "gold": 5537.720242,
      "pb": 2.2,
      "pe": 13.67,
      "yield": 2.86,
      "date": "2003-03-30"
  },
  "2003-03-31": {
      "equity": 978.2,
      "debt": 6.127,
      "gold": 5615.287748,
      "pb": 2.15,
      "pe": 13.36,
      "yield": 2.93,
      "date": "2003-03-31"
  },
  "2003-04-01": {
      "equity": 984.3,
      "debt": 6.071,
      "gold": 5618.536482,
      "pb": 2.16,
      "pe": 13.44,
      "yield": 2.91,
      "date": "2003-04-01"
  },
  "2003-04-02": {
      "equity": 999.4,
      "debt": 6.071,
      "gold": 5519.991534,
      "pb": 2.19,
      "pe": 13.65,
      "yield": 2.87,
      "date": "2003-04-02"
  },
  "2003-04-03": {
      "equity": 1009.1,
      "debt": 5.983,
      "gold": 5417.488845,
      "pb": 2.22,
      "pe": 13.78,
      "yield": 2.84,
      "date": "2003-04-03"
  },
  "2003-04-04": {
      "equity": 1016.9,
      "debt": 5.991,
      "gold": 5401.41096,
      "pb": 2.23,
      "pe": 13.89,
      "yield": 2.82,
      "date": "2003-04-04"
  },
  "2003-04-05": {
      "equity": 1016.9,
      "debt": 5.975,
      "gold": 5401.41096,
      "pb": 2.23,
      "pe": 13.89,
      "yield": 2.82,
      "date": "2003-04-05"
  },
  "2003-04-06": {
      "equity": 1016.9,
      "debt": 5.975,
      "gold": 5401.41096,
      "pb": 2.23,
      "pe": 13.89,
      "yield": 2.82,
      "date": "2003-04-06"
  },
  "2003-04-07": {
      "equity": 1031.5,
      "debt": 5.97,
      "gold": 5348.43648,
      "pb": 2.26,
      "pe": 14.09,
      "yield": 2.78,
      "date": "2003-04-07"
  },
  "2003-04-08": {
      "equity": 1018.1,
      "debt": 5.956,
      "gold": 5389.382529,
      "pb": 2.24,
      "pe": 13.91,
      "yield": 2.81,
      "date": "2003-04-08"
  },
  "2003-04-09": {
      "equity": 1004.8,
      "debt": 5.896,
      "gold": 5427.771213,
      "pb": 2.21,
      "pe": 13.73,
      "yield": 2.85,
      "date": "2003-04-09"
  },
  "2003-04-10": {
      "equity": 962.2,
      "debt": 5.878,
      "gold": 5435.055292,
      "pb": 2.11,
      "pe": 13.14,
      "yield": 2.98,
      "date": "2003-04-10"
  },
  "2003-04-11": {
      "equity": 949.8,
      "debt": 5.878,
      "gold": 5427.915836,
      "pb": 2.09,
      "pe": 12.97,
      "yield": 3.02,
      "date": "2003-04-11"
  },
  "2003-04-12": {
      "equity": 949.8,
      "debt": 5.869,
      "gold": 5427.915836,
      "pb": 2.09,
      "pe": 12.97,
      "yield": 3.02,
      "date": "2003-04-12"
  },
  "2003-04-13": {
      "equity": 949.8,
      "debt": 5.869,
      "gold": 5427.915836,
      "pb": 2.09,
      "pe": 12.97,
      "yield": 3.02,
      "date": "2003-04-13"
  },
  "2003-04-14": {
      "equity": 949.8,
      "debt": 5.869,
      "gold": 5438.822554,
      "pb": 2.09,
      "pe": 12.97,
      "yield": 3.02,
      "date": "2003-04-14"
  },
  "2003-04-15": {
      "equity": 951.2,
      "debt": 5.869,
      "gold": 5405.770825,
      "pb": 2.09,
      "pe": 12.99,
      "yield": 3.01,
      "date": "2003-04-15"
  },
  "2003-04-16": {
      "equity": 958.65,
      "debt": 5.874,
      "gold": 5409.418156,
      "pb": 2.1,
      "pe": 13.09,
      "yield": 2.99,
      "date": "2003-04-16"
  },
  "2003-04-17": {
      "equity": 940.7,
      "debt": 5.868,
      "gold": 5411.873225,
      "pb": 2.07,
      "pe": 12.85,
      "yield": 3.04,
      "date": "2003-04-17"
  },
  "2003-04-18": {
      "equity": 940.7,
      "debt": 5.868,
      "gold": 5411.873225,
      "pb": 2.07,
      "pe": 12.85,
      "yield": 3.04,
      "date": "2003-04-18"
  },
  "2003-04-19": {
      "equity": 940.7,
      "debt": 5.861,
      "gold": 5411.873225,
      "pb": 2.07,
      "pe": 12.85,
      "yield": 3.04,
      "date": "2003-04-19"
  },
  "2003-04-20": {
      "equity": 940.7,
      "debt": 5.861,
      "gold": 5411.873225,
      "pb": 2.07,
      "pe": 12.85,
      "yield": 3.04,
      "date": "2003-04-20"
  },
  "2003-04-21": {
      "equity": 947.2,
      "debt": 5.871,
      "gold": 5411.873225,
      "pb": 2.08,
      "pe": 12.94,
      "yield": 3.02,
      "date": "2003-04-21"
  },
  "2003-04-22": {
      "equity": 943.5,
      "debt": 5.893,
      "gold": 5565.724263,
      "pb": 2.07,
      "pe": 12.89,
      "yield": 3.04,
      "date": "2003-04-22"
  },
  "2003-04-23": {
      "equity": 934.2,
      "debt": 5.898,
      "gold": 5574.616836,
      "pb": 2.05,
      "pe": 12.76,
      "yield": 3.07,
      "date": "2003-04-23"
  },
  "2003-04-24": {
      "equity": 929.7,
      "debt": 5.916,
      "gold": 5543.501649,
      "pb": 2.04,
      "pe": 12.7,
      "yield": 3.08,
      "date": "2003-04-24"
  },
  "2003-04-25": {
      "equity": 924.3,
      "debt": 5.88,
      "gold": 5569.114094,
      "pb": 2.03,
      "pe": 12.63,
      "yield": 3.1,
      "date": "2003-04-25"
  },
  "2003-04-26": {
      "equity": 924.3,
      "debt": 5.889,
      "gold": 5569.114094,
      "pb": 2.03,
      "pe": 12.63,
      "yield": 3.1,
      "date": "2003-04-26"
  },
  "2003-04-27": {
      "equity": 924.3,
      "debt": 5.889,
      "gold": 5569.114094,
      "pb": 2.03,
      "pe": 12.63,
      "yield": 3.1,
      "date": "2003-04-27"
  },
  "2003-04-28": {
      "equity": 929.5,
      "debt": 5.889,
      "gold": 5575.1142,
      "pb": 2.04,
      "pe": 12.7,
      "yield": 3.08,
      "date": "2003-04-28"
  },
  "2003-04-29": {
      "equity": 932.3,
      "debt": 5.933,
      "gold": 5558.683575,
      "pb": 2.05,
      "pe": 12.73,
      "yield": 3.07,
      "date": "2003-04-29"
  },
  "2003-04-30": {
      "equity": 934.05,
      "debt": 5.882,
      "gold": 5608.987813,
      "pb": 2.11,
      "pe": 13.14,
      "yield": 2.98,
      "date": "2003-04-30"
  },
  "2003-05-01": {
      "equity": 934.05,
      "debt": 5.882,
      "gold": 5659.129791,
      "pb": 2.11,
      "pe": 13.14,
      "yield": 2.98,
      "date": "2003-05-01"
  },
  "2003-05-02": {
      "equity": 938.3,
      "debt": 5.937,
      "gold": 5698.855359,
      "pb": 2.02,
      "pe": 10.86,
      "yield": 3.17,
      "date": "2003-05-02"
  },
  "2003-05-03": {
      "equity": 938.3,
      "debt": 5.952,
      "gold": 5698.855359,
      "pb": 2.02,
      "pe": 10.86,
      "yield": 3.17,
      "date": "2003-05-03"
  },
  "2003-05-04": {
      "equity": 938.3,
      "debt": 5.952,
      "gold": 5698.855359,
      "pb": 2.02,
      "pe": 10.86,
      "yield": 3.17,
      "date": "2003-05-04"
  },
  "2003-05-05": {
      "equity": 945.4,
      "debt": 5.935,
      "gold": 5698.855359,
      "pb": 2.04,
      "pe": 10.94,
      "yield": 3.15,
      "date": "2003-05-05"
  },
  "2003-05-06": {
      "equity": 951.85,
      "debt": 5.946,
      "gold": 5713.472901,
      "pb": 2.05,
      "pe": 11.02,
      "yield": 3.12,
      "date": "2003-05-06"
  },
  "2003-05-07": {
      "equity": 950.15,
      "debt": 5.937,
      "gold": 5717.494136,
      "pb": 2.05,
      "pe": 11,
      "yield": 3.13,
      "date": "2003-05-07"
  },
  "2003-05-08": {
      "equity": 941.55,
      "debt": 5.914,
      "gold": 5686.184942,
      "pb": 2.03,
      "pe": 10.9,
      "yield": 3.16,
      "date": "2003-05-08"
  },
  "2003-05-09": {
      "equity": 937.85,
      "debt": 5.913,
      "gold": 5803.774317,
      "pb": 2.02,
      "pe": 10.86,
      "yield": 3.17,
      "date": "2003-05-09"
  },
  "2003-05-10": {
      "equity": 937.85,
      "debt": 5.917,
      "gold": 5803.774317,
      "pb": 2.02,
      "pe": 10.86,
      "yield": 3.17,
      "date": "2003-05-10"
  },
  "2003-05-11": {
      "equity": 937.85,
      "debt": 5.917,
      "gold": 5803.774317,
      "pb": 2.02,
      "pe": 10.86,
      "yield": 3.17,
      "date": "2003-05-11"
  },
  "2003-05-12": {
      "equity": 936,
      "debt": 5.919,
      "gold": 5828.702446,
      "pb": 2.02,
      "pe": 10.84,
      "yield": 3.18,
      "date": "2003-05-12"
  },
  "2003-05-13": {
      "equity": 944.2,
      "debt": 5.9,
      "gold": 5834.653168,
      "pb": 2.03,
      "pe": 10.93,
      "yield": 3.15,
      "date": "2003-05-13"
  },
  "2003-05-14": {
      "equity": 952.15,
      "debt": 5.897,
      "gold": 5803.104111,
      "pb": 2.05,
      "pe": 11.02,
      "yield": 3.12,
      "date": "2003-05-14"
  },
  "2003-05-15": {
      "equity": 959.85,
      "debt": 5.897,
      "gold": 5882.001446,
      "pb": 2.07,
      "pe": 11.11,
      "yield": 3.1,
      "date": "2003-05-15"
  },
  "2003-05-16": {
      "equity": 973.1,
      "debt": 5.897,
      "gold": 5861.634244,
      "pb": 2.1,
      "pe": 11.26,
      "yield": 3.06,
      "date": "2003-05-16"
  },
  "2003-05-17": {
      "equity": 973.1,
      "debt": 5.87,
      "gold": 5861.634244,
      "pb": 2.1,
      "pe": 11.26,
      "yield": 3.06,
      "date": "2003-05-17"
  },
  "2003-05-18": {
      "equity": 973.1,
      "debt": 5.87,
      "gold": 5861.634244,
      "pb": 2.1,
      "pe": 11.26,
      "yield": 3.06,
      "date": "2003-05-18"
  },
  "2003-05-19": {
      "equity": 966.55,
      "debt": 5.863,
      "gold": 5963.389125,
      "pb": 2.08,
      "pe": 11.19,
      "yield": 3.08,
      "date": "2003-05-19"
  },
  "2003-05-20": {
      "equity": 971.55,
      "debt": 5.86,
      "gold": 6089.955731,
      "pb": 2.09,
      "pe": 11.25,
      "yield": 3.06,
      "date": "2003-05-20"
  },
  "2003-05-21": {
      "equity": 968,
      "debt": 5.834,
      "gold": 6031.89827,
      "pb": 2.09,
      "pe": 11.21,
      "yield": 3.07,
      "date": "2003-05-21"
  },
  "2003-05-22": {
      "equity": 963.25,
      "debt": 5.834,
      "gold": 6115.130778,
      "pb": 2.08,
      "pe": 11.15,
      "yield": 3.09,
      "date": "2003-05-22"
  },
  "2003-05-23": {
      "equity": 967.9,
      "debt": 5.805,
      "gold": 6116.626396,
      "pb": 2.09,
      "pe": 11.2,
      "yield": 3.07,
      "date": "2003-05-23"
  },
  "2003-05-24": {
      "equity": 967.9,
      "debt": 5.794,
      "gold": 6116.626396,
      "pb": 2.09,
      "pe": 11.2,
      "yield": 3.07,
      "date": "2003-05-24"
  },
  "2003-05-25": {
      "equity": 967.9,
      "debt": 5.794,
      "gold": 6116.626396,
      "pb": 2.09,
      "pe": 11.2,
      "yield": 3.07,
      "date": "2003-05-25"
  },
  "2003-05-26": {
      "equity": 982.45,
      "debt": 5.787,
      "gold": 6116.626396,
      "pb": 2.12,
      "pe": 11.37,
      "yield": 3.03,
      "date": "2003-05-26"
  },
  "2003-05-27": {
      "equity": 976.85,
      "debt": 5.797,
      "gold": 6160.630699,
      "pb": 2.13,
      "pe": 11.47,
      "yield": 3,
      "date": "2003-05-27"
  },
  "2003-05-28": {
      "equity": 990.8,
      "debt": 5.785,
      "gold": 6041.221186,
      "pb": 2.1,
      "pe": 11.31,
      "yield": 3.04,
      "date": "2003-05-28"
  },
  "2003-05-29": {
      "equity": 1002.6,
      "debt": 5.8,
      "gold": 5993.918764,
      "pb": 2.16,
      "pe": 11.61,
      "yield": 2.97,
      "date": "2003-05-29"
  },
  "2003-05-30": {
      "equity": 1006.8,
      "debt": 5.793,
      "gold": 6074.837299,
      "pb": 2.17,
      "pe": 11.65,
      "yield": 2.95,
      "date": "2003-05-30"
  },
  "2003-05-31": {
      "equity": 1006.8,
      "debt": 5.797,
      "gold": 6074.837299,
      "pb": 2.17,
      "pe": 11.65,
      "yield": 2.95,
      "date": "2003-05-31"
  },
  "2003-06-01": {
      "equity": 1006.8,
      "debt": 5.797,
      "gold": 6074.837299,
      "pb": 2.17,
      "pe": 11.65,
      "yield": 2.95,
      "date": "2003-06-01"
  },
  "2003-06-02": {
      "equity": 1015.1,
      "debt": 5.813,
      "gold": 6005.841373,
      "pb": 2.19,
      "pe": 11.59,
      "yield": 2.93,
      "date": "2003-06-02"
  },
  "2003-06-03": {
      "equity": 1010.6,
      "debt": 5.81,
      "gold": 6049.785711,
      "pb": 2.18,
      "pe": 11.54,
      "yield": 2.94,
      "date": "2003-06-03"
  },
  "2003-06-04": {
      "equity": 1021,
      "debt": 5.794,
      "gold": 6041.408138,
      "pb": 2.2,
      "pe": 11.66,
      "yield": 2.91,
      "date": "2003-06-04"
  },
  "2003-06-05": {
      "equity": 1035,
      "debt": 5.777,
      "gold": 5999.63315,
      "pb": 2.23,
      "pe": 11.82,
      "yield": 2.87,
      "date": "2003-06-05"
  },
  "2003-06-06": {
      "equity": 1046.4,
      "debt": 5.768,
      "gold": 6076.608053,
      "pb": 2.25,
      "pe": 11.95,
      "yield": 2.84,
      "date": "2003-06-06"
  },
  "2003-06-07": {
      "equity": 1046.4,
      "debt": 5.764,
      "gold": 6076.608053,
      "pb": 2.25,
      "pe": 11.95,
      "yield": 2.84,
      "date": "2003-06-07"
  },
  "2003-06-08": {
      "equity": 1046.4,
      "debt": 5.764,
      "gold": 6076.608053,
      "pb": 2.25,
      "pe": 11.95,
      "yield": 2.84,
      "date": "2003-06-08"
  },
  "2003-06-09": {
      "equity": 1052.1,
      "debt": 5.76,
      "gold": 6000.063493,
      "pb": 2.27,
      "pe": 12.01,
      "yield": 2.83,
      "date": "2003-06-09"
  },
  "2003-06-10": {
      "equity": 1037.8,
      "debt": 5.775,
      "gold": 5931.473218,
      "pb": 2.24,
      "pe": 11.85,
      "yield": 2.87,
      "date": "2003-06-10"
  },
  "2003-06-11": {
      "equity": 1044.1,
      "debt": 5.763,
      "gold": 5832.960017,
      "pb": 2.25,
      "pe": 11.92,
      "yield": 2.85,
      "date": "2003-06-11"
  },
  "2003-06-12": {
      "equity": 1051.3,
      "debt": 5.764,
      "gold": 5832.84714,
      "pb": 2.27,
      "pe": 12,
      "yield": 2.83,
      "date": "2003-06-12"
  },
  "2003-06-13": {
      "equity": 1056.2,
      "debt": 5.769,
      "gold": 5834.970634,
      "pb": 2.28,
      "pe": 12.06,
      "yield": 2.82,
      "date": "2003-06-13"
  },
  "2003-06-14": {
      "equity": 1056.2,
      "debt": 5.763,
      "gold": 5834.970634,
      "pb": 2.28,
      "pe": 12.06,
      "yield": 2.82,
      "date": "2003-06-14"
  },
  "2003-06-15": {
      "equity": 1056.2,
      "debt": 5.763,
      "gold": 5834.970634,
      "pb": 2.28,
      "pe": 12.06,
      "yield": 2.82,
      "date": "2003-06-15"
  },
  "2003-06-16": {
      "equity": 1051.8,
      "debt": 5.747,
      "gold": 5909.673892,
      "pb": 2.27,
      "pe": 12.01,
      "yield": 2.83,
      "date": "2003-06-16"
  },
  "2003-06-17": {
      "equity": 1081.9,
      "debt": 5.743,
      "gold": 5912.1854,
      "pb": 2.33,
      "pe": 12.35,
      "yield": 2.75,
      "date": "2003-06-17"
  },
  "2003-06-18": {
      "equity": 1086.7,
      "debt": 5.745,
      "gold": 5936.683187,
      "pb": 2.34,
      "pe": 12.41,
      "yield": 2.74,
      "date": "2003-06-18"
  },
  "2003-06-19": {
      "equity": 1092.5,
      "debt": 5.744,
      "gold": 5856.194995,
      "pb": 2.35,
      "pe": 12.47,
      "yield": 2.72,
      "date": "2003-06-19"
  },
  "2003-06-20": {
      "equity": 1100.2,
      "debt": 5.732,
      "gold": 5903.377485,
      "pb": 2.37,
      "pe": 12.56,
      "yield": 2.7,
      "date": "2003-06-20"
  },
  "2003-06-21": {
      "equity": 1100.2,
      "debt": 5.72,
      "gold": 5903.377485,
      "pb": 2.37,
      "pe": 12.56,
      "yield": 2.7,
      "date": "2003-06-21"
  },
  "2003-06-22": {
      "equity": 1100.2,
      "debt": 5.72,
      "gold": 5903.377485,
      "pb": 2.37,
      "pe": 12.56,
      "yield": 2.7,
      "date": "2003-06-22"
  },
  "2003-06-23": {
      "equity": 1089.2,
      "debt": 5.72,
      "gold": 5859.542496,
      "pb": 2.35,
      "pe": 12.44,
      "yield": 2.73,
      "date": "2003-06-23"
  },
  "2003-06-24": {
      "equity": 1085.3,
      "debt": 5.718,
      "gold": 5822.815217,
      "pb": 2.34,
      "pe": 12.39,
      "yield": 2.74,
      "date": "2003-06-24"
  },
  "2003-06-25": {
      "equity": 1106.6,
      "debt": 5.709,
      "gold": 5716.619341,
      "pb": 2.38,
      "pe": 12.64,
      "yield": 2.69,
      "date": "2003-06-25"
  },
  "2003-06-26": {
      "equity": 1116.3,
      "debt": 5.734,
      "gold": 5682.2272,
      "pb": 2.41,
      "pe": 12.75,
      "yield": 2.66,
      "date": "2003-06-26"
  },
  "2003-06-27": {
      "equity": 1125.5,
      "debt": 5.73,
      "gold": 5622.413094,
      "pb": 2.42,
      "pe": 12.85,
      "yield": 2.64,
      "date": "2003-06-27"
  },
  "2003-06-28": {
      "equity": 1125.5,
      "debt": 5.728,
      "gold": 5622.413094,
      "pb": 2.42,
      "pe": 12.85,
      "yield": 2.64,
      "date": "2003-06-28"
  },
  "2003-06-29": {
      "equity": 1125.5,
      "debt": 5.728,
      "gold": 5622.413094,
      "pb": 2.42,
      "pe": 12.85,
      "yield": 2.64,
      "date": "2003-06-29"
  },
  "2003-06-30": {
      "equity": 1134.1,
      "debt": 5.728,
      "gold": 5659.76825,
      "pb": 2.44,
      "pe": 12.95,
      "yield": 2.62,
      "date": "2003-06-30"
  },
  "2003-07-01": {
      "equity": 1130.7,
      "debt": 5.727,
      "gold": 5709.067179,
      "pb": 2.44,
      "pe": 12.32,
      "yield": 2.33,
      "date": "2003-07-01"
  },
  "2003-07-02": {
      "equity": 1133.8,
      "debt": 5.723,
      "gold": 5750.612886,
      "pb": 2.44,
      "pe": 12.35,
      "yield": 2.33,
      "date": "2003-07-02"
  },
  "2003-07-03": {
      "equity": 1144.6,
      "debt": 5.723,
      "gold": 5721.949241,
      "pb": 2.47,
      "pe": 12.47,
      "yield": 2.31,
      "date": "2003-07-03"
  },
  "2003-07-04": {
      "equity": 1138.4,
      "debt": 5.709,
      "gold": 5737.829591,
      "pb": 2.45,
      "pe": 12.4,
      "yield": 2.32,
      "date": "2003-07-04"
  },
  "2003-07-05": {
      "equity": 1138.4,
      "debt": 5.713,
      "gold": 5737.829591,
      "pb": 2.45,
      "pe": 12.4,
      "yield": 2.32,
      "date": "2003-07-05"
  },
  "2003-07-06": {
      "equity": 1138.4,
      "debt": 5.713,
      "gold": 5737.829591,
      "pb": 2.45,
      "pe": 12.4,
      "yield": 2.32,
      "date": "2003-07-06"
  },
  "2003-07-07": {
      "equity": 1140.5,
      "debt": 5.727,
      "gold": 5723.938694,
      "pb": 2.46,
      "pe": 12.43,
      "yield": 2.31,
      "date": "2003-07-07"
  },
  "2003-07-08": {
      "equity": 1145.9,
      "debt": 5.726,
      "gold": 5635.73608,
      "pb": 2.47,
      "pe": 12.49,
      "yield": 2.3,
      "date": "2003-07-08"
  },
  "2003-07-09": {
      "equity": 1141,
      "debt": 5.703,
      "gold": 5633.309229,
      "pb": 2.46,
      "pe": 12.43,
      "yield": 2.31,
      "date": "2003-07-09"
  },
  "2003-07-10": {
      "equity": 1162.3,
      "debt": 5.707,
      "gold": 5595.03695,
      "pb": 2.5,
      "pe": 12.66,
      "yield": 2.27,
      "date": "2003-07-10"
  },
  "2003-07-11": {
      "equity": 1161.6,
      "debt": 5.706,
      "gold": 5598.052876,
      "pb": 2.5,
      "pe": 12.66,
      "yield": 2.27,
      "date": "2003-07-11"
  },
  "2003-07-12": {
      "equity": 1161.6,
      "debt": 5.709,
      "gold": 5598.052876,
      "pb": 2.5,
      "pe": 12.66,
      "yield": 2.27,
      "date": "2003-07-12"
  },
  "2003-07-13": {
      "equity": 1161.6,
      "debt": 5.709,
      "gold": 5598.052876,
      "pb": 2.5,
      "pe": 12.66,
      "yield": 2.27,
      "date": "2003-07-13"
  },
  "2003-07-14": {
      "equity": 1171.5,
      "debt": 5.705,
      "gold": 5594.730066,
      "pb": 2.52,
      "pe": 12.76,
      "yield": 2.25,
      "date": "2003-07-14"
  },
  "2003-07-15": {
      "equity": 1159.8,
      "debt": 5.702,
      "gold": 5670.756098,
      "pb": 2.5,
      "pe": 12.64,
      "yield": 2.27,
      "date": "2003-07-15"
  },
  "2003-07-16": {
      "equity": 1168.7,
      "debt": 5.708,
      "gold": 5589.424858,
      "pb": 2.52,
      "pe": 12.73,
      "yield": 2.26,
      "date": "2003-07-16"
  },
  "2003-07-17": {
      "equity": 1152,
      "debt": 5.721,
      "gold": 5618.233126,
      "pb": 2.48,
      "pe": 12.55,
      "yield": 2.29,
      "date": "2003-07-17"
  },
  "2003-07-18": {
      "equity": 1140,
      "debt": 5.722,
      "gold": 5610.271786,
      "pb": 2.46,
      "pe": 12.42,
      "yield": 2.31,
      "date": "2003-07-18"
  },
  "2003-07-19": {
      "equity": 1140,
      "debt": 5.703,
      "gold": 5610.271786,
      "pb": 2.46,
      "pe": 12.42,
      "yield": 2.31,
      "date": "2003-07-19"
  },
  "2003-07-20": {
      "equity": 1140,
      "debt": 5.703,
      "gold": 5610.271786,
      "pb": 2.46,
      "pe": 12.42,
      "yield": 2.31,
      "date": "2003-07-20"
  },
  "2003-07-21": {
      "equity": 1115.8,
      "debt": 5.698,
      "gold": 5670.406885,
      "pb": 2.4,
      "pe": 12.16,
      "yield": 2.36,
      "date": "2003-07-21"
  },
  "2003-07-22": {
      "equity": 1109.2,
      "debt": 5.702,
      "gold": 5737.988324,
      "pb": 2.39,
      "pe": 12,
      "yield": 2.38,
      "date": "2003-07-22"
  },
  "2003-07-23": {
      "equity": 1119,
      "debt": 5.689,
      "gold": 5734.686679,
      "pb": 2.41,
      "pe": 12.1,
      "yield": 2.36,
      "date": "2003-07-23"
  },
  "2003-07-24": {
      "equity": 1139.4,
      "debt": 5.667,
      "gold": 5858.434893,
      "pb": 2.45,
      "pe": 12.32,
      "yield": 2.32,
      "date": "2003-07-24"
  },
  "2003-07-25": {
      "equity": 1162.7,
      "debt": 5.63,
      "gold": 5873.232332,
      "pb": 2.51,
      "pe": 12.58,
      "yield": 2.27,
      "date": "2003-07-25"
  },
  "2003-07-26": {
      "equity": 1162.7,
      "debt": 5.635,
      "gold": 5873.232332,
      "pb": 2.51,
      "pe": 12.58,
      "yield": 2.27,
      "date": "2003-07-26"
  },
  "2003-07-27": {
      "equity": 1162.7,
      "debt": 5.635,
      "gold": 5873.232332,
      "pb": 2.51,
      "pe": 12.58,
      "yield": 2.27,
      "date": "2003-07-27"
  },
  "2003-07-28": {
      "equity": 1169.2,
      "debt": 5.638,
      "gold": 5890.248505,
      "pb": 2.52,
      "pe": 12.65,
      "yield": 2.26,
      "date": "2003-07-28"
  },
  "2003-07-29": {
      "equity": 1174.7,
      "debt": 5.637,
      "gold": 5921.363692,
      "pb": 2.53,
      "pe": 12.71,
      "yield": 2.25,
      "date": "2003-07-29"
  },
  "2003-07-30": {
      "equity": 1183,
      "debt": 5.637,
      "gold": 5842.222967,
      "pb": 2.55,
      "pe": 12.81,
      "yield": 2.23,
      "date": "2003-07-30"
  },
  "2003-07-31": {
      "equity": 1185.8,
      "debt": 5.616,
      "gold": 5790.232632,
      "pb": 2.55,
      "pe": 12.84,
      "yield": 2.23,
      "date": "2003-07-31"
  },
  "2003-08-01": {
      "equity": 1195.7,
      "debt": 5.598,
      "gold": 5748.528193,
      "pb": 2.58,
      "pe": 12.95,
      "yield": 2.21,
      "date": "2003-08-01"
  },
  "2003-08-02": {
      "equity": 1195.7,
      "debt": 5.548,
      "gold": 5748.528193,
      "pb": 2.58,
      "pe": 12.95,
      "yield": 2.21,
      "date": "2003-08-02"
  },
  "2003-08-03": {
      "equity": 1195.7,
      "debt": 5.548,
      "gold": 5748.528193,
      "pb": 2.58,
      "pe": 12.95,
      "yield": 2.21,
      "date": "2003-08-03"
  },
  "2003-08-04": {
      "equity": 1203.6,
      "debt": 5.573,
      "gold": 5678.692746,
      "pb": 2.59,
      "pe": 13.26,
      "yield": 2.17,
      "date": "2003-08-04"
  },
  "2003-08-05": {
      "equity": 1184.4,
      "debt": 5.572,
      "gold": 5683.945043,
      "pb": 2.55,
      "pe": 13.05,
      "yield": 2.21,
      "date": "2003-08-05"
  },
  "2003-08-06": {
      "equity": 1171,
      "debt": 5.564,
      "gold": 5727.709483,
      "pb": 2.52,
      "pe": 12.9,
      "yield": 2.23,
      "date": "2003-08-06"
  },
  "2003-08-07": {
      "equity": 1196.9,
      "debt": 5.56,
      "gold": 5672.900757,
      "pb": 2.57,
      "pe": 13.19,
      "yield": 2.19,
      "date": "2003-08-07"
  },
  "2003-08-08": {
      "equity": 1222.6,
      "debt": 5.553,
      "gold": 5730.915889,
      "pb": 2.63,
      "pe": 13.47,
      "yield": 2.14,
      "date": "2003-08-08"
  },
  "2003-08-09": {
      "equity": 1222.6,
      "debt": 5.523,
      "gold": 5730.915889,
      "pb": 2.63,
      "pe": 13.47,
      "yield": 2.14,
      "date": "2003-08-09"
  },
  "2003-08-10": {
      "equity": 1222.6,
      "debt": 5.523,
      "gold": 5730.915889,
      "pb": 2.63,
      "pe": 13.47,
      "yield": 2.14,
      "date": "2003-08-10"
  },
  "2003-08-11": {
      "equity": 1232.8,
      "debt": 5.528,
      "gold": 5786.398349,
      "pb": 2.65,
      "pe": 13.58,
      "yield": 2.12,
      "date": "2003-08-11"
  },
  "2003-08-12": {
      "equity": 1234.7,
      "debt": 5.525,
      "gold": 5826.1345,
      "pb": 2.65,
      "pe": 13.61,
      "yield": 2.12,
      "date": "2003-08-12"
  },
  "2003-08-13": {
      "equity": 1246.9,
      "debt": 5.521,
      "gold": 5763.91118,
      "pb": 2.68,
      "pe": 13.74,
      "yield": 2.1,
      "date": "2003-08-13"
  },
  "2003-08-14": {
      "equity": 1247.7,
      "debt": 5.539,
      "gold": 5864.463218,
      "pb": 2.68,
      "pe": 13.75,
      "yield": 2.1,
      "date": "2003-08-14"
  },
  "2003-08-15": {
      "equity": 1247.7,
      "debt": 5.539,
      "gold": 5878.900862,
      "pb": 2.68,
      "pe": 13.75,
      "yield": 2.1,
      "date": "2003-08-15"
  },
  "2003-08-16": {
      "equity": 1247.7,
      "debt": 5.538,
      "gold": 5878.900862,
      "pb": 2.68,
      "pe": 13.75,
      "yield": 2.1,
      "date": "2003-08-16"
  },
  "2003-08-17": {
      "equity": 1247.7,
      "debt": 5.538,
      "gold": 5878.900862,
      "pb": 2.68,
      "pe": 13.75,
      "yield": 2.1,
      "date": "2003-08-17"
  },
  "2003-08-18": {
      "equity": 1281.4,
      "debt": 5.532,
      "gold": 5842.074816,
      "pb": 2.76,
      "pe": 14.12,
      "yield": 2.04,
      "date": "2003-08-18"
  },
  "2003-08-19": {
      "equity": 1277.7,
      "debt": 5.533,
      "gold": 5788.511261,
      "pb": 2.75,
      "pe": 14.08,
      "yield": 2.05,
      "date": "2003-08-19"
  },
  "2003-08-20": {
      "equity": 1287.4,
      "debt": 5.535,
      "gold": 5853.433041,
      "pb": 2.77,
      "pe": 14.19,
      "yield": 2.03,
      "date": "2003-08-20"
  },
  "2003-08-21": {
      "equity": 1300.9,
      "debt": 5.541,
      "gold": 5885.733434,
      "pb": 2.8,
      "pe": 14.33,
      "yield": 2.01,
      "date": "2003-08-21"
  },
  "2003-08-22": {
      "equity": 1311.1,
      "debt": 5.573,
      "gold": 5834.455634,
      "pb": 2.82,
      "pe": 14.45,
      "yield": 1.99,
      "date": "2003-08-22"
  },
  "2003-08-23": {
      "equity": 1311.1,
      "debt": 5.56,
      "gold": 5834.455634,
      "pb": 2.82,
      "pe": 14.45,
      "yield": 1.99,
      "date": "2003-08-23"
  },
  "2003-08-24": {
      "equity": 1311.1,
      "debt": 5.56,
      "gold": 5834.455634,
      "pb": 2.82,
      "pe": 14.45,
      "yield": 1.99,
      "date": "2003-08-24"
  },
  "2003-08-25": {
      "equity": 1271.1,
      "debt": 5.302,
      "gold": 5834.455634,
      "pb": 2.73,
      "pe": 14.01,
      "yield": 2.06,
      "date": "2003-08-25"
  },
  "2003-08-26": {
      "equity": 1318.2,
      "debt": 5.242,
      "gold": 5836.998889,
      "pb": 2.83,
      "pe": 14.52,
      "yield": 1.98,
      "date": "2003-08-26"
  },
  "2003-08-27": {
      "equity": 1340.3,
      "debt": 5.271,
      "gold": 5889.299635,
      "pb": 2.88,
      "pe": 14.77,
      "yield": 1.95,
      "date": "2003-08-27"
  },
  "2003-08-28": {
      "equity": 1341,
      "debt": 5.263,
      "gold": 6000.574966,
      "pb": 2.88,
      "pe": 14.78,
      "yield": 1.95,
      "date": "2003-08-28"
  },
  "2003-08-29": {
      "equity": 1356.5,
      "debt": 5.282,
      "gold": 5998.920616,
      "pb": 2.92,
      "pe": 14.95,
      "yield": 1.93,
      "date": "2003-08-29"
  },
  "2003-08-30": {
      "equity": 1356.5,
      "debt": 5.264,
      "gold": 5998.920616,
      "pb": 2.92,
      "pe": 14.95,
      "yield": 1.93,
      "date": "2003-08-30"
  },
  "2003-08-31": {
      "equity": 1356.5,
      "debt": 5.264,
      "gold": 5998.920616,
      "pb": 2.92,
      "pe": 14.95,
      "yield": 1.93,
      "date": "2003-08-31"
  },
  "2003-09-01": {
      "equity": 1375.9,
      "debt": 5.27,
      "gold": 6084.100249,
      "pb": 2.96,
      "pe": 15.16,
      "yield": 1.9,
      "date": "2003-09-01"
  },
  "2003-09-02": {
      "equity": 1385.4,
      "debt": 5.275,
      "gold": 6075.655655,
      "pb": 2.98,
      "pe": 15.27,
      "yield": 1.89,
      "date": "2003-09-02"
  },
  "2003-09-03": {
      "equity": 1359.3,
      "debt": 5.277,
      "gold": 6024.889328,
      "pb": 2.92,
      "pe": 14.98,
      "yield": 1.92,
      "date": "2003-09-03"
  },
  "2003-09-04": {
      "equity": 1372.7,
      "debt": 5.275,
      "gold": 6033.89125,
      "pb": 2.95,
      "pe": 15.13,
      "yield": 1.91,
      "date": "2003-09-04"
  },
  "2003-09-05": {
      "equity": 1398.4,
      "debt": 5.28,
      "gold": 6002.194042,
      "pb": 3.01,
      "pe": 15.41,
      "yield": 1.87,
      "date": "2003-09-05"
  },
  "2003-09-06": {
      "equity": 1398.4,
      "debt": 5.276,
      "gold": 6002.194042,
      "pb": 3.01,
      "pe": 15.41,
      "yield": 1.87,
      "date": "2003-09-06"
  },
  "2003-09-07": {
      "equity": 1398.4,
      "debt": 5.276,
      "gold": 6002.194042,
      "pb": 3.01,
      "pe": 15.41,
      "yield": 1.87,
      "date": "2003-09-07"
  },
  "2003-09-08": {
      "equity": 1417.3,
      "debt": 5.285,
      "gold": 6080.92559,
      "pb": 3.05,
      "pe": 15.62,
      "yield": 1.85,
      "date": "2003-09-08"
  },
  "2003-09-09": {
      "equity": 1407,
      "debt": 5.29,
      "gold": 6110.005467,
      "pb": 3.03,
      "pe": 15.5,
      "yield": 1.86,
      "date": "2003-09-09"
  },
  "2003-09-10": {
      "equity": 1409.5,
      "debt": 5.326,
      "gold": 6145.565178,
      "pb": 3.03,
      "pe": 15.53,
      "yield": 1.86,
      "date": "2003-09-10"
  },
  "2003-09-11": {
      "equity": 1403.1,
      "debt": 5.331,
      "gold": 6109.698584,
      "pb": 3.02,
      "pe": 15.46,
      "yield": 1.86,
      "date": "2003-09-11"
  },
  "2003-09-12": {
      "equity": 1372.1,
      "debt": 5.327,
      "gold": 6092.040424,
      "pb": 2.95,
      "pe": 15.12,
      "yield": 1.91,
      "date": "2003-09-12"
  },
  "2003-09-13": {
      "equity": 1372.1,
      "debt": 5.326,
      "gold": 6092.040424,
      "pb": 2.95,
      "pe": 15.12,
      "yield": 1.91,
      "date": "2003-09-13"
  },
  "2003-09-14": {
      "equity": 1372.1,
      "debt": 5.326,
      "gold": 6092.040424,
      "pb": 2.95,
      "pe": 15.12,
      "yield": 1.91,
      "date": "2003-09-14"
  },
  "2003-09-15": {
      "equity": 1329.2,
      "debt": 5.33,
      "gold": 6061.831778,
      "pb": 2.86,
      "pe": 14.65,
      "yield": 1.97,
      "date": "2003-09-15"
  },
  "2003-09-16": {
      "equity": 1357.9,
      "debt": 5.303,
      "gold": 6063.306231,
      "pb": 2.92,
      "pe": 14.96,
      "yield": 1.93,
      "date": "2003-09-16"
  },
  "2003-09-17": {
      "equity": 1341.6,
      "debt": 5.291,
      "gold": 6044.441701,
      "pb": 2.88,
      "pe": 14.78,
      "yield": 1.95,
      "date": "2003-09-17"
  },
  "2003-09-18": {
      "equity": 1302.3,
      "debt": 5.314,
      "gold": 6114.411189,
      "pb": 2.8,
      "pe": 14.35,
      "yield": 2.01,
      "date": "2003-09-18"
  },
  "2003-09-19": {
      "equity": 1322.1,
      "debt": 5.313,
      "gold": 6096.566077,
      "pb": 2.84,
      "pe": 14.57,
      "yield": 1.98,
      "date": "2003-09-19"
  },
  "2003-09-20": {
      "equity": 1322.1,
      "debt": 5.311,
      "gold": 6096.566077,
      "pb": 2.84,
      "pe": 14.57,
      "yield": 1.98,
      "date": "2003-09-20"
  },
  "2003-09-21": {
      "equity": 1322.1,
      "debt": 5.311,
      "gold": 6096.566077,
      "pb": 2.84,
      "pe": 14.57,
      "yield": 1.98,
      "date": "2003-09-21"
  },
  "2003-09-22": {
      "equity": 1302.9,
      "debt": 5.315,
      "gold": 6231.757174,
      "pb": 2.8,
      "pe": 14.36,
      "yield": 2.01,
      "date": "2003-09-22"
  },
  "2003-09-23": {
      "equity": 1328.2,
      "debt": 5.309,
      "gold": 6237.834882,
      "pb": 2.86,
      "pe": 14.64,
      "yield": 1.97,
      "date": "2003-09-23"
  },
  "2003-09-24": {
      "equity": 1372,
      "debt": 5.272,
      "gold": 6219.823983,
      "pb": 2.95,
      "pe": 15.12,
      "yield": 1.91,
      "date": "2003-09-24"
  },
  "2003-09-25": {
      "equity": 1357.2,
      "debt": 5.254,
      "gold": 6340.203531,
      "pb": 2.92,
      "pe": 14.95,
      "yield": 1.93,
      "date": "2003-09-25"
  },
  "2003-09-26": {
      "equity": 1386.9,
      "debt": 5.259,
      "gold": 6214.130761,
      "pb": 2.98,
      "pe": 15.28,
      "yield": 1.89,
      "date": "2003-09-26"
  },
  "2003-09-27": {
      "equity": 1386.9,
      "debt": 5.258,
      "gold": 6214.130761,
      "pb": 2.98,
      "pe": 15.28,
      "yield": 1.89,
      "date": "2003-09-27"
  },
  "2003-09-28": {
      "equity": 1386.9,
      "debt": 5.258,
      "gold": 6214.130761,
      "pb": 2.98,
      "pe": 15.28,
      "yield": 1.89,
      "date": "2003-09-28"
  },
  "2003-09-29": {
      "equity": 1399.9,
      "debt": 5.244,
      "gold": 6136.662022,
      "pb": 3.01,
      "pe": 15.43,
      "yield": 1.87,
      "date": "2003-09-29"
  },
  "2003-09-30": {
      "equity": 1417.1,
      "debt": 5.174,
      "gold": 6206.359901,
      "pb": 3.05,
      "pe": 15.61,
      "yield": 1.85,
      "date": "2003-09-30"
  },
  "2003-10-01": {
      "equity": 1420.8,
      "debt": 5.156,
      "gold": 6185.911568,
      "pb": 3.06,
      "pe": 15.66,
      "yield": 1.84,
      "date": "2003-10-01"
  },
  "2003-10-02": {
      "equity": 1420.8,
      "debt": 5.156,
      "gold": 6181.530538,
      "pb": 3.06,
      "pe": 15.66,
      "yield": 1.84,
      "date": "2003-10-02"
  },
  "2003-10-03": {
      "equity": 1449.3,
      "debt": 5.159,
      "gold": 6146.016685,
      "pb": 3.12,
      "pe": 15.97,
      "yield": 1.8,
      "date": "2003-10-03"
  },
  "2003-10-04": {
      "equity": 1449.3,
      "debt": 5.159,
      "gold": 6146.016685,
      "pb": 3.12,
      "pe": 15.97,
      "yield": 1.8,
      "date": "2003-10-04"
  },
  "2003-10-05": {
      "equity": 1449.3,
      "debt": 5.159,
      "gold": 6146.016685,
      "pb": 3.12,
      "pe": 15.97,
      "yield": 1.8,
      "date": "2003-10-05"
  },
  "2003-10-06": {
      "equity": 1478.9,
      "debt": 5.12,
      "gold": 5974.623891,
      "pb": 3.18,
      "pe": 16.3,
      "yield": 1.77,
      "date": "2003-10-06"
  },
  "2003-10-07": {
      "equity": 1477.8,
      "debt": 5.065,
      "gold": 6008.691511,
      "pb": 3.18,
      "pe": 16.28,
      "yield": 1.77,
      "date": "2003-10-07"
  },
  "2003-10-08": {
      "equity": 1478.6,
      "debt": 5.065,
      "gold": 6029.690118,
      "pb": 3.18,
      "pe": 16.29,
      "yield": 1.77,
      "date": "2003-10-08"
  },
  "2003-10-09": {
      "equity": 1502.1,
      "debt": 5.059,
      "gold": 6007.372264,
      "pb": 3.23,
      "pe": 16.55,
      "yield": 1.84,
      "date": "2003-10-09"
  },
  "2003-10-10": {
      "equity": 1523.1,
      "debt": 5.058,
      "gold": 5927.011058,
      "pb": 3.28,
      "pe": 16.78,
      "yield": 1.81,
      "date": "2003-10-10"
  },
  "2003-10-11": {
      "equity": 1523.1,
      "debt": 5.039,
      "gold": 5927.011058,
      "pb": 3.28,
      "pe": 16.78,
      "yield": 1.81,
      "date": "2003-10-11"
  },
  "2003-10-12": {
      "equity": 1523.1,
      "debt": 5.039,
      "gold": 5927.011058,
      "pb": 3.28,
      "pe": 16.78,
      "yield": 1.81,
      "date": "2003-10-12"
  },
  "2003-10-13": {
      "equity": 1546.7,
      "debt": 5.033,
      "gold": 5939.44514,
      "pb": 3.33,
      "pe": 17.04,
      "yield": 1.78,
      "date": "2003-10-13"
  },
  "2003-10-14": {
      "equity": 1520.8,
      "debt": 5.019,
      "gold": 6017.38655,
      "pb": 3.27,
      "pe": 16.76,
      "yield": 1.81,
      "date": "2003-10-14"
  },
  "2003-10-15": {
      "equity": 1537,
      "debt": 4.985,
      "gold": 6035.700806,
      "pb": 3.3,
      "pe": 16.94,
      "yield": 1.79,
      "date": "2003-10-15"
  },
  "2003-10-16": {
      "equity": 1555.7,
      "debt": 4.962,
      "gold": 5932.757191,
      "pb": 3.35,
      "pe": 17.14,
      "yield": 1.77,
      "date": "2003-10-16"
  },
  "2003-10-17": {
      "equity": 1569.4,
      "debt": 4.973,
      "gold": 5942.34819,
      "pb": 3.37,
      "pe": 17.29,
      "yield": 1.76,
      "date": "2003-10-17"
  },
  "2003-10-18": {
      "equity": 1569.4,
      "debt": 4.971,
      "gold": 5942.34819,
      "pb": 3.37,
      "pe": 17.29,
      "yield": 1.76,
      "date": "2003-10-18"
  },
  "2003-10-19": {
      "equity": 1569.4,
      "debt": 4.971,
      "gold": 5942.34819,
      "pb": 3.37,
      "pe": 17.29,
      "yield": 1.76,
      "date": "2003-10-19"
  },
  "2003-10-20": {
      "equity": 1542.7,
      "debt": 5.007,
      "gold": 5938.0624,
      "pb": 3.32,
      "pe": 17,
      "yield": 1.79,
      "date": "2003-10-20"
  },
  "2003-10-21": {
      "equity": 1506.5,
      "debt": 5.041,
      "gold": 5988.973351,
      "pb": 3.24,
      "pe": 16.6,
      "yield": 1.83,
      "date": "2003-10-21"
  },
  "2003-10-22": {
      "equity": 1494.1,
      "debt": 5.076,
      "gold": 6097.504365,
      "pb": 3.21,
      "pe": 16.46,
      "yield": 1.85,
      "date": "2003-10-22"
  },
  "2003-10-23": {
      "equity": 1470.4,
      "debt": 5.129,
      "gold": 6175.830262,
      "pb": 3.16,
      "pe": 16.21,
      "yield": 1.87,
      "date": "2003-10-23"
  },
  "2003-10-24": {
      "equity": 1506,
      "debt": 5.093,
      "gold": 6146.926754,
      "pb": 3.24,
      "pe": 16.6,
      "yield": 1.83,
      "date": "2003-10-24"
  },
  "2003-10-25": {
      "equity": 1521.9,
      "debt": 5.093,
      "gold": 6146.926754,
      "pb": 3.27,
      "pe": 16.78,
      "yield": 1.81,
      "date": "2003-10-25"
  },
  "2003-10-26": {
      "equity": 1521.9,
      "debt": 5.093,
      "gold": 6146.926754,
      "pb": 3.27,
      "pe": 16.78,
      "yield": 1.81,
      "date": "2003-10-26"
  },
  "2003-10-27": {
      "equity": 1485.3,
      "debt": 5.115,
      "gold": 6192.885236,
      "pb": 3.19,
      "pe": 16.37,
      "yield": 1.86,
      "date": "2003-10-27"
  },
  "2003-10-28": {
      "equity": 1481.7,
      "debt": 5.186,
      "gold": 6176.729748,
      "pb": 3.19,
      "pe": 16.33,
      "yield": 1.86,
      "date": "2003-10-28"
  },
  "2003-10-29": {
      "equity": 1498.4,
      "debt": 5.103,
      "gold": 6118.584102,
      "pb": 3.22,
      "pe": 16.52,
      "yield": 1.84,
      "date": "2003-10-29"
  },
  "2003-10-30": {
      "equity": 1516.8,
      "debt": 5.102,
      "gold": 6241.630364,
      "pb": 3.26,
      "pe": 16.72,
      "yield": 1.82,
      "date": "2003-10-30"
  },
  "2003-10-31": {
      "equity": 1555.9,
      "debt": 5.1,
      "gold": 6141.498086,
      "pb": 3.35,
      "pe": 17.15,
      "yield": 1.77,
      "date": "2003-10-31"
  },
  "2003-11-01": {
      "equity": 1555.9,
      "debt": 5.071,
      "gold": 6141.498086,
      "pb": 3.35,
      "pe": 17.15,
      "yield": 1.77,
      "date": "2003-11-01"
  },
  "2003-11-02": {
      "equity": 1555.9,
      "debt": 5.071,
      "gold": 6141.498086,
      "pb": 3.35,
      "pe": 17.15,
      "yield": 1.77,
      "date": "2003-11-02"
  },
  "2003-11-03": {
      "equity": 1601.6,
      "debt": 5.089,
      "gold": 6120.615884,
      "pb": 3.45,
      "pe": 17.65,
      "yield": 1.72,
      "date": "2003-11-03"
  },
  "2003-11-04": {
      "equity": 1618.7,
      "debt": 5.077,
      "gold": 6039.563308,
      "pb": 3.48,
      "pe": 17.84,
      "yield": 1.7,
      "date": "2003-11-04"
  },
  "2003-11-05": {
      "equity": 1609.1,
      "debt": 5.064,
      "gold": 6106.418103,
      "pb": 3.46,
      "pe": 17.74,
      "yield": 1.71,
      "date": "2003-11-05"
  },
  "2003-11-06": {
      "equity": 1612.2,
      "debt": 5.058,
      "gold": 6103.09882,
      "pb": 3.47,
      "pe": 17.77,
      "yield": 1.71,
      "date": "2003-11-06"
  },
  "2003-11-07": {
      "equity": 1592,
      "debt": 5.064,
      "gold": 6088.043881,
      "pb": 3.42,
      "pe": 17.55,
      "yield": 1.73,
      "date": "2003-11-07"
  },
  "2003-11-08": {
      "equity": 1592,
      "debt": 5.064,
      "gold": 6088.043881,
      "pb": 3.42,
      "pe": 17.55,
      "yield": 1.73,
      "date": "2003-11-08"
  },
  "2003-11-09": {
      "equity": 1592,
      "debt": 5.064,
      "gold": 6088.043881,
      "pb": 3.42,
      "pe": 17.55,
      "yield": 1.73,
      "date": "2003-11-09"
  },
  "2003-11-10": {
      "equity": 1594.5,
      "debt": 5.062,
      "gold": 6105.451595,
      "pb": 3.43,
      "pe": 17.58,
      "yield": 1.73,
      "date": "2003-11-10"
  },
  "2003-11-11": {
      "equity": 1601.1,
      "debt": 5.062,
      "gold": 6172.969541,
      "pb": 3.44,
      "pe": 17.65,
      "yield": 1.72,
      "date": "2003-11-11"
  },
  "2003-11-12": {
      "equity": 1603.8,
      "debt": 5.079,
      "gold": 6204.804318,
      "pb": 3.45,
      "pe": 17.68,
      "yield": 1.72,
      "date": "2003-11-12"
  },
  "2003-11-13": {
      "equity": 1579.9,
      "debt": 5.097,
      "gold": 6327.413182,
      "pb": 3.4,
      "pe": 17.42,
      "yield": 1.74,
      "date": "2003-11-13"
  },
  "2003-11-14": {
      "equity": 1550.4,
      "debt": 5.102,
      "gold": 6334.079966,
      "pb": 3.34,
      "pe": 17.09,
      "yield": 1.78,
      "date": "2003-11-14"
  },
  "2003-11-15": {
      "equity": 1562.8,
      "debt": 5.095,
      "gold": 6334.079966,
      "pb": 3.36,
      "pe": 17.23,
      "yield": 1.76,
      "date": "2003-11-15"
  },
  "2003-11-16": {
      "equity": 1562.8,
      "debt": 5.095,
      "gold": 6334.079966,
      "pb": 3.36,
      "pe": 17.23,
      "yield": 1.76,
      "date": "2003-11-16"
  },
  "2003-11-17": {
      "equity": 1579.9,
      "debt": 5.178,
      "gold": 6387.76698,
      "pb": 3.4,
      "pe": 17.42,
      "yield": 1.74,
      "date": "2003-11-17"
  },
  "2003-11-18": {
      "equity": 1564.4,
      "debt": 5.142,
      "gold": 6315.882114,
      "pb": 3.37,
      "pe": 17.24,
      "yield": 1.76,
      "date": "2003-11-18"
  },
  "2003-11-19": {
      "equity": 1540.6,
      "debt": 5.13,
      "gold": 6391.636537,
      "pb": 3.31,
      "pe": 16.98,
      "yield": 1.79,
      "date": "2003-11-19"
  },
  "2003-11-20": {
      "equity": 1522.3,
      "debt": 5.111,
      "gold": 6384.461807,
      "pb": 3.27,
      "pe": 16.78,
      "yield": 1.81,
      "date": "2003-11-20"
  },
  "2003-11-21": {
      "equity": 1540.7,
      "debt": 5.105,
      "gold": 6355.893402,
      "pb": 3.31,
      "pe": 16.98,
      "yield": 1.79,
      "date": "2003-11-21"
  },
  "2003-11-22": {
      "equity": 1540.7,
      "debt": 5.104,
      "gold": 6355.893402,
      "pb": 3.31,
      "pe": 16.98,
      "yield": 1.79,
      "date": "2003-11-22"
  },
  "2003-11-23": {
      "equity": 1540.7,
      "debt": 5.104,
      "gold": 6355.893402,
      "pb": 3.31,
      "pe": 16.98,
      "yield": 1.79,
      "date": "2003-11-23"
  },
  "2003-11-24": {
      "equity": 1543.9,
      "debt": 5.11,
      "gold": 6377.646872,
      "pb": 3.32,
      "pe": 17.02,
      "yield": 1.79,
      "date": "2003-11-24"
  },
  "2003-11-25": {
      "equity": 1568.6,
      "debt": 5.131,
      "gold": 6342.136546,
      "pb": 3.37,
      "pe": 17.29,
      "yield": 1.76,
      "date": "2003-11-25"
  },
  "2003-11-26": {
      "equity": 1568.6,
      "debt": 5.131,
      "gold": 6344.013122,
      "pb": 3.37,
      "pe": 17.29,
      "yield": 1.76,
      "date": "2003-11-26"
  },
  "2003-11-27": {
      "equity": 1598.3,
      "debt": 5.118,
      "gold": 6414.889151,
      "pb": 3.44,
      "pe": 17.62,
      "yield": 1.72,
      "date": "2003-11-27"
  },
  "2003-11-28": {
      "equity": 1615.2,
      "debt": 5.147,
      "gold": 6427.287959,
      "pb": 3.47,
      "pe": 17.81,
      "yield": 1.71,
      "date": "2003-11-28"
  },
  "2003-11-29": {
      "equity": 1615.2,
      "debt": 5.158,
      "gold": 6427.287959,
      "pb": 3.47,
      "pe": 17.81,
      "yield": 1.71,
      "date": "2003-11-29"
  },
  "2003-11-30": {
      "equity": 1615.2,
      "debt": 5.158,
      "gold": 6427.287959,
      "pb": 3.47,
      "pe": 17.81,
      "yield": 1.71,
      "date": "2003-11-30"
  },
  "2003-12-01": {
      "equity": 1657.6,
      "debt": 5.215,
      "gold": 6427.231521,
      "pb": 3.57,
      "pe": 18.27,
      "yield": 1.66,
      "date": "2003-12-01"
  },
  "2003-12-02": {
      "equity": 1658.5,
      "debt": 5.208,
      "gold": 6456.523043,
      "pb": 3.57,
      "pe": 18.29,
      "yield": 1.66,
      "date": "2003-12-02"
  },
  "2003-12-03": {
      "equity": 1670.5,
      "debt": 5.166,
      "gold": 6490.611827,
      "pb": 3.59,
      "pe": 18.42,
      "yield": 1.65,
      "date": "2003-12-03"
  },
  "2003-12-04": {
      "equity": 1675.2,
      "debt": 5.165,
      "gold": 6471.870756,
      "pb": 3.6,
      "pe": 18.47,
      "yield": 1.65,
      "date": "2003-12-04"
  },
  "2003-12-05": {
      "equity": 1645.8,
      "debt": 5.189,
      "gold": 6465.43678,
      "pb": 3.54,
      "pe": 18.15,
      "yield": 1.67,
      "date": "2003-12-05"
  },
  "2003-12-06": {
      "equity": 1645.8,
      "debt": 5.176,
      "gold": 6465.43678,
      "pb": 3.54,
      "pe": 18.15,
      "yield": 1.67,
      "date": "2003-12-06"
  },
  "2003-12-07": {
      "equity": 1645.8,
      "debt": 5.176,
      "gold": 6465.43678,
      "pb": 3.54,
      "pe": 18.15,
      "yield": 1.67,
      "date": "2003-12-07"
  },
  "2003-12-08": {
      "equity": 1646.2,
      "debt": 5.174,
      "gold": 6568.951833,
      "pb": 3.54,
      "pe": 18.15,
      "yield": 1.67,
      "date": "2003-12-08"
  },
  "2003-12-09": {
      "equity": 1675.8,
      "debt": 5.181,
      "gold": 6555.156176,
      "pb": 3.61,
      "pe": 18.48,
      "yield": 1.64,
      "date": "2003-12-09"
  },
  "2003-12-10": {
      "equity": 1686.9,
      "debt": 5.214,
      "gold": 6539.184113,
      "pb": 3.63,
      "pe": 18.6,
      "yield": 1.63,
      "date": "2003-12-10"
  },
  "2003-12-11": {
      "equity": 1695.4,
      "debt": 5.214,
      "gold": 6489.052717,
      "pb": 3.65,
      "pe": 18.69,
      "yield": 1.63,
      "date": "2003-12-11"
  },
  "2003-12-12": {
      "equity": 1698.9,
      "debt": 5.18,
      "gold": 6529.381471,
      "pb": 3.66,
      "pe": 18.73,
      "yield": 1.62,
      "date": "2003-12-12"
  },
  "2003-12-13": {
      "equity": 1698.9,
      "debt": 5.193,
      "gold": 6529.381471,
      "pb": 3.66,
      "pe": 18.73,
      "yield": 1.62,
      "date": "2003-12-13"
  },
  "2003-12-14": {
      "equity": 1698.9,
      "debt": 5.193,
      "gold": 6529.381471,
      "pb": 3.66,
      "pe": 18.73,
      "yield": 1.62,
      "date": "2003-12-14"
  },
  "2003-12-15": {
      "equity": 1723.9,
      "debt": 5.203,
      "gold": 6497.084605,
      "pb": 3.71,
      "pe": 19.01,
      "yield": 1.6,
      "date": "2003-12-15"
  },
  "2003-12-16": {
      "equity": 1736.2,
      "debt": 5.208,
      "gold": 6568.323956,
      "pb": 3.74,
      "pe": 19.15,
      "yield": 1.59,
      "date": "2003-12-16"
  },
  "2003-12-17": {
      "equity": 1733.2,
      "debt": 5.193,
      "gold": 6540.958394,
      "pb": 3.73,
      "pe": 19.11,
      "yield": 1.59,
      "date": "2003-12-17"
  },
  "2003-12-18": {
      "equity": 1756.1,
      "debt": 5.153,
      "gold": 6595.297977,
      "pb": 3.78,
      "pe": 19.37,
      "yield": 1.57,
      "date": "2003-12-18"
  },
  "2003-12-19": {
      "equity": 1778.5,
      "debt": 5.153,
      "gold": 6561.74183,
      "pb": 3.83,
      "pe": 19.61,
      "yield": 1.55,
      "date": "2003-12-19"
  },
  "2003-12-20": {
      "equity": 1778.5,
      "debt": 5.158,
      "gold": 6561.74183,
      "pb": 3.83,
      "pe": 19.61,
      "yield": 1.55,
      "date": "2003-12-20"
  },
  "2003-12-21": {
      "equity": 1778.5,
      "debt": 5.158,
      "gold": 6561.74183,
      "pb": 3.83,
      "pe": 19.61,
      "yield": 1.55,
      "date": "2003-12-21"
  },
  "2003-12-22": {
      "equity": 1789.1,
      "debt": 5.152,
      "gold": 6598.275102,
      "pb": 3.85,
      "pe": 19.73,
      "yield": 1.54,
      "date": "2003-12-22"
  },
  "2003-12-23": {
      "equity": 1780.3,
      "debt": 5.145,
      "gold": 6579.001393,
      "pb": 3.83,
      "pe": 19.63,
      "yield": 1.55,
      "date": "2003-12-23"
  },
  "2003-12-24": {
      "equity": 1808.7,
      "debt": 5.145,
      "gold": 6589.580063,
      "pb": 3.89,
      "pe": 19.95,
      "yield": 1.52,
      "date": "2003-12-24"
  },
  "2003-12-25": {
      "equity": 1808.7,
      "debt": 5.145,
      "gold": 6589.580063,
      "pb": 3.89,
      "pe": 19.95,
      "yield": 1.52,
      "date": "2003-12-25"
  },
  "2003-12-26": {
      "equity": 1837,
      "debt": 5.11,
      "gold": 6589.580063,
      "pb": 3.95,
      "pe": 20.26,
      "yield": 1.5,
      "date": "2003-12-26"
  },
  "2003-12-27": {
      "equity": 1837,
      "debt": 5.108,
      "gold": 6589.580063,
      "pb": 3.95,
      "pe": 20.26,
      "yield": 1.5,
      "date": "2003-12-27"
  },
  "2003-12-28": {
      "equity": 1837,
      "debt": 5.108,
      "gold": 6589.580063,
      "pb": 3.95,
      "pe": 20.26,
      "yield": 1.5,
      "date": "2003-12-28"
  },
  "2003-12-29": {
      "equity": 1874,
      "debt": 5.118,
      "gold": 6649.665779,
      "pb": 4.03,
      "pe": 20.67,
      "yield": 1.47,
      "date": "2003-12-29"
  },
  "2003-12-30": {
      "equity": 1873.2,
      "debt": 5.106,
      "gold": 6675.373463,
      "pb": 4.03,
      "pe": 20.66,
      "yield": 1.47,
      "date": "2003-12-30"
  },
  "2003-12-31": {
      "equity": 1879.7,
      "debt": 5.122,
      "gold": 6715.120196,
      "pb": 4.05,
      "pe": 20.73,
      "yield": 1.47,
      "date": "2003-12-31"
  },
  "2004-01-01": {
      "equity": 1912.2,
      "debt": 5.127,
      "gold": 6715.120196,
      "pb": 4.12,
      "pe": 21.09,
      "yield": 1.44,
      "date": "2004-01-01"
  },
  "2004-01-02": {
      "equity": 1946,
      "debt": 5.121,
      "gold": 6682.862132,
      "pb": 4.19,
      "pe": 21.46,
      "yield": 1.42,
      "date": "2004-01-02"
  },
  "2004-01-03": {
      "equity": 1946,
      "debt": 5.092,
      "gold": 6682.862132,
      "pb": 4.19,
      "pe": 21.46,
      "yield": 1.42,
      "date": "2004-01-03"
  },
  "2004-01-04": {
      "equity": 1946,
      "debt": 5.092,
      "gold": 6682.862132,
      "pb": 4.19,
      "pe": 21.46,
      "yield": 1.42,
      "date": "2004-01-04"
  },
  "2004-01-05": {
      "equity": 1955,
      "debt": 5.12,
      "gold": 6712.114852,
      "pb": 4.21,
      "pe": 21.56,
      "yield": 1.41,
      "date": "2004-01-05"
  },
  "2004-01-06": {
      "equity": 1926.7,
      "debt": 5.116,
      "gold": 6885.109085,
      "pb": 4.15,
      "pe": 21.25,
      "yield": 1.43,
      "date": "2004-01-06"
  },
  "2004-01-07": {
      "equity": 1916.7,
      "debt": 5.119,
      "gold": 6757.226759,
      "pb": 4.12,
      "pe": 21.14,
      "yield": 1.44,
      "date": "2004-01-07"
  },
  "2004-01-08": {
      "equity": 1968.5,
      "debt": 5.113,
      "gold": 6708.153583,
      "pb": 4.24,
      "pe": 21.71,
      "yield": 1.4,
      "date": "2004-01-08"
  },
  "2004-01-09": {
      "equity": 1971.9,
      "debt": 5.118,
      "gold": 6768.648477,
      "pb": 4.24,
      "pe": 21.75,
      "yield": 1.4,
      "date": "2004-01-09"
  },
  "2004-01-10": {
      "equity": 1971.9,
      "debt": 5.131,
      "gold": 6768.648477,
      "pb": 4.24,
      "pe": 21.75,
      "yield": 1.4,
      "date": "2004-01-10"
  },
  "2004-01-11": {
      "equity": 1971.9,
      "debt": 5.131,
      "gold": 6768.648477,
      "pb": 4.24,
      "pe": 21.75,
      "yield": 1.4,
      "date": "2004-01-11"
  },
  "2004-01-12": {
      "equity": 1945.6,
      "debt": 5.14,
      "gold": 6859.627154,
      "pb": 4.19,
      "pe": 21.46,
      "yield": 1.42,
      "date": "2004-01-12"
  },
  "2004-01-13": {
      "equity": 1963.6,
      "debt": 5.128,
      "gold": 6797.361505,
      "pb": 4.23,
      "pe": 21.66,
      "yield": 1.4,
      "date": "2004-01-13"
  },
  "2004-01-14": {
      "equity": 1982.1,
      "debt": 5.124,
      "gold": 6745.773294,
      "pb": 4.27,
      "pe": 21.86,
      "yield": 1.39,
      "date": "2004-01-14"
  },
  "2004-01-15": {
      "equity": 1944.4,
      "debt": 5.139,
      "gold": 6657.644756,
      "pb": 4.18,
      "pe": 21.44,
      "yield": 1.42,
      "date": "2004-01-15"
  },
  "2004-01-16": {
      "equity": 1900.6,
      "debt": 5.138,
      "gold": 6570.704951,
      "pb": 4.09,
      "pe": 20.96,
      "yield": 1.45,
      "date": "2004-01-16"
  },
  "2004-01-17": {
      "equity": 1900.6,
      "debt": 5.137,
      "gold": 6570.704951,
      "pb": 4.09,
      "pe": 20.96,
      "yield": 1.45,
      "date": "2004-01-17"
  },
  "2004-01-18": {
      "equity": 1900.6,
      "debt": 5.137,
      "gold": 6570.704951,
      "pb": 4.09,
      "pe": 20.96,
      "yield": 1.45,
      "date": "2004-01-18"
  },
  "2004-01-19": {
      "equity": 1935.3,
      "debt": 5.143,
      "gold": 6522.3302,
      "pb": 4.17,
      "pe": 21.35,
      "yield": 1.42,
      "date": "2004-01-19"
  },
  "2004-01-20": {
      "equity": 1893.2,
      "debt": 5.142,
      "gold": 6539.395757,
      "pb": 4.07,
      "pe": 20.88,
      "yield": 1.46,
      "date": "2004-01-20"
  },
  "2004-01-21": {
      "equity": 1824.6,
      "debt": 5.145,
      "gold": 6583.742218,
      "pb": 3.93,
      "pe": 20.13,
      "yield": 1.51,
      "date": "2004-01-21"
  },
  "2004-01-22": {
      "equity": 1770.5,
      "debt": 5.138,
      "gold": 6594.296196,
      "pb": 3.81,
      "pe": 19.53,
      "yield": 1.56,
      "date": "2004-01-22"
  },
  "2004-01-23": {
      "equity": 1847.5,
      "debt": 5.135,
      "gold": 6581.064922,
      "pb": 3.98,
      "pe": 20.38,
      "yield": 1.49,
      "date": "2004-01-23"
  },
  "2004-01-24": {
      "equity": 1847.5,
      "debt": 5.134,
      "gold": 6581.064922,
      "pb": 3.98,
      "pe": 20.38,
      "yield": 1.49,
      "date": "2004-01-24"
  },
  "2004-01-25": {
      "equity": 1847.5,
      "debt": 5.134,
      "gold": 6581.064922,
      "pb": 3.98,
      "pe": 20.38,
      "yield": 1.49,
      "date": "2004-01-25"
  },
  "2004-01-26": {
      "equity": 1847.5,
      "debt": 5.134,
      "gold": 6490.717649,
      "pb": 3.98,
      "pe": 20.38,
      "yield": 1.49,
      "date": "2004-01-26"
  },
  "2004-01-27": {
      "equity": 1904.7,
      "debt": 5.143,
      "gold": 6461.976402,
      "pb": 4.1,
      "pe": 21.01,
      "yield": 1.45,
      "date": "2004-01-27"
  },
  "2004-01-28": {
      "equity": 1863.1,
      "debt": 5.149,
      "gold": 6564.796557,
      "pb": 4.01,
      "pe": 20.55,
      "yield": 1.48,
      "date": "2004-01-28"
  },
  "2004-01-29": {
      "equity": 1843.6,
      "debt": 5.19,
      "gold": 6548.771583,
      "pb": 3.97,
      "pe": 20.34,
      "yield": 1.49,
      "date": "2004-01-29"
  },
  "2004-01-30": {
      "equity": 1809.7,
      "debt": 5.208,
      "gold": 6403.925995,
      "pb": 3.9,
      "pe": 19.96,
      "yield": 1.52,
      "date": "2004-01-30"
  },
  "2004-01-31": {
      "equity": 1809.7,
      "debt": 5.223,
      "gold": 6403.925995,
      "pb": 3.9,
      "pe": 19.96,
      "yield": 1.52,
      "date": "2004-01-31"
  },
  "2004-02-01": {
      "equity": 1809.7,
      "debt": 5.223,
      "gold": 6403.925995,
      "pb": 3.9,
      "pe": 19.96,
      "yield": 1.52,
      "date": "2004-02-01"
  },
  "2004-02-02": {
      "equity": 1809.7,
      "debt": 5.223,
      "gold": 6419.083229,
      "pb": 3.9,
      "pe": 19.96,
      "yield": 1.52,
      "date": "2004-02-02"
  },
  "2004-02-03": {
      "equity": 1769,
      "debt": 5.196,
      "gold": 6416.067303,
      "pb": 3.81,
      "pe": 19.55,
      "yield": 1.55,
      "date": "2004-02-03"
  },
  "2004-02-04": {
      "equity": 1822.2,
      "debt": 5.169,
      "gold": 6378.913208,
      "pb": 3.93,
      "pe": 20.14,
      "yield": 1.51,
      "date": "2004-02-04"
  },
  "2004-02-05": {
      "equity": 1804.5,
      "debt": 5.194,
      "gold": 6366.376832,
      "pb": 3.89,
      "pe": 19.94,
      "yield": 1.52,
      "date": "2004-02-05"
  },
  "2004-02-06": {
      "equity": 1833.6,
      "debt": 5.216,
      "gold": 6331.325067,
      "pb": 3.95,
      "pe": 20.27,
      "yield": 1.5,
      "date": "2004-02-06"
  },
  "2004-02-07": {
      "equity": 1833.6,
      "debt": 5.219,
      "gold": 6331.325067,
      "pb": 3.95,
      "pe": 20.27,
      "yield": 1.5,
      "date": "2004-02-07"
  },
  "2004-02-08": {
      "equity": 1833.6,
      "debt": 5.219,
      "gold": 6331.325067,
      "pb": 3.95,
      "pe": 20.27,
      "yield": 1.5,
      "date": "2004-02-08"
  },
  "2004-02-09": {
      "equity": 1880.7,
      "debt": 5.214,
      "gold": 6487.948641,
      "pb": 4.06,
      "pe": 20.78,
      "yield": 1.46,
      "date": "2004-02-09"
  },
  "2004-02-10": {
      "equity": 1880.7,
      "debt": 5.232,
      "gold": 6514.270093,
      "pb": 4.06,
      "pe": 20.79,
      "yield": 1.46,
      "date": "2004-02-10"
  },
  "2004-02-11": {
      "equity": 1891.5,
      "debt": 5.287,
      "gold": 6488.537717,
      "pb": 4.08,
      "pe": 20.71,
      "yield": 1.45,
      "date": "2004-02-11"
  },
  "2004-02-12": {
      "equity": 1885.3,
      "debt": 5.278,
      "gold": 6561.473747,
      "pb": 4.07,
      "pe": 20.64,
      "yield": 1.46,
      "date": "2004-02-12"
  },
  "2004-02-13": {
      "equity": 1913.6,
      "debt": 5.283,
      "gold": 6575.544542,
      "pb": 4.13,
      "pe": 20.95,
      "yield": 1.44,
      "date": "2004-02-13"
  },
  "2004-02-14": {
      "equity": 1913.6,
      "debt": 5.293,
      "gold": 6575.544542,
      "pb": 4.13,
      "pe": 20.95,
      "yield": 1.44,
      "date": "2004-02-14"
  },
  "2004-02-15": {
      "equity": 1913.6,
      "debt": 5.293,
      "gold": 6575.544542,
      "pb": 4.13,
      "pe": 20.95,
      "yield": 1.44,
      "date": "2004-02-15"
  },
  "2004-02-16": {
      "equity": 1913.5,
      "debt": 5.335,
      "gold": 6562.260357,
      "pb": 4.13,
      "pe": 20.98,
      "yield": 1.43,
      "date": "2004-02-16"
  },
  "2004-02-17": {
      "equity": 1920.1,
      "debt": 5.311,
      "gold": 6607.830826,
      "pb": 4.15,
      "pe": 21.05,
      "yield": 1.43,
      "date": "2004-02-17"
  },
  "2004-02-18": {
      "equity": 1916.4,
      "debt": 5.311,
      "gold": 6642.046597,
      "pb": 4.14,
      "pe": 21.01,
      "yield": 1.43,
      "date": "2004-02-18"
  },
  "2004-02-19": {
      "equity": 1858.3,
      "debt": 5.328,
      "gold": 6545.953191,
      "pb": 4.01,
      "pe": 20.38,
      "yield": 1.48,
      "date": "2004-02-19"
  },
  "2004-02-20": {
      "equity": 1852.6,
      "debt": 5.298,
      "gold": 6517.166088,
      "pb": 4,
      "pe": 20.31,
      "yield": 1.48,
      "date": "2004-02-20"
  },
  "2004-02-21": {
      "equity": 1852.6,
      "debt": 5.268,
      "gold": 6517.166088,
      "pb": 4,
      "pe": 20.31,
      "yield": 1.48,
      "date": "2004-02-21"
  },
  "2004-02-22": {
      "equity": 1852.6,
      "debt": 5.268,
      "gold": 6517.166088,
      "pb": 4,
      "pe": 20.31,
      "yield": 1.48,
      "date": "2004-02-22"
  },
  "2004-02-23": {
      "equity": 1808.2,
      "debt": 5.267,
      "gold": 6387.135576,
      "pb": 3.91,
      "pe": 19.83,
      "yield": 1.52,
      "date": "2004-02-23"
  },
  "2004-02-24": {
      "equity": 1821.3,
      "debt": 5.25,
      "gold": 6404.903085,
      "pb": 3.93,
      "pe": 19.97,
      "yield": 1.51,
      "date": "2004-02-24"
  },
  "2004-02-25": {
      "equity": 1786.8,
      "debt": 5.232,
      "gold": 6431.746592,
      "pb": 3.86,
      "pe": 19.6,
      "yield": 1.54,
      "date": "2004-02-25"
  },
  "2004-02-26": {
      "equity": 1765.8,
      "debt": 5.255,
      "gold": 6303.493889,
      "pb": 3.81,
      "pe": 19.37,
      "yield": 1.55,
      "date": "2004-02-26"
  },
  "2004-02-27": {
      "equity": 1800.3,
      "debt": 5.265,
      "gold": 6258.815852,
      "pb": 3.89,
      "pe": 19.74,
      "yield": 1.52,
      "date": "2004-02-27"
  },
  "2004-02-28": {
      "equity": 1800.3,
      "debt": 5.261,
      "gold": 6258.815852,
      "pb": 3.89,
      "pe": 19.74,
      "yield": 1.52,
      "date": "2004-02-28"
  },
  "2004-02-29": {
      "equity": 1800.3,
      "debt": 5.261,
      "gold": 6258.815852,
      "pb": 3.89,
      "pe": 19.74,
      "yield": 1.52,
      "date": "2004-02-29"
  },
  "2004-03-01": {
      "equity": 1852.7,
      "debt": 5.257,
      "gold": 6358.891691,
      "pb": 4.26,
      "pe": 21.57,
      "yield": 1.39,
      "date": "2004-03-01"
  },
  "2004-03-02": {
      "equity": 1852.7,
      "debt": 5.257,
      "gold": 6360.489603,
      "pb": 4.26,
      "pe": 21.57,
      "yield": 1.39,
      "date": "2004-03-02"
  },
  "2004-03-03": {
      "equity": 1860.4,
      "debt": 5.249,
      "gold": 6225.746486,
      "pb": 3.67,
      "pe": 21.72,
      "yield": 1.38,
      "date": "2004-03-03"
  },
  "2004-03-04": {
      "equity": 1843.8,
      "debt": 5.243,
      "gold": 6293.99813,
      "pb": 3.64,
      "pe": 21.53,
      "yield": 1.39,
      "date": "2004-03-04"
  },
  "2004-03-05": {
      "equity": 1867.7,
      "debt": 5.243,
      "gold": 6283.620522,
      "pb": 3.68,
      "pe": 21.81,
      "yield": 1.37,
      "date": "2004-03-05"
  },
  "2004-03-06": {
      "equity": 1867.7,
      "debt": 5.233,
      "gold": 6283.620522,
      "pb": 3.68,
      "pe": 21.81,
      "yield": 1.37,
      "date": "2004-03-06"
  },
  "2004-03-07": {
      "equity": 1867.7,
      "debt": 5.233,
      "gold": 6283.620522,
      "pb": 3.68,
      "pe": 21.81,
      "yield": 1.37,
      "date": "2004-03-07"
  },
  "2004-03-08": {
      "equity": 1885.2,
      "debt": 5.229,
      "gold": 6373.957213,
      "pb": 3.72,
      "pe": 22.01,
      "yield": 1.36,
      "date": "2004-03-08"
  },
  "2004-03-09": {
      "equity": 1866,
      "debt": 5.223,
      "gold": 6398.084622,
      "pb": 3.68,
      "pe": 21.79,
      "yield": 1.38,
      "date": "2004-03-09"
  },
  "2004-03-10": {
      "equity": 1844.3,
      "debt": 5.215,
      "gold": 6391.707085,
      "pb": 3.64,
      "pe": 21.54,
      "yield": 1.39,
      "date": "2004-03-10"
  },
  "2004-03-11": {
      "equity": 1805.4,
      "debt": 5.211,
      "gold": 6347.999083,
      "pb": 3.56,
      "pe": 21.08,
      "yield": 1.42,
      "date": "2004-03-11"
  },
  "2004-03-12": {
      "equity": 1812.2,
      "debt": 5.185,
      "gold": 6397.9788,
      "pb": 3.58,
      "pe": 21.16,
      "yield": 1.42,
      "date": "2004-03-12"
  },
  "2004-03-13": {
      "equity": 1812.2,
      "debt": 5.186,
      "gold": 6397.9788,
      "pb": 3.58,
      "pe": 21.16,
      "yield": 1.42,
      "date": "2004-03-13"
  },
  "2004-03-14": {
      "equity": 1812.2,
      "debt": 5.186,
      "gold": 6397.9788,
      "pb": 3.58,
      "pe": 21.16,
      "yield": 1.42,
      "date": "2004-03-14"
  },
  "2004-03-15": {
      "equity": 1763.4,
      "debt": 5.203,
      "gold": 6367.92536,
      "pb": 3.48,
      "pe": 20.59,
      "yield": 1.46,
      "date": "2004-03-15"
  },
  "2004-03-16": {
      "equity": 1749.3,
      "debt": 5.195,
      "gold": 6377.149509,
      "pb": 3.45,
      "pe": 20.43,
      "yield": 1.79,
      "date": "2004-03-16"
  },
  "2004-03-17": {
      "equity": 1749.8,
      "debt": 5.169,
      "gold": 6415.9897,
      "pb": 3.45,
      "pe": 20.44,
      "yield": 1.79,
      "date": "2004-03-17"
  },
  "2004-03-18": {
      "equity": 1716.6,
      "debt": 5.177,
      "gold": 6496.922344,
      "pb": 3.39,
      "pe": 20.05,
      "yield": 1.82,
      "date": "2004-03-18"
  },
  "2004-03-19": {
      "equity": 1725.1,
      "debt": 5.159,
      "gold": 6548.584631,
      "pb": 3.4,
      "pe": 20.15,
      "yield": 1.82,
      "date": "2004-03-19"
  },
  "2004-03-20": {
      "equity": 1725.1,
      "debt": 5.163,
      "gold": 6548.584631,
      "pb": 3.4,
      "pe": 20.15,
      "yield": 1.82,
      "date": "2004-03-20"
  },
  "2004-03-21": {
      "equity": 1725.1,
      "debt": 5.163,
      "gold": 6548.584631,
      "pb": 3.4,
      "pe": 20.15,
      "yield": 1.82,
      "date": "2004-03-21"
  },
  "2004-03-22": {
      "equity": 1685,
      "debt": 5.168,
      "gold": 6587.008589,
      "pb": 3.32,
      "pe": 19.68,
      "yield": 1.86,
      "date": "2004-03-22"
  },
  "2004-03-23": {
      "equity": 1696.4,
      "debt": 5.182,
      "gold": 6571.639711,
      "pb": 3.35,
      "pe": 19.81,
      "yield": 1.85,
      "date": "2004-03-23"
  },
  "2004-03-24": {
      "equity": 1692.1,
      "debt": 5.173,
      "gold": 6591.139174,
      "pb": 3.34,
      "pe": 19.76,
      "yield": 1.85,
      "date": "2004-03-24"
  },
  "2004-03-25": {
      "equity": 1704.4,
      "debt": 5.167,
      "gold": 6574.239405,
      "pb": 3.36,
      "pe": 19.91,
      "yield": 1.84,
      "date": "2004-03-25"
  },
  "2004-03-26": {
      "equity": 1747.5,
      "debt": 5.161,
      "gold": 6550.521173,
      "pb": 3.45,
      "pe": 20.41,
      "yield": 1.79,
      "date": "2004-03-26"
  },
  "2004-03-27": {
      "equity": 1747.5,
      "debt": 5.16,
      "gold": 6550.521173,
      "pb": 3.45,
      "pe": 20.41,
      "yield": 1.79,
      "date": "2004-03-27"
  },
  "2004-03-28": {
      "equity": 1747.5,
      "debt": 5.16,
      "gold": 6550.521173,
      "pb": 3.45,
      "pe": 20.41,
      "yield": 1.79,
      "date": "2004-03-28"
  },
  "2004-03-29": {
      "equity": 1762,
      "debt": 5.158,
      "gold": 6542.993704,
      "pb": 3.48,
      "pe": 20.58,
      "yield": 1.78,
      "date": "2004-03-29"
  },
  "2004-03-30": {
      "equity": 1750.1,
      "debt": 5.158,
      "gold": 6514.312422,
      "pb": 3.45,
      "pe": 20.44,
      "yield": 1.79,
      "date": "2004-03-30"
  },
  "2004-03-31": {
      "equity": 1771.9,
      "debt": 5.147,
      "gold": 6505.511561,
      "pb": 3.5,
      "pe": 20.7,
      "yield": 1.77,
      "date": "2004-03-31"
  },
  "2004-04-01": {
      "equity": 1819.6,
      "debt": 5.127,
      "gold": 6540.884319,
      "pb": 3.59,
      "pe": 21.27,
      "yield": 1.72,
      "date": "2004-04-01"
  },
  "2004-04-02": {
      "equity": 1841.1,
      "debt": 5.111,
      "gold": 6573.477486,
      "pb": 3.64,
      "pe": 21.53,
      "yield": 1.7,
      "date": "2004-04-02"
  },
  "2004-04-03": {
      "equity": 1841.1,
      "debt": 5.111,
      "gold": 6573.477486,
      "pb": 3.64,
      "pe": 21.53,
      "yield": 1.7,
      "date": "2004-04-03"
  },
  "2004-04-04": {
      "equity": 1841.1,
      "debt": 5.111,
      "gold": 6573.477486,
      "pb": 3.64,
      "pe": 21.53,
      "yield": 1.7,
      "date": "2004-04-04"
  },
  "2004-04-05": {
      "equity": 1856.6,
      "debt": 5.128,
      "gold": 6480.812713,
      "pb": 3.67,
      "pe": 21.71,
      "yield": 1.69,
      "date": "2004-04-05"
  },
  "2004-04-06": {
      "equity": 1851.1,
      "debt": 5.131,
      "gold": 6432.321558,
      "pb": 3.66,
      "pe": 21.64,
      "yield": 1.69,
      "date": "2004-04-06"
  },
  "2004-04-07": {
      "equity": 1848.7,
      "debt": 5.121,
      "gold": 6438.596801,
      "pb": 3.65,
      "pe": 21.61,
      "yield": 1.69,
      "date": "2004-04-07"
  },
  "2004-04-08": {
      "equity": 1853.5,
      "debt": 5.115,
      "gold": 6493.109226,
      "pb": 3.66,
      "pe": 21.67,
      "yield": 1.69,
      "date": "2004-04-08"
  },
  "2004-04-09": {
      "equity": 1853.5,
      "debt": 5.115,
      "gold": 6493.109226,
      "pb": 3.66,
      "pe": 21.67,
      "yield": 1.69,
      "date": "2004-04-09"
  },
  "2004-04-10": {
      "equity": 1853.5,
      "debt": 5.113,
      "gold": 6493.109226,
      "pb": 3.66,
      "pe": 21.67,
      "yield": 1.69,
      "date": "2004-04-10"
  },
  "2004-04-11": {
      "equity": 1853.5,
      "debt": 5.113,
      "gold": 6493.109226,
      "pb": 3.66,
      "pe": 21.67,
      "yield": 1.69,
      "date": "2004-04-11"
  },
  "2004-04-12": {
      "equity": 1838.2,
      "debt": 5.081,
      "gold": 6493.109226,
      "pb": 3.61,
      "pe": 19.04,
      "yield": 1.95,
      "date": "2004-04-12"
  },
  "2004-04-13": {
      "equity": 1878.4,
      "debt": 5.08,
      "gold": 6449.605813,
      "pb": 3.69,
      "pe": 19.46,
      "yield": 1.9,
      "date": "2004-04-13"
  },
  "2004-04-14": {
      "equity": 1878.4,
      "debt": 5.08,
      "gold": 6268.84072,
      "pb": 3.69,
      "pe": 19.46,
      "yield": 1.9,
      "date": "2004-04-14"
  },
  "2004-04-15": {
      "equity": 1861.9,
      "debt": 5.078,
      "gold": 6175.777351,
      "pb": 3.65,
      "pe": 19.29,
      "yield": 1.92,
      "date": "2004-04-15"
  },
  "2004-04-16": {
      "equity": 1868.9,
      "debt": 5.079,
      "gold": 6187.421295,
      "pb": 3.67,
      "pe": 19.36,
      "yield": 1.91,
      "date": "2004-04-16"
  },
  "2004-04-17": {
      "equity": 1868.1,
      "debt": 5.061,
      "gold": 6187.421295,
      "pb": 3.67,
      "pe": 19.35,
      "yield": 1.91,
      "date": "2004-04-17"
  },
  "2004-04-18": {
      "equity": 1868.1,
      "debt": 5.061,
      "gold": 6187.421295,
      "pb": 3.67,
      "pe": 19.35,
      "yield": 1.91,
      "date": "2004-04-18"
  },
  "2004-04-19": {
      "equity": 1844,
      "debt": 5.069,
      "gold": 6275.828498,
      "pb": 3.62,
      "pe": 19.1,
      "yield": 1.94,
      "date": "2004-04-19"
  },
  "2004-04-20": {
      "equity": 1844.2,
      "debt": 5.062,
      "gold": 6182.564066,
      "pb": 3.62,
      "pe": 19.11,
      "yield": 1.94,
      "date": "2004-04-20"
  },
  "2004-04-21": {
      "equity": 1873.3,
      "debt": 5.07,
      "gold": 6118.859239,
      "pb": 3.68,
      "pe": 19.41,
      "yield": 1.91,
      "date": "2004-04-21"
  },
  "2004-04-22": {
      "equity": 1889.5,
      "debt": 5.067,
      "gold": 6093.296178,
      "pb": 3.71,
      "pe": 19.58,
      "yield": 1.89,
      "date": "2004-04-22"
  },
  "2004-04-23": {
      "equity": 1892.4,
      "debt": 5.071,
      "gold": 6154.21436,
      "pb": 3.73,
      "pe": 19.68,
      "yield": 1.88,
      "date": "2004-04-23"
  },
  "2004-04-24": {
      "equity": 1892.4,
      "debt": 5.079,
      "gold": 6154.21436,
      "pb": 3.73,
      "pe": 19.68,
      "yield": 1.88,
      "date": "2004-04-24"
  },
  "2004-04-25": {
      "equity": 1892.4,
      "debt": 5.079,
      "gold": 6154.21436,
      "pb": 3.73,
      "pe": 19.68,
      "yield": 1.88,
      "date": "2004-04-25"
  },
  "2004-04-26": {
      "equity": 1892.4,
      "debt": 5.079,
      "gold": 6165.787756,
      "pb": 3.73,
      "pe": 19.68,
      "yield": 1.88,
      "date": "2004-04-26"
  },
  "2004-04-27": {
      "equity": 1817.2,
      "debt": 5.112,
      "gold": 6196.208046,
      "pb": 3.58,
      "pe": 18.9,
      "yield": 1.96,
      "date": "2004-04-27"
  },
  "2004-04-28": {
      "equity": 1816.5,
      "debt": 5.123,
      "gold": 6181.572867,
      "pb": 3.58,
      "pe": 18.89,
      "yield": 1.96,
      "date": "2004-04-28"
  },
  "2004-04-29": {
      "equity": 1808.9,
      "debt": 5.135,
      "gold": 5997.199245,
      "pb": 3.56,
      "pe": 18.82,
      "yield": 1.97,
      "date": "2004-04-29"
  },
  "2004-04-30": {
      "equity": 1796.1,
      "debt": 5.117,
      "gold": 6079.41939,
      "pb": 3.54,
      "pe": 18.68,
      "yield": 1.98,
      "date": "2004-04-30"
  },
  "2004-05-01": {
      "equity": 1796.1,
      "debt": 5.117,
      "gold": 6079.41939,
      "pb": 3.54,
      "pe": 18.68,
      "yield": 1.98,
      "date": "2004-05-01"
  },
  "2004-05-02": {
      "equity": 1796.1,
      "debt": 5.117,
      "gold": 6079.41939,
      "pb": 3.54,
      "pe": 18.68,
      "yield": 1.98,
      "date": "2004-05-02"
  },
  "2004-05-03": {
      "equity": 1766.7,
      "debt": 5.124,
      "gold": 6079.41939,
      "pb": 3.48,
      "pe": 16.62,
      "yield": 2.02,
      "date": "2004-05-03"
  },
  "2004-05-04": {
      "equity": 1793.1,
      "debt": 5.124,
      "gold": 6146.059013,
      "pb": 3.53,
      "pe": 16.87,
      "yield": 1.96,
      "date": "2004-05-04"
  },
  "2004-05-05": {
      "equity": 1809.9,
      "debt": 5.113,
      "gold": 6215.030247,
      "pb": 3.57,
      "pe": 16.86,
      "yield": 1.94,
      "date": "2004-05-05"
  },
  "2004-05-06": {
      "equity": 1832.8,
      "debt": 5.112,
      "gold": 6178.08427,
      "pb": 3.61,
      "pe": 17.04,
      "yield": 2,
      "date": "2004-05-06"
  },
  "2004-05-07": {
      "equity": 1804.4,
      "debt": 5.128,
      "gold": 6094.18861,
      "pb": 3.56,
      "pe": 16.77,
      "yield": 2.03,
      "date": "2004-05-07"
  },
  "2004-05-08": {
      "equity": 1804.4,
      "debt": 5.165,
      "gold": 6094.18861,
      "pb": 3.56,
      "pe": 16.77,
      "yield": 2.03,
      "date": "2004-05-08"
  },
  "2004-05-09": {
      "equity": 1804.4,
      "debt": 5.165,
      "gold": 6094.18861,
      "pb": 3.56,
      "pe": 16.77,
      "yield": 2.03,
      "date": "2004-05-09"
  },
  "2004-05-10": {
      "equity": 1769.1,
      "debt": 5.189,
      "gold": 5942.15771,
      "pb": 3.49,
      "pe": 16.38,
      "yield": 2.07,
      "date": "2004-05-10"
  },
  "2004-05-11": {
      "equity": 1699.4,
      "debt": 5.195,
      "gold": 6054.773453,
      "pb": 3.35,
      "pe": 15.74,
      "yield": 2.16,
      "date": "2004-05-11"
  },
  "2004-05-12": {
      "equity": 1711.1,
      "debt": 5.187,
      "gold": 6100.523819,
      "pb": 3.37,
      "pe": 15.86,
      "yield": 2.14,
      "date": "2004-05-12"
  },
  "2004-05-13": {
      "equity": 1717.5,
      "debt": 5.184,
      "gold": 5996.186882,
      "pb": 3.39,
      "pe": 15.91,
      "yield": 2.14,
      "date": "2004-05-13"
  },
  "2004-05-14": {
      "equity": 1582.4,
      "debt": 5.229,
      "gold": 6011.679218,
      "pb": 3.12,
      "pe": 14.66,
      "yield": 2.32,
      "date": "2004-05-14"
  },
  "2004-05-15": {
      "equity": 1582.4,
      "debt": 5.227,
      "gold": 6011.679218,
      "pb": 3.12,
      "pe": 14.66,
      "yield": 2.32,
      "date": "2004-05-15"
  },
  "2004-05-16": {
      "equity": 1582.4,
      "debt": 5.227,
      "gold": 6011.679218,
      "pb": 3.12,
      "pe": 14.66,
      "yield": 2.32,
      "date": "2004-05-16"
  },
  "2004-05-17": {
      "equity": 1388.7,
      "debt": 5.257,
      "gold": 6115.070813,
      "pb": 2.74,
      "pe": 12.87,
      "yield": 2.64,
      "date": "2004-05-17"
  },
  "2004-05-18": {
      "equity": 1503.9,
      "debt": 5.187,
      "gold": 6080.149562,
      "pb": 2.97,
      "pe": 13.94,
      "yield": 2.44,
      "date": "2004-05-18"
  },
  "2004-05-19": {
      "equity": 1567.8,
      "debt": 5.183,
      "gold": 6067.884795,
      "pb": 3.09,
      "pe": 14.41,
      "yield": 2.34,
      "date": "2004-05-19"
  },
  "2004-05-20": {
      "equity": 1543.8,
      "debt": 5.185,
      "gold": 6085.296037,
      "pb": 3.04,
      "pe": 13.99,
      "yield": 2.38,
      "date": "2004-05-20"
  },
  "2004-05-21": {
      "equity": 1560.2,
      "debt": 5.191,
      "gold": 6105.994815,
      "pb": 3.08,
      "pe": 14.14,
      "yield": 2.35,
      "date": "2004-05-21"
  },
  "2004-05-22": {
      "equity": 1560.2,
      "debt": 5.177,
      "gold": 6105.994815,
      "pb": 3.08,
      "pe": 14.14,
      "yield": 2.35,
      "date": "2004-05-22"
  },
  "2004-05-23": {
      "equity": 1560.2,
      "debt": 5.177,
      "gold": 6105.994815,
      "pb": 3.08,
      "pe": 14.14,
      "yield": 2.35,
      "date": "2004-05-23"
  },
  "2004-05-24": {
      "equity": 1608.8,
      "debt": 5.178,
      "gold": 6138.662058,
      "pb": 3.15,
      "pe": 13.9,
      "yield": 2.3,
      "date": "2004-05-24"
  },
  "2004-05-25": {
      "equity": 1606.7,
      "debt": 5.184,
      "gold": 6201.710789,
      "pb": 3.14,
      "pe": 13.88,
      "yield": 2.3,
      "date": "2004-05-25"
  },
  "2004-05-26": {
      "equity": 1598.8,
      "debt": 5.191,
      "gold": 6252.93215,
      "pb": 3.13,
      "pe": 13.75,
      "yield": 2.41,
      "date": "2004-05-26"
  },
  "2004-05-27": {
      "equity": 1586.4,
      "debt": 5.212,
      "gold": 6270.904249,
      "pb": 3.1,
      "pe": 13.65,
      "yield": 2.42,
      "date": "2004-05-27"
  },
  "2004-05-28": {
      "equity": 1508.7,
      "debt": 5.244,
      "gold": 6317.688143,
      "pb": 2.95,
      "pe": 12.98,
      "yield": 2.55,
      "date": "2004-05-28"
  },
  "2004-05-29": {
      "equity": 1508.7,
      "debt": 5.256,
      "gold": 6317.688143,
      "pb": 2.95,
      "pe": 12.98,
      "yield": 2.55,
      "date": "2004-05-29"
  },
  "2004-05-30": {
      "equity": 1508.7,
      "debt": 5.256,
      "gold": 6317.688143,
      "pb": 2.95,
      "pe": 12.98,
      "yield": 2.55,
      "date": "2004-05-30"
  },
  "2004-05-31": {
      "equity": 1483.6,
      "debt": 5.274,
      "gold": 6317.688143,
      "pb": 2.84,
      "pe": 11.94,
      "yield": 2.59,
      "date": "2004-05-31"
  },
  "2004-06-01": {
      "equity": 1507.9,
      "debt": 5.285,
      "gold": 6343.487539,
      "pb": 2.89,
      "pe": 12.14,
      "yield": 2.55,
      "date": "2004-06-01"
  },
  "2004-06-02": {
      "equity": 1535.2,
      "debt": 5.293,
      "gold": 6337.385139,
      "pb": 2.94,
      "pe": 12.36,
      "yield": 2.51,
      "date": "2004-06-02"
  },
  "2004-06-03": {
      "equity": 1495.1,
      "debt": 5.284,
      "gold": 6247.323586,
      "pb": 2.87,
      "pe": 12.04,
      "yield": 2.57,
      "date": "2004-06-03"
  },
  "2004-06-04": {
      "equity": 1521.1,
      "debt": 5.278,
      "gold": 6176.168892,
      "pb": 2.92,
      "pe": 12.25,
      "yield": 2.53,
      "date": "2004-06-04"
  },
  "2004-06-05": {
      "equity": 1521.1,
      "debt": 5.228,
      "gold": 6176.168892,
      "pb": 2.92,
      "pe": 12.25,
      "yield": 2.53,
      "date": "2004-06-05"
  },
  "2004-06-06": {
      "equity": 1521.1,
      "debt": 5.228,
      "gold": 6176.168892,
      "pb": 2.92,
      "pe": 12.25,
      "yield": 2.53,
      "date": "2004-06-06"
  },
  "2004-06-07": {
      "equity": 1542.5,
      "debt": 5.276,
      "gold": 6252.635849,
      "pb": 2.96,
      "pe": 12.42,
      "yield": 2.49,
      "date": "2004-06-07"
  },
  "2004-06-08": {
      "equity": 1550.5,
      "debt": 5.272,
      "gold": 6272.639729,
      "pb": 2.97,
      "pe": 12.48,
      "yield": 2.48,
      "date": "2004-06-08"
  },
  "2004-06-09": {
      "equity": 1548.3,
      "debt": 5.288,
      "gold": 6176.726221,
      "pb": 2.97,
      "pe": 12.47,
      "yield": 2.48,
      "date": "2004-06-09"
  },
  "2004-06-10": {
      "equity": 1544.7,
      "debt": 5.332,
      "gold": 6108.463994,
      "pb": 2.96,
      "pe": 12.44,
      "yield": 2.49,
      "date": "2004-06-10"
  },
  "2004-06-11": {
      "equity": 1508.4,
      "debt": 5.323,
      "gold": 6125.102735,
      "pb": 2.89,
      "pe": 12.14,
      "yield": 2.55,
      "date": "2004-06-11"
  },
  "2004-06-12": {
      "equity": 1508.4,
      "debt": 5.327,
      "gold": 6125.102735,
      "pb": 2.89,
      "pe": 12.14,
      "yield": 2.55,
      "date": "2004-06-12"
  },
  "2004-06-13": {
      "equity": 1508.4,
      "debt": 5.327,
      "gold": 6125.102735,
      "pb": 2.89,
      "pe": 12.14,
      "yield": 2.55,
      "date": "2004-06-13"
  },
  "2004-06-14": {
      "equity": 1481.3,
      "debt": 5.405,
      "gold": 6124.481913,
      "pb": 2.84,
      "pe": 11.84,
      "yield": 2.62,
      "date": "2004-06-14"
  },
  "2004-06-15": {
      "equity": 1501,
      "debt": 5.421,
      "gold": 6121.628247,
      "pb": 2.88,
      "pe": 12,
      "yield": 2.58,
      "date": "2004-06-15"
  },
  "2004-06-16": {
      "equity": 1494.7,
      "debt": 5.372,
      "gold": 6210.949047,
      "pb": 2.87,
      "pe": 12.01,
      "yield": 2.59,
      "date": "2004-06-16"
  },
  "2004-06-17": {
      "equity": 1512,
      "debt": 5.384,
      "gold": 6193.809415,
      "pb": 2.9,
      "pe": 12.15,
      "yield": 2.56,
      "date": "2004-06-17"
  },
  "2004-06-18": {
      "equity": 1491.2,
      "debt": 5.468,
      "gold": 6261.814141,
      "pb": 2.86,
      "pe": 11.98,
      "yield": 2.6,
      "date": "2004-06-18"
  },
  "2004-06-19": {
      "equity": 1491.2,
      "debt": 5.473,
      "gold": 6261.814141,
      "pb": 2.86,
      "pe": 11.98,
      "yield": 2.6,
      "date": "2004-06-19"
  },
  "2004-06-20": {
      "equity": 1491.2,
      "debt": 5.473,
      "gold": 6261.814141,
      "pb": 2.86,
      "pe": 11.98,
      "yield": 2.6,
      "date": "2004-06-20"
  },
  "2004-06-21": {
      "equity": 1482,
      "debt": 5.596,
      "gold": 6381.594032,
      "pb": 2.84,
      "pe": 11.91,
      "yield": 2.62,
      "date": "2004-06-21"
  },
  "2004-06-22": {
      "equity": 1474.7,
      "debt": 5.739,
      "gold": 6437.591492,
      "pb": 2.83,
      "pe": 11.85,
      "yield": 2.63,
      "date": "2004-06-22"
  },
  "2004-06-23": {
      "equity": 1446.1,
      "debt": 5.795,
      "gold": 6372.909575,
      "pb": 2.77,
      "pe": 11.62,
      "yield": 2.68,
      "date": "2004-06-23"
  },
  "2004-06-24": {
      "equity": 1470.7,
      "debt": 5.782,
      "gold": 6405.49216,
      "pb": 2.82,
      "pe": 11.82,
      "yield": 2.64,
      "date": "2004-06-24"
  },
  "2004-06-25": {
      "equity": 1488.5,
      "debt": 5.851,
      "gold": 6485.37364,
      "pb": 2.85,
      "pe": 11.96,
      "yield": 2.6,
      "date": "2004-06-25"
  },
  "2004-06-26": {
      "equity": 1488.5,
      "debt": 5.863,
      "gold": 6485.37364,
      "pb": 2.85,
      "pe": 11.96,
      "yield": 2.6,
      "date": "2004-06-26"
  },
  "2004-06-27": {
      "equity": 1488.5,
      "debt": 5.863,
      "gold": 6485.37364,
      "pb": 2.85,
      "pe": 11.96,
      "yield": 2.6,
      "date": "2004-06-27"
  },
  "2004-06-28": {
      "equity": 1514.3,
      "debt": 5.806,
      "gold": 6508.788515,
      "pb": 2.84,
      "pe": 12.71,
      "yield": 2.57,
      "date": "2004-06-28"
  },
  "2004-06-29": {
      "equity": 1518.3,
      "debt": 5.85,
      "gold": 6437.679677,
      "pb": 2.85,
      "pe": 12.74,
      "yield": 2.57,
      "date": "2004-06-29"
  },
  "2004-06-30": {
      "equity": 1505.6,
      "debt": 5.85,
      "gold": 6397.336814,
      "pb": 2.83,
      "pe": 12.64,
      "yield": 2.59,
      "date": "2004-06-30"
  },
  "2004-07-01": {
      "equity": 1537.2,
      "debt": 5.843,
      "gold": 6385.167287,
      "pb": 2.89,
      "pe": 12.9,
      "yield": 2.54,
      "date": "2004-07-01"
  },
  "2004-07-02": {
      "equity": 1537.5,
      "debt": 5.783,
      "gold": 6387.710542,
      "pb": 2.89,
      "pe": 12.9,
      "yield": 2.54,
      "date": "2004-07-02"
  },
  "2004-07-03": {
      "equity": 1537.5,
      "debt": 5.71,
      "gold": 6387.710542,
      "pb": 2.89,
      "pe": 12.9,
      "yield": 2.54,
      "date": "2004-07-03"
  },
  "2004-07-04": {
      "equity": 1537.5,
      "debt": 5.71,
      "gold": 6387.710542,
      "pb": 2.89,
      "pe": 12.9,
      "yield": 2.54,
      "date": "2004-07-04"
  },
  "2004-07-05": {
      "equity": 1526.8,
      "debt": 5.697,
      "gold": 6441.263514,
      "pb": 2.87,
      "pe": 12.72,
      "yield": 2.57,
      "date": "2004-07-05"
  },
  "2004-07-06": {
      "equity": 1558.2,
      "debt": 5.693,
      "gold": 6470.777262,
      "pb": 2.93,
      "pe": 12.98,
      "yield": 2.52,
      "date": "2004-07-06"
  },
  "2004-07-07": {
      "equity": 1566.8,
      "debt": 5.738,
      "gold": 6426.392,
      "pb": 2.94,
      "pe": 13.05,
      "yield": 2.5,
      "date": "2004-07-07"
  },
  "2004-07-08": {
      "equity": 1518.1,
      "debt": 5.828,
      "gold": 6502.703751,
      "pb": 2.85,
      "pe": 12.64,
      "yield": 2.58,
      "date": "2004-07-08"
  },
  "2004-07-09": {
      "equity": 1553.2,
      "debt": 5.85,
      "gold": 6549.459426,
      "pb": 2.92,
      "pe": 12.94,
      "yield": 2.53,
      "date": "2004-07-09"
  },
  "2004-07-10": {
      "equity": 1553.2,
      "debt": 5.795,
      "gold": 6549.459426,
      "pb": 2.92,
      "pe": 12.94,
      "yield": 2.53,
      "date": "2004-07-10"
  },
  "2004-07-11": {
      "equity": 1553.2,
      "debt": 5.795,
      "gold": 6549.459426,
      "pb": 2.92,
      "pe": 12.94,
      "yield": 2.53,
      "date": "2004-07-11"
  },
  "2004-07-12": {
      "equity": 1556.9,
      "debt": 5.878,
      "gold": 6584.472389,
      "pb": 2.83,
      "pe": 12.97,
      "yield": 2.53,
      "date": "2004-07-12"
  },
  "2004-07-13": {
      "equity": 1539.3,
      "debt": 5.82,
      "gold": 6542.263532,
      "pb": 2.8,
      "pe": 12.82,
      "yield": 2.56,
      "date": "2004-07-13"
  },
  "2004-07-14": {
      "equity": 1522.7,
      "debt": 5.865,
      "gold": 6523.688248,
      "pb": 2.77,
      "pe": 12.68,
      "yield": 2.59,
      "date": "2004-07-14"
  },
  "2004-07-15": {
      "equity": 1539.4,
      "debt": 5.947,
      "gold": 6570.080601,
      "pb": 2.8,
      "pe": 12.82,
      "yield": 2.56,
      "date": "2004-07-15"
  },
  "2004-07-16": {
      "equity": 1558.8,
      "debt": 5.947,
      "gold": 6544.041341,
      "pb": 2.83,
      "pe": 12.98,
      "yield": 2.53,
      "date": "2004-07-16"
  },
  "2004-07-17": {
      "equity": 1558.8,
      "debt": 5.844,
      "gold": 6544.041341,
      "pb": 2.83,
      "pe": 12.98,
      "yield": 2.53,
      "date": "2004-07-17"
  },
  "2004-07-18": {
      "equity": 1558.8,
      "debt": 5.844,
      "gold": 6544.041341,
      "pb": 2.83,
      "pe": 12.98,
      "yield": 2.53,
      "date": "2004-07-18"
  },
  "2004-07-19": {
      "equity": 1571.6,
      "debt": 5.831,
      "gold": 6616.106104,
      "pb": 2.86,
      "pe": 13.09,
      "yield": 2.53,
      "date": "2004-07-19"
  },
  "2004-07-20": {
      "equity": 1566.1,
      "debt": 5.85,
      "gold": 6595.393217,
      "pb": 2.85,
      "pe": 13.05,
      "yield": 2.54,
      "date": "2004-07-20"
  },
  "2004-07-21": {
      "equity": 1581.4,
      "debt": 5.843,
      "gold": 6525.240304,
      "pb": 2.88,
      "pe": 13.17,
      "yield": 2.52,
      "date": "2004-07-21"
  },
  "2004-07-22": {
      "equity": 1598.1,
      "debt": 5.926,
      "gold": 6444.815605,
      "pb": 2.91,
      "pe": 13.31,
      "yield": 2.49,
      "date": "2004-07-22"
  },
  "2004-07-23": {
      "equity": 1601.6,
      "debt": 5.952,
      "gold": 6419.086756,
      "pb": 2.91,
      "pe": 13.34,
      "yield": 2.49,
      "date": "2004-07-23"
  },
  "2004-07-24": {
      "equity": 1601.6,
      "debt": 5.919,
      "gold": 6419.086756,
      "pb": 2.91,
      "pe": 13.34,
      "yield": 2.49,
      "date": "2004-07-24"
  },
  "2004-07-25": {
      "equity": 1601.6,
      "debt": 5.919,
      "gold": 6419.086756,
      "pb": 2.91,
      "pe": 13.34,
      "yield": 2.49,
      "date": "2004-07-25"
  },
  "2004-07-26": {
      "equity": 1618,
      "debt": 5.925,
      "gold": 6393.608353,
      "pb": 2.95,
      "pe": 13.51,
      "yield": 2.47,
      "date": "2004-07-26"
  },
  "2004-07-27": {
      "equity": 1600.7,
      "debt": 5.948,
      "gold": 6395.79534,
      "pb": 2.92,
      "pe": 13.37,
      "yield": 2.5,
      "date": "2004-07-27"
  },
  "2004-07-28": {
      "equity": 1594.1,
      "debt": 6.099,
      "gold": 6313.494065,
      "pb": 2.91,
      "pe": 13.31,
      "yield": 2.51,
      "date": "2004-07-28"
  },
  "2004-07-29": {
      "equity": 1618.7,
      "debt": 6.283,
      "gold": 6372.500397,
      "pb": 2.93,
      "pe": 13.52,
      "yield": 2.47,
      "date": "2004-07-29"
  },
  "2004-07-30": {
      "equity": 1632.3,
      "debt": 6.164,
      "gold": 6386.253726,
      "pb": 2.95,
      "pe": 13.63,
      "yield": 2.45,
      "date": "2004-07-30"
  },
  "2004-07-31": {
      "equity": 1632.3,
      "debt": 6.137,
      "gold": 6386.253726,
      "pb": 2.95,
      "pe": 13.63,
      "yield": 2.45,
      "date": "2004-07-31"
  },
  "2004-08-01": {
      "equity": 1632.3,
      "debt": 6.137,
      "gold": 6386.253726,
      "pb": 2.95,
      "pe": 13.63,
      "yield": 2.45,
      "date": "2004-08-01"
  },
  "2004-08-02": {
      "equity": 1639,
      "debt": 6.236,
      "gold": 6428.744775,
      "pb": 2.96,
      "pe": 13.69,
      "yield": 2.41,
      "date": "2004-08-02"
  },
  "2004-08-03": {
      "equity": 1630.6,
      "debt": 6.235,
      "gold": 6391.622427,
      "pb": 2.95,
      "pe": 13.62,
      "yield": 2.43,
      "date": "2004-08-03"
  },
  "2004-08-04": {
      "equity": 1626.5,
      "debt": 6.161,
      "gold": 6422.52597,
      "pb": 2.94,
      "pe": 13.58,
      "yield": 2.43,
      "date": "2004-08-04"
  },
  "2004-08-05": {
      "equity": 1654.9,
      "debt": 6.137,
      "gold": 6408.151819,
      "pb": 2.99,
      "pe": 13.82,
      "yield": 2.39,
      "date": "2004-08-05"
  },
  "2004-08-06": {
      "equity": 1633.4,
      "debt": 6.282,
      "gold": 6415.509974,
      "pb": 2.95,
      "pe": 13.64,
      "yield": 2.42,
      "date": "2004-08-06"
  },
  "2004-08-07": {
      "equity": 1633.4,
      "debt": 6.254,
      "gold": 6415.509974,
      "pb": 2.95,
      "pe": 13.64,
      "yield": 2.42,
      "date": "2004-08-07"
  },
  "2004-08-08": {
      "equity": 1633.4,
      "debt": 6.254,
      "gold": 6415.509974,
      "pb": 2.95,
      "pe": 13.64,
      "yield": 2.42,
      "date": "2004-08-08"
  },
  "2004-08-09": {
      "equity": 1642.6,
      "debt": 6.475,
      "gold": 6527.635408,
      "pb": 2.97,
      "pe": 13.72,
      "yield": 2.41,
      "date": "2004-08-09"
  },
  "2004-08-10": {
      "equity": 1652.1,
      "debt": 6.536,
      "gold": 6524.968694,
      "pb": 2.99,
      "pe": 13.8,
      "yield": 2.4,
      "date": "2004-08-10"
  },
  "2004-08-11": {
      "equity": 1621.6,
      "debt": 6.55,
      "gold": 6473.944867,
      "pb": 2.93,
      "pe": 13.55,
      "yield": 2.44,
      "date": "2004-08-11"
  },
  "2004-08-12": {
      "equity": 1607.2,
      "debt": 6.652,
      "gold": 6460.667737,
      "pb": 2.91,
      "pe": 13.43,
      "yield": 2.46,
      "date": "2004-08-12"
  },
  "2004-08-13": {
      "equity": 1598.2,
      "debt": 6.542,
      "gold": 6414.33535,
      "pb": 2.89,
      "pe": 13.36,
      "yield": 2.47,
      "date": "2004-08-13"
  },
  "2004-08-14": {
      "equity": 1598.2,
      "debt": 6.631,
      "gold": 6414.33535,
      "pb": 2.89,
      "pe": 13.36,
      "yield": 2.47,
      "date": "2004-08-14"
  },
  "2004-08-15": {
      "equity": 1598.2,
      "debt": 6.631,
      "gold": 6414.33535,
      "pb": 2.89,
      "pe": 13.36,
      "yield": 2.47,
      "date": "2004-08-15"
  },
  "2004-08-16": {
      "equity": 1599.1,
      "debt": 6.562,
      "gold": 6536.101166,
      "pb": 2.89,
      "pe": 13.36,
      "yield": 2.46,
      "date": "2004-08-16"
  },
  "2004-08-17": {
      "equity": 1604.3,
      "debt": 6.515,
      "gold": 6564.211009,
      "pb": 2.9,
      "pe": 13.41,
      "yield": 2.45,
      "date": "2004-08-17"
  },
  "2004-08-18": {
      "equity": 1581.8,
      "debt": 6.481,
      "gold": 6593.894072,
      "pb": 2.86,
      "pe": 13.22,
      "yield": 2.48,
      "date": "2004-08-18"
  },
  "2004-08-19": {
      "equity": 1609.2,
      "debt": 6.494,
      "gold": 6638.857828,
      "pb": 2.91,
      "pe": 13.45,
      "yield": 2.44,
      "date": "2004-08-19"
  },
  "2004-08-20": {
      "equity": 1590.3,
      "debt": 6.494,
      "gold": 6621.742888,
      "pb": 2.87,
      "pe": 13.29,
      "yield": 2.47,
      "date": "2004-08-20"
  },
  "2004-08-21": {
      "equity": 1590.3,
      "debt": 6.555,
      "gold": 6621.742888,
      "pb": 2.87,
      "pe": 13.29,
      "yield": 2.47,
      "date": "2004-08-21"
  },
  "2004-08-22": {
      "equity": 1590.3,
      "debt": 6.555,
      "gold": 6621.742888,
      "pb": 2.87,
      "pe": 13.29,
      "yield": 2.47,
      "date": "2004-08-22"
  },
  "2004-08-23": {
      "equity": 1578.2,
      "debt": 6.358,
      "gold": 6691.267924,
      "pb": 2.85,
      "pe": 13.19,
      "yield": 2.49,
      "date": "2004-08-23"
  },
  "2004-08-24": {
      "equity": 1591.6,
      "debt": 6.285,
      "gold": 6647.584613,
      "pb": 2.88,
      "pe": 13.3,
      "yield": 2.47,
      "date": "2004-08-24"
  },
  "2004-08-25": {
      "equity": 1595.7,
      "debt": 6.205,
      "gold": 6631.150461,
      "pb": 2.88,
      "pe": 13.34,
      "yield": 2.47,
      "date": "2004-08-25"
  },
  "2004-08-26": {
      "equity": 1610.7,
      "debt": 6.258,
      "gold": 6634.928306,
      "pb": 2.91,
      "pe": 13.46,
      "yield": 2.44,
      "date": "2004-08-26"
  },
  "2004-08-27": {
      "equity": 1609,
      "debt": 6.092,
      "gold": 6645.334133,
      "pb": 2.91,
      "pe": 13.45,
      "yield": 2.45,
      "date": "2004-08-27"
  },
  "2004-08-28": {
      "equity": 1609,
      "debt": 6.039,
      "gold": 6645.334133,
      "pb": 2.91,
      "pe": 13.45,
      "yield": 2.45,
      "date": "2004-08-28"
  },
  "2004-08-29": {
      "equity": 1609,
      "debt": 6.039,
      "gold": 6645.334133,
      "pb": 2.91,
      "pe": 13.45,
      "yield": 2.45,
      "date": "2004-08-29"
  },
  "2004-08-30": {
      "equity": 1628.4,
      "debt": 6.157,
      "gold": 6645.334133,
      "pb": 2.94,
      "pe": 13.61,
      "yield": 2.41,
      "date": "2004-08-30"
  },
  "2004-08-31": {
      "equity": 1631.7,
      "debt": 6.089,
      "gold": 6672.777298,
      "pb": 2.95,
      "pe": 13.64,
      "yield": 2.4,
      "date": "2004-08-31"
  },
  "2004-09-01": {
      "equity": 1635.4,
      "debt": 5.961,
      "gold": 6668.075275,
      "pb": 2.96,
      "pe": 13.67,
      "yield": 2.4,
      "date": "2004-09-01"
  },
  "2004-09-02": {
      "equity": 1629.3,
      "debt": 6.045,
      "gold": 6663.00993,
      "pb": 2.94,
      "pe": 13.62,
      "yield": 2.41,
      "date": "2004-09-02"
  },
  "2004-09-03": {
      "equity": 1634.1,
      "debt": 5.889,
      "gold": 6626.751795,
      "pb": 2.95,
      "pe": 13.66,
      "yield": 2.4,
      "date": "2004-09-03"
  },
  "2004-09-04": {
      "equity": 1634.1,
      "debt": 5.882,
      "gold": 6626.751795,
      "pb": 2.95,
      "pe": 13.66,
      "yield": 2.4,
      "date": "2004-09-04"
  },
  "2004-09-05": {
      "equity": 1634.1,
      "debt": 5.882,
      "gold": 6626.751795,
      "pb": 2.95,
      "pe": 13.66,
      "yield": 2.4,
      "date": "2004-09-05"
  },
  "2004-09-06": {
      "equity": 1644,
      "debt": 5.901,
      "gold": 6565.787756,
      "pb": 2.97,
      "pe": 13.74,
      "yield": 2.34,
      "date": "2004-09-06"
  },
  "2004-09-07": {
      "equity": 1650.1,
      "debt": 5.94,
      "gold": 6570.644985,
      "pb": 2.98,
      "pe": 13.79,
      "yield": 2.33,
      "date": "2004-09-07"
  },
  "2004-09-08": {
      "equity": 1656.2,
      "debt": 5.946,
      "gold": 6504.873102,
      "pb": 2.99,
      "pe": 13.84,
      "yield": 2.32,
      "date": "2004-09-08"
  },
  "2004-09-09": {
      "equity": 1649,
      "debt": 5.949,
      "gold": 6519.437733,
      "pb": 2.98,
      "pe": 13.78,
      "yield": 2.33,
      "date": "2004-09-09"
  },
  "2004-09-10": {
      "equity": 1668.7,
      "debt": 5.939,
      "gold": 6539.473359,
      "pb": 3.02,
      "pe": 13.95,
      "yield": 2.3,
      "date": "2004-09-10"
  },
  "2004-09-11": {
      "equity": 1668.7,
      "debt": 5.944,
      "gold": 6539.473359,
      "pb": 3.02,
      "pe": 13.95,
      "yield": 2.3,
      "date": "2004-09-11"
  },
  "2004-09-12": {
      "equity": 1668.7,
      "debt": 5.944,
      "gold": 6539.473359,
      "pb": 3.02,
      "pe": 13.95,
      "yield": 2.3,
      "date": "2004-09-12"
  },
  "2004-09-13": {
      "equity": 1675.2,
      "debt": 6.135,
      "gold": 6526.96873,
      "pb": 3.03,
      "pe": 14,
      "yield": 2.29,
      "date": "2004-09-13"
  },
  "2004-09-14": {
      "equity": 1685.5,
      "debt": 6.092,
      "gold": 6533.684897,
      "pb": 3.05,
      "pe": 14.09,
      "yield": 2.28,
      "date": "2004-09-14"
  },
  "2004-09-15": {
      "equity": 1683.2,
      "debt": 6.125,
      "gold": 6547.593432,
      "pb": 3.04,
      "pe": 14.07,
      "yield": 2.28,
      "date": "2004-09-15"
  },
  "2004-09-16": {
      "equity": 1705.7,
      "debt": 6.14,
      "gold": 6542.552779,
      "pb": 3.08,
      "pe": 14.25,
      "yield": 2.25,
      "date": "2004-09-16"
  },
  "2004-09-17": {
      "equity": 1733.6,
      "debt": 6.127,
      "gold": 6547.216,
      "pb": 3.13,
      "pe": 14.49,
      "yield": 2.21,
      "date": "2004-09-17"
  },
  "2004-09-18": {
      "equity": 1733.6,
      "debt": 6.127,
      "gold": 6547.216,
      "pb": 3.13,
      "pe": 14.49,
      "yield": 2.21,
      "date": "2004-09-18"
  },
  "2004-09-19": {
      "equity": 1733.6,
      "debt": 6.127,
      "gold": 6547.216,
      "pb": 3.13,
      "pe": 14.49,
      "yield": 2.21,
      "date": "2004-09-19"
  },
  "2004-09-20": {
      "equity": 1728.8,
      "debt": 6.14,
      "gold": 6540.288189,
      "pb": 3.13,
      "pe": 14.45,
      "yield": 2.22,
      "date": "2004-09-20"
  },
  "2004-09-21": {
      "equity": 1750.2,
      "debt": 6.126,
      "gold": 6556.680012,
      "pb": 3.16,
      "pe": 14.63,
      "yield": 2.19,
      "date": "2004-09-21"
  },
  "2004-09-22": {
      "equity": 1753.9,
      "debt": 6.146,
      "gold": 6591.985749,
      "pb": 3.17,
      "pe": 14.66,
      "yield": 2.19,
      "date": "2004-09-22"
  },
  "2004-09-23": {
      "equity": 1726.1,
      "debt": 6.06,
      "gold": 6610.24004,
      "pb": 3.12,
      "pe": 14.42,
      "yield": 2.22,
      "date": "2004-09-23"
  },
  "2004-09-24": {
      "equity": 1722.5,
      "debt": 6.046,
      "gold": 6630.751865,
      "pb": 3.11,
      "pe": 14.39,
      "yield": 2.23,
      "date": "2004-09-24"
  },
  "2004-09-25": {
      "equity": 1722.5,
      "debt": 6.061,
      "gold": 6630.751865,
      "pb": 3.11,
      "pe": 14.39,
      "yield": 2.23,
      "date": "2004-09-25"
  },
  "2004-09-26": {
      "equity": 1722.5,
      "debt": 6.061,
      "gold": 6630.751865,
      "pb": 3.11,
      "pe": 14.39,
      "yield": 2.23,
      "date": "2004-09-26"
  },
  "2004-09-27": {
      "equity": 1717.5,
      "debt": 6.098,
      "gold": 6631.400907,
      "pb": 3.1,
      "pe": 14.35,
      "yield": 2.22,
      "date": "2004-09-27"
  },
  "2004-09-28": {
      "equity": 1700.2,
      "debt": 6.204,
      "gold": 6673.461613,
      "pb": 3.07,
      "pe": 14.21,
      "yield": 2.24,
      "date": "2004-09-28"
  },
  "2004-09-29": {
      "equity": 1727.9,
      "debt": 6.231,
      "gold": 6679.278294,
      "pb": 3.12,
      "pe": 14.44,
      "yield": 2.21,
      "date": "2004-09-29"
  },
  "2004-09-30": {
      "equity": 1745.5,
      "debt": 6.244,
      "gold": 6683.532337,
      "pb": 3.16,
      "pe": 14.59,
      "yield": 2.18,
      "date": "2004-09-30"
  },
  "2004-10-01": {
      "equity": 1775.1,
      "debt": 6.392,
      "gold": 6770.168786,
      "pb": 3.21,
      "pe": 14.84,
      "yield": 2.15,
      "date": "2004-10-01"
  },
  "2004-10-02": {
      "equity": 1775.1,
      "debt": 6.392,
      "gold": 6770.168786,
      "pb": 3.21,
      "pe": 14.84,
      "yield": 2.15,
      "date": "2004-10-02"
  },
  "2004-10-03": {
      "equity": 1775.1,
      "debt": 6.392,
      "gold": 6770.168786,
      "pb": 3.21,
      "pe": 14.84,
      "yield": 2.15,
      "date": "2004-10-03"
  },
  "2004-10-04": {
      "equity": 1805.6,
      "debt": 6.446,
      "gold": 6742.133018,
      "pb": 3.11,
      "pe": 15.09,
      "yield": 2.11,
      "date": "2004-10-04"
  },
  "2004-10-05": {
      "equity": 1812.4,
      "debt": 6.498,
      "gold": 6701.910087,
      "pb": 3.13,
      "pe": 15.15,
      "yield": 2.1,
      "date": "2004-10-05"
  },
  "2004-10-06": {
      "equity": 1794.9,
      "debt": 6.514,
      "gold": 6760.662446,
      "pb": 3.1,
      "pe": 15,
      "yield": 2.12,
      "date": "2004-10-06"
  },
  "2004-10-07": {
      "equity": 1815.7,
      "debt": 6.597,
      "gold": 6759.678301,
      "pb": 3.13,
      "pe": 15.17,
      "yield": 2.1,
      "date": "2004-10-07"
  },
  "2004-10-08": {
      "equity": 1820.2,
      "debt": 6.673,
      "gold": 6756.013334,
      "pb": 3.14,
      "pe": 15.21,
      "yield": 2.09,
      "date": "2004-10-08"
  },
  "2004-10-09": {
      "equity": 1817.8,
      "debt": 6.68,
      "gold": 6756.013334,
      "pb": 3.14,
      "pe": 15.19,
      "yield": 2.1,
      "date": "2004-10-09"
  },
  "2004-10-10": {
      "equity": 1817.8,
      "debt": 6.68,
      "gold": 6756.013334,
      "pb": 3.14,
      "pe": 15.19,
      "yield": 2.1,
      "date": "2004-10-10"
  },
  "2004-10-11": {
      "equity": 1807.7,
      "debt": 6.792,
      "gold": 6819.898058,
      "pb": 3.12,
      "pe": 15.11,
      "yield": 2.11,
      "date": "2004-10-11"
  },
  "2004-10-12": {
      "equity": 1786.9,
      "debt": 6.816,
      "gold": 6766.920052,
      "pb": 3.08,
      "pe": 14.93,
      "yield": 2.13,
      "date": "2004-10-12"
  },
  "2004-10-13": {
      "equity": 1786.9,
      "debt": 6.816,
      "gold": 6668.424487,
      "pb": 3.08,
      "pe": 14.93,
      "yield": 2.13,
      "date": "2004-10-13"
  },
  "2004-10-14": {
      "equity": 1794.7,
      "debt": 6.764,
      "gold": 6707.649165,
      "pb": 3.1,
      "pe": 15,
      "yield": 2.12,
      "date": "2004-10-14"
  },
  "2004-10-15": {
      "equity": 1795,
      "debt": 6.764,
      "gold": 6758.605972,
      "pb": 3.1,
      "pe": 15,
      "yield": 2.12,
      "date": "2004-10-15"
  },
  "2004-10-16": {
      "equity": 1795,
      "debt": 6.791,
      "gold": 6758.605972,
      "pb": 3.1,
      "pe": 15,
      "yield": 2.12,
      "date": "2004-10-16"
  },
  "2004-10-17": {
      "equity": 1795,
      "debt": 6.791,
      "gold": 6758.605972,
      "pb": 3.1,
      "pe": 15,
      "yield": 2.12,
      "date": "2004-10-17"
  },
  "2004-10-18": {
      "equity": 1786,
      "debt": 6.763,
      "gold": 6768.084093,
      "pb": 3.08,
      "pe": 14.93,
      "yield": 2.13,
      "date": "2004-10-18"
  },
  "2004-10-19": {
      "equity": 1808.4,
      "debt": 6.777,
      "gold": 6725.088626,
      "pb": 3.12,
      "pe": 15.11,
      "yield": 2.11,
      "date": "2004-10-19"
  },
  "2004-10-20": {
      "equity": 1790,
      "debt": 6.763,
      "gold": 6808.307025,
      "pb": 3.09,
      "pe": 14.96,
      "yield": 2.13,
      "date": "2004-10-20"
  },
  "2004-10-21": {
      "equity": 1779.7,
      "debt": 6.728,
      "gold": 6864.780684,
      "pb": 3.07,
      "pe": 14.87,
      "yield": 2.14,
      "date": "2004-10-21"
  },
  "2004-10-22": {
      "equity": 1779.7,
      "debt": 6.728,
      "gold": 6807.68973,
      "pb": 3.07,
      "pe": 14.87,
      "yield": 2.14,
      "date": "2004-10-22"
  },
  "2004-10-23": {
      "equity": 1779.7,
      "debt": 6.629,
      "gold": 6807.68973,
      "pb": 3.07,
      "pe": 14.87,
      "yield": 2.14,
      "date": "2004-10-23"
  },
  "2004-10-24": {
      "equity": 1779.7,
      "debt": 6.629,
      "gold": 6807.68973,
      "pb": 3.07,
      "pe": 14.87,
      "yield": 2.14,
      "date": "2004-10-24"
  },
  "2004-10-25": {
      "equity": 1757.2,
      "debt": 6.713,
      "gold": 6908.005432,
      "pb": 3.03,
      "pe": 14.69,
      "yield": 2.17,
      "date": "2004-10-25"
  },
  "2004-10-26": {
      "equity": 1781,
      "debt": 6.84,
      "gold": 6881.983809,
      "pb": 3.07,
      "pe": 14.89,
      "yield": 2.14,
      "date": "2004-10-26"
  },
  "2004-10-27": {
      "equity": 1783.8,
      "debt": 6.882,
      "gold": 6855.027426,
      "pb": 3.08,
      "pe": 14.91,
      "yield": 2.14,
      "date": "2004-10-27"
  },
  "2004-10-28": {
      "equity": 1800.1,
      "debt": 6.932,
      "gold": 6812.370589,
      "pb": 3.1,
      "pe": 15.04,
      "yield": 2.12,
      "date": "2004-10-28"
  },
  "2004-10-29": {
      "equity": 1786.9,
      "debt": 6.861,
      "gold": 6823.453676,
      "pb": 3.08,
      "pe": 14.93,
      "yield": 2.14,
      "date": "2004-10-29"
  },
  "2004-10-30": {
      "equity": 1786.9,
      "debt": 6.932,
      "gold": 6823.453676,
      "pb": 3.08,
      "pe": 14.93,
      "yield": 2.14,
      "date": "2004-10-30"
  },
  "2004-10-31": {
      "equity": 1786.9,
      "debt": 6.932,
      "gold": 6823.453676,
      "pb": 3.08,
      "pe": 14.93,
      "yield": 2.14,
      "date": "2004-10-31"
  },
  "2004-11-01": {
      "equity": 1797.7,
      "debt": 6.946,
      "gold": 6858.403146,
      "pb": 3.1,
      "pe": 15.03,
      "yield": 2.13,
      "date": "2004-11-01"
  },
  "2004-11-02": {
      "equity": 1813.7,
      "debt": 6.931,
      "gold": 6824.212067,
      "pb": 3.13,
      "pe": 15.16,
      "yield": 2.11,
      "date": "2004-11-02"
  },
  "2004-11-03": {
      "equity": 1837.4,
      "debt": 6.924,
      "gold": 6757.554807,
      "pb": 3.17,
      "pe": 15.36,
      "yield": 2.09,
      "date": "2004-11-03"
  },
  "2004-11-04": {
      "equity": 1834.8,
      "debt": 6.946,
      "gold": 6798.363287,
      "pb": 3.17,
      "pe": 15.34,
      "yield": 2.09,
      "date": "2004-11-04"
  },
  "2004-11-05": {
      "equity": 1852.3,
      "debt": 7.221,
      "gold": 6832.14166,
      "pb": 3.2,
      "pe": 15.48,
      "yield": 2.07,
      "date": "2004-11-05"
  },
  "2004-11-06": {
      "equity": 1852.3,
      "debt": 7.221,
      "gold": 6832.14166,
      "pb": 3.2,
      "pe": 15.48,
      "yield": 2.07,
      "date": "2004-11-06"
  },
  "2004-11-07": {
      "equity": 1852.3,
      "debt": 7.221,
      "gold": 6832.14166,
      "pb": 3.2,
      "pe": 15.48,
      "yield": 2.07,
      "date": "2004-11-07"
  },
  "2004-11-08": {
      "equity": 1862.8,
      "debt": 7.265,
      "gold": 6898.686044,
      "pb": 3.21,
      "pe": 15.57,
      "yield": 2.02,
      "date": "2004-11-08"
  },
  "2004-11-09": {
      "equity": 1858.7,
      "debt": 7.177,
      "gold": 6886.424805,
      "pb": 3.21,
      "pe": 15.54,
      "yield": 2.02,
      "date": "2004-11-09"
  },
  "2004-11-10": {
      "equity": 1876.1,
      "debt": 7.155,
      "gold": 6934.097603,
      "pb": 3.24,
      "pe": 15.68,
      "yield": 2,
      "date": "2004-11-10"
  },
  "2004-11-11": {
      "equity": 1870.5,
      "debt": 7.177,
      "gold": 6902.386285,
      "pb": 3.23,
      "pe": 15.64,
      "yield": 2.01,
      "date": "2004-11-11"
  },
  "2004-11-12": {
      "equity": 1872.9,
      "debt": 7.177,
      "gold": 6958.930493,
      "pb": 3.23,
      "pe": 15.66,
      "yield": 2.01,
      "date": "2004-11-12"
  },
  "2004-11-13": {
      "equity": 1872.9,
      "debt": 7.176,
      "gold": 6958.930493,
      "pb": 3.23,
      "pe": 15.66,
      "yield": 2.01,
      "date": "2004-11-13"
  },
  "2004-11-14": {
      "equity": 1872.9,
      "debt": 7.176,
      "gold": 6958.930493,
      "pb": 3.23,
      "pe": 15.66,
      "yield": 2.01,
      "date": "2004-11-14"
  },
  "2004-11-15": {
      "equity": 1872.9,
      "debt": 7.176,
      "gold": 7000.342158,
      "pb": 3.23,
      "pe": 15.66,
      "yield": 2.01,
      "date": "2004-11-15"
  },
  "2004-11-16": {
      "equity": 1879,
      "debt": 7.147,
      "gold": 6962.524912,
      "pb": 3.24,
      "pe": 15.71,
      "yield": 2,
      "date": "2004-11-16"
  },
  "2004-11-17": {
      "equity": 1888.6,
      "debt": 7.162,
      "gold": 7065.292157,
      "pb": 3.26,
      "pe": 15.79,
      "yield": 1.99,
      "date": "2004-11-17"
  },
  "2004-11-18": {
      "equity": 1892,
      "debt": 7.118,
      "gold": 7072.879592,
      "pb": 3.26,
      "pe": 15.82,
      "yield": 1.99,
      "date": "2004-11-18"
  },
  "2004-11-19": {
      "equity": 1872.3,
      "debt": 7.118,
      "gold": 7057.849345,
      "pb": 3.23,
      "pe": 15.65,
      "yield": 2.01,
      "date": "2004-11-19"
  },
  "2004-11-20": {
      "equity": 1872.3,
      "debt": 7.088,
      "gold": 7057.849345,
      "pb": 3.23,
      "pe": 15.65,
      "yield": 2.01,
      "date": "2004-11-20"
  },
  "2004-11-21": {
      "equity": 1872.3,
      "debt": 7.088,
      "gold": 7057.849345,
      "pb": 3.23,
      "pe": 15.65,
      "yield": 2.01,
      "date": "2004-11-21"
  },
  "2004-11-22": {
      "equity": 1873.3,
      "debt": 7.074,
      "gold": 7101.670223,
      "pb": 3.23,
      "pe": 15.66,
      "yield": 2.01,
      "date": "2004-11-22"
  },
  "2004-11-23": {
      "equity": 1892.6,
      "debt": 7.139,
      "gold": 7106.400466,
      "pb": 3.27,
      "pe": 15.84,
      "yield": 1.98,
      "date": "2004-11-23"
  },
  "2004-11-24": {
      "equity": 1904,
      "debt": 7.218,
      "gold": 7134.422124,
      "pb": 3.29,
      "pe": 15.93,
      "yield": 1.97,
      "date": "2004-11-24"
  },
  "2004-11-25": {
      "equity": 1901,
      "debt": 7.215,
      "gold": 7174.348754,
      "pb": 3.28,
      "pe": 15.91,
      "yield": 1.97,
      "date": "2004-11-25"
  },
  "2004-11-26": {
      "equity": 1901,
      "debt": 7.215,
      "gold": 7173.558617,
      "pb": 3.28,
      "pe": 15.91,
      "yield": 1.97,
      "date": "2004-11-26"
  },
  "2004-11-27": {
      "equity": 1901,
      "debt": 7.183,
      "gold": 7173.558617,
      "pb": 3.28,
      "pe": 15.91,
      "yield": 1.97,
      "date": "2004-11-27"
  },
  "2004-11-28": {
      "equity": 1901,
      "debt": 7.183,
      "gold": 7173.558617,
      "pb": 3.28,
      "pe": 15.91,
      "yield": 1.97,
      "date": "2004-11-28"
  },
  "2004-11-29": {
      "equity": 1939.6,
      "debt": 7.209,
      "gold": 7118.351294,
      "pb": 3.35,
      "pe": 16.23,
      "yield": 1.93,
      "date": "2004-11-29"
  },
  "2004-11-30": {
      "equity": 1958.8,
      "debt": 7.197,
      "gold": 7116.534683,
      "pb": 3.38,
      "pe": 16.39,
      "yield": 1.92,
      "date": "2004-11-30"
  },
  "2004-12-01": {
      "equity": 1962,
      "debt": 6.885,
      "gold": 7073.722641,
      "pb": 3.39,
      "pe": 16.42,
      "yield": 1.91,
      "date": "2004-12-01"
  },
  "2004-12-02": {
      "equity": 1999,
      "debt": 6.812,
      "gold": 7068.593802,
      "pb": 3.45,
      "pe": 16.73,
      "yield": 1.88,
      "date": "2004-12-02"
  },
  "2004-12-03": {
      "equity": 1996.2,
      "debt": 6.81,
      "gold": 6992.447839,
      "pb": 3.45,
      "pe": 16.71,
      "yield": 1.88,
      "date": "2004-12-03"
  },
  "2004-12-04": {
      "equity": 1996.2,
      "debt": 6.72,
      "gold": 6992.447839,
      "pb": 3.45,
      "pe": 16.71,
      "yield": 1.88,
      "date": "2004-12-04"
  },
  "2004-12-05": {
      "equity": 1996.2,
      "debt": 6.72,
      "gold": 6992.447839,
      "pb": 3.45,
      "pe": 16.71,
      "yield": 1.88,
      "date": "2004-12-05"
  },
  "2004-12-06": {
      "equity": 1993.1,
      "debt": 6.756,
      "gold": 7014.010829,
      "pb": 3.44,
      "pe": 16.68,
      "yield": 1.88,
      "date": "2004-12-06"
  },
  "2004-12-07": {
      "equity": 1992.7,
      "debt": 6.761,
      "gold": 6998.437362,
      "pb": 3.44,
      "pe": 16.68,
      "yield": 1.88,
      "date": "2004-12-07"
  },
  "2004-12-08": {
      "equity": 1977.9,
      "debt": 6.75,
      "gold": 6930.079896,
      "pb": 3.42,
      "pe": 16.56,
      "yield": 1.9,
      "date": "2004-12-08"
  },
  "2004-12-09": {
      "equity": 1989.9,
      "debt": 6.738,
      "gold": 6820.698778,
      "pb": 3.44,
      "pe": 16.66,
      "yield": 1.88,
      "date": "2004-12-09"
  },
  "2004-12-10": {
      "equity": 1969,
      "debt": 6.684,
      "gold": 6846.099578,
      "pb": 3.41,
      "pe": 16.54,
      "yield": 1.92,
      "date": "2004-12-10"
  },
  "2004-12-11": {
      "equity": 1969,
      "debt": 6.655,
      "gold": 6846.099578,
      "pb": 3.41,
      "pe": 16.54,
      "yield": 1.92,
      "date": "2004-12-11"
  },
  "2004-12-12": {
      "equity": 1969,
      "debt": 6.655,
      "gold": 6846.099578,
      "pb": 3.41,
      "pe": 16.54,
      "yield": 1.92,
      "date": "2004-12-12"
  },
  "2004-12-13": {
      "equity": 1985.3,
      "debt": 6.754,
      "gold": 6824.864636,
      "pb": 3.44,
      "pe": 16.68,
      "yield": 1.91,
      "date": "2004-12-13"
  },
  "2004-12-14": {
      "equity": 2006.8,
      "debt": 6.783,
      "gold": 6825.108027,
      "pb": 3.47,
      "pe": 16.86,
      "yield": 1.89,
      "date": "2004-12-14"
  },
  "2004-12-15": {
      "equity": 2028.7,
      "debt": 6.808,
      "gold": 6801.075857,
      "pb": 3.51,
      "pe": 17.04,
      "yield": 1.87,
      "date": "2004-12-15"
  },
  "2004-12-16": {
      "equity": 2033.2,
      "debt": 6.811,
      "gold": 6821.986278,
      "pb": 3.52,
      "pe": 17.08,
      "yield": 1.87,
      "date": "2004-12-16"
  },
  "2004-12-17": {
      "equity": 2012.1,
      "debt": 6.797,
      "gold": 6808.105963,
      "pb": 3.48,
      "pe": 16.91,
      "yield": 1.89,
      "date": "2004-12-17"
  },
  "2004-12-18": {
      "equity": 2012.1,
      "debt": 6.803,
      "gold": 6808.105963,
      "pb": 3.48,
      "pe": 16.91,
      "yield": 1.89,
      "date": "2004-12-18"
  },
  "2004-12-19": {
      "equity": 2012.1,
      "debt": 6.803,
      "gold": 6808.105963,
      "pb": 3.48,
      "pe": 16.91,
      "yield": 1.89,
      "date": "2004-12-19"
  },
  "2004-12-20": {
      "equity": 2026.8,
      "debt": 6.795,
      "gold": 6845.298859,
      "pb": 3.51,
      "pe": 14.92,
      "yield": 1.87,
      "date": "2004-12-20"
  },
  "2004-12-21": {
      "equity": 2044.6,
      "debt": 6.752,
      "gold": 6829.704228,
      "pb": 3.54,
      "pe": 15.05,
      "yield": 1.85,
      "date": "2004-12-21"
  },
  "2004-12-22": {
      "equity": 2035.3,
      "debt": 6.711,
      "gold": 6846.745093,
      "pb": 3.53,
      "pe": 14.98,
      "yield": 1.86,
      "date": "2004-12-22"
  },
  "2004-12-23": {
      "equity": 2045.1,
      "debt": 6.668,
      "gold": 6831.182208,
      "pb": 3.55,
      "pe": 15.06,
      "yield": 1.85,
      "date": "2004-12-23"
  },
  "2004-12-24": {
      "equity": 2062.7,
      "debt": 6.662,
      "gold": 6829.654844,
      "pb": 3.58,
      "pe": 15.19,
      "yield": 1.84,
      "date": "2004-12-24"
  },
  "2004-12-25": {
      "equity": 2062.7,
      "debt": 6.667,
      "gold": 6829.654844,
      "pb": 3.58,
      "pe": 15.19,
      "yield": 1.84,
      "date": "2004-12-25"
  },
  "2004-12-26": {
      "equity": 2062.7,
      "debt": 6.667,
      "gold": 6829.654844,
      "pb": 3.58,
      "pe": 15.19,
      "yield": 1.84,
      "date": "2004-12-26"
  },
  "2004-12-27": {
      "equity": 2062.6,
      "debt": 6.666,
      "gold": 6829.654844,
      "pb": 3.58,
      "pe": 15.19,
      "yield": 1.84,
      "date": "2004-12-27"
  },
  "2004-12-28": {
      "equity": 2071.3,
      "debt": 6.596,
      "gold": 6829.654844,
      "pb": 3.59,
      "pe": 15.25,
      "yield": 1.84,
      "date": "2004-12-28"
  },
  "2004-12-29": {
      "equity": 2069.6,
      "debt": 6.595,
      "gold": 6836.434505,
      "pb": 3.59,
      "pe": 15.24,
      "yield": 1.84,
      "date": "2004-12-29"
  },
  "2004-12-30": {
      "equity": 2059.8,
      "debt": 6.63,
      "gold": 6711.557523,
      "pb": 3.57,
      "pe": 15.17,
      "yield": 1.85,
      "date": "2004-12-30"
  },
  "2004-12-31": {
      "equity": 2080.5,
      "debt": 6.706,
      "gold": 6714.573449,
      "pb": 3.61,
      "pe": 15.32,
      "yield": 1.83,
      "date": "2004-12-31"
  },
  "2005-01-01": {
      "equity": 2080.5,
      "debt": 6.795,
      "gold": 6714.573449,
      "pb": 3.61,
      "pe": 15.32,
      "yield": 1.83,
      "date": "2005-01-01"
  },
  "2005-01-02": {
      "equity": 2080.5,
      "debt": 6.795,
      "gold": 6714.573449,
      "pb": 3.61,
      "pe": 15.32,
      "yield": 1.83,
      "date": "2005-01-02"
  },
  "2005-01-03": {
      "equity": 2115,
      "debt": 6.538,
      "gold": 6714.573449,
      "pb": 3.67,
      "pe": 15.57,
      "yield": 1.89,
      "date": "2005-01-03"
  },
  "2005-01-04": {
      "equity": 2103.7,
      "debt": 6.485,
      "gold": 6551.910968,
      "pb": 3.65,
      "pe": 15.49,
      "yield": 1.9,
      "date": "2005-01-04"
  },
  "2005-01-05": {
      "equity": 2032.2,
      "debt": 6.567,
      "gold": 6578.482866,
      "pb": 3.52,
      "pe": 14.96,
      "yield": 1.97,
      "date": "2005-01-05"
  },
  "2005-01-06": {
      "equity": 1998.3,
      "debt": 6.605,
      "gold": 6595.135717,
      "pb": 3.47,
      "pe": 14.71,
      "yield": 2,
      "date": "2005-01-06"
  },
  "2005-01-07": {
      "equity": 2015.5,
      "debt": 6.548,
      "gold": 6540.655038,
      "pb": 3.49,
      "pe": 14.84,
      "yield": 1.98,
      "date": "2005-01-07"
  },
  "2005-01-08": {
      "equity": 2015.5,
      "debt": 6.566,
      "gold": 6540.655038,
      "pb": 3.49,
      "pe": 14.84,
      "yield": 1.98,
      "date": "2005-01-08"
  },
  "2005-01-09": {
      "equity": 2015.5,
      "debt": 6.566,
      "gold": 6540.655038,
      "pb": 3.49,
      "pe": 14.84,
      "yield": 1.98,
      "date": "2005-01-09"
  },
  "2005-01-10": {
      "equity": 1982,
      "debt": 6.588,
      "gold": 6527.631881,
      "pb": 3.44,
      "pe": 14.58,
      "yield": 2.02,
      "date": "2005-01-10"
  },
  "2005-01-11": {
      "equity": 1952,
      "debt": 6.554,
      "gold": 6528.774758,
      "pb": 3.39,
      "pe": 14.37,
      "yield": 2.05,
      "date": "2005-01-11"
  },
  "2005-01-12": {
      "equity": 1913.6,
      "debt": 6.581,
      "gold": 6516.756909,
      "pb": 3.32,
      "pe": 14.05,
      "yield": 2.09,
      "date": "2005-01-12"
  },
  "2005-01-13": {
      "equity": 1954.5,
      "debt": 6.566,
      "gold": 6551.110249,
      "pb": 3.39,
      "pe": 14.35,
      "yield": 2.05,
      "date": "2005-01-13"
  },
  "2005-01-14": {
      "equity": 1931.1,
      "debt": 6.648,
      "gold": 6507.458685,
      "pb": 3.35,
      "pe": 14.18,
      "yield": 2.07,
      "date": "2005-01-14"
  },
  "2005-01-15": {
      "equity": 1931.1,
      "debt": 6.726,
      "gold": 6507.458685,
      "pb": 3.35,
      "pe": 14.18,
      "yield": 2.07,
      "date": "2005-01-15"
  },
  "2005-01-16": {
      "equity": 1931.1,
      "debt": 6.726,
      "gold": 6507.458685,
      "pb": 3.35,
      "pe": 14.18,
      "yield": 2.07,
      "date": "2005-01-16"
  },
  "2005-01-17": {
      "equity": 1932.9,
      "debt": 6.69,
      "gold": 6511.409372,
      "pb": 3.35,
      "pe": 14.17,
      "yield": 2.07,
      "date": "2005-01-17"
  },
  "2005-01-18": {
      "equity": 1934,
      "debt": 6.717,
      "gold": 6517.222526,
      "pb": 3.36,
      "pe": 14.19,
      "yield": 2.07,
      "date": "2005-01-18"
  },
  "2005-01-19": {
      "equity": 1926.6,
      "debt": 6.758,
      "gold": 6541.127709,
      "pb": 3.34,
      "pe": 14.12,
      "yield": 2.07,
      "date": "2005-01-19"
  },
  "2005-01-20": {
      "equity": 1925.3,
      "debt": 6.719,
      "gold": 6516.756909,
      "pb": 3.34,
      "pe": 14.09,
      "yield": 2.08,
      "date": "2005-01-20"
  },
  "2005-01-21": {
      "equity": 1925.3,
      "debt": 6.719,
      "gold": 6541.480449,
      "pb": 3.34,
      "pe": 14.09,
      "yield": 2.08,
      "date": "2005-01-21"
  },
  "2005-01-22": {
      "equity": 1925.3,
      "debt": 6.738,
      "gold": 6541.480449,
      "pb": 3.34,
      "pe": 14.09,
      "yield": 2.08,
      "date": "2005-01-22"
  },
  "2005-01-23": {
      "equity": 1925.3,
      "debt": 6.738,
      "gold": 6541.480449,
      "pb": 3.34,
      "pe": 14.09,
      "yield": 2.08,
      "date": "2005-01-23"
  },
  "2005-01-24": {
      "equity": 1909,
      "debt": 6.748,
      "gold": 6601.142877,
      "pb": 3.31,
      "pe": 13.6,
      "yield": 2.09,
      "date": "2005-01-24"
  },
  "2005-01-25": {
      "equity": 1931.8,
      "debt": 6.755,
      "gold": 6584.042047,
      "pb": 3.35,
      "pe": 13.74,
      "yield": 2.07,
      "date": "2005-01-25"
  },
  "2005-01-26": {
      "equity": 1931.8,
      "debt": 6.755,
      "gold": 6545.420554,
      "pb": 3.35,
      "pe": 13.74,
      "yield": 2.07,
      "date": "2005-01-26"
  },
  "2005-01-27": {
      "equity": 1955,
      "debt": 6.756,
      "gold": 6584.084375,
      "pb": 3.39,
      "pe": 13.84,
      "yield": 2.04,
      "date": "2005-01-27"
  },
  "2005-01-28": {
      "equity": 2008.3,
      "debt": 6.736,
      "gold": 6576.602762,
      "pb": 3.49,
      "pe": 14.34,
      "yield": 1.99,
      "date": "2005-01-28"
  },
  "2005-01-29": {
      "equity": 2008.3,
      "debt": 6.712,
      "gold": 6576.602762,
      "pb": 3.49,
      "pe": 14.34,
      "yield": 1.99,
      "date": "2005-01-29"
  },
  "2005-01-30": {
      "equity": 2008.3,
      "debt": 6.712,
      "gold": 6576.602762,
      "pb": 3.49,
      "pe": 14.34,
      "yield": 1.99,
      "date": "2005-01-30"
  },
  "2005-01-31": {
      "equity": 2057.6,
      "debt": 6.703,
      "gold": 6535.755481,
      "pb": 3.57,
      "pe": 14.68,
      "yield": 1.94,
      "date": "2005-01-31"
  },
  "2005-02-01": {
      "equity": 2059.8,
      "debt": 6.723,
      "gold": 6492.657719,
      "pb": 3.58,
      "pe": 14.34,
      "yield": 1.94,
      "date": "2005-02-01"
  },
  "2005-02-02": {
      "equity": 2052.2,
      "debt": 6.679,
      "gold": 6461.807087,
      "pb": 3.56,
      "pe": 14.24,
      "yield": 1.95,
      "date": "2005-02-02"
  },
  "2005-02-03": {
      "equity": 2079.4,
      "debt": 6.727,
      "gold": 6429.644262,
      "pb": 3.61,
      "pe": 14.42,
      "yield": 1.92,
      "date": "2005-02-03"
  },
  "2005-02-04": {
      "equity": 2077.9,
      "debt": 6.731,
      "gold": 6387.181432,
      "pb": 3.61,
      "pe": 14.41,
      "yield": 1.92,
      "date": "2005-02-04"
  },
  "2005-02-05": {
      "equity": 2077.9,
      "debt": 6.714,
      "gold": 6387.181432,
      "pb": 3.61,
      "pe": 14.41,
      "yield": 1.92,
      "date": "2005-02-05"
  },
  "2005-02-06": {
      "equity": 2077.9,
      "debt": 6.714,
      "gold": 6387.181432,
      "pb": 3.61,
      "pe": 14.41,
      "yield": 1.92,
      "date": "2005-02-06"
  },
  "2005-02-07": {
      "equity": 2055.1,
      "debt": 6.582,
      "gold": 6358.704739,
      "pb": 3.57,
      "pe": 14.26,
      "yield": 2.02,
      "date": "2005-02-07"
  },
  "2005-02-08": {
      "equity": 2055.1,
      "debt": 6.544,
      "gold": 6343.163019,
      "pb": 3.57,
      "pe": 14.26,
      "yield": 2.02,
      "date": "2005-02-08"
  },
  "2005-02-09": {
      "equity": 2070,
      "debt": 6.534,
      "gold": 6370.824882,
      "pb": 3.59,
      "pe": 14.36,
      "yield": 2.01,
      "date": "2005-02-09"
  },
  "2005-02-10": {
      "equity": 2063.3,
      "debt": 6.48,
      "gold": 6385.661123,
      "pb": 3.58,
      "pe": 14.31,
      "yield": 2.01,
      "date": "2005-02-10"
  },
  "2005-02-11": {
      "equity": 2082,
      "debt": 6.452,
      "gold": 6447.104887,
      "pb": 3.61,
      "pe": 14.48,
      "yield": 2,
      "date": "2005-02-11"
  },
  "2005-02-12": {
      "equity": 2082,
      "debt": 6.454,
      "gold": 6447.104887,
      "pb": 3.61,
      "pe": 14.48,
      "yield": 2,
      "date": "2005-02-12"
  },
  "2005-02-13": {
      "equity": 2082,
      "debt": 6.454,
      "gold": 6447.104887,
      "pb": 3.61,
      "pe": 14.48,
      "yield": 2,
      "date": "2005-02-13"
  },
  "2005-02-14": {
      "equity": 2098.2,
      "debt": 6.499,
      "gold": 6525.550715,
      "pb": 3.64,
      "pe": 14.59,
      "yield": 1.99,
      "date": "2005-02-14"
  },
  "2005-02-15": {
      "equity": 2089.9,
      "debt": 6.516,
      "gold": 6563.068132,
      "pb": 3.63,
      "pe": 14.53,
      "yield": 2,
      "date": "2005-02-15"
  },
  "2005-02-16": {
      "equity": 2068.8,
      "debt": 6.532,
      "gold": 6547.882679,
      "pb": 3.59,
      "pe": 14.39,
      "yield": 2.02,
      "date": "2005-02-16"
  },
  "2005-02-17": {
      "equity": 2061.9,
      "debt": 6.507,
      "gold": 6592.479585,
      "pb": 3.58,
      "pe": 14.34,
      "yield": 2.03,
      "date": "2005-02-17"
  },
  "2005-02-18": {
      "equity": 2055.5,
      "debt": 6.498,
      "gold": 6597.54493,
      "pb": 3.57,
      "pe": 14.29,
      "yield": 2.04,
      "date": "2005-02-18"
  },
  "2005-02-19": {
      "equity": 2055.5,
      "debt": 6.512,
      "gold": 6597.54493,
      "pb": 3.57,
      "pe": 14.29,
      "yield": 2.04,
      "date": "2005-02-19"
  },
  "2005-02-20": {
      "equity": 2055.5,
      "debt": 6.512,
      "gold": 6597.54493,
      "pb": 3.57,
      "pe": 14.29,
      "yield": 2.04,
      "date": "2005-02-20"
  },
  "2005-02-21": {
      "equity": 2043.2,
      "debt": 6.508,
      "gold": 6603.037091,
      "pb": 3.55,
      "pe": 14.21,
      "yield": 2.05,
      "date": "2005-02-21"
  },
  "2005-02-22": {
      "equity": 2058.4,
      "debt": 6.502,
      "gold": 6648.452354,
      "pb": 3.57,
      "pe": 14.31,
      "yield": 2.03,
      "date": "2005-02-22"
  },
  "2005-02-23": {
      "equity": 2057.1,
      "debt": 6.507,
      "gold": 6680.724528,
      "pb": 3.57,
      "pe": 14.3,
      "yield": 2.03,
      "date": "2005-02-23"
  },
  "2005-02-24": {
      "equity": 2055.3,
      "debt": 6.506,
      "gold": 6701.557347,
      "pb": 3.57,
      "pe": 14.29,
      "yield": 2.04,
      "date": "2005-02-24"
  },
  "2005-02-25": {
      "equity": 2060.9,
      "debt": 6.495,
      "gold": 6665.330958,
      "pb": 3.84,
      "pe": 14.71,
      "yield": 1.93,
      "date": "2005-02-25"
  },
  "2005-02-26": {
      "equity": 2060.9,
      "debt": 6.473,
      "gold": 6665.330958,
      "pb": 3.84,
      "pe": 14.71,
      "yield": 1.93,
      "date": "2005-02-26"
  },
  "2005-02-27": {
      "equity": 2060.9,
      "debt": 6.473,
      "gold": 6665.330958,
      "pb": 3.84,
      "pe": 14.71,
      "yield": 1.93,
      "date": "2005-02-27"
  },
  "2005-02-28": {
      "equity": 2103.2,
      "debt": 6.549,
      "gold": 6725.451948,
      "pb": 3.92,
      "pe": 15.02,
      "yield": 1.9,
      "date": "2005-02-28"
  },
  "2005-03-01": {
      "equity": 2084.4,
      "debt": 6.651,
      "gold": 6703.081183,
      "pb": 3.89,
      "pe": 14.88,
      "yield": 1.92,
      "date": "2005-03-01"
  },
  "2005-03-02": {
      "equity": 2093.2,
      "debt": 6.634,
      "gold": 6616.748091,
      "pb": 3.9,
      "pe": 14.94,
      "yield": 1.91,
      "date": "2005-03-02"
  },
  "2005-03-03": {
      "equity": 2128.8,
      "debt": 6.62,
      "gold": 6680.724528,
      "pb": 3.97,
      "pe": 15.2,
      "yield": 1.88,
      "date": "2005-03-03"
  },
  "2005-03-04": {
      "equity": 2148.1,
      "debt": 6.625,
      "gold": 6627.485494,
      "pb": 4.01,
      "pe": 15.34,
      "yield": 1.86,
      "date": "2005-03-04"
  },
  "2005-03-05": {
      "equity": 2148.1,
      "debt": 6.578,
      "gold": 6627.485494,
      "pb": 4.01,
      "pe": 15.34,
      "yield": 1.86,
      "date": "2005-03-05"
  },
  "2005-03-06": {
      "equity": 2148.1,
      "debt": 6.578,
      "gold": 6627.485494,
      "pb": 4.01,
      "pe": 15.34,
      "yield": 1.86,
      "date": "2005-03-06"
  },
  "2005-03-07": {
      "equity": 2160.1,
      "debt": 6.533,
      "gold": 6687.65234,
      "pb": 4.03,
      "pe": 15.42,
      "yield": 1.85,
      "date": "2005-03-07"
  },
  "2005-03-08": {
      "equity": 2168.9,
      "debt": 6.533,
      "gold": 6702.911868,
      "pb": 4.05,
      "pe": 15.48,
      "yield": 1.84,
      "date": "2005-03-08"
  },
  "2005-03-09": {
      "equity": 2160.8,
      "debt": 6.55,
      "gold": 6772.408684,
      "pb": 4.03,
      "pe": 15.43,
      "yield": 1.85,
      "date": "2005-03-09"
  },
  "2005-03-10": {
      "equity": 2167.4,
      "debt": 6.578,
      "gold": 6766.955325,
      "pb": 4.04,
      "pe": 15.47,
      "yield": 1.84,
      "date": "2005-03-10"
  },
  "2005-03-11": {
      "equity": 2154,
      "debt": 6.559,
      "gold": 6776.13009,
      "pb": 4.02,
      "pe": 15.38,
      "yield": 1.86,
      "date": "2005-03-11"
  },
  "2005-03-12": {
      "equity": 2154,
      "debt": 6.562,
      "gold": 6776.13009,
      "pb": 4.02,
      "pe": 15.38,
      "yield": 1.86,
      "date": "2005-03-12"
  },
  "2005-03-13": {
      "equity": 2154,
      "debt": 6.562,
      "gold": 6776.13009,
      "pb": 4.02,
      "pe": 15.38,
      "yield": 1.86,
      "date": "2005-03-13"
  },
  "2005-03-14": {
      "equity": 2146.3,
      "debt": 6.574,
      "gold": 6802.61733,
      "pb": 4,
      "pe": 15.32,
      "yield": 1.86,
      "date": "2005-03-14"
  },
  "2005-03-15": {
      "equity": 2128.9,
      "debt": 6.623,
      "gold": 6795.774176,
      "pb": 3.97,
      "pe": 15.2,
      "yield": 1.88,
      "date": "2005-03-15"
  },
  "2005-03-16": {
      "equity": 2125.5,
      "debt": 6.626,
      "gold": 6790.476023,
      "pb": 3.96,
      "pe": 15.17,
      "yield": 1.88,
      "date": "2005-03-16"
  },
  "2005-03-17": {
      "equity": 2098.5,
      "debt": 6.694,
      "gold": 6817.086721,
      "pb": 3.91,
      "pe": 14.98,
      "yield": 1.91,
      "date": "2005-03-17"
  },
  "2005-03-18": {
      "equity": 2109.1,
      "debt": 6.661,
      "gold": 6723.914002,
      "pb": 3.93,
      "pe": 15.06,
      "yield": 1.9,
      "date": "2005-03-18"
  },
  "2005-03-19": {
      "equity": 2109.1,
      "debt": 6.664,
      "gold": 6723.914002,
      "pb": 3.93,
      "pe": 15.06,
      "yield": 1.9,
      "date": "2005-03-19"
  },
  "2005-03-20": {
      "equity": 2109.1,
      "debt": 6.664,
      "gold": 6723.914002,
      "pb": 3.93,
      "pe": 15.06,
      "yield": 1.9,
      "date": "2005-03-20"
  },
  "2005-03-21": {
      "equity": 2096.6,
      "debt": 6.709,
      "gold": 6728.908799,
      "pb": 3.91,
      "pe": 14.97,
      "yield": 1.92,
      "date": "2005-03-21"
  },
  "2005-03-22": {
      "equity": 2061.6,
      "debt": 6.715,
      "gold": 6643.291769,
      "pb": 3.85,
      "pe": 14.72,
      "yield": 1.95,
      "date": "2005-03-22"
  },
  "2005-03-23": {
      "equity": 2026.4,
      "debt": 6.728,
      "gold": 6598.003492,
      "pb": 3.78,
      "pe": 14.47,
      "yield": 1.99,
      "date": "2005-03-23"
  },
  "2005-03-24": {
      "equity": 2015.4,
      "debt": 6.673,
      "gold": 6558.157992,
      "pb": 3.76,
      "pe": 14.39,
      "yield": 2,
      "date": "2005-03-24"
  },
  "2005-03-25": {
      "equity": 2015.4,
      "debt": 6.673,
      "gold": 6558.157992,
      "pb": 3.76,
      "pe": 14.39,
      "yield": 2,
      "date": "2005-03-25"
  },
  "2005-03-26": {
      "equity": 2015.4,
      "debt": 6.673,
      "gold": 6558.157992,
      "pb": 3.76,
      "pe": 14.39,
      "yield": 2,
      "date": "2005-03-26"
  },
  "2005-03-27": {
      "equity": 2015.4,
      "debt": 6.673,
      "gold": 6558.157992,
      "pb": 3.76,
      "pe": 14.39,
      "yield": 2,
      "date": "2005-03-27"
  },
  "2005-03-28": {
      "equity": 2029.4,
      "debt": 6.653,
      "gold": 6558.157992,
      "pb": 3.8,
      "pe": 14.54,
      "yield": 1.98,
      "date": "2005-03-28"
  },
  "2005-03-29": {
      "equity": 1983.8,
      "debt": 6.666,
      "gold": 6599.393287,
      "pb": 3.71,
      "pe": 14.21,
      "yield": 2.02,
      "date": "2005-03-29"
  },
  "2005-03-30": {
      "equity": 1993.7,
      "debt": 6.668,
      "gold": 6592.437256,
      "pb": 3.74,
      "pe": 14.3,
      "yield": 2.01,
      "date": "2005-03-30"
  },
  "2005-03-31": {
      "equity": 2035.6,
      "debt": 6.676,
      "gold": 6596.585478,
      "pb": 3.82,
      "pe": 14.6,
      "yield": 1.97,
      "date": "2005-03-31"
  },
  "2005-04-01": {
      "equity": 2067.6,
      "debt": 6.678,
      "gold": 6596.567841,
      "pb": 3.88,
      "pe": 14.84,
      "yield": 1.94,
      "date": "2005-04-01"
  },
  "2005-04-02": {
      "equity": 2067.6,
      "debt": 6.73,
      "gold": 6596.567841,
      "pb": 3.88,
      "pe": 14.84,
      "yield": 1.94,
      "date": "2005-04-02"
  },
  "2005-04-03": {
      "equity": 2067.6,
      "debt": 6.73,
      "gold": 6596.567841,
      "pb": 3.88,
      "pe": 14.84,
      "yield": 1.94,
      "date": "2005-04-03"
  },
  "2005-04-04": {
      "equity": 2063.4,
      "debt": 6.75,
      "gold": 6578.302968,
      "pb": 3.86,
      "pe": 14.81,
      "yield": 1.94,
      "date": "2005-04-04"
  },
  "2005-04-05": {
      "equity": 2052.5,
      "debt": 6.73,
      "gold": 6543.55456,
      "pb": 3.84,
      "pe": 14.71,
      "yield": 1.95,
      "date": "2005-04-05"
  },
  "2005-04-06": {
      "equity": 2069.3,
      "debt": 6.822,
      "gold": 6584.020882,
      "pb": 3.87,
      "pe": 14.83,
      "yield": 1.94,
      "date": "2005-04-06"
  },
  "2005-04-07": {
      "equity": 2052.8,
      "debt": 6.975,
      "gold": 6576.980194,
      "pb": 3.84,
      "pe": 14.71,
      "yield": 1.95,
      "date": "2005-04-07"
  },
  "2005-04-08": {
      "equity": 2031.2,
      "debt": 7.015,
      "gold": 6567.375086,
      "pb": 3.8,
      "pe": 14.56,
      "yield": 1.97,
      "date": "2005-04-08"
  },
  "2005-04-09": {
      "equity": 2031.2,
      "debt": 7.015,
      "gold": 6567.375086,
      "pb": 3.8,
      "pe": 14.56,
      "yield": 1.97,
      "date": "2005-04-09"
  },
  "2005-04-10": {
      "equity": 2031.2,
      "debt": 7.015,
      "gold": 6567.375086,
      "pb": 3.8,
      "pe": 14.56,
      "yield": 1.97,
      "date": "2005-04-10"
  },
  "2005-04-11": {
      "equity": 2008.2,
      "debt": 7.002,
      "gold": 6601.509727,
      "pb": 3.76,
      "pe": 14.39,
      "yield": 2,
      "date": "2005-04-11"
  },
  "2005-04-12": {
      "equity": 2024.9,
      "debt": 7.002,
      "gold": 6620.152031,
      "pb": 3.82,
      "pe": 14.65,
      "yield": 1.96,
      "date": "2005-04-12"
  },
  "2005-04-13": {
      "equity": 2025.4,
      "debt": 7.002,
      "gold": 6618.17316,
      "pb": 3.79,
      "pe": 14.52,
      "yield": 1.98,
      "date": "2005-04-13"
  },
  "2005-04-14": {
      "equity": 2025.4,
      "debt": 7.002,
      "gold": 6592.708866,
      "pb": 3.79,
      "pe": 14.52,
      "yield": 1.98,
      "date": "2005-04-14"
  },
  "2005-04-15": {
      "equity": 1956.3,
      "debt": 6.992,
      "gold": 6549.879187,
      "pb": 3.66,
      "pe": 13.95,
      "yield": 2.05,
      "date": "2005-04-15"
  },
  "2005-04-16": {
      "equity": 1956.3,
      "debt": 7.089,
      "gold": 6549.879187,
      "pb": 3.66,
      "pe": 13.95,
      "yield": 2.05,
      "date": "2005-04-16"
  },
  "2005-04-17": {
      "equity": 1956.3,
      "debt": 7.089,
      "gold": 6549.879187,
      "pb": 3.66,
      "pe": 13.95,
      "yield": 2.05,
      "date": "2005-04-17"
  },
  "2005-04-18": {
      "equity": 1927.8,
      "debt": 7.089,
      "gold": 6580.800367,
      "pb": 3.61,
      "pe": 13.75,
      "yield": 2.08,
      "date": "2005-04-18"
  },
  "2005-04-19": {
      "equity": 1909.4,
      "debt": 7.071,
      "gold": 6590.338454,
      "pb": 3.57,
      "pe": 13.64,
      "yield": 2.1,
      "date": "2005-04-19"
  },
  "2005-04-20": {
      "equity": 1929.7,
      "debt": 7.082,
      "gold": 6673.719113,
      "pb": 3.61,
      "pe": 13.78,
      "yield": 2.08,
      "date": "2005-04-20"
  },
  "2005-04-21": {
      "equity": 1948.5,
      "debt": 7.082,
      "gold": 6689.155012,
      "pb": 3.65,
      "pe": 13.87,
      "yield": 2.06,
      "date": "2005-04-21"
  },
  "2005-04-22": {
      "equity": 1967.3,
      "debt": 7.082,
      "gold": 6686.096757,
      "pb": 3.68,
      "pe": 13.97,
      "yield": 2.04,
      "date": "2005-04-22"
  },
  "2005-04-23": {
      "equity": 1967.3,
      "debt": 7.102,
      "gold": 6686.096757,
      "pb": 3.68,
      "pe": 13.97,
      "yield": 2.04,
      "date": "2005-04-23"
  },
  "2005-04-24": {
      "equity": 1967.3,
      "debt": 7.102,
      "gold": 6686.096757,
      "pb": 3.68,
      "pe": 13.97,
      "yield": 2.04,
      "date": "2005-04-24"
  },
  "2005-04-25": {
      "equity": 1970.9,
      "debt": 7.123,
      "gold": 6675.345244,
      "pb": 3.69,
      "pe": 13.99,
      "yield": 2.03,
      "date": "2005-04-25"
  },
  "2005-04-26": {
      "equity": 1957.1,
      "debt": 7.123,
      "gold": 6696.185118,
      "pb": 3.66,
      "pe": 13.85,
      "yield": 2.05,
      "date": "2005-04-26"
  },
  "2005-04-27": {
      "equity": 1935.4,
      "debt": 7.11,
      "gold": 6694.79885,
      "pb": 3.62,
      "pe": 13.51,
      "yield": 2.07,
      "date": "2005-04-27"
  },
  "2005-04-28": {
      "equity": 1941.3,
      "debt": 7.225,
      "gold": 6671.489797,
      "pb": 3.63,
      "pe": 13.55,
      "yield": 2.07,
      "date": "2005-04-28"
  },
  "2005-04-29": {
      "equity": 1902.5,
      "debt": 7.239,
      "gold": 6652.448897,
      "pb": 3.56,
      "pe": 13.27,
      "yield": 2.11,
      "date": "2005-04-29"
  },
  "2005-04-30": {
      "equity": 1902.5,
      "debt": 7.357,
      "gold": 6652.448897,
      "pb": 3.56,
      "pe": 13.27,
      "yield": 2.11,
      "date": "2005-04-30"
  },
  "2005-05-01": {
      "equity": 1902.5,
      "debt": 7.357,
      "gold": 6652.448897,
      "pb": 3.56,
      "pe": 13.27,
      "yield": 2.11,
      "date": "2005-05-01"
  },
  "2005-05-02": {
      "equity": 1916.7,
      "debt": 7.301,
      "gold": 6652.448897,
      "pb": 3.59,
      "pe": 13.32,
      "yield": 2.11,
      "date": "2005-05-02"
  },
  "2005-05-03": {
      "equity": 1920.7,
      "debt": 7.239,
      "gold": 6599.350959,
      "pb": 3.59,
      "pe": 13.35,
      "yield": 2.1,
      "date": "2005-05-03"
  },
  "2005-05-04": {
      "equity": 1942.6,
      "debt": 7.232,
      "gold": 6589.32609,
      "pb": 3.63,
      "pe": 13.5,
      "yield": 2.08,
      "date": "2005-05-04"
  },
  "2005-05-05": {
      "equity": 1963.3,
      "debt": 7.239,
      "gold": 6592.765305,
      "pb": 3.67,
      "pe": 13.64,
      "yield": 2.06,
      "date": "2005-05-05"
  },
  "2005-05-06": {
      "equity": 1977.5,
      "debt": 7.26,
      "gold": 6583.544683,
      "pb": 3.7,
      "pe": 13.71,
      "yield": 2.04,
      "date": "2005-05-06"
  },
  "2005-05-07": {
      "equity": 1977.5,
      "debt": 7.252,
      "gold": 6583.544683,
      "pb": 3.7,
      "pe": 13.71,
      "yield": 2.04,
      "date": "2005-05-07"
  },
  "2005-05-08": {
      "equity": 1977.5,
      "debt": 7.252,
      "gold": 6583.544683,
      "pb": 3.7,
      "pe": 13.71,
      "yield": 2.04,
      "date": "2005-05-08"
  },
  "2005-05-09": {
      "equity": 2000.7,
      "debt": 7.252,
      "gold": 6530.54904,
      "pb": 3.74,
      "pe": 13.87,
      "yield": 2.02,
      "date": "2005-05-09"
  },
  "2005-05-10": {
      "equity": 1994.3,
      "debt": 7.204,
      "gold": 6540.644456,
      "pb": 3.73,
      "pe": 13.82,
      "yield": 2.02,
      "date": "2005-05-10"
  },
  "2005-05-11": {
      "equity": 1985.9,
      "debt": 7.204,
      "gold": 6540.95134,
      "pb": 3.72,
      "pe": 13.76,
      "yield": 2.03,
      "date": "2005-05-11"
  },
  "2005-05-12": {
      "equity": 1993.1,
      "debt": 7.194,
      "gold": 6520.912185,
      "pb": 3.73,
      "pe": 13.81,
      "yield": 2.03,
      "date": "2005-05-12"
  },
  "2005-05-13": {
      "equity": 1988.3,
      "debt": 7.169,
      "gold": 6456.399584,
      "pb": 3.72,
      "pe": 13.78,
      "yield": 2.03,
      "date": "2005-05-13"
  },
  "2005-05-14": {
      "equity": 1988.3,
      "debt": 7.169,
      "gold": 6456.399584,
      "pb": 3.72,
      "pe": 13.78,
      "yield": 2.03,
      "date": "2005-05-14"
  },
  "2005-05-15": {
      "equity": 1988.3,
      "debt": 7.169,
      "gold": 6456.399584,
      "pb": 3.72,
      "pe": 13.78,
      "yield": 2.03,
      "date": "2005-05-15"
  },
  "2005-05-16": {
      "equity": 2012.6,
      "debt": 7.197,
      "gold": 6442.65331,
      "pb": 3.77,
      "pe": 13.97,
      "yield": 2.04,
      "date": "2005-05-16"
  },
  "2005-05-17": {
      "equity": 1990.8,
      "debt": 7.161,
      "gold": 6448.639306,
      "pb": 3.73,
      "pe": 13.8,
      "yield": 2.06,
      "date": "2005-05-17"
  },
  "2005-05-18": {
      "equity": 1982.7,
      "debt": 7.162,
      "gold": 6431.654879,
      "pb": 3.72,
      "pe": 13.74,
      "yield": 2.07,
      "date": "2005-05-18"
  },
  "2005-05-19": {
      "equity": 1990.8,
      "debt": 7.066,
      "gold": 6468.255172,
      "pb": 3.73,
      "pe": 13.74,
      "yield": 2.06,
      "date": "2005-05-19"
  },
  "2005-05-20": {
      "equity": 1992.4,
      "debt": 7.005,
      "gold": 6448.815676,
      "pb": 3.73,
      "pe": 13.73,
      "yield": 2.06,
      "date": "2005-05-20"
  },
  "2005-05-21": {
      "equity": 1992.4,
      "debt": 7.032,
      "gold": 6448.815676,
      "pb": 3.73,
      "pe": 13.73,
      "yield": 2.06,
      "date": "2005-05-21"
  },
  "2005-05-22": {
      "equity": 1992.4,
      "debt": 7.032,
      "gold": 6448.815676,
      "pb": 3.73,
      "pe": 13.73,
      "yield": 2.06,
      "date": "2005-05-22"
  },
  "2005-05-23": {
      "equity": 2013.9,
      "debt": 7.032,
      "gold": 6401.294555,
      "pb": 3.77,
      "pe": 13.88,
      "yield": 2.04,
      "date": "2005-05-23"
  },
  "2005-05-24": {
      "equity": 2028.6,
      "debt": 6.917,
      "gold": 6418.635249,
      "pb": 3.8,
      "pe": 13.98,
      "yield": 2.02,
      "date": "2005-05-24"
  },
  "2005-05-25": {
      "equity": 2043.8,
      "debt": 6.924,
      "gold": 6426.709466,
      "pb": 3.83,
      "pe": 13.75,
      "yield": 2.01,
      "date": "2005-05-25"
  },
  "2005-05-26": {
      "equity": 2074.7,
      "debt": 6.971,
      "gold": 6429.81005,
      "pb": 3.89,
      "pe": 13.95,
      "yield": 1.98,
      "date": "2005-05-26"
  },
  "2005-05-27": {
      "equity": 2076.4,
      "debt": 6.971,
      "gold": 6428.437891,
      "pb": 3.89,
      "pe": 13.97,
      "yield": 1.98,
      "date": "2005-05-27"
  },
  "2005-05-28": {
      "equity": 2076.4,
      "debt": 6.995,
      "gold": 6428.437891,
      "pb": 3.89,
      "pe": 13.97,
      "yield": 1.98,
      "date": "2005-05-28"
  },
  "2005-05-29": {
      "equity": 2076.4,
      "debt": 6.995,
      "gold": 6428.437891,
      "pb": 3.89,
      "pe": 13.97,
      "yield": 1.98,
      "date": "2005-05-29"
  },
  "2005-05-30": {
      "equity": 2072.4,
      "debt": 7.002,
      "gold": 6428.437891,
      "pb": 3.88,
      "pe": 13.86,
      "yield": 1.99,
      "date": "2005-05-30"
  },
  "2005-05-31": {
      "equity": 2087.5,
      "debt": 6.979,
      "gold": 6419.785181,
      "pb": 3.91,
      "pe": 13.94,
      "yield": 1.97,
      "date": "2005-05-31"
  },
  "2005-06-01": {
      "equity": 2087.5,
      "debt": 6.923,
      "gold": 6425.079807,
      "pb": 3.91,
      "pe": 13.93,
      "yield": 1.97,
      "date": "2005-06-01"
  },
  "2005-06-02": {
      "equity": 2064.6,
      "debt": 6.92,
      "gold": 6429.556077,
      "pb": 3.87,
      "pe": 13.77,
      "yield": 1.99,
      "date": "2005-06-02"
  },
  "2005-06-03": {
      "equity": 2094.2,
      "debt": 6.896,
      "gold": 6510.827351,
      "pb": 3.93,
      "pe": 13.97,
      "yield": 1.96,
      "date": "2005-06-03"
  },
  "2005-06-04": {
      "equity": 2092.3,
      "debt": 6.902,
      "gold": 6510.827351,
      "pb": 3.92,
      "pe": 13.96,
      "yield": 1.97,
      "date": "2005-06-04"
  },
  "2005-06-05": {
      "equity": 2092.3,
      "debt": 6.902,
      "gold": 6510.827351,
      "pb": 3.92,
      "pe": 13.96,
      "yield": 1.97,
      "date": "2005-06-05"
  },
  "2005-06-06": {
      "equity": 2092.8,
      "debt": 6.896,
      "gold": 6529.448491,
      "pb": 3.92,
      "pe": 13.96,
      "yield": 1.65,
      "date": "2005-06-06"
  },
  "2005-06-07": {
      "equity": 2098.1,
      "debt": 6.855,
      "gold": 6553.353675,
      "pb": 3.93,
      "pe": 14,
      "yield": 1.65,
      "date": "2005-06-07"
  },
  "2005-06-08": {
      "equity": 2112.4,
      "debt": 6.804,
      "gold": 6517.497663,
      "pb": 3.96,
      "pe": 14.09,
      "yield": 1.64,
      "date": "2005-06-08"
  },
  "2005-06-09": {
      "equity": 2103.2,
      "debt": 6.828,
      "gold": 6500.523819,
      "pb": 3.94,
      "pe": 13.97,
      "yield": 1.64,
      "date": "2005-06-09"
  },
  "2005-06-10": {
      "equity": 2090.6,
      "debt": 6.848,
      "gold": 6488.992751,
      "pb": 3.92,
      "pe": 13.89,
      "yield": 1.65,
      "date": "2005-06-10"
  },
  "2005-06-11": {
      "equity": 2090.6,
      "debt": 6.875,
      "gold": 6488.992751,
      "pb": 3.92,
      "pe": 13.89,
      "yield": 1.65,
      "date": "2005-06-11"
  },
  "2005-06-12": {
      "equity": 2090.6,
      "debt": 6.875,
      "gold": 6488.992751,
      "pb": 3.92,
      "pe": 13.89,
      "yield": 1.65,
      "date": "2005-06-12"
  },
  "2005-06-13": {
      "equity": 2102.7,
      "debt": 6.888,
      "gold": 6554.507134,
      "pb": 3.79,
      "pe": 13.9,
      "yield": 1.61,
      "date": "2005-06-13"
  },
  "2005-06-14": {
      "equity": 2112.3,
      "debt": 6.915,
      "gold": 6567.58673,
      "pb": 3.81,
      "pe": 13.96,
      "yield": 1.6,
      "date": "2005-06-14"
  },
  "2005-06-15": {
      "equity": 2128.6,
      "debt": 6.902,
      "gold": 6569.925396,
      "pb": 3.84,
      "pe": 14.07,
      "yield": 1.59,
      "date": "2005-06-15"
  },
  "2005-06-16": {
      "equity": 2123.7,
      "debt": 6.949,
      "gold": 6630.878851,
      "pb": 3.83,
      "pe": 14.04,
      "yield": 1.59,
      "date": "2005-06-16"
  },
  "2005-06-17": {
      "equity": 2123.4,
      "debt": 6.962,
      "gold": 6694.678918,
      "pb": 3.83,
      "pe": 14.04,
      "yield": 1.59,
      "date": "2005-06-17"
  },
  "2005-06-18": {
      "equity": 2123.4,
      "debt": 6.949,
      "gold": 6694.678918,
      "pb": 3.83,
      "pe": 14.04,
      "yield": 1.59,
      "date": "2005-06-18"
  },
  "2005-06-19": {
      "equity": 2123.4,
      "debt": 6.949,
      "gold": 6694.678918,
      "pb": 3.83,
      "pe": 14.04,
      "yield": 1.59,
      "date": "2005-06-19"
  },
  "2005-06-20": {
      "equity": 2144.3,
      "debt": 6.935,
      "gold": 6769.212861,
      "pb": 3.87,
      "pe": 13.82,
      "yield": 1.58,
      "date": "2005-06-20"
  },
  "2005-06-21": {
      "equity": 2170,
      "debt": 6.874,
      "gold": 6668.576165,
      "pb": 3.92,
      "pe": 13.99,
      "yield": 1.56,
      "date": "2005-06-21"
  },
  "2005-06-22": {
      "equity": 2187.3,
      "debt": 6.834,
      "gold": 6712.308859,
      "pb": 3.95,
      "pe": 14.1,
      "yield": 1.55,
      "date": "2005-06-22"
  },
  "2005-06-23": {
      "equity": 2183.8,
      "debt": 6.82,
      "gold": 6716.192525,
      "pb": 3.94,
      "pe": 14.08,
      "yield": 1.55,
      "date": "2005-06-23"
  },
  "2005-06-24": {
      "equity": 2194.3,
      "debt": 6.867,
      "gold": 6800.613767,
      "pb": 3.96,
      "pe": 14.14,
      "yield": 1.54,
      "date": "2005-06-24"
  },
  "2005-06-25": {
      "equity": 2194.3,
      "debt": 6.864,
      "gold": 6800.613767,
      "pb": 3.96,
      "pe": 14.14,
      "yield": 1.54,
      "date": "2005-06-25"
  },
  "2005-06-26": {
      "equity": 2194.3,
      "debt": 6.864,
      "gold": 6800.613767,
      "pb": 3.96,
      "pe": 14.14,
      "yield": 1.54,
      "date": "2005-06-26"
  },
  "2005-06-27": {
      "equity": 2199.8,
      "debt": 6.935,
      "gold": 6739.141784,
      "pb": 3.94,
      "pe": 14.18,
      "yield": 1.57,
      "date": "2005-06-27"
  },
  "2005-06-28": {
      "equity": 2169.8,
      "debt": 6.901,
      "gold": 6710.767386,
      "pb": 3.89,
      "pe": 13.99,
      "yield": 1.59,
      "date": "2005-06-28"
  },
  "2005-06-29": {
      "equity": 2191.6,
      "debt": 6.894,
      "gold": 6678.555177,
      "pb": 3.93,
      "pe": 14.13,
      "yield": 1.57,
      "date": "2005-06-29"
  },
  "2005-06-30": {
      "equity": 2220.6,
      "debt": 6.907,
      "gold": 6706.192349,
      "pb": 3.98,
      "pe": 14.31,
      "yield": 1.55,
      "date": "2005-06-30"
  },
  "2005-07-01": {
      "equity": 2211.9,
      "debt": 6.962,
      "gold": 6665.507328,
      "pb": 3.96,
      "pe": 14.26,
      "yield": 1.56,
      "date": "2005-07-01"
  },
  "2005-07-02": {
      "equity": 2211.9,
      "debt": 7.071,
      "gold": 6665.507328,
      "pb": 3.96,
      "pe": 14.26,
      "yield": 1.56,
      "date": "2005-07-02"
  },
  "2005-07-03": {
      "equity": 2211.9,
      "debt": 7.071,
      "gold": 6665.507328,
      "pb": 3.96,
      "pe": 14.26,
      "yield": 1.56,
      "date": "2005-07-03"
  },
  "2005-07-04": {
      "equity": 2230.6,
      "debt": 7.063,
      "gold": 6567.960634,
      "pb": 3.91,
      "pe": 14.38,
      "yield": 1.63,
      "date": "2005-07-04"
  },
  "2005-07-05": {
      "equity": 2210.7,
      "debt": 7.05,
      "gold": 6550.147269,
      "pb": 3.88,
      "pe": 14.25,
      "yield": 1.64,
      "date": "2005-07-05"
  },
  "2005-07-06": {
      "equity": 2228.2,
      "debt": 7.119,
      "gold": 6513.352969,
      "pb": 3.91,
      "pe": 14.36,
      "yield": 1.63,
      "date": "2005-07-06"
  },
  "2005-07-07": {
      "equity": 2179.4,
      "debt": 7.158,
      "gold": 6578.592215,
      "pb": 3.82,
      "pe": 14.05,
      "yield": 1.67,
      "date": "2005-07-07"
  },
  "2005-07-08": {
      "equity": 2196.2,
      "debt": 7.193,
      "gold": 6510.319406,
      "pb": 3.85,
      "pe": 14.16,
      "yield": 1.66,
      "date": "2005-07-08"
  },
  "2005-07-09": {
      "equity": 2196.2,
      "debt": 7.188,
      "gold": 6510.319406,
      "pb": 3.85,
      "pe": 14.16,
      "yield": 1.66,
      "date": "2005-07-09"
  },
  "2005-07-10": {
      "equity": 2196.2,
      "debt": 7.188,
      "gold": 6510.319406,
      "pb": 3.85,
      "pe": 14.16,
      "yield": 1.66,
      "date": "2005-07-10"
  },
  "2005-07-11": {
      "equity": 2218.8,
      "debt": 7.237,
      "gold": 6530.647807,
      "pb": 3.84,
      "pe": 14.3,
      "yield": 1.65,
      "date": "2005-07-11"
  },
  "2005-07-12": {
      "equity": 2220.8,
      "debt": 7.227,
      "gold": 6556.665902,
      "pb": 3.84,
      "pe": 14.29,
      "yield": 1.65,
      "date": "2005-07-12"
  },
  "2005-07-13": {
      "equity": 2204,
      "debt": 7.227,
      "gold": 6531.219245,
      "pb": 3.81,
      "pe": 14.17,
      "yield": 1.66,
      "date": "2005-07-13"
  },
  "2005-07-14": {
      "equity": 2185.1,
      "debt": 7.208,
      "gold": 6488.135593,
      "pb": 3.78,
      "pe": 14.04,
      "yield": 1.68,
      "date": "2005-07-14"
  },
  "2005-07-15": {
      "equity": 2212.5,
      "debt": 7.17,
      "gold": 6441.379919,
      "pb": 3.83,
      "pe": 14.21,
      "yield": 1.65,
      "date": "2005-07-15"
  },
  "2005-07-16": {
      "equity": 2212.5,
      "debt": 7.202,
      "gold": 6441.379919,
      "pb": 3.83,
      "pe": 14.21,
      "yield": 1.65,
      "date": "2005-07-16"
  },
  "2005-07-17": {
      "equity": 2212.5,
      "debt": 7.202,
      "gold": 6441.379919,
      "pb": 3.83,
      "pe": 14.21,
      "yield": 1.65,
      "date": "2005-07-17"
  },
  "2005-07-18": {
      "equity": 2234,
      "debt": 7.16,
      "gold": 6475.800279,
      "pb": 3.86,
      "pe": 14.31,
      "yield": 1.64,
      "date": "2005-07-18"
  },
  "2005-07-19": {
      "equity": 2237.3,
      "debt": 7.181,
      "gold": 6451.224889,
      "pb": 3.87,
      "pe": 14.33,
      "yield": 1.64,
      "date": "2005-07-19"
  },
  "2005-07-20": {
      "equity": 2241.9,
      "debt": 7.174,
      "gold": 6457.690612,
      "pb": 3.87,
      "pe": 14.36,
      "yield": 1.64,
      "date": "2005-07-20"
  },
  "2005-07-21": {
      "equity": 2230.5,
      "debt": 7.158,
      "gold": 6451.99739,
      "pb": 3.85,
      "pe": 14.27,
      "yield": 1.65,
      "date": "2005-07-21"
  },
  "2005-07-22": {
      "equity": 2265.6,
      "debt": 7.163,
      "gold": 6539.737914,
      "pb": 3.91,
      "pe": 14.47,
      "yield": 1.62,
      "date": "2005-07-22"
  },
  "2005-07-23": {
      "equity": 2265.6,
      "debt": 7.174,
      "gold": 6539.737914,
      "pb": 3.91,
      "pe": 14.47,
      "yield": 1.62,
      "date": "2005-07-23"
  },
  "2005-07-24": {
      "equity": 2265.6,
      "debt": 7.174,
      "gold": 6539.737914,
      "pb": 3.91,
      "pe": 14.47,
      "yield": 1.62,
      "date": "2005-07-24"
  },
  "2005-07-25": {
      "equity": 2291.7,
      "debt": 7.165,
      "gold": 6526.665373,
      "pb": 3.82,
      "pe": 14.62,
      "yield": 1.63,
      "date": "2005-07-25"
  },
  "2005-07-26": {
      "equity": 2303.1,
      "debt": 6.999,
      "gold": 6509.490467,
      "pb": 3.84,
      "pe": 14.58,
      "yield": 1.62,
      "date": "2005-07-26"
  },
  "2005-07-27": {
      "equity": 2319.1,
      "debt": 6.999,
      "gold": 6470.724351,
      "pb": 3.87,
      "pe": 14.69,
      "yield": 1.61,
      "date": "2005-07-27"
  },
  "2005-07-28": {
      "equity": 2319.1,
      "debt": 6.999,
      "gold": 6520.629993,
      "pb": 3.87,
      "pe": 14.69,
      "yield": 1.61,
      "date": "2005-07-28"
  },
  "2005-07-29": {
      "equity": 2312.3,
      "debt": 6.96,
      "gold": 6551.254872,
      "pb": 3.86,
      "pe": 14.1,
      "yield": 1.62,
      "date": "2005-07-29"
  },
  "2005-07-30": {
      "equity": 2312.3,
      "debt": 6.994,
      "gold": 6551.254872,
      "pb": 3.86,
      "pe": 14.1,
      "yield": 1.62,
      "date": "2005-07-30"
  },
  "2005-07-31": {
      "equity": 2312.3,
      "debt": 6.994,
      "gold": 6551.254872,
      "pb": 3.86,
      "pe": 14.1,
      "yield": 1.62,
      "date": "2005-07-31"
  },
  "2005-08-01": {
      "equity": 2318,
      "debt": 7.015,
      "gold": 6607.301716,
      "pb": 3.87,
      "pe": 14.36,
      "yield": 1.61,
      "date": "2005-08-01"
  },
  "2005-08-02": {
      "equity": 2353.6,
      "debt": 6.98,
      "gold": 6632.480291,
      "pb": 3.92,
      "pe": 14.58,
      "yield": 1.59,
      "date": "2005-08-02"
  },
  "2005-08-03": {
      "equity": 2357,
      "debt": 6.974,
      "gold": 6644.642763,
      "pb": 3.93,
      "pe": 14.6,
      "yield": 1.59,
      "date": "2005-08-03"
  },
  "2005-08-04": {
      "equity": 2367.8,
      "debt": 6.98,
      "gold": 6687.384257,
      "pb": 3.95,
      "pe": 14.67,
      "yield": 1.58,
      "date": "2005-08-04"
  },
  "2005-08-05": {
      "equity": 2361.2,
      "debt": 6.987,
      "gold": 6710.580434,
      "pb": 3.94,
      "pe": 14.63,
      "yield": 1.58,
      "date": "2005-08-05"
  },
  "2005-08-06": {
      "equity": 2361.2,
      "debt": 7.001,
      "gold": 6710.580434,
      "pb": 3.94,
      "pe": 14.63,
      "yield": 1.58,
      "date": "2005-08-06"
  },
  "2005-08-07": {
      "equity": 2361.2,
      "debt": 7.001,
      "gold": 6710.580434,
      "pb": 3.94,
      "pe": 14.63,
      "yield": 1.58,
      "date": "2005-08-07"
  },
  "2005-08-08": {
      "equity": 2324.4,
      "debt": 7.009,
      "gold": 6710.813242,
      "pb": 3.87,
      "pe": 14.4,
      "yield": 1.61,
      "date": "2005-08-08"
  },
  "2005-08-09": {
      "equity": 2318.7,
      "debt": 7.052,
      "gold": 6677.401718,
      "pb": 3.86,
      "pe": 14.36,
      "yield": 1.62,
      "date": "2005-08-09"
  },
  "2005-08-10": {
      "equity": 2360.1,
      "debt": 7.021,
      "gold": 6703.716115,
      "pb": 3.93,
      "pe": 14.62,
      "yield": 1.59,
      "date": "2005-08-10"
  },
  "2005-08-11": {
      "equity": 2380.9,
      "debt": 7.017,
      "gold": 6732.355068,
      "pb": 3.96,
      "pe": 14.75,
      "yield": 1.57,
      "date": "2005-08-11"
  },
  "2005-08-12": {
      "equity": 2361.5,
      "debt": 7.028,
      "gold": 6868.808974,
      "pb": 3.93,
      "pe": 14.63,
      "yield": 1.59,
      "date": "2005-08-12"
  },
  "2005-08-13": {
      "equity": 2361.5,
      "debt": 7.059,
      "gold": 6868.808974,
      "pb": 3.93,
      "pe": 14.63,
      "yield": 1.59,
      "date": "2005-08-13"
  },
  "2005-08-14": {
      "equity": 2361.5,
      "debt": 7.059,
      "gold": 6868.808974,
      "pb": 3.93,
      "pe": 14.63,
      "yield": 1.59,
      "date": "2005-08-14"
  },
  "2005-08-15": {
      "equity": 2361.5,
      "debt": 7.059,
      "gold": 6797.393252,
      "pb": 3.93,
      "pe": 14.63,
      "yield": 1.59,
      "date": "2005-08-15"
  },
  "2005-08-16": {
      "equity": 2369.8,
      "debt": 7.079,
      "gold": 6769.741971,
      "pb": 3.95,
      "pe": 14.68,
      "yield": 1.58,
      "date": "2005-08-16"
  },
  "2005-08-17": {
      "equity": 2403.1,
      "debt": 7.077,
      "gold": 6822.529498,
      "pb": 3.92,
      "pe": 14.89,
      "yield": 1.58,
      "date": "2005-08-17"
  },
  "2005-08-18": {
      "equity": 2388.4,
      "debt": 7.07,
      "gold": 6746.937336,
      "pb": 3.89,
      "pe": 14.8,
      "yield": 1.59,
      "date": "2005-08-18"
  },
  "2005-08-19": {
      "equity": 2383.4,
      "debt": 7.072,
      "gold": 6752.715215,
      "pb": 3.89,
      "pe": 14.77,
      "yield": 1.59,
      "date": "2005-08-19"
  },
  "2005-08-20": {
      "equity": 2383.4,
      "debt": 7.072,
      "gold": 6752.715215,
      "pb": 3.89,
      "pe": 14.77,
      "yield": 1.59,
      "date": "2005-08-20"
  },
  "2005-08-21": {
      "equity": 2383.4,
      "debt": 7.072,
      "gold": 6752.715215,
      "pb": 3.89,
      "pe": 14.77,
      "yield": 1.59,
      "date": "2005-08-21"
  },
  "2005-08-22": {
      "equity": 2367.8,
      "debt": 7.074,
      "gold": 6732.573767,
      "pb": 3.86,
      "pe": 14.67,
      "yield": 1.61,
      "date": "2005-08-22"
  },
  "2005-08-23": {
      "equity": 2326.1,
      "debt": 7.085,
      "gold": 6773.389301,
      "pb": 3.79,
      "pe": 14.41,
      "yield": 1.64,
      "date": "2005-08-23"
  },
  "2005-08-24": {
      "equity": 2322.5,
      "debt": 7.097,
      "gold": 6761.88998,
      "pb": 3.78,
      "pe": 14.39,
      "yield": 1.64,
      "date": "2005-08-24"
  },
  "2005-08-25": {
      "equity": 2354.5,
      "debt": 7.09,
      "gold": 6768.61673,
      "pb": 3.84,
      "pe": 14.58,
      "yield": 1.62,
      "date": "2005-08-25"
  },
  "2005-08-26": {
      "equity": 2357,
      "debt": 7.081,
      "gold": 6758.972821,
      "pb": 3.84,
      "pe": 14.6,
      "yield": 1.61,
      "date": "2005-08-26"
  },
  "2005-08-27": {
      "equity": 2357,
      "debt": 7.069,
      "gold": 6758.972821,
      "pb": 3.84,
      "pe": 14.6,
      "yield": 1.61,
      "date": "2005-08-27"
  },
  "2005-08-28": {
      "equity": 2357,
      "debt": 7.069,
      "gold": 6758.972821,
      "pb": 3.84,
      "pe": 14.6,
      "yield": 1.61,
      "date": "2005-08-28"
  },
  "2005-08-29": {
      "equity": 2337.6,
      "debt": 7.088,
      "gold": 6758.972821,
      "pb": 3.81,
      "pe": 14.48,
      "yield": 1.63,
      "date": "2005-08-29"
  },
  "2005-08-30": {
      "equity": 2367.7,
      "debt": 7.086,
      "gold": 6775.258823,
      "pb": 3.86,
      "pe": 14.67,
      "yield": 1.61,
      "date": "2005-08-30"
  },
  "2005-08-31": {
      "equity": 2384.6,
      "debt": 7.094,
      "gold": 6743.31117,
      "pb": 3.89,
      "pe": 14.78,
      "yield": 1.59,
      "date": "2005-08-31"
  },
  "2005-09-01": {
      "equity": 2405.7,
      "debt": 7.083,
      "gold": 6763.04344,
      "pb": 3.92,
      "pe": 14.92,
      "yield": 1.58,
      "date": "2005-09-01"
  },
  "2005-09-02": {
      "equity": 2415.8,
      "debt": 7.043,
      "gold": 6906.358137,
      "pb": 3.94,
      "pe": 14.98,
      "yield": 1.57,
      "date": "2005-09-02"
  },
  "2005-09-03": {
      "equity": 2415.8,
      "debt": 7.033,
      "gold": 6906.358137,
      "pb": 3.94,
      "pe": 14.98,
      "yield": 1.57,
      "date": "2005-09-03"
  },
  "2005-09-04": {
      "equity": 2415.8,
      "debt": 7.033,
      "gold": 6906.358137,
      "pb": 3.94,
      "pe": 14.98,
      "yield": 1.57,
      "date": "2005-09-04"
  },
  "2005-09-05": {
      "equity": 2422.9,
      "debt": 7.034,
      "gold": 6886.879839,
      "pb": 3.93,
      "pe": 15.02,
      "yield": 1.55,
      "date": "2005-09-05"
  },
  "2005-09-06": {
      "equity": 2428.6,
      "debt": 7.025,
      "gold": 6866.939452,
      "pb": 3.94,
      "pe": 15.06,
      "yield": 1.55,
      "date": "2005-09-06"
  },
  "2005-09-07": {
      "equity": 2428.6,
      "debt": 7.025,
      "gold": 6873.800243,
      "pb": 3.94,
      "pe": 15.06,
      "yield": 1.55,
      "date": "2005-09-07"
  },
  "2005-09-08": {
      "equity": 2454.4,
      "debt": 7.034,
      "gold": 6875.694457,
      "pb": 3.98,
      "pe": 15.22,
      "yield": 1.53,
      "date": "2005-09-08"
  },
  "2005-09-09": {
      "equity": 2455.4,
      "debt": 7.034,
      "gold": 6908.213549,
      "pb": 3.98,
      "pe": 15.23,
      "yield": 1.53,
      "date": "2005-09-09"
  },
  "2005-09-10": {
      "equity": 2455.4,
      "debt": 7.034,
      "gold": 6908.213549,
      "pb": 3.98,
      "pe": 15.23,
      "yield": 1.53,
      "date": "2005-09-10"
  },
  "2005-09-11": {
      "equity": 2455.4,
      "debt": 7.034,
      "gold": 6908.213549,
      "pb": 3.98,
      "pe": 15.23,
      "yield": 1.53,
      "date": "2005-09-11"
  },
  "2005-09-12": {
      "equity": 2484.1,
      "debt": 7.031,
      "gold": 6944.521067,
      "pb": 4.03,
      "pe": 15.41,
      "yield": 1.51,
      "date": "2005-09-12"
  },
  "2005-09-13": {
      "equity": 2500.3,
      "debt": 7.018,
      "gold": 6950.034392,
      "pb": 4.05,
      "pe": 15.51,
      "yield": 1.5,
      "date": "2005-09-13"
  },
  "2005-09-14": {
      "equity": 2492.4,
      "debt": 7.013,
      "gold": 6935.476816,
      "pb": 4.04,
      "pe": 15.46,
      "yield": 1.51,
      "date": "2005-09-14"
  },
  "2005-09-15": {
      "equity": 2523.9,
      "debt": 6.999,
      "gold": 6953.060901,
      "pb": 4.09,
      "pe": 15.66,
      "yield": 1.49,
      "date": "2005-09-15"
  },
  "2005-09-16": {
      "equity": 2552.3,
      "debt": 7.019,
      "gold": 7089.976896,
      "pb": 4.14,
      "pe": 15.83,
      "yield": 1.47,
      "date": "2005-09-16"
  },
  "2005-09-17": {
      "equity": 2552.3,
      "debt": 7.012,
      "gold": 7089.976896,
      "pb": 4.14,
      "pe": 15.83,
      "yield": 1.47,
      "date": "2005-09-17"
  },
  "2005-09-18": {
      "equity": 2552.3,
      "debt": 7.012,
      "gold": 7089.976896,
      "pb": 4.14,
      "pe": 15.83,
      "yield": 1.47,
      "date": "2005-09-18"
  },
  "2005-09-19": {
      "equity": 2567.1,
      "debt": 6.998,
      "gold": 7155.053881,
      "pb": 4.07,
      "pe": 15.92,
      "yield": 1.57,
      "date": "2005-09-19"
  },
  "2005-09-20": {
      "equity": 2578,
      "debt": 7.005,
      "gold": 7273.937812,
      "pb": 4.09,
      "pe": 15.99,
      "yield": 1.56,
      "date": "2005-09-20"
  },
  "2005-09-21": {
      "equity": 2567.3,
      "debt": 7.005,
      "gold": 7228.134535,
      "pb": 4.07,
      "pe": 15.93,
      "yield": 1.57,
      "date": "2005-09-21"
  },
  "2005-09-22": {
      "equity": 2476.5,
      "debt": 7.045,
      "gold": 7325.670647,
      "pb": 3.93,
      "pe": 15.36,
      "yield": 1.63,
      "date": "2005-09-22"
  },
  "2005-09-23": {
      "equity": 2477.7,
      "debt": 7.06,
      "gold": 7194.356161,
      "pb": 3.93,
      "pe": 15.37,
      "yield": 1.63,
      "date": "2005-09-23"
  },
  "2005-09-24": {
      "equity": 2477.7,
      "debt": 7.039,
      "gold": 7194.356161,
      "pb": 3.93,
      "pe": 15.37,
      "yield": 1.63,
      "date": "2005-09-24"
  },
  "2005-09-25": {
      "equity": 2477.7,
      "debt": 7.039,
      "gold": 7194.356161,
      "pb": 3.93,
      "pe": 15.37,
      "yield": 1.63,
      "date": "2005-09-25"
  },
  "2005-09-26": {
      "equity": 2557.3,
      "debt": 7.039,
      "gold": 7150.059084,
      "pb": 3.94,
      "pe": 15.88,
      "yield": 1.73,
      "date": "2005-09-26"
  },
  "2005-09-27": {
      "equity": 2574.8,
      "debt": 7.088,
      "gold": 7235.358648,
      "pb": 3.97,
      "pe": 15.99,
      "yield": 1.72,
      "date": "2005-09-27"
  },
  "2005-09-28": {
      "equity": 2598,
      "debt": 7.074,
      "gold": 7176.412282,
      "pb": 4,
      "pe": 16.13,
      "yield": 1.7,
      "date": "2005-09-28"
  },
  "2005-09-29": {
      "equity": 2611.2,
      "debt": 7.095,
      "gold": 7336.178769,
      "pb": 4.03,
      "pe": 16.21,
      "yield": 1.69,
      "date": "2005-09-29"
  },
  "2005-09-30": {
      "equity": 2601.4,
      "debt": 7.101,
      "gold": 7349.935625,
      "pb": 4.01,
      "pe": 16.15,
      "yield": 1.7,
      "date": "2005-09-30"
  },
  "2005-10-01": {
      "equity": 2601.4,
      "debt": 7.101,
      "gold": 7349.935625,
      "pb": 4.01,
      "pe": 16.15,
      "yield": 1.7,
      "date": "2005-10-01"
  },
  "2005-10-02": {
      "equity": 2601.4,
      "debt": 7.101,
      "gold": 7349.935625,
      "pb": 4.01,
      "pe": 16.15,
      "yield": 1.7,
      "date": "2005-10-02"
  },
  "2005-10-03": {
      "equity": 2630,
      "debt": 7.155,
      "gold": 7237.972451,
      "pb": 4.06,
      "pe": 16.33,
      "yield": 1.68,
      "date": "2005-10-03"
  },
  "2005-10-04": {
      "equity": 2663.3,
      "debt": 7.144,
      "gold": 7292.139191,
      "pb": 4.11,
      "pe": 16.54,
      "yield": 1.66,
      "date": "2005-10-04"
  },
  "2005-10-05": {
      "equity": 2644.4,
      "debt": 7.144,
      "gold": 7268.121131,
      "pb": 4.08,
      "pe": 16.42,
      "yield": 1.67,
      "date": "2005-10-05"
  },
  "2005-10-06": {
      "equity": 2579.1,
      "debt": 7.179,
      "gold": 7283.091413,
      "pb": 3.98,
      "pe": 16.02,
      "yield": 1.71,
      "date": "2005-10-06"
  },
  "2005-10-07": {
      "equity": 2574,
      "debt": 7.179,
      "gold": 7391.467222,
      "pb": 3.97,
      "pe": 15.99,
      "yield": 1.72,
      "date": "2005-10-07"
  },
  "2005-10-08": {
      "equity": 2574,
      "debt": 7.179,
      "gold": 7391.467222,
      "pb": 3.97,
      "pe": 15.99,
      "yield": 1.72,
      "date": "2005-10-08"
  },
  "2005-10-09": {
      "equity": 2574,
      "debt": 7.179,
      "gold": 7391.467222,
      "pb": 3.97,
      "pe": 15.99,
      "yield": 1.72,
      "date": "2005-10-09"
  },
  "2005-10-10": {
      "equity": 2566.8,
      "debt": 7.178,
      "gold": 7538.231715,
      "pb": 3.96,
      "pe": 15.94,
      "yield": 1.72,
      "date": "2005-10-10"
  },
  "2005-10-11": {
      "equity": 2589.5,
      "debt": 7.157,
      "gold": 7526.785305,
      "pb": 3.99,
      "pe": 16,
      "yield": 1.71,
      "date": "2005-10-11"
  },
  "2005-10-12": {
      "equity": 2589.5,
      "debt": 7.157,
      "gold": 7574.274679,
      "pb": 3.99,
      "pe": 16,
      "yield": 1.71,
      "date": "2005-10-12"
  },
  "2005-10-13": {
      "equity": 2537.3,
      "debt": 7.143,
      "gold": 7471.779044,
      "pb": 3.91,
      "pe": 15.67,
      "yield": 1.74,
      "date": "2005-10-13"
  },
  "2005-10-14": {
      "equity": 2484.4,
      "debt": 7.129,
      "gold": 7446.039613,
      "pb": 3.83,
      "pe": 15.3,
      "yield": 1.78,
      "date": "2005-10-14"
  },
  "2005-10-15": {
      "equity": 2484.4,
      "debt": 7.129,
      "gold": 7446.039613,
      "pb": 3.83,
      "pe": 15.3,
      "yield": 1.78,
      "date": "2005-10-15"
  },
  "2005-10-16": {
      "equity": 2484.4,
      "debt": 7.129,
      "gold": 7446.039613,
      "pb": 3.83,
      "pe": 15.3,
      "yield": 1.78,
      "date": "2005-10-16"
  },
  "2005-10-17": {
      "equity": 2485.1,
      "debt": 7.136,
      "gold": 7477.341752,
      "pb": 3.8,
      "pe": 15.27,
      "yield": 1.78,
      "date": "2005-10-17"
  },
  "2005-10-18": {
      "equity": 2468.2,
      "debt": 7.178,
      "gold": 7536.23168,
      "pb": 3.78,
      "pe": 15.17,
      "yield": 1.79,
      "date": "2005-10-18"
  },
  "2005-10-19": {
      "equity": 2412.4,
      "debt": 7.171,
      "gold": 7462.526676,
      "pb": 3.69,
      "pe": 14.81,
      "yield": 1.83,
      "date": "2005-10-19"
  },
  "2005-10-20": {
      "equity": 2395.4,
      "debt": 7.178,
      "gold": 7402.035309,
      "pb": 3.67,
      "pe": 14.69,
      "yield": 1.85,
      "date": "2005-10-20"
  },
  "2005-10-21": {
      "equity": 2443.7,
      "debt": 7.171,
      "gold": 7363.971146,
      "pb": 3.74,
      "pe": 15.02,
      "yield": 1.81,
      "date": "2005-10-21"
  },
  "2005-10-22": {
      "equity": 2443.7,
      "debt": 7.171,
      "gold": 7363.971146,
      "pb": 3.74,
      "pe": 15.02,
      "yield": 1.81,
      "date": "2005-10-22"
  },
  "2005-10-23": {
      "equity": 2443.7,
      "debt": 7.171,
      "gold": 7363.971146,
      "pb": 3.74,
      "pe": 15.02,
      "yield": 1.81,
      "date": "2005-10-23"
  },
  "2005-10-24": {
      "equity": 2394.8,
      "debt": 7.108,
      "gold": 7387.216706,
      "pb": 3.67,
      "pe": 14.72,
      "yield": 1.85,
      "date": "2005-10-24"
  },
  "2005-10-25": {
      "equity": 2418.2,
      "debt": 7.128,
      "gold": 7425.185629,
      "pb": 3.7,
      "pe": 14.81,
      "yield": 1.83,
      "date": "2005-10-25"
  },
  "2005-10-26": {
      "equity": 2408.5,
      "debt": 7.093,
      "gold": 7496.696591,
      "pb": 3.69,
      "pe": 14.58,
      "yield": 1.84,
      "date": "2005-10-26"
  },
  "2005-10-27": {
      "equity": 2352.9,
      "debt": 7.085,
      "gold": 7526.644209,
      "pb": 3.6,
      "pe": 14.01,
      "yield": 1.88,
      "date": "2005-10-27"
  },
  "2005-10-28": {
      "equity": 2316,
      "debt": 7.078,
      "gold": 7532.171643,
      "pb": 3.55,
      "pe": 13.74,
      "yield": 1.91,
      "date": "2005-10-28"
  },
  "2005-10-29": {
      "equity": 2316,
      "debt": 7.078,
      "gold": 7532.171643,
      "pb": 3.55,
      "pe": 13.74,
      "yield": 1.91,
      "date": "2005-10-29"
  },
  "2005-10-30": {
      "equity": 2316,
      "debt": 7.078,
      "gold": 7532.171643,
      "pb": 3.55,
      "pe": 13.74,
      "yield": 1.91,
      "date": "2005-10-30"
  },
  "2005-10-31": {
      "equity": 2370.9,
      "debt": 7.099,
      "gold": 7514.185435,
      "pb": 3.63,
      "pe": 14.23,
      "yield": 1.87,
      "date": "2005-10-31"
  },
  "2005-11-01": {
      "equity": 2386.7,
      "debt": 7.099,
      "gold": 7407.054798,
      "pb": 3.65,
      "pe": 14.32,
      "yield": 1.85,
      "date": "2005-11-01"
  },
  "2005-11-02": {
      "equity": 2419,
      "debt": 7.099,
      "gold": 7361.290323,
      "pb": 3.7,
      "pe": 14.52,
      "yield": 1.83,
      "date": "2005-11-02"
  },
  "2005-11-03": {
      "equity": 2419,
      "debt": 7.096,
      "gold": 7420.536517,
      "pb": 3.7,
      "pe": 14.52,
      "yield": 1.83,
      "date": "2005-11-03"
  },
  "2005-11-04": {
      "equity": 2419,
      "debt": 7.103,
      "gold": 7405.326373,
      "pb": 3.7,
      "pe": 14.52,
      "yield": 1.83,
      "date": "2005-11-04"
  },
  "2005-11-05": {
      "equity": 2419,
      "debt": 7.103,
      "gold": 7405.326373,
      "pb": 3.7,
      "pe": 14.52,
      "yield": 1.83,
      "date": "2005-11-05"
  },
  "2005-11-06": {
      "equity": 2419,
      "debt": 7.103,
      "gold": 7405.326373,
      "pb": 3.7,
      "pe": 14.52,
      "yield": 1.83,
      "date": "2005-11-06"
  },
  "2005-11-07": {
      "equity": 2461.6,
      "debt": 7.106,
      "gold": 7398.402088,
      "pb": 3.77,
      "pe": 14.77,
      "yield": 1.8,
      "date": "2005-11-07"
  },
  "2005-11-08": {
      "equity": 2492.6,
      "debt": 7.106,
      "gold": 7395.156881,
      "pb": 3.82,
      "pe": 14.96,
      "yield": 1.78,
      "date": "2005-11-08"
  },
  "2005-11-09": {
      "equity": 2489.1,
      "debt": 7.127,
      "gold": 7461.292086,
      "pb": 3.81,
      "pe": 14.94,
      "yield": 1.78,
      "date": "2005-11-09"
  },
  "2005-11-10": {
      "equity": 2500.7,
      "debt": 7.123,
      "gold": 7544.61278,
      "pb": 3.83,
      "pe": 15.01,
      "yield": 1.77,
      "date": "2005-11-10"
  },
  "2005-11-11": {
      "equity": 2548.6,
      "debt": 7.12,
      "gold": 7507.941939,
      "pb": 3.9,
      "pe": 15.29,
      "yield": 1.74,
      "date": "2005-11-11"
  },
  "2005-11-12": {
      "equity": 2548.6,
      "debt": 7.12,
      "gold": 7507.941939,
      "pb": 3.9,
      "pe": 15.29,
      "yield": 1.74,
      "date": "2005-11-12"
  },
  "2005-11-13": {
      "equity": 2548.6,
      "debt": 7.12,
      "gold": 7507.941939,
      "pb": 3.9,
      "pe": 15.29,
      "yield": 1.74,
      "date": "2005-11-13"
  },
  "2005-11-14": {
      "equity": 2558.7,
      "debt": 7.098,
      "gold": 7589.040371,
      "pb": 3.92,
      "pe": 15.36,
      "yield": 1.73,
      "date": "2005-11-14"
  },
  "2005-11-15": {
      "equity": 2558.7,
      "debt": 7.098,
      "gold": 7545.251239,
      "pb": 3.92,
      "pe": 15.36,
      "yield": 1.73,
      "date": "2005-11-15"
  },
  "2005-11-16": {
      "equity": 2582.7,
      "debt": 7.098,
      "gold": 7611.88028,
      "pb": 3.96,
      "pe": 15.5,
      "yield": 1.71,
      "date": "2005-11-16"
  },
  "2005-11-17": {
      "equity": 2603.9,
      "debt": 7.098,
      "gold": 7774.380501,
      "pb": 3.99,
      "pe": 15.63,
      "yield": 1.7,
      "date": "2005-11-17"
  },
  "2005-11-18": {
      "equity": 2620,
      "debt": 7.094,
      "gold": 7873.659147,
      "pb": 4.01,
      "pe": 15.73,
      "yield": 1.69,
      "date": "2005-11-18"
  },
  "2005-11-19": {
      "equity": 2620,
      "debt": 7.094,
      "gold": 7873.659147,
      "pb": 4.01,
      "pe": 15.73,
      "yield": 1.69,
      "date": "2005-11-19"
  },
  "2005-11-20": {
      "equity": 2620,
      "debt": 7.094,
      "gold": 7873.659147,
      "pb": 4.01,
      "pe": 15.73,
      "yield": 1.69,
      "date": "2005-11-20"
  },
  "2005-11-21": {
      "equity": 2602.5,
      "debt": 7.091,
      "gold": 7876.131854,
      "pb": 3.99,
      "pe": 15.65,
      "yield": 1.7,
      "date": "2005-11-21"
  },
  "2005-11-22": {
      "equity": 2572.8,
      "debt": 7.077,
      "gold": 7959.438438,
      "pb": 3.95,
      "pe": 15.47,
      "yield": 1.72,
      "date": "2005-11-22"
  },
  "2005-11-23": {
      "equity": 2608.6,
      "debt": 7.065,
      "gold": 7892.435493,
      "pb": 4,
      "pe": 15.68,
      "yield": 1.69,
      "date": "2005-11-23"
  },
  "2005-11-24": {
      "equity": 2635,
      "debt": 7.069,
      "gold": 7943.896718,
      "pb": 4.04,
      "pe": 15.84,
      "yield": 1.68,
      "date": "2005-11-24"
  },
  "2005-11-25": {
      "equity": 2664.3,
      "debt": 7.091,
      "gold": 8013.520521,
      "pb": 4.09,
      "pe": 16.02,
      "yield": 1.66,
      "date": "2005-11-25"
  },
  "2005-11-26": {
      "equity": 2683.4,
      "debt": 7.091,
      "gold": 8013.520521,
      "pb": 4.12,
      "pe": 16.13,
      "yield": 1.65,
      "date": "2005-11-26"
  },
  "2005-11-27": {
      "equity": 2683.4,
      "debt": 7.091,
      "gold": 8013.520521,
      "pb": 4.12,
      "pe": 16.13,
      "yield": 1.65,
      "date": "2005-11-27"
  },
  "2005-11-28": {
      "equity": 2712,
      "debt": 7.09,
      "gold": 8046.402935,
      "pb": 4.15,
      "pe": 16.31,
      "yield": 1.64,
      "date": "2005-11-28"
  },
  "2005-11-29": {
      "equity": 2698.3,
      "debt": 7.076,
      "gold": 8048.427662,
      "pb": 4.13,
      "pe": 16.22,
      "yield": 1.65,
      "date": "2005-11-29"
  },
  "2005-11-30": {
      "equity": 2652.2,
      "debt": 7.083,
      "gold": 8003.707296,
      "pb": 4.06,
      "pe": 15.95,
      "yield": 1.68,
      "date": "2005-11-30"
  },
  "2005-12-01": {
      "equity": 2698.9,
      "debt": 7.079,
      "gold": 8015.174871,
      "pb": 4.13,
      "pe": 16.23,
      "yield": 1.65,
      "date": "2005-12-01"
  },
  "2005-12-02": {
      "equity": 2697.9,
      "debt": 7.097,
      "gold": 8220.360147,
      "pb": 4.13,
      "pe": 16.22,
      "yield": 1.65,
      "date": "2005-12-02"
  },
  "2005-12-03": {
      "equity": 2697.9,
      "debt": 7.097,
      "gold": 8220.360147,
      "pb": 4.13,
      "pe": 16.22,
      "yield": 1.65,
      "date": "2005-12-03"
  },
  "2005-12-04": {
      "equity": 2697.9,
      "debt": 7.097,
      "gold": 8220.360147,
      "pb": 4.13,
      "pe": 16.22,
      "yield": 1.65,
      "date": "2005-12-04"
  },
  "2005-12-05": {
      "equity": 2660.5,
      "debt": 7.09,
      "gold": 8249.369477,
      "pb": 4.08,
      "pe": 16,
      "yield": 1.68,
      "date": "2005-12-05"
  },
  "2005-12-06": {
      "equity": 2662.3,
      "debt": 7.097,
      "gold": 8280.050795,
      "pb": 4.08,
      "pe": 16.01,
      "yield": 1.68,
      "date": "2005-12-06"
  },
  "2005-12-07": {
      "equity": 2693,
      "debt": 7.097,
      "gold": 8350.545865,
      "pb": 4.13,
      "pe": 16.2,
      "yield": 1.66,
      "date": "2005-12-07"
  },
  "2005-12-08": {
      "equity": 2706.7,
      "debt": 7.097,
      "gold": 8397.55904,
      "pb": 4.15,
      "pe": 16.28,
      "yield": 1.65,
      "date": "2005-12-08"
  },
  "2005-12-09": {
      "equity": 2756.4,
      "debt": 7.104,
      "gold": 8554.408367,
      "pb": 4.22,
      "pe": 16.58,
      "yield": 1.62,
      "date": "2005-12-09"
  },
  "2005-12-10": {
      "equity": 2756.4,
      "debt": 7.104,
      "gold": 8554.408367,
      "pb": 4.22,
      "pe": 16.58,
      "yield": 1.62,
      "date": "2005-12-10"
  },
  "2005-12-11": {
      "equity": 2756.4,
      "debt": 7.104,
      "gold": 8554.408367,
      "pb": 4.22,
      "pe": 16.58,
      "yield": 1.62,
      "date": "2005-12-11"
  },
  "2005-12-12": {
      "equity": 2776.2,
      "debt": 7.111,
      "gold": 8738.559763,
      "pb": 4.25,
      "pe": 16.7,
      "yield": 1.61,
      "date": "2005-12-12"
  },
  "2005-12-13": {
      "equity": 2812.3,
      "debt": 7.111,
      "gold": 8500.492072,
      "pb": 4.31,
      "pe": 16.91,
      "yield": 1.59,
      "date": "2005-12-13"
  },
  "2005-12-14": {
      "equity": 2804.5,
      "debt": 7.111,
      "gold": 8287.648812,
      "pb": 4.3,
      "pe": 16.87,
      "yield": 1.59,
      "date": "2005-12-14"
  },
  "2005-12-15": {
      "equity": 2778.5,
      "debt": 7.111,
      "gold": 8052.720507,
      "pb": 4.26,
      "pe": 16.71,
      "yield": 1.61,
      "date": "2005-12-15"
  },
  "2005-12-16": {
      "equity": 2810.1,
      "debt": 7.104,
      "gold": 8033.051729,
      "pb": 4.3,
      "pe": 16.9,
      "yield": 1.59,
      "date": "2005-12-16"
  },
  "2005-12-17": {
      "equity": 2810.1,
      "debt": 7.104,
      "gold": 8033.051729,
      "pb": 4.3,
      "pe": 16.9,
      "yield": 1.59,
      "date": "2005-12-17"
  },
  "2005-12-18": {
      "equity": 2810.1,
      "debt": 7.104,
      "gold": 8033.051729,
      "pb": 4.3,
      "pe": 16.9,
      "yield": 1.59,
      "date": "2005-12-18"
  },
  "2005-12-19": {
      "equity": 2842.6,
      "debt": 7.115,
      "gold": 8062.064587,
      "pb": 4.38,
      "pe": 17.2,
      "yield": 1.56,
      "date": "2005-12-19"
  },
  "2005-12-20": {
      "equity": 2826.2,
      "debt": 7.118,
      "gold": 8070.734934,
      "pb": 4.35,
      "pe": 17.1,
      "yield": 1.57,
      "date": "2005-12-20"
  },
  "2005-12-21": {
      "equity": 2822.9,
      "debt": 7.118,
      "gold": 7888.590628,
      "pb": 4.35,
      "pe": 17.08,
      "yield": 1.57,
      "date": "2005-12-21"
  },
  "2005-12-22": {
      "equity": 2835.2,
      "debt": 7.129,
      "gold": 7888.654121,
      "pb": 4.37,
      "pe": 17.15,
      "yield": 1.56,
      "date": "2005-12-22"
  },
  "2005-12-23": {
      "equity": 2804.8,
      "debt": 7.122,
      "gold": 8023.545389,
      "pb": 4.32,
      "pe": 16.97,
      "yield": 1.58,
      "date": "2005-12-23"
  },
  "2005-12-24": {
      "equity": 2804.8,
      "debt": 7.122,
      "gold": 8023.545389,
      "pb": 4.32,
      "pe": 16.97,
      "yield": 1.58,
      "date": "2005-12-24"
  },
  "2005-12-25": {
      "equity": 2804.8,
      "debt": 7.122,
      "gold": 8023.545389,
      "pb": 4.32,
      "pe": 16.97,
      "yield": 1.58,
      "date": "2005-12-25"
  },
  "2005-12-26": {
      "equity": 2749.6,
      "debt": 7.125,
      "gold": 8023.545389,
      "pb": 4.24,
      "pe": 16.64,
      "yield": 1.61,
      "date": "2005-12-26"
  },
  "2005-12-27": {
      "equity": 2805.9,
      "debt": 7.117,
      "gold": 8023.545389,
      "pb": 4.32,
      "pe": 16.98,
      "yield": 1.58,
      "date": "2005-12-27"
  },
  "2005-12-28": {
      "equity": 2794,
      "debt": 7.117,
      "gold": 8106.135911,
      "pb": 4.31,
      "pe": 16.91,
      "yield": 1.59,
      "date": "2005-12-28"
  },
  "2005-12-29": {
      "equity": 2821.9,
      "debt": 7.11,
      "gold": 8236.127621,
      "pb": 4.35,
      "pe": 17.07,
      "yield": 1.57,
      "date": "2005-12-29"
  },
  "2005-12-30": {
      "equity": 2836.5,
      "debt": 7.11,
      "gold": 8152.048537,
      "pb": 4.37,
      "pe": 17.16,
      "yield": 1.56,
      "date": "2005-12-30"
  },
  "2005-12-31": {
      "equity": 2836.5,
      "debt": 7.11,
      "gold": 8152.048537,
      "pb": 4.37,
      "pe": 17.16,
      "yield": 1.56,
      "date": "2005-12-31"
  },
  "2006-01-01": {
      "equity": 2836.5,
      "debt": 7.11,
      "gold": 8152.048537,
      "pb": 4.37,
      "pe": 17.16,
      "yield": 1.56,
      "date": "2006-01-01"
  },
  "2006-01-02": {
      "equity": 2835.9,
      "debt": 7.18,
      "gold": 8152.048537,
      "pb": 4.37,
      "pe": 17.16,
      "yield": 1.56,
      "date": "2006-01-02"
  },
  "2006-01-03": {
      "equity": 2883.3,
      "debt": 7.18,
      "gold": 8274.981922,
      "pb": 4.44,
      "pe": 17.45,
      "yield": 1.54,
      "date": "2006-01-03"
  },
  "2006-01-04": {
      "equity": 2904.4,
      "debt": 7.189,
      "gold": 8436.660259,
      "pb": 4.48,
      "pe": 17.58,
      "yield": 1.53,
      "date": "2006-01-04"
  },
  "2006-01-05": {
      "equity": 2899.8,
      "debt": 7.176,
      "gold": 8386.677014,
      "pb": 4.47,
      "pe": 17.55,
      "yield": 1.53,
      "date": "2006-01-05"
  },
  "2006-01-06": {
      "equity": 2914,
      "debt": 7.183,
      "gold": 8327.399072,
      "pb": 4.49,
      "pe": 17.64,
      "yield": 1.52,
      "date": "2006-01-06"
  },
  "2006-01-07": {
      "equity": 2914,
      "debt": 7.183,
      "gold": 8327.399072,
      "pb": 4.49,
      "pe": 17.64,
      "yield": 1.52,
      "date": "2006-01-07"
  },
  "2006-01-08": {
      "equity": 2914,
      "debt": 7.183,
      "gold": 8327.399072,
      "pb": 4.49,
      "pe": 17.64,
      "yield": 1.52,
      "date": "2006-01-08"
  },
  "2006-01-09": {
      "equity": 2910.1,
      "debt": 7.187,
      "gold": 8454.826364,
      "pb": 4.49,
      "pe": 17.62,
      "yield": 1.52,
      "date": "2006-01-09"
  },
  "2006-01-10": {
      "equity": 2870.8,
      "debt": 7.188,
      "gold": 8525.57188,
      "pb": 4.43,
      "pe": 17.37,
      "yield": 1.54,
      "date": "2006-01-10"
  },
  "2006-01-11": {
      "equity": 2870.8,
      "debt": 7.188,
      "gold": 8478.664527,
      "pb": 4.43,
      "pe": 17.37,
      "yield": 1.54,
      "date": "2006-01-11"
  },
  "2006-01-12": {
      "equity": 2850.7,
      "debt": 7.196,
      "gold": 8526.474894,
      "pb": 4.4,
      "pe": 17.28,
      "yield": 1.55,
      "date": "2006-01-12"
  },
  "2006-01-13": {
      "equity": 2850.5,
      "debt": 7.213,
      "gold": 8539.953086,
      "pb": 4.4,
      "pe": 17.25,
      "yield": 1.55,
      "date": "2006-01-13"
  },
  "2006-01-14": {
      "equity": 2850.5,
      "debt": 7.213,
      "gold": 8539.953086,
      "pb": 4.4,
      "pe": 17.25,
      "yield": 1.55,
      "date": "2006-01-14"
  },
  "2006-01-15": {
      "equity": 2850.5,
      "debt": 7.213,
      "gold": 8539.953086,
      "pb": 4.4,
      "pe": 17.25,
      "yield": 1.55,
      "date": "2006-01-15"
  },
  "2006-01-16": {
      "equity": 2833.1,
      "debt": 7.202,
      "gold": 8733.011164,
      "pb": 4.37,
      "pe": 17.14,
      "yield": 1.56,
      "date": "2006-01-16"
  },
  "2006-01-17": {
      "equity": 2829.1,
      "debt": 7.19,
      "gold": 8732.859486,
      "pb": 4.36,
      "pe": 17.12,
      "yield": 1.57,
      "date": "2006-01-17"
  },
  "2006-01-18": {
      "equity": 2809.2,
      "debt": 7.201,
      "gold": 8544.655109,
      "pb": 4.24,
      "pe": 16.53,
      "yield": 1.61,
      "date": "2006-01-18"
  },
  "2006-01-19": {
      "equity": 2870.8,
      "debt": 7.215,
      "gold": 8567.745463,
      "pb": 4.33,
      "pe": 16.87,
      "yield": 1.58,
      "date": "2006-01-19"
  },
  "2006-01-20": {
      "equity": 2900.9,
      "debt": 7.203,
      "gold": 8729.187464,
      "pb": 4.38,
      "pe": 16.91,
      "yield": 1.56,
      "date": "2006-01-20"
  },
  "2006-01-21": {
      "equity": 2900.9,
      "debt": 7.203,
      "gold": 8729.187464,
      "pb": 4.38,
      "pe": 16.91,
      "yield": 1.56,
      "date": "2006-01-21"
  },
  "2006-01-22": {
      "equity": 2900.9,
      "debt": 7.203,
      "gold": 8729.187464,
      "pb": 4.38,
      "pe": 16.91,
      "yield": 1.56,
      "date": "2006-01-22"
  },
  "2006-01-23": {
      "equity": 2884,
      "debt": 7.205,
      "gold": 8704.185259,
      "pb": 4.35,
      "pe": 16.83,
      "yield": 1.57,
      "date": "2006-01-23"
  },
  "2006-01-24": {
      "equity": 2908,
      "debt": 7.325,
      "gold": 8691.550116,
      "pb": 4.39,
      "pe": 16.86,
      "yield": 1.56,
      "date": "2006-01-24"
  },
  "2006-01-25": {
      "equity": 2940.3,
      "debt": 7.448,
      "gold": 8761.138644,
      "pb": 4.44,
      "pe": 17.02,
      "yield": 1.54,
      "date": "2006-01-25"
  },
  "2006-01-26": {
      "equity": 2940.3,
      "debt": 7.448,
      "gold": 8761.138644,
      "pb": 4.44,
      "pe": 17.02,
      "yield": 1.54,
      "date": "2006-01-26"
  },
  "2006-01-27": {
      "equity": 2982.7,
      "debt": 7.485,
      "gold": 8716.259546,
      "pb": 4.5,
      "pe": 17.42,
      "yield": 1.52,
      "date": "2006-01-27"
  },
  "2006-01-28": {
      "equity": 2982.7,
      "debt": 7.485,
      "gold": 8716.259546,
      "pb": 4.5,
      "pe": 17.42,
      "yield": 1.52,
      "date": "2006-01-28"
  },
  "2006-01-29": {
      "equity": 2982.7,
      "debt": 7.485,
      "gold": 8716.259546,
      "pb": 4.5,
      "pe": 17.42,
      "yield": 1.52,
      "date": "2006-01-29"
  },
  "2006-01-30": {
      "equity": 2974.5,
      "debt": 7.425,
      "gold": 8720.139685,
      "pb": 4.49,
      "pe": 17.91,
      "yield": 1.52,
      "date": "2006-01-30"
  },
  "2006-01-31": {
      "equity": 3001.1,
      "debt": 7.372,
      "gold": 8867.228699,
      "pb": 4.53,
      "pe": 17.9,
      "yield": 1.51,
      "date": "2006-01-31"
  },
  "2006-02-01": {
      "equity": 2971.5,
      "debt": 7.368,
      "gold": 8845.274167,
      "pb": 4.49,
      "pe": 17.73,
      "yield": 1.52,
      "date": "2006-02-01"
  },
  "2006-02-02": {
      "equity": 2967.4,
      "debt": 7.395,
      "gold": 8928.379689,
      "pb": 4.48,
      "pe": 17.7,
      "yield": 1.53,
      "date": "2006-02-02"
  },
  "2006-02-03": {
      "equity": 2940.6,
      "debt": 7.349,
      "gold": 8915.268347,
      "pb": 4.44,
      "pe": 17.54,
      "yield": 1.54,
      "date": "2006-02-03"
  },
  "2006-02-04": {
      "equity": 2940.6,
      "debt": 7.349,
      "gold": 8915.268347,
      "pb": 4.44,
      "pe": 17.54,
      "yield": 1.54,
      "date": "2006-02-04"
  },
  "2006-02-05": {
      "equity": 2940.6,
      "debt": 7.349,
      "gold": 8915.268347,
      "pb": 4.44,
      "pe": 17.54,
      "yield": 1.54,
      "date": "2006-02-05"
  },
  "2006-02-06": {
      "equity": 3000.4,
      "debt": 7.326,
      "gold": 8895.977001,
      "pb": 4.53,
      "pe": 17.9,
      "yield": 1.51,
      "date": "2006-02-06"
  },
  "2006-02-07": {
      "equity": 3020.1,
      "debt": 7.355,
      "gold": 8861.648354,
      "pb": 4.56,
      "pe": 18.02,
      "yield": 1.5,
      "date": "2006-02-07"
  },
  "2006-02-08": {
      "equity": 3008.9,
      "debt": 7.364,
      "gold": 8565.671352,
      "pb": 4.54,
      "pe": 17.95,
      "yield": 1.5,
      "date": "2006-02-08"
  },
  "2006-02-09": {
      "equity": 3008.9,
      "debt": 7.364,
      "gold": 8713.455264,
      "pb": 4.54,
      "pe": 17.95,
      "yield": 1.5,
      "date": "2006-02-09"
  },
  "2006-02-10": {
      "equity": 3027.5,
      "debt": 7.352,
      "gold": 8711.529304,
      "pb": 4.57,
      "pe": 18.03,
      "yield": 1.49,
      "date": "2006-02-10"
  },
  "2006-02-11": {
      "equity": 3027.5,
      "debt": 7.352,
      "gold": 8711.529304,
      "pb": 4.57,
      "pe": 18.03,
      "yield": 1.49,
      "date": "2006-02-11"
  },
  "2006-02-12": {
      "equity": 3027.5,
      "debt": 7.352,
      "gold": 8711.529304,
      "pb": 4.57,
      "pe": 18.03,
      "yield": 1.49,
      "date": "2006-02-12"
  },
  "2006-02-13": {
      "equity": 3041.1,
      "debt": 7.345,
      "gold": 8534.146987,
      "pb": 4.59,
      "pe": 18.11,
      "yield": 1.49,
      "date": "2006-02-13"
  },
  "2006-02-14": {
      "equity": 3017.5,
      "debt": 7.359,
      "gold": 8431.757174,
      "pb": 4.56,
      "pe": 17.93,
      "yield": 1.5,
      "date": "2006-02-14"
  },
  "2006-02-15": {
      "equity": 3022.2,
      "debt": 7.374,
      "gold": 8490.07919,
      "pb": 4.56,
      "pe": 17.96,
      "yield": 1.5,
      "date": "2006-02-15"
  },
  "2006-02-16": {
      "equity": 3021.6,
      "debt": 7.342,
      "gold": 8457.708249,
      "pb": 4.56,
      "pe": 17.95,
      "yield": 1.5,
      "date": "2006-02-16"
  },
  "2006-02-17": {
      "equity": 2981.5,
      "debt": 7.342,
      "gold": 8560.341452,
      "pb": 4.5,
      "pe": 17.71,
      "yield": 1.52,
      "date": "2006-02-17"
  },
  "2006-02-18": {
      "equity": 2981.5,
      "debt": 7.342,
      "gold": 8560.341452,
      "pb": 4.5,
      "pe": 17.71,
      "yield": 1.52,
      "date": "2006-02-18"
  },
  "2006-02-19": {
      "equity": 2981.5,
      "debt": 7.342,
      "gold": 8560.341452,
      "pb": 4.5,
      "pe": 17.71,
      "yield": 1.52,
      "date": "2006-02-19"
  },
  "2006-02-20": {
      "equity": 3005.8,
      "debt": 7.348,
      "gold": 8701.504436,
      "pb": 4.54,
      "pe": 17.86,
      "yield": 1.51,
      "date": "2006-02-20"
  },
  "2006-02-21": {
      "equity": 3035.5,
      "debt": 7.343,
      "gold": 8669.108803,
      "pb": 4.58,
      "pe": 18.03,
      "yield": 1.49,
      "date": "2006-02-21"
  },
  "2006-02-22": {
      "equity": 3050.8,
      "debt": 7.366,
      "gold": 8689.486587,
      "pb": 4.61,
      "pe": 18.13,
      "yield": 1.48,
      "date": "2006-02-22"
  },
  "2006-02-23": {
      "equity": 3062.1,
      "debt": 7.379,
      "gold": 8661.831778,
      "pb": 4.62,
      "pe": 18.19,
      "yield": 1.48,
      "date": "2006-02-23"
  },
  "2006-02-24": {
      "equity": 3050,
      "debt": 7.389,
      "gold": 8633.425634,
      "pb": 4.61,
      "pe": 18.12,
      "yield": 1.48,
      "date": "2006-02-24"
  },
  "2006-02-25": {
      "equity": 3050,
      "debt": 7.389,
      "gold": 8633.425634,
      "pb": 4.61,
      "pe": 18.12,
      "yield": 1.48,
      "date": "2006-02-25"
  },
  "2006-02-26": {
      "equity": 3050,
      "debt": 7.389,
      "gold": 8633.425634,
      "pb": 4.61,
      "pe": 18.12,
      "yield": 1.48,
      "date": "2006-02-26"
  },
  "2006-02-27": {
      "equity": 3067.4,
      "debt": 7.371,
      "gold": 8728.07986,
      "pb": 4.63,
      "pe": 18.22,
      "yield": 1.48,
      "date": "2006-02-27"
  },
  "2006-02-28": {
      "equity": 3074.7,
      "debt": 7.393,
      "gold": 8713.748038,
      "pb": 4.64,
      "pe": 18.27,
      "yield": 1.47,
      "date": "2006-02-28"
  },
  "2006-03-01": {
      "equity": 3123.1,
      "debt": 7.408,
      "gold": 8805.291099,
      "pb": 4.72,
      "pe": 18.56,
      "yield": 1.45,
      "date": "2006-03-01"
  },
  "2006-03-02": {
      "equity": 3150.7,
      "debt": 7.411,
      "gold": 8824.543643,
      "pb": 4.76,
      "pe": 18.73,
      "yield": 1.44,
      "date": "2006-03-02"
  },
  "2006-03-03": {
      "equity": 3147.3,
      "debt": 7.447,
      "gold": 8892.939911,
      "pb": 4.75,
      "pe": 18.71,
      "yield": 1.44,
      "date": "2006-03-03"
  },
  "2006-03-04": {
      "equity": 3147.3,
      "debt": 7.447,
      "gold": 8892.939911,
      "pb": 4.75,
      "pe": 18.71,
      "yield": 1.44,
      "date": "2006-03-04"
  },
  "2006-03-05": {
      "equity": 3147.3,
      "debt": 7.447,
      "gold": 8892.939911,
      "pb": 4.75,
      "pe": 18.71,
      "yield": 1.44,
      "date": "2006-03-05"
  },
  "2006-03-06": {
      "equity": 3190.4,
      "debt": 7.477,
      "gold": 8880.290658,
      "pb": 4.82,
      "pe": 18.96,
      "yield": 1.42,
      "date": "2006-03-06"
  },
  "2006-03-07": {
      "equity": 3182.8,
      "debt": 7.435,
      "gold": 8687.574737,
      "pb": 4.81,
      "pe": 18.92,
      "yield": 1.42,
      "date": "2006-03-07"
  },
  "2006-03-08": {
      "equity": 3116.7,
      "debt": 7.41,
      "gold": 8608.197675,
      "pb": 4.71,
      "pe": 18.53,
      "yield": 1.45,
      "date": "2006-03-08"
  },
  "2006-03-09": {
      "equity": 3129.1,
      "debt": 7.389,
      "gold": 8587.248452,
      "pb": 4.73,
      "pe": 18.6,
      "yield": 1.45,
      "date": "2006-03-09"
  },
  "2006-03-10": {
      "equity": 3183.9,
      "debt": 7.409,
      "gold": 8560.14039,
      "pb": 4.81,
      "pe": 18.93,
      "yield": 1.42,
      "date": "2006-03-10"
  },
  "2006-03-11": {
      "equity": 3183.9,
      "debt": 7.409,
      "gold": 8560.14039,
      "pb": 4.81,
      "pe": 18.93,
      "yield": 1.42,
      "date": "2006-03-11"
  },
  "2006-03-12": {
      "equity": 3183.9,
      "debt": 7.409,
      "gold": 8560.14039,
      "pb": 4.81,
      "pe": 18.93,
      "yield": 1.42,
      "date": "2006-03-12"
  },
  "2006-03-13": {
      "equity": 3202.6,
      "debt": 7.413,
      "gold": 8561.403199,
      "pb": 4.84,
      "pe": 19.04,
      "yield": 1.41,
      "date": "2006-03-13"
  },
  "2006-03-14": {
      "equity": 3195.3,
      "debt": 7.416,
      "gold": 8555.865183,
      "pb": 4.83,
      "pe": 19,
      "yield": 1.42,
      "date": "2006-03-14"
  },
  "2006-03-15": {
      "equity": 3195.3,
      "debt": 7.416,
      "gold": 8636.889539,
      "pb": 4.83,
      "pe": 19,
      "yield": 1.42,
      "date": "2006-03-15"
  },
  "2006-03-16": {
      "equity": 3226.6,
      "debt": 7.41,
      "gold": 8670.664386,
      "pb": 4.87,
      "pe": 19.18,
      "yield": 1.4,
      "date": "2006-03-16"
  },
  "2006-03-17": {
      "equity": 3234,
      "debt": 7.391,
      "gold": 8710.333516,
      "pb": 4.89,
      "pe": 19.25,
      "yield": 1.4,
      "date": "2006-03-17"
  },
  "2006-03-18": {
      "equity": 3234,
      "debt": 7.391,
      "gold": 8710.333516,
      "pb": 4.89,
      "pe": 19.25,
      "yield": 1.4,
      "date": "2006-03-18"
  },
  "2006-03-19": {
      "equity": 3234,
      "debt": 7.391,
      "gold": 8710.333516,
      "pb": 4.89,
      "pe": 19.25,
      "yield": 1.4,
      "date": "2006-03-19"
  },
  "2006-03-20": {
      "equity": 3265.6,
      "debt": 7.385,
      "gold": 8680.470555,
      "pb": 4.94,
      "pe": 19.44,
      "yield": 1.38,
      "date": "2006-03-20"
  },
  "2006-03-21": {
      "equity": 3262.3,
      "debt": 7.375,
      "gold": 8667.722535,
      "pb": 4.93,
      "pe": 19.42,
      "yield": 1.38,
      "date": "2006-03-21"
  },
  "2006-03-22": {
      "equity": 3240.1,
      "debt": 7.39,
      "gold": 8610.49754,
      "pb": 4.9,
      "pe": 19.28,
      "yield": 1.39,
      "date": "2006-03-22"
  },
  "2006-03-23": {
      "equity": 3247.1,
      "debt": 7.393,
      "gold": 8610.613944,
      "pb": 4.91,
      "pe": 19.33,
      "yield": 1.39,
      "date": "2006-03-23"
  },
  "2006-03-24": {
      "equity": 3279.8,
      "debt": 7.385,
      "gold": 8659.443729,
      "pb": 4.96,
      "pe": 19.52,
      "yield": 1.38,
      "date": "2006-03-24"
  },
  "2006-03-25": {
      "equity": 3279.8,
      "debt": 7.385,
      "gold": 8659.443729,
      "pb": 4.96,
      "pe": 19.52,
      "yield": 1.38,
      "date": "2006-03-25"
  },
  "2006-03-26": {
      "equity": 3279.8,
      "debt": 7.385,
      "gold": 8659.443729,
      "pb": 4.96,
      "pe": 19.52,
      "yield": 1.38,
      "date": "2006-03-26"
  },
  "2006-03-27": {
      "equity": 3321.6,
      "debt": 7.434,
      "gold": 8886.647031,
      "pb": 5.02,
      "pe": 19.77,
      "yield": 1.36,
      "date": "2006-03-27"
  },
  "2006-03-28": {
      "equity": 3325,
      "debt": 7.43,
      "gold": 8878.97141,
      "pb": 5.03,
      "pe": 19.79,
      "yield": 1.36,
      "date": "2006-03-28"
  },
  "2006-03-29": {
      "equity": 3354.2,
      "debt": 7.493,
      "gold": 8866.015274,
      "pb": 5.07,
      "pe": 19.97,
      "yield": 1.35,
      "date": "2006-03-29"
  },
  "2006-03-30": {
      "equity": 3418.9,
      "debt": 7.493,
      "gold": 9121.483624,
      "pb": 5.17,
      "pe": 20.35,
      "yield": 1.32,
      "date": "2006-03-30"
  },
  "2006-03-31": {
      "equity": 3402.5,
      "debt": 7.55,
      "gold": 9192.239722,
      "pb": 5.15,
      "pe": 20.26,
      "yield": 1.33,
      "date": "2006-03-31"
  },
  "2006-04-01": {
      "equity": 3402.5,
      "debt": 7.55,
      "gold": 9192.239722,
      "pb": 5.15,
      "pe": 20.26,
      "yield": 1.33,
      "date": "2006-04-01"
  },
  "2006-04-02": {
      "equity": 3402.5,
      "debt": 7.55,
      "gold": 9192.239722,
      "pb": 5.15,
      "pe": 20.26,
      "yield": 1.33,
      "date": "2006-04-02"
  },
  "2006-04-03": {
      "equity": 3473.3,
      "debt": 7.557,
      "gold": 9211.471102,
      "pb": 5.26,
      "pe": 20.68,
      "yield": 1.3,
      "date": "2006-04-03"
  },
  "2006-04-04": {
      "equity": 3483.1,
      "debt": 7.549,
      "gold": 9226.818815,
      "pb": 5.27,
      "pe": 20.74,
      "yield": 1.3,
      "date": "2006-04-04"
  },
  "2006-04-05": {
      "equity": 3510.9,
      "debt": 7.538,
      "gold": 9208.927847,
      "pb": 5.31,
      "pe": 20.9,
      "yield": 1.29,
      "date": "2006-04-05"
  },
  "2006-04-06": {
      "equity": 3510.9,
      "debt": 7.538,
      "gold": 9385.85513,
      "pb": 5.31,
      "pe": 20.9,
      "yield": 1.29,
      "date": "2006-04-06"
  },
  "2006-04-07": {
      "equity": 3454.8,
      "debt": 7.546,
      "gold": 9410.550451,
      "pb": 5.23,
      "pe": 20.57,
      "yield": 1.31,
      "date": "2006-04-07"
  },
  "2006-04-08": {
      "equity": 3454.8,
      "debt": 7.546,
      "gold": 9410.550451,
      "pb": 5.23,
      "pe": 20.57,
      "yield": 1.31,
      "date": "2006-04-08"
  },
  "2006-04-09": {
      "equity": 3454.8,
      "debt": 7.546,
      "gold": 9410.550451,
      "pb": 5.23,
      "pe": 20.57,
      "yield": 1.31,
      "date": "2006-04-09"
  },
  "2006-04-10": {
      "equity": 3478.4,
      "debt": 7.544,
      "gold": 9449.517628,
      "pb": 5.26,
      "pe": 20.71,
      "yield": 1.3,
      "date": "2006-04-10"
  },
  "2006-04-11": {
      "equity": 3478.4,
      "debt": 7.544,
      "gold": 9481.147816,
      "pb": 5.26,
      "pe": 20.71,
      "yield": 1.3,
      "date": "2006-04-11"
  },
  "2006-04-12": {
      "equity": 3380,
      "debt": 7.575,
      "gold": 9512.382934,
      "pb": 5.12,
      "pe": 20.13,
      "yield": 1.34,
      "date": "2006-04-12"
  },
  "2006-04-13": {
      "equity": 3345.5,
      "debt": 7.611,
      "gold": 9509.000159,
      "pb": 5.06,
      "pe": 19.91,
      "yield": 1.35,
      "date": "2006-04-13"
  },
  "2006-04-14": {
      "equity": 3345.5,
      "debt": 7.611,
      "gold": 9509.000159,
      "pb": 5.06,
      "pe": 19.91,
      "yield": 1.35,
      "date": "2006-04-14"
  },
  "2006-04-15": {
      "equity": 3345.5,
      "debt": 7.611,
      "gold": 9509.000159,
      "pb": 5.06,
      "pe": 19.91,
      "yield": 1.35,
      "date": "2006-04-15"
  },
  "2006-04-16": {
      "equity": 3345.5,
      "debt": 7.611,
      "gold": 9509.000159,
      "pb": 5.06,
      "pe": 19.91,
      "yield": 1.35,
      "date": "2006-04-16"
  },
  "2006-04-17": {
      "equity": 3425.1,
      "debt": 7.632,
      "gold": 9509.000159,
      "pb": 5.18,
      "pe": 20.34,
      "yield": 1.32,
      "date": "2006-04-17"
  },
  "2006-04-18": {
      "equity": 3518.1,
      "debt": 7.537,
      "gold": 9830.102824,
      "pb": 5.32,
      "pe": 20.76,
      "yield": 1.28,
      "date": "2006-04-18"
  },
  "2006-04-19": {
      "equity": 3535.8,
      "debt": 7.318,
      "gold": 9923.518933,
      "pb": 5.35,
      "pe": 20.79,
      "yield": 1.28,
      "date": "2006-04-19"
  },
  "2006-04-20": {
      "equity": 3573.5,
      "debt": 7.358,
      "gold": 10256.78054,
      "pb": 5.41,
      "pe": 21.01,
      "yield": 1.26,
      "date": "2006-04-20"
  },
  "2006-04-21": {
      "equity": 3573,
      "debt": 7.338,
      "gold": 9928.891162,
      "pb": 5.41,
      "pe": 21.01,
      "yield": 1.26,
      "date": "2006-04-21"
  },
  "2006-04-22": {
      "equity": 3573,
      "debt": 7.338,
      "gold": 9928.891162,
      "pb": 5.41,
      "pe": 21.01,
      "yield": 1.26,
      "date": "2006-04-22"
  },
  "2006-04-23": {
      "equity": 3573,
      "debt": 7.338,
      "gold": 9928.891162,
      "pb": 5.41,
      "pe": 21.01,
      "yield": 1.26,
      "date": "2006-04-23"
  },
  "2006-04-24": {
      "equity": 3548.9,
      "debt": 7.357,
      "gold": 10045.77859,
      "pb": 5.37,
      "pe": 20.85,
      "yield": 1.27,
      "date": "2006-04-24"
  },
  "2006-04-25": {
      "equity": 3462.6,
      "debt": 7.35,
      "gold": 9963.353851,
      "pb": 5.24,
      "pe": 20.37,
      "yield": 1.3,
      "date": "2006-04-25"
  },
  "2006-04-26": {
      "equity": 3555.7,
      "debt": 7.405,
      "gold": 10025.43255,
      "pb": 5.38,
      "pe": 20.93,
      "yield": 1.27,
      "date": "2006-04-26"
  },
  "2006-04-27": {
      "equity": 3508.1,
      "debt": 7.42,
      "gold": 10108.96488,
      "pb": 5.31,
      "pe": 20.58,
      "yield": 1.29,
      "date": "2006-04-27"
  },
  "2006-04-28": {
      "equity": 3508.3,
      "debt": 7.39,
      "gold": 10104.11471,
      "pb": 5.31,
      "pe": 20.08,
      "yield": 1.29,
      "date": "2006-04-28"
  },
  "2006-04-29": {
      "equity": 3557.6,
      "debt": 7.39,
      "gold": 10104.11471,
      "pb": 5.39,
      "pe": 20.31,
      "yield": 1.27,
      "date": "2006-04-29"
  },
  "2006-04-30": {
      "equity": 3557.6,
      "debt": 7.39,
      "gold": 10104.11471,
      "pb": 5.39,
      "pe": 20.31,
      "yield": 1.27,
      "date": "2006-04-30"
  },
  "2006-05-01": {
      "equity": 3557.6,
      "debt": 7.39,
      "gold": 10104.11471,
      "pb": 5.39,
      "pe": 20.31,
      "yield": 1.27,
      "date": "2006-05-01"
  },
  "2006-05-02": {
      "equity": 3605.4,
      "debt": 7.472,
      "gold": 10401.21695,
      "pb": 5.46,
      "pe": 20.46,
      "yield": 1.25,
      "date": "2006-05-02"
  },
  "2006-05-03": {
      "equity": 3634.2,
      "debt": 7.507,
      "gold": 10675.571,
      "pb": 5.5,
      "pe": 20.62,
      "yield": 1.24,
      "date": "2006-05-03"
  },
  "2006-05-04": {
      "equity": 3648.4,
      "debt": 7.535,
      "gold": 10515.0179,
      "pb": 5.52,
      "pe": 20.7,
      "yield": 1.24,
      "date": "2006-05-04"
  },
  "2006-05-05": {
      "equity": 3663.9,
      "debt": 7.537,
      "gold": 10787.09677,
      "pb": 5.55,
      "pe": 20.77,
      "yield": 1.23,
      "date": "2006-05-05"
  },
  "2006-05-06": {
      "equity": 3663.9,
      "debt": 7.537,
      "gold": 10787.09677,
      "pb": 5.55,
      "pe": 20.77,
      "yield": 1.23,
      "date": "2006-05-06"
  },
  "2006-05-07": {
      "equity": 3663.9,
      "debt": 7.537,
      "gold": 10787.09677,
      "pb": 5.55,
      "pe": 20.77,
      "yield": 1.23,
      "date": "2006-05-07"
  },
  "2006-05-08": {
      "equity": 3693.1,
      "debt": 7.522,
      "gold": 10784.69109,
      "pb": 5.59,
      "pe": 20.93,
      "yield": 1.22,
      "date": "2006-05-08"
  },
  "2006-05-09": {
      "equity": 3720.5,
      "debt": 7.49,
      "gold": 10770.69084,
      "pb": 5.63,
      "pe": 21.09,
      "yield": 1.21,
      "date": "2006-05-09"
  },
  "2006-05-10": {
      "equity": 3754.2,
      "debt": 7.5,
      "gold": 11164.65546,
      "pb": 5.68,
      "pe": 21.28,
      "yield": 1.2,
      "date": "2006-05-10"
  },
  "2006-05-11": {
      "equity": 3701,
      "debt": 7.532,
      "gold": 11243.61982,
      "pb": 5.6,
      "pe": 20.98,
      "yield": 1.22,
      "date": "2006-05-11"
  },
  "2006-05-12": {
      "equity": 3650,
      "debt": 7.545,
      "gold": 11494.44611,
      "pb": 5.53,
      "pe": 20.69,
      "yield": 1.24,
      "date": "2006-05-12"
  },
  "2006-05-13": {
      "equity": 3650,
      "debt": 7.545,
      "gold": 11494.44611,
      "pb": 5.53,
      "pe": 20.69,
      "yield": 1.24,
      "date": "2006-05-13"
  },
  "2006-05-14": {
      "equity": 3650,
      "debt": 7.545,
      "gold": 11494.44611,
      "pb": 5.53,
      "pe": 20.69,
      "yield": 1.24,
      "date": "2006-05-14"
  },
  "2006-05-15": {
      "equity": 3502.9,
      "debt": 7.591,
      "gold": 11109.58571,
      "pb": 5.3,
      "pe": 19.86,
      "yield": 1.29,
      "date": "2006-05-15"
  },
  "2006-05-16": {
      "equity": 3523.3,
      "debt": 7.571,
      "gold": 10933.4274,
      "pb": 5.34,
      "pe": 19.97,
      "yield": 1.28,
      "date": "2006-05-16"
  },
  "2006-05-17": {
      "equity": 3635.1,
      "debt": 7.584,
      "gold": 11361.67481,
      "pb": 5.51,
      "pe": 20.62,
      "yield": 1.24,
      "date": "2006-05-17"
  },
  "2006-05-18": {
      "equity": 3388.9,
      "debt": 7.626,
      "gold": 11031.24923,
      "pb": 5.13,
      "pe": 19.26,
      "yield": 1.33,
      "date": "2006-05-18"
  },
  "2006-05-19": {
      "equity": 3246.9,
      "debt": 7.612,
      "gold": 10961.50902,
      "pb": 4.92,
      "pe": 18.51,
      "yield": 1.39,
      "date": "2006-05-19"
  },
  "2006-05-20": {
      "equity": 3246.9,
      "debt": 7.612,
      "gold": 10961.50902,
      "pb": 4.92,
      "pe": 18.51,
      "yield": 1.39,
      "date": "2006-05-20"
  },
  "2006-05-21": {
      "equity": 3246.9,
      "debt": 7.612,
      "gold": 10961.50902,
      "pb": 4.92,
      "pe": 18.51,
      "yield": 1.39,
      "date": "2006-05-21"
  },
  "2006-05-22": {
      "equity": 3081.3,
      "debt": 7.597,
      "gold": 10373.72088,
      "pb": 4.67,
      "pe": 17.52,
      "yield": 1.46,
      "date": "2006-05-22"
  },
  "2006-05-23": {
      "equity": 3199.3,
      "debt": 7.594,
      "gold": 10627.06573,
      "pb": 4.85,
      "pe": 18.19,
      "yield": 1.41,
      "date": "2006-05-23"
  },
  "2006-05-24": {
      "equity": 3115.5,
      "debt": 7.607,
      "gold": 10772.01714,
      "pb": 4.63,
      "pe": 17.71,
      "yield": 1.46,
      "date": "2006-05-24"
  },
  "2006-05-25": {
      "equity": 3177.7,
      "debt": 7.607,
      "gold": 10421.66881,
      "pb": 4.73,
      "pe": 18.39,
      "yield": 1.43,
      "date": "2006-05-25"
  },
  "2006-05-26": {
      "equity": 3209.6,
      "debt": 7.611,
      "gold": 10553.22669,
      "pb": 4.77,
      "pe": 18.28,
      "yield": 1.42,
      "date": "2006-05-26"
  },
  "2006-05-27": {
      "equity": 3209.6,
      "debt": 7.611,
      "gold": 10553.22669,
      "pb": 4.77,
      "pe": 18.28,
      "yield": 1.42,
      "date": "2006-05-27"
  },
  "2006-05-28": {
      "equity": 3209.6,
      "debt": 7.611,
      "gold": 10553.22669,
      "pb": 4.77,
      "pe": 18.28,
      "yield": 1.42,
      "date": "2006-05-28"
  },
  "2006-05-29": {
      "equity": 3214.9,
      "debt": 7.644,
      "gold": 10553.22669,
      "pb": 4.78,
      "pe": 18.27,
      "yield": 1.41,
      "date": "2006-05-29"
  },
  "2006-05-30": {
      "equity": 3185.3,
      "debt": 7.655,
      "gold": 10651.30249,
      "pb": 4.74,
      "pe": 18.11,
      "yield": 1.43,
      "date": "2006-05-30"
  },
  "2006-05-31": {
      "equity": 3071,
      "debt": 7.659,
      "gold": 10697.18337,
      "pb": 4.57,
      "pe": 17.46,
      "yield": 1.48,
      "date": "2006-05-31"
  },
  "2006-06-01": {
      "equity": 2962.2,
      "debt": 7.676,
      "gold": 10353.87573,
      "pb": 4.41,
      "pe": 16.78,
      "yield": 1.53,
      "date": "2006-06-01"
  },
  "2006-06-02": {
      "equity": 3091.3,
      "debt": 7.671,
      "gold": 10213.68984,
      "pb": 4.6,
      "pe": 17.51,
      "yield": 1.47,
      "date": "2006-06-02"
  },
  "2006-06-03": {
      "equity": 3091.3,
      "debt": 7.671,
      "gold": 10213.68984,
      "pb": 4.6,
      "pe": 17.51,
      "yield": 1.47,
      "date": "2006-06-03"
  },
  "2006-06-04": {
      "equity": 3091.3,
      "debt": 7.671,
      "gold": 10213.68984,
      "pb": 4.6,
      "pe": 17.51,
      "yield": 1.47,
      "date": "2006-06-04"
  },
  "2006-06-05": {
      "equity": 3016.6,
      "debt": 7.652,
      "gold": 10405.39339,
      "pb": 4.49,
      "pe": 17.09,
      "yield": 1.51,
      "date": "2006-06-05"
  },
  "2006-06-06": {
      "equity": 2937.3,
      "debt": 7.64,
      "gold": 10281.37357,
      "pb": 4.35,
      "pe": 16.64,
      "yield": 1.62,
      "date": "2006-06-06"
  },
  "2006-06-07": {
      "equity": 2860.4,
      "debt": 7.668,
      "gold": 10109.23649,
      "pb": 4.23,
      "pe": 16.21,
      "yield": 1.66,
      "date": "2006-06-07"
  },
  "2006-06-08": {
      "equity": 2724.3,
      "debt": 7.673,
      "gold": 10040.09595,
      "pb": 4.03,
      "pe": 15.44,
      "yield": 1.74,
      "date": "2006-06-08"
  },
  "2006-06-09": {
      "equity": 2866.3,
      "debt": 7.791,
      "gold": 9925.751777,
      "pb": 4.24,
      "pe": 16.24,
      "yield": 1.65,
      "date": "2006-06-09"
  },
  "2006-06-10": {
      "equity": 2866.3,
      "debt": 7.791,
      "gold": 9925.751777,
      "pb": 4.24,
      "pe": 16.24,
      "yield": 1.65,
      "date": "2006-06-10"
  },
  "2006-06-11": {
      "equity": 2866.3,
      "debt": 7.791,
      "gold": 9925.751777,
      "pb": 4.24,
      "pe": 16.24,
      "yield": 1.65,
      "date": "2006-06-11"
  },
  "2006-06-12": {
      "equity": 2776.8,
      "debt": 7.803,
      "gold": 9843.581016,
      "pb": 4.11,
      "pe": 15.73,
      "yield": 1.71,
      "date": "2006-06-12"
  },
  "2006-06-13": {
      "equity": 2663.3,
      "debt": 7.812,
      "gold": 9593.343798,
      "pb": 3.94,
      "pe": 15.09,
      "yield": 1.78,
      "date": "2006-06-13"
  },
  "2006-06-14": {
      "equity": 2632.8,
      "debt": 7.794,
      "gold": 9200.716062,
      "pb": 3.9,
      "pe": 14.92,
      "yield": 1.8,
      "date": "2006-06-14"
  },
  "2006-06-15": {
      "equity": 2798.8,
      "debt": 7.8,
      "gold": 9314.763929,
      "pb": 4.14,
      "pe": 15.91,
      "yield": 1.69,
      "date": "2006-06-15"
  },
  "2006-06-16": {
      "equity": 2890.3,
      "debt": 7.803,
      "gold": 9402.620858,
      "pb": 4.28,
      "pe": 16.43,
      "yield": 1.64,
      "date": "2006-06-16"
  },
  "2006-06-17": {
      "equity": 2890.3,
      "debt": 7.803,
      "gold": 9402.620858,
      "pb": 4.28,
      "pe": 16.43,
      "yield": 1.64,
      "date": "2006-06-17"
  },
  "2006-06-18": {
      "equity": 2890.3,
      "debt": 7.803,
      "gold": 9402.620858,
      "pb": 4.28,
      "pe": 16.43,
      "yield": 1.64,
      "date": "2006-06-18"
  },
  "2006-06-19": {
      "equity": 2916.9,
      "debt": 7.907,
      "gold": 9229.711282,
      "pb": 4.2,
      "pe": 16.58,
      "yield": 1.65,
      "date": "2006-06-19"
  },
  "2006-06-20": {
      "equity": 2861.3,
      "debt": 7.913,
      "gold": 9255.500097,
      "pb": 4.12,
      "pe": 16.27,
      "yield": 1.68,
      "date": "2006-06-20"
  },
  "2006-06-21": {
      "equity": 2923.4,
      "debt": 7.913,
      "gold": 9347.191308,
      "pb": 4.21,
      "pe": 16.62,
      "yield": 1.65,
      "date": "2006-06-21"
  },
  "2006-06-22": {
      "equity": 2994.7,
      "debt": 7.993,
      "gold": 9674.63271,
      "pb": 4.31,
      "pe": 17.03,
      "yield": 1.61,
      "date": "2006-06-22"
  },
  "2006-06-23": {
      "equity": 3042.7,
      "debt": 8.089,
      "gold": 9477.948465,
      "pb": 4.38,
      "pe": 17.3,
      "yield": 1.58,
      "date": "2006-06-23"
  },
  "2006-06-24": {
      "equity": 3042.7,
      "debt": 8.089,
      "gold": 9477.948465,
      "pb": 4.38,
      "pe": 17.3,
      "yield": 1.58,
      "date": "2006-06-24"
  },
  "2006-06-25": {
      "equity": 3050.3,
      "debt": 8.089,
      "gold": 9477.948465,
      "pb": 4.39,
      "pe": 17.34,
      "yield": 1.58,
      "date": "2006-06-25"
  },
  "2006-06-26": {
      "equity": 2943.2,
      "debt": 8.123,
      "gold": 9593.163901,
      "pb": 4.24,
      "pe": 16.73,
      "yield": 1.64,
      "date": "2006-06-26"
  },
  "2006-06-27": {
      "equity": 2982.4,
      "debt": 8.135,
      "gold": 9610.670382,
      "pb": 4.44,
      "pe": 17.36,
      "yield": 1.57,
      "date": "2006-06-27"
  },
  "2006-06-28": {
      "equity": 2981.1,
      "debt": 8.12,
      "gold": 9556.344909,
      "pb": 4.44,
      "pe": 17.57,
      "yield": 1.57,
      "date": "2006-06-28"
  },
  "2006-06-29": {
      "equity": 2997.9,
      "debt": 8.128,
      "gold": 9501.059983,
      "pb": 4.46,
      "pe": 17.67,
      "yield": 1.56,
      "date": "2006-06-29"
  },
  "2006-06-30": {
      "equity": 3128.2,
      "debt": 8.151,
      "gold": 9750.584666,
      "pb": 4.66,
      "pe": 18.44,
      "yield": 1.49,
      "date": "2006-06-30"
  },
  "2006-07-01": {
      "equity": 3128.2,
      "debt": 8.151,
      "gold": 9750.584666,
      "pb": 4.66,
      "pe": 18.44,
      "yield": 1.49,
      "date": "2006-07-01"
  },
  "2006-07-02": {
      "equity": 3128.2,
      "debt": 8.151,
      "gold": 9750.584666,
      "pb": 4.66,
      "pe": 18.44,
      "yield": 1.49,
      "date": "2006-07-02"
  },
  "2006-07-03": {
      "equity": 3150.9,
      "debt": 8.151,
      "gold": 10067.32747,
      "pb": 4.69,
      "pe": 18.57,
      "yield": 1.48,
      "date": "2006-07-03"
  },
  "2006-07-04": {
      "equity": 3138.6,
      "debt": 8.159,
      "gold": 10077.48285,
      "pb": 4.67,
      "pe": 18.5,
      "yield": 1.49,
      "date": "2006-07-04"
  },
  "2006-07-05": {
      "equity": 3197.1,
      "debt": 8.159,
      "gold": 10169.99594,
      "pb": 4.76,
      "pe": 18.84,
      "yield": 1.46,
      "date": "2006-07-05"
  },
  "2006-07-06": {
      "equity": 3156.4,
      "debt": 8.182,
      "gold": 10155.09268,
      "pb": 4.7,
      "pe": 18.6,
      "yield": 1.48,
      "date": "2006-07-06"
  },
  "2006-07-07": {
      "equity": 3075.8,
      "debt": 8.213,
      "gold": 10215.25953,
      "pb": 4.58,
      "pe": 18.13,
      "yield": 1.52,
      "date": "2006-07-07"
  },
  "2006-07-08": {
      "equity": 3075.8,
      "debt": 8.213,
      "gold": 10215.25953,
      "pb": 4.58,
      "pe": 18.13,
      "yield": 1.52,
      "date": "2006-07-08"
  },
  "2006-07-09": {
      "equity": 3075.8,
      "debt": 8.213,
      "gold": 10215.25953,
      "pb": 4.58,
      "pe": 18.13,
      "yield": 1.52,
      "date": "2006-07-09"
  },
  "2006-07-10": {
      "equity": 3142,
      "debt": 8.292,
      "gold": 10165.09991,
      "pb": 4.68,
      "pe": 18.52,
      "yield": 1.49,
      "date": "2006-07-10"
  },
  "2006-07-11": {
      "equity": 3116.1,
      "debt": 8.292,
      "gold": 10234.41683,
      "pb": 4.64,
      "pe": 18.36,
      "yield": 1.5,
      "date": "2006-07-11"
  },
  "2006-07-12": {
      "equity": 3195.9,
      "debt": 8.376,
      "gold": 10471.1935,
      "pb": 4.76,
      "pe": 18.76,
      "yield": 1.46,
      "date": "2006-07-12"
  },
  "2006-07-13": {
      "equity": 3169.3,
      "debt": 8.355,
      "gold": 10603.27343,
      "pb": 4.72,
      "pe": 18.61,
      "yield": 1.47,
      "date": "2006-07-13"
  },
  "2006-07-14": {
      "equity": 3123.3,
      "debt": 8.344,
      "gold": 10801.58733,
      "pb": 4.47,
      "pe": 18.33,
      "yield": 1.54,
      "date": "2006-07-14"
  },
  "2006-07-15": {
      "equity": 3123.3,
      "debt": 8.344,
      "gold": 10801.58733,
      "pb": 4.47,
      "pe": 18.33,
      "yield": 1.54,
      "date": "2006-07-15"
  },
  "2006-07-16": {
      "equity": 3123.3,
      "debt": 8.344,
      "gold": 10801.58733,
      "pb": 4.47,
      "pe": 18.33,
      "yield": 1.54,
      "date": "2006-07-16"
  },
  "2006-07-17": {
      "equity": 3007.5,
      "debt": 8.371,
      "gold": 11074.61507,
      "pb": 4.3,
      "pe": 17.63,
      "yield": 1.59,
      "date": "2006-07-17"
  },
  "2006-07-18": {
      "equity": 2993.6,
      "debt": 8.373,
      "gold": 10668.52325,
      "pb": 4.28,
      "pe": 17.52,
      "yield": 1.59,
      "date": "2006-07-18"
  },
  "2006-07-19": {
      "equity": 2932.7,
      "debt": 8.34,
      "gold": 10377.20947,
      "pb": 4.19,
      "pe": 17,
      "yield": 1.63,
      "date": "2006-07-19"
  },
  "2006-07-20": {
      "equity": 3023,
      "debt": 8.302,
      "gold": 10612.00727,
      "pb": 4.32,
      "pe": 17.43,
      "yield": 1.58,
      "date": "2006-07-20"
  },
  "2006-07-21": {
      "equity": 2945,
      "debt": 8.242,
      "gold": 10339.50158,
      "pb": 4.21,
      "pe": 16.93,
      "yield": 1.62,
      "date": "2006-07-21"
  },
  "2006-07-22": {
      "equity": 2945,
      "debt": 8.242,
      "gold": 10339.50158,
      "pb": 4.21,
      "pe": 16.93,
      "yield": 1.62,
      "date": "2006-07-22"
  },
  "2006-07-23": {
      "equity": 2945,
      "debt": 8.242,
      "gold": 10339.50158,
      "pb": 4.21,
      "pe": 16.93,
      "yield": 1.62,
      "date": "2006-07-23"
  },
  "2006-07-24": {
      "equity": 2985.8,
      "debt": 8.219,
      "gold": 10198.50086,
      "pb": 4.27,
      "pe": 17.13,
      "yield": 1.6,
      "date": "2006-07-24"
  },
  "2006-07-25": {
      "equity": 3040.5,
      "debt": 8.226,
      "gold": 10219.66172,
      "pb": 4.35,
      "pe": 17.44,
      "yield": 1.57,
      "date": "2006-07-25"
  },
  "2006-07-26": {
      "equity": 3110.1,
      "debt": 8.239,
      "gold": 10197.95764,
      "pb": 4.45,
      "pe": 17.58,
      "yield": 1.53,
      "date": "2006-07-26"
  },
  "2006-07-27": {
      "equity": 3156.1,
      "debt": 8.221,
      "gold": 10427.01282,
      "pb": 4.3,
      "pe": 17.83,
      "yield": 1.57,
      "date": "2006-07-27"
  },
  "2006-07-28": {
      "equity": 3130.8,
      "debt": 8.25,
      "gold": 10395.21332,
      "pb": 4.27,
      "pe": 17.57,
      "yield": 1.59,
      "date": "2006-07-28"
  },
  "2006-07-29": {
      "equity": 3130.8,
      "debt": 8.25,
      "gold": 10395.21332,
      "pb": 4.27,
      "pe": 17.57,
      "yield": 1.59,
      "date": "2006-07-29"
  },
  "2006-07-30": {
      "equity": 3130.8,
      "debt": 8.25,
      "gold": 10395.21332,
      "pb": 4.27,
      "pe": 17.57,
      "yield": 1.59,
      "date": "2006-07-30"
  },
  "2006-07-31": {
      "equity": 3143.2,
      "debt": 8.238,
      "gold": 10462.375,
      "pb": 4.29,
      "pe": 17.64,
      "yield": 1.58,
      "date": "2006-07-31"
  },
  "2006-08-01": {
      "equity": 3147.8,
      "debt": 8.29,
      "gold": 10444.46287,
      "pb": 4.29,
      "pe": 17.67,
      "yield": 1.58,
      "date": "2006-08-01"
  },
  "2006-08-02": {
      "equity": 3182.1,
      "debt": 8.29,
      "gold": 10679.16542,
      "pb": 4.34,
      "pe": 17.86,
      "yield": 1.56,
      "date": "2006-08-02"
  },
  "2006-08-03": {
      "equity": 3190,
      "debt": 8.29,
      "gold": 10614.5999,
      "pb": 4.35,
      "pe": 17.9,
      "yield": 1.56,
      "date": "2006-08-03"
  },
  "2006-08-04": {
      "equity": 3176.7,
      "debt": 8.287,
      "gold": 10583.28718,
      "pb": 4.33,
      "pe": 17.83,
      "yield": 1.56,
      "date": "2006-08-04"
  },
  "2006-08-05": {
      "equity": 3176.7,
      "debt": 8.287,
      "gold": 10583.28718,
      "pb": 4.33,
      "pe": 17.83,
      "yield": 1.56,
      "date": "2006-08-05"
  },
  "2006-08-06": {
      "equity": 3176.7,
      "debt": 8.287,
      "gold": 10583.28718,
      "pb": 4.33,
      "pe": 17.83,
      "yield": 1.56,
      "date": "2006-08-06"
  },
  "2006-08-07": {
      "equity": 3151.1,
      "debt": 8.255,
      "gold": 10638.14529,
      "pb": 4.3,
      "pe": 17.69,
      "yield": 1.58,
      "date": "2006-08-07"
  },
  "2006-08-08": {
      "equity": 3212.4,
      "debt": 8.263,
      "gold": 10596.3562,
      "pb": 4.38,
      "pe": 18.03,
      "yield": 1.55,
      "date": "2006-08-08"
  },
  "2006-08-09": {
      "equity": 3254.6,
      "debt": 8.12,
      "gold": 10497.83241,
      "pb": 4.44,
      "pe": 18.27,
      "yield": 1.53,
      "date": "2006-08-09"
  },
  "2006-08-10": {
      "equity": 3260.1,
      "debt": 8.087,
      "gold": 10649.68694,
      "pb": 4.45,
      "pe": 18.3,
      "yield": 1.52,
      "date": "2006-08-10"
  },
  "2006-08-11": {
      "equity": 3274.3,
      "debt": 8.121,
      "gold": 10527.49078,
      "pb": 4.46,
      "pe": 18.38,
      "yield": 1.52,
      "date": "2006-08-11"
  },
  "2006-08-12": {
      "equity": 3274.3,
      "debt": 8.121,
      "gold": 10527.49078,
      "pb": 4.46,
      "pe": 18.38,
      "yield": 1.52,
      "date": "2006-08-12"
  },
  "2006-08-13": {
      "equity": 3274.3,
      "debt": 8.121,
      "gold": 10527.49078,
      "pb": 4.46,
      "pe": 18.38,
      "yield": 1.52,
      "date": "2006-08-13"
  },
  "2006-08-14": {
      "equity": 3313.1,
      "debt": 8.139,
      "gold": 10327.0287,
      "pb": 4.52,
      "pe": 18.6,
      "yield": 1.5,
      "date": "2006-08-14"
  },
  "2006-08-15": {
      "equity": 3313.1,
      "debt": 8.139,
      "gold": 10229.77478,
      "pb": 4.52,
      "pe": 18.6,
      "yield": 1.5,
      "date": "2006-08-15"
  },
  "2006-08-16": {
      "equity": 3356,
      "debt": 8.093,
      "gold": 10285.85689,
      "pb": 4.58,
      "pe": 18.84,
      "yield": 1.48,
      "date": "2006-08-16"
  },
  "2006-08-17": {
      "equity": 3353.9,
      "debt": 8.027,
      "gold": 10333.57908,
      "pb": 4.54,
      "pe": 18.83,
      "yield": 1.5,
      "date": "2006-08-17"
  },
  "2006-08-18": {
      "equity": 3356.7,
      "debt": 8.019,
      "gold": 10063.86356,
      "pb": 4.54,
      "pe": 18.84,
      "yield": 1.5,
      "date": "2006-08-18"
  },
  "2006-08-19": {
      "equity": 3356.7,
      "debt": 8.019,
      "gold": 10063.86356,
      "pb": 4.54,
      "pe": 18.84,
      "yield": 1.5,
      "date": "2006-08-19"
  },
  "2006-08-20": {
      "equity": 3356.7,
      "debt": 8.019,
      "gold": 10063.86356,
      "pb": 4.54,
      "pe": 18.84,
      "yield": 1.5,
      "date": "2006-08-20"
  },
  "2006-08-21": {
      "equity": 3366,
      "debt": 8.035,
      "gold": 10186.15849,
      "pb": 4.56,
      "pe": 18.88,
      "yield": 1.49,
      "date": "2006-08-21"
  },
  "2006-08-22": {
      "equity": 3364.6,
      "debt": 8.027,
      "gold": 10276.10716,
      "pb": 4.55,
      "pe": 18.88,
      "yield": 1.49,
      "date": "2006-08-22"
  },
  "2006-08-23": {
      "equity": 3335.8,
      "debt": 8,
      "gold": 10233.49971,
      "pb": 4.52,
      "pe": 18.71,
      "yield": 1.51,
      "date": "2006-08-23"
  },
  "2006-08-24": {
      "equity": 3370.4,
      "debt": 7.929,
      "gold": 10234.59673,
      "pb": 4.56,
      "pe": 18.91,
      "yield": 1.49,
      "date": "2006-08-24"
  },
  "2006-08-25": {
      "equity": 3385.9,
      "debt": 7.98,
      "gold": 10216.01086,
      "pb": 4.58,
      "pe": 19,
      "yield": 1.48,
      "date": "2006-08-25"
  },
  "2006-08-26": {
      "equity": 3385.9,
      "debt": 7.98,
      "gold": 10216.01086,
      "pb": 4.58,
      "pe": 19,
      "yield": 1.48,
      "date": "2006-08-26"
  },
  "2006-08-27": {
      "equity": 3385.9,
      "debt": 7.98,
      "gold": 10216.01086,
      "pb": 4.58,
      "pe": 19,
      "yield": 1.48,
      "date": "2006-08-27"
  },
  "2006-08-28": {
      "equity": 3401.1,
      "debt": 7.95,
      "gold": 10216.01086,
      "pb": 4.6,
      "pe": 19.08,
      "yield": 1.48,
      "date": "2006-08-28"
  },
  "2006-08-29": {
      "equity": 3425.7,
      "debt": 7.946,
      "gold": 10118.65112,
      "pb": 4.64,
      "pe": 19.22,
      "yield": 1.47,
      "date": "2006-08-29"
  },
  "2006-08-30": {
      "equity": 3430.3,
      "debt": 7.933,
      "gold": 10086.09323,
      "pb": 4.58,
      "pe": 19.25,
      "yield": 1.47,
      "date": "2006-08-30"
  },
  "2006-08-31": {
      "equity": 3413.9,
      "debt": 7.891,
      "gold": 10207.25233,
      "pb": 4.55,
      "pe": 19.15,
      "yield": 1.47,
      "date": "2006-08-31"
  },
  "2006-09-01": {
      "equity": 3435.4,
      "debt": 7.877,
      "gold": 10240.33934,
      "pb": 4.5,
      "pe": 19.68,
      "yield": 1.43,
      "date": "2006-09-01"
  },
  "2006-09-02": {
      "equity": 3435.4,
      "debt": 7.877,
      "gold": 10240.33934,
      "pb": 4.5,
      "pe": 19.68,
      "yield": 1.43,
      "date": "2006-09-02"
  },
  "2006-09-03": {
      "equity": 3435.4,
      "debt": 7.877,
      "gold": 10240.33934,
      "pb": 4.5,
      "pe": 19.68,
      "yield": 1.43,
      "date": "2006-09-03"
  },
  "2006-09-04": {
      "equity": 3476.8,
      "debt": 7.723,
      "gold": 10279.39117,
      "pb": 4.56,
      "pe": 19.92,
      "yield": 1.41,
      "date": "2006-09-04"
  },
  "2006-09-05": {
      "equity": 3473.7,
      "debt": 7.754,
      "gold": 10281.65576,
      "pb": 4.55,
      "pe": 19.9,
      "yield": 1.42,
      "date": "2006-09-05"
  },
  "2006-09-06": {
      "equity": 3477.2,
      "debt": 7.769,
      "gold": 10365.368,
      "pb": 4.56,
      "pe": 19.92,
      "yield": 1.41,
      "date": "2006-09-06"
  },
  "2006-09-07": {
      "equity": 3454.5,
      "debt": 7.78,
      "gold": 10336.17171,
      "pb": 4.53,
      "pe": 19.79,
      "yield": 1.42,
      "date": "2006-09-07"
  },
  "2006-09-08": {
      "equity": 3471.4,
      "debt": 7.705,
      "gold": 9971.830191,
      "pb": 4.55,
      "pe": 19.89,
      "yield": 1.42,
      "date": "2006-09-08"
  },
  "2006-09-09": {
      "equity": 3471.4,
      "debt": 7.705,
      "gold": 9971.830191,
      "pb": 4.55,
      "pe": 19.89,
      "yield": 1.42,
      "date": "2006-09-09"
  },
  "2006-09-10": {
      "equity": 3471.4,
      "debt": 7.705,
      "gold": 9971.830191,
      "pb": 4.55,
      "pe": 19.89,
      "yield": 1.42,
      "date": "2006-09-10"
  },
  "2006-09-11": {
      "equity": 3366.1,
      "debt": 7.707,
      "gold": 9752.806928,
      "pb": 4.41,
      "pe": 19.29,
      "yield": 1.46,
      "date": "2006-09-11"
  },
  "2006-09-12": {
      "equity": 3389.9,
      "debt": 7.771,
      "gold": 9726.95462,
      "pb": 4.44,
      "pe": 19.42,
      "yield": 1.45,
      "date": "2006-09-12"
  },
  "2006-09-13": {
      "equity": 3454.5,
      "debt": 7.738,
      "gold": 9474.925484,
      "pb": 4.5,
      "pe": 19.81,
      "yield": 1.4,
      "date": "2006-09-13"
  },
  "2006-09-14": {
      "equity": 3471.6,
      "debt": 7.808,
      "gold": 9656.787598,
      "pb": 4.53,
      "pe": 19.91,
      "yield": 1.4,
      "date": "2006-09-14"
  },
  "2006-09-15": {
      "equity": 3478.6,
      "debt": 7.829,
      "gold": 9412.631616,
      "pb": 4.53,
      "pe": 19.95,
      "yield": 1.39,
      "date": "2006-09-15"
  },
  "2006-09-16": {
      "equity": 3478.6,
      "debt": 7.829,
      "gold": 9412.631616,
      "pb": 4.53,
      "pe": 19.95,
      "yield": 1.39,
      "date": "2006-09-16"
  },
  "2006-09-17": {
      "equity": 3478.6,
      "debt": 7.829,
      "gold": 9412.631616,
      "pb": 4.53,
      "pe": 19.95,
      "yield": 1.39,
      "date": "2006-09-17"
  },
  "2006-09-18": {
      "equity": 3492.7,
      "debt": 7.786,
      "gold": 9464.93589,
      "pb": 4.55,
      "pe": 20.03,
      "yield": 1.39,
      "date": "2006-09-18"
  },
  "2006-09-19": {
      "equity": 3457.3,
      "debt": 7.733,
      "gold": 9467.341576,
      "pb": 4.51,
      "pe": 19.83,
      "yield": 1.4,
      "date": "2006-09-19"
  },
  "2006-09-20": {
      "equity": 3502.8,
      "debt": 7.619,
      "gold": 9339.847264,
      "pb": 4.57,
      "pe": 20.09,
      "yield": 1.38,
      "date": "2006-09-20"
  },
  "2006-09-21": {
      "equity": 3553,
      "debt": 7.68,
      "gold": 9391.058043,
      "pb": 4.63,
      "pe": 20.38,
      "yield": 1.36,
      "date": "2006-09-21"
  },
  "2006-09-22": {
      "equity": 3544,
      "debt": 7.619,
      "gold": 9585.975061,
      "pb": 4.62,
      "pe": 20.33,
      "yield": 1.37,
      "date": "2006-09-22"
  },
  "2006-09-23": {
      "equity": 3544,
      "debt": 7.619,
      "gold": 9585.975061,
      "pb": 4.62,
      "pe": 20.33,
      "yield": 1.37,
      "date": "2006-09-23"
  },
  "2006-09-24": {
      "equity": 3544,
      "debt": 7.619,
      "gold": 9585.975061,
      "pb": 4.62,
      "pe": 20.33,
      "yield": 1.37,
      "date": "2006-09-24"
  },
  "2006-09-25": {
      "equity": 3523.4,
      "debt": 7.625,
      "gold": 9550.694016,
      "pb": 4.59,
      "pe": 20.21,
      "yield": 1.37,
      "date": "2006-09-25"
  },
  "2006-09-26": {
      "equity": 3571.7,
      "debt": 7.562,
      "gold": 9547.762747,
      "pb": 4.73,
      "pe": 20.83,
      "yield": 1.33,
      "date": "2006-09-26"
  },
  "2006-09-27": {
      "equity": 3579.3,
      "debt": 7.592,
      "gold": 9607.834353,
      "pb": 4.74,
      "pe": 20.87,
      "yield": 1.33,
      "date": "2006-09-27"
  },
  "2006-09-28": {
      "equity": 3571.7,
      "debt": 7.624,
      "gold": 9776.447556,
      "pb": 4.73,
      "pe": 20.83,
      "yield": 1.33,
      "date": "2006-09-28"
  },
  "2006-09-29": {
      "equity": 3588.4,
      "debt": 7.642,
      "gold": 9748.09785,
      "pb": 4.76,
      "pe": 20.92,
      "yield": 1.33,
      "date": "2006-09-29"
  },
  "2006-09-30": {
      "equity": 3588.4,
      "debt": 7.642,
      "gold": 9748.09785,
      "pb": 4.76,
      "pe": 20.92,
      "yield": 1.33,
      "date": "2006-09-30"
  },
  "2006-10-01": {
      "equity": 3588.4,
      "debt": 7.642,
      "gold": 9748.09785,
      "pb": 4.76,
      "pe": 20.92,
      "yield": 1.33,
      "date": "2006-10-01"
  },
  "2006-10-02": {
      "equity": 3588.4,
      "debt": 7.642,
      "gold": 9762.020494,
      "pb": 4.76,
      "pe": 20.92,
      "yield": 1.33,
      "date": "2006-10-02"
  },
  "2006-10-03": {
      "equity": 3569.6,
      "debt": 7.613,
      "gold": 9559.28676,
      "pb": 4.73,
      "pe": 20.82,
      "yield": 1.33,
      "date": "2006-10-03"
  },
  "2006-10-04": {
      "equity": 3515.3,
      "debt": 7.587,
      "gold": 9331.663698,
      "pb": 4.66,
      "pe": 20.5,
      "yield": 1.36,
      "date": "2006-10-04"
  },
  "2006-10-05": {
      "equity": 3564.9,
      "debt": 7.59,
      "gold": 9150.549392,
      "pb": 4.72,
      "pe": 20.79,
      "yield": 1.34,
      "date": "2006-10-05"
  },
  "2006-10-06": {
      "equity": 3569.7,
      "debt": 7.596,
      "gold": 9173.79848,
      "pb": 4.73,
      "pe": 20.82,
      "yield": 1.33,
      "date": "2006-10-06"
  },
  "2006-10-07": {
      "equity": 3569.7,
      "debt": 7.596,
      "gold": 9173.79848,
      "pb": 4.73,
      "pe": 20.82,
      "yield": 1.33,
      "date": "2006-10-07"
  },
  "2006-10-08": {
      "equity": 3569.7,
      "debt": 7.596,
      "gold": 9173.79848,
      "pb": 4.73,
      "pe": 20.82,
      "yield": 1.33,
      "date": "2006-10-08"
  },
  "2006-10-09": {
      "equity": 3567.1,
      "debt": 7.642,
      "gold": 9327.631881,
      "pb": 4.73,
      "pe": 20.82,
      "yield": 1.36,
      "date": "2006-10-09"
  },
  "2006-10-10": {
      "equity": 3571,
      "debt": 7.612,
      "gold": 9327.282668,
      "pb": 4.73,
      "pe": 20.83,
      "yield": 1.36,
      "date": "2006-10-10"
  },
  "2006-10-11": {
      "equity": 3558.5,
      "debt": 7.638,
      "gold": 9242.879063,
      "pb": 4.59,
      "pe": 20.68,
      "yield": 1.39,
      "date": "2006-10-11"
  },
  "2006-10-12": {
      "equity": 3621,
      "debt": 7.615,
      "gold": 9266.364486,
      "pb": 4.67,
      "pe": 21.04,
      "yield": 1.37,
      "date": "2006-10-12"
  },
  "2006-10-13": {
      "equity": 3676,
      "debt": 7.617,
      "gold": 9262.625443,
      "pb": 4.74,
      "pe": 21.36,
      "yield": 1.35,
      "date": "2006-10-13"
  },
  "2006-10-14": {
      "equity": 3676,
      "debt": 7.617,
      "gold": 9262.625443,
      "pb": 4.74,
      "pe": 21.36,
      "yield": 1.35,
      "date": "2006-10-14"
  },
  "2006-10-15": {
      "equity": 3676,
      "debt": 7.617,
      "gold": 9262.625443,
      "pb": 4.74,
      "pe": 21.36,
      "yield": 1.35,
      "date": "2006-10-15"
  },
  "2006-10-16": {
      "equity": 3723.9,
      "debt": 7.677,
      "gold": 9504.379266,
      "pb": 4.81,
      "pe": 21.64,
      "yield": 1.33,
      "date": "2006-10-16"
  },
  "2006-10-17": {
      "equity": 3715,
      "debt": 7.683,
      "gold": 9501.779573,
      "pb": 4.79,
      "pe": 21.47,
      "yield": 1.33,
      "date": "2006-10-17"
  },
  "2006-10-18": {
      "equity": 3710.6,
      "debt": 7.684,
      "gold": 9527.589552,
      "pb": 4.79,
      "pe": 21.33,
      "yield": 1.34,
      "date": "2006-10-18"
  },
  "2006-10-19": {
      "equity": 3677.8,
      "debt": 7.675,
      "gold": 9426.804706,
      "pb": 4.75,
      "pe": 21.06,
      "yield": 1.35,
      "date": "2006-10-19"
  },
  "2006-10-20": {
      "equity": 3676.8,
      "debt": 7.654,
      "gold": 9560.785905,
      "pb": 4.75,
      "pe": 20.91,
      "yield": 1.35,
      "date": "2006-10-20"
  },
  "2006-10-21": {
      "equity": 3683.5,
      "debt": 7.654,
      "gold": 9560.785905,
      "pb": 4.76,
      "pe": 20.94,
      "yield": 1.35,
      "date": "2006-10-21"
  },
  "2006-10-22": {
      "equity": 3683.5,
      "debt": 7.654,
      "gold": 9560.785905,
      "pb": 4.76,
      "pe": 20.94,
      "yield": 1.35,
      "date": "2006-10-22"
  },
  "2006-10-23": {
      "equity": 3657.3,
      "debt": 7.659,
      "gold": 9418.243708,
      "pb": 4.72,
      "pe": 20.79,
      "yield": 1.35,
      "date": "2006-10-23"
  },
  "2006-10-24": {
      "equity": 3657.3,
      "debt": 7.659,
      "gold": 9256.604173,
      "pb": 4.72,
      "pe": 20.79,
      "yield": 1.35,
      "date": "2006-10-24"
  },
  "2006-10-25": {
      "equity": 3657.3,
      "debt": 7.659,
      "gold": 9306.277007,
      "pb": 4.72,
      "pe": 20.79,
      "yield": 1.35,
      "date": "2006-10-25"
  },
  "2006-10-26": {
      "equity": 3677.5,
      "debt": 7.636,
      "gold": 9426.695356,
      "pb": 4.75,
      "pe": 20.79,
      "yield": 1.35,
      "date": "2006-10-26"
  },
  "2006-10-27": {
      "equity": 3739.3,
      "debt": 7.607,
      "gold": 9465.609623,
      "pb": 4.83,
      "pe": 21.04,
      "yield": 1.32,
      "date": "2006-10-27"
  },
  "2006-10-28": {
      "equity": 3739.3,
      "debt": 7.607,
      "gold": 9465.609623,
      "pb": 4.83,
      "pe": 21.04,
      "yield": 1.32,
      "date": "2006-10-28"
  },
  "2006-10-29": {
      "equity": 3739.3,
      "debt": 7.607,
      "gold": 9465.609623,
      "pb": 4.83,
      "pe": 21.04,
      "yield": 1.32,
      "date": "2006-10-29"
  },
  "2006-10-30": {
      "equity": 3769.1,
      "debt": 7.615,
      "gold": 9593.78825,
      "pb": 4.87,
      "pe": 20.48,
      "yield": 1.31,
      "date": "2006-10-30"
  },
  "2006-10-31": {
      "equity": 3744.1,
      "debt": 7.625,
      "gold": 9544.090725,
      "pb": 4.84,
      "pe": 20.37,
      "yield": 1.32,
      "date": "2006-10-31"
  },
  "2006-11-01": {
      "equity": 3767,
      "debt": 7.613,
      "gold": 9674.262333,
      "pb": 4.87,
      "pe": 20.16,
      "yield": 1.32,
      "date": "2006-11-01"
  },
  "2006-11-02": {
      "equity": 3791.2,
      "debt": 7.6,
      "gold": 9751.177269,
      "pb": 4.9,
      "pe": 20.29,
      "yield": 1.31,
      "date": "2006-11-02"
  },
  "2006-11-03": {
      "equity": 3805.3,
      "debt": 7.642,
      "gold": 9892.506041,
      "pb": 4.92,
      "pe": 20.37,
      "yield": 1.3,
      "date": "2006-11-03"
  },
  "2006-11-04": {
      "equity": 3805.3,
      "debt": 7.642,
      "gold": 9892.506041,
      "pb": 4.92,
      "pe": 20.37,
      "yield": 1.3,
      "date": "2006-11-04"
  },
  "2006-11-05": {
      "equity": 3805.3,
      "debt": 7.642,
      "gold": 9892.506041,
      "pb": 4.92,
      "pe": 20.37,
      "yield": 1.3,
      "date": "2006-11-05"
  },
  "2006-11-06": {
      "equity": 3809.2,
      "debt": 7.661,
      "gold": 9911.47992,
      "pb": 4.92,
      "pe": 20.39,
      "yield": 1.3,
      "date": "2006-11-06"
  },
  "2006-11-07": {
      "equity": 3798.7,
      "debt": 7.617,
      "gold": 9872.565654,
      "pb": 4.91,
      "pe": 20.34,
      "yield": 1.3,
      "date": "2006-11-07"
  },
  "2006-11-08": {
      "equity": 3777.3,
      "debt": 7.589,
      "gold": 9860.346743,
      "pb": 4.88,
      "pe": 20.22,
      "yield": 1.31,
      "date": "2006-11-08"
  },
  "2006-11-09": {
      "equity": 3796.4,
      "debt": 7.611,
      "gold": 9712.428791,
      "pb": 4.87,
      "pe": 20.32,
      "yield": 1.27,
      "date": "2006-11-09"
  },
  "2006-11-10": {
      "equity": 3834.7,
      "debt": 7.604,
      "gold": 9971.216424,
      "pb": 4.92,
      "pe": 20.53,
      "yield": 1.26,
      "date": "2006-11-10"
  },
  "2006-11-11": {
      "equity": 3834.7,
      "debt": 7.604,
      "gold": 9971.216424,
      "pb": 4.92,
      "pe": 20.53,
      "yield": 1.26,
      "date": "2006-11-11"
  },
  "2006-11-12": {
      "equity": 3834.7,
      "debt": 7.604,
      "gold": 9971.216424,
      "pb": 4.92,
      "pe": 20.53,
      "yield": 1.26,
      "date": "2006-11-12"
  },
  "2006-11-13": {
      "equity": 3858.7,
      "debt": 7.584,
      "gold": 9971.932486,
      "pb": 4.95,
      "pe": 20.66,
      "yield": 1.25,
      "date": "2006-11-13"
  },
  "2006-11-14": {
      "equity": 3865.9,
      "debt": 7.56,
      "gold": 9933.893014,
      "pb": 4.96,
      "pe": 20.7,
      "yield": 1.25,
      "date": "2006-11-14"
  },
  "2006-11-15": {
      "equity": 3876.3,
      "debt": 7.534,
      "gold": 9920.894548,
      "pb": 4.98,
      "pe": 20.78,
      "yield": 1.25,
      "date": "2006-11-15"
  },
  "2006-11-16": {
      "equity": 3876.8,
      "debt": 7.539,
      "gold": 9900.756627,
      "pb": 4.98,
      "pe": 20.78,
      "yield": 1.25,
      "date": "2006-11-16"
  },
  "2006-11-17": {
      "equity": 3852.8,
      "debt": 7.53,
      "gold": 9794.038696,
      "pb": 4.95,
      "pe": 20.65,
      "yield": 1.25,
      "date": "2006-11-17"
  },
  "2006-11-18": {
      "equity": 3852.8,
      "debt": 7.53,
      "gold": 9794.038696,
      "pb": 4.95,
      "pe": 20.65,
      "yield": 1.25,
      "date": "2006-11-18"
  },
  "2006-11-19": {
      "equity": 3852.8,
      "debt": 7.53,
      "gold": 9794.038696,
      "pb": 4.95,
      "pe": 20.65,
      "yield": 1.25,
      "date": "2006-11-19"
  },
  "2006-11-20": {
      "equity": 3856.1,
      "debt": 7.483,
      "gold": 9900.534401,
      "pb": 4.95,
      "pe": 20.67,
      "yield": 1.25,
      "date": "2006-11-20"
  },
  "2006-11-21": {
      "equity": 3918.2,
      "debt": 7.47,
      "gold": 9891.049225,
      "pb": 5.03,
      "pe": 21,
      "yield": 1.23,
      "date": "2006-11-21"
  },
  "2006-11-22": {
      "equity": 3954.7,
      "debt": 7.467,
      "gold": 9920.464206,
      "pb": 5.08,
      "pe": 21.2,
      "yield": 1.22,
      "date": "2006-11-22"
  },
  "2006-11-23": {
      "equity": 3945.4,
      "debt": 7.456,
      "gold": 9943.434628,
      "pb": 5.07,
      "pe": 21.14,
      "yield": 1.22,
      "date": "2006-11-23"
  },
  "2006-11-24": {
      "equity": 3950.8,
      "debt": 7.431,
      "gold": 10028.67423,
      "pb": 5.08,
      "pe": 21.17,
      "yield": 1.22,
      "date": "2006-11-24"
  },
  "2006-11-25": {
      "equity": 3950.8,
      "debt": 7.431,
      "gold": 10028.67423,
      "pb": 5.08,
      "pe": 21.17,
      "yield": 1.22,
      "date": "2006-11-25"
  },
  "2006-11-26": {
      "equity": 3950.8,
      "debt": 7.431,
      "gold": 10028.67423,
      "pb": 5.08,
      "pe": 21.17,
      "yield": 1.22,
      "date": "2006-11-26"
  },
  "2006-11-27": {
      "equity": 3968.9,
      "debt": 7.383,
      "gold": 10041.64447,
      "pb": 5.1,
      "pe": 21.25,
      "yield": 1.22,
      "date": "2006-11-27"
  },
  "2006-11-28": {
      "equity": 3921.7,
      "debt": 7.377,
      "gold": 10054.58297,
      "pb": 5.04,
      "pe": 21,
      "yield": 1.23,
      "date": "2006-11-28"
  },
  "2006-11-29": {
      "equity": 3928.2,
      "debt": 7.407,
      "gold": 10061.33794,
      "pb": 5.05,
      "pe": 21.03,
      "yield": 1.23,
      "date": "2006-11-29"
  },
  "2006-11-30": {
      "equity": 3954.5,
      "debt": 7.424,
      "gold": 10074.3999,
      "pb": 5.08,
      "pe": 21.18,
      "yield": 1.22,
      "date": "2006-11-30"
  },
  "2006-12-01": {
      "equity": 3997.6,
      "debt": 7.426,
      "gold": 10151.46652,
      "pb": 5.14,
      "pe": 21.41,
      "yield": 1.21,
      "date": "2006-12-01"
  },
  "2006-12-02": {
      "equity": 3997.6,
      "debt": 7.426,
      "gold": 10151.46652,
      "pb": 5.14,
      "pe": 21.41,
      "yield": 1.21,
      "date": "2006-12-02"
  },
  "2006-12-03": {
      "equity": 3997.6,
      "debt": 7.426,
      "gold": 10151.46652,
      "pb": 5.14,
      "pe": 21.41,
      "yield": 1.21,
      "date": "2006-12-03"
  },
  "2006-12-04": {
      "equity": 4001,
      "debt": 7.381,
      "gold": 10147.82977,
      "pb": 5.11,
      "pe": 21.43,
      "yield": 1.21,
      "date": "2006-12-04"
  },
  "2006-12-05": {
      "equity": 4015.7,
      "debt": 7.379,
      "gold": 10099.08817,
      "pb": 5.13,
      "pe": 21.51,
      "yield": 1.2,
      "date": "2006-12-05"
  },
  "2006-12-06": {
      "equity": 4015.9,
      "debt": 7.39,
      "gold": 10040.5016,
      "pb": 5.13,
      "pe": 21.51,
      "yield": 1.2,
      "date": "2006-12-06"
  },
  "2006-12-07": {
      "equity": 4015.3,
      "debt": 7.393,
      "gold": 9929.159244,
      "pb": 5.13,
      "pe": 21.54,
      "yield": 1.2,
      "date": "2006-12-07"
  },
  "2006-12-08": {
      "equity": 3962,
      "debt": 7.39,
      "gold": 9954.37662,
      "pb": 5.06,
      "pe": 21.23,
      "yield": 1.22,
      "date": "2006-12-08"
  },
  "2006-12-09": {
      "equity": 3962,
      "debt": 7.39,
      "gold": 9954.37662,
      "pb": 5.06,
      "pe": 21.23,
      "yield": 1.22,
      "date": "2006-12-09"
  },
  "2006-12-10": {
      "equity": 3962,
      "debt": 7.39,
      "gold": 9954.37662,
      "pb": 5.06,
      "pe": 21.23,
      "yield": 1.22,
      "date": "2006-12-10"
  },
  "2006-12-11": {
      "equity": 3849.5,
      "debt": 7.538,
      "gold": 9893.444329,
      "pb": 4.92,
      "pe": 20.62,
      "yield": 1.25,
      "date": "2006-12-11"
  },
  "2006-12-12": {
      "equity": 3716.9,
      "debt": 7.644,
      "gold": 9955.889875,
      "pb": 4.75,
      "pe": 19.91,
      "yield": 1.3,
      "date": "2006-12-12"
  },
  "2006-12-13": {
      "equity": 3765.2,
      "debt": 7.622,
      "gold": 9936.602056,
      "pb": 4.81,
      "pe": 20.17,
      "yield": 1.28,
      "date": "2006-12-13"
  },
  "2006-12-14": {
      "equity": 3843,
      "debt": 7.683,
      "gold": 9906.32639,
      "pb": 4.91,
      "pe": 20.59,
      "yield": 1.26,
      "date": "2006-12-14"
  },
  "2006-12-15": {
      "equity": 3888.6,
      "debt": 7.641,
      "gold": 9826.275596,
      "pb": 4.97,
      "pe": 20.83,
      "yield": 1.24,
      "date": "2006-12-15"
  },
  "2006-12-16": {
      "equity": 3888.6,
      "debt": 7.641,
      "gold": 9826.275596,
      "pb": 4.97,
      "pe": 20.83,
      "yield": 1.24,
      "date": "2006-12-16"
  },
  "2006-12-17": {
      "equity": 3888.6,
      "debt": 7.641,
      "gold": 9826.275596,
      "pb": 4.97,
      "pe": 20.83,
      "yield": 1.24,
      "date": "2006-12-17"
  },
  "2006-12-18": {
      "equity": 3928.7,
      "debt": 7.613,
      "gold": 9723.346091,
      "pb": 5.02,
      "pe": 21.05,
      "yield": 1.23,
      "date": "2006-12-18"
  },
  "2006-12-19": {
      "equity": 3832,
      "debt": 7.583,
      "gold": 9722.541844,
      "pb": 4.9,
      "pe": 20.53,
      "yield": 1.26,
      "date": "2006-12-19"
  },
  "2006-12-20": {
      "equity": 3815.5,
      "debt": 7.586,
      "gold": 9833.841867,
      "pb": 4.88,
      "pe": 20.45,
      "yield": 1.26,
      "date": "2006-12-20"
  },
  "2006-12-21": {
      "equity": 3833.5,
      "debt": 7.603,
      "gold": 9805.174694,
      "pb": 4.9,
      "pe": 20.54,
      "yield": 1.26,
      "date": "2006-12-21"
  },
  "2006-12-22": {
      "equity": 3871.1,
      "debt": 7.601,
      "gold": 9745.942609,
      "pb": 4.95,
      "pe": 20.74,
      "yield": 1.25,
      "date": "2006-12-22"
  },
  "2006-12-23": {
      "equity": 3871.1,
      "debt": 7.601,
      "gold": 9745.942609,
      "pb": 4.95,
      "pe": 20.74,
      "yield": 1.25,
      "date": "2006-12-23"
  },
  "2006-12-24": {
      "equity": 3871.1,
      "debt": 7.601,
      "gold": 9745.942609,
      "pb": 4.95,
      "pe": 20.74,
      "yield": 1.25,
      "date": "2006-12-24"
  },
  "2006-12-25": {
      "equity": 3871.1,
      "debt": 7.601,
      "gold": 9745.942609,
      "pb": 4.95,
      "pe": 20.74,
      "yield": 1.25,
      "date": "2006-12-25"
  },
  "2006-12-26": {
      "equity": 3940.5,
      "debt": 7.625,
      "gold": 9745.942609,
      "pb": 5.04,
      "pe": 21.12,
      "yield": 1.22,
      "date": "2006-12-26"
  },
  "2006-12-27": {
      "equity": 3974.2,
      "debt": 7.621,
      "gold": 9825.809979,
      "pb": 5.08,
      "pe": 21.3,
      "yield": 1.21,
      "date": "2006-12-27"
  },
  "2006-12-28": {
      "equity": 3970.5,
      "debt": 7.63,
      "gold": 9826.772959,
      "pb": 5.08,
      "pe": 21.28,
      "yield": 1.22,
      "date": "2006-12-28"
  },
  "2006-12-29": {
      "equity": 3966.4,
      "debt": 7.619,
      "gold": 9924.718249,
      "pb": 5.07,
      "pe": 21.26,
      "yield": 1.22,
      "date": "2006-12-29"
  },
  "2006-12-30": {
      "equity": 3966.4,
      "debt": 7.619,
      "gold": 9924.718249,
      "pb": 5.07,
      "pe": 21.26,
      "yield": 1.22,
      "date": "2006-12-30"
  },
  "2006-12-31": {
      "equity": 3966.4,
      "debt": 7.619,
      "gold": 9924.718249,
      "pb": 5.07,
      "pe": 21.26,
      "yield": 1.22,
      "date": "2006-12-31"
  },
  "2007-01-01": {
      "equity": 3966.4,
      "debt": 7.619,
      "gold": 9924.718249,
      "pb": 5.07,
      "pe": 21.26,
      "yield": 1.22,
      "date": "2007-01-01"
  },
  "2007-01-02": {
      "equity": 4007.4,
      "debt": 7.536,
      "gold": 9998.476164,
      "pb": 5.12,
      "pe": 21.48,
      "yield": 1.2,
      "date": "2007-01-02"
  },
  "2007-01-03": {
      "equity": 4024,
      "debt": 7.54,
      "gold": 9966.941216,
      "pb": 5.14,
      "pe": 21.57,
      "yield": 1.2,
      "date": "2007-01-03"
  },
  "2007-01-04": {
      "equity": 3988.8,
      "debt": 7.584,
      "gold": 9767.085839,
      "pb": 5.1,
      "pe": 21.38,
      "yield": 1.21,
      "date": "2007-01-04"
  },
  "2007-01-05": {
      "equity": 3983.4,
      "debt": 7.555,
      "gold": 9783.65756,
      "pb": 5.09,
      "pe": 21.35,
      "yield": 1.21,
      "date": "2007-01-05"
  },
  "2007-01-06": {
      "equity": 3983.4,
      "debt": 7.555,
      "gold": 9783.65756,
      "pb": 5.09,
      "pe": 21.35,
      "yield": 1.21,
      "date": "2007-01-06"
  },
  "2007-01-07": {
      "equity": 3983.4,
      "debt": 7.555,
      "gold": 9783.65756,
      "pb": 5.09,
      "pe": 21.35,
      "yield": 1.21,
      "date": "2007-01-07"
  },
  "2007-01-08": {
      "equity": 3933.4,
      "debt": 7.49,
      "gold": 9547.367678,
      "pb": 5.03,
      "pe": 21.08,
      "yield": 1.23,
      "date": "2007-01-08"
  },
  "2007-01-09": {
      "equity": 3911.4,
      "debt": 7.51,
      "gold": 9582.232491,
      "pb": 5,
      "pe": 20.97,
      "yield": 1.23,
      "date": "2007-01-09"
  },
  "2007-01-10": {
      "equity": 3850.3,
      "debt": 7.537,
      "gold": 9599.82363,
      "pb": 4.92,
      "pe": 20.64,
      "yield": 1.25,
      "date": "2007-01-10"
  },
  "2007-01-11": {
      "equity": 3942.2,
      "debt": 7.537,
      "gold": 9645.214201,
      "pb": 5.04,
      "pe": 21.04,
      "yield": 1.22,
      "date": "2007-01-11"
  },
  "2007-01-12": {
      "equity": 4052.4,
      "debt": 7.927,
      "gold": 9624.317889,
      "pb": 5.16,
      "pe": 21.63,
      "yield": 1.18,
      "date": "2007-01-12"
  },
  "2007-01-13": {
      "equity": 4052.4,
      "debt": 7.927,
      "gold": 9624.317889,
      "pb": 5.16,
      "pe": 21.63,
      "yield": 1.18,
      "date": "2007-01-13"
  },
  "2007-01-14": {
      "equity": 4052.4,
      "debt": 7.927,
      "gold": 9624.317889,
      "pb": 5.16,
      "pe": 21.63,
      "yield": 1.18,
      "date": "2007-01-14"
  },
  "2007-01-15": {
      "equity": 4078.4,
      "debt": 7.792,
      "gold": 9815.054939,
      "pb": 5.19,
      "pe": 21.77,
      "yield": 1.17,
      "date": "2007-01-15"
  },
  "2007-01-16": {
      "equity": 4080.5,
      "debt": 7.766,
      "gold": 9769.24108,
      "pb": 5.19,
      "pe": 21.67,
      "yield": 1.17,
      "date": "2007-01-16"
  },
  "2007-01-17": {
      "equity": 4076.4,
      "debt": 7.81,
      "gold": 9709.462248,
      "pb": 5.19,
      "pe": 21.59,
      "yield": 1.17,
      "date": "2007-01-17"
  },
  "2007-01-18": {
      "equity": 4109,
      "debt": 7.81,
      "gold": 9890.604773,
      "pb": 5.23,
      "pe": 21.74,
      "yield": 1.16,
      "date": "2007-01-18"
  },
  "2007-01-19": {
      "equity": 4090.1,
      "debt": 7.807,
      "gold": 9823.732341,
      "pb": 5.21,
      "pe": 21.43,
      "yield": 1.17,
      "date": "2007-01-19"
  },
  "2007-01-20": {
      "equity": 4090.1,
      "debt": 7.807,
      "gold": 9823.732341,
      "pb": 5.21,
      "pe": 21.43,
      "yield": 1.17,
      "date": "2007-01-20"
  },
  "2007-01-21": {
      "equity": 4090.1,
      "debt": 7.807,
      "gold": 9823.732341,
      "pb": 5.21,
      "pe": 21.43,
      "yield": 1.17,
      "date": "2007-01-21"
  },
  "2007-01-22": {
      "equity": 4102.4,
      "debt": 7.908,
      "gold": 9895.190391,
      "pb": 5.22,
      "pe": 21.42,
      "yield": 1.16,
      "date": "2007-01-22"
  },
  "2007-01-23": {
      "equity": 4066.1,
      "debt": 7.873,
      "gold": 9942.700929,
      "pb": 5.18,
      "pe": 20.96,
      "yield": 1.17,
      "date": "2007-01-23"
  },
  "2007-01-24": {
      "equity": 4089.9,
      "debt": 7.896,
      "gold": 10027.34793,
      "pb": 5.21,
      "pe": 21.07,
      "yield": 1.16,
      "date": "2007-01-24"
  },
  "2007-01-25": {
      "equity": 4147.7,
      "debt": 7.883,
      "gold": 10122.73938,
      "pb": 5.29,
      "pe": 21.35,
      "yield": 1.15,
      "date": "2007-01-25"
  },
  "2007-01-26": {
      "equity": 4147.7,
      "debt": 7.883,
      "gold": 10095.64543,
      "pb": 5.29,
      "pe": 21.35,
      "yield": 1.15,
      "date": "2007-01-26"
  },
  "2007-01-27": {
      "equity": 4147.7,
      "debt": 7.883,
      "gold": 10095.64543,
      "pb": 5.29,
      "pe": 21.35,
      "yield": 1.15,
      "date": "2007-01-27"
  },
  "2007-01-28": {
      "equity": 4147.7,
      "debt": 7.883,
      "gold": 10095.64543,
      "pb": 5.29,
      "pe": 21.35,
      "yield": 1.15,
      "date": "2007-01-28"
  },
  "2007-01-29": {
      "equity": 4124.4,
      "debt": 7.9,
      "gold": 10036.99183,
      "pb": 5.26,
      "pe": 20.88,
      "yield": 1.15,
      "date": "2007-01-29"
  },
  "2007-01-30": {
      "equity": 4124.4,
      "debt": 7.9,
      "gold": 10022.44837,
      "pb": 5.26,
      "pe": 20.88,
      "yield": 1.15,
      "date": "2007-01-30"
  },
  "2007-01-31": {
      "equity": 4082.7,
      "debt": 7.738,
      "gold": 10058.84407,
      "pb": 5.2,
      "pe": 19.85,
      "yield": 1.17,
      "date": "2007-01-31"
  },
  "2007-02-01": {
      "equity": 4137.2,
      "debt": 7.738,
      "gold": 10153.8334,
      "pb": 5.27,
      "pe": 19.95,
      "yield": 1.15,
      "date": "2007-02-01"
  },
  "2007-02-02": {
      "equity": 4183.5,
      "debt": 7.699,
      "gold": 10184.02441,
      "pb": 5.33,
      "pe": 20.12,
      "yield": 1.14,
      "date": "2007-02-02"
  },
  "2007-02-03": {
      "equity": 4183.5,
      "debt": 7.699,
      "gold": 10184.02441,
      "pb": 5.33,
      "pe": 20.12,
      "yield": 1.14,
      "date": "2007-02-03"
  },
  "2007-02-04": {
      "equity": 4183.5,
      "debt": 7.699,
      "gold": 10184.02441,
      "pb": 5.33,
      "pe": 20.12,
      "yield": 1.14,
      "date": "2007-02-04"
  },
  "2007-02-05": {
      "equity": 4215.3,
      "debt": 7.716,
      "gold": 10083.03497,
      "pb": 5.37,
      "pe": 20.27,
      "yield": 1.13,
      "date": "2007-02-05"
  },
  "2007-02-06": {
      "equity": 4195.9,
      "debt": 7.752,
      "gold": 10162.7295,
      "pb": 5.35,
      "pe": 20.18,
      "yield": 1.14,
      "date": "2007-02-06"
  },
  "2007-02-07": {
      "equity": 4224.2,
      "debt": 7.749,
      "gold": 10198.45853,
      "pb": 5.38,
      "pe": 20.32,
      "yield": 1.13,
      "date": "2007-02-07"
  },
  "2007-02-08": {
      "equity": 4223.4,
      "debt": 7.791,
      "gold": 10126.72887,
      "pb": 5.38,
      "pe": 20.31,
      "yield": 1.13,
      "date": "2007-02-08"
  },
  "2007-02-09": {
      "equity": 4187.4,
      "debt": 7.808,
      "gold": 10250.80513,
      "pb": 5.33,
      "pe": 20.14,
      "yield": 1.14,
      "date": "2007-02-09"
  },
  "2007-02-10": {
      "equity": 4187.4,
      "debt": 7.808,
      "gold": 10250.80513,
      "pb": 5.33,
      "pe": 20.14,
      "yield": 1.14,
      "date": "2007-02-10"
  },
  "2007-02-11": {
      "equity": 4187.4,
      "debt": 7.808,
      "gold": 10250.80513,
      "pb": 5.33,
      "pe": 20.14,
      "yield": 1.14,
      "date": "2007-02-11"
  },
  "2007-02-12": {
      "equity": 4058.3,
      "debt": 7.826,
      "gold": 10333.00058,
      "pb": 5.17,
      "pe": 19.52,
      "yield": 1.17,
      "date": "2007-02-12"
  },
  "2007-02-13": {
      "equity": 4044.5,
      "debt": 7.921,
      "gold": 10364.48967,
      "pb": 5.15,
      "pe": 19.45,
      "yield": 1.18,
      "date": "2007-02-13"
  },
  "2007-02-14": {
      "equity": 4047.1,
      "debt": 8.083,
      "gold": 10399.89771,
      "pb": 5.15,
      "pe": 19.47,
      "yield": 1.18,
      "date": "2007-02-14"
  },
  "2007-02-15": {
      "equity": 4146.2,
      "debt": 8.073,
      "gold": 10422.71292,
      "pb": 5.28,
      "pe": 19.94,
      "yield": 1.15,
      "date": "2007-02-15"
  },
  "2007-02-16": {
      "equity": 4146.2,
      "debt": 8.073,
      "gold": 10348.82097,
      "pb": 5.28,
      "pe": 19.94,
      "yield": 1.15,
      "date": "2007-02-16"
  },
  "2007-02-17": {
      "equity": 4146.2,
      "debt": 8.073,
      "gold": 10348.82097,
      "pb": 5.28,
      "pe": 19.94,
      "yield": 1.15,
      "date": "2007-02-17"
  },
  "2007-02-18": {
      "equity": 4146.2,
      "debt": 8.073,
      "gold": 10348.82097,
      "pb": 5.28,
      "pe": 19.94,
      "yield": 1.15,
      "date": "2007-02-18"
  },
  "2007-02-19": {
      "equity": 4164.5,
      "debt": 8.016,
      "gold": 10437.87016,
      "pb": 5.3,
      "pe": 20.02,
      "yield": 1.14,
      "date": "2007-02-19"
  },
  "2007-02-20": {
      "equity": 4106.9,
      "debt": 8.003,
      "gold": 10420.39189,
      "pb": 5.23,
      "pe": 19.74,
      "yield": 1.16,
      "date": "2007-02-20"
  },
  "2007-02-21": {
      "equity": 4096.2,
      "debt": 7.925,
      "gold": 10266.55849,
      "pb": 5.22,
      "pe": 19.69,
      "yield": 1.16,
      "date": "2007-02-21"
  },
  "2007-02-22": {
      "equity": 4040,
      "debt": 7.957,
      "gold": 10556.87049,
      "pb": 5.14,
      "pe": 19.42,
      "yield": 1.18,
      "date": "2007-02-22"
  },
  "2007-02-23": {
      "equity": 3938.9,
      "debt": 7.935,
      "gold": 10561.7771,
      "pb": 5.02,
      "pe": 18.94,
      "yield": 1.21,
      "date": "2007-02-23"
  },
  "2007-02-24": {
      "equity": 3938.9,
      "debt": 7.935,
      "gold": 10561.7771,
      "pb": 5.02,
      "pe": 18.94,
      "yield": 1.21,
      "date": "2007-02-24"
  },
  "2007-02-25": {
      "equity": 3938.9,
      "debt": 7.935,
      "gold": 10561.7771,
      "pb": 5.02,
      "pe": 18.94,
      "yield": 1.21,
      "date": "2007-02-25"
  },
  "2007-02-26": {
      "equity": 3942,
      "debt": 7.892,
      "gold": 10674.52689,
      "pb": 5.02,
      "pe": 18.95,
      "yield": 1.21,
      "date": "2007-02-26"
  },
  "2007-02-27": {
      "equity": 3893.9,
      "debt": 7.898,
      "gold": 10675.73326,
      "pb": 4.96,
      "pe": 18.72,
      "yield": 1.22,
      "date": "2007-02-27"
  },
  "2007-02-28": {
      "equity": 3745.3,
      "debt": 8,
      "gold": 10572.48981,
      "pb": 4.77,
      "pe": 18.01,
      "yield": 1.27,
      "date": "2007-02-28"
  },
  "2007-03-01": {
      "equity": 3811.2,
      "debt": 7.935,
      "gold": 10517.01794,
      "pb": 4.86,
      "pe": 18.33,
      "yield": 1.25,
      "date": "2007-03-01"
  },
  "2007-03-02": {
      "equity": 3726.7,
      "debt": 7.949,
      "gold": 10374.46163,
      "pb": 4.75,
      "pe": 17.92,
      "yield": 1.28,
      "date": "2007-03-02"
  },
  "2007-03-03": {
      "equity": 3726.7,
      "debt": 7.949,
      "gold": 10374.46163,
      "pb": 4.75,
      "pe": 17.92,
      "yield": 1.28,
      "date": "2007-03-03"
  },
  "2007-03-04": {
      "equity": 3726.7,
      "debt": 7.949,
      "gold": 10374.46163,
      "pb": 4.75,
      "pe": 17.92,
      "yield": 1.28,
      "date": "2007-03-04"
  },
  "2007-03-05": {
      "equity": 3576.5,
      "debt": 7.979,
      "gold": 10064.60431,
      "pb": 4.56,
      "pe": 17.2,
      "yield": 1.33,
      "date": "2007-03-05"
  },
  "2007-03-06": {
      "equity": 3655.6,
      "debt": 7.956,
      "gold": 10110.2806,
      "pb": 4.66,
      "pe": 17.58,
      "yield": 1.3,
      "date": "2007-03-06"
  },
  "2007-03-07": {
      "equity": 3626.8,
      "debt": 7.953,
      "gold": 10145.43466,
      "pb": 4.62,
      "pe": 17.44,
      "yield": 1.31,
      "date": "2007-03-07"
  },
  "2007-03-08": {
      "equity": 3761.6,
      "debt": 8.004,
      "gold": 10222.66354,
      "pb": 4.79,
      "pe": 18.09,
      "yield": 1.27,
      "date": "2007-03-08"
  },
  "2007-03-09": {
      "equity": 3718,
      "debt": 8.001,
      "gold": 10188.47599,
      "pb": 4.74,
      "pe": 17.88,
      "yield": 1.28,
      "date": "2007-03-09"
  },
  "2007-03-10": {
      "equity": 3718,
      "debt": 8.001,
      "gold": 10188.47599,
      "pb": 4.74,
      "pe": 17.88,
      "yield": 1.28,
      "date": "2007-03-10"
  },
  "2007-03-11": {
      "equity": 3718,
      "debt": 8.001,
      "gold": 10188.47599,
      "pb": 4.74,
      "pe": 17.88,
      "yield": 1.28,
      "date": "2007-03-11"
  },
  "2007-03-12": {
      "equity": 3734.6,
      "debt": 7.949,
      "gold": 10178.94848,
      "pb": 4.76,
      "pe": 17.96,
      "yield": 1.28,
      "date": "2007-03-12"
  },
  "2007-03-13": {
      "equity": 3770.5,
      "debt": 7.971,
      "gold": 10146.60223,
      "pb": 4.8,
      "pe": 18.14,
      "yield": 1.26,
      "date": "2007-03-13"
  },
  "2007-03-14": {
      "equity": 3641.1,
      "debt": 7.969,
      "gold": 10001.86599,
      "pb": 4.64,
      "pe": 17.51,
      "yield": 1.31,
      "date": "2007-03-14"
  },
  "2007-03-15": {
      "equity": 3643.6,
      "debt": 7.993,
      "gold": 10073.13709,
      "pb": 4.64,
      "pe": 17.53,
      "yield": 1.31,
      "date": "2007-03-15"
  },
  "2007-03-16": {
      "equity": 3608.5,
      "debt": 8.023,
      "gold": 10115.66342,
      "pb": 4.6,
      "pe": 17.36,
      "yield": 1.32,
      "date": "2007-03-16"
  },
  "2007-03-17": {
      "equity": 3608.5,
      "debt": 8.023,
      "gold": 10115.66342,
      "pb": 4.6,
      "pe": 17.36,
      "yield": 1.32,
      "date": "2007-03-17"
  },
  "2007-03-18": {
      "equity": 3608.5,
      "debt": 8.023,
      "gold": 10115.66342,
      "pb": 4.6,
      "pe": 17.36,
      "yield": 1.32,
      "date": "2007-03-18"
  },
  "2007-03-19": {
      "equity": 3678.9,
      "debt": 8.023,
      "gold": 10176.67331,
      "pb": 4.69,
      "pe": 17.7,
      "yield": 1.29,
      "date": "2007-03-19"
  },
  "2007-03-20": {
      "equity": 3697.6,
      "debt": 8.067,
      "gold": 10098.94354,
      "pb": 4.71,
      "pe": 17.79,
      "yield": 1.29,
      "date": "2007-03-20"
  },
  "2007-03-21": {
      "equity": 3764.5,
      "debt": 8.052,
      "gold": 10122.01979,
      "pb": 4.8,
      "pe": 18.11,
      "yield": 1.26,
      "date": "2007-03-21"
  },
  "2007-03-22": {
      "equity": 3875.9,
      "debt": 7.948,
      "gold": 10245.92321,
      "pb": 4.94,
      "pe": 18.65,
      "yield": 1.23,
      "date": "2007-03-22"
  },
  "2007-03-23": {
      "equity": 3861,
      "debt": 7.951,
      "gold": 10180.0067,
      "pb": 4.92,
      "pe": 18.58,
      "yield": 1.23,
      "date": "2007-03-23"
  },
  "2007-03-24": {
      "equity": 3861,
      "debt": 7.951,
      "gold": 10180.0067,
      "pb": 4.92,
      "pe": 18.58,
      "yield": 1.23,
      "date": "2007-03-24"
  },
  "2007-03-25": {
      "equity": 3861,
      "debt": 7.951,
      "gold": 10180.0067,
      "pb": 4.92,
      "pe": 18.58,
      "yield": 1.23,
      "date": "2007-03-25"
  },
  "2007-03-26": {
      "equity": 3819.9,
      "debt": 7.996,
      "gold": 10074.09302,
      "pb": 4.87,
      "pe": 18.38,
      "yield": 1.25,
      "date": "2007-03-26"
  },
  "2007-03-27": {
      "equity": 3819.9,
      "debt": 7.996,
      "gold": 10158.08744,
      "pb": 4.87,
      "pe": 18.38,
      "yield": 1.25,
      "date": "2007-03-27"
  },
  "2007-03-28": {
      "equity": 3761.1,
      "debt": 7.982,
      "gold": 10106.2629,
      "pb": 4.79,
      "pe": 18.1,
      "yield": 1.27,
      "date": "2007-03-28"
  },
  "2007-03-29": {
      "equity": 3798.1,
      "debt": 7.97,
      "gold": 10260.39613,
      "pb": 4.84,
      "pe": 18.29,
      "yield": 1.25,
      "date": "2007-03-29"
  },
  "2007-03-30": {
      "equity": 3821.5,
      "debt": 8.022,
      "gold": 10174.42988,
      "pb": 4.87,
      "pe": 18.4,
      "yield": 1.25,
      "date": "2007-03-30"
  },
  "2007-03-31": {
      "equity": 3821.5,
      "debt": 8.022,
      "gold": 10174.42988,
      "pb": 4.87,
      "pe": 18.4,
      "yield": 1.25,
      "date": "2007-03-31"
  },
  "2007-04-01": {
      "equity": 3821.5,
      "debt": 8.022,
      "gold": 10174.42988,
      "pb": 4.87,
      "pe": 18.4,
      "yield": 1.25,
      "date": "2007-04-01"
  },
  "2007-04-02": {
      "equity": 3633.6,
      "debt": 8.022,
      "gold": 10171.36457,
      "pb": 4.63,
      "pe": 17.49,
      "yield": 1.31,
      "date": "2007-04-02"
  },
  "2007-04-03": {
      "equity": 3690.6,
      "debt": 8.183,
      "gold": 10079.0596,
      "pb": 4.71,
      "pe": 17.77,
      "yield": 1.29,
      "date": "2007-04-03"
  },
  "2007-04-04": {
      "equity": 3733.2,
      "debt": 8.16,
      "gold": 10120.21376,
      "pb": 4.89,
      "pe": 18.43,
      "yield": 1.24,
      "date": "2007-04-04"
  },
  "2007-04-05": {
      "equity": 3752,
      "debt": 8.183,
      "gold": 10184.67345,
      "pb": 4.91,
      "pe": 18.54,
      "yield": 1.23,
      "date": "2007-04-05"
  },
  "2007-04-06": {
      "equity": 3752,
      "debt": 8.183,
      "gold": 10184.67345,
      "pb": 4.91,
      "pe": 18.54,
      "yield": 1.23,
      "date": "2007-04-06"
  },
  "2007-04-07": {
      "equity": 3752,
      "debt": 8.183,
      "gold": 10184.67345,
      "pb": 4.91,
      "pe": 18.54,
      "yield": 1.23,
      "date": "2007-04-07"
  },
  "2007-04-08": {
      "equity": 3752,
      "debt": 8.183,
      "gold": 10184.67345,
      "pb": 4.91,
      "pe": 18.54,
      "yield": 1.23,
      "date": "2007-04-08"
  },
  "2007-04-09": {
      "equity": 3843.5,
      "debt": 8.12,
      "gold": 10184.67345,
      "pb": 5.03,
      "pe": 19,
      "yield": 1.2,
      "date": "2007-04-09"
  },
  "2007-04-10": {
      "equity": 3848.1,
      "debt": 8.024,
      "gold": 10229.33032,
      "pb": 5.04,
      "pe": 19.02,
      "yield": 1.2,
      "date": "2007-04-10"
  },
  "2007-04-11": {
      "equity": 3862.6,
      "debt": 8.047,
      "gold": 10263.33445,
      "pb": 5.03,
      "pe": 19.09,
      "yield": 1.22,
      "date": "2007-04-11"
  },
  "2007-04-12": {
      "equity": 3829.8,
      "debt": 8.078,
      "gold": 10256.06095,
      "pb": 4.99,
      "pe": 18.93,
      "yield": 1.23,
      "date": "2007-04-12"
  },
  "2007-04-13": {
      "equity": 3917.3,
      "debt": 8.105,
      "gold": 10159.35731,
      "pb": 5.1,
      "pe": 19.28,
      "yield": 1.2,
      "date": "2007-04-13"
  },
  "2007-04-14": {
      "equity": 3917.3,
      "debt": 8.105,
      "gold": 10159.35731,
      "pb": 5.1,
      "pe": 19.28,
      "yield": 1.2,
      "date": "2007-04-14"
  },
  "2007-04-15": {
      "equity": 3917.3,
      "debt": 8.105,
      "gold": 10159.35731,
      "pb": 5.1,
      "pe": 19.28,
      "yield": 1.2,
      "date": "2007-04-15"
  },
  "2007-04-16": {
      "equity": 4013.3,
      "debt": 8.105,
      "gold": 10158.63066,
      "pb": 5.23,
      "pe": 19.75,
      "yield": 1.17,
      "date": "2007-04-16"
  },
  "2007-04-17": {
      "equity": 3984.9,
      "debt": 8.108,
      "gold": 10189.13208,
      "pb": 5.19,
      "pe": 19.55,
      "yield": 1.18,
      "date": "2007-04-17"
  },
  "2007-04-18": {
      "equity": 4011.6,
      "debt": 8.075,
      "gold": 10230.76245,
      "pb": 5.22,
      "pe": 19.66,
      "yield": 1.17,
      "date": "2007-04-18"
  },
  "2007-04-19": {
      "equity": 3997.6,
      "debt": 8.096,
      "gold": 10200.44798,
      "pb": 5.21,
      "pe": 19.57,
      "yield": 1.17,
      "date": "2007-04-19"
  },
  "2007-04-20": {
      "equity": 4083.5,
      "debt": 8.067,
      "gold": 10132.27394,
      "pb": 5.32,
      "pe": 19.88,
      "yield": 1.15,
      "date": "2007-04-20"
  },
  "2007-04-21": {
      "equity": 4083.5,
      "debt": 8.067,
      "gold": 10132.27394,
      "pb": 5.32,
      "pe": 19.88,
      "yield": 1.15,
      "date": "2007-04-21"
  },
  "2007-04-22": {
      "equity": 4083.5,
      "debt": 8.067,
      "gold": 10132.27394,
      "pb": 5.32,
      "pe": 19.88,
      "yield": 1.15,
      "date": "2007-04-22"
  },
  "2007-04-23": {
      "equity": 4085.1,
      "debt": 8.1,
      "gold": 10158.6095,
      "pb": 5.32,
      "pe": 19.88,
      "yield": 1.15,
      "date": "2007-04-23"
  },
  "2007-04-24": {
      "equity": 4141.8,
      "debt": 8.003,
      "gold": 10008.73384,
      "pb": 5.4,
      "pe": 20.13,
      "yield": 1.13,
      "date": "2007-04-24"
  },
  "2007-04-25": {
      "equity": 4167.3,
      "debt": 7.98,
      "gold": 9920.425404,
      "pb": 5.43,
      "pe": 20.24,
      "yield": 1.13,
      "date": "2007-04-25"
  },
  "2007-04-26": {
      "equity": 4177.8,
      "debt": 8.052,
      "gold": 9883.70518,
      "pb": 5.44,
      "pe": 20.25,
      "yield": 1.12,
      "date": "2007-04-26"
  },
  "2007-04-27": {
      "equity": 4083.5,
      "debt": 8.142,
      "gold": 9747.272439,
      "pb": 5.32,
      "pe": 19.58,
      "yield": 1.15,
      "date": "2007-04-27"
  },
  "2007-04-28": {
      "equity": 4083.5,
      "debt": 8.142,
      "gold": 9747.272439,
      "pb": 5.32,
      "pe": 19.58,
      "yield": 1.15,
      "date": "2007-04-28"
  },
  "2007-04-29": {
      "equity": 4083.5,
      "debt": 8.142,
      "gold": 9747.272439,
      "pb": 5.32,
      "pe": 19.58,
      "yield": 1.15,
      "date": "2007-04-29"
  },
  "2007-04-30": {
      "equity": 4087.9,
      "debt": 8.173,
      "gold": 9850.903896,
      "pb": 5.33,
      "pe": 19.48,
      "yield": 1.15,
      "date": "2007-04-30"
  },
  "2007-05-01": {
      "equity": 4087.9,
      "debt": 8.173,
      "gold": 9843.640981,
      "pb": 5.33,
      "pe": 19.48,
      "yield": 1.15,
      "date": "2007-05-01"
  },
  "2007-05-02": {
      "equity": 4087.9,
      "debt": 8.173,
      "gold": 9781.890333,
      "pb": 5.33,
      "pe": 19.48,
      "yield": 1.15,
      "date": "2007-05-02"
  },
  "2007-05-03": {
      "equity": 4150.8,
      "debt": 8.15,
      "gold": 9820.391894,
      "pb": 5.41,
      "pe": 19.76,
      "yield": 1.13,
      "date": "2007-05-03"
  },
  "2007-05-04": {
      "equity": 4117.3,
      "debt": 8.159,
      "gold": 9812.338842,
      "pb": 5.36,
      "pe": 19.59,
      "yield": 1.14,
      "date": "2007-05-04"
  },
  "2007-05-05": {
      "equity": 4117.3,
      "debt": 8.159,
      "gold": 9812.338842,
      "pb": 5.36,
      "pe": 19.59,
      "yield": 1.14,
      "date": "2007-05-05"
  },
  "2007-05-06": {
      "equity": 4117.3,
      "debt": 8.159,
      "gold": 9812.338842,
      "pb": 5.36,
      "pe": 19.59,
      "yield": 1.14,
      "date": "2007-05-06"
  },
  "2007-05-07": {
      "equity": 4111.1,
      "debt": 8.159,
      "gold": 9812.338842,
      "pb": 5.36,
      "pe": 19.56,
      "yield": 1.14,
      "date": "2007-05-07"
  },
  "2007-05-08": {
      "equity": 4077,
      "debt": 8.114,
      "gold": 9882.216618,
      "pb": 5.31,
      "pe": 19.39,
      "yield": 1.15,
      "date": "2007-05-08"
  },
  "2007-05-09": {
      "equity": 4079.3,
      "debt": 8.082,
      "gold": 9906.598,
      "pb": 5.32,
      "pe": 19.36,
      "yield": 1.15,
      "date": "2007-05-09"
  },
  "2007-05-10": {
      "equity": 4066.8,
      "debt": 8.122,
      "gold": 9914.478209,
      "pb": 5.3,
      "pe": 19.31,
      "yield": 1.15,
      "date": "2007-05-10"
  },
  "2007-05-11": {
      "equity": 4076.6,
      "debt": 8.167,
      "gold": 9676.339971,
      "pb": 5.31,
      "pe": 19.35,
      "yield": 1.15,
      "date": "2007-05-11"
  },
  "2007-05-12": {
      "equity": 4076.6,
      "debt": 8.167,
      "gold": 9676.339971,
      "pb": 5.31,
      "pe": 19.35,
      "yield": 1.15,
      "date": "2007-05-12"
  },
  "2007-05-13": {
      "equity": 4076.6,
      "debt": 8.167,
      "gold": 9676.339971,
      "pb": 5.31,
      "pe": 19.35,
      "yield": 1.15,
      "date": "2007-05-13"
  },
  "2007-05-14": {
      "equity": 4134.3,
      "debt": 8.151,
      "gold": 9719.589411,
      "pb": 5.39,
      "pe": 19.54,
      "yield": 1.13,
      "date": "2007-05-14"
  },
  "2007-05-15": {
      "equity": 4120.3,
      "debt": 8.122,
      "gold": 9638.145294,
      "pb": 5.36,
      "pe": 19.46,
      "yield": 1.15,
      "date": "2007-05-15"
  },
  "2007-05-16": {
      "equity": 4170.9,
      "debt": 8.104,
      "gold": 9649.535265,
      "pb": 5.43,
      "pe": 19.7,
      "yield": 1.14,
      "date": "2007-05-16"
  },
  "2007-05-17": {
      "equity": 4219.5,
      "debt": 8.123,
      "gold": 9553.992134,
      "pb": 5.49,
      "pe": 19.93,
      "yield": 1.12,
      "date": "2007-05-17"
  },
  "2007-05-18": {
      "equity": 4214.5,
      "debt": 8.154,
      "gold": 9489.56419,
      "pb": 5.48,
      "pe": 19.78,
      "yield": 1.12,
      "date": "2007-05-18"
  },
  "2007-05-19": {
      "equity": 4214.5,
      "debt": 8.154,
      "gold": 9489.56419,
      "pb": 5.48,
      "pe": 19.78,
      "yield": 1.12,
      "date": "2007-05-19"
  },
  "2007-05-20": {
      "equity": 4214.5,
      "debt": 8.154,
      "gold": 9489.56419,
      "pb": 5.48,
      "pe": 19.78,
      "yield": 1.12,
      "date": "2007-05-20"
  },
  "2007-05-21": {
      "equity": 4260.9,
      "debt": 8.09,
      "gold": 9501.578511,
      "pb": 5.54,
      "pe": 19.83,
      "yield": 1.11,
      "date": "2007-05-21"
  },
  "2007-05-22": {
      "equity": 4278.1,
      "debt": 8.113,
      "gold": 9500.971798,
      "pb": 5.57,
      "pe": 19.91,
      "yield": 1.11,
      "date": "2007-05-22"
  },
  "2007-05-23": {
      "equity": 4246.2,
      "debt": 8.143,
      "gold": 9432.723681,
      "pb": 5.53,
      "pe": 19.78,
      "yield": 1.12,
      "date": "2007-05-23"
  },
  "2007-05-24": {
      "equity": 4204.9,
      "debt": 8.156,
      "gold": 9472.332845,
      "pb": 5.47,
      "pe": 19.59,
      "yield": 1.13,
      "date": "2007-05-24"
  },
  "2007-05-25": {
      "equity": 4248.1,
      "debt": 8.136,
      "gold": 9374.638001,
      "pb": 5.53,
      "pe": 19.98,
      "yield": 1.12,
      "date": "2007-05-25"
  },
  "2007-05-26": {
      "equity": 4248.1,
      "debt": 8.136,
      "gold": 9374.638001,
      "pb": 5.53,
      "pe": 19.98,
      "yield": 1.12,
      "date": "2007-05-26"
  },
  "2007-05-27": {
      "equity": 4248.1,
      "debt": 8.136,
      "gold": 9374.638001,
      "pb": 5.53,
      "pe": 19.98,
      "yield": 1.12,
      "date": "2007-05-27"
  },
  "2007-05-28": {
      "equity": 4256.5,
      "debt": 8.071,
      "gold": 9374.638001,
      "pb": 5.54,
      "pe": 20.02,
      "yield": 1.11,
      "date": "2007-05-28"
  },
  "2007-05-29": {
      "equity": 4293.2,
      "debt": 8.104,
      "gold": 9395.029895,
      "pb": 5.59,
      "pe": 20.11,
      "yield": 1.1,
      "date": "2007-05-29"
  },
  "2007-05-30": {
      "equity": 4249.6,
      "debt": 8.101,
      "gold": 9464.957054,
      "pb": 5.53,
      "pe": 20.17,
      "yield": 1.11,
      "date": "2007-05-30"
  },
  "2007-05-31": {
      "equity": 4295.8,
      "debt": 8.08,
      "gold": 9396.377361,
      "pb": 5.59,
      "pe": 20.41,
      "yield": 1.1,
      "date": "2007-05-31"
  },
  "2007-06-01": {
      "equity": 4297,
      "debt": 8.077,
      "gold": 9472.632674,
      "pb": 5.59,
      "pe": 20.41,
      "yield": 1.1,
      "date": "2007-06-01"
  },
  "2007-06-02": {
      "equity": 4297,
      "debt": 8.077,
      "gold": 9472.632674,
      "pb": 5.59,
      "pe": 20.41,
      "yield": 1.1,
      "date": "2007-06-02"
  },
  "2007-06-03": {
      "equity": 4297,
      "debt": 8.077,
      "gold": 9472.632674,
      "pb": 5.59,
      "pe": 20.41,
      "yield": 1.1,
      "date": "2007-06-03"
  },
  "2007-06-04": {
      "equity": 4267,
      "debt": 8.086,
      "gold": 9587.043863,
      "pb": 5.48,
      "pe": 20.27,
      "yield": 1.09,
      "date": "2007-06-04"
  },
  "2007-06-05": {
      "equity": 4284.6,
      "debt": 8.092,
      "gold": 9594.906436,
      "pb": 5.5,
      "pe": 20.35,
      "yield": 1.08,
      "date": "2007-06-05"
  },
  "2007-06-06": {
      "equity": 4198.2,
      "debt": 8.136,
      "gold": 9608.906683,
      "pb": 5.39,
      "pe": 19.94,
      "yield": 1.1,
      "date": "2007-06-06"
  },
  "2007-06-07": {
      "equity": 4179.5,
      "debt": 8.103,
      "gold": 9636.984779,
      "pb": 5.37,
      "pe": 19.85,
      "yield": 1.11,
      "date": "2007-06-07"
  },
  "2007-06-08": {
      "equity": 4145,
      "debt": 8.138,
      "gold": 9534.04822,
      "pb": 5.32,
      "pe": 19.69,
      "yield": 1.12,
      "date": "2007-06-08"
  },
  "2007-06-09": {
      "equity": 4145,
      "debt": 8.138,
      "gold": 9534.04822,
      "pb": 5.32,
      "pe": 19.69,
      "yield": 1.12,
      "date": "2007-06-09"
  },
  "2007-06-10": {
      "equity": 4145,
      "debt": 8.138,
      "gold": 9534.04822,
      "pb": 5.32,
      "pe": 19.69,
      "yield": 1.12,
      "date": "2007-06-10"
  },
  "2007-06-11": {
      "equity": 4145.6,
      "debt": 8.345,
      "gold": 9397.118115,
      "pb": 5.33,
      "pe": 19.69,
      "yield": 1.12,
      "date": "2007-06-11"
  },
  "2007-06-12": {
      "equity": 4155.2,
      "debt": 8.379,
      "gold": 9392.655955,
      "pb": 5.34,
      "pe": 19.74,
      "yield": 1.11,
      "date": "2007-06-12"
  },
  "2007-06-13": {
      "equity": 4113,
      "debt": 8.411,
      "gold": 9304.968342,
      "pb": 5.28,
      "pe": 19.54,
      "yield": 1.13,
      "date": "2007-06-13"
  },
  "2007-06-14": {
      "equity": 4170,
      "debt": 8.331,
      "gold": 9389.558899,
      "pb": 5.36,
      "pe": 19.81,
      "yield": 1.11,
      "date": "2007-06-14"
  },
  "2007-06-15": {
      "equity": 4171.4,
      "debt": 8.288,
      "gold": 9377.068379,
      "pb": 5.36,
      "pe": 19.82,
      "yield": 1.11,
      "date": "2007-06-15"
  },
  "2007-06-16": {
      "equity": 4171.4,
      "debt": 8.288,
      "gold": 9377.068379,
      "pb": 5.36,
      "pe": 19.82,
      "yield": 1.11,
      "date": "2007-06-16"
  },
  "2007-06-17": {
      "equity": 4171.4,
      "debt": 8.288,
      "gold": 9377.068379,
      "pb": 5.36,
      "pe": 19.82,
      "yield": 1.11,
      "date": "2007-06-17"
  },
  "2007-06-18": {
      "equity": 4147.1,
      "debt": 8.202,
      "gold": 9440.783788,
      "pb": 5.33,
      "pe": 19.7,
      "yield": 1.12,
      "date": "2007-06-18"
  },
  "2007-06-19": {
      "equity": 4214.3,
      "debt": 8.221,
      "gold": 9411.883807,
      "pb": 5.41,
      "pe": 20.02,
      "yield": 1.1,
      "date": "2007-06-19"
  },
  "2007-06-20": {
      "equity": 4248.6,
      "debt": 8.249,
      "gold": 9469.556782,
      "pb": 5.46,
      "pe": 20.19,
      "yield": 1.09,
      "date": "2007-06-20"
  },
  "2007-06-21": {
      "equity": 4267.4,
      "debt": 8.235,
      "gold": 9405.573291,
      "pb": 5.48,
      "pe": 20.28,
      "yield": 1.09,
      "date": "2007-06-21"
  },
  "2007-06-22": {
      "equity": 4252,
      "debt": 8.229,
      "gold": 9374.246459,
      "pb": 5.31,
      "pe": 20.2,
      "yield": 1.13,
      "date": "2007-06-22"
  },
  "2007-06-23": {
      "equity": 4252,
      "debt": 8.229,
      "gold": 9374.246459,
      "pb": 5.31,
      "pe": 20.2,
      "yield": 1.13,
      "date": "2007-06-23"
  },
  "2007-06-24": {
      "equity": 4252,
      "debt": 8.229,
      "gold": 9374.246459,
      "pb": 5.31,
      "pe": 20.2,
      "yield": 1.13,
      "date": "2007-06-24"
  },
  "2007-06-25": {
      "equity": 4259.4,
      "debt": 8.183,
      "gold": 9387.583555,
      "pb": 5.32,
      "pe": 20.32,
      "yield": 1.13,
      "date": "2007-06-25"
  },
  "2007-06-26": {
      "equity": 4285.7,
      "debt": 8.206,
      "gold": 9380.264202,
      "pb": 5.36,
      "pe": 20.44,
      "yield": 1.12,
      "date": "2007-06-26"
  },
  "2007-06-27": {
      "equity": 4263.9,
      "debt": 8.238,
      "gold": 9298.81303,
      "pb": 5.33,
      "pe": 20.34,
      "yield": 1.12,
      "date": "2007-06-27"
  },
  "2007-06-28": {
      "equity": 4282,
      "debt": 8.194,
      "gold": 9306.442794,
      "pb": 5.35,
      "pe": 20.42,
      "yield": 1.12,
      "date": "2007-06-28"
  },
  "2007-06-29": {
      "equity": 4318.3,
      "debt": 8.187,
      "gold": 9310.199474,
      "pb": 5.4,
      "pe": 20.6,
      "yield": 1.11,
      "date": "2007-06-29"
  },
  "2007-06-30": {
      "equity": 4318.3,
      "debt": 8.187,
      "gold": 9310.199474,
      "pb": 5.4,
      "pe": 20.6,
      "yield": 1.11,
      "date": "2007-06-30"
  },
  "2007-07-01": {
      "equity": 4318.3,
      "debt": 8.187,
      "gold": 9310.199474,
      "pb": 5.4,
      "pe": 20.6,
      "yield": 1.11,
      "date": "2007-07-01"
  },
  "2007-07-02": {
      "equity": 4313.7,
      "debt": 8.111,
      "gold": 9329.73421,
      "pb": 5.39,
      "pe": 20.62,
      "yield": 1.11,
      "date": "2007-07-02"
  },
  "2007-07-03": {
      "equity": 4357.5,
      "debt": 8.097,
      "gold": 9400.462089,
      "pb": 5.45,
      "pe": 20.83,
      "yield": 1.1,
      "date": "2007-07-03"
  },
  "2007-07-04": {
      "equity": 4359.3,
      "debt": 8.028,
      "gold": 9331.487328,
      "pb": 5.45,
      "pe": 20.84,
      "yield": 1.1,
      "date": "2007-07-04"
  },
  "2007-07-05": {
      "equity": 4353.9,
      "debt": 8.007,
      "gold": 9356.284943,
      "pb": 5.44,
      "pe": 20.81,
      "yield": 1.1,
      "date": "2007-07-05"
  },
  "2007-07-06": {
      "equity": 4384.8,
      "debt": 8.01,
      "gold": 9243.454029,
      "pb": 5.52,
      "pe": 21.11,
      "yield": 1.09,
      "date": "2007-07-06"
  },
  "2007-07-07": {
      "equity": 4384.8,
      "debt": 8.01,
      "gold": 9243.454029,
      "pb": 5.52,
      "pe": 21.11,
      "yield": 1.09,
      "date": "2007-07-07"
  },
  "2007-07-08": {
      "equity": 4384.8,
      "debt": 8.01,
      "gold": 9243.454029,
      "pb": 5.52,
      "pe": 21.11,
      "yield": 1.09,
      "date": "2007-07-08"
  },
  "2007-07-09": {
      "equity": 4419.4,
      "debt": 7.925,
      "gold": 9358.069807,
      "pb": 5.56,
      "pe": 21.27,
      "yield": 1.08,
      "date": "2007-07-09"
  },
  "2007-07-10": {
      "equity": 4406,
      "debt": 7.939,
      "gold": 9426.508404,
      "pb": 5.55,
      "pe": 21.2,
      "yield": 1.08,
      "date": "2007-07-10"
  },
  "2007-07-11": {
      "equity": 4387.1,
      "debt": 7.925,
      "gold": 9456.604173,
      "pb": 5.52,
      "pe": 21.06,
      "yield": 1.09,
      "date": "2007-07-11"
  },
  "2007-07-12": {
      "equity": 4446.1,
      "debt": 7.916,
      "gold": 9472.181167,
      "pb": 5.6,
      "pe": 21.35,
      "yield": 1.07,
      "date": "2007-07-12"
  },
  "2007-07-13": {
      "equity": 4504.5,
      "debt": 7.939,
      "gold": 9517.046156,
      "pb": 5.67,
      "pe": 21.63,
      "yield": 1.06,
      "date": "2007-07-13"
  },
  "2007-07-14": {
      "equity": 4504.5,
      "debt": 7.939,
      "gold": 9517.046156,
      "pb": 5.67,
      "pe": 21.63,
      "yield": 1.06,
      "date": "2007-07-14"
  },
  "2007-07-15": {
      "equity": 4504.5,
      "debt": 7.939,
      "gold": 9517.046156,
      "pb": 5.67,
      "pe": 21.63,
      "yield": 1.06,
      "date": "2007-07-15"
  },
  "2007-07-16": {
      "equity": 4512.1,
      "debt": 7.914,
      "gold": 9497.007002,
      "pb": 5.71,
      "pe": 21.78,
      "yield": 1.05,
      "date": "2007-07-16"
  },
  "2007-07-17": {
      "equity": 4496.7,
      "debt": 7.843,
      "gold": 9439.820808,
      "pb": 5.69,
      "pe": 21.65,
      "yield": 1.05,
      "date": "2007-07-17"
  },
  "2007-07-18": {
      "equity": 4499.5,
      "debt": 7.784,
      "gold": 9500.001764,
      "pb": 5.69,
      "pe": 21.64,
      "yield": 1.05,
      "date": "2007-07-18"
  },
  "2007-07-19": {
      "equity": 4562.1,
      "debt": 7.831,
      "gold": 9584.497081,
      "pb": 5.77,
      "pe": 21.86,
      "yield": 1.04,
      "date": "2007-07-19"
  },
  "2007-07-20": {
      "equity": 4566,
      "debt": 7.808,
      "gold": 9614.867987,
      "pb": 5.35,
      "pe": 21.76,
      "yield": 1.07,
      "date": "2007-07-20"
  },
  "2007-07-21": {
      "equity": 4566,
      "debt": 7.808,
      "gold": 9614.867987,
      "pb": 5.35,
      "pe": 21.76,
      "yield": 1.07,
      "date": "2007-07-21"
  },
  "2007-07-22": {
      "equity": 4566,
      "debt": 7.808,
      "gold": 9614.867987,
      "pb": 5.35,
      "pe": 21.76,
      "yield": 1.07,
      "date": "2007-07-22"
  },
  "2007-07-23": {
      "equity": 4619.3,
      "debt": 7.747,
      "gold": 9713.829168,
      "pb": 5.42,
      "pe": 22,
      "yield": 1.05,
      "date": "2007-07-23"
  },
  "2007-07-24": {
      "equity": 4620.7,
      "debt": 7.775,
      "gold": 9690.523642,
      "pb": 5.42,
      "pe": 22.01,
      "yield": 1.05,
      "date": "2007-07-24"
  },
  "2007-07-25": {
      "equity": 4588.7,
      "debt": 7.825,
      "gold": 9634.557929,
      "pb": 5.38,
      "pe": 21.71,
      "yield": 1.06,
      "date": "2007-07-25"
  },
  "2007-07-26": {
      "equity": 4619.8,
      "debt": 7.804,
      "gold": 9614.793912,
      "pb": 5.42,
      "pe": 21.71,
      "yield": 1.05,
      "date": "2007-07-26"
  },
  "2007-07-27": {
      "equity": 4445.2,
      "debt": 7.834,
      "gold": 9498.633133,
      "pb": 5.21,
      "pe": 20.87,
      "yield": 1.09,
      "date": "2007-07-27"
  },
  "2007-07-28": {
      "equity": 4445.2,
      "debt": 7.834,
      "gold": 9498.633133,
      "pb": 5.21,
      "pe": 20.87,
      "yield": 1.09,
      "date": "2007-07-28"
  },
  "2007-07-29": {
      "equity": 4445.2,
      "debt": 7.834,
      "gold": 9498.633133,
      "pb": 5.21,
      "pe": 20.87,
      "yield": 1.09,
      "date": "2007-07-29"
  },
  "2007-07-30": {
      "equity": 4440,
      "debt": 7.776,
      "gold": 9457.168557,
      "pb": 5.21,
      "pe": 20.34,
      "yield": 1.1,
      "date": "2007-07-30"
  },
  "2007-07-31": {
      "equity": 4528.8,
      "debt": 7.845,
      "gold": 9511.056632,
      "pb": 5.31,
      "pe": 20.49,
      "yield": 1.07,
      "date": "2007-07-31"
  },
  "2007-08-01": {
      "equity": 4345.8,
      "debt": 7.897,
      "gold": 9422.130902,
      "pb": 5.1,
      "pe": 19.65,
      "yield": 1.12,
      "date": "2007-08-01"
  },
  "2007-08-02": {
      "equity": 4356.3,
      "debt": 7.891,
      "gold": 9486.978606,
      "pb": 5.12,
      "pe": 19.72,
      "yield": 1.11,
      "date": "2007-08-02"
  },
  "2007-08-03": {
      "equity": 4401.5,
      "debt": 7.86,
      "gold": 9491.246759,
      "pb": 5.17,
      "pe": 19.93,
      "yield": 1.1,
      "date": "2007-08-03"
  },
  "2007-08-04": {
      "equity": 4401.5,
      "debt": 7.86,
      "gold": 9491.246759,
      "pb": 5.17,
      "pe": 19.93,
      "yield": 1.1,
      "date": "2007-08-04"
  },
  "2007-08-05": {
      "equity": 4401.5,
      "debt": 7.86,
      "gold": 9491.246759,
      "pb": 5.17,
      "pe": 19.93,
      "yield": 1.1,
      "date": "2007-08-05"
  },
  "2007-08-06": {
      "equity": 4339.5,
      "debt": 7.852,
      "gold": 9602.941851,
      "pb": 5.1,
      "pe": 19.65,
      "yield": 1.12,
      "date": "2007-08-06"
  },
  "2007-08-07": {
      "equity": 4356.3,
      "debt": 7.826,
      "gold": 9547.900316,
      "pb": 5.12,
      "pe": 19.72,
      "yield": 1.11,
      "date": "2007-08-07"
  },
  "2007-08-08": {
      "equity": 4462.1,
      "debt": 7.906,
      "gold": 9583.816293,
      "pb": 5.24,
      "pe": 20.2,
      "yield": 1.09,
      "date": "2007-08-08"
  },
  "2007-08-09": {
      "equity": 4403.2,
      "debt": 7.991,
      "gold": 9600.303356,
      "pb": 5.17,
      "pe": 19.94,
      "yield": 1.1,
      "date": "2007-08-09"
  },
  "2007-08-10": {
      "equity": 4333.3,
      "debt": 7.983,
      "gold": 9521.152049,
      "pb": 5.09,
      "pe": 19.63,
      "yield": 1.12,
      "date": "2007-08-10"
  },
  "2007-08-11": {
      "equity": 4333.3,
      "debt": 7.983,
      "gold": 9521.152049,
      "pb": 5.09,
      "pe": 19.63,
      "yield": 1.12,
      "date": "2007-08-11"
  },
  "2007-08-12": {
      "equity": 4333.3,
      "debt": 7.983,
      "gold": 9521.152049,
      "pb": 5.09,
      "pe": 19.63,
      "yield": 1.12,
      "date": "2007-08-12"
  },
  "2007-08-13": {
      "equity": 4373.6,
      "debt": 7.977,
      "gold": 9583.632868,
      "pb": 5.14,
      "pe": 19.81,
      "yield": 1.11,
      "date": "2007-08-13"
  },
  "2007-08-14": {
      "equity": 4370.2,
      "debt": 7.988,
      "gold": 9580.821531,
      "pb": 5.14,
      "pe": 19.77,
      "yield": 1.11,
      "date": "2007-08-14"
  },
  "2007-08-15": {
      "equity": 4370.2,
      "debt": 7.988,
      "gold": 9547.081959,
      "pb": 5.14,
      "pe": 19.77,
      "yield": 1.11,
      "date": "2007-08-15"
  },
  "2007-08-16": {
      "equity": 4178.6,
      "debt": 8.036,
      "gold": 9684.812783,
      "pb": 4.92,
      "pe": 18.9,
      "yield": 1.16,
      "date": "2007-08-16"
  },
  "2007-08-17": {
      "equity": 4108,
      "debt": 7.999,
      "gold": 9523.37431,
      "pb": 4.83,
      "pe": 18.58,
      "yield": 1.18,
      "date": "2007-08-17"
  },
  "2007-08-18": {
      "equity": 4108,
      "debt": 7.999,
      "gold": 9523.37431,
      "pb": 4.83,
      "pe": 18.58,
      "yield": 1.18,
      "date": "2007-08-18"
  },
  "2007-08-19": {
      "equity": 4108,
      "debt": 7.999,
      "gold": 9523.37431,
      "pb": 4.83,
      "pe": 18.58,
      "yield": 1.18,
      "date": "2007-08-19"
  },
  "2007-08-20": {
      "equity": 4209,
      "debt": 7.999,
      "gold": 9603.583837,
      "pb": 4.95,
      "pe": 19.04,
      "yield": 1.15,
      "date": "2007-08-20"
  },
  "2007-08-21": {
      "equity": 4074.9,
      "debt": 7.958,
      "gold": 9510.121872,
      "pb": 4.79,
      "pe": 18.43,
      "yield": 1.19,
      "date": "2007-08-21"
  },
  "2007-08-22": {
      "equity": 4153.1,
      "debt": 7.912,
      "gold": 9485.380695,
      "pb": 4.73,
      "pe": 18.79,
      "yield": 1.18,
      "date": "2007-08-22"
  },
  "2007-08-23": {
      "equity": 4114.9,
      "debt": 7.931,
      "gold": 9587.026226,
      "pb": 4.69,
      "pe": 18.62,
      "yield": 1.19,
      "date": "2007-08-23"
  },
  "2007-08-24": {
      "equity": 4190.1,
      "debt": 7.937,
      "gold": 9562.193337,
      "pb": 4.77,
      "pe": 18.96,
      "yield": 1.17,
      "date": "2007-08-24"
  },
  "2007-08-25": {
      "equity": 4190.1,
      "debt": 7.937,
      "gold": 9562.193337,
      "pb": 4.77,
      "pe": 18.96,
      "yield": 1.17,
      "date": "2007-08-25"
  },
  "2007-08-26": {
      "equity": 4190.1,
      "debt": 7.937,
      "gold": 9562.193337,
      "pb": 4.77,
      "pe": 18.96,
      "yield": 1.17,
      "date": "2007-08-26"
  },
  "2007-08-27": {
      "equity": 4302.6,
      "debt": 7.928,
      "gold": 9562.193337,
      "pb": 4.9,
      "pe": 19.47,
      "yield": 1.14,
      "date": "2007-08-27"
  },
  "2007-08-28": {
      "equity": 4320.7,
      "debt": 7.939,
      "gold": 9689.906348,
      "pb": 4.92,
      "pe": 19.55,
      "yield": 1.14,
      "date": "2007-08-28"
  },
  "2007-08-29": {
      "equity": 4359.3,
      "debt": 7.952,
      "gold": 9639.04478,
      "pb": 4.96,
      "pe": 19.72,
      "yield": 1.13,
      "date": "2007-08-29"
  },
  "2007-08-30": {
      "equity": 4412.3,
      "debt": 7.97,
      "gold": 9663.281539,
      "pb": 5.02,
      "pe": 19.96,
      "yield": 1.11,
      "date": "2007-08-30"
  },
  "2007-08-31": {
      "equity": 4464,
      "debt": 7.93,
      "gold": 9637.277553,
      "pb": 5.08,
      "pe": 20.2,
      "yield": 1.1,
      "date": "2007-08-31"
  },
  "2007-09-01": {
      "equity": 4464,
      "debt": 7.93,
      "gold": 9637.277553,
      "pb": 5.08,
      "pe": 20.2,
      "yield": 1.1,
      "date": "2007-09-01"
  },
  "2007-09-02": {
      "equity": 4464,
      "debt": 7.93,
      "gold": 9637.277553,
      "pb": 5.08,
      "pe": 20.2,
      "yield": 1.1,
      "date": "2007-09-02"
  },
  "2007-09-03": {
      "equity": 4474.7,
      "debt": 7.902,
      "gold": 9696.315632,
      "pb": 5.1,
      "pe": 20.25,
      "yield": 1.1,
      "date": "2007-09-03"
  },
  "2007-09-04": {
      "equity": 4479.2,
      "debt": 7.917,
      "gold": 9716.626396,
      "pb": 5.1,
      "pe": 20.27,
      "yield": 1.1,
      "date": "2007-09-04"
  },
  "2007-09-05": {
      "equity": 4475.8,
      "debt": 7.928,
      "gold": 9817.569975,
      "pb": 5.1,
      "pe": 20.26,
      "yield": 1.1,
      "date": "2007-09-05"
  },
  "2007-09-06": {
      "equity": 4518.6,
      "debt": 7.92,
      "gold": 9834.378031,
      "pb": 5.15,
      "pe": 20.45,
      "yield": 1.09,
      "date": "2007-09-06"
  },
  "2007-09-07": {
      "equity": 4509.5,
      "debt": 7.896,
      "gold": 9972.874301,
      "pb": 5.14,
      "pe": 20.41,
      "yield": 1.09,
      "date": "2007-09-07"
  },
  "2007-09-08": {
      "equity": 4509.5,
      "debt": 7.896,
      "gold": 9972.874301,
      "pb": 5.14,
      "pe": 20.41,
      "yield": 1.09,
      "date": "2007-09-08"
  },
  "2007-09-09": {
      "equity": 4509.5,
      "debt": 7.896,
      "gold": 9972.874301,
      "pb": 5.14,
      "pe": 20.41,
      "yield": 1.09,
      "date": "2007-09-09"
  },
  "2007-09-10": {
      "equity": 4507.8,
      "debt": 7.864,
      "gold": 10094.56957,
      "pb": 5.13,
      "pe": 20.4,
      "yield": 1.09,
      "date": "2007-09-10"
  },
  "2007-09-11": {
      "equity": 4497,
      "debt": 7.896,
      "gold": 10074.55511,
      "pb": 5.12,
      "pe": 20.35,
      "yield": 1.09,
      "date": "2007-09-11"
  },
  "2007-09-12": {
      "equity": 4496.8,
      "debt": 7.896,
      "gold": 10149.20545,
      "pb": 5.12,
      "pe": 20.35,
      "yield": 1.09,
      "date": "2007-09-12"
  },
  "2007-09-13": {
      "equity": 4528.9,
      "debt": 7.898,
      "gold": 10100.90478,
      "pb": 5.16,
      "pe": 20.5,
      "yield": 1.08,
      "date": "2007-09-13"
  },
  "2007-09-14": {
      "equity": 4518,
      "debt": 7.886,
      "gold": 10082.89388,
      "pb": 5.03,
      "pe": 20.45,
      "yield": 1.14,
      "date": "2007-09-14"
  },
  "2007-09-15": {
      "equity": 4518,
      "debt": 7.886,
      "gold": 10082.89388,
      "pb": 5.03,
      "pe": 20.45,
      "yield": 1.14,
      "date": "2007-09-15"
  },
  "2007-09-16": {
      "equity": 4518,
      "debt": 7.886,
      "gold": 10082.89388,
      "pb": 5.03,
      "pe": 20.45,
      "yield": 1.14,
      "date": "2007-09-16"
  },
  "2007-09-17": {
      "equity": 4494.6,
      "debt": 7.892,
      "gold": 10161.01166,
      "pb": 5.01,
      "pe": 20.34,
      "yield": 1.15,
      "date": "2007-09-17"
  },
  "2007-09-18": {
      "equity": 4546.2,
      "debt": 7.886,
      "gold": 10253.03797,
      "pb": 5.06,
      "pe": 20.57,
      "yield": 1.14,
      "date": "2007-09-18"
  },
  "2007-09-19": {
      "equity": 4732.3,
      "debt": 7.862,
      "gold": 10243.73269,
      "pb": 5.27,
      "pe": 21.42,
      "yield": 1.09,
      "date": "2007-09-19"
  },
  "2007-09-20": {
      "equity": 4747.5,
      "debt": 7.844,
      "gold": 10201.51678,
      "pb": 5.29,
      "pe": 21.49,
      "yield": 1.09,
      "date": "2007-09-20"
  },
  "2007-09-21": {
      "equity": 4837.5,
      "debt": 7.859,
      "gold": 10333.98825,
      "pb": 5.39,
      "pe": 21.89,
      "yield": 1.07,
      "date": "2007-09-21"
  },
  "2007-09-22": {
      "equity": 4837.5,
      "debt": 7.859,
      "gold": 10333.98825,
      "pb": 5.39,
      "pe": 21.89,
      "yield": 1.07,
      "date": "2007-09-22"
  },
  "2007-09-23": {
      "equity": 4837.5,
      "debt": 7.859,
      "gold": 10333.98825,
      "pb": 5.39,
      "pe": 21.89,
      "yield": 1.07,
      "date": "2007-09-23"
  },
  "2007-09-24": {
      "equity": 4932.2,
      "debt": 7.887,
      "gold": 10265.68017,
      "pb": 5.26,
      "pe": 22.18,
      "yield": 1.08,
      "date": "2007-09-24"
  },
  "2007-09-25": {
      "equity": 4938.8,
      "debt": 7.927,
      "gold": 10200.81483,
      "pb": 5.27,
      "pe": 22.21,
      "yield": 1.08,
      "date": "2007-09-25"
  },
  "2007-09-26": {
      "equity": 4940.5,
      "debt": 7.923,
      "gold": 10227.88056,
      "pb": 5.27,
      "pe": 22.21,
      "yield": 1.08,
      "date": "2007-09-26"
  },
  "2007-09-27": {
      "equity": 5000.5,
      "debt": 7.928,
      "gold": 10229.55255,
      "pb": 5.33,
      "pe": 22.49,
      "yield": 1.07,
      "date": "2007-09-27"
  },
  "2007-09-28": {
      "equity": 5021.3,
      "debt": 7.925,
      "gold": 10349.50175,
      "pb": 5.36,
      "pe": 22.58,
      "yield": 1.06,
      "date": "2007-09-28"
  },
  "2007-09-29": {
      "equity": 5021.3,
      "debt": 7.925,
      "gold": 10349.50175,
      "pb": 5.36,
      "pe": 22.58,
      "yield": 1.06,
      "date": "2007-09-29"
  },
  "2007-09-30": {
      "equity": 5021.3,
      "debt": 7.925,
      "gold": 10349.50175,
      "pb": 5.36,
      "pe": 22.58,
      "yield": 1.06,
      "date": "2007-09-30"
  },
  "2007-10-01": {
      "equity": 5068.9,
      "debt": 7.919,
      "gold": 10473.11593,
      "pb": 5.41,
      "pe": 22.79,
      "yield": 1.05,
      "date": "2007-10-01"
  },
  "2007-10-02": {
      "equity": 5068.9,
      "debt": 7.919,
      "gold": 10339.61093,
      "pb": 5.41,
      "pe": 22.79,
      "yield": 1.05,
      "date": "2007-10-02"
  },
  "2007-10-03": {
      "equity": 5210.8,
      "debt": 7.898,
      "gold": 10233.24926,
      "pb": 5.56,
      "pe": 23.43,
      "yield": 1.03,
      "date": "2007-10-03"
  },
  "2007-10-04": {
      "equity": 5208.6,
      "debt": 7.893,
      "gold": 10119.56472,
      "pb": 5.56,
      "pe": 23.42,
      "yield": 1.03,
      "date": "2007-10-04"
  },
  "2007-10-05": {
      "equity": 5185.8,
      "debt": 7.908,
      "gold": 10239.78553,
      "pb": 5.66,
      "pe": 23.62,
      "yield": 1.01,
      "date": "2007-10-05"
  },
  "2007-10-06": {
      "equity": 5185.8,
      "debt": 7.913,
      "gold": 10239.78553,
      "pb": 5.66,
      "pe": 23.62,
      "yield": 1.01,
      "date": "2007-10-06"
  },
  "2007-10-07": {
      "equity": 5185.8,
      "debt": 7.913,
      "gold": 10239.78553,
      "pb": 5.66,
      "pe": 23.62,
      "yield": 1.01,
      "date": "2007-10-07"
  },
  "2007-10-08": {
      "equity": 5085.1,
      "debt": 7.941,
      "gold": 10250.1314,
      "pb": 5.55,
      "pe": 23.16,
      "yield": 1.03,
      "date": "2007-10-08"
  },
  "2007-10-09": {
      "equity": 5327.2,
      "debt": 7.926,
      "gold": 10136.53856,
      "pb": 5.82,
      "pe": 24.26,
      "yield": 0.99,
      "date": "2007-10-09"
  },
  "2007-10-10": {
      "equity": 5441.4,
      "debt": 7.901,
      "gold": 10273.1512,
      "pb": 5.94,
      "pe": 24.78,
      "yield": 0.97,
      "date": "2007-10-10"
  },
  "2007-10-11": {
      "equity": 5524.8,
      "debt": 7.893,
      "gold": 10349.17018,
      "pb": 6.03,
      "pe": 25.13,
      "yield": 0.95,
      "date": "2007-10-11"
  },
  "2007-10-12": {
      "equity": 5428.2,
      "debt": 7.904,
      "gold": 10382.81451,
      "pb": 5.93,
      "pe": 24.67,
      "yield": 0.97,
      "date": "2007-10-12"
  },
  "2007-10-13": {
      "equity": 5428.2,
      "debt": 7.902,
      "gold": 10382.81451,
      "pb": 5.93,
      "pe": 24.67,
      "yield": 0.97,
      "date": "2007-10-13"
  },
  "2007-10-14": {
      "equity": 5428.2,
      "debt": 7.902,
      "gold": 10382.81451,
      "pb": 5.93,
      "pe": 24.67,
      "yield": 0.97,
      "date": "2007-10-14"
  },
  "2007-10-15": {
      "equity": 5670.4,
      "debt": 7.908,
      "gold": 10480.77038,
      "pb": 6.19,
      "pe": 25.77,
      "yield": 0.93,
      "date": "2007-10-15"
  },
  "2007-10-16": {
      "equity": 5668,
      "debt": 7.912,
      "gold": 10574.35228,
      "pb": 5.9,
      "pe": 25.71,
      "yield": 0.95,
      "date": "2007-10-16"
  },
  "2007-10-17": {
      "equity": 5559.3,
      "debt": 7.927,
      "gold": 10585.77047,
      "pb": 5.78,
      "pe": 25.2,
      "yield": 0.97,
      "date": "2007-10-17"
  },
  "2007-10-18": {
      "equity": 5351,
      "debt": 7.914,
      "gold": 10635.81368,
      "pb": 5.6,
      "pe": 24.33,
      "yield": 1.01,
      "date": "2007-10-18"
  },
  "2007-10-19": {
      "equity": 5215.3,
      "debt": 7.898,
      "gold": 10767.88303,
      "pb": 5.45,
      "pe": 23.46,
      "yield": 1.03,
      "date": "2007-10-19"
  },
  "2007-10-20": {
      "equity": 5215.3,
      "debt": 7.898,
      "gold": 10767.88303,
      "pb": 5.45,
      "pe": 23.46,
      "yield": 1.03,
      "date": "2007-10-20"
  },
  "2007-10-21": {
      "equity": 5215.3,
      "debt": 7.898,
      "gold": 10767.88303,
      "pb": 5.45,
      "pe": 23.46,
      "yield": 1.03,
      "date": "2007-10-21"
  },
  "2007-10-22": {
      "equity": 5184,
      "debt": 7.863,
      "gold": 10633.22457,
      "pb": 5.42,
      "pe": 23.28,
      "yield": 1.04,
      "date": "2007-10-22"
  },
  "2007-10-23": {
      "equity": 5473.7,
      "debt": 7.823,
      "gold": 10586.48301,
      "pb": 5.72,
      "pe": 24.53,
      "yield": 0.98,
      "date": "2007-10-23"
  },
  "2007-10-24": {
      "equity": 5496.1,
      "debt": 7.831,
      "gold": 10589.72821,
      "pb": 5.75,
      "pe": 24.66,
      "yield": 0.98,
      "date": "2007-10-24"
  },
  "2007-10-25": {
      "equity": 5568.9,
      "debt": 7.819,
      "gold": 10668.36452,
      "pb": 5.82,
      "pe": 25.01,
      "yield": 0.97,
      "date": "2007-10-25"
  },
  "2007-10-26": {
      "equity": 5702.3,
      "debt": 7.822,
      "gold": 10794.29972,
      "pb": 5.96,
      "pe": 25.57,
      "yield": 0.94,
      "date": "2007-10-26"
  },
  "2007-10-27": {
      "equity": 5702.3,
      "debt": 7.823,
      "gold": 10794.29972,
      "pb": 5.96,
      "pe": 25.57,
      "yield": 0.94,
      "date": "2007-10-27"
  },
  "2007-10-28": {
      "equity": 5702.3,
      "debt": 7.823,
      "gold": 10794.29972,
      "pb": 5.96,
      "pe": 25.57,
      "yield": 0.94,
      "date": "2007-10-28"
  },
  "2007-10-29": {
      "equity": 5905.9,
      "debt": 7.816,
      "gold": 10981.97852,
      "pb": 6.18,
      "pe": 26.35,
      "yield": 0.91,
      "date": "2007-10-29"
  },
  "2007-10-30": {
      "equity": 5868.7,
      "debt": 7.86,
      "gold": 10900.14286,
      "pb": 6.15,
      "pe": 26.01,
      "yield": 0.92,
      "date": "2007-10-30"
  },
  "2007-10-31": {
      "equity": 5900.6,
      "debt": 7.839,
      "gold": 10868.28692,
      "pb": 6.18,
      "pe": 25.74,
      "yield": 0.91,
      "date": "2007-10-31"
  },
  "2007-11-01": {
      "equity": 5866.4,
      "debt": 7.859,
      "gold": 11022.54361,
      "pb": 6.15,
      "pe": 25.65,
      "yield": 0.92,
      "date": "2007-11-01"
  },
  "2007-11-02": {
      "equity": 5932.4,
      "debt": 7.859,
      "gold": 10999.56966,
      "pb": 6.21,
      "pe": 25.94,
      "yield": 0.91,
      "date": "2007-11-02"
  },
  "2007-11-03": {
      "equity": 5932.4,
      "debt": 7.857,
      "gold": 10999.56966,
      "pb": 6.21,
      "pe": 25.94,
      "yield": 0.91,
      "date": "2007-11-03"
  },
  "2007-11-04": {
      "equity": 5932.4,
      "debt": 7.857,
      "gold": 10999.56966,
      "pb": 6.21,
      "pe": 25.94,
      "yield": 0.91,
      "date": "2007-11-04"
  },
  "2007-11-05": {
      "equity": 5847.3,
      "debt": 7.868,
      "gold": 11138.95483,
      "pb": 6.13,
      "pe": 25.57,
      "yield": 0.92,
      "date": "2007-11-05"
  },
  "2007-11-06": {
      "equity": 5786.5,
      "debt": 7.896,
      "gold": 11327.66363,
      "pb": 6.06,
      "pe": 25.31,
      "yield": 0.93,
      "date": "2007-11-06"
  },
  "2007-11-07": {
      "equity": 5782.3,
      "debt": 7.896,
      "gold": 11677.81795,
      "pb": 6.06,
      "pe": 25.29,
      "yield": 0.93,
      "date": "2007-11-07"
  },
  "2007-11-08": {
      "equity": 5698.7,
      "debt": 7.914,
      "gold": 11531.3427,
      "pb": 5.97,
      "pe": 24.92,
      "yield": 0.94,
      "date": "2007-11-08"
  },
  "2007-11-09": {
      "equity": 5663.2,
      "debt": 7.914,
      "gold": 11583.30129,
      "pb": 5.93,
      "pe": 24.77,
      "yield": 0.95,
      "date": "2007-11-09"
  },
  "2007-11-10": {
      "equity": 5663.2,
      "debt": 7.935,
      "gold": 11583.30129,
      "pb": 5.93,
      "pe": 24.77,
      "yield": 0.95,
      "date": "2007-11-10"
  },
  "2007-11-11": {
      "equity": 5663.2,
      "debt": 7.935,
      "gold": 11583.30129,
      "pb": 5.93,
      "pe": 24.77,
      "yield": 0.95,
      "date": "2007-11-11"
  },
  "2007-11-12": {
      "equity": 5617.1,
      "debt": 7.923,
      "gold": 11338.77846,
      "pb": 5.89,
      "pe": 24.57,
      "yield": 0.96,
      "date": "2007-11-12"
  },
  "2007-11-13": {
      "equity": 5695.4,
      "debt": 7.887,
      "gold": 11176.24297,
      "pb": 5.97,
      "pe": 24.92,
      "yield": 0.94,
      "date": "2007-11-13"
  },
  "2007-11-14": {
      "equity": 5937.9,
      "debt": 7.875,
      "gold": 11205.93661,
      "pb": 6.22,
      "pe": 25.98,
      "yield": 0.9,
      "date": "2007-11-14"
  },
  "2007-11-15": {
      "equity": 5912.1,
      "debt": 7.883,
      "gold": 11170.47567,
      "pb": 6.2,
      "pe": 25.87,
      "yield": 0.91,
      "date": "2007-11-15"
  },
  "2007-11-16": {
      "equity": 5906.8,
      "debt": 7.881,
      "gold": 10961.94995,
      "pb": 6.2,
      "pe": 25.86,
      "yield": 0.91,
      "date": "2007-11-16"
  },
  "2007-11-17": {
      "equity": 5906.8,
      "debt": 7.881,
      "gold": 10961.94995,
      "pb": 6.2,
      "pe": 25.86,
      "yield": 0.91,
      "date": "2007-11-17"
  },
  "2007-11-18": {
      "equity": 5906.8,
      "debt": 7.881,
      "gold": 10961.94995,
      "pb": 6.2,
      "pe": 25.86,
      "yield": 0.91,
      "date": "2007-11-18"
  },
  "2007-11-19": {
      "equity": 5907.6,
      "debt": 7.875,
      "gold": 10941.8473,
      "pb": 6.2,
      "pe": 25.87,
      "yield": 0.91,
      "date": "2007-11-19"
  },
  "2007-11-20": {
      "equity": 5780.9,
      "debt": 7.875,
      "gold": 10964.43324,
      "pb": 6.06,
      "pe": 25.32,
      "yield": 0.93,
      "date": "2007-11-20"
  },
  "2007-11-21": {
      "equity": 5561,
      "debt": 7.886,
      "gold": 11064.84065,
      "pb": 5.83,
      "pe": 24.35,
      "yield": 0.97,
      "date": "2007-11-21"
  },
  "2007-11-22": {
      "equity": 5519.3,
      "debt": 7.884,
      "gold": 11185.00503,
      "pb": 5.79,
      "pe": 24.17,
      "yield": 0.97,
      "date": "2007-11-22"
  },
  "2007-11-23": {
      "equity": 5608.6,
      "debt": 7.89,
      "gold": 11354.3484,
      "pb": 5.88,
      "pe": 24.53,
      "yield": 0.96,
      "date": "2007-11-23"
  },
  "2007-11-24": {
      "equity": 5608.6,
      "debt": 7.89,
      "gold": 11354.3484,
      "pb": 5.88,
      "pe": 24.53,
      "yield": 0.96,
      "date": "2007-11-24"
  },
  "2007-11-25": {
      "equity": 5608.6,
      "debt": 7.89,
      "gold": 11354.3484,
      "pb": 5.88,
      "pe": 24.53,
      "yield": 0.96,
      "date": "2007-11-25"
  },
  "2007-11-26": {
      "equity": 5731.7,
      "debt": 7.89,
      "gold": 11737.20524,
      "pb": 6.01,
      "pe": 25.07,
      "yield": 0.94,
      "date": "2007-11-26"
  },
  "2007-11-27": {
      "equity": 5698.1,
      "debt": 7.896,
      "gold": 11562.00286,
      "pb": 5.98,
      "pe": 24.92,
      "yield": 0.94,
      "date": "2007-11-27"
  },
  "2007-11-28": {
      "equity": 5617.5,
      "debt": 7.922,
      "gold": 11179.99612,
      "pb": 5.89,
      "pe": 24.57,
      "yield": 0.96,
      "date": "2007-11-28"
  },
  "2007-11-29": {
      "equity": 5634.6,
      "debt": 7.923,
      "gold": 11321.63177,
      "pb": 5.91,
      "pe": 24.65,
      "yield": 0.95,
      "date": "2007-11-29"
  },
  "2007-11-30": {
      "equity": 5762.7,
      "debt": 7.905,
      "gold": 11104.26992,
      "pb": 6.05,
      "pe": 25.21,
      "yield": 0.93,
      "date": "2007-11-30"
  },
  "2007-12-01": {
      "equity": 5762.7,
      "debt": 7.908,
      "gold": 11104.26992,
      "pb": 6.05,
      "pe": 25.21,
      "yield": 0.93,
      "date": "2007-12-01"
  },
  "2007-12-02": {
      "equity": 5762.7,
      "debt": 7.908,
      "gold": 11104.26992,
      "pb": 6.05,
      "pe": 25.21,
      "yield": 0.93,
      "date": "2007-12-02"
  },
  "2007-12-03": {
      "equity": 5865,
      "debt": 7.881,
      "gold": 10918.78516,
      "pb": 6.15,
      "pe": 25.66,
      "yield": 0.91,
      "date": "2007-12-03"
  },
  "2007-12-04": {
      "equity": 5858.3,
      "debt": 7.883,
      "gold": 10987.03681,
      "pb": 6.15,
      "pe": 25.63,
      "yield": 0.92,
      "date": "2007-12-04"
  },
  "2007-12-05": {
      "equity": 5940,
      "debt": 7.869,
      "gold": 11167.08937,
      "pb": 6.23,
      "pe": 25.98,
      "yield": 0.9,
      "date": "2007-12-05"
  },
  "2007-12-06": {
      "equity": 5954.7,
      "debt": 7.878,
      "gold": 10985.6576,
      "pb": 6.25,
      "pe": 26.05,
      "yield": 0.9,
      "date": "2007-12-06"
  },
  "2007-12-07": {
      "equity": 5974.3,
      "debt": 7.874,
      "gold": 11116.29835,
      "pb": 6.27,
      "pe": 26.14,
      "yield": 0.9,
      "date": "2007-12-07"
  },
  "2007-12-08": {
      "equity": 5974.3,
      "debt": 7.869,
      "gold": 11116.29835,
      "pb": 6.27,
      "pe": 26.14,
      "yield": 0.9,
      "date": "2007-12-08"
  },
  "2007-12-09": {
      "equity": 5974.3,
      "debt": 7.869,
      "gold": 11116.29835,
      "pb": 6.27,
      "pe": 26.14,
      "yield": 0.9,
      "date": "2007-12-09"
  },
  "2007-12-10": {
      "equity": 5960.6,
      "debt": 7.877,
      "gold": 11119.77284,
      "pb": 6.26,
      "pe": 26.08,
      "yield": 0.9,
      "date": "2007-12-10"
  },
  "2007-12-11": {
      "equity": 6097.2,
      "debt": 7.883,
      "gold": 11195.80945,
      "pb": 6.4,
      "pe": 26.68,
      "yield": 0.88,
      "date": "2007-12-11"
  },
  "2007-12-12": {
      "equity": 6159.3,
      "debt": 7.878,
      "gold": 11215.4747,
      "pb": 6.44,
      "pe": 27.69,
      "yield": 0.83,
      "date": "2007-12-12"
  },
  "2007-12-13": {
      "equity": 6058.1,
      "debt": 7.885,
      "gold": 11244.87204,
      "pb": 6.33,
      "pe": 27.23,
      "yield": 0.85,
      "date": "2007-12-13"
  },
  "2007-12-14": {
      "equity": 6047.7,
      "debt": 7.9,
      "gold": 11050.79807,
      "pb": 6.32,
      "pe": 27.19,
      "yield": 0.85,
      "date": "2007-12-14"
  },
  "2007-12-15": {
      "equity": 6047.7,
      "debt": 7.897,
      "gold": 11050.79807,
      "pb": 6.32,
      "pe": 27.19,
      "yield": 0.85,
      "date": "2007-12-15"
  },
  "2007-12-16": {
      "equity": 6047.7,
      "debt": 7.897,
      "gold": 11050.79807,
      "pb": 6.32,
      "pe": 27.19,
      "yield": 0.85,
      "date": "2007-12-16"
  },
  "2007-12-17": {
      "equity": 5777,
      "debt": 7.898,
      "gold": 10977.94317,
      "pb": 6.01,
      "pe": 25.97,
      "yield": 0.89,
      "date": "2007-12-17"
  },
  "2007-12-18": {
      "equity": 5742.3,
      "debt": 7.892,
      "gold": 11112.58752,
      "pb": 5.97,
      "pe": 25.81,
      "yield": 0.9,
      "date": "2007-12-18"
  },
  "2007-12-19": {
      "equity": 5751.1,
      "debt": 7.882,
      "gold": 11186.22551,
      "pb": 5.98,
      "pe": 25.85,
      "yield": 0.89,
      "date": "2007-12-19"
  },
  "2007-12-20": {
      "equity": 5766.5,
      "debt": 7.871,
      "gold": 11152.30604,
      "pb": 6,
      "pe": 25.92,
      "yield": 0.89,
      "date": "2007-12-20"
  },
  "2007-12-21": {
      "equity": 5766.5,
      "debt": 7.871,
      "gold": 11201.12877,
      "pb": 6,
      "pe": 25.92,
      "yield": 0.89,
      "date": "2007-12-21"
  },
  "2007-12-22": {
      "equity": 5766.5,
      "debt": 7.868,
      "gold": 11201.12877,
      "pb": 6,
      "pe": 25.92,
      "yield": 0.89,
      "date": "2007-12-22"
  },
  "2007-12-23": {
      "equity": 5766.5,
      "debt": 7.868,
      "gold": 11201.12877,
      "pb": 6,
      "pe": 25.92,
      "yield": 0.89,
      "date": "2007-12-23"
  },
  "2007-12-24": {
      "equity": 5985.1,
      "debt": 7.864,
      "gold": 11275.10538,
      "pb": 6.23,
      "pe": 26.91,
      "yield": 0.86,
      "date": "2007-12-24"
  },
  "2007-12-25": {
      "equity": 5985.1,
      "debt": 7.864,
      "gold": 11275.10538,
      "pb": 6.23,
      "pe": 26.91,
      "yield": 0.86,
      "date": "2007-12-25"
  },
  "2007-12-26": {
      "equity": 6070.7,
      "debt": 7.87,
      "gold": 11275.10538,
      "pb": 6.32,
      "pe": 27.29,
      "yield": 0.85,
      "date": "2007-12-26"
  },
  "2007-12-27": {
      "equity": 6081.5,
      "debt": 7.856,
      "gold": 11436.51916,
      "pb": 6.33,
      "pe": 27.36,
      "yield": 0.85,
      "date": "2007-12-27"
  },
  "2007-12-28": {
      "equity": 6079.7,
      "debt": 7.811,
      "gold": 11524.68298,
      "pb": 6.33,
      "pe": 27.35,
      "yield": 0.85,
      "date": "2007-12-28"
  },
  "2007-12-29": {
      "equity": 6079.7,
      "debt": 7.81,
      "gold": 11524.68298,
      "pb": 6.33,
      "pe": 27.35,
      "yield": 0.85,
      "date": "2007-12-29"
  },
  "2007-12-30": {
      "equity": 6079.7,
      "debt": 7.81,
      "gold": 11524.68298,
      "pb": 6.33,
      "pe": 27.35,
      "yield": 0.85,
      "date": "2007-12-30"
  },
  "2007-12-31": {
      "equity": 6138.6,
      "debt": 7.791,
      "gold": 11630.06402,
      "pb": 6.39,
      "pe": 27.62,
      "yield": 0.84,
      "date": "2007-12-31"
  },
  "2008-01-01": {
      "equity": 6144.3,
      "debt": 7.746,
      "gold": 11630.06402,
      "pb": 6.4,
      "pe": 27.64,
      "yield": 0.84,
      "date": "2008-01-01"
  },
  "2008-01-02": {
      "equity": 6179.4,
      "debt": 7.746,
      "gold": 11693.24327,
      "pb": 6.43,
      "pe": 27.8,
      "yield": 0.83,
      "date": "2008-01-02"
  },
  "2008-01-03": {
      "equity": 6178.5,
      "debt": 7.755,
      "gold": 12028.12043,
      "pb": 6.43,
      "pe": 27.8,
      "yield": 0.83,
      "date": "2008-01-03"
  },
  "2008-01-04": {
      "equity": 6274.3,
      "debt": 7.706,
      "gold": 11910.63334,
      "pb": 6.53,
      "pe": 28.23,
      "yield": 0.82,
      "date": "2008-01-04"
  },
  "2008-01-05": {
      "equity": 6274.3,
      "debt": 7.707,
      "gold": 11910.63334,
      "pb": 6.53,
      "pe": 28.23,
      "yield": 0.82,
      "date": "2008-01-05"
  },
  "2008-01-06": {
      "equity": 6274.3,
      "debt": 7.707,
      "gold": 11910.63334,
      "pb": 6.53,
      "pe": 28.23,
      "yield": 0.82,
      "date": "2008-01-06"
  },
  "2008-01-07": {
      "equity": 6279.1,
      "debt": 7.66,
      "gold": 11885.73343,
      "pb": 6.54,
      "pe": 28.25,
      "yield": 0.82,
      "date": "2008-01-07"
  },
  "2008-01-08": {
      "equity": 6287.8,
      "debt": 7.673,
      "gold": 12097.8818,
      "pb": 6.55,
      "pe": 28.29,
      "yield": 0.82,
      "date": "2008-01-08"
  },
  "2008-01-09": {
      "equity": 6272,
      "debt": 7.654,
      "gold": 12302.90481,
      "pb": 6.53,
      "pe": 28.22,
      "yield": 0.82,
      "date": "2008-01-09"
  },
  "2008-01-10": {
      "equity": 6156.9,
      "debt": 7.586,
      "gold": 12114.82037,
      "pb": 6.41,
      "pe": 27.7,
      "yield": 0.83,
      "date": "2008-01-10"
  },
  "2008-01-11": {
      "equity": 6200.1,
      "debt": 7.563,
      "gold": 12388.1938,
      "pb": 6.46,
      "pe": 27.86,
      "yield": 0.83,
      "date": "2008-01-11"
  },
  "2008-01-12": {
      "equity": 6200.1,
      "debt": 7.563,
      "gold": 12388.1938,
      "pb": 6.46,
      "pe": 27.86,
      "yield": 0.83,
      "date": "2008-01-12"
  },
  "2008-01-13": {
      "equity": 6200.1,
      "debt": 7.563,
      "gold": 12388.1938,
      "pb": 6.46,
      "pe": 27.86,
      "yield": 0.83,
      "date": "2008-01-13"
  },
  "2008-01-14": {
      "equity": 6206.8,
      "debt": 7.544,
      "gold": 12632.61786,
      "pb": 6.47,
      "pe": 27.89,
      "yield": 0.83,
      "date": "2008-01-14"
  },
  "2008-01-15": {
      "equity": 6074.2,
      "debt": 7.556,
      "gold": 12531.08873,
      "pb": 6.33,
      "pe": 27.3,
      "yield": 0.85,
      "date": "2008-01-15"
  },
  "2008-01-16": {
      "equity": 5935.7,
      "debt": 7.563,
      "gold": 12211.09367,
      "pb": 6.18,
      "pe": 26.67,
      "yield": 0.87,
      "date": "2008-01-16"
  },
  "2008-01-17": {
      "equity": 5913.2,
      "debt": 7.554,
      "gold": 12223.06214,
      "pb": 6.16,
      "pe": 25.47,
      "yield": 0.87,
      "date": "2008-01-17"
  },
  "2008-01-18": {
      "equity": 5705.3,
      "debt": 7.551,
      "gold": 12098.65077,
      "pb": 5.94,
      "pe": 24.49,
      "yield": 0.9,
      "date": "2008-01-18"
  },
  "2008-01-19": {
      "equity": 5705.3,
      "debt": 7.551,
      "gold": 12098.65077,
      "pb": 5.94,
      "pe": 24.49,
      "yield": 0.9,
      "date": "2008-01-19"
  },
  "2008-01-20": {
      "equity": 5705.3,
      "debt": 7.551,
      "gold": 12098.65077,
      "pb": 5.94,
      "pe": 24.49,
      "yield": 0.9,
      "date": "2008-01-20"
  },
  "2008-01-21": {
      "equity": 5208.8,
      "debt": 7.54,
      "gold": 12198.08462,
      "pb": 5.43,
      "pe": 22.33,
      "yield": 0.99,
      "date": "2008-01-21"
  },
  "2008-01-22": {
      "equity": 4899.3,
      "debt": 7.467,
      "gold": 12005.88017,
      "pb": 5.1,
      "pe": 20.96,
      "yield": 1.05,
      "date": "2008-01-22"
  },
  "2008-01-23": {
      "equity": 5203.4,
      "debt": 7.385,
      "gold": 12396.53609,
      "pb": 5.42,
      "pe": 22.26,
      "yield": 0.99,
      "date": "2008-01-23"
  },
  "2008-01-24": {
      "equity": 5033.4,
      "debt": 7.379,
      "gold": 12412.03549,
      "pb": 5.24,
      "pe": 21.52,
      "yield": 1.02,
      "date": "2008-01-24"
  },
  "2008-01-25": {
      "equity": 5383.3,
      "debt": 7.431,
      "gold": 12792.11626,
      "pb": 5.61,
      "pe": 22.88,
      "yield": 0.95,
      "date": "2008-01-25"
  },
  "2008-01-26": {
      "equity": 5383.3,
      "debt": 7.431,
      "gold": 12792.11626,
      "pb": 5.61,
      "pe": 22.88,
      "yield": 0.95,
      "date": "2008-01-26"
  },
  "2008-01-27": {
      "equity": 5383.3,
      "debt": 7.431,
      "gold": 12792.11626,
      "pb": 5.61,
      "pe": 22.88,
      "yield": 0.95,
      "date": "2008-01-27"
  },
  "2008-01-28": {
      "equity": 5274.1,
      "debt": 7.438,
      "gold": 12732.62315,
      "pb": 5.52,
      "pe": 22.6,
      "yield": 0.97,
      "date": "2008-01-28"
  },
  "2008-01-29": {
      "equity": 5280.8,
      "debt": 7.542,
      "gold": 12883.80747,
      "pb": 5.53,
      "pe": 22.55,
      "yield": 0.97,
      "date": "2008-01-29"
  },
  "2008-01-30": {
      "equity": 5167.6,
      "debt": 7.559,
      "gold": 12835.39745,
      "pb": 5.41,
      "pe": 22.02,
      "yield": 0.99,
      "date": "2008-01-30"
  },
  "2008-01-31": {
      "equity": 5137.4,
      "debt": 7.529,
      "gold": 12831.32683,
      "pb": 5.38,
      "pe": 21.97,
      "yield": 0.99,
      "date": "2008-01-31"
  },
  "2008-02-01": {
      "equity": 5317.2,
      "debt": 7.487,
      "gold": 12953.62528,
      "pb": 5.57,
      "pe": 22.68,
      "yield": 0.96,
      "date": "2008-02-01"
  },
  "2008-02-02": {
      "equity": 5317.2,
      "debt": 7.496,
      "gold": 12953.62528,
      "pb": 5.57,
      "pe": 22.68,
      "yield": 0.96,
      "date": "2008-02-02"
  },
  "2008-02-03": {
      "equity": 5317.2,
      "debt": 7.496,
      "gold": 12953.62528,
      "pb": 5.57,
      "pe": 22.68,
      "yield": 0.96,
      "date": "2008-02-03"
  },
  "2008-02-04": {
      "equity": 5463.5,
      "debt": 7.515,
      "gold": 12515.48705,
      "pb": 5.72,
      "pe": 23.31,
      "yield": 0.94,
      "date": "2008-02-04"
  },
  "2008-02-05": {
      "equity": 5483.9,
      "debt": 7.518,
      "gold": 12414.34946,
      "pb": 5.74,
      "pe": 23.4,
      "yield": 0.93,
      "date": "2008-02-05"
  },
  "2008-02-06": {
      "equity": 5322.5,
      "debt": 7.488,
      "gold": 12430.79772,
      "pb": 5.58,
      "pe": 22.71,
      "yield": 0.96,
      "date": "2008-02-06"
  },
  "2008-02-07": {
      "equity": 5133.2,
      "debt": 7.473,
      "gold": 12666.06466,
      "pb": 5.38,
      "pe": 21.9,
      "yield": 1,
      "date": "2008-02-07"
  },
  "2008-02-08": {
      "equity": 5120.3,
      "debt": 7.47,
      "gold": 12778.49345,
      "pb": 5.36,
      "pe": 21.85,
      "yield": 1,
      "date": "2008-02-08"
  },
  "2008-02-09": {
      "equity": 5120.3,
      "debt": 7.464,
      "gold": 12778.49345,
      "pb": 5.36,
      "pe": 21.85,
      "yield": 1,
      "date": "2008-02-09"
  },
  "2008-02-10": {
      "equity": 5120.3,
      "debt": 7.464,
      "gold": 12778.49345,
      "pb": 5.36,
      "pe": 21.85,
      "yield": 1,
      "date": "2008-02-10"
  },
  "2008-02-11": {
      "equity": 4857,
      "debt": 7.439,
      "gold": 12958.85994,
      "pb": 5.09,
      "pe": 20.72,
      "yield": 1.05,
      "date": "2008-02-11"
  },
  "2008-02-12": {
      "equity": 4838.2,
      "debt": 7.453,
      "gold": 12883.72634,
      "pb": 5.07,
      "pe": 20.64,
      "yield": 1.06,
      "date": "2008-02-12"
  },
  "2008-02-13": {
      "equity": 4929.4,
      "debt": 7.455,
      "gold": 12699.09522,
      "pb": 5.16,
      "pe": 21.03,
      "yield": 1.04,
      "date": "2008-02-13"
  },
  "2008-02-14": {
      "equity": 5202,
      "debt": 7.464,
      "gold": 12724.81349,
      "pb": 5.45,
      "pe": 22.19,
      "yield": 0.98,
      "date": "2008-02-14"
  },
  "2008-02-15": {
      "equity": 5302.9,
      "debt": 7.503,
      "gold": 12730.30565,
      "pb": 5.56,
      "pe": 22.62,
      "yield": 0.96,
      "date": "2008-02-15"
  },
  "2008-02-16": {
      "equity": 5302.9,
      "debt": 7.512,
      "gold": 12730.30565,
      "pb": 5.56,
      "pe": 22.62,
      "yield": 0.96,
      "date": "2008-02-16"
  },
  "2008-02-17": {
      "equity": 5302.9,
      "debt": 7.512,
      "gold": 12730.30565,
      "pb": 5.56,
      "pe": 22.62,
      "yield": 0.96,
      "date": "2008-02-17"
  },
  "2008-02-18": {
      "equity": 5276.9,
      "debt": 7.543,
      "gold": 12700.54851,
      "pb": 5.53,
      "pe": 22.51,
      "yield": 0.97,
      "date": "2008-02-18"
  },
  "2008-02-19": {
      "equity": 5280.8,
      "debt": 7.562,
      "gold": 12908.81673,
      "pb": 5.53,
      "pe": 22.52,
      "yield": 0.97,
      "date": "2008-02-19"
  },
  "2008-02-20": {
      "equity": 5154.4,
      "debt": 7.645,
      "gold": 13114.50643,
      "pb": 5.4,
      "pe": 21.98,
      "yield": 0.99,
      "date": "2008-02-20"
  },
  "2008-02-21": {
      "equity": 5191.8,
      "debt": 7.583,
      "gold": 13281.23247,
      "pb": 5.44,
      "pe": 22.14,
      "yield": 0.98,
      "date": "2008-02-21"
  },
  "2008-02-22": {
      "equity": 5110.7,
      "debt": 7.679,
      "gold": 13360.83176,
      "pb": 5.35,
      "pe": 21.79,
      "yield": 1,
      "date": "2008-02-22"
  },
  "2008-02-23": {
      "equity": 5110.7,
      "debt": 7.692,
      "gold": 13360.83176,
      "pb": 5.35,
      "pe": 21.79,
      "yield": 1,
      "date": "2008-02-23"
  },
  "2008-02-24": {
      "equity": 5110.7,
      "debt": 7.692,
      "gold": 13360.83176,
      "pb": 5.35,
      "pe": 21.79,
      "yield": 1,
      "date": "2008-02-24"
  },
  "2008-02-25": {
      "equity": 5200.7,
      "debt": 7.64,
      "gold": 13357.14563,
      "pb": 5.45,
      "pe": 22.17,
      "yield": 0.98,
      "date": "2008-02-25"
  },
  "2008-02-26": {
      "equity": 5270,
      "debt": 7.631,
      "gold": 13147.06432,
      "pb": 5.52,
      "pe": 22.47,
      "yield": 0.97,
      "date": "2008-02-26"
  },
  "2008-02-27": {
      "equity": 5268.4,
      "debt": 7.629,
      "gold": 13451.48239,
      "pb": 5.52,
      "pe": 22.46,
      "yield": 0.97,
      "date": "2008-02-27"
  },
  "2008-02-28": {
      "equity": 5285.1,
      "debt": 7.602,
      "gold": 13462.375,
      "pb": 5.54,
      "pe": 22.53,
      "yield": 0.97,
      "date": "2008-02-28"
  },
  "2008-02-29": {
      "equity": 5223.5,
      "debt": 7.568,
      "gold": 13679.88853,
      "pb": 5.47,
      "pe": 22.27,
      "yield": 0.98,
      "date": "2008-02-29"
  },
  "2008-03-01": {
      "equity": 5223.5,
      "debt": 7.568,
      "gold": 13679.88853,
      "pb": 5.47,
      "pe": 22.27,
      "yield": 0.98,
      "date": "2008-03-01"
  },
  "2008-03-02": {
      "equity": 5223.5,
      "debt": 7.568,
      "gold": 13679.88853,
      "pb": 5.47,
      "pe": 22.27,
      "yield": 0.98,
      "date": "2008-03-02"
  },
  "2008-03-03": {
      "equity": 4953,
      "debt": 7.524,
      "gold": 13937.2899,
      "pb": 5.19,
      "pe": 21.12,
      "yield": 1.03,
      "date": "2008-03-03"
  },
  "2008-03-04": {
      "equity": 4864.2,
      "debt": 7.582,
      "gold": 13961.18097,
      "pb": 5.1,
      "pe": 20.74,
      "yield": 1.05,
      "date": "2008-03-04"
  },
  "2008-03-05": {
      "equity": 4921.4,
      "debt": 7.551,
      "gold": 13737.35339,
      "pb": 5.16,
      "pe": 20.98,
      "yield": 1.04,
      "date": "2008-03-05"
  },
  "2008-03-06": {
      "equity": 4921.4,
      "debt": 7.551,
      "gold": 14021.69703,
      "pb": 5.16,
      "pe": 20.98,
      "yield": 1.04,
      "date": "2008-03-06"
  },
  "2008-03-07": {
      "equity": 4771.6,
      "debt": 7.59,
      "gold": 13983.99619,
      "pb": 5,
      "pe": 20.35,
      "yield": 1.07,
      "date": "2008-03-07"
  },
  "2008-03-08": {
      "equity": 4771.6,
      "debt": 7.585,
      "gold": 13983.99619,
      "pb": 5,
      "pe": 20.35,
      "yield": 1.07,
      "date": "2008-03-08"
  },
  "2008-03-09": {
      "equity": 4771.6,
      "debt": 7.585,
      "gold": 13983.99619,
      "pb": 5,
      "pe": 20.35,
      "yield": 1.07,
      "date": "2008-03-09"
  },
  "2008-03-10": {
      "equity": 4800.4,
      "debt": 7.62,
      "gold": 13904.96129,
      "pb": 5.03,
      "pe": 20.47,
      "yield": 1.06,
      "date": "2008-03-10"
  },
  "2008-03-11": {
      "equity": 4865.9,
      "debt": 7.604,
      "gold": 13993.55544,
      "pb": 5.1,
      "pe": 20.75,
      "yield": 1.05,
      "date": "2008-03-11"
  },
  "2008-03-12": {
      "equity": 4872,
      "debt": 7.559,
      "gold": 13881.02083,
      "pb": 5.11,
      "pe": 20.78,
      "yield": 1.05,
      "date": "2008-03-12"
  },
  "2008-03-13": {
      "equity": 4623.6,
      "debt": 7.569,
      "gold": 14105.90663,
      "pb": 4.81,
      "pe": 19.72,
      "yield": 1.13,
      "date": "2008-03-13"
  },
  "2008-03-14": {
      "equity": 4745.8,
      "debt": 7.63,
      "gold": 14234.31807,
      "pb": 5.1,
      "pe": 20.68,
      "yield": 1.06,
      "date": "2008-03-14"
  },
  "2008-03-15": {
      "equity": 4745.8,
      "debt": 7.624,
      "gold": 14234.31807,
      "pb": 5.1,
      "pe": 20.68,
      "yield": 1.06,
      "date": "2008-03-15"
  },
  "2008-03-16": {
      "equity": 4745.8,
      "debt": 7.624,
      "gold": 14234.31807,
      "pb": 5.1,
      "pe": 20.68,
      "yield": 1.06,
      "date": "2008-03-16"
  },
  "2008-03-17": {
      "equity": 4503.1,
      "debt": 7.633,
      "gold": 14706.52745,
      "pb": 4.84,
      "pe": 19.62,
      "yield": 1.12,
      "date": "2008-03-17"
  },
  "2008-03-18": {
      "equity": 4533,
      "debt": 7.593,
      "gold": 14371.65735,
      "pb": 4.87,
      "pe": 19.75,
      "yield": 1.11,
      "date": "2008-03-18"
  },
  "2008-03-19": {
      "equity": 4573.9,
      "debt": 7.621,
      "gold": 14193.53428,
      "pb": 4.91,
      "pe": 19.93,
      "yield": 1.1,
      "date": "2008-03-19"
  },
  "2008-03-20": {
      "equity": 4573.9,
      "debt": 7.621,
      "gold": 13027.67597,
      "pb": 4.91,
      "pe": 19.93,
      "yield": 1.1,
      "date": "2008-03-20"
  },
  "2008-03-21": {
      "equity": 4573.9,
      "debt": 7.621,
      "gold": 13027.67597,
      "pb": 4.91,
      "pe": 19.93,
      "yield": 1.1,
      "date": "2008-03-21"
  },
  "2008-03-22": {
      "equity": 4573.9,
      "debt": 7.621,
      "gold": 13027.67597,
      "pb": 4.91,
      "pe": 19.93,
      "yield": 1.1,
      "date": "2008-03-22"
  },
  "2008-03-23": {
      "equity": 4573.9,
      "debt": 7.621,
      "gold": 13027.67597,
      "pb": 4.91,
      "pe": 19.93,
      "yield": 1.1,
      "date": "2008-03-23"
  },
  "2008-03-24": {
      "equity": 4609.8,
      "debt": 7.72,
      "gold": 13027.67597,
      "pb": 4.95,
      "pe": 20.09,
      "yield": 1.09,
      "date": "2008-03-24"
  },
  "2008-03-25": {
      "equity": 4877.5,
      "debt": 7.723,
      "gold": 13155.71703,
      "pb": 5.24,
      "pe": 21.25,
      "yield": 1.03,
      "date": "2008-03-25"
  },
  "2008-03-26": {
      "equity": 4828.8,
      "debt": 7.768,
      "gold": 13391.68945,
      "pb": 5.19,
      "pe": 21.05,
      "yield": 1.04,
      "date": "2008-03-26"
  },
  "2008-03-27": {
      "equity": 4830.2,
      "debt": 7.773,
      "gold": 13411.19949,
      "pb": 5.19,
      "pe": 21.05,
      "yield": 1.04,
      "date": "2008-03-27"
  },
  "2008-03-28": {
      "equity": 4942,
      "debt": 7.874,
      "gold": 13296.52728,
      "pb": 5.31,
      "pe": 21.54,
      "yield": 1.02,
      "date": "2008-03-28"
  },
  "2008-03-29": {
      "equity": 4942,
      "debt": 7.874,
      "gold": 13296.52728,
      "pb": 5.31,
      "pe": 21.54,
      "yield": 1.02,
      "date": "2008-03-29"
  },
  "2008-03-30": {
      "equity": 4942,
      "debt": 7.874,
      "gold": 13296.52728,
      "pb": 5.31,
      "pe": 21.54,
      "yield": 1.02,
      "date": "2008-03-30"
  },
  "2008-03-31": {
      "equity": 4734.5,
      "debt": 7.938,
      "gold": 13260.58661,
      "pb": 5.09,
      "pe": 20.63,
      "yield": 1.06,
      "date": "2008-03-31"
  },
  "2008-04-01": {
      "equity": 4739.5,
      "debt": 7.938,
      "gold": 12691.11272,
      "pb": 5.09,
      "pe": 20.66,
      "yield": 1.06,
      "date": "2008-04-01"
  },
  "2008-04-02": {
      "equity": 4754.2,
      "debt": 7.931,
      "gold": 12588.0139,
      "pb": 5.11,
      "pe": 20.72,
      "yield": 1.06,
      "date": "2008-04-02"
  },
  "2008-04-03": {
      "equity": 4771.6,
      "debt": 7.887,
      "gold": 12683.45121,
      "pb": 5.13,
      "pe": 20.8,
      "yield": 1.05,
      "date": "2008-04-03"
  },
  "2008-04-04": {
      "equity": 4647,
      "debt": 7.985,
      "gold": 12763.13162,
      "pb": 4.99,
      "pe": 20.25,
      "yield": 1.08,
      "date": "2008-04-04"
  },
  "2008-04-05": {
      "equity": 4647,
      "debt": 7.985,
      "gold": 12763.13162,
      "pb": 4.99,
      "pe": 20.25,
      "yield": 1.08,
      "date": "2008-04-05"
  },
  "2008-04-06": {
      "equity": 4647,
      "debt": 7.985,
      "gold": 12763.13162,
      "pb": 4.99,
      "pe": 20.25,
      "yield": 1.08,
      "date": "2008-04-06"
  },
  "2008-04-07": {
      "equity": 4761.2,
      "debt": 7.99,
      "gold": 12886.68936,
      "pb": 5.12,
      "pe": 20.75,
      "yield": 1.05,
      "date": "2008-04-07"
  },
  "2008-04-08": {
      "equity": 4709.6,
      "debt": 7.945,
      "gold": 13001.43565,
      "pb": 5.06,
      "pe": 20.53,
      "yield": 1.07,
      "date": "2008-04-08"
  },
  "2008-04-09": {
      "equity": 4747,
      "debt": 7.925,
      "gold": 12798.6737,
      "pb": 5.1,
      "pe": 20.69,
      "yield": 1.06,
      "date": "2008-04-09"
  },
  "2008-04-10": {
      "equity": 4733,
      "debt": 7.964,
      "gold": 13152.23196,
      "pb": 5.09,
      "pe": 20.63,
      "yield": 1.06,
      "date": "2008-04-10"
  },
  "2008-04-11": {
      "equity": 4777.8,
      "debt": 8.034,
      "gold": 13048.63931,
      "pb": 5.13,
      "pe": 20.82,
      "yield": 1.05,
      "date": "2008-04-11"
  },
  "2008-04-12": {
      "equity": 4777.8,
      "debt": 8.034,
      "gold": 13048.63931,
      "pb": 5.13,
      "pe": 20.82,
      "yield": 1.05,
      "date": "2008-04-12"
  },
  "2008-04-13": {
      "equity": 4777.8,
      "debt": 8.034,
      "gold": 13048.63931,
      "pb": 5.13,
      "pe": 20.82,
      "yield": 1.05,
      "date": "2008-04-13"
  },
  "2008-04-14": {
      "equity": 4777.8,
      "debt": 8.034,
      "gold": 12939.37106,
      "pb": 5.13,
      "pe": 20.82,
      "yield": 1.05,
      "date": "2008-04-14"
  },
  "2008-04-15": {
      "equity": 4879.6,
      "debt": 8.018,
      "gold": 13148.26011,
      "pb": 5.24,
      "pe": 21.26,
      "yield": 1.03,
      "date": "2008-04-15"
  },
  "2008-04-16": {
      "equity": 4887.3,
      "debt": 8.08,
      "gold": 13145.92144,
      "pb": 5.25,
      "pe": 21.29,
      "yield": 1.03,
      "date": "2008-04-16"
  },
  "2008-04-17": {
      "equity": 4958.4,
      "debt": 8.118,
      "gold": 13353.12087,
      "pb": 5.33,
      "pe": 21.6,
      "yield": 1.01,
      "date": "2008-04-17"
  },
  "2008-04-18": {
      "equity": 4958.4,
      "debt": 8.118,
      "gold": 13223.30905,
      "pb": 5.33,
      "pe": 21.6,
      "yield": 1.01,
      "date": "2008-04-18"
  },
  "2008-04-19": {
      "equity": 4958.4,
      "debt": 8.118,
      "gold": 13223.30905,
      "pb": 5.33,
      "pe": 21.6,
      "yield": 1.01,
      "date": "2008-04-19"
  },
  "2008-04-20": {
      "equity": 4958.4,
      "debt": 8.118,
      "gold": 13223.30905,
      "pb": 5.33,
      "pe": 21.6,
      "yield": 1.01,
      "date": "2008-04-20"
  },
  "2008-04-21": {
      "equity": 5037,
      "debt": 8.178,
      "gold": 12880.48466,
      "pb": 5.41,
      "pe": 21.92,
      "yield": 1,
      "date": "2008-04-21"
  },
  "2008-04-22": {
      "equity": 5049.3,
      "debt": 8.165,
      "gold": 12973.54803,
      "pb": 5.43,
      "pe": 21.8,
      "yield": 0.99,
      "date": "2008-04-22"
  },
  "2008-04-23": {
      "equity": 5022.8,
      "debt": 8.186,
      "gold": 12952.15789,
      "pb": 5.4,
      "pe": 21.69,
      "yield": 1,
      "date": "2008-04-23"
  },
  "2008-04-24": {
      "equity": 4999.8,
      "debt": 8.194,
      "gold": 12764.82125,
      "pb": 5.37,
      "pe": 21.57,
      "yield": 1,
      "date": "2008-04-24"
  },
  "2008-04-25": {
      "equity": 5111.7,
      "debt": 8.175,
      "gold": 12504.78492,
      "pb": 5.49,
      "pe": 21.96,
      "yield": 0.98,
      "date": "2008-04-25"
  },
  "2008-04-26": {
      "equity": 5111.7,
      "debt": 8.175,
      "gold": 12504.78492,
      "pb": 5.49,
      "pe": 21.96,
      "yield": 0.98,
      "date": "2008-04-26"
  },
  "2008-04-27": {
      "equity": 5111.7,
      "debt": 8.175,
      "gold": 12504.78492,
      "pb": 5.49,
      "pe": 21.96,
      "yield": 0.98,
      "date": "2008-04-27"
  },
  "2008-04-28": {
      "equity": 5089.6,
      "debt": 8.141,
      "gold": 12656.95339,
      "pb": 5.47,
      "pe": 21.85,
      "yield": 0.99,
      "date": "2008-04-28"
  },
  "2008-04-29": {
      "equity": 5195.5,
      "debt": 8.004,
      "gold": 12640.17708,
      "pb": 5.58,
      "pe": 22.31,
      "yield": 0.97,
      "date": "2008-04-29"
  },
  "2008-04-30": {
      "equity": 5165.9,
      "debt": 7.956,
      "gold": 12424.19443,
      "pb": 5.55,
      "pe": 22.17,
      "yield": 0.97,
      "date": "2008-04-30"
  },
  "2008-05-01": {
      "equity": 5165.9,
      "debt": 7.956,
      "gold": 12363.3468,
      "pb": 5.55,
      "pe": 22.17,
      "yield": 0.97,
      "date": "2008-05-01"
  },
  "2008-05-02": {
      "equity": 5228.2,
      "debt": 7.903,
      "gold": 12253.50712,
      "pb": 5.62,
      "pe": 22.42,
      "yield": 0.96,
      "date": "2008-05-02"
  },
  "2008-05-03": {
      "equity": 5228.2,
      "debt": 7.903,
      "gold": 12253.50712,
      "pb": 5.62,
      "pe": 22.42,
      "yield": 0.96,
      "date": "2008-05-03"
  },
  "2008-05-04": {
      "equity": 5228.2,
      "debt": 7.903,
      "gold": 12253.50712,
      "pb": 5.62,
      "pe": 22.42,
      "yield": 0.96,
      "date": "2008-05-04"
  },
  "2008-05-05": {
      "equity": 5192.2,
      "debt": 7.876,
      "gold": 12253.50712,
      "pb": 5.58,
      "pe": 22.2,
      "yield": 0.97,
      "date": "2008-05-05"
  },
  "2008-05-06": {
      "equity": 5144.6,
      "debt": 7.89,
      "gold": 12677.80031,
      "pb": 5.53,
      "pe": 22.02,
      "yield": 0.97,
      "date": "2008-05-06"
  },
  "2008-05-07": {
      "equity": 5135.5,
      "debt": 7.925,
      "gold": 12794.22918,
      "pb": 5.52,
      "pe": 21.98,
      "yield": 0.98,
      "date": "2008-05-07"
  },
  "2008-05-08": {
      "equity": 5081.7,
      "debt": 7.944,
      "gold": 12843.99372,
      "pb": 5.47,
      "pe": 21.75,
      "yield": 0.99,
      "date": "2008-05-08"
  },
  "2008-05-09": {
      "equity": 4982.6,
      "debt": 7.917,
      "gold": 13016.36008,
      "pb": 5.36,
      "pe": 21.32,
      "yield": 1.01,
      "date": "2008-05-09"
  },
  "2008-05-10": {
      "equity": 4982.6,
      "debt": 7.917,
      "gold": 13016.36008,
      "pb": 5.36,
      "pe": 21.32,
      "yield": 1.01,
      "date": "2008-05-10"
  },
  "2008-05-11": {
      "equity": 4982.6,
      "debt": 7.917,
      "gold": 13016.36008,
      "pb": 5.36,
      "pe": 21.32,
      "yield": 1.01,
      "date": "2008-05-11"
  },
  "2008-05-12": {
      "equity": 5012.6,
      "debt": 7.897,
      "gold": 13068.0788,
      "pb": 5.39,
      "pe": 21.45,
      "yield": 1,
      "date": "2008-05-12"
  },
  "2008-05-13": {
      "equity": 4957.8,
      "debt": 7.914,
      "gold": 13076.34703,
      "pb": 5.33,
      "pe": 21.21,
      "yield": 1.01,
      "date": "2008-05-13"
  },
  "2008-05-14": {
      "equity": 5011.7,
      "debt": 7.938,
      "gold": 12913.26478,
      "pb": 5.39,
      "pe": 21.44,
      "yield": 1,
      "date": "2008-05-14"
  },
  "2008-05-15": {
      "equity": 5115.2,
      "debt": 7.98,
      "gold": 13013.84151,
      "pb": 5.5,
      "pe": 21.84,
      "yield": 0.98,
      "date": "2008-05-15"
  },
  "2008-05-16": {
      "equity": 5157.7,
      "debt": 8.011,
      "gold": 13279.91675,
      "pb": 5.55,
      "pe": 21.94,
      "yield": 0.97,
      "date": "2008-05-16"
  },
  "2008-05-17": {
      "equity": 5157.7,
      "debt": 8.011,
      "gold": 13279.91675,
      "pb": 5.55,
      "pe": 21.94,
      "yield": 0.97,
      "date": "2008-05-17"
  },
  "2008-05-18": {
      "equity": 5157.7,
      "debt": 8.011,
      "gold": 13279.91675,
      "pb": 5.55,
      "pe": 21.94,
      "yield": 0.97,
      "date": "2008-05-18"
  },
  "2008-05-19": {
      "equity": 5157.7,
      "debt": 8.011,
      "gold": 13636.30046,
      "pb": 5.55,
      "pe": 21.94,
      "yield": 0.97,
      "date": "2008-05-19"
  },
  "2008-05-20": {
      "equity": 5104.9,
      "debt": 8.003,
      "gold": 13653.23198,
      "pb": 5.49,
      "pe": 21.72,
      "yield": 0.98,
      "date": "2008-05-20"
  },
  "2008-05-21": {
      "equity": 5117.6,
      "debt": 8.058,
      "gold": 13995.97171,
      "pb": 5.5,
      "pe": 21.77,
      "yield": 0.98,
      "date": "2008-05-21"
  },
  "2008-05-22": {
      "equity": 5025.4,
      "debt": 8.113,
      "gold": 14040.23351,
      "pb": 5.4,
      "pe": 21.38,
      "yield": 1,
      "date": "2008-05-22"
  },
  "2008-05-23": {
      "equity": 4946.5,
      "debt": 8.088,
      "gold": 13907.1906,
      "pb": 5.32,
      "pe": 21.03,
      "yield": 1.01,
      "date": "2008-05-23"
  },
  "2008-05-24": {
      "equity": 4946.5,
      "debt": 8.088,
      "gold": 13907.1906,
      "pb": 5.32,
      "pe": 21.03,
      "yield": 1.01,
      "date": "2008-05-24"
  },
  "2008-05-25": {
      "equity": 4946.5,
      "debt": 8.088,
      "gold": 13907.1906,
      "pb": 5.32,
      "pe": 21.03,
      "yield": 1.01,
      "date": "2008-05-25"
  },
  "2008-05-26": {
      "equity": 4875,
      "debt": 8.114,
      "gold": 13907.1906,
      "pb": 5.01,
      "pe": 20.73,
      "yield": 1.09,
      "date": "2008-05-26"
  },
  "2008-05-27": {
      "equity": 4859.8,
      "debt": 8.119,
      "gold": 13990.09154,
      "pb": 5,
      "pe": 20.67,
      "yield": 1.09,
      "date": "2008-05-27"
  },
  "2008-05-28": {
      "equity": 4918.3,
      "debt": 8.06,
      "gold": 13460.4702,
      "pb": 5.06,
      "pe": 20.92,
      "yield": 1.08,
      "date": "2008-05-28"
  },
  "2008-05-29": {
      "equity": 4835.3,
      "debt": 8.104,
      "gold": 13374.76146,
      "pb": 4.97,
      "pe": 20.59,
      "yield": 1.1,
      "date": "2008-05-29"
  },
  "2008-05-30": {
      "equity": 4870.1,
      "debt": 8.101,
      "gold": 13095.01049,
      "pb": 5.01,
      "pe": 20.74,
      "yield": 1.09,
      "date": "2008-05-30"
  },
  "2008-05-31": {
      "equity": 4870.1,
      "debt": 8.101,
      "gold": 13095.01049,
      "pb": 5.01,
      "pe": 20.74,
      "yield": 1.09,
      "date": "2008-05-31"
  },
  "2008-06-01": {
      "equity": 4870.1,
      "debt": 8.101,
      "gold": 13095.01049,
      "pb": 5.01,
      "pe": 20.74,
      "yield": 1.09,
      "date": "2008-06-01"
  },
  "2008-06-02": {
      "equity": 4739.6,
      "debt": 8.085,
      "gold": 13354.83871,
      "pb": 4.87,
      "pe": 20.17,
      "yield": 1.12,
      "date": "2008-06-02"
  },
  "2008-06-03": {
      "equity": 4715.9,
      "debt": 8.127,
      "gold": 13450.11023,
      "pb": 4.85,
      "pe": 20.07,
      "yield": 1.12,
      "date": "2008-06-03"
  },
  "2008-06-04": {
      "equity": 4585.6,
      "debt": 8.141,
      "gold": 13275.02425,
      "pb": 4.71,
      "pe": 19.52,
      "yield": 1.15,
      "date": "2008-06-04"
  },
  "2008-06-05": {
      "equity": 4676.9,
      "debt": 8.185,
      "gold": 13238.42396,
      "pb": 4.81,
      "pe": 19.91,
      "yield": 1.13,
      "date": "2008-06-05"
  },
  "2008-06-06": {
      "equity": 4627.8,
      "debt": 8.238,
      "gold": 13392.97342,
      "pb": 4.76,
      "pe": 19.7,
      "yield": 1.14,
      "date": "2008-06-06"
  },
  "2008-06-07": {
      "equity": 4627.8,
      "debt": 8.238,
      "gold": 13392.97342,
      "pb": 4.76,
      "pe": 19.7,
      "yield": 1.14,
      "date": "2008-06-07"
  },
  "2008-06-08": {
      "equity": 4627.8,
      "debt": 8.238,
      "gold": 13392.97342,
      "pb": 4.76,
      "pe": 19.7,
      "yield": 1.14,
      "date": "2008-06-08"
  },
  "2008-06-09": {
      "equity": 4500.9,
      "debt": 8.287,
      "gold": 13705.92779,
      "pb": 4.63,
      "pe": 19.16,
      "yield": 1.18,
      "date": "2008-06-09"
  },
  "2008-06-10": {
      "equity": 4449.8,
      "debt": 8.289,
      "gold": 13437.77492,
      "pb": 4.4,
      "pe": 18.94,
      "yield": 1.22,
      "date": "2008-06-10"
  },
  "2008-06-11": {
      "equity": 4523.6,
      "debt": 8.263,
      "gold": 13195.70363,
      "pb": 4.48,
      "pe": 19.25,
      "yield": 1.2,
      "date": "2008-06-11"
  },
  "2008-06-12": {
      "equity": 4539.3,
      "debt": 8.326,
      "gold": 13116.61581,
      "pb": 4.49,
      "pe": 19.32,
      "yield": 1.2,
      "date": "2008-06-12"
  },
  "2008-06-13": {
      "equity": 4517.1,
      "debt": 8.393,
      "gold": 13068.42096,
      "pb": 4.47,
      "pe": 19.23,
      "yield": 1.2,
      "date": "2008-06-13"
  },
  "2008-06-14": {
      "equity": 4517.1,
      "debt": 8.393,
      "gold": 13068.42096,
      "pb": 4.47,
      "pe": 19.23,
      "yield": 1.2,
      "date": "2008-06-14"
  },
  "2008-06-15": {
      "equity": 4517.1,
      "debt": 8.393,
      "gold": 13068.42096,
      "pb": 4.47,
      "pe": 19.23,
      "yield": 1.2,
      "date": "2008-06-15"
  },
  "2008-06-16": {
      "equity": 4572.5,
      "debt": 8.358,
      "gold": 13234.02529,
      "pb": 4.53,
      "pe": 19.46,
      "yield": 1.19,
      "date": "2008-06-16"
  },
  "2008-06-17": {
      "equity": 4653,
      "debt": 8.335,
      "gold": 13349.57583,
      "pb": 4.6,
      "pe": 19.89,
      "yield": 1.17,
      "date": "2008-06-17"
  },
  "2008-06-18": {
      "equity": 4582.4,
      "debt": 8.366,
      "gold": 13402.11291,
      "pb": 4.53,
      "pe": 19.6,
      "yield": 1.19,
      "date": "2008-06-18"
  },
  "2008-06-19": {
      "equity": 4504.2,
      "debt": 8.477,
      "gold": 13548.65165,
      "pb": 4.46,
      "pe": 19.26,
      "yield": 1.21,
      "date": "2008-06-19"
  },
  "2008-06-20": {
      "equity": 4347.5,
      "debt": 8.627,
      "gold": 13682.781,
      "pb": 4.3,
      "pe": 18.59,
      "yield": 1.25,
      "date": "2008-06-20"
  },
  "2008-06-21": {
      "equity": 4347.5,
      "debt": 8.627,
      "gold": 13682.781,
      "pb": 4.3,
      "pe": 18.59,
      "yield": 1.25,
      "date": "2008-06-21"
  },
  "2008-06-22": {
      "equity": 4347.5,
      "debt": 8.627,
      "gold": 13682.781,
      "pb": 4.3,
      "pe": 18.59,
      "yield": 1.25,
      "date": "2008-06-22"
  },
  "2008-06-23": {
      "equity": 4266.4,
      "debt": 8.632,
      "gold": 13743.43816,
      "pb": 4.22,
      "pe": 18.25,
      "yield": 1.27,
      "date": "2008-06-23"
  },
  "2008-06-24": {
      "equity": 4191.1,
      "debt": 8.567,
      "gold": 13426.45902,
      "pb": 4.15,
      "pe": 17.91,
      "yield": 1.3,
      "date": "2008-06-24"
  },
  "2008-06-25": {
      "equity": 4252.6,
      "debt": 8.666,
      "gold": 13374.70855,
      "pb": 4.21,
      "pe": 18.18,
      "yield": 1.28,
      "date": "2008-06-25"
  },
  "2008-06-26": {
      "equity": 4315.8,
      "debt": 8.612,
      "gold": 13505.79375,
      "pb": 4.27,
      "pe": 18.45,
      "yield": 1.26,
      "date": "2008-06-26"
  },
  "2008-06-27": {
      "equity": 4136.6,
      "debt": 8.615,
      "gold": 13961.14923,
      "pb": 4.09,
      "pe": 17.67,
      "yield": 1.31,
      "date": "2008-06-27"
  },
  "2008-06-28": {
      "equity": 4136.6,
      "debt": 8.615,
      "gold": 13961.14923,
      "pb": 4.09,
      "pe": 17.67,
      "yield": 1.31,
      "date": "2008-06-28"
  },
  "2008-06-29": {
      "equity": 4136.6,
      "debt": 8.615,
      "gold": 13961.14923,
      "pb": 4.09,
      "pe": 17.67,
      "yield": 1.31,
      "date": "2008-06-29"
  },
  "2008-06-30": {
      "equity": 4040.5,
      "debt": 8.713,
      "gold": 14165.87594,
      "pb": 4,
      "pe": 17.28,
      "yield": 1.35,
      "date": "2008-06-30"
  },
  "2008-07-01": {
      "equity": 3896.7,
      "debt": 8.792,
      "gold": 14116.51705,
      "pb": 3.86,
      "pe": 16.66,
      "yield": 1.4,
      "date": "2008-07-01"
  },
  "2008-07-02": {
      "equity": 4093.3,
      "debt": 8.808,
      "gold": 14259.80705,
      "pb": 4.05,
      "pe": 17.5,
      "yield": 1.33,
      "date": "2008-07-02"
  },
  "2008-07-03": {
      "equity": 3925.7,
      "debt": 8.84,
      "gold": 14320.25256,
      "pb": 3.89,
      "pe": 16.79,
      "yield": 1.39,
      "date": "2008-07-03"
  },
  "2008-07-04": {
      "equity": 4016,
      "debt": 9.154,
      "gold": 14166.29923,
      "pb": 3.8,
      "pe": 17.22,
      "yield": 1.37,
      "date": "2008-07-04"
  },
  "2008-07-05": {
      "equity": 4016,
      "debt": 9.154,
      "gold": 14166.29923,
      "pb": 3.8,
      "pe": 17.22,
      "yield": 1.37,
      "date": "2008-07-05"
  },
  "2008-07-06": {
      "equity": 4016,
      "debt": 9.154,
      "gold": 14166.29923,
      "pb": 3.8,
      "pe": 17.22,
      "yield": 1.37,
      "date": "2008-07-06"
  },
  "2008-07-07": {
      "equity": 4030,
      "debt": 9.159,
      "gold": 14050.77691,
      "pb": 3.82,
      "pe": 17.28,
      "yield": 1.37,
      "date": "2008-07-07"
  },
  "2008-07-08": {
      "equity": 3988.5,
      "debt": 9.294,
      "gold": 14175.00132,
      "pb": 3.78,
      "pe": 17.11,
      "yield": 1.38,
      "date": "2008-07-08"
  },
  "2008-07-09": {
      "equity": 4157.1,
      "debt": 9.319,
      "gold": 14039.5104,
      "pb": 3.94,
      "pe": 17.83,
      "yield": 1.32,
      "date": "2008-07-09"
  },
  "2008-07-10": {
      "equity": 4162.2,
      "debt": 9.419,
      "gold": 14105.95249,
      "pb": 3.94,
      "pe": 17.85,
      "yield": 1.32,
      "date": "2008-07-10"
  },
  "2008-07-11": {
      "equity": 4049,
      "debt": 9.469,
      "gold": 14355.76289,
      "pb": 3.83,
      "pe": 17.34,
      "yield": 1.36,
      "date": "2008-07-11"
  },
  "2008-07-12": {
      "equity": 4049,
      "debt": 9.469,
      "gold": 14355.76289,
      "pb": 3.83,
      "pe": 17.34,
      "yield": 1.36,
      "date": "2008-07-12"
  },
  "2008-07-13": {
      "equity": 4049,
      "debt": 9.469,
      "gold": 14355.76289,
      "pb": 3.83,
      "pe": 17.34,
      "yield": 1.36,
      "date": "2008-07-13"
  },
  "2008-07-14": {
      "equity": 4039.7,
      "debt": 9.383,
      "gold": 14476.30117,
      "pb": 3.82,
      "pe": 17.3,
      "yield": 1.36,
      "date": "2008-07-14"
  },
  "2008-07-15": {
      "equity": 3861.1,
      "debt": 9.478,
      "gold": 14925.63537,
      "pb": 3.66,
      "pe": 16.53,
      "yield": 1.42,
      "date": "2008-07-15"
  },
  "2008-07-16": {
      "equity": 3816.7,
      "debt": 9.383,
      "gold": 14802.65613,
      "pb": 3.61,
      "pe": 16.33,
      "yield": 1.44,
      "date": "2008-07-16"
  },
  "2008-07-17": {
      "equity": 3947.2,
      "debt": 9.246,
      "gold": 14614.04963,
      "pb": 3.74,
      "pe": 16.87,
      "yield": 1.39,
      "date": "2008-07-17"
  },
  "2008-07-18": {
      "equity": 4092.2,
      "debt": 9.096,
      "gold": 14421.05857,
      "pb": 3.87,
      "pe": 17.49,
      "yield": 1.34,
      "date": "2008-07-18"
  },
  "2008-07-19": {
      "equity": 4092.2,
      "debt": 9.096,
      "gold": 14421.05857,
      "pb": 3.87,
      "pe": 17.49,
      "yield": 1.34,
      "date": "2008-07-19"
  },
  "2008-07-20": {
      "equity": 4092.2,
      "debt": 9.096,
      "gold": 14421.05857,
      "pb": 3.87,
      "pe": 17.49,
      "yield": 1.34,
      "date": "2008-07-20"
  },
  "2008-07-21": {
      "equity": 4159.5,
      "debt": 9.052,
      "gold": 14563.87591,
      "pb": 3.94,
      "pe": 17.72,
      "yield": 1.32,
      "date": "2008-07-21"
  },
  "2008-07-22": {
      "equity": 4240.1,
      "debt": 9.132,
      "gold": 14636.44509,
      "pb": 4.01,
      "pe": 18.06,
      "yield": 1.3,
      "date": "2008-07-22"
  },
  "2008-07-23": {
      "equity": 4476.8,
      "debt": 9.038,
      "gold": 13892.90464,
      "pb": 4.12,
      "pe": 19.06,
      "yield": 1.24,
      "date": "2008-07-23"
  },
  "2008-07-24": {
      "equity": 4433.5,
      "debt": 9.07,
      "gold": 13747.96028,
      "pb": 4.08,
      "pe": 18.79,
      "yield": 1.25,
      "date": "2008-07-24"
  },
  "2008-07-25": {
      "equity": 4311.8,
      "debt": 9.157,
      "gold": 13911.65276,
      "pb": 3.97,
      "pe": 18.19,
      "yield": 1.28,
      "date": "2008-07-25"
  },
  "2008-07-26": {
      "equity": 4311.8,
      "debt": 9.157,
      "gold": 13911.65276,
      "pb": 3.97,
      "pe": 18.19,
      "yield": 1.28,
      "date": "2008-07-26"
  },
  "2008-07-27": {
      "equity": 4311.8,
      "debt": 9.157,
      "gold": 13911.65276,
      "pb": 3.97,
      "pe": 18.19,
      "yield": 1.28,
      "date": "2008-07-27"
  },
  "2008-07-28": {
      "equity": 4332.1,
      "debt": 9.082,
      "gold": 13930.80301,
      "pb": 3.99,
      "pe": 18.2,
      "yield": 1.28,
      "date": "2008-07-28"
  },
  "2008-07-29": {
      "equity": 4189.8,
      "debt": 9.393,
      "gold": 13920.03034,
      "pb": 3.85,
      "pe": 17.49,
      "yield": 1.32,
      "date": "2008-07-29"
  },
  "2008-07-30": {
      "equity": 4313.5,
      "debt": 9.321,
      "gold": 13668.21284,
      "pb": 3.97,
      "pe": 18.08,
      "yield": 1.28,
      "date": "2008-07-30"
  },
  "2008-07-31": {
      "equity": 4332.9,
      "debt": 9.316,
      "gold": 13668.98182,
      "pb": 3.99,
      "pe": 18.22,
      "yield": 1.28,
      "date": "2008-07-31"
  },
  "2008-08-01": {
      "equity": 4413.5,
      "debt": 9.274,
      "gold": 13599.43209,
      "pb": 4.06,
      "pe": 18.56,
      "yield": 1.25,
      "date": "2008-08-01"
  },
  "2008-08-02": {
      "equity": 4413.5,
      "debt": 9.274,
      "gold": 13599.43209,
      "pb": 4.06,
      "pe": 18.56,
      "yield": 1.25,
      "date": "2008-08-02"
  },
  "2008-08-03": {
      "equity": 4413.5,
      "debt": 9.274,
      "gold": 13599.43209,
      "pb": 4.06,
      "pe": 18.56,
      "yield": 1.25,
      "date": "2008-08-03"
  },
  "2008-08-04": {
      "equity": 4395.3,
      "debt": 9.2,
      "gold": 13600.48678,
      "pb": 4.04,
      "pe": 18.54,
      "yield": 1.26,
      "date": "2008-08-04"
  },
  "2008-08-05": {
      "equity": 4502.8,
      "debt": 8.988,
      "gold": 13158.89169,
      "pb": 4.14,
      "pe": 18.99,
      "yield": 1.23,
      "date": "2008-08-05"
  },
  "2008-08-06": {
      "equity": 4517.5,
      "debt": 9.025,
      "gold": 13109.19769,
      "pb": 4.16,
      "pe": 19.05,
      "yield": 1.23,
      "date": "2008-08-06"
  },
  "2008-08-07": {
      "equity": 4523.8,
      "debt": 9.097,
      "gold": 13130.3374,
      "pb": 4.16,
      "pe": 19.08,
      "yield": 1.22,
      "date": "2008-08-07"
  },
  "2008-08-08": {
      "equity": 4529.5,
      "debt": 9.029,
      "gold": 12877.77209,
      "pb": 4.17,
      "pe": 19.1,
      "yield": 1.22,
      "date": "2008-08-08"
  },
  "2008-08-09": {
      "equity": 4529.5,
      "debt": 9.029,
      "gold": 12877.77209,
      "pb": 4.17,
      "pe": 19.1,
      "yield": 1.22,
      "date": "2008-08-09"
  },
  "2008-08-10": {
      "equity": 4529.5,
      "debt": 9.029,
      "gold": 12877.77209,
      "pb": 4.17,
      "pe": 19.1,
      "yield": 1.22,
      "date": "2008-08-10"
  },
  "2008-08-11": {
      "equity": 4620.4,
      "debt": 8.908,
      "gold": 12846.79448,
      "pb": 4.25,
      "pe": 19.49,
      "yield": 1.2,
      "date": "2008-08-11"
  },
  "2008-08-12": {
      "equity": 4552.2,
      "debt": 8.965,
      "gold": 12088.67176,
      "pb": 4.19,
      "pe": 19.2,
      "yield": 1.22,
      "date": "2008-08-12"
  },
  "2008-08-13": {
      "equity": 4529,
      "debt": 9.078,
      "gold": 12416.89624,
      "pb": 4.17,
      "pe": 19.1,
      "yield": 1.22,
      "date": "2008-08-13"
  },
  "2008-08-14": {
      "equity": 4430.7,
      "debt": 9.161,
      "gold": 12641.52101,
      "pb": 4.08,
      "pe": 18.69,
      "yield": 1.25,
      "date": "2008-08-14"
  },
  "2008-08-15": {
      "equity": 4430.7,
      "debt": 9.161,
      "gold": 11905.71262,
      "pb": 4.08,
      "pe": 18.69,
      "yield": 1.25,
      "date": "2008-08-15"
  },
  "2008-08-16": {
      "equity": 4430.7,
      "debt": 9.161,
      "gold": 11905.71262,
      "pb": 4.08,
      "pe": 18.69,
      "yield": 1.25,
      "date": "2008-08-16"
  },
  "2008-08-17": {
      "equity": 4430.7,
      "debt": 9.161,
      "gold": 11905.71262,
      "pb": 4.08,
      "pe": 18.69,
      "yield": 1.25,
      "date": "2008-08-17"
  },
  "2008-08-18": {
      "equity": 4393,
      "debt": 9.094,
      "gold": 12246.83328,
      "pb": 4.04,
      "pe": 18.53,
      "yield": 1.26,
      "date": "2008-08-18"
  },
  "2008-08-19": {
      "equity": 4368.2,
      "debt": 9.094,
      "gold": 12147.01847,
      "pb": 4.02,
      "pe": 18.43,
      "yield": 1.27,
      "date": "2008-08-19"
  },
  "2008-08-20": {
      "equity": 4415.7,
      "debt": 9.132,
      "gold": 12414.49408,
      "pb": 4.06,
      "pe": 18.63,
      "yield": 1.25,
      "date": "2008-08-20"
  },
  "2008-08-21": {
      "equity": 4283.8,
      "debt": 9.206,
      "gold": 12599.02644,
      "pb": 3.94,
      "pe": 18.07,
      "yield": 1.29,
      "date": "2008-08-21"
  },
  "2008-08-22": {
      "equity": 4327.4,
      "debt": 9.085,
      "gold": 12722.49951,
      "pb": 3.8,
      "pe": 18.25,
      "yield": 1.3,
      "date": "2008-08-22"
  },
  "2008-08-23": {
      "equity": 4327.4,
      "debt": 9.085,
      "gold": 12722.49951,
      "pb": 3.8,
      "pe": 18.25,
      "yield": 1.3,
      "date": "2008-08-23"
  },
  "2008-08-24": {
      "equity": 4327.4,
      "debt": 9.085,
      "gold": 12722.49951,
      "pb": 3.8,
      "pe": 18.25,
      "yield": 1.3,
      "date": "2008-08-24"
  },
  "2008-08-25": {
      "equity": 4335.3,
      "debt": 8.991,
      "gold": 12722.49951,
      "pb": 3.8,
      "pe": 18.29,
      "yield": 1.3,
      "date": "2008-08-25"
  },
  "2008-08-26": {
      "equity": 4337.5,
      "debt": 8.891,
      "gold": 12529.20863,
      "pb": 3.8,
      "pe": 18.3,
      "yield": 1.3,
      "date": "2008-08-26"
  },
  "2008-08-27": {
      "equity": 4292.1,
      "debt": 8.885,
      "gold": 12867.29219,
      "pb": 3.77,
      "pe": 18.1,
      "yield": 1.31,
      "date": "2008-08-27"
  },
  "2008-08-28": {
      "equity": 4214,
      "debt": 8.799,
      "gold": 12841.20002,
      "pb": 3.7,
      "pe": 17.81,
      "yield": 1.33,
      "date": "2008-08-28"
  },
  "2008-08-29": {
      "equity": 4360,
      "debt": 8.7,
      "gold": 12966.71546,
      "pb": 3.83,
      "pe": 18.43,
      "yield": 1.29,
      "date": "2008-08-29"
  },
  "2008-08-30": {
      "equity": 4360,
      "debt": 8.7,
      "gold": 12966.71546,
      "pb": 3.83,
      "pe": 18.43,
      "yield": 1.29,
      "date": "2008-08-30"
  },
  "2008-08-31": {
      "equity": 4360,
      "debt": 8.7,
      "gold": 12966.71546,
      "pb": 3.83,
      "pe": 18.43,
      "yield": 1.29,
      "date": "2008-08-31"
  },
  "2008-09-01": {
      "equity": 4348.6,
      "debt": 8.637,
      "gold": 12958.59186,
      "pb": 3.82,
      "pe": 18.38,
      "yield": 1.29,
      "date": "2008-09-01"
  },
  "2008-09-02": {
      "equity": 4504,
      "debt": 8.609,
      "gold": 12552.90569,
      "pb": 3.96,
      "pe": 19.03,
      "yield": 1.24,
      "date": "2008-09-02"
  },
  "2008-09-03": {
      "equity": 4504,
      "debt": 8.609,
      "gold": 12427.76769,
      "pb": 3.96,
      "pe": 19.03,
      "yield": 1.24,
      "date": "2008-09-03"
  },
  "2008-09-04": {
      "equity": 4447.7,
      "debt": 8.476,
      "gold": 12682.33302,
      "pb": 3.91,
      "pe": 18.8,
      "yield": 1.26,
      "date": "2008-09-04"
  },
  "2008-09-05": {
      "equity": 4352.3,
      "debt": 8.484,
      "gold": 12522.55243,
      "pb": 3.83,
      "pe": 18.39,
      "yield": 1.29,
      "date": "2008-09-05"
  },
  "2008-09-06": {
      "equity": 4352.3,
      "debt": 8.484,
      "gold": 12522.55243,
      "pb": 3.83,
      "pe": 18.39,
      "yield": 1.29,
      "date": "2008-09-06"
  },
  "2008-09-07": {
      "equity": 4352.3,
      "debt": 8.484,
      "gold": 12522.55243,
      "pb": 3.83,
      "pe": 18.39,
      "yield": 1.29,
      "date": "2008-09-07"
  },
  "2008-09-08": {
      "equity": 4482.3,
      "debt": 8.494,
      "gold": 12726.42551,
      "pb": 3.94,
      "pe": 18.94,
      "yield": 1.25,
      "date": "2008-09-08"
  },
  "2008-09-09": {
      "equity": 4468.7,
      "debt": 8.41,
      "gold": 12636.83663,
      "pb": 3.93,
      "pe": 18.9,
      "yield": 1.25,
      "date": "2008-09-09"
  },
  "2008-09-10": {
      "equity": 4400.2,
      "debt": 8.357,
      "gold": 12370.46509,
      "pb": 3.94,
      "pe": 18.88,
      "yield": 1.26,
      "date": "2008-09-10"
  },
  "2008-09-11": {
      "equity": 4290.3,
      "debt": 8.288,
      "gold": 11987.69996,
      "pb": 3.84,
      "pe": 18.41,
      "yield": 1.29,
      "date": "2008-09-11"
  },
  "2008-09-12": {
      "equity": 4228.4,
      "debt": 8.348,
      "gold": 12169.64673,
      "pb": 3.79,
      "pe": 18.14,
      "yield": 1.31,
      "date": "2008-09-12"
  },
  "2008-09-13": {
      "equity": 4228.4,
      "debt": 8.348,
      "gold": 12169.64673,
      "pb": 3.79,
      "pe": 18.14,
      "yield": 1.31,
      "date": "2008-09-13"
  },
  "2008-09-14": {
      "equity": 4228.4,
      "debt": 8.348,
      "gold": 12169.64673,
      "pb": 3.79,
      "pe": 18.14,
      "yield": 1.31,
      "date": "2008-09-14"
  },
  "2008-09-15": {
      "equity": 4072.9,
      "debt": 8.15,
      "gold": 12624.89638,
      "pb": 3.65,
      "pe": 17.5,
      "yield": 1.36,
      "date": "2008-09-15"
  },
  "2008-09-16": {
      "equity": 4074.9,
      "debt": 8.097,
      "gold": 12770.52153,
      "pb": 3.66,
      "pe": 17.51,
      "yield": 1.35,
      "date": "2008-09-16"
  },
  "2008-09-17": {
      "equity": 4008.2,
      "debt": 8.185,
      "gold": 12886.85867,
      "pb": 3.6,
      "pe": 17.22,
      "yield": 1.38,
      "date": "2008-09-17"
  },
  "2008-09-18": {
      "equity": 4038.1,
      "debt": 8.384,
      "gold": 14145.29357,
      "pb": 3.36,
      "pe": 17.35,
      "yield": 1.41,
      "date": "2008-09-18"
  },
  "2008-09-19": {
      "equity": 4245.2,
      "debt": 8.415,
      "gold": 13496.24861,
      "pb": 3.53,
      "pe": 18.24,
      "yield": 1.34,
      "date": "2008-09-19"
  },
  "2008-09-20": {
      "equity": 4245.2,
      "debt": 8.415,
      "gold": 13496.24861,
      "pb": 3.53,
      "pe": 18.24,
      "yield": 1.34,
      "date": "2008-09-20"
  },
  "2008-09-21": {
      "equity": 4245.2,
      "debt": 8.415,
      "gold": 13496.24861,
      "pb": 3.53,
      "pe": 18.24,
      "yield": 1.34,
      "date": "2008-09-21"
  },
  "2008-09-22": {
      "equity": 4223,
      "debt": 8.464,
      "gold": 13988.26082,
      "pb": 3.52,
      "pe": 18.15,
      "yield": 1.35,
      "date": "2008-09-22"
  },
  "2008-09-23": {
      "equity": 4126.9,
      "debt": 8.426,
      "gold": 14358.95871,
      "pb": 3.44,
      "pe": 17.73,
      "yield": 1.38,
      "date": "2008-09-23"
  },
  "2008-09-24": {
      "equity": 4161.2,
      "debt": 8.632,
      "gold": 14379.22362,
      "pb": 3.46,
      "pe": 17.88,
      "yield": 1.37,
      "date": "2008-09-24"
  },
  "2008-09-25": {
      "equity": 4110.5,
      "debt": 8.572,
      "gold": 14443.76091,
      "pb": 3.42,
      "pe": 17.66,
      "yield": 1.38,
      "date": "2008-09-25"
  },
  "2008-09-26": {
      "equity": 3985.2,
      "debt": 8.58,
      "gold": 14252.15965,
      "pb": 3.32,
      "pe": 17.12,
      "yield": 1.43,
      "date": "2008-09-26"
  },
  "2008-09-27": {
      "equity": 3985.2,
      "debt": 8.58,
      "gold": 14252.15965,
      "pb": 3.32,
      "pe": 17.12,
      "yield": 1.43,
      "date": "2008-09-27"
  },
  "2008-09-28": {
      "equity": 3985.2,
      "debt": 8.58,
      "gold": 14252.15965,
      "pb": 3.32,
      "pe": 17.12,
      "yield": 1.43,
      "date": "2008-09-28"
  },
  "2008-09-29": {
      "equity": 3850,
      "debt": 8.617,
      "gold": 14585.40362,
      "pb": 3.21,
      "pe": 16.54,
      "yield": 1.48,
      "date": "2008-09-29"
  },
  "2008-09-30": {
      "equity": 3921.2,
      "debt": 8.617,
      "gold": 14926.53486,
      "pb": 3.26,
      "pe": 16.85,
      "yield": 1.45,
      "date": "2008-09-30"
  },
  "2008-10-01": {
      "equity": 3950.7,
      "debt": 8.46,
      "gold": 14408.6774,
      "pb": 3.29,
      "pe": 16.98,
      "yield": 1.44,
      "date": "2008-10-01"
  },
  "2008-10-02": {
      "equity": 3950.7,
      "debt": 8.46,
      "gold": 14248.30773,
      "pb": 3.29,
      "pe": 16.98,
      "yield": 1.44,
      "date": "2008-10-02"
  },
  "2008-10-03": {
      "equity": 3818.3,
      "debt": 8.291,
      "gold": 13988.42308,
      "pb": 3.18,
      "pe": 16.41,
      "yield": 1.49,
      "date": "2008-10-03"
  },
  "2008-10-04": {
      "equity": 3818.3,
      "debt": 8.291,
      "gold": 13988.42308,
      "pb": 3.18,
      "pe": 16.41,
      "yield": 1.49,
      "date": "2008-10-04"
  },
  "2008-10-05": {
      "equity": 3818.3,
      "debt": 8.291,
      "gold": 13988.42308,
      "pb": 3.18,
      "pe": 16.41,
      "yield": 1.49,
      "date": "2008-10-05"
  },
  "2008-10-06": {
      "equity": 3602.3,
      "debt": 8.102,
      "gold": 14107.1518,
      "pb": 3,
      "pe": 15.48,
      "yield": 1.58,
      "date": "2008-10-06"
  },
  "2008-10-07": {
      "equity": 3606.6,
      "debt": 8.094,
      "gold": 14912.25595,
      "pb": 3,
      "pe": 15.5,
      "yield": 1.58,
      "date": "2008-10-07"
  },
  "2008-10-08": {
      "equity": 3513.6,
      "debt": 7.977,
      "gold": 15458.47369,
      "pb": 2.93,
      "pe": 15.1,
      "yield": 1.62,
      "date": "2008-10-08"
  },
  "2008-10-09": {
      "equity": 3513.6,
      "debt": 7.977,
      "gold": 15014.02141,
      "pb": 2.93,
      "pe": 15.1,
      "yield": 1.62,
      "date": "2008-10-09"
  },
  "2008-10-10": {
      "equity": 3279.9,
      "debt": 7.814,
      "gold": 15690.4672,
      "pb": 2.73,
      "pe": 14.07,
      "yield": 1.73,
      "date": "2008-10-10"
  },
  "2008-10-11": {
      "equity": 3279.9,
      "debt": 7.814,
      "gold": 15690.4672,
      "pb": 2.73,
      "pe": 14.07,
      "yield": 1.73,
      "date": "2008-10-11"
  },
  "2008-10-12": {
      "equity": 3279.9,
      "debt": 7.814,
      "gold": 15690.4672,
      "pb": 2.73,
      "pe": 14.07,
      "yield": 1.73,
      "date": "2008-10-12"
  },
  "2008-10-13": {
      "equity": 3490.7,
      "debt": 7.785,
      "gold": 14725.09215,
      "pb": 2.91,
      "pe": 14.97,
      "yield": 1.63,
      "date": "2008-10-13"
  },
  "2008-10-14": {
      "equity": 3518.6,
      "debt": 7.974,
      "gold": 14405.79552,
      "pb": 2.93,
      "pe": 15.09,
      "yield": 1.62,
      "date": "2008-10-14"
  },
  "2008-10-15": {
      "equity": 3338.4,
      "debt": 7.929,
      "gold": 14525.76941,
      "pb": 2.78,
      "pe": 14.31,
      "yield": 1.7,
      "date": "2008-10-15"
  },
  "2008-10-16": {
      "equity": 3269.3,
      "debt": 7.714,
      "gold": 14386.91688,
      "pb": 2.72,
      "pe": 14.01,
      "yield": 1.74,
      "date": "2008-10-16"
  },
  "2008-10-17": {
      "equity": 3074.3,
      "debt": 7.705,
      "gold": 13812.19775,
      "pb": 2.56,
      "pe": 13.15,
      "yield": 1.85,
      "date": "2008-10-17"
  },
  "2008-10-18": {
      "equity": 3074.3,
      "debt": 7.705,
      "gold": 13812.19775,
      "pb": 2.56,
      "pe": 13.15,
      "yield": 1.85,
      "date": "2008-10-18"
  },
  "2008-10-19": {
      "equity": 3074.3,
      "debt": 7.705,
      "gold": 13812.19775,
      "pb": 2.56,
      "pe": 13.15,
      "yield": 1.85,
      "date": "2008-10-19"
  },
  "2008-10-20": {
      "equity": 3122.8,
      "debt": 7.517,
      "gold": 13870.75963,
      "pb": 2.62,
      "pe": 13.47,
      "yield": 1.81,
      "date": "2008-10-20"
  },
  "2008-10-21": {
      "equity": 3234.9,
      "debt": 7.517,
      "gold": 13490.73881,
      "pb": 2.72,
      "pe": 13.92,
      "yield": 1.74,
      "date": "2008-10-21"
  },
  "2008-10-22": {
      "equity": 3065.1,
      "debt": 7.589,
      "gold": 13162.29563,
      "pb": 2.57,
      "pe": 13.18,
      "yield": 1.84,
      "date": "2008-10-22"
  },
  "2008-10-23": {
      "equity": 2943.1,
      "debt": 7.567,
      "gold": 12756.44368,
      "pb": 2.47,
      "pe": 12.66,
      "yield": 1.92,
      "date": "2008-10-23"
  },
  "2008-10-24": {
      "equity": 2584,
      "debt": 7.683,
      "gold": 12205.06887,
      "pb": 2.17,
      "pe": 10.99,
      "yield": 2.18,
      "date": "2008-10-24"
  },
  "2008-10-25": {
      "equity": 2584,
      "debt": 7.683,
      "gold": 12205.06887,
      "pb": 2.17,
      "pe": 10.99,
      "yield": 2.18,
      "date": "2008-10-25"
  },
  "2008-10-26": {
      "equity": 2584,
      "debt": 7.683,
      "gold": 12205.06887,
      "pb": 2.17,
      "pe": 10.99,
      "yield": 2.18,
      "date": "2008-10-26"
  },
  "2008-10-27": {
      "equity": 2524.2,
      "debt": 7.61,
      "gold": 12671.23935,
      "pb": 2.12,
      "pe": 10.68,
      "yield": 2.24,
      "date": "2008-10-27"
  },
  "2008-10-28": {
      "equity": 2684.6,
      "debt": 7.61,
      "gold": 13102.11468,
      "pb": 2.25,
      "pe": 11.36,
      "yield": 2.1,
      "date": "2008-10-28"
  },
  "2008-10-29": {
      "equity": 2697,
      "debt": 7.512,
      "gold": 13137.2123,
      "pb": 2.26,
      "pe": 11.42,
      "yield": 2.09,
      "date": "2008-10-29"
  },
  "2008-10-30": {
      "equity": 2697,
      "debt": 7.512,
      "gold": 13540.49278,
      "pb": 2.26,
      "pe": 11.42,
      "yield": 2.09,
      "date": "2008-10-30"
  },
  "2008-10-31": {
      "equity": 2885.6,
      "debt": 7.478,
      "gold": 12707.21882,
      "pb": 2.42,
      "pe": 12.57,
      "yield": 1.96,
      "date": "2008-10-31"
  },
  "2008-11-01": {
      "equity": 2885.6,
      "debt": 7.478,
      "gold": 12707.21882,
      "pb": 2.42,
      "pe": 12.57,
      "yield": 1.96,
      "date": "2008-11-01"
  },
  "2008-11-02": {
      "equity": 2885.6,
      "debt": 7.478,
      "gold": 12707.21882,
      "pb": 2.42,
      "pe": 12.57,
      "yield": 1.96,
      "date": "2008-11-02"
  },
  "2008-11-03": {
      "equity": 3043.8,
      "debt": 7.561,
      "gold": 12597.31918,
      "pb": 2.56,
      "pe": 13.33,
      "yield": 1.85,
      "date": "2008-11-03"
  },
  "2008-11-04": {
      "equity": 3142.1,
      "debt": 7.631,
      "gold": 12348.11901,
      "pb": 2.64,
      "pe": 13.76,
      "yield": 1.8,
      "date": "2008-11-04"
  },
  "2008-11-05": {
      "equity": 2994.9,
      "debt": 7.763,
      "gold": 12608.8573,
      "pb": 2.51,
      "pe": 13.11,
      "yield": 1.88,
      "date": "2008-11-05"
  },
  "2008-11-06": {
      "equity": 2892.6,
      "debt": 7.714,
      "gold": 12442.0078,
      "pb": 2.43,
      "pe": 12.66,
      "yield": 1.95,
      "date": "2008-11-06"
  },
  "2008-11-07": {
      "equity": 2973,
      "debt": 7.711,
      "gold": 12510.83793,
      "pb": 2.5,
      "pe": 13.02,
      "yield": 1.9,
      "date": "2008-11-07"
  },
  "2008-11-08": {
      "equity": 2973,
      "debt": 7.711,
      "gold": 12510.83793,
      "pb": 2.5,
      "pe": 13.02,
      "yield": 1.9,
      "date": "2008-11-08"
  },
  "2008-11-09": {
      "equity": 2973,
      "debt": 7.711,
      "gold": 12510.83793,
      "pb": 2.5,
      "pe": 13.02,
      "yield": 1.9,
      "date": "2008-11-09"
  },
  "2008-11-10": {
      "equity": 3148.2,
      "debt": 7.715,
      "gold": 12555.90398,
      "pb": 2.64,
      "pe": 13.78,
      "yield": 1.79,
      "date": "2008-11-10"
  },
  "2008-11-11": {
      "equity": 2938.6,
      "debt": 7.682,
      "gold": 12592.96637,
      "pb": 2.47,
      "pe": 12.87,
      "yield": 1.92,
      "date": "2008-11-11"
  },
  "2008-11-12": {
      "equity": 2848.4,
      "debt": 7.632,
      "gold": 12695.03871,
      "pb": 2.39,
      "pe": 12.48,
      "yield": 1.98,
      "date": "2008-11-12"
  },
  "2008-11-13": {
      "equity": 2848.4,
      "debt": 7.632,
      "gold": 12391.32965,
      "pb": 2.39,
      "pe": 12.48,
      "yield": 1.98,
      "date": "2008-11-13"
  },
  "2008-11-14": {
      "equity": 2810.3,
      "debt": 7.468,
      "gold": 12603.71788,
      "pb": 2.36,
      "pe": 12.31,
      "yield": 2.01,
      "date": "2008-11-14"
  },
  "2008-11-15": {
      "equity": 2810.3,
      "debt": 7.468,
      "gold": 12603.71788,
      "pb": 2.36,
      "pe": 12.31,
      "yield": 2.01,
      "date": "2008-11-15"
  },
  "2008-11-16": {
      "equity": 2810.3,
      "debt": 7.468,
      "gold": 12603.71788,
      "pb": 2.36,
      "pe": 12.31,
      "yield": 2.01,
      "date": "2008-11-16"
  },
  "2008-11-17": {
      "equity": 2799.5,
      "debt": 7.509,
      "gold": 12968.74724,
      "pb": 2.35,
      "pe": 12.26,
      "yield": 2.01,
      "date": "2008-11-17"
  },
  "2008-11-18": {
      "equity": 2683.1,
      "debt": 7.473,
      "gold": 12898.72132,
      "pb": 2.25,
      "pe": 11.75,
      "yield": 2.11,
      "date": "2008-11-18"
  },
  "2008-11-19": {
      "equity": 2635,
      "debt": 7.403,
      "gold": 13014.29655,
      "pb": 2.21,
      "pe": 11.54,
      "yield": 2.14,
      "date": "2008-11-19"
  },
  "2008-11-20": {
      "equity": 2553.1,
      "debt": 7.252,
      "gold": 13189.97513,
      "pb": 2.15,
      "pe": 11.18,
      "yield": 2.21,
      "date": "2008-11-20"
  },
  "2008-11-21": {
      "equity": 2693.4,
      "debt": 7.223,
      "gold": 13391.03688,
      "pb": 2.26,
      "pe": 11.8,
      "yield": 2.1,
      "date": "2008-11-21"
  },
  "2008-11-22": {
      "equity": 2693.4,
      "debt": 7.223,
      "gold": 13391.03688,
      "pb": 2.26,
      "pe": 11.8,
      "yield": 2.1,
      "date": "2008-11-22"
  },
  "2008-11-23": {
      "equity": 2693.4,
      "debt": 7.223,
      "gold": 13391.03688,
      "pb": 2.26,
      "pe": 11.8,
      "yield": 2.1,
      "date": "2008-11-23"
  },
  "2008-11-24": {
      "equity": 2708.2,
      "debt": 7.201,
      "gold": 14376.20417,
      "pb": 2.28,
      "pe": 11.86,
      "yield": 2.09,
      "date": "2008-11-24"
  },
  "2008-11-25": {
      "equity": 2654,
      "debt": 7.215,
      "gold": 14125.3814,
      "pb": 2.23,
      "pe": 11.63,
      "yield": 2.13,
      "date": "2008-11-25"
  },
  "2008-11-26": {
      "equity": 2752.2,
      "debt": 7.112,
      "gold": 14258.12801,
      "pb": 2.31,
      "pe": 12.06,
      "yield": 2.05,
      "date": "2008-11-26"
  },
  "2008-11-27": {
      "equity": 2752.2,
      "debt": 7.112,
      "gold": 14197.04404,
      "pb": 2.31,
      "pe": 12.06,
      "yield": 2.05,
      "date": "2008-11-27"
  },
  "2008-11-28": {
      "equity": 2755.1,
      "debt": 7.08,
      "gold": 14376.39112,
      "pb": 2.32,
      "pe": 12.08,
      "yield": 2.05,
      "date": "2008-11-28"
  },
  "2008-11-29": {
      "equity": 2755.1,
      "debt": 7.08,
      "gold": 14376.39112,
      "pb": 2.32,
      "pe": 12.08,
      "yield": 2.05,
      "date": "2008-11-29"
  },
  "2008-11-30": {
      "equity": 2755.1,
      "debt": 7.08,
      "gold": 14376.39112,
      "pb": 2.32,
      "pe": 12.08,
      "yield": 2.05,
      "date": "2008-11-30"
  },
  "2008-12-01": {
      "equity": 2682.9,
      "debt": 7.065,
      "gold": 14128.44318,
      "pb": 2.25,
      "pe": 11.76,
      "yield": 2.11,
      "date": "2008-12-01"
  },
  "2008-12-02": {
      "equity": 2657.8,
      "debt": 7.016,
      "gold": 13665.45442,
      "pb": 2.23,
      "pe": 11.65,
      "yield": 2.13,
      "date": "2008-12-02"
  },
  "2008-12-03": {
      "equity": 2656.4,
      "debt": 6.787,
      "gold": 13628.57546,
      "pb": 2.23,
      "pe": 11.64,
      "yield": 2.13,
      "date": "2008-12-03"
  },
  "2008-12-04": {
      "equity": 2788,
      "debt": 6.865,
      "gold": 13582.64872,
      "pb": 2.34,
      "pe": 12.22,
      "yield": 2.03,
      "date": "2008-12-04"
  },
  "2008-12-05": {
      "equity": 2714.4,
      "debt": 6.771,
      "gold": 13495.65601,
      "pb": 2.24,
      "pe": 11.9,
      "yield": 2.08,
      "date": "2008-12-05"
  },
  "2008-12-06": {
      "equity": 2714.4,
      "debt": 6.771,
      "gold": 13495.65601,
      "pb": 2.24,
      "pe": 11.9,
      "yield": 2.08,
      "date": "2008-12-06"
  },
  "2008-12-07": {
      "equity": 2714.4,
      "debt": 6.771,
      "gold": 13495.65601,
      "pb": 2.24,
      "pe": 11.9,
      "yield": 2.08,
      "date": "2008-12-07"
  },
  "2008-12-08": {
      "equity": 2784,
      "debt": 6.692,
      "gold": 13503.03533,
      "pb": 2.3,
      "pe": 12.2,
      "yield": 2.03,
      "date": "2008-12-08"
  },
  "2008-12-09": {
      "equity": 2784,
      "debt": 6.692,
      "gold": 13481.17956,
      "pb": 2.3,
      "pe": 12.2,
      "yield": 2.03,
      "date": "2008-12-09"
  },
  "2008-12-10": {
      "equity": 2928.2,
      "debt": 6.625,
      "gold": 13567.24457,
      "pb": 2.42,
      "pe": 12.84,
      "yield": 1.93,
      "date": "2008-12-10"
  },
  "2008-12-11": {
      "equity": 2920.1,
      "debt": 6.473,
      "gold": 14031.09402,
      "pb": 2.41,
      "pe": 12.8,
      "yield": 1.94,
      "date": "2008-12-11"
  },
  "2008-12-12": {
      "equity": 2921.3,
      "debt": 6.23,
      "gold": 13907.90667,
      "pb": 2.41,
      "pe": 12.81,
      "yield": 1.93,
      "date": "2008-12-12"
  },
  "2008-12-13": {
      "equity": 2921.3,
      "debt": 6.23,
      "gold": 13907.90667,
      "pb": 2.41,
      "pe": 12.81,
      "yield": 1.93,
      "date": "2008-12-13"
  },
  "2008-12-14": {
      "equity": 2921.3,
      "debt": 6.23,
      "gold": 13907.90667,
      "pb": 2.41,
      "pe": 12.81,
      "yield": 1.93,
      "date": "2008-12-14"
  },
  "2008-12-15": {
      "equity": 2981.2,
      "debt": 6.178,
      "gold": 14025.42549,
      "pb": 2.46,
      "pe": 13.07,
      "yield": 1.9,
      "date": "2008-12-15"
  },
  "2008-12-16": {
      "equity": 3041.7,
      "debt": 6.011,
      "gold": 14091.83936,
      "pb": 2.51,
      "pe": 13.33,
      "yield": 1.86,
      "date": "2008-12-16"
  },
  "2008-12-17": {
      "equity": 2954.3,
      "debt": 5.813,
      "gold": 14364.93765,
      "pb": 2.44,
      "pe": 12.95,
      "yield": 1.91,
      "date": "2008-12-17"
  },
  "2008-12-18": {
      "equity": 3060.7,
      "debt": 5.574,
      "gold": 14431.1293,
      "pb": 2.53,
      "pe": 13.42,
      "yield": 1.85,
      "date": "2008-12-18"
  },
  "2008-12-19": {
      "equity": 3077.5,
      "debt": 5.572,
      "gold": 13985.06146,
      "pb": 2.54,
      "pe": 13.49,
      "yield": 1.84,
      "date": "2008-12-19"
  },
  "2008-12-20": {
      "equity": 3077.5,
      "debt": 5.572,
      "gold": 13985.06146,
      "pb": 2.54,
      "pe": 13.49,
      "yield": 1.84,
      "date": "2008-12-20"
  },
  "2008-12-21": {
      "equity": 3077.5,
      "debt": 5.572,
      "gold": 13985.06146,
      "pb": 2.54,
      "pe": 13.49,
      "yield": 1.84,
      "date": "2008-12-21"
  },
  "2008-12-22": {
      "equity": 3039.3,
      "debt": 5.722,
      "gold": 14321.07797,
      "pb": 2.51,
      "pe": 13.32,
      "yield": 1.86,
      "date": "2008-12-22"
  },
  "2008-12-23": {
      "equity": 2968.6,
      "debt": 5.746,
      "gold": 14501.57498,
      "pb": 2.45,
      "pe": 13.01,
      "yield": 1.9,
      "date": "2008-12-23"
  },
  "2008-12-24": {
      "equity": 2916.8,
      "debt": 5.603,
      "gold": 14185.1567,
      "pb": 2.41,
      "pe": 12.79,
      "yield": 1.94,
      "date": "2008-12-24"
  },
  "2008-12-25": {
      "equity": 2916.8,
      "debt": 5.603,
      "gold": 14185.1567,
      "pb": 2.41,
      "pe": 12.79,
      "yield": 1.94,
      "date": "2008-12-25"
  },
  "2008-12-26": {
      "equity": 2857.2,
      "debt": 5.618,
      "gold": 14185.1567,
      "pb": 2.36,
      "pe": 12.52,
      "yield": 1.98,
      "date": "2008-12-26"
  },
  "2008-12-27": {
      "equity": 2857.2,
      "debt": 5.618,
      "gold": 14185.1567,
      "pb": 2.36,
      "pe": 12.52,
      "yield": 1.98,
      "date": "2008-12-27"
  },
  "2008-12-28": {
      "equity": 2857.2,
      "debt": 5.618,
      "gold": 14185.1567,
      "pb": 2.36,
      "pe": 12.52,
      "yield": 1.98,
      "date": "2008-12-28"
  },
  "2008-12-29": {
      "equity": 2922.2,
      "debt": 5.549,
      "gold": 15040.97074,
      "pb": 2.41,
      "pe": 12.81,
      "yield": 1.93,
      "date": "2008-12-29"
  },
  "2008-12-30": {
      "equity": 2979.5,
      "debt": 5.337,
      "gold": 14873.11946,
      "pb": 2.46,
      "pe": 13.06,
      "yield": 1.9,
      "date": "2008-12-30"
  },
  "2008-12-31": {
      "equity": 2959.1,
      "debt": 5.26,
      "gold": 14851.71873,
      "pb": 2.44,
      "pe": 12.97,
      "yield": 1.91,
      "date": "2008-12-31"
  },
  "2009-01-01": {
      "equity": 3033.4,
      "debt": 5.275,
      "gold": 14851.71873,
      "pb": 2.5,
      "pe": 13.3,
      "yield": 1.86,
      "date": "2009-01-01"
  },
  "2009-01-02": {
      "equity": 3046.7,
      "debt": 5.349,
      "gold": 14901.05998,
      "pb": 2.51,
      "pe": 13.36,
      "yield": 1.85,
      "date": "2009-01-02"
  },
  "2009-01-03": {
      "equity": 3046.7,
      "debt": 5.349,
      "gold": 14901.05998,
      "pb": 2.51,
      "pe": 13.36,
      "yield": 1.85,
      "date": "2009-01-03"
  },
  "2009-01-04": {
      "equity": 3046.7,
      "debt": 5.349,
      "gold": 14901.05998,
      "pb": 2.51,
      "pe": 13.36,
      "yield": 1.85,
      "date": "2009-01-04"
  },
  "2009-01-05": {
      "equity": 3121.4,
      "debt": 5.172,
      "gold": 14746.15073,
      "pb": 2.58,
      "pe": 13.68,
      "yield": 1.81,
      "date": "2009-01-05"
  },
  "2009-01-06": {
      "equity": 3112.8,
      "debt": 5.293,
      "gold": 14510.50636,
      "pb": 2.57,
      "pe": 13.64,
      "yield": 1.82,
      "date": "2009-01-06"
  },
  "2009-01-07": {
      "equity": 2920.4,
      "debt": 5.982,
      "gold": 14877.21477,
      "pb": 2.41,
      "pe": 12.8,
      "yield": 1.93,
      "date": "2009-01-07"
  },
  "2009-01-08": {
      "equity": 2920.4,
      "debt": 5.982,
      "gold": 14507.00718,
      "pb": 2.41,
      "pe": 12.8,
      "yield": 1.93,
      "date": "2009-01-08"
  },
  "2009-01-09": {
      "equity": 2873,
      "debt": 6.048,
      "gold": 14537.83665,
      "pb": 2.37,
      "pe": 12.59,
      "yield": 1.97,
      "date": "2009-01-09"
  },
  "2009-01-10": {
      "equity": 2873,
      "debt": 6.048,
      "gold": 14537.83665,
      "pb": 2.37,
      "pe": 12.59,
      "yield": 1.97,
      "date": "2009-01-10"
  },
  "2009-01-11": {
      "equity": 2873,
      "debt": 6.048,
      "gold": 14537.83665,
      "pb": 2.37,
      "pe": 12.59,
      "yield": 1.97,
      "date": "2009-01-11"
  },
  "2009-01-12": {
      "equity": 2773.1,
      "debt": 5.845,
      "gold": 14619.2984,
      "pb": 2.31,
      "pe": 12.32,
      "yield": 2.02,
      "date": "2009-01-12"
  },
  "2009-01-13": {
      "equity": 2744.9,
      "debt": 5.679,
      "gold": 14128.39027,
      "pb": 2.28,
      "pe": 12.16,
      "yield": 2.04,
      "date": "2009-01-13"
  },
  "2009-01-14": {
      "equity": 2835.3,
      "debt": 5.576,
      "gold": 14242.95667,
      "pb": 2.36,
      "pe": 12.56,
      "yield": 1.98,
      "date": "2009-01-14"
  },
  "2009-01-15": {
      "equity": 2736.7,
      "debt": 5.559,
      "gold": 14060.70301,
      "pb": 2.28,
      "pe": 12.1,
      "yield": 2.05,
      "date": "2009-01-15"
  },
  "2009-01-16": {
      "equity": 2828.4,
      "debt": 5.588,
      "gold": 14165.13871,
      "pb": 2.35,
      "pe": 12.51,
      "yield": 1.98,
      "date": "2009-01-16"
  },
  "2009-01-17": {
      "equity": 2828.4,
      "debt": 5.588,
      "gold": 14165.13871,
      "pb": 2.35,
      "pe": 12.51,
      "yield": 1.98,
      "date": "2009-01-17"
  },
  "2009-01-18": {
      "equity": 2828.4,
      "debt": 5.588,
      "gold": 14165.13871,
      "pb": 2.35,
      "pe": 12.51,
      "yield": 1.98,
      "date": "2009-01-18"
  },
  "2009-01-19": {
      "equity": 2846.2,
      "debt": 5.6,
      "gold": 14454.99921,
      "pb": 2.37,
      "pe": 12.58,
      "yield": 1.97,
      "date": "2009-01-19"
  },
  "2009-01-20": {
      "equity": 2796.6,
      "debt": 5.682,
      "gold": 14494.20625,
      "pb": 2.33,
      "pe": 12.36,
      "yield": 2,
      "date": "2009-01-20"
  },
  "2009-01-21": {
      "equity": 2706.1,
      "debt": 5.879,
      "gold": 14906.49218,
      "pb": 2.25,
      "pe": 11.97,
      "yield": 2.07,
      "date": "2009-01-21"
  },
  "2009-01-22": {
      "equity": 2713.8,
      "debt": 5.814,
      "gold": 14685.62056,
      "pb": 2.26,
      "pe": 11.96,
      "yield": 2.06,
      "date": "2009-01-22"
  },
  "2009-01-23": {
      "equity": 2678.5,
      "debt": 5.748,
      "gold": 15150.7434,
      "pb": 2.23,
      "pe": 12.27,
      "yield": 2.09,
      "date": "2009-01-23"
  },
  "2009-01-24": {
      "equity": 2678.5,
      "debt": 5.742,
      "gold": 15150.7434,
      "pb": 2.23,
      "pe": 12.27,
      "yield": 2.09,
      "date": "2009-01-24"
  },
  "2009-01-25": {
      "equity": 2678.5,
      "debt": 5.742,
      "gold": 15150.7434,
      "pb": 2.23,
      "pe": 12.27,
      "yield": 2.09,
      "date": "2009-01-25"
  },
  "2009-01-26": {
      "equity": 2678.5,
      "debt": 5.742,
      "gold": 15732.12931,
      "pb": 2.23,
      "pe": 12.27,
      "yield": 2.09,
      "date": "2009-01-26"
  },
  "2009-01-27": {
      "equity": 2771.3,
      "debt": 5.966,
      "gold": 15469.30986,
      "pb": 2.3,
      "pe": 12.67,
      "yield": 2.02,
      "date": "2009-01-27"
  },
  "2009-01-28": {
      "equity": 2849.5,
      "debt": 6.057,
      "gold": 15379.44232,
      "pb": 2.37,
      "pe": 13.1,
      "yield": 1.97,
      "date": "2009-01-28"
  },
  "2009-01-29": {
      "equity": 2823.9,
      "debt": 6.109,
      "gold": 15181.12136,
      "pb": 2.35,
      "pe": 13.17,
      "yield": 1.98,
      "date": "2009-01-29"
  },
  "2009-01-30": {
      "equity": 2874.8,
      "debt": 6.187,
      "gold": 15865.87065,
      "pb": 2.39,
      "pe": 13.4,
      "yield": 1.95,
      "date": "2009-01-30"
  },
  "2009-01-31": {
      "equity": 2874.8,
      "debt": 6.187,
      "gold": 15865.87065,
      "pb": 2.39,
      "pe": 13.4,
      "yield": 1.95,
      "date": "2009-01-31"
  },
  "2009-02-01": {
      "equity": 2874.8,
      "debt": 6.187,
      "gold": 15865.87065,
      "pb": 2.39,
      "pe": 13.4,
      "yield": 1.95,
      "date": "2009-02-01"
  },
  "2009-02-02": {
      "equity": 2766.6,
      "debt": 6.169,
      "gold": 15738.6938,
      "pb": 2.3,
      "pe": 13.12,
      "yield": 2.02,
      "date": "2009-02-02"
  },
  "2009-02-03": {
      "equity": 2783.9,
      "debt": 6.359,
      "gold": 15533.12757,
      "pb": 2.31,
      "pe": 13.2,
      "yield": 2.01,
      "date": "2009-02-03"
  },
  "2009-02-04": {
      "equity": 2803,
      "debt": 6.413,
      "gold": 15508.78851,
      "pb": 2.33,
      "pe": 13.29,
      "yield": 2,
      "date": "2009-02-04"
  },
  "2009-02-05": {
      "equity": 2780,
      "debt": 6.5,
      "gold": 15736.55973,
      "pb": 2.31,
      "pe": 13.19,
      "yield": 2.02,
      "date": "2009-02-05"
  },
  "2009-02-06": {
      "equity": 2843.1,
      "debt": 6.324,
      "gold": 15693.0281,
      "pb": 2.36,
      "pe": 13.49,
      "yield": 1.97,
      "date": "2009-02-06"
  },
  "2009-02-07": {
      "equity": 2843.1,
      "debt": 6.324,
      "gold": 15693.0281,
      "pb": 2.36,
      "pe": 13.49,
      "yield": 1.97,
      "date": "2009-02-07"
  },
  "2009-02-08": {
      "equity": 2843.1,
      "debt": 6.324,
      "gold": 15693.0281,
      "pb": 2.36,
      "pe": 13.49,
      "yield": 1.97,
      "date": "2009-02-08"
  },
  "2009-02-09": {
      "equity": 2919.9,
      "debt": 6.317,
      "gold": 15430.09577,
      "pb": 2.43,
      "pe": 13.86,
      "yield": 1.92,
      "date": "2009-02-09"
  },
  "2009-02-10": {
      "equity": 2934.5,
      "debt": 6.454,
      "gold": 15414.00025,
      "pb": 2.44,
      "pe": 13.93,
      "yield": 1.91,
      "date": "2009-02-10"
  },
  "2009-02-11": {
      "equity": 2925.7,
      "debt": 6.316,
      "gold": 15822.25436,
      "pb": 2.43,
      "pe": 13.89,
      "yield": 1.91,
      "date": "2009-02-11"
  },
  "2009-02-12": {
      "equity": 2893,
      "debt": 6.182,
      "gold": 16249.73985,
      "pb": 2.41,
      "pe": 13.73,
      "yield": 1.94,
      "date": "2009-02-12"
  },
  "2009-02-13": {
      "equity": 2948.3,
      "debt": 6.155,
      "gold": 16101.71255,
      "pb": 2.45,
      "pe": 14,
      "yield": 1.9,
      "date": "2009-02-13"
  },
  "2009-02-14": {
      "equity": 2948.3,
      "debt": 6.155,
      "gold": 16101.71255,
      "pb": 2.45,
      "pe": 14,
      "yield": 1.9,
      "date": "2009-02-14"
  },
  "2009-02-15": {
      "equity": 2948.3,
      "debt": 6.155,
      "gold": 16101.71255,
      "pb": 2.45,
      "pe": 14,
      "yield": 1.9,
      "date": "2009-02-15"
  },
  "2009-02-16": {
      "equity": 2848.5,
      "debt": 6.415,
      "gold": 16247.49643,
      "pb": 2.36,
      "pe": 13.52,
      "yield": 1.97,
      "date": "2009-02-16"
  },
  "2009-02-17": {
      "equity": 2770.5,
      "debt": 6.489,
      "gold": 16852.39951,
      "pb": 2.3,
      "pe": 13.15,
      "yield": 2.02,
      "date": "2009-02-17"
  },
  "2009-02-18": {
      "equity": 2776.1,
      "debt": 6.367,
      "gold": 16989.76702,
      "pb": 2.3,
      "pe": 13.18,
      "yield": 2.02,
      "date": "2009-02-18"
  },
  "2009-02-19": {
      "equity": 2789.3,
      "debt": 6.313,
      "gold": 17045.99376,
      "pb": 2.31,
      "pe": 13.24,
      "yield": 2.01,
      "date": "2009-02-19"
  },
  "2009-02-20": {
      "equity": 2736.4,
      "debt": 6.313,
      "gold": 17208.46223,
      "pb": 2.27,
      "pe": 12.99,
      "yield": 2.05,
      "date": "2009-02-20"
  },
  "2009-02-21": {
      "equity": 2736.4,
      "debt": 6.313,
      "gold": 17208.46223,
      "pb": 2.27,
      "pe": 12.99,
      "yield": 2.05,
      "date": "2009-02-21"
  },
  "2009-02-22": {
      "equity": 2736.4,
      "debt": 6.313,
      "gold": 17208.46223,
      "pb": 2.27,
      "pe": 12.99,
      "yield": 2.05,
      "date": "2009-02-22"
  },
  "2009-02-23": {
      "equity": 2736.4,
      "debt": 6.313,
      "gold": 17313.71276,
      "pb": 2.27,
      "pe": 12.99,
      "yield": 2.05,
      "date": "2009-02-23"
  },
  "2009-02-24": {
      "equity": 2733.9,
      "debt": 6.596,
      "gold": 17409.08305,
      "pb": 2.27,
      "pe": 12.98,
      "yield": 2.05,
      "date": "2009-02-24"
  },
  "2009-02-25": {
      "equity": 2762.5,
      "debt": 6.665,
      "gold": 16845.14013,
      "pb": 2.29,
      "pe": 13.11,
      "yield": 2.03,
      "date": "2009-02-25"
  },
  "2009-02-26": {
      "equity": 2785.6,
      "debt": 6.507,
      "gold": 16818.63172,
      "pb": 2.31,
      "pe": 13.22,
      "yield": 2.01,
      "date": "2009-02-26"
  },
  "2009-02-27": {
      "equity": 2763.6,
      "debt": 6.328,
      "gold": 17024.42018,
      "pb": 2.29,
      "pe": 13.12,
      "yield": 2.03,
      "date": "2009-02-27"
  },
  "2009-02-28": {
      "equity": 2763.6,
      "debt": 6.328,
      "gold": 17024.42018,
      "pb": 2.29,
      "pe": 13.12,
      "yield": 2.03,
      "date": "2009-02-28"
  },
  "2009-03-01": {
      "equity": 2763.6,
      "debt": 6.328,
      "gold": 17024.42018,
      "pb": 2.29,
      "pe": 13.12,
      "yield": 2.03,
      "date": "2009-03-01"
  },
  "2009-03-02": {
      "equity": 2674.6,
      "debt": 6.301,
      "gold": 17387.71054,
      "pb": 2.22,
      "pe": 12.7,
      "yield": 2.09,
      "date": "2009-03-02"
  },
  "2009-03-03": {
      "equity": 2622.4,
      "debt": 6.368,
      "gold": 16952.41891,
      "pb": 2.18,
      "pe": 12.45,
      "yield": 2.14,
      "date": "2009-03-03"
  },
  "2009-03-04": {
      "equity": 2645.2,
      "debt": 6.443,
      "gold": 16562.1757,
      "pb": 2.19,
      "pe": 12.56,
      "yield": 2.12,
      "date": "2009-03-04"
  },
  "2009-03-05": {
      "equity": 2576.7,
      "debt": 6.429,
      "gold": 16675.56394,
      "pb": 2.14,
      "pe": 12.23,
      "yield": 2.17,
      "date": "2009-03-05"
  },
  "2009-03-06": {
      "equity": 2620.1,
      "debt": 6.752,
      "gold": 17098.11461,
      "pb": 2.17,
      "pe": 12.44,
      "yield": 2.14,
      "date": "2009-03-06"
  },
  "2009-03-07": {
      "equity": 2620.1,
      "debt": 6.752,
      "gold": 17098.11461,
      "pb": 2.17,
      "pe": 12.44,
      "yield": 2.14,
      "date": "2009-03-07"
  },
  "2009-03-08": {
      "equity": 2620.1,
      "debt": 6.752,
      "gold": 17098.11461,
      "pb": 2.17,
      "pe": 12.44,
      "yield": 2.14,
      "date": "2009-03-08"
  },
  "2009-03-09": {
      "equity": 2573.1,
      "debt": 6.78,
      "gold": 17099.46913,
      "pb": 2.14,
      "pe": 12.21,
      "yield": 2.18,
      "date": "2009-03-09"
  },
  "2009-03-10": {
      "equity": 2573.1,
      "debt": 6.78,
      "gold": 16674.15298,
      "pb": 2.14,
      "pe": 12.21,
      "yield": 2.18,
      "date": "2009-03-10"
  },
  "2009-03-11": {
      "equity": 2573.1,
      "debt": 6.78,
      "gold": 16463.78243,
      "pb": 2.14,
      "pe": 12.21,
      "yield": 2.18,
      "date": "2009-03-11"
  },
  "2009-03-12": {
      "equity": 2617.4,
      "debt": 7.077,
      "gold": 16738.71144,
      "pb": 2.17,
      "pe": 12.42,
      "yield": 2.14,
      "date": "2009-03-12"
  },
  "2009-03-13": {
      "equity": 2719.2,
      "debt": 6.82,
      "gold": 16707.94899,
      "pb": 2.26,
      "pe": 12.91,
      "yield": 2.06,
      "date": "2009-03-13"
  },
  "2009-03-14": {
      "equity": 2719.2,
      "debt": 6.82,
      "gold": 16707.94899,
      "pb": 2.26,
      "pe": 12.91,
      "yield": 2.06,
      "date": "2009-03-14"
  },
  "2009-03-15": {
      "equity": 2719.2,
      "debt": 6.82,
      "gold": 16707.94899,
      "pb": 2.26,
      "pe": 12.91,
      "yield": 2.06,
      "date": "2009-03-15"
  },
  "2009-03-16": {
      "equity": 2777.2,
      "debt": 6.624,
      "gold": 16710.34057,
      "pb": 2.3,
      "pe": 13.18,
      "yield": 2.02,
      "date": "2009-03-16"
  },
  "2009-03-17": {
      "equity": 2757.4,
      "debt": 6.756,
      "gold": 16706.32639,
      "pb": 2.29,
      "pe": 13.09,
      "yield": 2.03,
      "date": "2009-03-17"
  },
  "2009-03-18": {
      "equity": 2794.7,
      "debt": 6.7,
      "gold": 16477.31706,
      "pb": 2.32,
      "pe": 13.27,
      "yield": 2,
      "date": "2009-03-18"
  },
  "2009-03-19": {
      "equity": 2807.1,
      "debt": 6.812,
      "gold": 16662.51609,
      "pb": 2.33,
      "pe": 13.33,
      "yield": 2,
      "date": "2009-03-19"
  },
  "2009-03-20": {
      "equity": 2807,
      "debt": 6.853,
      "gold": 17098.02642,
      "pb": 2.33,
      "pe": 13.33,
      "yield": 2,
      "date": "2009-03-20"
  },
  "2009-03-21": {
      "equity": 2807,
      "debt": 6.853,
      "gold": 17098.02642,
      "pb": 2.33,
      "pe": 13.33,
      "yield": 2,
      "date": "2009-03-21"
  },
  "2009-03-22": {
      "equity": 2807,
      "debt": 6.853,
      "gold": 17098.02642,
      "pb": 2.33,
      "pe": 13.33,
      "yield": 2,
      "date": "2009-03-22"
  },
  "2009-03-23": {
      "equity": 2939.9,
      "debt": 6.648,
      "gold": 16961.60073,
      "pb": 2.44,
      "pe": 13.96,
      "yield": 1.9,
      "date": "2009-03-23"
  },
  "2009-03-24": {
      "equity": 2938.7,
      "debt": 6.701,
      "gold": 16616.23662,
      "pb": 2.44,
      "pe": 13.96,
      "yield": 1.91,
      "date": "2009-03-24"
  },
  "2009-03-25": {
      "equity": 2984.3,
      "debt": 6.788,
      "gold": 16491.80409,
      "pb": 2.48,
      "pe": 14.17,
      "yield": 1.88,
      "date": "2009-03-25"
  },
  "2009-03-26": {
      "equity": 3082.2,
      "debt": 7.031,
      "gold": 16689.15148,
      "pb": 2.56,
      "pe": 14.64,
      "yield": 1.82,
      "date": "2009-03-26"
  },
  "2009-03-27": {
      "equity": 3108.6,
      "debt": 7.031,
      "gold": 16537.51565,
      "pb": 2.57,
      "pe": 14.7,
      "yield": 1.8,
      "date": "2009-03-27"
  },
  "2009-03-28": {
      "equity": 3108.6,
      "debt": 6.993,
      "gold": 16537.51565,
      "pb": 2.57,
      "pe": 14.7,
      "yield": 1.8,
      "date": "2009-03-28"
  },
  "2009-03-29": {
      "equity": 3108.6,
      "debt": 6.993,
      "gold": 16537.51565,
      "pb": 2.57,
      "pe": 14.7,
      "yield": 1.8,
      "date": "2009-03-29"
  },
  "2009-03-30": {
      "equity": 2978.1,
      "debt": 7.074,
      "gold": 16669.64144,
      "pb": 2.46,
      "pe": 14.08,
      "yield": 1.88,
      "date": "2009-03-30"
  },
  "2009-03-31": {
      "equity": 3020.9,
      "debt": 7.014,
      "gold": 16437.71495,
      "pb": 2.5,
      "pe": 14.29,
      "yield": 1.85,
      "date": "2009-03-31"
  },
  "2009-04-01": {
      "equity": 3060.3,
      "debt": 7.014,
      "gold": 16455.60945,
      "pb": 2.53,
      "pe": 14.49,
      "yield": 1.83,
      "date": "2009-04-01"
  },
  "2009-04-02": {
      "equity": 3211,
      "debt": 6.951,
      "gold": 16260.89702,
      "pb": 2.66,
      "pe": 15.2,
      "yield": 1.74,
      "date": "2009-04-02"
  },
  "2009-04-03": {
      "equity": 3211,
      "debt": 6.951,
      "gold": 16025.35847,
      "pb": 2.66,
      "pe": 15.2,
      "yield": 1.74,
      "date": "2009-04-03"
  },
  "2009-04-04": {
      "equity": 3211,
      "debt": 6.969,
      "gold": 16025.35847,
      "pb": 2.66,
      "pe": 15.2,
      "yield": 1.74,
      "date": "2009-04-04"
  },
  "2009-04-05": {
      "equity": 3211,
      "debt": 6.969,
      "gold": 16025.35847,
      "pb": 2.66,
      "pe": 15.2,
      "yield": 1.74,
      "date": "2009-04-05"
  },
  "2009-04-06": {
      "equity": 3256.6,
      "debt": 7.015,
      "gold": 15527.25092,
      "pb": 2.69,
      "pe": 15.42,
      "yield": 1.72,
      "date": "2009-04-06"
  },
  "2009-04-07": {
      "equity": 3256.6,
      "debt": 7.015,
      "gold": 15522.83462,
      "pb": 2.69,
      "pe": 15.42,
      "yield": 1.72,
      "date": "2009-04-07"
  },
  "2009-04-08": {
      "equity": 3342.9,
      "debt": 6.863,
      "gold": 15708.67211,
      "pb": 2.77,
      "pe": 15.82,
      "yield": 1.67,
      "date": "2009-04-08"
  },
  "2009-04-09": {
      "equity": 3342,
      "debt": 6.718,
      "gold": 15589.81287,
      "pb": 2.77,
      "pe": 15.82,
      "yield": 1.67,
      "date": "2009-04-09"
  },
  "2009-04-10": {
      "equity": 3342,
      "debt": 6.718,
      "gold": 15589.81287,
      "pb": 2.77,
      "pe": 15.82,
      "yield": 1.67,
      "date": "2009-04-10"
  },
  "2009-04-11": {
      "equity": 3342,
      "debt": 6.696,
      "gold": 15589.81287,
      "pb": 2.77,
      "pe": 15.82,
      "yield": 1.67,
      "date": "2009-04-11"
  },
  "2009-04-12": {
      "equity": 3342,
      "debt": 6.696,
      "gold": 15589.81287,
      "pb": 2.77,
      "pe": 15.82,
      "yield": 1.67,
      "date": "2009-04-12"
  },
  "2009-04-13": {
      "equity": 3382.6,
      "debt": 6.74,
      "gold": 15589.81287,
      "pb": 2.8,
      "pe": 16.01,
      "yield": 1.65,
      "date": "2009-04-13"
  },
  "2009-04-14": {
      "equity": 3382.6,
      "debt": 6.74,
      "gold": 15751.96388,
      "pb": 2.8,
      "pe": 16.01,
      "yield": 1.65,
      "date": "2009-04-14"
  },
  "2009-04-15": {
      "equity": 3484.1,
      "debt": 6.617,
      "gold": 15634.32159,
      "pb": 2.88,
      "pe": 16.45,
      "yield": 1.61,
      "date": "2009-04-15"
  },
  "2009-04-16": {
      "equity": 3369.5,
      "debt": 6.445,
      "gold": 15604.02829,
      "pb": 2.79,
      "pe": 15.9,
      "yield": 1.66,
      "date": "2009-04-16"
  },
  "2009-04-17": {
      "equity": 3384.4,
      "debt": 6.41,
      "gold": 15336.39747,
      "pb": 2.8,
      "pe": 15.97,
      "yield": 1.65,
      "date": "2009-04-17"
  },
  "2009-04-18": {
      "equity": 3384.4,
      "debt": 6.409,
      "gold": 15336.39747,
      "pb": 2.8,
      "pe": 15.97,
      "yield": 1.65,
      "date": "2009-04-18"
  },
  "2009-04-19": {
      "equity": 3384.4,
      "debt": 6.409,
      "gold": 15336.39747,
      "pb": 2.8,
      "pe": 15.97,
      "yield": 1.65,
      "date": "2009-04-19"
  },
  "2009-04-20": {
      "equity": 3377.1,
      "debt": 6.396,
      "gold": 15440.85434,
      "pb": 2.79,
      "pe": 15.94,
      "yield": 1.66,
      "date": "2009-04-20"
  },
  "2009-04-21": {
      "equity": 3365.3,
      "debt": 6.172,
      "gold": 15822.22261,
      "pb": 2.78,
      "pe": 15.86,
      "yield": 1.65,
      "date": "2009-04-21"
  },
  "2009-04-22": {
      "equity": 3330.3,
      "debt": 6.172,
      "gold": 15711.75858,
      "pb": 2.75,
      "pe": 15.69,
      "yield": 1.67,
      "date": "2009-04-22"
  },
  "2009-04-23": {
      "equity": 3423.7,
      "debt": 6.207,
      "gold": 15751.05028,
      "pb": 2.82,
      "pe": 16.12,
      "yield": 1.63,
      "date": "2009-04-23"
  },
  "2009-04-24": {
      "equity": 3480.7,
      "debt": 6.124,
      "gold": 15971.10707,
      "pb": 2.87,
      "pe": 16.43,
      "yield": 1.6,
      "date": "2009-04-24"
  },
  "2009-04-25": {
      "equity": 3480.7,
      "debt": 6.12,
      "gold": 15971.10707,
      "pb": 2.87,
      "pe": 16.43,
      "yield": 1.6,
      "date": "2009-04-25"
  },
  "2009-04-26": {
      "equity": 3480.7,
      "debt": 6.12,
      "gold": 15971.10707,
      "pb": 2.87,
      "pe": 16.43,
      "yield": 1.6,
      "date": "2009-04-26"
  },
  "2009-04-27": {
      "equity": 3470,
      "debt": 6.131,
      "gold": 16160.93406,
      "pb": 2.87,
      "pe": 16.53,
      "yield": 1.6,
      "date": "2009-04-27"
  },
  "2009-04-28": {
      "equity": 3362.3,
      "debt": 6.198,
      "gold": 15983.33657,
      "pb": 2.78,
      "pe": 16.02,
      "yield": 1.65,
      "date": "2009-04-28"
  },
  "2009-04-29": {
      "equity": 3473.9,
      "debt": 6.242,
      "gold": 15825.19974,
      "pb": 2.87,
      "pe": 16.53,
      "yield": 1.6,
      "date": "2009-04-29"
  },
  "2009-04-30": {
      "equity": 3473.9,
      "debt": 6.242,
      "gold": 15727.89644,
      "pb": 2.87,
      "pe": 16.53,
      "yield": 1.6,
      "date": "2009-04-30"
  },
  "2009-05-01": {
      "equity": 3473.9,
      "debt": 6.242,
      "gold": 15595.20626,
      "pb": 2.87,
      "pe": 16.53,
      "yield": 1.6,
      "date": "2009-05-01"
  },
  "2009-05-02": {
      "equity": 3473.9,
      "debt": 6.227,
      "gold": 15595.20626,
      "pb": 2.87,
      "pe": 16.53,
      "yield": 1.6,
      "date": "2009-05-02"
  },
  "2009-05-03": {
      "equity": 3473.9,
      "debt": 6.227,
      "gold": 15595.20626,
      "pb": 2.87,
      "pe": 16.53,
      "yield": 1.6,
      "date": "2009-05-03"
  },
  "2009-05-04": {
      "equity": 3654,
      "debt": 6.355,
      "gold": 15595.20626,
      "pb": 3.02,
      "pe": 17.37,
      "yield": 1.52,
      "date": "2009-05-04"
  },
  "2009-05-05": {
      "equity": 3661.9,
      "debt": 6.299,
      "gold": 15703.23992,
      "pb": 3.02,
      "pe": 17.4,
      "yield": 1.52,
      "date": "2009-05-05"
  },
  "2009-05-06": {
      "equity": 3625,
      "debt": 6.187,
      "gold": 15813.91912,
      "pb": 2.99,
      "pe": 17.23,
      "yield": 1.53,
      "date": "2009-05-06"
  },
  "2009-05-07": {
      "equity": 3683.9,
      "debt": 6.235,
      "gold": 15866.3539,
      "pb": 3.04,
      "pe": 17.51,
      "yield": 1.51,
      "date": "2009-05-07"
  },
  "2009-05-08": {
      "equity": 3620.7,
      "debt": 6.381,
      "gold": 15948.92326,
      "pb": 2.99,
      "pe": 17.21,
      "yield": 1.54,
      "date": "2009-05-08"
  },
  "2009-05-09": {
      "equity": 3620.7,
      "debt": 6.381,
      "gold": 15948.92326,
      "pb": 2.99,
      "pe": 17.21,
      "yield": 1.54,
      "date": "2009-05-09"
  },
  "2009-05-10": {
      "equity": 3620.7,
      "debt": 6.381,
      "gold": 15948.92326,
      "pb": 2.99,
      "pe": 17.21,
      "yield": 1.54,
      "date": "2009-05-10"
  },
  "2009-05-11": {
      "equity": 3554.6,
      "debt": 6.362,
      "gold": 15938.45394,
      "pb": 2.93,
      "pe": 16.78,
      "yield": 1.56,
      "date": "2009-05-11"
  },
  "2009-05-12": {
      "equity": 3681.1,
      "debt": 6.405,
      "gold": 16005.36165,
      "pb": 3.04,
      "pe": 17.38,
      "yield": 1.51,
      "date": "2009-05-12"
  },
  "2009-05-13": {
      "equity": 3635.2,
      "debt": 6.355,
      "gold": 16232.74837,
      "pb": 3,
      "pe": 17.17,
      "yield": 1.53,
      "date": "2009-05-13"
  },
  "2009-05-14": {
      "equity": 3593.4,
      "debt": 6.405,
      "gold": 16239.39047,
      "pb": 2.97,
      "pe": 16.97,
      "yield": 1.55,
      "date": "2009-05-14"
  },
  "2009-05-15": {
      "equity": 3671.6,
      "debt": 6.419,
      "gold": 16111.92437,
      "pb": 3.03,
      "pe": 17.34,
      "yield": 1.51,
      "date": "2009-05-15"
  },
  "2009-05-16": {
      "equity": 3671.6,
      "debt": 6.419,
      "gold": 16111.92437,
      "pb": 3.03,
      "pe": 17.34,
      "yield": 1.51,
      "date": "2009-05-16"
  },
  "2009-05-17": {
      "equity": 3671.6,
      "debt": 6.419,
      "gold": 16111.92437,
      "pb": 3.03,
      "pe": 17.34,
      "yield": 1.51,
      "date": "2009-05-17"
  },
  "2009-05-18": {
      "equity": 4323.1,
      "debt": 6.299,
      "gold": 15706.00187,
      "pb": 3.57,
      "pe": 20.41,
      "yield": 1.29,
      "date": "2009-05-18"
  },
  "2009-05-19": {
      "equity": 4318.4,
      "debt": 6.398,
      "gold": 15530.88062,
      "pb": 3.57,
      "pe": 20.39,
      "yield": 1.29,
      "date": "2009-05-19"
  },
  "2009-05-20": {
      "equity": 4270.3,
      "debt": 6.367,
      "gold": 15554.69761,
      "pb": 3.53,
      "pe": 20.18,
      "yield": 1.3,
      "date": "2009-05-20"
  },
  "2009-05-21": {
      "equity": 4210.9,
      "debt": 6.423,
      "gold": 15709.21886,
      "pb": 3.48,
      "pe": 19.85,
      "yield": 1.32,
      "date": "2009-05-21"
  },
  "2009-05-22": {
      "equity": 4238.5,
      "debt": 6.477,
      "gold": 15828.24388,
      "pb": 3.5,
      "pe": 19.87,
      "yield": 1.31,
      "date": "2009-05-22"
  },
  "2009-05-23": {
      "equity": 4238.5,
      "debt": 6.477,
      "gold": 15828.24388,
      "pb": 3.5,
      "pe": 19.87,
      "yield": 1.31,
      "date": "2009-05-23"
  },
  "2009-05-24": {
      "equity": 4238.5,
      "debt": 6.477,
      "gold": 15828.24388,
      "pb": 3.5,
      "pe": 19.87,
      "yield": 1.31,
      "date": "2009-05-24"
  },
  "2009-05-25": {
      "equity": 4237.5,
      "debt": 6.542,
      "gold": 15828.24388,
      "pb": 3.5,
      "pe": 19.85,
      "yield": 1.31,
      "date": "2009-05-25"
  },
  "2009-05-26": {
      "equity": 4116.7,
      "debt": 6.564,
      "gold": 15917.92095,
      "pb": 3.36,
      "pe": 19.29,
      "yield": 1.34,
      "date": "2009-05-26"
  },
  "2009-05-27": {
      "equity": 4276,
      "debt": 6.622,
      "gold": 15974.32053,
      "pb": 3.49,
      "pe": 20,
      "yield": 1.29,
      "date": "2009-05-27"
  },
  "2009-05-28": {
      "equity": 4337.1,
      "debt": 6.699,
      "gold": 15948.37651,
      "pb": 3.54,
      "pe": 20.32,
      "yield": 1.27,
      "date": "2009-05-28"
  },
  "2009-05-29": {
      "equity": 4448.9,
      "debt": 6.71,
      "gold": 16153.99919,
      "pb": 3.63,
      "pe": 20.82,
      "yield": 1.24,
      "date": "2009-05-29"
  },
  "2009-05-30": {
      "equity": 4448.9,
      "debt": 6.71,
      "gold": 16153.99919,
      "pb": 3.63,
      "pe": 20.82,
      "yield": 1.24,
      "date": "2009-05-30"
  },
  "2009-05-31": {
      "equity": 4448.9,
      "debt": 6.71,
      "gold": 16153.99919,
      "pb": 3.63,
      "pe": 20.82,
      "yield": 1.24,
      "date": "2009-05-31"
  },
  "2009-06-01": {
      "equity": 4529.9,
      "debt": 6.681,
      "gold": 16351.06792,
      "pb": 3.69,
      "pe": 20.62,
      "yield": 1.21,
      "date": "2009-06-01"
  },
  "2009-06-02": {
      "equity": 4525.2,
      "debt": 6.649,
      "gold": 16148.02377,
      "pb": 3.69,
      "pe": 20.6,
      "yield": 1.22,
      "date": "2009-06-02"
  },
  "2009-06-03": {
      "equity": 4530.7,
      "debt": 6.579,
      "gold": 16256.52304,
      "pb": 3.69,
      "pe": 20.62,
      "yield": 1.21,
      "date": "2009-06-03"
  },
  "2009-06-04": {
      "equity": 4572.6,
      "debt": 6.543,
      "gold": 16106.61564,
      "pb": 3.73,
      "pe": 20.87,
      "yield": 1.2,
      "date": "2009-06-04"
  },
  "2009-06-05": {
      "equity": 4586.9,
      "debt": 6.555,
      "gold": 16247.83506,
      "pb": 3.74,
      "pe": 20.93,
      "yield": 1.2,
      "date": "2009-06-05"
  },
  "2009-06-06": {
      "equity": 4586.9,
      "debt": 6.555,
      "gold": 16247.83506,
      "pb": 3.74,
      "pe": 20.93,
      "yield": 1.2,
      "date": "2009-06-06"
  },
  "2009-06-07": {
      "equity": 4586.9,
      "debt": 6.555,
      "gold": 16247.83506,
      "pb": 3.74,
      "pe": 20.93,
      "yield": 1.2,
      "date": "2009-06-07"
  },
  "2009-06-08": {
      "equity": 4429.9,
      "debt": 6.594,
      "gold": 15878.7774,
      "pb": 3.61,
      "pe": 20.21,
      "yield": 1.24,
      "date": "2009-06-08"
  },
  "2009-06-09": {
      "equity": 4550.9,
      "debt": 6.653,
      "gold": 15955.91809,
      "pb": 3.71,
      "pe": 20.77,
      "yield": 1.21,
      "date": "2009-06-09"
  },
  "2009-06-10": {
      "equity": 4655.2,
      "debt": 6.785,
      "gold": 16023.65827,
      "pb": 3.8,
      "pe": 21.24,
      "yield": 1.18,
      "date": "2009-06-10"
  },
  "2009-06-11": {
      "equity": 4637.7,
      "debt": 6.897,
      "gold": 16002.94891,
      "pb": 3.78,
      "pe": 21.16,
      "yield": 1.19,
      "date": "2009-06-11"
  },
  "2009-06-12": {
      "equity": 4583.4,
      "debt": 6.912,
      "gold": 15954.24963,
      "pb": 3.72,
      "pe": 20.91,
      "yield": 1.19,
      "date": "2009-06-12"
  },
  "2009-06-13": {
      "equity": 4583.4,
      "debt": 6.912,
      "gold": 15954.24963,
      "pb": 3.72,
      "pe": 20.91,
      "yield": 1.19,
      "date": "2009-06-13"
  },
  "2009-06-14": {
      "equity": 4583.4,
      "debt": 6.912,
      "gold": 15954.24963,
      "pb": 3.72,
      "pe": 20.91,
      "yield": 1.19,
      "date": "2009-06-14"
  },
  "2009-06-15": {
      "equity": 4484,
      "debt": 6.905,
      "gold": 15684.83395,
      "pb": 3.64,
      "pe": 20.47,
      "yield": 1.22,
      "date": "2009-06-15"
  },
  "2009-06-16": {
      "equity": 4517.8,
      "debt": 6.912,
      "gold": 15792.86054,
      "pb": 3.67,
      "pe": 20.63,
      "yield": 1.21,
      "date": "2009-06-16"
  },
  "2009-06-17": {
      "equity": 4356.1,
      "debt": 6.898,
      "gold": 15850.97444,
      "pb": 3.56,
      "pe": 19.5,
      "yield": 1.26,
      "date": "2009-06-17"
  },
  "2009-06-18": {
      "equity": 4251.4,
      "debt": 6.905,
      "gold": 15926.68301,
      "pb": 3.47,
      "pe": 19.03,
      "yield": 1.29,
      "date": "2009-06-18"
  },
  "2009-06-19": {
      "equity": 4313.6,
      "debt": 6.921,
      "gold": 15836.15231,
      "pb": 3.52,
      "pe": 19.31,
      "yield": 1.27,
      "date": "2009-06-19"
  },
  "2009-06-20": {
      "equity": 4313.6,
      "debt": 6.921,
      "gold": 15836.15231,
      "pb": 3.52,
      "pe": 19.31,
      "yield": 1.27,
      "date": "2009-06-20"
  },
  "2009-06-21": {
      "equity": 4313.6,
      "debt": 6.921,
      "gold": 15836.15231,
      "pb": 3.52,
      "pe": 19.31,
      "yield": 1.27,
      "date": "2009-06-21"
  },
  "2009-06-22": {
      "equity": 4235.2,
      "debt": 6.951,
      "gold": 15848.42766,
      "pb": 3.46,
      "pe": 18.96,
      "yield": 1.3,
      "date": "2009-06-22"
  },
  "2009-06-23": {
      "equity": 4247,
      "debt": 7.012,
      "gold": 15758.14036,
      "pb": 3.47,
      "pe": 19.01,
      "yield": 1.29,
      "date": "2009-06-23"
  },
  "2009-06-24": {
      "equity": 4292.9,
      "debt": 7.004,
      "gold": 15908.60509,
      "pb": 3.51,
      "pe": 19.22,
      "yield": 1.28,
      "date": "2009-06-24"
  },
  "2009-06-25": {
      "equity": 4241.8,
      "debt": 7.002,
      "gold": 16014.34946,
      "pb": 3.46,
      "pe": 19.07,
      "yield": 1.3,
      "date": "2009-06-25"
  },
  "2009-06-26": {
      "equity": 4375.5,
      "debt": 6.998,
      "gold": 15998.02113,
      "pb": 3.63,
      "pe": 20.08,
      "yield": 1.14,
      "date": "2009-06-26"
  },
  "2009-06-27": {
      "equity": 4375.5,
      "debt": 6.998,
      "gold": 15998.02113,
      "pb": 3.63,
      "pe": 20.08,
      "yield": 1.14,
      "date": "2009-06-27"
  },
  "2009-06-28": {
      "equity": 4375.5,
      "debt": 6.998,
      "gold": 15998.02113,
      "pb": 3.63,
      "pe": 20.08,
      "yield": 1.14,
      "date": "2009-06-28"
  },
  "2009-06-29": {
      "equity": 4390.9,
      "debt": 6.998,
      "gold": 15946.19658,
      "pb": 3.64,
      "pe": 20.25,
      "yield": 1.13,
      "date": "2009-06-29"
  },
  "2009-06-30": {
      "equity": 4291.1,
      "debt": 7.013,
      "gold": 15901.02471,
      "pb": 3.56,
      "pe": 19.97,
      "yield": 1.16,
      "date": "2009-06-30"
  },
  "2009-07-01": {
      "equity": 4340.9,
      "debt": 6.982,
      "gold": 15735.565,
      "pb": 3.6,
      "pe": 20.2,
      "yield": 1.15,
      "date": "2009-07-01"
  },
  "2009-07-02": {
      "equity": 4348.8,
      "debt": 6.878,
      "gold": 15833.04115,
      "pb": 3.61,
      "pe": 20.23,
      "yield": 1.15,
      "date": "2009-07-02"
  },
  "2009-07-03": {
      "equity": 4424.2,
      "debt": 6.834,
      "gold": 15755.74525,
      "pb": 3.67,
      "pe": 20.58,
      "yield": 1.13,
      "date": "2009-07-03"
  },
  "2009-07-04": {
      "equity": 4424.2,
      "debt": 6.834,
      "gold": 15755.74525,
      "pb": 3.67,
      "pe": 20.58,
      "yield": 1.13,
      "date": "2009-07-04"
  },
  "2009-07-05": {
      "equity": 4424.2,
      "debt": 6.834,
      "gold": 15755.74525,
      "pb": 3.67,
      "pe": 20.58,
      "yield": 1.13,
      "date": "2009-07-05"
  },
  "2009-07-06": {
      "equity": 4165.7,
      "debt": 7.022,
      "gold": 15789.29434,
      "pb": 3.37,
      "pe": 19.38,
      "yield": 1.22,
      "date": "2009-07-06"
  },
  "2009-07-07": {
      "equity": 4202.1,
      "debt": 7.076,
      "gold": 15863.75068,
      "pb": 3.4,
      "pe": 19.55,
      "yield": 1.21,
      "date": "2009-07-07"
  },
  "2009-07-08": {
      "equity": 4078.9,
      "debt": 7.008,
      "gold": 15878.75271,
      "pb": 3.3,
      "pe": 18.98,
      "yield": 1.25,
      "date": "2009-07-08"
  },
  "2009-07-09": {
      "equity": 4080.9,
      "debt": 6.985,
      "gold": 15717.19783,
      "pb": 3.3,
      "pe": 18.99,
      "yield": 1.25,
      "date": "2009-07-09"
  },
  "2009-07-10": {
      "equity": 4003.9,
      "debt": 7.024,
      "gold": 15728.67246,
      "pb": 3.24,
      "pe": 18.58,
      "yield": 1.27,
      "date": "2009-07-10"
  },
  "2009-07-11": {
      "equity": 4003.9,
      "debt": 7.024,
      "gold": 15728.67246,
      "pb": 3.24,
      "pe": 18.58,
      "yield": 1.27,
      "date": "2009-07-11"
  },
  "2009-07-12": {
      "equity": 4003.9,
      "debt": 7.024,
      "gold": 15728.67246,
      "pb": 3.24,
      "pe": 18.58,
      "yield": 1.27,
      "date": "2009-07-12"
  },
  "2009-07-13": {
      "equity": 3974,
      "debt": 6.994,
      "gold": 15731.58962,
      "pb": 3.22,
      "pe": 18.42,
      "yield": 1.28,
      "date": "2009-07-13"
  },
  "2009-07-14": {
      "equity": 4111.4,
      "debt": 7.017,
      "gold": 15922.00921,
      "pb": 3.33,
      "pe": 19.02,
      "yield": 1.23,
      "date": "2009-07-14"
  },
  "2009-07-15": {
      "equity": 4233.5,
      "debt": 7.032,
      "gold": 15954.62001,
      "pb": 3.4,
      "pe": 19.58,
      "yield": 1.21,
      "date": "2009-07-15"
  },
  "2009-07-16": {
      "equity": 4231.4,
      "debt": 7.01,
      "gold": 16056.2338,
      "pb": 3.4,
      "pe": 19.21,
      "yield": 1.21,
      "date": "2009-07-16"
  },
  "2009-07-17": {
      "equity": 4374.9,
      "debt": 7.01,
      "gold": 16063.13692,
      "pb": 3.52,
      "pe": 19.87,
      "yield": 1.17,
      "date": "2009-07-17"
  },
  "2009-07-18": {
      "equity": 4374.9,
      "debt": 7.01,
      "gold": 16063.13692,
      "pb": 3.52,
      "pe": 19.87,
      "yield": 1.17,
      "date": "2009-07-18"
  },
  "2009-07-19": {
      "equity": 4374.9,
      "debt": 7.01,
      "gold": 16063.13692,
      "pb": 3.52,
      "pe": 19.87,
      "yield": 1.17,
      "date": "2009-07-19"
  },
  "2009-07-20": {
      "equity": 4502.2,
      "debt": 7.056,
      "gold": 16190.21499,
      "pb": 3.62,
      "pe": 20.44,
      "yield": 1.14,
      "date": "2009-07-20"
  },
  "2009-07-21": {
      "equity": 4469.1,
      "debt": 7.057,
      "gold": 16188.92397,
      "pb": 3.59,
      "pe": 20.29,
      "yield": 1.15,
      "date": "2009-07-21"
  },
  "2009-07-22": {
      "equity": 4398.9,
      "debt": 6.988,
      "gold": 16182.17605,
      "pb": 3.54,
      "pe": 19.89,
      "yield": 1.17,
      "date": "2009-07-22"
  },
  "2009-07-23": {
      "equity": 4523.7,
      "debt": 7.004,
      "gold": 16325.459,
      "pb": 3.64,
      "pe": 20.27,
      "yield": 1.13,
      "date": "2009-07-23"
  },
  "2009-07-24": {
      "equity": 4568.5,
      "debt": 7.043,
      "gold": 16157.7594,
      "pb": 3.67,
      "pe": 20.6,
      "yield": 1.12,
      "date": "2009-07-24"
  },
  "2009-07-25": {
      "equity": 4568.5,
      "debt": 7.043,
      "gold": 16157.7594,
      "pb": 3.67,
      "pe": 20.6,
      "yield": 1.12,
      "date": "2009-07-25"
  },
  "2009-07-26": {
      "equity": 4568.5,
      "debt": 7.043,
      "gold": 16157.7594,
      "pb": 3.67,
      "pe": 20.6,
      "yield": 1.12,
      "date": "2009-07-26"
  },
  "2009-07-27": {
      "equity": 4572.3,
      "debt": 6.949,
      "gold": 16242.17006,
      "pb": 3.68,
      "pe": 20.59,
      "yield": 1.12,
      "date": "2009-07-27"
  },
  "2009-07-28": {
      "equity": 4564.1,
      "debt": 6.906,
      "gold": 16242.02543,
      "pb": 3.67,
      "pe": 20.3,
      "yield": 1.12,
      "date": "2009-07-28"
  },
  "2009-07-29": {
      "equity": 4513.5,
      "debt": 6.906,
      "gold": 15978.02783,
      "pb": 3.63,
      "pe": 20.08,
      "yield": 1.14,
      "date": "2009-07-29"
  },
  "2009-07-30": {
      "equity": 4571.4,
      "debt": 6.96,
      "gold": 15896.88002,
      "pb": 3.68,
      "pe": 20.31,
      "yield": 1.12,
      "date": "2009-07-30"
  },
  "2009-07-31": {
      "equity": 4636.4,
      "debt": 6.998,
      "gold": 15877.01018,
      "pb": 3.73,
      "pe": 20.68,
      "yield": 1.11,
      "date": "2009-07-31"
  },
  "2009-08-01": {
      "equity": 4636.4,
      "debt": 6.998,
      "gold": 15877.01018,
      "pb": 3.73,
      "pe": 20.68,
      "yield": 1.11,
      "date": "2009-08-01"
  },
  "2009-08-02": {
      "equity": 4636.4,
      "debt": 6.998,
      "gold": 15877.01018,
      "pb": 3.73,
      "pe": 20.68,
      "yield": 1.11,
      "date": "2009-08-02"
  },
  "2009-08-03": {
      "equity": 4711.4,
      "debt": 7.012,
      "gold": 16034.0394,
      "pb": 3.79,
      "pe": 21.09,
      "yield": 1.09,
      "date": "2009-08-03"
  },
  "2009-08-04": {
      "equity": 4680.5,
      "debt": 7.008,
      "gold": 16058.43489,
      "pb": 3.77,
      "pe": 20.99,
      "yield": 1.09,
      "date": "2009-08-04"
  },
  "2009-08-05": {
      "equity": 4694.1,
      "debt": 7.062,
      "gold": 16170.54622,
      "pb": 3.78,
      "pe": 21.05,
      "yield": 1.09,
      "date": "2009-08-05"
  },
  "2009-08-06": {
      "equity": 4585.5,
      "debt": 7.091,
      "gold": 16160.20036,
      "pb": 3.69,
      "pe": 20.56,
      "yield": 1.12,
      "date": "2009-08-06"
  },
  "2009-08-07": {
      "equity": 4481.4,
      "debt": 7.041,
      "gold": 16211.90144,
      "pb": 3.61,
      "pe": 20.1,
      "yield": 1.14,
      "date": "2009-08-07"
  },
  "2009-08-08": {
      "equity": 4481.4,
      "debt": 7.041,
      "gold": 16211.90144,
      "pb": 3.61,
      "pe": 20.1,
      "yield": 1.14,
      "date": "2009-08-08"
  },
  "2009-08-09": {
      "equity": 4481.4,
      "debt": 7.041,
      "gold": 16211.90144,
      "pb": 3.61,
      "pe": 20.1,
      "yield": 1.14,
      "date": "2009-08-09"
  },
  "2009-08-10": {
      "equity": 4437.6,
      "debt": 7.095,
      "gold": 16080.29771,
      "pb": 3.57,
      "pe": 19.9,
      "yield": 1.15,
      "date": "2009-08-10"
  },
  "2009-08-11": {
      "equity": 4471.3,
      "debt": 7.055,
      "gold": 16005.53449,
      "pb": 3.6,
      "pe": 20.06,
      "yield": 1.15,
      "date": "2009-08-11"
  },
  "2009-08-12": {
      "equity": 4457.5,
      "debt": 7.041,
      "gold": 16096.36149,
      "pb": 3.59,
      "pe": 19.99,
      "yield": 1.15,
      "date": "2009-08-12"
  },
  "2009-08-13": {
      "equity": 4605,
      "debt": 7.036,
      "gold": 16223.62299,
      "pb": 3.71,
      "pe": 20.66,
      "yield": 1.11,
      "date": "2009-08-13"
  },
  "2009-08-14": {
      "equity": 4580,
      "debt": 7.112,
      "gold": 16294.67539,
      "pb": 3.58,
      "pe": 20.54,
      "yield": 1.12,
      "date": "2009-08-14"
  },
  "2009-08-15": {
      "equity": 4580,
      "debt": 7.112,
      "gold": 16294.67539,
      "pb": 3.58,
      "pe": 20.54,
      "yield": 1.12,
      "date": "2009-08-15"
  },
  "2009-08-16": {
      "equity": 4580,
      "debt": 7.112,
      "gold": 16294.67539,
      "pb": 3.58,
      "pe": 20.54,
      "yield": 1.12,
      "date": "2009-08-16"
  },
  "2009-08-17": {
      "equity": 4387.9,
      "debt": 7.098,
      "gold": 16191.58715,
      "pb": 3.43,
      "pe": 19.68,
      "yield": 1.17,
      "date": "2009-08-17"
  },
  "2009-08-18": {
      "equity": 4458.9,
      "debt": 7.134,
      "gold": 16150.76104,
      "pb": 3.49,
      "pe": 20,
      "yield": 1.15,
      "date": "2009-08-18"
  },
  "2009-08-19": {
      "equity": 4394.1,
      "debt": 7.134,
      "gold": 16081.90621,
      "pb": 3.44,
      "pe": 19.71,
      "yield": 1.16,
      "date": "2009-08-19"
  },
  "2009-08-20": {
      "equity": 4453.4,
      "debt": 7.184,
      "gold": 16199.53086,
      "pb": 3.48,
      "pe": 19.98,
      "yield": 1.15,
      "date": "2009-08-20"
  },
  "2009-08-21": {
      "equity": 4528.8,
      "debt": 7.33,
      "gold": 16145.26535,
      "pb": 3.54,
      "pe": 20.31,
      "yield": 1.13,
      "date": "2009-08-21"
  },
  "2009-08-22": {
      "equity": 4528.8,
      "debt": 7.33,
      "gold": 16145.26535,
      "pb": 3.54,
      "pe": 20.31,
      "yield": 1.13,
      "date": "2009-08-22"
  },
  "2009-08-23": {
      "equity": 4528.8,
      "debt": 7.33,
      "gold": 16145.26535,
      "pb": 3.54,
      "pe": 20.31,
      "yield": 1.13,
      "date": "2009-08-23"
  },
  "2009-08-24": {
      "equity": 4642.8,
      "debt": 7.341,
      "gold": 16355.33607,
      "pb": 3.63,
      "pe": 20.82,
      "yield": 1.1,
      "date": "2009-08-24"
  },
  "2009-08-25": {
      "equity": 4659.3,
      "debt": 7.316,
      "gold": 16271.55329,
      "pb": 3.64,
      "pe": 20.88,
      "yield": 1.1,
      "date": "2009-08-25"
  },
  "2009-08-26": {
      "equity": 4680.8,
      "debt": 7.228,
      "gold": 16386.28195,
      "pb": 3.66,
      "pe": 20.97,
      "yield": 1.09,
      "date": "2009-08-26"
  },
  "2009-08-27": {
      "equity": 4688.2,
      "debt": 7.272,
      "gold": 16277.74388,
      "pb": 3.67,
      "pe": 21.01,
      "yield": 1.09,
      "date": "2009-08-27"
  },
  "2009-08-28": {
      "equity": 4732.3,
      "debt": 7.343,
      "gold": 16317.74105,
      "pb": 3.71,
      "pe": 21.26,
      "yield": 1.08,
      "date": "2009-08-28"
  },
  "2009-08-29": {
      "equity": 4732.3,
      "debt": 7.343,
      "gold": 16317.74105,
      "pb": 3.71,
      "pe": 21.26,
      "yield": 1.08,
      "date": "2009-08-29"
  },
  "2009-08-30": {
      "equity": 4732.3,
      "debt": 7.343,
      "gold": 16317.74105,
      "pb": 3.71,
      "pe": 21.26,
      "yield": 1.08,
      "date": "2009-08-30"
  },
  "2009-08-31": {
      "equity": 4662.1,
      "debt": 7.434,
      "gold": 16317.74105,
      "pb": 3.66,
      "pe": 20.94,
      "yield": 1.09,
      "date": "2009-08-31"
  },
  "2009-09-01": {
      "equity": 4625.3,
      "debt": 7.424,
      "gold": 16429.12221,
      "pb": 3.63,
      "pe": 20.78,
      "yield": 1.1,
      "date": "2009-09-01"
  },
  "2009-09-02": {
      "equity": 4608.3,
      "debt": 7.397,
      "gold": 16496.35796,
      "pb": 3.61,
      "pe": 20.7,
      "yield": 1.11,
      "date": "2009-09-02"
  },
  "2009-09-03": {
      "equity": 4593.5,
      "debt": 7.454,
      "gold": 16952.32367,
      "pb": 3.6,
      "pe": 20.63,
      "yield": 1.11,
      "date": "2009-09-03"
  },
  "2009-09-04": {
      "equity": 4680.4,
      "debt": 7.461,
      "gold": 17023.83464,
      "pb": 3.67,
      "pe": 21.02,
      "yield": 1.09,
      "date": "2009-09-04"
  },
  "2009-09-05": {
      "equity": 4680.4,
      "debt": 7.461,
      "gold": 17023.83464,
      "pb": 3.67,
      "pe": 21.02,
      "yield": 1.09,
      "date": "2009-09-05"
  },
  "2009-09-06": {
      "equity": 4680.4,
      "debt": 7.461,
      "gold": 17023.83464,
      "pb": 3.67,
      "pe": 21.02,
      "yield": 1.09,
      "date": "2009-09-06"
  },
  "2009-09-07": {
      "equity": 4782.9,
      "debt": 7.297,
      "gold": 17038.13118,
      "pb": 3.75,
      "pe": 21.49,
      "yield": 1.07,
      "date": "2009-09-07"
  },
  "2009-09-08": {
      "equity": 4805.2,
      "debt": 7.376,
      "gold": 17172.46865,
      "pb": 3.77,
      "pe": 21.59,
      "yield": 1.06,
      "date": "2009-09-08"
  },
  "2009-09-09": {
      "equity": 4814.2,
      "debt": 7.343,
      "gold": 17036.93187,
      "pb": 3.78,
      "pe": 21.63,
      "yield": 1.06,
      "date": "2009-09-09"
  },
  "2009-09-10": {
      "equity": 4819.4,
      "debt": 7.42,
      "gold": 16958.21796,
      "pb": 3.78,
      "pe": 21.66,
      "yield": 1.06,
      "date": "2009-09-10"
  },
  "2009-09-11": {
      "equity": 4829.5,
      "debt": 7.368,
      "gold": 17069.14408,
      "pb": 3.79,
      "pe": 21.7,
      "yield": 1.06,
      "date": "2009-09-11"
  },
  "2009-09-12": {
      "equity": 4829.5,
      "debt": 7.368,
      "gold": 17069.14408,
      "pb": 3.79,
      "pe": 21.7,
      "yield": 1.06,
      "date": "2009-09-12"
  },
  "2009-09-13": {
      "equity": 4829.5,
      "debt": 7.368,
      "gold": 17069.14408,
      "pb": 3.79,
      "pe": 21.7,
      "yield": 1.06,
      "date": "2009-09-13"
  },
  "2009-09-14": {
      "equity": 4808.6,
      "debt": 7.233,
      "gold": 17091.93107,
      "pb": 3.78,
      "pe": 21.66,
      "yield": 1.06,
      "date": "2009-09-14"
  },
  "2009-09-15": {
      "equity": 4892.1,
      "debt": 7.106,
      "gold": 17114.37591,
      "pb": 3.85,
      "pe": 22.03,
      "yield": 1.04,
      "date": "2009-09-15"
  },
  "2009-09-16": {
      "equity": 4958.4,
      "debt": 7.084,
      "gold": 17303.65615,
      "pb": 3.9,
      "pe": 22.33,
      "yield": 1.03,
      "date": "2009-09-16"
  },
  "2009-09-17": {
      "equity": 4965.5,
      "debt": 7.149,
      "gold": 17334.40801,
      "pb": 3.9,
      "pe": 22.36,
      "yield": 1.02,
      "date": "2009-09-17"
  },
  "2009-09-18": {
      "equity": 4976,
      "debt": 7.113,
      "gold": 17216.84333,
      "pb": 3.91,
      "pe": 22.41,
      "yield": 1.02,
      "date": "2009-09-18"
  },
  "2009-09-19": {
      "equity": 4976,
      "debt": 7.113,
      "gold": 17216.84333,
      "pb": 3.91,
      "pe": 22.41,
      "yield": 1.02,
      "date": "2009-09-19"
  },
  "2009-09-20": {
      "equity": 4976,
      "debt": 7.113,
      "gold": 17216.84333,
      "pb": 3.91,
      "pe": 22.41,
      "yield": 1.02,
      "date": "2009-09-20"
  },
  "2009-09-21": {
      "equity": 4976,
      "debt": 7.113,
      "gold": 16966.40152,
      "pb": 3.91,
      "pe": 22.41,
      "yield": 1.02,
      "date": "2009-09-21"
  },
  "2009-09-22": {
      "equity": 5020.2,
      "debt": 7.135,
      "gold": 17185.64701,
      "pb": 3.95,
      "pe": 22.61,
      "yield": 1.01,
      "date": "2009-09-22"
  },
  "2009-09-23": {
      "equity": 4969.9,
      "debt": 7.091,
      "gold": 17179.46701,
      "pb": 3.68,
      "pe": 22.38,
      "yield": 1.02,
      "date": "2009-09-23"
  },
  "2009-09-24": {
      "equity": 4986.5,
      "debt": 7.106,
      "gold": 17159.61481,
      "pb": 3.69,
      "pe": 22.46,
      "yield": 1.02,
      "date": "2009-09-24"
  },
  "2009-09-25": {
      "equity": 4958.9,
      "debt": 7.179,
      "gold": 16900.06526,
      "pb": 3.67,
      "pe": 22.33,
      "yield": 1.02,
      "date": "2009-09-25"
  },
  "2009-09-26": {
      "equity": 4958.9,
      "debt": 7.179,
      "gold": 16900.06526,
      "pb": 3.67,
      "pe": 22.33,
      "yield": 1.02,
      "date": "2009-09-26"
  },
  "2009-09-27": {
      "equity": 4958.9,
      "debt": 7.179,
      "gold": 16900.06526,
      "pb": 3.67,
      "pe": 22.33,
      "yield": 1.02,
      "date": "2009-09-27"
  },
  "2009-09-28": {
      "equity": 4958.9,
      "debt": 7.179,
      "gold": 16789.88342,
      "pb": 3.67,
      "pe": 22.33,
      "yield": 1.02,
      "date": "2009-09-28"
  },
  "2009-09-29": {
      "equity": 5006.8,
      "debt": 7.215,
      "gold": 16828.56135,
      "pb": 3.7,
      "pe": 22.55,
      "yield": 1.01,
      "date": "2009-09-29"
  },
  "2009-09-30": {
      "equity": 5083.9,
      "debt": 7.215,
      "gold": 16989.76349,
      "pb": 3.76,
      "pe": 22.9,
      "yield": 1,
      "date": "2009-09-30"
  },
  "2009-10-01": {
      "equity": 5083.4,
      "debt": 7.223,
      "gold": 16938.40456,
      "pb": 3.76,
      "pe": 22.89,
      "yield": 1,
      "date": "2009-10-01"
  },
  "2009-10-02": {
      "equity": 5083.4,
      "debt": 7.223,
      "gold": 16807.88374,
      "pb": 3.76,
      "pe": 22.89,
      "yield": 1,
      "date": "2009-10-02"
  },
  "2009-10-03": {
      "equity": 5083.4,
      "debt": 7.223,
      "gold": 16807.88374,
      "pb": 3.76,
      "pe": 22.89,
      "yield": 1,
      "date": "2009-10-03"
  },
  "2009-10-04": {
      "equity": 5083.4,
      "debt": 7.223,
      "gold": 16807.88374,
      "pb": 3.76,
      "pe": 22.89,
      "yield": 1,
      "date": "2009-10-04"
  },
  "2009-10-05": {
      "equity": 5003.2,
      "debt": 7.164,
      "gold": 16833.43974,
      "pb": 3.7,
      "pe": 22.53,
      "yield": 1.01,
      "date": "2009-10-05"
  },
  "2009-10-06": {
      "equity": 5027.4,
      "debt": 7.223,
      "gold": 16874.90785,
      "pb": 3.72,
      "pe": 22.67,
      "yield": 1.01,
      "date": "2009-10-06"
  },
  "2009-10-07": {
      "equity": 4985.7,
      "debt": 7.26,
      "gold": 17241.64447,
      "pb": 3.69,
      "pe": 22.48,
      "yield": 1.02,
      "date": "2009-10-07"
  },
  "2009-10-08": {
      "equity": 5002.2,
      "debt": 7.216,
      "gold": 17242.76971,
      "pb": 3.71,
      "pe": 22.58,
      "yield": 1.01,
      "date": "2009-10-08"
  },
  "2009-10-09": {
      "equity": 4945.2,
      "debt": 7.326,
      "gold": 17134.13993,
      "pb": 3.67,
      "pe": 22.31,
      "yield": 1.02,
      "date": "2009-10-09"
  },
  "2009-10-10": {
      "equity": 4945.2,
      "debt": 7.326,
      "gold": 17134.13993,
      "pb": 3.67,
      "pe": 22.31,
      "yield": 1.02,
      "date": "2009-10-10"
  },
  "2009-10-11": {
      "equity": 4945.2,
      "debt": 7.326,
      "gold": 17134.13993,
      "pb": 3.67,
      "pe": 22.31,
      "yield": 1.02,
      "date": "2009-10-11"
  },
  "2009-10-12": {
      "equity": 5054.2,
      "debt": 7.356,
      "gold": 17251.61996,
      "pb": 3.75,
      "pe": 22.8,
      "yield": 1,
      "date": "2009-10-12"
  },
  "2009-10-13": {
      "equity": 5054.2,
      "debt": 7.356,
      "gold": 17456.6077,
      "pb": 3.75,
      "pe": 22.8,
      "yield": 1,
      "date": "2009-10-13"
  },
  "2009-10-14": {
      "equity": 5118.2,
      "debt": 7.371,
      "gold": 17347.71689,
      "pb": 3.79,
      "pe": 22.97,
      "yield": 0.99,
      "date": "2009-10-14"
  },
  "2009-10-15": {
      "equity": 5108.8,
      "debt": 7.371,
      "gold": 17098.32272,
      "pb": 3.79,
      "pe": 22.93,
      "yield": 0.99,
      "date": "2009-10-15"
  },
  "2009-10-16": {
      "equity": 5142.1,
      "debt": 7.409,
      "gold": 17150.51059,
      "pb": 3.81,
      "pe": 23.08,
      "yield": 0.98,
      "date": "2009-10-16"
  },
  "2009-10-17": {
      "equity": 5141.8,
      "debt": 7.409,
      "gold": 17150.51059,
      "pb": 3.81,
      "pe": 23.08,
      "yield": 0.98,
      "date": "2009-10-17"
  },
  "2009-10-18": {
      "equity": 5141.8,
      "debt": 7.409,
      "gold": 17150.51059,
      "pb": 3.81,
      "pe": 23.08,
      "yield": 0.98,
      "date": "2009-10-18"
  },
  "2009-10-19": {
      "equity": 5141.8,
      "debt": 7.409,
      "gold": 17260.99931,
      "pb": 3.81,
      "pe": 23.08,
      "yield": 0.98,
      "date": "2009-10-19"
  },
  "2009-10-20": {
      "equity": 5114.4,
      "debt": 7.405,
      "gold": 17307.66327,
      "pb": 3.79,
      "pe": 22.94,
      "yield": 0.99,
      "date": "2009-10-20"
  },
  "2009-10-21": {
      "equity": 5063.6,
      "debt": 7.35,
      "gold": 17249.27777,
      "pb": 3.75,
      "pe": 22.71,
      "yield": 1,
      "date": "2009-10-21"
  },
  "2009-10-22": {
      "equity": 4988.6,
      "debt": 7.384,
      "gold": 17389.73174,
      "pb": 3.73,
      "pe": 22.44,
      "yield": 1,
      "date": "2009-10-22"
  },
  "2009-10-23": {
      "equity": 4997,
      "debt": 7.462,
      "gold": 17393.01575,
      "pb": 3.74,
      "pe": 22.19,
      "yield": 1,
      "date": "2009-10-23"
  },
  "2009-10-24": {
      "equity": 4997,
      "debt": 7.462,
      "gold": 17393.01575,
      "pb": 3.74,
      "pe": 22.19,
      "yield": 1,
      "date": "2009-10-24"
  },
  "2009-10-25": {
      "equity": 4997,
      "debt": 7.462,
      "gold": 17393.01575,
      "pb": 3.74,
      "pe": 22.19,
      "yield": 1,
      "date": "2009-10-25"
  },
  "2009-10-26": {
      "equity": 4970.9,
      "debt": 7.428,
      "gold": 17350.1261,
      "pb": 3.72,
      "pe": 21.96,
      "yield": 1.01,
      "date": "2009-10-26"
  },
  "2009-10-27": {
      "equity": 4846.7,
      "debt": 7.335,
      "gold": 17223.15737,
      "pb": 3.63,
      "pe": 21.42,
      "yield": 1.03,
      "date": "2009-10-27"
  },
  "2009-10-28": {
      "equity": 4826.1,
      "debt": 7.266,
      "gold": 17239.46101,
      "pb": 3.61,
      "pe": 21.33,
      "yield": 1.04,
      "date": "2009-10-28"
  },
  "2009-10-29": {
      "equity": 4750.5,
      "debt": 7.266,
      "gold": 17217.22429,
      "pb": 3.56,
      "pe": 21,
      "yield": 1.05,
      "date": "2009-10-29"
  },
  "2009-10-30": {
      "equity": 4711.7,
      "debt": 7.306,
      "gold": 17303.63498,
      "pb": 3.53,
      "pe": 20.45,
      "yield": 1.06,
      "date": "2009-10-30"
  },
  "2009-10-31": {
      "equity": 4711.7,
      "debt": 7.306,
      "gold": 17303.63498,
      "pb": 3.53,
      "pe": 20.45,
      "yield": 1.06,
      "date": "2009-10-31"
  },
  "2009-11-01": {
      "equity": 4711.7,
      "debt": 7.306,
      "gold": 17303.63498,
      "pb": 3.53,
      "pe": 20.45,
      "yield": 1.06,
      "date": "2009-11-01"
  },
  "2009-11-02": {
      "equity": 4711.7,
      "debt": 7.306,
      "gold": 17516.94386,
      "pb": 3.53,
      "pe": 20.45,
      "yield": 1.06,
      "date": "2009-11-02"
  },
  "2009-11-03": {
      "equity": 4563.9,
      "debt": 7.242,
      "gold": 17695.22214,
      "pb": 3.42,
      "pe": 19.81,
      "yield": 1.1,
      "date": "2009-11-03"
  },
  "2009-11-04": {
      "equity": 4710.8,
      "debt": 7.277,
      "gold": 18124.90873,
      "pb": 3.53,
      "pe": 20.45,
      "yield": 1.06,
      "date": "2009-11-04"
  },
  "2009-11-05": {
      "equity": 4765.5,
      "debt": 7.284,
      "gold": 18054.97804,
      "pb": 3.57,
      "pe": 20.68,
      "yield": 1.05,
      "date": "2009-11-05"
  },
  "2009-11-06": {
      "equity": 4796.1,
      "debt": 7.321,
      "gold": 18082.3048,
      "pb": 3.59,
      "pe": 21.05,
      "yield": 1.04,
      "date": "2009-11-06"
  },
  "2009-11-07": {
      "equity": 4796.1,
      "debt": 7.321,
      "gold": 18082.3048,
      "pb": 3.59,
      "pe": 21.05,
      "yield": 1.04,
      "date": "2009-11-07"
  },
  "2009-11-08": {
      "equity": 4796.1,
      "debt": 7.321,
      "gold": 18082.3048,
      "pb": 3.59,
      "pe": 21.05,
      "yield": 1.04,
      "date": "2009-11-08"
  },
  "2009-11-09": {
      "equity": 4898.4,
      "debt": 7.307,
      "gold": 18168.38039,
      "pb": 3.67,
      "pe": 21.5,
      "yield": 1.02,
      "date": "2009-11-09"
  },
  "2009-11-10": {
      "equity": 4881.7,
      "debt": 7.307,
      "gold": 18040.48396,
      "pb": 3.65,
      "pe": 21.43,
      "yield": 1.02,
      "date": "2009-11-10"
  },
  "2009-11-11": {
      "equity": 5003.9,
      "debt": 7.337,
      "gold": 18211.83795,
      "pb": 3.78,
      "pe": 22.24,
      "yield": 0.98,
      "date": "2009-11-11"
  },
  "2009-11-12": {
      "equity": 4952.6,
      "debt": 7.359,
      "gold": 18362.16512,
      "pb": 3.5,
      "pe": 22.02,
      "yield": 1,
      "date": "2009-11-12"
  },
  "2009-11-13": {
      "equity": 4998.9,
      "debt": 7.331,
      "gold": 18105.11296,
      "pb": 3.54,
      "pe": 22.22,
      "yield": 0.99,
      "date": "2009-11-13"
  },
  "2009-11-14": {
      "equity": 4998.9,
      "debt": 7.331,
      "gold": 18105.11296,
      "pb": 3.54,
      "pe": 22.22,
      "yield": 0.99,
      "date": "2009-11-14"
  },
  "2009-11-15": {
      "equity": 4998.9,
      "debt": 7.331,
      "gold": 18105.11296,
      "pb": 3.54,
      "pe": 22.22,
      "yield": 0.99,
      "date": "2009-11-15"
  },
  "2009-11-16": {
      "equity": 5058,
      "debt": 7.315,
      "gold": 18405.24172,
      "pb": 3.58,
      "pe": 22.48,
      "yield": 0.98,
      "date": "2009-11-16"
  },
  "2009-11-17": {
      "equity": 5062.2,
      "debt": 7.278,
      "gold": 18491.56423,
      "pb": 3.58,
      "pe": 22.5,
      "yield": 0.98,
      "date": "2009-11-17"
  },
  "2009-11-18": {
      "equity": 5054.7,
      "debt": 7.267,
      "gold": 18683.96973,
      "pb": 3.58,
      "pe": 22.47,
      "yield": 0.98,
      "date": "2009-11-18"
  },
  "2009-11-19": {
      "equity": 4989,
      "debt": 7.263,
      "gold": 18711.2718,
      "pb": 3.53,
      "pe": 22.18,
      "yield": 0.99,
      "date": "2009-11-19"
  },
  "2009-11-20": {
      "equity": 5052.4,
      "debt": 7.188,
      "gold": 18798.18339,
      "pb": 3.58,
      "pe": 22.46,
      "yield": 0.98,
      "date": "2009-11-20"
  },
  "2009-11-21": {
      "equity": 5052.4,
      "debt": 7.188,
      "gold": 18798.18339,
      "pb": 3.58,
      "pe": 22.46,
      "yield": 0.98,
      "date": "2009-11-21"
  },
  "2009-11-22": {
      "equity": 5052.4,
      "debt": 7.188,
      "gold": 18798.18339,
      "pb": 3.58,
      "pe": 22.46,
      "yield": 0.98,
      "date": "2009-11-22"
  },
  "2009-11-23": {
      "equity": 5103.5,
      "debt": 7.226,
      "gold": 19118.00914,
      "pb": 3.61,
      "pe": 22.69,
      "yield": 0.97,
      "date": "2009-11-23"
  },
  "2009-11-24": {
      "equity": 5090.5,
      "debt": 7.201,
      "gold": 19155.6994,
      "pb": 3.6,
      "pe": 22.63,
      "yield": 0.97,
      "date": "2009-11-24"
  },
  "2009-11-25": {
      "equity": 5108.1,
      "debt": 7.19,
      "gold": 19183.30482,
      "pb": 3.61,
      "pe": 22.71,
      "yield": 0.97,
      "date": "2009-11-25"
  },
  "2009-11-26": {
      "equity": 5005.5,
      "debt": 7.168,
      "gold": 19381.09667,
      "pb": 3.54,
      "pe": 22.25,
      "yield": 0.99,
      "date": "2009-11-26"
  },
  "2009-11-27": {
      "equity": 4941.7,
      "debt": 7.197,
      "gold": 19172.48629,
      "pb": 3.5,
      "pe": 21.97,
      "yield": 1,
      "date": "2009-11-27"
  },
  "2009-11-28": {
      "equity": 4941.7,
      "debt": 7.197,
      "gold": 19172.48629,
      "pb": 3.5,
      "pe": 21.97,
      "yield": 1,
      "date": "2009-11-28"
  },
  "2009-11-29": {
      "equity": 4941.7,
      "debt": 7.197,
      "gold": 19172.48629,
      "pb": 3.5,
      "pe": 21.97,
      "yield": 1,
      "date": "2009-11-29"
  },
  "2009-11-30": {
      "equity": 5032.7,
      "debt": 7.257,
      "gold": 19242.22297,
      "pb": 3.56,
      "pe": 22.37,
      "yield": 0.98,
      "date": "2009-11-30"
  },
  "2009-12-01": {
      "equity": 5122,
      "debt": 7.324,
      "gold": 19506.80612,
      "pb": 3.62,
      "pe": 22.77,
      "yield": 0.97,
      "date": "2009-12-01"
  },
  "2009-12-02": {
      "equity": 5123.2,
      "debt": 7.333,
      "gold": 19802.07058,
      "pb": 3.63,
      "pe": 22.77,
      "yield": 0.97,
      "date": "2009-12-02"
  },
  "2009-12-03": {
      "equity": 5131.7,
      "debt": 7.399,
      "gold": 19808.19062,
      "pb": 3.63,
      "pe": 22.81,
      "yield": 0.96,
      "date": "2009-12-03"
  },
  "2009-12-04": {
      "equity": 5108.9,
      "debt": 7.475,
      "gold": 19649.18605,
      "pb": 3.62,
      "pe": 22.71,
      "yield": 0.97,
      "date": "2009-12-04"
  },
  "2009-12-05": {
      "equity": 5108.9,
      "debt": 7.475,
      "gold": 19649.18605,
      "pb": 3.62,
      "pe": 22.71,
      "yield": 0.97,
      "date": "2009-12-05"
  },
  "2009-12-06": {
      "equity": 5108.9,
      "debt": 7.475,
      "gold": 19649.18605,
      "pb": 3.62,
      "pe": 22.71,
      "yield": 0.97,
      "date": "2009-12-06"
  },
  "2009-12-07": {
      "equity": 5066.7,
      "debt": 7.483,
      "gold": 18852.11732,
      "pb": 3.59,
      "pe": 22.52,
      "yield": 0.98,
      "date": "2009-12-07"
  },
  "2009-12-08": {
      "equity": 5147.9,
      "debt": 7.453,
      "gold": 19176.5816,
      "pb": 3.65,
      "pe": 22.92,
      "yield": 0.96,
      "date": "2009-12-08"
  },
  "2009-12-09": {
      "equity": 5112,
      "debt": 7.466,
      "gold": 18757.80878,
      "pb": 3.62,
      "pe": 22.76,
      "yield": 0.97,
      "date": "2009-12-09"
  },
  "2009-12-10": {
      "equity": 5134.6,
      "debt": 7.532,
      "gold": 18534.49973,
      "pb": 3.64,
      "pe": 22.86,
      "yield": 0.96,
      "date": "2009-12-10"
  },
  "2009-12-11": {
      "equity": 5117.3,
      "debt": 7.575,
      "gold": 18724.88051,
      "pb": 3.63,
      "pe": 22.79,
      "yield": 0.96,
      "date": "2009-12-11"
  },
  "2009-12-12": {
      "equity": 5117.3,
      "debt": 7.575,
      "gold": 18724.88051,
      "pb": 3.63,
      "pe": 22.79,
      "yield": 0.96,
      "date": "2009-12-12"
  },
  "2009-12-13": {
      "equity": 5117.3,
      "debt": 7.575,
      "gold": 18724.88051,
      "pb": 3.63,
      "pe": 22.79,
      "yield": 0.96,
      "date": "2009-12-13"
  },
  "2009-12-14": {
      "equity": 5105.7,
      "debt": 7.568,
      "gold": 18459.58483,
      "pb": 3.62,
      "pe": 22.74,
      "yield": 0.97,
      "date": "2009-12-14"
  },
  "2009-12-15": {
      "equity": 5033,
      "debt": 7.605,
      "gold": 18372.26053,
      "pb": 3.57,
      "pe": 22.41,
      "yield": 0.98,
      "date": "2009-12-15"
  },
  "2009-12-16": {
      "equity": 5042,
      "debt": 7.691,
      "gold": 18678.32942,
      "pb": 3.57,
      "pe": 22.45,
      "yield": 0.98,
      "date": "2009-12-16"
  },
  "2009-12-17": {
      "equity": 5041.7,
      "debt": 7.647,
      "gold": 18551.55823,
      "pb": 3.57,
      "pe": 22.45,
      "yield": 0.98,
      "date": "2009-12-17"
  },
  "2009-12-18": {
      "equity": 4987.7,
      "debt": 7.731,
      "gold": 18240.98485,
      "pb": 3.5,
      "pe": 22.21,
      "yield": 0.99,
      "date": "2009-12-18"
  },
  "2009-12-19": {
      "equity": 4987.7,
      "debt": 7.731,
      "gold": 18240.98485,
      "pb": 3.5,
      "pe": 22.21,
      "yield": 0.99,
      "date": "2009-12-19"
  },
  "2009-12-20": {
      "equity": 4987.7,
      "debt": 7.731,
      "gold": 18240.98485,
      "pb": 3.5,
      "pe": 22.21,
      "yield": 0.99,
      "date": "2009-12-20"
  },
  "2009-12-21": {
      "equity": 4952.6,
      "debt": 7.739,
      "gold": 18403.3087,
      "pb": 3.48,
      "pe": 22.05,
      "yield": 0.99,
      "date": "2009-12-21"
  },
  "2009-12-22": {
      "equity": 4985.8,
      "debt": 7.711,
      "gold": 18069.53209,
      "pb": 3.5,
      "pe": 22.2,
      "yield": 0.99,
      "date": "2009-12-22"
  },
  "2009-12-23": {
      "equity": 5144.6,
      "debt": 7.708,
      "gold": 17869.53562,
      "pb": 3.61,
      "pe": 22.91,
      "yield": 0.96,
      "date": "2009-12-23"
  },
  "2009-12-24": {
      "equity": 5178.4,
      "debt": 7.686,
      "gold": 18176.84615,
      "pb": 3.64,
      "pe": 23.07,
      "yield": 0.95,
      "date": "2009-12-24"
  },
  "2009-12-25": {
      "equity": 5178.4,
      "debt": 7.686,
      "gold": 18176.84615,
      "pb": 3.64,
      "pe": 23.07,
      "yield": 0.95,
      "date": "2009-12-25"
  },
  "2009-12-26": {
      "equity": 5178.4,
      "debt": 7.686,
      "gold": 18176.84615,
      "pb": 3.64,
      "pe": 23.07,
      "yield": 0.95,
      "date": "2009-12-26"
  },
  "2009-12-27": {
      "equity": 5178.4,
      "debt": 7.686,
      "gold": 18176.84615,
      "pb": 3.64,
      "pe": 23.07,
      "yield": 0.95,
      "date": "2009-12-27"
  },
  "2009-12-28": {
      "equity": 5178.4,
      "debt": 7.686,
      "gold": 18176.84615,
      "pb": 3.64,
      "pe": 23.07,
      "yield": 0.95,
      "date": "2009-12-28"
  },
  "2009-12-29": {
      "equity": 5187.9,
      "debt": 7.717,
      "gold": 18157.99573,
      "pb": 3.64,
      "pe": 23.11,
      "yield": 0.95,
      "date": "2009-12-29"
  },
  "2009-12-30": {
      "equity": 5169.4,
      "debt": 7.686,
      "gold": 18017.89802,
      "pb": 3.63,
      "pe": 23.03,
      "yield": 0.95,
      "date": "2009-12-30"
  },
  "2009-12-31": {
      "equity": 5201,
      "debt": 7.679,
      "gold": 18118.96506,
      "pb": 3.65,
      "pe": 23.17,
      "yield": 0.95,
      "date": "2009-12-31"
  },
  "2010-01-01": {
      "equity": 5201,
      "debt": 7.695,
      "gold": 18118.96506,
      "pb": 3.65,
      "pe": 23.17,
      "yield": 0.95,
      "date": "2010-01-01"
  },
  "2010-01-02": {
      "equity": 5201,
      "debt": 7.695,
      "gold": 18118.96506,
      "pb": 3.65,
      "pe": 23.17,
      "yield": 0.95,
      "date": "2010-01-02"
  },
  "2010-01-03": {
      "equity": 5201,
      "debt": 7.695,
      "gold": 18118.96506,
      "pb": 3.65,
      "pe": 23.17,
      "yield": 0.95,
      "date": "2010-01-03"
  },
  "2010-01-04": {
      "equity": 5232.2,
      "debt": 7.676,
      "gold": 18183.24485,
      "pb": 3.67,
      "pe": 23.31,
      "yield": 0.94,
      "date": "2010-01-04"
  },
  "2010-01-05": {
      "equity": 5277.9,
      "debt": 7.701,
      "gold": 18355.59357,
      "pb": 3.71,
      "pe": 23.57,
      "yield": 0.93,
      "date": "2010-01-05"
  },
  "2010-01-06": {
      "equity": 5281.8,
      "debt": 7.734,
      "gold": 18197.34387,
      "pb": 3.72,
      "pe": 23.59,
      "yield": 0.93,
      "date": "2010-01-06"
  },
  "2010-01-07": {
      "equity": 5263.1,
      "debt": 7.778,
      "gold": 18225.93697,
      "pb": 3.7,
      "pe": 23.51,
      "yield": 0.93,
      "date": "2010-01-07"
  },
  "2010-01-08": {
      "equity": 5244.7,
      "debt": 7.773,
      "gold": 18108.64742,
      "pb": 3.69,
      "pe": 23.43,
      "yield": 0.94,
      "date": "2010-01-08"
  },
  "2010-01-09": {
      "equity": 5244.7,
      "debt": 7.773,
      "gold": 18108.64742,
      "pb": 3.69,
      "pe": 23.43,
      "yield": 0.94,
      "date": "2010-01-09"
  },
  "2010-01-10": {
      "equity": 5244.7,
      "debt": 7.773,
      "gold": 18108.64742,
      "pb": 3.69,
      "pe": 23.43,
      "yield": 0.94,
      "date": "2010-01-10"
  },
  "2010-01-11": {
      "equity": 5249.4,
      "debt": 7.751,
      "gold": 18522.19969,
      "pb": 3.7,
      "pe": 23.45,
      "yield": 0.94,
      "date": "2010-01-11"
  },
  "2010-01-12": {
      "equity": 5210.4,
      "debt": 7.798,
      "gold": 18564.27803,
      "pb": 3.67,
      "pe": 23.28,
      "yield": 0.94,
      "date": "2010-01-12"
  },
  "2010-01-13": {
      "equity": 5233.9,
      "debt": 7.767,
      "gold": 18238.19468,
      "pb": 3.68,
      "pe": 23.38,
      "yield": 0.94,
      "date": "2010-01-13"
  },
  "2010-01-14": {
      "equity": 5259.9,
      "debt": 7.7,
      "gold": 18332.7325,
      "pb": 3.7,
      "pe": 23.5,
      "yield": 0.93,
      "date": "2010-01-14"
  },
  "2010-01-15": {
      "equity": 5252.2,
      "debt": 7.736,
      "gold": 18196.17277,
      "pb": 3.69,
      "pe": 23.46,
      "yield": 0.94,
      "date": "2010-01-15"
  },
  "2010-01-16": {
      "equity": 5252.2,
      "debt": 7.736,
      "gold": 18196.17277,
      "pb": 3.69,
      "pe": 23.46,
      "yield": 0.94,
      "date": "2010-01-16"
  },
  "2010-01-17": {
      "equity": 5252.2,
      "debt": 7.736,
      "gold": 18196.17277,
      "pb": 3.69,
      "pe": 23.46,
      "yield": 0.94,
      "date": "2010-01-17"
  },
  "2010-01-18": {
      "equity": 5274.8,
      "debt": 7.613,
      "gold": 18234.41683,
      "pb": 3.71,
      "pe": 23.57,
      "yield": 0.93,
      "date": "2010-01-18"
  },
  "2010-01-19": {
      "equity": 5225.6,
      "debt": 7.635,
      "gold": 18336.32339,
      "pb": 3.68,
      "pe": 23.35,
      "yield": 0.94,
      "date": "2010-01-19"
  },
  "2010-01-20": {
      "equity": 5221.7,
      "debt": 7.628,
      "gold": 18323.17678,
      "pb": 3.67,
      "pe": 23.06,
      "yield": 0.94,
      "date": "2010-01-20"
  },
  "2010-01-21": {
      "equity": 5094.1,
      "debt": 7.566,
      "gold": 17909.6492,
      "pb": 3.58,
      "pe": 22.5,
      "yield": 0.97,
      "date": "2010-01-21"
  },
  "2010-01-22": {
      "equity": 5036,
      "debt": 7.567,
      "gold": 17859.53544,
      "pb": 3.54,
      "pe": 22.24,
      "yield": 0.98,
      "date": "2010-01-22"
  },
  "2010-01-23": {
      "equity": 5036,
      "debt": 7.567,
      "gold": 17859.53544,
      "pb": 3.54,
      "pe": 22.24,
      "yield": 0.98,
      "date": "2010-01-23"
  },
  "2010-01-24": {
      "equity": 5036,
      "debt": 7.567,
      "gold": 17859.53544,
      "pb": 3.54,
      "pe": 22.24,
      "yield": 0.98,
      "date": "2010-01-24"
  },
  "2010-01-25": {
      "equity": 5007.9,
      "debt": 7.583,
      "gold": 17942.40816,
      "pb": 3.52,
      "pe": 21.7,
      "yield": 0.98,
      "date": "2010-01-25"
  },
  "2010-01-26": {
      "equity": 5007.9,
      "debt": 7.583,
      "gold": 17735.09938,
      "pb": 3.52,
      "pe": 21.7,
      "yield": 0.98,
      "date": "2010-01-26"
  },
  "2010-01-27": {
      "equity": 4853.1,
      "debt": 7.544,
      "gold": 17908.26293,
      "pb": 3.41,
      "pe": 21.03,
      "yield": 1.01,
      "date": "2010-01-27"
  },
  "2010-01-28": {
      "equity": 4867.2,
      "debt": 7.549,
      "gold": 17853.41188,
      "pb": 3.42,
      "pe": 21.09,
      "yield": 1.01,
      "date": "2010-01-28"
  },
  "2010-01-29": {
      "equity": 4882,
      "debt": 7.591,
      "gold": 17636.53327,
      "pb": 3.43,
      "pe": 21,
      "yield": 1.01,
      "date": "2010-01-29"
  },
  "2010-01-30": {
      "equity": 4882,
      "debt": 7.591,
      "gold": 17636.53327,
      "pb": 3.43,
      "pe": 21,
      "yield": 1.01,
      "date": "2010-01-30"
  },
  "2010-01-31": {
      "equity": 4882,
      "debt": 7.591,
      "gold": 17636.53327,
      "pb": 3.43,
      "pe": 21,
      "yield": 1.01,
      "date": "2010-01-31"
  },
  "2010-02-01": {
      "equity": 4899.7,
      "debt": 7.638,
      "gold": 17703.51153,
      "pb": 3.45,
      "pe": 21.07,
      "yield": 1,
      "date": "2010-02-01"
  },
  "2010-02-02": {
      "equity": 4830.1,
      "debt": 7.676,
      "gold": 18138.67617,
      "pb": 3.4,
      "pe": 20.77,
      "yield": 1.02,
      "date": "2010-02-02"
  },
  "2010-02-03": {
      "equity": 4931.8,
      "debt": 7.669,
      "gold": 18135.0112,
      "pb": 3.47,
      "pe": 21.21,
      "yield": 1,
      "date": "2010-02-03"
  },
  "2010-02-04": {
      "equity": 4845.3,
      "debt": 7.693,
      "gold": 17978.65218,
      "pb": 3.41,
      "pe": 20.84,
      "yield": 1.02,
      "date": "2010-02-04"
  },
  "2010-02-05": {
      "equity": 4718.6,
      "debt": 7.678,
      "gold": 17342.94432,
      "pb": 3.32,
      "pe": 20.18,
      "yield": 1.05,
      "date": "2010-02-05"
  },
  "2010-02-06": {
      "equity": 4757.2,
      "debt": 7.678,
      "gold": 17342.94432,
      "pb": 3.35,
      "pe": 20.35,
      "yield": 1.04,
      "date": "2010-02-06"
  },
  "2010-02-07": {
      "equity": 4757.2,
      "debt": 7.678,
      "gold": 17342.94432,
      "pb": 3.35,
      "pe": 20.35,
      "yield": 1.04,
      "date": "2010-02-07"
  },
  "2010-02-08": {
      "equity": 4760.4,
      "debt": 7.678,
      "gold": 17665.69075,
      "pb": 3.35,
      "pe": 20.36,
      "yield": 1.04,
      "date": "2010-02-08"
  },
  "2010-02-09": {
      "equity": 4792.6,
      "debt": 7.701,
      "gold": 17576.16184,
      "pb": 3.37,
      "pe": 20.5,
      "yield": 1.03,
      "date": "2010-02-09"
  },
  "2010-02-10": {
      "equity": 4757.2,
      "debt": 7.788,
      "gold": 17636.99536,
      "pb": 3.35,
      "pe": 20.35,
      "yield": 1.04,
      "date": "2010-02-10"
  },
  "2010-02-11": {
      "equity": 4826.8,
      "debt": 7.859,
      "gold": 17675.93432,
      "pb": 3.4,
      "pe": 20.65,
      "yield": 1.02,
      "date": "2010-02-11"
  },
  "2010-02-12": {
      "equity": 4826.8,
      "debt": 7.859,
      "gold": 17655.46835,
      "pb": 3.4,
      "pe": 20.65,
      "yield": 1.02,
      "date": "2010-02-12"
  },
  "2010-02-13": {
      "equity": 4826.8,
      "debt": 7.859,
      "gold": 17655.46835,
      "pb": 3.4,
      "pe": 20.65,
      "yield": 1.02,
      "date": "2010-02-13"
  },
  "2010-02-14": {
      "equity": 4826.8,
      "debt": 7.859,
      "gold": 17655.46835,
      "pb": 3.4,
      "pe": 20.65,
      "yield": 1.02,
      "date": "2010-02-14"
  },
  "2010-02-15": {
      "equity": 4801.9,
      "debt": 7.891,
      "gold": 17966.57437,
      "pb": 3.38,
      "pe": 20.54,
      "yield": 1.03,
      "date": "2010-02-15"
  },
  "2010-02-16": {
      "equity": 4855.7,
      "debt": 7.868,
      "gold": 18178.69804,
      "pb": 3.42,
      "pe": 20.77,
      "yield": 1.02,
      "date": "2010-02-16"
  },
  "2010-02-17": {
      "equity": 4914,
      "debt": 7.892,
      "gold": 18186.18318,
      "pb": 3.46,
      "pe": 21.02,
      "yield": 1,
      "date": "2010-02-17"
  },
  "2010-02-18": {
      "equity": 4887.7,
      "debt": 7.869,
      "gold": 18041.22119,
      "pb": 3.44,
      "pe": 20.91,
      "yield": 1.01,
      "date": "2010-02-18"
  },
  "2010-02-19": {
      "equity": 4844.9,
      "debt": 7.885,
      "gold": 18081.32066,
      "pb": 3.41,
      "pe": 20.72,
      "yield": 1.02,
      "date": "2010-02-19"
  },
  "2010-02-20": {
      "equity": 4844.9,
      "debt": 7.885,
      "gold": 18081.32066,
      "pb": 3.41,
      "pe": 20.72,
      "yield": 1.02,
      "date": "2010-02-20"
  },
  "2010-02-21": {
      "equity": 4844.9,
      "debt": 7.885,
      "gold": 18081.32066,
      "pb": 3.41,
      "pe": 20.72,
      "yield": 1.02,
      "date": "2010-02-21"
  },
  "2010-02-22": {
      "equity": 4856.4,
      "debt": 7.846,
      "gold": 18220.45186,
      "pb": 3.42,
      "pe": 20.77,
      "yield": 1.02,
      "date": "2010-02-22"
  },
  "2010-02-23": {
      "equity": 4870,
      "debt": 7.799,
      "gold": 18135.52973,
      "pb": 3.43,
      "pe": 20.83,
      "yield": 1.01,
      "date": "2010-02-23"
  },
  "2010-02-24": {
      "equity": 4858.6,
      "debt": 7.799,
      "gold": 17864.2163,
      "pb": 3.42,
      "pe": 20.78,
      "yield": 1.02,
      "date": "2010-02-24"
  },
  "2010-02-25": {
      "equity": 4859.7,
      "debt": 7.823,
      "gold": 17912.16424,
      "pb": 3.42,
      "pe": 20.79,
      "yield": 1.02,
      "date": "2010-02-25"
  },
  "2010-02-26": {
      "equity": 4922.3,
      "debt": 7.864,
      "gold": 18092.6683,
      "pb": 3.46,
      "pe": 20.92,
      "yield": 1,
      "date": "2010-02-26"
  },
  "2010-02-27": {
      "equity": 4922.3,
      "debt": 7.864,
      "gold": 18092.6683,
      "pb": 3.46,
      "pe": 20.92,
      "yield": 1,
      "date": "2010-02-27"
  },
  "2010-02-28": {
      "equity": 4922.3,
      "debt": 7.864,
      "gold": 18092.6683,
      "pb": 3.46,
      "pe": 20.92,
      "yield": 1,
      "date": "2010-02-28"
  },
  "2010-03-01": {
      "equity": 4922.3,
      "debt": 7.864,
      "gold": 18169.91834,
      "pb": 3.46,
      "pe": 20.92,
      "yield": 1,
      "date": "2010-03-01"
  },
  "2010-03-02": {
      "equity": 5017,
      "debt": 7.944,
      "gold": 18070.85839,
      "pb": 3.53,
      "pe": 21.33,
      "yield": 0.98,
      "date": "2010-03-02"
  },
  "2010-03-03": {
      "equity": 5088.1,
      "debt": 7.952,
      "gold": 18314.58756,
      "pb": 3.58,
      "pe": 21.63,
      "yield": 0.97,
      "date": "2010-03-03"
  },
  "2010-03-04": {
      "equity": 5080.2,
      "debt": 7.945,
      "gold": 18382.72633,
      "pb": 3.57,
      "pe": 21.59,
      "yield": 0.97,
      "date": "2010-03-04"
  },
  "2010-03-05": {
      "equity": 5088.7,
      "debt": 7.973,
      "gold": 18258.4102,
      "pb": 3.58,
      "pe": 21.63,
      "yield": 0.97,
      "date": "2010-03-05"
  },
  "2010-03-06": {
      "equity": 5088.7,
      "debt": 7.973,
      "gold": 18258.4102,
      "pb": 3.58,
      "pe": 21.63,
      "yield": 0.97,
      "date": "2010-03-06"
  },
  "2010-03-07": {
      "equity": 5088.7,
      "debt": 7.973,
      "gold": 18258.4102,
      "pb": 3.58,
      "pe": 21.63,
      "yield": 0.97,
      "date": "2010-03-07"
  },
  "2010-03-08": {
      "equity": 5124,
      "debt": 8.01,
      "gold": 18174.32053,
      "pb": 3.61,
      "pe": 21.8,
      "yield": 0.96,
      "date": "2010-03-08"
  },
  "2010-03-09": {
      "equity": 5101.5,
      "debt": 7.97,
      "gold": 18011.18186,
      "pb": 3.59,
      "pe": 21.7,
      "yield": 0.97,
      "date": "2010-03-09"
  },
  "2010-03-10": {
      "equity": 5116.2,
      "debt": 7.978,
      "gold": 18000.25045,
      "pb": 3.6,
      "pe": 21.77,
      "yield": 0.96,
      "date": "2010-03-10"
  },
  "2010-03-11": {
      "equity": 5133.4,
      "debt": 8,
      "gold": 17795.79534,
      "pb": 3.61,
      "pe": 21.84,
      "yield": 0.96,
      "date": "2010-03-11"
  },
  "2010-03-12": {
      "equity": 5137,
      "debt": 8.015,
      "gold": 17929.91411,
      "pb": 3.62,
      "pe": 21.86,
      "yield": 0.96,
      "date": "2010-03-12"
  },
  "2010-03-13": {
      "equity": 5137,
      "debt": 8.015,
      "gold": 17929.91411,
      "pb": 3.62,
      "pe": 21.86,
      "yield": 0.96,
      "date": "2010-03-13"
  },
  "2010-03-14": {
      "equity": 5137,
      "debt": 8.015,
      "gold": 17929.91411,
      "pb": 3.62,
      "pe": 21.86,
      "yield": 0.96,
      "date": "2010-03-14"
  },
  "2010-03-15": {
      "equity": 5128.9,
      "debt": 8.005,
      "gold": 17751.93213,
      "pb": 3.61,
      "pe": 21.82,
      "yield": 0.96,
      "date": "2010-03-15"
  },
  "2010-03-16": {
      "equity": 5198.1,
      "debt": 8.005,
      "gold": 17900.66844,
      "pb": 3.66,
      "pe": 22.12,
      "yield": 0.95,
      "date": "2010-03-16"
  },
  "2010-03-17": {
      "equity": 5231.9,
      "debt": 7.965,
      "gold": 18106.30523,
      "pb": 3.68,
      "pe": 22.26,
      "yield": 0.94,
      "date": "2010-03-17"
  },
  "2010-03-18": {
      "equity": 5245.9,
      "debt": 7.912,
      "gold": 18017.78162,
      "pb": 3.69,
      "pe": 22.32,
      "yield": 0.94,
      "date": "2010-03-18"
  },
  "2010-03-19": {
      "equity": 5262.8,
      "debt": 7.831,
      "gold": 18001.67904,
      "pb": 3.71,
      "pe": 22.39,
      "yield": 0.94,
      "date": "2010-03-19"
  },
  "2010-03-20": {
      "equity": 5262.8,
      "debt": 7.831,
      "gold": 18001.67904,
      "pb": 3.71,
      "pe": 22.39,
      "yield": 0.94,
      "date": "2010-03-20"
  },
  "2010-03-21": {
      "equity": 5262.8,
      "debt": 7.831,
      "gold": 18001.67904,
      "pb": 3.71,
      "pe": 22.39,
      "yield": 0.94,
      "date": "2010-03-21"
  },
  "2010-03-22": {
      "equity": 5205.2,
      "debt": 7.855,
      "gold": 17757.90049,
      "pb": 3.66,
      "pe": 22.15,
      "yield": 0.95,
      "date": "2010-03-22"
  },
  "2010-03-23": {
      "equity": 5225.3,
      "debt": 7.863,
      "gold": 17641.43283,
      "pb": 3.68,
      "pe": 22.23,
      "yield": 0.94,
      "date": "2010-03-23"
  },
  "2010-03-24": {
      "equity": 5225.3,
      "debt": 7.863,
      "gold": 17533.25103,
      "pb": 3.68,
      "pe": 22.23,
      "yield": 0.94,
      "date": "2010-03-24"
  },
  "2010-03-25": {
      "equity": 5260.4,
      "debt": 7.875,
      "gold": 17532.9512,
      "pb": 3.7,
      "pe": 22.38,
      "yield": 0.94,
      "date": "2010-03-25"
  },
  "2010-03-26": {
      "equity": 5282,
      "debt": 7.856,
      "gold": 17519.89629,
      "pb": 3.72,
      "pe": 22.47,
      "yield": 0.93,
      "date": "2010-03-26"
  },
  "2010-03-27": {
      "equity": 5282,
      "debt": 7.856,
      "gold": 17519.89629,
      "pb": 3.72,
      "pe": 22.47,
      "yield": 0.93,
      "date": "2010-03-27"
  },
  "2010-03-28": {
      "equity": 5282,
      "debt": 7.856,
      "gold": 17519.89629,
      "pb": 3.72,
      "pe": 22.47,
      "yield": 0.93,
      "date": "2010-03-28"
  },
  "2010-03-29": {
      "equity": 5302.8,
      "debt": 7.762,
      "gold": 17598.04229,
      "pb": 3.73,
      "pe": 22.56,
      "yield": 0.93,
      "date": "2010-03-29"
  },
  "2010-03-30": {
      "equity": 5262.4,
      "debt": 7.769,
      "gold": 17650.6217,
      "pb": 3.71,
      "pe": 22.39,
      "yield": 0.94,
      "date": "2010-03-30"
  },
  "2010-03-31": {
      "equity": 5249.1,
      "debt": 7.85,
      "gold": 17591.85524,
      "pb": 3.7,
      "pe": 22.33,
      "yield": 0.94,
      "date": "2010-03-31"
  },
  "2010-04-01": {
      "equity": 5290.5,
      "debt": 7.85,
      "gold": 17694.91525,
      "pb": 3.73,
      "pe": 22.52,
      "yield": 0.93,
      "date": "2010-04-01"
  },
  "2010-04-02": {
      "equity": 5290.5,
      "debt": 7.85,
      "gold": 17694.91525,
      "pb": 3.73,
      "pe": 22.52,
      "yield": 0.93,
      "date": "2010-04-02"
  },
  "2010-04-03": {
      "equity": 5290.5,
      "debt": 7.85,
      "gold": 17694.91525,
      "pb": 3.73,
      "pe": 22.52,
      "yield": 0.93,
      "date": "2010-04-03"
  },
  "2010-04-04": {
      "equity": 5290.5,
      "debt": 7.85,
      "gold": 17694.91525,
      "pb": 3.73,
      "pe": 22.52,
      "yield": 0.93,
      "date": "2010-04-04"
  },
  "2010-04-05": {
      "equity": 5368.4,
      "debt": 7.811,
      "gold": 17694.91525,
      "pb": 3.78,
      "pe": 22.85,
      "yield": 0.92,
      "date": "2010-04-05"
  },
  "2010-04-06": {
      "equity": 5366,
      "debt": 7.795,
      "gold": 17631.45029,
      "pb": 3.78,
      "pe": 22.84,
      "yield": 0.92,
      "date": "2010-04-06"
  },
  "2010-04-07": {
      "equity": 5374.6,
      "debt": 7.796,
      "gold": 17806.52922,
      "pb": 3.79,
      "pe": 22.88,
      "yield": 0.92,
      "date": "2010-04-07"
  },
  "2010-04-08": {
      "equity": 5304.4,
      "debt": 7.863,
      "gold": 17943.94963,
      "pb": 3.77,
      "pe": 22.96,
      "yield": 0.92,
      "date": "2010-04-08"
  },
  "2010-04-09": {
      "equity": 5361.7,
      "debt": 8.013,
      "gold": 18057.97633,
      "pb": 3.81,
      "pe": 23.21,
      "yield": 0.91,
      "date": "2010-04-09"
  },
  "2010-04-10": {
      "equity": 5361.7,
      "debt": 8.013,
      "gold": 18057.97633,
      "pb": 3.81,
      "pe": 23.21,
      "yield": 0.91,
      "date": "2010-04-10"
  },
  "2010-04-11": {
      "equity": 5361.7,
      "debt": 8.013,
      "gold": 18057.97633,
      "pb": 3.81,
      "pe": 23.21,
      "yield": 0.91,
      "date": "2010-04-11"
  },
  "2010-04-12": {
      "equity": 5339.7,
      "debt": 8.029,
      "gold": 18240.80143,
      "pb": 3.79,
      "pe": 23.11,
      "yield": 0.91,
      "date": "2010-04-12"
  },
  "2010-04-13": {
      "equity": 5322.9,
      "debt": 8.057,
      "gold": 18029.55608,
      "pb": 3.78,
      "pe": 23.04,
      "yield": 0.92,
      "date": "2010-04-13"
  },
  "2010-04-14": {
      "equity": 5322.9,
      "debt": 8.057,
      "gold": 18182.51821,
      "pb": 3.78,
      "pe": 23.04,
      "yield": 0.92,
      "date": "2010-04-14"
  },
  "2010-04-15": {
      "equity": 5273.6,
      "debt": 8.079,
      "gold": 18047.73629,
      "pb": 3.75,
      "pe": 22.83,
      "yield": 0.93,
      "date": "2010-04-15"
  },
  "2010-04-16": {
      "equity": 5262.6,
      "debt": 8.088,
      "gold": 18091.96282,
      "pb": 3.74,
      "pe": 22.78,
      "yield": 0.93,
      "date": "2010-04-16"
  },
  "2010-04-17": {
      "equity": 5262.6,
      "debt": 8.088,
      "gold": 18091.96282,
      "pb": 3.74,
      "pe": 22.78,
      "yield": 0.93,
      "date": "2010-04-17"
  },
  "2010-04-18": {
      "equity": 5262.6,
      "debt": 8.088,
      "gold": 18091.96282,
      "pb": 3.74,
      "pe": 22.78,
      "yield": 0.93,
      "date": "2010-04-18"
  },
  "2010-04-19": {
      "equity": 5203.6,
      "debt": 8.077,
      "gold": 17792.74061,
      "pb": 3.7,
      "pe": 22.58,
      "yield": 0.94,
      "date": "2010-04-19"
  },
  "2010-04-20": {
      "equity": 5230.1,
      "debt": 7.984,
      "gold": 17937.28284,
      "pb": 3.72,
      "pe": 22.71,
      "yield": 0.93,
      "date": "2010-04-20"
  },
  "2010-04-21": {
      "equity": 5244.9,
      "debt": 8.008,
      "gold": 17977.56574,
      "pb": 3.73,
      "pe": 22.78,
      "yield": 0.93,
      "date": "2010-04-21"
  },
  "2010-04-22": {
      "equity": 5269.3,
      "debt": 7.992,
      "gold": 17981.38944,
      "pb": 3.75,
      "pe": 22.88,
      "yield": 0.93,
      "date": "2010-04-22"
  },
  "2010-04-23": {
      "equity": 5304.1,
      "debt": 8.058,
      "gold": 17868.35747,
      "pb": 3.77,
      "pe": 23.03,
      "yield": 0.92,
      "date": "2010-04-23"
  },
  "2010-04-24": {
      "equity": 5304.1,
      "debt": 8.058,
      "gold": 17868.35747,
      "pb": 3.77,
      "pe": 23.03,
      "yield": 0.92,
      "date": "2010-04-24"
  },
  "2010-04-25": {
      "equity": 5304.1,
      "debt": 8.058,
      "gold": 17868.35747,
      "pb": 3.77,
      "pe": 23.03,
      "yield": 0.92,
      "date": "2010-04-25"
  },
  "2010-04-26": {
      "equity": 5322.4,
      "debt": 8.07,
      "gold": 18072.04007,
      "pb": 3.78,
      "pe": 23.11,
      "yield": 0.92,
      "date": "2010-04-26"
  },
  "2010-04-27": {
      "equity": 5308.3,
      "debt": 8.108,
      "gold": 18064.42794,
      "pb": 3.77,
      "pe": 22.45,
      "yield": 0.92,
      "date": "2010-04-27"
  },
  "2010-04-28": {
      "equity": 5215.4,
      "debt": 8.108,
      "gold": 18332.64079,
      "pb": 3.71,
      "pe": 22.05,
      "yield": 0.94,
      "date": "2010-04-28"
  },
  "2010-04-29": {
      "equity": 5254.1,
      "debt": 8.092,
      "gold": 18370.56385,
      "pb": 3.74,
      "pe": 22.22,
      "yield": 0.93,
      "date": "2010-04-29"
  },
  "2010-04-30": {
      "equity": 5278,
      "debt": 8.061,
      "gold": 18388.73701,
      "pb": 3.75,
      "pe": 22.29,
      "yield": 0.92,
      "date": "2010-04-30"
  },
  "2010-05-01": {
      "equity": 5278,
      "debt": 8.061,
      "gold": 18388.73701,
      "pb": 3.75,
      "pe": 22.29,
      "yield": 0.92,
      "date": "2010-05-01"
  },
  "2010-05-02": {
      "equity": 5278,
      "debt": 8.061,
      "gold": 18388.73701,
      "pb": 3.75,
      "pe": 22.29,
      "yield": 0.92,
      "date": "2010-05-02"
  },
  "2010-05-03": {
      "equity": 5222.7,
      "debt": 8.045,
      "gold": 18388.73701,
      "pb": 3.71,
      "pe": 22.06,
      "yield": 0.93,
      "date": "2010-05-03"
  },
  "2010-05-04": {
      "equity": 5148.5,
      "debt": 7.968,
      "gold": 18639.2141,
      "pb": 3.66,
      "pe": 21.74,
      "yield": 0.95,
      "date": "2010-05-04"
  },
  "2010-05-05": {
      "equity": 5124.9,
      "debt": 7.973,
      "gold": 18578.20067,
      "pb": 3.64,
      "pe": 21.64,
      "yield": 0.95,
      "date": "2010-05-05"
  },
  "2010-05-06": {
      "equity": 5090.8,
      "debt": 7.978,
      "gold": 18827.55604,
      "pb": 3.62,
      "pe": 21.5,
      "yield": 0.96,
      "date": "2010-05-06"
  },
  "2010-05-07": {
      "equity": 5018,
      "debt": 7.966,
      "gold": 19244.71684,
      "pb": 3.57,
      "pe": 21.19,
      "yield": 0.97,
      "date": "2010-05-07"
  },
  "2010-05-08": {
      "equity": 5018,
      "debt": 7.966,
      "gold": 19244.71684,
      "pb": 3.57,
      "pe": 21.19,
      "yield": 0.97,
      "date": "2010-05-08"
  },
  "2010-05-09": {
      "equity": 5018,
      "debt": 7.966,
      "gold": 19244.71684,
      "pb": 3.57,
      "pe": 21.19,
      "yield": 0.97,
      "date": "2010-05-09"
  },
  "2010-05-10": {
      "equity": 5193.6,
      "debt": 8.023,
      "gold": 18802.76548,
      "pb": 3.69,
      "pe": 21.92,
      "yield": 0.95,
      "date": "2010-05-10"
  },
  "2010-05-11": {
      "equity": 5136.1,
      "debt": 7.975,
      "gold": 19319.39188,
      "pb": 3.65,
      "pe": 21.68,
      "yield": 0.96,
      "date": "2010-05-11"
  },
  "2010-05-12": {
      "equity": 5156.6,
      "debt": 7.93,
      "gold": 19750.89155,
      "pb": 3.66,
      "pe": 21.77,
      "yield": 0.96,
      "date": "2010-05-12"
  },
  "2010-05-13": {
      "equity": 5178.9,
      "debt": 7.499,
      "gold": 19637.28108,
      "pb": 3.68,
      "pe": 21.86,
      "yield": 0.95,
      "date": "2010-05-13"
  },
  "2010-05-14": {
      "equity": 5093.5,
      "debt": 7.502,
      "gold": 19759.17741,
      "pb": 3.62,
      "pe": 21.26,
      "yield": 0.97,
      "date": "2010-05-14"
  },
  "2010-05-15": {
      "equity": 5093.5,
      "debt": 7.502,
      "gold": 19759.17741,
      "pb": 3.62,
      "pe": 21.26,
      "yield": 0.97,
      "date": "2010-05-15"
  },
  "2010-05-16": {
      "equity": 5093.5,
      "debt": 7.502,
      "gold": 19759.17741,
      "pb": 3.62,
      "pe": 21.26,
      "yield": 0.97,
      "date": "2010-05-16"
  },
  "2010-05-17": {
      "equity": 5059.9,
      "debt": 7.466,
      "gold": 19801.51678,
      "pb": 3.6,
      "pe": 21.12,
      "yield": 0.97,
      "date": "2010-05-17"
  },
  "2010-05-18": {
      "equity": 5066.2,
      "debt": 7.5,
      "gold": 19537.8437,
      "pb": 3.6,
      "pe": 21.14,
      "yield": 0.97,
      "date": "2010-05-18"
  },
  "2010-05-19": {
      "equity": 4919.6,
      "debt": 7.438,
      "gold": 19781.11431,
      "pb": 3.5,
      "pe": 20.53,
      "yield": 1,
      "date": "2010-05-19"
  },
  "2010-05-20": {
      "equity": 4947.6,
      "debt": 7.416,
      "gold": 19603.58031,
      "pb": 3.52,
      "pe": 20.65,
      "yield": 1,
      "date": "2010-05-20"
  },
  "2010-05-21": {
      "equity": 4931.1,
      "debt": 7.382,
      "gold": 19589.67178,
      "pb": 3.5,
      "pe": 20.58,
      "yield": 1,
      "date": "2010-05-21"
  },
  "2010-05-22": {
      "equity": 4931.1,
      "debt": 7.382,
      "gold": 19589.67178,
      "pb": 3.5,
      "pe": 20.58,
      "yield": 1,
      "date": "2010-05-22"
  },
  "2010-05-23": {
      "equity": 4931.1,
      "debt": 7.382,
      "gold": 19589.67178,
      "pb": 3.5,
      "pe": 20.58,
      "yield": 1,
      "date": "2010-05-23"
  },
  "2010-05-24": {
      "equity": 4943.9,
      "debt": 7.395,
      "gold": 19616.77631,
      "pb": 3.51,
      "pe": 20.63,
      "yield": 1,
      "date": "2010-05-24"
  },
  "2010-05-25": {
      "equity": 4806.7,
      "debt": 7.383,
      "gold": 20016.26131,
      "pb": 3.42,
      "pe": 20.06,
      "yield": 1.03,
      "date": "2010-05-25"
  },
  "2010-05-26": {
      "equity": 4917.4,
      "debt": 7.524,
      "gold": 20227.937,
      "pb": 3.49,
      "pe": 20.52,
      "yield": 1,
      "date": "2010-05-26"
  },
  "2010-05-27": {
      "equity": 5003.1,
      "debt": 7.524,
      "gold": 20198.74072,
      "pb": 3.56,
      "pe": 20.88,
      "yield": 0.98,
      "date": "2010-05-27"
  },
  "2010-05-28": {
      "equity": 5066.5,
      "debt": 7.539,
      "gold": 19848.28657,
      "pb": 3.6,
      "pe": 21.22,
      "yield": 0.97,
      "date": "2010-05-28"
  },
  "2010-05-29": {
      "equity": 5066.5,
      "debt": 7.539,
      "gold": 19848.28657,
      "pb": 3.6,
      "pe": 21.22,
      "yield": 0.97,
      "date": "2010-05-29"
  },
  "2010-05-30": {
      "equity": 5066.5,
      "debt": 7.539,
      "gold": 19848.28657,
      "pb": 3.6,
      "pe": 21.22,
      "yield": 0.97,
      "date": "2010-05-30"
  },
  "2010-05-31": {
      "equity": 5086.3,
      "debt": 7.564,
      "gold": 19848.28657,
      "pb": 3.61,
      "pe": 21.3,
      "yield": 0.97,
      "date": "2010-05-31"
  },
  "2010-06-01": {
      "equity": 4970.2,
      "debt": 7.564,
      "gold": 20288.65059,
      "pb": 3.53,
      "pe": 20.81,
      "yield": 0.99,
      "date": "2010-06-01"
  },
  "2010-06-02": {
      "equity": 5019.8,
      "debt": 7.503,
      "gold": 20231.91943,
      "pb": 3.57,
      "pe": 21.02,
      "yield": 0.98,
      "date": "2010-06-02"
  },
  "2010-06-03": {
      "equity": 5110.5,
      "debt": 7.56,
      "gold": 20089.65943,
      "pb": 3.63,
      "pe": 21.4,
      "yield": 0.96,
      "date": "2010-06-03"
  },
  "2010-06-04": {
      "equity": 5135.5,
      "debt": 7.537,
      "gold": 19884.63289,
      "pb": 3.65,
      "pe": 21.5,
      "yield": 0.96,
      "date": "2010-06-04"
  },
  "2010-06-05": {
      "equity": 5135.5,
      "debt": 7.537,
      "gold": 19884.63289,
      "pb": 3.65,
      "pe": 21.5,
      "yield": 0.96,
      "date": "2010-06-05"
  },
  "2010-06-06": {
      "equity": 5135.5,
      "debt": 7.537,
      "gold": 19884.63289,
      "pb": 3.65,
      "pe": 21.5,
      "yield": 0.96,
      "date": "2010-06-06"
  },
  "2010-06-07": {
      "equity": 5034,
      "debt": 7.49,
      "gold": 20139.66737,
      "pb": 3.58,
      "pe": 21.11,
      "yield": 0.98,
      "date": "2010-06-07"
  },
  "2010-06-08": {
      "equity": 4987.1,
      "debt": 7.463,
      "gold": 20666.09993,
      "pb": 3.55,
      "pe": 20.91,
      "yield": 0.99,
      "date": "2010-06-08"
  },
  "2010-06-09": {
      "equity": 5000.3,
      "debt": 7.485,
      "gold": 20486.24491,
      "pb": 3.56,
      "pe": 20.97,
      "yield": 0.98,
      "date": "2010-06-09"
  },
  "2010-06-10": {
      "equity": 5078.6,
      "debt": 7.53,
      "gold": 20211.04429,
      "pb": 3.61,
      "pe": 21.3,
      "yield": 0.97,
      "date": "2010-06-10"
  },
  "2010-06-11": {
      "equity": 5119.3,
      "debt": 7.638,
      "gold": 20166.58848,
      "pb": 3.64,
      "pe": 21.44,
      "yield": 0.96,
      "date": "2010-06-11"
  },
  "2010-06-12": {
      "equity": 5119.3,
      "debt": 7.638,
      "gold": 20166.58848,
      "pb": 3.64,
      "pe": 21.44,
      "yield": 0.96,
      "date": "2010-06-12"
  },
  "2010-06-13": {
      "equity": 5119.3,
      "debt": 7.638,
      "gold": 20166.58848,
      "pb": 3.64,
      "pe": 21.44,
      "yield": 0.96,
      "date": "2010-06-13"
  },
  "2010-06-14": {
      "equity": 5197.7,
      "debt": 7.686,
      "gold": 20163.50553,
      "pb": 3.7,
      "pe": 21.77,
      "yield": 0.95,
      "date": "2010-06-14"
  },
  "2010-06-15": {
      "equity": 5222.3,
      "debt": 7.659,
      "gold": 20088.18498,
      "pb": 3.72,
      "pe": 21.87,
      "yield": 0.94,
      "date": "2010-06-15"
  },
  "2010-06-16": {
      "equity": 5233.3,
      "debt": 7.593,
      "gold": 20289.1409,
      "pb": 3.72,
      "pe": 21.92,
      "yield": 0.94,
      "date": "2010-06-16"
  },
  "2010-06-17": {
      "equity": 5274.8,
      "debt": 7.62,
      "gold": 20166.03467,
      "pb": 3.75,
      "pe": 22.09,
      "yield": 0.93,
      "date": "2010-06-17"
  },
  "2010-06-18": {
      "equity": 5262.6,
      "debt": 7.572,
      "gold": 20263.64839,
      "pb": 3.75,
      "pe": 22.04,
      "yield": 0.94,
      "date": "2010-06-18"
  },
  "2010-06-19": {
      "equity": 5262.6,
      "debt": 7.572,
      "gold": 20263.64839,
      "pb": 3.75,
      "pe": 22.04,
      "yield": 0.94,
      "date": "2010-06-19"
  },
  "2010-06-20": {
      "equity": 5262.6,
      "debt": 7.572,
      "gold": 20263.64839,
      "pb": 3.75,
      "pe": 22.04,
      "yield": 0.94,
      "date": "2010-06-20"
  },
  "2010-06-21": {
      "equity": 5353.3,
      "debt": 7.568,
      "gold": 20326.73239,
      "pb": 3.81,
      "pe": 22.42,
      "yield": 0.92,
      "date": "2010-06-21"
  },
  "2010-06-22": {
      "equity": 5316.5,
      "debt": 7.572,
      "gold": 20147.78391,
      "pb": 3.78,
      "pe": 22.27,
      "yield": 0.93,
      "date": "2010-06-22"
  },
  "2010-06-23": {
      "equity": 5323.1,
      "debt": 7.585,
      "gold": 20252.269,
      "pb": 3.79,
      "pe": 22.29,
      "yield": 0.92,
      "date": "2010-06-23"
  },
  "2010-06-24": {
      "equity": 5320.6,
      "debt": 7.581,
      "gold": 20223.91577,
      "pb": 3.79,
      "pe": 22.28,
      "yield": 0.92,
      "date": "2010-06-24"
  },
  "2010-06-25": {
      "equity": 5269,
      "debt": 7.659,
      "gold": 20286.41775,
      "pb": 3.75,
      "pe": 22.07,
      "yield": 0.93,
      "date": "2010-06-25"
  },
  "2010-06-26": {
      "equity": 5269,
      "debt": 7.659,
      "gold": 20286.41775,
      "pb": 3.75,
      "pe": 22.07,
      "yield": 0.93,
      "date": "2010-06-26"
  },
  "2010-06-27": {
      "equity": 5269,
      "debt": 7.659,
      "gold": 20286.41775,
      "pb": 3.75,
      "pe": 22.07,
      "yield": 0.93,
      "date": "2010-06-27"
  },
  "2010-06-28": {
      "equity": 5333.5,
      "debt": 7.598,
      "gold": 20470.72435,
      "pb": 3.8,
      "pe": 22.34,
      "yield": 0.92,
      "date": "2010-06-28"
  },
  "2010-06-29": {
      "equity": 5256.1,
      "debt": 7.57,
      "gold": 20264.6537,
      "pb": 3.74,
      "pe": 22.01,
      "yield": 0.94,
      "date": "2010-06-29"
  },
  "2010-06-30": {
      "equity": 5312.5,
      "debt": 7.561,
      "gold": 20326.40082,
      "pb": 3.78,
      "pe": 22.25,
      "yield": 0.93,
      "date": "2010-06-30"
  },
  "2010-07-01": {
      "equity": 5251.4,
      "debt": 7.528,
      "gold": 20373.97485,
      "pb": 3.74,
      "pe": 21.99,
      "yield": 0.94,
      "date": "2010-07-01"
  },
  "2010-07-02": {
      "equity": 5237.1,
      "debt": 7.551,
      "gold": 19978.94143,
      "pb": 3.73,
      "pe": 21.93,
      "yield": 0.94,
      "date": "2010-07-02"
  },
  "2010-07-03": {
      "equity": 5237.1,
      "debt": 7.551,
      "gold": 19978.94143,
      "pb": 3.73,
      "pe": 21.93,
      "yield": 0.94,
      "date": "2010-07-03"
  },
  "2010-07-04": {
      "equity": 5237.1,
      "debt": 7.551,
      "gold": 19978.94143,
      "pb": 3.73,
      "pe": 21.93,
      "yield": 0.94,
      "date": "2010-07-04"
  },
  "2010-07-05": {
      "equity": 5235.9,
      "debt": 7.613,
      "gold": 19941.6674,
      "pb": 3.73,
      "pe": 21.93,
      "yield": 0.94,
      "date": "2010-07-05"
  },
  "2010-07-06": {
      "equity": 5289,
      "debt": 7.616,
      "gold": 20032.18046,
      "pb": 3.76,
      "pe": 22.15,
      "yield": 0.93,
      "date": "2010-07-06"
  },
  "2010-07-07": {
      "equity": 5241.1,
      "debt": 7.587,
      "gold": 19674.97839,
      "pb": 3.73,
      "pe": 21.95,
      "yield": 0.94,
      "date": "2010-07-07"
  },
  "2010-07-08": {
      "equity": 5296.8,
      "debt": 7.601,
      "gold": 19859.10863,
      "pb": 3.77,
      "pe": 22.18,
      "yield": 0.93,
      "date": "2010-07-08"
  },
  "2010-07-09": {
      "equity": 5352.4,
      "debt": 7.654,
      "gold": 19691.00337,
      "pb": 3.81,
      "pe": 22.42,
      "yield": 0.92,
      "date": "2010-07-09"
  },
  "2010-07-10": {
      "equity": 5352.4,
      "debt": 7.654,
      "gold": 19691.00337,
      "pb": 3.81,
      "pe": 22.42,
      "yield": 0.92,
      "date": "2010-07-10"
  },
  "2010-07-11": {
      "equity": 5352.4,
      "debt": 7.654,
      "gold": 19691.00337,
      "pb": 3.81,
      "pe": 22.42,
      "yield": 0.92,
      "date": "2010-07-11"
  },
  "2010-07-12": {
      "equity": 5383,
      "debt": 7.614,
      "gold": 19862.22685,
      "pb": 3.83,
      "pe": 22.56,
      "yield": 0.91,
      "date": "2010-07-12"
  },
  "2010-07-13": {
      "equity": 5400.6,
      "debt": 7.623,
      "gold": 19908.66506,
      "pb": 3.85,
      "pe": 22.63,
      "yield": 0.91,
      "date": "2010-07-13"
  },
  "2010-07-14": {
      "equity": 5386.1,
      "debt": 7.626,
      "gold": 19968.26752,
      "pb": 3.84,
      "pe": 22.57,
      "yield": 0.91,
      "date": "2010-07-14"
  },
  "2010-07-15": {
      "equity": 5378.7,
      "debt": 7.636,
      "gold": 19922.63356,
      "pb": 3.83,
      "pe": 22.54,
      "yield": 0.91,
      "date": "2010-07-15"
  },
  "2010-07-16": {
      "equity": 5393.9,
      "debt": 7.637,
      "gold": 19878.3012,
      "pb": 3.84,
      "pe": 22.61,
      "yield": 0.91,
      "date": "2010-07-16"
  },
  "2010-07-17": {
      "equity": 5393.9,
      "debt": 7.637,
      "gold": 19878.3012,
      "pb": 3.84,
      "pe": 22.61,
      "yield": 0.91,
      "date": "2010-07-17"
  },
  "2010-07-18": {
      "equity": 5393.9,
      "debt": 7.637,
      "gold": 19878.3012,
      "pb": 3.84,
      "pe": 22.61,
      "yield": 0.91,
      "date": "2010-07-18"
  },
  "2010-07-19": {
      "equity": 5386.4,
      "debt": 7.62,
      "gold": 19782.21838,
      "pb": 3.84,
      "pe": 22.61,
      "yield": 0.91,
      "date": "2010-07-19"
  },
  "2010-07-20": {
      "equity": 5368,
      "debt": 7.65,
      "gold": 19737.85781,
      "pb": 3.83,
      "pe": 22.53,
      "yield": 0.91,
      "date": "2010-07-20"
  },
  "2010-07-21": {
      "equity": 5399.3,
      "debt": 7.682,
      "gold": 19822.7482,
      "pb": 3.85,
      "pe": 22.66,
      "yield": 0.91,
      "date": "2010-07-21"
  },
  "2010-07-22": {
      "equity": 5441.9,
      "debt": 7.678,
      "gold": 19660.16685,
      "pb": 3.88,
      "pe": 22.75,
      "yield": 0.9,
      "date": "2010-07-22"
  },
  "2010-07-23": {
      "equity": 5449.1,
      "debt": 7.681,
      "gold": 19865.3486,
      "pb": 3.89,
      "pe": 22.78,
      "yield": 0.9,
      "date": "2010-07-23"
  },
  "2010-07-24": {
      "equity": 5449.1,
      "debt": 7.681,
      "gold": 19865.3486,
      "pb": 3.89,
      "pe": 22.78,
      "yield": 0.9,
      "date": "2010-07-24"
  },
  "2010-07-25": {
      "equity": 5449.1,
      "debt": 7.681,
      "gold": 19865.3486,
      "pb": 3.89,
      "pe": 22.78,
      "yield": 0.9,
      "date": "2010-07-25"
  },
  "2010-07-26": {
      "equity": 5418.6,
      "debt": 7.681,
      "gold": 19678.61162,
      "pb": 3.87,
      "pe": 22.53,
      "yield": 0.91,
      "date": "2010-07-26"
  },
  "2010-07-27": {
      "equity": 5430.6,
      "debt": 7.713,
      "gold": 19508.15358,
      "pb": 3.87,
      "pe": 22.58,
      "yield": 0.9,
      "date": "2010-07-27"
  },
  "2010-07-28": {
      "equity": 5397.5,
      "debt": 7.753,
      "gold": 19207.36521,
      "pb": 3.85,
      "pe": 22.44,
      "yield": 0.91,
      "date": "2010-07-28"
  },
  "2010-07-29": {
      "equity": 5408.9,
      "debt": 7.787,
      "gold": 19166.3345,
      "pb": 3.81,
      "pe": 22.49,
      "yield": 1.01,
      "date": "2010-07-29"
  },
  "2010-07-30": {
      "equity": 5367.6,
      "debt": 7.803,
      "gold": 19149.76984,
      "pb": 3.78,
      "pe": 22.31,
      "yield": 1.02,
      "date": "2010-07-30"
  },
  "2010-07-31": {
      "equity": 5367.6,
      "debt": 7.803,
      "gold": 19149.76984,
      "pb": 3.78,
      "pe": 22.31,
      "yield": 1.02,
      "date": "2010-07-31"
  },
  "2010-08-01": {
      "equity": 5367.6,
      "debt": 7.803,
      "gold": 19149.76984,
      "pb": 3.78,
      "pe": 22.31,
      "yield": 1.02,
      "date": "2010-08-01"
  },
  "2010-08-02": {
      "equity": 5431.6,
      "debt": 7.864,
      "gold": 19176.59923,
      "pb": 3.83,
      "pe": 22.91,
      "yield": 1.01,
      "date": "2010-08-02"
  },
  "2010-08-03": {
      "equity": 5439.5,
      "debt": 7.823,
      "gold": 19274.27291,
      "pb": 3.68,
      "pe": 22.95,
      "yield": 1.04,
      "date": "2010-08-03"
  },
  "2010-08-04": {
      "equity": 5467.8,
      "debt": 7.837,
      "gold": 19403.06884,
      "pb": 3.7,
      "pe": 23.07,
      "yield": 1.03,
      "date": "2010-08-04"
  },
  "2010-08-05": {
      "equity": 5447.1,
      "debt": 7.899,
      "gold": 19477.2959,
      "pb": 3.69,
      "pe": 22.98,
      "yield": 1.04,
      "date": "2010-08-05"
  },
  "2010-08-06": {
      "equity": 5439.2,
      "debt": 7.833,
      "gold": 19445.20362,
      "pb": 3.56,
      "pe": 22.95,
      "yield": 1.11,
      "date": "2010-08-06"
  },
  "2010-08-07": {
      "equity": 5439.2,
      "debt": 7.833,
      "gold": 19445.20362,
      "pb": 3.56,
      "pe": 22.95,
      "yield": 1.11,
      "date": "2010-08-07"
  },
  "2010-08-08": {
      "equity": 5439.2,
      "debt": 7.833,
      "gold": 19445.20362,
      "pb": 3.56,
      "pe": 22.95,
      "yield": 1.11,
      "date": "2010-08-08"
  },
  "2010-08-09": {
      "equity": 5486.1,
      "debt": 7.817,
      "gold": 19622.83991,
      "pb": 3.59,
      "pe": 23,
      "yield": 1.1,
      "date": "2010-08-09"
  },
  "2010-08-10": {
      "equity": 5460.7,
      "debt": 7.84,
      "gold": 19585.25547,
      "pb": 3.58,
      "pe": 22.89,
      "yield": 1.11,
      "date": "2010-08-10"
  },
  "2010-08-11": {
      "equity": 5420.6,
      "debt": 7.824,
      "gold": 19734.59849,
      "pb": 3.55,
      "pe": 22.72,
      "yield": 1.12,
      "date": "2010-08-11"
  },
  "2010-08-12": {
      "equity": 5416.4,
      "debt": 7.833,
      "gold": 19797.17455,
      "pb": 3.55,
      "pe": 22.71,
      "yield": 1.12,
      "date": "2010-08-12"
  },
  "2010-08-13": {
      "equity": 5452.1,
      "debt": 7.827,
      "gold": 20042.4981,
      "pb": 3.57,
      "pe": 22.86,
      "yield": 1.11,
      "date": "2010-08-13"
  },
  "2010-08-14": {
      "equity": 5452.1,
      "debt": 7.827,
      "gold": 20042.4981,
      "pb": 3.57,
      "pe": 22.86,
      "yield": 1.11,
      "date": "2010-08-14"
  },
  "2010-08-15": {
      "equity": 5452.1,
      "debt": 7.827,
      "gold": 20042.4981,
      "pb": 3.57,
      "pe": 22.86,
      "yield": 1.11,
      "date": "2010-08-15"
  },
  "2010-08-16": {
      "equity": 5418.3,
      "debt": 7.824,
      "gold": 20173.0542,
      "pb": 3.55,
      "pe": 22.76,
      "yield": 1.12,
      "date": "2010-08-16"
  },
  "2010-08-17": {
      "equity": 5414.1,
      "debt": 7.876,
      "gold": 20182.65931,
      "pb": 3.55,
      "pe": 22.75,
      "yield": 1.12,
      "date": "2010-08-17"
  },
  "2010-08-18": {
      "equity": 5479.1,
      "debt": 7.951,
      "gold": 20093.1445,
      "pb": 3.59,
      "pe": 23.02,
      "yield": 1.1,
      "date": "2010-08-18"
  },
  "2010-08-19": {
      "equity": 5540.2,
      "debt": 7.951,
      "gold": 20175.29057,
      "pb": 3.63,
      "pe": 23.28,
      "yield": 1.09,
      "date": "2010-08-19"
  },
  "2010-08-20": {
      "equity": 5530.6,
      "debt": 7.977,
      "gold": 20256.94986,
      "pb": 3.62,
      "pe": 23.24,
      "yield": 1.09,
      "date": "2010-08-20"
  },
  "2010-08-21": {
      "equity": 5530.6,
      "debt": 7.977,
      "gold": 20256.94986,
      "pb": 3.62,
      "pe": 23.24,
      "yield": 1.09,
      "date": "2010-08-21"
  },
  "2010-08-22": {
      "equity": 5530.6,
      "debt": 7.977,
      "gold": 20256.94986,
      "pb": 3.62,
      "pe": 23.24,
      "yield": 1.09,
      "date": "2010-08-22"
  },
  "2010-08-23": {
      "equity": 5543.5,
      "debt": 8.015,
      "gold": 20190.67356,
      "pb": 3.63,
      "pe": 23.29,
      "yield": 1.09,
      "date": "2010-08-23"
  },
  "2010-08-24": {
      "equity": 5505.1,
      "debt": 8.012,
      "gold": 20156.9869,
      "pb": 3.61,
      "pe": 23.13,
      "yield": 1.1,
      "date": "2010-08-24"
  },
  "2010-08-25": {
      "equity": 5462.3,
      "debt": 8.067,
      "gold": 20474.76675,
      "pb": 3.58,
      "pe": 22.98,
      "yield": 1.11,
      "date": "2010-08-25"
  },
  "2010-08-26": {
      "equity": 5477.9,
      "debt": 8.031,
      "gold": 20496.20275,
      "pb": 3.59,
      "pe": 23.05,
      "yield": 1.11,
      "date": "2010-08-26"
  },
  "2010-08-27": {
      "equity": 5408.7,
      "debt": 7.976,
      "gold": 20417.51001,
      "pb": 3.54,
      "pe": 22.76,
      "yield": 1.12,
      "date": "2010-08-27"
  },
  "2010-08-28": {
      "equity": 5408.7,
      "debt": 7.976,
      "gold": 20417.51001,
      "pb": 3.54,
      "pe": 22.76,
      "yield": 1.12,
      "date": "2010-08-28"
  },
  "2010-08-29": {
      "equity": 5408.7,
      "debt": 7.976,
      "gold": 20417.51001,
      "pb": 3.54,
      "pe": 22.76,
      "yield": 1.12,
      "date": "2010-08-29"
  },
  "2010-08-30": {
      "equity": 5415.4,
      "debt": 7.993,
      "gold": 20417.51001,
      "pb": 3.55,
      "pe": 22.78,
      "yield": 1.12,
      "date": "2010-08-30"
  },
  "2010-08-31": {
      "equity": 5402.4,
      "debt": 7.936,
      "gold": 20480.37884,
      "pb": 3.54,
      "pe": 22.73,
      "yield": 1.12,
      "date": "2010-08-31"
  },
  "2010-09-01": {
      "equity": 5471.8,
      "debt": 7.961,
      "gold": 20639.69382,
      "pb": 3.58,
      "pe": 23.02,
      "yield": 1.11,
      "date": "2010-09-01"
  },
  "2010-09-02": {
      "equity": 5486.1,
      "debt": 7.981,
      "gold": 20562.93056,
      "pb": 3.59,
      "pe": 23.08,
      "yield": 1.1,
      "date": "2010-09-02"
  },
  "2010-09-03": {
      "equity": 5479.4,
      "debt": 7.97,
      "gold": 20597.64017,
      "pb": 3.59,
      "pe": 23.05,
      "yield": 1.1,
      "date": "2010-09-03"
  },
  "2010-09-04": {
      "equity": 5479.4,
      "debt": 7.97,
      "gold": 20597.64017,
      "pb": 3.59,
      "pe": 23.05,
      "yield": 1.1,
      "date": "2010-09-04"
  },
  "2010-09-05": {
      "equity": 5479.4,
      "debt": 7.97,
      "gold": 20597.64017,
      "pb": 3.59,
      "pe": 23.05,
      "yield": 1.1,
      "date": "2010-09-05"
  },
  "2010-09-06": {
      "equity": 5576.9,
      "debt": 7.99,
      "gold": 20514.64047,
      "pb": 3.65,
      "pe": 23.46,
      "yield": 1.09,
      "date": "2010-09-06"
  },
  "2010-09-07": {
      "equity": 5604,
      "debt": 7.949,
      "gold": 20607.48514,
      "pb": 3.67,
      "pe": 23.58,
      "yield": 1.08,
      "date": "2010-09-07"
  },
  "2010-09-08": {
      "equity": 5607.8,
      "debt": 7.936,
      "gold": 20700.78837,
      "pb": 3.67,
      "pe": 23.59,
      "yield": 1.08,
      "date": "2010-09-08"
  },
  "2010-09-09": {
      "equity": 5640,
      "debt": 7.913,
      "gold": 20547.15251,
      "pb": 3.69,
      "pe": 23.73,
      "yield": 1.07,
      "date": "2010-09-09"
  },
  "2010-09-10": {
      "equity": 5640,
      "debt": 7.913,
      "gold": 20469.2887,
      "pb": 3.69,
      "pe": 23.73,
      "yield": 1.07,
      "date": "2010-09-10"
  },
  "2010-09-11": {
      "equity": 5640,
      "debt": 7.913,
      "gold": 20469.2887,
      "pb": 3.69,
      "pe": 23.73,
      "yield": 1.07,
      "date": "2010-09-11"
  },
  "2010-09-12": {
      "equity": 5640,
      "debt": 7.913,
      "gold": 20469.2887,
      "pb": 3.69,
      "pe": 23.73,
      "yield": 1.07,
      "date": "2010-09-12"
  },
  "2010-09-13": {
      "equity": 5760,
      "debt": 7.977,
      "gold": 20344.3447,
      "pb": 3.78,
      "pe": 24.32,
      "yield": 1.05,
      "date": "2010-09-13"
  },
  "2010-09-14": {
      "equity": 5795.5,
      "debt": 7.944,
      "gold": 20535.31808,
      "pb": 3.81,
      "pe": 24.47,
      "yield": 1.04,
      "date": "2010-09-14"
  },
  "2010-09-15": {
      "equity": 5860.9,
      "debt": 7.948,
      "gold": 20778.23948,
      "pb": 3.85,
      "pe": 24.74,
      "yield": 1.03,
      "date": "2010-09-15"
  },
  "2010-09-16": {
      "equity": 5828.7,
      "debt": 7.973,
      "gold": 20694.6119,
      "pb": 3.83,
      "pe": 24.61,
      "yield": 1.04,
      "date": "2010-09-16"
  },
  "2010-09-17": {
      "equity": 5884.9,
      "debt": 7.985,
      "gold": 20723.60006,
      "pb": 3.87,
      "pe": 24.84,
      "yield": 1.03,
      "date": "2010-09-17"
  },
  "2010-09-18": {
      "equity": 5884.9,
      "debt": 7.985,
      "gold": 20723.60006,
      "pb": 3.87,
      "pe": 24.84,
      "yield": 1.03,
      "date": "2010-09-18"
  },
  "2010-09-19": {
      "equity": 5884.9,
      "debt": 7.985,
      "gold": 20723.60006,
      "pb": 3.87,
      "pe": 24.84,
      "yield": 1.03,
      "date": "2010-09-19"
  },
  "2010-09-20": {
      "equity": 5980.4,
      "debt": 7.981,
      "gold": 20640.16297,
      "pb": 3.93,
      "pe": 25.25,
      "yield": 1.01,
      "date": "2010-09-20"
  },
  "2010-09-21": {
      "equity": 6009,
      "debt": 7.968,
      "gold": 20600.19048,
      "pb": 3.87,
      "pe": 25.37,
      "yield": 1.02,
      "date": "2010-09-21"
  },
  "2010-09-22": {
      "equity": 5991,
      "debt": 7.953,
      "gold": 20773.16355,
      "pb": 3.86,
      "pe": 25.29,
      "yield": 1.02,
      "date": "2010-09-22"
  },
  "2010-09-23": {
      "equity": 5959.5,
      "debt": 7.925,
      "gold": 20801.03353,
      "pb": 3.84,
      "pe": 25.16,
      "yield": 1.03,
      "date": "2010-09-23"
  },
  "2010-09-24": {
      "equity": 6018.3,
      "debt": 7.867,
      "gold": 20720.29136,
      "pb": 3.88,
      "pe": 25.41,
      "yield": 1.02,
      "date": "2010-09-24"
  },
  "2010-09-25": {
      "equity": 6018.3,
      "debt": 7.867,
      "gold": 20720.29136,
      "pb": 3.88,
      "pe": 25.41,
      "yield": 1.02,
      "date": "2010-09-25"
  },
  "2010-09-26": {
      "equity": 6018.3,
      "debt": 7.867,
      "gold": 20720.29136,
      "pb": 3.88,
      "pe": 25.41,
      "yield": 1.02,
      "date": "2010-09-26"
  },
  "2010-09-27": {
      "equity": 6035.6,
      "debt": 7.864,
      "gold": 20614.37415,
      "pb": 3.89,
      "pe": 25.48,
      "yield": 1.01,
      "date": "2010-09-27"
  },
  "2010-09-28": {
      "equity": 6029.5,
      "debt": 7.849,
      "gold": 20531.15575,
      "pb": 3.82,
      "pe": 25.45,
      "yield": 1.03,
      "date": "2010-09-28"
  },
  "2010-09-29": {
      "equity": 5991.3,
      "debt": 7.852,
      "gold": 20726.66185,
      "pb": 3.79,
      "pe": 25.29,
      "yield": 1.03,
      "date": "2010-09-29"
  },
  "2010-09-30": {
      "equity": 6029.9,
      "debt": 7.852,
      "gold": 20793.70712,
      "pb": 3.82,
      "pe": 25.46,
      "yield": 1.03,
      "date": "2010-09-30"
  },
  "2010-10-01": {
      "equity": 6143.4,
      "debt": 7.912,
      "gold": 20593.85527,
      "pb": 3.93,
      "pe": 25.54,
      "yield": 1.02,
      "date": "2010-10-01"
  },
  "2010-10-02": {
      "equity": 6143.4,
      "debt": 7.912,
      "gold": 20593.85527,
      "pb": 3.93,
      "pe": 25.54,
      "yield": 1.02,
      "date": "2010-10-02"
  },
  "2010-10-03": {
      "equity": 6143.4,
      "debt": 7.912,
      "gold": 20593.85527,
      "pb": 3.93,
      "pe": 25.54,
      "yield": 1.02,
      "date": "2010-10-03"
  },
  "2010-10-04": {
      "equity": 6159.4,
      "debt": 7.936,
      "gold": 20696.61193,
      "pb": 3.88,
      "pe": 25.61,
      "yield": 1.02,
      "date": "2010-10-04"
  },
  "2010-10-05": {
      "equity": 6145.8,
      "debt": 7.956,
      "gold": 20863.97996,
      "pb": 3.87,
      "pe": 25.55,
      "yield": 1.02,
      "date": "2010-10-05"
  },
  "2010-10-06": {
      "equity": 6186.4,
      "debt": 7.93,
      "gold": 21150.88802,
      "pb": 3.89,
      "pe": 25.72,
      "yield": 1.01,
      "date": "2010-10-06"
  },
  "2010-10-07": {
      "equity": 6120.3,
      "debt": 7.947,
      "gold": 21203.29812,
      "pb": 3.85,
      "pe": 25.44,
      "yield": 1.02,
      "date": "2010-10-07"
  },
  "2010-10-08": {
      "equity": 6103.4,
      "debt": 7.991,
      "gold": 20854.25493,
      "pb": 3.84,
      "pe": 25.37,
      "yield": 1.03,
      "date": "2010-10-08"
  },
  "2010-10-09": {
      "equity": 6103.4,
      "debt": 7.991,
      "gold": 20854.25493,
      "pb": 3.84,
      "pe": 25.37,
      "yield": 1.03,
      "date": "2010-10-09"
  },
  "2010-10-10": {
      "equity": 6103.4,
      "debt": 7.991,
      "gold": 20854.25493,
      "pb": 3.84,
      "pe": 25.37,
      "yield": 1.03,
      "date": "2010-10-10"
  },
  "2010-10-11": {
      "equity": 6135.8,
      "debt": 8,
      "gold": 21131.62842,
      "pb": 3.86,
      "pe": 25.51,
      "yield": 1.02,
      "date": "2010-10-11"
  },
  "2010-10-12": {
      "equity": 6090.9,
      "debt": 8.021,
      "gold": 21167.01529,
      "pb": 3.83,
      "pe": 25.32,
      "yield": 1.03,
      "date": "2010-10-12"
  },
  "2010-10-13": {
      "equity": 6233.9,
      "debt": 8.022,
      "gold": 21333.85774,
      "pb": 3.92,
      "pe": 25.91,
      "yield": 1.01,
      "date": "2010-10-13"
  },
  "2010-10-14": {
      "equity": 6177.3,
      "debt": 8.025,
      "gold": 21490.88696,
      "pb": 3.89,
      "pe": 25.68,
      "yield": 1.02,
      "date": "2010-10-14"
  },
  "2010-10-15": {
      "equity": 6062.6,
      "debt": 8.07,
      "gold": 21424.26851,
      "pb": 3.81,
      "pe": 25.2,
      "yield": 1.03,
      "date": "2010-10-15"
  },
  "2010-10-16": {
      "equity": 6062.6,
      "debt": 8.07,
      "gold": 21424.26851,
      "pb": 3.81,
      "pe": 25.2,
      "yield": 1.03,
      "date": "2010-10-16"
  },
  "2010-10-17": {
      "equity": 6062.6,
      "debt": 8.07,
      "gold": 21424.26851,
      "pb": 3.81,
      "pe": 25.2,
      "yield": 1.03,
      "date": "2010-10-17"
  },
  "2010-10-18": {
      "equity": 6075.9,
      "debt": 8.095,
      "gold": 21283.94152,
      "pb": 3.82,
      "pe": 25.07,
      "yield": 1.03,
      "date": "2010-10-18"
  },
  "2010-10-19": {
      "equity": 6027.3,
      "debt": 8.086,
      "gold": 21404.3387,
      "pb": 3.79,
      "pe": 24.87,
      "yield": 1.04,
      "date": "2010-10-19"
  },
  "2010-10-20": {
      "equity": 5982.1,
      "debt": 8.157,
      "gold": 20982.62403,
      "pb": 3.76,
      "pe": 24.69,
      "yield": 1.05,
      "date": "2010-10-20"
  },
  "2010-10-21": {
      "equity": 6101.5,
      "debt": 8.14,
      "gold": 21033.24926,
      "pb": 3.84,
      "pe": 25.18,
      "yield": 1.03,
      "date": "2010-10-21"
  },
  "2010-10-22": {
      "equity": 6066,
      "debt": 8.147,
      "gold": 20748.44706,
      "pb": 3.82,
      "pe": 25.03,
      "yield": 1.03,
      "date": "2010-10-22"
  },
  "2010-10-23": {
      "equity": 6066,
      "debt": 8.147,
      "gold": 20748.44706,
      "pb": 3.82,
      "pe": 25.03,
      "yield": 1.03,
      "date": "2010-10-23"
  },
  "2010-10-24": {
      "equity": 6066,
      "debt": 8.147,
      "gold": 20748.44706,
      "pb": 3.82,
      "pe": 25.03,
      "yield": 1.03,
      "date": "2010-10-24"
  },
  "2010-10-25": {
      "equity": 6105.8,
      "debt": 8.147,
      "gold": 21048.31831,
      "pb": 3.84,
      "pe": 25.2,
      "yield": 1.03,
      "date": "2010-10-25"
  },
  "2010-10-26": {
      "equity": 6082,
      "debt": 8.177,
      "gold": 20913.81859,
      "pb": 3.82,
      "pe": 24.98,
      "yield": 1.03,
      "date": "2010-10-26"
  },
  "2010-10-27": {
      "equity": 6012.6,
      "debt": 8.13,
      "gold": 20900.48149,
      "pb": 3.78,
      "pe": 24.69,
      "yield": 1.04,
      "date": "2010-10-27"
  },
  "2010-10-28": {
      "equity": 5987.7,
      "debt": 8.135,
      "gold": 20843.02721,
      "pb": 3.76,
      "pe": 24.59,
      "yield": 1.04,
      "date": "2010-10-28"
  },
  "2010-10-29": {
      "equity": 6017.7,
      "debt": 8.121,
      "gold": 20952.21785,
      "pb": 3.78,
      "pe": 24.71,
      "yield": 1.04,
      "date": "2010-10-29"
  },
  "2010-10-30": {
      "equity": 6017.7,
      "debt": 8.121,
      "gold": 20952.21785,
      "pb": 3.78,
      "pe": 24.71,
      "yield": 1.04,
      "date": "2010-10-30"
  },
  "2010-10-31": {
      "equity": 6017.7,
      "debt": 8.121,
      "gold": 20952.21785,
      "pb": 3.78,
      "pe": 24.71,
      "yield": 1.04,
      "date": "2010-10-31"
  },
  "2010-11-01": {
      "equity": 6117.5,
      "debt": 8.11,
      "gold": 21359.35731,
      "pb": 3.85,
      "pe": 25.12,
      "yield": 1.02,
      "date": "2010-11-01"
  },
  "2010-11-02": {
      "equity": 6119,
      "debt": 7.973,
      "gold": 21256.54773,
      "pb": 3.85,
      "pe": 25.13,
      "yield": 1.02,
      "date": "2010-11-02"
  },
  "2010-11-03": {
      "equity": 6160.5,
      "debt": 7.955,
      "gold": 21259.5813,
      "pb": 3.87,
      "pe": 25.3,
      "yield": 1.01,
      "date": "2010-11-03"
  },
  "2010-11-04": {
      "equity": 6281.8,
      "debt": 7.985,
      "gold": 21231.49615,
      "pb": 3.95,
      "pe": 25.47,
      "yield": 0.99,
      "date": "2010-11-04"
  },
  "2010-11-05": {
      "equity": 6312.4,
      "debt": 7.985,
      "gold": 21594.1939,
      "pb": 3.97,
      "pe": 25.59,
      "yield": 0.99,
      "date": "2010-11-05"
  },
  "2010-11-06": {
      "equity": 6312.4,
      "debt": 7.985,
      "gold": 21594.1939,
      "pb": 3.97,
      "pe": 25.59,
      "yield": 0.99,
      "date": "2010-11-06"
  },
  "2010-11-07": {
      "equity": 6312.4,
      "debt": 7.985,
      "gold": 21594.1939,
      "pb": 3.97,
      "pe": 25.59,
      "yield": 0.99,
      "date": "2010-11-07"
  },
  "2010-11-08": {
      "equity": 6273.2,
      "debt": 8.012,
      "gold": 21767.24457,
      "pb": 3.94,
      "pe": 25.43,
      "yield": 1,
      "date": "2010-11-08"
  },
  "2010-11-09": {
      "equity": 6301.5,
      "debt": 8.032,
      "gold": 22113.37413,
      "pb": 3.96,
      "pe": 25.55,
      "yield": 0.99,
      "date": "2010-11-09"
  },
  "2010-11-10": {
      "equity": 6275.7,
      "debt": 8.078,
      "gold": 21910.65451,
      "pb": 3.94,
      "pe": 25.44,
      "yield": 1,
      "date": "2010-11-10"
  },
  "2010-11-11": {
      "equity": 6194.2,
      "debt": 8.09,
      "gold": 22085.05617,
      "pb": 3.89,
      "pe": 25.11,
      "yield": 1.01,
      "date": "2010-11-11"
  },
  "2010-11-12": {
      "equity": 6071.6,
      "debt": 8.089,
      "gold": 21930.64428,
      "pb": 3.82,
      "pe": 24.62,
      "yield": 1.03,
      "date": "2010-11-12"
  },
  "2010-11-13": {
      "equity": 6071.6,
      "debt": 8.089,
      "gold": 21930.64428,
      "pb": 3.82,
      "pe": 24.62,
      "yield": 1.03,
      "date": "2010-11-13"
  },
  "2010-11-14": {
      "equity": 6071.6,
      "debt": 8.089,
      "gold": 21930.64428,
      "pb": 3.82,
      "pe": 24.62,
      "yield": 1.03,
      "date": "2010-11-14"
  },
  "2010-11-15": {
      "equity": 6121.6,
      "debt": 8.066,
      "gold": 21812.11309,
      "pb": 3.86,
      "pe": 24.39,
      "yield": 1.02,
      "date": "2010-11-15"
  },
  "2010-11-16": {
      "equity": 5988.7,
      "debt": 8.069,
      "gold": 21819.59823,
      "pb": 3.77,
      "pe": 23.86,
      "yield": 1.04,
      "date": "2010-11-16"
  },
  "2010-11-17": {
      "equity": 5988.7,
      "debt": 8.069,
      "gold": 21391.44958,
      "pb": 3.77,
      "pe": 23.86,
      "yield": 1.04,
      "date": "2010-11-17"
  },
  "2010-11-18": {
      "equity": 5998.8,
      "debt": 8.025,
      "gold": 21643.77502,
      "pb": 3.78,
      "pe": 23.9,
      "yield": 1.04,
      "date": "2010-11-18"
  },
  "2010-11-19": {
      "equity": 5890.3,
      "debt": 8.03,
      "gold": 21689.25731,
      "pb": 3.71,
      "pe": 23.47,
      "yield": 1.06,
      "date": "2010-11-19"
  },
  "2010-11-20": {
      "equity": 5890.3,
      "debt": 8.03,
      "gold": 21689.25731,
      "pb": 3.71,
      "pe": 23.47,
      "yield": 1.06,
      "date": "2010-11-20"
  },
  "2010-11-21": {
      "equity": 5890.3,
      "debt": 8.03,
      "gold": 21689.25731,
      "pb": 3.71,
      "pe": 23.47,
      "yield": 1.06,
      "date": "2010-11-21"
  },
  "2010-11-22": {
      "equity": 6010,
      "debt": 8.021,
      "gold": 21744.32706,
      "pb": 3.79,
      "pe": 23.98,
      "yield": 1.04,
      "date": "2010-11-22"
  },
  "2010-11-23": {
      "equity": 5934.7,
      "debt": 7.996,
      "gold": 21884.40361,
      "pb": 3.74,
      "pe": 23.69,
      "yield": 1.05,
      "date": "2010-11-23"
  },
  "2010-11-24": {
      "equity": 5865.7,
      "debt": 8.033,
      "gold": 22187.8728,
      "pb": 3.7,
      "pe": 23.41,
      "yield": 1.06,
      "date": "2010-11-24"
  },
  "2010-11-25": {
      "equity": 5799.7,
      "debt": 8.019,
      "gold": 22005.73555,
      "pb": 3.66,
      "pe": 23.15,
      "yield": 1.08,
      "date": "2010-11-25"
  },
  "2010-11-26": {
      "equity": 5751.9,
      "debt": 7.968,
      "gold": 22098.16399,
      "pb": 3.63,
      "pe": 22.96,
      "yield": 1.08,
      "date": "2010-11-26"
  },
  "2010-11-27": {
      "equity": 5751.9,
      "debt": 7.968,
      "gold": 22098.16399,
      "pb": 3.63,
      "pe": 22.96,
      "yield": 1.08,
      "date": "2010-11-27"
  },
  "2010-11-28": {
      "equity": 5751.9,
      "debt": 7.968,
      "gold": 22098.16399,
      "pb": 3.63,
      "pe": 22.96,
      "yield": 1.08,
      "date": "2010-11-28"
  },
  "2010-11-29": {
      "equity": 5830,
      "debt": 8.016,
      "gold": 22041.02365,
      "pb": 3.67,
      "pe": 23.26,
      "yield": 1.07,
      "date": "2010-11-29"
  },
  "2010-11-30": {
      "equity": 5862.7,
      "debt": 8.066,
      "gold": 22256.2338,
      "pb": 3.69,
      "pe": 23.39,
      "yield": 1.06,
      "date": "2010-11-30"
  },
  "2010-12-01": {
      "equity": 5960.9,
      "debt": 8.114,
      "gold": 22271.75435,
      "pb": 3.76,
      "pe": 23.78,
      "yield": 1.05,
      "date": "2010-12-01"
  },
  "2010-12-02": {
      "equity": 6011.7,
      "debt": 8.087,
      "gold": 22206.07065,
      "pb": 3.78,
      "pe": 23.96,
      "yield": 1.04,
      "date": "2010-12-02"
  },
  "2010-12-03": {
      "equity": 5992.8,
      "debt": 8.178,
      "gold": 22143.20535,
      "pb": 3.77,
      "pe": 23.89,
      "yield": 1.04,
      "date": "2010-12-03"
  },
  "2010-12-04": {
      "equity": 5992.8,
      "debt": 8.178,
      "gold": 22143.20535,
      "pb": 3.77,
      "pe": 23.89,
      "yield": 1.04,
      "date": "2010-12-04"
  },
  "2010-12-05": {
      "equity": 5992.8,
      "debt": 8.178,
      "gold": 22143.20535,
      "pb": 3.77,
      "pe": 23.89,
      "yield": 1.04,
      "date": "2010-12-05"
  },
  "2010-12-06": {
      "equity": 5992.2,
      "debt": 8.219,
      "gold": 22377.77386,
      "pb": 3.77,
      "pe": 23.88,
      "yield": 1.04,
      "date": "2010-12-06"
  },
  "2010-12-07": {
      "equity": 5976.5,
      "debt": 8.097,
      "gold": 22456.75232,
      "pb": 3.76,
      "pe": 23.82,
      "yield": 1.04,
      "date": "2010-12-07"
  },
  "2010-12-08": {
      "equity": 5903.7,
      "debt": 8.114,
      "gold": 22185.07557,
      "pb": 3.72,
      "pe": 23.53,
      "yield": 1.06,
      "date": "2010-12-08"
  },
  "2010-12-09": {
      "equity": 5766.5,
      "debt": 8.125,
      "gold": 22041.70444,
      "pb": 3.63,
      "pe": 22.98,
      "yield": 1.08,
      "date": "2010-12-09"
  },
  "2010-12-10": {
      "equity": 5857.3,
      "debt": 8.097,
      "gold": 22090.84816,
      "pb": 3.69,
      "pe": 23.35,
      "yield": 1.07,
      "date": "2010-12-10"
  },
  "2010-12-11": {
      "equity": 5857.3,
      "debt": 8.097,
      "gold": 22090.84816,
      "pb": 3.69,
      "pe": 23.35,
      "yield": 1.07,
      "date": "2010-12-11"
  },
  "2010-12-12": {
      "equity": 5857.3,
      "debt": 8.097,
      "gold": 22090.84816,
      "pb": 3.69,
      "pe": 23.35,
      "yield": 1.07,
      "date": "2010-12-12"
  },
  "2010-12-13": {
      "equity": 5907.6,
      "debt": 8.114,
      "gold": 22097.31389,
      "pb": 3.72,
      "pe": 23.58,
      "yield": 1.06,
      "date": "2010-12-13"
  },
  "2010-12-14": {
      "equity": 5944.1,
      "debt": 8.099,
      "gold": 22279.67689,
      "pb": 3.75,
      "pe": 23.72,
      "yield": 1.05,
      "date": "2010-12-14"
  },
  "2010-12-15": {
      "equity": 5892.3,
      "debt": 8.078,
      "gold": 22234.42741,
      "pb": 3.71,
      "pe": 23.51,
      "yield": 1.06,
      "date": "2010-12-15"
  },
  "2010-12-16": {
      "equity": 5948.7,
      "debt": 7.959,
      "gold": 22137.06767,
      "pb": 3.75,
      "pe": 23.74,
      "yield": 1.05,
      "date": "2010-12-16"
  },
  "2010-12-17": {
      "equity": 5948.7,
      "debt": 7.959,
      "gold": 21989.11445,
      "pb": 3.75,
      "pe": 23.74,
      "yield": 1.05,
      "date": "2010-12-17"
  },
  "2010-12-18": {
      "equity": 5948.7,
      "debt": 7.959,
      "gold": 21989.11445,
      "pb": 3.75,
      "pe": 23.74,
      "yield": 1.05,
      "date": "2010-12-18"
  },
  "2010-12-19": {
      "equity": 5948.7,
      "debt": 7.959,
      "gold": 21989.11445,
      "pb": 3.75,
      "pe": 23.74,
      "yield": 1.05,
      "date": "2010-12-19"
  },
  "2010-12-20": {
      "equity": 5947,
      "debt": 7.967,
      "gold": 22121.96335,
      "pb": 3.75,
      "pe": 23.73,
      "yield": 1.05,
      "date": "2010-12-20"
  },
  "2010-12-21": {
      "equity": 6000.6,
      "debt": 7.934,
      "gold": 22098.16752,
      "pb": 3.78,
      "pe": 23.95,
      "yield": 1.04,
      "date": "2010-12-21"
  },
  "2010-12-22": {
      "equity": 5984.4,
      "debt": 7.957,
      "gold": 22048.0079,
      "pb": 3.77,
      "pe": 23.88,
      "yield": 1.04,
      "date": "2010-12-22"
  },
  "2010-12-23": {
      "equity": 5980,
      "debt": 7.933,
      "gold": 22007.69678,
      "pb": 3.77,
      "pe": 23.86,
      "yield": 1.04,
      "date": "2010-12-23"
  },
  "2010-12-24": {
      "equity": 6011.6,
      "debt": 7.916,
      "gold": 21969.08587,
      "pb": 3.79,
      "pe": 23.99,
      "yield": 1.04,
      "date": "2010-12-24"
  },
  "2010-12-25": {
      "equity": 6011.6,
      "debt": 7.916,
      "gold": 21969.08587,
      "pb": 3.79,
      "pe": 23.99,
      "yield": 1.04,
      "date": "2010-12-25"
  },
  "2010-12-26": {
      "equity": 6011.6,
      "debt": 7.916,
      "gold": 21969.08587,
      "pb": 3.79,
      "pe": 23.99,
      "yield": 1.04,
      "date": "2010-12-26"
  },
  "2010-12-27": {
      "equity": 5998.1,
      "debt": 7.919,
      "gold": 21969.08587,
      "pb": 3.78,
      "pe": 23.94,
      "yield": 1.04,
      "date": "2010-12-27"
  },
  "2010-12-28": {
      "equity": 5996,
      "debt": 7.903,
      "gold": 21969.08587,
      "pb": 3.78,
      "pe": 23.93,
      "yield": 1.04,
      "date": "2010-12-28"
  },
  "2010-12-29": {
      "equity": 6060.3,
      "debt": 7.893,
      "gold": 22258.36787,
      "pb": 3.82,
      "pe": 24.19,
      "yield": 1.03,
      "date": "2010-12-29"
  },
  "2010-12-30": {
      "equity": 6101.8,
      "debt": 7.92,
      "gold": 22325.49428,
      "pb": 3.85,
      "pe": 24.35,
      "yield": 1.02,
      "date": "2010-12-30"
  },
  "2010-12-31": {
      "equity": 6134.5,
      "debt": 7.913,
      "gold": 22238.56858,
      "pb": 3.87,
      "pe": 24.48,
      "yield": 1.02,
      "date": "2010-12-31"
  },
  "2011-01-01": {
      "equity": 6134.5,
      "debt": 7.913,
      "gold": 22238.56858,
      "pb": 3.87,
      "pe": 24.48,
      "yield": 1.02,
      "date": "2011-01-01"
  },
  "2011-01-02": {
      "equity": 6134.5,
      "debt": 7.913,
      "gold": 22238.56858,
      "pb": 3.87,
      "pe": 24.48,
      "yield": 1.02,
      "date": "2011-01-02"
  },
  "2011-01-03": {
      "equity": 6157.6,
      "debt": 7.96,
      "gold": 22238.56858,
      "pb": 3.88,
      "pe": 24.57,
      "yield": 1.01,
      "date": "2011-01-03"
  },
  "2011-01-04": {
      "equity": 6146.3,
      "debt": 8.068,
      "gold": 22272.73497,
      "pb": 3.87,
      "pe": 24.53,
      "yield": 1.01,
      "date": "2011-01-04"
  },
  "2011-01-05": {
      "equity": 6079.8,
      "debt": 8.068,
      "gold": 22085.37011,
      "pb": 3.83,
      "pe": 24.26,
      "yield": 1.03,
      "date": "2011-01-05"
  },
  "2011-01-06": {
      "equity": 6048.2,
      "debt": 8.126,
      "gold": 22028.52255,
      "pb": 3.81,
      "pe": 24.14,
      "yield": 1.03,
      "date": "2011-01-06"
  },
  "2011-01-07": {
      "equity": 5904.6,
      "debt": 8.199,
      "gold": 21740.35874,
      "pb": 3.72,
      "pe": 23.56,
      "yield": 1.06,
      "date": "2011-01-07"
  },
  "2011-01-08": {
      "equity": 5904.6,
      "debt": 8.199,
      "gold": 21740.35874,
      "pb": 3.72,
      "pe": 23.56,
      "yield": 1.06,
      "date": "2011-01-08"
  },
  "2011-01-09": {
      "equity": 5904.6,
      "debt": 8.199,
      "gold": 21740.35874,
      "pb": 3.72,
      "pe": 23.56,
      "yield": 1.06,
      "date": "2011-01-09"
  },
  "2011-01-10": {
      "equity": 5762.8,
      "debt": 8.226,
      "gold": 21889.97337,
      "pb": 3.63,
      "pe": 23.01,
      "yield": 1.08,
      "date": "2011-01-10"
  },
  "2011-01-11": {
      "equity": 5754.1,
      "debt": 8.208,
      "gold": 21969.73492,
      "pb": 3.63,
      "pe": 22.98,
      "yield": 1.08,
      "date": "2011-01-11"
  },
  "2011-01-12": {
      "equity": 5863.2,
      "debt": 8.178,
      "gold": 21970.46509,
      "pb": 3.7,
      "pe": 23.41,
      "yield": 1.06,
      "date": "2011-01-12"
  },
  "2011-01-13": {
      "equity": 5751.9,
      "debt": 8.142,
      "gold": 21938.96894,
      "pb": 3.63,
      "pe": 22.92,
      "yield": 1.08,
      "date": "2011-01-13"
  },
  "2011-01-14": {
      "equity": 5654.5,
      "debt": 8.191,
      "gold": 21910.80266,
      "pb": 3.57,
      "pe": 22.5,
      "yield": 1.1,
      "date": "2011-01-14"
  },
  "2011-01-15": {
      "equity": 5654.5,
      "debt": 8.191,
      "gold": 21910.80266,
      "pb": 3.57,
      "pe": 22.5,
      "yield": 1.1,
      "date": "2011-01-15"
  },
  "2011-01-16": {
      "equity": 5654.5,
      "debt": 8.191,
      "gold": 21910.80266,
      "pb": 3.57,
      "pe": 22.5,
      "yield": 1.1,
      "date": "2011-01-16"
  },
  "2011-01-17": {
      "equity": 5654.7,
      "debt": 8.234,
      "gold": 21765.87241,
      "pb": 3.57,
      "pe": 22.5,
      "yield": 1.1,
      "date": "2011-01-17"
  },
  "2011-01-18": {
      "equity": 5724,
      "debt": 8.194,
      "gold": 21951.08203,
      "pb": 3.61,
      "pe": 22.66,
      "yield": 1.09,
      "date": "2011-01-18"
  },
  "2011-01-19": {
      "equity": 5691,
      "debt": 8.184,
      "gold": 22050.64992,
      "pb": 3.59,
      "pe": 22.51,
      "yield": 1.09,
      "date": "2011-01-19"
  },
  "2011-01-20": {
      "equity": 5711.6,
      "debt": 8.13,
      "gold": 21916.61581,
      "pb": 3.6,
      "pe": 22.59,
      "yield": 1.09,
      "date": "2011-01-20"
  },
  "2011-01-21": {
      "equity": 5696.5,
      "debt": 8.171,
      "gold": 21627.6407,
      "pb": 3.59,
      "pe": 22.41,
      "yield": 1.09,
      "date": "2011-01-21"
  },
  "2011-01-22": {
      "equity": 5696.5,
      "debt": 8.171,
      "gold": 21627.6407,
      "pb": 3.59,
      "pe": 22.41,
      "yield": 1.09,
      "date": "2011-01-22"
  },
  "2011-01-23": {
      "equity": 5696.5,
      "debt": 8.171,
      "gold": 21627.6407,
      "pb": 3.59,
      "pe": 22.41,
      "yield": 1.09,
      "date": "2011-01-23"
  },
  "2011-01-24": {
      "equity": 5743.2,
      "debt": 8.185,
      "gold": 21615.04083,
      "pb": 3.62,
      "pe": 22.26,
      "yield": 1.08,
      "date": "2011-01-24"
  },
  "2011-01-25": {
      "equity": 5687.4,
      "debt": 8.154,
      "gold": 21375.40345,
      "pb": 3.59,
      "pe": 21.96,
      "yield": 1.1,
      "date": "2011-01-25"
  },
  "2011-01-26": {
      "equity": 5687.4,
      "debt": 8.154,
      "gold": 21528.54548,
      "pb": 3.59,
      "pe": 21.96,
      "yield": 1.1,
      "date": "2011-01-26"
  },
  "2011-01-27": {
      "equity": 5604.3,
      "debt": 8.148,
      "gold": 21558.42607,
      "pb": 3.53,
      "pe": 21.64,
      "yield": 1.11,
      "date": "2011-01-27"
  },
  "2011-01-28": {
      "equity": 5512.1,
      "debt": 8.132,
      "gold": 21244.37468,
      "pb": 3.48,
      "pe": 21.23,
      "yield": 1.13,
      "date": "2011-01-28"
  },
  "2011-01-29": {
      "equity": 5512.1,
      "debt": 8.132,
      "gold": 21244.37468,
      "pb": 3.48,
      "pe": 21.23,
      "yield": 1.13,
      "date": "2011-01-29"
  },
  "2011-01-30": {
      "equity": 5512.1,
      "debt": 8.132,
      "gold": 21244.37468,
      "pb": 3.48,
      "pe": 21.23,
      "yield": 1.13,
      "date": "2011-01-30"
  },
  "2011-01-31": {
      "equity": 5505.9,
      "debt": 8.148,
      "gold": 21593.90818,
      "pb": 3.47,
      "pe": 21.06,
      "yield": 1.13,
      "date": "2011-01-31"
  },
  "2011-02-01": {
      "equity": 5417.2,
      "debt": 8.154,
      "gold": 21449.91622,
      "pb": 3.42,
      "pe": 20.7,
      "yield": 1.15,
      "date": "2011-02-01"
  },
  "2011-02-02": {
      "equity": 5432,
      "debt": 8.15,
      "gold": 21451.33071,
      "pb": 3.43,
      "pe": 20.8,
      "yield": 1.15,
      "date": "2011-02-02"
  },
  "2011-02-03": {
      "equity": 5526.7,
      "debt": 8.162,
      "gold": 21451.98328,
      "pb": 3.49,
      "pe": 21.16,
      "yield": 1.13,
      "date": "2011-02-03"
  },
  "2011-02-04": {
      "equity": 5395.7,
      "debt": 8.196,
      "gold": 21673.26761,
      "pb": 3.4,
      "pe": 20.67,
      "yield": 1.15,
      "date": "2011-02-04"
  },
  "2011-02-05": {
      "equity": 5395.7,
      "debt": 8.196,
      "gold": 21673.26761,
      "pb": 3.4,
      "pe": 20.67,
      "yield": 1.15,
      "date": "2011-02-05"
  },
  "2011-02-06": {
      "equity": 5395.7,
      "debt": 8.196,
      "gold": 21673.26761,
      "pb": 3.4,
      "pe": 20.67,
      "yield": 1.15,
      "date": "2011-02-06"
  },
  "2011-02-07": {
      "equity": 5396,
      "debt": 8.207,
      "gold": 21535.75195,
      "pb": 3.41,
      "pe": 20.69,
      "yield": 1.15,
      "date": "2011-02-07"
  },
  "2011-02-08": {
      "equity": 5312.5,
      "debt": 8.179,
      "gold": 21634.53324,
      "pb": 3.35,
      "pe": 20.35,
      "yield": 1.17,
      "date": "2011-02-08"
  },
  "2011-02-09": {
      "equity": 5253.5,
      "debt": 8.166,
      "gold": 21860.45962,
      "pb": 3.32,
      "pe": 20.12,
      "yield": 1.19,
      "date": "2011-02-09"
  },
  "2011-02-10": {
      "equity": 5225.8,
      "debt": 8.131,
      "gold": 21916.52057,
      "pb": 3.3,
      "pe": 20.01,
      "yield": 1.19,
      "date": "2011-02-10"
  },
  "2011-02-11": {
      "equity": 5310,
      "debt": 8.127,
      "gold": 21901.37745,
      "pb": 3.35,
      "pe": 20.34,
      "yield": 1.17,
      "date": "2011-02-11"
  },
  "2011-02-12": {
      "equity": 5310,
      "debt": 8.127,
      "gold": 21901.37745,
      "pb": 3.35,
      "pe": 20.34,
      "yield": 1.17,
      "date": "2011-02-12"
  },
  "2011-02-13": {
      "equity": 5310,
      "debt": 8.127,
      "gold": 21901.37745,
      "pb": 3.35,
      "pe": 20.34,
      "yield": 1.17,
      "date": "2011-02-13"
  },
  "2011-02-14": {
      "equity": 5456,
      "debt": 8.09,
      "gold": 21775.38581,
      "pb": 3.45,
      "pe": 20.86,
      "yield": 1.14,
      "date": "2011-02-14"
  },
  "2011-02-15": {
      "equity": 5481,
      "debt": 8.105,
      "gold": 22033.83481,
      "pb": 3.47,
      "pe": 20.96,
      "yield": 1.13,
      "date": "2011-02-15"
  },
  "2011-02-16": {
      "equity": 5481.7,
      "debt": 8.105,
      "gold": 22069.96243,
      "pb": 3.47,
      "pe": 20.95,
      "yield": 1.13,
      "date": "2011-02-16"
  },
  "2011-02-17": {
      "equity": 5546.4,
      "debt": 8.083,
      "gold": 22025.10097,
      "pb": 3.51,
      "pe": 21.19,
      "yield": 1.12,
      "date": "2011-02-17"
  },
  "2011-02-18": {
      "equity": 5458.9,
      "debt": 8.099,
      "gold": 22090.19559,
      "pb": 3.46,
      "pe": 20.86,
      "yield": 1.14,
      "date": "2011-02-18"
  },
  "2011-02-19": {
      "equity": 5458.9,
      "debt": 8.099,
      "gold": 22090.19559,
      "pb": 3.46,
      "pe": 20.86,
      "yield": 1.14,
      "date": "2011-02-19"
  },
  "2011-02-20": {
      "equity": 5458.9,
      "debt": 8.099,
      "gold": 22090.19559,
      "pb": 3.46,
      "pe": 20.86,
      "yield": 1.14,
      "date": "2011-02-20"
  },
  "2011-02-21": {
      "equity": 5518.6,
      "debt": 8.078,
      "gold": 22208.50809,
      "pb": 3.49,
      "pe": 21.09,
      "yield": 1.13,
      "date": "2011-02-21"
  },
  "2011-02-22": {
      "equity": 5469.2,
      "debt": 8.063,
      "gold": 22265.66253,
      "pb": 3.46,
      "pe": 20.9,
      "yield": 1.14,
      "date": "2011-02-22"
  },
  "2011-02-23": {
      "equity": 5437.3,
      "debt": 8.039,
      "gold": 22307.94899,
      "pb": 3.44,
      "pe": 20.82,
      "yield": 1.14,
      "date": "2011-02-23"
  },
  "2011-02-24": {
      "equity": 5262.7,
      "debt": 8.075,
      "gold": 22691.02453,
      "pb": 3.33,
      "pe": 20.15,
      "yield": 1.18,
      "date": "2011-02-24"
  },
  "2011-02-25": {
      "equity": 5303.5,
      "debt": 8.079,
      "gold": 22463.05226,
      "pb": 3.36,
      "pe": 20.31,
      "yield": 1.17,
      "date": "2011-02-25"
  },
  "2011-02-26": {
      "equity": 5303.5,
      "debt": 8.079,
      "gold": 22463.05226,
      "pb": 3.36,
      "pe": 20.31,
      "yield": 1.17,
      "date": "2011-02-26"
  },
  "2011-02-27": {
      "equity": 5303.5,
      "debt": 8.079,
      "gold": 22463.05226,
      "pb": 3.36,
      "pe": 20.31,
      "yield": 1.17,
      "date": "2011-02-27"
  },
  "2011-02-28": {
      "equity": 5333.2,
      "debt": 7.992,
      "gold": 22512.88735,
      "pb": 3.38,
      "pe": 20.42,
      "yield": 1.17,
      "date": "2011-02-28"
  },
  "2011-03-01": {
      "equity": 5522.3,
      "debt": 7.994,
      "gold": 22427.83118,
      "pb": 3.5,
      "pe": 21.14,
      "yield": 1.13,
      "date": "2011-03-01"
  },
  "2011-03-02": {
      "equity": 5522.3,
      "debt": 7.994,
      "gold": 22681.52172,
      "pb": 3.5,
      "pe": 21.14,
      "yield": 1.13,
      "date": "2011-03-02"
  },
  "2011-03-03": {
      "equity": 5536.2,
      "debt": 7.936,
      "gold": 22728.19626,
      "pb": 3.51,
      "pe": 21.2,
      "yield": 1.12,
      "date": "2011-03-03"
  },
  "2011-03-04": {
      "equity": 5538.7,
      "debt": 7.942,
      "gold": 22502.0794,
      "pb": 3.51,
      "pe": 21.21,
      "yield": 1.12,
      "date": "2011-03-04"
  },
  "2011-03-05": {
      "equity": 5538.7,
      "debt": 7.942,
      "gold": 22502.0794,
      "pb": 3.51,
      "pe": 21.21,
      "yield": 1.12,
      "date": "2011-03-05"
  },
  "2011-03-06": {
      "equity": 5538.7,
      "debt": 7.942,
      "gold": 22502.0794,
      "pb": 3.51,
      "pe": 21.21,
      "yield": 1.12,
      "date": "2011-03-06"
  },
  "2011-03-07": {
      "equity": 5463.1,
      "debt": 7.98,
      "gold": 22836.5368,
      "pb": 3.46,
      "pe": 20.92,
      "yield": 1.14,
      "date": "2011-03-07"
  },
  "2011-03-08": {
      "equity": 5520.8,
      "debt": 7.964,
      "gold": 22822.97748,
      "pb": 3.5,
      "pe": 21.14,
      "yield": 1.13,
      "date": "2011-03-08"
  },
  "2011-03-09": {
      "equity": 5531,
      "debt": 7.961,
      "gold": 22725.14859,
      "pb": 3.5,
      "pe": 21.18,
      "yield": 1.12,
      "date": "2011-03-09"
  },
  "2011-03-10": {
      "equity": 5494.4,
      "debt": 7.961,
      "gold": 22699.22926,
      "pb": 3.48,
      "pe": 21.04,
      "yield": 1.13,
      "date": "2011-03-10"
  },
  "2011-03-11": {
      "equity": 5445.4,
      "debt": 7.965,
      "gold": 22496.72481,
      "pb": 3.45,
      "pe": 20.85,
      "yield": 1.14,
      "date": "2011-03-11"
  },
  "2011-03-12": {
      "equity": 5445.4,
      "debt": 7.965,
      "gold": 22496.72481,
      "pb": 3.45,
      "pe": 20.85,
      "yield": 1.14,
      "date": "2011-03-12"
  },
  "2011-03-13": {
      "equity": 5445.4,
      "debt": 7.965,
      "gold": 22496.72481,
      "pb": 3.45,
      "pe": 20.85,
      "yield": 1.14,
      "date": "2011-03-13"
  },
  "2011-03-14": {
      "equity": 5531.5,
      "debt": 7.962,
      "gold": 22670.55151,
      "pb": 3.5,
      "pe": 21.18,
      "yield": 1.12,
      "date": "2011-03-14"
  },
  "2011-03-15": {
      "equity": 5449.6,
      "debt": 7.963,
      "gold": 22455.96571,
      "pb": 3.45,
      "pe": 20.87,
      "yield": 1.14,
      "date": "2011-03-15"
  },
  "2011-03-16": {
      "equity": 5511.1,
      "debt": 7.965,
      "gold": 22256.76643,
      "pb": 3.49,
      "pe": 21.1,
      "yield": 1.13,
      "date": "2011-03-16"
  },
  "2011-03-17": {
      "equity": 5446.6,
      "debt": 7.989,
      "gold": 22369.75961,
      "pb": 3.45,
      "pe": 20.86,
      "yield": 1.14,
      "date": "2011-03-17"
  },
  "2011-03-18": {
      "equity": 5373.7,
      "debt": 8.005,
      "gold": 22531.06404,
      "pb": 3.4,
      "pe": 20.58,
      "yield": 1.16,
      "date": "2011-03-18"
  },
  "2011-03-19": {
      "equity": 5373.7,
      "debt": 8.005,
      "gold": 22531.06404,
      "pb": 3.4,
      "pe": 20.58,
      "yield": 1.16,
      "date": "2011-03-19"
  },
  "2011-03-20": {
      "equity": 5373.7,
      "debt": 8.005,
      "gold": 22531.06404,
      "pb": 3.4,
      "pe": 20.58,
      "yield": 1.16,
      "date": "2011-03-20"
  },
  "2011-03-21": {
      "equity": 5364.7,
      "debt": 8.017,
      "gold": 22668.13524,
      "pb": 3.4,
      "pe": 20.54,
      "yield": 1.16,
      "date": "2011-03-21"
  },
  "2011-03-22": {
      "equity": 5413.8,
      "debt": 8.016,
      "gold": 22604.75493,
      "pb": 3.43,
      "pe": 20.73,
      "yield": 1.15,
      "date": "2011-03-22"
  },
  "2011-03-23": {
      "equity": 5480.2,
      "debt": 8.013,
      "gold": 22668.08233,
      "pb": 3.47,
      "pe": 20.99,
      "yield": 1.13,
      "date": "2011-03-23"
  },
  "2011-03-24": {
      "equity": 5522.4,
      "debt": 8.013,
      "gold": 22755.37487,
      "pb": 3.5,
      "pe": 21.15,
      "yield": 1.13,
      "date": "2011-03-24"
  },
  "2011-03-25": {
      "equity": 5654.2,
      "debt": 7.992,
      "gold": 22597.91178,
      "pb": 3.59,
      "pe": 21.46,
      "yield": 1.1,
      "date": "2011-03-25"
  },
  "2011-03-26": {
      "equity": 5654.2,
      "debt": 7.992,
      "gold": 22597.91178,
      "pb": 3.59,
      "pe": 21.46,
      "yield": 1.1,
      "date": "2011-03-26"
  },
  "2011-03-27": {
      "equity": 5654.2,
      "debt": 7.992,
      "gold": 22597.91178,
      "pb": 3.59,
      "pe": 21.46,
      "yield": 1.1,
      "date": "2011-03-27"
  },
  "2011-03-28": {
      "equity": 5687.2,
      "debt": 7.982,
      "gold": 22459.93757,
      "pb": 3.61,
      "pe": 21.59,
      "yield": 1.1,
      "date": "2011-03-28"
  },
  "2011-03-29": {
      "equity": 5736.3,
      "debt": 8.002,
      "gold": 22336.35867,
      "pb": 3.64,
      "pe": 21.77,
      "yield": 1.09,
      "date": "2011-03-29"
  },
  "2011-03-30": {
      "equity": 5787.6,
      "debt": 8.016,
      "gold": 22400.32452,
      "pb": 3.67,
      "pe": 21.97,
      "yield": 1.08,
      "date": "2011-03-30"
  },
  "2011-03-31": {
      "equity": 5833.7,
      "debt": 7.985,
      "gold": 22506.46749,
      "pb": 3.7,
      "pe": 22.14,
      "yield": 1.07,
      "date": "2011-03-31"
  },
  "2011-04-01": {
      "equity": 5826,
      "debt": 7.985,
      "gold": 22561.51608,
      "pb": 3.7,
      "pe": 22.11,
      "yield": 1.07,
      "date": "2011-04-01"
  },
  "2011-04-02": {
      "equity": 5826,
      "debt": 7.985,
      "gold": 22561.51608,
      "pb": 3.7,
      "pe": 22.11,
      "yield": 1.07,
      "date": "2011-04-02"
  },
  "2011-04-03": {
      "equity": 5826,
      "debt": 7.985,
      "gold": 22561.51608,
      "pb": 3.7,
      "pe": 22.11,
      "yield": 1.07,
      "date": "2011-04-03"
  },
  "2011-04-04": {
      "equity": 5908.4,
      "debt": 7.985,
      "gold": 22530.05873,
      "pb": 3.75,
      "pe": 22.43,
      "yield": 1.06,
      "date": "2011-04-04"
  },
  "2011-04-05": {
      "equity": 5910,
      "debt": 7.977,
      "gold": 22475.49339,
      "pb": 3.75,
      "pe": 22.43,
      "yield": 1.06,
      "date": "2011-04-05"
  },
  "2011-04-06": {
      "equity": 5891.7,
      "debt": 7.99,
      "gold": 22700.82012,
      "pb": 3.74,
      "pe": 22.36,
      "yield": 1.06,
      "date": "2011-04-06"
  },
  "2011-04-07": {
      "equity": 5885.7,
      "debt": 7.974,
      "gold": 22711.01078,
      "pb": 3.73,
      "pe": 22.34,
      "yield": 1.06,
      "date": "2011-04-07"
  },
  "2011-04-08": {
      "equity": 5842,
      "debt": 7.965,
      "gold": 22861.88116,
      "pb": 3.71,
      "pe": 22.18,
      "yield": 1.07,
      "date": "2011-04-08"
  },
  "2011-04-09": {
      "equity": 5842,
      "debt": 7.965,
      "gold": 22861.88116,
      "pb": 3.71,
      "pe": 22.18,
      "yield": 1.07,
      "date": "2011-04-09"
  },
  "2011-04-10": {
      "equity": 5842,
      "debt": 7.965,
      "gold": 22861.88116,
      "pb": 3.71,
      "pe": 22.18,
      "yield": 1.07,
      "date": "2011-04-10"
  },
  "2011-04-11": {
      "equity": 5785.7,
      "debt": 8.019,
      "gold": 23009.09716,
      "pb": 3.67,
      "pe": 21.96,
      "yield": 1.08,
      "date": "2011-04-11"
  },
  "2011-04-12": {
      "equity": 5785.7,
      "debt": 8.019,
      "gold": 22879.92028,
      "pb": 3.67,
      "pe": 21.96,
      "yield": 1.08,
      "date": "2011-04-12"
  },
  "2011-04-13": {
      "equity": 5911.5,
      "debt": 8.024,
      "gold": 22888.68939,
      "pb": 3.75,
      "pe": 22.43,
      "yield": 1.06,
      "date": "2011-04-13"
  },
  "2011-04-14": {
      "equity": 5911.5,
      "debt": 8.024,
      "gold": 22880.84093,
      "pb": 3.75,
      "pe": 22.43,
      "yield": 1.06,
      "date": "2011-04-14"
  },
  "2011-04-15": {
      "equity": 5824.5,
      "debt": 8.064,
      "gold": 23026.72358,
      "pb": 3.69,
      "pe": 22.02,
      "yield": 1.07,
      "date": "2011-04-15"
  },
  "2011-04-16": {
      "equity": 5824.5,
      "debt": 8.064,
      "gold": 23026.72358,
      "pb": 3.69,
      "pe": 22.02,
      "yield": 1.07,
      "date": "2011-04-16"
  },
  "2011-04-17": {
      "equity": 5824.5,
      "debt": 8.064,
      "gold": 23026.72358,
      "pb": 3.69,
      "pe": 22.02,
      "yield": 1.07,
      "date": "2011-04-17"
  },
  "2011-04-18": {
      "equity": 5729.1,
      "debt": 8.082,
      "gold": 23275.9061,
      "pb": 3.63,
      "pe": 21.66,
      "yield": 1.09,
      "date": "2011-04-18"
  },
  "2011-04-19": {
      "equity": 5740.7,
      "debt": 8.08,
      "gold": 23458.99575,
      "pb": 3.64,
      "pe": 21.65,
      "yield": 1.09,
      "date": "2011-04-19"
  },
  "2011-04-20": {
      "equity": 5851.6,
      "debt": 8.092,
      "gold": 23533.62493,
      "pb": 3.71,
      "pe": 22.06,
      "yield": 1.07,
      "date": "2011-04-20"
  },
  "2011-04-21": {
      "equity": 5884.7,
      "debt": 8.11,
      "gold": 23584.83218,
      "pb": 3.73,
      "pe": 22.18,
      "yield": 1.06,
      "date": "2011-04-21"
  },
  "2011-04-22": {
      "equity": 5884.7,
      "debt": 8.11,
      "gold": 23584.83218,
      "pb": 3.73,
      "pe": 22.18,
      "yield": 1.06,
      "date": "2011-04-22"
  },
  "2011-04-23": {
      "equity": 5884.7,
      "debt": 8.11,
      "gold": 23584.83218,
      "pb": 3.73,
      "pe": 22.18,
      "yield": 1.06,
      "date": "2011-04-23"
  },
  "2011-04-24": {
      "equity": 5884.7,
      "debt": 8.11,
      "gold": 23584.83218,
      "pb": 3.73,
      "pe": 22.18,
      "yield": 1.06,
      "date": "2011-04-24"
  },
  "2011-04-25": {
      "equity": 5874.5,
      "debt": 8.134,
      "gold": 23584.83218,
      "pb": 3.73,
      "pe": 21.94,
      "yield": 1.06,
      "date": "2011-04-25"
  },
  "2011-04-26": {
      "equity": 5868.4,
      "debt": 8.091,
      "gold": 23634.49091,
      "pb": 3.72,
      "pe": 21.91,
      "yield": 1.06,
      "date": "2011-04-26"
  },
  "2011-04-27": {
      "equity": 5833.9,
      "debt": 8.091,
      "gold": 23639.04831,
      "pb": 3.7,
      "pe": 21.79,
      "yield": 1.07,
      "date": "2011-04-27"
  },
  "2011-04-28": {
      "equity": 5785.4,
      "debt": 8.111,
      "gold": 23994.19037,
      "pb": 3.67,
      "pe": 21.61,
      "yield": 1.08,
      "date": "2011-04-28"
  },
  "2011-04-29": {
      "equity": 5749.5,
      "debt": 8.135,
      "gold": 23994.19037,
      "pb": 3.65,
      "pe": 21.36,
      "yield": 1.09,
      "date": "2011-04-29"
  },
  "2011-04-30": {
      "equity": 5749.5,
      "debt": 8.135,
      "gold": 23994.19037,
      "pb": 3.65,
      "pe": 21.36,
      "yield": 1.09,
      "date": "2011-04-30"
  },
  "2011-05-01": {
      "equity": 5749.5,
      "debt": 8.135,
      "gold": 23994.19037,
      "pb": 3.65,
      "pe": 21.36,
      "yield": 1.09,
      "date": "2011-05-01"
  },
  "2011-05-02": {
      "equity": 5701.3,
      "debt": 8.139,
      "gold": 23994.19037,
      "pb": 3.62,
      "pe": 21.19,
      "yield": 1.09,
      "date": "2011-05-02"
  },
  "2011-05-03": {
      "equity": 5565.2,
      "debt": 8.23,
      "gold": 24284.841,
      "pb": 3.53,
      "pe": 20.68,
      "yield": 1.12,
      "date": "2011-05-03"
  },
  "2011-05-04": {
      "equity": 5537.1,
      "debt": 8.294,
      "gold": 24094.22388,
      "pb": 3.51,
      "pe": 20.58,
      "yield": 1.13,
      "date": "2011-05-04"
  },
  "2011-05-05": {
      "equity": 5459.8,
      "debt": 8.268,
      "gold": 23911.89263,
      "pb": 3.43,
      "pe": 20.32,
      "yield": 1.23,
      "date": "2011-05-05"
  },
  "2011-05-06": {
      "equity": 5551.4,
      "debt": 8.183,
      "gold": 23507.91372,
      "pb": 3.49,
      "pe": 20.67,
      "yield": 1.21,
      "date": "2011-05-06"
  },
  "2011-05-07": {
      "equity": 5551.4,
      "debt": 8.183,
      "gold": 23507.91372,
      "pb": 3.49,
      "pe": 20.67,
      "yield": 1.21,
      "date": "2011-05-07"
  },
  "2011-05-08": {
      "equity": 5551.4,
      "debt": 8.183,
      "gold": 23507.91372,
      "pb": 3.49,
      "pe": 20.67,
      "yield": 1.21,
      "date": "2011-05-08"
  },
  "2011-05-09": {
      "equity": 5551.1,
      "debt": 8.262,
      "gold": 23740.66562,
      "pb": 3.49,
      "pe": 20.66,
      "yield": 1.21,
      "date": "2011-05-09"
  },
  "2011-05-10": {
      "equity": 5541.2,
      "debt": 8.284,
      "gold": 23952.63409,
      "pb": 3.48,
      "pe": 20.61,
      "yield": 1.22,
      "date": "2011-05-10"
  },
  "2011-05-11": {
      "equity": 5565,
      "debt": 8.292,
      "gold": 24034.82601,
      "pb": 3.5,
      "pe": 20.71,
      "yield": 1.21,
      "date": "2011-05-11"
  },
  "2011-05-12": {
      "equity": 5486.1,
      "debt": 8.212,
      "gold": 23622.12032,
      "pb": 3.45,
      "pe": 20.4,
      "yield": 1.23,
      "date": "2011-05-12"
  },
  "2011-05-13": {
      "equity": 5544.7,
      "debt": 8.276,
      "gold": 23913.92794,
      "pb": 3.48,
      "pe": 20.6,
      "yield": 1.22,
      "date": "2011-05-13"
  },
  "2011-05-14": {
      "equity": 5544.7,
      "debt": 8.276,
      "gold": 23913.92794,
      "pb": 3.48,
      "pe": 20.6,
      "yield": 1.22,
      "date": "2011-05-14"
  },
  "2011-05-15": {
      "equity": 5544.7,
      "debt": 8.276,
      "gold": 23913.92794,
      "pb": 3.48,
      "pe": 20.6,
      "yield": 1.22,
      "date": "2011-05-15"
  },
  "2011-05-16": {
      "equity": 5499,
      "debt": 8.283,
      "gold": 23814.95264,
      "pb": 3.45,
      "pe": 20.39,
      "yield": 1.23,
      "date": "2011-05-16"
  },
  "2011-05-17": {
      "equity": 5438.9,
      "debt": 8.283,
      "gold": 23822.91751,
      "pb": 3.41,
      "pe": 20.16,
      "yield": 1.24,
      "date": "2011-05-17"
  },
  "2011-05-18": {
      "equity": 5420.6,
      "debt": 8.292,
      "gold": 23699.98413,
      "pb": 3.4,
      "pe": 20.18,
      "yield": 1.25,
      "date": "2011-05-18"
  },
  "2011-05-19": {
      "equity": 5428.1,
      "debt": 8.321,
      "gold": 23620.86457,
      "pb": 3.41,
      "pe": 20.2,
      "yield": 1.24,
      "date": "2011-05-19"
  },
  "2011-05-20": {
      "equity": 5486.3,
      "debt": 8.354,
      "gold": 23860.21976,
      "pb": 3.44,
      "pe": 20.34,
      "yield": 1.23,
      "date": "2011-05-20"
  },
  "2011-05-21": {
      "equity": 5486.3,
      "debt": 8.354,
      "gold": 23860.21976,
      "pb": 3.44,
      "pe": 20.34,
      "yield": 1.23,
      "date": "2011-05-21"
  },
  "2011-05-22": {
      "equity": 5486.3,
      "debt": 8.354,
      "gold": 23860.21976,
      "pb": 3.44,
      "pe": 20.34,
      "yield": 1.23,
      "date": "2011-05-22"
  },
  "2011-05-23": {
      "equity": 5386.5,
      "debt": 8.344,
      "gold": 24071.24288,
      "pb": 3.38,
      "pe": 19.93,
      "yield": 1.25,
      "date": "2011-05-23"
  },
  "2011-05-24": {
      "equity": 5394.8,
      "debt": 8.374,
      "gold": 24249.28129,
      "pb": 3.38,
      "pe": 19.9,
      "yield": 1.25,
      "date": "2011-05-24"
  },
  "2011-05-25": {
      "equity": 5348.9,
      "debt": 8.374,
      "gold": 24416.27189,
      "pb": 3.36,
      "pe": 19.72,
      "yield": 1.26,
      "date": "2011-05-25"
  },
  "2011-05-26": {
      "equity": 5412.3,
      "debt": 8.397,
      "gold": 24314.91208,
      "pb": 3.4,
      "pe": 19.95,
      "yield": 1.25,
      "date": "2011-05-26"
  },
  "2011-05-27": {
      "equity": 5476.1,
      "debt": 8.457,
      "gold": 24295.53608,
      "pb": 3.44,
      "pe": 20.26,
      "yield": 1.23,
      "date": "2011-05-27"
  },
  "2011-05-28": {
      "equity": 5476.1,
      "debt": 8.457,
      "gold": 24295.53608,
      "pb": 3.44,
      "pe": 20.26,
      "yield": 1.23,
      "date": "2011-05-28"
  },
  "2011-05-29": {
      "equity": 5476.1,
      "debt": 8.457,
      "gold": 24295.53608,
      "pb": 3.44,
      "pe": 20.26,
      "yield": 1.23,
      "date": "2011-05-29"
  },
  "2011-05-30": {
      "equity": 5473.1,
      "debt": 8.432,
      "gold": 24295.53608,
      "pb": 3.39,
      "pe": 20.25,
      "yield": 1.24,
      "date": "2011-05-30"
  },
  "2011-05-31": {
      "equity": 5560.1,
      "debt": 8.411,
      "gold": 24428.43084,
      "pb": 3.45,
      "pe": 20.53,
      "yield": 1.22,
      "date": "2011-05-31"
  },
  "2011-06-01": {
      "equity": 5592,
      "debt": 8.328,
      "gold": 24235.37981,
      "pb": 3.47,
      "pe": 20.65,
      "yield": 1.22,
      "date": "2011-06-01"
  },
  "2011-06-02": {
      "equity": 5550.3,
      "debt": 8.279,
      "gold": 24361.67128,
      "pb": 3.44,
      "pe": 20.49,
      "yield": 1.23,
      "date": "2011-06-02"
  },
  "2011-06-03": {
      "equity": 5516.7,
      "debt": 8.268,
      "gold": 24199.4074,
      "pb": 3.42,
      "pe": 20.37,
      "yield": 1.23,
      "date": "2011-06-03"
  },
  "2011-06-04": {
      "equity": 5516.7,
      "debt": 8.268,
      "gold": 24199.4074,
      "pb": 3.42,
      "pe": 20.37,
      "yield": 1.23,
      "date": "2011-06-04"
  },
  "2011-06-05": {
      "equity": 5516.7,
      "debt": 8.268,
      "gold": 24199.4074,
      "pb": 3.42,
      "pe": 20.37,
      "yield": 1.23,
      "date": "2011-06-05"
  },
  "2011-06-06": {
      "equity": 5532,
      "debt": 8.261,
      "gold": 24357.92166,
      "pb": 3.43,
      "pe": 20.43,
      "yield": 1.23,
      "date": "2011-06-06"
  },
  "2011-06-07": {
      "equity": 5556.1,
      "debt": 8.284,
      "gold": 24403.43216,
      "pb": 3.44,
      "pe": 20.52,
      "yield": 1.22,
      "date": "2011-06-07"
  },
  "2011-06-08": {
      "equity": 5526.8,
      "debt": 8.248,
      "gold": 24212.31062,
      "pb": 3.43,
      "pe": 20.41,
      "yield": 1.28,
      "date": "2011-06-08"
  },
  "2011-06-09": {
      "equity": 5521,
      "debt": 8.269,
      "gold": 24206.2435,
      "pb": 3.42,
      "pe": 20.39,
      "yield": 1.28,
      "date": "2011-06-09"
  },
  "2011-06-10": {
      "equity": 5485.8,
      "debt": 8.235,
      "gold": 24305.83255,
      "pb": 3.4,
      "pe": 20.26,
      "yield": 1.29,
      "date": "2011-06-10"
  },
  "2011-06-11": {
      "equity": 5485.8,
      "debt": 8.235,
      "gold": 24305.83255,
      "pb": 3.4,
      "pe": 20.26,
      "yield": 1.29,
      "date": "2011-06-11"
  },
  "2011-06-12": {
      "equity": 5485.8,
      "debt": 8.235,
      "gold": 24305.83255,
      "pb": 3.4,
      "pe": 20.26,
      "yield": 1.29,
      "date": "2011-06-12"
  },
  "2011-06-13": {
      "equity": 5482.8,
      "debt": 8.246,
      "gold": 24129.40969,
      "pb": 3.4,
      "pe": 20.25,
      "yield": 1.29,
      "date": "2011-06-13"
  },
  "2011-06-14": {
      "equity": 5500.5,
      "debt": 8.335,
      "gold": 23970.88485,
      "pb": 3.41,
      "pe": 20.31,
      "yield": 1.29,
      "date": "2011-06-14"
  },
  "2011-06-15": {
      "equity": 5447.5,
      "debt": 8.397,
      "gold": 23963.2057,
      "pb": 3.38,
      "pe": 20.12,
      "yield": 1.3,
      "date": "2011-06-15"
  },
  "2011-06-16": {
      "equity": 5396.7,
      "debt": 8.3,
      "gold": 24155.6747,
      "pb": 3.35,
      "pe": 19.93,
      "yield": 1.31,
      "date": "2011-06-16"
  },
  "2011-06-17": {
      "equity": 5366.4,
      "debt": 8.275,
      "gold": 24155.28316,
      "pb": 3.33,
      "pe": 19.82,
      "yield": 1.32,
      "date": "2011-06-17"
  },
  "2011-06-18": {
      "equity": 5366.4,
      "debt": 8.275,
      "gold": 24155.28316,
      "pb": 3.33,
      "pe": 19.82,
      "yield": 1.32,
      "date": "2011-06-18"
  },
  "2011-06-19": {
      "equity": 5366.4,
      "debt": 8.275,
      "gold": 24155.28316,
      "pb": 3.33,
      "pe": 19.82,
      "yield": 1.32,
      "date": "2011-06-19"
  },
  "2011-06-20": {
      "equity": 5257.9,
      "debt": 8.196,
      "gold": 24401.32277,
      "pb": 3.26,
      "pe": 19.42,
      "yield": 1.35,
      "date": "2011-06-20"
  },
  "2011-06-21": {
      "equity": 5275.8,
      "debt": 8.225,
      "gold": 24409.49223,
      "pb": 3.27,
      "pe": 19.48,
      "yield": 1.34,
      "date": "2011-06-21"
  },
  "2011-06-22": {
      "equity": 5278.3,
      "debt": 8.251,
      "gold": 24482.85508,
      "pb": 3.27,
      "pe": 19.46,
      "yield": 1.34,
      "date": "2011-06-22"
  },
  "2011-06-23": {
      "equity": 5320,
      "debt": 8.268,
      "gold": 24441.49985,
      "pb": 3.3,
      "pe": 19.61,
      "yield": 1.33,
      "date": "2011-06-23"
  },
  "2011-06-24": {
      "equity": 5471.2,
      "debt": 8.247,
      "gold": 24140.5986,
      "pb": 3.39,
      "pe": 20.17,
      "yield": 1.3,
      "date": "2011-06-24"
  },
  "2011-06-25": {
      "equity": 5471.2,
      "debt": 8.247,
      "gold": 24140.5986,
      "pb": 3.39,
      "pe": 20.17,
      "yield": 1.3,
      "date": "2011-06-25"
  },
  "2011-06-26": {
      "equity": 5471.2,
      "debt": 8.247,
      "gold": 24140.5986,
      "pb": 3.39,
      "pe": 20.17,
      "yield": 1.3,
      "date": "2011-06-26"
  },
  "2011-06-27": {
      "equity": 5526.6,
      "debt": 8.262,
      "gold": 23845.67276,
      "pb": 3.43,
      "pe": 20.38,
      "yield": 1.28,
      "date": "2011-06-27"
  },
  "2011-06-28": {
      "equity": 5545.3,
      "debt": 8.288,
      "gold": 23870.82665,
      "pb": 3.44,
      "pe": 20.44,
      "yield": 1.28,
      "date": "2011-06-28"
  },
  "2011-06-29": {
      "equity": 5600.4,
      "debt": 8.319,
      "gold": 23834.79779,
      "pb": 3.47,
      "pe": 20.65,
      "yield": 1.27,
      "date": "2011-06-29"
  },
  "2011-06-30": {
      "equity": 5647.4,
      "debt": 8.326,
      "gold": 23702.88012,
      "pb": 3.5,
      "pe": 20.82,
      "yield": 1.25,
      "date": "2011-06-30"
  },
  "2011-07-01": {
      "equity": 5627.2,
      "debt": 8.354,
      "gold": 23500.03704,
      "pb": 3.49,
      "pe": 20.75,
      "yield": 1.26,
      "date": "2011-07-01"
  },
  "2011-07-02": {
      "equity": 5627.2,
      "debt": 8.354,
      "gold": 23500.03704,
      "pb": 3.49,
      "pe": 20.75,
      "yield": 1.26,
      "date": "2011-07-02"
  },
  "2011-07-03": {
      "equity": 5627.2,
      "debt": 8.354,
      "gold": 23500.03704,
      "pb": 3.49,
      "pe": 20.75,
      "yield": 1.26,
      "date": "2011-07-03"
  },
  "2011-07-04": {
      "equity": 5650.5,
      "debt": 8.357,
      "gold": 23433.90889,
      "pb": 3.5,
      "pe": 20.83,
      "yield": 1.25,
      "date": "2011-07-04"
  },
  "2011-07-05": {
      "equity": 5632.1,
      "debt": 8.343,
      "gold": 23449.11198,
      "pb": 3.49,
      "pe": 20.76,
      "yield": 1.26,
      "date": "2011-07-05"
  },
  "2011-07-06": {
      "equity": 5625.4,
      "debt": 8.333,
      "gold": 23788.0527,
      "pb": 3.49,
      "pe": 20.74,
      "yield": 1.26,
      "date": "2011-07-06"
  },
  "2011-07-07": {
      "equity": 5728.9,
      "debt": 8.359,
      "gold": 23922.43955,
      "pb": 3.55,
      "pe": 21.12,
      "yield": 1.24,
      "date": "2011-07-07"
  },
  "2011-07-08": {
      "equity": 5660.6,
      "debt": 8.355,
      "gold": 23860.65715,
      "pb": 3.51,
      "pe": 20.87,
      "yield": 1.25,
      "date": "2011-07-08"
  },
  "2011-07-09": {
      "equity": 5660.6,
      "debt": 8.355,
      "gold": 23860.65715,
      "pb": 3.51,
      "pe": 20.87,
      "yield": 1.25,
      "date": "2011-07-09"
  },
  "2011-07-10": {
      "equity": 5660.6,
      "debt": 8.355,
      "gold": 23860.65715,
      "pb": 3.51,
      "pe": 20.87,
      "yield": 1.25,
      "date": "2011-07-10"
  },
  "2011-07-11": {
      "equity": 5616.1,
      "debt": 8.321,
      "gold": 24220.03915,
      "pb": 3.48,
      "pe": 20.67,
      "yield": 1.26,
      "date": "2011-07-11"
  },
  "2011-07-12": {
      "equity": 5526.1,
      "debt": 8.28,
      "gold": 24361.03635,
      "pb": 3.43,
      "pe": 20.29,
      "yield": 1.28,
      "date": "2011-07-12"
  },
  "2011-07-13": {
      "equity": 5585.4,
      "debt": 8.305,
      "gold": 24680.19189,
      "pb": 3.46,
      "pe": 20.51,
      "yield": 1.27,
      "date": "2011-07-13"
  },
  "2011-07-14": {
      "equity": 5599.8,
      "debt": 8.247,
      "gold": 24995.95055,
      "pb": 3.47,
      "pe": 20.55,
      "yield": 1.27,
      "date": "2011-07-14"
  },
  "2011-07-15": {
      "equity": 5581.1,
      "debt": 8.278,
      "gold": 24785.95037,
      "pb": 3.46,
      "pe": 20.45,
      "yield": 1.27,
      "date": "2011-07-15"
  },
  "2011-07-16": {
      "equity": 5581.1,
      "debt": 8.278,
      "gold": 24785.95037,
      "pb": 3.46,
      "pe": 20.45,
      "yield": 1.27,
      "date": "2011-07-16"
  },
  "2011-07-17": {
      "equity": 5581.1,
      "debt": 8.278,
      "gold": 24785.95037,
      "pb": 3.46,
      "pe": 20.45,
      "yield": 1.27,
      "date": "2011-07-17"
  },
  "2011-07-18": {
      "equity": 5567,
      "debt": 8.25,
      "gold": 25129.89294,
      "pb": 3.45,
      "pe": 20.4,
      "yield": 1.27,
      "date": "2011-07-18"
  },
  "2011-07-19": {
      "equity": 5613.5,
      "debt": 8.259,
      "gold": 25146.47525,
      "pb": 3.48,
      "pe": 20.57,
      "yield": 1.26,
      "date": "2011-07-19"
  },
  "2011-07-20": {
      "equity": 5567,
      "debt": 8.28,
      "gold": 24839.91252,
      "pb": 3.45,
      "pe": 20.34,
      "yield": 1.27,
      "date": "2011-07-20"
  },
  "2011-07-21": {
      "equity": 5541.6,
      "debt": 8.274,
      "gold": 25072.1212,
      "pb": 3.44,
      "pe": 20.25,
      "yield": 1.28,
      "date": "2011-07-21"
  },
  "2011-07-22": {
      "equity": 5633.9,
      "debt": 8.314,
      "gold": 24842.69564,
      "pb": 3.5,
      "pe": 20.57,
      "yield": 1.26,
      "date": "2011-07-22"
  },
  "2011-07-23": {
      "equity": 5633.9,
      "debt": 8.314,
      "gold": 24842.69564,
      "pb": 3.5,
      "pe": 20.57,
      "yield": 1.26,
      "date": "2011-07-23"
  },
  "2011-07-24": {
      "equity": 5633.9,
      "debt": 8.314,
      "gold": 24842.69564,
      "pb": 3.5,
      "pe": 20.57,
      "yield": 1.26,
      "date": "2011-07-24"
  },
  "2011-07-25": {
      "equity": 5680.3,
      "debt": 8.294,
      "gold": 25351.23724,
      "pb": 3.52,
      "pe": 20.71,
      "yield": 1.25,
      "date": "2011-07-25"
  },
  "2011-07-26": {
      "equity": 5574.8,
      "debt": 8.441,
      "gold": 25093.15861,
      "pb": 3.46,
      "pe": 20.22,
      "yield": 1.27,
      "date": "2011-07-26"
  },
  "2011-07-27": {
      "equity": 5546.8,
      "debt": 8.451,
      "gold": 25207.42518,
      "pb": 3.44,
      "pe": 20.09,
      "yield": 1.28,
      "date": "2011-07-27"
  },
  "2011-07-28": {
      "equity": 5487.7,
      "debt": 8.465,
      "gold": 25152.99741,
      "pb": 3.4,
      "pe": 19.86,
      "yield": 1.29,
      "date": "2011-07-28"
  },
  "2011-07-29": {
      "equity": 5482,
      "debt": 8.454,
      "gold": 25157.29731,
      "pb": 3.4,
      "pe": 19.76,
      "yield": 1.29,
      "date": "2011-07-29"
  },
  "2011-07-30": {
      "equity": 5482,
      "debt": 8.454,
      "gold": 25157.29731,
      "pb": 3.4,
      "pe": 19.76,
      "yield": 1.29,
      "date": "2011-07-30"
  },
  "2011-07-31": {
      "equity": 5482,
      "debt": 8.454,
      "gold": 25157.29731,
      "pb": 3.4,
      "pe": 19.76,
      "yield": 1.29,
      "date": "2011-07-31"
  },
  "2011-08-01": {
      "equity": 5516.8,
      "debt": 8.461,
      "gold": 25070.8725,
      "pb": 3.42,
      "pe": 19.81,
      "yield": 1.28,
      "date": "2011-08-01"
  },
  "2011-08-02": {
      "equity": 5456.5,
      "debt": 8.436,
      "gold": 25364.34858,
      "pb": 3.39,
      "pe": 19.6,
      "yield": 1.3,
      "date": "2011-08-02"
  },
  "2011-08-03": {
      "equity": 5404.8,
      "debt": 8.423,
      "gold": 26068.74901,
      "pb": 3.33,
      "pe": 19.42,
      "yield": 1.27,
      "date": "2011-08-03"
  },
  "2011-08-04": {
      "equity": 5331.8,
      "debt": 8.403,
      "gold": 26151.49474,
      "pb": 3.29,
      "pe": 19.19,
      "yield": 1.29,
      "date": "2011-08-04"
  },
  "2011-08-05": {
      "equity": 5211.2,
      "debt": 8.316,
      "gold": 26279.27477,
      "pb": 3.21,
      "pe": 18.76,
      "yield": 1.32,
      "date": "2011-08-05"
  },
  "2011-08-06": {
      "equity": 5211.2,
      "debt": 8.316,
      "gold": 26279.27477,
      "pb": 3.21,
      "pe": 18.76,
      "yield": 1.32,
      "date": "2011-08-06"
  },
  "2011-08-07": {
      "equity": 5211.2,
      "debt": 8.316,
      "gold": 26279.27477,
      "pb": 3.21,
      "pe": 18.76,
      "yield": 1.32,
      "date": "2011-08-07"
  },
  "2011-08-08": {
      "equity": 5118.5,
      "debt": 8.261,
      "gold": 27121.27551,
      "pb": 3.16,
      "pe": 18.44,
      "yield": 1.34,
      "date": "2011-08-08"
  },
  "2011-08-09": {
      "equity": 5072.8,
      "debt": 8.227,
      "gold": 28226.84703,
      "pb": 3.13,
      "pe": 18.24,
      "yield": 1.35,
      "date": "2011-08-09"
  },
  "2011-08-10": {
      "equity": 5161,
      "debt": 8.192,
      "gold": 27998.63137,
      "pb": 3.19,
      "pe": 18.56,
      "yield": 1.33,
      "date": "2011-08-10"
  },
  "2011-08-11": {
      "equity": 5138.3,
      "debt": 8.209,
      "gold": 28608.00367,
      "pb": 3.17,
      "pe": 18.47,
      "yield": 1.34,
      "date": "2011-08-11"
  },
  "2011-08-12": {
      "equity": 5072.9,
      "debt": 8.3,
      "gold": 28071.20761,
      "pb": 3.13,
      "pe": 18.24,
      "yield": 1.35,
      "date": "2011-08-12"
  },
  "2011-08-13": {
      "equity": 5072.9,
      "debt": 8.3,
      "gold": 28071.20761,
      "pb": 3.13,
      "pe": 18.24,
      "yield": 1.35,
      "date": "2011-08-13"
  },
  "2011-08-14": {
      "equity": 5072.9,
      "debt": 8.3,
      "gold": 28071.20761,
      "pb": 3.13,
      "pe": 18.24,
      "yield": 1.35,
      "date": "2011-08-14"
  },
  "2011-08-15": {
      "equity": 5072.9,
      "debt": 8.3,
      "gold": 27799.29452,
      "pb": 3.13,
      "pe": 18.24,
      "yield": 1.35,
      "date": "2011-08-15"
  },
  "2011-08-16": {
      "equity": 5035.8,
      "debt": 8.29,
      "gold": 28483.32775,
      "pb": 3.11,
      "pe": 18.11,
      "yield": 1.36,
      "date": "2011-08-16"
  },
  "2011-08-17": {
      "equity": 5056.6,
      "debt": 8.322,
      "gold": 28700.95063,
      "pb": 3.12,
      "pe": 18.25,
      "yield": 1.36,
      "date": "2011-08-17"
  },
  "2011-08-18": {
      "equity": 4944.1,
      "debt": 8.257,
      "gold": 28959.37494,
      "pb": 2.92,
      "pe": 17.85,
      "yield": 1.53,
      "date": "2011-08-18"
  },
  "2011-08-19": {
      "equity": 4845.6,
      "debt": 8.257,
      "gold": 30048.67811,
      "pb": 2.86,
      "pe": 17.49,
      "yield": 1.57,
      "date": "2011-08-19"
  },
  "2011-08-20": {
      "equity": 4845.6,
      "debt": 8.257,
      "gold": 30048.67811,
      "pb": 2.86,
      "pe": 17.49,
      "yield": 1.57,
      "date": "2011-08-20"
  },
  "2011-08-21": {
      "equity": 4845.6,
      "debt": 8.257,
      "gold": 30048.67811,
      "pb": 2.86,
      "pe": 17.49,
      "yield": 1.57,
      "date": "2011-08-21"
  },
  "2011-08-22": {
      "equity": 4898.8,
      "debt": 8.269,
      "gold": 30246.54756,
      "pb": 2.89,
      "pe": 17.68,
      "yield": 1.55,
      "date": "2011-08-22"
  },
  "2011-08-23": {
      "equity": 4948.9,
      "debt": 8.265,
      "gold": 30360.87409,
      "pb": 2.92,
      "pe": 17.87,
      "yield": 1.53,
      "date": "2011-08-23"
  },
  "2011-08-24": {
      "equity": 4888.9,
      "debt": 8.218,
      "gold": 30021.42895,
      "pb": 2.89,
      "pe": 17.65,
      "yield": 1.55,
      "date": "2011-08-24"
  },
  "2011-08-25": {
      "equity": 4839.6,
      "debt": 8.245,
      "gold": 27879.23597,
      "pb": 2.86,
      "pe": 17.47,
      "yield": 1.57,
      "date": "2011-08-25"
  },
  "2011-08-26": {
      "equity": 4747.8,
      "debt": 8.303,
      "gold": 29093.63128,
      "pb": 2.8,
      "pe": 17.14,
      "yield": 1.6,
      "date": "2011-08-26"
  },
  "2011-08-27": {
      "equity": 4747.8,
      "debt": 8.303,
      "gold": 29093.63128,
      "pb": 2.8,
      "pe": 17.14,
      "yield": 1.6,
      "date": "2011-08-27"
  },
  "2011-08-28": {
      "equity": 4747.8,
      "debt": 8.303,
      "gold": 29093.63128,
      "pb": 2.8,
      "pe": 17.14,
      "yield": 1.6,
      "date": "2011-08-28"
  },
  "2011-08-29": {
      "equity": 4919.6,
      "debt": 8.326,
      "gold": 29093.63128,
      "pb": 2.9,
      "pe": 17.76,
      "yield": 1.54,
      "date": "2011-08-29"
  },
  "2011-08-30": {
      "equity": 5001,
      "debt": 8.319,
      "gold": 29116.10787,
      "pb": 2.95,
      "pe": 18.05,
      "yield": 1.52,
      "date": "2011-08-30"
  },
  "2011-08-31": {
      "equity": 5001,
      "debt": 8.319,
      "gold": 29685.10203,
      "pb": 2.95,
      "pe": 18.05,
      "yield": 1.52,
      "date": "2011-08-31"
  },
  "2011-09-01": {
      "equity": 5001,
      "debt": 8.319,
      "gold": 29514.40413,
      "pb": 2.95,
      "pe": 18.05,
      "yield": 1.52,
      "date": "2011-09-01"
  },
  "2011-09-02": {
      "equity": 5040,
      "debt": 8.349,
      "gold": 29949.00439,
      "pb": 2.98,
      "pe": 18.19,
      "yield": 1.51,
      "date": "2011-09-02"
  },
  "2011-09-03": {
      "equity": 5040,
      "debt": 8.349,
      "gold": 29949.00439,
      "pb": 2.98,
      "pe": 18.19,
      "yield": 1.51,
      "date": "2011-09-03"
  },
  "2011-09-04": {
      "equity": 5040,
      "debt": 8.349,
      "gold": 29949.00439,
      "pb": 2.98,
      "pe": 18.19,
      "yield": 1.51,
      "date": "2011-09-04"
  },
  "2011-09-05": {
      "equity": 5017.2,
      "debt": 8.288,
      "gold": 30771.33988,
      "pb": 2.96,
      "pe": 18.11,
      "yield": 1.51,
      "date": "2011-09-05"
  },
  "2011-09-06": {
      "equity": 5064.3,
      "debt": 8.304,
      "gold": 30760.14392,
      "pb": 2.99,
      "pe": 18.28,
      "yield": 1.5,
      "date": "2011-09-06"
  },
  "2011-09-07": {
      "equity": 5124.6,
      "debt": 8.31,
      "gold": 30031.3868,
      "pb": 3.03,
      "pe": 18.5,
      "yield": 1.48,
      "date": "2011-09-07"
  },
  "2011-09-08": {
      "equity": 5153.2,
      "debt": 8.296,
      "gold": 29770.63793,
      "pb": 3.04,
      "pe": 18.6,
      "yield": 1.47,
      "date": "2011-09-08"
  },
  "2011-09-09": {
      "equity": 5059.4,
      "debt": 8.314,
      "gold": 30869.75784,
      "pb": 2.99,
      "pe": 18.26,
      "yield": 1.5,
      "date": "2011-09-09"
  },
  "2011-09-10": {
      "equity": 5059.4,
      "debt": 8.314,
      "gold": 30869.75784,
      "pb": 2.99,
      "pe": 18.26,
      "yield": 1.5,
      "date": "2011-09-10"
  },
  "2011-09-11": {
      "equity": 5059.4,
      "debt": 8.314,
      "gold": 30869.75784,
      "pb": 2.99,
      "pe": 18.26,
      "yield": 1.5,
      "date": "2011-09-11"
  },
  "2011-09-12": {
      "equity": 4946.8,
      "debt": 8.292,
      "gold": 30704.20642,
      "pb": 2.92,
      "pe": 17.86,
      "yield": 1.53,
      "date": "2011-09-12"
  },
  "2011-09-13": {
      "equity": 4940.9,
      "debt": 8.325,
      "gold": 30320.31253,
      "pb": 2.92,
      "pe": 17.84,
      "yield": 1.54,
      "date": "2011-09-13"
  },
  "2011-09-14": {
      "equity": 5012.5,
      "debt": 8.347,
      "gold": 30741.93548,
      "pb": 2.96,
      "pe": 18.1,
      "yield": 1.51,
      "date": "2011-09-14"
  },
  "2011-09-15": {
      "equity": 5075.7,
      "debt": 8.329,
      "gold": 30294.8306,
      "pb": 3,
      "pe": 18.33,
      "yield": 1.49,
      "date": "2011-09-15"
  },
  "2011-09-16": {
      "equity": 5084.2,
      "debt": 8.362,
      "gold": 29636.99183,
      "pb": 3,
      "pe": 18.36,
      "yield": 1.49,
      "date": "2011-09-16"
  },
  "2011-09-17": {
      "equity": 5084.2,
      "debt": 8.362,
      "gold": 29636.99183,
      "pb": 3,
      "pe": 18.36,
      "yield": 1.49,
      "date": "2011-09-17"
  },
  "2011-09-18": {
      "equity": 5084.2,
      "debt": 8.362,
      "gold": 29636.99183,
      "pb": 3,
      "pe": 18.36,
      "yield": 1.49,
      "date": "2011-09-18"
  },
  "2011-09-19": {
      "equity": 5031.9,
      "debt": 8.329,
      "gold": 30644.38879,
      "pb": 2.97,
      "pe": 18.17,
      "yield": 1.51,
      "date": "2011-09-19"
  },
  "2011-09-20": {
      "equity": 5140.2,
      "debt": 8.337,
      "gold": 30376.04191,
      "pb": 3.04,
      "pe": 18.56,
      "yield": 1.48,
      "date": "2011-09-20"
  },
  "2011-09-21": {
      "equity": 5133.2,
      "debt": 8.328,
      "gold": 30860.99578,
      "pb": 3.03,
      "pe": 18.54,
      "yield": 1.48,
      "date": "2011-09-21"
  },
  "2011-09-22": {
      "equity": 4923.6,
      "debt": 8.3,
      "gold": 30874.99956,
      "pb": 2.91,
      "pe": 17.78,
      "yield": 1.54,
      "date": "2011-09-22"
  },
  "2011-09-23": {
      "equity": 4867.7,
      "debt": 8.302,
      "gold": 30165.69252,
      "pb": 2.87,
      "pe": 17.58,
      "yield": 1.56,
      "date": "2011-09-23"
  },
  "2011-09-24": {
      "equity": 4867.7,
      "debt": 8.302,
      "gold": 30165.69252,
      "pb": 2.87,
      "pe": 17.58,
      "yield": 1.56,
      "date": "2011-09-24"
  },
  "2011-09-25": {
      "equity": 4867.7,
      "debt": 8.302,
      "gold": 30165.69252,
      "pb": 2.87,
      "pe": 17.58,
      "yield": 1.56,
      "date": "2011-09-25"
  },
  "2011-09-26": {
      "equity": 4835.4,
      "debt": 8.311,
      "gold": 28169.00122,
      "pb": 2.86,
      "pe": 17.46,
      "yield": 1.57,
      "date": "2011-09-26"
  },
  "2011-09-27": {
      "equity": 4971.2,
      "debt": 8.326,
      "gold": 28926.19976,
      "pb": 2.94,
      "pe": 17.95,
      "yield": 1.53,
      "date": "2011-09-27"
  },
  "2011-09-28": {
      "equity": 4945.9,
      "debt": 8.347,
      "gold": 28465.33449,
      "pb": 2.92,
      "pe": 17.86,
      "yield": 1.53,
      "date": "2011-09-28"
  },
  "2011-09-29": {
      "equity": 5015.4,
      "debt": 8.442,
      "gold": 27981.23776,
      "pb": 2.96,
      "pe": 18.11,
      "yield": 1.51,
      "date": "2011-09-29"
  },
  "2011-09-30": {
      "equity": 4943.2,
      "debt": 8.442,
      "gold": 28136.68671,
      "pb": 2.92,
      "pe": 17.85,
      "yield": 1.53,
      "date": "2011-09-30"
  },
  "2011-10-01": {
      "equity": 4943.2,
      "debt": 8.442,
      "gold": 28136.68671,
      "pb": 2.92,
      "pe": 17.85,
      "yield": 1.53,
      "date": "2011-10-01"
  },
  "2011-10-02": {
      "equity": 4943.2,
      "debt": 8.442,
      "gold": 28136.68671,
      "pb": 2.92,
      "pe": 17.85,
      "yield": 1.53,
      "date": "2011-10-02"
  },
  "2011-10-03": {
      "equity": 4849.5,
      "debt": 8.537,
      "gold": 28785.55177,
      "pb": 2.86,
      "pe": 17.51,
      "yield": 1.56,
      "date": "2011-10-03"
  },
  "2011-10-04": {
      "equity": 4772.1,
      "debt": 8.545,
      "gold": 29139.61093,
      "pb": 2.82,
      "pe": 17.23,
      "yield": 1.59,
      "date": "2011-10-04"
  },
  "2011-10-05": {
      "equity": 4751.3,
      "debt": 8.578,
      "gold": 27852.34307,
      "pb": 2.81,
      "pe": 17.16,
      "yield": 1.6,
      "date": "2011-10-05"
  },
  "2011-10-06": {
      "equity": 4751.3,
      "debt": 8.578,
      "gold": 28714.0267,
      "pb": 2.81,
      "pe": 17.16,
      "yield": 1.6,
      "date": "2011-10-06"
  },
  "2011-10-07": {
      "equity": 4888,
      "debt": 8.666,
      "gold": 28629.48553,
      "pb": 2.89,
      "pe": 17.65,
      "yield": 1.55,
      "date": "2011-10-07"
  },
  "2011-10-08": {
      "equity": 4888,
      "debt": 8.666,
      "gold": 28629.48553,
      "pb": 2.89,
      "pe": 17.65,
      "yield": 1.55,
      "date": "2011-10-08"
  },
  "2011-10-09": {
      "equity": 4888,
      "debt": 8.666,
      "gold": 28629.48553,
      "pb": 2.89,
      "pe": 17.65,
      "yield": 1.55,
      "date": "2011-10-09"
  },
  "2011-10-10": {
      "equity": 4979.6,
      "debt": 8.751,
      "gold": 28746.3271,
      "pb": 2.99,
      "pe": 18.03,
      "yield": 1.51,
      "date": "2011-10-10"
  },
  "2011-10-11": {
      "equity": 4974.3,
      "debt": 8.71,
      "gold": 28916.96503,
      "pb": 2.98,
      "pe": 18.01,
      "yield": 1.52,
      "date": "2011-10-11"
  },
  "2011-10-12": {
      "equity": 5099.4,
      "debt": 8.738,
      "gold": 29134.73606,
      "pb": 3.06,
      "pe": 18.43,
      "yield": 1.48,
      "date": "2011-10-12"
  },
  "2011-10-13": {
      "equity": 5077.8,
      "debt": 8.725,
      "gold": 28993.27678,
      "pb": 3.04,
      "pe": 18.35,
      "yield": 1.49,
      "date": "2011-10-13"
  },
  "2011-10-14": {
      "equity": 5132.3,
      "debt": 8.784,
      "gold": 28980.23598,
      "pb": 3.08,
      "pe": 18.55,
      "yield": 1.47,
      "date": "2011-10-14"
  },
  "2011-10-15": {
      "equity": 5132.3,
      "debt": 8.784,
      "gold": 28980.23598,
      "pb": 3.08,
      "pe": 18.55,
      "yield": 1.47,
      "date": "2011-10-15"
  },
  "2011-10-16": {
      "equity": 5132.3,
      "debt": 8.784,
      "gold": 28980.23598,
      "pb": 3.08,
      "pe": 18.55,
      "yield": 1.47,
      "date": "2011-10-16"
  },
  "2011-10-17": {
      "equity": 5118.2,
      "debt": 8.757,
      "gold": 29166.29923,
      "pb": 3.07,
      "pe": 18.5,
      "yield": 1.47,
      "date": "2011-10-17"
  },
  "2011-10-18": {
      "equity": 5037.5,
      "debt": 8.748,
      "gold": 28832.74837,
      "pb": 3.02,
      "pe": 18,
      "yield": 1.5,
      "date": "2011-10-18"
  },
  "2011-10-19": {
      "equity": 5139.1,
      "debt": 8.775,
      "gold": 28632.39563,
      "pb": 3.08,
      "pe": 18.36,
      "yield": 1.47,
      "date": "2011-10-19"
  },
  "2011-10-20": {
      "equity": 5091.9,
      "debt": 8.79,
      "gold": 28620.05326,
      "pb": 3.05,
      "pe": 18.14,
      "yield": 1.48,
      "date": "2011-10-20"
  },
  "2011-10-21": {
      "equity": 5049.9,
      "debt": 8.82,
      "gold": 28639.15766,
      "pb": 3.03,
      "pe": 17.99,
      "yield": 1.49,
      "date": "2011-10-21"
  },
  "2011-10-22": {
      "equity": 5049.9,
      "debt": 8.82,
      "gold": 28639.15766,
      "pb": 3.03,
      "pe": 17.99,
      "yield": 1.49,
      "date": "2011-10-22"
  },
  "2011-10-23": {
      "equity": 5049.9,
      "debt": 8.82,
      "gold": 28639.15766,
      "pb": 3.03,
      "pe": 17.99,
      "yield": 1.49,
      "date": "2011-10-23"
  },
  "2011-10-24": {
      "equity": 5098.3,
      "debt": 8.817,
      "gold": 29016.76573,
      "pb": 3.06,
      "pe": 18.1,
      "yield": 1.48,
      "date": "2011-10-24"
  },
  "2011-10-25": {
      "equity": 5191.6,
      "debt": 8.763,
      "gold": 28925.00397,
      "pb": 3.11,
      "pe": 18.39,
      "yield": 1.45,
      "date": "2011-10-25"
  },
  "2011-10-26": {
      "equity": 5201.8,
      "debt": 8.763,
      "gold": 29916.09376,
      "pb": 3.12,
      "pe": 18.43,
      "yield": 1.45,
      "date": "2011-10-26"
  },
  "2011-10-27": {
      "equity": 5201.8,
      "debt": 8.763,
      "gold": 29828.77299,
      "pb": 3.12,
      "pe": 18.43,
      "yield": 1.45,
      "date": "2011-10-27"
  },
  "2011-10-28": {
      "equity": 5360.7,
      "debt": 8.852,
      "gold": 29841.30232,
      "pb": 3.21,
      "pe": 19.03,
      "yield": 1.41,
      "date": "2011-10-28"
  },
  "2011-10-29": {
      "equity": 5360.7,
      "debt": 8.852,
      "gold": 29841.30232,
      "pb": 3.21,
      "pe": 19.03,
      "yield": 1.41,
      "date": "2011-10-29"
  },
  "2011-10-30": {
      "equity": 5360.7,
      "debt": 8.852,
      "gold": 29841.30232,
      "pb": 3.21,
      "pe": 19.03,
      "yield": 1.41,
      "date": "2011-10-30"
  },
  "2011-10-31": {
      "equity": 5326.6,
      "debt": 8.879,
      "gold": 29509.51869,
      "pb": 3.19,
      "pe": 18.9,
      "yield": 1.42,
      "date": "2011-10-31"
  },
  "2011-11-01": {
      "equity": 5257.9,
      "debt": 8.947,
      "gold": 29585.90451,
      "pb": 3.15,
      "pe": 19.04,
      "yield": 1.43,
      "date": "2011-11-01"
  },
  "2011-11-02": {
      "equity": 5258.4,
      "debt": 8.904,
      "gold": 30032.00762,
      "pb": 3.15,
      "pe": 19.04,
      "yield": 1.43,
      "date": "2011-11-02"
  },
  "2011-11-03": {
      "equity": 5265.7,
      "debt": 8.901,
      "gold": 30033.58437,
      "pb": 3.16,
      "pe": 19.06,
      "yield": 1.43,
      "date": "2011-11-03"
  },
  "2011-11-04": {
      "equity": 5284.2,
      "debt": 8.968,
      "gold": 30416.19076,
      "pb": 3.17,
      "pe": 19.21,
      "yield": 1.43,
      "date": "2011-11-04"
  },
  "2011-11-05": {
      "equity": 5284.2,
      "debt": 8.968,
      "gold": 30416.19076,
      "pb": 3.17,
      "pe": 19.21,
      "yield": 1.43,
      "date": "2011-11-05"
  },
  "2011-11-06": {
      "equity": 5284.2,
      "debt": 8.968,
      "gold": 30416.19076,
      "pb": 3.17,
      "pe": 19.21,
      "yield": 1.43,
      "date": "2011-11-06"
  },
  "2011-11-07": {
      "equity": 5284.2,
      "debt": 8.968,
      "gold": 30554.76111,
      "pb": 3.17,
      "pe": 19.21,
      "yield": 1.43,
      "date": "2011-11-07"
  },
  "2011-11-08": {
      "equity": 5289.3,
      "debt": 9.034,
      "gold": 31283.22898,
      "pb": 3.17,
      "pe": 19.11,
      "yield": 1.43,
      "date": "2011-11-08"
  },
  "2011-11-09": {
      "equity": 5221,
      "debt": 9.061,
      "gold": 31516.28777,
      "pb": 3.13,
      "pe": 18.78,
      "yield": 1.44,
      "date": "2011-11-09"
  },
  "2011-11-10": {
      "equity": 5221,
      "debt": 9.061,
      "gold": 31268.40685,
      "pb": 3.13,
      "pe": 18.78,
      "yield": 1.44,
      "date": "2011-11-10"
  },
  "2011-11-11": {
      "equity": 5168.8,
      "debt": 9.054,
      "gold": 31187.88338,
      "pb": 3.1,
      "pe": 18.61,
      "yield": 1.46,
      "date": "2011-11-11"
  },
  "2011-11-12": {
      "equity": 5168.8,
      "debt": 9.054,
      "gold": 31187.88338,
      "pb": 3.1,
      "pe": 18.61,
      "yield": 1.46,
      "date": "2011-11-12"
  },
  "2011-11-13": {
      "equity": 5168.8,
      "debt": 9.054,
      "gold": 31187.88338,
      "pb": 3.1,
      "pe": 18.61,
      "yield": 1.46,
      "date": "2011-11-13"
  },
  "2011-11-14": {
      "equity": 5148.3,
      "debt": 9.037,
      "gold": 31638.19115,
      "pb": 3.09,
      "pe": 18.59,
      "yield": 1.47,
      "date": "2011-11-14"
  },
  "2011-11-15": {
      "equity": 5068.5,
      "debt": 8.97,
      "gold": 31552.65525,
      "pb": 3.04,
      "pe": 18.35,
      "yield": 1.49,
      "date": "2011-11-15"
  },
  "2011-11-16": {
      "equity": 5030.4,
      "debt": 8.977,
      "gold": 31742.57747,
      "pb": 3.02,
      "pe": 18.21,
      "yield": 1.5,
      "date": "2011-11-16"
  },
  "2011-11-17": {
      "equity": 4934.7,
      "debt": 8.838,
      "gold": 31555.90751,
      "pb": 2.96,
      "pe": 17.87,
      "yield": 1.53,
      "date": "2011-11-17"
  },
  "2011-11-18": {
      "equity": 4905.8,
      "debt": 8.856,
      "gold": 31277.85322,
      "pb": 2.94,
      "pe": 17.76,
      "yield": 1.54,
      "date": "2011-11-18"
  },
  "2011-11-19": {
      "equity": 4905.8,
      "debt": 8.856,
      "gold": 31277.85322,
      "pb": 2.94,
      "pe": 17.76,
      "yield": 1.54,
      "date": "2011-11-19"
  },
  "2011-11-20": {
      "equity": 4905.8,
      "debt": 8.856,
      "gold": 31277.85322,
      "pb": 2.94,
      "pe": 17.76,
      "yield": 1.54,
      "date": "2011-11-20"
  },
  "2011-11-21": {
      "equity": 4778.3,
      "debt": 8.837,
      "gold": 31450.92506,
      "pb": 2.87,
      "pe": 17.3,
      "yield": 1.58,
      "date": "2011-11-21"
  },
  "2011-11-22": {
      "equity": 4812.3,
      "debt": 8.844,
      "gold": 31408.79381,
      "pb": 2.89,
      "pe": 17.42,
      "yield": 1.57,
      "date": "2011-11-22"
  },
  "2011-11-23": {
      "equity": 4706.4,
      "debt": 8.812,
      "gold": 31207.9049,
      "pb": 2.82,
      "pe": 17.04,
      "yield": 1.6,
      "date": "2011-11-23"
  },
  "2011-11-24": {
      "equity": 4756.4,
      "debt": 8.793,
      "gold": 31184.84277,
      "pb": 2.85,
      "pe": 17.22,
      "yield": 1.59,
      "date": "2011-11-24"
  },
  "2011-11-25": {
      "equity": 4710,
      "debt": 8.811,
      "gold": 30883.8745,
      "pb": 2.82,
      "pe": 17.05,
      "yield": 1.6,
      "date": "2011-11-25"
  },
  "2011-11-26": {
      "equity": 4710,
      "debt": 8.811,
      "gold": 30883.8745,
      "pb": 2.82,
      "pe": 17.05,
      "yield": 1.6,
      "date": "2011-11-26"
  },
  "2011-11-27": {
      "equity": 4710,
      "debt": 8.811,
      "gold": 30883.8745,
      "pb": 2.82,
      "pe": 17.05,
      "yield": 1.6,
      "date": "2011-11-27"
  },
  "2011-11-28": {
      "equity": 4851.3,
      "debt": 8.827,
      "gold": 31460.16332,
      "pb": 2.91,
      "pe": 17.56,
      "yield": 1.55,
      "date": "2011-11-28"
  },
  "2011-11-29": {
      "equity": 4805.1,
      "debt": 8.825,
      "gold": 31527.34263,
      "pb": 2.87,
      "pe": 17.4,
      "yield": 1.57,
      "date": "2011-11-29"
  },
  "2011-11-30": {
      "equity": 4832,
      "debt": 8.738,
      "gold": 31408.85024,
      "pb": 2.89,
      "pe": 17.49,
      "yield": 1.56,
      "date": "2011-11-30"
  },
  "2011-12-01": {
      "equity": 4936.8,
      "debt": 8.697,
      "gold": 31710.43581,
      "pb": 2.95,
      "pe": 17.87,
      "yield": 1.53,
      "date": "2011-12-01"
  },
  "2011-12-02": {
      "equity": 5050.1,
      "debt": 8.647,
      "gold": 31564.88121,
      "pb": 3.01,
      "pe": 18.28,
      "yield": 1.49,
      "date": "2011-12-02"
  },
  "2011-12-03": {
      "equity": 5050.1,
      "debt": 8.647,
      "gold": 31564.88121,
      "pb": 3.01,
      "pe": 18.28,
      "yield": 1.49,
      "date": "2011-12-03"
  },
  "2011-12-04": {
      "equity": 5050.1,
      "debt": 8.647,
      "gold": 31564.88121,
      "pb": 3.01,
      "pe": 18.28,
      "yield": 1.49,
      "date": "2011-12-04"
  },
  "2011-12-05": {
      "equity": 5039.1,
      "debt": 8.577,
      "gold": 31567.8795,
      "pb": 3.01,
      "pe": 18.24,
      "yield": 1.5,
      "date": "2011-12-05"
  },
  "2011-12-06": {
      "equity": 5039.1,
      "debt": 8.577,
      "gold": 31133.45914,
      "pb": 3.01,
      "pe": 18.24,
      "yield": 1.5,
      "date": "2011-12-06"
  },
  "2011-12-07": {
      "equity": 5062.6,
      "debt": 8.594,
      "gold": 31529.48377,
      "pb": 3.02,
      "pe": 18.33,
      "yield": 1.49,
      "date": "2011-12-07"
  },
  "2011-12-08": {
      "equity": 4943.6,
      "debt": 8.519,
      "gold": 31722.74291,
      "pb": 2.95,
      "pe": 17.9,
      "yield": 1.53,
      "date": "2011-12-08"
  },
  "2011-12-09": {
      "equity": 4866.7,
      "debt": 8.533,
      "gold": 31326.83116,
      "pb": 2.9,
      "pe": 17.62,
      "yield": 1.55,
      "date": "2011-12-09"
  },
  "2011-12-10": {
      "equity": 4866.7,
      "debt": 8.533,
      "gold": 31326.83116,
      "pb": 2.9,
      "pe": 17.62,
      "yield": 1.55,
      "date": "2011-12-10"
  },
  "2011-12-11": {
      "equity": 4866.7,
      "debt": 8.533,
      "gold": 31326.83116,
      "pb": 2.9,
      "pe": 17.62,
      "yield": 1.55,
      "date": "2011-12-11"
  },
  "2011-12-12": {
      "equity": 4764.6,
      "debt": 8.446,
      "gold": 31173.88314,
      "pb": 2.84,
      "pe": 17.25,
      "yield": 1.58,
      "date": "2011-12-12"
  },
  "2011-12-13": {
      "equity": 4800.6,
      "debt": 8.403,
      "gold": 31186.26431,
      "pb": 2.87,
      "pe": 17.38,
      "yield": 1.57,
      "date": "2011-12-13"
  },
  "2011-12-14": {
      "equity": 4763.2,
      "debt": 8.491,
      "gold": 31059.77883,
      "pb": 2.84,
      "pe": 17.25,
      "yield": 1.58,
      "date": "2011-12-14"
  },
  "2011-12-15": {
      "equity": 4746.3,
      "debt": 8.486,
      "gold": 29879.62751,
      "pb": 2.83,
      "pe": 17.19,
      "yield": 1.59,
      "date": "2011-12-15"
  },
  "2011-12-16": {
      "equity": 4651.6,
      "debt": 8.379,
      "gold": 29439.90899,
      "pb": 2.78,
      "pe": 16.84,
      "yield": 1.62,
      "date": "2011-12-16"
  },
  "2011-12-17": {
      "equity": 4651.6,
      "debt": 8.379,
      "gold": 29439.90899,
      "pb": 2.78,
      "pe": 16.84,
      "yield": 1.62,
      "date": "2011-12-17"
  },
  "2011-12-18": {
      "equity": 4651.6,
      "debt": 8.379,
      "gold": 29439.90899,
      "pb": 2.78,
      "pe": 16.84,
      "yield": 1.62,
      "date": "2011-12-18"
  },
  "2011-12-19": {
      "equity": 4613.1,
      "debt": 8.329,
      "gold": 29612.90323,
      "pb": 2.75,
      "pe": 16.71,
      "yield": 1.64,
      "date": "2011-12-19"
  },
  "2011-12-20": {
      "equity": 4544.2,
      "debt": 8.276,
      "gold": 29991.66828,
      "pb": 2.71,
      "pe": 16.46,
      "yield": 1.66,
      "date": "2011-12-20"
  },
  "2011-12-21": {
      "equity": 4693.1,
      "debt": 8.34,
      "gold": 30466.12462,
      "pb": 2.8,
      "pe": 17,
      "yield": 1.61,
      "date": "2011-12-21"
  },
  "2011-12-22": {
      "equity": 4733.8,
      "debt": 8.333,
      "gold": 29837.96892,
      "pb": 2.83,
      "pe": 17.14,
      "yield": 1.59,
      "date": "2011-12-22"
  },
  "2011-12-23": {
      "equity": 4714,
      "debt": 8.365,
      "gold": 29933.48384,
      "pb": 2.81,
      "pe": 17.07,
      "yield": 1.6,
      "date": "2011-12-23"
  },
  "2011-12-24": {
      "equity": 4714,
      "debt": 8.365,
      "gold": 29933.48384,
      "pb": 2.81,
      "pe": 17.07,
      "yield": 1.6,
      "date": "2011-12-24"
  },
  "2011-12-25": {
      "equity": 4714,
      "debt": 8.365,
      "gold": 29933.48384,
      "pb": 2.81,
      "pe": 17.07,
      "yield": 1.6,
      "date": "2011-12-25"
  },
  "2011-12-26": {
      "equity": 4779,
      "debt": 8.49,
      "gold": 29933.48384,
      "pb": 2.85,
      "pe": 17.31,
      "yield": 1.58,
      "date": "2011-12-26"
  },
  "2011-12-27": {
      "equity": 4750.5,
      "debt": 8.476,
      "gold": 29933.48384,
      "pb": 2.84,
      "pe": 17.2,
      "yield": 1.59,
      "date": "2011-12-27"
  },
  "2011-12-28": {
      "equity": 4705.8,
      "debt": 8.478,
      "gold": 29555.94984,
      "pb": 2.81,
      "pe": 17.04,
      "yield": 1.6,
      "date": "2011-12-28"
  },
  "2011-12-29": {
      "equity": 4646.2,
      "debt": 8.536,
      "gold": 28746.60576,
      "pb": 2.77,
      "pe": 16.83,
      "yield": 1.62,
      "date": "2011-12-29"
  },
  "2011-12-30": {
      "equity": 4624.3,
      "debt": 8.56,
      "gold": 29493.93111,
      "pb": 2.76,
      "pe": 16.75,
      "yield": 1.63,
      "date": "2011-12-30"
  },
  "2011-12-31": {
      "equity": 4624.3,
      "debt": 8.56,
      "gold": 29493.93111,
      "pb": 2.76,
      "pe": 16.75,
      "yield": 1.63,
      "date": "2011-12-31"
  },
  "2012-01-01": {
      "equity": 4624.3,
      "debt": 8.56,
      "gold": 29493.93111,
      "pb": 2.76,
      "pe": 16.75,
      "yield": 1.63,
      "date": "2012-01-01"
  },
  "2012-01-02": {
      "equity": 4636.7,
      "debt": 8.409,
      "gold": 29493.93111,
      "pb": 2.77,
      "pe": 16.79,
      "yield": 1.63,
      "date": "2012-01-02"
  },
  "2012-01-03": {
      "equity": 4765.3,
      "debt": 8.357,
      "gold": 29792.69476,
      "pb": 2.82,
      "pe": 17.26,
      "yield": 1.6,
      "date": "2012-01-03"
  },
  "2012-01-04": {
      "equity": 4749.6,
      "debt": 8.364,
      "gold": 30016.49412,
      "pb": 2.81,
      "pe": 17.2,
      "yield": 1.6,
      "date": "2012-01-04"
  },
  "2012-01-05": {
      "equity": 4749.9,
      "debt": 8.328,
      "gold": 30123.62828,
      "pb": 2.8,
      "pe": 17.2,
      "yield": 1.6,
      "date": "2012-01-05"
  },
  "2012-01-06": {
      "equity": 4754.1,
      "debt": 8.219,
      "gold": 30147.70278,
      "pb": 2.8,
      "pe": 17.22,
      "yield": 1.6,
      "date": "2012-01-06"
  },
  "2012-01-07": {
      "equity": 4746.9,
      "debt": 8.219,
      "gold": 30147.70278,
      "pb": 2.8,
      "pe": 17.19,
      "yield": 1.6,
      "date": "2012-01-07"
  },
  "2012-01-08": {
      "equity": 4746.9,
      "debt": 8.219,
      "gold": 30147.70278,
      "pb": 2.8,
      "pe": 17.19,
      "yield": 1.6,
      "date": "2012-01-08"
  },
  "2012-01-09": {
      "equity": 4742.8,
      "debt": 8.201,
      "gold": 29906.4181,
      "pb": 2.79,
      "pe": 17.18,
      "yield": 1.6,
      "date": "2012-01-09"
  },
  "2012-01-10": {
      "equity": 4849.5,
      "debt": 8.228,
      "gold": 29725.55777,
      "pb": 2.86,
      "pe": 17.56,
      "yield": 1.57,
      "date": "2012-01-10"
  },
  "2012-01-11": {
      "equity": 4860.9,
      "debt": 8.232,
      "gold": 29979.8903,
      "pb": 2.86,
      "pe": 17.61,
      "yield": 1.57,
      "date": "2012-01-11"
  },
  "2012-01-12": {
      "equity": 4831.2,
      "debt": 8.251,
      "gold": 30031.14693,
      "pb": 2.85,
      "pe": 17.5,
      "yield": 1.58,
      "date": "2012-01-12"
  },
  "2012-01-13": {
      "equity": 4866,
      "debt": 8.192,
      "gold": 29798.3386,
      "pb": 2.87,
      "pe": 17.5,
      "yield": 1.56,
      "date": "2012-01-13"
  },
  "2012-01-14": {
      "equity": 4866,
      "debt": 8.192,
      "gold": 29798.3386,
      "pb": 2.87,
      "pe": 17.5,
      "yield": 1.56,
      "date": "2012-01-14"
  },
  "2012-01-15": {
      "equity": 4866,
      "debt": 8.192,
      "gold": 29798.3386,
      "pb": 2.87,
      "pe": 17.5,
      "yield": 1.56,
      "date": "2012-01-15"
  },
  "2012-01-16": {
      "equity": 4873.9,
      "debt": 8.222,
      "gold": 29824.10977,
      "pb": 2.87,
      "pe": 17.53,
      "yield": 1.56,
      "date": "2012-01-16"
  },
  "2012-01-17": {
      "equity": 4967.3,
      "debt": 8.218,
      "gold": 29723.0639,
      "pb": 2.92,
      "pe": 17.85,
      "yield": 1.53,
      "date": "2012-01-17"
  },
  "2012-01-18": {
      "equity": 4955.8,
      "debt": 8.19,
      "gold": 29464.14222,
      "pb": 2.9,
      "pe": 17.81,
      "yield": 1.54,
      "date": "2012-01-18"
  },
  "2012-01-19": {
      "equity": 5018.4,
      "debt": 8.169,
      "gold": 29529.91764,
      "pb": 2.94,
      "pe": 18.03,
      "yield": 1.53,
      "date": "2012-01-19"
  },
  "2012-01-20": {
      "equity": 5048.6,
      "debt": 8.181,
      "gold": 29168.38745,
      "pb": 2.96,
      "pe": 18.14,
      "yield": 1.52,
      "date": "2012-01-20"
  },
  "2012-01-21": {
      "equity": 5048.6,
      "debt": 8.181,
      "gold": 29168.38745,
      "pb": 2.96,
      "pe": 18.14,
      "yield": 1.52,
      "date": "2012-01-21"
  },
  "2012-01-22": {
      "equity": 5048.6,
      "debt": 8.181,
      "gold": 29168.38745,
      "pb": 2.96,
      "pe": 18.14,
      "yield": 1.52,
      "date": "2012-01-22"
  },
  "2012-01-23": {
      "equity": 5046.2,
      "debt": 8.17,
      "gold": 29527.19801,
      "pb": 2.96,
      "pe": 18.14,
      "yield": 1.52,
      "date": "2012-01-23"
  },
  "2012-01-24": {
      "equity": 5127.3,
      "debt": 8.355,
      "gold": 29453.80695,
      "pb": 3.01,
      "pe": 18.28,
      "yield": 1.49,
      "date": "2012-01-24"
  },
  "2012-01-25": {
      "equity": 5158.3,
      "debt": 8.31,
      "gold": 29403.14997,
      "pb": 2.99,
      "pe": 18.38,
      "yield": 1.51,
      "date": "2012-01-25"
  },
  "2012-01-26": {
      "equity": 5158.3,
      "debt": 8.31,
      "gold": 30360.21447,
      "pb": 2.99,
      "pe": 18.38,
      "yield": 1.51,
      "date": "2012-01-26"
  },
  "2012-01-27": {
      "equity": 5204.7,
      "debt": 8.354,
      "gold": 30003.41805,
      "pb": 3.02,
      "pe": 18.55,
      "yield": 1.49,
      "date": "2012-01-27"
  },
  "2012-01-28": {
      "equity": 5204.7,
      "debt": 8.354,
      "gold": 30003.41805,
      "pb": 3.02,
      "pe": 18.55,
      "yield": 1.49,
      "date": "2012-01-28"
  },
  "2012-01-29": {
      "equity": 5204.7,
      "debt": 8.354,
      "gold": 30003.41805,
      "pb": 3.02,
      "pe": 18.55,
      "yield": 1.49,
      "date": "2012-01-29"
  },
  "2012-01-30": {
      "equity": 5087.3,
      "debt": 8.284,
      "gold": 30217.00559,
      "pb": 2.95,
      "pe": 18.13,
      "yield": 1.53,
      "date": "2012-01-30"
  },
  "2012-01-31": {
      "equity": 5199.2,
      "debt": 8.267,
      "gold": 30374.15475,
      "pb": 3.02,
      "pe": 18.53,
      "yield": 1.49,
      "date": "2012-01-31"
  },
  "2012-02-01": {
      "equity": 5235.7,
      "debt": 8.143,
      "gold": 30205.25935,
      "pb": 3.04,
      "pe": 18.66,
      "yield": 1.48,
      "date": "2012-02-01"
  },
  "2012-02-02": {
      "equity": 5269.9,
      "debt": 8.131,
      "gold": 30231.97587,
      "pb": 3.06,
      "pe": 18.77,
      "yield": 1.47,
      "date": "2012-02-02"
  },
  "2012-02-03": {
      "equity": 5325.8,
      "debt": 8.164,
      "gold": 30303.03533,
      "pb": 3.03,
      "pe": 18.97,
      "yield": 1.48,
      "date": "2012-02-03"
  },
  "2012-02-04": {
      "equity": 5325.8,
      "debt": 8.164,
      "gold": 30303.03533,
      "pb": 3.03,
      "pe": 18.97,
      "yield": 1.48,
      "date": "2012-02-04"
  },
  "2012-02-05": {
      "equity": 5325.8,
      "debt": 8.164,
      "gold": 30303.03533,
      "pb": 3.03,
      "pe": 18.97,
      "yield": 1.48,
      "date": "2012-02-05"
  },
  "2012-02-06": {
      "equity": 5361.6,
      "debt": 8.183,
      "gold": 29713.40588,
      "pb": 3.05,
      "pe": 19.13,
      "yield": 1.47,
      "date": "2012-02-06"
  },
  "2012-02-07": {
      "equity": 5335.1,
      "debt": 8.188,
      "gold": 29822.95984,
      "pb": 3.04,
      "pe": 19.02,
      "yield": 1.47,
      "date": "2012-02-07"
  },
  "2012-02-08": {
      "equity": 5368.1,
      "debt": 8.232,
      "gold": 30252.49828,
      "pb": 3.04,
      "pe": 19.14,
      "yield": 1.48,
      "date": "2012-02-08"
  },
  "2012-02-09": {
      "equity": 5412.3,
      "debt": 8.272,
      "gold": 30170.62735,
      "pb": 3.06,
      "pe": 19.3,
      "yield": 1.45,
      "date": "2012-02-09"
  },
  "2012-02-10": {
      "equity": 5381.6,
      "debt": 8.194,
      "gold": 29959.7559,
      "pb": 3.02,
      "pe": 19,
      "yield": 1.47,
      "date": "2012-02-10"
  },
  "2012-02-11": {
      "equity": 5381.6,
      "debt": 8.202,
      "gold": 29959.7559,
      "pb": 3.02,
      "pe": 19,
      "yield": 1.47,
      "date": "2012-02-11"
  },
  "2012-02-12": {
      "equity": 5381.6,
      "debt": 8.202,
      "gold": 29959.7559,
      "pb": 3.02,
      "pe": 19,
      "yield": 1.47,
      "date": "2012-02-12"
  },
  "2012-02-13": {
      "equity": 5390.2,
      "debt": 8.209,
      "gold": 29941.28644,
      "pb": 3.03,
      "pe": 18.94,
      "yield": 1.47,
      "date": "2012-02-13"
  },
  "2012-02-14": {
      "equity": 5416,
      "debt": 8.18,
      "gold": 30004.20466,
      "pb": 3.04,
      "pe": 18.95,
      "yield": 1.46,
      "date": "2012-02-14"
  },
  "2012-02-15": {
      "equity": 5531.9,
      "debt": 8.197,
      "gold": 30052.22667,
      "pb": 3.11,
      "pe": 19.35,
      "yield": 1.43,
      "date": "2012-02-15"
  },
  "2012-02-16": {
      "equity": 5521.9,
      "debt": 8.197,
      "gold": 29898.87652,
      "pb": 3.1,
      "pe": 19.34,
      "yield": 1.43,
      "date": "2012-02-16"
  },
  "2012-02-17": {
      "equity": 5564.3,
      "debt": 8.185,
      "gold": 30150.16138,
      "pb": 3.12,
      "pe": 19.49,
      "yield": 1.42,
      "date": "2012-02-17"
  },
  "2012-02-18": {
      "equity": 5564.3,
      "debt": 8.188,
      "gold": 30150.16138,
      "pb": 3.12,
      "pe": 19.49,
      "yield": 1.42,
      "date": "2012-02-18"
  },
  "2012-02-19": {
      "equity": 5564.3,
      "debt": 8.188,
      "gold": 30150.16138,
      "pb": 3.12,
      "pe": 19.49,
      "yield": 1.42,
      "date": "2012-02-19"
  },
  "2012-02-20": {
      "equity": 5564.3,
      "debt": 8.188,
      "gold": 30106.64386,
      "pb": 3.12,
      "pe": 19.49,
      "yield": 1.42,
      "date": "2012-02-20"
  },
  "2012-02-21": {
      "equity": 5607.1,
      "debt": 8.173,
      "gold": 30160.61306,
      "pb": 3.16,
      "pe": 19.64,
      "yield": 1.41,
      "date": "2012-02-21"
  },
  "2012-02-22": {
      "equity": 5505.3,
      "debt": 8.222,
      "gold": 30415.46059,
      "pb": 3.1,
      "pe": 19.29,
      "yield": 1.44,
      "date": "2012-02-22"
  },
  "2012-02-23": {
      "equity": 5483.3,
      "debt": 8.196,
      "gold": 30833.94063,
      "pb": 3.09,
      "pe": 19.21,
      "yield": 1.44,
      "date": "2012-02-23"
  },
  "2012-02-24": {
      "equity": 5429.3,
      "debt": 8.226,
      "gold": 30736.91247,
      "pb": 3.06,
      "pe": 19.02,
      "yield": 1.46,
      "date": "2012-02-24"
  },
  "2012-02-25": {
      "equity": 5429.3,
      "debt": 8.227,
      "gold": 30736.91247,
      "pb": 3.06,
      "pe": 19.02,
      "yield": 1.46,
      "date": "2012-02-25"
  },
  "2012-02-26": {
      "equity": 5429.3,
      "debt": 8.227,
      "gold": 30736.91247,
      "pb": 3.06,
      "pe": 19.02,
      "yield": 1.46,
      "date": "2012-02-26"
  },
  "2012-02-27": {
      "equity": 5281.2,
      "debt": 8.229,
      "gold": 30590.76174,
      "pb": 2.98,
      "pe": 18.5,
      "yield": 1.5,
      "date": "2012-02-27"
  },
  "2012-02-28": {
      "equity": 5375.5,
      "debt": 8.212,
      "gold": 30694.01224,
      "pb": 3.03,
      "pe": 19.05,
      "yield": 1.47,
      "date": "2012-02-28"
  },
  "2012-02-29": {
      "equity": 5385.2,
      "debt": 8.199,
      "gold": 30973.62564,
      "pb": 3.04,
      "pe": 19.09,
      "yield": 1.47,
      "date": "2012-02-29"
  },
  "2012-03-01": {
      "equity": 5339.7,
      "debt": 8.237,
      "gold": 29861.54606,
      "pb": 3.01,
      "pe": 18.92,
      "yield": 1.48,
      "date": "2012-03-01"
  },
  "2012-03-02": {
      "equity": 5359.3,
      "debt": 8.224,
      "gold": 29912.05136,
      "pb": 3.04,
      "pe": 18.99,
      "yield": 1.48,
      "date": "2012-03-02"
  },
  "2012-03-03": {
      "equity": 5359.4,
      "debt": 8.225,
      "gold": 29912.05136,
      "pb": 3.04,
      "pe": 18.99,
      "yield": 1.48,
      "date": "2012-03-03"
  },
  "2012-03-04": {
      "equity": 5359.4,
      "debt": 8.225,
      "gold": 29912.05136,
      "pb": 3.04,
      "pe": 18.99,
      "yield": 1.48,
      "date": "2012-03-04"
  },
  "2012-03-05": {
      "equity": 5280.3,
      "debt": 8.228,
      "gold": 29845.79622,
      "pb": 2.99,
      "pe": 18.71,
      "yield": 1.5,
      "date": "2012-03-05"
  },
  "2012-03-06": {
      "equity": 5222.4,
      "debt": 8.232,
      "gold": 29956.05566,
      "pb": 2.96,
      "pe": 18.51,
      "yield": 1.52,
      "date": "2012-03-06"
  },
  "2012-03-07": {
      "equity": 5220.4,
      "debt": 8.241,
      "gold": 29840.42047,
      "pb": 2.96,
      "pe": 18.5,
      "yield": 1.52,
      "date": "2012-03-07"
  },
  "2012-03-08": {
      "equity": 5220.4,
      "debt": 8.242,
      "gold": 30177.39995,
      "pb": 2.96,
      "pe": 18.5,
      "yield": 1.52,
      "date": "2012-03-08"
  },
  "2012-03-09": {
      "equity": 5333.5,
      "debt": 8.281,
      "gold": 29851.17903,
      "pb": 3.02,
      "pe": 18.9,
      "yield": 1.48,
      "date": "2012-03-09"
  },
  "2012-03-10": {
      "equity": 5333.5,
      "debt": 8.285,
      "gold": 29851.17903,
      "pb": 3.02,
      "pe": 18.9,
      "yield": 1.48,
      "date": "2012-03-10"
  },
  "2012-03-11": {
      "equity": 5333.5,
      "debt": 8.285,
      "gold": 29851.17903,
      "pb": 3.02,
      "pe": 18.9,
      "yield": 1.48,
      "date": "2012-03-11"
  },
  "2012-03-12": {
      "equity": 5359.5,
      "debt": 8.294,
      "gold": 30090.52364,
      "pb": 3.05,
      "pe": 18.94,
      "yield": 1.49,
      "date": "2012-03-12"
  },
  "2012-03-13": {
      "equity": 5429.5,
      "debt": 8.321,
      "gold": 29923.17678,
      "pb": 3.09,
      "pe": 19.18,
      "yield": 1.47,
      "date": "2012-03-13"
  },
  "2012-03-14": {
      "equity": 5463.9,
      "debt": 8.281,
      "gold": 29327.34263,
      "pb": 3.1,
      "pe": 19.31,
      "yield": 1.46,
      "date": "2012-03-14"
  },
  "2012-03-15": {
      "equity": 5380.5,
      "debt": 8.36,
      "gold": 29249.93386,
      "pb": 3.06,
      "pe": 19.01,
      "yield": 1.48,
      "date": "2012-03-15"
  },
  "2012-03-16": {
      "equity": 5317.9,
      "debt": 8.422,
      "gold": 29173.56567,
      "pb": 3.02,
      "pe": 18.79,
      "yield": 1.5,
      "date": "2012-03-16"
  },
  "2012-03-17": {
      "equity": 5317.9,
      "debt": 8.428,
      "gold": 29173.56567,
      "pb": 3.02,
      "pe": 18.79,
      "yield": 1.5,
      "date": "2012-03-17"
  },
  "2012-03-18": {
      "equity": 5317.9,
      "debt": 8.428,
      "gold": 29173.56567,
      "pb": 3.02,
      "pe": 18.79,
      "yield": 1.5,
      "date": "2012-03-18"
  },
  "2012-03-19": {
      "equity": 5257,
      "debt": 8.416,
      "gold": 29297.02817,
      "pb": 2.99,
      "pe": 18.58,
      "yield": 1.51,
      "date": "2012-03-19"
  },
  "2012-03-20": {
      "equity": 5274.8,
      "debt": 8.411,
      "gold": 29379.86913,
      "pb": 3,
      "pe": 18.64,
      "yield": 1.51,
      "date": "2012-03-20"
  },
  "2012-03-21": {
      "equity": 5364.9,
      "debt": 8.391,
      "gold": 29589.47424,
      "pb": 3.05,
      "pe": 18.96,
      "yield": 1.48,
      "date": "2012-03-21"
  },
  "2012-03-22": {
      "equity": 5228.4,
      "debt": 8.376,
      "gold": 29589.90458,
      "pb": 2.97,
      "pe": 18.47,
      "yield": 1.52,
      "date": "2012-03-22"
  },
  "2012-03-23": {
      "equity": 5278.2,
      "debt": 8.384,
      "gold": 29861.20743,
      "pb": 3,
      "pe": 18.65,
      "yield": 1.51,
      "date": "2012-03-23"
  },
  "2012-03-24": {
      "equity": 5278.2,
      "debt": 8.384,
      "gold": 29861.20743,
      "pb": 3,
      "pe": 18.65,
      "yield": 1.51,
      "date": "2012-03-24"
  },
  "2012-03-25": {
      "equity": 5278.2,
      "debt": 8.384,
      "gold": 29861.20743,
      "pb": 3,
      "pe": 18.65,
      "yield": 1.51,
      "date": "2012-03-25"
  },
  "2012-03-26": {
      "equity": 5184.2,
      "debt": 8.472,
      "gold": 29859.14743,
      "pb": 2.95,
      "pe": 18.32,
      "yield": 1.54,
      "date": "2012-03-26"
  },
  "2012-03-27": {
      "equity": 5243.1,
      "debt": 8.502,
      "gold": 30393.94346,
      "pb": 2.98,
      "pe": 18.53,
      "yield": 1.52,
      "date": "2012-03-27"
  },
  "2012-03-28": {
      "equity": 5194.7,
      "debt": 8.62,
      "gold": 30112.59105,
      "pb": 2.95,
      "pe": 18.36,
      "yield": 1.53,
      "date": "2012-03-28"
  },
  "2012-03-29": {
      "equity": 5178.8,
      "debt": 8.606,
      "gold": 29982.16194,
      "pb": 2.94,
      "pe": 18.3,
      "yield": 1.54,
      "date": "2012-03-29"
  },
  "2012-03-30": {
      "equity": 5295.5,
      "debt": 8.572,
      "gold": 29844.23358,
      "pb": 3.01,
      "pe": 18.71,
      "yield": 1.5,
      "date": "2012-03-30"
  },
  "2012-03-31": {
      "equity": 5295.5,
      "debt": 8.588,
      "gold": 29844.23358,
      "pb": 3.01,
      "pe": 18.71,
      "yield": 1.5,
      "date": "2012-03-31"
  },
  "2012-04-01": {
      "equity": 5295.5,
      "debt": 8.588,
      "gold": 29844.23358,
      "pb": 3.01,
      "pe": 18.71,
      "yield": 1.5,
      "date": "2012-04-01"
  },
  "2012-04-02": {
      "equity": 5317.9,
      "debt": 8.588,
      "gold": 29864.48438,
      "pb": 3.02,
      "pe": 18.79,
      "yield": 1.5,
      "date": "2012-04-02"
  },
  "2012-04-03": {
      "equity": 5358.5,
      "debt": 8.736,
      "gold": 29877.23946,
      "pb": 3.04,
      "pe": 18.93,
      "yield": 1.49,
      "date": "2012-04-03"
  },
  "2012-04-04": {
      "equity": 5322.9,
      "debt": 8.688,
      "gold": 29455.47893,
      "pb": 3.02,
      "pe": 18.81,
      "yield": 1.5,
      "date": "2012-04-04"
  },
  "2012-04-05": {
      "equity": 5322.9,
      "debt": 8.693,
      "gold": 29288.50244,
      "pb": 3.02,
      "pe": 18.81,
      "yield": 1.5,
      "date": "2012-04-05"
  },
  "2012-04-06": {
      "equity": 5322.9,
      "debt": 8.693,
      "gold": 29288.50244,
      "pb": 3.02,
      "pe": 18.81,
      "yield": 1.5,
      "date": "2012-04-06"
  },
  "2012-04-07": {
      "equity": 5322.9,
      "debt": 8.693,
      "gold": 29288.50244,
      "pb": 3.02,
      "pe": 18.81,
      "yield": 1.5,
      "date": "2012-04-07"
  },
  "2012-04-08": {
      "equity": 5322.9,
      "debt": 8.693,
      "gold": 29288.50244,
      "pb": 3.02,
      "pe": 18.81,
      "yield": 1.5,
      "date": "2012-04-08"
  },
  "2012-04-09": {
      "equity": 5234.4,
      "debt": 8.626,
      "gold": 29288.50244,
      "pb": 2.97,
      "pe": 18.5,
      "yield": 1.52,
      "date": "2012-04-09"
  },
  "2012-04-10": {
      "equity": 5243.6,
      "debt": 8.591,
      "gold": 29944.60925,
      "pb": 2.98,
      "pe": 18.53,
      "yield": 1.52,
      "date": "2012-04-10"
  },
  "2012-04-11": {
      "equity": 5226.8,
      "debt": 8.545,
      "gold": 30061.32383,
      "pb": 2.97,
      "pe": 18.47,
      "yield": 1.52,
      "date": "2012-04-11"
  },
  "2012-04-12": {
      "equity": 5276.8,
      "debt": 8.439,
      "gold": 29954.27433,
      "pb": 3,
      "pe": 18.65,
      "yield": 1.51,
      "date": "2012-04-12"
  },
  "2012-04-13": {
      "equity": 5207.4,
      "debt": 8.468,
      "gold": 30361.20919,
      "pb": 2.96,
      "pe": 18.3,
      "yield": 1.53,
      "date": "2012-04-13"
  },
  "2012-04-14": {
      "equity": 5207.4,
      "debt": 8.471,
      "gold": 30361.20919,
      "pb": 2.96,
      "pe": 18.3,
      "yield": 1.53,
      "date": "2012-04-14"
  },
  "2012-04-15": {
      "equity": 5207.4,
      "debt": 8.471,
      "gold": 30361.20919,
      "pb": 2.96,
      "pe": 18.3,
      "yield": 1.53,
      "date": "2012-04-15"
  },
  "2012-04-16": {
      "equity": 5226.2,
      "debt": 8.455,
      "gold": 30009.14302,
      "pb": 2.98,
      "pe": 18.41,
      "yield": 1.52,
      "date": "2012-04-16"
  },
  "2012-04-17": {
      "equity": 5289.7,
      "debt": 8.341,
      "gold": 29984.18314,
      "pb": 3.01,
      "pe": 18.63,
      "yield": 1.5,
      "date": "2012-04-17"
  },
  "2012-04-18": {
      "equity": 5300,
      "debt": 8.359,
      "gold": 30099.2469,
      "pb": 3.02,
      "pe": 18.67,
      "yield": 1.5,
      "date": "2012-04-18"
  },
  "2012-04-19": {
      "equity": 5332.4,
      "debt": 8.409,
      "gold": 30190.7441,
      "pb": 3.04,
      "pe": 18.72,
      "yield": 1.49,
      "date": "2012-04-19"
  },
  "2012-04-20": {
      "equity": 5290.8,
      "debt": 8.543,
      "gold": 30130.83123,
      "pb": 3.02,
      "pe": 18.58,
      "yield": 1.5,
      "date": "2012-04-20"
  },
  "2012-04-21": {
      "equity": 5290.8,
      "debt": 8.548,
      "gold": 30130.83123,
      "pb": 3.02,
      "pe": 18.58,
      "yield": 1.5,
      "date": "2012-04-21"
  },
  "2012-04-22": {
      "equity": 5290.8,
      "debt": 8.548,
      "gold": 30130.83123,
      "pb": 3.02,
      "pe": 18.58,
      "yield": 1.5,
      "date": "2012-04-22"
  },
  "2012-04-23": {
      "equity": 5200.6,
      "debt": 8.568,
      "gold": 30323.49777,
      "pb": 2.96,
      "pe": 18.26,
      "yield": 1.53,
      "date": "2012-04-23"
  },
  "2012-04-24": {
      "equity": 5222.6,
      "debt": 8.567,
      "gold": 30443.13656,
      "pb": 2.98,
      "pe": 18.34,
      "yield": 1.52,
      "date": "2012-04-24"
  },
  "2012-04-25": {
      "equity": 5202,
      "debt": 8.628,
      "gold": 30449.05201,
      "pb": 2.96,
      "pe": 18.27,
      "yield": 1.53,
      "date": "2012-04-25"
  },
  "2012-04-26": {
      "equity": 5189,
      "debt": 8.655,
      "gold": 30514.96499,
      "pb": 2.96,
      "pe": 18.22,
      "yield": 1.53,
      "date": "2012-04-26"
  },
  "2012-04-27": {
      "equity": 5190.6,
      "debt": 8.647,
      "gold": 30633.087,
      "pb": 3.04,
      "pe": 17.93,
      "yield": 1.54,
      "date": "2012-04-27"
  },
  "2012-04-28": {
      "equity": 5209,
      "debt": 8.652,
      "gold": 30633.087,
      "pb": 3.05,
      "pe": 17.99,
      "yield": 1.54,
      "date": "2012-04-28"
  },
  "2012-04-29": {
      "equity": 5209,
      "debt": 8.652,
      "gold": 30633.087,
      "pb": 3.05,
      "pe": 17.99,
      "yield": 1.54,
      "date": "2012-04-29"
  },
  "2012-04-30": {
      "equity": 5248.1,
      "debt": 8.671,
      "gold": 30896.06871,
      "pb": 3.08,
      "pe": 18.12,
      "yield": 1.52,
      "date": "2012-04-30"
  },
  "2012-05-01": {
      "equity": 5248.1,
      "debt": 8.675,
      "gold": 30872.84079,
      "pb": 3.08,
      "pe": 18.12,
      "yield": 1.52,
      "date": "2012-05-01"
  },
  "2012-05-02": {
      "equity": 5239.1,
      "debt": 8.635,
      "gold": 30885.0985,
      "pb": 3.07,
      "pe": 17.99,
      "yield": 1.53,
      "date": "2012-05-02"
  },
  "2012-05-03": {
      "equity": 5188.4,
      "debt": 8.619,
      "gold": 31092.17447,
      "pb": 3.04,
      "pe": 17.81,
      "yield": 1.54,
      "date": "2012-05-03"
  },
  "2012-05-04": {
      "equity": 5086.8,
      "debt": 8.63,
      "gold": 30719.63527,
      "pb": 2.98,
      "pe": 17.45,
      "yield": 1.57,
      "date": "2012-05-04"
  },
  "2012-05-05": {
      "equity": 5086.8,
      "debt": 8.651,
      "gold": 30719.63527,
      "pb": 2.98,
      "pe": 17.45,
      "yield": 1.57,
      "date": "2012-05-05"
  },
  "2012-05-06": {
      "equity": 5086.8,
      "debt": 8.651,
      "gold": 30719.63527,
      "pb": 2.98,
      "pe": 17.45,
      "yield": 1.57,
      "date": "2012-05-06"
  },
  "2012-05-07": {
      "equity": 5114.1,
      "debt": 8.69,
      "gold": 30719.63527,
      "pb": 3,
      "pe": 17.54,
      "yield": 1.56,
      "date": "2012-05-07"
  },
  "2012-05-08": {
      "equity": 4999.9,
      "debt": 8.541,
      "gold": 30712.67924,
      "pb": 2.93,
      "pe": 17.15,
      "yield": 1.6,
      "date": "2012-05-08"
  },
  "2012-05-09": {
      "equity": 4974.8,
      "debt": 8.561,
      "gold": 30154.67292,
      "pb": 2.92,
      "pe": 17.03,
      "yield": 1.61,
      "date": "2012-05-09"
  },
  "2012-05-10": {
      "equity": 4965.7,
      "debt": 8.562,
      "gold": 29874.01894,
      "pb": 2.91,
      "pe": 17,
      "yield": 1.61,
      "date": "2012-05-10"
  },
  "2012-05-11": {
      "equity": 4928.9,
      "debt": 8.565,
      "gold": 29817.31953,
      "pb": 2.89,
      "pe": 16.87,
      "yield": 1.62,
      "date": "2012-05-11"
  },
  "2012-05-12": {
      "equity": 4928.9,
      "debt": 8.569,
      "gold": 29817.31953,
      "pb": 2.89,
      "pe": 16.87,
      "yield": 1.62,
      "date": "2012-05-12"
  },
  "2012-05-13": {
      "equity": 4928.9,
      "debt": 8.569,
      "gold": 29817.31953,
      "pb": 2.89,
      "pe": 16.87,
      "yield": 1.62,
      "date": "2012-05-13"
  },
  "2012-05-14": {
      "equity": 4907.8,
      "debt": 8.518,
      "gold": 29736.1188,
      "pb": 2.88,
      "pe": 16.95,
      "yield": 1.63,
      "date": "2012-05-14"
  },
  "2012-05-15": {
      "equity": 4942.8,
      "debt": 8.495,
      "gold": 29665.51791,
      "pb": 2.9,
      "pe": 17.04,
      "yield": 1.62,
      "date": "2012-05-15"
  },
  "2012-05-16": {
      "equity": 4858.2,
      "debt": 8.517,
      "gold": 29489.60652,
      "pb": 2.85,
      "pe": 16.74,
      "yield": 1.64,
      "date": "2012-05-16"
  },
  "2012-05-17": {
      "equity": 4870.2,
      "debt": 8.495,
      "gold": 29666.36449,
      "pb": 2.86,
      "pe": 16.78,
      "yield": 1.64,
      "date": "2012-05-17"
  },
  "2012-05-18": {
      "equity": 4891.4,
      "debt": 8.525,
      "gold": 30514.22424,
      "pb": 2.87,
      "pe": 16.85,
      "yield": 1.63,
      "date": "2012-05-18"
  },
  "2012-05-19": {
      "equity": 4891.4,
      "debt": 8.525,
      "gold": 30514.22424,
      "pb": 2.87,
      "pe": 16.85,
      "yield": 1.63,
      "date": "2012-05-19"
  },
  "2012-05-20": {
      "equity": 4891.4,
      "debt": 8.525,
      "gold": 30514.22424,
      "pb": 2.87,
      "pe": 16.85,
      "yield": 1.63,
      "date": "2012-05-20"
  },
  "2012-05-21": {
      "equity": 4906,
      "debt": 8.542,
      "gold": 30826.71299,
      "pb": 2.88,
      "pe": 16.62,
      "yield": 1.63,
      "date": "2012-05-21"
  },
  "2012-05-22": {
      "equity": 4860.5,
      "debt": 8.52,
      "gold": 30784.63818,
      "pb": 2.85,
      "pe": 16.46,
      "yield": 1.64,
      "date": "2012-05-22"
  },
  "2012-05-23": {
      "equity": 4835.6,
      "debt": 8.513,
      "gold": 30922.28434,
      "pb": 2.84,
      "pe": 16.38,
      "yield": 1.65,
      "date": "2012-05-23"
  },
  "2012-05-24": {
      "equity": 4921.4,
      "debt": 8.497,
      "gold": 30656.53715,
      "pb": 2.89,
      "pe": 16.69,
      "yield": 1.62,
      "date": "2012-05-24"
  },
  "2012-05-25": {
      "equity": 4920.4,
      "debt": 8.509,
      "gold": 30470.19524,
      "pb": 2.89,
      "pe": 16.68,
      "yield": 1.62,
      "date": "2012-05-25"
  },
  "2012-05-26": {
      "equity": 4920.4,
      "debt": 8.514,
      "gold": 30470.19524,
      "pb": 2.89,
      "pe": 16.68,
      "yield": 1.62,
      "date": "2012-05-26"
  },
  "2012-05-27": {
      "equity": 4920.4,
      "debt": 8.514,
      "gold": 30470.19524,
      "pb": 2.89,
      "pe": 16.68,
      "yield": 1.62,
      "date": "2012-05-27"
  },
  "2012-05-28": {
      "equity": 4985.6,
      "debt": 8.512,
      "gold": 30842.56512,
      "pb": 2.93,
      "pe": 16.9,
      "yield": 1.6,
      "date": "2012-05-28"
  },
  "2012-05-29": {
      "equity": 4990.1,
      "debt": 8.518,
      "gold": 30989.81993,
      "pb": 2.93,
      "pe": 16.9,
      "yield": 1.6,
      "date": "2012-05-29"
  },
  "2012-05-30": {
      "equity": 4950.7,
      "debt": 8.523,
      "gold": 30776.1442,
      "pb": 2.9,
      "pe": 16.75,
      "yield": 1.65,
      "date": "2012-05-30"
  },
  "2012-05-31": {
      "equity": 4924.2,
      "debt": 8.377,
      "gold": 31176.38406,
      "pb": 2.89,
      "pe": 16.66,
      "yield": 1.66,
      "date": "2012-05-31"
  },
  "2012-06-01": {
      "equity": 4841.6,
      "debt": 8.369,
      "gold": 30385.17787,
      "pb": 2.84,
      "pe": 16.36,
      "yield": 1.69,
      "date": "2012-06-01"
  },
  "2012-06-02": {
      "equity": 4841.6,
      "debt": 8.37,
      "gold": 30385.17787,
      "pb": 2.84,
      "pe": 16.36,
      "yield": 1.69,
      "date": "2012-06-02"
  },
  "2012-06-03": {
      "equity": 4841.6,
      "debt": 8.37,
      "gold": 30385.17787,
      "pb": 2.84,
      "pe": 16.36,
      "yield": 1.69,
      "date": "2012-06-03"
  },
  "2012-06-04": {
      "equity": 4848.1,
      "debt": 8.342,
      "gold": 30385.17787,
      "pb": 2.84,
      "pe": 16.28,
      "yield": 1.68,
      "date": "2012-06-04"
  },
  "2012-06-05": {
      "equity": 4863.3,
      "debt": 8.332,
      "gold": 30385.17787,
      "pb": 2.8,
      "pe": 16.33,
      "yield": 1.68,
      "date": "2012-06-05"
  },
  "2012-06-06": {
      "equity": 4997.1,
      "debt": 8.371,
      "gold": 31729.39205,
      "pb": 2.86,
      "pe": 16.78,
      "yield": 1.6,
      "date": "2012-06-06"
  },
  "2012-06-07": {
      "equity": 5049.6,
      "debt": 8.347,
      "gold": 31423.66532,
      "pb": 2.89,
      "pe": 16.96,
      "yield": 1.58,
      "date": "2012-06-07"
  },
  "2012-06-08": {
      "equity": 5068.3,
      "debt": 8.354,
      "gold": 30856.2338,
      "pb": 2.9,
      "pe": 16.83,
      "yield": 1.58,
      "date": "2012-06-08"
  },
  "2012-06-09": {
      "equity": 5068.3,
      "debt": 8.36,
      "gold": 30856.2338,
      "pb": 2.9,
      "pe": 16.83,
      "yield": 1.58,
      "date": "2012-06-09"
  },
  "2012-06-10": {
      "equity": 5068.3,
      "debt": 8.36,
      "gold": 30856.2338,
      "pb": 2.9,
      "pe": 16.83,
      "yield": 1.58,
      "date": "2012-06-10"
  },
  "2012-06-11": {
      "equity": 5054.1,
      "debt": 8.329,
      "gold": 31363.26919,
      "pb": 2.89,
      "pe": 16.79,
      "yield": 1.58,
      "date": "2012-06-11"
  },
  "2012-06-12": {
      "equity": 5115.9,
      "debt": 8.294,
      "gold": 31205.34754,
      "pb": 2.93,
      "pe": 16.99,
      "yield": 1.56,
      "date": "2012-06-12"
  },
  "2012-06-13": {
      "equity": 5121.4,
      "debt": 8.292,
      "gold": 31621.26669,
      "pb": 2.93,
      "pe": 16.99,
      "yield": 1.56,
      "date": "2012-06-13"
  },
  "2012-06-14": {
      "equity": 5054.7,
      "debt": 8.326,
      "gold": 31818.05323,
      "pb": 2.89,
      "pe": 16.77,
      "yield": 1.58,
      "date": "2012-06-14"
  },
  "2012-06-15": {
      "equity": 5139,
      "debt": 8.336,
      "gold": 31813.25597,
      "pb": 2.94,
      "pe": 17.05,
      "yield": 1.55,
      "date": "2012-06-15"
  },
  "2012-06-16": {
      "equity": 5139,
      "debt": 8.338,
      "gold": 31813.25597,
      "pb": 2.94,
      "pe": 17.05,
      "yield": 1.55,
      "date": "2012-06-16"
  },
  "2012-06-17": {
      "equity": 5139,
      "debt": 8.338,
      "gold": 31813.25597,
      "pb": 2.94,
      "pe": 17.05,
      "yield": 1.55,
      "date": "2012-06-17"
  },
  "2012-06-18": {
      "equity": 5064.2,
      "debt": 8.428,
      "gold": 32038.20526,
      "pb": 2.9,
      "pe": 16.8,
      "yield": 1.58,
      "date": "2012-06-18"
  },
  "2012-06-19": {
      "equity": 5103.8,
      "debt": 8.388,
      "gold": 32079.42997,
      "pb": 2.92,
      "pe": 16.93,
      "yield": 1.57,
      "date": "2012-06-19"
  },
  "2012-06-20": {
      "equity": 5120.5,
      "debt": 8.388,
      "gold": 32127.18743,
      "pb": 2.93,
      "pe": 16.98,
      "yield": 1.56,
      "date": "2012-06-20"
  },
  "2012-06-21": {
      "equity": 5165,
      "debt": 8.343,
      "gold": 32082.40004,
      "pb": 2.96,
      "pe": 17.13,
      "yield": 1.55,
      "date": "2012-06-21"
  },
  "2012-06-22": {
      "equity": 5146,
      "debt": 8.346,
      "gold": 31670.92541,
      "pb": 2.94,
      "pe": 17.07,
      "yield": 1.55,
      "date": "2012-06-22"
  },
  "2012-06-23": {
      "equity": 5146,
      "debt": 8.349,
      "gold": 31670.92541,
      "pb": 2.94,
      "pe": 17.07,
      "yield": 1.55,
      "date": "2012-06-23"
  },
  "2012-06-24": {
      "equity": 5146,
      "debt": 8.349,
      "gold": 31670.92541,
      "pb": 2.94,
      "pe": 17.07,
      "yield": 1.55,
      "date": "2012-06-24"
  },
  "2012-06-25": {
      "equity": 5114.6,
      "debt": 8.329,
      "gold": 31604.70202,
      "pb": 2.93,
      "pe": 16.96,
      "yield": 1.56,
      "date": "2012-06-25"
  },
  "2012-06-26": {
      "equity": 5120.8,
      "debt": 8.343,
      "gold": 31858.23383,
      "pb": 2.93,
      "pe": 16.99,
      "yield": 1.56,
      "date": "2012-06-26"
  },
  "2012-06-27": {
      "equity": 5141.9,
      "debt": 8.355,
      "gold": 31486.01915,
      "pb": 2.94,
      "pe": 17.06,
      "yield": 1.55,
      "date": "2012-06-27"
  },
  "2012-06-28": {
      "equity": 5149.1,
      "debt": 8.364,
      "gold": 31518.68992,
      "pb": 2.95,
      "pe": 17.08,
      "yield": 1.55,
      "date": "2012-06-28"
  },
  "2012-06-29": {
      "equity": 5278.9,
      "debt": 8.38,
      "gold": 30740.0448,
      "pb": 3.02,
      "pe": 17.51,
      "yield": 1.51,
      "date": "2012-06-29"
  },
  "2012-06-30": {
      "equity": 5278.9,
      "debt": 8.39,
      "gold": 30740.0448,
      "pb": 3.02,
      "pe": 17.51,
      "yield": 1.51,
      "date": "2012-06-30"
  },
  "2012-07-01": {
      "equity": 5278.9,
      "debt": 8.39,
      "gold": 30740.0448,
      "pb": 3.02,
      "pe": 17.51,
      "yield": 1.51,
      "date": "2012-07-01"
  },
  "2012-07-02": {
      "equity": 5278.6,
      "debt": 8.383,
      "gold": 31210.47638,
      "pb": 3.02,
      "pe": 17.51,
      "yield": 1.51,
      "date": "2012-07-02"
  },
  "2012-07-03": {
      "equity": 5287.9,
      "debt": 8.178,
      "gold": 30777.64334,
      "pb": 3.03,
      "pe": 17.54,
      "yield": 1.51,
      "date": "2012-07-03"
  },
  "2012-07-04": {
      "equity": 5302.5,
      "debt": 8.159,
      "gold": 31151.32895,
      "pb": 3.03,
      "pe": 17.59,
      "yield": 1.51,
      "date": "2012-07-04"
  },
  "2012-07-05": {
      "equity": 5327.3,
      "debt": 8.175,
      "gold": 31537.16291,
      "pb": 3.05,
      "pe": 17.67,
      "yield": 1.5,
      "date": "2012-07-05"
  },
  "2012-07-06": {
      "equity": 5316.9,
      "debt": 8.154,
      "gold": 31344.27062,
      "pb": 3.04,
      "pe": 17.64,
      "yield": 1.5,
      "date": "2012-07-06"
  },
  "2012-07-07": {
      "equity": 5316.9,
      "debt": 8.157,
      "gold": 31344.27062,
      "pb": 3.04,
      "pe": 17.64,
      "yield": 1.5,
      "date": "2012-07-07"
  },
  "2012-07-08": {
      "equity": 5316.9,
      "debt": 8.157,
      "gold": 31344.27062,
      "pb": 3.04,
      "pe": 17.64,
      "yield": 1.5,
      "date": "2012-07-08"
  },
  "2012-07-09": {
      "equity": 5275.1,
      "debt": 8.148,
      "gold": 31127.01106,
      "pb": 3.02,
      "pe": 17.5,
      "yield": 1.51,
      "date": "2012-07-09"
  },
  "2012-07-10": {
      "equity": 5345.3,
      "debt": 8.151,
      "gold": 31195.94349,
      "pb": 3.06,
      "pe": 17.74,
      "yield": 1.49,
      "date": "2012-07-10"
  },
  "2012-07-11": {
      "equity": 5306.3,
      "debt": 8.142,
      "gold": 30777.04721,
      "pb": 3.04,
      "pe": 17.61,
      "yield": 1.51,
      "date": "2012-07-11"
  },
  "2012-07-12": {
      "equity": 5235.2,
      "debt": 8.104,
      "gold": 30772.14413,
      "pb": 3,
      "pe": 17.37,
      "yield": 1.53,
      "date": "2012-07-12"
  },
  "2012-07-13": {
      "equity": 5227.2,
      "debt": 8.096,
      "gold": 30630.91413,
      "pb": 2.99,
      "pe": 17.2,
      "yield": 1.53,
      "date": "2012-07-13"
  },
  "2012-07-14": {
      "equity": 5227.2,
      "debt": 8.101,
      "gold": 30630.91413,
      "pb": 2.99,
      "pe": 17.2,
      "yield": 1.53,
      "date": "2012-07-14"
  },
  "2012-07-15": {
      "equity": 5227.2,
      "debt": 8.101,
      "gold": 30630.91413,
      "pb": 2.99,
      "pe": 17.2,
      "yield": 1.53,
      "date": "2012-07-15"
  },
  "2012-07-16": {
      "equity": 5197.2,
      "debt": 8.049,
      "gold": 30711.14482,
      "pb": 2.98,
      "pe": 17.05,
      "yield": 1.54,
      "date": "2012-07-16"
  },
  "2012-07-17": {
      "equity": 5192.8,
      "debt": 8.09,
      "gold": 31014.43764,
      "pb": 2.97,
      "pe": 17.04,
      "yield": 1.54,
      "date": "2012-07-17"
  },
  "2012-07-18": {
      "equity": 5216.3,
      "debt": 8.066,
      "gold": 30824.47309,
      "pb": 2.99,
      "pe": 17.11,
      "yield": 1.53,
      "date": "2012-07-18"
  },
  "2012-07-19": {
      "equity": 5242.7,
      "debt": 8.085,
      "gold": 30678.17775,
      "pb": 3,
      "pe": 17.18,
      "yield": 1.52,
      "date": "2012-07-19"
  },
  "2012-07-20": {
      "equity": 5205.1,
      "debt": 8.075,
      "gold": 30864.85829,
      "pb": 2.98,
      "pe": 17.08,
      "yield": 1.53,
      "date": "2012-07-20"
  },
  "2012-07-21": {
      "equity": 5205.1,
      "debt": 8.079,
      "gold": 30864.85829,
      "pb": 2.98,
      "pe": 17.08,
      "yield": 1.53,
      "date": "2012-07-21"
  },
  "2012-07-22": {
      "equity": 5205.1,
      "debt": 8.079,
      "gold": 30864.85829,
      "pb": 2.98,
      "pe": 17.08,
      "yield": 1.53,
      "date": "2012-07-22"
  },
  "2012-07-23": {
      "equity": 5117.9,
      "debt": 8.066,
      "gold": 31089.64179,
      "pb": 2.93,
      "pe": 16.9,
      "yield": 1.56,
      "date": "2012-07-23"
  },
  "2012-07-24": {
      "equity": 5128.2,
      "debt": 8.07,
      "gold": 31208.09538,
      "pb": 2.94,
      "pe": 16.86,
      "yield": 1.56,
      "date": "2012-07-24"
  },
  "2012-07-25": {
      "equity": 5109.6,
      "debt": 8.101,
      "gold": 31417.37597,
      "pb": 2.89,
      "pe": 16.8,
      "yield": 1.58,
      "date": "2012-07-25"
  },
  "2012-07-26": {
      "equity": 5043,
      "debt": 8.113,
      "gold": 31486.6435,
      "pb": 2.85,
      "pe": 16.62,
      "yield": 1.6,
      "date": "2012-07-26"
  },
  "2012-07-27": {
      "equity": 5099.8,
      "debt": 8.119,
      "gold": 31599.01586,
      "pb": 2.89,
      "pe": 16.68,
      "yield": 1.58,
      "date": "2012-07-27"
  },
  "2012-07-28": {
      "equity": 5099.8,
      "debt": 8.119,
      "gold": 31599.01586,
      "pb": 2.89,
      "pe": 16.68,
      "yield": 1.58,
      "date": "2012-07-28"
  },
  "2012-07-29": {
      "equity": 5099.8,
      "debt": 8.119,
      "gold": 31599.01586,
      "pb": 2.89,
      "pe": 16.68,
      "yield": 1.58,
      "date": "2012-07-29"
  },
  "2012-07-30": {
      "equity": 5199.8,
      "debt": 8.148,
      "gold": 31614.96323,
      "pb": 2.94,
      "pe": 17,
      "yield": 1.55,
      "date": "2012-07-30"
  },
  "2012-07-31": {
      "equity": 5229,
      "debt": 8.245,
      "gold": 31886.02621,
      "pb": 2.96,
      "pe": 17.09,
      "yield": 1.54,
      "date": "2012-07-31"
  },
  "2012-08-01": {
      "equity": 5240.5,
      "debt": 8.23,
      "gold": 31751.61467,
      "pb": 2.97,
      "pe": 17.13,
      "yield": 1.54,
      "date": "2012-08-01"
  },
  "2012-08-02": {
      "equity": 5227.7,
      "debt": 8.219,
      "gold": 31816.07083,
      "pb": 2.96,
      "pe": 17.07,
      "yield": 1.54,
      "date": "2012-08-02"
  },
  "2012-08-03": {
      "equity": 5215.7,
      "debt": 8.256,
      "gold": 31194.47609,
      "pb": 2.95,
      "pe": 17.03,
      "yield": 1.54,
      "date": "2012-08-03"
  },
  "2012-08-04": {
      "equity": 5215.7,
      "debt": 8.256,
      "gold": 31194.47609,
      "pb": 2.95,
      "pe": 17.03,
      "yield": 1.54,
      "date": "2012-08-04"
  },
  "2012-08-05": {
      "equity": 5215.7,
      "debt": 8.256,
      "gold": 31194.47609,
      "pb": 2.95,
      "pe": 17.03,
      "yield": 1.54,
      "date": "2012-08-05"
  },
  "2012-08-06": {
      "equity": 5282.5,
      "debt": 8.222,
      "gold": 31316.59112,
      "pb": 2.99,
      "pe": 17.25,
      "yield": 1.52,
      "date": "2012-08-06"
  },
  "2012-08-07": {
      "equity": 5336.7,
      "debt": 8.149,
      "gold": 31267.71901,
      "pb": 3.02,
      "pe": 17.43,
      "yield": 1.51,
      "date": "2012-08-07"
  },
  "2012-08-08": {
      "equity": 5338,
      "debt": 8.138,
      "gold": 31236.43803,
      "pb": 3.02,
      "pe": 17.43,
      "yield": 1.51,
      "date": "2012-08-08"
  },
  "2012-08-09": {
      "equity": 5322.9,
      "debt": 8.141,
      "gold": 31382.33479,
      "pb": 3.01,
      "pe": 17.35,
      "yield": 1.51,
      "date": "2012-08-09"
  },
  "2012-08-10": {
      "equity": 5320.4,
      "debt": 8.164,
      "gold": 31310.98256,
      "pb": 3.01,
      "pe": 17.34,
      "yield": 1.51,
      "date": "2012-08-10"
  },
  "2012-08-11": {
      "equity": 5320.4,
      "debt": 8.17,
      "gold": 31310.98256,
      "pb": 3.01,
      "pe": 17.34,
      "yield": 1.51,
      "date": "2012-08-11"
  },
  "2012-08-12": {
      "equity": 5320.4,
      "debt": 8.17,
      "gold": 31310.98256,
      "pb": 3.01,
      "pe": 17.34,
      "yield": 1.51,
      "date": "2012-08-12"
  },
  "2012-08-13": {
      "equity": 5347.9,
      "debt": 8.204,
      "gold": 31784.64523,
      "pb": 3.03,
      "pe": 17.51,
      "yield": 1.5,
      "date": "2012-08-13"
  },
  "2012-08-14": {
      "equity": 5380.3,
      "debt": 8.219,
      "gold": 31809.34408,
      "pb": 3.04,
      "pe": 17.88,
      "yield": 1.52,
      "date": "2012-08-14"
  },
  "2012-08-15": {
      "equity": 5380.3,
      "debt": 8.221,
      "gold": 31420.22258,
      "pb": 3.04,
      "pe": 17.88,
      "yield": 1.52,
      "date": "2012-08-15"
  },
  "2012-08-16": {
      "equity": 5362.9,
      "debt": 8.265,
      "gold": 31451.21431,
      "pb": 2.99,
      "pe": 17.97,
      "yield": 1.51,
      "date": "2012-08-16"
  },
  "2012-08-17": {
      "equity": 5366.3,
      "debt": 8.237,
      "gold": 31751.8122,
      "pb": 2.92,
      "pe": 17.97,
      "yield": 1.54,
      "date": "2012-08-17"
  },
  "2012-08-18": {
      "equity": 5366.3,
      "debt": 8.237,
      "gold": 31751.8122,
      "pb": 2.92,
      "pe": 17.97,
      "yield": 1.54,
      "date": "2012-08-18"
  },
  "2012-08-19": {
      "equity": 5366.3,
      "debt": 8.237,
      "gold": 31751.8122,
      "pb": 2.92,
      "pe": 17.97,
      "yield": 1.54,
      "date": "2012-08-19"
  },
  "2012-08-20": {
      "equity": 5366.3,
      "debt": 8.237,
      "gold": 31727.2615,
      "pb": 2.92,
      "pe": 17.97,
      "yield": 1.54,
      "date": "2012-08-20"
  },
  "2012-08-21": {
      "equity": 5421,
      "debt": 8.245,
      "gold": 31647.07667,
      "pb": 2.95,
      "pe": 18.19,
      "yield": 1.53,
      "date": "2012-08-21"
  },
  "2012-08-22": {
      "equity": 5412.8,
      "debt": 8.221,
      "gold": 32038.05358,
      "pb": 2.95,
      "pe": 18.16,
      "yield": 1.53,
      "date": "2012-08-22"
  },
  "2012-08-23": {
      "equity": 5415.3,
      "debt": 8.228,
      "gold": 32338.68675,
      "pb": 2.95,
      "pe": 18.17,
      "yield": 1.53,
      "date": "2012-08-23"
  },
  "2012-08-24": {
      "equity": 5386.7,
      "debt": 8.207,
      "gold": 32551.69932,
      "pb": 2.94,
      "pe": 18.07,
      "yield": 1.54,
      "date": "2012-08-24"
  },
  "2012-08-25": {
      "equity": 5386.7,
      "debt": 8.21,
      "gold": 32551.69932,
      "pb": 2.94,
      "pe": 18.07,
      "yield": 1.54,
      "date": "2012-08-25"
  },
  "2012-08-26": {
      "equity": 5386.7,
      "debt": 8.21,
      "gold": 32551.69932,
      "pb": 2.94,
      "pe": 18.07,
      "yield": 1.54,
      "date": "2012-08-26"
  },
  "2012-08-27": {
      "equity": 5350.2,
      "debt": 8.186,
      "gold": 32551.69932,
      "pb": 2.92,
      "pe": 17.95,
      "yield": 1.55,
      "date": "2012-08-27"
  },
  "2012-08-28": {
      "equity": 5334.6,
      "debt": 8.174,
      "gold": 32651.53177,
      "pb": 2.91,
      "pe": 17.9,
      "yield": 1.55,
      "date": "2012-08-28"
  },
  "2012-08-29": {
      "equity": 5287.8,
      "debt": 8.177,
      "gold": 32654.51243,
      "pb": 2.88,
      "pe": 17.74,
      "yield": 1.56,
      "date": "2012-08-29"
  },
  "2012-08-30": {
      "equity": 5315,
      "debt": 8.194,
      "gold": 32599.93298,
      "pb": 2.9,
      "pe": 17.83,
      "yield": 1.56,
      "date": "2012-08-30"
  },
  "2012-08-31": {
      "equity": 5258.5,
      "debt": 8.241,
      "gold": 32404.17644,
      "pb": 2.87,
      "pe": 17.64,
      "yield": 1.57,
      "date": "2012-08-31"
  },
  "2012-09-01": {
      "equity": 5258.5,
      "debt": 8.241,
      "gold": 32404.17644,
      "pb": 2.87,
      "pe": 17.64,
      "yield": 1.57,
      "date": "2012-09-01"
  },
  "2012-09-02": {
      "equity": 5258.5,
      "debt": 8.241,
      "gold": 32404.17644,
      "pb": 2.87,
      "pe": 17.64,
      "yield": 1.57,
      "date": "2012-09-02"
  },
  "2012-09-03": {
      "equity": 5253.7,
      "debt": 8.215,
      "gold": 32980.16896,
      "pb": 2.86,
      "pe": 17.63,
      "yield": 1.58,
      "date": "2012-09-03"
  },
  "2012-09-04": {
      "equity": 5274,
      "debt": 8.212,
      "gold": 33242.88612,
      "pb": 2.87,
      "pe": 17.69,
      "yield": 1.57,
      "date": "2012-09-04"
  },
  "2012-09-05": {
      "equity": 5225.7,
      "debt": 8.179,
      "gold": 33304.89427,
      "pb": 2.85,
      "pe": 17.53,
      "yield": 1.58,
      "date": "2012-09-05"
  },
  "2012-09-06": {
      "equity": 5238.4,
      "debt": 8.159,
      "gold": 33589.04037,
      "pb": 2.85,
      "pe": 17.58,
      "yield": 1.58,
      "date": "2012-09-06"
  },
  "2012-09-07": {
      "equity": 5342.1,
      "debt": 8.2,
      "gold": 33002.28928,
      "pb": 2.91,
      "pe": 17.92,
      "yield": 1.55,
      "date": "2012-09-07"
  },
  "2012-09-08": {
      "equity": 5358.7,
      "debt": 8.201,
      "gold": 33002.28928,
      "pb": 2.92,
      "pe": 17.98,
      "yield": 1.54,
      "date": "2012-09-08"
  },
  "2012-09-09": {
      "equity": 5358.7,
      "debt": 8.201,
      "gold": 33002.28928,
      "pb": 2.92,
      "pe": 17.98,
      "yield": 1.54,
      "date": "2012-09-09"
  },
  "2012-09-10": {
      "equity": 5363.4,
      "debt": 8.177,
      "gold": 33839.64444,
      "pb": 2.92,
      "pe": 17.99,
      "yield": 1.54,
      "date": "2012-09-10"
  },
  "2012-09-11": {
      "equity": 5390,
      "debt": 8.183,
      "gold": 33707.77615,
      "pb": 2.94,
      "pe": 18.11,
      "yield": 1.53,
      "date": "2012-09-11"
  },
  "2012-09-12": {
      "equity": 5431,
      "debt": 8.198,
      "gold": 33998.0564,
      "pb": 2.96,
      "pe": 18.24,
      "yield": 1.52,
      "date": "2012-09-12"
  },
  "2012-09-13": {
      "equity": 5435.3,
      "debt": 8.174,
      "gold": 33630.89296,
      "pb": 2.97,
      "pe": 18.26,
      "yield": 1.52,
      "date": "2012-09-13"
  },
  "2012-09-14": {
      "equity": 5577.6,
      "debt": 8.177,
      "gold": 33734.36569,
      "pb": 3.04,
      "pe": 18.74,
      "yield": 1.48,
      "date": "2012-09-14"
  },
  "2012-09-15": {
      "equity": 5577.6,
      "debt": 8.18,
      "gold": 33734.36569,
      "pb": 3.04,
      "pe": 18.74,
      "yield": 1.48,
      "date": "2012-09-15"
  },
  "2012-09-16": {
      "equity": 5577.6,
      "debt": 8.18,
      "gold": 33734.36569,
      "pb": 3.04,
      "pe": 18.74,
      "yield": 1.48,
      "date": "2012-09-16"
  },
  "2012-09-17": {
      "equity": 5610,
      "debt": 8.18,
      "gold": 33609.50987,
      "pb": 3.06,
      "pe": 18.85,
      "yield": 1.47,
      "date": "2012-09-17"
  },
  "2012-09-18": {
      "equity": 5600,
      "debt": 8.165,
      "gold": 33626.70947,
      "pb": 3.06,
      "pe": 18.81,
      "yield": 1.48,
      "date": "2012-09-18"
  },
  "2012-09-19": {
      "equity": 5600,
      "debt": 8.168,
      "gold": 33816.24367,
      "pb": 3.06,
      "pe": 18.81,
      "yield": 1.48,
      "date": "2012-09-19"
  },
  "2012-09-20": {
      "equity": 5554.2,
      "debt": 8.16,
      "gold": 33664.08579,
      "pb": 3.03,
      "pe": 18.66,
      "yield": 1.49,
      "date": "2012-09-20"
  },
  "2012-09-21": {
      "equity": 5691.1,
      "debt": 8.165,
      "gold": 33338.95483,
      "pb": 3.1,
      "pe": 19.12,
      "yield": 1.45,
      "date": "2012-09-21"
  },
  "2012-09-22": {
      "equity": 5691.1,
      "debt": 8.167,
      "gold": 33338.95483,
      "pb": 3.1,
      "pe": 19.12,
      "yield": 1.45,
      "date": "2012-09-22"
  },
  "2012-09-23": {
      "equity": 5691.1,
      "debt": 8.167,
      "gold": 33338.95483,
      "pb": 3.1,
      "pe": 19.12,
      "yield": 1.45,
      "date": "2012-09-23"
  },
  "2012-09-24": {
      "equity": 5669.6,
      "debt": 8.155,
      "gold": 33170.17584,
      "pb": 3.09,
      "pe": 19.05,
      "yield": 1.46,
      "date": "2012-09-24"
  },
  "2012-09-25": {
      "equity": 5673.9,
      "debt": 8.168,
      "gold": 33219.84867,
      "pb": 3.1,
      "pe": 19.06,
      "yield": 1.46,
      "date": "2012-09-25"
  },
  "2012-09-26": {
      "equity": 5663.4,
      "debt": 8.168,
      "gold": 33350.08378,
      "pb": 3.09,
      "pe": 19.02,
      "yield": 1.46,
      "date": "2012-09-26"
  },
  "2012-09-27": {
      "equity": 5649.5,
      "debt": 8.162,
      "gold": 32824.06392,
      "pb": 3.06,
      "pe": 18.98,
      "yield": 1.46,
      "date": "2012-09-27"
  },
  "2012-09-28": {
      "equity": 5703.3,
      "debt": 8.149,
      "gold": 33205.08298,
      "pb": 3.18,
      "pe": 19.17,
      "yield": 1.42,
      "date": "2012-09-28"
  },
  "2012-09-29": {
      "equity": 5703.3,
      "debt": 8.152,
      "gold": 33205.08298,
      "pb": 3.18,
      "pe": 19.17,
      "yield": 1.42,
      "date": "2012-09-29"
  },
  "2012-09-30": {
      "equity": 5703.3,
      "debt": 8.152,
      "gold": 33205.08298,
      "pb": 3.18,
      "pe": 19.17,
      "yield": 1.42,
      "date": "2012-09-30"
  },
  "2012-10-01": {
      "equity": 5718.8,
      "debt": 8.161,
      "gold": 32728.28445,
      "pb": 3.19,
      "pe": 19.22,
      "yield": 1.41,
      "date": "2012-10-01"
  },
  "2012-10-02": {
      "equity": 5718.8,
      "debt": 8.161,
      "gold": 32913.808,
      "pb": 3.19,
      "pe": 19.22,
      "yield": 1.41,
      "date": "2012-10-02"
  },
  "2012-10-03": {
      "equity": 5731.2,
      "debt": 8.147,
      "gold": 32759.02573,
      "pb": 3.19,
      "pe": 19.27,
      "yield": 1.41,
      "date": "2012-10-03"
  },
  "2012-10-04": {
      "equity": 5787.6,
      "debt": 8.149,
      "gold": 32488.4072,
      "pb": 3.2,
      "pe": 19.45,
      "yield": 1.4,
      "date": "2012-10-04"
  },
  "2012-10-05": {
      "equity": 5746.9,
      "debt": 8.159,
      "gold": 32735.16288,
      "pb": 3.18,
      "pe": 19.32,
      "yield": 1.41,
      "date": "2012-10-05"
  },
  "2012-10-06": {
      "equity": 5746.9,
      "debt": 8.162,
      "gold": 32735.16288,
      "pb": 3.18,
      "pe": 19.32,
      "yield": 1.41,
      "date": "2012-10-06"
  },
  "2012-10-07": {
      "equity": 5746.9,
      "debt": 8.162,
      "gold": 32735.16288,
      "pb": 3.18,
      "pe": 19.32,
      "yield": 1.41,
      "date": "2012-10-07"
  },
  "2012-10-08": {
      "equity": 5676,
      "debt": 8.149,
      "gold": 32850.31835,
      "pb": 3.14,
      "pe": 19.08,
      "yield": 1.43,
      "date": "2012-10-08"
  },
  "2012-10-09": {
      "equity": 5704.6,
      "debt": 8.143,
      "gold": 32957.76645,
      "pb": 3.16,
      "pe": 19.18,
      "yield": 1.42,
      "date": "2012-10-09"
  },
  "2012-10-10": {
      "equity": 5652.1,
      "debt": 8.155,
      "gold": 32990.75821,
      "pb": 3.13,
      "pe": 19,
      "yield": 1.43,
      "date": "2012-10-10"
  },
  "2012-10-11": {
      "equity": 5708,
      "debt": 8.162,
      "gold": 32836.51916,
      "pb": 3.16,
      "pe": 19.19,
      "yield": 1.42,
      "date": "2012-10-11"
  },
  "2012-10-12": {
      "equity": 5676,
      "debt": 8.169,
      "gold": 32969.00122,
      "pb": 3.14,
      "pe": 19,
      "yield": 1.43,
      "date": "2012-10-12"
  },
  "2012-10-13": {
      "equity": 5676,
      "debt": 8.17,
      "gold": 32969.00122,
      "pb": 3.14,
      "pe": 19,
      "yield": 1.43,
      "date": "2012-10-13"
  },
  "2012-10-14": {
      "equity": 5676,
      "debt": 8.17,
      "gold": 32969.00122,
      "pb": 3.14,
      "pe": 19,
      "yield": 1.43,
      "date": "2012-10-14"
  },
  "2012-10-15": {
      "equity": 5687.2,
      "debt": 8.169,
      "gold": 32717.60349,
      "pb": 3.15,
      "pe": 19.04,
      "yield": 1.42,
      "date": "2012-10-15"
  },
  "2012-10-16": {
      "equity": 5648,
      "debt": 8.152,
      "gold": 32406.32463,
      "pb": 3.11,
      "pe": 18.86,
      "yield": 1.41,
      "date": "2012-10-16"
  },
  "2012-10-17": {
      "equity": 5660.2,
      "debt": 8.152,
      "gold": 32603.66497,
      "pb": 3.11,
      "pe": 18.88,
      "yield": 1.4,
      "date": "2012-10-17"
  },
  "2012-10-18": {
      "equity": 5718.7,
      "debt": 8.135,
      "gold": 32972.11591,
      "pb": 3.14,
      "pe": 19.07,
      "yield": 1.39,
      "date": "2012-10-18"
  },
  "2012-10-19": {
      "equity": 5684.2,
      "debt": 8.128,
      "gold": 33063.40853,
      "pb": 3.12,
      "pe": 18.94,
      "yield": 1.4,
      "date": "2012-10-19"
  },
  "2012-10-20": {
      "equity": 5684.2,
      "debt": 8.129,
      "gold": 33063.40853,
      "pb": 3.12,
      "pe": 18.94,
      "yield": 1.4,
      "date": "2012-10-20"
  },
  "2012-10-21": {
      "equity": 5684.2,
      "debt": 8.129,
      "gold": 33063.40853,
      "pb": 3.12,
      "pe": 18.94,
      "yield": 1.4,
      "date": "2012-10-21"
  },
  "2012-10-22": {
      "equity": 5717.1,
      "debt": 8.132,
      "gold": 32586.95215,
      "pb": 3.14,
      "pe": 18.88,
      "yield": 1.39,
      "date": "2012-10-22"
  },
  "2012-10-23": {
      "equity": 5691.4,
      "debt": 8.135,
      "gold": 32659.9164,
      "pb": 3.11,
      "pe": 18.77,
      "yield": 1.42,
      "date": "2012-10-23"
  },
  "2012-10-24": {
      "equity": 5691.4,
      "debt": 8.137,
      "gold": 32498.23101,
      "pb": 3.11,
      "pe": 18.77,
      "yield": 1.42,
      "date": "2012-10-24"
  },
  "2012-10-25": {
      "equity": 5705.3,
      "debt": 8.128,
      "gold": 32525.08157,
      "pb": 3.09,
      "pe": 18.81,
      "yield": 1.41,
      "date": "2012-10-25"
  },
  "2012-10-26": {
      "equity": 5664.3,
      "debt": 8.13,
      "gold": 32316.46414,
      "pb": 3.06,
      "pe": 18.57,
      "yield": 1.42,
      "date": "2012-10-26"
  },
  "2012-10-27": {
      "equity": 5664.3,
      "debt": 8.13,
      "gold": 32316.46414,
      "pb": 3.06,
      "pe": 18.57,
      "yield": 1.42,
      "date": "2012-10-27"
  },
  "2012-10-28": {
      "equity": 5664.3,
      "debt": 8.13,
      "gold": 32316.46414,
      "pb": 3.06,
      "pe": 18.57,
      "yield": 1.42,
      "date": "2012-10-28"
  },
  "2012-10-29": {
      "equity": 5665.6,
      "debt": 8.133,
      "gold": 32721.81873,
      "pb": 3.06,
      "pe": 18.58,
      "yield": 1.42,
      "date": "2012-10-29"
  },
  "2012-10-30": {
      "equity": 5597.9,
      "debt": 8.181,
      "gold": 32647.73629,
      "pb": 3.02,
      "pe": 18.36,
      "yield": 1.44,
      "date": "2012-10-30"
  },
  "2012-10-31": {
      "equity": 5619.7,
      "debt": 8.215,
      "gold": 32636.51564,
      "pb": 3.03,
      "pe": 18.4,
      "yield": 1.44,
      "date": "2012-10-31"
  },
  "2012-11-01": {
      "equity": 5645,
      "debt": 8.191,
      "gold": 32632.91416,
      "pb": 3.05,
      "pe": 18.46,
      "yield": 1.43,
      "date": "2012-11-01"
  },
  "2012-11-02": {
      "equity": 5697.7,
      "debt": 8.197,
      "gold": 32445.27064,
      "pb": 3.08,
      "pe": 18.6,
      "yield": 1.42,
      "date": "2012-11-02"
  },
  "2012-11-03": {
      "equity": 5697.7,
      "debt": 8.198,
      "gold": 32445.27064,
      "pb": 3.08,
      "pe": 18.6,
      "yield": 1.42,
      "date": "2012-11-03"
  },
  "2012-11-04": {
      "equity": 5697.7,
      "debt": 8.198,
      "gold": 32445.27064,
      "pb": 3.08,
      "pe": 18.6,
      "yield": 1.42,
      "date": "2012-11-04"
  },
  "2012-11-05": {
      "equity": 5704.2,
      "debt": 8.195,
      "gold": 32322.06212,
      "pb": 3.06,
      "pe": 18.62,
      "yield": 1.43,
      "date": "2012-11-05"
  },
  "2012-11-06": {
      "equity": 5724.4,
      "debt": 8.183,
      "gold": 32483.96268,
      "pb": 3.07,
      "pe": 18.66,
      "yield": 1.43,
      "date": "2012-11-06"
  },
  "2012-11-07": {
      "equity": 5760.1,
      "debt": 8.191,
      "gold": 33191.39315,
      "pb": 3.08,
      "pe": 18.78,
      "yield": 1.43,
      "date": "2012-11-07"
  },
  "2012-11-08": {
      "equity": 5738.7,
      "debt": 8.191,
      "gold": 32930.39736,
      "pb": 3.06,
      "pe": 18.61,
      "yield": 1.43,
      "date": "2012-11-08"
  },
  "2012-11-09": {
      "equity": 5686.2,
      "debt": 8.215,
      "gold": 33424.02159,
      "pb": 3.03,
      "pe": 18.43,
      "yield": 1.45,
      "date": "2012-11-09"
  },
  "2012-11-10": {
      "equity": 5686.2,
      "debt": 8.215,
      "gold": 33424.02159,
      "pb": 3.03,
      "pe": 18.43,
      "yield": 1.45,
      "date": "2012-11-10"
  },
  "2012-11-11": {
      "equity": 5686.2,
      "debt": 8.215,
      "gold": 33424.02159,
      "pb": 3.03,
      "pe": 18.43,
      "yield": 1.45,
      "date": "2012-11-11"
  },
  "2012-11-12": {
      "equity": 5683.7,
      "debt": 8.211,
      "gold": 33656.389,
      "pb": 3.02,
      "pe": 17.95,
      "yield": 1.44,
      "date": "2012-11-12"
  },
  "2012-11-13": {
      "equity": 5666.9,
      "debt": 8.213,
      "gold": 33443.09776,
      "pb": 3.02,
      "pe": 17.9,
      "yield": 1.45,
      "date": "2012-11-13"
  },
  "2012-11-14": {
      "equity": 5666.9,
      "debt": 8.213,
      "gold": 33438.25112,
      "pb": 3.02,
      "pe": 17.9,
      "yield": 1.45,
      "date": "2012-11-14"
  },
  "2012-11-15": {
      "equity": 5631,
      "debt": 8.19,
      "gold": 33309.4305,
      "pb": 3,
      "pe": 17.78,
      "yield": 1.45,
      "date": "2012-11-15"
  },
  "2012-11-16": {
      "equity": 5574,
      "debt": 8.193,
      "gold": 33298.84125,
      "pb": 2.97,
      "pe": 17.62,
      "yield": 1.47,
      "date": "2012-11-16"
  },
  "2012-11-17": {
      "equity": 5574,
      "debt": 8.193,
      "gold": 33298.84125,
      "pb": 2.97,
      "pe": 17.62,
      "yield": 1.47,
      "date": "2012-11-17"
  },
  "2012-11-18": {
      "equity": 5574,
      "debt": 8.193,
      "gold": 33298.84125,
      "pb": 2.97,
      "pe": 17.62,
      "yield": 1.47,
      "date": "2012-11-18"
  },
  "2012-11-19": {
      "equity": 5571.4,
      "debt": 8.191,
      "gold": 33338.03065,
      "pb": 2.97,
      "pe": 17.61,
      "yield": 1.47,
      "date": "2012-11-19"
  },
  "2012-11-20": {
      "equity": 5571.5,
      "debt": 8.201,
      "gold": 33711.14482,
      "pb": 2.97,
      "pe": 17.61,
      "yield": 1.47,
      "date": "2012-11-20"
  },
  "2012-11-21": {
      "equity": 5614.8,
      "debt": 8.208,
      "gold": 33588.46893,
      "pb": 2.99,
      "pe": 17.74,
      "yield": 1.46,
      "date": "2012-11-21"
  },
  "2012-11-22": {
      "equity": 5627.7,
      "debt": 8.226,
      "gold": 33695.63484,
      "pb": 3,
      "pe": 17.79,
      "yield": 1.46,
      "date": "2012-11-22"
  },
  "2012-11-23": {
      "equity": 5626.6,
      "debt": 8.23,
      "gold": 33903.17995,
      "pb": 2.99,
      "pe": 17.78,
      "yield": 1.46,
      "date": "2012-11-23"
  },
  "2012-11-24": {
      "equity": 5626.6,
      "debt": 8.23,
      "gold": 33903.17995,
      "pb": 2.99,
      "pe": 17.78,
      "yield": 1.46,
      "date": "2012-11-24"
  },
  "2012-11-25": {
      "equity": 5626.6,
      "debt": 8.23,
      "gold": 33903.17995,
      "pb": 2.99,
      "pe": 17.78,
      "yield": 1.46,
      "date": "2012-11-25"
  },
  "2012-11-26": {
      "equity": 5635.9,
      "debt": 8.204,
      "gold": 34264.57609,
      "pb": 3,
      "pe": 17.82,
      "yield": 1.45,
      "date": "2012-11-26"
  },
  "2012-11-27": {
      "equity": 5727.4,
      "debt": 8.191,
      "gold": 34184.45475,
      "pb": 3.04,
      "pe": 18.11,
      "yield": 1.43,
      "date": "2012-11-27"
  },
  "2012-11-28": {
      "equity": 5727.4,
      "debt": 8.191,
      "gold": 34062.17746,
      "pb": 3.04,
      "pe": 18.11,
      "yield": 1.43,
      "date": "2012-11-28"
  },
  "2012-11-29": {
      "equity": 5825,
      "debt": 8.206,
      "gold": 33331.79774,
      "pb": 3.09,
      "pe": 18.42,
      "yield": 1.41,
      "date": "2012-11-29"
  },
  "2012-11-30": {
      "equity": 5879.8,
      "debt": 8.175,
      "gold": 33160.42964,
      "pb": 3.12,
      "pe": 18.59,
      "yield": 1.4,
      "date": "2012-11-30"
  },
  "2012-12-01": {
      "equity": 5879.8,
      "debt": 8.177,
      "gold": 33160.42964,
      "pb": 3.12,
      "pe": 18.59,
      "yield": 1.4,
      "date": "2012-12-01"
  },
  "2012-12-02": {
      "equity": 5879.8,
      "debt": 8.177,
      "gold": 33160.42964,
      "pb": 3.12,
      "pe": 18.59,
      "yield": 1.4,
      "date": "2012-12-02"
  },
  "2012-12-03": {
      "equity": 5870.9,
      "debt": 8.174,
      "gold": 33091.02101,
      "pb": 3.11,
      "pe": 18.56,
      "yield": 1.4,
      "date": "2012-12-03"
  },
  "2012-12-04": {
      "equity": 5889.2,
      "debt": 8.171,
      "gold": 32895.40204,
      "pb": 3.12,
      "pe": 18.62,
      "yield": 1.4,
      "date": "2012-12-04"
  },
  "2012-12-05": {
      "equity": 5900.5,
      "debt": 8.171,
      "gold": 32784.08085,
      "pb": 3.13,
      "pe": 18.66,
      "yield": 1.4,
      "date": "2012-12-05"
  },
  "2012-12-06": {
      "equity": 5930.9,
      "debt": 8.17,
      "gold": 32436.30399,
      "pb": 3.15,
      "pe": 18.75,
      "yield": 1.39,
      "date": "2012-12-06"
  },
  "2012-12-07": {
      "equity": 5907.4,
      "debt": 8.159,
      "gold": 32488.99628,
      "pb": 3.13,
      "pe": 18.68,
      "yield": 1.4,
      "date": "2012-12-07"
  },
  "2012-12-08": {
      "equity": 5907.4,
      "debt": 8.159,
      "gold": 32488.99628,
      "pb": 3.13,
      "pe": 18.68,
      "yield": 1.4,
      "date": "2012-12-08"
  },
  "2012-12-09": {
      "equity": 5907.4,
      "debt": 8.159,
      "gold": 32488.99628,
      "pb": 3.13,
      "pe": 18.68,
      "yield": 1.4,
      "date": "2012-12-09"
  },
  "2012-12-10": {
      "equity": 5908.9,
      "debt": 8.172,
      "gold": 32763.39971,
      "pb": 3.14,
      "pe": 18.69,
      "yield": 1.4,
      "date": "2012-12-10"
  },
  "2012-12-11": {
      "equity": 5898.8,
      "debt": 8.178,
      "gold": 32745.156,
      "pb": 3.13,
      "pe": 18.66,
      "yield": 1.4,
      "date": "2012-12-11"
  },
  "2012-12-12": {
      "equity": 5888,
      "debt": 8.177,
      "gold": 32761.57957,
      "pb": 3.12,
      "pe": 18.62,
      "yield": 1.4,
      "date": "2012-12-12"
  },
  "2012-12-13": {
      "equity": 5851.5,
      "debt": 8.158,
      "gold": 32565.48087,
      "pb": 3.11,
      "pe": 18.51,
      "yield": 1.41,
      "date": "2012-12-13"
  },
  "2012-12-14": {
      "equity": 5879.6,
      "debt": 8.142,
      "gold": 32593.12157,
      "pb": 3.12,
      "pe": 18.6,
      "yield": 1.4,
      "date": "2012-12-14"
  },
  "2012-12-15": {
      "equity": 5879.6,
      "debt": 8.142,
      "gold": 32593.12157,
      "pb": 3.12,
      "pe": 18.6,
      "yield": 1.4,
      "date": "2012-12-15"
  },
  "2012-12-16": {
      "equity": 5879.6,
      "debt": 8.142,
      "gold": 32593.12157,
      "pb": 3.12,
      "pe": 18.6,
      "yield": 1.4,
      "date": "2012-12-16"
  },
  "2012-12-17": {
      "equity": 5857.9,
      "debt": 8.14,
      "gold": 32623.23851,
      "pb": 3.11,
      "pe": 18.53,
      "yield": 1.41,
      "date": "2012-12-17"
  },
  "2012-12-18": {
      "equity": 5896.8,
      "debt": 8.148,
      "gold": 32935.51209,
      "pb": 3.13,
      "pe": 18.65,
      "yield": 1.4,
      "date": "2012-12-18"
  },
  "2012-12-19": {
      "equity": 5929.6,
      "debt": 8.154,
      "gold": 32270.8725,
      "pb": 3.15,
      "pe": 18.76,
      "yield": 1.39,
      "date": "2012-12-19"
  },
  "2012-12-20": {
      "equity": 5916.4,
      "debt": 8.154,
      "gold": 32285.0985,
      "pb": 3.14,
      "pe": 18.71,
      "yield": 1.39,
      "date": "2012-12-20"
  },
  "2012-12-21": {
      "equity": 5847.7,
      "debt": 8.143,
      "gold": 32038.24406,
      "pb": 3.1,
      "pe": 18.5,
      "yield": 1.41,
      "date": "2012-12-21"
  },
  "2012-12-22": {
      "equity": 5847.7,
      "debt": 8.143,
      "gold": 32038.24406,
      "pb": 3.1,
      "pe": 18.5,
      "yield": 1.41,
      "date": "2012-12-22"
  },
  "2012-12-23": {
      "equity": 5847.7,
      "debt": 8.143,
      "gold": 32038.24406,
      "pb": 3.1,
      "pe": 18.5,
      "yield": 1.41,
      "date": "2012-12-23"
  },
  "2012-12-24": {
      "equity": 5855.7,
      "debt": 8.129,
      "gold": 32344.55281,
      "pb": 3.11,
      "pe": 18.52,
      "yield": 1.41,
      "date": "2012-12-24"
  },
  "2012-12-25": {
      "equity": 5855.7,
      "debt": 8.131,
      "gold": 32344.55281,
      "pb": 3.11,
      "pe": 18.52,
      "yield": 1.41,
      "date": "2012-12-25"
  },
  "2012-12-26": {
      "equity": 5905.6,
      "debt": 8.112,
      "gold": 32344.55281,
      "pb": 3.13,
      "pe": 18.68,
      "yield": 1.4,
      "date": "2012-12-26"
  },
  "2012-12-27": {
      "equity": 5870.1,
      "debt": 8.111,
      "gold": 32057.53188,
      "pb": 3.12,
      "pe": 18.57,
      "yield": 1.4,
      "date": "2012-12-27"
  },
  "2012-12-28": {
      "equity": 5908.3,
      "debt": 8.106,
      "gold": 32069.73315,
      "pb": 3.14,
      "pe": 18.69,
      "yield": 1.4,
      "date": "2012-12-28"
  },
  "2012-12-29": {
      "equity": 5908.3,
      "debt": 8.107,
      "gold": 32069.73315,
      "pb": 3.14,
      "pe": 18.69,
      "yield": 1.4,
      "date": "2012-12-29"
  },
  "2012-12-30": {
      "equity": 5908.3,
      "debt": 8.107,
      "gold": 32069.73315,
      "pb": 3.14,
      "pe": 18.69,
      "yield": 1.4,
      "date": "2012-12-30"
  },
  "2012-12-31": {
      "equity": 5905.1,
      "debt": 8.049,
      "gold": 32159.49488,
      "pb": 3.13,
      "pe": 18.68,
      "yield": 1.4,
      "date": "2012-12-31"
  },
  "2013-01-01": {
      "equity": 5950.8,
      "debt": 7.994,
      "gold": 32159.49488,
      "pb": 3.16,
      "pe": 18.82,
      "yield": 1.39,
      "date": "2013-01-01"
  },
  "2013-01-02": {
      "equity": 5993.2,
      "debt": 7.993,
      "gold": 32198.17986,
      "pb": 3.18,
      "pe": 18.96,
      "yield": 1.38,
      "date": "2013-01-02"
  },
  "2013-01-03": {
      "equity": 6009.5,
      "debt": 7.967,
      "gold": 32374.46163,
      "pb": 3.19,
      "pe": 19.01,
      "yield": 1.37,
      "date": "2013-01-03"
  },
  "2013-01-04": {
      "equity": 6016.1,
      "debt": 7.93,
      "gold": 31594.81472,
      "pb": 3.19,
      "pe": 19.03,
      "yield": 1.37,
      "date": "2013-01-04"
  },
  "2013-01-05": {
      "equity": 6016.1,
      "debt": 7.933,
      "gold": 31594.81472,
      "pb": 3.19,
      "pe": 19.03,
      "yield": 1.37,
      "date": "2013-01-05"
  },
  "2013-01-06": {
      "equity": 6016.1,
      "debt": 7.933,
      "gold": 31594.81472,
      "pb": 3.19,
      "pe": 19.03,
      "yield": 1.37,
      "date": "2013-01-06"
  },
  "2013-01-07": {
      "equity": 5988.4,
      "debt": 7.9,
      "gold": 32156.81758,
      "pb": 3.18,
      "pe": 18.94,
      "yield": 1.38,
      "date": "2013-01-07"
  },
  "2013-01-08": {
      "equity": 6001.7,
      "debt": 7.906,
      "gold": 32069.31692,
      "pb": 3.19,
      "pe": 18.98,
      "yield": 1.37,
      "date": "2013-01-08"
  },
  "2013-01-09": {
      "equity": 5971.5,
      "debt": 7.897,
      "gold": 32182.1055,
      "pb": 3.17,
      "pe": 18.89,
      "yield": 1.38,
      "date": "2013-01-09"
  },
  "2013-01-10": {
      "equity": 5968.6,
      "debt": 7.877,
      "gold": 32002.3175,
      "pb": 3.17,
      "pe": 18.88,
      "yield": 1.38,
      "date": "2013-01-10"
  },
  "2013-01-11": {
      "equity": 5951.3,
      "debt": 7.866,
      "gold": 32280.51288,
      "pb": 3.16,
      "pe": 18.82,
      "yield": 1.39,
      "date": "2013-01-11"
  },
  "2013-01-12": {
      "equity": 5951.3,
      "debt": 7.866,
      "gold": 32280.51288,
      "pb": 3.16,
      "pe": 18.82,
      "yield": 1.39,
      "date": "2013-01-12"
  },
  "2013-01-13": {
      "equity": 5951.3,
      "debt": 7.869,
      "gold": 32280.51288,
      "pb": 3.16,
      "pe": 18.82,
      "yield": 1.39,
      "date": "2013-01-13"
  },
  "2013-01-14": {
      "equity": 6024,
      "debt": 7.8,
      "gold": 32093.72299,
      "pb": 3.2,
      "pe": 19.05,
      "yield": 1.37,
      "date": "2013-01-14"
  },
  "2013-01-15": {
      "equity": 6056.6,
      "debt": 7.832,
      "gold": 32408.00014,
      "pb": 3.21,
      "pe": 19.12,
      "yield": 1.36,
      "date": "2013-01-15"
  },
  "2013-01-16": {
      "equity": 6001.8,
      "debt": 7.881,
      "gold": 32389.82345,
      "pb": 3.19,
      "pe": 18.95,
      "yield": 1.37,
      "date": "2013-01-16"
  },
  "2013-01-17": {
      "equity": 6039.2,
      "debt": 7.837,
      "gold": 32160.44022,
      "pb": 3.21,
      "pe": 19.07,
      "yield": 1.36,
      "date": "2013-01-17"
  },
  "2013-01-18": {
      "equity": 6064.4,
      "debt": 7.861,
      "gold": 32050.95328,
      "pb": 3.22,
      "pe": 19.01,
      "yield": 1.36,
      "date": "2013-01-18"
  },
  "2013-01-19": {
      "equity": 6064.4,
      "debt": 7.861,
      "gold": 32050.95328,
      "pb": 3.22,
      "pe": 19.01,
      "yield": 1.36,
      "date": "2013-01-19"
  },
  "2013-01-20": {
      "equity": 6064.4,
      "debt": 7.861,
      "gold": 32050.95328,
      "pb": 3.22,
      "pe": 19.01,
      "yield": 1.36,
      "date": "2013-01-20"
  },
  "2013-01-21": {
      "equity": 6082.3,
      "debt": 7.861,
      "gold": 31911.80091,
      "pb": 3.23,
      "pe": 18.97,
      "yield": 1.36,
      "date": "2013-01-21"
  },
  "2013-01-22": {
      "equity": 6048.5,
      "debt": 7.849,
      "gold": 32002.84308,
      "pb": 3.21,
      "pe": 18.75,
      "yield": 1.36,
      "date": "2013-01-22"
  },
  "2013-01-23": {
      "equity": 6054.3,
      "debt": 7.864,
      "gold": 32045.8703,
      "pb": 3.21,
      "pe": 18.76,
      "yield": 1.36,
      "date": "2013-01-23"
  },
  "2013-01-24": {
      "equity": 6019.3,
      "debt": 7.885,
      "gold": 31751.17021,
      "pb": 3.19,
      "pe": 18.6,
      "yield": 1.37,
      "date": "2013-01-24"
  },
  "2013-01-25": {
      "equity": 6074.6,
      "debt": 7.885,
      "gold": 31697.01406,
      "pb": 3.22,
      "pe": 18.77,
      "yield": 1.36,
      "date": "2013-01-25"
  },
  "2013-01-26": {
      "equity": 6074.6,
      "debt": 7.885,
      "gold": 31697.01406,
      "pb": 3.22,
      "pe": 18.77,
      "yield": 1.36,
      "date": "2013-01-26"
  },
  "2013-01-27": {
      "equity": 6074.6,
      "debt": 7.885,
      "gold": 31697.01406,
      "pb": 3.22,
      "pe": 18.77,
      "yield": 1.36,
      "date": "2013-01-27"
  },
  "2013-01-28": {
      "equity": 6074.8,
      "debt": 7.863,
      "gold": 31554.77874,
      "pb": 3.22,
      "pe": 18.75,
      "yield": 1.36,
      "date": "2013-01-28"
  },
  "2013-01-29": {
      "equity": 6049.9,
      "debt": 7.847,
      "gold": 31368.48269,
      "pb": 3.21,
      "pe": 18.67,
      "yield": 1.36,
      "date": "2013-01-29"
  },
  "2013-01-30": {
      "equity": 6055.7,
      "debt": 7.891,
      "gold": 31271.39103,
      "pb": 3.21,
      "pe": 18.66,
      "yield": 1.36,
      "date": "2013-01-30"
  },
  "2013-01-31": {
      "equity": 6034.7,
      "debt": 7.912,
      "gold": 31508.9155,
      "pb": 3.2,
      "pe": 18.5,
      "yield": 1.37,
      "date": "2013-01-31"
  },
  "2013-02-01": {
      "equity": 5998.9,
      "debt": 7.905,
      "gold": 31165.71015,
      "pb": 3.18,
      "pe": 18.42,
      "yield": 1.37,
      "date": "2013-02-01"
  },
  "2013-02-02": {
      "equity": 5998.9,
      "debt": 7.905,
      "gold": 31165.71015,
      "pb": 3.18,
      "pe": 18.42,
      "yield": 1.37,
      "date": "2013-02-02"
  },
  "2013-02-03": {
      "equity": 5998.9,
      "debt": 7.905,
      "gold": 31165.71015,
      "pb": 3.18,
      "pe": 18.42,
      "yield": 1.37,
      "date": "2013-02-03"
  },
  "2013-02-04": {
      "equity": 5987.2,
      "debt": 7.937,
      "gold": 31298.43207,
      "pb": 3.18,
      "pe": 18.43,
      "yield": 1.38,
      "date": "2013-02-04"
  },
  "2013-02-05": {
      "equity": 5956.9,
      "debt": 7.923,
      "gold": 31379.44937,
      "pb": 3.16,
      "pe": 18.33,
      "yield": 1.38,
      "date": "2013-02-05"
  },
  "2013-02-06": {
      "equity": 5959.2,
      "debt": 7.907,
      "gold": 31276.97138,
      "pb": 3.16,
      "pe": 18.34,
      "yield": 1.38,
      "date": "2013-02-06"
  },
  "2013-02-07": {
      "equity": 5938.8,
      "debt": 7.878,
      "gold": 31609.28059,
      "pb": 3.15,
      "pe": 18.27,
      "yield": 1.39,
      "date": "2013-02-07"
  },
  "2013-02-08": {
      "equity": 5903.5,
      "debt": 7.843,
      "gold": 31584.45122,
      "pb": 3.13,
      "pe": 18.16,
      "yield": 1.4,
      "date": "2013-02-08"
  },
  "2013-02-09": {
      "equity": 5903.5,
      "debt": 7.844,
      "gold": 31584.45122,
      "pb": 3.13,
      "pe": 18.16,
      "yield": 1.4,
      "date": "2013-02-09"
  },
  "2013-02-10": {
      "equity": 5903.5,
      "debt": 7.844,
      "gold": 31584.45122,
      "pb": 3.13,
      "pe": 18.16,
      "yield": 1.4,
      "date": "2013-02-10"
  },
  "2013-02-11": {
      "equity": 5897.8,
      "debt": 7.858,
      "gold": 31642.2653,
      "pb": 3.13,
      "pe": 18.17,
      "yield": 1.4,
      "date": "2013-02-11"
  },
  "2013-02-12": {
      "equity": 5922.5,
      "debt": 7.867,
      "gold": 31101.14111,
      "pb": 3.14,
      "pe": 18.29,
      "yield": 1.39,
      "date": "2013-02-12"
  },
  "2013-02-13": {
      "equity": 5932.9,
      "debt": 7.844,
      "gold": 31271.86017,
      "pb": 3.15,
      "pe": 18.33,
      "yield": 1.39,
      "date": "2013-02-13"
  },
  "2013-02-14": {
      "equity": 5896.9,
      "debt": 7.818,
      "gold": 31300.34039,
      "pb": 3.13,
      "pe": 18.27,
      "yield": 1.4,
      "date": "2013-02-14"
  },
  "2013-02-15": {
      "equity": 5887.4,
      "debt": 7.832,
      "gold": 31214.91032,
      "pb": 3.13,
      "pe": 18.21,
      "yield": 1.4,
      "date": "2013-02-15"
  },
  "2013-02-16": {
      "equity": 5887.4,
      "debt": 7.832,
      "gold": 31214.91032,
      "pb": 3.13,
      "pe": 18.21,
      "yield": 1.4,
      "date": "2013-02-16"
  },
  "2013-02-17": {
      "equity": 5887.4,
      "debt": 7.832,
      "gold": 31214.91032,
      "pb": 3.13,
      "pe": 18.21,
      "yield": 1.4,
      "date": "2013-02-17"
  },
  "2013-02-18": {
      "equity": 5898.2,
      "debt": 7.824,
      "gold": 30887.09854,
      "pb": 3.14,
      "pe": 18.27,
      "yield": 1.39,
      "date": "2013-02-18"
  },
  "2013-02-19": {
      "equity": 5939.7,
      "debt": 7.827,
      "gold": 30930.23157,
      "pb": 3.16,
      "pe": 18.52,
      "yield": 1.38,
      "date": "2013-02-19"
  },
  "2013-02-20": {
      "equity": 5943,
      "debt": 7.804,
      "gold": 30641.96899,
      "pb": 3.16,
      "pe": 18.61,
      "yield": 1.38,
      "date": "2013-02-20"
  },
  "2013-02-21": {
      "equity": 5852.2,
      "debt": 7.812,
      "gold": 30222.51186,
      "pb": 3.11,
      "pe": 18.32,
      "yield": 1.4,
      "date": "2013-02-21"
  },
  "2013-02-22": {
      "equity": 5850.3,
      "debt": 7.799,
      "gold": 30262.9676,
      "pb": 3.11,
      "pe": 18.28,
      "yield": 1.41,
      "date": "2013-02-22"
  },
  "2013-02-23": {
      "equity": 5850.3,
      "debt": 7.799,
      "gold": 30262.9676,
      "pb": 3.11,
      "pe": 18.28,
      "yield": 1.41,
      "date": "2013-02-23"
  },
  "2013-02-24": {
      "equity": 5850.3,
      "debt": 7.799,
      "gold": 30262.9676,
      "pb": 3.11,
      "pe": 18.28,
      "yield": 1.41,
      "date": "2013-02-24"
  },
  "2013-02-25": {
      "equity": 5854.7,
      "debt": 7.798,
      "gold": 30317.01794,
      "pb": 3.11,
      "pe": 18.29,
      "yield": 1.4,
      "date": "2013-02-25"
  },
  "2013-02-26": {
      "equity": 5761.3,
      "debt": 7.816,
      "gold": 30497.58902,
      "pb": 3.06,
      "pe": 18,
      "yield": 1.43,
      "date": "2013-02-26"
  },
  "2013-02-27": {
      "equity": 5796.9,
      "debt": 7.798,
      "gold": 30479.7686,
      "pb": 3.08,
      "pe": 17.98,
      "yield": 1.42,
      "date": "2013-02-27"
  },
  "2013-02-28": {
      "equity": 5693,
      "debt": 7.872,
      "gold": 30512.94379,
      "pb": 3.03,
      "pe": 17.66,
      "yield": 1.44,
      "date": "2013-02-28"
  },
  "2013-03-01": {
      "equity": 5719.7,
      "debt": 7.908,
      "gold": 30500.62611,
      "pb": 3.04,
      "pe": 17.74,
      "yield": 1.44,
      "date": "2013-03-01"
  },
  "2013-03-02": {
      "equity": 5719.7,
      "debt": 7.908,
      "gold": 30500.62611,
      "pb": 3.04,
      "pe": 17.74,
      "yield": 1.44,
      "date": "2013-03-02"
  },
  "2013-03-03": {
      "equity": 5719.7,
      "debt": 7.908,
      "gold": 30500.62611,
      "pb": 3.04,
      "pe": 17.74,
      "yield": 1.44,
      "date": "2013-03-03"
  },
  "2013-03-04": {
      "equity": 5698.5,
      "debt": 7.885,
      "gold": 30544.7186,
      "pb": 3.03,
      "pe": 17.67,
      "yield": 1.44,
      "date": "2013-03-04"
  },
  "2013-03-05": {
      "equity": 5784.2,
      "debt": 7.871,
      "gold": 30685.25724,
      "pb": 3.08,
      "pe": 17.94,
      "yield": 1.42,
      "date": "2013-03-05"
  },
  "2013-03-06": {
      "equity": 5818.6,
      "debt": 7.86,
      "gold": 30470.06826,
      "pb": 3.1,
      "pe": 18.05,
      "yield": 1.41,
      "date": "2013-03-06"
  },
  "2013-03-07": {
      "equity": 5863.3,
      "debt": 7.862,
      "gold": 30367.32217,
      "pb": 3.12,
      "pe": 18.19,
      "yield": 1.4,
      "date": "2013-03-07"
  },
  "2013-03-08": {
      "equity": 5945.7,
      "debt": 7.839,
      "gold": 30213.84857,
      "pb": 3.16,
      "pe": 18.44,
      "yield": 1.38,
      "date": "2013-03-08"
  },
  "2013-03-09": {
      "equity": 5945.7,
      "debt": 7.839,
      "gold": 30213.84857,
      "pb": 3.16,
      "pe": 18.44,
      "yield": 1.38,
      "date": "2013-03-09"
  },
  "2013-03-10": {
      "equity": 5945.7,
      "debt": 7.839,
      "gold": 30213.84857,
      "pb": 3.16,
      "pe": 18.44,
      "yield": 1.38,
      "date": "2013-03-10"
  },
  "2013-03-11": {
      "equity": 5942.3,
      "debt": 7.84,
      "gold": 30234.55793,
      "pb": 3.15,
      "pe": 18.37,
      "yield": 1.39,
      "date": "2013-03-11"
  },
  "2013-03-12": {
      "equity": 5914.1,
      "debt": 7.888,
      "gold": 30241.07656,
      "pb": 3.13,
      "pe": 18.28,
      "yield": 1.4,
      "date": "2013-03-12"
  },
  "2013-03-13": {
      "equity": 5851.2,
      "debt": 7.903,
      "gold": 30559.0222,
      "pb": 3.1,
      "pe": 18.09,
      "yield": 1.41,
      "date": "2013-03-13"
  },
  "2013-03-14": {
      "equity": 5908.9,
      "debt": 7.865,
      "gold": 30255.30609,
      "pb": 3.13,
      "pe": 18.26,
      "yield": 1.4,
      "date": "2013-03-14"
  },
  "2013-03-15": {
      "equity": 5872.6,
      "debt": 7.861,
      "gold": 30373.44574,
      "pb": 3.11,
      "pe": 18.15,
      "yield": 1.41,
      "date": "2013-03-15"
  },
  "2013-03-16": {
      "equity": 5872.6,
      "debt": 7.861,
      "gold": 30373.44574,
      "pb": 3.11,
      "pe": 18.15,
      "yield": 1.41,
      "date": "2013-03-16"
  },
  "2013-03-17": {
      "equity": 5872.6,
      "debt": 7.863,
      "gold": 30373.44574,
      "pb": 3.11,
      "pe": 18.15,
      "yield": 1.41,
      "date": "2013-03-17"
  },
  "2013-03-18": {
      "equity": 5835.2,
      "debt": 7.884,
      "gold": 30582.86742,
      "pb": 3.09,
      "pe": 18.04,
      "yield": 1.42,
      "date": "2013-03-18"
  },
  "2013-03-19": {
      "equity": 5745.9,
      "debt": 7.903,
      "gold": 30809.80617,
      "pb": 3.04,
      "pe": 17.76,
      "yield": 1.44,
      "date": "2013-03-19"
  },
  "2013-03-20": {
      "equity": 5694.4,
      "debt": 7.922,
      "gold": 30914.62989,
      "pb": 3.01,
      "pe": 17.6,
      "yield": 1.45,
      "date": "2013-03-20"
  },
  "2013-03-21": {
      "equity": 5658.7,
      "debt": 7.933,
      "gold": 30867.54969,
      "pb": 3,
      "pe": 17.49,
      "yield": 1.46,
      "date": "2013-03-21"
  },
  "2013-03-22": {
      "equity": 5651.3,
      "debt": 7.961,
      "gold": 30880.52347,
      "pb": 2.99,
      "pe": 17.47,
      "yield": 1.46,
      "date": "2013-03-22"
  },
  "2013-03-23": {
      "equity": 5651.3,
      "debt": 7.961,
      "gold": 30880.52347,
      "pb": 2.99,
      "pe": 17.47,
      "yield": 1.46,
      "date": "2013-03-23"
  },
  "2013-03-24": {
      "equity": 5651.3,
      "debt": 7.961,
      "gold": 30880.52347,
      "pb": 2.99,
      "pe": 17.47,
      "yield": 1.46,
      "date": "2013-03-24"
  },
  "2013-03-25": {
      "equity": 5633.8,
      "debt": 7.948,
      "gold": 30703.26814,
      "pb": 2.98,
      "pe": 17.41,
      "yield": 1.47,
      "date": "2013-03-25"
  },
  "2013-03-26": {
      "equity": 5641.6,
      "debt": 7.99,
      "gold": 30686.33309,
      "pb": 2.98,
      "pe": 17.44,
      "yield": 1.47,
      "date": "2013-03-26"
  },
  "2013-03-27": {
      "equity": 5641.6,
      "debt": 7.99,
      "gold": 30566.26043,
      "pb": 2.98,
      "pe": 17.44,
      "yield": 1.47,
      "date": "2013-03-27"
  },
  "2013-03-28": {
      "equity": 5682.5,
      "debt": 7.951,
      "gold": 30798.50086,
      "pb": 3.01,
      "pe": 17.57,
      "yield": 1.46,
      "date": "2013-03-28"
  },
  "2013-03-29": {
      "equity": 5682.5,
      "debt": 7.96,
      "gold": 30798.50086,
      "pb": 3.01,
      "pe": 17.57,
      "yield": 1.46,
      "date": "2013-03-29"
  },
  "2013-03-30": {
      "equity": 5682.5,
      "debt": 7.96,
      "gold": 30798.50086,
      "pb": 3.01,
      "pe": 17.57,
      "yield": 1.46,
      "date": "2013-03-30"
  },
  "2013-03-31": {
      "equity": 5682.5,
      "debt": 7.96,
      "gold": 30798.50086,
      "pb": 3.01,
      "pe": 17.57,
      "yield": 1.46,
      "date": "2013-03-31"
  },
  "2013-04-01": {
      "equity": 5704.4,
      "debt": 7.96,
      "gold": 30798.50086,
      "pb": 3.01,
      "pe": 17.51,
      "yield": 1.46,
      "date": "2013-04-01"
  },
  "2013-04-02": {
      "equity": 5748.1,
      "debt": 7.993,
      "gold": 30594.49373,
      "pb": 3.03,
      "pe": 17.64,
      "yield": 1.44,
      "date": "2013-04-02"
  },
  "2013-04-03": {
      "equity": 5672.9,
      "debt": 7.964,
      "gold": 30139.52274,
      "pb": 2.99,
      "pe": 17.41,
      "yield": 1.46,
      "date": "2013-04-03"
  },
  "2013-04-04": {
      "equity": 5574.7,
      "debt": 7.962,
      "gold": 29928.77829,
      "pb": 2.94,
      "pe": 17.11,
      "yield": 1.49,
      "date": "2013-04-04"
  },
  "2013-04-05": {
      "equity": 5553.2,
      "debt": 7.931,
      "gold": 29995.71421,
      "pb": 2.93,
      "pe": 17.04,
      "yield": 1.49,
      "date": "2013-04-05"
  },
  "2013-04-06": {
      "equity": 5553.2,
      "debt": 7.931,
      "gold": 29995.71421,
      "pb": 2.93,
      "pe": 17.04,
      "yield": 1.49,
      "date": "2013-04-06"
  },
  "2013-04-07": {
      "equity": 5553.2,
      "debt": 7.931,
      "gold": 29995.71421,
      "pb": 2.93,
      "pe": 17.04,
      "yield": 1.49,
      "date": "2013-04-07"
  },
  "2013-04-08": {
      "equity": 5542.9,
      "debt": 7.91,
      "gold": 30352.16494,
      "pb": 2.92,
      "pe": 17.01,
      "yield": 1.5,
      "date": "2013-04-08"
  },
  "2013-04-09": {
      "equity": 5495.1,
      "debt": 7.882,
      "gold": 30172.01009,
      "pb": 2.9,
      "pe": 16.86,
      "yield": 1.51,
      "date": "2013-04-09"
  },
  "2013-04-10": {
      "equity": 5558.7,
      "debt": 7.899,
      "gold": 30411.63689,
      "pb": 2.93,
      "pe": 17.06,
      "yield": 1.49,
      "date": "2013-04-10"
  },
  "2013-04-11": {
      "equity": 5594,
      "debt": 7.9,
      "gold": 29916.47472,
      "pb": 2.95,
      "pe": 17.17,
      "yield": 1.48,
      "date": "2013-04-11"
  },
  "2013-04-12": {
      "equity": 5528.5,
      "debt": 7.869,
      "gold": 29822.04977,
      "pb": 2.91,
      "pe": 16.96,
      "yield": 1.5,
      "date": "2013-04-12"
  },
  "2013-04-13": {
      "equity": 5528.5,
      "debt": 7.869,
      "gold": 29822.04977,
      "pb": 2.91,
      "pe": 16.96,
      "yield": 1.5,
      "date": "2013-04-13"
  },
  "2013-04-14": {
      "equity": 5528.5,
      "debt": 7.869,
      "gold": 29822.04977,
      "pb": 2.91,
      "pe": 16.96,
      "yield": 1.5,
      "date": "2013-04-14"
  },
  "2013-04-15": {
      "equity": 5568.4,
      "debt": 7.831,
      "gold": 27179.18835,
      "pb": 2.93,
      "pe": 17.08,
      "yield": 1.49,
      "date": "2013-04-15"
  },
  "2013-04-16": {
      "equity": 5688.9,
      "debt": 7.823,
      "gold": 26284.53765,
      "pb": 3,
      "pe": 17.45,
      "yield": 1.46,
      "date": "2013-04-16"
  },
  "2013-04-17": {
      "equity": 5688.7,
      "debt": 7.803,
      "gold": 26352.25665,
      "pb": 3,
      "pe": 17.31,
      "yield": 1.46,
      "date": "2013-04-17"
  },
  "2013-04-18": {
      "equity": 5783.1,
      "debt": 7.777,
      "gold": 26577.96434,
      "pb": 3.05,
      "pe": 17.6,
      "yield": 1.44,
      "date": "2013-04-18"
  },
  "2013-04-19": {
      "equity": 5783.1,
      "debt": 7.782,
      "gold": 26901.38803,
      "pb": 3.05,
      "pe": 17.6,
      "yield": 1.44,
      "date": "2013-04-19"
  },
  "2013-04-20": {
      "equity": 5783.1,
      "debt": 7.782,
      "gold": 26901.38803,
      "pb": 3.05,
      "pe": 17.6,
      "yield": 1.44,
      "date": "2013-04-20"
  },
  "2013-04-21": {
      "equity": 5783.1,
      "debt": 7.782,
      "gold": 26901.38803,
      "pb": 3.05,
      "pe": 17.6,
      "yield": 1.44,
      "date": "2013-04-21"
  },
  "2013-04-22": {
      "equity": 5834.4,
      "debt": 7.753,
      "gold": 27246.38177,
      "pb": 3.07,
      "pe": 17.76,
      "yield": 1.42,
      "date": "2013-04-22"
  },
  "2013-04-23": {
      "equity": 5836.9,
      "debt": 7.734,
      "gold": 27183.83393,
      "pb": 3.08,
      "pe": 17.76,
      "yield": 1.42,
      "date": "2013-04-23"
  },
  "2013-04-24": {
      "equity": 5836.9,
      "debt": 7.739,
      "gold": 27322.89458,
      "pb": 3.08,
      "pe": 17.76,
      "yield": 1.42,
      "date": "2013-04-24"
  },
  "2013-04-25": {
      "equity": 5916.3,
      "debt": 7.773,
      "gold": 27606.44103,
      "pb": 3.12,
      "pe": 18,
      "yield": 1.4,
      "date": "2013-04-25"
  },
  "2013-04-26": {
      "equity": 5871.4,
      "debt": 7.743,
      "gold": 28061.76829,
      "pb": 3.09,
      "pe": 17.73,
      "yield": 1.41,
      "date": "2013-04-26"
  },
  "2013-04-27": {
      "equity": 5871.4,
      "debt": 7.743,
      "gold": 28061.76829,
      "pb": 3.09,
      "pe": 17.73,
      "yield": 1.41,
      "date": "2013-04-27"
  },
  "2013-04-28": {
      "equity": 5871.4,
      "debt": 7.743,
      "gold": 28061.76829,
      "pb": 3.09,
      "pe": 17.73,
      "yield": 1.41,
      "date": "2013-04-28"
  },
  "2013-04-29": {
      "equity": 5904.1,
      "debt": 7.762,
      "gold": 28183.1602,
      "pb": 3.11,
      "pe": 17.78,
      "yield": 1.41,
      "date": "2013-04-29"
  },
  "2013-04-30": {
      "equity": 5930.2,
      "debt": 7.731,
      "gold": 27868.45271,
      "pb": 3.13,
      "pe": 17.85,
      "yield": 1.4,
      "date": "2013-04-30"
  },
  "2013-05-01": {
      "equity": 5930.2,
      "debt": 7.731,
      "gold": 27806.95603,
      "pb": 3.13,
      "pe": 17.85,
      "yield": 1.4,
      "date": "2013-05-01"
  },
  "2013-05-02": {
      "equity": 5999.3,
      "debt": 7.723,
      "gold": 27659.35202,
      "pb": 3.16,
      "pe": 18.05,
      "yield": 1.38,
      "date": "2013-05-02"
  },
  "2013-05-03": {
      "equity": 5944,
      "debt": 7.735,
      "gold": 27991.49544,
      "pb": 3.13,
      "pe": 17.89,
      "yield": 1.4,
      "date": "2013-05-03"
  },
  "2013-05-04": {
      "equity": 5944,
      "debt": 7.735,
      "gold": 27991.49544,
      "pb": 3.13,
      "pe": 17.89,
      "yield": 1.4,
      "date": "2013-05-04"
  },
  "2013-05-05": {
      "equity": 5944,
      "debt": 7.735,
      "gold": 27991.49544,
      "pb": 3.13,
      "pe": 17.89,
      "yield": 1.4,
      "date": "2013-05-05"
  },
  "2013-05-06": {
      "equity": 5971,
      "debt": 7.749,
      "gold": 27991.49544,
      "pb": 3.15,
      "pe": 17.97,
      "yield": 1.39,
      "date": "2013-05-06"
  },
  "2013-05-07": {
      "equity": 6043.5,
      "debt": 7.743,
      "gold": 27905.8643,
      "pb": 3.19,
      "pe": 18.19,
      "yield": 1.37,
      "date": "2013-05-07"
  },
  "2013-05-08": {
      "equity": 6069.3,
      "debt": 7.694,
      "gold": 27729.064,
      "pb": 3.2,
      "pe": 18.2,
      "yield": 1.37,
      "date": "2013-05-08"
  },
  "2013-05-09": {
      "equity": 6050.1,
      "debt": 7.605,
      "gold": 28164.61666,
      "pb": 3.19,
      "pe": 18.11,
      "yield": 1.37,
      "date": "2013-05-09"
  },
  "2013-05-10": {
      "equity": 6094.7,
      "debt": 7.586,
      "gold": 28088.34018,
      "pb": 3.21,
      "pe": 18.22,
      "yield": 1.36,
      "date": "2013-05-10"
  },
  "2013-05-11": {
      "equity": 6107.2,
      "debt": 7.586,
      "gold": 28088.34018,
      "pb": 3.22,
      "pe": 18.26,
      "yield": 1.36,
      "date": "2013-05-11"
  },
  "2013-05-12": {
      "equity": 6107.2,
      "debt": 7.586,
      "gold": 28088.34018,
      "pb": 3.22,
      "pe": 18.26,
      "yield": 1.36,
      "date": "2013-05-12"
  },
  "2013-05-13": {
      "equity": 5980.4,
      "debt": 7.585,
      "gold": 27659.97284,
      "pb": 3.15,
      "pe": 17.79,
      "yield": 1.39,
      "date": "2013-05-13"
  },
  "2013-05-14": {
      "equity": 5995.4,
      "debt": 7.472,
      "gold": 27709.48341,
      "pb": 3.16,
      "pe": 17.88,
      "yield": 1.38,
      "date": "2013-05-14"
  },
  "2013-05-15": {
      "equity": 6146.7,
      "debt": 7.457,
      "gold": 27311.45523,
      "pb": 3.24,
      "pe": 18.33,
      "yield": 1.35,
      "date": "2013-05-15"
  },
  "2013-05-16": {
      "equity": 6169.9,
      "debt": 7.391,
      "gold": 26556.89518,
      "pb": 3.25,
      "pe": 18.33,
      "yield": 1.35,
      "date": "2013-05-16"
  },
  "2013-05-17": {
      "equity": 6187.3,
      "debt": 7.404,
      "gold": 26794.89233,
      "pb": 3.26,
      "pe": 18.38,
      "yield": 1.34,
      "date": "2013-05-17"
  },
  "2013-05-18": {
      "equity": 6187.3,
      "debt": 7.404,
      "gold": 26794.89233,
      "pb": 3.26,
      "pe": 18.38,
      "yield": 1.34,
      "date": "2013-05-18"
  },
  "2013-05-19": {
      "equity": 6187.3,
      "debt": 7.404,
      "gold": 26794.89233,
      "pb": 3.26,
      "pe": 18.38,
      "yield": 1.34,
      "date": "2013-05-19"
  },
  "2013-05-20": {
      "equity": 6156.9,
      "debt": 7.338,
      "gold": 26242.2018,
      "pb": 3.25,
      "pe": 18.37,
      "yield": 1.35,
      "date": "2013-05-20"
  },
  "2013-05-21": {
      "equity": 6114.1,
      "debt": 7.351,
      "gold": 26931.0852,
      "pb": 3.22,
      "pe": 18.24,
      "yield": 1.36,
      "date": "2013-05-21"
  },
  "2013-05-22": {
      "equity": 6094.5,
      "debt": 7.365,
      "gold": 27160.66245,
      "pb": 3.21,
      "pe": 18.15,
      "yield": 1.36,
      "date": "2013-05-22"
  },
  "2013-05-23": {
      "equity": 5967,
      "debt": 7.382,
      "gold": 27268.2587,
      "pb": 3.15,
      "pe": 17.77,
      "yield": 1.39,
      "date": "2013-05-23"
  },
  "2013-05-24": {
      "equity": 5983.5,
      "debt": 7.341,
      "gold": 27243.72917,
      "pb": 3.15,
      "pe": 17.88,
      "yield": 1.39,
      "date": "2013-05-24"
  },
  "2013-05-25": {
      "equity": 5983.5,
      "debt": 7.341,
      "gold": 27243.72917,
      "pb": 3.15,
      "pe": 17.88,
      "yield": 1.39,
      "date": "2013-05-25"
  },
  "2013-05-26": {
      "equity": 5983.5,
      "debt": 7.341,
      "gold": 27243.72917,
      "pb": 3.15,
      "pe": 17.88,
      "yield": 1.39,
      "date": "2013-05-26"
  },
  "2013-05-27": {
      "equity": 6083.1,
      "debt": 7.335,
      "gold": 27243.72917,
      "pb": 3.21,
      "pe": 18.19,
      "yield": 1.36,
      "date": "2013-05-27"
  },
  "2013-05-28": {
      "equity": 6111.2,
      "debt": 7.356,
      "gold": 27266.74192,
      "pb": 3.22,
      "pe": 18.25,
      "yield": 1.36,
      "date": "2013-05-28"
  },
  "2013-05-29": {
      "equity": 6104.3,
      "debt": 7.39,
      "gold": 27478.04723,
      "pb": 3.22,
      "pe": 18.31,
      "yield": 1.36,
      "date": "2013-05-29"
  },
  "2013-05-30": {
      "equity": 6124,
      "debt": 7.441,
      "gold": 28038.68851,
      "pb": 3.23,
      "pe": 18.37,
      "yield": 1.36,
      "date": "2013-05-30"
  },
  "2013-05-31": {
      "equity": 5985.9,
      "debt": 7.443,
      "gold": 28173.16355,
      "pb": 3.16,
      "pe": 17.95,
      "yield": 1.39,
      "date": "2013-05-31"
  },
  "2013-06-01": {
      "equity": 5985.9,
      "debt": 7.443,
      "gold": 28173.16355,
      "pb": 3.16,
      "pe": 17.95,
      "yield": 1.39,
      "date": "2013-06-01"
  },
  "2013-06-02": {
      "equity": 5985.9,
      "debt": 7.443,
      "gold": 28173.16355,
      "pb": 3.16,
      "pe": 17.95,
      "yield": 1.39,
      "date": "2013-06-02"
  },
  "2013-06-03": {
      "equity": 5939.3,
      "debt": 7.244,
      "gold": 27814.78333,
      "pb": 3.13,
      "pe": 17.81,
      "yield": 1.4,
      "date": "2013-06-03"
  },
  "2013-06-04": {
      "equity": 5919.4,
      "debt": 7.192,
      "gold": 27998.92414,
      "pb": 3.12,
      "pe": 17.75,
      "yield": 1.4,
      "date": "2013-06-04"
  },
  "2013-06-05": {
      "equity": 5923.8,
      "debt": 7.208,
      "gold": 28051.17903,
      "pb": 3.12,
      "pe": 17.77,
      "yield": 1.4,
      "date": "2013-06-05"
  },
  "2013-06-06": {
      "equity": 5921.4,
      "debt": 7.212,
      "gold": 28042.32879,
      "pb": 3.12,
      "pe": 17.89,
      "yield": 1.4,
      "date": "2013-06-06"
  },
  "2013-06-07": {
      "equity": 5881,
      "debt": 7.242,
      "gold": 28464.0999,
      "pb": 3.1,
      "pe": 17.76,
      "yield": 1.41,
      "date": "2013-06-07"
  },
  "2013-06-08": {
      "equity": 5881,
      "debt": 7.242,
      "gold": 28464.0999,
      "pb": 3.1,
      "pe": 17.76,
      "yield": 1.41,
      "date": "2013-06-08"
  },
  "2013-06-09": {
      "equity": 5881,
      "debt": 7.242,
      "gold": 28464.0999,
      "pb": 3.1,
      "pe": 17.76,
      "yield": 1.41,
      "date": "2013-06-09"
  },
  "2013-06-10": {
      "equity": 5878,
      "debt": 7.278,
      "gold": 28246.9391,
      "pb": 3.1,
      "pe": 17.73,
      "yield": 1.41,
      "date": "2013-06-10"
  },
  "2013-06-11": {
      "equity": 5788.8,
      "debt": 7.302,
      "gold": 28175.48458,
      "pb": 3.06,
      "pe": 17.47,
      "yield": 1.43,
      "date": "2013-06-11"
  },
  "2013-06-12": {
      "equity": 5760.2,
      "debt": 7.291,
      "gold": 28102.22403,
      "pb": 3.04,
      "pe": 17.38,
      "yield": 1.44,
      "date": "2013-06-12"
  },
  "2013-06-13": {
      "equity": 5699.1,
      "debt": 7.331,
      "gold": 28364.10519,
      "pb": 3.01,
      "pe": 17.2,
      "yield": 1.45,
      "date": "2013-06-13"
  },
  "2013-06-14": {
      "equity": 5808.4,
      "debt": 7.314,
      "gold": 28051.03088,
      "pb": 3.07,
      "pe": 17.53,
      "yield": 1.43,
      "date": "2013-06-14"
  },
  "2013-06-15": {
      "equity": 5808.4,
      "debt": 7.314,
      "gold": 28051.03088,
      "pb": 3.07,
      "pe": 17.53,
      "yield": 1.43,
      "date": "2013-06-15"
  },
  "2013-06-16": {
      "equity": 5808.4,
      "debt": 7.317,
      "gold": 28051.03088,
      "pb": 3.07,
      "pe": 17.53,
      "yield": 1.43,
      "date": "2013-06-16"
  },
  "2013-06-17": {
      "equity": 5850,
      "debt": 7.255,
      "gold": 28336.99007,
      "pb": 3.09,
      "pe": 17.75,
      "yield": 1.42,
      "date": "2013-06-17"
  },
  "2013-06-18": {
      "equity": 5813.6,
      "debt": 7.279,
      "gold": 28618.84689,
      "pb": 3.07,
      "pe": 17.64,
      "yield": 1.43,
      "date": "2013-06-18"
  },
  "2013-06-19": {
      "equity": 5822.2,
      "debt": 7.263,
      "gold": 28299.1058,
      "pb": 3.07,
      "pe": 17.68,
      "yield": 1.42,
      "date": "2013-06-19"
  },
  "2013-06-20": {
      "equity": 5655.9,
      "debt": 7.393,
      "gold": 27529.63192,
      "pb": 2.99,
      "pe": 17.17,
      "yield": 1.47,
      "date": "2013-06-20"
  },
  "2013-06-21": {
      "equity": 5667.6,
      "debt": 7.433,
      "gold": 26982.40181,
      "pb": 2.99,
      "pe": 17.2,
      "yield": 1.46,
      "date": "2013-06-21"
  },
  "2013-06-22": {
      "equity": 5667.6,
      "debt": 7.433,
      "gold": 26982.40181,
      "pb": 2.99,
      "pe": 17.2,
      "yield": 1.46,
      "date": "2013-06-22"
  },
  "2013-06-23": {
      "equity": 5667.6,
      "debt": 7.436,
      "gold": 26982.40181,
      "pb": 2.99,
      "pe": 17.2,
      "yield": 1.46,
      "date": "2013-06-23"
  },
  "2013-06-24": {
      "equity": 5590.2,
      "debt": 7.518,
      "gold": 27147.89326,
      "pb": 2.95,
      "pe": 16.97,
      "yield": 1.48,
      "date": "2013-06-24"
  },
  "2013-06-25": {
      "equity": 5609.1,
      "debt": 7.497,
      "gold": 27062.53373,
      "pb": 2.96,
      "pe": 17.03,
      "yield": 1.48,
      "date": "2013-06-25"
  },
  "2013-06-26": {
      "equity": 5588.7,
      "debt": 7.579,
      "gold": 26327.94229,
      "pb": 2.95,
      "pe": 16.96,
      "yield": 1.48,
      "date": "2013-06-26"
  },
  "2013-06-27": {
      "equity": 5682.3,
      "debt": 7.557,
      "gold": 26141.89316,
      "pb": 2.98,
      "pe": 17.28,
      "yield": 1.46,
      "date": "2013-06-27"
  },
  "2013-06-28": {
      "equity": 5842.2,
      "debt": 7.439,
      "gold": 25311.13776,
      "pb": 3.04,
      "pe": 17.76,
      "yield": 1.43,
      "date": "2013-06-28"
  },
  "2013-06-29": {
      "equity": 5842.2,
      "debt": 7.439,
      "gold": 25311.13776,
      "pb": 3.04,
      "pe": 17.76,
      "yield": 1.43,
      "date": "2013-06-29"
  },
  "2013-06-30": {
      "equity": 5842.2,
      "debt": 7.439,
      "gold": 25311.13776,
      "pb": 3.04,
      "pe": 17.76,
      "yield": 1.43,
      "date": "2013-06-30"
  },
  "2013-07-01": {
      "equity": 5898.8,
      "debt": 7.455,
      "gold": 26052.99212,
      "pb": 3.07,
      "pe": 17.96,
      "yield": 1.4,
      "date": "2013-07-01"
  },
  "2013-07-02": {
      "equity": 5857.5,
      "debt": 7.464,
      "gold": 26467.32394,
      "pb": 3.05,
      "pe": 17.84,
      "yield": 1.41,
      "date": "2013-07-02"
  },
  "2013-07-03": {
      "equity": 5770.9,
      "debt": 7.502,
      "gold": 26474.12124,
      "pb": 2.98,
      "pe": 17.57,
      "yield": 1.42,
      "date": "2013-07-03"
  },
  "2013-07-04": {
      "equity": 5836.9,
      "debt": 7.419,
      "gold": 26495.59604,
      "pb": 2.99,
      "pe": 17.77,
      "yield": 1.42,
      "date": "2013-07-04"
  },
  "2013-07-05": {
      "equity": 5867.9,
      "debt": 7.497,
      "gold": 26401.31925,
      "pb": 3,
      "pe": 17.87,
      "yield": 1.41,
      "date": "2013-07-05"
  },
  "2013-07-06": {
      "equity": 5867.9,
      "debt": 7.497,
      "gold": 26401.31925,
      "pb": 3,
      "pe": 17.87,
      "yield": 1.41,
      "date": "2013-07-06"
  },
  "2013-07-07": {
      "equity": 5867.9,
      "debt": 7.497,
      "gold": 26401.31925,
      "pb": 3,
      "pe": 17.87,
      "yield": 1.41,
      "date": "2013-07-07"
  },
  "2013-07-08": {
      "equity": 5811.5,
      "debt": 7.572,
      "gold": 26241.7256,
      "pb": 2.98,
      "pe": 17.71,
      "yield": 1.42,
      "date": "2013-07-08"
  },
  "2013-07-09": {
      "equity": 5859,
      "debt": 7.548,
      "gold": 26542.86672,
      "pb": 3,
      "pe": 17.85,
      "yield": 1.41,
      "date": "2013-07-09"
  },
  "2013-07-10": {
      "equity": 5816.7,
      "debt": 7.522,
      "gold": 26489.86049,
      "pb": 2.98,
      "pe": 17.73,
      "yield": 1.42,
      "date": "2013-07-10"
  },
  "2013-07-11": {
      "equity": 5935.1,
      "debt": 7.47,
      "gold": 27079.19364,
      "pb": 3.04,
      "pe": 18.09,
      "yield": 1.39,
      "date": "2013-07-11"
  },
  "2013-07-12": {
      "equity": 6009,
      "debt": 7.535,
      "gold": 26923.88931,
      "pb": 3.08,
      "pe": 18.31,
      "yield": 1.38,
      "date": "2013-07-12"
  },
  "2013-07-13": {
      "equity": 6009,
      "debt": 7.535,
      "gold": 26923.88931,
      "pb": 3.08,
      "pe": 18.31,
      "yield": 1.38,
      "date": "2013-07-13"
  },
  "2013-07-14": {
      "equity": 6009,
      "debt": 7.535,
      "gold": 26923.88931,
      "pb": 3.08,
      "pe": 18.31,
      "yield": 1.38,
      "date": "2013-07-14"
  },
  "2013-07-15": {
      "equity": 6030.8,
      "debt": 7.55,
      "gold": 26805.03713,
      "pb": 3.09,
      "pe": 18.36,
      "yield": 1.37,
      "date": "2013-07-15"
  },
  "2013-07-16": {
      "equity": 5955.2,
      "debt": 8.068,
      "gold": 26698.01231,
      "pb": 3.05,
      "pe": 18.13,
      "yield": 1.39,
      "date": "2013-07-16"
  },
  "2013-07-17": {
      "equity": 5973.3,
      "debt": 8.052,
      "gold": 26854.20907,
      "pb": 3.06,
      "pe": 18.18,
      "yield": 1.38,
      "date": "2013-07-17"
  },
  "2013-07-18": {
      "equity": 6038,
      "debt": 7.987,
      "gold": 26888.767,
      "pb": 3.09,
      "pe": 18.32,
      "yield": 1.37,
      "date": "2013-07-18"
  },
  "2013-07-19": {
      "equity": 6029.2,
      "debt": 7.939,
      "gold": 26947.50525,
      "pb": 3.09,
      "pe": 18.3,
      "yield": 1.37,
      "date": "2013-07-19"
  },
  "2013-07-20": {
      "equity": 6029.2,
      "debt": 7.939,
      "gold": 26947.50525,
      "pb": 3.09,
      "pe": 18.3,
      "yield": 1.37,
      "date": "2013-07-20"
  },
  "2013-07-21": {
      "equity": 6029.2,
      "debt": 7.939,
      "gold": 26947.50525,
      "pb": 3.09,
      "pe": 18.3,
      "yield": 1.37,
      "date": "2013-07-21"
  },
  "2013-07-22": {
      "equity": 6031.8,
      "debt": 8.087,
      "gold": 27517.40948,
      "pb": 3.09,
      "pe": 18.23,
      "yield": 1.37,
      "date": "2013-07-22"
  },
  "2013-07-23": {
      "equity": 6077.8,
      "debt": 8.175,
      "gold": 27799.06171,
      "pb": 3.11,
      "pe": 18.32,
      "yield": 1.36,
      "date": "2013-07-23"
  },
  "2013-07-24": {
      "equity": 5990.5,
      "debt": 8.424,
      "gold": 27998.69486,
      "pb": 3.07,
      "pe": 18.03,
      "yield": 1.38,
      "date": "2013-07-24"
  },
  "2013-07-25": {
      "equity": 5907.5,
      "debt": 8.193,
      "gold": 27288.69292,
      "pb": 3.02,
      "pe": 17.68,
      "yield": 1.4,
      "date": "2013-07-25"
  },
  "2013-07-26": {
      "equity": 5886.2,
      "debt": 8.162,
      "gold": 27714.63694,
      "pb": 3.01,
      "pe": 17.58,
      "yield": 1.4,
      "date": "2013-07-26"
  },
  "2013-07-27": {
      "equity": 5886.2,
      "debt": 8.162,
      "gold": 27714.63694,
      "pb": 3.01,
      "pe": 17.58,
      "yield": 1.4,
      "date": "2013-07-27"
  },
  "2013-07-28": {
      "equity": 5886.2,
      "debt": 8.162,
      "gold": 27714.63694,
      "pb": 3.01,
      "pe": 17.58,
      "yield": 1.4,
      "date": "2013-07-28"
  },
  "2013-07-29": {
      "equity": 5831.6,
      "debt": 8.129,
      "gold": 27835.93009,
      "pb": 2.93,
      "pe": 17.31,
      "yield": 1.44,
      "date": "2013-07-29"
  },
  "2013-07-30": {
      "equity": 5755,
      "debt": 8.255,
      "gold": 28369.4104,
      "pb": 2.85,
      "pe": 17.11,
      "yield": 1.46,
      "date": "2013-07-30"
  },
  "2013-07-31": {
      "equity": 5742,
      "debt": 8.172,
      "gold": 28614.83977,
      "pb": 2.84,
      "pe": 17.05,
      "yield": 1.47,
      "date": "2013-07-31"
  },
  "2013-08-01": {
      "equity": 5727.8,
      "debt": 8.065,
      "gold": 28219.48535,
      "pb": 2.84,
      "pe": 17.03,
      "yield": 1.47,
      "date": "2013-08-01"
  },
  "2013-08-02": {
      "equity": 5677.9,
      "debt": 8.283,
      "gold": 27713.27537,
      "pb": 2.81,
      "pe": 16.88,
      "yield": 1.48,
      "date": "2013-08-02"
  },
  "2013-08-03": {
      "equity": 5677.9,
      "debt": 8.283,
      "gold": 27713.27537,
      "pb": 2.81,
      "pe": 16.88,
      "yield": 1.48,
      "date": "2013-08-03"
  },
  "2013-08-04": {
      "equity": 5677.9,
      "debt": 8.283,
      "gold": 27713.27537,
      "pb": 2.81,
      "pe": 16.88,
      "yield": 1.48,
      "date": "2013-08-04"
  },
  "2013-08-05": {
      "equity": 5685.4,
      "debt": 8.201,
      "gold": 28155.78405,
      "pb": 2.82,
      "pe": 16.94,
      "yield": 1.48,
      "date": "2013-08-05"
  },
  "2013-08-06": {
      "equity": 5542.2,
      "debt": 8.198,
      "gold": 27697.59608,
      "pb": 2.75,
      "pe": 16.51,
      "yield": 1.52,
      "date": "2013-08-06"
  },
  "2013-08-07": {
      "equity": 5519.1,
      "debt": 8.138,
      "gold": 27582.33126,
      "pb": 2.73,
      "pe": 16.36,
      "yield": 1.53,
      "date": "2013-08-07"
  },
  "2013-08-08": {
      "equity": 5565.6,
      "debt": 8.125,
      "gold": 27506.55214,
      "pb": 2.76,
      "pe": 16.5,
      "yield": 1.51,
      "date": "2013-08-08"
  },
  "2013-08-09": {
      "equity": 5565.6,
      "debt": 8.126,
      "gold": 27899.51146,
      "pb": 2.76,
      "pe": 16.5,
      "yield": 1.51,
      "date": "2013-08-09"
  },
  "2013-08-10": {
      "equity": 5565.6,
      "debt": 8.126,
      "gold": 27899.51146,
      "pb": 2.76,
      "pe": 16.5,
      "yield": 1.51,
      "date": "2013-08-10"
  },
  "2013-08-11": {
      "equity": 5565.6,
      "debt": 8.126,
      "gold": 27899.51146,
      "pb": 2.76,
      "pe": 16.5,
      "yield": 1.51,
      "date": "2013-08-11"
  },
  "2013-08-12": {
      "equity": 5612.4,
      "debt": 8.297,
      "gold": 28598.82538,
      "pb": 2.78,
      "pe": 16.59,
      "yield": 1.5,
      "date": "2013-08-12"
  },
  "2013-08-13": {
      "equity": 5699.3,
      "debt": 8.398,
      "gold": 28885.0209,
      "pb": 2.82,
      "pe": 16.84,
      "yield": 1.48,
      "date": "2013-08-13"
  },
  "2013-08-14": {
      "equity": 5742.3,
      "debt": 8.499,
      "gold": 28568.23577,
      "pb": 2.84,
      "pe": 16.97,
      "yield": 1.47,
      "date": "2013-08-14"
  },
  "2013-08-15": {
      "equity": 5742.3,
      "debt": 8.502,
      "gold": 28973.39988,
      "pb": 2.84,
      "pe": 16.97,
      "yield": 1.47,
      "date": "2013-08-15"
  },
  "2013-08-16": {
      "equity": 5507.8,
      "debt": 8.884,
      "gold": 29877.02781,
      "pb": 2.73,
      "pe": 16.35,
      "yield": 1.53,
      "date": "2013-08-16"
  },
  "2013-08-17": {
      "equity": 5507.8,
      "debt": 8.884,
      "gold": 29877.02781,
      "pb": 2.73,
      "pe": 16.35,
      "yield": 1.53,
      "date": "2013-08-17"
  },
  "2013-08-18": {
      "equity": 5507.8,
      "debt": 8.884,
      "gold": 29877.02781,
      "pb": 2.73,
      "pe": 16.35,
      "yield": 1.53,
      "date": "2013-08-18"
  },
  "2013-08-19": {
      "equity": 5414.7,
      "debt": 9.228,
      "gold": 30913.35297,
      "pb": 2.68,
      "pe": 15.62,
      "yield": 1.56,
      "date": "2013-08-19"
  },
  "2013-08-20": {
      "equity": 5401.4,
      "debt": 8.903,
      "gold": 30499.5432,
      "pb": 2.68,
      "pe": 15.58,
      "yield": 1.56,
      "date": "2013-08-20"
  },
  "2013-08-21": {
      "equity": 5302.5,
      "debt": 8.408,
      "gold": 31019.10087,
      "pb": 2.63,
      "pe": 15.3,
      "yield": 1.59,
      "date": "2013-08-21"
  },
  "2013-08-22": {
      "equity": 5408.4,
      "debt": 8.234,
      "gold": 31123.22616,
      "pb": 2.68,
      "pe": 15.6,
      "yield": 1.56,
      "date": "2013-08-22"
  },
  "2013-08-23": {
      "equity": 5471.7,
      "debt": 8.264,
      "gold": 31138.9125,
      "pb": 2.71,
      "pe": 15.79,
      "yield": 1.54,
      "date": "2013-08-23"
  },
  "2013-08-24": {
      "equity": 5471.7,
      "debt": 8.264,
      "gold": 31138.9125,
      "pb": 2.71,
      "pe": 15.79,
      "yield": 1.54,
      "date": "2013-08-24"
  },
  "2013-08-25": {
      "equity": 5471.7,
      "debt": 8.264,
      "gold": 31138.9125,
      "pb": 2.71,
      "pe": 15.79,
      "yield": 1.54,
      "date": "2013-08-25"
  },
  "2013-08-26": {
      "equity": 5476.5,
      "debt": 8.339,
      "gold": 31138.9125,
      "pb": 2.71,
      "pe": 15.8,
      "yield": 1.54,
      "date": "2013-08-26"
  },
  "2013-08-27": {
      "equity": 5287.4,
      "debt": 8.785,
      "gold": 33436.56149,
      "pb": 2.62,
      "pe": 15.24,
      "yield": 1.59,
      "date": "2013-08-27"
  },
  "2013-08-28": {
      "equity": 5285,
      "debt": 8.961,
      "gold": 33880.73511,
      "pb": 2.62,
      "pe": 15.23,
      "yield": 1.6,
      "date": "2013-08-28"
  },
  "2013-08-29": {
      "equity": 5409,
      "debt": 8.769,
      "gold": 33309.12009,
      "pb": 2.68,
      "pe": 15.59,
      "yield": 1.56,
      "date": "2013-08-29"
  },
  "2013-08-30": {
      "equity": 5471.8,
      "debt": 8.596,
      "gold": 32728.97582,
      "pb": 2.71,
      "pe": 15.77,
      "yield": 1.54,
      "date": "2013-08-30"
  },
  "2013-08-31": {
      "equity": 5471.8,
      "debt": 8.596,
      "gold": 32728.97582,
      "pb": 2.71,
      "pe": 15.77,
      "yield": 1.54,
      "date": "2013-08-31"
  },
  "2013-09-01": {
      "equity": 5471.8,
      "debt": 8.602,
      "gold": 32728.97582,
      "pb": 2.71,
      "pe": 15.77,
      "yield": 1.54,
      "date": "2013-09-01"
  },
  "2013-09-02": {
      "equity": 5550.7,
      "debt": 8.463,
      "gold": 32394.02459,
      "pb": 2.75,
      "pe": 16,
      "yield": 1.52,
      "date": "2013-09-02"
  },
  "2013-09-03": {
      "equity": 5341.4,
      "debt": 8.58,
      "gold": 33554.99039,
      "pb": 2.65,
      "pe": 15.4,
      "yield": 1.58,
      "date": "2013-09-03"
  },
  "2013-09-04": {
      "equity": 5448.1,
      "debt": 8.386,
      "gold": 32779.50228,
      "pb": 2.7,
      "pe": 15.7,
      "yield": 1.55,
      "date": "2013-09-04"
  },
  "2013-09-05": {
      "equity": 5592.9,
      "debt": 8.418,
      "gold": 32534.13993,
      "pb": 2.77,
      "pe": 16.12,
      "yield": 1.51,
      "date": "2013-09-05"
  },
  "2013-09-06": {
      "equity": 5680.4,
      "debt": 8.631,
      "gold": 31501.19402,
      "pb": 2.81,
      "pe": 16.37,
      "yield": 1.48,
      "date": "2013-09-06"
  },
  "2013-09-07": {
      "equity": 5680.4,
      "debt": 8.631,
      "gold": 31501.19402,
      "pb": 2.81,
      "pe": 16.37,
      "yield": 1.48,
      "date": "2013-09-07"
  },
  "2013-09-08": {
      "equity": 5680.4,
      "debt": 8.631,
      "gold": 31501.19402,
      "pb": 2.81,
      "pe": 16.37,
      "yield": 1.48,
      "date": "2013-09-08"
  },
  "2013-09-09": {
      "equity": 5680.4,
      "debt": 8.631,
      "gold": 31909.85026,
      "pb": 2.81,
      "pe": 16.37,
      "yield": 1.48,
      "date": "2013-09-09"
  },
  "2013-09-10": {
      "equity": 5896.7,
      "debt": 8.473,
      "gold": 30841.46458,
      "pb": 2.95,
      "pe": 17.14,
      "yield": 1.42,
      "date": "2013-09-10"
  },
  "2013-09-11": {
      "equity": 5913.1,
      "debt": 8.464,
      "gold": 30534.94418,
      "pb": 2.96,
      "pe": 17.19,
      "yield": 1.41,
      "date": "2013-09-11"
  },
  "2013-09-12": {
      "equity": 5850.7,
      "debt": 8.5,
      "gold": 30046.24067,
      "pb": 2.89,
      "pe": 17,
      "yield": 1.44,
      "date": "2013-09-12"
  },
  "2013-09-13": {
      "equity": 5850.6,
      "debt": 8.487,
      "gold": 29215.79217,
      "pb": 2.87,
      "pe": 17,
      "yield": 1.44,
      "date": "2013-09-13"
  },
  "2013-09-14": {
      "equity": 5850.6,
      "debt": 8.487,
      "gold": 29215.79217,
      "pb": 2.87,
      "pe": 17,
      "yield": 1.44,
      "date": "2013-09-14"
  },
  "2013-09-15": {
      "equity": 5850.6,
      "debt": 8.492,
      "gold": 29215.79217,
      "pb": 2.87,
      "pe": 17,
      "yield": 1.44,
      "date": "2013-09-15"
  },
  "2013-09-16": {
      "equity": 5840.5,
      "debt": 8.429,
      "gold": 29193.99284,
      "pb": 2.85,
      "pe": 16.98,
      "yield": 1.45,
      "date": "2013-09-16"
  },
  "2013-09-17": {
      "equity": 5850.2,
      "debt": 8.438,
      "gold": 29319.20492,
      "pb": 2.84,
      "pe": 17,
      "yield": 1.45,
      "date": "2013-09-17"
  },
  "2013-09-18": {
      "equity": 5899.4,
      "debt": 8.372,
      "gold": 29181.85153,
      "pb": 2.86,
      "pe": 17.15,
      "yield": 1.49,
      "date": "2013-09-18"
  },
  "2013-09-19": {
      "equity": 6115.5,
      "debt": 8.193,
      "gold": 29992.71945,
      "pb": 2.95,
      "pe": 17.77,
      "yield": 1.42,
      "date": "2013-09-19"
  },
  "2013-09-20": {
      "equity": 6012.1,
      "debt": 8.578,
      "gold": 29997.68603,
      "pb": 2.88,
      "pe": 17.47,
      "yield": 1.47,
      "date": "2013-09-20"
  },
  "2013-09-21": {
      "equity": 6012.1,
      "debt": 8.578,
      "gold": 29997.68603,
      "pb": 2.88,
      "pe": 17.47,
      "yield": 1.47,
      "date": "2013-09-21"
  },
  "2013-09-22": {
      "equity": 6012.1,
      "debt": 8.576,
      "gold": 29997.68603,
      "pb": 2.88,
      "pe": 17.47,
      "yield": 1.47,
      "date": "2013-09-22"
  },
  "2013-09-23": {
      "equity": 5889.7,
      "debt": 8.851,
      "gold": 29186.24667,
      "pb": 2.82,
      "pe": 17.12,
      "yield": 1.5,
      "date": "2013-09-23"
  },
  "2013-09-24": {
      "equity": 5892.4,
      "debt": 8.839,
      "gold": 29144.61983,
      "pb": 2.79,
      "pe": 17.13,
      "yield": 1.51,
      "date": "2013-09-24"
  },
  "2013-09-25": {
      "equity": 5873.8,
      "debt": 8.786,
      "gold": 28986.40188,
      "pb": 2.79,
      "pe": 17.07,
      "yield": 1.55,
      "date": "2013-09-25"
  },
  "2013-09-26": {
      "equity": 5882.2,
      "debt": 8.718,
      "gold": 29009.76384,
      "pb": 2.79,
      "pe": 17.1,
      "yield": 1.55,
      "date": "2013-09-26"
  },
  "2013-09-27": {
      "equity": 5833.2,
      "debt": 8.71,
      "gold": 29321.03564,
      "pb": 2.82,
      "pe": 17.11,
      "yield": 1.56,
      "date": "2013-09-27"
  },
  "2013-09-28": {
      "equity": 5833.2,
      "debt": 8.71,
      "gold": 29321.03564,
      "pb": 2.82,
      "pe": 17.11,
      "yield": 1.56,
      "date": "2013-09-28"
  },
  "2013-09-29": {
      "equity": 5833.2,
      "debt": 8.71,
      "gold": 29321.03564,
      "pb": 2.82,
      "pe": 17.11,
      "yield": 1.56,
      "date": "2013-09-29"
  },
  "2013-09-30": {
      "equity": 5735.3,
      "debt": 8.766,
      "gold": 29521.53654,
      "pb": 2.77,
      "pe": 16.82,
      "yield": 1.56,
      "date": "2013-09-30"
  },
  "2013-10-01": {
      "equity": 5780,
      "debt": 8.728,
      "gold": 29400.24339,
      "pb": 2.78,
      "pe": 16.95,
      "yield": 1.56,
      "date": "2013-10-01"
  },
  "2013-10-02": {
      "equity": 5780,
      "debt": 8.735,
      "gold": 28564.08402,
      "pb": 2.78,
      "pe": 16.95,
      "yield": 1.56,
      "date": "2013-10-02"
  },
  "2013-10-03": {
      "equity": 5909.7,
      "debt": 8.635,
      "gold": 28635.28457,
      "pb": 2.82,
      "pe": 17.33,
      "yield": 1.54,
      "date": "2013-10-03"
  },
  "2013-10-04": {
      "equity": 5907.3,
      "debt": 8.613,
      "gold": 28495.96289,
      "pb": 2.81,
      "pe": 17.32,
      "yield": 1.56,
      "date": "2013-10-04"
  },
  "2013-10-05": {
      "equity": 5907.3,
      "debt": 8.613,
      "gold": 28495.96289,
      "pb": 2.81,
      "pe": 17.32,
      "yield": 1.56,
      "date": "2013-10-05"
  },
  "2013-10-06": {
      "equity": 5907.3,
      "debt": 8.613,
      "gold": 28495.96289,
      "pb": 2.81,
      "pe": 17.32,
      "yield": 1.56,
      "date": "2013-10-06"
  },
  "2013-10-07": {
      "equity": 5906.1,
      "debt": 8.683,
      "gold": 28509.78324,
      "pb": 2.81,
      "pe": 17.32,
      "yield": 1.56,
      "date": "2013-10-07"
  },
  "2013-10-08": {
      "equity": 5928.4,
      "debt": 8.496,
      "gold": 28785.259,
      "pb": 2.82,
      "pe": 17.38,
      "yield": 1.56,
      "date": "2013-10-08"
  },
  "2013-10-09": {
      "equity": 6007.4,
      "debt": 8.455,
      "gold": 28594.39496,
      "pb": 2.86,
      "pe": 17.62,
      "yield": 1.54,
      "date": "2013-10-09"
  },
  "2013-10-10": {
      "equity": 6020.9,
      "debt": 8.421,
      "gold": 27961.06104,
      "pb": 2.86,
      "pe": 17.66,
      "yield": 1.53,
      "date": "2013-10-10"
  },
  "2013-10-11": {
      "equity": 6096.2,
      "debt": 8.489,
      "gold": 27667.60613,
      "pb": 2.9,
      "pe": 17.88,
      "yield": 1.52,
      "date": "2013-10-11"
  },
  "2013-10-12": {
      "equity": 6096.2,
      "debt": 8.489,
      "gold": 27667.60613,
      "pb": 2.9,
      "pe": 17.88,
      "yield": 1.52,
      "date": "2013-10-12"
  },
  "2013-10-13": {
      "equity": 6096.2,
      "debt": 8.489,
      "gold": 27667.60613,
      "pb": 2.9,
      "pe": 17.88,
      "yield": 1.52,
      "date": "2013-10-13"
  },
  "2013-10-14": {
      "equity": 6112.7,
      "debt": 8.575,
      "gold": 27633.19988,
      "pb": 2.91,
      "pe": 17.94,
      "yield": 1.51,
      "date": "2013-10-14"
  },
  "2013-10-15": {
      "equity": 6089,
      "debt": 8.66,
      "gold": 27354.44011,
      "pb": 2.9,
      "pe": 17.85,
      "yield": 1.52,
      "date": "2013-10-15"
  },
  "2013-10-16": {
      "equity": 6089,
      "debt": 8.663,
      "gold": 27850.11023,
      "pb": 2.9,
      "pe": 17.85,
      "yield": 1.52,
      "date": "2013-10-16"
  },
  "2013-10-17": {
      "equity": 6045.8,
      "debt": 8.602,
      "gold": 28187.24845,
      "pb": 2.88,
      "pe": 17.63,
      "yield": 1.53,
      "date": "2013-10-17"
  },
  "2013-10-18": {
      "equity": 6189.3,
      "debt": 8.552,
      "gold": 28407.9825,
      "pb": 2.95,
      "pe": 18.02,
      "yield": 1.49,
      "date": "2013-10-18"
  },
  "2013-10-19": {
      "equity": 6189.3,
      "debt": 8.552,
      "gold": 28407.9825,
      "pb": 2.95,
      "pe": 18.02,
      "yield": 1.49,
      "date": "2013-10-19"
  },
  "2013-10-20": {
      "equity": 6189.3,
      "debt": 8.552,
      "gold": 28407.9825,
      "pb": 2.95,
      "pe": 18.02,
      "yield": 1.49,
      "date": "2013-10-20"
  },
  "2013-10-21": {
      "equity": 6204.9,
      "debt": 8.604,
      "gold": 28597.62606,
      "pb": 2.96,
      "pe": 18.07,
      "yield": 1.49,
      "date": "2013-10-21"
  },
  "2013-10-22": {
      "equity": 6202.8,
      "debt": 8.606,
      "gold": 28329.90353,
      "pb": 2.95,
      "pe": 18.01,
      "yield": 1.49,
      "date": "2013-10-22"
  },
  "2013-10-23": {
      "equity": 6178.3,
      "debt": 8.631,
      "gold": 28922.14325,
      "pb": 2.93,
      "pe": 17.83,
      "yield": 1.49,
      "date": "2013-10-23"
  },
  "2013-10-24": {
      "equity": 6164.3,
      "debt": 8.583,
      "gold": 28971.44923,
      "pb": 2.93,
      "pe": 17.79,
      "yield": 1.5,
      "date": "2013-10-24"
  },
  "2013-10-25": {
      "equity": 6144.9,
      "debt": 8.579,
      "gold": 29095.09515,
      "pb": 2.92,
      "pe": 17.73,
      "yield": 1.5,
      "date": "2013-10-25"
  },
  "2013-10-26": {
      "equity": 6144.9,
      "debt": 8.579,
      "gold": 29095.09515,
      "pb": 2.92,
      "pe": 17.73,
      "yield": 1.5,
      "date": "2013-10-26"
  },
  "2013-10-27": {
      "equity": 6144.9,
      "debt": 8.579,
      "gold": 29095.09515,
      "pb": 2.92,
      "pe": 17.73,
      "yield": 1.5,
      "date": "2013-10-27"
  },
  "2013-10-28": {
      "equity": 6101.1,
      "debt": 8.656,
      "gold": 29314.35828,
      "pb": 2.9,
      "pe": 17.61,
      "yield": 1.51,
      "date": "2013-10-28"
  },
  "2013-10-29": {
      "equity": 6220.9,
      "debt": 8.535,
      "gold": 29208.26822,
      "pb": 2.95,
      "pe": 17.95,
      "yield": 1.48,
      "date": "2013-10-29"
  },
  "2013-10-30": {
      "equity": 6251.7,
      "debt": 8.573,
      "gold": 29170.65909,
      "pb": 2.97,
      "pe": 18.04,
      "yield": 1.47,
      "date": "2013-10-30"
  },
  "2013-10-31": {
      "equity": 6299.1,
      "debt": 8.625,
      "gold": 29093.43375,
      "pb": 2.99,
      "pe": 18.18,
      "yield": 1.46,
      "date": "2013-10-31"
  },
  "2013-11-01": {
      "equity": 6307.2,
      "debt": 8.7,
      "gold": 28853.12616,
      "pb": 3,
      "pe": 18.2,
      "yield": 1.46,
      "date": "2013-11-01"
  },
  "2013-11-02": {
      "equity": 6307.2,
      "debt": 8.7,
      "gold": 28853.12616,
      "pb": 3,
      "pe": 18.2,
      "yield": 1.46,
      "date": "2013-11-02"
  },
  "2013-11-03": {
      "equity": 6317.3,
      "debt": 8.705,
      "gold": 28853.12616,
      "pb": 3,
      "pe": 18.23,
      "yield": 1.46,
      "date": "2013-11-03"
  },
  "2013-11-04": {
      "equity": 6317.3,
      "debt": 8.705,
      "gold": 28842.15242,
      "pb": 3,
      "pe": 18.23,
      "yield": 1.46,
      "date": "2013-11-04"
  },
  "2013-11-05": {
      "equity": 6253.1,
      "debt": 8.737,
      "gold": 28774.00307,
      "pb": 2.97,
      "pe": 18.06,
      "yield": 1.47,
      "date": "2013-11-05"
  },
  "2013-11-06": {
      "equity": 6215.1,
      "debt": 8.82,
      "gold": 29036.90012,
      "pb": 2.95,
      "pe": 17.92,
      "yield": 1.48,
      "date": "2013-11-06"
  },
  "2013-11-07": {
      "equity": 6187.2,
      "debt": 8.849,
      "gold": 29138.19291,
      "pb": 2.94,
      "pe": 17.78,
      "yield": 1.49,
      "date": "2013-11-07"
  },
  "2013-11-08": {
      "equity": 6140.7,
      "debt": 8.99,
      "gold": 29251.70109,
      "pb": 2.92,
      "pe": 17.65,
      "yield": 1.5,
      "date": "2013-11-08"
  },
  "2013-11-09": {
      "equity": 6140.7,
      "debt": 8.99,
      "gold": 29251.70109,
      "pb": 2.92,
      "pe": 17.65,
      "yield": 1.5,
      "date": "2013-11-09"
  },
  "2013-11-10": {
      "equity": 6140.7,
      "debt": 8.99,
      "gold": 29251.70109,
      "pb": 2.92,
      "pe": 17.65,
      "yield": 1.5,
      "date": "2013-11-10"
  },
  "2013-11-11": {
      "equity": 6078.8,
      "debt": 8.954,
      "gold": 28761.48786,
      "pb": 2.89,
      "pe": 17.48,
      "yield": 1.52,
      "date": "2013-11-11"
  },
  "2013-11-12": {
      "equity": 6018,
      "debt": 9.053,
      "gold": 28934.16462,
      "pb": 2.86,
      "pe": 17.31,
      "yield": 1.53,
      "date": "2013-11-12"
  },
  "2013-11-13": {
      "equity": 5989.6,
      "debt": 8.918,
      "gold": 28485.9098,
      "pb": 2.85,
      "pe": 17.23,
      "yield": 1.54,
      "date": "2013-11-13"
  },
  "2013-11-14": {
      "equity": 6056.1,
      "debt": 9.024,
      "gold": 28543.6498,
      "pb": 2.88,
      "pe": 17.42,
      "yield": 1.52,
      "date": "2013-11-14"
  },
  "2013-11-15": {
      "equity": 6056.1,
      "debt": 9.024,
      "gold": 28508.38639,
      "pb": 2.88,
      "pe": 17.42,
      "yield": 1.52,
      "date": "2013-11-15"
  },
  "2013-11-16": {
      "equity": 6056.1,
      "debt": 9.024,
      "gold": 28508.38639,
      "pb": 2.88,
      "pe": 17.42,
      "yield": 1.52,
      "date": "2013-11-16"
  },
  "2013-11-17": {
      "equity": 6056.1,
      "debt": 9.024,
      "gold": 28508.38639,
      "pb": 2.88,
      "pe": 17.42,
      "yield": 1.52,
      "date": "2013-11-17"
  },
  "2013-11-18": {
      "equity": 6189,
      "debt": 9,
      "gold": 28115.93855,
      "pb": 2.95,
      "pe": 17.76,
      "yield": 1.49,
      "date": "2013-11-18"
  },
  "2013-11-19": {
      "equity": 6203.3,
      "debt": 9.009,
      "gold": 27947.8086,
      "pb": 2.95,
      "pe": 17.85,
      "yield": 1.49,
      "date": "2013-11-19"
  },
  "2013-11-20": {
      "equity": 6122.9,
      "debt": 9.035,
      "gold": 27966.9906,
      "pb": 2.91,
      "pe": 17.61,
      "yield": 1.51,
      "date": "2013-11-20"
  },
  "2013-11-21": {
      "equity": 5999,
      "debt": 9.077,
      "gold": 27740.53158,
      "pb": 2.85,
      "pe": 17.26,
      "yield": 1.54,
      "date": "2013-11-21"
  },
  "2013-11-22": {
      "equity": 5995.4,
      "debt": 9.099,
      "gold": 27441.62684,
      "pb": 2.85,
      "pe": 17.71,
      "yield": 1.55,
      "date": "2013-11-22"
  },
  "2013-11-23": {
      "equity": 5995.4,
      "debt": 9.099,
      "gold": 27441.62684,
      "pb": 2.85,
      "pe": 17.71,
      "yield": 1.55,
      "date": "2013-11-23"
  },
  "2013-11-24": {
      "equity": 5995.4,
      "debt": 9.099,
      "gold": 27441.62684,
      "pb": 2.85,
      "pe": 17.71,
      "yield": 1.55,
      "date": "2013-11-24"
  },
  "2013-11-25": {
      "equity": 6115.3,
      "debt": 9.087,
      "gold": 27057.91989,
      "pb": 2.9,
      "pe": 18.06,
      "yield": 1.52,
      "date": "2013-11-25"
  },
  "2013-11-26": {
      "equity": 6059.1,
      "debt": 9.042,
      "gold": 27545.66394,
      "pb": 2.88,
      "pe": 17.94,
      "yield": 1.53,
      "date": "2013-11-26"
  },
  "2013-11-27": {
      "equity": 6057.1,
      "debt": 8.996,
      "gold": 27572.57447,
      "pb": 2.88,
      "pe": 18.03,
      "yield": 1.54,
      "date": "2013-11-27"
  },
  "2013-11-28": {
      "equity": 6091.8,
      "debt": 9.014,
      "gold": 27297.29978,
      "pb": 2.89,
      "pe": 18.13,
      "yield": 1.53,
      "date": "2013-11-28"
  },
  "2013-11-29": {
      "equity": 6176.1,
      "debt": 9.044,
      "gold": 27381.92561,
      "pb": 2.93,
      "pe": 18.38,
      "yield": 1.51,
      "date": "2013-11-29"
  },
  "2013-11-30": {
      "equity": 6176.1,
      "debt": 9.044,
      "gold": 27381.92561,
      "pb": 2.93,
      "pe": 18.38,
      "yield": 1.51,
      "date": "2013-11-30"
  },
  "2013-12-01": {
      "equity": 6176.1,
      "debt": 9.044,
      "gold": 27381.92561,
      "pb": 2.93,
      "pe": 18.38,
      "yield": 1.51,
      "date": "2013-12-01"
  },
  "2013-12-02": {
      "equity": 6217.8,
      "debt": 9.048,
      "gold": 27223.29847,
      "pb": 2.95,
      "pe": 18.51,
      "yield": 1.5,
      "date": "2013-12-02"
  },
  "2013-12-03": {
      "equity": 6201.8,
      "debt": 9.069,
      "gold": 26852.44184,
      "pb": 2.94,
      "pe": 18.46,
      "yield": 1.51,
      "date": "2013-12-03"
  },
  "2013-12-04": {
      "equity": 6160.9,
      "debt": 9.09,
      "gold": 26434.24046,
      "pb": 2.92,
      "pe": 18.34,
      "yield": 1.52,
      "date": "2013-12-04"
  },
  "2013-12-05": {
      "equity": 6241.1,
      "debt": 9.108,
      "gold": 26786.54297,
      "pb": 2.96,
      "pe": 18.58,
      "yield": 1.5,
      "date": "2013-12-05"
  },
  "2013-12-06": {
      "equity": 6259.9,
      "debt": 9.165,
      "gold": 26567.30454,
      "pb": 2.97,
      "pe": 18.58,
      "yield": 1.49,
      "date": "2013-12-06"
  },
  "2013-12-07": {
      "equity": 6259.9,
      "debt": 9.165,
      "gold": 26567.30454,
      "pb": 2.97,
      "pe": 18.58,
      "yield": 1.49,
      "date": "2013-12-07"
  },
  "2013-12-08": {
      "equity": 6259.9,
      "debt": 9.165,
      "gold": 26567.30454,
      "pb": 2.97,
      "pe": 18.58,
      "yield": 1.49,
      "date": "2013-12-08"
  },
  "2013-12-09": {
      "equity": 6363.9,
      "debt": 8.904,
      "gold": 26389.8164,
      "pb": 3.02,
      "pe": 18.9,
      "yield": 1.47,
      "date": "2013-12-09"
  },
  "2013-12-10": {
      "equity": 6332.8,
      "debt": 8.835,
      "gold": 26831.33741,
      "pb": 3.01,
      "pe": 18.8,
      "yield": 1.47,
      "date": "2013-12-10"
  },
  "2013-12-11": {
      "equity": 6307.9,
      "debt": 8.826,
      "gold": 27221.25258,
      "pb": 3,
      "pe": 18.73,
      "yield": 1.48,
      "date": "2013-12-11"
  },
  "2013-12-12": {
      "equity": 6237,
      "debt": 8.85,
      "gold": 27274.3611,
      "pb": 2.96,
      "pe": 18.52,
      "yield": 1.5,
      "date": "2013-12-12"
  },
  "2013-12-13": {
      "equity": 6168.4,
      "debt": 8.911,
      "gold": 26816.43768,
      "pb": 2.93,
      "pe": 18.32,
      "yield": 1.51,
      "date": "2013-12-13"
  },
  "2013-12-14": {
      "equity": 6168.4,
      "debt": 8.911,
      "gold": 26816.43768,
      "pb": 2.93,
      "pe": 18.32,
      "yield": 1.51,
      "date": "2013-12-14"
  },
  "2013-12-15": {
      "equity": 6168.4,
      "debt": 8.911,
      "gold": 26816.43768,
      "pb": 2.93,
      "pe": 18.32,
      "yield": 1.51,
      "date": "2013-12-15"
  },
  "2013-12-16": {
      "equity": 6154.7,
      "debt": 8.865,
      "gold": 26796.41616,
      "pb": 2.92,
      "pe": 18.27,
      "yield": 1.52,
      "date": "2013-12-16"
  },
  "2013-12-17": {
      "equity": 6139,
      "debt": 8.915,
      "gold": 26924.73589,
      "pb": 2.91,
      "pe": 18.23,
      "yield": 1.52,
      "date": "2013-12-17"
  },
  "2013-12-18": {
      "equity": 6217.1,
      "debt": 8.784,
      "gold": 26994.94876,
      "pb": 2.95,
      "pe": 18.46,
      "yield": 1.5,
      "date": "2013-12-18"
  },
  "2013-12-19": {
      "equity": 6166.6,
      "debt": 8.743,
      "gold": 26545.30062,
      "pb": 2.93,
      "pe": 18.31,
      "yield": 1.51,
      "date": "2013-12-19"
  },
  "2013-12-20": {
      "equity": 6274.2,
      "debt": 8.797,
      "gold": 26110.68273,
      "pb": 2.98,
      "pe": 18.63,
      "yield": 1.49,
      "date": "2013-12-20"
  },
  "2013-12-21": {
      "equity": 6274.2,
      "debt": 8.797,
      "gold": 26110.68273,
      "pb": 2.98,
      "pe": 18.63,
      "yield": 1.49,
      "date": "2013-12-21"
  },
  "2013-12-22": {
      "equity": 6274.2,
      "debt": 8.797,
      "gold": 26110.68273,
      "pb": 2.98,
      "pe": 18.63,
      "yield": 1.49,
      "date": "2013-12-22"
  },
  "2013-12-23": {
      "equity": 6284.5,
      "debt": 8.817,
      "gold": 25988.52537,
      "pb": 2.98,
      "pe": 18.66,
      "yield": 1.49,
      "date": "2013-12-23"
  },
  "2013-12-24": {
      "equity": 6268.4,
      "debt": 8.866,
      "gold": 26084.79162,
      "pb": 2.98,
      "pe": 18.61,
      "yield": 1.49,
      "date": "2013-12-24"
  },
  "2013-12-25": {
      "equity": 6268.4,
      "debt": 8.869,
      "gold": 26084.79162,
      "pb": 2.98,
      "pe": 18.61,
      "yield": 1.49,
      "date": "2013-12-25"
  },
  "2013-12-26": {
      "equity": 6278.9,
      "debt": 8.889,
      "gold": 26084.79162,
      "pb": 2.98,
      "pe": 18.64,
      "yield": 1.49,
      "date": "2013-12-26"
  },
  "2013-12-27": {
      "equity": 6313.8,
      "debt": 8.959,
      "gold": 26439.31992,
      "pb": 3,
      "pe": 18.75,
      "yield": 1.48,
      "date": "2013-12-27"
  },
  "2013-12-28": {
      "equity": 6313.8,
      "debt": 8.959,
      "gold": 26439.31992,
      "pb": 3,
      "pe": 18.75,
      "yield": 1.48,
      "date": "2013-12-28"
  },
  "2013-12-29": {
      "equity": 6313.8,
      "debt": 8.959,
      "gold": 26439.31992,
      "pb": 3,
      "pe": 18.75,
      "yield": 1.48,
      "date": "2013-12-29"
  },
  "2013-12-30": {
      "equity": 6291.1,
      "debt": 8.858,
      "gold": 26200.15521,
      "pb": 2.99,
      "pe": 18.66,
      "yield": 1.49,
      "date": "2013-12-30"
  },
  "2013-12-31": {
      "equity": 6304,
      "debt": 8.82,
      "gold": 26215.19956,
      "pb": 2.99,
      "pe": 18.7,
      "yield": 1.48,
      "date": "2013-12-31"
  },
  "2014-01-01": {
      "equity": 6301.6,
      "debt": 8.844,
      "gold": 26215.19956,
      "pb": 2.99,
      "pe": 18.69,
      "yield": 1.49,
      "date": "2014-01-01"
  },
  "2014-01-02": {
      "equity": 6221.1,
      "debt": 8.84,
      "gold": 26811.30884,
      "pb": 2.95,
      "pe": 18.46,
      "yield": 1.5,
      "date": "2014-01-02"
  },
  "2014-01-03": {
      "equity": 6211.1,
      "debt": 8.834,
      "gold": 27044.78033,
      "pb": 2.95,
      "pe": 18.43,
      "yield": 1.51,
      "date": "2014-01-03"
  },
  "2014-01-04": {
      "equity": 6211.1,
      "debt": 8.834,
      "gold": 27044.78033,
      "pb": 2.95,
      "pe": 18.43,
      "yield": 1.51,
      "date": "2014-01-04"
  },
  "2014-01-05": {
      "equity": 6211.1,
      "debt": 8.834,
      "gold": 27044.78033,
      "pb": 2.95,
      "pe": 18.43,
      "yield": 1.51,
      "date": "2014-01-05"
  },
  "2014-01-06": {
      "equity": 6191.4,
      "debt": 8.783,
      "gold": 27225.56306,
      "pb": 2.94,
      "pe": 18.37,
      "yield": 1.51,
      "date": "2014-01-06"
  },
  "2014-01-07": {
      "equity": 6162.2,
      "debt": 8.789,
      "gold": 27135.12055,
      "pb": 2.92,
      "pe": 18.28,
      "yield": 1.52,
      "date": "2014-01-07"
  },
  "2014-01-08": {
      "equity": 6174.6,
      "debt": 8.801,
      "gold": 26881.80744,
      "pb": 2.93,
      "pe": 18.32,
      "yield": 1.52,
      "date": "2014-01-08"
  },
  "2014-01-09": {
      "equity": 6168.3,
      "debt": 8.79,
      "gold": 26838.41338,
      "pb": 2.93,
      "pe": 18.3,
      "yield": 1.52,
      "date": "2014-01-09"
  },
  "2014-01-10": {
      "equity": 6171.4,
      "debt": 8.758,
      "gold": 26733.55791,
      "pb": 2.93,
      "pe": 18.24,
      "yield": 1.52,
      "date": "2014-01-10"
  },
  "2014-01-11": {
      "equity": 6171.4,
      "debt": 8.758,
      "gold": 26733.55791,
      "pb": 2.93,
      "pe": 18.24,
      "yield": 1.52,
      "date": "2014-01-11"
  },
  "2014-01-12": {
      "equity": 6171.4,
      "debt": 8.758,
      "gold": 26733.55791,
      "pb": 2.93,
      "pe": 18.24,
      "yield": 1.52,
      "date": "2014-01-12"
  },
  "2014-01-13": {
      "equity": 6272.7,
      "debt": 8.711,
      "gold": 27012.74449,
      "pb": 2.98,
      "pe": 18.55,
      "yield": 1.49,
      "date": "2014-01-13"
  },
  "2014-01-14": {
      "equity": 6241.8,
      "debt": 8.711,
      "gold": 27083.15491,
      "pb": 2.96,
      "pe": 18.44,
      "yield": 1.5,
      "date": "2014-01-14"
  },
  "2014-01-15": {
      "equity": 6320.9,
      "debt": 8.639,
      "gold": 26893.67714,
      "pb": 3,
      "pe": 18.68,
      "yield": 1.48,
      "date": "2014-01-15"
  },
  "2014-01-16": {
      "equity": 6318.9,
      "debt": 8.615,
      "gold": 26835.70433,
      "pb": 3,
      "pe": 18.63,
      "yield": 1.48,
      "date": "2014-01-16"
  },
  "2014-01-17": {
      "equity": 6261.6,
      "debt": 8.627,
      "gold": 26987.52006,
      "pb": 2.97,
      "pe": 18.45,
      "yield": 1.5,
      "date": "2014-01-17"
  },
  "2014-01-18": {
      "equity": 6261.6,
      "debt": 8.627,
      "gold": 26987.52006,
      "pb": 2.97,
      "pe": 18.45,
      "yield": 1.5,
      "date": "2014-01-18"
  },
  "2014-01-19": {
      "equity": 6261.6,
      "debt": 8.627,
      "gold": 26987.52006,
      "pb": 2.97,
      "pe": 18.45,
      "yield": 1.5,
      "date": "2014-01-19"
  },
  "2014-01-20": {
      "equity": 6303.9,
      "debt": 8.517,
      "gold": 27240.94958,
      "pb": 2.99,
      "pe": 18.35,
      "yield": 1.49,
      "date": "2014-01-20"
  },
  "2014-01-21": {
      "equity": 6313.8,
      "debt": 8.553,
      "gold": 27273.39107,
      "pb": 2.99,
      "pe": 18.38,
      "yield": 1.48,
      "date": "2014-01-21"
  },
  "2014-01-22": {
      "equity": 6338.9,
      "debt": 8.608,
      "gold": 27055.2426,
      "pb": 3,
      "pe": 18.45,
      "yield": 1.48,
      "date": "2014-01-22"
  },
  "2014-01-23": {
      "equity": 6345.6,
      "debt": 8.665,
      "gold": 27318.24194,
      "pb": 3,
      "pe": 18.49,
      "yield": 1.48,
      "date": "2014-01-23"
  },
  "2014-01-24": {
      "equity": 6266.7,
      "debt": 8.738,
      "gold": 27851.4577,
      "pb": 2.97,
      "pe": 18.25,
      "yield": 1.49,
      "date": "2014-01-24"
  },
  "2014-01-25": {
      "equity": 6266.7,
      "debt": 8.738,
      "gold": 27851.4577,
      "pb": 2.97,
      "pe": 18.25,
      "yield": 1.49,
      "date": "2014-01-25"
  },
  "2014-01-26": {
      "equity": 6266.7,
      "debt": 8.738,
      "gold": 27851.4577,
      "pb": 2.97,
      "pe": 18.25,
      "yield": 1.49,
      "date": "2014-01-26"
  },
  "2014-01-27": {
      "equity": 6135.8,
      "debt": 8.765,
      "gold": 28439.7679,
      "pb": 2.9,
      "pe": 17.85,
      "yield": 1.53,
      "date": "2014-01-27"
  },
  "2014-01-28": {
      "equity": 6126.2,
      "debt": 8.748,
      "gold": 27743.51576,
      "pb": 2.9,
      "pe": 17.82,
      "yield": 1.53,
      "date": "2014-01-28"
  },
  "2014-01-29": {
      "equity": 6120.2,
      "debt": 8.774,
      "gold": 27845.32002,
      "pb": 2.9,
      "pe": 17.79,
      "yield": 1.53,
      "date": "2014-01-29"
  },
  "2014-01-30": {
      "equity": 6073.7,
      "debt": 8.82,
      "gold": 27639.13297,
      "pb": 2.87,
      "pe": 17.65,
      "yield": 1.54,
      "date": "2014-01-30"
  },
  "2014-01-31": {
      "equity": 6089.5,
      "debt": 8.77,
      "gold": 27515.81862,
      "pb": 2.88,
      "pe": 17.7,
      "yield": 1.54,
      "date": "2014-01-31"
  },
  "2014-02-01": {
      "equity": 6089.5,
      "debt": 8.77,
      "gold": 27515.81862,
      "pb": 2.88,
      "pe": 17.7,
      "yield": 1.54,
      "date": "2014-02-01"
  },
  "2014-02-02": {
      "equity": 6089.5,
      "debt": 8.77,
      "gold": 27515.81862,
      "pb": 2.88,
      "pe": 17.7,
      "yield": 1.54,
      "date": "2014-02-02"
  },
  "2014-02-03": {
      "equity": 6001.8,
      "debt": 8.734,
      "gold": 27578.91321,
      "pb": 2.84,
      "pe": 17.34,
      "yield": 1.56,
      "date": "2014-02-03"
  },
  "2014-02-04": {
      "equity": 6000.9,
      "debt": 8.679,
      "gold": 27527.59308,
      "pb": 2.84,
      "pe": 17.35,
      "yield": 1.56,
      "date": "2014-02-04"
  },
  "2014-02-05": {
      "equity": 6022.4,
      "debt": 8.707,
      "gold": 27707.02834,
      "pb": 2.85,
      "pe": 17.41,
      "yield": 1.56,
      "date": "2014-02-05"
  },
  "2014-02-06": {
      "equity": 6036.3,
      "debt": 8.717,
      "gold": 27585.60821,
      "pb": 2.86,
      "pe": 17.16,
      "yield": 1.55,
      "date": "2014-02-06"
  },
  "2014-02-07": {
      "equity": 6063.2,
      "debt": 8.736,
      "gold": 27586.04208,
      "pb": 2.87,
      "pe": 17.25,
      "yield": 1.54,
      "date": "2014-02-07"
  },
  "2014-02-08": {
      "equity": 6063.2,
      "debt": 8.736,
      "gold": 27586.04208,
      "pb": 2.87,
      "pe": 17.25,
      "yield": 1.54,
      "date": "2014-02-08"
  },
  "2014-02-09": {
      "equity": 6063.2,
      "debt": 8.736,
      "gold": 27586.04208,
      "pb": 2.87,
      "pe": 17.25,
      "yield": 1.54,
      "date": "2014-02-09"
  },
  "2014-02-10": {
      "equity": 6053.4,
      "debt": 8.69,
      "gold": 28001.32277,
      "pb": 2.87,
      "pe": 17.24,
      "yield": 1.55,
      "date": "2014-02-10"
  },
  "2014-02-11": {
      "equity": 6062.7,
      "debt": 8.74,
      "gold": 28094.07926,
      "pb": 2.87,
      "pe": 17.26,
      "yield": 1.54,
      "date": "2014-02-11"
  },
  "2014-02-12": {
      "equity": 6084,
      "debt": 8.811,
      "gold": 28072.28699,
      "pb": 2.88,
      "pe": 17.32,
      "yield": 1.54,
      "date": "2014-02-12"
  },
  "2014-02-13": {
      "equity": 6001.1,
      "debt": 8.865,
      "gold": 28316.13961,
      "pb": 2.84,
      "pe": 17.09,
      "yield": 1.56,
      "date": "2014-02-13"
  },
  "2014-02-14": {
      "equity": 6048.3,
      "debt": 8.808,
      "gold": 28550.49648,
      "pb": 2.87,
      "pe": 17.22,
      "yield": 1.55,
      "date": "2014-02-14"
  },
  "2014-02-15": {
      "equity": 6048.3,
      "debt": 8.808,
      "gold": 28550.49648,
      "pb": 2.87,
      "pe": 17.22,
      "yield": 1.55,
      "date": "2014-02-15"
  },
  "2014-02-16": {
      "equity": 6048.3,
      "debt": 8.808,
      "gold": 28550.49648,
      "pb": 2.87,
      "pe": 17.22,
      "yield": 1.55,
      "date": "2014-02-16"
  },
  "2014-02-17": {
      "equity": 6073.3,
      "debt": 8.805,
      "gold": 28907.31053,
      "pb": 2.87,
      "pe": 17.28,
      "yield": 1.54,
      "date": "2014-02-17"
  },
  "2014-02-18": {
      "equity": 6127.1,
      "debt": 8.768,
      "gold": 28809.5522,
      "pb": 2.9,
      "pe": 17.59,
      "yield": 1.53,
      "date": "2014-02-18"
  },
  "2014-02-19": {
      "equity": 6152.7,
      "debt": 8.768,
      "gold": 28917.41653,
      "pb": 2.91,
      "pe": 17.59,
      "yield": 1.52,
      "date": "2014-02-19"
  },
  "2014-02-20": {
      "equity": 6091.4,
      "debt": 8.788,
      "gold": 28801.05822,
      "pb": 2.88,
      "pe": 17.42,
      "yield": 1.54,
      "date": "2014-02-20"
  },
  "2014-02-21": {
      "equity": 6155.4,
      "debt": 8.795,
      "gold": 28954.51772,
      "pb": 2.91,
      "pe": 17.33,
      "yield": 1.52,
      "date": "2014-02-21"
  },
  "2014-02-22": {
      "equity": 6155.4,
      "debt": 8.795,
      "gold": 28954.51772,
      "pb": 2.91,
      "pe": 17.33,
      "yield": 1.52,
      "date": "2014-02-22"
  },
  "2014-02-23": {
      "equity": 6155.4,
      "debt": 8.795,
      "gold": 28954.51772,
      "pb": 2.91,
      "pe": 17.33,
      "yield": 1.52,
      "date": "2014-02-23"
  },
  "2014-02-24": {
      "equity": 6186.1,
      "debt": 8.885,
      "gold": 29088.83049,
      "pb": 2.93,
      "pe": 17.42,
      "yield": 1.51,
      "date": "2014-02-24"
  },
  "2014-02-25": {
      "equity": 6200,
      "debt": 8.875,
      "gold": 29058.92873,
      "pb": 2.93,
      "pe": 17.46,
      "yield": 1.51,
      "date": "2014-02-25"
  },
  "2014-02-26": {
      "equity": 6238.8,
      "debt": 8.915,
      "gold": 29363.29741,
      "pb": 2.95,
      "pe": 17.57,
      "yield": 1.5,
      "date": "2014-02-26"
  },
  "2014-02-27": {
      "equity": 6238.8,
      "debt": 8.915,
      "gold": 29170.07355,
      "pb": 2.95,
      "pe": 17.57,
      "yield": 1.5,
      "date": "2014-02-27"
  },
  "2014-02-28": {
      "equity": 6276.9,
      "debt": 8.862,
      "gold": 28944.28826,
      "pb": 2.97,
      "pe": 17.67,
      "yield": 1.49,
      "date": "2014-02-28"
  },
  "2014-03-01": {
      "equity": 6276.9,
      "debt": 8.862,
      "gold": 28944.28826,
      "pb": 2.97,
      "pe": 17.67,
      "yield": 1.49,
      "date": "2014-03-01"
  },
  "2014-03-02": {
      "equity": 6276.9,
      "debt": 8.862,
      "gold": 28944.28826,
      "pb": 2.97,
      "pe": 17.67,
      "yield": 1.49,
      "date": "2014-03-02"
  },
  "2014-03-03": {
      "equity": 6221.4,
      "debt": 8.904,
      "gold": 29475.63096,
      "pb": 2.94,
      "pe": 17.52,
      "yield": 1.5,
      "date": "2014-03-03"
  },
  "2014-03-04": {
      "equity": 6297.9,
      "debt": 8.836,
      "gold": 29251.46475,
      "pb": 2.98,
      "pe": 17.73,
      "yield": 1.49,
      "date": "2014-03-04"
  },
  "2014-03-05": {
      "equity": 6328.6,
      "debt": 8.842,
      "gold": 28876.54809,
      "pb": 2.99,
      "pe": 17.82,
      "yield": 1.48,
      "date": "2014-03-05"
  },
  "2014-03-06": {
      "equity": 6401.1,
      "debt": 8.792,
      "gold": 28673.23233,
      "pb": 3.03,
      "pe": 18.02,
      "yield": 1.46,
      "date": "2014-03-06"
  },
  "2014-03-07": {
      "equity": 6526.6,
      "debt": 8.811,
      "gold": 29121.52595,
      "pb": 3.09,
      "pe": 18.38,
      "yield": 1.43,
      "date": "2014-03-07"
  },
  "2014-03-08": {
      "equity": 6526.6,
      "debt": 8.811,
      "gold": 29121.52595,
      "pb": 3.09,
      "pe": 18.38,
      "yield": 1.43,
      "date": "2014-03-08"
  },
  "2014-03-09": {
      "equity": 6526.6,
      "debt": 8.812,
      "gold": 29121.52595,
      "pb": 3.09,
      "pe": 18.38,
      "yield": 1.43,
      "date": "2014-03-09"
  },
  "2014-03-10": {
      "equity": 6537.2,
      "debt": 8.752,
      "gold": 28721.0039,
      "pb": 3.09,
      "pe": 18.41,
      "yield": 1.43,
      "date": "2014-03-10"
  },
  "2014-03-11": {
      "equity": 6511.9,
      "debt": 8.733,
      "gold": 29034.81543,
      "pb": 3.08,
      "pe": 18.34,
      "yield": 1.44,
      "date": "2014-03-11"
  },
  "2014-03-12": {
      "equity": 6516.9,
      "debt": 8.715,
      "gold": 29179.98554,
      "pb": 3.08,
      "pe": 18.35,
      "yield": 1.44,
      "date": "2014-03-12"
  },
  "2014-03-13": {
      "equity": 6493.1,
      "debt": 8.735,
      "gold": 29690.29083,
      "pb": 3.07,
      "pe": 18.28,
      "yield": 1.44,
      "date": "2014-03-13"
  },
  "2014-03-14": {
      "equity": 6504.2,
      "debt": 8.791,
      "gold": 29620.79402,
      "pb": 3.08,
      "pe": 18.31,
      "yield": 1.44,
      "date": "2014-03-14"
  },
  "2014-03-15": {
      "equity": 6504.2,
      "debt": 8.791,
      "gold": 29620.79402,
      "pb": 3.08,
      "pe": 18.31,
      "yield": 1.44,
      "date": "2014-03-15"
  },
  "2014-03-16": {
      "equity": 6504.2,
      "debt": 8.791,
      "gold": 29620.79402,
      "pb": 3.08,
      "pe": 18.31,
      "yield": 1.44,
      "date": "2014-03-16"
  },
  "2014-03-17": {
      "equity": 6504.2,
      "debt": 8.791,
      "gold": 29815.38299,
      "pb": 3.08,
      "pe": 18.31,
      "yield": 1.44,
      "date": "2014-03-17"
  },
  "2014-03-18": {
      "equity": 6516.6,
      "debt": 8.8,
      "gold": 29316.13256,
      "pb": 3.08,
      "pe": 18.35,
      "yield": 1.44,
      "date": "2014-03-18"
  },
  "2014-03-19": {
      "equity": 6524,
      "debt": 8.777,
      "gold": 28960.40142,
      "pb": 3.09,
      "pe": 18.38,
      "yield": 1.43,
      "date": "2014-03-19"
  },
  "2014-03-20": {
      "equity": 6483.1,
      "debt": 8.819,
      "gold": 28618.76929,
      "pb": 3.07,
      "pe": 18.26,
      "yield": 1.44,
      "date": "2014-03-20"
  },
  "2014-03-21": {
      "equity": 6493.2,
      "debt": 8.795,
      "gold": 28761.2621,
      "pb": 3.07,
      "pe": 18.29,
      "yield": 1.44,
      "date": "2014-03-21"
  },
  "2014-03-22": {
      "equity": 6494.9,
      "debt": 8.795,
      "gold": 28761.2621,
      "pb": 3.07,
      "pe": 18.29,
      "yield": 1.44,
      "date": "2014-03-22"
  },
  "2014-03-23": {
      "equity": 6494.9,
      "debt": 8.795,
      "gold": 28761.2621,
      "pb": 3.07,
      "pe": 18.29,
      "yield": 1.44,
      "date": "2014-03-23"
  },
  "2014-03-24": {
      "equity": 6583.5,
      "debt": 8.783,
      "gold": 28277.54281,
      "pb": 3.12,
      "pe": 18.54,
      "yield": 1.42,
      "date": "2014-03-24"
  },
  "2014-03-25": {
      "equity": 6589.7,
      "debt": 8.788,
      "gold": 27928.6125,
      "pb": 3.12,
      "pe": 18.56,
      "yield": 1.42,
      "date": "2014-03-25"
  },
  "2014-03-26": {
      "equity": 6601.4,
      "debt": 8.783,
      "gold": 27870.44216,
      "pb": 3.12,
      "pe": 18.59,
      "yield": 1.42,
      "date": "2014-03-26"
  },
  "2014-03-27": {
      "equity": 6641.7,
      "debt": 8.816,
      "gold": 27507.47279,
      "pb": 3.14,
      "pe": 18.71,
      "yield": 1.41,
      "date": "2014-03-27"
  },
  "2014-03-28": {
      "equity": 6695.9,
      "debt": 8.804,
      "gold": 27374.17238,
      "pb": 3.23,
      "pe": 18.84,
      "yield": 1.37,
      "date": "2014-03-28"
  },
  "2014-03-29": {
      "equity": 6695.9,
      "debt": 8.804,
      "gold": 27374.17238,
      "pb": 3.23,
      "pe": 18.84,
      "yield": 1.37,
      "date": "2014-03-29"
  },
  "2014-03-30": {
      "equity": 6695.9,
      "debt": 8.804,
      "gold": 27374.17238,
      "pb": 3.23,
      "pe": 18.84,
      "yield": 1.37,
      "date": "2014-03-30"
  },
  "2014-03-31": {
      "equity": 6704.2,
      "debt": 8.804,
      "gold": 27337.20171,
      "pb": 3.23,
      "pe": 18.86,
      "yield": 1.37,
      "date": "2014-03-31"
  },
  "2014-04-01": {
      "equity": 6721,
      "debt": 8.81,
      "gold": 27178.75448,
      "pb": 3.24,
      "pe": 18.91,
      "yield": 1.37,
      "date": "2014-04-01"
  },
  "2014-04-02": {
      "equity": 6752.5,
      "debt": 8.962,
      "gold": 27105.10591,
      "pb": 3.26,
      "pe": 19,
      "yield": 1.36,
      "date": "2014-04-02"
  },
  "2014-04-03": {
      "equity": 6736.1,
      "debt": 9.008,
      "gold": 27404.3775,
      "pb": 3.25,
      "pe": 18.95,
      "yield": 1.37,
      "date": "2014-04-03"
  },
  "2014-04-04": {
      "equity": 6694.3,
      "debt": 9.065,
      "gold": 27308.84495,
      "pb": 3.23,
      "pe": 18.84,
      "yield": 1.37,
      "date": "2014-04-04"
  },
  "2014-04-05": {
      "equity": 6694.3,
      "debt": 9.065,
      "gold": 27308.84495,
      "pb": 3.23,
      "pe": 18.84,
      "yield": 1.37,
      "date": "2014-04-05"
  },
  "2014-04-06": {
      "equity": 6694.3,
      "debt": 9.065,
      "gold": 27308.84495,
      "pb": 3.23,
      "pe": 18.84,
      "yield": 1.37,
      "date": "2014-04-06"
  },
  "2014-04-07": {
      "equity": 6695,
      "debt": 9.101,
      "gold": 27581.21307,
      "pb": 3.23,
      "pe": 18.84,
      "yield": 1.37,
      "date": "2014-04-07"
  },
  "2014-04-08": {
      "equity": 6695,
      "debt": 9.101,
      "gold": 27937.42394,
      "pb": 3.23,
      "pe": 18.84,
      "yield": 1.37,
      "date": "2014-04-08"
  },
  "2014-04-09": {
      "equity": 6796.2,
      "debt": 9.032,
      "gold": 27780.58872,
      "pb": 3.28,
      "pe": 19.12,
      "yield": 1.35,
      "date": "2014-04-09"
  },
  "2014-04-10": {
      "equity": 6796.4,
      "debt": 9.002,
      "gold": 28103.22933,
      "pb": 3.28,
      "pe": 19.12,
      "yield": 1.35,
      "date": "2014-04-10"
  },
  "2014-04-11": {
      "equity": 6776.3,
      "debt": 8.941,
      "gold": 28044.44523,
      "pb": 3.27,
      "pe": 19.07,
      "yield": 1.36,
      "date": "2014-04-11"
  },
  "2014-04-12": {
      "equity": 6776.3,
      "debt": 8.941,
      "gold": 28044.44523,
      "pb": 3.27,
      "pe": 19.07,
      "yield": 1.36,
      "date": "2014-04-12"
  },
  "2014-04-13": {
      "equity": 6776.3,
      "debt": 8.941,
      "gold": 28044.44523,
      "pb": 3.27,
      "pe": 19.07,
      "yield": 1.36,
      "date": "2014-04-13"
  },
  "2014-04-14": {
      "equity": 6776.3,
      "debt": 8.941,
      "gold": 28198.79716,
      "pb": 3.27,
      "pe": 19.07,
      "yield": 1.36,
      "date": "2014-04-14"
  },
  "2014-04-15": {
      "equity": 6733.1,
      "debt": 8.953,
      "gold": 27972.68382,
      "pb": 3.25,
      "pe": 18.95,
      "yield": 1.37,
      "date": "2014-04-15"
  },
  "2014-04-16": {
      "equity": 6675.3,
      "debt": 8.964,
      "gold": 27601.37216,
      "pb": 3.22,
      "pe": 18.79,
      "yield": 1.38,
      "date": "2014-04-16"
  },
  "2014-04-17": {
      "equity": 6779.4,
      "debt": 8.85,
      "gold": 27651.59879,
      "pb": 3.27,
      "pe": 19.04,
      "yield": 1.36,
      "date": "2014-04-17"
  },
  "2014-04-18": {
      "equity": 6779.4,
      "debt": 8.85,
      "gold": 27651.59879,
      "pb": 3.27,
      "pe": 19.04,
      "yield": 1.36,
      "date": "2014-04-18"
  },
  "2014-04-19": {
      "equity": 6779.4,
      "debt": 8.85,
      "gold": 27651.59879,
      "pb": 3.27,
      "pe": 19.04,
      "yield": 1.36,
      "date": "2014-04-19"
  },
  "2014-04-20": {
      "equity": 6779.4,
      "debt": 8.85,
      "gold": 27651.59879,
      "pb": 3.27,
      "pe": 19.04,
      "yield": 1.36,
      "date": "2014-04-20"
  },
  "2014-04-21": {
      "equity": 6817.6,
      "debt": 8.862,
      "gold": 27651.59879,
      "pb": 3.29,
      "pe": 19.14,
      "yield": 1.35,
      "date": "2014-04-21"
  },
  "2014-04-22": {
      "equity": 6815.3,
      "debt": 8.854,
      "gold": 27701.98769,
      "pb": 3.29,
      "pe": 19.13,
      "yield": 1.35,
      "date": "2014-04-22"
  },
  "2014-04-23": {
      "equity": 6840.8,
      "debt": 8.851,
      "gold": 27672.25172,
      "pb": 3.3,
      "pe": 19.2,
      "yield": 1.34,
      "date": "2014-04-23"
  },
  "2014-04-24": {
      "equity": 6840.8,
      "debt": 8.851,
      "gold": 27682.6646,
      "pb": 3.3,
      "pe": 19.2,
      "yield": 1.34,
      "date": "2014-04-24"
  },
  "2014-04-25": {
      "equity": 6782.7,
      "debt": 8.883,
      "gold": 27700.40741,
      "pb": 3.27,
      "pe": 19.04,
      "yield": 1.36,
      "date": "2014-04-25"
  },
  "2014-04-26": {
      "equity": 6782.7,
      "debt": 8.883,
      "gold": 27700.40741,
      "pb": 3.27,
      "pe": 19.04,
      "yield": 1.36,
      "date": "2014-04-26"
  },
  "2014-04-27": {
      "equity": 6782.7,
      "debt": 8.883,
      "gold": 27700.40741,
      "pb": 3.27,
      "pe": 19.04,
      "yield": 1.36,
      "date": "2014-04-27"
  },
  "2014-04-28": {
      "equity": 6761.2,
      "debt": 8.853,
      "gold": 27868.11408,
      "pb": 3.26,
      "pe": 18.98,
      "yield": 1.36,
      "date": "2014-04-28"
  },
  "2014-04-29": {
      "equity": 6715.2,
      "debt": 8.828,
      "gold": 27388.44777,
      "pb": 3.24,
      "pe": 18.88,
      "yield": 1.37,
      "date": "2014-04-29"
  },
  "2014-04-30": {
      "equity": 6696.4,
      "debt": 8.828,
      "gold": 27470.18113,
      "pb": 3.23,
      "pe": 18.79,
      "yield": 1.37,
      "date": "2014-04-30"
  },
  "2014-05-01": {
      "equity": 6696.4,
      "debt": 8.828,
      "gold": 27305.5292,
      "pb": 3.23,
      "pe": 18.79,
      "yield": 1.37,
      "date": "2014-05-01"
  },
  "2014-05-02": {
      "equity": 6694.8,
      "debt": 8.808,
      "gold": 27197.60842,
      "pb": 3.2,
      "pe": 18.72,
      "yield": 1.42,
      "date": "2014-05-02"
  },
  "2014-05-03": {
      "equity": 6694.8,
      "debt": 8.808,
      "gold": 27197.60842,
      "pb": 3.2,
      "pe": 18.72,
      "yield": 1.42,
      "date": "2014-05-03"
  },
  "2014-05-04": {
      "equity": 6694.8,
      "debt": 8.808,
      "gold": 27197.60842,
      "pb": 3.2,
      "pe": 18.72,
      "yield": 1.42,
      "date": "2014-05-04"
  },
  "2014-05-05": {
      "equity": 6699.3,
      "debt": 8.735,
      "gold": 27197.60842,
      "pb": 3.21,
      "pe": 18.69,
      "yield": 1.42,
      "date": "2014-05-05"
  },
  "2014-05-06": {
      "equity": 6715.3,
      "debt": 8.778,
      "gold": 27646.30064,
      "pb": 3.21,
      "pe": 18.74,
      "yield": 1.41,
      "date": "2014-05-06"
  },
  "2014-05-07": {
      "equity": 6652.5,
      "debt": 8.802,
      "gold": 27705.13413,
      "pb": 3.18,
      "pe": 18.56,
      "yield": 1.43,
      "date": "2014-05-07"
  },
  "2014-05-08": {
      "equity": 6659.8,
      "debt": 8.758,
      "gold": 27285.25371,
      "pb": 3.17,
      "pe": 18.52,
      "yield": 1.45,
      "date": "2014-05-08"
  },
  "2014-05-09": {
      "equity": 6858.8,
      "debt": 8.746,
      "gold": 27218.3848,
      "pb": 3.26,
      "pe": 19.1,
      "yield": 1.41,
      "date": "2014-05-09"
  },
  "2014-05-10": {
      "equity": 6858.8,
      "debt": 8.746,
      "gold": 27218.3848,
      "pb": 3.26,
      "pe": 19.1,
      "yield": 1.41,
      "date": "2014-05-10"
  },
  "2014-05-11": {
      "equity": 6858.8,
      "debt": 8.746,
      "gold": 27218.3848,
      "pb": 3.26,
      "pe": 19.1,
      "yield": 1.41,
      "date": "2014-05-11"
  },
  "2014-05-12": {
      "equity": 7014.2,
      "debt": 8.727,
      "gold": 27172.16882,
      "pb": 3.33,
      "pe": 19.47,
      "yield": 1.38,
      "date": "2014-05-12"
  },
  "2014-05-13": {
      "equity": 7108.7,
      "debt": 8.784,
      "gold": 27085.07381,
      "pb": 3.38,
      "pe": 19.67,
      "yield": 1.36,
      "date": "2014-05-13"
  },
  "2014-05-14": {
      "equity": 7108.7,
      "debt": 8.784,
      "gold": 27273.85668,
      "pb": 3.38,
      "pe": 19.67,
      "yield": 1.36,
      "date": "2014-05-14"
  },
  "2014-05-15": {
      "equity": 7123.1,
      "debt": 8.781,
      "gold": 27374.63447,
      "pb": 3.38,
      "pe": 19.71,
      "yield": 1.36,
      "date": "2014-05-15"
  },
  "2014-05-16": {
      "equity": 7203,
      "debt": 8.834,
      "gold": 26716.97914,
      "pb": 3.42,
      "pe": 19.93,
      "yield": 1.34,
      "date": "2014-05-16"
  },
  "2014-05-17": {
      "equity": 7203,
      "debt": 8.834,
      "gold": 26716.97914,
      "pb": 3.42,
      "pe": 19.93,
      "yield": 1.34,
      "date": "2014-05-17"
  },
  "2014-05-18": {
      "equity": 7203,
      "debt": 8.834,
      "gold": 26716.97914,
      "pb": 3.42,
      "pe": 19.93,
      "yield": 1.34,
      "date": "2014-05-18"
  },
  "2014-05-19": {
      "equity": 7263.5,
      "debt": 8.863,
      "gold": 26804.28579,
      "pb": 3.45,
      "pe": 20.15,
      "yield": 1.33,
      "date": "2014-05-19"
  },
  "2014-05-20": {
      "equity": 7275.5,
      "debt": 8.855,
      "gold": 26689.64885,
      "pb": 3.46,
      "pe": 20.21,
      "yield": 1.33,
      "date": "2014-05-20"
  },
  "2014-05-21": {
      "equity": 7252.9,
      "debt": 8.768,
      "gold": 26735.52973,
      "pb": 3.44,
      "pe": 20.07,
      "yield": 1.33,
      "date": "2014-05-21"
  },
  "2014-05-22": {
      "equity": 7276.4,
      "debt": 8.711,
      "gold": 26652.78753,
      "pb": 3.46,
      "pe": 20.14,
      "yield": 1.33,
      "date": "2014-05-22"
  },
  "2014-05-23": {
      "equity": 7367.1,
      "debt": 8.637,
      "gold": 26599.94709,
      "pb": 3.5,
      "pe": 20.39,
      "yield": 1.31,
      "date": "2014-05-23"
  },
  "2014-05-24": {
      "equity": 7367.1,
      "debt": 8.637,
      "gold": 26599.94709,
      "pb": 3.5,
      "pe": 20.39,
      "yield": 1.31,
      "date": "2014-05-24"
  },
  "2014-05-25": {
      "equity": 7367.1,
      "debt": 8.637,
      "gold": 26599.94709,
      "pb": 3.5,
      "pe": 20.39,
      "yield": 1.31,
      "date": "2014-05-25"
  },
  "2014-05-26": {
      "equity": 7359,
      "debt": 8.675,
      "gold": 26599.94709,
      "pb": 3.49,
      "pe": 20.32,
      "yield": 1.31,
      "date": "2014-05-26"
  },
  "2014-05-27": {
      "equity": 7318,
      "debt": 8.673,
      "gold": 26659.03808,
      "pb": 3.48,
      "pe": 20.21,
      "yield": 1.32,
      "date": "2014-05-27"
  },
  "2014-05-28": {
      "equity": 7329.6,
      "debt": 8.703,
      "gold": 26305.16588,
      "pb": 3.48,
      "pe": 20.22,
      "yield": 1.32,
      "date": "2014-05-28"
  },
  "2014-05-29": {
      "equity": 7235.6,
      "debt": 8.671,
      "gold": 26060.87938,
      "pb": 3.44,
      "pe": 19.79,
      "yield": 1.34,
      "date": "2014-05-29"
  },
  "2014-05-30": {
      "equity": 7229.9,
      "debt": 8.645,
      "gold": 26217.46768,
      "pb": 3.43,
      "pe": 19.82,
      "yield": 1.34,
      "date": "2014-05-30"
  },
  "2014-05-31": {
      "equity": 7229.9,
      "debt": 8.645,
      "gold": 26217.46768,
      "pb": 3.43,
      "pe": 19.82,
      "yield": 1.34,
      "date": "2014-05-31"
  },
  "2014-06-01": {
      "equity": 7229.9,
      "debt": 8.645,
      "gold": 26217.46768,
      "pb": 3.43,
      "pe": 19.82,
      "yield": 1.34,
      "date": "2014-06-01"
  },
  "2014-06-02": {
      "equity": 7362.5,
      "debt": 8.66,
      "gold": 25995.31561,
      "pb": 3.5,
      "pe": 20.27,
      "yield": 1.31,
      "date": "2014-06-02"
  },
  "2014-06-03": {
      "equity": 7415.8,
      "debt": 8.598,
      "gold": 26026.34967,
      "pb": 3.52,
      "pe": 20.46,
      "yield": 1.3,
      "date": "2014-06-03"
  },
  "2014-06-04": {
      "equity": 7402.2,
      "debt": 8.587,
      "gold": 26043.17889,
      "pb": 3.52,
      "pe": 20.42,
      "yield": 1.31,
      "date": "2014-06-04"
  },
  "2014-06-05": {
      "equity": 7474.1,
      "debt": 8.526,
      "gold": 25955.80169,
      "pb": 3.55,
      "pe": 20.6,
      "yield": 1.29,
      "date": "2014-06-05"
  },
  "2014-06-06": {
      "equity": 7583.4,
      "debt": 8.512,
      "gold": 26082.33302,
      "pb": 3.6,
      "pe": 20.75,
      "yield": 1.28,
      "date": "2014-06-06"
  },
  "2014-06-07": {
      "equity": 7583.4,
      "debt": 8.512,
      "gold": 26082.33302,
      "pb": 3.6,
      "pe": 20.75,
      "yield": 1.28,
      "date": "2014-06-07"
  },
  "2014-06-08": {
      "equity": 7583.4,
      "debt": 8.512,
      "gold": 26082.33302,
      "pb": 3.6,
      "pe": 20.75,
      "yield": 1.28,
      "date": "2014-06-08"
  },
  "2014-06-09": {
      "equity": 7654.6,
      "debt": 8.548,
      "gold": 26214.24716,
      "pb": 3.62,
      "pe": 20.85,
      "yield": 1.26,
      "date": "2014-06-09"
  },
  "2014-06-10": {
      "equity": 7656.4,
      "debt": 8.561,
      "gold": 26231.99351,
      "pb": 3.62,
      "pe": 20.86,
      "yield": 1.26,
      "date": "2014-06-10"
  },
  "2014-06-11": {
      "equity": 7626.8,
      "debt": 8.535,
      "gold": 26416.1061,
      "pb": 3.56,
      "pe": 20.78,
      "yield": 1.28,
      "date": "2014-06-11"
  },
  "2014-06-12": {
      "equity": 7649.9,
      "debt": 8.547,
      "gold": 26378.16187,
      "pb": 3.58,
      "pe": 20.84,
      "yield": 1.28,
      "date": "2014-06-12"
  },
  "2014-06-13": {
      "equity": 7542.1,
      "debt": 8.595,
      "gold": 26823.28083,
      "pb": 3.52,
      "pe": 20.55,
      "yield": 1.28,
      "date": "2014-06-13"
  },
  "2014-06-14": {
      "equity": 7542.1,
      "debt": 8.595,
      "gold": 26823.28083,
      "pb": 3.52,
      "pe": 20.55,
      "yield": 1.28,
      "date": "2014-06-14"
  },
  "2014-06-15": {
      "equity": 7542.1,
      "debt": 8.595,
      "gold": 26823.28083,
      "pb": 3.52,
      "pe": 20.55,
      "yield": 1.28,
      "date": "2014-06-15"
  },
  "2014-06-16": {
      "equity": 7533.5,
      "debt": 8.649,
      "gold": 27195.96113,
      "pb": 3.52,
      "pe": 20.53,
      "yield": 1.28,
      "date": "2014-06-16"
  },
  "2014-06-17": {
      "equity": 7631.7,
      "debt": 8.598,
      "gold": 26905.33166,
      "pb": 3.48,
      "pe": 20.76,
      "yield": 1.28,
      "date": "2014-06-17"
  },
  "2014-06-18": {
      "equity": 7558.2,
      "debt": 8.671,
      "gold": 26928.34089,
      "pb": 3.45,
      "pe": 20.56,
      "yield": 1.3,
      "date": "2014-06-18"
  },
  "2014-06-19": {
      "equity": 7540.7,
      "debt": 8.688,
      "gold": 27198.32449,
      "pb": 3.44,
      "pe": 20.52,
      "yield": 1.3,
      "date": "2014-06-19"
  },
  "2014-06-20": {
      "equity": 7511.4,
      "debt": 8.724,
      "gold": 27837.87721,
      "pb": 3.43,
      "pe": 20.38,
      "yield": 1.3,
      "date": "2014-06-20"
  },
  "2014-06-21": {
      "equity": 7511.4,
      "debt": 8.724,
      "gold": 27837.87721,
      "pb": 3.43,
      "pe": 20.38,
      "yield": 1.3,
      "date": "2014-06-21"
  },
  "2014-06-22": {
      "equity": 7511.4,
      "debt": 8.724,
      "gold": 27837.87721,
      "pb": 3.43,
      "pe": 20.38,
      "yield": 1.3,
      "date": "2014-06-22"
  },
  "2014-06-23": {
      "equity": 7493.3,
      "debt": 8.773,
      "gold": 27864.53024,
      "pb": 3.42,
      "pe": 20.33,
      "yield": 1.31,
      "date": "2014-06-23"
  },
  "2014-06-24": {
      "equity": 7580.2,
      "debt": 8.717,
      "gold": 28096.62957,
      "pb": 3.46,
      "pe": 20.56,
      "yield": 1.29,
      "date": "2014-06-24"
  },
  "2014-06-25": {
      "equity": 7569.2,
      "debt": 8.702,
      "gold": 27830.16279,
      "pb": 3.46,
      "pe": 20.53,
      "yield": 1.29,
      "date": "2014-06-25"
  },
  "2014-06-26": {
      "equity": 7493.2,
      "debt": 8.731,
      "gold": 27845.23184,
      "pb": 3.42,
      "pe": 20.33,
      "yield": 1.31,
      "date": "2014-06-26"
  },
  "2014-06-27": {
      "equity": 7508.8,
      "debt": 8.747,
      "gold": 27880.54463,
      "pb": 3.43,
      "pe": 20.37,
      "yield": 1.31,
      "date": "2014-06-27"
  },
  "2014-06-28": {
      "equity": 7508.8,
      "debt": 8.747,
      "gold": 27880.54463,
      "pb": 3.43,
      "pe": 20.37,
      "yield": 1.31,
      "date": "2014-06-28"
  },
  "2014-06-29": {
      "equity": 7508.8,
      "debt": 8.747,
      "gold": 27880.54463,
      "pb": 3.43,
      "pe": 20.37,
      "yield": 1.31,
      "date": "2014-06-29"
  },
  "2014-06-30": {
      "equity": 7611.3,
      "debt": 8.744,
      "gold": 27809.69329,
      "pb": 3.48,
      "pe": 20.65,
      "yield": 1.29,
      "date": "2014-06-30"
  },
  "2014-07-01": {
      "equity": 7634.7,
      "debt": 8.737,
      "gold": 28093.76885,
      "pb": 3.49,
      "pe": 20.71,
      "yield": 1.28,
      "date": "2014-07-01"
  },
  "2014-07-02": {
      "equity": 7725.1,
      "debt": 8.657,
      "gold": 27878.6222,
      "pb": 3.53,
      "pe": 20.96,
      "yield": 1.27,
      "date": "2014-07-02"
  },
  "2014-07-03": {
      "equity": 7714.8,
      "debt": 8.657,
      "gold": 27785.81986,
      "pb": 3.52,
      "pe": 20.93,
      "yield": 1.27,
      "date": "2014-07-03"
  },
  "2014-07-04": {
      "equity": 7751.6,
      "debt": 8.663,
      "gold": 27869.45802,
      "pb": 3.54,
      "pe": 20.99,
      "yield": 1.26,
      "date": "2014-07-04"
  },
  "2014-07-05": {
      "equity": 7751.6,
      "debt": 8.663,
      "gold": 27869.45802,
      "pb": 3.54,
      "pe": 20.99,
      "yield": 1.26,
      "date": "2014-07-05"
  },
  "2014-07-06": {
      "equity": 7751.6,
      "debt": 8.663,
      "gold": 27869.45802,
      "pb": 3.54,
      "pe": 20.99,
      "yield": 1.26,
      "date": "2014-07-06"
  },
  "2014-07-07": {
      "equity": 7787.1,
      "debt": 8.696,
      "gold": 27752.67994,
      "pb": 3.56,
      "pe": 21.08,
      "yield": 1.26,
      "date": "2014-07-07"
  },
  "2014-07-08": {
      "equity": 7623.2,
      "debt": 8.727,
      "gold": 27836.72375,
      "pb": 3.48,
      "pe": 20.64,
      "yield": 1.29,
      "date": "2014-07-08"
  },
  "2014-07-09": {
      "equity": 7585,
      "debt": 8.727,
      "gold": 27864.19161,
      "pb": 3.46,
      "pe": 20.54,
      "yield": 1.29,
      "date": "2014-07-09"
  },
  "2014-07-10": {
      "equity": 7567.7,
      "debt": 8.765,
      "gold": 28457.74,
      "pb": 3.46,
      "pe": 20.48,
      "yield": 1.3,
      "date": "2014-07-10"
  },
  "2014-07-11": {
      "equity": 7459.6,
      "debt": 8.767,
      "gold": 28346.08723,
      "pb": 3.41,
      "pe": 20.12,
      "yield": 1.31,
      "date": "2014-07-11"
  },
  "2014-07-12": {
      "equity": 7459.6,
      "debt": 8.771,
      "gold": 28346.08723,
      "pb": 3.41,
      "pe": 20.12,
      "yield": 1.31,
      "date": "2014-07-12"
  },
  "2014-07-13": {
      "equity": 7459.6,
      "debt": 8.771,
      "gold": 28346.08723,
      "pb": 3.41,
      "pe": 20.12,
      "yield": 1.31,
      "date": "2014-07-13"
  },
  "2014-07-14": {
      "equity": 7454.1,
      "debt": 8.781,
      "gold": 27998.40914,
      "pb": 3.41,
      "pe": 20.12,
      "yield": 1.31,
      "date": "2014-07-14"
  },
  "2014-07-15": {
      "equity": 7526.6,
      "debt": 8.735,
      "gold": 27847.51759,
      "pb": 3.44,
      "pe": 20.31,
      "yield": 1.3,
      "date": "2014-07-15"
  },
  "2014-07-16": {
      "equity": 7624.4,
      "debt": 8.724,
      "gold": 27486.89042,
      "pb": 3.48,
      "pe": 20.57,
      "yield": 1.29,
      "date": "2014-07-16"
  },
  "2014-07-17": {
      "equity": 7640.4,
      "debt": 8.736,
      "gold": 27707.47632,
      "pb": 3.49,
      "pe": 20.62,
      "yield": 1.28,
      "date": "2014-07-17"
  },
  "2014-07-18": {
      "equity": 7663.9,
      "debt": 8.769,
      "gold": 27882.93621,
      "pb": 3.5,
      "pe": 20.6,
      "yield": 1.28,
      "date": "2014-07-18"
  },
  "2014-07-19": {
      "equity": 7663.9,
      "debt": 8.769,
      "gold": 27882.93621,
      "pb": 3.5,
      "pe": 20.6,
      "yield": 1.28,
      "date": "2014-07-19"
  },
  "2014-07-20": {
      "equity": 7663.9,
      "debt": 8.771,
      "gold": 27882.93621,
      "pb": 3.5,
      "pe": 20.6,
      "yield": 1.28,
      "date": "2014-07-20"
  },
  "2014-07-21": {
      "equity": 7684.2,
      "debt": 8.692,
      "gold": 27924.09743,
      "pb": 3.51,
      "pe": 20.58,
      "yield": 1.28,
      "date": "2014-07-21"
  },
  "2014-07-22": {
      "equity": 7767.8,
      "debt": 8.69,
      "gold": 27715.80451,
      "pb": 3.54,
      "pe": 20.78,
      "yield": 1.26,
      "date": "2014-07-22"
  },
  "2014-07-23": {
      "equity": 7795.7,
      "debt": 8.66,
      "gold": 27659.30263,
      "pb": 3.56,
      "pe": 20.82,
      "yield": 1.26,
      "date": "2014-07-23"
  },
  "2014-07-24": {
      "equity": 7830.6,
      "debt": 8.648,
      "gold": 27550.85628,
      "pb": 3.57,
      "pe": 20.92,
      "yield": 1.25,
      "date": "2014-07-24"
  },
  "2014-07-25": {
      "equity": 7790.4,
      "debt": 8.671,
      "gold": 27381.19544,
      "pb": 3.55,
      "pe": 20.79,
      "yield": 1.26,
      "date": "2014-07-25"
  },
  "2014-07-26": {
      "equity": 7790.4,
      "debt": 8.671,
      "gold": 27381.19544,
      "pb": 3.55,
      "pe": 20.79,
      "yield": 1.26,
      "date": "2014-07-26"
  },
  "2014-07-27": {
      "equity": 7790.4,
      "debt": 8.671,
      "gold": 27381.19544,
      "pb": 3.55,
      "pe": 20.79,
      "yield": 1.26,
      "date": "2014-07-27"
  },
  "2014-07-28": {
      "equity": 7748.7,
      "debt": 8.707,
      "gold": 27662.11397,
      "pb": 3.53,
      "pe": 20.66,
      "yield": 1.27,
      "date": "2014-07-28"
  },
  "2014-07-29": {
      "equity": 7748.7,
      "debt": 8.707,
      "gold": 27705.88194,
      "pb": 3.53,
      "pe": 20.66,
      "yield": 1.27,
      "date": "2014-07-29"
  },
  "2014-07-30": {
      "equity": 7791.4,
      "debt": 8.728,
      "gold": 27655.31667,
      "pb": 3.54,
      "pe": 20.78,
      "yield": 1.27,
      "date": "2014-07-30"
  },
  "2014-07-31": {
      "equity": 7721.3,
      "debt": 8.718,
      "gold": 27798.22572,
      "pb": 3.48,
      "pe": 20.56,
      "yield": 1.3,
      "date": "2014-07-31"
  },
  "2014-08-01": {
      "equity": 7602.6,
      "debt": 8.755,
      "gold": 27641.70444,
      "pb": 3.42,
      "pe": 20.05,
      "yield": 1.32,
      "date": "2014-08-01"
  },
  "2014-08-02": {
      "equity": 7602.6,
      "debt": 8.755,
      "gold": 27641.70444,
      "pb": 3.42,
      "pe": 20.05,
      "yield": 1.32,
      "date": "2014-08-02"
  },
  "2014-08-03": {
      "equity": 7602.6,
      "debt": 8.755,
      "gold": 27641.70444,
      "pb": 3.42,
      "pe": 20.05,
      "yield": 1.32,
      "date": "2014-08-03"
  },
  "2014-08-04": {
      "equity": 7683.6,
      "debt": 8.731,
      "gold": 27834.23694,
      "pb": 3.46,
      "pe": 20.26,
      "yield": 1.31,
      "date": "2014-08-04"
  },
  "2014-08-05": {
      "equity": 7746.5,
      "debt": 8.834,
      "gold": 27837.47862,
      "pb": 3.48,
      "pe": 20.43,
      "yield": 1.3,
      "date": "2014-08-05"
  },
  "2014-08-06": {
      "equity": 7672,
      "debt": 8.865,
      "gold": 27849.13314,
      "pb": 3.4,
      "pe": 20.2,
      "yield": 1.32,
      "date": "2014-08-06"
  },
  "2014-08-07": {
      "equity": 7649.2,
      "debt": 8.852,
      "gold": 28239.19999,
      "pb": 3.35,
      "pe": 20.14,
      "yield": 1.32,
      "date": "2014-08-07"
  },
  "2014-08-08": {
      "equity": 7568.5,
      "debt": 8.852,
      "gold": 28434.80132,
      "pb": 3.29,
      "pe": 19.93,
      "yield": 1.34,
      "date": "2014-08-08"
  },
  "2014-08-09": {
      "equity": 7568.5,
      "debt": 8.852,
      "gold": 28434.80132,
      "pb": 3.29,
      "pe": 19.93,
      "yield": 1.34,
      "date": "2014-08-09"
  },
  "2014-08-10": {
      "equity": 7568.5,
      "debt": 8.855,
      "gold": 28434.80132,
      "pb": 3.29,
      "pe": 19.93,
      "yield": 1.34,
      "date": "2014-08-10"
  },
  "2014-08-11": {
      "equity": 7625.9,
      "debt": 8.817,
      "gold": 28216.70576,
      "pb": 3.32,
      "pe": 20,
      "yield": 1.33,
      "date": "2014-08-11"
  },
  "2014-08-12": {
      "equity": 7727,
      "debt": 8.845,
      "gold": 28367.11759,
      "pb": 3.36,
      "pe": 20.26,
      "yield": 1.31,
      "date": "2014-08-12"
  },
  "2014-08-13": {
      "equity": 7739.5,
      "debt": 8.764,
      "gold": 28216.1061,
      "pb": 3.37,
      "pe": 20.31,
      "yield": 1.31,
      "date": "2014-08-13"
  },
  "2014-08-14": {
      "equity": 7791.7,
      "debt": 8.713,
      "gold": 28250.96739,
      "pb": 3.39,
      "pe": 20.45,
      "yield": 1.3,
      "date": "2014-08-14"
  },
  "2014-08-15": {
      "equity": 7791.7,
      "debt": 8.713,
      "gold": 28203.04767,
      "pb": 3.39,
      "pe": 20.45,
      "yield": 1.3,
      "date": "2014-08-15"
  },
  "2014-08-16": {
      "equity": 7791.7,
      "debt": 8.713,
      "gold": 28203.04767,
      "pb": 3.39,
      "pe": 20.45,
      "yield": 1.3,
      "date": "2014-08-16"
  },
  "2014-08-17": {
      "equity": 7791.7,
      "debt": 8.713,
      "gold": 28203.04767,
      "pb": 3.39,
      "pe": 20.45,
      "yield": 1.3,
      "date": "2014-08-17"
  },
  "2014-08-18": {
      "equity": 7874.2,
      "debt": 8.713,
      "gold": 27970.09824,
      "pb": 3.43,
      "pe": 20.57,
      "yield": 1.29,
      "date": "2014-08-18"
  },
  "2014-08-19": {
      "equity": 7897.5,
      "debt": 8.532,
      "gold": 27867.57791,
      "pb": 3.44,
      "pe": 20.63,
      "yield": 1.28,
      "date": "2014-08-19"
  },
  "2014-08-20": {
      "equity": 7875.3,
      "debt": 8.479,
      "gold": 27673.79319,
      "pb": 3.43,
      "pe": 20.58,
      "yield": 1.29,
      "date": "2014-08-20"
  },
  "2014-08-21": {
      "equity": 7891.1,
      "debt": 8.512,
      "gold": 27349.43121,
      "pb": 3.43,
      "pe": 20.63,
      "yield": 1.29,
      "date": "2014-08-21"
  },
  "2014-08-22": {
      "equity": 7913.2,
      "debt": 8.516,
      "gold": 27299.11286,
      "pb": 3.44,
      "pe": 20.69,
      "yield": 1.28,
      "date": "2014-08-22"
  },
  "2014-08-23": {
      "equity": 7913.2,
      "debt": 8.516,
      "gold": 27299.11286,
      "pb": 3.44,
      "pe": 20.69,
      "yield": 1.28,
      "date": "2014-08-23"
  },
  "2014-08-24": {
      "equity": 7913.2,
      "debt": 8.516,
      "gold": 27299.11286,
      "pb": 3.44,
      "pe": 20.69,
      "yield": 1.28,
      "date": "2014-08-24"
  },
  "2014-08-25": {
      "equity": 7906.3,
      "debt": 8.556,
      "gold": 27299.11286,
      "pb": 3.44,
      "pe": 20.67,
      "yield": 1.28,
      "date": "2014-08-25"
  },
  "2014-08-26": {
      "equity": 7904.7,
      "debt": 8.554,
      "gold": 27444.23006,
      "pb": 3.44,
      "pe": 20.67,
      "yield": 1.28,
      "date": "2014-08-26"
  },
  "2014-08-27": {
      "equity": 7936,
      "debt": 8.556,
      "gold": 27350.35891,
      "pb": 3.45,
      "pe": 20.75,
      "yield": 1.28,
      "date": "2014-08-27"
  },
  "2014-08-28": {
      "equity": 7954.3,
      "debt": 8.559,
      "gold": 27535.28986,
      "pb": 3.46,
      "pe": 20.8,
      "yield": 1.27,
      "date": "2014-08-28"
  },
  "2014-08-29": {
      "equity": 7954.3,
      "debt": 8.559,
      "gold": 27501.70197,
      "pb": 3.46,
      "pe": 20.8,
      "yield": 1.27,
      "date": "2014-08-29"
  },
  "2014-08-30": {
      "equity": 7954.3,
      "debt": 8.559,
      "gold": 27501.70197,
      "pb": 3.46,
      "pe": 20.8,
      "yield": 1.27,
      "date": "2014-08-30"
  },
  "2014-08-31": {
      "equity": 7954.3,
      "debt": 8.565,
      "gold": 27501.70197,
      "pb": 3.46,
      "pe": 20.8,
      "yield": 1.27,
      "date": "2014-08-31"
  },
  "2014-09-01": {
      "equity": 8027.7,
      "debt": 8.55,
      "gold": 27480.66103,
      "pb": 3.49,
      "pe": 20.99,
      "yield": 1.26,
      "date": "2014-09-01"
  },
  "2014-09-02": {
      "equity": 8083,
      "debt": 8.519,
      "gold": 27322.9228,
      "pb": 3.52,
      "pe": 21.14,
      "yield": 1.25,
      "date": "2014-09-02"
  },
  "2014-09-03": {
      "equity": 8114.6,
      "debt": 8.522,
      "gold": 27038.99187,
      "pb": 3.53,
      "pe": 21.22,
      "yield": 1.25,
      "date": "2014-09-03"
  },
  "2014-09-04": {
      "equity": 8095.9,
      "debt": 8.522,
      "gold": 27079.27829,
      "pb": 3.52,
      "pe": 21.17,
      "yield": 1.25,
      "date": "2014-09-04"
  },
  "2014-09-05": {
      "equity": 8086.8,
      "debt": 8.522,
      "gold": 26861.70126,
      "pb": 3.52,
      "pe": 21.15,
      "yield": 1.25,
      "date": "2014-09-05"
  },
  "2014-09-06": {
      "equity": 8086.8,
      "debt": 8.522,
      "gold": 26861.70126,
      "pb": 3.52,
      "pe": 21.15,
      "yield": 1.25,
      "date": "2014-09-06"
  },
  "2014-09-07": {
      "equity": 8086.8,
      "debt": 8.522,
      "gold": 26861.70126,
      "pb": 3.52,
      "pe": 21.15,
      "yield": 1.25,
      "date": "2014-09-07"
  },
  "2014-09-08": {
      "equity": 8173.9,
      "debt": 8.502,
      "gold": 26978.37352,
      "pb": 3.56,
      "pe": 21.73,
      "yield": 1.24,
      "date": "2014-09-08"
  },
  "2014-09-09": {
      "equity": 8152.9,
      "debt": 8.522,
      "gold": 26941.78733,
      "pb": 3.55,
      "pe": 21.68,
      "yield": 1.24,
      "date": "2014-09-09"
  },
  "2014-09-10": {
      "equity": 8094.1,
      "debt": 8.543,
      "gold": 26921.72349,
      "pb": 3.52,
      "pe": 21.52,
      "yield": 1.25,
      "date": "2014-09-10"
  },
  "2014-09-11": {
      "equity": 8085.7,
      "debt": 8.505,
      "gold": 26750.48943,
      "pb": 3.52,
      "pe": 21.5,
      "yield": 1.25,
      "date": "2014-09-11"
  },
  "2014-09-12": {
      "equity": 8105.5,
      "debt": 8.501,
      "gold": 26596.76185,
      "pb": 3.53,
      "pe": 21.55,
      "yield": 1.25,
      "date": "2014-09-12"
  },
  "2014-09-13": {
      "equity": 8105.5,
      "debt": 8.505,
      "gold": 26596.76185,
      "pb": 3.53,
      "pe": 21.55,
      "yield": 1.25,
      "date": "2014-09-13"
  },
  "2014-09-14": {
      "equity": 8105.5,
      "debt": 8.505,
      "gold": 26596.76185,
      "pb": 3.53,
      "pe": 21.55,
      "yield": 1.25,
      "date": "2014-09-14"
  },
  "2014-09-15": {
      "equity": 8042,
      "debt": 8.504,
      "gold": 26608.78675,
      "pb": 3.5,
      "pe": 21.38,
      "yield": 1.26,
      "date": "2014-09-15"
  },
  "2014-09-16": {
      "equity": 7932.9,
      "debt": 8.498,
      "gold": 26628.7871,
      "pb": 3.45,
      "pe": 21.09,
      "yield": 1.28,
      "date": "2014-09-16"
  },
  "2014-09-17": {
      "equity": 7975.5,
      "debt": 8.498,
      "gold": 26550.32717,
      "pb": 3.47,
      "pe": 21.21,
      "yield": 1.27,
      "date": "2014-09-17"
  },
  "2014-09-18": {
      "equity": 8114.7,
      "debt": 8.453,
      "gold": 26214.93854,
      "pb": 3.53,
      "pe": 21.58,
      "yield": 1.25,
      "date": "2014-09-18"
  },
  "2014-09-19": {
      "equity": 8121.4,
      "debt": 8.455,
      "gold": 26250.3642,
      "pb": 3.55,
      "pe": 21.23,
      "yield": 1.25,
      "date": "2014-09-19"
  },
  "2014-09-20": {
      "equity": 8121.4,
      "debt": 8.455,
      "gold": 26250.3642,
      "pb": 3.55,
      "pe": 21.23,
      "yield": 1.25,
      "date": "2014-09-20"
  },
  "2014-09-21": {
      "equity": 8121.4,
      "debt": 8.455,
      "gold": 26250.3642,
      "pb": 3.55,
      "pe": 21.23,
      "yield": 1.25,
      "date": "2014-09-21"
  },
  "2014-09-22": {
      "equity": 8146.3,
      "debt": 8.435,
      "gold": 26118.37599,
      "pb": 3.56,
      "pe": 21.3,
      "yield": 1.25,
      "date": "2014-09-22"
  },
  "2014-09-23": {
      "equity": 8017.5,
      "debt": 8.467,
      "gold": 26385.71403,
      "pb": 3.5,
      "pe": 20.96,
      "yield": 1.27,
      "date": "2014-09-23"
  },
  "2014-09-24": {
      "equity": 8002.4,
      "debt": 8.481,
      "gold": 26309.98783,
      "pb": 3.48,
      "pe": 20.92,
      "yield": 1.31,
      "date": "2014-09-24"
  },
  "2014-09-25": {
      "equity": 7911.8,
      "debt": 8.487,
      "gold": 26266.39271,
      "pb": 3.43,
      "pe": 20.68,
      "yield": 1.33,
      "date": "2014-09-25"
  },
  "2014-09-26": {
      "equity": 7968.8,
      "debt": 8.441,
      "gold": 26511.22242,
      "pb": 3.45,
      "pe": 20.83,
      "yield": 1.31,
      "date": "2014-09-26"
  },
  "2014-09-27": {
      "equity": 7968.8,
      "debt": 8.443,
      "gold": 26511.22242,
      "pb": 3.45,
      "pe": 20.83,
      "yield": 1.31,
      "date": "2014-09-27"
  },
  "2014-09-28": {
      "equity": 7968.8,
      "debt": 8.443,
      "gold": 26511.22242,
      "pb": 3.45,
      "pe": 20.83,
      "yield": 1.31,
      "date": "2014-09-28"
  },
  "2014-09-29": {
      "equity": 7958.9,
      "debt": 8.49,
      "gold": 26456.13856,
      "pb": 3.44,
      "pe": 20.81,
      "yield": 1.32,
      "date": "2014-09-29"
  },
  "2014-09-30": {
      "equity": 7964.8,
      "debt": 8.511,
      "gold": 26426.48371,
      "pb": 3.43,
      "pe": 20.82,
      "yield": 1.31,
      "date": "2014-09-30"
  },
  "2014-10-01": {
      "equity": 7945.5,
      "debt": 8.477,
      "gold": 26310.17126,
      "pb": 3.4,
      "pe": 20.77,
      "yield": 1.32,
      "date": "2014-10-01"
  },
  "2014-10-02": {
      "equity": 7945.5,
      "debt": 8.477,
      "gold": 26482.35066,
      "pb": 3.4,
      "pe": 20.77,
      "yield": 1.32,
      "date": "2014-10-02"
  },
  "2014-10-03": {
      "equity": 7945.5,
      "debt": 8.477,
      "gold": 26329.71305,
      "pb": 3.4,
      "pe": 20.77,
      "yield": 1.32,
      "date": "2014-10-03"
  },
  "2014-10-04": {
      "equity": 7945.5,
      "debt": 8.477,
      "gold": 26329.71305,
      "pb": 3.4,
      "pe": 20.77,
      "yield": 1.32,
      "date": "2014-10-04"
  },
  "2014-10-05": {
      "equity": 7945.5,
      "debt": 8.477,
      "gold": 26329.71305,
      "pb": 3.4,
      "pe": 20.77,
      "yield": 1.32,
      "date": "2014-10-05"
  },
  "2014-10-06": {
      "equity": 7945.5,
      "debt": 8.477,
      "gold": 26018.99152,
      "pb": 3.4,
      "pe": 20.77,
      "yield": 1.32,
      "date": "2014-10-06"
  },
  "2014-10-07": {
      "equity": 7852.4,
      "debt": 8.453,
      "gold": 26120.36897,
      "pb": 3.36,
      "pe": 20.53,
      "yield": 1.34,
      "date": "2014-10-07"
  },
  "2014-10-08": {
      "equity": 7842.7,
      "debt": 8.408,
      "gold": 26450.79807,
      "pb": 3.34,
      "pe": 20.5,
      "yield": 1.34,
      "date": "2014-10-08"
  },
  "2014-10-09": {
      "equity": 7960.5,
      "debt": 8.465,
      "gold": 26471.1688,
      "pb": 3.37,
      "pe": 20.81,
      "yield": 1.33,
      "date": "2014-10-09"
  },
  "2014-10-10": {
      "equity": 7859.9,
      "debt": 8.456,
      "gold": 26368.29926,
      "pb": 3.33,
      "pe": 20.55,
      "yield": 1.35,
      "date": "2014-10-10"
  },
  "2014-10-11": {
      "equity": 7859.9,
      "debt": 8.456,
      "gold": 26368.29926,
      "pb": 3.33,
      "pe": 20.55,
      "yield": 1.35,
      "date": "2014-10-11"
  },
  "2014-10-12": {
      "equity": 7859.9,
      "debt": 8.456,
      "gold": 26368.29926,
      "pb": 3.33,
      "pe": 20.55,
      "yield": 1.35,
      "date": "2014-10-12"
  },
  "2014-10-13": {
      "equity": 7884.2,
      "debt": 8.418,
      "gold": 26411.56281,
      "pb": 3.34,
      "pe": 20.47,
      "yield": 1.34,
      "date": "2014-10-13"
  },
  "2014-10-14": {
      "equity": 7864,
      "debt": 8.397,
      "gold": 26694.37556,
      "pb": 3.33,
      "pe": 20.41,
      "yield": 1.34,
      "date": "2014-10-14"
  },
  "2014-10-15": {
      "equity": 7864,
      "debt": 8.398,
      "gold": 26509.63509,
      "pb": 3.33,
      "pe": 20.41,
      "yield": 1.34,
      "date": "2014-10-15"
  },
  "2014-10-16": {
      "equity": 7748.2,
      "debt": 8.37,
      "gold": 26973.51276,
      "pb": 3.28,
      "pe": 20.13,
      "yield": 1.36,
      "date": "2014-10-16"
  },
  "2014-10-17": {
      "equity": 7779.7,
      "debt": 8.389,
      "gold": 26738.65148,
      "pb": 3.3,
      "pe": 20.21,
      "yield": 1.36,
      "date": "2014-10-17"
  },
  "2014-10-18": {
      "equity": 7779.7,
      "debt": 8.389,
      "gold": 26738.65148,
      "pb": 3.3,
      "pe": 20.21,
      "yield": 1.36,
      "date": "2014-10-18"
  },
  "2014-10-19": {
      "equity": 7779.7,
      "debt": 8.389,
      "gold": 26738.65148,
      "pb": 3.3,
      "pe": 20.21,
      "yield": 1.36,
      "date": "2014-10-19"
  },
  "2014-10-20": {
      "equity": 7879.4,
      "debt": 8.359,
      "gold": 26792.28205,
      "pb": 3.34,
      "pe": 20.47,
      "yield": 1.34,
      "date": "2014-10-20"
  },
  "2014-10-21": {
      "equity": 7927.7,
      "debt": 8.371,
      "gold": 26979.77389,
      "pb": 3.36,
      "pe": 20.53,
      "yield": 1.33,
      "date": "2014-10-21"
  },
  "2014-10-22": {
      "equity": 7995.9,
      "debt": 8.356,
      "gold": 26917.30013,
      "pb": 3.39,
      "pe": 20.65,
      "yield": 1.32,
      "date": "2014-10-22"
  },
  "2014-10-23": {
      "equity": 8014.5,
      "debt": 8.356,
      "gold": 26772.95543,
      "pb": 3.4,
      "pe": 20.7,
      "yield": 1.32,
      "date": "2014-10-23"
  },
  "2014-10-24": {
      "equity": 8014.5,
      "debt": 8.356,
      "gold": 26584.10907,
      "pb": 3.4,
      "pe": 20.7,
      "yield": 1.32,
      "date": "2014-10-24"
  },
  "2014-10-25": {
      "equity": 8014.5,
      "debt": 8.356,
      "gold": 26584.10907,
      "pb": 3.4,
      "pe": 20.7,
      "yield": 1.32,
      "date": "2014-10-25"
  },
  "2014-10-26": {
      "equity": 8014.5,
      "debt": 8.356,
      "gold": 26584.10907,
      "pb": 3.4,
      "pe": 20.7,
      "yield": 1.32,
      "date": "2014-10-26"
  },
  "2014-10-27": {
      "equity": 7991.7,
      "debt": 8.316,
      "gold": 26627.01282,
      "pb": 3.39,
      "pe": 20.64,
      "yield": 1.32,
      "date": "2014-10-27"
  },
  "2014-10-28": {
      "equity": 8027.6,
      "debt": 8.322,
      "gold": 26508.13947,
      "pb": 3.4,
      "pe": 20.75,
      "yield": 1.32,
      "date": "2014-10-28"
  },
  "2014-10-29": {
      "equity": 8090.4,
      "debt": 8.318,
      "gold": 26545.84384,
      "pb": 3.43,
      "pe": 20.92,
      "yield": 1.31,
      "date": "2014-10-29"
  },
  "2014-10-30": {
      "equity": 8169.2,
      "debt": 8.289,
      "gold": 26098.037,
      "pb": 3.46,
      "pe": 21.16,
      "yield": 1.29,
      "date": "2014-10-30"
  },
  "2014-10-31": {
      "equity": 8322.2,
      "debt": 8.278,
      "gold": 25415.07258,
      "pb": 3.51,
      "pe": 21.58,
      "yield": 1.27,
      "date": "2014-10-31"
  },
  "2014-11-01": {
      "equity": 8322.2,
      "debt": 8.278,
      "gold": 25415.07258,
      "pb": 3.51,
      "pe": 21.58,
      "yield": 1.27,
      "date": "2014-11-01"
  },
  "2014-11-02": {
      "equity": 8322.2,
      "debt": 8.278,
      "gold": 25415.07258,
      "pb": 3.51,
      "pe": 21.58,
      "yield": 1.27,
      "date": "2014-11-02"
  },
  "2014-11-03": {
      "equity": 8324.1,
      "debt": 8.259,
      "gold": 25368.76135,
      "pb": 3.51,
      "pe": 21.58,
      "yield": 1.27,
      "date": "2014-11-03"
  },
  "2014-11-04": {
      "equity": 8324.1,
      "debt": 8.259,
      "gold": 25337.7026,
      "pb": 3.51,
      "pe": 21.58,
      "yield": 1.27,
      "date": "2014-11-04"
  },
  "2014-11-05": {
      "equity": 8338.3,
      "debt": 8.191,
      "gold": 24810.75504,
      "pb": 3.52,
      "pe": 21.54,
      "yield": 1.27,
      "date": "2014-11-05"
  },
  "2014-11-06": {
      "equity": 8338.3,
      "debt": 8.191,
      "gold": 24797.93647,
      "pb": 3.52,
      "pe": 21.54,
      "yield": 1.27,
      "date": "2014-11-06"
  },
  "2014-11-07": {
      "equity": 8337,
      "debt": 8.209,
      "gold": 24788.37369,
      "pb": 3.52,
      "pe": 21.31,
      "yield": 1.27,
      "date": "2014-11-07"
  },
  "2014-11-08": {
      "equity": 8337,
      "debt": 8.209,
      "gold": 24788.37369,
      "pb": 3.52,
      "pe": 21.31,
      "yield": 1.27,
      "date": "2014-11-08"
  },
  "2014-11-09": {
      "equity": 8337,
      "debt": 8.209,
      "gold": 24788.37369,
      "pb": 3.52,
      "pe": 21.31,
      "yield": 1.27,
      "date": "2014-11-09"
  },
  "2014-11-10": {
      "equity": 8344.2,
      "debt": 8.178,
      "gold": 25457.85993,
      "pb": 3.52,
      "pe": 21.24,
      "yield": 1.27,
      "date": "2014-11-10"
  },
  "2014-11-11": {
      "equity": 8362.6,
      "debt": 8.185,
      "gold": 24977.89732,
      "pb": 3.53,
      "pe": 21.29,
      "yield": 1.27,
      "date": "2014-11-11"
  },
  "2014-11-12": {
      "equity": 8383.3,
      "debt": 8.158,
      "gold": 25203.58031,
      "pb": 3.54,
      "pe": 21.33,
      "yield": 1.26,
      "date": "2014-11-12"
  },
  "2014-11-13": {
      "equity": 8357.8,
      "debt": 8.221,
      "gold": 25213.39353,
      "pb": 3.53,
      "pe": 21.26,
      "yield": 1.27,
      "date": "2014-11-13"
  },
  "2014-11-14": {
      "equity": 8389.9,
      "debt": 8.215,
      "gold": 25101.46916,
      "pb": 3.54,
      "pe": 21.35,
      "yield": 1.26,
      "date": "2014-11-14"
  },
  "2014-11-15": {
      "equity": 8389.9,
      "debt": 8.215,
      "gold": 25101.46916,
      "pb": 3.54,
      "pe": 21.35,
      "yield": 1.26,
      "date": "2014-11-15"
  },
  "2014-11-16": {
      "equity": 8389.9,
      "debt": 8.221,
      "gold": 25101.46916,
      "pb": 3.54,
      "pe": 21.35,
      "yield": 1.26,
      "date": "2014-11-16"
  },
  "2014-11-17": {
      "equity": 8430.7,
      "debt": 8.181,
      "gold": 25869.52151,
      "pb": 3.56,
      "pe": 21.45,
      "yield": 1.25,
      "date": "2014-11-17"
  },
  "2014-11-18": {
      "equity": 8425.9,
      "debt": 8.153,
      "gold": 26181.5905,
      "pb": 3.56,
      "pe": 21.47,
      "yield": 1.26,
      "date": "2014-11-18"
  },
  "2014-11-19": {
      "equity": 8382.3,
      "debt": 8.164,
      "gold": 26254.1103,
      "pb": 3.54,
      "pe": 21.33,
      "yield": 1.26,
      "date": "2014-11-19"
  },
  "2014-11-20": {
      "equity": 8401.9,
      "debt": 8.158,
      "gold": 26083.14785,
      "pb": 3.55,
      "pe": 21.51,
      "yield": 1.26,
      "date": "2014-11-20"
  },
  "2014-11-21": {
      "equity": 8477.3,
      "debt": 8.168,
      "gold": 25938.59856,
      "pb": 3.58,
      "pe": 21.7,
      "yield": 1.25,
      "date": "2014-11-21"
  },
  "2014-11-22": {
      "equity": 8477.3,
      "debt": 8.168,
      "gold": 25938.59856,
      "pb": 3.58,
      "pe": 21.7,
      "yield": 1.25,
      "date": "2014-11-22"
  },
  "2014-11-23": {
      "equity": 8477.3,
      "debt": 8.168,
      "gold": 25938.59856,
      "pb": 3.58,
      "pe": 21.7,
      "yield": 1.25,
      "date": "2014-11-23"
  },
  "2014-11-24": {
      "equity": 8530.1,
      "debt": 8.162,
      "gold": 26100.47091,
      "pb": 3.6,
      "pe": 21.78,
      "yield": 1.24,
      "date": "2014-11-24"
  },
  "2014-11-25": {
      "equity": 8463.1,
      "debt": 8.158,
      "gold": 26231.77481,
      "pb": 3.57,
      "pe": 21.61,
      "yield": 1.25,
      "date": "2014-11-25"
  },
  "2014-11-26": {
      "equity": 8475.7,
      "debt": 8.143,
      "gold": 26055.15441,
      "pb": 3.58,
      "pe": 21.65,
      "yield": 1.25,
      "date": "2014-11-26"
  },
  "2014-11-27": {
      "equity": 8494.2,
      "debt": 8.147,
      "gold": 26093.02457,
      "pb": 3.59,
      "pe": 21.7,
      "yield": 1.25,
      "date": "2014-11-27"
  },
  "2014-11-28": {
      "equity": 8588.2,
      "debt": 8.087,
      "gold": 26017.05145,
      "pb": 3.63,
      "pe": 21.94,
      "yield": 1.23,
      "date": "2014-11-28"
  },
  "2014-11-29": {
      "equity": 8588.2,
      "debt": 8.087,
      "gold": 26017.05145,
      "pb": 3.63,
      "pe": 21.94,
      "yield": 1.23,
      "date": "2014-11-29"
  },
  "2014-11-30": {
      "equity": 8588.2,
      "debt": 8.087,
      "gold": 26017.05145,
      "pb": 3.63,
      "pe": 21.94,
      "yield": 1.23,
      "date": "2014-11-30"
  },
  "2014-12-01": {
      "equity": 8555.9,
      "debt": 8.059,
      "gold": 25743.77326,
      "pb": 3.61,
      "pe": 21.85,
      "yield": 1.24,
      "date": "2014-12-01"
  },
  "2014-12-02": {
      "equity": 8524.7,
      "debt": 7.969,
      "gold": 26100.54851,
      "pb": 3.6,
      "pe": 21.77,
      "yield": 1.24,
      "date": "2014-12-02"
  },
  "2014-12-03": {
      "equity": 8537.6,
      "debt": 7.971,
      "gold": 26270.14939,
      "pb": 3.61,
      "pe": 21.81,
      "yield": 1.24,
      "date": "2014-12-03"
  },
  "2014-12-04": {
      "equity": 8564.4,
      "debt": 7.967,
      "gold": 26241.08009,
      "pb": 3.62,
      "pe": 21.87,
      "yield": 1.23,
      "date": "2014-12-04"
  },
  "2014-12-05": {
      "equity": 8538.3,
      "debt": 7.936,
      "gold": 26311.45876,
      "pb": 3.61,
      "pe": 21.81,
      "yield": 1.24,
      "date": "2014-12-05"
  },
  "2014-12-06": {
      "equity": 8538.3,
      "debt": 7.936,
      "gold": 26311.45876,
      "pb": 3.61,
      "pe": 21.81,
      "yield": 1.24,
      "date": "2014-12-06"
  },
  "2014-12-07": {
      "equity": 8538.3,
      "debt": 7.936,
      "gold": 26311.45876,
      "pb": 3.61,
      "pe": 21.81,
      "yield": 1.24,
      "date": "2014-12-07"
  },
  "2014-12-08": {
      "equity": 8438.2,
      "debt": 7.917,
      "gold": 26066.81599,
      "pb": 3.57,
      "pe": 21.56,
      "yield": 1.25,
      "date": "2014-12-08"
  },
  "2014-12-09": {
      "equity": 8340.7,
      "debt": 7.902,
      "gold": 26393.44962,
      "pb": 3.52,
      "pe": 21.31,
      "yield": 1.27,
      "date": "2014-12-09"
  },
  "2014-12-10": {
      "equity": 8355.6,
      "debt": 7.908,
      "gold": 26934.6761,
      "pb": 3.53,
      "pe": 21.35,
      "yield": 1.27,
      "date": "2014-12-10"
  },
  "2014-12-11": {
      "equity": 8292.9,
      "debt": 7.866,
      "gold": 26881.30655,
      "pb": 3.5,
      "pe": 21.19,
      "yield": 1.27,
      "date": "2014-12-11"
  },
  "2014-12-12": {
      "equity": 8224.1,
      "debt": 7.83,
      "gold": 27007.45692,
      "pb": 3.47,
      "pe": 21.01,
      "yield": 1.29,
      "date": "2014-12-12"
  },
  "2014-12-13": {
      "equity": 8224.1,
      "debt": 7.83,
      "gold": 27007.45692,
      "pb": 3.47,
      "pe": 21.01,
      "yield": 1.29,
      "date": "2014-12-13"
  },
  "2014-12-14": {
      "equity": 8224.1,
      "debt": 7.831,
      "gold": 27007.45692,
      "pb": 3.47,
      "pe": 21.01,
      "yield": 1.29,
      "date": "2014-12-14"
  },
  "2014-12-15": {
      "equity": 8219.6,
      "debt": 7.832,
      "gold": 27200.50089,
      "pb": 3.47,
      "pe": 21,
      "yield": 1.29,
      "date": "2014-12-15"
  },
  "2014-12-16": {
      "equity": 8067.6,
      "debt": 7.987,
      "gold": 27020.40248,
      "pb": 3.41,
      "pe": 20.61,
      "yield": 1.31,
      "date": "2014-12-16"
  },
  "2014-12-17": {
      "equity": 8029.8,
      "debt": 7.969,
      "gold": 26820.22258,
      "pb": 3.39,
      "pe": 20.51,
      "yield": 1.32,
      "date": "2014-12-17"
  },
  "2014-12-18": {
      "equity": 8159.3,
      "debt": 7.928,
      "gold": 26924.39373,
      "pb": 3.45,
      "pe": 20.85,
      "yield": 1.3,
      "date": "2014-12-18"
  },
  "2014-12-19": {
      "equity": 8225.2,
      "debt": 7.96,
      "gold": 26781.59756,
      "pb": 3.48,
      "pe": 21.01,
      "yield": 1.29,
      "date": "2014-12-19"
  },
  "2014-12-20": {
      "equity": 8225.2,
      "debt": 7.96,
      "gold": 26781.59756,
      "pb": 3.48,
      "pe": 21.01,
      "yield": 1.29,
      "date": "2014-12-20"
  },
  "2014-12-21": {
      "equity": 8225.2,
      "debt": 7.96,
      "gold": 26781.59756,
      "pb": 3.48,
      "pe": 21.01,
      "yield": 1.29,
      "date": "2014-12-21"
  },
  "2014-12-22": {
      "equity": 8324,
      "debt": 7.954,
      "gold": 26692.06864,
      "pb": 3.52,
      "pe": 21.27,
      "yield": 1.27,
      "date": "2014-12-22"
  },
  "2014-12-23": {
      "equity": 8267,
      "debt": 7.922,
      "gold": 26399.62962,
      "pb": 3.49,
      "pe": 21.12,
      "yield": 1.28,
      "date": "2014-12-23"
  },
  "2014-12-24": {
      "equity": 8174.1,
      "debt": 7.96,
      "gold": 26375.02249,
      "pb": 3.45,
      "pe": 20.88,
      "yield": 1.29,
      "date": "2014-12-24"
  },
  "2014-12-25": {
      "equity": 8174.1,
      "debt": 7.96,
      "gold": 26375.02249,
      "pb": 3.45,
      "pe": 20.88,
      "yield": 1.29,
      "date": "2014-12-25"
  },
  "2014-12-26": {
      "equity": 8200.7,
      "debt": 7.978,
      "gold": 26375.02249,
      "pb": 3.46,
      "pe": 20.95,
      "yield": 1.29,
      "date": "2014-12-26"
  },
  "2014-12-27": {
      "equity": 8200.7,
      "debt": 7.978,
      "gold": 26375.02249,
      "pb": 3.46,
      "pe": 20.95,
      "yield": 1.29,
      "date": "2014-12-27"
  },
  "2014-12-28": {
      "equity": 8200.7,
      "debt": 7.981,
      "gold": 26375.02249,
      "pb": 3.46,
      "pe": 20.95,
      "yield": 1.29,
      "date": "2014-12-28"
  },
  "2014-12-29": {
      "equity": 8246.3,
      "debt": 7.927,
      "gold": 26822.7235,
      "pb": 3.47,
      "pe": 21.07,
      "yield": 1.28,
      "date": "2014-12-29"
  },
  "2014-12-30": {
      "equity": 8248.2,
      "debt": 7.873,
      "gold": 26486.4107,
      "pb": 3.48,
      "pe": 21.07,
      "yield": 1.28,
      "date": "2014-12-30"
  },
  "2014-12-31": {
      "equity": 8282.7,
      "debt": 7.855,
      "gold": 26745.64984,
      "pb": 3.49,
      "pe": 21.16,
      "yield": 1.27,
      "date": "2014-12-31"
  },
  "2015-01-01": {
      "equity": 8284,
      "debt": 7.88,
      "gold": 26745.64984,
      "pb": 3.49,
      "pe": 21.16,
      "yield": 1.27,
      "date": "2015-01-01"
  },
  "2015-01-02": {
      "equity": 8395.4,
      "debt": 7.872,
      "gold": 26423.86285,
      "pb": 3.54,
      "pe": 21.45,
      "yield": 1.26,
      "date": "2015-01-02"
  },
  "2015-01-03": {
      "equity": 8395.4,
      "debt": 7.872,
      "gold": 26423.86285,
      "pb": 3.54,
      "pe": 21.45,
      "yield": 1.26,
      "date": "2015-01-03"
  },
  "2015-01-04": {
      "equity": 8395.4,
      "debt": 7.872,
      "gold": 26423.86285,
      "pb": 3.54,
      "pe": 21.45,
      "yield": 1.26,
      "date": "2015-01-04"
  },
  "2015-01-05": {
      "equity": 8378.4,
      "debt": 7.892,
      "gold": 26678.98552,
      "pb": 3.53,
      "pe": 21.4,
      "yield": 1.26,
      "date": "2015-01-05"
  },
  "2015-01-06": {
      "equity": 8127.3,
      "debt": 7.899,
      "gold": 27065.15106,
      "pb": 3.42,
      "pe": 20.76,
      "yield": 1.3,
      "date": "2015-01-06"
  },
  "2015-01-07": {
      "equity": 8102.1,
      "debt": 7.856,
      "gold": 27072.88312,
      "pb": 3.41,
      "pe": 20.7,
      "yield": 1.3,
      "date": "2015-01-07"
  },
  "2015-01-08": {
      "equity": 8234.6,
      "debt": 7.86,
      "gold": 26592.19739,
      "pb": 3.47,
      "pe": 21.04,
      "yield": 1.28,
      "date": "2015-01-08"
  },
  "2015-01-09": {
      "equity": 8284.5,
      "debt": 7.844,
      "gold": 26539.01832,
      "pb": 3.49,
      "pe": 21.16,
      "yield": 1.27,
      "date": "2015-01-09"
  },
  "2015-01-10": {
      "equity": 8284.5,
      "debt": 7.844,
      "gold": 26539.01832,
      "pb": 3.49,
      "pe": 21.16,
      "yield": 1.27,
      "date": "2015-01-10"
  },
  "2015-01-11": {
      "equity": 8284.5,
      "debt": 7.844,
      "gold": 26539.01832,
      "pb": 3.49,
      "pe": 21.16,
      "yield": 1.27,
      "date": "2015-01-11"
  },
  "2015-01-12": {
      "equity": 8323,
      "debt": 7.812,
      "gold": 26756.8846,
      "pb": 3.51,
      "pe": 21.27,
      "yield": 1.27,
      "date": "2015-01-12"
  },
  "2015-01-13": {
      "equity": 8299.4,
      "debt": 7.768,
      "gold": 27083.66285,
      "pb": 3.5,
      "pe": 21.21,
      "yield": 1.27,
      "date": "2015-01-13"
  },
  "2015-01-14": {
      "equity": 8277.5,
      "debt": 7.772,
      "gold": 26937.62147,
      "pb": 3.49,
      "pe": 21.14,
      "yield": 1.27,
      "date": "2015-01-14"
  },
  "2015-01-15": {
      "equity": 8494.1,
      "debt": 7.694,
      "gold": 26948.75042,
      "pb": 3.58,
      "pe": 21.7,
      "yield": 1.24,
      "date": "2015-01-15"
  },
  "2015-01-16": {
      "equity": 8513.8,
      "debt": 7.706,
      "gold": 27326.91935,
      "pb": 3.59,
      "pe": 21.75,
      "yield": 1.24,
      "date": "2015-01-16"
  },
  "2015-01-17": {
      "equity": 8513.8,
      "debt": 7.706,
      "gold": 27326.91935,
      "pb": 3.59,
      "pe": 21.75,
      "yield": 1.24,
      "date": "2015-01-17"
  },
  "2015-01-18": {
      "equity": 8513.8,
      "debt": 7.706,
      "gold": 27326.91935,
      "pb": 3.59,
      "pe": 21.75,
      "yield": 1.24,
      "date": "2015-01-18"
  },
  "2015-01-19": {
      "equity": 8550.7,
      "debt": 7.723,
      "gold": 27746.55285,
      "pb": 3.6,
      "pe": 21.8,
      "yield": 1.23,
      "date": "2015-01-19"
  },
  "2015-01-20": {
      "equity": 8695.6,
      "debt": 7.723,
      "gold": 28142.60216,
      "pb": 3.67,
      "pe": 22.16,
      "yield": 1.21,
      "date": "2015-01-20"
  },
  "2015-01-21": {
      "equity": 8729.5,
      "debt": 7.69,
      "gold": 28183.35068,
      "pb": 3.68,
      "pe": 22.21,
      "yield": 1.21,
      "date": "2015-01-21"
  },
  "2015-01-22": {
      "equity": 8761.4,
      "debt": 7.712,
      "gold": 27843.49989,
      "pb": 3.69,
      "pe": 22.25,
      "yield": 1.2,
      "date": "2015-01-22"
  },
  "2015-01-23": {
      "equity": 8835.6,
      "debt": 7.704,
      "gold": 28027.46786,
      "pb": 3.73,
      "pe": 22.48,
      "yield": 1.19,
      "date": "2015-01-23"
  },
  "2015-01-24": {
      "equity": 8835.6,
      "debt": 7.704,
      "gold": 28027.46786,
      "pb": 3.73,
      "pe": 22.48,
      "yield": 1.19,
      "date": "2015-01-24"
  },
  "2015-01-25": {
      "equity": 8835.6,
      "debt": 7.704,
      "gold": 28027.46786,
      "pb": 3.73,
      "pe": 22.48,
      "yield": 1.19,
      "date": "2015-01-25"
  },
  "2015-01-26": {
      "equity": 8835.6,
      "debt": 7.704,
      "gold": 27794.53959,
      "pb": 3.73,
      "pe": 22.48,
      "yield": 1.19,
      "date": "2015-01-26"
  },
  "2015-01-27": {
      "equity": 8910.5,
      "debt": 7.704,
      "gold": 27700.1993,
      "pb": 3.76,
      "pe": 22.67,
      "yield": 1.18,
      "date": "2015-01-27"
  },
  "2015-01-28": {
      "equity": 8914.3,
      "debt": 7.705,
      "gold": 27840.09595,
      "pb": 3.76,
      "pe": 22.67,
      "yield": 1.18,
      "date": "2015-01-28"
  },
  "2015-01-29": {
      "equity": 8952.3,
      "debt": 7.711,
      "gold": 27868.70315,
      "pb": 3.77,
      "pe": 22.84,
      "yield": 1.18,
      "date": "2015-01-29"
  },
  "2015-01-30": {
      "equity": 8808.9,
      "debt": 7.691,
      "gold": 27668.10702,
      "pb": 3.71,
      "pe": 22.48,
      "yield": 1.2,
      "date": "2015-01-30"
  },
  "2015-01-31": {
      "equity": 8808.9,
      "debt": 7.691,
      "gold": 27668.10702,
      "pb": 3.71,
      "pe": 22.48,
      "yield": 1.2,
      "date": "2015-01-31"
  },
  "2015-02-01": {
      "equity": 8808.9,
      "debt": 7.693,
      "gold": 27668.10702,
      "pb": 3.71,
      "pe": 22.48,
      "yield": 1.2,
      "date": "2015-02-01"
  },
  "2015-02-02": {
      "equity": 8797.4,
      "debt": 7.652,
      "gold": 27715.00026,
      "pb": 3.71,
      "pe": 22.51,
      "yield": 1.2,
      "date": "2015-02-02"
  },
  "2015-02-03": {
      "equity": 8756.5,
      "debt": 7.727,
      "gold": 27860.54781,
      "pb": 3.69,
      "pe": 22.42,
      "yield": 1.21,
      "date": "2015-02-03"
  },
  "2015-02-04": {
      "equity": 8723.7,
      "debt": 7.717,
      "gold": 27674.39285,
      "pb": 3.68,
      "pe": 22.53,
      "yield": 1.21,
      "date": "2015-02-04"
  },
  "2015-02-05": {
      "equity": 8711.7,
      "debt": 7.699,
      "gold": 27498.83772,
      "pb": 3.67,
      "pe": 22.57,
      "yield": 1.21,
      "date": "2015-02-05"
  },
  "2015-02-06": {
      "equity": 8661,
      "debt": 7.7,
      "gold": 27654.22318,
      "pb": 3.65,
      "pe": 22.44,
      "yield": 1.22,
      "date": "2015-02-06"
  },
  "2015-02-07": {
      "equity": 8661,
      "debt": 7.7,
      "gold": 27654.22318,
      "pb": 3.65,
      "pe": 22.44,
      "yield": 1.22,
      "date": "2015-02-07"
  },
  "2015-02-08": {
      "equity": 8661,
      "debt": 7.7,
      "gold": 27654.22318,
      "pb": 3.65,
      "pe": 22.44,
      "yield": 1.22,
      "date": "2015-02-08"
  },
  "2015-02-09": {
      "equity": 8526.3,
      "debt": 7.728,
      "gold": 27203.60853,
      "pb": 3.59,
      "pe": 22.59,
      "yield": 1.24,
      "date": "2015-02-09"
  },
  "2015-02-10": {
      "equity": 8565.5,
      "debt": 7.728,
      "gold": 27200.25045,
      "pb": 3.61,
      "pe": 22.69,
      "yield": 1.23,
      "date": "2015-02-10"
  },
  "2015-02-11": {
      "equity": 8627.4,
      "debt": 7.732,
      "gold": 27228.54724,
      "pb": 3.64,
      "pe": 22.85,
      "yield": 1.22,
      "date": "2015-02-11"
  },
  "2015-02-12": {
      "equity": 8711.5,
      "debt": 7.743,
      "gold": 26830.59666,
      "pb": 3.67,
      "pe": 23.07,
      "yield": 1.21,
      "date": "2015-02-12"
  },
  "2015-02-13": {
      "equity": 8805.5,
      "debt": 7.697,
      "gold": 26851.96564,
      "pb": 3.71,
      "pe": 23.31,
      "yield": 1.2,
      "date": "2015-02-13"
  },
  "2015-02-14": {
      "equity": 8805.5,
      "debt": 7.697,
      "gold": 26851.96564,
      "pb": 3.71,
      "pe": 23.31,
      "yield": 1.2,
      "date": "2015-02-14"
  },
  "2015-02-15": {
      "equity": 8805.5,
      "debt": 7.697,
      "gold": 26851.96564,
      "pb": 3.71,
      "pe": 23.31,
      "yield": 1.2,
      "date": "2015-02-15"
  },
  "2015-02-16": {
      "equity": 8809.3,
      "debt": 7.707,
      "gold": 27020.08854,
      "pb": 3.74,
      "pe": 23.22,
      "yield": 1.25,
      "date": "2015-02-16"
  },
  "2015-02-17": {
      "equity": 8809.3,
      "debt": 7.707,
      "gold": 26801.18521,
      "pb": 3.74,
      "pe": 23.22,
      "yield": 1.25,
      "date": "2015-02-17"
  },
  "2015-02-18": {
      "equity": 8869.1,
      "debt": 7.708,
      "gold": 26482.90799,
      "pb": 3.77,
      "pe": 23.28,
      "yield": 1.24,
      "date": "2015-02-18"
  },
  "2015-02-19": {
      "equity": 8895.3,
      "debt": 7.71,
      "gold": 26722.20321,
      "pb": 3.78,
      "pe": 23.76,
      "yield": 1.24,
      "date": "2015-02-19"
  },
  "2015-02-20": {
      "equity": 8833.6,
      "debt": 7.695,
      "gold": 26420.15556,
      "pb": 3.75,
      "pe": 23.62,
      "yield": 1.25,
      "date": "2015-02-20"
  },
  "2015-02-21": {
      "equity": 8833.6,
      "debt": 7.695,
      "gold": 26420.15556,
      "pb": 3.75,
      "pe": 23.62,
      "yield": 1.25,
      "date": "2015-02-21"
  },
  "2015-02-22": {
      "equity": 8833.6,
      "debt": 7.695,
      "gold": 26420.15556,
      "pb": 3.75,
      "pe": 23.62,
      "yield": 1.25,
      "date": "2015-02-22"
  },
  "2015-02-23": {
      "equity": 8754.9,
      "debt": 7.702,
      "gold": 26195.15688,
      "pb": 3.72,
      "pe": 23.41,
      "yield": 1.26,
      "date": "2015-02-23"
  },
  "2015-02-24": {
      "equity": 8762.1,
      "debt": 7.716,
      "gold": 26236.52269,
      "pb": 3.72,
      "pe": 23.43,
      "yield": 1.26,
      "date": "2015-02-24"
  },
  "2015-02-25": {
      "equity": 8767.2,
      "debt": 7.706,
      "gold": 26327.06044,
      "pb": 3.72,
      "pe": 23.44,
      "yield": 1.26,
      "date": "2015-02-25"
  },
  "2015-02-26": {
      "equity": 8683.8,
      "debt": 7.738,
      "gold": 26574.82143,
      "pb": 3.69,
      "pe": 23.22,
      "yield": 1.27,
      "date": "2015-02-26"
  },
  "2015-02-27": {
      "equity": 8844.6,
      "debt": 7.724,
      "gold": 26217.0973,
      "pb": 3.76,
      "pe": 23.65,
      "yield": 1.24,
      "date": "2015-02-27"
  },
  "2015-02-28": {
      "equity": 8901.8,
      "debt": 7.724,
      "gold": 26217.0973,
      "pb": 3.78,
      "pe": 23.8,
      "yield": 1.24,
      "date": "2015-02-28"
  },
  "2015-03-01": {
      "equity": 8901.8,
      "debt": 7.724,
      "gold": 26217.0973,
      "pb": 3.78,
      "pe": 23.8,
      "yield": 1.24,
      "date": "2015-03-01"
  },
  "2015-03-02": {
      "equity": 8956.7,
      "debt": 7.741,
      "gold": 26555.87577,
      "pb": 3.8,
      "pe": 23.95,
      "yield": 1.23,
      "date": "2015-03-02"
  },
  "2015-03-03": {
      "equity": 8996.2,
      "debt": 7.754,
      "gold": 26319.48712,
      "pb": 3.82,
      "pe": 24.06,
      "yield": 1.22,
      "date": "2015-03-03"
  },
  "2015-03-04": {
      "equity": 8922.6,
      "debt": 7.685,
      "gold": 26451.36246,
      "pb": 3.79,
      "pe": 23.86,
      "yield": 1.23,
      "date": "2015-03-04"
  },
  "2015-03-05": {
      "equity": 8937.7,
      "debt": 7.705,
      "gold": 26403.85192,
      "pb": 3.8,
      "pe": 23.9,
      "yield": 1.23,
      "date": "2015-03-05"
  },
  "2015-03-06": {
      "equity": 8937.7,
      "debt": 7.705,
      "gold": 26335.07117,
      "pb": 3.8,
      "pe": 23.9,
      "yield": 1.23,
      "date": "2015-03-06"
  },
  "2015-03-07": {
      "equity": 8937.7,
      "debt": 7.705,
      "gold": 26335.07117,
      "pb": 3.8,
      "pe": 23.9,
      "yield": 1.23,
      "date": "2015-03-07"
  },
  "2015-03-08": {
      "equity": 8937.7,
      "debt": 7.705,
      "gold": 26335.07117,
      "pb": 3.8,
      "pe": 23.9,
      "yield": 1.23,
      "date": "2015-03-08"
  },
  "2015-03-09": {
      "equity": 8756.7,
      "debt": 7.74,
      "gold": 25971.17762,
      "pb": 3.73,
      "pe": 23.48,
      "yield": 1.25,
      "date": "2015-03-09"
  },
  "2015-03-10": {
      "equity": 8712,
      "debt": 7.747,
      "gold": 25747.62518,
      "pb": 3.71,
      "pe": 23.36,
      "yield": 1.26,
      "date": "2015-03-10"
  },
  "2015-03-11": {
      "equity": 8699.9,
      "debt": 7.759,
      "gold": 25651.99033,
      "pb": 3.7,
      "pe": 23.32,
      "yield": 1.26,
      "date": "2015-03-11"
  },
  "2015-03-12": {
      "equity": 8776,
      "debt": 7.722,
      "gold": 25595.75301,
      "pb": 3.74,
      "pe": 23.53,
      "yield": 1.25,
      "date": "2015-03-12"
  },
  "2015-03-13": {
      "equity": 8647.7,
      "debt": 7.796,
      "gold": 25760.21799,
      "pb": 3.68,
      "pe": 23.18,
      "yield": 1.27,
      "date": "2015-03-13"
  },
  "2015-03-14": {
      "equity": 8647.7,
      "debt": 7.796,
      "gold": 25760.21799,
      "pb": 3.68,
      "pe": 23.18,
      "yield": 1.27,
      "date": "2015-03-14"
  },
  "2015-03-15": {
      "equity": 8647.7,
      "debt": 7.796,
      "gold": 25760.21799,
      "pb": 3.68,
      "pe": 23.18,
      "yield": 1.27,
      "date": "2015-03-15"
  },
  "2015-03-16": {
      "equity": 8633.1,
      "debt": 7.804,
      "gold": 25632.43091,
      "pb": 3.68,
      "pe": 23.14,
      "yield": 1.27,
      "date": "2015-03-16"
  },
  "2015-03-17": {
      "equity": 8723.3,
      "debt": 7.782,
      "gold": 25546.29182,
      "pb": 3.71,
      "pe": 23.39,
      "yield": 1.26,
      "date": "2015-03-17"
  },
  "2015-03-18": {
      "equity": 8685.9,
      "debt": 7.792,
      "gold": 25395.98229,
      "pb": 3.7,
      "pe": 23.29,
      "yield": 1.26,
      "date": "2015-03-18"
  },
  "2015-03-19": {
      "equity": 8634.6,
      "debt": 7.756,
      "gold": 25658.33965,
      "pb": 3.68,
      "pe": 23.15,
      "yield": 1.27,
      "date": "2015-03-19"
  },
  "2015-03-20": {
      "equity": 8570.9,
      "debt": 7.746,
      "gold": 25738.86312,
      "pb": 3.65,
      "pe": 22.98,
      "yield": 1.28,
      "date": "2015-03-20"
  },
  "2015-03-21": {
      "equity": 8570.9,
      "debt": 7.746,
      "gold": 25738.86312,
      "pb": 3.65,
      "pe": 22.98,
      "yield": 1.28,
      "date": "2015-03-21"
  },
  "2015-03-22": {
      "equity": 8570.9,
      "debt": 7.746,
      "gold": 25738.86312,
      "pb": 3.65,
      "pe": 22.98,
      "yield": 1.28,
      "date": "2015-03-22"
  },
  "2015-03-23": {
      "equity": 8550.9,
      "debt": 7.756,
      "gold": 25913.53992,
      "pb": 3.64,
      "pe": 22.92,
      "yield": 1.28,
      "date": "2015-03-23"
  },
  "2015-03-24": {
      "equity": 8542.9,
      "debt": 7.75,
      "gold": 26270.27284,
      "pb": 3.64,
      "pe": 22.9,
      "yield": 1.29,
      "date": "2015-03-24"
  },
  "2015-03-25": {
      "equity": 8530.8,
      "debt": 7.772,
      "gold": 26269.37336,
      "pb": 3.63,
      "pe": 22.87,
      "yield": 1.29,
      "date": "2015-03-25"
  },
  "2015-03-26": {
      "equity": 8342.1,
      "debt": 7.797,
      "gold": 26829.10457,
      "pb": 3.55,
      "pe": 22.36,
      "yield": 1.32,
      "date": "2015-03-26"
  },
  "2015-03-27": {
      "equity": 8341.4,
      "debt": 7.776,
      "gold": 26452.60057,
      "pb": 3.59,
      "pe": 22.3,
      "yield": 1.3,
      "date": "2015-03-27"
  },
  "2015-03-28": {
      "equity": 8341.4,
      "debt": 7.776,
      "gold": 26452.60057,
      "pb": 3.59,
      "pe": 22.3,
      "yield": 1.3,
      "date": "2015-03-28"
  },
  "2015-03-29": {
      "equity": 8341.4,
      "debt": 7.776,
      "gold": 26452.60057,
      "pb": 3.59,
      "pe": 22.3,
      "yield": 1.3,
      "date": "2015-03-29"
  },
  "2015-03-30": {
      "equity": 8492.3,
      "debt": 7.761,
      "gold": 26180.97674,
      "pb": 3.65,
      "pe": 22.7,
      "yield": 1.28,
      "date": "2015-03-30"
  },
  "2015-03-31": {
      "equity": 8491,
      "debt": 7.738,
      "gold": 25918.54177,
      "pb": 3.65,
      "pe": 22.7,
      "yield": 1.28,
      "date": "2015-03-31"
  },
  "2015-04-01": {
      "equity": 8586.2,
      "debt": 7.738,
      "gold": 25962.95878,
      "pb": 3.69,
      "pe": 22.95,
      "yield": 1.27,
      "date": "2015-04-01"
  },
  "2015-04-02": {
      "equity": 8586.2,
      "debt": 7.738,
      "gold": 26408.03894,
      "pb": 3.69,
      "pe": 22.95,
      "yield": 1.27,
      "date": "2015-04-02"
  },
  "2015-04-03": {
      "equity": 8586.2,
      "debt": 7.738,
      "gold": 26408.03894,
      "pb": 3.69,
      "pe": 22.95,
      "yield": 1.27,
      "date": "2015-04-03"
  },
  "2015-04-04": {
      "equity": 8586.2,
      "debt": 7.738,
      "gold": 26408.03894,
      "pb": 3.69,
      "pe": 22.95,
      "yield": 1.27,
      "date": "2015-04-04"
  },
  "2015-04-05": {
      "equity": 8586.2,
      "debt": 7.738,
      "gold": 26408.03894,
      "pb": 3.69,
      "pe": 22.95,
      "yield": 1.27,
      "date": "2015-04-05"
  },
  "2015-04-06": {
      "equity": 8659.9,
      "debt": 7.721,
      "gold": 26408.03894,
      "pb": 3.73,
      "pe": 23.18,
      "yield": 1.26,
      "date": "2015-04-06"
  },
  "2015-04-07": {
      "equity": 8660.3,
      "debt": 7.786,
      "gold": 26539.51216,
      "pb": 3.73,
      "pe": 23.19,
      "yield": 1.25,
      "date": "2015-04-07"
  },
  "2015-04-08": {
      "equity": 8714.4,
      "debt": 7.786,
      "gold": 26592.7653,
      "pb": 3.75,
      "pe": 23.33,
      "yield": 1.25,
      "date": "2015-04-08"
  },
  "2015-04-09": {
      "equity": 8778.3,
      "debt": 7.774,
      "gold": 26266.39623,
      "pb": 3.78,
      "pe": 23.5,
      "yield": 1.24,
      "date": "2015-04-09"
  },
  "2015-04-10": {
      "equity": 8780.3,
      "debt": 7.801,
      "gold": 26383.42122,
      "pb": 3.78,
      "pe": 23.51,
      "yield": 1.24,
      "date": "2015-04-10"
  },
  "2015-04-11": {
      "equity": 8780.3,
      "debt": 7.801,
      "gold": 26383.42122,
      "pb": 3.78,
      "pe": 23.51,
      "yield": 1.24,
      "date": "2015-04-11"
  },
  "2015-04-12": {
      "equity": 8780.3,
      "debt": 7.801,
      "gold": 26383.42122,
      "pb": 3.78,
      "pe": 23.51,
      "yield": 1.24,
      "date": "2015-04-12"
  },
  "2015-04-13": {
      "equity": 8834,
      "debt": 7.796,
      "gold": 26369.81252,
      "pb": 3.81,
      "pe": 23.66,
      "yield": 1.23,
      "date": "2015-04-13"
  },
  "2015-04-14": {
      "equity": 8834,
      "debt": 7.796,
      "gold": 26232.8295,
      "pb": 3.81,
      "pe": 23.66,
      "yield": 1.23,
      "date": "2015-04-14"
  },
  "2015-04-15": {
      "equity": 8750.2,
      "debt": 7.782,
      "gold": 26169.31163,
      "pb": 3.77,
      "pe": 23.43,
      "yield": 1.25,
      "date": "2015-04-15"
  },
  "2015-04-16": {
      "equity": 8706.7,
      "debt": 7.799,
      "gold": 26480.29419,
      "pb": 3.75,
      "pe": 23.33,
      "yield": 1.25,
      "date": "2015-04-16"
  },
  "2015-04-17": {
      "equity": 8606,
      "debt": 7.791,
      "gold": 26562.1757,
      "pb": 3.71,
      "pe": 23.06,
      "yield": 1.27,
      "date": "2015-04-17"
  },
  "2015-04-18": {
      "equity": 8606,
      "debt": 7.791,
      "gold": 26562.1757,
      "pb": 3.71,
      "pe": 23.06,
      "yield": 1.27,
      "date": "2015-04-18"
  },
  "2015-04-19": {
      "equity": 8606,
      "debt": 7.791,
      "gold": 26562.1757,
      "pb": 3.71,
      "pe": 23.06,
      "yield": 1.27,
      "date": "2015-04-19"
  },
  "2015-04-20": {
      "equity": 8448.1,
      "debt": 7.794,
      "gold": 26766.05584,
      "pb": 3.64,
      "pe": 22.64,
      "yield": 1.29,
      "date": "2015-04-20"
  },
  "2015-04-21": {
      "equity": 8377.7,
      "debt": 7.773,
      "gold": 26578.17245,
      "pb": 3.61,
      "pe": 22.44,
      "yield": 1.3,
      "date": "2015-04-21"
  },
  "2015-04-22": {
      "equity": 8429.7,
      "debt": 7.753,
      "gold": 26701.55382,
      "pb": 3.63,
      "pe": 22.59,
      "yield": 1.29,
      "date": "2015-04-22"
  },
  "2015-04-23": {
      "equity": 8398.3,
      "debt": 7.757,
      "gold": 26492.94697,
      "pb": 3.62,
      "pe": 22.5,
      "yield": 1.3,
      "date": "2015-04-23"
  },
  "2015-04-24": {
      "equity": 8305.2,
      "debt": 7.787,
      "gold": 26774.98369,
      "pb": 3.58,
      "pe": 22.34,
      "yield": 1.4,
      "date": "2015-04-24"
  },
  "2015-04-25": {
      "equity": 8305.2,
      "debt": 7.787,
      "gold": 26774.98369,
      "pb": 3.58,
      "pe": 22.34,
      "yield": 1.4,
      "date": "2015-04-25"
  },
  "2015-04-26": {
      "equity": 8305.2,
      "debt": 7.787,
      "gold": 26774.98369,
      "pb": 3.58,
      "pe": 22.34,
      "yield": 1.4,
      "date": "2015-04-26"
  },
  "2015-04-27": {
      "equity": 8213.8,
      "debt": 7.778,
      "gold": 26391.68592,
      "pb": 3.56,
      "pe": 22.26,
      "yield": 1.4,
      "date": "2015-04-27"
  },
  "2015-04-28": {
      "equity": 8285.6,
      "debt": 7.761,
      "gold": 26721.81167,
      "pb": 3.59,
      "pe": 22.46,
      "yield": 1.39,
      "date": "2015-04-28"
  },
  "2015-04-29": {
      "equity": 8239.7,
      "debt": 7.815,
      "gold": 26945.92144,
      "pb": 3.57,
      "pe": 22.29,
      "yield": 1.4,
      "date": "2015-04-29"
  },
  "2015-04-30": {
      "equity": 8181.5,
      "debt": 7.86,
      "gold": 27025.01279,
      "pb": 3.53,
      "pe": 22.07,
      "yield": 1.45,
      "date": "2015-04-30"
  },
  "2015-05-01": {
      "equity": 8181.5,
      "debt": 7.86,
      "gold": 26420.5859,
      "pb": 3.53,
      "pe": 22.07,
      "yield": 1.45,
      "date": "2015-05-01"
  },
  "2015-05-02": {
      "equity": 8181.5,
      "debt": 7.86,
      "gold": 26420.5859,
      "pb": 3.53,
      "pe": 22.07,
      "yield": 1.45,
      "date": "2015-05-02"
  },
  "2015-05-03": {
      "equity": 8181.5,
      "debt": 7.86,
      "gold": 26420.5859,
      "pb": 3.53,
      "pe": 22.07,
      "yield": 1.45,
      "date": "2015-05-03"
  },
  "2015-05-04": {
      "equity": 8331.9,
      "debt": 7.86,
      "gold": 26420.5859,
      "pb": 3.59,
      "pe": 22.47,
      "yield": 1.43,
      "date": "2015-05-04"
  },
  "2015-05-05": {
      "equity": 8324.8,
      "debt": 7.849,
      "gold": 26541.89316,
      "pb": 3.59,
      "pe": 22.45,
      "yield": 1.43,
      "date": "2015-05-05"
  },
  "2015-05-06": {
      "equity": 8097,
      "debt": 7.891,
      "gold": 26744.76799,
      "pb": 3.49,
      "pe": 21.71,
      "yield": 1.47,
      "date": "2015-05-06"
  },
  "2015-05-07": {
      "equity": 8057.3,
      "debt": 7.993,
      "gold": 26755.34313,
      "pb": 3.47,
      "pe": 21.61,
      "yield": 1.47,
      "date": "2015-05-07"
  },
  "2015-05-08": {
      "equity": 8191.5,
      "debt": 7.977,
      "gold": 26640.16649,
      "pb": 3.53,
      "pe": 21.92,
      "yield": 1.45,
      "date": "2015-05-08"
  },
  "2015-05-09": {
      "equity": 8191.5,
      "debt": 7.977,
      "gold": 26640.16649,
      "pb": 3.53,
      "pe": 21.92,
      "yield": 1.45,
      "date": "2015-05-09"
  },
  "2015-05-10": {
      "equity": 8191.5,
      "debt": 7.977,
      "gold": 26640.16649,
      "pb": 3.53,
      "pe": 21.92,
      "yield": 1.45,
      "date": "2015-05-10"
  },
  "2015-05-11": {
      "equity": 8325.2,
      "debt": 7.886,
      "gold": 26726.51017,
      "pb": 3.62,
      "pe": 22.41,
      "yield": 1.42,
      "date": "2015-05-11"
  },
  "2015-05-12": {
      "equity": 8126.9,
      "debt": 7.95,
      "gold": 26859.70476,
      "pb": 3.53,
      "pe": 21.92,
      "yield": 1.45,
      "date": "2015-05-12"
  },
  "2015-05-13": {
      "equity": 8235.4,
      "debt": 7.957,
      "gold": 26909.67389,
      "pb": 3.58,
      "pe": 22.17,
      "yield": 1.43,
      "date": "2015-05-13"
  },
  "2015-05-14": {
      "equity": 8224.2,
      "debt": 7.939,
      "gold": 27155.17734,
      "pb": 3.56,
      "pe": 22.14,
      "yield": 1.44,
      "date": "2015-05-14"
  },
  "2015-05-15": {
      "equity": 8262.3,
      "debt": 7.946,
      "gold": 27182.96266,
      "pb": 3.58,
      "pe": 22.24,
      "yield": 1.43,
      "date": "2015-05-15"
  },
  "2015-05-16": {
      "equity": 8262.3,
      "debt": 7.946,
      "gold": 27182.96266,
      "pb": 3.58,
      "pe": 22.24,
      "yield": 1.43,
      "date": "2015-05-16"
  },
  "2015-05-17": {
      "equity": 8262.3,
      "debt": 7.946,
      "gold": 27182.96266,
      "pb": 3.58,
      "pe": 22.24,
      "yield": 1.43,
      "date": "2015-05-17"
  },
  "2015-05-18": {
      "equity": 8373.6,
      "debt": 7.898,
      "gold": 27585.47064,
      "pb": 3.63,
      "pe": 22.57,
      "yield": 1.41,
      "date": "2015-05-18"
  },
  "2015-05-19": {
      "equity": 8365.6,
      "debt": 7.858,
      "gold": 27453.19318,
      "pb": 3.62,
      "pe": 22.55,
      "yield": 1.41,
      "date": "2015-05-19"
  },
  "2015-05-20": {
      "equity": 8423.2,
      "debt": 7.855,
      "gold": 27112.12896,
      "pb": 3.65,
      "pe": 22.69,
      "yield": 1.4,
      "date": "2015-05-20"
  },
  "2015-05-21": {
      "equity": 8421,
      "debt": 7.876,
      "gold": 27102.17464,
      "pb": 3.65,
      "pe": 22.67,
      "yield": 1.4,
      "date": "2015-05-21"
  },
  "2015-05-22": {
      "equity": 8458.9,
      "debt": 7.858,
      "gold": 27112.35472,
      "pb": 3.62,
      "pe": 22.94,
      "yield": 1.4,
      "date": "2015-05-22"
  },
  "2015-05-23": {
      "equity": 8458.9,
      "debt": 7.858,
      "gold": 27112.35472,
      "pb": 3.62,
      "pe": 22.94,
      "yield": 1.4,
      "date": "2015-05-23"
  },
  "2015-05-24": {
      "equity": 8458.9,
      "debt": 7.858,
      "gold": 27112.35472,
      "pb": 3.62,
      "pe": 22.94,
      "yield": 1.4,
      "date": "2015-05-24"
  },
  "2015-05-25": {
      "equity": 8370.2,
      "debt": 7.864,
      "gold": 27112.35472,
      "pb": 3.58,
      "pe": 22.64,
      "yield": 1.41,
      "date": "2015-05-25"
  },
  "2015-05-26": {
      "equity": 8339.3,
      "debt": 7.891,
      "gold": 26917.02499,
      "pb": 3.57,
      "pe": 22.55,
      "yield": 1.42,
      "date": "2015-05-26"
  },
  "2015-05-27": {
      "equity": 8334.6,
      "debt": 7.868,
      "gold": 26788.64883,
      "pb": 3.57,
      "pe": 22.6,
      "yield": 1.42,
      "date": "2015-05-27"
  },
  "2015-05-28": {
      "equity": 8319,
      "debt": 7.854,
      "gold": 26780.92735,
      "pb": 3.56,
      "pe": 22.61,
      "yield": 1.42,
      "date": "2015-05-28"
  },
  "2015-05-29": {
      "equity": 8433.6,
      "debt": 7.815,
      "gold": 26743.11364,
      "pb": 3.66,
      "pe": 23.12,
      "yield": 1.39,
      "date": "2015-05-29"
  },
  "2015-05-30": {
      "equity": 8433.6,
      "debt": 7.816,
      "gold": 26743.11364,
      "pb": 3.66,
      "pe": 23.12,
      "yield": 1.39,
      "date": "2015-05-30"
  },
  "2015-05-31": {
      "equity": 8433.6,
      "debt": 7.816,
      "gold": 26743.11364,
      "pb": 3.66,
      "pe": 23.12,
      "yield": 1.39,
      "date": "2015-05-31"
  },
  "2015-06-01": {
      "equity": 8433.4,
      "debt": 7.822,
      "gold": 26636.10998,
      "pb": 3.65,
      "pe": 23.25,
      "yield": 1.39,
      "date": "2015-06-01"
  },
  "2015-06-02": {
      "equity": 8236.4,
      "debt": 7.933,
      "gold": 26698.49556,
      "pb": 3.57,
      "pe": 22.71,
      "yield": 1.43,
      "date": "2015-06-02"
  },
  "2015-06-03": {
      "equity": 8135.1,
      "debt": 7.951,
      "gold": 26833.95827,
      "pb": 3.53,
      "pe": 22.41,
      "yield": 1.45,
      "date": "2015-06-03"
  },
  "2015-06-04": {
      "equity": 8130.6,
      "debt": 8.01,
      "gold": 26671.99774,
      "pb": 3.52,
      "pe": 22.53,
      "yield": 1.45,
      "date": "2015-06-04"
  },
  "2015-06-05": {
      "equity": 8114.7,
      "debt": 7.983,
      "gold": 26579.45996,
      "pb": 3.47,
      "pe": 22.49,
      "yield": 1.45,
      "date": "2015-06-05"
  },
  "2015-06-06": {
      "equity": 8114.7,
      "debt": 7.983,
      "gold": 26579.45996,
      "pb": 3.47,
      "pe": 22.49,
      "yield": 1.45,
      "date": "2015-06-06"
  },
  "2015-06-07": {
      "equity": 8114.7,
      "debt": 7.983,
      "gold": 26579.45996,
      "pb": 3.47,
      "pe": 22.49,
      "yield": 1.45,
      "date": "2015-06-07"
  },
  "2015-06-08": {
      "equity": 8044.1,
      "debt": 7.801,
      "gold": 26518.27369,
      "pb": 3.44,
      "pe": 22.29,
      "yield": 1.47,
      "date": "2015-06-08"
  },
  "2015-06-09": {
      "equity": 8022.4,
      "debt": 7.789,
      "gold": 26654.57945,
      "pb": 3.44,
      "pe": 22.23,
      "yield": 1.47,
      "date": "2015-06-09"
  },
  "2015-06-10": {
      "equity": 8124.4,
      "debt": 7.825,
      "gold": 26691.62066,
      "pb": 3.48,
      "pe": 22.52,
      "yield": 1.45,
      "date": "2015-06-10"
  },
  "2015-06-11": {
      "equity": 7965.3,
      "debt": 7.879,
      "gold": 26646.87561,
      "pb": 3.41,
      "pe": 22.08,
      "yield": 1.48,
      "date": "2015-06-11"
  },
  "2015-06-12": {
      "equity": 7982.9,
      "debt": 7.886,
      "gold": 26634.75546,
      "pb": 3.4,
      "pe": 22.12,
      "yield": 1.49,
      "date": "2015-06-12"
  },
  "2015-06-13": {
      "equity": 7982.9,
      "debt": 7.886,
      "gold": 26634.75546,
      "pb": 3.4,
      "pe": 22.12,
      "yield": 1.49,
      "date": "2015-06-13"
  },
  "2015-06-14": {
      "equity": 7982.9,
      "debt": 7.886,
      "gold": 26634.75546,
      "pb": 3.4,
      "pe": 22.12,
      "yield": 1.49,
      "date": "2015-06-14"
  },
  "2015-06-15": {
      "equity": 8013.9,
      "debt": 7.863,
      "gold": 26616.94915,
      "pb": 3.41,
      "pe": 22.21,
      "yield": 1.48,
      "date": "2015-06-15"
  },
  "2015-06-16": {
      "equity": 8047.3,
      "debt": 7.882,
      "gold": 26776.64156,
      "pb": 3.42,
      "pe": 22.3,
      "yield": 1.48,
      "date": "2015-06-16"
  },
  "2015-06-17": {
      "equity": 8091.5,
      "debt": 7.846,
      "gold": 26669.32397,
      "pb": 3.44,
      "pe": 22.43,
      "yield": 1.47,
      "date": "2015-06-17"
  },
  "2015-06-18": {
      "equity": 8174.6,
      "debt": 7.763,
      "gold": 26882.83391,
      "pb": 3.48,
      "pe": 22.66,
      "yield": 1.45,
      "date": "2015-06-18"
  },
  "2015-06-19": {
      "equity": 8224.9,
      "debt": 7.708,
      "gold": 26811.60514,
      "pb": 3.5,
      "pe": 22.79,
      "yield": 1.44,
      "date": "2015-06-19"
  },
  "2015-06-20": {
      "equity": 8224.9,
      "debt": 7.708,
      "gold": 26811.60514,
      "pb": 3.5,
      "pe": 22.79,
      "yield": 1.44,
      "date": "2015-06-20"
  },
  "2015-06-21": {
      "equity": 8224.9,
      "debt": 7.708,
      "gold": 26811.60514,
      "pb": 3.5,
      "pe": 22.79,
      "yield": 1.44,
      "date": "2015-06-21"
  },
  "2015-06-22": {
      "equity": 8353.1,
      "debt": 7.736,
      "gold": 26721.16263,
      "pb": 3.56,
      "pe": 23.15,
      "yield": 1.42,
      "date": "2015-06-22"
  },
  "2015-06-23": {
      "equity": 8381.5,
      "debt": 7.748,
      "gold": 26536.26695,
      "pb": 3.57,
      "pe": 23.23,
      "yield": 1.41,
      "date": "2015-06-23"
  },
  "2015-06-24": {
      "equity": 8360.8,
      "debt": 7.804,
      "gold": 26348.29538,
      "pb": 3.56,
      "pe": 23.17,
      "yield": 1.42,
      "date": "2015-06-24"
  },
  "2015-06-25": {
      "equity": 8398,
      "debt": 7.823,
      "gold": 26321.1591,
      "pb": 3.58,
      "pe": 23.27,
      "yield": 1.41,
      "date": "2015-06-25"
  },
  "2015-06-26": {
      "equity": 8381.1,
      "debt": 7.821,
      "gold": 26329.6425,
      "pb": 3.56,
      "pe": 23.23,
      "yield": 1.42,
      "date": "2015-06-26"
  },
  "2015-06-27": {
      "equity": 8381.1,
      "debt": 7.821,
      "gold": 26329.6425,
      "pb": 3.56,
      "pe": 23.23,
      "yield": 1.42,
      "date": "2015-06-27"
  },
  "2015-06-28": {
      "equity": 8381.1,
      "debt": 7.821,
      "gold": 26329.6425,
      "pb": 3.56,
      "pe": 23.23,
      "yield": 1.42,
      "date": "2015-06-28"
  },
  "2015-06-29": {
      "equity": 8318.4,
      "debt": 7.886,
      "gold": 26452.04677,
      "pb": 3.53,
      "pe": 23.05,
      "yield": 1.43,
      "date": "2015-06-29"
  },
  "2015-06-30": {
      "equity": 8368.5,
      "debt": 7.861,
      "gold": 26365.43502,
      "pb": 3.48,
      "pe": 23.19,
      "yield": 1.44,
      "date": "2015-06-30"
  },
  "2015-07-01": {
      "equity": 8453,
      "debt": 7.817,
      "gold": 26280.22716,
      "pb": 3.52,
      "pe": 23.43,
      "yield": 1.42,
      "date": "2015-07-01"
  },
  "2015-07-02": {
      "equity": 8444.9,
      "debt": 7.811,
      "gold": 26020.77638,
      "pb": 3.51,
      "pe": 23.4,
      "yield": 1.42,
      "date": "2015-07-02"
  },
  "2015-07-03": {
      "equity": 8484.9,
      "debt": 7.799,
      "gold": 26143.1348,
      "pb": 3.52,
      "pe": 23.52,
      "yield": 1.43,
      "date": "2015-07-03"
  },
  "2015-07-04": {
      "equity": 8484.9,
      "debt": 7.799,
      "gold": 26143.1348,
      "pb": 3.52,
      "pe": 23.52,
      "yield": 1.43,
      "date": "2015-07-04"
  },
  "2015-07-05": {
      "equity": 8484.9,
      "debt": 7.799,
      "gold": 26143.1348,
      "pb": 3.52,
      "pe": 23.52,
      "yield": 1.43,
      "date": "2015-07-05"
  },
  "2015-07-06": {
      "equity": 8522.1,
      "debt": 7.753,
      "gold": 25975.47047,
      "pb": 3.54,
      "pe": 23.62,
      "yield": 1.42,
      "date": "2015-07-06"
  },
  "2015-07-07": {
      "equity": 8510.8,
      "debt": 7.78,
      "gold": 26129.72716,
      "pb": 3.54,
      "pe": 23.59,
      "yield": 1.42,
      "date": "2015-07-07"
  },
  "2015-07-08": {
      "equity": 8363,
      "debt": 7.781,
      "gold": 25846.78037,
      "pb": 3.47,
      "pe": 23.18,
      "yield": 1.45,
      "date": "2015-07-08"
  },
  "2015-07-09": {
      "equity": 8328.5,
      "debt": 7.792,
      "gold": 25962.0981,
      "pb": 3.46,
      "pe": 23.08,
      "yield": 1.45,
      "date": "2015-07-09"
  },
  "2015-07-10": {
      "equity": 8360.5,
      "debt": 7.802,
      "gold": 25965.5232,
      "pb": 3.44,
      "pe": 23.17,
      "yield": 1.45,
      "date": "2015-07-10"
  },
  "2015-07-11": {
      "equity": 8360.5,
      "debt": 7.802,
      "gold": 25965.5232,
      "pb": 3.44,
      "pe": 23.17,
      "yield": 1.45,
      "date": "2015-07-11"
  },
  "2015-07-12": {
      "equity": 8360.5,
      "debt": 7.802,
      "gold": 25965.5232,
      "pb": 3.44,
      "pe": 23.17,
      "yield": 1.45,
      "date": "2015-07-12"
  },
  "2015-07-13": {
      "equity": 8459.6,
      "debt": 7.867,
      "gold": 25844.3253,
      "pb": 3.49,
      "pe": 23.45,
      "yield": 1.44,
      "date": "2015-07-13"
  },
  "2015-07-14": {
      "equity": 8454.1,
      "debt": 7.823,
      "gold": 25751.06439,
      "pb": 3.48,
      "pe": 23.44,
      "yield": 1.44,
      "date": "2015-07-14"
  },
  "2015-07-15": {
      "equity": 8523.8,
      "debt": 7.839,
      "gold": 25858.99575,
      "pb": 3.51,
      "pe": 23.63,
      "yield": 1.43,
      "date": "2015-07-15"
  },
  "2015-07-16": {
      "equity": 8608,
      "debt": 7.845,
      "gold": 25626.05337,
      "pb": 3.55,
      "pe": 23.86,
      "yield": 1.41,
      "date": "2015-07-16"
  },
  "2015-07-17": {
      "equity": 8609.8,
      "debt": 7.83,
      "gold": 25588.61356,
      "pb": 3.52,
      "pe": 23.87,
      "yield": 1.42,
      "date": "2015-07-17"
  },
  "2015-07-18": {
      "equity": 8609.8,
      "debt": 7.831,
      "gold": 25588.61356,
      "pb": 3.52,
      "pe": 23.87,
      "yield": 1.42,
      "date": "2015-07-18"
  },
  "2015-07-19": {
      "equity": 8609.8,
      "debt": 7.831,
      "gold": 25588.61356,
      "pb": 3.52,
      "pe": 23.87,
      "yield": 1.42,
      "date": "2015-07-19"
  },
  "2015-07-20": {
      "equity": 8603.4,
      "debt": 7.84,
      "gold": 25025.7606,
      "pb": 3.53,
      "pe": 23.88,
      "yield": 1.42,
      "date": "2015-07-20"
  },
  "2015-07-21": {
      "equity": 8529.4,
      "debt": 7.836,
      "gold": 24846.68513,
      "pb": 3.5,
      "pe": 23.68,
      "yield": 1.43,
      "date": "2015-07-21"
  },
  "2015-07-22": {
      "equity": 8633.5,
      "debt": 7.828,
      "gold": 24629.76419,
      "pb": 3.54,
      "pe": 23.96,
      "yield": 1.41,
      "date": "2015-07-22"
  },
  "2015-07-23": {
      "equity": 8589.8,
      "debt": 7.803,
      "gold": 24807.03011,
      "pb": 3.52,
      "pe": 23.84,
      "yield": 1.42,
      "date": "2015-07-23"
  },
  "2015-07-24": {
      "equity": 8521.5,
      "debt": 7.83,
      "gold": 24501.7831,
      "pb": 3.48,
      "pe": 23.65,
      "yield": 1.43,
      "date": "2015-07-24"
  },
  "2015-07-25": {
      "equity": 8521.5,
      "debt": 7.83,
      "gold": 24501.7831,
      "pb": 3.48,
      "pe": 23.65,
      "yield": 1.43,
      "date": "2015-07-25"
  },
  "2015-07-26": {
      "equity": 8521.5,
      "debt": 7.83,
      "gold": 24501.7831,
      "pb": 3.48,
      "pe": 23.65,
      "yield": 1.43,
      "date": "2015-07-26"
  },
  "2015-07-27": {
      "equity": 8361,
      "debt": 7.828,
      "gold": 24888.51302,
      "pb": 3.42,
      "pe": 23.21,
      "yield": 1.46,
      "date": "2015-07-27"
  },
  "2015-07-28": {
      "equity": 8337,
      "debt": 7.805,
      "gold": 24647.03081,
      "pb": 3.41,
      "pe": 23.02,
      "yield": 1.46,
      "date": "2015-07-28"
  },
  "2015-07-29": {
      "equity": 8375,
      "debt": 7.809,
      "gold": 24692.97871,
      "pb": 3.42,
      "pe": 23.08,
      "yield": 1.45,
      "date": "2015-07-29"
  },
  "2015-07-30": {
      "equity": 8421.8,
      "debt": 7.821,
      "gold": 24549.14196,
      "pb": 3.44,
      "pe": 23.23,
      "yield": 1.45,
      "date": "2015-07-30"
  },
  "2015-07-31": {
      "equity": 8532.8,
      "debt": 7.806,
      "gold": 24374.13006,
      "pb": 3.5,
      "pe": 23.53,
      "yield": 1.41,
      "date": "2015-07-31"
  },
  "2015-08-01": {
      "equity": 8532.8,
      "debt": 7.806,
      "gold": 24374.13006,
      "pb": 3.5,
      "pe": 23.53,
      "yield": 1.41,
      "date": "2015-08-01"
  },
  "2015-08-02": {
      "equity": 8532.8,
      "debt": 7.806,
      "gold": 24374.13006,
      "pb": 3.5,
      "pe": 23.53,
      "yield": 1.41,
      "date": "2015-08-02"
  },
  "2015-08-03": {
      "equity": 8543,
      "debt": 7.813,
      "gold": 24693.02104,
      "pb": 3.5,
      "pe": 23.54,
      "yield": 1.41,
      "date": "2015-08-03"
  },
  "2015-08-04": {
      "equity": 8516.9,
      "debt": 7.84,
      "gold": 24552.79282,
      "pb": 3.49,
      "pe": 23.48,
      "yield": 1.42,
      "date": "2015-08-04"
  },
  "2015-08-05": {
      "equity": 8567.9,
      "debt": 7.836,
      "gold": 24425.86994,
      "pb": 3.51,
      "pe": 23.62,
      "yield": 1.41,
      "date": "2015-08-05"
  },
  "2015-08-06": {
      "equity": 8588.6,
      "debt": 7.806,
      "gold": 24380.62752,
      "pb": 3.52,
      "pe": 23.67,
      "yield": 1.4,
      "date": "2015-08-06"
  },
  "2015-08-07": {
      "equity": 8564.6,
      "debt": 7.809,
      "gold": 24537.02182,
      "pb": 3.49,
      "pe": 23.61,
      "yield": 1.4,
      "date": "2015-08-07"
  },
  "2015-08-08": {
      "equity": 8564.6,
      "debt": 7.809,
      "gold": 24537.02182,
      "pb": 3.49,
      "pe": 23.61,
      "yield": 1.4,
      "date": "2015-08-08"
  },
  "2015-08-09": {
      "equity": 8564.6,
      "debt": 7.809,
      "gold": 24537.02182,
      "pb": 3.49,
      "pe": 23.61,
      "yield": 1.4,
      "date": "2015-08-09"
  },
  "2015-08-10": {
      "equity": 8525.6,
      "debt": 7.788,
      "gold": 24618.91391,
      "pb": 3.48,
      "pe": 23.54,
      "yield": 1.41,
      "date": "2015-08-10"
  },
  "2015-08-11": {
      "equity": 8462.3,
      "debt": 7.796,
      "gold": 25272.59387,
      "pb": 3.45,
      "pe": 23.38,
      "yield": 1.42,
      "date": "2015-08-11"
  },
  "2015-08-12": {
      "equity": 8349.4,
      "debt": 7.796,
      "gold": 25570.52153,
      "pb": 3.4,
      "pe": 23.1,
      "yield": 1.44,
      "date": "2015-08-12"
  },
  "2015-08-13": {
      "equity": 8355.8,
      "debt": 7.744,
      "gold": 25752.75049,
      "pb": 3.41,
      "pe": 23.16,
      "yield": 1.44,
      "date": "2015-08-13"
  },
  "2015-08-14": {
      "equity": 8518.5,
      "debt": 7.745,
      "gold": 25616.76573,
      "pb": 3.45,
      "pe": 23.69,
      "yield": 1.42,
      "date": "2015-08-14"
  },
  "2015-08-15": {
      "equity": 8518.5,
      "debt": 7.745,
      "gold": 25616.76573,
      "pb": 3.45,
      "pe": 23.69,
      "yield": 1.42,
      "date": "2015-08-15"
  },
  "2015-08-16": {
      "equity": 8518.5,
      "debt": 7.745,
      "gold": 25616.76573,
      "pb": 3.45,
      "pe": 23.69,
      "yield": 1.42,
      "date": "2015-08-16"
  },
  "2015-08-17": {
      "equity": 8477.3,
      "debt": 7.744,
      "gold": 25778.12307,
      "pb": 3.44,
      "pe": 23.48,
      "yield": 1.43,
      "date": "2015-08-17"
  },
  "2015-08-18": {
      "equity": 8466.5,
      "debt": 7.744,
      "gold": 25821.47481,
      "pb": 3.43,
      "pe": 23.45,
      "yield": 1.43,
      "date": "2015-08-18"
  },
  "2015-08-19": {
      "equity": 8495.1,
      "debt": 7.741,
      "gold": 25834.21577,
      "pb": 3.44,
      "pe": 23.53,
      "yield": 1.42,
      "date": "2015-08-19"
  },
  "2015-08-20": {
      "equity": 8372.7,
      "debt": 7.747,
      "gold": 26282.49175,
      "pb": 3.39,
      "pe": 23.19,
      "yield": 1.44,
      "date": "2015-08-20"
  },
  "2015-08-21": {
      "equity": 8299.9,
      "debt": 7.784,
      "gold": 26844.18067,
      "pb": 3.36,
      "pe": 22.99,
      "yield": 1.42,
      "date": "2015-08-21"
  },
  "2015-08-22": {
      "equity": 8299.9,
      "debt": 7.784,
      "gold": 26844.18067,
      "pb": 3.36,
      "pe": 22.99,
      "yield": 1.42,
      "date": "2015-08-22"
  },
  "2015-08-23": {
      "equity": 8299.9,
      "debt": 7.784,
      "gold": 26844.18067,
      "pb": 3.36,
      "pe": 22.99,
      "yield": 1.42,
      "date": "2015-08-23"
  },
  "2015-08-24": {
      "equity": 7809,
      "debt": 7.893,
      "gold": 27255.22496,
      "pb": 3.16,
      "pe": 21.63,
      "yield": 1.51,
      "date": "2015-08-24"
  },
  "2015-08-25": {
      "equity": 7880.7,
      "debt": 7.814,
      "gold": 26915.67047,
      "pb": 3.19,
      "pe": 21.83,
      "yield": 1.5,
      "date": "2015-08-25"
  },
  "2015-08-26": {
      "equity": 7791.8,
      "debt": 7.791,
      "gold": 26461.55664,
      "pb": 3.15,
      "pe": 21.59,
      "yield": 1.51,
      "date": "2015-08-26"
  },
  "2015-08-27": {
      "equity": 7948.9,
      "debt": 7.766,
      "gold": 26279.30651,
      "pb": 3.22,
      "pe": 22.02,
      "yield": 1.48,
      "date": "2015-08-27"
  },
  "2015-08-28": {
      "equity": 8001.9,
      "debt": 7.777,
      "gold": 26287.93453,
      "pb": 3.22,
      "pe": 22.17,
      "yield": 1.48,
      "date": "2015-08-28"
  },
  "2015-08-29": {
      "equity": 8001.9,
      "debt": 7.777,
      "gold": 26287.93453,
      "pb": 3.22,
      "pe": 22.17,
      "yield": 1.48,
      "date": "2015-08-29"
  },
  "2015-08-30": {
      "equity": 8001.9,
      "debt": 7.777,
      "gold": 26287.93453,
      "pb": 3.22,
      "pe": 22.17,
      "yield": 1.48,
      "date": "2015-08-30"
  },
  "2015-08-31": {
      "equity": 7971.3,
      "debt": 7.784,
      "gold": 26287.93453,
      "pb": 3.21,
      "pe": 22.08,
      "yield": 1.48,
      "date": "2015-08-31"
  },
  "2015-09-01": {
      "equity": 7785.8,
      "debt": 7.751,
      "gold": 26739.9037,
      "pb": 3.13,
      "pe": 21.57,
      "yield": 1.52,
      "date": "2015-09-01"
  },
  "2015-09-02": {
      "equity": 7717,
      "debt": 7.75,
      "gold": 26607.86963,
      "pb": 3.11,
      "pe": 21.38,
      "yield": 1.53,
      "date": "2015-09-02"
  },
  "2015-09-03": {
      "equity": 7823,
      "debt": 7.746,
      "gold": 26348.44706,
      "pb": 3.15,
      "pe": 21.68,
      "yield": 1.51,
      "date": "2015-09-03"
  },
  "2015-09-04": {
      "equity": 7655,
      "debt": 7.753,
      "gold": 26488.60121,
      "pb": 3.08,
      "pe": 21.21,
      "yield": 1.54,
      "date": "2015-09-04"
  },
  "2015-09-05": {
      "equity": 7655,
      "debt": 7.753,
      "gold": 26488.60121,
      "pb": 3.08,
      "pe": 21.21,
      "yield": 1.54,
      "date": "2015-09-05"
  },
  "2015-09-06": {
      "equity": 7655,
      "debt": 7.753,
      "gold": 26488.60121,
      "pb": 3.08,
      "pe": 21.21,
      "yield": 1.54,
      "date": "2015-09-06"
  },
  "2015-09-07": {
      "equity": 7558.8,
      "debt": 7.801,
      "gold": 26456.14561,
      "pb": 3.04,
      "pe": 20.95,
      "yield": 1.56,
      "date": "2015-09-07"
  },
  "2015-09-08": {
      "equity": 7688.2,
      "debt": 7.771,
      "gold": 26242.90375,
      "pb": 3.1,
      "pe": 21.31,
      "yield": 1.54,
      "date": "2015-09-08"
  },
  "2015-09-09": {
      "equity": 7818.6,
      "debt": 7.766,
      "gold": 26317.31071,
      "pb": 3.15,
      "pe": 21.67,
      "yield": 1.51,
      "date": "2015-09-09"
  },
  "2015-09-10": {
      "equity": 7788.1,
      "debt": 7.772,
      "gold": 25974.01012,
      "pb": 3.14,
      "pe": 21.59,
      "yield": 1.52,
      "date": "2015-09-10"
  },
  "2015-09-11": {
      "equity": 7789.3,
      "debt": 7.771,
      "gold": 25899.19399,
      "pb": 3.14,
      "pe": 21.59,
      "yield": 1.52,
      "date": "2015-09-11"
  },
  "2015-09-12": {
      "equity": 7789.3,
      "debt": 7.771,
      "gold": 25899.19399,
      "pb": 3.14,
      "pe": 21.59,
      "yield": 1.52,
      "date": "2015-09-12"
  },
  "2015-09-13": {
      "equity": 7789.3,
      "debt": 7.771,
      "gold": 25899.19399,
      "pb": 3.14,
      "pe": 21.59,
      "yield": 1.52,
      "date": "2015-09-13"
  },
  "2015-09-14": {
      "equity": 7872.2,
      "debt": 7.755,
      "gold": 25912.72862,
      "pb": 3.17,
      "pe": 21.82,
      "yield": 1.5,
      "date": "2015-09-14"
  },
  "2015-09-15": {
      "equity": 7829.1,
      "debt": 7.756,
      "gold": 25885.26076,
      "pb": 3.15,
      "pe": 21.7,
      "yield": 1.51,
      "date": "2015-09-15"
  },
  "2015-09-16": {
      "equity": 7899.1,
      "debt": 7.753,
      "gold": 25933.76956,
      "pb": 3.18,
      "pe": 21.89,
      "yield": 1.5,
      "date": "2015-09-16"
  },
  "2015-09-17": {
      "equity": 7899.1,
      "debt": 7.753,
      "gold": 26130.06931,
      "pb": 3.18,
      "pe": 21.89,
      "yield": 1.5,
      "date": "2015-09-17"
  },
  "2015-09-18": {
      "equity": 7981.9,
      "debt": 7.697,
      "gold": 26351.85806,
      "pb": 3.21,
      "pe": 22.12,
      "yield": 1.48,
      "date": "2015-09-18"
  },
  "2015-09-19": {
      "equity": 7981.9,
      "debt": 7.697,
      "gold": 26351.85806,
      "pb": 3.21,
      "pe": 22.12,
      "yield": 1.48,
      "date": "2015-09-19"
  },
  "2015-09-20": {
      "equity": 7981.9,
      "debt": 7.697,
      "gold": 26351.85806,
      "pb": 3.21,
      "pe": 22.12,
      "yield": 1.48,
      "date": "2015-09-20"
  },
  "2015-09-21": {
      "equity": 7977.1,
      "debt": 7.703,
      "gold": 26316.39711,
      "pb": 3.21,
      "pe": 22.11,
      "yield": 1.48,
      "date": "2015-09-21"
  },
  "2015-09-22": {
      "equity": 7812,
      "debt": 7.715,
      "gold": 26247.22835,
      "pb": 3.15,
      "pe": 21.65,
      "yield": 1.51,
      "date": "2015-09-22"
  },
  "2015-09-23": {
      "equity": 7845.9,
      "debt": 7.715,
      "gold": 26268.10349,
      "pb": 3.16,
      "pe": 21.75,
      "yield": 1.51,
      "date": "2015-09-23"
  },
  "2015-09-24": {
      "equity": 7868.5,
      "debt": 7.715,
      "gold": 26517.38479,
      "pb": 3.17,
      "pe": 21.81,
      "yield": 1.5,
      "date": "2015-09-24"
  },
  "2015-09-25": {
      "equity": 7868.5,
      "debt": 7.715,
      "gold": 26754.7858,
      "pb": 3.17,
      "pe": 21.81,
      "yield": 1.5,
      "date": "2015-09-25"
  },
  "2015-09-26": {
      "equity": 7868.5,
      "debt": 7.715,
      "gold": 26754.7858,
      "pb": 3.17,
      "pe": 21.81,
      "yield": 1.5,
      "date": "2015-09-26"
  },
  "2015-09-27": {
      "equity": 7868.5,
      "debt": 7.716,
      "gold": 26754.7858,
      "pb": 3.17,
      "pe": 21.81,
      "yield": 1.5,
      "date": "2015-09-27"
  },
  "2015-09-28": {
      "equity": 7795.7,
      "debt": 7.725,
      "gold": 26571.30461,
      "pb": 3.16,
      "pe": 21.78,
      "yield": 1.48,
      "date": "2015-09-28"
  },
  "2015-09-29": {
      "equity": 7843.3,
      "debt": 7.611,
      "gold": 26147.5511,
      "pb": 3.18,
      "pe": 21.91,
      "yield": 1.48,
      "date": "2015-09-29"
  },
  "2015-09-30": {
      "equity": 7948.9,
      "debt": 7.539,
      "gold": 25945.64984,
      "pb": 3.22,
      "pe": 22.21,
      "yield": 1.46,
      "date": "2015-09-30"
  },
  "2015-10-01": {
      "equity": 7950.9,
      "debt": 7.561,
      "gold": 25796.44438,
      "pb": 3.2,
      "pe": 22.21,
      "yield": 1.43,
      "date": "2015-10-01"
  },
  "2015-10-02": {
      "equity": 7950.9,
      "debt": 7.561,
      "gold": 25598.7513,
      "pb": 3.2,
      "pe": 22.21,
      "yield": 1.43,
      "date": "2015-10-02"
  },
  "2015-10-03": {
      "equity": 7950.9,
      "debt": 7.563,
      "gold": 25598.7513,
      "pb": 3.2,
      "pe": 22.21,
      "yield": 1.43,
      "date": "2015-10-03"
  },
  "2015-10-04": {
      "equity": 7950.9,
      "debt": 7.563,
      "gold": 25598.7513,
      "pb": 3.2,
      "pe": 22.21,
      "yield": 1.43,
      "date": "2015-10-04"
  },
  "2015-10-05": {
      "equity": 8119.3,
      "debt": 7.513,
      "gold": 26059.74003,
      "pb": 3.27,
      "pe": 22.68,
      "yield": 1.4,
      "date": "2015-10-05"
  },
  "2015-10-06": {
      "equity": 8152.9,
      "debt": 7.527,
      "gold": 26142.94432,
      "pb": 3.29,
      "pe": 22.78,
      "yield": 1.39,
      "date": "2015-10-06"
  },
  "2015-10-07": {
      "equity": 8177.4,
      "debt": 7.54,
      "gold": 26273.40517,
      "pb": 3.3,
      "pe": 22.84,
      "yield": 1.39,
      "date": "2015-10-07"
  },
  "2015-10-08": {
      "equity": 8129.3,
      "debt": 7.536,
      "gold": 26192.11274,
      "pb": 3.28,
      "pe": 22.71,
      "yield": 1.4,
      "date": "2015-10-08"
  },
  "2015-10-09": {
      "equity": 8189.7,
      "debt": 7.545,
      "gold": 26295.72656,
      "pb": 3.3,
      "pe": 22.88,
      "yield": 1.39,
      "date": "2015-10-09"
  },
  "2015-10-10": {
      "equity": 8189.7,
      "debt": 7.545,
      "gold": 26295.72656,
      "pb": 3.3,
      "pe": 22.88,
      "yield": 1.39,
      "date": "2015-10-10"
  },
  "2015-10-11": {
      "equity": 8189.7,
      "debt": 7.545,
      "gold": 26295.72656,
      "pb": 3.3,
      "pe": 22.88,
      "yield": 1.39,
      "date": "2015-10-11"
  },
  "2015-10-12": {
      "equity": 8143.6,
      "debt": 7.58,
      "gold": 26594.74065,
      "pb": 3.28,
      "pe": 22.73,
      "yield": 1.39,
      "date": "2015-10-12"
  },
  "2015-10-13": {
      "equity": 8131.7,
      "debt": 7.56,
      "gold": 26500.2381,
      "pb": 3.28,
      "pe": 22.25,
      "yield": 1.4,
      "date": "2015-10-13"
  },
  "2015-10-14": {
      "equity": 8107.9,
      "debt": 7.554,
      "gold": 26874.89021,
      "pb": 3.27,
      "pe": 22.16,
      "yield": 1.4,
      "date": "2015-10-14"
  },
  "2015-10-15": {
      "equity": 8179.5,
      "debt": 7.548,
      "gold": 27040.29701,
      "pb": 3.3,
      "pe": 22.35,
      "yield": 1.39,
      "date": "2015-10-15"
  },
  "2015-10-16": {
      "equity": 8238.1,
      "debt": 7.564,
      "gold": 26881.21131,
      "pb": 3.3,
      "pe": 22.51,
      "yield": 1.38,
      "date": "2015-10-16"
  },
  "2015-10-17": {
      "equity": 8238.1,
      "debt": 7.564,
      "gold": 26881.21131,
      "pb": 3.3,
      "pe": 22.51,
      "yield": 1.38,
      "date": "2015-10-17"
  },
  "2015-10-18": {
      "equity": 8238.1,
      "debt": 7.565,
      "gold": 26881.21131,
      "pb": 3.3,
      "pe": 22.51,
      "yield": 1.38,
      "date": "2015-10-18"
  },
  "2015-10-19": {
      "equity": 8275,
      "debt": 7.571,
      "gold": 26830.63899,
      "pb": 3.31,
      "pe": 22.61,
      "yield": 1.38,
      "date": "2015-10-19"
  },
  "2015-10-20": {
      "equity": 8261.6,
      "debt": 7.579,
      "gold": 26902.09351,
      "pb": 3.31,
      "pe": 22.51,
      "yield": 1.38,
      "date": "2015-10-20"
  },
  "2015-10-21": {
      "equity": 8251.7,
      "debt": 7.583,
      "gold": 27004.635,
      "pb": 3.3,
      "pe": 22.48,
      "yield": 1.38,
      "date": "2015-10-21"
  },
  "2015-10-22": {
      "equity": 8251.7,
      "debt": 7.583,
      "gold": 26799.23808,
      "pb": 3.3,
      "pe": 22.48,
      "yield": 1.38,
      "date": "2015-10-22"
  },
  "2015-10-23": {
      "equity": 8295.4,
      "debt": 7.585,
      "gold": 26873.39107,
      "pb": 3.32,
      "pe": 22.55,
      "yield": 1.38,
      "date": "2015-10-23"
  },
  "2015-10-24": {
      "equity": 8295.4,
      "debt": 7.585,
      "gold": 26873.39107,
      "pb": 3.32,
      "pe": 22.55,
      "yield": 1.38,
      "date": "2015-10-24"
  },
  "2015-10-25": {
      "equity": 8295.4,
      "debt": 7.585,
      "gold": 26873.39107,
      "pb": 3.32,
      "pe": 22.55,
      "yield": 1.38,
      "date": "2015-10-25"
  },
  "2015-10-26": {
      "equity": 8260.5,
      "debt": 7.611,
      "gold": 26713.45174,
      "pb": 3.3,
      "pe": 22.45,
      "yield": 1.38,
      "date": "2015-10-26"
  },
  "2015-10-27": {
      "equity": 8232.9,
      "debt": 7.604,
      "gold": 26746.39412,
      "pb": 3.3,
      "pe": 22.39,
      "yield": 1.39,
      "date": "2015-10-27"
  },
  "2015-10-28": {
      "equity": 8171.2,
      "debt": 7.591,
      "gold": 26808.8573,
      "pb": 3.27,
      "pe": 22.2,
      "yield": 1.4,
      "date": "2015-10-28"
  },
  "2015-10-29": {
      "equity": 8111.7,
      "debt": 7.629,
      "gold": 26692.01573,
      "pb": 3.25,
      "pe": 22.19,
      "yield": 1.41,
      "date": "2015-10-29"
  },
  "2015-10-30": {
      "equity": 8065.8,
      "debt": 7.64,
      "gold": 26482.11785,
      "pb": 3.23,
      "pe": 22.06,
      "yield": 1.41,
      "date": "2015-10-30"
  },
  "2015-10-31": {
      "equity": 8065.8,
      "debt": 7.64,
      "gold": 26482.11785,
      "pb": 3.23,
      "pe": 22.06,
      "yield": 1.41,
      "date": "2015-10-31"
  },
  "2015-11-01": {
      "equity": 8065.8,
      "debt": 7.64,
      "gold": 26482.11785,
      "pb": 3.23,
      "pe": 22.06,
      "yield": 1.41,
      "date": "2015-11-01"
  },
  "2015-11-02": {
      "equity": 8050.8,
      "debt": 7.629,
      "gold": 26265.1687,
      "pb": 3.22,
      "pe": 21.98,
      "yield": 1.42,
      "date": "2015-11-02"
  },
  "2015-11-03": {
      "equity": 8060.7,
      "debt": 7.646,
      "gold": 26138.13295,
      "pb": 3.23,
      "pe": 22.02,
      "yield": 1.41,
      "date": "2015-11-03"
  },
  "2015-11-04": {
      "equity": 8040.2,
      "debt": 7.652,
      "gold": 25857.21441,
      "pb": 3.22,
      "pe": 21.87,
      "yield": 1.42,
      "date": "2015-11-04"
  },
  "2015-11-05": {
      "equity": 7955.4,
      "debt": 7.679,
      "gold": 25726.80294,
      "pb": 3.19,
      "pe": 21.58,
      "yield": 1.43,
      "date": "2015-11-05"
  },
  "2015-11-06": {
      "equity": 7954.3,
      "debt": 7.685,
      "gold": 25826.58953,
      "pb": 3.19,
      "pe": 21.57,
      "yield": 1.43,
      "date": "2015-11-06"
  },
  "2015-11-07": {
      "equity": 7954.3,
      "debt": 7.685,
      "gold": 25826.58953,
      "pb": 3.19,
      "pe": 21.57,
      "yield": 1.43,
      "date": "2015-11-07"
  },
  "2015-11-08": {
      "equity": 7954.3,
      "debt": 7.685,
      "gold": 25826.58953,
      "pb": 3.19,
      "pe": 21.57,
      "yield": 1.43,
      "date": "2015-11-08"
  },
  "2015-11-09": {
      "equity": 7915.2,
      "debt": 7.725,
      "gold": 25693.72299,
      "pb": 3.18,
      "pe": 21.41,
      "yield": 1.44,
      "date": "2015-11-09"
  },
  "2015-11-10": {
      "equity": 7783.3,
      "debt": 7.682,
      "gold": 25521.40602,
      "pb": 3.13,
      "pe": 21.05,
      "yield": 1.46,
      "date": "2015-11-10"
  },
  "2015-11-11": {
      "equity": 7825,
      "debt": 7.682,
      "gold": 25428.64601,
      "pb": 3.14,
      "pe": 21.17,
      "yield": 1.45,
      "date": "2015-11-11"
  },
  "2015-11-12": {
      "equity": 7825,
      "debt": 7.682,
      "gold": 25405.28757,
      "pb": 3.14,
      "pe": 21.17,
      "yield": 1.45,
      "date": "2015-11-12"
  },
  "2015-11-13": {
      "equity": 7762.2,
      "debt": 7.646,
      "gold": 25263.47907,
      "pb": 3.12,
      "pe": 20.97,
      "yield": 1.47,
      "date": "2015-11-13"
  },
  "2015-11-14": {
      "equity": 7762.2,
      "debt": 7.646,
      "gold": 25263.47907,
      "pb": 3.12,
      "pe": 20.97,
      "yield": 1.47,
      "date": "2015-11-14"
  },
  "2015-11-15": {
      "equity": 7762.2,
      "debt": 7.646,
      "gold": 25263.47907,
      "pb": 3.12,
      "pe": 20.97,
      "yield": 1.47,
      "date": "2015-11-15"
  },
  "2015-11-16": {
      "equity": 7806.6,
      "debt": 7.652,
      "gold": 25469.83192,
      "pb": 3.14,
      "pe": 21.11,
      "yield": 1.46,
      "date": "2015-11-16"
  },
  "2015-11-17": {
      "equity": 7837.5,
      "debt": 7.667,
      "gold": 25154.83518,
      "pb": 3.15,
      "pe": 21.19,
      "yield": 1.45,
      "date": "2015-11-17"
  },
  "2015-11-18": {
      "equity": 7731.8,
      "debt": 7.679,
      "gold": 24978.09485,
      "pb": 3.11,
      "pe": 20.9,
      "yield": 1.47,
      "date": "2015-11-18"
  },
  "2015-11-19": {
      "equity": 7842.7,
      "debt": 7.672,
      "gold": 25009.91552,
      "pb": 3.15,
      "pe": 21.2,
      "yield": 1.45,
      "date": "2015-11-19"
  },
  "2015-11-20": {
      "equity": 7856.5,
      "debt": 7.697,
      "gold": 25304.15351,
      "pb": 3.16,
      "pe": 21.24,
      "yield": 1.45,
      "date": "2015-11-20"
  },
  "2015-11-21": {
      "equity": 7856.5,
      "debt": 7.697,
      "gold": 25304.15351,
      "pb": 3.16,
      "pe": 21.24,
      "yield": 1.45,
      "date": "2015-11-21"
  },
  "2015-11-22": {
      "equity": 7856.5,
      "debt": 7.697,
      "gold": 25304.15351,
      "pb": 3.16,
      "pe": 21.24,
      "yield": 1.45,
      "date": "2015-11-22"
  },
  "2015-11-23": {
      "equity": 7849.2,
      "debt": 7.715,
      "gold": 25003.07236,
      "pb": 3.15,
      "pe": 21.22,
      "yield": 1.45,
      "date": "2015-11-23"
  },
  "2015-11-24": {
      "equity": 7831.6,
      "debt": 7.703,
      "gold": 25121.88927,
      "pb": 3.15,
      "pe": 21.17,
      "yield": 1.45,
      "date": "2015-11-24"
  },
  "2015-11-25": {
      "equity": 7831.6,
      "debt": 7.703,
      "gold": 25075.28528,
      "pb": 3.15,
      "pe": 21.17,
      "yield": 1.45,
      "date": "2015-11-25"
  },
  "2015-11-26": {
      "equity": 7883.8,
      "debt": 7.72,
      "gold": 25162.82121,
      "pb": 3.17,
      "pe": 21.31,
      "yield": 1.44,
      "date": "2015-11-26"
  },
  "2015-11-27": {
      "equity": 7942.7,
      "debt": 7.766,
      "gold": 25111.61043,
      "pb": 3.19,
      "pe": 21.47,
      "yield": 1.43,
      "date": "2015-11-27"
  },
  "2015-11-28": {
      "equity": 7942.7,
      "debt": 7.766,
      "gold": 25111.61043,
      "pb": 3.19,
      "pe": 21.47,
      "yield": 1.43,
      "date": "2015-11-28"
  },
  "2015-11-29": {
      "equity": 7942.7,
      "debt": 7.766,
      "gold": 25111.61043,
      "pb": 3.19,
      "pe": 21.47,
      "yield": 1.43,
      "date": "2015-11-29"
  },
  "2015-11-30": {
      "equity": 7935.2,
      "debt": 7.786,
      "gold": 24788.47599,
      "pb": 3.19,
      "pe": 21.45,
      "yield": 1.43,
      "date": "2015-11-30"
  },
  "2015-12-01": {
      "equity": 7954.9,
      "debt": 7.72,
      "gold": 25072.82668,
      "pb": 3.19,
      "pe": 21.51,
      "yield": 1.43,
      "date": "2015-12-01"
  },
  "2015-12-02": {
      "equity": 7931.3,
      "debt": 7.742,
      "gold": 25049.10845,
      "pb": 3.19,
      "pe": 21.44,
      "yield": 1.44,
      "date": "2015-12-02"
  },
  "2015-12-03": {
      "equity": 7864.1,
      "debt": 7.712,
      "gold": 24767.39625,
      "pb": 3.16,
      "pe": 21.26,
      "yield": 1.45,
      "date": "2015-12-03"
  },
  "2015-12-04": {
      "equity": 7781.9,
      "debt": 7.757,
      "gold": 25018.43771,
      "pb": 3.13,
      "pe": 21.04,
      "yield": 1.46,
      "date": "2015-12-04"
  },
  "2015-12-05": {
      "equity": 7781.9,
      "debt": 7.757,
      "gold": 25018.43771,
      "pb": 3.13,
      "pe": 21.04,
      "yield": 1.46,
      "date": "2015-12-05"
  },
  "2015-12-06": {
      "equity": 7781.9,
      "debt": 7.757,
      "gold": 25018.43771,
      "pb": 3.13,
      "pe": 21.04,
      "yield": 1.46,
      "date": "2015-12-06"
  },
  "2015-12-07": {
      "equity": 7765.4,
      "debt": 7.754,
      "gold": 25500.23104,
      "pb": 3.12,
      "pe": 20.99,
      "yield": 1.47,
      "date": "2015-12-07"
  },
  "2015-12-08": {
      "equity": 7701.7,
      "debt": 7.795,
      "gold": 25265.5814,
      "pb": 3.09,
      "pe": 20.82,
      "yield": 1.48,
      "date": "2015-12-08"
  },
  "2015-12-09": {
      "equity": 7612.5,
      "debt": 7.777,
      "gold": 25434.37098,
      "pb": 3.06,
      "pe": 20.58,
      "yield": 1.5,
      "date": "2015-12-09"
  },
  "2015-12-10": {
      "equity": 7683.3,
      "debt": 7.78,
      "gold": 25229.61604,
      "pb": 3.09,
      "pe": 20.77,
      "yield": 1.48,
      "date": "2015-12-10"
  },
  "2015-12-11": {
      "equity": 7610.4,
      "debt": 7.773,
      "gold": 25295.90998,
      "pb": 3.05,
      "pe": 20.57,
      "yield": 1.52,
      "date": "2015-12-11"
  },
  "2015-12-12": {
      "equity": 7610.4,
      "debt": 7.773,
      "gold": 25295.90998,
      "pb": 3.05,
      "pe": 20.57,
      "yield": 1.52,
      "date": "2015-12-12"
  },
  "2015-12-13": {
      "equity": 7610.4,
      "debt": 7.773,
      "gold": 25295.90998,
      "pb": 3.05,
      "pe": 20.57,
      "yield": 1.52,
      "date": "2015-12-13"
  },
  "2015-12-14": {
      "equity": 7650,
      "debt": 7.816,
      "gold": 25257.83524,
      "pb": 3.07,
      "pe": 20.69,
      "yield": 1.51,
      "date": "2015-12-14"
  },
  "2015-12-15": {
      "equity": 7700.9,
      "debt": 7.787,
      "gold": 25214.5858,
      "pb": 3.09,
      "pe": 20.82,
      "yield": 1.5,
      "date": "2015-12-15"
  },
  "2015-12-16": {
      "equity": 7750.9,
      "debt": 7.734,
      "gold": 25023.79936,
      "pb": 3.11,
      "pe": 20.96,
      "yield": 1.49,
      "date": "2015-12-16"
  },
  "2015-12-17": {
      "equity": 7844.3,
      "debt": 7.708,
      "gold": 24965.99235,
      "pb": 3.15,
      "pe": 21.21,
      "yield": 1.47,
      "date": "2015-12-17"
  },
  "2015-12-18": {
      "equity": 7761.9,
      "debt": 7.725,
      "gold": 24666.37507,
      "pb": 3.11,
      "pe": 20.99,
      "yield": 1.49,
      "date": "2015-12-18"
  },
  "2015-12-19": {
      "equity": 7761.9,
      "debt": 7.725,
      "gold": 24666.37507,
      "pb": 3.11,
      "pe": 20.99,
      "yield": 1.49,
      "date": "2015-12-19"
  },
  "2015-12-20": {
      "equity": 7761.9,
      "debt": 7.725,
      "gold": 24666.37507,
      "pb": 3.11,
      "pe": 20.99,
      "yield": 1.49,
      "date": "2015-12-20"
  },
  "2015-12-21": {
      "equity": 7834.4,
      "debt": 7.773,
      "gold": 25019.33015,
      "pb": 3.14,
      "pe": 21.19,
      "yield": 1.48,
      "date": "2015-12-21"
  },
  "2015-12-22": {
      "equity": 7786.1,
      "debt": 7.756,
      "gold": 25154.79638,
      "pb": 3.12,
      "pe": 21.06,
      "yield": 1.49,
      "date": "2015-12-22"
  },
  "2015-12-23": {
      "equity": 7865.9,
      "debt": 7.749,
      "gold": 24958.83173,
      "pb": 3.15,
      "pe": 21.27,
      "yield": 1.47,
      "date": "2015-12-23"
  },
  "2015-12-24": {
      "equity": 7861,
      "debt": 7.749,
      "gold": 24979.37883,
      "pb": 3.15,
      "pe": 21.26,
      "yield": 1.47,
      "date": "2015-12-24"
  },
  "2015-12-25": {
      "equity": 7861,
      "debt": 7.749,
      "gold": 24979.37883,
      "pb": 3.15,
      "pe": 21.26,
      "yield": 1.47,
      "date": "2015-12-25"
  },
  "2015-12-26": {
      "equity": 7861,
      "debt": 7.749,
      "gold": 24979.37883,
      "pb": 3.15,
      "pe": 21.26,
      "yield": 1.47,
      "date": "2015-12-26"
  },
  "2015-12-27": {
      "equity": 7861,
      "debt": 7.749,
      "gold": 24979.37883,
      "pb": 3.15,
      "pe": 21.26,
      "yield": 1.47,
      "date": "2015-12-27"
  },
  "2015-12-28": {
      "equity": 7925.1,
      "debt": 7.756,
      "gold": 24979.37883,
      "pb": 3.18,
      "pe": 21.43,
      "yield": 1.46,
      "date": "2015-12-28"
  },
  "2015-12-29": {
      "equity": 7928.9,
      "debt": 7.755,
      "gold": 24951.69227,
      "pb": 3.18,
      "pe": 21.44,
      "yield": 1.46,
      "date": "2015-12-29"
  },
  "2015-12-30": {
      "equity": 7896.2,
      "debt": 7.749,
      "gold": 25028.95642,
      "pb": 3.17,
      "pe": 21.35,
      "yield": 1.46,
      "date": "2015-12-30"
  },
  "2015-12-31": {
      "equity": 7946.3,
      "debt": 7.758,
      "gold": 24817.55939,
      "pb": 3.19,
      "pe": 21.49,
      "yield": 1.46,
      "date": "2015-12-31"
  },
  "2016-01-01": {
      "equity": 7963.2,
      "debt": 7.727,
      "gold": 24817.55939,
      "pb": 3.19,
      "pe": 21.53,
      "yield": 1.45,
      "date": "2016-01-01"
  },
  "2016-01-02": {
      "equity": 7963.2,
      "debt": 7.727,
      "gold": 24817.55939,
      "pb": 3.19,
      "pe": 21.53,
      "yield": 1.45,
      "date": "2016-01-02"
  },
  "2016-01-03": {
      "equity": 7963.2,
      "debt": 7.727,
      "gold": 24817.55939,
      "pb": 3.19,
      "pe": 21.53,
      "yield": 1.45,
      "date": "2016-01-03"
  },
  "2016-01-04": {
      "equity": 7791.3,
      "debt": 7.723,
      "gold": 25191.56599,
      "pb": 3.13,
      "pe": 21.07,
      "yield": 1.48,
      "date": "2016-01-04"
  },
  "2016-01-05": {
      "equity": 7784.6,
      "debt": 7.739,
      "gold": 25271.35223,
      "pb": 3.12,
      "pe": 21.05,
      "yield": 1.49,
      "date": "2016-01-05"
  },
  "2016-01-06": {
      "equity": 7741,
      "debt": 7.736,
      "gold": 25560.19683,
      "pb": 3.1,
      "pe": 20.93,
      "yield": 1.49,
      "date": "2016-01-06"
  },
  "2016-01-07": {
      "equity": 7568.3,
      "debt": 7.736,
      "gold": 25785.44948,
      "pb": 3.04,
      "pe": 20.47,
      "yield": 1.53,
      "date": "2016-01-07"
  },
  "2016-01-08": {
      "equity": 7601.3,
      "debt": 7.741,
      "gold": 25904.14646,
      "pb": 3.05,
      "pe": 20.56,
      "yield": 1.52,
      "date": "2016-01-08"
  },
  "2016-01-09": {
      "equity": 7601.3,
      "debt": 7.741,
      "gold": 25904.14646,
      "pb": 3.05,
      "pe": 20.56,
      "yield": 1.52,
      "date": "2016-01-09"
  },
  "2016-01-10": {
      "equity": 7601.3,
      "debt": 7.741,
      "gold": 25904.14646,
      "pb": 3.05,
      "pe": 20.56,
      "yield": 1.52,
      "date": "2016-01-10"
  },
  "2016-01-11": {
      "equity": 7563.8,
      "debt": 7.754,
      "gold": 26030.30741,
      "pb": 3.03,
      "pe": 20.46,
      "yield": 1.53,
      "date": "2016-01-11"
  },
  "2016-01-12": {
      "equity": 7510.3,
      "debt": 7.759,
      "gold": 25820.727,
      "pb": 3.01,
      "pe": 20.31,
      "yield": 1.54,
      "date": "2016-01-12"
  },
  "2016-01-13": {
      "equity": 7562.4,
      "debt": 7.765,
      "gold": 25486.09323,
      "pb": 3.03,
      "pe": 20.45,
      "yield": 1.53,
      "date": "2016-01-13"
  },
  "2016-01-14": {
      "equity": 7536.8,
      "debt": 7.786,
      "gold": 25922.10092,
      "pb": 3.02,
      "pe": 20.38,
      "yield": 1.53,
      "date": "2016-01-14"
  },
  "2016-01-15": {
      "equity": 7437.8,
      "debt": 7.806,
      "gold": 25890.30494,
      "pb": 2.98,
      "pe": 20.12,
      "yield": 1.55,
      "date": "2016-01-15"
  },
  "2016-01-16": {
      "equity": 7437.8,
      "debt": 7.806,
      "gold": 25890.30494,
      "pb": 2.98,
      "pe": 20.12,
      "yield": 1.55,
      "date": "2016-01-16"
  },
  "2016-01-17": {
      "equity": 7437.8,
      "debt": 7.806,
      "gold": 25890.30494,
      "pb": 2.98,
      "pe": 20.12,
      "yield": 1.55,
      "date": "2016-01-17"
  },
  "2016-01-18": {
      "equity": 7351,
      "debt": 7.811,
      "gold": 26026.36731,
      "pb": 2.95,
      "pe": 19.86,
      "yield": 1.57,
      "date": "2016-01-18"
  },
  "2016-01-19": {
      "equity": 7435.1,
      "debt": 7.78,
      "gold": 25973.43163,
      "pb": 2.98,
      "pe": 20.08,
      "yield": 1.56,
      "date": "2016-01-19"
  },
  "2016-01-20": {
      "equity": 7309.3,
      "debt": 7.754,
      "gold": 26270.92541,
      "pb": 2.93,
      "pe": 19.74,
      "yield": 1.58,
      "date": "2016-01-20"
  },
  "2016-01-21": {
      "equity": 7276.8,
      "debt": 7.748,
      "gold": 26251.1614,
      "pb": 2.92,
      "pe": 19.65,
      "yield": 1.59,
      "date": "2016-01-21"
  },
  "2016-01-22": {
      "equity": 7422.4,
      "debt": 7.775,
      "gold": 26168.01002,
      "pb": 2.98,
      "pe": 20.01,
      "yield": 1.56,
      "date": "2016-01-22"
  },
  "2016-01-23": {
      "equity": 7422.4,
      "debt": 7.775,
      "gold": 26168.01002,
      "pb": 2.98,
      "pe": 20.01,
      "yield": 1.56,
      "date": "2016-01-23"
  },
  "2016-01-24": {
      "equity": 7422.4,
      "debt": 7.775,
      "gold": 26168.01002,
      "pb": 2.98,
      "pe": 20.01,
      "yield": 1.56,
      "date": "2016-01-24"
  },
  "2016-01-25": {
      "equity": 7436.1,
      "debt": 7.805,
      "gold": 26366.3592,
      "pb": 2.98,
      "pe": 20.04,
      "yield": 1.56,
      "date": "2016-01-25"
  },
  "2016-01-26": {
      "equity": 7436.1,
      "debt": 7.805,
      "gold": 26630.08166,
      "pb": 2.98,
      "pe": 20.04,
      "yield": 1.56,
      "date": "2016-01-26"
  },
  "2016-01-27": {
      "equity": 7437.7,
      "debt": 7.788,
      "gold": 26810.41288,
      "pb": 2.98,
      "pe": 20.05,
      "yield": 1.55,
      "date": "2016-01-27"
  },
  "2016-01-28": {
      "equity": 7424.6,
      "debt": 7.805,
      "gold": 26858.56541,
      "pb": 2.98,
      "pe": 19.85,
      "yield": 1.56,
      "date": "2016-01-28"
  },
  "2016-01-29": {
      "equity": 7563.5,
      "debt": 7.779,
      "gold": 26645.45406,
      "pb": 3.03,
      "pe": 20.22,
      "yield": 1.53,
      "date": "2016-01-29"
  },
  "2016-01-30": {
      "equity": 7563.5,
      "debt": 7.779,
      "gold": 26645.45406,
      "pb": 3.03,
      "pe": 20.22,
      "yield": 1.53,
      "date": "2016-01-30"
  },
  "2016-01-31": {
      "equity": 7563.5,
      "debt": 7.779,
      "gold": 26645.45406,
      "pb": 3.03,
      "pe": 20.22,
      "yield": 1.53,
      "date": "2016-01-31"
  },
  "2016-02-01": {
      "equity": 7555.9,
      "debt": 7.792,
      "gold": 26870.45274,
      "pb": 3.03,
      "pe": 20.22,
      "yield": 1.53,
      "date": "2016-02-01"
  },
  "2016-02-02": {
      "equity": 7455.5,
      "debt": 7.849,
      "gold": 26922.68294,
      "pb": 2.99,
      "pe": 19.9,
      "yield": 1.55,
      "date": "2016-02-02"
  },
  "2016-02-03": {
      "equity": 7361.8,
      "debt": 7.849,
      "gold": 27061.0875,
      "pb": 2.95,
      "pe": 19.65,
      "yield": 1.57,
      "date": "2016-02-03"
  },
  "2016-02-04": {
      "equity": 7404,
      "debt": 7.835,
      "gold": 27294.85529,
      "pb": 2.97,
      "pe": 19.76,
      "yield": 1.56,
      "date": "2016-02-04"
  },
  "2016-02-05": {
      "equity": 7489.1,
      "debt": 7.822,
      "gold": 27714.34064,
      "pb": 3,
      "pe": 19.99,
      "yield": 1.54,
      "date": "2016-02-05"
  },
  "2016-02-06": {
      "equity": 7489.1,
      "debt": 7.822,
      "gold": 27714.34064,
      "pb": 3,
      "pe": 19.99,
      "yield": 1.54,
      "date": "2016-02-06"
  },
  "2016-02-07": {
      "equity": 7489.1,
      "debt": 7.822,
      "gold": 27714.34064,
      "pb": 3,
      "pe": 19.99,
      "yield": 1.54,
      "date": "2016-02-07"
  },
  "2016-02-08": {
      "equity": 7387.2,
      "debt": 7.725,
      "gold": 28145.49816,
      "pb": 2.96,
      "pe": 19.69,
      "yield": 1.57,
      "date": "2016-02-08"
  },
  "2016-02-09": {
      "equity": 7298.2,
      "debt": 7.738,
      "gold": 28487.13381,
      "pb": 2.93,
      "pe": 19.46,
      "yield": 1.58,
      "date": "2016-02-09"
  },
  "2016-02-10": {
      "equity": 7215.7,
      "debt": 7.722,
      "gold": 28330.42911,
      "pb": 2.89,
      "pe": 19.27,
      "yield": 1.6,
      "date": "2016-02-10"
  },
  "2016-02-11": {
      "equity": 6976.3,
      "debt": 7.716,
      "gold": 29547.42412,
      "pb": 2.8,
      "pe": 18.65,
      "yield": 1.66,
      "date": "2016-02-11"
  },
  "2016-02-12": {
      "equity": 6980.9,
      "debt": 7.723,
      "gold": 29801.4286,
      "pb": 2.8,
      "pe": 18.67,
      "yield": 1.66,
      "date": "2016-02-12"
  },
  "2016-02-13": {
      "equity": 6980.9,
      "debt": 7.723,
      "gold": 29801.4286,
      "pb": 2.8,
      "pe": 18.67,
      "yield": 1.66,
      "date": "2016-02-13"
  },
  "2016-02-14": {
      "equity": 6980.9,
      "debt": 7.723,
      "gold": 29801.4286,
      "pb": 2.8,
      "pe": 18.67,
      "yield": 1.66,
      "date": "2016-02-14"
  },
  "2016-02-15": {
      "equity": 7162.9,
      "debt": 7.754,
      "gold": 29048.49468,
      "pb": 2.87,
      "pe": 19.32,
      "yield": 1.61,
      "date": "2016-02-15"
  },
  "2016-02-16": {
      "equity": 7048.2,
      "debt": 7.78,
      "gold": 29264.99233,
      "pb": 2.83,
      "pe": 19.01,
      "yield": 1.64,
      "date": "2016-02-16"
  },
  "2016-02-17": {
      "equity": 7108.4,
      "debt": 7.786,
      "gold": 28984.20431,
      "pb": 2.85,
      "pe": 19.18,
      "yield": 1.63,
      "date": "2016-02-17"
  },
  "2016-02-18": {
      "equity": 7191.7,
      "debt": 7.738,
      "gold": 29117.51177,
      "pb": 2.89,
      "pe": 19.44,
      "yield": 1.61,
      "date": "2016-02-18"
  },
  "2016-02-19": {
      "equity": 7210.7,
      "debt": 7.738,
      "gold": 29527.55781,
      "pb": 2.89,
      "pe": 19.5,
      "yield": 1.6,
      "date": "2016-02-19"
  },
  "2016-02-20": {
      "equity": 7210.7,
      "debt": 7.738,
      "gold": 29527.55781,
      "pb": 2.89,
      "pe": 19.5,
      "yield": 1.6,
      "date": "2016-02-20"
  },
  "2016-02-21": {
      "equity": 7210.7,
      "debt": 7.738,
      "gold": 29527.55781,
      "pb": 2.89,
      "pe": 19.5,
      "yield": 1.6,
      "date": "2016-02-21"
  },
  "2016-02-22": {
      "equity": 7234.5,
      "debt": 7.773,
      "gold": 29092.96813,
      "pb": 2.9,
      "pe": 19.56,
      "yield": 1.6,
      "date": "2016-02-22"
  },
  "2016-02-23": {
      "equity": 7109.5,
      "debt": 7.822,
      "gold": 29472.51627,
      "pb": 2.85,
      "pe": 19.23,
      "yield": 1.63,
      "date": "2016-02-23"
  },
  "2016-02-24": {
      "equity": 7018.7,
      "debt": 7.832,
      "gold": 29784.68051,
      "pb": 2.82,
      "pe": 18.98,
      "yield": 1.65,
      "date": "2016-02-24"
  },
  "2016-02-25": {
      "equity": 6970.6,
      "debt": 7.861,
      "gold": 30035.78546,
      "pb": 2.8,
      "pe": 18.85,
      "yield": 1.66,
      "date": "2016-02-25"
  },
  "2016-02-26": {
      "equity": 7029.7,
      "debt": 7.779,
      "gold": 29902.10409,
      "pb": 2.82,
      "pe": 19.01,
      "yield": 1.65,
      "date": "2016-02-26"
  },
  "2016-02-27": {
      "equity": 7029.7,
      "debt": 7.779,
      "gold": 29902.10409,
      "pb": 2.82,
      "pe": 19.01,
      "yield": 1.65,
      "date": "2016-02-27"
  },
  "2016-02-28": {
      "equity": 7029.7,
      "debt": 7.779,
      "gold": 29902.10409,
      "pb": 2.82,
      "pe": 19.01,
      "yield": 1.65,
      "date": "2016-02-28"
  },
  "2016-02-29": {
      "equity": 6987,
      "debt": 7.623,
      "gold": 29698.13577,
      "pb": 2.81,
      "pe": 18.9,
      "yield": 1.66,
      "date": "2016-02-29"
  },
  "2016-03-01": {
      "equity": 7222.3,
      "debt": 7.604,
      "gold": 29579.35413,
      "pb": 2.9,
      "pe": 19.53,
      "yield": 1.6,
      "date": "2016-03-01"
  },
  "2016-03-02": {
      "equity": 7368.8,
      "debt": 7.623,
      "gold": 29204.83959,
      "pb": 2.96,
      "pe": 19.93,
      "yield": 1.57,
      "date": "2016-03-02"
  },
  "2016-03-03": {
      "equity": 7475.6,
      "debt": 7.658,
      "gold": 29432.76248,
      "pb": 3,
      "pe": 20.22,
      "yield": 1.55,
      "date": "2016-03-03"
  },
  "2016-03-04": {
      "equity": 7485.3,
      "debt": 7.633,
      "gold": 30007.70384,
      "pb": 3.01,
      "pe": 20.24,
      "yield": 1.55,
      "date": "2016-03-04"
  },
  "2016-03-05": {
      "equity": 7485.3,
      "debt": 7.633,
      "gold": 30007.70384,
      "pb": 3.01,
      "pe": 20.24,
      "yield": 1.55,
      "date": "2016-03-05"
  },
  "2016-03-06": {
      "equity": 7485.3,
      "debt": 7.633,
      "gold": 30007.70384,
      "pb": 3.01,
      "pe": 20.24,
      "yield": 1.55,
      "date": "2016-03-06"
  },
  "2016-03-07": {
      "equity": 7485.3,
      "debt": 7.633,
      "gold": 29915.66342,
      "pb": 3.01,
      "pe": 20.24,
      "yield": 1.55,
      "date": "2016-03-07"
  },
  "2016-03-08": {
      "equity": 7485.3,
      "debt": 7.642,
      "gold": 30243.44345,
      "pb": 3,
      "pe": 20.2,
      "yield": 1.55,
      "date": "2016-03-08"
  },
  "2016-03-09": {
      "equity": 7531.8,
      "debt": 7.654,
      "gold": 29778.22184,
      "pb": 3.02,
      "pe": 20.33,
      "yield": 1.54,
      "date": "2016-03-09"
  },
  "2016-03-10": {
      "equity": 7486.1,
      "debt": 7.635,
      "gold": 29605.00185,
      "pb": 3,
      "pe": 20.2,
      "yield": 1.55,
      "date": "2016-03-10"
  },
  "2016-03-11": {
      "equity": 7510.2,
      "debt": 7.627,
      "gold": 29807.5698,
      "pb": 3.01,
      "pe": 20.27,
      "yield": 1.54,
      "date": "2016-03-11"
  },
  "2016-03-12": {
      "equity": 7510.2,
      "debt": 7.627,
      "gold": 29807.5698,
      "pb": 3.01,
      "pe": 20.27,
      "yield": 1.54,
      "date": "2016-03-12"
  },
  "2016-03-13": {
      "equity": 7510.2,
      "debt": 7.627,
      "gold": 29807.5698,
      "pb": 3.01,
      "pe": 20.27,
      "yield": 1.54,
      "date": "2016-03-13"
  },
  "2016-03-14": {
      "equity": 7538.7,
      "debt": 7.599,
      "gold": 29753.90042,
      "pb": 3.02,
      "pe": 20.35,
      "yield": 1.54,
      "date": "2016-03-14"
  },
  "2016-03-15": {
      "equity": 7460.6,
      "debt": 7.58,
      "gold": 29313.3318,
      "pb": 2.99,
      "pe": 20.14,
      "yield": 1.55,
      "date": "2016-03-15"
  },
  "2016-03-16": {
      "equity": 7498.7,
      "debt": 7.571,
      "gold": 29288.27316,
      "pb": 3,
      "pe": 20.24,
      "yield": 1.55,
      "date": "2016-03-16"
  },
  "2016-03-17": {
      "equity": 7512.5,
      "debt": 7.512,
      "gold": 29759.54426,
      "pb": 3.01,
      "pe": 20.28,
      "yield": 1.54,
      "date": "2016-03-17"
  },
  "2016-03-18": {
      "equity": 7604.3,
      "debt": 7.52,
      "gold": 29382.81098,
      "pb": 3.05,
      "pe": 20.52,
      "yield": 1.52,
      "date": "2016-03-18"
  },
  "2016-03-19": {
      "equity": 7604.3,
      "debt": 7.52,
      "gold": 29382.81098,
      "pb": 3.05,
      "pe": 20.52,
      "yield": 1.52,
      "date": "2016-03-19"
  },
  "2016-03-20": {
      "equity": 7604.3,
      "debt": 7.52,
      "gold": 29382.81098,
      "pb": 3.05,
      "pe": 20.52,
      "yield": 1.52,
      "date": "2016-03-20"
  },
  "2016-03-21": {
      "equity": 7704.2,
      "debt": 7.504,
      "gold": 29214.49408,
      "pb": 3.09,
      "pe": 20.79,
      "yield": 1.5,
      "date": "2016-03-21"
  },
  "2016-03-22": {
      "equity": 7714.9,
      "debt": 7.504,
      "gold": 29453.14379,
      "pb": 3.09,
      "pe": 20.82,
      "yield": 1.5,
      "date": "2016-03-22"
  },
  "2016-03-23": {
      "equity": 7716.5,
      "debt": 7.507,
      "gold": 29058.39609,
      "pb": 3.09,
      "pe": 20.83,
      "yield": 1.5,
      "date": "2016-03-23"
  },
  "2016-03-24": {
      "equity": 7716.5,
      "debt": 7.507,
      "gold": 28686.97155,
      "pb": 3.09,
      "pe": 20.83,
      "yield": 1.5,
      "date": "2016-03-24"
  },
  "2016-03-25": {
      "equity": 7716.5,
      "debt": 7.507,
      "gold": 28686.97155,
      "pb": 3.09,
      "pe": 20.83,
      "yield": 1.5,
      "date": "2016-03-25"
  },
  "2016-03-26": {
      "equity": 7716.5,
      "debt": 7.507,
      "gold": 28686.97155,
      "pb": 3.09,
      "pe": 20.83,
      "yield": 1.5,
      "date": "2016-03-26"
  },
  "2016-03-27": {
      "equity": 7716.5,
      "debt": 7.507,
      "gold": 28686.97155,
      "pb": 3.09,
      "pe": 20.83,
      "yield": 1.5,
      "date": "2016-03-27"
  },
  "2016-03-28": {
      "equity": 7615.1,
      "debt": 7.498,
      "gold": 28686.97155,
      "pb": 3.05,
      "pe": 20.55,
      "yield": 1.52,
      "date": "2016-03-28"
  },
  "2016-03-29": {
      "equity": 7597,
      "debt": 7.514,
      "gold": 28472.42456,
      "pb": 3.04,
      "pe": 20.5,
      "yield": 1.52,
      "date": "2016-03-29"
  },
  "2016-03-30": {
      "equity": 7735.2,
      "debt": 7.496,
      "gold": 28927.13452,
      "pb": 3.1,
      "pe": 20.88,
      "yield": 1.49,
      "date": "2016-03-30"
  },
  "2016-03-31": {
      "equity": 7738.4,
      "debt": 7.459,
      "gold": 28783.33304,
      "pb": 3.1,
      "pe": 20.89,
      "yield": 1.49,
      "date": "2016-03-31"
  },
  "2016-04-01": {
      "equity": 7713,
      "debt": 7.459,
      "gold": 28777.71389,
      "pb": 3.26,
      "pe": 21.19,
      "yield": 1.45,
      "date": "2016-04-01"
  },
  "2016-04-02": {
      "equity": 7713,
      "debt": 7.459,
      "gold": 28777.71389,
      "pb": 3.26,
      "pe": 21.19,
      "yield": 1.45,
      "date": "2016-04-02"
  },
  "2016-04-03": {
      "equity": 7713,
      "debt": 7.459,
      "gold": 28777.71389,
      "pb": 3.26,
      "pe": 21.19,
      "yield": 1.45,
      "date": "2016-04-03"
  },
  "2016-04-04": {
      "equity": 7758.8,
      "debt": 7.408,
      "gold": 28310.85557,
      "pb": 3.28,
      "pe": 21.31,
      "yield": 1.44,
      "date": "2016-04-04"
  },
  "2016-04-05": {
      "equity": 7603.2,
      "debt": 7.462,
      "gold": 28877.55692,
      "pb": 3.21,
      "pe": 20.88,
      "yield": 1.47,
      "date": "2016-04-05"
  },
  "2016-04-06": {
      "equity": 7614.3,
      "debt": 7.46,
      "gold": 28747.9109,
      "pb": 3.22,
      "pe": 20.91,
      "yield": 1.47,
      "date": "2016-04-06"
  },
  "2016-04-07": {
      "equity": 7546.4,
      "debt": 7.447,
      "gold": 29127.55428,
      "pb": 3.19,
      "pe": 20.73,
      "yield": 1.48,
      "date": "2016-04-07"
  },
  "2016-04-08": {
      "equity": 7555.2,
      "debt": 7.447,
      "gold": 29104.60502,
      "pb": 3.19,
      "pe": 20.75,
      "yield": 1.48,
      "date": "2016-04-08"
  },
  "2016-04-09": {
      "equity": 7555.2,
      "debt": 7.447,
      "gold": 29104.60502,
      "pb": 3.19,
      "pe": 20.75,
      "yield": 1.48,
      "date": "2016-04-09"
  },
  "2016-04-10": {
      "equity": 7555.2,
      "debt": 7.447,
      "gold": 29104.60502,
      "pb": 3.19,
      "pe": 20.75,
      "yield": 1.48,
      "date": "2016-04-10"
  },
  "2016-04-11": {
      "equity": 7671.4,
      "debt": 7.415,
      "gold": 29179.69629,
      "pb": 3.24,
      "pe": 21.07,
      "yield": 1.45,
      "date": "2016-04-11"
  },
  "2016-04-12": {
      "equity": 7708.9,
      "debt": 7.417,
      "gold": 29460.33616,
      "pb": 3.26,
      "pe": 21.17,
      "yield": 1.45,
      "date": "2016-04-12"
  },
  "2016-04-13": {
      "equity": 7850.4,
      "debt": 7.435,
      "gold": 29234.64259,
      "pb": 3.32,
      "pe": 21.56,
      "yield": 1.42,
      "date": "2016-04-13"
  },
  "2016-04-14": {
      "equity": 7850.4,
      "debt": 7.435,
      "gold": 29117.37773,
      "pb": 3.32,
      "pe": 21.56,
      "yield": 1.42,
      "date": "2016-04-14"
  },
  "2016-04-15": {
      "equity": 7850.4,
      "debt": 7.435,
      "gold": 28869.70493,
      "pb": 3.32,
      "pe": 21.56,
      "yield": 1.42,
      "date": "2016-04-15"
  },
  "2016-04-16": {
      "equity": 7850.4,
      "debt": 7.435,
      "gold": 28869.70493,
      "pb": 3.32,
      "pe": 21.56,
      "yield": 1.42,
      "date": "2016-04-16"
  },
  "2016-04-17": {
      "equity": 7850.4,
      "debt": 7.435,
      "gold": 28869.70493,
      "pb": 3.32,
      "pe": 21.56,
      "yield": 1.42,
      "date": "2016-04-17"
  },
  "2016-04-18": {
      "equity": 7914.7,
      "debt": 7.417,
      "gold": 29027.43611,
      "pb": 3.34,
      "pe": 21.68,
      "yield": 1.41,
      "date": "2016-04-18"
  },
  "2016-04-19": {
      "equity": 7914.7,
      "debt": 7.417,
      "gold": 29114.37239,
      "pb": 3.34,
      "pe": 21.68,
      "yield": 1.41,
      "date": "2016-04-19"
  },
  "2016-04-20": {
      "equity": 7914.7,
      "debt": 7.437,
      "gold": 29093.28912,
      "pb": 3.34,
      "pe": 21.59,
      "yield": 1.41,
      "date": "2016-04-20"
  },
  "2016-04-21": {
      "equity": 7912,
      "debt": 7.473,
      "gold": 29488.92926,
      "pb": 3.34,
      "pe": 21.59,
      "yield": 1.41,
      "date": "2016-04-21"
  },
  "2016-04-22": {
      "equity": 7899.3,
      "debt": 7.456,
      "gold": 29299.70546,
      "pb": 3.29,
      "pe": 21.55,
      "yield": 1.36,
      "date": "2016-04-22"
  },
  "2016-04-23": {
      "equity": 7899.3,
      "debt": 7.456,
      "gold": 29299.70546,
      "pb": 3.29,
      "pe": 21.55,
      "yield": 1.36,
      "date": "2016-04-23"
  },
  "2016-04-24": {
      "equity": 7899.3,
      "debt": 7.456,
      "gold": 29299.70546,
      "pb": 3.29,
      "pe": 21.55,
      "yield": 1.36,
      "date": "2016-04-24"
  },
  "2016-04-25": {
      "equity": 7855,
      "debt": 7.47,
      "gold": 28948.88799,
      "pb": 3.27,
      "pe": 21.4,
      "yield": 1.37,
      "date": "2016-04-25"
  },
  "2016-04-26": {
      "equity": 7962.6,
      "debt": 7.469,
      "gold": 28945.8121,
      "pb": 3.32,
      "pe": 21.6,
      "yield": 1.35,
      "date": "2016-04-26"
  },
  "2016-04-27": {
      "equity": 7979.9,
      "debt": 7.46,
      "gold": 29170.03474,
      "pb": 3.33,
      "pe": 21.66,
      "yield": 1.35,
      "date": "2016-04-27"
  },
  "2016-04-28": {
      "equity": 7847.2,
      "debt": 7.443,
      "gold": 29419.45361,
      "pb": 3.27,
      "pe": 21.3,
      "yield": 1.37,
      "date": "2016-04-28"
  },
  "2016-04-29": {
      "equity": 7849.8,
      "debt": 7.435,
      "gold": 29863.7013,
      "pb": 3.27,
      "pe": 21.24,
      "yield": 1.37,
      "date": "2016-04-29"
  },
  "2016-04-30": {
      "equity": 7849.8,
      "debt": 7.435,
      "gold": 29863.7013,
      "pb": 3.27,
      "pe": 21.24,
      "yield": 1.37,
      "date": "2016-04-30"
  },
  "2016-05-01": {
      "equity": 7849.8,
      "debt": 7.435,
      "gold": 29863.7013,
      "pb": 3.27,
      "pe": 21.24,
      "yield": 1.37,
      "date": "2016-05-01"
  },
  "2016-05-02": {
      "equity": 7805.9,
      "debt": 7.439,
      "gold": 29863.7013,
      "pb": 3.25,
      "pe": 21.25,
      "yield": 1.38,
      "date": "2016-05-02"
  },
  "2016-05-03": {
      "equity": 7747,
      "debt": 7.442,
      "gold": 30420.08501,
      "pb": 3.23,
      "pe": 21.08,
      "yield": 1.39,
      "date": "2016-05-03"
  },
  "2016-05-04": {
      "equity": 7706.5,
      "debt": 7.432,
      "gold": 30108.80615,
      "pb": 3.21,
      "pe": 20.88,
      "yield": 1.39,
      "date": "2016-05-04"
  },
  "2016-05-05": {
      "equity": 7735.5,
      "debt": 7.435,
      "gold": 29963.01169,
      "pb": 3.22,
      "pe": 20.96,
      "yield": 1.39,
      "date": "2016-05-05"
  },
  "2016-05-06": {
      "equity": 7733.4,
      "debt": 7.435,
      "gold": 30055.15441,
      "pb": 3.22,
      "pe": 20.91,
      "yield": 1.39,
      "date": "2016-05-06"
  },
  "2016-05-07": {
      "equity": 7733.4,
      "debt": 7.435,
      "gold": 30055.15441,
      "pb": 3.22,
      "pe": 20.91,
      "yield": 1.39,
      "date": "2016-05-07"
  },
  "2016-05-08": {
      "equity": 7733.4,
      "debt": 7.435,
      "gold": 30055.15441,
      "pb": 3.22,
      "pe": 20.91,
      "yield": 1.39,
      "date": "2016-05-08"
  },
  "2016-05-09": {
      "equity": 7866,
      "debt": 7.425,
      "gold": 30066.37154,
      "pb": 3.28,
      "pe": 21.24,
      "yield": 1.37,
      "date": "2016-05-09"
  },
  "2016-05-10": {
      "equity": 7887.8,
      "debt": 7.428,
      "gold": 29756.40135,
      "pb": 3.29,
      "pe": 21.3,
      "yield": 1.36,
      "date": "2016-05-10"
  },
  "2016-05-11": {
      "equity": 7848.8,
      "debt": 7.428,
      "gold": 29851.48944,
      "pb": 3.27,
      "pe": 21.19,
      "yield": 1.37,
      "date": "2016-05-11"
  },
  "2016-05-12": {
      "equity": 7900.4,
      "debt": 7.422,
      "gold": 29837.11529,
      "pb": 3.29,
      "pe": 21.33,
      "yield": 1.36,
      "date": "2016-05-12"
  },
  "2016-05-13": {
      "equity": 7814.9,
      "debt": 7.448,
      "gold": 30147.37121,
      "pb": 3.26,
      "pe": 21.1,
      "yield": 1.38,
      "date": "2016-05-13"
  },
  "2016-05-14": {
      "equity": 7814.9,
      "debt": 7.448,
      "gold": 30147.37121,
      "pb": 3.26,
      "pe": 21.1,
      "yield": 1.38,
      "date": "2016-05-14"
  },
  "2016-05-15": {
      "equity": 7814.9,
      "debt": 7.448,
      "gold": 30147.37121,
      "pb": 3.26,
      "pe": 21.1,
      "yield": 1.38,
      "date": "2016-05-15"
  },
  "2016-05-16": {
      "equity": 7860.7,
      "debt": 7.452,
      "gold": 30182.74396,
      "pb": 3.28,
      "pe": 21.54,
      "yield": 1.37,
      "date": "2016-05-16"
  },
  "2016-05-17": {
      "equity": 7890.7,
      "debt": 7.452,
      "gold": 29920.13969,
      "pb": 3.29,
      "pe": 21.62,
      "yield": 1.36,
      "date": "2016-05-17"
  },
  "2016-05-18": {
      "equity": 7870.1,
      "debt": 7.474,
      "gold": 30039.58447,
      "pb": 3.28,
      "pe": 21.57,
      "yield": 1.37,
      "date": "2016-05-18"
  },
  "2016-05-19": {
      "equity": 7783.4,
      "debt": 7.47,
      "gold": 29882.36477,
      "pb": 3.24,
      "pe": 21.33,
      "yield": 1.38,
      "date": "2016-05-19"
  },
  "2016-05-20": {
      "equity": 7749.7,
      "debt": 7.476,
      "gold": 29868.2199,
      "pb": 3.22,
      "pe": 21.19,
      "yield": 1.39,
      "date": "2016-05-20"
  },
  "2016-05-21": {
      "equity": 7749.7,
      "debt": 7.476,
      "gold": 29868.2199,
      "pb": 3.22,
      "pe": 21.19,
      "yield": 1.39,
      "date": "2016-05-21"
  },
  "2016-05-22": {
      "equity": 7749.7,
      "debt": 7.476,
      "gold": 29868.2199,
      "pb": 3.22,
      "pe": 21.19,
      "yield": 1.39,
      "date": "2016-05-22"
  },
  "2016-05-23": {
      "equity": 7731,
      "debt": 7.464,
      "gold": 29767.10348,
      "pb": 3.22,
      "pe": 21.13,
      "yield": 1.39,
      "date": "2016-05-23"
  },
  "2016-05-24": {
      "equity": 7748.8,
      "debt": 7.466,
      "gold": 29687.8922,
      "pb": 3.22,
      "pe": 21.16,
      "yield": 1.39,
      "date": "2016-05-24"
  },
  "2016-05-25": {
      "equity": 7934.9,
      "debt": 7.463,
      "gold": 28971.04005,
      "pb": 3.3,
      "pe": 22.07,
      "yield": 1.36,
      "date": "2016-05-25"
  },
  "2016-05-26": {
      "equity": 8069.6,
      "debt": 7.469,
      "gold": 28961.2621,
      "pb": 3.36,
      "pe": 22.39,
      "yield": 1.33,
      "date": "2016-05-26"
  },
  "2016-05-27": {
      "equity": 8156.6,
      "debt": 7.47,
      "gold": 28842.94608,
      "pb": 3.4,
      "pe": 22.66,
      "yield": 1.32,
      "date": "2016-05-27"
  },
  "2016-05-28": {
      "equity": 8156.6,
      "debt": 7.471,
      "gold": 28842.94608,
      "pb": 3.4,
      "pe": 22.66,
      "yield": 1.32,
      "date": "2016-05-28"
  },
  "2016-05-29": {
      "equity": 8156.6,
      "debt": 7.471,
      "gold": 28842.94608,
      "pb": 3.4,
      "pe": 22.66,
      "yield": 1.32,
      "date": "2016-05-29"
  },
  "2016-05-30": {
      "equity": 8178.5,
      "debt": 7.46,
      "gold": 28842.94608,
      "pb": 3.41,
      "pe": 22.69,
      "yield": 1.31,
      "date": "2016-05-30"
  },
  "2016-05-31": {
      "equity": 8160.1,
      "debt": 7.471,
      "gold": 28662.4138,
      "pb": 3.4,
      "pe": 22.6,
      "yield": 1.32,
      "date": "2016-05-31"
  },
  "2016-06-01": {
      "equity": 8179.9,
      "debt": 7.486,
      "gold": 28944.98668,
      "pb": 3.41,
      "pe": 22.65,
      "yield": 1.31,
      "date": "2016-06-01"
  },
  "2016-06-02": {
      "equity": 8218.9,
      "debt": 7.486,
      "gold": 28846.87561,
      "pb": 3.43,
      "pe": 22.76,
      "yield": 1.31,
      "date": "2016-06-02"
  },
  "2016-06-03": {
      "equity": 8220.8,
      "debt": 7.488,
      "gold": 28568.3557,
      "pb": 3.43,
      "pe": 22.77,
      "yield": 1.31,
      "date": "2016-06-03"
  },
  "2016-06-04": {
      "equity": 8220.8,
      "debt": 7.488,
      "gold": 28568.3557,
      "pb": 3.43,
      "pe": 22.77,
      "yield": 1.31,
      "date": "2016-06-04"
  },
  "2016-06-05": {
      "equity": 8220.8,
      "debt": 7.488,
      "gold": 28568.3557,
      "pb": 3.43,
      "pe": 22.77,
      "yield": 1.31,
      "date": "2016-06-05"
  },
  "2016-06-06": {
      "equity": 8201,
      "debt": 7.475,
      "gold": 29246.16307,
      "pb": 3.42,
      "pe": 22.71,
      "yield": 1.31,
      "date": "2016-06-06"
  },
  "2016-06-07": {
      "equity": 8266.4,
      "debt": 7.482,
      "gold": 29185.62585,
      "pb": 3.44,
      "pe": 22.69,
      "yield": 1.3,
      "date": "2016-06-07"
  },
  "2016-06-08": {
      "equity": 8273,
      "debt": 7.488,
      "gold": 29368.96594,
      "pb": 3.45,
      "pe": 22.71,
      "yield": 1.3,
      "date": "2016-06-08"
  },
  "2016-06-09": {
      "equity": 8203.6,
      "debt": 7.486,
      "gold": 29614.49056,
      "pb": 3.42,
      "pe": 22.52,
      "yield": 1.31,
      "date": "2016-06-09"
  },
  "2016-06-10": {
      "equity": 8170,
      "debt": 7.491,
      "gold": 29891.83936,
      "pb": 3.38,
      "pe": 22.43,
      "yield": 1.28,
      "date": "2016-06-10"
  },
  "2016-06-11": {
      "equity": 8170,
      "debt": 7.491,
      "gold": 29891.83936,
      "pb": 3.38,
      "pe": 22.43,
      "yield": 1.28,
      "date": "2016-06-11"
  },
  "2016-06-12": {
      "equity": 8170,
      "debt": 7.491,
      "gold": 29891.83936,
      "pb": 3.38,
      "pe": 22.43,
      "yield": 1.28,
      "date": "2016-06-12"
  },
  "2016-06-13": {
      "equity": 8110.6,
      "debt": 7.525,
      "gold": 30435.11173,
      "pb": 3.36,
      "pe": 22.26,
      "yield": 1.29,
      "date": "2016-06-13"
  },
  "2016-06-14": {
      "equity": 8108.8,
      "debt": 7.517,
      "gold": 30395.7389,
      "pb": 3.36,
      "pe": 22.26,
      "yield": 1.29,
      "date": "2016-06-14"
  },
  "2016-06-15": {
      "equity": 8206.6,
      "debt": 7.517,
      "gold": 30357.02922,
      "pb": 3.4,
      "pe": 22.53,
      "yield": 1.27,
      "date": "2016-06-15"
  },
  "2016-06-16": {
      "equity": 8140.7,
      "debt": 7.504,
      "gold": 31052.24078,
      "pb": 3.37,
      "pe": 22.35,
      "yield": 1.28,
      "date": "2016-06-16"
  },
  "2016-06-17": {
      "equity": 8170.2,
      "debt": 7.501,
      "gold": 30399.0088,
      "pb": 3.36,
      "pe": 22.43,
      "yield": 1.28,
      "date": "2016-06-17"
  },
  "2016-06-18": {
      "equity": 8170.2,
      "debt": 7.501,
      "gold": 30399.0088,
      "pb": 3.36,
      "pe": 22.43,
      "yield": 1.28,
      "date": "2016-06-18"
  },
  "2016-06-19": {
      "equity": 8170.2,
      "debt": 7.501,
      "gold": 30399.0088,
      "pb": 3.36,
      "pe": 22.43,
      "yield": 1.28,
      "date": "2016-06-19"
  },
  "2016-06-20": {
      "equity": 8238.5,
      "debt": 7.494,
      "gold": 30580.68044,
      "pb": 3.39,
      "pe": 22.62,
      "yield": 1.27,
      "date": "2016-06-20"
  },
  "2016-06-21": {
      "equity": 8219.9,
      "debt": 7.498,
      "gold": 30601.35805,
      "pb": 3.38,
      "pe": 22.56,
      "yield": 1.27,
      "date": "2016-06-21"
  },
  "2016-06-22": {
      "equity": 8203.7,
      "debt": 7.476,
      "gold": 30107.03892,
      "pb": 3.37,
      "pe": 22.52,
      "yield": 1.27,
      "date": "2016-06-22"
  },
  "2016-06-23": {
      "equity": 8270.4,
      "debt": 7.479,
      "gold": 30051.26722,
      "pb": 3.4,
      "pe": 22.7,
      "yield": 1.26,
      "date": "2016-06-23"
  },
  "2016-06-24": {
      "equity": 8088.6,
      "debt": 7.473,
      "gold": 31441.28468,
      "pb": 3.29,
      "pe": 22.2,
      "yield": 1.28,
      "date": "2016-06-24"
  },
  "2016-06-25": {
      "equity": 8088.6,
      "debt": 7.473,
      "gold": 31441.28468,
      "pb": 3.29,
      "pe": 22.2,
      "yield": 1.28,
      "date": "2016-06-25"
  },
  "2016-06-26": {
      "equity": 8088.6,
      "debt": 7.473,
      "gold": 31441.28468,
      "pb": 3.29,
      "pe": 22.2,
      "yield": 1.28,
      "date": "2016-06-26"
  },
  "2016-06-27": {
      "equity": 8094.7,
      "debt": 7.459,
      "gold": 31743.76973,
      "pb": 3.29,
      "pe": 22.22,
      "yield": 1.28,
      "date": "2016-06-27"
  },
  "2016-06-28": {
      "equity": 8127.8,
      "debt": 7.453,
      "gold": 31374.5216,
      "pb": 3.3,
      "pe": 22.31,
      "yield": 1.28,
      "date": "2016-06-28"
  },
  "2016-06-29": {
      "equity": 8204,
      "debt": 7.444,
      "gold": 31341.94607,
      "pb": 3.33,
      "pe": 22.52,
      "yield": 1.26,
      "date": "2016-06-29"
  },
  "2016-06-30": {
      "equity": 8287.7,
      "debt": 7.448,
      "gold": 31370.47214,
      "pb": 3.37,
      "pe": 22.75,
      "yield": 1.25,
      "date": "2016-06-30"
  },
  "2016-07-01": {
      "equity": 8328.3,
      "debt": 7.419,
      "gold": 31576.79677,
      "pb": 3.38,
      "pe": 22.86,
      "yield": 1.25,
      "date": "2016-07-01"
  },
  "2016-07-02": {
      "equity": 8328.3,
      "debt": 7.419,
      "gold": 31576.79677,
      "pb": 3.38,
      "pe": 22.86,
      "yield": 1.25,
      "date": "2016-07-02"
  },
  "2016-07-03": {
      "equity": 8328.3,
      "debt": 7.419,
      "gold": 31576.79677,
      "pb": 3.38,
      "pe": 22.86,
      "yield": 1.25,
      "date": "2016-07-03"
  },
  "2016-07-04": {
      "equity": 8370.7,
      "debt": 7.425,
      "gold": 32007.56627,
      "pb": 3.4,
      "pe": 22.98,
      "yield": 1.24,
      "date": "2016-07-04"
  },
  "2016-07-05": {
      "equity": 8335.9,
      "debt": 7.394,
      "gold": 31976.72622,
      "pb": 3.39,
      "pe": 22.88,
      "yield": 1.24,
      "date": "2016-07-05"
  },
  "2016-07-06": {
      "equity": 8335.9,
      "debt": 7.394,
      "gold": 32581.44588,
      "pb": 3.39,
      "pe": 22.88,
      "yield": 1.24,
      "date": "2016-07-06"
  },
  "2016-07-07": {
      "equity": 8337.9,
      "debt": 7.381,
      "gold": 32542.47165,
      "pb": 3.39,
      "pe": 22.89,
      "yield": 1.24,
      "date": "2016-07-07"
  },
  "2016-07-08": {
      "equity": 8323.2,
      "debt": 7.384,
      "gold": 32120.28431,
      "pb": 3.38,
      "pe": 22.85,
      "yield": 1.25,
      "date": "2016-07-08"
  },
  "2016-07-09": {
      "equity": 8323.2,
      "debt": 7.384,
      "gold": 32120.28431,
      "pb": 3.38,
      "pe": 22.85,
      "yield": 1.25,
      "date": "2016-07-09"
  },
  "2016-07-10": {
      "equity": 8323.2,
      "debt": 7.384,
      "gold": 32120.28431,
      "pb": 3.38,
      "pe": 22.85,
      "yield": 1.25,
      "date": "2016-07-10"
  },
  "2016-07-11": {
      "equity": 8467.9,
      "debt": 7.384,
      "gold": 32167.13875,
      "pb": 3.44,
      "pe": 23.25,
      "yield": 1.23,
      "date": "2016-07-11"
  },
  "2016-07-12": {
      "equity": 8521,
      "debt": 7.337,
      "gold": 31966.90594,
      "pb": 3.46,
      "pe": 23.37,
      "yield": 1.22,
      "date": "2016-07-12"
  },
  "2016-07-13": {
      "equity": 8519.5,
      "debt": 7.284,
      "gold": 31689.08094,
      "pb": 3.46,
      "pe": 23.37,
      "yield": 1.22,
      "date": "2016-07-13"
  },
  "2016-07-14": {
      "equity": 8565,
      "debt": 7.285,
      "gold": 31251.06616,
      "pb": 3.48,
      "pe": 23.49,
      "yield": 1.21,
      "date": "2016-07-14"
  },
  "2016-07-15": {
      "equity": 8541.4,
      "debt": 7.272,
      "gold": 31498.81656,
      "pb": 3.47,
      "pe": 23.43,
      "yield": 1.21,
      "date": "2016-07-15"
  },
  "2016-07-16": {
      "equity": 8541.4,
      "debt": 7.272,
      "gold": 31498.81656,
      "pb": 3.47,
      "pe": 23.43,
      "yield": 1.21,
      "date": "2016-07-16"
  },
  "2016-07-17": {
      "equity": 8541.4,
      "debt": 7.272,
      "gold": 31498.81656,
      "pb": 3.47,
      "pe": 23.43,
      "yield": 1.21,
      "date": "2016-07-17"
  },
  "2016-07-18": {
      "equity": 8508.7,
      "debt": 7.294,
      "gold": 31409.72504,
      "pb": 3.46,
      "pe": 23.34,
      "yield": 1.22,
      "date": "2016-07-18"
  },
  "2016-07-19": {
      "equity": 8528.5,
      "debt": 7.28,
      "gold": 31568.38745,
      "pb": 3.46,
      "pe": 23.39,
      "yield": 1.22,
      "date": "2016-07-19"
  },
  "2016-07-20": {
      "equity": 8565.8,
      "debt": 7.27,
      "gold": 31412.03549,
      "pb": 3.48,
      "pe": 23.49,
      "yield": 1.21,
      "date": "2016-07-20"
  },
  "2016-07-21": {
      "equity": 8510.1,
      "debt": 7.261,
      "gold": 31321.92808,
      "pb": 3.46,
      "pe": 23.34,
      "yield": 1.22,
      "date": "2016-07-21"
  },
  "2016-07-22": {
      "equity": 8541.2,
      "debt": 7.249,
      "gold": 31362.02755,
      "pb": 3.47,
      "pe": 23.43,
      "yield": 1.21,
      "date": "2016-07-22"
  },
  "2016-07-23": {
      "equity": 8541.2,
      "debt": 7.249,
      "gold": 31362.02755,
      "pb": 3.47,
      "pe": 23.43,
      "yield": 1.21,
      "date": "2016-07-23"
  },
  "2016-07-24": {
      "equity": 8541.2,
      "debt": 7.249,
      "gold": 31362.02755,
      "pb": 3.47,
      "pe": 23.43,
      "yield": 1.21,
      "date": "2016-07-24"
  },
  "2016-07-25": {
      "equity": 8635.6,
      "debt": 7.248,
      "gold": 31277.74388,
      "pb": 3.44,
      "pe": 23.69,
      "yield": 1.23,
      "date": "2016-07-25"
  },
  "2016-07-26": {
      "equity": 8590.6,
      "debt": 7.252,
      "gold": 31401.42154,
      "pb": 3.39,
      "pe": 23.56,
      "yield": 1.28,
      "date": "2016-07-26"
  },
  "2016-07-27": {
      "equity": 8615.8,
      "debt": 7.25,
      "gold": 31276.1636,
      "pb": 3.4,
      "pe": 23.63,
      "yield": 1.27,
      "date": "2016-07-27"
  },
  "2016-07-28": {
      "equity": 8666.3,
      "debt": 7.185,
      "gold": 31693.27854,
      "pb": 3.42,
      "pe": 23.69,
      "yield": 1.26,
      "date": "2016-07-28"
  },
  "2016-07-29": {
      "equity": 8638.5,
      "debt": 7.163,
      "gold": 31397.49555,
      "pb": 3.38,
      "pe": 23.62,
      "yield": 1.27,
      "date": "2016-07-29"
  },
  "2016-07-30": {
      "equity": 8638.5,
      "debt": 7.163,
      "gold": 31397.49555,
      "pb": 3.38,
      "pe": 23.62,
      "yield": 1.27,
      "date": "2016-07-30"
  },
  "2016-07-31": {
      "equity": 8638.5,
      "debt": 7.163,
      "gold": 31397.49555,
      "pb": 3.38,
      "pe": 23.62,
      "yield": 1.27,
      "date": "2016-07-31"
  },
  "2016-08-01": {
      "equity": 8636.5,
      "debt": 7.139,
      "gold": 31743.87555,
      "pb": 3.38,
      "pe": 23.61,
      "yield": 1.27,
      "date": "2016-08-01"
  },
  "2016-08-02": {
      "equity": 8622.9,
      "debt": 7.174,
      "gold": 31937.49802,
      "pb": 3.38,
      "pe": 23.58,
      "yield": 1.27,
      "date": "2016-08-02"
  },
  "2016-08-03": {
      "equity": 8544.8,
      "debt": 7.194,
      "gold": 32151.50884,
      "pb": 3.35,
      "pe": 23.36,
      "yield": 1.28,
      "date": "2016-08-03"
  },
  "2016-08-04": {
      "equity": 8551.1,
      "debt": 7.169,
      "gold": 31830.9882,
      "pb": 3.35,
      "pe": 23.38,
      "yield": 1.28,
      "date": "2016-08-04"
  },
  "2016-08-05": {
      "equity": 8683.1,
      "debt": 7.166,
      "gold": 32131.10637,
      "pb": 3.4,
      "pe": 23.74,
      "yield": 1.26,
      "date": "2016-08-05"
  },
  "2016-08-06": {
      "equity": 8683.1,
      "debt": 7.168,
      "gold": 32131.10637,
      "pb": 3.4,
      "pe": 23.74,
      "yield": 1.26,
      "date": "2016-08-06"
  },
  "2016-08-07": {
      "equity": 8683.1,
      "debt": 7.168,
      "gold": 32131.10637,
      "pb": 3.4,
      "pe": 23.74,
      "yield": 1.26,
      "date": "2016-08-07"
  },
  "2016-08-08": {
      "equity": 8711.3,
      "debt": 7.173,
      "gold": 31340.09065,
      "pb": 3.41,
      "pe": 23.82,
      "yield": 1.26,
      "date": "2016-08-08"
  },
  "2016-08-09": {
      "equity": 8678.2,
      "debt": 7.124,
      "gold": 31390.84287,
      "pb": 3.4,
      "pe": 23.73,
      "yield": 1.26,
      "date": "2016-08-09"
  },
  "2016-08-10": {
      "equity": 8575.3,
      "debt": 7.101,
      "gold": 31832.88594,
      "pb": 3.36,
      "pe": 23.44,
      "yield": 1.28,
      "date": "2016-08-10"
  },
  "2016-08-11": {
      "equity": 8592.1,
      "debt": 7.083,
      "gold": 31667.86363,
      "pb": 3.34,
      "pe": 23.49,
      "yield": 1.26,
      "date": "2016-08-11"
  },
  "2016-08-12": {
      "equity": 8672.1,
      "debt": 7.101,
      "gold": 31544.32001,
      "pb": 3.36,
      "pe": 23.71,
      "yield": 1.24,
      "date": "2016-08-12"
  },
  "2016-08-13": {
      "equity": 8672.1,
      "debt": 7.103,
      "gold": 31544.32001,
      "pb": 3.36,
      "pe": 23.71,
      "yield": 1.24,
      "date": "2016-08-13"
  },
  "2016-08-14": {
      "equity": 8672.1,
      "debt": 7.103,
      "gold": 31544.32001,
      "pb": 3.36,
      "pe": 23.71,
      "yield": 1.24,
      "date": "2016-08-14"
  },
  "2016-08-15": {
      "equity": 8672.1,
      "debt": 7.103,
      "gold": 31603.31576,
      "pb": 3.36,
      "pe": 23.71,
      "yield": 1.24,
      "date": "2016-08-15"
  },
  "2016-08-16": {
      "equity": 8642.5,
      "debt": 7.104,
      "gold": 31809.15007,
      "pb": 3.35,
      "pe": 23.63,
      "yield": 1.25,
      "date": "2016-08-16"
  },
  "2016-08-17": {
      "equity": 8624,
      "debt": 7.104,
      "gold": 31671.2711,
      "pb": 3.34,
      "pe": 23.58,
      "yield": 1.25,
      "date": "2016-08-17"
  },
  "2016-08-18": {
      "equity": 8673.2,
      "debt": 7.14,
      "gold": 31770.35927,
      "pb": 3.36,
      "pe": 23.71,
      "yield": 1.24,
      "date": "2016-08-18"
  },
  "2016-08-19": {
      "equity": 8666.9,
      "debt": 7.099,
      "gold": 31890.26614,
      "pb": 3.31,
      "pe": 23.7,
      "yield": 1.25,
      "date": "2016-08-19"
  },
  "2016-08-20": {
      "equity": 8666.9,
      "debt": 7.099,
      "gold": 31890.26614,
      "pb": 3.31,
      "pe": 23.7,
      "yield": 1.25,
      "date": "2016-08-20"
  },
  "2016-08-21": {
      "equity": 8666.9,
      "debt": 7.099,
      "gold": 31890.26614,
      "pb": 3.31,
      "pe": 23.7,
      "yield": 1.25,
      "date": "2016-08-21"
  },
  "2016-08-22": {
      "equity": 8629.1,
      "debt": 7.156,
      "gold": 31625.9158,
      "pb": 3.3,
      "pe": 23.59,
      "yield": 1.25,
      "date": "2016-08-22"
  },
  "2016-08-23": {
      "equity": 8632.6,
      "debt": 7.158,
      "gold": 31665.21808,
      "pb": 3.3,
      "pe": 23.73,
      "yield": 1.25,
      "date": "2016-08-23"
  },
  "2016-08-24": {
      "equity": 8650.3,
      "debt": 7.134,
      "gold": 31667.06644,
      "pb": 3.31,
      "pe": 23.78,
      "yield": 1.25,
      "date": "2016-08-24"
  },
  "2016-08-25": {
      "equity": 8592.2,
      "debt": 7.122,
      "gold": 31312.85208,
      "pb": 3.29,
      "pe": 23.62,
      "yield": 1.26,
      "date": "2016-08-25"
  },
  "2016-08-26": {
      "equity": 8572.5,
      "debt": 7.127,
      "gold": 31316.09376,
      "pb": 3.27,
      "pe": 23.5,
      "yield": 1.25,
      "date": "2016-08-26"
  },
  "2016-08-27": {
      "equity": 8572.5,
      "debt": 7.127,
      "gold": 31316.09376,
      "pb": 3.27,
      "pe": 23.5,
      "yield": 1.25,
      "date": "2016-08-27"
  },
  "2016-08-28": {
      "equity": 8572.5,
      "debt": 7.127,
      "gold": 31316.09376,
      "pb": 3.27,
      "pe": 23.5,
      "yield": 1.25,
      "date": "2016-08-28"
  },
  "2016-08-29": {
      "equity": 8607.4,
      "debt": 7.121,
      "gold": 31316.09376,
      "pb": 3.29,
      "pe": 23.6,
      "yield": 1.24,
      "date": "2016-08-29"
  },
  "2016-08-30": {
      "equity": 8744.3,
      "debt": 7.107,
      "gold": 31238.59327,
      "pb": 3.34,
      "pe": 23.97,
      "yield": 1.22,
      "date": "2016-08-30"
  },
  "2016-08-31": {
      "equity": 8786.2,
      "debt": 7.11,
      "gold": 31042.66389,
      "pb": 3.36,
      "pe": 24.09,
      "yield": 1.22,
      "date": "2016-08-31"
  },
  "2016-09-01": {
      "equity": 8774.6,
      "debt": 7.118,
      "gold": 30781.16369,
      "pb": 3.35,
      "pe": 24.06,
      "yield": 1.22,
      "date": "2016-09-01"
  },
  "2016-09-02": {
      "equity": 8809.6,
      "debt": 7.119,
      "gold": 30802.05647,
      "pb": 3.36,
      "pe": 24.15,
      "yield": 1.22,
      "date": "2016-09-02"
  },
  "2016-09-03": {
      "equity": 8809.6,
      "debt": 7.119,
      "gold": 30802.05647,
      "pb": 3.36,
      "pe": 24.15,
      "yield": 1.22,
      "date": "2016-09-03"
  },
  "2016-09-04": {
      "equity": 8809.6,
      "debt": 7.119,
      "gold": 30802.05647,
      "pb": 3.36,
      "pe": 24.15,
      "yield": 1.22,
      "date": "2016-09-04"
  },
  "2016-09-05": {
      "equity": 8809.6,
      "debt": 7.119,
      "gold": 31196.99818,
      "pb": 3.36,
      "pe": 24.15,
      "yield": 1.22,
      "date": "2016-09-05"
  },
  "2016-09-06": {
      "equity": 8943,
      "debt": 7.097,
      "gold": 31098.38269,
      "pb": 3.42,
      "pe": 24.52,
      "yield": 1.2,
      "date": "2016-09-06"
  },
  "2016-09-07": {
      "equity": 8917.9,
      "debt": 7.055,
      "gold": 31596.89236,
      "pb": 3.4,
      "pe": 24.45,
      "yield": 1.2,
      "date": "2016-09-07"
  },
  "2016-09-08": {
      "equity": 8952.5,
      "debt": 7.042,
      "gold": 31690.58714,
      "pb": 3.41,
      "pe": 24.54,
      "yield": 1.2,
      "date": "2016-09-08"
  },
  "2016-09-09": {
      "equity": 8866.7,
      "debt": 7.057,
      "gold": 31508.13947,
      "pb": 3.38,
      "pe": 24.31,
      "yield": 1.21,
      "date": "2016-09-09"
  },
  "2016-09-10": {
      "equity": 8866.7,
      "debt": 7.057,
      "gold": 31508.13947,
      "pb": 3.38,
      "pe": 24.31,
      "yield": 1.21,
      "date": "2016-09-10"
  },
  "2016-09-11": {
      "equity": 8866.7,
      "debt": 7.057,
      "gold": 31508.13947,
      "pb": 3.38,
      "pe": 24.31,
      "yield": 1.21,
      "date": "2016-09-11"
  },
  "2016-09-12": {
      "equity": 8715.6,
      "debt": 7.083,
      "gold": 31272.05065,
      "pb": 3.32,
      "pe": 23.89,
      "yield": 1.23,
      "date": "2016-09-12"
  },
  "2016-09-13": {
      "equity": 8715.6,
      "debt": 7.083,
      "gold": 31283.75104,
      "pb": 3.32,
      "pe": 23.89,
      "yield": 1.23,
      "date": "2016-09-13"
  },
  "2016-09-14": {
      "equity": 8726.6,
      "debt": 7.08,
      "gold": 31195.11808,
      "pb": 3.33,
      "pe": 23.92,
      "yield": 1.23,
      "date": "2016-09-14"
  },
  "2016-09-15": {
      "equity": 8742.5,
      "debt": 7.067,
      "gold": 31137.21582,
      "pb": 3.33,
      "pe": 23.97,
      "yield": 1.23,
      "date": "2016-09-15"
  },
  "2016-09-16": {
      "equity": 8779.8,
      "debt": 7.051,
      "gold": 31109.70211,
      "pb": 3.34,
      "pe": 24.07,
      "yield": 1.26,
      "date": "2016-09-16"
  },
  "2016-09-17": {
      "equity": 8779.8,
      "debt": 7.052,
      "gold": 31109.70211,
      "pb": 3.34,
      "pe": 24.07,
      "yield": 1.26,
      "date": "2016-09-17"
  },
  "2016-09-18": {
      "equity": 8779.8,
      "debt": 7.052,
      "gold": 31109.70211,
      "pb": 3.34,
      "pe": 24.07,
      "yield": 1.26,
      "date": "2016-09-18"
  },
  "2016-09-19": {
      "equity": 8808.4,
      "debt": 7.052,
      "gold": 31067.27103,
      "pb": 3.34,
      "pe": 24.15,
      "yield": 1.25,
      "date": "2016-09-19"
  },
  "2016-09-20": {
      "equity": 8775.9,
      "debt": 7.065,
      "gold": 31085.74754,
      "pb": 3.33,
      "pe": 24.06,
      "yield": 1.26,
      "date": "2016-09-20"
  },
  "2016-09-21": {
      "equity": 8777.1,
      "debt": 7.039,
      "gold": 31171.03653,
      "pb": 3.33,
      "pe": 24.11,
      "yield": 1.26,
      "date": "2016-09-21"
  },
  "2016-09-22": {
      "equity": 8867.4,
      "debt": 6.977,
      "gold": 31305.51156,
      "pb": 3.37,
      "pe": 24.34,
      "yield": 1.25,
      "date": "2016-09-22"
  },
  "2016-09-23": {
      "equity": 8831.5,
      "debt": 6.966,
      "gold": 31420.82929,
      "pb": 3.35,
      "pe": 24.24,
      "yield": 1.25,
      "date": "2016-09-23"
  },
  "2016-09-24": {
      "equity": 8831.5,
      "debt": 6.966,
      "gold": 31420.82929,
      "pb": 3.35,
      "pe": 24.24,
      "yield": 1.25,
      "date": "2016-09-24"
  },
  "2016-09-25": {
      "equity": 8831.5,
      "debt": 6.966,
      "gold": 31420.82929,
      "pb": 3.35,
      "pe": 24.24,
      "yield": 1.25,
      "date": "2016-09-25"
  },
  "2016-09-26": {
      "equity": 8723,
      "debt": 6.934,
      "gold": 31388.09503,
      "pb": 3.31,
      "pe": 23.94,
      "yield": 1.27,
      "date": "2016-09-26"
  },
  "2016-09-27": {
      "equity": 8706.4,
      "debt": 6.933,
      "gold": 31300.76721,
      "pb": 3.3,
      "pe": 23.9,
      "yield": 1.27,
      "date": "2016-09-27"
  },
  "2016-09-28": {
      "equity": 8745.1,
      "debt": 6.921,
      "gold": 31067.74017,
      "pb": 3.32,
      "pe": 23.97,
      "yield": 1.27,
      "date": "2016-09-28"
  },
  "2016-09-29": {
      "equity": 8591.2,
      "debt": 7.013,
      "gold": 31118.09732,
      "pb": 3.26,
      "pe": 23.51,
      "yield": 1.29,
      "date": "2016-09-29"
  },
  "2016-09-30": {
      "equity": 8611.1,
      "debt": 6.958,
      "gold": 31209.85555,
      "pb": 3.27,
      "pe": 23.4,
      "yield": 1.29,
      "date": "2016-09-30"
  },
  "2016-10-01": {
      "equity": 8611.1,
      "debt": 6.958,
      "gold": 31209.85555,
      "pb": 3.27,
      "pe": 23.4,
      "yield": 1.29,
      "date": "2016-10-01"
  },
  "2016-10-02": {
      "equity": 8611.1,
      "debt": 6.958,
      "gold": 31209.85555,
      "pb": 3.27,
      "pe": 23.4,
      "yield": 1.29,
      "date": "2016-10-02"
  },
  "2016-10-03": {
      "equity": 8738.1,
      "debt": 6.899,
      "gold": 30941.61096,
      "pb": 3.31,
      "pe": 23.8,
      "yield": 1.27,
      "date": "2016-10-03"
  },
  "2016-10-04": {
      "equity": 8769.1,
      "debt": 6.837,
      "gold": 30759.84056,
      "pb": 3.33,
      "pe": 23.95,
      "yield": 1.26,
      "date": "2016-10-04"
  },
  "2016-10-05": {
      "equity": 8743.9,
      "debt": 6.797,
      "gold": 29952.78576,
      "pb": 3.32,
      "pe": 23.43,
      "yield": 1.27,
      "date": "2016-10-05"
  },
  "2016-10-06": {
      "equity": 8709.5,
      "debt": 6.82,
      "gold": 29772.58505,
      "pb": 3.3,
      "pe": 23.52,
      "yield": 1.27,
      "date": "2016-10-06"
  },
  "2016-10-07": {
      "equity": 8697.6,
      "debt": 6.848,
      "gold": 29507.628,
      "pb": 3.29,
      "pe": 23.49,
      "yield": 1.27,
      "date": "2016-10-07"
  },
  "2016-10-08": {
      "equity": 8697.6,
      "debt": 6.848,
      "gold": 29507.628,
      "pb": 3.29,
      "pe": 23.49,
      "yield": 1.27,
      "date": "2016-10-08"
  },
  "2016-10-09": {
      "equity": 8697.6,
      "debt": 6.848,
      "gold": 29507.628,
      "pb": 3.29,
      "pe": 23.49,
      "yield": 1.27,
      "date": "2016-10-09"
  },
  "2016-10-10": {
      "equity": 8708.8,
      "debt": 6.819,
      "gold": 29593.98578,
      "pb": 3.3,
      "pe": 23.34,
      "yield": 1.27,
      "date": "2016-10-10"
  },
  "2016-10-11": {
      "equity": 8708.8,
      "debt": 6.819,
      "gold": 29462.94291,
      "pb": 3.3,
      "pe": 23.34,
      "yield": 1.27,
      "date": "2016-10-11"
  },
  "2016-10-12": {
      "equity": 8708.8,
      "debt": 6.819,
      "gold": 29446.52992,
      "pb": 3.3,
      "pe": 23.34,
      "yield": 1.27,
      "date": "2016-10-12"
  },
  "2016-10-13": {
      "equity": 8573.3,
      "debt": 6.828,
      "gold": 29659.85996,
      "pb": 3.25,
      "pe": 23.16,
      "yield": 1.29,
      "date": "2016-10-13"
  },
  "2016-10-14": {
      "equity": 8583.4,
      "debt": 6.852,
      "gold": 29554.3872,
      "pb": 3.25,
      "pe": 22.98,
      "yield": 1.29,
      "date": "2016-10-14"
  },
  "2016-10-15": {
      "equity": 8583.4,
      "debt": 6.854,
      "gold": 29554.3872,
      "pb": 3.25,
      "pe": 22.98,
      "yield": 1.29,
      "date": "2016-10-15"
  },
  "2016-10-16": {
      "equity": 8583.4,
      "debt": 6.854,
      "gold": 29554.3872,
      "pb": 3.25,
      "pe": 22.98,
      "yield": 1.29,
      "date": "2016-10-16"
  },
  "2016-10-17": {
      "equity": 8520.4,
      "debt": 6.844,
      "gold": 29519.12732,
      "pb": 3.23,
      "pe": 23,
      "yield": 1.3,
      "date": "2016-10-17"
  },
  "2016-10-18": {
      "equity": 8677.9,
      "debt": 6.812,
      "gold": 29695.49375,
      "pb": 3.29,
      "pe": 23.22,
      "yield": 1.28,
      "date": "2016-10-18"
  },
  "2016-10-19": {
      "equity": 8659.1,
      "debt": 6.819,
      "gold": 29846.50523,
      "pb": 3.28,
      "pe": 23.35,
      "yield": 1.28,
      "date": "2016-10-19"
  },
  "2016-10-20": {
      "equity": 8699.4,
      "debt": 6.847,
      "gold": 29922.93691,
      "pb": 3.3,
      "pe": 23.46,
      "yield": 1.27,
      "date": "2016-10-20"
  },
  "2016-10-21": {
      "equity": 8693,
      "debt": 6.848,
      "gold": 29845.30591,
      "pb": 3.29,
      "pe": 23.23,
      "yield": 1.27,
      "date": "2016-10-21"
  },
  "2016-10-22": {
      "equity": 8693,
      "debt": 6.848,
      "gold": 29845.30591,
      "pb": 3.29,
      "pe": 23.23,
      "yield": 1.27,
      "date": "2016-10-22"
  },
  "2016-10-23": {
      "equity": 8693,
      "debt": 6.848,
      "gold": 29845.30591,
      "pb": 3.29,
      "pe": 23.23,
      "yield": 1.27,
      "date": "2016-10-23"
  },
  "2016-10-24": {
      "equity": 8708.9,
      "debt": 6.856,
      "gold": 29893.28207,
      "pb": 3.3,
      "pe": 23.27,
      "yield": 1.27,
      "date": "2016-10-24"
  },
  "2016-10-25": {
      "equity": 8691.3,
      "debt": 6.87,
      "gold": 29914.8627,
      "pb": 3.29,
      "pe": 23.23,
      "yield": 1.27,
      "date": "2016-10-25"
  },
  "2016-10-26": {
      "equity": 8615.2,
      "debt": 6.871,
      "gold": 30046.81917,
      "pb": 3.26,
      "pe": 23.3,
      "yield": 1.28,
      "date": "2016-10-26"
  },
  "2016-10-27": {
      "equity": 8615.2,
      "debt": 6.888,
      "gold": 29937.33928,
      "pb": 3.26,
      "pe": 23.28,
      "yield": 1.28,
      "date": "2016-10-27"
  },
  "2016-10-28": {
      "equity": 8638,
      "debt": 6.885,
      "gold": 29827.81001,
      "pb": 3.27,
      "pe": 23.34,
      "yield": 1.28,
      "date": "2016-10-28"
  },
  "2016-10-29": {
      "equity": 8638,
      "debt": 6.885,
      "gold": 29827.81001,
      "pb": 3.27,
      "pe": 23.34,
      "yield": 1.28,
      "date": "2016-10-29"
  },
  "2016-10-30": {
      "equity": 8625.7,
      "debt": 6.885,
      "gold": 29827.81001,
      "pb": 3.27,
      "pe": 23.31,
      "yield": 1.28,
      "date": "2016-10-30"
  },
  "2016-10-31": {
      "equity": 8625.7,
      "debt": 6.885,
      "gold": 30023.3796,
      "pb": 3.27,
      "pe": 23.31,
      "yield": 1.28,
      "date": "2016-10-31"
  },
  "2016-11-01": {
      "equity": 8626.2,
      "debt": 6.908,
      "gold": 30232.86125,
      "pb": 3.27,
      "pe": 23.31,
      "yield": 1.28,
      "date": "2016-11-01"
  },
  "2016-11-02": {
      "equity": 8514,
      "debt": 6.9,
      "gold": 30509.92081,
      "pb": 3.23,
      "pe": 23.01,
      "yield": 1.3,
      "date": "2016-11-02"
  },
  "2016-11-03": {
      "equity": 8484.9,
      "debt": 6.823,
      "gold": 30413.40059,
      "pb": 3.21,
      "pe": 22.93,
      "yield": 1.3,
      "date": "2016-11-03"
  },
  "2016-11-04": {
      "equity": 8433.7,
      "debt": 6.838,
      "gold": 30675.66271,
      "pb": 3.19,
      "pe": 22.73,
      "yield": 1.31,
      "date": "2016-11-04"
  },
  "2016-11-05": {
      "equity": 8433.7,
      "debt": 6.838,
      "gold": 30675.66271,
      "pb": 3.19,
      "pe": 22.73,
      "yield": 1.31,
      "date": "2016-11-05"
  },
  "2016-11-06": {
      "equity": 8433.7,
      "debt": 6.838,
      "gold": 30675.66271,
      "pb": 3.19,
      "pe": 22.73,
      "yield": 1.31,
      "date": "2016-11-06"
  },
  "2016-11-07": {
      "equity": 8497,
      "debt": 6.834,
      "gold": 30293.66655,
      "pb": 3.22,
      "pe": 22.67,
      "yield": 1.3,
      "date": "2016-11-07"
  },
  "2016-11-08": {
      "equity": 8543.5,
      "debt": 6.796,
      "gold": 30053.73992,
      "pb": 3.24,
      "pe": 22.57,
      "yield": 1.3,
      "date": "2016-11-08"
  },
  "2016-11-09": {
      "equity": 8432,
      "debt": 6.666,
      "gold": 30592.81116,
      "pb": 3.19,
      "pe": 22.48,
      "yield": 1.31,
      "date": "2016-11-09"
  },
  "2016-11-10": {
      "equity": 8525.7,
      "debt": 6.657,
      "gold": 30297.1869,
      "pb": 3.23,
      "pe": 22.7,
      "yield": 1.3,
      "date": "2016-11-10"
  },
  "2016-11-11": {
      "equity": 8296.3,
      "debt": 6.722,
      "gold": 29967.29043,
      "pb": 3.14,
      "pe": 22.02,
      "yield": 1.33,
      "date": "2016-11-11"
  },
  "2016-11-12": {
      "equity": 8296.3,
      "debt": 6.722,
      "gold": 29967.29043,
      "pb": 3.14,
      "pe": 22.02,
      "yield": 1.33,
      "date": "2016-11-12"
  },
  "2016-11-13": {
      "equity": 8296.3,
      "debt": 6.722,
      "gold": 29967.29043,
      "pb": 3.14,
      "pe": 22.02,
      "yield": 1.33,
      "date": "2016-11-13"
  },
  "2016-11-14": {
      "equity": 8296.3,
      "debt": 6.722,
      "gold": 29178.52167,
      "pb": 3.14,
      "pe": 22.02,
      "yield": 1.33,
      "date": "2016-11-14"
  },
  "2016-11-15": {
      "equity": 8108.4,
      "debt": 6.53,
      "gold": 29409.24531,
      "pb": 3.07,
      "pe": 21.37,
      "yield": 1.36,
      "date": "2016-11-15"
  },
  "2016-11-16": {
      "equity": 8111.6,
      "debt": 6.444,
      "gold": 29432.45207,
      "pb": 3.07,
      "pe": 21.62,
      "yield": 1.36,
      "date": "2016-11-16"
  },
  "2016-11-17": {
      "equity": 8079.9,
      "debt": 6.419,
      "gold": 29521.15205,
      "pb": 3.06,
      "pe": 21.49,
      "yield": 1.37,
      "date": "2016-11-17"
  },
  "2016-11-18": {
      "equity": 8074.1,
      "debt": 6.428,
      "gold": 28989.71058,
      "pb": 3.06,
      "pe": 21.41,
      "yield": 1.37,
      "date": "2016-11-18"
  },
  "2016-11-19": {
      "equity": 8074.1,
      "debt": 6.428,
      "gold": 28989.71058,
      "pb": 3.06,
      "pe": 21.41,
      "yield": 1.37,
      "date": "2016-11-19"
  },
  "2016-11-20": {
      "equity": 8074.1,
      "debt": 6.428,
      "gold": 28989.71058,
      "pb": 3.06,
      "pe": 21.41,
      "yield": 1.37,
      "date": "2016-11-20"
  },
  "2016-11-21": {
      "equity": 7929.1,
      "debt": 6.304,
      "gold": 29247.16838,
      "pb": 3,
      "pe": 21.19,
      "yield": 1.4,
      "date": "2016-11-21"
  },
  "2016-11-22": {
      "equity": 8002.3,
      "debt": 6.307,
      "gold": 29369.58324,
      "pb": 3.03,
      "pe": 21.38,
      "yield": 1.38,
      "date": "2016-11-22"
  },
  "2016-11-23": {
      "equity": 8033.3,
      "debt": 6.278,
      "gold": 29469.44391,
      "pb": 3.04,
      "pe": 21.47,
      "yield": 1.38,
      "date": "2016-11-23"
  },
  "2016-11-24": {
      "equity": 7965.5,
      "debt": 6.183,
      "gold": 28801.30867,
      "pb": 3.02,
      "pe": 21.29,
      "yield": 1.39,
      "date": "2016-11-24"
  },
  "2016-11-25": {
      "equity": 8114.3,
      "debt": 6.229,
      "gold": 28738.21761,
      "pb": 3.08,
      "pe": 21.52,
      "yield": 1.36,
      "date": "2016-11-25"
  },
  "2016-11-26": {
      "equity": 8114.3,
      "debt": 6.229,
      "gold": 28738.21761,
      "pb": 3.08,
      "pe": 21.52,
      "yield": 1.36,
      "date": "2016-11-26"
  },
  "2016-11-27": {
      "equity": 8114.3,
      "debt": 6.229,
      "gold": 28738.21761,
      "pb": 3.08,
      "pe": 21.52,
      "yield": 1.36,
      "date": "2016-11-27"
  },
  "2016-11-28": {
      "equity": 8126.9,
      "debt": 6.323,
      "gold": 28770.85663,
      "pb": 3.08,
      "pe": 21.55,
      "yield": 1.36,
      "date": "2016-11-28"
  },
  "2016-11-29": {
      "equity": 8142.1,
      "debt": 6.317,
      "gold": 28747.32182,
      "pb": 3.09,
      "pe": 21.43,
      "yield": 1.36,
      "date": "2016-11-29"
  },
  "2016-11-30": {
      "equity": 8224.5,
      "debt": 6.243,
      "gold": 28715.73396,
      "pb": 3.12,
      "pe": 21.61,
      "yield": 1.34,
      "date": "2016-11-30"
  },
  "2016-12-01": {
      "equity": 8192.9,
      "debt": 6.212,
      "gold": 28134.72195,
      "pb": 3.11,
      "pe": 21.53,
      "yield": 1.35,
      "date": "2016-12-01"
  },
  "2016-12-02": {
      "equity": 8086.8,
      "debt": 6.241,
      "gold": 28113.60694,
      "pb": 3.07,
      "pe": 21.25,
      "yield": 1.37,
      "date": "2016-12-02"
  },
  "2016-12-03": {
      "equity": 8086.8,
      "debt": 6.241,
      "gold": 28113.60694,
      "pb": 3.07,
      "pe": 21.25,
      "yield": 1.37,
      "date": "2016-12-03"
  },
  "2016-12-04": {
      "equity": 8086.8,
      "debt": 6.241,
      "gold": 28113.60694,
      "pb": 3.07,
      "pe": 21.25,
      "yield": 1.37,
      "date": "2016-12-04"
  },
  "2016-12-05": {
      "equity": 8128.7,
      "debt": 6.216,
      "gold": 27942.72562,
      "pb": 3.08,
      "pe": 21.34,
      "yield": 1.36,
      "date": "2016-12-05"
  },
  "2016-12-06": {
      "equity": 8143.1,
      "debt": 6.201,
      "gold": 27980.52523,
      "pb": 3.09,
      "pe": 21.56,
      "yield": 1.36,
      "date": "2016-12-06"
  },
  "2016-12-07": {
      "equity": 8102,
      "debt": 6.41,
      "gold": 27900.90478,
      "pb": 3.07,
      "pe": 21.28,
      "yield": 1.36,
      "date": "2016-12-07"
  },
  "2016-12-08": {
      "equity": 8246.8,
      "debt": 6.4,
      "gold": 27938.70086,
      "pb": 3.13,
      "pe": 21.83,
      "yield": 1.34,
      "date": "2016-12-08"
  },
  "2016-12-09": {
      "equity": 8261.7,
      "debt": 6.437,
      "gold": 27841.99721,
      "pb": 3.13,
      "pe": 21.87,
      "yield": 1.34,
      "date": "2016-12-09"
  },
  "2016-12-10": {
      "equity": 8261.7,
      "debt": 6.437,
      "gold": 27841.99721,
      "pb": 3.13,
      "pe": 21.87,
      "yield": 1.34,
      "date": "2016-12-10"
  },
  "2016-12-11": {
      "equity": 8261.7,
      "debt": 6.437,
      "gold": 27841.99721,
      "pb": 3.13,
      "pe": 21.87,
      "yield": 1.34,
      "date": "2016-12-11"
  },
  "2016-12-12": {
      "equity": 8170.8,
      "debt": 6.437,
      "gold": 27496.62252,
      "pb": 3.1,
      "pe": 21.45,
      "yield": 1.35,
      "date": "2016-12-12"
  },
  "2016-12-13": {
      "equity": 8221.8,
      "debt": 6.423,
      "gold": 27542.15066,
      "pb": 3.12,
      "pe": 21.59,
      "yield": 1.34,
      "date": "2016-12-13"
  },
  "2016-12-14": {
      "equity": 8182.4,
      "debt": 6.404,
      "gold": 27631.09755,
      "pb": 3.1,
      "pe": 21.48,
      "yield": 1.35,
      "date": "2016-12-14"
  },
  "2016-12-15": {
      "equity": 8153.6,
      "debt": 6.538,
      "gold": 27128.78887,
      "pb": 3.09,
      "pe": 21.41,
      "yield": 1.35,
      "date": "2016-12-15"
  },
  "2016-12-16": {
      "equity": 8139.4,
      "debt": 6.504,
      "gold": 27158.22149,
      "pb": 3.09,
      "pe": 21.37,
      "yield": 1.36,
      "date": "2016-12-16"
  },
  "2016-12-17": {
      "equity": 8139.4,
      "debt": 6.504,
      "gold": 27158.22149,
      "pb": 3.09,
      "pe": 21.37,
      "yield": 1.36,
      "date": "2016-12-17"
  },
  "2016-12-18": {
      "equity": 8139.4,
      "debt": 6.504,
      "gold": 27158.22149,
      "pb": 3.09,
      "pe": 21.37,
      "yield": 1.36,
      "date": "2016-12-18"
  },
  "2016-12-19": {
      "equity": 8104.3,
      "debt": 6.51,
      "gold": 27228.12395,
      "pb": 3.07,
      "pe": 21.3,
      "yield": 1.36,
      "date": "2016-12-19"
  },
  "2016-12-20": {
      "equity": 8082.4,
      "debt": 6.48,
      "gold": 27148.52114,
      "pb": 3.06,
      "pe": 21.24,
      "yield": 1.37,
      "date": "2016-12-20"
  },
  "2016-12-21": {
      "equity": 8061.3,
      "debt": 6.46,
      "gold": 27139.04654,
      "pb": 3.06,
      "pe": 21.16,
      "yield": 1.37,
      "date": "2016-12-21"
  },
  "2016-12-22": {
      "equity": 7979.1,
      "debt": 6.518,
      "gold": 27075.056,
      "pb": 3.03,
      "pe": 21.18,
      "yield": 1.38,
      "date": "2016-12-22"
  },
  "2016-12-23": {
      "equity": 7985.7,
      "debt": 6.544,
      "gold": 27064.68897,
      "pb": 3.03,
      "pe": 21.39,
      "yield": 1.38,
      "date": "2016-12-23"
  },
  "2016-12-24": {
      "equity": 7985.7,
      "debt": 6.544,
      "gold": 27064.68897,
      "pb": 3.03,
      "pe": 21.39,
      "yield": 1.38,
      "date": "2016-12-24"
  },
  "2016-12-25": {
      "equity": 7985.7,
      "debt": 6.544,
      "gold": 27064.68897,
      "pb": 3.03,
      "pe": 21.39,
      "yield": 1.38,
      "date": "2016-12-25"
  },
  "2016-12-26": {
      "equity": 7908.2,
      "debt": 6.57,
      "gold": 27064.68897,
      "pb": 3,
      "pe": 21.36,
      "yield": 1.4,
      "date": "2016-12-26"
  },
  "2016-12-27": {
      "equity": 8032.8,
      "debt": 6.587,
      "gold": 27064.68897,
      "pb": 3.05,
      "pe": 21.7,
      "yield": 1.37,
      "date": "2016-12-27"
  },
  "2016-12-28": {
      "equity": 8034.8,
      "debt": 6.581,
      "gold": 27424.83994,
      "pb": 3.05,
      "pe": 21.71,
      "yield": 1.37,
      "date": "2016-12-28"
  },
  "2016-12-29": {
      "equity": 8103.6,
      "debt": 6.523,
      "gold": 27488.2908,
      "pb": 3.07,
      "pe": 21.89,
      "yield": 1.36,
      "date": "2016-12-29"
  },
  "2016-12-30": {
      "equity": 8185.8,
      "debt": 6.512,
      "gold": 27792.88876,
      "pb": 3.1,
      "pe": 21.93,
      "yield": 1.35,
      "date": "2016-12-30"
  },
  "2016-12-31": {
      "equity": 8185.8,
      "debt": 6.512,
      "gold": 27792.88876,
      "pb": 3.1,
      "pe": 21.93,
      "yield": 1.35,
      "date": "2016-12-31"
  },
  "2017-01-01": {
      "equity": 8185.8,
      "debt": 6.512,
      "gold": 27792.88876,
      "pb": 3.1,
      "pe": 21.93,
      "yield": 1.35,
      "date": "2017-01-01"
  },
  "2017-01-02": {
      "equity": 8179.5,
      "debt": 6.402,
      "gold": 27792.88876,
      "pb": 3.1,
      "pe": 22.08,
      "yield": 1.35,
      "date": "2017-01-02"
  },
  "2017-01-03": {
      "equity": 8192.2,
      "debt": 6.44,
      "gold": 27710.91201,
      "pb": 3.11,
      "pe": 22.12,
      "yield": 1.35,
      "date": "2017-01-03"
  },
  "2017-01-04": {
      "equity": 8190.5,
      "debt": 6.36,
      "gold": 27918.34777,
      "pb": 3.11,
      "pe": 21.94,
      "yield": 1.35,
      "date": "2017-01-04"
  },
  "2017-01-05": {
      "equity": 8273.8,
      "debt": 6.385,
      "gold": 28033.65844,
      "pb": 3.14,
      "pe": 22.17,
      "yield": 1.33,
      "date": "2017-01-05"
  },
  "2017-01-06": {
      "equity": 8243.8,
      "debt": 6.385,
      "gold": 28321.36369,
      "pb": 3.13,
      "pe": 22.09,
      "yield": 1.34,
      "date": "2017-01-06"
  },
  "2017-01-07": {
      "equity": 8243.8,
      "debt": 6.385,
      "gold": 28321.36369,
      "pb": 3.13,
      "pe": 22.09,
      "yield": 1.34,
      "date": "2017-01-07"
  },
  "2017-01-08": {
      "equity": 8243.8,
      "debt": 6.385,
      "gold": 28321.36369,
      "pb": 3.13,
      "pe": 22.09,
      "yield": 1.34,
      "date": "2017-01-08"
  },
  "2017-01-09": {
      "equity": 8236,
      "debt": 6.394,
      "gold": 28274.81261,
      "pb": 3.12,
      "pe": 22.07,
      "yield": 1.34,
      "date": "2017-01-09"
  },
  "2017-01-10": {
      "equity": 8288.6,
      "debt": 6.398,
      "gold": 28506.06536,
      "pb": 3.14,
      "pe": 22.39,
      "yield": 1.33,
      "date": "2017-01-10"
  },
  "2017-01-11": {
      "equity": 8380.6,
      "debt": 6.391,
      "gold": 28581.58698,
      "pb": 3.18,
      "pe": 22.42,
      "yield": 1.32,
      "date": "2017-01-11"
  },
  "2017-01-12": {
      "equity": 8407.2,
      "debt": 6.372,
      "gold": 28955.8546,
      "pb": 3.19,
      "pe": 22.5,
      "yield": 1.31,
      "date": "2017-01-12"
  },
  "2017-01-13": {
      "equity": 8400.3,
      "debt": 6.417,
      "gold": 28761.43495,
      "pb": 3.18,
      "pe": 22.47,
      "yield": 1.31,
      "date": "2017-01-13"
  },
  "2017-01-14": {
      "equity": 8400.3,
      "debt": 6.417,
      "gold": 28761.43495,
      "pb": 3.18,
      "pe": 22.47,
      "yield": 1.31,
      "date": "2017-01-14"
  },
  "2017-01-15": {
      "equity": 8400.3,
      "debt": 6.417,
      "gold": 28761.43495,
      "pb": 3.18,
      "pe": 22.47,
      "yield": 1.31,
      "date": "2017-01-15"
  },
  "2017-01-16": {
      "equity": 8412.8,
      "debt": 6.44,
      "gold": 28903.8431,
      "pb": 3.19,
      "pe": 22.5,
      "yield": 1.31,
      "date": "2017-01-16"
  },
  "2017-01-17": {
      "equity": 8398,
      "debt": 6.419,
      "gold": 29184.09848,
      "pb": 3.18,
      "pe": 22.4,
      "yield": 1.32,
      "date": "2017-01-17"
  },
  "2017-01-18": {
      "equity": 8417,
      "debt": 6.452,
      "gold": 29154.27433,
      "pb": 3.19,
      "pe": 22.39,
      "yield": 1.31,
      "date": "2017-01-18"
  },
  "2017-01-19": {
      "equity": 8435.1,
      "debt": 6.474,
      "gold": 28921.87164,
      "pb": 3.2,
      "pe": 22.44,
      "yield": 1.31,
      "date": "2017-01-19"
  },
  "2017-01-20": {
      "equity": 8349.3,
      "debt": 6.462,
      "gold": 28802.29634,
      "pb": 3.17,
      "pe": 22.37,
      "yield": 1.32,
      "date": "2017-01-20"
  },
  "2017-01-21": {
      "equity": 8349.3,
      "debt": 6.462,
      "gold": 28802.29634,
      "pb": 3.17,
      "pe": 22.37,
      "yield": 1.32,
      "date": "2017-01-21"
  },
  "2017-01-22": {
      "equity": 8349.3,
      "debt": 6.462,
      "gold": 28802.29634,
      "pb": 3.17,
      "pe": 22.37,
      "yield": 1.32,
      "date": "2017-01-22"
  },
  "2017-01-23": {
      "equity": 8391.5,
      "debt": 6.451,
      "gold": 29160.48255,
      "pb": 3.18,
      "pe": 22.48,
      "yield": 1.32,
      "date": "2017-01-23"
  },
  "2017-01-24": {
      "equity": 8475.8,
      "debt": 6.437,
      "gold": 29164.65193,
      "pb": 3.21,
      "pe": 22.64,
      "yield": 1.3,
      "date": "2017-01-24"
  },
  "2017-01-25": {
      "equity": 8602.7,
      "debt": 6.423,
      "gold": 28901.49033,
      "pb": 3.26,
      "pe": 22.98,
      "yield": 1.28,
      "date": "2017-01-25"
  },
  "2017-01-26": {
      "equity": 8602.7,
      "debt": 6.423,
      "gold": 28614.51525,
      "pb": 3.26,
      "pe": 22.98,
      "yield": 1.28,
      "date": "2017-01-26"
  },
  "2017-01-27": {
      "equity": 8641.2,
      "debt": 6.398,
      "gold": 28456.63945,
      "pb": 3.28,
      "pe": 23,
      "yield": 1.28,
      "date": "2017-01-27"
  },
  "2017-01-28": {
      "equity": 8641.2,
      "debt": 6.398,
      "gold": 28456.63945,
      "pb": 3.28,
      "pe": 23,
      "yield": 1.28,
      "date": "2017-01-28"
  },
  "2017-01-29": {
      "equity": 8641.2,
      "debt": 6.398,
      "gold": 28456.63945,
      "pb": 3.28,
      "pe": 23,
      "yield": 1.28,
      "date": "2017-01-29"
  },
  "2017-01-30": {
      "equity": 8632.7,
      "debt": 6.405,
      "gold": 28452.31133,
      "pb": 3.27,
      "pe": 22.98,
      "yield": 1.28,
      "date": "2017-01-30"
  },
  "2017-01-31": {
      "equity": 8561.3,
      "debt": 6.407,
      "gold": 28550.29542,
      "pb": 3.25,
      "pe": 22.86,
      "yield": 1.29,
      "date": "2017-01-31"
  },
  "2017-02-01": {
      "equity": 8716.4,
      "debt": 6.426,
      "gold": 28763.55139,
      "pb": 3.3,
      "pe": 23.27,
      "yield": 1.27,
      "date": "2017-02-01"
  },
  "2017-02-02": {
      "equity": 8734.2,
      "debt": 6.4,
      "gold": 29021.07621,
      "pb": 3.31,
      "pe": 23.38,
      "yield": 1.26,
      "date": "2017-02-02"
  },
  "2017-02-03": {
      "equity": 8740.9,
      "debt": 6.408,
      "gold": 28721.68116,
      "pb": 3.31,
      "pe": 23.29,
      "yield": 1.26,
      "date": "2017-02-03"
  },
  "2017-02-04": {
      "equity": 8740.9,
      "debt": 6.408,
      "gold": 28721.68116,
      "pb": 3.31,
      "pe": 23.29,
      "yield": 1.26,
      "date": "2017-02-04"
  },
  "2017-02-05": {
      "equity": 8740.9,
      "debt": 6.408,
      "gold": 28721.68116,
      "pb": 3.31,
      "pe": 23.29,
      "yield": 1.26,
      "date": "2017-02-05"
  },
  "2017-02-06": {
      "equity": 8801,
      "debt": 6.412,
      "gold": 28969.81957,
      "pb": 3.34,
      "pe": 23.43,
      "yield": 1.25,
      "date": "2017-02-06"
  },
  "2017-02-07": {
      "equity": 8768.3,
      "debt": 6.431,
      "gold": 29263.36267,
      "pb": 3.32,
      "pe": 23.31,
      "yield": 1.26,
      "date": "2017-02-07"
  },
  "2017-02-08": {
      "equity": 8769,
      "debt": 6.747,
      "gold": 29208.18004,
      "pb": 3.32,
      "pe": 23.28,
      "yield": 1.26,
      "date": "2017-02-08"
  },
  "2017-02-09": {
      "equity": 8778.4,
      "debt": 6.855,
      "gold": 29232.31803,
      "pb": 3.33,
      "pe": 23.31,
      "yield": 1.26,
      "date": "2017-02-09"
  },
  "2017-02-10": {
      "equity": 8793.5,
      "debt": 6.805,
      "gold": 28910.48167,
      "pb": 3.33,
      "pe": 23.34,
      "yield": 1.26,
      "date": "2017-02-10"
  },
  "2017-02-11": {
      "equity": 8793.5,
      "debt": 6.805,
      "gold": 28910.48167,
      "pb": 3.33,
      "pe": 23.34,
      "yield": 1.26,
      "date": "2017-02-11"
  },
  "2017-02-12": {
      "equity": 8793.5,
      "debt": 6.805,
      "gold": 28910.48167,
      "pb": 3.33,
      "pe": 23.34,
      "yield": 1.26,
      "date": "2017-02-12"
  },
  "2017-02-13": {
      "equity": 8805,
      "debt": 6.828,
      "gold": 29056.19852,
      "pb": 3.34,
      "pe": 23.15,
      "yield": 1.25,
      "date": "2017-02-13"
  },
  "2017-02-14": {
      "equity": 8792.3,
      "debt": 6.875,
      "gold": 29015.13254,
      "pb": 3.33,
      "pe": 23.05,
      "yield": 1.26,
      "date": "2017-02-14"
  },
  "2017-02-15": {
      "equity": 8724.7,
      "debt": 6.862,
      "gold": 28904.62971,
      "pb": 3.31,
      "pe": 22.84,
      "yield": 1.27,
      "date": "2017-02-15"
  },
  "2017-02-16": {
      "equity": 8778,
      "debt": 6.842,
      "gold": 29296.00522,
      "pb": 3.33,
      "pe": 23.08,
      "yield": 1.26,
      "date": "2017-02-16"
  },
  "2017-02-17": {
      "equity": 8821.7,
      "debt": 6.849,
      "gold": 29377.0331,
      "pb": 3.34,
      "pe": 23.1,
      "yield": 1.25,
      "date": "2017-02-17"
  },
  "2017-02-18": {
      "equity": 8821.7,
      "debt": 6.849,
      "gold": 29377.0331,
      "pb": 3.34,
      "pe": 23.1,
      "yield": 1.25,
      "date": "2017-02-18"
  },
  "2017-02-19": {
      "equity": 8821.7,
      "debt": 6.849,
      "gold": 29377.0331,
      "pb": 3.34,
      "pe": 23.1,
      "yield": 1.25,
      "date": "2017-02-19"
  },
  "2017-02-20": {
      "equity": 8879.2,
      "debt": 6.901,
      "gold": 29173.94663,
      "pb": 3.37,
      "pe": 23.19,
      "yield": 1.24,
      "date": "2017-02-20"
  },
  "2017-02-21": {
      "equity": 8907.8,
      "debt": 6.901,
      "gold": 29004.98421,
      "pb": 3.38,
      "pe": 23.27,
      "yield": 1.24,
      "date": "2017-02-21"
  },
  "2017-02-22": {
      "equity": 8926.9,
      "debt": 6.935,
      "gold": 29242.22649,
      "pb": 3.38,
      "pe": 23.32,
      "yield": 1.24,
      "date": "2017-02-22"
  },
  "2017-02-23": {
      "equity": 8939.5,
      "debt": 6.915,
      "gold": 29079.24302,
      "pb": 3.39,
      "pe": 23.36,
      "yield": 1.24,
      "date": "2017-02-23"
  },
  "2017-02-24": {
      "equity": 8939.5,
      "debt": 6.915,
      "gold": 29502.26283,
      "pb": 3.39,
      "pe": 23.36,
      "yield": 1.24,
      "date": "2017-02-24"
  },
  "2017-02-25": {
      "equity": 8939.5,
      "debt": 6.915,
      "gold": 29502.26283,
      "pb": 3.39,
      "pe": 23.36,
      "yield": 1.24,
      "date": "2017-02-25"
  },
  "2017-02-26": {
      "equity": 8939.5,
      "debt": 6.915,
      "gold": 29502.26283,
      "pb": 3.39,
      "pe": 23.36,
      "yield": 1.24,
      "date": "2017-02-26"
  },
  "2017-02-27": {
      "equity": 8896.7,
      "debt": 6.88,
      "gold": 29564.27097,
      "pb": 3.37,
      "pe": 23.21,
      "yield": 1.25,
      "date": "2017-02-27"
  },
  "2017-02-28": {
      "equity": 8879.6,
      "debt": 6.87,
      "gold": 29451.52119,
      "pb": 3.37,
      "pe": 23.13,
      "yield": 1.25,
      "date": "2017-02-28"
  },
  "2017-03-01": {
      "equity": 8945.8,
      "debt": 6.928,
      "gold": 29325.54719,
      "pb": 3.39,
      "pe": 23.3,
      "yield": 1.24,
      "date": "2017-03-01"
  },
  "2017-03-02": {
      "equity": 8899.7,
      "debt": 6.831,
      "gold": 29305.99834,
      "pb": 3.37,
      "pe": 23.11,
      "yield": 1.25,
      "date": "2017-03-02"
  },
  "2017-03-03": {
      "equity": 8897.5,
      "debt": 6.771,
      "gold": 28941.1912,
      "pb": 3.37,
      "pe": 23.1,
      "yield": 1.25,
      "date": "2017-03-03"
  },
  "2017-03-04": {
      "equity": 8897.5,
      "debt": 6.771,
      "gold": 28941.1912,
      "pb": 3.37,
      "pe": 23.1,
      "yield": 1.25,
      "date": "2017-03-04"
  },
  "2017-03-05": {
      "equity": 8897.5,
      "debt": 6.771,
      "gold": 28941.1912,
      "pb": 3.37,
      "pe": 23.1,
      "yield": 1.25,
      "date": "2017-03-05"
  },
  "2017-03-06": {
      "equity": 8963.4,
      "debt": 6.867,
      "gold": 28949.90741,
      "pb": 3.4,
      "pe": 23.27,
      "yield": 1.24,
      "date": "2017-03-06"
  },
  "2017-03-07": {
      "equity": 8946.9,
      "debt": 6.832,
      "gold": 28755.73114,
      "pb": 3.39,
      "pe": 23.23,
      "yield": 1.24,
      "date": "2017-03-07"
  },
  "2017-03-08": {
      "equity": 8924.3,
      "debt": 6.864,
      "gold": 28604.0424,
      "pb": 3.38,
      "pe": 23.17,
      "yield": 1.24,
      "date": "2017-03-08"
  },
  "2017-03-09": {
      "equity": 8927,
      "debt": 6.854,
      "gold": 28322.4078,
      "pb": 3.38,
      "pe": 23.18,
      "yield": 1.24,
      "date": "2017-03-09"
  },
  "2017-03-10": {
      "equity": 8934.5,
      "debt": 6.904,
      "gold": 28091.09861,
      "pb": 3.39,
      "pe": 23.2,
      "yield": 1.24,
      "date": "2017-03-10"
  },
  "2017-03-11": {
      "equity": 8934.5,
      "debt": 6.904,
      "gold": 28091.09861,
      "pb": 3.39,
      "pe": 23.2,
      "yield": 1.24,
      "date": "2017-03-11"
  },
  "2017-03-12": {
      "equity": 8934.5,
      "debt": 6.904,
      "gold": 28091.09861,
      "pb": 3.39,
      "pe": 23.2,
      "yield": 1.24,
      "date": "2017-03-12"
  },
  "2017-03-13": {
      "equity": 8934.5,
      "debt": 6.904,
      "gold": 28355.21261,
      "pb": 3.39,
      "pe": 23.2,
      "yield": 1.24,
      "date": "2017-03-13"
  },
  "2017-03-14": {
      "equity": 9087,
      "debt": 6.9,
      "gold": 27917.12023,
      "pb": 3.44,
      "pe": 23.59,
      "yield": 1.22,
      "date": "2017-03-14"
  },
  "2017-03-15": {
      "equity": 9084.8,
      "debt": 6.826,
      "gold": 27794.30678,
      "pb": 3.44,
      "pe": 23.59,
      "yield": 1.22,
      "date": "2017-03-15"
  },
  "2017-03-16": {
      "equity": 9153.7,
      "debt": 6.842,
      "gold": 28269.83897,
      "pb": 3.47,
      "pe": 23.77,
      "yield": 1.21,
      "date": "2017-03-16"
  },
  "2017-03-17": {
      "equity": 9160,
      "debt": 6.86,
      "gold": 28398.27863,
      "pb": 3.47,
      "pe": 23.78,
      "yield": 1.21,
      "date": "2017-03-17"
  },
  "2017-03-18": {
      "equity": 9160,
      "debt": 6.859,
      "gold": 28398.27863,
      "pb": 3.47,
      "pe": 23.78,
      "yield": 1.21,
      "date": "2017-03-18"
  },
  "2017-03-19": {
      "equity": 9160,
      "debt": 6.859,
      "gold": 28398.27863,
      "pb": 3.47,
      "pe": 23.78,
      "yield": 1.21,
      "date": "2017-03-19"
  },
  "2017-03-20": {
      "equity": 9126.8,
      "debt": 6.895,
      "gold": 28402.99476,
      "pb": 3.46,
      "pe": 23.7,
      "yield": 1.22,
      "date": "2017-03-20"
  },
  "2017-03-21": {
      "equity": 9121.5,
      "debt": 6.886,
      "gold": 28469.9836,
      "pb": 3.46,
      "pe": 23.68,
      "yield": 1.22,
      "date": "2017-03-21"
  },
  "2017-03-22": {
      "equity": 9030.4,
      "debt": 6.811,
      "gold": 28752.14378,
      "pb": 3.42,
      "pe": 23.45,
      "yield": 1.23,
      "date": "2017-03-22"
  },
  "2017-03-23": {
      "equity": 9086.3,
      "debt": 6.83,
      "gold": 28802.47976,
      "pb": 3.44,
      "pe": 23.59,
      "yield": 1.22,
      "date": "2017-03-23"
  },
  "2017-03-24": {
      "equity": 9108,
      "debt": 6.828,
      "gold": 28700.26632,
      "pb": 3.45,
      "pe": 23.65,
      "yield": 1.22,
      "date": "2017-03-24"
  },
  "2017-03-25": {
      "equity": 9108,
      "debt": 6.828,
      "gold": 28700.26632,
      "pb": 3.45,
      "pe": 23.65,
      "yield": 1.22,
      "date": "2017-03-25"
  },
  "2017-03-26": {
      "equity": 9108,
      "debt": 6.831,
      "gold": 28700.26632,
      "pb": 3.45,
      "pe": 23.65,
      "yield": 1.22,
      "date": "2017-03-26"
  },
  "2017-03-27": {
      "equity": 9045.2,
      "debt": 6.708,
      "gold": 28851.5741,
      "pb": 3.43,
      "pe": 23.49,
      "yield": 1.23,
      "date": "2017-03-27"
  },
  "2017-03-28": {
      "equity": 9100.8,
      "debt": 6.708,
      "gold": 28760.12275,
      "pb": 3.45,
      "pe": 23.63,
      "yield": 1.22,
      "date": "2017-03-28"
  },
  "2017-03-29": {
      "equity": 9143.8,
      "debt": 6.749,
      "gold": 28663.41205,
      "pb": 3.47,
      "pe": 23.74,
      "yield": 1.21,
      "date": "2017-03-29"
  },
  "2017-03-30": {
      "equity": 9173.7,
      "debt": 6.687,
      "gold": 28585.88688,
      "pb": 3.48,
      "pe": 23.82,
      "yield": 1.21,
      "date": "2017-03-30"
  },
  "2017-03-31": {
      "equity": 9173.7,
      "debt": 6.658,
      "gold": 28410.24709,
      "pb": 3.5,
      "pe": 23.26,
      "yield": 1.25,
      "date": "2017-03-31"
  },
  "2017-04-01": {
      "equity": 9173.7,
      "debt": 6.658,
      "gold": 28410.24709,
      "pb": 3.5,
      "pe": 23.26,
      "yield": 1.25,
      "date": "2017-04-01"
  },
  "2017-04-02": {
      "equity": 9173.7,
      "debt": 6.658,
      "gold": 28410.24709,
      "pb": 3.5,
      "pe": 23.26,
      "yield": 1.25,
      "date": "2017-04-02"
  },
  "2017-04-03": {
      "equity": 9237.8,
      "debt": 6.648,
      "gold": 28592.20445,
      "pb": 3.53,
      "pe": 23.42,
      "yield": 1.25,
      "date": "2017-04-03"
  },
  "2017-04-04": {
      "equity": 9237.8,
      "debt": 6.648,
      "gold": 28864.57257,
      "pb": 3.53,
      "pe": 23.42,
      "yield": 1.25,
      "date": "2017-04-04"
  },
  "2017-04-05": {
      "equity": 9265.1,
      "debt": 6.651,
      "gold": 28727.60013,
      "pb": 3.54,
      "pe": 23.49,
      "yield": 1.24,
      "date": "2017-04-05"
  },
  "2017-04-06": {
      "equity": 9261.9,
      "debt": 6.768,
      "gold": 28569.28694,
      "pb": 3.53,
      "pe": 23.48,
      "yield": 1.24,
      "date": "2017-04-06"
  },
  "2017-04-07": {
      "equity": 9198.3,
      "debt": 6.819,
      "gold": 28671.48627,
      "pb": 3.51,
      "pe": 23.32,
      "yield": 1.25,
      "date": "2017-04-07"
  },
  "2017-04-08": {
      "equity": 9198.3,
      "debt": 6.819,
      "gold": 28671.48627,
      "pb": 3.51,
      "pe": 23.32,
      "yield": 1.25,
      "date": "2017-04-08"
  },
  "2017-04-09": {
      "equity": 9198.3,
      "debt": 6.819,
      "gold": 28671.48627,
      "pb": 3.51,
      "pe": 23.32,
      "yield": 1.25,
      "date": "2017-04-09"
  },
  "2017-04-10": {
      "equity": 9181.4,
      "debt": 6.865,
      "gold": 28552.56001,
      "pb": 3.5,
      "pe": 23.28,
      "yield": 1.25,
      "date": "2017-04-10"
  },
  "2017-04-11": {
      "equity": 9237,
      "debt": 6.811,
      "gold": 28640.20882,
      "pb": 3.52,
      "pe": 23.42,
      "yield": 1.25,
      "date": "2017-04-11"
  },
  "2017-04-12": {
      "equity": 9203.4,
      "debt": 6.779,
      "gold": 29063.70483,
      "pb": 3.51,
      "pe": 23.33,
      "yield": 1.25,
      "date": "2017-04-12"
  },
  "2017-04-13": {
      "equity": 9150.8,
      "debt": 6.821,
      "gold": 29251.91979,
      "pb": 3.49,
      "pe": 23.2,
      "yield": 1.26,
      "date": "2017-04-13"
  },
  "2017-04-14": {
      "equity": 9150.8,
      "debt": 6.821,
      "gold": 29251.91979,
      "pb": 3.49,
      "pe": 23.2,
      "yield": 1.26,
      "date": "2017-04-14"
  },
  "2017-04-15": {
      "equity": 9150.8,
      "debt": 6.821,
      "gold": 29251.91979,
      "pb": 3.49,
      "pe": 23.2,
      "yield": 1.26,
      "date": "2017-04-15"
  },
  "2017-04-16": {
      "equity": 9150.8,
      "debt": 6.821,
      "gold": 29251.91979,
      "pb": 3.49,
      "pe": 23.2,
      "yield": 1.26,
      "date": "2017-04-16"
  },
  "2017-04-17": {
      "equity": 9139.3,
      "debt": 6.847,
      "gold": 29251.91979,
      "pb": 3.49,
      "pe": 23.17,
      "yield": 1.26,
      "date": "2017-04-17"
  },
  "2017-04-18": {
      "equity": 9105.1,
      "debt": 6.863,
      "gold": 29281.70162,
      "pb": 3.47,
      "pe": 23.08,
      "yield": 1.26,
      "date": "2017-04-18"
  },
  "2017-04-19": {
      "equity": 9103.5,
      "debt": 6.853,
      "gold": 29234.51207,
      "pb": 3.47,
      "pe": 23.08,
      "yield": 1.26,
      "date": "2017-04-19"
  },
  "2017-04-20": {
      "equity": 9136.4,
      "debt": 6.878,
      "gold": 29221.51361,
      "pb": 3.49,
      "pe": 23.16,
      "yield": 1.26,
      "date": "2017-04-20"
  },
  "2017-04-21": {
      "equity": 9119.4,
      "debt": 6.918,
      "gold": 29227.7571,
      "pb": 3.48,
      "pe": 23.12,
      "yield": 1.26,
      "date": "2017-04-21"
  },
  "2017-04-22": {
      "equity": 9119.4,
      "debt": 6.923,
      "gold": 29227.7571,
      "pb": 3.48,
      "pe": 23.12,
      "yield": 1.26,
      "date": "2017-04-22"
  },
  "2017-04-23": {
      "equity": 9119.4,
      "debt": 6.923,
      "gold": 29227.7571,
      "pb": 3.48,
      "pe": 23.12,
      "yield": 1.26,
      "date": "2017-04-23"
  },
  "2017-04-24": {
      "equity": 9217.9,
      "debt": 6.943,
      "gold": 28937.16644,
      "pb": 3.52,
      "pe": 23.41,
      "yield": 1.25,
      "date": "2017-04-24"
  },
  "2017-04-25": {
      "equity": 9306.6,
      "debt": 6.934,
      "gold": 28830.77656,
      "pb": 3.56,
      "pe": 23.64,
      "yield": 1.23,
      "date": "2017-04-25"
  },
  "2017-04-26": {
      "equity": 9351.8,
      "debt": 6.957,
      "gold": 28618.40244,
      "pb": 3.57,
      "pe": 23.75,
      "yield": 1.23,
      "date": "2017-04-26"
  },
  "2017-04-27": {
      "equity": 9342.1,
      "debt": 6.943,
      "gold": 28588.84636,
      "pb": 3.57,
      "pe": 23.73,
      "yield": 1.23,
      "date": "2017-04-27"
  },
  "2017-04-28": {
      "equity": 9304,
      "debt": 6.961,
      "gold": 28692.95755,
      "pb": 3.55,
      "pe": 23.63,
      "yield": 1.23,
      "date": "2017-04-28"
  },
  "2017-04-29": {
      "equity": 9304,
      "debt": 6.961,
      "gold": 28692.95755,
      "pb": 3.55,
      "pe": 23.63,
      "yield": 1.23,
      "date": "2017-04-29"
  },
  "2017-04-30": {
      "equity": 9304,
      "debt": 6.961,
      "gold": 28692.95755,
      "pb": 3.55,
      "pe": 23.63,
      "yield": 1.23,
      "date": "2017-04-30"
  },
  "2017-05-01": {
      "equity": 9304,
      "debt": 6.961,
      "gold": 28692.95755,
      "pb": 3.55,
      "pe": 23.63,
      "yield": 1.23,
      "date": "2017-05-01"
  },
  "2017-05-02": {
      "equity": 9313.8,
      "debt": 6.992,
      "gold": 28427.60543,
      "pb": 3.56,
      "pe": 23.66,
      "yield": 1.23,
      "date": "2017-05-02"
  },
  "2017-05-03": {
      "equity": 9311.9,
      "debt": 6.955,
      "gold": 28383.47061,
      "pb": 3.56,
      "pe": 23.65,
      "yield": 1.23,
      "date": "2017-05-03"
  },
  "2017-05-04": {
      "equity": 9359.9,
      "debt": 6.97,
      "gold": 28000.23281,
      "pb": 3.58,
      "pe": 23.77,
      "yield": 1.23,
      "date": "2017-05-04"
  },
  "2017-05-05": {
      "equity": 9285.3,
      "debt": 6.942,
      "gold": 28126.35143,
      "pb": 3.55,
      "pe": 23.59,
      "yield": 1.24,
      "date": "2017-05-05"
  },
  "2017-05-06": {
      "equity": 9285.3,
      "debt": 6.942,
      "gold": 28126.35143,
      "pb": 3.55,
      "pe": 23.59,
      "yield": 1.24,
      "date": "2017-05-06"
  },
  "2017-05-07": {
      "equity": 9285.3,
      "debt": 6.943,
      "gold": 28126.35143,
      "pb": 3.55,
      "pe": 23.59,
      "yield": 1.24,
      "date": "2017-05-07"
  },
  "2017-05-08": {
      "equity": 9314,
      "debt": 6.928,
      "gold": 27923.18383,
      "pb": 3.56,
      "pe": 23.66,
      "yield": 1.23,
      "date": "2017-05-08"
  },
  "2017-05-09": {
      "equity": 9316.8,
      "debt": 6.94,
      "gold": 27949.47001,
      "pb": 3.56,
      "pe": 23.67,
      "yield": 1.23,
      "date": "2017-05-09"
  },
  "2017-05-10": {
      "equity": 9407.3,
      "debt": 6.94,
      "gold": 27901.5256,
      "pb": 3.59,
      "pe": 23.89,
      "yield": 1.22,
      "date": "2017-05-10"
  },
  "2017-05-11": {
      "equity": 9422.4,
      "debt": 6.941,
      "gold": 27735.83661,
      "pb": 3.6,
      "pe": 23.93,
      "yield": 1.22,
      "date": "2017-05-11"
  },
  "2017-05-12": {
      "equity": 9400.9,
      "debt": 6.905,
      "gold": 27786.76167,
      "pb": 3.59,
      "pe": 23.88,
      "yield": 1.22,
      "date": "2017-05-12"
  },
  "2017-05-13": {
      "equity": 9400.9,
      "debt": 6.905,
      "gold": 27786.76167,
      "pb": 3.59,
      "pe": 23.88,
      "yield": 1.22,
      "date": "2017-05-13"
  },
  "2017-05-14": {
      "equity": 9400.9,
      "debt": 6.905,
      "gold": 27786.76167,
      "pb": 3.59,
      "pe": 23.88,
      "yield": 1.22,
      "date": "2017-05-14"
  },
  "2017-05-15": {
      "equity": 9445.4,
      "debt": 6.806,
      "gold": 27812.45172,
      "pb": 3.61,
      "pe": 23.99,
      "yield": 1.22,
      "date": "2017-05-15"
  },
  "2017-05-16": {
      "equity": 9512.2,
      "debt": 6.841,
      "gold": 27878.661,
      "pb": 3.63,
      "pe": 24.16,
      "yield": 1.21,
      "date": "2017-05-16"
  },
  "2017-05-17": {
      "equity": 9525.7,
      "debt": 6.843,
      "gold": 28152.20727,
      "pb": 3.64,
      "pe": 25.23,
      "yield": 1.2,
      "date": "2017-05-17"
  },
  "2017-05-18": {
      "equity": 9429.4,
      "debt": 6.841,
      "gold": 28902.5556,
      "pb": 3.6,
      "pe": 24.91,
      "yield": 1.22,
      "date": "2017-05-18"
  },
  "2017-05-19": {
      "equity": 9427.9,
      "debt": 6.854,
      "gold": 28508.24177,
      "pb": 3.6,
      "pe": 24.91,
      "yield": 1.22,
      "date": "2017-05-19"
  },
  "2017-05-20": {
      "equity": 9427.9,
      "debt": 6.854,
      "gold": 28508.24177,
      "pb": 3.6,
      "pe": 24.91,
      "yield": 1.22,
      "date": "2017-05-20"
  },
  "2017-05-21": {
      "equity": 9427.9,
      "debt": 6.854,
      "gold": 28508.24177,
      "pb": 3.6,
      "pe": 24.91,
      "yield": 1.22,
      "date": "2017-05-21"
  },
  "2017-05-22": {
      "equity": 9438.2,
      "debt": 6.767,
      "gold": 28574.64153,
      "pb": 3.61,
      "pe": 24.94,
      "yield": 1.22,
      "date": "2017-05-22"
  },
  "2017-05-23": {
      "equity": 9386.1,
      "debt": 6.789,
      "gold": 28842.17358,
      "pb": 3.59,
      "pe": 24.8,
      "yield": 1.22,
      "date": "2017-05-23"
  },
  "2017-05-24": {
      "equity": 9360.5,
      "debt": 6.797,
      "gold": 28591.71061,
      "pb": 3.58,
      "pe": 24.73,
      "yield": 1.23,
      "date": "2017-05-24"
  },
  "2017-05-25": {
      "equity": 9509.7,
      "debt": 6.791,
      "gold": 28629.57019,
      "pb": 3.63,
      "pe": 24.88,
      "yield": 1.21,
      "date": "2017-05-25"
  },
  "2017-05-26": {
      "equity": 9595.1,
      "debt": 6.787,
      "gold": 28814.39884,
      "pb": 3.62,
      "pe": 24.32,
      "yield": 1.19,
      "date": "2017-05-26"
  },
  "2017-05-27": {
      "equity": 9595.1,
      "debt": 6.787,
      "gold": 28814.39884,
      "pb": 3.62,
      "pe": 24.32,
      "yield": 1.19,
      "date": "2017-05-27"
  },
  "2017-05-28": {
      "equity": 9595.1,
      "debt": 6.787,
      "gold": 28814.39884,
      "pb": 3.62,
      "pe": 24.32,
      "yield": 1.19,
      "date": "2017-05-28"
  },
  "2017-05-29": {
      "equity": 9604.9,
      "debt": 6.668,
      "gold": 28814.39884,
      "pb": 3.62,
      "pe": 24.31,
      "yield": 1.19,
      "date": "2017-05-29"
  },
  "2017-05-30": {
      "equity": 9624.5,
      "debt": 6.661,
      "gold": 28758.94107,
      "pb": 3.63,
      "pe": 24.36,
      "yield": 1.19,
      "date": "2017-05-30"
  },
  "2017-05-31": {
      "equity": 9621.2,
      "debt": 6.661,
      "gold": 28759.86878,
      "pb": 3.62,
      "pe": 24.35,
      "yield": 1.19,
      "date": "2017-05-31"
  },
  "2017-06-01": {
      "equity": 9616.1,
      "debt": 6.623,
      "gold": 28760.24621,
      "pb": 3.62,
      "pe": 24.34,
      "yield": 1.19,
      "date": "2017-06-01"
  },
  "2017-06-02": {
      "equity": 9653.5,
      "debt": 6.624,
      "gold": 28588.7088,
      "pb": 3.63,
      "pe": 24.43,
      "yield": 1.18,
      "date": "2017-06-02"
  },
  "2017-06-03": {
      "equity": 9653.5,
      "debt": 6.624,
      "gold": 28588.7088,
      "pb": 3.63,
      "pe": 24.43,
      "yield": 1.18,
      "date": "2017-06-03"
  },
  "2017-06-04": {
      "equity": 9653.5,
      "debt": 6.624,
      "gold": 28588.7088,
      "pb": 3.63,
      "pe": 24.43,
      "yield": 1.18,
      "date": "2017-06-04"
  },
  "2017-06-05": {
      "equity": 9675.1,
      "debt": 6.646,
      "gold": 29073.53216,
      "pb": 3.64,
      "pe": 24.45,
      "yield": 1.18,
      "date": "2017-06-05"
  },
  "2017-06-06": {
      "equity": 9637.1,
      "debt": 6.635,
      "gold": 29272.50569,
      "pb": 3.63,
      "pe": 24.35,
      "yield": 1.19,
      "date": "2017-06-06"
  },
  "2017-06-07": {
      "equity": 9663.9,
      "debt": 6.562,
      "gold": 29387.62588,
      "pb": 3.64,
      "pe": 24.48,
      "yield": 1.18,
      "date": "2017-06-07"
  },
  "2017-06-08": {
      "equity": 9647.2,
      "debt": 6.532,
      "gold": 29123.14503,
      "pb": 3.63,
      "pe": 24.43,
      "yield": 1.18,
      "date": "2017-06-08"
  },
  "2017-06-09": {
      "equity": 9668.2,
      "debt": 6.501,
      "gold": 28930.70425,
      "pb": 3.64,
      "pe": 24.38,
      "yield": 1.18,
      "date": "2017-06-09"
  },
  "2017-06-10": {
      "equity": 9668.2,
      "debt": 6.501,
      "gold": 28930.70425,
      "pb": 3.64,
      "pe": 24.38,
      "yield": 1.18,
      "date": "2017-06-10"
  },
  "2017-06-11": {
      "equity": 9668.2,
      "debt": 6.501,
      "gold": 28930.70425,
      "pb": 3.64,
      "pe": 24.38,
      "yield": 1.18,
      "date": "2017-06-11"
  },
  "2017-06-12": {
      "equity": 9616.4,
      "debt": 6.515,
      "gold": 28872.69969,
      "pb": 3.62,
      "pe": 24.25,
      "yield": 1.19,
      "date": "2017-06-12"
  },
  "2017-06-13": {
      "equity": 9606.9,
      "debt": 6.492,
      "gold": 28639.75731,
      "pb": 3.62,
      "pe": 24.27,
      "yield": 1.19,
      "date": "2017-06-13"
  },
  "2017-06-14": {
      "equity": 9618.1,
      "debt": 6.467,
      "gold": 28664.74541,
      "pb": 3.62,
      "pe": 24.29,
      "yield": 1.19,
      "date": "2017-06-14"
  },
  "2017-06-15": {
      "equity": 9578,
      "debt": 6.479,
      "gold": 28723.49424,
      "pb": 3.6,
      "pe": 24.19,
      "yield": 1.19,
      "date": "2017-06-15"
  },
  "2017-06-16": {
      "equity": 9588,
      "debt": 6.49,
      "gold": 28570.53564,
      "pb": 3.56,
      "pe": 24,
      "yield": 1.11,
      "date": "2017-06-16"
  },
  "2017-06-17": {
      "equity": 9588,
      "debt": 6.49,
      "gold": 28570.53564,
      "pb": 3.56,
      "pe": 24,
      "yield": 1.11,
      "date": "2017-06-17"
  },
  "2017-06-18": {
      "equity": 9588,
      "debt": 6.49,
      "gold": 28570.53564,
      "pb": 3.56,
      "pe": 24,
      "yield": 1.11,
      "date": "2017-06-18"
  },
  "2017-06-19": {
      "equity": 9657.5,
      "debt": 6.465,
      "gold": 28455.85636,
      "pb": 3.58,
      "pe": 24.37,
      "yield": 1.1,
      "date": "2017-06-19"
  },
  "2017-06-20": {
      "equity": 9653.5,
      "debt": 6.445,
      "gold": 28426.85762,
      "pb": 3.58,
      "pe": 24.36,
      "yield": 1.1,
      "date": "2017-06-20"
  },
  "2017-06-21": {
      "equity": 9633.6,
      "debt": 6.43,
      "gold": 28410.14833,
      "pb": 3.57,
      "pe": 24.31,
      "yield": 1.11,
      "date": "2017-06-21"
  },
  "2017-06-22": {
      "equity": 9630,
      "debt": 6.453,
      "gold": 28519.9739,
      "pb": 3.57,
      "pe": 24.49,
      "yield": 1.11,
      "date": "2017-06-22"
  },
  "2017-06-23": {
      "equity": 9574.9,
      "debt": 6.459,
      "gold": 28571.86899,
      "pb": 3.54,
      "pe": 24.37,
      "yield": 1.11,
      "date": "2017-06-23"
  },
  "2017-06-24": {
      "equity": 9574.9,
      "debt": 6.459,
      "gold": 28571.86899,
      "pb": 3.54,
      "pe": 24.37,
      "yield": 1.11,
      "date": "2017-06-24"
  },
  "2017-06-25": {
      "equity": 9574.9,
      "debt": 6.46,
      "gold": 28571.86899,
      "pb": 3.54,
      "pe": 24.37,
      "yield": 1.11,
      "date": "2017-06-25"
  },
  "2017-06-26": {
      "equity": 9574.9,
      "debt": 6.46,
      "gold": 28232.48382,
      "pb": 3.54,
      "pe": 24.37,
      "yield": 1.11,
      "date": "2017-06-26"
  },
  "2017-06-27": {
      "equity": 9511.4,
      "debt": 6.463,
      "gold": 28437.06591,
      "pb": 3.52,
      "pe": 24.21,
      "yield": 1.12,
      "date": "2017-06-27"
  },
  "2017-06-28": {
      "equity": 9491.2,
      "debt": 6.497,
      "gold": 28478.22007,
      "pb": 3.51,
      "pe": 24.15,
      "yield": 1.12,
      "date": "2017-06-28"
  },
  "2017-06-29": {
      "equity": 9504.1,
      "debt": 6.507,
      "gold": 28493.64539,
      "pb": 3.52,
      "pe": 24.19,
      "yield": 1.12,
      "date": "2017-06-29"
  },
  "2017-06-30": {
      "equity": 9520.9,
      "debt": 6.511,
      "gold": 28338.66206,
      "pb": 3.52,
      "pe": 24.23,
      "yield": 1.12,
      "date": "2017-06-30"
  },
  "2017-07-01": {
      "equity": 9520.9,
      "debt": 6.511,
      "gold": 28338.66206,
      "pb": 3.52,
      "pe": 24.23,
      "yield": 1.12,
      "date": "2017-07-01"
  },
  "2017-07-02": {
      "equity": 9520.9,
      "debt": 6.511,
      "gold": 28338.66206,
      "pb": 3.52,
      "pe": 24.23,
      "yield": 1.12,
      "date": "2017-07-02"
  },
  "2017-07-03": {
      "equity": 9615,
      "debt": 6.552,
      "gold": 28253.33427,
      "pb": 3.56,
      "pe": 24.47,
      "yield": 1.11,
      "date": "2017-07-03"
  },
  "2017-07-04": {
      "equity": 9613.3,
      "debt": 6.548,
      "gold": 27963.48436,
      "pb": 3.56,
      "pe": 24.47,
      "yield": 1.11,
      "date": "2017-07-04"
  },
  "2017-07-05": {
      "equity": 9637.6,
      "debt": 6.55,
      "gold": 27917.56821,
      "pb": 3.57,
      "pe": 24.53,
      "yield": 1.11,
      "date": "2017-07-05"
  },
  "2017-07-06": {
      "equity": 9674.5,
      "debt": 6.536,
      "gold": 27952.10498,
      "pb": 3.58,
      "pe": 24.62,
      "yield": 1.1,
      "date": "2017-07-06"
  },
  "2017-07-07": {
      "equity": 9665.8,
      "debt": 6.529,
      "gold": 27824.31789,
      "pb": 3.58,
      "pe": 24.6,
      "yield": 1.1,
      "date": "2017-07-07"
  },
  "2017-07-08": {
      "equity": 9665.8,
      "debt": 6.529,
      "gold": 27824.31789,
      "pb": 3.58,
      "pe": 24.6,
      "yield": 1.1,
      "date": "2017-07-08"
  },
  "2017-07-09": {
      "equity": 9665.8,
      "debt": 6.529,
      "gold": 27824.31789,
      "pb": 3.58,
      "pe": 24.6,
      "yield": 1.1,
      "date": "2017-07-09"
  },
  "2017-07-10": {
      "equity": 9771,
      "debt": 6.468,
      "gold": 27463.15455,
      "pb": 3.62,
      "pe": 24.87,
      "yield": 1.09,
      "date": "2017-07-10"
  },
  "2017-07-11": {
      "equity": 9786,
      "debt": 6.484,
      "gold": 27591.36845,
      "pb": 3.62,
      "pe": 24.91,
      "yield": 1.09,
      "date": "2017-07-11"
  },
  "2017-07-12": {
      "equity": 9816.1,
      "debt": 6.459,
      "gold": 27706.89077,
      "pb": 3.63,
      "pe": 24.98,
      "yield": 1.09,
      "date": "2017-07-12"
  },
  "2017-07-13": {
      "equity": 9891.7,
      "debt": 6.456,
      "gold": 27771.63618,
      "pb": 3.66,
      "pe": 25.17,
      "yield": 1.08,
      "date": "2017-07-13"
  },
  "2017-07-14": {
      "equity": 9886.3,
      "debt": 6.463,
      "gold": 27640.98485,
      "pb": 3.66,
      "pe": 25.12,
      "yield": 1.08,
      "date": "2017-07-14"
  },
  "2017-07-15": {
      "equity": 9886.3,
      "debt": 6.463,
      "gold": 27640.98485,
      "pb": 3.66,
      "pe": 25.12,
      "yield": 1.08,
      "date": "2017-07-15"
  },
  "2017-07-16": {
      "equity": 9886.3,
      "debt": 6.463,
      "gold": 27640.98485,
      "pb": 3.66,
      "pe": 25.12,
      "yield": 1.08,
      "date": "2017-07-16"
  },
  "2017-07-17": {
      "equity": 9915.9,
      "debt": 6.456,
      "gold": 27917.65287,
      "pb": 3.67,
      "pe": 25.17,
      "yield": 1.08,
      "date": "2017-07-17"
  },
  "2017-07-18": {
      "equity": 9827.1,
      "debt": 6.455,
      "gold": 28060.62894,
      "pb": 3.56,
      "pe": 25.1,
      "yield": 1.06,
      "date": "2017-07-18"
  },
  "2017-07-19": {
      "equity": 9899.6,
      "debt": 6.449,
      "gold": 28105.99129,
      "pb": 3.53,
      "pe": 25.28,
      "yield": 1.01,
      "date": "2017-07-19"
  },
  "2017-07-20": {
      "equity": 9873.3,
      "debt": 6.453,
      "gold": 28070.39983,
      "pb": 3.52,
      "pe": 25.22,
      "yield": 1.01,
      "date": "2017-07-20"
  },
  "2017-07-21": {
      "equity": 9915.2,
      "debt": 6.436,
      "gold": 28328.69363,
      "pb": 3.53,
      "pe": 25.32,
      "yield": 1.01,
      "date": "2017-07-21"
  },
  "2017-07-22": {
      "equity": 9915.2,
      "debt": 6.436,
      "gold": 28328.69363,
      "pb": 3.53,
      "pe": 25.32,
      "yield": 1.01,
      "date": "2017-07-22"
  },
  "2017-07-23": {
      "equity": 9915.2,
      "debt": 6.436,
      "gold": 28328.69363,
      "pb": 3.53,
      "pe": 25.32,
      "yield": 1.01,
      "date": "2017-07-23"
  },
  "2017-07-24": {
      "equity": 9966.4,
      "debt": 6.414,
      "gold": 28526.28441,
      "pb": 3.55,
      "pe": 25.45,
      "yield": 1,
      "date": "2017-07-24"
  },
  "2017-07-25": {
      "equity": 9964.5,
      "debt": 6.431,
      "gold": 28458.66065,
      "pb": 3.55,
      "pe": 25.45,
      "yield": 1,
      "date": "2017-07-25"
  },
  "2017-07-26": {
      "equity": 10020,
      "debt": 6.446,
      "gold": 28260.36085,
      "pb": 3.57,
      "pe": 25.57,
      "yield": 1,
      "date": "2017-07-26"
  },
  "2017-07-27": {
      "equity": 10020,
      "debt": 6.44,
      "gold": 28566.90594,
      "pb": 3.57,
      "pe": 25.56,
      "yield": 1,
      "date": "2017-07-27"
  },
  "2017-07-28": {
      "equity": 10014,
      "debt": 6.462,
      "gold": 28487.05621,
      "pb": 3.49,
      "pe": 25.56,
      "yield": 0.96,
      "date": "2017-07-28"
  },
  "2017-07-29": {
      "equity": 10014,
      "debt": 6.462,
      "gold": 28487.05621,
      "pb": 3.49,
      "pe": 25.56,
      "yield": 0.96,
      "date": "2017-07-29"
  },
  "2017-07-30": {
      "equity": 10014,
      "debt": 6.462,
      "gold": 28487.05621,
      "pb": 3.49,
      "pe": 25.56,
      "yield": 0.96,
      "date": "2017-07-30"
  },
  "2017-07-31": {
      "equity": 10077,
      "debt": 6.465,
      "gold": 28666.24808,
      "pb": 3.51,
      "pe": 25.69,
      "yield": 0.95,
      "date": "2017-07-31"
  },
  "2017-08-01": {
      "equity": 10114,
      "debt": 6.44,
      "gold": 28646.56167,
      "pb": 3.52,
      "pe": 25.75,
      "yield": 0.95,
      "date": "2017-08-01"
  },
  "2017-08-02": {
      "equity": 10081,
      "debt": 6.46,
      "gold": 28431.98998,
      "pb": 3.51,
      "pe": 25.63,
      "yield": 0.95,
      "date": "2017-08-02"
  },
  "2017-08-03": {
      "equity": 10013,
      "debt": 6.429,
      "gold": 28354.54946,
      "pb": 3.49,
      "pe": 25.5,
      "yield": 0.96,
      "date": "2017-08-03"
  },
  "2017-08-04": {
      "equity": 10066,
      "debt": 6.438,
      "gold": 28541.84377,
      "pb": 3.56,
      "pe": 25.61,
      "yield": 0.96,
      "date": "2017-08-04"
  },
  "2017-08-05": {
      "equity": 10066,
      "debt": 6.438,
      "gold": 28541.84377,
      "pb": 3.56,
      "pe": 25.61,
      "yield": 0.96,
      "date": "2017-08-05"
  },
  "2017-08-06": {
      "equity": 10066,
      "debt": 6.441,
      "gold": 28541.84377,
      "pb": 3.56,
      "pe": 25.61,
      "yield": 0.96,
      "date": "2017-08-06"
  },
  "2017-08-07": {
      "equity": 10057,
      "debt": 6.457,
      "gold": 28319.77284,
      "pb": 3.56,
      "pe": 25.67,
      "yield": 0.96,
      "date": "2017-08-07"
  },
  "2017-08-08": {
      "equity": 9978.5,
      "debt": 6.457,
      "gold": 28340.40812,
      "pb": 3.53,
      "pe": 25.5,
      "yield": 0.97,
      "date": "2017-08-08"
  },
  "2017-08-09": {
      "equity": 9908,
      "debt": 6.465,
      "gold": 28586.40188,
      "pb": 3.51,
      "pe": 25.31,
      "yield": 0.97,
      "date": "2017-08-09"
  },
  "2017-08-10": {
      "equity": 9820.2,
      "debt": 6.494,
      "gold": 28923.45544,
      "pb": 3.47,
      "pe": 25.06,
      "yield": 0.98,
      "date": "2017-08-10"
  },
  "2017-08-11": {
      "equity": 9710.8,
      "debt": 6.502,
      "gold": 29114.5029,
      "pb": 3.43,
      "pe": 24.8,
      "yield": 0.99,
      "date": "2017-08-11"
  },
  "2017-08-12": {
      "equity": 9710.8,
      "debt": 6.502,
      "gold": 29114.5029,
      "pb": 3.43,
      "pe": 24.8,
      "yield": 0.99,
      "date": "2017-08-12"
  },
  "2017-08-13": {
      "equity": 9710.8,
      "debt": 6.502,
      "gold": 29114.5029,
      "pb": 3.43,
      "pe": 24.8,
      "yield": 0.99,
      "date": "2017-08-13"
  },
  "2017-08-14": {
      "equity": 9794.1,
      "debt": 6.52,
      "gold": 28952.9198,
      "pb": 3.46,
      "pe": 25.02,
      "yield": 0.98,
      "date": "2017-08-14"
  },
  "2017-08-15": {
      "equity": 9794.1,
      "debt": 6.52,
      "gold": 28824.00395,
      "pb": 3.46,
      "pe": 25.02,
      "yield": 0.98,
      "date": "2017-08-15"
  },
  "2017-08-16": {
      "equity": 9897.3,
      "debt": 6.533,
      "gold": 28756.97279,
      "pb": 3.49,
      "pe": 25.28,
      "yield": 0.97,
      "date": "2017-08-16"
  },
  "2017-08-17": {
      "equity": 9904.1,
      "debt": 6.533,
      "gold": 29094.87293,
      "pb": 3.5,
      "pe": 25.3,
      "yield": 0.97,
      "date": "2017-08-17"
  },
  "2017-08-18": {
      "equity": 9837.4,
      "debt": 6.509,
      "gold": 29279.78977,
      "pb": 3.46,
      "pe": 25.1,
      "yield": 0.97,
      "date": "2017-08-18"
  },
  "2017-08-19": {
      "equity": 9837.4,
      "debt": 6.509,
      "gold": 29279.78977,
      "pb": 3.46,
      "pe": 25.1,
      "yield": 0.97,
      "date": "2017-08-19"
  },
  "2017-08-20": {
      "equity": 9837.4,
      "debt": 6.509,
      "gold": 29279.78977,
      "pb": 3.46,
      "pe": 25.1,
      "yield": 0.97,
      "date": "2017-08-20"
  },
  "2017-08-21": {
      "equity": 9754.3,
      "debt": 6.51,
      "gold": 29147.50877,
      "pb": 3.44,
      "pe": 24.99,
      "yield": 0.97,
      "date": "2017-08-21"
  },
  "2017-08-22": {
      "equity": 9765.5,
      "debt": 6.535,
      "gold": 29044.49814,
      "pb": 3.44,
      "pe": 25.25,
      "yield": 0.97,
      "date": "2017-08-22"
  },
  "2017-08-23": {
      "equity": 9852.5,
      "debt": 6.535,
      "gold": 29062.48435,
      "pb": 3.47,
      "pe": 25.45,
      "yield": 0.96,
      "date": "2017-08-23"
  },
  "2017-08-24": {
      "equity": 9857,
      "debt": 6.538,
      "gold": 29045.06958,
      "pb": 3.46,
      "pe": 25.47,
      "yield": 0.95,
      "date": "2017-08-24"
  },
  "2017-08-25": {
      "equity": 9857,
      "debt": 6.538,
      "gold": 29068.7737,
      "pb": 3.46,
      "pe": 25.47,
      "yield": 0.95,
      "date": "2017-08-25"
  },
  "2017-08-26": {
      "equity": 9857,
      "debt": 6.538,
      "gold": 29068.7737,
      "pb": 3.46,
      "pe": 25.47,
      "yield": 0.95,
      "date": "2017-08-26"
  },
  "2017-08-27": {
      "equity": 9857,
      "debt": 6.538,
      "gold": 29068.7737,
      "pb": 3.46,
      "pe": 25.47,
      "yield": 0.95,
      "date": "2017-08-27"
  },
  "2017-08-28": {
      "equity": 9912.8,
      "debt": 6.568,
      "gold": 29068.7737,
      "pb": 3.48,
      "pe": 25.61,
      "yield": 0.94,
      "date": "2017-08-28"
  },
  "2017-08-29": {
      "equity": 9796,
      "debt": 6.533,
      "gold": 29846.5793,
      "pb": 3.44,
      "pe": 25.31,
      "yield": 0.95,
      "date": "2017-08-29"
  },
  "2017-08-30": {
      "equity": 9884.4,
      "debt": 6.536,
      "gold": 29582.63461,
      "pb": 3.47,
      "pe": 25.54,
      "yield": 0.94,
      "date": "2017-08-30"
  },
  "2017-08-31": {
      "equity": 9917.9,
      "debt": 6.525,
      "gold": 29469.68377,
      "pb": 3.49,
      "pe": 25.62,
      "yield": 0.94,
      "date": "2017-08-31"
  },
  "2017-09-01": {
      "equity": 9974.4,
      "debt": 6.483,
      "gold": 29678.47405,
      "pb": 3.51,
      "pe": 25.99,
      "yield": 0.93,
      "date": "2017-09-01"
  },
  "2017-09-02": {
      "equity": 9974.4,
      "debt": 6.483,
      "gold": 29678.47405,
      "pb": 3.51,
      "pe": 25.99,
      "yield": 0.93,
      "date": "2017-09-02"
  },
  "2017-09-03": {
      "equity": 9974.4,
      "debt": 6.483,
      "gold": 29678.47405,
      "pb": 3.51,
      "pe": 25.99,
      "yield": 0.93,
      "date": "2017-09-03"
  },
  "2017-09-04": {
      "equity": 9912.8,
      "debt": 6.496,
      "gold": 30133.77661,
      "pb": 3.48,
      "pe": 25.83,
      "yield": 0.94,
      "date": "2017-09-04"
  },
  "2017-09-05": {
      "equity": 9952.2,
      "debt": 6.497,
      "gold": 30100.48502,
      "pb": 3.5,
      "pe": 25.97,
      "yield": 0.94,
      "date": "2017-09-05"
  },
  "2017-09-06": {
      "equity": 9916.2,
      "debt": 6.507,
      "gold": 30286.74227,
      "pb": 3.49,
      "pe": 25.88,
      "yield": 0.94,
      "date": "2017-09-06"
  },
  "2017-09-07": {
      "equity": 9929.9,
      "debt": 6.515,
      "gold": 30246.94968,
      "pb": 3.49,
      "pe": 25.89,
      "yield": 0.94,
      "date": "2017-09-07"
  },
  "2017-09-08": {
      "equity": 9934.8,
      "debt": 6.543,
      "gold": 30468.45623,
      "pb": 3.47,
      "pe": 25.9,
      "yield": 0.94,
      "date": "2017-09-08"
  },
  "2017-09-09": {
      "equity": 9934.8,
      "debt": 6.543,
      "gold": 30468.45623,
      "pb": 3.47,
      "pe": 25.9,
      "yield": 0.94,
      "date": "2017-09-09"
  },
  "2017-09-10": {
      "equity": 9934.8,
      "debt": 6.543,
      "gold": 30468.45623,
      "pb": 3.47,
      "pe": 25.9,
      "yield": 0.94,
      "date": "2017-09-10"
  },
  "2017-09-11": {
      "equity": 10006,
      "debt": 6.562,
      "gold": 30190.409,
      "pb": 3.5,
      "pe": 26.11,
      "yield": 0.93,
      "date": "2017-09-11"
  },
  "2017-09-12": {
      "equity": 10093,
      "debt": 6.557,
      "gold": 29954.87751,
      "pb": 3.51,
      "pe": 26.26,
      "yield": 0.93,
      "date": "2017-09-12"
  },
  "2017-09-13": {
      "equity": 10079,
      "debt": 6.586,
      "gold": 30138.98658,
      "pb": 3.51,
      "pe": 26.23,
      "yield": 0.93,
      "date": "2017-09-13"
  },
  "2017-09-14": {
      "equity": 10086,
      "debt": 6.59,
      "gold": 29964.72954,
      "pb": 3.51,
      "pe": 26.25,
      "yield": 0.93,
      "date": "2017-09-14"
  },
  "2017-09-15": {
      "equity": 10085,
      "debt": 6.598,
      "gold": 29971.23406,
      "pb": 3.51,
      "pe": 26.24,
      "yield": 0.94,
      "date": "2017-09-15"
  },
  "2017-09-16": {
      "equity": 10085,
      "debt": 6.598,
      "gold": 29971.23406,
      "pb": 3.51,
      "pe": 26.24,
      "yield": 0.94,
      "date": "2017-09-16"
  },
  "2017-09-17": {
      "equity": 10085,
      "debt": 6.598,
      "gold": 29971.23406,
      "pb": 3.51,
      "pe": 26.24,
      "yield": 0.94,
      "date": "2017-09-17"
  },
  "2017-09-18": {
      "equity": 10153,
      "debt": 6.61,
      "gold": 29749.91799,
      "pb": 3.53,
      "pe": 26.42,
      "yield": 0.94,
      "date": "2017-09-18"
  },
  "2017-09-19": {
      "equity": 10147,
      "debt": 6.591,
      "gold": 29677.2324,
      "pb": 3.53,
      "pe": 26.4,
      "yield": 0.94,
      "date": "2017-09-19"
  },
  "2017-09-20": {
      "equity": 10141,
      "debt": 6.577,
      "gold": 29844.39584,
      "pb": 3.53,
      "pe": 26.39,
      "yield": 0.94,
      "date": "2017-09-20"
  },
  "2017-09-21": {
      "equity": 10121,
      "debt": 6.674,
      "gold": 29708.51338,
      "pb": 3.52,
      "pe": 26.34,
      "yield": 0.94,
      "date": "2017-09-21"
  },
  "2017-09-22": {
      "equity": 9964.4,
      "debt": 6.663,
      "gold": 29675.97665,
      "pb": 3.47,
      "pe": 25.95,
      "yield": 0.95,
      "date": "2017-09-22"
  },
  "2017-09-23": {
      "equity": 9964.4,
      "debt": 6.663,
      "gold": 29675.97665,
      "pb": 3.47,
      "pe": 25.95,
      "yield": 0.95,
      "date": "2017-09-23"
  },
  "2017-09-24": {
      "equity": 9964.4,
      "debt": 6.663,
      "gold": 29675.97665,
      "pb": 3.47,
      "pe": 25.95,
      "yield": 0.95,
      "date": "2017-09-24"
  },
  "2017-09-25": {
      "equity": 9872.6,
      "debt": 6.618,
      "gold": 29820.10265,
      "pb": 3.43,
      "pe": 25.71,
      "yield": 0.96,
      "date": "2017-09-25"
  },
  "2017-09-26": {
      "equity": 9871.5,
      "debt": 6.669,
      "gold": 30165.72074,
      "pb": 3.43,
      "pe": 25.71,
      "yield": 0.96,
      "date": "2017-09-26"
  },
  "2017-09-27": {
      "equity": 9735.7,
      "debt": 6.666,
      "gold": 29844.36057,
      "pb": 3.39,
      "pe": 25.36,
      "yield": 0.98,
      "date": "2017-09-27"
  },
  "2017-09-28": {
      "equity": 9768.9,
      "debt": 6.641,
      "gold": 29644.74858,
      "pb": 3.4,
      "pe": 25.44,
      "yield": 0.97,
      "date": "2017-09-28"
  },
  "2017-09-29": {
      "equity": 9788.6,
      "debt": 6.663,
      "gold": 29666.19517,
      "pb": 3.35,
      "pe": 25.43,
      "yield": 1.17,
      "date": "2017-09-29"
  },
  "2017-09-30": {
      "equity": 9788.6,
      "debt": 6.663,
      "gold": 29666.19517,
      "pb": 3.35,
      "pe": 25.43,
      "yield": 1.17,
      "date": "2017-09-30"
  },
  "2017-10-01": {
      "equity": 9788.6,
      "debt": 6.663,
      "gold": 29666.19517,
      "pb": 3.35,
      "pe": 25.43,
      "yield": 1.17,
      "date": "2017-10-01"
  },
  "2017-10-02": {
      "equity": 9788.6,
      "debt": 6.663,
      "gold": 29329.10281,
      "pb": 3.35,
      "pe": 25.43,
      "yield": 1.17,
      "date": "2017-10-02"
  },
  "2017-10-03": {
      "equity": 9859.5,
      "debt": 6.648,
      "gold": 29354.31665,
      "pb": 3.37,
      "pe": 25.61,
      "yield": 1.16,
      "date": "2017-10-03"
  },
  "2017-10-04": {
      "equity": 9914.9,
      "debt": 6.703,
      "gold": 29279.67689,
      "pb": 3.39,
      "pe": 25.75,
      "yield": 1.15,
      "date": "2017-10-04"
  },
  "2017-10-05": {
      "equity": 9888.7,
      "debt": 6.729,
      "gold": 29372.15118,
      "pb": 3.38,
      "pe": 25.69,
      "yield": 1.15,
      "date": "2017-10-05"
  },
  "2017-10-06": {
      "equity": 9979.7,
      "debt": 6.757,
      "gold": 29293.0281,
      "pb": 3.41,
      "pe": 25.92,
      "yield": 1.14,
      "date": "2017-10-06"
  },
  "2017-10-07": {
      "equity": 9979.7,
      "debt": 6.757,
      "gold": 29293.0281,
      "pb": 3.41,
      "pe": 25.92,
      "yield": 1.14,
      "date": "2017-10-07"
  },
  "2017-10-08": {
      "equity": 9979.7,
      "debt": 6.757,
      "gold": 29293.0281,
      "pb": 3.41,
      "pe": 25.92,
      "yield": 1.14,
      "date": "2017-10-08"
  },
  "2017-10-09": {
      "equity": 9988.7,
      "debt": 6.78,
      "gold": 29560.29912,
      "pb": 3.41,
      "pe": 25.95,
      "yield": 1.14,
      "date": "2017-10-09"
  },
  "2017-10-10": {
      "equity": 10016,
      "debt": 6.741,
      "gold": 29666.32568,
      "pb": 3.42,
      "pe": 26.02,
      "yield": 1.14,
      "date": "2017-10-10"
  },
  "2017-10-11": {
      "equity": 9984.8,
      "debt": 6.74,
      "gold": 29659.19328,
      "pb": 3.41,
      "pe": 25.94,
      "yield": 1.14,
      "date": "2017-10-11"
  },
  "2017-10-12": {
      "equity": 10096,
      "debt": 6.753,
      "gold": 29704.34046,
      "pb": 3.45,
      "pe": 26.22,
      "yield": 1.13,
      "date": "2017-10-12"
  },
  "2017-10-13": {
      "equity": 10167,
      "debt": 6.734,
      "gold": 29529.69188,
      "pb": 3.47,
      "pe": 26.41,
      "yield": 1.12,
      "date": "2017-10-13"
  },
  "2017-10-14": {
      "equity": 10167,
      "debt": 6.734,
      "gold": 29529.69188,
      "pb": 3.47,
      "pe": 26.41,
      "yield": 1.12,
      "date": "2017-10-14"
  },
  "2017-10-15": {
      "equity": 10167,
      "debt": 6.734,
      "gold": 29529.69188,
      "pb": 3.47,
      "pe": 26.41,
      "yield": 1.12,
      "date": "2017-10-15"
  },
  "2017-10-16": {
      "equity": 10230,
      "debt": 6.734,
      "gold": 29786.53592,
      "pb": 3.46,
      "pe": 26.55,
      "yield": 1.12,
      "date": "2017-10-16"
  },
  "2017-10-17": {
      "equity": 10234,
      "debt": 6.763,
      "gold": 29583.96444,
      "pb": 3.46,
      "pe": 26.52,
      "yield": 1.12,
      "date": "2017-10-17"
  },
  "2017-10-18": {
      "equity": 10210,
      "debt": 6.76,
      "gold": 29394.26092,
      "pb": 3.45,
      "pe": 26.44,
      "yield": 1.12,
      "date": "2017-10-18"
  },
  "2017-10-19": {
      "equity": 10146,
      "debt": 6.76,
      "gold": 29453.07677,
      "pb": 3.43,
      "pe": 26.28,
      "yield": 1.12,
      "date": "2017-10-19"
  },
  "2017-10-20": {
      "equity": 10146,
      "debt": 6.76,
      "gold": 29380.78978,
      "pb": 3.43,
      "pe": 26.28,
      "yield": 1.12,
      "date": "2017-10-20"
  },
  "2017-10-21": {
      "equity": 10146,
      "debt": 6.76,
      "gold": 29380.78978,
      "pb": 3.43,
      "pe": 26.28,
      "yield": 1.12,
      "date": "2017-10-21"
  },
  "2017-10-22": {
      "equity": 10146,
      "debt": 6.762,
      "gold": 29380.78978,
      "pb": 3.43,
      "pe": 26.28,
      "yield": 1.12,
      "date": "2017-10-22"
  },
  "2017-10-23": {
      "equity": 10184,
      "debt": 6.799,
      "gold": 29248.0467,
      "pb": 3.44,
      "pe": 26.37,
      "yield": 1.12,
      "date": "2017-10-23"
  },
  "2017-10-24": {
      "equity": 10207,
      "debt": 6.782,
      "gold": 29401.01236,
      "pb": 3.45,
      "pe": 26.42,
      "yield": 1.12,
      "date": "2017-10-24"
  },
  "2017-10-25": {
      "equity": 10295,
      "debt": 6.814,
      "gold": 29158.27792,
      "pb": 3.48,
      "pe": 26.63,
      "yield": 1.11,
      "date": "2017-10-25"
  },
  "2017-10-26": {
      "equity": 10343,
      "debt": 6.798,
      "gold": 29277.35586,
      "pb": 3.49,
      "pe": 26.73,
      "yield": 1.1,
      "date": "2017-10-26"
  },
  "2017-10-27": {
      "equity": 10323,
      "debt": 6.807,
      "gold": 29055.04506,
      "pb": 3.49,
      "pe": 26.67,
      "yield": 1.11,
      "date": "2017-10-27"
  },
  "2017-10-28": {
      "equity": 10323,
      "debt": 6.807,
      "gold": 29055.04506,
      "pb": 3.49,
      "pe": 26.67,
      "yield": 1.11,
      "date": "2017-10-28"
  },
  "2017-10-29": {
      "equity": 10323,
      "debt": 6.808,
      "gold": 29055.04506,
      "pb": 3.49,
      "pe": 26.67,
      "yield": 1.11,
      "date": "2017-10-29"
  },
  "2017-10-30": {
      "equity": 10363,
      "debt": 6.882,
      "gold": 29128.97935,
      "pb": 3.5,
      "pe": 26.77,
      "yield": 1.1,
      "date": "2017-10-30"
  },
  "2017-10-31": {
      "equity": 10335,
      "debt": 6.862,
      "gold": 29066.94651,
      "pb": 3.49,
      "pe": 26.38,
      "yield": 1.08,
      "date": "2017-10-31"
  },
  "2017-11-01": {
      "equity": 10440,
      "debt": 6.892,
      "gold": 29117.95975,
      "pb": 3.52,
      "pe": 26.49,
      "yield": 1.07,
      "date": "2017-11-01"
  },
  "2017-11-02": {
      "equity": 10423,
      "debt": 6.86,
      "gold": 29058.31143,
      "pb": 3.52,
      "pe": 26.63,
      "yield": 1.07,
      "date": "2017-11-02"
  },
  "2017-11-03": {
      "equity": 10452,
      "debt": 6.858,
      "gold": 29087.25022,
      "pb": 3.53,
      "pe": 26.87,
      "yield": 1.07,
      "date": "2017-11-03"
  },
  "2017-11-04": {
      "equity": 10452,
      "debt": 6.858,
      "gold": 29087.25022,
      "pb": 3.53,
      "pe": 26.87,
      "yield": 1.07,
      "date": "2017-11-04"
  },
  "2017-11-05": {
      "equity": 10452,
      "debt": 6.858,
      "gold": 29087.25022,
      "pb": 3.53,
      "pe": 26.87,
      "yield": 1.07,
      "date": "2017-11-05"
  },
  "2017-11-06": {
      "equity": 10451,
      "debt": 6.894,
      "gold": 28970.74375,
      "pb": 3.53,
      "pe": 26.74,
      "yield": 1.07,
      "date": "2017-11-06"
  },
  "2017-11-07": {
      "equity": 10350,
      "debt": 6.928,
      "gold": 29351.84042,
      "pb": 3.49,
      "pe": 26.48,
      "yield": 1.08,
      "date": "2017-11-07"
  },
  "2017-11-08": {
      "equity": 10303,
      "debt": 6.939,
      "gold": 29373.08947,
      "pb": 3.48,
      "pe": 26.37,
      "yield": 1.08,
      "date": "2017-11-08"
  },
  "2017-11-09": {
      "equity": 10308,
      "debt": 6.931,
      "gold": 29503.08118,
      "pb": 3.48,
      "pe": 26.31,
      "yield": 1.08,
      "date": "2017-11-09"
  },
  "2017-11-10": {
      "equity": 10321,
      "debt": 6.956,
      "gold": 29613.09723,
      "pb": 3.48,
      "pe": 26.35,
      "yield": 1.08,
      "date": "2017-11-10"
  },
  "2017-11-11": {
      "equity": 10321,
      "debt": 6.956,
      "gold": 29613.09723,
      "pb": 3.48,
      "pe": 26.35,
      "yield": 1.08,
      "date": "2017-11-11"
  },
  "2017-11-12": {
      "equity": 10321,
      "debt": 6.956,
      "gold": 29613.09723,
      "pb": 3.48,
      "pe": 26.35,
      "yield": 1.08,
      "date": "2017-11-12"
  },
  "2017-11-13": {
      "equity": 10224,
      "debt": 6.972,
      "gold": 29520.9651,
      "pb": 3.45,
      "pe": 25.99,
      "yield": 1.09,
      "date": "2017-11-13"
  },
  "2017-11-14": {
      "equity": 10186,
      "debt": 7.05,
      "gold": 29385.69992,
      "pb": 3.44,
      "pe": 25.89,
      "yield": 1.09,
      "date": "2017-11-14"
  },
  "2017-11-15": {
      "equity": 10118,
      "debt": 7.016,
      "gold": 29646.45232,
      "pb": 3.41,
      "pe": 25.72,
      "yield": 1.1,
      "date": "2017-11-15"
  },
  "2017-11-16": {
      "equity": 10214,
      "debt": 7.062,
      "gold": 29385.36482,
      "pb": 3.45,
      "pe": 25.96,
      "yield": 1.09,
      "date": "2017-11-16"
  },
  "2017-11-17": {
      "equity": 10283,
      "debt": 7.049,
      "gold": 29410.19418,
      "pb": 3.46,
      "pe": 26.14,
      "yield": 1.08,
      "date": "2017-11-17"
  },
  "2017-11-18": {
      "equity": 10283,
      "debt": 7.049,
      "gold": 29410.19418,
      "pb": 3.46,
      "pe": 26.14,
      "yield": 1.08,
      "date": "2017-11-18"
  },
  "2017-11-19": {
      "equity": 10283,
      "debt": 7.049,
      "gold": 29410.19418,
      "pb": 3.46,
      "pe": 26.14,
      "yield": 1.08,
      "date": "2017-11-19"
  },
  "2017-11-20": {
      "equity": 10298,
      "debt": 6.889,
      "gold": 29671.01007,
      "pb": 3.47,
      "pe": 26.18,
      "yield": 1.08,
      "date": "2017-11-20"
  },
  "2017-11-21": {
      "equity": 10326,
      "debt": 6.896,
      "gold": 29263.6625,
      "pb": 3.48,
      "pe": 26.2,
      "yield": 1.08,
      "date": "2017-11-21"
  },
  "2017-11-22": {
      "equity": 10342,
      "debt": 6.959,
      "gold": 29330.55609,
      "pb": 3.48,
      "pe": 26.4,
      "yield": 1.08,
      "date": "2017-11-22"
  },
  "2017-11-23": {
      "equity": 10348,
      "debt": 6.985,
      "gold": 29384.99444,
      "pb": 3.49,
      "pe": 26.48,
      "yield": 1.08,
      "date": "2017-11-23"
  },
  "2017-11-24": {
      "equity": 10389,
      "debt": 7.002,
      "gold": 29363.35385,
      "pb": 3.5,
      "pe": 26.59,
      "yield": 1.07,
      "date": "2017-11-24"
  },
  "2017-11-25": {
      "equity": 10389,
      "debt": 7.002,
      "gold": 29363.35385,
      "pb": 3.5,
      "pe": 26.59,
      "yield": 1.07,
      "date": "2017-11-25"
  },
  "2017-11-26": {
      "equity": 10389,
      "debt": 7.002,
      "gold": 29363.35385,
      "pb": 3.5,
      "pe": 26.59,
      "yield": 1.07,
      "date": "2017-11-26"
  },
  "2017-11-27": {
      "equity": 10399,
      "debt": 7.056,
      "gold": 29456.88636,
      "pb": 3.5,
      "pe": 26.61,
      "yield": 1.07,
      "date": "2017-11-27"
  },
  "2017-11-28": {
      "equity": 10370,
      "debt": 7.03,
      "gold": 29407.19237,
      "pb": 3.49,
      "pe": 26.53,
      "yield": 1.07,
      "date": "2017-11-28"
  },
  "2017-11-29": {
      "equity": 10361,
      "debt": 7.025,
      "gold": 29439.51745,
      "pb": 3.49,
      "pe": 26.51,
      "yield": 1.07,
      "date": "2017-11-29"
  },
  "2017-11-30": {
      "equity": 10226,
      "debt": 7.058,
      "gold": 29202.78312,
      "pb": 3.45,
      "pe": 26.16,
      "yield": 1.11,
      "date": "2017-11-30"
  },
  "2017-12-01": {
      "equity": 10121,
      "debt": 7.058,
      "gold": 29088.92573,
      "pb": 3.41,
      "pe": 25.9,
      "yield": 1.12,
      "date": "2017-12-01"
  },
  "2017-12-02": {
      "equity": 10121,
      "debt": 7.058,
      "gold": 29088.92573,
      "pb": 3.41,
      "pe": 25.9,
      "yield": 1.12,
      "date": "2017-12-02"
  },
  "2017-12-03": {
      "equity": 10121,
      "debt": 7.058,
      "gold": 29088.92573,
      "pb": 3.41,
      "pe": 25.9,
      "yield": 1.12,
      "date": "2017-12-03"
  },
  "2017-12-04": {
      "equity": 10127,
      "debt": 7.083,
      "gold": 29054.03975,
      "pb": 3.42,
      "pe": 25.91,
      "yield": 1.12,
      "date": "2017-12-04"
  },
  "2017-12-05": {
      "equity": 10118,
      "debt": 7.059,
      "gold": 28970.14762,
      "pb": 3.41,
      "pe": 25.89,
      "yield": 1.12,
      "date": "2017-12-05"
  },
  "2017-12-06": {
      "equity": 10044,
      "debt": 7.031,
      "gold": 28887.43011,
      "pb": 3.39,
      "pe": 25.7,
      "yield": 1.13,
      "date": "2017-12-06"
  },
  "2017-12-07": {
      "equity": 10166,
      "debt": 7.054,
      "gold": 28598.80068,
      "pb": 3.43,
      "pe": 26.01,
      "yield": 1.12,
      "date": "2017-12-07"
  },
  "2017-12-08": {
      "equity": 10265,
      "debt": 7.089,
      "gold": 28354.02388,
      "pb": 3.46,
      "pe": 26.26,
      "yield": 1.11,
      "date": "2017-12-08"
  },
  "2017-12-09": {
      "equity": 10265,
      "debt": 7.089,
      "gold": 28354.02388,
      "pb": 3.46,
      "pe": 26.26,
      "yield": 1.11,
      "date": "2017-12-09"
  },
  "2017-12-10": {
      "equity": 10265,
      "debt": 7.089,
      "gold": 28354.02388,
      "pb": 3.46,
      "pe": 26.26,
      "yield": 1.11,
      "date": "2017-12-10"
  },
  "2017-12-11": {
      "equity": 10322,
      "debt": 7.172,
      "gold": 28413.46055,
      "pb": 3.48,
      "pe": 26.43,
      "yield": 1.1,
      "date": "2017-12-11"
  },
  "2017-12-12": {
      "equity": 10240,
      "debt": 7.191,
      "gold": 28319.3178,
      "pb": 3.45,
      "pe": 26.22,
      "yield": 1.11,
      "date": "2017-12-12"
  },
  "2017-12-13": {
      "equity": 10192,
      "debt": 7.173,
      "gold": 28188.53948,
      "pb": 3.44,
      "pe": 26.1,
      "yield": 1.11,
      "date": "2017-12-13"
  },
  "2017-12-14": {
      "equity": 10252,
      "debt": 7.13,
      "gold": 28474.05422,
      "pb": 3.46,
      "pe": 26.25,
      "yield": 1.11,
      "date": "2017-12-14"
  },
  "2017-12-15": {
      "equity": 10333,
      "debt": 7.134,
      "gold": 28391.73178,
      "pb": 3.48,
      "pe": 26.46,
      "yield": 1.1,
      "date": "2017-12-15"
  },
  "2017-12-16": {
      "equity": 10333,
      "debt": 7.133,
      "gold": 28391.73178,
      "pb": 3.48,
      "pe": 26.46,
      "yield": 1.1,
      "date": "2017-12-16"
  },
  "2017-12-17": {
      "equity": 10333,
      "debt": 7.133,
      "gold": 28391.73178,
      "pb": 3.48,
      "pe": 26.46,
      "yield": 1.1,
      "date": "2017-12-17"
  },
  "2017-12-18": {
      "equity": 10388,
      "debt": 7.181,
      "gold": 28481.06669,
      "pb": 3.5,
      "pe": 26.6,
      "yield": 1.09,
      "date": "2017-12-18"
  },
  "2017-12-19": {
      "equity": 10463,
      "debt": 7.178,
      "gold": 28540.99367,
      "pb": 3.53,
      "pe": 26.79,
      "yield": 1.09,
      "date": "2017-12-19"
  },
  "2017-12-20": {
      "equity": 10444,
      "debt": 7.219,
      "gold": 28572.57094,
      "pb": 3.52,
      "pe": 26.75,
      "yield": 1.09,
      "date": "2017-12-20"
  },
  "2017-12-21": {
      "equity": 10440,
      "debt": 7.214,
      "gold": 28581.47763,
      "pb": 3.52,
      "pe": 26.74,
      "yield": 1.09,
      "date": "2017-12-21"
  },
  "2017-12-22": {
      "equity": 10493,
      "debt": 7.271,
      "gold": 28634.14875,
      "pb": 3.54,
      "pe": 26.86,
      "yield": 1.08,
      "date": "2017-12-22"
  },
  "2017-12-23": {
      "equity": 10493,
      "debt": 7.271,
      "gold": 28634.14875,
      "pb": 3.54,
      "pe": 26.86,
      "yield": 1.08,
      "date": "2017-12-23"
  },
  "2017-12-24": {
      "equity": 10493,
      "debt": 7.271,
      "gold": 28634.14875,
      "pb": 3.54,
      "pe": 26.86,
      "yield": 1.08,
      "date": "2017-12-24"
  },
  "2017-12-25": {
      "equity": 10493,
      "debt": 7.271,
      "gold": 28634.14875,
      "pb": 3.54,
      "pe": 26.86,
      "yield": 1.08,
      "date": "2017-12-25"
  },
  "2017-12-26": {
      "equity": 10531,
      "debt": 7.275,
      "gold": 28634.14875,
      "pb": 3.55,
      "pe": 26.96,
      "yield": 1.08,
      "date": "2017-12-26"
  },
  "2017-12-27": {
      "equity": 10490,
      "debt": 7.219,
      "gold": 29085.2396,
      "pb": 3.54,
      "pe": 26.85,
      "yield": 1.08,
      "date": "2017-12-27"
  },
  "2017-12-28": {
      "equity": 10477,
      "debt": 7.396,
      "gold": 29183.15667,
      "pb": 3.53,
      "pe": 26.82,
      "yield": 1.08,
      "date": "2017-12-28"
  },
  "2017-12-29": {
      "equity": 10530,
      "debt": 7.326,
      "gold": 29201.03353,
      "pb": 3.55,
      "pe": 26.92,
      "yield": 1.08,
      "date": "2017-12-29"
  },
  "2017-12-30": {
      "equity": 10530,
      "debt": 7.326,
      "gold": 29201.03353,
      "pb": 3.55,
      "pe": 26.92,
      "yield": 1.08,
      "date": "2017-12-30"
  },
  "2017-12-31": {
      "equity": 10530,
      "debt": 7.326,
      "gold": 29201.03353,
      "pb": 3.55,
      "pe": 26.92,
      "yield": 1.08,
      "date": "2017-12-31"
  },
  "2018-01-01": {
      "equity": 10435.55,
      "debt": 7.337,
      "gold": 29201.03353,
      "pb": 3.51,
      "pe": 26.68,
      "yield": 1.09,
      "date": "2018-01-01"
  },
  "2018-01-02": {
      "equity": 10442.2,
      "debt": 7.384,
      "gold": 29379.54814,
      "pb": 3.52,
      "pe": 26.7,
      "yield": 1.09,
      "date": "2018-01-02"
  },
  "2018-01-03": {
      "equity": 10443.2,
      "debt": 7.322,
      "gold": 29398.17281,
      "pb": 3.52,
      "pe": 26.7,
      "yield": 1.09,
      "date": "2018-01-03"
  },
  "2018-01-04": {
      "equity": 10504.8,
      "debt": 7.331,
      "gold": 29366.46149,
      "pb": 3.54,
      "pe": 26.86,
      "yield": 1.08,
      "date": "2018-01-04"
  },
  "2018-01-05": {
      "equity": 10558.85,
      "debt": 7.288,
      "gold": 29428.97053,
      "pb": 3.55,
      "pe": 26.99,
      "yield": 1.07,
      "date": "2018-01-05"
  },
  "2018-01-06": {
      "equity": 10558.85,
      "debt": 7.288,
      "gold": 29428.97053,
      "pb": 3.55,
      "pe": 26.99,
      "yield": 1.07,
      "date": "2018-01-06"
  },
  "2018-01-07": {
      "equity": 10558.85,
      "debt": 7.288,
      "gold": 29428.97053,
      "pb": 3.55,
      "pe": 26.99,
      "yield": 1.07,
      "date": "2018-01-07"
  },
  "2018-01-08": {
      "equity": 10623.6,
      "debt": 7.344,
      "gold": 29521.87164,
      "pb": 3.58,
      "pe": 27.16,
      "yield": 1.07,
      "date": "2018-01-08"
  },
  "2018-01-09": {
      "equity": 10637,
      "debt": 7.367,
      "gold": 29524.04804,
      "pb": 3.58,
      "pe": 27.19,
      "yield": 1.07,
      "date": "2018-01-09"
  },
  "2018-01-10": {
      "equity": 10632.2,
      "debt": 7.441,
      "gold": 29738.81374,
      "pb": 3.58,
      "pe": 27.18,
      "yield": 1.07,
      "date": "2018-01-10"
  },
  "2018-01-11": {
      "equity": 10651.2,
      "debt": 7.436,
      "gold": 29668.05058,
      "pb": 3.59,
      "pe": 27.23,
      "yield": 1.06,
      "date": "2018-01-11"
  },
  "2018-01-12": {
      "equity": 10681.25,
      "debt": 7.455,
      "gold": 29883.81453,
      "pb": 3.6,
      "pe": 27.28,
      "yield": 1.06,
      "date": "2018-01-12"
  },
  "2018-01-13": {
      "equity": 10681.25,
      "debt": 7.455,
      "gold": 29883.81453,
      "pb": 3.6,
      "pe": 27.28,
      "yield": 1.06,
      "date": "2018-01-13"
  },
  "2018-01-14": {
      "equity": 10681.25,
      "debt": 7.455,
      "gold": 29883.81453,
      "pb": 3.6,
      "pe": 27.28,
      "yield": 1.06,
      "date": "2018-01-14"
  },
  "2018-01-15": {
      "equity": 10741.55,
      "debt": 7.443,
      "gold": 30100.78485,
      "pb": 3.62,
      "pe": 27.06,
      "yield": 1.06,
      "date": "2018-01-15"
  },
  "2018-01-16": {
      "equity": 10700.45,
      "debt": 7.552,
      "gold": 30147.37473,
      "pb": 3.61,
      "pe": 26.96,
      "yield": 1.06,
      "date": "2018-01-16"
  },
  "2018-01-17": {
      "equity": 10788.55,
      "debt": 7.415,
      "gold": 30114.96499,
      "pb": 3.64,
      "pe": 27.18,
      "yield": 1.05,
      "date": "2018-01-17"
  },
  "2018-01-18": {
      "equity": 10817,
      "debt": 7.471,
      "gold": 29975.57982,
      "pb": 3.65,
      "pe": 27.25,
      "yield": 1.05,
      "date": "2018-01-18"
  },
  "2018-01-19": {
      "equity": 10894.7,
      "debt": 7.479,
      "gold": 30068.04706,
      "pb": 3.68,
      "pe": 27.44,
      "yield": 1.04,
      "date": "2018-01-19"
  },
  "2018-01-20": {
      "equity": 10894.7,
      "debt": 7.479,
      "gold": 30068.04706,
      "pb": 3.68,
      "pe": 27.44,
      "yield": 1.04,
      "date": "2018-01-20"
  },
  "2018-01-21": {
      "equity": 10894.7,
      "debt": 7.479,
      "gold": 30068.04706,
      "pb": 3.68,
      "pe": 27.44,
      "yield": 1.04,
      "date": "2018-01-21"
  },
  "2018-01-22": {
      "equity": 10966.2,
      "debt": 7.462,
      "gold": 30078.1072,
      "pb": 3.7,
      "pe": 27.62,
      "yield": 1.03,
      "date": "2018-01-22"
  },
  "2018-01-23": {
      "equity": 11083.7,
      "debt": 7.413,
      "gold": 30083.86391,
      "pb": 3.74,
      "pe": 27.81,
      "yield": 1.02,
      "date": "2018-01-23"
  },
  "2018-01-24": {
      "equity": 11086,
      "debt": 7.276,
      "gold": 30301.75135,
      "pb": 3.74,
      "pe": 27.66,
      "yield": 1.02,
      "date": "2018-01-24"
  },
  "2018-01-25": {
      "equity": 11069.65,
      "debt": 7.307,
      "gold": 30449.98677,
      "pb": 3.74,
      "pe": 27.61,
      "yield": 1.02,
      "date": "2018-01-25"
  },
  "2018-01-26": {
      "equity": 11069.65,
      "debt": 7.307,
      "gold": 30317.91037,
      "pb": 3.74,
      "pe": 27.61,
      "yield": 1.02,
      "date": "2018-01-26"
  },
  "2018-01-27": {
      "equity": 11069.65,
      "debt": 7.307,
      "gold": 30317.91037,
      "pb": 3.74,
      "pe": 27.61,
      "yield": 1.02,
      "date": "2018-01-27"
  },
  "2018-01-28": {
      "equity": 11069.65,
      "debt": 7.307,
      "gold": 30317.91037,
      "pb": 3.74,
      "pe": 27.61,
      "yield": 1.02,
      "date": "2018-01-28"
  },
  "2018-01-29": {
      "equity": 11130.4,
      "debt": 7.441,
      "gold": 30289.59241,
      "pb": 3.76,
      "pe": 27.71,
      "yield": 1.02,
      "date": "2018-01-29"
  },
  "2018-01-30": {
      "equity": 11049.65,
      "debt": 7.434,
      "gold": 30274.03658,
      "pb": 3.73,
      "pe": 27.51,
      "yield": 1.03,
      "date": "2018-01-30"
  },
  "2018-01-31": {
      "equity": 11027.7,
      "debt": 7.43,
      "gold": 30179.47054,
      "pb": 3.73,
      "pe": 27.5,
      "yield": 1.03,
      "date": "2018-01-31"
  },
  "2018-02-01": {
      "equity": 11016.9,
      "debt": 7.605,
      "gold": 30260.9076,
      "pb": 3.72,
      "pe": 27.48,
      "yield": 1.03,
      "date": "2018-02-01"
  },
  "2018-02-02": {
      "equity": 10760.6,
      "debt": 7.562,
      "gold": 30429.08693,
      "pb": 3.64,
      "pe": 26.04,
      "yield": 1.05,
      "date": "2018-02-02"
  },
  "2018-02-03": {
      "equity": 10760.6,
      "debt": 7.562,
      "gold": 30429.08693,
      "pb": 3.64,
      "pe": 26.04,
      "yield": 1.05,
      "date": "2018-02-03"
  },
  "2018-02-04": {
      "equity": 10760.6,
      "debt": 7.562,
      "gold": 30429.08693,
      "pb": 3.64,
      "pe": 26.04,
      "yield": 1.05,
      "date": "2018-02-04"
  },
  "2018-02-05": {
      "equity": 10666.55,
      "debt": 7.605,
      "gold": 30206.73028,
      "pb": 3.6,
      "pe": 25.78,
      "yield": 1.06,
      "date": "2018-02-05"
  },
  "2018-02-06": {
      "equity": 10498.25,
      "debt": 7.568,
      "gold": 30415.14312,
      "pb": 3.55,
      "pe": 25.37,
      "yield": 1.08,
      "date": "2018-02-06"
  },
  "2018-02-07": {
      "equity": 10476.7,
      "debt": 7.531,
      "gold": 30143.65685,
      "pb": 3.54,
      "pe": 25.32,
      "yield": 1.08,
      "date": "2018-02-07"
  },
  "2018-02-08": {
      "equity": 10576.85,
      "debt": 7.466,
      "gold": 29824.02159,
      "pb": 3.57,
      "pe": 25.56,
      "yield": 1.07,
      "date": "2018-02-08"
  },
  "2018-02-09": {
      "equity": 10454.95,
      "debt": 7.49,
      "gold": 29930.79948,
      "pb": 3.53,
      "pe": 25.27,
      "yield": 1.08,
      "date": "2018-02-09"
  },
  "2018-02-10": {
      "equity": 10454.95,
      "debt": 7.49,
      "gold": 29930.79948,
      "pb": 3.53,
      "pe": 25.27,
      "yield": 1.08,
      "date": "2018-02-10"
  },
  "2018-02-11": {
      "equity": 10454.95,
      "debt": 7.49,
      "gold": 29930.79948,
      "pb": 3.53,
      "pe": 25.27,
      "yield": 1.08,
      "date": "2018-02-11"
  },
  "2018-02-12": {
      "equity": 10539.75,
      "debt": 7.496,
      "gold": 29958.6871,
      "pb": 3.56,
      "pe": 25.48,
      "yield": 1.07,
      "date": "2018-02-12"
  },
  "2018-02-13": {
      "equity": 10539.75,
      "debt": 7.496,
      "gold": 30133.22281,
      "pb": 3.56,
      "pe": 25.48,
      "yield": 1.07,
      "date": "2018-02-13"
  },
  "2018-02-14": {
      "equity": 10500.9,
      "debt": 7.491,
      "gold": 30052.71345,
      "pb": 3.55,
      "pe": 25.37,
      "yield": 1.08,
      "date": "2018-02-14"
  },
  "2018-02-15": {
      "equity": 10545.5,
      "debt": 7.568,
      "gold": 30535.61791,
      "pb": 3.56,
      "pe": 25.45,
      "yield": 1.07,
      "date": "2018-02-15"
  },
  "2018-02-16": {
      "equity": 10452.3,
      "debt": 7.577,
      "gold": 30859.21445,
      "pb": 3.53,
      "pe": 25.32,
      "yield": 1.08,
      "date": "2018-02-16"
  },
  "2018-02-17": {
      "equity": 10452.3,
      "debt": 7.577,
      "gold": 30859.21445,
      "pb": 3.53,
      "pe": 25.32,
      "yield": 1.08,
      "date": "2018-02-17"
  },
  "2018-02-18": {
      "equity": 10452.3,
      "debt": 7.583,
      "gold": 30859.21445,
      "pb": 3.53,
      "pe": 25.32,
      "yield": 1.08,
      "date": "2018-02-18"
  },
  "2018-02-19": {
      "equity": 10378.4,
      "debt": 7.583,
      "gold": 30604.81843,
      "pb": 3.51,
      "pe": 25.14,
      "yield": 1.09,
      "date": "2018-02-19"
  },
  "2018-02-20": {
      "equity": 10360.4,
      "debt": 7.668,
      "gold": 30579.1178,
      "pb": 3.5,
      "pe": 25.09,
      "yield": 1.09,
      "date": "2018-02-20"
  },
  "2018-02-21": {
      "equity": 10397.45,
      "debt": 7.71,
      "gold": 30366.19341,
      "pb": 3.51,
      "pe": 25.18,
      "yield": 1.09,
      "date": "2018-02-21"
  },
  "2018-02-22": {
      "equity": 10382.7,
      "debt": 7.746,
      "gold": 30341.82966,
      "pb": 3.51,
      "pe": 25.49,
      "yield": 1.09,
      "date": "2018-02-22"
  },
  "2018-02-23": {
      "equity": 10491.05,
      "debt": 7.667,
      "gold": 30419.92628,
      "pb": 3.54,
      "pe": 25.75,
      "yield": 1.13,
      "date": "2018-02-23"
  },
  "2018-02-24": {
      "equity": 10491.05,
      "debt": 7.667,
      "gold": 30419.92628,
      "pb": 3.54,
      "pe": 25.75,
      "yield": 1.13,
      "date": "2018-02-24"
  },
  "2018-02-25": {
      "equity": 10491.05,
      "debt": 7.667,
      "gold": 30419.92628,
      "pb": 3.54,
      "pe": 25.75,
      "yield": 1.13,
      "date": "2018-02-25"
  },
  "2018-02-26": {
      "equity": 10582.6,
      "debt": 7.688,
      "gold": 30626.99871,
      "pb": 3.57,
      "pe": 25.95,
      "yield": 1.12,
      "date": "2018-02-26"
  },
  "2018-02-27": {
      "equity": 10554.3,
      "debt": 7.666,
      "gold": 30606.77966,
      "pb": 3.57,
      "pe": 25.87,
      "yield": 1.12,
      "date": "2018-02-27"
  },
  "2018-02-28": {
      "equity": 10492.85,
      "debt": 7.726,
      "gold": 30354.85987,
      "pb": 3.54,
      "pe": 25.68,
      "yield": 1.13,
      "date": "2018-02-28"
  },
  "2018-03-01": {
      "equity": 10458.35,
      "debt": 7.738,
      "gold": 30169.68906,
      "pb": 3.53,
      "pe": 25.59,
      "yield": 1.19,
      "date": "2018-03-01"
  },
  "2018-03-02": {
      "equity": 10458.35,
      "debt": 7.738,
      "gold": 30297.16221,
      "pb": 3.53,
      "pe": 25.59,
      "yield": 1.19,
      "date": "2018-03-02"
  },
  "2018-03-03": {
      "equity": 10458.35,
      "debt": 7.738,
      "gold": 30297.16221,
      "pb": 3.53,
      "pe": 25.59,
      "yield": 1.19,
      "date": "2018-03-03"
  },
  "2018-03-04": {
      "equity": 10458.35,
      "debt": 7.738,
      "gold": 30297.16221,
      "pb": 3.53,
      "pe": 25.59,
      "yield": 1.19,
      "date": "2018-03-04"
  },
  "2018-03-05": {
      "equity": 10358.85,
      "debt": 7.776,
      "gold": 30426.70241,
      "pb": 3.5,
      "pe": 25.4,
      "yield": 1.2,
      "date": "2018-03-05"
  },
  "2018-03-06": {
      "equity": 10249.25,
      "debt": 7.775,
      "gold": 30295.38793,
      "pb": 3.46,
      "pe": 25.02,
      "yield": 1.21,
      "date": "2018-03-06"
  },
  "2018-03-07": {
      "equity": 10154.2,
      "debt": 7.678,
      "gold": 30532.88418,
      "pb": 3.43,
      "pe": 24.79,
      "yield": 1.28,
      "date": "2018-03-07"
  },
  "2018-03-08": {
      "equity": 10242.65,
      "debt": 7.662,
      "gold": 30448.13136,
      "pb": 3.46,
      "pe": 25,
      "yield": 1.27,
      "date": "2018-03-08"
  },
  "2018-03-09": {
      "equity": 10226.85,
      "debt": 7.671,
      "gold": 30233.89125,
      "pb": 3.45,
      "pe": 24.97,
      "yield": 1.28,
      "date": "2018-03-09"
  },
  "2018-03-10": {
      "equity": 10226.85,
      "debt": 7.671,
      "gold": 30233.89125,
      "pb": 3.45,
      "pe": 24.97,
      "yield": 1.28,
      "date": "2018-03-10"
  },
  "2018-03-11": {
      "equity": 10226.85,
      "debt": 7.671,
      "gold": 30233.89125,
      "pb": 3.45,
      "pe": 24.97,
      "yield": 1.28,
      "date": "2018-03-11"
  },
  "2018-03-12": {
      "equity": 10421.4,
      "debt": 7.629,
      "gold": 30180.42646,
      "pb": 3.52,
      "pe": 25.44,
      "yield": 1.25,
      "date": "2018-03-12"
  },
  "2018-03-13": {
      "equity": 10426.85,
      "debt": 7.653,
      "gold": 30125.26852,
      "pb": 3.52,
      "pe": 25.45,
      "yield": 1.25,
      "date": "2018-03-13"
  },
  "2018-03-14": {
      "equity": 10410.9,
      "debt": 7.685,
      "gold": 30333.01116,
      "pb": 3.52,
      "pe": 25.41,
      "yield": 1.25,
      "date": "2018-03-14"
  },
  "2018-03-15": {
      "equity": 10360.15,
      "debt": 7.633,
      "gold": 30344.4611,
      "pb": 3.5,
      "pe": 25.28,
      "yield": 1.26,
      "date": "2018-03-15"
  },
  "2018-03-16": {
      "equity": 10195.15,
      "debt": 7.557,
      "gold": 30305.10944,
      "pb": 3.44,
      "pe": 24.87,
      "yield": 1.28,
      "date": "2018-03-16"
  },
  "2018-03-17": {
      "equity": 10195.15,
      "debt": 7.557,
      "gold": 30305.10944,
      "pb": 3.44,
      "pe": 24.87,
      "yield": 1.28,
      "date": "2018-03-17"
  },
  "2018-03-18": {
      "equity": 10195.15,
      "debt": 7.557,
      "gold": 30305.10944,
      "pb": 3.44,
      "pe": 24.87,
      "yield": 1.28,
      "date": "2018-03-18"
  },
  "2018-03-19": {
      "equity": 10094.25,
      "debt": 7.608,
      "gold": 30211.27357,
      "pb": 3.41,
      "pe": 24.61,
      "yield": 1.29,
      "date": "2018-03-19"
  },
  "2018-03-20": {
      "equity": 10124.35,
      "debt": 7.615,
      "gold": 30226.89289,
      "pb": 3.42,
      "pe": 24.69,
      "yield": 1.29,
      "date": "2018-03-20"
  },
  "2018-03-21": {
      "equity": 10155.25,
      "debt": 7.581,
      "gold": 30264.97469,
      "pb": 3.43,
      "pe": 24.76,
      "yield": 1.28,
      "date": "2018-03-21"
  },
  "2018-03-22": {
      "equity": 10114.75,
      "debt": 7.56,
      "gold": 30521.90338,
      "pb": 3.42,
      "pe": 24.67,
      "yield": 1.29,
      "date": "2018-03-22"
  },
  "2018-03-23": {
      "equity": 9998.05,
      "debt": 7.557,
      "gold": 30777.52694,
      "pb": 3.38,
      "pe": 24.38,
      "yield": 1.3,
      "date": "2018-03-23"
  },
  "2018-03-24": {
      "equity": 9998.05,
      "debt": 7.557,
      "gold": 30777.52694,
      "pb": 3.38,
      "pe": 24.38,
      "yield": 1.3,
      "date": "2018-03-24"
  },
  "2018-03-25": {
      "equity": 9998.05,
      "debt": 7.557,
      "gold": 30777.52694,
      "pb": 3.38,
      "pe": 24.38,
      "yield": 1.3,
      "date": "2018-03-25"
  },
  "2018-03-26": {
      "equity": 10130.65,
      "debt": 7.623,
      "gold": 30774.93077,
      "pb": 3.42,
      "pe": 24.7,
      "yield": 1.29,
      "date": "2018-03-26"
  },
  "2018-03-27": {
      "equity": 10184.15,
      "debt": 7.333,
      "gold": 30896.36502,
      "pb": 3.44,
      "pe": 24.83,
      "yield": 1.28,
      "date": "2018-03-27"
  },
  "2018-03-28": {
      "equity": 10113.7,
      "debt": 7.398,
      "gold": 30815.12901,
      "pb": 3.42,
      "pe": 24.66,
      "yield": 1.29,
      "date": "2018-03-28"
  },
  "2018-03-29": {
      "equity": 10113.7,
      "debt": 7.398,
      "gold": 30419.88395,
      "pb": 3.42,
      "pe": 24.66,
      "yield": 1.29,
      "date": "2018-03-29"
  },
  "2018-03-30": {
      "equity": 10113.7,
      "debt": 7.398,
      "gold": 30419.88395,
      "pb": 3.42,
      "pe": 24.66,
      "yield": 1.29,
      "date": "2018-03-30"
  },
  "2018-03-31": {
      "equity": 10113.7,
      "debt": 7.398,
      "gold": 30419.88395,
      "pb": 3.42,
      "pe": 24.66,
      "yield": 1.29,
      "date": "2018-03-31"
  },
  "2018-04-01": {
      "equity": 10113.7,
      "debt": 7.398,
      "gold": 30419.88395,
      "pb": 3.42,
      "pe": 24.66,
      "yield": 1.29,
      "date": "2018-04-01"
  },
  "2018-04-02": {
      "equity": 10211.8,
      "debt": 7.398,
      "gold": 30419.88395,
      "pb": 3.51,
      "pe": 25.35,
      "yield": 1.26,
      "date": "2018-04-02"
  },
  "2018-04-03": {
      "equity": 10245,
      "debt": 7.332,
      "gold": 30651.20725,
      "pb": 3.52,
      "pe": 25.43,
      "yield": 1.25,
      "date": "2018-04-03"
  },
  "2018-04-04": {
      "equity": 10128.4,
      "debt": 7.294,
      "gold": 30798.23983,
      "pb": 3.48,
      "pe": 25.14,
      "yield": 1.27,
      "date": "2018-04-04"
  },
  "2018-04-05": {
      "equity": 10325.15,
      "debt": 7.127,
      "gold": 30350.65874,
      "pb": 3.55,
      "pe": 25.63,
      "yield": 1.24,
      "date": "2018-04-05"
  },
  "2018-04-06": {
      "equity": 10331.6,
      "debt": 7.175,
      "gold": 30359.34673,
      "pb": 3.55,
      "pe": 25.65,
      "yield": 1.24,
      "date": "2018-04-06"
  },
  "2018-04-07": {
      "equity": 10331.6,
      "debt": 7.175,
      "gold": 30359.34673,
      "pb": 3.55,
      "pe": 25.65,
      "yield": 1.24,
      "date": "2018-04-07"
  },
  "2018-04-08": {
      "equity": 10331.6,
      "debt": 7.175,
      "gold": 30359.34673,
      "pb": 3.55,
      "pe": 25.65,
      "yield": 1.24,
      "date": "2018-04-08"
  },
  "2018-04-09": {
      "equity": 10379.35,
      "debt": 7.226,
      "gold": 30392.02455,
      "pb": 3.57,
      "pe": 25.76,
      "yield": 1.23,
      "date": "2018-04-09"
  },
  "2018-04-10": {
      "equity": 10402.25,
      "debt": 7.379,
      "gold": 30611.89086,
      "pb": 3.57,
      "pe": 25.82,
      "yield": 1.23,
      "date": "2018-04-10"
  },
  "2018-04-11": {
      "equity": 10417.15,
      "debt": 7.536,
      "gold": 30933.0288,
      "pb": 3.58,
      "pe": 25.86,
      "yield": 1.23,
      "date": "2018-04-11"
  },
  "2018-04-12": {
      "equity": 10458.65,
      "debt": 7.468,
      "gold": 30996.64897,
      "pb": 3.59,
      "pe": 25.96,
      "yield": 1.23,
      "date": "2018-04-12"
  },
  "2018-04-13": {
      "equity": 10480.6,
      "debt": 7.431,
      "gold": 30849.6164,
      "pb": 3.6,
      "pe": 26.01,
      "yield": 1.22,
      "date": "2018-04-13"
  },
  "2018-04-14": {
      "equity": 10480.6,
      "debt": 7.431,
      "gold": 30849.6164,
      "pb": 3.6,
      "pe": 26.01,
      "yield": 1.22,
      "date": "2018-04-14"
  },
  "2018-04-15": {
      "equity": 10480.6,
      "debt": 7.431,
      "gold": 30849.6164,
      "pb": 3.6,
      "pe": 26.01,
      "yield": 1.22,
      "date": "2018-04-15"
  },
  "2018-04-16": {
      "equity": 10528.35,
      "debt": 7.486,
      "gold": 31047.17896,
      "pb": 3.62,
      "pe": 26.13,
      "yield": 1.22,
      "date": "2018-04-16"
  },
  "2018-04-17": {
      "equity": 10548.7,
      "debt": 7.489,
      "gold": 31112.46054,
      "pb": 3.63,
      "pe": 26.18,
      "yield": 1.22,
      "date": "2018-04-17"
  },
  "2018-04-18": {
      "equity": 10526.2,
      "debt": 7.536,
      "gold": 31227.92289,
      "pb": 3.62,
      "pe": 26.13,
      "yield": 1.22,
      "date": "2018-04-18"
  },
  "2018-04-19": {
      "equity": 10565.3,
      "debt": 7.631,
      "gold": 31458.73472,
      "pb": 3.63,
      "pe": 26.23,
      "yield": 1.21,
      "date": "2018-04-19"
  },
  "2018-04-20": {
      "equity": 10564.05,
      "debt": 7.718,
      "gold": 31284.90097,
      "pb": 3.63,
      "pe": 26.22,
      "yield": 1.21,
      "date": "2018-04-20"
  },
  "2018-04-21": {
      "equity": 10564.05,
      "debt": 7.718,
      "gold": 31284.90097,
      "pb": 3.63,
      "pe": 26.22,
      "yield": 1.21,
      "date": "2018-04-21"
  },
  "2018-04-22": {
      "equity": 10564.05,
      "debt": 7.718,
      "gold": 31284.90097,
      "pb": 3.63,
      "pe": 26.22,
      "yield": 1.21,
      "date": "2018-04-22"
  },
  "2018-04-23": {
      "equity": 10584.7,
      "debt": 7.74,
      "gold": 31169.01533,
      "pb": 3.64,
      "pe": 26.27,
      "yield": 1.21,
      "date": "2018-04-23"
  },
  "2018-04-24": {
      "equity": 10614.35,
      "debt": 7.683,
      "gold": 31091.39138,
      "pb": 3.65,
      "pe": 26.35,
      "yield": 1.21,
      "date": "2018-04-24"
  },
  "2018-04-25": {
      "equity": 10570.55,
      "debt": 7.738,
      "gold": 31309.9843,
      "pb": 3.63,
      "pe": 26.24,
      "yield": 1.21,
      "date": "2018-04-25"
  },
  "2018-04-26": {
      "equity": 10617.8,
      "debt": 7.755,
      "gold": 31182.00321,
      "pb": 3.65,
      "pe": 26.36,
      "yield": 1.21,
      "date": "2018-04-26"
  },
  "2018-04-27": {
      "equity": 10692.3,
      "debt": 7.767,
      "gold": 30974.53571,
      "pb": 3.67,
      "pe": 26.54,
      "yield": 1.2,
      "date": "2018-04-27"
  },
  "2018-04-28": {
      "equity": 10692.3,
      "debt": 7.767,
      "gold": 30974.53571,
      "pb": 3.67,
      "pe": 26.54,
      "yield": 1.2,
      "date": "2018-04-28"
  },
  "2018-04-29": {
      "equity": 10692.3,
      "debt": 7.767,
      "gold": 30974.53571,
      "pb": 3.67,
      "pe": 26.54,
      "yield": 1.2,
      "date": "2018-04-29"
  },
  "2018-04-30": {
      "equity": 10739.35,
      "debt": 7.767,
      "gold": 30940.45398,
      "pb": 3.69,
      "pe": 26.66,
      "yield": 1.19,
      "date": "2018-04-30"
  },
  "2018-05-01": {
      "equity": 10739.35,
      "debt": 7.767,
      "gold": 30774.73324,
      "pb": 3.69,
      "pe": 26.66,
      "yield": 1.19,
      "date": "2018-05-01"
  },
  "2018-05-02": {
      "equity": 10718.05,
      "debt": 7.733,
      "gold": 30863.64486,
      "pb": 3.68,
      "pe": 26.6,
      "yield": 1.2,
      "date": "2018-05-02"
  },
  "2018-05-03": {
      "equity": 10679.65,
      "debt": 7.734,
      "gold": 30894.36498,
      "pb": 3.67,
      "pe": 26.51,
      "yield": 1.2,
      "date": "2018-05-03"
  },
  "2018-05-04": {
      "equity": 10618.25,
      "debt": 7.728,
      "gold": 30858.48428,
      "pb": 3.65,
      "pe": 26.36,
      "yield": 1.21,
      "date": "2018-05-04"
  },
  "2018-05-05": {
      "equity": 10618.25,
      "debt": 7.728,
      "gold": 30858.48428,
      "pb": 3.65,
      "pe": 26.36,
      "yield": 1.21,
      "date": "2018-05-05"
  },
  "2018-05-06": {
      "equity": 10618.25,
      "debt": 7.728,
      "gold": 30858.48428,
      "pb": 3.65,
      "pe": 26.36,
      "yield": 1.21,
      "date": "2018-05-06"
  },
  "2018-05-07": {
      "equity": 10715.5,
      "debt": 7.622,
      "gold": 30858.48428,
      "pb": 3.68,
      "pe": 26.6,
      "yield": 1.2,
      "date": "2018-05-07"
  },
  "2018-05-08": {
      "equity": 10717.8,
      "debt": 7.58,
      "gold": 30995.82003,
      "pb": 3.68,
      "pe": 26.6,
      "yield": 1.2,
      "date": "2018-05-08"
  },
  "2018-05-09": {
      "equity": 10741.7,
      "debt": 7.709,
      "gold": 31064.16339,
      "pb": 3.69,
      "pe": 26.66,
      "yield": 1.19,
      "date": "2018-05-09"
  },
  "2018-05-10": {
      "equity": 10716.55,
      "debt": 7.715,
      "gold": 31093.13392,
      "pb": 3.68,
      "pe": 26.6,
      "yield": 1.2,
      "date": "2018-05-10"
  },
  "2018-05-11": {
      "equity": 10806.5,
      "debt": 7.726,
      "gold": 31478.13189,
      "pb": 3.71,
      "pe": 26.82,
      "yield": 1.19,
      "date": "2018-05-11"
  },
  "2018-05-12": {
      "equity": 10806.5,
      "debt": 7.726,
      "gold": 31478.13189,
      "pb": 3.71,
      "pe": 26.82,
      "yield": 1.19,
      "date": "2018-05-12"
  },
  "2018-05-13": {
      "equity": 10806.5,
      "debt": 7.726,
      "gold": 31478.13189,
      "pb": 3.71,
      "pe": 26.82,
      "yield": 1.19,
      "date": "2018-05-13"
  },
  "2018-05-14": {
      "equity": 10806.6,
      "debt": 7.826,
      "gold": 31483.76162,
      "pb": 3.71,
      "pe": 26.82,
      "yield": 1.19,
      "date": "2018-05-14"
  },
  "2018-05-15": {
      "equity": 10801.85,
      "debt": 7.903,
      "gold": 31520.31253,
      "pb": 3.71,
      "pe": 26.81,
      "yield": 1.19,
      "date": "2018-05-15"
  },
  "2018-05-16": {
      "equity": 10741.1,
      "debt": 7.902,
      "gold": 30842.61451,
      "pb": 3.69,
      "pe": 26.66,
      "yield": 1.19,
      "date": "2018-05-16"
  },
  "2018-05-17": {
      "equity": 10682.7,
      "debt": 7.88,
      "gold": 30830.6531,
      "pb": 3.67,
      "pe": 26.52,
      "yield": 1.2,
      "date": "2018-05-17"
  },
  "2018-05-18": {
      "equity": 10596.4,
      "debt": 7.835,
      "gold": 30872.50569,
      "pb": 3.64,
      "pe": 26.27,
      "yield": 1.21,
      "date": "2018-05-18"
  },
  "2018-05-19": {
      "equity": 10596.4,
      "debt": 7.835,
      "gold": 30872.50569,
      "pb": 3.64,
      "pe": 26.27,
      "yield": 1.21,
      "date": "2018-05-19"
  },
  "2018-05-20": {
      "equity": 10596.4,
      "debt": 7.835,
      "gold": 30872.50569,
      "pb": 3.64,
      "pe": 26.27,
      "yield": 1.21,
      "date": "2018-05-20"
  },
  "2018-05-21": {
      "equity": 10516.7,
      "debt": 7.809,
      "gold": 30899.49735,
      "pb": 3.61,
      "pe": 26.08,
      "yield": 1.22,
      "date": "2018-05-21"
  },
  "2018-05-22": {
      "equity": 10536.7,
      "debt": 7.809,
      "gold": 31047.7116,
      "pb": 3.62,
      "pe": 26.13,
      "yield": 1.22,
      "date": "2018-05-22"
  },
  "2018-05-23": {
      "equity": 10430.35,
      "debt": 7.849,
      "gold": 31207.17473,
      "pb": 3.58,
      "pe": 25.86,
      "yield": 1.23,
      "date": "2018-05-23"
  },
  "2018-05-24": {
      "equity": 10513.85,
      "debt": 7.871,
      "gold": 31235.27046,
      "pb": 3.61,
      "pe": 26.07,
      "yield": 1.22,
      "date": "2018-05-24"
  },
  "2018-05-25": {
      "equity": 10605.15,
      "debt": 7.794,
      "gold": 31184.9627,
      "pb": 3.64,
      "pe": 26.24,
      "yield": 1.21,
      "date": "2018-05-25"
  },
  "2018-05-26": {
      "equity": 10605.15,
      "debt": 7.794,
      "gold": 31184.9627,
      "pb": 3.64,
      "pe": 26.24,
      "yield": 1.21,
      "date": "2018-05-26"
  },
  "2018-05-27": {
      "equity": 10605.15,
      "debt": 7.794,
      "gold": 31184.9627,
      "pb": 3.64,
      "pe": 26.24,
      "yield": 1.21,
      "date": "2018-05-27"
  },
  "2018-05-28": {
      "equity": 10688.65,
      "debt": 7.739,
      "gold": 31184.9627,
      "pb": 3.67,
      "pe": 27.23,
      "yield": 1.2,
      "date": "2018-05-28"
  },
  "2018-05-29": {
      "equity": 10633.3,
      "debt": 7.756,
      "gold": 31138.00596,
      "pb": 3.65,
      "pe": 27.06,
      "yield": 1.21,
      "date": "2018-05-29"
  },
  "2018-05-30": {
      "equity": 10614.35,
      "debt": 7.782,
      "gold": 30908.18533,
      "pb": 3.65,
      "pe": 27.01,
      "yield": 1.21,
      "date": "2018-05-30"
  },
  "2018-05-31": {
      "equity": 10736.15,
      "debt": 7.826,
      "gold": 30999.47794,
      "pb": 3.69,
      "pe": 27.19,
      "yield": 1.23,
      "date": "2018-05-31"
  },
  "2018-06-01": {
      "equity": 10696.2,
      "debt": 7.848,
      "gold": 30687.01388,
      "pb": 3.68,
      "pe": 27.09,
      "yield": 1.23,
      "date": "2018-06-01"
  },
  "2018-06-02": {
      "equity": 10696.2,
      "debt": 7.848,
      "gold": 30687.01388,
      "pb": 3.68,
      "pe": 27.09,
      "yield": 1.23,
      "date": "2018-06-02"
  },
  "2018-06-03": {
      "equity": 10696.2,
      "debt": 7.848,
      "gold": 30687.01388,
      "pb": 3.68,
      "pe": 27.09,
      "yield": 1.23,
      "date": "2018-06-03"
  },
  "2018-06-04": {
      "equity": 10628.5,
      "debt": 7.876,
      "gold": 30624.7447,
      "pb": 3.66,
      "pe": 26.92,
      "yield": 1.24,
      "date": "2018-06-04"
  },
  "2018-06-05": {
      "equity": 10593.15,
      "debt": 7.834,
      "gold": 30594.95582,
      "pb": 3.64,
      "pe": 26.83,
      "yield": 1.25,
      "date": "2018-06-05"
  },
  "2018-06-06": {
      "equity": 10684.65,
      "debt": 7.917,
      "gold": 30539.97425,
      "pb": 3.67,
      "pe": 27.06,
      "yield": 1.23,
      "date": "2018-06-06"
  },
  "2018-06-07": {
      "equity": 10768.35,
      "debt": 7.993,
      "gold": 30895.50433,
      "pb": 3.7,
      "pe": 27.27,
      "yield": 1.23,
      "date": "2018-06-07"
  },
  "2018-06-08": {
      "equity": 10767.65,
      "debt": 7.947,
      "gold": 30960.50371,
      "pb": 3.7,
      "pe": 27.27,
      "yield": 1.23,
      "date": "2018-06-08"
  },
  "2018-06-09": {
      "equity": 10767.65,
      "debt": 7.947,
      "gold": 30960.50371,
      "pb": 3.7,
      "pe": 27.27,
      "yield": 1.23,
      "date": "2018-06-09"
  },
  "2018-06-10": {
      "equity": 10767.65,
      "debt": 7.947,
      "gold": 30960.50371,
      "pb": 3.7,
      "pe": 27.27,
      "yield": 1.23,
      "date": "2018-06-10"
  },
  "2018-06-11": {
      "equity": 10786.95,
      "debt": 7.963,
      "gold": 30861.1651,
      "pb": 3.71,
      "pe": 27.47,
      "yield": 1.22,
      "date": "2018-06-11"
  },
  "2018-06-12": {
      "equity": 10842.85,
      "debt": 7.966,
      "gold": 30915.88564,
      "pb": 3.73,
      "pe": 27.6,
      "yield": 1.22,
      "date": "2018-06-12"
  },
  "2018-06-13": {
      "equity": 10856.7,
      "debt": 7.93,
      "gold": 30793.33674,
      "pb": 3.73,
      "pe": 27.54,
      "yield": 1.22,
      "date": "2018-06-13"
  },
  "2018-06-14": {
      "equity": 10808.05,
      "debt": 7.945,
      "gold": 31196.99113,
      "pb": 3.72,
      "pe": 27.42,
      "yield": 1.22,
      "date": "2018-06-14"
  },
  "2018-06-15": {
      "equity": 10817.7,
      "debt": 7.889,
      "gold": 31274.03305,
      "pb": 3.72,
      "pe": 27.44,
      "yield": 1.22,
      "date": "2018-06-15"
  },
  "2018-06-16": {
      "equity": 10817.7,
      "debt": 7.889,
      "gold": 31274.03305,
      "pb": 3.72,
      "pe": 27.44,
      "yield": 1.22,
      "date": "2018-06-16"
  },
  "2018-06-17": {
      "equity": 10817.7,
      "debt": 7.889,
      "gold": 31274.03305,
      "pb": 3.72,
      "pe": 27.44,
      "yield": 1.22,
      "date": "2018-06-17"
  },
  "2018-06-18": {
      "equity": 10799.85,
      "debt": 7.877,
      "gold": 30781.27657,
      "pb": 3.71,
      "pe": 26.6,
      "yield": 1.22,
      "date": "2018-06-18"
  },
  "2018-06-19": {
      "equity": 10710.45,
      "debt": 7.863,
      "gold": 30726.76767,
      "pb": 3.68,
      "pe": 26.38,
      "yield": 1.23,
      "date": "2018-06-19"
  },
  "2018-06-20": {
      "equity": 10772.05,
      "debt": 7.827,
      "gold": 30570.66615,
      "pb": 3.7,
      "pe": 26.52,
      "yield": 1.22,
      "date": "2018-06-20"
  },
  "2018-06-21": {
      "equity": 10741.1,
      "debt": 7.772,
      "gold": 30241.52101,
      "pb": 3.69,
      "pe": 26.43,
      "yield": 1.23,
      "date": "2018-06-21"
  },
  "2018-06-22": {
      "equity": 10821.85,
      "debt": 7.819,
      "gold": 30398.31743,
      "pb": 3.72,
      "pe": 26.63,
      "yield": 1.22,
      "date": "2018-06-22"
  },
  "2018-06-23": {
      "equity": 10821.85,
      "debt": 7.819,
      "gold": 30398.31743,
      "pb": 3.72,
      "pe": 26.63,
      "yield": 1.22,
      "date": "2018-06-23"
  },
  "2018-06-24": {
      "equity": 10821.85,
      "debt": 7.819,
      "gold": 30398.31743,
      "pb": 3.72,
      "pe": 26.63,
      "yield": 1.22,
      "date": "2018-06-24"
  },
  "2018-06-25": {
      "equity": 10762.45,
      "debt": 7.824,
      "gold": 30504.85194,
      "pb": 3.7,
      "pe": 26.48,
      "yield": 1.23,
      "date": "2018-06-25"
  },
  "2018-06-26": {
      "equity": 10769.15,
      "debt": 7.829,
      "gold": 30301.83954,
      "pb": 3.7,
      "pe": 26.02,
      "yield": 1.23,
      "date": "2018-06-26"
  },
  "2018-06-27": {
      "equity": 10671.4,
      "debt": 7.871,
      "gold": 30530.36209,
      "pb": 3.67,
      "pe": 25.73,
      "yield": 1.24,
      "date": "2018-06-27"
  },
  "2018-06-28": {
      "equity": 10589.1,
      "debt": 7.935,
      "gold": 30361.00108,
      "pb": 3.64,
      "pe": 25.57,
      "yield": 1.25,
      "date": "2018-06-28"
  },
  "2018-06-29": {
      "equity": 10714.3,
      "debt": 7.903,
      "gold": 30205.61562,
      "pb": 3.61,
      "pe": 25.9,
      "yield": 1.22,
      "date": "2018-06-29"
  },
  "2018-06-30": {
      "equity": 10714.3,
      "debt": 7.903,
      "gold": 30205.61562,
      "pb": 3.61,
      "pe": 25.9,
      "yield": 1.22,
      "date": "2018-06-30"
  },
  "2018-07-01": {
      "equity": 10714.3,
      "debt": 7.903,
      "gold": 30205.61562,
      "pb": 3.61,
      "pe": 25.9,
      "yield": 1.22,
      "date": "2018-07-01"
  },
  "2018-07-02": {
      "equity": 10657.3,
      "debt": 7.912,
      "gold": 30284.92919,
      "pb": 3.59,
      "pe": 25.76,
      "yield": 1.23,
      "date": "2018-07-02"
  },
  "2018-07-03": {
      "equity": 10699.9,
      "debt": 7.883,
      "gold": 30139.685,
      "pb": 3.6,
      "pe": 25.85,
      "yield": 1.22,
      "date": "2018-07-03"
  },
  "2018-07-04": {
      "equity": 10769.9,
      "debt": 7.852,
      "gold": 30585.10732,
      "pb": 3.62,
      "pe": 26.02,
      "yield": 1.21,
      "date": "2018-07-04"
  },
  "2018-07-05": {
      "equity": 10749.75,
      "debt": 7.897,
      "gold": 30407.34757,
      "pb": 3.62,
      "pe": 26.69,
      "yield": 1.21,
      "date": "2018-07-05"
  },
  "2018-07-06": {
      "equity": 10772.65,
      "debt": 7.87,
      "gold": 30421.36898,
      "pb": 3.63,
      "pe": 26.62,
      "yield": 1.21,
      "date": "2018-07-06"
  },
  "2018-07-07": {
      "equity": 10772.65,
      "debt": 7.87,
      "gold": 30421.36898,
      "pb": 3.63,
      "pe": 26.62,
      "yield": 1.21,
      "date": "2018-07-07"
  },
  "2018-07-08": {
      "equity": 10772.65,
      "debt": 7.87,
      "gold": 30421.36898,
      "pb": 3.63,
      "pe": 26.62,
      "yield": 1.21,
      "date": "2018-07-08"
  },
  "2018-07-09": {
      "equity": 10852.9,
      "debt": 7.89,
      "gold": 30576.16889,
      "pb": 3.65,
      "pe": 26.82,
      "yield": 1.2,
      "date": "2018-07-09"
  },
  "2018-07-10": {
      "equity": 10947.25,
      "debt": 7.9,
      "gold": 30360.85998,
      "pb": 3.68,
      "pe": 27.05,
      "yield": 1.19,
      "date": "2018-07-10"
  },
  "2018-07-11": {
      "equity": 10948.3,
      "debt": 7.872,
      "gold": 30329.01815,
      "pb": 3.68,
      "pe": 27.2,
      "yield": 1.19,
      "date": "2018-07-11"
  },
  "2018-07-12": {
      "equity": 11023.2,
      "debt": 7.78,
      "gold": 29991.09332,
      "pb": 3.71,
      "pe": 27.39,
      "yield": 1.18,
      "date": "2018-07-12"
  },
  "2018-07-13": {
      "equity": 11018.9,
      "debt": 7.791,
      "gold": 29963.26214,
      "pb": 3.71,
      "pe": 27.38,
      "yield": 1.18,
      "date": "2018-07-13"
  },
  "2018-07-14": {
      "equity": 11018.9,
      "debt": 7.791,
      "gold": 29963.26214,
      "pb": 3.71,
      "pe": 27.38,
      "yield": 1.18,
      "date": "2018-07-14"
  },
  "2018-07-15": {
      "equity": 11018.9,
      "debt": 7.791,
      "gold": 29963.26214,
      "pb": 3.71,
      "pe": 27.38,
      "yield": 1.18,
      "date": "2018-07-15"
  },
  "2018-07-16": {
      "equity": 10936.85,
      "debt": 7.8,
      "gold": 30090.44604,
      "pb": 3.68,
      "pe": 27.17,
      "yield": 1.19,
      "date": "2018-07-16"
  },
  "2018-07-17": {
      "equity": 11008.05,
      "debt": 7.746,
      "gold": 30010.4411,
      "pb": 3.7,
      "pe": 27.35,
      "yield": 1.19,
      "date": "2018-07-17"
  },
  "2018-07-18": {
      "equity": 10980.45,
      "debt": 7.752,
      "gold": 29535.94243,
      "pb": 3.7,
      "pe": 27.28,
      "yield": 1.19,
      "date": "2018-07-18"
  },
  "2018-07-19": {
      "equity": 10957.1,
      "debt": 7.785,
      "gold": 29643.54574,
      "pb": 3.69,
      "pe": 27.22,
      "yield": 1.19,
      "date": "2018-07-19"
  },
  "2018-07-20": {
      "equity": 11010.2,
      "debt": 7.785,
      "gold": 29703.02827,
      "pb": 3.59,
      "pe": 27.36,
      "yield": 1.22,
      "date": "2018-07-20"
  },
  "2018-07-21": {
      "equity": 11010.2,
      "debt": 7.785,
      "gold": 29703.02827,
      "pb": 3.59,
      "pe": 27.36,
      "yield": 1.22,
      "date": "2018-07-21"
  },
  "2018-07-22": {
      "equity": 11010.2,
      "debt": 7.785,
      "gold": 29703.02827,
      "pb": 3.59,
      "pe": 27.36,
      "yield": 1.22,
      "date": "2018-07-22"
  },
  "2018-07-23": {
      "equity": 11084.75,
      "debt": 7.81,
      "gold": 29902.61557,
      "pb": 3.61,
      "pe": 27.54,
      "yield": 1.21,
      "date": "2018-07-23"
  },
  "2018-07-24": {
      "equity": 11134.3,
      "debt": 7.784,
      "gold": 29742.81028,
      "pb": 3.63,
      "pe": 27.66,
      "yield": 1.2,
      "date": "2018-07-24"
  },
  "2018-07-25": {
      "equity": 11132,
      "debt": 7.785,
      "gold": 29819.1185,
      "pb": 3.63,
      "pe": 27.66,
      "yield": 1.2,
      "date": "2018-07-25"
  },
  "2018-07-26": {
      "equity": 11167.3,
      "debt": 7.759,
      "gold": 29760.78238,
      "pb": 3.64,
      "pe": 27.75,
      "yield": 1.2,
      "date": "2018-07-26"
  },
  "2018-07-27": {
      "equity": 11278.35,
      "debt": 7.78,
      "gold": 29510.61571,
      "pb": 3.67,
      "pe": 28.02,
      "yield": 1.19,
      "date": "2018-07-27"
  },
  "2018-07-28": {
      "equity": 11278.35,
      "debt": 7.78,
      "gold": 29510.61571,
      "pb": 3.67,
      "pe": 28.02,
      "yield": 1.19,
      "date": "2018-07-28"
  },
  "2018-07-29": {
      "equity": 11278.35,
      "debt": 7.78,
      "gold": 29510.61571,
      "pb": 3.67,
      "pe": 28.02,
      "yield": 1.19,
      "date": "2018-07-29"
  },
  "2018-07-30": {
      "equity": 11319.55,
      "debt": 7.79,
      "gold": 29581.02965,
      "pb": 3.69,
      "pe": 28.12,
      "yield": 1.19,
      "date": "2018-07-30"
  },
  "2018-07-31": {
      "equity": 11356.5,
      "debt": 7.772,
      "gold": 29440.13475,
      "pb": 3.7,
      "pe": 28.22,
      "yield": 1.18,
      "date": "2018-07-31"
  },
  "2018-08-01": {
      "equity": 11346.2,
      "debt": 7.701,
      "gold": 29467.28514,
      "pb": 3.69,
      "pe": 28.14,
      "yield": 1.19,
      "date": "2018-08-01"
  },
  "2018-08-02": {
      "equity": 11244.7,
      "debt": 7.721,
      "gold": 29450.54763,
      "pb": 3.66,
      "pe": 27.89,
      "yield": 1.2,
      "date": "2018-08-02"
  },
  "2018-08-03": {
      "equity": 11360.8,
      "debt": 7.763,
      "gold": 29200.31394,
      "pb": 3.7,
      "pe": 28.23,
      "yield": 1.18,
      "date": "2018-08-03"
  },
  "2018-08-04": {
      "equity": 11360.8,
      "debt": 7.763,
      "gold": 29200.31394,
      "pb": 3.7,
      "pe": 28.23,
      "yield": 1.18,
      "date": "2018-08-04"
  },
  "2018-08-05": {
      "equity": 11360.8,
      "debt": 7.763,
      "gold": 29200.31394,
      "pb": 3.7,
      "pe": 28.23,
      "yield": 1.18,
      "date": "2018-08-05"
  },
  "2018-08-06": {
      "equity": 11387.1,
      "debt": 7.768,
      "gold": 29423.69001,
      "pb": 3.71,
      "pe": 28.26,
      "yield": 1.18,
      "date": "2018-08-06"
  },
  "2018-08-07": {
      "equity": 11389.45,
      "debt": 7.786,
      "gold": 29423.70412,
      "pb": 3.71,
      "pe": 28.26,
      "yield": 1.18,
      "date": "2018-08-07"
  },
  "2018-08-08": {
      "equity": 11450,
      "debt": 7.782,
      "gold": 29361.75241,
      "pb": 3.73,
      "pe": 28.23,
      "yield": 1.17,
      "date": "2018-08-08"
  },
  "2018-08-09": {
      "equity": 11470.7,
      "debt": 7.745,
      "gold": 29484.17785,
      "pb": 3.74,
      "pe": 28.11,
      "yield": 1.17,
      "date": "2018-08-09"
  },
  "2018-08-10": {
      "equity": 11429.5,
      "debt": 7.754,
      "gold": 29539.56507,
      "pb": 3.72,
      "pe": 27.83,
      "yield": 1.18,
      "date": "2018-08-10"
  },
  "2018-08-11": {
      "equity": 11429.5,
      "debt": 7.754,
      "gold": 29539.56507,
      "pb": 3.72,
      "pe": 27.83,
      "yield": 1.18,
      "date": "2018-08-11"
  },
  "2018-08-12": {
      "equity": 11429.5,
      "debt": 7.754,
      "gold": 29539.56507,
      "pb": 3.72,
      "pe": 27.83,
      "yield": 1.18,
      "date": "2018-08-12"
  },
  "2018-08-13": {
      "equity": 11355.75,
      "debt": 7.823,
      "gold": 29802.52209,
      "pb": 3.7,
      "pe": 27.8,
      "yield": 1.18,
      "date": "2018-08-13"
  },
  "2018-08-14": {
      "equity": 11435.1,
      "debt": 7.818,
      "gold": 29534.12935,
      "pb": 3.72,
      "pe": 28.02,
      "yield": 1.18,
      "date": "2018-08-14"
  },
  "2018-08-15": {
      "equity": 11435.1,
      "debt": 7.818,
      "gold": 29460.19154,
      "pb": 3.72,
      "pe": 28.02,
      "yield": 1.18,
      "date": "2018-08-15"
  },
  "2018-08-16": {
      "equity": 11385.05,
      "debt": 7.861,
      "gold": 29146.39764,
      "pb": 3.71,
      "pe": 27.9,
      "yield": 1.18,
      "date": "2018-08-16"
  },
  "2018-08-17": {
      "equity": 11470.75,
      "debt": 7.861,
      "gold": 29065.21103,
      "pb": 3.74,
      "pe": 28.11,
      "yield": 1.17,
      "date": "2018-08-17"
  },
  "2018-08-18": {
      "equity": 11470.75,
      "debt": 7.861,
      "gold": 29065.21103,
      "pb": 3.74,
      "pe": 28.11,
      "yield": 1.17,
      "date": "2018-08-18"
  },
  "2018-08-19": {
      "equity": 11470.75,
      "debt": 7.861,
      "gold": 29065.21103,
      "pb": 3.74,
      "pe": 28.11,
      "yield": 1.17,
      "date": "2018-08-19"
  },
  "2018-08-20": {
      "equity": 11551.75,
      "debt": 7.838,
      "gold": 29278.98905,
      "pb": 3.76,
      "pe": 28.25,
      "yield": 1.16,
      "date": "2018-08-20"
  },
  "2018-08-21": {
      "equity": 11570.9,
      "debt": 7.827,
      "gold": 29403.47096,
      "pb": 3.77,
      "pe": 28.29,
      "yield": 1.16,
      "date": "2018-08-21"
  },
  "2018-08-22": {
      "equity": 11570.9,
      "debt": 7.827,
      "gold": 29480.6046,
      "pb": 3.77,
      "pe": 28.29,
      "yield": 1.16,
      "date": "2018-08-22"
  },
  "2018-08-23": {
      "equity": 11582.75,
      "debt": 7.879,
      "gold": 29389.01921,
      "pb": 3.77,
      "pe": 28.32,
      "yield": 1.16,
      "date": "2018-08-23"
  },
  "2018-08-24": {
      "equity": 11557.1,
      "debt": 7.871,
      "gold": 29302.24872,
      "pb": 3.76,
      "pe": 28.06,
      "yield": 1.16,
      "date": "2018-08-24"
  },
  "2018-08-25": {
      "equity": 11557.1,
      "debt": 7.871,
      "gold": 29302.24872,
      "pb": 3.76,
      "pe": 28.06,
      "yield": 1.16,
      "date": "2018-08-25"
  },
  "2018-08-26": {
      "equity": 11557.1,
      "debt": 7.871,
      "gold": 29302.24872,
      "pb": 3.76,
      "pe": 28.06,
      "yield": 1.16,
      "date": "2018-08-26"
  },
  "2018-08-27": {
      "equity": 11691.95,
      "debt": 7.894,
      "gold": 29302.24872,
      "pb": 3.81,
      "pe": 28.72,
      "yield": 1.15,
      "date": "2018-08-27"
  },
  "2018-08-28": {
      "equity": 11738.5,
      "debt": 7.895,
      "gold": 29987.75287,
      "pb": 3.82,
      "pe": 28.66,
      "yield": 1.15,
      "date": "2018-08-28"
  },
  "2018-08-29": {
      "equity": 11691.9,
      "debt": 7.918,
      "gold": 29963.59724,
      "pb": 3.76,
      "pe": 28.55,
      "yield": 1.14,
      "date": "2018-08-29"
  },
  "2018-08-30": {
      "equity": 11676.8,
      "debt": 7.93,
      "gold": 30106.99307,
      "pb": 3.76,
      "pe": 28.51,
      "yield": 1.15,
      "date": "2018-08-30"
  },
  "2018-08-31": {
      "equity": 11680.5,
      "debt": 7.951,
      "gold": 30167.52676,
      "pb": 3.76,
      "pe": 28.4,
      "yield": 1.15,
      "date": "2018-08-31"
  },
  "2018-09-01": {
      "equity": 11680.5,
      "debt": 7.951,
      "gold": 30167.52676,
      "pb": 3.76,
      "pe": 28.4,
      "yield": 1.15,
      "date": "2018-09-01"
  },
  "2018-09-02": {
      "equity": 11680.5,
      "debt": 7.951,
      "gold": 30167.52676,
      "pb": 3.76,
      "pe": 28.4,
      "yield": 1.15,
      "date": "2018-09-02"
  },
  "2018-09-03": {
      "equity": 11582.35,
      "debt": 7.999,
      "gold": 30170.19701,
      "pb": 3.73,
      "pe": 28.16,
      "yield": 1.16,
      "date": "2018-09-03"
  },
  "2018-09-04": {
      "equity": 11520.3,
      "debt": 8.062,
      "gold": 30100.53087,
      "pb": 3.71,
      "pe": 28.01,
      "yield": 1.16,
      "date": "2018-09-04"
  },
  "2018-09-05": {
      "equity": 11476.95,
      "debt": 8.049,
      "gold": 30151.2196,
      "pb": 3.69,
      "pe": 27.9,
      "yield": 1.17,
      "date": "2018-09-05"
  },
  "2018-09-06": {
      "equity": 11536.9,
      "debt": 8.056,
      "gold": 30573.19177,
      "pb": 3.71,
      "pe": 28.05,
      "yield": 1.16,
      "date": "2018-09-06"
  },
  "2018-09-07": {
      "equity": 11589.1,
      "debt": 8.031,
      "gold": 30493.6595,
      "pb": 3.73,
      "pe": 28.17,
      "yield": 1.15,
      "date": "2018-09-07"
  },
  "2018-09-08": {
      "equity": 11589.1,
      "debt": 8.031,
      "gold": 30493.6595,
      "pb": 3.73,
      "pe": 28.17,
      "yield": 1.15,
      "date": "2018-09-08"
  },
  "2018-09-09": {
      "equity": 11589.1,
      "debt": 8.031,
      "gold": 30493.6595,
      "pb": 3.73,
      "pe": 28.17,
      "yield": 1.15,
      "date": "2018-09-09"
  },
  "2018-09-10": {
      "equity": 11438.1,
      "debt": 8.158,
      "gold": 30614.70573,
      "pb": 3.68,
      "pe": 27.81,
      "yield": 1.17,
      "date": "2018-09-10"
  },
  "2018-09-11": {
      "equity": 11287.5,
      "debt": 8.182,
      "gold": 30572.8355,
      "pb": 3.63,
      "pe": 27.44,
      "yield": 1.19,
      "date": "2018-09-11"
  },
  "2018-09-12": {
      "equity": 11369.9,
      "debt": 8.134,
      "gold": 30406.78319,
      "pb": 3.66,
      "pe": 27.64,
      "yield": 1.18,
      "date": "2018-09-12"
  },
  "2018-09-13": {
      "equity": 11369.9,
      "debt": 8.134,
      "gold": 30506.01245,
      "pb": 3.66,
      "pe": 27.64,
      "yield": 1.18,
      "date": "2018-09-13"
  },
  "2018-09-14": {
      "equity": 11515.2,
      "debt": 8.127,
      "gold": 30610.79031,
      "pb": 3.71,
      "pe": 28,
      "yield": 1.16,
      "date": "2018-09-14"
  },
  "2018-09-15": {
      "equity": 11515.2,
      "debt": 8.127,
      "gold": 30610.79031,
      "pb": 3.71,
      "pe": 28,
      "yield": 1.16,
      "date": "2018-09-15"
  },
  "2018-09-16": {
      "equity": 11515.2,
      "debt": 8.124,
      "gold": 30610.79031,
      "pb": 3.71,
      "pe": 28,
      "yield": 1.16,
      "date": "2018-09-16"
  },
  "2018-09-17": {
      "equity": 11377.75,
      "debt": 8.098,
      "gold": 30583.95033,
      "pb": 3.66,
      "pe": 27.66,
      "yield": 1.18,
      "date": "2018-09-17"
  },
  "2018-09-18": {
      "equity": 11278.9,
      "debt": 8.14,
      "gold": 30769.27635,
      "pb": 3.63,
      "pe": 27.42,
      "yield": 1.19,
      "date": "2018-09-18"
  },
  "2018-09-19": {
      "equity": 11234.35,
      "debt": 8.074,
      "gold": 30609.35819,
      "pb": 3.61,
      "pe": 27.31,
      "yield": 1.19,
      "date": "2018-09-19"
  },
  "2018-09-20": {
      "equity": 11234.35,
      "debt": 8.074,
      "gold": 30461.26034,
      "pb": 3.61,
      "pe": 27.31,
      "yield": 1.19,
      "date": "2018-09-20"
  },
  "2018-09-21": {
      "equity": 11143.1,
      "debt": 8.076,
      "gold": 30795.40733,
      "pb": 3.59,
      "pe": 27.09,
      "yield": 1.2,
      "date": "2018-09-21"
  },
  "2018-09-22": {
      "equity": 11143.1,
      "debt": 8.076,
      "gold": 30795.40733,
      "pb": 3.59,
      "pe": 27.09,
      "yield": 1.2,
      "date": "2018-09-22"
  },
  "2018-09-23": {
      "equity": 11143.1,
      "debt": 8.076,
      "gold": 30795.40733,
      "pb": 3.59,
      "pe": 27.09,
      "yield": 1.2,
      "date": "2018-09-23"
  },
  "2018-09-24": {
      "equity": 10967.4,
      "debt": 8.122,
      "gold": 30804.40219,
      "pb": 3.53,
      "pe": 26.66,
      "yield": 1.22,
      "date": "2018-09-24"
  },
  "2018-09-25": {
      "equity": 11067.45,
      "debt": 8.125,
      "gold": 30768.44389,
      "pb": 3.56,
      "pe": 26.91,
      "yield": 1.21,
      "date": "2018-09-25"
  },
  "2018-09-26": {
      "equity": 11053.8,
      "debt": 8.072,
      "gold": 30688.04388,
      "pb": 3.5,
      "pe": 26.87,
      "yield": 1.22,
      "date": "2018-09-26"
  },
  "2018-09-27": {
      "equity": 10977.55,
      "debt": 8.027,
      "gold": 30621.93689,
      "pb": 3.47,
      "pe": 26.69,
      "yield": 1.23,
      "date": "2018-09-27"
  },
  "2018-09-28": {
      "equity": 10930.45,
      "debt": 8.024,
      "gold": 30287.07032,
      "pb": 3.47,
      "pe": 26.44,
      "yield": 1.23,
      "date": "2018-09-28"
  },
  "2018-09-29": {
      "equity": 10930.45,
      "debt": 8.024,
      "gold": 30287.07032,
      "pb": 3.47,
      "pe": 26.44,
      "yield": 1.23,
      "date": "2018-09-29"
  },
  "2018-09-30": {
      "equity": 10930.45,
      "debt": 8.024,
      "gold": 30287.07032,
      "pb": 3.47,
      "pe": 26.44,
      "yield": 1.23,
      "date": "2018-09-30"
  },
  "2018-10-01": {
      "equity": 11008.3,
      "debt": 7.988,
      "gold": 30607.20295,
      "pb": 3.5,
      "pe": 26.62,
      "yield": 1.22,
      "date": "2018-10-01"
  },
  "2018-10-02": {
      "equity": 11008.3,
      "debt": 7.988,
      "gold": 30855.89164,
      "pb": 3.5,
      "pe": 26.62,
      "yield": 1.22,
      "date": "2018-10-02"
  },
  "2018-10-03": {
      "equity": 10858.25,
      "debt": 8.112,
      "gold": 31191.78821,
      "pb": 3.45,
      "pe": 26.26,
      "yield": 1.24,
      "date": "2018-10-03"
  },
  "2018-10-04": {
      "equity": 10599.25,
      "debt": 8.158,
      "gold": 31251.82807,
      "pb": 3.37,
      "pe": 25.63,
      "yield": 1.27,
      "date": "2018-10-04"
  },
  "2018-10-05": {
      "equity": 10316.45,
      "debt": 8.024,
      "gold": 31430.43793,
      "pb": 3.28,
      "pe": 24.95,
      "yield": 1.3,
      "date": "2018-10-05"
  },
  "2018-10-06": {
      "equity": 10316.45,
      "debt": 8.024,
      "gold": 31430.43793,
      "pb": 3.28,
      "pe": 24.95,
      "yield": 1.3,
      "date": "2018-10-06"
  },
  "2018-10-07": {
      "equity": 10316.45,
      "debt": 8.024,
      "gold": 31430.43793,
      "pb": 3.28,
      "pe": 24.95,
      "yield": 1.3,
      "date": "2018-10-07"
  },
  "2018-10-08": {
      "equity": 10348.05,
      "debt": 7.974,
      "gold": 31189.6753,
      "pb": 3.29,
      "pe": 25.03,
      "yield": 1.3,
      "date": "2018-10-08"
  },
  "2018-10-09": {
      "equity": 10301.05,
      "debt": 8.076,
      "gold": 31046.76626,
      "pb": 3.27,
      "pe": 24.91,
      "yield": 1.3,
      "date": "2018-10-09"
  },
  "2018-10-10": {
      "equity": 10460.1,
      "debt": 8.031,
      "gold": 31063.51435,
      "pb": 3.32,
      "pe": 25.3,
      "yield": 1.28,
      "date": "2018-10-10"
  },
  "2018-10-11": {
      "equity": 10234.65,
      "debt": 7.986,
      "gold": 31277.87439,
      "pb": 3.25,
      "pe": 24.75,
      "yield": 1.31,
      "date": "2018-10-11"
  },
  "2018-10-12": {
      "equity": 10472.5,
      "debt": 7.984,
      "gold": 31668.71373,
      "pb": 3.33,
      "pe": 25.33,
      "yield": 1.28,
      "date": "2018-10-12"
  },
  "2018-10-13": {
      "equity": 10472.5,
      "debt": 7.984,
      "gold": 31668.71373,
      "pb": 3.33,
      "pe": 25.33,
      "yield": 1.28,
      "date": "2018-10-13"
  },
  "2018-10-14": {
      "equity": 10472.5,
      "debt": 7.984,
      "gold": 31668.71373,
      "pb": 3.33,
      "pe": 25.33,
      "yield": 1.28,
      "date": "2018-10-14"
  },
  "2018-10-15": {
      "equity": 10512.5,
      "debt": 7.921,
      "gold": 32067.48267,
      "pb": 3.34,
      "pe": 25.42,
      "yield": 1.28,
      "date": "2018-10-15"
  },
  "2018-10-16": {
      "equity": 10584.75,
      "debt": 7.872,
      "gold": 31755.60416,
      "pb": 3.36,
      "pe": 25.52,
      "yield": 1.27,
      "date": "2018-10-16"
  },
  "2018-10-17": {
      "equity": 10453.05,
      "debt": 7.909,
      "gold": 31748.28833,
      "pb": 3.32,
      "pe": 25.19,
      "yield": 1.29,
      "date": "2018-10-17"
  },
  "2018-10-18": {
      "equity": 10453.05,
      "debt": 7.909,
      "gold": 31827.84882,
      "pb": 3.32,
      "pe": 25.19,
      "yield": 1.29,
      "date": "2018-10-18"
  },
  "2018-10-19": {
      "equity": 10303.55,
      "debt": 7.921,
      "gold": 31815.70045,
      "pb": 3.27,
      "pe": 24.83,
      "yield": 1.3,
      "date": "2018-10-19"
  },
  "2018-10-20": {
      "equity": 10303.55,
      "debt": 7.921,
      "gold": 31815.70045,
      "pb": 3.27,
      "pe": 24.83,
      "yield": 1.3,
      "date": "2018-10-20"
  },
  "2018-10-21": {
      "equity": 10303.55,
      "debt": 7.921,
      "gold": 31815.70045,
      "pb": 3.27,
      "pe": 24.83,
      "yield": 1.3,
      "date": "2018-10-21"
  },
  "2018-10-22": {
      "equity": 10245.25,
      "debt": 7.93,
      "gold": 31775.68564,
      "pb": 3.26,
      "pe": 24.65,
      "yield": 1.31,
      "date": "2018-10-22"
  },
  "2018-10-23": {
      "equity": 10146.8,
      "debt": 7.894,
      "gold": 31987.56945,
      "pb": 3.22,
      "pe": 24.41,
      "yield": 1.32,
      "date": "2018-10-23"
  },
  "2018-10-24": {
      "equity": 10224.75,
      "debt": 7.872,
      "gold": 31827.96169,
      "pb": 3.25,
      "pe": 24.6,
      "yield": 1.31,
      "date": "2018-10-24"
  },
  "2018-10-25": {
      "equity": 10124.9,
      "debt": 7.867,
      "gold": 31834.36392,
      "pb": 3.22,
      "pe": 24.36,
      "yield": 1.33,
      "date": "2018-10-25"
  },
  "2018-10-26": {
      "equity": 10030,
      "debt": 7.877,
      "gold": 31891.08803,
      "pb": 3.19,
      "pe": 24.12,
      "yield": 1.34,
      "date": "2018-10-26"
  },
  "2018-10-27": {
      "equity": 10030,
      "debt": 7.877,
      "gold": 31891.08803,
      "pb": 3.19,
      "pe": 24.12,
      "yield": 1.34,
      "date": "2018-10-27"
  },
  "2018-10-28": {
      "equity": 10030,
      "debt": 7.877,
      "gold": 31891.08803,
      "pb": 3.19,
      "pe": 24.12,
      "yield": 1.34,
      "date": "2018-10-28"
  },
  "2018-10-29": {
      "equity": 10250.85,
      "debt": 7.81,
      "gold": 31924.09037,
      "pb": 3.26,
      "pe": 24.65,
      "yield": 1.31,
      "date": "2018-10-29"
  },
  "2018-10-30": {
      "equity": 10198.4,
      "debt": 7.834,
      "gold": 31696.8906,
      "pb": 3.24,
      "pe": 24.55,
      "yield": 1.32,
      "date": "2018-10-30"
  },
  "2018-10-31": {
      "equity": 10386.6,
      "debt": 7.853,
      "gold": 31811.09367,
      "pb": 3.29,
      "pe": 25,
      "yield": 1.27,
      "date": "2018-10-31"
  },
  "2018-11-01": {
      "equity": 10380.45,
      "debt": 7.823,
      "gold": 31556.99748,
      "pb": 3.29,
      "pe": 24.98,
      "yield": 1.27,
      "date": "2018-11-01"
  },
  "2018-11-02": {
      "equity": 10553,
      "debt": 7.781,
      "gold": 31801.0688,
      "pb": 3.35,
      "pe": 25.4,
      "yield": 1.25,
      "date": "2018-11-02"
  },
  "2018-11-03": {
      "equity": 10553,
      "debt": 7.781,
      "gold": 31801.0688,
      "pb": 3.35,
      "pe": 25.4,
      "yield": 1.25,
      "date": "2018-11-03"
  },
  "2018-11-04": {
      "equity": 10553,
      "debt": 7.781,
      "gold": 31801.0688,
      "pb": 3.35,
      "pe": 25.4,
      "yield": 1.25,
      "date": "2018-11-04"
  },
  "2018-11-05": {
      "equity": 10524,
      "debt": 7.808,
      "gold": 31700.68255,
      "pb": 3.34,
      "pe": 25.33,
      "yield": 1.26,
      "date": "2018-11-05"
  },
  "2018-11-06": {
      "equity": 10530,
      "debt": 7.796,
      "gold": 31810.25415,
      "pb": 3.34,
      "pe": 25.32,
      "yield": 1.26,
      "date": "2018-11-06"
  },
  "2018-11-07": {
      "equity": 10598.4,
      "debt": 7.796,
      "gold": 31508.48869,
      "pb": 3.36,
      "pe": 25.49,
      "yield": 1.25,
      "date": "2018-11-07"
  },
  "2018-11-08": {
      "equity": 10598.4,
      "debt": 7.796,
      "gold": 31337.48743,
      "pb": 3.36,
      "pe": 25.49,
      "yield": 1.25,
      "date": "2018-11-08"
  },
  "2018-11-09": {
      "equity": 10585.2,
      "debt": 7.765,
      "gold": 31233.602,
      "pb": 3.35,
      "pe": 25.46,
      "yield": 1.25,
      "date": "2018-11-09"
  },
  "2018-11-10": {
      "equity": 10585.2,
      "debt": 7.765,
      "gold": 31233.602,
      "pb": 3.35,
      "pe": 25.46,
      "yield": 1.25,
      "date": "2018-11-10"
  },
  "2018-11-11": {
      "equity": 10585.2,
      "debt": 7.765,
      "gold": 31233.602,
      "pb": 3.35,
      "pe": 25.46,
      "yield": 1.25,
      "date": "2018-11-11"
  },
  "2018-11-12": {
      "equity": 10482.2,
      "debt": 7.804,
      "gold": 31041.10478,
      "pb": 3.32,
      "pe": 25.21,
      "yield": 1.26,
      "date": "2018-11-12"
  },
  "2018-11-13": {
      "equity": 10582.5,
      "debt": 7.762,
      "gold": 30585.79869,
      "pb": 3.35,
      "pe": 25.45,
      "yield": 1.25,
      "date": "2018-11-13"
  },
  "2018-11-14": {
      "equity": 10576.3,
      "debt": 7.733,
      "gold": 30589.83756,
      "pb": 3.35,
      "pe": 25.43,
      "yield": 1.25,
      "date": "2018-11-14"
  },
  "2018-11-15": {
      "equity": 10616.7,
      "debt": 7.756,
      "gold": 30685.00326,
      "pb": 3.36,
      "pe": 25.53,
      "yield": 1.25,
      "date": "2018-11-15"
  },
  "2018-11-16": {
      "equity": 10682.2,
      "debt": 7.815,
      "gold": 30792.57482,
      "pb": 3.38,
      "pe": 25.69,
      "yield": 1.24,
      "date": "2018-11-16"
  },
  "2018-11-17": {
      "equity": 10682.2,
      "debt": 7.815,
      "gold": 30792.57482,
      "pb": 3.38,
      "pe": 25.69,
      "yield": 1.24,
      "date": "2018-11-17"
  },
  "2018-11-18": {
      "equity": 10682.2,
      "debt": 7.815,
      "gold": 30792.57482,
      "pb": 3.38,
      "pe": 25.69,
      "yield": 1.24,
      "date": "2018-11-18"
  },
  "2018-11-19": {
      "equity": 10763.4,
      "debt": 7.791,
      "gold": 30809.9649,
      "pb": 3.41,
      "pe": 25.88,
      "yield": 1.23,
      "date": "2018-11-19"
  },
  "2018-11-20": {
      "equity": 10656.2,
      "debt": 7.792,
      "gold": 30823.26672,
      "pb": 3.37,
      "pe": 25.62,
      "yield": 1.25,
      "date": "2018-11-20"
  },
  "2018-11-21": {
      "equity": 10600.05,
      "debt": 7.792,
      "gold": 30693.36673,
      "pb": 3.35,
      "pe": 25.55,
      "yield": 1.25,
      "date": "2018-11-21"
  },
  "2018-11-22": {
      "equity": 10526.75,
      "debt": 7.71,
      "gold": 30758.82467,
      "pb": 3.33,
      "pe": 25.43,
      "yield": 1.26,
      "date": "2018-11-22"
  },
  "2018-11-23": {
      "equity": 10526.75,
      "debt": 7.71,
      "gold": 30416.33539,
      "pb": 3.33,
      "pe": 25.43,
      "yield": 1.26,
      "date": "2018-11-23"
  },
  "2018-11-24": {
      "equity": 10526.75,
      "debt": 7.71,
      "gold": 30416.33539,
      "pb": 3.33,
      "pe": 25.43,
      "yield": 1.26,
      "date": "2018-11-24"
  },
  "2018-11-25": {
      "equity": 10526.75,
      "debt": 7.71,
      "gold": 30416.33539,
      "pb": 3.33,
      "pe": 25.43,
      "yield": 1.26,
      "date": "2018-11-25"
  },
  "2018-11-26": {
      "equity": 10628.6,
      "debt": 7.726,
      "gold": 30606.86432,
      "pb": 3.36,
      "pe": 25.71,
      "yield": 1.25,
      "date": "2018-11-26"
  },
  "2018-11-27": {
      "equity": 10685.6,
      "debt": 7.734,
      "gold": 30635.43272,
      "pb": 3.38,
      "pe": 25.85,
      "yield": 1.24,
      "date": "2018-11-27"
  },
  "2018-11-28": {
      "equity": 10728.85,
      "debt": 7.644,
      "gold": 30157.21618,
      "pb": 3.39,
      "pe": 25.96,
      "yield": 1.24,
      "date": "2018-11-28"
  },
  "2018-11-29": {
      "equity": 10858.7,
      "debt": 7.608,
      "gold": 30187.77051,
      "pb": 3.43,
      "pe": 26.27,
      "yield": 1.22,
      "date": "2018-11-29"
  },
  "2018-11-30": {
      "equity": 10876.75,
      "debt": 7.607,
      "gold": 30022.95279,
      "pb": 3.44,
      "pe": 26.31,
      "yield": 1.22,
      "date": "2018-11-30"
  },
  "2018-12-01": {
      "equity": 10876.75,
      "debt": 7.607,
      "gold": 30022.95279,
      "pb": 3.44,
      "pe": 26.31,
      "yield": 1.22,
      "date": "2018-12-01"
  },
  "2018-12-02": {
      "equity": 10876.75,
      "debt": 7.607,
      "gold": 30022.95279,
      "pb": 3.44,
      "pe": 26.31,
      "yield": 1.22,
      "date": "2018-12-02"
  },
  "2018-12-03": {
      "equity": 10883.75,
      "debt": 7.626,
      "gold": 30613.73569,
      "pb": 3.44,
      "pe": 26.33,
      "yield": 1.22,
      "date": "2018-12-03"
  },
  "2018-12-04": {
      "equity": 10869.5,
      "debt": 7.574,
      "gold": 30862.11044,
      "pb": 3.44,
      "pe": 26.3,
      "yield": 1.22,
      "date": "2018-12-04"
  },
  "2018-12-05": {
      "equity": 10782.9,
      "debt": 7.44,
      "gold": 30801.82719,
      "pb": 3.41,
      "pe": 26.09,
      "yield": 1.23,
      "date": "2018-12-05"
  },
  "2018-12-06": {
      "equity": 10601.15,
      "debt": 7.423,
      "gold": 30785.31191,
      "pb": 3.35,
      "pe": 25.65,
      "yield": 1.25,
      "date": "2018-12-06"
  },
  "2018-12-07": {
      "equity": 10693.7,
      "debt": 7.464,
      "gold": 31207.25233,
      "pb": 3.38,
      "pe": 25.87,
      "yield": 1.24,
      "date": "2018-12-07"
  },
  "2018-12-08": {
      "equity": 10693.7,
      "debt": 7.464,
      "gold": 31207.25233,
      "pb": 3.38,
      "pe": 25.87,
      "yield": 1.24,
      "date": "2018-12-08"
  },
  "2018-12-09": {
      "equity": 10693.7,
      "debt": 7.464,
      "gold": 31207.25233,
      "pb": 3.38,
      "pe": 25.87,
      "yield": 1.24,
      "date": "2018-12-09"
  },
  "2018-12-10": {
      "equity": 10488.45,
      "debt": 7.587,
      "gold": 31919.74109,
      "pb": 3.32,
      "pe": 25.37,
      "yield": 1.27,
      "date": "2018-12-10"
  },
  "2018-12-11": {
      "equity": 10549.15,
      "debt": 7.528,
      "gold": 31854.71349,
      "pb": 3.34,
      "pe": 25.52,
      "yield": 1.26,
      "date": "2018-12-11"
  },
  "2018-12-12": {
      "equity": 10737.6,
      "debt": 7.411,
      "gold": 31517.75869,
      "pb": 3.4,
      "pe": 25.98,
      "yield": 1.24,
      "date": "2018-12-12"
  },
  "2018-12-13": {
      "equity": 10791.55,
      "debt": 7.408,
      "gold": 31403.93305,
      "pb": 3.41,
      "pe": 26.11,
      "yield": 1.23,
      "date": "2018-12-13"
  },
  "2018-12-14": {
      "equity": 10805.45,
      "debt": 7.441,
      "gold": 31425.13625,
      "pb": 3.42,
      "pe": 26.14,
      "yield": 1.23,
      "date": "2018-12-14"
  },
  "2018-12-15": {
      "equity": 10805.45,
      "debt": 7.441,
      "gold": 31425.13625,
      "pb": 3.42,
      "pe": 26.14,
      "yield": 1.23,
      "date": "2018-12-15"
  },
  "2018-12-16": {
      "equity": 10805.45,
      "debt": 7.441,
      "gold": 31425.13625,
      "pb": 3.42,
      "pe": 26.14,
      "yield": 1.23,
      "date": "2018-12-16"
  },
  "2018-12-17": {
      "equity": 10888.35,
      "debt": 7.461,
      "gold": 31296.89765,
      "pb": 3.44,
      "pe": 26.34,
      "yield": 1.22,
      "date": "2018-12-17"
  },
  "2018-12-18": {
      "equity": 10908.7,
      "debt": 7.345,
      "gold": 31052.1914,
      "pb": 3.45,
      "pe": 26.39,
      "yield": 1.22,
      "date": "2018-12-18"
  },
  "2018-12-19": {
      "equity": 10967.3,
      "debt": 7.219,
      "gold": 30961.20214,
      "pb": 3.47,
      "pe": 26.53,
      "yield": 1.21,
      "date": "2018-12-19"
  },
  "2018-12-20": {
      "equity": 10951.7,
      "debt": 7.272,
      "gold": 30964.87063,
      "pb": 3.46,
      "pe": 26.5,
      "yield": 1.21,
      "date": "2018-12-20"
  },
  "2018-12-21": {
      "equity": 10754,
      "debt": 7.276,
      "gold": 31102.30516,
      "pb": 3.4,
      "pe": 26.02,
      "yield": 1.24,
      "date": "2018-12-21"
  },
  "2018-12-22": {
      "equity": 10754,
      "debt": 7.276,
      "gold": 31102.30516,
      "pb": 3.4,
      "pe": 26.02,
      "yield": 1.24,
      "date": "2018-12-22"
  },
  "2018-12-23": {
      "equity": 10754,
      "debt": 7.276,
      "gold": 31102.30516,
      "pb": 3.4,
      "pe": 26.02,
      "yield": 1.24,
      "date": "2018-12-23"
  },
  "2018-12-24": {
      "equity": 10663.5,
      "debt": 7.287,
      "gold": 31140.29877,
      "pb": 3.37,
      "pe": 25.8,
      "yield": 1.25,
      "date": "2018-12-24"
  },
  "2018-12-25": {
      "equity": 10663.5,
      "debt": 7.287,
      "gold": 31140.29877,
      "pb": 3.37,
      "pe": 25.8,
      "yield": 1.25,
      "date": "2018-12-25"
  },
  "2018-12-26": {
      "equity": 10729.85,
      "debt": 7.263,
      "gold": 31140.29877,
      "pb": 3.39,
      "pe": 25.96,
      "yield": 1.24,
      "date": "2018-12-26"
  },
  "2018-12-27": {
      "equity": 10779.8,
      "debt": 7.277,
      "gold": 31447.39061,
      "pb": 3.41,
      "pe": 26.08,
      "yield": 1.23,
      "date": "2018-12-27"
  },
  "2018-12-28": {
      "equity": 10859.9,
      "debt": 7.391,
      "gold": 31494.7918,
      "pb": 3.43,
      "pe": 26.16,
      "yield": 1.24,
      "date": "2018-12-28"
  },
  "2018-12-29": {
      "equity": 10859.9,
      "debt": 7.391,
      "gold": 31494.7918,
      "pb": 3.43,
      "pe": 26.16,
      "yield": 1.24,
      "date": "2018-12-29"
  },
  "2018-12-30": {
      "equity": 10859.9,
      "debt": 7.391,
      "gold": 31494.7918,
      "pb": 3.43,
      "pe": 26.16,
      "yield": 1.24,
      "date": "2018-12-30"
  },
  "2018-12-31": {
      "equity": 10862.55,
      "debt": 7.37,
      "gold": 31506.09005,
      "pb": 3.38,
      "pe": 26.17,
      "yield": 1.24,
      "date": "2018-12-31"
  },
  "2019-01-01": {
      "equity": 10910.1,
      "debt": 7.418,
      "gold": 31506.09005,
      "pb": 3.4,
      "pe": 26.28,
      "yield": 1.24,
      "date": "2019-01-01"
  },
  "2019-01-02": {
      "equity": 10792.5,
      "debt": 7.354,
      "gold": 31790.7688,
      "pb": 3.36,
      "pe": 26,
      "yield": 1.25,
      "date": "2019-01-02"
  },
  "2019-01-03": {
      "equity": 10672.25,
      "debt": 7.427,
      "gold": 31859.71887,
      "pb": 3.32,
      "pe": 25.71,
      "yield": 1.26,
      "date": "2019-01-03"
  },
  "2019-01-04": {
      "equity": 10727.35,
      "debt": 7.448,
      "gold": 31656.2338,
      "pb": 3.34,
      "pe": 25.84,
      "yield": 1.26,
      "date": "2019-01-04"
  },
  "2019-01-05": {
      "equity": 10727.35,
      "debt": 7.448,
      "gold": 31656.2338,
      "pb": 3.34,
      "pe": 25.84,
      "yield": 1.26,
      "date": "2019-01-05"
  },
  "2019-01-06": {
      "equity": 10727.35,
      "debt": 7.448,
      "gold": 31656.2338,
      "pb": 3.34,
      "pe": 25.84,
      "yield": 1.26,
      "date": "2019-01-06"
  },
  "2019-01-07": {
      "equity": 10771.8,
      "debt": 7.508,
      "gold": 31781.93972,
      "pb": 3.36,
      "pe": 25.95,
      "yield": 1.25,
      "date": "2019-01-07"
  },
  "2019-01-08": {
      "equity": 10802.15,
      "debt": 7.454,
      "gold": 31766.33098,
      "pb": 3.36,
      "pe": 26.02,
      "yield": 1.25,
      "date": "2019-01-08"
  },
  "2019-01-09": {
      "equity": 10855.15,
      "debt": 7.47,
      "gold": 31900.86598,
      "pb": 3.38,
      "pe": 26.15,
      "yield": 1.24,
      "date": "2019-01-09"
  },
  "2019-01-10": {
      "equity": 10821.6,
      "debt": 7.476,
      "gold": 32095.48669,
      "pb": 3.37,
      "pe": 26.07,
      "yield": 1.25,
      "date": "2019-01-10"
  },
  "2019-01-11": {
      "equity": 10794.95,
      "debt": 7.503,
      "gold": 32092.78823,
      "pb": 3.36,
      "pe": 26,
      "yield": 1.25,
      "date": "2019-01-11"
  },
  "2019-01-12": {
      "equity": 10794.95,
      "debt": 7.503,
      "gold": 32092.78823,
      "pb": 3.36,
      "pe": 26,
      "yield": 1.25,
      "date": "2019-01-12"
  },
  "2019-01-13": {
      "equity": 10794.95,
      "debt": 7.503,
      "gold": 32092.78823,
      "pb": 3.36,
      "pe": 26,
      "yield": 1.25,
      "date": "2019-01-13"
  },
  "2019-01-14": {
      "equity": 10737.6,
      "debt": 7.431,
      "gold": 32288.53772,
      "pb": 3.34,
      "pe": 25.8,
      "yield": 1.26,
      "date": "2019-01-14"
  },
  "2019-01-15": {
      "equity": 10886.8,
      "debt": 7.473,
      "gold": 32348.02025,
      "pb": 3.39,
      "pe": 26.16,
      "yield": 1.24,
      "date": "2019-01-15"
  },
  "2019-01-16": {
      "equity": 10890.3,
      "debt": 7.563,
      "gold": 32319.74109,
      "pb": 3.39,
      "pe": 26.17,
      "yield": 1.24,
      "date": "2019-01-16"
  },
  "2019-01-17": {
      "equity": 10905.2,
      "debt": 7.549,
      "gold": 32450.66756,
      "pb": 3.4,
      "pe": 26.21,
      "yield": 1.24,
      "date": "2019-01-17"
  },
  "2019-01-18": {
      "equity": 10906.95,
      "debt": 7.596,
      "gold": 32288.64001,
      "pb": 3.4,
      "pe": 26.19,
      "yield": 1.24,
      "date": "2019-01-18"
  },
  "2019-01-19": {
      "equity": 10906.95,
      "debt": 7.596,
      "gold": 32288.64001,
      "pb": 3.4,
      "pe": 26.19,
      "yield": 1.24,
      "date": "2019-01-19"
  },
  "2019-01-20": {
      "equity": 10906.95,
      "debt": 7.596,
      "gold": 32288.64001,
      "pb": 3.4,
      "pe": 26.19,
      "yield": 1.24,
      "date": "2019-01-20"
  },
  "2019-01-21": {
      "equity": 10961.85,
      "debt": 7.569,
      "gold": 32113.52581,
      "pb": 3.41,
      "pe": 26.29,
      "yield": 1.23,
      "date": "2019-01-21"
  },
  "2019-01-22": {
      "equity": 10922.75,
      "debt": 7.533,
      "gold": 32276.3435,
      "pb": 3.4,
      "pe": 26.54,
      "yield": 1.24,
      "date": "2019-01-22"
  },
  "2019-01-23": {
      "equity": 10831.5,
      "debt": 7.577,
      "gold": 32283.60289,
      "pb": 3.37,
      "pe": 26.32,
      "yield": 1.25,
      "date": "2019-01-23"
  },
  "2019-01-24": {
      "equity": 10849.8,
      "debt": 7.557,
      "gold": 32080.08254,
      "pb": 3.38,
      "pe": 26.31,
      "yield": 1.24,
      "date": "2019-01-24"
  },
  "2019-01-25": {
      "equity": 10780.55,
      "debt": 7.546,
      "gold": 32079.06665,
      "pb": 3.36,
      "pe": 26.14,
      "yield": 1.25,
      "date": "2019-01-25"
  },
  "2019-01-26": {
      "equity": 10780.55,
      "debt": 7.546,
      "gold": 32079.06665,
      "pb": 3.36,
      "pe": 26.14,
      "yield": 1.25,
      "date": "2019-01-26"
  },
  "2019-01-27": {
      "equity": 10780.55,
      "debt": 7.546,
      "gold": 32079.06665,
      "pb": 3.36,
      "pe": 26.14,
      "yield": 1.25,
      "date": "2019-01-27"
  },
  "2019-01-28": {
      "equity": 10661.55,
      "debt": 7.541,
      "gold": 32614.37415,
      "pb": 3.32,
      "pe": 25.85,
      "yield": 1.27,
      "date": "2019-01-28"
  },
  "2019-01-29": {
      "equity": 10652.2,
      "debt": 7.525,
      "gold": 32955.07857,
      "pb": 3.32,
      "pe": 25.84,
      "yield": 1.27,
      "date": "2019-01-29"
  },
  "2019-01-30": {
      "equity": 10651.8,
      "debt": 7.551,
      "gold": 32993.6013,
      "pb": 3.32,
      "pe": 25.84,
      "yield": 1.27,
      "date": "2019-01-30"
  },
  "2019-01-31": {
      "equity": 10830.95,
      "debt": 7.483,
      "gold": 33104.36868,
      "pb": 3.37,
      "pe": 26.26,
      "yield": 1.25,
      "date": "2019-01-31"
  },
  "2019-02-01": {
      "equity": 10893.65,
      "debt": 7.61,
      "gold": 33282.55525,
      "pb": 3.39,
      "pe": 26.4,
      "yield": 1.24,
      "date": "2019-02-01"
  },
  "2019-02-02": {
      "equity": 10893.65,
      "debt": 7.61,
      "gold": 33282.55525,
      "pb": 3.39,
      "pe": 26.4,
      "yield": 1.24,
      "date": "2019-02-02"
  },
  "2019-02-03": {
      "equity": 10893.65,
      "debt": 7.61,
      "gold": 33282.55525,
      "pb": 3.39,
      "pe": 26.4,
      "yield": 1.24,
      "date": "2019-02-03"
  },
  "2019-02-04": {
      "equity": 10912.25,
      "debt": 7.672,
      "gold": 33142.06247,
      "pb": 3.4,
      "pe": 27.02,
      "yield": 1.24,
      "date": "2019-02-04"
  },
  "2019-02-05": {
      "equity": 10934.35,
      "debt": 7.615,
      "gold": 33161.12453,
      "pb": 3.41,
      "pe": 27.07,
      "yield": 1.23,
      "date": "2019-02-05"
  },
  "2019-02-06": {
      "equity": 11062.45,
      "debt": 7.565,
      "gold": 33150.51059,
      "pb": 3.45,
      "pe": 27.39,
      "yield": 1.22,
      "date": "2019-02-06"
  },
  "2019-02-07": {
      "equity": 11069.4,
      "debt": 7.501,
      "gold": 32870.67144,
      "pb": 3.45,
      "pe": 27.41,
      "yield": 1.22,
      "date": "2019-02-07"
  },
  "2019-02-08": {
      "equity": 10943.6,
      "debt": 7.524,
      "gold": 32932.77836,
      "pb": 3.41,
      "pe": 27.1,
      "yield": 1.23,
      "date": "2019-02-08"
  },
  "2019-02-09": {
      "equity": 10943.6,
      "debt": 7.524,
      "gold": 32932.77836,
      "pb": 3.41,
      "pe": 27.1,
      "yield": 1.23,
      "date": "2019-02-09"
  },
  "2019-02-10": {
      "equity": 10943.6,
      "debt": 7.524,
      "gold": 32932.77836,
      "pb": 3.41,
      "pe": 27.1,
      "yield": 1.23,
      "date": "2019-02-10"
  },
  "2019-02-11": {
      "equity": 10888.8,
      "debt": 7.529,
      "gold": 32807.83788,
      "pb": 3.39,
      "pe": 26.96,
      "yield": 1.24,
      "date": "2019-02-11"
  },
  "2019-02-12": {
      "equity": 10831.4,
      "debt": 7.534,
      "gold": 32652.93921,
      "pb": 3.37,
      "pe": 26.84,
      "yield": 1.25,
      "date": "2019-02-12"
  },
  "2019-02-13": {
      "equity": 10793.65,
      "debt": 7.466,
      "gold": 32774.47221,
      "pb": 3.36,
      "pe": 26.6,
      "yield": 1.25,
      "date": "2019-02-13"
  },
  "2019-02-14": {
      "equity": 10746.05,
      "debt": 7.521,
      "gold": 32714.73218,
      "pb": 3.35,
      "pe": 26.58,
      "yield": 1.26,
      "date": "2019-02-14"
  },
  "2019-02-15": {
      "equity": 10724.4,
      "debt": 7.577,
      "gold": 33183.03674,
      "pb": 3.34,
      "pe": 26.53,
      "yield": 1.26,
      "date": "2019-02-15"
  },
  "2019-02-16": {
      "equity": 10724.4,
      "debt": 7.577,
      "gold": 33183.03674,
      "pb": 3.34,
      "pe": 26.53,
      "yield": 1.26,
      "date": "2019-02-16"
  },
  "2019-02-17": {
      "equity": 10724.4,
      "debt": 7.577,
      "gold": 33183.03674,
      "pb": 3.34,
      "pe": 26.53,
      "yield": 1.26,
      "date": "2019-02-17"
  },
  "2019-02-18": {
      "equity": 10640.95,
      "debt": 7.58,
      "gold": 33342.88083,
      "pb": 3.31,
      "pe": 26.32,
      "yield": 1.27,
      "date": "2019-02-18"
  },
  "2019-02-19": {
      "equity": 10604.35,
      "debt": 7.58,
      "gold": 33410.28237,
      "pb": 3.3,
      "pe": 26.23,
      "yield": 1.27,
      "date": "2019-02-19"
  },
  "2019-02-20": {
      "equity": 10735.45,
      "debt": 7.545,
      "gold": 33690.86227,
      "pb": 3.34,
      "pe": 26.5,
      "yield": 1.26,
      "date": "2019-02-20"
  },
  "2019-02-21": {
      "equity": 10789.85,
      "debt": 7.544,
      "gold": 33539.07124,
      "pb": 3.36,
      "pe": 26.31,
      "yield": 1.25,
      "date": "2019-02-21"
  },
  "2019-02-22": {
      "equity": 10791.65,
      "debt": 7.605,
      "gold": 33119.79753,
      "pb": 3.41,
      "pe": 26.32,
      "yield": 1.25,
      "date": "2019-02-22"
  },
  "2019-02-23": {
      "equity": 10791.65,
      "debt": 7.605,
      "gold": 33119.79753,
      "pb": 3.41,
      "pe": 26.32,
      "yield": 1.25,
      "date": "2019-02-23"
  },
  "2019-02-24": {
      "equity": 10791.65,
      "debt": 7.605,
      "gold": 33119.79753,
      "pb": 3.41,
      "pe": 26.32,
      "yield": 1.25,
      "date": "2019-02-24"
  },
  "2019-02-25": {
      "equity": 10880.1,
      "debt": 7.584,
      "gold": 33215.27011,
      "pb": 3.43,
      "pe": 26.53,
      "yield": 1.24,
      "date": "2019-02-25"
  },
  "2019-02-26": {
      "equity": 10835.3,
      "debt": 7.587,
      "gold": 33241.3129,
      "pb": 3.42,
      "pe": 26.43,
      "yield": 1.25,
      "date": "2019-02-26"
  },
  "2019-02-27": {
      "equity": 10806.65,
      "debt": 7.672,
      "gold": 33281.38062,
      "pb": 3.41,
      "pe": 26.36,
      "yield": 1.25,
      "date": "2019-02-27"
  },
  "2019-02-28": {
      "equity": 10792.5,
      "debt": 7.591,
      "gold": 33117.19783,
      "pb": 3.41,
      "pe": 26.32,
      "yield": 1.25,
      "date": "2019-02-28"
  },
  "2019-03-01": {
      "equity": 10863.5,
      "debt": 7.556,
      "gold": 32790.91342,
      "pb": 3.43,
      "pe": 26.49,
      "yield": 1.24,
      "date": "2019-03-01"
  },
  "2019-03-02": {
      "equity": 10863.5,
      "debt": 7.556,
      "gold": 32790.91342,
      "pb": 3.43,
      "pe": 26.49,
      "yield": 1.24,
      "date": "2019-03-02"
  },
  "2019-03-03": {
      "equity": 10863.5,
      "debt": 7.556,
      "gold": 32790.91342,
      "pb": 3.43,
      "pe": 26.49,
      "yield": 1.24,
      "date": "2019-03-03"
  },
  "2019-03-04": {
      "equity": 10863.5,
      "debt": 7.556,
      "gold": 32207.02658,
      "pb": 3.43,
      "pe": 26.49,
      "yield": 1.24,
      "date": "2019-03-04"
  },
  "2019-03-05": {
      "equity": 10987.45,
      "debt": 7.556,
      "gold": 32000.19048,
      "pb": 3.47,
      "pe": 26.93,
      "yield": 1.23,
      "date": "2019-03-05"
  },
  "2019-03-06": {
      "equity": 11053,
      "debt": 7.572,
      "gold": 31785.84102,
      "pb": 3.49,
      "pe": 27.09,
      "yield": 1.22,
      "date": "2019-03-06"
  },
  "2019-03-07": {
      "equity": 11058.2,
      "debt": 7.58,
      "gold": 31819.3372,
      "pb": 3.49,
      "pe": 27.11,
      "yield": 1.22,
      "date": "2019-03-07"
  },
  "2019-03-08": {
      "equity": 11035.4,
      "debt": 7.532,
      "gold": 31950.45415,
      "pb": 3.48,
      "pe": 27.05,
      "yield": 1.22,
      "date": "2019-03-08"
  },
  "2019-03-09": {
      "equity": 11035.4,
      "debt": 7.532,
      "gold": 31950.45415,
      "pb": 3.48,
      "pe": 27.05,
      "yield": 1.22,
      "date": "2019-03-09"
  },
  "2019-03-10": {
      "equity": 11035.4,
      "debt": 7.532,
      "gold": 31950.45415,
      "pb": 3.48,
      "pe": 27.05,
      "yield": 1.22,
      "date": "2019-03-10"
  },
  "2019-03-11": {
      "equity": 11168.05,
      "debt": 7.512,
      "gold": 31912.03725,
      "pb": 3.52,
      "pe": 27.38,
      "yield": 1.21,
      "date": "2019-03-11"
  },
  "2019-03-12": {
      "equity": 11301.2,
      "debt": 7.513,
      "gold": 31845.23537,
      "pb": 3.57,
      "pe": 27.7,
      "yield": 1.19,
      "date": "2019-03-12"
  },
  "2019-03-13": {
      "equity": 11341.7,
      "debt": 7.548,
      "gold": 32106.53451,
      "pb": 3.58,
      "pe": 27.8,
      "yield": 1.19,
      "date": "2019-03-13"
  },
  "2019-03-14": {
      "equity": 11343.25,
      "debt": 7.553,
      "gold": 31782.15489,
      "pb": 3.58,
      "pe": 27.81,
      "yield": 1.19,
      "date": "2019-03-14"
  },
  "2019-03-15": {
      "equity": 11426.85,
      "debt": 7.502,
      "gold": 31685.92391,
      "pb": 3.61,
      "pe": 28.01,
      "yield": 1.18,
      "date": "2019-03-15"
  },
  "2019-03-16": {
      "equity": 11426.85,
      "debt": 7.502,
      "gold": 31685.92391,
      "pb": 3.61,
      "pe": 28.01,
      "yield": 1.18,
      "date": "2019-03-16"
  },
  "2019-03-17": {
      "equity": 11426.85,
      "debt": 7.502,
      "gold": 31685.92391,
      "pb": 3.61,
      "pe": 28.01,
      "yield": 1.18,
      "date": "2019-03-17"
  },
  "2019-03-18": {
      "equity": 11462.2,
      "debt": 7.48,
      "gold": 31612.1272,
      "pb": 3.62,
      "pe": 28.1,
      "yield": 1.18,
      "date": "2019-03-18"
  },
  "2019-03-19": {
      "equity": 11532.4,
      "debt": 7.536,
      "gold": 31862.23037,
      "pb": 3.64,
      "pe": 28.27,
      "yield": 1.17,
      "date": "2019-03-19"
  },
  "2019-03-20": {
      "equity": 11521.05,
      "debt": 7.517,
      "gold": 31619.01268,
      "pb": 3.64,
      "pe": 28.24,
      "yield": 1.17,
      "date": "2019-03-20"
  },
  "2019-03-21": {
      "equity": 11521.05,
      "debt": 7.517,
      "gold": 31963.09282,
      "pb": 3.64,
      "pe": 28.24,
      "yield": 1.17,
      "date": "2019-03-21"
  },
  "2019-03-22": {
      "equity": 11456.9,
      "debt": 7.504,
      "gold": 32011.75329,
      "pb": 3.62,
      "pe": 28.08,
      "yield": 1.18,
      "date": "2019-03-22"
  },
  "2019-03-23": {
      "equity": 11456.9,
      "debt": 7.504,
      "gold": 32011.75329,
      "pb": 3.62,
      "pe": 28.08,
      "yield": 1.18,
      "date": "2019-03-23"
  },
  "2019-03-24": {
      "equity": 11456.9,
      "debt": 7.504,
      "gold": 32011.75329,
      "pb": 3.62,
      "pe": 28.08,
      "yield": 1.18,
      "date": "2019-03-24"
  },
  "2019-03-25": {
      "equity": 11354.25,
      "debt": 7.471,
      "gold": 32074.73148,
      "pb": 3.58,
      "pe": 27.83,
      "yield": 1.19,
      "date": "2019-03-25"
  },
  "2019-03-26": {
      "equity": 11483.25,
      "debt": 7.342,
      "gold": 32004.88898,
      "pb": 3.62,
      "pe": 28.15,
      "yield": 1.18,
      "date": "2019-03-26"
  },
  "2019-03-27": {
      "equity": 11445.05,
      "debt": 7.327,
      "gold": 32121.78698,
      "pb": 3.61,
      "pe": 28.05,
      "yield": 1.18,
      "date": "2019-03-27"
  },
  "2019-03-28": {
      "equity": 11570,
      "debt": 7.323,
      "gold": 31883.62052,
      "pb": 3.65,
      "pe": 28.36,
      "yield": 1.17,
      "date": "2019-03-28"
  },
  "2019-03-29": {
      "equity": 11623.9,
      "debt": 7.346,
      "gold": 31572.61327,
      "pb": 3.71,
      "pe": 29.01,
      "yield": 1.13,
      "date": "2019-03-29"
  },
  "2019-03-30": {
      "equity": 11623.9,
      "debt": 7.346,
      "gold": 31572.61327,
      "pb": 3.71,
      "pe": 29.01,
      "yield": 1.13,
      "date": "2019-03-30"
  },
  "2019-03-31": {
      "equity": 11623.9,
      "debt": 7.346,
      "gold": 31572.61327,
      "pb": 3.71,
      "pe": 29.01,
      "yield": 1.13,
      "date": "2019-03-31"
  },
  "2019-04-01": {
      "equity": 11669.15,
      "debt": 7.346,
      "gold": 31539.77672,
      "pb": 3.72,
      "pe": 29.13,
      "yield": 1.13,
      "date": "2019-04-01"
  },
  "2019-04-02": {
      "equity": 11713.2,
      "debt": 7.272,
      "gold": 31292.67888,
      "pb": 3.74,
      "pe": 29.24,
      "yield": 1.12,
      "date": "2019-04-02"
  },
  "2019-04-03": {
      "equity": 11643.95,
      "debt": 7.271,
      "gold": 31197.01935,
      "pb": 3.72,
      "pe": 29.06,
      "yield": 1.13,
      "date": "2019-04-03"
  },
  "2019-04-04": {
      "equity": 11598,
      "debt": 7.348,
      "gold": 31416.04614,
      "pb": 3.7,
      "pe": 28.95,
      "yield": 1.14,
      "date": "2019-04-04"
  },
  "2019-04-05": {
      "equity": 11665.95,
      "debt": 7.353,
      "gold": 31451.98681,
      "pb": 3.72,
      "pe": 29.12,
      "yield": 1.13,
      "date": "2019-04-05"
  },
  "2019-04-06": {
      "equity": 11665.95,
      "debt": 7.353,
      "gold": 31451.98681,
      "pb": 3.72,
      "pe": 29.12,
      "yield": 1.13,
      "date": "2019-04-06"
  },
  "2019-04-07": {
      "equity": 11665.95,
      "debt": 7.353,
      "gold": 31451.98681,
      "pb": 3.72,
      "pe": 29.12,
      "yield": 1.13,
      "date": "2019-04-07"
  },
  "2019-04-08": {
      "equity": 11604.5,
      "debt": 7.4,
      "gold": 31841.27762,
      "pb": 3.7,
      "pe": 28.96,
      "yield": 1.14,
      "date": "2019-04-08"
  },
  "2019-04-09": {
      "equity": 11671.95,
      "debt": 7.374,
      "gold": 31812.76918,
      "pb": 3.72,
      "pe": 29.13,
      "yield": 1.13,
      "date": "2019-04-09"
  },
  "2019-04-10": {
      "equity": 11584.3,
      "debt": 7.367,
      "gold": 31821.11148,
      "pb": 3.7,
      "pe": 28.91,
      "yield": 1.14,
      "date": "2019-04-10"
  },
  "2019-04-11": {
      "equity": 11596.7,
      "debt": 7.371,
      "gold": 31765.45265,
      "pb": 3.7,
      "pe": 28.94,
      "yield": 1.14,
      "date": "2019-04-11"
  },
  "2019-04-12": {
      "equity": 11643.45,
      "debt": 7.409,
      "gold": 31646.96379,
      "pb": 3.72,
      "pe": 29.06,
      "yield": 1.13,
      "date": "2019-04-12"
  },
  "2019-04-13": {
      "equity": 11643.45,
      "debt": 7.409,
      "gold": 31646.96379,
      "pb": 3.72,
      "pe": 29.06,
      "yield": 1.13,
      "date": "2019-04-13"
  },
  "2019-04-14": {
      "equity": 11643.45,
      "debt": 7.409,
      "gold": 31646.96379,
      "pb": 3.72,
      "pe": 29.06,
      "yield": 1.13,
      "date": "2019-04-14"
  },
  "2019-04-15": {
      "equity": 11690.35,
      "debt": 7.392,
      "gold": 31494.6119,
      "pb": 3.73,
      "pe": 29.18,
      "yield": 1.13,
      "date": "2019-04-15"
  },
  "2019-04-16": {
      "equity": 11787.15,
      "debt": 7.39,
      "gold": 31484.57997,
      "pb": 3.76,
      "pe": 29.42,
      "yield": 1.12,
      "date": "2019-04-16"
  },
  "2019-04-17": {
      "equity": 11787.15,
      "debt": 7.39,
      "gold": 31250.14551,
      "pb": 3.76,
      "pe": 29.42,
      "yield": 1.12,
      "date": "2019-04-17"
  },
  "2019-04-18": {
      "equity": 11752.8,
      "debt": 7.42,
      "gold": 31260.61835,
      "pb": 3.75,
      "pe": 29.33,
      "yield": 1.12,
      "date": "2019-04-18"
  },
  "2019-04-19": {
      "equity": 11752.8,
      "debt": 7.42,
      "gold": 31260.61835,
      "pb": 3.75,
      "pe": 29.33,
      "yield": 1.12,
      "date": "2019-04-19"
  },
  "2019-04-20": {
      "equity": 11752.8,
      "debt": 7.42,
      "gold": 31260.61835,
      "pb": 3.75,
      "pe": 29.33,
      "yield": 1.12,
      "date": "2019-04-20"
  },
  "2019-04-21": {
      "equity": 11752.8,
      "debt": 7.42,
      "gold": 31260.61835,
      "pb": 3.75,
      "pe": 29.33,
      "yield": 1.12,
      "date": "2019-04-21"
  },
  "2019-04-22": {
      "equity": 11594.45,
      "debt": 7.474,
      "gold": 31260.61835,
      "pb": 3.7,
      "pe": 28.94,
      "yield": 1.14,
      "date": "2019-04-22"
  },
  "2019-04-23": {
      "equity": 11575.95,
      "debt": 7.473,
      "gold": 31335.06058,
      "pb": 3.7,
      "pe": 28.94,
      "yield": 1.14,
      "date": "2019-04-23"
  },
  "2019-04-24": {
      "equity": 11726.15,
      "debt": 7.424,
      "gold": 31454.65352,
      "pb": 3.75,
      "pe": 29.27,
      "yield": 1.12,
      "date": "2019-04-24"
  },
  "2019-04-25": {
      "equity": 11641.8,
      "debt": 7.451,
      "gold": 31669.60264,
      "pb": 3.72,
      "pe": 29.06,
      "yield": 1.13,
      "date": "2019-04-25"
  },
  "2019-04-26": {
      "equity": 11754.65,
      "debt": 7.408,
      "gold": 31552.8034,
      "pb": 3.76,
      "pe": 29.34,
      "yield": 1.12,
      "date": "2019-04-26"
  },
  "2019-04-27": {
      "equity": 11754.65,
      "debt": 7.408,
      "gold": 31552.8034,
      "pb": 3.76,
      "pe": 29.34,
      "yield": 1.12,
      "date": "2019-04-27"
  },
  "2019-04-28": {
      "equity": 11754.65,
      "debt": 7.408,
      "gold": 31552.8034,
      "pb": 3.76,
      "pe": 29.34,
      "yield": 1.12,
      "date": "2019-04-28"
  },
  "2019-04-29": {
      "equity": 11754.65,
      "debt": 7.408,
      "gold": 31586.22198,
      "pb": 3.76,
      "pe": 29.34,
      "yield": 1.12,
      "date": "2019-04-29"
  },
  "2019-04-30": {
      "equity": 11748.15,
      "debt": 7.414,
      "gold": 31548.15429,
      "pb": 3.76,
      "pe": 29.33,
      "yield": 1.12,
      "date": "2019-04-30"
  },
  "2019-05-01": {
      "equity": 11748.15,
      "debt": 7.414,
      "gold": 31451.6764,
      "pb": 3.76,
      "pe": 29.33,
      "yield": 1.12,
      "date": "2019-05-01"
  },
  "2019-05-02": {
      "equity": 11724.75,
      "debt": 7.386,
      "gold": 31130.39736,
      "pb": 3.75,
      "pe": 29.27,
      "yield": 1.12,
      "date": "2019-05-02"
  },
  "2019-05-03": {
      "equity": 11712.25,
      "debt": 7.397,
      "gold": 30975.96783,
      "pb": 3.74,
      "pe": 29.24,
      "yield": 1.12,
      "date": "2019-05-03"
  },
  "2019-05-04": {
      "equity": 11712.25,
      "debt": 7.397,
      "gold": 30975.96783,
      "pb": 3.74,
      "pe": 29.24,
      "yield": 1.12,
      "date": "2019-05-04"
  },
  "2019-05-05": {
      "equity": 11712.25,
      "debt": 7.397,
      "gold": 30975.96783,
      "pb": 3.74,
      "pe": 29.24,
      "yield": 1.12,
      "date": "2019-05-05"
  },
  "2019-05-06": {
      "equity": 11598.25,
      "debt": 7.394,
      "gold": 30975.96783,
      "pb": 3.71,
      "pe": 28.95,
      "yield": 1.13,
      "date": "2019-05-06"
  },
  "2019-05-07": {
      "equity": 11497.9,
      "debt": 7.382,
      "gold": 31453.87044,
      "pb": 3.68,
      "pe": 28.73,
      "yield": 1.14,
      "date": "2019-05-07"
  },
  "2019-05-08": {
      "equity": 11359.45,
      "debt": 7.375,
      "gold": 31663.99055,
      "pb": 3.63,
      "pe": 28.38,
      "yield": 1.16,
      "date": "2019-05-08"
  },
  "2019-05-09": {
      "equity": 11301.8,
      "debt": 7.397,
      "gold": 31741.61096,
      "pb": 3.61,
      "pe": 28.24,
      "yield": 1.16,
      "date": "2019-05-09"
  },
  "2019-05-10": {
      "equity": 11278.9,
      "debt": 7.413,
      "gold": 31746.08723,
      "pb": 3.61,
      "pe": 28.14,
      "yield": 1.17,
      "date": "2019-05-10"
  },
  "2019-05-11": {
      "equity": 11278.9,
      "debt": 7.413,
      "gold": 31746.08723,
      "pb": 3.61,
      "pe": 28.14,
      "yield": 1.17,
      "date": "2019-05-11"
  },
  "2019-05-12": {
      "equity": 11278.9,
      "debt": 7.413,
      "gold": 31746.08723,
      "pb": 3.61,
      "pe": 28.14,
      "yield": 1.17,
      "date": "2019-05-12"
  },
  "2019-05-13": {
      "equity": 11148.2,
      "debt": 7.388,
      "gold": 31928.59486,
      "pb": 3.56,
      "pe": 27.8,
      "yield": 1.18,
      "date": "2019-05-13"
  },
  "2019-05-14": {
      "equity": 11222.05,
      "debt": 7.378,
      "gold": 32183.6117,
      "pb": 3.59,
      "pe": 27.98,
      "yield": 1.17,
      "date": "2019-05-14"
  },
  "2019-05-15": {
      "equity": 11157,
      "debt": 7.379,
      "gold": 32183.50588,
      "pb": 3.57,
      "pe": 27.82,
      "yield": 1.18,
      "date": "2019-05-15"
  },
  "2019-05-16": {
      "equity": 11257.1,
      "debt": 7.377,
      "gold": 32078.56576,
      "pb": 3.6,
      "pe": 28.07,
      "yield": 1.17,
      "date": "2019-05-16"
  },
  "2019-05-17": {
      "equity": 11407.15,
      "debt": 7.362,
      "gold": 31896.79183,
      "pb": 3.65,
      "pe": 28.44,
      "yield": 1.15,
      "date": "2019-05-17"
  },
  "2019-05-18": {
      "equity": 11407.15,
      "debt": 7.362,
      "gold": 31896.79183,
      "pb": 3.65,
      "pe": 28.44,
      "yield": 1.15,
      "date": "2019-05-18"
  },
  "2019-05-19": {
      "equity": 11407.15,
      "debt": 7.362,
      "gold": 31896.79183,
      "pb": 3.65,
      "pe": 28.44,
      "yield": 1.15,
      "date": "2019-05-19"
  },
  "2019-05-20": {
      "equity": 11828.25,
      "debt": 7.286,
      "gold": 31390.59595,
      "pb": 3.78,
      "pe": 29.48,
      "yield": 1.11,
      "date": "2019-05-20"
  },
  "2019-05-21": {
      "equity": 11709.1,
      "debt": 7.303,
      "gold": 31348.47528,
      "pb": 3.74,
      "pe": 29.18,
      "yield": 1.12,
      "date": "2019-05-21"
  },
  "2019-05-22": {
      "equity": 11737.9,
      "debt": 7.26,
      "gold": 31325.89993,
      "pb": 3.75,
      "pe": 29.22,
      "yield": 1.12,
      "date": "2019-05-22"
  },
  "2019-05-23": {
      "equity": 11657.05,
      "debt": 7.239,
      "gold": 31362.81769,
      "pb": 3.73,
      "pe": 29.02,
      "yield": 1.13,
      "date": "2019-05-23"
  },
  "2019-05-24": {
      "equity": 11844.1,
      "debt": 7.226,
      "gold": 31365.88652,
      "pb": 3.79,
      "pe": 29.44,
      "yield": 1.11,
      "date": "2019-05-24"
  },
  "2019-05-25": {
      "equity": 11844.1,
      "debt": 7.226,
      "gold": 31365.88652,
      "pb": 3.79,
      "pe": 29.44,
      "yield": 1.11,
      "date": "2019-05-25"
  },
  "2019-05-26": {
      "equity": 11844.1,
      "debt": 7.226,
      "gold": 31365.88652,
      "pb": 3.79,
      "pe": 29.44,
      "yield": 1.11,
      "date": "2019-05-26"
  },
  "2019-05-27": {
      "equity": 11924.75,
      "debt": 7.167,
      "gold": 31365.88652,
      "pb": 3.81,
      "pe": 29.68,
      "yield": 1.22,
      "date": "2019-05-27"
  },
  "2019-05-28": {
      "equity": 11928.75,
      "debt": 7.149,
      "gold": 31523.81171,
      "pb": 3.81,
      "pe": 29.69,
      "yield": 1.22,
      "date": "2019-05-28"
  },
  "2019-05-29": {
      "equity": 11861.1,
      "debt": 7.125,
      "gold": 31634.42036,
      "pb": 3.79,
      "pe": 29.44,
      "yield": 1.23,
      "date": "2019-05-29"
  },
  "2019-05-30": {
      "equity": 11945.9,
      "debt": 7.133,
      "gold": 31425.98635,
      "pb": 3.82,
      "pe": 29.65,
      "yield": 1.22,
      "date": "2019-05-30"
  },
  "2019-05-31": {
      "equity": 11922.8,
      "debt": 7.032,
      "gold": 31812.58223,
      "pb": 3.81,
      "pe": 29.49,
      "yield": 1.22,
      "date": "2019-05-31"
  },
  "2019-06-01": {
      "equity": 11922.8,
      "debt": 7.032,
      "gold": 31812.58223,
      "pb": 3.81,
      "pe": 29.49,
      "yield": 1.22,
      "date": "2019-06-01"
  },
  "2019-06-02": {
      "equity": 11922.8,
      "debt": 7.032,
      "gold": 31812.58223,
      "pb": 3.81,
      "pe": 29.49,
      "yield": 1.22,
      "date": "2019-06-02"
  },
  "2019-06-03": {
      "equity": 12088.55,
      "debt": 6.982,
      "gold": 32062.93938,
      "pb": 3.86,
      "pe": 29.9,
      "yield": 1.2,
      "date": "2019-06-03"
  },
  "2019-06-04": {
      "equity": 12021.65,
      "debt": 7.022,
      "gold": 32401.92596,
      "pb": 3.84,
      "pe": 29.73,
      "yield": 1.21,
      "date": "2019-06-04"
  },
  "2019-06-05": {
      "equity": 12021.65,
      "debt": 7.022,
      "gold": 32745.20538,
      "pb": 3.84,
      "pe": 29.73,
      "yield": 1.21,
      "date": "2019-06-05"
  },
  "2019-06-06": {
      "equity": 11843.75,
      "debt": 6.931,
      "gold": 32623.32316,
      "pb": 3.79,
      "pe": 29.29,
      "yield": 1.23,
      "date": "2019-06-06"
  },
  "2019-06-07": {
      "equity": 11870.65,
      "debt": 6.974,
      "gold": 32635.15406,
      "pb": 3.79,
      "pe": 29.36,
      "yield": 1.23,
      "date": "2019-06-07"
  },
  "2019-06-08": {
      "equity": 11870.65,
      "debt": 6.974,
      "gold": 32635.15406,
      "pb": 3.79,
      "pe": 29.36,
      "yield": 1.23,
      "date": "2019-06-08"
  },
  "2019-06-09": {
      "equity": 11870.65,
      "debt": 6.974,
      "gold": 32635.15406,
      "pb": 3.79,
      "pe": 29.36,
      "yield": 1.23,
      "date": "2019-06-09"
  },
  "2019-06-10": {
      "equity": 11922.7,
      "debt": 7.077,
      "gold": 32571.28697,
      "pb": 3.81,
      "pe": 29.49,
      "yield": 1.22,
      "date": "2019-06-10"
  },
  "2019-06-11": {
      "equity": 11965.6,
      "debt": 7.037,
      "gold": 32387.99979,
      "pb": 3.82,
      "pe": 29.59,
      "yield": 1.22,
      "date": "2019-06-11"
  },
  "2019-06-12": {
      "equity": 11906.2,
      "debt": 7.012,
      "gold": 32709.1342,
      "pb": 3.81,
      "pe": 29.45,
      "yield": 1.22,
      "date": "2019-06-12"
  },
  "2019-06-13": {
      "equity": 11914.05,
      "debt": 7.009,
      "gold": 32771.49862,
      "pb": 3.81,
      "pe": 29.46,
      "yield": 1.22,
      "date": "2019-06-13"
  },
  "2019-06-14": {
      "equity": 11823.3,
      "debt": 6.919,
      "gold": 33314.98615,
      "pb": 3.77,
      "pe": 29.24,
      "yield": 1.23,
      "date": "2019-06-14"
  },
  "2019-06-15": {
      "equity": 11823.3,
      "debt": 6.919,
      "gold": 33314.98615,
      "pb": 3.77,
      "pe": 29.24,
      "yield": 1.23,
      "date": "2019-06-15"
  },
  "2019-06-16": {
      "equity": 11823.3,
      "debt": 6.919,
      "gold": 33314.98615,
      "pb": 3.77,
      "pe": 29.24,
      "yield": 1.23,
      "date": "2019-06-16"
  },
  "2019-06-17": {
      "equity": 11672.15,
      "debt": 6.931,
      "gold": 32851.61643,
      "pb": 3.73,
      "pe": 28.87,
      "yield": 1.25,
      "date": "2019-06-17"
  },
  "2019-06-18": {
      "equity": 11691.5,
      "debt": 6.808,
      "gold": 32982.6064,
      "pb": 3.73,
      "pe": 28.91,
      "yield": 1.25,
      "date": "2019-06-18"
  },
  "2019-06-19": {
      "equity": 11691.45,
      "debt": 6.841,
      "gold": 33006.81141,
      "pb": 3.73,
      "pe": 28.91,
      "yield": 1.25,
      "date": "2019-06-19"
  },
  "2019-06-20": {
      "equity": 11831.75,
      "debt": 6.791,
      "gold": 33896.39676,
      "pb": 3.78,
      "pe": 29.26,
      "yield": 1.23,
      "date": "2019-06-20"
  },
  "2019-06-21": {
      "equity": 11724.1,
      "debt": 6.861,
      "gold": 34069.04531,
      "pb": 3.74,
      "pe": 28.99,
      "yield": 1.24,
      "date": "2019-06-21"
  },
  "2019-06-22": {
      "equity": 11724.1,
      "debt": 6.861,
      "gold": 34069.04531,
      "pb": 3.74,
      "pe": 28.99,
      "yield": 1.24,
      "date": "2019-06-22"
  },
  "2019-06-23": {
      "equity": 11724.1,
      "debt": 6.861,
      "gold": 34069.04531,
      "pb": 3.74,
      "pe": 28.99,
      "yield": 1.24,
      "date": "2019-06-23"
  },
  "2019-06-24": {
      "equity": 11699.65,
      "debt": 6.851,
      "gold": 34345.54049,
      "pb": 3.74,
      "pe": 28.93,
      "yield": 1.25,
      "date": "2019-06-24"
  },
  "2019-06-25": {
      "equity": 11796.45,
      "debt": 6.882,
      "gold": 34933.87538,
      "pb": 3.77,
      "pe": 29.17,
      "yield": 1.24,
      "date": "2019-06-25"
  },
  "2019-06-26": {
      "equity": 11847.55,
      "debt": 6.933,
      "gold": 34332.00586,
      "pb": 3.78,
      "pe": 29.09,
      "yield": 1.23,
      "date": "2019-06-26"
  },
  "2019-06-27": {
      "equity": 11841.55,
      "debt": 6.897,
      "gold": 34145.51227,
      "pb": 3.78,
      "pe": 29.07,
      "yield": 1.23,
      "date": "2019-06-27"
  },
  "2019-06-28": {
      "equity": 11788.85,
      "debt": 6.879,
      "gold": 34359.51251,
      "pb": 3.71,
      "pe": 28.98,
      "yield": 1.24,
      "date": "2019-06-28"
  },
  "2019-06-29": {
      "equity": 11788.85,
      "debt": 6.879,
      "gold": 34359.51251,
      "pb": 3.71,
      "pe": 28.98,
      "yield": 1.24,
      "date": "2019-06-29"
  },
  "2019-06-30": {
      "equity": 11788.85,
      "debt": 6.879,
      "gold": 34359.51251,
      "pb": 3.71,
      "pe": 28.98,
      "yield": 1.24,
      "date": "2019-06-30"
  },
  "2019-07-01": {
      "equity": 11865.6,
      "debt": 6.882,
      "gold": 33786.16554,
      "pb": 3.73,
      "pe": 29.17,
      "yield": 1.23,
      "date": "2019-07-01"
  },
  "2019-07-02": {
      "equity": 11910.3,
      "debt": 6.846,
      "gold": 33851.20725,
      "pb": 3.75,
      "pe": 29.28,
      "yield": 1.23,
      "date": "2019-07-02"
  },
  "2019-07-03": {
      "equity": 11916.75,
      "debt": 6.833,
      "gold": 34582.28187,
      "pb": 3.75,
      "pe": 29.3,
      "yield": 1.23,
      "date": "2019-07-03"
  },
  "2019-07-04": {
      "equity": 11946.75,
      "debt": 6.751,
      "gold": 34226.19094,
      "pb": 3.76,
      "pe": 29.37,
      "yield": 1.23,
      "date": "2019-07-04"
  },
  "2019-07-05": {
      "equity": 11811.15,
      "debt": 6.695,
      "gold": 34204.63853,
      "pb": 3.72,
      "pe": 29.04,
      "yield": 1.24,
      "date": "2019-07-05"
  },
  "2019-07-06": {
      "equity": 11811.15,
      "debt": 6.695,
      "gold": 34204.63853,
      "pb": 3.72,
      "pe": 29.04,
      "yield": 1.24,
      "date": "2019-07-06"
  },
  "2019-07-07": {
      "equity": 11811.15,
      "debt": 6.695,
      "gold": 34204.63853,
      "pb": 3.72,
      "pe": 29.04,
      "yield": 1.24,
      "date": "2019-07-07"
  },
  "2019-07-08": {
      "equity": 11558.6,
      "debt": 6.562,
      "gold": 33994.9664,
      "pb": 3.64,
      "pe": 28.42,
      "yield": 1.27,
      "date": "2019-07-08"
  },
  "2019-07-09": {
      "equity": 11555.9,
      "debt": 6.588,
      "gold": 33575.87612,
      "pb": 3.64,
      "pe": 28.41,
      "yield": 1.27,
      "date": "2019-07-09"
  },
  "2019-07-10": {
      "equity": 11498.9,
      "debt": 6.543,
      "gold": 33662.04695,
      "pb": 3.62,
      "pe": 28.2,
      "yield": 1.27,
      "date": "2019-07-10"
  },
  "2019-07-11": {
      "equity": 11582.9,
      "debt": 6.493,
      "gold": 34356.04861,
      "pb": 3.64,
      "pe": 28.4,
      "yield": 1.26,
      "date": "2019-07-11"
  },
  "2019-07-12": {
      "equity": 11552.5,
      "debt": 6.488,
      "gold": 33998.76541,
      "pb": 3.61,
      "pe": 28.33,
      "yield": 1.29,
      "date": "2019-07-12"
  },
  "2019-07-13": {
      "equity": 11552.5,
      "debt": 6.488,
      "gold": 33998.76541,
      "pb": 3.61,
      "pe": 28.33,
      "yield": 1.29,
      "date": "2019-07-13"
  },
  "2019-07-14": {
      "equity": 11552.5,
      "debt": 6.488,
      "gold": 33998.76541,
      "pb": 3.61,
      "pe": 28.33,
      "yield": 1.29,
      "date": "2019-07-14"
  },
  "2019-07-15": {
      "equity": 11588.35,
      "debt": 6.433,
      "gold": 34236.63557,
      "pb": 3.62,
      "pe": 28.33,
      "yield": 1.29,
      "date": "2019-07-15"
  },
  "2019-07-16": {
      "equity": 11662.6,
      "debt": 6.331,
      "gold": 34320.52417,
      "pb": 3.64,
      "pe": 28.51,
      "yield": 1.28,
      "date": "2019-07-16"
  },
  "2019-07-17": {
      "equity": 11687.5,
      "debt": 6.345,
      "gold": 34018.05323,
      "pb": 3.65,
      "pe": 28.58,
      "yield": 1.28,
      "date": "2019-07-17"
  },
  "2019-07-18": {
      "equity": 11596.9,
      "debt": 6.387,
      "gold": 34509.43403,
      "pb": 3.62,
      "pe": 28.35,
      "yield": 1.29,
      "date": "2019-07-18"
  },
  "2019-07-19": {
      "equity": 11419.25,
      "debt": 6.361,
      "gold": 34913.8327,
      "pb": 3.57,
      "pe": 27.92,
      "yield": 1.31,
      "date": "2019-07-19"
  },
  "2019-07-20": {
      "equity": 11419.25,
      "debt": 6.361,
      "gold": 34913.8327,
      "pb": 3.57,
      "pe": 27.92,
      "yield": 1.31,
      "date": "2019-07-20"
  },
  "2019-07-21": {
      "equity": 11419.25,
      "debt": 6.361,
      "gold": 34913.8327,
      "pb": 3.57,
      "pe": 27.92,
      "yield": 1.31,
      "date": "2019-07-21"
  },
  "2019-07-22": {
      "equity": 11346.2,
      "debt": 6.418,
      "gold": 34621.78169,
      "pb": 3.55,
      "pe": 27.81,
      "yield": 1.31,
      "date": "2019-07-22"
  },
  "2019-07-23": {
      "equity": 11331.05,
      "debt": 6.464,
      "gold": 34517.82571,
      "pb": 3.55,
      "pe": 27.77,
      "yield": 1.31,
      "date": "2019-07-23"
  },
  "2019-07-24": {
      "equity": 11271.3,
      "debt": 6.438,
      "gold": 34671.69791,
      "pb": 3.53,
      "pe": 27.62,
      "yield": 1.32,
      "date": "2019-07-24"
  },
  "2019-07-25": {
      "equity": 11252.15,
      "debt": 6.514,
      "gold": 34737.03945,
      "pb": 3.52,
      "pe": 27.57,
      "yield": 1.32,
      "date": "2019-07-25"
  },
  "2019-07-26": {
      "equity": 11284.3,
      "debt": 6.525,
      "gold": 34454.07856,
      "pb": 3.53,
      "pe": 27.73,
      "yield": 1.32,
      "date": "2019-07-26"
  },
  "2019-07-27": {
      "equity": 11284.3,
      "debt": 6.525,
      "gold": 34454.07856,
      "pb": 3.53,
      "pe": 27.73,
      "yield": 1.32,
      "date": "2019-07-27"
  },
  "2019-07-28": {
      "equity": 11284.3,
      "debt": 6.525,
      "gold": 34454.07856,
      "pb": 3.53,
      "pe": 27.73,
      "yield": 1.32,
      "date": "2019-07-28"
  },
  "2019-07-29": {
      "equity": 11189.2,
      "debt": 6.412,
      "gold": 34402.01062,
      "pb": 3.5,
      "pe": 27.6,
      "yield": 1.33,
      "date": "2019-07-29"
  },
  "2019-07-30": {
      "equity": 11085.4,
      "debt": 6.387,
      "gold": 34715.72691,
      "pb": 3.47,
      "pe": 27.34,
      "yield": 1.34,
      "date": "2019-07-30"
  },
  "2019-07-31": {
      "equity": 11118,
      "debt": 6.369,
      "gold": 34754.40131,
      "pb": 3.45,
      "pe": 27.42,
      "yield": 1.33,
      "date": "2019-07-31"
  },
  "2019-08-01": {
      "equity": 10980,
      "debt": 6.423,
      "gold": 34158.76118,
      "pb": 3.41,
      "pe": 27.05,
      "yield": 1.35,
      "date": "2019-08-01"
  },
  "2019-08-02": {
      "equity": 10997.35,
      "debt": 6.352,
      "gold": 35264.63959,
      "pb": 3.42,
      "pe": 27.09,
      "yield": 1.34,
      "date": "2019-08-02"
  },
  "2019-08-03": {
      "equity": 10997.35,
      "debt": 6.352,
      "gold": 35264.63959,
      "pb": 3.42,
      "pe": 27.09,
      "yield": 1.34,
      "date": "2019-08-03"
  },
  "2019-08-04": {
      "equity": 10997.35,
      "debt": 6.352,
      "gold": 35264.63959,
      "pb": 3.42,
      "pe": 27.09,
      "yield": 1.34,
      "date": "2019-08-04"
  },
  "2019-08-05": {
      "equity": 10862.6,
      "debt": 6.39,
      "gold": 36474.16357,
      "pb": 3.37,
      "pe": 26.76,
      "yield": 1.36,
      "date": "2019-08-05"
  },
  "2019-08-06": {
      "equity": 10948.25,
      "debt": 6.338,
      "gold": 36632.7519,
      "pb": 3.4,
      "pe": 26.97,
      "yield": 1.35,
      "date": "2019-08-06"
  },
  "2019-08-07": {
      "equity": 10855.5,
      "debt": 6.369,
      "gold": 37307.87492,
      "pb": 3.37,
      "pe": 26.74,
      "yield": 1.36,
      "date": "2019-08-07"
  },
  "2019-08-08": {
      "equity": 11032.45,
      "debt": 6.402,
      "gold": 37207.48161,
      "pb": 3.43,
      "pe": 27.2,
      "yield": 1.34,
      "date": "2019-08-08"
  },
  "2019-08-09": {
      "equity": 11109.65,
      "debt": 6.494,
      "gold": 37669.94127,
      "pb": 3.45,
      "pe": 27.41,
      "yield": 1.33,
      "date": "2019-08-09"
  },
  "2019-08-10": {
      "equity": 11109.65,
      "debt": 6.494,
      "gold": 37669.94127,
      "pb": 3.45,
      "pe": 27.41,
      "yield": 1.33,
      "date": "2019-08-10"
  },
  "2019-08-11": {
      "equity": 11109.65,
      "debt": 6.494,
      "gold": 37669.94127,
      "pb": 3.45,
      "pe": 27.41,
      "yield": 1.33,
      "date": "2019-08-11"
  },
  "2019-08-12": {
      "equity": 11109.65,
      "debt": 6.494,
      "gold": 37748.08727,
      "pb": 3.45,
      "pe": 27.41,
      "yield": 1.33,
      "date": "2019-08-12"
  },
  "2019-08-13": {
      "equity": 10925.85,
      "debt": 6.518,
      "gold": 38276.5622,
      "pb": 3.39,
      "pe": 27.03,
      "yield": 1.35,
      "date": "2019-08-13"
  },
  "2019-08-14": {
      "equity": 11029.4,
      "debt": 6.62,
      "gold": 37881.1972,
      "pb": 3.43,
      "pe": 27.24,
      "yield": 1.34,
      "date": "2019-08-14"
  },
  "2019-08-15": {
      "equity": 11029.4,
      "debt": 6.62,
      "gold": 38287.4795,
      "pb": 3.43,
      "pe": 27.24,
      "yield": 1.34,
      "date": "2019-08-15"
  },
  "2019-08-16": {
      "equity": 11047.8,
      "debt": 6.535,
      "gold": 37923.38842,
      "pb": 3.43,
      "pe": 27.33,
      "yield": 1.34,
      "date": "2019-08-16"
  },
  "2019-08-17": {
      "equity": 11047.8,
      "debt": 6.535,
      "gold": 37923.38842,
      "pb": 3.43,
      "pe": 27.33,
      "yield": 1.34,
      "date": "2019-08-17"
  },
  "2019-08-18": {
      "equity": 11047.8,
      "debt": 6.535,
      "gold": 37923.38842,
      "pb": 3.43,
      "pe": 27.33,
      "yield": 1.34,
      "date": "2019-08-18"
  },
  "2019-08-19": {
      "equity": 11053.9,
      "debt": 6.581,
      "gold": 37832.94238,
      "pb": 3.43,
      "pe": 27.35,
      "yield": 1.34,
      "date": "2019-08-19"
  },
  "2019-08-20": {
      "equity": 11017,
      "debt": 6.578,
      "gold": 37903.54327,
      "pb": 3.42,
      "pe": 27.26,
      "yield": 1.34,
      "date": "2019-08-20"
  },
  "2019-08-21": {
      "equity": 10918.7,
      "debt": 6.56,
      "gold": 37781.31889,
      "pb": 3.39,
      "pe": 27.01,
      "yield": 1.35,
      "date": "2019-08-21"
  },
  "2019-08-22": {
      "equity": 10741.35,
      "debt": 6.554,
      "gold": 38029.9582,
      "pb": 3.34,
      "pe": 26.57,
      "yield": 1.38,
      "date": "2019-08-22"
  },
  "2019-08-23": {
      "equity": 10829.35,
      "debt": 6.564,
      "gold": 37825.73943,
      "pb": 3.36,
      "pe": 26.79,
      "yield": 1.36,
      "date": "2019-08-23"
  },
  "2019-08-24": {
      "equity": 10829.35,
      "debt": 6.564,
      "gold": 37825.73943,
      "pb": 3.36,
      "pe": 26.79,
      "yield": 1.36,
      "date": "2019-08-24"
  },
  "2019-08-25": {
      "equity": 10829.35,
      "debt": 6.564,
      "gold": 37825.73943,
      "pb": 3.36,
      "pe": 26.79,
      "yield": 1.36,
      "date": "2019-08-25"
  },
  "2019-08-26": {
      "equity": 11057.85,
      "debt": 6.472,
      "gold": 37825.73943,
      "pb": 3.43,
      "pe": 27.36,
      "yield": 1.34,
      "date": "2019-08-26"
  },
  "2019-08-27": {
      "equity": 11105.35,
      "debt": 6.521,
      "gold": 38649.22838,
      "pb": 3.45,
      "pe": 27.47,
      "yield": 1.33,
      "date": "2019-08-27"
  },
  "2019-08-28": {
      "equity": 11046.1,
      "debt": 6.564,
      "gold": 39034.58968,
      "pb": 3.43,
      "pe": 27.33,
      "yield": 1.34,
      "date": "2019-08-28"
  },
  "2019-08-29": {
      "equity": 10948.3,
      "debt": 6.543,
      "gold": 38842.15595,
      "pb": 3.4,
      "pe": 27.09,
      "yield": 1.35,
      "date": "2019-08-29"
  },
  "2019-08-30": {
      "equity": 11023.25,
      "debt": 6.556,
      "gold": 38510.66156,
      "pb": 3.35,
      "pe": 27.27,
      "yield": 1.39,
      "date": "2019-08-30"
  },
  "2019-08-31": {
      "equity": 11023.25,
      "debt": 6.556,
      "gold": 38510.66156,
      "pb": 3.35,
      "pe": 27.27,
      "yield": 1.39,
      "date": "2019-08-31"
  },
  "2019-09-01": {
      "equity": 11023.25,
      "debt": 6.556,
      "gold": 38510.66156,
      "pb": 3.35,
      "pe": 27.27,
      "yield": 1.39,
      "date": "2019-09-01"
  },
  "2019-09-02": {
      "equity": 11023.25,
      "debt": 6.556,
      "gold": 38686.24843,
      "pb": 3.35,
      "pe": 27.27,
      "yield": 1.39,
      "date": "2019-09-02"
  },
  "2019-09-03": {
      "equity": 10797.9,
      "debt": 6.516,
      "gold": 39018.97388,
      "pb": 3.28,
      "pe": 26.54,
      "yield": 1.42,
      "date": "2019-09-03"
  },
  "2019-09-04": {
      "equity": 10844.65,
      "debt": 6.545,
      "gold": 39078.01196,
      "pb": 3.29,
      "pe": 26.61,
      "yield": 1.42,
      "date": "2019-09-04"
  },
  "2019-09-05": {
      "equity": 10847.9,
      "debt": 6.571,
      "gold": 39086.9645,
      "pb": 3.29,
      "pe": 26.67,
      "yield": 1.41,
      "date": "2019-09-05"
  },
  "2019-09-06": {
      "equity": 10946.2,
      "debt": 6.597,
      "gold": 38003.97538,
      "pb": 3.32,
      "pe": 26.91,
      "yield": 1.4,
      "date": "2019-09-06"
  },
  "2019-09-07": {
      "equity": 10946.2,
      "debt": 6.597,
      "gold": 38003.97538,
      "pb": 3.32,
      "pe": 26.91,
      "yield": 1.4,
      "date": "2019-09-07"
  },
  "2019-09-08": {
      "equity": 10946.2,
      "debt": 6.597,
      "gold": 38003.97538,
      "pb": 3.32,
      "pe": 26.91,
      "yield": 1.4,
      "date": "2019-09-08"
  },
  "2019-09-09": {
      "equity": 11003.05,
      "debt": 6.57,
      "gold": 38200.01058,
      "pb": 3.34,
      "pe": 27.05,
      "yield": 1.39,
      "date": "2019-09-09"
  },
  "2019-09-10": {
      "equity": 11003.05,
      "debt": 6.57,
      "gold": 37884.69285,
      "pb": 3.34,
      "pe": 27.05,
      "yield": 1.39,
      "date": "2019-09-10"
  },
  "2019-09-11": {
      "equity": 11035.7,
      "debt": 6.674,
      "gold": 37688.53772,
      "pb": 3.35,
      "pe": 27.13,
      "yield": 1.39,
      "date": "2019-09-11"
  },
  "2019-09-12": {
      "equity": 10982.8,
      "debt": 6.658,
      "gold": 37629.54902,
      "pb": 3.34,
      "pe": 27,
      "yield": 1.4,
      "date": "2019-09-12"
  },
  "2019-09-13": {
      "equity": 11075.9,
      "debt": 6.631,
      "gold": 37718.31249,
      "pb": 3.36,
      "pe": 27.23,
      "yield": 1.39,
      "date": "2019-09-13"
  },
  "2019-09-14": {
      "equity": 11075.9,
      "debt": 6.631,
      "gold": 37718.31249,
      "pb": 3.36,
      "pe": 27.23,
      "yield": 1.39,
      "date": "2019-09-14"
  },
  "2019-09-15": {
      "equity": 11075.9,
      "debt": 6.631,
      "gold": 37718.31249,
      "pb": 3.36,
      "pe": 27.23,
      "yield": 1.39,
      "date": "2019-09-15"
  },
  "2019-09-16": {
      "equity": 11003.5,
      "debt": 6.71,
      "gold": 37961.63248,
      "pb": 3.34,
      "pe": 27.06,
      "yield": 1.39,
      "date": "2019-09-16"
  },
  "2019-09-17": {
      "equity": 10817.6,
      "debt": 6.728,
      "gold": 37824.96693,
      "pb": 3.29,
      "pe": 26.6,
      "yield": 1.42,
      "date": "2019-09-17"
  },
  "2019-09-18": {
      "equity": 10840.65,
      "debt": 6.617,
      "gold": 37689.72292,
      "pb": 3.29,
      "pe": 26.66,
      "yield": 1.42,
      "date": "2019-09-18"
  },
  "2019-09-19": {
      "equity": 10704.8,
      "debt": 6.634,
      "gold": 37715.26129,
      "pb": 3.25,
      "pe": 26.32,
      "yield": 1.43,
      "date": "2019-09-19"
  },
  "2019-09-20": {
      "equity": 11274.2,
      "debt": 6.782,
      "gold": 37686.72463,
      "pb": 3.42,
      "pe": 27.72,
      "yield": 1.36,
      "date": "2019-09-20"
  },
  "2019-09-21": {
      "equity": 11274.2,
      "debt": 6.782,
      "gold": 37686.72463,
      "pb": 3.42,
      "pe": 27.72,
      "yield": 1.36,
      "date": "2019-09-21"
  },
  "2019-09-22": {
      "equity": 11274.2,
      "debt": 6.782,
      "gold": 37686.72463,
      "pb": 3.42,
      "pe": 27.72,
      "yield": 1.36,
      "date": "2019-09-22"
  },
  "2019-09-23": {
      "equity": 11600.2,
      "debt": 6.739,
      "gold": 37949.20193,
      "pb": 3.52,
      "pe": 28.52,
      "yield": 1.32,
      "date": "2019-09-23"
  },
  "2019-09-24": {
      "equity": 11588.2,
      "debt": 6.775,
      "gold": 38050.08907,
      "pb": 3.52,
      "pe": 28.49,
      "yield": 1.32,
      "date": "2019-09-24"
  },
  "2019-09-25": {
      "equity": 11440.2,
      "debt": 6.754,
      "gold": 38355.8934,
      "pb": 3.47,
      "pe": 25.92,
      "yield": 1.34,
      "date": "2019-09-25"
  },
  "2019-09-26": {
      "equity": 11571.2,
      "debt": 6.71,
      "gold": 37736.18582,
      "pb": 3.51,
      "pe": 26.22,
      "yield": 1.33,
      "date": "2019-09-26"
  },
  "2019-09-27": {
      "equity": 11512.4,
      "debt": 6.729,
      "gold": 37286.98566,
      "pb": 3.55,
      "pe": 26.52,
      "yield": 1.3,
      "date": "2019-09-27"
  },
  "2019-09-28": {
      "equity": 11512.4,
      "debt": 6.729,
      "gold": 37286.98566,
      "pb": 3.55,
      "pe": 26.52,
      "yield": 1.3,
      "date": "2019-09-28"
  },
  "2019-09-29": {
      "equity": 11512.4,
      "debt": 6.729,
      "gold": 37286.98566,
      "pb": 3.55,
      "pe": 26.52,
      "yield": 1.3,
      "date": "2019-09-29"
  },
  "2019-09-30": {
      "equity": 11474.45,
      "debt": 6.695,
      "gold": 37082.29775,
      "pb": 3.53,
      "pe": 26.44,
      "yield": 1.31,
      "date": "2019-09-30"
  },
  "2019-10-01": {
      "equity": 11359.9,
      "debt": 6.657,
      "gold": 36756.57772,
      "pb": 3.5,
      "pe": 26.17,
      "yield": 1.32,
      "date": "2019-10-01"
  },
  "2019-10-02": {
      "equity": 11359.9,
      "debt": 6.657,
      "gold": 37276.22357,
      "pb": 3.5,
      "pe": 26.17,
      "yield": 1.32,
      "date": "2019-10-02"
  },
  "2019-10-03": {
      "equity": 11314,
      "debt": 6.606,
      "gold": 37683.15843,
      "pb": 3.48,
      "pe": 26.07,
      "yield": 1.32,
      "date": "2019-10-03"
  },
  "2019-10-04": {
      "equity": 11174.75,
      "debt": 6.68,
      "gold": 37716.06907,
      "pb": 3.44,
      "pe": 25.75,
      "yield": 1.34,
      "date": "2019-10-04"
  },
  "2019-10-05": {
      "equity": 11174.75,
      "debt": 6.68,
      "gold": 37716.06907,
      "pb": 3.44,
      "pe": 25.75,
      "yield": 1.34,
      "date": "2019-10-05"
  },
  "2019-10-06": {
      "equity": 11174.75,
      "debt": 6.68,
      "gold": 37716.06907,
      "pb": 3.44,
      "pe": 25.75,
      "yield": 1.34,
      "date": "2019-10-06"
  },
  "2019-10-07": {
      "equity": 11126.4,
      "debt": 6.669,
      "gold": 37648.99557,
      "pb": 3.43,
      "pe": 25.64,
      "yield": 1.35,
      "date": "2019-10-07"
  },
  "2019-10-08": {
      "equity": 11126.4,
      "debt": 6.669,
      "gold": 37686.37895,
      "pb": 3.43,
      "pe": 25.64,
      "yield": 1.35,
      "date": "2019-10-08"
  },
  "2019-10-09": {
      "equity": 11313.3,
      "debt": 6.655,
      "gold": 37706.30875,
      "pb": 3.49,
      "pe": 26.13,
      "yield": 1.32,
      "date": "2019-10-09"
  },
  "2019-10-10": {
      "equity": 11234.55,
      "debt": 6.679,
      "gold": 37709.38817,
      "pb": 3.47,
      "pe": 25.95,
      "yield": 1.33,
      "date": "2019-10-10"
  },
  "2019-10-11": {
      "equity": 11305.05,
      "debt": 6.727,
      "gold": 37473.1512,
      "pb": 3.49,
      "pe": 26.11,
      "yield": 1.32,
      "date": "2019-10-11"
  },
  "2019-10-12": {
      "equity": 11305.05,
      "debt": 6.727,
      "gold": 37473.1512,
      "pb": 3.49,
      "pe": 26.11,
      "yield": 1.32,
      "date": "2019-10-12"
  },
  "2019-10-13": {
      "equity": 11305.05,
      "debt": 6.727,
      "gold": 37473.1512,
      "pb": 3.49,
      "pe": 26.11,
      "yield": 1.32,
      "date": "2019-10-13"
  },
  "2019-10-14": {
      "equity": 11341.15,
      "debt": 6.67,
      "gold": 37528.79945,
      "pb": 3.5,
      "pe": 26.2,
      "yield": 1.32,
      "date": "2019-10-14"
  },
  "2019-10-15": {
      "equity": 11428.3,
      "debt": 6.66,
      "gold": 37698.68252,
      "pb": 3.53,
      "pe": 26.4,
      "yield": 1.31,
      "date": "2019-10-15"
  },
  "2019-10-16": {
      "equity": 11464,
      "debt": 6.656,
      "gold": 37331.37092,
      "pb": 3.54,
      "pe": 26.48,
      "yield": 1.3,
      "date": "2019-10-16"
  },
  "2019-10-17": {
      "equity": 11586.35,
      "debt": 6.699,
      "gold": 37252.49828,
      "pb": 3.58,
      "pe": 26.76,
      "yield": 1.29,
      "date": "2019-10-17"
  },
  "2019-10-18": {
      "equity": 11661.85,
      "debt": 6.692,
      "gold": 37299.65608,
      "pb": 3.6,
      "pe": 26.94,
      "yield": 1.28,
      "date": "2019-10-18"
  },
  "2019-10-19": {
      "equity": 11661.85,
      "debt": 6.692,
      "gold": 37299.65608,
      "pb": 3.6,
      "pe": 26.94,
      "yield": 1.28,
      "date": "2019-10-19"
  },
  "2019-10-20": {
      "equity": 11661.85,
      "debt": 6.692,
      "gold": 37299.65608,
      "pb": 3.6,
      "pe": 26.94,
      "yield": 1.28,
      "date": "2019-10-20"
  },
  "2019-10-21": {
      "equity": 11661.85,
      "debt": 6.692,
      "gold": 37288.47422,
      "pb": 3.6,
      "pe": 26.94,
      "yield": 1.28,
      "date": "2019-10-21"
  },
  "2019-10-22": {
      "equity": 11588.35,
      "debt": 6.7,
      "gold": 37135.74843,
      "pb": 3.58,
      "pe": 26.71,
      "yield": 1.29,
      "date": "2019-10-22"
  },
  "2019-10-23": {
      "equity": 11604.1,
      "debt": 6.678,
      "gold": 37269.41216,
      "pb": 3.58,
      "pe": 26.75,
      "yield": 1.29,
      "date": "2019-10-23"
  },
  "2019-10-24": {
      "equity": 11582.6,
      "debt": 6.678,
      "gold": 37264.71014,
      "pb": 3.58,
      "pe": 26.7,
      "yield": 1.29,
      "date": "2019-10-24"
  },
  "2019-10-25": {
      "equity": 11583.9,
      "debt": 6.67,
      "gold": 37588.25376,
      "pb": 3.58,
      "pe": 26.7,
      "yield": 1.29,
      "date": "2019-10-25"
  },
  "2019-10-26": {
      "equity": 11583.9,
      "debt": 6.67,
      "gold": 37588.25376,
      "pb": 3.58,
      "pe": 26.7,
      "yield": 1.29,
      "date": "2019-10-26"
  },
  "2019-10-27": {
      "equity": 11627.15,
      "debt": 6.67,
      "gold": 37588.25376,
      "pb": 3.59,
      "pe": 26.8,
      "yield": 1.29,
      "date": "2019-10-27"
  },
  "2019-10-28": {
      "equity": 11627.15,
      "debt": 6.67,
      "gold": 37545.42055,
      "pb": 3.59,
      "pe": 26.8,
      "yield": 1.29,
      "date": "2019-10-28"
  },
  "2019-10-29": {
      "equity": 11786.85,
      "debt": 6.679,
      "gold": 37316.51352,
      "pb": 3.64,
      "pe": 27.17,
      "yield": 1.27,
      "date": "2019-10-29"
  },
  "2019-10-30": {
      "equity": 11844.1,
      "debt": 6.664,
      "gold": 37309.86084,
      "pb": 3.66,
      "pe": 27.3,
      "yield": 1.26,
      "date": "2019-10-30"
  },
  "2019-10-31": {
      "equity": 11877.45,
      "debt": 6.643,
      "gold": 37762.15454,
      "pb": 3.67,
      "pe": 27.38,
      "yield": 1.26,
      "date": "2019-10-31"
  },
  "2019-11-01": {
      "equity": 11890.6,
      "debt": 6.445,
      "gold": 37572.81434,
      "pb": 3.67,
      "pe": 27.47,
      "yield": 1.26,
      "date": "2019-11-01"
  },
  "2019-11-02": {
      "equity": 11890.6,
      "debt": 6.445,
      "gold": 37572.81434,
      "pb": 3.67,
      "pe": 27.47,
      "yield": 1.26,
      "date": "2019-11-02"
  },
  "2019-11-03": {
      "equity": 11890.6,
      "debt": 6.445,
      "gold": 37572.81434,
      "pb": 3.67,
      "pe": 27.47,
      "yield": 1.26,
      "date": "2019-11-03"
  },
  "2019-11-04": {
      "equity": 11941.3,
      "debt": 6.471,
      "gold": 37699.25748,
      "pb": 3.69,
      "pe": 27.59,
      "yield": 1.25,
      "date": "2019-11-04"
  },
  "2019-11-05": {
      "equity": 11917.2,
      "debt": 6.514,
      "gold": 37571.87957,
      "pb": 3.68,
      "pe": 27.59,
      "yield": 1.25,
      "date": "2019-11-05"
  },
  "2019-11-06": {
      "equity": 11966.05,
      "debt": 6.486,
      "gold": 37319.94568,
      "pb": 3.7,
      "pe": 27.71,
      "yield": 1.25,
      "date": "2019-11-06"
  },
  "2019-11-07": {
      "equity": 12012.05,
      "debt": 6.502,
      "gold": 37140.58449,
      "pb": 3.71,
      "pe": 27.75,
      "yield": 1.24,
      "date": "2019-11-07"
  },
  "2019-11-08": {
      "equity": 11908.15,
      "debt": 6.554,
      "gold": 36891.28203,
      "pb": 3.68,
      "pe": 27.51,
      "yield": 1.25,
      "date": "2019-11-08"
  },
  "2019-11-09": {
      "equity": 11908.15,
      "debt": 6.554,
      "gold": 36891.28203,
      "pb": 3.68,
      "pe": 27.51,
      "yield": 1.25,
      "date": "2019-11-09"
  },
  "2019-11-10": {
      "equity": 11908.15,
      "debt": 6.554,
      "gold": 36891.28203,
      "pb": 3.68,
      "pe": 27.51,
      "yield": 1.25,
      "date": "2019-11-10"
  },
  "2019-11-11": {
      "equity": 11913.45,
      "debt": 6.561,
      "gold": 36996.64544,
      "pb": 3.68,
      "pe": 27.52,
      "yield": 1.25,
      "date": "2019-11-11"
  },
  "2019-11-12": {
      "equity": 11913.45,
      "debt": 6.561,
      "gold": 36838.32519,
      "pb": 3.68,
      "pe": 27.52,
      "yield": 1.25,
      "date": "2019-11-12"
  },
  "2019-11-13": {
      "equity": 11840.45,
      "debt": 6.528,
      "gold": 37235.00591,
      "pb": 3.66,
      "pe": 27.31,
      "yield": 1.26,
      "date": "2019-11-13"
  },
  "2019-11-14": {
      "equity": 11872.1,
      "debt": 6.515,
      "gold": 37276.48107,
      "pb": 3.67,
      "pe": 27.38,
      "yield": 1.26,
      "date": "2019-11-14"
  },
  "2019-11-15": {
      "equity": 11895.45,
      "debt": 6.519,
      "gold": 37028.12043,
      "pb": 3.67,
      "pe": 27.32,
      "yield": 1.26,
      "date": "2019-11-15"
  },
  "2019-11-16": {
      "equity": 11895.45,
      "debt": 6.519,
      "gold": 37028.12043,
      "pb": 3.67,
      "pe": 27.32,
      "yield": 1.26,
      "date": "2019-11-16"
  },
  "2019-11-17": {
      "equity": 11895.45,
      "debt": 6.519,
      "gold": 37028.12043,
      "pb": 3.67,
      "pe": 27.32,
      "yield": 1.26,
      "date": "2019-11-17"
  },
  "2019-11-18": {
      "equity": 11884.5,
      "debt": 6.477,
      "gold": 37003.62969,
      "pb": 3.67,
      "pe": 27.29,
      "yield": 1.26,
      "date": "2019-11-18"
  },
  "2019-11-19": {
      "equity": 11940.1,
      "debt": 6.473,
      "gold": 37119.72345,
      "pb": 3.69,
      "pe": 27.31,
      "yield": 1.25,
      "date": "2019-11-19"
  },
  "2019-11-20": {
      "equity": 11999.1,
      "debt": 6.458,
      "gold": 37380.63458,
      "pb": 3.71,
      "pe": 27.23,
      "yield": 1.24,
      "date": "2019-11-20"
  },
  "2019-11-21": {
      "equity": 11968.4,
      "debt": 6.505,
      "gold": 37176.83204,
      "pb": 3.7,
      "pe": 27.89,
      "yield": 1.25,
      "date": "2019-11-21"
  },
  "2019-11-22": {
      "equity": 11914.4,
      "debt": 6.495,
      "gold": 37240.48043,
      "pb": 3.68,
      "pe": 27.77,
      "yield": 1.25,
      "date": "2019-11-22"
  },
  "2019-11-23": {
      "equity": 11914.4,
      "debt": 6.495,
      "gold": 37240.48043,
      "pb": 3.68,
      "pe": 27.77,
      "yield": 1.25,
      "date": "2019-11-23"
  },
  "2019-11-24": {
      "equity": 11914.4,
      "debt": 6.495,
      "gold": 37240.48043,
      "pb": 3.68,
      "pe": 27.77,
      "yield": 1.25,
      "date": "2019-11-24"
  },
  "2019-11-25": {
      "equity": 12073.75,
      "debt": 6.468,
      "gold": 36906.46043,
      "pb": 3.73,
      "pe": 28.14,
      "yield": 1.24,
      "date": "2019-11-25"
  },
  "2019-11-26": {
      "equity": 12037.7,
      "debt": 6.478,
      "gold": 36760.52488,
      "pb": 3.72,
      "pe": 28.05,
      "yield": 1.24,
      "date": "2019-11-26"
  },
  "2019-11-27": {
      "equity": 12100.7,
      "debt": 6.467,
      "gold": 36692.7071,
      "pb": 3.74,
      "pe": 28.2,
      "yield": 1.23,
      "date": "2019-11-27"
  },
  "2019-11-28": {
      "equity": 12151.15,
      "debt": 6.453,
      "gold": 36774.65917,
      "pb": 3.75,
      "pe": 28.32,
      "yield": 1.23,
      "date": "2019-11-28"
  },
  "2019-11-29": {
      "equity": 12056.05,
      "debt": 6.46,
      "gold": 36869.16524,
      "pb": 3.72,
      "pe": 28.1,
      "yield": 1.24,
      "date": "2019-11-29"
  },
  "2019-11-30": {
      "equity": 12056.05,
      "debt": 6.46,
      "gold": 36869.16524,
      "pb": 3.72,
      "pe": 28.1,
      "yield": 1.24,
      "date": "2019-11-30"
  },
  "2019-12-01": {
      "equity": 12056.05,
      "debt": 6.46,
      "gold": 36869.16524,
      "pb": 3.72,
      "pe": 28.1,
      "yield": 1.24,
      "date": "2019-12-01"
  },
  "2019-12-02": {
      "equity": 12048.2,
      "debt": 6.484,
      "gold": 36838.38516,
      "pb": 3.72,
      "pe": 28.08,
      "yield": 1.24,
      "date": "2019-12-02"
  },
  "2019-12-03": {
      "equity": 11994.2,
      "debt": 6.462,
      "gold": 37206.18353,
      "pb": 3.7,
      "pe": 27.95,
      "yield": 1.25,
      "date": "2019-12-03"
  },
  "2019-12-04": {
      "equity": 12043.2,
      "debt": 6.462,
      "gold": 37218.10614,
      "pb": 3.72,
      "pe": 28.07,
      "yield": 1.24,
      "date": "2019-12-04"
  },
  "2019-12-05": {
      "equity": 12018.4,
      "debt": 6.608,
      "gold": 37049.00263,
      "pb": 3.71,
      "pe": 28.01,
      "yield": 1.24,
      "date": "2019-12-05"
  },
  "2019-12-06": {
      "equity": 11921.5,
      "debt": 6.661,
      "gold": 37086.23785,
      "pb": 3.68,
      "pe": 27.78,
      "yield": 1.25,
      "date": "2019-12-06"
  },
  "2019-12-07": {
      "equity": 11921.5,
      "debt": 6.661,
      "gold": 37086.23785,
      "pb": 3.68,
      "pe": 27.78,
      "yield": 1.25,
      "date": "2019-12-07"
  },
  "2019-12-08": {
      "equity": 11921.5,
      "debt": 6.661,
      "gold": 37086.23785,
      "pb": 3.68,
      "pe": 27.78,
      "yield": 1.25,
      "date": "2019-12-08"
  },
  "2019-12-09": {
      "equity": 11937.5,
      "debt": 6.659,
      "gold": 36656.72763,
      "pb": 3.69,
      "pe": 27.82,
      "yield": 1.25,
      "date": "2019-12-09"
  },
  "2019-12-10": {
      "equity": 11856.8,
      "debt": 6.703,
      "gold": 36594.28209,
      "pb": 3.66,
      "pe": 27.63,
      "yield": 1.26,
      "date": "2019-12-10"
  },
  "2019-12-11": {
      "equity": 11910.15,
      "debt": 6.76,
      "gold": 36642.0854,
      "pb": 3.68,
      "pe": 27.76,
      "yield": 1.25,
      "date": "2019-12-11"
  },
  "2019-12-12": {
      "equity": 11971.8,
      "debt": 6.77,
      "gold": 36724.73589,
      "pb": 3.7,
      "pe": 27.9,
      "yield": 1.25,
      "date": "2019-12-12"
  },
  "2019-12-13": {
      "equity": 12086.7,
      "debt": 6.782,
      "gold": 36681.61343,
      "pb": 3.73,
      "pe": 28.17,
      "yield": 1.24,
      "date": "2019-12-13"
  },
  "2019-12-14": {
      "equity": 12086.7,
      "debt": 6.782,
      "gold": 36681.61343,
      "pb": 3.73,
      "pe": 28.17,
      "yield": 1.24,
      "date": "2019-12-14"
  },
  "2019-12-15": {
      "equity": 12086.7,
      "debt": 6.782,
      "gold": 36681.61343,
      "pb": 3.73,
      "pe": 28.17,
      "yield": 1.24,
      "date": "2019-12-15"
  },
  "2019-12-16": {
      "equity": 12053.95,
      "debt": 6.794,
      "gold": 36966.398,
      "pb": 3.72,
      "pe": 28.09,
      "yield": 1.24,
      "date": "2019-12-16"
  },
  "2019-12-17": {
      "equity": 12165,
      "debt": 6.744,
      "gold": 37037.0518,
      "pb": 3.76,
      "pe": 28.35,
      "yield": 1.23,
      "date": "2019-12-17"
  },
  "2019-12-18": {
      "equity": 12221.65,
      "debt": 6.705,
      "gold": 37049.10845,
      "pb": 3.77,
      "pe": 28.48,
      "yield": 1.22,
      "date": "2019-12-18"
  },
  "2019-12-19": {
      "equity": 12259.7,
      "debt": 6.746,
      "gold": 37039.29875,
      "pb": 3.79,
      "pe": 28.57,
      "yield": 1.22,
      "date": "2019-12-19"
  },
  "2019-12-20": {
      "equity": 12271.8,
      "debt": 6.598,
      "gold": 37037.76433,
      "pb": 3.79,
      "pe": 28.6,
      "yield": 1.22,
      "date": "2019-12-20"
  },
  "2019-12-21": {
      "equity": 12271.8,
      "debt": 6.598,
      "gold": 37037.76433,
      "pb": 3.79,
      "pe": 28.6,
      "yield": 1.22,
      "date": "2019-12-21"
  },
  "2019-12-22": {
      "equity": 12271.8,
      "debt": 6.598,
      "gold": 37037.76433,
      "pb": 3.79,
      "pe": 28.6,
      "yield": 1.22,
      "date": "2019-12-22"
  },
  "2019-12-23": {
      "equity": 12262.75,
      "debt": 6.562,
      "gold": 37287.53241,
      "pb": 3.79,
      "pe": 28.58,
      "yield": 1.22,
      "date": "2019-12-23"
  },
  "2019-12-24": {
      "equity": 12214.55,
      "debt": 6.571,
      "gold": 37484.89039,
      "pb": 3.77,
      "pe": 28.47,
      "yield": 1.22,
      "date": "2019-12-24"
  },
  "2019-12-25": {
      "equity": 12214.55,
      "debt": 6.571,
      "gold": 37484.89039,
      "pb": 3.77,
      "pe": 28.47,
      "yield": 1.22,
      "date": "2019-12-25"
  },
  "2019-12-26": {
      "equity": 12126.55,
      "debt": 6.576,
      "gold": 37484.89039,
      "pb": 3.74,
      "pe": 28.26,
      "yield": 1.23,
      "date": "2019-12-26"
  },
  "2019-12-27": {
      "equity": 12245.8,
      "debt": 6.5,
      "gold": 38097.89591,
      "pb": 3.78,
      "pe": 28.48,
      "yield": 1.23,
      "date": "2019-12-27"
  },
  "2019-12-28": {
      "equity": 12245.8,
      "debt": 6.5,
      "gold": 38097.89591,
      "pb": 3.78,
      "pe": 28.48,
      "yield": 1.23,
      "date": "2019-12-28"
  },
  "2019-12-29": {
      "equity": 12245.8,
      "debt": 6.5,
      "gold": 38097.89591,
      "pb": 3.78,
      "pe": 28.48,
      "yield": 1.23,
      "date": "2019-12-29"
  },
  "2019-12-30": {
      "equity": 12255.85,
      "debt": 6.544,
      "gold": 38005.43219,
      "pb": 3.78,
      "pe": 28.5,
      "yield": 1.23,
      "date": "2019-12-30"
  },
  "2019-12-31": {
      "equity": 12168.45,
      "debt": 6.554,
      "gold": 38263.42969,
      "pb": 3.75,
      "pe": 28.3,
      "yield": 1.24,
      "date": "2019-12-31"
  },
  "2020-01-01": {
      "equity": 12182.5,
      "debt": 6.501,
      "gold": 38263.42969,
      "pb": 3.76,
      "pe": 28.33,
      "yield": 1.24,
      "date": "2020-01-01"
  },
  "2020-01-02": {
      "equity": 12282.2,
      "debt": 6.502,
      "gold": 38269.45802,
      "pb": 3.79,
      "pe": 28.56,
      "yield": 1.23,
      "date": "2020-01-02"
  },
  "2020-01-03": {
      "equity": 12226.65,
      "debt": 6.51,
      "gold": 39154.8246,
      "pb": 3.77,
      "pe": 28.44,
      "yield": 1.23,
      "date": "2020-01-03"
  },
  "2020-01-04": {
      "equity": 12226.65,
      "debt": 6.51,
      "gold": 39154.8246,
      "pb": 3.77,
      "pe": 28.44,
      "yield": 1.23,
      "date": "2020-01-04"
  },
  "2020-01-05": {
      "equity": 12226.65,
      "debt": 6.51,
      "gold": 39154.8246,
      "pb": 3.77,
      "pe": 28.44,
      "yield": 1.23,
      "date": "2020-01-05"
  },
  "2020-01-06": {
      "equity": 11993.05,
      "debt": 6.565,
      "gold": 39971.60091,
      "pb": 3.7,
      "pe": 27.89,
      "yield": 1.26,
      "date": "2020-01-06"
  },
  "2020-01-07": {
      "equity": 12052.95,
      "debt": 6.55,
      "gold": 39703.59971,
      "pb": 3.72,
      "pe": 28.03,
      "yield": 1.25,
      "date": "2020-01-07"
  },
  "2020-01-08": {
      "equity": 12025.35,
      "debt": 6.557,
      "gold": 39844.13482,
      "pb": 3.71,
      "pe": 27.97,
      "yield": 1.25,
      "date": "2020-01-08"
  },
  "2020-01-09": {
      "equity": 12215.9,
      "debt": 6.524,
      "gold": 38871.64853,
      "pb": 3.77,
      "pe": 28.41,
      "yield": 1.23,
      "date": "2020-01-09"
  },
  "2020-01-10": {
      "equity": 12256.8,
      "debt": 6.585,
      "gold": 38750.18607,
      "pb": 3.78,
      "pe": 28.51,
      "yield": 1.23,
      "date": "2020-01-10"
  },
  "2020-01-11": {
      "equity": 12256.8,
      "debt": 6.585,
      "gold": 38750.18607,
      "pb": 3.78,
      "pe": 28.51,
      "yield": 1.23,
      "date": "2020-01-11"
  },
  "2020-01-12": {
      "equity": 12256.8,
      "debt": 6.585,
      "gold": 38750.18607,
      "pb": 3.78,
      "pe": 28.51,
      "yield": 1.23,
      "date": "2020-01-12"
  },
  "2020-01-13": {
      "equity": 12329.55,
      "debt": 6.592,
      "gold": 38680.68573,
      "pb": 3.8,
      "pe": 28.67,
      "yield": 1.22,
      "date": "2020-01-13"
  },
  "2020-01-14": {
      "equity": 12362.3,
      "debt": 6.662,
      "gold": 38612.71275,
      "pb": 3.81,
      "pe": 28.67,
      "yield": 1.22,
      "date": "2020-01-14"
  },
  "2020-01-15": {
      "equity": 12343.3,
      "debt": 6.623,
      "gold": 38704.30166,
      "pb": 3.81,
      "pe": 28.63,
      "yield": 1.22,
      "date": "2020-01-15"
  },
  "2020-01-16": {
      "equity": 12355.5,
      "debt": 6.598,
      "gold": 38937.02534,
      "pb": 3.68,
      "pe": 28.62,
      "yield": 1.24,
      "date": "2020-01-16"
  },
  "2020-01-17": {
      "equity": 12352.35,
      "debt": 6.622,
      "gold": 39019.53474,
      "pb": 3.67,
      "pe": 28.61,
      "yield": 1.24,
      "date": "2020-01-17"
  },
  "2020-01-18": {
      "equity": 12352.35,
      "debt": 6.622,
      "gold": 39019.53474,
      "pb": 3.67,
      "pe": 28.61,
      "yield": 1.24,
      "date": "2020-01-18"
  },
  "2020-01-19": {
      "equity": 12352.35,
      "debt": 6.622,
      "gold": 39019.53474,
      "pb": 3.67,
      "pe": 28.61,
      "yield": 1.24,
      "date": "2020-01-19"
  },
  "2020-01-20": {
      "equity": 12224.55,
      "debt": 6.639,
      "gold": 39072.68911,
      "pb": 3.63,
      "pe": 28,
      "yield": 1.25,
      "date": "2020-01-20"
  },
  "2020-01-21": {
      "equity": 12169.85,
      "debt": 6.633,
      "gold": 39070.79843,
      "pb": 3.45,
      "pe": 27.84,
      "yield": 1.25,
      "date": "2020-01-21"
  },
  "2020-01-22": {
      "equity": 12106.9,
      "debt": 6.635,
      "gold": 39068.87952,
      "pb": 3.42,
      "pe": 27.73,
      "yield": 1.26,
      "date": "2020-01-22"
  },
  "2020-01-23": {
      "equity": 12180.35,
      "debt": 6.596,
      "gold": 39138.35517,
      "pb": 3.43,
      "pe": 27.88,
      "yield": 1.25,
      "date": "2020-01-23"
  },
  "2020-01-24": {
      "equity": 12248.25,
      "debt": 6.578,
      "gold": 39284.93977,
      "pb": 3.45,
      "pe": 28.1,
      "yield": 1.24,
      "date": "2020-01-24"
  },
  "2020-01-25": {
      "equity": 12248.25,
      "debt": 6.578,
      "gold": 39284.93977,
      "pb": 3.45,
      "pe": 28.1,
      "yield": 1.24,
      "date": "2020-01-25"
  },
  "2020-01-26": {
      "equity": 12248.25,
      "debt": 6.578,
      "gold": 39284.93977,
      "pb": 3.45,
      "pe": 28.1,
      "yield": 1.24,
      "date": "2020-01-26"
  },
  "2020-01-27": {
      "equity": 12119,
      "debt": 6.551,
      "gold": 39896.93998,
      "pb": 3.42,
      "pe": 27.73,
      "yield": 1.25,
      "date": "2020-01-27"
  },
  "2020-01-28": {
      "equity": 12055.8,
      "debt": 6.575,
      "gold": 39672.61856,
      "pb": 3.4,
      "pe": 26.65,
      "yield": 1.26,
      "date": "2020-01-28"
  },
  "2020-01-29": {
      "equity": 12129.5,
      "debt": 6.568,
      "gold": 39543.94963,
      "pb": 3.42,
      "pe": 26.81,
      "yield": 1.26,
      "date": "2020-01-29"
  },
  "2020-01-30": {
      "equity": 12035.8,
      "debt": 6.558,
      "gold": 39919.32133,
      "pb": 3.39,
      "pe": 26.57,
      "yield": 1.27,
      "date": "2020-01-30"
  },
  "2020-01-31": {
      "equity": 11962.1,
      "debt": 6.599,
      "gold": 39906.71088,
      "pb": 3.37,
      "pe": 26.41,
      "yield": 1.27,
      "date": "2020-01-31"
  },
  "2020-02-01": {
      "equity": 11661.85,
      "debt": 6.599,
      "gold": 39906.71088,
      "pb": 3.28,
      "pe": 25.75,
      "yield": 1.31,
      "date": "2020-02-01"
  },
  "2020-02-02": {
      "equity": 11661.85,
      "debt": 6.599,
      "gold": 39906.71088,
      "pb": 3.28,
      "pe": 25.75,
      "yield": 1.31,
      "date": "2020-02-02"
  },
  "2020-02-03": {
      "equity": 11707.9,
      "debt": 6.503,
      "gold": 39703.0706,
      "pb": 3.3,
      "pe": 25.9,
      "yield": 1.3,
      "date": "2020-02-03"
  },
  "2020-02-04": {
      "equity": 11979.65,
      "debt": 6.504,
      "gold": 39411.7674,
      "pb": 3.37,
      "pe": 26.57,
      "yield": 1.27,
      "date": "2020-02-04"
  },
  "2020-02-05": {
      "equity": 12089.15,
      "debt": 6.505,
      "gold": 38978.70156,
      "pb": 3.4,
      "pe": 27.05,
      "yield": 1.26,
      "date": "2020-02-05"
  },
  "2020-02-06": {
      "equity": 12137.95,
      "debt": 6.446,
      "gold": 39292.47782,
      "pb": 3.42,
      "pe": 27.16,
      "yield": 1.26,
      "date": "2020-02-06"
  },
  "2020-02-07": {
      "equity": 12098.35,
      "debt": 6.439,
      "gold": 39556.43309,
      "pb": 3.41,
      "pe": 27.03,
      "yield": 1.26,
      "date": "2020-02-07"
  },
  "2020-02-08": {
      "equity": 12098.35,
      "debt": 6.439,
      "gold": 39556.43309,
      "pb": 3.41,
      "pe": 27.03,
      "yield": 1.26,
      "date": "2020-02-08"
  },
  "2020-02-09": {
      "equity": 12098.35,
      "debt": 6.439,
      "gold": 39556.43309,
      "pb": 3.41,
      "pe": 27.03,
      "yield": 1.26,
      "date": "2020-02-09"
  },
  "2020-02-10": {
      "equity": 12031.5,
      "debt": 6.44,
      "gold": 39589.58359,
      "pb": 3.39,
      "pe": 26.89,
      "yield": 1.27,
      "date": "2020-02-10"
  },
  "2020-02-11": {
      "equity": 12107.9,
      "debt": 6.464,
      "gold": 39418.25782,
      "pb": 3.41,
      "pe": 27.21,
      "yield": 1.26,
      "date": "2020-02-11"
  },
  "2020-02-12": {
      "equity": 12201.2,
      "debt": 6.473,
      "gold": 39404.26463,
      "pb": 3.44,
      "pe": 27.65,
      "yield": 1.25,
      "date": "2020-02-12"
  },
  "2020-02-13": {
      "equity": 12174.65,
      "debt": 6.423,
      "gold": 39607.08654,
      "pb": 3.43,
      "pe": 27.59,
      "yield": 1.25,
      "date": "2020-02-13"
  },
  "2020-02-14": {
      "equity": 12113.45,
      "debt": 6.369,
      "gold": 39776.71211,
      "pb": 3.41,
      "pe": 27.45,
      "yield": 1.26,
      "date": "2020-02-14"
  },
  "2020-02-15": {
      "equity": 12113.45,
      "debt": 6.369,
      "gold": 39776.71211,
      "pb": 3.41,
      "pe": 27.45,
      "yield": 1.26,
      "date": "2020-02-15"
  },
  "2020-02-16": {
      "equity": 12113.45,
      "debt": 6.369,
      "gold": 39776.71211,
      "pb": 3.41,
      "pe": 27.45,
      "yield": 1.26,
      "date": "2020-02-16"
  },
  "2020-02-17": {
      "equity": 12045.8,
      "debt": 6.387,
      "gold": 39769.35396,
      "pb": 3.39,
      "pe": 27.42,
      "yield": 1.27,
      "date": "2020-02-17"
  },
  "2020-02-18": {
      "equity": 11992.5,
      "debt": 6.384,
      "gold": 40096.95762,
      "pb": 3.38,
      "pe": 27.29,
      "yield": 1.27,
      "date": "2020-02-18"
  },
  "2020-02-19": {
      "equity": 12125.9,
      "debt": 6.384,
      "gold": 40630.79772,
      "pb": 3.41,
      "pe": 27.6,
      "yield": 1.26,
      "date": "2020-02-19"
  },
  "2020-02-20": {
      "equity": 12080.85,
      "debt": 6.422,
      "gold": 40870.9501,
      "pb": 3.4,
      "pe": 27.5,
      "yield": 1.26,
      "date": "2020-02-20"
  },
  "2020-02-21": {
      "equity": 12080.85,
      "debt": 6.422,
      "gold": 41382.60992,
      "pb": 3.4,
      "pe": 27.5,
      "yield": 1.26,
      "date": "2020-02-21"
  },
  "2020-02-22": {
      "equity": 12080.85,
      "debt": 6.422,
      "gold": 41382.60992,
      "pb": 3.4,
      "pe": 27.5,
      "yield": 1.26,
      "date": "2020-02-22"
  },
  "2020-02-23": {
      "equity": 12080.85,
      "debt": 6.422,
      "gold": 41382.60992,
      "pb": 3.4,
      "pe": 27.5,
      "yield": 1.26,
      "date": "2020-02-23"
  },
  "2020-02-24": {
      "equity": 11829.4,
      "debt": 6.367,
      "gold": 42802.76548,
      "pb": 3.33,
      "pe": 26.92,
      "yield": 1.29,
      "date": "2020-02-24"
  },
  "2020-02-25": {
      "equity": 11797.9,
      "debt": 6.367,
      "gold": 41963.69954,
      "pb": 3.32,
      "pe": 26.85,
      "yield": 1.29,
      "date": "2020-02-25"
  },
  "2020-02-26": {
      "equity": 11678.5,
      "debt": 6.346,
      "gold": 41652.59705,
      "pb": 3.29,
      "pe": 26.58,
      "yield": 1.3,
      "date": "2020-02-26"
  },
  "2020-02-27": {
      "equity": 11633.3,
      "debt": 6.378,
      "gold": 41525.54366,
      "pb": 3.27,
      "pe": 26.48,
      "yield": 1.31,
      "date": "2020-02-27"
  },
  "2020-02-28": {
      "equity": 11201.75,
      "debt": 6.371,
      "gold": 41572.18999,
      "pb": 3.15,
      "pe": 25.49,
      "yield": 1.36,
      "date": "2020-02-28"
  },
  "2020-02-29": {
      "equity": 11201.75,
      "debt": 6.371,
      "gold": 41572.18999,
      "pb": 3.15,
      "pe": 25.49,
      "yield": 1.36,
      "date": "2020-02-29"
  },
  "2020-03-01": {
      "equity": 11201.75,
      "debt": 6.371,
      "gold": 41572.18999,
      "pb": 3.15,
      "pe": 25.49,
      "yield": 1.36,
      "date": "2020-03-01"
  },
  "2020-03-02": {
      "equity": 11132.75,
      "debt": 6.346,
      "gold": 41250.78044,
      "pb": 3.13,
      "pe": 25.34,
      "yield": 1.37,
      "date": "2020-03-02"
  },
  "2020-03-03": {
      "equity": 11303.3,
      "debt": 6.342,
      "gold": 41190.22558,
      "pb": 3.18,
      "pe": 25.73,
      "yield": 1.35,
      "date": "2020-03-03"
  },
  "2020-03-04": {
      "equity": 11251,
      "debt": 6.226,
      "gold": 42710.43581,
      "pb": 3.17,
      "pe": 25.61,
      "yield": 1.35,
      "date": "2020-03-04"
  },
  "2020-03-05": {
      "equity": 11269,
      "debt": 6.239,
      "gold": 43016.34597,
      "pb": 3.17,
      "pe": 25.65,
      "yield": 1.35,
      "date": "2020-03-05"
  },
  "2020-03-06": {
      "equity": 10989.45,
      "debt": 6.185,
      "gold": 44148.40826,
      "pb": 3.09,
      "pe": 25.01,
      "yield": 1.39,
      "date": "2020-03-06"
  },
  "2020-03-07": {
      "equity": 10989.45,
      "debt": 6.185,
      "gold": 44148.40826,
      "pb": 3.09,
      "pe": 25.01,
      "yield": 1.39,
      "date": "2020-03-07"
  },
  "2020-03-08": {
      "equity": 10989.45,
      "debt": 6.185,
      "gold": 44148.40826,
      "pb": 3.09,
      "pe": 25.01,
      "yield": 1.39,
      "date": "2020-03-08"
  },
  "2020-03-09": {
      "equity": 10451.45,
      "debt": 6.066,
      "gold": 44058.39609,
      "pb": 2.94,
      "pe": 23.79,
      "yield": 1.46,
      "date": "2020-03-09"
  },
  "2020-03-10": {
      "equity": 10451.45,
      "debt": 6.066,
      "gold": 43179.68571,
      "pb": 2.94,
      "pe": 23.79,
      "yield": 1.46,
      "date": "2020-03-10"
  },
  "2020-03-11": {
      "equity": 10458.4,
      "debt": 6.125,
      "gold": 43406.97014,
      "pb": 2.94,
      "pe": 23.8,
      "yield": 1.46,
      "date": "2020-03-11"
  },
  "2020-03-12": {
      "equity": 9590.15,
      "debt": 6.236,
      "gold": 42859.04866,
      "pb": 2.7,
      "pe": 21.83,
      "yield": 1.59,
      "date": "2020-03-12"
  },
  "2020-03-13": {
      "equity": 9955.2,
      "debt": 6.322,
      "gold": 41463.21099,
      "pb": 2.8,
      "pe": 22.66,
      "yield": 1.53,
      "date": "2020-03-13"
  },
  "2020-03-14": {
      "equity": 9955.2,
      "debt": 6.322,
      "gold": 41463.21099,
      "pb": 2.8,
      "pe": 22.66,
      "yield": 1.53,
      "date": "2020-03-14"
  },
  "2020-03-15": {
      "equity": 9955.2,
      "debt": 6.322,
      "gold": 41463.21099,
      "pb": 2.8,
      "pe": 22.66,
      "yield": 1.53,
      "date": "2020-03-15"
  },
  "2020-03-16": {
      "equity": 9197.4,
      "debt": 6.208,
      "gold": 39334.15404,
      "pb": 2.59,
      "pe": 20.93,
      "yield": 1.66,
      "date": "2020-03-16"
  },
  "2020-03-17": {
      "equity": 8967.05,
      "debt": 6.265,
      "gold": 38469.00298,
      "pb": 2.52,
      "pe": 20.41,
      "yield": 1.7,
      "date": "2020-03-17"
  },
  "2020-03-18": {
      "equity": 8468.8,
      "debt": 6.296,
      "gold": 39873.04891,
      "pb": 2.38,
      "pe": 19.27,
      "yield": 1.8,
      "date": "2020-03-18"
  },
  "2020-03-19": {
      "equity": 8263.45,
      "debt": 6.41,
      "gold": 39167.6855,
      "pb": 2.36,
      "pe": 18.63,
      "yield": 1.84,
      "date": "2020-03-19"
  },
  "2020-03-20": {
      "equity": 8745.45,
      "debt": 6.259,
      "gold": 40081.16545,
      "pb": 2.49,
      "pe": 19.72,
      "yield": 1.74,
      "date": "2020-03-20"
  },
  "2020-03-21": {
      "equity": 8745.45,
      "debt": 6.259,
      "gold": 40081.16545,
      "pb": 2.49,
      "pe": 19.72,
      "yield": 1.74,
      "date": "2020-03-21"
  },
  "2020-03-22": {
      "equity": 8745.45,
      "debt": 6.259,
      "gold": 40081.16545,
      "pb": 2.49,
      "pe": 19.72,
      "yield": 1.74,
      "date": "2020-03-22"
  },
  "2020-03-23": {
      "equity": 7610.25,
      "debt": 6.379,
      "gold": 40235.70786,
      "pb": 2.17,
      "pe": 17.15,
      "yield": 2,
      "date": "2020-03-23"
  },
  "2020-03-24": {
      "equity": 7801.05,
      "debt": 6.304,
      "gold": 42940.42223,
      "pb": 2.22,
      "pe": 17.58,
      "yield": 1.95,
      "date": "2020-03-24"
  },
  "2020-03-25": {
      "equity": 8317.85,
      "debt": 6.304,
      "gold": 43383.04732,
      "pb": 2.37,
      "pe": 18.74,
      "yield": 1.83,
      "date": "2020-03-25"
  },
  "2020-03-26": {
      "equity": 8641.45,
      "debt": 6.225,
      "gold": 42742.53514,
      "pb": 2.46,
      "pe": 19.47,
      "yield": 1.76,
      "date": "2020-03-26"
  },
  "2020-03-27": {
      "equity": 8660.25,
      "debt": 6.144,
      "gold": 43025.46429,
      "pb": 2.47,
      "pe": 19.52,
      "yield": 1.76,
      "date": "2020-03-27"
  },
  "2020-03-28": {
      "equity": 8660.25,
      "debt": 6.144,
      "gold": 43025.46429,
      "pb": 2.47,
      "pe": 19.52,
      "yield": 1.76,
      "date": "2020-03-28"
  },
  "2020-03-29": {
      "equity": 8660.25,
      "debt": 6.144,
      "gold": 43025.46429,
      "pb": 2.47,
      "pe": 19.52,
      "yield": 1.76,
      "date": "2020-03-29"
  },
  "2020-03-30": {
      "equity": 8281.1,
      "debt": 6.209,
      "gold": 43180.47585,
      "pb": 2.36,
      "pe": 18.66,
      "yield": 1.84,
      "date": "2020-03-30"
  },
  "2020-03-31": {
      "equity": 8597.75,
      "debt": 6.138,
      "gold": 42663.33092,
      "pb": 2.45,
      "pe": 19.38,
      "yield": 1.77,
      "date": "2020-03-31"
  },
  "2020-04-01": {
      "equity": 8253.8,
      "debt": 6.138,
      "gold": 43062.09986,
      "pb": 2.35,
      "pe": 18.6,
      "yield": 1.84,
      "date": "2020-04-01"
  },
  "2020-04-02": {
      "equity": 8253.8,
      "debt": 6.138,
      "gold": 42711.17656,
      "pb": 2.35,
      "pe": 18.6,
      "yield": 1.84,
      "date": "2020-04-02"
  },
  "2020-04-03": {
      "equity": 8083.8,
      "debt": 6.306,
      "gold": 43318.0303,
      "pb": 2.3,
      "pe": 18.22,
      "yield": 1.88,
      "date": "2020-04-03"
  },
  "2020-04-04": {
      "equity": 8083.8,
      "debt": 6.306,
      "gold": 43318.0303,
      "pb": 2.3,
      "pe": 18.22,
      "yield": 1.88,
      "date": "2020-04-04"
  },
  "2020-04-05": {
      "equity": 8083.8,
      "debt": 6.306,
      "gold": 43318.0303,
      "pb": 2.3,
      "pe": 18.22,
      "yield": 1.88,
      "date": "2020-04-05"
  },
  "2020-04-06": {
      "equity": 8083.8,
      "debt": 6.306,
      "gold": 43871.75788,
      "pb": 2.3,
      "pe": 18.22,
      "yield": 1.88,
      "date": "2020-04-06"
  },
  "2020-04-07": {
      "equity": 8792.2,
      "debt": 6.414,
      "gold": 44096.44967,
      "pb": 2.51,
      "pe": 19.81,
      "yield": 1.73,
      "date": "2020-04-07"
  },
  "2020-04-08": {
      "equity": 8748.75,
      "debt": 6.441,
      "gold": 44184.55705,
      "pb": 2.49,
      "pe": 19.72,
      "yield": 1.74,
      "date": "2020-04-08"
  },
  "2020-04-09": {
      "equity": 9111.9,
      "debt": 6.49,
      "gold": 44506.23115,
      "pb": 2.6,
      "pe": 20.53,
      "yield": 1.67,
      "date": "2020-04-09"
  },
  "2020-04-10": {
      "equity": 9111.9,
      "debt": 6.49,
      "gold": 44506.23115,
      "pb": 2.6,
      "pe": 20.53,
      "yield": 1.67,
      "date": "2020-04-10"
  },
  "2020-04-11": {
      "equity": 9111.9,
      "debt": 6.49,
      "gold": 44506.23115,
      "pb": 2.6,
      "pe": 20.53,
      "yield": 1.67,
      "date": "2020-04-11"
  },
  "2020-04-12": {
      "equity": 9111.9,
      "debt": 6.49,
      "gold": 44506.23115,
      "pb": 2.6,
      "pe": 20.53,
      "yield": 1.67,
      "date": "2020-04-12"
  },
  "2020-04-13": {
      "equity": 8993.85,
      "debt": 6.495,
      "gold": 44506.23115,
      "pb": 2.56,
      "pe": 20.27,
      "yield": 1.69,
      "date": "2020-04-13"
  },
  "2020-04-14": {
      "equity": 8993.85,
      "debt": 6.495,
      "gold": 45999.81305,
      "pb": 2.56,
      "pe": 20.27,
      "yield": 1.69,
      "date": "2020-04-14"
  },
  "2020-04-15": {
      "equity": 8925.3,
      "debt": 6.425,
      "gold": 46207.10418,
      "pb": 2.54,
      "pe": 20.11,
      "yield": 1.7,
      "date": "2020-04-15"
  },
  "2020-04-16": {
      "equity": 8992.8,
      "debt": 6.44,
      "gold": 46542.13302,
      "pb": 2.56,
      "pe": 20.25,
      "yield": 1.69,
      "date": "2020-04-16"
  },
  "2020-04-17": {
      "equity": 9266.75,
      "debt": 6.347,
      "gold": 45727.50137,
      "pb": 2.64,
      "pe": 20.85,
      "yield": 1.64,
      "date": "2020-04-17"
  },
  "2020-04-18": {
      "equity": 9266.75,
      "debt": 6.347,
      "gold": 45727.50137,
      "pb": 2.64,
      "pe": 20.85,
      "yield": 1.64,
      "date": "2020-04-18"
  },
  "2020-04-19": {
      "equity": 9266.75,
      "debt": 6.347,
      "gold": 45727.50137,
      "pb": 2.64,
      "pe": 20.85,
      "yield": 1.64,
      "date": "2020-04-19"
  },
  "2020-04-20": {
      "equity": 9261.85,
      "debt": 6.209,
      "gold": 45511.39174,
      "pb": 2.64,
      "pe": 20.75,
      "yield": 1.64,
      "date": "2020-04-20"
  },
  "2020-04-21": {
      "equity": 8981.45,
      "debt": 6.212,
      "gold": 45610.76562,
      "pb": 2.56,
      "pe": 20.1,
      "yield": 1.69,
      "date": "2020-04-21"
  },
  "2020-04-22": {
      "equity": 9187.3,
      "debt": 6.224,
      "gold": 45913.08136,
      "pb": 2.62,
      "pe": 20.56,
      "yield": 1.66,
      "date": "2020-04-22"
  },
  "2020-04-23": {
      "equity": 9313.9,
      "debt": 6.06,
      "gold": 46234.86834,
      "pb": 2.65,
      "pe": 20.84,
      "yield": 1.63,
      "date": "2020-04-23"
  },
  "2020-04-24": {
      "equity": 9154.4,
      "debt": 6.167,
      "gold": 46539.39223,
      "pb": 2.61,
      "pe": 20.48,
      "yield": 1.66,
      "date": "2020-04-24"
  },
  "2020-04-25": {
      "equity": 9154.4,
      "debt": 6.167,
      "gold": 46539.39223,
      "pb": 2.61,
      "pe": 20.48,
      "yield": 1.66,
      "date": "2020-04-25"
  },
  "2020-04-26": {
      "equity": 9154.4,
      "debt": 6.167,
      "gold": 46539.39223,
      "pb": 2.61,
      "pe": 20.48,
      "yield": 1.66,
      "date": "2020-04-26"
  },
  "2020-04-27": {
      "equity": 9282.3,
      "debt": 6.153,
      "gold": 46143.59336,
      "pb": 2.65,
      "pe": 20.77,
      "yield": 1.64,
      "date": "2020-04-27"
  },
  "2020-04-28": {
      "equity": 9380.9,
      "debt": 6.137,
      "gold": 45818.79751,
      "pb": 2.67,
      "pe": 21,
      "yield": 1.62,
      "date": "2020-04-28"
  },
  "2020-04-29": {
      "equity": 9553.35,
      "debt": 6.125,
      "gold": 45344.29531,
      "pb": 2.72,
      "pe": 21.65,
      "yield": 1.59,
      "date": "2020-04-29"
  },
  "2020-04-30": {
      "equity": 9859.9,
      "debt": 6.11,
      "gold": 45550.69402,
      "pb": 2.81,
      "pe": 22.35,
      "yield": 1.54,
      "date": "2020-04-30"
  },
  "2020-05-01": {
      "equity": 9859.9,
      "debt": 6.11,
      "gold": 44772.72968,
      "pb": 2.81,
      "pe": 22.35,
      "yield": 1.54,
      "date": "2020-05-01"
  },
  "2020-05-02": {
      "equity": 9859.9,
      "debt": 6.11,
      "gold": 44772.72968,
      "pb": 2.81,
      "pe": 22.35,
      "yield": 1.54,
      "date": "2020-05-02"
  },
  "2020-05-03": {
      "equity": 9859.9,
      "debt": 6.11,
      "gold": 44772.72968,
      "pb": 2.81,
      "pe": 22.35,
      "yield": 1.54,
      "date": "2020-05-03"
  },
  "2020-05-04": {
      "equity": 9293.5,
      "debt": 6.084,
      "gold": 45468.73843,
      "pb": 2.65,
      "pe": 21.39,
      "yield": 1.64,
      "date": "2020-05-04"
  },
  "2020-05-05": {
      "equity": 9205.6,
      "debt": 6.073,
      "gold": 45282.73515,
      "pb": 2.62,
      "pe": 21.19,
      "yield": 1.65,
      "date": "2020-05-05"
  },
  "2020-05-06": {
      "equity": 9270.9,
      "debt": 6.028,
      "gold": 45577.7668,
      "pb": 2.64,
      "pe": 21.34,
      "yield": 1.64,
      "date": "2020-05-06"
  },
  "2020-05-07": {
      "equity": 9199.05,
      "debt": 6.028,
      "gold": 45086.55885,
      "pb": 2.62,
      "pe": 21.18,
      "yield": 1.65,
      "date": "2020-05-07"
  },
  "2020-05-08": {
      "equity": 9251.5,
      "debt": 5.967,
      "gold": 45086.55885,
      "pb": 2.64,
      "pe": 21.28,
      "yield": 1.64,
      "date": "2020-05-08"
  },
  "2020-05-09": {
      "equity": 9251.5,
      "debt": 5.967,
      "gold": 45086.55885,
      "pb": 2.64,
      "pe": 21.28,
      "yield": 1.64,
      "date": "2020-05-09"
  },
  "2020-05-10": {
      "equity": 9251.5,
      "debt": 5.967,
      "gold": 45086.55885,
      "pb": 2.64,
      "pe": 21.28,
      "yield": 1.64,
      "date": "2020-05-10"
  },
  "2020-05-11": {
      "equity": 9239.2,
      "debt": 6.167,
      "gold": 45473.75439,
      "pb": 2.63,
      "pe": 21.21,
      "yield": 1.65,
      "date": "2020-05-11"
  },
  "2020-05-12": {
      "equity": 9196.55,
      "debt": 6.163,
      "gold": 45163.25156,
      "pb": 2.62,
      "pe": 21.11,
      "yield": 1.65,
      "date": "2020-05-12"
  },
  "2020-05-13": {
      "equity": 9383.55,
      "debt": 6.095,
      "gold": 45194.80061,
      "pb": 2.67,
      "pe": 21.51,
      "yield": 1.62,
      "date": "2020-05-13"
  },
  "2020-05-14": {
      "equity": 9142.75,
      "debt": 6.056,
      "gold": 45717.34598,
      "pb": 2.6,
      "pe": 21,
      "yield": 1.67,
      "date": "2020-05-14"
  },
  "2020-05-15": {
      "equity": 9136.85,
      "debt": 6.081,
      "gold": 46402.70199,
      "pb": 2.6,
      "pe": 20.98,
      "yield": 1.67,
      "date": "2020-05-15"
  },
  "2020-05-16": {
      "equity": 9136.85,
      "debt": 6.081,
      "gold": 46402.70199,
      "pb": 2.6,
      "pe": 20.98,
      "yield": 1.67,
      "date": "2020-05-16"
  },
  "2020-05-17": {
      "equity": 9136.85,
      "debt": 6.081,
      "gold": 46402.70199,
      "pb": 2.6,
      "pe": 20.98,
      "yield": 1.67,
      "date": "2020-05-17"
  },
  "2020-05-18": {
      "equity": 8823.25,
      "debt": 6.05,
      "gold": 46919.66349,
      "pb": 2.51,
      "pe": 20.26,
      "yield": 1.73,
      "date": "2020-05-18"
  },
  "2020-05-19": {
      "equity": 8879.1,
      "debt": 6.035,
      "gold": 46308.85554,
      "pb": 2.53,
      "pe": 20.39,
      "yield": 1.72,
      "date": "2020-05-19"
  },
  "2020-05-20": {
      "equity": 9066.55,
      "debt": 6.041,
      "gold": 46641.66211,
      "pb": 2.58,
      "pe": 21.16,
      "yield": 1.68,
      "date": "2020-05-20"
  },
  "2020-05-21": {
      "equity": 9106.25,
      "debt": 6.034,
      "gold": 46230.20865,
      "pb": 2.59,
      "pe": 21.14,
      "yield": 1.67,
      "date": "2020-05-21"
  },
  "2020-05-22": {
      "equity": 9039.25,
      "debt": 5.961,
      "gold": 46439.0342,
      "pb": 2.57,
      "pe": 20.97,
      "yield": 1.69,
      "date": "2020-05-22"
  },
  "2020-05-23": {
      "equity": 9039.25,
      "debt": 5.961,
      "gold": 46439.0342,
      "pb": 2.57,
      "pe": 20.97,
      "yield": 1.69,
      "date": "2020-05-23"
  },
  "2020-05-24": {
      "equity": 9039.25,
      "debt": 5.961,
      "gold": 46439.0342,
      "pb": 2.57,
      "pe": 20.97,
      "yield": 1.69,
      "date": "2020-05-24"
  },
  "2020-05-25": {
      "equity": 9039.25,
      "debt": 5.961,
      "gold": 46439.0342,
      "pb": 2.57,
      "pe": 20.97,
      "yield": 1.69,
      "date": "2020-05-25"
  },
  "2020-05-26": {
      "equity": 9029.05,
      "debt": 5.975,
      "gold": 45855.24965,
      "pb": 2.57,
      "pe": 21.1,
      "yield": 1.69,
      "date": "2020-05-26"
  },
  "2020-05-27": {
      "equity": 9314.95,
      "debt": 5.984,
      "gold": 45651.60938,
      "pb": 2.65,
      "pe": 21.77,
      "yield": 1.64,
      "date": "2020-05-27"
  },
  "2020-05-28": {
      "equity": 9490.1,
      "debt": 5.99,
      "gold": 45979.30475,
      "pb": 2.7,
      "pe": 22.17,
      "yield": 1.61,
      "date": "2020-05-28"
  },
  "2020-05-29": {
      "equity": 9580.3,
      "debt": 6.013,
      "gold": 46006.88548,
      "pb": 2.73,
      "pe": 22.38,
      "yield": 1.59,
      "date": "2020-05-29"
  },
  "2020-05-30": {
      "equity": 9580.3,
      "debt": 6.013,
      "gold": 46006.88548,
      "pb": 2.73,
      "pe": 22.38,
      "yield": 1.59,
      "date": "2020-05-30"
  },
  "2020-05-31": {
      "equity": 9580.3,
      "debt": 6.013,
      "gold": 46006.88548,
      "pb": 2.73,
      "pe": 22.38,
      "yield": 1.59,
      "date": "2020-05-31"
  },
  "2020-06-01": {
      "equity": 9826.15,
      "debt": 6.042,
      "gold": 46243.17889,
      "pb": 2.8,
      "pe": 22.96,
      "yield": 1.55,
      "date": "2020-06-01"
  },
  "2020-06-02": {
      "equity": 9979.1,
      "debt": 5.998,
      "gold": 46117.4377,
      "pb": 2.84,
      "pe": 23.31,
      "yield": 1.53,
      "date": "2020-06-02"
  },
  "2020-06-03": {
      "equity": 10061.55,
      "debt": 6.031,
      "gold": 45641.16122,
      "pb": 2.86,
      "pe": 23.5,
      "yield": 1.51,
      "date": "2020-06-03"
  },
  "2020-06-04": {
      "equity": 10029.1,
      "debt": 5.796,
      "gold": 45418.91744,
      "pb": 2.85,
      "pe": 23.42,
      "yield": 1.52,
      "date": "2020-06-04"
  },
  "2020-06-05": {
      "equity": 10142.15,
      "debt": 5.821,
      "gold": 45582.77218,
      "pb": 2.89,
      "pe": 23.91,
      "yield": 1.5,
      "date": "2020-06-05"
  },
  "2020-06-06": {
      "equity": 10142.15,
      "debt": 5.821,
      "gold": 45582.77218,
      "pb": 2.89,
      "pe": 23.91,
      "yield": 1.5,
      "date": "2020-06-06"
  },
  "2020-06-07": {
      "equity": 10142.15,
      "debt": 5.821,
      "gold": 45582.77218,
      "pb": 2.89,
      "pe": 23.91,
      "yield": 1.5,
      "date": "2020-06-07"
  },
  "2020-06-08": {
      "equity": 10167.45,
      "debt": 5.807,
      "gold": 45026.85409,
      "pb": 2.89,
      "pe": 23.97,
      "yield": 1.5,
      "date": "2020-06-08"
  },
  "2020-06-09": {
      "equity": 10046.65,
      "debt": 5.775,
      "gold": 45463.12633,
      "pb": 2.86,
      "pe": 24.25,
      "yield": 1.52,
      "date": "2020-06-09"
  },
  "2020-06-10": {
      "equity": 10116.15,
      "debt": 5.772,
      "gold": 45855.70116,
      "pb": 2.88,
      "pe": 24.41,
      "yield": 1.51,
      "date": "2020-06-10"
  },
  "2020-06-11": {
      "equity": 9902,
      "debt": 5.781,
      "gold": 46549.22309,
      "pb": 2.82,
      "pe": 23.9,
      "yield": 1.54,
      "date": "2020-06-11"
  },
  "2020-06-12": {
      "equity": 9972.9,
      "debt": 5.796,
      "gold": 46540.94781,
      "pb": 2.84,
      "pe": 24.08,
      "yield": 1.53,
      "date": "2020-06-12"
  },
  "2020-06-13": {
      "equity": 9972.9,
      "debt": 5.796,
      "gold": 46540.94781,
      "pb": 2.84,
      "pe": 24.08,
      "yield": 1.53,
      "date": "2020-06-13"
  },
  "2020-06-14": {
      "equity": 9972.9,
      "debt": 5.796,
      "gold": 46540.94781,
      "pb": 2.84,
      "pe": 24.08,
      "yield": 1.53,
      "date": "2020-06-14"
  },
  "2020-06-15": {
      "equity": 9813.7,
      "debt": 5.792,
      "gold": 45835.60557,
      "pb": 2.79,
      "pe": 23.69,
      "yield": 1.55,
      "date": "2020-06-15"
  },
  "2020-06-16": {
      "equity": 9914,
      "debt": 5.846,
      "gold": 46470.7561,
      "pb": 2.82,
      "pe": 24.31,
      "yield": 1.54,
      "date": "2020-06-16"
  },
  "2020-06-17": {
      "equity": 9881.15,
      "debt": 5.835,
      "gold": 46158.32378,
      "pb": 2.81,
      "pe": 24.59,
      "yield": 1.54,
      "date": "2020-06-17"
  },
  "2020-06-18": {
      "equity": 10091.65,
      "debt": 5.822,
      "gold": 46699.86772,
      "pb": 2.87,
      "pe": 25.11,
      "yield": 1.51,
      "date": "2020-06-18"
  },
  "2020-06-19": {
      "equity": 10244.4,
      "debt": 5.849,
      "gold": 46501.49738,
      "pb": 2.92,
      "pe": 25.49,
      "yield": 1.49,
      "date": "2020-06-19"
  },
  "2020-06-20": {
      "equity": 10244.4,
      "debt": 5.849,
      "gold": 46501.49738,
      "pb": 2.92,
      "pe": 25.49,
      "yield": 1.49,
      "date": "2020-06-20"
  },
  "2020-06-21": {
      "equity": 10244.4,
      "debt": 5.849,
      "gold": 46501.49738,
      "pb": 2.92,
      "pe": 25.49,
      "yield": 1.49,
      "date": "2020-06-21"
  },
  "2020-06-22": {
      "equity": 10311.2,
      "debt": 5.877,
      "gold": 46613.88032,
      "pb": 2.94,
      "pe": 25.66,
      "yield": 1.48,
      "date": "2020-06-22"
  },
  "2020-06-23": {
      "equity": 10471,
      "debt": 5.903,
      "gold": 46824.90344,
      "pb": 2.98,
      "pe": 26.05,
      "yield": 1.45,
      "date": "2020-06-23"
  },
  "2020-06-24": {
      "equity": 10305.3,
      "debt": 5.918,
      "gold": 47391.35787,
      "pb": 2.93,
      "pe": 25.63,
      "yield": 1.48,
      "date": "2020-06-24"
  },
  "2020-06-25": {
      "equity": 10288.9,
      "debt": 5.891,
      "gold": 46868.19873,
      "pb": 2.93,
      "pe": 25.59,
      "yield": 1.48,
      "date": "2020-06-25"
  },
  "2020-06-26": {
      "equity": 10383,
      "debt": 5.913,
      "gold": 46985.49886,
      "pb": 2.97,
      "pe": 26.67,
      "yield": 1.54,
      "date": "2020-06-26"
  },
  "2020-06-27": {
      "equity": 10383,
      "debt": 5.913,
      "gold": 46985.49886,
      "pb": 2.97,
      "pe": 26.67,
      "yield": 1.54,
      "date": "2020-06-27"
  },
  "2020-06-28": {
      "equity": 10383,
      "debt": 5.913,
      "gold": 46985.49886,
      "pb": 2.97,
      "pe": 26.67,
      "yield": 1.54,
      "date": "2020-06-28"
  },
  "2020-06-29": {
      "equity": 10312.4,
      "debt": 5.903,
      "gold": 47109.87143,
      "pb": 2.95,
      "pe": 26.49,
      "yield": 1.55,
      "date": "2020-06-29"
  },
  "2020-06-30": {
      "equity": 10302.1,
      "debt": 5.888,
      "gold": 47189.83051,
      "pb": 2.94,
      "pe": 26.32,
      "yield": 1.55,
      "date": "2020-06-30"
  },
  "2020-07-01": {
      "equity": 10430.05,
      "debt": 5.838,
      "gold": 47606.52216,
      "pb": 2.98,
      "pe": 26.97,
      "yield": 1.54,
      "date": "2020-07-01"
  },
  "2020-07-02": {
      "equity": 10551.7,
      "debt": 5.841,
      "gold": 46737.6638,
      "pb": 3.01,
      "pe": 27.63,
      "yield": 1.52,
      "date": "2020-07-02"
  },
  "2020-07-03": {
      "equity": 10607.35,
      "debt": 5.845,
      "gold": 46723.7588,
      "pb": 3.03,
      "pe": 27.78,
      "yield": 1.51,
      "date": "2020-07-03"
  },
  "2020-07-04": {
      "equity": 10607.35,
      "debt": 5.845,
      "gold": 46723.7588,
      "pb": 3.03,
      "pe": 27.78,
      "yield": 1.51,
      "date": "2020-07-04"
  },
  "2020-07-05": {
      "equity": 10607.35,
      "debt": 5.845,
      "gold": 46723.7588,
      "pb": 3.03,
      "pe": 27.78,
      "yield": 1.51,
      "date": "2020-07-05"
  },
  "2020-07-06": {
      "equity": 10763.65,
      "debt": 5.836,
      "gold": 46712.0443,
      "pb": 3.07,
      "pe": 28.13,
      "yield": 1.49,
      "date": "2020-07-06"
  },
  "2020-07-07": {
      "equity": 10799.65,
      "debt": 5.792,
      "gold": 46873.08771,
      "pb": 3.08,
      "pe": 28.22,
      "yield": 1.49,
      "date": "2020-07-07"
  },
  "2020-07-08": {
      "equity": 10705.75,
      "debt": 5.778,
      "gold": 47539.22291,
      "pb": 3.05,
      "pe": 27.98,
      "yield": 1.5,
      "date": "2020-07-08"
  },
  "2020-07-09": {
      "equity": 10813.45,
      "debt": 5.772,
      "gold": 48045.15776,
      "pb": 3.08,
      "pe": 28.26,
      "yield": 1.48,
      "date": "2020-07-09"
  },
  "2020-07-10": {
      "equity": 10768.05,
      "debt": 5.76,
      "gold": 47890.67179,
      "pb": 3.07,
      "pe": 28.27,
      "yield": 1.49,
      "date": "2020-07-10"
  },
  "2020-07-11": {
      "equity": 10768.05,
      "debt": 5.76,
      "gold": 47890.67179,
      "pb": 3.07,
      "pe": 28.27,
      "yield": 1.49,
      "date": "2020-07-11"
  },
  "2020-07-12": {
      "equity": 10768.05,
      "debt": 5.76,
      "gold": 47890.67179,
      "pb": 3.07,
      "pe": 28.27,
      "yield": 1.49,
      "date": "2020-07-12"
  },
  "2020-07-13": {
      "equity": 10802.7,
      "debt": 5.786,
      "gold": 47939.36048,
      "pb": 3.08,
      "pe": 28.36,
      "yield": 1.49,
      "date": "2020-07-13"
  },
  "2020-07-14": {
      "equity": 10607.35,
      "debt": 5.825,
      "gold": 47830.17337,
      "pb": 3.02,
      "pe": 27.85,
      "yield": 1.51,
      "date": "2020-07-14"
  },
  "2020-07-15": {
      "equity": 10618.2,
      "debt": 5.815,
      "gold": 47950.29542,
      "pb": 3.03,
      "pe": 27.88,
      "yield": 1.51,
      "date": "2020-07-15"
  },
  "2020-07-16": {
      "equity": 10739.95,
      "debt": 5.813,
      "gold": 47849.79629,
      "pb": 3.06,
      "pe": 28.19,
      "yield": 1.49,
      "date": "2020-07-16"
  },
  "2020-07-17": {
      "equity": 10901.7,
      "debt": 5.805,
      "gold": 47655.96924,
      "pb": 3.11,
      "pe": 28.55,
      "yield": 1.47,
      "date": "2020-07-17"
  },
  "2020-07-18": {
      "equity": 10901.7,
      "debt": 5.805,
      "gold": 47655.96924,
      "pb": 3.11,
      "pe": 28.55,
      "yield": 1.47,
      "date": "2020-07-18"
  },
  "2020-07-19": {
      "equity": 10901.7,
      "debt": 5.805,
      "gold": 47655.96924,
      "pb": 3.11,
      "pe": 28.55,
      "yield": 1.47,
      "date": "2020-07-19"
  },
  "2020-07-20": {
      "equity": 11022.2,
      "debt": 5.799,
      "gold": 47754.76816,
      "pb": 3.14,
      "pe": 28.86,
      "yield": 1.46,
      "date": "2020-07-20"
  },
  "2020-07-21": {
      "equity": 11162.25,
      "debt": 5.829,
      "gold": 47921.42366,
      "pb": 3.18,
      "pe": 29.01,
      "yield": 1.44,
      "date": "2020-07-21"
  },
  "2020-07-22": {
      "equity": 11132.6,
      "debt": 5.814,
      "gold": 48695.21861,
      "pb": 3.17,
      "pe": 28.93,
      "yield": 1.44,
      "date": "2020-07-22"
  },
  "2020-07-23": {
      "equity": 11215.45,
      "debt": 5.805,
      "gold": 49665.36976,
      "pb": 3.2,
      "pe": 29.2,
      "yield": 1.43,
      "date": "2020-07-23"
  },
  "2020-07-24": {
      "equity": 11194.15,
      "debt": 5.817,
      "gold": 49956.90224,
      "pb": 3.19,
      "pe": 29.35,
      "yield": 1.43,
      "date": "2020-07-24"
  },
  "2020-07-25": {
      "equity": 11194.15,
      "debt": 5.817,
      "gold": 49956.90224,
      "pb": 3.19,
      "pe": 29.35,
      "yield": 1.43,
      "date": "2020-07-25"
  },
  "2020-07-26": {
      "equity": 11194.15,
      "debt": 5.817,
      "gold": 49956.90224,
      "pb": 3.19,
      "pe": 29.35,
      "yield": 1.43,
      "date": "2020-07-26"
  },
  "2020-07-27": {
      "equity": 11131.8,
      "debt": 5.859,
      "gold": 51194.39144,
      "pb": 3.17,
      "pe": 29.18,
      "yield": 1.44,
      "date": "2020-07-27"
  },
  "2020-07-28": {
      "equity": 11300.55,
      "debt": 5.85,
      "gold": 50995.43907,
      "pb": 3.14,
      "pe": 29.87,
      "yield": 1.49,
      "date": "2020-07-28"
  },
  "2020-07-29": {
      "equity": 11202.85,
      "debt": 5.835,
      "gold": 51614.01788,
      "pb": 3.1,
      "pe": 29.59,
      "yield": 1.54,
      "date": "2020-07-29"
  },
  "2020-07-30": {
      "equity": 11102.15,
      "debt": 5.825,
      "gold": 51547.25127,
      "pb": 3.07,
      "pe": 29.46,
      "yield": 1.56,
      "date": "2020-07-30"
  },
  "2020-07-31": {
      "equity": 11073.45,
      "debt": 5.837,
      "gold": 52187.12147,
      "pb": 3.12,
      "pe": 30.2,
      "yield": 1.49,
      "date": "2020-07-31"
  },
  "2020-08-01": {
      "equity": 11073.45,
      "debt": 5.837,
      "gold": 52187.12147,
      "pb": 3.12,
      "pe": 30.2,
      "yield": 1.49,
      "date": "2020-08-01"
  },
  "2020-08-02": {
      "equity": 11073.45,
      "debt": 5.837,
      "gold": 52187.12147,
      "pb": 3.12,
      "pe": 30.2,
      "yield": 1.49,
      "date": "2020-08-02"
  },
  "2020-08-03": {
      "equity": 10891.6,
      "debt": 5.837,
      "gold": 52349.51586,
      "pb": 3.07,
      "pe": 29.7,
      "yield": 1.51,
      "date": "2020-08-03"
  },
  "2020-08-04": {
      "equity": 11095.25,
      "debt": 5.833,
      "gold": 52218.86453,
      "pb": 3.12,
      "pe": 30.39,
      "yield": 1.49,
      "date": "2020-08-04"
  },
  "2020-08-05": {
      "equity": 11101.65,
      "debt": 5.826,
      "gold": 53714.37591,
      "pb": 3.13,
      "pe": 30.41,
      "yield": 1.48,
      "date": "2020-08-05"
  },
  "2020-08-06": {
      "equity": 11200.15,
      "debt": 5.862,
      "gold": 54153.44891,
      "pb": 3.15,
      "pe": 30.68,
      "yield": 1.47,
      "date": "2020-08-06"
  },
  "2020-08-07": {
      "equity": 11214.05,
      "debt": 5.886,
      "gold": 54564.32389,
      "pb": 3.16,
      "pe": 30.72,
      "yield": 1.47,
      "date": "2020-08-07"
  },
  "2020-08-08": {
      "equity": 11214.05,
      "debt": 5.886,
      "gold": 54564.32389,
      "pb": 3.16,
      "pe": 30.72,
      "yield": 1.47,
      "date": "2020-08-08"
  },
  "2020-08-09": {
      "equity": 11214.05,
      "debt": 5.886,
      "gold": 54564.32389,
      "pb": 3.16,
      "pe": 30.72,
      "yield": 1.47,
      "date": "2020-08-09"
  },
  "2020-08-10": {
      "equity": 11270.15,
      "debt": 5.893,
      "gold": 53646.48406,
      "pb": 3.17,
      "pe": 31.23,
      "yield": 1.46,
      "date": "2020-08-10"
  },
  "2020-08-11": {
      "equity": 11322.5,
      "debt": 5.903,
      "gold": 52535.45212,
      "pb": 3.19,
      "pe": 31.37,
      "yield": 1.46,
      "date": "2020-08-11"
  },
  "2020-08-12": {
      "equity": 11308.4,
      "debt": 5.884,
      "gold": 50937.61442,
      "pb": 3.18,
      "pe": 31.33,
      "yield": 1.46,
      "date": "2020-08-12"
  },
  "2020-08-13": {
      "equity": 11300.45,
      "debt": 5.895,
      "gold": 50971.46687,
      "pb": 3.18,
      "pe": 31.42,
      "yield": 1.46,
      "date": "2020-08-13"
  },
  "2020-08-14": {
      "equity": 11178.4,
      "debt": 5.965,
      "gold": 51419.53474,
      "pb": 3.15,
      "pe": 31.09,
      "yield": 1.47,
      "date": "2020-08-14"
  },
  "2020-08-15": {
      "equity": 11178.4,
      "debt": 5.965,
      "gold": 51419.53474,
      "pb": 3.15,
      "pe": 31.09,
      "yield": 1.47,
      "date": "2020-08-15"
  },
  "2020-08-16": {
      "equity": 11178.4,
      "debt": 5.965,
      "gold": 51419.53474,
      "pb": 3.15,
      "pe": 31.09,
      "yield": 1.47,
      "date": "2020-08-16"
  },
  "2020-08-17": {
      "equity": 11247.1,
      "debt": 5.975,
      "gold": 51420.55063,
      "pb": 3.17,
      "pe": 31.64,
      "yield": 1.46,
      "date": "2020-08-17"
  },
  "2020-08-18": {
      "equity": 11385.35,
      "debt": 5.986,
      "gold": 52780.65222,
      "pb": 3.21,
      "pe": 32.03,
      "yield": 1.45,
      "date": "2020-08-18"
  },
  "2020-08-19": {
      "equity": 11408.4,
      "debt": 6.008,
      "gold": 52613.76038,
      "pb": 3.21,
      "pe": 32.09,
      "yield": 1.44,
      "date": "2020-08-19"
  },
  "2020-08-20": {
      "equity": 11312.2,
      "debt": 6,
      "gold": 51026.21563,
      "pb": 3.17,
      "pe": 31.91,
      "yield": 1.49,
      "date": "2020-08-20"
  },
  "2020-08-21": {
      "equity": 11371.6,
      "debt": 6.14,
      "gold": 51112.34061,
      "pb": 3.18,
      "pe": 32.08,
      "yield": 1.47,
      "date": "2020-08-21"
  },
  "2020-08-22": {
      "equity": 11371.6,
      "debt": 6.14,
      "gold": 51112.34061,
      "pb": 3.18,
      "pe": 32.08,
      "yield": 1.47,
      "date": "2020-08-22"
  },
  "2020-08-23": {
      "equity": 11371.6,
      "debt": 6.14,
      "gold": 51112.34061,
      "pb": 3.18,
      "pe": 32.08,
      "yield": 1.47,
      "date": "2020-08-23"
  },
  "2020-08-24": {
      "equity": 11466.45,
      "debt": 6.221,
      "gold": 50952.172,
      "pb": 3.21,
      "pe": 32.41,
      "yield": 1.46,
      "date": "2020-08-24"
  },
  "2020-08-25": {
      "equity": 11472.25,
      "debt": 6.157,
      "gold": 50420.85046,
      "pb": 3.21,
      "pe": 32.43,
      "yield": 1.46,
      "date": "2020-08-25"
  },
  "2020-08-26": {
      "equity": 11549.6,
      "debt": 6.203,
      "gold": 50237.50331,
      "pb": 3.23,
      "pe": 32.64,
      "yield": 1.45,
      "date": "2020-08-26"
  },
  "2020-08-27": {
      "equity": 11559.25,
      "debt": 6.147,
      "gold": 50494.83412,
      "pb": 3.23,
      "pe": 32.67,
      "yield": 1.45,
      "date": "2020-08-27"
  },
  "2020-08-28": {
      "equity": 11647.6,
      "debt": 6.097,
      "gold": 50498.38621,
      "pb": 3.26,
      "pe": 32.92,
      "yield": 1.43,
      "date": "2020-08-28"
  },
  "2020-08-29": {
      "equity": 11647.6,
      "debt": 6.097,
      "gold": 50498.38621,
      "pb": 3.26,
      "pe": 32.92,
      "yield": 1.43,
      "date": "2020-08-29"
  },
  "2020-08-30": {
      "equity": 11647.6,
      "debt": 6.097,
      "gold": 50498.38621,
      "pb": 3.26,
      "pe": 32.92,
      "yield": 1.43,
      "date": "2020-08-30"
  },
  "2020-08-31": {
      "equity": 11387.5,
      "debt": 6.078,
      "gold": 50498.38621,
      "pb": 3.2,
      "pe": 32.29,
      "yield": 1.46,
      "date": "2020-08-31"
  },
  "2020-09-01": {
      "equity": 11470.25,
      "debt": 5.943,
      "gold": 51238.47334,
      "pb": 3.22,
      "pe": 32.53,
      "yield": 1.45,
      "date": "2020-09-01"
  },
  "2020-09-02": {
      "equity": 11535,
      "debt": 5.92,
      "gold": 50885.8322,
      "pb": 3.24,
      "pe": 32.71,
      "yield": 1.44,
      "date": "2020-09-02"
  },
  "2020-09-03": {
      "equity": 11527.45,
      "debt": 5.896,
      "gold": 50113.1766,
      "pb": 3.24,
      "pe": 33.04,
      "yield": 1.45,
      "date": "2020-09-03"
  },
  "2020-09-04": {
      "equity": 11333.85,
      "debt": 5.93,
      "gold": 50058.62537,
      "pb": 3.18,
      "pe": 32.49,
      "yield": 1.48,
      "date": "2020-09-04"
  },
  "2020-09-05": {
      "equity": 11333.85,
      "debt": 5.93,
      "gold": 50058.62537,
      "pb": 3.18,
      "pe": 32.49,
      "yield": 1.48,
      "date": "2020-09-05"
  },
  "2020-09-06": {
      "equity": 11333.85,
      "debt": 5.93,
      "gold": 50058.62537,
      "pb": 3.18,
      "pe": 32.49,
      "yield": 1.48,
      "date": "2020-09-06"
  },
  "2020-09-07": {
      "equity": 11355.05,
      "debt": 5.993,
      "gold": 49959.36436,
      "pb": 3.19,
      "pe": 32.55,
      "yield": 1.48,
      "date": "2020-09-07"
  },
  "2020-09-08": {
      "equity": 11317.35,
      "debt": 6.056,
      "gold": 49888.37898,
      "pb": 3.18,
      "pe": 32.44,
      "yield": 1.48,
      "date": "2020-09-08"
  },
  "2020-09-09": {
      "equity": 11278,
      "debt": 5.989,
      "gold": 49877.73682,
      "pb": 3.17,
      "pe": 32.33,
      "yield": 1.49,
      "date": "2020-09-09"
  },
  "2020-09-10": {
      "equity": 11449.25,
      "debt": 6.053,
      "gold": 50450.78396,
      "pb": 3.22,
      "pe": 32.82,
      "yield": 1.46,
      "date": "2020-09-10"
  },
  "2020-09-11": {
      "equity": 11464.45,
      "debt": 6.041,
      "gold": 50369.88659,
      "pb": 3.22,
      "pe": 32.86,
      "yield": 1.46,
      "date": "2020-09-11"
  },
  "2020-09-12": {
      "equity": 11464.45,
      "debt": 6.041,
      "gold": 50369.88659,
      "pb": 3.22,
      "pe": 32.86,
      "yield": 1.46,
      "date": "2020-09-12"
  },
  "2020-09-13": {
      "equity": 11464.45,
      "debt": 6.041,
      "gold": 50369.88659,
      "pb": 3.22,
      "pe": 32.86,
      "yield": 1.46,
      "date": "2020-09-13"
  },
  "2020-09-14": {
      "equity": 11440.05,
      "debt": 6.031,
      "gold": 50283.50412,
      "pb": 3.21,
      "pe": 32.79,
      "yield": 1.47,
      "date": "2020-09-14"
  },
  "2020-09-15": {
      "equity": 11521.8,
      "debt": 6.029,
      "gold": 50978.39821,
      "pb": 3.2,
      "pe": 33.03,
      "yield": 1.42,
      "date": "2020-09-15"
  },
  "2020-09-16": {
      "equity": 11604.55,
      "debt": 5.992,
      "gold": 50907.23646,
      "pb": 3.23,
      "pe": 33.26,
      "yield": 1.41,
      "date": "2020-09-16"
  },
  "2020-09-17": {
      "equity": 11516.1,
      "debt": 6.033,
      "gold": 50275.70151,
      "pb": 3.2,
      "pe": 33.01,
      "yield": 1.42,
      "date": "2020-09-17"
  },
  "2020-09-18": {
      "equity": 11504.95,
      "debt": 6.014,
      "gold": 50704.07591,
      "pb": 3.2,
      "pe": 32.98,
      "yield": 1.42,
      "date": "2020-09-18"
  },
  "2020-09-19": {
      "equity": 11504.95,
      "debt": 6.014,
      "gold": 50704.07591,
      "pb": 3.2,
      "pe": 32.98,
      "yield": 1.42,
      "date": "2020-09-19"
  },
  "2020-09-20": {
      "equity": 11504.95,
      "debt": 6.014,
      "gold": 50704.07591,
      "pb": 3.2,
      "pe": 32.98,
      "yield": 1.42,
      "date": "2020-09-20"
  },
  "2020-09-21": {
      "equity": 11250.55,
      "debt": 6.016,
      "gold": 50116.08318,
      "pb": 3.13,
      "pe": 32.25,
      "yield": 1.45,
      "date": "2020-09-21"
  },
  "2020-09-22": {
      "equity": 11153.65,
      "debt": 6.01,
      "gold": 49362.3168,
      "pb": 3.1,
      "pe": 31.97,
      "yield": 1.47,
      "date": "2020-09-22"
  },
  "2020-09-23": {
      "equity": 11131.85,
      "debt": 5.99,
      "gold": 48985.17081,
      "pb": 3.1,
      "pe": 31.91,
      "yield": 1.47,
      "date": "2020-09-23"
  },
  "2020-09-24": {
      "equity": 10805.55,
      "debt": 5.992,
      "gold": 48124.59126,
      "pb": 3.01,
      "pe": 30.97,
      "yield": 1.51,
      "date": "2020-09-24"
  },
  "2020-09-25": {
      "equity": 11050.25,
      "debt": 6.038,
      "gold": 48634.09584,
      "pb": 3.13,
      "pe": 32.12,
      "yield": 1.44,
      "date": "2020-09-25"
  },
  "2020-09-26": {
      "equity": 11050.25,
      "debt": 6.038,
      "gold": 48634.09584,
      "pb": 3.13,
      "pe": 32.12,
      "yield": 1.44,
      "date": "2020-09-26"
  },
  "2020-09-27": {
      "equity": 11050.25,
      "debt": 6.038,
      "gold": 48634.09584,
      "pb": 3.13,
      "pe": 32.12,
      "yield": 1.44,
      "date": "2020-09-27"
  },
  "2020-09-28": {
      "equity": 11227.55,
      "debt": 6.056,
      "gold": 48184.63818,
      "pb": 3.18,
      "pe": 32.63,
      "yield": 1.41,
      "date": "2020-09-28"
  },
  "2020-09-29": {
      "equity": 11222.4,
      "debt": 6.037,
      "gold": 48976.79677,
      "pb": 3.18,
      "pe": 32.62,
      "yield": 1.42,
      "date": "2020-09-29"
  },
  "2020-09-30": {
      "equity": 11247.55,
      "debt": 6.015,
      "gold": 48876.18829,
      "pb": 3.19,
      "pe": 32.69,
      "yield": 1.41,
      "date": "2020-09-30"
  },
  "2020-10-01": {
      "equity": 11416.95,
      "debt": 5.996,
      "gold": 48965.562,
      "pb": 3.23,
      "pe": 33.18,
      "yield": 1.39,
      "date": "2020-10-01"
  },
  "2020-10-02": {
      "equity": 11416.95,
      "debt": 5.996,
      "gold": 49292.90816,
      "pb": 3.23,
      "pe": 33.18,
      "yield": 1.39,
      "date": "2020-10-02"
  },
  "2020-10-03": {
      "equity": 11416.95,
      "debt": 5.996,
      "gold": 49292.90816,
      "pb": 3.23,
      "pe": 33.18,
      "yield": 1.39,
      "date": "2020-10-03"
  },
  "2020-10-04": {
      "equity": 11416.95,
      "debt": 5.996,
      "gold": 49292.90816,
      "pb": 3.23,
      "pe": 33.18,
      "yield": 1.39,
      "date": "2020-10-04"
  },
  "2020-10-05": {
      "equity": 11503.35,
      "debt": 6.004,
      "gold": 48984.35951,
      "pb": 3.26,
      "pe": 33.43,
      "yield": 1.38,
      "date": "2020-10-05"
  },
  "2020-10-06": {
      "equity": 11662.4,
      "debt": 6.026,
      "gold": 49553.1773,
      "pb": 3.3,
      "pe": 33.9,
      "yield": 1.36,
      "date": "2020-10-06"
  },
  "2020-10-07": {
      "equity": 11738.85,
      "debt": 6.014,
      "gold": 48841.12242,
      "pb": 3.32,
      "pe": 34.12,
      "yield": 1.35,
      "date": "2020-10-07"
  },
  "2020-10-08": {
      "equity": 11834.6,
      "debt": 6.015,
      "gold": 48922.11503,
      "pb": 3.35,
      "pe": 34.4,
      "yield": 1.34,
      "date": "2020-10-08"
  },
  "2020-10-09": {
      "equity": 11914.2,
      "debt": 5.937,
      "gold": 49234.67786,
      "pb": 3.37,
      "pe": 34.71,
      "yield": 1.33,
      "date": "2020-10-09"
  },
  "2020-10-10": {
      "equity": 11914.2,
      "debt": 5.937,
      "gold": 49234.67786,
      "pb": 3.37,
      "pe": 34.71,
      "yield": 1.33,
      "date": "2020-10-10"
  },
  "2020-10-11": {
      "equity": 11914.2,
      "debt": 5.937,
      "gold": 49234.67786,
      "pb": 3.37,
      "pe": 34.71,
      "yield": 1.33,
      "date": "2020-10-11"
  },
  "2020-10-12": {
      "equity": 11930.95,
      "debt": 5.899,
      "gold": 49679.00316,
      "pb": 3.38,
      "pe": 34.76,
      "yield": 1.33,
      "date": "2020-10-12"
  },
  "2020-10-13": {
      "equity": 11934.5,
      "debt": 5.904,
      "gold": 49741.67093,
      "pb": 3.38,
      "pe": 34.77,
      "yield": 1.33,
      "date": "2020-10-13"
  },
  "2020-10-14": {
      "equity": 11971.05,
      "debt": 5.9,
      "gold": 49016.8398,
      "pb": 3.39,
      "pe": 34.87,
      "yield": 1.33,
      "date": "2020-10-14"
  },
  "2020-10-15": {
      "equity": 11680.35,
      "debt": 5.898,
      "gold": 48999.89771,
      "pb": 3.31,
      "pe": 34.03,
      "yield": 1.36,
      "date": "2020-10-15"
  },
  "2020-10-16": {
      "equity": 11762.45,
      "debt": 5.935,
      "gold": 49428.63543,
      "pb": 3.33,
      "pe": 34.13,
      "yield": 1.35,
      "date": "2020-10-16"
  },
  "2020-10-17": {
      "equity": 11762.45,
      "debt": 5.935,
      "gold": 49428.63543,
      "pb": 3.33,
      "pe": 34.13,
      "yield": 1.35,
      "date": "2020-10-17"
  },
  "2020-10-18": {
      "equity": 11762.45,
      "debt": 5.935,
      "gold": 49428.63543,
      "pb": 3.33,
      "pe": 34.13,
      "yield": 1.35,
      "date": "2020-10-18"
  },
  "2020-10-19": {
      "equity": 11873.05,
      "debt": 5.94,
      "gold": 49420.35309,
      "pb": 3.36,
      "pe": 34.43,
      "yield": 1.34,
      "date": "2020-10-19"
  },
  "2020-10-20": {
      "equity": 11896.8,
      "debt": 5.931,
      "gold": 49406.26819,
      "pb": 3.37,
      "pe": 34.28,
      "yield": 1.33,
      "date": "2020-10-20"
  },
  "2020-10-21": {
      "equity": 11937.65,
      "debt": 5.912,
      "gold": 49809.64391,
      "pb": 3.38,
      "pe": 34.4,
      "yield": 1.33,
      "date": "2020-10-21"
  },
  "2020-10-22": {
      "equity": 11896.45,
      "debt": 5.915,
      "gold": 49808.55394,
      "pb": 3.37,
      "pe": 34.27,
      "yield": 1.33,
      "date": "2020-10-22"
  },
  "2020-10-23": {
      "equity": 11930.35,
      "debt": 5.844,
      "gold": 49766.11228,
      "pb": 3.38,
      "pe": 34.37,
      "yield": 1.33,
      "date": "2020-10-23"
  },
  "2020-10-24": {
      "equity": 11930.35,
      "debt": 5.844,
      "gold": 49766.11228,
      "pb": 3.38,
      "pe": 34.37,
      "yield": 1.33,
      "date": "2020-10-24"
  },
  "2020-10-25": {
      "equity": 11930.35,
      "debt": 5.844,
      "gold": 49766.11228,
      "pb": 3.38,
      "pe": 34.37,
      "yield": 1.33,
      "date": "2020-10-25"
  },
  "2020-10-26": {
      "equity": 11767.75,
      "debt": 5.834,
      "gold": 49613.18189,
      "pb": 3.33,
      "pe": 33.95,
      "yield": 1.35,
      "date": "2020-10-26"
  },
  "2020-10-27": {
      "equity": 11889.4,
      "debt": 5.846,
      "gold": 49384.99797,
      "pb": 3.37,
      "pe": 34.49,
      "yield": 1.34,
      "date": "2020-10-27"
  },
  "2020-10-28": {
      "equity": 11729.6,
      "debt": 5.859,
      "gold": 49655.18969,
      "pb": 3.32,
      "pe": 33.94,
      "yield": 1.35,
      "date": "2020-10-28"
  },
  "2020-10-29": {
      "equity": 11670.8,
      "debt": 5.881,
      "gold": 49251.13318,
      "pb": 3.3,
      "pe": 31.56,
      "yield": 1.36,
      "date": "2020-10-29"
  },
  "2020-10-30": {
      "equity": 11642.4,
      "debt": 5.881,
      "gold": 49352.60587,
      "pb": 3.3,
      "pe": 31.9,
      "yield": 1.36,
      "date": "2020-10-30"
  },
  "2020-10-31": {
      "equity": 11642.4,
      "debt": 5.881,
      "gold": 49352.60587,
      "pb": 3.3,
      "pe": 31.9,
      "yield": 1.36,
      "date": "2020-10-31"
  },
  "2020-11-01": {
      "equity": 11642.4,
      "debt": 5.881,
      "gold": 49352.60587,
      "pb": 3.3,
      "pe": 31.9,
      "yield": 1.36,
      "date": "2020-11-01"
  },
  "2020-11-02": {
      "equity": 11669.15,
      "debt": 5.89,
      "gold": 49587.79167,
      "pb": 3.3,
      "pe": 31.43,
      "yield": 1.36,
      "date": "2020-11-02"
  },
  "2020-11-03": {
      "equity": 11813.5,
      "debt": 5.898,
      "gold": 50040.98838,
      "pb": 3.34,
      "pe": 31.82,
      "yield": 1.34,
      "date": "2020-11-03"
  },
  "2020-11-04": {
      "equity": 11908.5,
      "debt": 5.886,
      "gold": 49438.91427,
      "pb": 3.37,
      "pe": 32.27,
      "yield": 1.33,
      "date": "2020-11-04"
  },
  "2020-11-05": {
      "equity": 12120.3,
      "debt": 5.86,
      "gold": 50007.38637,
      "pb": 3.43,
      "pe": 32.84,
      "yield": 1.31,
      "date": "2020-11-05"
  },
  "2020-11-06": {
      "equity": 12263.55,
      "debt": 5.871,
      "gold": 50826.24385,
      "pb": 3.47,
      "pe": 33.09,
      "yield": 1.29,
      "date": "2020-11-06"
  },
  "2020-11-07": {
      "equity": 12263.55,
      "debt": 5.871,
      "gold": 50826.24385,
      "pb": 3.47,
      "pe": 33.09,
      "yield": 1.29,
      "date": "2020-11-07"
  },
  "2020-11-08": {
      "equity": 12263.55,
      "debt": 5.871,
      "gold": 50826.24385,
      "pb": 3.47,
      "pe": 33.09,
      "yield": 1.29,
      "date": "2020-11-08"
  },
  "2020-11-09": {
      "equity": 12461.05,
      "debt": 5.876,
      "gold": 51136.95127,
      "pb": 3.53,
      "pe": 33.62,
      "yield": 1.27,
      "date": "2020-11-09"
  },
  "2020-11-10": {
      "equity": 12631.1,
      "debt": 5.916,
      "gold": 49123.24732,
      "pb": 3.58,
      "pe": 34.18,
      "yield": 1.26,
      "date": "2020-11-10"
  },
  "2020-11-11": {
      "equity": 12749.15,
      "debt": 5.903,
      "gold": 49292.51662,
      "pb": 3.61,
      "pe": 34.5,
      "yield": 1.25,
      "date": "2020-11-11"
  },
  "2020-11-12": {
      "equity": 12690.8,
      "debt": 5.903,
      "gold": 49158.58833,
      "pb": 3.59,
      "pe": 34.48,
      "yield": 1.25,
      "date": "2020-11-12"
  },
  "2020-11-13": {
      "equity": 12719.95,
      "debt": 5.878,
      "gold": 49379.3118,
      "pb": 3.6,
      "pe": 34.57,
      "yield": 1.25,
      "date": "2020-11-13"
  },
  "2020-11-14": {
      "equity": 12780.25,
      "debt": 5.878,
      "gold": 49379.3118,
      "pb": 3.62,
      "pe": 34.73,
      "yield": 1.24,
      "date": "2020-11-14"
  },
  "2020-11-15": {
      "equity": 12780.25,
      "debt": 5.878,
      "gold": 49379.3118,
      "pb": 3.62,
      "pe": 34.73,
      "yield": 1.24,
      "date": "2020-11-15"
  },
  "2020-11-16": {
      "equity": 12780.25,
      "debt": 5.878,
      "gold": 49673.17942,
      "pb": 3.62,
      "pe": 34.73,
      "yield": 1.24,
      "date": "2020-11-16"
  },
  "2020-11-17": {
      "equity": 12874.2,
      "debt": 5.877,
      "gold": 49498.11108,
      "pb": 3.64,
      "pe": 34.93,
      "yield": 1.23,
      "date": "2020-11-17"
  },
  "2020-11-18": {
      "equity": 12938.25,
      "debt": 5.878,
      "gold": 49091.79703,
      "pb": 3.66,
      "pe": 35.59,
      "yield": 1.23,
      "date": "2020-11-18"
  },
  "2020-11-19": {
      "equity": 12771.7,
      "debt": 5.876,
      "gold": 48562.53197,
      "pb": 3.61,
      "pe": 35.12,
      "yield": 1.24,
      "date": "2020-11-19"
  },
  "2020-11-20": {
      "equity": 12859.05,
      "debt": 5.877,
      "gold": 48810.23298,
      "pb": 3.64,
      "pe": 35.36,
      "yield": 1.23,
      "date": "2020-11-20"
  },
  "2020-11-21": {
      "equity": 12859.05,
      "debt": 5.877,
      "gold": 48810.23298,
      "pb": 3.64,
      "pe": 35.36,
      "yield": 1.23,
      "date": "2020-11-21"
  },
  "2020-11-22": {
      "equity": 12859.05,
      "debt": 5.877,
      "gold": 48810.23298,
      "pb": 3.64,
      "pe": 35.36,
      "yield": 1.23,
      "date": "2020-11-22"
  },
  "2020-11-23": {
      "equity": 12926.45,
      "debt": 5.9,
      "gold": 48796.45849,
      "pb": 3.66,
      "pe": 35.55,
      "yield": 1.23,
      "date": "2020-11-23"
  },
  "2020-11-24": {
      "equity": 13055.15,
      "debt": 5.88,
      "gold": 47491.0845,
      "pb": 3.7,
      "pe": 35.9,
      "yield": 1.22,
      "date": "2020-11-24"
  },
  "2020-11-25": {
      "equity": 12858.4,
      "debt": 5.885,
      "gold": 47040.35345,
      "pb": 3.64,
      "pe": 35.36,
      "yield": 1.23,
      "date": "2020-11-25"
  },
  "2020-11-26": {
      "equity": 12987,
      "debt": 5.872,
      "gold": 47256.19852,
      "pb": 3.68,
      "pe": 35.71,
      "yield": 1.22,
      "date": "2020-11-26"
  },
  "2020-11-27": {
      "equity": 12968.95,
      "debt": 5.911,
      "gold": 47192.469,
      "pb": 3.67,
      "pe": 35.66,
      "yield": 1.22,
      "date": "2020-11-27"
  },
  "2020-11-28": {
      "equity": 12968.95,
      "debt": 5.911,
      "gold": 47192.469,
      "pb": 3.67,
      "pe": 35.66,
      "yield": 1.22,
      "date": "2020-11-28"
  },
  "2020-11-29": {
      "equity": 12968.95,
      "debt": 5.911,
      "gold": 47192.469,
      "pb": 3.67,
      "pe": 35.66,
      "yield": 1.22,
      "date": "2020-11-29"
  },
  "2020-11-30": {
      "equity": 12968.95,
      "debt": 5.911,
      "gold": 46250.21253,
      "pb": 3.67,
      "pe": 35.66,
      "yield": 1.22,
      "date": "2020-11-30"
  },
  "2020-12-01": {
      "equity": 13109.05,
      "debt": 5.93,
      "gold": 46568.0206,
      "pb": 3.71,
      "pe": 36.05,
      "yield": 1.21,
      "date": "2020-12-01"
  },
  "2020-12-02": {
      "equity": 13113.75,
      "debt": 5.92,
      "gold": 47666.39976,
      "pb": 3.71,
      "pe": 36.06,
      "yield": 1.21,
      "date": "2020-12-02"
  },
  "2020-12-03": {
      "equity": 13133.9,
      "debt": 5.932,
      "gold": 47794.6313,
      "pb": 3.72,
      "pe": 36.12,
      "yield": 1.21,
      "date": "2020-12-03"
  },
  "2020-12-04": {
      "equity": 13258.55,
      "debt": 5.897,
      "gold": 47852.87571,
      "pb": 3.75,
      "pe": 36.46,
      "yield": 1.2,
      "date": "2020-12-04"
  },
  "2020-12-05": {
      "equity": 13258.55,
      "debt": 5.897,
      "gold": 47852.87571,
      "pb": 3.75,
      "pe": 36.46,
      "yield": 1.2,
      "date": "2020-12-05"
  },
  "2020-12-06": {
      "equity": 13258.55,
      "debt": 5.897,
      "gold": 47852.87571,
      "pb": 3.75,
      "pe": 36.46,
      "yield": 1.2,
      "date": "2020-12-06"
  },
  "2020-12-07": {
      "equity": 13355.75,
      "debt": 5.924,
      "gold": 47663.78948,
      "pb": 3.78,
      "pe": 36.73,
      "yield": 1.19,
      "date": "2020-12-07"
  },
  "2020-12-08": {
      "equity": 13392.95,
      "debt": 5.939,
      "gold": 48467.79308,
      "pb": 3.79,
      "pe": 36.83,
      "yield": 1.19,
      "date": "2020-12-08"
  },
  "2020-12-09": {
      "equity": 13529.1,
      "debt": 5.917,
      "gold": 48412.07076,
      "pb": 3.83,
      "pe": 37.2,
      "yield": 1.17,
      "date": "2020-12-09"
  },
  "2020-12-10": {
      "equity": 13478.3,
      "debt": 5.916,
      "gold": 47719.15554,
      "pb": 3.81,
      "pe": 37.06,
      "yield": 1.18,
      "date": "2020-12-10"
  },
  "2020-12-11": {
      "equity": 13513.85,
      "debt": 5.956,
      "gold": 47695.46906,
      "pb": 3.82,
      "pe": 37.16,
      "yield": 1.18,
      "date": "2020-12-11"
  },
  "2020-12-12": {
      "equity": 13513.85,
      "debt": 5.956,
      "gold": 47695.46906,
      "pb": 3.82,
      "pe": 37.16,
      "yield": 1.18,
      "date": "2020-12-12"
  },
  "2020-12-13": {
      "equity": 13513.85,
      "debt": 5.956,
      "gold": 47695.46906,
      "pb": 3.82,
      "pe": 37.16,
      "yield": 1.18,
      "date": "2020-12-13"
  },
  "2020-12-14": {
      "equity": 13558.15,
      "debt": 5.946,
      "gold": 47301.65259,
      "pb": 3.84,
      "pe": 37.28,
      "yield": 1.17,
      "date": "2020-12-14"
  },
  "2020-12-15": {
      "equity": 13567.85,
      "debt": 5.941,
      "gold": 47829.04108,
      "pb": 3.84,
      "pe": 37.31,
      "yield": 1.17,
      "date": "2020-12-15"
  },
  "2020-12-16": {
      "equity": 13682.7,
      "debt": 5.944,
      "gold": 48347.03963,
      "pb": 3.87,
      "pe": 37.63,
      "yield": 1.16,
      "date": "2020-12-16"
  },
  "2020-12-17": {
      "equity": 13740.7,
      "debt": 5.952,
      "gold": 48550.72223,
      "pb": 3.89,
      "pe": 37.79,
      "yield": 1.16,
      "date": "2020-12-17"
  },
  "2020-12-18": {
      "equity": 13760.55,
      "debt": 5.959,
      "gold": 48738.90192,
      "pb": 3.89,
      "pe": 37.84,
      "yield": 1.15,
      "date": "2020-12-18"
  },
  "2020-12-19": {
      "equity": 13760.55,
      "debt": 5.959,
      "gold": 48738.90192,
      "pb": 3.89,
      "pe": 37.84,
      "yield": 1.15,
      "date": "2020-12-19"
  },
  "2020-12-20": {
      "equity": 13760.55,
      "debt": 5.959,
      "gold": 48738.90192,
      "pb": 3.89,
      "pe": 37.84,
      "yield": 1.15,
      "date": "2020-12-20"
  },
  "2020-12-21": {
      "equity": 13328.4,
      "debt": 5.957,
      "gold": 48765.53731,
      "pb": 3.77,
      "pe": 36.65,
      "yield": 1.19,
      "date": "2020-12-21"
  },
  "2020-12-22": {
      "equity": 13466.3,
      "debt": 5.951,
      "gold": 48816.01792,
      "pb": 3.81,
      "pe": 37.03,
      "yield": 1.18,
      "date": "2020-12-22"
  },
  "2020-12-23": {
      "equity": 13601.1,
      "debt": 5.95,
      "gold": 48598.14459,
      "pb": 3.85,
      "pe": 37.4,
      "yield": 1.17,
      "date": "2020-12-23"
  },
  "2020-12-24": {
      "equity": 13749.25,
      "debt": 5.929,
      "gold": 48558.15799,
      "pb": 3.89,
      "pe": 37.81,
      "yield": 1.15,
      "date": "2020-12-24"
  },
  "2020-12-25": {
      "equity": 13749.25,
      "debt": 5.929,
      "gold": 48558.15799,
      "pb": 3.89,
      "pe": 37.81,
      "yield": 1.15,
      "date": "2020-12-25"
  },
  "2020-12-26": {
      "equity": 13749.25,
      "debt": 5.929,
      "gold": 48558.15799,
      "pb": 3.89,
      "pe": 37.81,
      "yield": 1.15,
      "date": "2020-12-26"
  },
  "2020-12-27": {
      "equity": 13749.25,
      "debt": 5.929,
      "gold": 48558.15799,
      "pb": 3.89,
      "pe": 37.81,
      "yield": 1.15,
      "date": "2020-12-27"
  },
  "2020-12-28": {
      "equity": 13873.2,
      "debt": 5.925,
      "gold": 48558.15799,
      "pb": 3.93,
      "pe": 38.15,
      "yield": 1.14,
      "date": "2020-12-28"
  },
  "2020-12-29": {
      "equity": 13932.6,
      "debt": 5.927,
      "gold": 48530.5702,
      "pb": 3.94,
      "pe": 38.31,
      "yield": 1.14,
      "date": "2020-12-29"
  },
  "2020-12-30": {
      "equity": 13981.95,
      "debt": 5.899,
      "gold": 48459.72592,
      "pb": 3.96,
      "pe": 38.45,
      "yield": 1.14,
      "date": "2020-12-30"
  },
  "2020-12-31": {
      "equity": 13981.75,
      "debt": 5.894,
      "gold": 48732.73955,
      "pb": 3.96,
      "pe": 38.45,
      "yield": 1.14,
      "date": "2020-12-31"
  },
  "2021-01-01": {
      "equity": 14018.5,
      "debt": 5.898,
      "gold": 48732.73955,
      "pb": 3.97,
      "pe": 38.55,
      "yield": 1.13,
      "date": "2021-01-01"
  },
  "2021-01-02": {
      "equity": 14018.5,
      "debt": 5.898,
      "gold": 48732.73955,
      "pb": 3.97,
      "pe": 38.55,
      "yield": 1.13,
      "date": "2021-01-02"
  },
  "2021-01-03": {
      "equity": 14018.5,
      "debt": 5.898,
      "gold": 48732.73955,
      "pb": 3.97,
      "pe": 38.55,
      "yield": 1.13,
      "date": "2021-01-03"
  },
  "2021-01-04": {
      "equity": 14132.9,
      "debt": 5.858,
      "gold": 49789.56948,
      "pb": 4.01,
      "pe": 38.87,
      "yield": 1.12,
      "date": "2021-01-04"
  },
  "2021-01-05": {
      "equity": 14199.5,
      "debt": 5.85,
      "gold": 50228.46611,
      "pb": 4.02,
      "pe": 39.05,
      "yield": 1.12,
      "date": "2021-01-05"
  },
  "2021-01-06": {
      "equity": 14146.25,
      "debt": 5.894,
      "gold": 50500.44622,
      "pb": 4.01,
      "pe": 38.9,
      "yield": 1.12,
      "date": "2021-01-06"
  },
  "2021-01-07": {
      "equity": 14137.35,
      "debt": 5.892,
      "gold": 49518.77458,
      "pb": 4.01,
      "pe": 38.88,
      "yield": 1.12,
      "date": "2021-01-07"
  },
  "2021-01-08": {
      "equity": 14347.25,
      "debt": 5.892,
      "gold": 48944.50343,
      "pb": 4.07,
      "pe": 39.45,
      "yield": 1.11,
      "date": "2021-01-08"
  },
  "2021-01-09": {
      "equity": 14347.25,
      "debt": 5.892,
      "gold": 48944.50343,
      "pb": 4.07,
      "pe": 39.45,
      "yield": 1.11,
      "date": "2021-01-09"
  },
  "2021-01-10": {
      "equity": 14347.25,
      "debt": 5.892,
      "gold": 48944.50343,
      "pb": 4.07,
      "pe": 39.45,
      "yield": 1.11,
      "date": "2021-01-10"
  },
  "2021-01-11": {
      "equity": 14484.75,
      "debt": 5.924,
      "gold": 47899.40563,
      "pb": 4.1,
      "pe": 39.72,
      "yield": 1.1,
      "date": "2021-01-11"
  },
  "2021-01-12": {
      "equity": 14563.45,
      "debt": 5.931,
      "gold": 48115.38828,
      "pb": 4.13,
      "pe": 39.94,
      "yield": 1.09,
      "date": "2021-01-12"
  },
  "2021-01-13": {
      "equity": 14564.85,
      "debt": 5.947,
      "gold": 47748.06963,
      "pb": 4.13,
      "pe": 39.94,
      "yield": 1.09,
      "date": "2021-01-13"
  },
  "2021-01-14": {
      "equity": 14595.6,
      "debt": 5.924,
      "gold": 47405.28757,
      "pb": 4.14,
      "pe": 40.03,
      "yield": 1.09,
      "date": "2021-01-14"
  },
  "2021-01-15": {
      "equity": 14433.7,
      "debt": 5.988,
      "gold": 47823.96162,
      "pb": 4.09,
      "pe": 39.34,
      "yield": 1.1,
      "date": "2021-01-15"
  },
  "2021-01-16": {
      "equity": 14433.7,
      "debt": 5.988,
      "gold": 47823.96162,
      "pb": 4.09,
      "pe": 39.34,
      "yield": 1.1,
      "date": "2021-01-16"
  },
  "2021-01-17": {
      "equity": 14433.7,
      "debt": 5.988,
      "gold": 47823.96162,
      "pb": 4.09,
      "pe": 39.34,
      "yield": 1.1,
      "date": "2021-01-17"
  },
  "2021-01-18": {
      "equity": 14281.3,
      "debt": 5.957,
      "gold": 47349.54408,
      "pb": 4.05,
      "pe": 38.92,
      "yield": 1.11,
      "date": "2021-01-18"
  },
  "2021-01-19": {
      "equity": 14521.15,
      "debt": 5.936,
      "gold": 47561.92173,
      "pb": 4.12,
      "pe": 39.22,
      "yield": 1.09,
      "date": "2021-01-19"
  },
  "2021-01-20": {
      "equity": 14644.7,
      "debt": 5.942,
      "gold": 47707.89256,
      "pb": 4.15,
      "pe": 39.55,
      "yield": 1.08,
      "date": "2021-01-20"
  },
  "2021-01-21": {
      "equity": 14590.35,
      "debt": 5.956,
      "gold": 48075.74031,
      "pb": 4.13,
      "pe": 39.41,
      "yield": 1.09,
      "date": "2021-01-21"
  },
  "2021-01-22": {
      "equity": 14371.9,
      "debt": 5.939,
      "gold": 47727.41318,
      "pb": 4.07,
      "pe": 38.86,
      "yield": 1.1,
      "date": "2021-01-22"
  },
  "2021-01-23": {
      "equity": 14371.9,
      "debt": 5.939,
      "gold": 47727.41318,
      "pb": 4.07,
      "pe": 38.86,
      "yield": 1.1,
      "date": "2021-01-23"
  },
  "2021-01-24": {
      "equity": 14371.9,
      "debt": 5.939,
      "gold": 47727.41318,
      "pb": 4.07,
      "pe": 38.86,
      "yield": 1.1,
      "date": "2021-01-24"
  },
  "2021-01-25": {
      "equity": 14238.9,
      "debt": 5.954,
      "gold": 47780.21835,
      "pb": 4.04,
      "pe": 38.42,
      "yield": 1.11,
      "date": "2021-01-25"
  },
  "2021-01-26": {
      "equity": 14238.9,
      "debt": 5.954,
      "gold": 47718.42184,
      "pb": 4.04,
      "pe": 38.42,
      "yield": 1.11,
      "date": "2021-01-26"
  },
  "2021-01-27": {
      "equity": 13967.5,
      "debt": 5.954,
      "gold": 47567.42447,
      "pb": 3.96,
      "pe": 37.52,
      "yield": 1.14,
      "date": "2021-01-27"
  },
  "2021-01-28": {
      "equity": 13817.55,
      "debt": 5.933,
      "gold": 47325.7165,
      "pb": 3.92,
      "pe": 36.96,
      "yield": 1.15,
      "date": "2021-01-28"
  },
  "2021-01-29": {
      "equity": 13634.6,
      "debt": 5.949,
      "gold": 47631.37269,
      "pb": 3.86,
      "pe": 36.57,
      "yield": 1.16,
      "date": "2021-01-29"
  },
  "2021-01-30": {
      "equity": 13634.6,
      "debt": 5.949,
      "gold": 47631.37269,
      "pb": 3.86,
      "pe": 36.57,
      "yield": 1.16,
      "date": "2021-01-30"
  },
  "2021-01-31": {
      "equity": 13634.6,
      "debt": 5.949,
      "gold": 47631.37269,
      "pb": 3.86,
      "pe": 36.57,
      "yield": 1.16,
      "date": "2021-01-31"
  },
  "2021-02-01": {
      "equity": 14281.2,
      "debt": 6.06,
      "gold": 47960.5919,
      "pb": 4.05,
      "pe": 38.26,
      "yield": 1.11,
      "date": "2021-02-01"
  },
  "2021-02-02": {
      "equity": 14647.85,
      "debt": 6.127,
      "gold": 47543.36761,
      "pb": 4.15,
      "pe": 38.79,
      "yield": 1.08,
      "date": "2021-02-02"
  },
  "2021-02-03": {
      "equity": 14789.95,
      "debt": 6.084,
      "gold": 47133.52969,
      "pb": 4.19,
      "pe": 39.16,
      "yield": 1.07,
      "date": "2021-02-03"
  },
  "2021-02-04": {
      "equity": 14895.65,
      "debt": 6.074,
      "gold": 46632.8683,
      "pb": 4.22,
      "pe": 40.81,
      "yield": 1.07,
      "date": "2021-02-04"
  },
  "2021-02-05": {
      "equity": 14924.25,
      "debt": 6.071,
      "gold": 46451.01677,
      "pb": 4.23,
      "pe": 41.46,
      "yield": 1.06,
      "date": "2021-02-05"
  },
  "2021-02-06": {
      "equity": 14924.25,
      "debt": 6.071,
      "gold": 46451.01677,
      "pb": 4.23,
      "pe": 41.46,
      "yield": 1.06,
      "date": "2021-02-06"
  },
  "2021-02-07": {
      "equity": 14924.25,
      "debt": 6.071,
      "gold": 46451.01677,
      "pb": 4.23,
      "pe": 41.46,
      "yield": 1.06,
      "date": "2021-02-07"
  },
  "2021-02-08": {
      "equity": 15115.8,
      "debt": 6.04,
      "gold": 46592.37024,
      "pb": 4.28,
      "pe": 42,
      "yield": 1.05,
      "date": "2021-02-08"
  },
  "2021-02-09": {
      "equity": 15109.3,
      "debt": 6.074,
      "gold": 47463.55668,
      "pb": 4.28,
      "pe": 41.97,
      "yield": 1.05,
      "date": "2021-02-09"
  },
  "2021-02-10": {
      "equity": 15106.5,
      "debt": 6.01,
      "gold": 47363.65015,
      "pb": 4.28,
      "pe": 41.81,
      "yield": 1.05,
      "date": "2021-02-10"
  },
  "2021-02-11": {
      "equity": 15173.3,
      "debt": 5.962,
      "gold": 47236.05355,
      "pb": 4.3,
      "pe": 41.65,
      "yield": 1.05,
      "date": "2021-02-11"
  },
  "2021-02-12": {
      "equity": 15163.3,
      "debt": 5.99,
      "gold": 46546.5599,
      "pb": 4.3,
      "pe": 41.58,
      "yield": 1.05,
      "date": "2021-02-12"
  },
  "2021-02-13": {
      "equity": 15163.3,
      "debt": 5.99,
      "gold": 46546.5599,
      "pb": 4.3,
      "pe": 41.58,
      "yield": 1.05,
      "date": "2021-02-13"
  },
  "2021-02-14": {
      "equity": 15163.3,
      "debt": 5.99,
      "gold": 46546.5599,
      "pb": 4.3,
      "pe": 41.58,
      "yield": 1.05,
      "date": "2021-02-14"
  },
  "2021-02-15": {
      "equity": 15314.7,
      "debt": 6.022,
      "gold": 46567.92889,
      "pb": 4.34,
      "pe": 41.59,
      "yield": 1.04,
      "date": "2021-02-15"
  },
  "2021-02-16": {
      "equity": 15313.45,
      "debt": 6.022,
      "gold": 46870.24463,
      "pb": 4.34,
      "pe": 41.78,
      "yield": 1.04,
      "date": "2021-02-16"
  },
  "2021-02-17": {
      "equity": 15208.9,
      "debt": 6.03,
      "gold": 45937.15233,
      "pb": 4.31,
      "pe": 41.49,
      "yield": 1.04,
      "date": "2021-02-17"
  },
  "2021-02-18": {
      "equity": 15118.95,
      "debt": 6.132,
      "gold": 45680.85857,
      "pb": 4.28,
      "pe": 41.26,
      "yield": 1.05,
      "date": "2021-02-18"
  },
  "2021-02-19": {
      "equity": 14981.75,
      "debt": 6.132,
      "gold": 45449.71869,
      "pb": 4.24,
      "pe": 40.88,
      "yield": 1.06,
      "date": "2021-02-19"
  },
  "2021-02-20": {
      "equity": 14981.75,
      "debt": 6.132,
      "gold": 45449.71869,
      "pb": 4.24,
      "pe": 40.88,
      "yield": 1.06,
      "date": "2021-02-20"
  },
  "2021-02-21": {
      "equity": 14981.75,
      "debt": 6.132,
      "gold": 45449.71869,
      "pb": 4.24,
      "pe": 40.88,
      "yield": 1.06,
      "date": "2021-02-21"
  },
  "2021-02-22": {
      "equity": 14675.7,
      "debt": 6.202,
      "gold": 45974.14064,
      "pb": 4.16,
      "pe": 40.05,
      "yield": 1.08,
      "date": "2021-02-22"
  },
  "2021-02-23": {
      "equity": 14707.8,
      "debt": 6.172,
      "gold": 46239.12591,
      "pb": 4.17,
      "pe": 40.14,
      "yield": 1.08,
      "date": "2021-02-23"
  },
  "2021-02-24": {
      "equity": 14982,
      "debt": 6.147,
      "gold": 46138.78904,
      "pb": 4.24,
      "pe": 40.88,
      "yield": 1.06,
      "date": "2021-02-24"
  },
  "2021-02-25": {
      "equity": 15097.35,
      "debt": 6.182,
      "gold": 46022.8258,
      "pb": 4.28,
      "pe": 41.2,
      "yield": 1.05,
      "date": "2021-02-25"
  },
  "2021-02-26": {
      "equity": 14529.15,
      "debt": 6.229,
      "gold": 46007.97192,
      "pb": 4.12,
      "pe": 39.65,
      "yield": 1.09,
      "date": "2021-02-26"
  },
  "2021-02-27": {
      "equity": 14529.15,
      "debt": 6.229,
      "gold": 46007.97192,
      "pb": 4.12,
      "pe": 39.65,
      "yield": 1.09,
      "date": "2021-02-27"
  },
  "2021-02-28": {
      "equity": 14529.15,
      "debt": 6.229,
      "gold": 46007.97192,
      "pb": 4.12,
      "pe": 39.65,
      "yield": 1.09,
      "date": "2021-02-28"
  },
  "2021-03-01": {
      "equity": 14761.55,
      "debt": 6.209,
      "gold": 45213.28066,
      "pb": 4.18,
      "pe": 40.28,
      "yield": 1.08,
      "date": "2021-03-01"
  },
  "2021-03-02": {
      "equity": 14919.1,
      "debt": 6.236,
      "gold": 44685.77576,
      "pb": 4.23,
      "pe": 40.71,
      "yield": 1.06,
      "date": "2021-03-02"
  },
  "2021-03-03": {
      "equity": 15245.6,
      "debt": 6.238,
      "gold": 44449.93386,
      "pb": 4.32,
      "pe": 41.6,
      "yield": 1.04,
      "date": "2021-03-03"
  },
  "2021-03-04": {
      "equity": 15080.75,
      "debt": 6.212,
      "gold": 43929.45555,
      "pb": 4.27,
      "pe": 41.15,
      "yield": 1.05,
      "date": "2021-03-04"
  },
  "2021-03-05": {
      "equity": 14938.1,
      "debt": 6.232,
      "gold": 43795.47435,
      "pb": 4.23,
      "pe": 40.76,
      "yield": 1.06,
      "date": "2021-03-05"
  },
  "2021-03-06": {
      "equity": 14938.1,
      "debt": 6.232,
      "gold": 43795.47435,
      "pb": 4.23,
      "pe": 40.76,
      "yield": 1.06,
      "date": "2021-03-06"
  },
  "2021-03-07": {
      "equity": 14938.1,
      "debt": 6.232,
      "gold": 43795.47435,
      "pb": 4.23,
      "pe": 40.76,
      "yield": 1.06,
      "date": "2021-03-07"
  },
  "2021-03-08": {
      "equity": 14956.2,
      "debt": 6.223,
      "gold": 43843.81382,
      "pb": 4.24,
      "pe": 40.74,
      "yield": null,
      "date": "2021-03-08"
  },
  "2021-03-09": {
      "equity": 15098.4,
      "debt": 6.206,
      "gold": 43759.51251,
      "pb": 4.28,
      "pe": 41.12,
      "yield": 1.05,
      "date": "2021-03-09"
  },
  "2021-03-10": {
      "equity": 15174.8,
      "debt": 6.247,
      "gold": 43924.44664,
      "pb": 4.3,
      "pe": 41.33,
      "yield": 1.05,
      "date": "2021-03-10"
  },
  "2021-03-11": {
      "equity": 15174.8,
      "debt": 6.247,
      "gold": 44546.46114,
      "pb": 4.3,
      "pe": 41.33,
      "yield": 1.05,
      "date": "2021-03-11"
  },
  "2021-03-12": {
      "equity": 15030.95,
      "debt": 6.232,
      "gold": 43695.47964,
      "pb": 4.26,
      "pe": 40.94,
      "yield": 1.06,
      "date": "2021-03-12"
  },
  "2021-03-13": {
      "equity": 15030.95,
      "debt": 6.232,
      "gold": 43695.47964,
      "pb": 4.26,
      "pe": 40.94,
      "yield": 1.06,
      "date": "2021-03-13"
  },
  "2021-03-14": {
      "equity": 15030.95,
      "debt": 6.232,
      "gold": 43695.47964,
      "pb": 4.26,
      "pe": 40.94,
      "yield": 1.06,
      "date": "2021-03-14"
  },
  "2021-03-15": {
      "equity": 14929.5,
      "debt": 6.201,
      "gold": 44210.09189,
      "pb": 4.23,
      "pe": 40.66,
      "yield": 1.06,
      "date": "2021-03-15"
  },
  "2021-03-16": {
      "equity": 14910.45,
      "debt": 6.18,
      "gold": 44302.2593,
      "pb": 4.23,
      "pe": 40.61,
      "yield": 1.07,
      "date": "2021-03-16"
  },
  "2021-03-17": {
      "equity": 14721.3,
      "debt": 6.185,
      "gold": 44478.65042,
      "pb": 4.17,
      "pe": 40.1,
      "yield": 1.08,
      "date": "2021-03-17"
  },
  "2021-03-18": {
      "equity": 14557.85,
      "debt": 6.202,
      "gold": 44449.1014,
      "pb": 4.13,
      "pe": 39.65,
      "yield": 1.09,
      "date": "2021-03-18"
  },
  "2021-03-19": {
      "equity": 14744,
      "debt": 6.193,
      "gold": 44395.83414,
      "pb": 4.18,
      "pe": 40.16,
      "yield": 1.08,
      "date": "2021-03-19"
  },
  "2021-03-20": {
      "equity": 14744,
      "debt": 6.193,
      "gold": 44395.83414,
      "pb": 4.18,
      "pe": 40.16,
      "yield": 1.08,
      "date": "2021-03-20"
  },
  "2021-03-21": {
      "equity": 14744,
      "debt": 6.193,
      "gold": 44395.83414,
      "pb": 4.18,
      "pe": 40.16,
      "yield": 1.08,
      "date": "2021-03-21"
  },
  "2021-03-22": {
      "equity": 14736.4,
      "debt": 6.18,
      "gold": 44247.17544,
      "pb": 4.18,
      "pe": 40.14,
      "yield": 1.08,
      "date": "2021-03-22"
  },
  "2021-03-23": {
      "equity": 14814.75,
      "debt": 6.144,
      "gold": 44488.22378,
      "pb": 4.2,
      "pe": 40.35,
      "yield": 1.07,
      "date": "2021-03-23"
  },
  "2021-03-24": {
      "equity": 14549.4,
      "debt": 6.152,
      "gold": 44411.36175,
      "pb": 4.12,
      "pe": 39.63,
      "yield": 1.09,
      "date": "2021-03-24"
  },
  "2021-03-25": {
      "equity": 14324.9,
      "debt": 6.131,
      "gold": 44326.67243,
      "pb": 4.06,
      "pe": 39.02,
      "yield": 1.11,
      "date": "2021-03-25"
  },
  "2021-03-26": {
      "equity": 14507.3,
      "debt": 6.123,
      "gold": 44258.54777,
      "pb": 4.11,
      "pe": 39.51,
      "yield": 1.09,
      "date": "2021-03-26"
  },
  "2021-03-27": {
      "equity": 14507.3,
      "debt": 6.123,
      "gold": 44258.54777,
      "pb": 4.11,
      "pe": 39.51,
      "yield": 1.09,
      "date": "2021-03-27"
  },
  "2021-03-28": {
      "equity": 14507.3,
      "debt": 6.123,
      "gold": 44258.54777,
      "pb": 4.11,
      "pe": 39.51,
      "yield": 1.09,
      "date": "2021-03-28"
  },
  "2021-03-29": {
      "equity": 14507.3,
      "debt": 6.123,
      "gold": 44158.6483,
      "pb": 4.11,
      "pe": 39.51,
      "yield": 1.09,
      "date": "2021-03-29"
  },
  "2021-03-30": {
      "equity": 14845.1,
      "debt": 6.143,
      "gold": 43956.31669,
      "pb": 4.21,
      "pe": 40.43,
      "yield": 1.07,
      "date": "2021-03-30"
  },
  "2021-03-31": {
      "equity": 14690.7,
      "debt": 6.177,
      "gold": 43493.79354,
      "pb": 4.2,
      "pe": 33.2,
      "yield": 0.96,
      "date": "2021-03-31"
  },
  "2021-04-01": {
      "equity": 14867.35,
      "debt": 6.177,
      "gold": 44293.92053,
      "pb": 4.25,
      "pe": 33.6,
      "yield": 0.95,
      "date": "2021-04-01"
  },
  "2021-04-02": {
      "equity": 14867.35,
      "debt": 6.177,
      "gold": 44293.92053,
      "pb": 4.25,
      "pe": 33.6,
      "yield": 0.95,
      "date": "2021-04-02"
  },
  "2021-04-03": {
      "equity": 14867.35,
      "debt": 6.177,
      "gold": 44293.92053,
      "pb": 4.25,
      "pe": 33.6,
      "yield": 0.95,
      "date": "2021-04-03"
  },
  "2021-04-04": {
      "equity": 14867.35,
      "debt": 6.177,
      "gold": 44293.92053,
      "pb": 4.25,
      "pe": 33.6,
      "yield": 0.95,
      "date": "2021-04-04"
  },
  "2021-04-05": {
      "equity": 14637.8,
      "debt": 6.123,
      "gold": 44293.92053,
      "pb": 4.19,
      "pe": 33.08,
      "yield": 0.97,
      "date": "2021-04-05"
  },
  "2021-04-06": {
      "equity": 14683.5,
      "debt": 6.122,
      "gold": 44879.86737,
      "pb": 4.2,
      "pe": 33.18,
      "yield": 0.96,
      "date": "2021-04-06"
  },
  "2021-04-07": {
      "equity": 14819.05,
      "debt": 6.082,
      "gold": 45532.74661,
      "pb": 4.24,
      "pe": 33.49,
      "yield": 0.95,
      "date": "2021-04-07"
  },
  "2021-04-08": {
      "equity": 14873.8,
      "debt": 6.031,
      "gold": 45871.6838,
      "pb": 4.25,
      "pe": 33.61,
      "yield": 0.96,
      "date": "2021-04-08"
  },
  "2021-04-09": {
      "equity": 14834.85,
      "debt": 6.017,
      "gold": 46078.19186,
      "pb": 4.24,
      "pe": 33.53,
      "yield": 0.97,
      "date": "2021-04-09"
  },
  "2021-04-10": {
      "equity": 14834.85,
      "debt": 6.017,
      "gold": 46078.19186,
      "pb": 4.24,
      "pe": 33.53,
      "yield": 0.97,
      "date": "2021-04-10"
  },
  "2021-04-11": {
      "equity": 14834.85,
      "debt": 6.017,
      "gold": 46078.19186,
      "pb": 4.24,
      "pe": 33.53,
      "yield": 0.97,
      "date": "2021-04-11"
  },
  "2021-04-12": {
      "equity": 14310.8,
      "debt": 6.011,
      "gold": 46085.4195,
      "pb": 4.09,
      "pe": 32.34,
      "yield": 1,
      "date": "2021-04-12"
  },
  "2021-04-13": {
      "equity": 14504.8,
      "debt": 6.011,
      "gold": 45722.00568,
      "pb": 4.15,
      "pe": 32.72,
      "yield": 0.99,
      "date": "2021-04-13"
  },
  "2021-04-14": {
      "equity": 14504.8,
      "debt": 6.011,
      "gold": 46125.70239,
      "pb": 4.15,
      "pe": 32.72,
      "yield": 0.99,
      "date": "2021-04-14"
  },
  "2021-04-15": {
      "equity": 14581.45,
      "debt": 6.126,
      "gold": 46088.51302,
      "pb": 4.17,
      "pe": 32.78,
      "yield": 0.98,
      "date": "2021-04-15"
  },
  "2021-04-16": {
      "equity": 14617.85,
      "debt": 6.088,
      "gold": 46464.68544,
      "pb": 4.18,
      "pe": 32.84,
      "yield": 0.98,
      "date": "2021-04-16"
  },
  "2021-04-17": {
      "equity": 14617.85,
      "debt": 6.088,
      "gold": 46464.68544,
      "pb": 4.18,
      "pe": 32.84,
      "yield": 0.98,
      "date": "2021-04-17"
  },
  "2021-04-18": {
      "equity": 14617.85,
      "debt": 6.088,
      "gold": 46464.68544,
      "pb": 4.18,
      "pe": 32.84,
      "yield": 0.98,
      "date": "2021-04-18"
  },
  "2021-04-19": {
      "equity": 14359.45,
      "debt": 6.081,
      "gold": 47226.89289,
      "pb": 4.11,
      "pe": 32.12,
      "yield": 1,
      "date": "2021-04-19"
  },
  "2021-04-20": {
      "equity": 14296.4,
      "debt": 6.074,
      "gold": 46962.81769,
      "pb": 4.09,
      "pe": 31.97,
      "yield": 1,
      "date": "2021-04-20"
  },
  "2021-04-21": {
      "equity": 14296.4,
      "debt": 6.074,
      "gold": 47390.27143,
      "pb": 4.09,
      "pe": 31.97,
      "yield": 1,
      "date": "2021-04-21"
  },
  "2021-04-22": {
      "equity": 14406.15,
      "debt": 6.05,
      "gold": 47317.28249,
      "pb": 4.12,
      "pe": 32.22,
      "yield": 0.99,
      "date": "2021-04-22"
  },
  "2021-04-23": {
      "equity": 14341.35,
      "debt": 6.035,
      "gold": 47208.0072,
      "pb": 4.11,
      "pe": 32.07,
      "yield": 1,
      "date": "2021-04-23"
  },
  "2021-04-24": {
      "equity": 14341.35,
      "debt": 6.035,
      "gold": 47208.0072,
      "pb": 4.11,
      "pe": 32.07,
      "yield": 1,
      "date": "2021-04-24"
  },
  "2021-04-25": {
      "equity": 14341.35,
      "debt": 6.035,
      "gold": 47208.0072,
      "pb": 4.11,
      "pe": 32.07,
      "yield": 1,
      "date": "2021-04-25"
  },
  "2021-04-26": {
      "equity": 14485,
      "debt": 6.038,
      "gold": 46902.92245,
      "pb": 4.15,
      "pe": 32.39,
      "yield": 0.99,
      "date": "2021-04-26"
  },
  "2021-04-27": {
      "equity": 14653.05,
      "debt": 6.055,
      "gold": 46857.96928,
      "pb": 4.2,
      "pe": 32.28,
      "yield": 0.98,
      "date": "2021-04-27"
  },
  "2021-04-28": {
      "equity": 14864.55,
      "debt": 6.053,
      "gold": 46349.30069,
      "pb": 4.26,
      "pe": 32.72,
      "yield": 0.96,
      "date": "2021-04-28"
  },
  "2021-04-29": {
      "equity": 14894.9,
      "debt": 6.059,
      "gold": 46373.70677,
      "pb": 4.26,
      "pe": 32.79,
      "yield": 0.99,
      "date": "2021-04-29"
  },
  "2021-04-30": {
      "equity": 14631.1,
      "debt": 6.03,
      "gold": 46169.36454,
      "pb": 4.19,
      "pe": 32.21,
      "yield": 1,
      "date": "2021-04-30"
  },
  "2021-05-01": {
      "equity": 14631.1,
      "debt": 6.03,
      "gold": 46169.36454,
      "pb": 4.19,
      "pe": 32.21,
      "yield": 1,
      "date": "2021-05-01"
  },
  "2021-05-02": {
      "equity": 14631.1,
      "debt": 6.03,
      "gold": 46169.36454,
      "pb": 4.19,
      "pe": 32.21,
      "yield": 1,
      "date": "2021-05-02"
  },
  "2021-05-03": {
      "equity": 14634.15,
      "debt": 6.001,
      "gold": 46169.36454,
      "pb": 4.19,
      "pe": 31.54,
      "yield": 1,
      "date": "2021-05-03"
  },
  "2021-05-04": {
      "equity": 14496.5,
      "debt": 6.015,
      "gold": 46455.16499,
      "pb": 4.15,
      "pe": 30.47,
      "yield": 1.01,
      "date": "2021-05-04"
  },
  "2021-05-05": {
      "equity": 14617.85,
      "debt": 5.978,
      "gold": 46267.62024,
      "pb": 4.19,
      "pe": 30.65,
      "yield": 1,
      "date": "2021-05-05"
  },
  "2021-05-06": {
      "equity": 14724.8,
      "debt": 5.974,
      "gold": 46547.89679,
      "pb": 4.22,
      "pe": 30.82,
      "yield": 1,
      "date": "2021-05-06"
  },
  "2021-05-07": {
      "equity": 14823.15,
      "debt": 6.015,
      "gold": 47058.34671,
      "pb": 4.24,
      "pe": 30.23,
      "yield": 0.99,
      "date": "2021-05-07"
  },
  "2021-05-08": {
      "equity": 14823.15,
      "debt": 6.015,
      "gold": 47058.34671,
      "pb": 4.24,
      "pe": 30.23,
      "yield": 0.99,
      "date": "2021-05-08"
  },
  "2021-05-09": {
      "equity": 14823.15,
      "debt": 6.015,
      "gold": 47058.34671,
      "pb": 4.24,
      "pe": 30.23,
      "yield": 0.99,
      "date": "2021-05-09"
  },
  "2021-05-10": {
      "equity": 14942.35,
      "debt": 6.014,
      "gold": 47497.49378,
      "pb": 4.28,
      "pe": 30.43,
      "yield": 0.98,
      "date": "2021-05-10"
  },
  "2021-05-11": {
      "equity": 14850.75,
      "debt": 6.008,
      "gold": 47558.07333,
      "pb": 4.25,
      "pe": 30.14,
      "yield": 0.99,
      "date": "2021-05-11"
  },
  "2021-05-12": {
      "equity": 14696.5,
      "debt": 6.01,
      "gold": 47629.81358,
      "pb": 4.21,
      "pe": 29.83,
      "yield": 1,
      "date": "2021-05-12"
  },
  "2021-05-13": {
      "equity": 14696.5,
      "debt": 6.01,
      "gold": 47124.63359,
      "pb": 4.21,
      "pe": 29.83,
      "yield": 1,
      "date": "2021-05-13"
  },
  "2021-05-14": {
      "equity": 14677.8,
      "debt": 5.986,
      "gold": 47396.64897,
      "pb": 4.2,
      "pe": 29.79,
      "yield": 1,
      "date": "2021-05-14"
  },
  "2021-05-15": {
      "equity": 14677.8,
      "debt": 5.986,
      "gold": 47396.64897,
      "pb": 4.2,
      "pe": 29.79,
      "yield": 1,
      "date": "2021-05-15"
  },
  "2021-05-16": {
      "equity": 14677.8,
      "debt": 5.986,
      "gold": 47396.64897,
      "pb": 4.2,
      "pe": 29.79,
      "yield": 1,
      "date": "2021-05-16"
  },
  "2021-05-17": {
      "equity": 14923.15,
      "debt": 5.972,
      "gold": 47838.20879,
      "pb": 4.27,
      "pe": 30.2,
      "yield": 0.98,
      "date": "2021-05-17"
  },
  "2021-05-18": {
      "equity": 15108.1,
      "debt": 5.976,
      "gold": 48116.20311,
      "pb": 4.33,
      "pe": 30.53,
      "yield": 0.97,
      "date": "2021-05-18"
  },
  "2021-05-19": {
      "equity": 15030.15,
      "debt": 5.975,
      "gold": 48022.37429,
      "pb": 4.3,
      "pe": 29.98,
      "yield": 0.98,
      "date": "2021-05-19"
  },
  "2021-05-20": {
      "equity": 14906.05,
      "debt": 5.968,
      "gold": 48162.16159,
      "pb": 4.27,
      "pe": 29.59,
      "yield": 0.99,
      "date": "2021-05-20"
  },
  "2021-05-21": {
      "equity": 15175.3,
      "debt": 5.977,
      "gold": 48259.03808,
      "pb": 4.34,
      "pe": 29.48,
      "yield": 0.97,
      "date": "2021-05-21"
  },
  "2021-05-22": {
      "equity": 15175.3,
      "debt": 5.977,
      "gold": 48259.03808,
      "pb": 4.34,
      "pe": 29.48,
      "yield": 0.97,
      "date": "2021-05-22"
  },
  "2021-05-23": {
      "equity": 15175.3,
      "debt": 5.977,
      "gold": 48259.03808,
      "pb": 4.34,
      "pe": 29.48,
      "yield": 0.97,
      "date": "2021-05-23"
  },
  "2021-05-24": {
      "equity": 15197.7,
      "debt": 5.972,
      "gold": 48257.74352,
      "pb": 4.35,
      "pe": 29.52,
      "yield": 0.97,
      "date": "2021-05-24"
  },
  "2021-05-25": {
      "equity": 15208.45,
      "debt": 5.972,
      "gold": 48336.34808,
      "pb": 4.35,
      "pe": 29.25,
      "yield": 1,
      "date": "2021-05-25"
  },
  "2021-05-26": {
      "equity": 15301.45,
      "debt": 5.972,
      "gold": 48884.27662,
      "pb": 4.38,
      "pe": 29.41,
      "yield": 0.99,
      "date": "2021-05-26"
  },
  "2021-05-27": {
      "equity": 15337.85,
      "debt": 5.987,
      "gold": 48576.23591,
      "pb": 4.39,
      "pe": 29.48,
      "yield": 0.99,
      "date": "2021-05-27"
  },
  "2021-05-28": {
      "equity": 15435.65,
      "debt": 6.003,
      "gold": 48349.98148,
      "pb": 4.42,
      "pe": 28.98,
      "yield": 0.98,
      "date": "2021-05-28"
  },
  "2021-05-29": {
      "equity": 15435.65,
      "debt": 6.003,
      "gold": 48349.98148,
      "pb": 4.42,
      "pe": 28.98,
      "yield": 0.98,
      "date": "2021-05-29"
  },
  "2021-05-30": {
      "equity": 15435.65,
      "debt": 6.003,
      "gold": 48349.98148,
      "pb": 4.42,
      "pe": 28.98,
      "yield": 0.98,
      "date": "2021-05-30"
  },
  "2021-05-31": {
      "equity": 15582.8,
      "debt": 6.022,
      "gold": 48349.98148,
      "pb": 4.39,
      "pe": 29.22,
      "yield": 1.06,
      "date": "2021-05-31"
  },
  "2021-06-01": {
      "equity": 15574.85,
      "debt": 6.025,
      "gold": 49024.71649,
      "pb": 4.39,
      "pe": 28.87,
      "yield": 1.07,
      "date": "2021-06-01"
  },
  "2021-06-02": {
      "equity": 15576.2,
      "debt": 6.005,
      "gold": 48804.40219,
      "pb": 4.39,
      "pe": 28.88,
      "yield": 1.06,
      "date": "2021-06-02"
  },
  "2021-06-03": {
      "equity": 15690.35,
      "debt": 5.997,
      "gold": 48758.59539,
      "pb": 4.43,
      "pe": 29.1,
      "yield": 1.07,
      "date": "2021-06-03"
  },
  "2021-06-04": {
      "equity": 15670.25,
      "debt": 6.028,
      "gold": 48062.32914,
      "pb": 4.42,
      "pe": 29.06,
      "yield": 1.07,
      "date": "2021-06-04"
  },
  "2021-06-05": {
      "equity": 15670.25,
      "debt": 6.028,
      "gold": 48062.32914,
      "pb": 4.42,
      "pe": 29.06,
      "yield": 1.07,
      "date": "2021-06-05"
  },
  "2021-06-06": {
      "equity": 15670.25,
      "debt": 6.028,
      "gold": 48062.32914,
      "pb": 4.42,
      "pe": 29.06,
      "yield": 1.07,
      "date": "2021-06-06"
  },
  "2021-06-07": {
      "equity": 15751.65,
      "debt": 6.022,
      "gold": 48338.33754,
      "pb": 4.44,
      "pe": 29.21,
      "yield": 1.07,
      "date": "2021-06-07"
  },
  "2021-06-08": {
      "equity": 15740.1,
      "debt": 6.006,
      "gold": 48690.28025,
      "pb": 4.44,
      "pe": 29.19,
      "yield": 1.07,
      "date": "2021-06-08"
  },
  "2021-06-09": {
      "equity": 15635.35,
      "debt": 6.016,
      "gold": 48671.84254,
      "pb": 4.41,
      "pe": 28.99,
      "yield": 1.07,
      "date": "2021-06-09"
  },
  "2021-06-10": {
      "equity": 15737.75,
      "debt": 6.019,
      "gold": 48506.60153,
      "pb": 4.44,
      "pe": 29.18,
      "yield": 1.15,
      "date": "2021-06-10"
  },
  "2021-06-11": {
      "equity": 15799.35,
      "debt": 6.006,
      "gold": 48888.58357,
      "pb": 4.46,
      "pe": 29.3,
      "yield": 1.18,
      "date": "2021-06-11"
  },
  "2021-06-12": {
      "equity": 15799.35,
      "debt": 6.006,
      "gold": 48888.58357,
      "pb": 4.46,
      "pe": 29.3,
      "yield": 1.18,
      "date": "2021-06-12"
  },
  "2021-06-13": {
      "equity": 15799.35,
      "debt": 6.006,
      "gold": 48888.58357,
      "pb": 4.46,
      "pe": 29.3,
      "yield": 1.18,
      "date": "2021-06-13"
  },
  "2021-06-14": {
      "equity": 15811.85,
      "debt": 6.004,
      "gold": 48004.04593,
      "pb": 4.46,
      "pe": 29.32,
      "yield": 1.2,
      "date": "2021-06-14"
  },
  "2021-06-15": {
      "equity": 15869.25,
      "debt": 6.04,
      "gold": 48229.85943,
      "pb": 4.48,
      "pe": 29.43,
      "yield": 1.2,
      "date": "2021-06-15"
  },
  "2021-06-16": {
      "equity": 15767.55,
      "debt": 6.045,
      "gold": 48048.6252,
      "pb": 4.45,
      "pe": 29.24,
      "yield": 1.2,
      "date": "2021-06-16"
  },
  "2021-06-17": {
      "equity": 15691.4,
      "debt": 6.019,
      "gold": 47368.27457,
      "pb": 4.43,
      "pe": 29.1,
      "yield": 1.24,
      "date": "2021-06-17"
  },
  "2021-06-18": {
      "equity": 15683.35,
      "debt": 6.006,
      "gold": 46869.35572,
      "pb": 4.32,
      "pe": 29.08,
      "yield": 1.24,
      "date": "2021-06-18"
  },
  "2021-06-19": {
      "equity": 15683.35,
      "debt": 6.006,
      "gold": 46869.35572,
      "pb": 4.32,
      "pe": 29.08,
      "yield": 1.24,
      "date": "2021-06-19"
  },
  "2021-06-20": {
      "equity": 15683.35,
      "debt": 6.006,
      "gold": 46869.35572,
      "pb": 4.32,
      "pe": 29.08,
      "yield": 1.24,
      "date": "2021-06-20"
  },
  "2021-06-21": {
      "equity": 15746.5,
      "debt": 6.029,
      "gold": 46635.94067,
      "pb": 4.31,
      "pe": 29.19,
      "yield": 1.22,
      "date": "2021-06-21"
  },
  "2021-06-22": {
      "equity": 15772.75,
      "debt": 6.025,
      "gold": 46652.40304,
      "pb": 4.32,
      "pe": 29.05,
      "yield": 1.21,
      "date": "2021-06-22"
  },
  "2021-06-23": {
      "equity": 15686.95,
      "debt": 6.018,
      "gold": 46651.79986,
      "pb": 4.3,
      "pe": 28.89,
      "yield": 1.22,
      "date": "2021-06-23"
  },
  "2021-06-24": {
      "equity": 15790.45,
      "debt": 6.012,
      "gold": 46595.90822,
      "pb": 4.32,
      "pe": 29.08,
      "yield": 1.22,
      "date": "2021-06-24"
  },
  "2021-06-25": {
      "equity": 15860.35,
      "debt": 6.028,
      "gold": 46647.30948,
      "pb": 4.34,
      "pe": 29.21,
      "yield": 1.21,
      "date": "2021-06-25"
  },
  "2021-06-26": {
      "equity": 15860.35,
      "debt": 6.028,
      "gold": 46647.30948,
      "pb": 4.34,
      "pe": 29.21,
      "yield": 1.21,
      "date": "2021-06-26"
  },
  "2021-06-27": {
      "equity": 15860.35,
      "debt": 6.028,
      "gold": 46647.30948,
      "pb": 4.34,
      "pe": 29.21,
      "yield": 1.21,
      "date": "2021-06-27"
  },
  "2021-06-28": {
      "equity": 15814.7,
      "debt": 6.056,
      "gold": 46493.61717,
      "pb": 4.33,
      "pe": 29.12,
      "yield": 1.22,
      "date": "2021-06-28"
  },
  "2021-06-29": {
      "equity": 15748.45,
      "debt": 6.036,
      "gold": 46342.80322,
      "pb": 4.31,
      "pe": 29,
      "yield": 1.26,
      "date": "2021-06-29"
  },
  "2021-06-30": {
      "equity": 15721.5,
      "debt": 6.051,
      "gold": 46104.77433,
      "pb": 4.24,
      "pe": 28.33,
      "yield": 1.26,
      "date": "2021-06-30"
  },
  "2021-07-01": {
      "equity": 15680,
      "debt": 6.039,
      "gold": 46658.02571,
      "pb": 4.23,
      "pe": 28.26,
      "yield": 1.27,
      "date": "2021-07-01"
  },
  "2021-07-02": {
      "equity": 15722.2,
      "debt": 6.065,
      "gold": 46881.33477,
      "pb": 4.24,
      "pe": 28.33,
      "yield": 1.23,
      "date": "2021-07-02"
  },
  "2021-07-03": {
      "equity": 15722.2,
      "debt": 6.065,
      "gold": 46881.33477,
      "pb": 4.24,
      "pe": 28.33,
      "yield": 1.23,
      "date": "2021-07-03"
  },
  "2021-07-04": {
      "equity": 15722.2,
      "debt": 6.065,
      "gold": 46881.33477,
      "pb": 4.24,
      "pe": 28.33,
      "yield": 1.23,
      "date": "2021-07-04"
  },
  "2021-07-05": {
      "equity": 15834.35,
      "debt": 6.088,
      "gold": 46932.56318,
      "pb": 4.27,
      "pe": 28.53,
      "yield": 1.23,
      "date": "2021-07-05"
  },
  "2021-07-06": {
      "equity": 15818.25,
      "debt": 6.175,
      "gold": 47675.49339,
      "pb": 4.26,
      "pe": 28.5,
      "yield": 1.1,
      "date": "2021-07-06"
  },
  "2021-07-07": {
      "equity": 15879.65,
      "debt": 6.164,
      "gold": 47608.53631,
      "pb": 4.28,
      "pe": 28.62,
      "yield": 1.1,
      "date": "2021-07-07"
  },
  "2021-07-08": {
      "equity": 15727.9,
      "debt": 6.124,
      "gold": 47739.59329,
      "pb": 4.24,
      "pe": 28.34,
      "yield": 1.14,
      "date": "2021-07-08"
  },
  "2021-07-09": {
      "equity": 15689.8,
      "debt": 6.186,
      "gold": 47416.51528,
      "pb": 4.23,
      "pe": 28.27,
      "yield": 1.09,
      "date": "2021-07-09"
  },
  "2021-07-10": {
      "equity": 15689.8,
      "debt": 6.186,
      "gold": 47416.51528,
      "pb": 4.23,
      "pe": 28.27,
      "yield": 1.09,
      "date": "2021-07-10"
  },
  "2021-07-11": {
      "equity": 15689.8,
      "debt": 6.186,
      "gold": 47416.51528,
      "pb": 4.23,
      "pe": 28.27,
      "yield": 1.09,
      "date": "2021-07-11"
  },
  "2021-07-12": {
      "equity": 15692.6,
      "debt": 6.22,
      "gold": 47396.79712,
      "pb": 4.23,
      "pe": 28.21,
      "yield": 1.09,
      "date": "2021-07-12"
  },
  "2021-07-13": {
      "equity": 15812.35,
      "debt": 6.103,
      "gold": 47547.2936,
      "pb": 4.26,
      "pe": 28.43,
      "yield": 1.08,
      "date": "2021-07-13"
  },
  "2021-07-14": {
      "equity": 15853.95,
      "debt": 6.109,
      "gold": 47673.05596,
      "pb": 4.27,
      "pe": 28.5,
      "yield": 1.09,
      "date": "2021-07-14"
  },
  "2021-07-15": {
      "equity": 15924.2,
      "debt": 6.117,
      "gold": 48174.69444,
      "pb": 4.29,
      "pe": 28.63,
      "yield": 1.1,
      "date": "2021-07-15"
  },
  "2021-07-16": {
      "equity": 15923.4,
      "debt": 6.13,
      "gold": 47940.33404,
      "pb": 4.29,
      "pe": 28.53,
      "yield": 1.09,
      "date": "2021-07-16"
  },
  "2021-07-17": {
      "equity": 15923.4,
      "debt": 6.13,
      "gold": 47940.33404,
      "pb": 4.29,
      "pe": 28.53,
      "yield": 1.09,
      "date": "2021-07-17"
  },
  "2021-07-18": {
      "equity": 15923.4,
      "debt": 6.13,
      "gold": 47940.33404,
      "pb": 4.29,
      "pe": 28.53,
      "yield": 1.09,
      "date": "2021-07-18"
  },
  "2021-07-19": {
      "equity": 15752.4,
      "debt": 6.127,
      "gold": 47702.68611,
      "pb": 4.25,
      "pe": 28.23,
      "yield": 1.11,
      "date": "2021-07-19"
  },
  "2021-07-20": {
      "equity": 15632.1,
      "debt": 6.119,
      "gold": 47726.26325,
      "pb": 4.21,
      "pe": 27.89,
      "yield": 1.11,
      "date": "2021-07-20"
  },
  "2021-07-21": {
      "equity": 15632.1,
      "debt": 6.119,
      "gold": 47488.68234,
      "pb": 4.21,
      "pe": 27.89,
      "yield": 1.11,
      "date": "2021-07-21"
  },
  "2021-07-22": {
      "equity": 15824.05,
      "debt": 6.138,
      "gold": 47203.60147,
      "pb": 4.26,
      "pe": 28.22,
      "yield": 1.11,
      "date": "2021-07-22"
  },
  "2021-07-23": {
      "equity": 15856.05,
      "debt": 6.165,
      "gold": 47351.02559,
      "pb": 4.16,
      "pe": 28.28,
      "yield": 1.13,
      "date": "2021-07-23"
  },
  "2021-07-24": {
      "equity": 15856.05,
      "debt": 6.165,
      "gold": 47351.02559,
      "pb": 4.16,
      "pe": 28.28,
      "yield": 1.13,
      "date": "2021-07-24"
  },
  "2021-07-25": {
      "equity": 15856.05,
      "debt": 6.165,
      "gold": 47351.02559,
      "pb": 4.16,
      "pe": 28.28,
      "yield": 1.13,
      "date": "2021-07-25"
  },
  "2021-07-26": {
      "equity": 15824.45,
      "debt": 6.169,
      "gold": 47411.99668,
      "pb": 4.13,
      "pe": 28.13,
      "yield": 1.13,
      "date": "2021-07-26"
  },
  "2021-07-27": {
      "equity": 15746.45,
      "debt": 6.178,
      "gold": 47234.94242,
      "pb": 4.11,
      "pe": 27.45,
      "yield": 1.14,
      "date": "2021-07-27"
  },
  "2021-07-28": {
      "equity": 15709.4,
      "debt": 6.188,
      "gold": 47278.31531,
      "pb": 4.1,
      "pe": 26.98,
      "yield": 1.18,
      "date": "2021-07-28"
  },
  "2021-07-29": {
      "equity": 15778.45,
      "debt": 6.198,
      "gold": 47659.52133,
      "pb": 4.12,
      "pe": 27.08,
      "yield": 1.19,
      "date": "2021-07-29"
  },
  "2021-07-30": {
      "equity": 15763.05,
      "debt": 6.204,
      "gold": 47948.65165,
      "pb": 4.12,
      "pe": 27.01,
      "yield": 1.18,
      "date": "2021-07-30"
  },
  "2021-07-31": {
      "equity": 15763.05,
      "debt": 6.204,
      "gold": 47948.65165,
      "pb": 4.12,
      "pe": 27.01,
      "yield": 1.18,
      "date": "2021-07-31"
  },
  "2021-08-01": {
      "equity": 15763.05,
      "debt": 6.204,
      "gold": 47948.65165,
      "pb": 4.12,
      "pe": 27.01,
      "yield": 1.18,
      "date": "2021-08-01"
  },
  "2021-08-02": {
      "equity": 15885.15,
      "debt": 6.197,
      "gold": 47394.34911,
      "pb": 4.15,
      "pe": 27.21,
      "yield": 1.17,
      "date": "2021-08-02"
  },
  "2021-08-03": {
      "equity": 16130.75,
      "debt": 6.2,
      "gold": 47390.71941,
      "pb": 4.21,
      "pe": 27.31,
      "yield": 1.15,
      "date": "2021-08-03"
  },
  "2021-08-04": {
      "equity": 16258.8,
      "debt": 6.199,
      "gold": 47433.64433,
      "pb": 4.25,
      "pe": 27.39,
      "yield": 1.14,
      "date": "2021-08-04"
  },
  "2021-08-05": {
      "equity": 16294.6,
      "debt": 6.207,
      "gold": 47341.19473,
      "pb": 4.25,
      "pe": 26.72,
      "yield": 1.14,
      "date": "2021-08-05"
  },
  "2021-08-06": {
      "equity": 16238.2,
      "debt": 6.234,
      "gold": 47100.84834,
      "pb": 4.24,
      "pe": 26.51,
      "yield": 1.13,
      "date": "2021-08-06"
  },
  "2021-08-07": {
      "equity": 16238.2,
      "debt": 6.234,
      "gold": 47100.84834,
      "pb": 4.24,
      "pe": 26.51,
      "yield": 1.13,
      "date": "2021-08-07"
  },
  "2021-08-08": {
      "equity": 16238.2,
      "debt": 6.234,
      "gold": 47100.84834,
      "pb": 4.24,
      "pe": 26.51,
      "yield": 1.13,
      "date": "2021-08-08"
  },
  "2021-08-09": {
      "equity": 16258.25,
      "debt": 6.221,
      "gold": 45742.67271,
      "pb": 4.25,
      "pe": 26.53,
      "yield": 1.13,
      "date": "2021-08-09"
  },
  "2021-08-10": {
      "equity": 16280.1,
      "debt": 6.233,
      "gold": 45409.57336,
      "pb": 4.24,
      "pe": 26.35,
      "yield": 1.13,
      "date": "2021-08-10"
  },
  "2021-08-11": {
      "equity": 16282.25,
      "debt": 6.236,
      "gold": 45417.61936,
      "pb": 4.24,
      "pe": 26.34,
      "yield": 1.13,
      "date": "2021-08-11"
  },
  "2021-08-12": {
      "equity": 16364.4,
      "debt": 6.226,
      "gold": 45997.69661,
      "pb": 4.26,
      "pe": 26.25,
      "yield": 1.14,
      "date": "2021-08-12"
  },
  "2021-08-13": {
      "equity": 16529.1,
      "debt": 6.241,
      "gold": 45978.70156,
      "pb": 4.3,
      "pe": 26.51,
      "yield": 1.1,
      "date": "2021-08-13"
  },
  "2021-08-14": {
      "equity": 16529.1,
      "debt": 6.241,
      "gold": 45978.70156,
      "pb": 4.3,
      "pe": 26.51,
      "yield": 1.1,
      "date": "2021-08-14"
  },
  "2021-08-15": {
      "equity": 16529.1,
      "debt": 6.241,
      "gold": 45978.70156,
      "pb": 4.3,
      "pe": 26.51,
      "yield": 1.1,
      "date": "2021-08-15"
  },
  "2021-08-16": {
      "equity": 16563.05,
      "debt": 6.241,
      "gold": 46452.18434,
      "pb": 4.31,
      "pe": 25.62,
      "yield": 1.1,
      "date": "2021-08-16"
  },
  "2021-08-17": {
      "equity": 16614.6,
      "debt": 6.234,
      "gold": 47074.29761,
      "pb": 4.21,
      "pe": 25.46,
      "yield": 1.1,
      "date": "2021-08-17"
  },
  "2021-08-18": {
      "equity": 16568.85,
      "debt": 6.228,
      "gold": 46854.09266,
      "pb": 4.19,
      "pe": 25.39,
      "yield": 1.1,
      "date": "2021-08-18"
  },
  "2021-08-19": {
      "equity": 16568.85,
      "debt": 6.228,
      "gold": 46864.59726,
      "pb": 4.19,
      "pe": 25.39,
      "yield": 1.1,
      "date": "2021-08-19"
  },
  "2021-08-20": {
      "equity": 16450.5,
      "debt": 6.234,
      "gold": 46722.91928,
      "pb": 4.16,
      "pe": 25.21,
      "yield": 1.11,
      "date": "2021-08-20"
  },
  "2021-08-21": {
      "equity": 16450.5,
      "debt": 6.234,
      "gold": 46722.91928,
      "pb": 4.16,
      "pe": 25.21,
      "yield": 1.11,
      "date": "2021-08-21"
  },
  "2021-08-22": {
      "equity": 16450.5,
      "debt": 6.234,
      "gold": 46722.91928,
      "pb": 4.16,
      "pe": 25.21,
      "yield": 1.11,
      "date": "2021-08-22"
  },
  "2021-08-23": {
      "equity": 16496.45,
      "debt": 6.243,
      "gold": 46730.30918,
      "pb": 4.18,
      "pe": 25.28,
      "yield": 1.11,
      "date": "2021-08-23"
  },
  "2021-08-24": {
      "equity": 16624.6,
      "debt": 6.25,
      "gold": 47149.85097,
      "pb": 4.21,
      "pe": 25.47,
      "yield": 1.1,
      "date": "2021-08-24"
  },
  "2021-08-25": {
      "equity": 16634.65,
      "debt": 6.241,
      "gold": 46958.03101,
      "pb": 4.21,
      "pe": 25.49,
      "yield": 1.1,
      "date": "2021-08-25"
  },
  "2021-08-26": {
      "equity": 16636.9,
      "debt": 6.251,
      "gold": 46647.47526,
      "pb": 4.21,
      "pe": 25.49,
      "yield": 1.08,
      "date": "2021-08-26"
  },
  "2021-08-27": {
      "equity": 16705.2,
      "debt": 6.254,
      "gold": 46520.10441,
      "pb": 4.23,
      "pe": 25.6,
      "yield": 1.08,
      "date": "2021-08-27"
  },
  "2021-08-28": {
      "equity": 16705.2,
      "debt": 6.254,
      "gold": 46520.10441,
      "pb": 4.23,
      "pe": 25.6,
      "yield": 1.08,
      "date": "2021-08-28"
  },
  "2021-08-29": {
      "equity": 16705.2,
      "debt": 6.254,
      "gold": 46520.10441,
      "pb": 4.23,
      "pe": 25.6,
      "yield": 1.08,
      "date": "2021-08-29"
  },
  "2021-08-30": {
      "equity": 16931.05,
      "debt": 6.225,
      "gold": 46520.10441,
      "pb": 4.29,
      "pe": 25.94,
      "yield": 1.06,
      "date": "2021-08-30"
  },
  "2021-08-31": {
      "equity": 17132.2,
      "debt": 6.215,
      "gold": 46701.93125,
      "pb": 4.33,
      "pe": 26.25,
      "yield": 1.05,
      "date": "2021-08-31"
  },
  "2021-09-01": {
      "equity": 17076.25,
      "debt": 6.199,
      "gold": 46673.07713,
      "pb": 4.32,
      "pe": 26.17,
      "yield": 1.05,
      "date": "2021-09-01"
  },
  "2021-09-02": {
      "equity": 17234.15,
      "debt": 6.171,
      "gold": 46753.26196,
      "pb": 4.36,
      "pe": 26.41,
      "yield": 1.04,
      "date": "2021-09-02"
  },
  "2021-09-03": {
      "equity": 17323.6,
      "debt": 6.156,
      "gold": 46657.11565,
      "pb": 4.38,
      "pe": 26.54,
      "yield": 1.03,
      "date": "2021-09-03"
  },
  "2021-09-04": {
      "equity": 17323.6,
      "debt": 6.156,
      "gold": 46657.11565,
      "pb": 4.38,
      "pe": 26.54,
      "yield": 1.03,
      "date": "2021-09-04"
  },
  "2021-09-05": {
      "equity": 17323.6,
      "debt": 6.156,
      "gold": 46657.11565,
      "pb": 4.38,
      "pe": 26.54,
      "yield": 1.03,
      "date": "2021-09-05"
  },
  "2021-09-06": {
      "equity": 17377.8,
      "debt": 6.173,
      "gold": 47024.18738,
      "pb": 4.39,
      "pe": 26.63,
      "yield": 1.03,
      "date": "2021-09-06"
  },
  "2021-09-07": {
      "equity": 17362.1,
      "debt": 6.195,
      "gold": 46897.01758,
      "pb": 4.39,
      "pe": 26.6,
      "yield": 1.03,
      "date": "2021-09-07"
  },
  "2021-09-08": {
      "equity": 17353.5,
      "debt": 6.188,
      "gold": 46806.50452,
      "pb": 4.39,
      "pe": 26.59,
      "yield": 1.07,
      "date": "2021-09-08"
  },
  "2021-09-09": {
      "equity": 17369.25,
      "debt": 6.178,
      "gold": 46575.43167,
      "pb": 4.39,
      "pe": 26.61,
      "yield": 1.07,
      "date": "2021-09-09"
  },
  "2021-09-10": {
      "equity": 17369.25,
      "debt": 6.178,
      "gold": 46684.89744,
      "pb": 4.39,
      "pe": 26.61,
      "yield": 1.07,
      "date": "2021-09-10"
  },
  "2021-09-11": {
      "equity": 17369.25,
      "debt": 6.178,
      "gold": 46684.89744,
      "pb": 4.39,
      "pe": 26.61,
      "yield": 1.07,
      "date": "2021-09-11"
  },
  "2021-09-12": {
      "equity": 17369.25,
      "debt": 6.178,
      "gold": 46684.89744,
      "pb": 4.39,
      "pe": 26.61,
      "yield": 1.07,
      "date": "2021-09-12"
  },
  "2021-09-13": {
      "equity": 17355.3,
      "debt": 6.192,
      "gold": 46422.48717,
      "pb": 4.39,
      "pe": 26.59,
      "yield": 1.07,
      "date": "2021-09-13"
  },
  "2021-09-14": {
      "equity": 17380,
      "debt": 6.198,
      "gold": 46464.39267,
      "pb": 4.39,
      "pe": 26.63,
      "yield": 1.07,
      "date": "2021-09-14"
  },
  "2021-09-15": {
      "equity": 17519.45,
      "debt": 6.163,
      "gold": 46713.3177,
      "pb": 4.43,
      "pe": 26.84,
      "yield": 1.06,
      "date": "2021-09-15"
  },
  "2021-09-16": {
      "equity": 17629.5,
      "debt": 6.169,
      "gold": 46234.62848,
      "pb": 4.45,
      "pe": 26.99,
      "yield": 1.12,
      "date": "2021-09-16"
  },
  "2021-09-17": {
      "equity": 17585.15,
      "debt": 6.167,
      "gold": 45882.53056,
      "pb": 4.44,
      "pe": 26.93,
      "yield": 1.13,
      "date": "2021-09-17"
  },
  "2021-09-18": {
      "equity": 17585.15,
      "debt": 6.167,
      "gold": 45882.53056,
      "pb": 4.44,
      "pe": 26.93,
      "yield": 1.13,
      "date": "2021-09-18"
  },
  "2021-09-19": {
      "equity": 17585.15,
      "debt": 6.167,
      "gold": 45882.53056,
      "pb": 4.44,
      "pe": 26.93,
      "yield": 1.13,
      "date": "2021-09-19"
  },
  "2021-09-20": {
      "equity": 17396.9,
      "debt": 6.138,
      "gold": 45672.44925,
      "pb": 4.4,
      "pe": 26.64,
      "yield": 1.14,
      "date": "2021-09-20"
  },
  "2021-09-21": {
      "equity": 17562,
      "debt": 6.122,
      "gold": 45942.59158,
      "pb": 4.44,
      "pe": 26.89,
      "yield": 1.13,
      "date": "2021-09-21"
  },
  "2021-09-22": {
      "equity": 17546.65,
      "debt": 6.138,
      "gold": 46232.37094,
      "pb": 4.43,
      "pe": 26.87,
      "yield": 1.13,
      "date": "2021-09-22"
  },
  "2021-09-23": {
      "equity": 17822.95,
      "debt": 6.14,
      "gold": 46113.70924,
      "pb": 4.5,
      "pe": 27.29,
      "yield": 1.11,
      "date": "2021-09-23"
  },
  "2021-09-24": {
      "equity": 17853.2,
      "debt": 6.181,
      "gold": 45690.42488,
      "pb": 4.47,
      "pe": 27.34,
      "yield": 1.11,
      "date": "2021-09-24"
  },
  "2021-09-25": {
      "equity": 17853.2,
      "debt": 6.181,
      "gold": 45690.42488,
      "pb": 4.47,
      "pe": 27.34,
      "yield": 1.11,
      "date": "2021-09-25"
  },
  "2021-09-26": {
      "equity": 17853.2,
      "debt": 6.181,
      "gold": 45690.42488,
      "pb": 4.47,
      "pe": 27.34,
      "yield": 1.11,
      "date": "2021-09-26"
  },
  "2021-09-27": {
      "equity": 17855.1,
      "debt": 6.209,
      "gold": 45567.3645,
      "pb": 4.47,
      "pe": 27.33,
      "yield": 1.15,
      "date": "2021-09-27"
  },
  "2021-09-28": {
      "equity": 17748.6,
      "debt": 6.228,
      "gold": 45543.42757,
      "pb": 4.44,
      "pe": 27.16,
      "yield": 1.16,
      "date": "2021-09-28"
  },
  "2021-09-29": {
      "equity": 17711.3,
      "debt": 6.206,
      "gold": 45686.40364,
      "pb": 4.43,
      "pe": 27.11,
      "yield": 1.16,
      "date": "2021-09-29"
  },
  "2021-09-30": {
      "equity": 17618.15,
      "debt": 6.223,
      "gold": 45334.59497,
      "pb": 4.41,
      "pe": 26.98,
      "yield": 1.17,
      "date": "2021-09-30"
  },
  "2021-10-01": {
      "equity": 17532.05,
      "debt": 6.244,
      "gold": 45932.94062,
      "pb": 4.39,
      "pe": 26.84,
      "yield": 1.17,
      "date": "2021-10-01"
  },
  "2021-10-02": {
      "equity": 17532.05,
      "debt": 6.244,
      "gold": 45932.94062,
      "pb": 4.39,
      "pe": 26.84,
      "yield": 1.17,
      "date": "2021-10-02"
  },
  "2021-10-03": {
      "equity": 17532.05,
      "debt": 6.244,
      "gold": 45932.94062,
      "pb": 4.39,
      "pe": 26.84,
      "yield": 1.17,
      "date": "2021-10-03"
  },
  "2021-10-04": {
      "equity": 17691.25,
      "debt": 6.248,
      "gold": 46059.02044,
      "pb": 4.43,
      "pe": 27.09,
      "yield": 1.16,
      "date": "2021-10-04"
  },
  "2021-10-05": {
      "equity": 17822.3,
      "debt": 6.26,
      "gold": 46216.06025,
      "pb": 4.46,
      "pe": 27.29,
      "yield": 1.16,
      "date": "2021-10-05"
  },
  "2021-10-06": {
      "equity": 17646,
      "debt": 6.276,
      "gold": 46150.60583,
      "pb": 4.42,
      "pe": 27.02,
      "yield": 1.17,
      "date": "2021-10-06"
  },
  "2021-10-07": {
      "equity": 17790.35,
      "debt": 6.267,
      "gold": 46416.86097,
      "pb": 4.45,
      "pe": 27.24,
      "yield": 1.16,
      "date": "2021-10-07"
  },
  "2021-10-08": {
      "equity": 17895.2,
      "debt": 6.318,
      "gold": 46610.4023,
      "pb": 4.48,
      "pe": 27.4,
      "yield": 1.15,
      "date": "2021-10-08"
  },
  "2021-10-09": {
      "equity": 17895.2,
      "debt": 6.318,
      "gold": 46610.4023,
      "pb": 4.48,
      "pe": 27.4,
      "yield": 1.15,
      "date": "2021-10-09"
  },
  "2021-10-10": {
      "equity": 17895.2,
      "debt": 6.318,
      "gold": 46610.4023,
      "pb": 4.48,
      "pe": 27.4,
      "yield": 1.15,
      "date": "2021-10-10"
  },
  "2021-10-11": {
      "equity": 17945.95,
      "debt": 6.34,
      "gold": 46604.43394,
      "pb": 4.49,
      "pe": 27.48,
      "yield": 1.15,
      "date": "2021-10-11"
  },
  "2021-10-12": {
      "equity": 17991.95,
      "debt": 6.326,
      "gold": 46824.21912,
      "pb": 4.51,
      "pe": 27.49,
      "yield": 1.14,
      "date": "2021-10-12"
  },
  "2021-10-13": {
      "equity": 18161.75,
      "debt": 6.314,
      "gold": 46961.38203,
      "pb": 4.55,
      "pe": 27.75,
      "yield": 1.13,
      "date": "2021-10-13"
  },
  "2021-10-14": {
      "equity": 18338.55,
      "debt": 6.328,
      "gold": 47558.11919,
      "pb": 4.59,
      "pe": 28.02,
      "yield": 1.12,
      "date": "2021-10-14"
  },
  "2021-10-15": {
      "equity": 18338.55,
      "debt": 6.328,
      "gold": 47144.84912,
      "pb": 4.59,
      "pe": 28.02,
      "yield": 1.12,
      "date": "2021-10-15"
  },
  "2021-10-16": {
      "equity": 18338.55,
      "debt": 6.328,
      "gold": 47144.84912,
      "pb": 4.59,
      "pe": 28.02,
      "yield": 1.12,
      "date": "2021-10-16"
  },
  "2021-10-17": {
      "equity": 18338.55,
      "debt": 6.328,
      "gold": 47144.84912,
      "pb": 4.59,
      "pe": 28.02,
      "yield": 1.12,
      "date": "2021-10-17"
  },
  "2021-10-18": {
      "equity": 18477.05,
      "debt": 6.387,
      "gold": 46820.9457,
      "pb": 4.63,
      "pe": 28.17,
      "yield": 1.11,
      "date": "2021-10-18"
  },
  "2021-10-19": {
      "equity": 18418.75,
      "debt": 6.387,
      "gold": 47237.46803,
      "pb": 4.61,
      "pe": 27.97,
      "yield": 1.07,
      "date": "2021-10-19"
  },
  "2021-10-20": {
      "equity": 18266.6,
      "debt": 6.373,
      "gold": 46918.89804,
      "pb": 4.57,
      "pe": 27.72,
      "yield": 1.08,
      "date": "2021-10-20"
  },
  "2021-10-21": {
      "equity": 18178.1,
      "debt": 6.338,
      "gold": 47122.86284,
      "pb": 4.55,
      "pe": 27.58,
      "yield": 1.1,
      "date": "2021-10-21"
  },
  "2021-10-22": {
      "equity": 18114.9,
      "debt": 6.364,
      "gold": 47414.5999,
      "pb": 4.54,
      "pe": 27.48,
      "yield": 1.1,
      "date": "2021-10-22"
  },
  "2021-10-23": {
      "equity": 18114.9,
      "debt": 6.364,
      "gold": 47414.5999,
      "pb": 4.54,
      "pe": 27.48,
      "yield": 1.1,
      "date": "2021-10-23"
  },
  "2021-10-24": {
      "equity": 18114.9,
      "debt": 6.364,
      "gold": 47414.5999,
      "pb": 4.54,
      "pe": 27.48,
      "yield": 1.1,
      "date": "2021-10-24"
  },
  "2021-10-25": {
      "equity": 18125.4,
      "debt": 6.35,
      "gold": 47618.71285,
      "pb": 4.54,
      "pe": 27.29,
      "yield": 1.04,
      "date": "2021-10-25"
  },
  "2021-10-26": {
      "equity": 18268.4,
      "debt": 6.362,
      "gold": 47646.00081,
      "pb": 4.57,
      "pe": 27.16,
      "yield": 1.12,
      "date": "2021-10-26"
  },
  "2021-10-27": {
      "equity": 18210.95,
      "debt": 6.336,
      "gold": 47170.60971,
      "pb": 4.56,
      "pe": 27.06,
      "yield": 1.13,
      "date": "2021-10-27"
  },
  "2021-10-28": {
      "equity": 17857.25,
      "debt": 6.367,
      "gold": 47441.72913,
      "pb": 4.47,
      "pe": 26.39,
      "yield": 1.13,
      "date": "2021-10-28"
  },
  "2021-10-29": {
      "equity": 17671.65,
      "debt": 6.388,
      "gold": 47508.06187,
      "pb": 4.43,
      "pe": 25.71,
      "yield": 1.13,
      "date": "2021-10-29"
  },
  "2021-10-30": {
      "equity": 17671.65,
      "debt": 6.388,
      "gold": 47508.06187,
      "pb": 4.43,
      "pe": 25.71,
      "yield": 1.13,
      "date": "2021-10-30"
  },
  "2021-10-31": {
      "equity": 17671.65,
      "debt": 6.388,
      "gold": 47508.06187,
      "pb": 4.43,
      "pe": 25.71,
      "yield": 1.13,
      "date": "2021-10-31"
  },
  "2021-11-01": {
      "equity": 17929.65,
      "debt": 6.387,
      "gold": 47176.46519,
      "pb": 4.49,
      "pe": 26.07,
      "yield": 1.11,
      "date": "2021-11-01"
  },
  "2021-11-02": {
      "equity": 17888.95,
      "debt": 6.36,
      "gold": 47149.19487,
      "pb": 4.48,
      "pe": 25.95,
      "yield": 1.13,
      "date": "2021-11-02"
  },
  "2021-11-03": {
      "equity": 17829.2,
      "debt": 6.357,
      "gold": 46863.60606,
      "pb": 4.46,
      "pe": 25.98,
      "yield": 1.13,
      "date": "2021-11-03"
  },
  "2021-11-04": {
      "equity": 17916.8,
      "debt": 6.357,
      "gold": 46727.97404,
      "pb": 4.49,
      "pe": 26.11,
      "yield": 1.1,
      "date": "2021-11-04"
  },
  "2021-11-05": {
      "equity": 17916.8,
      "debt": 6.357,
      "gold": 47124.79938,
      "pb": 4.49,
      "pe": 26.11,
      "yield": 1.1,
      "date": "2021-11-05"
  },
  "2021-11-06": {
      "equity": 17916.8,
      "debt": 6.357,
      "gold": 47124.79938,
      "pb": 4.49,
      "pe": 26.11,
      "yield": 1.1,
      "date": "2021-11-06"
  },
  "2021-11-07": {
      "equity": 17916.8,
      "debt": 6.357,
      "gold": 47124.79938,
      "pb": 4.49,
      "pe": 26.11,
      "yield": 1.1,
      "date": "2021-11-07"
  },
  "2021-11-08": {
      "equity": 18068.55,
      "debt": 6.301,
      "gold": 47375.47752,
      "pb": 4.52,
      "pe": 26.25,
      "yield": 1.09,
      "date": "2021-11-08"
  },
  "2021-11-09": {
      "equity": 18044.25,
      "debt": 6.293,
      "gold": 47702.94714,
      "pb": 4.52,
      "pe": 26.06,
      "yield": 1.09,
      "date": "2021-11-09"
  },
  "2021-11-10": {
      "equity": 18017.2,
      "debt": 6.336,
      "gold": 47820.90689,
      "pb": 4.51,
      "pe": 26.03,
      "yield": 1.09,
      "date": "2021-11-10"
  },
  "2021-11-11": {
      "equity": 17873.6,
      "debt": 6.367,
      "gold": 48721.07797,
      "pb": 4.48,
      "pe": 25.65,
      "yield": 1.12,
      "date": "2021-11-11"
  },
  "2021-11-12": {
      "equity": 18102.75,
      "debt": 6.368,
      "gold": 48546.22833,
      "pb": 4.53,
      "pe": 25.98,
      "yield": 1.11,
      "date": "2021-11-12"
  },
  "2021-11-13": {
      "equity": 18102.75,
      "debt": 6.368,
      "gold": 48546.22833,
      "pb": 4.53,
      "pe": 25.98,
      "yield": 1.11,
      "date": "2021-11-13"
  },
  "2021-11-14": {
      "equity": 18102.75,
      "debt": 6.368,
      "gold": 48546.22833,
      "pb": 4.53,
      "pe": 25.98,
      "yield": 1.11,
      "date": "2021-11-14"
  },
  "2021-11-15": {
      "equity": 18109.45,
      "debt": 6.342,
      "gold": 48911.31413,
      "pb": 4.53,
      "pe": 26,
      "yield": 1.11,
      "date": "2021-11-15"
  },
  "2021-11-16": {
      "equity": 17999.2,
      "debt": 6.364,
      "gold": 49151.88628,
      "pb": 4.51,
      "pe": 24.83,
      "yield": 1.11,
      "date": "2021-11-16"
  },
  "2021-11-17": {
      "equity": 17898.65,
      "debt": 6.361,
      "gold": 48728.89469,
      "pb": 4.48,
      "pe": 24.69,
      "yield": 1.12,
      "date": "2021-11-17"
  },
  "2021-11-18": {
      "equity": 17764.8,
      "debt": 6.345,
      "gold": 48691.49015,
      "pb": 4.45,
      "pe": 24.51,
      "yield": 1.13,
      "date": "2021-11-18"
  },
  "2021-11-19": {
      "equity": 17764.8,
      "debt": 6.345,
      "gold": 48703.22581,
      "pb": 4.45,
      "pe": 24.51,
      "yield": 1.13,
      "date": "2021-11-19"
  },
  "2021-11-20": {
      "equity": 17764.8,
      "debt": 6.345,
      "gold": 48703.22581,
      "pb": 4.45,
      "pe": 24.51,
      "yield": 1.13,
      "date": "2021-11-20"
  },
  "2021-11-21": {
      "equity": 17764.8,
      "debt": 6.345,
      "gold": 48703.22581,
      "pb": 4.45,
      "pe": 24.51,
      "yield": 1.13,
      "date": "2021-11-21"
  },
  "2021-11-22": {
      "equity": 17416.55,
      "debt": 6.351,
      "gold": 48349.37124,
      "pb": 4.36,
      "pe": 24.03,
      "yield": 1.16,
      "date": "2021-11-22"
  },
  "2021-11-23": {
      "equity": 17503.35,
      "debt": 6.364,
      "gold": 47224.81173,
      "pb": 4.38,
      "pe": 24.15,
      "yield": 1.15,
      "date": "2021-11-23"
  },
  "2021-11-24": {
      "equity": 17415.05,
      "debt": 6.367,
      "gold": 47065.83185,
      "pb": 4.36,
      "pe": 24.03,
      "yield": 1.16,
      "date": "2021-11-24"
  },
  "2021-11-25": {
      "equity": 17536.25,
      "debt": 6.367,
      "gold": 47059.23914,
      "pb": 4.39,
      "pe": 24.19,
      "yield": 1.15,
      "date": "2021-11-25"
  },
  "2021-11-26": {
      "equity": 17026.45,
      "debt": 6.33,
      "gold": 47852.97801,
      "pb": 4.26,
      "pe": 23.49,
      "yield": 1.18,
      "date": "2021-11-26"
  },
  "2021-11-27": {
      "equity": 17026.45,
      "debt": 6.33,
      "gold": 47852.97801,
      "pb": 4.26,
      "pe": 23.49,
      "yield": 1.18,
      "date": "2021-11-27"
  },
  "2021-11-28": {
      "equity": 17026.45,
      "debt": 6.33,
      "gold": 47852.97801,
      "pb": 4.26,
      "pe": 23.49,
      "yield": 1.18,
      "date": "2021-11-28"
  },
  "2021-11-29": {
      "equity": 17053.95,
      "debt": 6.335,
      "gold": 47563.84416,
      "pb": 4.27,
      "pe": 23.53,
      "yield": 1.18,
      "date": "2021-11-29"
  },
  "2021-11-30": {
      "equity": 16983.2,
      "debt": 6.326,
      "gold": 47645.79975,
      "pb": 4.25,
      "pe": 23.43,
      "yield": 1.19,
      "date": "2021-11-30"
  },
  "2021-12-01": {
      "equity": 17166.9,
      "debt": 6.353,
      "gold": 47265.00291,
      "pb": 4.3,
      "pe": 23.68,
      "yield": 1.17,
      "date": "2021-12-01"
  },
  "2021-12-02": {
      "equity": 17401.65,
      "debt": 6.352,
      "gold": 46956.97631,
      "pb": 4.36,
      "pe": 24.01,
      "yield": 1.16,
      "date": "2021-12-02"
  },
  "2021-12-03": {
      "equity": 17196.7,
      "debt": 6.369,
      "gold": 47071.20761,
      "pb": 4.31,
      "pe": 23.73,
      "yield": 1.17,
      "date": "2021-12-03"
  },
  "2021-12-04": {
      "equity": 17196.7,
      "debt": 6.369,
      "gold": 47071.20761,
      "pb": 4.31,
      "pe": 23.73,
      "yield": 1.17,
      "date": "2021-12-04"
  },
  "2021-12-05": {
      "equity": 17196.7,
      "debt": 6.369,
      "gold": 47071.20761,
      "pb": 4.31,
      "pe": 23.73,
      "yield": 1.17,
      "date": "2021-12-05"
  },
  "2021-12-06": {
      "equity": 16912.25,
      "debt": 6.359,
      "gold": 47348.15782,
      "pb": 4.23,
      "pe": 23.33,
      "yield": 1.22,
      "date": "2021-12-06"
  },
  "2021-12-07": {
      "equity": 17176.7,
      "debt": 6.391,
      "gold": 47361.24447,
      "pb": 4.3,
      "pe": 23.69,
      "yield": 1.2,
      "date": "2021-12-07"
  },
  "2021-12-08": {
      "equity": 17469.75,
      "debt": 6.347,
      "gold": 47610.59278,
      "pb": 4.37,
      "pe": 24.1,
      "yield": 1.18,
      "date": "2021-12-08"
  },
  "2021-12-09": {
      "equity": 17516.85,
      "debt": 6.348,
      "gold": 47557.54422,
      "pb": 4.39,
      "pe": 24.16,
      "yield": 1.17,
      "date": "2021-12-09"
  },
  "2021-12-10": {
      "equity": 17511.3,
      "debt": 6.37,
      "gold": 47296.56255,
      "pb": 4.38,
      "pe": 24.15,
      "yield": 1.18,
      "date": "2021-12-10"
  },
  "2021-12-11": {
      "equity": 17511.3,
      "debt": 6.37,
      "gold": 47296.56255,
      "pb": 4.38,
      "pe": 24.15,
      "yield": 1.18,
      "date": "2021-12-11"
  },
  "2021-12-12": {
      "equity": 17511.3,
      "debt": 6.37,
      "gold": 47296.56255,
      "pb": 4.38,
      "pe": 24.15,
      "yield": 1.18,
      "date": "2021-12-12"
  },
  "2021-12-13": {
      "equity": 17368.25,
      "debt": 6.37,
      "gold": 47743.21946,
      "pb": 4.35,
      "pe": 23.96,
      "yield": 1.18,
      "date": "2021-12-13"
  },
  "2021-12-14": {
      "equity": 17324.9,
      "debt": 6.356,
      "gold": 47814.97381,
      "pb": 4.34,
      "pe": 23.9,
      "yield": 1.19,
      "date": "2021-12-14"
  },
  "2021-12-15": {
      "equity": 17221.4,
      "debt": 6.364,
      "gold": 47711.60338,
      "pb": 4.31,
      "pe": 23.75,
      "yield": 1.2,
      "date": "2021-12-15"
  },
  "2021-12-16": {
      "equity": 17248.4,
      "debt": 6.374,
      "gold": 48010.67744,
      "pb": 4.32,
      "pe": 23.79,
      "yield": 1.19,
      "date": "2021-12-16"
  },
  "2021-12-17": {
      "equity": 16985.2,
      "debt": 6.411,
      "gold": 48454.67116,
      "pb": 4.25,
      "pe": 23.43,
      "yield": 1.19,
      "date": "2021-12-17"
  },
  "2021-12-18": {
      "equity": 16985.2,
      "debt": 6.411,
      "gold": 48454.67116,
      "pb": 4.25,
      "pe": 23.43,
      "yield": 1.19,
      "date": "2021-12-18"
  },
  "2021-12-19": {
      "equity": 16985.2,
      "debt": 6.411,
      "gold": 48454.67116,
      "pb": 4.25,
      "pe": 23.43,
      "yield": 1.19,
      "date": "2021-12-19"
  },
  "2021-12-20": {
      "equity": 16614.2,
      "debt": 6.435,
      "gold": 48025.66183,
      "pb": 4.16,
      "pe": 22.92,
      "yield": 1.22,
      "date": "2021-12-20"
  },
  "2021-12-21": {
      "equity": 16770.85,
      "debt": 6.468,
      "gold": 47955.17029,
      "pb": 4.2,
      "pe": 23.13,
      "yield": 1.2,
      "date": "2021-12-21"
  },
  "2021-12-22": {
      "equity": 16955.45,
      "debt": 6.464,
      "gold": 47651.95506,
      "pb": 4.24,
      "pe": 23.39,
      "yield": 1.22,
      "date": "2021-12-22"
  },
  "2021-12-23": {
      "equity": 17072.6,
      "debt": 6.46,
      "gold": 47781.17427,
      "pb": 4.27,
      "pe": 23.55,
      "yield": 1.22,
      "date": "2021-12-23"
  },
  "2021-12-24": {
      "equity": 17003.75,
      "debt": 6.462,
      "gold": 47304.32283,
      "pb": 4.26,
      "pe": 23.45,
      "yield": 1.22,
      "date": "2021-12-24"
  },
  "2021-12-25": {
      "equity": 17003.75,
      "debt": 6.462,
      "gold": 47304.32283,
      "pb": 4.26,
      "pe": 23.45,
      "yield": 1.22,
      "date": "2021-12-25"
  },
  "2021-12-26": {
      "equity": 17003.75,
      "debt": 6.462,
      "gold": 47304.32283,
      "pb": 4.26,
      "pe": 23.45,
      "yield": 1.22,
      "date": "2021-12-26"
  },
  "2021-12-27": {
      "equity": 17086.25,
      "debt": 6.465,
      "gold": 47304.32283,
      "pb": 4.28,
      "pe": 23.57,
      "yield": 1.21,
      "date": "2021-12-27"
  },
  "2021-12-28": {
      "equity": 17233.25,
      "debt": 6.478,
      "gold": 47304.32283,
      "pb": 4.31,
      "pe": 23.77,
      "yield": 1.2,
      "date": "2021-12-28"
  },
  "2021-12-29": {
      "equity": 17213.6,
      "debt": 6.461,
      "gold": 47246.73804,
      "pb": 4.31,
      "pe": 23.74,
      "yield": 1.21,
      "date": "2021-12-29"
  },
  "2021-12-30": {
      "equity": 17203.95,
      "debt": 6.469,
      "gold": 47265.26041,
      "pb": 4.31,
      "pe": 23.73,
      "yield": 1.21,
      "date": "2021-12-30"
  },
  "2021-12-31": {
      "equity": 17354.05,
      "debt": 6.454,
      "gold": 47827.937,
      "pb": 4.37,
      "pe": 24.11,
      "yield": 1.19,
      "date": "2021-12-31"
  },
  "2022-01-01": {
      "equity": 17354.05,
      "debt": 6.454,
      "gold": 47827.937,
      "pb": 4.37,
      "pe": 24.11,
      "yield": 1.19,
      "date": "2022-01-01"
  },
  "2022-01-02": {
      "equity": 17354.05,
      "debt": 6.454,
      "gold": 47827.937,
      "pb": 4.37,
      "pe": 24.11,
      "yield": 1.19,
      "date": "2022-01-02"
  },
  "2022-01-03": {
      "equity": 17625.7,
      "debt": 6.46,
      "gold": 47827.937,
      "pb": 4.44,
      "pe": 24.49,
      "yield": 1.17,
      "date": "2022-01-03"
  },
  "2022-01-04": {
      "equity": 17805.25,
      "debt": 6.518,
      "gold": 47566.72957,
      "pb": 4.49,
      "pe": 24.74,
      "yield": 1.16,
      "date": "2022-01-04"
  },
  "2022-01-05": {
      "equity": 17925.25,
      "debt": 6.508,
      "gold": 47649.06965,
      "pb": 4.52,
      "pe": 24.91,
      "yield": 1.15,
      "date": "2022-01-05"
  },
  "2022-01-06": {
      "equity": 17745.9,
      "debt": 6.525,
      "gold": 47364.38032,
      "pb": 4.47,
      "pe": 24.66,
      "yield": 1.16,
      "date": "2022-01-06"
  },
  "2022-01-07": {
      "equity": 17812.7,
      "debt": 6.542,
      "gold": 46939.08182,
      "pb": 4.49,
      "pe": 24.75,
      "yield": 1.16,
      "date": "2022-01-07"
  },
  "2022-01-08": {
      "equity": 17812.7,
      "debt": 6.542,
      "gold": 46939.08182,
      "pb": 4.49,
      "pe": 24.75,
      "yield": 1.16,
      "date": "2022-01-08"
  },
  "2022-01-09": {
      "equity": 17812.7,
      "debt": 6.542,
      "gold": 46939.08182,
      "pb": 4.49,
      "pe": 24.75,
      "yield": 1.16,
      "date": "2022-01-09"
  },
  "2022-01-10": {
      "equity": 18003.3,
      "debt": 6.59,
      "gold": 47020.58943,
      "pb": 4.54,
      "pe": 25.01,
      "yield": 1.14,
      "date": "2022-01-10"
  },
  "2022-01-11": {
      "equity": 18055.75,
      "debt": 6.57,
      "gold": 47025.17505,
      "pb": 4.55,
      "pe": 25.09,
      "yield": 1.14,
      "date": "2022-01-11"
  },
  "2022-01-12": {
      "equity": 18212.35,
      "debt": 6.593,
      "gold": 47263.43322,
      "pb": 4.59,
      "pe": 25.3,
      "yield": 1.13,
      "date": "2022-01-12"
  },
  "2022-01-13": {
      "equity": 18257.8,
      "debt": 6.561,
      "gold": 47500.23104,
      "pb": 4.6,
      "pe": 25.37,
      "yield": 1.13,
      "date": "2022-01-13"
  },
  "2022-01-14": {
      "equity": 18255.75,
      "debt": 6.582,
      "gold": 47632.65666,
      "pb": 4.6,
      "pe": 25.3,
      "yield": 1.12,
      "date": "2022-01-14"
  },
  "2022-01-15": {
      "equity": 18255.75,
      "debt": 6.582,
      "gold": 47632.65666,
      "pb": 4.6,
      "pe": 25.3,
      "yield": 1.12,
      "date": "2022-01-15"
  },
  "2022-01-16": {
      "equity": 18255.75,
      "debt": 6.582,
      "gold": 47632.65666,
      "pb": 4.6,
      "pe": 25.3,
      "yield": 1.12,
      "date": "2022-01-16"
  },
  "2022-01-17": {
      "equity": 18308.1,
      "debt": 6.636,
      "gold": 47687.69114,
      "pb": 4.61,
      "pe": 25.37,
      "yield": 1.12,
      "date": "2022-01-17"
  },
  "2022-01-18": {
      "equity": 18113.05,
      "debt": 6.627,
      "gold": 47634.58968,
      "pb": 4.56,
      "pe": 25,
      "yield": 1.13,
      "date": "2022-01-18"
  },
  "2022-01-19": {
      "equity": 17938.4,
      "debt": 6.598,
      "gold": 47691.08097,
      "pb": 4.52,
      "pe": 24.76,
      "yield": 1.15,
      "date": "2022-01-19"
  },
  "2022-01-20": {
      "equity": 17757,
      "debt": 6.608,
      "gold": 48152.51415,
      "pb": 4.47,
      "pe": 24.47,
      "yield": 1.18,
      "date": "2022-01-20"
  },
  "2022-01-21": {
      "equity": 17617.15,
      "debt": 6.62,
      "gold": 48076.8973,
      "pb": 4.44,
      "pe": 24.29,
      "yield": 1.18,
      "date": "2022-01-21"
  },
  "2022-01-22": {
      "equity": 17617.15,
      "debt": 6.62,
      "gold": 48076.8973,
      "pb": 4.44,
      "pe": 24.29,
      "yield": 1.18,
      "date": "2022-01-22"
  },
  "2022-01-23": {
      "equity": 17617.15,
      "debt": 6.62,
      "gold": 48076.8973,
      "pb": 4.44,
      "pe": 24.29,
      "yield": 1.18,
      "date": "2022-01-23"
  },
  "2022-01-24": {
      "equity": 17149.1,
      "debt": 6.652,
      "gold": 48423.21029,
      "pb": 4.32,
      "pe": 23.63,
      "yield": 1.21,
      "date": "2022-01-24"
  },
  "2022-01-25": {
      "equity": 17277.95,
      "debt": 6.662,
      "gold": 48409.56631,
      "pb": 4.35,
      "pe": 23.47,
      "yield": 1.2,
      "date": "2022-01-25"
  },
  "2022-01-26": {
      "equity": 17277.95,
      "debt": 6.662,
      "gold": 48661.74359,
      "pb": 4.35,
      "pe": 23.47,
      "yield": 1.2,
      "date": "2022-01-26"
  },
  "2022-01-27": {
      "equity": 17110.15,
      "debt": 6.746,
      "gold": 48184.28544,
      "pb": 4.31,
      "pe": 23.09,
      "yield": 1.21,
      "date": "2022-01-27"
  },
  "2022-01-28": {
      "equity": 17101.95,
      "debt": 6.768,
      "gold": 47372.93779,
      "pb": 4.31,
      "pe": 23.1,
      "yield": 1.21,
      "date": "2022-01-28"
  },
  "2022-01-29": {
      "equity": 17101.95,
      "debt": 6.768,
      "gold": 47372.93779,
      "pb": 4.31,
      "pe": 23.1,
      "yield": 1.21,
      "date": "2022-01-29"
  },
  "2022-01-30": {
      "equity": 17101.95,
      "debt": 6.768,
      "gold": 47372.93779,
      "pb": 4.31,
      "pe": 23.1,
      "yield": 1.21,
      "date": "2022-01-30"
  },
  "2022-01-31": {
      "equity": 17339.85,
      "debt": 6.684,
      "gold": 47117.61054,
      "pb": 4.37,
      "pe": 23.42,
      "yield": 1.2,
      "date": "2022-01-31"
  },
  "2022-02-01": {
      "equity": 17576.85,
      "debt": 6.828,
      "gold": 47642.73797,
      "pb": 4.43,
      "pe": 23.62,
      "yield": 1.18,
      "date": "2022-02-01"
  },
  "2022-02-02": {
      "equity": 17780,
      "debt": 6.88,
      "gold": 47533.84716,
      "pb": 4.48,
      "pe": 23.95,
      "yield": 1.17,
      "date": "2022-02-02"
  },
  "2022-02-03": {
      "equity": 17560.2,
      "debt": 6.889,
      "gold": 47539.6074,
      "pb": 4.42,
      "pe": 23.65,
      "yield": 1.21,
      "date": "2022-02-03"
  },
  "2022-02-04": {
      "equity": 17516.3,
      "debt": 6.879,
      "gold": 47766.90241,
      "pb": 4.41,
      "pe": 23.56,
      "yield": 1.21,
      "date": "2022-02-04"
  },
  "2022-02-05": {
      "equity": 17516.3,
      "debt": 6.879,
      "gold": 47766.90241,
      "pb": 4.41,
      "pe": 23.56,
      "yield": 1.21,
      "date": "2022-02-05"
  },
  "2022-02-06": {
      "equity": 17516.3,
      "debt": 6.879,
      "gold": 47766.90241,
      "pb": 4.41,
      "pe": 23.56,
      "yield": 1.21,
      "date": "2022-02-06"
  },
  "2022-02-07": {
      "equity": 17213.6,
      "debt": 6.879,
      "gold": 47695.67012,
      "pb": 4.34,
      "pe": 23.11,
      "yield": 1.23,
      "date": "2022-02-07"
  },
  "2022-02-08": {
      "equity": 17266.75,
      "debt": 6.81,
      "gold": 47997.52377,
      "pb": 4.35,
      "pe": 22.83,
      "yield": 1.23,
      "date": "2022-02-08"
  },
  "2022-02-09": {
      "equity": 17463.8,
      "debt": 6.798,
      "gold": 48234.90009,
      "pb": 4.4,
      "pe": 23.09,
      "yield": 1.2,
      "date": "2022-02-09"
  },
  "2022-02-10": {
      "equity": 17605.85,
      "debt": 6.725,
      "gold": 48584.14434,
      "pb": 4.44,
      "pe": 22.98,
      "yield": 1.2,
      "date": "2022-02-10"
  },
  "2022-02-11": {
      "equity": 17374.75,
      "debt": 6.698,
      "gold": 48541.551,
      "pb": 4.38,
      "pe": 22.68,
      "yield": 1.2,
      "date": "2022-02-11"
  },
  "2022-02-12": {
      "equity": 17374.75,
      "debt": 6.698,
      "gold": 48541.551,
      "pb": 4.38,
      "pe": 22.68,
      "yield": 1.2,
      "date": "2022-02-12"
  },
  "2022-02-13": {
      "equity": 17374.75,
      "debt": 6.698,
      "gold": 48541.551,
      "pb": 4.38,
      "pe": 22.68,
      "yield": 1.2,
      "date": "2022-02-13"
  },
  "2022-02-14": {
      "equity": 16842.8,
      "debt": 6.667,
      "gold": 49491.16563,
      "pb": 4.24,
      "pe": 21.85,
      "yield": 1.3,
      "date": "2022-02-14"
  },
  "2022-02-15": {
      "equity": 17352.45,
      "debt": 6.673,
      "gold": 49213.04079,
      "pb": 4.37,
      "pe": 22.36,
      "yield": 1.25,
      "date": "2022-02-15"
  },
  "2022-02-16": {
      "equity": 17322.2,
      "debt": 6.686,
      "gold": 49075.41932,
      "pb": 4.36,
      "pe": 22.27,
      "yield": 1.28,
      "date": "2022-02-16"
  },
  "2022-02-17": {
      "equity": 17304.6,
      "debt": 6.674,
      "gold": 49933.90007,
      "pb": 4.36,
      "pe": 22.25,
      "yield": 1.25,
      "date": "2022-02-17"
  },
  "2022-02-18": {
      "equity": 17276.3,
      "debt": 6.663,
      "gold": 49703.53974,
      "pb": 4.35,
      "pe": 22.21,
      "yield": 1.25,
      "date": "2022-02-18"
  },
  "2022-02-19": {
      "equity": 17276.3,
      "debt": 6.663,
      "gold": 49703.53974,
      "pb": 4.35,
      "pe": 22.21,
      "yield": 1.25,
      "date": "2022-02-19"
  },
  "2022-02-20": {
      "equity": 17276.3,
      "debt": 6.663,
      "gold": 49703.53974,
      "pb": 4.35,
      "pe": 22.21,
      "yield": 1.25,
      "date": "2022-02-20"
  },
  "2022-02-21": {
      "equity": 17206.65,
      "debt": 6.693,
      "gold": 49820.12381,
      "pb": 4.34,
      "pe": 22.12,
      "yield": 1.29,
      "date": "2022-02-21"
  },
  "2022-02-22": {
      "equity": 17092.2,
      "debt": 6.749,
      "gold": 49931.6249,
      "pb": 4.31,
      "pe": 21.97,
      "yield": 1.24,
      "date": "2022-02-22"
  },
  "2022-02-23": {
      "equity": 17063.25,
      "debt": 6.739,
      "gold": 49939.70264,
      "pb": 4.3,
      "pe": 21.94,
      "yield": 1.24,
      "date": "2022-02-23"
  },
  "2022-02-24": {
      "equity": 16247.95,
      "debt": 6.759,
      "gold": 52551.49826,
      "pb": 4.09,
      "pe": 20.89,
      "yield": 1.3,
      "date": "2022-02-24"
  },
  "2022-02-25": {
      "equity": 16658.4,
      "debt": 6.749,
      "gold": 50661.40144,
      "pb": 4.2,
      "pe": 21.42,
      "yield": 1.27,
      "date": "2022-02-25"
  },
  "2022-02-26": {
      "equity": 16658.4,
      "debt": 6.749,
      "gold": 50661.40144,
      "pb": 4.2,
      "pe": 21.42,
      "yield": 1.27,
      "date": "2022-02-26"
  },
  "2022-02-27": {
      "equity": 16658.4,
      "debt": 6.749,
      "gold": 50661.40144,
      "pb": 4.2,
      "pe": 21.42,
      "yield": 1.27,
      "date": "2022-02-27"
  },
  "2022-02-28": {
      "equity": 16793.9,
      "debt": 6.77,
      "gold": 50718.30191,
      "pb": 4.23,
      "pe": 21.59,
      "yield": 1.26,
      "date": "2022-02-28"
  },
  "2022-03-01": {
      "equity": 16793.9,
      "debt": 6.77,
      "gold": 51185.46712,
      "pb": 4.23,
      "pe": 21.59,
      "yield": 1.26,
      "date": "2022-03-01"
  },
  "2022-03-02": {
      "equity": 16605.95,
      "debt": 6.814,
      "gold": 51343.24062,
      "pb": 4.18,
      "pe": 21.35,
      "yield": 1.27,
      "date": "2022-03-02"
  },
  "2022-03-03": {
      "equity": 16498.05,
      "debt": 6.826,
      "gold": 51845.73978,
      "pb": 4.16,
      "pe": 21.21,
      "yield": 1.28,
      "date": "2022-03-03"
  },
  "2022-03-04": {
      "equity": 16245.35,
      "debt": 6.813,
      "gold": 52381.70691,
      "pb": 4.09,
      "pe": 20.89,
      "yield": 1.3,
      "date": "2022-03-04"
  },
  "2022-03-05": {
      "equity": 16245.35,
      "debt": 6.813,
      "gold": 52381.70691,
      "pb": 4.09,
      "pe": 20.89,
      "yield": 1.3,
      "date": "2022-03-05"
  },
  "2022-03-06": {
      "equity": 16245.35,
      "debt": 6.813,
      "gold": 52381.70691,
      "pb": 4.09,
      "pe": 20.89,
      "yield": 1.3,
      "date": "2022-03-06"
  },
  "2022-03-07": {
      "equity": 15863.15,
      "debt": 6.888,
      "gold": 54259.47195,
      "pb": 4,
      "pe": 20.39,
      "yield": 1.33,
      "date": "2022-03-07"
  },
  "2022-03-08": {
      "equity": 16013.45,
      "debt": 6.895,
      "gold": 54507.75499,
      "pb": 4.03,
      "pe": 20.59,
      "yield": 1.31,
      "date": "2022-03-08"
  },
  "2022-03-09": {
      "equity": 16345.35,
      "debt": 6.845,
      "gold": 54283.42299,
      "pb": 4.12,
      "pe": 21.01,
      "yield": 1.28,
      "date": "2022-03-09"
  },
  "2022-03-10": {
      "equity": 16594.9,
      "debt": 6.809,
      "gold": 53826.51899,
      "pb": 4.18,
      "pe": 21.34,
      "yield": 1.26,
      "date": "2022-03-10"
  },
  "2022-03-11": {
      "equity": 16630.45,
      "debt": 6.855,
      "gold": 53850.45944,
      "pb": 4.19,
      "pe": 21.38,
      "yield": 1.26,
      "date": "2022-03-11"
  },
  "2022-03-12": {
      "equity": 16630.45,
      "debt": 6.855,
      "gold": 53850.45944,
      "pb": 4.19,
      "pe": 21.38,
      "yield": 1.26,
      "date": "2022-03-12"
  },
  "2022-03-13": {
      "equity": 16630.45,
      "debt": 6.855,
      "gold": 53850.45944,
      "pb": 4.19,
      "pe": 21.38,
      "yield": 1.26,
      "date": "2022-03-13"
  },
  "2022-03-14": {
      "equity": 16871.3,
      "debt": 6.854,
      "gold": 52955.82991,
      "pb": 4.25,
      "pe": 21.69,
      "yield": 1.24,
      "date": "2022-03-14"
  },
  "2022-03-15": {
      "equity": 16663,
      "debt": 6.821,
      "gold": 51986.13732,
      "pb": 4.2,
      "pe": 21.42,
      "yield": 1.24,
      "date": "2022-03-15"
  },
  "2022-03-16": {
      "equity": 16975.35,
      "debt": 6.788,
      "gold": 51580.56403,
      "pb": 4.28,
      "pe": 21.82,
      "yield": 1.22,
      "date": "2022-03-16"
  },
  "2022-03-17": {
      "equity": 17287.05,
      "debt": 6.778,
      "gold": 52014.34593,
      "pb": 4.36,
      "pe": 22.23,
      "yield": 1.2,
      "date": "2022-03-17"
  },
  "2022-03-18": {
      "equity": 17287.05,
      "debt": 6.778,
      "gold": 51786.95215,
      "pb": 4.36,
      "pe": 22.23,
      "yield": 1.2,
      "date": "2022-03-18"
  },
  "2022-03-19": {
      "equity": 17287.05,
      "debt": 6.778,
      "gold": 51786.95215,
      "pb": 4.36,
      "pe": 22.23,
      "yield": 1.2,
      "date": "2022-03-19"
  },
  "2022-03-20": {
      "equity": 17287.05,
      "debt": 6.778,
      "gold": 51786.95215,
      "pb": 4.36,
      "pe": 22.23,
      "yield": 1.2,
      "date": "2022-03-20"
  },
  "2022-03-21": {
      "equity": 17117.6,
      "debt": 6.781,
      "gold": 51779.66454,
      "pb": 4.31,
      "pe": 22.01,
      "yield": 1.21,
      "date": "2022-03-21"
  },
  "2022-03-22": {
      "equity": 17315.5,
      "debt": 6.831,
      "gold": 51796.98407,
      "pb": 4.36,
      "pe": 22.26,
      "yield": 1.2,
      "date": "2022-03-22"
  },
  "2022-03-23": {
      "equity": 17245.65,
      "debt": 6.831,
      "gold": 52175.10362,
      "pb": 4.35,
      "pe": 22.17,
      "yield": 1.19,
      "date": "2022-03-23"
  },
  "2022-03-24": {
      "equity": 17222.75,
      "debt": 6.834,
      "gold": 52362.45084,
      "pb": 4.34,
      "pe": 22.14,
      "yield": 1.19,
      "date": "2022-03-24"
  },
  "2022-03-25": {
      "equity": 17153,
      "debt": 6.811,
      "gold": 52654.48421,
      "pb": 4.32,
      "pe": 22.05,
      "yield": 1.19,
      "date": "2022-03-25"
  },
  "2022-03-26": {
      "equity": 17153,
      "debt": 6.811,
      "gold": 52654.48421,
      "pb": 4.32,
      "pe": 22.05,
      "yield": 1.19,
      "date": "2022-03-26"
  },
  "2022-03-27": {
      "equity": 17153,
      "debt": 6.811,
      "gold": 52654.48421,
      "pb": 4.32,
      "pe": 22.05,
      "yield": 1.19,
      "date": "2022-03-27"
  },
  "2022-03-28": {
      "equity": 17222,
      "debt": 6.836,
      "gold": 51732.19634,
      "pb": 4.34,
      "pe": 22.14,
      "yield": 1.19,
      "date": "2022-03-28"
  },
  "2022-03-29": {
      "equity": 17325.3,
      "debt": 6.821,
      "gold": 50938.63737,
      "pb": 4.37,
      "pe": 22.27,
      "yield": 1.18,
      "date": "2022-03-29"
  },
  "2022-03-30": {
      "equity": 17498.25,
      "debt": 6.784,
      "gold": 51289.77583,
      "pb": 4.41,
      "pe": 22.5,
      "yield": 1.17,
      "date": "2022-03-30"
  },
  "2022-03-31": {
      "equity": 17464.75,
      "debt": 6.843,
      "gold": 51522.74291,
      "pb": 4.46,
      "pe": 22.92,
      "yield": 1.13,
      "date": "2022-03-31"
  },
  "2022-04-01": {
      "equity": 17670.45,
      "debt": 6.843,
      "gold": 51794.91702,
      "pb": 4.51,
      "pe": 23.19,
      "yield": 1.12,
      "date": "2022-04-01"
  },
  "2022-04-02": {
      "equity": 17670.45,
      "debt": 6.843,
      "gold": 51794.91702,
      "pb": 4.51,
      "pe": 23.19,
      "yield": 1.12,
      "date": "2022-04-02"
  },
  "2022-04-03": {
      "equity": 17670.45,
      "debt": 6.843,
      "gold": 51794.91702,
      "pb": 4.51,
      "pe": 23.19,
      "yield": 1.12,
      "date": "2022-04-03"
  },
  "2022-04-04": {
      "equity": 18053.4,
      "debt": 6.898,
      "gold": 51282.36124,
      "pb": 4.61,
      "pe": 23.69,
      "yield": 1.1,
      "date": "2022-04-04"
  },
  "2022-04-05": {
      "equity": 17957.4,
      "debt": 6.899,
      "gold": 51323.25438,
      "pb": 4.59,
      "pe": 23.57,
      "yield": 1.11,
      "date": "2022-04-05"
  },
  "2022-04-06": {
      "equity": 17807.65,
      "debt": 6.917,
      "gold": 51682.25895,
      "pb": 4.55,
      "pe": 23.37,
      "yield": 1.12,
      "date": "2022-04-06"
  },
  "2022-04-07": {
      "equity": 17639.55,
      "debt": 6.914,
      "gold": 51592.34907,
      "pb": 4.51,
      "pe": 23.15,
      "yield": 1.13,
      "date": "2022-04-07"
  },
  "2022-04-08": {
      "equity": 17784.35,
      "debt": 7.119,
      "gold": 51724.51013,
      "pb": 4.54,
      "pe": 23.34,
      "yield": 1.11,
      "date": "2022-04-08"
  },
  "2022-04-09": {
      "equity": 17784.35,
      "debt": 7.119,
      "gold": 51724.51013,
      "pb": 4.54,
      "pe": 23.34,
      "yield": 1.11,
      "date": "2022-04-09"
  },
  "2022-04-10": {
      "equity": 17784.35,
      "debt": 7.119,
      "gold": 51724.51013,
      "pb": 4.54,
      "pe": 23.34,
      "yield": 1.11,
      "date": "2022-04-10"
  },
  "2022-04-11": {
      "equity": 17674.95,
      "debt": 7.149,
      "gold": 52382.45119,
      "pb": 4.52,
      "pe": 23.2,
      "yield": 1.12,
      "date": "2022-04-11"
  },
  "2022-04-12": {
      "equity": 17530.3,
      "debt": 7.189,
      "gold": 52371.61149,
      "pb": 4.48,
      "pe": 23.01,
      "yield": 1.13,
      "date": "2022-04-12"
  },
  "2022-04-13": {
      "equity": 17475.65,
      "debt": 7.215,
      "gold": 53001.38627,
      "pb": 4.46,
      "pe": 22.92,
      "yield": 1.13,
      "date": "2022-04-13"
  },
  "2022-04-14": {
      "equity": 17475.65,
      "debt": 7.215,
      "gold": 52887.45833,
      "pb": 4.46,
      "pe": 22.92,
      "yield": 1.13,
      "date": "2022-04-14"
  },
  "2022-04-15": {
      "equity": 17475.65,
      "debt": 7.215,
      "gold": 52887.45833,
      "pb": 4.46,
      "pe": 22.92,
      "yield": 1.13,
      "date": "2022-04-15"
  },
  "2022-04-16": {
      "equity": 17475.65,
      "debt": 7.215,
      "gold": 52887.45833,
      "pb": 4.46,
      "pe": 22.92,
      "yield": 1.13,
      "date": "2022-04-16"
  },
  "2022-04-17": {
      "equity": 17475.65,
      "debt": 7.215,
      "gold": 52887.45833,
      "pb": 4.46,
      "pe": 22.92,
      "yield": 1.13,
      "date": "2022-04-17"
  },
  "2022-04-18": {
      "equity": 17173.65,
      "debt": 7.153,
      "gold": 52887.45833,
      "pb": 4.39,
      "pe": 22.53,
      "yield": 1.15,
      "date": "2022-04-18"
  },
  "2022-04-19": {
      "equity": 16958.65,
      "debt": 7.151,
      "gold": 53242.51221,
      "pb": 4.33,
      "pe": 22.1,
      "yield": 1.16,
      "date": "2022-04-19"
  },
  "2022-04-20": {
      "equity": 17136.55,
      "debt": 7.105,
      "gold": 52414.61401,
      "pb": 4.38,
      "pe": 22.33,
      "yield": 1.15,
      "date": "2022-04-20"
  },
  "2022-04-21": {
      "equity": 17392.6,
      "debt": 7.144,
      "gold": 52381.98557,
      "pb": 4.44,
      "pe": 22.67,
      "yield": 1.14,
      "date": "2022-04-21"
  },
  "2022-04-22": {
      "equity": 17171.95,
      "debt": 7.167,
      "gold": 52419.16789,
      "pb": 4.39,
      "pe": 22.38,
      "yield": 1.15,
      "date": "2022-04-22"
  },
  "2022-04-23": {
      "equity": 17171.95,
      "debt": 7.167,
      "gold": 52419.16789,
      "pb": 4.39,
      "pe": 22.38,
      "yield": 1.15,
      "date": "2022-04-23"
  },
  "2022-04-24": {
      "equity": 17171.95,
      "debt": 7.167,
      "gold": 52419.16789,
      "pb": 4.39,
      "pe": 22.38,
      "yield": 1.15,
      "date": "2022-04-24"
  },
  "2022-04-25": {
      "equity": 16953.95,
      "debt": 7.043,
      "gold": 51897.99467,
      "pb": 4.33,
      "pe": 22.03,
      "yield": 1.17,
      "date": "2022-04-25"
  },
  "2022-04-26": {
      "equity": 17200.8,
      "debt": 7.053,
      "gold": 51487.70878,
      "pb": 4.39,
      "pe": 22.17,
      "yield": 1.15,
      "date": "2022-04-26"
  },
  "2022-04-27": {
      "equity": 17038.4,
      "debt": 7.083,
      "gold": 51220.02857,
      "pb": 4.35,
      "pe": 21.96,
      "yield": 1.16,
      "date": "2022-04-27"
  },
  "2022-04-28": {
      "equity": 17245.05,
      "debt": 7.155,
      "gold": 51104.90132,
      "pb": 4.41,
      "pe": 22.2,
      "yield": 1.17,
      "date": "2022-04-28"
  },
  "2022-04-29": {
      "equity": 17102.55,
      "debt": 7.139,
      "gold": 51719.07441,
      "pb": 4.37,
      "pe": 22.01,
      "yield": 1.16,
      "date": "2022-04-29"
  },
  "2022-04-30": {
      "equity": 17102.55,
      "debt": 7.139,
      "gold": 51719.07441,
      "pb": 4.37,
      "pe": 22.01,
      "yield": 1.16,
      "date": "2022-04-30"
  },
  "2022-05-01": {
      "equity": 17102.55,
      "debt": 7.139,
      "gold": 51719.07441,
      "pb": 4.37,
      "pe": 22.01,
      "yield": 1.16,
      "date": "2022-05-01"
  },
  "2022-05-02": {
      "equity": 17069.1,
      "debt": 7.119,
      "gold": 51719.07441,
      "pb": 4.36,
      "pe": 21.87,
      "yield": 1.16,
      "date": "2022-05-02"
  },
  "2022-05-03": {
      "equity": 17069.1,
      "debt": 7.119,
      "gold": 50148.78217,
      "pb": 4.36,
      "pe": 21.87,
      "yield": 1.16,
      "date": "2022-05-03"
  },
  "2022-05-04": {
      "equity": 16677.6,
      "debt": 7.378,
      "gold": 50265.6202,
      "pb": 4.26,
      "pe": 21.31,
      "yield": 1.19,
      "date": "2022-05-04"
  },
  "2022-05-05": {
      "equity": 16682.65,
      "debt": 7.403,
      "gold": 51159.40316,
      "pb": 4.26,
      "pe": 21.24,
      "yield": 1.19,
      "date": "2022-05-05"
  },
  "2022-05-06": {
      "equity": 16411.25,
      "debt": 7.451,
      "gold": 51150.04145,
      "pb": 4.19,
      "pe": 20.89,
      "yield": 1.21,
      "date": "2022-05-06"
  },
  "2022-05-07": {
      "equity": 16411.25,
      "debt": 7.451,
      "gold": 51150.04145,
      "pb": 4.19,
      "pe": 20.89,
      "yield": 1.21,
      "date": "2022-05-07"
  },
  "2022-05-08": {
      "equity": 16411.25,
      "debt": 7.451,
      "gold": 51150.04145,
      "pb": 4.19,
      "pe": 20.89,
      "yield": 1.21,
      "date": "2022-05-08"
  },
  "2022-05-09": {
      "equity": 16301.85,
      "debt": 7.465,
      "gold": 50931.91062,
      "pb": 4.17,
      "pe": 20.75,
      "yield": 1.21,
      "date": "2022-05-09"
  },
  "2022-05-10": {
      "equity": 16240.05,
      "debt": 7.301,
      "gold": 50769.63968,
      "pb": 4.15,
      "pe": 20.42,
      "yield": 1.22,
      "date": "2022-05-10"
  },
  "2022-05-11": {
      "equity": 16167.1,
      "debt": 7.215,
      "gold": 50492.26618,
      "pb": 4.13,
      "pe": 20.32,
      "yield": 1.22,
      "date": "2022-05-11"
  },
  "2022-05-12": {
      "equity": 15808,
      "debt": 7.244,
      "gold": 50510.11129,
      "pb": 4.04,
      "pe": 19.87,
      "yield": 1.35,
      "date": "2022-05-12"
  },
  "2022-05-13": {
      "equity": 15782.15,
      "debt": 7.318,
      "gold": 49822.51539,
      "pb": 4.03,
      "pe": 19.83,
      "yield": 1.35,
      "date": "2022-05-13"
  },
  "2022-05-14": {
      "equity": 15782.15,
      "debt": 7.318,
      "gold": 49822.51539,
      "pb": 4.03,
      "pe": 19.83,
      "yield": 1.35,
      "date": "2022-05-14"
  },
  "2022-05-15": {
      "equity": 15782.15,
      "debt": 7.318,
      "gold": 49822.51539,
      "pb": 4.03,
      "pe": 19.83,
      "yield": 1.35,
      "date": "2022-05-15"
  },
  "2022-05-16": {
      "equity": 15842.3,
      "debt": 7.318,
      "gold": 49340.29524,
      "pb": 4.05,
      "pe": 19.72,
      "yield": 1.35,
      "date": "2022-05-16"
  },
  "2022-05-17": {
      "equity": 16259.3,
      "debt": 7.366,
      "gold": 49969.77372,
      "pb": 4.15,
      "pe": 20.12,
      "yield": 1.31,
      "date": "2022-05-17"
  },
  "2022-05-18": {
      "equity": 16240.3,
      "debt": 7.355,
      "gold": 49860.1739,
      "pb": 4.15,
      "pe": 20.09,
      "yield": 1.31,
      "date": "2022-05-18"
  },
  "2022-05-19": {
      "equity": 15809.4,
      "debt": 7.32,
      "gold": 49878.51285,
      "pb": 4.04,
      "pe": 19.51,
      "yield": 1.35,
      "date": "2022-05-19"
  },
  "2022-05-20": {
      "equity": 16266.15,
      "debt": 7.359,
      "gold": 50713.44821,
      "pb": 4.16,
      "pe": 20.05,
      "yield": 1.31,
      "date": "2022-05-20"
  },
  "2022-05-21": {
      "equity": 16266.15,
      "debt": 7.359,
      "gold": 50713.44821,
      "pb": 4.16,
      "pe": 20.05,
      "yield": 1.31,
      "date": "2022-05-21"
  },
  "2022-05-22": {
      "equity": 16266.15,
      "debt": 7.359,
      "gold": 50713.44821,
      "pb": 4.16,
      "pe": 20.05,
      "yield": 1.31,
      "date": "2022-05-22"
  },
  "2022-05-23": {
      "equity": 16214.7,
      "debt": 7.386,
      "gold": 50987.56592,
      "pb": 4.14,
      "pe": 20.01,
      "yield": 1.32,
      "date": "2022-05-23"
  },
  "2022-05-24": {
      "equity": 16125.15,
      "debt": 7.359,
      "gold": 50832.45913,
      "pb": 4.12,
      "pe": 19.87,
      "yield": 1.32,
      "date": "2022-05-24"
  },
  "2022-05-25": {
      "equity": 16025.8,
      "debt": 7.305,
      "gold": 50855.39075,
      "pb": 4.09,
      "pe": 19.74,
      "yield": 1.38,
      "date": "2022-05-25"
  },
  "2022-05-26": {
      "equity": 16170.15,
      "debt": 7.294,
      "gold": 50537.82606,
      "pb": 4.13,
      "pe": 19.88,
      "yield": 1.44,
      "date": "2022-05-26"
  },
  "2022-05-27": {
      "equity": 16352.45,
      "debt": 7.35,
      "gold": 50904.98245,
      "pb": 4.18,
      "pe": 20.27,
      "yield": 1.43,
      "date": "2022-05-27"
  },
  "2022-05-28": {
      "equity": 16352.45,
      "debt": 7.35,
      "gold": 50904.98245,
      "pb": 4.18,
      "pe": 20.27,
      "yield": 1.43,
      "date": "2022-05-28"
  },
  "2022-05-29": {
      "equity": 16352.45,
      "debt": 7.35,
      "gold": 50904.98245,
      "pb": 4.18,
      "pe": 20.27,
      "yield": 1.43,
      "date": "2022-05-29"
  },
  "2022-05-30": {
      "equity": 16661.4,
      "debt": 7.409,
      "gold": 50799.20634,
      "pb": 4.26,
      "pe": 20.65,
      "yield": 1.4,
      "date": "2022-05-30"
  },
  "2022-05-31": {
      "equity": 16584.55,
      "debt": 7.415,
      "gold": 50775.87259,
      "pb": 4.24,
      "pe": 20.44,
      "yield": 1.43,
      "date": "2022-05-31"
  },
  "2022-06-01": {
      "equity": 16522.75,
      "debt": 7.414,
      "gold": 50070.8337,
      "pb": 4.22,
      "pe": 20.45,
      "yield": 1.44,
      "date": "2022-06-01"
  },
  "2022-06-02": {
      "equity": 16628,
      "debt": 7.434,
      "gold": 50070.8337,
      "pb": 4.25,
      "pe": 20.58,
      "yield": 1.43,
      "date": "2022-06-02"
  },
  "2022-06-03": {
      "equity": 16584.3,
      "debt": 7.456,
      "gold": 50070.8337,
      "pb": 4.24,
      "pe": 20.52,
      "yield": 1.41,
      "date": "2022-06-03"
  },
  "2022-06-04": {
      "equity": 16584.3,
      "debt": 7.456,
      "gold": 50070.8337,
      "pb": 4.24,
      "pe": 20.52,
      "yield": 1.41,
      "date": "2022-06-04"
  },
  "2022-06-05": {
      "equity": 16584.3,
      "debt": 7.456,
      "gold": 50070.8337,
      "pb": 4.24,
      "pe": 20.52,
      "yield": 1.41,
      "date": "2022-06-05"
  },
  "2022-06-06": {
      "equity": 16569.55,
      "debt": 7.5,
      "gold": 50799.26277,
      "pb": 4.24,
      "pe": 20.5,
      "yield": 1.41,
      "date": "2022-06-06"
  },
  "2022-06-07": {
      "equity": 16416.35,
      "debt": 7.518,
      "gold": 50583.28013,
      "pb": 4.2,
      "pe": 20.31,
      "yield": 1.43,
      "date": "2022-06-07"
  },
  "2022-06-08": {
      "equity": 16356.25,
      "debt": 7.494,
      "gold": 50640.27232,
      "pb": 4.18,
      "pe": 20.24,
      "yield": 1.43,
      "date": "2022-06-08"
  },
  "2022-06-09": {
      "equity": 16478.1,
      "debt": 7.496,
      "gold": 50774.45105,
      "pb": 4.21,
      "pe": 20.39,
      "yield": 1.43,
      "date": "2022-06-09"
  },
  "2022-06-10": {
      "equity": 16201.8,
      "debt": 7.519,
      "gold": 50779.62927,
      "pb": 4.14,
      "pe": 20.05,
      "yield": 1.38,
      "date": "2022-06-10"
  },
  "2022-06-11": {
      "equity": 16201.8,
      "debt": 7.519,
      "gold": 50779.62927,
      "pb": 4.14,
      "pe": 20.05,
      "yield": 1.38,
      "date": "2022-06-11"
  },
  "2022-06-12": {
      "equity": 16201.8,
      "debt": 7.519,
      "gold": 50779.62927,
      "pb": 4.14,
      "pe": 20.05,
      "yield": 1.38,
      "date": "2022-06-12"
  },
  "2022-06-13": {
      "equity": 15774.4,
      "debt": 7.604,
      "gold": 51119.72345,
      "pb": 4.03,
      "pe": 19.52,
      "yield": 1.38,
      "date": "2022-06-13"
  },
  "2022-06-14": {
      "equity": 15732.1,
      "debt": 7.581,
      "gold": 50187.98921,
      "pb": 4.02,
      "pe": 19.47,
      "yield": 1.36,
      "date": "2022-06-14"
  },
  "2022-06-15": {
      "equity": 15692.15,
      "debt": 7.592,
      "gold": 50249.92328,
      "pb": 4.01,
      "pe": 19.42,
      "yield": 1.45,
      "date": "2022-06-15"
  },
  "2022-06-16": {
      "equity": 15360.6,
      "debt": 7.617,
      "gold": 50355.91457,
      "pb": 3.93,
      "pe": 19.01,
      "yield": 1.48,
      "date": "2022-06-16"
  },
  "2022-06-17": {
      "equity": 15293.5,
      "debt": 7.546,
      "gold": 50912.74978,
      "pb": 3.91,
      "pe": 18.92,
      "yield": 1.46,
      "date": "2022-06-17"
  },
  "2022-06-18": {
      "equity": 15293.5,
      "debt": 7.546,
      "gold": 50912.74978,
      "pb": 3.91,
      "pe": 18.92,
      "yield": 1.46,
      "date": "2022-06-18"
  },
  "2022-06-19": {
      "equity": 15293.5,
      "debt": 7.546,
      "gold": 50912.74978,
      "pb": 3.91,
      "pe": 18.92,
      "yield": 1.46,
      "date": "2022-06-19"
  },
  "2022-06-20": {
      "equity": 15350.15,
      "debt": 7.427,
      "gold": 50612.00727,
      "pb": 3.92,
      "pe": 18.99,
      "yield": 1.46,
      "date": "2022-06-20"
  },
  "2022-06-21": {
      "equity": 15638.8,
      "debt": 7.482,
      "gold": 50606.86432,
      "pb": 4,
      "pe": 19.35,
      "yield": 1.44,
      "date": "2022-06-21"
  },
  "2022-06-22": {
      "equity": 15413.3,
      "debt": 7.396,
      "gold": 50425.08686,
      "pb": 3.94,
      "pe": 19.07,
      "yield": 1.46,
      "date": "2022-06-22"
  },
  "2022-06-23": {
      "equity": 15556.65,
      "debt": 7.419,
      "gold": 50485.45124,
      "pb": 3.98,
      "pe": 19.25,
      "yield": 1.44,
      "date": "2022-06-23"
  },
  "2022-06-24": {
      "equity": 15699.25,
      "debt": 7.444,
      "gold": 50417.95799,
      "pb": 4.01,
      "pe": 19.43,
      "yield": 1.43,
      "date": "2022-06-24"
  },
  "2022-06-25": {
      "equity": 15699.25,
      "debt": 7.444,
      "gold": 50417.95799,
      "pb": 4.01,
      "pe": 19.43,
      "yield": 1.43,
      "date": "2022-06-25"
  },
  "2022-06-26": {
      "equity": 15699.25,
      "debt": 7.444,
      "gold": 50417.95799,
      "pb": 4.01,
      "pe": 19.43,
      "yield": 1.43,
      "date": "2022-06-26"
  },
  "2022-06-27": {
      "equity": 15832.05,
      "debt": 7.407,
      "gold": 50862.56195,
      "pb": 4.05,
      "pe": 19.59,
      "yield": 1.41,
      "date": "2022-06-27"
  },
  "2022-06-28": {
      "equity": 15850.2,
      "debt": 7.466,
      "gold": 50908.01249,
      "pb": 4.05,
      "pe": 19.61,
      "yield": 1.41,
      "date": "2022-06-28"
  },
  "2022-06-29": {
      "equity": 15799.1,
      "debt": 7.459,
      "gold": 50460.24092,
      "pb": 4.04,
      "pe": 19.55,
      "yield": 1.38,
      "date": "2022-06-29"
  },
  "2022-06-30": {
      "equity": 15780.25,
      "debt": 7.45,
      "gold": 50500.85539,
      "pb": 4.03,
      "pe": 19.5,
      "yield": 1.41,
      "date": "2022-06-30"
  },
  "2022-07-01": {
      "equity": 15752.05,
      "debt": 7.424,
      "gold": 49992.15859,
      "pb": 4.02,
      "pe": 19.46,
      "yield": 1.41,
      "date": "2022-07-01"
  },
  "2022-07-02": {
      "equity": 15752.05,
      "debt": 7.424,
      "gold": 49992.15859,
      "pb": 4.02,
      "pe": 19.46,
      "yield": 1.41,
      "date": "2022-07-02"
  },
  "2022-07-03": {
      "equity": 15752.05,
      "debt": 7.424,
      "gold": 49992.15859,
      "pb": 4.02,
      "pe": 19.46,
      "yield": 1.41,
      "date": "2022-07-03"
  },
  "2022-07-04": {
      "equity": 15835.35,
      "debt": 7.374,
      "gold": 50326.1151,
      "pb": 4.04,
      "pe": 19.57,
      "yield": 1.43,
      "date": "2022-07-04"
  },
  "2022-07-05": {
      "equity": 15810.85,
      "debt": 7.391,
      "gold": 50405.7038,
      "pb": 4.04,
      "pe": 19.54,
      "yield": 1.42,
      "date": "2022-07-05"
  },
  "2022-07-06": {
      "equity": 15989.8,
      "debt": 7.294,
      "gold": 49259.24619,
      "pb": 4.08,
      "pe": 19.76,
      "yield": 1.41,
      "date": "2022-07-06"
  },
  "2022-07-07": {
      "equity": 16132.9,
      "debt": 7.354,
      "gold": 48664.88298,
      "pb": 4.12,
      "pe": 19.93,
      "yield": 1.4,
      "date": "2022-07-07"
  },
  "2022-07-08": {
      "equity": 16220.6,
      "debt": 7.415,
      "gold": 48578.39468,
      "pb": 4,
      "pe": 20.04,
      "yield": 1.37,
      "date": "2022-07-08"
  },
  "2022-07-09": {
      "equity": 16220.6,
      "debt": 7.415,
      "gold": 48578.39468,
      "pb": 4,
      "pe": 20.04,
      "yield": 1.37,
      "date": "2022-07-09"
  },
  "2022-07-10": {
      "equity": 16220.6,
      "debt": 7.415,
      "gold": 48578.39468,
      "pb": 4,
      "pe": 20.04,
      "yield": 1.37,
      "date": "2022-07-10"
  },
  "2022-07-11": {
      "equity": 16216,
      "debt": 7.427,
      "gold": 48711.12013,
      "pb": 3.91,
      "pe": 20.03,
      "yield": 1.37,
      "date": "2022-07-11"
  },
  "2022-07-12": {
      "equity": 16058.3,
      "debt": 7.391,
      "gold": 48601.80956,
      "pb": 3.87,
      "pe": 19.83,
      "yield": 1.38,
      "date": "2022-07-12"
  },
  "2022-07-13": {
      "equity": 15966.65,
      "debt": 7.337,
      "gold": 48568.55324,
      "pb": 3.85,
      "pe": 19.72,
      "yield": 1.39,
      "date": "2022-07-13"
  },
  "2022-07-14": {
      "equity": 15938.65,
      "debt": 7.384,
      "gold": 48380.54287,
      "pb": 3.84,
      "pe": 19.69,
      "yield": 1.42,
      "date": "2022-07-14"
  },
  "2022-07-15": {
      "equity": 16049.2,
      "debt": 7.438,
      "gold": 47906.15708,
      "pb": 3.87,
      "pe": 19.82,
      "yield": 1.38,
      "date": "2022-07-15"
  },
  "2022-07-16": {
      "equity": 16049.2,
      "debt": 7.438,
      "gold": 47906.15708,
      "pb": 3.87,
      "pe": 19.82,
      "yield": 1.38,
      "date": "2022-07-16"
  },
  "2022-07-17": {
      "equity": 16049.2,
      "debt": 7.438,
      "gold": 47906.15708,
      "pb": 3.87,
      "pe": 19.82,
      "yield": 1.38,
      "date": "2022-07-17"
  },
  "2022-07-18": {
      "equity": 16278.5,
      "debt": 7.437,
      "gold": 48654.60414,
      "pb": 3.92,
      "pe": 20.11,
      "yield": 1.36,
      "date": "2022-07-18"
  },
  "2022-07-19": {
      "equity": 16340.55,
      "debt": 7.434,
      "gold": 48266.46678,
      "pb": 3.94,
      "pe": 20.18,
      "yield": 1.37,
      "date": "2022-07-19"
  },
  "2022-07-20": {
      "equity": 16520.85,
      "debt": 7.449,
      "gold": 48319.69171,
      "pb": 3.98,
      "pe": 20.33,
      "yield": 1.36,
      "date": "2022-07-20"
  },
  "2022-07-21": {
      "equity": 16605.25,
      "debt": 7.44,
      "gold": 47512.82386,
      "pb": 4,
      "pe": 20.43,
      "yield": 1.41,
      "date": "2022-07-21"
  },
  "2022-07-22": {
      "equity": 16719.45,
      "debt": 7.414,
      "gold": 48580.38766,
      "pb": 4.03,
      "pe": 20.55,
      "yield": 1.4,
      "date": "2022-07-22"
  },
  "2022-07-23": {
      "equity": 16719.45,
      "debt": 7.414,
      "gold": 48580.38766,
      "pb": 4.03,
      "pe": 20.55,
      "yield": 1.4,
      "date": "2022-07-23"
  },
  "2022-07-24": {
      "equity": 16719.45,
      "debt": 7.414,
      "gold": 48580.38766,
      "pb": 4.03,
      "pe": 20.55,
      "yield": 1.4,
      "date": "2022-07-24"
  },
  "2022-07-25": {
      "equity": 16631,
      "debt": 7.391,
      "gold": 48745.75213,
      "pb": 4.01,
      "pe": 20.44,
      "yield": 1.38,
      "date": "2022-07-25"
  },
  "2022-07-26": {
      "equity": 16483.85,
      "debt": 7.367,
      "gold": 48430.37443,
      "pb": 3.96,
      "pe": 20.17,
      "yield": 1.39,
      "date": "2022-07-26"
  },
  "2022-07-27": {
      "equity": 16641.8,
      "debt": 7.338,
      "gold": 48620.38131,
      "pb": 4,
      "pe": 20.19,
      "yield": 1.38,
      "date": "2022-07-27"
  },
  "2022-07-28": {
      "equity": 16929.6,
      "debt": 7.332,
      "gold": 49076.01192,
      "pb": 4.07,
      "pe": 20.49,
      "yield": 1.33,
      "date": "2022-07-28"
  },
  "2022-07-29": {
      "equity": 17158.25,
      "debt": 7.32,
      "gold": 49266.1987,
      "pb": 4.12,
      "pe": 20.73,
      "yield": 1.29,
      "date": "2022-07-29"
  },
  "2022-07-30": {
      "equity": 17158.25,
      "debt": 7.32,
      "gold": 49266.1987,
      "pb": 4.12,
      "pe": 20.73,
      "yield": 1.29,
      "date": "2022-07-30"
  },
  "2022-07-31": {
      "equity": 17158.25,
      "debt": 7.32,
      "gold": 49266.1987,
      "pb": 4.12,
      "pe": 20.73,
      "yield": 1.29,
      "date": "2022-07-31"
  },
  "2022-08-01": {
      "equity": 17340.05,
      "debt": 7.24,
      "gold": 49204.13764,
      "pb": 4.17,
      "pe": 20.9,
      "yield": 1.29,
      "date": "2022-08-01"
  },
  "2022-08-02": {
      "equity": 17345.45,
      "debt": 7.196,
      "gold": 49089.43368,
      "pb": 4.17,
      "pe": 20.86,
      "yield": 1.29,
      "date": "2022-08-02"
  },
  "2022-08-03": {
      "equity": 17388.15,
      "debt": 7.242,
      "gold": 49282.15312,
      "pb": 4.18,
      "pe": 20.85,
      "yield": 1.3,
      "date": "2022-08-03"
  },
  "2022-08-04": {
      "equity": 17382,
      "debt": 7.157,
      "gold": 49665.74366,
      "pb": 4.18,
      "pe": 20.85,
      "yield": 1.3,
      "date": "2022-08-04"
  },
  "2022-08-05": {
      "equity": 17397.5,
      "debt": 7.3,
      "gold": 50034.29337,
      "pb": 4.18,
      "pe": 20.86,
      "yield": 1.29,
      "date": "2022-08-05"
  },
  "2022-08-06": {
      "equity": 17397.5,
      "debt": 7.3,
      "gold": 50034.29337,
      "pb": 4.18,
      "pe": 20.86,
      "yield": 1.29,
      "date": "2022-08-06"
  },
  "2022-08-07": {
      "equity": 17397.5,
      "debt": 7.3,
      "gold": 50034.29337,
      "pb": 4.18,
      "pe": 20.86,
      "yield": 1.29,
      "date": "2022-08-07"
  },
  "2022-08-08": {
      "equity": 17525.1,
      "debt": 7.348,
      "gold": 49848.25482,
      "pb": 4.21,
      "pe": 21.02,
      "yield": 1.33,
      "date": "2022-08-08"
  },
  "2022-08-09": {
      "equity": 17525.1,
      "debt": 7.348,
      "gold": 50258.32202,
      "pb": 4.21,
      "pe": 21.02,
      "yield": 1.33,
      "date": "2022-08-09"
  },
  "2022-08-10": {
      "equity": 17534.75,
      "debt": 7.31,
      "gold": 50023.52775,
      "pb": 4.21,
      "pe": 21.11,
      "yield": 1.34,
      "date": "2022-08-10"
  },
  "2022-08-11": {
      "equity": 17659,
      "debt": 7.267,
      "gold": 50228.95995,
      "pb": 4.18,
      "pe": 21.29,
      "yield": 1.36,
      "date": "2022-08-11"
  },
  "2022-08-12": {
      "equity": 17698.15,
      "debt": 7.289,
      "gold": 50222.26494,
      "pb": 4.15,
      "pe": 21.18,
      "yield": 1.35,
      "date": "2022-08-12"
  },
  "2022-08-13": {
      "equity": 17698.15,
      "debt": 7.289,
      "gold": 50222.26494,
      "pb": 4.15,
      "pe": 21.18,
      "yield": 1.35,
      "date": "2022-08-13"
  },
  "2022-08-14": {
      "equity": 17698.15,
      "debt": 7.289,
      "gold": 50222.26494,
      "pb": 4.15,
      "pe": 21.18,
      "yield": 1.35,
      "date": "2022-08-14"
  },
  "2022-08-15": {
      "equity": 17698.15,
      "debt": 7.289,
      "gold": 50038.63913,
      "pb": 4.15,
      "pe": 21.18,
      "yield": 1.35,
      "date": "2022-08-15"
  },
  "2022-08-16": {
      "equity": 17825.25,
      "debt": 7.289,
      "gold": 49889.76878,
      "pb": 4.18,
      "pe": 21.34,
      "yield": 1.34,
      "date": "2022-08-16"
  },
  "2022-08-17": {
      "equity": 17944.25,
      "debt": 7.182,
      "gold": 49661.82472,
      "pb": 4.2,
      "pe": 21.42,
      "yield": 1.33,
      "date": "2022-08-17"
  },
  "2022-08-18": {
      "equity": 17956.5,
      "debt": 7.242,
      "gold": 49685.54648,
      "pb": 4.2,
      "pe": 21.44,
      "yield": 1.38,
      "date": "2022-08-18"
  },
  "2022-08-19": {
      "equity": 17758.45,
      "debt": 7.264,
      "gold": 49419.66878,
      "pb": 4.15,
      "pe": 21.2,
      "yield": 1.41,
      "date": "2022-08-19"
  },
  "2022-08-20": {
      "equity": 17758.45,
      "debt": 7.264,
      "gold": 49419.66878,
      "pb": 4.15,
      "pe": 21.2,
      "yield": 1.41,
      "date": "2022-08-20"
  },
  "2022-08-21": {
      "equity": 17758.45,
      "debt": 7.264,
      "gold": 49419.66878,
      "pb": 4.15,
      "pe": 21.2,
      "yield": 1.41,
      "date": "2022-08-21"
  },
  "2022-08-22": {
      "equity": 17490.7,
      "debt": 7.27,
      "gold": 48800.1164,
      "pb": 4.09,
      "pe": 20.88,
      "yield": 1.44,
      "date": "2022-08-22"
  },
  "2022-08-23": {
      "equity": 17577.5,
      "debt": 7.281,
      "gold": 48978.07016,
      "pb": 4.11,
      "pe": 20.98,
      "yield": 1.43,
      "date": "2022-08-23"
  },
  "2022-08-24": {
      "equity": 17604.95,
      "debt": 7.286,
      "gold": 49365.86536,
      "pb": 4.11,
      "pe": 21.02,
      "yield": 1.42,
      "date": "2022-08-24"
  },
  "2022-08-25": {
      "equity": 17522.45,
      "debt": 7.292,
      "gold": 49671.77199,
      "pb": 4.09,
      "pe": 20.92,
      "yield": 1.43,
      "date": "2022-08-25"
  },
  "2022-08-26": {
      "equity": 17558.9,
      "debt": 7.217,
      "gold": 49410.39877,
      "pb": 4.1,
      "pe": 20.96,
      "yield": 1.43,
      "date": "2022-08-26"
  },
  "2022-08-27": {
      "equity": 17558.9,
      "debt": 7.217,
      "gold": 49410.39877,
      "pb": 4.1,
      "pe": 20.96,
      "yield": 1.43,
      "date": "2022-08-27"
  },
  "2022-08-28": {
      "equity": 17558.9,
      "debt": 7.217,
      "gold": 49410.39877,
      "pb": 4.1,
      "pe": 20.96,
      "yield": 1.43,
      "date": "2022-08-28"
  },
  "2022-08-29": {
      "equity": 17312.9,
      "debt": 7.253,
      "gold": 49410.39877,
      "pb": 4.05,
      "pe": 20.67,
      "yield": 1.45,
      "date": "2022-08-29"
  },
  "2022-08-30": {
      "equity": 17759.3,
      "debt": 7.188,
      "gold": 48724.48544,
      "pb": 4.12,
      "pe": 21.2,
      "yield": 1.41,
      "date": "2022-08-30"
  },
  "2022-08-31": {
      "equity": 17759.3,
      "debt": 7.188,
      "gold": 48086.67172,
      "pb": 4.12,
      "pe": 21.2,
      "yield": 1.41,
      "date": "2022-08-31"
  },
  "2022-09-01": {
      "equity": 17542.8,
      "debt": 7.215,
      "gold": 47953.28665,
      "pb": 4.07,
      "pe": 20.94,
      "yield": 1.43,
      "date": "2022-09-01"
  },
  "2022-09-02": {
      "equity": 17539.45,
      "debt": 7.232,
      "gold": 47984.42301,
      "pb": 4.07,
      "pe": 20.94,
      "yield": 1.42,
      "date": "2022-09-02"
  },
  "2022-09-03": {
      "equity": 17539.45,
      "debt": 7.232,
      "gold": 47984.42301,
      "pb": 4.07,
      "pe": 20.94,
      "yield": 1.42,
      "date": "2022-09-03"
  },
  "2022-09-04": {
      "equity": 17539.45,
      "debt": 7.232,
      "gold": 47984.42301,
      "pb": 4.07,
      "pe": 20.94,
      "yield": 1.42,
      "date": "2022-09-04"
  },
  "2022-09-05": {
      "equity": 17665.8,
      "debt": 7.218,
      "gold": 48177.90084,
      "pb": 4.1,
      "pe": 21.09,
      "yield": 1.41,
      "date": "2022-09-05"
  },
  "2022-09-06": {
      "equity": 17655.6,
      "debt": 7.179,
      "gold": 48248.58992,
      "pb": 4.1,
      "pe": 21.08,
      "yield": 1.41,
      "date": "2022-09-06"
  },
  "2022-09-07": {
      "equity": 17624.4,
      "debt": 7.185,
      "gold": 47970.18642,
      "pb": 4.09,
      "pe": 21.04,
      "yield": 1.41,
      "date": "2022-09-07"
  },
  "2022-09-08": {
      "equity": 17798.75,
      "debt": 7.135,
      "gold": 48379.86561,
      "pb": 4.13,
      "pe": 21.25,
      "yield": 1.36,
      "date": "2022-09-08"
  },
  "2022-09-09": {
      "equity": 17833.35,
      "debt": 7.167,
      "gold": 48505.30697,
      "pb": 4.14,
      "pe": 21.29,
      "yield": 1.35,
      "date": "2022-09-09"
  },
  "2022-09-10": {
      "equity": 17833.35,
      "debt": 7.167,
      "gold": 48505.30697,
      "pb": 4.14,
      "pe": 21.29,
      "yield": 1.35,
      "date": "2022-09-10"
  },
  "2022-09-11": {
      "equity": 17833.35,
      "debt": 7.167,
      "gold": 48505.30697,
      "pb": 4.14,
      "pe": 21.29,
      "yield": 1.35,
      "date": "2022-09-11"
  },
  "2022-09-12": {
      "equity": 17936.35,
      "debt": 7.181,
      "gold": 48308.19944,
      "pb": 4.16,
      "pe": 21.41,
      "yield": 1.35,
      "date": "2022-09-12"
  },
  "2022-09-13": {
      "equity": 18070.05,
      "debt": 7.108,
      "gold": 48415.88388,
      "pb": 4.19,
      "pe": 21.57,
      "yield": 1.34,
      "date": "2022-09-13"
  },
  "2022-09-14": {
      "equity": 18003.75,
      "debt": 7.155,
      "gold": 47761.21272,
      "pb": 4.18,
      "pe": 21.49,
      "yield": 1.34,
      "date": "2022-09-14"
  },
  "2022-09-15": {
      "equity": 17877.4,
      "debt": 7.238,
      "gold": 47550.627,
      "pb": 4.15,
      "pe": 21.34,
      "yield": 1.35,
      "date": "2022-09-15"
  },
  "2022-09-16": {
      "equity": 17530.85,
      "debt": 7.266,
      "gold": 46785.85513,
      "pb": 4.07,
      "pe": 20.93,
      "yield": 1.31,
      "date": "2022-09-16"
  },
  "2022-09-17": {
      "equity": 17530.85,
      "debt": 7.266,
      "gold": 46785.85513,
      "pb": 4.07,
      "pe": 20.93,
      "yield": 1.31,
      "date": "2022-09-17"
  },
  "2022-09-18": {
      "equity": 17530.85,
      "debt": 7.266,
      "gold": 46785.85513,
      "pb": 4.07,
      "pe": 20.93,
      "yield": 1.31,
      "date": "2022-09-18"
  },
  "2022-09-19": {
      "equity": 17622.25,
      "debt": 7.277,
      "gold": 46785.85513,
      "pb": 4.09,
      "pe": 21.04,
      "yield": 1.3,
      "date": "2022-09-19"
  },
  "2022-09-20": {
      "equity": 17816.25,
      "debt": 7.294,
      "gold": 46913.70571,
      "pb": 4.13,
      "pe": 21.27,
      "yield": 1.28,
      "date": "2022-09-20"
  },
  "2022-09-21": {
      "equity": 17718.35,
      "debt": 7.233,
      "gold": 47248.08198,
      "pb": 4.11,
      "pe": 21.15,
      "yield": 1.29,
      "date": "2022-09-21"
  },
  "2022-09-22": {
      "equity": 17629.8,
      "debt": 7.312,
      "gold": 47753.88984,
      "pb": 4.09,
      "pe": 21.05,
      "yield": 1.3,
      "date": "2022-09-22"
  },
  "2022-09-23": {
      "equity": 17327.35,
      "debt": 7.393,
      "gold": 47552.29898,
      "pb": 4.02,
      "pe": 20.69,
      "yield": 1.32,
      "date": "2022-09-23"
  },
  "2022-09-24": {
      "equity": 17327.35,
      "debt": 7.393,
      "gold": 47552.29898,
      "pb": 4.02,
      "pe": 20.69,
      "yield": 1.32,
      "date": "2022-09-24"
  },
  "2022-09-25": {
      "equity": 17327.35,
      "debt": 7.393,
      "gold": 47552.29898,
      "pb": 4.02,
      "pe": 20.69,
      "yield": 1.32,
      "date": "2022-09-25"
  },
  "2022-09-26": {
      "equity": 17016.3,
      "debt": 7.359,
      "gold": 47405.73555,
      "pb": 3.95,
      "pe": 20.31,
      "yield": 1.35,
      "date": "2022-09-26"
  },
  "2022-09-27": {
      "equity": 17007.4,
      "debt": 7.292,
      "gold": 47041.59156,
      "pb": 3.95,
      "pe": 20.3,
      "yield": 1.35,
      "date": "2022-09-27"
  },
  "2022-09-28": {
      "equity": 16858.6,
      "debt": 7.333,
      "gold": 46557.31847,
      "pb": 3.91,
      "pe": 20.13,
      "yield": 1.36,
      "date": "2022-09-28"
  },
  "2022-09-29": {
      "equity": 16818.1,
      "debt": 7.34,
      "gold": 47365.59022,
      "pb": 3.9,
      "pe": 20.08,
      "yield": 1.36,
      "date": "2022-09-29"
  },
  "2022-09-30": {
      "equity": 17094.35,
      "debt": 7.398,
      "gold": 48025.58775,
      "pb": 4.02,
      "pe": 20.64,
      "yield": 1.32,
      "date": "2022-09-30"
  },
  "2022-10-01": {
      "equity": 17094.35,
      "debt": 7.398,
      "gold": 48025.58775,
      "pb": 4.02,
      "pe": 20.64,
      "yield": 1.32,
      "date": "2022-10-01"
  },
  "2022-10-02": {
      "equity": 17094.35,
      "debt": 7.398,
      "gold": 48025.58775,
      "pb": 4.02,
      "pe": 20.64,
      "yield": 1.32,
      "date": "2022-10-02"
  },
  "2022-10-03": {
      "equity": 16887.35,
      "debt": 7.468,
      "gold": 47787.5377,
      "pb": 3.97,
      "pe": 20.39,
      "yield": 1.34,
      "date": "2022-10-03"
  },
  "2022-10-04": {
      "equity": 17274.3,
      "debt": 7.362,
      "gold": 49102.60498,
      "pb": 4.06,
      "pe": 20.86,
      "yield": 1.31,
      "date": "2022-10-04"
  },
  "2022-10-05": {
      "equity": 17274.3,
      "debt": 7.362,
      "gold": 49177.86204,
      "pb": 4.06,
      "pe": 20.86,
      "yield": 1.31,
      "date": "2022-10-05"
  },
  "2022-10-06": {
      "equity": 17331.8,
      "debt": 7.454,
      "gold": 49770.32399,
      "pb": 4.07,
      "pe": 20.93,
      "yield": 1.31,
      "date": "2022-10-06"
  },
  "2022-10-07": {
      "equity": 17314.65,
      "debt": 7.46,
      "gold": 49877.97316,
      "pb": 4.07,
      "pe": 20.9,
      "yield": 1.31,
      "date": "2022-10-07"
  },
  "2022-10-08": {
      "equity": 17314.65,
      "debt": 7.46,
      "gold": 49877.97316,
      "pb": 4.07,
      "pe": 20.9,
      "yield": 1.31,
      "date": "2022-10-08"
  },
  "2022-10-09": {
      "equity": 17314.65,
      "debt": 7.46,
      "gold": 49877.97316,
      "pb": 4.07,
      "pe": 20.9,
      "yield": 1.31,
      "date": "2022-10-09"
  },
  "2022-10-10": {
      "equity": 17241,
      "debt": 7.476,
      "gold": 48844.98139,
      "pb": 4.05,
      "pe": 20.82,
      "yield": 1.31,
      "date": "2022-10-10"
  },
  "2022-10-11": {
      "equity": 16983.55,
      "debt": 7.426,
      "gold": 48249.9903,
      "pb": 3.99,
      "pe": 20.51,
      "yield": 1.33,
      "date": "2022-10-11"
  },
  "2022-10-12": {
      "equity": 17123.6,
      "debt": 7.435,
      "gold": 48523.03921,
      "pb": 4.02,
      "pe": 20.67,
      "yield": 1.32,
      "date": "2022-10-12"
  },
  "2022-10-13": {
      "equity": 17014.35,
      "debt": 7.422,
      "gold": 48629.23156,
      "pb": 4,
      "pe": 20.53,
      "yield": 1.33,
      "date": "2022-10-13"
  },
  "2022-10-14": {
      "equity": 17185.7,
      "debt": 7.47,
      "gold": 48100.61553,
      "pb": 4.04,
      "pe": 20.74,
      "yield": 1.31,
      "date": "2022-10-14"
  },
  "2022-10-15": {
      "equity": 17185.7,
      "debt": 7.47,
      "gold": 48100.61553,
      "pb": 4.04,
      "pe": 20.74,
      "yield": 1.31,
      "date": "2022-10-15"
  },
  "2022-10-16": {
      "equity": 17185.7,
      "debt": 7.47,
      "gold": 48100.61553,
      "pb": 4.04,
      "pe": 20.74,
      "yield": 1.31,
      "date": "2022-10-16"
  },
  "2022-10-17": {
      "equity": 17311.8,
      "debt": 7.407,
      "gold": 48026.64597,
      "pb": 4.07,
      "pe": 20.86,
      "yield": 1.31,
      "date": "2022-10-17"
  },
  "2022-10-18": {
      "equity": 17486.95,
      "debt": 7.426,
      "gold": 47952.2884,
      "pb": 4.11,
      "pe": 20.99,
      "yield": 1.3,
      "date": "2022-10-18"
  },
  "2022-10-19": {
      "equity": 17512.25,
      "debt": 7.451,
      "gold": 48046.15602,
      "pb": 4.12,
      "pe": 21.02,
      "yield": 1.31,
      "date": "2022-10-19"
  },
  "2022-10-20": {
      "equity": 17563.95,
      "debt": 7.476,
      "gold": 47662.39969,
      "pb": 4.13,
      "pe": 21.06,
      "yield": 1.3,
      "date": "2022-10-20"
  },
  "2022-10-21": {
      "equity": 17576.3,
      "debt": 7.511,
      "gold": 47350.306,
      "pb": 4.13,
      "pe": 21.07,
      "yield": 1.29,
      "date": "2022-10-21"
  },
  "2022-10-22": {
      "equity": 17576.3,
      "debt": 7.511,
      "gold": 47350.306,
      "pb": 4.13,
      "pe": 21.07,
      "yield": 1.29,
      "date": "2022-10-22"
  },
  "2022-10-23": {
      "equity": 17576.3,
      "debt": 7.511,
      "gold": 47350.306,
      "pb": 4.13,
      "pe": 21.07,
      "yield": 1.29,
      "date": "2022-10-23"
  },
  "2022-10-24": {
      "equity": 17730.75,
      "debt": 7.511,
      "gold": 48119.56472,
      "pb": 4.17,
      "pe": 21.26,
      "yield": 1.28,
      "date": "2022-10-24"
  },
  "2022-10-25": {
      "equity": 17656.35,
      "debt": 7.442,
      "gold": 47785.46712,
      "pb": 4.15,
      "pe": 21,
      "yield": 1.28,
      "date": "2022-10-25"
  },
  "2022-10-26": {
      "equity": 17656.35,
      "debt": 7.442,
      "gold": 48627.03046,
      "pb": 4.15,
      "pe": 21,
      "yield": 1.28,
      "date": "2022-10-26"
  },
  "2022-10-27": {
      "equity": 17736.95,
      "debt": 7.412,
      "gold": 48325.55424,
      "pb": 4.17,
      "pe": 21.1,
      "yield": 1.26,
      "date": "2022-10-27"
  },
  "2022-10-28": {
      "equity": 17786.8,
      "debt": 7.416,
      "gold": 47903.55738,
      "pb": 4.18,
      "pe": 21.15,
      "yield": 1.26,
      "date": "2022-10-28"
  },
  "2022-10-29": {
      "equity": 17786.8,
      "debt": 7.416,
      "gold": 47903.55738,
      "pb": 4.18,
      "pe": 21.15,
      "yield": 1.26,
      "date": "2022-10-29"
  },
  "2022-10-30": {
      "equity": 17786.8,
      "debt": 7.416,
      "gold": 47903.55738,
      "pb": 4.18,
      "pe": 21.15,
      "yield": 1.26,
      "date": "2022-10-30"
  },
  "2022-10-31": {
      "equity": 18012.2,
      "debt": 7.445,
      "gold": 47883.87802,
      "pb": 4.23,
      "pe": 21.42,
      "yield": 1.25,
      "date": "2022-10-31"
  },
  "2022-11-01": {
      "equity": 18145.4,
      "debt": 7.396,
      "gold": 48155.63237,
      "pb": 4.26,
      "pe": 21.98,
      "yield": 1.26,
      "date": "2022-11-01"
  },
  "2022-11-02": {
      "equity": 18082.85,
      "debt": 7.404,
      "gold": 48332.7325,
      "pb": 4.25,
      "pe": 21.82,
      "yield": 1.25,
      "date": "2022-11-02"
  },
  "2022-11-03": {
      "equity": 18052.7,
      "debt": 7.482,
      "gold": 47306.66502,
      "pb": 4.24,
      "pe": 21.79,
      "yield": 1.25,
      "date": "2022-11-03"
  },
  "2022-11-04": {
      "equity": 18117.15,
      "debt": 7.469,
      "gold": 47719.79047,
      "pb": 4.26,
      "pe": 21.77,
      "yield": 1.25,
      "date": "2022-11-04"
  },
  "2022-11-05": {
      "equity": 18117.15,
      "debt": 7.469,
      "gold": 47719.79047,
      "pb": 4.26,
      "pe": 21.77,
      "yield": 1.25,
      "date": "2022-11-05"
  },
  "2022-11-06": {
      "equity": 18117.15,
      "debt": 7.469,
      "gold": 47719.79047,
      "pb": 4.26,
      "pe": 21.77,
      "yield": 1.25,
      "date": "2022-11-06"
  },
  "2022-11-07": {
      "equity": 18202.8,
      "debt": 7.434,
      "gold": 48507.41988,
      "pb": 4.28,
      "pe": 21.71,
      "yield": 1.24,
      "date": "2022-11-07"
  },
  "2022-11-08": {
      "equity": 18202.8,
      "debt": 7.434,
      "gold": 48245.24595,
      "pb": 4.28,
      "pe": 21.71,
      "yield": 1.24,
      "date": "2022-11-08"
  },
  "2022-11-09": {
      "equity": 18157,
      "debt": 7.387,
      "gold": 48892.65419,
      "pb": 4.27,
      "pe": 21.66,
      "yield": 1.26,
      "date": "2022-11-09"
  },
  "2022-11-10": {
      "equity": 18028.2,
      "debt": 7.346,
      "gold": 48627.10453,
      "pb": 4.24,
      "pe": 21.53,
      "yield": 1.27,
      "date": "2022-11-10"
  },
  "2022-11-11": {
      "equity": 18349.7,
      "debt": 7.307,
      "gold": 50123.82934,
      "pb": 4.31,
      "pe": 21.92,
      "yield": 1.24,
      "date": "2022-11-11"
  },
  "2022-11-12": {
      "equity": 18349.7,
      "debt": 7.307,
      "gold": 50123.82934,
      "pb": 4.31,
      "pe": 21.92,
      "yield": 1.24,
      "date": "2022-11-12"
  },
  "2022-11-13": {
      "equity": 18349.7,
      "debt": 7.307,
      "gold": 50123.82934,
      "pb": 4.31,
      "pe": 21.92,
      "yield": 1.24,
      "date": "2022-11-13"
  },
  "2022-11-14": {
      "equity": 18329.15,
      "debt": 7.287,
      "gold": 50181.13194,
      "pb": 4.31,
      "pe": 21.79,
      "yield": 1.26,
      "date": "2022-11-14"
  },
  "2022-11-15": {
      "equity": 18403.4,
      "debt": 7.261,
      "gold": 50670.76315,
      "pb": 4.32,
      "pe": 21.89,
      "yield": 1.3,
      "date": "2022-11-15"
  },
  "2022-11-16": {
      "equity": 18409.65,
      "debt": 7.274,
      "gold": 51231.51731,
      "pb": 4.32,
      "pe": 21.91,
      "yield": 1.3,
      "date": "2022-11-16"
  },
  "2022-11-17": {
      "equity": 18343.9,
      "debt": 7.281,
      "gold": 50812.46583,
      "pb": 4.31,
      "pe": 21.83,
      "yield": 1.3,
      "date": "2022-11-17"
  },
  "2022-11-18": {
      "equity": 18307.65,
      "debt": 7.307,
      "gold": 50793.32616,
      "pb": 4.3,
      "pe": 21.79,
      "yield": 1.3,
      "date": "2022-11-18"
  },
  "2022-11-19": {
      "equity": 18307.65,
      "debt": 7.307,
      "gold": 50793.32616,
      "pb": 4.3,
      "pe": 21.79,
      "yield": 1.3,
      "date": "2022-11-19"
  },
  "2022-11-20": {
      "equity": 18307.65,
      "debt": 7.307,
      "gold": 50793.32616,
      "pb": 4.3,
      "pe": 21.79,
      "yield": 1.3,
      "date": "2022-11-20"
  },
  "2022-11-21": {
      "equity": 18159.95,
      "debt": 7.32,
      "gold": 50141.33935,
      "pb": 4.26,
      "pe": 21.82,
      "yield": 1.35,
      "date": "2022-11-21"
  },
  "2022-11-22": {
      "equity": 18244.2,
      "debt": 7.285,
      "gold": 50343.51223,
      "pb": 4.28,
      "pe": 21.93,
      "yield": 1.32,
      "date": "2022-11-22"
  },
  "2022-11-23": {
      "equity": 18267.25,
      "debt": 7.291,
      "gold": 50056.0539,
      "pb": 4.29,
      "pe": 21.95,
      "yield": 1.31,
      "date": "2022-11-23"
  },
  "2022-11-24": {
      "equity": 18484.1,
      "debt": 7.255,
      "gold": 50583.60465,
      "pb": 4.34,
      "pe": 22.21,
      "yield": 1.3,
      "date": "2022-11-24"
  },
  "2022-11-25": {
      "equity": 18512.75,
      "debt": 7.301,
      "gold": 50528.79945,
      "pb": 4.35,
      "pe": 22.25,
      "yield": 1.3,
      "date": "2022-11-25"
  },
  "2022-11-26": {
      "equity": 18512.75,
      "debt": 7.301,
      "gold": 50528.79945,
      "pb": 4.35,
      "pe": 22.25,
      "yield": 1.3,
      "date": "2022-11-26"
  },
  "2022-11-27": {
      "equity": 18512.75,
      "debt": 7.301,
      "gold": 50528.79945,
      "pb": 4.35,
      "pe": 22.25,
      "yield": 1.3,
      "date": "2022-11-27"
  },
  "2022-11-28": {
      "equity": 18562.75,
      "debt": 7.274,
      "gold": 50765.44913,
      "pb": 4.36,
      "pe": 22.31,
      "yield": 1.29,
      "date": "2022-11-28"
  },
  "2022-11-29": {
      "equity": 18618.05,
      "debt": 7.279,
      "gold": 50535.34278,
      "pb": 4.37,
      "pe": 22.37,
      "yield": 1.29,
      "date": "2022-11-29"
  },
  "2022-11-30": {
      "equity": 18758.35,
      "debt": 7.28,
      "gold": 50584.77927,
      "pb": 4.4,
      "pe": 22.54,
      "yield": 1.28,
      "date": "2022-11-30"
  },
  "2022-12-01": {
      "equity": 18812.5,
      "debt": 7.21,
      "gold": 50918.48533,
      "pb": 4.42,
      "pe": 22.61,
      "yield": 1.28,
      "date": "2022-12-01"
  },
  "2022-12-02": {
      "equity": 18696.1,
      "debt": 7.221,
      "gold": 51702.76372,
      "pb": 4.39,
      "pe": 22.47,
      "yield": 1.28,
      "date": "2022-12-02"
  },
  "2022-12-03": {
      "equity": 18696.1,
      "debt": 7.221,
      "gold": 51702.76372,
      "pb": 4.39,
      "pe": 22.47,
      "yield": 1.28,
      "date": "2022-12-03"
  },
  "2022-12-04": {
      "equity": 18696.1,
      "debt": 7.221,
      "gold": 51702.76372,
      "pb": 4.39,
      "pe": 22.47,
      "yield": 1.28,
      "date": "2022-12-04"
  },
  "2022-12-05": {
      "equity": 18701.05,
      "debt": 7.226,
      "gold": 51795.22037,
      "pb": 4.39,
      "pe": 22.47,
      "yield": 1.28,
      "date": "2022-12-05"
  },
  "2022-12-06": {
      "equity": 18642.75,
      "debt": 7.249,
      "gold": 51596.01051,
      "pb": 4.38,
      "pe": 22.4,
      "yield": 1.26,
      "date": "2022-12-06"
  },
  "2022-12-07": {
      "equity": 18560.5,
      "debt": 7.269,
      "gold": 51464.86887,
      "pb": 4.36,
      "pe": 22.31,
      "yield": 1.27,
      "date": "2022-12-07"
  },
  "2022-12-08": {
      "equity": 18609.35,
      "debt": 7.288,
      "gold": 51747.91795,
      "pb": 4.37,
      "pe": 22.36,
      "yield": 1.27,
      "date": "2022-12-08"
  },
  "2022-12-09": {
      "equity": 18496.6,
      "debt": 7.299,
      "gold": 52136.74315,
      "pb": 4.34,
      "pe": 22.23,
      "yield": 1.27,
      "date": "2022-12-09"
  },
  "2022-12-10": {
      "equity": 18496.6,
      "debt": 7.299,
      "gold": 52136.74315,
      "pb": 4.34,
      "pe": 22.23,
      "yield": 1.27,
      "date": "2022-12-10"
  },
  "2022-12-11": {
      "equity": 18496.6,
      "debt": 7.299,
      "gold": 52136.74315,
      "pb": 4.34,
      "pe": 22.23,
      "yield": 1.27,
      "date": "2022-12-11"
  },
  "2022-12-12": {
      "equity": 18497.15,
      "debt": 7.294,
      "gold": 52225.49251,
      "pb": 4.34,
      "pe": 22.23,
      "yield": 1.27,
      "date": "2022-12-12"
  },
  "2022-12-13": {
      "equity": 18608,
      "debt": 7.265,
      "gold": 51968.80721,
      "pb": 4.37,
      "pe": 22.36,
      "yield": 1.27,
      "date": "2022-12-13"
  },
  "2022-12-14": {
      "equity": 18660.3,
      "debt": 7.221,
      "gold": 52626.24385,
      "pb": 4.38,
      "pe": 22.43,
      "yield": 1.26,
      "date": "2022-12-14"
  },
  "2022-12-15": {
      "equity": 18414.9,
      "debt": 7.268,
      "gold": 51919.10616,
      "pb": 4.32,
      "pe": 22.13,
      "yield": 1.28,
      "date": "2022-12-15"
  },
  "2022-12-16": {
      "equity": 18269,
      "debt": 7.281,
      "gold": 51982.86742,
      "pb": 4.29,
      "pe": 21.96,
      "yield": 1.29,
      "date": "2022-12-16"
  },
  "2022-12-17": {
      "equity": 18269,
      "debt": 7.281,
      "gold": 51982.86742,
      "pb": 4.29,
      "pe": 21.96,
      "yield": 1.29,
      "date": "2022-12-17"
  },
  "2022-12-18": {
      "equity": 18269,
      "debt": 7.281,
      "gold": 51982.86742,
      "pb": 4.29,
      "pe": 21.96,
      "yield": 1.29,
      "date": "2022-12-18"
  },
  "2022-12-19": {
      "equity": 18420.45,
      "debt": 7.297,
      "gold": 52355.74878,
      "pb": 4.32,
      "pe": 22.14,
      "yield": 1.28,
      "date": "2022-12-19"
  },
  "2022-12-20": {
      "equity": 18385.3,
      "debt": 7.299,
      "gold": 52649.18253,
      "pb": 4.32,
      "pe": 22.1,
      "yield": 1.28,
      "date": "2022-12-20"
  },
  "2022-12-21": {
      "equity": 18199.1,
      "debt": 7.287,
      "gold": 53030.74481,
      "pb": 4.27,
      "pe": 21.87,
      "yield": 1.29,
      "date": "2022-12-21"
  },
  "2022-12-22": {
      "equity": 18127.35,
      "debt": 7.316,
      "gold": 53119.13085,
      "pb": 4.26,
      "pe": 21.79,
      "yield": 1.27,
      "date": "2022-12-22"
  },
  "2022-12-23": {
      "equity": 17806.8,
      "debt": 7.318,
      "gold": 52506.56273,
      "pb": 4.18,
      "pe": 21.4,
      "yield": 1.29,
      "date": "2022-12-23"
  },
  "2022-12-24": {
      "equity": 17806.8,
      "debt": 7.318,
      "gold": 52506.56273,
      "pb": 4.18,
      "pe": 21.4,
      "yield": 1.29,
      "date": "2022-12-24"
  },
  "2022-12-25": {
      "equity": 17806.8,
      "debt": 7.318,
      "gold": 52506.56273,
      "pb": 4.18,
      "pe": 21.4,
      "yield": 1.29,
      "date": "2022-12-25"
  },
  "2022-12-26": {
      "equity": 18014.6,
      "debt": 7.319,
      "gold": 52506.56273,
      "pb": 4.23,
      "pe": 21.65,
      "yield": 1.28,
      "date": "2022-12-26"
  },
  "2022-12-27": {
      "equity": 18132.3,
      "debt": 7.308,
      "gold": 52506.56273,
      "pb": 4.26,
      "pe": 21.79,
      "yield": 1.27,
      "date": "2022-12-27"
  },
  "2022-12-28": {
      "equity": 18122.5,
      "debt": 7.314,
      "gold": 52629.02344,
      "pb": 4.25,
      "pe": 21.78,
      "yield": 1.27,
      "date": "2022-12-28"
  },
  "2022-12-29": {
      "equity": 18191,
      "debt": 7.311,
      "gold": 52750.13316,
      "pb": 4.27,
      "pe": 21.86,
      "yield": 1.27,
      "date": "2022-12-29"
  },
  "2022-12-30": {
      "equity": 18105.3,
      "debt": 7.327,
      "gold": 52870.08942,
      "pb": 4.25,
      "pe": 21.79,
      "yield": 1.27,
      "date": "2022-12-30"
  },
  "2022-12-31": {
      "equity": 18105.3,
      "debt": 7.327,
      "gold": 52870.08942,
      "pb": 4.25,
      "pe": 21.79,
      "yield": 1.27,
      "date": "2022-12-31"
  },
  "2023-01-01": {
      "equity": 18105.3,
      "debt": 7.327,
      "gold": 52870.08942,
      "pb": 4.25,
      "pe": 21.79,
      "yield": 1.27,
      "date": "2023-01-01"
  },
  "2023-01-02": {
      "equity": 18197.45,
      "debt": 7.34,
      "gold": 52870.08942,
      "pb": 4.27,
      "pe": 21.9,
      "yield": 1.26,
      "date": "2023-01-02"
  },
  "2023-01-03": {
      "equity": 18232.55,
      "debt": 7.321,
      "gold": 53611.91555,
      "pb": 4.28,
      "pe": 21.94,
      "yield": 1.26,
      "date": "2023-01-03"
  },
  "2023-01-04": {
      "equity": 18042.95,
      "debt": 7.321,
      "gold": 54176.54985,
      "pb": 4.23,
      "pe": 21.71,
      "yield": 1.27,
      "date": "2023-01-04"
  },
  "2023-01-05": {
      "equity": 17992.15,
      "debt": 7.327,
      "gold": 53906.53098,
      "pb": 4.22,
      "pe": 21.65,
      "yield": 1.28,
      "date": "2023-01-05"
  },
  "2023-01-06": {
      "equity": 17859.45,
      "debt": 7.373,
      "gold": 53248.02907,
      "pb": 4.19,
      "pe": 21.49,
      "yield": 1.29,
      "date": "2023-01-06"
  },
  "2023-01-07": {
      "equity": 17859.45,
      "debt": 7.373,
      "gold": 53248.02907,
      "pb": 4.19,
      "pe": 21.49,
      "yield": 1.29,
      "date": "2023-01-07"
  },
  "2023-01-08": {
      "equity": 17859.45,
      "debt": 7.373,
      "gold": 53248.02907,
      "pb": 4.19,
      "pe": 21.49,
      "yield": 1.29,
      "date": "2023-01-08"
  },
  "2023-01-09": {
      "equity": 18101.2,
      "debt": 7.342,
      "gold": 54292.57659,
      "pb": 4.25,
      "pe": 21.78,
      "yield": 1.27,
      "date": "2023-01-09"
  },
  "2023-01-10": {
      "equity": 17914.15,
      "debt": 7.313,
      "gold": 54012.86442,
      "pb": 4.2,
      "pe": 21.56,
      "yield": 1.28,
      "date": "2023-01-10"
  },
  "2023-01-11": {
      "equity": 17895.7,
      "debt": 7.291,
      "gold": 54265.96236,
      "pb": 4.2,
      "pe": 21.52,
      "yield": 1.28,
      "date": "2023-01-11"
  },
  "2023-01-12": {
      "equity": 17858.2,
      "debt": 7.286,
      "gold": 53916.52763,
      "pb": 4.19,
      "pe": 21.47,
      "yield": 1.29,
      "date": "2023-01-12"
  },
  "2023-01-13": {
      "equity": 17956.6,
      "debt": 7.299,
      "gold": 54587.42482,
      "pb": 4.21,
      "pe": 21.54,
      "yield": 1.28,
      "date": "2023-01-13"
  },
  "2023-01-14": {
      "equity": 17956.6,
      "debt": 7.299,
      "gold": 54587.42482,
      "pb": 4.21,
      "pe": 21.54,
      "yield": 1.28,
      "date": "2023-01-14"
  },
  "2023-01-15": {
      "equity": 17956.6,
      "debt": 7.299,
      "gold": 54587.42482,
      "pb": 4.21,
      "pe": 21.54,
      "yield": 1.28,
      "date": "2023-01-15"
  },
  "2023-01-16": {
      "equity": 17894.85,
      "debt": 7.327,
      "gold": 55165.91827,
      "pb": 4.19,
      "pe": 21.34,
      "yield": 1.38,
      "date": "2023-01-16"
  },
  "2023-01-17": {
      "equity": 18053.3,
      "debt": 7.333,
      "gold": 54808.42696,
      "pb": 4.23,
      "pe": 21.53,
      "yield": 1.37,
      "date": "2023-01-17"
  },
  "2023-01-18": {
      "equity": 18165.35,
      "debt": 7.325,
      "gold": 54927.1839,
      "pb": 4.26,
      "pe": 21.67,
      "yield": 1.36,
      "date": "2023-01-18"
  },
  "2023-01-19": {
      "equity": 18107.85,
      "debt": 7.313,
      "gold": 54672.87606,
      "pb": 4.24,
      "pe": 21.56,
      "yield": 1.37,
      "date": "2023-01-19"
  },
  "2023-01-20": {
      "equity": 18027.65,
      "debt": 7.347,
      "gold": 55132.26688,
      "pb": 4.23,
      "pe": 21.46,
      "yield": 1.36,
      "date": "2023-01-20"
  },
  "2023-01-21": {
      "equity": 18027.65,
      "debt": 7.347,
      "gold": 55132.26688,
      "pb": 4.23,
      "pe": 21.46,
      "yield": 1.36,
      "date": "2023-01-21"
  },
  "2023-01-22": {
      "equity": 18027.65,
      "debt": 7.347,
      "gold": 55132.26688,
      "pb": 4.23,
      "pe": 21.46,
      "yield": 1.36,
      "date": "2023-01-22"
  },
  "2023-01-23": {
      "equity": 18118.55,
      "debt": 7.352,
      "gold": 55370.41923,
      "pb": 4.25,
      "pe": 21.6,
      "yield": 1.35,
      "date": "2023-01-23"
  },
  "2023-01-24": {
      "equity": 18118.3,
      "debt": 7.346,
      "gold": 55734.06938,
      "pb": 4.25,
      "pe": 21.49,
      "yield": 1.36,
      "date": "2023-01-24"
  },
  "2023-01-25": {
      "equity": 17891.95,
      "debt": 7.347,
      "gold": 55351.33247,
      "pb": 4.19,
      "pe": 21.19,
      "yield": 1.37,
      "date": "2023-01-25"
  },
  "2023-01-26": {
      "equity": 17891.95,
      "debt": 7.347,
      "gold": 55654.96393,
      "pb": 4.19,
      "pe": 21.19,
      "yield": 1.37,
      "date": "2023-01-26"
  },
  "2023-01-27": {
      "equity": 17604.35,
      "debt": 7.387,
      "gold": 55467.9236,
      "pb": 4.13,
      "pe": 20.7,
      "yield": 1.4,
      "date": "2023-01-27"
  },
  "2023-01-28": {
      "equity": 17604.35,
      "debt": 7.387,
      "gold": 55467.9236,
      "pb": 4.13,
      "pe": 20.7,
      "yield": 1.4,
      "date": "2023-01-28"
  },
  "2023-01-29": {
      "equity": 17604.35,
      "debt": 7.387,
      "gold": 55467.9236,
      "pb": 4.13,
      "pe": 20.7,
      "yield": 1.4,
      "date": "2023-01-29"
  },
  "2023-01-30": {
      "equity": 17648.95,
      "debt": 7.401,
      "gold": 55423.41488,
      "pb": 4.14,
      "pe": 20.73,
      "yield": 1.39,
      "date": "2023-01-30"
  },
  "2023-01-31": {
      "equity": 17662.15,
      "debt": 7.343,
      "gold": 54944.65158,
      "pb": 4.14,
      "pe": 20.73,
      "yield": 1.39,
      "date": "2023-01-31"
  },
  "2023-02-01": {
      "equity": 17616.3,
      "debt": 7.277,
      "gold": 55704.48156,
      "pb": 4.13,
      "pe": 20.68,
      "yield": 1.4,
      "date": "2023-02-01"
  },
  "2023-02-02": {
      "equity": 17610.4,
      "debt": 7.296,
      "gold": 56596.00698,
      "pb": 4.13,
      "pe": 20.59,
      "yield": 1.4,
      "date": "2023-02-02"
  },
  "2023-02-03": {
      "equity": 17854.05,
      "debt": 7.277,
      "gold": 55458.48428,
      "pb": 4.18,
      "pe": 20.86,
      "yield": 1.38,
      "date": "2023-02-03"
  },
  "2023-02-04": {
      "equity": 17854.05,
      "debt": 7.277,
      "gold": 55458.48428,
      "pb": 4.18,
      "pe": 20.86,
      "yield": 1.38,
      "date": "2023-02-04"
  },
  "2023-02-05": {
      "equity": 17854.05,
      "debt": 7.277,
      "gold": 55458.48428,
      "pb": 4.18,
      "pe": 20.86,
      "yield": 1.38,
      "date": "2023-02-05"
  },
  "2023-02-06": {
      "equity": 17764.6,
      "debt": 7.32,
      "gold": 54735.25106,
      "pb": 4.16,
      "pe": 20.71,
      "yield": 1.39,
      "date": "2023-02-06"
  },
  "2023-02-07": {
      "equity": 17721.5,
      "debt": 7.309,
      "gold": 54628.67775,
      "pb": 4.15,
      "pe": 20.5,
      "yield": 1.39,
      "date": "2023-02-07"
  },
  "2023-02-08": {
      "equity": 17871.7,
      "debt": 7.344,
      "gold": 54826.85409,
      "pb": 4.19,
      "pe": 21.1,
      "yield": 1.42,
      "date": "2023-02-08"
  },
  "2023-02-09": {
      "equity": 17893.45,
      "debt": 7.344,
      "gold": 54747.50525,
      "pb": 4.19,
      "pe": 21.11,
      "yield": 1.41,
      "date": "2023-02-09"
  },
  "2023-02-10": {
      "equity": 17856.5,
      "debt": 7.363,
      "gold": 54271.58151,
      "pb": 4.18,
      "pe": 21.06,
      "yield": 1.41,
      "date": "2023-02-10"
  },
  "2023-02-11": {
      "equity": 17856.5,
      "debt": 7.363,
      "gold": 54271.58151,
      "pb": 4.18,
      "pe": 21.06,
      "yield": 1.41,
      "date": "2023-02-11"
  },
  "2023-02-12": {
      "equity": 17856.5,
      "debt": 7.363,
      "gold": 54271.58151,
      "pb": 4.18,
      "pe": 21.06,
      "yield": 1.41,
      "date": "2023-02-12"
  },
  "2023-02-13": {
      "equity": 17770.9,
      "debt": 7.366,
      "gold": 54171.8055,
      "pb": 4.16,
      "pe": 21.05,
      "yield": 1.42,
      "date": "2023-02-13"
  },
  "2023-02-14": {
      "equity": 17929.85,
      "debt": 7.371,
      "gold": 54439.12591,
      "pb": 4.2,
      "pe": 21.21,
      "yield": 1.35,
      "date": "2023-02-14"
  },
  "2023-02-15": {
      "equity": 18015.85,
      "debt": 7.347,
      "gold": 53584.43006,
      "pb": 4.22,
      "pe": 21.31,
      "yield": 1.41,
      "date": "2023-02-15"
  },
  "2023-02-16": {
      "equity": 18035.85,
      "debt": 7.342,
      "gold": 53593.39671,
      "pb": 4.23,
      "pe": 21.26,
      "yield": 1.38,
      "date": "2023-02-16"
  },
  "2023-02-17": {
      "equity": 17944.2,
      "debt": 7.388,
      "gold": 53261.15099,
      "pb": 4.2,
      "pe": 21.15,
      "yield": 1.4,
      "date": "2023-02-17"
  },
  "2023-02-18": {
      "equity": 17944.2,
      "debt": 7.388,
      "gold": 53261.15099,
      "pb": 4.2,
      "pe": 21.15,
      "yield": 1.4,
      "date": "2023-02-18"
  },
  "2023-02-19": {
      "equity": 17944.2,
      "debt": 7.388,
      "gold": 53261.15099,
      "pb": 4.2,
      "pe": 21.15,
      "yield": 1.4,
      "date": "2023-02-19"
  },
  "2023-02-20": {
      "equity": 17844.6,
      "debt": 7.37,
      "gold": 53811.58398,
      "pb": 4.18,
      "pe": 21.04,
      "yield": 1.41,
      "date": "2023-02-20"
  },
  "2023-02-21": {
      "equity": 17826.7,
      "debt": 7.391,
      "gold": 53584.89568,
      "pb": 4.18,
      "pe": 21.01,
      "yield": 1.38,
      "date": "2023-02-21"
  },
  "2023-02-22": {
      "equity": 17554.3,
      "debt": 7.429,
      "gold": 53560.18977,
      "pb": 4.11,
      "pe": 20.69,
      "yield": 1.4,
      "date": "2023-02-22"
  },
  "2023-02-23": {
      "equity": 17511.25,
      "debt": 7.391,
      "gold": 53276.67155,
      "pb": 4.1,
      "pe": 20.64,
      "yield": 1.4,
      "date": "2023-02-23"
  },
  "2023-02-24": {
      "equity": 17465.8,
      "debt": 7.419,
      "gold": 53398.04229,
      "pb": 4.09,
      "pe": 20.58,
      "yield": 1.42,
      "date": "2023-02-24"
  },
  "2023-02-25": {
      "equity": 17465.8,
      "debt": 7.419,
      "gold": 53398.04229,
      "pb": 4.09,
      "pe": 20.58,
      "yield": 1.42,
      "date": "2023-02-25"
  },
  "2023-02-26": {
      "equity": 17465.8,
      "debt": 7.419,
      "gold": 53398.04229,
      "pb": 4.09,
      "pe": 20.58,
      "yield": 1.42,
      "date": "2023-02-26"
  },
  "2023-02-27": {
      "equity": 17392.7,
      "debt": 7.448,
      "gold": 52773.50218,
      "pb": 4.07,
      "pe": 20.49,
      "yield": 1.43,
      "date": "2023-02-27"
  },
  "2023-02-28": {
      "equity": 17303.95,
      "debt": 7.457,
      "gold": 52763.63252,
      "pb": 4.05,
      "pe": 20.39,
      "yield": 1.44,
      "date": "2023-02-28"
  },
  "2023-03-01": {
      "equity": 17450.9,
      "debt": 7.434,
      "gold": 53276.56572,
      "pb": 4.09,
      "pe": 20.56,
      "yield": 1.43,
      "date": "2023-03-01"
  },
  "2023-03-02": {
      "equity": 17321.9,
      "debt": 7.43,
      "gold": 53231.52789,
      "pb": 4.06,
      "pe": 20.41,
      "yield": 1.44,
      "date": "2023-03-02"
  },
  "2023-03-03": {
      "equity": 17594.35,
      "debt": 7.416,
      "gold": 53177.64687,
      "pb": 4.12,
      "pe": 20.73,
      "yield": 1.41,
      "date": "2023-03-03"
  },
  "2023-03-04": {
      "equity": 17594.35,
      "debt": 7.416,
      "gold": 53177.64687,
      "pb": 4.12,
      "pe": 20.73,
      "yield": 1.41,
      "date": "2023-03-04"
  },
  "2023-03-05": {
      "equity": 17594.35,
      "debt": 7.416,
      "gold": 53177.64687,
      "pb": 4.12,
      "pe": 20.73,
      "yield": 1.41,
      "date": "2023-03-05"
  },
  "2023-03-06": {
      "equity": 17711.45,
      "debt": 7.418,
      "gold": 53418.73049,
      "pb": 4.15,
      "pe": 20.87,
      "yield": 1.41,
      "date": "2023-03-06"
  },
  "2023-03-07": {
      "equity": 17711.45,
      "debt": 7.418,
      "gold": 53170.49331,
      "pb": 4.15,
      "pe": 20.87,
      "yield": 1.41,
      "date": "2023-03-07"
  },
  "2023-03-08": {
      "equity": 17754.4,
      "debt": 7.459,
      "gold": 52410.25768,
      "pb": 4.16,
      "pe": 20.92,
      "yield": 1.4,
      "date": "2023-03-08"
  },
  "2023-03-09": {
      "equity": 17589.6,
      "debt": 7.441,
      "gold": 52594.8888,
      "pb": 4.12,
      "pe": 20.73,
      "yield": 1.41,
      "date": "2023-03-09"
  },
  "2023-03-10": {
      "equity": 17412.9,
      "debt": 7.429,
      "gold": 53045.81386,
      "pb": 4.08,
      "pe": 20.52,
      "yield": 1.43,
      "date": "2023-03-10"
  },
  "2023-03-11": {
      "equity": 17412.9,
      "debt": 7.429,
      "gold": 53045.81386,
      "pb": 4.08,
      "pe": 20.52,
      "yield": 1.43,
      "date": "2023-03-11"
  },
  "2023-03-12": {
      "equity": 17412.9,
      "debt": 7.429,
      "gold": 53045.81386,
      "pb": 4.08,
      "pe": 20.52,
      "yield": 1.43,
      "date": "2023-03-12"
  },
  "2023-03-13": {
      "equity": 17154.3,
      "debt": 7.365,
      "gold": 54672.15295,
      "pb": 4.02,
      "pe": 20.21,
      "yield": 1.45,
      "date": "2023-03-13"
  },
  "2023-03-14": {
      "equity": 17043.3,
      "debt": 7.378,
      "gold": 55203.72141,
      "pb": 3.99,
      "pe": 20.08,
      "yield": 1.46,
      "date": "2023-03-14"
  },
  "2023-03-15": {
      "equity": 16972.15,
      "debt": 7.363,
      "gold": 55716.28777,
      "pb": 3.97,
      "pe": 20,
      "yield": 1.47,
      "date": "2023-03-15"
  },
  "2023-03-16": {
      "equity": 16985.6,
      "debt": 7.352,
      "gold": 55986.87102,
      "pb": 3.98,
      "pe": 20.01,
      "yield": 1.47,
      "date": "2023-03-16"
  },
  "2023-03-17": {
      "equity": 17100.05,
      "debt": 7.354,
      "gold": 56221.64765,
      "pb": 4,
      "pe": 20.15,
      "yield": 1.46,
      "date": "2023-03-17"
  },
  "2023-03-18": {
      "equity": 17100.05,
      "debt": 7.354,
      "gold": 56221.64765,
      "pb": 4,
      "pe": 20.15,
      "yield": 1.46,
      "date": "2023-03-18"
  },
  "2023-03-19": {
      "equity": 17100.05,
      "debt": 7.354,
      "gold": 56221.64765,
      "pb": 4,
      "pe": 20.15,
      "yield": 1.46,
      "date": "2023-03-19"
  },
  "2023-03-20": {
      "equity": 16988.4,
      "debt": 7.334,
      "gold": 57705.05653,
      "pb": 3.98,
      "pe": 20.02,
      "yield": 1.47,
      "date": "2023-03-20"
  },
  "2023-03-21": {
      "equity": 17107.5,
      "debt": 7.354,
      "gold": 57301.3457,
      "pb": 4.01,
      "pe": 20.16,
      "yield": 1.46,
      "date": "2023-03-21"
  },
  "2023-03-22": {
      "equity": 17151.9,
      "debt": 7.354,
      "gold": 56598.90651,
      "pb": 4.02,
      "pe": 20.21,
      "yield": 1.45,
      "date": "2023-03-22"
  },
  "2023-03-23": {
      "equity": 17076.9,
      "debt": 7.345,
      "gold": 57304.61913,
      "pb": 4,
      "pe": 20.12,
      "yield": 1.46,
      "date": "2023-03-23"
  },
  "2023-03-24": {
      "equity": 16945.05,
      "debt": 7.318,
      "gold": 58006.39517,
      "pb": 3.97,
      "pe": 19.97,
      "yield": 1.47,
      "date": "2023-03-24"
  },
  "2023-03-25": {
      "equity": 16945.05,
      "debt": 7.318,
      "gold": 58006.39517,
      "pb": 3.97,
      "pe": 19.97,
      "yield": 1.47,
      "date": "2023-03-25"
  },
  "2023-03-26": {
      "equity": 16945.05,
      "debt": 7.318,
      "gold": 58006.39517,
      "pb": 3.97,
      "pe": 19.97,
      "yield": 1.47,
      "date": "2023-03-26"
  },
  "2023-03-27": {
      "equity": 16985.7,
      "debt": 7.312,
      "gold": 56789.47777,
      "pb": 3.98,
      "pe": 20.01,
      "yield": 1.47,
      "date": "2023-03-27"
  },
  "2023-03-28": {
      "equity": 16951.7,
      "debt": 7.321,
      "gold": 56504.00536,
      "pb": 3.97,
      "pe": 19.97,
      "yield": 1.47,
      "date": "2023-03-28"
  },
  "2023-03-29": {
      "equity": 17080.7,
      "debt": 7.296,
      "gold": 57038.39221,
      "pb": 4,
      "pe": 20.13,
      "yield": 1.46,
      "date": "2023-03-29"
  },
  "2023-03-30": {
      "equity": 17080.7,
      "debt": 7.296,
      "gold": 57094.85882,
      "pb": 4,
      "pe": 20.13,
      "yield": 1.46,
      "date": "2023-03-30"
  },
  "2023-03-31": {
      "equity": 17359.75,
      "debt": 7.315,
      "gold": 57323.04273,
      "pb": 4.06,
      "pe": 20.44,
      "yield": 1.44,
      "date": "2023-03-31"
  },
  "2023-04-01": {
      "equity": 17359.75,
      "debt": 7.315,
      "gold": 57323.04273,
      "pb": 4.06,
      "pe": 20.44,
      "yield": 1.44,
      "date": "2023-04-01"
  },
  "2023-04-02": {
      "equity": 17359.75,
      "debt": 7.315,
      "gold": 57323.04273,
      "pb": 4.06,
      "pe": 20.44,
      "yield": 1.44,
      "date": "2023-04-02"
  },
  "2023-04-03": {
      "equity": 17398.05,
      "debt": 7.315,
      "gold": 56943.36761,
      "pb": 4.07,
      "pe": 20.48,
      "yield": 1.43,
      "date": "2023-04-03"
  },
  "2023-04-04": {
      "equity": 17398.05,
      "debt": 7.315,
      "gold": 57451.30249,
      "pb": 4.07,
      "pe": 20.48,
      "yield": 1.43,
      "date": "2023-04-04"
  },
  "2023-04-05": {
      "equity": 17557.05,
      "debt": 7.273,
      "gold": 58454.06092,
      "pb": 4.11,
      "pe": 20.67,
      "yield": 1.41,
      "date": "2023-04-05"
  },
  "2023-04-06": {
      "equity": 17599.15,
      "debt": 7.219,
      "gold": 58238.07475,
      "pb": 4.12,
      "pe": 20.72,
      "yield": 1.41,
      "date": "2023-04-06"
  },
  "2023-04-07": {
      "equity": 17599.15,
      "debt": 7.219,
      "gold": 58238.07475,
      "pb": 4.12,
      "pe": 20.72,
      "yield": 1.41,
      "date": "2023-04-07"
  },
  "2023-04-08": {
      "equity": 17599.15,
      "debt": 7.219,
      "gold": 58238.07475,
      "pb": 4.12,
      "pe": 20.72,
      "yield": 1.41,
      "date": "2023-04-08"
  },
  "2023-04-09": {
      "equity": 17599.15,
      "debt": 7.219,
      "gold": 58238.07475,
      "pb": 4.12,
      "pe": 20.72,
      "yield": 1.41,
      "date": "2023-04-09"
  },
  "2023-04-10": {
      "equity": 17624.05,
      "debt": 7.227,
      "gold": 58238.07475,
      "pb": 4.13,
      "pe": 20.75,
      "yield": 1.41,
      "date": "2023-04-10"
  },
  "2023-04-11": {
      "equity": 17722.3,
      "debt": 7.224,
      "gold": 57962.62721,
      "pb": 4.15,
      "pe": 20.86,
      "yield": 1.4,
      "date": "2023-04-11"
  },
  "2023-04-12": {
      "equity": 17812.4,
      "debt": 7.213,
      "gold": 58095.79005,
      "pb": 4.17,
      "pe": 20.97,
      "yield": 1.39,
      "date": "2023-04-12"
  },
  "2023-04-13": {
      "equity": 17828,
      "debt": 7.229,
      "gold": 58406.82199,
      "pb": 4.17,
      "pe": 20.99,
      "yield": 1.4,
      "date": "2023-04-13"
  },
  "2023-04-14": {
      "equity": 17828,
      "debt": 7.229,
      "gold": 58535.84367,
      "pb": 4.17,
      "pe": 20.99,
      "yield": 1.4,
      "date": "2023-04-14"
  },
  "2023-04-15": {
      "equity": 17828,
      "debt": 7.229,
      "gold": 58535.84367,
      "pb": 4.17,
      "pe": 20.99,
      "yield": 1.4,
      "date": "2023-04-15"
  },
  "2023-04-16": {
      "equity": 17828,
      "debt": 7.229,
      "gold": 58535.84367,
      "pb": 4.17,
      "pe": 20.99,
      "yield": 1.4,
      "date": "2023-04-16"
  },
  "2023-04-17": {
      "equity": 17706.85,
      "debt": 7.247,
      "gold": 58151.94624,
      "pb": 4.14,
      "pe": 20.82,
      "yield": 1.41,
      "date": "2023-04-17"
  },
  "2023-04-18": {
      "equity": 17660.15,
      "debt": 7.221,
      "gold": 57901.45505,
      "pb": 4.13,
      "pe": 20.65,
      "yield": 1.41,
      "date": "2023-04-18"
  },
  "2023-04-19": {
      "equity": 17618.75,
      "debt": 7.227,
      "gold": 57287.34898,
      "pb": 4.12,
      "pe": 20.6,
      "yield": 1.42,
      "date": "2023-04-19"
  },
  "2023-04-20": {
      "equity": 17624.45,
      "debt": 7.22,
      "gold": 57733.51205,
      "pb": 4.13,
      "pe": 20.61,
      "yield": 1.42,
      "date": "2023-04-20"
  },
  "2023-04-21": {
      "equity": 17624.05,
      "debt": 7.185,
      "gold": 57493.88173,
      "pb": 4.13,
      "pe": 20.61,
      "yield": 1.42,
      "date": "2023-04-21"
  },
  "2023-04-22": {
      "equity": 17624.05,
      "debt": 7.185,
      "gold": 57493.88173,
      "pb": 4.13,
      "pe": 20.61,
      "yield": 1.42,
      "date": "2023-04-22"
  },
  "2023-04-23": {
      "equity": 17624.05,
      "debt": 7.185,
      "gold": 57493.88173,
      "pb": 4.13,
      "pe": 20.61,
      "yield": 1.42,
      "date": "2023-04-23"
  },
  "2023-04-24": {
      "equity": 17743.4,
      "debt": 7.111,
      "gold": 57409.1536,
      "pb": 4.15,
      "pe": 20.74,
      "yield": 1.41,
      "date": "2023-04-24"
  },
  "2023-04-25": {
      "equity": 17769.25,
      "debt": 7.11,
      "gold": 57587.64705,
      "pb": 4.16,
      "pe": 20.56,
      "yield": 1.41,
      "date": "2023-04-25"
  },
  "2023-04-26": {
      "equity": 17813.6,
      "debt": 7.116,
      "gold": 57659.04866,
      "pb": 4.17,
      "pe": 20.59,
      "yield": 1.4,
      "date": "2023-04-26"
  },
  "2023-04-27": {
      "equity": 17915.05,
      "debt": 7.097,
      "gold": 57610.21182,
      "pb": 4.19,
      "pe": 20.7,
      "yield": 1.39,
      "date": "2023-04-27"
  },
  "2023-04-28": {
      "equity": 18065,
      "debt": 7.116,
      "gold": 57164.17221,
      "pb": 4.23,
      "pe": 20.87,
      "yield": 1.38,
      "date": "2023-04-28"
  },
  "2023-04-29": {
      "equity": 18065,
      "debt": 7.116,
      "gold": 57164.17221,
      "pb": 4.23,
      "pe": 20.87,
      "yield": 1.38,
      "date": "2023-04-29"
  },
  "2023-04-30": {
      "equity": 18065,
      "debt": 7.116,
      "gold": 57164.17221,
      "pb": 4.23,
      "pe": 20.87,
      "yield": 1.38,
      "date": "2023-04-30"
  },
  "2023-05-01": {
      "equity": 18065,
      "debt": 7.116,
      "gold": 57164.17221,
      "pb": 4.23,
      "pe": 20.87,
      "yield": 1.38,
      "date": "2023-05-01"
  },
  "2023-05-02": {
      "equity": 18147.65,
      "debt": 7.106,
      "gold": 57118.35482,
      "pb": 4.25,
      "pe": 20.92,
      "yield": 1.38,
      "date": "2023-05-02"
  },
  "2023-05-03": {
      "equity": 18089.85,
      "debt": 7.011,
      "gold": 58124.73941,
      "pb": 4.23,
      "pe": 21.33,
      "yield": 1.38,
      "date": "2023-05-03"
  },
  "2023-05-04": {
      "equity": 18255.8,
      "debt": 7.014,
      "gold": 58676.0472,
      "pb": 4.27,
      "pe": 21.85,
      "yield": 1.37,
      "date": "2023-05-04"
  },
  "2023-05-05": {
      "equity": 18069,
      "debt": 7.014,
      "gold": 58744.15422,
      "pb": 4.23,
      "pe": 21.62,
      "yield": 1.38,
      "date": "2023-05-05"
  },
  "2023-05-06": {
      "equity": 18069,
      "debt": 7.014,
      "gold": 58744.15422,
      "pb": 4.23,
      "pe": 21.62,
      "yield": 1.38,
      "date": "2023-05-06"
  },
  "2023-05-07": {
      "equity": 18069,
      "debt": 7.014,
      "gold": 58744.15422,
      "pb": 4.23,
      "pe": 21.62,
      "yield": 1.38,
      "date": "2023-05-07"
  },
  "2023-05-08": {
      "equity": 18264.4,
      "debt": 7.047,
      "gold": 58744.15422,
      "pb": 4.28,
      "pe": 21.77,
      "yield": 1.37,
      "date": "2023-05-08"
  },
  "2023-05-09": {
      "equity": 18265.95,
      "debt": 7.046,
      "gold": 58635.90187,
      "pb": 4.28,
      "pe": 21.77,
      "yield": 1.37,
      "date": "2023-05-09"
  },
  "2023-05-10": {
      "equity": 18315.1,
      "debt": 7.036,
      "gold": 58665.35212,
      "pb": 4.29,
      "pe": 21.88,
      "yield": 1.36,
      "date": "2023-05-10"
  },
  "2023-05-11": {
      "equity": 18297,
      "debt": 7.023,
      "gold": 58693.83587,
      "pb": 4.28,
      "pe": 21.86,
      "yield": 1.36,
      "date": "2023-05-11"
  },
  "2023-05-12": {
      "equity": 18314.8,
      "debt": 6.995,
      "gold": 58180.78273,
      "pb": 4.29,
      "pe": 21.82,
      "yield": 1.28,
      "date": "2023-05-12"
  },
  "2023-05-13": {
      "equity": 18314.8,
      "debt": 6.995,
      "gold": 58180.78273,
      "pb": 4.29,
      "pe": 21.82,
      "yield": 1.28,
      "date": "2023-05-13"
  },
  "2023-05-14": {
      "equity": 18314.8,
      "debt": 6.995,
      "gold": 58180.78273,
      "pb": 4.29,
      "pe": 21.82,
      "yield": 1.28,
      "date": "2023-05-14"
  },
  "2023-05-15": {
      "equity": 18398.85,
      "debt": 7.001,
      "gold": 58451.69756,
      "pb": 4.31,
      "pe": 21.92,
      "yield": 1.27,
      "date": "2023-05-15"
  },
  "2023-05-16": {
      "equity": 18286.5,
      "debt": 6.963,
      "gold": 58342.00956,
      "pb": 4.28,
      "pe": 21.59,
      "yield": 1.48,
      "date": "2023-05-16"
  },
  "2023-05-17": {
      "equity": 18181.75,
      "debt": 6.972,
      "gold": 57682.32597,
      "pb": 4.26,
      "pe": 21.47,
      "yield": 1.49,
      "date": "2023-05-17"
  },
  "2023-05-18": {
      "equity": 18129.95,
      "debt": 6.983,
      "gold": 57711.22242,
      "pb": 4.24,
      "pe": 21.39,
      "yield": 1.5,
      "date": "2023-05-18"
  },
  "2023-05-19": {
      "equity": 18203.4,
      "debt": 7.004,
      "gold": 57425.92638,
      "pb": 4.26,
      "pe": 21.48,
      "yield": 1.5,
      "date": "2023-05-19"
  },
  "2023-05-20": {
      "equity": 18203.4,
      "debt": 7.004,
      "gold": 57425.92638,
      "pb": 4.26,
      "pe": 21.48,
      "yield": 1.5,
      "date": "2023-05-20"
  },
  "2023-05-21": {
      "equity": 18203.4,
      "debt": 7.004,
      "gold": 57425.92638,
      "pb": 4.26,
      "pe": 21.48,
      "yield": 1.5,
      "date": "2023-05-21"
  },
  "2023-05-22": {
      "equity": 18314.4,
      "debt": 6.985,
      "gold": 57903.91365,
      "pb": 4.29,
      "pe": 21.36,
      "yield": 1.49,
      "date": "2023-05-22"
  },
  "2023-05-23": {
      "equity": 18348,
      "debt": 7.016,
      "gold": 57281.54641,
      "pb": 4.29,
      "pe": 21.41,
      "yield": 1.49,
      "date": "2023-05-23"
  },
  "2023-05-24": {
      "equity": 18285.4,
      "debt": 6.99,
      "gold": 57649.66578,
      "pb": 4.28,
      "pe": 21.24,
      "yield": 1.49,
      "date": "2023-05-24"
  },
  "2023-05-25": {
      "equity": 18321.15,
      "debt": 7.007,
      "gold": 57278.16011,
      "pb": 4.29,
      "pe": 21.28,
      "yield": 1.43,
      "date": "2023-05-25"
  },
  "2023-05-26": {
      "equity": 18499.35,
      "debt": 7.003,
      "gold": 56915.38122,
      "pb": 4.33,
      "pe": 21.54,
      "yield": 1.34,
      "date": "2023-05-26"
  },
  "2023-05-27": {
      "equity": 18499.35,
      "debt": 7.003,
      "gold": 56915.38122,
      "pb": 4.33,
      "pe": 21.54,
      "yield": 1.34,
      "date": "2023-05-27"
  },
  "2023-05-28": {
      "equity": 18499.35,
      "debt": 7.003,
      "gold": 56915.38122,
      "pb": 4.33,
      "pe": 21.54,
      "yield": 1.34,
      "date": "2023-05-28"
  },
  "2023-05-29": {
      "equity": 18598.65,
      "debt": 7.024,
      "gold": 56915.38122,
      "pb": 4.35,
      "pe": 21.65,
      "yield": 1.34,
      "date": "2023-05-29"
  },
  "2023-05-30": {
      "equity": 18633.85,
      "debt": 7.011,
      "gold": 56863.82476,
      "pb": 4.36,
      "pe": 21.7,
      "yield": 1.44,
      "date": "2023-05-30"
  },
  "2023-05-31": {
      "equity": 18534.4,
      "debt": 6.989,
      "gold": 57146.79977,
      "pb": 4.33,
      "pe": 21.59,
      "yield": 1.36,
      "date": "2023-05-31"
  },
  "2023-06-01": {
      "equity": 18487.75,
      "debt": 6.979,
      "gold": 56879.85679,
      "pb": 4.32,
      "pe": 21.53,
      "yield": 1.36,
      "date": "2023-06-01"
  },
  "2023-06-02": {
      "equity": 18534.1,
      "debt": 6.984,
      "gold": 57574.74382,
      "pb": 4.33,
      "pe": 21.58,
      "yield": 1.45,
      "date": "2023-06-02"
  },
  "2023-06-03": {
      "equity": 18534.1,
      "debt": 6.984,
      "gold": 57574.74382,
      "pb": 4.33,
      "pe": 21.58,
      "yield": 1.45,
      "date": "2023-06-03"
  },
  "2023-06-04": {
      "equity": 18534.1,
      "debt": 6.984,
      "gold": 57574.74382,
      "pb": 4.33,
      "pe": 21.58,
      "yield": 1.45,
      "date": "2023-06-04"
  },
  "2023-06-05": {
      "equity": 18593.85,
      "debt": 6.997,
      "gold": 56545.90381,
      "pb": 4.35,
      "pe": 21.65,
      "yield": 1.44,
      "date": "2023-06-05"
  },
  "2023-06-06": {
      "equity": 18599,
      "debt": 6.977,
      "gold": 57110.4993,
      "pb": 4.35,
      "pe": 21.66,
      "yield": 1.44,
      "date": "2023-06-06"
  },
  "2023-06-07": {
      "equity": 18726.4,
      "debt": 6.982,
      "gold": 57197.77774,
      "pb": 4.38,
      "pe": 21.81,
      "yield": 1.43,
      "date": "2023-06-07"
  },
  "2023-06-08": {
      "equity": 18634.55,
      "debt": 7.021,
      "gold": 56649.48941,
      "pb": 4.36,
      "pe": 21.7,
      "yield": 1.44,
      "date": "2023-06-08"
  },
  "2023-06-09": {
      "equity": 18563.4,
      "debt": 7.039,
      "gold": 57058.23736,
      "pb": 4.34,
      "pe": 21.62,
      "yield": 1.45,
      "date": "2023-06-09"
  },
  "2023-06-10": {
      "equity": 18563.4,
      "debt": 7.039,
      "gold": 57058.23736,
      "pb": 4.34,
      "pe": 21.62,
      "yield": 1.45,
      "date": "2023-06-10"
  },
  "2023-06-11": {
      "equity": 18563.4,
      "debt": 7.039,
      "gold": 57058.23736,
      "pb": 4.34,
      "pe": 21.62,
      "yield": 1.45,
      "date": "2023-06-11"
  },
  "2023-06-12": {
      "equity": 18601.5,
      "debt": 7.016,
      "gold": 57112.39352,
      "pb": 4.35,
      "pe": 21.66,
      "yield": 1.44,
      "date": "2023-06-12"
  },
  "2023-06-13": {
      "equity": 18716.15,
      "debt": 7.002,
      "gold": 56985.96448,
      "pb": 4.37,
      "pe": 21.79,
      "yield": 1.43,
      "date": "2023-06-13"
  },
  "2023-06-14": {
      "equity": 18755.9,
      "debt": 7.011,
      "gold": 56389.21674,
      "pb": 4.38,
      "pe": 21.84,
      "yield": 1.43,
      "date": "2023-06-14"
  },
  "2023-06-15": {
      "equity": 18688.1,
      "debt": 7.036,
      "gold": 55909.29999,
      "pb": 4.37,
      "pe": 21.76,
      "yield": 1.39,
      "date": "2023-06-15"
  },
  "2023-06-16": {
      "equity": 18826,
      "debt": 7.034,
      "gold": 56754.13676,
      "pb": 4.4,
      "pe": 21.92,
      "yield": 1.39,
      "date": "2023-06-16"
  },
  "2023-06-17": {
      "equity": 18826,
      "debt": 7.034,
      "gold": 56754.13676,
      "pb": 4.4,
      "pe": 21.92,
      "yield": 1.39,
      "date": "2023-06-17"
  },
  "2023-06-18": {
      "equity": 18826,
      "debt": 7.034,
      "gold": 56754.13676,
      "pb": 4.4,
      "pe": 21.92,
      "yield": 1.39,
      "date": "2023-06-18"
  },
  "2023-06-19": {
      "equity": 18755.45,
      "debt": 7.053,
      "gold": 56475.5075,
      "pb": 4.38,
      "pe": 21.84,
      "yield": 1.42,
      "date": "2023-06-19"
  },
  "2023-06-20": {
      "equity": 18816.7,
      "debt": 7.059,
      "gold": 56513.09194,
      "pb": 4.4,
      "pe": 21.91,
      "yield": 1.4,
      "date": "2023-06-20"
  },
  "2023-06-21": {
      "equity": 18856.85,
      "debt": 7.067,
      "gold": 55957.01864,
      "pb": 4.41,
      "pe": 21.96,
      "yield": 1.4,
      "date": "2023-06-21"
  },
  "2023-06-22": {
      "equity": 18771.25,
      "debt": 7.086,
      "gold": 55721.79756,
      "pb": 4.39,
      "pe": 21.85,
      "yield": 1.44,
      "date": "2023-06-22"
  },
  "2023-06-23": {
      "equity": 18665.5,
      "debt": 7.074,
      "gold": 55507.70208,
      "pb": 4.36,
      "pe": 21.73,
      "yield": 1.45,
      "date": "2023-06-23"
  },
  "2023-06-24": {
      "equity": 18665.5,
      "debt": 7.074,
      "gold": 55507.70208,
      "pb": 4.36,
      "pe": 21.73,
      "yield": 1.45,
      "date": "2023-06-24"
  },
  "2023-06-25": {
      "equity": 18665.5,
      "debt": 7.074,
      "gold": 55507.70208,
      "pb": 4.36,
      "pe": 21.73,
      "yield": 1.45,
      "date": "2023-06-25"
  },
  "2023-06-26": {
      "equity": 18691.2,
      "debt": 7.066,
      "gold": 55839.8455,
      "pb": 4.37,
      "pe": 21.76,
      "yield": 1.45,
      "date": "2023-06-26"
  },
  "2023-06-27": {
      "equity": 18817.4,
      "debt": 7.063,
      "gold": 55602.11644,
      "pb": 4.4,
      "pe": 21.9,
      "yield": 1.44,
      "date": "2023-06-27"
  },
  "2023-06-28": {
      "equity": 18972.1,
      "debt": 7.056,
      "gold": 55267.45445,
      "pb": 4.43,
      "pe": 22.08,
      "yield": 1.43,
      "date": "2023-06-28"
  },
  "2023-06-29": {
      "equity": 18972.1,
      "debt": 7.056,
      "gold": 55101.62789,
      "pb": 4.43,
      "pe": 22.08,
      "yield": 1.43,
      "date": "2023-06-29"
  },
  "2023-06-30": {
      "equity": 19189.05,
      "debt": 7.11,
      "gold": 55091.45488,
      "pb": 4.5,
      "pe": 22.36,
      "yield": 1.41,
      "date": "2023-06-30"
  },
  "2023-07-01": {
      "equity": 19189.05,
      "debt": 7.11,
      "gold": 55091.45488,
      "pb": 4.5,
      "pe": 22.36,
      "yield": 1.41,
      "date": "2023-07-01"
  },
  "2023-07-02": {
      "equity": 19189.05,
      "debt": 7.11,
      "gold": 55091.45488,
      "pb": 4.5,
      "pe": 22.36,
      "yield": 1.41,
      "date": "2023-07-02"
  },
  "2023-07-03": {
      "equity": 19322.55,
      "debt": 7.117,
      "gold": 55307.94547,
      "pb": 4.53,
      "pe": 22.51,
      "yield": 1.4,
      "date": "2023-07-03"
  },
  "2023-07-04": {
      "equity": 19389,
      "debt": 7.119,
      "gold": 55819.49593,
      "pb": 4.54,
      "pe": 22.59,
      "yield": 1.38,
      "date": "2023-07-04"
  },
  "2023-07-05": {
      "equity": 19398.5,
      "debt": 7.105,
      "gold": 56013.10429,
      "pb": 4.55,
      "pe": 22.6,
      "yield": 1.38,
      "date": "2023-07-05"
  },
  "2023-07-06": {
      "equity": 19497.3,
      "debt": 7.147,
      "gold": 56025.54542,
      "pb": 4.57,
      "pe": 22.72,
      "yield": 1.37,
      "date": "2023-07-06"
  },
  "2023-07-07": {
      "equity": 19331.8,
      "debt": 7.159,
      "gold": 55823.56655,
      "pb": 4.53,
      "pe": 22.52,
      "yield": 1.39,
      "date": "2023-07-07"
  },
  "2023-07-08": {
      "equity": 19331.8,
      "debt": 7.159,
      "gold": 55823.56655,
      "pb": 4.53,
      "pe": 22.52,
      "yield": 1.39,
      "date": "2023-07-08"
  },
  "2023-07-09": {
      "equity": 19331.8,
      "debt": 7.159,
      "gold": 55823.56655,
      "pb": 4.53,
      "pe": 22.52,
      "yield": 1.39,
      "date": "2023-07-09"
  },
  "2023-07-10": {
      "equity": 19355.9,
      "debt": 7.149,
      "gold": 56050.49825,
      "pb": 4.54,
      "pe": 22.55,
      "yield": 1.38,
      "date": "2023-07-10"
  },
  "2023-07-11": {
      "equity": 19439.4,
      "debt": 7.095,
      "gold": 56274.20237,
      "pb": 4.56,
      "pe": 22.65,
      "yield": 1.38,
      "date": "2023-07-11"
  },
  "2023-07-12": {
      "equity": 19384.3,
      "debt": 7.118,
      "gold": 55999.12873,
      "pb": 4.54,
      "pe": 22.58,
      "yield": 1.38,
      "date": "2023-07-12"
  },
  "2023-07-13": {
      "equity": 19413.75,
      "debt": 7.071,
      "gold": 56695.64895,
      "pb": 4.74,
      "pe": 23.81,
      "yield": 1.32,
      "date": "2023-07-13"
  },
  "2023-07-14": {
      "equity": 19564.5,
      "debt": 7.089,
      "gold": 56657.58126,
      "pb": 4.78,
      "pe": 23.96,
      "yield": 1.3,
      "date": "2023-07-14"
  },
  "2023-07-15": {
      "equity": 19564.5,
      "debt": 7.089,
      "gold": 56657.58126,
      "pb": 4.78,
      "pe": 23.96,
      "yield": 1.3,
      "date": "2023-07-15"
  },
  "2023-07-16": {
      "equity": 19564.5,
      "debt": 7.089,
      "gold": 56657.58126,
      "pb": 4.78,
      "pe": 23.96,
      "yield": 1.3,
      "date": "2023-07-16"
  },
  "2023-07-17": {
      "equity": 19711.45,
      "debt": 7.074,
      "gold": 56575.04012,
      "pb": 4.82,
      "pe": 24.14,
      "yield": 1.29,
      "date": "2023-07-17"
  },
  "2023-07-18": {
      "equity": 19749.25,
      "debt": 7.058,
      "gold": 56789.79876,
      "pb": 4.83,
      "pe": 24.18,
      "yield": 1.28,
      "date": "2023-07-18"
  },
  "2023-07-19": {
      "equity": 19833.15,
      "debt": 7.072,
      "gold": 57274.90785,
      "pb": 4.85,
      "pe": 24.09,
      "yield": 1.27,
      "date": "2023-07-19"
  },
  "2023-07-20": {
      "equity": 19979.15,
      "debt": 7.083,
      "gold": 57356.38371,
      "pb": 4.88,
      "pe": 24.24,
      "yield": 1.28,
      "date": "2023-07-20"
  },
  "2023-07-21": {
      "equity": 19745,
      "debt": 7.087,
      "gold": 56809.19946,
      "pb": 4.82,
      "pe": 23.95,
      "yield": 1.27,
      "date": "2023-07-21"
  },
  "2023-07-22": {
      "equity": 19745,
      "debt": 7.087,
      "gold": 56809.19946,
      "pb": 4.82,
      "pe": 23.95,
      "yield": 1.27,
      "date": "2023-07-22"
  },
  "2023-07-23": {
      "equity": 19745,
      "debt": 7.087,
      "gold": 56809.19946,
      "pb": 4.82,
      "pe": 23.95,
      "yield": 1.27,
      "date": "2023-07-23"
  },
  "2023-07-24": {
      "equity": 19672.35,
      "debt": 7.071,
      "gold": 56690.44957,
      "pb": 4.81,
      "pe": 23.83,
      "yield": 1.28,
      "date": "2023-07-24"
  },
  "2023-07-25": {
      "equity": 19680.6,
      "debt": 7.1,
      "gold": 56703.4304,
      "pb": 4.81,
      "pe": 23.55,
      "yield": 1.28,
      "date": "2023-07-25"
  },
  "2023-07-26": {
      "equity": 19778.3,
      "debt": 7.096,
      "gold": 57053.01681,
      "pb": 4.83,
      "pe": 23.97,
      "yield": 1.27,
      "date": "2023-07-26"
  },
  "2023-07-27": {
      "equity": 19659.9,
      "debt": 7.12,
      "gold": 57258.12095,
      "pb": 4.8,
      "pe": 23.83,
      "yield": 1.28,
      "date": "2023-07-27"
  },
  "2023-07-28": {
      "equity": 19646.05,
      "debt": 7.161,
      "gold": 56554.31665,
      "pb": 4.8,
      "pe": 22.91,
      "yield": 1.29,
      "date": "2023-07-28"
  },
  "2023-07-29": {
      "equity": 19646.05,
      "debt": 7.161,
      "gold": 56554.31665,
      "pb": 4.8,
      "pe": 22.91,
      "yield": 1.29,
      "date": "2023-07-29"
  },
  "2023-07-30": {
      "equity": 19646.05,
      "debt": 7.161,
      "gold": 56554.31665,
      "pb": 4.8,
      "pe": 22.91,
      "yield": 1.29,
      "date": "2023-07-30"
  },
  "2023-07-31": {
      "equity": 19753.8,
      "debt": 7.172,
      "gold": 56739.26524,
      "pb": 4.83,
      "pe": 23.01,
      "yield": 1.28,
      "date": "2023-07-31"
  },
  "2023-08-01": {
      "equity": 19733.55,
      "debt": 7.156,
      "gold": 56829.92293,
      "pb": 4.82,
      "pe": 22.96,
      "yield": 1.28,
      "date": "2023-08-01"
  },
  "2023-08-02": {
      "equity": 19526.55,
      "debt": 7.154,
      "gold": 56868.97123,
      "pb": 4.73,
      "pe": 22.72,
      "yield": 1.33,
      "date": "2023-08-02"
  },
  "2023-08-03": {
      "equity": 19381.65,
      "debt": 7.194,
      "gold": 56536.61617,
      "pb": 4.69,
      "pe": 22.55,
      "yield": 1.35,
      "date": "2023-08-03"
  },
  "2023-08-04": {
      "equity": 19517,
      "debt": 7.193,
      "gold": 56385.79164,
      "pb": 4.72,
      "pe": 22.71,
      "yield": 1.34,
      "date": "2023-08-04"
  },
  "2023-08-05": {
      "equity": 19517,
      "debt": 7.193,
      "gold": 56385.79164,
      "pb": 4.72,
      "pe": 22.71,
      "yield": 1.34,
      "date": "2023-08-05"
  },
  "2023-08-06": {
      "equity": 19517,
      "debt": 7.193,
      "gold": 56385.79164,
      "pb": 4.72,
      "pe": 22.71,
      "yield": 1.34,
      "date": "2023-08-06"
  },
  "2023-08-07": {
      "equity": 19597.3,
      "debt": 7.197,
      "gold": 56555.81227,
      "pb": 4.74,
      "pe": 22.83,
      "yield": 1.34,
      "date": "2023-08-07"
  },
  "2023-08-08": {
      "equity": 19570.85,
      "debt": 7.168,
      "gold": 56570.1829,
      "pb": 4.73,
      "pe": 22.73,
      "yield": 1.31,
      "date": "2023-08-08"
  },
  "2023-08-09": {
      "equity": 19632.55,
      "debt": 7.178,
      "gold": 56361.2233,
      "pb": 4.75,
      "pe": 22.81,
      "yield": 1.37,
      "date": "2023-08-09"
  },
  "2023-08-10": {
      "equity": 19543.1,
      "debt": 7.154,
      "gold": 56015.75689,
      "pb": 4.73,
      "pe": 22.77,
      "yield": 1.36,
      "date": "2023-08-10"
  },
  "2023-08-11": {
      "equity": 19428.3,
      "debt": 7.198,
      "gold": 56093.83587,
      "pb": 4.7,
      "pe": 22.63,
      "yield": 1.38,
      "date": "2023-08-11"
  },
  "2023-08-12": {
      "equity": 19428.3,
      "debt": 7.198,
      "gold": 56093.83587,
      "pb": 4.7,
      "pe": 22.63,
      "yield": 1.38,
      "date": "2023-08-12"
  },
  "2023-08-13": {
      "equity": 19428.3,
      "debt": 7.198,
      "gold": 56093.83587,
      "pb": 4.7,
      "pe": 22.63,
      "yield": 1.38,
      "date": "2023-08-13"
  },
  "2023-08-14": {
      "equity": 19434.55,
      "debt": 7.209,
      "gold": 56123.57537,
      "pb": 4.7,
      "pe": 22.64,
      "yield": 1.39,
      "date": "2023-08-14"
  },
  "2023-08-15": {
      "equity": 19434.55,
      "debt": 7.209,
      "gold": 55867.07702,
      "pb": 4.7,
      "pe": 22.64,
      "yield": 1.39,
      "date": "2023-08-15"
  },
  "2023-08-16": {
      "equity": 19465,
      "debt": 7.209,
      "gold": 55946.29535,
      "pb": 4.68,
      "pe": 22.52,
      "yield": 1.39,
      "date": "2023-08-16"
  },
  "2023-08-17": {
      "equity": 19365.25,
      "debt": 7.25,
      "gold": 55536.87014,
      "pb": 4.66,
      "pe": 22.39,
      "yield": 1.39,
      "date": "2023-08-17"
  },
  "2023-08-18": {
      "equity": 19310.15,
      "debt": 7.214,
      "gold": 55465.6202,
      "pb": 4.64,
      "pe": 22.32,
      "yield": 1.36,
      "date": "2023-08-18"
  },
  "2023-08-19": {
      "equity": 19310.15,
      "debt": 7.214,
      "gold": 55465.6202,
      "pb": 4.64,
      "pe": 22.32,
      "yield": 1.36,
      "date": "2023-08-19"
  },
  "2023-08-20": {
      "equity": 19310.15,
      "debt": 7.214,
      "gold": 55465.6202,
      "pb": 4.64,
      "pe": 22.32,
      "yield": 1.36,
      "date": "2023-08-20"
  },
  "2023-08-21": {
      "equity": 19393.6,
      "debt": 7.22,
      "gold": 55363.90413,
      "pb": 4.66,
      "pe": 22.42,
      "yield": 1.37,
      "date": "2023-08-21"
  },
  "2023-08-22": {
      "equity": 19396.45,
      "debt": 7.218,
      "gold": 55716.13256,
      "pb": 4.55,
      "pe": 22.13,
      "yield": 1.37,
      "date": "2023-08-22"
  },
  "2023-08-23": {
      "equity": 19444,
      "debt": 7.194,
      "gold": 55461.67657,
      "pb": 4.56,
      "pe": 22.19,
      "yield": 1.37,
      "date": "2023-08-23"
  },
  "2023-08-24": {
      "equity": 19386.7,
      "debt": 7.193,
      "gold": 55958.05923,
      "pb": 4.47,
      "pe": 22.12,
      "yield": 1.37,
      "date": "2023-08-24"
  },
  "2023-08-25": {
      "equity": 19265.8,
      "debt": 7.206,
      "gold": 55863.86356,
      "pb": 4.44,
      "pe": 21.98,
      "yield": 1.38,
      "date": "2023-08-25"
  },
  "2023-08-26": {
      "equity": 19265.8,
      "debt": 7.206,
      "gold": 55863.86356,
      "pb": 4.44,
      "pe": 21.98,
      "yield": 1.38,
      "date": "2023-08-26"
  },
  "2023-08-27": {
      "equity": 19265.8,
      "debt": 7.206,
      "gold": 55863.86356,
      "pb": 4.44,
      "pe": 21.98,
      "yield": 1.38,
      "date": "2023-08-27"
  },
  "2023-08-28": {
      "equity": 19306.05,
      "debt": 7.178,
      "gold": 55863.86356,
      "pb": 4.43,
      "pe": 22.03,
      "yield": 1.38,
      "date": "2023-08-28"
  },
  "2023-08-29": {
      "equity": 19342.65,
      "debt": 7.18,
      "gold": 56047.03081,
      "pb": 4.42,
      "pe": 22.07,
      "yield": 1.38,
      "date": "2023-08-29"
  },
  "2023-08-30": {
      "equity": 19347.45,
      "debt": 7.185,
      "gold": 56484.99268,
      "pb": 4.42,
      "pe": 22.07,
      "yield": 1.38,
      "date": "2023-08-30"
  },
  "2023-08-31": {
      "equity": 19253.8,
      "debt": 7.166,
      "gold": 56712.25242,
      "pb": 4.4,
      "pe": 21.97,
      "yield": 1.38,
      "date": "2023-08-31"
  },
  "2023-09-01": {
      "equity": 19435.3,
      "debt": 7.175,
      "gold": 56707.28232,
      "pb": 4.44,
      "pe": 22.17,
      "yield": 1.37,
      "date": "2023-09-01"
  },
  "2023-09-02": {
      "equity": 19435.3,
      "debt": 7.175,
      "gold": 56707.28232,
      "pb": 4.44,
      "pe": 22.17,
      "yield": 1.37,
      "date": "2023-09-02"
  },
  "2023-09-03": {
      "equity": 19435.3,
      "debt": 7.175,
      "gold": 56707.28232,
      "pb": 4.44,
      "pe": 22.17,
      "yield": 1.37,
      "date": "2023-09-03"
  },
  "2023-09-04": {
      "equity": 19528.8,
      "debt": 7.204,
      "gold": 56671.93778,
      "pb": 4.46,
      "pe": 22.28,
      "yield": 1.36,
      "date": "2023-09-04"
  },
  "2023-09-05": {
      "equity": 19574.9,
      "debt": 7.206,
      "gold": 56593.87996,
      "pb": 4.46,
      "pe": 22.33,
      "yield": 1.36,
      "date": "2023-09-05"
  },
  "2023-09-06": {
      "equity": 19611.05,
      "debt": 7.21,
      "gold": 56478.17069,
      "pb": 4.43,
      "pe": 22.37,
      "yield": 1.36,
      "date": "2023-09-06"
  },
  "2023-09-07": {
      "equity": 19727.05,
      "debt": 7.173,
      "gold": 56317.53999,
      "pb": 4.41,
      "pe": 22.28,
      "yield": 1.36,
      "date": "2023-09-07"
  },
  "2023-09-08": {
      "equity": 19819.95,
      "debt": 7.199,
      "gold": 56375.80204,
      "pb": 4.43,
      "pe": 22.39,
      "yield": 1.36,
      "date": "2023-09-08"
  },
  "2023-09-09": {
      "equity": 19819.95,
      "debt": 7.199,
      "gold": 56375.80204,
      "pb": 4.43,
      "pe": 22.39,
      "yield": 1.36,
      "date": "2023-09-09"
  },
  "2023-09-10": {
      "equity": 19819.95,
      "debt": 7.199,
      "gold": 56375.80204,
      "pb": 4.43,
      "pe": 22.39,
      "yield": 1.36,
      "date": "2023-09-10"
  },
  "2023-09-11": {
      "equity": 19996.35,
      "debt": 7.209,
      "gold": 56303.92423,
      "pb": 4.46,
      "pe": 22.59,
      "yield": 1.35,
      "date": "2023-09-11"
  },
  "2023-09-12": {
      "equity": 19993.2,
      "debt": 7.203,
      "gold": 56092.7706,
      "pb": 4.44,
      "pe": 22.58,
      "yield": 1.35,
      "date": "2023-09-12"
  },
  "2023-09-13": {
      "equity": 20070,
      "debt": 7.172,
      "gold": 55915.35653,
      "pb": 4.46,
      "pe": 22.67,
      "yield": 1.34,
      "date": "2023-09-13"
  },
  "2023-09-14": {
      "equity": 20103.1,
      "debt": 7.101,
      "gold": 55846.11722,
      "pb": 4.47,
      "pe": 22.71,
      "yield": 1.34,
      "date": "2023-09-14"
  },
  "2023-09-15": {
      "equity": 20192.35,
      "debt": 7.158,
      "gold": 56262.42085,
      "pb": 4.47,
      "pe": 22.81,
      "yield": 1.33,
      "date": "2023-09-15"
  },
  "2023-09-16": {
      "equity": 20192.35,
      "debt": 7.158,
      "gold": 56262.42085,
      "pb": 4.47,
      "pe": 22.81,
      "yield": 1.33,
      "date": "2023-09-16"
  },
  "2023-09-17": {
      "equity": 20192.35,
      "debt": 7.158,
      "gold": 56262.42085,
      "pb": 4.47,
      "pe": 22.81,
      "yield": 1.33,
      "date": "2023-09-17"
  },
  "2023-09-18": {
      "equity": 20133.3,
      "debt": 7.149,
      "gold": 56585.5165,
      "pb": 4.46,
      "pe": 22.74,
      "yield": 1.34,
      "date": "2023-09-18"
  },
  "2023-09-19": {
      "equity": 20133.3,
      "debt": 7.149,
      "gold": 56793.49195,
      "pb": 4.46,
      "pe": 22.74,
      "yield": 1.34,
      "date": "2023-09-19"
  },
  "2023-09-20": {
      "equity": 19901.4,
      "debt": 7.153,
      "gold": 56428.09573,
      "pb": 4.41,
      "pe": 22.48,
      "yield": 1.35,
      "date": "2023-09-20"
  },
  "2023-09-21": {
      "equity": 19742.35,
      "debt": 7.136,
      "gold": 56402.6138,
      "pb": 4.37,
      "pe": 22.3,
      "yield": 1.36,
      "date": "2023-09-21"
  },
  "2023-09-22": {
      "equity": 19674.25,
      "debt": 7.15,
      "gold": 56375.67153,
      "pb": 4.36,
      "pe": 22.22,
      "yield": 1.37,
      "date": "2023-09-22"
  },
  "2023-09-23": {
      "equity": 19674.25,
      "debt": 7.15,
      "gold": 56375.67153,
      "pb": 4.36,
      "pe": 22.22,
      "yield": 1.37,
      "date": "2023-09-23"
  },
  "2023-09-24": {
      "equity": 19674.25,
      "debt": 7.15,
      "gold": 56375.67153,
      "pb": 4.36,
      "pe": 22.22,
      "yield": 1.37,
      "date": "2023-09-24"
  },
  "2023-09-25": {
      "equity": 19674.55,
      "debt": 7.15,
      "gold": 56368.66964,
      "pb": 4.36,
      "pe": 22.22,
      "yield": 1.37,
      "date": "2023-09-25"
  },
  "2023-09-26": {
      "equity": 19664.7,
      "debt": 7.146,
      "gold": 56191.245,
      "pb": 4.35,
      "pe": 22.21,
      "yield": 1.37,
      "date": "2023-09-26"
  },
  "2023-09-27": {
      "equity": 19716.45,
      "debt": 7.173,
      "gold": 55657.67297,
      "pb": 4.36,
      "pe": 22.27,
      "yield": 1.36,
      "date": "2023-09-27"
  },
  "2023-09-28": {
      "equity": 19523.55,
      "debt": 7.238,
      "gold": 54960.68714,
      "pb": 4.31,
      "pe": 22.05,
      "yield": 1.38,
      "date": "2023-09-28"
  },
  "2023-09-29": {
      "equity": 19638.3,
      "debt": 7.21,
      "gold": 54864.19866,
      "pb": 3.45,
      "pe": 22.21,
      "yield": 1.37,
      "date": "2023-09-29"
  },
  "2023-09-30": {
      "equity": 19638.3,
      "debt": 7.21,
      "gold": 54864.19866,
      "pb": 3.45,
      "pe": 22.21,
      "yield": 1.37,
      "date": "2023-09-30"
  },
  "2023-10-01": {
      "equity": 19638.3,
      "debt": 7.21,
      "gold": 54864.19866,
      "pb": 3.45,
      "pe": 22.21,
      "yield": 1.37,
      "date": "2023-10-01"
  },
  "2023-10-02": {
      "equity": 19638.3,
      "debt": 7.21,
      "gold": 53698.96471,
      "pb": 3.45,
      "pe": 22.21,
      "yield": 1.37,
      "date": "2023-10-02"
  },
  "2023-10-03": {
      "equity": 19528.75,
      "debt": 7.235,
      "gold": 53676.93257,
      "pb": 3.43,
      "pe": 22.09,
      "yield": 1.38,
      "date": "2023-10-03"
  },
  "2023-10-04": {
      "equity": 19436.1,
      "debt": 7.238,
      "gold": 53522.49599,
      "pb": 3.41,
      "pe": 21.98,
      "yield": 1.38,
      "date": "2023-10-04"
  },
  "2023-10-05": {
      "equity": 19545.75,
      "debt": 7.214,
      "gold": 53512.64043,
      "pb": 3.43,
      "pe": 22.11,
      "yield": 1.37,
      "date": "2023-10-05"
  },
  "2023-10-06": {
      "equity": 19653.5,
      "debt": 7.339,
      "gold": 53425.86289,
      "pb": 3.45,
      "pe": 22.23,
      "yield": 1.37,
      "date": "2023-10-06"
  },
  "2023-10-07": {
      "equity": 19653.5,
      "debt": 7.339,
      "gold": 53425.86289,
      "pb": 3.45,
      "pe": 22.23,
      "yield": 1.37,
      "date": "2023-10-07"
  },
  "2023-10-08": {
      "equity": 19653.5,
      "debt": 7.339,
      "gold": 53425.86289,
      "pb": 3.45,
      "pe": 22.23,
      "yield": 1.37,
      "date": "2023-10-08"
  },
  "2023-10-09": {
      "equity": 19512.35,
      "debt": 7.386,
      "gold": 54443.35526,
      "pb": 3.42,
      "pe": 22.07,
      "yield": 1.38,
      "date": "2023-10-09"
  },
  "2023-10-10": {
      "equity": 19689.85,
      "debt": 7.351,
      "gold": 54576.63098,
      "pb": 3.46,
      "pe": 22.27,
      "yield": 1.36,
      "date": "2023-10-10"
  },
  "2023-10-11": {
      "equity": 19811.35,
      "debt": 7.307,
      "gold": 54844.47345,
      "pb": 3.48,
      "pe": 22.41,
      "yield": 1.36,
      "date": "2023-10-11"
  },
  "2023-10-12": {
      "equity": 19794,
      "debt": 7.302,
      "gold": 55241.42577,
      "pb": 3.47,
      "pe": 22.39,
      "yield": 1.36,
      "date": "2023-10-12"
  },
  "2023-10-13": {
      "equity": 19751.05,
      "debt": 7.32,
      "gold": 55399.56966,
      "pb": 3.47,
      "pe": 22.33,
      "yield": 1.36,
      "date": "2023-10-13"
  },
  "2023-10-14": {
      "equity": 19751.05,
      "debt": 7.32,
      "gold": 55399.56966,
      "pb": 3.47,
      "pe": 22.33,
      "yield": 1.36,
      "date": "2023-10-14"
  },
  "2023-10-15": {
      "equity": 19751.05,
      "debt": 7.32,
      "gold": 55399.56966,
      "pb": 3.47,
      "pe": 22.33,
      "yield": 1.36,
      "date": "2023-10-15"
  },
  "2023-10-16": {
      "equity": 19731.75,
      "debt": 7.333,
      "gold": 56190.6665,
      "pb": 3.46,
      "pe": 22.29,
      "yield": 1.36,
      "date": "2023-10-16"
  },
  "2023-10-17": {
      "equity": 19811.5,
      "debt": 7.328,
      "gold": 56459.51075,
      "pb": 3.48,
      "pe": 22.38,
      "yield": 1.35,
      "date": "2023-10-17"
  },
  "2023-10-18": {
      "equity": 19671.1,
      "debt": 7.353,
      "gold": 57104.12177,
      "pb": 3.45,
      "pe": 21.87,
      "yield": 1.36,
      "date": "2023-10-18"
  },
  "2023-10-19": {
      "equity": 19624.7,
      "debt": 7.369,
      "gold": 57085.79693,
      "pb": 3.44,
      "pe": 21.77,
      "yield": 1.36,
      "date": "2023-10-19"
  },
  "2023-10-20": {
      "equity": 19542.65,
      "debt": 7.365,
      "gold": 58207.02658,
      "pb": 3.43,
      "pe": 21.68,
      "yield": 1.38,
      "date": "2023-10-20"
  },
  "2023-10-21": {
      "equity": 19542.65,
      "debt": 7.365,
      "gold": 58207.02658,
      "pb": 3.43,
      "pe": 21.68,
      "yield": 1.38,
      "date": "2023-10-21"
  },
  "2023-10-22": {
      "equity": 19542.65,
      "debt": 7.365,
      "gold": 58207.02658,
      "pb": 3.43,
      "pe": 21.68,
      "yield": 1.38,
      "date": "2023-10-22"
  },
  "2023-10-23": {
      "equity": 19281.75,
      "debt": 7.382,
      "gold": 58043.55632,
      "pb": 3.38,
      "pe": 21.36,
      "yield": 1.4,
      "date": "2023-10-23"
  },
  "2023-10-24": {
      "equity": 19281.75,
      "debt": 7.382,
      "gold": 57636.39923,
      "pb": 3.38,
      "pe": 21.36,
      "yield": 1.4,
      "date": "2023-10-24"
  },
  "2023-10-25": {
      "equity": 19122.15,
      "debt": 7.343,
      "gold": 57782.14783,
      "pb": 3.36,
      "pe": 20.92,
      "yield": 1.48,
      "date": "2023-10-25"
  },
  "2023-10-26": {
      "equity": 18857.25,
      "debt": 7.366,
      "gold": 58487.84635,
      "pb": 3.31,
      "pe": 20.63,
      "yield": 1.51,
      "date": "2023-10-26"
  },
  "2023-10-27": {
      "equity": 19047.25,
      "debt": 7.351,
      "gold": 58409.12891,
      "pb": 3.34,
      "pe": 20.84,
      "yield": 1.42,
      "date": "2023-10-27"
  },
  "2023-10-28": {
      "equity": 19047.25,
      "debt": 7.351,
      "gold": 58409.12891,
      "pb": 3.34,
      "pe": 20.84,
      "yield": 1.42,
      "date": "2023-10-28"
  },
  "2023-10-29": {
      "equity": 19047.25,
      "debt": 7.351,
      "gold": 58409.12891,
      "pb": 3.34,
      "pe": 20.84,
      "yield": 1.42,
      "date": "2023-10-29"
  },
  "2023-10-30": {
      "equity": 19140.9,
      "debt": 7.369,
      "gold": 58566.38389,
      "pb": 3.36,
      "pe": 20.9,
      "yield": 1.41,
      "date": "2023-10-30"
  },
  "2023-10-31": {
      "equity": 19079.6,
      "debt": 7.351,
      "gold": 58682.28011,
      "pb": 3.35,
      "pe": 20.45,
      "yield": 1.41,
      "date": "2023-10-31"
  },
  "2023-11-01": {
      "equity": 18989.15,
      "debt": 7.358,
      "gold": 58236.10646,
      "pb": 3.33,
      "pe": 20.4,
      "yield": 1.41,
      "date": "2023-11-01"
  },
  "2023-11-02": {
      "equity": 19133.25,
      "debt": 7.322,
      "gold": 58350.16138,
      "pb": 3.36,
      "pe": 20.77,
      "yield": 1.42,
      "date": "2023-11-02"
  },
  "2023-11-03": {
      "equity": 19230.6,
      "debt": 7.317,
      "gold": 58281.26422,
      "pb": 3.37,
      "pe": 20.88,
      "yield": 1.43,
      "date": "2023-11-03"
  },
  "2023-11-04": {
      "equity": 19230.6,
      "debt": 7.317,
      "gold": 58281.26422,
      "pb": 3.37,
      "pe": 20.88,
      "yield": 1.43,
      "date": "2023-11-04"
  },
  "2023-11-05": {
      "equity": 19230.6,
      "debt": 7.317,
      "gold": 58281.26422,
      "pb": 3.37,
      "pe": 20.88,
      "yield": 1.43,
      "date": "2023-11-05"
  },
  "2023-11-06": {
      "equity": 19411.75,
      "debt": 7.305,
      "gold": 58327.1451,
      "pb": 3.41,
      "pe": 20.96,
      "yield": 1.42,
      "date": "2023-11-06"
  },
  "2023-11-07": {
      "equity": 19406.7,
      "debt": 7.28,
      "gold": 57774.51807,
      "pb": 3.41,
      "pe": 20.92,
      "yield": 1.42,
      "date": "2023-11-07"
  },
  "2023-11-08": {
      "equity": 19443.5,
      "debt": 7.266,
      "gold": 57540.42576,
      "pb": 3.41,
      "pe": 20.97,
      "yield": 1.42,
      "date": "2023-11-08"
  },
  "2023-11-09": {
      "equity": 19395.3,
      "debt": 7.269,
      "gold": 57193.36496,
      "pb": 3.4,
      "pe": 20.91,
      "yield": 1.41,
      "date": "2023-11-09"
  },
  "2023-11-10": {
      "equity": 19425.35,
      "debt": 7.3,
      "gold": 57394.13041,
      "pb": 3.41,
      "pe": 20.95,
      "yield": 1.4,
      "date": "2023-11-10"
  },
  "2023-11-11": {
      "equity": 19425.35,
      "debt": 7.3,
      "gold": 57394.13041,
      "pb": 3.41,
      "pe": 20.95,
      "yield": 1.4,
      "date": "2023-11-11"
  },
  "2023-11-12": {
      "equity": 19525.55,
      "debt": 7.3,
      "gold": 57394.13041,
      "pb": 3.43,
      "pe": 21.05,
      "yield": 1.4,
      "date": "2023-11-12"
  },
  "2023-11-13": {
      "equity": 19443.55,
      "debt": 7.283,
      "gold": 56883.92035,
      "pb": 3.41,
      "pe": 20.96,
      "yield": 1.4,
      "date": "2023-11-13"
  },
  "2023-11-14": {
      "equity": 19443.55,
      "debt": 7.283,
      "gold": 57146.42939,
      "pb": 3.41,
      "pe": 20.96,
      "yield": 1.4,
      "date": "2023-11-14"
  },
  "2023-11-15": {
      "equity": 19675.45,
      "debt": 7.225,
      "gold": 57893.68419,
      "pb": 3.45,
      "pe": 21.05,
      "yield": 1.33,
      "date": "2023-11-15"
  },
  "2023-11-16": {
      "equity": 19765.2,
      "debt": 7.232,
      "gold": 57722.26671,
      "pb": 3.47,
      "pe": 21.13,
      "yield": 1.34,
      "date": "2023-11-16"
  },
  "2023-11-17": {
      "equity": 19731.8,
      "debt": 7.212,
      "gold": 58507.14122,
      "pb": 3.46,
      "pe": 21.1,
      "yield": 1.34,
      "date": "2023-11-17"
  },
  "2023-11-18": {
      "equity": 19731.8,
      "debt": 7.212,
      "gold": 58507.14122,
      "pb": 3.46,
      "pe": 21.1,
      "yield": 1.34,
      "date": "2023-11-18"
  },
  "2023-11-19": {
      "equity": 19731.8,
      "debt": 7.212,
      "gold": 58507.14122,
      "pb": 3.46,
      "pe": 21.1,
      "yield": 1.34,
      "date": "2023-11-19"
  },
  "2023-11-20": {
      "equity": 19694,
      "debt": 7.252,
      "gold": 58099.82187,
      "pb": 3.46,
      "pe": 21.06,
      "yield": 1.35,
      "date": "2023-11-20"
  },
  "2023-11-21": {
      "equity": 19783.4,
      "debt": 7.269,
      "gold": 58415.30186,
      "pb": 3.47,
      "pe": 21.14,
      "yield": 1.38,
      "date": "2023-11-21"
  },
  "2023-11-22": {
      "equity": 19811.85,
      "debt": 7.247,
      "gold": 58771.43159,
      "pb": 3.47,
      "pe": 21.17,
      "yield": 1.38,
      "date": "2023-11-22"
  },
  "2023-11-23": {
      "equity": 19802,
      "debt": 7.253,
      "gold": 58534.97593,
      "pb": 3.47,
      "pe": 21.16,
      "yield": 1.38,
      "date": "2023-11-23"
  },
  "2023-11-24": {
      "equity": 19794.7,
      "debt": 7.272,
      "gold": 58618.39186,
      "pb": 3.47,
      "pe": 21.15,
      "yield": 1.38,
      "date": "2023-11-24"
  },
  "2023-11-25": {
      "equity": 19794.7,
      "debt": 7.272,
      "gold": 58618.39186,
      "pb": 3.47,
      "pe": 21.15,
      "yield": 1.38,
      "date": "2023-11-25"
  },
  "2023-11-26": {
      "equity": 19794.7,
      "debt": 7.272,
      "gold": 58618.39186,
      "pb": 3.47,
      "pe": 21.15,
      "yield": 1.38,
      "date": "2023-11-26"
  },
  "2023-11-27": {
      "equity": 19794.7,
      "debt": 7.272,
      "gold": 59103.15526,
      "pb": 3.47,
      "pe": 21.15,
      "yield": 1.38,
      "date": "2023-11-27"
  },
  "2023-11-28": {
      "equity": 19889.7,
      "debt": 7.273,
      "gold": 59215.20309,
      "pb": 3.49,
      "pe": 21.26,
      "yield": 1.37,
      "date": "2023-11-28"
  },
  "2023-11-29": {
      "equity": 20096.6,
      "debt": 7.252,
      "gold": 59898.23101,
      "pb": 3.52,
      "pe": 21.48,
      "yield": 1.36,
      "date": "2023-11-29"
  },
  "2023-11-30": {
      "equity": 20133.15,
      "debt": 7.279,
      "gold": 59946.55285,
      "pb": 3.53,
      "pe": 21.52,
      "yield": 1.35,
      "date": "2023-11-30"
  },
  "2023-12-01": {
      "equity": 20267.9,
      "debt": 7.29,
      "gold": 60049.81746,
      "pb": 3.55,
      "pe": 21.66,
      "yield": 1.34,
      "date": "2023-12-01"
  },
  "2023-12-02": {
      "equity": 20267.9,
      "debt": 7.29,
      "gold": 60049.81746,
      "pb": 3.55,
      "pe": 21.66,
      "yield": 1.34,
      "date": "2023-12-02"
  },
  "2023-12-03": {
      "equity": 20267.9,
      "debt": 7.29,
      "gold": 60049.81746,
      "pb": 3.55,
      "pe": 21.66,
      "yield": 1.34,
      "date": "2023-12-03"
  },
  "2023-12-04": {
      "equity": 20686.8,
      "debt": 7.271,
      "gold": 60770.12998,
      "pb": 3.63,
      "pe": 22.11,
      "yield": 1.32,
      "date": "2023-12-04"
  },
  "2023-12-05": {
      "equity": 20855.1,
      "debt": 7.262,
      "gold": 59486.91159,
      "pb": 3.66,
      "pe": 22.29,
      "yield": 1.31,
      "date": "2023-12-05"
  },
  "2023-12-06": {
      "equity": 20937.7,
      "debt": 7.248,
      "gold": 59419.36895,
      "pb": 3.67,
      "pe": 22.38,
      "yield": 1.3,
      "date": "2023-12-06"
  },
  "2023-12-07": {
      "equity": 20901.15,
      "debt": 7.236,
      "gold": 59776.05954,
      "pb": 3.67,
      "pe": 22.34,
      "yield": 1.3,
      "date": "2023-12-07"
  },
  "2023-12-08": {
      "equity": 20969.4,
      "debt": 7.266,
      "gold": 59755.37487,
      "pb": 3.68,
      "pe": 22.41,
      "yield": 1.3,
      "date": "2023-12-08"
  },
  "2023-12-09": {
      "equity": 20969.4,
      "debt": 7.266,
      "gold": 59755.37487,
      "pb": 3.68,
      "pe": 22.41,
      "yield": 1.3,
      "date": "2023-12-09"
  },
  "2023-12-10": {
      "equity": 20969.4,
      "debt": 7.266,
      "gold": 59755.37487,
      "pb": 3.68,
      "pe": 22.41,
      "yield": 1.3,
      "date": "2023-12-10"
  },
  "2023-12-11": {
      "equity": 20997.1,
      "debt": 7.281,
      "gold": 58585.08968,
      "pb": 3.68,
      "pe": 22.44,
      "yield": 1.3,
      "date": "2023-12-11"
  },
  "2023-12-12": {
      "equity": 20906.4,
      "debt": 7.275,
      "gold": 58416.59289,
      "pb": 3.67,
      "pe": 22.34,
      "yield": 1.33,
      "date": "2023-12-12"
  },
  "2023-12-13": {
      "equity": 20926.35,
      "debt": 7.259,
      "gold": 58259.994,
      "pb": 3.67,
      "pe": 22.36,
      "yield": 1.32,
      "date": "2023-12-13"
  },
  "2023-12-14": {
      "equity": 21182.7,
      "debt": 7.194,
      "gold": 59758.46488,
      "pb": 3.71,
      "pe": 22.63,
      "yield": 1.31,
      "date": "2023-12-14"
  },
  "2023-12-15": {
      "equity": 21456.65,
      "debt": 7.163,
      "gold": 59847.20013,
      "pb": 3.76,
      "pe": 22.92,
      "yield": 1.29,
      "date": "2023-12-15"
  },
  "2023-12-16": {
      "equity": 21456.65,
      "debt": 7.163,
      "gold": 59847.20013,
      "pb": 3.76,
      "pe": 22.92,
      "yield": 1.29,
      "date": "2023-12-16"
  },
  "2023-12-17": {
      "equity": 21456.65,
      "debt": 7.163,
      "gold": 59847.20013,
      "pb": 3.76,
      "pe": 22.92,
      "yield": 1.29,
      "date": "2023-12-17"
  },
  "2023-12-18": {
      "equity": 21418.65,
      "debt": 7.153,
      "gold": 59225.21738,
      "pb": 3.76,
      "pe": 22.88,
      "yield": 1.29,
      "date": "2023-12-18"
  },
  "2023-12-19": {
      "equity": 21453.1,
      "debt": 7.171,
      "gold": 59375.42461,
      "pb": 3.76,
      "pe": 22.92,
      "yield": 1.29,
      "date": "2023-12-19"
  },
  "2023-12-20": {
      "equity": 21150.15,
      "debt": 7.171,
      "gold": 59712.43937,
      "pb": 3.71,
      "pe": 22.6,
      "yield": 1.31,
      "date": "2023-12-20"
  },
  "2023-12-21": {
      "equity": 21255.05,
      "debt": 7.184,
      "gold": 59719.77636,
      "pb": 3.73,
      "pe": 22.71,
      "yield": 1.3,
      "date": "2023-12-21"
  },
  "2023-12-22": {
      "equity": 21349.4,
      "debt": 7.188,
      "gold": 60307.58214,
      "pb": 3.74,
      "pe": 22.81,
      "yield": 1.3,
      "date": "2023-12-22"
  },
  "2023-12-23": {
      "equity": 21349.4,
      "debt": 7.188,
      "gold": 60307.58214,
      "pb": 3.74,
      "pe": 22.81,
      "yield": 1.3,
      "date": "2023-12-23"
  },
  "2023-12-24": {
      "equity": 21349.4,
      "debt": 7.188,
      "gold": 60307.58214,
      "pb": 3.74,
      "pe": 22.81,
      "yield": 1.3,
      "date": "2023-12-24"
  },
  "2023-12-25": {
      "equity": 21349.4,
      "debt": 7.188,
      "gold": 60307.58214,
      "pb": 3.74,
      "pe": 22.81,
      "yield": 1.3,
      "date": "2023-12-25"
  },
  "2023-12-26": {
      "equity": 21441.35,
      "debt": 7.183,
      "gold": 60307.58214,
      "pb": 3.76,
      "pe": 22.91,
      "yield": 1.29,
      "date": "2023-12-26"
  },
  "2023-12-27": {
      "equity": 21654.75,
      "debt": 7.205,
      "gold": 60558.17916,
      "pb": 3.8,
      "pe": 23.14,
      "yield": 1.28,
      "date": "2023-12-27"
  },
  "2023-12-28": {
      "equity": 21778.7,
      "debt": 7.207,
      "gold": 60961.67481,
      "pb": 3.82,
      "pe": 23.27,
      "yield": 1.27,
      "date": "2023-12-28"
  },
  "2023-12-29": {
      "equity": 21731.4,
      "debt": 7.176,
      "gold": 60543.74857,
      "pb": 3.81,
      "pe": 23.17,
      "yield": 1.28,
      "date": "2023-12-29"
  },
  "2023-12-30": {
      "equity": 21731.4,
      "debt": 7.176,
      "gold": 60543.74857,
      "pb": 3.81,
      "pe": 23.17,
      "yield": 1.28,
      "date": "2023-12-30"
  },
  "2023-12-31": {
      "equity": 21731.4,
      "debt": 7.176,
      "gold": 60543.74857,
      "pb": 3.81,
      "pe": 23.17,
      "yield": 1.28,
      "date": "2023-12-31"
  },
  "2024-01-01": {
      "equity": 21741.9,
      "debt": 7.196,
      "gold": 60543.74857,
      "pb": 3.81,
      "pe": 23.18,
      "yield": 1.28,
      "date": "2024-01-01"
  }
}