import './App.css';
import React from "react";
import { Howl, Howler } from 'howler';
import {Link} from 'react-router-dom';

function App () {

  const KeySound = new Howl({
    src: ['sounds/keystroke.mp3'],
    volume: 0.3
  });

  const onHover = () => {
    KeySound.play();
  }

  return (
    <div className="App">
      <div className="big-logo">
        <a href='https://www.investmyfunds.in' target='_blank'  rel="noreferrer">
          <img className='logoImg' src='/green_logo.png' alt='Investmyfunds.in'></img>
        </a>
      </div>
      <div className="center mt-5">
        <div>
          <button className='btn btn-lg nav-btn' onMouseEnter={onHover}><Link className="nav-link" to="/invest">Investment Game</Link></button>
          <button className='btn btn-lg nav-btn ms-5' onMouseEnter={onHover}><Link className="nav-link" to="/crossword">Crossword Game</Link></button>
        </div>
      </div>
    </div>
  )
}

export default App