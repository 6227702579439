export const equity = [
  {
      "Date": "22-Apr-96",
      "Open": "1136.28",
      "High": "1136.28",
      "Low": "1102.83",
      "Close": "1106.9"
  },
  {
      "Date": "23-Apr-96",
      "Open": "1090.04",
      "High": "1100.51",
      "Low": "1090.04",
      "Close": "1095.8"
  },
  {
      "Date": "24-Apr-96",
      "Open": "1136.97",
      "High": "1145.11",
      "Low": "1126.77",
      "Close": "1145.1"
  },
  {
      "Date": "25-Apr-96",
      "Open": "1157.94",
      "High": "1160.16",
      "Low": "1110.61",
      "Close": "1120.8"
  },
  {
      "Date": "26-Apr-96",
      "Open": "1133.17",
      "High": "1133.17",
      "Low": "1106.29",
      "Close": "1123.6"
  },
  {
      "Date": "29-Apr-96",
      "Open": "1112.7",
      "High": "1118.69",
      "Low": "1107.51",
      "Close": "1118.3"
  },
  {
      "Date": "30-Apr-96",
      "Open": "1118.1",
      "High": "1118.23",
      "Low": "1112.44",
      "Close": "1114.3"
  },
  {
      "Date": "1-May-96",
      "Open": "1141.65",
      "High": "1141.65",
      "Low": "1133.92",
      "Close": "1137.8"
  },
  {
      "Date": "2-May-96",
      "Open": "1131.96",
      "High": "1132.05",
      "Low": "1123.15",
      "Close": "1132"
  },
  {
      "Date": "3-May-96",
      "Open": "1126.08",
      "High": "1127.99",
      "Low": "1119.34",
      "Close": "1119.4"
  },
  {
      "Date": "6-May-96",
      "Open": "1108",
      "High": "1108",
      "Low": "1087.86",
      "Close": "1088.2"
  },
  {
      "Date": "7-May-96",
      "Open": "1086.66",
      "High": "1094.97",
      "Low": "1086.66",
      "Close": "1093"
  },
  {
      "Date": "8-May-96",
      "Open": "1082.78",
      "High": "1113.62",
      "Low": "1075.94",
      "Close": "1108.5"
  },
  {
      "Date": "9-May-96",
      "Open": "1101.41",
      "High": "1107.41",
      "Low": "1089.87",
      "Close": "1090.5"
  },
  {
      "Date": "10-May-96",
      "Open": "1076.97",
      "High": "1080.91",
      "Low": "1063.75",
      "Close": "1078.6"
  },
  {
      "Date": "13-May-96",
      "Open": "1081.68",
      "High": "1090.16",
      "Low": "1080.68",
      "Close": "1088.1"
  },
  {
      "Date": "14-May-96",
      "Open": "1087.94",
      "High": "1107.44",
      "Low": "1085.85",
      "Close": "1105.4"
  },
  {
      "Date": "15-May-96",
      "Open": "1107",
      "High": "1117.41",
      "Low": "1095.67",
      "Close": "1115.9"
  },
  {
      "Date": "16-May-96",
      "Open": "1128.45",
      "High": "1141.64",
      "Low": "1126.29",
      "Close": "1128.5"
  },
  {
      "Date": "5/17/96",
      "Open": "1122.42",
      "High": "1122.42",
      "Low": "1113.59",
      "Close": "1115.5"
  },
  {
      "Date": "5/20/96",
      "Open": "1094.6",
      "High": "1094.6",
      "Low": "1083.56",
      "Close": "1083.6"
  },
  {
      "Date": "5/21/96",
      "Open": "1077.18",
      "High": "1082.52",
      "Low": "1074.19",
      "Close": "1074.8"
  },
  {
      "Date": "5/22/96",
      "Open": "1082.25",
      "High": "1086.07",
      "Low": "1079.61",
      "Close": "1081.1"
  },
  {
      "Date": "5/23/96",
      "Open": "1079.89",
      "High": "1079.89",
      "Low": "1073.76",
      "Close": "1076.7"
  },
  {
      "Date": "5/24/96",
      "Open": "1079.77",
      "High": "1087.77",
      "Low": "1077.87",
      "Close": "1085"
  },
  {
      "Date": "5/27/96",
      "Open": "1080.55",
      "High": "1080.55",
      "Low": "1065.29",
      "Close": "1065.2"
  },
  {
      "Date": "5/28/96",
      "Open": "1054.62",
      "High": "1065.61",
      "Low": "1046.95",
      "Close": "1063.3"
  },
  {
      "Date": "5/29/96",
      "Open": "1074.23",
      "High": "1100.59",
      "Low": "1074.23",
      "Close": "1096.7"
  },
  {
      "Date": "5/30/96",
      "Open": "1095.86",
      "High": "1095.86",
      "Low": "1082.58",
      "Close": "1082.8"
  },
  {
      "Date": "5/31/96",
      "Open": "1086.46",
      "High": "1089.92",
      "Low": "1083.73",
      "Close": "1089.9"
  },
  {
      "Date": "6/3/96",
      "Open": "1098.18",
      "High": "1109.99",
      "Low": "1098.18",
      "Close": "1108.8"
  },
  {
      "Date": "6/4/96",
      "Open": "1114.55",
      "High": "1118.13",
      "Low": "1104.32",
      "Close": "1105.6"
  },
  {
      "Date": "6/5/96",
      "Open": "1108.78",
      "High": "1115.06",
      "Low": "1104.45",
      "Close": "1113.1"
  },
  {
      "Date": "6/6/96",
      "Open": "1129.17",
      "High": "1132.62",
      "Low": "1127.52",
      "Close": "1129.8"
  },
  {
      "Date": "6/7/96",
      "Open": "1126.39",
      "High": "1134.86",
      "Low": "1126.39",
      "Close": "1133.4"
  },
  {
      "Date": "6/10/96",
      "Open": "1136.86",
      "High": "1144.15",
      "Low": "1136.86",
      "Close": "1141.3"
  },
  {
      "Date": "6/11/96",
      "Open": "1137.74",
      "High": "1145.68",
      "Low": "1136.77",
      "Close": "1143"
  },
  {
      "Date": "6/12/96",
      "Open": "1164.83",
      "High": "1171.01",
      "Low": "1163.58",
      "Close": "1163.8"
  },
  {
      "Date": "6/13/96",
      "Open": "1166.37",
      "High": "1172.34",
      "Low": "1164.66",
      "Close": "1168.4"
  },
  {
      "Date": "6/14/96",
      "Open": "1169.26",
      "High": "1198.35",
      "Low": "1169.26",
      "Close": "1196"
  },
  {
      "Date": "6/17/96",
      "Open": "1202.58",
      "High": "1203.11",
      "Low": "1184.36",
      "Close": "1185.3"
  },
  {
      "Date": "6/18/96",
      "Open": "1176.48",
      "High": "1184.18",
      "Low": "1173.66",
      "Close": "1181.9"
  },
  {
      "Date": "6/19/96",
      "Open": "1180.78",
      "High": "1190.03",
      "Low": "1163.46",
      "Close": "1168"
  },
  {
      "Date": "6/20/96",
      "Open": "1163.68",
      "High": "1170.54",
      "Low": "1150.4",
      "Close": "1169.4"
  },
  {
      "Date": "6/21/96",
      "Open": "1168.72",
      "High": "1170.01",
      "Low": "1155.56",
      "Close": "1157.4"
  },
  {
      "Date": "6/24/96",
      "Open": "1157.92",
      "High": "1159.92",
      "Low": "1138.88",
      "Close": "1146.7"
  },
  {
      "Date": "6/25/96",
      "Open": "1147.28",
      "High": "1154.22",
      "Low": "1123.79",
      "Close": "1127.7"
  },
  {
      "Date": "6/26/96",
      "Open": "1127.43",
      "High": "1134.43",
      "Low": "1116.96",
      "Close": "1119.8"
  },
  {
      "Date": "6/27/96",
      "Open": "1119",
      "High": "1130",
      "Low": "1107",
      "Close": "1127"
  },
  {
      "Date": "6/28/96",
      "Open": "1128",
      "High": "1136",
      "Low": "1119",
      "Close": "1122"
  },
  {
      "Date": "7/1/96",
      "Open": "1121",
      "High": "1121",
      "Low": "1105",
      "Close": "1109"
  },
  {
      "Date": "7/2/96",
      "Open": "1109",
      "High": "1111",
      "Low": "1098",
      "Close": "1100"
  },
  {
      "Date": "7/3/96",
      "Open": "1100",
      "High": "1100",
      "Low": "1076",
      "Close": "1080"
  },
  {
      "Date": "7/4/96",
      "Open": "1080",
      "High": "1099",
      "Low": "1056",
      "Close": "1088"
  },
  {
      "Date": "7/5/96",
      "Open": "1089",
      "High": "1104",
      "Low": "1088",
      "Close": "1093"
  },
  {
      "Date": "7/8/96",
      "Open": "1095.08",
      "High": "1106.36",
      "Low": "1095.08",
      "Close": "1103.4"
  },
  {
      "Date": "7/9/96",
      "Open": "1104.01",
      "High": "1104.13",
      "Low": "1082.41",
      "Close": "1088.7"
  },
  {
      "Date": "7/10/96",
      "Open": "1083.49",
      "High": "1093.27",
      "Low": "1072.48",
      "Close": "1086.6"
  },
  {
      "Date": "7/11/96",
      "Open": "1089.45",
      "High": "1103.19",
      "Low": "1089.45",
      "Close": "1097.4"
  },
  {
      "Date": "7/12/96",
      "Open": "1096.85",
      "High": "1099.2",
      "Low": "1086.07",
      "Close": "1089.3"
  },
  {
      "Date": "7/15/96",
      "Open": "1089",
      "High": "1098.35",
      "Low": "1081.22",
      "Close": "1093.3"
  },
  {
      "Date": "7/16/96",
      "Open": "1100.14",
      "High": "1105.94",
      "Low": "1076.92",
      "Close": "1083.4"
  },
  {
      "Date": "7/18/96",
      "Open": "1109.17",
      "High": "1126.14",
      "Low": "1109.17",
      "Close": "1118.6"
  },
  {
      "Date": "7/19/96",
      "Open": "1118.92",
      "High": "1126.71",
      "Low": "1112.35",
      "Close": "1119.2"
  },
  {
      "Date": "7/22/96",
      "Open": "1119.39",
      "High": "1126.29",
      "Low": "1115.13",
      "Close": "1125.6"
  },
  {
      "Date": "7/23/96",
      "Open": "1125.15",
      "High": "1127.08",
      "Low": "1086.28",
      "Close": "1092.1"
  },
  {
      "Date": "7/24/96",
      "Open": "1090.74",
      "High": "1090.74",
      "Low": "1049.88",
      "Close": "1069.4"
  },
  {
      "Date": "7/25/96",
      "Open": "1069.27",
      "High": "1075.79",
      "Low": "1060.6",
      "Close": "1062.6"
  },
  {
      "Date": "7/26/96",
      "Open": "1062.04",
      "High": "1066.12",
      "Low": "1046.46",
      "Close": "1051.2"
  },
  {
      "Date": "7/29/96",
      "Open": "1054.51",
      "High": "1057.84",
      "Low": "1030.87",
      "Close": "1033.9"
  },
  {
      "Date": "7/30/96",
      "Open": "1031.01",
      "High": "1031.01",
      "Low": "999.03",
      "Close": "1014.9"
  },
  {
      "Date": "7/31/96",
      "Open": "1017.06",
      "High": "1047.04",
      "Low": "1004.76",
      "Close": "1042.8"
  },
  {
      "Date": "8/1/96",
      "Open": "1047.47",
      "High": "1054.08",
      "Low": "1035.3",
      "Close": "1036.6"
  },
  {
      "Date": "8/2/96",
      "Open": "1036.34",
      "High": "1036.48",
      "Low": "1024.8",
      "Close": "1032.9"
  },
  {
      "Date": "8/5/96",
      "Open": "1035.73",
      "High": "1038.2",
      "Low": "1030.34",
      "Close": "1032.2"
  },
  {
      "Date": "8/6/96",
      "Open": "1031.8",
      "High": "1038.04",
      "Low": "1023.73",
      "Close": "1035.5"
  },
  {
      "Date": "8/7/96",
      "Open": "1036.59",
      "High": "1050.54",
      "Low": "1033.52",
      "Close": "1048.5"
  },
  {
      "Date": "8/8/96",
      "Open": "1050.43",
      "High": "1054.29",
      "Low": "1037.94",
      "Close": "1039.2"
  },
  {
      "Date": "8/9/96",
      "Open": "1038.76",
      "High": "1042.25",
      "Low": "1033.46",
      "Close": "1037.9"
  },
  {
      "Date": "8/12/96",
      "Open": "1038.11",
      "High": "1039.93",
      "Low": "1030.4",
      "Close": "1032.1"
  },
  {
      "Date": "8/13/96",
      "Open": "1031.05",
      "High": "1031.05",
      "Low": "1021.67",
      "Close": "1024.4"
  },
  {
      "Date": "8/14/96",
      "Open": "1021.18",
      "High": "1027.95",
      "Low": "1017.43",
      "Close": "1018.4"
  },
  {
      "Date": "8/16/96",
      "Open": "1018.28",
      "High": "1019.64",
      "Low": "1001.35",
      "Close": "1002.2"
  },
  {
      "Date": "8/19/96",
      "Open": "1001.19",
      "High": "1001.19",
      "Low": "972.96",
      "Close": "975.66"
  },
  {
      "Date": "8/20/96",
      "Open": "973.13",
      "High": "980.78",
      "Low": "968.76",
      "Close": "977.34"
  },
  {
      "Date": "8/21/96",
      "Open": "980.23",
      "High": "1001.84",
      "Low": "980.23",
      "Close": "999.19"
  },
  {
      "Date": "8/22/96",
      "Open": "1001.45",
      "High": "1010.4",
      "Low": "999.85",
      "Close": "1002.1"
  },
  {
      "Date": "8/23/96",
      "Open": "1001.38",
      "High": "1014.78",
      "Low": "1000.49",
      "Close": "1012.7"
  },
  {
      "Date": "8/26/96",
      "Open": "1011.12",
      "High": "1016.63",
      "Low": "1007.25",
      "Close": "1009"
  },
  {
      "Date": "8/27/96",
      "Open": "1009.9",
      "High": "1016.59",
      "Low": "1006.02",
      "Close": "1014.6"
  },
  {
      "Date": "8/28/96",
      "Open": "1015.01",
      "High": "1029.49",
      "Low": "1015.01",
      "Close": "1027"
  },
  {
      "Date": "8/29/96",
      "Open": "1028.7",
      "High": "1035.59",
      "Low": "1024.67",
      "Close": "1025.8"
  },
  {
      "Date": "8/30/96",
      "Open": "1025.91",
      "High": "1029.89",
      "Low": "1023.44",
      "Close": "1029"
  },
  {
      "Date": "9/2/96",
      "Open": "1028.91",
      "High": "1039.98",
      "Low": "1028.91",
      "Close": "1036.9"
  },
  {
      "Date": "9/3/96",
      "Open": "1039.84",
      "High": "1046.51",
      "Low": "1017.46",
      "Close": "1023.2"
  },
  {
      "Date": "9/4/96",
      "Open": "1022.37",
      "High": "1028.26",
      "Low": "1012.15",
      "Close": "1026.4"
  },
  {
      "Date": "9/5/96",
      "Open": "1028.06",
      "High": "1038.31",
      "Low": "1028.06",
      "Close": "1037.4"
  },
  {
      "Date": "9/6/96",
      "Open": "1038.04",
      "High": "1038.79",
      "Low": "1029.96",
      "Close": "1032"
  },
  {
      "Date": "9/9/96",
      "Open": "1030.42",
      "High": "1031.22",
      "Low": "1012.34",
      "Close": "1013.8"
  },
  {
      "Date": "9/10/96",
      "Open": "1014.87",
      "High": "1014.87",
      "Low": "996.75",
      "Close": "1001.1"
  },
  {
      "Date": "9/11/96",
      "Open": "1001.86",
      "High": "1008.79",
      "Low": "989.71",
      "Close": "991.34"
  },
  {
      "Date": "9/12/96",
      "Open": "990.88",
      "High": "992.68",
      "Low": "981.01",
      "Close": "989.28"
  },
  {
      "Date": "9/13/96",
      "Open": "991.65",
      "High": "991.86",
      "Low": "979.8",
      "Close": "980.72"
  },
  {
      "Date": "9/16/96",
      "Open": "980.69",
      "High": "980.69",
      "Low": "959.65",
      "Close": "962.27"
  },
  {
      "Date": "9/17/96",
      "Open": "965.02",
      "High": "975.19",
      "Low": "962.93",
      "Close": "971.21"
  },
  {
      "Date": "9/18/96",
      "Open": "972.73",
      "High": "996.97",
      "Low": "969.67",
      "Close": "994.43"
  },
  {
      "Date": "9/19/96",
      "Open": "996.41",
      "High": "1001.25",
      "Low": "975.85",
      "Close": "977.76"
  },
  {
      "Date": "9/20/96",
      "Open": "979.14",
      "High": "984.45",
      "Low": "973.09",
      "Close": "977.03"
  },
  {
      "Date": "9/23/96",
      "Open": "976.08",
      "High": "976.08",
      "Low": "963.19",
      "Close": "968.84"
  },
  {
      "Date": "9/24/96",
      "Open": "968.78",
      "High": "972.74",
      "Low": "965.69",
      "Close": "968.91"
  },
  {
      "Date": "9/25/96",
      "Open": "967.98",
      "High": "981.75",
      "Low": "967.98",
      "Close": "971.29"
  },
  {
      "Date": "9/26/96",
      "Open": "970.76",
      "High": "970.89",
      "Low": "955.6",
      "Close": "959.31"
  },
  {
      "Date": "9/27/96",
      "Open": "958.79",
      "High": "958.83",
      "Low": "942.05",
      "Close": "947.23"
  },
  {
      "Date": "9/30/96",
      "Open": "945.68",
      "High": "948.41",
      "Low": "932.27",
      "Close": "943.96"
  },
  {
      "Date": "10/1/96",
      "Open": "944.51",
      "High": "945.66",
      "Low": "933.56",
      "Close": "936.27"
  },
  {
      "Date": "10/3/96",
      "Open": "937.16",
      "High": "943.38",
      "Low": "921.18",
      "Close": "924.92"
  },
  {
      "Date": "10/4/96",
      "Open": "924.93",
      "High": "925.93",
      "Low": "898.47",
      "Close": "904.12"
  },
  {
      "Date": "10/7/96",
      "Open": "905.76",
      "High": "905.76",
      "Low": "859.65",
      "Close": "874"
  },
  {
      "Date": "10/8/96",
      "Open": "872.57",
      "High": "876.78",
      "Low": "850.52",
      "Close": "873.9"
  },
  {
      "Date": "10/9/96",
      "Open": "873.18",
      "High": "875.85",
      "Low": "859.35",
      "Close": "874.72"
  },
  {
      "Date": "10/10/96",
      "Open": "874.2",
      "High": "900.53",
      "Low": "872.83",
      "Close": "898.42"
  },
  {
      "Date": "10/11/96",
      "Open": "900.11",
      "High": "915.59",
      "Low": "897.6",
      "Close": "908.79"
  },
  {
      "Date": "10/14/96",
      "Open": "907.26",
      "High": "907.26",
      "Low": "890.71",
      "Close": "892.05"
  },
  {
      "Date": "10/15/96",
      "Open": "892.07",
      "High": "902.74",
      "Low": "885.23",
      "Close": "897.77"
  },
  {
      "Date": "10/16/96",
      "Open": "896.63",
      "High": "905.11",
      "Low": "891.16",
      "Close": "892.77"
  },
  {
      "Date": "10/17/96",
      "Open": "891.42",
      "High": "893.33",
      "Low": "880.76",
      "Close": "892.07"
  },
  {
      "Date": "10/18/96",
      "Open": "892.61",
      "High": "899.51",
      "Low": "888.61",
      "Close": "895"
  },
  {
      "Date": "10/22/96",
      "Open": "896.87",
      "High": "924.83",
      "Low": "896.87",
      "Close": "919.09"
  },
  {
      "Date": "10/23/96",
      "Open": "924.2",
      "High": "953.44",
      "Low": "924.2",
      "Close": "951.29"
  },
  {
      "Date": "10/24/96",
      "Open": "953.48",
      "High": "956.45",
      "Low": "928.45",
      "Close": "932.77"
  },
  {
      "Date": "10/25/96",
      "Open": "929.42",
      "High": "939.73",
      "Low": "929.42",
      "Close": "936.95"
  },
  {
      "Date": "10/28/96",
      "Open": "936.84",
      "High": "948.37",
      "Low": "934.64",
      "Close": "935.89"
  },
  {
      "Date": "10/29/96",
      "Open": "936.78",
      "High": "936.78",
      "Low": "922.89",
      "Close": "926.39"
  },
  {
      "Date": "10/30/96",
      "Open": "\""
  },
  {
      "Date": "\"",
      "Open": "\""
  },
  {
      "Date": "\"",
      "Open": "\""
  },
  {
      "Date": "\"",
      "Open": "929.03"
  },
  {
      "Date": "10/31/96",
      "Open": "927.31",
      "High": "927.31",
      "Low": "897.23",
      "Close": "909.29"
  },
  {
      "Date": "11/1/96",
      "Open": "910.32",
      "High": "915.39",
      "Low": "904.95",
      "Close": "906.68"
  },
  {
      "Date": "11/4/96",
      "Open": "906.56",
      "High": "906.56",
      "Low": "886.68",
      "Close": "890.13"
  },
  {
      "Date": "11/5/96",
      "Open": "890.19",
      "High": "894.4",
      "Low": "878.29",
      "Close": "881.57"
  },
  {
      "Date": "11/6/96",
      "Open": "879.81",
      "High": "880.15",
      "Low": "851.77",
      "Close": "866.57"
  },
  {
      "Date": "11/7/96",
      "Open": "867.24",
      "High": "876.25",
      "Low": "866.76",
      "Close": "874.89"
  },
  {
      "Date": "11/8/96",
      "Open": "876.11",
      "High": "885.68",
      "Low": "876.11",
      "Close": "884.64"
  },
  {
      "Date": "11/10/96",
      "Open": "885.21",
      "High": "886.84",
      "Low": "878.57",
      "Close": "880.36"
  },
  {
      "Date": "11/11/96",
      "Open": "879.28",
      "High": "895.64",
      "Low": "876.83",
      "Close": "891.27"
  },
  {
      "Date": "11/13/96",
      "Open": "895.98",
      "High": "898.54",
      "Low": "889.47",
      "Close": "896.84"
  },
  {
      "Date": "11/14/96",
      "Open": "895.67",
      "High": "897.92",
      "Low": "885.21",
      "Close": "889.39"
  },
  {
      "Date": "11/15/96",
      "Open": "888.58",
      "High": "894.62",
      "Low": "885.62",
      "Close": "894.31"
  },
  {
      "Date": "11/18/96",
      "Open": "893.95",
      "High": "904.89",
      "Low": "893.95",
      "Close": "904.05"
  },
  {
      "Date": "11/19/96",
      "Open": "904.5",
      "High": "905.61",
      "Low": "895.86",
      "Close": "897.58"
  },
  {
      "Date": "11/20/96",
      "Open": "897.99",
      "High": "897.99",
      "Low": "875.36",
      "Close": "876.61"
  },
  {
      "Date": "11/21/96",
      "Open": "876.36",
      "High": "878.26",
      "Low": "866.97",
      "Close": "869.82"
  },
  {
      "Date": "11/22/96",
      "Open": "867.82",
      "High": "868.18",
      "Low": "848.13",
      "Close": "850.33"
  },
  {
      "Date": "11/25/96",
      "Open": "850.24",
      "High": "859.24",
      "Low": "826.99",
      "Close": "852.26"
  },
  {
      "Date": "11/26/96",
      "Open": "856.31",
      "High": "870.84",
      "Low": "847.6",
      "Close": "855.48"
  },
  {
      "Date": "11/27/96",
      "Open": "848.17",
      "High": "848.21",
      "Low": "822.17",
      "Close": "829.08"
  },
  {
      "Date": "11/28/96",
      "Open": "829.28",
      "High": "835.15",
      "Low": "823.26",
      "Close": "825.47"
  },
  {
      "Date": "11/29/96",
      "Open": "825.59",
      "High": "833.81",
      "Low": "825.59",
      "Close": "830.32"
  },
  {
      "Date": "12/2/96",
      "Open": "830.48",
      "High": "834.92",
      "Low": "818.61",
      "Close": "820.25"
  },
  {
      "Date": "12/3/96",
      "Open": "819.77",
      "High": "819.77",
      "Low": "804.46",
      "Close": "809.43"
  },
  {
      "Date": "12/4/96",
      "Open": "805.4",
      "High": "805.82",
      "Low": "775.43",
      "Close": "788.15"
  },
  {
      "Date": "12/5/96",
      "Open": "786.37",
      "High": "808.21",
      "Low": "781.54",
      "Close": "802.23"
  },
  {
      "Date": "12/6/96",
      "Open": "805.51",
      "High": "815.03",
      "Low": "805.47",
      "Close": "808.19"
  },
  {
      "Date": "12/9/96",
      "Open": "809.03",
      "High": "816.2",
      "Low": "804.97",
      "Close": "808.69"
  },
  {
      "Date": "12/10/96",
      "Open": "807.73",
      "High": "808.53",
      "Low": "797.66",
      "Close": "803.2"
  },
  {
      "Date": "12/11/96",
      "Open": "806.46",
      "High": "839.13",
      "Low": "803.68",
      "Close": "837.07"
  },
  {
      "Date": "12/12/96",
      "Open": "838.86",
      "High": "847.36",
      "Low": "825.52",
      "Close": "827.25"
  },
  {
      "Date": "12/13/96",
      "Open": "826.54",
      "High": "826.54",
      "Low": "814.89",
      "Close": "824.62"
  },
  {
      "Date": "12/16/96",
      "Open": "823.71",
      "High": "848.88",
      "Low": "823.71",
      "Close": "847.27"
  },
  {
      "Date": "12/17/96",
      "Open": "846.06",
      "High": "853.36",
      "Low": "841",
      "Close": "850.74"
  },
  {
      "Date": "12/18/96",
      "Open": "850.69",
      "High": "871.84",
      "Low": "850.69",
      "Close": "860.4"
  },
  {
      "Date": "12/19/96",
      "Open": "858.03",
      "High": "864.54",
      "Low": "853.47",
      "Close": "860.58"
  },
  {
      "Date": "12/20/96",
      "Open": "864.25",
      "High": "887.92",
      "Low": "864.25",
      "Close": "884.38"
  },
  {
      "Date": "12/23/96",
      "Open": "884.4",
      "High": "890.65",
      "Low": "878.1",
      "Close": "882"
  },
  {
      "Date": "12/24/96",
      "Open": "881.85",
      "High": "887.5",
      "Low": "874.05",
      "Close": "884.05"
  },
  {
      "Date": "12/26/96",
      "Open": "887.95",
      "High": "913.35",
      "Low": "887.95",
      "Close": "911.8"
  },
  {
      "Date": "12/31/96",
      "Open": "918.15",
      "High": "922.1",
      "Low": "895.6",
      "Close": "899.1"
  },
  {
      "Date": "1/1/97",
      "Open": "905.2",
      "High": "941.4",
      "Low": "905.2",
      "Close": "939.55"
  },
  {
      "Date": "1/2/97",
      "Open": "941.95",
      "High": "944",
      "Low": "925.05",
      "Close": "927.05"
  },
  {
      "Date": "1/3/97",
      "Open": "924.3",
      "High": "932.6",
      "Low": "919.55",
      "Close": "931.65"
  },
  {
      "Date": "1/6/97",
      "Open": "932.45",
      "High": "937.95",
      "Low": "925.65",
      "Close": "927.8"
  },
  {
      "Date": "1/7/97",
      "Open": "926.35",
      "High": "935.15",
      "Low": "922.25",
      "Close": "928.8"
  },
  {
      "Date": "1/8/97",
      "Open": "937.35",
      "High": "953.1",
      "Low": "937.35",
      "Close": "946.7"
  },
  {
      "Date": "1/9/97",
      "Open": "945.15",
      "High": "959.75",
      "Low": "943.6",
      "Close": "956.6"
  },
  {
      "Date": "1/10/97",
      "Open": "962.7",
      "High": "984.1",
      "Low": "962.7",
      "Close": "980.35"
  },
  {
      "Date": "1/13/97",
      "Open": "978.55",
      "High": "982.25",
      "Low": "965.5",
      "Close": "980.7"
  },
  {
      "Date": "1/14/97",
      "Open": "984",
      "High": "994.4",
      "Low": "977.7",
      "Close": "989.3"
  },
  {
      "Date": "1/15/97",
      "Open": "997.5",
      "High": "1042.75",
      "Low": "997.05",
      "Close": "1041.8"
  },
  {
      "Date": "1/16/97",
      "Open": "1064.05",
      "High": "1085.75",
      "Low": "995.35",
      "Close": "1009.4"
  },
  {
      "Date": "1/17/97",
      "Open": "994.1",
      "High": "1009.7",
      "Low": "976.35",
      "Close": "1001.7"
  },
  {
      "Date": "1/20/97",
      "Open": "1004.1",
      "High": "1005.55",
      "Low": "973.95",
      "Close": "977"
  },
  {
      "Date": "1/21/97",
      "Open": "972.1",
      "High": "980.2",
      "Low": "956.45",
      "Close": "960.5"
  },
  {
      "Date": "1/22/97",
      "Open": "971.4",
      "High": "992.9",
      "Low": "971.4",
      "Close": "987.1"
  },
  {
      "Date": "1/24/97",
      "Open": "991.05",
      "High": "991.4",
      "Low": "979.55",
      "Close": "985.9"
  },
  {
      "Date": "1/27/97",
      "Open": "993.15",
      "High": "1017.3",
      "Low": "990.3",
      "Close": "1009.1"
  },
  {
      "Date": "1/28/97",
      "Open": "1016",
      "High": "1025.1",
      "Low": "1007.95",
      "Close": "1021.8"
  },
  {
      "Date": "1/29/97",
      "Open": "1038.1",
      "High": "1043.9",
      "Low": "1007.1",
      "Close": "1017.2"
  },
  {
      "Date": "1/30/97",
      "Open": "1010.6",
      "High": "1012.85",
      "Low": "993.5",
      "Close": "1000"
  },
  {
      "Date": "1/31/97",
      "Open": "988.7",
      "High": "988.7",
      "Low": "965.85",
      "Close": "972.65"
  },
  {
      "Date": "2/3/97",
      "Open": "967.9",
      "High": "967.9",
      "Low": "944.2",
      "Close": "948.2"
  },
  {
      "Date": "2/4/97",
      "Open": "947",
      "High": "963.2",
      "Low": "944.45",
      "Close": "958.3"
  },
  {
      "Date": "2/5/97",
      "Open": "970.75",
      "High": "984.8",
      "Low": "959.5",
      "Close": "962.5"
  },
  {
      "Date": "2/6/97",
      "Open": "960.75",
      "High": "972.4",
      "Low": "953.95",
      "Close": "967.75"
  },
  {
      "Date": "2/7/97",
      "Open": "969.5",
      "High": "980.95",
      "Low": "966.5",
      "Close": "978.8"
  },
  {
      "Date": "2/10/97",
      "Open": "981",
      "High": "986.75",
      "Low": "975.15",
      "Close": "981.45"
  },
  {
      "Date": "2/11/97",
      "Open": "980.95",
      "High": "996.05",
      "Low": "978.85",
      "Close": "992.75"
  },
  {
      "Date": "2/12/97",
      "Open": "991.65",
      "High": "1008.8",
      "Low": "990.9",
      "Close": "1001"
  },
  {
      "Date": "2/13/97",
      "Open": "998.45",
      "High": "1005.1",
      "Low": "995.35",
      "Close": "1002.1"
  },
  {
      "Date": "2/14/97",
      "Open": "1001.3",
      "High": "1011.85",
      "Low": "1001.1",
      "Close": "1009.4"
  },
  {
      "Date": "2/17/97",
      "Open": "1017.75",
      "High": "1024.3",
      "Low": "1015.1",
      "Close": "1020.1"
  },
  {
      "Date": "2/18/97",
      "Open": "1020.75",
      "High": "1020.85",
      "Low": "998.9",
      "Close": "1000.7"
  },
  {
      "Date": "2/19/97",
      "Open": "1004.05",
      "High": "1013.05",
      "Low": "993.85",
      "Close": "1011.8"
  },
  {
      "Date": "2/20/97",
      "Open": "1009.55",
      "High": "1009.6",
      "Low": "1000.55",
      "Close": "1003.1"
  },
  {
      "Date": "2/21/97",
      "Open": "1000.75",
      "High": "1002",
      "Low": "989.5",
      "Close": "991.55"
  },
  {
      "Date": "2/24/97",
      "Open": "988.7",
      "High": "988.9",
      "Low": "961.95",
      "Close": "964.75"
  },
  {
      "Date": "2/25/97",
      "Open": "962.6",
      "High": "975.25",
      "Low": "954.5",
      "Close": "966.1"
  },
  {
      "Date": "2/26/97",
      "Open": "963.95",
      "High": "970.5",
      "Low": "956.75",
      "Close": "968.85"
  },
  {
      "Date": "2/27/97",
      "Open": "972.35",
      "High": "994.5",
      "Low": "972.05",
      "Close": "992.75"
  },
  {
      "Date": "2/28/97",
      "Open": "998.25",
      "High": "1006.6",
      "Low": "996",
      "Close": "998.65"
  },
  {
      "Date": "3/1/97",
      "Open": "1070.8",
      "High": "1106.6",
      "Low": "1070.8",
      "Close": "1102.9"
  },
  {
      "Date": "3/3/97",
      "Open": "1102.45",
      "High": "1140.65",
      "Low": "1095.9",
      "Close": "1107.7"
  },
  {
      "Date": "3/4/97",
      "Open": "1111.75",
      "High": "1121.4",
      "Low": "1098.2",
      "Close": "1117.3"
  },
  {
      "Date": "3/5/97",
      "Open": "1144.25",
      "High": "1167.95",
      "Low": "1122.25",
      "Close": "1132.9"
  },
  {
      "Date": "3/6/97",
      "Open": "1127",
      "High": "1135.75",
      "Low": "1119.3",
      "Close": "1127.2"
  },
  {
      "Date": "3/10/97",
      "Open": "1118.8",
      "High": "1118.8",
      "Low": "1075.05",
      "Close": "1078.7"
  },
  {
      "Date": "3/11/97",
      "Open": "1073.8",
      "High": "1083.35",
      "Low": "1046.3",
      "Close": "1052"
  },
  {
      "Date": "3/12/97",
      "Open": "1066.55",
      "High": "1088.65",
      "Low": "1065.25",
      "Close": "1074.2"
  },
  {
      "Date": "3/13/97",
      "Open": "1076.85",
      "High": "1078.6",
      "Low": "1062.7",
      "Close": "1066.5"
  },
  {
      "Date": "3/14/97",
      "Open": "1062.2",
      "High": "1076.65",
      "Low": "1057.55",
      "Close": "1070.1"
  },
  {
      "Date": "3/17/97",
      "Open": "1071.95",
      "High": "1076.25",
      "Low": "1065.45",
      "Close": "1072.6"
  },
  {
      "Date": "3/18/97",
      "Open": "1072.3",
      "High": "1074.5",
      "Low": "1061.05",
      "Close": "1064.3"
  },
  {
      "Date": "3/19/97",
      "Open": "1074.25",
      "High": "1079.6",
      "Low": "1050",
      "Close": "1052"
  },
  {
      "Date": "3/20/97",
      "Open": "1049",
      "High": "1059.5",
      "Low": "1040.95",
      "Close": "1054.8"
  },
  {
      "Date": "3/21/97",
      "Open": "1058.7",
      "High": "1069.5",
      "Low": "1058.55",
      "Close": "1061.4"
  },
  {
      "Date": "3/25/97",
      "Open": "1064.5",
      "High": "1067.25",
      "Low": "1054.5",
      "Close": "1058.3"
  },
  {
      "Date": "3/26/97",
      "Open": "1062.5",
      "High": "1067.2",
      "Low": "1057.5",
      "Close": "1059.2"
  },
  {
      "Date": "3/27/97",
      "Open": "1057.45",
      "High": "1059",
      "Low": "1047.7",
      "Close": "1057.8"
  },
  {
      "Date": "3/31/97",
      "Open": "1012.15",
      "High": "1012.15",
      "Low": "966.8",
      "Close": "968.3"
  },
  {
      "Date": "4/1/97",
      "Open": "931.95",
      "High": "973.85",
      "Low": "931.5",
      "Close": "970.15"
  },
  {
      "Date": "4/2/97",
      "Open": "982.1",
      "High": "1004.65",
      "Low": "982.1",
      "Close": "1002.2"
  },
  {
      "Date": "4/3/97",
      "Open": "1004.3",
      "High": "1012.75",
      "Low": "1000.55",
      "Close": "1010.6"
  },
  {
      "Date": "4/4/97",
      "Open": "1001.9",
      "High": "1002",
      "Low": "996.6",
      "Close": "1001"
  },
  {
      "Date": "4/7/97",
      "Open": "997.5",
      "High": "998",
      "Low": "988",
      "Close": "990.9"
  },
  {
      "Date": "4/9/97",
      "Open": "1009.95",
      "High": "1031.15",
      "Low": "1009.95",
      "Close": "1030.3"
  },
  {
      "Date": "4/10/97",
      "Open": "1024.45",
      "High": "1024.7",
      "Low": "1000.3",
      "Close": "1008.3"
  },
  {
      "Date": "4/11/97",
      "Open": "993.3",
      "High": "1017.2",
      "Low": "985.65",
      "Close": "1013.4"
  },
  {
      "Date": "4/12/97",
      "Open": "1004.9",
      "High": "1009.9",
      "Low": "999.3",
      "Close": "1003.2"
  },
  {
      "Date": "4/15/97",
      "Open": "1010.65",
      "High": "1018.6",
      "Low": "1010.65",
      "Close": "1014.9"
  },
  {
      "Date": "4/17/97",
      "Open": "1020.4",
      "High": "1036.15",
      "Low": "1018.25",
      "Close": "1035.2"
  },
  {
      "Date": "4/21/97",
      "Open": "1041.45",
      "High": "1061.8",
      "Low": "1041.25",
      "Close": "1060.6"
  },
  {
      "Date": "4/22/97",
      "Open": "1066.7",
      "High": "1073.9",
      "Low": "1056.4",
      "Close": "1059.8"
  },
  {
      "Date": "4/23/97",
      "Open": "1065.95",
      "High": "1078.05",
      "Low": "1063.85",
      "Close": "1071"
  },
  {
      "Date": "4/24/97",
      "Open": "1071.4",
      "High": "1075.9",
      "Low": "1063.3",
      "Close": "1067.2"
  },
  {
      "Date": "4/25/97",
      "Open": "1065.4",
      "High": "1071.05",
      "Low": "1062.95",
      "Close": "1069.4"
  },
  {
      "Date": "4/28/97",
      "Open": "1068.6",
      "High": "1070.7",
      "Low": "1061.2",
      "Close": "1065.4"
  },
  {
      "Date": "4/29/97",
      "Open": "1063.6",
      "High": "1065.4",
      "Low": "1052.8",
      "Close": "1057.4"
  },
  {
      "Date": "4/30/97",
      "Open": "1081.1",
      "High": "1088.4",
      "Low": "1075.95",
      "Close": "1079.8"
  },
  {
      "Date": "5/2/97",
      "Open": "1074.7",
      "High": "1074.7",
      "Low": "1063.75",
      "Close": "1069"
  },
  {
      "Date": "5/5/97",
      "Open": "1061.8",
      "High": "1061.8",
      "Low": "1043.55",
      "Close": "1045.5"
  },
  {
      "Date": "5/6/97",
      "Open": "1045.8",
      "High": "1050.55",
      "Low": "1038.75",
      "Close": "1048.4"
  },
  {
      "Date": "5/7/97",
      "Open": "1053.1",
      "High": "1055.05",
      "Low": "1042.55",
      "Close": "1044.4"
  },
  {
      "Date": "5/8/97",
      "Open": "1043.7",
      "High": "1055.4",
      "Low": "1039.8",
      "Close": "1054"
  },
  {
      "Date": "5/9/97",
      "Open": "1054.6",
      "High": "1063",
      "Low": "1048.05",
      "Close": "1053.3"
  },
  {
      "Date": "5/12/97",
      "Open": "1052.8",
      "High": "1056.5",
      "Low": "1039.05",
      "Close": "1040.5"
  },
  {
      "Date": "5/13/97",
      "Open": "1041.05",
      "High": "1042.25",
      "Low": "1027.5",
      "Close": "1031"
  },
  {
      "Date": "5/14/97",
      "Open": "1030.35",
      "High": "1044.15",
      "Low": "1018.15",
      "Close": "1041.6"
  },
  {
      "Date": "5/15/97",
      "Open": "1041.65",
      "High": "1049",
      "Low": "1036.25",
      "Close": "1045"
  },
  {
      "Date": "5/16/97",
      "Open": "1043.4",
      "High": "1049.75",
      "Low": "1041.1",
      "Close": "1048.4"
  },
  {
      "Date": "5/19/97",
      "Open": "1052.2",
      "High": "1059.25",
      "Low": "1048.05",
      "Close": "1050.8"
  },
  {
      "Date": "5/20/97",
      "Open": "1051.55",
      "High": "1053.25",
      "Low": "1045.3",
      "Close": "1050.8"
  },
  {
      "Date": "5/21/97",
      "Open": "1047.5",
      "High": "1049.7",
      "Low": "1040.55",
      "Close": "1042"
  },
  {
      "Date": "5/22/97",
      "Open": "1042.45",
      "High": "1043.8",
      "Low": "1034.35",
      "Close": "1037.2"
  },
  {
      "Date": "5/23/97",
      "Open": "1037",
      "High": "1039",
      "Low": "1028.75",
      "Close": "1033.7"
  },
  {
      "Date": "5/26/97",
      "Open": "1032.15",
      "High": "1035.8",
      "Low": "1025.5",
      "Close": "1026.9"
  },
  {
      "Date": "5/27/97",
      "Open": "1027.5",
      "High": "1031.5",
      "Low": "1023.45",
      "Close": "1025.7"
  },
  {
      "Date": "5/28/97",
      "Open": "1032.5",
      "High": "1035.35",
      "Low": "1024.55",
      "Close": "1027"
  },
  {
      "Date": "5/29/97",
      "Open": "1024.75",
      "High": "1040.05",
      "Low": "1023.8",
      "Close": "1038.2"
  },
  {
      "Date": "5/30/97",
      "Open": "1039.85",
      "High": "1051.2",
      "Low": "1038.3",
      "Close": "1050.9"
  },
  {
      "Date": "6/2/97",
      "Open": "1053.05",
      "High": "1066.15",
      "Low": "1051.05",
      "Close": "1064.9"
  },
  {
      "Date": "6/3/97",
      "Open": "1067.65",
      "High": "1081.75",
      "Low": "1067.55",
      "Close": "1073.7"
  },
  {
      "Date": "6/4/97",
      "Open": "1081.55",
      "High": "1090.95",
      "Low": "1078.8",
      "Close": "1085.7"
  },
  {
      "Date": "6/5/97",
      "Open": "1089.85",
      "High": "1095.5",
      "Low": "1082.85",
      "Close": "1086.6"
  },
  {
      "Date": "6/6/97",
      "Open": "1087.5",
      "High": "1091.05",
      "Low": "1081.3",
      "Close": "1082.7"
  },
  {
      "Date": "6/9/97",
      "Open": "1082.95",
      "High": "1082.95",
      "Low": "1071.55",
      "Close": "1074.6"
  },
  {
      "Date": "6/10/97",
      "Open": "1075.3",
      "High": "1084.15",
      "Low": "1075.2",
      "Close": "1081.1"
  },
  {
      "Date": "6/11/97",
      "Open": "1090.45",
      "High": "1108.95",
      "Low": "1090.45",
      "Close": "1104.6"
  },
  {
      "Date": "6/12/97",
      "Open": "1106.1",
      "High": "1122.2",
      "Low": "1105.75",
      "Close": "1120.7"
  },
  {
      "Date": "6/13/97",
      "Open": "1124.95",
      "High": "1147.5",
      "Low": "1122.75",
      "Close": "1136.3"
  },
  {
      "Date": "6/16/97",
      "Open": "1135.3",
      "High": "1141.05",
      "Low": "1121.85",
      "Close": "1129.7"
  },
  {
      "Date": "6/17/97",
      "Open": "1134.9",
      "High": "1145.8",
      "Low": "1127.15",
      "Close": "1138.8"
  },
  {
      "Date": "6/18/97",
      "Open": "1162.2",
      "High": "1171",
      "Low": "1156.05",
      "Close": "1159.8"
  },
  {
      "Date": "6/19/97",
      "Open": "1151.95",
      "High": "1161.7",
      "Low": "1145.35",
      "Close": "1159.8"
  },
  {
      "Date": "6/20/97",
      "Open": "1162.7",
      "High": "1174.15",
      "Low": "1154.3",
      "Close": "1158.3"
  },
  {
      "Date": "6/23/97",
      "Open": "1159.6",
      "High": "1163",
      "Low": "1149.3",
      "Close": "1153.7"
  },
  {
      "Date": "6/24/97",
      "Open": "1154.95",
      "High": "1161.95",
      "Low": "1146.85",
      "Close": "1155.3"
  },
  {
      "Date": "6/25/97",
      "Open": "1166.1",
      "High": "1175",
      "Low": "1158.9",
      "Close": "1161.9"
  },
  {
      "Date": "6/26/97",
      "Open": "1164",
      "High": "1177.6",
      "Low": "1160.25",
      "Close": "1168.2"
  },
  {
      "Date": "6/27/97",
      "Open": "1168.3",
      "High": "1174.75",
      "Low": "1165.95",
      "Close": "1169.6"
  },
  {
      "Date": "6/30/97",
      "Open": "1167.45",
      "High": "1194.75",
      "Low": "1164.5",
      "Close": "1192.4"
  },
  {
      "Date": "7/1/97",
      "Open": "1201.3",
      "High": "1206.1",
      "Low": "1193.55",
      "Close": "1200.4"
  },
  {
      "Date": "7/2/97",
      "Open": "1214.7",
      "High": "1237.05",
      "Low": "1212.6",
      "Close": "1222.9"
  },
  {
      "Date": "7/3/97",
      "Open": "1222.45",
      "High": "1235.45",
      "Low": "1209.45",
      "Close": "1214.9"
  },
  {
      "Date": "7/4/97",
      "Open": "1218.95",
      "High": "1222.6",
      "Low": "1209.45",
      "Close": "1216.1"
  },
  {
      "Date": "7/7/97",
      "Open": "1217.4",
      "High": "1226.6",
      "Low": "1208.25",
      "Close": "1209.7"
  },
  {
      "Date": "7/8/97",
      "Open": "1205.1",
      "High": "1205.8",
      "Low": "1193.35",
      "Close": "1198.2"
  },
  {
      "Date": "7/9/97",
      "Open": "1211.6",
      "High": "1239.55",
      "Low": "1211.6",
      "Close": "1236.5"
  },
  {
      "Date": "7/10/97",
      "Open": "1248.1",
      "High": "1252.7",
      "Low": "1234.95",
      "Close": "1239.1"
  },
  {
      "Date": "7/11/97",
      "Open": "1233.5",
      "High": "1233.5",
      "Low": "1217",
      "Close": "1221.1"
  },
  {
      "Date": "7/14/97",
      "Open": "1212.4",
      "High": "1215.6",
      "Low": "1176.6",
      "Close": "1187"
  },
  {
      "Date": "7/15/97",
      "Open": "1190.5",
      "High": "1195.4",
      "Low": "1180.75",
      "Close": "1183.3"
  },
  {
      "Date": "7/16/97",
      "Open": "1195.55",
      "High": "1207",
      "Low": "1179.25",
      "Close": "1183"
  },
  {
      "Date": "7/17/97",
      "Open": "1182.85",
      "High": "1185.3",
      "Low": "1173",
      "Close": "1181.4"
  },
  {
      "Date": "7/21/97",
      "Open": "1171.35",
      "High": "1171.35",
      "Low": "1153.75",
      "Close": "1165"
  },
  {
      "Date": "7/22/97",
      "Open": "1169.55",
      "High": "1176.4",
      "Low": "1165.15",
      "Close": "1173.2"
  },
  {
      "Date": "7/23/97",
      "Open": "1184.05",
      "High": "1201.75",
      "Low": "1183.9",
      "Close": "1200.7"
  },
  {
      "Date": "7/24/97",
      "Open": "1204.35",
      "High": "1213.4",
      "Low": "1195.2",
      "Close": "1199.9"
  },
  {
      "Date": "7/25/97",
      "Open": "1194.75",
      "High": "1195.95",
      "Low": "1179",
      "Close": "1185"
  },
  {
      "Date": "7/28/97",
      "Open": "1185.25",
      "High": "1187.85",
      "Low": "1176.95",
      "Close": "1181.6"
  },
  {
      "Date": "7/29/97",
      "Open": "1182.6",
      "High": "1190.55",
      "Low": "1180.1",
      "Close": "1190.3"
  },
  {
      "Date": "7/30/97",
      "Open": "1196.65",
      "High": "1205.8",
      "Low": "1195.85",
      "Close": "1197.9"
  },
  {
      "Date": "7/31/97",
      "Open": "1203.95",
      "High": "1224.7",
      "Low": "1201.85",
      "Close": "1221.5"
  },
  {
      "Date": "8/1/97",
      "Open": "1224.4",
      "High": "1233.85",
      "Low": "1219.9",
      "Close": "1232.4"
  },
  {
      "Date": "8/4/97",
      "Open": "1238.75",
      "High": "1254.5",
      "Low": "1237.95",
      "Close": "1250.4"
  },
  {
      "Date": "8/5/97",
      "Open": "1250.5",
      "High": "1275.45",
      "Low": "1249.7",
      "Close": "1273.1"
  },
  {
      "Date": "8/6/97",
      "Open": "1273.65",
      "High": "1297.1",
      "Low": "1273.65",
      "Close": "1292.9"
  },
  {
      "Date": "8/7/97",
      "Open": "1292.8",
      "High": "1292.8",
      "Low": "1259.05",
      "Close": "1269.6"
  },
  {
      "Date": "8/8/97",
      "Open": "1269.55",
      "High": "1269.55",
      "Low": "1253.2",
      "Close": "1256.9"
  },
  {
      "Date": "8/11/97",
      "Open": "1256.85",
      "High": "1260",
      "Low": "1247.65",
      "Close": "1258.3"
  },
  {
      "Date": "8/12/97",
      "Open": "1258.5",
      "High": "1272",
      "Low": "1243.95",
      "Close": "1248.4"
  },
  {
      "Date": "8/13/97",
      "Open": "1248.3",
      "High": "1251.4",
      "Low": "1240.9",
      "Close": "1244.6"
  },
  {
      "Date": "8/14/97",
      "Open": "1244.4",
      "High": "1245.8",
      "Low": "1226.1",
      "Close": "1227.9"
  },
  {
      "Date": "8/18/97",
      "Open": "1228.4",
      "High": "1228.45",
      "Low": "1198.75",
      "Close": "1201.4"
  },
  {
      "Date": "8/19/97",
      "Open": "1201.5",
      "High": "1203.15",
      "Low": "1177.1",
      "Close": "1180.7"
  },
  {
      "Date": "8/20/97",
      "Open": "1179.55",
      "High": "1200.25",
      "Low": "1178.65",
      "Close": "1198.2"
  },
  {
      "Date": "8/21/97",
      "Open": "1198.8",
      "High": "1212.15",
      "Low": "1179.3",
      "Close": "1181.5"
  },
  {
      "Date": "8/22/97",
      "Open": "1181.55",
      "High": "1181.6",
      "Low": "1158.45",
      "Close": "1160.4"
  },
  {
      "Date": "8/25/97",
      "Open": "1160.4",
      "High": "1160.4",
      "Low": "1119.65",
      "Close": "1144.3"
  },
  {
      "Date": "8/26/97",
      "Open": "1144.55",
      "High": "1160.45",
      "Low": "1144.05",
      "Close": "1151.7"
  },
  {
      "Date": "8/27/97",
      "Open": "1152.4",
      "High": "1184.85",
      "Low": "1152.4",
      "Close": "1168.4"
  },
  {
      "Date": "8/28/97",
      "Open": "1168.4",
      "High": "1168.4",
      "Low": "1132.25",
      "Close": "1134.6"
  },
  {
      "Date": "8/29/97",
      "Open": "1134.2",
      "High": "1134.2",
      "Low": "1098.9",
      "Close": "1105"
  },
  {
      "Date": "9/1/97",
      "Open": "1105.45",
      "High": "1115",
      "Low": "1097.95",
      "Close": "1104.7"
  },
  {
      "Date": "9/2/97",
      "Open": "1104.9",
      "High": "1125.85",
      "Low": "1102.45",
      "Close": "1117.1"
  },
  {
      "Date": "9/3/97",
      "Open": "1118.45",
      "High": "1145.4",
      "Low": "1118.45",
      "Close": "1134.7"
  },
  {
      "Date": "9/4/97",
      "Open": "1134.3",
      "High": "1138.8",
      "Low": "1123.7",
      "Close": "1137.1"
  },
  {
      "Date": "9/5/97",
      "Open": "1137.2",
      "High": "1152.3",
      "Low": "1137.2",
      "Close": "1149.2"
  },
  {
      "Date": "9/8/97",
      "Open": "1148.7",
      "High": "1160.85",
      "Low": "1136.85",
      "Close": "1158.2"
  },
  {
      "Date": "9/9/97",
      "Open": "1158.3",
      "High": "1168.35",
      "Low": "1154.95",
      "Close": "1159.5"
  },
  {
      "Date": "9/10/97",
      "Open": "1159",
      "High": "1159",
      "Low": "1143.95",
      "Close": "1154.5"
  },
  {
      "Date": "9/11/97",
      "Open": "1154.6",
      "High": "1163.3",
      "Low": "1152.55",
      "Close": "1154.3"
  },
  {
      "Date": "9/12/97",
      "Open": "1154.2",
      "High": "1154.2",
      "Low": "1138.25",
      "Close": "1142.8"
  },
  {
      "Date": "9/15/97",
      "Open": "1142.75",
      "High": "1143.35",
      "Low": "1136.3",
      "Close": "1141"
  },
  {
      "Date": "9/16/97",
      "Open": "1141.1",
      "High": "1149.25",
      "Low": "1137.8",
      "Close": "1140.1"
  },
  {
      "Date": "9/17/97",
      "Open": "1140.3",
      "High": "1141.75",
      "Low": "1132.75",
      "Close": "1134.2"
  },
  {
      "Date": "9/18/97",
      "Open": "1133.6",
      "High": "1133.6",
      "Low": "1101.65",
      "Close": "1109.3"
  },
  {
      "Date": "9/19/97",
      "Open": "1109.6",
      "High": "1115.15",
      "Low": "1099.6",
      "Close": "1104.3"
  },
  {
      "Date": "9/22/97",
      "Open": "1104.25",
      "High": "1104.25",
      "Low": "1082.5",
      "Close": "1085"
  },
  {
      "Date": "9/23/97",
      "Open": "1084.4",
      "High": "1090.95",
      "Low": "1079.6",
      "Close": "1088.6"
  },
  {
      "Date": "9/24/97",
      "Open": "1089.15",
      "High": "1108.15",
      "Low": "1089.15",
      "Close": "1100.8"
  },
  {
      "Date": "9/25/97",
      "Open": "1100.85",
      "High": "1112.4",
      "Low": "1098.35",
      "Close": "1111.3"
  },
  {
      "Date": "9/26/97",
      "Open": "1111.65",
      "High": "1122.25",
      "Low": "1111.65",
      "Close": "1116.1"
  },
  {
      "Date": "9/29/97",
      "Open": "1124.9",
      "High": "1133.4",
      "Low": "1122.6",
      "Close": "1125.7"
  },
  {
      "Date": "9/30/97",
      "Open": "1125.65",
      "High": "1125.65",
      "Low": "1112.5",
      "Close": "1123.8"
  },
  {
      "Date": "10/1/97",
      "Open": "1123.45",
      "High": "1123.45",
      "Low": "1102.45",
      "Close": "1114.7"
  },
  {
      "Date": "10/9/97",
      "Open": "1119.1",
      "High": "1135.95",
      "Low": "1118.65",
      "Close": "1134.5"
  },
  {
      "Date": "10/10/97",
      "Open": "1135.3",
      "High": "1166.05",
      "Low": "1133.6",
      "Close": "1163.5"
  },
  {
      "Date": "10/13/97",
      "Open": "1165.5",
      "High": "1177.5",
      "Low": "1153.85",
      "Close": "1156.9"
  },
  {
      "Date": "10/14/97",
      "Open": "1156.45",
      "High": "1158.8",
      "Low": "1131",
      "Close": "1137.9"
  },
  {
      "Date": "10/15/97",
      "Open": "1138.45",
      "High": "1152.65",
      "Low": "1138.45",
      "Close": "1150.4"
  },
  {
      "Date": "10/16/97",
      "Open": "1150.45",
      "High": "1171.6",
      "Low": "1150.45",
      "Close": "1168.3"
  },
  {
      "Date": "10/17/97",
      "Open": "1168.75",
      "High": "1181.15",
      "Low": "1168.7",
      "Close": "1177.8"
  },
  {
      "Date": "10/20/97",
      "Open": "1177.5",
      "High": "1189.4",
      "Low": "1172.05",
      "Close": "1188.2"
  },
  {
      "Date": "10/21/97",
      "Open": "1188.2",
      "High": "1192",
      "Low": "1171.2",
      "Close": "1174.9"
  },
  {
      "Date": "10/22/97",
      "Open": "1175.55",
      "High": "1184.9",
      "Low": "1168.3",
      "Close": "1169.8"
  },
  {
      "Date": "10/23/97",
      "Open": "1169.4",
      "High": "1171.05",
      "Low": "1140.8",
      "Close": "1151.6"
  },
  {
      "Date": "10/24/97",
      "Open": "1151.45",
      "High": "1151.45",
      "Low": "1122.55",
      "Close": "1136.6"
  },
  {
      "Date": "10/27/97",
      "Open": "1136",
      "High": "1136",
      "Low": "1116",
      "Close": "1120.8"
  },
  {
      "Date": "10/28/97",
      "Open": "1120.6",
      "High": "1120.6",
      "Low": "1028.2",
      "Close": "1032.6"
  },
  {
      "Date": "10/29/97",
      "Open": "1033",
      "High": "1108.5",
      "Low": "1033",
      "Close": "1107"
  },
  {
      "Date": "10/30/97",
      "Open": "1106.9",
      "High": "1106.9",
      "Low": "1080.15",
      "Close": "1085.2"
  },
  {
      "Date": "11/3/97",
      "Open": "1085.75",
      "High": "1096.05",
      "Low": "1071.2",
      "Close": "1084"
  },
  {
      "Date": "11/4/97",
      "Open": "1083.8",
      "High": "1097.05",
      "Low": "1079.55",
      "Close": "1090.2"
  },
  {
      "Date": "11/5/97",
      "Open": "1091.2",
      "High": "1096.05",
      "Low": "1081.9",
      "Close": "1085"
  },
  {
      "Date": "11/6/97",
      "Open": "1084.8",
      "High": "1085.2",
      "Low": "1068.4",
      "Close": "1080.9"
  },
  {
      "Date": "11/7/97",
      "Open": "1081.15",
      "High": "1081.45",
      "Low": "1072.15",
      "Close": "1074.8"
  },
  {
      "Date": "11/10/97",
      "Open": "1074.5",
      "High": "1074.5",
      "Low": "1064.4",
      "Close": "1065.9"
  },
  {
      "Date": "11/11/97",
      "Open": "1065.9",
      "High": "1076.5",
      "Low": "1064.3",
      "Close": "1073.3"
  },
  {
      "Date": "11/12/97",
      "Open": "1073.3",
      "High": "1073.3",
      "Low": "1042.35",
      "Close": "1045"
  },
  {
      "Date": "11/13/97",
      "Open": "1044.55",
      "High": "1044.55",
      "Low": "1013.1",
      "Close": "1022.4"
  },
  {
      "Date": "11/17/97",
      "Open": "1022.3",
      "High": "1028.5",
      "Low": "1014.55",
      "Close": "1024.9"
  },
  {
      "Date": "11/18/97",
      "Open": "1025.3",
      "High": "1025.4",
      "Low": "1004.2",
      "Close": "1009.8"
  },
  {
      "Date": "11/19/97",
      "Open": "1009.55",
      "High": "1009.55",
      "Low": "971.2",
      "Close": "987.85"
  },
  {
      "Date": "11/20/97",
      "Open": "987.7",
      "High": "1004.8",
      "Low": "974.2",
      "Close": "995.15"
  },
  {
      "Date": "11/21/97",
      "Open": "994.85",
      "High": "1014.5",
      "Low": "990.4",
      "Close": "1005.7"
  },
  {
      "Date": "11/24/97",
      "Open": "1005.7",
      "High": "1010.65",
      "Low": "979.75",
      "Close": "981.25"
  },
  {
      "Date": "11/25/97",
      "Open": "981.1",
      "High": "1015.2",
      "Low": "974.7",
      "Close": "1010.7"
  },
  {
      "Date": "11/26/97",
      "Open": "1011.4",
      "High": "1028.9",
      "Low": "1011.05",
      "Close": "1016.8"
  },
  {
      "Date": "11/27/97",
      "Open": "1016.95",
      "High": "1048.95",
      "Low": "1011.9",
      "Close": "1046.7"
  },
  {
      "Date": "11/28/97",
      "Open": "1046.35",
      "High": "1046.35",
      "Low": "1014.85",
      "Close": "1023.9"
  },
  {
      "Date": "12/1/97",
      "Open": "1023.85",
      "High": "1033.9",
      "Low": "1008.45",
      "Close": "1032.6"
  },
  {
      "Date": "12/2/97",
      "Open": "1033.15",
      "High": "1041.25",
      "Low": "1024.85",
      "Close": "1029.8"
  },
  {
      "Date": "12/3/97",
      "Open": "1030.2",
      "High": "1037.4",
      "Low": "1022.05",
      "Close": "1030.7"
  },
  {
      "Date": "12/4/97",
      "Open": "1030.2",
      "High": "1036.6",
      "Low": "1007.8",
      "Close": "1011.7"
  },
  {
      "Date": "12/5/97",
      "Open": "1011.4",
      "High": "1024.35",
      "Low": "1002.45",
      "Close": "1006.3"
  },
  {
      "Date": "12/8/97",
      "Open": "1006.65",
      "High": "1014.15",
      "Low": "1003.2",
      "Close": "1008.8"
  },
  {
      "Date": "12/9/97",
      "Open": "1008.5",
      "High": "1011.5",
      "Low": "979.7",
      "Close": "984.05"
  },
  {
      "Date": "12/10/97",
      "Open": "983.2",
      "High": "983.2",
      "Low": "965.85",
      "Close": "971.9"
  },
  {
      "Date": "12/11/97",
      "Open": "971.7",
      "High": "978.8",
      "Low": "943",
      "Close": "973.65"
  },
  {
      "Date": "12/12/97",
      "Open": "973.7",
      "High": "979.1",
      "Low": "963.85",
      "Close": "965.15"
  },
  {
      "Date": "12/15/97",
      "Open": "965",
      "High": "980.65",
      "Low": "957.2",
      "Close": "979.85"
  },
  {
      "Date": "12/16/97",
      "Open": "980",
      "High": "1007.75",
      "Low": "979.8",
      "Close": "1002.3"
  },
  {
      "Date": "12/17/97",
      "Open": "1002.25",
      "High": "1017.6",
      "Low": "1002.15",
      "Close": "1013.1"
  },
  {
      "Date": "12/18/97",
      "Open": "1013.5",
      "High": "1023.85",
      "Low": "1008.15",
      "Close": "1009.7"
  },
  {
      "Date": "12/19/97",
      "Open": "1009.3",
      "High": "1029.75",
      "Low": "1006.8",
      "Close": "1028.4"
  },
  {
      "Date": "12/22/97",
      "Open": "1028.45",
      "High": "1053.4",
      "Low": "1028.45",
      "Close": "1051.4"
  },
  {
      "Date": "12/23/97",
      "Open": "1051.45",
      "High": "1066.25",
      "Low": "1049.55",
      "Close": "1054.8"
  },
  {
      "Date": "12/24/97",
      "Open": "1054.8",
      "High": "1055.5",
      "Low": "1032.95",
      "Close": "1054.1"
  },
  {
      "Date": "12/26/97",
      "Open": "1054.15",
      "High": "1066",
      "Low": "1054.15",
      "Close": "1061.4"
  },
  {
      "Date": "12/29/97",
      "Open": "1061.65",
      "High": "1081.5",
      "Low": "1060.2",
      "Close": "1079"
  },
  {
      "Date": "12/30/97",
      "Open": "1078.75",
      "High": "1080.2",
      "Low": "1054.55",
      "Close": "1058.5"
  },
  {
      "Date": "12/31/97",
      "Open": "1058.45",
      "High": "1083.35",
      "Low": "1057.75",
      "Close": "1079.4"
  },
  {
      "Date": "1/1/98",
      "Open": "1078.95",
      "High": "1084.5",
      "Low": "1074.15",
      "Close": "1081.2"
  },
  {
      "Date": "1/2/98",
      "Open": "1081",
      "High": "1091.3",
      "Low": "1081",
      "Close": "1086.2"
  },
  {
      "Date": "1/5/98",
      "Open": "1086.6",
      "High": "1102.15",
      "Low": "1086.6",
      "Close": "1091.6"
  },
  {
      "Date": "1/6/98",
      "Open": "1091.25",
      "High": "1091.25",
      "Low": "1074.1",
      "Close": "1079.1"
  },
  {
      "Date": "1/7/98",
      "Open": "1079.95",
      "High": "1097.3",
      "Low": "1071.75",
      "Close": "1082.4"
  },
  {
      "Date": "1/8/98",
      "Open": "1082.05",
      "High": "1082.05",
      "Low": "1054",
      "Close": "1057.7"
  },
  {
      "Date": "1/9/98",
      "Open": "1057.65",
      "High": "1057.65",
      "Low": "1028.7",
      "Close": "1033.5"
  },
  {
      "Date": "1/12/98",
      "Open": "1033.2",
      "High": "1033.2",
      "Low": "992.5",
      "Close": "995.8"
  },
  {
      "Date": "1/13/98",
      "Open": "995.7",
      "High": "1016.8",
      "Low": "995.7",
      "Close": "999.1"
  },
  {
      "Date": "1/14/98",
      "Open": "999.45",
      "High": "1019.85",
      "Low": "990.75",
      "Close": "997.55"
  },
  {
      "Date": "1/15/98",
      "Open": "997.5",
      "High": "1001.3",
      "Low": "975.65",
      "Close": "989.65"
  },
  {
      "Date": "1/16/98",
      "Open": "990.05",
      "High": "999.8",
      "Low": "987.1",
      "Close": "997.8"
  },
  {
      "Date": "1/19/98",
      "Open": "998.3",
      "High": "1019.75",
      "Low": "992.05",
      "Close": "1017.2"
  },
  {
      "Date": "1/20/98",
      "Open": "1017.25",
      "High": "1029.65",
      "Low": "1008.8",
      "Close": "1011.4"
  },
  {
      "Date": "1/21/98",
      "Open": "1011.6",
      "High": "1017.75",
      "Low": "1003.9",
      "Close": "1012.4"
  },
  {
      "Date": "1/22/98",
      "Open": "1012.2",
      "High": "1012.2",
      "Low": "990.85",
      "Close": "992.35"
  },
  {
      "Date": "1/23/98",
      "Open": "992.2",
      "High": "992.45",
      "Low": "984.55",
      "Close": "986.8"
  },
  {
      "Date": "1/27/98",
      "Open": "986.9",
      "High": "988",
      "Low": "959.4",
      "Close": "963.3"
  },
  {
      "Date": "1/28/98",
      "Open": "962.65",
      "High": "969.7",
      "Low": "940.1",
      "Close": "944.8"
  },
  {
      "Date": "1/29/98",
      "Open": "944.55",
      "High": "944.55",
      "Low": "929.05",
      "Close": "941.35"
  },
  {
      "Date": "1/30/98",
      "Open": "941.4",
      "High": "965.3",
      "Low": "941.4",
      "Close": "963.45"
  },
  {
      "Date": "2/2/98",
      "Open": "963.45",
      "High": "978.05",
      "Low": "962.25",
      "Close": "976.6"
  },
  {
      "Date": "2/3/98",
      "Open": "977.05",
      "High": "979.55",
      "Low": "967.85",
      "Close": "975.25"
  },
  {
      "Date": "2/4/98",
      "Open": "975.9",
      "High": "988.4",
      "Low": "965.45",
      "Close": "967.95"
  },
  {
      "Date": "2/5/98",
      "Open": "968.15",
      "High": "980.45",
      "Low": "968.15",
      "Close": "976.1"
  },
  {
      "Date": "2/6/98",
      "Open": "976.1",
      "High": "981.2",
      "Low": "971.9",
      "Close": "977.65"
  },
  {
      "Date": "2/9/98",
      "Open": "977.55",
      "High": "980.75",
      "Low": "973.1",
      "Close": "976.1"
  },
  {
      "Date": "2/10/98",
      "Open": "976.1",
      "High": "976.1",
      "Low": "962",
      "Close": "963.7"
  },
  {
      "Date": "2/11/98",
      "Open": "964.25",
      "High": "971.75",
      "Low": "956",
      "Close": "958.9"
  },
  {
      "Date": "2/12/98",
      "Open": "959.05",
      "High": "960.25",
      "Low": "949.65",
      "Close": "956.65"
  },
  {
      "Date": "2/13/98",
      "Open": "956.8",
      "High": "978.15",
      "Low": "956.8",
      "Close": "974.55"
  },
  {
      "Date": "2/17/98",
      "Open": "974.65",
      "High": "1002.15",
      "Low": "974.65",
      "Close": "991.7"
  },
  {
      "Date": "2/18/98",
      "Open": "992.15",
      "High": "997.8",
      "Low": "984",
      "Close": "988.85"
  },
  {
      "Date": "2/19/98",
      "Open": "988.95",
      "High": "1001.1",
      "Low": "988.95",
      "Close": "997.55"
  },
  {
      "Date": "2/20/98",
      "Open": "997.1",
      "High": "1006.25",
      "Low": "991.85",
      "Close": "993.65"
  },
  {
      "Date": "2/23/98",
      "Open": "994.1",
      "High": "1011.7",
      "Low": "994.1",
      "Close": "1009.6"
  },
  {
      "Date": "2/24/98",
      "Open": "1009.5",
      "High": "1017.55",
      "Low": "1007.4",
      "Close": "1014.1"
  },
  {
      "Date": "2/25/98",
      "Open": "1014.1",
      "High": "1037.15",
      "Low": "1014.1",
      "Close": "1036"
  },
  {
      "Date": "2/26/98",
      "Open": "1036.3",
      "High": "1048.55",
      "Low": "1032.4",
      "Close": "1045.8"
  },
  {
      "Date": "2/27/98",
      "Open": "1045.85",
      "High": "1064.15",
      "Low": "1045.75",
      "Close": "1060.7"
  },
  {
      "Date": "3/2/98",
      "Open": "1060.9",
      "High": "1100.8",
      "Low": "1060.9",
      "Close": "1098.2"
  },
  {
      "Date": "3/3/98",
      "Open": "1099.25",
      "High": "1101.95",
      "Low": "1046.75",
      "Close": "1063.5"
  },
  {
      "Date": "3/4/98",
      "Open": "1062.7",
      "High": "1094.45",
      "Low": "1062.7",
      "Close": "1092.3"
  },
  {
      "Date": "3/5/98",
      "Open": "1091.95",
      "High": "1098.9",
      "Low": "1079.95",
      "Close": "1083.3"
  },
  {
      "Date": "3/6/98",
      "Open": "1083.3",
      "High": "1086",
      "Low": "1073.25",
      "Close": "1081.4"
  },
  {
      "Date": "3/9/98",
      "Open": "1081.95",
      "High": "1100.4",
      "Low": "1081.95",
      "Close": "1099.5"
  },
  {
      "Date": "3/10/98",
      "Open": "1099.35",
      "High": "1102.85",
      "Low": "1082.7",
      "Close": "1086"
  },
  {
      "Date": "3/11/98",
      "Open": "1086.65",
      "High": "1102.6",
      "Low": "1074.55",
      "Close": "1083"
  },
  {
      "Date": "3/12/98",
      "Open": "1083.35",
      "High": "1083.95",
      "Low": "1072.4",
      "Close": "1077.5"
  },
  {
      "Date": "3/16/98",
      "Open": "1077.75",
      "High": "1102.6",
      "Low": "1077.75",
      "Close": "1090.4"
  },
  {
      "Date": "3/17/98",
      "Open": "1090.25",
      "High": "1090.25",
      "Low": "1072.7",
      "Close": "1083.3"
  },
  {
      "Date": "3/18/98",
      "Open": "1083.85",
      "High": "1108.2",
      "Low": "1083.85",
      "Close": "1103.3"
  },
  {
      "Date": "3/19/98",
      "Open": "1103.8",
      "High": "1113.45",
      "Low": "1099.8",
      "Close": "1105.8"
  },
  {
      "Date": "3/20/98",
      "Open": "1105.5",
      "High": "1112.4",
      "Low": "1097.75",
      "Close": "1109.6"
  },
  {
      "Date": "3/23/98",
      "Open": "1110.1",
      "High": "1124.8",
      "Low": "1110.1",
      "Close": "1124.1"
  },
  {
      "Date": "3/24/98",
      "Open": "1124.25",
      "High": "1137.8",
      "Low": "1117.35",
      "Close": "1120.8"
  },
  {
      "Date": "3/25/98",
      "Open": "1121.45",
      "High": "1149.35",
      "Low": "1121.45",
      "Close": "1146.9"
  },
  {
      "Date": "3/26/98",
      "Open": "1147.65",
      "High": "1156.7",
      "Low": "1127.3",
      "Close": "1129.5"
  },
  {
      "Date": "3/27/98",
      "Open": "1129.5",
      "High": "1142.1",
      "Low": "1121.2",
      "Close": "1140.3"
  },
  {
      "Date": "3/30/98",
      "Open": "1140.6",
      "High": "1146.65",
      "Low": "1122.95",
      "Close": "1124.8"
  },
  {
      "Date": "3/31/98",
      "Open": "1124.75",
      "High": "1127.5",
      "Low": "1111.85",
      "Close": "1116.9"
  },
  {
      "Date": "4/1/98",
      "Open": "1117.15",
      "High": "1151",
      "Low": "1117.15",
      "Close": "1150.1"
  },
  {
      "Date": "4/2/98",
      "Open": "1150.4",
      "High": "1169.55",
      "Low": "1150.4",
      "Close": "1157.4"
  },
  {
      "Date": "4/3/98",
      "Open": "1157.9",
      "High": "1174.55",
      "Low": "1157.3",
      "Close": "1173.4"
  },
  {
      "Date": "4/6/98",
      "Open": "1173.55",
      "High": "1191.55",
      "Low": "1173.55",
      "Close": "1187.1"
  },
  {
      "Date": "4/7/98",
      "Open": "1187.35",
      "High": "1199.55",
      "Low": "1184",
      "Close": "1187.8"
  },
  {
      "Date": "4/9/98",
      "Open": "1189.4",
      "High": "1224.25",
      "Low": "1189.4",
      "Close": "1209.6"
  },
  {
      "Date": "4/10/98",
      "Open": "1209.75",
      "High": "1209.9",
      "Low": "1189.45",
      "Close": "1192.4"
  },
  {
      "Date": "4/13/98",
      "Open": "1191.65",
      "High": "1191.65",
      "Low": "1156.85",
      "Close": "1159.4"
  },
  {
      "Date": "4/15/98",
      "Open": "1159.55",
      "High": "1210.6",
      "Low": "1159.55",
      "Close": "1206.3"
  },
  {
      "Date": "4/16/98",
      "Open": "1206.35",
      "High": "1215.05",
      "Low": "1194.5",
      "Close": "1197"
  },
  {
      "Date": "4/17/98",
      "Open": "1197.05",
      "High": "1211.8",
      "Low": "1189.75",
      "Close": "1210"
  },
  {
      "Date": "4/20/98",
      "Open": "1210.05",
      "High": "1222.55",
      "Low": "1202.2",
      "Close": "1207.1"
  },
  {
      "Date": "4/21/98",
      "Open": "1207.2",
      "High": "1218.95",
      "Low": "1207.2",
      "Close": "1212.7"
  },
  {
      "Date": "4/22/98",
      "Open": "1212.95",
      "High": "1247.15",
      "Low": "1208",
      "Close": "1210.8"
  },
  {
      "Date": "4/23/98",
      "Open": "1210.25",
      "High": "1211.3",
      "Low": "1179.75",
      "Close": "1182.1"
  },
  {
      "Date": "4/24/98",
      "Open": "1182.15",
      "High": "1183.75",
      "Low": "1144.85",
      "Close": "1173.8"
  },
  {
      "Date": "4/27/98",
      "Open": "1173.85",
      "High": "1180.2",
      "Low": "1161.2",
      "Close": "1164.3"
  },
  {
      "Date": "4/28/98",
      "Open": "1164.1",
      "High": "1164.3",
      "Low": "1144.55",
      "Close": "1146.8"
  },
  {
      "Date": "4/29/98",
      "Open": "1151.3",
      "High": "1178.05",
      "Low": "1147",
      "Close": "1151.1"
  },
  {
      "Date": "4/30/98",
      "Open": "1151.4",
      "High": "1161",
      "Low": "1137.8",
      "Close": "1159.3"
  },
  {
      "Date": "5/4/98",
      "Open": "1159.8",
      "High": "1185.75",
      "Low": "1159.8",
      "Close": "1185.1"
  },
  {
      "Date": "5/5/98",
      "Open": "1185.2",
      "High": "1191.25",
      "Low": "1177.3",
      "Close": "1188.1"
  },
  {
      "Date": "5/6/98",
      "Open": "1188",
      "High": "1196.7",
      "Low": "1169.15",
      "Close": "1172.4"
  },
  {
      "Date": "5/8/98",
      "Open": "1172.1",
      "High": "1172.2",
      "Low": "1157.3",
      "Close": "1161.5"
  },
  {
      "Date": "5/11/98",
      "Open": "1162.2",
      "High": "1162.95",
      "Low": "1156.45",
      "Close": "1160.3"
  },
  {
      "Date": "5/12/98",
      "Open": "1160.15",
      "High": "1160.15",
      "Low": "1100.1",
      "Close": "1132.2"
  },
  {
      "Date": "5/13/98",
      "Open": "1132.1",
      "High": "1132.1",
      "Low": "1091.25",
      "Close": "1094.1"
  },
  {
      "Date": "5/14/98",
      "Open": "1094.05",
      "High": "1121.75",
      "Low": "1081.6",
      "Close": "1118.6"
  },
  {
      "Date": "5/15/98",
      "Open": "1119.15",
      "High": "1130.4",
      "Low": "1104.2",
      "Close": "1110.1"
  },
  {
      "Date": "5/18/98",
      "Open": "1110.65",
      "High": "1130",
      "Low": "1110.65",
      "Close": "1119.8"
  },
  {
      "Date": "5/19/98",
      "Open": "1119.8",
      "High": "1125.15",
      "Low": "1116.2",
      "Close": "1121.7"
  },
  {
      "Date": "5/20/98",
      "Open": "1121.7",
      "High": "1142.95",
      "Low": "1121.7",
      "Close": "1141.3"
  },
  {
      "Date": "5/21/98",
      "Open": "1141.65",
      "High": "1146.35",
      "Low": "1120.35",
      "Close": "1126.1"
  },
  {
      "Date": "5/22/98",
      "Open": "1126.1",
      "High": "1131.25",
      "Low": "1118.5",
      "Close": "1126.5"
  },
  {
      "Date": "5/25/98",
      "Open": "1125.9",
      "High": "1127",
      "Low": "1111.2",
      "Close": "1122.9"
  },
  {
      "Date": "5/26/98",
      "Open": "1123.15",
      "High": "1123.15",
      "Low": "1101.55",
      "Close": "1104.4"
  },
  {
      "Date": "5/27/98",
      "Open": "1104.05",
      "High": "1107.2",
      "Low": "1089",
      "Close": "1090.9"
  },
  {
      "Date": "5/28/98",
      "Open": "1090.5",
      "High": "1097",
      "Low": "1073.3",
      "Close": "1075.5"
  },
  {
      "Date": "5/29/98",
      "Open": "1075.35",
      "High": "1077.15",
      "Low": "1037.95",
      "Close": "1063.1"
  },
  {
      "Date": "6/1/98",
      "Open": "1064.1",
      "High": "1100",
      "Low": "1049.4",
      "Close": "1053.7"
  },
  {
      "Date": "6/2/98",
      "Open": "1053.4",
      "High": "1053.65",
      "Low": "1016.25",
      "Close": "1019.6"
  },
  {
      "Date": "6/3/98",
      "Open": "1019.35",
      "High": "1037.3",
      "Low": "1009.95",
      "Close": "1033.9"
  },
  {
      "Date": "6/4/98",
      "Open": "1034.1",
      "High": "1044.4",
      "Low": "1023.35",
      "Close": "1027"
  },
  {
      "Date": "6/5/98",
      "Open": "1026.6",
      "High": "1026.6",
      "Low": "989.2",
      "Close": "993.5"
  },
  {
      "Date": "6/8/98",
      "Open": "993.5",
      "High": "1003.15",
      "Low": "936.55",
      "Close": "968.6"
  },
  {
      "Date": "6/9/98",
      "Open": "968.9",
      "High": "1002.45",
      "Low": "964.7",
      "Close": "995.05"
  },
  {
      "Date": "6/10/98",
      "Open": "994.55",
      "High": "998.65",
      "Low": "959.6",
      "Close": "962.9"
  },
  {
      "Date": "6/11/98",
      "Open": "962.15",
      "High": "964.65",
      "Low": "929.95",
      "Close": "961.35"
  },
  {
      "Date": "6/12/98",
      "Open": "961.6",
      "High": "987.3",
      "Low": "961.6",
      "Close": "971.15"
  },
  {
      "Date": "6/15/98",
      "Open": "970.95",
      "High": "970.95",
      "Low": "914.3",
      "Close": "916.8"
  },
  {
      "Date": "6/16/98",
      "Open": "916.25",
      "High": "916.25",
      "Low": "862.15",
      "Close": "910.1"
  },
  {
      "Date": "6/17/98",
      "Open": "910.9",
      "High": "980.3",
      "Low": "910.9",
      "Close": "976.5"
  },
  {
      "Date": "6/18/98",
      "Open": "977",
      "High": "990.15",
      "Low": "952.65",
      "Close": "956.15"
  },
  {
      "Date": "6/19/98",
      "Open": "955.05",
      "High": "955.05",
      "Low": "910.55",
      "Close": "913.25"
  },
  {
      "Date": "6/22/98",
      "Open": "912.9",
      "High": "912.9",
      "Low": "853.3",
      "Close": "880.2"
  },
  {
      "Date": "6/23/98",
      "Open": "882.2",
      "High": "882.2",
      "Low": "853.05",
      "Close": "875.6"
  },
  {
      "Date": "6/24/98",
      "Open": "875.45",
      "High": "910.05",
      "Low": "873.6",
      "Close": "908.5"
  },
  {
      "Date": "6/25/98",
      "Open": "908.65",
      "High": "939.15",
      "Low": "908.65",
      "Close": "932.15"
  },
  {
      "Date": "6/26/98",
      "Open": "931.8",
      "High": "932.5",
      "Low": "914.45",
      "Close": "924.35"
  },
  {
      "Date": "6/29/98",
      "Open": "923.2",
      "High": "951.2",
      "Low": "916.65",
      "Close": "949.1"
  },
  {
      "Date": "6/30/98",
      "Open": "949.45",
      "High": "952.75",
      "Low": "933",
      "Close": "941.65"
  },
  {
      "Date": "7/1/98",
      "Open": "941.6",
      "High": "945.55",
      "Low": "916.5",
      "Close": "942.25"
  },
  {
      "Date": "7/2/98",
      "Open": "941.65",
      "High": "941.65",
      "Low": "918.1",
      "Close": "919.9"
  },
  {
      "Date": "7/3/98",
      "Open": "920.05",
      "High": "920.05",
      "Low": "898.65",
      "Close": "900.55"
  },
  {
      "Date": "7/6/98",
      "Open": "901",
      "High": "919.5",
      "Low": "900.9",
      "Close": "917.35"
  },
  {
      "Date": "7/7/98",
      "Open": "917.45",
      "High": "936",
      "Low": "917.45",
      "Close": "924.7"
  },
  {
      "Date": "7/8/98",
      "Open": "925.15",
      "High": "948.3",
      "Low": "924.95",
      "Close": "944.15"
  },
  {
      "Date": "7/9/98",
      "Open": "944.1",
      "High": "966.1",
      "Low": "944.1",
      "Close": "961.05"
  },
  {
      "Date": "7/10/98",
      "Open": "961.65",
      "High": "982.2",
      "Low": "961.65",
      "Close": "980.15"
  },
  {
      "Date": "7/13/98",
      "Open": "980.3",
      "High": "991.7",
      "Low": "959.4",
      "Close": "964.35"
  },
  {
      "Date": "7/14/98",
      "Open": "964.1",
      "High": "970.3",
      "Low": "954.5",
      "Close": "967.35"
  },
  {
      "Date": "7/15/98",
      "Open": "968.05",
      "High": "988.55",
      "Low": "968.05",
      "Close": "971.4"
  },
  {
      "Date": "7/16/98",
      "Open": "972",
      "High": "1005.9",
      "Low": "969.55",
      "Close": "1004"
  },
  {
      "Date": "7/17/98",
      "Open": "1005.1",
      "High": "1015.55",
      "Low": "996.6",
      "Close": "1000.9"
  },
  {
      "Date": "7/20/98",
      "Open": "1001.9",
      "High": "1002.4",
      "Low": "973.75",
      "Close": "977.3"
  },
  {
      "Date": "7/21/98",
      "Open": "977.65",
      "High": "983.4",
      "Low": "960.85",
      "Close": "965.5"
  },
  {
      "Date": "7/22/98",
      "Open": "965.55",
      "High": "976.5",
      "Low": "959.5",
      "Close": "965.7"
  },
  {
      "Date": "7/23/98",
      "Open": "965.35",
      "High": "965.35",
      "Low": "931.75",
      "Close": "934.4"
  },
  {
      "Date": "7/24/98",
      "Open": "933.5",
      "High": "934.75",
      "Low": "915.5",
      "Close": "920.1"
  },
  {
      "Date": "7/27/98",
      "Open": "919.4",
      "High": "929.45",
      "Low": "895.85",
      "Close": "899.35"
  },
  {
      "Date": "7/28/98",
      "Open": "899.1",
      "High": "920",
      "Low": "897.65",
      "Close": "917.25"
  },
  {
      "Date": "7/29/98",
      "Open": "917.4",
      "High": "940.15",
      "Low": "917.4",
      "Close": "937.85"
  },
  {
      "Date": "7/30/98",
      "Open": "938.15",
      "High": "949.85",
      "Low": "926.8",
      "Close": "932.6"
  },
  {
      "Date": "7/31/98",
      "Open": "932.6",
      "High": "939.6",
      "Low": "925.25",
      "Close": "931.4"
  },
  {
      "Date": "8/3/98",
      "Open": "930.8",
      "High": "930.8",
      "Low": "903.5",
      "Close": "906"
  },
  {
      "Date": "8/4/98",
      "Open": "905.65",
      "High": "913.45",
      "Low": "903.15",
      "Close": "906.2"
  },
  {
      "Date": "8/5/98",
      "Open": "905.65",
      "High": "911.8",
      "Low": "890.95",
      "Close": "907.45"
  },
  {
      "Date": "8/6/98",
      "Open": "907.9",
      "High": "919",
      "Low": "900.2",
      "Close": "902.75"
  },
  {
      "Date": "8/7/98",
      "Open": "902.75",
      "High": "903.55",
      "Low": "892.9",
      "Close": "895.55"
  },
  {
      "Date": "8/10/98",
      "Open": "895.15",
      "High": "895.15",
      "Low": "877.55",
      "Close": "878.75"
  },
  {
      "Date": "8/11/98",
      "Open": "878.7",
      "High": "878.7",
      "Low": "863.65",
      "Close": "866.5"
  },
  {
      "Date": "8/12/98",
      "Open": "866.35",
      "High": "871.4",
      "Low": "853.1",
      "Close": "860.1"
  },
  {
      "Date": "8/13/98",
      "Open": "859.8",
      "High": "878.4",
      "Low": "854.5",
      "Close": "872.25"
  },
  {
      "Date": "8/14/98",
      "Open": "872.85",
      "High": "876.8",
      "Low": "863.55",
      "Close": "864.1"
  },
  {
      "Date": "8/17/98",
      "Open": "864.35",
      "High": "873.2",
      "Low": "853.05",
      "Close": "854.55"
  },
  {
      "Date": "8/18/98",
      "Open": "854.55",
      "High": "854.8",
      "Low": "831.1",
      "Close": "832.45"
  },
  {
      "Date": "8/19/98",
      "Open": "832.85",
      "High": "853.9",
      "Low": "830.15",
      "Close": "838.3"
  },
  {
      "Date": "8/20/98",
      "Open": "839.3",
      "High": "871.15",
      "Low": "839.3",
      "Close": "868.7"
  },
  {
      "Date": "8/21/98",
      "Open": "868.55",
      "High": "871.15",
      "Low": "853.4",
      "Close": "855.45"
  },
  {
      "Date": "8/24/98",
      "Open": "855.2",
      "High": "860.95",
      "Low": "845.35",
      "Close": "859.8"
  },
  {
      "Date": "8/25/98",
      "Open": "859.85",
      "High": "874.15",
      "Low": "859.85",
      "Close": "871.9"
  },
  {
      "Date": "8/27/98",
      "Open": "872.15",
      "High": "889.7",
      "Low": "872.15",
      "Close": "877.45"
  },
  {
      "Date": "8/28/98",
      "Open": "877.15",
      "High": "877.15",
      "Low": "847.15",
      "Close": "849.8"
  },
  {
      "Date": "8/31/98",
      "Open": "850.3",
      "High": "858.6",
      "Low": "841.1",
      "Close": "852.8"
  },
  {
      "Date": "9/1/98",
      "Open": "852.75",
      "High": "852.75",
      "Low": "832.05",
      "Close": "834.55"
  },
  {
      "Date": "9/2/98",
      "Open": "834.55",
      "High": "847.95",
      "Low": "834.55",
      "Close": "842.5"
  },
  {
      "Date": "9/3/98",
      "Open": "842.3",
      "High": "852.05",
      "Low": "838.75",
      "Close": "846.45"
  },
  {
      "Date": "9/4/98",
      "Open": "846.9",
      "High": "868.05",
      "Low": "846.9",
      "Close": "866.5"
  },
  {
      "Date": "9/7/98",
      "Open": "866.9",
      "High": "889.45",
      "Low": "866.9",
      "Close": "887.1"
  },
  {
      "Date": "9/8/98",
      "Open": "887.25",
      "High": "902.05",
      "Low": "882.6",
      "Close": "899.95"
  },
  {
      "Date": "9/9/98",
      "Open": "900.2",
      "High": "916.8",
      "Low": "900.2",
      "Close": "903.6"
  },
  {
      "Date": "9/10/98",
      "Open": "903.15",
      "High": "913.2",
      "Low": "897.35",
      "Close": "907.4"
  },
  {
      "Date": "9/11/98",
      "Open": "907.1",
      "High": "907.1",
      "Low": "893.3",
      "Close": "899.7"
  },
  {
      "Date": "9/14/98",
      "Open": "900.2",
      "High": "921.65",
      "Low": "900.2",
      "Close": "920.5"
  },
  {
      "Date": "9/15/98",
      "Open": "920.1",
      "High": "923.2",
      "Low": "915.6",
      "Close": "920.8"
  },
  {
      "Date": "9/16/98",
      "Open": "921.4",
      "High": "926.55",
      "Low": "912.6",
      "Close": "914.5"
  },
  {
      "Date": "9/17/98",
      "Open": "914.45",
      "High": "919.95",
      "Low": "908.55",
      "Close": "910.1"
  },
  {
      "Date": "9/18/98",
      "Open": "910",
      "High": "910",
      "Low": "899.95",
      "Close": "901.65"
  },
  {
      "Date": "9/21/98",
      "Open": "902.1",
      "High": "908.05",
      "Low": "895.85",
      "Close": "897.7"
  },
  {
      "Date": "9/22/98",
      "Open": "897.85",
      "High": "900.8",
      "Low": "891.4",
      "Close": "897.25"
  },
  {
      "Date": "9/23/98",
      "Open": "897.9",
      "High": "910.75",
      "Low": "897.9",
      "Close": "907.75"
  },
  {
      "Date": "9/24/98",
      "Open": "908.2",
      "High": "936.5",
      "Low": "908.2",
      "Close": "934.8"
  },
  {
      "Date": "9/25/98",
      "Open": "935.3",
      "High": "945.25",
      "Low": "933",
      "Close": "934.95"
  },
  {
      "Date": "9/28/98",
      "Open": "935.6",
      "High": "941.45",
      "Low": "926",
      "Close": "927.9"
  },
  {
      "Date": "9/29/98",
      "Open": "928.3",
      "High": "932.5",
      "Low": "902.4",
      "Close": "908.4"
  },
  {
      "Date": "9/30/98",
      "Open": "908.3",
      "High": "912.25",
      "Low": "896.05",
      "Close": "904.95"
  },
  {
      "Date": "10/5/98",
      "Open": "904.75",
      "High": "904.75",
      "Low": "839.3",
      "Close": "840.75"
  },
  {
      "Date": "10/6/98",
      "Open": "839.95",
      "High": "848.2",
      "Low": "820.1",
      "Close": "845.75"
  },
  {
      "Date": "10/7/98",
      "Open": "846.15",
      "High": "875.75",
      "Low": "846.05",
      "Close": "874.25"
  },
  {
      "Date": "10/8/98",
      "Open": "874.65",
      "High": "885.25",
      "Low": "845.1",
      "Close": "847.95"
  },
  {
      "Date": "10/9/98",
      "Open": "847.95",
      "High": "861.55",
      "Low": "842.1",
      "Close": "849.1"
  },
  {
      "Date": "10/12/98",
      "Open": "849.7",
      "High": "860.9",
      "Low": "832.1",
      "Close": "835.8"
  },
  {
      "Date": "10/13/98",
      "Open": "835.45",
      "High": "835.75",
      "Low": "810",
      "Close": "816.75"
  },
  {
      "Date": "10/14/98",
      "Open": "816.55",
      "High": "846.75",
      "Low": "816.55",
      "Close": "843.55"
  },
  {
      "Date": "10/15/98",
      "Open": "843.65",
      "High": "845.9",
      "Low": "832.1",
      "Close": "835.65"
  },
  {
      "Date": "10/16/98",
      "Open": "836.25",
      "High": "844.7",
      "Low": "829.25",
      "Close": "839.5"
  },
  {
      "Date": "10/19/98",
      "Open": "839.7",
      "High": "840",
      "Low": "830.1",
      "Close": "831.25"
  },
  {
      "Date": "10/20/98",
      "Open": "831.15",
      "High": "831.45",
      "Low": "803.3",
      "Close": "808.7"
  },
  {
      "Date": "10/22/98",
      "Open": "809.3",
      "High": "825.2",
      "Low": "805.7",
      "Close": "811.6"
  },
  {
      "Date": "10/23/98",
      "Open": "811.85",
      "High": "820.35",
      "Low": "804.7",
      "Close": "817.35"
  },
  {
      "Date": "10/26/98",
      "Open": "817.6",
      "High": "854.1",
      "Low": "817.6",
      "Close": "845.25"
  },
  {
      "Date": "10/27/98",
      "Open": "845.45",
      "High": "848.25",
      "Low": "835.7",
      "Close": "841.6"
  },
  {
      "Date": "10/28/98",
      "Open": "842.8",
      "High": "857.75",
      "Low": "831.55",
      "Close": "833.85"
  },
  {
      "Date": "10/29/98",
      "Open": "833.55",
      "High": "834",
      "Low": "818.95",
      "Close": "832.25"
  },
  {
      "Date": "10/30/98",
      "Open": "832.65",
      "High": "839.2",
      "Low": "826.85",
      "Close": "828.85"
  },
  {
      "Date": "10/31/98",
      "Open": "828.25",
      "High": "828.25",
      "Low": "819.85",
      "Close": "824"
  },
  {
      "Date": "11/2/98",
      "Open": "824.2",
      "High": "829.1",
      "Low": "812.3",
      "Close": "823.2"
  },
  {
      "Date": "11/3/98",
      "Open": "823.2",
      "High": "825.3",
      "Low": "818.6",
      "Close": "820.9"
  },
  {
      "Date": "11/5/98",
      "Open": "821.35",
      "High": "830.4",
      "Low": "821.35",
      "Close": "829.15"
  },
  {
      "Date": "11/6/98",
      "Open": "829.2",
      "High": "842.15",
      "Low": "829.2",
      "Close": "840.8"
  },
  {
      "Date": "11/9/98",
      "Open": "841.3",
      "High": "870.5",
      "Low": "841.3",
      "Close": "864.65"
  },
  {
      "Date": "11/10/98",
      "Open": "864.8",
      "High": "871.6",
      "Low": "859.7",
      "Close": "868.7"
  },
  {
      "Date": "11/11/98",
      "Open": "869.45",
      "High": "882.1",
      "Low": "866.6",
      "Close": "868.85"
  },
  {
      "Date": "11/12/98",
      "Open": "868.45",
      "High": "874.2",
      "Low": "857.2",
      "Close": "871.75"
  },
  {
      "Date": "11/13/98",
      "Open": "871.7",
      "High": "877.25",
      "Low": "865",
      "Close": "867.25"
  },
  {
      "Date": "11/16/98",
      "Open": "866.5",
      "High": "866.5",
      "Low": "854.3",
      "Close": "855.35"
  },
  {
      "Date": "11/17/98",
      "Open": "855.45",
      "High": "861.65",
      "Low": "854.55",
      "Close": "860"
  },
  {
      "Date": "11/18/98",
      "Open": "862.9",
      "High": "876.15",
      "Low": "862.9",
      "Close": "875.05"
  },
  {
      "Date": "11/19/98",
      "Open": "875.75",
      "High": "878.85",
      "Low": "864.3",
      "Close": "865.1"
  },
  {
      "Date": "11/20/98",
      "Open": "865.1",
      "High": "867.7",
      "Low": "855.35",
      "Close": "857.95"
  },
  {
      "Date": "11/21/98",
      "Open": "857.85",
      "High": "857.95",
      "Low": "853.95",
      "Close": "857.4"
  },
  {
      "Date": "11/23/98",
      "Open": "856.4",
      "High": "856.4",
      "Low": "844.85",
      "Close": "847.55"
  },
  {
      "Date": "11/24/98",
      "Open": "850.05",
      "High": "851.55",
      "Low": "840.75",
      "Close": "841.75"
  },
  {
      "Date": "11/26/98",
      "Open": "838.85",
      "High": "843.55",
      "Low": "821.4",
      "Close": "824.45"
  },
  {
      "Date": "11/27/98",
      "Open": "823.3",
      "High": "824.65",
      "Low": "810.3",
      "Close": "812.45"
  },
  {
      "Date": "11/28/98",
      "Open": "812.7",
      "High": "817.4",
      "Low": "804.3",
      "Close": "810.85"
  },
  {
      "Date": "11/30/98",
      "Open": "808.6",
      "High": "817.75",
      "Low": "800.1",
      "Close": "817.75"
  },
  {
      "Date": "12/1/98",
      "Open": "816.55",
      "High": "820.85",
      "Low": "812.25",
      "Close": "816.65"
  },
  {
      "Date": "12/2/98",
      "Open": "820.35",
      "High": "826.05",
      "Low": "817.35",
      "Close": "821.15"
  },
  {
      "Date": "12/3/98",
      "Open": "821.3",
      "High": "822.5",
      "Low": "814.5",
      "Close": "817.05"
  },
  {
      "Date": "12/4/98",
      "Open": "816.15",
      "High": "828.35",
      "Low": "813.1",
      "Close": "828.35"
  },
  {
      "Date": "12/7/98",
      "Open": "829.75",
      "High": "846",
      "Low": "828.2",
      "Close": "845.15"
  },
  {
      "Date": "12/8/98",
      "Open": "844.95",
      "High": "853.85",
      "Low": "840.5",
      "Close": "853"
  },
  {
      "Date": "12/9/98",
      "Open": "855.95",
      "High": "864.7",
      "Low": "855.1",
      "Close": "856.55"
  },
  {
      "Date": "12/10/98",
      "Open": "858.6",
      "High": "865.8",
      "Low": "858.5",
      "Close": "864.4"
  },
  {
      "Date": "12/11/98",
      "Open": "867.15",
      "High": "874.2",
      "Low": "865.7",
      "Close": "870"
  },
  {
      "Date": "12/14/98",
      "Open": "871.65",
      "High": "874.75",
      "Low": "861.95",
      "Close": "863.6"
  },
  {
      "Date": "12/15/98",
      "Open": "861",
      "High": "861.1",
      "Low": "844.55",
      "Close": "845.05"
  },
  {
      "Date": "12/16/98",
      "Open": "844.65",
      "High": "851.55",
      "Low": "836.85",
      "Close": "851.55"
  },
  {
      "Date": "12/17/98",
      "Open": "835.45",
      "High": "845.1",
      "Low": "827.9",
      "Close": "830.25"
  },
  {
      "Date": "12/18/98",
      "Open": "830.3",
      "High": "838.8",
      "Low": "827.3",
      "Close": "835.2"
  },
  {
      "Date": "12/21/98",
      "Open": "845.6",
      "High": "859.55",
      "Low": "845.25",
      "Close": "859.3"
  },
  {
      "Date": "12/22/98",
      "Open": "864.55",
      "High": "864.9",
      "Low": "851.35",
      "Close": "857.3"
  },
  {
      "Date": "12/23/98",
      "Open": "866.25",
      "High": "874.65",
      "Low": "858.7",
      "Close": "858.7"
  },
  {
      "Date": "12/24/98",
      "Open": "856.3",
      "High": "863.5",
      "Low": "854.75",
      "Close": "861.1"
  },
  {
      "Date": "12/28/98",
      "Open": "869.75",
      "High": "882.7",
      "Low": "866.25",
      "Close": "879.85"
  },
  {
      "Date": "12/29/98",
      "Open": "876.9",
      "High": "881.25",
      "Low": "867.8",
      "Close": "872.65"
  },
  {
      "Date": "12/30/98",
      "Open": "887.4",
      "High": "903.85",
      "Low": "887",
      "Close": "899.95"
  },
  {
      "Date": "12/31/98",
      "Open": "900.55",
      "High": "902.9",
      "Low": "884.25",
      "Close": "884.25"
  },
  {
      "Date": "1/1/99",
      "Open": "886.75",
      "High": "892.2",
      "Low": "882.6",
      "Close": "890.8"
  },
  {
      "Date": "1/4/99",
      "Open": "896.4",
      "High": "905.45",
      "Low": "895.75",
      "Close": "897.8"
  },
  {
      "Date": "1/5/99",
      "Open": "901",
      "High": "907.2",
      "Low": "893.15",
      "Close": "907.2"
  },
  {
      "Date": "1/6/99",
      "Open": "923.3",
      "High": "930.55",
      "Low": "915.65",
      "Close": "928.25"
  },
  {
      "Date": "1/7/99",
      "Open": "932.95",
      "High": "961.15",
      "Low": "932.95",
      "Close": "954.7"
  },
  {
      "Date": "1/8/99",
      "Open": "969.95",
      "High": "990.05",
      "Low": "966.4",
      "Close": "990.05"
  },
  {
      "Date": "1/11/99",
      "Open": "1005.15",
      "High": "1016.3",
      "Low": "985.6",
      "Close": "985.6"
  },
  {
      "Date": "1/12/99",
      "Open": "976.7",
      "High": "985.55",
      "Low": "953.9",
      "Close": "964.45"
  },
  {
      "Date": "1/13/99",
      "Open": "980.3",
      "High": "986.25",
      "Low": "958.05",
      "Close": "961.2"
  },
  {
      "Date": "1/14/99",
      "Open": "948",
      "High": "968.4",
      "Low": "944.5",
      "Close": "954.75"
  },
  {
      "Date": "1/15/99",
      "Open": "961.7",
      "High": "967.85",
      "Low": "932.25",
      "Close": "934.3"
  },
  {
      "Date": "1/18/99",
      "Open": "938.65",
      "High": "945.05",
      "Low": "922.8",
      "Close": "944.9"
  },
  {
      "Date": "1/19/99",
      "Open": "944.15",
      "High": "948",
      "Low": "923.2",
      "Close": "924.1"
  },
  {
      "Date": "1/21/99",
      "Open": "935.15",
      "High": "967.2",
      "Low": "932.8",
      "Close": "967.2"
  },
  {
      "Date": "1/22/99",
      "Open": "976.6",
      "High": "980.45",
      "Low": "955.15",
      "Close": "957.05"
  },
  {
      "Date": "1/25/99",
      "Open": "954.35",
      "High": "956.65",
      "Low": "935",
      "Close": "949.8"
  },
  {
      "Date": "1/27/99",
      "Open": "968.55",
      "High": "985.25",
      "Low": "967.65",
      "Close": "971.3"
  },
  {
      "Date": "1/28/99",
      "Open": "971.2",
      "High": "976.75",
      "Low": "954.25",
      "Close": "955.1"
  },
  {
      "Date": "1/29/99",
      "Open": "956.8",
      "High": "966.35",
      "Low": "952.7",
      "Close": "966.2"
  },
  {
      "Date": "2/1/99",
      "Open": "962.4",
      "High": "968.05",
      "Low": "940.15",
      "Close": "940.15"
  },
  {
      "Date": "2/2/99",
      "Open": "940.1",
      "High": "945.15",
      "Low": "924.95",
      "Close": "931.2"
  },
  {
      "Date": "2/3/99",
      "Open": "948",
      "High": "955.55",
      "Low": "940.75",
      "Close": "952.4"
  },
  {
      "Date": "2/4/99",
      "Open": "957.15",
      "High": "958.8",
      "Low": "937.5",
      "Close": "939.7"
  },
  {
      "Date": "2/5/99",
      "Open": "939.65",
      "High": "943.7",
      "Low": "930.1",
      "Close": "936.3"
  },
  {
      "Date": "2/8/99",
      "Open": "937.75",
      "High": "940.9",
      "Low": "916.5",
      "Close": "916.5"
  },
  {
      "Date": "2/9/99",
      "Open": "914.7",
      "High": "924.75",
      "Low": "907.45",
      "Close": "924.75"
  },
  {
      "Date": "2/10/99",
      "Open": "938.8",
      "High": "959.6",
      "Low": "935.2",
      "Close": "959.45"
  },
  {
      "Date": "2/11/99",
      "Open": "966.85",
      "High": "973.7",
      "Low": "958.15",
      "Close": "960.05"
  },
  {
      "Date": "2/12/99",
      "Open": "965.7",
      "High": "972.75",
      "Low": "962.35",
      "Close": "970.25"
  },
  {
      "Date": "2/15/99",
      "Open": "974.1",
      "High": "976.85",
      "Low": "954.55",
      "Close": "954.55"
  },
  {
      "Date": "2/16/99",
      "Open": "952.55",
      "High": "960.25",
      "Low": "951.5",
      "Close": "958.9"
  },
  {
      "Date": "2/17/99",
      "Open": "970.1",
      "High": "978.75",
      "Low": "953.1",
      "Close": "969.05"
  },
  {
      "Date": "2/18/99",
      "Open": "974.15",
      "High": "989.15",
      "Low": "968.7",
      "Close": "973.45"
  },
  {
      "Date": "2/19/99",
      "Open": "979.4",
      "High": "985.4",
      "Low": "975.85",
      "Close": "976.3"
  },
  {
      "Date": "2/22/99",
      "Open": "978.5",
      "High": "978.85",
      "Low": "953.6",
      "Close": "953.85"
  },
  {
      "Date": "2/23/99",
      "Open": "949.3",
      "High": "952.65",
      "Low": "938.65",
      "Close": "949.65"
  },
  {
      "Date": "2/24/99",
      "Open": "959.8",
      "High": "961.4",
      "Low": "947.95",
      "Close": "957.65"
  },
  {
      "Date": "2/25/99",
      "Open": "954.7",
      "High": "962.6",
      "Low": "951.9",
      "Close": "954.3"
  },
  {
      "Date": "2/26/99",
      "Open": "955.4",
      "High": "955.75",
      "Low": "938.2",
      "Close": "941.2"
  },
  {
      "Date": "2/27/99",
      "Open": "947.35",
      "High": "981.55",
      "Low": "932.2",
      "Close": "981.3"
  },
  {
      "Date": "3/1/99",
      "Open": "992.45",
      "High": "1018.4",
      "Low": "984.45",
      "Close": "1015.8"
  },
  {
      "Date": "3/3/99",
      "Open": "1066.4",
      "High": "1073.05",
      "Low": "1051.8",
      "Close": "1051.8"
  },
  {
      "Date": "3/4/99",
      "Open": "1056.85",
      "High": "1060.55",
      "Low": "1030.45",
      "Close": "1041.2"
  },
  {
      "Date": "3/5/99",
      "Open": "1043.45",
      "High": "1061.4",
      "Low": "1033.8",
      "Close": "1054.4"
  },
  {
      "Date": "3/8/99",
      "Open": "1064.35",
      "High": "1080.3",
      "Low": "1056.85",
      "Close": "1072.1"
  },
  {
      "Date": "3/9/99",
      "Open": "1074.25",
      "High": "1084.65",
      "Low": "1071.75",
      "Close": "1078.3"
  },
  {
      "Date": "3/10/99",
      "Open": "1099.15",
      "High": "1099.8",
      "Low": "1062.7",
      "Close": "1078.8"
  },
  {
      "Date": "3/11/99",
      "Open": "1080.15",
      "High": "1085.4",
      "Low": "1059.6",
      "Close": "1059.6"
  },
  {
      "Date": "3/12/99",
      "Open": "1066.85",
      "High": "1074.15",
      "Low": "1057.1",
      "Close": "1072.6"
  },
  {
      "Date": "3/15/99",
      "Open": "1073.15",
      "High": "1082.5",
      "Low": "1063.45",
      "Close": "1073.9"
  },
  {
      "Date": "3/16/99",
      "Open": "1068.85",
      "High": "1075.7",
      "Low": "1052.9",
      "Close": "1053.1"
  },
  {
      "Date": "3/17/99",
      "Open": "1078.2",
      "High": "1088.55",
      "Low": "1057.75",
      "Close": "1060.6"
  },
  {
      "Date": "3/18/99",
      "Open": "1061.4",
      "High": "1074.6",
      "Low": "1058.25",
      "Close": "1074.6"
  },
  {
      "Date": "3/19/99",
      "Open": "1080.7",
      "High": "1083.15",
      "Low": "1056.4",
      "Close": "1062.9"
  },
  {
      "Date": "3/20/99",
      "Open": "1063.15",
      "High": "1063.65",
      "Low": "1053.6",
      "Close": "1060.3"
  },
  {
      "Date": "3/22/99",
      "Open": "1052.6",
      "High": "1066.6",
      "Low": "1044.1",
      "Close": "1063"
  },
  {
      "Date": "3/23/99",
      "Open": "1063.95",
      "High": "1079.85",
      "Low": "1063.9",
      "Close": "1072.9"
  },
  {
      "Date": "3/24/99",
      "Open": "1091.15",
      "High": "1091.9",
      "Low": "1047.05",
      "Close": "1062.8"
  },
  {
      "Date": "3/25/99",
      "Open": "1057.95",
      "High": "1068.15",
      "Low": "1053.3",
      "Close": "1054.9"
  },
  {
      "Date": "3/26/99",
      "Open": "1054.95",
      "High": "1057.8",
      "Low": "1033",
      "Close": "1041.2"
  },
  {
      "Date": "3/30/99",
      "Open": "1036",
      "High": "1054.65",
      "Low": "1028.45",
      "Close": "1054.6"
  },
  {
      "Date": "3/31/99",
      "Open": "1076.95",
      "High": "1090.4",
      "Low": "1073.65",
      "Close": "1078"
  },
  {
      "Date": "4/1/99",
      "Open": "1082.55",
      "High": "1084.05",
      "Low": "1062.45",
      "Close": "1063.4"
  },
  {
      "Date": "4/5/99",
      "Open": "1030.65",
      "High": "1036.45",
      "Low": "1008.35",
      "Close": "1011.4"
  },
  {
      "Date": "4/6/99",
      "Open": "999.8",
      "High": "1025.95",
      "Low": "993",
      "Close": "1024.2"
  },
  {
      "Date": "4/7/99",
      "Open": "1027.35",
      "High": "1043.4",
      "Low": "1027",
      "Close": "1031"
  },
  {
      "Date": "4/8/99",
      "Open": "1033",
      "High": "1036.95",
      "Low": "1015.3",
      "Close": "1020.3"
  },
  {
      "Date": "4/9/99",
      "Open": "1021.55",
      "High": "1022.15",
      "Low": "989.75",
      "Close": "993.4"
  },
  {
      "Date": "4/12/99",
      "Open": "984.55",
      "High": "999.65",
      "Low": "972.7",
      "Close": "998.2"
  },
  {
      "Date": "4/13/99",
      "Open": "991.05",
      "High": "1006.15",
      "Low": "989.9",
      "Close": "994.25"
  },
  {
      "Date": "4/15/99",
      "Open": "968.4",
      "High": "980.65",
      "Low": "964.35",
      "Close": "968.6"
  },
  {
      "Date": "4/16/99",
      "Open": "970.95",
      "High": "1044.85",
      "Low": "965.15",
      "Close": "1044.4"
  },
  {
      "Date": "4/17/99",
      "Open": "1065.5",
      "High": "1069.55",
      "Low": "961.4",
      "Close": "966.95"
  },
  {
      "Date": "4/19/99",
      "Open": "961.3",
      "High": "1000.6",
      "Low": "955.75",
      "Close": "993.9"
  },
  {
      "Date": "4/20/99",
      "Open": "995.65",
      "High": "1003.7",
      "Low": "983.7",
      "Close": "995.4"
  },
  {
      "Date": "4/21/99",
      "Open": "997.35",
      "High": "1003.4",
      "Low": "990.95",
      "Close": "996.65"
  },
  {
      "Date": "4/22/99",
      "Open": "999.55",
      "High": "1012",
      "Low": "971.9",
      "Close": "981.65"
  },
  {
      "Date": "4/23/99",
      "Open": "987.05",
      "High": "994.3",
      "Low": "968.15",
      "Close": "982"
  },
  {
      "Date": "4/26/99",
      "Open": "945.9",
      "High": "955.5",
      "Low": "931.3",
      "Close": "931.35"
  },
  {
      "Date": "4/28/99",
      "Open": "928.85",
      "High": "944.45",
      "Low": "916",
      "Close": "943.5"
  },
  {
      "Date": "4/29/99",
      "Open": "950.25",
      "High": "966.6",
      "Low": "948.4",
      "Close": "966.6"
  },
  {
      "Date": "4/30/99",
      "Open": "970.9",
      "High": "980.75",
      "Low": "969.6",
      "Close": "978.2"
  },
  {
      "Date": "5/3/99",
      "Open": "979.8",
      "High": "986.4",
      "Low": "968.25",
      "Close": "970.75"
  },
  {
      "Date": "5/4/99",
      "Open": "971.9",
      "High": "1008.35",
      "Low": "971.8",
      "Close": "1008.3"
  },
  {
      "Date": "5/5/99",
      "Open": "1014.65",
      "High": "1034.55",
      "Low": "1004.9",
      "Close": "1019.5"
  },
  {
      "Date": "5/6/99",
      "Open": "1024.95",
      "High": "1048.05",
      "Low": "1023.95",
      "Close": "1046.9"
  },
  {
      "Date": "5/7/99",
      "Open": "1065.4",
      "High": "1080.7",
      "Low": "1059.05",
      "Close": "1062.9"
  },
  {
      "Date": "5/10/99",
      "Open": "1070.75",
      "High": "1094.2",
      "Low": "1070.35",
      "Close": "1094.2"
  },
  {
      "Date": "5/11/99",
      "Open": "1102.2",
      "High": "1121.95",
      "Low": "1100.5",
      "Close": "1116"
  },
  {
      "Date": "5/12/99",
      "Open": "1135.45",
      "High": "1142.1",
      "Low": "1086.7",
      "Close": "1110.9"
  },
  {
      "Date": "5/13/99",
      "Open": "1131.95",
      "High": "1149.6",
      "Low": "1130.85",
      "Close": "1148.1"
  },
  {
      "Date": "5/14/99",
      "Open": "1157.6",
      "High": "1173.55",
      "Low": "1144.9",
      "Close": "1165.3"
  },
  {
      "Date": "5/17/99",
      "Open": "1164.45",
      "High": "1171.1",
      "Low": "1120.7",
      "Close": "1151.9"
  },
  {
      "Date": "5/18/99",
      "Open": "1155.75",
      "High": "1176.3",
      "Low": "1153.45",
      "Close": "1160.1"
  },
  {
      "Date": "5/19/99",
      "Open": "1169.6",
      "High": "1189.2",
      "Low": "1161.6",
      "Close": "1180.2"
  },
  {
      "Date": "5/20/99",
      "Open": "1181",
      "High": "1201.3",
      "Low": "1166.9",
      "Close": "1166.9"
  },
  {
      "Date": "5/21/99",
      "Open": "1176.95",
      "High": "1177",
      "Low": "1139.1",
      "Close": "1145.8"
  },
  {
      "Date": "5/24/99",
      "Open": "1147.4",
      "High": "1154.5",
      "Low": "1132.5",
      "Close": "1151.6"
  },
  {
      "Date": "5/25/99",
      "Open": "1148.05",
      "High": "1164.2",
      "Low": "1147.4",
      "Close": "1160.2"
  },
  {
      "Date": "5/26/99",
      "Open": "1165.15",
      "High": "1170.1",
      "Low": "1122.6",
      "Close": "1135.5"
  },
  {
      "Date": "5/27/99",
      "Open": "1132.85",
      "High": "1147.95",
      "Low": "1091.45",
      "Close": "1091.4"
  },
  {
      "Date": "5/28/99",
      "Open": "1088",
      "High": "1098.25",
      "Low": "1049.95",
      "Close": "1081.5"
  },
  {
      "Date": "5/31/99",
      "Open": "1098",
      "High": "1132.8",
      "Low": "1097.85",
      "Close": "1132.3"
  },
  {
      "Date": "6/1/99",
      "Open": "1125.55",
      "High": "1139.8",
      "Low": "1113",
      "Close": "1123.8"
  },
  {
      "Date": "6/2/99",
      "Open": "1120.65",
      "High": "1127.65",
      "Low": "1107.2",
      "Close": "1125.1"
  },
  {
      "Date": "6/3/99",
      "Open": "1129.9",
      "High": "1141.05",
      "Low": "1127.3",
      "Close": "1136.6"
  },
  {
      "Date": "6/4/99",
      "Open": "1150.05",
      "High": "1162",
      "Low": "1149.1",
      "Close": "1155"
  },
  {
      "Date": "6/7/99",
      "Open": "1159.55",
      "High": "1164.15",
      "Low": "1150.2",
      "Close": "1156.6"
  },
  {
      "Date": "6/8/99",
      "Open": "1145.9",
      "High": "1178.95",
      "Low": "1138.05",
      "Close": "1178.9"
  },
  {
      "Date": "6/9/99",
      "Open": "1180",
      "High": "1192.05",
      "Low": "1159.25",
      "Close": "1163.2"
  },
  {
      "Date": "6/10/99",
      "Open": "1163.7",
      "High": "1175.95",
      "Low": "1159.6",
      "Close": "1162.6"
  },
  {
      "Date": "6/11/99",
      "Open": "1161.8",
      "High": "1162.95",
      "Low": "1140.25",
      "Close": "1141.9"
  },
  {
      "Date": "6/14/99",
      "Open": "1140.75",
      "High": "1140.75",
      "Low": "1099.85",
      "Close": "1126.9"
  },
  {
      "Date": "6/15/99",
      "Open": "1127.4",
      "High": "1143.3",
      "Low": "1113.3",
      "Close": "1120.5"
  },
  {
      "Date": "6/16/99",
      "Open": "1120.35",
      "High": "1152.35",
      "Low": "1109.7",
      "Close": "1148.1"
  },
  {
      "Date": "6/17/99",
      "Open": "1148.95",
      "High": "1181.9",
      "Low": "1148.95",
      "Close": "1176.3"
  },
  {
      "Date": "6/18/99",
      "Open": "1177.15",
      "High": "1189.2",
      "Low": "1172.2",
      "Close": "1175.1"
  },
  {
      "Date": "6/21/99",
      "Open": "1175.3",
      "High": "1203.35",
      "Low": "1175.3",
      "Close": "1202.3"
  },
  {
      "Date": "6/22/99",
      "Open": "1202.95",
      "High": "1219.45",
      "Low": "1201.2",
      "Close": "1214.3"
  },
  {
      "Date": "6/23/99",
      "Open": "1215.95",
      "High": "1228.55",
      "Low": "1188.65",
      "Close": "1193.1"
  },
  {
      "Date": "6/24/99",
      "Open": "1192.55",
      "High": "1193.1",
      "Low": "1171.3",
      "Close": "1181.3"
  },
  {
      "Date": "6/25/99",
      "Open": "1181.6",
      "High": "1197",
      "Low": "1174.2",
      "Close": "1177"
  },
  {
      "Date": "6/28/99",
      "Open": "1177.05",
      "High": "1194.9",
      "Low": "1177.05",
      "Close": "1192.2"
  },
  {
      "Date": "6/29/99",
      "Open": "1192.2",
      "High": "1198.7",
      "Low": "1185.3",
      "Close": "1191.3"
  },
  {
      "Date": "6/30/99",
      "Open": "1191.45",
      "High": "1193.9",
      "Low": "1179.65",
      "Close": "1187.7"
  },
  {
      "Date": "7/1/99",
      "Open": "1187.85",
      "High": "1197",
      "Low": "1179.3",
      "Close": "1183.2"
  },
  {
      "Date": "7/2/99",
      "Open": "1183.65",
      "High": "1204.15",
      "Low": "1183.65",
      "Close": "1197.8"
  },
  {
      "Date": "7/5/99",
      "Open": "1197.95",
      "High": "1232.4",
      "Low": "1197.95",
      "Close": "1230.2"
  },
  {
      "Date": "7/6/99",
      "Open": "1230.3",
      "High": "1246.4",
      "Low": "1230.3",
      "Close": "1241.2"
  },
  {
      "Date": "7/7/99",
      "Open": "1241.65",
      "High": "1255.75",
      "Low": "1238.15",
      "Close": "1243.7"
  },
  {
      "Date": "7/8/99",
      "Open": "1243.9",
      "High": "1251.15",
      "Low": "1238.4",
      "Close": "1244"
  },
  {
      "Date": "7/9/99",
      "Open": "1244.4",
      "High": "1260.7",
      "Low": "1244.4",
      "Close": "1254"
  },
  {
      "Date": "7/12/99",
      "Open": "1254.1",
      "High": "1318.05",
      "Low": "1254.1",
      "Close": "1315.3"
  },
  {
      "Date": "7/13/99",
      "Open": "1315.75",
      "High": "1338.15",
      "Low": "1315.75",
      "Close": "1321.4"
  },
  {
      "Date": "7/14/99",
      "Open": "1322.9",
      "High": "1341.1",
      "Low": "1306.2",
      "Close": "1335.1"
  },
  {
      "Date": "7/15/99",
      "Open": "1335.55",
      "High": "1367.95",
      "Low": "1310.45",
      "Close": "1317.7"
  },
  {
      "Date": "7/16/99",
      "Open": "1318.3",
      "High": "1331.75",
      "Low": "1312.3",
      "Close": "1324.2"
  },
  {
      "Date": "7/19/99",
      "Open": "1325.35",
      "High": "1357.85",
      "Low": "1325.35",
      "Close": "1349.6"
  },
  {
      "Date": "7/20/99",
      "Open": "1349.6",
      "High": "1350.35",
      "Low": "1310.85",
      "Close": "1314.7"
  },
  {
      "Date": "7/21/99",
      "Open": "1314.8",
      "High": "1346.65",
      "Low": "1302.8",
      "Close": "1340.1"
  },
  {
      "Date": "7/22/99",
      "Open": "1339.05",
      "High": "1358.25",
      "Low": "1337.55",
      "Close": "1349.2"
  },
  {
      "Date": "7/23/99",
      "Open": "1350",
      "High": "1367.3",
      "Low": "1339.6",
      "Close": "1342.9"
  },
  {
      "Date": "7/26/99",
      "Open": "1342.6",
      "High": "1342.6",
      "Low": "1314.8",
      "Close": "1326.1"
  },
  {
      "Date": "7/27/99",
      "Open": "1325.4",
      "High": "1327",
      "Low": "1304.4",
      "Close": "1309.8"
  },
  {
      "Date": "7/28/99",
      "Open": "1309.75",
      "High": "1337",
      "Low": "1309.75",
      "Close": "1320.1"
  },
  {
      "Date": "7/29/99",
      "Open": "1320.75",
      "High": "1329.75",
      "Low": "1312.55",
      "Close": "1325.7"
  },
  {
      "Date": "7/30/99",
      "Open": "1325.55",
      "High": "1325.55",
      "Low": "1302.5",
      "Close": "1310.1"
  },
  {
      "Date": "8/2/99",
      "Open": "1309.85",
      "High": "1310.8",
      "Low": "1282.85",
      "Close": "1289.6"
  },
  {
      "Date": "8/3/99",
      "Open": "1289.2",
      "High": "1290.8",
      "Low": "1277.85",
      "Close": "1285"
  },
  {
      "Date": "8/4/99",
      "Open": "1285.9",
      "High": "1329.3",
      "Low": "1285.9",
      "Close": "1326.4"
  },
  {
      "Date": "8/5/99",
      "Open": "1326.75",
      "High": "1345.85",
      "Low": "1317.5",
      "Close": "1323.3"
  },
  {
      "Date": "8/6/99",
      "Open": "1323.15",
      "High": "1326",
      "Low": "1315.15",
      "Close": "1316.8"
  },
  {
      "Date": "8/9/99",
      "Open": "1316.9",
      "High": "1332.9",
      "Low": "1312.8",
      "Close": "1327.7"
  },
  {
      "Date": "8/10/99",
      "Open": "1328.15",
      "High": "1328.4",
      "Low": "1305.85",
      "Close": "1309.7"
  },
  {
      "Date": "8/11/99",
      "Open": "1308.75",
      "High": "1328.2",
      "Low": "1295.2",
      "Close": "1315.3"
  },
  {
      "Date": "8/12/99",
      "Open": "1315.9",
      "High": "1321.55",
      "Low": "1308.05",
      "Close": "1309.5"
  },
  {
      "Date": "8/13/99",
      "Open": "1309.7",
      "High": "1317.1",
      "Low": "1290.3",
      "Close": "1291.8"
  },
  {
      "Date": "8/16/99",
      "Open": "1292.35",
      "High": "1314.15",
      "Low": "1292.35",
      "Close": "1309.9"
  },
  {
      "Date": "8/17/99",
      "Open": "1310.3",
      "High": "1327.9",
      "Low": "1310.3",
      "Close": "1322.7"
  },
  {
      "Date": "8/18/99",
      "Open": "1326",
      "High": "1371.9",
      "Low": "1326",
      "Close": "1363.3"
  },
  {
      "Date": "8/19/99",
      "Open": "1363.5",
      "High": "1375.45",
      "Low": "1341.55",
      "Close": "1345"
  },
  {
      "Date": "8/20/99",
      "Open": "1345.6",
      "High": "1350.3",
      "Low": "1332.4",
      "Close": "1347.3"
  },
  {
      "Date": "8/23/99",
      "Open": "1347.75",
      "High": "1375.95",
      "Low": "1347.75",
      "Close": "1369.7"
  },
  {
      "Date": "8/24/99",
      "Open": "1370.05",
      "High": "1385.6",
      "Low": "1369.9",
      "Close": "1382.6"
  },
  {
      "Date": "8/25/99",
      "Open": "1383.45",
      "High": "1414",
      "Low": "1341.35",
      "Close": "1371.6"
  },
  {
      "Date": "8/26/99",
      "Open": "1371.9",
      "High": "1408.15",
      "Low": "1371.9",
      "Close": "1402.5"
  },
  {
      "Date": "8/27/99",
      "Open": "1402.6",
      "High": "1425.2",
      "Low": "1402.6",
      "Close": "1417.5"
  },
  {
      "Date": "8/30/99",
      "Open": "1417.8",
      "High": "1435.5",
      "Low": "1417.8",
      "Close": "1422.6"
  },
  {
      "Date": "8/31/99",
      "Open": "1422.65",
      "High": "1424.05",
      "Low": "1409.55",
      "Close": "1412"
  },
  {
      "Date": "9/1/99",
      "Open": "1412.05",
      "High": "1430.8",
      "Low": "1397.7",
      "Close": "1410.7"
  },
  {
      "Date": "9/2/99",
      "Open": "1410",
      "High": "1410",
      "Low": "1372.5",
      "Close": "1375.8"
  },
  {
      "Date": "9/3/99",
      "Open": "1375.7",
      "High": "1384.25",
      "Low": "1370.95",
      "Close": "1374.9"
  },
  {
      "Date": "9/6/99",
      "Open": "1375.45",
      "High": "1392.05",
      "Low": "1372.5",
      "Close": "1390.2"
  },
  {
      "Date": "9/7/99",
      "Open": "1391.2",
      "High": "1403.75",
      "Low": "1378.95",
      "Close": "1383.2"
  },
  {
      "Date": "9/8/99",
      "Open": "1385.55",
      "High": "1413.45",
      "Low": "1385.55",
      "Close": "1399.5"
  },
  {
      "Date": "9/9/99",
      "Open": "1400.15",
      "High": "1402.25",
      "Low": "1388.65",
      "Close": "1396.9"
  },
  {
      "Date": "9/10/99",
      "Open": "1397.15",
      "High": "1398.6",
      "Low": "1388.85",
      "Close": "1391.1"
  },
  {
      "Date": "9/14/99",
      "Open": "1391.9",
      "High": "1397.45",
      "Low": "1366.25",
      "Close": "1370.5"
  },
  {
      "Date": "9/15/99",
      "Open": "1371",
      "High": "1392.35",
      "Low": "1371",
      "Close": "1374.7"
  },
  {
      "Date": "9/16/99",
      "Open": "1374.45",
      "High": "1375.35",
      "Low": "1347.3",
      "Close": "1351.2"
  },
  {
      "Date": "9/17/99",
      "Open": "1350.35",
      "High": "1364.05",
      "Low": "1329.7",
      "Close": "1359.7"
  },
  {
      "Date": "9/20/99",
      "Open": "1359.8",
      "High": "1379.05",
      "Low": "1356.3",
      "Close": "1357.8"
  },
  {
      "Date": "9/21/99",
      "Open": "1358.45",
      "High": "1367.75",
      "Low": "1348.6",
      "Close": "1350.6"
  },
  {
      "Date": "9/22/99",
      "Open": "1350.7",
      "High": "1400",
      "Low": "1350.7",
      "Close": "1386.4"
  },
  {
      "Date": "9/23/99",
      "Open": "1387.95",
      "High": "1403.55",
      "Low": "1387.45",
      "Close": "1398"
  },
  {
      "Date": "9/24/99",
      "Open": "1397.45",
      "High": "1427.75",
      "Low": "1396.75",
      "Close": "1412.2"
  },
  {
      "Date": "9/27/99",
      "Open": "1413.8",
      "High": "1427.15",
      "Low": "1387.05",
      "Close": "1389.5"
  },
  {
      "Date": "9/28/99",
      "Open": "1389.25",
      "High": "1389.45",
      "Low": "1363.45",
      "Close": "1379.1"
  },
  {
      "Date": "9/29/99",
      "Open": "1380.7",
      "High": "1429.05",
      "Low": "1380.3",
      "Close": "1415.3"
  },
  {
      "Date": "9/30/99",
      "Open": "1415.55",
      "High": "1432.55",
      "Low": "1409.55",
      "Close": "1413.1"
  },
  {
      "Date": "10/1/99",
      "Open": "1414.65",
      "High": "1419.1",
      "Low": "1389.05",
      "Close": "1403.2"
  },
  {
      "Date": "10/4/99",
      "Open": "1403.45",
      "High": "1406.35",
      "Low": "1367.85",
      "Close": "1372.3"
  },
  {
      "Date": "10/5/99",
      "Open": "1372.4",
      "High": "1384.35",
      "Low": "1358.95",
      "Close": "1378.1"
  },
  {
      "Date": "10/6/99",
      "Open": "1377.65",
      "High": "1419.75",
      "Low": "1377.15",
      "Close": "1392.7"
  },
  {
      "Date": "10/7/99",
      "Open": "1394.35",
      "High": "1475.45",
      "Low": "1394.35",
      "Close": "1469.7"
  },
  {
      "Date": "10/8/99",
      "Open": "1470",
      "High": "1502.65",
      "Low": "1462.75",
      "Close": "1479.2"
  },
  {
      "Date": "10/11/99",
      "Open": "1480.8",
      "High": "1504.55",
      "Low": "1479.1",
      "Close": "1484"
  },
  {
      "Date": "10/12/99",
      "Open": "1484.4",
      "High": "1493.15",
      "Low": "1472.35",
      "Close": "1483.8"
  },
  {
      "Date": "10/13/99",
      "Open": "1485.45",
      "High": "1510.5",
      "Low": "1466",
      "Close": "1496.3"
  },
  {
      "Date": "10/14/99",
      "Open": "1497.3",
      "High": "1522.85",
      "Low": "1496.65",
      "Close": "1505.2"
  },
  {
      "Date": "10/15/99",
      "Open": "1505.7",
      "High": "1512",
      "Low": "1448.95",
      "Close": "1454.5"
  },
  {
      "Date": "10/18/99",
      "Open": "1454.9",
      "High": "1461.45",
      "Low": "1426.15",
      "Close": "1444"
  },
  {
      "Date": "10/20/99",
      "Open": "1446.2",
      "High": "1498.7",
      "Low": "1446.2",
      "Close": "1477.6"
  },
  {
      "Date": "10/21/99",
      "Open": "1478",
      "High": "1496.45",
      "Low": "1448.35",
      "Close": "1453.3"
  },
  {
      "Date": "10/22/99",
      "Open": "1452.6",
      "High": "1452.6",
      "Low": "1421.4",
      "Close": "1430.2"
  },
  {
      "Date": "10/23/99",
      "Open": "1430.75",
      "High": "1451.85",
      "Low": "1429.1",
      "Close": "1449.8"
  },
  {
      "Date": "10/25/99",
      "Open": "1449.85",
      "High": "1457.2",
      "Low": "1410.85",
      "Close": "1426.2"
  },
  {
      "Date": "10/26/99",
      "Open": "1426.6",
      "High": "1431.5",
      "Low": "1410.95",
      "Close": "1414.3"
  },
  {
      "Date": "10/27/99",
      "Open": "1419.1",
      "High": "1440.7",
      "Low": "1400.75",
      "Close": "1411.4"
  },
  {
      "Date": "10/28/99",
      "Open": "1411.2",
      "High": "1411.45",
      "Low": "1361.05",
      "Close": "1367.7"
  },
  {
      "Date": "10/29/99",
      "Open": "1367.9",
      "High": "1370.8",
      "Low": "1296.7",
      "Close": "1325.4"
  },
  {
      "Date": "11/1/99",
      "Open": "1325.9",
      "High": "1332.7",
      "Low": "1260.8",
      "Close": "1270"
  },
  {
      "Date": "11/2/99",
      "Open": "1269.3",
      "High": "1335.6",
      "Low": "1239.8",
      "Close": "1332.2"
  },
  {
      "Date": "11/3/99",
      "Open": "1332.7",
      "High": "1353.55",
      "Low": "1303.25",
      "Close": "1326.4"
  },
  {
      "Date": "11/4/99",
      "Open": "1326.85",
      "High": "1339.75",
      "Low": "1313.6",
      "Close": "1336.8"
  },
  {
      "Date": "11/5/99",
      "Open": "1336.75",
      "High": "1369.65",
      "Low": "1336.55",
      "Close": "1364.5"
  },
  {
      "Date": "11/7/99",
      "Open": "1364.7",
      "High": "1382.25",
      "Low": "1364.7",
      "Close": "1369.6"
  },
  {
      "Date": "11/9/99",
      "Open": "1370.5",
      "High": "1374.8",
      "Low": "1345.25",
      "Close": "1371.2"
  },
  {
      "Date": "11/10/99",
      "Open": "1371.6",
      "High": "1392.55",
      "Low": "1371.6",
      "Close": "1389.1"
  },
  {
      "Date": "11/11/99",
      "Open": "1389.2",
      "High": "1402.7",
      "Low": "1382.3",
      "Close": "1389.6"
  },
  {
      "Date": "11/12/99",
      "Open": "1389.25",
      "High": "1389.4",
      "Low": "1368.85",
      "Close": "1373.5"
  },
  {
      "Date": "11/15/99",
      "Open": "1372.75",
      "High": "1381.1",
      "Low": "1358.15",
      "Close": "1362.7"
  },
  {
      "Date": "11/16/99",
      "Open": "1362.3",
      "High": "1362.3",
      "Low": "1351.4",
      "Close": "1357.7"
  },
  {
      "Date": "11/17/99",
      "Open": "1367.65",
      "High": "1381.5",
      "Low": "1352.25",
      "Close": "1352.2"
  },
  {
      "Date": "11/18/99",
      "Open": "1353.4",
      "High": "1366.25",
      "Low": "1342.25",
      "Close": "1364.2"
  },
  {
      "Date": "11/19/99",
      "Open": "1365.25",
      "High": "1381.05",
      "Low": "1360.05",
      "Close": "1361.8"
  },
  {
      "Date": "11/22/99",
      "Open": "1362.4",
      "High": "1378.1",
      "Low": "1358.2",
      "Close": "1375.2"
  },
  {
      "Date": "11/24/99",
      "Open": "1376.5",
      "High": "1404.05",
      "Low": "1376.5",
      "Close": "1394.9"
  },
  {
      "Date": "11/25/99",
      "Open": "1395.85",
      "High": "1417.65",
      "Low": "1395.85",
      "Close": "1408.6"
  },
  {
      "Date": "11/26/99",
      "Open": "1409.35",
      "High": "1418.15",
      "Low": "1395.5",
      "Close": "1399.6"
  },
  {
      "Date": "11/29/99",
      "Open": "1399.8",
      "High": "1401.25",
      "Low": "1382.2",
      "Close": "1384.6"
  },
  {
      "Date": "11/30/99",
      "Open": "1384.9",
      "High": "1386.75",
      "Low": "1370.55",
      "Close": "1376.1"
  },
  {
      "Date": "12/1/99",
      "Open": "1375.05",
      "High": "1401.1",
      "Low": "1374.7",
      "Close": "1388.7"
  },
  {
      "Date": "12/2/99",
      "Open": "1388.2",
      "High": "1420.7",
      "Low": "1387.2",
      "Close": "1408.8"
  },
  {
      "Date": "12/3/99",
      "Open": "1409.05",
      "High": "1436.65",
      "Low": "1409.05",
      "Close": "1418"
  },
  {
      "Date": "12/6/99",
      "Open": "1418.3",
      "High": "1445.6",
      "Low": "1418.3",
      "Close": "1442.8"
  },
  {
      "Date": "12/7/99",
      "Open": "1443.2",
      "High": "1455.4",
      "Low": "1439.65",
      "Close": "1445.1"
  },
  {
      "Date": "12/8/99",
      "Open": "1451.1",
      "High": "1486.15",
      "Low": "1420.95",
      "Close": "1438.6"
  },
  {
      "Date": "12/9/99",
      "Open": "1437.1",
      "High": "1451.5",
      "Low": "1428.05",
      "Close": "1447.5"
  },
  {
      "Date": "12/10/99",
      "Open": "1448.05",
      "High": "1462.6",
      "Low": "1437",
      "Close": "1439.7"
  },
  {
      "Date": "12/13/99",
      "Open": "1440.2",
      "High": "1444.95",
      "Low": "1421.85",
      "Close": "1427.6"
  },
  {
      "Date": "12/14/99",
      "Open": "1427.9",
      "High": "1431.25",
      "Low": "1391.1",
      "Close": "1398.2"
  },
  {
      "Date": "12/15/99",
      "Open": "1396.6",
      "High": "1410.8",
      "Low": "1376.75",
      "Close": "1401.4"
  },
  {
      "Date": "12/16/99",
      "Open": "1404.05",
      "High": "1428.5",
      "Low": "1402.75",
      "Close": "1419.7"
  },
  {
      "Date": "12/17/99",
      "Open": "1420.1",
      "High": "1439.25",
      "Low": "1414.15",
      "Close": "1419.3"
  },
  {
      "Date": "12/20/99",
      "Open": "1419.45",
      "High": "1435.55",
      "Low": "1418.75",
      "Close": "1422.4"
  },
  {
      "Date": "12/21/99",
      "Open": "1422.6",
      "High": "1437.6",
      "Low": "1413.85",
      "Close": "1421.7"
  },
  {
      "Date": "12/22/99",
      "Open": "1429.15",
      "High": "1486.9",
      "Low": "1429.15",
      "Close": "1481.7"
  },
  {
      "Date": "12/23/99",
      "Open": "1481.6",
      "High": "1510.85",
      "Low": "1481.6",
      "Close": "1488.3"
  },
  {
      "Date": "12/24/99",
      "Open": "1488.05",
      "High": "1489.9",
      "Low": "1453.35",
      "Close": "1459"
  },
  {
      "Date": "12/27/99",
      "Open": "1459.65",
      "High": "1460.5",
      "Low": "1427.7",
      "Close": "1432.1"
  },
  {
      "Date": "12/28/99",
      "Open": "1427.45",
      "High": "1451.35",
      "Low": "1418.85",
      "Close": "1442.2"
  },
  {
      "Date": "12/29/99",
      "Open": "1443.9",
      "High": "1483.4",
      "Low": "1443.9",
      "Close": "1476.3"
  },
  {
      "Date": "12/30/99",
      "Open": "1489.2",
      "High": "1504.95",
      "Low": "1476.75",
      "Close": "1480.4"
  },
  {
      "Date": "1/3/00",
      "Open": "1482.15",
      "High": "1592.9",
      "Low": "1482.15",
      "Close": "1592.2"
  },
  {
      "Date": "1/4/00",
      "Open": "1594.4",
      "High": "1641.95",
      "Low": "1594.4",
      "Close": "1638.7"
  },
  {
      "Date": "1/5/00",
      "Open": "1634.55",
      "High": "1635.5",
      "Low": "1555.05",
      "Close": "1595.8"
  },
  {
      "Date": "1/6/00",
      "Open": "1595.8",
      "High": "1639",
      "Low": "1595.8",
      "Close": "1617.6"
  },
  {
      "Date": "1/7/00",
      "Open": "1616.6",
      "High": "1628.25",
      "Low": "1597.2",
      "Close": "1613.3"
  },
  {
      "Date": "1/10/00",
      "Open": "1615.65",
      "High": "1662.1",
      "Low": "1614.95",
      "Close": "1632.9"
  },
  {
      "Date": "1/11/00",
      "Open": "1633.25",
      "High": "1639.9",
      "Low": "1548.25",
      "Close": "1572.5"
  },
  {
      "Date": "1/12/00",
      "Open": "1572.3",
      "High": "1631.55",
      "Low": "1571.7",
      "Close": "1624.8"
  },
  {
      "Date": "1/13/00",
      "Open": "1627.85",
      "High": "1671.15",
      "Low": "1613.65",
      "Close": "1621.4"
  },
  {
      "Date": "1/14/00",
      "Open": "1622.15",
      "High": "1627.4",
      "Low": "1591.4",
      "Close": "1622.7"
  },
  {
      "Date": "1/17/00",
      "Open": "1623.5",
      "High": "1668.45",
      "Low": "1604.65",
      "Close": "1611.6"
  },
  {
      "Date": "1/18/00",
      "Open": "1611.65",
      "High": "1615.15",
      "Low": "1587.85",
      "Close": "1606.7"
  },
  {
      "Date": "1/19/00",
      "Open": "1610.05",
      "High": "1644.45",
      "Low": "1608.85",
      "Close": "1634.8"
  },
  {
      "Date": "1/20/00",
      "Open": "1634.65",
      "High": "1644.4",
      "Low": "1596.65",
      "Close": "1601.1"
  },
  {
      "Date": "1/21/00",
      "Open": "1601.25",
      "High": "1626.5",
      "Low": "1593.2",
      "Close": "1620.6"
  },
  {
      "Date": "1/24/00",
      "Open": "1623.05",
      "High": "1645",
      "Low": "1608.3",
      "Close": "1613.6"
  },
  {
      "Date": "1/25/00",
      "Open": "1612.95",
      "High": "1613.65",
      "Low": "1579.55",
      "Close": "1586.4"
  },
  {
      "Date": "1/27/00",
      "Open": "1600.5",
      "High": "1633.55",
      "Low": "1600.05",
      "Close": "1603.9"
  },
  {
      "Date": "1/28/00",
      "Open": "1603.65",
      "High": "1610.9",
      "Low": "1592.7",
      "Close": "1599.1"
  },
  {
      "Date": "1/31/00",
      "Open": "1598.35",
      "High": "1598.35",
      "Low": "1538.7",
      "Close": "1546.2"
  },
  {
      "Date": "2/1/00",
      "Open": "1546.2",
      "High": "1554.15",
      "Low": "1521.4",
      "Close": "1549.5"
  },
  {
      "Date": "2/2/00",
      "Open": "1554.2",
      "High": "1605.9",
      "Low": "1554.2",
      "Close": "1588"
  },
  {
      "Date": "2/3/00",
      "Open": "1591.25",
      "High": "1616.7",
      "Low": "1591.25",
      "Close": "1597.9"
  },
  {
      "Date": "2/4/00",
      "Open": "1598.5",
      "High": "1621.35",
      "Low": "1596.45",
      "Close": "1599.7"
  },
  {
      "Date": "2/7/00",
      "Open": "1599.8",
      "High": "1645.9",
      "Low": "1599.8",
      "Close": "1636.6"
  },
  {
      "Date": "2/8/00",
      "Open": "1636.6",
      "High": "1676.1",
      "Low": "1636.6",
      "Close": "1662.7"
  },
  {
      "Date": "2/9/00",
      "Open": "1666.95",
      "High": "1731.65",
      "Low": "1666.7",
      "Close": "1689.6"
  },
  {
      "Date": "2/10/00",
      "Open": "1692.1",
      "High": "1713.7",
      "Low": "1692.1",
      "Close": "1711.2"
  },
  {
      "Date": "2/11/00",
      "Open": "1712.85",
      "High": "1771.65",
      "Low": "1712.85",
      "Close": "1756"
  },
  {
      "Date": "2/14/00",
      "Open": "1777.75",
      "High": "1795.45",
      "Low": "1738.75",
      "Close": "1744.5"
  },
  {
      "Date": "2/15/00",
      "Open": "1744.5",
      "High": "1744.5",
      "Low": "1694.45",
      "Close": "1702.5"
  },
  {
      "Date": "2/16/00",
      "Open": "1704.85",
      "High": "1742.8",
      "Low": "1704.15",
      "Close": "1711.1"
  },
  {
      "Date": "2/17/00",
      "Open": "1710.05",
      "High": "1753.1",
      "Low": "1710.05",
      "Close": "1742.1"
  },
  {
      "Date": "2/18/00",
      "Open": "1742.3",
      "High": "1764.15",
      "Low": "1713.45",
      "Close": "1717.8"
  },
  {
      "Date": "2/21/00",
      "Open": "1719.55",
      "High": "1767.8",
      "Low": "1719.55",
      "Close": "1753.5"
  },
  {
      "Date": "2/22/00",
      "Open": "1754.2",
      "High": "1781.05",
      "Low": "1733.55",
      "Close": "1739"
  },
  {
      "Date": "2/23/00",
      "Open": "1745.2",
      "High": "1818.15",
      "Low": "1689.2",
      "Close": "1696.4"
  },
  {
      "Date": "2/24/00",
      "Open": "1696.4",
      "High": "1751.6",
      "Low": "1693.1",
      "Close": "1732"
  },
  {
      "Date": "2/25/00",
      "Open": "1734.05",
      "High": "1757.05",
      "Low": "1699.25",
      "Close": "1710.4"
  },
  {
      "Date": "2/28/00",
      "Open": "1711.15",
      "High": "1726.65",
      "Low": "1670.75",
      "Close": "1722.5"
  },
  {
      "Date": "2/29/00",
      "Open": "1722.85",
      "High": "1783.3",
      "Low": "1626.35",
      "Close": "1654.8"
  },
  {
      "Date": "3/1/00",
      "Open": "1661.5",
      "High": "1727.9",
      "Low": "1630",
      "Close": "1712.7"
  },
  {
      "Date": "3/2/00",
      "Open": "1713.65",
      "High": "1773.85",
      "Low": "1690.05",
      "Close": "1696.5"
  },
  {
      "Date": "3/3/00",
      "Open": "1696.45",
      "High": "1719.15",
      "Low": "1644.4",
      "Close": "1656"
  },
  {
      "Date": "3/6/00",
      "Open": "1656",
      "High": "1721",
      "Low": "1656",
      "Close": "1688.5"
  },
  {
      "Date": "3/7/00",
      "Open": "1711.85",
      "High": "1730.85",
      "Low": "1684.9",
      "Close": "1702.7"
  },
  {
      "Date": "3/8/00",
      "Open": "1717.75",
      "High": "1765.25",
      "Low": "1654.45",
      "Close": "1666.3"
  },
  {
      "Date": "3/9/00",
      "Open": "1667.45",
      "High": "1692.25",
      "Low": "1595.45",
      "Close": "1646.2"
  },
  {
      "Date": "3/10/00",
      "Open": "1647.85",
      "High": "1680.45",
      "Low": "1588.2",
      "Close": "1602.7"
  },
  {
      "Date": "3/13/00",
      "Open": "1601.4",
      "High": "1645",
      "Low": "1553.45",
      "Close": "1560.7"
  },
  {
      "Date": "3/14/00",
      "Open": "1561.55",
      "High": "1571.3",
      "Low": "1503.2",
      "Close": "1567"
  },
  {
      "Date": "3/15/00",
      "Open": "1546.8",
      "High": "1630.95",
      "Low": "1538.7",
      "Close": "1620.1"
  },
  {
      "Date": "3/16/00",
      "Open": "1620.4",
      "High": "1621.2",
      "Low": "1554.3",
      "Close": "1562.2"
  },
  {
      "Date": "3/21/00",
      "Open": "1563.3",
      "High": "1585.25",
      "Low": "1542.65",
      "Close": "1556.6"
  },
  {
      "Date": "3/22/00",
      "Open": "1551.05",
      "High": "1604",
      "Low": "1551.05",
      "Close": "1589.6"
  },
  {
      "Date": "3/23/00",
      "Open": "1590.3",
      "High": "1629.2",
      "Low": "1544.5",
      "Close": "1553.4"
  },
  {
      "Date": "3/24/00",
      "Open": "1554.2",
      "High": "1579.4",
      "Low": "1540.45",
      "Close": "1569.5"
  },
  {
      "Date": "3/27/00",
      "Open": "1570.45",
      "High": "1593.3",
      "Low": "1555.75",
      "Close": "1562.9"
  },
  {
      "Date": "3/28/00",
      "Open": "1563.3",
      "High": "1575.85",
      "Low": "1527.55",
      "Close": "1568.6"
  },
  {
      "Date": "3/29/00",
      "Open": "1569.3",
      "High": "1609.4",
      "Low": "1553.4",
      "Close": "1558.2"
  },
  {
      "Date": "3/30/00",
      "Open": "1556.95",
      "High": "1557.85",
      "Low": "1489.1",
      "Close": "1549.5"
  },
  {
      "Date": "3/31/00",
      "Open": "1545.55",
      "High": "1545.55",
      "Low": "1493.9",
      "Close": "1528.4"
  },
  {
      "Date": "4/3/00",
      "Open": "1528.7",
      "High": "1555.5",
      "Low": "1510.75",
      "Close": "1534.7"
  },
  {
      "Date": "4/4/00",
      "Open": "1534.05",
      "High": "1534.05",
      "Low": "1420.85",
      "Close": "1428.1"
  },
  {
      "Date": "4/5/00",
      "Open": "1428.5",
      "High": "1467.4",
      "Low": "1391.65",
      "Close": "1434.6"
  },
  {
      "Date": "4/6/00",
      "Open": "1436.15",
      "High": "1475.25",
      "Low": "1381.05",
      "Close": "1452.9"
  },
  {
      "Date": "4/7/00",
      "Open": "1453.3",
      "High": "1558.05",
      "Low": "1453.3",
      "Close": "1557.1"
  },
  {
      "Date": "4/10/00",
      "Open": "1557.55",
      "High": "1636.95",
      "Low": "1557.55",
      "Close": "1613"
  },
  {
      "Date": "4/11/00",
      "Open": "1613",
      "High": "1631.6",
      "Low": "1550.85",
      "Close": "1624.6"
  },
  {
      "Date": "4/12/00",
      "Open": "1624.4",
      "High": "1628.55",
      "Low": "1586",
      "Close": "1592.7"
  },
  {
      "Date": "4/13/00",
      "Open": "1587.95",
      "High": "1587.95",
      "Low": "1502.7",
      "Close": "1518.6"
  },
  {
      "Date": "4/17/00",
      "Open": "1518.55",
      "High": "1518.55",
      "Low": "1412.45",
      "Close": "1443.5"
  },
  {
      "Date": "4/18/00",
      "Open": "1443.55",
      "High": "1478.85",
      "Low": "1410",
      "Close": "1414.8"
  },
  {
      "Date": "4/19/00",
      "Open": "1417.75",
      "High": "1469.05",
      "Low": "1375.05",
      "Close": "1404.9"
  },
  {
      "Date": "4/20/00",
      "Open": "1405.7",
      "High": "1425.55",
      "Low": "1339.75",
      "Close": "1415.6"
  },
  {
      "Date": "4/24/00",
      "Open": "1416.05",
      "High": "1431.8",
      "Low": "1371.85",
      "Close": "1388"
  },
  {
      "Date": "4/25/00",
      "Open": "1383.8",
      "High": "1383.8",
      "Low": "1311.3",
      "Close": "1359.4"
  },
  {
      "Date": "4/26/00",
      "Open": "1362.5",
      "High": "1439.05",
      "Low": "1362.5",
      "Close": "1436.1"
  },
  {
      "Date": "4/27/00",
      "Open": "1436.15",
      "High": "1455.85",
      "Low": "1400.65",
      "Close": "1416.9"
  },
  {
      "Date": "4/28/00",
      "Open": "1419.9",
      "High": "1438",
      "Low": "1396.55",
      "Close": "1406.5"
  },
  {
      "Date": "5/2/00",
      "Open": "1410",
      "High": "1421.5",
      "Low": "1319.65",
      "Close": "1333.4"
  },
  {
      "Date": "5/3/00",
      "Open": "1330.15",
      "High": "1342.15",
      "Low": "1258.1",
      "Close": "1316"
  },
  {
      "Date": "5/4/00",
      "Open": "1317.95",
      "High": "1385.15",
      "Low": "1317.95",
      "Close": "1380.5"
  },
  {
      "Date": "5/5/00",
      "Open": "1381.9",
      "High": "1428.6",
      "Low": "1381.9",
      "Close": "1422.4"
  },
  {
      "Date": "5/8/00",
      "Open": "1423.25",
      "High": "1436.6",
      "Low": "1358.8",
      "Close": "1365"
  },
  {
      "Date": "5/9/00",
      "Open": "1362.45",
      "High": "1385.7",
      "Low": "1328.05",
      "Close": "1378.5"
  },
  {
      "Date": "5/10/00",
      "Open": "1380.4",
      "High": "1391.35",
      "Low": "1359.65",
      "Close": "1363.1"
  },
  {
      "Date": "5/11/00",
      "Open": "1359.1",
      "High": "1359.1",
      "Low": "1295.95",
      "Close": "1304.5"
  },
  {
      "Date": "5/12/00",
      "Open": "1305.3",
      "High": "1326.3",
      "Low": "1276.4",
      "Close": "1282.8"
  },
  {
      "Date": "5/15/00",
      "Open": "1281.3",
      "High": "1311.7",
      "Low": "1220.7",
      "Close": "1299.2"
  },
  {
      "Date": "5/16/00",
      "Open": "1300.2",
      "High": "1310.45",
      "Low": "1287.8",
      "Close": "1306.8"
  },
  {
      "Date": "5/17/00",
      "Open": "1310.8",
      "High": "1347.7",
      "Low": "1303.75",
      "Close": "1311.6"
  },
  {
      "Date": "5/18/00",
      "Open": "1311.3",
      "High": "1316.2",
      "Low": "1289.55",
      "Close": "1293.4"
  },
  {
      "Date": "5/19/00",
      "Open": "1293.45",
      "High": "1293.45",
      "Low": "1252.7",
      "Close": "1268"
  },
  {
      "Date": "5/22/00",
      "Open": "1267.85",
      "High": "1267.85",
      "Low": "1228.4",
      "Close": "1233"
  },
  {
      "Date": "5/23/00",
      "Open": "1229.25",
      "High": "1230.9",
      "Low": "1203.2",
      "Close": "1224.4"
  },
  {
      "Date": "5/24/00",
      "Open": "1224.5",
      "High": "1246.25",
      "Low": "1201.5",
      "Close": "1235.9"
  },
  {
      "Date": "5/25/00",
      "Open": "1237",
      "High": "1269.8",
      "Low": "1235.7",
      "Close": "1247.6"
  },
  {
      "Date": "5/26/00",
      "Open": "1247.1",
      "High": "1278.75",
      "Low": "1232.85",
      "Close": "1275.3"
  },
  {
      "Date": "5/29/00",
      "Open": "1273.7",
      "High": "1317.75",
      "Low": "1267.4",
      "Close": "1311"
  },
  {
      "Date": "5/30/00",
      "Open": "1310.8",
      "High": "1347.3",
      "Low": "1304.95",
      "Close": "1344.8"
  },
  {
      "Date": "5/31/00",
      "Open": "1355.35",
      "High": "1410.75",
      "Low": "1355.35",
      "Close": "1380.4"
  },
  {
      "Date": "6/1/00",
      "Open": "1380.4",
      "High": "1380.4",
      "Low": "1345.1",
      "Close": "1349"
  },
  {
      "Date": "6/2/00",
      "Open": "1350.15",
      "High": "1395.35",
      "Low": "1350.15",
      "Close": "1389.2"
  },
  {
      "Date": "6/5/00",
      "Open": "1390.95",
      "High": "1461.05",
      "Low": "1390.95",
      "Close": "1404.6"
  },
  {
      "Date": "6/6/00",
      "Open": "1405.45",
      "High": "1426.15",
      "Low": "1392.7",
      "Close": "1421.7"
  },
  {
      "Date": "6/7/00",
      "Open": "1423.4",
      "High": "1442.7",
      "Low": "1413.8",
      "Close": "1430.3"
  },
  {
      "Date": "6/8/00",
      "Open": "1430.5",
      "High": "1468.7",
      "Low": "1430.5",
      "Close": "1463.6"
  },
  {
      "Date": "6/9/00",
      "Open": "1464.3",
      "High": "1485.5",
      "Low": "1458.9",
      "Close": "1467.2"
  },
  {
      "Date": "6/12/00",
      "Open": "1464.4",
      "High": "1477.55",
      "Low": "1437.15",
      "Close": "1440.4"
  },
  {
      "Date": "6/13/00",
      "Open": "1438.8",
      "High": "1438.8",
      "Low": "1411.9",
      "Close": "1432.9"
  },
  {
      "Date": "6/14/00",
      "Open": "1434.95",
      "High": "1459.15",
      "Low": "1423.95",
      "Close": "1435.5"
  },
  {
      "Date": "6/15/00",
      "Open": "1437",
      "High": "1452.8",
      "Low": "1422.4",
      "Close": "1445.2"
  },
  {
      "Date": "6/16/00",
      "Open": "1443.15",
      "High": "1480.85",
      "Low": "1441.6",
      "Close": "1477.3"
  },
  {
      "Date": "6/19/00",
      "Open": "1477.55",
      "High": "1510.45",
      "Low": "1477.55",
      "Close": "1498.7"
  },
  {
      "Date": "6/20/00",
      "Open": "1498",
      "High": "1518.05",
      "Low": "1497.05",
      "Close": "1507.1"
  },
  {
      "Date": "6/21/00",
      "Open": "1524.05",
      "High": "1539.1",
      "Low": "1470.4",
      "Close": "1475.1"
  },
  {
      "Date": "6/22/00",
      "Open": "1475.65",
      "High": "1491.4",
      "Low": "1461.3",
      "Close": "1488.2"
  },
  {
      "Date": "6/23/00",
      "Open": "1487.85",
      "High": "1499.85",
      "Low": "1462.75",
      "Close": "1472.2"
  },
  {
      "Date": "6/26/00",
      "Open": "1472.15",
      "High": "1472.15",
      "Low": "1431.25",
      "Close": "1451.6"
  },
  {
      "Date": "6/27/00",
      "Open": "1452.8",
      "High": "1467.15",
      "Low": "1448.95",
      "Close": "1454.3"
  },
  {
      "Date": "6/28/00",
      "Open": "1453.7",
      "High": "1474.7",
      "Low": "1445.6",
      "Close": "1470"
  },
  {
      "Date": "6/29/00",
      "Open": "1471.35",
      "High": "1505.75",
      "Low": "1471.35",
      "Close": "1492.3"
  },
  {
      "Date": "6/30/00",
      "Open": "1490.2",
      "High": "1490.65",
      "Low": "1462.45",
      "Close": "1471.4"
  },
  {
      "Date": "7/3/00",
      "Open": "1473.45",
      "High": "1498.7",
      "Low": "1473.45",
      "Close": "1495.2"
  },
  {
      "Date": "7/4/00",
      "Open": "1496.15",
      "High": "1516.8",
      "Low": "1494.8",
      "Close": "1511.3"
  },
  {
      "Date": "7/5/00",
      "Open": "1511.5",
      "High": "1530.9",
      "Low": "1511.5",
      "Close": "1526"
  },
  {
      "Date": "7/6/00",
      "Open": "1525.15",
      "High": "1525.15",
      "Low": "1504.05",
      "Close": "1516.8"
  },
  {
      "Date": "7/7/00",
      "Open": "1517",
      "High": "1528.15",
      "Low": "1513.4",
      "Close": "1517.6"
  },
  {
      "Date": "7/10/00",
      "Open": "1518.15",
      "High": "1523.2",
      "Low": "1502.7",
      "Close": "1509.6"
  },
  {
      "Date": "7/11/00",
      "Open": "1510.55",
      "High": "1523.1",
      "Low": "1506.15",
      "Close": "1518.5"
  },
  {
      "Date": "7/12/00",
      "Open": "1518.7",
      "High": "1537.1",
      "Low": "1507.95",
      "Close": "1533.3"
  },
  {
      "Date": "7/13/00",
      "Open": "1535.55",
      "High": "1564.75",
      "Low": "1513.65",
      "Close": "1522.6"
  },
  {
      "Date": "7/14/00",
      "Open": "1522.2",
      "High": "1534.35",
      "Low": "1505.9",
      "Close": "1509.7"
  },
  {
      "Date": "7/17/00",
      "Open": "1509.25",
      "High": "1512.25",
      "Low": "1475.15",
      "Close": "1479.6"
  },
  {
      "Date": "7/18/00",
      "Open": "1479.3",
      "High": "1482.9",
      "Low": "1458.6",
      "Close": "1463.1"
  },
  {
      "Date": "7/19/00",
      "Open": "1464.55",
      "High": "1468.55",
      "Low": "1432.85",
      "Close": "1435.8"
  },
  {
      "Date": "7/20/00",
      "Open": "1434.95",
      "High": "1443.25",
      "Low": "1416",
      "Close": "1424.2"
  },
  {
      "Date": "7/21/00",
      "Open": "1424.9",
      "High": "1435.2",
      "Low": "1394.3",
      "Close": "1397.2"
  },
  {
      "Date": "7/24/00",
      "Open": "1397.05",
      "High": "1397.05",
      "Low": "1310.85",
      "Close": "1317.7"
  },
  {
      "Date": "7/25/00",
      "Open": "1317.35",
      "High": "1363.45",
      "Low": "1269.25",
      "Close": "1348.9"
  },
  {
      "Date": "7/26/00",
      "Open": "1350.8",
      "High": "1371.8",
      "Low": "1315.15",
      "Close": "1318.2"
  },
  {
      "Date": "7/27/00",
      "Open": "1317",
      "High": "1342.75",
      "Low": "1291.3",
      "Close": "1338"
  },
  {
      "Date": "7/28/00",
      "Open": "1337.7",
      "High": "1359.4",
      "Low": "1327.95",
      "Close": "1333.8"
  },
  {
      "Date": "7/31/00",
      "Open": "1333.3",
      "High": "1343.65",
      "Low": "1317.3",
      "Close": "1332.8"
  },
  {
      "Date": "8/1/00",
      "Open": "1332.85",
      "High": "1344.5",
      "Low": "1322.6",
      "Close": "1326.8"
  },
  {
      "Date": "8/2/00",
      "Open": "1323.7",
      "High": "1333.85",
      "Low": "1312.25",
      "Close": "1331.9"
  },
  {
      "Date": "8/3/00",
      "Open": "1330.7",
      "High": "1339.4",
      "Low": "1319",
      "Close": "1321.2"
  },
  {
      "Date": "8/4/00",
      "Open": "1320.25",
      "High": "1333.15",
      "Low": "1302.65",
      "Close": "1318.5"
  },
  {
      "Date": "8/7/00",
      "Open": "1319.7",
      "High": "1321.7",
      "Low": "1299.9",
      "Close": "1310.8"
  },
  {
      "Date": "8/8/00",
      "Open": "1312.1",
      "High": "1348.25",
      "Low": "1311.15",
      "Close": "1345.6"
  },
  {
      "Date": "8/9/00",
      "Open": "1345.95",
      "High": "1363.95",
      "Low": "1341.05",
      "Close": "1344.9"
  },
  {
      "Date": "8/10/00",
      "Open": "1345.25",
      "High": "1360.4",
      "Low": "1325.15",
      "Close": "1328"
  },
  {
      "Date": "8/11/00",
      "Open": "1328.15",
      "High": "1328.15",
      "Low": "1306.6",
      "Close": "1310.7"
  },
  {
      "Date": "8/14/00",
      "Open": "1312.25",
      "High": "1321.55",
      "Low": "1295.45",
      "Close": "1317.9"
  },
  {
      "Date": "8/16/00",
      "Open": "1319.35",
      "High": "1354.95",
      "Low": "1319.35",
      "Close": "1351.4"
  },
  {
      "Date": "8/17/00",
      "Open": "1351.5",
      "High": "1363.05",
      "Low": "1334.95",
      "Close": "1341.4"
  },
  {
      "Date": "8/18/00",
      "Open": "1342",
      "High": "1360.65",
      "Low": "1339.05",
      "Close": "1358"
  },
  {
      "Date": "8/21/00",
      "Open": "1358.75",
      "High": "1374.5",
      "Low": "1358.75",
      "Close": "1370.2"
  },
  {
      "Date": "8/22/00",
      "Open": "1370.75",
      "High": "1394.9",
      "Low": "1370.75",
      "Close": "1381.2"
  },
  {
      "Date": "8/23/00",
      "Open": "1381.6",
      "High": "1398.9",
      "Low": "1376.9",
      "Close": "1386"
  },
  {
      "Date": "8/24/00",
      "Open": "1386.3",
      "High": "1390.45",
      "Low": "1374.9",
      "Close": "1386.9"
  },
  {
      "Date": "8/25/00",
      "Open": "1387.35",
      "High": "1396.1",
      "Low": "1377.75",
      "Close": "1381.2"
  },
  {
      "Date": "8/28/00",
      "Open": "1379.6",
      "High": "1383.45",
      "Low": "1368.25",
      "Close": "1369.8"
  },
  {
      "Date": "8/29/00",
      "Open": "1368.65",
      "High": "1373.55",
      "Low": "1360.65",
      "Close": "1367.7"
  },
  {
      "Date": "8/30/00",
      "Open": "1369.9",
      "High": "1384.85",
      "Low": "1369.8",
      "Close": "1375.9"
  },
  {
      "Date": "8/31/00",
      "Open": "1376.5",
      "High": "1397.3",
      "Low": "1375.9",
      "Close": "1394.1"
  },
  {
      "Date": "9/4/00",
      "Open": "1395.95",
      "High": "1433.15",
      "Low": "1395.95",
      "Close": "1427.7"
  },
  {
      "Date": "9/5/00",
      "Open": "1428.05",
      "High": "1434.65",
      "Low": "1420.9",
      "Close": "1428.2"
  },
  {
      "Date": "9/6/00",
      "Open": "1430.9",
      "High": "1444.45",
      "Low": "1421",
      "Close": "1435.3"
  },
  {
      "Date": "9/7/00",
      "Open": "1434.7",
      "High": "1450.2",
      "Low": "1424.35",
      "Close": "1439.7"
  },
  {
      "Date": "9/8/00",
      "Open": "1440.6",
      "High": "1453.15",
      "Low": "1440.6",
      "Close": "1450"
  },
  {
      "Date": "9/11/00",
      "Open": "1449.35",
      "High": "1469",
      "Low": "1448.95",
      "Close": "1456.3"
  },
  {
      "Date": "9/12/00",
      "Open": "1457.1",
      "High": "1470.55",
      "Low": "1447.8",
      "Close": "1467.6"
  },
  {
      "Date": "9/13/00",
      "Open": "1467.65",
      "High": "1482",
      "Low": "1452.6",
      "Close": "1456.1"
  },
  {
      "Date": "9/14/00",
      "Open": "1456.75",
      "High": "1466.65",
      "Low": "1437.85",
      "Close": "1445.3"
  },
  {
      "Date": "9/15/00",
      "Open": "1443.25",
      "High": "1451.05",
      "Low": "1411.55",
      "Close": "1417.2"
  },
  {
      "Date": "9/18/00",
      "Open": "1416.05",
      "High": "1416.05",
      "Low": "1352.1",
      "Close": "1354.3"
  },
  {
      "Date": "9/19/00",
      "Open": "1351.8",
      "High": "1361.7",
      "Low": "1295.3",
      "Close": "1317"
  },
  {
      "Date": "9/20/00",
      "Open": "1317.8",
      "High": "1351.1",
      "Low": "1311.1",
      "Close": "1342.9"
  },
  {
      "Date": "9/21/00",
      "Open": "1341.65",
      "High": "1347.4",
      "Low": "1325.75",
      "Close": "1329.8"
  },
  {
      "Date": "9/22/00",
      "Open": "1329.1",
      "High": "1329.1",
      "Low": "1261.2",
      "Close": "1266.4"
  },
  {
      "Date": "9/25/00",
      "Open": "1268.4",
      "High": "1305.1",
      "Low": "1268.4",
      "Close": "1292.5"
  },
  {
      "Date": "9/26/00",
      "Open": "1292.9",
      "High": "1298.25",
      "Low": "1261",
      "Close": "1267.9"
  },
  {
      "Date": "9/27/00",
      "Open": "1267.45",
      "High": "1299",
      "Low": "1249.35",
      "Close": "1292.5"
  },
  {
      "Date": "9/28/00",
      "Open": "1292.4",
      "High": "1302.75",
      "Low": "1260.8",
      "Close": "1266.4"
  },
  {
      "Date": "9/29/00",
      "Open": "1267.3",
      "High": "1284.15",
      "Low": "1264.4",
      "Close": "1271.6"
  },
  {
      "Date": "10/3/00",
      "Open": "1270.75",
      "High": "1288.15",
      "Low": "1254.35",
      "Close": "1282"
  },
  {
      "Date": "10/4/00",
      "Open": "1281.45",
      "High": "1300.05",
      "Low": "1275.8",
      "Close": "1297.8"
  },
  {
      "Date": "10/5/00",
      "Open": "1296.1",
      "High": "1306.45",
      "Low": "1282.5",
      "Close": "1284.7"
  },
  {
      "Date": "10/6/00",
      "Open": "1284.35",
      "High": "1287.85",
      "Low": "1262.65",
      "Close": "1285"
  },
  {
      "Date": "10/9/00",
      "Open": "1285.05",
      "High": "1286.35",
      "Low": "1264.2",
      "Close": "1267.3"
  },
  {
      "Date": "10/10/00",
      "Open": "1268.7",
      "High": "1272.75",
      "Low": "1230.05",
      "Close": "1238.9"
  },
  {
      "Date": "10/11/00",
      "Open": "1232.85",
      "High": "1234.1",
      "Low": "1193.8",
      "Close": "1201.9"
  },
  {
      "Date": "10/12/00",
      "Open": "1201.3",
      "High": "1214.35",
      "Low": "1185.1",
      "Close": "1206.2"
  },
  {
      "Date": "10/13/00",
      "Open": "1205.7",
      "High": "1205.7",
      "Low": "1167.5",
      "Close": "1176.7"
  },
  {
      "Date": "10/16/00",
      "Open": "1177.85",
      "High": "1228.8",
      "Low": "1173.35",
      "Close": "1175.4"
  },
  {
      "Date": "10/17/00",
      "Open": "1174.3",
      "High": "1174.3",
      "Low": "1140.6",
      "Close": "1158"
  },
  {
      "Date": "10/18/00",
      "Open": "1157.05",
      "High": "1157.05",
      "Low": "1122.8",
      "Close": "1136"
  },
  {
      "Date": "10/19/00",
      "Open": "1135.65",
      "High": "1171.5",
      "Low": "1108.2",
      "Close": "1166.1"
  },
  {
      "Date": "10/20/00",
      "Open": "1166.2",
      "High": "1202.7",
      "Low": "1166.2",
      "Close": "1172"
  },
  {
      "Date": "10/23/00",
      "Open": "1171.75",
      "High": "1172.15",
      "Low": "1141.45",
      "Close": "1143.9"
  },
  {
      "Date": "10/24/00",
      "Open": "1143",
      "High": "1155.35",
      "Low": "1132.9",
      "Close": "1152.4"
  },
  {
      "Date": "10/25/00",
      "Open": "1158.2",
      "High": "1190.4",
      "Low": "1157.25",
      "Close": "1183.9"
  },
  {
      "Date": "10/26/00",
      "Open": "1182.8",
      "High": "1188.2",
      "Low": "1176.6",
      "Close": "1186.3"
  },
  {
      "Date": "10/27/00",
      "Open": "1186.95",
      "High": "1192.9",
      "Low": "1171.85",
      "Close": "1178.7"
  },
  {
      "Date": "10/30/00",
      "Open": "1178.75",
      "High": "1185.8",
      "Low": "1164.25",
      "Close": "1167.1"
  },
  {
      "Date": "10/31/00",
      "Open": "1167.2",
      "High": "1175.6",
      "Low": "1155.15",
      "Close": "1172.7"
  },
  {
      "Date": "11/1/00",
      "Open": "1178.25",
      "High": "1203.85",
      "Low": "1177.1",
      "Close": "1200.8"
  },
  {
      "Date": "11/2/00",
      "Open": "1201.25",
      "High": "1226.35",
      "Low": "1199.75",
      "Close": "1224.8"
  },
  {
      "Date": "11/3/00",
      "Open": "1225",
      "High": "1249.2",
      "Low": "1225",
      "Close": "1242"
  },
  {
      "Date": "11/6/00",
      "Open": "1242.15",
      "High": "1261.4",
      "Low": "1236.65",
      "Close": "1240.2"
  },
  {
      "Date": "11/7/00",
      "Open": "1239",
      "High": "1249.1",
      "Low": "1232.35",
      "Close": "1246.7"
  },
  {
      "Date": "11/8/00",
      "Open": "1246.65",
      "High": "1277.25",
      "Low": "1246.65",
      "Close": "1266.8"
  },
  {
      "Date": "11/9/00",
      "Open": "1266.75",
      "High": "1275.55",
      "Low": "1255.95",
      "Close": "1269.9"
  },
  {
      "Date": "11/10/00",
      "Open": "1271.35",
      "High": "1271.4",
      "Low": "1237.5",
      "Close": "1239.5"
  },
  {
      "Date": "11/13/00",
      "Open": "1239.35",
      "High": "1239.35",
      "Low": "1199.95",
      "Close": "1208"
  },
  {
      "Date": "11/14/00",
      "Open": "1207.2",
      "High": "1246.9",
      "Low": "1207.05",
      "Close": "1242.8"
  },
  {
      "Date": "11/15/00",
      "Open": "1245",
      "High": "1270.1",
      "Low": "1243.2",
      "Close": "1247"
  },
  {
      "Date": "11/16/00",
      "Open": "1246.6",
      "High": "1254.55",
      "Low": "1228.6",
      "Close": "1233.2"
  },
  {
      "Date": "11/17/00",
      "Open": "1232.35",
      "High": "1238",
      "Low": "1216.9",
      "Close": "1236"
  },
  {
      "Date": "11/20/00",
      "Open": "1236",
      "High": "1241.75",
      "Low": "1231.05",
      "Close": "1237.6"
  },
  {
      "Date": "11/21/00",
      "Open": "1236.95",
      "High": "1237.85",
      "Low": "1224.3",
      "Close": "1235"
  },
  {
      "Date": "11/22/00",
      "Open": "1235.85",
      "High": "1247.5",
      "Low": "1220.05",
      "Close": "1222.3"
  },
  {
      "Date": "11/23/00",
      "Open": "1221.2",
      "High": "1223.05",
      "Low": "1209.85",
      "Close": "1216"
  },
  {
      "Date": "11/24/00",
      "Open": "1216.05",
      "High": "1230",
      "Low": "1216.05",
      "Close": "1225.2"
  },
  {
      "Date": "11/27/00",
      "Open": "1225.4",
      "High": "1254.45",
      "Low": "1225.35",
      "Close": "1252.9"
  },
  {
      "Date": "11/28/00",
      "Open": "1252.9",
      "High": "1275.45",
      "Low": "1248.9",
      "Close": "1272.7"
  },
  {
      "Date": "11/29/00",
      "Open": "1272.25",
      "High": "1281",
      "Low": "1253.45",
      "Close": "1264.7"
  },
  {
      "Date": "11/30/00",
      "Open": "1264.75",
      "High": "1272.1",
      "Low": "1258.4",
      "Close": "1268.1"
  },
  {
      "Date": "12/1/00",
      "Open": "1268.1",
      "High": "1280.5",
      "Low": "1255.2",
      "Close": "1276.2"
  },
  {
      "Date": "12/4/00",
      "Open": "1276.25",
      "High": "1290",
      "Low": "1273.65",
      "Close": "1275.6"
  },
  {
      "Date": "12/5/00",
      "Open": "1275.7",
      "High": "1288.2",
      "Low": "1275.5",
      "Close": "1284.6"
  },
  {
      "Date": "12/6/00",
      "Open": "1284.85",
      "High": "1319.85",
      "Low": "1284.85",
      "Close": "1298.5"
  },
  {
      "Date": "12/7/00",
      "Open": "1298.9",
      "High": "1308.45",
      "Low": "1295.1",
      "Close": "1302.5"
  },
  {
      "Date": "12/8/00",
      "Open": "1303.3",
      "High": "1317.2",
      "Low": "1303.3",
      "Close": "1313.7"
  },
  {
      "Date": "12/11/00",
      "Open": "1313.7",
      "High": "1333.5",
      "Low": "1313.7",
      "Close": "1332.1"
  },
  {
      "Date": "12/12/00",
      "Open": "1332.2",
      "High": "1341.75",
      "Low": "1327.6",
      "Close": "1333.3"
  },
  {
      "Date": "12/13/00",
      "Open": "1333.8",
      "High": "1360.8",
      "Low": "1333.8",
      "Close": "1354.3"
  },
  {
      "Date": "12/14/00",
      "Open": "1354.45",
      "High": "1369.5",
      "Low": "1346.15",
      "Close": "1349.3"
  },
  {
      "Date": "12/15/00",
      "Open": "1349.25",
      "High": "1349.25",
      "Low": "1308.15",
      "Close": "1312.6"
  },
  {
      "Date": "12/18/00",
      "Open": "1310.15",
      "High": "1321.65",
      "Low": "1297.2",
      "Close": "1317.6"
  },
  {
      "Date": "12/19/00",
      "Open": "1317.75",
      "High": "1325.6",
      "Low": "1307",
      "Close": "1310.5"
  },
  {
      "Date": "12/20/00",
      "Open": "1312.8",
      "High": "1313.4",
      "Low": "1285.45",
      "Close": "1295.2"
  },
  {
      "Date": "12/21/00",
      "Open": "1294.25",
      "High": "1294.25",
      "Low": "1257.1",
      "Close": "1277.4"
  },
  {
      "Date": "12/22/00",
      "Open": "1278.95",
      "High": "1292.8",
      "Low": "1237.3",
      "Close": "1242"
  },
  {
      "Date": "12/26/00",
      "Open": "1242.05",
      "High": "1248.5",
      "Low": "1207.95",
      "Close": "1212"
  },
  {
      "Date": "12/27/00",
      "Open": "1212.15",
      "High": "1239",
      "Low": "1208.75",
      "Close": "1228.3"
  },
  {
      "Date": "12/28/00",
      "Open": "1229.35",
      "High": "1253.6",
      "Low": "1229.35",
      "Close": "1248.9"
  },
  {
      "Date": "12/29/00",
      "Open": "1249",
      "High": "1265.9",
      "Low": "1242.25",
      "Close": "1263.5"
  },
  {
      "Date": "1/1/01",
      "Open": "1263.5",
      "High": "1276.15",
      "Low": "1250.65",
      "Close": "1254.3"
  },
  {
      "Date": "1/2/01",
      "Open": "1254.25",
      "High": "1279.6",
      "Low": "1248.55",
      "Close": "1271.8"
  },
  {
      "Date": "1/3/01",
      "Open": "1271.8",
      "High": "1293.55",
      "Low": "1263.95",
      "Close": "1291.2"
  },
  {
      "Date": "1/4/01",
      "Open": "1291.3",
      "High": "1331.35",
      "Low": "1291.3",
      "Close": "1307.6"
  },
  {
      "Date": "1/5/01",
      "Open": "1307.55",
      "High": "1330.3",
      "Low": "1306.25",
      "Close": "1327.2"
  },
  {
      "Date": "1/8/01",
      "Open": "1327.35",
      "High": "1334.2",
      "Low": "1303.35",
      "Close": "1309.2"
  },
  {
      "Date": "1/9/01",
      "Open": "1309.2",
      "High": "1323.4",
      "Low": "1304.9",
      "Close": "1311.6"
  },
  {
      "Date": "1/10/01",
      "Open": "1311.65",
      "High": "1324.35",
      "Low": "1285.3",
      "Close": "1287.3"
  },
  {
      "Date": "1/11/01",
      "Open": "1287.5",
      "High": "1296.75",
      "Low": "1275.95",
      "Close": "1280.4"
  },
  {
      "Date": "1/12/01",
      "Open": "1280.45",
      "High": "1298.85",
      "Low": "1279.4",
      "Close": "1286.7"
  },
  {
      "Date": "1/15/01",
      "Open": "1286.85",
      "High": "1295.2",
      "Low": "1277.85",
      "Close": "1286.7"
  },
  {
      "Date": "1/16/01",
      "Open": "1287",
      "High": "1302.6",
      "Low": "1279.85",
      "Close": "1293"
  },
  {
      "Date": "1/17/01",
      "Open": "1299.4",
      "High": "1312.35",
      "Low": "1295.3",
      "Close": "1297.9"
  },
  {
      "Date": "1/18/01",
      "Open": "1297.95",
      "High": "1309.95",
      "Low": "1297.95",
      "Close": "1305.9"
  },
  {
      "Date": "1/19/01",
      "Open": "1306",
      "High": "1333.5",
      "Low": "1306",
      "Close": "1329.1"
  },
  {
      "Date": "1/22/01",
      "Open": "1329.3",
      "High": "1351.85",
      "Low": "1329.3",
      "Close": "1348"
  },
  {
      "Date": "1/23/01",
      "Open": "1347.95",
      "High": "1362.05",
      "Low": "1346.5",
      "Close": "1355.2"
  },
  {
      "Date": "1/24/01",
      "Open": "1355.35",
      "High": "1371.1",
      "Low": "1355.35",
      "Close": "1365.9"
  },
  {
      "Date": "1/25/01",
      "Open": "1366.05",
      "High": "1374.2",
      "Low": "1353.5",
      "Close": "1370.1"
  },
  {
      "Date": "1/29/01",
      "Open": "1368.55",
      "High": "1368.55",
      "Low": "1312.1",
      "Close": "1342"
  },
  {
      "Date": "1/30/01",
      "Open": "1342.1",
      "High": "1382.55",
      "Low": "1342.05",
      "Close": "1379.7"
  },
  {
      "Date": "1/31/01",
      "Open": "1385.85",
      "High": "1396.05",
      "Low": "1369",
      "Close": "1371.7"
  },
  {
      "Date": "2/1/01",
      "Open": "1371.5",
      "High": "1373.5",
      "Low": "1340.95",
      "Close": "1359.1"
  },
  {
      "Date": "2/2/01",
      "Open": "1359.1",
      "High": "1384.9",
      "Low": "1355.3",
      "Close": "1378.8"
  },
  {
      "Date": "2/5/01",
      "Open": "1378.8",
      "High": "1392.15",
      "Low": "1373.45",
      "Close": "1382.6"
  },
  {
      "Date": "2/6/01",
      "Open": "1382.7",
      "High": "1395.4",
      "Low": "1382.7",
      "Close": "1387.1"
  },
  {
      "Date": "2/7/01",
      "Open": "1387.3",
      "High": "1391.85",
      "Low": "1368.05",
      "Close": "1370.8"
  },
  {
      "Date": "2/8/01",
      "Open": "1370.65",
      "High": "1396.8",
      "Low": "1365.05",
      "Close": "1395.5"
  },
  {
      "Date": "2/9/01",
      "Open": "1395.55",
      "High": "1416.3",
      "Low": "1395.55",
      "Close": "1405.7"
  },
  {
      "Date": "2/12/01",
      "Open": "1405.7",
      "High": "1416.45",
      "Low": "1397.6",
      "Close": "1402.2"
  },
  {
      "Date": "2/13/01",
      "Open": "1402.2",
      "High": "1412.7",
      "Low": "1386.65",
      "Close": "1391.2"
  },
  {
      "Date": "2/14/01",
      "Open": "1393.15",
      "High": "1396.35",
      "Low": "1380.65",
      "Close": "1393.3"
  },
  {
      "Date": "2/15/01",
      "Open": "1393.35",
      "High": "1421",
      "Low": "1393.35",
      "Close": "1416.7"
  },
  {
      "Date": "2/16/01",
      "Open": "1415.85",
      "High": "1422.95",
      "Low": "1376.15",
      "Close": "1381.3"
  },
  {
      "Date": "2/19/01",
      "Open": "1381.4",
      "High": "1392.05",
      "Low": "1369.45",
      "Close": "1384.8"
  },
  {
      "Date": "2/20/01",
      "Open": "1384.85",
      "High": "1392.8",
      "Low": "1380.4",
      "Close": "1383.8"
  },
  {
      "Date": "2/21/01",
      "Open": "1383.9",
      "High": "1389.55",
      "Low": "1364.25",
      "Close": "1370.1"
  },
  {
      "Date": "2/22/01",
      "Open": "1370.05",
      "High": "1370.05",
      "Low": "1335.65",
      "Close": "1355.1"
  },
  {
      "Date": "2/23/01",
      "Open": "1355.1",
      "High": "1362.8",
      "Low": "1314.1",
      "Close": "1320.4"
  },
  {
      "Date": "2/26/01",
      "Open": "1320.9",
      "High": "1325.2",
      "Low": "1299.05",
      "Close": "1312.4"
  },
  {
      "Date": "2/27/01",
      "Open": "1312.45",
      "High": "1324",
      "Low": "1281.9",
      "Close": "1295.5"
  },
  {
      "Date": "2/28/01",
      "Open": "1295.3",
      "High": "1362.25",
      "Low": "1294.6",
      "Close": "1351.4"
  },
  {
      "Date": "3/1/01",
      "Open": "1351.75",
      "High": "1399.55",
      "Low": "1345.05",
      "Close": "1358"
  },
  {
      "Date": "3/2/01",
      "Open": "1360.25",
      "High": "1386.75",
      "Low": "1301.8",
      "Close": "1306.3"
  },
  {
      "Date": "3/5/01",
      "Open": "1304.85",
      "High": "1316.45",
      "Low": "1259.1",
      "Close": "1271.4"
  },
  {
      "Date": "3/7/01",
      "Open": "1272.8",
      "High": "1311.1",
      "Low": "1249.45",
      "Close": "1290.5"
  },
  {
      "Date": "3/8/01",
      "Open": "1290.85",
      "High": "1307.95",
      "Low": "1279.3",
      "Close": "1292.8"
  },
  {
      "Date": "3/9/01",
      "Open": "1292.7",
      "High": "1292.7",
      "Low": "1219.35",
      "Close": "1254.7"
  },
  {
      "Date": "3/12/01",
      "Open": "1253.25",
      "High": "1253.25",
      "Low": "1193.95",
      "Close": "1197.9"
  },
  {
      "Date": "3/13/01",
      "Open": "1197.85",
      "High": "1201.15",
      "Low": "1098.75",
      "Close": "1124.7"
  },
  {
      "Date": "3/14/01",
      "Open": "1125.15",
      "High": "1200.25",
      "Low": "1114.6",
      "Close": "1194.2"
  },
  {
      "Date": "3/15/01",
      "Open": "1191.6",
      "High": "1219.7",
      "Low": "1170.7",
      "Close": "1217.1"
  },
  {
      "Date": "3/16/01",
      "Open": "1216.9",
      "High": "1233.4",
      "Low": "1179.6",
      "Close": "1193.5"
  },
  {
      "Date": "3/19/01",
      "Open": "1192.85",
      "High": "1201.7",
      "Low": "1173.15",
      "Close": "1186.7"
  },
  {
      "Date": "3/20/01",
      "Open": "1186.8",
      "High": "1193.35",
      "Low": "1162.35",
      "Close": "1170.9"
  },
  {
      "Date": "3/21/01",
      "Open": "1168.8",
      "High": "1212.2",
      "Low": "1156.15",
      "Close": "1207.1"
  },
  {
      "Date": "3/22/01",
      "Open": "1206.45",
      "High": "1218.35",
      "Low": "1184.95",
      "Close": "1187.5"
  },
  {
      "Date": "3/23/01",
      "Open": "1187.6",
      "High": "1196.55",
      "Low": "1139.65",
      "Close": "1161.3"
  },
  {
      "Date": "3/26/01",
      "Open": "1159.85",
      "High": "1165.65",
      "Low": "1147.5",
      "Close": "1161.5"
  },
  {
      "Date": "3/27/01",
      "Open": "1161.6",
      "High": "1182.7",
      "Low": "1157.25",
      "Close": "1177.7"
  },
  {
      "Date": "3/28/01",
      "Open": "1177.55",
      "High": "1209.6",
      "Low": "1177.55",
      "Close": "1206.2"
  },
  {
      "Date": "3/29/01",
      "Open": "1204.9",
      "High": "1210.1",
      "Low": "1185",
      "Close": "1195.1"
  },
  {
      "Date": "3/30/01",
      "Open": "1195.05",
      "High": "1195.25",
      "Low": "1144.65",
      "Close": "1148.2"
  },
  {
      "Date": "4/2/01",
      "Open": "1148.1",
      "High": "1148.1",
      "Low": "1094.35",
      "Close": "1138.1"
  },
  {
      "Date": "4/3/01",
      "Open": "1136.65",
      "High": "1153.1",
      "Low": "1128.1",
      "Close": "1149.2"
  },
  {
      "Date": "4/4/01",
      "Open": "1146.1",
      "High": "1146.15",
      "Low": "1120.35",
      "Close": "1136.6"
  },
  {
      "Date": "4/6/01",
      "Open": "1137.55",
      "High": "1171.85",
      "Low": "1133.05",
      "Close": "1139.6"
  },
  {
      "Date": "4/9/01",
      "Open": "1137.6",
      "High": "1138.55",
      "Low": "1116.1",
      "Close": "1128.3"
  },
  {
      "Date": "4/10/01",
      "Open": "1129.1",
      "High": "1129.75",
      "Low": "1093.05",
      "Close": "1103"
  },
  {
      "Date": "4/11/01",
      "Open": "1104.9",
      "High": "1116.85",
      "Low": "1056.1",
      "Close": "1066.8"
  },
  {
      "Date": "4/12/01",
      "Open": "1066.75",
      "High": "1066.75",
      "Low": "1001.8",
      "Close": "1024.9"
  },
  {
      "Date": "4/16/01",
      "Open": "1024",
      "High": "1049.4",
      "Low": "1000.1",
      "Close": "1044.6"
  },
  {
      "Date": "4/17/01",
      "Open": "1044.85",
      "High": "1072.9",
      "Low": "1037.7",
      "Close": "1067"
  },
  {
      "Date": "4/18/01",
      "Open": "1066.5",
      "High": "1106.3",
      "Low": "1063.05",
      "Close": "1103.4"
  },
  {
      "Date": "4/19/01",
      "Open": "1103.6",
      "High": "1159.3",
      "Low": "1103.6",
      "Close": "1144.4"
  },
  {
      "Date": "4/20/01",
      "Open": "1145.75",
      "High": "1156.45",
      "Low": "1120.6",
      "Close": "1144"
  },
  {
      "Date": "4/23/01",
      "Open": "1144.05",
      "High": "1162.05",
      "Low": "1137.3",
      "Close": "1149.7"
  },
  {
      "Date": "4/24/01",
      "Open": "1149.6",
      "High": "1149.6",
      "Low": "1128.4",
      "Close": "1146.3"
  },
  {
      "Date": "4/25/01",
      "Open": "1146.5",
      "High": "1162.85",
      "Low": "1142.3",
      "Close": "1155.3"
  },
  {
      "Date": "4/26/01",
      "Open": "1155.4",
      "High": "1170.2",
      "Low": "1138.85",
      "Close": "1143.7"
  },
  {
      "Date": "4/27/01",
      "Open": "1143.65",
      "High": "1143.65",
      "Low": "1078.1",
      "Close": "1101.3"
  },
  {
      "Date": "4/30/01",
      "Open": "1101.45",
      "High": "1127.9",
      "Low": "1101.45",
      "Close": "1125.2"
  },
  {
      "Date": "5/2/01",
      "Open": "1125.45",
      "High": "1150.65",
      "Low": "1125.25",
      "Close": "1137.2"
  },
  {
      "Date": "5/3/01",
      "Open": "1137.15",
      "High": "1139.85",
      "Low": "1117.7",
      "Close": "1122"
  },
  {
      "Date": "5/4/01",
      "Open": "1121.45",
      "High": "1131.95",
      "Low": "1113.65",
      "Close": "1130"
  },
  {
      "Date": "5/7/01",
      "Open": "1130.05",
      "High": "1145.4",
      "Low": "1130.05",
      "Close": "1139.2"
  },
  {
      "Date": "5/8/01",
      "Open": "1139.25",
      "High": "1151.95",
      "Low": "1138.2",
      "Close": "1148.9"
  },
  {
      "Date": "5/9/01",
      "Open": "1149.9",
      "High": "1164.3",
      "Low": "1145.25",
      "Close": "1149.2"
  },
  {
      "Date": "5/10/01",
      "Open": "1149.1",
      "High": "1151.6",
      "Low": "1139.25",
      "Close": "1144.9"
  },
  {
      "Date": "5/11/01",
      "Open": "1144.1",
      "High": "1146.85",
      "Low": "1136.9",
      "Close": "1140.5"
  },
  {
      "Date": "5/14/01",
      "Open": "1140.45",
      "High": "1146.25",
      "Low": "1134.05",
      "Close": "1140.8"
  },
  {
      "Date": "5/15/01",
      "Open": "1140.75",
      "High": "1147.75",
      "Low": "1096.25",
      "Close": "1145.3"
  },
  {
      "Date": "5/16/01",
      "Open": "1147.1",
      "High": "1163.15",
      "Low": "1146.05",
      "Close": "1151.1"
  },
  {
      "Date": "5/17/01",
      "Open": "1151.2",
      "High": "1179",
      "Low": "1150.6",
      "Close": "1174.9"
  },
  {
      "Date": "5/18/01",
      "Open": "1175",
      "High": "1187.65",
      "Low": "1169.2",
      "Close": "1172.8"
  },
  {
      "Date": "5/21/01",
      "Open": "1172.95",
      "High": "1182.65",
      "Low": "1166.7",
      "Close": "1169.4"
  },
  {
      "Date": "5/22/01",
      "Open": "1169.5",
      "High": "1176.2",
      "Low": "1165.65",
      "Close": "1168.1"
  },
  {
      "Date": "5/23/01",
      "Open": "1168.25",
      "High": "1181.8",
      "Low": "1167",
      "Close": "1179.1"
  },
  {
      "Date": "5/24/01",
      "Open": "1179.1",
      "High": "1190.15",
      "Low": "1177.55",
      "Close": "1181.8"
  },
  {
      "Date": "5/25/01",
      "Open": "1181.95",
      "High": "1188.5",
      "Low": "1171.35",
      "Close": "1174.9"
  },
  {
      "Date": "5/28/01",
      "Open": "1174.95",
      "High": "1194.5",
      "Low": "1174.45",
      "Close": "1193.2"
  },
  {
      "Date": "5/29/01",
      "Open": "1193.25",
      "High": "1202.05",
      "Low": "1193.25",
      "Close": "1198.4"
  },
  {
      "Date": "5/30/01",
      "Open": "1199.5",
      "High": "1207",
      "Low": "1173.75",
      "Close": "1177.5"
  },
  {
      "Date": "5/31/01",
      "Open": "1177.5",
      "High": "1177.5",
      "Low": "1154.9",
      "Close": "1167.9"
  },
  {
      "Date": "6/1/01",
      "Open": "1168.1",
      "High": "1175.8",
      "Low": "1146.9",
      "Close": "1148"
  },
  {
      "Date": "6/4/01",
      "Open": "1148",
      "High": "1154.45",
      "Low": "1125.35",
      "Close": "1127.2"
  },
  {
      "Date": "6/5/01",
      "Open": "1127.15",
      "High": "1130.65",
      "Low": "1106.8",
      "Close": "1115.6"
  },
  {
      "Date": "6/6/01",
      "Open": "1117.5",
      "High": "1132.95",
      "Low": "1114",
      "Close": "1115.7"
  },
  {
      "Date": "6/7/01",
      "Open": "1115.05",
      "High": "1116.1",
      "Low": "1095.45",
      "Close": "1112.3"
  },
  {
      "Date": "6/8/01",
      "Open": "1112.45",
      "High": "1128.9",
      "Low": "1112.45",
      "Close": "1126.6"
  },
  {
      "Date": "6/11/01",
      "Open": "1126.55",
      "High": "1134.8",
      "Low": "1122.35",
      "Close": "1131.1"
  },
  {
      "Date": "6/12/01",
      "Open": "1131.05",
      "High": "1133.3",
      "Low": "1121.8",
      "Close": "1127.1"
  },
  {
      "Date": "6/13/01",
      "Open": "1127.1",
      "High": "1132.95",
      "Low": "1126.9",
      "Close": "1129"
  },
  {
      "Date": "6/14/01",
      "Open": "1128.95",
      "High": "1128.95",
      "Low": "1110.15",
      "Close": "1112.7"
  },
  {
      "Date": "6/15/01",
      "Open": "1112.7",
      "High": "1112.7",
      "Low": "1084.2",
      "Close": "1087.7"
  },
  {
      "Date": "6/18/01",
      "Open": "1087.75",
      "High": "1087.75",
      "Low": "1064.4",
      "Close": "1078.3"
  },
  {
      "Date": "6/19/01",
      "Open": "1078.25",
      "High": "1099.7",
      "Low": "1075.45",
      "Close": "1096.6"
  },
  {
      "Date": "6/20/01",
      "Open": "1096.35",
      "High": "1100.55",
      "Low": "1084.7",
      "Close": "1097.6"
  },
  {
      "Date": "6/21/01",
      "Open": "1097.9",
      "High": "1102.45",
      "Low": "1090.25",
      "Close": "1095.2"
  },
  {
      "Date": "6/22/01",
      "Open": "1095.85",
      "High": "1097.8",
      "Low": "1081.3",
      "Close": "1087.6"
  },
  {
      "Date": "6/25/01",
      "Open": "1087.1",
      "High": "1087.1",
      "Low": "1064.4",
      "Close": "1067"
  },
  {
      "Date": "6/26/01",
      "Open": "1066.4",
      "High": "1099.8",
      "Low": "1060.05",
      "Close": "1096.6"
  },
  {
      "Date": "6/27/01",
      "Open": "1096.6",
      "High": "1102.8",
      "Low": "1086.75",
      "Close": "1096.1"
  },
  {
      "Date": "6/28/01",
      "Open": "1095.85",
      "High": "1101",
      "Low": "1090.2",
      "Close": "1094"
  },
  {
      "Date": "6/29/01",
      "Open": "1094.05",
      "High": "1114.35",
      "Low": "1091.05",
      "Close": "1107.9"
  },
  {
      "Date": "7/2/01",
      "Open": "1108.35",
      "High": "1119.55",
      "Low": "1097.95",
      "Close": "1100.7"
  },
  {
      "Date": "7/3/01",
      "Open": "1097.4",
      "High": "1097.4",
      "Low": "1066.05",
      "Close": "1069.8"
  },
  {
      "Date": "7/4/01",
      "Open": "1068.05",
      "High": "1074.6",
      "Low": "1062.2",
      "Close": "1067.9"
  },
  {
      "Date": "7/5/01",
      "Open": "1068.35",
      "High": "1079.35",
      "Low": "1066.95",
      "Close": "1069.7"
  },
  {
      "Date": "7/6/01",
      "Open": "1069.15",
      "High": "1069.15",
      "Low": "1059.6",
      "Close": "1065.1"
  },
  {
      "Date": "7/9/01",
      "Open": "1064.8",
      "High": "1064.8",
      "Low": "1052.1",
      "Close": "1059.5"
  },
  {
      "Date": "7/10/01",
      "Open": "1059.95",
      "High": "1078.1",
      "Low": "1059.25",
      "Close": "1072"
  },
  {
      "Date": "7/11/01",
      "Open": "1072.15",
      "High": "1088.5",
      "Low": "1068.35",
      "Close": "1083.6"
  },
  {
      "Date": "7/12/01",
      "Open": "1083.8",
      "High": "1109",
      "Low": "1083.8",
      "Close": "1105.5"
  },
  {
      "Date": "7/13/01",
      "Open": "1105.7",
      "High": "1127.15",
      "Low": "1103.3",
      "Close": "1110.4"
  },
  {
      "Date": "7/16/01",
      "Open": "1110.35",
      "High": "1116.7",
      "Low": "1102.45",
      "Close": "1105.5"
  },
  {
      "Date": "7/17/01",
      "Open": "1103.55",
      "High": "1106.05",
      "Low": "1092.5",
      "Close": "1103.1"
  },
  {
      "Date": "7/18/01",
      "Open": "1103.1",
      "High": "1108.45",
      "Low": "1088.1",
      "Close": "1091.9"
  },
  {
      "Date": "7/19/01",
      "Open": "1091.35",
      "High": "1091.35",
      "Low": "1079.55",
      "Close": "1085.9"
  },
  {
      "Date": "7/20/01",
      "Open": "1085.95",
      "High": "1089.95",
      "Low": "1072.75",
      "Close": "1077.7"
  },
  {
      "Date": "7/23/01",
      "Open": "1076.15",
      "High": "1076.15",
      "Low": "1063.4",
      "Close": "1070.6"
  },
  {
      "Date": "7/24/01",
      "Open": "1065.95",
      "High": "1074.75",
      "Low": "1064.5",
      "Close": "1072.5"
  },
  {
      "Date": "7/25/01",
      "Open": "1072.45",
      "High": "1073.95",
      "Low": "1062.15",
      "Close": "1064.2"
  },
  {
      "Date": "7/26/01",
      "Open": "1063.9",
      "High": "1066.55",
      "Low": "1050.15",
      "Close": "1053.4"
  },
  {
      "Date": "7/27/01",
      "Open": "1053.2",
      "High": "1061.2",
      "Low": "1046.9",
      "Close": "1051.7"
  },
  {
      "Date": "7/30/01",
      "Open": "1051.45",
      "High": "1062.8",
      "Low": "1050.9",
      "Close": "1061.4"
  },
  {
      "Date": "7/31/01",
      "Open": "1061.45",
      "High": "1076.35",
      "Low": "1053.6",
      "Close": "1072.8"
  },
  {
      "Date": "8/1/01",
      "Open": "1073",
      "High": "1077.7",
      "Low": "1059.75",
      "Close": "1063.1"
  },
  {
      "Date": "8/2/01",
      "Open": "1064.25",
      "High": "1070.05",
      "Low": "1059.55",
      "Close": "1066"
  },
  {
      "Date": "8/3/01",
      "Open": "1066.4",
      "High": "1077.55",
      "Low": "1066.2",
      "Close": "1074.6"
  },
  {
      "Date": "8/6/01",
      "Open": "1074.2",
      "High": "1082.4",
      "Low": "1072.55",
      "Close": "1075.2"
  },
  {
      "Date": "8/7/01",
      "Open": "1074.35",
      "High": "1079.3",
      "Low": "1070.2",
      "Close": "1072.1"
  },
  {
      "Date": "8/8/01",
      "Open": "1071.75",
      "High": "1072.1",
      "Low": "1059.55",
      "Close": "1068"
  },
  {
      "Date": "8/9/01",
      "Open": "1068.1",
      "High": "1072.6",
      "Low": "1061.4",
      "Close": "1070.6"
  },
  {
      "Date": "8/10/01",
      "Open": "1070.65",
      "High": "1076.7",
      "Low": "1069.05",
      "Close": "1071.1"
  },
  {
      "Date": "8/13/01",
      "Open": "1070.95",
      "High": "1081.4",
      "Low": "1061.05",
      "Close": "1063"
  },
  {
      "Date": "8/14/01",
      "Open": "1063.05",
      "High": "1076.6",
      "Low": "1062.2",
      "Close": "1075.5"
  },
  {
      "Date": "8/16/01",
      "Open": "1075.2",
      "High": "1083.7",
      "Low": "1072.95",
      "Close": "1078.9"
  },
  {
      "Date": "8/17/01",
      "Open": "1079",
      "High": "1084",
      "Low": "1067.6",
      "Close": "1069.2"
  },
  {
      "Date": "8/20/01",
      "Open": "1062.5",
      "High": "1067.95",
      "Low": "1061",
      "Close": "1063.7"
  },
  {
      "Date": "8/21/01",
      "Open": "1063.65",
      "High": "1071.2",
      "Low": "1062.3",
      "Close": "1068.7"
  },
  {
      "Date": "8/23/01",
      "Open": "1068.7",
      "High": "1079",
      "Low": "1065.7",
      "Close": "1071.5"
  },
  {
      "Date": "8/24/01",
      "Open": "1071.5",
      "High": "1076.7",
      "Low": "1064.9",
      "Close": "1069.1"
  },
  {
      "Date": "8/27/01",
      "Open": "1064.05",
      "High": "1078.35",
      "Low": "1064.05",
      "Close": "1072.5"
  },
  {
      "Date": "8/28/01",
      "Open": "1072.35",
      "High": "1076.85",
      "Low": "1069.6",
      "Close": "1070.6"
  },
  {
      "Date": "8/29/01",
      "Open": "1070.55",
      "High": "1074.5",
      "Low": "1066.45",
      "Close": "1067.4"
  },
  {
      "Date": "8/30/01",
      "Open": "1068.3",
      "High": "1070.25",
      "Low": "1061.9",
      "Close": "1064.1"
  },
  {
      "Date": "8/31/01",
      "Open": "1064.1",
      "High": "1064.1",
      "Low": "1051.75",
      "Close": "1053.7"
  },
  {
      "Date": "9/3/01",
      "Open": "1054.65",
      "High": "1059.9",
      "Low": "1046.35",
      "Close": "1048"
  },
  {
      "Date": "9/4/01",
      "Open": "1048.2",
      "High": "1053.25",
      "Low": "1043.3",
      "Close": "1048.2"
  },
  {
      "Date": "9/5/01",
      "Open": "1047.25",
      "High": "1051.55",
      "Low": "1043.3",
      "Close": "1045"
  },
  {
      "Date": "9/6/01",
      "Open": "1047.7",
      "High": "1048.2",
      "Low": "1033.4",
      "Close": "1036.1"
  },
  {
      "Date": "9/7/01",
      "Open": "1036.35",
      "High": "1037.35",
      "Low": "1026.45",
      "Close": "1035.2"
  },
  {
      "Date": "9/10/01",
      "Open": "1035",
      "High": "1045.3",
      "Low": "1031.45",
      "Close": "1033.4"
  },
  {
      "Date": "9/11/01",
      "Open": "1033.35",
      "High": "1037.45",
      "Low": "1020.7",
      "Close": "1023.4"
  },
  {
      "Date": "9/12/01",
      "Open": "1023.25",
      "High": "1023.25",
      "Low": "957.95",
      "Close": "982.2"
  },
  {
      "Date": "9/13/01",
      "Open": "982.2",
      "High": "993.05",
      "Low": "969",
      "Close": "971.7"
  },
  {
      "Date": "9/14/01",
      "Open": "972.05",
      "High": "974.5",
      "Low": "902.7",
      "Close": "919.7"
  },
  {
      "Date": "9/17/01",
      "Open": "916.15",
      "High": "916.15",
      "Low": "861.05",
      "Close": "872.25"
  },
  {
      "Date": "9/18/01",
      "Open": "872.15",
      "High": "910.35",
      "Low": "868.5",
      "Close": "900.2"
  },
  {
      "Date": "9/19/01",
      "Open": "899.7",
      "High": "915",
      "Low": "899.7",
      "Close": "912.2"
  },
  {
      "Date": "9/20/01",
      "Open": "910.2",
      "High": "910.35",
      "Low": "873.7",
      "Close": "898.8"
  },
  {
      "Date": "9/21/01",
      "Open": "903.75",
      "High": "903.75",
      "Low": "849.95",
      "Close": "854.2"
  },
  {
      "Date": "9/24/01",
      "Open": "853",
      "High": "878.6",
      "Low": "853",
      "Close": "869.05"
  },
  {
      "Date": "9/25/01",
      "Open": "869.15",
      "High": "893.35",
      "Low": "858.85",
      "Close": "861.4"
  },
  {
      "Date": "9/26/01",
      "Open": "861.35",
      "High": "877",
      "Low": "859.2",
      "Close": "873.7"
  },
  {
      "Date": "9/27/01",
      "Open": "873.15",
      "High": "893.05",
      "Low": "868.3",
      "Close": "890"
  },
  {
      "Date": "9/28/01",
      "Open": "891.75",
      "High": "922.55",
      "Low": "891",
      "Close": "913.85"
  },
  {
      "Date": "10/1/01",
      "Open": "914.65",
      "High": "919.2",
      "Low": "905.5",
      "Close": "910.1"
  },
  {
      "Date": "10/3/01",
      "Open": "910.05",
      "High": "915.05",
      "Low": "896.25",
      "Close": "899.65"
  },
  {
      "Date": "10/4/01",
      "Open": "899.65",
      "High": "914.25",
      "Low": "899.65",
      "Close": "911.65"
  },
  {
      "Date": "10/5/01",
      "Open": "911.6",
      "High": "919.75",
      "Low": "903.05",
      "Close": "914.6"
  },
  {
      "Date": "10/8/01",
      "Open": "911.2",
      "High": "913.05",
      "Low": "884.65",
      "Close": "901.95"
  },
  {
      "Date": "10/9/01",
      "Open": "903",
      "High": "914.3",
      "Low": "902.55",
      "Close": "912.7"
  },
  {
      "Date": "10/10/01",
      "Open": "915.5",
      "High": "942.8",
      "Low": "912.9",
      "Close": "940.35"
  },
  {
      "Date": "10/11/01",
      "Open": "940.45",
      "High": "959.75",
      "Low": "940.45",
      "Close": "954.9"
  },
  {
      "Date": "10/12/01",
      "Open": "958.4",
      "High": "969.9",
      "Low": "956.85",
      "Close": "960.4"
  },
  {
      "Date": "10/15/01",
      "Open": "963.65",
      "High": "966.15",
      "Low": "954.35",
      "Close": "963.4"
  },
  {
      "Date": "10/16/01",
      "Open": "963.4",
      "High": "973.25",
      "Low": "962.5",
      "Close": "971.25"
  },
  {
      "Date": "10/17/01",
      "Open": "971.95",
      "High": "989.05",
      "Low": "971.95",
      "Close": "986.25"
  },
  {
      "Date": "10/18/01",
      "Open": "984.2",
      "High": "984.5",
      "Low": "969.35",
      "Close": "972.05"
  },
  {
      "Date": "10/19/01",
      "Open": "972.2",
      "High": "981",
      "Low": "970.85",
      "Close": "976.65"
  },
  {
      "Date": "10/22/01",
      "Open": "980.05",
      "High": "994.15",
      "Low": "973.2",
      "Close": "976.4"
  },
  {
      "Date": "10/23/01",
      "Open": "976.75",
      "High": "994.15",
      "Low": "976.55",
      "Close": "993.2"
  },
  {
      "Date": "10/24/01",
      "Open": "992.95",
      "High": "1000.95",
      "Low": "987.4",
      "Close": "991.2"
  },
  {
      "Date": "10/25/01",
      "Open": "991.25",
      "High": "998",
      "Low": "979.95",
      "Close": "983.2"
  },
  {
      "Date": "10/29/01",
      "Open": "983.15",
      "High": "996.6",
      "Low": "974.05",
      "Close": "977.45"
  },
  {
      "Date": "10/30/01",
      "Open": "977.35",
      "High": "977.35",
      "Low": "960.5",
      "Close": "963.1"
  },
  {
      "Date": "10/31/01",
      "Open": "962.85",
      "High": "976.4",
      "Low": "959.35",
      "Close": "971.9"
  },
  {
      "Date": "11/1/01",
      "Open": "973.55",
      "High": "997.3",
      "Low": "973.55",
      "Close": "994"
  },
  {
      "Date": "11/2/01",
      "Open": "994.25",
      "High": "1009.55",
      "Low": "991.95",
      "Close": "997.6"
  },
  {
      "Date": "11/5/01",
      "Open": "997.25",
      "High": "1001.3",
      "Low": "987.85",
      "Close": "991.05"
  },
  {
      "Date": "11/6/01",
      "Open": "991.2",
      "High": "1007.4",
      "Low": "991.15",
      "Close": "1001.9"
  },
  {
      "Date": "11/7/01",
      "Open": "1001.95",
      "High": "1014.1",
      "Low": "984.45",
      "Close": "987.5"
  },
  {
      "Date": "11/8/01",
      "Open": "987.5",
      "High": "1001.15",
      "Low": "985.75",
      "Close": "997.7"
  },
  {
      "Date": "11/9/01",
      "Open": "999.1",
      "High": "1008.95",
      "Low": "999.1",
      "Close": "1004"
  },
  {
      "Date": "11/12/01",
      "Open": "1004.55",
      "High": "1014.25",
      "Low": "1003.6",
      "Close": "1010.9"
  },
  {
      "Date": "11/13/01",
      "Open": "1010.95",
      "High": "1013.25",
      "Low": "999.85",
      "Close": "1005.4"
  },
  {
      "Date": "11/14/01",
      "Open": "1005.45",
      "High": "1017.2",
      "Low": "1004.7",
      "Close": "1015.8"
  },
  {
      "Date": "11/15/01",
      "Open": "1016.05",
      "High": "1038.35",
      "Low": "1015.15",
      "Close": "1035.7"
  },
  {
      "Date": "11/19/01",
      "Open": "1034.2",
      "High": "1072",
      "Low": "1032.85",
      "Close": "1068.1"
  },
  {
      "Date": "11/20/01",
      "Open": "1067.3",
      "High": "1082.4",
      "Low": "1046.15",
      "Close": "1050.2"
  },
  {
      "Date": "11/21/01",
      "Open": "1050.4",
      "High": "1064.85",
      "Low": "1046.3",
      "Close": "1056.3"
  },
  {
      "Date": "11/22/01",
      "Open": "1056.45",
      "High": "1075.9",
      "Low": "1056.45",
      "Close": "1062.4"
  },
  {
      "Date": "11/23/01",
      "Open": "1063.05",
      "High": "1075.05",
      "Low": "1057.3",
      "Close": "1059"
  },
  {
      "Date": "11/26/01",
      "Open": "1059.25",
      "High": "1084.65",
      "Low": "1059.25",
      "Close": "1080.6"
  },
  {
      "Date": "11/27/01",
      "Open": "1087.6",
      "High": "1097.6",
      "Low": "1071.05",
      "Close": "1076"
  },
  {
      "Date": "11/28/01",
      "Open": "1076.15",
      "High": "1082.3",
      "Low": "1067.65",
      "Close": "1070.8"
  },
  {
      "Date": "11/29/01",
      "Open": "1070.3",
      "High": "1071",
      "Low": "1063",
      "Close": "1067.1"
  },
  {
      "Date": "12/3/01",
      "Open": "1067.2",
      "High": "1074.5",
      "Low": "1063.25",
      "Close": "1065.4"
  },
  {
      "Date": "12/4/01",
      "Open": "1066.05",
      "High": "1082.6",
      "Low": "1065.65",
      "Close": "1077.7"
  },
  {
      "Date": "12/5/01",
      "Open": "1077.95",
      "High": "1107.15",
      "Low": "1077.95",
      "Close": "1104.5"
  },
  {
      "Date": "12/6/01",
      "Open": "1105.1",
      "High": "1132.65",
      "Low": "1105.1",
      "Close": "1110.4"
  },
  {
      "Date": "12/7/01",
      "Open": "1110.9",
      "High": "1122.65",
      "Low": "1109.65",
      "Close": "1112.3"
  },
  {
      "Date": "12/10/01",
      "Open": "1112.15",
      "High": "1123.55",
      "Low": "1112.15",
      "Close": "1115.2"
  },
  {
      "Date": "12/11/01",
      "Open": "1112.15",
      "High": "1122.85",
      "Low": "1107.7",
      "Close": "1110.2"
  },
  {
      "Date": "12/12/01",
      "Open": "1110.65",
      "High": "1121.55",
      "Low": "1104.75",
      "Close": "1107.6"
  },
  {
      "Date": "12/13/01",
      "Open": "1107.75",
      "High": "1114.15",
      "Low": "1069.95",
      "Close": "1098.7"
  },
  {
      "Date": "12/14/01",
      "Open": "1098.65",
      "High": "1102.9",
      "Low": "1082.75",
      "Close": "1087.8"
  },
  {
      "Date": "12/18/01",
      "Open": "1089.9",
      "High": "1098",
      "Low": "1071.35",
      "Close": "1082.3"
  },
  {
      "Date": "12/19/01",
      "Open": "1082.5",
      "High": "1086.35",
      "Low": "1057.1",
      "Close": "1060.7"
  },
  {
      "Date": "12/20/01",
      "Open": "1060.45",
      "High": "1064.8",
      "Low": "1047.5",
      "Close": "1062"
  },
  {
      "Date": "12/21/01",
      "Open": "1061.8",
      "High": "1061.8",
      "Low": "1043.65",
      "Close": "1050.8"
  },
  {
      "Date": "12/24/01",
      "Open": "1051",
      "High": "1055",
      "Low": "1032.6",
      "Close": "1048.5"
  },
  {
      "Date": "12/26/01",
      "Open": "1048.9",
      "High": "1058.3",
      "Low": "1030.5",
      "Close": "1034.2"
  },
  {
      "Date": "12/27/01",
      "Open": "1033.85",
      "High": "1037.7",
      "Low": "1017.35",
      "Close": "1020"
  },
  {
      "Date": "12/28/01",
      "Open": "1019.9",
      "High": "1036.35",
      "Low": "1010.45",
      "Close": "1033.8"
  },
  {
      "Date": "12/31/01",
      "Open": "1033.9",
      "High": "1062.3",
      "Low": "1033.9",
      "Close": "1059"
  },
  {
      "Date": "1/1/02",
      "Open": "1058.85",
      "High": "1071.15",
      "Low": "1052.05",
      "Close": "1055.3"
  },
  {
      "Date": "1/2/02",
      "Open": "1054.95",
      "High": "1065.25",
      "Low": "1054.1",
      "Close": "1060.7"
  },
  {
      "Date": "1/3/02",
      "Open": "1063.45",
      "High": "1074.85",
      "Low": "1062.9",
      "Close": "1072.2"
  },
  {
      "Date": "1/4/02",
      "Open": "1072.4",
      "High": "1099.25",
      "Low": "1072.4",
      "Close": "1096.2"
  },
  {
      "Date": "1/7/02",
      "Open": "1093.05",
      "High": "1111.55",
      "Low": "1093.05",
      "Close": "1100.1"
  },
  {
      "Date": "1/8/02",
      "Open": "1100.55",
      "High": "1114.55",
      "Low": "1097.9",
      "Close": "1109.9"
  },
  {
      "Date": "1/9/02",
      "Open": "1110",
      "High": "1119.4",
      "Low": "1098.7",
      "Close": "1102.8"
  },
  {
      "Date": "1/10/02",
      "Open": "1098.8",
      "High": "1105.35",
      "Low": "1093.6",
      "Close": "1098.2"
  },
  {
      "Date": "1/11/02",
      "Open": "1098.15",
      "High": "1105.9",
      "Low": "1073.45",
      "Close": "1088.5"
  },
  {
      "Date": "1/14/02",
      "Open": "1089.45",
      "High": "1118.5",
      "Low": "1089.45",
      "Close": "1109.8"
  },
  {
      "Date": "1/15/02",
      "Open": "1109.45",
      "High": "1112.6",
      "Low": "1090.2",
      "Close": "1094.1"
  },
  {
      "Date": "1/16/02",
      "Open": "1094.15",
      "High": "1104.8",
      "Low": "1085.3",
      "Close": "1090.3"
  },
  {
      "Date": "1/17/02",
      "Open": "1090.25",
      "High": "1116.05",
      "Low": "1078.95",
      "Close": "1109.2"
  },
  {
      "Date": "1/18/02",
      "Open": "1109.55",
      "High": "1121.75",
      "Low": "1089.05",
      "Close": "1093.1"
  },
  {
      "Date": "1/21/02",
      "Open": "1093.25",
      "High": "1099.8",
      "Low": "1082.65",
      "Close": "1091.3"
  },
  {
      "Date": "1/22/02",
      "Open": "1091.35",
      "High": "1099",
      "Low": "1090",
      "Close": "1092.8"
  },
  {
      "Date": "1/23/02",
      "Open": "1092.85",
      "High": "1096.95",
      "Low": "1087.5",
      "Close": "1089.4"
  },
  {
      "Date": "1/24/02",
      "Open": "1089.6",
      "High": "1098.6",
      "Low": "1081.7",
      "Close": "1085.3"
  },
  {
      "Date": "1/25/02",
      "Open": "1085.85",
      "High": "1089.7",
      "Low": "1077",
      "Close": "1080.1"
  },
  {
      "Date": "1/28/02",
      "Open": "1083.25",
      "High": "1088.75",
      "Low": "1068.7",
      "Close": "1071.3"
  },
  {
      "Date": "1/29/02",
      "Open": "1071.35",
      "High": "1076.45",
      "Low": "1068.85",
      "Close": "1071.6"
  },
  {
      "Date": "1/30/02",
      "Open": "1072.85",
      "High": "1072.85",
      "Low": "1058.8",
      "Close": "1067.4"
  },
  {
      "Date": "1/31/02",
      "Open": "1067.65",
      "High": "1082",
      "Low": "1067.65",
      "Close": "1075.4"
  },
  {
      "Date": "2/1/02",
      "Open": "1076.95",
      "High": "1088.05",
      "Low": "1076.95",
      "Close": "1081.6"
  },
  {
      "Date": "2/4/02",
      "Open": "1083.05",
      "High": "1086.95",
      "Low": "1074.15",
      "Close": "1076.9"
  },
  {
      "Date": "2/5/02",
      "Open": "1076.25",
      "High": "1081.2",
      "Low": "1069.4",
      "Close": "1074.2"
  },
  {
      "Date": "2/6/02",
      "Open": "1074.4",
      "High": "1117.1",
      "Low": "1074",
      "Close": "1113.1"
  },
  {
      "Date": "2/7/02",
      "Open": "1114.85",
      "High": "1130.95",
      "Low": "1104.3",
      "Close": "1110.4"
  },
  {
      "Date": "2/8/02",
      "Open": "1112.8",
      "High": "1131.85",
      "Low": "1104.45",
      "Close": "1123.7"
  },
  {
      "Date": "2/11/02",
      "Open": "1124.85",
      "High": "1144.15",
      "Low": "1124.85",
      "Close": "1131.5"
  },
  {
      "Date": "2/12/02",
      "Open": "1131.95",
      "High": "1143.3",
      "Low": "1125.55",
      "Close": "1129.5"
  },
  {
      "Date": "2/13/02",
      "Open": "1129.8",
      "High": "1142.3",
      "Low": "1129.6",
      "Close": "1135.1"
  },
  {
      "Date": "2/14/02",
      "Open": "1135.5",
      "High": "1151.65",
      "Low": "1133.85",
      "Close": "1150"
  },
  {
      "Date": "2/15/02",
      "Open": "1150.65",
      "High": "1164.1",
      "Low": "1150.3",
      "Close": "1159.9"
  },
  {
      "Date": "2/18/02",
      "Open": "1159.85",
      "High": "1177.05",
      "Low": "1156.7",
      "Close": "1172.8"
  },
  {
      "Date": "2/19/02",
      "Open": "1172.85",
      "High": "1178.1",
      "Low": "1155.1",
      "Close": "1158.9"
  },
  {
      "Date": "2/20/02",
      "Open": "1158.45",
      "High": "1158.45",
      "Low": "1134.65",
      "Close": "1145.9"
  },
  {
      "Date": "2/21/02",
      "Open": "1146.05",
      "High": "1158.05",
      "Low": "1146.05",
      "Close": "1149.8"
  },
  {
      "Date": "2/22/02",
      "Open": "1149.85",
      "High": "1165.7",
      "Low": "1145.5",
      "Close": "1163.5"
  },
  {
      "Date": "2/25/02",
      "Open": "1163.65",
      "High": "1167.6",
      "Low": "1155.45",
      "Close": "1165.4"
  },
  {
      "Date": "2/26/02",
      "Open": "1165.75",
      "High": "1192.65",
      "Low": "1165.75",
      "Close": "1189.4"
  },
  {
      "Date": "2/27/02",
      "Open": "1190.15",
      "High": "1205.95",
      "Low": "1180",
      "Close": "1189.2"
  },
  {
      "Date": "2/28/02",
      "Open": "1189.6",
      "High": "1197",
      "Low": "1135.2",
      "Close": "1142"
  },
  {
      "Date": "3/1/02",
      "Open": "1142.05",
      "High": "1181.25",
      "Low": "1135.45",
      "Close": "1178"
  },
  {
      "Date": "3/4/02",
      "Open": "1178.45",
      "High": "1201.1",
      "Low": "1172.3",
      "Close": "1177.3"
  },
  {
      "Date": "3/5/02",
      "Open": "1176.4",
      "High": "1193.1",
      "Low": "1175.15",
      "Close": "1178.5"
  },
  {
      "Date": "3/6/02",
      "Open": "1176.55",
      "High": "1182.65",
      "Low": "1162.75",
      "Close": "1172.6"
  },
  {
      "Date": "3/7/02",
      "Open": "1173.6",
      "High": "1195.2",
      "Low": "1173.6",
      "Close": "1193"
  },
  {
      "Date": "3/8/02",
      "Open": "1193.6",
      "High": "1199.6",
      "Low": "1182.55",
      "Close": "1187.6"
  },
  {
      "Date": "3/11/02",
      "Open": "1187.5",
      "High": "1192.75",
      "Low": "1164.55",
      "Close": "1167.8"
  },
  {
      "Date": "3/12/02",
      "Open": "1167.85",
      "High": "1171.2",
      "Low": "1144.3",
      "Close": "1150.4"
  },
  {
      "Date": "3/13/02",
      "Open": "1150.2",
      "High": "1165.4",
      "Low": "1143.55",
      "Close": "1157"
  },
  {
      "Date": "3/14/02",
      "Open": "1156.85",
      "High": "1163.65",
      "Low": "1153.15",
      "Close": "1159.4"
  },
  {
      "Date": "3/15/02",
      "Open": "1159.6",
      "High": "1173.75",
      "Low": "1159.6",
      "Close": "1169.7"
  },
  {
      "Date": "3/18/02",
      "Open": "1169.95",
      "High": "1184.7",
      "Low": "1163.75",
      "Close": "1169.3"
  },
  {
      "Date": "3/19/02",
      "Open": "1169.4",
      "High": "1170.8",
      "Low": "1150.3",
      "Close": "1152.1"
  },
  {
      "Date": "3/20/02",
      "Open": "1152.05",
      "High": "1158.95",
      "Low": "1148.5",
      "Close": "1155.6"
  },
  {
      "Date": "3/21/02",
      "Open": "1155.55",
      "High": "1155.55",
      "Low": "1141.05",
      "Close": "1144.2"
  },
  {
      "Date": "3/22/02",
      "Open": "1144.5",
      "High": "1148.65",
      "Low": "1135.9",
      "Close": "1138.4"
  },
  {
      "Date": "3/26/02",
      "Open": "1138.1",
      "High": "1140.2",
      "Low": "1117.85",
      "Close": "1123"
  },
  {
      "Date": "3/27/02",
      "Open": "1130",
      "High": "1134.1",
      "Low": "1121.35",
      "Close": "1123.3"
  },
  {
      "Date": "3/28/02",
      "Open": "1123.6",
      "High": "1138.45",
      "Low": "1123.6",
      "Close": "1129.5"
  },
  {
      "Date": "4/1/02",
      "Open": "1129.85",
      "High": "1143.3",
      "Low": "1129.85",
      "Close": "1138.9"
  },
  {
      "Date": "4/2/02",
      "Open": "1138.8",
      "High": "1147.3",
      "Low": "1132.3",
      "Close": "1136.9"
  },
  {
      "Date": "4/3/02",
      "Open": "1136.6",
      "High": "1136.9",
      "Low": "1121.45",
      "Close": "1123.5"
  },
  {
      "Date": "4/4/02",
      "Open": "1124.05",
      "High": "1149.65",
      "Low": "1123.9",
      "Close": "1145.9"
  },
  {
      "Date": "4/5/02",
      "Open": "1146.05",
      "High": "1153.3",
      "Low": "1139.9",
      "Close": "1141.9"
  },
  {
      "Date": "4/8/02",
      "Open": "1141.9",
      "High": "1147.9",
      "Low": "1134",
      "Close": "1135.2"
  },
  {
      "Date": "4/9/02",
      "Open": "1135.15",
      "High": "1137.45",
      "Low": "1122.1",
      "Close": "1126.7"
  },
  {
      "Date": "4/10/02",
      "Open": "1126.7",
      "High": "1140.25",
      "Low": "1125.75",
      "Close": "1138.5"
  },
  {
      "Date": "4/11/02",
      "Open": "1138.55",
      "High": "1150.2",
      "Low": "1138.55",
      "Close": "1143.6"
  },
  {
      "Date": "4/12/02",
      "Open": "1143.25",
      "High": "1151.55",
      "Low": "1138.65",
      "Close": "1146.5"
  },
  {
      "Date": "4/15/02",
      "Open": "1146.55",
      "High": "1152.55",
      "Low": "1130.45",
      "Close": "1134.1"
  },
  {
      "Date": "4/16/02",
      "Open": "1130.8",
      "High": "1133.5",
      "Low": "1116.65",
      "Close": "1118.7"
  },
  {
      "Date": "4/17/02",
      "Open": "1119",
      "High": "1136.6",
      "Low": "1118.95",
      "Close": "1125.1"
  },
  {
      "Date": "4/18/02",
      "Open": "1125.25",
      "High": "1135",
      "Low": "1123.8",
      "Close": "1129"
  },
  {
      "Date": "4/19/02",
      "Open": "1125.55",
      "High": "1127.05",
      "Low": "1097.6",
      "Close": "1100.3"
  },
  {
      "Date": "4/22/02",
      "Open": "1100.5",
      "High": "1107.2",
      "Low": "1088.05",
      "Close": "1104.1"
  },
  {
      "Date": "4/23/02",
      "Open": "1104",
      "High": "1108.8",
      "Low": "1095",
      "Close": "1106"
  },
  {
      "Date": "4/24/02",
      "Open": "1106.25",
      "High": "1116.85",
      "Low": "1105.7",
      "Close": "1110.6"
  },
  {
      "Date": "4/25/02",
      "Open": "1110.65",
      "High": "1113.85",
      "Low": "1089",
      "Close": "1094.3"
  },
  {
      "Date": "4/26/02",
      "Open": "1094.25",
      "High": "1103.35",
      "Low": "1094.1",
      "Close": "1097.4"
  },
  {
      "Date": "4/29/02",
      "Open": "1097.55",
      "High": "1098.65",
      "Low": "1073.3",
      "Close": "1074.2"
  },
  {
      "Date": "4/30/02",
      "Open": "1074.25",
      "High": "1085.75",
      "Low": "1073.4",
      "Close": "1084.5"
  },
  {
      "Date": "5/2/02",
      "Open": "1084.8",
      "High": "1101.1",
      "Low": "1084.8",
      "Close": "1093.3"
  },
  {
      "Date": "5/3/02",
      "Open": "1093.4",
      "High": "1098.6",
      "Low": "1091.5",
      "Close": "1096.9"
  },
  {
      "Date": "5/6/02",
      "Open": "1096.95",
      "High": "1102.05",
      "Low": "1096.25",
      "Close": "1100.9"
  },
  {
      "Date": "5/7/02",
      "Open": "1100.9",
      "High": "1111.85",
      "Low": "1096.65",
      "Close": "1110.7"
  },
  {
      "Date": "5/8/02",
      "Open": "1110.7",
      "High": "1122.7",
      "Low": "1110.7",
      "Close": "1117.6"
  },
  {
      "Date": "5/9/02",
      "Open": "1117.7",
      "High": "1136.55",
      "Low": "1117.7",
      "Close": "1127.6"
  },
  {
      "Date": "5/10/02",
      "Open": "1127.05",
      "High": "1127.3",
      "Low": "1111.15",
      "Close": "1116.4"
  },
  {
      "Date": "5/13/02",
      "Open": "1116.8",
      "High": "1121.95",
      "Low": "1114.2",
      "Close": "1119.6"
  },
  {
      "Date": "5/14/02",
      "Open": "1119.95",
      "High": "1125.95",
      "Low": "1111.45",
      "Close": "1115.1"
  },
  {
      "Date": "5/15/02",
      "Open": "1115.15",
      "High": "1121.5",
      "Low": "1102.65",
      "Close": "1107.8"
  },
  {
      "Date": "5/16/02",
      "Open": "1108.25",
      "High": "1111.25",
      "Low": "1089.85",
      "Close": "1092.8"
  },
  {
      "Date": "5/17/02",
      "Open": "1092.8",
      "High": "1099.75",
      "Low": "1079.95",
      "Close": "1090.6"
  },
  {
      "Date": "5/20/02",
      "Open": "1091.7",
      "High": "1097.95",
      "Low": "1071.35",
      "Close": "1074.3"
  },
  {
      "Date": "5/21/02",
      "Open": "1074.75",
      "High": "1074.85",
      "Low": "1028.25",
      "Close": "1049.2"
  },
  {
      "Date": "5/22/02",
      "Open": "1049.5",
      "High": "1050.9",
      "Low": "1035.5",
      "Close": "1045.3"
  },
  {
      "Date": "5/23/02",
      "Open": "1045.2",
      "High": "1045.35",
      "Low": "1024.3",
      "Close": "1026.7"
  },
  {
      "Date": "5/24/02",
      "Open": "1026.9",
      "High": "1069.2",
      "Low": "1026.9",
      "Close": "1067"
  },
  {
      "Date": "5/27/02",
      "Open": "1067",
      "High": "1071.5",
      "Low": "1054.1",
      "Close": "1062.7"
  },
  {
      "Date": "5/28/02",
      "Open": "1061.9",
      "High": "1062.3",
      "Low": "1034.1",
      "Close": "1038.2"
  },
  {
      "Date": "5/29/02",
      "Open": "1038.2",
      "High": "1047.95",
      "Low": "1026.5",
      "Close": "1041.6"
  },
  {
      "Date": "5/30/02",
      "Open": "1041.4",
      "High": "1049.95",
      "Low": "1030.1",
      "Close": "1032.1"
  },
  {
      "Date": "5/31/02",
      "Open": "1032.5",
      "High": "1037.7",
      "Low": "1020.1",
      "Close": "1028.8"
  },
  {
      "Date": "6/3/02",
      "Open": "1029.25",
      "High": "1047.25",
      "Low": "1029.25",
      "Close": "1039.7"
  },
  {
      "Date": "6/4/02",
      "Open": "1040.15",
      "High": "1047.4",
      "Low": "1036.55",
      "Close": "1045.4"
  },
  {
      "Date": "6/5/02",
      "Open": "1045.55",
      "High": "1067.5",
      "Low": "1045",
      "Close": "1064.2"
  },
  {
      "Date": "6/6/02",
      "Open": "1064.15",
      "High": "1072",
      "Low": "1055.5",
      "Close": "1064.3"
  },
  {
      "Date": "6/7/02",
      "Open": "1063.75",
      "High": "1063.75",
      "Low": "1044.35",
      "Close": "1048.8"
  },
  {
      "Date": "6/10/02",
      "Open": "1049.95",
      "High": "1072.5",
      "Low": "1049.95",
      "Close": "1069.9"
  },
  {
      "Date": "6/11/02",
      "Open": "1070.95",
      "High": "1099.5",
      "Low": "1070.55",
      "Close": "1097"
  },
  {
      "Date": "6/12/02",
      "Open": "1097.15",
      "High": "1100.45",
      "Low": "1088.5",
      "Close": "1092.8"
  },
  {
      "Date": "6/13/02",
      "Open": "1093",
      "High": "1102.05",
      "Low": "1080.8",
      "Close": "1082.8"
  },
  {
      "Date": "6/14/02",
      "Open": "1081.75",
      "High": "1087.6",
      "Low": "1079.45",
      "Close": "1085.7"
  },
  {
      "Date": "6/17/02",
      "Open": "1085.8",
      "High": "1097.55",
      "Low": "1085.7",
      "Close": "1088.9"
  },
  {
      "Date": "6/18/02",
      "Open": "1088.9",
      "High": "1097.4",
      "Low": "1073.75",
      "Close": "1074.9"
  },
  {
      "Date": "6/19/02",
      "Open": "1074.7",
      "High": "1079.2",
      "Low": "1056.45",
      "Close": "1062.9"
  },
  {
      "Date": "6/20/02",
      "Open": "1063.1",
      "High": "1074",
      "Low": "1056.25",
      "Close": "1070"
  },
  {
      "Date": "6/21/02",
      "Open": "1069.5",
      "High": "1069.8",
      "Low": "1058.95",
      "Close": "1062.5"
  },
  {
      "Date": "6/24/02",
      "Open": "1065.6",
      "High": "1065.6",
      "Low": "1054.45",
      "Close": "1061.8"
  },
  {
      "Date": "6/25/02",
      "Open": "1061.9",
      "High": "1063.3",
      "Low": "1048.55",
      "Close": "1055.4"
  },
  {
      "Date": "6/26/02",
      "Open": "1055.35",
      "High": "1061.05",
      "Low": "1042.2",
      "Close": "1044.2"
  },
  {
      "Date": "6/27/02",
      "Open": "1044.5",
      "High": "1053.7",
      "Low": "1044",
      "Close": "1048.5"
  },
  {
      "Date": "6/28/02",
      "Open": "1049.4",
      "High": "1068.9",
      "Low": "1049.4",
      "Close": "1057.8"
  },
  {
      "Date": "7/1/02",
      "Open": "1058",
      "High": "1070.15",
      "Low": "1058",
      "Close": "1068.9"
  },
  {
      "Date": "7/2/02",
      "Open": "1067.9",
      "High": "1071.7",
      "Low": "1064.95",
      "Close": "1068"
  },
  {
      "Date": "7/3/02",
      "Open": "1067.8",
      "High": "1074",
      "Low": "1065.55",
      "Close": "1069.9"
  },
  {
      "Date": "7/4/02",
      "Open": "1069.95",
      "High": "1078.55",
      "Low": "1068.4",
      "Close": "1070.5"
  },
  {
      "Date": "7/5/02",
      "Open": "1071",
      "High": "1076.2",
      "Low": "1067.45",
      "Close": "1073.8"
  },
  {
      "Date": "7/8/02",
      "Open": "1074",
      "High": "1085.3",
      "Low": "1072.9",
      "Close": "1082"
  },
  {
      "Date": "7/9/02",
      "Open": "1081.9",
      "High": "1083.65",
      "Low": "1077.55",
      "Close": "1080.3"
  },
  {
      "Date": "7/10/02",
      "Open": "1081.55",
      "High": "1087.4",
      "Low": "1069.45",
      "Close": "1071.7"
  },
  {
      "Date": "7/11/02",
      "Open": "1070.95",
      "High": "1070.95",
      "Low": "1055.3",
      "Close": "1056.6"
  },
  {
      "Date": "7/12/02",
      "Open": "1057.1",
      "High": "1064.9",
      "Low": "1054.9",
      "Close": "1058.2"
  },
  {
      "Date": "7/15/02",
      "Open": "1057.95",
      "High": "1058.45",
      "Low": "1043.3",
      "Close": "1048"
  },
  {
      "Date": "7/16/02",
      "Open": "1047.9",
      "High": "1051.85",
      "Low": "1033.95",
      "Close": "1035.9"
  },
  {
      "Date": "7/17/02",
      "Open": "1035.95",
      "High": "1037.5",
      "Low": "1025",
      "Close": "1032.5"
  },
  {
      "Date": "7/18/02",
      "Open": "1032.55",
      "High": "1044",
      "Low": "1032.55",
      "Close": "1041.3"
  },
  {
      "Date": "7/19/02",
      "Open": "1041.35",
      "High": "1041.5",
      "Low": "1032.25",
      "Close": "1035.9"
  },
  {
      "Date": "7/22/02",
      "Open": "1035.2",
      "High": "1035.2",
      "Low": "1009",
      "Close": "1012"
  },
  {
      "Date": "7/23/02",
      "Open": "1012.05",
      "High": "1023.65",
      "Low": "1005.75",
      "Close": "1021.9"
  },
  {
      "Date": "7/24/02",
      "Open": "1021.8",
      "High": "1022.2",
      "Low": "1001.9",
      "Close": "1004"
  },
  {
      "Date": "7/25/02",
      "Open": "1004.2",
      "High": "1021.3",
      "Low": "997.85",
      "Close": "1001.5"
  },
  {
      "Date": "7/26/02",
      "Open": "1001.05",
      "High": "1001.05",
      "Low": "972.55",
      "Close": "973.5"
  },
  {
      "Date": "7/29/02",
      "Open": "973",
      "High": "977.95",
      "Low": "953.15",
      "Close": "971.65"
  },
  {
      "Date": "7/30/02",
      "Open": "973",
      "High": "987.5",
      "Low": "956.8",
      "Close": "960.65"
  },
  {
      "Date": "7/31/02",
      "Open": "960.3",
      "High": "964.1",
      "Low": "943.6",
      "Close": "958.9"
  },
  {
      "Date": "8/1/02",
      "Open": "959.2",
      "High": "972.05",
      "Low": "954.15",
      "Close": "957.7"
  },
  {
      "Date": "8/2/02",
      "Open": "957.55",
      "High": "957.55",
      "Low": "935.55",
      "Close": "954.75"
  },
  {
      "Date": "8/5/02",
      "Open": "954.9",
      "High": "966.5",
      "Low": "953.9",
      "Close": "963.25"
  },
  {
      "Date": "8/6/02",
      "Open": "963.3",
      "High": "969.55",
      "Low": "955.4",
      "Close": "966.65"
  },
  {
      "Date": "8/7/02",
      "Open": "967.7",
      "High": "978.6",
      "Low": "966.55",
      "Close": "969.1"
  },
  {
      "Date": "8/8/02",
      "Open": "969.15",
      "High": "971.75",
      "Low": "951.2",
      "Close": "953.55"
  },
  {
      "Date": "8/9/02",
      "Open": "953.8",
      "High": "963.85",
      "Low": "948",
      "Close": "961.95"
  },
  {
      "Date": "8/12/02",
      "Open": "961.15",
      "High": "971.55",
      "Low": "961.15",
      "Close": "969.85"
  },
  {
      "Date": "8/13/02",
      "Open": "970.4",
      "High": "977.2",
      "Low": "969.9",
      "Close": "976.05"
  },
  {
      "Date": "8/14/02",
      "Open": "976.05",
      "High": "976.25",
      "Low": "967.25",
      "Close": "969.65"
  },
  {
      "Date": "8/16/02",
      "Open": "970.75",
      "High": "981.2",
      "Low": "968.35",
      "Close": "979.25"
  },
  {
      "Date": "8/19/02",
      "Open": "979.4",
      "High": "983.55",
      "Low": "978.2",
      "Close": "979.85"
  },
  {
      "Date": "8/20/02",
      "Open": "979.75",
      "High": "990.85",
      "Low": "979.05",
      "Close": "988.55"
  },
  {
      "Date": "8/21/02",
      "Open": "988.6",
      "High": "994.25",
      "Low": "984.35",
      "Close": "988.45"
  },
  {
      "Date": "8/22/02",
      "Open": "988.55",
      "High": "995.3",
      "Low": "983.45",
      "Close": "985.7"
  },
  {
      "Date": "8/23/02",
      "Open": "985.75",
      "High": "996.4",
      "Low": "984.7",
      "Close": "995.2"
  },
  {
      "Date": "8/26/02",
      "Open": "992.4",
      "High": "1001.45",
      "Low": "986.15",
      "Close": "998.85"
  },
  {
      "Date": "8/27/02",
      "Open": "998.85",
      "High": "1003.3",
      "Low": "985.7",
      "Close": "987.7"
  },
  {
      "Date": "8/28/02",
      "Open": "987.35",
      "High": "988.1",
      "Low": "981.1",
      "Close": "985.7"
  },
  {
      "Date": "8/29/02",
      "Open": "985.85",
      "High": "989.4",
      "Low": "981.45",
      "Close": "987.25"
  },
  {
      "Date": "8/30/02",
      "Open": "987.65",
      "High": "1012.75",
      "Low": "986.7",
      "Close": "1010.6"
  },
  {
      "Date": "9/2/02",
      "Open": "1010.9",
      "High": "1024.65",
      "Low": "1010.85",
      "Close": "1013.5"
  },
  {
      "Date": "9/3/02",
      "Open": "1013.65",
      "High": "1014.8",
      "Low": "999.4",
      "Close": "1001.1"
  },
  {
      "Date": "9/4/02",
      "Open": "1000.7",
      "High": "1008.8",
      "Low": "990.85",
      "Close": "1006.9"
  },
  {
      "Date": "9/5/02",
      "Open": "1006.9",
      "High": "1013.45",
      "Low": "1004.2",
      "Close": "1008.6"
  },
  {
      "Date": "9/6/02",
      "Open": "1008.45",
      "High": "1008.45",
      "Low": "992.7",
      "Close": "995.2"
  },
  {
      "Date": "9/9/02",
      "Open": "992.05",
      "High": "1005.85",
      "Low": "985.25",
      "Close": "998.55"
  },
  {
      "Date": "9/11/02",
      "Open": "998.6",
      "High": "1002.35",
      "Low": "993.35",
      "Close": "998.85"
  },
  {
      "Date": "9/12/02",
      "Open": "998.95",
      "High": "1005.15",
      "Low": "998.8",
      "Close": "1001.6"
  },
  {
      "Date": "9/13/02",
      "Open": "1001.4",
      "High": "1001.4",
      "Low": "989.85",
      "Close": "992"
  },
  {
      "Date": "9/16/02",
      "Open": "991.75",
      "High": "997",
      "Low": "983.55",
      "Close": "985.75"
  },
  {
      "Date": "9/17/02",
      "Open": "985.75",
      "High": "996.8",
      "Low": "985.75",
      "Close": "994.9"
  },
  {
      "Date": "9/18/02",
      "Open": "994.15",
      "High": "994.15",
      "Low": "981.6",
      "Close": "983.6"
  },
  {
      "Date": "9/19/02",
      "Open": "983.45",
      "High": "985.25",
      "Low": "970.8",
      "Close": "976.05"
  },
  {
      "Date": "9/20/02",
      "Open": "975.6",
      "High": "975.6",
      "Low": "960.7",
      "Close": "969.6"
  },
  {
      "Date": "9/23/02",
      "Open": "969.85",
      "High": "974.75",
      "Low": "967.35",
      "Close": "970.3"
  },
  {
      "Date": "9/24/02",
      "Open": "969.95",
      "High": "969.95",
      "Low": "960.2",
      "Close": "966.2"
  },
  {
      "Date": "9/25/02",
      "Open": "964.65",
      "High": "971.85",
      "Low": "960.5",
      "Close": "970.05"
  },
  {
      "Date": "9/26/02",
      "Open": "970.1",
      "High": "976.25",
      "Low": "968.25",
      "Close": "969.9"
  },
  {
      "Date": "9/27/02",
      "Open": "969.95",
      "High": "978",
      "Low": "969.25",
      "Close": "976.45"
  },
  {
      "Date": "9/30/02",
      "Open": "976.15",
      "High": "976.15",
      "Low": "961.55",
      "Close": "963.15"
  },
  {
      "Date": "10/1/02",
      "Open": "961.15",
      "High": "964.2",
      "Low": "950.3",
      "Close": "955.2"
  },
  {
      "Date": "10/3/02",
      "Open": "955.35",
      "High": "957.9",
      "Low": "945.8",
      "Close": "948.2"
  },
  {
      "Date": "10/4/02",
      "Open": "948.1",
      "High": "951.8",
      "Low": "944.5",
      "Close": "948.2"
  },
  {
      "Date": "10/7/02",
      "Open": "947.8",
      "High": "956.35",
      "Low": "946.25",
      "Close": "954.75"
  },
  {
      "Date": "10/8/02",
      "Open": "954.8",
      "High": "963.15",
      "Low": "952.5",
      "Close": "960.8"
  },
  {
      "Date": "10/9/02",
      "Open": "960.85",
      "High": "968.75",
      "Low": "952.95",
      "Close": "954.75"
  },
  {
      "Date": "10/10/02",
      "Open": "954.8",
      "High": "960.45",
      "Low": "945",
      "Close": "958.45"
  },
  {
      "Date": "10/11/02",
      "Open": "958.45",
      "High": "973.5",
      "Low": "958.45",
      "Close": "971.05"
  },
  {
      "Date": "10/14/02",
      "Open": "971.5",
      "High": "976.5",
      "Low": "969",
      "Close": "972.45"
  },
  {
      "Date": "10/16/02",
      "Open": "972.9",
      "High": "983.6",
      "Low": "971.7",
      "Close": "973.6"
  },
  {
      "Date": "10/17/02",
      "Open": "973.7",
      "High": "976.05",
      "Low": "966.55",
      "Close": "973.3"
  },
  {
      "Date": "10/18/02",
      "Open": "973.8",
      "High": "982.5",
      "Low": "968.8",
      "Close": "971.65"
  },
  {
      "Date": "10/21/02",
      "Open": "971.75",
      "High": "976.2",
      "Low": "965.65",
      "Close": "967.35"
  },
  {
      "Date": "10/22/02",
      "Open": "967.5",
      "High": "976.35",
      "Low": "961.65",
      "Close": "962.5"
  },
  {
      "Date": "10/23/02",
      "Open": "962.9",
      "High": "963.75",
      "Low": "955.45",
      "Close": "957.35"
  },
  {
      "Date": "10/24/02",
      "Open": "957.7",
      "High": "959.8",
      "Low": "945",
      "Close": "946.9"
  },
  {
      "Date": "10/25/02",
      "Open": "946.8",
      "High": "946.8",
      "Low": "929.25",
      "Close": "932.2"
  },
  {
      "Date": "10/28/02",
      "Open": "932.35",
      "High": "934.9",
      "Low": "920.1",
      "Close": "922.7"
  },
  {
      "Date": "10/29/02",
      "Open": "922.55",
      "High": "939.7",
      "Low": "921.65",
      "Close": "936.9"
  },
  {
      "Date": "10/30/02",
      "Open": "936.15",
      "High": "944.35",
      "Low": "935.9",
      "Close": "937.75"
  },
  {
      "Date": "10/31/02",
      "Open": "937.35",
      "High": "953.3",
      "Low": "937.05",
      "Close": "951.4"
  },
  {
      "Date": "11/1/02",
      "Open": "951.45",
      "High": "956.95",
      "Low": "946.4",
      "Close": "951.45"
  },
  {
      "Date": "11/4/02",
      "Open": "951.6",
      "High": "965.5",
      "Low": "951.6",
      "Close": "962.1"
  },
  {
      "Date": "11/5/02",
      "Open": "962.85",
      "High": "963.7",
      "Low": "957.2",
      "Close": "962.3"
  },
  {
      "Date": "11/7/02",
      "Open": "962.35",
      "High": "970.2",
      "Low": "957.95",
      "Close": "960.7"
  },
  {
      "Date": "11/8/02",
      "Open": "960.5",
      "High": "960.95",
      "Low": "954.35",
      "Close": "956.95"
  },
  {
      "Date": "11/11/02",
      "Open": "957.55",
      "High": "958.55",
      "Low": "952.1",
      "Close": "954.05"
  },
  {
      "Date": "11/12/02",
      "Open": "953.9",
      "High": "960.85",
      "Low": "948.05",
      "Close": "959.85"
  },
  {
      "Date": "11/13/02",
      "Open": "960.35",
      "High": "965.75",
      "Low": "956.9",
      "Close": "962.65"
  },
  {
      "Date": "11/14/02",
      "Open": "964.15",
      "High": "974.1",
      "Low": "963.5",
      "Close": "971.9"
  },
  {
      "Date": "11/15/02",
      "Open": "972.3",
      "High": "992.05",
      "Low": "972.3",
      "Close": "990.35"
  },
  {
      "Date": "11/18/02",
      "Open": "990.35",
      "High": "1000.3",
      "Low": "989.05",
      "Close": "996.85"
  },
  {
      "Date": "11/20/02",
      "Open": "996.85",
      "High": "1007.2",
      "Low": "992.3",
      "Close": "1001.6"
  },
  {
      "Date": "11/21/02",
      "Open": "1001.8",
      "High": "1012.75",
      "Low": "1001.8",
      "Close": "1008.7"
  },
  {
      "Date": "11/22/02",
      "Open": "1009.5",
      "High": "1026",
      "Low": "1009.5",
      "Close": "1020.1"
  },
  {
      "Date": "11/25/02",
      "Open": "1020.9",
      "High": "1030.35",
      "Low": "1017.5",
      "Close": "1026.2"
  },
  {
      "Date": "11/26/02",
      "Open": "1026.4",
      "High": "1040.45",
      "Low": "1026",
      "Close": "1036.1"
  },
  {
      "Date": "11/27/02",
      "Open": "1036.35",
      "High": "1042.2",
      "Low": "1027.7",
      "Close": "1031.1"
  },
  {
      "Date": "11/28/02",
      "Open": "1031.25",
      "High": "1051.75",
      "Low": "1031.25",
      "Close": "1049.7"
  },
  {
      "Date": "11/29/02",
      "Open": "1049.55",
      "High": "1057.45",
      "Low": "1040.95",
      "Close": "1050.1"
  },
  {
      "Date": "12/2/02",
      "Open": "1050.15",
      "High": "1070.15",
      "Low": "1050.15",
      "Close": "1067.9"
  },
  {
      "Date": "12/3/02",
      "Open": "1067.7",
      "High": "1075.75",
      "Low": "1051.8",
      "Close": "1055"
  },
  {
      "Date": "12/4/02",
      "Open": "1055.75",
      "High": "1055.75",
      "Low": "1034.1",
      "Close": "1036.4"
  },
  {
      "Date": "12/5/02",
      "Open": "1036.75",
      "High": "1052.45",
      "Low": "1036.75",
      "Close": "1045.9"
  },
  {
      "Date": "12/6/02",
      "Open": "1047.55",
      "High": "1072.25",
      "Low": "1047.55",
      "Close": "1069.8"
  },
  {
      "Date": "12/9/02",
      "Open": "1070.5",
      "High": "1085.75",
      "Low": "1054.95",
      "Close": "1058.6"
  },
  {
      "Date": "12/10/02",
      "Open": "1058.35",
      "High": "1066.1",
      "Low": "1045.65",
      "Close": "1063.7"
  },
  {
      "Date": "12/11/02",
      "Open": "1064.1",
      "High": "1076.95",
      "Low": "1062.3",
      "Close": "1069.7"
  },
  {
      "Date": "12/12/02",
      "Open": "1069.95",
      "High": "1078.85",
      "Low": "1066.8",
      "Close": "1077"
  },
  {
      "Date": "12/13/02",
      "Open": "1074.45",
      "High": "1087.35",
      "Low": "1074.45",
      "Close": "1086.2"
  },
  {
      "Date": "12/16/02",
      "Open": "1089",
      "High": "1100",
      "Low": "1075.55",
      "Close": "1078.4"
  },
  {
      "Date": "12/17/02",
      "Open": "1078.7",
      "High": "1084.95",
      "Low": "1069",
      "Close": "1073.2"
  },
  {
      "Date": "12/18/02",
      "Open": "1073.2",
      "High": "1083.65",
      "Low": "1072.85",
      "Close": "1077.9"
  },
  {
      "Date": "12/19/02",
      "Open": "1077.85",
      "High": "1079.9",
      "Low": "1070.5",
      "Close": "1076"
  },
  {
      "Date": "12/20/02",
      "Open": "1075.45",
      "High": "1084.95",
      "Low": "1075.4",
      "Close": "1079.3"
  },
  {
      "Date": "12/23/02",
      "Open": "1079.3",
      "High": "1082.6",
      "Low": "1073.35",
      "Close": "1076"
  },
  {
      "Date": "12/24/02",
      "Open": "1075.95",
      "High": "1087",
      "Low": "1072.65",
      "Close": "1085"
  },
  {
      "Date": "12/26/02",
      "Open": "1085.05",
      "High": "1100.4",
      "Low": "1085.05",
      "Close": "1094.8"
  },
  {
      "Date": "12/27/02",
      "Open": "1095.65",
      "High": "1103.95",
      "Low": "1095.6",
      "Close": "1098.4"
  },
  {
      "Date": "12/30/02",
      "Open": "1095.8",
      "High": "1095.8",
      "Low": "1084.35",
      "Close": "1091.9"
  },
  {
      "Date": "12/31/02",
      "Open": "1091.85",
      "High": "1100.1",
      "Low": "1091.7",
      "Close": "1093.5"
  },
  {
      "Date": "1/1/03",
      "Open": "1093.6",
      "High": "1102.1",
      "Low": "1093.6",
      "Close": "1100.1"
  },
  {
      "Date": "1/2/03",
      "Open": "1100.55",
      "High": "1105.6",
      "Low": "1091.2",
      "Close": "1093"
  },
  {
      "Date": "1/3/03",
      "Open": "1094.45",
      "High": "1099.85",
      "Low": "1087.3",
      "Close": "1089.6"
  },
  {
      "Date": "1/6/03",
      "Open": "1089.75",
      "High": "1093.05",
      "Low": "1081.25",
      "Close": "1084.3"
  },
  {
      "Date": "1/7/03",
      "Open": "1084.4",
      "High": "1089.85",
      "Low": "1078.95",
      "Close": "1081.8"
  },
  {
      "Date": "1/8/03",
      "Open": "1082.4",
      "High": "1091.45",
      "Low": "1082.3",
      "Close": "1089.3"
  },
  {
      "Date": "1/9/03",
      "Open": "1089.75",
      "High": "1099.15",
      "Low": "1087.75",
      "Close": "1097.3"
  },
  {
      "Date": "1/10/03",
      "Open": "1097.6",
      "High": "1103.25",
      "Low": "1077.4",
      "Close": "1080.2"
  },
  {
      "Date": "1/13/03",
      "Open": "1080.25",
      "High": "1080.4",
      "Low": "1070.75",
      "Close": "1073.7"
  },
  {
      "Date": "1/14/03",
      "Open": "1072.7",
      "High": "1080.8",
      "Low": "1070.3",
      "Close": "1078.9"
  },
  {
      "Date": "1/15/03",
      "Open": "1077.9",
      "High": "1087.5",
      "Low": "1077.8",
      "Close": "1085"
  },
  {
      "Date": "1/16/03",
      "Open": "1085.05",
      "High": "1091.35",
      "Low": "1083.95",
      "Close": "1088.3"
  },
  {
      "Date": "1/17/03",
      "Open": "1089.3",
      "High": "1090.25",
      "Low": "1083.2",
      "Close": "1086.5"
  },
  {
      "Date": "1/20/03",
      "Open": "1086.25",
      "High": "1087",
      "Low": "1074.2",
      "Close": "1076.3"
  },
  {
      "Date": "1/21/03",
      "Open": "1076.3",
      "High": "1080.5",
      "Low": "1074.1",
      "Close": "1077.9"
  },
  {
      "Date": "1/22/03",
      "Open": "1078.45",
      "High": "1086.2",
      "Low": "1073.6",
      "Close": "1082.9"
  },
  {
      "Date": "1/23/03",
      "Open": "1081.4",
      "High": "1083.15",
      "Low": "1069.05",
      "Close": "1070.9"
  },
  {
      "Date": "1/24/03",
      "Open": "1070.6",
      "High": "1075.2",
      "Low": "1053.4",
      "Close": "1056"
  },
  {
      "Date": "1/27/03",
      "Open": "1057.8",
      "High": "1059.35",
      "Low": "1030.35",
      "Close": "1037.6"
  },
  {
      "Date": "1/28/03",
      "Open": "1036.35",
      "High": "1047.95",
      "Low": "1026.75",
      "Close": "1046.2"
  },
  {
      "Date": "1/29/03",
      "Open": "1059.8",
      "High": "1059.8",
      "Low": "1033.3",
      "Close": "1037.2"
  },
  {
      "Date": "1/30/03",
      "Open": "1037.15",
      "High": "1050.55",
      "Low": "1031.35",
      "Close": "1034.6"
  },
  {
      "Date": "1/31/03",
      "Open": "1034.75",
      "High": "1044.25",
      "Low": "1026.2",
      "Close": "1041.8"
  },
  {
      "Date": "2/3/03",
      "Open": "1040.25",
      "High": "1056.6",
      "Low": "1036.5",
      "Close": "1055.3"
  },
  {
      "Date": "2/4/03",
      "Open": "1056.55",
      "High": "1063.7",
      "Low": "1050.45",
      "Close": "1054.8"
  },
  {
      "Date": "2/5/03",
      "Open": "1054.3",
      "High": "1054.65",
      "Low": "1041.75",
      "Close": "1047.4"
  },
  {
      "Date": "2/6/03",
      "Open": "1047.25",
      "High": "1065.95",
      "Low": "1043.7",
      "Close": "1063.6"
  },
  {
      "Date": "2/7/03",
      "Open": "1063.75",
      "High": "1065.8",
      "Low": "1055.5",
      "Close": "1057.5"
  },
  {
      "Date": "2/10/03",
      "Open": "1058.2",
      "High": "1058.75",
      "Low": "1047.15",
      "Close": "1048.6"
  },
  {
      "Date": "2/11/03",
      "Open": "1048.6",
      "High": "1055.8",
      "Low": "1044.55",
      "Close": "1048"
  },
  {
      "Date": "2/12/03",
      "Open": "1048.15",
      "High": "1051",
      "Low": "1040.4",
      "Close": "1044.4"
  },
  {
      "Date": "2/14/03",
      "Open": "1044.45",
      "High": "1047.05",
      "Low": "1034.1",
      "Close": "1036"
  },
  {
      "Date": "2/17/03",
      "Open": "1036.2",
      "High": "1060.5",
      "Low": "1036.2",
      "Close": "1058.2"
  },
  {
      "Date": "2/18/03",
      "Open": "1058.95",
      "High": "1065.15",
      "Low": "1057.6",
      "Close": "1059.3"
  },
  {
      "Date": "2/19/03",
      "Open": "1059.3",
      "High": "1068.8",
      "Low": "1059.3",
      "Close": "1064.3"
  },
  {
      "Date": "2/20/03",
      "Open": "1064.15",
      "High": "1071.7",
      "Low": "1058.35",
      "Close": "1065.6"
  },
  {
      "Date": "2/21/03",
      "Open": "1065.6",
      "High": "1069.2",
      "Low": "1061.1",
      "Close": "1066.1"
  },
  {
      "Date": "2/24/03",
      "Open": "1066.2",
      "High": "1075.5",
      "Low": "1066.2",
      "Close": "1070.1"
  },
  {
      "Date": "2/25/03",
      "Open": "1070.1",
      "High": "1073.25",
      "Low": "1054.9",
      "Close": "1055.5"
  },
  {
      "Date": "2/26/03",
      "Open": "1055.6",
      "High": "1059.6",
      "Low": "1048.2",
      "Close": "1049.6"
  },
  {
      "Date": "2/27/03",
      "Open": "1049.6",
      "High": "1057.45",
      "Low": "1044.9",
      "Close": "1052.9"
  },
  {
      "Date": "2/28/03",
      "Open": "1053.65",
      "High": "1068.65",
      "Low": "1053.65",
      "Close": "1063.4"
  },
  {
      "Date": "3/3/03",
      "Open": "1063.55",
      "High": "1070.85",
      "Low": "1057.55",
      "Close": "1058.8"
  },
  {
      "Date": "3/4/03",
      "Open": "1058.7",
      "High": "1058.7",
      "Low": "1045.2",
      "Close": "1046.6"
  },
  {
      "Date": "3/5/03",
      "Open": "1046.55",
      "High": "1046.55",
      "Low": "1029.9",
      "Close": "1040.7"
  },
  {
      "Date": "3/6/03",
      "Open": "1040.3",
      "High": "1040.75",
      "Low": "1029.55",
      "Close": "1031.2"
  },
  {
      "Date": "3/7/03",
      "Open": "1031.05",
      "High": "1031.05",
      "Low": "1014.3",
      "Close": "1017.1"
  },
  {
      "Date": "3/10/03",
      "Open": "1017.1",
      "High": "1021.5",
      "Low": "1004.35",
      "Close": "1006.7"
  },
  {
      "Date": "3/11/03",
      "Open": "1006.65",
      "High": "1016.7",
      "Low": "998.95",
      "Close": "1014.5"
  },
  {
      "Date": "3/12/03",
      "Open": "1014.25",
      "High": "1017.75",
      "Low": "1000.05",
      "Close": "1001.7"
  },
  {
      "Date": "3/13/03",
      "Open": "1001.5",
      "High": "1006.4",
      "Low": "994.2",
      "Close": "999.65"
  },
  {
      "Date": "3/17/03",
      "Open": "999.7",
      "High": "1000.85",
      "Low": "982.7",
      "Close": "993"
  },
  {
      "Date": "3/19/03",
      "Open": "993.35",
      "High": "1011.45",
      "Low": "992.9",
      "Close": "1003.9"
  },
  {
      "Date": "3/20/03",
      "Open": "1003.45",
      "High": "1028.9",
      "Low": "1001.75",
      "Close": "1025.2"
  },
  {
      "Date": "3/21/03",
      "Open": "1025.55",
      "High": "1033.7",
      "Low": "1022.05",
      "Close": "1030.5"
  },
  {
      "Date": "3/22/03",
      "Open": "1030.6",
      "High": "1039.8",
      "Low": "1030.6",
      "Close": "1037.1"
  },
  {
      "Date": "3/24/03",
      "Open": "1037.15",
      "High": "1038.55",
      "Low": "1011.95",
      "Close": "1013.9"
  },
  {
      "Date": "3/25/03",
      "Open": "1013.5",
      "High": "1013.5",
      "Low": "998.4",
      "Close": "1011.3"
  },
  {
      "Date": "3/26/03",
      "Open": "1011.55",
      "High": "1019.9",
      "Low": "1004.85",
      "Close": "1013.8"
  },
  {
      "Date": "3/27/03",
      "Open": "1013.9",
      "High": "1013.9",
      "Low": "999.6",
      "Close": "1002.7"
  },
  {
      "Date": "3/28/03",
      "Open": "1002.8",
      "High": "1006.3",
      "Low": "996.75",
      "Close": "1000.6"
  },
  {
      "Date": "3/31/03",
      "Open": "1000.6",
      "High": "1000.6",
      "Low": "974.1",
      "Close": "978.2"
  },
  {
      "Date": "4/1/03",
      "Open": "977.4",
      "High": "992.05",
      "Low": "973.5",
      "Close": "984.3"
  },
  {
      "Date": "4/2/03",
      "Open": "984.45",
      "High": "1002.1",
      "Low": "984.45",
      "Close": "999.4"
  },
  {
      "Date": "4/3/03",
      "Open": "999.55",
      "High": "1010.75",
      "Low": "999.55",
      "Close": "1009.1"
  },
  {
      "Date": "4/4/03",
      "Open": "1009.15",
      "High": "1018.25",
      "Low": "1002.75",
      "Close": "1016.9"
  },
  {
      "Date": "4/7/03",
      "Open": "1017.05",
      "High": "1033.45",
      "Low": "1017.05",
      "Close": "1031.5"
  },
  {
      "Date": "4/8/03",
      "Open": "1032",
      "High": "1032",
      "Low": "1016.6",
      "Close": "1018.1"
  },
  {
      "Date": "4/9/03",
      "Open": "1018",
      "High": "1018",
      "Low": "1002.3",
      "Close": "1004.8"
  },
  {
      "Date": "4/10/03",
      "Open": "1003.8",
      "High": "1003.8",
      "Low": "958.2",
      "Close": "962.2"
  },
  {
      "Date": "4/11/03",
      "Open": "958.9",
      "High": "965.15",
      "Low": "935.7",
      "Close": "949.8"
  },
  {
      "Date": "4/15/03",
      "Open": "951.8",
      "High": "964.2",
      "Low": "938.8",
      "Close": "951.2"
  },
  {
      "Date": "4/16/03",
      "Open": "950.65",
      "High": "961.75",
      "Low": "949",
      "Close": "958.65"
  },
  {
      "Date": "4/17/03",
      "Open": "958.65",
      "High": "958.65",
      "Low": "936.7",
      "Close": "940.7"
  },
  {
      "Date": "4/21/03",
      "Open": "941.5",
      "High": "949.7",
      "Low": "941.5",
      "Close": "947.2"
  },
  {
      "Date": "4/22/03",
      "Open": "947.1",
      "High": "951.4",
      "Low": "937.85",
      "Close": "943.5"
  },
  {
      "Date": "4/23/03",
      "Open": "943.75",
      "High": "951.2",
      "Low": "931.5",
      "Close": "934.2"
  },
  {
      "Date": "4/24/03",
      "Open": "934.2",
      "High": "943.15",
      "Low": "927.8",
      "Close": "929.7"
  },
  {
      "Date": "4/25/03",
      "Open": "929.45",
      "High": "931.35",
      "Low": "921.1",
      "Close": "924.3"
  },
  {
      "Date": "4/28/03",
      "Open": "922.85",
      "High": "931.05",
      "Low": "920",
      "Close": "929.5"
  },
  {
      "Date": "4/29/03",
      "Open": "929.75",
      "High": "936.9",
      "Low": "929.75",
      "Close": "932.3"
  },
  {
      "Date": "4/30/03",
      "Open": "935.25",
      "High": "935.55",
      "Low": "929.85",
      "Close": "934.05"
  },
  {
      "Date": "5/2/03",
      "Open": "930.9",
      "High": "940.2",
      "Low": "930.8",
      "Close": "938.3"
  },
  {
      "Date": "5/5/03",
      "Open": "937.45",
      "High": "948.95",
      "Low": "936.65",
      "Close": "945.4"
  },
  {
      "Date": "5/6/03",
      "Open": "945.85",
      "High": "955",
      "Low": "943.2",
      "Close": "951.85"
  },
  {
      "Date": "5/7/03",
      "Open": "950.4",
      "High": "956.65",
      "Low": "948.9",
      "Close": "950.15"
  },
  {
      "Date": "5/8/03",
      "Open": "950.15",
      "High": "951.3",
      "Low": "938.7",
      "Close": "941.55"
  },
  {
      "Date": "5/9/03",
      "Open": "941.6",
      "High": "941.65",
      "Low": "935.8",
      "Close": "937.85"
  },
  {
      "Date": "5/12/03",
      "Open": "938.5",
      "High": "944.45",
      "Low": "934",
      "Close": "936"
  },
  {
      "Date": "5/13/03",
      "Open": "936.9",
      "High": "945.8",
      "Low": "936.7",
      "Close": "944.2"
  },
  {
      "Date": "5/14/03",
      "Open": "944.2",
      "High": "953.45",
      "Low": "943.15",
      "Close": "952.15"
  },
  {
      "Date": "5/15/03",
      "Open": "952.15",
      "High": "961.6",
      "Low": "951.15",
      "Close": "959.85"
  },
  {
      "Date": "5/16/03",
      "Open": "959.85",
      "High": "974.4",
      "Low": "959.85",
      "Close": "973.1"
  },
  {
      "Date": "5/19/03",
      "Open": "973.7",
      "High": "979.85",
      "Low": "964.6",
      "Close": "966.55"
  },
  {
      "Date": "5/20/03",
      "Open": "964.65",
      "High": "974.05",
      "Low": "959.8",
      "Close": "971.55"
  },
  {
      "Date": "5/21/03",
      "Open": "972.05",
      "High": "980.75",
      "Low": "965.55",
      "Close": "968"
  },
  {
      "Date": "5/22/03",
      "Open": "967.95",
      "High": "972.4",
      "Low": "960.05",
      "Close": "963.25"
  },
  {
      "Date": "5/23/03",
      "Open": "963.5",
      "High": "972.9",
      "Low": "962.4",
      "Close": "967.9"
  },
  {
      "Date": "5/26/03",
      "Open": "967.85",
      "High": "983.9",
      "Low": "967.1",
      "Close": "982.45"
  },
  {
      "Date": "5/27/03",
      "Open": "982.35",
      "High": "990",
      "Low": "974.25",
      "Close": "976.85"
  },
  {
      "Date": "5/28/03",
      "Open": "977.65",
      "High": "992.25",
      "Low": "977.55",
      "Close": "990.8"
  },
  {
      "Date": "5/29/03",
      "Open": "990.8",
      "High": "1004.85",
      "Low": "989.5",
      "Close": "1002.6"
  },
  {
      "Date": "5/30/03",
      "Open": "1000.05",
      "High": "1013.85",
      "Low": "994.65",
      "Close": "1006.8"
  },
  {
      "Date": "6/2/03",
      "Open": "1006.85",
      "High": "1020.5",
      "Low": "1004.7",
      "Close": "1015.1"
  },
  {
      "Date": "6/3/03",
      "Open": "1015.9",
      "High": "1016.9",
      "Low": "1007.65",
      "Close": "1010.6"
  },
  {
      "Date": "6/4/03",
      "Open": "1010.7",
      "High": "1022.75",
      "Low": "1010.1",
      "Close": "1021"
  },
  {
      "Date": "6/5/03",
      "Open": "1021.1",
      "High": "1038.3",
      "Low": "1021.1",
      "Close": "1035"
  },
  {
      "Date": "6/6/03",
      "Open": "1036.2",
      "High": "1048.85",
      "Low": "1035.95",
      "Close": "1046.4"
  },
  {
      "Date": "6/9/03",
      "Open": "1046",
      "High": "1057.05",
      "Low": "1039.85",
      "Close": "1052.1"
  },
  {
      "Date": "6/10/03",
      "Open": "1052",
      "High": "1056.55",
      "Low": "1033.95",
      "Close": "1037.8"
  },
  {
      "Date": "6/11/03",
      "Open": "1038",
      "High": "1048.25",
      "Low": "1035",
      "Close": "1044.1"
  },
  {
      "Date": "6/12/03",
      "Open": "1044.2",
      "High": "1052.6",
      "Low": "1043.1",
      "Close": "1051.3"
  },
  {
      "Date": "6/13/03",
      "Open": "1050.95",
      "High": "1059.45",
      "Low": "1050.95",
      "Close": "1056.2"
  },
  {
      "Date": "6/16/03",
      "Open": "1056.5",
      "High": "1059.8",
      "Low": "1045.55",
      "Close": "1051.8"
  },
  {
      "Date": "6/17/03",
      "Open": "1052.3",
      "High": "1083.2",
      "Low": "1052.3",
      "Close": "1081.9"
  },
  {
      "Date": "6/18/03",
      "Open": "1082.8",
      "High": "1093.2",
      "Low": "1080.9",
      "Close": "1086.7"
  },
  {
      "Date": "6/19/03",
      "Open": "1085.95",
      "High": "1097",
      "Low": "1078.9",
      "Close": "1092.5"
  },
  {
      "Date": "6/20/03",
      "Open": "1092.65",
      "High": "1101.6",
      "Low": "1081.35",
      "Close": "1100.2"
  },
  {
      "Date": "6/23/03",
      "Open": "1100.6",
      "High": "1106.15",
      "Low": "1087.65",
      "Close": "1089.2"
  },
  {
      "Date": "6/24/03",
      "Open": "1089.2",
      "High": "1092.65",
      "Low": "1079.1",
      "Close": "1085.3"
  },
  {
      "Date": "6/25/03",
      "Open": "1085.65",
      "High": "1109.75",
      "Low": "1084.35",
      "Close": "1106.6"
  },
  {
      "Date": "6/26/03",
      "Open": "1106.8",
      "High": "1118.9",
      "Low": "1106.8",
      "Close": "1116.3"
  },
  {
      "Date": "6/27/03",
      "Open": "1117.45",
      "High": "1126.8",
      "Low": "1109.35",
      "Close": "1125.5"
  },
  {
      "Date": "6/30/03",
      "Open": "1125.6",
      "High": "1141.3",
      "Low": "1125.5",
      "Close": "1134.1"
  },
  {
      "Date": "7/1/03",
      "Open": "1133.95",
      "High": "1139",
      "Low": "1125.75",
      "Close": "1130.7"
  },
  {
      "Date": "7/2/03",
      "Open": "1131.55",
      "High": "1145.4",
      "Low": "1130.2",
      "Close": "1133.8"
  },
  {
      "Date": "7/3/03",
      "Open": "1133.8",
      "High": "1150.75",
      "Low": "1126.35",
      "Close": "1144.6"
  },
  {
      "Date": "7/4/03",
      "Open": "1145.6",
      "High": "1148.85",
      "Low": "1134",
      "Close": "1138.4"
  },
  {
      "Date": "7/7/03",
      "Open": "1138.9",
      "High": "1145.75",
      "Low": "1134.6",
      "Close": "1140.5"
  },
  {
      "Date": "7/8/03",
      "Open": "1141.45",
      "High": "1153.15",
      "Low": "1141.3",
      "Close": "1145.9"
  },
  {
      "Date": "7/9/03",
      "Open": "1146.35",
      "High": "1155.3",
      "Low": "1137.15",
      "Close": "1141"
  },
  {
      "Date": "7/10/03",
      "Open": "1146",
      "High": "1163.6",
      "Low": "1146",
      "Close": "1162.3"
  },
  {
      "Date": "7/11/03",
      "Open": "1162.3",
      "High": "1170.75",
      "Low": "1157.45",
      "Close": "1161.6"
  },
  {
      "Date": "7/14/03",
      "Open": "1161.2",
      "High": "1173.95",
      "Low": "1161.2",
      "Close": "1171.5"
  },
  {
      "Date": "7/15/03",
      "Open": "1171.5",
      "High": "1175.55",
      "Low": "1148.8",
      "Close": "1159.8"
  },
  {
      "Date": "7/16/03",
      "Open": "1158.7",
      "High": "1170.45",
      "Low": "1148.6",
      "Close": "1168.7"
  },
  {
      "Date": "7/17/03",
      "Open": "1168.85",
      "High": "1175.95",
      "Low": "1145.65",
      "Close": "1152"
  },
  {
      "Date": "7/18/03",
      "Open": "1151.85",
      "High": "1162.6",
      "Low": "1130.45",
      "Close": "1140"
  },
  {
      "Date": "7/21/03",
      "Open": "1140",
      "High": "1146.4",
      "Low": "1112.55",
      "Close": "1115.8"
  },
  {
      "Date": "7/22/03",
      "Open": "1115.35",
      "High": "1118.25",
      "Low": "1089.3",
      "Close": "1109.2"
  },
  {
      "Date": "7/23/03",
      "Open": "1109.6",
      "High": "1124.7",
      "Low": "1109.6",
      "Close": "1119"
  },
  {
      "Date": "7/24/03",
      "Open": "1119.95",
      "High": "1144.35",
      "Low": "1118.55",
      "Close": "1139.4"
  },
  {
      "Date": "7/25/03",
      "Open": "1135.5",
      "High": "1164.7",
      "Low": "1135.05",
      "Close": "1162.7"
  },
  {
      "Date": "7/28/03",
      "Open": "1163.25",
      "High": "1180.6",
      "Low": "1163.25",
      "Close": "1169.2"
  },
  {
      "Date": "7/29/03",
      "Open": "1169.25",
      "High": "1177.25",
      "Low": "1154.8",
      "Close": "1174.7"
  },
  {
      "Date": "7/30/03",
      "Open": "1175.3",
      "High": "1187.7",
      "Low": "1168.45",
      "Close": "1183"
  },
  {
      "Date": "7/31/03",
      "Open": "1183.3",
      "High": "1198.5",
      "Low": "1181.9",
      "Close": "1185.8"
  },
  {
      "Date": "8/1/03",
      "Open": "1185.8",
      "High": "1198.8",
      "Low": "1180.3",
      "Close": "1195.7"
  },
  {
      "Date": "8/4/03",
      "Open": "1196.05",
      "High": "1206.1",
      "Low": "1185.95",
      "Close": "1203.6"
  },
  {
      "Date": "8/5/03",
      "Open": "1203.9",
      "High": "1218.5",
      "Low": "1181",
      "Close": "1184.4"
  },
  {
      "Date": "8/6/03",
      "Open": "1184.1",
      "High": "1191.05",
      "Low": "1164.75",
      "Close": "1171"
  },
  {
      "Date": "8/7/03",
      "Open": "1170.75",
      "High": "1199.7",
      "Low": "1170.75",
      "Close": "1196.9"
  },
  {
      "Date": "8/8/03",
      "Open": "1199.7",
      "High": "1224.5",
      "Low": "1199.7",
      "Close": "1222.6"
  },
  {
      "Date": "8/11/03",
      "Open": "1222.6",
      "High": "1240.35",
      "Low": "1215.75",
      "Close": "1232.8"
  },
  {
      "Date": "8/12/03",
      "Open": "1233.95",
      "High": "1249.35",
      "Low": "1229.8",
      "Close": "1234.7"
  },
  {
      "Date": "8/13/03",
      "Open": "1235.35",
      "High": "1249.35",
      "Low": "1229.3",
      "Close": "1246.9"
  },
  {
      "Date": "8/14/03",
      "Open": "1247",
      "High": "1260.35",
      "Low": "1243.4",
      "Close": "1247.7"
  },
  {
      "Date": "8/18/03",
      "Open": "1247.9",
      "High": "1285.7",
      "Low": "1247.9",
      "Close": "1281.4"
  },
  {
      "Date": "8/19/03",
      "Open": "1286.05",
      "High": "1307.6",
      "Low": "1268.1",
      "Close": "1277.7"
  },
  {
      "Date": "8/20/03",
      "Open": "1280.8",
      "High": "1292.95",
      "Low": "1280.8",
      "Close": "1287.4"
  },
  {
      "Date": "8/21/03",
      "Open": "1288.2",
      "High": "1304.15",
      "Low": "1287",
      "Close": "1300.9"
  },
  {
      "Date": "8/22/03",
      "Open": "1301.5",
      "High": "1319.45",
      "Low": "1298.8",
      "Close": "1311.1"
  },
  {
      "Date": "8/25/03",
      "Open": "1311.95",
      "High": "1332.55",
      "Low": "1245.85",
      "Close": "1271.1"
  },
  {
      "Date": "8/26/03",
      "Open": "1270.2",
      "High": "1322.95",
      "Low": "1269.5",
      "Close": "1318.2"
  },
  {
      "Date": "8/27/03",
      "Open": "1318.75",
      "High": "1346.3",
      "Low": "1318.75",
      "Close": "1340.3"
  },
  {
      "Date": "8/28/03",
      "Open": "1340.2",
      "High": "1349",
      "Low": "1328.35",
      "Close": "1341"
  },
  {
      "Date": "8/29/03",
      "Open": "1341.2",
      "High": "1365.8",
      "Low": "1340.8",
      "Close": "1356.5"
  },
  {
      "Date": "9/1/03",
      "Open": "1356.7",
      "High": "1379.4",
      "Low": "1356.65",
      "Close": "1375.9"
  },
  {
      "Date": "9/2/03",
      "Open": "1375.95",
      "High": "1388.95",
      "Low": "1366.9",
      "Close": "1385.4"
  },
  {
      "Date": "9/3/03",
      "Open": "1387.2",
      "High": "1394.95",
      "Low": "1353.6",
      "Close": "1359.3"
  },
  {
      "Date": "9/4/03",
      "Open": "1358.9",
      "High": "1382.6",
      "Low": "1355.8",
      "Close": "1372.7"
  },
  {
      "Date": "9/5/03",
      "Open": "1373",
      "High": "1400.9",
      "Low": "1372.75",
      "Close": "1398.4"
  },
  {
      "Date": "9/8/03",
      "Open": "1398.4",
      "High": "1420.25",
      "Low": "1398.35",
      "Close": "1417.3"
  },
  {
      "Date": "9/9/03",
      "Open": "1417.8",
      "High": "1430.7",
      "Low": "1397.65",
      "Close": "1407"
  },
  {
      "Date": "9/10/03",
      "Open": "1407",
      "High": "1414.1",
      "Low": "1389.65",
      "Close": "1409.5"
  },
  {
      "Date": "9/11/03",
      "Open": "1409.65",
      "High": "1422.4",
      "Low": "1399.6",
      "Close": "1403.1"
  },
  {
      "Date": "9/12/03",
      "Open": "1405.55",
      "High": "1415.65",
      "Low": "1367.15",
      "Close": "1372.1"
  },
  {
      "Date": "9/15/03",
      "Open": "1371.75",
      "High": "1371.85",
      "Low": "1322.65",
      "Close": "1329.2"
  },
  {
      "Date": "9/16/03",
      "Open": "1330.15",
      "High": "1362.1",
      "Low": "1299.5",
      "Close": "1357.9"
  },
  {
      "Date": "9/17/03",
      "Open": "1357.95",
      "High": "1377.45",
      "Low": "1332.2",
      "Close": "1341.6"
  },
  {
      "Date": "9/18/03",
      "Open": "1341.45",
      "High": "1342.6",
      "Low": "1299.25",
      "Close": "1302.3"
  },
  {
      "Date": "9/19/03",
      "Open": "1303.2",
      "High": "1331.05",
      "Low": "1285.25",
      "Close": "1322.1"
  },
  {
      "Date": "9/22/03",
      "Open": "1319.25",
      "High": "1333.55",
      "Low": "1300.25",
      "Close": "1302.9"
  },
  {
      "Date": "9/23/03",
      "Open": "1302.85",
      "High": "1333.7",
      "Low": "1290.55",
      "Close": "1328.2"
  },
  {
      "Date": "9/24/03",
      "Open": "1324.85",
      "High": "1373.9",
      "Low": "1322.65",
      "Close": "1372"
  },
  {
      "Date": "9/25/03",
      "Open": "1371.45",
      "High": "1377.25",
      "Low": "1352.8",
      "Close": "1357.2"
  },
  {
      "Date": "9/26/03",
      "Open": "1357.55",
      "High": "1389.3",
      "Low": "1357.35",
      "Close": "1386.9"
  },
  {
      "Date": "9/29/03",
      "Open": "1386.9",
      "High": "1422.35",
      "Low": "1386.1",
      "Close": "1399.9"
  },
  {
      "Date": "9/30/03",
      "Open": "1400.7",
      "High": "1418.1",
      "Low": "1399.8",
      "Close": "1417.1"
  },
  {
      "Date": "10/1/03",
      "Open": "1416.6",
      "High": "1430.9",
      "Low": "1407.95",
      "Close": "1420.8"
  },
  {
      "Date": "10/3/03",
      "Open": "1420.85",
      "High": "1451.2",
      "Low": "1420.85",
      "Close": "1449.3"
  },
  {
      "Date": "10/6/03",
      "Open": "1449.45",
      "High": "1481.05",
      "Low": "1423.75",
      "Close": "1478.9"
  },
  {
      "Date": "10/7/03",
      "Open": "1478.95",
      "High": "1498.45",
      "Low": "1467.75",
      "Close": "1477.8"
  },
  {
      "Date": "10/8/03",
      "Open": "1477.85",
      "High": "1493.05",
      "Low": "1460.6",
      "Close": "1478.6"
  },
  {
      "Date": "10/9/03",
      "Open": "1487.85",
      "High": "1505.05",
      "Low": "1478.4",
      "Close": "1502.1"
  },
  {
      "Date": "10/10/03",
      "Open": "1507.1",
      "High": "1527.25",
      "Low": "1505.1",
      "Close": "1523.1"
  },
  {
      "Date": "10/13/03",
      "Open": "1523.35",
      "High": "1550.9",
      "Low": "1516.05",
      "Close": "1546.7"
  },
  {
      "Date": "10/14/03",
      "Open": "1548.7",
      "High": "1562.85",
      "Low": "1513.75",
      "Close": "1520.8"
  },
  {
      "Date": "10/15/03",
      "Open": "1521.45",
      "High": "1543.85",
      "Low": "1503",
      "Close": "1537"
  },
  {
      "Date": "10/16/03",
      "Open": "1537.3",
      "High": "1563.4",
      "Low": "1537.3",
      "Close": "1555.7"
  },
  {
      "Date": "10/17/03",
      "Open": "1565.2",
      "High": "1574.1",
      "Low": "1552.15",
      "Close": "1569.4"
  },
  {
      "Date": "10/20/03",
      "Open": "1569.7",
      "High": "1574.1",
      "Low": "1536.5",
      "Close": "1542.7"
  },
  {
      "Date": "10/21/03",
      "Open": "1546.2",
      "High": "1549.65",
      "Low": "1496.95",
      "Close": "1506.5"
  },
  {
      "Date": "10/22/03",
      "Open": "1506.75",
      "High": "1522.65",
      "Low": "1483",
      "Close": "1494.1"
  },
  {
      "Date": "10/23/03",
      "Open": "1494.75",
      "High": "1503",
      "Low": "1466.25",
      "Close": "1470.4"
  },
  {
      "Date": "10/24/03",
      "Open": "1485.8",
      "High": "1512.55",
      "Low": "1434.75",
      "Close": "1506"
  },
  {
      "Date": "10/25/03",
      "Open": "1506.05",
      "High": "1533.05",
      "Low": "1506.05",
      "Close": "1521.9"
  },
  {
      "Date": "10/27/03",
      "Open": "1522.05",
      "High": "1524.05",
      "Low": "1482",
      "Close": "1485.3"
  },
  {
      "Date": "10/28/03",
      "Open": "1485.45",
      "High": "1500.8",
      "Low": "1471.3",
      "Close": "1481.7"
  },
  {
      "Date": "10/29/03",
      "Open": "1481.3",
      "High": "1501.7",
      "Low": "1477.1",
      "Close": "1498.4"
  },
  {
      "Date": "10/30/03",
      "Open": "1499.25",
      "High": "1524.25",
      "Low": "1495.8",
      "Close": "1516.8"
  },
  {
      "Date": "10/31/03",
      "Open": "1517.1",
      "High": "1559.75",
      "Low": "1515.55",
      "Close": "1555.9"
  },
  {
      "Date": "11/3/03",
      "Open": "1556.5",
      "High": "1605.6",
      "Low": "1556.5",
      "Close": "1601.6"
  },
  {
      "Date": "11/4/03",
      "Open": "1601.3",
      "High": "1626.4",
      "Low": "1601.3",
      "Close": "1618.7"
  },
  {
      "Date": "11/5/03",
      "Open": "1618.7",
      "High": "1627.6",
      "Low": "1592.55",
      "Close": "1609.1"
  },
  {
      "Date": "11/6/03",
      "Open": "1610.55",
      "High": "1630.25",
      "Low": "1605.4",
      "Close": "1612.2"
  },
  {
      "Date": "11/7/03",
      "Open": "1612.1",
      "High": "1624.5",
      "Low": "1585.35",
      "Close": "1592"
  },
  {
      "Date": "11/10/03",
      "Open": "1603.55",
      "High": "1603.65",
      "Low": "1568.1",
      "Close": "1594.5"
  },
  {
      "Date": "11/11/03",
      "Open": "1594.5",
      "High": "1608.85",
      "Low": "1590.95",
      "Close": "1601.1"
  },
  {
      "Date": "11/12/03",
      "Open": "1601.3",
      "High": "1617.75",
      "Low": "1598",
      "Close": "1603.8"
  },
  {
      "Date": "11/13/03",
      "Open": "1610.4",
      "High": "1616.15",
      "Low": "1573.6",
      "Close": "1579.9"
  },
  {
      "Date": "11/14/03",
      "Open": "1577.2",
      "High": "1580.3",
      "Low": "1544.75",
      "Close": "1550.4"
  },
  {
      "Date": "11/15/03",
      "Open": "1549.65",
      "High": "1571.3",
      "Low": "1542",
      "Close": "1562.8"
  },
  {
      "Date": "11/17/03",
      "Open": "1563.1",
      "High": "1584.75",
      "Low": "1554.4",
      "Close": "1579.9"
  },
  {
      "Date": "11/18/03",
      "Open": "1579.15",
      "High": "1591.25",
      "Low": "1557.2",
      "Close": "1564.4"
  },
  {
      "Date": "11/19/03",
      "Open": "1563.95",
      "High": "1564",
      "Low": "1534.35",
      "Close": "1540.6"
  },
  {
      "Date": "11/20/03",
      "Open": "1542.85",
      "High": "1558.15",
      "Low": "1517.9",
      "Close": "1522.3"
  },
  {
      "Date": "11/21/03",
      "Open": "1520.4",
      "High": "1547",
      "Low": "1509.15",
      "Close": "1540.7"
  },
  {
      "Date": "11/24/03",
      "Open": "1541.35",
      "High": "1554.05",
      "Low": "1536.5",
      "Close": "1543.9"
  },
  {
      "Date": "11/25/03",
      "Open": "1543.95",
      "High": "1572.05",
      "Low": "1543.95",
      "Close": "1568.6"
  },
  {
      "Date": "11/27/03",
      "Open": "1568.85",
      "High": "1602.6",
      "Low": "1568.7",
      "Close": "1598.3"
  },
  {
      "Date": "11/28/03",
      "Open": "1598.55",
      "High": "1618.95",
      "Low": "1598.55",
      "Close": "1615.2"
  },
  {
      "Date": "12/1/03",
      "Open": "1615.85",
      "High": "1660.55",
      "Low": "1615.7",
      "Close": "1657.6"
  },
  {
      "Date": "12/2/03",
      "Open": "1657.65",
      "High": "1671.85",
      "Low": "1639.65",
      "Close": "1658.5"
  },
  {
      "Date": "12/3/03",
      "Open": "1660.7",
      "High": "1676.2",
      "Low": "1655.9",
      "Close": "1670.5"
  },
  {
      "Date": "12/4/03",
      "Open": "1670.65",
      "High": "1683.75",
      "Low": "1656.1",
      "Close": "1675.2"
  },
  {
      "Date": "12/5/03",
      "Open": "1675.5",
      "High": "1688.25",
      "Low": "1642.05",
      "Close": "1645.8"
  },
  {
      "Date": "12/8/03",
      "Open": "1644.85",
      "High": "1654.35",
      "Low": "1631.85",
      "Close": "1646.2"
  },
  {
      "Date": "12/9/03",
      "Open": "1646.4",
      "High": "1677.9",
      "Low": "1646.4",
      "Close": "1675.8"
  },
  {
      "Date": "12/10/03",
      "Open": "1675.75",
      "High": "1697.3",
      "Low": "1672.65",
      "Close": "1686.9"
  },
  {
      "Date": "12/11/03",
      "Open": "1688.35",
      "High": "1701.7",
      "Low": "1678.35",
      "Close": "1695.4"
  },
  {
      "Date": "12/12/03",
      "Open": "1695.8",
      "High": "1705.95",
      "Low": "1686.45",
      "Close": "1698.9"
  },
  {
      "Date": "12/15/03",
      "Open": "1699.7",
      "High": "1728",
      "Low": "1699.7",
      "Close": "1723.9"
  },
  {
      "Date": "12/16/03",
      "Open": "1723.9",
      "High": "1740.3",
      "Low": "1711.3",
      "Close": "1736.2"
  },
  {
      "Date": "12/17/03",
      "Open": "1736.35",
      "High": "1746.2",
      "Low": "1725.4",
      "Close": "1733.2"
  },
  {
      "Date": "12/18/03",
      "Open": "1733.2",
      "High": "1759",
      "Low": "1725.85",
      "Close": "1756.1"
  },
  {
      "Date": "12/19/03",
      "Open": "1757",
      "High": "1783.7",
      "Low": "1755.4",
      "Close": "1778.5"
  },
  {
      "Date": "12/22/03",
      "Open": "1778.85",
      "High": "1794.3",
      "Low": "1778.4",
      "Close": "1789.1"
  },
  {
      "Date": "12/23/03",
      "Open": "1789.1",
      "High": "1800.9",
      "Low": "1751.05",
      "Close": "1780.3"
  },
  {
      "Date": "12/24/03",
      "Open": "1788.45",
      "High": "1815.6",
      "Low": "1780.3",
      "Close": "1808.7"
  },
  {
      "Date": "12/26/03",
      "Open": "1804.7",
      "High": "1840.35",
      "Low": "1804.7",
      "Close": "1837"
  },
  {
      "Date": "12/29/03",
      "Open": "1838.05",
      "High": "1876",
      "Low": "1837.65",
      "Close": "1874"
  },
  {
      "Date": "12/30/03",
      "Open": "1878.9",
      "High": "1914.4",
      "Low": "1858.55",
      "Close": "1873.2"
  },
  {
      "Date": "12/31/03",
      "Open": "1868.9",
      "High": "1895.65",
      "Low": "1852.5",
      "Close": "1879.7"
  },
  {
      "Date": "1/1/04",
      "Open": "1880.35",
      "High": "1917.05",
      "Low": "1880.35",
      "Close": "1912.2"
  },
  {
      "Date": "1/2/04",
      "Open": "1912.25",
      "High": "1951.7",
      "Low": "1911.05",
      "Close": "1946"
  },
  {
      "Date": "1/5/04",
      "Open": "1946.3",
      "High": "1969.2",
      "Low": "1930.75",
      "Close": "1955"
  },
  {
      "Date": "1/6/04",
      "Open": "1955.1",
      "High": "1979.05",
      "Low": "1908.75",
      "Close": "1926.7"
  },
  {
      "Date": "1/7/04",
      "Open": "1927.95",
      "High": "1930.95",
      "Low": "1888.1",
      "Close": "1916.7"
  },
  {
      "Date": "1/8/04",
      "Open": "1918.1",
      "High": "1973.45",
      "Low": "1918.1",
      "Close": "1968.5"
  },
  {
      "Date": "1/9/04",
      "Open": "1969",
      "High": "2014.65",
      "Low": "1957.45",
      "Close": "1971.9"
  },
  {
      "Date": "1/12/04",
      "Open": "1972",
      "High": "1980.55",
      "Low": "1936.75",
      "Close": "1945.6"
  },
  {
      "Date": "1/13/04",
      "Open": "1944.7",
      "High": "1967.85",
      "Low": "1926.1",
      "Close": "1963.6"
  },
  {
      "Date": "1/14/04",
      "Open": "1987.4",
      "High": "1995.2",
      "Low": "1970.1",
      "Close": "1982.1"
  },
  {
      "Date": "1/15/04",
      "Open": "1983.2",
      "High": "2000.3",
      "Low": "1933.25",
      "Close": "1944.4"
  },
  {
      "Date": "1/16/04",
      "Open": "1944.15",
      "High": "1953.05",
      "Low": "1887.1",
      "Close": "1900.6"
  },
  {
      "Date": "1/19/04",
      "Open": "1901.9",
      "High": "1943.1",
      "Low": "1874.95",
      "Close": "1935.3"
  },
  {
      "Date": "1/20/04",
      "Open": "1928.8",
      "High": "1957.65",
      "Low": "1876.85",
      "Close": "1893.2"
  },
  {
      "Date": "1/21/04",
      "Open": "1895.45",
      "High": "1899.55",
      "Low": "1811.35",
      "Close": "1824.6"
  },
  {
      "Date": "1/22/04",
      "Open": "1824.7",
      "High": "1854.55",
      "Low": "1756.25",
      "Close": "1770.5"
  },
  {
      "Date": "1/23/04",
      "Open": "1771.1",
      "High": "1858.5",
      "Low": "1771.1",
      "Close": "1847.5"
  },
  {
      "Date": "1/27/04",
      "Open": "1847.9",
      "High": "1911.3",
      "Low": "1844.65",
      "Close": "1904.7"
  },
  {
      "Date": "1/28/04",
      "Open": "1903.9",
      "High": "1918.45",
      "Low": "1846.35",
      "Close": "1863.1"
  },
  {
      "Date": "1/29/04",
      "Open": "1863",
      "High": "1883.1",
      "Low": "1827.25",
      "Close": "1843.6"
  },
  {
      "Date": "1/30/04",
      "Open": "1843.7",
      "High": "1860.4",
      "Low": "1804.3",
      "Close": "1809.7"
  },
  {
      "Date": "2/3/04",
      "Open": "1809.3",
      "High": "1815.95",
      "Low": "1755.65",
      "Close": "1769"
  },
  {
      "Date": "2/4/04",
      "Open": "1769.1",
      "High": "1829.65",
      "Low": "1761.75",
      "Close": "1822.2"
  },
  {
      "Date": "2/5/04",
      "Open": "1823.5",
      "High": "1846.75",
      "Low": "1787.15",
      "Close": "1804.5"
  },
  {
      "Date": "2/6/04",
      "Open": "1804.35",
      "High": "1837.95",
      "Low": "1797.95",
      "Close": "1833.6"
  },
  {
      "Date": "2/9/04",
      "Open": "1834.9",
      "High": "1885.2",
      "Low": "1833.05",
      "Close": "1880.7"
  },
  {
      "Date": "2/10/04",
      "Open": "1880.85",
      "High": "1897",
      "Low": "1861.65",
      "Close": "1880.7"
  },
  {
      "Date": "2/11/04",
      "Open": "1891.8",
      "High": "1894.8",
      "Low": "1874.5",
      "Close": "1891.5"
  },
  {
      "Date": "2/12/04",
      "Open": "1892.2",
      "High": "1906.1",
      "Low": "1869.25",
      "Close": "1885.3"
  },
  {
      "Date": "2/13/04",
      "Open": "1875.6",
      "High": "1916.2",
      "Low": "1873.25",
      "Close": "1913.6"
  },
  {
      "Date": "2/16/04",
      "Open": "1914.45",
      "High": "1929.5",
      "Low": "1909.45",
      "Close": "1913.5"
  },
  {
      "Date": "2/17/04",
      "Open": "1913.55",
      "High": "1926.15",
      "Low": "1905.5",
      "Close": "1920.1"
  },
  {
      "Date": "2/18/04",
      "Open": "1920",
      "High": "1935.8",
      "Low": "1912.6",
      "Close": "1916.4"
  },
  {
      "Date": "2/19/04",
      "Open": "1916.65",
      "High": "1919.7",
      "Low": "1851.8",
      "Close": "1858.3"
  },
  {
      "Date": "2/20/04",
      "Open": "1858.25",
      "High": "1872.85",
      "Low": "1831.15",
      "Close": "1852.6"
  },
  {
      "Date": "2/23/04",
      "Open": "1853",
      "High": "1867.25",
      "Low": "1800.2",
      "Close": "1808.2"
  },
  {
      "Date": "2/24/04",
      "Open": "1808.4",
      "High": "1826.85",
      "Low": "1780.35",
      "Close": "1821.3"
  },
  {
      "Date": "2/25/04",
      "Open": "1833.65",
      "High": "1834.1",
      "Low": "1779",
      "Close": "1786.8"
  },
  {
      "Date": "2/26/04",
      "Open": "1787.45",
      "High": "1818.6",
      "Low": "1760.55",
      "Close": "1765.8"
  },
  {
      "Date": "2/27/04",
      "Open": "1765.9",
      "High": "1807.45",
      "Low": "1763.95",
      "Close": "1800.3"
  },
  {
      "Date": "3/1/04",
      "Open": "1798.35",
      "High": "1856.45",
      "Low": "1795.35",
      "Close": "1852.7"
  },
  {
      "Date": "3/3/04",
      "Open": "1852.45",
      "High": "1868.25",
      "Low": "1842.45",
      "Close": "1860.4"
  },
  {
      "Date": "3/4/04",
      "Open": "1859.3",
      "High": "1867.95",
      "Low": "1831.2",
      "Close": "1843.8"
  },
  {
      "Date": "3/5/04",
      "Open": "1843.9",
      "High": "1871.1",
      "Low": "1843.9",
      "Close": "1867.7"
  },
  {
      "Date": "3/8/04",
      "Open": "1868.15",
      "High": "1891.95",
      "Low": "1867.95",
      "Close": "1885.2"
  },
  {
      "Date": "3/9/04",
      "Open": "1884.5",
      "High": "1898.7",
      "Low": "1843",
      "Close": "1866"
  },
  {
      "Date": "3/10/04",
      "Open": "1866.3",
      "High": "1866.4",
      "Low": "1835.8",
      "Close": "1844.3"
  },
  {
      "Date": "3/11/04",
      "Open": "1843.95",
      "High": "1843.95",
      "Low": "1798.45",
      "Close": "1805.4"
  },
  {
      "Date": "3/12/04",
      "Open": "1805.2",
      "High": "1820.95",
      "Low": "1775.9",
      "Close": "1812.2"
  },
  {
      "Date": "3/15/04",
      "Open": "1812.45",
      "High": "1836",
      "Low": "1751.8",
      "Close": "1763.4"
  },
  {
      "Date": "3/16/04",
      "Open": "1763.35",
      "High": "1769.5",
      "Low": "1730.1",
      "Close": "1749.3"
  },
  {
      "Date": "3/17/04",
      "Open": "1749.6",
      "High": "1765.05",
      "Low": "1738.85",
      "Close": "1749.8"
  },
  {
      "Date": "3/18/04",
      "Open": "1750",
      "High": "1754.95",
      "Low": "1708.3",
      "Close": "1716.6"
  },
  {
      "Date": "3/19/04",
      "Open": "1716.3",
      "High": "1738.6",
      "Low": "1703.55",
      "Close": "1725.1"
  },
  {
      "Date": "3/22/04",
      "Open": "1726.3",
      "High": "1726.35",
      "Low": "1678.4",
      "Close": "1685"
  },
  {
      "Date": "3/23/04",
      "Open": "1685.15",
      "High": "1710.45",
      "Low": "1669.7",
      "Close": "1696.4"
  },
  {
      "Date": "3/24/04",
      "Open": "1697.4",
      "High": "1700.65",
      "Low": "1676.4",
      "Close": "1692.1"
  },
  {
      "Date": "3/25/04",
      "Open": "1691.95",
      "High": "1720.65",
      "Low": "1691.95",
      "Close": "1704.4"
  },
  {
      "Date": "3/26/04",
      "Open": "1704.45",
      "High": "1755.45",
      "Low": "1703.8",
      "Close": "1747.5"
  },
  {
      "Date": "3/29/04",
      "Open": "1747.35",
      "High": "1766.15",
      "Low": "1739.5",
      "Close": "1762"
  },
  {
      "Date": "3/30/04",
      "Open": "1762.15",
      "High": "1775.4",
      "Low": "1746.25",
      "Close": "1750.1"
  },
  {
      "Date": "3/31/04",
      "Open": "1744.6",
      "High": "1775.3",
      "Low": "1740.2",
      "Close": "1771.9"
  },
  {
      "Date": "4/1/04",
      "Open": "1771.45",
      "High": "1823.05",
      "Low": "1771.45",
      "Close": "1819.6"
  },
  {
      "Date": "4/2/04",
      "Open": "1856.1",
      "High": "1856.3",
      "Low": "1809",
      "Close": "1841.1"
  },
  {
      "Date": "4/5/04",
      "Open": "1841.1",
      "High": "1878.8",
      "Low": "1840",
      "Close": "1856.6"
  },
  {
      "Date": "4/6/04",
      "Open": "1870.8",
      "High": "1876.15",
      "Low": "1836.9",
      "Close": "1851.1"
  },
  {
      "Date": "4/7/04",
      "Open": "1856.4",
      "High": "1865.2",
      "Low": "1833.95",
      "Close": "1848.7"
  },
  {
      "Date": "4/8/04",
      "Open": "1854",
      "High": "1864.95",
      "Low": "1839.7",
      "Close": "1853.5"
  },
  {
      "Date": "4/12/04",
      "Open": "1860.3",
      "High": "1873.75",
      "Low": "1828.95",
      "Close": "1838.2"
  },
  {
      "Date": "4/13/04",
      "Open": "1838.5",
      "High": "1885.15",
      "Low": "1819.8",
      "Close": "1878.4"
  },
  {
      "Date": "4/15/04",
      "Open": "1878.5",
      "High": "1884.5",
      "Low": "1855",
      "Close": "1861.9"
  },
  {
      "Date": "4/16/04",
      "Open": "1863.85",
      "High": "1882.55",
      "Low": "1861.8",
      "Close": "1868.9"
  },
  {
      "Date": "4/17/04",
      "Open": "1869.2",
      "High": "1875.95",
      "Low": "1864.95",
      "Close": "1868.1"
  },
  {
      "Date": "4/19/04",
      "Open": "1868.2",
      "High": "1876.2",
      "Low": "1837.4",
      "Close": "1844"
  },
  {
      "Date": "4/20/04",
      "Open": "1844.4",
      "High": "1851",
      "Low": "1832",
      "Close": "1844.2"
  },
  {
      "Date": "4/21/04",
      "Open": "1844.35",
      "High": "1876",
      "Low": "1838.6",
      "Close": "1873.3"
  },
  {
      "Date": "4/22/04",
      "Open": "1873.55",
      "High": "1894.6",
      "Low": "1871",
      "Close": "1889.5"
  },
  {
      "Date": "4/23/04",
      "Open": "1890.85",
      "High": "1912.35",
      "Low": "1885.75",
      "Close": "1892.4"
  },
  {
      "Date": "4/27/04",
      "Open": "1890.05",
      "High": "1893.1",
      "Low": "1814",
      "Close": "1817.2"
  },
  {
      "Date": "4/28/04",
      "Open": "1819",
      "High": "1833.95",
      "Low": "1801.8",
      "Close": "1816.5"
  },
  {
      "Date": "4/29/04",
      "Open": "1816.7",
      "High": "1818.9",
      "Low": "1793.1",
      "Close": "1808.9"
  },
  {
      "Date": "4/30/04",
      "Open": "1820.55",
      "High": "1841.45",
      "Low": "1788.3",
      "Close": "1796.1"
  },
  {
      "Date": "5/3/04",
      "Open": "1796.1",
      "High": "1801.45",
      "Low": "1742.8",
      "Close": "1766.7"
  },
  {
      "Date": "5/4/04",
      "Open": "1769.1",
      "High": "1803.15",
      "Low": "1768.8",
      "Close": "1793.1"
  },
  {
      "Date": "5/5/04",
      "Open": "1789.45",
      "High": "1814.35",
      "Low": "1788.1",
      "Close": "1809.9"
  },
  {
      "Date": "5/6/04",
      "Open": "1810.3",
      "High": "1837.95",
      "Low": "1810.3",
      "Close": "1832.8"
  },
  {
      "Date": "5/7/04",
      "Open": "1832.85",
      "High": "1833.15",
      "Low": "1796.95",
      "Close": "1804.4"
  },
  {
      "Date": "5/10/04",
      "Open": "1804.4",
      "High": "1804.4",
      "Low": "1753.8",
      "Close": "1769.1"
  },
  {
      "Date": "5/11/04",
      "Open": "1768.7",
      "High": "1768.7",
      "Low": "1692.9",
      "Close": "1699.4"
  },
  {
      "Date": "5/12/04",
      "Open": "1699.25",
      "High": "1721.35",
      "Low": "1689.85",
      "Close": "1711.1"
  },
  {
      "Date": "5/13/04",
      "Open": "1707.95",
      "High": "1750.3",
      "Low": "1625.1",
      "Close": "1717.5"
  },
  {
      "Date": "5/14/04",
      "Open": "1717.05",
      "High": "1722.8",
      "Low": "1566.95",
      "Close": "1582.4"
  },
  {
      "Date": "5/17/04",
      "Open": "1582.5",
      "High": "1583.8",
      "Low": "1292.2",
      "Close": "1388.7"
  },
  {
      "Date": "5/18/04",
      "Open": "1392.95",
      "High": "1517.6",
      "Low": "1389.65",
      "Close": "1503.9"
  },
  {
      "Date": "5/19/04",
      "Open": "1508.05",
      "High": "1576.05",
      "Low": "1508.05",
      "Close": "1567.8"
  },
  {
      "Date": "5/20/04",
      "Open": "1566.5",
      "High": "1591.05",
      "Low": "1531.3",
      "Close": "1543.8"
  },
  {
      "Date": "5/21/04",
      "Open": "1545.05",
      "High": "1566.15",
      "Low": "1498.1",
      "Close": "1560.2"
  },
  {
      "Date": "5/24/04",
      "Open": "1563.65",
      "High": "1613.6",
      "Low": "1563.05",
      "Close": "1608.8"
  },
  {
      "Date": "5/25/04",
      "Open": "1608.9",
      "High": "1621.45",
      "Low": "1566.4",
      "Close": "1606.7"
  },
  {
      "Date": "5/26/04",
      "Open": "1608.15",
      "High": "1625.7",
      "Low": "1587",
      "Close": "1598.8"
  },
  {
      "Date": "5/27/04",
      "Open": "1590.15",
      "High": "1606",
      "Low": "1576.05",
      "Close": "1586.4"
  },
  {
      "Date": "5/28/04",
      "Open": "1586.35",
      "High": "1587.1",
      "Low": "1504",
      "Close": "1508.7"
  },
  {
      "Date": "5/31/04",
      "Open": "1507.05",
      "High": "1509.05",
      "Low": "1456.2",
      "Close": "1483.6"
  },
  {
      "Date": "6/1/04",
      "Open": "1483.9",
      "High": "1529.6",
      "Low": "1483.9",
      "Close": "1507.9"
  },
  {
      "Date": "6/2/04",
      "Open": "1508",
      "High": "1543.3",
      "Low": "1508",
      "Close": "1535.2"
  },
  {
      "Date": "6/3/04",
      "Open": "1535.8",
      "High": "1566.5",
      "Low": "1484.5",
      "Close": "1495.1"
  },
  {
      "Date": "6/4/04",
      "Open": "1494.85",
      "High": "1527",
      "Low": "1480.8",
      "Close": "1521.1"
  },
  {
      "Date": "6/7/04",
      "Open": "1521.6",
      "High": "1557.5",
      "Low": "1521.6",
      "Close": "1542.5"
  },
  {
      "Date": "6/8/04",
      "Open": "1542.6",
      "High": "1557",
      "Low": "1526.45",
      "Close": "1550.5"
  },
  {
      "Date": "6/9/04",
      "Open": "1550.45",
      "High": "1561.6",
      "Low": "1541.6",
      "Close": "1548.3"
  },
  {
      "Date": "6/10/04",
      "Open": "1547.7",
      "High": "1552.75",
      "Low": "1535",
      "Close": "1544.7"
  },
  {
      "Date": "6/11/04",
      "Open": "1544.95",
      "High": "1547.4",
      "Low": "1504.8",
      "Close": "1508.4"
  },
  {
      "Date": "6/14/04",
      "Open": "1508.15",
      "High": "1509.6",
      "Low": "1474.65",
      "Close": "1481.3"
  },
  {
      "Date": "6/15/04",
      "Open": "1481.45",
      "High": "1507.85",
      "Low": "1481",
      "Close": "1501"
  },
  {
      "Date": "6/16/04",
      "Open": "1500.95",
      "High": "1525.2",
      "Low": "1490.45",
      "Close": "1494.7"
  },
  {
      "Date": "6/17/04",
      "Open": "1494.7",
      "High": "1519.2",
      "Low": "1482.2",
      "Close": "1512"
  },
  {
      "Date": "6/18/04",
      "Open": "1511.95",
      "High": "1515.05",
      "Low": "1485.05",
      "Close": "1491.2"
  },
  {
      "Date": "6/21/04",
      "Open": "1490.75",
      "High": "1499.8",
      "Low": "1478.85",
      "Close": "1482"
  },
  {
      "Date": "6/22/04",
      "Open": "1482.85",
      "High": "1489.6",
      "Low": "1467.65",
      "Close": "1474.7"
  },
  {
      "Date": "6/23/04",
      "Open": "1474.8",
      "High": "1481.45",
      "Low": "1442.55",
      "Close": "1446.1"
  },
  {
      "Date": "6/24/04",
      "Open": "1445.35",
      "High": "1474.3",
      "Low": "1437.9",
      "Close": "1470.7"
  },
  {
      "Date": "6/25/04",
      "Open": "1471.5",
      "High": "1494",
      "Low": "1459.55",
      "Close": "1488.5"
  },
  {
      "Date": "6/28/04",
      "Open": "1488.6",
      "High": "1516.95",
      "Low": "1488.6",
      "Close": "1514.3"
  },
  {
      "Date": "6/29/04",
      "Open": "1514.35",
      "High": "1528.5",
      "Low": "1503.3",
      "Close": "1518.3"
  },
  {
      "Date": "6/30/04",
      "Open": "1518.7",
      "High": "1532.15",
      "Low": "1501.7",
      "Close": "1505.6"
  },
  {
      "Date": "7/1/04",
      "Open": "1506.65",
      "High": "1539.15",
      "Low": "1504.55",
      "Close": "1537.2"
  },
  {
      "Date": "7/2/04",
      "Open": "1537.05",
      "High": "1547.65",
      "Low": "1516.15",
      "Close": "1537.5"
  },
  {
      "Date": "7/5/04",
      "Open": "1537.55",
      "High": "1546",
      "Low": "1522.9",
      "Close": "1526.8"
  },
  {
      "Date": "7/6/04",
      "Open": "1526.65",
      "High": "1561",
      "Low": "1524.15",
      "Close": "1558.2"
  },
  {
      "Date": "7/7/04",
      "Open": "1558.05",
      "High": "1578.5",
      "Low": "1551.35",
      "Close": "1566.8"
  },
  {
      "Date": "7/8/04",
      "Open": "1567.25",
      "High": "1586.55",
      "Low": "1504.5",
      "Close": "1518.1"
  },
  {
      "Date": "7/9/04",
      "Open": "1517.45",
      "High": "1557.75",
      "Low": "1472.55",
      "Close": "1553.2"
  },
  {
      "Date": "7/12/04",
      "Open": "1548.6",
      "High": "1562.8",
      "Low": "1537.7",
      "Close": "1556.9"
  },
  {
      "Date": "7/13/04",
      "Open": "1560",
      "High": "1569.75",
      "Low": "1530.6",
      "Close": "1539.3"
  },
  {
      "Date": "7/14/04",
      "Open": "1535.1",
      "High": "1552.25",
      "Low": "1519.35",
      "Close": "1522.7"
  },
  {
      "Date": "7/15/04",
      "Open": "1523.1",
      "High": "1543.25",
      "Low": "1517.8",
      "Close": "1539.4"
  },
  {
      "Date": "7/16/04",
      "Open": "1538.15",
      "High": "1562",
      "Low": "1533.65",
      "Close": "1558.8"
  },
  {
      "Date": "7/19/04",
      "Open": "1559.2",
      "High": "1582.35",
      "Low": "1556.55",
      "Close": "1571.6"
  },
  {
      "Date": "7/20/04",
      "Open": "1571.7",
      "High": "1581.05",
      "Low": "1555.55",
      "Close": "1566.1"
  },
  {
      "Date": "7/21/04",
      "Open": "1566.15",
      "High": "1600.7",
      "Low": "1562.9",
      "Close": "1581.4"
  },
  {
      "Date": "7/22/04",
      "Open": "1581.6",
      "High": "1609.55",
      "Low": "1580.95",
      "Close": "1598.1"
  },
  {
      "Date": "7/23/04",
      "Open": "1587.6",
      "High": "1610.85",
      "Low": "1587.6",
      "Close": "1601.6"
  },
  {
      "Date": "7/26/04",
      "Open": "1602.15",
      "High": "1620.35",
      "Low": "1584.65",
      "Close": "1618"
  },
  {
      "Date": "7/27/04",
      "Open": "1617.95",
      "High": "1629.35",
      "Low": "1597.2",
      "Close": "1600.7"
  },
  {
      "Date": "7/28/04",
      "Open": "1600.85",
      "High": "1609.45",
      "Low": "1586.15",
      "Close": "1594.1"
  },
  {
      "Date": "7/29/04",
      "Open": "1594.7",
      "High": "1624.3",
      "Low": "1590.15",
      "Close": "1618.7"
  },
  {
      "Date": "7/30/04",
      "Open": "1618.9",
      "High": "1638.7",
      "Low": "1609.9",
      "Close": "1632.3"
  },
  {
      "Date": "8/2/04",
      "Open": "1631.55",
      "High": "1643.8",
      "Low": "1627.25",
      "Close": "1639"
  },
  {
      "Date": "8/3/04",
      "Open": "1639.95",
      "High": "1648.35",
      "Low": "1626.3",
      "Close": "1630.6"
  },
  {
      "Date": "8/4/04",
      "Open": "1631.3",
      "High": "1635.95",
      "Low": "1618.2",
      "Close": "1626.5"
  },
  {
      "Date": "8/5/04",
      "Open": "1626.4",
      "High": "1656.25",
      "Low": "1626.4",
      "Close": "1654.9"
  },
  {
      "Date": "8/6/04",
      "Open": "1654.85",
      "High": "1658.7",
      "Low": "1626.7",
      "Close": "1633.4"
  },
  {
      "Date": "8/9/04",
      "Open": "1633.25",
      "High": "1644.4",
      "Low": "1617.05",
      "Close": "1642.6"
  },
  {
      "Date": "8/10/04",
      "Open": "1642.75",
      "High": "1656.6",
      "Low": "1637.7",
      "Close": "1652.1"
  },
  {
      "Date": "8/11/04",
      "Open": "1652.1",
      "High": "1658.9",
      "Low": "1616.85",
      "Close": "1621.6"
  },
  {
      "Date": "8/12/04",
      "Open": "1624.3",
      "High": "1629.15",
      "Low": "1599.5",
      "Close": "1607.2"
  },
  {
      "Date": "8/13/04",
      "Open": "1598.2",
      "High": "1612.7",
      "Low": "1591.6",
      "Close": "1598.2"
  },
  {
      "Date": "8/16/04",
      "Open": "1597.5",
      "High": "1603.2",
      "Low": "1582.35",
      "Close": "1599.1"
  },
  {
      "Date": "8/17/04",
      "Open": "1624",
      "High": "1628.45",
      "Low": "1597.45",
      "Close": "1604.3"
  },
  {
      "Date": "8/18/04",
      "Open": "1604.15",
      "High": "1604.15",
      "Low": "1577.25",
      "Close": "1581.8"
  },
  {
      "Date": "8/19/04",
      "Open": "1581.9",
      "High": "1612.5",
      "Low": "1581.9",
      "Close": "1609.2"
  },
  {
      "Date": "8/20/04",
      "Open": "1609.2",
      "High": "1610.6",
      "Low": "1584.05",
      "Close": "1590.3"
  },
  {
      "Date": "8/23/04",
      "Open": "1590.9",
      "High": "1595.55",
      "Low": "1573.7",
      "Close": "1578.2"
  },
  {
      "Date": "8/24/04",
      "Open": "1578.2",
      "High": "1597.9",
      "Low": "1578.05",
      "Close": "1591.6"
  },
  {
      "Date": "8/25/04",
      "Open": "1592.2",
      "High": "1598.55",
      "Low": "1586.35",
      "Close": "1595.7"
  },
  {
      "Date": "8/26/04",
      "Open": "1595.75",
      "High": "1613.25",
      "Low": "1593.45",
      "Close": "1610.7"
  },
  {
      "Date": "8/27/04",
      "Open": "1610.85",
      "High": "1617.25",
      "Low": "1600.15",
      "Close": "1609"
  },
  {
      "Date": "8/30/04",
      "Open": "1609.45",
      "High": "1629.9",
      "Low": "1609.2",
      "Close": "1628.4"
  },
  {
      "Date": "8/31/04",
      "Open": "1628.5",
      "High": "1634.8",
      "Low": "1618.3",
      "Close": "1631.7"
  },
  {
      "Date": "9/1/04",
      "Open": "1631.7",
      "High": "1640.55",
      "Low": "1631.1",
      "Close": "1635.4"
  },
  {
      "Date": "9/2/04",
      "Open": "1635.5",
      "High": "1641.25",
      "Low": "1619.9",
      "Close": "1629.3"
  },
  {
      "Date": "9/3/04",
      "Open": "1629.65",
      "High": "1636.1",
      "Low": "1622.1",
      "Close": "1634.1"
  },
  {
      "Date": "9/6/04",
      "Open": "1634.6",
      "High": "1649.15",
      "Low": "1634.45",
      "Close": "1644"
  },
  {
      "Date": "9/7/04",
      "Open": "1643.95",
      "High": "1653.15",
      "Low": "1641.8",
      "Close": "1650.1"
  },
  {
      "Date": "9/8/04",
      "Open": "1650.6",
      "High": "1658.85",
      "Low": "1648.25",
      "Close": "1656.2"
  },
  {
      "Date": "9/9/04",
      "Open": "1656.25",
      "High": "1662.4",
      "Low": "1640.25",
      "Close": "1649"
  },
  {
      "Date": "9/10/04",
      "Open": "1649.45",
      "High": "1670.95",
      "Low": "1642.35",
      "Close": "1668.7"
  },
  {
      "Date": "9/13/04",
      "Open": "1668.25",
      "High": "1681.75",
      "Low": "1668.05",
      "Close": "1675.2"
  },
  {
      "Date": "9/14/04",
      "Open": "1675.2",
      "High": "1686.8",
      "Low": "1668.45",
      "Close": "1685.5"
  },
  {
      "Date": "9/15/04",
      "Open": "1685.7",
      "High": "1694.45",
      "Low": "1675.85",
      "Close": "1683.2"
  },
  {
      "Date": "9/16/04",
      "Open": "1683.6",
      "High": "1708",
      "Low": "1677.35",
      "Close": "1705.7"
  },
  {
      "Date": "9/17/04",
      "Open": "1702.7",
      "High": "1736.4",
      "Low": "1702.7",
      "Close": "1733.6"
  },
  {
      "Date": "9/20/04",
      "Open": "1734.05",
      "High": "1741.55",
      "Low": "1725.3",
      "Close": "1728.8"
  },
  {
      "Date": "9/21/04",
      "Open": "1728.75",
      "High": "1753",
      "Low": "1724.7",
      "Close": "1750.2"
  },
  {
      "Date": "9/22/04",
      "Open": "1749.75",
      "High": "1760.8",
      "Low": "1736.3",
      "Close": "1753.9"
  },
  {
      "Date": "9/23/04",
      "Open": "1753.1",
      "High": "1753.15",
      "Low": "1723.4",
      "Close": "1726.1"
  },
  {
      "Date": "9/24/04",
      "Open": "1726.15",
      "High": "1736.9",
      "Low": "1717.2",
      "Close": "1722.5"
  },
  {
      "Date": "9/27/04",
      "Open": "1722.4",
      "High": "1726.15",
      "Low": "1707.95",
      "Close": "1717.5"
  },
  {
      "Date": "9/28/04",
      "Open": "1717.45",
      "High": "1717.9",
      "Low": "1697.4",
      "Close": "1700.2"
  },
  {
      "Date": "9/29/04",
      "Open": "1700.55",
      "High": "1730.3",
      "Low": "1695.7",
      "Close": "1727.9"
  },
  {
      "Date": "9/30/04",
      "Open": "1728.3",
      "High": "1748.2",
      "Low": "1728.2",
      "Close": "1745.5"
  },
  {
      "Date": "10/1/04",
      "Open": "1744.4",
      "High": "1778.65",
      "Low": "1737.85",
      "Close": "1775.1"
  },
  {
      "Date": "10/4/04",
      "Open": "1776.75",
      "High": "1813.9",
      "Low": "1775.45",
      "Close": "1805.6"
  },
  {
      "Date": "10/5/04",
      "Open": "1805.85",
      "High": "1816.55",
      "Low": "1798.6",
      "Close": "1812.4"
  },
  {
      "Date": "10/6/04",
      "Open": "1811.7",
      "High": "1824.8",
      "Low": "1790.6",
      "Close": "1794.9"
  },
  {
      "Date": "10/7/04",
      "Open": "1793",
      "High": "1821.85",
      "Low": "1793",
      "Close": "1815.7"
  },
  {
      "Date": "10/8/04",
      "Open": "1815.65",
      "High": "1825.15",
      "Low": "1808.75",
      "Close": "1820.2"
  },
  {
      "Date": "10/9/04",
      "Open": "1812.85",
      "High": "1824.9",
      "Low": "1812.8",
      "Close": "1817.8"
  },
  {
      "Date": "10/11/04",
      "Open": "1817.85",
      "High": "1829.45",
      "Low": "1803.5",
      "Close": "1807.7"
  },
  {
      "Date": "10/12/04",
      "Open": "1808.45",
      "High": "1820.9",
      "Low": "1783.05",
      "Close": "1786.9"
  },
  {
      "Date": "10/14/04",
      "Open": "1784.8",
      "High": "1805.7",
      "Low": "1773",
      "Close": "1794.7"
  },
  {
      "Date": "10/15/04",
      "Open": "1794.8",
      "High": "1814.05",
      "Low": "1789.55",
      "Close": "1795"
  },
  {
      "Date": "10/18/04",
      "Open": "1796.05",
      "High": "1806.15",
      "Low": "1782.95",
      "Close": "1786"
  },
  {
      "Date": "10/19/04",
      "Open": "1787.2",
      "High": "1813.9",
      "Low": "1787.2",
      "Close": "1808.4"
  },
  {
      "Date": "10/20/04",
      "Open": "1808.6",
      "High": "1815.2",
      "Low": "1785.45",
      "Close": "1790"
  },
  {
      "Date": "10/21/04",
      "Open": "1790.35",
      "High": "1795.4",
      "Low": "1772.4",
      "Close": "1779.7"
  },
  {
      "Date": "10/25/04",
      "Open": "1780",
      "High": "1783.55",
      "Low": "1753.5",
      "Close": "1757.2"
  },
  {
      "Date": "10/26/04",
      "Open": "1777.85",
      "High": "1782.1",
      "Low": "1750.3",
      "Close": "1781"
  },
  {
      "Date": "10/27/04",
      "Open": "1781.15",
      "High": "1797.55",
      "Low": "1774.2",
      "Close": "1783.8"
  },
  {
      "Date": "10/28/04",
      "Open": "1783.9",
      "High": "1808.15",
      "Low": "1783.9",
      "Close": "1800.1"
  },
  {
      "Date": "10/29/04",
      "Open": "1800.85",
      "High": "1808.85",
      "Low": "1783.1",
      "Close": "1786.9"
  },
  {
      "Date": "11/1/04",
      "Open": "1787.3",
      "High": "1800.25",
      "Low": "1776.7",
      "Close": "1797.7"
  },
  {
      "Date": "11/2/04",
      "Open": "1798.3",
      "High": "1817.1",
      "Low": "1798.3",
      "Close": "1813.7"
  },
  {
      "Date": "11/3/04",
      "Open": "1814.15",
      "High": "1838.4",
      "Low": "1814.15",
      "Close": "1837.4"
  },
  {
      "Date": "11/4/04",
      "Open": "1837.8",
      "High": "1853.05",
      "Low": "1830.5",
      "Close": "1834.8"
  },
  {
      "Date": "11/5/04",
      "Open": "1836.05",
      "High": "1854.4",
      "Low": "1836",
      "Close": "1852.3"
  },
  {
      "Date": "11/8/04",
      "Open": "1852.45",
      "High": "1871.05",
      "Low": "1852.45",
      "Close": "1862.8"
  },
  {
      "Date": "11/9/04",
      "Open": "1863.55",
      "High": "1869.45",
      "Low": "1853.45",
      "Close": "1858.7"
  },
  {
      "Date": "11/10/04",
      "Open": "1859.65",
      "High": "1878.2",
      "Low": "1857.7",
      "Close": "1876.1"
  },
  {
      "Date": "11/11/04",
      "Open": "1876.45",
      "High": "1884.65",
      "Low": "1866.95",
      "Close": "1870.5"
  },
  {
      "Date": "11/12/04",
      "Open": "1871.9",
      "High": "1885.05",
      "Low": "1866.75",
      "Close": "1872.9"
  },
  {
      "Date": "11/16/04",
      "Open": "1872.8",
      "High": "1881.4",
      "Low": "1862.8",
      "Close": "1879"
  },
  {
      "Date": "11/17/04",
      "Open": "1879.05",
      "High": "1892.15",
      "Low": "1874.35",
      "Close": "1888.6"
  },
  {
      "Date": "11/18/04",
      "Open": "1890.45",
      "High": "1895.3",
      "Low": "1880.8",
      "Close": "1892"
  },
  {
      "Date": "11/19/04",
      "Open": "1891.95",
      "High": "1898.15",
      "Low": "1869.35",
      "Close": "1872.3"
  },
  {
      "Date": "11/22/04",
      "Open": "1872.55",
      "High": "1878.05",
      "Low": "1845.1",
      "Close": "1873.3"
  },
  {
      "Date": "11/23/04",
      "Open": "1873.35",
      "High": "1900.05",
      "Low": "1873.35",
      "Close": "1892.6"
  },
  {
      "Date": "11/24/04",
      "Open": "1893.15",
      "High": "1907.35",
      "Low": "1889.85",
      "Close": "1904"
  },
  {
      "Date": "11/25/04",
      "Open": "1904.25",
      "High": "1915.8",
      "Low": "1892.6",
      "Close": "1901"
  },
  {
      "Date": "11/29/04",
      "Open": "1899.3",
      "High": "1942.95",
      "Low": "1894.6",
      "Close": "1939.6"
  },
  {
      "Date": "11/30/04",
      "Open": "1940.25",
      "High": "1963.8",
      "Low": "1940.25",
      "Close": "1958.8"
  },
  {
      "Date": "12/1/04",
      "Open": "1960.75",
      "High": "1971.6",
      "Low": "1944.5",
      "Close": "1962"
  },
  {
      "Date": "12/2/04",
      "Open": "1962.75",
      "High": "2001.55",
      "Low": "1962.75",
      "Close": "1999"
  },
  {
      "Date": "12/3/04",
      "Open": "1991.9",
      "High": "2011.9",
      "Low": "1990.3",
      "Close": "1996.2"
  },
  {
      "Date": "12/6/04",
      "Open": "1996.3",
      "High": "2012.25",
      "Low": "1989.95",
      "Close": "1993.1"
  },
  {
      "Date": "12/7/04",
      "Open": "1990.6",
      "High": "2002.6",
      "Low": "1981.15",
      "Close": "1992.7"
  },
  {
      "Date": "12/8/04",
      "Open": "1994.15",
      "High": "2009.7",
      "Low": "1973.15",
      "Close": "1977.9"
  },
  {
      "Date": "12/9/04",
      "Open": "1977.8",
      "High": "1995.7",
      "Low": "1964.5",
      "Close": "1989.9"
  },
  {
      "Date": "12/10/04",
      "Open": "1990.2",
      "High": "1999.7",
      "Low": "1964.65",
      "Close": "1969"
  },
  {
      "Date": "12/13/04",
      "Open": "1970.6",
      "High": "1987",
      "Low": "1964.8",
      "Close": "1985.3"
  },
  {
      "Date": "12/14/04",
      "Open": "1985.2",
      "High": "2008.8",
      "Low": "1972.85",
      "Close": "2006.8"
  },
  {
      "Date": "12/15/04",
      "Open": "2006.8",
      "High": "2034.35",
      "Low": "2006.8",
      "Close": "2028.7"
  },
  {
      "Date": "12/16/04",
      "Open": "2028.8",
      "High": "2039.2",
      "Low": "2019.5",
      "Close": "2033.2"
  },
  {
      "Date": "12/17/04",
      "Open": "2032.85",
      "High": "2037.7",
      "Low": "2007.2",
      "Close": "2012.1"
  },
  {
      "Date": "12/20/04",
      "Open": "2012.5",
      "High": "2029.5",
      "Low": "2007.15",
      "Close": "2026.8"
  },
  {
      "Date": "12/21/04",
      "Open": "2027.8",
      "High": "2047.5",
      "Low": "2025.25",
      "Close": "2044.6"
  },
  {
      "Date": "12/22/04",
      "Open": "2040.6",
      "High": "2053.9",
      "Low": "2029.85",
      "Close": "2035.3"
  },
  {
      "Date": "12/23/04",
      "Open": "2035.6",
      "High": "2049.3",
      "Low": "2031.9",
      "Close": "2045.1"
  },
  {
      "Date": "12/24/04",
      "Open": "2045.35",
      "High": "2065.6",
      "Low": "2045.3",
      "Close": "2062.7"
  },
  {
      "Date": "12/27/04",
      "Open": "2061.6",
      "High": "2079.05",
      "Low": "2046.25",
      "Close": "2062.6"
  },
  {
      "Date": "12/28/04",
      "Open": "2063.6",
      "High": "2074.6",
      "Low": "2060.05",
      "Close": "2071.3"
  },
  {
      "Date": "12/29/04",
      "Open": "2071.55",
      "High": "2086.15",
      "Low": "2062.35",
      "Close": "2069.6"
  },
  {
      "Date": "12/30/04",
      "Open": "2070.55",
      "High": "2088.45",
      "Low": "2052.25",
      "Close": "2059.8"
  },
  {
      "Date": "12/31/04",
      "Open": "2059.85",
      "High": "2083",
      "Low": "2059.85",
      "Close": "2080.5"
  },
  {
      "Date": "1/3/05",
      "Open": "2080",
      "High": "2118.6",
      "Low": "2080",
      "Close": "2115"
  },
  {
      "Date": "1/4/05",
      "Open": "2116.95",
      "High": "2120.15",
      "Low": "2100.55",
      "Close": "2103.7"
  },
  {
      "Date": "1/5/05",
      "Open": "2103.75",
      "High": "2105.1",
      "Low": "1990.15",
      "Close": "2032.2"
  },
  {
      "Date": "1/6/05",
      "Open": "2031.55",
      "High": "2035.65",
      "Low": "1984.25",
      "Close": "1998.3"
  },
  {
      "Date": "1/7/05",
      "Open": "1998.25",
      "High": "2021.45",
      "Low": "1992.55",
      "Close": "2015.5"
  },
  {
      "Date": "1/10/05",
      "Open": "2016.75",
      "High": "2025.9",
      "Low": "1974.8",
      "Close": "1982"
  },
  {
      "Date": "1/11/05",
      "Open": "1982.7",
      "High": "1988.9",
      "Low": "1947.35",
      "Close": "1952"
  },
  {
      "Date": "1/12/05",
      "Open": "1953.6",
      "High": "1966.65",
      "Low": "1900.85",
      "Close": "1913.6"
  },
  {
      "Date": "1/13/05",
      "Open": "1922.5",
      "High": "1963.4",
      "Low": "1916.95",
      "Close": "1954.5"
  },
  {
      "Date": "1/14/05",
      "Open": "1954.9",
      "High": "1961.4",
      "Low": "1922.85",
      "Close": "1931.1"
  },
  {
      "Date": "1/17/05",
      "Open": "1931.75",
      "High": "1944.55",
      "Low": "1902.45",
      "Close": "1932.9"
  },
  {
      "Date": "1/18/05",
      "Open": "1933.05",
      "High": "1956.95",
      "Low": "1925.35",
      "Close": "1934"
  },
  {
      "Date": "1/19/05",
      "Open": "1934.1",
      "High": "1945.65",
      "Low": "1922.35",
      "Close": "1926.6"
  },
  {
      "Date": "1/20/05",
      "Open": "1928.1",
      "High": "1940.95",
      "Low": "1900.05",
      "Close": "1925.3"
  },
  {
      "Date": "1/24/05",
      "Open": "1925.3",
      "High": "1932.75",
      "Low": "1902.9",
      "Close": "1909"
  },
  {
      "Date": "1/25/05",
      "Open": "1908.85",
      "High": "1934.25",
      "Low": "1894.4",
      "Close": "1931.8"
  },
  {
      "Date": "1/27/05",
      "Open": "1931.9",
      "High": "1961.75",
      "Low": "1929",
      "Close": "1955"
  },
  {
      "Date": "1/28/05",
      "Open": "1955.25",
      "High": "2014.25",
      "Low": "1950.85",
      "Close": "2008.3"
  },
  {
      "Date": "1/31/05",
      "Open": "2008.45",
      "High": "2060.4",
      "Low": "2006.35",
      "Close": "2057.6"
  },
  {
      "Date": "2/1/05",
      "Open": "2057.75",
      "High": "2072.5",
      "Low": "2045.25",
      "Close": "2059.8"
  },
  {
      "Date": "2/2/05",
      "Open": "2062.15",
      "High": "2074.5",
      "Low": "2045.5",
      "Close": "2052.2"
  },
  {
      "Date": "2/3/05",
      "Open": "2052.35",
      "High": "2083.75",
      "Low": "2052.35",
      "Close": "2079.4"
  },
  {
      "Date": "2/4/05",
      "Open": "2079.4",
      "High": "2099.2",
      "Low": "2060.8",
      "Close": "2077.9"
  },
  {
      "Date": "2/7/05",
      "Open": "2097.45",
      "High": "2098",
      "Low": "2049.85",
      "Close": "2055.1"
  },
  {
      "Date": "2/8/05",
      "Open": "2055",
      "High": "2065",
      "Low": "2043.6",
      "Close": "2055.1"
  },
  {
      "Date": "2/9/05",
      "Open": "2055.2",
      "High": "2077.7",
      "Low": "2055.2",
      "Close": "2070"
  },
  {
      "Date": "2/10/05",
      "Open": "2070.1",
      "High": "2075.1",
      "Low": "2049.85",
      "Close": "2063.3"
  },
  {
      "Date": "2/11/05",
      "Open": "2063.35",
      "High": "2084.5",
      "Low": "2063.35",
      "Close": "2082"
  },
  {
      "Date": "2/14/05",
      "Open": "2083.05",
      "High": "2110.15",
      "Low": "2083.05",
      "Close": "2098.2"
  },
  {
      "Date": "2/15/05",
      "Open": "2098.25",
      "High": "2101.6",
      "Low": "2081.2",
      "Close": "2089.9"
  },
  {
      "Date": "2/16/05",
      "Open": "2090",
      "High": "2103.4",
      "Low": "2059.45",
      "Close": "2068.8"
  },
  {
      "Date": "2/17/05",
      "Open": "2069.1",
      "High": "2069.15",
      "Low": "2045.85",
      "Close": "2061.9"
  },
  {
      "Date": "2/18/05",
      "Open": "2062.45",
      "High": "2076.7",
      "Low": "2048.85",
      "Close": "2055.5"
  },
  {
      "Date": "2/21/05",
      "Open": "2055.15",
      "High": "2065.75",
      "Low": "2039.9",
      "Close": "2043.2"
  },
  {
      "Date": "2/22/05",
      "Open": "2043.4",
      "High": "2061.65",
      "Low": "2036.6",
      "Close": "2058.4"
  },
  {
      "Date": "2/23/05",
      "Open": "2058.7",
      "High": "2065.15",
      "Low": "2051.35",
      "Close": "2057.1"
  },
  {
      "Date": "2/24/05",
      "Open": "2057.75",
      "High": "2070.5",
      "Low": "2052.4",
      "Close": "2055.3"
  },
  {
      "Date": "2/25/05",
      "Open": "2057.3",
      "High": "2081.85",
      "Low": "2051.2",
      "Close": "2060.9"
  },
  {
      "Date": "2/28/05",
      "Open": "2061.2",
      "High": "2106.2",
      "Low": "2047.7",
      "Close": "2103.2"
  },
  {
      "Date": "3/1/05",
      "Open": "2103.1",
      "High": "2115.1",
      "Low": "2073.8",
      "Close": "2084.4"
  },
  {
      "Date": "3/2/05",
      "Open": "2084.6",
      "High": "2096.35",
      "Low": "2080.55",
      "Close": "2093.2"
  },
  {
      "Date": "3/3/05",
      "Open": "2093.4",
      "High": "2132.75",
      "Low": "2093.35",
      "Close": "2128.8"
  },
  {
      "Date": "3/4/05",
      "Open": "2129.1",
      "High": "2152.75",
      "Low": "2129.1",
      "Close": "2148.1"
  },
  {
      "Date": "3/7/05",
      "Open": "2148.25",
      "High": "2166.85",
      "Low": "2143.05",
      "Close": "2160.1"
  },
  {
      "Date": "3/8/05",
      "Open": "2160.65",
      "High": "2173.85",
      "Low": "2154",
      "Close": "2168.9"
  },
  {
      "Date": "3/9/05",
      "Open": "2169.1",
      "High": "2183.45",
      "Low": "2141.35",
      "Close": "2160.8"
  },
  {
      "Date": "3/10/05",
      "Open": "2160.85",
      "High": "2170.2",
      "Low": "2145.75",
      "Close": "2167.4"
  },
  {
      "Date": "3/11/05",
      "Open": "2167.7",
      "High": "2182.1",
      "Low": "2148.7",
      "Close": "2154"
  },
  {
      "Date": "3/14/05",
      "Open": "2153.8",
      "High": "2164.15",
      "Low": "2140.9",
      "Close": "2146.3"
  },
  {
      "Date": "3/15/05",
      "Open": "2146.55",
      "High": "2151.35",
      "Low": "2122",
      "Close": "2128.9"
  },
  {
      "Date": "3/16/05",
      "Open": "2129.35",
      "High": "2158.9",
      "Low": "2121.1",
      "Close": "2125.5"
  },
  {
      "Date": "3/17/05",
      "Open": "2123.95",
      "High": "2126.55",
      "Low": "2090.45",
      "Close": "2098.5"
  },
  {
      "Date": "3/18/05",
      "Open": "2097.75",
      "High": "2112.25",
      "Low": "2077.2",
      "Close": "2109.1"
  },
  {
      "Date": "3/21/05",
      "Open": "2117.95",
      "High": "2124.8",
      "Low": "2089.35",
      "Close": "2096.6"
  },
  {
      "Date": "3/22/05",
      "Open": "2096.75",
      "High": "2099",
      "Low": "2056.5",
      "Close": "2061.6"
  },
  {
      "Date": "3/23/05",
      "Open": "2061.2",
      "High": "2067.45",
      "Low": "2019.85",
      "Close": "2026.4"
  },
  {
      "Date": "3/24/05",
      "Open": "2026.6",
      "High": "2042.45",
      "Low": "2007.35",
      "Close": "2015.4"
  },
  {
      "Date": "3/28/05",
      "Open": "2015.75",
      "High": "2046.85",
      "Low": "2015.25",
      "Close": "2029.4"
  },
  {
      "Date": "3/29/05",
      "Open": "2029.4",
      "High": "2032.65",
      "Low": "1971.55",
      "Close": "1983.8"
  },
  {
      "Date": "3/30/05",
      "Open": "1983.65",
      "High": "2000.45",
      "Low": "1971.15",
      "Close": "1993.7"
  },
  {
      "Date": "3/31/05",
      "Open": "1994.5",
      "High": "2043.6",
      "Low": "1994.5",
      "Close": "2035.6"
  },
  {
      "Date": "4/1/05",
      "Open": "2035.9",
      "High": "2071.2",
      "Low": "2024.25",
      "Close": "2067.6"
  },
  {
      "Date": "4/4/05",
      "Open": "2067.65",
      "High": "2076.6",
      "Low": "2054.9",
      "Close": "2063.4"
  },
  {
      "Date": "4/5/05",
      "Open": "2063.2",
      "High": "2077.95",
      "Low": "2043.7",
      "Close": "2052.5"
  },
  {
      "Date": "4/6/05",
      "Open": "2054",
      "High": "2073.1",
      "Low": "2051.3",
      "Close": "2069.3"
  },
  {
      "Date": "4/7/05",
      "Open": "2069.15",
      "High": "2084.9",
      "Low": "2048.05",
      "Close": "2052.8"
  },
  {
      "Date": "4/8/05",
      "Open": "2053.05",
      "High": "2057.5",
      "Low": "2024.8",
      "Close": "2031.2"
  },
  {
      "Date": "4/11/05",
      "Open": "2032.75",
      "High": "2033",
      "Low": "2001.85",
      "Close": "2008.2"
  },
  {
      "Date": "4/12/05",
      "Open": "2002.75",
      "High": "2027.8",
      "Low": "2002.75",
      "Close": "2024.9"
  },
  {
      "Date": "4/13/05",
      "Open": "2024.9",
      "High": "2038.85",
      "Low": "2018.1",
      "Close": "2025.4"
  },
  {
      "Date": "4/15/05",
      "Open": "2022.5",
      "High": "2022.5",
      "Low": "1952.75",
      "Close": "1956.3"
  },
  {
      "Date": "4/18/05",
      "Open": "1955.5",
      "High": "1959.25",
      "Low": "1914.85",
      "Close": "1927.8"
  },
  {
      "Date": "4/19/05",
      "Open": "1927.9",
      "High": "1961.15",
      "Low": "1902.8",
      "Close": "1909.4"
  },
  {
      "Date": "4/20/05",
      "Open": "1908.5",
      "High": "1941.85",
      "Low": "1902.9",
      "Close": "1929.7"
  },
  {
      "Date": "4/21/05",
      "Open": "1930.5",
      "High": "1950",
      "Low": "1911.4",
      "Close": "1948.5"
  },
  {
      "Date": "4/22/05",
      "Open": "1950.6",
      "High": "1972.95",
      "Low": "1950",
      "Close": "1967.3"
  },
  {
      "Date": "4/25/05",
      "Open": "1968.45",
      "High": "1974.2",
      "Low": "1952.4",
      "Close": "1970.9"
  },
  {
      "Date": "4/26/05",
      "Open": "1971.05",
      "High": "1972.85",
      "Low": "1951.8",
      "Close": "1957.1"
  },
  {
      "Date": "4/27/05",
      "Open": "1959.45",
      "High": "1962.2",
      "Low": "1930.35",
      "Close": "1935.4"
  },
  {
      "Date": "4/28/05",
      "Open": "1935.65",
      "High": "1950.25",
      "Low": "1921.05",
      "Close": "1941.3"
  },
  {
      "Date": "4/29/05",
      "Open": "1943.2",
      "High": "1943.2",
      "Low": "1896.3",
      "Close": "1902.5"
  },
  {
      "Date": "5/2/05",
      "Open": "1903.1",
      "High": "1925.6",
      "Low": "1898.15",
      "Close": "1916.7"
  },
  {
      "Date": "5/3/05",
      "Open": "1916.95",
      "High": "1940.1",
      "Low": "1911",
      "Close": "1920.7"
  },
  {
      "Date": "5/4/05",
      "Open": "1920.5",
      "High": "1944.45",
      "Low": "1920.15",
      "Close": "1942.6"
  },
  {
      "Date": "5/5/05",
      "Open": "1942.6",
      "High": "1973.35",
      "Low": "1942.05",
      "Close": "1963.3"
  },
  {
      "Date": "5/6/05",
      "Open": "1962.3",
      "High": "1984.4",
      "Low": "1947.3",
      "Close": "1977.5"
  },
  {
      "Date": "5/9/05",
      "Open": "1978.05",
      "High": "2003.15",
      "Low": "1978.05",
      "Close": "2000.7"
  },
  {
      "Date": "5/10/05",
      "Open": "2001",
      "High": "2007.8",
      "Low": "1989.1",
      "Close": "1994.3"
  },
  {
      "Date": "5/11/05",
      "Open": "1990.55",
      "High": "1991.85",
      "Low": "1975.05",
      "Close": "1985.9"
  },
  {
      "Date": "5/12/05",
      "Open": "1985.95",
      "High": "1999.85",
      "Low": "1981.95",
      "Close": "1993.1"
  },
  {
      "Date": "5/13/05",
      "Open": "1992.5",
      "High": "1992.75",
      "Low": "1978.9",
      "Close": "1988.3"
  },
  {
      "Date": "5/16/05",
      "Open": "1991.5",
      "High": "2014.95",
      "Low": "1989.7",
      "Close": "2012.6"
  },
  {
      "Date": "5/17/05",
      "Open": "2013.1",
      "High": "2024.6",
      "Low": "1984.75",
      "Close": "1990.8"
  },
  {
      "Date": "5/18/05",
      "Open": "1990.65",
      "High": "1990.95",
      "Low": "1964.65",
      "Close": "1982.7"
  },
  {
      "Date": "5/19/05",
      "Open": "1983.15",
      "High": "2008.35",
      "Low": "1983.15",
      "Close": "1990.8"
  },
  {
      "Date": "5/20/05",
      "Open": "1991.85",
      "High": "1998.2",
      "Low": "1975.95",
      "Close": "1992.4"
  },
  {
      "Date": "5/23/05",
      "Open": "1992.1",
      "High": "2019.35",
      "Low": "1991.7",
      "Close": "2013.9"
  },
  {
      "Date": "5/24/05",
      "Open": "2016",
      "High": "2031.9",
      "Low": "2009.55",
      "Close": "2028.6"
  },
  {
      "Date": "5/25/05",
      "Open": "2028.55",
      "High": "2047.45",
      "Low": "2019",
      "Close": "2043.8"
  },
  {
      "Date": "5/26/05",
      "Open": "2043.5",
      "High": "2078.65",
      "Low": "2025.65",
      "Close": "2074.7"
  },
  {
      "Date": "5/27/05",
      "Open": "2073.25",
      "High": "2099.35",
      "Low": "2069.5",
      "Close": "2076.4"
  },
  {
      "Date": "5/30/05",
      "Open": "2076.55",
      "High": "2086.85",
      "Low": "2064.85",
      "Close": "2072.4"
  },
  {
      "Date": "5/31/05",
      "Open": "2072.5",
      "High": "2091.75",
      "Low": "2066.55",
      "Close": "2087.5"
  },
  {
      "Date": "6/1/05",
      "Open": "2087.8",
      "High": "2097.6",
      "Low": "2081.2",
      "Close": "2087.5"
  },
  {
      "Date": "6/2/05",
      "Open": "2087.85",
      "High": "2093.45",
      "Low": "2062.55",
      "Close": "2064.6"
  },
  {
      "Date": "6/3/05",
      "Open": "2064.1",
      "High": "2097.25",
      "Low": "2061.35",
      "Close": "2094.2"
  },
  {
      "Date": "6/4/05",
      "Open": "2094.1",
      "High": "2098.1",
      "Low": "2087",
      "Close": "2092.3"
  },
  {
      "Date": "6/6/05",
      "Open": "2092",
      "High": "2109.1",
      "Low": "2087.4",
      "Close": "2092.8"
  },
  {
      "Date": "6/7/05",
      "Open": "2091.95",
      "High": "2102.85",
      "Low": "2084.35",
      "Close": "2098.1"
  },
  {
      "Date": "6/8/05",
      "Open": "2094.7",
      "High": "2115.25",
      "Low": "2094.05",
      "Close": "2112.4"
  },
  {
      "Date": "6/9/05",
      "Open": "2112.25",
      "High": "2118.65",
      "Low": "2097.55",
      "Close": "2103.2"
  },
  {
      "Date": "6/10/05",
      "Open": "2103.45",
      "High": "2133.1",
      "Low": "2086.3",
      "Close": "2090.6"
  },
  {
      "Date": "6/13/05",
      "Open": "2090.7",
      "High": "2106.65",
      "Low": "2081.7",
      "Close": "2102.7"
  },
  {
      "Date": "6/14/05",
      "Open": "2103.65",
      "High": "2113.85",
      "Low": "2098.7",
      "Close": "2112.3"
  },
  {
      "Date": "6/15/05",
      "Open": "2110.5",
      "High": "2131.95",
      "Low": "2110.35",
      "Close": "2128.6"
  },
  {
      "Date": "6/16/05",
      "Open": "2128.3",
      "High": "2135.1",
      "Low": "2114.55",
      "Close": "2123.7"
  },
  {
      "Date": "6/17/05",
      "Open": "2123.6",
      "High": "2131.75",
      "Low": "2103.9",
      "Close": "2123.4"
  },
  {
      "Date": "6/20/05",
      "Open": "2130.8",
      "High": "2150.25",
      "Low": "2124.65",
      "Close": "2144.3"
  },
  {
      "Date": "6/21/05",
      "Open": "2144.75",
      "High": "2173.25",
      "Low": "2140.15",
      "Close": "2170"
  },
  {
      "Date": "6/22/05",
      "Open": "2167.8",
      "High": "2196.45",
      "Low": "2167.35",
      "Close": "2187.3"
  },
  {
      "Date": "6/23/05",
      "Open": "2187.35",
      "High": "2192.1",
      "Low": "2174.05",
      "Close": "2183.8"
  },
  {
      "Date": "6/24/05",
      "Open": "2184",
      "High": "2204.45",
      "Low": "2162.95",
      "Close": "2194.3"
  },
  {
      "Date": "6/27/05",
      "Open": "2194.35",
      "High": "2219.65",
      "Low": "2188.15",
      "Close": "2199.8"
  },
  {
      "Date": "6/28/05",
      "Open": "2199.8",
      "High": "2206.8",
      "Low": "2165.9",
      "Close": "2169.8"
  },
  {
      "Date": "6/29/05",
      "Open": "2170.2",
      "High": "2194.25",
      "Low": "2162",
      "Close": "2191.6"
  },
  {
      "Date": "6/30/05",
      "Open": "2191.55",
      "High": "2226.15",
      "Low": "2189.45",
      "Close": "2220.6"
  },
  {
      "Date": "7/1/05",
      "Open": "2220.45",
      "High": "2220.55",
      "Low": "2198.9",
      "Close": "2211.9"
  },
  {
      "Date": "7/4/05",
      "Open": "2212.05",
      "High": "2232.2",
      "Low": "2211.4",
      "Close": "2230.6"
  },
  {
      "Date": "7/5/05",
      "Open": "2231.2",
      "High": "2238.6",
      "Low": "2205.1",
      "Close": "2210.7"
  },
  {
      "Date": "7/6/05",
      "Open": "2211.35",
      "High": "2232.65",
      "Low": "2211.3",
      "Close": "2228.2"
  },
  {
      "Date": "7/7/05",
      "Open": "2228.3",
      "High": "2235.8",
      "Low": "2171.25",
      "Close": "2179.4"
  },
  {
      "Date": "7/8/05",
      "Open": "2183.55",
      "High": "2210.7",
      "Low": "2179.05",
      "Close": "2196.2"
  },
  {
      "Date": "7/11/05",
      "Open": "2195.55",
      "High": "2224.65",
      "Low": "2195.55",
      "Close": "2218.8"
  },
  {
      "Date": "7/12/05",
      "Open": "2219.1",
      "High": "2233.2",
      "Low": "2191.7",
      "Close": "2220.8"
  },
  {
      "Date": "7/13/05",
      "Open": "2221.45",
      "High": "2234.15",
      "Low": "2200.05",
      "Close": "2204"
  },
  {
      "Date": "7/14/05",
      "Open": "2204.4",
      "High": "2212.55",
      "Low": "2178.6",
      "Close": "2185.1"
  },
  {
      "Date": "7/15/05",
      "Open": "2185.1",
      "High": "2216.5",
      "Low": "2181.85",
      "Close": "2212.5"
  },
  {
      "Date": "7/18/05",
      "Open": "2212.95",
      "High": "2248.7",
      "Low": "2212.95",
      "Close": "2234"
  },
  {
      "Date": "7/19/05",
      "Open": "2234.25",
      "High": "2246.7",
      "Low": "2227.2",
      "Close": "2237.3"
  },
  {
      "Date": "7/20/05",
      "Open": "2236.9",
      "High": "2252",
      "Low": "2236.9",
      "Close": "2241.9"
  },
  {
      "Date": "7/21/05",
      "Open": "2240.95",
      "High": "2251.85",
      "Low": "2221.2",
      "Close": "2230.5"
  },
  {
      "Date": "7/22/05",
      "Open": "2231",
      "High": "2268.55",
      "Low": "2223.15",
      "Close": "2265.6"
  },
  {
      "Date": "7/25/05",
      "Open": "2266.65",
      "High": "2293.95",
      "Low": "2266.65",
      "Close": "2291.7"
  },
  {
      "Date": "7/26/05",
      "Open": "2289.25",
      "High": "2307.1",
      "Low": "2279.8",
      "Close": "2303.1"
  },
  {
      "Date": "7/27/05",
      "Open": "2304.25",
      "High": "2324",
      "Low": "2292.85",
      "Close": "2319.1"
  },
  {
      "Date": "7/29/05",
      "Open": "2318.05",
      "High": "2332.55",
      "Low": "2280.85",
      "Close": "2312.3"
  },
  {
      "Date": "8/1/05",
      "Open": "2312.05",
      "High": "2329.9",
      "Low": "2294.25",
      "Close": "2318"
  },
  {
      "Date": "8/2/05",
      "Open": "2321.9",
      "High": "2357.15",
      "Low": "2319.75",
      "Close": "2353.6"
  },
  {
      "Date": "8/3/05",
      "Open": "2359.1",
      "High": "2377",
      "Low": "2345.2",
      "Close": "2357"
  },
  {
      "Date": "8/4/05",
      "Open": "2357.75",
      "High": "2377.35",
      "Low": "2352.05",
      "Close": "2367.8"
  },
  {
      "Date": "8/5/05",
      "Open": "2367.35",
      "High": "2377.1",
      "Low": "2355.95",
      "Close": "2361.2"
  },
  {
      "Date": "8/8/05",
      "Open": "2361.95",
      "High": "2386.95",
      "Low": "2320.05",
      "Close": "2324.4"
  },
  {
      "Date": "8/9/05",
      "Open": "2324.75",
      "High": "2342.2",
      "Low": "2303.1",
      "Close": "2318.7"
  },
  {
      "Date": "8/10/05",
      "Open": "2322.3",
      "High": "2364.8",
      "Low": "2322.05",
      "Close": "2360.1"
  },
  {
      "Date": "8/11/05",
      "Open": "2358.9",
      "High": "2387.3",
      "Low": "2355.5",
      "Close": "2380.9"
  },
  {
      "Date": "8/12/05",
      "Open": "2381.05",
      "High": "2390.45",
      "Low": "2356.9",
      "Close": "2361.5"
  },
  {
      "Date": "8/16/05",
      "Open": "2361.65",
      "High": "2379.4",
      "Low": "2356.85",
      "Close": "2369.8"
  },
  {
      "Date": "8/17/05",
      "Open": "2369.8",
      "High": "2406.25",
      "Low": "2357.5",
      "Close": "2403.1"
  },
  {
      "Date": "8/18/05",
      "Open": "2404.65",
      "High": "2426.65",
      "Low": "2380.7",
      "Close": "2388.4"
  },
  {
      "Date": "8/19/05",
      "Open": "2400.8",
      "High": "2409.35",
      "Low": "2378.45",
      "Close": "2383.4"
  },
  {
      "Date": "8/22/05",
      "Open": "2383.45",
      "High": "2403.45",
      "Low": "2355.75",
      "Close": "2367.8"
  },
  {
      "Date": "8/23/05",
      "Open": "2369.55",
      "High": "2380.5",
      "Low": "2320.35",
      "Close": "2326.1"
  },
  {
      "Date": "8/24/05",
      "Open": "2326.1",
      "High": "2334.9",
      "Low": "2300.45",
      "Close": "2322.5"
  },
  {
      "Date": "8/25/05",
      "Open": "2323.1",
      "High": "2359.2",
      "Low": "2320.7",
      "Close": "2354.5"
  },
  {
      "Date": "8/26/05",
      "Open": "2354.6",
      "High": "2368.05",
      "Low": "2340.2",
      "Close": "2357"
  },
  {
      "Date": "8/29/05",
      "Open": "2359.2",
      "High": "2365.85",
      "Low": "2312.6",
      "Close": "2337.6"
  },
  {
      "Date": "8/30/05",
      "Open": "2337.8",
      "High": "2373.8",
      "Low": "2337.75",
      "Close": "2367.7"
  },
  {
      "Date": "8/31/05",
      "Open": "2369.05",
      "High": "2387",
      "Low": "2355",
      "Close": "2384.6"
  },
  {
      "Date": "9/1/05",
      "Open": "2384.7",
      "High": "2412.35",
      "Low": "2382.9",
      "Close": "2405.7"
  },
  {
      "Date": "9/2/05",
      "Open": "2406.45",
      "High": "2422.1",
      "Low": "2396.1",
      "Close": "2415.8"
  },
  {
      "Date": "9/5/05",
      "Open": "2417.1",
      "High": "2439.55",
      "Low": "2414.95",
      "Close": "2422.9"
  },
  {
      "Date": "9/6/05",
      "Open": "2422.4",
      "High": "2436.25",
      "Low": "2417",
      "Close": "2428.6"
  },
  {
      "Date": "9/8/05",
      "Open": "2429",
      "High": "2456.9",
      "Low": "2429",
      "Close": "2454.4"
  },
  {
      "Date": "9/9/05",
      "Open": "2448.8",
      "High": "2462.8",
      "Low": "2441.9",
      "Close": "2455.4"
  },
  {
      "Date": "9/12/05",
      "Open": "2455.85",
      "High": "2486.35",
      "Low": "2455.85",
      "Close": "2484.1"
  },
  {
      "Date": "9/13/05",
      "Open": "2484",
      "High": "2503",
      "Low": "2477.1",
      "Close": "2500.3"
  },
  {
      "Date": "9/14/05",
      "Open": "2500.4",
      "High": "2514.75",
      "Low": "2476",
      "Close": "2492.4"
  },
  {
      "Date": "9/15/05",
      "Open": "2492.85",
      "High": "2527.5",
      "Low": "2492.75",
      "Close": "2523.9"
  },
  {
      "Date": "9/16/05",
      "Open": "2524.9",
      "High": "2555.1",
      "Low": "2519.05",
      "Close": "2552.3"
  },
  {
      "Date": "9/19/05",
      "Open": "2555.45",
      "High": "2579.7",
      "Low": "2550.45",
      "Close": "2567.1"
  },
  {
      "Date": "9/20/05",
      "Open": "2567.2",
      "High": "2582.8",
      "Low": "2546.6",
      "Close": "2578"
  },
  {
      "Date": "9/21/05",
      "Open": "2576.7",
      "High": "2585.6",
      "Low": "2504.9",
      "Close": "2567.3"
  },
  {
      "Date": "9/22/05",
      "Open": "2567.85",
      "High": "2580.4",
      "Low": "2465.85",
      "Close": "2476.5"
  },
  {
      "Date": "9/23/05",
      "Open": "2480.5",
      "High": "2507.9",
      "Low": "2453.05",
      "Close": "2477.7"
  },
  {
      "Date": "9/26/05",
      "Open": "2477.85",
      "High": "2560.85",
      "Low": "2477.85",
      "Close": "2557.3"
  },
  {
      "Date": "9/27/05",
      "Open": "2558.5",
      "High": "2592.5",
      "Low": "2549.85",
      "Close": "2574.8"
  },
  {
      "Date": "9/28/05",
      "Open": "2574.45",
      "High": "2602.95",
      "Low": "2559.85",
      "Close": "2598"
  },
  {
      "Date": "9/29/05",
      "Open": "2598.6",
      "High": "2633.9",
      "Low": "2589.45",
      "Close": "2611.2"
  },
  {
      "Date": "9/30/05",
      "Open": "2607.9",
      "High": "2623.05",
      "Low": "2567.75",
      "Close": "2601.4"
  },
  {
      "Date": "10/3/05",
      "Open": "2601",
      "High": "2635",
      "Low": "2597.2",
      "Close": "2630"
  },
  {
      "Date": "10/4/05",
      "Open": "2630",
      "High": "2667.05",
      "Low": "2629.6",
      "Close": "2663.3"
  },
  {
      "Date": "10/5/05",
      "Open": "2663.7",
      "High": "2669.2",
      "Low": "2636.6",
      "Close": "2644.4"
  },
  {
      "Date": "10/6/05",
      "Open": "2643.8",
      "High": "2643.8",
      "Low": "2571.3",
      "Close": "2579.1"
  },
  {
      "Date": "10/7/05",
      "Open": "2577.2",
      "High": "2603.25",
      "Low": "2547.55",
      "Close": "2574"
  },
  {
      "Date": "10/10/05",
      "Open": "2577.1",
      "High": "2595.15",
      "Low": "2561",
      "Close": "2566.8"
  },
  {
      "Date": "10/11/05",
      "Open": "2569.15",
      "High": "2592",
      "Low": "2533.7",
      "Close": "2589.5"
  },
  {
      "Date": "10/13/05",
      "Open": "2584.4",
      "High": "2589.35",
      "Low": "2529.05",
      "Close": "2537.3"
  },
  {
      "Date": "10/14/05",
      "Open": "2537.7",
      "High": "2548",
      "Low": "2478",
      "Close": "2484.4"
  },
  {
      "Date": "10/17/05",
      "Open": "2488.4",
      "High": "2498.6",
      "Low": "2459.5",
      "Close": "2485.1"
  },
  {
      "Date": "10/18/05",
      "Open": "2485.25",
      "High": "2517.7",
      "Low": "2452.5",
      "Close": "2468.2"
  },
  {
      "Date": "10/19/05",
      "Open": "2467.8",
      "High": "2467.85",
      "Low": "2394.95",
      "Close": "2412.4"
  },
  {
      "Date": "10/20/05",
      "Open": "2421.4",
      "High": "2463.85",
      "Low": "2363.55",
      "Close": "2395.4"
  },
  {
      "Date": "10/21/05",
      "Open": "2397.85",
      "High": "2447.85",
      "Low": "2384.05",
      "Close": "2443.7"
  },
  {
      "Date": "10/24/05",
      "Open": "2444.2",
      "High": "2460",
      "Low": "2388.4",
      "Close": "2394.8"
  },
  {
      "Date": "10/25/05",
      "Open": "2394.95",
      "High": "2444.6",
      "Low": "2390.85",
      "Close": "2418.2"
  },
  {
      "Date": "10/26/05",
      "Open": "2418.45",
      "High": "2434",
      "Low": "2401.1",
      "Close": "2408.5"
  },
  {
      "Date": "10/27/05",
      "Open": "2408.95",
      "High": "2416.45",
      "Low": "2338.6",
      "Close": "2352.9"
  },
  {
      "Date": "10/28/05",
      "Open": "2352.65",
      "High": "2355.15",
      "Low": "2307.45",
      "Close": "2316"
  },
  {
      "Date": "10/31/05",
      "Open": "2314.85",
      "High": "2373.4",
      "Low": "2314.2",
      "Close": "2370.9"
  },
  {
      "Date": "11/1/05",
      "Open": "2366.8",
      "High": "2410.15",
      "Low": "2366.8",
      "Close": "2386.7"
  },
  {
      "Date": "11/2/05",
      "Open": "2386.45",
      "High": "2423.8",
      "Low": "2367.75",
      "Close": "2419"
  },
  {
      "Date": "11/7/05",
      "Open": "2419.25",
      "High": "2464.65",
      "Low": "2411.6",
      "Close": "2461.6"
  },
  {
      "Date": "11/8/05",
      "Open": "2463.65",
      "High": "2502.9",
      "Low": "2460",
      "Close": "2492.6"
  },
  {
      "Date": "11/9/05",
      "Open": "2493.1",
      "High": "2519.4",
      "Low": "2475.7",
      "Close": "2489.1"
  },
  {
      "Date": "11/10/05",
      "Open": "2489.1",
      "High": "2504.75",
      "Low": "2480.85",
      "Close": "2500.7"
  },
  {
      "Date": "11/11/05",
      "Open": "2500.85",
      "High": "2551.4",
      "Low": "2500.85",
      "Close": "2548.6"
  },
  {
      "Date": "11/14/05",
      "Open": "2548.55",
      "High": "2576.95",
      "Low": "2534.4",
      "Close": "2558.7"
  },
  {
      "Date": "11/16/05",
      "Open": "2559.45",
      "High": "2585.95",
      "Low": "2559.45",
      "Close": "2582.7"
  },
  {
      "Date": "11/17/05",
      "Open": "2558.45",
      "High": "2608.85",
      "Low": "2558.45",
      "Close": "2603.9"
  },
  {
      "Date": "11/18/05",
      "Open": "2604",
      "High": "2632.35",
      "Low": "2595.15",
      "Close": "2620"
  },
  {
      "Date": "11/21/05",
      "Open": "2620.1",
      "High": "2626.4",
      "Low": "2591.75",
      "Close": "2602.5"
  },
  {
      "Date": "11/22/05",
      "Open": "2603.25",
      "High": "2614",
      "Low": "2567.05",
      "Close": "2572.8"
  },
  {
      "Date": "11/23/05",
      "Open": "2572.85",
      "High": "2613.4",
      "Low": "2563.1",
      "Close": "2608.6"
  },
  {
      "Date": "11/24/05",
      "Open": "2608.9",
      "High": "2643.7",
      "Low": "2608.7",
      "Close": "2635"
  },
  {
      "Date": "11/25/05",
      "Open": "2635.35",
      "High": "2668.9",
      "Low": "2633.75",
      "Close": "2664.3"
  },
  {
      "Date": "11/26/05",
      "Open": "2664.85",
      "High": "2686.5",
      "Low": "2664.7",
      "Close": "2683.4"
  },
  {
      "Date": "11/28/05",
      "Open": "2683.6",
      "High": "2717.15",
      "Low": "2682.65",
      "Close": "2712"
  },
  {
      "Date": "11/29/05",
      "Open": "2712.35",
      "High": "2713.9",
      "Low": "2679.9",
      "Close": "2698.3"
  },
  {
      "Date": "11/30/05",
      "Open": "2698.1",
      "High": "2727.05",
      "Low": "2647.1",
      "Close": "2652.2"
  },
  {
      "Date": "12/1/05",
      "Open": "2651.6",
      "High": "2705",
      "Low": "2641.95",
      "Close": "2698.9"
  },
  {
      "Date": "12/2/05",
      "Open": "2699.55",
      "High": "2730.7",
      "Low": "2691.5",
      "Close": "2697.9"
  },
  {
      "Date": "12/5/05",
      "Open": "2697.6",
      "High": "2710.65",
      "Low": "2654.35",
      "Close": "2660.5"
  },
  {
      "Date": "12/6/05",
      "Open": "2661.4",
      "High": "2694.4",
      "Low": "2647.35",
      "Close": "2662.3"
  },
  {
      "Date": "12/7/05",
      "Open": "2662.3",
      "High": "2697.1",
      "Low": "2662.3",
      "Close": "2693"
  },
  {
      "Date": "12/8/05",
      "Open": "2694.95",
      "High": "2711.6",
      "Low": "2673.5",
      "Close": "2706.7"
  },
  {
      "Date": "12/9/05",
      "Open": "2706.75",
      "High": "2761.1",
      "Low": "2698",
      "Close": "2756.4"
  },
  {
      "Date": "12/12/05",
      "Open": "2756.4",
      "High": "2789.35",
      "Low": "2756.4",
      "Close": "2776.2"
  },
  {
      "Date": "12/13/05",
      "Open": "2776.8",
      "High": "2815.3",
      "Low": "2764.65",
      "Close": "2812.3"
  },
  {
      "Date": "12/14/05",
      "Open": "2812.8",
      "High": "2825.65",
      "Low": "2788.3",
      "Close": "2804.5"
  },
  {
      "Date": "12/15/05",
      "Open": "2805.2",
      "High": "2821.5",
      "Low": "2763.35",
      "Close": "2778.5"
  },
  {
      "Date": "12/16/05",
      "Open": "2778.65",
      "High": "2814.9",
      "Low": "2766.5",
      "Close": "2810.1"
  },
  {
      "Date": "12/19/05",
      "Open": "2808.35",
      "High": "2846",
      "Low": "2803.45",
      "Close": "2842.6"
  },
  {
      "Date": "12/20/05",
      "Open": "2843.15",
      "High": "2853.1",
      "Low": "2815.2",
      "Close": "2826.2"
  },
  {
      "Date": "12/21/05",
      "Open": "2826.45",
      "High": "2847.3",
      "Low": "2799.45",
      "Close": "2822.9"
  },
  {
      "Date": "12/22/05",
      "Open": "2826.7",
      "High": "2846.65",
      "Low": "2818.65",
      "Close": "2835.2"
  },
  {
      "Date": "12/23/05",
      "Open": "2835.7",
      "High": "2857",
      "Low": "2799.7",
      "Close": "2804.8"
  },
  {
      "Date": "12/26/05",
      "Open": "2804.3",
      "High": "2804.3",
      "Low": "2741.8",
      "Close": "2749.6"
  },
  {
      "Date": "12/27/05",
      "Open": "2746.25",
      "High": "2812.6",
      "Low": "2725.7",
      "Close": "2805.9"
  },
  {
      "Date": "12/28/05",
      "Open": "2806.8",
      "High": "2824.3",
      "Low": "2780",
      "Close": "2794"
  },
  {
      "Date": "12/29/05",
      "Open": "2792.75",
      "High": "2829.4",
      "Low": "2792.75",
      "Close": "2821.9"
  },
  {
      "Date": "12/30/05",
      "Open": "2823.95",
      "High": "2845.55",
      "Low": "2812.75",
      "Close": "2836.5"
  },
  {
      "Date": "1/2/06",
      "Open": "2836.8",
      "High": "2849.45",
      "Low": "2825.4",
      "Close": "2835.9"
  },
  {
      "Date": "1/3/06",
      "Open": "2835.95",
      "High": "2887.2",
      "Low": "2832.05",
      "Close": "2883.3"
  },
  {
      "Date": "1/4/06",
      "Open": "2883",
      "High": "2909.35",
      "Low": "2883",
      "Close": "2904.4"
  },
  {
      "Date": "1/5/06",
      "Open": "2904.45",
      "High": "2916.2",
      "Low": "2884.8",
      "Close": "2899.8"
  },
  {
      "Date": "1/6/06",
      "Open": "2899.85",
      "High": "2921.7",
      "Low": "2877.25",
      "Close": "2914"
  },
  {
      "Date": "1/9/06",
      "Open": "2913.35",
      "High": "2927.25",
      "Low": "2898.25",
      "Close": "2910.1"
  },
  {
      "Date": "1/10/06",
      "Open": "2910.15",
      "High": "2913.05",
      "Low": "2865.6",
      "Close": "2870.8"
  },
  {
      "Date": "1/12/06",
      "Open": "2869.35",
      "High": "2869.35",
      "Low": "2824",
      "Close": "2850.7"
  },
  {
      "Date": "1/13/06",
      "Open": "2851.4",
      "High": "2878.35",
      "Low": "2846.7",
      "Close": "2850.5"
  },
  {
      "Date": "1/16/06",
      "Open": "2851.35",
      "High": "2855.7",
      "Low": "2824.05",
      "Close": "2833.1"
  },
  {
      "Date": "1/17/06",
      "Open": "2833.8",
      "High": "2861.25",
      "Low": "2820.65",
      "Close": "2829.1"
  },
  {
      "Date": "1/18/06",
      "Open": "2809.9",
      "High": "2840.1",
      "Low": "2783.85",
      "Close": "2809.2"
  },
  {
      "Date": "1/19/06",
      "Open": "2811.1",
      "High": "2875.95",
      "Low": "2811.1",
      "Close": "2870.8"
  },
  {
      "Date": "1/20/06",
      "Open": "2870.8",
      "High": "2912.8",
      "Low": "2870.8",
      "Close": "2900.9"
  },
  {
      "Date": "1/23/06",
      "Open": "2900.3",
      "High": "2900.3",
      "Low": "2870.95",
      "Close": "2884"
  },
  {
      "Date": "1/24/06",
      "Open": "2886.35",
      "High": "2914.25",
      "Low": "2885.75",
      "Close": "2908"
  },
  {
      "Date": "1/25/06",
      "Open": "2908.75",
      "High": "2949.1",
      "Low": "2871.25",
      "Close": "2940.3"
  },
  {
      "Date": "1/27/06",
      "Open": "2941.9",
      "High": "2989.7",
      "Low": "2941.9",
      "Close": "2982.7"
  },
  {
      "Date": "1/30/06",
      "Open": "2983.3",
      "High": "3002.2",
      "Low": "2963.65",
      "Close": "2974.5"
  },
  {
      "Date": "1/31/06",
      "Open": "2968.95",
      "High": "3005.1",
      "Low": "2968.85",
      "Close": "3001.1"
  },
  {
      "Date": "2/1/06",
      "Open": "3001.3",
      "High": "3011.05",
      "Low": "2960.9",
      "Close": "2971.5"
  },
  {
      "Date": "2/2/06",
      "Open": "2972.2",
      "High": "2997.25",
      "Low": "2957.55",
      "Close": "2967.4"
  },
  {
      "Date": "2/3/06",
      "Open": "2968.1",
      "High": "2973.9",
      "Low": "2931.85",
      "Close": "2940.6"
  },
  {
      "Date": "2/6/06",
      "Open": "2940.95",
      "High": "3009.45",
      "Low": "2928.1",
      "Close": "3000.4"
  },
  {
      "Date": "2/7/06",
      "Open": "3001.15",
      "High": "3025.1",
      "Low": "2991.25",
      "Close": "3020.1"
  },
  {
      "Date": "2/8/06",
      "Open": "3018.2",
      "High": "3021.25",
      "Low": "2984.9",
      "Close": "3008.9"
  },
  {
      "Date": "2/10/06",
      "Open": "3009.15",
      "High": "3031.75",
      "Low": "3009.1",
      "Close": "3027.5"
  },
  {
      "Date": "2/13/06",
      "Open": "3026.15",
      "High": "3042.75",
      "Low": "3018.5",
      "Close": "3041.1"
  },
  {
      "Date": "2/14/06",
      "Open": "3040.25",
      "High": "3051.7",
      "Low": "3009.9",
      "Close": "3017.5"
  },
  {
      "Date": "2/15/06",
      "Open": "3001.4",
      "High": "3048.8",
      "Low": "2995.3",
      "Close": "3022.2"
  },
  {
      "Date": "2/16/06",
      "Open": "3023.1",
      "High": "3036.65",
      "Low": "3013.7",
      "Close": "3021.6"
  },
  {
      "Date": "2/17/06",
      "Open": "3021.65",
      "High": "3038.8",
      "Low": "2976.7",
      "Close": "2981.5"
  },
  {
      "Date": "2/20/06",
      "Open": "2982.35",
      "High": "3010.65",
      "Low": "2955.85",
      "Close": "3005.8"
  },
  {
      "Date": "2/21/06",
      "Open": "3008.55",
      "High": "3042.05",
      "Low": "3008.55",
      "Close": "3035.5"
  },
  {
      "Date": "2/22/06",
      "Open": "3035.65",
      "High": "3055.65",
      "Low": "3032.5",
      "Close": "3050.8"
  },
  {
      "Date": "2/23/06",
      "Open": "3050.85",
      "High": "3078.4",
      "Low": "3050.85",
      "Close": "3062.1"
  },
  {
      "Date": "2/24/06",
      "Open": "3061.7",
      "High": "3072",
      "Low": "3041.6",
      "Close": "3050"
  },
  {
      "Date": "2/27/06",
      "Open": "3050.3",
      "High": "3070.35",
      "Low": "3050.3",
      "Close": "3067.4"
  },
  {
      "Date": "2/28/06",
      "Open": "3067.9",
      "High": "3090.3",
      "Low": "3031.8",
      "Close": "3074.7"
  },
  {
      "Date": "3/1/06",
      "Open": "3074.6",
      "High": "3127.25",
      "Low": "3064",
      "Close": "3123.1"
  },
  {
      "Date": "3/2/06",
      "Open": "3124.25",
      "High": "3170.35",
      "Low": "3124.25",
      "Close": "3150.7"
  },
  {
      "Date": "3/3/06",
      "Open": "3151.3",
      "High": "3167.2",
      "Low": "3132.6",
      "Close": "3147.3"
  },
  {
      "Date": "3/6/06",
      "Open": "3147.25",
      "High": "3194",
      "Low": "3147.2",
      "Close": "3190.4"
  },
  {
      "Date": "3/7/06",
      "Open": "3190.45",
      "High": "3192.95",
      "Low": "3166.75",
      "Close": "3182.8"
  },
  {
      "Date": "3/8/06",
      "Open": "3183.45",
      "High": "3187.5",
      "Low": "3107.9",
      "Close": "3116.7"
  },
  {
      "Date": "3/9/06",
      "Open": "3116.75",
      "High": "3132.65",
      "Low": "3069.85",
      "Close": "3129.1"
  },
  {
      "Date": "3/10/06",
      "Open": "3129.05",
      "High": "3189.35",
      "Low": "3128.6",
      "Close": "3183.9"
  },
  {
      "Date": "3/13/06",
      "Open": "3184.1",
      "High": "3221.3",
      "Low": "3174.05",
      "Close": "3202.6"
  },
  {
      "Date": "3/14/06",
      "Open": "3197.2",
      "High": "3223.45",
      "Low": "3184.05",
      "Close": "3195.3"
  },
  {
      "Date": "3/16/06",
      "Open": "3197.3",
      "High": "3230.4",
      "Low": "3196.95",
      "Close": "3226.6"
  },
  {
      "Date": "3/17/06",
      "Open": "3226.9",
      "High": "3258.3",
      "Low": "3226.9",
      "Close": "3234"
  },
  {
      "Date": "3/20/06",
      "Open": "3234.2",
      "High": "3268.25",
      "Low": "3234.2",
      "Close": "3265.6"
  },
  {
      "Date": "3/21/06",
      "Open": "3264.65",
      "High": "3292.15",
      "Low": "3247.25",
      "Close": "3262.3"
  },
  {
      "Date": "3/22/06",
      "Open": "3263.35",
      "High": "3267.95",
      "Low": "3221.95",
      "Close": "3240.1"
  },
  {
      "Date": "3/23/06",
      "Open": "3240.85",
      "High": "3265.6",
      "Low": "3225.2",
      "Close": "3247.1"
  },
  {
      "Date": "3/24/06",
      "Open": "3247.15",
      "High": "3286.2",
      "Low": "3242.6",
      "Close": "3279.8"
  },
  {
      "Date": "3/27/06",
      "Open": "3283.4",
      "High": "3327.05",
      "Low": "3280.8",
      "Close": "3321.6"
  },
  {
      "Date": "3/28/06",
      "Open": "3321.45",
      "High": "3344.5",
      "Low": "3311.85",
      "Close": "3325"
  },
  {
      "Date": "3/29/06",
      "Open": "3324.85",
      "High": "3359.8",
      "Low": "3324.8",
      "Close": "3354.2"
  },
  {
      "Date": "3/30/06",
      "Open": "3354.25",
      "High": "3433.85",
      "Low": "3354.25",
      "Close": "3418.9"
  },
  {
      "Date": "3/31/06",
      "Open": "3420.55",
      "High": "3425.45",
      "Low": "3381.9",
      "Close": "3402.5"
  },
  {
      "Date": "4/3/06",
      "Open": "3403.15",
      "High": "3478.25",
      "Low": "3402.45",
      "Close": "3473.3"
  },
  {
      "Date": "4/4/06",
      "Open": "3473.1",
      "High": "3508.25",
      "Low": "3463.85",
      "Close": "3483.1"
  },
  {
      "Date": "4/5/06",
      "Open": "3483.15",
      "High": "3513.95",
      "Low": "3483.15",
      "Close": "3510.9"
  },
  {
      "Date": "4/7/06",
      "Open": "3525.6",
      "High": "3555.5",
      "Low": "3445.9",
      "Close": "3454.8"
  },
  {
      "Date": "4/10/06",
      "Open": "3455",
      "High": "3489.05",
      "Low": "3430.4",
      "Close": "3478.4"
  },
  {
      "Date": "4/12/06",
      "Open": "3479.1",
      "High": "3484.65",
      "Low": "3366.75",
      "Close": "3380"
  },
  {
      "Date": "4/13/06",
      "Open": "3380.4",
      "High": "3391.05",
      "Low": "3290.35",
      "Close": "3345.5"
  },
  {
      "Date": "4/17/06",
      "Open": "3342.15",
      "High": "3433.65",
      "Low": "3342.15",
      "Close": "3425.1"
  },
  {
      "Date": "4/18/06",
      "Open": "3429",
      "High": "3521.95",
      "Low": "3429",
      "Close": "3518.1"
  },
  {
      "Date": "4/19/06",
      "Open": "3523.65",
      "High": "3570.5",
      "Low": "3502.75",
      "Close": "3535.8"
  },
  {
      "Date": "4/20/06",
      "Open": "3539.8",
      "High": "3578.35",
      "Low": "3527.25",
      "Close": "3573.5"
  },
  {
      "Date": "4/21/06",
      "Open": "3576.75",
      "High": "3592.75",
      "Low": "3517.1",
      "Close": "3573"
  },
  {
      "Date": "4/24/06",
      "Open": "3572.8",
      "High": "3585.35",
      "Low": "3536.3",
      "Close": "3548.9"
  },
  {
      "Date": "4/25/06",
      "Open": "3548.8",
      "High": "3552.65",
      "Low": "3433.55",
      "Close": "3462.6"
  },
  {
      "Date": "4/26/06",
      "Open": "3462.65",
      "High": "3562.3",
      "Low": "3454.1",
      "Close": "3555.7"
  },
  {
      "Date": "4/27/06",
      "Open": "3554.7",
      "High": "3598.95",
      "Low": "3492.75",
      "Close": "3508.1"
  },
  {
      "Date": "4/28/06",
      "Open": "3507.45",
      "High": "3517.65",
      "Low": "3342.85",
      "Close": "3508.3"
  },
  {
      "Date": "4/29/06",
      "Open": "3508.35",
      "High": "3565.75",
      "Low": "3508.35",
      "Close": "3557.6"
  },
  {
      "Date": "5/2/06",
      "Open": "3557.55",
      "High": "3622.05",
      "Low": "3556.9",
      "Close": "3605.4"
  },
  {
      "Date": "5/3/06",
      "Open": "3604.4",
      "High": "3640.8",
      "Low": "3581.4",
      "Close": "3634.2"
  },
  {
      "Date": "5/4/06",
      "Open": "3630.65",
      "High": "3674.5",
      "Low": "3621.55",
      "Close": "3648.4"
  },
  {
      "Date": "5/5/06",
      "Open": "3649.45",
      "High": "3676.55",
      "Low": "3639.55",
      "Close": "3663.9"
  },
  {
      "Date": "5/8/06",
      "Open": "3668.65",
      "High": "3708.55",
      "Low": "3668.65",
      "Close": "3693.1"
  },
  {
      "Date": "5/9/06",
      "Open": "3694.65",
      "High": "3725.4",
      "Low": "3655.65",
      "Close": "3720.5"
  },
  {
      "Date": "5/10/06",
      "Open": "3720.75",
      "High": "3758.05",
      "Low": "3717.25",
      "Close": "3754.2"
  },
  {
      "Date": "5/11/06",
      "Open": "3755.8",
      "High": "3774.15",
      "Low": "3687.9",
      "Close": "3701"
  },
  {
      "Date": "5/12/06",
      "Open": "3704.15",
      "High": "3704.15",
      "Low": "3633.85",
      "Close": "3650"
  },
  {
      "Date": "5/15/06",
      "Open": "3651",
      "High": "3653",
      "Low": "3482.85",
      "Close": "3502.9"
  },
  {
      "Date": "5/16/06",
      "Open": "3502.95",
      "High": "3543.5",
      "Low": "3382.4",
      "Close": "3523.3"
  },
  {
      "Date": "5/17/06",
      "Open": "3525.05",
      "High": "3641.85",
      "Low": "3525.05",
      "Close": "3635.1"
  },
  {
      "Date": "5/18/06",
      "Open": "3636.45",
      "High": "3636.45",
      "Low": "3365.9",
      "Close": "3388.9"
  },
  {
      "Date": "5/19/06",
      "Open": "3391.85",
      "High": "3493.05",
      "Low": "3205.25",
      "Close": "3246.9"
  },
  {
      "Date": "5/22/06",
      "Open": "3254.3",
      "High": "3313.9",
      "Low": "2896.4",
      "Close": "3081.3"
  },
  {
      "Date": "5/23/06",
      "Open": "3068.6",
      "High": "3221.05",
      "Low": "2997.35",
      "Close": "3199.3"
  },
  {
      "Date": "5/24/06",
      "Open": "3203.5",
      "High": "3249.75",
      "Low": "3091.6",
      "Close": "3115.5"
  },
  {
      "Date": "5/25/06",
      "Open": "3114.7",
      "High": "3198.35",
      "Low": "3012.95",
      "Close": "3177.7"
  },
  {
      "Date": "5/26/06",
      "Open": "3177.7",
      "High": "3277.95",
      "Low": "3177.7",
      "Close": "3209.6"
  },
  {
      "Date": "5/29/06",
      "Open": "3207.25",
      "High": "3255.25",
      "Low": "3193.15",
      "Close": "3214.9"
  },
  {
      "Date": "5/30/06",
      "Open": "3215.5",
      "High": "3252.1",
      "Low": "3167.05",
      "Close": "3185.3"
  },
  {
      "Date": "5/31/06",
      "Open": "3181.95",
      "High": "3181.95",
      "Low": "2972.9",
      "Close": "3071"
  },
  {
      "Date": "6/1/06",
      "Open": "3072.55",
      "High": "3130",
      "Low": "2936.6",
      "Close": "2962.2"
  },
  {
      "Date": "6/2/06",
      "Open": "2961.5",
      "High": "3099.35",
      "Low": "2940.2",
      "Close": "3091.3"
  },
  {
      "Date": "6/5/06",
      "Open": "3092.6",
      "High": "3125.4",
      "Low": "3006.4",
      "Close": "3016.6"
  },
  {
      "Date": "6/6/06",
      "Open": "3015.05",
      "High": "3015.05",
      "Low": "2910.3",
      "Close": "2937.3"
  },
  {
      "Date": "6/7/06",
      "Open": "2935.25",
      "High": "2956.9",
      "Low": "2819.45",
      "Close": "2860.4"
  },
  {
      "Date": "6/8/06",
      "Open": "2856.9",
      "High": "2859.4",
      "Low": "2683.2",
      "Close": "2724.3"
  },
  {
      "Date": "6/9/06",
      "Open": "2721.2",
      "High": "2875.75",
      "Low": "2707.85",
      "Close": "2866.3"
  },
  {
      "Date": "6/12/06",
      "Open": "2867.65",
      "High": "2877.8",
      "Low": "2761.85",
      "Close": "2776.8"
  },
  {
      "Date": "6/13/06",
      "Open": "2773.6",
      "High": "2773.6",
      "Low": "2638.1",
      "Close": "2663.3"
  },
  {
      "Date": "6/14/06",
      "Open": "2665.05",
      "High": "2767.75",
      "Low": "2595.65",
      "Close": "2632.8"
  },
  {
      "Date": "6/15/06",
      "Open": "2634.1",
      "High": "2814.15",
      "Low": "2634.1",
      "Close": "2798.8"
  },
  {
      "Date": "6/16/06",
      "Open": "2799.85",
      "High": "2960.15",
      "Low": "2799.85",
      "Close": "2890.3"
  },
  {
      "Date": "6/19/06",
      "Open": "2892",
      "High": "2932.9",
      "Low": "2846.5",
      "Close": "2916.9"
  },
  {
      "Date": "6/20/06",
      "Open": "2919",
      "High": "2919",
      "Low": "2837.45",
      "Close": "2861.3"
  },
  {
      "Date": "6/21/06",
      "Open": "2861.4",
      "High": "2930.8",
      "Low": "2836.4",
      "Close": "2923.4"
  },
  {
      "Date": "6/22/06",
      "Open": "2923.75",
      "High": "3017.65",
      "Low": "2923.75",
      "Close": "2994.7"
  },
  {
      "Date": "6/23/06",
      "Open": "2993.55",
      "High": "3063.2",
      "Low": "2920.2",
      "Close": "3042.7"
  },
  {
      "Date": "6/25/06",
      "Open": "3043.15",
      "High": "3083",
      "Low": "3035.95",
      "Close": "3050.3"
  },
  {
      "Date": "6/26/06",
      "Open": "3051.8",
      "High": "3059.85",
      "Low": "2928",
      "Close": "2943.2"
  },
  {
      "Date": "6/27/06",
      "Open": "2943.6",
      "High": "3004.15",
      "Low": "2899.25",
      "Close": "2982.4"
  },
  {
      "Date": "6/28/06",
      "Open": "2982.2",
      "High": "3003.65",
      "Low": "2909.6",
      "Close": "2981.1"
  },
  {
      "Date": "6/29/06",
      "Open": "2982.9",
      "High": "3049.25",
      "Low": "2982.9",
      "Close": "2997.9"
  },
  {
      "Date": "6/30/06",
      "Open": "2993.5",
      "High": "3134.15",
      "Low": "2993.5",
      "Close": "3128.2"
  },
  {
      "Date": "7/3/06",
      "Open": "3128.75",
      "High": "3160.35",
      "Low": "3114.85",
      "Close": "3150.9"
  },
  {
      "Date": "7/4/06",
      "Open": "3151.05",
      "High": "3177.4",
      "Low": "3130.3",
      "Close": "3138.6"
  },
  {
      "Date": "7/5/06",
      "Open": "3136.95",
      "High": "3201.2",
      "Low": "3121.8",
      "Close": "3197.1"
  },
  {
      "Date": "7/6/06",
      "Open": "3197.5",
      "High": "3197.5",
      "Low": "3138.4",
      "Close": "3156.4"
  },
  {
      "Date": "7/7/06",
      "Open": "3157.95",
      "High": "3193.1",
      "Low": "3056.3",
      "Close": "3075.8"
  },
  {
      "Date": "7/10/06",
      "Open": "3077.1",
      "High": "3147.7",
      "Low": "3064.1",
      "Close": "3142"
  },
  {
      "Date": "7/11/06",
      "Open": "3145.3",
      "High": "3146",
      "Low": "3100.4",
      "Close": "3116.1"
  },
  {
      "Date": "7/12/06",
      "Open": "3124.95",
      "High": "3201.35",
      "Low": "3078.25",
      "Close": "3195.9"
  },
  {
      "Date": "7/13/06",
      "Open": "3196.3",
      "High": "3208.85",
      "Low": "3148.95",
      "Close": "3169.3"
  },
  {
      "Date": "7/14/06",
      "Open": "3166.25",
      "High": "3166.25",
      "Low": "3089.55",
      "Close": "3123.3"
  },
  {
      "Date": "7/17/06",
      "Open": "3123.65",
      "High": "3125.1",
      "Low": "2999.35",
      "Close": "3007.5"
  },
  {
      "Date": "7/18/06",
      "Open": "3007.15",
      "High": "3038.25",
      "Low": "2967.95",
      "Close": "2993.6"
  },
  {
      "Date": "7/19/06",
      "Open": "2995.85",
      "High": "3045.35",
      "Low": "2919.95",
      "Close": "2932.7"
  },
  {
      "Date": "7/20/06",
      "Open": "2943.65",
      "High": "3041.25",
      "Low": "2943.65",
      "Close": "3023"
  },
  {
      "Date": "7/21/06",
      "Open": "3025.1",
      "High": "3029.1",
      "Low": "2930.15",
      "Close": "2945"
  },
  {
      "Date": "7/24/06",
      "Open": "2944.9",
      "High": "2996.65",
      "Low": "2878.25",
      "Close": "2985.8"
  },
  {
      "Date": "7/25/06",
      "Open": "2989.5",
      "High": "3046.95",
      "Low": "2987.15",
      "Close": "3040.5"
  },
  {
      "Date": "7/26/06",
      "Open": "3040.45",
      "High": "3121.4",
      "Low": "3022.7",
      "Close": "3110.1"
  },
  {
      "Date": "7/27/06",
      "Open": "3109.85",
      "High": "3167.1",
      "Low": "3109.85",
      "Close": "3156.1"
  },
  {
      "Date": "7/28/06",
      "Open": "3158",
      "High": "3168.5",
      "Low": "3109.45",
      "Close": "3130.8"
  },
  {
      "Date": "7/31/06",
      "Open": "3131.35",
      "High": "3179.7",
      "Low": "3110.6",
      "Close": "3143.2"
  },
  {
      "Date": "8/1/06",
      "Open": "3128.2",
      "High": "3154.7",
      "Low": "3113.6",
      "Close": "3147.8"
  },
  {
      "Date": "8/2/06",
      "Open": "3129.45",
      "High": "3187.85",
      "Low": "3127.9",
      "Close": "3182.1"
  },
  {
      "Date": "8/3/06",
      "Open": "3182.55",
      "High": "3234.25",
      "Low": "3175.15",
      "Close": "3190"
  },
  {
      "Date": "8/4/06",
      "Open": "3189.7",
      "High": "3228.15",
      "Low": "3161.8",
      "Close": "3176.7"
  },
  {
      "Date": "8/7/06",
      "Open": "3176.7",
      "High": "3178.5",
      "Low": "3142.55",
      "Close": "3151.1"
  },
  {
      "Date": "8/8/06",
      "Open": "3151.55",
      "High": "3216.75",
      "Low": "3151.55",
      "Close": "3212.4"
  },
  {
      "Date": "8/9/06",
      "Open": "3211.55",
      "High": "3267.2",
      "Low": "3187.85",
      "Close": "3254.6"
  },
  {
      "Date": "8/10/06",
      "Open": "3254.6",
      "High": "3274.75",
      "Low": "3229.45",
      "Close": "3260.1"
  },
  {
      "Date": "8/11/06",
      "Open": "3260.05",
      "High": "3286.5",
      "Low": "3236.95",
      "Close": "3274.3"
  },
  {
      "Date": "8/14/06",
      "Open": "3274.95",
      "High": "3317.8",
      "Low": "3269.15",
      "Close": "3313.1"
  },
  {
      "Date": "8/16/06",
      "Open": "3314.75",
      "High": "3377.1",
      "Low": "3314.75",
      "Close": "3356"
  },
  {
      "Date": "8/17/06",
      "Open": "3359.7",
      "High": "3385.15",
      "Low": "3328.6",
      "Close": "3353.9"
  },
  {
      "Date": "8/18/06",
      "Open": "3354.5",
      "High": "3366.25",
      "Low": "3332.2",
      "Close": "3356.7"
  },
  {
      "Date": "8/21/06",
      "Open": "3356.8",
      "High": "3374.9",
      "Low": "3332.9",
      "Close": "3366"
  },
  {
      "Date": "8/22/06",
      "Open": "3369.6",
      "High": "3398.35",
      "Low": "3351.5",
      "Close": "3364.6"
  },
  {
      "Date": "8/23/06",
      "Open": "3363.6",
      "High": "3369.65",
      "Low": "3325.1",
      "Close": "3335.8"
  },
  {
      "Date": "8/24/06",
      "Open": "3336.65",
      "High": "3379.55",
      "Low": "3304.85",
      "Close": "3370.4"
  },
  {
      "Date": "8/25/06",
      "Open": "3368.55",
      "High": "3402.7",
      "Low": "3368.55",
      "Close": "3385.9"
  },
  {
      "Date": "8/28/06",
      "Open": "3386",
      "High": "3405.3",
      "Low": "3377.75",
      "Close": "3401.1"
  },
  {
      "Date": "8/29/06",
      "Open": "3405.05",
      "High": "3434.95",
      "Low": "3396.1",
      "Close": "3425.7"
  },
  {
      "Date": "8/30/06",
      "Open": "3425.8",
      "High": "3440.2",
      "Low": "3407.75",
      "Close": "3430.3"
  },
  {
      "Date": "8/31/06",
      "Open": "3404.5",
      "High": "3452.3",
      "Low": "3403.65",
      "Close": "3413.9"
  },
  {
      "Date": "9/1/06",
      "Open": "3414",
      "High": "3439.5",
      "Low": "3402.9",
      "Close": "3435.4"
  },
  {
      "Date": "9/4/06",
      "Open": "3435.55",
      "High": "3483.1",
      "Low": "3435.55",
      "Close": "3476.8"
  },
  {
      "Date": "9/5/06",
      "Open": "3471.4",
      "High": "3488.6",
      "Low": "3457.9",
      "Close": "3473.7"
  },
  {
      "Date": "9/6/06",
      "Open": "3474.4",
      "High": "3490.7",
      "Low": "3465.8",
      "Close": "3477.2"
  },
  {
      "Date": "9/7/06",
      "Open": "3477.15",
      "High": "3478.85",
      "Low": "3438.8",
      "Close": "3454.5"
  },
  {
      "Date": "9/8/06",
      "Open": "3454.65",
      "High": "3477.95",
      "Low": "3442.85",
      "Close": "3471.4"
  },
  {
      "Date": "9/11/06",
      "Open": "3470.35",
      "High": "3486.65",
      "Low": "3351.3",
      "Close": "3366.1"
  },
  {
      "Date": "9/12/06",
      "Open": "3363.3",
      "High": "3395.05",
      "Low": "3328.45",
      "Close": "3389.9"
  },
  {
      "Date": "9/13/06",
      "Open": "3389.85",
      "High": "3470.65",
      "Low": "3389.8",
      "Close": "3454.5"
  },
  {
      "Date": "9/14/06",
      "Open": "3454.6",
      "High": "3484",
      "Low": "3454.6",
      "Close": "3471.6"
  },
  {
      "Date": "9/15/06",
      "Open": "3471.65",
      "High": "3487.45",
      "Low": "3434.55",
      "Close": "3478.6"
  },
  {
      "Date": "9/18/06",
      "Open": "3478.65",
      "High": "3506.2",
      "Low": "3478.4",
      "Close": "3492.7"
  },
  {
      "Date": "9/19/06",
      "Open": "3493.5",
      "High": "3514.95",
      "Low": "3438.8",
      "Close": "3457.3"
  },
  {
      "Date": "9/20/06",
      "Open": "3457.85",
      "High": "3509.85",
      "Low": "3419.75",
      "Close": "3502.8"
  },
  {
      "Date": "9/21/06",
      "Open": "3506.7",
      "High": "3556.35",
      "Low": "3506.7",
      "Close": "3553"
  },
  {
      "Date": "9/22/06",
      "Open": "3554.05",
      "High": "3562.45",
      "Low": "3525.4",
      "Close": "3544"
  },
  {
      "Date": "9/25/06",
      "Open": "3545.1",
      "High": "3568.65",
      "Low": "3514.85",
      "Close": "3523.4"
  },
  {
      "Date": "9/26/06",
      "Open": "3523.7",
      "High": "3576.75",
      "Low": "3517.15",
      "Close": "3571.7"
  },
  {
      "Date": "9/27/06",
      "Open": "3571.75",
      "High": "3603.7",
      "Low": "3568.4",
      "Close": "3579.3"
  },
  {
      "Date": "9/28/06",
      "Open": "3579.95",
      "High": "3589.3",
      "Low": "3560.75",
      "Close": "3571.7"
  },
  {
      "Date": "9/29/06",
      "Open": "3572.15",
      "High": "3599.8",
      "Low": "3564.7",
      "Close": "3588.4"
  },
  {
      "Date": "10/3/06",
      "Open": "3588.95",
      "High": "3606.2",
      "Low": "3561.7",
      "Close": "3569.6"
  },
  {
      "Date": "10/4/06",
      "Open": "3570.95",
      "High": "3580.05",
      "Low": "3508.65",
      "Close": "3515.3"
  },
  {
      "Date": "10/5/06",
      "Open": "3515.6",
      "High": "3574.95",
      "Low": "3515.6",
      "Close": "3564.9"
  },
  {
      "Date": "10/6/06",
      "Open": "3565.45",
      "High": "3583.75",
      "Low": "3560.9",
      "Close": "3569.7"
  },
  {
      "Date": "10/9/06",
      "Open": "3570.05",
      "High": "3585.35",
      "Low": "3540.45",
      "Close": "3567.1"
  },
  {
      "Date": "10/10/06",
      "Open": "3567.45",
      "High": "3597.2",
      "Low": "3563.1",
      "Close": "3571"
  },
  {
      "Date": "10/11/06",
      "Open": "3581.55",
      "High": "3614.5",
      "Low": "3544.25",
      "Close": "3558.5"
  },
  {
      "Date": "10/12/06",
      "Open": "3562.55",
      "High": "3626.8",
      "Low": "3545.95",
      "Close": "3621"
  },
  {
      "Date": "10/13/06",
      "Open": "3621.65",
      "High": "3682.35",
      "Low": "3621.65",
      "Close": "3676"
  },
  {
      "Date": "10/16/06",
      "Open": "3668.9",
      "High": "3729.7",
      "Low": "3668.9",
      "Close": "3723.9"
  },
  {
      "Date": "10/17/06",
      "Open": "3726.4",
      "High": "3742.5",
      "Low": "3691.35",
      "Close": "3715"
  },
  {
      "Date": "10/18/06",
      "Open": "3715.2",
      "High": "3727",
      "Low": "3701.3",
      "Close": "3710.6"
  },
  {
      "Date": "10/19/06",
      "Open": "3711.1",
      "High": "3726.95",
      "Low": "3655.05",
      "Close": "3677.8"
  },
  {
      "Date": "10/20/06",
      "Open": "3678.75",
      "High": "3714.25",
      "Low": "3665.5",
      "Close": "3676.8"
  },
  {
      "Date": "10/21/06",
      "Open": "3680.85",
      "High": "3708.4",
      "Low": "3662.85",
      "Close": "3683.5"
  },
  {
      "Date": "10/23/06",
      "Open": "3683.4",
      "High": "3690.85",
      "Low": "3651.2",
      "Close": "3657.3"
  },
  {
      "Date": "10/26/06",
      "Open": "3656.2",
      "High": "3686.85",
      "Low": "3651.2",
      "Close": "3677.5"
  },
  {
      "Date": "10/27/06",
      "Open": "3676.85",
      "High": "3747.35",
      "Low": "3674.8",
      "Close": "3739.3"
  },
  {
      "Date": "10/30/06",
      "Open": "3739.35",
      "High": "3776.05",
      "Low": "3719.45",
      "Close": "3769.1"
  },
  {
      "Date": "10/31/06",
      "Open": "3770.5",
      "High": "3782.85",
      "Low": "3726.75",
      "Close": "3744.1"
  },
  {
      "Date": "11/1/06",
      "Open": "3744.1",
      "High": "3777.7",
      "Low": "3737",
      "Close": "3767"
  },
  {
      "Date": "11/2/06",
      "Open": "3769.4",
      "High": "3805.5",
      "Low": "3761.9",
      "Close": "3791.2"
  },
  {
      "Date": "11/3/06",
      "Open": "3793.05",
      "High": "3809.65",
      "Low": "3771",
      "Close": "3805.3"
  },
  {
      "Date": "11/6/06",
      "Open": "3804.75",
      "High": "3822.4",
      "Low": "3798.25",
      "Close": "3809.2"
  },
  {
      "Date": "11/7/06",
      "Open": "3812.15",
      "High": "3840.45",
      "Low": "3789.4",
      "Close": "3798.7"
  },
  {
      "Date": "11/8/06",
      "Open": "3799.2",
      "High": "3809.95",
      "Low": "3737.2",
      "Close": "3777.3"
  },
  {
      "Date": "11/9/06",
      "Open": "3777.8",
      "High": "3808.2",
      "Low": "3769.8",
      "Close": "3796.4"
  },
  {
      "Date": "11/10/06",
      "Open": "3796.05",
      "High": "3842.4",
      "Low": "3785.4",
      "Close": "3834.7"
  },
  {
      "Date": "11/13/06",
      "Open": "3834.8",
      "High": "3862.05",
      "Low": "3834",
      "Close": "3858.7"
  },
  {
      "Date": "11/14/06",
      "Open": "3862",
      "High": "3883",
      "Low": "3850.75",
      "Close": "3865.9"
  },
  {
      "Date": "11/15/06",
      "Open": "3865.95",
      "High": "3888.8",
      "Low": "3838.25",
      "Close": "3876.3"
  },
  {
      "Date": "11/16/06",
      "Open": "3876.4",
      "High": "3900.4",
      "Low": "3863.85",
      "Close": "3876.8"
  },
  {
      "Date": "11/17/06",
      "Open": "3877",
      "High": "3891.85",
      "Low": "3835.7",
      "Close": "3852.8"
  },
  {
      "Date": "11/20/06",
      "Open": "3852.65",
      "High": "3866.95",
      "Low": "3794.3",
      "Close": "3856.1"
  },
  {
      "Date": "11/21/06",
      "Open": "3859.5",
      "High": "3921.7",
      "Low": "3856.75",
      "Close": "3918.2"
  },
  {
      "Date": "11/22/06",
      "Open": "3918.3",
      "High": "3960",
      "Low": "3909.6",
      "Close": "3954.7"
  },
  {
      "Date": "11/23/06",
      "Open": "3955.3",
      "High": "3976.8",
      "Low": "3933.7",
      "Close": "3945.4"
  },
  {
      "Date": "11/24/06",
      "Open": "3945.45",
      "High": "3965.8",
      "Low": "3935.3",
      "Close": "3950.8"
  },
  {
      "Date": "11/27/06",
      "Open": "3974.95",
      "High": "3974.95",
      "Low": "3948.85",
      "Close": "3968.9"
  },
  {
      "Date": "11/28/06",
      "Open": "3966",
      "High": "3966",
      "Low": "3911.55",
      "Close": "3921.7"
  },
  {
      "Date": "11/29/06",
      "Open": "3922.5",
      "High": "3952.4",
      "Low": "3920",
      "Close": "3928.2"
  },
  {
      "Date": "11/30/06",
      "Open": "3929.4",
      "High": "3973",
      "Low": "3929.4",
      "Close": "3954.5"
  },
  {
      "Date": "12/1/06",
      "Open": "3955.7",
      "High": "4001.3",
      "Low": "3953.95",
      "Close": "3997.6"
  },
  {
      "Date": "12/4/06",
      "Open": "4002.4",
      "High": "4015.25",
      "Low": "3991.55",
      "Close": "4001"
  },
  {
      "Date": "12/5/06",
      "Open": "4001.25",
      "High": "4033.2",
      "Low": "4001.25",
      "Close": "4015.7"
  },
  {
      "Date": "12/6/06",
      "Open": "4016",
      "High": "4036.2",
      "Low": "3982.5",
      "Close": "4015.9"
  },
  {
      "Date": "12/7/06",
      "Open": "4015",
      "High": "4027.7",
      "Low": "4001.4",
      "Close": "4015.3"
  },
  {
      "Date": "12/8/06",
      "Open": "4015.75",
      "High": "4046.85",
      "Low": "3948.7",
      "Close": "3962"
  },
  {
      "Date": "12/11/06",
      "Open": "3961.9",
      "High": "3965.05",
      "Low": "3798.3",
      "Close": "3849.5"
  },
  {
      "Date": "12/12/06",
      "Open": "3849.1",
      "High": "3861.25",
      "Low": "3661.6",
      "Close": "3716.9"
  },
  {
      "Date": "12/13/06",
      "Open": "3716.75",
      "High": "3777.6",
      "Low": "3657.65",
      "Close": "3765.2"
  },
  {
      "Date": "12/14/06",
      "Open": "3765.7",
      "High": "3855.05",
      "Low": "3763.5",
      "Close": "3843"
  },
  {
      "Date": "12/15/06",
      "Open": "3849.6",
      "High": "3908.45",
      "Low": "3845.45",
      "Close": "3888.6"
  },
  {
      "Date": "12/18/06",
      "Open": "3888.2",
      "High": "3934",
      "Low": "3828.15",
      "Close": "3928.7"
  },
  {
      "Date": "12/19/06",
      "Open": "3928.85",
      "High": "3931.9",
      "Low": "3783.45",
      "Close": "3832"
  },
  {
      "Date": "12/20/06",
      "Open": "3832.15",
      "High": "3886.1",
      "Low": "3787.55",
      "Close": "3815.5"
  },
  {
      "Date": "12/21/06",
      "Open": "3814.75",
      "High": "3851.2",
      "Low": "3768.8",
      "Close": "3833.5"
  },
  {
      "Date": "12/22/06",
      "Open": "3834.9",
      "High": "3880.15",
      "Low": "3823.4",
      "Close": "3871.1"
  },
  {
      "Date": "12/26/06",
      "Open": "3871.3",
      "High": "3946.1",
      "Low": "3870.45",
      "Close": "3940.5"
  },
  {
      "Date": "12/27/06",
      "Open": "3942.75",
      "High": "3986.75",
      "Low": "3937.7",
      "Close": "3974.2"
  },
  {
      "Date": "12/28/06",
      "Open": "3974.2",
      "High": "3997.35",
      "Low": "3961.95",
      "Close": "3970.5"
  },
  {
      "Date": "12/29/06",
      "Open": "3971.65",
      "High": "3991.6",
      "Low": "3960.45",
      "Close": "3966.4"
  },
  {
      "Date": "1/2/07",
      "Open": "3966.25",
      "High": "4016.65",
      "Low": "3964.95",
      "Close": "4007.4"
  },
  {
      "Date": "1/3/07",
      "Open": "4007.7",
      "High": "4029.8",
      "Low": "3985",
      "Close": "4024"
  },
  {
      "Date": "1/4/07",
      "Open": "4027.3",
      "High": "4041",
      "Low": "3979.8",
      "Close": "3988.8"
  },
  {
      "Date": "1/5/07",
      "Open": "3990.15",
      "High": "4011.45",
      "Low": "3969.65",
      "Close": "3983.4"
  },
  {
      "Date": "1/8/07",
      "Open": "3983.3",
      "High": "3987.45",
      "Low": "3913",
      "Close": "3933.4"
  },
  {
      "Date": "1/9/07",
      "Open": "3933.3",
      "High": "3963.75",
      "Low": "3890.75",
      "Close": "3911.4"
  },
  {
      "Date": "1/10/07",
      "Open": "3910.95",
      "High": "3911.95",
      "Low": "3841.7",
      "Close": "3850.3"
  },
  {
      "Date": "1/11/07",
      "Open": "3852.15",
      "High": "3953.1",
      "Low": "3833.6",
      "Close": "3942.2"
  },
  {
      "Date": "1/12/07",
      "Open": "3944.55",
      "High": "4059.15",
      "Low": "3944.55",
      "Close": "4052.4"
  },
  {
      "Date": "1/15/07",
      "Open": "4052.85",
      "High": "4099.65",
      "Low": "4052.4",
      "Close": "4078.4"
  },
  {
      "Date": "1/16/07",
      "Open": "4090.7",
      "High": "4107.45",
      "Low": "4067.9",
      "Close": "4080.5"
  },
  {
      "Date": "1/17/07",
      "Open": "4084.9",
      "High": "4096.75",
      "Low": "4071.55",
      "Close": "4076.4"
  },
  {
      "Date": "1/18/07",
      "Open": "4075.2",
      "High": "4140.25",
      "Low": "4075.2",
      "Close": "4109"
  },
  {
      "Date": "1/19/07",
      "Open": "4126",
      "High": "4137.15",
      "Low": "4058.35",
      "Close": "4090.1"
  },
  {
      "Date": "1/22/07",
      "Open": "4089.6",
      "High": "4114.5",
      "Low": "4069.95",
      "Close": "4102.4"
  },
  {
      "Date": "1/23/07",
      "Open": "4102.65",
      "High": "4105.1",
      "Low": "4056.45",
      "Close": "4066.1"
  },
  {
      "Date": "1/24/07",
      "Open": "4066.6",
      "High": "4098.25",
      "Low": "4065.75",
      "Close": "4089.9"
  },
  {
      "Date": "1/25/07",
      "Open": "4092.05",
      "High": "4157.95",
      "Low": "4090.1",
      "Close": "4147.7"
  },
  {
      "Date": "1/29/07",
      "Open": "4148.4",
      "High": "4167.15",
      "Low": "4115.75",
      "Close": "4124.4"
  },
  {
      "Date": "1/31/07",
      "Open": "4123.85",
      "High": "4137.85",
      "Low": "4068.55",
      "Close": "4082.7"
  },
  {
      "Date": "2/1/07",
      "Open": "4083.4",
      "High": "4141.6",
      "Low": "4081.1",
      "Close": "4137.2"
  },
  {
      "Date": "2/2/07",
      "Open": "4140.2",
      "High": "4198.7",
      "Low": "4132.95",
      "Close": "4183.5"
  },
  {
      "Date": "2/5/07",
      "Open": "4193.15",
      "High": "4219",
      "Low": "4170",
      "Close": "4215.3"
  },
  {
      "Date": "2/6/07",
      "Open": "4216.55",
      "High": "4228.15",
      "Low": "4186.15",
      "Close": "4195.9"
  },
  {
      "Date": "2/7/07",
      "Open": "4198.2",
      "High": "4232.3",
      "Low": "4192.85",
      "Close": "4224.2"
  },
  {
      "Date": "2/8/07",
      "Open": "4232",
      "High": "4245.3",
      "Low": "4188.45",
      "Close": "4223.4"
  },
  {
      "Date": "2/9/07",
      "Open": "4223.5",
      "High": "4239.2",
      "Low": "4171.8",
      "Close": "4187.4"
  },
  {
      "Date": "2/12/07",
      "Open": "4187.2",
      "High": "4187.2",
      "Low": "4044.35",
      "Close": "4058.3"
  },
  {
      "Date": "2/13/07",
      "Open": "4069.1",
      "High": "4132.7",
      "Low": "3998.3",
      "Close": "4044.5"
  },
  {
      "Date": "2/14/07",
      "Open": "4044.9",
      "High": "4057.35",
      "Low": "3965.2",
      "Close": "4047.1"
  },
  {
      "Date": "2/15/07",
      "Open": "4046.8",
      "High": "4155.7",
      "Low": "4046.8",
      "Close": "4146.2"
  },
  {
      "Date": "2/19/07",
      "Open": "4149.25",
      "High": "4177.7",
      "Low": "4149.25",
      "Close": "4164.5"
  },
  {
      "Date": "2/20/07",
      "Open": "4164.85",
      "High": "4175.45",
      "Low": "4099.55",
      "Close": "4106.9"
  },
  {
      "Date": "2/21/07",
      "Open": "4107.15",
      "High": "4132.8",
      "Low": "4080.9",
      "Close": "4096.2"
  },
  {
      "Date": "2/22/07",
      "Open": "4096.65",
      "High": "4126.9",
      "Low": "4023.15",
      "Close": "4040"
  },
  {
      "Date": "2/23/07",
      "Open": "4046",
      "High": "4065.45",
      "Low": "3918.2",
      "Close": "3938.9"
  },
  {
      "Date": "2/26/07",
      "Open": "3939.1",
      "High": "3958.9",
      "Low": "3856.7",
      "Close": "3942"
  },
  {
      "Date": "2/27/07",
      "Open": "3948.05",
      "High": "3958",
      "Low": "3873.85",
      "Close": "3893.9"
  },
  {
      "Date": "2/28/07",
      "Open": "3893.4",
      "High": "3893.4",
      "Low": "3674.85",
      "Close": "3745.3"
  },
  {
      "Date": "3/1/07",
      "Open": "3745.4",
      "High": "3818.75",
      "Low": "3718.15",
      "Close": "3811.2"
  },
  {
      "Date": "3/2/07",
      "Open": "3811.65",
      "High": "3842.05",
      "Low": "3711.05",
      "Close": "3726.7"
  },
  {
      "Date": "3/5/07",
      "Open": "3726.5",
      "High": "3726.65",
      "Low": "3554.5",
      "Close": "3576.5"
  },
  {
      "Date": "3/6/07",
      "Open": "3577.15",
      "High": "3679.15",
      "Low": "3576.65",
      "Close": "3655.6"
  },
  {
      "Date": "3/7/07",
      "Open": "3661.55",
      "High": "3714.15",
      "Low": "3568.55",
      "Close": "3626.8"
  },
  {
      "Date": "3/8/07",
      "Open": "3627.25",
      "High": "3779.5",
      "Low": "3626.8",
      "Close": "3761.6"
  },
  {
      "Date": "3/9/07",
      "Open": "3761.85",
      "High": "3795.7",
      "Low": "3684.25",
      "Close": "3718"
  },
  {
      "Date": "3/12/07",
      "Open": "3717.45",
      "High": "3781.45",
      "Low": "3713.9",
      "Close": "3734.6"
  },
  {
      "Date": "3/13/07",
      "Open": "3735.25",
      "High": "3775.85",
      "Low": "3717.15",
      "Close": "3770.5"
  },
  {
      "Date": "3/14/07",
      "Open": "3768.4",
      "High": "3768.4",
      "Low": "3623",
      "Close": "3641.1"
  },
  {
      "Date": "3/15/07",
      "Open": "3644.9",
      "High": "3711.05",
      "Low": "3630.55",
      "Close": "3643.6"
  },
  {
      "Date": "3/16/07",
      "Open": "3639.35",
      "High": "3683.6",
      "Low": "3573.85",
      "Close": "3608.5"
  },
  {
      "Date": "3/19/07",
      "Open": "3611.3",
      "High": "3683.35",
      "Low": "3602.85",
      "Close": "3678.9"
  },
  {
      "Date": "3/20/07",
      "Open": "3680.35",
      "High": "3725",
      "Low": "3676.65",
      "Close": "3697.6"
  },
  {
      "Date": "3/21/07",
      "Open": "3697.7",
      "High": "3771.2",
      "Low": "3680.6",
      "Close": "3764.5"
  },
  {
      "Date": "3/22/07",
      "Open": "3764.5",
      "High": "3881",
      "Low": "3764.5",
      "Close": "3875.9"
  },
  {
      "Date": "3/23/07",
      "Open": "3876.75",
      "High": "3901.75",
      "Low": "3850.8",
      "Close": "3861"
  },
  {
      "Date": "3/26/07",
      "Open": "3863.45",
      "High": "3885.45",
      "Low": "3768.25",
      "Close": "3819.9"
  },
  {
      "Date": "3/28/07",
      "Open": "3818.75",
      "High": "3830.3",
      "Low": "3752.95",
      "Close": "3761.1"
  },
  {
      "Date": "3/29/07",
      "Open": "3759.15",
      "High": "3805.85",
      "Low": "3750.35",
      "Close": "3798.1"
  },
  {
      "Date": "3/30/07",
      "Open": "3788.85",
      "High": "3832.2",
      "Low": "3785.3",
      "Close": "3821.5"
  },
  {
      "Date": "4/2/07",
      "Open": "3820",
      "High": "3820",
      "Low": "3617",
      "Close": "3633.6"
  },
  {
      "Date": "4/3/07",
      "Open": "3633.85",
      "High": "3703.05",
      "Low": "3632.2",
      "Close": "3690.6"
  },
  {
      "Date": "4/4/07",
      "Open": "3689.75",
      "High": "3751.4",
      "Low": "3689.75",
      "Close": "3733.2"
  },
  {
      "Date": "4/5/07",
      "Open": "3735.2",
      "High": "3771.45",
      "Low": "3709.15",
      "Close": "3752"
  },
  {
      "Date": "4/9/07",
      "Open": "3752.9",
      "High": "3850.9",
      "Low": "3747.25",
      "Close": "3843.5"
  },
  {
      "Date": "4/10/07",
      "Open": "3844.15",
      "High": "3858.35",
      "Low": "3819.3",
      "Close": "3848.1"
  },
  {
      "Date": "4/11/07",
      "Open": "3848.35",
      "High": "3876.35",
      "Low": "3844.75",
      "Close": "3862.6"
  },
  {
      "Date": "4/12/07",
      "Open": "3861.85",
      "High": "3861.85",
      "Low": "3811.25",
      "Close": "3829.8"
  },
  {
      "Date": "4/13/07",
      "Open": "3830.35",
      "High": "3924.55",
      "Low": "3828.45",
      "Close": "3917.3"
  },
  {
      "Date": "4/16/07",
      "Open": "3920.5",
      "High": "4016.8",
      "Low": "3920.5",
      "Close": "4013.3"
  },
  {
      "Date": "4/17/07",
      "Open": "4014.4",
      "High": "4030",
      "Low": "3976.25",
      "Close": "3984.9"
  },
  {
      "Date": "4/18/07",
      "Open": "3989.6",
      "High": "4039.25",
      "Low": "3981.75",
      "Close": "4011.6"
  },
  {
      "Date": "4/19/07",
      "Open": "3998.5",
      "High": "4011",
      "Low": "3933.35",
      "Close": "3997.6"
  },
  {
      "Date": "4/20/07",
      "Open": "4000.25",
      "High": "4090.05",
      "Low": "3995.5",
      "Close": "4083.5"
  },
  {
      "Date": "4/23/07",
      "Open": "4083.55",
      "High": "4122.35",
      "Low": "4075.2",
      "Close": "4085.1"
  },
  {
      "Date": "4/24/07",
      "Open": "4085.1",
      "High": "4162.15",
      "Low": "4057.7",
      "Close": "4141.8"
  },
  {
      "Date": "4/25/07",
      "Open": "4134.25",
      "High": "4173.3",
      "Low": "4114.35",
      "Close": "4167.3"
  },
  {
      "Date": "4/26/07",
      "Open": "4170.05",
      "High": "4217.9",
      "Low": "4143.25",
      "Close": "4177.8"
  },
  {
      "Date": "4/27/07",
      "Open": "4182",
      "High": "4182",
      "Low": "4074.3",
      "Close": "4083.5"
  },
  {
      "Date": "4/30/07",
      "Open": "4081.6",
      "High": "4096.9",
      "Low": "4028.9",
      "Close": "4087.9"
  },
  {
      "Date": "5/3/07",
      "Open": "4089.45",
      "High": "4161.2",
      "Low": "4080.75",
      "Close": "4150.8"
  },
  {
      "Date": "5/4/07",
      "Open": "4168.9",
      "High": "4180.9",
      "Low": "4109.7",
      "Close": "4117.3"
  },
  {
      "Date": "5/7/07",
      "Open": "4117.5",
      "High": "4157.65",
      "Low": "4103.6",
      "Close": "4111.1"
  },
  {
      "Date": "5/8/07",
      "Open": "4111.25",
      "High": "4136.05",
      "Low": "4066.4",
      "Close": "4077"
  },
  {
      "Date": "5/9/07",
      "Open": "4077.25",
      "High": "4087.8",
      "Low": "4030.55",
      "Close": "4079.3"
  },
  {
      "Date": "5/10/07",
      "Open": "4079.6",
      "High": "4134.2",
      "Low": "4057.55",
      "Close": "4066.8"
  },
  {
      "Date": "5/11/07",
      "Open": "4070.2",
      "High": "4094.65",
      "Low": "3981.15",
      "Close": "4076.6"
  },
  {
      "Date": "5/14/07",
      "Open": "4078.8",
      "High": "4151.3",
      "Low": "4072.45",
      "Close": "4134.3"
  },
  {
      "Date": "5/15/07",
      "Open": "4134.3",
      "High": "4150.45",
      "Low": "4102.45",
      "Close": "4120.3"
  },
  {
      "Date": "5/16/07",
      "Open": "4125.4",
      "High": "4181",
      "Low": "4113.05",
      "Close": "4170.9"
  },
  {
      "Date": "5/17/07",
      "Open": "4172.1",
      "High": "4232.45",
      "Low": "4172.1",
      "Close": "4219.5"
  },
  {
      "Date": "5/18/07",
      "Open": "4216.5",
      "High": "4228.45",
      "Low": "4177",
      "Close": "4214.5"
  },
  {
      "Date": "5/21/07",
      "Open": "4217.65",
      "High": "4269.35",
      "Low": "4217.55",
      "Close": "4260.9"
  },
  {
      "Date": "5/22/07",
      "Open": "4263.1",
      "High": "4281.6",
      "Low": "4234.1",
      "Close": "4278.1"
  },
  {
      "Date": "5/23/07",
      "Open": "4279.6",
      "High": "4291.4",
      "Low": "4231.05",
      "Close": "4246.2"
  },
  {
      "Date": "5/24/07",
      "Open": "4246.2",
      "High": "4250.85",
      "Low": "4189.05",
      "Close": "4204.9"
  },
  {
      "Date": "5/25/07",
      "Open": "4197.85",
      "High": "4256.4",
      "Low": "4141.35",
      "Close": "4248.1"
  },
  {
      "Date": "5/28/07",
      "Open": "4248.35",
      "High": "4295.6",
      "Low": "4242.8",
      "Close": "4256.5"
  },
  {
      "Date": "5/29/07",
      "Open": "4257.6",
      "High": "4298.85",
      "Low": "4248.9",
      "Close": "4293.2"
  },
  {
      "Date": "5/30/07",
      "Open": "4292.7",
      "High": "4301.6",
      "Low": "4241.35",
      "Close": "4249.6"
  },
  {
      "Date": "5/31/07",
      "Open": "4250.25",
      "High": "4306.75",
      "Low": "4250.25",
      "Close": "4295.8"
  },
  {
      "Date": "6/1/07",
      "Open": "4296.05",
      "High": "4325.8",
      "Low": "4288.55",
      "Close": "4297"
  },
  {
      "Date": "6/4/07",
      "Open": "4300.7",
      "High": "4362.95",
      "Low": "4256.45",
      "Close": "4267"
  },
  {
      "Date": "6/5/07",
      "Open": "4268.9",
      "High": "4292.5",
      "Low": "4249.1",
      "Close": "4284.6"
  },
  {
      "Date": "6/6/07",
      "Open": "4285.75",
      "High": "4324.1",
      "Low": "4190.95",
      "Close": "4198.2"
  },
  {
      "Date": "6/7/07",
      "Open": "4197.6",
      "High": "4230.05",
      "Low": "4130.5",
      "Close": "4179.5"
  },
  {
      "Date": "6/8/07",
      "Open": "4179.5",
      "High": "4195.15",
      "Low": "4126.1",
      "Close": "4145"
  },
  {
      "Date": "6/11/07",
      "Open": "4183.75",
      "High": "4205.2",
      "Low": "4134.95",
      "Close": "4145.6"
  },
  {
      "Date": "6/12/07",
      "Open": "4139.7",
      "High": "4166.85",
      "Low": "4100.8",
      "Close": "4155.2"
  },
  {
      "Date": "6/13/07",
      "Open": "4155.2",
      "High": "4161.8",
      "Low": "4102.95",
      "Close": "4113"
  },
  {
      "Date": "6/14/07",
      "Open": "4113.2",
      "High": "4174.05",
      "Low": "4112.85",
      "Close": "4170"
  },
  {
      "Date": "6/15/07",
      "Open": "4186.4",
      "High": "4209.45",
      "Low": "4153.7",
      "Close": "4171.4"
  },
  {
      "Date": "6/18/07",
      "Open": "4177",
      "High": "4208.15",
      "Low": "4140.25",
      "Close": "4147.1"
  },
  {
      "Date": "6/19/07",
      "Open": "4143.85",
      "High": "4222.4",
      "Low": "4136.15",
      "Close": "4214.3"
  },
  {
      "Date": "6/20/07",
      "Open": "4214.3",
      "High": "4257",
      "Low": "4214.3",
      "Close": "4248.6"
  },
  {
      "Date": "6/21/07",
      "Open": "4248.65",
      "High": "4275.35",
      "Low": "4220.1",
      "Close": "4267.4"
  },
  {
      "Date": "6/22/07",
      "Open": "4267.55",
      "High": "4278.85",
      "Low": "4242.5",
      "Close": "4252"
  },
  {
      "Date": "6/25/07",
      "Open": "4251.4",
      "High": "4264.25",
      "Low": "4236.3",
      "Close": "4259.4"
  },
  {
      "Date": "6/26/07",
      "Open": "4259.4",
      "High": "4296.15",
      "Low": "4250.1",
      "Close": "4285.7"
  },
  {
      "Date": "6/27/07",
      "Open": "4286.2",
      "High": "4294.2",
      "Low": "4255.25",
      "Close": "4263.9"
  },
  {
      "Date": "6/28/07",
      "Open": "4263.9",
      "High": "4291.4",
      "Low": "4256.9",
      "Close": "4282"
  },
  {
      "Date": "6/29/07",
      "Open": "4282.4",
      "High": "4321.35",
      "Low": "4280.95",
      "Close": "4318.3"
  },
  {
      "Date": "7/2/07",
      "Open": "4318.4",
      "High": "4346.75",
      "Low": "4305.45",
      "Close": "4313.7"
  },
  {
      "Date": "7/3/07",
      "Open": "4315.05",
      "High": "4363.35",
      "Low": "4313.55",
      "Close": "4357.5"
  },
  {
      "Date": "7/4/07",
      "Open": "4358.25",
      "High": "4386.45",
      "Low": "4342",
      "Close": "4359.3"
  },
  {
      "Date": "7/5/07",
      "Open": "4359.2",
      "High": "4378.55",
      "Low": "4311.8",
      "Close": "4353.9"
  },
  {
      "Date": "7/6/07",
      "Open": "4353.1",
      "High": "4411",
      "Low": "4304",
      "Close": "4384.8"
  },
  {
      "Date": "7/9/07",
      "Open": "4385.45",
      "High": "4427.55",
      "Low": "4385.45",
      "Close": "4419.4"
  },
  {
      "Date": "7/10/07",
      "Open": "4419.1",
      "High": "4434.45",
      "Low": "4393",
      "Close": "4406"
  },
  {
      "Date": "7/11/07",
      "Open": "4403.8",
      "High": "4411.45",
      "Low": "4344.7",
      "Close": "4387.1"
  },
  {
      "Date": "7/12/07",
      "Open": "4388.05",
      "High": "4451.95",
      "Low": "4387.05",
      "Close": "4446.1"
  },
  {
      "Date": "7/13/07",
      "Open": "4446.4",
      "High": "4513.9",
      "Low": "4446.05",
      "Close": "4504.5"
  },
  {
      "Date": "7/16/07",
      "Open": "4505.95",
      "High": "4521.85",
      "Low": "4495.95",
      "Close": "4512.1"
  },
  {
      "Date": "7/17/07",
      "Open": "4497.1",
      "High": "4550.25",
      "Low": "4488.25",
      "Close": "4496.7"
  },
  {
      "Date": "7/18/07",
      "Open": "4498.65",
      "High": "4510.8",
      "Low": "4452.85",
      "Close": "4499.5"
  },
  {
      "Date": "7/19/07",
      "Open": "4500.35",
      "High": "4573",
      "Low": "4496.2",
      "Close": "4562.1"
  },
  {
      "Date": "7/20/07",
      "Open": "4563.75",
      "High": "4600.8",
      "Low": "4553.8",
      "Close": "4566"
  },
  {
      "Date": "7/23/07",
      "Open": "4564.25",
      "High": "4628.45",
      "Low": "4547.2",
      "Close": "4619.3"
  },
  {
      "Date": "7/24/07",
      "Open": "4620.05",
      "High": "4647.95",
      "Low": "4609.75",
      "Close": "4620.7"
  },
  {
      "Date": "7/25/07",
      "Open": "4620.5",
      "High": "4620.5",
      "Low": "4555.8",
      "Close": "4588.7"
  },
  {
      "Date": "7/26/07",
      "Open": "4593.1",
      "High": "4624.3",
      "Low": "4570.8",
      "Close": "4619.8"
  },
  {
      "Date": "7/27/07",
      "Open": "4618.65",
      "High": "4618.9",
      "Low": "4424.25",
      "Close": "4445.2"
  },
  {
      "Date": "7/30/07",
      "Open": "4442.35",
      "High": "4493.05",
      "Low": "4403.7",
      "Close": "4440"
  },
  {
      "Date": "7/31/07",
      "Open": "4444.25",
      "High": "4534.2",
      "Low": "4432.85",
      "Close": "4528.8"
  },
  {
      "Date": "8/1/07",
      "Open": "4528.85",
      "High": "4532.9",
      "Low": "4339.75",
      "Close": "4345.8"
  },
  {
      "Date": "8/2/07",
      "Open": "4356.35",
      "High": "4399.75",
      "Low": "4327",
      "Close": "4356.3"
  },
  {
      "Date": "8/3/07",
      "Open": "4355.75",
      "High": "4428.1",
      "Low": "4355.75",
      "Close": "4401.5"
  },
  {
      "Date": "8/6/07",
      "Open": "4404.05",
      "High": "4404.05",
      "Low": "4267.15",
      "Close": "4339.5"
  },
  {
      "Date": "8/7/07",
      "Open": "4341.5",
      "High": "4406.4",
      "Low": "4341.5",
      "Close": "4356.3"
  },
  {
      "Date": "8/8/07",
      "Open": "4357",
      "High": "4472.15",
      "Low": "4356.75",
      "Close": "4462.1"
  },
  {
      "Date": "8/9/07",
      "Open": "4462.25",
      "High": "4530.05",
      "Low": "4390.8",
      "Close": "4403.2"
  },
  {
      "Date": "8/10/07",
      "Open": "4393.6",
      "High": "4395.5",
      "Low": "4239.2",
      "Close": "4333.3"
  },
  {
      "Date": "8/13/07",
      "Open": "4324.65",
      "High": "4383.8",
      "Low": "4324.65",
      "Close": "4373.6"
  },
  {
      "Date": "8/14/07",
      "Open": "4373.9",
      "High": "4394.3",
      "Low": "4354.35",
      "Close": "4370.2"
  },
  {
      "Date": "8/16/07",
      "Open": "4366",
      "High": "4366",
      "Low": "4171.15",
      "Close": "4178.6"
  },
  {
      "Date": "8/17/07",
      "Open": "4171.1",
      "High": "4171.1",
      "Low": "4002.2",
      "Close": "4108"
  },
  {
      "Date": "8/20/07",
      "Open": "4108.95",
      "High": "4262.6",
      "Low": "4108.95",
      "Close": "4209"
  },
  {
      "Date": "8/21/07",
      "Open": "4209.55",
      "High": "4238.1",
      "Low": "4058.55",
      "Close": "4074.9"
  },
  {
      "Date": "8/22/07",
      "Open": "4081.25",
      "High": "4165.7",
      "Low": "4040.15",
      "Close": "4153.1"
  },
  {
      "Date": "8/23/07",
      "Open": "4160.1",
      "High": "4249.85",
      "Low": "4100.8",
      "Close": "4114.9"
  },
  {
      "Date": "8/24/07",
      "Open": "4113.05",
      "High": "4201.45",
      "Low": "4110.05",
      "Close": "4190.1"
  },
  {
      "Date": "8/27/07",
      "Open": "4193.6",
      "High": "4310",
      "Low": "4193.6",
      "Close": "4302.6"
  },
  {
      "Date": "8/28/07",
      "Open": "4302.4",
      "High": "4329.15",
      "Low": "4280.6",
      "Close": "4320.7"
  },
  {
      "Date": "8/29/07",
      "Open": "4317.7",
      "High": "4368.6",
      "Low": "4226.35",
      "Close": "4359.3"
  },
  {
      "Date": "8/30/07",
      "Open": "4360.35",
      "High": "4422.3",
      "Low": "4359.75",
      "Close": "4412.3"
  },
  {
      "Date": "8/31/07",
      "Open": "4412.6",
      "High": "4471.3",
      "Low": "4403",
      "Close": "4464"
  },
  {
      "Date": "9/3/07",
      "Open": "4466.65",
      "High": "4490.55",
      "Low": "4452.4",
      "Close": "4474.7"
  },
  {
      "Date": "9/4/07",
      "Open": "4481.55",
      "High": "4501.3",
      "Low": "4460.4",
      "Close": "4479.2"
  },
  {
      "Date": "9/5/07",
      "Open": "4479.6",
      "High": "4507.75",
      "Low": "4458.55",
      "Close": "4475.8"
  },
  {
      "Date": "9/6/07",
      "Open": "4475.7",
      "High": "4522.5",
      "Low": "4445.55",
      "Close": "4518.6"
  },
  {
      "Date": "9/7/07",
      "Open": "4518.65",
      "High": "4547.75",
      "Low": "4499.9",
      "Close": "4509.5"
  },
  {
      "Date": "9/10/07",
      "Open": "4506.85",
      "High": "4515.25",
      "Low": "4452.95",
      "Close": "4507.8"
  },
  {
      "Date": "9/11/07",
      "Open": "4509.65",
      "High": "4538.6",
      "Low": "4487.15",
      "Close": "4497"
  },
  {
      "Date": "9/12/07",
      "Open": "4498.65",
      "High": "4531.5",
      "Low": "4490.4",
      "Close": "4496.8"
  },
  {
      "Date": "9/13/07",
      "Open": "4498.05",
      "High": "4540.6",
      "Low": "4498.05",
      "Close": "4528.9"
  },
  {
      "Date": "9/14/07",
      "Open": "4530",
      "High": "4582.6",
      "Low": "4508.85",
      "Close": "4518"
  },
  {
      "Date": "9/17/07",
      "Open": "4518.45",
      "High": "4549.05",
      "Low": "4482.85",
      "Close": "4494.6"
  },
  {
      "Date": "9/18/07",
      "Open": "4494.1",
      "High": "4551.8",
      "Low": "4481.55",
      "Close": "4546.2"
  },
  {
      "Date": "9/19/07",
      "Open": "4550.25",
      "High": "4739",
      "Low": "4550.25",
      "Close": "4732.3"
  },
  {
      "Date": "9/20/07",
      "Open": "4734.85",
      "High": "4760.85",
      "Low": "4721.15",
      "Close": "4747.5"
  },
  {
      "Date": "9/21/07",
      "Open": "4752.95",
      "High": "4855.7",
      "Low": "4733.7",
      "Close": "4837.5"
  },
  {
      "Date": "9/24/07",
      "Open": "4837.15",
      "High": "4941.15",
      "Low": "4837.15",
      "Close": "4932.2"
  },
  {
      "Date": "9/25/07",
      "Open": "4939.1",
      "High": "4953.9",
      "Low": "4878.15",
      "Close": "4938.8"
  },
  {
      "Date": "9/26/07",
      "Open": "4937.6",
      "High": "4980.85",
      "Low": "4930.35",
      "Close": "4940.5"
  },
  {
      "Date": "9/27/07",
      "Open": "4942.7",
      "High": "5016.4",
      "Low": "4942.7",
      "Close": "5000.5"
  },
  {
      "Date": "9/28/07",
      "Open": "4996.45",
      "High": "5055.8",
      "Low": "4996.45",
      "Close": "5021.3"
  },
  {
      "Date": "10/1/07",
      "Open": "5021.5",
      "High": "5089.3",
      "Low": "5001.35",
      "Close": "5068.9"
  },
  {
      "Date": "10/3/07",
      "Open": "5069",
      "High": "5261.35",
      "Low": "5034.15",
      "Close": "5210.8"
  },
  {
      "Date": "10/4/07",
      "Open": "5211.65",
      "High": "5233.1",
      "Low": "5126.05",
      "Close": "5208.6"
  },
  {
      "Date": "10/5/07",
      "Open": "5208.15",
      "High": "5248.55",
      "Low": "5164.5",
      "Close": "5185.8"
  },
  {
      "Date": "10/8/07",
      "Open": "5186.25",
      "High": "5249.3",
      "Low": "5024.75",
      "Close": "5085.1"
  },
  {
      "Date": "10/9/07",
      "Open": "5062.65",
      "High": "5348.7",
      "Low": "5000.95",
      "Close": "5327.2"
  },
  {
      "Date": "10/10/07",
      "Open": "5328.15",
      "High": "5454.7",
      "Low": "5328.15",
      "Close": "5441.4"
  },
  {
      "Date": "10/11/07",
      "Open": "5438.9",
      "High": "5532.75",
      "Low": "5424.25",
      "Close": "5524.8"
  },
  {
      "Date": "10/12/07",
      "Open": "5525.3",
      "High": "5549.3",
      "Low": "5402.6",
      "Close": "5428.2"
  },
  {
      "Date": "10/15/07",
      "Open": "5428.35",
      "High": "5682.65",
      "Low": "5419.9",
      "Close": "5670.4"
  },
  {
      "Date": "10/16/07",
      "Open": "5670.65",
      "High": "5708.35",
      "Low": "5578.45",
      "Close": "5668"
  },
  {
      "Date": "10/17/07",
      "Open": "5658.9",
      "High": "5658.9",
      "Low": "5107.3",
      "Close": "5559.3"
  },
  {
      "Date": "10/18/07",
      "Open": "5551.1",
      "High": "5736.8",
      "Low": "5269.65",
      "Close": "5351"
  },
  {
      "Date": "10/19/07",
      "Open": "5360.35",
      "High": "5390.85",
      "Low": "5101.75",
      "Close": "5215.3"
  },
  {
      "Date": "10/22/07",
      "Open": "5202.75",
      "High": "5247.4",
      "Low": "5070.9",
      "Close": "5184"
  },
  {
      "Date": "10/23/07",
      "Open": "5185.3",
      "High": "5488.5",
      "Low": "5176.85",
      "Close": "5473.7"
  },
  {
      "Date": "10/24/07",
      "Open": "5477.6",
      "High": "5577.9",
      "Low": "5419.4",
      "Close": "5496.1"
  },
  {
      "Date": "10/25/07",
      "Open": "5499.05",
      "High": "5605.95",
      "Low": "5469.3",
      "Close": "5568.9"
  },
  {
      "Date": "10/26/07",
      "Open": "5564.25",
      "High": "5716.9",
      "Low": "5513.35",
      "Close": "5702.3"
  },
  {
      "Date": "10/29/07",
      "Open": "5708.9",
      "High": "5922.5",
      "Low": "5708.9",
      "Close": "5905.9"
  },
  {
      "Date": "10/30/07",
      "Open": "5917.55",
      "High": "5976",
      "Low": "5833.9",
      "Close": "5868.7"
  },
  {
      "Date": "10/31/07",
      "Open": "5868.9",
      "High": "5952.25",
      "Low": "5861.3",
      "Close": "5900.6"
  },
  {
      "Date": "11/1/07",
      "Open": "5903.8",
      "High": "6011.95",
      "Low": "5837.2",
      "Close": "5866.4"
  },
  {
      "Date": "11/2/07",
      "Open": "5854.85",
      "High": "5944.75",
      "Low": "5714.25",
      "Close": "5932.4"
  },
  {
      "Date": "11/5/07",
      "Open": "5931.9",
      "High": "5948.55",
      "Low": "5819.6",
      "Close": "5847.3"
  },
  {
      "Date": "11/6/07",
      "Open": "5847.1",
      "High": "5957.2",
      "Low": "5759.95",
      "Close": "5786.5"
  },
  {
      "Date": "11/7/07",
      "Open": "5782.4",
      "High": "5861.35",
      "Low": "5744.3",
      "Close": "5782.3"
  },
  {
      "Date": "11/8/07",
      "Open": "5779.3",
      "High": "5780.8",
      "Low": "5646.75",
      "Close": "5698.7"
  },
  {
      "Date": "11/9/07",
      "Open": "5703.45",
      "High": "5794.2",
      "Low": "5614.9",
      "Close": "5663.2"
  },
  {
      "Date": "11/12/07",
      "Open": "5660.6",
      "High": "5660.6",
      "Low": "5477.5",
      "Close": "5617.1"
  },
  {
      "Date": "11/13/07",
      "Open": "5612.35",
      "High": "5758.85",
      "Low": "5591.6",
      "Close": "5695.4"
  },
  {
      "Date": "11/14/07",
      "Open": "5703.95",
      "High": "5950.2",
      "Low": "5700.05",
      "Close": "5937.9"
  },
  {
      "Date": "11/15/07",
      "Open": "5942.7",
      "High": "5966.95",
      "Low": "5895.65",
      "Close": "5912.1"
  },
  {
      "Date": "11/16/07",
      "Open": "5913.15",
      "High": "5948.05",
      "Low": "5817.4",
      "Close": "5906.8"
  },
  {
      "Date": "11/19/07",
      "Open": "5908.05",
      "High": "5981.8",
      "Low": "5893.8",
      "Close": "5907.6"
  },
  {
      "Date": "11/20/07",
      "Open": "5911.25",
      "High": "5923.7",
      "Low": "5755.8",
      "Close": "5780.9"
  },
  {
      "Date": "11/21/07",
      "Open": "5778.8",
      "High": "5790.05",
      "Low": "5530.85",
      "Close": "5561"
  },
  {
      "Date": "11/22/07",
      "Open": "5564.65",
      "High": "5608.65",
      "Low": "5394.35",
      "Close": "5519.3"
  },
  {
      "Date": "11/23/07",
      "Open": "5524.3",
      "High": "5638.6",
      "Low": "5514.75",
      "Close": "5608.6"
  },
  {
      "Date": "11/26/07",
      "Open": "5611.3",
      "High": "5772.55",
      "Low": "5608.95",
      "Close": "5731.7"
  },
  {
      "Date": "11/27/07",
      "Open": "5729.25",
      "High": "5743.55",
      "Low": "5655.6",
      "Close": "5698.1"
  },
  {
      "Date": "11/28/07",
      "Open": "5699.55",
      "High": "5749.95",
      "Low": "5595.5",
      "Close": "5617.5"
  },
  {
      "Date": "11/29/07",
      "Open": "5617.8",
      "High": "5725",
      "Low": "5612.1",
      "Close": "5634.6"
  },
  {
      "Date": "11/30/07",
      "Open": "5633.9",
      "High": "5782.55",
      "Low": "5632.65",
      "Close": "5762.7"
  },
  {
      "Date": "12/3/07",
      "Open": "5765.45",
      "High": "5878.8",
      "Low": "5754.6",
      "Close": "5865"
  },
  {
      "Date": "12/4/07",
      "Open": "5870.2",
      "High": "5897.25",
      "Low": "5840.3",
      "Close": "5858.3"
  },
  {
      "Date": "12/5/07",
      "Open": "5861.9",
      "High": "5949.3",
      "Low": "5859.95",
      "Close": "5940"
  },
  {
      "Date": "12/6/07",
      "Open": "5941.05",
      "High": "6027.05",
      "Low": "5919.8",
      "Close": "5954.7"
  },
  {
      "Date": "12/7/07",
      "Open": "5963.6",
      "High": "6042.1",
      "Low": "5894.8",
      "Close": "5974.3"
  },
  {
      "Date": "12/10/07",
      "Open": "5974",
      "High": "6015.3",
      "Low": "5923.35",
      "Close": "5960.6"
  },
  {
      "Date": "12/11/07",
      "Open": "5960.4",
      "High": "6111.2",
      "Low": "5960.4",
      "Close": "6097.2"
  },
  {
      "Date": "12/12/07",
      "Open": "6067.05",
      "High": "6175.65",
      "Low": "6005.45",
      "Close": "6159.3"
  },
  {
      "Date": "12/13/07",
      "Open": "6159.25",
      "High": "6185.4",
      "Low": "6040.4",
      "Close": "6058.1"
  },
  {
      "Date": "12/14/07",
      "Open": "6056.2",
      "High": "6078.55",
      "Low": "6015.05",
      "Close": "6047.7"
  },
  {
      "Date": "12/17/07",
      "Open": "6037.95",
      "High": "6039.95",
      "Low": "5740.6",
      "Close": "5777"
  },
  {
      "Date": "12/18/07",
      "Open": "5777.6",
      "High": "5874.6",
      "Low": "5710.6",
      "Close": "5742.3"
  },
  {
      "Date": "12/19/07",
      "Open": "5730.25",
      "High": "5840.8",
      "Low": "5676.7",
      "Close": "5751.1"
  },
  {
      "Date": "12/20/07",
      "Open": "5751.75",
      "High": "5799.5",
      "Low": "5742.75",
      "Close": "5766.5"
  },
  {
      "Date": "12/24/07",
      "Open": "5771.3",
      "High": "6001.05",
      "Low": "5771.3",
      "Close": "5985.1"
  },
  {
      "Date": "12/26/07",
      "Open": "5988.45",
      "High": "6085.25",
      "Low": "5988.45",
      "Close": "6070.7"
  },
  {
      "Date": "12/27/07",
      "Open": "6069",
      "High": "6110.85",
      "Low": "6060.2",
      "Close": "6081.5"
  },
  {
      "Date": "12/28/07",
      "Open": "6079.55",
      "High": "6098.6",
      "Low": "6021.9",
      "Close": "6079.7"
  },
  {
      "Date": "12/31/07",
      "Open": "6095",
      "High": "6167.75",
      "Low": "6095",
      "Close": "6138.6"
  },
  {
      "Date": "1/1/08",
      "Open": "6136.75",
      "High": "6165.35",
      "Low": "6109.85",
      "Close": "6144.3"
  },
  {
      "Date": "1/2/08",
      "Open": "6144.7",
      "High": "6197",
      "Low": "6060.85",
      "Close": "6179.4"
  },
  {
      "Date": "1/3/08",
      "Open": "6184.25",
      "High": "6230.15",
      "Low": "6126.4",
      "Close": "6178.5"
  },
  {
      "Date": "1/4/08",
      "Open": "6179.1",
      "High": "6300.05",
      "Low": "6179.1",
      "Close": "6274.3"
  },
  {
      "Date": "1/7/08",
      "Open": "6271",
      "High": "6289.8",
      "Low": "6193.35",
      "Close": "6279.1"
  },
  {
      "Date": "1/8/08",
      "Open": "6282.45",
      "High": "6357.1",
      "Low": "6221.6",
      "Close": "6287.8"
  },
  {
      "Date": "1/9/08",
      "Open": "6287.55",
      "High": "6338.3",
      "Low": "6231.25",
      "Close": "6272"
  },
  {
      "Date": "1/10/08",
      "Open": "6278.1",
      "High": "6347",
      "Low": "6142.9",
      "Close": "6156.9"
  },
  {
      "Date": "1/11/08",
      "Open": "6166.65",
      "High": "6224.2",
      "Low": "6112.55",
      "Close": "6200.1"
  },
  {
      "Date": "1/14/08",
      "Open": "6208.8",
      "High": "6244.15",
      "Low": "6172",
      "Close": "6206.8"
  },
  {
      "Date": "1/15/08",
      "Open": "6226.35",
      "High": "6260.45",
      "Low": "6053.3",
      "Close": "6074.2"
  },
  {
      "Date": "1/16/08",
      "Open": "6065",
      "High": "6065",
      "Low": "5825.75",
      "Close": "5935.7"
  },
  {
      "Date": "1/17/08",
      "Open": "5937.95",
      "High": "6013.15",
      "Low": "5880.3",
      "Close": "5913.2"
  },
  {
      "Date": "1/18/08",
      "Open": "5907.75",
      "High": "5908.75",
      "Low": "5677",
      "Close": "5705.3"
  },
  {
      "Date": "1/21/08",
      "Open": "5705",
      "High": "5705",
      "Low": "4977.1",
      "Close": "5208.8"
  },
  {
      "Date": "1/22/08",
      "Open": "5203.35",
      "High": "5203.35",
      "Low": "4448.5",
      "Close": "4899.3"
  },
  {
      "Date": "1/23/08",
      "Open": "4903.05",
      "High": "5328.05",
      "Low": "4891.6",
      "Close": "5203.4"
  },
  {
      "Date": "1/24/08",
      "Open": "5208",
      "High": "5357.2",
      "Low": "4995.8",
      "Close": "5033.4"
  },
  {
      "Date": "1/25/08",
      "Open": "5035.05",
      "High": "5399.25",
      "Low": "5035.05",
      "Close": "5383.3"
  },
  {
      "Date": "1/28/08",
      "Open": "5380.95",
      "High": "5380.95",
      "Low": "5071",
      "Close": "5274.1"
  },
  {
      "Date": "1/29/08",
      "Open": "5279.55",
      "High": "5391.6",
      "Low": "5225.25",
      "Close": "5280.8"
  },
  {
      "Date": "1/30/08",
      "Open": "5283.75",
      "High": "5314.3",
      "Low": "5142.25",
      "Close": "5167.6"
  },
  {
      "Date": "1/31/08",
      "Open": "5172.25",
      "High": "5251.65",
      "Low": "5071.15",
      "Close": "5137.4"
  },
  {
      "Date": "2/1/08",
      "Open": "5140.6",
      "High": "5339.95",
      "Low": "5090.75",
      "Close": "5317.2"
  },
  {
      "Date": "2/4/08",
      "Open": "5315.55",
      "High": "5545.2",
      "Low": "5315.55",
      "Close": "5463.5"
  },
  {
      "Date": "2/5/08",
      "Open": "5463.75",
      "High": "5500.6",
      "Low": "5412.95",
      "Close": "5483.9"
  },
  {
      "Date": "2/6/08",
      "Open": "5470.4",
      "High": "5470.4",
      "Low": "5257.05",
      "Close": "5322.5"
  },
  {
      "Date": "2/7/08",
      "Open": "5322.55",
      "High": "5344.6",
      "Low": "5113.85",
      "Close": "5133.2"
  },
  {
      "Date": "2/8/08",
      "Open": "5132.1",
      "High": "5173.85",
      "Low": "5034.25",
      "Close": "5120.3"
  },
  {
      "Date": "2/11/08",
      "Open": "5120.55",
      "High": "5126.4",
      "Low": "4803.6",
      "Close": "4857"
  },
  {
      "Date": "2/12/08",
      "Open": "4877.85",
      "High": "4949.6",
      "Low": "4820.45",
      "Close": "4838.2"
  },
  {
      "Date": "2/13/08",
      "Open": "4836.55",
      "High": "4986.55",
      "Low": "4836.55",
      "Close": "4929.4"
  },
  {
      "Date": "2/14/08",
      "Open": "4944.65",
      "High": "5220.25",
      "Low": "4944.65",
      "Close": "5202"
  },
  {
      "Date": "2/15/08",
      "Open": "5202.85",
      "High": "5315.4",
      "Low": "5104.75",
      "Close": "5302.9"
  },
  {
      "Date": "2/18/08",
      "Open": "5304.45",
      "High": "5348.6",
      "Low": "5224",
      "Close": "5276.9"
  },
  {
      "Date": "2/19/08",
      "Open": "5278.4",
      "High": "5368.45",
      "Low": "5262",
      "Close": "5280.8"
  },
  {
      "Date": "2/20/08",
      "Open": "5267.15",
      "High": "5267.15",
      "Low": "5116.3",
      "Close": "5154.4"
  },
  {
      "Date": "2/21/08",
      "Open": "5156.9",
      "High": "5241.35",
      "Low": "5120.05",
      "Close": "5191.8"
  },
  {
      "Date": "2/22/08",
      "Open": "5183.4",
      "High": "5184",
      "Low": "5092.8",
      "Close": "5110.7"
  },
  {
      "Date": "2/25/08",
      "Open": "5112.25",
      "High": "5212.35",
      "Low": "5055.15",
      "Close": "5200.7"
  },
  {
      "Date": "2/26/08",
      "Open": "5200.8",
      "High": "5281.2",
      "Low": "5200.8",
      "Close": "5270"
  },
  {
      "Date": "2/27/08",
      "Open": "5271.4",
      "High": "5368.15",
      "Low": "5249.75",
      "Close": "5268.4"
  },
  {
      "Date": "2/28/08",
      "Open": "5266.35",
      "High": "5302.85",
      "Low": "5227.15",
      "Close": "5285.1"
  },
  {
      "Date": "2/29/08",
      "Open": "5285",
      "High": "5290.8",
      "Low": "5098.35",
      "Close": "5223.5"
  },
  {
      "Date": "3/3/08",
      "Open": "5222.8",
      "High": "5222.8",
      "Low": "4936.05",
      "Close": "4953"
  },
  {
      "Date": "3/4/08",
      "Open": "4958.55",
      "High": "4976.7",
      "Low": "4812.95",
      "Close": "4864.2"
  },
  {
      "Date": "3/5/08",
      "Open": "4866.85",
      "High": "4936.75",
      "Low": "4847.25",
      "Close": "4921.4"
  },
  {
      "Date": "3/7/08",
      "Open": "4918.3",
      "High": "4918.3",
      "Low": "4672.25",
      "Close": "4771.6"
  },
  {
      "Date": "3/10/08",
      "Open": "4767.8",
      "High": "4814.95",
      "Low": "4620.5",
      "Close": "4800.4"
  },
  {
      "Date": "3/11/08",
      "Open": "4796.3",
      "High": "4888.5",
      "Low": "4732.85",
      "Close": "4865.9"
  },
  {
      "Date": "3/12/08",
      "Open": "4869.75",
      "High": "5019.2",
      "Low": "4854.75",
      "Close": "4872"
  },
  {
      "Date": "3/13/08",
      "Open": "4868.7",
      "High": "4868.8",
      "Low": "4580.15",
      "Close": "4623.6"
  },
  {
      "Date": "3/14/08",
      "Open": "4623.8",
      "High": "4758.95",
      "Low": "4607.55",
      "Close": "4745.8"
  },
  {
      "Date": "3/17/08",
      "Open": "4745.45",
      "High": "4745.45",
      "Low": "4482.1",
      "Close": "4503.1"
  },
  {
      "Date": "3/18/08",
      "Open": "4519.9",
      "High": "4617.95",
      "Low": "4468.55",
      "Close": "4533"
  },
  {
      "Date": "3/19/08",
      "Open": "4534.75",
      "High": "4718.4",
      "Low": "4533.9",
      "Close": "4573.9"
  },
  {
      "Date": "3/24/08",
      "Open": "4576.8",
      "High": "4649.45",
      "Low": "4539.8",
      "Close": "4609.8"
  },
  {
      "Date": "3/25/08",
      "Open": "4616.8",
      "High": "4896.8",
      "Low": "4616.8",
      "Close": "4877.5"
  },
  {
      "Date": "3/26/08",
      "Open": "4878.7",
      "High": "4912.3",
      "Low": "4808.65",
      "Close": "4828.8"
  },
  {
      "Date": "3/27/08",
      "Open": "4828.8",
      "High": "4863.75",
      "Low": "4769.6",
      "Close": "4830.2"
  },
  {
      "Date": "3/28/08",
      "Open": "4830",
      "High": "4970.8",
      "Low": "4796.35",
      "Close": "4942"
  },
  {
      "Date": "3/31/08",
      "Open": "4942.15",
      "High": "4947",
      "Low": "4703.85",
      "Close": "4734.5"
  },
  {
      "Date": "4/1/08",
      "Open": "4735.65",
      "High": "4800.75",
      "Low": "4628.75",
      "Close": "4739.5"
  },
  {
      "Date": "4/2/08",
      "Open": "4741.4",
      "High": "4916.75",
      "Low": "4741.4",
      "Close": "4754.2"
  },
  {
      "Date": "4/3/08",
      "Open": "4753.9",
      "High": "4833.25",
      "Low": "4738.2",
      "Close": "4771.6"
  },
  {
      "Date": "4/4/08",
      "Open": "4766.7",
      "High": "4791.7",
      "Low": "4632.7",
      "Close": "4647"
  },
  {
      "Date": "4/7/08",
      "Open": "4631.35",
      "High": "4798.55",
      "Low": "4628.8",
      "Close": "4761.2"
  },
  {
      "Date": "4/8/08",
      "Open": "4760.65",
      "High": "4769.55",
      "Low": "4677.8",
      "Close": "4709.6"
  },
  {
      "Date": "4/9/08",
      "Open": "4707.05",
      "High": "4758.25",
      "Low": "4667.5",
      "Close": "4747"
  },
  {
      "Date": "4/10/08",
      "Open": "4747.55",
      "High": "4799.5",
      "Low": "4720.9",
      "Close": "4733"
  },
  {
      "Date": "4/11/08",
      "Open": "4734.35",
      "High": "4817.4",
      "Low": "4727.25",
      "Close": "4777.8"
  },
  {
      "Date": "4/15/08",
      "Open": "4779.95",
      "High": "4917.1",
      "Low": "4708.3",
      "Close": "4879.6"
  },
  {
      "Date": "4/16/08",
      "Open": "4881.65",
      "High": "4951.4",
      "Low": "4874.05",
      "Close": "4887.3"
  },
  {
      "Date": "4/17/08",
      "Open": "4890.6",
      "High": "4984.95",
      "Low": "4889.65",
      "Close": "4958.4"
  },
  {
      "Date": "4/21/08",
      "Open": "4955.9",
      "High": "5053.4",
      "Low": "4955.9",
      "Close": "5037"
  },
  {
      "Date": "4/22/08",
      "Open": "5037.05",
      "High": "5074.25",
      "Low": "4994.05",
      "Close": "5049.3"
  },
  {
      "Date": "4/23/08",
      "Open": "5044.35",
      "High": "5083.5",
      "Low": "5003.55",
      "Close": "5022.8"
  },
  {
      "Date": "4/24/08",
      "Open": "5022.9",
      "High": "5072.7",
      "Low": "4991.35",
      "Close": "4999.8"
  },
  {
      "Date": "4/25/08",
      "Open": "4999.15",
      "High": "5117.7",
      "Low": "4999.15",
      "Close": "5111.7"
  },
  {
      "Date": "4/28/08",
      "Open": "5112.5",
      "High": "5147.45",
      "Low": "5079.15",
      "Close": "5089.6"
  },
  {
      "Date": "4/29/08",
      "Open": "5092.4",
      "High": "5210.9",
      "Low": "5082.15",
      "Close": "5195.5"
  },
  {
      "Date": "4/30/08",
      "Open": "5198.35",
      "High": "5230.75",
      "Low": "5155.85",
      "Close": "5165.9"
  },
  {
      "Date": "5/2/08",
      "Open": "5265.3",
      "High": "5298.85",
      "Low": "5197.6",
      "Close": "5228.2"
  },
  {
      "Date": "5/5/08",
      "Open": "5227.25",
      "High": "5254.5",
      "Low": "5182.6",
      "Close": "5192.2"
  },
  {
      "Date": "5/6/08",
      "Open": "5192.35",
      "High": "5206.5",
      "Low": "5110.9",
      "Close": "5144.6"
  },
  {
      "Date": "5/7/08",
      "Open": "5156.7",
      "High": "5159.05",
      "Low": "5101.25",
      "Close": "5135.5"
  },
  {
      "Date": "5/8/08",
      "Open": "5135.8",
      "High": "5143.05",
      "Low": "5062.45",
      "Close": "5081.7"
  },
  {
      "Date": "5/9/08",
      "Open": "5070.85",
      "High": "5087.65",
      "Low": "4969.4",
      "Close": "4982.6"
  },
  {
      "Date": "5/12/08",
      "Open": "4981",
      "High": "5021.75",
      "Low": "4913.8",
      "Close": "5012.6"
  },
  {
      "Date": "5/13/08",
      "Open": "5008.6",
      "High": "5066",
      "Low": "4943.75",
      "Close": "4957.8"
  },
  {
      "Date": "5/14/08",
      "Open": "4958.45",
      "High": "5026.1",
      "Low": "4932.05",
      "Close": "5011.7"
  },
  {
      "Date": "5/15/08",
      "Open": "5010.9",
      "High": "5118.55",
      "Low": "4999.6",
      "Close": "5115.2"
  },
  {
      "Date": "5/16/08",
      "Open": "5115.65",
      "High": "5167.4",
      "Low": "5106.4",
      "Close": "5157.7"
  },
  {
      "Date": "5/20/08",
      "Open": "5157",
      "High": "5160.05",
      "Low": "5072.4",
      "Close": "5104.9"
  },
  {
      "Date": "5/21/08",
      "Open": "5105.7",
      "High": "5135.55",
      "Low": "5048.7",
      "Close": "5117.6"
  },
  {
      "Date": "5/22/08",
      "Open": "5117",
      "High": "5118.9",
      "Low": "5010.7",
      "Close": "5025.4"
  },
  {
      "Date": "5/23/08",
      "Open": "5026.55",
      "High": "5059.05",
      "Low": "4940.7",
      "Close": "4946.5"
  },
  {
      "Date": "5/26/08",
      "Open": "4953.6",
      "High": "4953.6",
      "Low": "4858",
      "Close": "4875"
  },
  {
      "Date": "5/27/08",
      "Open": "4877.15",
      "High": "4932",
      "Low": "4846.2",
      "Close": "4859.8"
  },
  {
      "Date": "5/28/08",
      "Open": "4862.7",
      "High": "4926.9",
      "Low": "4835.65",
      "Close": "4918.3"
  },
  {
      "Date": "5/29/08",
      "Open": "4926.3",
      "High": "4957.4",
      "Low": "4801.9",
      "Close": "4835.3"
  },
  {
      "Date": "5/30/08",
      "Open": "4844.05",
      "High": "4908.85",
      "Low": "4833.45",
      "Close": "4870.1"
  },
  {
      "Date": "6/2/08",
      "Open": "4869.25",
      "High": "4908.8",
      "Low": "4713",
      "Close": "4739.6"
  },
  {
      "Date": "6/3/08",
      "Open": "4739.3",
      "High": "4739.3",
      "Low": "4634",
      "Close": "4715.9"
  },
  {
      "Date": "6/4/08",
      "Open": "4718.7",
      "High": "4731.5",
      "Low": "4564.5",
      "Close": "4585.6"
  },
  {
      "Date": "6/5/08",
      "Open": "4586.95",
      "High": "4690.6",
      "Low": "4536.25",
      "Close": "4676.9"
  },
  {
      "Date": "6/6/08",
      "Open": "4680.55",
      "High": "4746.3",
      "Low": "4614.25",
      "Close": "4627.8"
  },
  {
      "Date": "6/9/08",
      "Open": "4626.45",
      "High": "4626.45",
      "Low": "4411.6",
      "Close": "4500.9"
  },
  {
      "Date": "6/10/08",
      "Open": "4522",
      "High": "4522.55",
      "Low": "4369.8",
      "Close": "4449.8"
  },
  {
      "Date": "6/11/08",
      "Open": "4469.65",
      "High": "4541.05",
      "Low": "4468.05",
      "Close": "4523.6"
  },
  {
      "Date": "6/12/08",
      "Open": "4524.4",
      "High": "4550",
      "Low": "4392",
      "Close": "4539.3"
  },
  {
      "Date": "6/13/08",
      "Open": "4539.5",
      "High": "4563.35",
      "Low": "4491.35",
      "Close": "4517.1"
  },
  {
      "Date": "6/16/08",
      "Open": "4536.3",
      "High": "4617.7",
      "Low": "4536.3",
      "Close": "4572.5"
  },
  {
      "Date": "6/17/08",
      "Open": "4572.5",
      "High": "4664.05",
      "Low": "4561.75",
      "Close": "4653"
  },
  {
      "Date": "6/18/08",
      "Open": "4652.8",
      "High": "4679.75",
      "Low": "4569.9",
      "Close": "4582.4"
  },
  {
      "Date": "6/19/08",
      "Open": "4582.55",
      "High": "4585.7",
      "Low": "4488.95",
      "Close": "4504.2"
  },
  {
      "Date": "6/20/08",
      "Open": "4504.2",
      "High": "4532",
      "Low": "4333.6",
      "Close": "4347.5"
  },
  {
      "Date": "6/23/08",
      "Open": "4351.15",
      "High": "4351.15",
      "Low": "4225.5",
      "Close": "4266.4"
  },
  {
      "Date": "6/24/08",
      "Open": "4271.05",
      "High": "4305.9",
      "Low": "4156.1",
      "Close": "4191.1"
  },
  {
      "Date": "6/25/08",
      "Open": "4189.6",
      "High": "4264.55",
      "Low": "4093.2",
      "Close": "4252.6"
  },
  {
      "Date": "6/26/08",
      "Open": "4252.6",
      "High": "4324.75",
      "Low": "4230",
      "Close": "4315.8"
  },
  {
      "Date": "6/27/08",
      "Open": "4315.3",
      "High": "4315.3",
      "Low": "4119.2",
      "Close": "4136.6"
  },
  {
      "Date": "6/30/08",
      "Open": "4136.25",
      "High": "4163",
      "Low": "4021.7",
      "Close": "4040.5"
  },
  {
      "Date": "7/1/08",
      "Open": "4039.75",
      "High": "4075.4",
      "Low": "3878.2",
      "Close": "3896.7"
  },
  {
      "Date": "7/2/08",
      "Open": "3895.3",
      "High": "4107.15",
      "Low": "3848.25",
      "Close": "4093.3"
  },
  {
      "Date": "7/3/08",
      "Open": "4094.6",
      "High": "4097.35",
      "Low": "3874.85",
      "Close": "3925.7"
  },
  {
      "Date": "7/4/08",
      "Open": "3926.65",
      "High": "4033.5",
      "Low": "3896.4",
      "Close": "4016"
  },
  {
      "Date": "7/7/08",
      "Open": "4002",
      "High": "4114.5",
      "Low": "4002",
      "Close": "4030"
  },
  {
      "Date": "7/8/08",
      "Open": "4028.7",
      "High": "4028.7",
      "Low": "3896.05",
      "Close": "3988.5"
  },
  {
      "Date": "7/9/08",
      "Open": "3990.9",
      "High": "4169.4",
      "Low": "3990.9",
      "Close": "4157.1"
  },
  {
      "Date": "7/10/08",
      "Open": "4155.65",
      "High": "4187.95",
      "Low": "4110.4",
      "Close": "4162.2"
  },
  {
      "Date": "7/11/08",
      "Open": "4166",
      "High": "4215.5",
      "Low": "4014.45",
      "Close": "4049"
  },
  {
      "Date": "7/14/08",
      "Open": "4047.45",
      "High": "4118.1",
      "Low": "4004.25",
      "Close": "4039.7"
  },
  {
      "Date": "7/15/08",
      "Open": "4039.3",
      "High": "4040.7",
      "Low": "3835.5",
      "Close": "3861.1"
  },
  {
      "Date": "7/16/08",
      "Open": "3861.55",
      "High": "3920.05",
      "Low": "3790.2",
      "Close": "3816.7"
  },
  {
      "Date": "7/17/08",
      "Open": "3823.15",
      "High": "3968.75",
      "Low": "3823.15",
      "Close": "3947.2"
  },
  {
      "Date": "7/18/08",
      "Open": "3962.95",
      "High": "4110.55",
      "Low": "3926.3",
      "Close": "4092.2"
  },
  {
      "Date": "7/21/08",
      "Open": "4092.2",
      "High": "4168.15",
      "Low": "4072.75",
      "Close": "4159.5"
  },
  {
      "Date": "7/22/08",
      "Open": "4158.45",
      "High": "4262.45",
      "Low": "4137.95",
      "Close": "4240.1"
  },
  {
      "Date": "7/23/08",
      "Open": "4246.7",
      "High": "4491.55",
      "Low": "4246.7",
      "Close": "4476.8"
  },
  {
      "Date": "7/24/08",
      "Open": "4476.2",
      "High": "4539.45",
      "Low": "4385.85",
      "Close": "4433.5"
  },
  {
      "Date": "7/25/08",
      "Open": "4440.85",
      "High": "4440.85",
      "Low": "4297.15",
      "Close": "4311.8"
  },
  {
      "Date": "7/28/08",
      "Open": "4282.25",
      "High": "4352.65",
      "Low": "4282.25",
      "Close": "4332.1"
  },
  {
      "Date": "7/29/08",
      "Open": "4332.2",
      "High": "4332.2",
      "Low": "4159.15",
      "Close": "4189.8"
  },
  {
      "Date": "7/30/08",
      "Open": "4191.2",
      "High": "4327",
      "Low": "4191.2",
      "Close": "4313.5"
  },
  {
      "Date": "7/31/08",
      "Open": "4314.35",
      "High": "4342",
      "Low": "4285.55",
      "Close": "4332.9"
  },
  {
      "Date": "8/1/08",
      "Open": "4331.6",
      "High": "4422.95",
      "Low": "4235.7",
      "Close": "4413.5"
  },
  {
      "Date": "8/4/08",
      "Open": "4426.1",
      "High": "4436.15",
      "Low": "4362.9",
      "Close": "4395.3"
  },
  {
      "Date": "8/5/08",
      "Open": "4395.8",
      "High": "4515.15",
      "Low": "4376",
      "Close": "4502.8"
  },
  {
      "Date": "8/6/08",
      "Open": "4506.25",
      "High": "4615.9",
      "Low": "4503.9",
      "Close": "4517.5"
  },
  {
      "Date": "8/7/08",
      "Open": "4515.25",
      "High": "4580.15",
      "Low": "4493.7",
      "Close": "4523.8"
  },
  {
      "Date": "8/8/08",
      "Open": "4518.35",
      "High": "4546.35",
      "Low": "4464",
      "Close": "4529.5"
  },
  {
      "Date": "8/11/08",
      "Open": "4529.35",
      "High": "4625.2",
      "Low": "4529.35",
      "Close": "4620.4"
  },
  {
      "Date": "8/12/08",
      "Open": "4620.95",
      "High": "4649.85",
      "Low": "4525.75",
      "Close": "4552.2"
  },
  {
      "Date": "8/13/08",
      "Open": "4548.05",
      "High": "4572.65",
      "Low": "4497.25",
      "Close": "4529"
  },
  {
      "Date": "8/14/08",
      "Open": "4524.2",
      "High": "4529.8",
      "Low": "4421.25",
      "Close": "4430.7"
  },
  {
      "Date": "8/18/08",
      "Open": "4430.7",
      "High": "4447.4",
      "Low": "4379.85",
      "Close": "4393"
  },
  {
      "Date": "8/19/08",
      "Open": "4393.1",
      "High": "4393.7",
      "Low": "4316.55",
      "Close": "4368.2"
  },
  {
      "Date": "8/20/08",
      "Open": "4365.45",
      "High": "4434.9",
      "Low": "4365.45",
      "Close": "4415.7"
  },
  {
      "Date": "8/21/08",
      "Open": "4416.2",
      "High": "4418.55",
      "Low": "4271.3",
      "Close": "4283.8"
  },
  {
      "Date": "8/22/08",
      "Open": "4283.85",
      "High": "4337",
      "Low": "4248",
      "Close": "4327.4"
  },
  {
      "Date": "8/25/08",
      "Open": "4317.95",
      "High": "4398.8",
      "Low": "4317.95",
      "Close": "4335.3"
  },
  {
      "Date": "8/26/08",
      "Open": "4335.2",
      "High": "4345.05",
      "Low": "4283.3",
      "Close": "4337.5"
  },
  {
      "Date": "8/27/08",
      "Open": "4336.85",
      "High": "4364.25",
      "Low": "4282.65",
      "Close": "4292.1"
  },
  {
      "Date": "8/28/08",
      "Open": "4290.75",
      "High": "4304.5",
      "Low": "4201.85",
      "Close": "4214"
  },
  {
      "Date": "8/29/08",
      "Open": "4230.6",
      "High": "4368.8",
      "Low": "4230.6",
      "Close": "4360"
  },
  {
      "Date": "9/1/08",
      "Open": "4356.1",
      "High": "4365",
      "Low": "4281.35",
      "Close": "4348.6"
  },
  {
      "Date": "9/2/08",
      "Open": "4358.85",
      "High": "4522.4",
      "Low": "4343.1",
      "Close": "4504"
  },
  {
      "Date": "9/4/08",
      "Open": "4512.95",
      "High": "4514.6",
      "Low": "4419.45",
      "Close": "4447.7"
  },
  {
      "Date": "9/5/08",
      "Open": "4444.7",
      "High": "4444.7",
      "Low": "4328.9",
      "Close": "4352.3"
  },
  {
      "Date": "9/8/08",
      "Open": "4358.3",
      "High": "4558",
      "Low": "4358.3",
      "Close": "4482.3"
  },
  {
      "Date": "9/9/08",
      "Open": "4485.15",
      "High": "4497.5",
      "Low": "4418.95",
      "Close": "4468.7"
  },
  {
      "Date": "9/10/08",
      "Open": "4467.5",
      "High": "4467.5",
      "Low": "4382.35",
      "Close": "4400.2"
  },
  {
      "Date": "9/11/08",
      "Open": "4397.25",
      "High": "4399.3",
      "Low": "4272.75",
      "Close": "4290.3"
  },
  {
      "Date": "9/12/08",
      "Open": "4291.6",
      "High": "4323.9",
      "Low": "4200.15",
      "Close": "4228.4"
  },
  {
      "Date": "9/15/08",
      "Open": "4231.95",
      "High": "4237.25",
      "Low": "3955.4",
      "Close": "4072.9"
  },
  {
      "Date": "9/16/08",
      "Open": "4072.55",
      "High": "4090.1",
      "Low": "3919.35",
      "Close": "4074.9"
  },
  {
      "Date": "9/17/08",
      "Open": "4074.8",
      "High": "4116.7",
      "Low": "3974.6",
      "Close": "4008.2"
  },
  {
      "Date": "9/18/08",
      "Open": "4005.25",
      "High": "4050.1",
      "Low": "3799.55",
      "Close": "4038.1"
  },
  {
      "Date": "9/19/08",
      "Open": "4040.8",
      "High": "4262.65",
      "Low": "4040.8",
      "Close": "4245.2"
  },
  {
      "Date": "9/22/08",
      "Open": "4248.95",
      "High": "4303.25",
      "Low": "4202.4",
      "Close": "4223"
  },
  {
      "Date": "9/23/08",
      "Open": "4223.9",
      "High": "4224.7",
      "Low": "4117.9",
      "Close": "4126.9"
  },
  {
      "Date": "9/24/08",
      "Open": "4125.75",
      "High": "4207.95",
      "Low": "4115.85",
      "Close": "4161.2"
  },
  {
      "Date": "9/25/08",
      "Open": "4162.15",
      "High": "4172.6",
      "Low": "4077.5",
      "Close": "4110.5"
  },
  {
      "Date": "9/26/08",
      "Open": "4108.75",
      "High": "4110.7",
      "Low": "3970.35",
      "Close": "3985.2"
  },
  {
      "Date": "9/29/08",
      "Open": "3990.2",
      "High": "3997.55",
      "Low": "3777.3",
      "Close": "3850"
  },
  {
      "Date": "9/30/08",
      "Open": "3848.7",
      "High": "3966.85",
      "Low": "3715.05",
      "Close": "3921.2"
  },
  {
      "Date": "10/1/08",
      "Open": "3921.85",
      "High": "4000.5",
      "Low": "3861.25",
      "Close": "3950.7"
  },
  {
      "Date": "10/3/08",
      "Open": "3953.55",
      "High": "3969.55",
      "Low": "3804.85",
      "Close": "3818.3"
  },
  {
      "Date": "10/6/08",
      "Open": "3817.3",
      "High": "3820.85",
      "Low": "3581.6",
      "Close": "3602.3"
  },
  {
      "Date": "10/7/08",
      "Open": "3606.95",
      "High": "3732.65",
      "Low": "3537",
      "Close": "3606.6"
  },
  {
      "Date": "10/8/08",
      "Open": "3604.4",
      "High": "3604.4",
      "Low": "3329.45",
      "Close": "3513.6"
  },
  {
      "Date": "10/10/08",
      "Open": "3502.05",
      "High": "3502.05",
      "Low": "3198.95",
      "Close": "3279.9"
  },
  {
      "Date": "10/13/08",
      "Open": "3272.9",
      "High": "3510.2",
      "Low": "3272.9",
      "Close": "3490.7"
  },
  {
      "Date": "10/14/08",
      "Open": "3494.1",
      "High": "3648.25",
      "Low": "3491.5",
      "Close": "3518.6"
  },
  {
      "Date": "10/15/08",
      "Open": "3517.9",
      "High": "3518.5",
      "Low": "3324.55",
      "Close": "3338.4"
  },
  {
      "Date": "10/16/08",
      "Open": "3333.85",
      "High": "3333.85",
      "Low": "3099.9",
      "Close": "3269.3"
  },
  {
      "Date": "10/17/08",
      "Open": "3269.05",
      "High": "3335.95",
      "Low": "3046.6",
      "Close": "3074.3"
  },
  {
      "Date": "10/20/08",
      "Open": "3108.2",
      "High": "3238.4",
      "Low": "3058.95",
      "Close": "3122.8"
  },
  {
      "Date": "10/21/08",
      "Open": "3125.4",
      "High": "3254.85",
      "Low": "3117.35",
      "Close": "3234.9"
  },
  {
      "Date": "10/22/08",
      "Open": "3234.7",
      "High": "3235.75",
      "Low": "3051.8",
      "Close": "3065.1"
  },
  {
      "Date": "10/23/08",
      "Open": "3064.8",
      "High": "3085.1",
      "Low": "2917.15",
      "Close": "2943.1"
  },
  {
      "Date": "10/24/08",
      "Open": "2936.25",
      "High": "2936.25",
      "Low": "2525.05",
      "Close": "2584"
  },
  {
      "Date": "10/27/08",
      "Open": "2583.75",
      "High": "2585.3",
      "Low": "2252.75",
      "Close": "2524.2"
  },
  {
      "Date": "10/28/08",
      "Open": "2526.2",
      "High": "2695.95",
      "Low": "2526.2",
      "Close": "2684.6"
  },
  {
      "Date": "10/29/08",
      "Open": "2685.3",
      "High": "2781.25",
      "Low": "2631.9",
      "Close": "2697"
  },
  {
      "Date": "10/31/08",
      "Open": "2696.3",
      "High": "2921.35",
      "Low": "2696.3",
      "Close": "2885.6"
  },
  {
      "Date": "11/3/08",
      "Open": "2885.4",
      "High": "3062.05",
      "Low": "2885.4",
      "Close": "3043.8"
  },
  {
      "Date": "11/4/08",
      "Open": "3050.25",
      "High": "3152.3",
      "Low": "2985",
      "Close": "3142.1"
  },
  {
      "Date": "11/5/08",
      "Open": "3155.75",
      "High": "3240.55",
      "Low": "2971",
      "Close": "2994.9"
  },
  {
      "Date": "11/6/08",
      "Open": "2998.45",
      "High": "3007.8",
      "Low": "2860.25",
      "Close": "2892.6"
  },
  {
      "Date": "11/7/08",
      "Open": "2893.25",
      "High": "3010",
      "Low": "2860.1",
      "Close": "2973"
  },
  {
      "Date": "11/10/08",
      "Open": "2973.3",
      "High": "3161.25",
      "Low": "2973.3",
      "Close": "3148.2"
  },
  {
      "Date": "11/11/08",
      "Open": "3147.2",
      "High": "3147.2",
      "Low": "2919.45",
      "Close": "2938.6"
  },
  {
      "Date": "11/12/08",
      "Open": "2937.9",
      "High": "2975.2",
      "Low": "2794.95",
      "Close": "2848.4"
  },
  {
      "Date": "11/14/08",
      "Open": "2848",
      "High": "2938.8",
      "Low": "2778.8",
      "Close": "2810.3"
  },
  {
      "Date": "11/17/08",
      "Open": "2813.4",
      "High": "2835.7",
      "Low": "2694.5",
      "Close": "2799.5"
  },
  {
      "Date": "11/18/08",
      "Open": "2802.45",
      "High": "2802.45",
      "Low": "2664.3",
      "Close": "2683.1"
  },
  {
      "Date": "11/19/08",
      "Open": "2682.75",
      "High": "2772.4",
      "Low": "2617.9",
      "Close": "2635"
  },
  {
      "Date": "11/20/08",
      "Open": "2634.2",
      "High": "2634.2",
      "Low": "2502.9",
      "Close": "2553.1"
  },
  {
      "Date": "11/21/08",
      "Open": "2553.6",
      "High": "2718.6",
      "Low": "2539.8",
      "Close": "2693.4"
  },
  {
      "Date": "11/24/08",
      "Open": "2690.85",
      "High": "2740.35",
      "Low": "2633.8",
      "Close": "2708.2"
  },
  {
      "Date": "11/25/08",
      "Open": "2708.3",
      "High": "2790.7",
      "Low": "2638.2",
      "Close": "2654"
  },
  {
      "Date": "11/26/08",
      "Open": "2652.45",
      "High": "2762.6",
      "Low": "2643.35",
      "Close": "2752.2"
  },
  {
      "Date": "11/28/08",
      "Open": "2745.7",
      "High": "2779",
      "Low": "2690.3",
      "Close": "2755.1"
  },
  {
      "Date": "12/1/08",
      "Open": "2755.15",
      "High": "2832.85",
      "Low": "2669.5",
      "Close": "2682.9"
  },
  {
      "Date": "12/2/08",
      "Open": "2672.9",
      "High": "2672.9",
      "Low": "2570.7",
      "Close": "2657.8"
  },
  {
      "Date": "12/3/08",
      "Open": "2657.5",
      "High": "2693.65",
      "Low": "2611.95",
      "Close": "2656.4"
  },
  {
      "Date": "12/4/08",
      "Open": "2656.5",
      "High": "2793.8",
      "Low": "2646.35",
      "Close": "2788"
  },
  {
      "Date": "12/5/08",
      "Open": "2786.65",
      "High": "2821.15",
      "Low": "2701.35",
      "Close": "2714.4"
  },
  {
      "Date": "12/8/08",
      "Open": "2714.7",
      "High": "2861.55",
      "Low": "2714.7",
      "Close": "2784"
  },
  {
      "Date": "12/10/08",
      "Open": "2785.7",
      "High": "2940.15",
      "Low": "2785.7",
      "Close": "2928.2"
  },
  {
      "Date": "12/11/08",
      "Open": "2934.05",
      "High": "2945.3",
      "Low": "2861.55",
      "Close": "2920.1"
  },
  {
      "Date": "12/12/08",
      "Open": "2915.35",
      "High": "2936.8",
      "Low": "2812.55",
      "Close": "2921.3"
  },
  {
      "Date": "12/15/08",
      "Open": "2917.9",
      "High": "3012.1",
      "Low": "2917.9",
      "Close": "2981.2"
  },
  {
      "Date": "12/16/08",
      "Open": "2983.6",
      "High": "3052.55",
      "Low": "2963.3",
      "Close": "3041.7"
  },
  {
      "Date": "12/17/08",
      "Open": "3040.45",
      "High": "3076.2",
      "Low": "2943.5",
      "Close": "2954.3"
  },
  {
      "Date": "12/18/08",
      "Open": "2955.35",
      "High": "3072.55",
      "Low": "2922.65",
      "Close": "3060.7"
  },
  {
      "Date": "12/19/08",
      "Open": "3063",
      "High": "3106.8",
      "Low": "3036.3",
      "Close": "3077.5"
  },
  {
      "Date": "12/22/08",
      "Open": "3077.25",
      "High": "3110.45",
      "Low": "3027.8",
      "Close": "3039.3"
  },
  {
      "Date": "12/23/08",
      "Open": "3039.25",
      "High": "3040",
      "Low": "2957.05",
      "Close": "2968.6"
  },
  {
      "Date": "12/24/08",
      "Open": "2967.4",
      "High": "2968",
      "Low": "2900.45",
      "Close": "2916.8"
  },
  {
      "Date": "12/26/08",
      "Open": "2919.85",
      "High": "2960.95",
      "Low": "2844.8",
      "Close": "2857.2"
  },
  {
      "Date": "12/29/08",
      "Open": "2857.15",
      "High": "2931.8",
      "Low": "2812.9",
      "Close": "2922.2"
  },
  {
      "Date": "12/30/08",
      "Open": "2922.55",
      "High": "2999.15",
      "Low": "2899.75",
      "Close": "2979.5"
  },
  {
      "Date": "12/31/08",
      "Open": "2979.8",
      "High": "3002.65",
      "Low": "2937.35",
      "Close": "2959.1"
  },
  {
      "Date": "1/1/09",
      "Open": "2963.3",
      "High": "3039.25",
      "Low": "2963.3",
      "Close": "3033.4"
  },
  {
      "Date": "1/2/09",
      "Open": "3034.6",
      "High": "3079.85",
      "Low": "3021.8",
      "Close": "3046.7"
  },
  {
      "Date": "1/5/09",
      "Open": "3058.75",
      "High": "3131.95",
      "Low": "3056.45",
      "Close": "3121.4"
  },
  {
      "Date": "1/6/09",
      "Open": "3121.5",
      "High": "3141.8",
      "Low": "3056.1",
      "Close": "3112.8"
  },
  {
      "Date": "1/7/09",
      "Open": "3112.8",
      "High": "3147.2",
      "Low": "2888.2",
      "Close": "2920.4"
  },
  {
      "Date": "1/9/09",
      "Open": "2919.95",
      "High": "2929.85",
      "Low": "2810.25",
      "Close": "2873"
  },
  {
      "Date": "1/12/09",
      "Open": "2868.85",
      "High": "2869.2",
      "Low": "2748.55",
      "Close": "2773.1"
  },
  {
      "Date": "1/13/09",
      "Open": "2775",
      "High": "2802.6",
      "Low": "2720.8",
      "Close": "2744.9"
  },
  {
      "Date": "1/14/09",
      "Open": "2748.4",
      "High": "2853.25",
      "Low": "2748.4",
      "Close": "2835.3"
  },
  {
      "Date": "1/15/09",
      "Open": "2832.3",
      "High": "2832.3",
      "Low": "2701.75",
      "Close": "2736.7"
  },
  {
      "Date": "1/16/09",
      "Open": "2737",
      "High": "2835.65",
      "Low": "2724.2",
      "Close": "2828.4"
  },
  {
      "Date": "1/19/09",
      "Open": "2828.7",
      "High": "2868.2",
      "Low": "2819.9",
      "Close": "2846.2"
  },
  {
      "Date": "1/20/09",
      "Open": "2842.9",
      "High": "2842.9",
      "Low": "2758",
      "Close": "2796.6"
  },
  {
      "Date": "1/21/09",
      "Open": "2777.4",
      "High": "2787.3",
      "Low": "2690.2",
      "Close": "2706.1"
  },
  {
      "Date": "1/22/09",
      "Open": "2714.7",
      "High": "2744.85",
      "Low": "2681.4",
      "Close": "2713.8"
  },
  {
      "Date": "1/23/09",
      "Open": "2705.45",
      "High": "2765.55",
      "Low": "2661.65",
      "Close": "2678.5"
  },
  {
      "Date": "1/27/09",
      "Open": "2686.05",
      "High": "2777.3",
      "Low": "2685.25",
      "Close": "2771.3"
  },
  {
      "Date": "1/28/09",
      "Open": "2771.1",
      "High": "2855.4",
      "Low": "2765.6",
      "Close": "2849.5"
  },
  {
      "Date": "1/29/09",
      "Open": "2849.35",
      "High": "2873.85",
      "Low": "2795.35",
      "Close": "2823.9"
  },
  {
      "Date": "1/30/09",
      "Open": "2824.05",
      "High": "2881",
      "Low": "2774.1",
      "Close": "2874.8"
  },
  {
      "Date": "2/2/09",
      "Open": "2872.35",
      "High": "2873.45",
      "Low": "2760.7",
      "Close": "2766.6"
  },
  {
      "Date": "2/3/09",
      "Open": "2773.5",
      "High": "2831.7",
      "Low": "2752.9",
      "Close": "2783.9"
  },
  {
      "Date": "2/4/09",
      "Open": "2780.7",
      "High": "2842.2",
      "Low": "2780.7",
      "Close": "2803"
  },
  {
      "Date": "2/5/09",
      "Open": "2802.75",
      "High": "2816.8",
      "Low": "2754.85",
      "Close": "2780"
  },
  {
      "Date": "2/6/09",
      "Open": "2779.35",
      "High": "2852.5",
      "Low": "2778.65",
      "Close": "2843.1"
  },
  {
      "Date": "2/9/09",
      "Open": "2843.05",
      "High": "2926.75",
      "Low": "2840.15",
      "Close": "2919.9"
  },
  {
      "Date": "2/10/09",
      "Open": "2919.7",
      "High": "2957.4",
      "Low": "2891.75",
      "Close": "2934.5"
  },
  {
      "Date": "2/11/09",
      "Open": "2933",
      "High": "2937.5",
      "Low": "2877.6",
      "Close": "2925.7"
  },
  {
      "Date": "2/12/09",
      "Open": "2927.4",
      "High": "2939",
      "Low": "2886.55",
      "Close": "2893"
  },
  {
      "Date": "2/13/09",
      "Open": "2896.95",
      "High": "2969.75",
      "Low": "2896.85",
      "Close": "2948.3"
  },
  {
      "Date": "2/16/09",
      "Open": "2948.25",
      "High": "2953.2",
      "Low": "2839.1",
      "Close": "2848.5"
  },
  {
      "Date": "2/17/09",
      "Open": "2853.85",
      "High": "2854.65",
      "Low": "2757.3",
      "Close": "2770.5"
  },
  {
      "Date": "2/18/09",
      "Open": "2755.15",
      "High": "2806.5",
      "Low": "2736.65",
      "Close": "2776.1"
  },
  {
      "Date": "2/19/09",
      "Open": "2776.7",
      "High": "2802.15",
      "Low": "2767.6",
      "Close": "2789.3"
  },
  {
      "Date": "2/20/09",
      "Open": "2789.3",
      "High": "2789.3",
      "Low": "2709.3",
      "Close": "2736.4"
  },
  {
      "Date": "2/24/09",
      "Open": "2737.25",
      "High": "2746.2",
      "Low": "2677.55",
      "Close": "2733.9"
  },
  {
      "Date": "2/25/09",
      "Open": "2733.45",
      "High": "2789.35",
      "Low": "2733.45",
      "Close": "2762.5"
  },
  {
      "Date": "2/26/09",
      "Open": "2762.2",
      "High": "2797.8",
      "Low": "2731.9",
      "Close": "2785.6"
  },
  {
      "Date": "2/27/09",
      "Open": "2785.7",
      "High": "2787.2",
      "Low": "2708.45",
      "Close": "2763.6"
  },
  {
      "Date": "3/2/09",
      "Open": "2764.6",
      "High": "2764.6",
      "Low": "2659.55",
      "Close": "2674.6"
  },
  {
      "Date": "3/3/09",
      "Open": "2672.15",
      "High": "2688.5",
      "Low": "2611.55",
      "Close": "2622.4"
  },
  {
      "Date": "3/4/09",
      "Open": "2611.95",
      "High": "2655.7",
      "Low": "2611.95",
      "Close": "2645.2"
  },
  {
      "Date": "3/5/09",
      "Open": "2645.9",
      "High": "2663.9",
      "Low": "2564.1",
      "Close": "2576.7"
  },
  {
      "Date": "3/6/09",
      "Open": "2576.75",
      "High": "2628.1",
      "Low": "2539.45",
      "Close": "2620.1"
  },
  {
      "Date": "3/9/09",
      "Open": "2620.1",
      "High": "2621.25",
      "Low": "2555.6",
      "Close": "2573.1"
  },
  {
      "Date": "3/12/09",
      "Open": "2574.5",
      "High": "2646.1",
      "Low": "2574.5",
      "Close": "2617.4"
  },
  {
      "Date": "3/13/09",
      "Open": "2616.6",
      "High": "2726.15",
      "Low": "2616.6",
      "Close": "2719.2"
  },
  {
      "Date": "3/16/09",
      "Open": "2716.05",
      "High": "2781.95",
      "Low": "2701.95",
      "Close": "2777.2"
  },
  {
      "Date": "3/17/09",
      "Open": "2776.35",
      "High": "2805.6",
      "Low": "2738.7",
      "Close": "2757.4"
  },
  {
      "Date": "3/18/09",
      "Open": "2757.65",
      "High": "2836.05",
      "Low": "2752.25",
      "Close": "2794.7"
  },
  {
      "Date": "3/19/09",
      "Open": "2797.05",
      "High": "2822.25",
      "Low": "2771.35",
      "Close": "2807.1"
  },
  {
      "Date": "3/20/09",
      "Open": "2807.35",
      "High": "2816.1",
      "Low": "2773.65",
      "Close": "2807"
  },
  {
      "Date": "3/23/09",
      "Open": "2807.25",
      "High": "2949.75",
      "Low": "2807.25",
      "Close": "2939.9"
  },
  {
      "Date": "3/24/09",
      "Open": "2923.8",
      "High": "3017.4",
      "Low": "2914.5",
      "Close": "2938.7"
  },
  {
      "Date": "3/25/09",
      "Open": "2938.8",
      "High": "2996.5",
      "Low": "2923.3",
      "Close": "2984.3"
  },
  {
      "Date": "3/26/09",
      "Open": "2982.25",
      "High": "3103.35",
      "Low": "2982.25",
      "Close": "3082.2"
  },
  {
      "Date": "3/27/09",
      "Open": "3079.4",
      "High": "3123.35",
      "Low": "3055.9",
      "Close": "3108.6"
  },
  {
      "Date": "3/30/09",
      "Open": "3108.75",
      "High": "3110.2",
      "Low": "2962.4",
      "Close": "2978.1"
  },
  {
      "Date": "3/31/09",
      "Open": "2981.7",
      "High": "3054.3",
      "Low": "2966.4",
      "Close": "3020.9"
  },
  {
      "Date": "4/1/09",
      "Open": "3023.85",
      "High": "3069.3",
      "Low": "2965.7",
      "Close": "3060.3"
  },
  {
      "Date": "4/2/09",
      "Open": "3061.05",
      "High": "3228.75",
      "Low": "3061.05",
      "Close": "3211"
  },
  {
      "Date": "4/6/09",
      "Open": "3211.35",
      "High": "3303.9",
      "Low": "3211.35",
      "Close": "3256.6"
  },
  {
      "Date": "4/8/09",
      "Open": "3255.35",
      "High": "3357.05",
      "Low": "3149.25",
      "Close": "3342.9"
  },
  {
      "Date": "4/9/09",
      "Open": "3346",
      "High": "3401.15",
      "Low": "3307.05",
      "Close": "3342"
  },
  {
      "Date": "4/13/09",
      "Open": "3342.2",
      "High": "3417.8",
      "Low": "3334.15",
      "Close": "3382.6"
  },
  {
      "Date": "4/15/09",
      "Open": "3381.45",
      "High": "3497.55",
      "Low": "3311.8",
      "Close": "3484.1"
  },
  {
      "Date": "4/16/09",
      "Open": "3484.35",
      "High": "3511.25",
      "Low": "3354.2",
      "Close": "3369.5"
  },
  {
      "Date": "4/17/09",
      "Open": "3369.5",
      "High": "3489.85",
      "Low": "3359.25",
      "Close": "3384.4"
  },
  {
      "Date": "4/20/09",
      "Open": "3384.75",
      "High": "3441.1",
      "Low": "3339.45",
      "Close": "3377.1"
  },
  {
      "Date": "4/21/09",
      "Open": "3376.85",
      "High": "3414.7",
      "Low": "3309.35",
      "Close": "3365.3"
  },
  {
      "Date": "4/22/09",
      "Open": "3364.6",
      "High": "3401.1",
      "Low": "3296.9",
      "Close": "3330.3"
  },
  {
      "Date": "4/23/09",
      "Open": "3330.5",
      "High": "3439.9",
      "Low": "3310.5",
      "Close": "3423.7"
  },
  {
      "Date": "4/24/09",
      "Open": "3423.6",
      "High": "3491.35",
      "Low": "3402.9",
      "Close": "3480.7"
  },
  {
      "Date": "4/27/09",
      "Open": "3481.3",
      "High": "3517.25",
      "Low": "3435.3",
      "Close": "3470"
  },
  {
      "Date": "4/28/09",
      "Open": "3469.5",
      "High": "3471.95",
      "Low": "3351.5",
      "Close": "3362.3"
  },
  {
      "Date": "4/29/09",
      "Open": "3371.65",
      "High": "3486.4",
      "Low": "3366.7",
      "Close": "3473.9"
  },
  {
      "Date": "5/4/09",
      "Open": "3478.7",
      "High": "3664.5",
      "Low": "3478.7",
      "Close": "3654"
  },
  {
      "Date": "5/5/09",
      "Open": "3664.5",
      "High": "3682.2",
      "Low": "3618.75",
      "Close": "3661.9"
  },
  {
      "Date": "5/6/09",
      "Open": "3662",
      "High": "3717.05",
      "Low": "3608.65",
      "Close": "3625"
  },
  {
      "Date": "5/7/09",
      "Open": "3617.15",
      "High": "3692.05",
      "Low": "3617.15",
      "Close": "3683.9"
  },
  {
      "Date": "5/8/09",
      "Open": "3681.8",
      "High": "3711.25",
      "Low": "3582.85",
      "Close": "3620.7"
  },
  {
      "Date": "5/11/09",
      "Open": "3615.75",
      "High": "3660.2",
      "Low": "3534.55",
      "Close": "3554.6"
  },
  {
      "Date": "5/12/09",
      "Open": "3554.65",
      "High": "3691.65",
      "Low": "3534.2",
      "Close": "3681.1"
  },
  {
      "Date": "5/13/09",
      "Open": "3668.75",
      "High": "3709.6",
      "Low": "3610.2",
      "Close": "3635.2"
  },
  {
      "Date": "5/14/09",
      "Open": "3631.9",
      "High": "3631.9",
      "Low": "3537.6",
      "Close": "3593.4"
  },
  {
      "Date": "5/15/09",
      "Open": "3597.85",
      "High": "3686.25",
      "Low": "3597.85",
      "Close": "3671.6"
  },
  {
      "Date": "5/18/09",
      "Open": "3673.15",
      "High": "4384.3",
      "Low": "3673.15",
      "Close": "4323.1"
  },
  {
      "Date": "5/19/09",
      "Open": "4324.95",
      "High": "4509.4",
      "Low": "4167.65",
      "Close": "4318.4"
  },
  {
      "Date": "5/20/09",
      "Open": "4318.75",
      "High": "4362.85",
      "Low": "4244.7",
      "Close": "4270.3"
  },
  {
      "Date": "5/21/09",
      "Open": "4270.35",
      "High": "4319",
      "Low": "4199.2",
      "Close": "4210.9"
  },
  {
      "Date": "5/22/09",
      "Open": "4211.85",
      "High": "4249.5",
      "Low": "4155.85",
      "Close": "4238.5"
  },
  {
      "Date": "5/25/09",
      "Open": "4238.1",
      "High": "4270.05",
      "Low": "4205.1",
      "Close": "4237.5"
  },
  {
      "Date": "5/26/09",
      "Open": "4239.55",
      "High": "4256.05",
      "Low": "4092.25",
      "Close": "4116.7"
  },
  {
      "Date": "5/27/09",
      "Open": "4117.3",
      "High": "4286.45",
      "Low": "4115.25",
      "Close": "4276"
  },
  {
      "Date": "5/28/09",
      "Open": "4276.15",
      "High": "4354.85",
      "Low": "4254.85",
      "Close": "4337.1"
  },
  {
      "Date": "5/29/09",
      "Open": "4340.75",
      "High": "4488.05",
      "Low": "4340.75",
      "Close": "4448.9"
  },
  {
      "Date": "6/1/09",
      "Open": "4450.4",
      "High": "4545.4",
      "Low": "4450.4",
      "Close": "4529.9"
  },
  {
      "Date": "6/2/09",
      "Open": "4530.45",
      "High": "4586.4",
      "Low": "4451.3",
      "Close": "4525.2"
  },
  {
      "Date": "6/3/09",
      "Open": "4525.5",
      "High": "4574.9",
      "Low": "4478.6",
      "Close": "4530.7"
  },
  {
      "Date": "6/4/09",
      "Open": "4530.3",
      "High": "4582.2",
      "Low": "4453.45",
      "Close": "4572.6"
  },
  {
      "Date": "6/5/09",
      "Open": "4573.3",
      "High": "4636.85",
      "Low": "4561.95",
      "Close": "4586.9"
  },
  {
      "Date": "6/8/09",
      "Open": "4582.35",
      "High": "4611.4",
      "Low": "4404.65",
      "Close": "4429.9"
  },
  {
      "Date": "6/9/09",
      "Open": "4427.75",
      "High": "4562.45",
      "Low": "4365.1",
      "Close": "4550.9"
  },
  {
      "Date": "6/10/09",
      "Open": "4551.7",
      "High": "4688.95",
      "Low": "4551.7",
      "Close": "4655.2"
  },
  {
      "Date": "6/11/09",
      "Open": "4657.4",
      "High": "4679.55",
      "Low": "4586.15",
      "Close": "4637.7"
  },
  {
      "Date": "6/12/09",
      "Open": "4637.55",
      "High": "4693.2",
      "Low": "4566.15",
      "Close": "4583.4"
  },
  {
      "Date": "6/15/09",
      "Open": "4584.65",
      "High": "4601.05",
      "Low": "4469.6",
      "Close": "4484"
  },
  {
      "Date": "6/16/09",
      "Open": "4478.1",
      "High": "4537.95",
      "Low": "4405.95",
      "Close": "4517.8"
  },
  {
      "Date": "6/17/09",
      "Open": "4515.35",
      "High": "4517.8",
      "Low": "4332.8",
      "Close": "4356.1"
  },
  {
      "Date": "6/18/09",
      "Open": "4352.95",
      "High": "4375.3",
      "Low": "4222.15",
      "Close": "4251.4"
  },
  {
      "Date": "6/19/09",
      "Open": "4251.1",
      "High": "4326.2",
      "Low": "4206.7",
      "Close": "4313.6"
  },
  {
      "Date": "6/22/09",
      "Open": "4314.2",
      "High": "4352.25",
      "Low": "4221.9",
      "Close": "4235.2"
  },
  {
      "Date": "6/23/09",
      "Open": "4223.3",
      "High": "4267.45",
      "Low": "4143.25",
      "Close": "4247"
  },
  {
      "Date": "6/24/09",
      "Open": "4247.3",
      "High": "4307",
      "Low": "4218.25",
      "Close": "4292.9"
  },
  {
      "Date": "6/25/09",
      "Open": "4293.85",
      "High": "4337.95",
      "Low": "4221.15",
      "Close": "4241.8"
  },
  {
      "Date": "6/26/09",
      "Open": "4243.95",
      "High": "4383.75",
      "Low": "4243.95",
      "Close": "4375.5"
  },
  {
      "Date": "6/29/09",
      "Open": "4375.4",
      "High": "4439.95",
      "Low": "4350.9",
      "Close": "4390.9"
  },
  {
      "Date": "6/30/09",
      "Open": "4391.5",
      "High": "4426.75",
      "Low": "4267.35",
      "Close": "4291.1"
  },
  {
      "Date": "7/1/09",
      "Open": "4292.3",
      "High": "4362.3",
      "Low": "4249.7",
      "Close": "4340.9"
  },
  {
      "Date": "7/2/09",
      "Open": "4373.5",
      "High": "4383.65",
      "Low": "4288.75",
      "Close": "4348.8"
  },
  {
      "Date": "7/3/09",
      "Open": "4347.3",
      "High": "4434.45",
      "Low": "4298.95",
      "Close": "4424.2"
  },
  {
      "Date": "7/6/09",
      "Open": "4429.6",
      "High": "4479.8",
      "Low": "4133.7",
      "Close": "4165.7"
  },
  {
      "Date": "7/7/09",
      "Open": "4166",
      "High": "4231.8",
      "Low": "4155.5",
      "Close": "4202.1"
  },
  {
      "Date": "7/8/09",
      "Open": "4201.85",
      "High": "4201.85",
      "Low": "4061.1",
      "Close": "4078.9"
  },
  {
      "Date": "7/9/09",
      "Open": "4078.75",
      "High": "4114.9",
      "Low": "4039.85",
      "Close": "4080.9"
  },
  {
      "Date": "7/10/09",
      "Open": "4081.4",
      "High": "4129.95",
      "Low": "3976.8",
      "Close": "4003.9"
  },
  {
      "Date": "7/13/09",
      "Open": "4003.4",
      "High": "4003.4",
      "Low": "3918.75",
      "Close": "3974"
  },
  {
      "Date": "7/14/09",
      "Open": "3974.1",
      "High": "4128.9",
      "Low": "3974.1",
      "Close": "4111.4"
  },
  {
      "Date": "7/15/09",
      "Open": "4120.8",
      "High": "4249.55",
      "Low": "4118.75",
      "Close": "4233.5"
  },
  {
      "Date": "7/16/09",
      "Open": "4223.5",
      "High": "4305",
      "Low": "4205.5",
      "Close": "4231.4"
  },
  {
      "Date": "7/17/09",
      "Open": "4231.45",
      "High": "4390.4",
      "Low": "4230.15",
      "Close": "4374.9"
  },
  {
      "Date": "7/20/09",
      "Open": "4377.9",
      "High": "4510.3",
      "Low": "4377.9",
      "Close": "4502.2"
  },
  {
      "Date": "7/21/09",
      "Open": "4501.5",
      "High": "4524",
      "Low": "4436.6",
      "Close": "4469.1"
  },
  {
      "Date": "7/22/09",
      "Open": "4469.3",
      "High": "4557.95",
      "Low": "4380.45",
      "Close": "4398.9"
  },
  {
      "Date": "7/23/09",
      "Open": "4409.7",
      "High": "4532.4",
      "Low": "4405.95",
      "Close": "4523.7"
  },
  {
      "Date": "7/24/09",
      "Open": "4524.8",
      "High": "4578.75",
      "Low": "4504.85",
      "Close": "4568.5"
  },
  {
      "Date": "7/27/09",
      "Open": "4568.65",
      "High": "4596.75",
      "Low": "4528.5",
      "Close": "4572.3"
  },
  {
      "Date": "7/28/09",
      "Open": "4572.8",
      "High": "4599.9",
      "Low": "4529.15",
      "Close": "4564.1"
  },
  {
      "Date": "7/29/09",
      "Open": "4565.8",
      "High": "4573.85",
      "Low": "4420.8",
      "Close": "4513.5"
  },
  {
      "Date": "7/30/09",
      "Open": "4513.1",
      "High": "4582.35",
      "Low": "4474.5",
      "Close": "4571.4"
  },
  {
      "Date": "7/31/09",
      "Open": "4571.6",
      "High": "4669.75",
      "Low": "4571.6",
      "Close": "4636.4"
  },
  {
      "Date": "8/3/09",
      "Open": "4633.8",
      "High": "4723.75",
      "Low": "4617.75",
      "Close": "4711.4"
  },
  {
      "Date": "8/4/09",
      "Open": "4706.25",
      "High": "4731.45",
      "Low": "4642.6",
      "Close": "4680.5"
  },
  {
      "Date": "8/5/09",
      "Open": "4680.95",
      "High": "4717.2",
      "Low": "4629.85",
      "Close": "4694.1"
  },
  {
      "Date": "8/6/09",
      "Open": "4694.35",
      "High": "4718.15",
      "Low": "4559.2",
      "Close": "4585.5"
  },
  {
      "Date": "8/7/09",
      "Open": "4591.9",
      "High": "4591.9",
      "Low": "4463.95",
      "Close": "4481.4"
  },
  {
      "Date": "8/10/09",
      "Open": "4486.5",
      "High": "4562.5",
      "Low": "4399.85",
      "Close": "4437.6"
  },
  {
      "Date": "8/11/09",
      "Open": "4435",
      "High": "4510.8",
      "Low": "4398.9",
      "Close": "4471.3"
  },
  {
      "Date": "8/12/09",
      "Open": "4473.8",
      "High": "4473.8",
      "Low": "4359.4",
      "Close": "4457.5"
  },
  {
      "Date": "8/13/09",
      "Open": "4458.55",
      "High": "4614.15",
      "Low": "4458.55",
      "Close": "4605"
  },
  {
      "Date": "8/14/09",
      "Open": "4605.15",
      "High": "4619",
      "Low": "4559.35",
      "Close": "4580"
  },
  {
      "Date": "8/17/09",
      "Open": "4578.8",
      "High": "4578.8",
      "Low": "4374.6",
      "Close": "4387.9"
  },
  {
      "Date": "8/18/09",
      "Open": "4389.5",
      "High": "4491.45",
      "Low": "4372.65",
      "Close": "4458.9"
  },
  {
      "Date": "8/19/09",
      "Open": "4457.75",
      "High": "4477.55",
      "Low": "4353.45",
      "Close": "4394.1"
  },
  {
      "Date": "8/20/09",
      "Open": "4394.35",
      "High": "4492.8",
      "Low": "4394.35",
      "Close": "4453.4"
  },
  {
      "Date": "8/21/09",
      "Open": "4453.45",
      "High": "4538.7",
      "Low": "4400.9",
      "Close": "4528.8"
  },
  {
      "Date": "8/24/09",
      "Open": "4536.95",
      "High": "4656.3",
      "Low": "4536.95",
      "Close": "4642.8"
  },
  {
      "Date": "8/25/09",
      "Open": "4641.65",
      "High": "4672.9",
      "Low": "4582.5",
      "Close": "4659.3"
  },
  {
      "Date": "8/26/09",
      "Open": "4659.45",
      "High": "4697.8",
      "Low": "4659.1",
      "Close": "4680.8"
  },
  {
      "Date": "8/27/09",
      "Open": "4679",
      "High": "4707.9",
      "Low": "4645.15",
      "Close": "4688.2"
  },
  {
      "Date": "8/28/09",
      "Open": "4688.4",
      "High": "4743.75",
      "Low": "4651.4",
      "Close": "4732.3"
  },
  {
      "Date": "8/31/09",
      "Open": "4730.85",
      "High": "4730.85",
      "Low": "4635",
      "Close": "4662.1"
  },
  {
      "Date": "9/1/09",
      "Open": "4662.2",
      "High": "4735.9",
      "Low": "4600.65",
      "Close": "4625.3"
  },
  {
      "Date": "9/2/09",
      "Open": "4624",
      "High": "4650.45",
      "Low": "4576.6",
      "Close": "4608.3"
  },
  {
      "Date": "9/3/09",
      "Open": "4608.75",
      "High": "4647.35",
      "Low": "4581.05",
      "Close": "4593.5"
  },
  {
      "Date": "9/4/09",
      "Open": "4608.7",
      "High": "4697.2",
      "Low": "4580.35",
      "Close": "4680.4"
  },
  {
      "Date": "9/7/09",
      "Open": "4682.4",
      "High": "4790",
      "Low": "4679.3",
      "Close": "4782.9"
  },
  {
      "Date": "9/8/09",
      "Open": "4782.85",
      "High": "4842.2",
      "Low": "4782.65",
      "Close": "4805.2"
  },
  {
      "Date": "9/9/09",
      "Open": "4804.9",
      "High": "4825.05",
      "Low": "4783.5",
      "Close": "4814.2"
  },
  {
      "Date": "9/10/09",
      "Open": "4814.35",
      "High": "4889.05",
      "Low": "4807.9",
      "Close": "4819.4"
  },
  {
      "Date": "9/11/09",
      "Open": "4819.4",
      "High": "4856.15",
      "Low": "4791.55",
      "Close": "4829.5"
  },
  {
      "Date": "9/14/09",
      "Open": "4830.35",
      "High": "4832.25",
      "Low": "4786.25",
      "Close": "4808.6"
  },
  {
      "Date": "9/15/09",
      "Open": "4808.35",
      "High": "4899.45",
      "Low": "4808.35",
      "Close": "4892.1"
  },
  {
      "Date": "9/16/09",
      "Open": "4894.65",
      "High": "4966.3",
      "Low": "4894.65",
      "Close": "4958.4"
  },
  {
      "Date": "9/17/09",
      "Open": "4958.55",
      "High": "5003.05",
      "Low": "4944.15",
      "Close": "4965.5"
  },
  {
      "Date": "9/18/09",
      "Open": "4963.95",
      "High": "4980.85",
      "Low": "4931.9",
      "Close": "4976"
  },
  {
      "Date": "9/22/09",
      "Open": "4977.1",
      "High": "5036.3",
      "Low": "4977.1",
      "Close": "5020.2"
  },
  {
      "Date": "9/23/09",
      "Open": "5019.95",
      "High": "5030.75",
      "Low": "4957.05",
      "Close": "4969.9"
  },
  {
      "Date": "9/24/09",
      "Open": "4977.15",
      "High": "5016.7",
      "Low": "4904.05",
      "Close": "4986.5"
  },
  {
      "Date": "9/25/09",
      "Open": "4985.1",
      "High": "4994.35",
      "Low": "4931.25",
      "Close": "4958.9"
  },
  {
      "Date": "9/29/09",
      "Open": "4959.15",
      "High": "5020.25",
      "Low": "4959.1",
      "Close": "5006.8"
  },
  {
      "Date": "9/30/09",
      "Open": "5007.65",
      "High": "5087.6",
      "Low": "5004.35",
      "Close": "5083.9"
  },
  {
      "Date": "10/1/09",
      "Open": "5087.2",
      "High": "5110.5",
      "Low": "5057.05",
      "Close": "5083.4"
  },
  {
      "Date": "10/5/09",
      "Open": "5076.05",
      "High": "5076.05",
      "Low": "4991.95",
      "Close": "5003.2"
  },
  {
      "Date": "10/6/09",
      "Open": "5003.65",
      "High": "5034.7",
      "Low": "4921.05",
      "Close": "5027.4"
  },
  {
      "Date": "10/7/09",
      "Open": "5031.7",
      "High": "5077",
      "Low": "4972.95",
      "Close": "4985.7"
  },
  {
      "Date": "10/8/09",
      "Open": "5011.25",
      "High": "5043.05",
      "Low": "4971.75",
      "Close": "5002.2"
  },
  {
      "Date": "10/9/09",
      "Open": "4993",
      "High": "5032.6",
      "Low": "4934.55",
      "Close": "4945.2"
  },
  {
      "Date": "10/12/09",
      "Open": "4945.45",
      "High": "5068.05",
      "Low": "4945.45",
      "Close": "5054.2"
  },
  {
      "Date": "10/14/09",
      "Open": "5054.35",
      "High": "5127.4",
      "Low": "5054.35",
      "Close": "5118.2"
  },
  {
      "Date": "10/15/09",
      "Open": "5118.55",
      "High": "5152.25",
      "Low": "5077.1",
      "Close": "5108.8"
  },
  {
      "Date": "10/16/09",
      "Open": "5108.65",
      "High": "5149.65",
      "Low": "5093.2",
      "Close": "5142.1"
  },
  {
      "Date": "10/17/09",
      "Open": "5159.35",
      "High": "5176.8",
      "Low": "5124.25",
      "Close": "5141.8"
  },
  {
      "Date": "10/20/09",
      "Open": "5145.6",
      "High": "5181.95",
      "Low": "5102.65",
      "Close": "5114.4"
  },
  {
      "Date": "10/21/09",
      "Open": "5114.85",
      "High": "5117.45",
      "Low": "5051.65",
      "Close": "5063.6"
  },
  {
      "Date": "10/22/09",
      "Open": "5063.35",
      "High": "5064.25",
      "Low": "4968.45",
      "Close": "4988.6"
  },
  {
      "Date": "10/23/09",
      "Open": "4986.55",
      "High": "5054.95",
      "Low": "4983.25",
      "Close": "4997"
  },
  {
      "Date": "10/26/09",
      "Open": "4997.15",
      "High": "5033.75",
      "Low": "4961.35",
      "Close": "4970.9"
  },
  {
      "Date": "10/27/09",
      "Open": "4970.55",
      "High": "4970.55",
      "Low": "4829.5",
      "Close": "4846.7"
  },
  {
      "Date": "10/28/09",
      "Open": "4846.55",
      "High": "4867",
      "Low": "4784.1",
      "Close": "4826.1"
  },
  {
      "Date": "10/29/09",
      "Open": "4826.1",
      "High": "4826.1",
      "Low": "4738.4",
      "Close": "4750.5"
  },
  {
      "Date": "10/30/09",
      "Open": "4751.1",
      "High": "4853.65",
      "Low": "4687.5",
      "Close": "4711.7"
  },
  {
      "Date": "11/3/09",
      "Open": "4712.25",
      "High": "4729.85",
      "Low": "4538.5",
      "Close": "4563.9"
  },
  {
      "Date": "11/4/09",
      "Open": "4567.3",
      "High": "4717.8",
      "Low": "4565",
      "Close": "4710.8"
  },
  {
      "Date": "11/5/09",
      "Open": "4711.65",
      "High": "4776.35",
      "Low": "4610.6",
      "Close": "4765.5"
  },
  {
      "Date": "11/6/09",
      "Open": "4767.5",
      "High": "4836.2",
      "Low": "4764.85",
      "Close": "4796.1"
  },
  {
      "Date": "11/9/09",
      "Open": "4796.15",
      "High": "4905.25",
      "Low": "4789.9",
      "Close": "4898.4"
  },
  {
      "Date": "11/10/09",
      "Open": "4898.9",
      "High": "4947.7",
      "Low": "4860.1",
      "Close": "4881.7"
  },
  {
      "Date": "11/11/09",
      "Open": "4882.3",
      "High": "5016.7",
      "Low": "4870.05",
      "Close": "5003.9"
  },
  {
      "Date": "11/12/09",
      "Open": "5004.4",
      "High": "5014.4",
      "Low": "4924.75",
      "Close": "4952.6"
  },
  {
      "Date": "11/13/09",
      "Open": "4952.35",
      "High": "5017.9",
      "Low": "4942.65",
      "Close": "4998.9"
  },
  {
      "Date": "11/16/09",
      "Open": "4996.5",
      "High": "5073.2",
      "Low": "4994",
      "Close": "5058"
  },
  {
      "Date": "11/17/09",
      "Open": "5058.95",
      "High": "5074",
      "Low": "5010.15",
      "Close": "5062.2"
  },
  {
      "Date": "11/18/09",
      "Open": "5061.5",
      "High": "5079.3",
      "Low": "5041.65",
      "Close": "5054.7"
  },
  {
      "Date": "11/19/09",
      "Open": "5043.95",
      "High": "5053.45",
      "Low": "4963.7",
      "Close": "4989"
  },
  {
      "Date": "11/20/09",
      "Open": "4988.75",
      "High": "5063.3",
      "Low": "4932.8",
      "Close": "5052.4"
  },
  {
      "Date": "11/23/09",
      "Open": "5052.95",
      "High": "5113.1",
      "Low": "5052.1",
      "Close": "5103.5"
  },
  {
      "Date": "11/24/09",
      "Open": "5105",
      "High": "5112.85",
      "Low": "5053.5",
      "Close": "5090.5"
  },
  {
      "Date": "11/25/09",
      "Open": "5091.55",
      "High": "5138",
      "Low": "5078.35",
      "Close": "5108.1"
  },
  {
      "Date": "11/26/09",
      "Open": "5116.45",
      "High": "5116.45",
      "Low": "4986.05",
      "Close": "5005.5"
  },
  {
      "Date": "11/27/09",
      "Open": "5005.05",
      "High": "5005.05",
      "Low": "4806.7",
      "Close": "4941.7"
  },
  {
      "Date": "11/30/09",
      "Open": "4942.25",
      "High": "5066.35",
      "Low": "4942.25",
      "Close": "5032.7"
  },
  {
      "Date": "12/1/09",
      "Open": "5039.7",
      "High": "5130.35",
      "Low": "5038.85",
      "Close": "5122"
  },
  {
      "Date": "12/2/09",
      "Open": "5122.75",
      "High": "5161.75",
      "Low": "5111.75",
      "Close": "5123.2"
  },
  {
      "Date": "12/3/09",
      "Open": "5124.55",
      "High": "5181",
      "Low": "5106.6",
      "Close": "5131.7"
  },
  {
      "Date": "12/4/09",
      "Open": "5131.7",
      "High": "5161.8",
      "Low": "5081.85",
      "Close": "5108.9"
  },
  {
      "Date": "12/7/09",
      "Open": "5108.85",
      "High": "5131.3",
      "Low": "5051.55",
      "Close": "5066.7"
  },
  {
      "Date": "12/8/09",
      "Open": "5068.55",
      "High": "5152.55",
      "Low": "5058.9",
      "Close": "5147.9"
  },
  {
      "Date": "12/9/09",
      "Open": "5147.65",
      "High": "5147.65",
      "Low": "5090.6",
      "Close": "5112"
  },
  {
      "Date": "12/10/09",
      "Open": "5112.4",
      "High": "5146.45",
      "Low": "5084.65",
      "Close": "5134.6"
  },
  {
      "Date": "12/11/09",
      "Open": "5136.05",
      "High": "5182.55",
      "Low": "5088.4",
      "Close": "5117.3"
  },
  {
      "Date": "12/14/09",
      "Open": "5117.45",
      "High": "5156.7",
      "Low": "5090.15",
      "Close": "5105.7"
  },
  {
      "Date": "12/15/09",
      "Open": "5105.75",
      "High": "5129.45",
      "Low": "5018.25",
      "Close": "5033"
  },
  {
      "Date": "12/16/09",
      "Open": "5032.95",
      "High": "5067.25",
      "Low": "5001.8",
      "Close": "5042"
  },
  {
      "Date": "12/17/09",
      "Open": "5046.65",
      "High": "5064.2",
      "Low": "5013.15",
      "Close": "5041.7"
  },
  {
      "Date": "12/18/09",
      "Open": "5042",
      "High": "5043.4",
      "Low": "4979.05",
      "Close": "4987.7"
  },
  {
      "Date": "12/21/09",
      "Open": "4983.65",
      "High": "4997.85",
      "Low": "4943.95",
      "Close": "4952.6"
  },
  {
      "Date": "12/22/09",
      "Open": "4953.35",
      "High": "4997.3",
      "Low": "4953.35",
      "Close": "4985.8"
  },
  {
      "Date": "12/23/09",
      "Open": "4990.05",
      "High": "5150.6",
      "Low": "4990.05",
      "Close": "5144.6"
  },
  {
      "Date": "12/24/09",
      "Open": "5144.8",
      "High": "5197.9",
      "Low": "5129.05",
      "Close": "5178.4"
  },
  {
      "Date": "12/29/09",
      "Open": "5180.75",
      "High": "5214.6",
      "Low": "5175.85",
      "Close": "5187.9"
  },
  {
      "Date": "12/30/09",
      "Open": "5188.75",
      "High": "5197.05",
      "Low": "5160.1",
      "Close": "5169.4"
  },
  {
      "Date": "12/31/09",
      "Open": "5171.2",
      "High": "5221.85",
      "Low": "5168.75",
      "Close": "5201"
  },
  {
      "Date": "1/4/10",
      "Open": "5200.9",
      "High": "5238.45",
      "Low": "5167.1",
      "Close": "5232.2"
  },
  {
      "Date": "1/5/10",
      "Open": "5277.15",
      "High": "5288.35",
      "Low": "5242.4",
      "Close": "5277.9"
  },
  {
      "Date": "1/6/10",
      "Open": "5278.15",
      "High": "5310.85",
      "Low": "5260.05",
      "Close": "5281.8"
  },
  {
      "Date": "1/7/10",
      "Open": "5281.8",
      "High": "5302.55",
      "Low": "5244.75",
      "Close": "5263.1"
  },
  {
      "Date": "1/8/10",
      "Open": "5264.25",
      "High": "5276.75",
      "Low": "5234.7",
      "Close": "5244.7"
  },
  {
      "Date": "1/11/10",
      "Open": "5263.8",
      "High": "5287.2",
      "Low": "5227.8",
      "Close": "5249.4"
  },
  {
      "Date": "1/12/10",
      "Open": "5251.1",
      "High": "5300.5",
      "Low": "5200.95",
      "Close": "5210.4"
  },
  {
      "Date": "1/13/10",
      "Open": "5212.6",
      "High": "5239.2",
      "Low": "5169.55",
      "Close": "5233.9"
  },
  {
      "Date": "1/14/10",
      "Open": "5234.5",
      "High": "5272.85",
      "Low": "5232.5",
      "Close": "5259.9"
  },
  {
      "Date": "1/15/10",
      "Open": "5259.9",
      "High": "5279.85",
      "Low": "5242.45",
      "Close": "5252.2"
  },
  {
      "Date": "1/18/10",
      "Open": "5253.65",
      "High": "5292.5",
      "Low": "5228.95",
      "Close": "5274.8"
  },
  {
      "Date": "1/19/10",
      "Open": "5274.2",
      "High": "5287.8",
      "Low": "5218.65",
      "Close": "5225.6"
  },
  {
      "Date": "1/20/10",
      "Open": "5226.1",
      "High": "5256.7",
      "Low": "5201.4",
      "Close": "5221.7"
  },
  {
      "Date": "1/21/10",
      "Open": "5220.2",
      "High": "5220.35",
      "Low": "5085.45",
      "Close": "5094.1"
  },
  {
      "Date": "1/22/10",
      "Open": "5094.15",
      "High": "5094.15",
      "Low": "4954.85",
      "Close": "5036"
  },
  {
      "Date": "1/25/10",
      "Open": "5034.55",
      "High": "5035.7",
      "Low": "4983.05",
      "Close": "5007.9"
  },
  {
      "Date": "1/27/10",
      "Open": "5008.5",
      "High": "5008.5",
      "Low": "4833.05",
      "Close": "4853.1"
  },
  {
      "Date": "1/28/10",
      "Open": "4863",
      "High": "4929.9",
      "Low": "4824.95",
      "Close": "4867.2"
  },
  {
      "Date": "1/29/10",
      "Open": "4866.15",
      "High": "4893.7",
      "Low": "4766",
      "Close": "4882"
  },
  {
      "Date": "2/1/10",
      "Open": "4882.05",
      "High": "4918.8",
      "Low": "4827.15",
      "Close": "4899.7"
  },
  {
      "Date": "2/2/10",
      "Open": "4907.85",
      "High": "4951.15",
      "Low": "4814.1",
      "Close": "4830.1"
  },
  {
      "Date": "2/3/10",
      "Open": "4831",
      "High": "4949.15",
      "Low": "4831",
      "Close": "4931.8"
  },
  {
      "Date": "2/4/10",
      "Open": "4931.3",
      "High": "4931.3",
      "Low": "4832.35",
      "Close": "4845.3"
  },
  {
      "Date": "2/5/10",
      "Open": "4819.65",
      "High": "4827",
      "Low": "4692.35",
      "Close": "4718.6"
  },
  {
      "Date": "2/6/10",
      "Open": "4712.75",
      "High": "4768.15",
      "Low": "4712.75",
      "Close": "4757.2"
  },
  {
      "Date": "2/8/10",
      "Open": "4755.35",
      "High": "4799.05",
      "Low": "4675.4",
      "Close": "4760.4"
  },
  {
      "Date": "2/9/10",
      "Open": "4760.55",
      "High": "4810.4",
      "Low": "4739.35",
      "Close": "4792.6"
  },
  {
      "Date": "2/10/10",
      "Open": "4793",
      "High": "4826.85",
      "Low": "4748.1",
      "Close": "4757.2"
  },
  {
      "Date": "2/11/10",
      "Open": "4757.25",
      "High": "4843.8",
      "Low": "4757.25",
      "Close": "4826.8"
  },
  {
      "Date": "2/15/10",
      "Open": "4827.9",
      "High": "4845.6",
      "Low": "4783.9",
      "Close": "4801.9"
  },
  {
      "Date": "2/16/10",
      "Open": "4801.8",
      "High": "4880",
      "Low": "4791.35",
      "Close": "4855.7"
  },
  {
      "Date": "2/17/10",
      "Open": "4858.65",
      "High": "4929.7",
      "Low": "4857.6",
      "Close": "4914"
  },
  {
      "Date": "2/18/10",
      "Open": "4915.1",
      "High": "4922.05",
      "Low": "4873.7",
      "Close": "4887.7"
  },
  {
      "Date": "2/19/10",
      "Open": "4887.3",
      "High": "4887.3",
      "Low": "4805.55",
      "Close": "4844.9"
  },
  {
      "Date": "2/22/10",
      "Open": "4849.35",
      "High": "4912.05",
      "Low": "4845.9",
      "Close": "4856.4"
  },
  {
      "Date": "2/23/10",
      "Open": "4856.6",
      "High": "4884.1",
      "Low": "4833.15",
      "Close": "4870"
  },
  {
      "Date": "2/24/10",
      "Open": "4869.55",
      "High": "4880.55",
      "Low": "4834.65",
      "Close": "4858.6"
  },
  {
      "Date": "2/25/10",
      "Open": "4859",
      "High": "4880.15",
      "Low": "4835.6",
      "Close": "4859.7"
  },
  {
      "Date": "2/26/10",
      "Open": "4858.5",
      "High": "4992",
      "Low": "4858.45",
      "Close": "4922.3"
  },
  {
      "Date": "3/2/10",
      "Open": "4935.6",
      "High": "5029.45",
      "Low": "4935.35",
      "Close": "5017"
  },
  {
      "Date": "3/3/10",
      "Open": "5015.8",
      "High": "5093.25",
      "Low": "5015.1",
      "Close": "5088.1"
  },
  {
      "Date": "3/4/10",
      "Open": "5096.95",
      "High": "5096.95",
      "Low": "5049",
      "Close": "5080.2"
  },
  {
      "Date": "3/5/10",
      "Open": "5080.55",
      "High": "5118.65",
      "Low": "5068.05",
      "Close": "5088.7"
  },
  {
      "Date": "3/8/10",
      "Open": "5092.15",
      "High": "5147.1",
      "Low": "5092.15",
      "Close": "5124"
  },
  {
      "Date": "3/9/10",
      "Open": "5121.05",
      "High": "5131.8",
      "Low": "5094.35",
      "Close": "5101.5"
  },
  {
      "Date": "3/10/10",
      "Open": "5101.6",
      "High": "5137.4",
      "Low": "5092.05",
      "Close": "5116.2"
  },
  {
      "Date": "3/11/10",
      "Open": "5116.35",
      "High": "5152.6",
      "Low": "5102.1",
      "Close": "5133.4"
  },
  {
      "Date": "3/12/10",
      "Open": "5131.8",
      "High": "5158.1",
      "Low": "5122.1",
      "Close": "5137"
  },
  {
      "Date": "3/15/10",
      "Open": "5134.45",
      "High": "5151.05",
      "Low": "5101.2",
      "Close": "5128.9"
  },
  {
      "Date": "3/16/10",
      "Open": "5128.95",
      "High": "5209.25",
      "Low": "5125.7",
      "Close": "5198.1"
  },
  {
      "Date": "3/17/10",
      "Open": "5198.45",
      "High": "5260.5",
      "Low": "5177.15",
      "Close": "5231.9"
  },
  {
      "Date": "3/18/10",
      "Open": "5232.55",
      "High": "5255.65",
      "Low": "5214.4",
      "Close": "5245.9"
  },
  {
      "Date": "3/19/10",
      "Open": "5246.8",
      "High": "5269.95",
      "Low": "5237.1",
      "Close": "5262.8"
  },
  {
      "Date": "3/22/10",
      "Open": "5260.95",
      "High": "5260.95",
      "Low": "5187.05",
      "Close": "5205.2"
  },
  {
      "Date": "3/23/10",
      "Open": "5205.85",
      "High": "5243.6",
      "Low": "5193.4",
      "Close": "5225.3"
  },
  {
      "Date": "3/25/10",
      "Open": "5225.3",
      "High": "5267.3",
      "Low": "5202.95",
      "Close": "5260.4"
  },
  {
      "Date": "3/26/10",
      "Open": "5260.55",
      "High": "5293.75",
      "Low": "5260.55",
      "Close": "5282"
  },
  {
      "Date": "3/29/10",
      "Open": "5283.9",
      "High": "5329.55",
      "Low": "5242.15",
      "Close": "5302.8"
  },
  {
      "Date": "3/30/10",
      "Open": "5302.95",
      "High": "5325",
      "Low": "5251.35",
      "Close": "5262.4"
  },
  {
      "Date": "3/31/10",
      "Open": "5260.4",
      "High": "5293.9",
      "Low": "5235.15",
      "Close": "5249.1"
  },
  {
      "Date": "4/1/10",
      "Open": "5249.2",
      "High": "5298.6",
      "Low": "5249.2",
      "Close": "5290.5"
  },
  {
      "Date": "4/5/10",
      "Open": "5291.4",
      "High": "5377.55",
      "Low": "5291.4",
      "Close": "5368.4"
  },
  {
      "Date": "4/6/10",
      "Open": "5369.65",
      "High": "5388.65",
      "Low": "5351.7",
      "Close": "5366"
  },
  {
      "Date": "4/7/10",
      "Open": "5365.7",
      "High": "5399.65",
      "Low": "5345.05",
      "Close": "5374.6"
  },
  {
      "Date": "4/8/10",
      "Open": "5376.3",
      "High": "5383.65",
      "Low": "5290.25",
      "Close": "5304.4"
  },
  {
      "Date": "4/9/10",
      "Open": "5302.4",
      "High": "5377.45",
      "Low": "5302.25",
      "Close": "5361.7"
  },
  {
      "Date": "4/12/10",
      "Open": "5354.15",
      "High": "5382.15",
      "Low": "5324.9",
      "Close": "5339.7"
  },
  {
      "Date": "4/13/10",
      "Open": "5340.85",
      "High": "5356.5",
      "Low": "5301.7",
      "Close": "5322.9"
  },
  {
      "Date": "4/15/10",
      "Open": "5323.3",
      "High": "5373.15",
      "Low": "5265.3",
      "Close": "5273.6"
  },
  {
      "Date": "4/16/10",
      "Open": "5273.4",
      "High": "5283.05",
      "Low": "5237.55",
      "Close": "5262.6"
  },
  {
      "Date": "4/19/10",
      "Open": "5279.05",
      "High": "5279.05",
      "Low": "5160.9",
      "Close": "5203.6"
  },
  {
      "Date": "4/20/10",
      "Open": "5208.3",
      "High": "5257.25",
      "Low": "5208.3",
      "Close": "5230.1"
  },
  {
      "Date": "4/21/10",
      "Open": "5230.3",
      "High": "5266.3",
      "Low": "5230.3",
      "Close": "5244.9"
  },
  {
      "Date": "4/22/10",
      "Open": "5248.6",
      "High": "5331.8",
      "Low": "5221.1",
      "Close": "5269.3"
  },
  {
      "Date": "4/23/10",
      "Open": "5269.65",
      "High": "5311.05",
      "Low": "5269.65",
      "Close": "5304.1"
  },
  {
      "Date": "4/26/10",
      "Open": "5299.35",
      "High": "5342.35",
      "Low": "5299.35",
      "Close": "5322.4"
  },
  {
      "Date": "4/27/10",
      "Open": "5322.1",
      "High": "5330.55",
      "Low": "5301.4",
      "Close": "5308.3"
  },
  {
      "Date": "4/28/10",
      "Open": "5308.2",
      "High": "5308.25",
      "Low": "5202.45",
      "Close": "5215.4"
  },
  {
      "Date": "4/29/10",
      "Open": "5215.25",
      "High": "5264.75",
      "Low": "5214.8",
      "Close": "5254.1"
  },
  {
      "Date": "4/30/10",
      "Open": "5254.2",
      "High": "5294.8",
      "Low": "5254.2",
      "Close": "5278"
  },
  {
      "Date": "5/3/10",
      "Open": "5278.4",
      "High": "5278.7",
      "Low": "5210.05",
      "Close": "5222.7"
  },
  {
      "Date": "5/4/10",
      "Open": "5223.9",
      "High": "5250.15",
      "Low": "5134.85",
      "Close": "5148.5"
  },
  {
      "Date": "5/5/10",
      "Open": "5148.35",
      "High": "5148.35",
      "Low": "5056.5",
      "Close": "5124.9"
  },
  {
      "Date": "5/6/10",
      "Open": "5124.4",
      "High": "5124.9",
      "Low": "5037.75",
      "Close": "5090.8"
  },
  {
      "Date": "5/7/10",
      "Open": "5072.3",
      "High": "5085.65",
      "Low": "4984.6",
      "Close": "5018"
  },
  {
      "Date": "5/10/10",
      "Open": "5026.6",
      "High": "5203.3",
      "Low": "5026.6",
      "Close": "5193.6"
  },
  {
      "Date": "5/11/10",
      "Open": "5189.75",
      "High": "5206.7",
      "Low": "5126.5",
      "Close": "5136.1"
  },
  {
      "Date": "5/12/10",
      "Open": "5133.75",
      "High": "5172.85",
      "Low": "5098.8",
      "Close": "5156.6"
  },
  {
      "Date": "5/13/10",
      "Open": "5157.55",
      "High": "5212.7",
      "Low": "5147.95",
      "Close": "5178.9"
  },
  {
      "Date": "5/14/10",
      "Open": "5180.55",
      "High": "5192.75",
      "Low": "5070.95",
      "Close": "5093.5"
  },
  {
      "Date": "5/17/10",
      "Open": "5093.9",
      "High": "5094.55",
      "Low": "4966.25",
      "Close": "5059.9"
  },
  {
      "Date": "5/18/10",
      "Open": "5059.55",
      "High": "5105.2",
      "Low": "5024.25",
      "Close": "5066.2"
  },
  {
      "Date": "5/19/10",
      "Open": "5065.1",
      "High": "5065.1",
      "Low": "4908.15",
      "Close": "4919.6"
  },
  {
      "Date": "5/20/10",
      "Open": "4924.3",
      "High": "4980.25",
      "Low": "4924.3",
      "Close": "4947.6"
  },
  {
      "Date": "5/21/10",
      "Open": "4946.7",
      "High": "4946.7",
      "Low": "4842.3",
      "Close": "4931.1"
  },
  {
      "Date": "5/24/10",
      "Open": "4944.3",
      "High": "5029.55",
      "Low": "4923.45",
      "Close": "4943.9"
  },
  {
      "Date": "5/25/10",
      "Open": "4945.3",
      "High": "4946.6",
      "Low": "4786.45",
      "Close": "4806.7"
  },
  {
      "Date": "5/26/10",
      "Open": "4807.3",
      "High": "4925.45",
      "Low": "4807.3",
      "Close": "4917.4"
  },
  {
      "Date": "5/27/10",
      "Open": "4915.15",
      "High": "5016.6",
      "Low": "4897.6",
      "Close": "5003.1"
  },
  {
      "Date": "5/28/10",
      "Open": "5005.6",
      "High": "5077.25",
      "Low": "5005.6",
      "Close": "5066.5"
  },
  {
      "Date": "5/31/10",
      "Open": "5076.1",
      "High": "5097.6",
      "Low": "5038.55",
      "Close": "5086.3"
  },
  {
      "Date": "6/1/10",
      "Open": "5086.25",
      "High": "5086.95",
      "Low": "4961.05",
      "Close": "4970.2"
  },
  {
      "Date": "6/2/10",
      "Open": "4970.75",
      "High": "5031.2",
      "Low": "4967.05",
      "Close": "5019.8"
  },
  {
      "Date": "6/3/10",
      "Open": "5020.15",
      "High": "5125.7",
      "Low": "5020.15",
      "Close": "5110.5"
  },
  {
      "Date": "6/4/10",
      "Open": "5112.6",
      "High": "5147.9",
      "Low": "5091.6",
      "Close": "5135.5"
  },
  {
      "Date": "6/7/10",
      "Open": "5132.95",
      "High": "5132.95",
      "Low": "5004.25",
      "Close": "5034"
  },
  {
      "Date": "6/8/10",
      "Open": "5036.7",
      "High": "5071.35",
      "Low": "4967.3",
      "Close": "4987.1"
  },
  {
      "Date": "6/9/10",
      "Open": "4985.05",
      "High": "5050.6",
      "Low": "4980.1",
      "Close": "5000.3"
  },
  {
      "Date": "6/10/10",
      "Open": "4999.6",
      "High": "5085.2",
      "Low": "4997.6",
      "Close": "5078.6"
  },
  {
      "Date": "6/11/10",
      "Open": "5078.75",
      "High": "5139.05",
      "Low": "5078.75",
      "Close": "5119.3"
  },
  {
      "Date": "6/14/10",
      "Open": "5120.15",
      "High": "5201.25",
      "Low": "5120.15",
      "Close": "5197.7"
  },
  {
      "Date": "6/15/10",
      "Open": "5201.3",
      "High": "5231.45",
      "Low": "5171.05",
      "Close": "5222.3"
  },
  {
      "Date": "6/16/10",
      "Open": "5225.05",
      "High": "5255.65",
      "Low": "5214.9",
      "Close": "5233.3"
  },
  {
      "Date": "6/17/10",
      "Open": "5233.65",
      "High": "5285.55",
      "Low": "5206.55",
      "Close": "5274.8"
  },
  {
      "Date": "6/18/10",
      "Open": "5274.95",
      "High": "5302.3",
      "Low": "5245.5",
      "Close": "5262.6"
  },
  {
      "Date": "6/21/10",
      "Open": "5266.5",
      "High": "5366.75",
      "Low": "5266.5",
      "Close": "5353.3"
  },
  {
      "Date": "6/22/10",
      "Open": "5353.95",
      "High": "5354.35",
      "Low": "5311.05",
      "Close": "5316.5"
  },
  {
      "Date": "6/23/10",
      "Open": "5316.15",
      "High": "5333.3",
      "Low": "5288.15",
      "Close": "5323.1"
  },
  {
      "Date": "6/24/10",
      "Open": "5323.25",
      "High": "5348.3",
      "Low": "5284.55",
      "Close": "5320.6"
  },
  {
      "Date": "6/25/10",
      "Open": "5320.5",
      "High": "5320.5",
      "Low": "5259.9",
      "Close": "5269"
  },
  {
      "Date": "6/28/10",
      "Open": "5271.1",
      "High": "5339.45",
      "Low": "5270.75",
      "Close": "5333.5"
  },
  {
      "Date": "6/29/10",
      "Open": "5333.55",
      "High": "5334.15",
      "Low": "5235.8",
      "Close": "5256.1"
  },
  {
      "Date": "6/30/10",
      "Open": "5254.25",
      "High": "5320.35",
      "Low": "5210",
      "Close": "5312.5"
  },
  {
      "Date": "7/1/10",
      "Open": "5312.05",
      "High": "5312.55",
      "Low": "5232.1",
      "Close": "5251.4"
  },
  {
      "Date": "7/2/10",
      "Open": "5251.25",
      "High": "5277.25",
      "Low": "5225.6",
      "Close": "5237.1"
  },
  {
      "Date": "7/5/10",
      "Open": "5237",
      "High": "5252.75",
      "Low": "5225.85",
      "Close": "5235.9"
  },
  {
      "Date": "7/6/10",
      "Open": "5236.1",
      "High": "5297.45",
      "Low": "5231.5",
      "Close": "5289"
  },
  {
      "Date": "7/7/10",
      "Open": "5293.1",
      "High": "5296.75",
      "Low": "5233.45",
      "Close": "5241.1"
  },
  {
      "Date": "7/8/10",
      "Open": "5242",
      "High": "5320.5",
      "Low": "5242",
      "Close": "5296.8"
  },
  {
      "Date": "7/9/10",
      "Open": "5297.2",
      "High": "5359.05",
      "Low": "5297.2",
      "Close": "5352.4"
  },
  {
      "Date": "7/12/10",
      "Open": "5352.25",
      "High": "5402.7",
      "Low": "5351.6",
      "Close": "5383"
  },
  {
      "Date": "7/13/10",
      "Open": "5370.2",
      "High": "5406.2",
      "Low": "5357.85",
      "Close": "5400.6"
  },
  {
      "Date": "7/14/10",
      "Open": "5402",
      "High": "5453.45",
      "Low": "5371.7",
      "Close": "5386.1"
  },
  {
      "Date": "7/15/10",
      "Open": "5387.1",
      "High": "5399.2",
      "Low": "5360.6",
      "Close": "5378.7"
  },
  {
      "Date": "7/16/10",
      "Open": "5376.65",
      "High": "5401.35",
      "Low": "5374.4",
      "Close": "5393.9"
  },
  {
      "Date": "7/19/10",
      "Open": "5392.7",
      "High": "5409.1",
      "Low": "5361.5",
      "Close": "5386.4"
  },
  {
      "Date": "7/20/10",
      "Open": "5387",
      "High": "5416.45",
      "Low": "5353.6",
      "Close": "5368"
  },
  {
      "Date": "7/21/10",
      "Open": "5368.85",
      "High": "5409.1",
      "Low": "5368.85",
      "Close": "5399.3"
  },
  {
      "Date": "7/22/10",
      "Open": "5399.15",
      "High": "5447.15",
      "Low": "5372.2",
      "Close": "5441.9"
  },
  {
      "Date": "7/23/10",
      "Open": "5441.9",
      "High": "5477.5",
      "Low": "5435.15",
      "Close": "5449.1"
  },
  {
      "Date": "7/26/10",
      "Open": "5446.55",
      "High": "5466.25",
      "Low": "5409.2",
      "Close": "5418.6"
  },
  {
      "Date": "7/27/10",
      "Open": "5420.2",
      "High": "5450.95",
      "Low": "5407.2",
      "Close": "5430.6"
  },
  {
      "Date": "7/28/10",
      "Open": "5436.1",
      "High": "5447.85",
      "Low": "5386.25",
      "Close": "5397.5"
  },
  {
      "Date": "7/29/10",
      "Open": "5397.85",
      "High": "5415.85",
      "Low": "5381.55",
      "Close": "5408.9"
  },
  {
      "Date": "7/30/10",
      "Open": "5408.4",
      "High": "5413.25",
      "Low": "5349.2",
      "Close": "5367.6"
  },
  {
      "Date": "8/2/10",
      "Open": "5369.55",
      "High": "5438.85",
      "Low": "5351.3",
      "Close": "5431.6"
  },
  {
      "Date": "8/3/10",
      "Open": "5432.5",
      "High": "5459.2",
      "Low": "5426.4",
      "Close": "5439.5"
  },
  {
      "Date": "8/4/10",
      "Open": "5441.35",
      "High": "5481.9",
      "Low": "5428.4",
      "Close": "5467.8"
  },
  {
      "Date": "8/5/10",
      "Open": "5470.15",
      "High": "5487.15",
      "Low": "5443.1",
      "Close": "5447.1"
  },
  {
      "Date": "8/6/10",
      "Open": "5448.25",
      "High": "5471.9",
      "Low": "5431.35",
      "Close": "5439.2"
  },
  {
      "Date": "8/9/10",
      "Open": "5439.8",
      "High": "5492.3",
      "Low": "5433.25",
      "Close": "5486.1"
  },
  {
      "Date": "8/10/10",
      "Open": "5486.8",
      "High": "5491.45",
      "Low": "5445.35",
      "Close": "5460.7"
  },
  {
      "Date": "8/11/10",
      "Open": "5460.7",
      "High": "5474.6",
      "Low": "5412",
      "Close": "5420.6"
  },
  {
      "Date": "8/12/10",
      "Open": "5418.7",
      "High": "5431.1",
      "Low": "5372.45",
      "Close": "5416.4"
  },
  {
      "Date": "8/13/10",
      "Open": "5420.65",
      "High": "5476.5",
      "Low": "5415.5",
      "Close": "5452.1"
  },
  {
      "Date": "8/16/10",
      "Open": "5452.1",
      "High": "5465.25",
      "Low": "5397.4",
      "Close": "5418.3"
  },
  {
      "Date": "8/17/10",
      "Open": "5422.15",
      "High": "5443.55",
      "Low": "5408.8",
      "Close": "5414.1"
  },
  {
      "Date": "8/18/10",
      "Open": "5416.25",
      "High": "5487.95",
      "Low": "5416.25",
      "Close": "5479.1"
  },
  {
      "Date": "8/19/10",
      "Open": "5478.25",
      "High": "5544.7",
      "Low": "5478.1",
      "Close": "5540.2"
  },
  {
      "Date": "8/20/10",
      "Open": "5540.8",
      "High": "5546.6",
      "Low": "5513.35",
      "Close": "5530.6"
  },
  {
      "Date": "8/23/10",
      "Open": "5531.15",
      "High": "5549.8",
      "Low": "5519.4",
      "Close": "5543.5"
  },
  {
      "Date": "8/24/10",
      "Open": "5541.1",
      "High": "5547.25",
      "Low": "5488.45",
      "Close": "5505.1"
  },
  {
      "Date": "8/25/10",
      "Open": "5505.3",
      "High": "5506.15",
      "Low": "5452.55",
      "Close": "5462.3"
  },
  {
      "Date": "8/26/10",
      "Open": "5462.1",
      "High": "5486.55",
      "Low": "5454.7",
      "Close": "5477.9"
  },
  {
      "Date": "8/27/10",
      "Open": "5489.6",
      "High": "5495.2",
      "Low": "5391.95",
      "Close": "5408.7"
  },
  {
      "Date": "8/30/10",
      "Open": "5408.9",
      "High": "5469",
      "Low": "5390.35",
      "Close": "5415.4"
  },
  {
      "Date": "8/31/10",
      "Open": "5413.55",
      "High": "5413.9",
      "Low": "5348.9",
      "Close": "5402.4"
  },
  {
      "Date": "9/1/10",
      "Open": "5403.05",
      "High": "5478.6",
      "Low": "5403.05",
      "Close": "5471.8"
  },
  {
      "Date": "9/2/10",
      "Open": "5471.9",
      "High": "5513.95",
      "Low": "5471.85",
      "Close": "5486.1"
  },
  {
      "Date": "9/3/10",
      "Open": "5486.3",
      "High": "5510.4",
      "Low": "5473.65",
      "Close": "5479.4"
  },
  {
      "Date": "9/6/10",
      "Open": "5479.55",
      "High": "5589.4",
      "Low": "5479.55",
      "Close": "5576.9"
  },
  {
      "Date": "9/7/10",
      "Open": "5575.9",
      "High": "5625.5",
      "Low": "5571.65",
      "Close": "5604"
  },
  {
      "Date": "9/8/10",
      "Open": "5604.25",
      "High": "5625.3",
      "Low": "5567.75",
      "Close": "5607.8"
  },
  {
      "Date": "9/9/10",
      "Open": "5608.3",
      "High": "5647.45",
      "Low": "5608.1",
      "Close": "5640"
  },
  {
      "Date": "9/13/10",
      "Open": "5639.2",
      "High": "5770.6",
      "Low": "5639.2",
      "Close": "5760"
  },
  {
      "Date": "9/14/10",
      "Open": "5760.3",
      "High": "5838.45",
      "Low": "5760.3",
      "Close": "5795.5"
  },
  {
      "Date": "9/15/10",
      "Open": "5795.25",
      "High": "5869.45",
      "Low": "5792.2",
      "Close": "5860.9"
  },
  {
      "Date": "9/16/10",
      "Open": "5861.1",
      "High": "5901.65",
      "Low": "5815.8",
      "Close": "5828.7"
  },
  {
      "Date": "9/17/10",
      "Open": "5828.7",
      "High": "5898.4",
      "Low": "5828.7",
      "Close": "5884.9"
  },
  {
      "Date": "9/20/10",
      "Open": "5885.05",
      "High": "5989.5",
      "Low": "5885.05",
      "Close": "5980.4"
  },
  {
      "Date": "9/21/10",
      "Open": "5980.5",
      "High": "6032.8",
      "Low": "5961.85",
      "Close": "6009"
  },
  {
      "Date": "9/22/10",
      "Open": "6008.4",
      "High": "6037.4",
      "Low": "5946.45",
      "Close": "5991"
  },
  {
      "Date": "9/23/10",
      "Open": "5991.05",
      "High": "6006.8",
      "Low": "5932.4",
      "Close": "5959.5"
  },
  {
      "Date": "9/24/10",
      "Open": "5959.45",
      "High": "6029.1",
      "Low": "5951.2",
      "Close": "6018.3"
  },
  {
      "Date": "9/27/10",
      "Open": "6018.3",
      "High": "6073.5",
      "Low": "6018.3",
      "Close": "6035.6"
  },
  {
      "Date": "9/28/10",
      "Open": "6036.05",
      "High": "6049.8",
      "Low": "5991.3",
      "Close": "6029.5"
  },
  {
      "Date": "9/29/10",
      "Open": "6029.15",
      "High": "6071.55",
      "Low": "5979.65",
      "Close": "5991.3"
  },
  {
      "Date": "9/30/10",
      "Open": "5991.35",
      "High": "6048.45",
      "Low": "5963.6",
      "Close": "6029.9"
  },
  {
      "Date": "10/1/10",
      "Open": "6030.3",
      "High": "6153.1",
      "Low": "6030.3",
      "Close": "6143.4"
  },
  {
      "Date": "10/4/10",
      "Open": "6144.7",
      "High": "6222.1",
      "Low": "6144.7",
      "Close": "6159.4"
  },
  {
      "Date": "10/5/10",
      "Open": "6159.45",
      "High": "6188.35",
      "Low": "6118.05",
      "Close": "6145.8"
  },
  {
      "Date": "10/6/10",
      "Open": "6150.95",
      "High": "6223.4",
      "Low": "6148.6",
      "Close": "6186.4"
  },
  {
      "Date": "10/7/10",
      "Open": "6186.85",
      "High": "6199.45",
      "Low": "6107.5",
      "Close": "6120.3"
  },
  {
      "Date": "10/8/10",
      "Open": "6121.4",
      "High": "6148.6",
      "Low": "6067",
      "Close": "6103.4"
  },
  {
      "Date": "10/11/10",
      "Open": "6105.95",
      "High": "6187.75",
      "Low": "6105.95",
      "Close": "6135.8"
  },
  {
      "Date": "10/12/10",
      "Open": "6135.95",
      "High": "6144.95",
      "Low": "6057.95",
      "Close": "6090.9"
  },
  {
      "Date": "10/13/10",
      "Open": "6091.45",
      "High": "6240.25",
      "Low": "6089.75",
      "Close": "6233.9"
  },
  {
      "Date": "10/14/10",
      "Open": "6234.3",
      "High": "6284.1",
      "Low": "6157.9",
      "Close": "6177.3"
  },
  {
      "Date": "10/15/10",
      "Open": "6175.9",
      "High": "6200.6",
      "Low": "6050.35",
      "Close": "6062.6"
  },
  {
      "Date": "10/18/10",
      "Open": "6112.75",
      "High": "6115.1",
      "Low": "5985.1",
      "Close": "6075.9"
  },
  {
      "Date": "10/19/10",
      "Open": "6114.45",
      "High": "6127.05",
      "Low": "6008.15",
      "Close": "6027.3"
  },
  {
      "Date": "10/20/10",
      "Open": "6002.95",
      "High": "6038.1",
      "Low": "5966.75",
      "Close": "5982.1"
  },
  {
      "Date": "10/21/10",
      "Open": "6008.9",
      "High": "6113.5",
      "Low": "5984.9",
      "Close": "6101.5"
  },
  {
      "Date": "10/22/10",
      "Open": "6113.4",
      "High": "6121.1",
      "Low": "6041.35",
      "Close": "6066"
  },
  {
      "Date": "10/25/10",
      "Open": "6101.25",
      "High": "6151.3",
      "Low": "6094.6",
      "Close": "6105.8"
  },
  {
      "Date": "10/26/10",
      "Open": "6116.3",
      "High": "6120.25",
      "Low": "6074.65",
      "Close": "6082"
  },
  {
      "Date": "10/27/10",
      "Open": "6075.45",
      "High": "6075.95",
      "Low": "5987.55",
      "Close": "6012.6"
  },
  {
      "Date": "10/28/10",
      "Open": "6039.95",
      "High": "6071.1",
      "Low": "5968.1",
      "Close": "5987.7"
  },
  {
      "Date": "10/29/10",
      "Open": "6021.15",
      "High": "6032.65",
      "Low": "5937.1",
      "Close": "6017.7"
  },
  {
      "Date": "11/1/10",
      "Open": "6092.3",
      "High": "6132.4",
      "Low": "6084.75",
      "Close": "6117.5"
  },
  {
      "Date": "11/2/10",
      "Open": "6127.55",
      "High": "6138.1",
      "Low": "6094.4",
      "Close": "6119"
  },
  {
      "Date": "11/3/10",
      "Open": "6150.6",
      "High": "6182.5",
      "Low": "6146.8",
      "Close": "6160.5"
  },
  {
      "Date": "11/4/10",
      "Open": "6209.6",
      "High": "6290.15",
      "Low": "6199.35",
      "Close": "6281.8"
  },
  {
      "Date": "11/5/10",
      "Open": "6321.85",
      "High": "6338.5",
      "Low": "6303.1",
      "Close": "6312.4"
  },
  {
      "Date": "11/8/10",
      "Open": "6335.25",
      "High": "6335.9",
      "Low": "6265.35",
      "Close": "6273.2"
  },
  {
      "Date": "11/9/10",
      "Open": "6281",
      "High": "6310.9",
      "Low": "6243.55",
      "Close": "6301.5"
  },
  {
      "Date": "11/10/10",
      "Open": "6303.2",
      "High": "6307.65",
      "Low": "6269.25",
      "Close": "6275.7"
  },
  {
      "Date": "11/11/10",
      "Open": "6286.35",
      "High": "6296.55",
      "Low": "6179.5",
      "Close": "6194.2"
  },
  {
      "Date": "11/12/10",
      "Open": "6182.3",
      "High": "6202.5",
      "Low": "6056.75",
      "Close": "6071.6"
  },
  {
      "Date": "11/15/10",
      "Open": "6079.9",
      "High": "6128.75",
      "Low": "6039.4",
      "Close": "6121.6"
  },
  {
      "Date": "11/16/10",
      "Open": "6143.45",
      "High": "6144.05",
      "Low": "5970.6",
      "Close": "5988.7"
  },
  {
      "Date": "11/18/10",
      "Open": "6075.95",
      "High": "6076.2",
      "Low": "5906.75",
      "Close": "5998.8"
  },
  {
      "Date": "11/19/10",
      "Open": "6011.3",
      "High": "6013.25",
      "Low": "5863.95",
      "Close": "5890.3"
  },
  {
      "Date": "11/22/10",
      "Open": "5920.9",
      "High": "6020.25",
      "Low": "5905.15",
      "Close": "6010"
  },
  {
      "Date": "11/23/10",
      "Open": "5971.55",
      "High": "5973.35",
      "Low": "5824.95",
      "Close": "5934.7"
  },
  {
      "Date": "11/24/10",
      "Open": "5946.8",
      "High": "5976.65",
      "Low": "5833.6",
      "Close": "5865.7"
  },
  {
      "Date": "11/25/10",
      "Open": "5883.95",
      "High": "5907.1",
      "Low": "5780.35",
      "Close": "5799.7"
  },
  {
      "Date": "11/26/10",
      "Open": "5828.55",
      "High": "5838.5",
      "Low": "5690.35",
      "Close": "5751.9"
  },
  {
      "Date": "11/29/10",
      "Open": "5789.95",
      "High": "5843.15",
      "Low": "5754.7",
      "Close": "5830"
  },
  {
      "Date": "11/30/10",
      "Open": "5811.6",
      "High": "5892.25",
      "Low": "5768.35",
      "Close": "5862.7"
  },
  {
      "Date": "12/1/10",
      "Open": "5871",
      "High": "5971",
      "Low": "5865.55",
      "Close": "5960.9"
  },
  {
      "Date": "12/2/10",
      "Open": "6023.05",
      "High": "6029.5",
      "Low": "5980.6",
      "Close": "6011.7"
  },
  {
      "Date": "12/3/10",
      "Open": "6013.35",
      "High": "6025.4",
      "Low": "5964.25",
      "Close": "5992.8"
  },
  {
      "Date": "12/6/10",
      "Open": "6033.65",
      "High": "6069.45",
      "Low": "5981.7",
      "Close": "5992.2"
  },
  {
      "Date": "12/7/10",
      "Open": "5995.05",
      "High": "6001",
      "Low": "5939.7",
      "Close": "5976.5"
  },
  {
      "Date": "12/8/10",
      "Open": "5954.4",
      "High": "5960",
      "Low": "5878.6",
      "Close": "5903.7"
  },
  {
      "Date": "12/9/10",
      "Open": "5924.25",
      "High": "5927.3",
      "Low": "5742.3",
      "Close": "5766.5"
  },
  {
      "Date": "12/10/10",
      "Open": "5761",
      "High": "5865.5",
      "Low": "5721.15",
      "Close": "5857.3"
  },
  {
      "Date": "12/13/10",
      "Open": "5882.65",
      "High": "5913.8",
      "Low": "5795.9",
      "Close": "5907.6"
  },
  {
      "Date": "12/14/10",
      "Open": "5928.7",
      "High": "5953.95",
      "Low": "5888.75",
      "Close": "5944.1"
  },
  {
      "Date": "12/15/10",
      "Open": "5931.15",
      "High": "5942.75",
      "Low": "5865.8",
      "Close": "5892.3"
  },
  {
      "Date": "12/16/10",
      "Open": "5910.55",
      "High": "5956.15",
      "Low": "5855.05",
      "Close": "5948.7"
  },
  {
      "Date": "12/20/10",
      "Open": "5926.85",
      "High": "5985",
      "Low": "5900.25",
      "Close": "5947"
  },
  {
      "Date": "12/21/10",
      "Open": "5960.4",
      "High": "6007.45",
      "Low": "5960.05",
      "Close": "6000.6"
  },
  {
      "Date": "12/22/10",
      "Open": "6019",
      "High": "6023.8",
      "Low": "5958",
      "Close": "5984.4"
  },
  {
      "Date": "12/23/10",
      "Open": "6005.9",
      "High": "6006.45",
      "Low": "5964.6",
      "Close": "5980"
  },
  {
      "Date": "12/24/10",
      "Open": "5940.25",
      "High": "6017.35",
      "Low": "5940.25",
      "Close": "6011.6"
  },
  {
      "Date": "12/27/10",
      "Open": "6013.3",
      "High": "6045.75",
      "Low": "5991.1",
      "Close": "5998.1"
  },
  {
      "Date": "12/28/10",
      "Open": "6005.35",
      "High": "6010.9",
      "Low": "5982.25",
      "Close": "5996"
  },
  {
      "Date": "12/29/10",
      "Open": "6005.15",
      "High": "6067.55",
      "Low": "6002.85",
      "Close": "6060.3"
  },
  {
      "Date": "12/30/10",
      "Open": "6062.35",
      "High": "6106.4",
      "Low": "6062.35",
      "Close": "6101.8"
  },
  {
      "Date": "12/31/10",
      "Open": "6105.1",
      "High": "6147.3",
      "Low": "6103.55",
      "Close": "6134.5"
  },
  {
      "Date": "1/3/11",
      "Open": "6177.45",
      "High": "6178.55",
      "Low": "6147.2",
      "Close": "6157.6"
  },
  {
      "Date": "1/4/11",
      "Open": "6172.75",
      "High": "6181.05",
      "Low": "6124.4",
      "Close": "6146.3"
  },
  {
      "Date": "1/5/11",
      "Open": "6141.35",
      "High": "6141.35",
      "Low": "6062.35",
      "Close": "6079.8"
  },
  {
      "Date": "1/6/11",
      "Open": "6107",
      "High": "6116.15",
      "Low": "6022.3",
      "Close": "6048.2"
  },
  {
      "Date": "1/7/11",
      "Open": "6030.9",
      "High": "6051.2",
      "Low": "5883.6",
      "Close": "5904.6"
  },
  {
      "Date": "1/10/11",
      "Open": "5901.3",
      "High": "5907.25",
      "Low": "5740.95",
      "Close": "5762.8"
  },
  {
      "Date": "1/11/11",
      "Open": "5767.95",
      "High": "5842.6",
      "Low": "5698.2",
      "Close": "5754.1"
  },
  {
      "Date": "1/12/11",
      "Open": "5800.05",
      "High": "5874.2",
      "Low": "5711.3",
      "Close": "5863.2"
  },
  {
      "Date": "1/13/11",
      "Open": "5850.75",
      "High": "5857.75",
      "Low": "5736.7",
      "Close": "5751.9"
  },
  {
      "Date": "1/14/11",
      "Open": "5752.1",
      "High": "5833.65",
      "Low": "5639.65",
      "Close": "5654.5"
  },
  {
      "Date": "1/17/11",
      "Open": "5648.8",
      "High": "5696.15",
      "Low": "5624.15",
      "Close": "5654.7"
  },
  {
      "Date": "1/18/11",
      "Open": "5682.55",
      "High": "5730.5",
      "Low": "5671.25",
      "Close": "5724"
  },
  {
      "Date": "1/19/11",
      "Open": "5737.35",
      "High": "5747.65",
      "Low": "5662.55",
      "Close": "5691"
  },
  {
      "Date": "1/20/11",
      "Open": "5656",
      "High": "5729.45",
      "Low": "5634.5",
      "Close": "5711.6"
  },
  {
      "Date": "1/21/11",
      "Open": "5692.05",
      "High": "5717.55",
      "Low": "5674.5",
      "Close": "5696.5"
  },
  {
      "Date": "1/24/11",
      "Open": "5717.1",
      "High": "5756",
      "Low": "5697.75",
      "Close": "5743.2"
  },
  {
      "Date": "1/25/11",
      "Open": "5763.3",
      "High": "5801.55",
      "Low": "5680.65",
      "Close": "5687.4"
  },
  {
      "Date": "1/27/11",
      "Open": "5725.3",
      "High": "5726.1",
      "Low": "5594.95",
      "Close": "5604.3"
  },
  {
      "Date": "1/28/11",
      "Open": "5614",
      "High": "5614.4",
      "Low": "5459.55",
      "Close": "5512.1"
  },
  {
      "Date": "1/31/11",
      "Open": "5452.55",
      "High": "5526.85",
      "Low": "5416.65",
      "Close": "5505.9"
  },
  {
      "Date": "2/1/11",
      "Open": "5537.3",
      "High": "5539.15",
      "Low": "5402",
      "Close": "5417.2"
  },
  {
      "Date": "2/2/11",
      "Open": "5469.55",
      "High": "5490.6",
      "Low": "5415.65",
      "Close": "5432"
  },
  {
      "Date": "2/3/11",
      "Open": "5430.45",
      "High": "5532.65",
      "Low": "5418",
      "Close": "5526.7"
  },
  {
      "Date": "2/4/11",
      "Open": "5519.9",
      "High": "5556.3",
      "Low": "5369.05",
      "Close": "5395.7"
  },
  {
      "Date": "2/7/11",
      "Open": "5430.15",
      "High": "5440.35",
      "Low": "5376.95",
      "Close": "5396"
  },
  {
      "Date": "2/8/11",
      "Open": "5432.35",
      "High": "5432.35",
      "Low": "5303.4",
      "Close": "5312.5"
  },
  {
      "Date": "2/9/11",
      "Open": "5293.05",
      "High": "5339.45",
      "Low": "5225.65",
      "Close": "5253.5"
  },
  {
      "Date": "2/10/11",
      "Open": "5246.05",
      "High": "5272.6",
      "Low": "5196.8",
      "Close": "5225.8"
  },
  {
      "Date": "2/11/11",
      "Open": "5219.65",
      "High": "5319.45",
      "Low": "5177.7",
      "Close": "5310"
  },
  {
      "Date": "2/14/11",
      "Open": "5340.25",
      "High": "5463.8",
      "Low": "5340.25",
      "Close": "5456"
  },
  {
      "Date": "2/15/11",
      "Open": "5467.75",
      "High": "5506.5",
      "Low": "5408.35",
      "Close": "5481"
  },
  {
      "Date": "2/16/11",
      "Open": "5467.6",
      "High": "5504.8",
      "Low": "5460.35",
      "Close": "5481.7"
  },
  {
      "Date": "2/17/11",
      "Open": "5501.7",
      "High": "5553",
      "Low": "5463.4",
      "Close": "5546.4"
  },
  {
      "Date": "2/18/11",
      "Open": "5557.55",
      "High": "5599.25",
      "Low": "5441.95",
      "Close": "5458.9"
  },
  {
      "Date": "2/21/11",
      "Open": "5456.6",
      "High": "5526.25",
      "Low": "5413.1",
      "Close": "5518.6"
  },
  {
      "Date": "2/22/11",
      "Open": "5504.4",
      "High": "5519.45",
      "Low": "5437.3",
      "Close": "5469.2"
  },
  {
      "Date": "2/23/11",
      "Open": "5452.45",
      "High": "5495.2",
      "Low": "5427.55",
      "Close": "5437.3"
  },
  {
      "Date": "2/24/11",
      "Open": "5408.75",
      "High": "5423.4",
      "Low": "5242.5",
      "Close": "5262.7"
  },
  {
      "Date": "2/25/11",
      "Open": "5321.05",
      "High": "5338.2",
      "Low": "5232.75",
      "Close": "5303.5"
  },
  {
      "Date": "2/28/11",
      "Open": "5330.15",
      "High": "5477",
      "Low": "5308.6",
      "Close": "5333.2"
  },
  {
      "Date": "3/1/11",
      "Open": "5382",
      "High": "5533.05",
      "Low": "5373.55",
      "Close": "5522.3"
  },
  {
      "Date": "3/3/11",
      "Open": "5478.45",
      "High": "5570.75",
      "Low": "5468.25",
      "Close": "5536.2"
  },
  {
      "Date": "3/4/11",
      "Open": "5586.2",
      "High": "5608.2",
      "Low": "5524.1",
      "Close": "5538.7"
  },
  {
      "Date": "3/7/11",
      "Open": "5490.05",
      "High": "5491.25",
      "Low": "5408.45",
      "Close": "5463.1"
  },
  {
      "Date": "3/8/11",
      "Open": "5466.1",
      "High": "5530.55",
      "Low": "5464.75",
      "Close": "5520.8"
  },
  {
      "Date": "3/9/11",
      "Open": "5542.4",
      "High": "5563.3",
      "Low": "5477.45",
      "Close": "5531"
  },
  {
      "Date": "3/10/11",
      "Open": "5516.1",
      "High": "5516.3",
      "Low": "5468.45",
      "Close": "5494.4"
  },
  {
      "Date": "3/11/11",
      "Open": "5456.15",
      "High": "5502.7",
      "Low": "5411.55",
      "Close": "5445.4"
  },
  {
      "Date": "3/14/11",
      "Open": "5436.5",
      "High": "5537.3",
      "Low": "5434.25",
      "Close": "5531.5"
  },
  {
      "Date": "3/15/11",
      "Open": "5420",
      "High": "5497.85",
      "Low": "5373.65",
      "Close": "5449.6"
  },
  {
      "Date": "3/16/11",
      "Open": "5475.95",
      "High": "5535.1",
      "Low": "5475.95",
      "Close": "5511.1"
  },
  {
      "Date": "3/17/11",
      "Open": "5455.4",
      "High": "5510.05",
      "Low": "5435.3",
      "Close": "5446.6"
  },
  {
      "Date": "3/18/11",
      "Open": "5475.35",
      "High": "5483.05",
      "Low": "5366.4",
      "Close": "5373.7"
  },
  {
      "Date": "3/21/11",
      "Open": "5408.75",
      "High": "5413.3",
      "Low": "5348.2",
      "Close": "5364.7"
  },
  {
      "Date": "3/22/11",
      "Open": "5390.85",
      "High": "5428.15",
      "Low": "5376.15",
      "Close": "5413.8"
  },
  {
      "Date": "3/23/11",
      "Open": "5411.4",
      "High": "5484.95",
      "Low": "5401.95",
      "Close": "5480.2"
  },
  {
      "Date": "3/24/11",
      "Open": "5501.8",
      "High": "5529",
      "Low": "5496.1",
      "Close": "5522.4"
  },
  {
      "Date": "3/25/11",
      "Open": "5588.65",
      "High": "5667.1",
      "Low": "5560.95",
      "Close": "5654.2"
  },
  {
      "Date": "3/28/11",
      "Open": "5645.25",
      "High": "5709.1",
      "Low": "5643.2",
      "Close": "5687.2"
  },
  {
      "Date": "3/29/11",
      "Open": "5686.5",
      "High": "5770.35",
      "Low": "5680.7",
      "Close": "5736.3"
  },
  {
      "Date": "3/30/11",
      "Open": "5755.8",
      "High": "5803.15",
      "Low": "5753.9",
      "Close": "5787.6"
  },
  {
      "Date": "3/31/11",
      "Open": "5803.05",
      "High": "5872",
      "Low": "5778.65",
      "Close": "5833.7"
  },
  {
      "Date": "4/1/11",
      "Open": "5835",
      "High": "5860.2",
      "Low": "5810.4",
      "Close": "5826"
  },
  {
      "Date": "4/4/11",
      "Open": "5842",
      "High": "5918.7",
      "Low": "5833.2",
      "Close": "5908.4"
  },
  {
      "Date": "4/5/11",
      "Open": "5923.85",
      "High": "5928.65",
      "Low": "5855.85",
      "Close": "5910"
  },
  {
      "Date": "4/6/11",
      "Open": "5908",
      "High": "5944.45",
      "Low": "5868.8",
      "Close": "5891.7"
  },
  {
      "Date": "4/7/11",
      "Open": "5888.55",
      "High": "5906.1",
      "Low": "5866.25",
      "Close": "5885.7"
  },
  {
      "Date": "4/8/11",
      "Open": "5886.75",
      "High": "5926.95",
      "Low": "5822",
      "Close": "5842"
  },
  {
      "Date": "4/11/11",
      "Open": "5805.35",
      "High": "5830.3",
      "Low": "5777.9",
      "Close": "5785.7"
  },
  {
      "Date": "4/13/11",
      "Open": "5747.95",
      "High": "5923.6",
      "Low": "5735.55",
      "Close": "5911.5"
  },
  {
      "Date": "4/15/11",
      "Open": "5898.75",
      "High": "5907.35",
      "Low": "5806.45",
      "Close": "5824.5"
  },
  {
      "Date": "4/18/11",
      "Open": "5824.35",
      "High": "5897.9",
      "Low": "5722.25",
      "Close": "5729.1"
  },
  {
      "Date": "4/19/11",
      "Open": "5716",
      "High": "5762.95",
      "Low": "5693.25",
      "Close": "5740.7"
  },
  {
      "Date": "4/20/11",
      "Open": "5786.05",
      "High": "5857.35",
      "Low": "5759.65",
      "Close": "5851.6"
  },
  {
      "Date": "4/21/11",
      "Open": "5882.85",
      "High": "5912.9",
      "Low": "5864.35",
      "Close": "5884.7"
  },
  {
      "Date": "4/25/11",
      "Open": "5859.6",
      "High": "5906.6",
      "Low": "5857",
      "Close": "5874.5"
  },
  {
      "Date": "4/26/11",
      "Open": "5876.85",
      "High": "5893.2",
      "Low": "5791.55",
      "Close": "5868.4"
  },
  {
      "Date": "4/27/11",
      "Open": "5884.2",
      "High": "5892.35",
      "Low": "5819.95",
      "Close": "5833.9"
  },
  {
      "Date": "4/28/11",
      "Open": "5851.35",
      "High": "5856.4",
      "Low": "5776.95",
      "Close": "5785.4"
  },
  {
      "Date": "4/29/11",
      "Open": "5782.5",
      "High": "5804.3",
      "Low": "5706.05",
      "Close": "5749.5"
  },
  {
      "Date": "5/2/11",
      "Open": "5766.9",
      "High": "5775.25",
      "Low": "5687.7",
      "Close": "5701.3"
  },
  {
      "Date": "5/3/11",
      "Open": "5689.7",
      "High": "5710.8",
      "Low": "5554.85",
      "Close": "5565.2"
  },
  {
      "Date": "5/4/11",
      "Open": "5567.7",
      "High": "5578.8",
      "Low": "5503",
      "Close": "5537.1"
  },
  {
      "Date": "5/5/11",
      "Open": "5531.6",
      "High": "5560.3",
      "Low": "5443.65",
      "Close": "5459.8"
  },
  {
      "Date": "5/6/11",
      "Open": "5477.65",
      "High": "5564.4",
      "Low": "5472.45",
      "Close": "5551.4"
  },
  {
      "Date": "5/9/11",
      "Open": "5575.2",
      "High": "5586.05",
      "Low": "5502.4",
      "Close": "5551.1"
  },
  {
      "Date": "5/10/11",
      "Open": "5555.55",
      "High": "5592.9",
      "Low": "5514.55",
      "Close": "5541.2"
  },
  {
      "Date": "5/11/11",
      "Open": "5547.2",
      "High": "5574.7",
      "Low": "5525",
      "Close": "5565"
  },
  {
      "Date": "5/12/11",
      "Open": "5537.8",
      "High": "5572.5",
      "Low": "5476.3",
      "Close": "5486.1"
  },
  {
      "Date": "5/13/11",
      "Open": "5492.35",
      "High": "5605",
      "Low": "5472.15",
      "Close": "5544.7"
  },
  {
      "Date": "5/16/11",
      "Open": "5541.7",
      "High": "5541.8",
      "Low": "5487.65",
      "Close": "5499"
  },
  {
      "Date": "5/17/11",
      "Open": "5496.1",
      "High": "5523.85",
      "Low": "5421.05",
      "Close": "5438.9"
  },
  {
      "Date": "5/18/11",
      "Open": "5448.2",
      "High": "5460.5",
      "Low": "5401.25",
      "Close": "5420.6"
  },
  {
      "Date": "5/19/11",
      "Open": "5448.15",
      "High": "5452.6",
      "Low": "5411.25",
      "Close": "5428.1"
  },
  {
      "Date": "5/20/11",
      "Open": "5450.65",
      "High": "5517.55",
      "Low": "5432.75",
      "Close": "5486.3"
  },
  {
      "Date": "5/23/11",
      "Open": "5456.7",
      "High": "5456.7",
      "Low": "5373",
      "Close": "5386.5"
  },
  {
      "Date": "5/24/11",
      "Open": "5385.1",
      "High": "5422.6",
      "Low": "5367.45",
      "Close": "5394.8"
  },
  {
      "Date": "5/25/11",
      "Open": "5389.1",
      "High": "5389.1",
      "Low": "5328.7",
      "Close": "5348.9"
  },
  {
      "Date": "5/26/11",
      "Open": "5372.75",
      "High": "5422.2",
      "Low": "5356.35",
      "Close": "5412.3"
  },
  {
      "Date": "5/27/11",
      "Open": "5413.7",
      "High": "5485.8",
      "Low": "5413.6",
      "Close": "5476.1"
  },
  {
      "Date": "5/30/11",
      "Open": "5493.75",
      "High": "5509.3",
      "Low": "5458.6",
      "Close": "5473.1"
  },
  {
      "Date": "5/31/11",
      "Open": "5492",
      "High": "5571.6",
      "Low": "5489.7",
      "Close": "5560.1"
  },
  {
      "Date": "6/1/11",
      "Open": "5561.05",
      "High": "5597.35",
      "Low": "5559.45",
      "Close": "5592"
  },
  {
      "Date": "6/2/11",
      "Open": "5529.9",
      "High": "5568.2",
      "Low": "5521.95",
      "Close": "5550.3"
  },
  {
      "Date": "6/3/11",
      "Open": "5565.7",
      "High": "5604.95",
      "Low": "5507.2",
      "Close": "5516.7"
  },
  {
      "Date": "6/6/11",
      "Open": "5504.3",
      "High": "5542.65",
      "Low": "5479.85",
      "Close": "5532"
  },
  {
      "Date": "6/7/11",
      "Open": "5509.15",
      "High": "5570.1",
      "Low": "5507.8",
      "Close": "5556.1"
  },
  {
      "Date": "6/8/11",
      "Open": "5535.25",
      "High": "5556.6",
      "Low": "5514.9",
      "Close": "5526.8"
  },
  {
      "Date": "6/9/11",
      "Open": "5523.55",
      "High": "5540.1",
      "Low": "5502.05",
      "Close": "5521"
  },
  {
      "Date": "6/10/11",
      "Open": "5518.05",
      "High": "5521.45",
      "Low": "5457.45",
      "Close": "5485.8"
  },
  {
      "Date": "6/13/11",
      "Open": "5469.85",
      "High": "5496.7",
      "Low": "5436.95",
      "Close": "5482.8"
  },
  {
      "Date": "6/14/11",
      "Open": "5485.6",
      "High": "5520.15",
      "Low": "5484.2",
      "Close": "5500.5"
  },
  {
      "Date": "6/15/11",
      "Open": "5494.45",
      "High": "5499.35",
      "Low": "5438.95",
      "Close": "5447.5"
  },
  {
      "Date": "6/16/11",
      "Open": "5419.65",
      "High": "5447.5",
      "Low": "5389.8",
      "Close": "5396.7"
  },
  {
      "Date": "6/17/11",
      "Open": "5412.5",
      "High": "5421.15",
      "Low": "5355.85",
      "Close": "5366.4"
  },
  {
      "Date": "6/20/11",
      "Open": "5372.2",
      "High": "5377.4",
      "Low": "5195.9",
      "Close": "5257.9"
  },
  {
      "Date": "6/21/11",
      "Open": "5280.8",
      "High": "5322.45",
      "Low": "5257",
      "Close": "5275.8"
  },
  {
      "Date": "6/22/11",
      "Open": "5304.65",
      "High": "5310.5",
      "Low": "5262.5",
      "Close": "5278.3"
  },
  {
      "Date": "6/23/11",
      "Open": "5269.1",
      "High": "5330.6",
      "Low": "5252.25",
      "Close": "5320"
  },
  {
      "Date": "6/24/11",
      "Open": "5343.4",
      "High": "5477.85",
      "Low": "5343.4",
      "Close": "5471.2"
  },
  {
      "Date": "6/27/11",
      "Open": "5441.2",
      "High": "5552.65",
      "Low": "5434.25",
      "Close": "5526.6"
  },
  {
      "Date": "6/28/11",
      "Open": "5548.85",
      "High": "5558.3",
      "Low": "5496.35",
      "Close": "5545.3"
  },
  {
      "Date": "6/29/11",
      "Open": "5566.5",
      "High": "5608.65",
      "Low": "5566.5",
      "Close": "5600.4"
  },
  {
      "Date": "6/30/11",
      "Open": "5614.5",
      "High": "5657.9",
      "Low": "5606.1",
      "Close": "5647.4"
  },
  {
      "Date": "7/1/11",
      "Open": "5705.75",
      "High": "5705.8",
      "Low": "5609.75",
      "Close": "5627.2"
  },
  {
      "Date": "7/4/11",
      "Open": "5679.55",
      "High": "5679.65",
      "Low": "5633.1",
      "Close": "5650.5"
  },
  {
      "Date": "7/5/11",
      "Open": "5659.85",
      "High": "5659.85",
      "Low": "5612.3",
      "Close": "5632.1"
  },
  {
      "Date": "7/6/11",
      "Open": "5622.7",
      "High": "5655.4",
      "Low": "5610.75",
      "Close": "5625.4"
  },
  {
      "Date": "7/7/11",
      "Open": "5633.35",
      "High": "5737.15",
      "Low": "5632.95",
      "Close": "5728.9"
  },
  {
      "Date": "7/8/11",
      "Open": "5734.65",
      "High": "5740.4",
      "Low": "5651.05",
      "Close": "5660.6"
  },
  {
      "Date": "7/11/11",
      "Open": "5648.05",
      "High": "5652.9",
      "Low": "5601.7",
      "Close": "5616.1"
  },
  {
      "Date": "7/12/11",
      "Open": "5556.9",
      "High": "5580.25",
      "Low": "5496.95",
      "Close": "5526.1"
  },
  {
      "Date": "7/13/11",
      "Open": "5542.05",
      "High": "5596.15",
      "Low": "5541.4",
      "Close": "5585.4"
  },
  {
      "Date": "7/14/11",
      "Open": "5569",
      "High": "5653.95",
      "Low": "5541.7",
      "Close": "5599.8"
  },
  {
      "Date": "7/15/11",
      "Open": "5602.95",
      "High": "5631.7",
      "Low": "5562.75",
      "Close": "5581.1"
  },
  {
      "Date": "7/18/11",
      "Open": "5581.75",
      "High": "5596.6",
      "Low": "5550.95",
      "Close": "5567"
  },
  {
      "Date": "7/19/11",
      "Open": "5569.85",
      "High": "5627.65",
      "Low": "5557.2",
      "Close": "5613.5"
  },
  {
      "Date": "7/20/11",
      "Open": "5642.05",
      "High": "5645.4",
      "Low": "5555.1",
      "Close": "5567"
  },
  {
      "Date": "7/21/11",
      "Open": "5554.6",
      "High": "5578.9",
      "Low": "5532.7",
      "Close": "5541.6"
  },
  {
      "Date": "7/22/11",
      "Open": "5576.95",
      "High": "5642.2",
      "Low": "5567.1",
      "Close": "5633.9"
  },
  {
      "Date": "7/25/11",
      "Open": "5633.8",
      "High": "5700.55",
      "Low": "5616.7",
      "Close": "5680.3"
  },
  {
      "Date": "7/26/11",
      "Open": "5688.45",
      "High": "5702.25",
      "Low": "5560.15",
      "Close": "5574.8"
  },
  {
      "Date": "7/27/11",
      "Open": "5588.55",
      "High": "5591.7",
      "Low": "5521.5",
      "Close": "5546.8"
  },
  {
      "Date": "7/28/11",
      "Open": "5492.4",
      "High": "5512.1",
      "Low": "5475.65",
      "Close": "5487.7"
  },
  {
      "Date": "7/29/11",
      "Open": "5479",
      "High": "5520.3",
      "Low": "5453.95",
      "Close": "5482"
  },
  {
      "Date": "8/1/11",
      "Open": "5527.5",
      "High": "5551.9",
      "Low": "5486.45",
      "Close": "5516.8"
  },
  {
      "Date": "8/2/11",
      "Open": "5493.2",
      "High": "5496.3",
      "Low": "5433.65",
      "Close": "5456.5"
  },
  {
      "Date": "8/3/11",
      "Open": "5402",
      "High": "5422.6",
      "Low": "5378.85",
      "Close": "5404.8"
  },
  {
      "Date": "8/4/11",
      "Open": "5412.4",
      "High": "5434.5",
      "Low": "5323.15",
      "Close": "5331.8"
  },
  {
      "Date": "8/5/11",
      "Open": "5204.35",
      "High": "5229.65",
      "Low": "5116.45",
      "Close": "5211.2"
  },
  {
      "Date": "8/8/11",
      "Open": "5083.85",
      "High": "5204.2",
      "Low": "5054.05",
      "Close": "5118.5"
  },
  {
      "Date": "8/9/11",
      "Open": "4947.9",
      "High": "5167",
      "Low": "4946.45",
      "Close": "5072.8"
  },
  {
      "Date": "8/10/11",
      "Open": "5196.55",
      "High": "5197.95",
      "Low": "5123.35",
      "Close": "5161"
  },
  {
      "Date": "8/11/11",
      "Open": "5128",
      "High": "5184.95",
      "Low": "5121",
      "Close": "5138.3"
  },
  {
      "Date": "8/12/11",
      "Open": "5194.4",
      "High": "5194.45",
      "Low": "5053.35",
      "Close": "5072.9"
  },
  {
      "Date": "8/16/11",
      "Open": "5125.75",
      "High": "5132.2",
      "Low": "5015.4",
      "Close": "5035.8"
  },
  {
      "Date": "8/17/11",
      "Open": "5030.3",
      "High": "5112.15",
      "Low": "5017.25",
      "Close": "5056.6"
  },
  {
      "Date": "8/18/11",
      "Open": "5077.95",
      "High": "5078.6",
      "Low": "4932.15",
      "Close": "4944.1"
  },
  {
      "Date": "8/19/11",
      "Open": "4859.3",
      "High": "4893.6",
      "Low": "4796.1",
      "Close": "4845.6"
  },
  {
      "Date": "8/22/11",
      "Open": "4843.7",
      "High": "4910.05",
      "Low": "4808.75",
      "Close": "4898.8"
  },
  {
      "Date": "8/23/11",
      "Open": "4925.15",
      "High": "4965.8",
      "Low": "4863.8",
      "Close": "4948.9"
  },
  {
      "Date": "8/24/11",
      "Open": "4934.35",
      "High": "4962.4",
      "Low": "4875.3",
      "Close": "4888.9"
  },
  {
      "Date": "8/25/11",
      "Open": "4914.65",
      "High": "4915.85",
      "Low": "4825.05",
      "Close": "4839.6"
  },
  {
      "Date": "8/26/11",
      "Open": "4839.25",
      "High": "4872",
      "Low": "4720",
      "Close": "4747.8"
  },
  {
      "Date": "8/29/11",
      "Open": "4806.2",
      "High": "4934.4",
      "Low": "4806.05",
      "Close": "4919.6"
  },
  {
      "Date": "8/30/11",
      "Open": "4973.25",
      "High": "5016.25",
      "Low": "4927.55",
      "Close": "5001"
  },
  {
      "Date": "9/2/11",
      "Open": "5109.8",
      "High": "5113.7",
      "Low": "4993.35",
      "Close": "5040"
  },
  {
      "Date": "9/5/11",
      "Open": "4998.9",
      "High": "5030.3",
      "Low": "4964.45",
      "Close": "5017.2"
  },
  {
      "Date": "9/6/11",
      "Open": "4993.35",
      "High": "5072.9",
      "Low": "4942.9",
      "Close": "5064.3"
  },
  {
      "Date": "9/7/11",
      "Open": "5080.15",
      "High": "5154.5",
      "Low": "5076.3",
      "Close": "5124.6"
  },
  {
      "Date": "9/8/11",
      "Open": "5139.2",
      "High": "5169.25",
      "Low": "5098.25",
      "Close": "5153.2"
  },
  {
      "Date": "9/9/11",
      "Open": "5161.3",
      "High": "5163.75",
      "Low": "5046.8",
      "Close": "5059.4"
  },
  {
      "Date": "9/12/11",
      "Open": "4981.7",
      "High": "4985.6",
      "Low": "4911.25",
      "Close": "4946.8"
  },
  {
      "Date": "9/13/11",
      "Open": "4977.8",
      "High": "5030.15",
      "Low": "4911.05",
      "Close": "4940.9"
  },
  {
      "Date": "9/14/11",
      "Open": "4965.05",
      "High": "5026.15",
      "Low": "4917.4",
      "Close": "5012.5"
  },
  {
      "Date": "9/15/11",
      "Open": "5062.35",
      "High": "5091.45",
      "Low": "4967.45",
      "Close": "5075.7"
  },
  {
      "Date": "9/16/11",
      "Open": "5123.35",
      "High": "5143.6",
      "Low": "5068.1",
      "Close": "5084.2"
  },
  {
      "Date": "9/19/11",
      "Open": "5068.4",
      "High": "5068.4",
      "Low": "5019.25",
      "Close": "5031.9"
  },
  {
      "Date": "9/20/11",
      "Open": "5042.55",
      "High": "5149.9",
      "Low": "5035.25",
      "Close": "5140.2"
  },
  {
      "Date": "9/21/11",
      "Open": "5153.75",
      "High": "5168.4",
      "Low": "5109.85",
      "Close": "5133.2"
  },
  {
      "Date": "9/22/11",
      "Open": "5054.45",
      "High": "5059.85",
      "Low": "4907.75",
      "Close": "4923.6"
  },
  {
      "Date": "9/23/11",
      "Open": "4873.75",
      "High": "4930.25",
      "Low": "4829.6",
      "Close": "4867.7"
  },
  {
      "Date": "9/26/11",
      "Open": "4878.6",
      "High": "4879.8",
      "Low": "4758.85",
      "Close": "4835.4"
  },
  {
      "Date": "9/27/11",
      "Open": "4905.15",
      "High": "4982.95",
      "Low": "4905.15",
      "Close": "4971.2"
  },
  {
      "Date": "9/28/11",
      "Open": "5005.5",
      "High": "5006.05",
      "Low": "4918.45",
      "Close": "4945.9"
  },
  {
      "Date": "9/29/11",
      "Open": "4924.2",
      "High": "5034.25",
      "Low": "4906",
      "Close": "5015.4"
  },
  {
      "Date": "9/30/11",
      "Open": "4990.15",
      "High": "5025.55",
      "Low": "4924.3",
      "Close": "4943.2"
  },
  {
      "Date": "10/3/11",
      "Open": "4874.4",
      "High": "4879.15",
      "Low": "4823.9",
      "Close": "4849.5"
  },
  {
      "Date": "10/4/11",
      "Open": "4823.5",
      "High": "4869.75",
      "Low": "4728.3",
      "Close": "4772.1"
  },
  {
      "Date": "10/5/11",
      "Open": "4791.3",
      "High": "4827.8",
      "Low": "4741",
      "Close": "4751.3"
  },
  {
      "Date": "10/7/11",
      "Open": "4883.65",
      "High": "4922.6",
      "Low": "4861.2",
      "Close": "4888"
  },
  {
      "Date": "10/10/11",
      "Open": "4886.85",
      "High": "4991.15",
      "Low": "4882.05",
      "Close": "4979.6"
  },
  {
      "Date": "10/11/11",
      "Open": "5019.9",
      "High": "5045.1",
      "Low": "4964",
      "Close": "4974.3"
  },
  {
      "Date": "10/12/11",
      "Open": "5011.2",
      "High": "5109.8",
      "Low": "4997.65",
      "Close": "5099.4"
  },
  {
      "Date": "10/13/11",
      "Open": "5130.8",
      "High": "5136.95",
      "Low": "5067.65",
      "Close": "5077.8"
  },
  {
      "Date": "10/14/11",
      "Open": "5057.35",
      "High": "5141.4",
      "Low": "5056.6",
      "Close": "5132.3"
  },
  {
      "Date": "10/17/11",
      "Open": "5156.2",
      "High": "5160.2",
      "Low": "5084.5",
      "Close": "5118.2"
  },
  {
      "Date": "10/18/11",
      "Open": "5049.45",
      "High": "5057.5",
      "Low": "5011.05",
      "Close": "5037.5"
  },
  {
      "Date": "10/19/11",
      "Open": "5080.45",
      "High": "5148.05",
      "Low": "5075.3",
      "Close": "5139.1"
  },
  {
      "Date": "10/20/11",
      "Open": "5086.55",
      "High": "5099",
      "Low": "5033.95",
      "Close": "5091.9"
  },
  {
      "Date": "10/21/11",
      "Open": "5106.6",
      "High": "5120.75",
      "Low": "5037.95",
      "Close": "5049.9"
  },
  {
      "Date": "10/24/11",
      "Open": "5114.7",
      "High": "5145.65",
      "Low": "5084.75",
      "Close": "5098.3"
  },
  {
      "Date": "10/25/11",
      "Open": "5137.9",
      "High": "5211",
      "Low": "5085.55",
      "Close": "5191.6"
  },
  {
      "Date": "10/26/11",
      "Open": "5214.95",
      "High": "5219.25",
      "Low": "5196.15",
      "Close": "5201.8"
  },
  {
      "Date": "10/28/11",
      "Open": "5341.9",
      "High": "5399.7",
      "Low": "5322.8",
      "Close": "5360.7"
  },
  {
      "Date": "10/31/11",
      "Open": "5358.9",
      "High": "5360.25",
      "Low": "5314.6",
      "Close": "5326.6"
  },
  {
      "Date": "11/1/11",
      "Open": "5278.6",
      "High": "5310.85",
      "Low": "5238.3",
      "Close": "5257.9"
  },
  {
      "Date": "11/2/11",
      "Open": "5216.75",
      "High": "5300.1",
      "Low": "5204.95",
      "Close": "5258.4"
  },
  {
      "Date": "11/3/11",
      "Open": "5241.55",
      "High": "5281.6",
      "Low": "5201.85",
      "Close": "5265.7"
  },
  {
      "Date": "11/4/11",
      "Open": "5325.4",
      "High": "5326.45",
      "Low": "5256.8",
      "Close": "5284.2"
  },
  {
      "Date": "11/8/11",
      "Open": "5292.25",
      "High": "5304.25",
      "Low": "5252",
      "Close": "5289.3"
  },
  {
      "Date": "11/9/11",
      "Open": "5309.7",
      "High": "5317.5",
      "Low": "5211.75",
      "Close": "5221"
  },
  {
      "Date": "11/11/11",
      "Open": "5159.75",
      "High": "5198.6",
      "Low": "5142.25",
      "Close": "5168.8"
  },
  {
      "Date": "11/14/11",
      "Open": "5217.35",
      "High": "5228.9",
      "Low": "5140.55",
      "Close": "5148.3"
  },
  {
      "Date": "11/15/11",
      "Open": "5131.2",
      "High": "5158.75",
      "Low": "5052.85",
      "Close": "5068.5"
  },
  {
      "Date": "11/16/11",
      "Open": "5059.1",
      "High": "5065.2",
      "Low": "4989.5",
      "Close": "5030.4"
  },
  {
      "Date": "11/17/11",
      "Open": "5027.1",
      "High": "5036.8",
      "Low": "4919.45",
      "Close": "4934.7"
  },
  {
      "Date": "11/18/11",
      "Open": "4899.15",
      "High": "4915.9",
      "Low": "4837.95",
      "Close": "4905.8"
  },
  {
      "Date": "11/21/11",
      "Open": "4873.8",
      "High": "4873.8",
      "Low": "4764.8",
      "Close": "4778.3"
  },
  {
      "Date": "11/22/11",
      "Open": "4794.85",
      "High": "4854",
      "Low": "4782.55",
      "Close": "4812.3"
  },
  {
      "Date": "11/23/11",
      "Open": "4779.5",
      "High": "4779.5",
      "Low": "4640.95",
      "Close": "4706.4"
  },
  {
      "Date": "11/24/11",
      "Open": "4707.55",
      "High": "4771.1",
      "Low": "4639.1",
      "Close": "4756.4"
  },
  {
      "Date": "11/25/11",
      "Open": "4731.3",
      "High": "4767.3",
      "Low": "4693.1",
      "Close": "4710"
  },
  {
      "Date": "11/28/11",
      "Open": "4769.3",
      "High": "4859.1",
      "Low": "4766.4",
      "Close": "4851.3"
  },
  {
      "Date": "11/29/11",
      "Open": "4864.2",
      "High": "4866.1",
      "Low": "4787.1",
      "Close": "4805.1"
  },
  {
      "Date": "11/30/11",
      "Open": "4766.15",
      "High": "4851.55",
      "Low": "4754.8",
      "Close": "4832"
  },
  {
      "Date": "12/1/11",
      "Open": "4970.85",
      "High": "5011.9",
      "Low": "4916.7",
      "Close": "4936.8"
  },
  {
      "Date": "12/2/11",
      "Open": "4940.85",
      "High": "5062.55",
      "Low": "4918.4",
      "Close": "5050.1"
  },
  {
      "Date": "12/5/11",
      "Open": "5036.5",
      "High": "5055.4",
      "Low": "5002.55",
      "Close": "5039.1"
  },
  {
      "Date": "12/7/11",
      "Open": "5050.1",
      "High": "5099.25",
      "Low": "5032.25",
      "Close": "5062.6"
  },
  {
      "Date": "12/8/11",
      "Open": "5037.4",
      "High": "5049.05",
      "Low": "4921.45",
      "Close": "4943.6"
  },
  {
      "Date": "12/9/11",
      "Open": "4870.75",
      "High": "4918.35",
      "Low": "4841.75",
      "Close": "4866.7"
  },
  {
      "Date": "12/12/11",
      "Open": "4906.85",
      "High": "4910.25",
      "Low": "4755.55",
      "Close": "4764.6"
  },
  {
      "Date": "12/13/11",
      "Open": "4733.6",
      "High": "4824.7",
      "Low": "4728.5",
      "Close": "4800.6"
  },
  {
      "Date": "12/14/11",
      "Open": "4788.7",
      "High": "4839.55",
      "Low": "4750.4",
      "Close": "4763.2"
  },
  {
      "Date": "12/15/11",
      "Open": "4712.8",
      "High": "4768.65",
      "Low": "4673.85",
      "Close": "4746.3"
  },
  {
      "Date": "12/16/11",
      "Open": "4752.5",
      "High": "4818.85",
      "Low": "4628.2",
      "Close": "4651.6"
  },
  {
      "Date": "12/19/11",
      "Open": "4623.15",
      "High": "4623.15",
      "Low": "4555.9",
      "Close": "4613.1"
  },
  {
      "Date": "12/20/11",
      "Open": "4635.8",
      "High": "4637.25",
      "Low": "4531.15",
      "Close": "4544.2"
  },
  {
      "Date": "12/21/11",
      "Open": "4636.45",
      "High": "4707.35",
      "Low": "4601.95",
      "Close": "4693.1"
  },
  {
      "Date": "12/22/11",
      "Open": "4636.9",
      "High": "4740.6",
      "Low": "4632.95",
      "Close": "4733.8"
  },
  {
      "Date": "12/23/11",
      "Open": "4763.2",
      "High": "4763.45",
      "Low": "4693.2",
      "Close": "4714"
  },
  {
      "Date": "12/26/11",
      "Open": "4718.15",
      "High": "4787.25",
      "Low": "4718.15",
      "Close": "4779"
  },
  {
      "Date": "12/27/11",
      "Open": "4780.2",
      "High": "4800.5",
      "Low": "4723.65",
      "Close": "4750.5"
  },
  {
      "Date": "12/28/11",
      "Open": "4756.2",
      "High": "4756.2",
      "Low": "4685.65",
      "Close": "4705.8"
  },
  {
      "Date": "12/29/11",
      "Open": "4681.15",
      "High": "4701.8",
      "Low": "4639.05",
      "Close": "4646.2"
  },
  {
      "Date": "12/30/11",
      "Open": "4659.95",
      "High": "4690.45",
      "Low": "4608.9",
      "Close": "4624.3"
  },
  {
      "Date": "1/2/12",
      "Open": "4640.2",
      "High": "4645.95",
      "Low": "4588.05",
      "Close": "4636.7"
  },
  {
      "Date": "1/3/12",
      "Open": "4675.8",
      "High": "4773.1",
      "Low": "4675.8",
      "Close": "4765.3"
  },
  {
      "Date": "1/4/12",
      "Open": "4774.95",
      "High": "4782.85",
      "Low": "4728.85",
      "Close": "4749.6"
  },
  {
      "Date": "1/5/12",
      "Open": "4749",
      "High": "4779.8",
      "Low": "4730.15",
      "Close": "4749.9"
  },
  {
      "Date": "1/6/12",
      "Open": "4724.15",
      "High": "4794.9",
      "Low": "4686.85",
      "Close": "4754.1"
  },
  {
      "Date": "1/7/12",
      "Open": "4755.6",
      "High": "4759.4",
      "Low": "4743.05",
      "Close": "4746.9"
  },
  {
      "Date": "1/9/12",
      "Open": "4747.55",
      "High": "4758.7",
      "Low": "4695.45",
      "Close": "4742.8"
  },
  {
      "Date": "1/10/12",
      "Open": "4771.85",
      "High": "4855.9",
      "Low": "4768.25",
      "Close": "4849.5"
  },
  {
      "Date": "1/11/12",
      "Open": "4863.15",
      "High": "4877.2",
      "Low": "4841.6",
      "Close": "4860.9"
  },
  {
      "Date": "1/12/12",
      "Open": "4840.95",
      "High": "4869.2",
      "Low": "4803.9",
      "Close": "4831.2"
  },
  {
      "Date": "1/13/12",
      "Open": "4861.95",
      "High": "4898.85",
      "Low": "4834.2",
      "Close": "4866"
  },
  {
      "Date": "1/16/12",
      "Open": "4844",
      "High": "4880.8",
      "Low": "4827.05",
      "Close": "4873.9"
  },
  {
      "Date": "1/17/12",
      "Open": "4904.5",
      "High": "4975.55",
      "Low": "4904",
      "Close": "4967.3"
  },
  {
      "Date": "1/18/12",
      "Open": "4977.75",
      "High": "4980.65",
      "Low": "4931.05",
      "Close": "4955.8"
  },
  {
      "Date": "1/19/12",
      "Open": "4995",
      "High": "5023.8",
      "Low": "4991.4",
      "Close": "5018.4"
  },
  {
      "Date": "1/20/12",
      "Open": "5044.85",
      "High": "5064.15",
      "Low": "5004.3",
      "Close": "5048.6"
  },
  {
      "Date": "1/23/12",
      "Open": "5025.35",
      "High": "5059.55",
      "Low": "5021.35",
      "Close": "5046.2"
  },
  {
      "Date": "1/24/12",
      "Open": "5064.8",
      "High": "5141.05",
      "Low": "5049.8",
      "Close": "5127.3"
  },
  {
      "Date": "1/25/12",
      "Open": "5151.5",
      "High": "5174.15",
      "Low": "5130.25",
      "Close": "5158.3"
  },
  {
      "Date": "1/27/12",
      "Open": "5216.75",
      "High": "5217",
      "Low": "5162.4",
      "Close": "5204.7"
  },
  {
      "Date": "1/30/12",
      "Open": "5163.55",
      "High": "5166.15",
      "Low": "5076.7",
      "Close": "5087.3"
  },
  {
      "Date": "1/31/12",
      "Open": "5125.25",
      "High": "5215.4",
      "Low": "5120.15",
      "Close": "5199.2"
  },
  {
      "Date": "2/1/12",
      "Open": "5198.15",
      "High": "5244.6",
      "Low": "5159",
      "Close": "5235.7"
  },
  {
      "Date": "2/2/12",
      "Open": "5272.1",
      "High": "5289.95",
      "Low": "5225.75",
      "Close": "5269.9"
  },
  {
      "Date": "2/3/12",
      "Open": "5276.1",
      "High": "5334.85",
      "Low": "5255.55",
      "Close": "5325.8"
  },
  {
      "Date": "2/6/12",
      "Open": "5379.45",
      "High": "5390.05",
      "Low": "5327.25",
      "Close": "5361.6"
  },
  {
      "Date": "2/7/12",
      "Open": "5412.95",
      "High": "5413.35",
      "Low": "5322.95",
      "Close": "5335.1"
  },
  {
      "Date": "2/8/12",
      "Open": "5343.8",
      "High": "5396.9",
      "Low": "5325.2",
      "Close": "5368.1"
  },
  {
      "Date": "2/9/12",
      "Open": "5343.05",
      "High": "5423.4",
      "Low": "5338.9",
      "Close": "5412.3"
  },
  {
      "Date": "2/10/12",
      "Open": "5399.8",
      "High": "5427.75",
      "Low": "5341.05",
      "Close": "5381.6"
  },
  {
      "Date": "2/13/12",
      "Open": "5382.1",
      "High": "5421.05",
      "Low": "5351.4",
      "Close": "5390.2"
  },
  {
      "Date": "2/14/12",
      "Open": "5380.8",
      "High": "5428.05",
      "Low": "5377.95",
      "Close": "5416"
  },
  {
      "Date": "2/15/12",
      "Open": "5460.6",
      "High": "5542.1",
      "Low": "5460.6",
      "Close": "5531.9"
  },
  {
      "Date": "2/16/12",
      "Open": "5513.75",
      "High": "5531.4",
      "Low": "5483.75",
      "Close": "5521.9"
  },
  {
      "Date": "2/17/12",
      "Open": "5574.2",
      "High": "5606.7",
      "Low": "5545.2",
      "Close": "5564.3"
  },
  {
      "Date": "2/21/12",
      "Open": "5561.9",
      "High": "5621.5",
      "Low": "5561.75",
      "Close": "5607.1"
  },
  {
      "Date": "2/22/12",
      "Open": "5609.75",
      "High": "5629.95",
      "Low": "5491.35",
      "Close": "5505.3"
  },
  {
      "Date": "2/23/12",
      "Open": "5490.05",
      "High": "5537.4",
      "Low": "5460.8",
      "Close": "5483.3"
  },
  {
      "Date": "2/24/12",
      "Open": "5479.15",
      "High": "5521.4",
      "Low": "5405.9",
      "Close": "5429.3"
  },
  {
      "Date": "2/27/12",
      "Open": "5448.1",
      "High": "5449.8",
      "Low": "5268.15",
      "Close": "5281.2"
  },
  {
      "Date": "2/28/12",
      "Open": "5310.5",
      "High": "5391.1",
      "Low": "5306.45",
      "Close": "5375.5"
  },
  {
      "Date": "2/29/12",
      "Open": "5424.95",
      "High": "5458.8",
      "Low": "5352.25",
      "Close": "5385.2"
  },
  {
      "Date": "3/1/12",
      "Open": "5366",
      "High": "5372.45",
      "Low": "5297.5",
      "Close": "5339.7"
  },
  {
      "Date": "3/2/12",
      "Open": "5369.45",
      "High": "5392.55",
      "Low": "5315.05",
      "Close": "5359.3"
  },
  {
      "Date": "3/3/12",
      "Open": "5360.05",
      "High": "5369.6",
      "Low": "5353.4",
      "Close": "5359.4"
  },
  {
      "Date": "3/5/12",
      "Open": "5342.55",
      "High": "5344.5",
      "Low": "5265.7",
      "Close": "5280.3"
  },
  {
      "Date": "3/6/12",
      "Open": "5266",
      "High": "5382.05",
      "Low": "5206.4",
      "Close": "5222.4"
  },
  {
      "Date": "3/7/12",
      "Open": "5207.05",
      "High": "5243.85",
      "Low": "5171.45",
      "Close": "5220.4"
  },
  {
      "Date": "3/9/12",
      "Open": "5294.1",
      "High": "5342.3",
      "Low": "5291.6",
      "Close": "5333.5"
  },
  {
      "Date": "3/12/12",
      "Open": "5420.1",
      "High": "5421.9",
      "Low": "5327.3",
      "Close": "5359.5"
  },
  {
      "Date": "3/13/12",
      "Open": "5391.05",
      "High": "5438.65",
      "Low": "5390.8",
      "Close": "5429.5"
  },
  {
      "Date": "3/14/12",
      "Open": "5490.55",
      "High": "5499.4",
      "Low": "5437.8",
      "Close": "5463.9"
  },
  {
      "Date": "3/15/12",
      "Open": "5462.5",
      "High": "5462.5",
      "Low": "5362.3",
      "Close": "5380.5"
  },
  {
      "Date": "3/16/12",
      "Open": "5380.35",
      "High": "5445.65",
      "Low": "5305",
      "Close": "5317.9"
  },
  {
      "Date": "3/19/12",
      "Open": "5337.35",
      "High": "5340.7",
      "Low": "5238.55",
      "Close": "5257"
  },
  {
      "Date": "3/20/12",
      "Open": "5257.15",
      "High": "5297.35",
      "Low": "5233.25",
      "Close": "5274.8"
  },
  {
      "Date": "3/21/12",
      "Open": "5267.2",
      "High": "5372.35",
      "Low": "5256",
      "Close": "5364.9"
  },
  {
      "Date": "3/22/12",
      "Open": "5361.1",
      "High": "5385.95",
      "Low": "5205.65",
      "Close": "5228.4"
  },
  {
      "Date": "3/23/12",
      "Open": "5255.65",
      "High": "5312",
      "Low": "5220",
      "Close": "5278.2"
  },
  {
      "Date": "3/26/12",
      "Open": "5274.35",
      "High": "5274.95",
      "Low": "5174.9",
      "Close": "5184.2"
  },
  {
      "Date": "3/27/12",
      "Open": "5242.95",
      "High": "5277.95",
      "Low": "5184.65",
      "Close": "5243.1"
  },
  {
      "Date": "3/28/12",
      "Open": "5231.7",
      "High": "5236.55",
      "Low": "5169.6",
      "Close": "5194.7"
  },
  {
      "Date": "3/29/12",
      "Open": "5145.95",
      "High": "5194.3",
      "Low": "5135.95",
      "Close": "5178.8"
  },
  {
      "Date": "3/30/12",
      "Open": "5206.6",
      "High": "5307.1",
      "Low": "5203.65",
      "Close": "5295.5"
  },
  {
      "Date": "4/2/12",
      "Open": "5296.35",
      "High": "5331.55",
      "Low": "5278.8",
      "Close": "5317.9"
  },
  {
      "Date": "4/3/12",
      "Open": "5353.2",
      "High": "5378.75",
      "Low": "5344.45",
      "Close": "5358.5"
  },
  {
      "Date": "4/4/12",
      "Open": "5328.65",
      "High": "5338.4",
      "Low": "5305.3",
      "Close": "5322.9"
  },
  {
      "Date": "4/9/12",
      "Open": "5282.5",
      "High": "5287.9",
      "Low": "5228",
      "Close": "5234.4"
  },
  {
      "Date": "4/10/12",
      "Open": "5254.1",
      "High": "5255.8",
      "Low": "5211.85",
      "Close": "5243.6"
  },
  {
      "Date": "4/11/12",
      "Open": "5209.45",
      "High": "5263.65",
      "Low": "5190.8",
      "Close": "5226.8"
  },
  {
      "Date": "4/12/12",
      "Open": "5246.75",
      "High": "5290.6",
      "Low": "5246.75",
      "Close": "5276.8"
  },
  {
      "Date": "4/13/12",
      "Open": "5255.7",
      "High": "5306.75",
      "Low": "5185.4",
      "Close": "5207.4"
  },
  {
      "Date": "4/16/12",
      "Open": "5190.6",
      "High": "5233.5",
      "Low": "5183.5",
      "Close": "5226.2"
  },
  {
      "Date": "4/17/12",
      "Open": "5266.6",
      "High": "5298.2",
      "Low": "5208.35",
      "Close": "5289.7"
  },
  {
      "Date": "4/18/12",
      "Open": "5320.7",
      "High": "5342",
      "Low": "5293.45",
      "Close": "5300"
  },
  {
      "Date": "4/19/12",
      "Open": "5320.6",
      "High": "5342.45",
      "Low": "5291.3",
      "Close": "5332.4"
  },
  {
      "Date": "4/20/12",
      "Open": "5313.95",
      "High": "5336.15",
      "Low": "5245.45",
      "Close": "5290.8"
  },
  {
      "Date": "4/23/12",
      "Open": "5277.4",
      "High": "5310.55",
      "Low": "5187.15",
      "Close": "5200.6"
  },
  {
      "Date": "4/24/12",
      "Open": "5215.9",
      "High": "5232.35",
      "Low": "5180.35",
      "Close": "5222.6"
  },
  {
      "Date": "4/25/12",
      "Open": "5222.2",
      "High": "5236.1",
      "Low": "5160.65",
      "Close": "5202"
  },
  {
      "Date": "4/26/12",
      "Open": "5214.75",
      "High": "5215.6",
      "Low": "5179.05",
      "Close": "5189"
  },
  {
      "Date": "4/27/12",
      "Open": "5189",
      "High": "5223.05",
      "Low": "5154.3",
      "Close": "5190.6"
  },
  {
      "Date": "4/28/12",
      "Open": "5209.6",
      "High": "5216.4",
      "Low": "5196.35",
      "Close": "5209"
  },
  {
      "Date": "4/30/12",
      "Open": "5201.45",
      "High": "5262.15",
      "Low": "5201.45",
      "Close": "5248.1"
  },
  {
      "Date": "5/2/12",
      "Open": "5254.3",
      "High": "5279.6",
      "Low": "5226.45",
      "Close": "5239.1"
  },
  {
      "Date": "5/3/12",
      "Open": "5211.2",
      "High": "5217.3",
      "Low": "5180.65",
      "Close": "5188.4"
  },
  {
      "Date": "5/4/12",
      "Open": "5166.65",
      "High": "5177.2",
      "Low": "5070.6",
      "Close": "5086.8"
  },
  {
      "Date": "5/7/12",
      "Open": "5017.8",
      "High": "5124.75",
      "Low": "4988",
      "Close": "5114.1"
  },
  {
      "Date": "5/8/12",
      "Open": "5114.7",
      "High": "5119.95",
      "Low": "4983.6",
      "Close": "4999.9"
  },
  {
      "Date": "5/9/12",
      "Open": "4967.9",
      "High": "5016.25",
      "Low": "4956.45",
      "Close": "4974.8"
  },
  {
      "Date": "5/10/12",
      "Open": "4984.15",
      "High": "5039.3",
      "Low": "4950.3",
      "Close": "4965.7"
  },
  {
      "Date": "5/11/12",
      "Open": "4938.85",
      "High": "4976.25",
      "Low": "4906.15",
      "Close": "4928.9"
  },
  {
      "Date": "5/14/12",
      "Open": "4934.35",
      "High": "4957.2",
      "Low": "4874.5",
      "Close": "4907.8"
  },
  {
      "Date": "5/15/12",
      "Open": "4869.85",
      "High": "4955.2",
      "Low": "4868.55",
      "Close": "4942.8"
  },
  {
      "Date": "5/16/12",
      "Open": "4875.3",
      "High": "4882.25",
      "Low": "4837.05",
      "Close": "4858.2"
  },
  {
      "Date": "5/17/12",
      "Open": "4878.6",
      "High": "4922.25",
      "Low": "4850.2",
      "Close": "4870.2"
  },
  {
      "Date": "5/18/12",
      "Open": "4796.4",
      "High": "4908.5",
      "Low": "4788.95",
      "Close": "4891.4"
  },
  {
      "Date": "5/21/12",
      "Open": "4888.5",
      "High": "4937.5",
      "Low": "4888.5",
      "Close": "4906"
  },
  {
      "Date": "5/22/12",
      "Open": "4954.7",
      "High": "4956.35",
      "Low": "4849.9",
      "Close": "4860.5"
  },
  {
      "Date": "5/23/12",
      "Open": "4843",
      "High": "4853.75",
      "Low": "4803.95",
      "Close": "4835.6"
  },
  {
      "Date": "5/24/12",
      "Open": "4863.4",
      "High": "4931.9",
      "Low": "4830.15",
      "Close": "4921.4"
  },
  {
      "Date": "5/25/12",
      "Open": "4905.95",
      "High": "4935.8",
      "Low": "4889.35",
      "Close": "4920.4"
  },
  {
      "Date": "5/28/12",
      "Open": "4931.7",
      "High": "4994.95",
      "Low": "4931.3",
      "Close": "4985.6"
  },
  {
      "Date": "5/29/12",
      "Open": "5005.35",
      "High": "5020.15",
      "Low": "4982.15",
      "Close": "4990.1"
  },
  {
      "Date": "5/30/12",
      "Open": "4964.25",
      "High": "4982.25",
      "Low": "4944.9",
      "Close": "4950.7"
  },
  {
      "Date": "5/31/12",
      "Open": "4896.1",
      "High": "4949.25",
      "Low": "4883.55",
      "Close": "4924.2"
  },
  {
      "Date": "6/1/12",
      "Open": "4910.85",
      "High": "4925",
      "Low": "4831.75",
      "Close": "4841.6"
  },
  {
      "Date": "6/4/12",
      "Open": "4797.3",
      "High": "4858.3",
      "Low": "4770.35",
      "Close": "4848.1"
  },
  {
      "Date": "6/5/12",
      "Open": "4869.45",
      "High": "4898.95",
      "Low": "4847.7",
      "Close": "4863.3"
  },
  {
      "Date": "6/6/12",
      "Open": "4886.65",
      "High": "5010.45",
      "Low": "4886.15",
      "Close": "4997.1"
  },
  {
      "Date": "6/7/12",
      "Open": "5035.35",
      "High": "5059.65",
      "Low": "5007.75",
      "Close": "5049.6"
  },
  {
      "Date": "6/8/12",
      "Open": "5044.25",
      "High": "5084.45",
      "Low": "4994.8",
      "Close": "5068.3"
  },
  {
      "Date": "6/11/12",
      "Open": "5096.7",
      "High": "5124.45",
      "Low": "5040.7",
      "Close": "5054.1"
  },
  {
      "Date": "6/12/12",
      "Open": "5015.5",
      "High": "5128.9",
      "Low": "5015.15",
      "Close": "5115.9"
  },
  {
      "Date": "6/13/12",
      "Open": "5117.55",
      "High": "5144.9",
      "Low": "5095.45",
      "Close": "5121.4"
  },
  {
      "Date": "6/14/12",
      "Open": "5105.1",
      "High": "5130",
      "Low": "5047.6",
      "Close": "5054.7"
  },
  {
      "Date": "6/15/12",
      "Open": "5069.55",
      "High": "5146.2",
      "Low": "5069.15",
      "Close": "5139"
  },
  {
      "Date": "6/18/12",
      "Open": "5174",
      "High": "5190.2",
      "Low": "5041.7",
      "Close": "5064.2"
  },
  {
      "Date": "6/19/12",
      "Open": "5050.8",
      "High": "5113.6",
      "Low": "5048.1",
      "Close": "5103.8"
  },
  {
      "Date": "6/20/12",
      "Open": "5114.55",
      "High": "5141.7",
      "Low": "5100.7",
      "Close": "5120.5"
  },
  {
      "Date": "6/21/12",
      "Open": "5097.35",
      "High": "5170.4",
      "Low": "5093.45",
      "Close": "5165"
  },
  {
      "Date": "6/22/12",
      "Open": "5101.75",
      "High": "5159.8",
      "Low": "5094",
      "Close": "5146"
  },
  {
      "Date": "6/25/12",
      "Open": "5158.5",
      "High": "5194.6",
      "Low": "5105.65",
      "Close": "5114.6"
  },
  {
      "Date": "6/26/12",
      "Open": "5107.45",
      "High": "5134.55",
      "Low": "5095.5",
      "Close": "5120.8"
  },
  {
      "Date": "6/27/12",
      "Open": "5149.45",
      "High": "5160.1",
      "Low": "5129.25",
      "Close": "5141.9"
  },
  {
      "Date": "6/28/12",
      "Open": "5148.95",
      "High": "5159.05",
      "Low": "5125.3",
      "Close": "5149.1"
  },
  {
      "Date": "6/29/12",
      "Open": "5191.25",
      "High": "5286.25",
      "Low": "5189",
      "Close": "5278.9"
  },
  {
      "Date": "7/2/12",
      "Open": "5283.85",
      "High": "5302.15",
      "Low": "5263.35",
      "Close": "5278.6"
  },
  {
      "Date": "7/3/12",
      "Open": "5298.85",
      "High": "5317",
      "Low": "5265.95",
      "Close": "5287.9"
  },
  {
      "Date": "7/4/12",
      "Open": "5310.4",
      "High": "5317.65",
      "Low": "5273.3",
      "Close": "5302.5"
  },
  {
      "Date": "7/5/12",
      "Open": "5297.05",
      "High": "5333.65",
      "Low": "5288.85",
      "Close": "5327.3"
  },
  {
      "Date": "7/6/12",
      "Open": "5324.7",
      "High": "5327.2",
      "Low": "5287.75",
      "Close": "5316.9"
  },
  {
      "Date": "7/9/12",
      "Open": "5283.7",
      "High": "5300.6",
      "Low": "5257.75",
      "Close": "5275.1"
  },
  {
      "Date": "7/10/12",
      "Open": "5286.6",
      "High": "5348.55",
      "Low": "5284.55",
      "Close": "5345.3"
  },
  {
      "Date": "7/11/12",
      "Open": "5315.25",
      "High": "5336.45",
      "Low": "5300.25",
      "Close": "5306.3"
  },
  {
      "Date": "7/12/12",
      "Open": "5240",
      "High": "5261.75",
      "Low": "5217.7",
      "Close": "5235.2"
  },
  {
      "Date": "7/13/12",
      "Open": "5242.75",
      "High": "5267.15",
      "Low": "5216.85",
      "Close": "5227.2"
  },
  {
      "Date": "7/16/12",
      "Open": "5232.35",
      "High": "5246.85",
      "Low": "5190.45",
      "Close": "5197.2"
  },
  {
      "Date": "7/17/12",
      "Open": "5228.05",
      "High": "5236.7",
      "Low": "5181.7",
      "Close": "5192.8"
  },
  {
      "Date": "7/18/12",
      "Open": "5199.1",
      "High": "5222.85",
      "Low": "5169.05",
      "Close": "5216.3"
  },
  {
      "Date": "7/19/12",
      "Open": "5249.85",
      "High": "5257.75",
      "Low": "5233.15",
      "Close": "5242.7"
  },
  {
      "Date": "7/20/12",
      "Open": "5233.55",
      "High": "5238.7",
      "Low": "5197.5",
      "Close": "5205.1"
  },
  {
      "Date": "7/23/12",
      "Open": "5163.25",
      "High": "5164.2",
      "Low": "5108.1",
      "Close": "5117.9"
  },
  {
      "Date": "7/24/12",
      "Open": "5128.8",
      "High": "5144",
      "Low": "5103.25",
      "Close": "5128.2"
  },
  {
      "Date": "7/25/12",
      "Open": "5118.4",
      "High": "5121.6",
      "Low": "5076.6",
      "Close": "5109.6"
  },
  {
      "Date": "7/26/12",
      "Open": "5126.3",
      "High": "5126.3",
      "Low": "5032.4",
      "Close": "5043"
  },
  {
      "Date": "7/27/12",
      "Open": "5124.3",
      "High": "5149.95",
      "Low": "5077.5",
      "Close": "5099.8"
  },
  {
      "Date": "7/30/12",
      "Open": "5129.75",
      "High": "5206.6",
      "Low": "5129.75",
      "Close": "5199.8"
  },
  {
      "Date": "7/31/12",
      "Open": "5214.85",
      "High": "5234.55",
      "Low": "5154.05",
      "Close": "5229"
  },
  {
      "Date": "8/1/12",
      "Open": "5220.7",
      "High": "5246.35",
      "Low": "5212.65",
      "Close": "5240.5"
  },
  {
      "Date": "8/2/12",
      "Open": "5233.1",
      "High": "5236.9",
      "Low": "5209.95",
      "Close": "5227.7"
  },
  {
      "Date": "8/3/12",
      "Open": "5195.6",
      "High": "5220.2",
      "Low": "5164.65",
      "Close": "5215.7"
  },
  {
      "Date": "8/6/12",
      "Open": "5260.85",
      "High": "5293.2",
      "Low": "5260.85",
      "Close": "5282.5"
  },
  {
      "Date": "8/7/12",
      "Open": "5295.4",
      "High": "5350.1",
      "Low": "5281.65",
      "Close": "5336.7"
  },
  {
      "Date": "8/8/12",
      "Open": "5345.25",
      "High": "5377.6",
      "Low": "5331.05",
      "Close": "5338"
  },
  {
      "Date": "8/9/12",
      "Open": "5348.3",
      "High": "5368.2",
      "Low": "5312.1",
      "Close": "5322.9"
  },
  {
      "Date": "8/10/12",
      "Open": "5308.2",
      "High": "5330.1",
      "Low": "5294.1",
      "Close": "5320.4"
  },
  {
      "Date": "8/13/12",
      "Open": "5316.35",
      "High": "5352.45",
      "Low": "5309.05",
      "Close": "5347.9"
  },
  {
      "Date": "8/14/12",
      "Open": "5343.25",
      "High": "5387.05",
      "Low": "5328.8",
      "Close": "5380.3"
  },
  {
      "Date": "8/16/12",
      "Open": "5385.95",
      "High": "5390.65",
      "Low": "5356.65",
      "Close": "5362.9"
  },
  {
      "Date": "8/17/12",
      "Open": "5368.6",
      "High": "5399.95",
      "Low": "5341.7",
      "Close": "5366.3"
  },
  {
      "Date": "8/21/12",
      "Open": "5368.7",
      "High": "5425.15",
      "Low": "5368.7",
      "Close": "5421"
  },
  {
      "Date": "8/22/12",
      "Open": "5395.75",
      "High": "5433.35",
      "Low": "5394.8",
      "Close": "5412.8"
  },
  {
      "Date": "8/23/12",
      "Open": "5426.15",
      "High": "5448.6",
      "Low": "5393.85",
      "Close": "5415.3"
  },
  {
      "Date": "8/24/12",
      "Open": "5392.6",
      "High": "5399.65",
      "Low": "5371",
      "Close": "5386.7"
  },
  {
      "Date": "8/27/12",
      "Open": "5387.85",
      "High": "5399.15",
      "Low": "5346.65",
      "Close": "5350.2"
  },
  {
      "Date": "8/28/12",
      "Open": "5348.05",
      "High": "5359.25",
      "Low": "5312.6",
      "Close": "5334.6"
  },
  {
      "Date": "8/29/12",
      "Open": "5343.85",
      "High": "5345.5",
      "Low": "5282.7",
      "Close": "5287.8"
  },
  {
      "Date": "8/30/12",
      "Open": "5268.6",
      "High": "5342.8",
      "Low": "5255.05",
      "Close": "5315"
  },
  {
      "Date": "8/31/12",
      "Open": "5298.2",
      "High": "5303.25",
      "Low": "5238.9",
      "Close": "5258.5"
  },
  {
      "Date": "9/3/12",
      "Open": "5276.5",
      "High": "5295.8",
      "Low": "5243.15",
      "Close": "5253.7"
  },
  {
      "Date": "9/4/12",
      "Open": "5249.15",
      "High": "5278.35",
      "Low": "5233.2",
      "Close": "5274"
  },
  {
      "Date": "9/5/12",
      "Open": "5243.9",
      "High": "5259.5",
      "Low": "5215.7",
      "Close": "5225.7"
  },
  {
      "Date": "9/6/12",
      "Open": "5217.65",
      "High": "5260.6",
      "Low": "5217.65",
      "Close": "5238.4"
  },
  {
      "Date": "9/7/12",
      "Open": "5309.45",
      "High": "5347.15",
      "Low": "5309.2",
      "Close": "5342.1"
  },
  {
      "Date": "9/8/12",
      "Open": "5343.65",
      "High": "5366.3",
      "Low": "5343.45",
      "Close": "5358.7"
  },
  {
      "Date": "9/10/12",
      "Open": "5361.9",
      "High": "5375.45",
      "Low": "5349.1",
      "Close": "5363.4"
  },
  {
      "Date": "9/11/12",
      "Open": "5336.1",
      "High": "5393.35",
      "Low": "5332.1",
      "Close": "5390"
  },
  {
      "Date": "9/12/12",
      "Open": "5404.45",
      "High": "5435.55",
      "Low": "5393.95",
      "Close": "5431"
  },
  {
      "Date": "9/13/12",
      "Open": "5435.2",
      "High": "5447.45",
      "Low": "5421.85",
      "Close": "5435.3"
  },
  {
      "Date": "9/14/12",
      "Open": "5528.35",
      "High": "5586.65",
      "Low": "5526.95",
      "Close": "5577.6"
  },
  {
      "Date": "9/17/12",
      "Open": "5631.75",
      "High": "5652.2",
      "Low": "5585.15",
      "Close": "5610"
  },
  {
      "Date": "9/18/12",
      "Open": "5602.4",
      "High": "5620.55",
      "Low": "5586.45",
      "Close": "5600"
  },
  {
      "Date": "9/20/12",
      "Open": "5536.95",
      "High": "5581.35",
      "Low": "5534.9",
      "Close": "5554.2"
  },
  {
      "Date": "9/21/12",
      "Open": "5577",
      "High": "5720",
      "Low": "5575.45",
      "Close": "5691.1"
  },
  {
      "Date": "9/24/12",
      "Open": "5691.95",
      "High": "5709.85",
      "Low": "5662.75",
      "Close": "5669.6"
  },
  {
      "Date": "9/25/12",
      "Open": "5674.9",
      "High": "5702.7",
      "Low": "5652.45",
      "Close": "5673.9"
  },
  {
      "Date": "9/26/12",
      "Open": "5653.4",
      "High": "5672.8",
      "Low": "5638.65",
      "Close": "5663.4"
  },
  {
      "Date": "9/27/12",
      "Open": "5673.75",
      "High": "5693.7",
      "Low": "5639.7",
      "Close": "5649.5"
  },
  {
      "Date": "9/28/12",
      "Open": "5684.8",
      "High": "5735.15",
      "Low": "5683.45",
      "Close": "5703.3"
  },
  {
      "Date": "10/1/12",
      "Open": "5704.75",
      "High": "5722.95",
      "Low": "5694",
      "Close": "5718.8"
  },
  {
      "Date": "10/3/12",
      "Open": "5727.7",
      "High": "5743.25",
      "Low": "5715.8",
      "Close": "5731.2"
  },
  {
      "Date": "10/4/12",
      "Open": "5751.55",
      "High": "5807.25",
      "Low": "5751.35",
      "Close": "5787.6"
  },
  {
      "Date": "10/5/12",
      "Open": "5815",
      "High": "5815.35",
      "Low": "4888.2",
      "Close": "5746.9"
  },
  {
      "Date": "10/8/12",
      "Open": "5751.85",
      "High": "5751.85",
      "Low": "5666.2",
      "Close": "5676"
  },
  {
      "Date": "10/9/12",
      "Open": "5708.15",
      "High": "5728.65",
      "Low": "5677.9",
      "Close": "5704.6"
  },
  {
      "Date": "10/10/12",
      "Open": "5671.15",
      "High": "5686.5",
      "Low": "5647.05",
      "Close": "5652.1"
  },
  {
      "Date": "10/11/12",
      "Open": "5663.5",
      "High": "5721.1",
      "Low": "5636.95",
      "Close": "5708"
  },
  {
      "Date": "10/12/12",
      "Open": "5681.7",
      "High": "5725",
      "Low": "5659.35",
      "Close": "5676"
  },
  {
      "Date": "10/15/12",
      "Open": "5674.25",
      "High": "5693.7",
      "Low": "5651.05",
      "Close": "5687.2"
  },
  {
      "Date": "10/16/12",
      "Open": "5705.6",
      "High": "5714",
      "Low": "5635.6",
      "Close": "5648"
  },
  {
      "Date": "10/17/12",
      "Open": "5681.1",
      "High": "5684.35",
      "Low": "5633.9",
      "Close": "5660.2"
  },
  {
      "Date": "10/18/12",
      "Open": "5675.3",
      "High": "5722.5",
      "Low": "5650.55",
      "Close": "5718.7"
  },
  {
      "Date": "10/19/12",
      "Open": "5703.3",
      "High": "5711.7",
      "Low": "5660",
      "Close": "5684.2"
  },
  {
      "Date": "10/22/12",
      "Open": "5667.6",
      "High": "5721.55",
      "Low": "5658.05",
      "Close": "5717.1"
  },
  {
      "Date": "10/23/12",
      "Open": "5715.65",
      "High": "5720.8",
      "Low": "5681.45",
      "Close": "5691.4"
  },
  {
      "Date": "10/25/12",
      "Open": "5688.8",
      "High": "5718.75",
      "Low": "5685.7",
      "Close": "5705.3"
  },
  {
      "Date": "10/26/12",
      "Open": "5683.55",
      "High": "5697.2",
      "Low": "5641.75",
      "Close": "5664.3"
  },
  {
      "Date": "10/29/12",
      "Open": "5665.2",
      "High": "5698.3",
      "Low": "5645.1",
      "Close": "5665.6"
  },
  {
      "Date": "10/30/12",
      "Open": "5656.35",
      "High": "5689.9",
      "Low": "5589.9",
      "Close": "5597.9"
  },
  {
      "Date": "10/31/12",
      "Open": "5596.75",
      "High": "5624.4",
      "Low": "5583.05",
      "Close": "5619.7"
  },
  {
      "Date": "11/1/12",
      "Open": "5609.85",
      "High": "5649.75",
      "Low": "5601.95",
      "Close": "5645"
  },
  {
      "Date": "11/2/12",
      "Open": "5696.35",
      "High": "5711.3",
      "Low": "5682.55",
      "Close": "5697.7"
  },
  {
      "Date": "11/5/12",
      "Open": "5693.05",
      "High": "5709.2",
      "Low": "5679.5",
      "Close": "5704.2"
  },
  {
      "Date": "11/6/12",
      "Open": "5694.1",
      "High": "5730.8",
      "Low": "5693.65",
      "Close": "5724.4"
  },
  {
      "Date": "11/7/12",
      "Open": "5718.6",
      "High": "5777.3",
      "Low": "5711.4",
      "Close": "5760.1"
  },
  {
      "Date": "11/8/12",
      "Open": "5709",
      "High": "5744.5",
      "Low": "5693.95",
      "Close": "5738.7"
  },
  {
      "Date": "11/9/12",
      "Open": "5731.1",
      "High": "5751.7",
      "Low": "5677.75",
      "Close": "5686.2"
  },
  {
      "Date": "11/12/12",
      "Open": "5688.45",
      "High": "5718.9",
      "Low": "5665.75",
      "Close": "5683.7"
  },
  {
      "Date": "11/13/12",
      "Open": "5689.7",
      "High": "5698.25",
      "Low": "5660.35",
      "Close": "5666.9"
  },
  {
      "Date": "11/15/12",
      "Open": "5650.35",
      "High": "5651.65",
      "Low": "5603.55",
      "Close": "5631"
  },
  {
      "Date": "11/16/12",
      "Open": "5624.8",
      "High": "5650.15",
      "Low": "5559.8",
      "Close": "5574"
  },
  {
      "Date": "11/19/12",
      "Open": "5577.3",
      "High": "5592.75",
      "Low": "5549.25",
      "Close": "5571.4"
  },
  {
      "Date": "11/20/12",
      "Open": "5604.8",
      "High": "5613.7",
      "Low": "5548.35",
      "Close": "5571.5"
  },
  {
      "Date": "11/21/12",
      "Open": "5582.5",
      "High": "5620.2",
      "Low": "5561.4",
      "Close": "5614.8"
  },
  {
      "Date": "11/22/12",
      "Open": "5628.6",
      "High": "5643.35",
      "Low": "5608",
      "Close": "5627.7"
  },
  {
      "Date": "11/23/12",
      "Open": "5635.45",
      "High": "5637.75",
      "Low": "5593.55",
      "Close": "5626.6"
  },
  {
      "Date": "11/26/12",
      "Open": "5648.65",
      "High": "5649.2",
      "Low": "5623.45",
      "Close": "5635.9"
  },
  {
      "Date": "11/27/12",
      "Open": "5658.5",
      "High": "5733.2",
      "Low": "5658",
      "Close": "5727.4"
  },
  {
      "Date": "11/29/12",
      "Open": "5736.7",
      "High": "5833.5",
      "Low": "5736.1",
      "Close": "5825"
  },
  {
      "Date": "11/30/12",
      "Open": "5836",
      "High": "5885.25",
      "Low": "5827.85",
      "Close": "5879.8"
  },
  {
      "Date": "12/3/12",
      "Open": "5878.25",
      "High": "5899.15",
      "Low": "5854.6",
      "Close": "5870.9"
  },
  {
      "Date": "12/4/12",
      "Open": "5866.8",
      "High": "5894.95",
      "Low": "5859",
      "Close": "5889.2"
  },
  {
      "Date": "12/5/12",
      "Open": "5906.6",
      "High": "5917.8",
      "Low": "5891.35",
      "Close": "5900.5"
  },
  {
      "Date": "12/6/12",
      "Open": "5926.3",
      "High": "5942.55",
      "Low": "5838.9",
      "Close": "5930.9"
  },
  {
      "Date": "12/7/12",
      "Open": "5934",
      "High": "5949.85",
      "Low": "5888.65",
      "Close": "5907.4"
  },
  {
      "Date": "12/10/12",
      "Open": "5916.05",
      "High": "5919.95",
      "Low": "5888.1",
      "Close": "5908.9"
  },
  {
      "Date": "12/11/12",
      "Open": "5923.8",
      "High": "5965.15",
      "Low": "5865.45",
      "Close": "5898.8"
  },
  {
      "Date": "12/12/12",
      "Open": "5917.8",
      "High": "5924.6",
      "Low": "5874.25",
      "Close": "5888"
  },
  {
      "Date": "12/13/12",
      "Open": "5900.35",
      "High": "5907.45",
      "Low": "5841.35",
      "Close": "5851.5"
  },
  {
      "Date": "12/14/12",
      "Open": "5846.9",
      "High": "5886.1",
      "Low": "5839.15",
      "Close": "5879.6"
  },
  {
      "Date": "12/17/12",
      "Open": "5860.5",
      "High": "5886.05",
      "Low": "5850.15",
      "Close": "5857.9"
  },
  {
      "Date": "12/18/12",
      "Open": "5873.6",
      "High": "5905.8",
      "Low": "5823.15",
      "Close": "5896.8"
  },
  {
      "Date": "12/19/12",
      "Open": "5917.3",
      "High": "5939.4",
      "Low": "5910.8",
      "Close": "5929.6"
  },
  {
      "Date": "12/20/12",
      "Open": "5934.45",
      "High": "5937.6",
      "Low": "5881.45",
      "Close": "5916.4"
  },
  {
      "Date": "12/21/12",
      "Open": "5888",
      "High": "5888",
      "Low": "5841.65",
      "Close": "5847.7"
  },
  {
      "Date": "12/24/12",
      "Open": "5869",
      "High": "5871.9",
      "Low": "5844.7",
      "Close": "5855.7"
  },
  {
      "Date": "12/26/12",
      "Open": "5864.95",
      "High": "5917.3",
      "Low": "5859.55",
      "Close": "5905.6"
  },
  {
      "Date": "12/27/12",
      "Open": "5930.2",
      "High": "5930.8",
      "Low": "5864.7",
      "Close": "5870.1"
  },
  {
      "Date": "12/28/12",
      "Open": "5887.15",
      "High": "5915.75",
      "Low": "5879.5",
      "Close": "5908.3"
  },
  {
      "Date": "12/31/12",
      "Open": "5901.2",
      "High": "5919",
      "Low": "5897.15",
      "Close": "5905.1"
  },
  {
      "Date": "1/1/13",
      "Open": "5937.65",
      "High": "5963.9",
      "Low": "5935.2",
      "Close": "5950.8"
  },
  {
      "Date": "1/2/13",
      "Open": "5982.6",
      "High": "6006.05",
      "Low": "5982",
      "Close": "5993.2"
  },
  {
      "Date": "1/3/13",
      "Open": "6015.8",
      "High": "6017",
      "Low": "5986.55",
      "Close": "6009.5"
  },
  {
      "Date": "1/4/13",
      "Open": "6011.95",
      "High": "6020.75",
      "Low": "5981.55",
      "Close": "6016.1"
  },
  {
      "Date": "1/7/13",
      "Open": "6042.15",
      "High": "6042.15",
      "Low": "5977.15",
      "Close": "5988.4"
  },
  {
      "Date": "1/8/13",
      "Open": "5983.45",
      "High": "6007.05",
      "Low": "5964.4",
      "Close": "6001.7"
  },
  {
      "Date": "1/9/13",
      "Open": "6006.2",
      "High": "6020.1",
      "Low": "5958.45",
      "Close": "5971.5"
  },
  {
      "Date": "1/10/13",
      "Open": "5998.8",
      "High": "6005.15",
      "Low": "5947.3",
      "Close": "5968.6"
  },
  {
      "Date": "1/11/13",
      "Open": "6012.4",
      "High": "6018.85",
      "Low": "5940.6",
      "Close": "5951.3"
  },
  {
      "Date": "1/14/13",
      "Open": "5967.2",
      "High": "6036.9",
      "Low": "5962.15",
      "Close": "6024"
  },
  {
      "Date": "1/15/13",
      "Open": "6037.85",
      "High": "6068.5",
      "Low": "6018.6",
      "Close": "6056.6"
  },
  {
      "Date": "1/16/13",
      "Open": "6049",
      "High": "6055.95",
      "Low": "5992.05",
      "Close": "6001.8"
  },
  {
      "Date": "1/17/13",
      "Open": "6001.25",
      "High": "6053.2",
      "Low": "5988.1",
      "Close": "6039.2"
  },
  {
      "Date": "1/18/13",
      "Open": "6059.85",
      "High": "6083.4",
      "Low": "6048.3",
      "Close": "6064.4"
  },
  {
      "Date": "1/21/13",
      "Open": "6085.75",
      "High": "6094.35",
      "Low": "6065.1",
      "Close": "6082.3"
  },
  {
      "Date": "1/22/13",
      "Open": "6080.15",
      "High": "6101.3",
      "Low": "6040.5",
      "Close": "6048.5"
  },
  {
      "Date": "1/23/13",
      "Open": "6052.85",
      "High": "6069.8",
      "Low": "6021.15",
      "Close": "6054.3"
  },
  {
      "Date": "1/24/13",
      "Open": "6046.2",
      "High": "6065.3",
      "Low": "6007.85",
      "Close": "6019.3"
  },
  {
      "Date": "1/25/13",
      "Open": "6024.5",
      "High": "6080.55",
      "Low": "6014.45",
      "Close": "6074.6"
  },
  {
      "Date": "1/28/13",
      "Open": "6082.1",
      "High": "6088.4",
      "Low": "6061.4",
      "Close": "6074.8"
  },
  {
      "Date": "1/29/13",
      "Open": "6064.7",
      "High": "6111.8",
      "Low": "6042.45",
      "Close": "6049.9"
  },
  {
      "Date": "1/30/13",
      "Open": "6065",
      "High": "6071.95",
      "Low": "6044.15",
      "Close": "6055.7"
  },
  {
      "Date": "1/31/13",
      "Open": "6045.65",
      "High": "6058.05",
      "Low": "6025.15",
      "Close": "6034.7"
  },
  {
      "Date": "2/1/13",
      "Open": "6040.95",
      "High": "6052.95",
      "Low": "5983.2",
      "Close": "5998.9"
  },
  {
      "Date": "2/4/13",
      "Open": "6025.2",
      "High": "6038.5",
      "Low": "5981.25",
      "Close": "5987.2"
  },
  {
      "Date": "2/5/13",
      "Open": "5948.2",
      "High": "5970.35",
      "Low": "5946.9",
      "Close": "5956.9"
  },
  {
      "Date": "2/6/13",
      "Open": "5988.05",
      "High": "5990.9",
      "Low": "5953.15",
      "Close": "5959.2"
  },
  {
      "Date": "2/7/13",
      "Open": "5936.45",
      "High": "5978.5",
      "Low": "5927.6",
      "Close": "5938.8"
  },
  {
      "Date": "2/8/13",
      "Open": "5929.1",
      "High": "5953.7",
      "Low": "5883.65",
      "Close": "5903.5"
  },
  {
      "Date": "2/11/13",
      "Open": "5920.05",
      "High": "5924.15",
      "Low": "5879.1",
      "Close": "5897.8"
  },
  {
      "Date": "2/12/13",
      "Open": "5894.35",
      "High": "5927.65",
      "Low": "5886.45",
      "Close": "5922.5"
  },
  {
      "Date": "2/13/13",
      "Open": "5943.15",
      "High": "5969.5",
      "Low": "5922.95",
      "Close": "5932.9"
  },
  {
      "Date": "2/14/13",
      "Open": "5933.2",
      "High": "5940.2",
      "Low": "5884.55",
      "Close": "5896.9"
  },
  {
      "Date": "2/15/13",
      "Open": "5869.95",
      "High": "5899.95",
      "Low": "5853.9",
      "Close": "5887.4"
  },
  {
      "Date": "2/18/13",
      "Open": "5888.65",
      "High": "5911",
      "Low": "5878.45",
      "Close": "5898.2"
  },
  {
      "Date": "2/19/13",
      "Open": "5900.2",
      "High": "5947.55",
      "Low": "5883.15",
      "Close": "5939.7"
  },
  {
      "Date": "2/20/13",
      "Open": "5966.3",
      "High": "5971",
      "Low": "5937.55",
      "Close": "5943"
  },
  {
      "Date": "2/21/13",
      "Open": "5909.65",
      "High": "5921.15",
      "Low": "5844.4",
      "Close": "5852.2"
  },
  {
      "Date": "2/22/13",
      "Open": "5837.95",
      "High": "5873.8",
      "Low": "5835.8",
      "Close": "5850.3"
  },
  {
      "Date": "2/25/13",
      "Open": "5870.55",
      "High": "5878.4",
      "Low": "5825",
      "Close": "5854.7"
  },
  {
      "Date": "2/26/13",
      "Open": "5838.3",
      "High": "5838.85",
      "Low": "5748.6",
      "Close": "5761.3"
  },
  {
      "Date": "2/27/13",
      "Open": "5784.9",
      "High": "5818.2",
      "Low": "5749.7",
      "Close": "5796.9"
  },
  {
      "Date": "2/28/13",
      "Open": "5834.35",
      "High": "5849.9",
      "Low": "5671.9",
      "Close": "5693"
  },
  {
      "Date": "3/1/13",
      "Open": "5702.45",
      "High": "5739.45",
      "Low": "5679.9",
      "Close": "5719.7"
  },
  {
      "Date": "3/4/13",
      "Open": "5704.7",
      "High": "5712",
      "Low": "5663.6",
      "Close": "5698.5"
  },
  {
      "Date": "3/5/13",
      "Open": "5722.45",
      "High": "5790.1",
      "Low": "5722.4",
      "Close": "5784.2"
  },
  {
      "Date": "3/6/13",
      "Open": "5816.4",
      "High": "5828.7",
      "Low": "5795.05",
      "Close": "5818.6"
  },
  {
      "Date": "3/7/13",
      "Open": "5801.3",
      "High": "5878",
      "Low": "5801.3",
      "Close": "5863.3"
  },
  {
      "Date": "3/8/13",
      "Open": "5883.65",
      "High": "5952.85",
      "Low": "5883",
      "Close": "5945.7"
  },
  {
      "Date": "3/11/13",
      "Open": "5946.1",
      "High": "5971.2",
      "Low": "5930.35",
      "Close": "5942.3"
  },
  {
      "Date": "3/12/13",
      "Open": "5944.6",
      "High": "5952",
      "Low": "5893.65",
      "Close": "5914.1"
  },
  {
      "Date": "3/13/13",
      "Open": "5884.8",
      "High": "5893.85",
      "Low": "5842.25",
      "Close": "5851.2"
  },
  {
      "Date": "3/14/13",
      "Open": "5845.95",
      "High": "5920.15",
      "Low": "5791.75",
      "Close": "5908.9"
  },
  {
      "Date": "3/15/13",
      "Open": "5914.9",
      "High": "5945.65",
      "Low": "5861",
      "Close": "5872.6"
  },
  {
      "Date": "3/18/13",
      "Open": "5816.75",
      "High": "5850.2",
      "Low": "5814.35",
      "Close": "5835.2"
  },
  {
      "Date": "3/19/13",
      "Open": "5859.5",
      "High": "5863.6",
      "Low": "5724.3",
      "Close": "5745.9"
  },
  {
      "Date": "3/20/13",
      "Open": "5740.55",
      "High": "5745.3",
      "Low": "5682.3",
      "Close": "5694.4"
  },
  {
      "Date": "3/21/13",
      "Open": "5705.9",
      "High": "5757.75",
      "Low": "5647.95",
      "Close": "5658.7"
  },
  {
      "Date": "3/22/13",
      "Open": "5659.8",
      "High": "5691.45",
      "Low": "5631.8",
      "Close": "5651.3"
  },
  {
      "Date": "3/25/13",
      "Open": "5707.3",
      "High": "5718.4",
      "Low": "5624.4",
      "Close": "5633.8"
  },
  {
      "Date": "3/26/13",
      "Open": "5613.75",
      "High": "5655.3",
      "Low": "5612.05",
      "Close": "5641.6"
  },
  {
      "Date": "3/28/13",
      "Open": "5647.75",
      "High": "5692.95",
      "Low": "5604.85",
      "Close": "5682.5"
  },
  {
      "Date": "4/1/13",
      "Open": "5697.35",
      "High": "5720.95",
      "Low": "5675.9",
      "Close": "5704.4"
  },
  {
      "Date": "4/2/13",
      "Open": "5701.7",
      "High": "5754.6",
      "Low": "5687.15",
      "Close": "5748.1"
  },
  {
      "Date": "4/3/13",
      "Open": "5740.2",
      "High": "5744.95",
      "Low": "5650.1",
      "Close": "5672.9"
  },
  {
      "Date": "4/4/13",
      "Open": "5640.65",
      "High": "5644.45",
      "Low": "5565.65",
      "Close": "5574.7"
  },
  {
      "Date": "4/5/13",
      "Open": "5568.1",
      "High": "5577.3",
      "Low": "5534.7",
      "Close": "5553.2"
  },
  {
      "Date": "4/8/13",
      "Open": "5550.5",
      "High": "5569.2",
      "Low": "5537.05",
      "Close": "5542.9"
  },
  {
      "Date": "4/9/13",
      "Open": "5568.75",
      "High": "5603.05",
      "Low": "5487",
      "Close": "5495.1"
  },
  {
      "Date": "4/10/13",
      "Open": "5536.25",
      "High": "5569.25",
      "Low": "5477.2",
      "Close": "5558.7"
  },
  {
      "Date": "4/11/13",
      "Open": "5601.65",
      "High": "5610.65",
      "Low": "5542.85",
      "Close": "5594"
  },
  {
      "Date": "4/12/13",
      "Open": "5520.7",
      "High": "5544.5",
      "Low": "5494.9",
      "Close": "5528.5"
  },
  {
      "Date": "4/15/13",
      "Open": "5508.5",
      "High": "5592.85",
      "Low": "5500.3",
      "Close": "5568.4"
  },
  {
      "Date": "4/16/13",
      "Open": "5562.45",
      "High": "5699.25",
      "Low": "5555.85",
      "Close": "5688.9"
  },
  {
      "Date": "4/17/13",
      "Open": "5708.65",
      "High": "5732.15",
      "Low": "5669",
      "Close": "5688.7"
  },
  {
      "Date": "4/18/13",
      "Open": "5682.7",
      "High": "5794.35",
      "Low": "5681.85",
      "Close": "5783.1"
  },
  {
      "Date": "4/22/13",
      "Open": "5789.85",
      "High": "5844.85",
      "Low": "5789.8",
      "Close": "5834.4"
  },
  {
      "Date": "4/23/13",
      "Open": "5843.1",
      "High": "5844.3",
      "Low": "5791.55",
      "Close": "5836.9"
  },
  {
      "Date": "4/25/13",
      "Open": "5856.1",
      "High": "5924.6",
      "Low": "5853.3",
      "Close": "5916.3"
  },
  {
      "Date": "4/26/13",
      "Open": "5899.75",
      "High": "5907.05",
      "Low": "5860.5",
      "Close": "5871.4"
  },
  {
      "Date": "4/29/13",
      "Open": "5877.6",
      "High": "5918.65",
      "Low": "5868.8",
      "Close": "5904.1"
  },
  {
      "Date": "4/30/13",
      "Open": "5932.6",
      "High": "5962.3",
      "Low": "5867.8",
      "Close": "5930.2"
  },
  {
      "Date": "5/2/13",
      "Open": "5911.4",
      "High": "6019.45",
      "Low": "5910.95",
      "Close": "5999.3"
  },
  {
      "Date": "5/3/13",
      "Open": "5993.5",
      "High": "6000.3",
      "Low": "5930.15",
      "Close": "5944"
  },
  {
      "Date": "5/6/13",
      "Open": "5944.9",
      "High": "5976.5",
      "Low": "5928.45",
      "Close": "5971"
  },
  {
      "Date": "5/7/13",
      "Open": "5983.45",
      "High": "6050.5",
      "Low": "5982.95",
      "Close": "6043.5"
  },
  {
      "Date": "5/8/13",
      "Open": "6064.15",
      "High": "6083.55",
      "Low": "6024.95",
      "Close": "6069.3"
  },
  {
      "Date": "5/9/13",
      "Open": "6078.35",
      "High": "6084.7",
      "Low": "6040.45",
      "Close": "6050.1"
  },
  {
      "Date": "5/10/13",
      "Open": "6046.25",
      "High": "6105.3",
      "Low": "6045.6",
      "Close": "6094.7"
  },
  {
      "Date": "5/11/13",
      "Open": "6088.2",
      "High": "6114.55",
      "Low": "6084.15",
      "Close": "6107.2"
  },
  {
      "Date": "5/13/13",
      "Open": "6098.2",
      "High": "6104.95",
      "Low": "5972.9",
      "Close": "5980.4"
  },
  {
      "Date": "5/14/13",
      "Open": "5989.7",
      "High": "6026.2",
      "Low": "5970.05",
      "Close": "5995.4"
  },
  {
      "Date": "5/15/13",
      "Open": "6018.85",
      "High": "6157.1",
      "Low": "6018.85",
      "Close": "6146.7"
  },
  {
      "Date": "5/16/13",
      "Open": "6128.25",
      "High": "6187.3",
      "Low": "6128.25",
      "Close": "6169.9"
  },
  {
      "Date": "5/17/13",
      "Open": "6172.95",
      "High": "6199.95",
      "Low": "6146.15",
      "Close": "6187.3"
  },
  {
      "Date": "5/20/13",
      "Open": "6198",
      "High": "6229.45",
      "Low": "6146.05",
      "Close": "6156.9"
  },
  {
      "Date": "5/21/13",
      "Open": "6152.35",
      "High": "6180.25",
      "Low": "6102.35",
      "Close": "6114.1"
  },
  {
      "Date": "5/22/13",
      "Open": "6127.05",
      "High": "6147.6",
      "Low": "6074.45",
      "Close": "6094.5"
  },
  {
      "Date": "5/23/13",
      "Open": "6050.4",
      "High": "6081.45",
      "Low": "5955.7",
      "Close": "5967"
  },
  {
      "Date": "5/24/13",
      "Open": "6010.7",
      "High": "6015.3",
      "Low": "5936.8",
      "Close": "5983.5"
  },
  {
      "Date": "5/27/13",
      "Open": "5989.4",
      "High": "6099.9",
      "Low": "5975.55",
      "Close": "6083.1"
  },
  {
      "Date": "5/28/13",
      "Open": "6086.35",
      "High": "6127.65",
      "Low": "6055.4",
      "Close": "6111.2"
  },
  {
      "Date": "5/29/13",
      "Open": "6120.45",
      "High": "6125.05",
      "Low": "6069.8",
      "Close": "6104.3"
  },
  {
      "Date": "5/30/13",
      "Open": "6072.15",
      "High": "6133.75",
      "Low": "6072.15",
      "Close": "6124"
  },
  {
      "Date": "5/31/13",
      "Open": "6098.7",
      "High": "6106.25",
      "Low": "5975.55",
      "Close": "5985.9"
  },
  {
      "Date": "6/3/13",
      "Open": "5997.35",
      "High": "6011",
      "Low": "5916.35",
      "Close": "5939.3"
  },
  {
      "Date": "6/4/13",
      "Open": "5941.1",
      "High": "5981.6",
      "Low": "5910.25",
      "Close": "5919.4"
  },
  {
      "Date": "6/5/13",
      "Open": "5908.3",
      "High": "5935.2",
      "Low": "5883.7",
      "Close": "5923.8"
  },
  {
      "Date": "6/6/13",
      "Open": "5895",
      "High": "5956.55",
      "Low": "5869.5",
      "Close": "5921.4"
  },
  {
      "Date": "6/7/13",
      "Open": "5900.05",
      "High": "5972.7",
      "Low": "5871.3",
      "Close": "5881"
  },
  {
      "Date": "6/10/13",
      "Open": "5907.9",
      "High": "5931.65",
      "Low": "5857.4",
      "Close": "5878"
  },
  {
      "Date": "6/11/13",
      "Open": "5848.75",
      "High": "5868.05",
      "Low": "5780.35",
      "Close": "5788.8"
  },
  {
      "Date": "6/12/13",
      "Open": "5771.75",
      "High": "5792.9",
      "Low": "5738.6",
      "Close": "5760.2"
  },
  {
      "Date": "6/13/13",
      "Open": "5709.35",
      "High": "5729.85",
      "Low": "5683.1",
      "Close": "5699.1"
  },
  {
      "Date": "6/14/13",
      "Open": "5748.95",
      "High": "5819.4",
      "Low": "5739.4",
      "Close": "5808.4"
  },
  {
      "Date": "6/17/13",
      "Open": "5820.4",
      "High": "5854.9",
      "Low": "5770.25",
      "Close": "5850"
  },
  {
      "Date": "6/18/13",
      "Open": "5841.9",
      "High": "5863.4",
      "Low": "5804.3",
      "Close": "5813.6"
  },
  {
      "Date": "6/19/13",
      "Open": "5805.75",
      "High": "5828.4",
      "Low": "5777.9",
      "Close": "5822.2"
  },
  {
      "Date": "6/20/13",
      "Open": "5754.15",
      "High": "5755",
      "Low": "5645.65",
      "Close": "5655.9"
  },
  {
      "Date": "6/21/13",
      "Open": "5639.9",
      "High": "5686.15",
      "Low": "5616.85",
      "Close": "5667.6"
  },
  {
      "Date": "6/24/13",
      "Open": "5638.05",
      "High": "5640",
      "Low": "5566.25",
      "Close": "5590.2"
  },
  {
      "Date": "6/25/13",
      "Open": "5606.95",
      "High": "5666.25",
      "Low": "5570.25",
      "Close": "5609.1"
  },
  {
      "Date": "6/26/13",
      "Open": "5627.95",
      "High": "5635.25",
      "Low": "5579.35",
      "Close": "5588.7"
  },
  {
      "Date": "6/27/13",
      "Open": "5647.95",
      "High": "5699.35",
      "Low": "5630.95",
      "Close": "5682.3"
  },
  {
      "Date": "6/28/13",
      "Open": "5749.5",
      "High": "5852.95",
      "Low": "5749.5",
      "Close": "5842.2"
  },
  {
      "Date": "7/1/13",
      "Open": "5834.1",
      "High": "5904.35",
      "Low": "5822.2",
      "Close": "5898.8"
  },
  {
      "Date": "7/2/13",
      "Open": "5885.5",
      "High": "5898.8",
      "Low": "5852.3",
      "Close": "5857.5"
  },
  {
      "Date": "7/3/13",
      "Open": "5811.95",
      "High": "5815",
      "Low": "5760.4",
      "Close": "5770.9"
  },
  {
      "Date": "7/4/13",
      "Open": "5794.75",
      "High": "5848.2",
      "Low": "5786.05",
      "Close": "5836.9"
  },
  {
      "Date": "7/5/13",
      "Open": "5889.95",
      "High": "5900.45",
      "Low": "5858.45",
      "Close": "5867.9"
  },
  {
      "Date": "7/8/13",
      "Open": "5833.15",
      "High": "5833.85",
      "Low": "5775.55",
      "Close": "5811.5"
  },
  {
      "Date": "7/9/13",
      "Open": "5834.6",
      "High": "5864.95",
      "Low": "5834.6",
      "Close": "5859"
  },
  {
      "Date": "7/10/13",
      "Open": "5869.9",
      "High": "5879.35",
      "Low": "5802.85",
      "Close": "5816.7"
  },
  {
      "Date": "7/11/13",
      "Open": "5894.5",
      "High": "5948.85",
      "Low": "5887.95",
      "Close": "5935.1"
  },
  {
      "Date": "7/12/13",
      "Open": "6000.5",
      "High": "6019",
      "Low": "5951.15",
      "Close": "6009"
  },
  {
      "Date": "7/15/13",
      "Open": "5991.2",
      "High": "6038.2",
      "Low": "5980.95",
      "Close": "6030.8"
  },
  {
      "Date": "7/16/13",
      "Open": "5930.8",
      "High": "5966.05",
      "Low": "5910.95",
      "Close": "5955.2"
  },
  {
      "Date": "7/17/13",
      "Open": "5972.25",
      "High": "5989.8",
      "Low": "5926.75",
      "Close": "5973.3"
  },
  {
      "Date": "7/18/13",
      "Open": "5984.7",
      "High": "6051.1",
      "Low": "5974.55",
      "Close": "6038"
  },
  {
      "Date": "7/19/13",
      "Open": "6057.2",
      "High": "6066.85",
      "Low": "6020.25",
      "Close": "6029.2"
  },
  {
      "Date": "7/22/13",
      "Open": "6009.75",
      "High": "6064.15",
      "Low": "6004.25",
      "Close": "6031.8"
  },
  {
      "Date": "7/23/13",
      "Open": "6064.3",
      "High": "6093.35",
      "Low": "6061.3",
      "Close": "6077.8"
  },
  {
      "Date": "7/24/13",
      "Open": "6032.2",
      "High": "6047.25",
      "Low": "5962.6",
      "Close": "5990.5"
  },
  {
      "Date": "7/25/13",
      "Open": "5970.4",
      "High": "5990.65",
      "Low": "5896.4",
      "Close": "5907.5"
  },
  {
      "Date": "7/26/13",
      "Open": "5937.95",
      "High": "5944.5",
      "Low": "5869.5",
      "Close": "5886.2"
  },
  {
      "Date": "7/29/13",
      "Open": "5869.95",
      "High": "5886",
      "Low": "5825.8",
      "Close": "5831.6"
  },
  {
      "Date": "7/30/13",
      "Open": "5836.05",
      "High": "5861.3",
      "Low": "5747.6",
      "Close": "5755"
  },
  {
      "Date": "7/31/13",
      "Open": "5738.35",
      "High": "5752.1",
      "Low": "5675.75",
      "Close": "5742"
  },
  {
      "Date": "8/1/13",
      "Open": "5776.9",
      "High": "5808.5",
      "Low": "5676.85",
      "Close": "5727.8"
  },
  {
      "Date": "8/2/13",
      "Open": "5750.05",
      "High": "5761.85",
      "Low": "5649",
      "Close": "5677.9"
  },
  {
      "Date": "8/5/13",
      "Open": "5682.4",
      "High": "5721",
      "Low": "5661.5",
      "Close": "5685.4"
  },
  {
      "Date": "8/6/13",
      "Open": "5664.9",
      "High": "5664.9",
      "Low": "5521.8",
      "Close": "5542.2"
  },
  {
      "Date": "8/7/13",
      "Open": "5549.3",
      "High": "5561.45",
      "Low": "5486.85",
      "Close": "5519.1"
  },
  {
      "Date": "8/8/13",
      "Open": "5510.05",
      "High": "5577.6",
      "Low": "5510.05",
      "Close": "5565.6"
  },
  {
      "Date": "8/12/13",
      "Open": "5606.7",
      "High": "5644.1",
      "Low": "5557.1",
      "Close": "5612.4"
  },
  {
      "Date": "8/13/13",
      "Open": "5600.25",
      "High": "5704.75",
      "Low": "5578.9",
      "Close": "5699.3"
  },
  {
      "Date": "8/14/13",
      "Open": "5715.4",
      "High": "5754.55",
      "Low": "5690.2",
      "Close": "5742.3"
  },
  {
      "Date": "8/16/13",
      "Open": "5705.45",
      "High": "5716.6",
      "Low": "5496.05",
      "Close": "5507.8"
  },
  {
      "Date": "8/19/13",
      "Open": "5497.55",
      "High": "5499.65",
      "Low": "5360.65",
      "Close": "5414.7"
  },
  {
      "Date": "8/20/13",
      "Open": "5353.45",
      "High": "5417.8",
      "Low": "5306.35",
      "Close": "5401.4"
  },
  {
      "Date": "8/21/13",
      "Open": "5494.45",
      "High": "5504.1",
      "Low": "5268.45",
      "Close": "5302.5"
  },
  {
      "Date": "8/22/13",
      "Open": "5282.8",
      "High": "5418.95",
      "Low": "5254.05",
      "Close": "5408.4"
  },
  {
      "Date": "8/23/13",
      "Open": "5428.75",
      "High": "5478.8",
      "Low": "5377.8",
      "Close": "5471.7"
  },
  {
      "Date": "8/26/13",
      "Open": "5499.4",
      "High": "5528.7",
      "Low": "5454.45",
      "Close": "5476.5"
  },
  {
      "Date": "8/27/13",
      "Open": "5426.5",
      "High": "5427.4",
      "Low": "5274.25",
      "Close": "5287.4"
  },
  {
      "Date": "8/28/13",
      "Open": "5233.45",
      "High": "5317.7",
      "Low": "5118.85",
      "Close": "5285"
  },
  {
      "Date": "8/29/13",
      "Open": "5316.5",
      "High": "5428.9",
      "Low": "5303",
      "Close": "5409"
  },
  {
      "Date": "8/30/13",
      "Open": "5407.45",
      "High": "5493.3",
      "Low": "5360.2",
      "Close": "5471.8"
  },
  {
      "Date": "9/2/13",
      "Open": "5480.25",
      "High": "5564.9",
      "Low": "5478.85",
      "Close": "5550.7"
  },
  {
      "Date": "9/3/13",
      "Open": "5574.7",
      "High": "5580.95",
      "Low": "5323.75",
      "Close": "5341.4"
  },
  {
      "Date": "9/4/13",
      "Open": "5358.65",
      "High": "5460.25",
      "Low": "5318.9",
      "Close": "5448.1"
  },
  {
      "Date": "9/5/13",
      "Open": "5553.75",
      "High": "5625.75",
      "Low": "5552.7",
      "Close": "5592.9"
  },
  {
      "Date": "9/6/13",
      "Open": "5617.45",
      "High": "5688.6",
      "Low": "5566.15",
      "Close": "5680.4"
  },
  {
      "Date": "9/10/13",
      "Open": "5738.5",
      "High": "5904.85",
      "Low": "5738.2",
      "Close": "5896.7"
  },
  {
      "Date": "9/11/13",
      "Open": "5887.25",
      "High": "5924.35",
      "Low": "5832.7",
      "Close": "5913.1"
  },
  {
      "Date": "9/12/13",
      "Open": "5931.15",
      "High": "5932",
      "Low": "5815.8",
      "Close": "5850.7"
  },
  {
      "Date": "9/13/13",
      "Open": "5828",
      "High": "5884.3",
      "Low": "5822.9",
      "Close": "5850.6"
  },
  {
      "Date": "9/16/13",
      "Open": "5930.3",
      "High": "5957.25",
      "Low": "5798.15",
      "Close": "5840.5"
  },
  {
      "Date": "9/17/13",
      "Open": "5824.2",
      "High": "5857.8",
      "Low": "5804.9",
      "Close": "5850.2"
  },
  {
      "Date": "9/18/13",
      "Open": "5872.75",
      "High": "5916.9",
      "Low": "5840.2",
      "Close": "5899.4"
  },
  {
      "Date": "9/19/13",
      "Open": "6044.15",
      "High": "6142.5",
      "Low": "6040.15",
      "Close": "6115.5"
  },
  {
      "Date": "9/20/13",
      "Open": "6104.55",
      "High": "6130.95",
      "Low": "5932.85",
      "Close": "6012.1"
  },
  {
      "Date": "9/23/13",
      "Open": "5945.8",
      "High": "5989.4",
      "Low": "5871.4",
      "Close": "5889.7"
  },
  {
      "Date": "9/24/13",
      "Open": "5855",
      "High": "5938.4",
      "Low": "5854.55",
      "Close": "5892.4"
  },
  {
      "Date": "9/25/13",
      "Open": "5901.55",
      "High": "5910.55",
      "Low": "5811.1",
      "Close": "5873.8"
  },
  {
      "Date": "9/26/13",
      "Open": "5872.8",
      "High": "5917.65",
      "Low": "5864.1",
      "Close": "5882.2"
  },
  {
      "Date": "9/27/13",
      "Open": "5905.55",
      "High": "5909.2",
      "Low": "5819.3",
      "Close": "5833.2"
  },
  {
      "Date": "9/30/13",
      "Open": "5801.05",
      "High": "5810.2",
      "Low": "5718.5",
      "Close": "5735.3"
  },
  {
      "Date": "10/1/13",
      "Open": "5756.1",
      "High": "5786.45",
      "Low": "5700.95",
      "Close": "5780"
  },
  {
      "Date": "10/3/13",
      "Open": "5819.1",
      "High": "5917.6",
      "Low": "5802.7",
      "Close": "5909.7"
  },
  {
      "Date": "10/4/13",
      "Open": "5891.3",
      "High": "5950.45",
      "Low": "5885",
      "Close": "5907.3"
  },
  {
      "Date": "10/7/13",
      "Open": "5889.05",
      "High": "5912",
      "Low": "5825.85",
      "Close": "5906.1"
  },
  {
      "Date": "10/8/13",
      "Open": "5975",
      "High": "5981.7",
      "Low": "5913",
      "Close": "5928.4"
  },
  {
      "Date": "10/9/13",
      "Open": "5893.25",
      "High": "6015.5",
      "Low": "5877.1",
      "Close": "6007.4"
  },
  {
      "Date": "10/10/13",
      "Open": "6001.05",
      "High": "6033.95",
      "Low": "5979.8",
      "Close": "6020.9"
  },
  {
      "Date": "10/11/13",
      "Open": "6104.85",
      "High": "6107.6",
      "Low": "6046.4",
      "Close": "6096.2"
  },
  {
      "Date": "10/14/13",
      "Open": "6093",
      "High": "6124.1",
      "Low": "6082.9",
      "Close": "6112.7"
  },
  {
      "Date": "10/15/13",
      "Open": "6147.55",
      "High": "6156.3",
      "Low": "6056.55",
      "Close": "6089"
  },
  {
      "Date": "10/17/13",
      "Open": "6098.5",
      "High": "6110.75",
      "Low": "6032.55",
      "Close": "6045.8"
  },
  {
      "Date": "10/18/13",
      "Open": "6070.9",
      "High": "6201.45",
      "Low": "6070.9",
      "Close": "6189.3"
  },
  {
      "Date": "10/21/13",
      "Open": "6202",
      "High": "6218.95",
      "Low": "6163.3",
      "Close": "6204.9"
  },
  {
      "Date": "10/22/13",
      "Open": "6192.3",
      "High": "6220.1",
      "Low": "6181.8",
      "Close": "6202.8"
  },
  {
      "Date": "10/23/13",
      "Open": "6209.55",
      "High": "6217.95",
      "Low": "6116.6",
      "Close": "6178.3"
  },
  {
      "Date": "10/24/13",
      "Open": "6162.8",
      "High": "6252.45",
      "Low": "6142.95",
      "Close": "6164.3"
  },
  {
      "Date": "10/25/13",
      "Open": "6154",
      "High": "6174.75",
      "Low": "6125.95",
      "Close": "6144.9"
  },
  {
      "Date": "10/28/13",
      "Open": "6155.1",
      "High": "6168.75",
      "Low": "6094.1",
      "Close": "6101.1"
  },
  {
      "Date": "10/29/13",
      "Open": "6107.55",
      "High": "6228.05",
      "Low": "6079.2",
      "Close": "6220.9"
  },
  {
      "Date": "10/30/13",
      "Open": "6230.8",
      "High": "6269.2",
      "Low": "6222.6",
      "Close": "6251.7"
  },
  {
      "Date": "10/31/13",
      "Open": "6237.15",
      "High": "6309.05",
      "Low": "6235.9",
      "Close": "6299.1"
  },
  {
      "Date": "11/1/13",
      "Open": "6289.75",
      "High": "6332.6",
      "Low": "6286.95",
      "Close": "6307.2"
  },
  {
      "Date": "11/3/13",
      "Open": "6332.05",
      "High": "6342.95",
      "Low": "6311.15",
      "Close": "6317.3"
  },
  {
      "Date": "11/5/13",
      "Open": "6282.15",
      "High": "6304.75",
      "Low": "6244.3",
      "Close": "6253.1"
  },
  {
      "Date": "11/6/13",
      "Open": "6260.55",
      "High": "6269.7",
      "Low": "6208.7",
      "Close": "6215.1"
  },
  {
      "Date": "11/7/13",
      "Open": "6228.9",
      "High": "6288.95",
      "Low": "6180.8",
      "Close": "6187.2"
  },
  {
      "Date": "11/8/13",
      "Open": "6170.15",
      "High": "6185.15",
      "Low": "6120.95",
      "Close": "6140.7"
  },
  {
      "Date": "11/11/13",
      "Open": "6110.4",
      "High": "6141.65",
      "Low": "6067.75",
      "Close": "6078.8"
  },
  {
      "Date": "11/12/13",
      "Open": "6087.25",
      "High": "6108.7",
      "Low": "6011.75",
      "Close": "6018"
  },
  {
      "Date": "11/13/13",
      "Open": "5998.85",
      "High": "6042.25",
      "Low": "5972.45",
      "Close": "5989.6"
  },
  {
      "Date": "11/14/13",
      "Open": "6037",
      "High": "6101.65",
      "Low": "6036.65",
      "Close": "6056.1"
  },
  {
      "Date": "11/18/13",
      "Open": "6111.05",
      "High": "6196.8",
      "Low": "6110.4",
      "Close": "6189"
  },
  {
      "Date": "11/19/13",
      "Open": "6197.25",
      "High": "6212.4",
      "Low": "6180.2",
      "Close": "6203.3"
  },
  {
      "Date": "11/20/13",
      "Open": "6186.85",
      "High": "6204.35",
      "Low": "6106.95",
      "Close": "6122.9"
  },
  {
      "Date": "11/21/13",
      "Open": "6096.5",
      "High": "6097.35",
      "Low": "5985.4",
      "Close": "5999"
  },
  {
      "Date": "11/22/13",
      "Open": "6027.35",
      "High": "6049.6",
      "Low": "5972.8",
      "Close": "5995.4"
  },
  {
      "Date": "11/25/13",
      "Open": "6035.95",
      "High": "6123.5",
      "Low": "6035.95",
      "Close": "6115.3"
  },
  {
      "Date": "11/26/13",
      "Open": "6099.25",
      "High": "6112.7",
      "Low": "6047.75",
      "Close": "6059.1"
  },
  {
      "Date": "11/27/13",
      "Open": "6062.7",
      "High": "6074",
      "Low": "6030.3",
      "Close": "6057.1"
  },
  {
      "Date": "11/28/13",
      "Open": "6092",
      "High": "6112.95",
      "Low": "6068.3",
      "Close": "6091.8"
  },
  {
      "Date": "11/29/13",
      "Open": "6103.9",
      "High": "6182.5",
      "Low": "6103.8",
      "Close": "6176.1"
  },
  {
      "Date": "12/2/13",
      "Open": "6171.15",
      "High": "6228.7",
      "Low": "6171.15",
      "Close": "6217.8"
  },
  {
      "Date": "12/3/13",
      "Open": "6204.25",
      "High": "6225.4",
      "Low": "6191.4",
      "Close": "6201.8"
  },
  {
      "Date": "12/4/13",
      "Open": "6187.95",
      "High": "6209.15",
      "Low": "6149.9",
      "Close": "6160.9"
  },
  {
      "Date": "12/5/13",
      "Open": "6262.45",
      "High": "6300.55",
      "Low": "6232",
      "Close": "6241.1"
  },
  {
      "Date": "12/6/13",
      "Open": "6234.4",
      "High": "6275.35",
      "Low": "6230.75",
      "Close": "6259.9"
  },
  {
      "Date": "12/9/13",
      "Open": "6415",
      "High": "6415.25",
      "Low": "6345",
      "Close": "6363.9"
  },
  {
      "Date": "12/10/13",
      "Open": "6354.7",
      "High": "6362.25",
      "Low": "6307.55",
      "Close": "6332.8"
  },
  {
      "Date": "12/11/13",
      "Open": "6307.2",
      "High": "6326.6",
      "Low": "6280.25",
      "Close": "6307.9"
  },
  {
      "Date": "12/12/13",
      "Open": "6276.75",
      "High": "6286.85",
      "Low": "6230.55",
      "Close": "6237"
  },
  {
      "Date": "12/13/13",
      "Open": "6201.3",
      "High": "6208.6",
      "Low": "6161.4",
      "Close": "6168.4"
  },
  {
      "Date": "12/16/13",
      "Open": "6168.35",
      "High": "6183.25",
      "Low": "6146.05",
      "Close": "6154.7"
  },
  {
      "Date": "12/17/13",
      "Open": "6178.2",
      "High": "6190.55",
      "Low": "6133",
      "Close": "6139"
  },
  {
      "Date": "12/18/13",
      "Open": "6129.95",
      "High": "6236",
      "Low": "6129.95",
      "Close": "6217.1"
  },
  {
      "Date": "12/19/13",
      "Open": "6253.9",
      "High": "6263.75",
      "Low": "6150.7",
      "Close": "6166.6"
  },
  {
      "Date": "12/20/13",
      "Open": "6179.95",
      "High": "6284.5",
      "Low": "6170.35",
      "Close": "6274.2"
  },
  {
      "Date": "12/23/13",
      "Open": "6267.2",
      "High": "6317.5",
      "Low": "6266.95",
      "Close": "6284.5"
  },
  {
      "Date": "12/24/13",
      "Open": "6296.45",
      "High": "6301.5",
      "Low": "6262",
      "Close": "6268.4"
  },
  {
      "Date": "12/26/13",
      "Open": "6270.1",
      "High": "6302.75",
      "Low": "6259.45",
      "Close": "6278.9"
  },
  {
      "Date": "12/27/13",
      "Open": "6292.8",
      "High": "6324.9",
      "Low": "6289.4",
      "Close": "6313.8"
  },
  {
      "Date": "12/30/13",
      "Open": "6336.4",
      "High": "6344.05",
      "Low": "6273.15",
      "Close": "6291.1"
  },
  {
      "Date": "12/31/13",
      "Open": "6307.35",
      "High": "6317.3",
      "Low": "6287.3",
      "Close": "6304"
  },
  {
      "Date": "1/1/14",
      "Open": "6323.8",
      "High": "6327.2",
      "Low": "6298.25",
      "Close": "6301.6"
  },
  {
      "Date": "1/2/14",
      "Open": "6301.25",
      "High": "6358.3",
      "Low": "6211.3",
      "Close": "6221.1"
  },
  {
      "Date": "1/3/14",
      "Open": "6194.55",
      "High": "6221.7",
      "Low": "6171.25",
      "Close": "6211.1"
  },
  {
      "Date": "1/6/14",
      "Open": "6220.85",
      "High": "6224.7",
      "Low": "6170.25",
      "Close": "6191.4"
  },
  {
      "Date": "1/7/14",
      "Open": "6203.9",
      "High": "6221.5",
      "Low": "6144.75",
      "Close": "6162.2"
  },
  {
      "Date": "1/8/14",
      "Open": "6178.05",
      "High": "6192.1",
      "Low": "6160.35",
      "Close": "6174.6"
  },
  {
      "Date": "1/9/14",
      "Open": "6181.7",
      "High": "6188.05",
      "Low": "6148.25",
      "Close": "6168.3"
  },
  {
      "Date": "1/10/14",
      "Open": "6178.85",
      "High": "6239.1",
      "Low": "6139.6",
      "Close": "6171.4"
  },
  {
      "Date": "1/13/14",
      "Open": "6189.55",
      "High": "6288.2",
      "Low": "6189.55",
      "Close": "6272.7"
  },
  {
      "Date": "1/14/14",
      "Open": "6260.25",
      "High": "6280.35",
      "Low": "6234.15",
      "Close": "6241.8"
  },
  {
      "Date": "1/15/14",
      "Open": "6265.95",
      "High": "6325.2",
      "Low": "6265.3",
      "Close": "6320.9"
  },
  {
      "Date": "1/16/14",
      "Open": "6341.35",
      "High": "6346.5",
      "Low": "6299.85",
      "Close": "6318.9"
  },
  {
      "Date": "1/17/14",
      "Open": "6306.25",
      "High": "6327.1",
      "Low": "6246.35",
      "Close": "6261.6"
  },
  {
      "Date": "1/20/14",
      "Open": "6261.75",
      "High": "6307.45",
      "Low": "6243.35",
      "Close": "6303.9"
  },
  {
      "Date": "1/21/14",
      "Open": "6320.15",
      "High": "6330.3",
      "Low": "6297.9",
      "Close": "6313.8"
  },
  {
      "Date": "1/22/14",
      "Open": "6309.05",
      "High": "6349.95",
      "Low": "6287.45",
      "Close": "6338.9"
  },
  {
      "Date": "1/23/14",
      "Open": "6325.95",
      "High": "6355.6",
      "Low": "6316.4",
      "Close": "6345.6"
  },
  {
      "Date": "1/24/14",
      "Open": "6301.65",
      "High": "6331.45",
      "Low": "6263.9",
      "Close": "6266.7"
  },
  {
      "Date": "1/27/14",
      "Open": "6186.3",
      "High": "6188.55",
      "Low": "6130.25",
      "Close": "6135.8"
  },
  {
      "Date": "1/28/14",
      "Open": "6131.85",
      "High": "6163.6",
      "Low": "6085.95",
      "Close": "6126.2"
  },
  {
      "Date": "1/29/14",
      "Open": "6161",
      "High": "6170.45",
      "Low": "6109.8",
      "Close": "6120.2"
  },
  {
      "Date": "1/30/14",
      "Open": "6067",
      "High": "6082.85",
      "Low": "6027.25",
      "Close": "6073.7"
  },
  {
      "Date": "1/31/14",
      "Open": "6082.75",
      "High": "6097.85",
      "Low": "6067.35",
      "Close": "6089.5"
  },
  {
      "Date": "2/3/14",
      "Open": "6058.8",
      "High": "6074.85",
      "Low": "5994.45",
      "Close": "6001.8"
  },
  {
      "Date": "2/4/14",
      "Open": "5947.6",
      "High": "6017.8",
      "Low": "5933.3",
      "Close": "6000.9"
  },
  {
      "Date": "2/5/14",
      "Open": "6004.25",
      "High": "6028.05",
      "Low": "5962.05",
      "Close": "6022.4"
  },
  {
      "Date": "2/6/14",
      "Open": "6028.35",
      "High": "6048.35",
      "Low": "5965.4",
      "Close": "6036.3"
  },
  {
      "Date": "2/7/14",
      "Open": "6077.65",
      "High": "6079.95",
      "Low": "6030.9",
      "Close": "6063.2"
  },
  {
      "Date": "2/10/14",
      "Open": "6072.8",
      "High": "6083.05",
      "Low": "6046.4",
      "Close": "6053.4"
  },
  {
      "Date": "2/11/14",
      "Open": "6072.45",
      "High": "6081.85",
      "Low": "6053.25",
      "Close": "6062.7"
  },
  {
      "Date": "2/12/14",
      "Open": "6085.35",
      "High": "6106.6",
      "Low": "6077.4",
      "Close": "6084"
  },
  {
      "Date": "2/13/14",
      "Open": "6087.55",
      "High": "6094.4",
      "Low": "5991.1",
      "Close": "6001.1"
  },
  {
      "Date": "2/14/14",
      "Open": "6023.75",
      "High": "6056.4",
      "Low": "5984.6",
      "Close": "6048.3"
  },
  {
      "Date": "2/17/14",
      "Open": "6057.1",
      "High": "6080.65",
      "Low": "6038.3",
      "Close": "6073.3"
  },
  {
      "Date": "2/18/14",
      "Open": "6071.3",
      "High": "6141.7",
      "Low": "6066.8",
      "Close": "6127.1"
  },
  {
      "Date": "2/19/14",
      "Open": "6132.05",
      "High": "6160.35",
      "Low": "6125.75",
      "Close": "6152.7"
  },
  {
      "Date": "2/20/14",
      "Open": "6127.15",
      "High": "6129.1",
      "Low": "6086.45",
      "Close": "6091.4"
  },
  {
      "Date": "2/21/14",
      "Open": "6108.3",
      "High": "6159.65",
      "Low": "6108",
      "Close": "6155.4"
  },
  {
      "Date": "2/24/14",
      "Open": "6140.95",
      "High": "6191.85",
      "Low": "6130.8",
      "Close": "6186.1"
  },
  {
      "Date": "2/25/14",
      "Open": "6205.7",
      "High": "6216.85",
      "Low": "6176.6",
      "Close": "6200"
  },
  {
      "Date": "2/26/14",
      "Open": "6202.45",
      "High": "6245.95",
      "Low": "6202.1",
      "Close": "6238.8"
  },
  {
      "Date": "2/28/14",
      "Open": "6228.45",
      "High": "6282.7",
      "Low": "6228.1",
      "Close": "6276.9"
  },
  {
      "Date": "3/3/14",
      "Open": "6264.35",
      "High": "6277.75",
      "Low": "6212.25",
      "Close": "6221.4"
  },
  {
      "Date": "3/4/14",
      "Open": "6216.75",
      "High": "6302.15",
      "Low": "6215.7",
      "Close": "6297.9"
  },
  {
      "Date": "3/5/14",
      "Open": "6328.45",
      "High": "6336.25",
      "Low": "6287.8",
      "Close": "6328.6"
  },
  {
      "Date": "3/6/14",
      "Open": "6344.75",
      "High": "6406.6",
      "Low": "6339.7",
      "Close": "6401.1"
  },
  {
      "Date": "3/7/14",
      "Open": "6413.95",
      "High": "6537.8",
      "Low": "6413.55",
      "Close": "6526.6"
  },
  {
      "Date": "3/10/14",
      "Open": "6491.7",
      "High": "6562.2",
      "Low": "6487.35",
      "Close": "6537.2"
  },
  {
      "Date": "3/11/14",
      "Open": "6537.35",
      "High": "6562.85",
      "Low": "6494.25",
      "Close": "6511.9"
  },
  {
      "Date": "3/12/14",
      "Open": "6497.5",
      "High": "6546.15",
      "Low": "6487.3",
      "Close": "6516.9"
  },
  {
      "Date": "3/13/14",
      "Open": "6491.75",
      "High": "6561.45",
      "Low": "6476.65",
      "Close": "6493.1"
  },
  {
      "Date": "3/14/14",
      "Open": "6447.25",
      "High": "6518.45",
      "Low": "6432.7",
      "Close": "6504.2"
  },
  {
      "Date": "3/18/14",
      "Open": "6532.45",
      "High": "6574.95",
      "Low": "6497.65",
      "Close": "6516.6"
  },
  {
      "Date": "3/19/14",
      "Open": "6530",
      "High": "6541.2",
      "Low": "6506",
      "Close": "6524"
  },
  {
      "Date": "3/20/14",
      "Open": "6508.35",
      "High": "6523.65",
      "Low": "6473.25",
      "Close": "6483.1"
  },
  {
      "Date": "3/21/14",
      "Open": "6515.2",
      "High": "6522.9",
      "Low": "6485.7",
      "Close": "6493.2"
  },
  {
      "Date": "3/22/14",
      "Open": "6497.8",
      "High": "6502.65",
      "Low": "6481.35",
      "Close": "6494.9"
  },
  {
      "Date": "3/24/14",
      "Open": "6510.5",
      "High": "6591.5",
      "Low": "6510.5",
      "Close": "6583.5"
  },
  {
      "Date": "3/25/14",
      "Open": "6550.1",
      "High": "6595.55",
      "Low": "6544.85",
      "Close": "6589.7"
  },
  {
      "Date": "3/26/14",
      "Open": "6615.65",
      "High": "6627.45",
      "Low": "6580.6",
      "Close": "6601.4"
  },
  {
      "Date": "3/27/14",
      "Open": "6613.1",
      "High": "6673.95",
      "Low": "6599.5",
      "Close": "6641.7"
  },
  {
      "Date": "3/28/14",
      "Open": "6673.05",
      "High": "6702.6",
      "Low": "6643.8",
      "Close": "6695.9"
  },
  {
      "Date": "3/31/14",
      "Open": "6723.15",
      "High": "6730.05",
      "Low": "6662.4",
      "Close": "6704.2"
  },
  {
      "Date": "4/1/14",
      "Open": "6729.5",
      "High": "6732.25",
      "Low": "6675.45",
      "Close": "6721"
  },
  {
      "Date": "4/2/14",
      "Open": "6757.6",
      "High": "6763.5",
      "Low": "6723.6",
      "Close": "6752.5"
  },
  {
      "Date": "4/3/14",
      "Open": "6772.05",
      "High": "6776.75",
      "Low": "6696.9",
      "Close": "6736.1"
  },
  {
      "Date": "4/4/14",
      "Open": "6741.85",
      "High": "6741.85",
      "Low": "6685.15",
      "Close": "6694.3"
  },
  {
      "Date": "4/7/14",
      "Open": "6694.25",
      "High": "6725.15",
      "Low": "6650.4",
      "Close": "6695"
  },
  {
      "Date": "4/9/14",
      "Open": "6722",
      "High": "6808.7",
      "Low": "6705.1",
      "Close": "6796.2"
  },
  {
      "Date": "4/10/14",
      "Open": "6803.05",
      "High": "6819.05",
      "Low": "6777.3",
      "Close": "6796.4"
  },
  {
      "Date": "4/11/14",
      "Open": "6758.35",
      "High": "6789.35",
      "Low": "6743.15",
      "Close": "6776.3"
  },
  {
      "Date": "4/15/14",
      "Open": "6792.7",
      "High": "6813.4",
      "Low": "6711.75",
      "Close": "6733.1"
  },
  {
      "Date": "4/16/14",
      "Open": "6727.25",
      "High": "6748.65",
      "Low": "6665.15",
      "Close": "6675.3"
  },
  {
      "Date": "4/17/14",
      "Open": "6695.45",
      "High": "6783.05",
      "Low": "6684.4",
      "Close": "6779.4"
  },
  {
      "Date": "4/21/14",
      "Open": "6789.25",
      "High": "6825.45",
      "Low": "6786.9",
      "Close": "6817.6"
  },
  {
      "Date": "4/22/14",
      "Open": "6822.9",
      "High": "6838",
      "Low": "6806.25",
      "Close": "6815.3"
  },
  {
      "Date": "4/23/14",
      "Open": "6823.25",
      "High": "6861.6",
      "Low": "6820.75",
      "Close": "6840.8"
  },
  {
      "Date": "4/25/14",
      "Open": "6855.8",
      "High": "6869.85",
      "Low": "6772.85",
      "Close": "6782.7"
  },
  {
      "Date": "4/28/14",
      "Open": "6778.55",
      "High": "6786.25",
      "Low": "6750.3",
      "Close": "6761.2"
  },
  {
      "Date": "4/29/14",
      "Open": "6769",
      "High": "6779.7",
      "Low": "6708.65",
      "Close": "6715.2"
  },
  {
      "Date": "4/30/14",
      "Open": "6724.95",
      "High": "6780.15",
      "Low": "6656.8",
      "Close": "6696.4"
  },
  {
      "Date": "5/2/14",
      "Open": "6709.95",
      "High": "6737.65",
      "Low": "6689.5",
      "Close": "6694.8"
  },
  {
      "Date": "5/5/14",
      "Open": "6681.65",
      "High": "6741.05",
      "Low": "6680.45",
      "Close": "6699.3"
  },
  {
      "Date": "5/6/14",
      "Open": "6719.25",
      "High": "6743.45",
      "Low": "6701.9",
      "Close": "6715.3"
  },
  {
      "Date": "5/7/14",
      "Open": "6708.6",
      "High": "6718.75",
      "Low": "6642.9",
      "Close": "6652.5"
  },
  {
      "Date": "5/8/14",
      "Open": "6669.9",
      "High": "6688.4",
      "Low": "6638.55",
      "Close": "6659.8"
  },
  {
      "Date": "5/9/14",
      "Open": "6654.15",
      "High": "6871.35",
      "Low": "6652.15",
      "Close": "6858.8"
  },
  {
      "Date": "5/12/14",
      "Open": "6863.4",
      "High": "7020.05",
      "Low": "6862.9",
      "Close": "7014.2"
  },
  {
      "Date": "5/13/14",
      "Open": "7080",
      "High": "7172.35",
      "Low": "7067.15",
      "Close": "7108.7"
  },
  {
      "Date": "5/14/14",
      "Open": "7112",
      "High": "7142.25",
      "Low": "7080.9",
      "Close": "7108.7"
  },
  {
      "Date": "5/15/14",
      "Open": "7111.3",
      "High": "7152.55",
      "Low": "7082.55",
      "Close": "7123.1"
  },
  {
      "Date": "5/16/14",
      "Open": "7270.2",
      "High": "7563.5",
      "Low": "7130.65",
      "Close": "7203"
  },
  {
      "Date": "5/19/14",
      "Open": "7276.85",
      "High": "7291.1",
      "Low": "7193.55",
      "Close": "7263.5"
  },
  {
      "Date": "5/20/14",
      "Open": "7309.95",
      "High": "7353.65",
      "Low": "7247.7",
      "Close": "7275.5"
  },
  {
      "Date": "5/21/14",
      "Open": "7274.85",
      "High": "7287.15",
      "Low": "7206.7",
      "Close": "7252.9"
  },
  {
      "Date": "5/22/14",
      "Open": "7289.95",
      "High": "7319.55",
      "Low": "7258.15",
      "Close": "7276.4"
  },
  {
      "Date": "5/23/14",
      "Open": "7306.5",
      "High": "7381",
      "Low": "7293.9",
      "Close": "7367.1"
  },
  {
      "Date": "5/26/14",
      "Open": "7428.75",
      "High": "7504",
      "Low": "7269.05",
      "Close": "7359"
  },
  {
      "Date": "5/27/14",
      "Open": "7363.1",
      "High": "7372.95",
      "Low": "7274.75",
      "Close": "7318"
  },
  {
      "Date": "5/28/14",
      "Open": "7324.95",
      "High": "7344.75",
      "Low": "7302.6",
      "Close": "7329.6"
  },
  {
      "Date": "5/29/14",
      "Open": "7316.6",
      "High": "7325.4",
      "Low": "7224.4",
      "Close": "7235.6"
  },
  {
      "Date": "5/30/14",
      "Open": "7254.85",
      "High": "7272.5",
      "Low": "7118.45",
      "Close": "7229.9"
  },
  {
      "Date": "6/2/14",
      "Open": "7264.05",
      "High": "7368.6",
      "Low": "7239.5",
      "Close": "7362.5"
  },
  {
      "Date": "6/3/14",
      "Open": "7375.35",
      "High": "7424.95",
      "Low": "7342.15",
      "Close": "7415.8"
  },
  {
      "Date": "6/4/14",
      "Open": "7417.55",
      "High": "7433.3",
      "Low": "7391.35",
      "Close": "7402.2"
  },
  {
      "Date": "6/5/14",
      "Open": "7399.75",
      "High": "7484.7",
      "Low": "7360.5",
      "Close": "7474.1"
  },
  {
      "Date": "6/6/14",
      "Open": "7521.5",
      "High": "7592.7",
      "Low": "7497.65",
      "Close": "7583.4"
  },
  {
      "Date": "6/9/14",
      "Open": "7621.65",
      "High": "7673.7",
      "Low": "7580.25",
      "Close": "7654.6"
  },
  {
      "Date": "6/10/14",
      "Open": "7679.05",
      "High": "7683.2",
      "Low": "7579.3",
      "Close": "7656.4"
  },
  {
      "Date": "6/11/14",
      "Open": "7672.4",
      "High": "7700.05",
      "Low": "7589.05",
      "Close": "7626.8"
  },
  {
      "Date": "6/12/14",
      "Open": "7641.3",
      "High": "7658",
      "Low": "7593.8",
      "Close": "7649.9"
  },
  {
      "Date": "6/13/14",
      "Open": "7668.2",
      "High": "7678.5",
      "Low": "7525.35",
      "Close": "7542.1"
  },
  {
      "Date": "6/16/14",
      "Open": "7534.8",
      "High": "7548.6",
      "Low": "7487.55",
      "Close": "7533.5"
  },
  {
      "Date": "6/17/14",
      "Open": "7525.05",
      "High": "7637.6",
      "Low": "7509.25",
      "Close": "7631.7"
  },
  {
      "Date": "6/18/14",
      "Open": "7636.05",
      "High": "7663",
      "Low": "7515.5",
      "Close": "7558.2"
  },
  {
      "Date": "6/19/14",
      "Open": "7580.05",
      "High": "7606.45",
      "Low": "7502.55",
      "Close": "7540.7"
  },
  {
      "Date": "6/20/14",
      "Open": "7543.3",
      "High": "7560.55",
      "Low": "7497.3",
      "Close": "7511.4"
  },
  {
      "Date": "6/23/14",
      "Open": "7514",
      "High": "7534.8",
      "Low": "7441.6",
      "Close": "7493.3"
  },
  {
      "Date": "6/24/14",
      "Open": "7515.2",
      "High": "7593.35",
      "Low": "7515.2",
      "Close": "7580.2"
  },
  {
      "Date": "6/25/14",
      "Open": "7588.55",
      "High": "7589.25",
      "Low": "7557.05",
      "Close": "7569.2"
  },
  {
      "Date": "6/26/14",
      "Open": "7554.1",
      "High": "7570.2",
      "Low": "7481.3",
      "Close": "7493.2"
  },
  {
      "Date": "6/27/14",
      "Open": "7514.2",
      "High": "7538.75",
      "Low": "7482.3",
      "Close": "7508.8"
  },
  {
      "Date": "6/30/14",
      "Open": "7534.05",
      "High": "7623.65",
      "Low": "7531.6",
      "Close": "7611.3"
  },
  {
      "Date": "7/1/14",
      "Open": "7629",
      "High": "7649.5",
      "Low": "7618.15",
      "Close": "7634.7"
  },
  {
      "Date": "7/2/14",
      "Open": "7683.05",
      "High": "7732.4",
      "Low": "7677.3",
      "Close": "7725.1"
  },
  {
      "Date": "7/3/14",
      "Open": "7734.35",
      "High": "7754.65",
      "Low": "7706.8",
      "Close": "7714.8"
  },
  {
      "Date": "7/4/14",
      "Open": "7718.1",
      "High": "7758",
      "Low": "7661.3",
      "Close": "7751.6"
  },
  {
      "Date": "7/7/14",
      "Open": "7780.4",
      "High": "7792",
      "Low": "7755.1",
      "Close": "7787.1"
  },
  {
      "Date": "7/8/14",
      "Open": "7804.05",
      "High": "7808.85",
      "Low": "7595.9",
      "Close": "7623.2"
  },
  {
      "Date": "7/9/14",
      "Open": "7637.95",
      "High": "7650.1",
      "Low": "7551.65",
      "Close": "7585"
  },
  {
      "Date": "7/10/14",
      "Open": "7589.5",
      "High": "7731.05",
      "Low": "7479.05",
      "Close": "7567.7"
  },
  {
      "Date": "7/11/14",
      "Open": "7584.1",
      "High": "7625.85",
      "Low": "7447.2",
      "Close": "7459.6"
  },
  {
      "Date": "7/14/14",
      "Open": "7469",
      "High": "7478.45",
      "Low": "7422.15",
      "Close": "7454.1"
  },
  {
      "Date": "7/15/14",
      "Open": "7491.3",
      "High": "7534.9",
      "Low": "7459.15",
      "Close": "7526.6"
  },
  {
      "Date": "7/16/14",
      "Open": "7564.15",
      "High": "7640.1",
      "Low": "7532.45",
      "Close": "7624.4"
  },
  {
      "Date": "7/17/14",
      "Open": "7612.7",
      "High": "7655.65",
      "Low": "7612.7",
      "Close": "7640.4"
  },
  {
      "Date": "7/18/14",
      "Open": "7630.25",
      "High": "7685",
      "Low": "7595.5",
      "Close": "7663.9"
  },
  {
      "Date": "7/21/14",
      "Open": "7701.65",
      "High": "7722.1",
      "Low": "7674",
      "Close": "7684.2"
  },
  {
      "Date": "7/22/14",
      "Open": "7708.2",
      "High": "7773.85",
      "Low": "7704.8",
      "Close": "7767.8"
  },
  {
      "Date": "7/23/14",
      "Open": "7794.9",
      "High": "7809.2",
      "Low": "7752.9",
      "Close": "7795.7"
  },
  {
      "Date": "7/24/14",
      "Open": "7796.25",
      "High": "7835.65",
      "Low": "7771.65",
      "Close": "7830.6"
  },
  {
      "Date": "7/25/14",
      "Open": "7828.2",
      "High": "7840.95",
      "Low": "7748.6",
      "Close": "7790.4"
  },
  {
      "Date": "7/28/14",
      "Open": "7792.9",
      "High": "7799.9",
      "Low": "7722.65",
      "Close": "7748.7"
  },
  {
      "Date": "7/30/14",
      "Open": "7746.2",
      "High": "7798.7",
      "Low": "7707.6",
      "Close": "7791.4"
  },
  {
      "Date": "7/31/14",
      "Open": "7784.65",
      "High": "7791.85",
      "Low": "7711.15",
      "Close": "7721.3"
  },
  {
      "Date": "8/1/14",
      "Open": "7662.5",
      "High": "7716.7",
      "Low": "7593.9",
      "Close": "7602.6"
  },
  {
      "Date": "8/4/14",
      "Open": "7639.55",
      "High": "7694.8",
      "Low": "7622.05",
      "Close": "7683.6"
  },
  {
      "Date": "8/5/14",
      "Open": "7706.65",
      "High": "7752.45",
      "Low": "7638.05",
      "Close": "7746.5"
  },
  {
      "Date": "8/6/14",
      "Open": "7726.15",
      "High": "7740.95",
      "Low": "7658.95",
      "Close": "7672"
  },
  {
      "Date": "8/7/14",
      "Open": "7651.15",
      "High": "7708.95",
      "Low": "7630.4",
      "Close": "7649.2"
  },
  {
      "Date": "8/8/14",
      "Open": "7588.7",
      "High": "7592.45",
      "Low": "7540.1",
      "Close": "7568.5"
  },
  {
      "Date": "8/11/14",
      "Open": "7619.85",
      "High": "7635.55",
      "Low": "7598.6",
      "Close": "7625.9"
  },
  {
      "Date": "8/12/14",
      "Open": "7688.8",
      "High": "7735.75",
      "Low": "7654.8",
      "Close": "7727"
  },
  {
      "Date": "8/13/14",
      "Open": "7717.3",
      "High": "7757.1",
      "Low": "7695.7",
      "Close": "7739.5"
  },
  {
      "Date": "8/14/14",
      "Open": "7756.15",
      "High": "7796.7",
      "Low": "7739.1",
      "Close": "7791.7"
  },
  {
      "Date": "8/18/14",
      "Open": "7785.25",
      "High": "7880.5",
      "Low": "7779.2",
      "Close": "7874.2"
  },
  {
      "Date": "8/19/14",
      "Open": "7901",
      "High": "7918.55",
      "Low": "7881.15",
      "Close": "7897.5"
  },
  {
      "Date": "8/20/14",
      "Open": "7915.8",
      "High": "7922.7",
      "Low": "7864.05",
      "Close": "7875.3"
  },
  {
      "Date": "8/21/14",
      "Open": "7875.35",
      "High": "7919.65",
      "Low": "7855.95",
      "Close": "7891.1"
  },
  {
      "Date": "8/22/14",
      "Open": "7904.55",
      "High": "7929.05",
      "Low": "7900.05",
      "Close": "7913.2"
  },
  {
      "Date": "8/25/14",
      "Open": "7931.75",
      "High": "7968.25",
      "Low": "7897.95",
      "Close": "7906.3"
  },
  {
      "Date": "8/26/14",
      "Open": "7874.5",
      "High": "7915.45",
      "Low": "7862.45",
      "Close": "7904.7"
  },
  {
      "Date": "8/27/14",
      "Open": "7933.9",
      "High": "7946.85",
      "Low": "7916.55",
      "Close": "7936"
  },
  {
      "Date": "8/28/14",
      "Open": "7942.25",
      "High": "7967.8",
      "Low": "7939.2",
      "Close": "7954.3"
  },
  {
      "Date": "9/1/14",
      "Open": "7990.35",
      "High": "8035",
      "Low": "7984",
      "Close": "8027.7"
  },
  {
      "Date": "9/2/14",
      "Open": "8038.6",
      "High": "8101.95",
      "Low": "8036.55",
      "Close": "8083"
  },
  {
      "Date": "9/3/14",
      "Open": "8110.85",
      "High": "8141.9",
      "Low": "8092.25",
      "Close": "8114.6"
  },
  {
      "Date": "9/4/14",
      "Open": "8114.2",
      "High": "8114.8",
      "Low": "8060.9",
      "Close": "8095.9"
  },
  {
      "Date": "9/5/14",
      "Open": "8099.9",
      "High": "8122.7",
      "Low": "8049.85",
      "Close": "8086.8"
  },
  {
      "Date": "9/8/14",
      "Open": "8132.95",
      "High": "8180.2",
      "Low": "8126.15",
      "Close": "8173.9"
  },
  {
      "Date": "9/9/14",
      "Open": "8161.9",
      "High": "8174.55",
      "Low": "8126.5",
      "Close": "8152.9"
  },
  {
      "Date": "9/10/14",
      "Open": "8135.55",
      "High": "8135.75",
      "Low": "8082.1",
      "Close": "8094.1"
  },
  {
      "Date": "9/11/14",
      "Open": "8115.15",
      "High": "8127.95",
      "Low": "8057.3",
      "Close": "8085.7"
  },
  {
      "Date": "9/12/14",
      "Open": "8087.05",
      "High": "8114.3",
      "Low": "8071.6",
      "Close": "8105.5"
  },
  {
      "Date": "9/15/14",
      "Open": "8070.35",
      "High": "8077.3",
      "Low": "8030",
      "Close": "8042"
  },
  {
      "Date": "9/16/14",
      "Open": "8036.6",
      "High": "8044.9",
      "Low": "7925.15",
      "Close": "7932.9"
  },
  {
      "Date": "9/17/14",
      "Open": "7971.5",
      "High": "7990.65",
      "Low": "7936.95",
      "Close": "7975.5"
  },
  {
      "Date": "9/18/14",
      "Open": "7950.65",
      "High": "8120.85",
      "Low": "7939.7",
      "Close": "8114.7"
  },
  {
      "Date": "9/19/14",
      "Open": "8129.4",
      "High": "8160.9",
      "Low": "8105.35",
      "Close": "8121.4"
  },
  {
      "Date": "9/22/14",
      "Open": "8084.45",
      "High": "8159.9",
      "Low": "8064.8",
      "Close": "8146.3"
  },
  {
      "Date": "9/23/14",
      "Open": "8144.4",
      "High": "8159.75",
      "Low": "8008.1",
      "Close": "8017.5"
  },
  {
      "Date": "9/24/14",
      "Open": "8015.55",
      "High": "8042.05",
      "Low": "7950.05",
      "Close": "8002.4"
  },
  {
      "Date": "9/25/14",
      "Open": "8003.3",
      "High": "8019.3",
      "Low": "7877.35",
      "Close": "7911.8"
  },
  {
      "Date": "9/26/14",
      "Open": "7885.85",
      "High": "7993.3",
      "Low": "7841.8",
      "Close": "7968.8"
  },
  {
      "Date": "9/29/14",
      "Open": "7978.45",
      "High": "7991.75",
      "Low": "7934.7",
      "Close": "7958.9"
  },
  {
      "Date": "9/30/14",
      "Open": "7948.8",
      "High": "8030.9",
      "Low": "7923.85",
      "Close": "7964.8"
  },
  {
      "Date": "10/1/14",
      "Open": "7960.5",
      "High": "7977.5",
      "Low": "7936.7",
      "Close": "7945.5"
  },
  {
      "Date": "10/7/14",
      "Open": "7897.4",
      "High": "7943.05",
      "Low": "7842.7",
      "Close": "7852.4"
  },
  {
      "Date": "10/8/14",
      "Open": "7828.75",
      "High": "7869.9",
      "Low": "7815.75",
      "Close": "7842.7"
  },
  {
      "Date": "10/9/14",
      "Open": "7886.5",
      "High": "7972.35",
      "Low": "7886.5",
      "Close": "7960.5"
  },
  {
      "Date": "10/10/14",
      "Open": "7911",
      "High": "7924.05",
      "Low": "7848.45",
      "Close": "7859.9"
  },
  {
      "Date": "10/13/14",
      "Open": "7831",
      "High": "7901.15",
      "Low": "7796",
      "Close": "7884.2"
  },
  {
      "Date": "10/14/14",
      "Open": "7923.25",
      "High": "7928",
      "Low": "7825.45",
      "Close": "7864"
  },
  {
      "Date": "10/16/14",
      "Open": "7837.3",
      "High": "7893.9",
      "Low": "7729.65",
      "Close": "7748.2"
  },
  {
      "Date": "10/17/14",
      "Open": "7733.75",
      "High": "7819.2",
      "Low": "7723.85",
      "Close": "7779.7"
  },
  {
      "Date": "10/20/14",
      "Open": "7896.95",
      "High": "7905.95",
      "Low": "7856.95",
      "Close": "7879.4"
  },
  {
      "Date": "10/21/14",
      "Open": "7906.15",
      "High": "7936.6",
      "Low": "7874.35",
      "Close": "7927.7"
  },
  {
      "Date": "10/22/14",
      "Open": "7997.8",
      "High": "8005",
      "Low": "7974.55",
      "Close": "7995.9"
  },
  {
      "Date": "10/23/14",
      "Open": "8027.7",
      "High": "8031.75",
      "Low": "8008.85",
      "Close": "8014.5"
  },
  {
      "Date": "10/27/14",
      "Open": "8064.35",
      "High": "8064.4",
      "Low": "7985.65",
      "Close": "7991.7"
  },
  {
      "Date": "10/28/14",
      "Open": "8002.4",
      "High": "8037.8",
      "Low": "7995.05",
      "Close": "8027.6"
  },
  {
      "Date": "10/29/14",
      "Open": "8077.05",
      "High": "8097.95",
      "Low": "8052.25",
      "Close": "8090.4"
  },
  {
      "Date": "10/30/14",
      "Open": "8085.2",
      "High": "8181.55",
      "Low": "8085.2",
      "Close": "8169.2"
  },
  {
      "Date": "10/31/14",
      "Open": "8200.8",
      "High": "8330.75",
      "Low": "8198.05",
      "Close": "8322.2"
  },
  {
      "Date": "11/3/14",
      "Open": "8348.15",
      "High": "8350.6",
      "Low": "8297.65",
      "Close": "8324.1"
  },
  {
      "Date": "11/5/14",
      "Open": "8351.25",
      "High": "8365.55",
      "Low": "8323.5",
      "Close": "8338.3"
  },
  {
      "Date": "11/7/14",
      "Open": "8331.85",
      "High": "8360.35",
      "Low": "8290.25",
      "Close": "8337"
  },
  {
      "Date": "11/10/14",
      "Open": "8337.8",
      "High": "8383.05",
      "Low": "8304.45",
      "Close": "8344.2"
  },
  {
      "Date": "11/11/14",
      "Open": "8354.1",
      "High": "8378.7",
      "Low": "8321.85",
      "Close": "8362.6"
  },
  {
      "Date": "11/12/14",
      "Open": "8378.9",
      "High": "8415.05",
      "Low": "8370.5",
      "Close": "8383.3"
  },
  {
      "Date": "11/13/14",
      "Open": "8405.25",
      "High": "8408",
      "Low": "8320.35",
      "Close": "8357.8"
  },
  {
      "Date": "11/14/14",
      "Open": "8360.7",
      "High": "8400.65",
      "Low": "8346.8",
      "Close": "8389.9"
  },
  {
      "Date": "11/17/14",
      "Open": "8378.4",
      "High": "8438.1",
      "Low": "8349.1",
      "Close": "8430.7"
  },
  {
      "Date": "11/18/14",
      "Open": "8441.25",
      "High": "8454.5",
      "Low": "8407.25",
      "Close": "8425.9"
  },
  {
      "Date": "11/19/14",
      "Open": "8440.65",
      "High": "8455.65",
      "Low": "8360.5",
      "Close": "8382.3"
  },
  {
      "Date": "11/20/14",
      "Open": "8406.5",
      "High": "8410.85",
      "Low": "8353.15",
      "Close": "8401.9"
  },
  {
      "Date": "11/21/14",
      "Open": "8408.2",
      "High": "8489.8",
      "Low": "8398.6",
      "Close": "8477.3"
  },
  {
      "Date": "11/24/14",
      "Open": "8490.95",
      "High": "8534.65",
      "Low": "8490.8",
      "Close": "8530.1"
  },
  {
      "Date": "11/25/14",
      "Open": "8530.8",
      "High": "8535.35",
      "Low": "8429.45",
      "Close": "8463.1"
  },
  {
      "Date": "11/26/14",
      "Open": "8450.3",
      "High": "8500.3",
      "Low": "8438.65",
      "Close": "8475.7"
  },
  {
      "Date": "11/27/14",
      "Open": "8477.8",
      "High": "8506.75",
      "Low": "8456.35",
      "Close": "8494.2"
  },
  {
      "Date": "11/28/14",
      "Open": "8516.8",
      "High": "8617",
      "Low": "8516.25",
      "Close": "8588.2"
  },
  {
      "Date": "12/1/14",
      "Open": "8605.1",
      "High": "8623",
      "Low": "8545.15",
      "Close": "8555.9"
  },
  {
      "Date": "12/2/14",
      "Open": "8535.45",
      "High": "8560.2",
      "Low": "8504.65",
      "Close": "8524.7"
  },
  {
      "Date": "12/3/14",
      "Open": "8528.7",
      "High": "8546.95",
      "Low": "8508.35",
      "Close": "8537.6"
  },
  {
      "Date": "12/4/14",
      "Open": "8582.4",
      "High": "8626.95",
      "Low": "8526.4",
      "Close": "8564.4"
  },
  {
      "Date": "12/5/14",
      "Open": "8584.25",
      "High": "8588.35",
      "Low": "8523.9",
      "Close": "8538.3"
  },
  {
      "Date": "12/8/14",
      "Open": "8538.65",
      "High": "8546.35",
      "Low": "8432.25",
      "Close": "8438.2"
  },
  {
      "Date": "12/9/14",
      "Open": "8439.3",
      "High": "8444.5",
      "Low": "8330.5",
      "Close": "8340.7"
  },
  {
      "Date": "12/10/14",
      "Open": "8318.05",
      "High": "8376.8",
      "Low": "8317",
      "Close": "8355.6"
  },
  {
      "Date": "12/11/14",
      "Open": "8338.85",
      "High": "8348.3",
      "Low": "8272.4",
      "Close": "8292.9"
  },
  {
      "Date": "12/12/14",
      "Open": "8302",
      "High": "8321.9",
      "Low": "8216.3",
      "Close": "8224.1"
  },
  {
      "Date": "12/15/14",
      "Open": "8160.75",
      "High": "8242.4",
      "Low": "8152.5",
      "Close": "8219.6"
  },
  {
      "Date": "12/16/14",
      "Open": "8172.6",
      "High": "8189.35",
      "Low": "8052.6",
      "Close": "8067.6"
  },
  {
      "Date": "12/17/14",
      "Open": "8041.2",
      "High": "8082",
      "Low": "7961.35",
      "Close": "8029.8"
  },
  {
      "Date": "12/18/14",
      "Open": "8138.9",
      "High": "8174.3",
      "Low": "8084.9",
      "Close": "8159.3"
  },
  {
      "Date": "12/19/14",
      "Open": "8230.45",
      "High": "8263.45",
      "Low": "8208.6",
      "Close": "8225.2"
  },
  {
      "Date": "12/22/14",
      "Open": "8255",
      "High": "8330.95",
      "Low": "8228.2",
      "Close": "8324"
  },
  {
      "Date": "12/23/14",
      "Open": "8324.6",
      "High": "8364.75",
      "Low": "8252.85",
      "Close": "8267"
  },
  {
      "Date": "12/24/14",
      "Open": "8272.05",
      "High": "8286.4",
      "Low": "8155.25",
      "Close": "8174.1"
  },
  {
      "Date": "12/26/14",
      "Open": "8204.8",
      "High": "8234.55",
      "Low": "8147.95",
      "Close": "8200.7"
  },
  {
      "Date": "12/29/14",
      "Open": "8214.7",
      "High": "8279.15",
      "Low": "8214.7",
      "Close": "8246.3"
  },
  {
      "Date": "12/30/14",
      "Open": "8260.3",
      "High": "8268.25",
      "Low": "8220.55",
      "Close": "8248.2"
  },
  {
      "Date": "12/31/14",
      "Open": "8243.9",
      "High": "8291",
      "Low": "8243.75",
      "Close": "8282.7"
  },
  {
      "Date": "1/1/15",
      "Open": "8272.8",
      "High": "8294.7",
      "Low": "8248.75",
      "Close": "8284"
  },
  {
      "Date": "1/2/15",
      "Open": "8288.7",
      "High": "8410.6",
      "Low": "8288.7",
      "Close": "8395.4"
  },
  {
      "Date": "1/5/15",
      "Open": "8407.95",
      "High": "8445.6",
      "Low": "8363.9",
      "Close": "8378.4"
  },
  {
      "Date": "1/6/15",
      "Open": "8325.3",
      "High": "8327.85",
      "Low": "8111.35",
      "Close": "8127.3"
  },
  {
      "Date": "1/7/15",
      "Open": "8118.65",
      "High": "8151.2",
      "Low": "8065.45",
      "Close": "8102.1"
  },
  {
      "Date": "1/8/15",
      "Open": "8191.4",
      "High": "8243.5",
      "Low": "8167.3",
      "Close": "8234.6"
  },
  {
      "Date": "1/9/15",
      "Open": "8285.45",
      "High": "8303.3",
      "Low": "8190.8",
      "Close": "8284.5"
  },
  {
      "Date": "1/12/15",
      "Open": "8291.35",
      "High": "8332.6",
      "Low": "8245.6",
      "Close": "8323"
  },
  {
      "Date": "1/13/15",
      "Open": "8346.15",
      "High": "8356.65",
      "Low": "8267.9",
      "Close": "8299.4"
  },
  {
      "Date": "1/14/15",
      "Open": "8307.25",
      "High": "8326.45",
      "Low": "8236.65",
      "Close": "8277.5"
  },
  {
      "Date": "1/15/15",
      "Open": "8424.5",
      "High": "8527.1",
      "Low": "8380.55",
      "Close": "8494.1"
  },
  {
      "Date": "1/16/15",
      "Open": "8504.05",
      "High": "8530.75",
      "Low": "8452.25",
      "Close": "8513.8"
  },
  {
      "Date": "1/19/15",
      "Open": "8550.05",
      "High": "8570.95",
      "Low": "8531.5",
      "Close": "8550.7"
  },
  {
      "Date": "1/20/15",
      "Open": "8575.1",
      "High": "8707.9",
      "Low": "8574.5",
      "Close": "8695.6"
  },
  {
      "Date": "1/21/15",
      "Open": "8719.65",
      "High": "8741.85",
      "Low": "8689.6",
      "Close": "8729.5"
  },
  {
      "Date": "1/22/15",
      "Open": "8745.85",
      "High": "8774.15",
      "Low": "8727",
      "Close": "8761.4"
  },
  {
      "Date": "1/23/15",
      "Open": "8827.95",
      "High": "8866.4",
      "Low": "8795.4",
      "Close": "8835.6"
  },
  {
      "Date": "1/27/15",
      "Open": "8871.35",
      "High": "8925.05",
      "Low": "8825.45",
      "Close": "8910.5"
  },
  {
      "Date": "1/28/15",
      "Open": "8902.75",
      "High": "8985.05",
      "Low": "8874.05",
      "Close": "8914.3"
  },
  {
      "Date": "1/29/15",
      "Open": "8901.5",
      "High": "8966.65",
      "Low": "8861.25",
      "Close": "8952.3"
  },
  {
      "Date": "1/30/15",
      "Open": "8996.6",
      "High": "8996.6",
      "Low": "8775.1",
      "Close": "8808.9"
  },
  {
      "Date": "2/2/15",
      "Open": "8802.5",
      "High": "8840.8",
      "Low": "8751.1",
      "Close": "8797.4"
  },
  {
      "Date": "2/3/15",
      "Open": "8823.15",
      "High": "8837.3",
      "Low": "8726.65",
      "Close": "8756.5"
  },
  {
      "Date": "2/4/15",
      "Open": "8789.15",
      "High": "8792.85",
      "Low": "8704.4",
      "Close": "8723.7"
  },
  {
      "Date": "2/5/15",
      "Open": "8733.1",
      "High": "8838.45",
      "Low": "8683.65",
      "Close": "8711.7"
  },
  {
      "Date": "2/6/15",
      "Open": "8696.85",
      "High": "8726.2",
      "Low": "8645.55",
      "Close": "8661"
  },
  {
      "Date": "2/9/15",
      "Open": "8584.4",
      "High": "8605.55",
      "Low": "8516.35",
      "Close": "8526.3"
  },
  {
      "Date": "2/10/15",
      "Open": "8478.1",
      "High": "8646.25",
      "Low": "8470.5",
      "Close": "8565.5"
  },
  {
      "Date": "2/11/15",
      "Open": "8603.3",
      "High": "8651.95",
      "Low": "8593.65",
      "Close": "8627.4"
  },
  {
      "Date": "2/12/15",
      "Open": "8676.95",
      "High": "8732.55",
      "Low": "8599.25",
      "Close": "8711.5"
  },
  {
      "Date": "2/13/15",
      "Open": "8741.5",
      "High": "8822.1",
      "Low": "8729.65",
      "Close": "8805.5"
  },
  {
      "Date": "2/16/15",
      "Open": "8831.4",
      "High": "8870.1",
      "Low": "8793.4",
      "Close": "8809.3"
  },
  {
      "Date": "2/18/15",
      "Open": "8811.55",
      "High": "8894.3",
      "Low": "8808.9",
      "Close": "8869.1"
  },
  {
      "Date": "2/19/15",
      "Open": "8883.05",
      "High": "8913.45",
      "Low": "8794.45",
      "Close": "8895.3"
  },
  {
      "Date": "2/20/15",
      "Open": "8895.5",
      "High": "8899.95",
      "Low": "8816.3",
      "Close": "8833.6"
  },
  {
      "Date": "2/23/15",
      "Open": "8856.85",
      "High": "8869",
      "Low": "8736.1",
      "Close": "8754.9"
  },
  {
      "Date": "2/24/15",
      "Open": "8772.9",
      "High": "8800.5",
      "Low": "8726.75",
      "Close": "8762.1"
  },
  {
      "Date": "2/25/15",
      "Open": "8801.9",
      "High": "8840.65",
      "Low": "8751.4",
      "Close": "8767.2"
  },
  {
      "Date": "2/26/15",
      "Open": "8779",
      "High": "8786.05",
      "Low": "8669.45",
      "Close": "8683.8"
  },
  {
      "Date": "2/27/15",
      "Open": "8729.5",
      "High": "8856.95",
      "Low": "8717.45",
      "Close": "8844.6"
  },
  {
      "Date": "2/28/15",
      "Open": "8913.05",
      "High": "8941.1",
      "Low": "8751.35",
      "Close": "8901.8"
  },
  {
      "Date": "3/2/15",
      "Open": "8953.85",
      "High": "8972.35",
      "Low": "8885.45",
      "Close": "8956.7"
  },
  {
      "Date": "3/3/15",
      "Open": "8962.85",
      "High": "9008.4",
      "Low": "8925.55",
      "Close": "8996.2"
  },
  {
      "Date": "3/4/15",
      "Open": "9109.15",
      "High": "9119.2",
      "Low": "8893.95",
      "Close": "8922.6"
  },
  {
      "Date": "3/5/15",
      "Open": "8929.4",
      "High": "8957.55",
      "Low": "8849.35",
      "Close": "8937.7"
  },
  {
      "Date": "3/9/15",
      "Open": "8891.15",
      "High": "8891.3",
      "Low": "8740.45",
      "Close": "8756.7"
  },
  {
      "Date": "3/10/15",
      "Open": "8769.75",
      "High": "8778",
      "Low": "8677.35",
      "Close": "8712"
  },
  {
      "Date": "3/11/15",
      "Open": "8728.75",
      "High": "8755.6",
      "Low": "8682.35",
      "Close": "8699.9"
  },
  {
      "Date": "3/12/15",
      "Open": "8740.65",
      "High": "8787.2",
      "Low": "8732.9",
      "Close": "8776"
  },
  {
      "Date": "3/13/15",
      "Open": "8844.05",
      "High": "8849.75",
      "Low": "8631.75",
      "Close": "8647.7"
  },
  {
      "Date": "3/16/15",
      "Open": "8656.75",
      "High": "8663.55",
      "Low": "8612",
      "Close": "8633.1"
  },
  {
      "Date": "3/17/15",
      "Open": "8689.1",
      "High": "8742.55",
      "Low": "8630.8",
      "Close": "8723.3"
  },
  {
      "Date": "3/18/15",
      "Open": "8742.9",
      "High": "8747.25",
      "Low": "8664",
      "Close": "8685.9"
  },
  {
      "Date": "3/19/15",
      "Open": "8749.45",
      "High": "8788.2",
      "Low": "8614.65",
      "Close": "8634.6"
  },
  {
      "Date": "3/20/15",
      "Open": "8627.9",
      "High": "8627.9",
      "Low": "8553",
      "Close": "8570.9"
  },
  {
      "Date": "3/23/15",
      "Open": "8591.55",
      "High": "8608.35",
      "Low": "8540.55",
      "Close": "8550.9"
  },
  {
      "Date": "3/24/15",
      "Open": "8537.05",
      "High": "8627.75",
      "Low": "8535.85",
      "Close": "8542.9"
  },
  {
      "Date": "3/25/15",
      "Open": "8568.9",
      "High": "8573.75",
      "Low": "8516.55",
      "Close": "8530.8"
  },
  {
      "Date": "3/26/15",
      "Open": "8474.95",
      "High": "8499.45",
      "Low": "8325.35",
      "Close": "8342.1"
  },
  {
      "Date": "3/27/15",
      "Open": "8396",
      "High": "8413.2",
      "Low": "8269.15",
      "Close": "8341.4"
  },
  {
      "Date": "3/30/15",
      "Open": "8390.95",
      "High": "8504.55",
      "Low": "8380.75",
      "Close": "8492.3"
  },
  {
      "Date": "3/31/15",
      "Open": "8527.6",
      "High": "8550.45",
      "Low": "8454.15",
      "Close": "8491"
  },
  {
      "Date": "4/1/15",
      "Open": "8483.7",
      "High": "8603.4",
      "Low": "8464.75",
      "Close": "8586.2"
  },
  {
      "Date": "4/6/15",
      "Open": "8615.8",
      "High": "8667.55",
      "Low": "8573.75",
      "Close": "8659.9"
  },
  {
      "Date": "4/7/15",
      "Open": "8684.45",
      "High": "8693.6",
      "Low": "8586.85",
      "Close": "8660.3"
  },
  {
      "Date": "4/8/15",
      "Open": "8698.95",
      "High": "8730.5",
      "Low": "8679.8",
      "Close": "8714.4"
  },
  {
      "Date": "4/9/15",
      "Open": "8756.2",
      "High": "8785.5",
      "Low": "8682.45",
      "Close": "8778.3"
  },
  {
      "Date": "4/10/15",
      "Open": "8774.35",
      "High": "8787.4",
      "Low": "8733.6",
      "Close": "8780.3"
  },
  {
      "Date": "4/13/15",
      "Open": "8801.75",
      "High": "8841.65",
      "Low": "8762.1",
      "Close": "8834"
  },
  {
      "Date": "4/15/15",
      "Open": "8844.75",
      "High": "8844.8",
      "Low": "8722.4",
      "Close": "8750.2"
  },
  {
      "Date": "4/16/15",
      "Open": "8757.05",
      "High": "8760",
      "Low": "8645.65",
      "Close": "8706.7"
  },
  {
      "Date": "4/17/15",
      "Open": "8698.05",
      "High": "8699.85",
      "Low": "8596.7",
      "Close": "8606"
  },
  {
      "Date": "4/20/15",
      "Open": "8618.8",
      "High": "8619.95",
      "Low": "8422.75",
      "Close": "8448.1"
  },
  {
      "Date": "4/21/15",
      "Open": "8416.1",
      "High": "8469.35",
      "Low": "8352.7",
      "Close": "8377.7"
  },
  {
      "Date": "4/22/15",
      "Open": "8400.4",
      "High": "8449.95",
      "Low": "8284.7",
      "Close": "8429.7"
  },
  {
      "Date": "4/23/15",
      "Open": "8478.2",
      "High": "8504.95",
      "Low": "8361.85",
      "Close": "8398.3"
  },
  {
      "Date": "4/24/15",
      "Open": "8405.7",
      "High": "8413.3",
      "Low": "8273.35",
      "Close": "8305.2"
  },
  {
      "Date": "4/27/15",
      "Open": "8330.55",
      "High": "8334.45",
      "Low": "8202.35",
      "Close": "8213.8"
  },
  {
      "Date": "4/28/15",
      "Open": "8215.55",
      "High": "8308",
      "Low": "8185.15",
      "Close": "8285.6"
  },
  {
      "Date": "4/29/15",
      "Open": "8274.8",
      "High": "8308.2",
      "Low": "8219.2",
      "Close": "8239.7"
  },
  {
      "Date": "4/30/15",
      "Open": "8224.5",
      "High": "8229.4",
      "Low": "8144.75",
      "Close": "8181.5"
  },
  {
      "Date": "5/4/15",
      "Open": "8230.05",
      "High": "8346",
      "Low": "8220.45",
      "Close": "8331.9"
  },
  {
      "Date": "5/5/15",
      "Open": "8338.4",
      "High": "8355.65",
      "Low": "8280.6",
      "Close": "8324.8"
  },
  {
      "Date": "5/6/15",
      "Open": "8316.6",
      "High": "8331.95",
      "Low": "8083",
      "Close": "8097"
  },
  {
      "Date": "5/7/15",
      "Open": "8077",
      "High": "8122.6",
      "Low": "7997.15",
      "Close": "8057.3"
  },
  {
      "Date": "5/8/15",
      "Open": "8131.5",
      "High": "8224.95",
      "Low": "8123.45",
      "Close": "8191.5"
  },
  {
      "Date": "5/11/15",
      "Open": "8243.2",
      "High": "8332.75",
      "Low": "8224.65",
      "Close": "8325.2"
  },
  {
      "Date": "5/12/15",
      "Open": "8326.15",
      "High": "8326.65",
      "Low": "8115.3",
      "Close": "8126.9"
  },
  {
      "Date": "5/13/15",
      "Open": "8181.55",
      "High": "8254.95",
      "Low": "8089.8",
      "Close": "8235.4"
  },
  {
      "Date": "5/14/15",
      "Open": "8232.45",
      "High": "8236.25",
      "Low": "8137.3",
      "Close": "8224.2"
  },
  {
      "Date": "5/15/15",
      "Open": "8240.3",
      "High": "8279.2",
      "Low": "8212.2",
      "Close": "8262.3"
  },
  {
      "Date": "5/18/15",
      "Open": "8284.95",
      "High": "8384.6",
      "Low": "8271.95",
      "Close": "8373.6"
  },
  {
      "Date": "5/19/15",
      "Open": "8356.2",
      "High": "8427.8",
      "Low": "8335",
      "Close": "8365.6"
  },
  {
      "Date": "5/20/15",
      "Open": "8392.65",
      "High": "8440.35",
      "Low": "8391.45",
      "Close": "8423.2"
  },
  {
      "Date": "5/21/15",
      "Open": "8434.5",
      "High": "8446.35",
      "Low": "8382.5",
      "Close": "8421"
  },
  {
      "Date": "5/22/15",
      "Open": "8432.5",
      "High": "8489.55",
      "Low": "8420.6",
      "Close": "8458.9"
  },
  {
      "Date": "5/25/15",
      "Open": "8438.15",
      "High": "8441.95",
      "Low": "8364.15",
      "Close": "8370.2"
  },
  {
      "Date": "5/26/15",
      "Open": "8377.1",
      "High": "8378.9",
      "Low": "8320.05",
      "Close": "8339.3"
  },
  {
      "Date": "5/27/15",
      "Open": "8302.75",
      "High": "8342.85",
      "Low": "8277.95",
      "Close": "8334.6"
  },
  {
      "Date": "5/28/15",
      "Open": "8345.7",
      "High": "8364.5",
      "Low": "8270.15",
      "Close": "8319"
  },
  {
      "Date": "5/29/15",
      "Open": "8327.1",
      "High": "8443.9",
      "Low": "8305.7",
      "Close": "8433.6"
  },
  {
      "Date": "6/1/15",
      "Open": "8417.25",
      "High": "8467.15",
      "Low": "8405.4",
      "Close": "8433.4"
  },
  {
      "Date": "6/2/15",
      "Open": "8442.8",
      "High": "8445.35",
      "Low": "8226.05",
      "Close": "8236.4"
  },
  {
      "Date": "6/3/15",
      "Open": "8232.45",
      "High": "8236.7",
      "Low": "8094.15",
      "Close": "8135.1"
  },
  {
      "Date": "6/4/15",
      "Open": "8155.15",
      "High": "8160.05",
      "Low": "8056.75",
      "Close": "8130.6"
  },
  {
      "Date": "6/5/15",
      "Open": "8119.15",
      "High": "8191",
      "Low": "8100.15",
      "Close": "8114.7"
  },
  {
      "Date": "6/8/15",
      "Open": "8124.35",
      "High": "8131",
      "Low": "8030.55",
      "Close": "8044.1"
  },
  {
      "Date": "6/9/15",
      "Open": "8026.5",
      "High": "8057.15",
      "Low": "8005.15",
      "Close": "8022.4"
  },
  {
      "Date": "6/10/15",
      "Open": "8024.15",
      "High": "8152.25",
      "Low": "8023.8",
      "Close": "8124.4"
  },
  {
      "Date": "6/11/15",
      "Open": "8157.3",
      "High": "8163.05",
      "Low": "7958.25",
      "Close": "7965.3"
  },
  {
      "Date": "6/12/15",
      "Open": "7959.85",
      "High": "7995.6",
      "Low": "7940.3",
      "Close": "7982.9"
  },
  {
      "Date": "6/15/15",
      "Open": "7986.6",
      "High": "8057.7",
      "Low": "7944.85",
      "Close": "8013.9"
  },
  {
      "Date": "6/16/15",
      "Open": "8004.2",
      "High": "8061.85",
      "Low": "7952.35",
      "Close": "8047.3"
  },
  {
      "Date": "6/17/15",
      "Open": "8084.2",
      "High": "8136.85",
      "Low": "8048.95",
      "Close": "8091.5"
  },
  {
      "Date": "6/18/15",
      "Open": "8113.7",
      "High": "8186.9",
      "Low": "8101.8",
      "Close": "8174.6"
  },
  {
      "Date": "6/19/15",
      "Open": "8201.15",
      "High": "8250.8",
      "Low": "8195.65",
      "Close": "8224.9"
  },
  {
      "Date": "6/22/15",
      "Open": "8259.3",
      "High": "8369.45",
      "Low": "8257.4",
      "Close": "8353.1"
  },
  {
      "Date": "6/23/15",
      "Open": "8377.45",
      "High": "8398.45",
      "Low": "8334.95",
      "Close": "8381.5"
  },
  {
      "Date": "6/24/15",
      "Open": "8399.4",
      "High": "8421.35",
      "Low": "8338.9",
      "Close": "8360.8"
  },
  {
      "Date": "6/25/15",
      "Open": "8336.25",
      "High": "8423.15",
      "Low": "8329.5",
      "Close": "8398"
  },
  {
      "Date": "6/26/15",
      "Open": "8393.95",
      "High": "8408.55",
      "Low": "8339.7",
      "Close": "8381.1"
  },
  {
      "Date": "6/29/15",
      "Open": "8247.05",
      "High": "8329.45",
      "Low": "8195.65",
      "Close": "8318.4"
  },
  {
      "Date": "6/30/15",
      "Open": "8316.35",
      "High": "8378",
      "Low": "8298.95",
      "Close": "8368.5"
  },
  {
      "Date": "7/1/15",
      "Open": "8376.25",
      "High": "8481.6",
      "Low": "8370.15",
      "Close": "8453"
  },
  {
      "Date": "7/2/15",
      "Open": "8471.95",
      "High": "8479.25",
      "Low": "8433.2",
      "Close": "8444.9"
  },
  {
      "Date": "7/3/15",
      "Open": "8440.1",
      "High": "8497.75",
      "Low": "8424.15",
      "Close": "8484.9"
  },
  {
      "Date": "7/6/15",
      "Open": "8386.15",
      "High": "8533.15",
      "Low": "8386.15",
      "Close": "8522.1"
  },
  {
      "Date": "7/7/15",
      "Open": "8525.5",
      "High": "8561.35",
      "Low": "8483.85",
      "Close": "8510.8"
  },
  {
      "Date": "7/8/15",
      "Open": "8439.2",
      "High": "8457.5",
      "Low": "8341.4",
      "Close": "8363"
  },
  {
      "Date": "7/9/15",
      "Open": "8364.85",
      "High": "8400.3",
      "Low": "8323",
      "Close": "8328.5"
  },
  {
      "Date": "7/10/15",
      "Open": "8365.7",
      "High": "8377.1",
      "Low": "8315.4",
      "Close": "8360.5"
  },
  {
      "Date": "7/13/15",
      "Open": "8397.25",
      "High": "8471.65",
      "Low": "8355.4",
      "Close": "8459.6"
  },
  {
      "Date": "7/14/15",
      "Open": "8470.6",
      "High": "8480.25",
      "Low": "8424.1",
      "Close": "8454.1"
  },
  {
      "Date": "7/15/15",
      "Open": "8463.4",
      "High": "8531.4",
      "Low": "8462.95",
      "Close": "8523.8"
  },
  {
      "Date": "7/16/15",
      "Open": "8546.15",
      "High": "8616.1",
      "Low": "8542.9",
      "Close": "8608"
  },
  {
      "Date": "7/17/15",
      "Open": "8623.65",
      "High": "8642.95",
      "Low": "8593.15",
      "Close": "8609.8"
  },
  {
      "Date": "7/20/15",
      "Open": "8623.9",
      "High": "8624.1",
      "Low": "8559",
      "Close": "8603.4"
  },
  {
      "Date": "7/21/15",
      "Open": "8601.5",
      "High": "8646.75",
      "Low": "8517.9",
      "Close": "8529.4"
  },
  {
      "Date": "7/22/15",
      "Open": "8512.25",
      "High": "8643.9",
      "Low": "8498.65",
      "Close": "8633.5"
  },
  {
      "Date": "7/23/15",
      "Open": "8643.95",
      "High": "8654.75",
      "Low": "8573.8",
      "Close": "8589.8"
  },
  {
      "Date": "7/24/15",
      "Open": "8588.15",
      "High": "8589.15",
      "Low": "8513.5",
      "Close": "8521.5"
  },
  {
      "Date": "7/27/15",
      "Open": "8492.1",
      "High": "8492.2",
      "Low": "8351.55",
      "Close": "8361"
  },
  {
      "Date": "7/28/15",
      "Open": "8371.1",
      "High": "8397.4",
      "Low": "8321.75",
      "Close": "8337"
  },
  {
      "Date": "7/29/15",
      "Open": "8365.6",
      "High": "8381.5",
      "Low": "8338.45",
      "Close": "8375"
  },
  {
      "Date": "7/30/15",
      "Open": "8417",
      "High": "8458.9",
      "Low": "8408.3",
      "Close": "8421.8"
  },
  {
      "Date": "7/31/15",
      "Open": "8456.1",
      "High": "8548.95",
      "Low": "8448",
      "Close": "8532.8"
  },
  {
      "Date": "8/3/15",
      "Open": "8510.65",
      "High": "8563.95",
      "Low": "8508.1",
      "Close": "8543"
  },
  {
      "Date": "8/4/15",
      "Open": "8564.1",
      "High": "8565.15",
      "Low": "8448.25",
      "Close": "8516.9"
  },
  {
      "Date": "8/5/15",
      "Open": "8547.45",
      "High": "8591.85",
      "Low": "8545.85",
      "Close": "8567.9"
  },
  {
      "Date": "8/6/15",
      "Open": "8585.8",
      "High": "8606.3",
      "Low": "8551.5",
      "Close": "8588.6"
  },
  {
      "Date": "8/7/15",
      "Open": "8580.8",
      "High": "8595.95",
      "Low": "8552.7",
      "Close": "8564.6"
  },
  {
      "Date": "8/10/15",
      "Open": "8577",
      "High": "8621.55",
      "Low": "8497.8",
      "Close": "8525.6"
  },
  {
      "Date": "8/11/15",
      "Open": "8548.45",
      "High": "8556.25",
      "Low": "8441.3",
      "Close": "8462.3"
  },
  {
      "Date": "8/12/15",
      "Open": "8445.7",
      "High": "8446.95",
      "Low": "8337.95",
      "Close": "8349.4"
  },
  {
      "Date": "8/13/15",
      "Open": "8384.45",
      "High": "8429.5",
      "Low": "8339.75",
      "Close": "8355.8"
  },
  {
      "Date": "8/14/15",
      "Open": "8402.35",
      "High": "8530.1",
      "Low": "8381.2",
      "Close": "8518.5"
  },
  {
      "Date": "8/17/15",
      "Open": "8530.5",
      "High": "8530.6",
      "Low": "8428.05",
      "Close": "8477.3"
  },
  {
      "Date": "8/18/15",
      "Open": "8505.85",
      "High": "8525.75",
      "Low": "8433.6",
      "Close": "8466.5"
  },
  {
      "Date": "8/19/15",
      "Open": "8468.05",
      "High": "8520.45",
      "Low": "8425.95",
      "Close": "8495.1"
  },
  {
      "Date": "8/20/15",
      "Open": "8471.05",
      "High": "8501.35",
      "Low": "8359.75",
      "Close": "8372.7"
  },
  {
      "Date": "8/21/15",
      "Open": "8305.4",
      "High": "8322.2",
      "Low": "8225.05",
      "Close": "8299.9"
  },
  {
      "Date": "8/24/15",
      "Open": "8055.95",
      "High": "8060.05",
      "Low": "7769.4",
      "Close": "7809"
  },
  {
      "Date": "8/25/15",
      "Open": "7895.4",
      "High": "7925.4",
      "Low": "7667.25",
      "Close": "7880.7"
  },
  {
      "Date": "8/26/15",
      "Open": "7865.25",
      "High": "7930.05",
      "Low": "7777.1",
      "Close": "7791.8"
  },
  {
      "Date": "8/27/15",
      "Open": "7921.6",
      "High": "7963.6",
      "Low": "7862.3",
      "Close": "7948.9"
  },
  {
      "Date": "8/28/15",
      "Open": "8053.7",
      "High": "8091.8",
      "Low": "7961.65",
      "Close": "8001.9"
  },
  {
      "Date": "8/31/15",
      "Open": "8009.25",
      "High": "8043.6",
      "Low": "7947.95",
      "Close": "7971.3"
  },
  {
      "Date": "9/1/15",
      "Open": "7907.95",
      "High": "7929.1",
      "Low": "7746.5",
      "Close": "7785.8"
  },
  {
      "Date": "9/2/15",
      "Open": "7856.65",
      "High": "7862.55",
      "Low": "7699.25",
      "Close": "7717"
  },
  {
      "Date": "9/3/15",
      "Open": "7774.45",
      "High": "7845.6",
      "Low": "7754.05",
      "Close": "7823"
  },
  {
      "Date": "9/4/15",
      "Open": "7803.4",
      "High": "7804.9",
      "Low": "7626.85",
      "Close": "7655"
  },
  {
      "Date": "9/7/15",
      "Open": "7685.85",
      "High": "7705.05",
      "Low": "7545.9",
      "Close": "7558.8"
  },
  {
      "Date": "9/8/15",
      "Open": "7587.7",
      "High": "7720.9",
      "Low": "7539.5",
      "Close": "7688.2"
  },
  {
      "Date": "9/9/15",
      "Open": "7805.85",
      "High": "7846.05",
      "Low": "7764.55",
      "Close": "7818.6"
  },
  {
      "Date": "9/10/15",
      "Open": "7729.05",
      "High": "7819.85",
      "Low": "7678.5",
      "Close": "7788.1"
  },
  {
      "Date": "9/11/15",
      "Open": "7833.8",
      "High": "7864.85",
      "Low": "7759.9",
      "Close": "7789.3"
  },
  {
      "Date": "9/14/15",
      "Open": "7811.1",
      "High": "7879.95",
      "Low": "7761.85",
      "Close": "7872.2"
  },
  {
      "Date": "9/15/15",
      "Open": "7867.35",
      "High": "7880",
      "Low": "7799.75",
      "Close": "7829.1"
  },
  {
      "Date": "9/16/15",
      "Open": "7886.85",
      "High": "7913.9",
      "Low": "7853.3",
      "Close": "7899.1"
  },
  {
      "Date": "9/18/15",
      "Open": "7967.45",
      "High": "8055",
      "Low": "7955.85",
      "Close": "7981.9"
  },
  {
      "Date": "9/21/15",
      "Open": "7911.5",
      "High": "7987.9",
      "Low": "7908.35",
      "Close": "7977.1"
  },
  {
      "Date": "9/22/15",
      "Open": "8014.8",
      "High": "8021.6",
      "Low": "7787.75",
      "Close": "7812"
  },
  {
      "Date": "9/23/15",
      "Open": "7737.35",
      "High": "7882.9",
      "Low": "7723.25",
      "Close": "7845.9"
  },
  {
      "Date": "9/24/15",
      "Open": "7838.4",
      "High": "7894.5",
      "Low": "7804.1",
      "Close": "7868.5"
  },
  {
      "Date": "9/28/15",
      "Open": "7892.8",
      "High": "7893.95",
      "Low": "7787.95",
      "Close": "7795.7"
  },
  {
      "Date": "9/29/15",
      "Open": "7725.7",
      "High": "7926.55",
      "Low": "7691.2",
      "Close": "7843.3"
  },
  {
      "Date": "9/30/15",
      "Open": "7924.25",
      "High": "7957.7",
      "Low": "7874.5",
      "Close": "7948.9"
  },
  {
      "Date": "10/1/15",
      "Open": "7992.05",
      "High": "8008.25",
      "Low": "7930.65",
      "Close": "7950.9"
  },
  {
      "Date": "10/5/15",
      "Open": "8005.1",
      "High": "8128.9",
      "Low": "8005.1",
      "Close": "8119.3"
  },
  {
      "Date": "10/6/15",
      "Open": "8180.45",
      "High": "8180.95",
      "Low": "8096.5",
      "Close": "8152.9"
  },
  {
      "Date": "10/7/15",
      "Open": "8146.2",
      "High": "8188.9",
      "Low": "8132.9",
      "Close": "8177.4"
  },
  {
      "Date": "10/8/15",
      "Open": "8196.75",
      "High": "8196.75",
      "Low": "8105.85",
      "Close": "8129.3"
  },
  {
      "Date": "10/9/15",
      "Open": "8186.35",
      "High": "8232.2",
      "Low": "8139.65",
      "Close": "8189.7"
  },
  {
      "Date": "10/12/15",
      "Open": "8231.5",
      "High": "8244.5",
      "Low": "8128.2",
      "Close": "8143.6"
  },
  {
      "Date": "10/13/15",
      "Open": "8121.95",
      "High": "8150.25",
      "Low": "8088.6",
      "Close": "8131.7"
  },
  {
      "Date": "10/14/15",
      "Open": "8102.4",
      "High": "8139.3",
      "Low": "8096.35",
      "Close": "8107.9"
  },
  {
      "Date": "10/15/15",
      "Open": "8134.35",
      "High": "8190.55",
      "Low": "8129.8",
      "Close": "8179.5"
  },
  {
      "Date": "10/16/15",
      "Open": "8193.65",
      "High": "8246.4",
      "Low": "8147.65",
      "Close": "8238.1"
  },
  {
      "Date": "10/19/15",
      "Open": "8262.55",
      "High": "8283.05",
      "Low": "8239.2",
      "Close": "8275"
  },
  {
      "Date": "10/20/15",
      "Open": "8280.3",
      "High": "8294.05",
      "Low": "8229.2",
      "Close": "8261.6"
  },
  {
      "Date": "10/21/15",
      "Open": "8258.35",
      "High": "8294.4",
      "Low": "8217.15",
      "Close": "8251.7"
  },
  {
      "Date": "10/23/15",
      "Open": "8308.25",
      "High": "8328.1",
      "Low": "8280.75",
      "Close": "8295.4"
  },
  {
      "Date": "10/26/15",
      "Open": "8333.65",
      "High": "8336.3",
      "Low": "8252.05",
      "Close": "8260.5"
  },
  {
      "Date": "10/27/15",
      "Open": "8230.35",
      "High": "8241.95",
      "Low": "8217.05",
      "Close": "8232.9"
  },
  {
      "Date": "10/28/15",
      "Open": "8188.9",
      "High": "8209.1",
      "Low": "8131.8",
      "Close": "8171.2"
  },
  {
      "Date": "10/29/15",
      "Open": "8175.45",
      "High": "8179.6",
      "Low": "8098",
      "Close": "8111.7"
  },
  {
      "Date": "10/30/15",
      "Open": "8123.55",
      "High": "8146.1",
      "Low": "8044.4",
      "Close": "8065.8"
  },
  {
      "Date": "11/2/15",
      "Open": "8054.55",
      "High": "8060.7",
      "Low": "7995.6",
      "Close": "8050.8"
  },
  {
      "Date": "11/3/15",
      "Open": "8086.35",
      "High": "8100.35",
      "Low": "8031.75",
      "Close": "8060.7"
  },
  {
      "Date": "11/4/15",
      "Open": "8104.9",
      "High": "8116.1",
      "Low": "8027.3",
      "Close": "8040.2"
  },
  {
      "Date": "11/5/15",
      "Open": "8030.35",
      "High": "8031.2",
      "Low": "7944.1",
      "Close": "7955.4"
  },
  {
      "Date": "11/6/15",
      "Open": "7956.55",
      "High": "8002.65",
      "Low": "7926.15",
      "Close": "7954.3"
  },
  {
      "Date": "11/9/15",
      "Open": "7788.25",
      "High": "7937.75",
      "Low": "7771.7",
      "Close": "7915.2"
  },
  {
      "Date": "11/10/15",
      "Open": "7877.6",
      "High": "7885.1",
      "Low": "7772.85",
      "Close": "7783.3"
  },
  {
      "Date": "11/11/15",
      "Open": "7838.8",
      "High": "7847.95",
      "Low": "7819.1",
      "Close": "7825"
  },
  {
      "Date": "11/13/15",
      "Open": "7762.45",
      "High": "7775.1",
      "Low": "7730.9",
      "Close": "7762.2"
  },
  {
      "Date": "11/16/15",
      "Open": "7732.95",
      "High": "7838.85",
      "Low": "7714.15",
      "Close": "7806.6"
  },
  {
      "Date": "11/17/15",
      "Open": "7848.75",
      "High": "7860.45",
      "Low": "7793",
      "Close": "7837.5"
  },
  {
      "Date": "11/18/15",
      "Open": "7823.15",
      "High": "7843.4",
      "Low": "7725.05",
      "Close": "7731.8"
  },
  {
      "Date": "11/19/15",
      "Open": "7788.5",
      "High": "7854.9",
      "Low": "7765.45",
      "Close": "7842.7"
  },
  {
      "Date": "11/20/15",
      "Open": "7841.9",
      "High": "7906.95",
      "Low": "7817.8",
      "Close": "7856.5"
  },
  {
      "Date": "11/23/15",
      "Open": "7869.5",
      "High": "7877.5",
      "Low": "7825.2",
      "Close": "7849.2"
  },
  {
      "Date": "11/24/15",
      "Open": "7837",
      "High": "7870.35",
      "Low": "7812.65",
      "Close": "7831.6"
  },
  {
      "Date": "11/26/15",
      "Open": "7837.15",
      "High": "7897.1",
      "Low": "7832",
      "Close": "7883.8"
  },
  {
      "Date": "11/27/15",
      "Open": "7910.6",
      "High": "7959.3",
      "Low": "7879.45",
      "Close": "7942.7"
  },
  {
      "Date": "11/30/15",
      "Open": "7936.25",
      "High": "7966",
      "Low": "7922.8",
      "Close": "7935.2"
  },
  {
      "Date": "12/1/15",
      "Open": "7958.15",
      "High": "7972.15",
      "Low": "7934.15",
      "Close": "7954.9"
  },
  {
      "Date": "12/2/15",
      "Open": "7976.7",
      "High": "7979.3",
      "Low": "7910.8",
      "Close": "7931.3"
  },
  {
      "Date": "12/3/15",
      "Open": "7902.3",
      "High": "7912.3",
      "Low": "7853.3",
      "Close": "7864.1"
  },
  {
      "Date": "12/4/15",
      "Open": "7817.6",
      "High": "7821.4",
      "Low": "7775.7",
      "Close": "7781.9"
  },
  {
      "Date": "12/7/15",
      "Open": "7816.55",
      "High": "7825.4",
      "Low": "7746.05",
      "Close": "7765.4"
  },
  {
      "Date": "12/8/15",
      "Open": "7738.5",
      "High": "7771.25",
      "Low": "7685.45",
      "Close": "7701.7"
  },
  {
      "Date": "12/9/15",
      "Open": "7695.5",
      "High": "7702.85",
      "Low": "7606.9",
      "Close": "7612.5"
  },
  {
      "Date": "12/10/15",
      "Open": "7643.3",
      "High": "7691.95",
      "Low": "7610",
      "Close": "7683.3"
  },
  {
      "Date": "12/11/15",
      "Open": "7699.6",
      "High": "7703.05",
      "Low": "7575.3",
      "Close": "7610.4"
  },
  {
      "Date": "12/14/15",
      "Open": "7558.2",
      "High": "7663.95",
      "Low": "7551.05",
      "Close": "7650"
  },
  {
      "Date": "12/15/15",
      "Open": "7659.15",
      "High": "7705",
      "Low": "7625.1",
      "Close": "7700.9"
  },
  {
      "Date": "12/16/15",
      "Open": "7725.25",
      "High": "7776.6",
      "Low": "7715.75",
      "Close": "7750.9"
  },
  {
      "Date": "12/17/15",
      "Open": "7783.05",
      "High": "7852.9",
      "Low": "7737.55",
      "Close": "7844.3"
  },
  {
      "Date": "12/18/15",
      "Open": "7828.9",
      "High": "7836.15",
      "Low": "7753.35",
      "Close": "7761.9"
  },
  {
      "Date": "12/21/15",
      "Open": "7745.65",
      "High": "7840.75",
      "Low": "7733.45",
      "Close": "7834.4"
  },
  {
      "Date": "12/22/15",
      "Open": "7829.4",
      "High": "7846.3",
      "Low": "7776.85",
      "Close": "7786.1"
  },
  {
      "Date": "12/23/15",
      "Open": "7830.45",
      "High": "7871.45",
      "Low": "7826.1",
      "Close": "7865.9"
  },
  {
      "Date": "12/24/15",
      "Open": "7888.75",
      "High": "7888.75",
      "Low": "7835.5",
      "Close": "7861"
  },
  {
      "Date": "12/28/15",
      "Open": "7863.2",
      "High": "7937.2",
      "Low": "7863",
      "Close": "7925.1"
  },
  {
      "Date": "12/29/15",
      "Open": "7929.2",
      "High": "7942.15",
      "Low": "7902.75",
      "Close": "7928.9"
  },
  {
      "Date": "12/30/15",
      "Open": "7938.6",
      "High": "7944.75",
      "Low": "7889.85",
      "Close": "7896.2"
  },
  {
      "Date": "12/31/15",
      "Open": "7897.8",
      "High": "7955.55",
      "Low": "7891.15",
      "Close": "7946.3"
  },
  {
      "Date": "1/1/16",
      "Open": "7938.45",
      "High": "7972.55",
      "Low": "7909.8",
      "Close": "7963.2"
  },
  {
      "Date": "1/4/16",
      "Open": "7924.55",
      "High": "7937.55",
      "Low": "7781.1",
      "Close": "7791.3"
  },
  {
      "Date": "1/5/16",
      "Open": "7828.4",
      "High": "7831.2",
      "Low": "7763.25",
      "Close": "7784.6"
  },
  {
      "Date": "1/6/16",
      "Open": "7788.05",
      "High": "7800.95",
      "Low": "7721.2",
      "Close": "7741"
  },
  {
      "Date": "1/7/16",
      "Open": "7673.35",
      "High": "7674.95",
      "Low": "7556.6",
      "Close": "7568.3"
  },
  {
      "Date": "1/8/16",
      "Open": "7611.65",
      "High": "7634.1",
      "Low": "7581.05",
      "Close": "7601.3"
  },
  {
      "Date": "1/11/16",
      "Open": "7527.45",
      "High": "7605.1",
      "Low": "7494.35",
      "Close": "7563.8"
  },
  {
      "Date": "1/12/16",
      "Open": "7587.2",
      "High": "7588.3",
      "Low": "7487.8",
      "Close": "7510.3"
  },
  {
      "Date": "1/13/16",
      "Open": "7557.9",
      "High": "7590.95",
      "Low": "7425.8",
      "Close": "7562.4"
  },
  {
      "Date": "1/14/16",
      "Open": "7467.4",
      "High": "7604.8",
      "Low": "7443.8",
      "Close": "7536.8"
  },
  {
      "Date": "1/15/16",
      "Open": "7561.65",
      "High": "7566.5",
      "Low": "7427.3",
      "Close": "7437.8"
  },
  {
      "Date": "1/18/16",
      "Open": "7420.35",
      "High": "7463.65",
      "Low": "7336.4",
      "Close": "7351"
  },
  {
      "Date": "1/19/16",
      "Open": "7381.8",
      "High": "7462.75",
      "Low": "7364.15",
      "Close": "7435.1"
  },
  {
      "Date": "1/20/16",
      "Open": "7357",
      "High": "7470.9",
      "Low": "7241.5",
      "Close": "7309.3"
  },
  {
      "Date": "1/21/16",
      "Open": "7376.65",
      "High": "7398.7",
      "Low": "7250",
      "Close": "7276.8"
  },
  {
      "Date": "1/22/16",
      "Open": "7355.7",
      "High": "7433.4",
      "Low": "7327.6",
      "Close": "7422.4"
  },
  {
      "Date": "1/25/16",
      "Open": "7468.75",
      "High": "7487.15",
      "Low": "7421.2",
      "Close": "7436.1"
  },
  {
      "Date": "1/27/16",
      "Open": "7469.6",
      "High": "7477.9",
      "Low": "7419.7",
      "Close": "7437.7"
  },
  {
      "Date": "1/28/16",
      "Open": "7426.5",
      "High": "7468.85",
      "Low": "7409.6",
      "Close": "7424.6"
  },
  {
      "Date": "1/29/16",
      "Open": "7413.35",
      "High": "7575.65",
      "Low": "7402.8",
      "Close": "7563.5"
  },
  {
      "Date": "2/1/16",
      "Open": "7589.5",
      "High": "7600.45",
      "Low": "7541.25",
      "Close": "7555.9"
  },
  {
      "Date": "2/2/16",
      "Open": "7566.65",
      "High": "7576.3",
      "Low": "7428.05",
      "Close": "7455.5"
  },
  {
      "Date": "2/3/16",
      "Open": "7392.45",
      "High": "7419.4",
      "Low": "7350.3",
      "Close": "7361.8"
  },
  {
      "Date": "2/4/16",
      "Open": "7411.45",
      "High": "7457.05",
      "Low": "7365.95",
      "Close": "7404"
  },
  {
      "Date": "2/5/16",
      "Open": "7418.25",
      "High": "7503.15",
      "Low": "7406.65",
      "Close": "7489.1"
  },
  {
      "Date": "2/8/16",
      "Open": "7489.7",
      "High": "7512.55",
      "Low": "7363.2",
      "Close": "7387.2"
  },
  {
      "Date": "2/9/16",
      "Open": "7303.95",
      "High": "7323.45",
      "Low": "7275.15",
      "Close": "7298.2"
  },
  {
      "Date": "2/10/16",
      "Open": "7264.3",
      "High": "7271.85",
      "Low": "7177.75",
      "Close": "7215.7"
  },
  {
      "Date": "2/11/16",
      "Open": "7203.6",
      "High": "7208.65",
      "Low": "6959.95",
      "Close": "6976.3"
  },
  {
      "Date": "2/12/16",
      "Open": "7023.65",
      "High": "7034.8",
      "Low": "6869",
      "Close": "6980.9"
  },
  {
      "Date": "2/15/16",
      "Open": "7057.35",
      "High": "7182.8",
      "Low": "7056.8",
      "Close": "7162.9"
  },
  {
      "Date": "2/16/16",
      "Open": "7201.25",
      "High": "7204.65",
      "Low": "7037.7",
      "Close": "7048.2"
  },
  {
      "Date": "2/17/16",
      "Open": "7058.85",
      "High": "7123.7",
      "Low": "6960.65",
      "Close": "7108.4"
  },
  {
      "Date": "2/18/16",
      "Open": "7177.4",
      "High": "7215.1",
      "Low": "7127.85",
      "Close": "7191.7"
  },
  {
      "Date": "2/19/16",
      "Open": "7170.55",
      "High": "7226.85",
      "Low": "7145.95",
      "Close": "7210.7"
  },
  {
      "Date": "2/22/16",
      "Open": "7208.85",
      "High": "7252.4",
      "Low": "7200.7",
      "Close": "7234.5"
  },
  {
      "Date": "2/23/16",
      "Open": "7240.3",
      "High": "7241.7",
      "Low": "7090.7",
      "Close": "7109.5"
  },
  {
      "Date": "2/24/16",
      "Open": "7075",
      "High": "7090.8",
      "Low": "7009.75",
      "Close": "7018.7"
  },
  {
      "Date": "2/25/16",
      "Open": "7029.85",
      "High": "7034.2",
      "Low": "6961.4",
      "Close": "6970.6"
  },
  {
      "Date": "2/26/16",
      "Open": "7039.3",
      "High": "7052.9",
      "Low": "6985.1",
      "Close": "7029.7"
  },
  {
      "Date": "2/29/16",
      "Open": "7050.45",
      "High": "7094.6",
      "Low": "6825.8",
      "Close": "6987"
  },
  {
      "Date": "3/1/16",
      "Open": "7038.25",
      "High": "7235.5",
      "Low": "7035.1",
      "Close": "7222.3"
  },
  {
      "Date": "3/2/16",
      "Open": "7321.7",
      "High": "7380.35",
      "Low": "7308.15",
      "Close": "7368.8"
  },
  {
      "Date": "3/3/16",
      "Open": "7429.55",
      "High": "7483.95",
      "Low": "7406.05",
      "Close": "7475.6"
  },
  {
      "Date": "3/4/16",
      "Open": "7505.4",
      "High": "7505.9",
      "Low": "7444.1",
      "Close": "7485.3"
  },
  {
      "Date": "3/8/16",
      "Open": "7486.4",
      "High": "7527.15",
      "Low": "7442.15",
      "Close": "7485.3"
  },
  {
      "Date": "3/9/16",
      "Open": "7436.1",
      "High": "7539",
      "Low": "7424.3",
      "Close": "7531.8"
  },
  {
      "Date": "3/10/16",
      "Open": "7545.35",
      "High": "7547.1",
      "Low": "7447.4",
      "Close": "7486.1"
  },
  {
      "Date": "3/11/16",
      "Open": "7484.85",
      "High": "7543.95",
      "Low": "7460.6",
      "Close": "7510.2"
  },
  {
      "Date": "3/14/16",
      "Open": "7542.6",
      "High": "7583.7",
      "Low": "7515.05",
      "Close": "7538.7"
  },
  {
      "Date": "3/15/16",
      "Open": "7535.85",
      "High": "7545.2",
      "Low": "7452.8",
      "Close": "7460.6"
  },
  {
      "Date": "3/16/16",
      "Open": "7457.05",
      "High": "7508",
      "Low": "7405.15",
      "Close": "7498.7"
  },
  {
      "Date": "3/17/16",
      "Open": "7557.4",
      "High": "7585.3",
      "Low": "7479.4",
      "Close": "7512.5"
  },
  {
      "Date": "3/18/16",
      "Open": "7534.65",
      "High": "7613.6",
      "Low": "7517.9",
      "Close": "7604.3"
  },
  {
      "Date": "3/21/16",
      "Open": "7619.2",
      "High": "7713.55",
      "Low": "7617.7",
      "Close": "7704.2"
  },
  {
      "Date": "3/22/16",
      "Open": "7695.55",
      "High": "7728.2",
      "Low": "7643.8",
      "Close": "7714.9"
  },
  {
      "Date": "3/23/16",
      "Open": "7717.45",
      "High": "7726.85",
      "Low": "7670.6",
      "Close": "7716.5"
  },
  {
      "Date": "3/28/16",
      "Open": "7741",
      "High": "7749.4",
      "Low": "7587.7",
      "Close": "7615.1"
  },
  {
      "Date": "3/29/16",
      "Open": "7606.55",
      "High": "7652.9",
      "Low": "7582.25",
      "Close": "7597"
  },
  {
      "Date": "3/30/16",
      "Open": "7651.1",
      "High": "7741.95",
      "Low": "7643.45",
      "Close": "7735.2"
  },
  {
      "Date": "3/31/16",
      "Open": "7727.65",
      "High": "7777.6",
      "Low": "7702",
      "Close": "7738.4"
  },
  {
      "Date": "4/1/16",
      "Open": "7718.05",
      "High": "7740.15",
      "Low": "7666.1",
      "Close": "7713"
  },
  {
      "Date": "4/4/16",
      "Open": "7733.15",
      "High": "7764.45",
      "Low": "7704.4",
      "Close": "7758.8"
  },
  {
      "Date": "4/5/16",
      "Open": "7736.3",
      "High": "7736.3",
      "Low": "7588.65",
      "Close": "7603.2"
  },
  {
      "Date": "4/6/16",
      "Open": "7636.05",
      "High": "7638.65",
      "Low": "7591.75",
      "Close": "7614.3"
  },
  {
      "Date": "4/7/16",
      "Open": "7630.4",
      "High": "7630.75",
      "Low": "7535.85",
      "Close": "7546.4"
  },
  {
      "Date": "4/8/16",
      "Open": "7542.35",
      "High": "7569.35",
      "Low": "7526.7",
      "Close": "7555.2"
  },
  {
      "Date": "4/11/16",
      "Open": "7577.8",
      "High": "7678.8",
      "Low": "7516.85",
      "Close": "7671.4"
  },
  {
      "Date": "4/12/16",
      "Open": "7669.25",
      "High": "7717.4",
      "Low": "7663.35",
      "Close": "7708.9"
  },
  {
      "Date": "4/13/16",
      "Open": "7777.15",
      "High": "7864.8",
      "Low": "7772.2",
      "Close": "7850.4"
  },
  {
      "Date": "4/18/16",
      "Open": "7908.15",
      "High": "7920.6",
      "Low": "7842.75",
      "Close": "7914.7"
  },
  {
      "Date": "4/20/16",
      "Open": "7950.05",
      "High": "7950.4",
      "Low": "7877.55",
      "Close": "7914.7"
  },
  {
      "Date": "4/21/16",
      "Open": "7953.65",
      "High": "7978.45",
      "Low": "7884.1",
      "Close": "7912"
  },
  {
      "Date": "4/22/16",
      "Open": "7891.8",
      "High": "7923.35",
      "Low": "7873.35",
      "Close": "7899.3"
  },
  {
      "Date": "4/25/16",
      "Open": "7894.8",
      "High": "7911",
      "Low": "7827",
      "Close": "7855"
  },
  {
      "Date": "4/26/16",
      "Open": "7828.15",
      "High": "7974.5",
      "Low": "7822.55",
      "Close": "7962.6"
  },
  {
      "Date": "4/27/16",
      "Open": "7942",
      "High": "7991",
      "Low": "7940.55",
      "Close": "7979.9"
  },
  {
      "Date": "4/28/16",
      "Open": "7967.4",
      "High": "7992",
      "Low": "7834.45",
      "Close": "7847.2"
  },
  {
      "Date": "4/29/16",
      "Open": "7844.25",
      "High": "7889.05",
      "Low": "7788.7",
      "Close": "7849.8"
  },
  {
      "Date": "5/2/16",
      "Open": "7822.7",
      "High": "7829.8",
      "Low": "7777.3",
      "Close": "7805.9"
  },
  {
      "Date": "5/3/16",
      "Open": "7824.8",
      "High": "7890.25",
      "Low": "7735.15",
      "Close": "7747"
  },
  {
      "Date": "5/4/16",
      "Open": "7724.15",
      "High": "7749",
      "Low": "7697.25",
      "Close": "7706.5"
  },
  {
      "Date": "5/5/16",
      "Open": "7731",
      "High": "7777.55",
      "Low": "7706.85",
      "Close": "7735.5"
  },
  {
      "Date": "5/6/16",
      "Open": "7717.65",
      "High": "7738.9",
      "Low": "7678.35",
      "Close": "7733.4"
  },
  {
      "Date": "5/9/16",
      "Open": "7755.25",
      "High": "7873.65",
      "Low": "7753.55",
      "Close": "7866"
  },
  {
      "Date": "5/10/16",
      "Open": "7873.55",
      "High": "7896.9",
      "Low": "7837.7",
      "Close": "7887.8"
  },
  {
      "Date": "5/11/16",
      "Open": "7804.65",
      "High": "7893.1",
      "Low": "7780.9",
      "Close": "7848.8"
  },
  {
      "Date": "5/12/16",
      "Open": "7871.45",
      "High": "7916.05",
      "Low": "7849.65",
      "Close": "7900.4"
  },
  {
      "Date": "5/13/16",
      "Open": "7881",
      "High": "7881",
      "Low": "7784.2",
      "Close": "7814.9"
  },
  {
      "Date": "5/16/16",
      "Open": "7831.2",
      "High": "7873.9",
      "Low": "7772.15",
      "Close": "7860.7"
  },
  {
      "Date": "5/17/16",
      "Open": "7896.85",
      "High": "7940.1",
      "Low": "7879.7",
      "Close": "7890.7"
  },
  {
      "Date": "5/18/16",
      "Open": "7846.75",
      "High": "7882.05",
      "Low": "7810.75",
      "Close": "7870.1"
  },
  {
      "Date": "5/19/16",
      "Open": "7875.5",
      "High": "7876.2",
      "Low": "7766.8",
      "Close": "7783.4"
  },
  {
      "Date": "5/20/16",
      "Open": "7792.2",
      "High": "7812.4",
      "Low": "7735.75",
      "Close": "7749.7"
  },
  {
      "Date": "5/23/16",
      "Open": "7813.95",
      "High": "7820.6",
      "Low": "7722.2",
      "Close": "7731"
  },
  {
      "Date": "5/24/16",
      "Open": "7738.05",
      "High": "7761.55",
      "Low": "7715.8",
      "Close": "7748.8"
  },
  {
      "Date": "5/25/16",
      "Open": "7811.8",
      "High": "7941.2",
      "Low": "7809.3",
      "Close": "7934.9"
  },
  {
      "Date": "5/26/16",
      "Open": "7974.45",
      "High": "8083",
      "Low": "7948.5",
      "Close": "8069.6"
  },
  {
      "Date": "5/27/16",
      "Open": "8081.95",
      "High": "8164.2",
      "Low": "8077.05",
      "Close": "8156.6"
  },
  {
      "Date": "5/30/16",
      "Open": "8166.5",
      "High": "8200",
      "Low": "8150.8",
      "Close": "8178.5"
  },
  {
      "Date": "5/31/16",
      "Open": "8209.85",
      "High": "8213.6",
      "Low": "8134.3",
      "Close": "8160.1"
  },
  {
      "Date": "6/1/16",
      "Open": "8179.2",
      "High": "8215.35",
      "Low": "8171.05",
      "Close": "8179.9"
  },
  {
      "Date": "6/2/16",
      "Open": "8156.9",
      "High": "8229.5",
      "Low": "8154.75",
      "Close": "8218.9"
  },
  {
      "Date": "6/3/16",
      "Open": "8246.2",
      "High": "8262",
      "Low": "8209.85",
      "Close": "8220.8"
  },
  {
      "Date": "6/6/16",
      "Open": "8228.75",
      "High": "8234.7",
      "Low": "8186.05",
      "Close": "8201"
  },
  {
      "Date": "6/7/16",
      "Open": "8235.55",
      "High": "8294.95",
      "Low": "8216.4",
      "Close": "8266.4"
  },
  {
      "Date": "6/8/16",
      "Open": "8285.5",
      "High": "8288.9",
      "Low": "8252.05",
      "Close": "8273"
  },
  {
      "Date": "6/9/16",
      "Open": "8273.35",
      "High": "8273.35",
      "Low": "8184.6",
      "Close": "8203.6"
  },
  {
      "Date": "6/10/16",
      "Open": "8180.25",
      "High": "8265.6",
      "Low": "8162.85",
      "Close": "8170"
  },
  {
      "Date": "6/13/16",
      "Open": "8102.25",
      "High": "8125.25",
      "Low": "8063.9",
      "Close": "8110.6"
  },
  {
      "Date": "6/14/16",
      "Open": "8134.4",
      "High": "8134.95",
      "Low": "8069.5",
      "Close": "8108.8"
  },
  {
      "Date": "6/15/16",
      "Open": "8139.4",
      "High": "8213.2",
      "Low": "8123.15",
      "Close": "8206.6"
  },
  {
      "Date": "6/16/16",
      "Open": "8180.65",
      "High": "8180.65",
      "Low": "8074.45",
      "Close": "8140.7"
  },
  {
      "Date": "6/17/16",
      "Open": "8176.65",
      "High": "8195.25",
      "Low": "8135.8",
      "Close": "8170.2"
  },
  {
      "Date": "6/20/16",
      "Open": "8115.75",
      "High": "8244.15",
      "Low": "8107.35",
      "Close": "8238.5"
  },
  {
      "Date": "6/21/16",
      "Open": "8255.4",
      "High": "8257.25",
      "Low": "8202.15",
      "Close": "8219.9"
  },
  {
      "Date": "6/22/16",
      "Open": "8213.65",
      "High": "8238.35",
      "Low": "8153.25",
      "Close": "8203.7"
  },
  {
      "Date": "6/23/16",
      "Open": "8201.15",
      "High": "8285.6",
      "Low": "8188.3",
      "Close": "8270.4"
  },
  {
      "Date": "6/24/16",
      "Open": "8029.1",
      "High": "8100.7",
      "Low": "7927.05",
      "Close": "8088.6"
  },
  {
      "Date": "6/27/16",
      "Open": "8039.35",
      "High": "8120.65",
      "Low": "8039.35",
      "Close": "8094.7"
  },
  {
      "Date": "6/28/16",
      "Open": "8096.05",
      "High": "8146.35",
      "Low": "8086.85",
      "Close": "8127.8"
  },
  {
      "Date": "6/29/16",
      "Open": "8173.1",
      "High": "8212.4",
      "Low": "8157.65",
      "Close": "8204"
  },
  {
      "Date": "6/30/16",
      "Open": "8260.25",
      "High": "8308.15",
      "Low": "8242.1",
      "Close": "8287.7"
  },
  {
      "Date": "7/1/16",
      "Open": "8313.05",
      "High": "8356.75",
      "Low": "8308.65",
      "Close": "8328.3"
  },
  {
      "Date": "7/4/16",
      "Open": "8376.75",
      "High": "8398.45",
      "Low": "8364.7",
      "Close": "8370.7"
  },
  {
      "Date": "7/5/16",
      "Open": "8379.3",
      "High": "8381.45",
      "Low": "8319.95",
      "Close": "8335.9"
  },
  {
      "Date": "7/7/16",
      "Open": "8342",
      "High": "8361.95",
      "Low": "8317.7",
      "Close": "8337.9"
  },
  {
      "Date": "7/8/16",
      "Open": "8350",
      "High": "8353.3",
      "Low": "8287.55",
      "Close": "8323.2"
  },
  {
      "Date": "7/11/16",
      "Open": "8413.35",
      "High": "8475.25",
      "Low": "8407.05",
      "Close": "8467.9"
  },
  {
      "Date": "7/12/16",
      "Open": "8502.6",
      "High": "8526.6",
      "Low": "8479.2",
      "Close": "8521"
  },
  {
      "Date": "7/13/16",
      "Open": "8540.45",
      "High": "8550.25",
      "Low": "8493.55",
      "Close": "8519.5"
  },
  {
      "Date": "7/14/16",
      "Open": "8515.75",
      "High": "8571.4",
      "Low": "8500.7",
      "Close": "8565"
  },
  {
      "Date": "7/15/16",
      "Open": "8565.45",
      "High": "8594.8",
      "Low": "8510.05",
      "Close": "8541.4"
  },
  {
      "Date": "7/18/16",
      "Open": "8564.05",
      "High": "8587.1",
      "Low": "8494.35",
      "Close": "8508.7"
  },
  {
      "Date": "7/19/16",
      "Open": "8514.3",
      "High": "8540.05",
      "Low": "8476.7",
      "Close": "8528.5"
  },
  {
      "Date": "7/20/16",
      "Open": "8515.45",
      "High": "8569.9",
      "Low": "8512.55",
      "Close": "8565.8"
  },
  {
      "Date": "7/21/16",
      "Open": "8582.7",
      "High": "8585.25",
      "Low": "8503.45",
      "Close": "8510.1"
  },
  {
      "Date": "7/22/16",
      "Open": "8519.65",
      "High": "8548.95",
      "Low": "8489.8",
      "Close": "8541.2"
  },
  {
      "Date": "7/25/16",
      "Open": "8519.95",
      "High": "8641.15",
      "Low": "8517.2",
      "Close": "8635.6"
  },
  {
      "Date": "7/26/16",
      "Open": "8633.75",
      "High": "8644.9",
      "Low": "8577.15",
      "Close": "8590.6"
  },
  {
      "Date": "7/27/16",
      "Open": "8599.4",
      "High": "8665",
      "Low": "8572.05",
      "Close": "8615.8"
  },
  {
      "Date": "7/28/16",
      "Open": "8636.95",
      "High": "8674.7",
      "Low": "8625.25",
      "Close": "8666.3"
  },
  {
      "Date": "7/29/16",
      "Open": "8668.3",
      "High": "8670.35",
      "Low": "8631.15",
      "Close": "8638.5"
  },
  {
      "Date": "8/1/16",
      "Open": "8654.3",
      "High": "8711.3",
      "Low": "8590.5",
      "Close": "8636.5"
  },
  {
      "Date": "8/2/16",
      "Open": "8647.45",
      "High": "8687.2",
      "Low": "8611.4",
      "Close": "8622.9"
  },
  {
      "Date": "8/3/16",
      "Open": "8635.2",
      "High": "8635.45",
      "Low": "8529.6",
      "Close": "8544.8"
  },
  {
      "Date": "8/4/16",
      "Open": "8599.95",
      "High": "8601.4",
      "Low": "8518.15",
      "Close": "8551.1"
  },
  {
      "Date": "8/5/16",
      "Open": "8600.2",
      "High": "8689.4",
      "Low": "8590.15",
      "Close": "8683.1"
  },
  {
      "Date": "8/8/16",
      "Open": "8712.85",
      "High": "8723.5",
      "Low": "8697.6",
      "Close": "8711.3"
  },
  {
      "Date": "8/9/16",
      "Open": "8727.8",
      "High": "8728.35",
      "Low": "8638.2",
      "Close": "8678.2"
  },
  {
      "Date": "8/10/16",
      "Open": "8686.7",
      "High": "8690.1",
      "Low": "8564.6",
      "Close": "8575.3"
  },
  {
      "Date": "8/11/16",
      "Open": "8572.8",
      "High": "8601.15",
      "Low": "8540.05",
      "Close": "8592.1"
  },
  {
      "Date": "8/12/16",
      "Open": "8605.45",
      "High": "8684.3",
      "Low": "8604.45",
      "Close": "8672.1"
  },
  {
      "Date": "8/16/16",
      "Open": "8670.25",
      "High": "8682.35",
      "Low": "8600.45",
      "Close": "8642.5"
  },
  {
      "Date": "8/17/16",
      "Open": "8639.8",
      "High": "8667.1",
      "Low": "8603.6",
      "Close": "8624"
  },
  {
      "Date": "8/18/16",
      "Open": "8648.85",
      "High": "8690.7",
      "Low": "8645.05",
      "Close": "8673.2"
  },
  {
      "Date": "8/19/16",
      "Open": "8694.3",
      "High": "8696.6",
      "Low": "8647.1",
      "Close": "8666.9"
  },
  {
      "Date": "8/22/16",
      "Open": "8667",
      "High": "8684.85",
      "Low": "8614",
      "Close": "8629.1"
  },
  {
      "Date": "8/23/16",
      "Open": "8628.35",
      "High": "8642.15",
      "Low": "8580",
      "Close": "8632.6"
  },
  {
      "Date": "8/24/16",
      "Open": "8648.5",
      "High": "8661.05",
      "Low": "8620.9",
      "Close": "8650.3"
  },
  {
      "Date": "8/25/16",
      "Open": "8668.85",
      "High": "8683.05",
      "Low": "8583.65",
      "Close": "8592.2"
  },
  {
      "Date": "8/26/16",
      "Open": "8614.35",
      "High": "8622.95",
      "Low": "8547.55",
      "Close": "8572.5"
  },
  {
      "Date": "8/29/16",
      "Open": "8583.75",
      "High": "8622",
      "Low": "8543.75",
      "Close": "8607.4"
  },
  {
      "Date": "8/30/16",
      "Open": "8646.75",
      "High": "8750.6",
      "Low": "8642.25",
      "Close": "8744.3"
  },
  {
      "Date": "8/31/16",
      "Open": "8754.05",
      "High": "8819.2",
      "Low": "8754.05",
      "Close": "8786.2"
  },
  {
      "Date": "9/1/16",
      "Open": "8793.6",
      "High": "8813.25",
      "Low": "8759.95",
      "Close": "8774.6"
  },
  {
      "Date": "9/2/16",
      "Open": "8796.35",
      "High": "8824.1",
      "Low": "8768.2",
      "Close": "8809.6"
  },
  {
      "Date": "9/6/16",
      "Open": "8852.7",
      "High": "8950.85",
      "Low": "8848.45",
      "Close": "8943"
  },
  {
      "Date": "9/7/16",
      "Open": "8968.7",
      "High": "8968.7",
      "Low": "8913.35",
      "Close": "8917.9"
  },
  {
      "Date": "9/8/16",
      "Open": "8915.5",
      "High": "8960.35",
      "Low": "8896",
      "Close": "8952.5"
  },
  {
      "Date": "9/9/16",
      "Open": "8934.3",
      "High": "8939.15",
      "Low": "8858.7",
      "Close": "8866.7"
  },
  {
      "Date": "9/12/16",
      "Open": "8732.95",
      "High": "8746.95",
      "Low": "8699.4",
      "Close": "8715.6"
  },
  {
      "Date": "9/14/16",
      "Open": "8710.65",
      "High": "8739.85",
      "Low": "8688.9",
      "Close": "8726.6"
  },
  {
      "Date": "9/15/16",
      "Open": "8743.85",
      "High": "8751.95",
      "Low": "8704.35",
      "Close": "8742.5"
  },
  {
      "Date": "9/16/16",
      "Open": "8780.85",
      "High": "8847.65",
      "Low": "8750.5",
      "Close": "8779.8"
  },
  {
      "Date": "9/19/16",
      "Open": "8788.45",
      "High": "8824.3",
      "Low": "8774.2",
      "Close": "8808.4"
  },
  {
      "Date": "9/20/16",
      "Open": "8816.1",
      "High": "8816.45",
      "Low": "8759.3",
      "Close": "8775.9"
  },
  {
      "Date": "9/21/16",
      "Open": "8790.3",
      "High": "8826.85",
      "Low": "8757.3",
      "Close": "8777.1"
  },
  {
      "Date": "9/22/16",
      "Open": "8873.35",
      "High": "8893.35",
      "Low": "8837.8",
      "Close": "8867.4"
  },
  {
      "Date": "9/23/16",
      "Open": "8880.75",
      "High": "8885.2",
      "Low": "8820.3",
      "Close": "8831.5"
  },
  {
      "Date": "9/26/16",
      "Open": "8807.9",
      "High": "8809.55",
      "Low": "8715.1",
      "Close": "8723"
  },
  {
      "Date": "9/27/16",
      "Open": "8748.9",
      "High": "8768.5",
      "Low": "8690.5",
      "Close": "8706.4"
  },
  {
      "Date": "9/28/16",
      "Open": "8711.2",
      "High": "8767.05",
      "Low": "8703.15",
      "Close": "8745.1"
  },
  {
      "Date": "9/29/16",
      "Open": "8792.7",
      "High": "8800.65",
      "Low": "8558.25",
      "Close": "8591.2"
  },
  {
      "Date": "9/30/16",
      "Open": "8581.5",
      "High": "8637.15",
      "Low": "8555.2",
      "Close": "8611.1"
  },
  {
      "Date": "10/3/16",
      "Open": "8666.15",
      "High": "8745.2",
      "Low": "8635",
      "Close": "8738.1"
  },
  {
      "Date": "10/4/16",
      "Open": "8770",
      "High": "8783.65",
      "Low": "8736.1",
      "Close": "8769.1"
  },
  {
      "Date": "10/5/16",
      "Open": "8806.35",
      "High": "8806.95",
      "Low": "8731.4",
      "Close": "8743.9"
  },
  {
      "Date": "10/6/16",
      "Open": "8768.7",
      "High": "8781.15",
      "Low": "8684.65",
      "Close": "8709.5"
  },
  {
      "Date": "10/7/16",
      "Open": "8721.7",
      "High": "8723.7",
      "Low": "8663.8",
      "Close": "8697.6"
  },
  {
      "Date": "10/10/16",
      "Open": "8735.35",
      "High": "8745.8",
      "Low": "8703.95",
      "Close": "8708.8"
  },
  {
      "Date": "10/13/16",
      "Open": "8671.5",
      "High": "8681.55",
      "Low": "8541.35",
      "Close": "8573.3"
  },
  {
      "Date": "10/14/16",
      "Open": "8594",
      "High": "8604.45",
      "Low": "8549.8",
      "Close": "8583.4"
  },
  {
      "Date": "10/17/16",
      "Open": "8612.95",
      "High": "8615.4",
      "Low": "8506.15",
      "Close": "8520.4"
  },
  {
      "Date": "10/18/16",
      "Open": "8556.05",
      "High": "8685.1",
      "Low": "8555.9",
      "Close": "8677.9"
  },
  {
      "Date": "10/19/16",
      "Open": "8697.5",
      "High": "8698.75",
      "Low": "8636.7",
      "Close": "8659.1"
  },
  {
      "Date": "10/20/16",
      "Open": "8693.35",
      "High": "8727",
      "Low": "8678.3",
      "Close": "8699.4"
  },
  {
      "Date": "10/21/16",
      "Open": "8708.6",
      "High": "8709.1",
      "Low": "8652.05",
      "Close": "8693"
  },
  {
      "Date": "10/24/16",
      "Open": "8709.85",
      "High": "8736.95",
      "Low": "8684.15",
      "Close": "8708.9"
  },
  {
      "Date": "10/25/16",
      "Open": "8721.7",
      "High": "8722.65",
      "Low": "8663.45",
      "Close": "8691.3"
  },
  {
      "Date": "10/26/16",
      "Open": "8657.3",
      "High": "8657.3",
      "Low": "8596.6",
      "Close": "8615.2"
  },
  {
      "Date": "10/27/16",
      "Open": "8607.1",
      "High": "8624.85",
      "Low": "8550.25",
      "Close": "8615.2"
  },
  {
      "Date": "10/28/16",
      "Open": "8625",
      "High": "8653.75",
      "Low": "8581.75",
      "Close": "8638"
  },
  {
      "Date": "10/30/16",
      "Open": "8672.35",
      "High": "8678.25",
      "Low": "8616.25",
      "Close": "8625.7"
  },
  {
      "Date": "11/1/16",
      "Open": "8653.15",
      "High": "8669.6",
      "Low": "8614.5",
      "Close": "8626.2"
  },
  {
      "Date": "11/2/16",
      "Open": "8542.8",
      "High": "8549.5",
      "Low": "8504.85",
      "Close": "8514"
  },
  {
      "Date": "11/3/16",
      "Open": "8499.85",
      "High": "8537.65",
      "Low": "8476.15",
      "Close": "8484.9"
  },
  {
      "Date": "11/4/16",
      "Open": "8503.6",
      "High": "8504",
      "Low": "8400.25",
      "Close": "8433.7"
  },
  {
      "Date": "11/7/16",
      "Open": "8535.75",
      "High": "8535.85",
      "Low": "8481.45",
      "Close": "8497"
  },
  {
      "Date": "11/8/16",
      "Open": "8540",
      "High": "8559.4",
      "Low": "8480.1",
      "Close": "8543.5"
  },
  {
      "Date": "11/9/16",
      "Open": "8067.5",
      "High": "8476.2",
      "Low": "8002.25",
      "Close": "8432"
  },
  {
      "Date": "11/10/16",
      "Open": "8555.6",
      "High": "8598.45",
      "Low": "8510.7",
      "Close": "8525.7"
  },
  {
      "Date": "11/11/16",
      "Open": "8456.65",
      "High": "8460.6",
      "Low": "8284.95",
      "Close": "8296.3"
  },
  {
      "Date": "11/15/16",
      "Open": "8284.85",
      "High": "8288.55",
      "Low": "8093.2",
      "Close": "8108.4"
  },
  {
      "Date": "11/16/16",
      "Open": "8205.65",
      "High": "8210.05",
      "Low": "8089.4",
      "Close": "8111.6"
  },
  {
      "Date": "11/17/16",
      "Open": "8105.1",
      "High": "8151.25",
      "Low": "8060.3",
      "Close": "8079.9"
  },
  {
      "Date": "11/18/16",
      "Open": "8097.55",
      "High": "8128.95",
      "Low": "8048.3",
      "Close": "8074.1"
  },
  {
      "Date": "11/21/16",
      "Open": "8102.1",
      "High": "8102.45",
      "Low": "7916.4",
      "Close": "7929.1"
  },
  {
      "Date": "11/22/16",
      "Open": "7989.15",
      "High": "8019.05",
      "Low": "7938.15",
      "Close": "8002.3"
  },
  {
      "Date": "11/23/16",
      "Open": "8051.2",
      "High": "8055.2",
      "Low": "7973.1",
      "Close": "8033.3"
  },
  {
      "Date": "11/24/16",
      "Open": "8011.8",
      "High": "8024.85",
      "Low": "7952.55",
      "Close": "7965.5"
  },
  {
      "Date": "11/25/16",
      "Open": "8007.95",
      "High": "8122.25",
      "Low": "7976.75",
      "Close": "8114.3"
  },
  {
      "Date": "11/28/16",
      "Open": "8080.65",
      "High": "8146.5",
      "Low": "8066.5",
      "Close": "8126.9"
  },
  {
      "Date": "11/29/16",
      "Open": "8131.55",
      "High": "8197.35",
      "Low": "8128.7",
      "Close": "8142.1"
  },
  {
      "Date": "11/30/16",
      "Open": "8172.15",
      "High": "8234.25",
      "Low": "8139.25",
      "Close": "8224.5"
  },
  {
      "Date": "12/1/16",
      "Open": "8244",
      "High": "8250.8",
      "Low": "8185.05",
      "Close": "8192.9"
  },
  {
      "Date": "12/2/16",
      "Open": "8153.55",
      "High": "8159.3",
      "Low": "8070.05",
      "Close": "8086.8"
  },
  {
      "Date": "12/5/16",
      "Open": "8088.75",
      "High": "8141.9",
      "Low": "8056.85",
      "Close": "8128.7"
  },
  {
      "Date": "12/6/16",
      "Open": "8153.15",
      "High": "8178.7",
      "Low": "8130.85",
      "Close": "8143.1"
  },
  {
      "Date": "12/7/16",
      "Open": "8168.4",
      "High": "8190.45",
      "Low": "8077.5",
      "Close": "8102"
  },
  {
      "Date": "12/8/16",
      "Open": "8152.1",
      "High": "8256.25",
      "Low": "8151.75",
      "Close": "8246.8"
  },
  {
      "Date": "12/9/16",
      "Open": "8271.7",
      "High": "8274.95",
      "Low": "8241.95",
      "Close": "8261.7"
  },
  {
      "Date": "12/12/16",
      "Open": "8230.65",
      "High": "8230.65",
      "Low": "8154.45",
      "Close": "8170.8"
  },
  {
      "Date": "12/13/16",
      "Open": "8196.15",
      "High": "8228.85",
      "Low": "8155.8",
      "Close": "8221.8"
  },
  {
      "Date": "12/14/16",
      "Open": "8229.35",
      "High": "8229.4",
      "Low": "8165.1",
      "Close": "8182.4"
  },
  {
      "Date": "12/15/16",
      "Open": "8128.4",
      "High": "8225.9",
      "Low": "8121.95",
      "Close": "8153.6"
  },
  {
      "Date": "12/16/16",
      "Open": "8178.2",
      "High": "8178.7",
      "Low": "8127.45",
      "Close": "8139.4"
  },
  {
      "Date": "12/19/16",
      "Open": "8126",
      "High": "8132.5",
      "Low": "8094.85",
      "Close": "8104.3"
  },
  {
      "Date": "12/20/16",
      "Open": "8110.6",
      "High": "8124.1",
      "Low": "8062.75",
      "Close": "8082.4"
  },
  {
      "Date": "12/21/16",
      "Open": "8105.85",
      "High": "8112.55",
      "Low": "8053.25",
      "Close": "8061.3"
  },
  {
      "Date": "12/22/16",
      "Open": "8043.85",
      "High": "8046.45",
      "Low": "7964.95",
      "Close": "7979.1"
  },
  {
      "Date": "12/23/16",
      "Open": "7972.5",
      "High": "8022.6",
      "Low": "7942.05",
      "Close": "7985.7"
  },
  {
      "Date": "12/26/16",
      "Open": "7965.1",
      "High": "7970.05",
      "Low": "7893.8",
      "Close": "7908.2"
  },
  {
      "Date": "12/27/16",
      "Open": "7915.05",
      "High": "8044.65",
      "Low": "7903.7",
      "Close": "8032.8"
  },
  {
      "Date": "12/28/16",
      "Open": "8047.55",
      "High": "8100.55",
      "Low": "8028.4",
      "Close": "8034.8"
  },
  {
      "Date": "12/29/16",
      "Open": "8030.6",
      "High": "8111.1",
      "Low": "8020.8",
      "Close": "8103.6"
  },
  {
      "Date": "12/30/16",
      "Open": "8119.65",
      "High": "8197",
      "Low": "8114.75",
      "Close": "8185.8"
  },
  {
      "Date": "1/2/17",
      "Open": "8210.1",
      "High": "8212",
      "Low": "8133.8",
      "Close": "8179.5"
  },
  {
      "Date": "1/3/17",
      "Open": "8196.05",
      "High": "8219.1",
      "Low": "8148.6",
      "Close": "8192.2"
  },
  {
      "Date": "1/4/17",
      "Open": "8202.65",
      "High": "8218.5",
      "Low": "8180.9",
      "Close": "8190.5"
  },
  {
      "Date": "1/5/17",
      "Open": "8226.65",
      "High": "8282.65",
      "Low": "8223.7",
      "Close": "8273.8"
  },
  {
      "Date": "1/6/17",
      "Open": "8281.85",
      "High": "8306.85",
      "Low": "8233.25",
      "Close": "8243.8"
  },
  {
      "Date": "1/9/17",
      "Open": "8259.35",
      "High": "8263",
      "Low": "8227.75",
      "Close": "8236"
  },
  {
      "Date": "1/10/17",
      "Open": "8262.7",
      "High": "8293.8",
      "Low": "8261",
      "Close": "8288.6"
  },
  {
      "Date": "1/11/17",
      "Open": "8327.8",
      "High": "8389",
      "Low": "8322.25",
      "Close": "8380.6"
  },
  {
      "Date": "1/12/17",
      "Open": "8391.05",
      "High": "8417.2",
      "Low": "8382.3",
      "Close": "8407.2"
  },
  {
      "Date": "1/13/17",
      "Open": "8457.65",
      "High": "8461.05",
      "Low": "8373.15",
      "Close": "8400.3"
  },
  {
      "Date": "1/16/17",
      "Open": "8390.95",
      "High": "8426.7",
      "Low": "8374.4",
      "Close": "8412.8"
  },
  {
      "Date": "1/17/17",
      "Open": "8415.05",
      "High": "8440.9",
      "Low": "8378.3",
      "Close": "8398"
  },
  {
      "Date": "1/18/17",
      "Open": "8403.85",
      "High": "8460.3",
      "Low": "8397.4",
      "Close": "8417"
  },
  {
      "Date": "1/19/17",
      "Open": "8418.4",
      "High": "8445.15",
      "Low": "8404.05",
      "Close": "8435.1"
  },
  {
      "Date": "1/20/17",
      "Open": "8404.35",
      "High": "8423.65",
      "Low": "8340.95",
      "Close": "8349.3"
  },
  {
      "Date": "1/23/17",
      "Open": "8329.6",
      "High": "8404.15",
      "Low": "8327.2",
      "Close": "8391.5"
  },
  {
      "Date": "1/24/17",
      "Open": "8407.05",
      "High": "8480.95",
      "Low": "8398.15",
      "Close": "8475.8"
  },
  {
      "Date": "1/25/17",
      "Open": "8499.45",
      "High": "8612.6",
      "Low": "8493.95",
      "Close": "8602.7"
  },
  {
      "Date": "1/27/17",
      "Open": "8610.5",
      "High": "8672.7",
      "Low": "8606.9",
      "Close": "8641.2"
  },
  {
      "Date": "1/30/17",
      "Open": "8635.55",
      "High": "8662.6",
      "Low": "8617.75",
      "Close": "8632.7"
  },
  {
      "Date": "1/31/17",
      "Open": "8629.45",
      "High": "8631.75",
      "Low": "8552.4",
      "Close": "8561.3"
  },
  {
      "Date": "2/1/17",
      "Open": "8570.35",
      "High": "8722.4",
      "Low": "8537.5",
      "Close": "8716.4"
  },
  {
      "Date": "2/2/17",
      "Open": "8724.75",
      "High": "8757.6",
      "Low": "8685.8",
      "Close": "8734.2"
  },
  {
      "Date": "2/3/17",
      "Open": "8735.15",
      "High": "8748.25",
      "Low": "8707.75",
      "Close": "8740.9"
  },
  {
      "Date": "2/6/17",
      "Open": "8785.45",
      "High": "8814.1",
      "Low": "8770.2",
      "Close": "8801"
  },
  {
      "Date": "2/7/17",
      "Open": "8805.7",
      "High": "8809.3",
      "Low": "8741.05",
      "Close": "8768.3"
  },
  {
      "Date": "2/8/17",
      "Open": "8774.55",
      "High": "8791.25",
      "Low": "8715",
      "Close": "8769"
  },
  {
      "Date": "2/9/17",
      "Open": "8795.55",
      "High": "8821.4",
      "Low": "8724.1",
      "Close": "8778.4"
  },
  {
      "Date": "2/10/17",
      "Open": "8812.35",
      "High": "8822.1",
      "Low": "8771.2",
      "Close": "8793.5"
  },
  {
      "Date": "2/13/17",
      "Open": "8819.8",
      "High": "8826.9",
      "Low": "8754.2",
      "Close": "8805"
  },
  {
      "Date": "2/14/17",
      "Open": "8819.9",
      "High": "8820.45",
      "Low": "8772.5",
      "Close": "8792.3"
  },
  {
      "Date": "2/15/17",
      "Open": "8778.95",
      "High": "8807.9",
      "Low": "8712.85",
      "Close": "8724.7"
  },
  {
      "Date": "2/16/17",
      "Open": "8739",
      "High": "8783.95",
      "Low": "8719.6",
      "Close": "8778"
  },
  {
      "Date": "2/17/17",
      "Open": "8883.7",
      "High": "8896.45",
      "Low": "8804.25",
      "Close": "8821.7"
  },
  {
      "Date": "2/20/17",
      "Open": "8818.55",
      "High": "8886.25",
      "Low": "8809.8",
      "Close": "8879.2"
  },
  {
      "Date": "2/21/17",
      "Open": "8890.75",
      "High": "8920.8",
      "Low": "8860.95",
      "Close": "8907.8"
  },
  {
      "Date": "2/22/17",
      "Open": "8931.6",
      "High": "8960.75",
      "Low": "8905.25",
      "Close": "8926.9"
  },
  {
      "Date": "2/23/17",
      "Open": "8956.4",
      "High": "8982.15",
      "Low": "8927.55",
      "Close": "8939.5"
  },
  {
      "Date": "2/27/17",
      "Open": "8943.7",
      "High": "8951.8",
      "Low": "8888.65",
      "Close": "8896.7"
  },
  {
      "Date": "2/28/17",
      "Open": "8898.95",
      "High": "8914.75",
      "Low": "8867.6",
      "Close": "8879.6"
  },
  {
      "Date": "3/1/17",
      "Open": "8904.4",
      "High": "8960.8",
      "Low": "8898.6",
      "Close": "8945.8"
  },
  {
      "Date": "3/2/17",
      "Open": "8982.85",
      "High": "8992.5",
      "Low": "8879.8",
      "Close": "8899.7"
  },
  {
      "Date": "3/3/17",
      "Open": "8883.5",
      "High": "8907.1",
      "Low": "8860.1",
      "Close": "8897.5"
  },
  {
      "Date": "3/6/17",
      "Open": "8915.1",
      "High": "8967.8",
      "Low": "8914",
      "Close": "8963.4"
  },
  {
      "Date": "3/7/17",
      "Open": "8977.75",
      "High": "8977.85",
      "Low": "8932.8",
      "Close": "8946.9"
  },
  {
      "Date": "3/8/17",
      "Open": "8950.7",
      "High": "8957.05",
      "Low": "8891.95",
      "Close": "8924.3"
  },
  {
      "Date": "3/9/17",
      "Open": "8914.5",
      "High": "8945.8",
      "Low": "8899.5",
      "Close": "8927"
  },
  {
      "Date": "3/10/17",
      "Open": "8953.7",
      "High": "8975.7",
      "Low": "8903.95",
      "Close": "8934.5"
  },
  {
      "Date": "3/14/17",
      "Open": "9091.65",
      "High": "9122.75",
      "Low": "9060.5",
      "Close": "9087"
  },
  {
      "Date": "3/15/17",
      "Open": "9086.85",
      "High": "9106.55",
      "Low": "9075.5",
      "Close": "9084.8"
  },
  {
      "Date": "3/16/17",
      "Open": "9129.65",
      "High": "9158.45",
      "Low": "9128.55",
      "Close": "9153.7"
  },
  {
      "Date": "3/17/17",
      "Open": "9207.8",
      "High": "9218.4",
      "Low": "9147.6",
      "Close": "9160"
  },
  {
      "Date": "3/20/17",
      "Open": "9166.95",
      "High": "9167.6",
      "Low": "9116.3",
      "Close": "9126.8"
  },
  {
      "Date": "3/21/17",
      "Open": "9133.95",
      "High": "9147.75",
      "Low": "9087.2",
      "Close": "9121.5"
  },
  {
      "Date": "3/22/17",
      "Open": "9047.2",
      "High": "9072.9",
      "Low": "9019.3",
      "Close": "9030.4"
  },
  {
      "Date": "3/23/17",
      "Open": "9048.75",
      "High": "9099.05",
      "Low": "9048.6",
      "Close": "9086.3"
  },
  {
      "Date": "3/24/17",
      "Open": "9104",
      "High": "9133.55",
      "Low": "9089.4",
      "Close": "9108"
  },
  {
      "Date": "3/27/17",
      "Open": "9093.45",
      "High": "9094.85",
      "Low": "9024.65",
      "Close": "9045.2"
  },
  {
      "Date": "3/28/17",
      "Open": "9081.5",
      "High": "9110.4",
      "Low": "9079.8",
      "Close": "9100.8"
  },
  {
      "Date": "3/29/17",
      "Open": "9128.7",
      "High": "9153.15",
      "Low": "9109.1",
      "Close": "9143.8"
  },
  {
      "Date": "3/30/17",
      "Open": "9142.6",
      "High": "9183.15",
      "Low": "9136.35",
      "Close": "9173.7"
  },
  {
      "Date": "3/31/17",
      "Open": "9158.9",
      "High": "9191.7",
      "Low": "9152.1",
      "Close": "9173.7"
  },
  {
      "Date": "4/3/17",
      "Open": "9220.6",
      "High": "9245.35",
      "Low": "9192.4",
      "Close": "9237.8"
  },
  {
      "Date": "4/5/17",
      "Open": "9264.4",
      "High": "9273.9",
      "Low": "9215.4",
      "Close": "9265.1"
  },
  {
      "Date": "4/6/17",
      "Open": "9245.8",
      "High": "9267.95",
      "Low": "9218.85",
      "Close": "9261.9"
  },
  {
      "Date": "4/7/17",
      "Open": "9223.7",
      "High": "9250.5",
      "Low": "9188.1",
      "Close": "9198.3"
  },
  {
      "Date": "4/10/17",
      "Open": "9225.6",
      "High": "9225.65",
      "Low": "9174.85",
      "Close": "9181.4"
  },
  {
      "Date": "4/11/17",
      "Open": "9184.55",
      "High": "9242.7",
      "Low": "9172.85",
      "Close": "9237"
  },
  {
      "Date": "4/12/17",
      "Open": "9242.5",
      "High": "9246.4",
      "Low": "9161.8",
      "Close": "9203.4"
  },
  {
      "Date": "4/13/17",
      "Open": "9202.5",
      "High": "9202.65",
      "Low": "9144.95",
      "Close": "9150.8"
  },
  {
      "Date": "4/17/17",
      "Open": "9144.75",
      "High": "9160",
      "Low": "9120.25",
      "Close": "9139.3"
  },
  {
      "Date": "4/18/17",
      "Open": "9163",
      "High": "9217.9",
      "Low": "9095.45",
      "Close": "9105.1"
  },
  {
      "Date": "4/19/17",
      "Open": "9112.2",
      "High": "9120.5",
      "Low": "9075.15",
      "Close": "9103.5"
  },
  {
      "Date": "4/20/17",
      "Open": "9108.1",
      "High": "9143.9",
      "Low": "9102.65",
      "Close": "9136.4"
  },
  {
      "Date": "4/21/17",
      "Open": "9179.1",
      "High": "9183.65",
      "Low": "9088.75",
      "Close": "9119.4"
  },
  {
      "Date": "4/24/17",
      "Open": "9135.35",
      "High": "9225.4",
      "Low": "9130.55",
      "Close": "9217.9"
  },
  {
      "Date": "4/25/17",
      "Open": "9273.05",
      "High": "9309.2",
      "Low": "9250.35",
      "Close": "9306.6"
  },
  {
      "Date": "4/26/17",
      "Open": "9336.2",
      "High": "9367",
      "Low": "9301.35",
      "Close": "9351.8"
  },
  {
      "Date": "4/27/17",
      "Open": "9359.15",
      "High": "9367.15",
      "Low": "9322.65",
      "Close": "9342.1"
  },
  {
      "Date": "4/28/17",
      "Open": "9340.95",
      "High": "9342.65",
      "Low": "9282.25",
      "Close": "9304"
  },
  {
      "Date": "5/2/17",
      "Open": "9339.85",
      "High": "9352.55",
      "Low": "9269.9",
      "Close": "9313.8"
  },
  {
      "Date": "5/3/17",
      "Open": "9344.7",
      "High": "9346.3",
      "Low": "9298.4",
      "Close": "9311.9"
  },
  {
      "Date": "5/4/17",
      "Open": "9360.95",
      "High": "9365.65",
      "Low": "9323.25",
      "Close": "9359.9"
  },
  {
      "Date": "5/5/17",
      "Open": "9374.55",
      "High": "9377.1",
      "Low": "9272",
      "Close": "9285.3"
  },
  {
      "Date": "5/8/17",
      "Open": "9311.45",
      "High": "9338.7",
      "Low": "9297.95",
      "Close": "9314"
  },
  {
      "Date": "5/9/17",
      "Open": "9337.35",
      "High": "9338.95",
      "Low": "9307.7",
      "Close": "9316.8"
  },
  {
      "Date": "5/10/17",
      "Open": "9339.65",
      "High": "9414.75",
      "Low": "9336",
      "Close": "9407.3"
  },
  {
      "Date": "5/11/17",
      "Open": "9448.6",
      "High": "9450.65",
      "Low": "9411.3",
      "Close": "9422.4"
  },
  {
      "Date": "5/12/17",
      "Open": "9436.65",
      "High": "9437.75",
      "Low": "9372.55",
      "Close": "9400.9"
  },
  {
      "Date": "5/15/17",
      "Open": "9433.55",
      "High": "9449.25",
      "Low": "9423.1",
      "Close": "9445.4"
  },
  {
      "Date": "5/16/17",
      "Open": "9461",
      "High": "9517.2",
      "Low": "9456.35",
      "Close": "9512.2"
  },
  {
      "Date": "5/17/17",
      "Open": "9517.6",
      "High": "9532.6",
      "Low": "9486.1",
      "Close": "9525.7"
  },
  {
      "Date": "5/18/17",
      "Open": "9453.2",
      "High": "9489.1",
      "Low": "9418.1",
      "Close": "9429.4"
  },
  {
      "Date": "5/19/17",
      "Open": "9469.9",
      "High": "9505.75",
      "Low": "9390.75",
      "Close": "9427.9"
  },
  {
      "Date": "5/22/17",
      "Open": "9480.25",
      "High": "9498.65",
      "Low": "9427.9",
      "Close": "9438.2"
  },
  {
      "Date": "5/23/17",
      "Open": "9445.05",
      "High": "9448.05",
      "Low": "9370",
      "Close": "9386.1"
  },
  {
      "Date": "5/24/17",
      "Open": "9410.9",
      "High": "9431.9",
      "Low": "9341.65",
      "Close": "9360.5"
  },
  {
      "Date": "5/25/17",
      "Open": "9384.05",
      "High": "9523.3",
      "Low": "9379.2",
      "Close": "9509.7"
  },
  {
      "Date": "5/26/17",
      "Open": "9507.75",
      "High": "9604.9",
      "Low": "9495.4",
      "Close": "9595.1"
  },
  {
      "Date": "5/29/17",
      "Open": "9560.05",
      "High": "9637.75",
      "Low": "9547.7",
      "Close": "9604.9"
  },
  {
      "Date": "5/30/17",
      "Open": "9590.65",
      "High": "9635.3",
      "Low": "9581.2",
      "Close": "9624.5"
  },
  {
      "Date": "5/31/17",
      "Open": "9636.55",
      "High": "9649.6",
      "Low": "9609.25",
      "Close": "9621.2"
  },
  {
      "Date": "6/1/17",
      "Open": "9603.55",
      "High": "9634.65",
      "Low": "9589.9",
      "Close": "9616.1"
  },
  {
      "Date": "6/2/17",
      "Open": "9657.15",
      "High": "9673.5",
      "Low": "9637.45",
      "Close": "9653.5"
  },
  {
      "Date": "6/5/17",
      "Open": "9656.3",
      "High": "9687.2",
      "Low": "9640.7",
      "Close": "9675.1"
  },
  {
      "Date": "6/6/17",
      "Open": "9704.25",
      "High": "9709.3",
      "Low": "9630.2",
      "Close": "9637.1"
  },
  {
      "Date": "6/7/17",
      "Open": "9663.95",
      "High": "9678.55",
      "Low": "9630.55",
      "Close": "9663.9"
  },
  {
      "Date": "6/8/17",
      "Open": "9682.4",
      "High": "9688.7",
      "Low": "9641.5",
      "Close": "9647.2"
  },
  {
      "Date": "6/9/17",
      "Open": "9638.55",
      "High": "9676.25",
      "Low": "9608.15",
      "Close": "9668.2"
  },
  {
      "Date": "6/12/17",
      "Open": "9646.7",
      "High": "9647.05",
      "Low": "9598.5",
      "Close": "9616.4"
  },
  {
      "Date": "6/13/17",
      "Open": "9615.55",
      "High": "9654.15",
      "Low": "9595.4",
      "Close": "9606.9"
  },
  {
      "Date": "6/14/17",
      "Open": "9621.55",
      "High": "9627.4",
      "Low": "9580.45",
      "Close": "9618.1"
  },
  {
      "Date": "6/15/17",
      "Open": "9617.9",
      "High": "9621.4",
      "Low": "9560.8",
      "Close": "9578"
  },
  {
      "Date": "6/16/17",
      "Open": "9595.45",
      "High": "9615.85",
      "Low": "9565.5",
      "Close": "9588"
  },
  {
      "Date": "6/19/17",
      "Open": "9626.4",
      "High": "9673.3",
      "Low": "9614.9",
      "Close": "9657.5"
  },
  {
      "Date": "6/20/17",
      "Open": "9670.5",
      "High": "9676.5",
      "Low": "9643.75",
      "Close": "9653.5"
  },
  {
      "Date": "6/21/17",
      "Open": "9648.1",
      "High": "9650.45",
      "Low": "9608.6",
      "Close": "9633.6"
  },
  {
      "Date": "6/22/17",
      "Open": "9642.65",
      "High": "9698.85",
      "Low": "9617.75",
      "Close": "9630"
  },
  {
      "Date": "6/23/17",
      "Open": "9643.25",
      "High": "9647.65",
      "Low": "9565.3",
      "Close": "9574.9"
  },
  {
      "Date": "6/27/17",
      "Open": "9594.05",
      "High": "9615.4",
      "Low": "9473.45",
      "Close": "9511.4"
  },
  {
      "Date": "6/28/17",
      "Open": "9520.2",
      "High": "9522.5",
      "Low": "9474.35",
      "Close": "9491.2"
  },
  {
      "Date": "6/29/17",
      "Open": "9522.95",
      "High": "9575.8",
      "Low": "9493.8",
      "Close": "9504.1"
  },
  {
      "Date": "6/30/17",
      "Open": "9478.5",
      "High": "9535.8",
      "Low": "9448.75",
      "Close": "9520.9"
  },
  {
      "Date": "7/3/17",
      "Open": "9587.95",
      "High": "9624",
      "Low": "9543.55",
      "Close": "9615"
  },
  {
      "Date": "7/4/17",
      "Open": "9645.9",
      "High": "9650.65",
      "Low": "9595.5",
      "Close": "9613.3"
  },
  {
      "Date": "7/5/17",
      "Open": "9619.75",
      "High": "9643.65",
      "Low": "9607.35",
      "Close": "9637.6"
  },
  {
      "Date": "7/6/17",
      "Open": "9653.6",
      "High": "9700.7",
      "Low": "9639.95",
      "Close": "9674.5"
  },
  {
      "Date": "7/7/17",
      "Open": "9670.35",
      "High": "9684.25",
      "Low": "9642.65",
      "Close": "9665.8"
  },
  {
      "Date": "7/10/17",
      "Open": "9719.3",
      "High": "9782.15",
      "Low": "9646.45",
      "Close": "9771"
  },
  {
      "Date": "7/11/17",
      "Open": "9797.45",
      "High": "9830.05",
      "Low": "9778.85",
      "Close": "9786"
  },
  {
      "Date": "7/12/17",
      "Open": "9807.3",
      "High": "9824.95",
      "Low": "9787.7",
      "Close": "9816.1"
  },
  {
      "Date": "7/13/17",
      "Open": "9855.8",
      "High": "9897.25",
      "Low": "9853.45",
      "Close": "9891.7"
  },
  {
      "Date": "7/14/17",
      "Open": "9913.3",
      "High": "9913.3",
      "Low": "9845.45",
      "Close": "9886.3"
  },
  {
      "Date": "7/17/17",
      "Open": "9908.15",
      "High": "9928.2",
      "Low": "9894.7",
      "Close": "9915.9"
  },
  {
      "Date": "7/18/17",
      "Open": "9832.7",
      "High": "9885.35",
      "Low": "9792.05",
      "Close": "9827.1"
  },
  {
      "Date": "7/19/17",
      "Open": "9855.95",
      "High": "9905.05",
      "Low": "9851.65",
      "Close": "9899.6"
  },
  {
      "Date": "7/20/17",
      "Open": "9920.2",
      "High": "9922.55",
      "Low": "9863.45",
      "Close": "9873.3"
  },
  {
      "Date": "7/21/17",
      "Open": "9899.6",
      "High": "9924.7",
      "Low": "9838",
      "Close": "9915.2"
  },
  {
      "Date": "7/24/17",
      "Open": "9936.8",
      "High": "9982.05",
      "Low": "9919.6",
      "Close": "9966.4"
  },
  {
      "Date": "7/25/17",
      "Open": "10010.55",
      "High": "10011.3",
      "Low": "9949.1",
      "Close": "9964.5"
  },
  {
      "Date": "7/26/17",
      "Open": "9983.65",
      "High": "10025.95",
      "Low": "9965.95",
      "Close": "10020"
  },
  {
      "Date": "7/27/17",
      "Open": "10063.25",
      "High": "10114.85",
      "Low": "10005.5",
      "Close": "10020"
  },
  {
      "Date": "7/28/17",
      "Open": "9996.55",
      "High": "10026.05",
      "Low": "9944.5",
      "Close": "10014"
  },
  {
      "Date": "7/31/17",
      "Open": "10034.7",
      "High": "10085.9",
      "Low": "10016.95",
      "Close": "10077"
  },
  {
      "Date": "8/1/17",
      "Open": "10101.05",
      "High": "10128.6",
      "Low": "10065.75",
      "Close": "10114"
  },
  {
      "Date": "8/2/17",
      "Open": "10136.3",
      "High": "10137.85",
      "Low": "10054.2",
      "Close": "10081"
  },
  {
      "Date": "8/3/17",
      "Open": "10081.15",
      "High": "10081.15",
      "Low": "9998.25",
      "Close": "10013"
  },
  {
      "Date": "8/4/17",
      "Open": "10008.6",
      "High": "10075.25",
      "Low": "9988.35",
      "Close": "10066"
  },
  {
      "Date": "8/7/17",
      "Open": "10074.8",
      "High": "10088.1",
      "Low": "10046.35",
      "Close": "10057"
  },
  {
      "Date": "8/8/17",
      "Open": "10068.35",
      "High": "10083.8",
      "Low": "9947",
      "Close": "9978.5"
  },
  {
      "Date": "8/9/17",
      "Open": "9961.15",
      "High": "9969.8",
      "Low": "9893.05",
      "Close": "9908"
  },
  {
      "Date": "8/10/17",
      "Open": "9872.85",
      "High": "9892.65",
      "Low": "9776.2",
      "Close": "9820.2"
  },
  {
      "Date": "8/11/17",
      "Open": "9712.15",
      "High": "9771.65",
      "Low": "9685.55",
      "Close": "9710.8"
  },
  {
      "Date": "8/14/17",
      "Open": "9755.75",
      "High": "9818.3",
      "Low": "9752.1",
      "Close": "9794.1"
  },
  {
      "Date": "8/16/17",
      "Open": "9825.85",
      "High": "9903.95",
      "Low": "9773.85",
      "Close": "9897.3"
  },
  {
      "Date": "8/17/17",
      "Open": "9945.55",
      "High": "9947.8",
      "Low": "9883.75",
      "Close": "9904.1"
  },
  {
      "Date": "8/18/17",
      "Open": "9865.95",
      "High": "9865.95",
      "Low": "9783.65",
      "Close": "9837.4"
  },
  {
      "Date": "8/21/17",
      "Open": "9864.25",
      "High": "9884.35",
      "Low": "9740.1",
      "Close": "9754.3"
  },
  {
      "Date": "8/22/17",
      "Open": "9815.75",
      "High": "9828.45",
      "Low": "9752.6",
      "Close": "9765.5"
  },
  {
      "Date": "8/23/17",
      "Open": "9803.05",
      "High": "9857.9",
      "Low": "9786.75",
      "Close": "9852.5"
  },
  {
      "Date": "8/24/17",
      "Open": "9881.2",
      "High": "9881.5",
      "Low": "9848.85",
      "Close": "9857"
  },
  {
      "Date": "8/28/17",
      "Open": "9907.15",
      "High": "9925.75",
      "Low": "9882",
      "Close": "9912.8"
  },
  {
      "Date": "8/29/17",
      "Open": "9886.4",
      "High": "9887.35",
      "Low": "9783.75",
      "Close": "9796"
  },
  {
      "Date": "8/30/17",
      "Open": "9859.5",
      "High": "9909.45",
      "Low": "9850.8",
      "Close": "9884.4"
  },
  {
      "Date": "8/31/17",
      "Open": "9905.7",
      "High": "9925.1",
      "Low": "9856.95",
      "Close": "9917.9"
  },
  {
      "Date": "9/1/17",
      "Open": "9937.65",
      "High": "9983.45",
      "Low": "9909.85",
      "Close": "9974.4"
  },
  {
      "Date": "9/4/17",
      "Open": "9984.15",
      "High": "9988.4",
      "Low": "9861",
      "Close": "9912.8"
  },
  {
      "Date": "9/5/17",
      "Open": "9933.25",
      "High": "9963.1",
      "Low": "9901.05",
      "Close": "9952.2"
  },
  {
      "Date": "9/6/17",
      "Open": "9899.25",
      "High": "9931.55",
      "Low": "9882.55",
      "Close": "9916.2"
  },
  {
      "Date": "9/7/17",
      "Open": "9945.85",
      "High": "9964.85",
      "Low": "9917.2",
      "Close": "9929.9"
  },
  {
      "Date": "9/8/17",
      "Open": "9958.65",
      "High": "9963.6",
      "Low": "9913.3",
      "Close": "9934.8"
  },
  {
      "Date": "9/11/17",
      "Open": "9971.75",
      "High": "10028.65",
      "Low": "9968.8",
      "Close": "10006"
  },
  {
      "Date": "9/12/17",
      "Open": "10056.85",
      "High": "10097.55",
      "Low": "10028.05",
      "Close": "10093"
  },
  {
      "Date": "9/13/17",
      "Open": "10099.25",
      "High": "10131.95",
      "Low": "10063.15",
      "Close": "10079"
  },
  {
      "Date": "9/14/17",
      "Open": "10107.4",
      "High": "10126.5",
      "Low": "10070.35",
      "Close": "10086"
  },
  {
      "Date": "9/15/17",
      "Open": "10062.35",
      "High": "10115.15",
      "Low": "10043.65",
      "Close": "10085"
  },
  {
      "Date": "9/18/17",
      "Open": "10133.1",
      "High": "10171.7",
      "Low": "10131.3",
      "Close": "10153"
  },
  {
      "Date": "9/19/17",
      "Open": "10175.6",
      "High": "10178.95",
      "Low": "10129.95",
      "Close": "10147"
  },
  {
      "Date": "9/20/17",
      "Open": "10160.95",
      "High": "10171.05",
      "Low": "10134.2",
      "Close": "10141"
  },
  {
      "Date": "9/21/17",
      "Open": "10139.6",
      "High": "10158.9",
      "Low": "10058.6",
      "Close": "10121"
  },
  {
      "Date": "9/22/17",
      "Open": "10094.35",
      "High": "10095.05",
      "Low": "9952.8",
      "Close": "9964.4"
  },
  {
      "Date": "9/25/17",
      "Open": "9960.1",
      "High": "9960.5",
      "Low": "9816.05",
      "Close": "9872.6"
  },
  {
      "Date": "9/26/17",
      "Open": "9875.25",
      "High": "9891.35",
      "Low": "9813",
      "Close": "9871.5"
  },
  {
      "Date": "9/27/17",
      "Open": "9920.6",
      "High": "9921.05",
      "Low": "9714.4",
      "Close": "9735.7"
  },
  {
      "Date": "9/28/17",
      "Open": "9736.4",
      "High": "9789.2",
      "Low": "9687.55",
      "Close": "9768.9"
  },
  {
      "Date": "9/29/17",
      "Open": "9814.3",
      "High": "9854",
      "Low": "9775.35",
      "Close": "9788.6"
  },
  {
      "Date": "10/3/17",
      "Open": "9893.3",
      "High": "9895.4",
      "Low": "9831.05",
      "Close": "9859.5"
  },
  {
      "Date": "10/4/17",
      "Open": "9884.35",
      "High": "9938.3",
      "Low": "9850.65",
      "Close": "9914.9"
  },
  {
      "Date": "10/5/17",
      "Open": "9927",
      "High": "9945.95",
      "Low": "9881.85",
      "Close": "9888.7"
  },
  {
      "Date": "10/6/17",
      "Open": "9908.15",
      "High": "9989.35",
      "Low": "9906.6",
      "Close": "9979.7"
  },
  {
      "Date": "10/9/17",
      "Open": "9988.2",
      "High": "10015.75",
      "Low": "9959.45",
      "Close": "9988.7"
  },
  {
      "Date": "10/10/17",
      "Open": "10013.7",
      "High": "10034",
      "Low": "10002.3",
      "Close": "10016"
  },
  {
      "Date": "10/11/17",
      "Open": "10042.6",
      "High": "10067.25",
      "Low": "9955.8",
      "Close": "9984.8"
  },
  {
      "Date": "10/12/17",
      "Open": "10011.2",
      "High": "10104.45",
      "Low": "9977.1",
      "Close": "10096"
  },
  {
      "Date": "10/13/17",
      "Open": "10123.7",
      "High": "10191.9",
      "Low": "10120.1",
      "Close": "10167"
  },
  {
      "Date": "10/16/17",
      "Open": "10207.4",
      "High": "10242.95",
      "Low": "10175.1",
      "Close": "10230"
  },
  {
      "Date": "10/17/17",
      "Open": "10227.65",
      "High": "10251.85",
      "Low": "10212.6",
      "Close": "10234"
  },
  {
      "Date": "10/18/17",
      "Open": "10209.4",
      "High": "10236.45",
      "Low": "10175.75",
      "Close": "10210"
  },
  {
      "Date": "10/19/17",
      "Open": "10210.35",
      "High": "10211.95",
      "Low": "10123.35",
      "Close": "10146"
  },
  {
      "Date": "10/23/17",
      "Open": "10176.65",
      "High": "10224.15",
      "Low": "10124.5",
      "Close": "10184"
  },
  {
      "Date": "10/24/17",
      "Open": "10218.55",
      "High": "10237.75",
      "Low": "10182.4",
      "Close": "10207"
  },
  {
      "Date": "10/25/17",
      "Open": "10321.15",
      "High": "10340.55",
      "Low": "10240.9",
      "Close": "10295"
  },
  {
      "Date": "10/26/17",
      "Open": "10291.8",
      "High": "10355.65",
      "Low": "10271.85",
      "Close": "10343"
  },
  {
      "Date": "10/27/17",
      "Open": "10362.3",
      "High": "10366.15",
      "Low": "10311.3",
      "Close": "10323"
  },
  {
      "Date": "10/30/17",
      "Open": "10353.85",
      "High": "10384.5",
      "Low": "10344.3",
      "Close": "10363"
  },
  {
      "Date": "10/31/17",
      "Open": "10364.9",
      "High": "10367.7",
      "Low": "10323.95",
      "Close": "10335"
  },
  {
      "Date": "11/1/17",
      "Open": "10390.35",
      "High": "10451.65",
      "Low": "10383.05",
      "Close": "10440"
  },
  {
      "Date": "11/2/17",
      "Open": "10440.5",
      "High": "10453",
      "Low": "10412.55",
      "Close": "10423"
  },
  {
      "Date": "11/3/17",
      "Open": "10461.55",
      "High": "10461.7",
      "Low": "10403.6",
      "Close": "10452"
  },
  {
      "Date": "11/6/17",
      "Open": "10431.75",
      "High": "10490.45",
      "Low": "10413.75",
      "Close": "10451"
  },
  {
      "Date": "11/7/17",
      "Open": "10477.15",
      "High": "10485.75",
      "Low": "10340.8",
      "Close": "10350"
  },
  {
      "Date": "11/8/17",
      "Open": "10361.95",
      "High": "10384.25",
      "Low": "10285.5",
      "Close": "10303"
  },
  {
      "Date": "11/9/17",
      "Open": "10358.65",
      "High": "10368.45",
      "Low": "10266.95",
      "Close": "10308"
  },
  {
      "Date": "11/10/17",
      "Open": "10304.35",
      "High": "10344.95",
      "Low": "10254.1",
      "Close": "10321"
  },
  {
      "Date": "11/13/17",
      "Open": "10322",
      "High": "10334.15",
      "Low": "10216.25",
      "Close": "10224"
  },
  {
      "Date": "11/14/17",
      "Open": "10223.4",
      "High": "10248",
      "Low": "10175.55",
      "Close": "10186"
  },
  {
      "Date": "11/15/17",
      "Open": "10171.95",
      "High": "10175.45",
      "Low": "10094",
      "Close": "10118"
  },
  {
      "Date": "11/16/17",
      "Open": "10152.9",
      "High": "10232.25",
      "Low": "10139.2",
      "Close": "10214"
  },
  {
      "Date": "11/17/17",
      "Open": "10324.55",
      "High": "10343.6",
      "Low": "10268.05",
      "Close": "10283"
  },
  {
      "Date": "11/20/17",
      "Open": "10287.2",
      "High": "10309.85",
      "Low": "10261.5",
      "Close": "10298"
  },
  {
      "Date": "11/21/17",
      "Open": "10329.25",
      "High": "10358.7",
      "Low": "10315.05",
      "Close": "10326"
  },
  {
      "Date": "11/22/17",
      "Open": "10350.8",
      "High": "10368.7",
      "Low": "10309.55",
      "Close": "10342"
  },
  {
      "Date": "11/23/17",
      "Open": "10358.45",
      "High": "10374.3",
      "Low": "10307.3",
      "Close": "10348"
  },
  {
      "Date": "11/24/17",
      "Open": "10366.8",
      "High": "10404.5",
      "Low": "10362.25",
      "Close": "10389"
  },
  {
      "Date": "11/27/17",
      "Open": "10361.05",
      "High": "10407.15",
      "Low": "10340.2",
      "Close": "10399"
  },
  {
      "Date": "11/28/17",
      "Open": "10387.9",
      "High": "10409.55",
      "Low": "10355.2",
      "Close": "10370"
  },
  {
      "Date": "11/29/17",
      "Open": "10376.65",
      "High": "10392.95",
      "Low": "10345.9",
      "Close": "10361"
  },
  {
      "Date": "11/30/17",
      "Open": "10332.7",
      "High": "10332.7",
      "Low": "10211.25",
      "Close": "10226"
  },
  {
      "Date": "12/1/17",
      "Open": "10263.7",
      "High": "10272.7",
      "Low": "10108.55",
      "Close": "10121"
  },
  {
      "Date": "12/4/17",
      "Open": "10175.05",
      "High": "10179.2",
      "Low": "10095.7",
      "Close": "10127"
  },
  {
      "Date": "12/5/17",
      "Open": "10118.25",
      "High": "10147.95",
      "Low": "10069.1",
      "Close": "10118"
  },
  {
      "Date": "12/6/17",
      "Open": "10088.8",
      "High": "10104.2",
      "Low": "10033.35",
      "Close": "10044"
  },
  {
      "Date": "12/7/17",
      "Open": "10063.45",
      "High": "10182.65",
      "Low": "10061.9",
      "Close": "10166"
  },
  {
      "Date": "12/8/17",
      "Open": "10198.45",
      "High": "10270.85",
      "Low": "10195.25",
      "Close": "10265"
  },
  {
      "Date": "12/11/17",
      "Open": "10310.5",
      "High": "10329.2",
      "Low": "10282.05",
      "Close": "10322"
  },
  {
      "Date": "12/12/17",
      "Open": "10324.9",
      "High": "10326.1",
      "Low": "10230.2",
      "Close": "10240"
  },
  {
      "Date": "12/13/17",
      "Open": "10236.6",
      "High": "10296.55",
      "Low": "10169.85",
      "Close": "10192"
  },
  {
      "Date": "12/14/17",
      "Open": "10229.3",
      "High": "10276.1",
      "Low": "10141.55",
      "Close": "10252"
  },
  {
      "Date": "12/15/17",
      "Open": "10345.65",
      "High": "10373.1",
      "Low": "10319.65",
      "Close": "10333"
  },
  {
      "Date": "12/18/17",
      "Open": "10263.1",
      "High": "10443.55",
      "Low": "10074.8",
      "Close": "10388"
  },
  {
      "Date": "12/19/17",
      "Open": "10414.8",
      "High": "10472.2",
      "Low": "10406",
      "Close": "10463"
  },
  {
      "Date": "12/20/17",
      "Open": "10494.4",
      "High": "10494.45",
      "Low": "10437.15",
      "Close": "10444"
  },
  {
      "Date": "12/21/17",
      "Open": "10473.95",
      "High": "10473.95",
      "Low": "10426.9",
      "Close": "10440"
  },
  {
      "Date": "12/22/17",
      "Open": "10457.3",
      "High": "10501.1",
      "Low": "10448.25",
      "Close": "10493"
  },
  {
      "Date": "12/26/17",
      "Open": "10512.3",
      "High": "10545.45",
      "Low": "10477.95",
      "Close": "10531"
  },
  {
      "Date": "12/27/17",
      "Open": "10531.05",
      "High": "10552.4",
      "Low": "10469.25",
      "Close": "10490"
  },
  {
      "Date": "12/28/17",
      "Open": "10498.2",
      "High": "10534.55",
      "Low": "10460.45",
      "Close": "10477"
  },
  {
      "Date": "12/29/17",
      "Open": "10492.35",
      "High": "10538.7",
      "Low": "10488.65",
      "Close": "10530"
  },
  {
      "Date": "1/1/18",
      "Open": "10531.7",
      "High": "10537.85",
      "Low": "10423.1",
      "Close": "10435.55"
  },
  {
      "Date": "1/2/18",
      "Open": "10477.55",
      "High": "10495.2",
      "Low": "10404.65",
      "Close": "10442.2"
  },
  {
      "Date": "1/3/18",
      "Open": "10482.65",
      "High": "10503.6",
      "Low": "10429.55",
      "Close": "10443.2"
  },
  {
      "Date": "1/4/18",
      "Open": "10469.4",
      "High": "10513",
      "Low": "10441.45",
      "Close": "10504.8"
  },
  {
      "Date": "1/5/18",
      "Open": "10534.25",
      "High": "10566.1",
      "Low": "10520.1",
      "Close": "10558.85"
  },
  {
      "Date": "1/8/18",
      "Open": "10591.7",
      "High": "10631.2",
      "Low": "10588.55",
      "Close": "10623.6"
  },
  {
      "Date": "1/9/18",
      "Open": "10645.1",
      "High": "10659.15",
      "Low": "10603.6",
      "Close": "10637"
  },
  {
      "Date": "1/10/18",
      "Open": "10652.05",
      "High": "10655.5",
      "Low": "10592.7",
      "Close": "10632.2"
  },
  {
      "Date": "1/11/18",
      "Open": "10637.05",
      "High": "10664.6",
      "Low": "10612.35",
      "Close": "10651.2"
  },
  {
      "Date": "1/12/18",
      "Open": "10682.55",
      "High": "10690.4",
      "Low": "10597.1",
      "Close": "10681.25"
  },
  {
      "Date": "1/15/18",
      "Open": "10718.5",
      "High": "10782.65",
      "Low": "10713.8",
      "Close": "10741.55"
  },
  {
      "Date": "1/16/18",
      "Open": "10761.5",
      "High": "10762.35",
      "Low": "10687.85",
      "Close": "10700.45"
  },
  {
      "Date": "1/17/18",
      "Open": "10702.45",
      "High": "10803",
      "Low": "10666.75",
      "Close": "10788.55"
  },
  {
      "Date": "1/18/18",
      "Open": "10873.4",
      "High": "10887.5",
      "Low": "10782.4",
      "Close": "10817"
  },
  {
      "Date": "1/19/18",
      "Open": "10829.2",
      "High": "10906.85",
      "Low": "10793.9",
      "Close": "10894.7"
  },
  {
      "Date": "1/22/18",
      "Open": "10883.2",
      "High": "10975.1",
      "Low": "10881.4",
      "Close": "10966.2"
  },
  {
      "Date": "1/23/18",
      "Open": "10997.4",
      "High": "11092.9",
      "Low": "10994.55",
      "Close": "11083.7"
  },
  {
      "Date": "1/24/18",
      "Open": "11069.35",
      "High": "11110.1",
      "Low": "11046.15",
      "Close": "11086"
  },
  {
      "Date": "1/25/18",
      "Open": "11095.6",
      "High": "11095.6",
      "Low": "11009.2",
      "Close": "11069.65"
  },
  {
      "Date": "1/29/18",
      "Open": "11079.35",
      "High": "11171.55",
      "Low": "11075.95",
      "Close": "11130.4"
  },
  {
      "Date": "1/30/18",
      "Open": "11120.85",
      "High": "11121.1",
      "Low": "11033.9",
      "Close": "11049.65"
  },
  {
      "Date": "1/31/18",
      "Open": "11018.8",
      "High": "11058.5",
      "Low": "10979.3",
      "Close": "11027.7"
  },
  {
      "Date": "2/1/18",
      "Open": "11044.55",
      "High": "11117.35",
      "Low": "10878.8",
      "Close": "11016.9"
  },
  {
      "Date": "2/2/18",
      "Open": "10938.2",
      "High": "10954.95",
      "Low": "10736.1",
      "Close": "10760.6"
  },
  {
      "Date": "2/5/18",
      "Open": "10604.3",
      "High": "10702.75",
      "Low": "10586.8",
      "Close": "10666.55"
  },
  {
      "Date": "2/6/18",
      "Open": "10295.15",
      "High": "10594.15",
      "Low": "10276.3",
      "Close": "10498.25"
  },
  {
      "Date": "2/7/18",
      "Open": "10607.2",
      "High": "10614",
      "Low": "10446.4",
      "Close": "10476.7"
  },
  {
      "Date": "2/8/18",
      "Open": "10518.5",
      "High": "10637.8",
      "Low": "10479.55",
      "Close": "10576.85"
  },
  {
      "Date": "2/9/18",
      "Open": "10416.5",
      "High": "10480.2",
      "Low": "10398.2",
      "Close": "10454.95"
  },
  {
      "Date": "2/12/18",
      "Open": "10518.2",
      "High": "10555.5",
      "Low": "10485.4",
      "Close": "10539.75"
  },
  {
      "Date": "2/14/18",
      "Open": "10585.75",
      "High": "10590.55",
      "Low": "10456.65",
      "Close": "10500.9"
  },
  {
      "Date": "2/15/18",
      "Open": "10537.9",
      "High": "10618.1",
      "Low": "10511.05",
      "Close": "10545.5"
  },
  {
      "Date": "2/16/18",
      "Open": "10596.2",
      "High": "10612.9",
      "Low": "10434.05",
      "Close": "10452.3"
  },
  {
      "Date": "2/19/18",
      "Open": "10488.9",
      "High": "10489.35",
      "Low": "10302.75",
      "Close": "10378.4"
  },
  {
      "Date": "2/20/18",
      "Open": "10391",
      "High": "10429.35",
      "Low": "10347.65",
      "Close": "10360.4"
  },
  {
      "Date": "2/21/18",
      "Open": "10426",
      "High": "10426.1",
      "Low": "10349.6",
      "Close": "10397.45"
  },
  {
      "Date": "2/22/18",
      "Open": "10354.35",
      "High": "10397.55",
      "Low": "10340.65",
      "Close": "10382.7"
  },
  {
      "Date": "2/23/18",
      "Open": "10408.1",
      "High": "10499.1",
      "Low": "10396.65",
      "Close": "10491.05"
  },
  {
      "Date": "2/26/18",
      "Open": "10526.55",
      "High": "10592.95",
      "Low": "10520.2",
      "Close": "10582.6"
  },
  {
      "Date": "2/27/18",
      "Open": "10615.2",
      "High": "10631.65",
      "Low": "10537.25",
      "Close": "10554.3"
  },
  {
      "Date": "2/28/18",
      "Open": "10488.95",
      "High": "10535.5",
      "Low": "10461.55",
      "Close": "10492.85"
  },
  {
      "Date": "3/1/18",
      "Open": "10479.95",
      "High": "10525.5",
      "Low": "10447.15",
      "Close": "10458.35"
  },
  {
      "Date": "3/5/18",
      "Open": "10428.3",
      "High": "10428.7",
      "Low": "10323.9",
      "Close": "10358.85"
  },
  {
      "Date": "3/6/18",
      "Open": "10420.5",
      "High": "10441.35",
      "Low": "10215.9",
      "Close": "10249.25"
  },
  {
      "Date": "3/7/18",
      "Open": "10232.95",
      "High": "10243.35",
      "Low": "10141.55",
      "Close": "10154.2"
  },
  {
      "Date": "3/8/18",
      "Open": "10216.25",
      "High": "10270.35",
      "Low": "10146.4",
      "Close": "10242.65"
  },
  {
      "Date": "3/9/18",
      "Open": "10271.3",
      "High": "10296.7",
      "Low": "10211.9",
      "Close": "10226.85"
  },
  {
      "Date": "3/12/18",
      "Open": "10301.6",
      "High": "10433.65",
      "Low": "10295.45",
      "Close": "10421.4"
  },
  {
      "Date": "3/13/18",
      "Open": "10389.5",
      "High": "10478.6",
      "Low": "10377.85",
      "Close": "10426.85"
  },
  {
      "Date": "3/14/18",
      "Open": "10393.05",
      "High": "10420.35",
      "Low": "10336.3",
      "Close": "10410.9"
  },
  {
      "Date": "3/15/18",
      "Open": "10405.45",
      "High": "10420",
      "Low": "10346.2",
      "Close": "10360.15"
  },
  {
      "Date": "3/16/18",
      "Open": "10345.15",
      "High": "10346.3",
      "Low": "10180.25",
      "Close": "10195.15"
  },
  {
      "Date": "3/19/18",
      "Open": "10215.35",
      "High": "10224.55",
      "Low": "10075.3",
      "Close": "10094.25"
  },
  {
      "Date": "3/20/18",
      "Open": "10051.55",
      "High": "10155.65",
      "Low": "10049.1",
      "Close": "10124.35"
  },
  {
      "Date": "3/21/18",
      "Open": "10181.95",
      "High": "10227.3",
      "Low": "10132.95",
      "Close": "10155.25"
  },
  {
      "Date": "3/22/18",
      "Open": "10167.5",
      "High": "10207.85",
      "Low": "10105.4",
      "Close": "10114.75"
  },
  {
      "Date": "3/23/18",
      "Open": "9968.8",
      "High": "10027.7",
      "Low": "9951.9",
      "Close": "9998.05"
  },
  {
      "Date": "3/26/18",
      "Open": "9989.15",
      "High": "10143.5",
      "Low": "9958.55",
      "Close": "10130.65"
  },
  {
      "Date": "3/27/18",
      "Open": "10188",
      "High": "10207.9",
      "Low": "10139.65",
      "Close": "10184.15"
  },
  {
      "Date": "3/28/18",
      "Open": "10143.6",
      "High": "10158.35",
      "Low": "10096.9",
      "Close": "10113.7"
  },
  {
      "Date": "4/2/18",
      "Open": "10151.65",
      "High": "10220.1",
      "Low": "10127.75",
      "Close": "10211.8"
  },
  {
      "Date": "4/3/18",
      "Open": "10186.85",
      "High": "10255.35",
      "Low": "10171.05",
      "Close": "10245"
  },
  {
      "Date": "4/4/18",
      "Open": "10274.6",
      "High": "10279.85",
      "Low": "10111.3",
      "Close": "10128.4"
  },
  {
      "Date": "4/5/18",
      "Open": "10228.45",
      "High": "10331.8",
      "Low": "10227.45",
      "Close": "10325.15"
  },
  {
      "Date": "4/6/18",
      "Open": "10322.75",
      "High": "10350.45",
      "Low": "10290.85",
      "Close": "10331.6"
  },
  {
      "Date": "4/9/18",
      "Open": "10333.7",
      "High": "10397.7",
      "Low": "10328.5",
      "Close": "10379.35"
  },
  {
      "Date": "4/10/18",
      "Open": "10412.9",
      "High": "10424.85",
      "Low": "10381.5",
      "Close": "10402.25"
  },
  {
      "Date": "4/11/18",
      "Open": "10428.15",
      "High": "10428.15",
      "Low": "10355.6",
      "Close": "10417.15"
  },
  {
      "Date": "4/12/18",
      "Open": "10410.65",
      "High": "10469.9",
      "Low": "10395.25",
      "Close": "10458.65"
  },
  {
      "Date": "4/13/18",
      "Open": "10495.3",
      "High": "10519.9",
      "Low": "10451.45",
      "Close": "10480.6"
  },
  {
      "Date": "4/16/18",
      "Open": "10398.3",
      "High": "10540.15",
      "Low": "10396.35",
      "Close": "10528.35"
  },
  {
      "Date": "4/17/18",
      "Open": "10557.3",
      "High": "10560.45",
      "Low": "10495.65",
      "Close": "10548.7"
  },
  {
      "Date": "4/18/18",
      "Open": "10578.9",
      "High": "10594.2",
      "Low": "10509.7",
      "Close": "10526.2"
  },
  {
      "Date": "4/19/18",
      "Open": "10563.65",
      "High": "10572.2",
      "Low": "10546.2",
      "Close": "10565.3"
  },
  {
      "Date": "4/20/18",
      "Open": "10560.35",
      "High": "10582.35",
      "Low": "10527.45",
      "Close": "10564.05"
  },
  {
      "Date": "4/23/18",
      "Open": "10592.8",
      "High": "10638.35",
      "Low": "10514.95",
      "Close": "10584.7"
  },
  {
      "Date": "4/24/18",
      "Open": "10578.1",
      "High": "10636.8",
      "Low": "10569",
      "Close": "10614.35"
  },
  {
      "Date": "4/25/18",
      "Open": "10612.4",
      "High": "10612.6",
      "Low": "10536.45",
      "Close": "10570.55"
  },
  {
      "Date": "4/26/18",
      "Open": "10586.5",
      "High": "10628.4",
      "Low": "10559.65",
      "Close": "10617.8"
  },
  {
      "Date": "4/27/18",
      "Open": "10651.65",
      "High": "10719.8",
      "Low": "10647.55",
      "Close": "10692.3"
  },
  {
      "Date": "4/30/18",
      "Open": "10705.75",
      "High": "10759",
      "Low": "10704.6",
      "Close": "10739.35"
  },
  {
      "Date": "5/2/18",
      "Open": "10783.85",
      "High": "10784.65",
      "Low": "10689.8",
      "Close": "10718.05"
  },
  {
      "Date": "5/3/18",
      "Open": "10720.15",
      "High": "10720.6",
      "Low": "10647.45",
      "Close": "10679.65"
  },
  {
      "Date": "5/4/18",
      "Open": "10700.45",
      "High": "10700.45",
      "Low": "10601.6",
      "Close": "10618.25"
  },
  {
      "Date": "5/7/18",
      "Open": "10653.15",
      "High": "10725.65",
      "Low": "10635.65",
      "Close": "10715.5"
  },
  {
      "Date": "5/8/18",
      "Open": "10757.9",
      "High": "10758.55",
      "Low": "10689.4",
      "Close": "10717.8"
  },
  {
      "Date": "5/9/18",
      "Open": "10693.35",
      "High": "10766.25",
      "Low": "10689.85",
      "Close": "10741.7"
  },
  {
      "Date": "5/10/18",
      "Open": "10779.65",
      "High": "10785.55",
      "Low": "10705",
      "Close": "10716.55"
  },
  {
      "Date": "5/11/18",
      "Open": "10741.95",
      "High": "10812.05",
      "Low": "10724.45",
      "Close": "10806.5"
  },
  {
      "Date": "5/14/18",
      "Open": "10815.15",
      "High": "10834.85",
      "Low": "10774.75",
      "Close": "10806.6"
  },
  {
      "Date": "5/15/18",
      "Open": "10812.6",
      "High": "10929.2",
      "Low": "10781.4",
      "Close": "10801.85"
  },
  {
      "Date": "5/16/18",
      "Open": "10751.95",
      "High": "10790.45",
      "Low": "10699.7",
      "Close": "10741.1"
  },
  {
      "Date": "5/17/18",
      "Open": "10775.6",
      "High": "10777.25",
      "Low": "10664.5",
      "Close": "10682.7"
  },
  {
      "Date": "5/18/18",
      "Open": "10671.85",
      "High": "10674.95",
      "Low": "10589.1",
      "Close": "10596.4"
  },
  {
      "Date": "5/21/18",
      "Open": "10616.7",
      "High": "10621.7",
      "Low": "10505.8",
      "Close": "10516.7"
  },
  {
      "Date": "5/22/18",
      "Open": "10518.45",
      "High": "10558.6",
      "Low": "10490.55",
      "Close": "10536.7"
  },
  {
      "Date": "5/23/18",
      "Open": "10521.1",
      "High": "10533.55",
      "Low": "10417.8",
      "Close": "10430.35"
  },
  {
      "Date": "5/24/18",
      "Open": "10464.85",
      "High": "10535.15",
      "Low": "10419.8",
      "Close": "10513.85"
  },
  {
      "Date": "5/25/18",
      "Open": "10533.05",
      "High": "10628.05",
      "Low": "10524",
      "Close": "10605.15"
  },
  {
      "Date": "5/28/18",
      "Open": "10648.35",
      "High": "10709.8",
      "Low": "10640.55",
      "Close": "10688.65"
  },
  {
      "Date": "5/29/18",
      "Open": "10689.4",
      "High": "10717.25",
      "Low": "10616.1",
      "Close": "10633.3"
  },
  {
      "Date": "5/30/18",
      "Open": "10579",
      "High": "10648.7",
      "Low": "10558.45",
      "Close": "10614.35"
  },
  {
      "Date": "5/31/18",
      "Open": "10670.1",
      "High": "10763.8",
      "Low": "10620.4",
      "Close": "10736.15"
  },
  {
      "Date": "6/1/18",
      "Open": "10738.45",
      "High": "10764.75",
      "Low": "10681.5",
      "Close": "10696.2"
  },
  {
      "Date": "6/4/18",
      "Open": "10765.95",
      "High": "10770.3",
      "Low": "10618.35",
      "Close": "10628.5"
  },
  {
      "Date": "6/5/18",
      "Open": "10630.7",
      "High": "10633.15",
      "Low": "10550.9",
      "Close": "10593.15"
  },
  {
      "Date": "6/6/18",
      "Open": "10603.45",
      "High": "10698.35",
      "Low": "10587.5",
      "Close": "10684.65"
  },
  {
      "Date": "6/7/18",
      "Open": "10722.6",
      "High": "10818",
      "Low": "10722.6",
      "Close": "10768.35"
  },
  {
      "Date": "6/8/18",
      "Open": "10736.4",
      "High": "10779.45",
      "Low": "10709.05",
      "Close": "10767.65"
  },
  {
      "Date": "6/11/18",
      "Open": "10781.85",
      "High": "10850.55",
      "Low": "10777.05",
      "Close": "10786.95"
  },
  {
      "Date": "6/12/18",
      "Open": "10816.15",
      "High": "10856.55",
      "Low": "10789.4",
      "Close": "10842.85"
  },
  {
      "Date": "6/13/18",
      "Open": "10887.5",
      "High": "10893.25",
      "Low": "10842.65",
      "Close": "10856.7"
  },
  {
      "Date": "6/14/18",
      "Open": "10832.9",
      "High": "10833.7",
      "Low": "10773.55",
      "Close": "10808.05"
  },
  {
      "Date": "6/15/18",
      "Open": "10808.65",
      "High": "10834",
      "Low": "10755.4",
      "Close": "10817.7"
  },
  {
      "Date": "6/18/18",
      "Open": "10830.2",
      "High": "10830.2",
      "Low": "10787.35",
      "Close": "10799.85"
  },
  {
      "Date": "6/19/18",
      "Open": "10789.45",
      "High": "10789.45",
      "Low": "10701.2",
      "Close": "10710.45"
  },
  {
      "Date": "6/20/18",
      "Open": "10734.65",
      "High": "10781.8",
      "Low": "10724.05",
      "Close": "10772.05"
  },
  {
      "Date": "6/21/18",
      "Open": "10808.45",
      "High": "10809.6",
      "Low": "10725.9",
      "Close": "10741.1"
  },
  {
      "Date": "6/22/18",
      "Open": "10742.7",
      "High": "10837",
      "Low": "10710.45",
      "Close": "10821.85"
  },
  {
      "Date": "6/25/18",
      "Open": "10822.9",
      "High": "10831.05",
      "Low": "10753.05",
      "Close": "10762.45"
  },
  {
      "Date": "6/26/18",
      "Open": "10742.7",
      "High": "10805.25",
      "Low": "10732.55",
      "Close": "10769.15"
  },
  {
      "Date": "6/27/18",
      "Open": "10785.5",
      "High": "10785.5",
      "Low": "10652.4",
      "Close": "10671.4"
  },
  {
      "Date": "6/28/18",
      "Open": "10660.8",
      "High": "10674.2",
      "Low": "10557.7",
      "Close": "10589.1"
  },
  {
      "Date": "6/29/18",
      "Open": "10612.85",
      "High": "10723.05",
      "Low": "10612.35",
      "Close": "10714.3"
  },
  {
      "Date": "7/2/18",
      "Open": "10732.35",
      "High": "10736.15",
      "Low": "10604.65",
      "Close": "10657.3"
  },
  {
      "Date": "7/3/18",
      "Open": "10668.6",
      "High": "10713.3",
      "Low": "10630.25",
      "Close": "10699.9"
  },
  {
      "Date": "7/4/18",
      "Open": "10715",
      "High": "10777.15",
      "Low": "10677.75",
      "Close": "10769.9"
  },
  {
      "Date": "7/5/18",
      "Open": "10786.05",
      "High": "10786.05",
      "Low": "10726.25",
      "Close": "10749.75"
  },
  {
      "Date": "7/6/18",
      "Open": "10744.15",
      "High": "10816.35",
      "Low": "10735.05",
      "Close": "10772.65"
  },
  {
      "Date": "7/9/18",
      "Open": "10838.3",
      "High": "10860.35",
      "Low": "10807.15",
      "Close": "10852.9"
  },
  {
      "Date": "7/10/18",
      "Open": "10902.75",
      "High": "10956.9",
      "Low": "10876.65",
      "Close": "10947.25"
  },
  {
      "Date": "7/11/18",
      "Open": "10956.4",
      "High": "10976.65",
      "Low": "10923",
      "Close": "10948.3"
  },
  {
      "Date": "7/12/18",
      "Open": "11006.95",
      "High": "11078.3",
      "Low": "10999.65",
      "Close": "11023.2"
  },
  {
      "Date": "7/13/18",
      "Open": "11056.9",
      "High": "11071.35",
      "Low": "10999.75",
      "Close": "11018.9"
  },
  {
      "Date": "7/16/18",
      "Open": "11018.95",
      "High": "11019.5",
      "Low": "10926.25",
      "Close": "10936.85"
  },
  {
      "Date": "7/17/18",
      "Open": "10939.65",
      "High": "11018.5",
      "Low": "10925.6",
      "Close": "11008.05"
  },
  {
      "Date": "7/18/18",
      "Open": "11060.2",
      "High": "11076.2",
      "Low": "10956.3",
      "Close": "10980.45"
  },
  {
      "Date": "7/19/18",
      "Open": "10999.5",
      "High": "11006.5",
      "Low": "10935.45",
      "Close": "10957.1"
  },
  {
      "Date": "7/20/18",
      "Open": "10963.5",
      "High": "11030.25",
      "Low": "10946.2",
      "Close": "11010.2"
  },
  {
      "Date": "7/23/18",
      "Open": "11019.85",
      "High": "11093.4",
      "Low": "11010.95",
      "Close": "11084.75"
  },
  {
      "Date": "7/24/18",
      "Open": "11109",
      "High": "11143.4",
      "Low": "11092.5",
      "Close": "11134.3"
  },
  {
      "Date": "7/25/18",
      "Open": "11148.4",
      "High": "11157.15",
      "Low": "11113.25",
      "Close": "11132"
  },
  {
      "Date": "7/26/18",
      "Open": "11132.95",
      "High": "11185.85",
      "Low": "11125.7",
      "Close": "11167.3"
  },
  {
      "Date": "7/27/18",
      "Open": "11232.75",
      "High": "11283.4",
      "Low": "11210.25",
      "Close": "11278.35"
  },
  {
      "Date": "7/30/18",
      "Open": "11296.65",
      "High": "11328.1",
      "Low": "11261.45",
      "Close": "11319.55"
  },
  {
      "Date": "7/31/18",
      "Open": "11311.05",
      "High": "11366",
      "Low": "11267.75",
      "Close": "11356.5"
  },
  {
      "Date": "8/1/18",
      "Open": "11359.8",
      "High": "11390.55",
      "Low": "11313.55",
      "Close": "11346.2"
  },
  {
      "Date": "8/2/18",
      "Open": "11328.9",
      "High": "11328.9",
      "Low": "11234.95",
      "Close": "11244.7"
  },
  {
      "Date": "8/3/18",
      "Open": "11297.8",
      "High": "11368",
      "Low": "11294.55",
      "Close": "11360.8"
  },
  {
      "Date": "8/6/18",
      "Open": "11401.5",
      "High": "11427.65",
      "Low": "11370.6",
      "Close": "11387.1"
  },
  {
      "Date": "8/7/18",
      "Open": "11423.15",
      "High": "11428.95",
      "Low": "11359.7",
      "Close": "11389.45"
  },
  {
      "Date": "8/8/18",
      "Open": "11412.5",
      "High": "11459.95",
      "Low": "11379.3",
      "Close": "11450"
  },
  {
      "Date": "8/9/18",
      "Open": "11493.25",
      "High": "11495.2",
      "Low": "11454.1",
      "Close": "11470.7"
  },
  {
      "Date": "8/10/18",
      "Open": "11474.95",
      "High": "11478.75",
      "Low": "11419.65",
      "Close": "11429.5"
  },
  {
      "Date": "8/13/18",
      "Open": "11369.6",
      "High": "11406.3",
      "Low": "11340.3",
      "Close": "11355.75"
  },
  {
      "Date": "8/14/18",
      "Open": "11381.7",
      "High": "11452.45",
      "Low": "11370.8",
      "Close": "11435.1"
  },
  {
      "Date": "8/16/18",
      "Open": "11397.15",
      "High": "11449.85",
      "Low": "11366.25",
      "Close": "11385.05"
  },
  {
      "Date": "8/17/18",
      "Open": "11437.15",
      "High": "11486.45",
      "Low": "11431.8",
      "Close": "11470.75"
  },
  {
      "Date": "8/20/18",
      "Open": "11502.1",
      "High": "11565.3",
      "Low": "11499.65",
      "Close": "11551.75"
  },
  {
      "Date": "8/21/18",
      "Open": "11576.2",
      "High": "11581.75",
      "Low": "11539.6",
      "Close": "11570.9"
  },
  {
      "Date": "8/23/18",
      "Open": "11620.7",
      "High": "11620.7",
      "Low": "11546.7",
      "Close": "11582.75"
  },
  {
      "Date": "8/24/18",
      "Open": "11566.6",
      "High": "11604.6",
      "Low": "11532",
      "Close": "11557.1"
  },
  {
      "Date": "8/27/18",
      "Open": "11605.85",
      "High": "11700.95",
      "Low": "11595.6",
      "Close": "11691.95"
  },
  {
      "Date": "8/28/18",
      "Open": "11731.95",
      "High": "11760.2",
      "Low": "11710.5",
      "Close": "11738.5"
  },
  {
      "Date": "8/29/18",
      "Open": "11744.95",
      "High": "11753.2",
      "Low": "11678.85",
      "Close": "11691.9"
  },
  {
      "Date": "8/30/18",
      "Open": "11694.75",
      "High": "11698.8",
      "Low": "11639.7",
      "Close": "11676.8"
  },
  {
      "Date": "8/31/18",
      "Open": "11675.85",
      "High": "11727.65",
      "Low": "11640.1",
      "Close": "11680.5"
  },
  {
      "Date": "9/3/18",
      "Open": "11751.8",
      "High": "11751.8",
      "Low": "11567.4",
      "Close": "11582.35"
  },
  {
      "Date": "9/4/18",
      "Open": "11598.75",
      "High": "11602.55",
      "Low": "11496.85",
      "Close": "11520.3"
  },
  {
      "Date": "9/5/18",
      "Open": "11514.85",
      "High": "11542.65",
      "Low": "11393.85",
      "Close": "11476.95"
  },
  {
      "Date": "9/6/18",
      "Open": "11514.15",
      "High": "11562.25",
      "Low": "11436.05",
      "Close": "11536.9"
  },
  {
      "Date": "9/7/18",
      "Open": "11558.25",
      "High": "11603",
      "Low": "11484.4",
      "Close": "11589.1"
  },
  {
      "Date": "9/10/18",
      "Open": "11570.25",
      "High": "11573",
      "Low": "11427.3",
      "Close": "11438.1"
  },
  {
      "Date": "9/11/18",
      "Open": "11476.85",
      "High": "11479.4",
      "Low": "11274",
      "Close": "11287.5"
  },
  {
      "Date": "9/12/18",
      "Open": "11340.1",
      "High": "11380.75",
      "Low": "11250.2",
      "Close": "11369.9"
  },
  {
      "Date": "9/14/18",
      "Open": "11443.5",
      "High": "11523.25",
      "Low": "11430.55",
      "Close": "11515.2"
  },
  {
      "Date": "9/17/18",
      "Open": "11464.95",
      "High": "11464.95",
      "Low": "11366.9",
      "Close": "11377.75"
  },
  {
      "Date": "9/18/18",
      "Open": "11381.55",
      "High": "11411.45",
      "Low": "11268.95",
      "Close": "11278.9"
  },
  {
      "Date": "9/19/18",
      "Open": "11326.65",
      "High": "11332.05",
      "Low": "11210.9",
      "Close": "11234.35"
  },
  {
      "Date": "9/21/18",
      "Open": "11271.3",
      "High": "11346.8",
      "Low": "10866.45",
      "Close": "11143.1"
  },
  {
      "Date": "9/24/18",
      "Open": "11164.4",
      "High": "11170.15",
      "Low": "10943.6",
      "Close": "10967.4"
  },
  {
      "Date": "9/25/18",
      "Open": "10969.95",
      "High": "11080.6",
      "Low": "10882.85",
      "Close": "11067.45"
  },
  {
      "Date": "9/26/18",
      "Open": "11145.55",
      "High": "11145.55",
      "Low": "10993.05",
      "Close": "11053.8"
  },
  {
      "Date": "9/27/18",
      "Open": "11079.8",
      "High": "11089.45",
      "Low": "10953.35",
      "Close": "10977.55"
  },
  {
      "Date": "9/28/18",
      "Open": "11008.1",
      "High": "11034.1",
      "Low": "10850.3",
      "Close": "10930.45"
  },
  {
      "Date": "10/1/18",
      "Open": "10930.9",
      "High": "11035.65",
      "Low": "10821.55",
      "Close": "11008.3"
  },
  {
      "Date": "10/3/18",
      "Open": "10982.7",
      "High": "10989.05",
      "Low": "10843.75",
      "Close": "10858.25"
  },
  {
      "Date": "10/4/18",
      "Open": "10754.7",
      "High": "10754.7",
      "Low": "10547.25",
      "Close": "10599.25"
  },
  {
      "Date": "10/5/18",
      "Open": "10514.1",
      "High": "10540.65",
      "Low": "10261.9",
      "Close": "10316.45"
  },
  {
      "Date": "10/8/18",
      "Open": "10310.15",
      "High": "10398.35",
      "Low": "10198.4",
      "Close": "10348.05"
  },
  {
      "Date": "10/9/18",
      "Open": "10390.3",
      "High": "10397.6",
      "Low": "10279.35",
      "Close": "10301.05"
  },
  {
      "Date": "10/10/18",
      "Open": "10331.85",
      "High": "10482.35",
      "Low": "10318.25",
      "Close": "10460.1"
  },
  {
      "Date": "10/11/18",
      "Open": "10169.8",
      "High": "10335.95",
      "Low": "10138.6",
      "Close": "10234.65"
  },
  {
      "Date": "10/12/18",
      "Open": "10331.55",
      "High": "10492.45",
      "Low": "10322.15",
      "Close": "10472.5"
  },
  {
      "Date": "10/15/18",
      "Open": "10524.2",
      "High": "10526.3",
      "Low": "10410.15",
      "Close": "10512.5"
  },
  {
      "Date": "10/16/18",
      "Open": "10550.15",
      "High": "10604.9",
      "Low": "10525.3",
      "Close": "10584.75"
  },
  {
      "Date": "10/17/18",
      "Open": "10688.7",
      "High": "10710.15",
      "Low": "10436.45",
      "Close": "10453.05"
  },
  {
      "Date": "10/19/18",
      "Open": "10339.7",
      "High": "10380.1",
      "Low": "10249.6",
      "Close": "10303.55"
  },
  {
      "Date": "10/22/18",
      "Open": "10405.85",
      "High": "10408.55",
      "Low": "10224",
      "Close": "10245.25"
  },
  {
      "Date": "10/23/18",
      "Open": "10152.6",
      "High": "10222.1",
      "Low": "10102.35",
      "Close": "10146.8"
  },
  {
      "Date": "10/24/18",
      "Open": "10278.15",
      "High": "10290.65",
      "Low": "10126.7",
      "Close": "10224.75"
  },
  {
      "Date": "10/25/18",
      "Open": "10135.05",
      "High": "10166.6",
      "Low": "10079.3",
      "Close": "10124.9"
  },
  {
      "Date": "10/26/18",
      "Open": "10122.35",
      "High": "10128.85",
      "Low": "10004.55",
      "Close": "10030"
  },
  {
      "Date": "10/29/18",
      "Open": "10078.1",
      "High": "10275.3",
      "Low": "10020.35",
      "Close": "10250.85"
  },
  {
      "Date": "10/30/18",
      "Open": "10239.4",
      "High": "10285.1",
      "Low": "10175.35",
      "Close": "10198.4"
  },
  {
      "Date": "10/31/18",
      "Open": "10209.55",
      "High": "10396",
      "Low": "10105.1",
      "Close": "10386.6"
  },
  {
      "Date": "11/1/18",
      "Open": "10441.7",
      "High": "10441.9",
      "Low": "10341.9",
      "Close": "10380.45"
  },
  {
      "Date": "11/2/18",
      "Open": "10462.3",
      "High": "10606.95",
      "Low": "10457.7",
      "Close": "10553"
  },
  {
      "Date": "11/5/18",
      "Open": "10558.75",
      "High": "10558.8",
      "Low": "10477",
      "Close": "10524"
  },
  {
      "Date": "11/6/18",
      "Open": "10552",
      "High": "10600.25",
      "Low": "10491.45",
      "Close": "10530"
  },
  {
      "Date": "11/7/18",
      "Open": "10614.45",
      "High": "10616.45",
      "Low": "10582.3",
      "Close": "10598.4"
  },
  {
      "Date": "11/9/18",
      "Open": "10614.7",
      "High": "10619.55",
      "Low": "10544.85",
      "Close": "10585.2"
  },
  {
      "Date": "11/12/18",
      "Open": "10607.8",
      "High": "10645.5",
      "Low": "10464.05",
      "Close": "10482.2"
  },
  {
      "Date": "11/13/18",
      "Open": "10451.9",
      "High": "10596.25",
      "Low": "10440.55",
      "Close": "10582.5"
  },
  {
      "Date": "11/14/18",
      "Open": "10634.9",
      "High": "10651.6",
      "Low": "10532.7",
      "Close": "10576.3"
  },
  {
      "Date": "11/15/18",
      "Open": "10580.6",
      "High": "10646.5",
      "Low": "10557.5",
      "Close": "10616.7"
  },
  {
      "Date": "11/16/18",
      "Open": "10644",
      "High": "10695.15",
      "Low": "10631.15",
      "Close": "10682.2"
  },
  {
      "Date": "11/19/18",
      "Open": "10731.25",
      "High": "10774.7",
      "Low": "10688.8",
      "Close": "10763.4"
  },
  {
      "Date": "11/20/18",
      "Open": "10740.1",
      "High": "10740.85",
      "Low": "10640.85",
      "Close": "10656.2"
  },
  {
      "Date": "11/21/18",
      "Open": "10670.95",
      "High": "10671.3",
      "Low": "10562.35",
      "Close": "10600.05"
  },
  {
      "Date": "11/22/18",
      "Open": "10612.65",
      "High": "10646.25",
      "Low": "10512",
      "Close": "10526.75"
  },
  {
      "Date": "11/26/18",
      "Open": "10568.3",
      "High": "10637.8",
      "Low": "10489.75",
      "Close": "10628.6"
  },
  {
      "Date": "11/27/18",
      "Open": "10621.45",
      "High": "10695.15",
      "Low": "10596.35",
      "Close": "10685.6"
  },
  {
      "Date": "11/28/18",
      "Open": "10708.75",
      "High": "10757.8",
      "Low": "10699.85",
      "Close": "10728.85"
  },
  {
      "Date": "11/29/18",
      "Open": "10808.7",
      "High": "10883.05",
      "Low": "10782.35",
      "Close": "10858.7"
  },
  {
      "Date": "11/30/18",
      "Open": "10892.1",
      "High": "10922.45",
      "Low": "10835.1",
      "Close": "10876.75"
  },
  {
      "Date": "12/3/18",
      "Open": "10930.7",
      "High": "10941.2",
      "Low": "10845.35",
      "Close": "10883.75"
  },
  {
      "Date": "12/4/18",
      "Open": "10877.1",
      "High": "10890.95",
      "Low": "10833.35",
      "Close": "10869.5"
  },
  {
      "Date": "12/5/18",
      "Open": "10820.45",
      "High": "10821.05",
      "Low": "10747.95",
      "Close": "10782.9"
  },
  {
      "Date": "12/6/18",
      "Open": "10718.15",
      "High": "10722.65",
      "Low": "10588.25",
      "Close": "10601.15"
  },
  {
      "Date": "12/7/18",
      "Open": "10644.8",
      "High": "10704.55",
      "Low": "10599.35",
      "Close": "10693.7"
  },
  {
      "Date": "12/10/18",
      "Open": "10508.7",
      "High": "10558.85",
      "Low": "10474.95",
      "Close": "10488.45"
  },
  {
      "Date": "12/11/18",
      "Open": "10350.05",
      "High": "10567.15",
      "Low": "10333.85",
      "Close": "10549.15"
  },
  {
      "Date": "12/12/18",
      "Open": "10591",
      "High": "10752.2",
      "Low": "10560.8",
      "Close": "10737.6"
  },
  {
      "Date": "12/13/18",
      "Open": "10810.75",
      "High": "10838.6",
      "Low": "10749.5",
      "Close": "10791.55"
  },
  {
      "Date": "12/14/18",
      "Open": "10784.5",
      "High": "10815.75",
      "Low": "10752.1",
      "Close": "10805.45"
  },
  {
      "Date": "12/17/18",
      "Open": "10853.2",
      "High": "10900.35",
      "Low": "10844.85",
      "Close": "10888.35"
  },
  {
      "Date": "12/18/18",
      "Open": "10850.9",
      "High": "10915.4",
      "Low": "10819.1",
      "Close": "10908.7"
  },
  {
      "Date": "12/19/18",
      "Open": "10930.55",
      "High": "10985.15",
      "Low": "10928",
      "Close": "10967.3"
  },
  {
      "Date": "12/20/18",
      "Open": "10885.2",
      "High": "10962.55",
      "Low": "10880.05",
      "Close": "10951.7"
  },
  {
      "Date": "12/21/18",
      "Open": "10944.25",
      "High": "10963.65",
      "Low": "10738.65",
      "Close": "10754"
  },
  {
      "Date": "12/24/18",
      "Open": "10780.9",
      "High": "10782.3",
      "Low": "10649.25",
      "Close": "10663.5"
  },
  {
      "Date": "12/26/18",
      "Open": "10635.45",
      "High": "10747.5",
      "Low": "10534.55",
      "Close": "10729.85"
  },
  {
      "Date": "12/27/18",
      "Open": "10817.9",
      "High": "10834.2",
      "Low": "10764.45",
      "Close": "10779.8"
  },
  {
      "Date": "12/28/18",
      "Open": "10820.95",
      "High": "10893.6",
      "Low": "10817.15",
      "Close": "10859.9"
  },
  {
      "Date": "12/31/18",
      "Open": "10913.2",
      "High": "10923.55",
      "Low": "10853.2",
      "Close": "10862.55"
  },
  {
      "Date": "1/1/19",
      "Open": "10881.7",
      "High": "10923.6",
      "Low": "10807.1",
      "Close": "10910.1"
  },
  {
      "Date": "1/2/19",
      "Open": "10868.85",
      "High": "10895.35",
      "Low": "10735.05",
      "Close": "10792.5"
  },
  {
      "Date": "1/3/19",
      "Open": "10796.8",
      "High": "10814.05",
      "Low": "10661.25",
      "Close": "10672.25"
  },
  {
      "Date": "1/4/19",
      "Open": "10699.7",
      "High": "10741.05",
      "Low": "10628.65",
      "Close": "10727.35"
  },
  {
      "Date": "1/7/19",
      "Open": "10804.85",
      "High": "10835.95",
      "Low": "10750.15",
      "Close": "10771.8"
  },
  {
      "Date": "1/8/19",
      "Open": "10786.25",
      "High": "10818.45",
      "Low": "10733.25",
      "Close": "10802.15"
  },
  {
      "Date": "1/9/19",
      "Open": "10862.4",
      "High": "10870.4",
      "Low": "10749.4",
      "Close": "10855.15"
  },
  {
      "Date": "1/10/19",
      "Open": "10859.35",
      "High": "10859.35",
      "Low": "10801.8",
      "Close": "10821.6"
  },
  {
      "Date": "1/11/19",
      "Open": "10834.75",
      "High": "10850.15",
      "Low": "10739.4",
      "Close": "10794.95"
  },
  {
      "Date": "1/14/19",
      "Open": "10807",
      "High": "10808",
      "Low": "10692.35",
      "Close": "10737.6"
  },
  {
      "Date": "1/15/19",
      "Open": "10777.55",
      "High": "10896.95",
      "Low": "10777.55",
      "Close": "10886.8"
  },
  {
      "Date": "1/16/19",
      "Open": "10899.65",
      "High": "10928.15",
      "Low": "10876.9",
      "Close": "10890.3"
  },
  {
      "Date": "1/17/19",
      "Open": "10920.85",
      "High": "10930.65",
      "Low": "10844.65",
      "Close": "10905.2"
  },
  {
      "Date": "1/18/19",
      "Open": "10914.85",
      "High": "10928.2",
      "Low": "10852.2",
      "Close": "10906.95"
  },
  {
      "Date": "1/21/19",
      "Open": "10919.35",
      "High": "10987.45",
      "Low": "10885.75",
      "Close": "10961.85"
  },
  {
      "Date": "1/22/19",
      "Open": "10949.8",
      "High": "10949.8",
      "Low": "10864.15",
      "Close": "10922.75"
  },
  {
      "Date": "1/23/19",
      "Open": "10931.05",
      "High": "10944.8",
      "Low": "10811.95",
      "Close": "10831.5"
  },
  {
      "Date": "1/24/19",
      "Open": "10844.05",
      "High": "10866.6",
      "Low": "10798.65",
      "Close": "10849.8"
  },
  {
      "Date": "1/25/19",
      "Open": "10859.75",
      "High": "10931.7",
      "Low": "10756.45",
      "Close": "10780.55"
  },
  {
      "Date": "1/28/19",
      "Open": "10792.45",
      "High": "10804.45",
      "Low": "10630.95",
      "Close": "10661.55"
  },
  {
      "Date": "1/29/19",
      "Open": "10653.7",
      "High": "10690.35",
      "Low": "10583.65",
      "Close": "10652.2"
  },
  {
      "Date": "1/30/19",
      "Open": "10702.25",
      "High": "10710.2",
      "Low": "10612.85",
      "Close": "10651.8"
  },
  {
      "Date": "1/31/19",
      "Open": "10690.55",
      "High": "10838.05",
      "Low": "10678.55",
      "Close": "10830.95"
  },
  {
      "Date": "2/1/19",
      "Open": "10851.35",
      "High": "10983.45",
      "Low": "10813.45",
      "Close": "10893.65"
  },
  {
      "Date": "2/4/19",
      "Open": "10876.75",
      "High": "10927.9",
      "Low": "10814.15",
      "Close": "10912.25"
  },
  {
      "Date": "2/5/19",
      "Open": "10908.65",
      "High": "10956.7",
      "Low": "10886.7",
      "Close": "10934.35"
  },
  {
      "Date": "2/6/19",
      "Open": "10965.1",
      "High": "11072.6",
      "Low": "10962.7",
      "Close": "11062.45"
  },
  {
      "Date": "2/7/19",
      "Open": "11070.45",
      "High": "11118.1",
      "Low": "11043.6",
      "Close": "11069.4"
  },
  {
      "Date": "2/8/19",
      "Open": "11023.5",
      "High": "11041.2",
      "Low": "10925.45",
      "Close": "10943.6"
  },
  {
      "Date": "2/11/19",
      "Open": "10930.9",
      "High": "10930.9",
      "Low": "10857.1",
      "Close": "10888.8"
  },
  {
      "Date": "2/12/19",
      "Open": "10879.7",
      "High": "10910.9",
      "Low": "10823.8",
      "Close": "10831.4"
  },
  {
      "Date": "2/13/19",
      "Open": "10870.55",
      "High": "10891.65",
      "Low": "10772.1",
      "Close": "10793.65"
  },
  {
      "Date": "2/14/19",
      "Open": "10786.1",
      "High": "10792.7",
      "Low": "10718.75",
      "Close": "10746.05"
  },
  {
      "Date": "2/15/19",
      "Open": "10780.25",
      "High": "10785.75",
      "Low": "10620.4",
      "Close": "10724.4"
  },
  {
      "Date": "2/18/19",
      "Open": "10738.65",
      "High": "10759.9",
      "Low": "10628.4",
      "Close": "10640.95"
  },
  {
      "Date": "2/19/19",
      "Open": "10636.7",
      "High": "10722.85",
      "Low": "10585.65",
      "Close": "10604.35"
  },
  {
      "Date": "2/20/19",
      "Open": "10655.45",
      "High": "10752.7",
      "Low": "10646.4",
      "Close": "10735.45"
  },
  {
      "Date": "2/21/19",
      "Open": "10744.1",
      "High": "10808.85",
      "Low": "10721.5",
      "Close": "10789.85"
  },
  {
      "Date": "2/22/19",
      "Open": "10782.7",
      "High": "10801.55",
      "Low": "10758.4",
      "Close": "10791.65"
  },
  {
      "Date": "2/25/19",
      "Open": "10813.25",
      "High": "10887.1",
      "Low": "10788.05",
      "Close": "10880.1"
  },
  {
      "Date": "2/26/19",
      "Open": "10775.3",
      "High": "10888.75",
      "Low": "10729.3",
      "Close": "10835.3"
  },
  {
      "Date": "2/27/19",
      "Open": "10881.2",
      "High": "10939.7",
      "Low": "10751.2",
      "Close": "10806.65"
  },
  {
      "Date": "2/28/19",
      "Open": "10865.7",
      "High": "10865.7",
      "Low": "10784.85",
      "Close": "10792.5"
  },
  {
      "Date": "3/1/19",
      "Open": "10842.65",
      "High": "10877.9",
      "Low": "10823.1",
      "Close": "10863.5"
  },
  {
      "Date": "3/5/19",
      "Open": "10864.85",
      "High": "10994.9",
      "Low": "10817",
      "Close": "10987.45"
  },
  {
      "Date": "3/6/19",
      "Open": "11024.85",
      "High": "11062.3",
      "Low": "10998.85",
      "Close": "11053"
  },
  {
      "Date": "3/7/19",
      "Open": "11077.95",
      "High": "11089.05",
      "Low": "11027.1",
      "Close": "11058.2"
  },
  {
      "Date": "3/8/19",
      "Open": "11038.85",
      "High": "11049",
      "Low": "11008.95",
      "Close": "11035.4"
  },
  {
      "Date": "3/11/19",
      "Open": "11068.75",
      "High": "11180.9",
      "Low": "11059.85",
      "Close": "11168.05"
  },
  {
      "Date": "3/12/19",
      "Open": "11231.35",
      "High": "11320.4",
      "Low": "11227",
      "Close": "11301.2"
  },
  {
      "Date": "3/13/19",
      "Open": "11326.2",
      "High": "11352.3",
      "Low": "11276.6",
      "Close": "11341.7"
  },
  {
      "Date": "3/14/19",
      "Open": "11382.5",
      "High": "11383.45",
      "Low": "11313.75",
      "Close": "11343.25"
  },
  {
      "Date": "3/15/19",
      "Open": "11376.85",
      "High": "11487",
      "Low": "11370.8",
      "Close": "11426.85"
  },
  {
      "Date": "3/18/19",
      "Open": "11473.85",
      "High": "11530.15",
      "Low": "11412.5",
      "Close": "11462.2"
  },
  {
      "Date": "3/19/19",
      "Open": "11500.3",
      "High": "11543.85",
      "Low": "11451.25",
      "Close": "11532.4"
  },
  {
      "Date": "3/20/19",
      "Open": "11553.35",
      "High": "11556.1",
      "Low": "11503.1",
      "Close": "11521.05"
  },
  {
      "Date": "3/22/19",
      "Open": "11549.2",
      "High": "11572.8",
      "Low": "11434.55",
      "Close": "11456.9"
  },
  {
      "Date": "3/25/19",
      "Open": "11395.65",
      "High": "11395.65",
      "Low": "11311.6",
      "Close": "11354.25"
  },
  {
      "Date": "3/26/19",
      "Open": "11375.2",
      "High": "11496.75",
      "Low": "11352.45",
      "Close": "11483.25"
  },
  {
      "Date": "3/27/19",
      "Open": "11531.45",
      "High": "11546.2",
      "Low": "11413",
      "Close": "11445.05"
  },
  {
      "Date": "3/28/19",
      "Open": "11463.65",
      "High": "11588.5",
      "Low": "11452.45",
      "Close": "11570"
  },
  {
      "Date": "3/29/19",
      "Open": "11625.45",
      "High": "11630.35",
      "Low": "11570.15",
      "Close": "11623.9"
  },
  {
      "Date": "4/1/19",
      "Open": "11665.2",
      "High": "11738.1",
      "Low": "11644.75",
      "Close": "11669.15"
  },
  {
      "Date": "4/2/19",
      "Open": "11711.55",
      "High": "11729.35",
      "Low": "11655.85",
      "Close": "11713.2"
  },
  {
      "Date": "4/3/19",
      "Open": "11735.3",
      "High": "11761",
      "Low": "11629.15",
      "Close": "11643.95"
  },
  {
      "Date": "4/4/19",
      "Open": "11660.2",
      "High": "11662.55",
      "Low": "11559.2",
      "Close": "11598"
  },
  {
      "Date": "4/5/19",
      "Open": "11638.4",
      "High": "11689.65",
      "Low": "11609.5",
      "Close": "11665.95"
  },
  {
      "Date": "4/8/19",
      "Open": "11704.35",
      "High": "11710.3",
      "Low": "11549.1",
      "Close": "11604.5"
  },
  {
      "Date": "4/9/19",
      "Open": "11612.05",
      "High": "11683.9",
      "Low": "11569.7",
      "Close": "11671.95"
  },
  {
      "Date": "4/10/19",
      "Open": "11646.85",
      "High": "11680.05",
      "Low": "11571.75",
      "Close": "11584.3"
  },
  {
      "Date": "4/11/19",
      "Open": "11592.55",
      "High": "11606.7",
      "Low": "11550.55",
      "Close": "11596.7"
  },
  {
      "Date": "4/12/19",
      "Open": "11612.85",
      "High": "11657.35",
      "Low": "11578.8",
      "Close": "11643.45"
  },
  {
      "Date": "4/15/19",
      "Open": "11667",
      "High": "11704.6",
      "Low": "11648.25",
      "Close": "11690.35"
  },
  {
      "Date": "4/16/19",
      "Open": "11736.2",
      "High": "11810.95",
      "Low": "11731.55",
      "Close": "11787.15"
  },
  {
      "Date": "4/18/19",
      "Open": "11856.15",
      "High": "11856.15",
      "Low": "11738.5",
      "Close": "11752.8"
  },
  {
      "Date": "4/22/19",
      "Open": "11727.05",
      "High": "11727.05",
      "Low": "11583.95",
      "Close": "11594.45"
  },
  {
      "Date": "4/23/19",
      "Open": "11612.95",
      "High": "11645.95",
      "Low": "11564.8",
      "Close": "11575.95"
  },
  {
      "Date": "4/24/19",
      "Open": "11601.5",
      "High": "11740.85",
      "Low": "11578.85",
      "Close": "11726.15"
  },
  {
      "Date": "4/25/19",
      "Open": "11735.7",
      "High": "11796.75",
      "Low": "11624.3",
      "Close": "11641.8"
  },
  {
      "Date": "4/26/19",
      "Open": "11683.75",
      "High": "11762.9",
      "Low": "11661.75",
      "Close": "11754.65"
  },
  {
      "Date": "4/30/19",
      "Open": "11748.75",
      "High": "11756.25",
      "Low": "11655.9",
      "Close": "11748.15"
  },
  {
      "Date": "5/2/19",
      "Open": "11725.55",
      "High": "11789.3",
      "Low": "11699.55",
      "Close": "11724.75"
  },
  {
      "Date": "5/3/19",
      "Open": "11722.6",
      "High": "11770.9",
      "Low": "11699.35",
      "Close": "11712.25"
  },
  {
      "Date": "5/6/19",
      "Open": "11605.8",
      "High": "11632.55",
      "Low": "11571.35",
      "Close": "11598.25"
  },
  {
      "Date": "5/7/19",
      "Open": "11651.5",
      "High": "11657.05",
      "Low": "11484.45",
      "Close": "11497.9"
  },
  {
      "Date": "5/8/19",
      "Open": "11478.7",
      "High": "11479.1",
      "Low": "11346.95",
      "Close": "11359.45"
  },
  {
      "Date": "5/9/19",
      "Open": "11322.4",
      "High": "11357.6",
      "Low": "11255.05",
      "Close": "11301.8"
  },
  {
      "Date": "5/10/19",
      "Open": "11314.15",
      "High": "11345.8",
      "Low": "11251.05",
      "Close": "11278.9"
  },
  {
      "Date": "5/13/19",
      "Open": "11258.7",
      "High": "11300.2",
      "Low": "11125.6",
      "Close": "11148.2"
  },
  {
      "Date": "5/14/19",
      "Open": "11151.65",
      "High": "11294.75",
      "Low": "11108.3",
      "Close": "11222.05"
  },
  {
      "Date": "5/15/19",
      "Open": "11271.7",
      "High": "11286.8",
      "Low": "11136.95",
      "Close": "11157"
  },
  {
      "Date": "5/16/19",
      "Open": "11180.35",
      "High": "11281.55",
      "Low": "11143.35",
      "Close": "11257.1"
  },
  {
      "Date": "5/17/19",
      "Open": "11261.9",
      "High": "11426.15",
      "Low": "11259.85",
      "Close": "11407.15"
  },
  {
      "Date": "5/20/19",
      "Open": "11651.9",
      "High": "11845.2",
      "Low": "11591.7",
      "Close": "11828.25"
  },
  {
      "Date": "5/21/19",
      "Open": "11863.65",
      "High": "11883.55",
      "Low": "11682.8",
      "Close": "11709.1"
  },
  {
      "Date": "5/22/19",
      "Open": "11727.95",
      "High": "11784.8",
      "Low": "11682.4",
      "Close": "11737.9"
  },
  {
      "Date": "5/23/19",
      "Open": "11901.3",
      "High": "12041.15",
      "Low": "11614.5",
      "Close": "11657.05"
  },
  {
      "Date": "5/24/19",
      "Open": "11748",
      "High": "11859",
      "Low": "11658.1",
      "Close": "11844.1"
  },
  {
      "Date": "5/27/19",
      "Open": "11855.5",
      "High": "11957.15",
      "Low": "11812.4",
      "Close": "11924.75"
  },
  {
      "Date": "5/28/19",
      "Open": "11958.35",
      "High": "11958.55",
      "Low": "11864.9",
      "Close": "11928.75"
  },
  {
      "Date": "5/29/19",
      "Open": "11905.8",
      "High": "11931.9",
      "Low": "11836.8",
      "Close": "11861.1"
  },
  {
      "Date": "5/30/19",
      "Open": "11865.3",
      "High": "11968.55",
      "Low": "11859.4",
      "Close": "11945.9"
  },
  {
      "Date": "5/31/19",
      "Open": "11999.8",
      "High": "12039.25",
      "Low": "11829.45",
      "Close": "11922.8"
  },
  {
      "Date": "6/3/19",
      "Open": "11953.75",
      "High": "12103.05",
      "Low": "11920.1",
      "Close": "12088.55"
  },
  {
      "Date": "6/4/19",
      "Open": "12052.65",
      "High": "12095.2",
      "Low": "12005.85",
      "Close": "12021.65"
  },
  {
      "Date": "6/6/19",
      "Open": "12039.8",
      "High": "12039.8",
      "Low": "11830.25",
      "Close": "11843.75"
  },
  {
      "Date": "6/7/19",
      "Open": "11865.2",
      "High": "11897.5",
      "Low": "11769.5",
      "Close": "11870.65"
  },
  {
      "Date": "6/10/19",
      "Open": "11934.9",
      "High": "11975.05",
      "Low": "11871.75",
      "Close": "11922.7"
  },
  {
      "Date": "6/11/19",
      "Open": "11959.85",
      "High": "12000.35",
      "Low": "11904.35",
      "Close": "11965.6"
  },
  {
      "Date": "6/12/19",
      "Open": "11962.45",
      "High": "11962.45",
      "Low": "11866.35",
      "Close": "11906.2"
  },
  {
      "Date": "6/13/19",
      "Open": "11873.9",
      "High": "11931.35",
      "Low": "11817.05",
      "Close": "11914.05"
  },
  {
      "Date": "6/14/19",
      "Open": "11910.1",
      "High": "11911.85",
      "Low": "11797.7",
      "Close": "11823.3"
  },
  {
      "Date": "6/17/19",
      "Open": "11844",
      "High": "11844.05",
      "Low": "11657.75",
      "Close": "11672.15"
  },
  {
      "Date": "6/18/19",
      "Open": "11677.05",
      "High": "11727.2",
      "Low": "11641.15",
      "Close": "11691.5"
  },
  {
      "Date": "6/19/19",
      "Open": "11744.45",
      "High": "11802.5",
      "Low": "11625.1",
      "Close": "11691.45"
  },
  {
      "Date": "6/20/19",
      "Open": "11653.65",
      "High": "11843.5",
      "Low": "11635.05",
      "Close": "11831.75"
  },
  {
      "Date": "6/21/19",
      "Open": "11827.6",
      "High": "11827.95",
      "Low": "11705.1",
      "Close": "11724.1"
  },
  {
      "Date": "6/24/19",
      "Open": "11725.8",
      "High": "11754",
      "Low": "11670.2",
      "Close": "11699.65"
  },
  {
      "Date": "6/25/19",
      "Open": "11681",
      "High": "11814.4",
      "Low": "11651",
      "Close": "11796.45"
  },
  {
      "Date": "6/26/19",
      "Open": "11768.15",
      "High": "11871.85",
      "Low": "11757.55",
      "Close": "11847.55"
  },
  {
      "Date": "6/27/19",
      "Open": "11860.85",
      "High": "11911.15",
      "Low": "11821.05",
      "Close": "11841.55"
  },
  {
      "Date": "6/28/19",
      "Open": "11861.15",
      "High": "11871.7",
      "Low": "11775.5",
      "Close": "11788.85"
  },
  {
      "Date": "7/1/19",
      "Open": "11839.9",
      "High": "11884.65",
      "Low": "11830.8",
      "Close": "11865.6"
  },
  {
      "Date": "7/2/19",
      "Open": "11890.3",
      "High": "11917.45",
      "Low": "11814.7",
      "Close": "11910.3"
  },
  {
      "Date": "7/3/19",
      "Open": "11932.15",
      "High": "11945.2",
      "Low": "11887.05",
      "Close": "11916.75"
  },
  {
      "Date": "7/4/19",
      "Open": "11928.8",
      "High": "11969.25",
      "Low": "11923.65",
      "Close": "11946.75"
  },
  {
      "Date": "7/5/19",
      "Open": "11964.75",
      "High": "11981.75",
      "Low": "11797.9",
      "Close": "11811.15"
  },
  {
      "Date": "7/8/19",
      "Open": "11770.4",
      "High": "11771.9",
      "Low": "11523.3",
      "Close": "11558.6"
  },
  {
      "Date": "7/9/19",
      "Open": "11531.6",
      "High": "11582.55",
      "Low": "11461",
      "Close": "11555.9"
  },
  {
      "Date": "7/10/19",
      "Open": "11536.15",
      "High": "11593.7",
      "Low": "11475.65",
      "Close": "11498.9"
  },
  {
      "Date": "7/11/19",
      "Open": "11561.45",
      "High": "11599",
      "Low": "11519.5",
      "Close": "11582.9"
  },
  {
      "Date": "7/12/19",
      "Open": "11601.15",
      "High": "11639.55",
      "Low": "11538.6",
      "Close": "11552.5"
  },
  {
      "Date": "7/15/19",
      "Open": "11614.75",
      "High": "11618.4",
      "Low": "11532.3",
      "Close": "11588.35"
  },
  {
      "Date": "7/16/19",
      "Open": "11596.65",
      "High": "11670.05",
      "Low": "11573.95",
      "Close": "11662.6"
  },
  {
      "Date": "7/17/19",
      "Open": "11670.75",
      "High": "11706.65",
      "Low": "11651.15",
      "Close": "11687.5"
  },
  {
      "Date": "7/18/19",
      "Open": "11675.6",
      "High": "11677.15",
      "Low": "11582.4",
      "Close": "11596.9"
  },
  {
      "Date": "7/19/19",
      "Open": "11627.95",
      "High": "11640.35",
      "Low": "11399.3",
      "Close": "11419.25"
  },
  {
      "Date": "7/22/19",
      "Open": "11392.85",
      "High": "11398.15",
      "Low": "11301.25",
      "Close": "11346.2"
  },
  {
      "Date": "7/23/19",
      "Open": "11372.25",
      "High": "11398.15",
      "Low": "11302.8",
      "Close": "11331.05"
  },
  {
      "Date": "7/24/19",
      "Open": "11322.45",
      "High": "11359.75",
      "Low": "11229.8",
      "Close": "11271.3"
  },
  {
      "Date": "7/25/19",
      "Open": "11290.4",
      "High": "11361.4",
      "Low": "11239.35",
      "Close": "11252.15"
  },
  {
      "Date": "7/26/19",
      "Open": "11247.45",
      "High": "11307.6",
      "Low": "11210.05",
      "Close": "11284.3"
  },
  {
      "Date": "7/29/19",
      "Open": "11307.5",
      "High": "11310.95",
      "Low": "11152.4",
      "Close": "11189.2"
  },
  {
      "Date": "7/30/19",
      "Open": "11213.7",
      "High": "11267.45",
      "Low": "11072.65",
      "Close": "11085.4"
  },
  {
      "Date": "7/31/19",
      "Open": "11034.05",
      "High": "11145.3",
      "Low": "10999.4",
      "Close": "11118"
  },
  {
      "Date": "8/1/19",
      "Open": "11060.2",
      "High": "11076.75",
      "Low": "10881",
      "Close": "10980"
  },
  {
      "Date": "8/2/19",
      "Open": "10930.3",
      "High": "11080.15",
      "Low": "10848.95",
      "Close": "10997.35"
  },
  {
      "Date": "8/5/19",
      "Open": "10895.8",
      "High": "10895.8",
      "Low": "10782.6",
      "Close": "10862.6"
  },
  {
      "Date": "8/6/19",
      "Open": "10815.4",
      "High": "11018.55",
      "Low": "10813.8",
      "Close": "10948.25"
  },
  {
      "Date": "8/7/19",
      "Open": "10958.1",
      "High": "10975.65",
      "Low": "10835.9",
      "Close": "10855.5"
  },
  {
      "Date": "8/8/19",
      "Open": "10899.2",
      "High": "11058.05",
      "Low": "10842.95",
      "Close": "11032.45"
  },
  {
      "Date": "8/9/19",
      "Open": "11087.9",
      "High": "11181.45",
      "Low": "11062.8",
      "Close": "11109.65"
  },
  {
      "Date": "8/13/19",
      "Open": "11139.4",
      "High": "11145.9",
      "Low": "10901.6",
      "Close": "10925.85"
  },
  {
      "Date": "8/14/19",
      "Open": "11003.25",
      "High": "11078.15",
      "Low": "10935.6",
      "Close": "11029.4"
  },
  {
      "Date": "8/16/19",
      "Open": "11043.65",
      "High": "11068.65",
      "Low": "10924.3",
      "Close": "11047.8"
  },
  {
      "Date": "8/19/19",
      "Open": "11094.8",
      "High": "11146.9",
      "Low": "11037.85",
      "Close": "11053.9"
  },
  {
      "Date": "8/20/19",
      "Open": "11063.9",
      "High": "11076.3",
      "Low": "10985.3",
      "Close": "11017"
  },
  {
      "Date": "8/21/19",
      "Open": "11018.15",
      "High": "11034.2",
      "Low": "10906.65",
      "Close": "10918.7"
  },
  {
      "Date": "8/22/19",
      "Open": "10905.3",
      "High": "10908.25",
      "Low": "10718.3",
      "Close": "10741.35"
  },
  {
      "Date": "8/23/19",
      "Open": "10699.6",
      "High": "10862.55",
      "Low": "10637.15",
      "Close": "10829.35"
  },
  {
      "Date": "8/26/19",
      "Open": "11000.3",
      "High": "11070.3",
      "Low": "10756.55",
      "Close": "11057.85"
  },
  {
      "Date": "8/27/19",
      "Open": "11106.55",
      "High": "11141.75",
      "Low": "11049.5",
      "Close": "11105.35"
  },
  {
      "Date": "8/28/19",
      "Open": "11101.3",
      "High": "11129.65",
      "Low": "10987.65",
      "Close": "11046.1"
  },
  {
      "Date": "8/29/19",
      "Open": "10996.05",
      "High": "11021.1",
      "Low": "10922.4",
      "Close": "10948.3"
  },
  {
      "Date": "8/30/19",
      "Open": "10987.8",
      "High": "11042.6",
      "Low": "10874.8",
      "Close": "11023.25"
  },
  {
      "Date": "9/3/19",
      "Open": "10960.95",
      "High": "10967.5",
      "Low": "10772.7",
      "Close": "10797.9"
  },
  {
      "Date": "9/4/19",
      "Open": "10790.4",
      "High": "10858.75",
      "Low": "10746.35",
      "Close": "10844.65"
  },
  {
      "Date": "9/5/19",
      "Open": "10860.95",
      "High": "10920.1",
      "Low": "10816",
      "Close": "10847.9"
  },
  {
      "Date": "9/6/19",
      "Open": "10883.8",
      "High": "10957.05",
      "Low": "10867.45",
      "Close": "10946.2"
  },
  {
      "Date": "9/9/19",
      "Open": "10936.7",
      "High": "11028.85",
      "Low": "10889.8",
      "Close": "11003.05"
  },
  {
      "Date": "9/11/19",
      "Open": "11028.5",
      "High": "11054.8",
      "Low": "11011.65",
      "Close": "11035.7"
  },
  {
      "Date": "9/12/19",
      "Open": "11058.3",
      "High": "11081.75",
      "Low": "10964.95",
      "Close": "10982.8"
  },
  {
      "Date": "9/13/19",
      "Open": "10986.8",
      "High": "11084.45",
      "Low": "10945.75",
      "Close": "11075.9"
  },
  {
      "Date": "9/16/19",
      "Open": "10994.85",
      "High": "11052.7",
      "Low": "10968.2",
      "Close": "11003.5"
  },
  {
      "Date": "9/17/19",
      "Open": "11000.1",
      "High": "11000.1",
      "Low": "10796.5",
      "Close": "10817.6"
  },
  {
      "Date": "9/18/19",
      "Open": "10872.8",
      "High": "10885.15",
      "Low": "10804.85",
      "Close": "10840.65"
  },
  {
      "Date": "9/19/19",
      "Open": "10845.2",
      "High": "10845.2",
      "Low": "10670.25",
      "Close": "10704.8"
  },
  {
      "Date": "9/20/19",
      "Open": "10746.8",
      "High": "11381.9",
      "Low": "10691",
      "Close": "11274.2"
  },
  {
      "Date": "9/23/19",
      "Open": "11542.7",
      "High": "11694.85",
      "Low": "11471.35",
      "Close": "11600.2"
  },
  {
      "Date": "9/24/19",
      "Open": "11590.7",
      "High": "11655.05",
      "Low": "11539.2",
      "Close": "11588.2"
  },
  {
      "Date": "9/25/19",
      "Open": "11564.85",
      "High": "11564.95",
      "Low": "11416.1",
      "Close": "11440.2"
  },
  {
      "Date": "9/26/19",
      "Open": "11469.85",
      "High": "11610.85",
      "Low": "11466.35",
      "Close": "11571.2"
  },
  {
      "Date": "9/27/19",
      "Open": "11556.35",
      "High": "11593.6",
      "Low": "11499.75",
      "Close": "11512.4"
  },
  {
      "Date": "9/30/19",
      "Open": "11491.15",
      "High": "11508.25",
      "Low": "11390.8",
      "Close": "11474.45"
  },
  {
      "Date": "10/1/19",
      "Open": "11515.4",
      "High": "11554.2",
      "Low": "11247.9",
      "Close": "11359.9"
  },
  {
      "Date": "10/3/19",
      "Open": "11322.25",
      "High": "11370.4",
      "Low": "11257.35",
      "Close": "11314"
  },
  {
      "Date": "10/4/19",
      "Open": "11388.45",
      "High": "11400.3",
      "Low": "11158.35",
      "Close": "11174.75"
  },
  {
      "Date": "10/7/19",
      "Open": "11196.2",
      "High": "11233.85",
      "Low": "11112.65",
      "Close": "11126.4"
  },
  {
      "Date": "10/9/19",
      "Open": "11152.95",
      "High": "11321.6",
      "Low": "11090.15",
      "Close": "11313.3"
  },
  {
      "Date": "10/10/19",
      "Open": "11280.5",
      "High": "11293.35",
      "Low": "11208.55",
      "Close": "11234.55"
  },
  {
      "Date": "10/11/19",
      "Open": "11257.7",
      "High": "11362.9",
      "Low": "11189.4",
      "Close": "11305.05"
  },
  {
      "Date": "10/14/19",
      "Open": "11335.9",
      "High": "11420.45",
      "Low": "11290.05",
      "Close": "11341.15"
  },
  {
      "Date": "10/15/19",
      "Open": "11360.85",
      "High": "11462.35",
      "Low": "11342.1",
      "Close": "11428.3"
  },
  {
      "Date": "10/16/19",
      "Open": "11464.95",
      "High": "11481.05",
      "Low": "11411.1",
      "Close": "11464"
  },
  {
      "Date": "10/17/19",
      "Open": "11466.3",
      "High": "11599.1",
      "Low": "11439.65",
      "Close": "11586.35"
  },
  {
      "Date": "10/18/19",
      "Open": "11580.3",
      "High": "11684.7",
      "Low": "11553.15",
      "Close": "11661.85"
  },
  {
      "Date": "10/22/19",
      "Open": "11657.15",
      "High": "11714.35",
      "Low": "11573.65",
      "Close": "11588.35"
  },
  {
      "Date": "10/23/19",
      "Open": "11596.2",
      "High": "11651.6",
      "Low": "11554.4",
      "Close": "11604.1"
  },
  {
      "Date": "10/24/19",
      "Open": "11661.65",
      "High": "11679.6",
      "Low": "11534.65",
      "Close": "11582.6"
  },
  {
      "Date": "10/25/19",
      "Open": "11646.15",
      "High": "11646.9",
      "Low": "11490.75",
      "Close": "11583.9"
  },
  {
      "Date": "10/27/19",
      "Open": "11662.25",
      "High": "11672.4",
      "Low": "11604.6",
      "Close": "11627.15"
  },
  {
      "Date": "10/29/19",
      "Open": "11643.95",
      "High": "11809.4",
      "Low": "11627.35",
      "Close": "11786.85"
  },
  {
      "Date": "10/30/19",
      "Open": "11883.9",
      "High": "11883.95",
      "Low": "11784.45",
      "Close": "11844.1"
  },
  {
      "Date": "10/31/19",
      "Open": "11890.45",
      "High": "11945",
      "Low": "11855.1",
      "Close": "11877.45"
  },
  {
      "Date": "11/1/19",
      "Open": "11886.6",
      "High": "11918.3",
      "Low": "11843.35",
      "Close": "11890.6"
  },
  {
      "Date": "11/4/19",
      "Open": "11928.9",
      "High": "11989.15",
      "Low": "11905.35",
      "Close": "11941.3"
  },
  {
      "Date": "11/5/19",
      "Open": "11974.6",
      "High": "11978.95",
      "Low": "11861.9",
      "Close": "11917.2"
  },
  {
      "Date": "11/6/19",
      "Open": "11911.5",
      "High": "12002.9",
      "Low": "11850.25",
      "Close": "11966.05"
  },
  {
      "Date": "11/7/19",
      "Open": "12021.1",
      "High": "12021.4",
      "Low": "11946.85",
      "Close": "12012.05"
  },
  {
      "Date": "11/8/19",
      "Open": "11987.15",
      "High": "12034.15",
      "Low": "11888.75",
      "Close": "11908.15"
  },
  {
      "Date": "11/11/19",
      "Open": "11879.2",
      "High": "11932.65",
      "Low": "11853.95",
      "Close": "11913.45"
  },
  {
      "Date": "11/13/19",
      "Open": "11908.3",
      "High": "11946.8",
      "Low": "11823.2",
      "Close": "11840.45"
  },
  {
      "Date": "11/14/19",
      "Open": "11858.75",
      "High": "11895.65",
      "Low": "11802.65",
      "Close": "11872.1"
  },
  {
      "Date": "11/15/19",
      "Open": "11904.2",
      "High": "11973.65",
      "Low": "11879.25",
      "Close": "11895.45"
  },
  {
      "Date": "11/18/19",
      "Open": "11915.15",
      "High": "11946.2",
      "Low": "11867.6",
      "Close": "11884.5"
  },
  {
      "Date": "11/19/19",
      "Open": "11919.45",
      "High": "11958.85",
      "Low": "11881.75",
      "Close": "11940.1"
  },
  {
      "Date": "11/20/19",
      "Open": "12004.75",
      "High": "12038.6",
      "Low": "11966.05",
      "Close": "11999.1"
  },
  {
      "Date": "11/21/19",
      "Open": "12025.65",
      "High": "12028.2",
      "Low": "11956.9",
      "Close": "11968.4"
  },
  {
      "Date": "11/22/19",
      "Open": "11967.3",
      "High": "11968.1",
      "Low": "11883.5",
      "Close": "11914.4"
  },
  {
      "Date": "11/25/19",
      "Open": "11922.45",
      "High": "12084.5",
      "Low": "11919.75",
      "Close": "12073.75"
  },
  {
      "Date": "11/26/19",
      "Open": "12110.2",
      "High": "12132.45",
      "Low": "12006.35",
      "Close": "12037.7"
  },
  {
      "Date": "11/27/19",
      "Open": "12068.5",
      "High": "12114.9",
      "Low": "12055.15",
      "Close": "12100.7"
  },
  {
      "Date": "11/28/19",
      "Open": "12132.1",
      "High": "12158.8",
      "Low": "12099.95",
      "Close": "12151.15"
  },
  {
      "Date": "11/29/19",
      "Open": "12146.2",
      "High": "12147.4",
      "Low": "12017.4",
      "Close": "12056.05"
  },
  {
      "Date": "12/2/19",
      "Open": "12137.05",
      "High": "12137.15",
      "Low": "12023.7",
      "Close": "12048.2"
  },
  {
      "Date": "12/3/19",
      "Open": "12067.65",
      "High": "12068.6",
      "Low": "11956.4",
      "Close": "11994.2"
  },
  {
      "Date": "12/4/19",
      "Open": "11969.95",
      "High": "12054.7",
      "Low": "11935.3",
      "Close": "12043.2"
  },
  {
      "Date": "12/5/19",
      "Open": "12071.25",
      "High": "12081.2",
      "Low": "11998.75",
      "Close": "12018.4"
  },
  {
      "Date": "12/6/19",
      "Open": "12047.35",
      "High": "12057.05",
      "Low": "11888.85",
      "Close": "11921.5"
  },
  {
      "Date": "12/9/19",
      "Open": "11939.1",
      "High": "11981.95",
      "Low": "11888.05",
      "Close": "11937.5"
  },
  {
      "Date": "12/10/19",
      "Open": "11950.5",
      "High": "11953.2",
      "Low": "11844.7",
      "Close": "11856.8"
  },
  {
      "Date": "12/11/19",
      "Open": "11867.35",
      "High": "11923.2",
      "Low": "11832.3",
      "Close": "11910.15"
  },
  {
      "Date": "12/12/19",
      "Open": "11944.3",
      "High": "12005.5",
      "Low": "11934",
      "Close": "11971.8"
  },
  {
      "Date": "12/13/19",
      "Open": "12026.4",
      "High": "12098.85",
      "Low": "12023.6",
      "Close": "12086.7"
  },
  {
      "Date": "12/16/19",
      "Open": "12131.35",
      "High": "12134.65",
      "Low": "12046.3",
      "Close": "12053.95"
  },
  {
      "Date": "12/17/19",
      "Open": "12082.45",
      "High": "12182.75",
      "Low": "12070.35",
      "Close": "12165"
  },
  {
      "Date": "12/18/19",
      "Open": "12197",
      "High": "12237.7",
      "Low": "12163.45",
      "Close": "12221.65"
  },
  {
      "Date": "12/19/19",
      "Open": "12223.4",
      "High": "12268.35",
      "Low": "12191.15",
      "Close": "12259.7"
  },
  {
      "Date": "12/20/19",
      "Open": "12266.45",
      "High": "12293.9",
      "Low": "12252.75",
      "Close": "12271.8"
  },
  {
      "Date": "12/23/19",
      "Open": "12235.45",
      "High": "12287.15",
      "Low": "12213.25",
      "Close": "12262.75"
  },
  {
      "Date": "12/24/19",
      "Open": "12269.25",
      "High": "12283.7",
      "Low": "12202.1",
      "Close": "12214.55"
  },
  {
      "Date": "12/26/19",
      "Open": "12211.85",
      "High": "12221.55",
      "Low": "12118.85",
      "Close": "12126.55"
  },
  {
      "Date": "12/27/19",
      "Open": "12172.9",
      "High": "12258.45",
      "Low": "12157.9",
      "Close": "12245.8"
  },
  {
      "Date": "12/30/19",
      "Open": "12274.9",
      "High": "12286.45",
      "Low": "12213.8",
      "Close": "12255.85"
  },
  {
      "Date": "12/31/19",
      "Open": "12247.1",
      "High": "12247.1",
      "Low": "12151.8",
      "Close": "12168.45"
  },
  {
      "Date": "1/1/20",
      "Open": "12202.15",
      "High": "12222.2",
      "Low": "12165.3",
      "Close": "12182.5"
  },
  {
      "Date": "1/2/20",
      "Open": "12198.55",
      "High": "12289.9",
      "Low": "12195.25",
      "Close": "12282.2"
  },
  {
      "Date": "1/3/20",
      "Open": "12261.1",
      "High": "12265.6",
      "Low": "12191.35",
      "Close": "12226.65"
  },
  {
      "Date": "1/6/20",
      "Open": "12170.6",
      "High": "12179.1",
      "Low": "11974.2",
      "Close": "11993.05"
  },
  {
      "Date": "1/7/20",
      "Open": "12079.1",
      "High": "12152.15",
      "Low": "12005.35",
      "Close": "12052.95"
  },
  {
      "Date": "1/8/20",
      "Open": "11939.1",
      "High": "12044.95",
      "Low": "11929.6",
      "Close": "12025.35"
  },
  {
      "Date": "1/9/20",
      "Open": "12153.15",
      "High": "12224.05",
      "Low": "12132.55",
      "Close": "12215.9"
  },
  {
      "Date": "1/10/20",
      "Open": "12271",
      "High": "12311.2",
      "Low": "12213.2",
      "Close": "12256.8"
  },
  {
      "Date": "1/13/20",
      "Open": "12296.7",
      "High": "12337.75",
      "Low": "12285.8",
      "Close": "12329.55"
  },
  {
      "Date": "1/14/20",
      "Open": "12333.1",
      "High": "12374.25",
      "Low": "12308.7",
      "Close": "12362.3"
  },
  {
      "Date": "1/15/20",
      "Open": "12349.4",
      "High": "12355.15",
      "Low": "12278.75",
      "Close": "12343.3"
  },
  {
      "Date": "1/16/20",
      "Open": "12347.1",
      "High": "12389.05",
      "Low": "12315.8",
      "Close": "12355.5"
  },
  {
      "Date": "1/17/20",
      "Open": "12328.4",
      "High": "12385.45",
      "Low": "12321.4",
      "Close": "12352.35"
  },
  {
      "Date": "1/20/20",
      "Open": "12430.5",
      "High": "12430.5",
      "Low": "12216.9",
      "Close": "12224.55"
  },
  {
      "Date": "1/21/20",
      "Open": "12195.3",
      "High": "12230.05",
      "Low": "12162.3",
      "Close": "12169.85"
  },
  {
      "Date": "1/22/20",
      "Open": "12218.35",
      "High": "12225.05",
      "Low": "12087.9",
      "Close": "12106.9"
  },
  {
      "Date": "1/23/20",
      "Open": "12123.75",
      "High": "12189",
      "Low": "12094.1",
      "Close": "12180.35"
  },
  {
      "Date": "1/24/20",
      "Open": "12174.55",
      "High": "12272.15",
      "Low": "12149.65",
      "Close": "12248.25"
  },
  {
      "Date": "1/27/20",
      "Open": "12197.1",
      "High": "12216.6",
      "Low": "12107",
      "Close": "12119"
  },
  {
      "Date": "1/28/20",
      "Open": "12148.1",
      "High": "12163.55",
      "Low": "12024.5",
      "Close": "12055.8"
  },
  {
      "Date": "1/29/20",
      "Open": "12114.9",
      "High": "12169.6",
      "Low": "12103.8",
      "Close": "12129.5"
  },
  {
      "Date": "1/30/20",
      "Open": "12147.75",
      "High": "12150.3",
      "Low": "12010.6",
      "Close": "12035.8"
  },
  {
      "Date": "1/31/20",
      "Open": "12100.4",
      "High": "12103.55",
      "Low": "11945.85",
      "Close": "11962.1"
  },
  {
      "Date": "2/1/20",
      "Open": "11939",
      "High": "12017.35",
      "Low": "11633.3",
      "Close": "11661.85"
  },
  {
      "Date": "2/3/20",
      "Open": "11627.45",
      "High": "11749.85",
      "Low": "11614.5",
      "Close": "11707.9"
  },
  {
      "Date": "2/4/20",
      "Open": "11786.25",
      "High": "11986.15",
      "Low": "11783.4",
      "Close": "11979.65"
  },
  {
      "Date": "2/5/20",
      "Open": "12005.85",
      "High": "12098.15",
      "Low": "11953.35",
      "Close": "12089.15"
  },
  {
      "Date": "2/6/20",
      "Open": "12120",
      "High": "12160.6",
      "Low": "12084.65",
      "Close": "12137.95"
  },
  {
      "Date": "2/7/20",
      "Open": "12151.15",
      "High": "12154.7",
      "Low": "12073.95",
      "Close": "12098.35"
  },
  {
      "Date": "2/10/20",
      "Open": "12102.35",
      "High": "12103.55",
      "Low": "11990.75",
      "Close": "12031.5"
  },
  {
      "Date": "2/11/20",
      "Open": "12108.4",
      "High": "12172.3",
      "Low": "12099",
      "Close": "12107.9"
  },
  {
      "Date": "2/12/20",
      "Open": "12151",
      "High": "12231.75",
      "Low": "12144.3",
      "Close": "12201.2"
  },
  {
      "Date": "2/13/20",
      "Open": "12219.55",
      "High": "12225.65",
      "Low": "12139.8",
      "Close": "12174.65"
  },
  {
      "Date": "2/14/20",
      "Open": "12190.15",
      "High": "12246.7",
      "Low": "12091.2",
      "Close": "12113.45"
  },
  {
      "Date": "2/17/20",
      "Open": "12131.8",
      "High": "12159.6",
      "Low": "12037",
      "Close": "12045.8"
  },
  {
      "Date": "2/18/20",
      "Open": "12028.25",
      "High": "12030.75",
      "Low": "11908.05",
      "Close": "11992.5"
  },
  {
      "Date": "2/19/20",
      "Open": "12090.6",
      "High": "12134.7",
      "Low": "12042.1",
      "Close": "12125.9"
  },
  {
      "Date": "2/20/20",
      "Open": "12119",
      "High": "12152",
      "Low": "12071.45",
      "Close": "12080.85"
  },
  {
      "Date": "2/24/20",
      "Open": "12012.55",
      "High": "12012.55",
      "Low": "11813.4",
      "Close": "11829.4"
  },
  {
      "Date": "2/25/20",
      "Open": "11877.5",
      "High": "11883.05",
      "Low": "11779.9",
      "Close": "11797.9"
  },
  {
      "Date": "2/26/20",
      "Open": "11738.55",
      "High": "11783.25",
      "Low": "11639.6",
      "Close": "11678.5"
  },
  {
      "Date": "2/27/20",
      "Open": "11661.25",
      "High": "11663.85",
      "Low": "11536.7",
      "Close": "11633.3"
  },
  {
      "Date": "2/28/20",
      "Open": "11382",
      "High": "11384.8",
      "Low": "11175.05",
      "Close": "11201.75"
  },
  {
      "Date": "3/2/20",
      "Open": "11387.35",
      "High": "11433",
      "Low": "11036.25",
      "Close": "11132.75"
  },
  {
      "Date": "3/3/20",
      "Open": "11217.55",
      "High": "11342.25",
      "Low": "11152.55",
      "Close": "11303.3"
  },
  {
      "Date": "3/4/20",
      "Open": "11351.35",
      "High": "11356.6",
      "Low": "11082.15",
      "Close": "11251"
  },
  {
      "Date": "3/5/20",
      "Open": "11306.05",
      "High": "11389.5",
      "Low": "11244.6",
      "Close": "11269"
  },
  {
      "Date": "3/6/20",
      "Open": "10942.65",
      "High": "11035.1",
      "Low": "10827.4",
      "Close": "10989.45"
  },
  {
      "Date": "3/9/20",
      "Open": "10742.05",
      "High": "10751.55",
      "Low": "10294.45",
      "Close": "10451.45"
  },
  {
      "Date": "3/11/20",
      "Open": "10334.3",
      "High": "10545.1",
      "Low": "10334",
      "Close": "10458.4"
  },
  {
      "Date": "3/12/20",
      "Open": "10039.95",
      "High": "10040.75",
      "Low": "9508",
      "Close": "9590.15"
  },
  {
      "Date": "3/13/20",
      "Open": "9107.6",
      "High": "10159.4",
      "Low": "8555.15",
      "Close": "9955.2"
  },
  {
      "Date": "3/16/20",
      "Open": "9587.8",
      "High": "9602.2",
      "Low": "9165.1",
      "Close": "9197.4"
  },
  {
      "Date": "3/17/20",
      "Open": "9285.4",
      "High": "9403.8",
      "Low": "8915.6",
      "Close": "8967.05"
  },
  {
      "Date": "3/18/20",
      "Open": "9088.45",
      "High": "9127.55",
      "Low": "8407.05",
      "Close": "8468.8"
  },
  {
      "Date": "3/19/20",
      "Open": "8063.3",
      "High": "8575.45",
      "Low": "7832.55",
      "Close": "8263.45"
  },
  {
      "Date": "3/20/20",
      "Open": "8284.45",
      "High": "8883",
      "Low": "8178.2",
      "Close": "8745.45"
  },
  {
      "Date": "3/23/20",
      "Open": "7945.7",
      "High": "8159.25",
      "Low": "7583.6",
      "Close": "7610.25"
  },
  {
      "Date": "3/24/20",
      "Open": "7848.3",
      "High": "8036.95",
      "Low": "7511.1",
      "Close": "7801.05"
  },
  {
      "Date": "3/25/20",
      "Open": "7735.15",
      "High": "8376.75",
      "Low": "7714.75",
      "Close": "8317.85"
  },
  {
      "Date": "3/26/20",
      "Open": "8451",
      "High": "8749.05",
      "Low": "8304.9",
      "Close": "8641.45"
  },
  {
      "Date": "3/27/20",
      "Open": "8949.1",
      "High": "9038.9",
      "Low": "8522.9",
      "Close": "8660.25"
  },
  {
      "Date": "3/30/20",
      "Open": "8385.95",
      "High": "8576",
      "Low": "8244",
      "Close": "8281.1"
  },
  {
      "Date": "3/31/20",
      "Open": "8529.35",
      "High": "8678.3",
      "Low": "8358",
      "Close": "8597.75"
  },
  {
      "Date": "4/1/20",
      "Open": "8584.1",
      "High": "8588.1",
      "Low": "8198.35",
      "Close": "8253.8"
  },
  {
      "Date": "4/3/20",
      "Open": "8356.55",
      "High": "8356.55",
      "Low": "8055.8",
      "Close": "8083.8"
  },
  {
      "Date": "4/7/20",
      "Open": "8446.3",
      "High": "8819.4",
      "Low": "8360.95",
      "Close": "8792.2"
  },
  {
      "Date": "4/8/20",
      "Open": "8688.9",
      "High": "9131.7",
      "Low": "8653.9",
      "Close": "8748.75"
  },
  {
      "Date": "4/9/20",
      "Open": "8973.05",
      "High": "9128.35",
      "Low": "8904.55",
      "Close": "9111.9"
  },
  {
      "Date": "4/13/20",
      "Open": "9103.95",
      "High": "9112.05",
      "Low": "8912.4",
      "Close": "8993.85"
  },
  {
      "Date": "4/15/20",
      "Open": "9196.4",
      "High": "9261.2",
      "Low": "8874.1",
      "Close": "8925.3"
  },
  {
      "Date": "4/16/20",
      "Open": "8851.25",
      "High": "9053.75",
      "Low": "8821.9",
      "Close": "8992.8"
  },
  {
      "Date": "4/17/20",
      "Open": "9323.45",
      "High": "9324",
      "Low": "9091.35",
      "Close": "9266.75"
  },
  {
      "Date": "4/20/20",
      "Open": "9390.2",
      "High": "9390.85",
      "Low": "9230.8",
      "Close": "9261.85"
  },
  {
      "Date": "4/21/20",
      "Open": "9016.95",
      "High": "9044.4",
      "Low": "8909.4",
      "Close": "8981.45"
  },
  {
      "Date": "4/22/20",
      "Open": "9026.75",
      "High": "9209.75",
      "Low": "8946.25",
      "Close": "9187.3"
  },
  {
      "Date": "4/23/20",
      "Open": "9232.35",
      "High": "9343.6",
      "Low": "9170.15",
      "Close": "9313.9"
  },
  {
      "Date": "4/24/20",
      "Open": "9163.9",
      "High": "9296.9",
      "Low": "9141.3",
      "Close": "9154.4"
  },
  {
      "Date": "4/27/20",
      "Open": "9259.7",
      "High": "9377.1",
      "Low": "9250.35",
      "Close": "9282.3"
  },
  {
      "Date": "4/28/20",
      "Open": "9389.8",
      "High": "9404.4",
      "Low": "9260",
      "Close": "9380.9"
  },
  {
      "Date": "4/29/20",
      "Open": "9408.6",
      "High": "9599.85",
      "Low": "9392.35",
      "Close": "9553.35"
  },
  {
      "Date": "4/30/20",
      "Open": "9753.5",
      "High": "9889.05",
      "Low": "9731.5",
      "Close": "9859.9"
  },
  {
      "Date": "5/4/20",
      "Open": "9533.5",
      "High": "9533.5",
      "Low": "9266.95",
      "Close": "9293.5"
  },
  {
      "Date": "5/5/20",
      "Open": "9429.4",
      "High": "9450.9",
      "Low": "9190.75",
      "Close": "9205.6"
  },
  {
      "Date": "5/6/20",
      "Open": "9226.8",
      "High": "9346.9",
      "Low": "9116.5",
      "Close": "9270.9"
  },
  {
      "Date": "5/7/20",
      "Open": "9234.05",
      "High": "9277.85",
      "Low": "9175.9",
      "Close": "9199.05"
  },
  {
      "Date": "5/8/20",
      "Open": "9376.95",
      "High": "9382.65",
      "Low": "9238.2",
      "Close": "9251.5"
  },
  {
      "Date": "5/11/20",
      "Open": "9348.15",
      "High": "9439.9",
      "Low": "9219.95",
      "Close": "9239.2"
  },
  {
      "Date": "5/12/20",
      "Open": "9168.85",
      "High": "9240.85",
      "Low": "9043.95",
      "Close": "9196.55"
  },
  {
      "Date": "5/13/20",
      "Open": "9584.2",
      "High": "9584.5",
      "Low": "9351.1",
      "Close": "9383.55"
  },
  {
      "Date": "5/14/20",
      "Open": "9213.95",
      "High": "9281.1",
      "Low": "9119.75",
      "Close": "9142.75"
  },
  {
      "Date": "5/15/20",
      "Open": "9182.4",
      "High": "9182.4",
      "Low": "9050",
      "Close": "9136.85"
  },
  {
      "Date": "5/18/20",
      "Open": "9158.3",
      "High": "9158.3",
      "Low": "8806.75",
      "Close": "8823.25"
  },
  {
      "Date": "5/19/20",
      "Open": "8961.7",
      "High": "9030.35",
      "Low": "8855.3",
      "Close": "8879.1"
  },
  {
      "Date": "5/20/20",
      "Open": "8889.15",
      "High": "9093.8",
      "Low": "8875.35",
      "Close": "9066.55"
  },
  {
      "Date": "5/21/20",
      "Open": "9079.45",
      "High": "9178.55",
      "Low": "9056.1",
      "Close": "9106.25"
  },
  {
      "Date": "5/22/20",
      "Open": "9067.9",
      "High": "9149.6",
      "Low": "8968.55",
      "Close": "9039.25"
  },
  {
      "Date": "5/26/20",
      "Open": "9099.75",
      "High": "9161.65",
      "Low": "8996.65",
      "Close": "9029.05"
  },
  {
      "Date": "5/27/20",
      "Open": "9082.2",
      "High": "9334",
      "Low": "9004.25",
      "Close": "9314.95"
  },
  {
      "Date": "5/28/20",
      "Open": "9364.95",
      "High": "9511.25",
      "Low": "9336.5",
      "Close": "9490.1"
  },
  {
      "Date": "5/29/20",
      "Open": "9422.2",
      "High": "9598.85",
      "Low": "9376.9",
      "Close": "9580.3"
  },
  {
      "Date": "6/1/20",
      "Open": "9726.85",
      "High": "9931.6",
      "Low": "9706.95",
      "Close": "9826.15"
  },
  {
      "Date": "6/2/20",
      "Open": "9880.85",
      "High": "9995.6",
      "Low": "9824.05",
      "Close": "9979.1"
  },
  {
      "Date": "6/3/20",
      "Open": "10108.3",
      "High": "10176.2",
      "Low": "10035.55",
      "Close": "10061.55"
  },
  {
      "Date": "6/4/20",
      "Open": "10054.25",
      "High": "10123.85",
      "Low": "9944.25",
      "Close": "10029.1"
  },
  {
      "Date": "6/5/20",
      "Open": "10093.8",
      "High": "10177.8",
      "Low": "10040.75",
      "Close": "10142.15"
  },
  {
      "Date": "6/8/20",
      "Open": "10326.75",
      "High": "10328.5",
      "Low": "10120.25",
      "Close": "10167.45"
  },
  {
      "Date": "6/9/20",
      "Open": "10181.15",
      "High": "10291.15",
      "Low": "10021.45",
      "Close": "10046.65"
  },
  {
      "Date": "6/10/20",
      "Open": "10072.6",
      "High": "10148.75",
      "Low": "10036.85",
      "Close": "10116.15"
  },
  {
      "Date": "6/11/20",
      "Open": "10094.1",
      "High": "10112.05",
      "Low": "9885.05",
      "Close": "9902"
  },
  {
      "Date": "6/12/20",
      "Open": "9544.95",
      "High": "9996.05",
      "Low": "9544.35",
      "Close": "9972.9"
  },
  {
      "Date": "6/15/20",
      "Open": "9919.35",
      "High": "9943.35",
      "Low": "9726.35",
      "Close": "9813.7"
  },
  {
      "Date": "6/16/20",
      "Open": "10014.8",
      "High": "10046.15",
      "Low": "9728.5",
      "Close": "9914"
  },
  {
      "Date": "6/17/20",
      "Open": "9876.7",
      "High": "10003.6",
      "Low": "9833.8",
      "Close": "9881.15"
  },
  {
      "Date": "6/18/20",
      "Open": "9863.25",
      "High": "10111.2",
      "Low": "9845.05",
      "Close": "10091.65"
  },
  {
      "Date": "6/19/20",
      "Open": "10119",
      "High": "10272.4",
      "Low": "10072.65",
      "Close": "10244.4"
  },
  {
      "Date": "6/22/20",
      "Open": "10318.75",
      "High": "10393.65",
      "Low": "10277.6",
      "Close": "10311.2"
  },
  {
      "Date": "6/23/20",
      "Open": "10347.95",
      "High": "10484.7",
      "Low": "10301.75",
      "Close": "10471"
  },
  {
      "Date": "6/24/20",
      "Open": "10529.25",
      "High": "10553.15",
      "Low": "10281.95",
      "Close": "10305.3"
  },
  {
      "Date": "6/25/20",
      "Open": "10235.55",
      "High": "10361.8",
      "Low": "10194.5",
      "Close": "10288.9"
  },
  {
      "Date": "6/26/20",
      "Open": "10378.9",
      "High": "10409.85",
      "Low": "10311.25",
      "Close": "10383"
  },
  {
      "Date": "6/29/20",
      "Open": "10311.95",
      "High": "10337.95",
      "Low": "10223.6",
      "Close": "10312.4"
  },
  {
      "Date": "6/30/20",
      "Open": "10382.6",
      "High": "10401.05",
      "Low": "10267.35",
      "Close": "10302.1"
  },
  {
      "Date": "7/1/20",
      "Open": "10323.8",
      "High": "10447.05",
      "Low": "10299.6",
      "Close": "10430.05"
  },
  {
      "Date": "7/2/20",
      "Open": "10493.05",
      "High": "10598.2",
      "Low": "10485.55",
      "Close": "10551.7"
  },
  {
      "Date": "7/3/20",
      "Open": "10614.95",
      "High": "10631.3",
      "Low": "10562.65",
      "Close": "10607.35"
  },
  {
      "Date": "7/6/20",
      "Open": "10723.85",
      "High": "10811.4",
      "Low": "10695.1",
      "Close": "10763.65"
  },
  {
      "Date": "7/7/20",
      "Open": "10802.85",
      "High": "10813.8",
      "Low": "10689.7",
      "Close": "10799.65"
  },
  {
      "Date": "7/8/20",
      "Open": "10818.65",
      "High": "10847.85",
      "Low": "10676.55",
      "Close": "10705.75"
  },
  {
      "Date": "7/9/20",
      "Open": "10755.55",
      "High": "10836.85",
      "Low": "10733",
      "Close": "10813.45"
  },
  {
      "Date": "7/10/20",
      "Open": "10764.1",
      "High": "10819.4",
      "Low": "10713",
      "Close": "10768.05"
  },
  {
      "Date": "7/13/20",
      "Open": "10851.85",
      "High": "10894.05",
      "Low": "10756.05",
      "Close": "10802.7"
  },
  {
      "Date": "7/14/20",
      "Open": "10750.85",
      "High": "10755.65",
      "Low": "10562.9",
      "Close": "10607.35"
  },
  {
      "Date": "7/15/20",
      "Open": "10701",
      "High": "10827.45",
      "Low": "10577.75",
      "Close": "10618.2"
  },
  {
      "Date": "7/16/20",
      "Open": "10706.2",
      "High": "10755.3",
      "Low": "10595.2",
      "Close": "10739.95"
  },
  {
      "Date": "7/17/20",
      "Open": "10752",
      "High": "10933.45",
      "Low": "10749.65",
      "Close": "10901.7"
  },
  {
      "Date": "7/20/20",
      "Open": "10999.45",
      "High": "11037.9",
      "Low": "10953",
      "Close": "11022.2"
  },
  {
      "Date": "7/21/20",
      "Open": "11126.1",
      "High": "11179.55",
      "Low": "11113.25",
      "Close": "11162.25"
  },
  {
      "Date": "7/22/20",
      "Open": "11231.2",
      "High": "11238.1",
      "Low": "11056.55",
      "Close": "11132.6"
  },
  {
      "Date": "7/23/20",
      "Open": "11135",
      "High": "11239.8",
      "Low": "11103.15",
      "Close": "11215.45"
  },
  {
      "Date": "7/24/20",
      "Open": "11149.95",
      "High": "11225.4",
      "Low": "11090.3",
      "Close": "11194.15"
  },
  {
      "Date": "7/27/20",
      "Open": "11225",
      "High": "11225",
      "Low": "11087.85",
      "Close": "11131.8"
  },
  {
      "Date": "7/28/20",
      "Open": "11154.1",
      "High": "11317.75",
      "Low": "11151.4",
      "Close": "11300.55"
  },
  {
      "Date": "7/29/20",
      "Open": "11276.9",
      "High": "11341.4",
      "Low": "11149.75",
      "Close": "11202.85"
  },
  {
      "Date": "7/30/20",
      "Open": "11254.3",
      "High": "11299.95",
      "Low": "11084.95",
      "Close": "11102.15"
  },
  {
      "Date": "7/31/20",
      "Open": "11139.5",
      "High": "11150.4",
      "Low": "11026.65",
      "Close": "11073.45"
  },
  {
      "Date": "8/3/20",
      "Open": "11057.55",
      "High": "11058.05",
      "Low": "10882.25",
      "Close": "10891.6"
  },
  {
      "Date": "8/4/20",
      "Open": "10946.65",
      "High": "11112.25",
      "Low": "10908.1",
      "Close": "11095.25"
  },
  {
      "Date": "8/5/20",
      "Open": "11155.75",
      "High": "11225.65",
      "Low": "11064.05",
      "Close": "11101.65"
  },
  {
      "Date": "8/6/20",
      "Open": "11185.7",
      "High": "11256.8",
      "Low": "11127.3",
      "Close": "11200.15"
  },
  {
      "Date": "8/7/20",
      "Open": "11186.65",
      "High": "11231.9",
      "Low": "11142.05",
      "Close": "11214.05"
  },
  {
      "Date": "8/10/20",
      "Open": "11270.25",
      "High": "11337.3",
      "Low": "11238",
      "Close": "11270.15"
  },
  {
      "Date": "8/11/20",
      "Open": "11322.25",
      "High": "11373.6",
      "Low": "11299.15",
      "Close": "11322.5"
  },
  {
      "Date": "8/12/20",
      "Open": "11289",
      "High": "11322",
      "Low": "11242.65",
      "Close": "11308.4"
  },
  {
      "Date": "8/13/20",
      "Open": "11334.85",
      "High": "11359.3",
      "Low": "11269.95",
      "Close": "11300.45"
  },
  {
      "Date": "8/14/20",
      "Open": "11353.3",
      "High": "11366.25",
      "Low": "11111.45",
      "Close": "11178.4"
  },
  {
      "Date": "8/17/20",
      "Open": "11248.9",
      "High": "11267.1",
      "Low": "11144.5",
      "Close": "11247.1"
  },
  {
      "Date": "8/18/20",
      "Open": "11259.8",
      "High": "11401.7",
      "Low": "11253.15",
      "Close": "11385.35"
  },
  {
      "Date": "8/19/20",
      "Open": "11452.15",
      "High": "11460.35",
      "Low": "11394.1",
      "Close": "11408.4"
  },
  {
      "Date": "8/20/20",
      "Open": "11317.45",
      "High": "11361.45",
      "Low": "11289.8",
      "Close": "11312.2"
  },
  {
      "Date": "8/21/20",
      "Open": "11409.65",
      "High": "11418.5",
      "Low": "11362.2",
      "Close": "11371.6"
  },
  {
      "Date": "8/24/20",
      "Open": "11412",
      "High": "11497.25",
      "Low": "11410.65",
      "Close": "11466.45"
  },
  {
      "Date": "8/25/20",
      "Open": "11513.1",
      "High": "11525.9",
      "Low": "11423.35",
      "Close": "11472.25"
  },
  {
      "Date": "8/26/20",
      "Open": "11512.85",
      "High": "11561.75",
      "Low": "11461.85",
      "Close": "11549.6"
  },
  {
      "Date": "8/27/20",
      "Open": "11609.3",
      "High": "11617.35",
      "Low": "11540.6",
      "Close": "11559.25"
  },
  {
      "Date": "8/28/20",
      "Open": "11602.95",
      "High": "11686.05",
      "Low": "11589.4",
      "Close": "11647.6"
  },
  {
      "Date": "8/31/20",
      "Open": "11777.55",
      "High": "11794.25",
      "Low": "11325.85",
      "Close": "11387.5"
  },
  {
      "Date": "9/1/20",
      "Open": "11464.3",
      "High": "11553.55",
      "Low": "11366.9",
      "Close": "11470.25"
  },
  {
      "Date": "9/2/20",
      "Open": "11478.55",
      "High": "11554.75",
      "Low": "11430.4",
      "Close": "11535"
  },
  {
      "Date": "9/3/20",
      "Open": "11566.2",
      "High": "11584.95",
      "Low": "11507.65",
      "Close": "11527.45"
  },
  {
      "Date": "9/4/20",
      "Open": "11354.4",
      "High": "11452.05",
      "Low": "11303.65",
      "Close": "11333.85"
  },
  {
      "Date": "9/7/20",
      "Open": "11359.6",
      "High": "11381.15",
      "Low": "11251.7",
      "Close": "11355.05"
  },
  {
      "Date": "9/8/20",
      "Open": "11378.55",
      "High": "11437.25",
      "Low": "11290.45",
      "Close": "11317.35"
  },
  {
      "Date": "9/9/20",
      "Open": "11218.6",
      "High": "11298.15",
      "Low": "11185.15",
      "Close": "11278"
  },
  {
      "Date": "9/10/20",
      "Open": "11363.3",
      "High": "11464.05",
      "Low": "11327.4",
      "Close": "11449.25"
  },
  {
      "Date": "9/11/20",
      "Open": "11447.8",
      "High": "11493.5",
      "Low": "11419.9",
      "Close": "11464.45"
  },
  {
      "Date": "9/14/20",
      "Open": "11540.15",
      "High": "11568.9",
      "Low": "11383.55",
      "Close": "11440.05"
  },
  {
      "Date": "9/15/20",
      "Open": "11487.2",
      "High": "11535.95",
      "Low": "11442.25",
      "Close": "11521.8"
  },
  {
      "Date": "9/16/20",
      "Open": "11538.45",
      "High": "11618.1",
      "Low": "11516.75",
      "Close": "11604.55"
  },
  {
      "Date": "9/17/20",
      "Open": "11539.4",
      "High": "11587.2",
      "Low": "11498.5",
      "Close": "11516.1"
  },
  {
      "Date": "9/18/20",
      "Open": "11584.1",
      "High": "11584.1",
      "Low": "11446.1",
      "Close": "11504.95"
  },
  {
      "Date": "9/21/20",
      "Open": "11503.8",
      "High": "11535.25",
      "Low": "11218.5",
      "Close": "11250.55"
  },
  {
      "Date": "9/22/20",
      "Open": "11301.75",
      "High": "11302.2",
      "Low": "11084.65",
      "Close": "11153.65"
  },
  {
      "Date": "9/23/20",
      "Open": "11258.75",
      "High": "11259.55",
      "Low": "11024.4",
      "Close": "11131.85"
  },
  {
      "Date": "9/24/20",
      "Open": "11011",
      "High": "11015.3",
      "Low": "10790.2",
      "Close": "10805.55"
  },
  {
      "Date": "9/25/20",
      "Open": "10910.4",
      "High": "11072.6",
      "Low": "10854.85",
      "Close": "11050.25"
  },
  {
      "Date": "9/28/20",
      "Open": "11140.85",
      "High": "11239.35",
      "Low": "11099.85",
      "Close": "11227.55"
  },
  {
      "Date": "9/29/20",
      "Open": "11288.6",
      "High": "11305.4",
      "Low": "11181",
      "Close": "11222.4"
  },
  {
      "Date": "9/30/20",
      "Open": "11244.45",
      "High": "11295.4",
      "Low": "11184.55",
      "Close": "11247.55"
  },
  {
      "Date": "10/1/20",
      "Open": "11364.45",
      "High": "11428.6",
      "Low": "11347.05",
      "Close": "11416.95"
  },
  {
      "Date": "10/5/20",
      "Open": "11487.8",
      "High": "11578.05",
      "Low": "11452.3",
      "Close": "11503.35"
  },
  {
      "Date": "10/6/20",
      "Open": "11603.45",
      "High": "11680.3",
      "Low": "11564.3",
      "Close": "11662.4"
  },
  {
      "Date": "10/7/20",
      "Open": "11679.25",
      "High": "11763.05",
      "Low": "11629.35",
      "Close": "11738.85"
  },
  {
      "Date": "10/8/20",
      "Open": "11835.4",
      "High": "11905.7",
      "Low": "11791.15",
      "Close": "11834.6"
  },
  {
      "Date": "10/9/20",
      "Open": "11852.05",
      "High": "11938.6",
      "Low": "11805.2",
      "Close": "11914.2"
  },
  {
      "Date": "10/12/20",
      "Open": "11973.55",
      "High": "12022.05",
      "Low": "11867.2",
      "Close": "11930.95"
  },
  {
      "Date": "10/13/20",
      "Open": "11934.65",
      "High": "11988.2",
      "Low": "11888.9",
      "Close": "11934.5"
  },
  {
      "Date": "10/14/20",
      "Open": "11917.4",
      "High": "11997.2",
      "Low": "11822.15",
      "Close": "11971.05"
  },
  {
      "Date": "10/15/20",
      "Open": "12023.45",
      "High": "12025.45",
      "Low": "11661.3",
      "Close": "11680.35"
  },
  {
      "Date": "10/16/20",
      "Open": "11727.4",
      "High": "11789.75",
      "Low": "11667.85",
      "Close": "11762.45"
  },
  {
      "Date": "10/19/20",
      "Open": "11879.2",
      "High": "11898.25",
      "Low": "11820.4",
      "Close": "11873.05"
  },
  {
      "Date": "10/20/20",
      "Open": "11861",
      "High": "11949.25",
      "Low": "11837.25",
      "Close": "11896.8"
  },
  {
      "Date": "10/21/20",
      "Open": "11958.55",
      "High": "12018.65",
      "Low": "11775.75",
      "Close": "11937.65"
  },
  {
      "Date": "10/22/20",
      "Open": "11890",
      "High": "11939.55",
      "Low": "11823.45",
      "Close": "11896.45"
  },
  {
      "Date": "10/23/20",
      "Open": "11957.9",
      "High": "11974.55",
      "Low": "11908.75",
      "Close": "11930.35"
  },
  {
      "Date": "10/26/20",
      "Open": "11937.4",
      "High": "11942.85",
      "Low": "11711.7",
      "Close": "11767.75"
  },
  {
      "Date": "10/27/20",
      "Open": "11807.1",
      "High": "11899.05",
      "Low": "11723",
      "Close": "11889.4"
  },
  {
      "Date": "10/28/20",
      "Open": "11922.6",
      "High": "11929.4",
      "Low": "11684.85",
      "Close": "11729.6"
  },
  {
      "Date": "10/29/20",
      "Open": "11633.3",
      "High": "11744.15",
      "Low": "11606.45",
      "Close": "11670.8"
  },
  {
      "Date": "10/30/20",
      "Open": "11678.45",
      "High": "11748.95",
      "Low": "11535.45",
      "Close": "11642.4"
  },
  {
      "Date": "11/2/20",
      "Open": "11697.35",
      "High": "11725.65",
      "Low": "11557.4",
      "Close": "11669.15"
  },
  {
      "Date": "11/3/20",
      "Open": "11734.45",
      "High": "11836.2",
      "Low": "11723.3",
      "Close": "11813.5"
  },
  {
      "Date": "11/4/20",
      "Open": "11783.35",
      "High": "11929.65",
      "Low": "11756.4",
      "Close": "11908.5"
  },
  {
      "Date": "11/5/20",
      "Open": "12062.4",
      "High": "12131.1",
      "Low": "12027.6",
      "Close": "12120.3"
  },
  {
      "Date": "11/6/20",
      "Open": "12156.65",
      "High": "12280.4",
      "Low": "12131.85",
      "Close": "12263.55"
  },
  {
      "Date": "11/9/20",
      "Open": "12399.4",
      "High": "12474.05",
      "Low": "12367.35",
      "Close": "12461.05"
  },
  {
      "Date": "11/10/20",
      "Open": "12556.4",
      "High": "12643.9",
      "Low": "12475.25",
      "Close": "12631.1"
  },
  {
      "Date": "11/11/20",
      "Open": "12680.6",
      "High": "12769.75",
      "Low": "12571.1",
      "Close": "12749.15"
  },
  {
      "Date": "11/12/20",
      "Open": "12702.15",
      "High": "12741.15",
      "Low": "12624.85",
      "Close": "12690.8"
  },
  {
      "Date": "11/13/20",
      "Open": "12659.7",
      "High": "12735.95",
      "Low": "12607.7",
      "Close": "12719.95"
  },
  {
      "Date": "11/14/20",
      "Open": "12823.35",
      "High": "12828.7",
      "Low": "12749.45",
      "Close": "12780.25"
  },
  {
      "Date": "11/17/20",
      "Open": "12932.5",
      "High": "12934.05",
      "Low": "12797.1",
      "Close": "12874.2"
  },
  {
      "Date": "11/18/20",
      "Open": "12860.1",
      "High": "12948.85",
      "Low": "12819.35",
      "Close": "12938.25"
  },
  {
      "Date": "11/19/20",
      "Open": "12839.5",
      "High": "12963",
      "Low": "12745.75",
      "Close": "12771.7"
  },
  {
      "Date": "11/20/20",
      "Open": "12813.4",
      "High": "12892.45",
      "Low": "12730.25",
      "Close": "12859.05"
  },
  {
      "Date": "11/23/20",
      "Open": "12960.3",
      "High": "12968.85",
      "Low": "12825.7",
      "Close": "12926.45"
  },
  {
      "Date": "11/24/20",
      "Open": "13002.6",
      "High": "13079.1",
      "Low": "12978",
      "Close": "13055.15"
  },
  {
      "Date": "11/25/20",
      "Open": "13130",
      "High": "13145.85",
      "Low": "12833.65",
      "Close": "12858.4"
  },
  {
      "Date": "11/26/20",
      "Open": "12906.45",
      "High": "13018",
      "Low": "12790.4",
      "Close": "12987"
  },
  {
      "Date": "11/27/20",
      "Open": "13012.05",
      "High": "13035.3",
      "Low": "12914.3",
      "Close": "12968.95"
  },
  {
      "Date": "12/1/20",
      "Open": "13062.2",
      "High": "13128.4",
      "Low": "12962.8",
      "Close": "13109.05"
  },
  {
      "Date": "12/2/20",
      "Open": "13121.4",
      "High": "13128.5",
      "Low": "12983.55",
      "Close": "13113.75"
  },
  {
      "Date": "12/3/20",
      "Open": "13215.3",
      "High": "13216.6",
      "Low": "13107.9",
      "Close": "13133.9"
  },
  {
      "Date": "12/4/20",
      "Open": "13177.4",
      "High": "13280.05",
      "Low": "13152.85",
      "Close": "13258.55"
  },
  {
      "Date": "12/7/20",
      "Open": "13264.85",
      "High": "13366.65",
      "Low": "13241.95",
      "Close": "13355.75"
  },
  {
      "Date": "12/8/20",
      "Open": "13393.85",
      "High": "13435.45",
      "Low": "13311.05",
      "Close": "13392.95"
  },
  {
      "Date": "12/9/20",
      "Open": "13458.1",
      "High": "13548.9",
      "Low": "13449.6",
      "Close": "13529.1"
  },
  {
      "Date": "12/10/20",
      "Open": "13488.5",
      "High": "13503.55",
      "Low": "13399.3",
      "Close": "13478.3"
  },
  {
      "Date": "12/11/20",
      "Open": "13512.3",
      "High": "13579.35",
      "Low": "13402.85",
      "Close": "13513.85"
  },
  {
      "Date": "12/14/20",
      "Open": "13571.45",
      "High": "13597.5",
      "Low": "13472.45",
      "Close": "13558.15"
  },
  {
      "Date": "12/15/20",
      "Open": "13547.2",
      "High": "13589.65",
      "Low": "13447.05",
      "Close": "13567.85"
  },
  {
      "Date": "12/16/20",
      "Open": "13663.1",
      "High": "13692.35",
      "Low": "13606.45",
      "Close": "13682.7"
  },
  {
      "Date": "12/17/20",
      "Open": "13713.55",
      "High": "13773.25",
      "Low": "13673.55",
      "Close": "13740.7"
  },
  {
      "Date": "12/18/20",
      "Open": "13764.4",
      "High": "13772.85",
      "Low": "13658.6",
      "Close": "13760.55"
  },
  {
      "Date": "12/21/20",
      "Open": "13741.9",
      "High": "13777.5",
      "Low": "13131.45",
      "Close": "13328.4"
  },
  {
      "Date": "12/22/20",
      "Open": "13373.65",
      "High": "13492.05",
      "Low": "13192.9",
      "Close": "13466.3"
  },
  {
      "Date": "12/23/20",
      "Open": "13473.5",
      "High": "13619.45",
      "Low": "13432.2",
      "Close": "13601.1"
  },
  {
      "Date": "12/24/20",
      "Open": "13672.15",
      "High": "13771.75",
      "Low": "13626.9",
      "Close": "13749.25"
  },
  {
      "Date": "12/28/20",
      "Open": "13815.15",
      "High": "13885.3",
      "Low": "13811.55",
      "Close": "13873.2"
  },
  {
      "Date": "12/29/20",
      "Open": "13910.35",
      "High": "13967.6",
      "Low": "13859.9",
      "Close": "13932.6"
  },
  {
      "Date": "12/30/20",
      "Open": "13980.9",
      "High": "13997",
      "Low": "13864.95",
      "Close": "13981.95"
  },
  {
      "Date": "12/31/20",
      "Open": "13970",
      "High": "14024.85",
      "Low": "13936.45",
      "Close": "13981.75"
  },
  {
      "Date": "1/1/21",
      "Open": "13996.1",
      "High": "14049.85",
      "Low": "13991.35",
      "Close": "14018.5"
  },
  {
      "Date": "1/4/21",
      "Open": "14104.35",
      "High": "14147.95",
      "Low": "13953.75",
      "Close": "14132.9"
  },
  {
      "Date": "1/5/21",
      "Open": "14075.15",
      "High": "14215.6",
      "Low": "14048.15",
      "Close": "14199.5"
  },
  {
      "Date": "1/6/21",
      "Open": "14240.95",
      "High": "14244.15",
      "Low": "14039.9",
      "Close": "14146.25"
  },
  {
      "Date": "1/7/21",
      "Open": "14253.75",
      "High": "14256.25",
      "Low": "14123.1",
      "Close": "14137.35"
  },
  {
      "Date": "1/8/21",
      "Open": "14258.4",
      "High": "14367.3",
      "Low": "14221.65",
      "Close": "14347.25"
  },
  {
      "Date": "1/11/21",
      "Open": "14474.05",
      "High": "14498.2",
      "Low": "14383.1",
      "Close": "14484.75"
  },
  {
      "Date": "1/12/21",
      "Open": "14473.8",
      "High": "14590.65",
      "Low": "14432.85",
      "Close": "14563.45"
  },
  {
      "Date": "1/13/21",
      "Open": "14639.8",
      "High": "14653.35",
      "Low": "14435.7",
      "Close": "14564.85"
  },
  {
      "Date": "1/14/21",
      "Open": "14550.05",
      "High": "14617.8",
      "Low": "14471.5",
      "Close": "14595.6"
  },
  {
      "Date": "1/15/21",
      "Open": "14594.35",
      "High": "14617.45",
      "Low": "14357.85",
      "Close": "14433.7"
  },
  {
      "Date": "1/18/21",
      "Open": "14453.3",
      "High": "14459.15",
      "Low": "14222.8",
      "Close": "14281.3"
  },
  {
      "Date": "1/19/21",
      "Open": "14371.65",
      "High": "14546.05",
      "Low": "14350.85",
      "Close": "14521.15"
  },
  {
      "Date": "1/20/21",
      "Open": "14538.3",
      "High": "14666.45",
      "Low": "14517.55",
      "Close": "14644.7"
  },
  {
      "Date": "1/21/21",
      "Open": "14730.95",
      "High": "14753.55",
      "Low": "14517.25",
      "Close": "14590.35"
  },
  {
      "Date": "1/22/21",
      "Open": "14583.4",
      "High": "14619.9",
      "Low": "14357.75",
      "Close": "14371.9"
  },
  {
      "Date": "1/25/21",
      "Open": "14477.8",
      "High": "14491.1",
      "Low": "14218.6",
      "Close": "14238.9"
  },
  {
      "Date": "1/27/21",
      "Open": "14237.95",
      "High": "14237.95",
      "Low": "13929.3",
      "Close": "13967.5"
  },
  {
      "Date": "1/28/21",
      "Open": "13810.4",
      "High": "13898.25",
      "Low": "13713.25",
      "Close": "13817.55"
  },
  {
      "Date": "1/29/21",
      "Open": "13946.6",
      "High": "13966.85",
      "Low": "13596.75",
      "Close": "13634.6"
  },
  {
      "Date": "2/1/21",
      "Open": "13758.6",
      "High": "14336.35",
      "Low": "13661.75",
      "Close": "14281.2"
  },
  {
      "Date": "2/2/21",
      "Open": "14481.1",
      "High": "14731.7",
      "Low": "14469.15",
      "Close": "14647.85"
  },
  {
      "Date": "2/3/21",
      "Open": "14754.9",
      "High": "14868.85",
      "Low": "14574.15",
      "Close": "14789.95"
  },
  {
      "Date": "2/4/21",
      "Open": "14789.05",
      "High": "14913.7",
      "Low": "14714.75",
      "Close": "14895.65"
  },
  {
      "Date": "2/5/21",
      "Open": "14952.6",
      "High": "15014.65",
      "Low": "14864.75",
      "Close": "14924.25"
  },
  {
      "Date": "2/8/21",
      "Open": "15064.3",
      "High": "15159.9",
      "Low": "15041.05",
      "Close": "15115.8"
  },
  {
      "Date": "2/9/21",
      "Open": "15164.15",
      "High": "15257.1",
      "Low": "15064.3",
      "Close": "15109.3"
  },
  {
      "Date": "2/10/21",
      "Open": "15119.05",
      "High": "15168.25",
      "Low": "14977.2",
      "Close": "15106.5"
  },
  {
      "Date": "2/11/21",
      "Open": "15073.25",
      "High": "15188.5",
      "Low": "15065.4",
      "Close": "15173.3"
  },
  {
      "Date": "2/12/21",
      "Open": "15186.2",
      "High": "15243.5",
      "Low": "15081",
      "Close": "15163.3"
  },
  {
      "Date": "2/15/21",
      "Open": "15270.3",
      "High": "15340.15",
      "Low": "15243.4",
      "Close": "15314.7"
  },
  {
      "Date": "2/16/21",
      "Open": "15371.45",
      "High": "15431.75",
      "Low": "15242.2",
      "Close": "15313.45"
  },
  {
      "Date": "2/17/21",
      "Open": "15279.9",
      "High": "15314.3",
      "Low": "15170.75",
      "Close": "15208.9"
  },
  {
      "Date": "2/18/21",
      "Open": "15238.7",
      "High": "15250.75",
      "Low": "15078.05",
      "Close": "15118.95"
  },
  {
      "Date": "2/19/21",
      "Open": "15074.8",
      "High": "15144.05",
      "Low": "14898.2",
      "Close": "14981.75"
  },
  {
      "Date": "2/22/21",
      "Open": "14999.05",
      "High": "15010.1",
      "Low": "14635.05",
      "Close": "14675.7"
  },
  {
      "Date": "2/23/21",
      "Open": "14782.25",
      "High": "14854.5",
      "Low": "14651.85",
      "Close": "14707.8"
  },
  {
      "Date": "2/24/21",
      "Open": "14729.15",
      "High": "15008.8",
      "Low": "14723.05",
      "Close": "14982"
  },
  {
      "Date": "2/25/21",
      "Open": "15079.85",
      "High": "15176.5",
      "Low": "15065.35",
      "Close": "15097.35"
  },
  {
      "Date": "2/26/21",
      "Open": "14888.6",
      "High": "14919.45",
      "Low": "14467.75",
      "Close": "14529.15"
  },
  {
      "Date": "3/1/21",
      "Open": "14702.5",
      "High": "14806.8",
      "Low": "14638.55",
      "Close": "14761.55"
  },
  {
      "Date": "3/2/21",
      "Open": "14865.3",
      "High": "14959.1",
      "Low": "14760.8",
      "Close": "14919.1"
  },
  {
      "Date": "3/3/21",
      "Open": "15064.4",
      "High": "15273.15",
      "Low": "14995.8",
      "Close": "15245.6"
  },
  {
      "Date": "3/4/21",
      "Open": "15026.75",
      "High": "15202.35",
      "Low": "14980.2",
      "Close": "15080.75"
  },
  {
      "Date": "3/5/21",
      "Open": "14977.95",
      "High": "15092.35",
      "Low": "14862.1",
      "Close": "14938.1"
  },
  {
      "Date": "3/8/21",
      "Open": "15002.45",
      "High": "15111.15",
      "Low": "14919.9",
      "Close": "14956.2"
  },
  {
      "Date": "3/9/21",
      "Open": "15049.9",
      "High": "15126.85",
      "Low": "14925.45",
      "Close": "15098.4"
  },
  {
      "Date": "3/10/21",
      "Open": "15202.15",
      "High": "15218.45",
      "Low": "15100.85",
      "Close": "15174.8"
  },
  {
      "Date": "3/12/21",
      "Open": "15321.15",
      "High": "15336.3",
      "Low": "14953.6",
      "Close": "15030.95"
  },
  {
      "Date": "3/15/21",
      "Open": "15048.4",
      "High": "15048.4",
      "Low": "14745.85",
      "Close": "14929.5"
  },
  {
      "Date": "3/16/21",
      "Open": "14996.1",
      "High": "15051.6",
      "Low": "14890.65",
      "Close": "14910.45"
  },
  {
      "Date": "3/17/21",
      "Open": "14946.55",
      "High": "14956.55",
      "Low": "14696.05",
      "Close": "14721.3"
  },
  {
      "Date": "3/18/21",
      "Open": "14855.5",
      "High": "14875.2",
      "Low": "14478.6",
      "Close": "14557.85"
  },
  {
      "Date": "3/19/21",
      "Open": "14471.15",
      "High": "14788.25",
      "Low": "14350.1",
      "Close": "14744"
  },
  {
      "Date": "3/22/21",
      "Open": "14736.3",
      "High": "14763.9",
      "Low": "14597.85",
      "Close": "14736.4"
  },
  {
      "Date": "3/23/21",
      "Open": "14768.55",
      "High": "14878.6",
      "Low": "14707",
      "Close": "14814.75"
  },
  {
      "Date": "3/24/21",
      "Open": "14712.45",
      "High": "14752.35",
      "Low": "14535",
      "Close": "14549.4"
  },
  {
      "Date": "3/25/21",
      "Open": "14570.9",
      "High": "14575.6",
      "Low": "14264.4",
      "Close": "14324.9"
  },
  {
      "Date": "3/26/21",
      "Open": "14506.3",
      "High": "14572.9",
      "Low": "14414.25",
      "Close": "14507.3"
  },
  {
      "Date": "3/30/21",
      "Open": "14628.5",
      "High": "14876.3",
      "Low": "14617.6",
      "Close": "14845.1"
  },
  {
      "Date": "3/31/21",
      "Open": "14811.85",
      "High": "14813.75",
      "Low": "14670.25",
      "Close": "14690.7"
  },
  {
      "Date": "4/1/21",
      "Open": "14798.4",
      "High": "14883.2",
      "Low": "14692.45",
      "Close": "14867.35"
  },
  {
      "Date": "4/5/21",
      "Open": "14837.7",
      "High": "14849.85",
      "Low": "14459.5",
      "Close": "14637.8"
  },
  {
      "Date": "4/6/21",
      "Open": "14737",
      "High": "14779.1",
      "Low": "14573.9",
      "Close": "14683.5"
  },
  {
      "Date": "4/7/21",
      "Open": "14716.45",
      "High": "14879.8",
      "Low": "14649.85",
      "Close": "14819.05"
  },
  {
      "Date": "4/8/21",
      "Open": "14875.65",
      "High": "14984.15",
      "Low": "14821.1",
      "Close": "14873.8"
  },
  {
      "Date": "4/9/21",
      "Open": "14882.65",
      "High": "14918.45",
      "Low": "14785.65",
      "Close": "14834.85"
  },
  {
      "Date": "4/12/21",
      "Open": "14644.65",
      "High": "14652.5",
      "Low": "14248.7",
      "Close": "14310.8"
  },
  {
      "Date": "4/13/21",
      "Open": "14364.9",
      "High": "14528.9",
      "Low": "14274.9",
      "Close": "14504.8"
  },
  {
      "Date": "4/15/21",
      "Open": "14522.4",
      "High": "14597.55",
      "Low": "14353.2",
      "Close": "14581.45"
  },
  {
      "Date": "4/16/21",
      "Open": "14599.6",
      "High": "14697.7",
      "Low": "14559",
      "Close": "14617.85"
  },
  {
      "Date": "4/19/21",
      "Open": "14306.6",
      "High": "14382.3",
      "Low": "14191.4",
      "Close": "14359.45"
  },
  {
      "Date": "4/20/21",
      "Open": "14526.7",
      "High": "14526.95",
      "Low": "14207.3",
      "Close": "14296.4"
  },
  {
      "Date": "4/22/21",
      "Open": "14219.15",
      "High": "14424.75",
      "Low": "14151.4",
      "Close": "14406.15"
  },
  {
      "Date": "4/23/21",
      "Open": "14326.35",
      "High": "14461.15",
      "Low": "14273.3",
      "Close": "14341.35"
  },
  {
      "Date": "4/26/21",
      "Open": "14449.45",
      "High": "14557.5",
      "Low": "14421.3",
      "Close": "14485"
  },
  {
      "Date": "4/27/21",
      "Open": "14493.8",
      "High": "14667.55",
      "Low": "14484.85",
      "Close": "14653.05"
  },
  {
      "Date": "4/28/21",
      "Open": "14710.5",
      "High": "14890.25",
      "Low": "14694.95",
      "Close": "14864.55"
  },
  {
      "Date": "4/29/21",
      "Open": "14979",
      "High": "15044.35",
      "Low": "14814.45",
      "Close": "14894.9"
  },
  {
      "Date": "4/30/21",
      "Open": "14747.35",
      "High": "14855.45",
      "Low": "14601.7",
      "Close": "14631.1"
  },
  {
      "Date": "5/3/21",
      "Open": "14481.05",
      "High": "14673.85",
      "Low": "14416.25",
      "Close": "14634.15"
  },
  {
      "Date": "5/4/21",
      "Open": "14687.25",
      "High": "14723.4",
      "Low": "14461.5",
      "Close": "14496.5"
  },
  {
      "Date": "5/5/21",
      "Open": "14604.15",
      "High": "14637.9",
      "Low": "14506.6",
      "Close": "14617.85"
  },
  {
      "Date": "5/6/21",
      "Open": "14668.35",
      "High": "14743.9",
      "Low": "14611.5",
      "Close": "14724.8"
  },
  {
      "Date": "5/7/21",
      "Open": "14816.85",
      "High": "14863.05",
      "Low": "14765.5",
      "Close": "14823.15"
  },
  {
      "Date": "5/10/21",
      "Open": "14928.25",
      "High": "14966.9",
      "Low": "14892.5",
      "Close": "14942.35"
  },
  {
      "Date": "5/11/21",
      "Open": "14789.7",
      "High": "14900",
      "Low": "14771.4",
      "Close": "14850.75"
  },
  {
      "Date": "5/12/21",
      "Open": "14823.55",
      "High": "14824.05",
      "Low": "14649.7",
      "Close": "14696.5"
  },
  {
      "Date": "5/14/21",
      "Open": "14749.4",
      "High": "14749.65",
      "Low": "14591.9",
      "Close": "14677.8"
  },
  {
      "Date": "5/17/21",
      "Open": "14756.25",
      "High": "14938",
      "Low": "14725.35",
      "Close": "14923.15"
  },
  {
      "Date": "5/18/21",
      "Open": "15067.2",
      "High": "15137.25",
      "Low": "15043.7",
      "Close": "15108.1"
  },
  {
      "Date": "5/19/21",
      "Open": "15058.6",
      "High": "15133.4",
      "Low": "15008.85",
      "Close": "15030.15"
  },
  {
      "Date": "5/20/21",
      "Open": "15042.6",
      "High": "15069.8",
      "Low": "14884.9",
      "Close": "14906.05"
  },
  {
      "Date": "5/21/21",
      "Open": "14987.8",
      "High": "15190",
      "Low": "14985.85",
      "Close": "15175.3"
  },
  {
      "Date": "5/24/21",
      "Open": "15211.35",
      "High": "15256.25",
      "Low": "15145.45",
      "Close": "15197.7"
  },
  {
      "Date": "5/25/21",
      "Open": "15291.75",
      "High": "15293.85",
      "Low": "15163.4",
      "Close": "15208.45"
  },
  {
      "Date": "5/26/21",
      "Open": "15257.05",
      "High": "15319.9",
      "Low": "15194.95",
      "Close": "15301.45"
  },
  {
      "Date": "5/27/21",
      "Open": "15323.95",
      "High": "15384.55",
      "Low": "15272.5",
      "Close": "15337.85"
  },
  {
      "Date": "5/28/21",
      "Open": "15421.2",
      "High": "15469.65",
      "Low": "15394.75",
      "Close": "15435.65"
  },
  {
      "Date": "5/31/21",
      "Open": "15437.75",
      "High": "15606.35",
      "Low": "15374",
      "Close": "15582.8"
  },
  {
      "Date": "6/1/21",
      "Open": "15629.65",
      "High": "15660.75",
      "Low": "15528.3",
      "Close": "15574.85"
  },
  {
      "Date": "6/2/21",
      "Open": "15520.35",
      "High": "15597.45",
      "Low": "15459.85",
      "Close": "15576.2"
  },
  {
      "Date": "6/3/21",
      "Open": "15655.55",
      "High": "15705.1",
      "Low": "15611",
      "Close": "15690.35"
  },
  {
      "Date": "6/4/21",
      "Open": "15712.5",
      "High": "15733.6",
      "Low": "15622.35",
      "Close": "15670.25"
  },
  {
      "Date": "6/7/21",
      "Open": "15725.1",
      "High": "15773.45",
      "Low": "15678.1",
      "Close": "15751.65"
  },
  {
      "Date": "6/8/21",
      "Open": "15773.9",
      "High": "15778.8",
      "Low": "15680",
      "Close": "15740.1"
  },
  {
      "Date": "6/9/21",
      "Open": "15766.3",
      "High": "15800.45",
      "Low": "15566.9",
      "Close": "15635.35"
  },
  {
      "Date": "6/10/21",
      "Open": "15692.1",
      "High": "15751.25",
      "Low": "15648.5",
      "Close": "15737.75"
  },
  {
      "Date": "6/11/21",
      "Open": "15796.45",
      "High": "15835.55",
      "Low": "15749.8",
      "Close": "15799.35"
  },
  {
      "Date": "6/14/21",
      "Open": "15791.4",
      "High": "15823.05",
      "Low": "15606.5",
      "Close": "15811.85"
  },
  {
      "Date": "6/15/21",
      "Open": "15866.95",
      "High": "15901.6",
      "Low": "15842.4",
      "Close": "15869.25"
  },
  {
      "Date": "6/16/21",
      "Open": "15847.5",
      "High": "15880.85",
      "Low": "15742.6",
      "Close": "15767.55"
  },
  {
      "Date": "6/17/21",
      "Open": "15648.3",
      "High": "15769.35",
      "Low": "15616.75",
      "Close": "15691.4"
  },
  {
      "Date": "6/18/21",
      "Open": "15756.5",
      "High": "15761.5",
      "Low": "15450.9",
      "Close": "15683.35"
  },
  {
      "Date": "6/21/21",
      "Open": "15525.85",
      "High": "15765.15",
      "Low": "15505.65",
      "Close": "15746.5"
  },
  {
      "Date": "6/22/21",
      "Open": "15840.5",
      "High": "15895.75",
      "Low": "15752.1",
      "Close": "15772.75"
  },
  {
      "Date": "6/23/21",
      "Open": "15862.8",
      "High": "15862.95",
      "Low": "15673.95",
      "Close": "15686.95"
  },
  {
      "Date": "6/24/21",
      "Open": "15737.3",
      "High": "15821.4",
      "Low": "15702.7",
      "Close": "15790.45"
  },
  {
      "Date": "6/25/21",
      "Open": "15839.35",
      "High": "15870.8",
      "Low": "15772.3",
      "Close": "15860.35"
  },
  {
      "Date": "6/28/21",
      "Open": "15915.35",
      "High": "15915.65",
      "Low": "15792.15",
      "Close": "15814.7"
  },
  {
      "Date": "6/29/21",
      "Open": "15807.5",
      "High": "15835.9",
      "Low": "15724.05",
      "Close": "15748.45"
  },
  {
      "Date": "6/30/21",
      "Open": "15776.9",
      "High": "15839.1",
      "Low": "15708.75",
      "Close": "15721.5"
  },
  {
      "Date": "7/1/21",
      "Open": "15755.05",
      "High": "15755.55",
      "Low": "15667.05",
      "Close": "15680"
  },
  {
      "Date": "7/2/21",
      "Open": "15705.85",
      "High": "15738.35",
      "Low": "15635.95",
      "Close": "15722.2"
  },
  {
      "Date": "7/5/21",
      "Open": "15793.4",
      "High": "15845.95",
      "Low": "15762.05",
      "Close": "15834.35"
  },
  {
      "Date": "7/6/21",
      "Open": "15813.75",
      "High": "15914.2",
      "Low": "15801",
      "Close": "15818.25"
  },
  {
      "Date": "7/7/21",
      "Open": "15819.6",
      "High": "15893.55",
      "Low": "15779.7",
      "Close": "15879.65"
  },
  {
      "Date": "7/8/21",
      "Open": "15855.4",
      "High": "15885.75",
      "Low": "15682.9",
      "Close": "15727.9"
  },
  {
      "Date": "7/9/21",
      "Open": "15688.25",
      "High": "15730.85",
      "Low": "15632.75",
      "Close": "15689.8"
  },
  {
      "Date": "7/12/21",
      "Open": "15766.8",
      "High": "15789.2",
      "Low": "15644.75",
      "Close": "15692.6"
  },
  {
      "Date": "7/13/21",
      "Open": "15794",
      "High": "15820.8",
      "Low": "15744.6",
      "Close": "15812.35"
  },
  {
      "Date": "7/14/21",
      "Open": "15808.7",
      "High": "15877.35",
      "Low": "15764.2",
      "Close": "15853.95"
  },
  {
      "Date": "7/15/21",
      "Open": "15872.15",
      "High": "15952.35",
      "Low": "15855",
      "Close": "15924.2"
  },
  {
      "Date": "7/16/21",
      "Open": "15958.35",
      "High": "15962.25",
      "Low": "15882.6",
      "Close": "15923.4"
  },
  {
      "Date": "7/19/21",
      "Open": "15754.5",
      "High": "15836.9",
      "Low": "15707.5",
      "Close": "15752.4"
  },
  {
      "Date": "7/20/21",
      "Open": "15703.95",
      "High": "15728.45",
      "Low": "15578.55",
      "Close": "15632.1"
  },
  {
      "Date": "7/22/21",
      "Open": "15736.6",
      "High": "15834.8",
      "Low": "15726.4",
      "Close": "15824.05"
  },
  {
      "Date": "7/23/21",
      "Open": "15856.8",
      "High": "15899.8",
      "Low": "15768.4",
      "Close": "15856.05"
  },
  {
      "Date": "7/26/21",
      "Open": "15849.3",
      "High": "15893.35",
      "Low": "15797",
      "Close": "15824.45"
  },
  {
      "Date": "7/27/21",
      "Open": "15860.5",
      "High": "15881.55",
      "Low": "15701",
      "Close": "15746.45"
  },
  {
      "Date": "7/28/21",
      "Open": "15761.55",
      "High": "15767.5",
      "Low": "15513.45",
      "Close": "15709.4"
  },
  {
      "Date": "7/29/21",
      "Open": "15762.7",
      "High": "15817.35",
      "Low": "15737.8",
      "Close": "15778.45"
  },
  {
      "Date": "7/30/21",
      "Open": "15800.6",
      "High": "15862.8",
      "Low": "15744.85",
      "Close": "15763.05"
  },
  {
      "Date": "8/2/21",
      "Open": "15874.9",
      "High": "15892.9",
      "Low": "15834.65",
      "Close": "15885.15"
  },
  {
      "Date": "8/3/21",
      "Open": "15951.55",
      "High": "16146.9",
      "Low": "15914.35",
      "Close": "16130.75"
  },
  {
      "Date": "8/4/21",
      "Open": "16195.25",
      "High": "16290.2",
      "Low": "16176.15",
      "Close": "16258.8"
  },
  {
      "Date": "8/5/21",
      "Open": "16288.95",
      "High": "16349.45",
      "Low": "16210.3",
      "Close": "16294.6"
  },
  {
      "Date": "8/6/21",
      "Open": "16304.4",
      "High": "16336.75",
      "Low": "16223.3",
      "Close": "16238.2"
  },
  {
      "Date": "8/9/21",
      "Open": "16281.35",
      "High": "16320.75",
      "Low": "16179.05",
      "Close": "16258.25"
  },
  {
      "Date": "8/10/21",
      "Open": "16274.8",
      "High": "16359.25",
      "Low": "16202.25",
      "Close": "16280.1"
  },
  {
      "Date": "8/11/21",
      "Open": "16327.3",
      "High": "16338.75",
      "Low": "16162.55",
      "Close": "16282.25"
  },
  {
      "Date": "8/12/21",
      "Open": "16303.65",
      "High": "16375.5",
      "Low": "16286.9",
      "Close": "16364.4"
  },
  {
      "Date": "8/13/21",
      "Open": "16385.7",
      "High": "16543.6",
      "Low": "16376.3",
      "Close": "16529.1"
  },
  {
      "Date": "8/16/21",
      "Open": "16518.4",
      "High": "16589.4",
      "Low": "16480.75",
      "Close": "16563.05"
  },
  {
      "Date": "8/17/21",
      "Open": "16545.25",
      "High": "16628.55",
      "Low": "16495.4",
      "Close": "16614.6"
  },
  {
      "Date": "8/18/21",
      "Open": "16691.95",
      "High": "16701.85",
      "Low": "16535.85",
      "Close": "16568.85"
  },
  {
      "Date": "8/20/21",
      "Open": "16382.5",
      "High": "16509.55",
      "Low": "16376.05",
      "Close": "16450.5"
  },
  {
      "Date": "8/23/21",
      "Open": "16592.25",
      "High": "16592.5",
      "Low": "16395.7",
      "Close": "16496.45"
  },
  {
      "Date": "8/24/21",
      "Open": "16561.4",
      "High": "16647.1",
      "Low": "16495.3",
      "Close": "16624.6"
  },
  {
      "Date": "8/25/21",
      "Open": "16654",
      "High": "16712.45",
      "Low": "16617.5",
      "Close": "16634.65"
  },
  {
      "Date": "8/26/21",
      "Open": "16627.95",
      "High": "16683.7",
      "Low": "16603.4",
      "Close": "16636.9"
  },
  {
      "Date": "8/27/21",
      "Open": "16642.55",
      "High": "16722.05",
      "Low": "16565.6",
      "Close": "16705.2"
  },
  {
      "Date": "8/30/21",
      "Open": "16775.85",
      "High": "16951.5",
      "Low": "16764.85",
      "Close": "16931.05"
  },
  {
      "Date": "8/31/21",
      "Open": "16947.5",
      "High": "17153.5",
      "Low": "16915.85",
      "Close": "17132.2"
  },
  {
      "Date": "9/1/21",
      "Open": "17185.6",
      "High": "17225.75",
      "Low": "17055.05",
      "Close": "17076.25"
  },
  {
      "Date": "9/2/21",
      "Open": "17095.4",
      "High": "17245.5",
      "Low": "17059.7",
      "Close": "17234.15"
  },
  {
      "Date": "9/3/21",
      "Open": "17262.45",
      "High": "17340.1",
      "Low": "17212.2",
      "Close": "17323.6"
  },
  {
      "Date": "9/6/21",
      "Open": "17399.35",
      "High": "17429.55",
      "Low": "17345.55",
      "Close": "17377.8"
  },
  {
      "Date": "9/7/21",
      "Open": "17401.55",
      "High": "17436.5",
      "Low": "17287",
      "Close": "17362.1"
  },
  {
      "Date": "9/8/21",
      "Open": "17375.75",
      "High": "17383.4",
      "Low": "17254.2",
      "Close": "17353.5"
  },
  {
      "Date": "9/9/21",
      "Open": "17312.85",
      "High": "17379.65",
      "Low": "17302.7",
      "Close": "17369.25"
  },
  {
      "Date": "9/13/21",
      "Open": "17363.55",
      "High": "17378.35",
      "Low": "17269.15",
      "Close": "17355.3"
  },
  {
      "Date": "9/14/21",
      "Open": "17420.35",
      "High": "17438.55",
      "Low": "17367.05",
      "Close": "17380"
  },
  {
      "Date": "9/15/21",
      "Open": "17387.65",
      "High": "17532.7",
      "Low": "17386.9",
      "Close": "17519.45"
  },
  {
      "Date": "9/16/21",
      "Open": "17539.2",
      "High": "17644.6",
      "Low": "17510.45",
      "Close": "17629.5"
  },
  {
      "Date": "9/17/21",
      "Open": "17709.65",
      "High": "17792.95",
      "Low": "17537.65",
      "Close": "17585.15"
  },
  {
      "Date": "9/20/21",
      "Open": "17443.85",
      "High": "17622.75",
      "Low": "17361.8",
      "Close": "17396.9"
  },
  {
      "Date": "9/21/21",
      "Open": "17450.5",
      "High": "17578.35",
      "Low": "17326.1",
      "Close": "17562"
  },
  {
      "Date": "9/22/21",
      "Open": "17580.9",
      "High": "17610.45",
      "Low": "17524",
      "Close": "17546.65"
  },
  {
      "Date": "9/23/21",
      "Open": "17670.85",
      "High": "17843.9",
      "Low": "17646.55",
      "Close": "17822.95"
  },
  {
      "Date": "9/24/21",
      "Open": "17897.45",
      "High": "17947.65",
      "Low": "17819.4",
      "Close": "17853.2"
  },
  {
      "Date": "9/27/21",
      "Open": "17932.2",
      "High": "17943.5",
      "Low": "17802.9",
      "Close": "17855.1"
  },
  {
      "Date": "9/28/21",
      "Open": "17906.45",
      "High": "17912.85",
      "Low": "17576.1",
      "Close": "17748.6"
  },
  {
      "Date": "9/29/21",
      "Open": "17657.95",
      "High": "17781.75",
      "Low": "17608.15",
      "Close": "17711.3"
  },
  {
      "Date": "9/30/21",
      "Open": "17718.9",
      "High": "17742.15",
      "Low": "17585.35",
      "Close": "17618.15"
  },
  {
      "Date": "10/1/21",
      "Open": "17531.9",
      "High": "17557.15",
      "Low": "17452.9",
      "Close": "17532.05"
  },
  {
      "Date": "10/4/21",
      "Open": "17615.55",
      "High": "17750.9",
      "Low": "17581.35",
      "Close": "17691.25"
  },
  {
      "Date": "10/5/21",
      "Open": "17661.35",
      "High": "17833.45",
      "Low": "17640.9",
      "Close": "17822.3"
  },
  {
      "Date": "10/6/21",
      "Open": "17861.5",
      "High": "17884.6",
      "Low": "17613.15",
      "Close": "17646"
  },
  {
      "Date": "10/7/21",
      "Open": "17810.55",
      "High": "17857.55",
      "Low": "17763.8",
      "Close": "17790.35"
  },
  {
      "Date": "10/8/21",
      "Open": "17886.85",
      "High": "17941.85",
      "Low": "17840.35",
      "Close": "17895.2"
  },
  {
      "Date": "10/11/21",
      "Open": "17867.55",
      "High": "18041.95",
      "Low": "17839.1",
      "Close": "17945.95"
  },
  {
      "Date": "10/12/21",
      "Open": "17915.8",
      "High": "18008.65",
      "Low": "17864.95",
      "Close": "17991.95"
  },
  {
      "Date": "10/13/21",
      "Open": "18097.85",
      "High": "18197.8",
      "Low": "18050.75",
      "Close": "18161.75"
  },
  {
      "Date": "10/14/21",
      "Open": "18272.85",
      "High": "18350.75",
      "Low": "18248.7",
      "Close": "18338.55"
  },
  {
      "Date": "10/18/21",
      "Open": "18500.1",
      "High": "18543.15",
      "Low": "18445.3",
      "Close": "18477.05"
  },
  {
      "Date": "10/19/21",
      "Open": "18602.35",
      "High": "18604.45",
      "Low": "18377.7",
      "Close": "18418.75"
  },
  {
      "Date": "10/20/21",
      "Open": "18439.9",
      "High": "18458.3",
      "Low": "18209.35",
      "Close": "18266.6"
  },
  {
      "Date": "10/21/21",
      "Open": "18382.7",
      "High": "18384.2",
      "Low": "18048",
      "Close": "18178.1"
  },
  {
      "Date": "10/22/21",
      "Open": "18230.7",
      "High": "18314.25",
      "Low": "18034.35",
      "Close": "18114.9"
  },
  {
      "Date": "10/25/21",
      "Open": "18229.5",
      "High": "18241.4",
      "Low": "17968.5",
      "Close": "18125.4"
  },
  {
      "Date": "10/26/21",
      "Open": "18154.5",
      "High": "18310.45",
      "Low": "18099.3",
      "Close": "18268.4"
  },
  {
      "Date": "10/27/21",
      "Open": "18295.85",
      "High": "18342.05",
      "Low": "18167.9",
      "Close": "18210.95"
  },
  {
      "Date": "10/28/21",
      "Open": "18187.65",
      "High": "18190.7",
      "Low": "17799.45",
      "Close": "17857.25"
  },
  {
      "Date": "10/29/21",
      "Open": "17833.05",
      "High": "17915.85",
      "Low": "17613.1",
      "Close": "17671.65"
  },
  {
      "Date": "11/1/21",
      "Open": "17783.15",
      "High": "17954.1",
      "Low": "17697.1",
      "Close": "17929.65"
  },
  {
      "Date": "11/2/21",
      "Open": "17970.9",
      "High": "18012.25",
      "Low": "17847.6",
      "Close": "17888.95"
  },
  {
      "Date": "11/3/21",
      "Open": "17947.95",
      "High": "17988.75",
      "Low": "17757.95",
      "Close": "17829.2"
  },
  {
      "Date": "11/4/21",
      "Open": "17935.05",
      "High": "17947.55",
      "Low": "17900.6",
      "Close": "17916.8"
  },
  {
      "Date": "11/8/21",
      "Open": "18040.2",
      "High": "18087.8",
      "Low": "17836.1",
      "Close": "18068.55"
  },
  {
      "Date": "11/9/21",
      "Open": "18084.35",
      "High": "18112.6",
      "Low": "17983.05",
      "Close": "18044.25"
  },
  {
      "Date": "11/10/21",
      "Open": "17973.45",
      "High": "18061.25",
      "Low": "17915",
      "Close": "18017.2"
  },
  {
      "Date": "11/11/21",
      "Open": "17967.45",
      "High": "17971.35",
      "Low": "17798.2",
      "Close": "17873.6"
  },
  {
      "Date": "11/12/21",
      "Open": "17977.6",
      "High": "18123",
      "Low": "17905.9",
      "Close": "18102.75"
  },
  {
      "Date": "11/15/21",
      "Open": "18140.95",
      "High": "18210.15",
      "Low": "18071.3",
      "Close": "18109.45"
  },
  {
      "Date": "11/16/21",
      "Open": "18127.05",
      "High": "18132.65",
      "Low": "17958.8",
      "Close": "17999.2"
  },
  {
      "Date": "11/17/21",
      "Open": "17939.35",
      "High": "18022.65",
      "Low": "17879.25",
      "Close": "17898.65"
  },
  {
      "Date": "11/18/21",
      "Open": "17890.55",
      "High": "17945.6",
      "Low": "17688.5",
      "Close": "17764.8"
  },
  {
      "Date": "11/22/21",
      "Open": "17796.25",
      "High": "17805.25",
      "Low": "17280.45",
      "Close": "17416.55"
  },
  {
      "Date": "11/23/21",
      "Open": "17281.75",
      "High": "17553.7",
      "Low": "17216.1",
      "Close": "17503.35"
  },
  {
      "Date": "11/24/21",
      "Open": "17550.05",
      "High": "17600.6",
      "Low": "17354",
      "Close": "17415.05"
  },
  {
      "Date": "11/25/21",
      "Open": "17417.3",
      "High": "17564.35",
      "Low": "17351.7",
      "Close": "17536.25"
  },
  {
      "Date": "11/26/21",
      "Open": "17338.75",
      "High": "17355.4",
      "Low": "16985.7",
      "Close": "17026.45"
  },
  {
      "Date": "11/29/21",
      "Open": "17055.8",
      "High": "17160.7",
      "Low": "16782.4",
      "Close": "17053.95"
  },
  {
      "Date": "11/30/21",
      "Open": "17051.15",
      "High": "17324.65",
      "Low": "16931.4",
      "Close": "16983.2"
  },
  {
      "Date": "12/1/21",
      "Open": "17104.4",
      "High": "17213.05",
      "Low": "17064.25",
      "Close": "17166.9"
  },
  {
      "Date": "12/2/21",
      "Open": "17183.2",
      "High": "17420.35",
      "Low": "17149.3",
      "Close": "17401.65"
  },
  {
      "Date": "12/3/21",
      "Open": "17424.9",
      "High": "17489.8",
      "Low": "17180.8",
      "Close": "17196.7"
  },
  {
      "Date": "12/6/21",
      "Open": "17209.05",
      "High": "17216.75",
      "Low": "16891.7",
      "Close": "16912.25"
  },
  {
      "Date": "12/7/21",
      "Open": "17044.1",
      "High": "17251.65",
      "Low": "16987.75",
      "Close": "17176.7"
  },
  {
      "Date": "12/8/21",
      "Open": "17315.25",
      "High": "17484.6",
      "Low": "17308.95",
      "Close": "17469.75"
  },
  {
      "Date": "12/9/21",
      "Open": "17524.4",
      "High": "17543.25",
      "Low": "17379.6",
      "Close": "17516.85"
  },
  {
      "Date": "12/10/21",
      "Open": "17476.05",
      "High": "17534.35",
      "Low": "17405.25",
      "Close": "17511.3"
  },
  {
      "Date": "12/13/21",
      "Open": "17619.1",
      "High": "17639.5",
      "Low": "17355.95",
      "Close": "17368.25"
  },
  {
      "Date": "12/14/21",
      "Open": "17283.2",
      "High": "17376.2",
      "Low": "17225.8",
      "Close": "17324.9"
  },
  {
      "Date": "12/15/21",
      "Open": "17323.65",
      "High": "17351.2",
      "Low": "17192.2",
      "Close": "17221.4"
  },
  {
      "Date": "12/16/21",
      "Open": "17373",
      "High": "17379.35",
      "Low": "17184.95",
      "Close": "17248.4"
  },
  {
      "Date": "12/17/21",
      "Open": "17276",
      "High": "17298.15",
      "Low": "16966.45",
      "Close": "16985.2"
  },
  {
      "Date": "12/20/21",
      "Open": "16824.25",
      "High": "16840.1",
      "Low": "16410.2",
      "Close": "16614.2"
  },
  {
      "Date": "12/21/21",
      "Open": "16773.15",
      "High": "16936.4",
      "Low": "16688.25",
      "Close": "16770.85"
  },
  {
      "Date": "12/22/21",
      "Open": "16865.55",
      "High": "16971",
      "Low": "16819.5",
      "Close": "16955.45"
  },
  {
      "Date": "12/23/21",
      "Open": "17066.8",
      "High": "17118.65",
      "Low": "17015.55",
      "Close": "17072.6"
  },
  {
      "Date": "12/24/21",
      "Open": "17149.5",
      "High": "17155.6",
      "Low": "16909.6",
      "Close": "17003.75"
  },
  {
      "Date": "12/27/21",
      "Open": "16937.75",
      "High": "17112.05",
      "Low": "16833.2",
      "Close": "17086.25"
  },
  {
      "Date": "12/28/21",
      "Open": "17177.6",
      "High": "17250.25",
      "Low": "17161.15",
      "Close": "17233.25"
  },
  {
      "Date": "12/29/21",
      "Open": "17220.1",
      "High": "17285.95",
      "Low": "17176.65",
      "Close": "17213.6"
  },
  {
      "Date": "12/30/21",
      "Open": "17201.45",
      "High": "17264.05",
      "Low": "17146.35",
      "Close": "17203.95"
  },
  {
      "Date": "12/31/21",
      "Open": "17244.5",
      "High": "17400.8",
      "Low": "17238.5",
      "Close": "17354.05"
  },
  {
      "Date": "1/3/22",
      "Open": "17387.15",
      "High": "17646.65",
      "Low": "17383.3",
      "Close": "17625.7"
  },
  {
      "Date": "1/4/22",
      "Open": "17681.4",
      "High": "17827.6",
      "Low": "17593.55",
      "Close": "17805.25"
  },
  {
      "Date": "1/5/22",
      "Open": "17820.1",
      "High": "17944.7",
      "Low": "17748.85",
      "Close": "17925.25"
  },
  {
      "Date": "1/6/22",
      "Open": "17768.5",
      "High": "17797.95",
      "Low": "17655.55",
      "Close": "17745.9"
  },
  {
      "Date": "1/7/22",
      "Open": "17797.6",
      "High": "17905",
      "Low": "17704.55",
      "Close": "17812.7"
  },
  {
      "Date": "1/10/22",
      "Open": "17913.3",
      "High": "18017.45",
      "Low": "17879.15",
      "Close": "18003.3"
  },
  {
      "Date": "1/11/22",
      "Open": "17997.75",
      "High": "18081.25",
      "Low": "17964.4",
      "Close": "18055.75"
  },
  {
      "Date": "1/12/22",
      "Open": "18170.4",
      "High": "18227.95",
      "Low": "18128.8",
      "Close": "18212.35"
  },
  {
      "Date": "1/13/22",
      "Open": "18257",
      "High": "18272.25",
      "Low": "18163.8",
      "Close": "18257.8"
  },
  {
      "Date": "1/14/22",
      "Open": "18185",
      "High": "18286.95",
      "Low": "18119.65",
      "Close": "18255.75"
  },
  {
      "Date": "1/17/22",
      "Open": "18235.65",
      "High": "18321.55",
      "Low": "18228.75",
      "Close": "18308.1"
  },
  {
      "Date": "1/18/22",
      "Open": "18337.2",
      "High": "18350.95",
      "Low": "18085.9",
      "Close": "18113.05"
  },
  {
      "Date": "1/19/22",
      "Open": "18129.2",
      "High": "18129.2",
      "Low": "17884.9",
      "Close": "17938.4"
  },
  {
      "Date": "1/20/22",
      "Open": "17921",
      "High": "17943.7",
      "Low": "17648.45",
      "Close": "17757"
  },
  {
      "Date": "1/21/22",
      "Open": "17613.7",
      "High": "17707.6",
      "Low": "17485.85",
      "Close": "17617.15"
  },
  {
      "Date": "1/24/22",
      "Open": "17575.15",
      "High": "17599.4",
      "Low": "16997.85",
      "Close": "17149.1"
  },
  {
      "Date": "1/25/22",
      "Open": "17001.55",
      "High": "17309.15",
      "Low": "16836.8",
      "Close": "17277.95"
  },
  {
      "Date": "1/27/22",
      "Open": "17062",
      "High": "17182.5",
      "Low": "16866.75",
      "Close": "17110.15"
  },
  {
      "Date": "1/28/22",
      "Open": "17208.3",
      "High": "17373.5",
      "Low": "17077.1",
      "Close": "17101.95"
  },
  {
      "Date": "1/31/22",
      "Open": "17301.05",
      "High": "17410",
      "Low": "17264.15",
      "Close": "17339.85"
  },
  {
      "Date": "2/1/22",
      "Open": "17529.45",
      "High": "17622.4",
      "Low": "17244.55",
      "Close": "17576.85"
  },
  {
      "Date": "2/2/22",
      "Open": "17706.2",
      "High": "17794.6",
      "Low": "17674.8",
      "Close": "17780"
  },
  {
      "Date": "2/3/22",
      "Open": "17767.75",
      "High": "17781.15",
      "Low": "17511.15",
      "Close": "17560.2"
  },
  {
      "Date": "2/4/22",
      "Open": "17590.2",
      "High": "17617.8",
      "Low": "17462.55",
      "Close": "17516.3"
  },
  {
      "Date": "2/7/22",
      "Open": "17456.3",
      "High": "17536.75",
      "Low": "17119.4",
      "Close": "17213.6"
  },
  {
      "Date": "2/8/22",
      "Open": "17279.85",
      "High": "17306.45",
      "Low": "17043.65",
      "Close": "17266.75"
  },
  {
      "Date": "2/9/22",
      "Open": "17370.1",
      "High": "17477.15",
      "Low": "17339",
      "Close": "17463.8"
  },
  {
      "Date": "2/10/22",
      "Open": "17554.1",
      "High": "17639.45",
      "Low": "17427.15",
      "Close": "17605.85"
  },
  {
      "Date": "2/11/22",
      "Open": "17451",
      "High": "17454.75",
      "Low": "17303",
      "Close": "17374.75"
  },
  {
      "Date": "2/14/22",
      "Open": "17076.15",
      "High": "17099.5",
      "Low": "16809.65",
      "Close": "16842.8"
  },
  {
      "Date": "2/15/22",
      "Open": "16933.25",
      "High": "17375",
      "Low": "16839.25",
      "Close": "17352.45"
  },
  {
      "Date": "2/16/22",
      "Open": "17408.45",
      "High": "17490.6",
      "Low": "17257.7",
      "Close": "17322.2"
  },
  {
      "Date": "2/17/22",
      "Open": "17396.55",
      "High": "17442.9",
      "Low": "17235.85",
      "Close": "17304.6"
  },
  {
      "Date": "2/18/22",
      "Open": "17236.05",
      "High": "17380.8",
      "Low": "17219.2",
      "Close": "17276.3"
  },
  {
      "Date": "2/21/22",
      "Open": "17192.25",
      "High": "17351.05",
      "Low": "17070.7",
      "Close": "17206.65"
  },
  {
      "Date": "2/22/22",
      "Open": "16847.95",
      "High": "17148.55",
      "Low": "16843.8",
      "Close": "17092.2"
  },
  {
      "Date": "2/23/22",
      "Open": "17194.5",
      "High": "17220.7",
      "Low": "17027.85",
      "Close": "17063.25"
  },
  {
      "Date": "2/24/22",
      "Open": "16548.9",
      "High": "16705.25",
      "Low": "16203.25",
      "Close": "16247.95"
  },
  {
      "Date": "2/25/22",
      "Open": "16515.65",
      "High": "16748.8",
      "Low": "16478.3",
      "Close": "16658.4"
  },
  {
      "Date": "2/28/22",
      "Open": "16481.6",
      "High": "16815.9",
      "Low": "16356.3",
      "Close": "16793.9"
  },
  {
      "Date": "3/2/22",
      "Open": "16593.1",
      "High": "16678.5",
      "Low": "16478.65",
      "Close": "16605.95"
  },
  {
      "Date": "3/3/22",
      "Open": "16723.2",
      "High": "16768.95",
      "Low": "16442.95",
      "Close": "16498.05"
  },
  {
      "Date": "3/4/22",
      "Open": "16339.45",
      "High": "16456",
      "Low": "16133.8",
      "Close": "16245.35"
  },
  {
      "Date": "3/7/22",
      "Open": "15867.95",
      "High": "15944.6",
      "Low": "15711.45",
      "Close": "15863.15"
  },
  {
      "Date": "3/8/22",
      "Open": "15747.75",
      "High": "16028.75",
      "Low": "15671.45",
      "Close": "16013.45"
  },
  {
      "Date": "3/9/22",
      "Open": "16078",
      "High": "16418.05",
      "Low": "15990",
      "Close": "16345.35"
  },
  {
      "Date": "3/10/22",
      "Open": "16757.1",
      "High": "16757.3",
      "Low": "16447.9",
      "Close": "16594.9"
  },
  {
      "Date": "3/11/22",
      "Open": "16528.8",
      "High": "16694.4",
      "Low": "16470.9",
      "Close": "16630.45"
  },
  {
      "Date": "3/14/22",
      "Open": "16633.7",
      "High": "16887.95",
      "Low": "16606.5",
      "Close": "16871.3"
  },
  {
      "Date": "3/15/22",
      "Open": "16900.65",
      "High": "16927.75",
      "Low": "16555",
      "Close": "16663"
  },
  {
      "Date": "3/16/22",
      "Open": "16876.65",
      "High": "16987.9",
      "Low": "16837.85",
      "Close": "16975.35"
  },
  {
      "Date": "3/17/22",
      "Open": "17202.9",
      "High": "17344.6",
      "Low": "17175.75",
      "Close": "17287.05"
  },
  {
      "Date": "3/21/22",
      "Open": "17329.5",
      "High": "17353.35",
      "Low": "17096.4",
      "Close": "17117.6"
  },
  {
      "Date": "3/22/22",
      "Open": "17120.4",
      "High": "17334.4",
      "Low": "17006.3",
      "Close": "17315.5"
  },
  {
      "Date": "3/23/22",
      "Open": "17405.05",
      "High": "17442.4",
      "Low": "17199.6",
      "Close": "17245.65"
  },
  {
      "Date": "3/24/22",
      "Open": "17094.95",
      "High": "17291.75",
      "Low": "17091.15",
      "Close": "17222.75"
  },
  {
      "Date": "3/25/22",
      "Open": "17289",
      "High": "17294.9",
      "Low": "17076.55",
      "Close": "17153"
  },
  {
      "Date": "3/28/22",
      "Open": "17181.85",
      "High": "17235.1",
      "Low": "17003.9",
      "Close": "17222"
  },
  {
      "Date": "3/29/22",
      "Open": "17297.2",
      "High": "17343.65",
      "Low": "17235.7",
      "Close": "17325.3"
  },
  {
      "Date": "3/30/22",
      "Open": "17468.15",
      "High": "17522.5",
      "Low": "17387.2",
      "Close": "17498.25"
  },
  {
      "Date": "3/31/22",
      "Open": "17519.2",
      "High": "17559.8",
      "Low": "17435.2",
      "Close": "17464.75"
  },
  {
      "Date": "4/1/22",
      "Open": "17436.9",
      "High": "17703.7",
      "Low": "17422.7",
      "Close": "17670.45"
  },
  {
      "Date": "4/4/22",
      "Open": "17809.1",
      "High": "18114.65",
      "Low": "17791.4",
      "Close": "18053.4"
  },
  {
      "Date": "4/5/22",
      "Open": "18080.6",
      "High": "18095.45",
      "Low": "17921.55",
      "Close": "17957.4"
  },
  {
      "Date": "4/6/22",
      "Open": "17842.75",
      "High": "17901",
      "Low": "17779.85",
      "Close": "17807.65"
  },
  {
      "Date": "4/7/22",
      "Open": "17723.3",
      "High": "17787.5",
      "Low": "17623.7",
      "Close": "17639.55"
  },
  {
      "Date": "4/8/22",
      "Open": "17698.15",
      "High": "17842.75",
      "Low": "17600.55",
      "Close": "17784.35"
  },
  {
      "Date": "4/11/22",
      "Open": "17740.9",
      "High": "17779.05",
      "Low": "17650.95",
      "Close": "17674.95"
  },
  {
      "Date": "4/12/22",
      "Open": "17584.85",
      "High": "17595.3",
      "Low": "17442.35",
      "Close": "17530.3"
  },
  {
      "Date": "4/13/22",
      "Open": "17599.9",
      "High": "17663.65",
      "Low": "17457.4",
      "Close": "17475.65"
  },
  {
      "Date": "4/18/22",
      "Open": "17183.45",
      "High": "17237.75",
      "Low": "17067.85",
      "Close": "17173.65"
  },
  {
      "Date": "4/19/22",
      "Open": "17258.95",
      "High": "17275.65",
      "Low": "16824.7",
      "Close": "16958.65"
  },
  {
      "Date": "4/20/22",
      "Open": "17045.25",
      "High": "17186.9",
      "Low": "16978.95",
      "Close": "17136.55"
  },
  {
      "Date": "4/21/22",
      "Open": "17234.6",
      "High": "17414.7",
      "Low": "17215.5",
      "Close": "17392.6"
  },
  {
      "Date": "4/22/22",
      "Open": "17242.75",
      "High": "17315.3",
      "Low": "17149.2",
      "Close": "17171.95"
  },
  {
      "Date": "4/25/22",
      "Open": "17009.05",
      "High": "17054.3",
      "Low": "16888.7",
      "Close": "16953.95"
  },
  {
      "Date": "4/26/22",
      "Open": "17121.3",
      "High": "17223.85",
      "Low": "17064.45",
      "Close": "17200.8"
  },
  {
      "Date": "4/27/22",
      "Open": "17073.35",
      "High": "17110.7",
      "Low": "16958.45",
      "Close": "17038.4"
  },
  {
      "Date": "4/28/22",
      "Open": "17189.5",
      "High": "17322.5",
      "Low": "17071.05",
      "Close": "17245.05"
  },
  {
      "Date": "4/29/22",
      "Open": "17329.25",
      "High": "17377.65",
      "Low": "17053.25",
      "Close": "17102.55"
  },
  {
      "Date": "5/2/22",
      "Open": "16924.45",
      "High": "17092.25",
      "Low": "16917.25",
      "Close": "17069.1"
  },
  {
      "Date": "5/4/22",
      "Open": "17096.6",
      "High": "17132.85",
      "Low": "16623.95",
      "Close": "16677.6"
  },
  {
      "Date": "5/5/22",
      "Open": "16854.75",
      "High": "16945.7",
      "Low": "16651.85",
      "Close": "16682.65"
  },
  {
      "Date": "5/6/22",
      "Open": "16415.55",
      "High": "16484.2",
      "Low": "16340.9",
      "Close": "16411.25"
  },
  {
      "Date": "5/9/22",
      "Open": "16227.7",
      "High": "16403.7",
      "Low": "16142.1",
      "Close": "16301.85"
  },
  {
      "Date": "5/10/22",
      "Open": "16248.9",
      "High": "16404.55",
      "Low": "16197.3",
      "Close": "16240.05"
  },
  {
      "Date": "5/11/22",
      "Open": "16270.05",
      "High": "16318.75",
      "Low": "15992.6",
      "Close": "16167.1"
  },
  {
      "Date": "5/12/22",
      "Open": "16021.1",
      "High": "16041.95",
      "Low": "15735.75",
      "Close": "15808"
  },
  {
      "Date": "5/13/22",
      "Open": "15977",
      "High": "16083.6",
      "Low": "15740.85",
      "Close": "15782.15"
  },
  {
      "Date": "5/16/22",
      "Open": "15845.1",
      "High": "15977.95",
      "Low": "15739.65",
      "Close": "15842.3"
  },
  {
      "Date": "5/17/22",
      "Open": "15912.6",
      "High": "16284.25",
      "Low": "15900.8",
      "Close": "16259.3"
  },
  {
      "Date": "5/18/22",
      "Open": "16318.15",
      "High": "16399.8",
      "Low": "16211.2",
      "Close": "16240.3"
  },
  {
      "Date": "5/19/22",
      "Open": "15917.4",
      "High": "15984.75",
      "Low": "15775.2",
      "Close": "15809.4"
  },
  {
      "Date": "5/20/22",
      "Open": "16043.8",
      "High": "16283.05",
      "Low": "16003.85",
      "Close": "16266.15"
  },
  {
      "Date": "5/23/22",
      "Open": "16290.95",
      "High": "16414.7",
      "Low": "16185.75",
      "Close": "16214.7"
  },
  {
      "Date": "5/24/22",
      "Open": "16225.55",
      "High": "16262.8",
      "Low": "16078.6",
      "Close": "16125.15"
  },
  {
      "Date": "5/25/22",
      "Open": "16196.35",
      "High": "16223.35",
      "Low": "16006.95",
      "Close": "16025.8"
  },
  {
      "Date": "5/26/22",
      "Open": "16105",
      "High": "16204.45",
      "Low": "15903.7",
      "Close": "16170.15"
  },
  {
      "Date": "5/27/22",
      "Open": "16296.6",
      "High": "16370.6",
      "Low": "16221.95",
      "Close": "16352.45"
  },
  {
      "Date": "5/30/22",
      "Open": "16527.9",
      "High": "16695.5",
      "Low": "16506.15",
      "Close": "16661.4"
  },
  {
      "Date": "5/31/22",
      "Open": "16578.45",
      "High": "16690.75",
      "Low": "16521.9",
      "Close": "16584.55"
  },
  {
      "Date": "6/1/22",
      "Open": "16594.4",
      "High": "16649.2",
      "Low": "16438.85",
      "Close": "16522.75"
  },
  {
      "Date": "6/2/22",
      "Open": "16481.65",
      "High": "16646.4",
      "Low": "16443.05",
      "Close": "16628"
  },
  {
      "Date": "6/3/22",
      "Open": "16761.65",
      "High": "16793.85",
      "Low": "16567.9",
      "Close": "16584.3"
  },
  {
      "Date": "6/6/22",
      "Open": "16530.7",
      "High": "16610.95",
      "Low": "16444.55",
      "Close": "16569.55"
  },
  {
      "Date": "6/7/22",
      "Open": "16469.6",
      "High": "16487.25",
      "Low": "16347.1",
      "Close": "16416.35"
  },
  {
      "Date": "6/8/22",
      "Open": "16474.95",
      "High": "16514.3",
      "Low": "16293.35",
      "Close": "16356.25"
  },
  {
      "Date": "6/9/22",
      "Open": "16263.85",
      "High": "16492.8",
      "Low": "16243.85",
      "Close": "16478.1"
  },
  {
      "Date": "6/10/22",
      "Open": "16283.95",
      "High": "16324.7",
      "Low": "16172.6",
      "Close": "16201.8"
  },
  {
      "Date": "6/13/22",
      "Open": "15877.55",
      "High": "15886.15",
      "Low": "15684",
      "Close": "15774.4"
  },
  {
      "Date": "6/14/22",
      "Open": "15674.25",
      "High": "15858",
      "Low": "15659.45",
      "Close": "15732.1"
  },
  {
      "Date": "6/15/22",
      "Open": "15729.25",
      "High": "15783.65",
      "Low": "15678.9",
      "Close": "15692.15"
  },
  {
      "Date": "6/16/22",
      "Open": "15832.25",
      "High": "15863.15",
      "Low": "15335.1",
      "Close": "15360.6"
  },
  {
      "Date": "6/17/22",
      "Open": "15272.65",
      "High": "15400.4",
      "Low": "15183.4",
      "Close": "15293.5"
  },
  {
      "Date": "6/20/22",
      "Open": "15334.5",
      "High": "15382.5",
      "Low": "15191.1",
      "Close": "15350.15"
  },
  {
      "Date": "6/21/22",
      "Open": "15455.95",
      "High": "15707.25",
      "Low": "15419.85",
      "Close": "15638.8"
  },
  {
      "Date": "6/22/22",
      "Open": "15545.65",
      "High": "15565.4",
      "Low": "15385.95",
      "Close": "15413.3"
  },
  {
      "Date": "6/23/22",
      "Open": "15451.55",
      "High": "15628.45",
      "Low": "15367.5",
      "Close": "15556.65"
  },
  {
      "Date": "6/24/22",
      "Open": "15657.4",
      "High": "15749.25",
      "Low": "15619.45",
      "Close": "15699.25"
  },
  {
      "Date": "6/27/22",
      "Open": "15926.2",
      "High": "15927.45",
      "Low": "15815.5",
      "Close": "15832.05"
  },
  {
      "Date": "6/28/22",
      "Open": "15757.45",
      "High": "15892.1",
      "Low": "15710.15",
      "Close": "15850.2"
  },
  {
      "Date": "6/29/22",
      "Open": "15701.7",
      "High": "15861.6",
      "Low": "15687.8",
      "Close": "15799.1"
  },
  {
      "Date": "6/30/22",
      "Open": "15774.5",
      "High": "15890",
      "Low": "15728.85",
      "Close": "15780.25"
  },
  {
      "Date": "7/1/22",
      "Open": "15703.7",
      "High": "15793.95",
      "Low": "15511.05",
      "Close": "15752.05"
  },
  {
      "Date": "7/4/22",
      "Open": "15710.5",
      "High": "15852.35",
      "Low": "15661.8",
      "Close": "15835.35"
  },
  {
      "Date": "7/5/22",
      "Open": "15909.15",
      "High": "16025.75",
      "Low": "15785.45",
      "Close": "15810.85"
  },
  {
      "Date": "7/6/22",
      "Open": "15818.2",
      "High": "16011.35",
      "Low": "15800.9",
      "Close": "15989.8"
  },
  {
      "Date": "7/7/22",
      "Open": "16113.75",
      "High": "16150.5",
      "Low": "16045.95",
      "Close": "16132.9"
  },
  {
      "Date": "7/8/22",
      "Open": "16273.65",
      "High": "16275.5",
      "Low": "16157.9",
      "Close": "16220.6"
  },
  {
      "Date": "7/11/22",
      "Open": "16136.15",
      "High": "16248.55",
      "Low": "16115.5",
      "Close": "16216"
  },
  {
      "Date": "7/12/22",
      "Open": "16126.2",
      "High": "16158.75",
      "Low": "16031.15",
      "Close": "16058.3"
  },
  {
      "Date": "7/13/22",
      "Open": "16128.2",
      "High": "16140",
      "Low": "15950.15",
      "Close": "15966.65"
  },
  {
      "Date": "7/14/22",
      "Open": "16018.85",
      "High": "16070.85",
      "Low": "15858.2",
      "Close": "15938.65"
  },
  {
      "Date": "7/15/22",
      "Open": "16010.8",
      "High": "16066.95",
      "Low": "15927.3",
      "Close": "16049.2"
  },
  {
      "Date": "7/18/22",
      "Open": "16151.4",
      "High": "16287.95",
      "Low": "16142.2",
      "Close": "16278.5"
  },
  {
      "Date": "7/19/22",
      "Open": "16187.05",
      "High": "16359.5",
      "Low": "16187.05",
      "Close": "16340.55"
  },
  {
      "Date": "7/20/22",
      "Open": "16562.8",
      "High": "16588",
      "Low": "16490.95",
      "Close": "16520.85"
  },
  {
      "Date": "7/21/22",
      "Open": "16523.55",
      "High": "16626.95",
      "Low": "16483.9",
      "Close": "16605.25"
  },
  {
      "Date": "7/22/22",
      "Open": "16661.25",
      "High": "16752.25",
      "Low": "16610.9",
      "Close": "16719.45"
  },
  {
      "Date": "7/25/22",
      "Open": "16662.55",
      "High": "16706.05",
      "Low": "16564.25",
      "Close": "16631"
  },
  {
      "Date": "7/26/22",
      "Open": "16632.9",
      "High": "16636.1",
      "Low": "16463.3",
      "Close": "16483.85"
  },
  {
      "Date": "7/27/22",
      "Open": "16475.35",
      "High": "16653.45",
      "Low": "16438.75",
      "Close": "16641.8"
  },
  {
      "Date": "7/28/22",
      "Open": "16774.85",
      "High": "16947.65",
      "Low": "16746.25",
      "Close": "16929.6"
  },
  {
      "Date": "7/29/22",
      "Open": "17079.5",
      "High": "17172.8",
      "Low": "17018.15",
      "Close": "17158.25"
  },
  {
      "Date": "8/1/22",
      "Open": "17243.2",
      "High": "17356.25",
      "Low": "17154.8",
      "Close": "17340.05"
  },
  {
      "Date": "8/2/22",
      "Open": "17310.15",
      "High": "17390.15",
      "Low": "17215.85",
      "Close": "17345.45"
  },
  {
      "Date": "8/3/22",
      "Open": "17349.25",
      "High": "17407.5",
      "Low": "17225.85",
      "Close": "17388.15"
  },
  {
      "Date": "8/4/22",
      "Open": "17463.1",
      "High": "17490.7",
      "Low": "17161.25",
      "Close": "17382"
  },
  {
      "Date": "8/5/22",
      "Open": "17423.65",
      "High": "17474.4",
      "Low": "17348.75",
      "Close": "17397.5"
  },
  {
      "Date": "8/8/22",
      "Open": "17401.5",
      "High": "17548.8",
      "Low": "17359.75",
      "Close": "17525.1"
  },
  {
      "Date": "8/10/22",
      "Open": "17566.1",
      "High": "17566.1",
      "Low": "17442.8",
      "Close": "17534.75"
  },
  {
      "Date": "8/11/22",
      "Open": "17711.65",
      "High": "17719.3",
      "Low": "17631.95",
      "Close": "17659"
  },
  {
      "Date": "8/12/22",
      "Open": "17659.65",
      "High": "17724.65",
      "Low": "17597.85",
      "Close": "17698.15"
  },
  {
      "Date": "8/16/22",
      "Open": "17797.2",
      "High": "17839.1",
      "Low": "17764.05",
      "Close": "17825.25"
  },
  {
      "Date": "8/17/22",
      "Open": "17868.15",
      "High": "17965.95",
      "Low": "17833.35",
      "Close": "17944.25"
  },
  {
      "Date": "8/18/22",
      "Open": "17898.65",
      "High": "17968.45",
      "Low": "17852.05",
      "Close": "17956.5"
  },
  {
      "Date": "8/19/22",
      "Open": "17966.55",
      "High": "17992.2",
      "Low": "17710.75",
      "Close": "17758.45"
  },
  {
      "Date": "8/22/22",
      "Open": "17682.9",
      "High": "17690.05",
      "Low": "17467.35",
      "Close": "17490.7"
  },
  {
      "Date": "8/23/22",
      "Open": "17357.35",
      "High": "17625.55",
      "Low": "17345.2",
      "Close": "17577.5"
  },
  {
      "Date": "8/24/22",
      "Open": "17525.45",
      "High": "17623.65",
      "Low": "17499.25",
      "Close": "17604.95"
  },
  {
      "Date": "8/25/22",
      "Open": "17679",
      "High": "17726.5",
      "Low": "17487.45",
      "Close": "17522.45"
  },
  {
      "Date": "8/26/22",
      "Open": "17619.3",
      "High": "17685.85",
      "Low": "17519.35",
      "Close": "17558.9"
  },
  {
      "Date": "8/29/22",
      "Open": "17188.65",
      "High": "17380.15",
      "Low": "17166.2",
      "Close": "17312.9"
  },
  {
      "Date": "8/30/22",
      "Open": "17414.95",
      "High": "17777.65",
      "Low": "17401.5",
      "Close": "17759.3"
  },
  {
      "Date": "9/1/22",
      "Open": "17485.7",
      "High": "17695.6",
      "Low": "17468.45",
      "Close": "17542.8"
  },
  {
      "Date": "9/2/22",
      "Open": "17598.4",
      "High": "17643.85",
      "Low": "17476.45",
      "Close": "17539.45"
  },
  {
      "Date": "9/5/22",
      "Open": "17546.45",
      "High": "17683.15",
      "Low": "17540.35",
      "Close": "17665.8"
  },
  {
      "Date": "9/6/22",
      "Open": "17695.7",
      "High": "17764.65",
      "Low": "17587.65",
      "Close": "17655.6"
  },
  {
      "Date": "9/7/22",
      "Open": "17519.4",
      "High": "17650.75",
      "Low": "17484.3",
      "Close": "17624.4"
  },
  {
      "Date": "9/8/22",
      "Open": "17748.15",
      "High": "17807.65",
      "Low": "17691.95",
      "Close": "17798.75"
  },
  {
      "Date": "9/9/22",
      "Open": "17923.35",
      "High": "17925.95",
      "Low": "17786",
      "Close": "17833.35"
  },
  {
      "Date": "9/12/22",
      "Open": "17890.85",
      "High": "17980.55",
      "Low": "17889.15",
      "Close": "17936.35"
  },
  {
      "Date": "9/13/22",
      "Open": "18044.45",
      "High": "18088.3",
      "Low": "18015.45",
      "Close": "18070.05"
  },
  {
      "Date": "9/14/22",
      "Open": "17771.15",
      "High": "18091.55",
      "Low": "17771.15",
      "Close": "18003.75"
  },
  {
      "Date": "9/15/22",
      "Open": "18046.35",
      "High": "18096.15",
      "Low": "17861.5",
      "Close": "17877.4"
  },
  {
      "Date": "9/16/22",
      "Open": "17796.8",
      "High": "17820.05",
      "Low": "17497.25",
      "Close": "17530.85"
  },
  {
      "Date": "9/19/22",
      "Open": "17540.65",
      "High": "17667.2",
      "Low": "17429.7",
      "Close": "17622.25"
  },
  {
      "Date": "9/20/22",
      "Open": "17770.4",
      "High": "17919.3",
      "Low": "17744.4",
      "Close": "17816.25"
  },
  {
      "Date": "9/21/22",
      "Open": "17766.35",
      "High": "17838.7",
      "Low": "17663.6",
      "Close": "17718.35"
  },
  {
      "Date": "9/22/22",
      "Open": "17609.65",
      "High": "17722.75",
      "Low": "17532.45",
      "Close": "17629.8"
  },
  {
      "Date": "9/23/22",
      "Open": "17593.85",
      "High": "17642.15",
      "Low": "17291.65",
      "Close": "17327.35"
  },
  {
      "Date": "9/26/22",
      "Open": "17156.3",
      "High": "17196.4",
      "Low": "16978.3",
      "Close": "17016.3"
  },
  {
      "Date": "9/27/22",
      "Open": "17110.9",
      "High": "17176.45",
      "Low": "16942.35",
      "Close": "17007.4"
  },
  {
      "Date": "9/28/22",
      "Open": "16870.55",
      "High": "17037.6",
      "Low": "16820.4",
      "Close": "16858.6"
  },
  {
      "Date": "9/29/22",
      "Open": "16993.6",
      "High": "17026.05",
      "Low": "16788.6",
      "Close": "16818.1"
  },
  {
      "Date": "9/30/22",
      "Open": "16798.05",
      "High": "17187.1",
      "Low": "16747.7",
      "Close": "17094.35"
  },
  {
      "Date": "10/3/22",
      "Open": "17102.1",
      "High": "17114.65",
      "Low": "16855.55",
      "Close": "16887.35"
  },
  {
      "Date": "10/4/22",
      "Open": "17147.45",
      "High": "17287.3",
      "Low": "17117.3",
      "Close": "17274.3"
  },
  {
      "Date": "10/6/22",
      "Open": "17379.25",
      "High": "17428.8",
      "Low": "17315.65",
      "Close": "17331.8"
  },
  {
      "Date": "10/7/22",
      "Open": "17287.2",
      "High": "17337.35",
      "Low": "17216.95",
      "Close": "17314.65"
  },
  {
      "Date": "10/10/22",
      "Open": "17094.35",
      "High": "17280.15",
      "Low": "17064.7",
      "Close": "17241"
  },
  {
      "Date": "10/11/22",
      "Open": "17256.05",
      "High": "17261.8",
      "Low": "16950.3",
      "Close": "16983.55"
  },
  {
      "Date": "10/12/22",
      "Open": "17025.55",
      "High": "17142.35",
      "Low": "16960.05",
      "Close": "17123.6"
  },
  {
      "Date": "10/13/22",
      "Open": "17087.35",
      "High": "17112.35",
      "Low": "16956.95",
      "Close": "17014.35"
  },
  {
      "Date": "10/14/22",
      "Open": "17322.3",
      "High": "17348.55",
      "Low": "17169.75",
      "Close": "17185.7"
  },
  {
      "Date": "10/17/22",
      "Open": "17144.8",
      "High": "17328.55",
      "Low": "17098.55",
      "Close": "17311.8"
  },
  {
      "Date": "10/18/22",
      "Open": "17438.75",
      "High": "17527.8",
      "Low": "17434.05",
      "Close": "17486.95"
  },
  {
      "Date": "10/19/22",
      "Open": "17568.15",
      "High": "17607.6",
      "Low": "17472.85",
      "Close": "17512.25"
  },
  {
      "Date": "10/20/22",
      "Open": "17423.1",
      "High": "17584.15",
      "Low": "17421",
      "Close": "17563.95"
  },
  {
      "Date": "10/21/22",
      "Open": "17622.85",
      "High": "17670.15",
      "Low": "17520.75",
      "Close": "17576.3"
  },
  {
      "Date": "10/24/22",
      "Open": "17736.35",
      "High": "17777.55",
      "Low": "17707.4",
      "Close": "17730.75"
  },
  {
      "Date": "10/25/22",
      "Open": "17808.3",
      "High": "17811.5",
      "Low": "17637",
      "Close": "17656.35"
  },
  {
      "Date": "10/27/22",
      "Open": "17771.4",
      "High": "17783.9",
      "Low": "17654.5",
      "Close": "17736.95"
  },
  {
      "Date": "10/28/22",
      "Open": "17756.4",
      "High": "17838.9",
      "Low": "17723.7",
      "Close": "17786.8"
  },
  {
      "Date": "10/31/22",
      "Open": "17910.2",
      "High": "18022.8",
      "Low": "17899.9",
      "Close": "18012.2"
  },
  {
      "Date": "11/1/22",
      "Open": "18130.7",
      "High": "18175.8",
      "Low": "18060.15",
      "Close": "18145.4"
  },
  {
      "Date": "11/2/22",
      "Open": "18177.9",
      "High": "18178.75",
      "Low": "18048.65",
      "Close": "18082.85"
  },
  {
      "Date": "11/3/22",
      "Open": "17968.35",
      "High": "18106.3",
      "Low": "17959.2",
      "Close": "18052.7"
  },
  {
      "Date": "11/4/22",
      "Open": "18053.4",
      "High": "18135.1",
      "Low": "18017.15",
      "Close": "18117.15"
  },
  {
      "Date": "11/7/22",
      "Open": "18211.75",
      "High": "18255.5",
      "Low": "18064.75",
      "Close": "18202.8"
  },
  {
      "Date": "11/9/22",
      "Open": "18288.25",
      "High": "18296.4",
      "Low": "18117.5",
      "Close": "18157"
  },
  {
      "Date": "11/10/22",
      "Open": "18044.35",
      "High": "18103.1",
      "Low": "17969.4",
      "Close": "18028.2"
  },
  {
      "Date": "11/11/22",
      "Open": "18272.35",
      "High": "18362.3",
      "Low": "18259.35",
      "Close": "18349.7"
  },
  {
      "Date": "11/14/22",
      "Open": "18376.4",
      "High": "18399.45",
      "Low": "18311.4",
      "Close": "18329.15"
  },
  {
      "Date": "11/15/22",
      "Open": "18362.75",
      "High": "18427.95",
      "Low": "18282",
      "Close": "18403.4"
  },
  {
      "Date": "11/16/22",
      "Open": "18398.25",
      "High": "18442.15",
      "Low": "18344.15",
      "Close": "18409.65"
  },
  {
      "Date": "11/17/22",
      "Open": "18358.7",
      "High": "18417.6",
      "Low": "18312.95",
      "Close": "18343.9"
  },
  {
      "Date": "11/18/22",
      "Open": "18382.95",
      "High": "18394.6",
      "Low": "18209.8",
      "Close": "18307.65"
  },
  {
      "Date": "11/21/22",
      "Open": "18246.4",
      "High": "18262.3",
      "Low": "18133.35",
      "Close": "18159.95"
  },
  {
      "Date": "11/22/22",
      "Open": "18179.15",
      "High": "18261.85",
      "Low": "18137.7",
      "Close": "18244.2"
  },
  {
      "Date": "11/23/22",
      "Open": "18325.2",
      "High": "18325.4",
      "Low": "18246",
      "Close": "18267.25"
  },
  {
      "Date": "11/24/22",
      "Open": "18326.1",
      "High": "18529.7",
      "Low": "18294.25",
      "Close": "18484.1"
  },
  {
      "Date": "11/25/22",
      "Open": "18528.45",
      "High": "18534.9",
      "Low": "18445.1",
      "Close": "18512.75"
  },
  {
      "Date": "11/28/22",
      "Open": "18430.55",
      "High": "18614.25",
      "Low": "18365.6",
      "Close": "18562.75"
  },
  {
      "Date": "11/29/22",
      "Open": "18552.45",
      "High": "18678.1",
      "Low": "18552.15",
      "Close": "18618.05"
  },
  {
      "Date": "11/30/22",
      "Open": "18625.7",
      "High": "18816.05",
      "Low": "18616.55",
      "Close": "18758.35"
  },
  {
      "Date": "12/1/22",
      "Open": "18871.95",
      "High": "18887.6",
      "Low": "18778.2",
      "Close": "18812.5"
  },
  {
      "Date": "12/2/22",
      "Open": "18752.4",
      "High": "18781.95",
      "Low": "18639.2",
      "Close": "18696.1"
  },
  {
      "Date": "12/5/22",
      "Open": "18719.55",
      "High": "18728.6",
      "Low": "18591.35",
      "Close": "18701.05"
  },
  {
      "Date": "12/6/22",
      "Open": "18600.65",
      "High": "18654.9",
      "Low": "18577.9",
      "Close": "18642.75"
  },
  {
      "Date": "12/7/22",
      "Open": "18638.85",
      "High": "18668.3",
      "Low": "18528.4",
      "Close": "18560.5"
  },
  {
      "Date": "12/8/22",
      "Open": "18570.85",
      "High": "18625",
      "Low": "18536.95",
      "Close": "18609.35"
  },
  {
      "Date": "12/9/22",
      "Open": "18662.4",
      "High": "18664.7",
      "Low": "18410.1",
      "Close": "18496.6"
  },
  {
      "Date": "12/12/22",
      "Open": "18402.15",
      "High": "18521.55",
      "Low": "18345.7",
      "Close": "18497.15"
  },
  {
      "Date": "12/13/22",
      "Open": "18524.4",
      "High": "18617.25",
      "Low": "18490.2",
      "Close": "18608"
  },
  {
      "Date": "12/14/22",
      "Open": "18671.25",
      "High": "18696.1",
      "Low": "18632.9",
      "Close": "18660.3"
  },
  {
      "Date": "12/15/22",
      "Open": "18614.4",
      "High": "18652.9",
      "Low": "18387.7",
      "Close": "18414.9"
  },
  {
      "Date": "12/16/22",
      "Open": "18319.1",
      "High": "18440.95",
      "Low": "18255.15",
      "Close": "18269"
  },
  {
      "Date": "12/19/22",
      "Open": "18288.1",
      "High": "18431.65",
      "Low": "18244.55",
      "Close": "18420.45"
  },
  {
      "Date": "12/20/22",
      "Open": "18340.3",
      "High": "18404.9",
      "Low": "18202.65",
      "Close": "18385.3"
  },
  {
      "Date": "12/21/22",
      "Open": "18435.15",
      "High": "18473.35",
      "Low": "18162.75",
      "Close": "18199.1"
  },
  {
      "Date": "12/22/22",
      "Open": "18288.8",
      "High": "18318.75",
      "Low": "18068.6",
      "Close": "18127.35"
  },
  {
      "Date": "12/23/22",
      "Open": "17977.65",
      "High": "18050.45",
      "Low": "17779.5",
      "Close": "17806.8"
  },
  {
      "Date": "12/26/22",
      "Open": "17830.4",
      "High": "18084.1",
      "Low": "17774.25",
      "Close": "18014.6"
  },
  {
      "Date": "12/27/22",
      "Open": "18089.8",
      "High": "18149.25",
      "Low": "17967.45",
      "Close": "18132.3"
  },
  {
      "Date": "12/28/22",
      "Open": "18084.75",
      "High": "18173.1",
      "Low": "18068.35",
      "Close": "18122.5"
  },
  {
      "Date": "12/29/22",
      "Open": "18045.7",
      "High": "18229.7",
      "Low": "17992.8",
      "Close": "18191"
  },
  {
      "Date": "12/30/22",
      "Open": "18259.1",
      "High": "18265.25",
      "Low": "18080.3",
      "Close": "18105.3"
  },
  {
      "Date": "1/2/23",
      "Open": "18131.7",
      "High": "18215.15",
      "Low": "18086.5",
      "Close": "18197.45"
  },
  {
      "Date": "1/3/23",
      "Open": "18163.2",
      "High": "18251.95",
      "Low": "18149.8",
      "Close": "18232.55"
  },
  {
      "Date": "1/4/23",
      "Open": "18230.65",
      "High": "18243",
      "Low": "18020.6",
      "Close": "18042.95"
  },
  {
      "Date": "1/5/23",
      "Open": "18101.95",
      "High": "18120.3",
      "Low": "17892.6",
      "Close": "17992.15"
  },
  {
      "Date": "1/6/23",
      "Open": "18008.05",
      "High": "18047.4",
      "Low": "17795.55",
      "Close": "17859.45"
  },
  {
      "Date": "1/9/23",
      "Open": "17952.55",
      "High": "18141.4",
      "Low": "17936.15",
      "Close": "18101.2"
  },
  {
      "Date": "1/10/23",
      "Open": "18121.3",
      "High": "18127.6",
      "Low": "17856",
      "Close": "17914.15"
  },
  {
      "Date": "1/11/23",
      "Open": "17924.25",
      "High": "17976.35",
      "Low": "17824.35",
      "Close": "17895.7"
  },
  {
      "Date": "1/12/23",
      "Open": "17920.85",
      "High": "17945.8",
      "Low": "17761.65",
      "Close": "17858.2"
  },
  {
      "Date": "1/13/23",
      "Open": "17867.5",
      "High": "17999.35",
      "Low": "17774.25",
      "Close": "17956.6"
  },
  {
      "Date": "1/16/23",
      "Open": "18033.15",
      "High": "18049.65",
      "Low": "17853.65",
      "Close": "17894.85"
  },
  {
      "Date": "1/17/23",
      "Open": "17922.8",
      "High": "18072.05",
      "Low": "17886.95",
      "Close": "18053.3"
  },
  {
      "Date": "1/18/23",
      "Open": "18074.3",
      "High": "18183.75",
      "Low": "18032.45",
      "Close": "18165.35"
  },
  {
      "Date": "1/19/23",
      "Open": "18119.8",
      "High": "18155.2",
      "Low": "18063.75",
      "Close": "18107.85"
  },
  {
      "Date": "1/20/23",
      "Open": "18115.6",
      "High": "18145.45",
      "Low": "18016.2",
      "Close": "18027.65"
  },
  {
      "Date": "1/23/23",
      "Open": "18118.45",
      "High": "18162.6",
      "Low": "18063.45",
      "Close": "18118.55"
  },
  {
      "Date": "1/24/23",
      "Open": "18183.95",
      "High": "18201.25",
      "Low": "18078.65",
      "Close": "18118.3"
  },
  {
      "Date": "1/25/23",
      "Open": "18093.35",
      "High": "18100.6",
      "Low": "17846.15",
      "Close": "17891.95"
  },
  {
      "Date": "1/27/23",
      "Open": "17877.2",
      "High": "17884.75",
      "Low": "17493.55",
      "Close": "17604.35"
  },
  {
      "Date": "1/30/23",
      "Open": "17541.95",
      "High": "17709.15",
      "Low": "17405.55",
      "Close": "17648.95"
  },
  {
      "Date": "1/31/23",
      "Open": "17731.45",
      "High": "17735.7",
      "Low": "17537.55",
      "Close": "17662.15"
  },
  {
      "Date": "2/1/23",
      "Open": "17811.6",
      "High": "17972.2",
      "Low": "17353.4",
      "Close": "17616.3"
  },
  {
      "Date": "2/2/23",
      "Open": "17517.1",
      "High": "17653.9",
      "Low": "17445.95",
      "Close": "17610.4"
  },
  {
      "Date": "2/3/23",
      "Open": "17721.75",
      "High": "17870.3",
      "Low": "17584.2",
      "Close": "17854.05"
  },
  {
      "Date": "2/6/23",
      "Open": "17818.55",
      "High": "17823.7",
      "Low": "17698.35",
      "Close": "17764.6"
  },
  {
      "Date": "2/7/23",
      "Open": "17790.1",
      "High": "17811.15",
      "Low": "17652.55",
      "Close": "17721.5"
  },
  {
      "Date": "2/8/23",
      "Open": "17750.3",
      "High": "17898.7",
      "Low": "17744.15",
      "Close": "17871.7"
  },
  {
      "Date": "2/9/23",
      "Open": "17885.5",
      "High": "17916.9",
      "Low": "17779.8",
      "Close": "17893.45"
  },
  {
      "Date": "2/10/23",
      "Open": "17847.55",
      "High": "17876.95",
      "Low": "17801",
      "Close": "17856.5"
  },
  {
      "Date": "2/13/23",
      "Open": "17859.1",
      "High": "17880.7",
      "Low": "17719.75",
      "Close": "17770.9"
  },
  {
      "Date": "2/14/23",
      "Open": "17840.35",
      "High": "17954.55",
      "Low": "17800.05",
      "Close": "17929.85"
  },
  {
      "Date": "2/15/23",
      "Open": "17896.6",
      "High": "18034.1",
      "Low": "17853.8",
      "Close": "18015.85"
  },
  {
      "Date": "2/16/23",
      "Open": "18094.75",
      "High": "18134.75",
      "Low": "18000.65",
      "Close": "18035.85"
  },
  {
      "Date": "2/17/23",
      "Open": "17974.85",
      "High": "18034.25",
      "Low": "17884.6",
      "Close": "17944.2"
  },
  {
      "Date": "2/20/23",
      "Open": "17965.55",
      "High": "18004.35",
      "Low": "17818.4",
      "Close": "17844.6"
  },
  {
      "Date": "2/21/23",
      "Open": "17905.8",
      "High": "17924.9",
      "Low": "17800.3",
      "Close": "17826.7"
  },
  {
      "Date": "2/22/23",
      "Open": "17755.35",
      "High": "17772.5",
      "Low": "17529.45",
      "Close": "17554.3"
  },
  {
      "Date": "2/23/23",
      "Open": "17574.65",
      "High": "17620.05",
      "Low": "17455.4",
      "Close": "17511.25"
  },
  {
      "Date": "2/24/23",
      "Open": "17591.35",
      "High": "17599.75",
      "Low": "17421.8",
      "Close": "17465.8"
  },
  {
      "Date": "2/27/23",
      "Open": "17428.6",
      "High": "17451.6",
      "Low": "17299",
      "Close": "17392.7"
  },
  {
      "Date": "2/28/23",
      "Open": "17383.25",
      "High": "17440.45",
      "Low": "17255.2",
      "Close": "17303.95"
  },
  {
      "Date": "3/1/23",
      "Open": "17360.1",
      "High": "17467.75",
      "Low": "17345.25",
      "Close": "17450.9"
  },
  {
      "Date": "3/2/23",
      "Open": "17421.5",
      "High": "17445.8",
      "Low": "17306",
      "Close": "17321.9"
  },
  {
      "Date": "3/3/23",
      "Open": "17451.25",
      "High": "17644.75",
      "Low": "17427.7",
      "Close": "17594.35"
  },
  {
      "Date": "3/6/23",
      "Open": "17680.35",
      "High": "17799.95",
      "Low": "17671.95",
      "Close": "17711.45"
  },
  {
      "Date": "3/8/23",
      "Open": "17665.75",
      "High": "17766.5",
      "Low": "17602.25",
      "Close": "17754.4"
  },
  {
      "Date": "3/9/23",
      "Open": "17772.05",
      "High": "17772.35",
      "Low": "17573.6",
      "Close": "17589.6"
  },
  {
      "Date": "3/10/23",
      "Open": "17443.8",
      "High": "17451.5",
      "Low": "17324.35",
      "Close": "17412.9"
  },
  {
      "Date": "3/13/23",
      "Open": "17421.9",
      "High": "17529.9",
      "Low": "17113.45",
      "Close": "17154.3"
  },
  {
      "Date": "3/14/23",
      "Open": "17160.55",
      "High": "17224.65",
      "Low": "16987.1",
      "Close": "17043.3"
  },
  {
      "Date": "3/15/23",
      "Open": "17166.45",
      "High": "17211.35",
      "Low": "16938.9",
      "Close": "16972.15"
  },
  {
      "Date": "3/16/23",
      "Open": "16994.65",
      "High": "17062.45",
      "Low": "16850.15",
      "Close": "16985.6"
  },
  {
      "Date": "3/17/23",
      "Open": "17111.8",
      "High": "17145.8",
      "Low": "16958.15",
      "Close": "17100.05"
  },
  {
      "Date": "3/20/23",
      "Open": "17066.6",
      "High": "17066.6",
      "Low": "16828.35",
      "Close": "16988.4"
  },
  {
      "Date": "3/21/23",
      "Open": "17060.4",
      "High": "17127.7",
      "Low": "17016",
      "Close": "17107.5"
  },
  {
      "Date": "3/22/23",
      "Open": "17177.45",
      "High": "17207.25",
      "Low": "17107.85",
      "Close": "17151.9"
  },
  {
      "Date": "3/23/23",
      "Open": "17097.4",
      "High": "17205.4",
      "Low": "17045.3",
      "Close": "17076.9"
  },
  {
      "Date": "3/24/23",
      "Open": "17076.2",
      "High": "17109.45",
      "Low": "16917.35",
      "Close": "16945.05"
  },
  {
      "Date": "3/27/23",
      "Open": "16984.3",
      "High": "17091",
      "Low": "16918.55",
      "Close": "16985.7"
  },
  {
      "Date": "3/28/23",
      "Open": "17031.75",
      "High": "17061.75",
      "Low": "16913.75",
      "Close": "16951.7"
  },
  {
      "Date": "3/29/23",
      "Open": "16977.3",
      "High": "17126.15",
      "Low": "16940.6",
      "Close": "17080.7"
  },
  {
      "Date": "3/31/23",
      "Open": "17210.35",
      "High": "17381.6",
      "Low": "17204.65",
      "Close": "17359.75"
  },
  {
      "Date": "4/3/23",
      "Open": "17427.95",
      "High": "17428.05",
      "Low": "17312.75",
      "Close": "17398.05"
  },
  {
      "Date": "4/5/23",
      "Open": "17422.3",
      "High": "17570.55",
      "Low": "17402.7",
      "Close": "17557.05"
  },
  {
      "Date": "4/6/23",
      "Open": "17533.85",
      "High": "17638.7",
      "Low": "17502.85",
      "Close": "17599.15"
  },
  {
      "Date": "4/10/23",
      "Open": "17634.9",
      "High": "17694.1",
      "Low": "17597.95",
      "Close": "17624.05"
  },
  {
      "Date": "4/11/23",
      "Open": "17704.8",
      "High": "17748.75",
      "Low": "17655.15",
      "Close": "17722.3"
  },
  {
      "Date": "4/12/23",
      "Open": "17759.55",
      "High": "17825.75",
      "Low": "17717.25",
      "Close": "17812.4"
  },
  {
      "Date": "4/13/23",
      "Open": "17807.3",
      "High": "17842.15",
      "Low": "17729.65",
      "Close": "17828"
  },
  {
      "Date": "4/17/23",
      "Open": "17863",
      "High": "17863",
      "Low": "17574.05",
      "Close": "17706.85"
  },
  {
      "Date": "4/18/23",
      "Open": "17766.6",
      "High": "17766.6",
      "Low": "17610.2",
      "Close": "17660.15"
  },
  {
      "Date": "4/19/23",
      "Open": "17653.35",
      "High": "17666.15",
      "Low": "17579.85",
      "Close": "17618.75"
  },
  {
      "Date": "4/20/23",
      "Open": "17638.6",
      "High": "17684.45",
      "Low": "17584.35",
      "Close": "17624.45"
  },
  {
      "Date": "4/21/23",
      "Open": "17639.75",
      "High": "17663.2",
      "Low": "17553.95",
      "Close": "17624.05"
  },
  {
      "Date": "4/24/23",
      "Open": "17707.55",
      "High": "17754.5",
      "Low": "17612.5",
      "Close": "17743.4"
  },
  {
      "Date": "4/25/23",
      "Open": "17761.55",
      "High": "17807.45",
      "Low": "17716.85",
      "Close": "17769.25"
  },
  {
      "Date": "4/26/23",
      "Open": "17767.3",
      "High": "17827.75",
      "Low": "17711.2",
      "Close": "17813.6"
  },
  {
      "Date": "4/27/23",
      "Open": "17813.1",
      "High": "17931.6",
      "Low": "17797.9",
      "Close": "17915.05"
  },
  {
      "Date": "4/28/23",
      "Open": "17950.4",
      "High": "18089.15",
      "Low": "17885.3",
      "Close": "18065"
  },
  {
      "Date": "5/2/23",
      "Open": "18124.8",
      "High": "18180.25",
      "Low": "18101.75",
      "Close": "18147.65"
  },
  {
      "Date": "5/3/23",
      "Open": "18113.8",
      "High": "18116.35",
      "Low": "18042.4",
      "Close": "18089.85"
  },
  {
      "Date": "5/4/23",
      "Open": "18081",
      "High": "18267.45",
      "Low": "18066.7",
      "Close": "18255.8"
  },
  {
      "Date": "5/5/23",
      "Open": "18117.3",
      "High": "18216.95",
      "Low": "18055.45",
      "Close": "18069"
  },
  {
      "Date": "5/8/23",
      "Open": "18120.6",
      "High": "18286.95",
      "Low": "18100.3",
      "Close": "18264.4"
  },
  {
      "Date": "5/9/23",
      "Open": "18303.4",
      "High": "18344.2",
      "Low": "18229.65",
      "Close": "18265.95"
  },
  {
      "Date": "5/10/23",
      "Open": "18313.6",
      "High": "18326.75",
      "Low": "18211.95",
      "Close": "18315.1"
  },
  {
      "Date": "5/11/23",
      "Open": "18357.8",
      "High": "18389.7",
      "Low": "18270.4",
      "Close": "18297"
  },
  {
      "Date": "5/12/23",
      "Open": "18273.75",
      "High": "18342.75",
      "Low": "18194.55",
      "Close": "18314.8"
  },
  {
      "Date": "5/15/23",
      "Open": "18339.3",
      "High": "18458.9",
      "Low": "18287.9",
      "Close": "18398.85"
  },
  {
      "Date": "5/16/23",
      "Open": "18432.35",
      "High": "18432.35",
      "Low": "18264.35",
      "Close": "18286.5"
  },
  {
      "Date": "5/17/23",
      "Open": "18300.45",
      "High": "18309",
      "Low": "18115.35",
      "Close": "18181.75"
  },
  {
      "Date": "5/18/23",
      "Open": "18287.5",
      "High": "18297.2",
      "Low": "18104.85",
      "Close": "18129.95"
  },
  {
      "Date": "5/19/23",
      "Open": "18186.15",
      "High": "18218.1",
      "Low": "18060.4",
      "Close": "18203.4"
  },
  {
      "Date": "5/22/23",
      "Open": "18201.1",
      "High": "18335.25",
      "Low": "18178.85",
      "Close": "18314.4"
  },
  {
      "Date": "5/23/23",
      "Open": "18362.9",
      "High": "18419.75",
      "Low": "18324.2",
      "Close": "18348"
  },
  {
      "Date": "5/24/23",
      "Open": "18294.8",
      "High": "18392.6",
      "Low": "18262.95",
      "Close": "18285.4"
  },
  {
      "Date": "5/25/23",
      "Open": "18268.9",
      "High": "18338.1",
      "Low": "18202.4",
      "Close": "18321.15"
  },
  {
      "Date": "5/26/23",
      "Open": "18368.35",
      "High": "18508.55",
      "Low": "18333.15",
      "Close": "18499.35"
  },
  {
      "Date": "5/29/23",
      "Open": "18619.15",
      "High": "18641.2",
      "Low": "18581.25",
      "Close": "18598.65"
  },
  {
      "Date": "5/30/23",
      "Open": "18606.65",
      "High": "18662.45",
      "Low": "18575.5",
      "Close": "18633.85"
  },
  {
      "Date": "5/31/23",
      "Open": "18594.2",
      "High": "18603.9",
      "Low": "18483.85",
      "Close": "18534.4"
  },
  {
      "Date": "6/1/23",
      "Open": "18579.4",
      "High": "18580.3",
      "Low": "18464.55",
      "Close": "18487.75"
  },
  {
      "Date": "6/2/23",
      "Open": "18550.85",
      "High": "18573.7",
      "Low": "18478.4",
      "Close": "18534.1"
  },
  {
      "Date": "6/5/23",
      "Open": "18612",
      "High": "18640.15",
      "Low": "18582.8",
      "Close": "18593.85"
  },
  {
      "Date": "6/6/23",
      "Open": "18600.8",
      "High": "18622.75",
      "Low": "18531.6",
      "Close": "18599"
  },
  {
      "Date": "6/7/23",
      "Open": "18665.6",
      "High": "18738.95",
      "Low": "18636",
      "Close": "18726.4"
  },
  {
      "Date": "6/8/23",
      "Open": "18725.35",
      "High": "18777.9",
      "Low": "18615.6",
      "Close": "18634.55"
  },
  {
      "Date": "6/9/23",
      "Open": "18655.9",
      "High": "18676.65",
      "Low": "18555.4",
      "Close": "18563.4"
  },
  {
      "Date": "6/12/23",
      "Open": "18595.05",
      "High": "18633.6",
      "Low": "18559.75",
      "Close": "18601.5"
  },
  {
      "Date": "6/13/23",
      "Open": "18631.8",
      "High": "18728.9",
      "Low": "18631.8",
      "Close": "18716.15"
  },
  {
      "Date": "6/14/23",
      "Open": "18744.6",
      "High": "18769.7",
      "Low": "18690",
      "Close": "18755.9"
  },
  {
      "Date": "6/15/23",
      "Open": "18774.45",
      "High": "18794.1",
      "Low": "18669.05",
      "Close": "18688.1"
  },
  {
      "Date": "6/16/23",
      "Open": "18723.3",
      "High": "18864.7",
      "Low": "18710.5",
      "Close": "18826"
  },
  {
      "Date": "6/19/23",
      "Open": "18873.3",
      "High": "18881.45",
      "Low": "18719.15",
      "Close": "18755.45"
  },
  {
      "Date": "6/20/23",
      "Open": "18752.35",
      "High": "18839.7",
      "Low": "18660.65",
      "Close": "18816.7"
  },
  {
      "Date": "6/21/23",
      "Open": "18849.4",
      "High": "18875.9",
      "Low": "18794.85",
      "Close": "18856.85"
  },
  {
      "Date": "6/22/23",
      "Open": "18853.6",
      "High": "18886.6",
      "Low": "18759.5",
      "Close": "18771.25"
  },
  {
      "Date": "6/23/23",
      "Open": "18741.85",
      "High": "18756.4",
      "Low": "18647.1",
      "Close": "18665.5"
  },
  {
      "Date": "6/26/23",
      "Open": "18682.35",
      "High": "18722.05",
      "Low": "18646.7",
      "Close": "18691.2"
  },
  {
      "Date": "6/27/23",
      "Open": "18748.55",
      "High": "18829.25",
      "Low": "18714.25",
      "Close": "18817.4"
  },
  {
      "Date": "6/28/23",
      "Open": "18908.15",
      "High": "19011.25",
      "Low": "18861.35",
      "Close": "18972.1"
  },
  {
      "Date": "6/30/23",
      "Open": "19076.85",
      "High": "19201.7",
      "Low": "19024.6",
      "Close": "19189.05"
  },
  {
      "Date": "7/3/23",
      "Open": "19246.5",
      "High": "19345.1",
      "Low": "19234.4",
      "Close": "19322.55"
  },
  {
      "Date": "7/4/23",
      "Open": "19406.6",
      "High": "19434.15",
      "Low": "19300",
      "Close": "19389"
  },
  {
      "Date": "7/5/23",
      "Open": "19405.95",
      "High": "19421.6",
      "Low": "19339.6",
      "Close": "19398.5"
  },
  {
      "Date": "7/6/23",
      "Open": "19385.7",
      "High": "19512.2",
      "Low": "19373",
      "Close": "19497.3"
  },
  {
      "Date": "7/7/23",
      "Open": "19422.8",
      "High": "19523.6",
      "Low": "19303.6",
      "Close": "19331.8"
  },
  {
      "Date": "7/10/23",
      "Open": "19400.35",
      "High": "19435.85",
      "Low": "19327.1",
      "Close": "19355.9"
  },
  {
      "Date": "7/11/23",
      "Open": "19427.1",
      "High": "19515.1",
      "Low": "19406.45",
      "Close": "19439.4"
  },
  {
      "Date": "7/12/23",
      "Open": "19497.45",
      "High": "19507.7",
      "Low": "19361.75",
      "Close": "19384.3"
  },
  {
      "Date": "7/13/23",
      "Open": "19495.2",
      "High": "19567",
      "Low": "19385.8",
      "Close": "19413.75"
  },
  {
      "Date": "7/14/23",
      "Open": "19493.45",
      "High": "19595.35",
      "Low": "19433.5",
      "Close": "19564.5"
  },
  {
      "Date": "7/17/23",
      "Open": "19612.15",
      "High": "19731.85",
      "Low": "19562.95",
      "Close": "19711.45"
  },
  {
      "Date": "7/18/23",
      "Open": "19787.5",
      "High": "19819.45",
      "Low": "19690.2",
      "Close": "19749.25"
  },
  {
      "Date": "7/19/23",
      "Open": "19802.95",
      "High": "19851.7",
      "Low": "19727.45",
      "Close": "19833.15"
  },
  {
      "Date": "7/20/23",
      "Open": "19831.7",
      "High": "19991.85",
      "Low": "19758.4",
      "Close": "19979.15"
  },
  {
      "Date": "7/21/23",
      "Open": "19800.45",
      "High": "19887.4",
      "Low": "19700",
      "Close": "19745"
  },
  {
      "Date": "7/24/23",
      "Open": "19748.45",
      "High": "19782.75",
      "Low": "19658.3",
      "Close": "19672.35"
  },
  {
      "Date": "7/25/23",
      "Open": "19729.35",
      "High": "19729.35",
      "Low": "19615.95",
      "Close": "19680.6"
  },
  {
      "Date": "7/26/23",
      "Open": "19733.35",
      "High": "19825.6",
      "Low": "19716.7",
      "Close": "19778.3"
  },
  {
      "Date": "7/27/23",
      "Open": "19850.9",
      "High": "19867.55",
      "Low": "19603.55",
      "Close": "19659.9"
  },
  {
      "Date": "7/28/23",
      "Open": "19659.75",
      "High": "19695.9",
      "Low": "19563.1",
      "Close": "19646.05"
  },
  {
      "Date": "7/31/23",
      "Open": "19666.35",
      "High": "19772.75",
      "Low": "19597.6",
      "Close": "19753.8"
  },
  {
      "Date": "8/1/23",
      "Open": "19784",
      "High": "19795.6",
      "Low": "19704.6",
      "Close": "19733.55"
  },
  {
      "Date": "8/2/23",
      "Open": "19655.4",
      "High": "19678.25",
      "Low": "19423.55",
      "Close": "19526.55"
  },
  {
      "Date": "8/3/23",
      "Open": "19463.75",
      "High": "19537.65",
      "Low": "19296.45",
      "Close": "19381.65"
  },
  {
      "Date": "8/4/23",
      "Open": "19462.8",
      "High": "19538.85",
      "Low": "19436.45",
      "Close": "19517"
  },
  {
      "Date": "8/7/23",
      "Open": "19576.85",
      "High": "19620.45",
      "Low": "19524.8",
      "Close": "19597.3"
  },
  {
      "Date": "8/8/23",
      "Open": "19627.2",
      "High": "19634.4",
      "Low": "19533.1",
      "Close": "19570.85"
  },
  {
      "Date": "8/9/23",
      "Open": "19578.8",
      "High": "19645.5",
      "Low": "19467.5",
      "Close": "19632.55"
  },
  {
      "Date": "8/10/23",
      "Open": "19605.55",
      "High": "19623.6",
      "Low": "19495.4",
      "Close": "19543.1"
  },
  {
      "Date": "8/11/23",
      "Open": "19554.25",
      "High": "19557.75",
      "Low": "19412.75",
      "Close": "19428.3"
  },
  {
      "Date": "8/14/23",
      "Open": "19383.95",
      "High": "19465.85",
      "Low": "19257.9",
      "Close": "19434.55"
  },
  {
      "Date": "8/16/23",
      "Open": "19369",
      "High": "19482.75",
      "Low": "19317.2",
      "Close": "19465"
  },
  {
      "Date": "8/17/23",
      "Open": "19450.55",
      "High": "19461.55",
      "Low": "19326.25",
      "Close": "19365.25"
  },
  {
      "Date": "8/18/23",
      "Open": "19301.75",
      "High": "19373.8",
      "Low": "19253.6",
      "Close": "19310.15"
  },
  {
      "Date": "8/21/23",
      "Open": "19320.65",
      "High": "19425.95",
      "Low": "19296.3",
      "Close": "19393.6"
  },
  {
      "Date": "8/22/23",
      "Open": "19417.1",
      "High": "19443.5",
      "Low": "19381.3",
      "Close": "19396.45"
  },
  {
      "Date": "8/23/23",
      "Open": "19439.2",
      "High": "19472.05",
      "Low": "19366.6",
      "Close": "19444"
  },
  {
      "Date": "8/24/23",
      "Open": "19535.15",
      "High": "19584.45",
      "Low": "19369",
      "Close": "19386.7"
  },
  {
      "Date": "8/25/23",
      "Open": "19297.4",
      "High": "19339.55",
      "Low": "19229.7",
      "Close": "19265.8"
  },
  {
      "Date": "8/28/23",
      "Open": "19298.35",
      "High": "19366.85",
      "Low": "19249.7",
      "Close": "19306.05"
  },
  {
      "Date": "8/29/23",
      "Open": "19374.85",
      "High": "19377.9",
      "Low": "19309.1",
      "Close": "19342.65"
  },
  {
      "Date": "8/30/23",
      "Open": "19433.45",
      "High": "19452.8",
      "Low": "19334.75",
      "Close": "19347.45"
  },
  {
      "Date": "8/31/23",
      "Open": "19375.55",
      "High": "19388.2",
      "Low": "19223.65",
      "Close": "19253.8"
  },
  {
      "Date": "9/1/23",
      "Open": "19258.15",
      "High": "19458.55",
      "Low": "19255.7",
      "Close": "19435.3"
  },
  {
      "Date": "9/4/23",
      "Open": "19525.05",
      "High": "19545.15",
      "Low": "19432.85",
      "Close": "19528.8"
  },
  {
      "Date": "9/5/23",
      "Open": "19564.65",
      "High": "19587.05",
      "Low": "19525.75",
      "Close": "19574.9"
  },
  {
      "Date": "9/6/23",
      "Open": "19581.2",
      "High": "19636.45",
      "Low": "19491.5",
      "Close": "19611.05"
  },
  {
      "Date": "9/7/23",
      "Open": "19598.65",
      "High": "19737",
      "Low": "19550.05",
      "Close": "19727.05"
  },
  {
      "Date": "9/8/23",
      "Open": "19774.8",
      "High": "19867.15",
      "Low": "19728.05",
      "Close": "19819.95"
  },
  {
      "Date": "9/11/23",
      "Open": "19890",
      "High": "20008.15",
      "Low": "19865.35",
      "Close": "19996.35"
  },
  {
      "Date": "9/12/23",
      "Open": "20110.15",
      "High": "20110.35",
      "Low": "19914.65",
      "Close": "19993.2"
  },
  {
      "Date": "9/13/23",
      "Open": "19989.5",
      "High": "20096.9",
      "Low": "19944.1",
      "Close": "20070"
  },
  {
      "Date": "9/14/23",
      "Open": "20127.95",
      "High": "20167.65",
      "Low": "20043.45",
      "Close": "20103.1"
  },
  {
      "Date": "9/15/23",
      "Open": "20156.45",
      "High": "20222.45",
      "Low": "20129.7",
      "Close": "20192.35"
  },
  {
      "Date": "9/18/23",
      "Open": "20155.95",
      "High": "20195.35",
      "Low": "20115.7",
      "Close": "20133.3"
  },
  {
      "Date": "9/20/23",
      "Open": "19980.75",
      "High": "20050.65",
      "Low": "19878.85",
      "Close": "19901.4"
  },
  {
      "Date": "9/21/23",
      "Open": "19840.55",
      "High": "19848.75",
      "Low": "19709.95",
      "Close": "19742.35"
  },
  {
      "Date": "9/22/23",
      "Open": "19744.85",
      "High": "19798.65",
      "Low": "19657.5",
      "Close": "19674.25"
  },
  {
      "Date": "9/25/23",
      "Open": "19678.2",
      "High": "19734.15",
      "Low": "19601.55",
      "Close": "19674.55"
  },
  {
      "Date": "9/26/23",
      "Open": "19682.8",
      "High": "19699.35",
      "Low": "19637.45",
      "Close": "19664.7"
  },
  {
      "Date": "9/27/23",
      "Open": "19637.05",
      "High": "19730.7",
      "Low": "19554",
      "Close": "19716.45"
  },
  {
      "Date": "9/28/23",
      "Open": "19761.8",
      "High": "19766.65",
      "Low": "19492.1",
      "Close": "19523.55"
  },
  {
      "Date": "9/29/23",
      "Open": "19581.2",
      "High": "19726.25",
      "Low": "19551.05",
      "Close": "19638.3"
  },
  {
      "Date": "10/3/23",
      "Open": "19622.4",
      "High": "19623.2",
      "Low": "19479.65",
      "Close": "19528.75"
  },
  {
      "Date": "10/4/23",
      "Open": "19446.3",
      "High": "19457.8",
      "Low": "19333.6",
      "Close": "19436.1"
  },
  {
      "Date": "10/5/23",
      "Open": "19521.85",
      "High": "19576.95",
      "Low": "19487.3",
      "Close": "19545.75"
  },
  {
      "Date": "10/6/23",
      "Open": "19621.2",
      "High": "19675.75",
      "Low": "19589.4",
      "Close": "19653.5"
  },
  {
      "Date": "10/9/23",
      "Open": "19539.45",
      "High": "19588.95",
      "Low": "19480.5",
      "Close": "19512.35"
  },
  {
      "Date": "10/10/23",
      "Open": "19565.6",
      "High": "19717.8",
      "Low": "19565.45",
      "Close": "19689.85"
  },
  {
      "Date": "10/11/23",
      "Open": "19767",
      "High": "19839.2",
      "Low": "19756.95",
      "Close": "19811.35"
  },
  {
      "Date": "10/12/23",
      "Open": "19822.7",
      "High": "19843.3",
      "Low": "19772.65",
      "Close": "19794"
  },
  {
      "Date": "10/13/23",
      "Open": "19654.55",
      "High": "19805.4",
      "Low": "19635.3",
      "Close": "19751.05"
  },
  {
      "Date": "10/16/23",
      "Open": "19737.25",
      "High": "19781.3",
      "Low": "19691.85",
      "Close": "19731.75"
  },
  {
      "Date": "10/17/23",
      "Open": "19843.2",
      "High": "19849.75",
      "Low": "19775.65",
      "Close": "19811.5"
  },
  {
      "Date": "10/18/23",
      "Open": "19820.45",
      "High": "19840.95",
      "Low": "19659.95",
      "Close": "19671.1"
  },
  {
      "Date": "10/19/23",
      "Open": "19545.2",
      "High": "19681.8",
      "Low": "19512.35",
      "Close": "19624.7"
  },
  {
      "Date": "10/20/23",
      "Open": "19542.15",
      "High": "19593.8",
      "Low": "19518.7",
      "Close": "19542.65"
  },
  {
      "Date": "10/23/23",
      "Open": "19521.6",
      "High": "19556.85",
      "Low": "19257.85",
      "Close": "19281.75"
  },
  {
      "Date": "10/25/23",
      "Open": "19286.45",
      "High": "19347.3",
      "Low": "19074.15",
      "Close": "19122.15"
  },
  {
      "Date": "10/26/23",
      "Open": "19027.25",
      "High": "19041.7",
      "Low": "18837.85",
      "Close": "18857.25"
  },
  {
      "Date": "10/27/23",
      "Open": "18928.75",
      "High": "19076.15",
      "Low": "18926.65",
      "Close": "19047.25"
  },
  {
      "Date": "10/30/23",
      "Open": "19053.4",
      "High": "19158.5",
      "Low": "18940",
      "Close": "19140.9"
  },
  {
      "Date": "10/31/23",
      "Open": "19232.95",
      "High": "19233.7",
      "Low": "19056.45",
      "Close": "19079.6"
  },
  {
      "Date": "11/1/23",
      "Open": "19064.05",
      "High": "19096.05",
      "Low": "18973.7",
      "Close": "18989.15"
  },
  {
      "Date": "11/2/23",
      "Open": "19120",
      "High": "19175.25",
      "Low": "19064.15",
      "Close": "19133.25"
  },
  {
      "Date": "11/3/23",
      "Open": "19241",
      "High": "19276.25",
      "Low": "19210.9",
      "Close": "19230.6"
  },
  {
      "Date": "11/6/23",
      "Open": "19345.85",
      "High": "19423",
      "Low": "19309.7",
      "Close": "19411.75"
  },
  {
      "Date": "11/7/23",
      "Open": "19404.05",
      "High": "19423.5",
      "Low": "19329.1",
      "Close": "19406.7"
  },
  {
      "Date": "11/8/23",
      "Open": "19449.6",
      "High": "19464.4",
      "Low": "19401.5",
      "Close": "19443.5"
  },
  {
      "Date": "11/9/23",
      "Open": "19457.4",
      "High": "19463.9",
      "Low": "19378.35",
      "Close": "19395.3"
  },
  {
      "Date": "11/10/23",
      "Open": "19351.85",
      "High": "19451.3",
      "Low": "19329.45",
      "Close": "19425.35"
  },
  {
      "Date": "11/12/23",
      "Open": "19547.25",
      "High": "19547.25",
      "Low": "19510.25",
      "Close": "19525.55"
  },
  {
      "Date": "11/13/23",
      "Open": "19486.75",
      "High": "19494.4",
      "Low": "19414.75",
      "Close": "19443.55"
  },
  {
      "Date": "11/15/23",
      "Open": "19651.4",
      "High": "19693.2",
      "Low": "19579.65",
      "Close": "19675.45"
  },
  {
      "Date": "11/16/23",
      "Open": "19674.7",
      "High": "19875.25",
      "Low": "19627",
      "Close": "19765.2"
  },
  {
      "Date": "11/17/23",
      "Open": "19674.75",
      "High": "19806",
      "Low": "19667.45",
      "Close": "19731.8"
  },
  {
      "Date": "11/20/23",
      "Open": "19731.15",
      "High": "19756.45",
      "Low": "19670.5",
      "Close": "19694"
  },
  {
      "Date": "11/21/23",
      "Open": "19770.9",
      "High": "19829.1",
      "Low": "19754.05",
      "Close": "19783.4"
  },
  {
      "Date": "11/22/23",
      "Open": "19784",
      "High": "19825.55",
      "Low": "19703.85",
      "Close": "19811.85"
  },
  {
      "Date": "11/23/23",
      "Open": "19828.45",
      "High": "19875.15",
      "Low": "19786.75",
      "Close": "19802"
  },
  {
      "Date": "11/24/23",
      "Open": "19809.6",
      "High": "19832.85",
      "Low": "19768.85",
      "Close": "19794.7"
  },
  {
      "Date": "11/28/23",
      "Open": "19844.65",
      "High": "19916.85",
      "Low": "19800",
      "Close": "19889.7"
  },
  {
      "Date": "11/29/23",
      "Open": "19976.55",
      "High": "20104.65",
      "Low": "19956.3",
      "Close": "20096.6"
  },
  {
      "Date": "11/30/23",
      "Open": "20108.5",
      "High": "20158.7",
      "Low": "20015.85",
      "Close": "20133.15"
  },
  {
      "Date": "12/1/23",
      "Open": "20194.1",
      "High": "20291.55",
      "Low": "20183.7",
      "Close": "20267.9"
  },
  {
      "Date": "12/4/23",
      "Open": "20601.95",
      "High": "20702.65",
      "Low": "20507.75",
      "Close": "20686.8"
  },
  {
      "Date": "12/5/23",
      "Open": "20808.9",
      "High": "20864.05",
      "Low": "20711.15",
      "Close": "20855.1"
  },
  {
      "Date": "12/6/23",
      "Open": "20950.75",
      "High": "20961.95",
      "Low": "20852.15",
      "Close": "20937.7"
  },
  {
      "Date": "12/7/23",
      "Open": "20932.4",
      "High": "20941.25",
      "Low": "20850.8",
      "Close": "20901.15"
  },
  {
      "Date": "12/8/23",
      "Open": "20934.1",
      "High": "21006.1",
      "Low": "20862.7",
      "Close": "20969.4"
  },
  {
      "Date": "12/11/23",
      "Open": "20965.3",
      "High": "21026.1",
      "Low": "20923.7",
      "Close": "20997.1"
  },
  {
      "Date": "12/12/23",
      "Open": "21018.55",
      "High": "21037.9",
      "Low": "20867.15",
      "Close": "20906.4"
  },
  {
      "Date": "12/13/23",
      "Open": "20929.75",
      "High": "20950",
      "Low": "20769.5",
      "Close": "20926.35"
  },
  {
      "Date": "12/14/23",
      "Open": "21110.4",
      "High": "21210.9",
      "Low": "21074.45",
      "Close": "21182.7"
  },
  {
      "Date": "12/15/23",
      "Open": "21287.45",
      "High": "21492.3",
      "Low": "21235.3",
      "Close": "21456.65"
  },
  {
      "Date": "12/18/23",
      "Open": "21434.8",
      "High": "21482.8",
      "Low": "21365.35",
      "Close": "21418.65"
  },
  {
      "Date": "12/19/23",
      "Open": "21477.65",
      "High": "21505.05",
      "Low": "21337.75",
      "Close": "21453.1"
  },
  {
      "Date": "12/20/23",
      "Open": "21543.5",
      "High": "21593",
      "Low": "21087.35",
      "Close": "21150.15"
  },
  {
      "Date": "12/21/23",
      "Open": "21033.95",
      "High": "21288.35",
      "Low": "20976.8",
      "Close": "21255.05"
  },
  {
      "Date": "12/22/23",
      "Open": "21295.85",
      "High": "21390.5",
      "Low": "21232.45",
      "Close": "21349.4"
  },
  {
      "Date": "12/26/23",
      "Open": "21365.2",
      "High": "21477.15",
      "Low": "21329.45",
      "Close": "21441.35"
  },
  {
      "Date": "12/27/23",
      "Open": "21497.65",
      "High": "21675.75",
      "Low": "21495.8",
      "Close": "21654.75"
  },
  {
      "Date": "12/28/23",
      "Open": "21715",
      "High": "21801.45",
      "Low": "21678",
      "Close": "21778.7"
  },
  {
      "Date": "12/29/23",
      "Open": "21737.65",
      "High": "21770.3",
      "Low": "21676.9",
      "Close": "21731.4"
  },
  {
      "Date": "1/1/24",
      "Open": "21727.75",
      "High": "21834.35",
      "Low": "21680.85",
      "Close": "21741.9"
  },
  {
      "Date": "1/2/24",
      "Open": "21751.35",
      "High": "21755.6",
      "Low": "21555.65",
      "Close": "21665.8"
  },
  {
      "Date": "1/3/24",
      "Open": "21661.1",
      "High": "21677",
      "Low": "21500.35",
      "Close": "21517.35"
  },
  {
      "Date": "1/4/24",
      "Open": "21605.8",
      "High": "21685.65",
      "Low": "21564.55",
      "Close": "21658.6"
  },
  {
      "Date": "1/5/24",
      "Open": "21705.75",
      "High": "21749.6",
      "Low": "21629.2",
      "Close": "21710.8"
  },
  {
      "Date": "1/8/24",
      "Open": "21747.6",
      "High": "21763.95",
      "Low": "21492.9",
      "Close": "21513"
  },
  {
      "Date": "1/9/24",
      "Open": "21653.6",
      "High": "21724.45",
      "Low": "21517.85",
      "Close": "21544.85"
  },
  {
      "Date": "1/10/24",
      "Open": "21529.3",
      "High": "21641.85",
      "Low": "21448.65",
      "Close": "21618.7"
  },
  {
      "Date": "1/11/24",
      "Open": "21688",
      "High": "21726.5",
      "Low": "21593.75",
      "Close": "21647.2"
  },
  {
      "Date": "1/12/24",
      "Open": "21773.55",
      "High": "21928.25",
      "Low": "21715.15",
      "Close": "21894.55"
  },
  {
      "Date": "1/15/24",
      "Open": "22053.15",
      "High": "22115.55",
      "Low": "21963.55",
      "Close": "22097.45"
  },
  {
      "Date": "1/16/24",
      "Open": "22080.5",
      "High": "22124.15",
      "Low": "21969.8",
      "Close": "22032.3"
  },
  {
      "Date": "1/17/24",
      "Open": "21647.25",
      "High": "21851.5",
      "Low": "21550.45",
      "Close": "21571.95"
  },
  {
      "Date": "1/18/24",
      "Open": "21414.2",
      "High": "21539.4",
      "Low": "21285.55",
      "Close": "21462.25"
  },
  {
      "Date": "1/19/24",
      "Open": "21615.2",
      "High": "21670.6",
      "Low": "21575",
      "Close": "21622.4"
  },
  {
      "Date": "1/20/24",
      "Open": "21706.15",
      "High": "21720.3",
      "Low": "21541.8",
      "Close": "21571.8"
  },
  {
      "Date": "1/23/24",
      "Open": "21716.7",
      "High": "21750.25",
      "Low": "21192.6",
      "Close": "21238.8"
  },
  {
      "Date": "1/24/24",
      "Open": "21185.25",
      "High": "21482.35",
      "Low": "21137.2",
      "Close": "21453.95"
  },
  {
      "Date": "1/25/24",
      "Open": "21454.6",
      "High": "21459",
      "Low": "21247.05",
      "Close": "21352.6"
  },
  {
      "Date": "1/29/24",
      "Open": "21433.1",
      "High": "21763.25",
      "Low": "21429.6",
      "Close": "21737.6"
  },
  {
      "Date": "1/30/24",
      "Open": "21775.75",
      "High": "21813.05",
      "Low": "21501.8",
      "Close": "21522.1"
  },
  {
      "Date": "1/31/24",
      "Open": "21487.25",
      "High": "21741.35",
      "Low": "21448.85",
      "Close": "21725.7"
  },
  {
      "Date": "2/1/24",
      "Open": "21780.65",
      "High": "21832.95",
      "Low": "21658.75",
      "Close": "21697.45"
  },
  {
      "Date": "2/2/24",
      "Open": "21812.75",
      "High": "22126.8",
      "Low": "21805.55",
      "Close": "21853.8"
  }
];

export const debt = [
  {
      "Date": "8-Feb-24",
      "Price": "7.074",
      "Open": "7.073",
      "High": "7.092",
      "Low": "7.048",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Feb-24",
      "Price": "7.074",
      "Open": "7.075",
      "High": "7.078",
      "Low": "7.064",
      "Change %": "-0.25%"
  },
  {
      "Date": "6-Feb-24",
      "Price": "7.092",
      "Open": "7.096",
      "High": "7.096",
      "Low": "7.07",
      "Change %": "0.01%"
  },
  {
      "Date": "5-Feb-24",
      "Price": "7.091",
      "Open": "7.092",
      "High": "7.096",
      "Low": "7.071",
      "Change %": "0.58%"
  },
  {
      "Date": "2-Feb-24",
      "Price": "7.05",
      "Open": "7.054",
      "High": "7.058",
      "Low": "7.021",
      "Change %": "-0.20%"
  },
  {
      "Date": "1-Feb-24",
      "Price": "7.064",
      "Open": "7.129",
      "High": "7.135",
      "Low": "7.039",
      "Change %": "-1.12%"
  },
  {
      "Date": "31-Jan-24",
      "Price": "7.144",
      "Open": "7.152",
      "High": "7.152",
      "Low": "7.139",
      "Change %": "-0.15%"
  },
  {
      "Date": "30-Jan-24",
      "Price": "7.155",
      "Open": "7.164",
      "High": "7.164",
      "Low": "7.147",
      "Change %": "-0.22%"
  },
  {
      "Date": "29-Jan-24",
      "Price": "7.171",
      "Open": "7.182",
      "High": "7.182",
      "Low": "7.165",
      "Change %": "0.00%"
  },
  {
      "Date": "25-Jan-24",
      "Price": "7.171",
      "Open": "7.195",
      "High": "7.195",
      "Low": "7.169",
      "Change %": "-0.14%"
  },
  {
      "Date": "24-Jan-24",
      "Price": "7.181",
      "Open": "7.186",
      "High": "7.186",
      "Low": "7.17",
      "Change %": "0.10%"
  },
  {
      "Date": "23-Jan-24",
      "Price": "7.174",
      "Open": "7.172",
      "High": "7.177",
      "Low": "7.166",
      "Change %": "-0.14%"
  },
  {
      "Date": "19-Jan-24",
      "Price": "7.184",
      "Open": "7.194",
      "High": "7.196",
      "Low": "7.177",
      "Change %": "0.08%"
  },
  {
      "Date": "18-Jan-24",
      "Price": "7.178",
      "Open": "7.177",
      "High": "7.18",
      "Low": "7.169",
      "Change %": "0.21%"
  },
  {
      "Date": "17-Jan-24",
      "Price": "7.163",
      "Open": "7.161",
      "High": "7.165",
      "Low": "7.151",
      "Change %": "0.24%"
  },
  {
      "Date": "16-Jan-24",
      "Price": "7.146",
      "Open": "7.161",
      "High": "7.161",
      "Low": "7.14",
      "Change %": "-0.03%"
  },
  {
      "Date": "15-Jan-24",
      "Price": "7.148",
      "Open": "7.153",
      "High": "7.153",
      "Low": "7.134",
      "Change %": "-0.42%"
  },
  {
      "Date": "12-Jan-24",
      "Price": "7.178",
      "Open": "7.168",
      "High": "7.181",
      "Low": "7.158",
      "Change %": "0.18%"
  },
  {
      "Date": "11-Jan-24",
      "Price": "7.165",
      "Open": "7.192",
      "High": "7.192",
      "Low": "7.162",
      "Change %": "-0.18%"
  },
  {
      "Date": "10-Jan-24",
      "Price": "7.178",
      "Open": "7.192",
      "High": "7.192",
      "Low": "7.176",
      "Change %": "-0.15%"
  },
  {
      "Date": "9-Jan-24",
      "Price": "7.189",
      "Open": "7.183",
      "High": "7.197",
      "Low": "7.182",
      "Change %": "-0.19%"
  },
  {
      "Date": "8-Jan-24",
      "Price": "7.203",
      "Open": "7.233",
      "High": "7.233",
      "Low": "7.201",
      "Change %": "-0.44%"
  },
  {
      "Date": "5-Jan-24",
      "Price": "7.235",
      "Open": "7.237",
      "High": "7.239",
      "Low": "7.225",
      "Change %": "0.21%"
  },
  {
      "Date": "4-Jan-24",
      "Price": "7.22",
      "Open": "7.214",
      "High": "7.224",
      "Low": "7.208",
      "Change %": "0.06%"
  },
  {
      "Date": "3-Jan-24",
      "Price": "7.216",
      "Open": "7.215",
      "High": "7.222",
      "Low": "7.197",
      "Change %": "0.14%"
  },
  {
      "Date": "2-Jan-24",
      "Price": "7.206",
      "Open": "7.215",
      "High": "7.215",
      "Low": "7.204",
      "Change %": "0.14%"
  },
  {
      "Date": "1-Jan-24",
      "Price": "7.196",
      "Open": "7.207",
      "High": "7.207",
      "Low": "7.192",
      "Change %": "0.28%"
  },
  {
      "Date": "29-Dec-23",
      "Price": "7.176",
      "Open": "7.225",
      "High": "7.225",
      "Low": "7.172",
      "Change %": "-0.43%"
  },
  {
      "Date": "28-Dec-23",
      "Price": "7.207",
      "Open": "7.2",
      "High": "7.213",
      "Low": "7.191",
      "Change %": "0.03%"
  },
  {
      "Date": "27-Dec-23",
      "Price": "7.205",
      "Open": "7.191",
      "High": "7.208",
      "Low": "7.182",
      "Change %": "0.31%"
  },
  {
      "Date": "26-Dec-23",
      "Price": "7.183",
      "Open": "7.213",
      "High": "7.213",
      "Low": "7.177",
      "Change %": "-0.07%"
  },
  {
      "Date": "22-Dec-23",
      "Price": "7.188",
      "Open": "7.187",
      "High": "7.198",
      "Low": "7.185",
      "Change %": "0.06%"
  },
  {
      "Date": "21-Dec-23",
      "Price": "7.184",
      "Open": "7.173",
      "High": "7.188",
      "Low": "7.151",
      "Change %": "0.18%"
  },
  {
      "Date": "20-Dec-23",
      "Price": "7.171",
      "Open": "7.184",
      "High": "7.184",
      "Low": "7.165",
      "Change %": "0.00%"
  },
  {
      "Date": "19-Dec-23",
      "Price": "7.171",
      "Open": "7.185",
      "High": "7.185",
      "Low": "7.153",
      "Change %": "0.25%"
  },
  {
      "Date": "18-Dec-23",
      "Price": "7.153",
      "Open": "7.171",
      "High": "7.179",
      "Low": "7.147",
      "Change %": "-0.14%"
  },
  {
      "Date": "15-Dec-23",
      "Price": "7.163",
      "Open": "7.204",
      "High": "7.206",
      "Low": "7.155",
      "Change %": "-0.43%"
  },
  {
      "Date": "14-Dec-23",
      "Price": "7.194",
      "Open": "7.222",
      "High": "7.224",
      "Low": "7.185",
      "Change %": "-0.90%"
  },
  {
      "Date": "13-Dec-23",
      "Price": "7.259",
      "Open": "7.262",
      "High": "7.269",
      "Low": "7.257",
      "Change %": "-0.22%"
  },
  {
      "Date": "12-Dec-23",
      "Price": "7.275",
      "Open": "7.279",
      "High": "7.28",
      "Low": "7.267",
      "Change %": "-0.08%"
  },
  {
      "Date": "11-Dec-23",
      "Price": "7.281",
      "Open": "7.287",
      "High": "7.287",
      "Low": "7.273",
      "Change %": "0.21%"
  },
  {
      "Date": "8-Dec-23",
      "Price": "7.266",
      "Open": "7.249",
      "High": "7.271",
      "Low": "7.226",
      "Change %": "0.41%"
  },
  {
      "Date": "7-Dec-23",
      "Price": "7.236",
      "Open": "7.248",
      "High": "7.248",
      "Low": "7.233",
      "Change %": "-0.17%"
  },
  {
      "Date": "6-Dec-23",
      "Price": "7.248",
      "Open": "7.251",
      "High": "7.254",
      "Low": "7.243",
      "Change %": "-0.19%"
  },
  {
      "Date": "5-Dec-23",
      "Price": "7.262",
      "Open": "7.275",
      "High": "7.275",
      "Low": "7.256",
      "Change %": "-0.12%"
  },
  {
      "Date": "4-Dec-23",
      "Price": "7.271",
      "Open": "7.264",
      "High": "7.276",
      "Low": "7.262",
      "Change %": "-0.26%"
  },
  {
      "Date": "1-Dec-23",
      "Price": "7.29",
      "Open": "7.293",
      "High": "7.298",
      "Low": "7.279",
      "Change %": "0.15%"
  },
  {
      "Date": "30-Nov-23",
      "Price": "7.279",
      "Open": "7.25",
      "High": "7.287",
      "Low": "7.248",
      "Change %": "0.37%"
  },
  {
      "Date": "29-Nov-23",
      "Price": "7.252",
      "Open": "7.258",
      "High": "7.258",
      "Low": "7.237",
      "Change %": "-0.29%"
  },
  {
      "Date": "28-Nov-23",
      "Price": "7.273",
      "Open": "7.258",
      "High": "7.277",
      "Low": "7.253",
      "Change %": "0.01%"
  },
  {
      "Date": "24-Nov-23",
      "Price": "7.272",
      "Open": "7.278",
      "High": "7.291",
      "Low": "7.266",
      "Change %": "0.26%"
  },
  {
      "Date": "23-Nov-23",
      "Price": "7.253",
      "Open": "7.258",
      "High": "7.263",
      "Low": "7.246",
      "Change %": "0.08%"
  },
  {
      "Date": "22-Nov-23",
      "Price": "7.247",
      "Open": "7.277",
      "High": "7.277",
      "Low": "7.245",
      "Change %": "-0.30%"
  },
  {
      "Date": "21-Nov-23",
      "Price": "7.269",
      "Open": "7.247",
      "High": "7.272",
      "Low": "7.237",
      "Change %": "0.23%"
  },
  {
      "Date": "20-Nov-23",
      "Price": "7.252",
      "Open": "7.23",
      "High": "7.257",
      "Low": "7.224",
      "Change %": "0.55%"
  },
  {
      "Date": "17-Nov-23",
      "Price": "7.212",
      "Open": "7.226",
      "High": "7.23",
      "Low": "7.194",
      "Change %": "-0.28%"
  },
  {
      "Date": "16-Nov-23",
      "Price": "7.232",
      "Open": "7.236",
      "High": "7.236",
      "Low": "7.22",
      "Change %": "0.10%"
  },
  {
      "Date": "15-Nov-23",
      "Price": "7.225",
      "Open": "7.262",
      "High": "7.262",
      "Low": "7.22",
      "Change %": "-0.80%"
  },
  {
      "Date": "13-Nov-23",
      "Price": "7.283",
      "Open": "7.316",
      "High": "7.316",
      "Low": "7.281",
      "Change %": "-0.23%"
  },
  {
      "Date": "10-Nov-23",
      "Price": "7.3",
      "Open": "7.293",
      "High": "7.305",
      "Low": "7.288",
      "Change %": "0.43%"
  },
  {
      "Date": "9-Nov-23",
      "Price": "7.269",
      "Open": "7.256",
      "High": "7.276",
      "Low": "7.246",
      "Change %": "0.04%"
  },
  {
      "Date": "8-Nov-23",
      "Price": "7.266",
      "Open": "7.273",
      "High": "7.281",
      "Low": "7.255",
      "Change %": "-0.19%"
  },
  {
      "Date": "7-Nov-23",
      "Price": "7.28",
      "Open": "7.318",
      "High": "7.318",
      "Low": "7.277",
      "Change %": "-0.34%"
  },
  {
      "Date": "6-Nov-23",
      "Price": "7.305",
      "Open": "7.301",
      "High": "7.307",
      "Low": "7.294",
      "Change %": "-0.16%"
  },
  {
      "Date": "3-Nov-23",
      "Price": "7.317",
      "Open": "7.324",
      "High": "7.324",
      "Low": "7.309",
      "Change %": "-0.07%"
  },
  {
      "Date": "2-Nov-23",
      "Price": "7.322",
      "Open": "7.323",
      "High": "7.335",
      "Low": "7.318",
      "Change %": "-0.49%"
  },
  {
      "Date": "1-Nov-23",
      "Price": "7.358",
      "Open": "7.376",
      "High": "7.376",
      "Low": "7.352",
      "Change %": "0.10%"
  },
  {
      "Date": "31-Oct-23",
      "Price": "7.351",
      "Open": "7.365",
      "High": "7.366",
      "Low": "7.346",
      "Change %": "-0.24%"
  },
  {
      "Date": "30-Oct-23",
      "Price": "7.369",
      "Open": "7.359",
      "High": "7.372",
      "Low": "7.354",
      "Change %": "0.24%"
  },
  {
      "Date": "27-Oct-23",
      "Price": "7.351",
      "Open": "7.359",
      "High": "7.359",
      "Low": "7.341",
      "Change %": "-0.20%"
  },
  {
      "Date": "26-Oct-23",
      "Price": "7.366",
      "Open": "7.377",
      "High": "7.377",
      "Low": "7.36",
      "Change %": "0.31%"
  },
  {
      "Date": "25-Oct-23",
      "Price": "7.343",
      "Open": "7.343",
      "High": "7.349",
      "Low": "7.333",
      "Change %": "-0.53%"
  },
  {
      "Date": "23-Oct-23",
      "Price": "7.382",
      "Open": "7.377",
      "High": "7.391",
      "Low": "7.36",
      "Change %": "0.23%"
  },
  {
      "Date": "20-Oct-23",
      "Price": "7.365",
      "Open": "7.373",
      "High": "7.377",
      "Low": "7.349",
      "Change %": "-0.05%"
  },
  {
      "Date": "19-Oct-23",
      "Price": "7.369",
      "Open": "7.381",
      "High": "7.381",
      "Low": "7.365",
      "Change %": "0.22%"
  },
  {
      "Date": "18-Oct-23",
      "Price": "7.353",
      "Open": "7.352",
      "High": "7.36",
      "Low": "7.341",
      "Change %": "0.34%"
  },
  {
      "Date": "17-Oct-23",
      "Price": "7.328",
      "Open": "7.349",
      "High": "7.349",
      "Low": "7.309",
      "Change %": "-0.07%"
  },
  {
      "Date": "16-Oct-23",
      "Price": "7.333",
      "Open": "7.344",
      "High": "7.344",
      "Low": "7.325",
      "Change %": "0.18%"
  },
  {
      "Date": "13-Oct-23",
      "Price": "7.32",
      "Open": "7.322",
      "High": "7.337",
      "Low": "7.305",
      "Change %": "0.25%"
  },
  {
      "Date": "12-Oct-23",
      "Price": "7.302",
      "Open": "7.308",
      "High": "7.314",
      "Low": "7.292",
      "Change %": "-0.07%"
  },
  {
      "Date": "11-Oct-23",
      "Price": "7.307",
      "Open": "7.337",
      "High": "7.337",
      "Low": "7.301",
      "Change %": "-0.60%"
  },
  {
      "Date": "10-Oct-23",
      "Price": "7.351",
      "Open": "7.359",
      "High": "7.372",
      "Low": "7.344",
      "Change %": "-0.47%"
  },
  {
      "Date": "9-Oct-23",
      "Price": "7.386",
      "Open": "7.366",
      "High": "7.395",
      "Low": "7.34",
      "Change %": "0.64%"
  },
  {
      "Date": "6-Oct-23",
      "Price": "7.339",
      "Open": "7.221",
      "High": "7.365",
      "Low": "7.218",
      "Change %": "1.73%"
  },
  {
      "Date": "5-Oct-23",
      "Price": "7.214",
      "Open": "7.214",
      "High": "7.226",
      "Low": "7.205",
      "Change %": "-0.33%"
  },
  {
      "Date": "4-Oct-23",
      "Price": "7.238",
      "Open": "7.257",
      "High": "7.261",
      "Low": "7.236",
      "Change %": "0.04%"
  },
  {
      "Date": "3-Oct-23",
      "Price": "7.235",
      "Open": "7.239",
      "High": "7.24",
      "Low": "7.21",
      "Change %": "0.35%"
  },
  {
      "Date": "29-Sep-23",
      "Price": "7.21",
      "Open": "7.241",
      "High": "7.241",
      "Low": "7.188",
      "Change %": "-0.39%"
  },
  {
      "Date": "28-Sep-23",
      "Price": "7.238",
      "Open": "7.214",
      "High": "7.241",
      "Low": "7.148",
      "Change %": "0.91%"
  },
  {
      "Date": "27-Sep-23",
      "Price": "7.173",
      "Open": "7.173",
      "High": "7.173",
      "Low": "7.173",
      "Change %": "0.38%"
  },
  {
      "Date": "26-Sep-23",
      "Price": "7.146",
      "Open": "7.178",
      "High": "7.18",
      "Low": "7.144",
      "Change %": "-0.06%"
  },
  {
      "Date": "25-Sep-23",
      "Price": "7.15",
      "Open": "7.186",
      "High": "7.186",
      "Low": "7.124",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Sep-23",
      "Price": "7.15",
      "Open": "7.11",
      "High": "7.164",
      "Low": "7.086",
      "Change %": "0.20%"
  },
  {
      "Date": "21-Sep-23",
      "Price": "7.136",
      "Open": "7.207",
      "High": "7.207",
      "Low": "7.129",
      "Change %": "-0.24%"
  },
  {
      "Date": "20-Sep-23",
      "Price": "7.153",
      "Open": "7.171",
      "High": "7.171",
      "Low": "7.143",
      "Change %": "0.06%"
  },
  {
      "Date": "18-Sep-23",
      "Price": "7.149",
      "Open": "7.193",
      "High": "7.193",
      "Low": "7.139",
      "Change %": "-0.13%"
  },
  {
      "Date": "15-Sep-23",
      "Price": "7.158",
      "Open": "7.117",
      "High": "7.166",
      "Low": "7.105",
      "Change %": "0.80%"
  },
  {
      "Date": "14-Sep-23",
      "Price": "7.101",
      "Open": "7.157",
      "High": "7.157",
      "Low": "7.096",
      "Change %": "-0.99%"
  },
  {
      "Date": "13-Sep-23",
      "Price": "7.172",
      "Open": "7.19",
      "High": "7.19",
      "Low": "7.171",
      "Change %": "-0.43%"
  },
  {
      "Date": "12-Sep-23",
      "Price": "7.203",
      "Open": "7.222",
      "High": "7.222",
      "Low": "7.194",
      "Change %": "-0.08%"
  },
  {
      "Date": "11-Sep-23",
      "Price": "7.209",
      "Open": "7.188",
      "High": "7.214",
      "Low": "7.183",
      "Change %": "0.14%"
  },
  {
      "Date": "8-Sep-23",
      "Price": "7.199",
      "Open": "7.183",
      "High": "7.207",
      "Low": "7.165",
      "Change %": "0.36%"
  },
  {
      "Date": "7-Sep-23",
      "Price": "7.173",
      "Open": "7.229",
      "High": "7.229",
      "Low": "7.158",
      "Change %": "-0.51%"
  },
  {
      "Date": "6-Sep-23",
      "Price": "7.21",
      "Open": "7.217",
      "High": "7.232",
      "Low": "7.209",
      "Change %": "0.06%"
  },
  {
      "Date": "5-Sep-23",
      "Price": "7.206",
      "Open": "7.21",
      "High": "7.211",
      "Low": "7.195",
      "Change %": "0.03%"
  },
  {
      "Date": "4-Sep-23",
      "Price": "7.204",
      "Open": "7.192",
      "High": "7.206",
      "Low": "7.186",
      "Change %": "0.40%"
  },
  {
      "Date": "1-Sep-23",
      "Price": "7.175",
      "Open": "7.174",
      "High": "7.184",
      "Low": "7.163",
      "Change %": "0.13%"
  },
  {
      "Date": "31-Aug-23",
      "Price": "7.166",
      "Open": "7.177",
      "High": "7.186",
      "Low": "7.164",
      "Change %": "-0.26%"
  },
  {
      "Date": "30-Aug-23",
      "Price": "7.185",
      "Open": "7.153",
      "High": "7.192",
      "Low": "7.153",
      "Change %": "0.07%"
  },
  {
      "Date": "29-Aug-23",
      "Price": "7.18",
      "Open": "7.178",
      "High": "7.189",
      "Low": "7.159",
      "Change %": "0.03%"
  },
  {
      "Date": "28-Aug-23",
      "Price": "7.178",
      "Open": "7.212",
      "High": "7.212",
      "Low": "7.166",
      "Change %": "-0.39%"
  },
  {
      "Date": "25-Aug-23",
      "Price": "7.206",
      "Open": "7.241",
      "High": "7.241",
      "Low": "7.198",
      "Change %": "0.18%"
  },
  {
      "Date": "24-Aug-23",
      "Price": "7.193",
      "Open": "7.178",
      "High": "7.197",
      "Low": "7.175",
      "Change %": "-0.01%"
  },
  {
      "Date": "23-Aug-23",
      "Price": "7.194",
      "Open": "7.222",
      "High": "7.222",
      "Low": "7.193",
      "Change %": "-0.33%"
  },
  {
      "Date": "22-Aug-23",
      "Price": "7.218",
      "Open": "7.244",
      "High": "7.244",
      "Low": "7.211",
      "Change %": "-0.03%"
  },
  {
      "Date": "21-Aug-23",
      "Price": "7.22",
      "Open": "7.233",
      "High": "7.233",
      "Low": "7.21",
      "Change %": "0.08%"
  },
  {
      "Date": "18-Aug-23",
      "Price": "7.214",
      "Open": "7.24",
      "High": "7.241",
      "Low": "7.209",
      "Change %": "-0.50%"
  },
  {
      "Date": "17-Aug-23",
      "Price": "7.25",
      "Open": "7.252",
      "High": "7.256",
      "Low": "7.243",
      "Change %": "0.57%"
  },
  {
      "Date": "14-Aug-23",
      "Price": "7.209",
      "Open": "7.226",
      "High": "7.226",
      "Low": "7.205",
      "Change %": "0.15%"
  },
  {
      "Date": "11-Aug-23",
      "Price": "7.198",
      "Open": "7.179",
      "High": "7.202",
      "Low": "7.179",
      "Change %": "0.62%"
  },
  {
      "Date": "10-Aug-23",
      "Price": "7.154",
      "Open": "7.193",
      "High": "7.193",
      "Low": "7.152",
      "Change %": "-0.33%"
  },
  {
      "Date": "9-Aug-23",
      "Price": "7.178",
      "Open": "7.171",
      "High": "7.181",
      "Low": "7.161",
      "Change %": "0.14%"
  },
  {
      "Date": "8-Aug-23",
      "Price": "7.168",
      "Open": "7.186",
      "High": "7.191",
      "Low": "7.163",
      "Change %": "-0.40%"
  },
  {
      "Date": "7-Aug-23",
      "Price": "7.197",
      "Open": "7.17",
      "High": "7.2",
      "Low": "7.156",
      "Change %": "0.06%"
  },
  {
      "Date": "4-Aug-23",
      "Price": "7.193",
      "Open": "7.201",
      "High": "7.219",
      "Low": "7.19",
      "Change %": "-0.01%"
  },
  {
      "Date": "3-Aug-23",
      "Price": "7.194",
      "Open": "7.178",
      "High": "7.214",
      "Low": "7.173",
      "Change %": "0.56%"
  },
  {
      "Date": "2-Aug-23",
      "Price": "7.154",
      "Open": "7.185",
      "High": "7.185",
      "Low": "7.152",
      "Change %": "-0.03%"
  },
  {
      "Date": "1-Aug-23",
      "Price": "7.156",
      "Open": "7.186",
      "High": "7.186",
      "Low": "7.147",
      "Change %": "-0.22%"
  },
  {
      "Date": "31-Jul-23",
      "Price": "7.172",
      "Open": "7.179",
      "High": "7.185",
      "Low": "7.162",
      "Change %": "0.15%"
  },
  {
      "Date": "28-Jul-23",
      "Price": "7.161",
      "Open": "7.154",
      "High": "7.179",
      "Low": "7.148",
      "Change %": "0.58%"
  },
  {
      "Date": "27-Jul-23",
      "Price": "7.12",
      "Open": "7.089",
      "High": "7.122",
      "Low": "7.078",
      "Change %": "0.34%"
  },
  {
      "Date": "26-Jul-23",
      "Price": "7.096",
      "Open": "7.106",
      "High": "7.106",
      "Low": "7.089",
      "Change %": "-0.06%"
  },
  {
      "Date": "25-Jul-23",
      "Price": "7.1",
      "Open": "7.091",
      "High": "7.105",
      "Low": "7.088",
      "Change %": "0.41%"
  },
  {
      "Date": "24-Jul-23",
      "Price": "7.071",
      "Open": "7.082",
      "High": "7.087",
      "Low": "7.066",
      "Change %": "-0.23%"
  },
  {
      "Date": "21-Jul-23",
      "Price": "7.087",
      "Open": "7.099",
      "High": "7.107",
      "Low": "7.076",
      "Change %": "0.06%"
  },
  {
      "Date": "20-Jul-23",
      "Price": "7.083",
      "Open": "7.081",
      "High": "7.088",
      "Low": "7.074",
      "Change %": "0.16%"
  },
  {
      "Date": "19-Jul-23",
      "Price": "7.072",
      "Open": "7.062",
      "High": "7.076",
      "Low": "7.054",
      "Change %": "0.20%"
  },
  {
      "Date": "18-Jul-23",
      "Price": "7.058",
      "Open": "7.084",
      "High": "7.084",
      "Low": "7.047",
      "Change %": "-0.23%"
  },
  {
      "Date": "17-Jul-23",
      "Price": "7.074",
      "Open": "7.11",
      "High": "7.11",
      "Low": "7.071",
      "Change %": "-0.21%"
  },
  {
      "Date": "14-Jul-23",
      "Price": "7.089",
      "Open": "7.06",
      "High": "7.095",
      "Low": "7.06",
      "Change %": "0.25%"
  },
  {
      "Date": "13-Jul-23",
      "Price": "7.071",
      "Open": "7.099",
      "High": "7.099",
      "Low": "7.064",
      "Change %": "-0.66%"
  },
  {
      "Date": "12-Jul-23",
      "Price": "7.118",
      "Open": "7.091",
      "High": "7.122",
      "Low": "7.087",
      "Change %": "0.32%"
  },
  {
      "Date": "11-Jul-23",
      "Price": "7.095",
      "Open": "7.12",
      "High": "7.123",
      "Low": "7.088",
      "Change %": "-0.76%"
  },
  {
      "Date": "10-Jul-23",
      "Price": "7.149",
      "Open": "7.168",
      "High": "7.168",
      "Low": "7.138",
      "Change %": "-0.14%"
  },
  {
      "Date": "7-Jul-23",
      "Price": "7.159",
      "Open": "7.171",
      "High": "7.175",
      "Low": "7.144",
      "Change %": "0.17%"
  },
  {
      "Date": "6-Jul-23",
      "Price": "7.147",
      "Open": "7.132",
      "High": "7.151",
      "Low": "7.13",
      "Change %": "0.59%"
  },
  {
      "Date": "5-Jul-23",
      "Price": "7.105",
      "Open": "7.122",
      "High": "7.122",
      "Low": "7.102",
      "Change %": "-0.20%"
  },
  {
      "Date": "4-Jul-23",
      "Price": "7.119",
      "Open": "7.127",
      "High": "7.127",
      "Low": "7.108",
      "Change %": "0.03%"
  },
  {
      "Date": "3-Jul-23",
      "Price": "7.117",
      "Open": "7.113",
      "High": "7.122",
      "Low": "7.1",
      "Change %": "0.10%"
  },
  {
      "Date": "30-Jun-23",
      "Price": "7.11",
      "Open": "7.091",
      "High": "7.117",
      "Low": "7.084",
      "Change %": "0.77%"
  },
  {
      "Date": "28-Jun-23",
      "Price": "7.056",
      "Open": "7.065",
      "High": "7.071",
      "Low": "7.047",
      "Change %": "-0.10%"
  },
  {
      "Date": "27-Jun-23",
      "Price": "7.063",
      "Open": "7.091",
      "High": "7.091",
      "Low": "7.057",
      "Change %": "-0.04%"
  },
  {
      "Date": "26-Jun-23",
      "Price": "7.066",
      "Open": "7.077",
      "High": "7.085",
      "Low": "7.063",
      "Change %": "-0.11%"
  },
  {
      "Date": "23-Jun-23",
      "Price": "7.074",
      "Open": "7.095",
      "High": "7.098",
      "Low": "7.063",
      "Change %": "-0.17%"
  },
  {
      "Date": "22-Jun-23",
      "Price": "7.086",
      "Open": "7.063",
      "High": "7.093",
      "Low": "7.058",
      "Change %": "0.27%"
  },
  {
      "Date": "21-Jun-23",
      "Price": "7.067",
      "Open": "7.063",
      "High": "7.071",
      "Low": "7.049",
      "Change %": "0.11%"
  },
  {
      "Date": "20-Jun-23",
      "Price": "7.059",
      "Open": "7.063",
      "High": "7.069",
      "Low": "7.051",
      "Change %": "0.09%"
  },
  {
      "Date": "19-Jun-23",
      "Price": "7.053",
      "Open": "7.055",
      "High": "7.061",
      "Low": "7.028",
      "Change %": "0.27%"
  },
  {
      "Date": "16-Jun-23",
      "Price": "7.034",
      "Open": "7.041",
      "High": "7.057",
      "Low": "7.027",
      "Change %": "-0.03%"
  },
  {
      "Date": "15-Jun-23",
      "Price": "7.036",
      "Open": "7.027",
      "High": "7.043",
      "Low": "7.02",
      "Change %": "0.36%"
  },
  {
      "Date": "14-Jun-23",
      "Price": "7.011",
      "Open": "7.02",
      "High": "7.021",
      "Low": "7.008",
      "Change %": "0.13%"
  },
  {
      "Date": "13-Jun-23",
      "Price": "7.002",
      "Open": "6.998",
      "High": "7.012",
      "Low": "6.998",
      "Change %": "-0.20%"
  },
  {
      "Date": "12-Jun-23",
      "Price": "7.016",
      "Open": "7.048",
      "High": "7.048",
      "Low": "7.015",
      "Change %": "-0.33%"
  },
  {
      "Date": "9-Jun-23",
      "Price": "7.039",
      "Open": "7.012",
      "High": "7.044",
      "Low": "7.012",
      "Change %": "0.26%"
  },
  {
      "Date": "8-Jun-23",
      "Price": "7.021",
      "Open": "7.006",
      "High": "7.023",
      "Low": "6.99",
      "Change %": "0.56%"
  },
  {
      "Date": "7-Jun-23",
      "Price": "6.982",
      "Open": "6.984",
      "High": "6.986",
      "Low": "6.972",
      "Change %": "0.07%"
  },
  {
      "Date": "6-Jun-23",
      "Price": "6.977",
      "Open": "6.999",
      "High": "6.999",
      "Low": "6.974",
      "Change %": "-0.29%"
  },
  {
      "Date": "5-Jun-23",
      "Price": "6.997",
      "Open": "7.005",
      "High": "7.009",
      "Low": "6.994",
      "Change %": "0.19%"
  },
  {
      "Date": "2-Jun-23",
      "Price": "6.984",
      "Open": "6.992",
      "High": "6.992",
      "Low": "6.966",
      "Change %": "0.07%"
  },
  {
      "Date": "1-Jun-23",
      "Price": "6.979",
      "Open": "7.019",
      "High": "7.019",
      "Low": "6.963",
      "Change %": "-0.14%"
  },
  {
      "Date": "31-May-23",
      "Price": "6.989",
      "Open": "7.027",
      "High": "7.027",
      "Low": "6.982",
      "Change %": "-0.31%"
  },
  {
      "Date": "30-May-23",
      "Price": "7.011",
      "Open": "7.034",
      "High": "7.034",
      "Low": "7.01",
      "Change %": "-0.19%"
  },
  {
      "Date": "29-May-23",
      "Price": "7.024",
      "Open": "7.02",
      "High": "7.031",
      "Low": "7.002",
      "Change %": "0.30%"
  },
  {
      "Date": "26-May-23",
      "Price": "7.003",
      "Open": "7.026",
      "High": "7.026",
      "Low": "6.996",
      "Change %": "-0.06%"
  },
  {
      "Date": "25-May-23",
      "Price": "7.007",
      "Open": "7.013",
      "High": "7.015",
      "Low": "6.996",
      "Change %": "0.24%"
  },
  {
      "Date": "24-May-23",
      "Price": "6.99",
      "Open": "7.009",
      "High": "7.023",
      "Low": "6.982",
      "Change %": "-0.37%"
  },
  {
      "Date": "23-May-23",
      "Price": "7.016",
      "Open": "7.005",
      "High": "7.02",
      "Low": "6.995",
      "Change %": "0.44%"
  },
  {
      "Date": "22-May-23",
      "Price": "6.985",
      "Open": "6.989",
      "High": "7.001",
      "Low": "6.976",
      "Change %": "-0.27%"
  },
  {
      "Date": "19-May-23",
      "Price": "7.004",
      "Open": "7.013",
      "High": "7.016",
      "Low": "6.967",
      "Change %": "0.30%"
  },
  {
      "Date": "18-May-23",
      "Price": "6.983",
      "Open": "6.985",
      "High": "6.989",
      "Low": "6.968",
      "Change %": "0.16%"
  },
  {
      "Date": "17-May-23",
      "Price": "6.972",
      "Open": "6.992",
      "High": "6.992",
      "Low": "6.947",
      "Change %": "0.13%"
  },
  {
      "Date": "16-May-23",
      "Price": "6.963",
      "Open": "7.028",
      "High": "7.028",
      "Low": "6.961",
      "Change %": "-0.54%"
  },
  {
      "Date": "15-May-23",
      "Price": "7.001",
      "Open": "7.042",
      "High": "7.042",
      "Low": "6.978",
      "Change %": "0.09%"
  },
  {
      "Date": "12-May-23",
      "Price": "6.995",
      "Open": "7.027",
      "High": "7.03",
      "Low": "6.992",
      "Change %": "-0.40%"
  },
  {
      "Date": "11-May-23",
      "Price": "7.023",
      "Open": "7.021",
      "High": "7.033",
      "Low": "7.016",
      "Change %": "-0.18%"
  },
  {
      "Date": "10-May-23",
      "Price": "7.036",
      "Open": "7.056",
      "High": "7.061",
      "Low": "7.034",
      "Change %": "-0.14%"
  },
  {
      "Date": "9-May-23",
      "Price": "7.046",
      "Open": "7.07",
      "High": "7.07",
      "Low": "7.03",
      "Change %": "-0.01%"
  },
  {
      "Date": "8-May-23",
      "Price": "7.047",
      "Open": "7.049",
      "High": "7.05",
      "Low": "7.021",
      "Change %": "0.47%"
  },
  {
      "Date": "4-May-23",
      "Price": "7.014",
      "Open": "7.011",
      "High": "7.052",
      "Low": "6.991",
      "Change %": "0.04%"
  },
  {
      "Date": "3-May-23",
      "Price": "7.011",
      "Open": "7.085",
      "High": "7.085",
      "Low": "7.006",
      "Change %": "-1.34%"
  },
  {
      "Date": "2-May-23",
      "Price": "7.106",
      "Open": "7.135",
      "High": "7.138",
      "Low": "7.092",
      "Change %": "-0.14%"
  },
  {
      "Date": "28-Apr-23",
      "Price": "7.116",
      "Open": "7.131",
      "High": "7.131",
      "Low": "7.108",
      "Change %": "0.27%"
  },
  {
      "Date": "27-Apr-23",
      "Price": "7.097",
      "Open": "7.128",
      "High": "7.128",
      "Low": "7.094",
      "Change %": "-0.27%"
  },
  {
      "Date": "26-Apr-23",
      "Price": "7.116",
      "Open": "7.143",
      "High": "7.143",
      "Low": "7.098",
      "Change %": "0.08%"
  },
  {
      "Date": "25-Apr-23",
      "Price": "7.11",
      "Open": "7.088",
      "High": "7.131",
      "Low": "7.077",
      "Change %": "-0.01%"
  },
  {
      "Date": "24-Apr-23",
      "Price": "7.111",
      "Open": "7.161",
      "High": "7.161",
      "Low": "7.1",
      "Change %": "-1.03%"
  },
  {
      "Date": "21-Apr-23",
      "Price": "7.185",
      "Open": "7.237",
      "High": "7.237",
      "Low": "7.183",
      "Change %": "-0.48%"
  },
  {
      "Date": "20-Apr-23",
      "Price": "7.22",
      "Open": "7.237",
      "High": "7.237",
      "Low": "7.216",
      "Change %": "-0.10%"
  },
  {
      "Date": "19-Apr-23",
      "Price": "7.227",
      "Open": "7.224",
      "High": "7.235",
      "Low": "7.212",
      "Change %": "0.08%"
  },
  {
      "Date": "18-Apr-23",
      "Price": "7.221",
      "Open": "7.276",
      "High": "7.276",
      "Low": "7.22",
      "Change %": "-0.36%"
  },
  {
      "Date": "17-Apr-23",
      "Price": "7.247",
      "Open": "7.251",
      "High": "7.251",
      "Low": "7.239",
      "Change %": "0.25%"
  },
  {
      "Date": "13-Apr-23",
      "Price": "7.229",
      "Open": "7.204",
      "High": "7.234",
      "Low": "7.204",
      "Change %": "0.22%"
  },
  {
      "Date": "12-Apr-23",
      "Price": "7.213",
      "Open": "7.243",
      "High": "7.243",
      "Low": "7.212",
      "Change %": "-0.15%"
  },
  {
      "Date": "11-Apr-23",
      "Price": "7.224",
      "Open": "7.255",
      "High": "7.255",
      "Low": "7.222",
      "Change %": "-0.04%"
  },
  {
      "Date": "10-Apr-23",
      "Price": "7.227",
      "Open": "7.258",
      "High": "7.258",
      "Low": "7.196",
      "Change %": "0.11%"
  },
  {
      "Date": "6-Apr-23",
      "Price": "7.219",
      "Open": "7.285",
      "High": "7.288",
      "Low": "7.181",
      "Change %": "-0.74%"
  },
  {
      "Date": "5-Apr-23",
      "Price": "7.273",
      "Open": "7.297",
      "High": "7.297",
      "Low": "7.272",
      "Change %": "-0.57%"
  },
  {
      "Date": "3-Apr-23",
      "Price": "7.315",
      "Open": "7.37",
      "High": "7.37",
      "Low": "7.312",
      "Change %": "0.00%"
  },
  {
      "Date": "31-Mar-23",
      "Price": "7.315",
      "Open": "7.339",
      "High": "7.339",
      "Low": "7.28",
      "Change %": "0.26%"
  },
  {
      "Date": "29-Mar-23",
      "Price": "7.296",
      "Open": "7.335",
      "High": "7.342",
      "Low": "7.292",
      "Change %": "-0.34%"
  },
  {
      "Date": "28-Mar-23",
      "Price": "7.321",
      "Open": "7.325",
      "High": "7.335",
      "Low": "7.31",
      "Change %": "0.12%"
  },
  {
      "Date": "27-Mar-23",
      "Price": "7.312",
      "Open": "7.331",
      "High": "7.332",
      "Low": "7.306",
      "Change %": "-0.08%"
  },
  {
      "Date": "24-Mar-23",
      "Price": "7.318",
      "Open": "7.348",
      "High": "7.348",
      "Low": "7.314",
      "Change %": "-0.37%"
  },
  {
      "Date": "23-Mar-23",
      "Price": "7.345",
      "Open": "7.34",
      "High": "7.351",
      "Low": "7.315",
      "Change %": "-0.12%"
  },
  {
      "Date": "21-Mar-23",
      "Price": "7.354",
      "Open": "7.375",
      "High": "7.375",
      "Low": "7.341",
      "Change %": "0.27%"
  },
  {
      "Date": "20-Mar-23",
      "Price": "7.334",
      "Open": "7.341",
      "High": "7.344",
      "Low": "7.316",
      "Change %": "-0.27%"
  },
  {
      "Date": "17-Mar-23",
      "Price": "7.354",
      "Open": "7.372",
      "High": "7.379",
      "Low": "7.35",
      "Change %": "0.03%"
  },
  {
      "Date": "16-Mar-23",
      "Price": "7.352",
      "Open": "7.354",
      "High": "7.366",
      "Low": "7.33",
      "Change %": "-0.15%"
  },
  {
      "Date": "15-Mar-23",
      "Price": "7.363",
      "Open": "7.408",
      "High": "7.408",
      "Low": "7.359",
      "Change %": "-0.20%"
  },
  {
      "Date": "14-Mar-23",
      "Price": "7.378",
      "Open": "7.392",
      "High": "7.392",
      "Low": "7.336",
      "Change %": "0.18%"
  },
  {
      "Date": "13-Mar-23",
      "Price": "7.365",
      "Open": "7.393",
      "High": "7.393",
      "Low": "7.354",
      "Change %": "-0.86%"
  },
  {
      "Date": "10-Mar-23",
      "Price": "7.429",
      "Open": "7.426",
      "High": "7.435",
      "Low": "7.401",
      "Change %": "-0.16%"
  },
  {
      "Date": "9-Mar-23",
      "Price": "7.441",
      "Open": "7.465",
      "High": "7.465",
      "Low": "7.437",
      "Change %": "-0.24%"
  },
  {
      "Date": "8-Mar-23",
      "Price": "7.459",
      "Open": "7.455",
      "High": "7.473",
      "Low": "7.455",
      "Change %": "0.55%"
  },
  {
      "Date": "6-Mar-23",
      "Price": "7.418",
      "Open": "7.4",
      "High": "7.428",
      "Low": "7.4",
      "Change %": "0.03%"
  },
  {
      "Date": "3-Mar-23",
      "Price": "7.416",
      "Open": "7.453",
      "High": "7.453",
      "Low": "7.41",
      "Change %": "-0.19%"
  },
  {
      "Date": "2-Mar-23",
      "Price": "7.43",
      "Open": "7.456",
      "High": "7.461",
      "Low": "7.427",
      "Change %": "-0.05%"
  },
  {
      "Date": "1-Mar-23",
      "Price": "7.434",
      "Open": "7.468",
      "High": "7.468",
      "Low": "7.419",
      "Change %": "-0.31%"
  },
  {
      "Date": "28-Feb-23",
      "Price": "7.457",
      "Open": "7.446",
      "High": "7.465",
      "Low": "7.434",
      "Change %": "0.12%"
  },
  {
      "Date": "27-Feb-23",
      "Price": "7.448",
      "Open": "7.453",
      "High": "7.453",
      "Low": "7.431",
      "Change %": "0.39%"
  },
  {
      "Date": "24-Feb-23",
      "Price": "7.419",
      "Open": "7.39",
      "High": "7.422",
      "Low": "7.373",
      "Change %": "0.38%"
  },
  {
      "Date": "23-Feb-23",
      "Price": "7.391",
      "Open": "7.431",
      "High": "7.431",
      "Low": "7.375",
      "Change %": "-0.51%"
  },
  {
      "Date": "22-Feb-23",
      "Price": "7.429",
      "Open": "7.403",
      "High": "7.438",
      "Low": "7.401",
      "Change %": "0.51%"
  },
  {
      "Date": "21-Feb-23",
      "Price": "7.391",
      "Open": "7.393",
      "High": "7.396",
      "Low": "7.373",
      "Change %": "0.28%"
  },
  {
      "Date": "20-Feb-23",
      "Price": "7.37",
      "Open": "7.386",
      "High": "7.386",
      "Low": "7.361",
      "Change %": "-0.24%"
  },
  {
      "Date": "17-Feb-23",
      "Price": "7.388",
      "Open": "7.405",
      "High": "7.405",
      "Low": "7.358",
      "Change %": "0.63%"
  },
  {
      "Date": "16-Feb-23",
      "Price": "7.342",
      "Open": "7.363",
      "High": "7.363",
      "Low": "7.335",
      "Change %": "-0.07%"
  },
  {
      "Date": "15-Feb-23",
      "Price": "7.347",
      "Open": "7.378",
      "High": "7.381",
      "Low": "7.345",
      "Change %": "-0.33%"
  },
  {
      "Date": "14-Feb-23",
      "Price": "7.371",
      "Open": "7.408",
      "High": "7.408",
      "Low": "7.369",
      "Change %": "0.07%"
  },
  {
      "Date": "13-Feb-23",
      "Price": "7.366",
      "Open": "7.387",
      "High": "7.387",
      "Low": "7.36",
      "Change %": "0.04%"
  },
  {
      "Date": "10-Feb-23",
      "Price": "7.363",
      "Open": "7.356",
      "High": "7.37",
      "Low": "7.351",
      "Change %": "0.26%"
  },
  {
      "Date": "9-Feb-23",
      "Price": "7.344",
      "Open": "7.343",
      "High": "7.36",
      "Low": "7.338",
      "Change %": "0.00%"
  },
  {
      "Date": "8-Feb-23",
      "Price": "7.344",
      "Open": "7.33",
      "High": "7.358",
      "Low": "7.31",
      "Change %": "0.48%"
  },
  {
      "Date": "7-Feb-23",
      "Price": "7.309",
      "Open": "7.332",
      "High": "7.332",
      "Low": "7.306",
      "Change %": "-0.15%"
  },
  {
      "Date": "6-Feb-23",
      "Price": "7.32",
      "Open": "7.309",
      "High": "7.335",
      "Low": "7.309",
      "Change %": "0.59%"
  },
  {
      "Date": "4-Feb-23",
      "Price": "7.277",
      "Open": "7.278",
      "High": "7.278",
      "Low": "7.277",
      "Change %": "0.00%"
  },
  {
      "Date": "3-Feb-23",
      "Price": "7.277",
      "Open": "7.287",
      "High": "7.298",
      "Low": "7.277",
      "Change %": "-0.26%"
  },
  {
      "Date": "2-Feb-23",
      "Price": "7.296",
      "Open": "7.255",
      "High": "7.299",
      "Low": "7.245",
      "Change %": "0.26%"
  },
  {
      "Date": "1-Feb-23",
      "Price": "7.277",
      "Open": "7.416",
      "High": "7.416",
      "Low": "7.277",
      "Change %": "-0.90%"
  },
  {
      "Date": "31-Jan-23",
      "Price": "7.343",
      "Open": "7.405",
      "High": "7.405",
      "Low": "7.343",
      "Change %": "-0.78%"
  },
  {
      "Date": "30-Jan-23",
      "Price": "7.401",
      "Open": "7.392",
      "High": "7.407",
      "Low": "7.377",
      "Change %": "0.19%"
  },
  {
      "Date": "27-Jan-23",
      "Price": "7.387",
      "Open": "7.376",
      "High": "7.403",
      "Low": "7.373",
      "Change %": "0.54%"
  },
  {
      "Date": "25-Jan-23",
      "Price": "7.347",
      "Open": "7.344",
      "High": "7.358",
      "Low": "7.331",
      "Change %": "0.01%"
  },
  {
      "Date": "24-Jan-23",
      "Price": "7.346",
      "Open": "7.362",
      "High": "7.363",
      "Low": "7.338",
      "Change %": "-0.08%"
  },
  {
      "Date": "23-Jan-23",
      "Price": "7.352",
      "Open": "7.366",
      "High": "7.366",
      "Low": "7.35",
      "Change %": "0.07%"
  },
  {
      "Date": "20-Jan-23",
      "Price": "7.347",
      "Open": "7.332",
      "High": "7.356",
      "Low": "7.327",
      "Change %": "0.46%"
  },
  {
      "Date": "19-Jan-23",
      "Price": "7.313",
      "Open": "7.299",
      "High": "7.314",
      "Low": "7.281",
      "Change %": "-0.16%"
  },
  {
      "Date": "18-Jan-23",
      "Price": "7.325",
      "Open": "7.325",
      "High": "7.327",
      "Low": "7.313",
      "Change %": "-0.11%"
  },
  {
      "Date": "17-Jan-23",
      "Price": "7.333",
      "Open": "7.345",
      "High": "7.345",
      "Low": "7.331",
      "Change %": "0.08%"
  },
  {
      "Date": "16-Jan-23",
      "Price": "7.327",
      "Open": "7.326",
      "High": "7.329",
      "Low": "7.308",
      "Change %": "0.38%"
  },
  {
      "Date": "13-Jan-23",
      "Price": "7.299",
      "Open": "7.266",
      "High": "7.301",
      "Low": "7.266",
      "Change %": "0.18%"
  },
  {
      "Date": "12-Jan-23",
      "Price": "7.286",
      "Open": "7.295",
      "High": "7.299",
      "Low": "7.277",
      "Change %": "-0.07%"
  },
  {
      "Date": "11-Jan-23",
      "Price": "7.291",
      "Open": "7.332",
      "High": "7.332",
      "Low": "7.29",
      "Change %": "-0.30%"
  },
  {
      "Date": "10-Jan-23",
      "Price": "7.313",
      "Open": "7.332",
      "High": "7.34",
      "Low": "7.313",
      "Change %": "-0.39%"
  },
  {
      "Date": "9-Jan-23",
      "Price": "7.342",
      "Open": "7.338",
      "High": "7.349",
      "Low": "7.323",
      "Change %": "-0.42%"
  },
  {
      "Date": "6-Jan-23",
      "Price": "7.373",
      "Open": "7.358",
      "High": "7.378",
      "Low": "7.33",
      "Change %": "0.63%"
  },
  {
      "Date": "5-Jan-23",
      "Price": "7.327",
      "Open": "7.317",
      "High": "7.331",
      "Low": "7.317",
      "Change %": "0.08%"
  },
  {
      "Date": "4-Jan-23",
      "Price": "7.321",
      "Open": "7.317",
      "High": "7.324",
      "Low": "7.308",
      "Change %": "0.00%"
  },
  {
      "Date": "3-Jan-23",
      "Price": "7.321",
      "Open": "7.338",
      "High": "7.338",
      "Low": "7.321",
      "Change %": "-0.26%"
  },
  {
      "Date": "2-Jan-23",
      "Price": "7.34",
      "Open": "7.346",
      "High": "7.359",
      "Low": "7.336",
      "Change %": "0.18%"
  },
  {
      "Date": "30-Dec-22",
      "Price": "7.327",
      "Open": "7.313",
      "High": "7.335",
      "Low": "7.312",
      "Change %": "0.22%"
  },
  {
      "Date": "29-Dec-22",
      "Price": "7.311",
      "Open": "7.334",
      "High": "7.334",
      "Low": "7.308",
      "Change %": "-0.04%"
  },
  {
      "Date": "28-Dec-22",
      "Price": "7.314",
      "Open": "7.339",
      "High": "7.339",
      "Low": "7.311",
      "Change %": "0.08%"
  },
  {
      "Date": "27-Dec-22",
      "Price": "7.308",
      "Open": "7.324",
      "High": "7.324",
      "Low": "7.305",
      "Change %": "-0.15%"
  },
  {
      "Date": "26-Dec-22",
      "Price": "7.319",
      "Open": "7.337",
      "High": "7.337",
      "Low": "7.319",
      "Change %": "0.01%"
  },
  {
      "Date": "23-Dec-22",
      "Price": "7.318",
      "Open": "7.338",
      "High": "7.338",
      "Low": "7.306",
      "Change %": "0.03%"
  },
  {
      "Date": "22-Dec-22",
      "Price": "7.316",
      "Open": "7.3",
      "High": "7.319",
      "Low": "7.293",
      "Change %": "0.40%"
  },
  {
      "Date": "21-Dec-22",
      "Price": "7.287",
      "Open": "7.316",
      "High": "7.316",
      "Low": "7.281",
      "Change %": "-0.16%"
  },
  {
      "Date": "20-Dec-22",
      "Price": "7.299",
      "Open": "7.328",
      "High": "7.344",
      "Low": "7.299",
      "Change %": "0.03%"
  },
  {
      "Date": "19-Dec-22",
      "Price": "7.297",
      "Open": "7.302",
      "High": "7.302",
      "Low": "7.283",
      "Change %": "0.22%"
  },
  {
      "Date": "16-Dec-22",
      "Price": "7.281",
      "Open": "7.302",
      "High": "7.302",
      "Low": "7.273",
      "Change %": "0.18%"
  },
  {
      "Date": "15-Dec-22",
      "Price": "7.268",
      "Open": "7.233",
      "High": "7.271",
      "Low": "7.233",
      "Change %": "0.65%"
  },
  {
      "Date": "14-Dec-22",
      "Price": "7.221",
      "Open": "7.236",
      "High": "7.249",
      "Low": "7.211",
      "Change %": "-0.61%"
  },
  {
      "Date": "13-Dec-22",
      "Price": "7.265",
      "Open": "7.258",
      "High": "7.281",
      "Low": "7.258",
      "Change %": "-0.40%"
  },
  {
      "Date": "12-Dec-22",
      "Price": "7.294",
      "Open": "7.328",
      "High": "7.328",
      "Low": "7.287",
      "Change %": "-0.07%"
  },
  {
      "Date": "9-Dec-22",
      "Price": "7.299",
      "Open": "7.298",
      "High": "7.309",
      "Low": "7.286",
      "Change %": "0.15%"
  },
  {
      "Date": "8-Dec-22",
      "Price": "7.288",
      "Open": "7.256",
      "High": "7.289",
      "Low": "7.25",
      "Change %": "0.26%"
  },
  {
      "Date": "7-Dec-22",
      "Price": "7.269",
      "Open": "7.258",
      "High": "7.307",
      "Low": "7.226",
      "Change %": "0.28%"
  },
  {
      "Date": "6-Dec-22",
      "Price": "7.249",
      "Open": "7.242",
      "High": "7.264",
      "Low": "7.231",
      "Change %": "0.32%"
  },
  {
      "Date": "5-Dec-22",
      "Price": "7.226",
      "Open": "7.237",
      "High": "7.237",
      "Low": "7.209",
      "Change %": "0.07%"
  },
  {
      "Date": "2-Dec-22",
      "Price": "7.221",
      "Open": "7.2",
      "High": "7.236",
      "Low": "7.181",
      "Change %": "0.15%"
  },
  {
      "Date": "1-Dec-22",
      "Price": "7.21",
      "Open": "7.255",
      "High": "7.255",
      "Low": "7.205",
      "Change %": "-0.96%"
  },
  {
      "Date": "30-Nov-22",
      "Price": "7.28",
      "Open": "7.306",
      "High": "7.306",
      "Low": "7.28",
      "Change %": "0.01%"
  },
  {
      "Date": "29-Nov-22",
      "Price": "7.279",
      "Open": "7.294",
      "High": "7.297",
      "Low": "7.277",
      "Change %": "0.07%"
  },
  {
      "Date": "28-Nov-22",
      "Price": "7.274",
      "Open": "7.272",
      "High": "7.275",
      "Low": "7.25",
      "Change %": "-0.37%"
  },
  {
      "Date": "25-Nov-22",
      "Price": "7.301",
      "Open": "7.272",
      "High": "7.304",
      "Low": "7.245",
      "Change %": "0.63%"
  },
  {
      "Date": "24-Nov-22",
      "Price": "7.255",
      "Open": "7.264",
      "High": "7.283",
      "Low": "7.255",
      "Change %": "-0.49%"
  },
  {
      "Date": "23-Nov-22",
      "Price": "7.291",
      "Open": "7.282",
      "High": "7.299",
      "Low": "7.279",
      "Change %": "0.08%"
  },
  {
      "Date": "22-Nov-22",
      "Price": "7.285",
      "Open": "7.33",
      "High": "7.339",
      "Low": "7.285",
      "Change %": "-0.48%"
  },
  {
      "Date": "21-Nov-22",
      "Price": "7.32",
      "Open": "7.338",
      "High": "7.338",
      "Low": "7.285",
      "Change %": "0.18%"
  },
  {
      "Date": "18-Nov-22",
      "Price": "7.307",
      "Open": "7.314",
      "High": "7.32",
      "Low": "7.271",
      "Change %": "0.36%"
  },
  {
      "Date": "17-Nov-22",
      "Price": "7.281",
      "Open": "7.25",
      "High": "7.292",
      "Low": "7.243",
      "Change %": "0.10%"
  },
  {
      "Date": "16-Nov-22",
      "Price": "7.274",
      "Open": "7.272",
      "High": "7.288",
      "Low": "7.254",
      "Change %": "0.18%"
  },
  {
      "Date": "15-Nov-22",
      "Price": "7.261",
      "Open": "7.295",
      "High": "7.299",
      "Low": "7.258",
      "Change %": "-0.36%"
  },
  {
      "Date": "14-Nov-22",
      "Price": "7.287",
      "Open": "7.338",
      "High": "7.338",
      "Low": "7.277",
      "Change %": "-0.27%"
  },
  {
      "Date": "11-Nov-22",
      "Price": "7.307",
      "Open": "7.272",
      "High": "7.308",
      "Low": "7.235",
      "Change %": "-0.53%"
  },
  {
      "Date": "10-Nov-22",
      "Price": "7.346",
      "Open": "7.381",
      "High": "7.385",
      "Low": "7.334",
      "Change %": "-0.56%"
  },
  {
      "Date": "9-Nov-22",
      "Price": "7.387",
      "Open": "7.425",
      "High": "7.425",
      "Low": "7.381",
      "Change %": "-0.63%"
  },
  {
      "Date": "7-Nov-22",
      "Price": "7.434",
      "Open": "7.484",
      "High": "7.484",
      "Low": "7.434",
      "Change %": "-0.47%"
  },
  {
      "Date": "4-Nov-22",
      "Price": "7.469",
      "Open": "7.497",
      "High": "7.497",
      "Low": "7.457",
      "Change %": "-0.17%"
  },
  {
      "Date": "3-Nov-22",
      "Price": "7.482",
      "Open": "7.44",
      "High": "7.49",
      "Low": "7.44",
      "Change %": "1.05%"
  },
  {
      "Date": "2-Nov-22",
      "Price": "7.404",
      "Open": "7.421",
      "High": "7.424",
      "Low": "7.398",
      "Change %": "0.11%"
  },
  {
      "Date": "1-Nov-22",
      "Price": "7.396",
      "Open": "7.435",
      "High": "7.437",
      "Low": "7.396",
      "Change %": "-0.66%"
  },
  {
      "Date": "31-Oct-22",
      "Price": "7.445",
      "Open": "7.432",
      "High": "7.453",
      "Low": "7.418",
      "Change %": "0.39%"
  },
  {
      "Date": "28-Oct-22",
      "Price": "7.416",
      "Open": "7.45",
      "High": "7.45",
      "Low": "7.378",
      "Change %": "0.05%"
  },
  {
      "Date": "27-Oct-22",
      "Price": "7.412",
      "Open": "7.41",
      "High": "7.42",
      "Low": "7.372",
      "Change %": "-0.40%"
  },
  {
      "Date": "25-Oct-22",
      "Price": "7.442",
      "Open": "7.505",
      "High": "7.505",
      "Low": "7.44",
      "Change %": "-0.92%"
  },
  {
      "Date": "21-Oct-22",
      "Price": "7.511",
      "Open": "7.541",
      "High": "7.541",
      "Low": "7.504",
      "Change %": "0.47%"
  },
  {
      "Date": "20-Oct-22",
      "Price": "7.476",
      "Open": "7.505",
      "High": "7.516",
      "Low": "7.476",
      "Change %": "0.34%"
  },
  {
      "Date": "19-Oct-22",
      "Price": "7.451",
      "Open": "7.439",
      "High": "7.458",
      "Low": "7.395",
      "Change %": "0.34%"
  },
  {
      "Date": "18-Oct-22",
      "Price": "7.426",
      "Open": "7.417",
      "High": "7.427",
      "Low": "7.368",
      "Change %": "0.26%"
  },
  {
      "Date": "17-Oct-22",
      "Price": "7.407",
      "Open": "7.461",
      "High": "7.461",
      "Low": "7.392",
      "Change %": "-0.84%"
  },
  {
      "Date": "14-Oct-22",
      "Price": "7.47",
      "Open": "7.469",
      "High": "7.481",
      "Low": "7.416",
      "Change %": "0.65%"
  },
  {
      "Date": "13-Oct-22",
      "Price": "7.422",
      "Open": "7.446",
      "High": "7.451",
      "Low": "7.403",
      "Change %": "-0.17%"
  },
  {
      "Date": "12-Oct-22",
      "Price": "7.435",
      "Open": "7.433",
      "High": "7.441",
      "Low": "7.372",
      "Change %": "0.12%"
  },
  {
      "Date": "11-Oct-22",
      "Price": "7.426",
      "Open": "7.532",
      "High": "7.532",
      "Low": "7.426",
      "Change %": "-0.67%"
  },
  {
      "Date": "10-Oct-22",
      "Price": "7.476",
      "Open": "7.498",
      "High": "7.513",
      "Low": "7.471",
      "Change %": "0.21%"
  },
  {
      "Date": "7-Oct-22",
      "Price": "7.46",
      "Open": "7.49",
      "High": "7.518",
      "Low": "7.458",
      "Change %": "0.08%"
  },
  {
      "Date": "6-Oct-22",
      "Price": "7.454",
      "Open": "7.49",
      "High": "7.49",
      "Low": "7.446",
      "Change %": "1.25%"
  },
  {
      "Date": "4-Oct-22",
      "Price": "7.362",
      "Open": "7.454",
      "High": "7.454",
      "Low": "7.361",
      "Change %": "-1.42%"
  },
  {
      "Date": "3-Oct-22",
      "Price": "7.468",
      "Open": "7.403",
      "High": "7.47",
      "Low": "7.403",
      "Change %": "0.95%"
  },
  {
      "Date": "30-Sep-22",
      "Price": "7.398",
      "Open": "7.423",
      "High": "7.423",
      "Low": "7.301",
      "Change %": "0.79%"
  },
  {
      "Date": "29-Sep-22",
      "Price": "7.34",
      "Open": "7.294",
      "High": "7.343",
      "Low": "7.286",
      "Change %": "0.10%"
  },
  {
      "Date": "28-Sep-22",
      "Price": "7.333",
      "Open": "7.351",
      "High": "7.352",
      "Low": "7.32",
      "Change %": "0.56%"
  },
  {
      "Date": "27-Sep-22",
      "Price": "7.292",
      "Open": "7.41",
      "High": "7.41",
      "Low": "7.283",
      "Change %": "-0.91%"
  },
  {
      "Date": "26-Sep-22",
      "Price": "7.359",
      "Open": "7.413",
      "High": "7.422",
      "Low": "7.359",
      "Change %": "-0.46%"
  },
  {
      "Date": "23-Sep-22",
      "Price": "7.393",
      "Open": "7.366",
      "High": "7.396",
      "Low": "7.352",
      "Change %": "1.11%"
  },
  {
      "Date": "22-Sep-22",
      "Price": "7.312",
      "Open": "7.312",
      "High": "7.312",
      "Low": "7.312",
      "Change %": "1.09%"
  },
  {
      "Date": "21-Sep-22",
      "Price": "7.233",
      "Open": "7.27",
      "High": "7.271",
      "Low": "7.233",
      "Change %": "-0.84%"
  },
  {
      "Date": "20-Sep-22",
      "Price": "7.294",
      "Open": "7.3",
      "High": "7.3",
      "Low": "7.271",
      "Change %": "0.23%"
  },
  {
      "Date": "19-Sep-22",
      "Price": "7.277",
      "Open": "7.294",
      "High": "7.294",
      "Low": "7.232",
      "Change %": "0.15%"
  },
  {
      "Date": "16-Sep-22",
      "Price": "7.266",
      "Open": "7.255",
      "High": "7.291",
      "Low": "7.243",
      "Change %": "0.39%"
  },
  {
      "Date": "15-Sep-22",
      "Price": "7.238",
      "Open": "7.17",
      "High": "7.258",
      "Low": "7.144",
      "Change %": "1.16%"
  },
  {
      "Date": "14-Sep-22",
      "Price": "7.155",
      "Open": "7.163",
      "High": "7.163",
      "Low": "7.128",
      "Change %": "0.66%"
  },
  {
      "Date": "13-Sep-22",
      "Price": "7.108",
      "Open": "7.208",
      "High": "7.208",
      "Low": "7.106",
      "Change %": "-1.02%"
  },
  {
      "Date": "12-Sep-22",
      "Price": "7.181",
      "Open": "7.182",
      "High": "7.185",
      "Low": "7.162",
      "Change %": "0.20%"
  },
  {
      "Date": "9-Sep-22",
      "Price": "7.167",
      "Open": "7.159",
      "High": "7.172",
      "Low": "7.135",
      "Change %": "0.45%"
  },
  {
      "Date": "8-Sep-22",
      "Price": "7.135",
      "Open": "7.139",
      "High": "7.152",
      "Low": "7.122",
      "Change %": "-0.70%"
  },
  {
      "Date": "7-Sep-22",
      "Price": "7.185",
      "Open": "7.208",
      "High": "7.208",
      "Low": "7.175",
      "Change %": "0.08%"
  },
  {
      "Date": "6-Sep-22",
      "Price": "7.179",
      "Open": "7.221",
      "High": "7.221",
      "Low": "7.179",
      "Change %": "-0.54%"
  },
  {
      "Date": "5-Sep-22",
      "Price": "7.218",
      "Open": "7.232",
      "High": "7.232",
      "Low": "7.201",
      "Change %": "-0.19%"
  },
  {
      "Date": "2-Sep-22",
      "Price": "7.232",
      "Open": "7.238",
      "High": "7.248",
      "Low": "7.224",
      "Change %": "0.24%"
  },
  {
      "Date": "1-Sep-22",
      "Price": "7.215",
      "Open": "7.23",
      "High": "7.23",
      "Low": "7.172",
      "Change %": "0.38%"
  },
  {
      "Date": "30-Aug-22",
      "Price": "7.188",
      "Open": "7.28",
      "High": "7.28",
      "Low": "7.186",
      "Change %": "-0.90%"
  },
  {
      "Date": "29-Aug-22",
      "Price": "7.253",
      "Open": "7.256",
      "High": "7.271",
      "Low": "7.238",
      "Change %": "0.50%"
  },
  {
      "Date": "26-Aug-22",
      "Price": "7.217",
      "Open": "7.276",
      "High": "7.291",
      "Low": "7.203",
      "Change %": "-1.03%"
  },
  {
      "Date": "25-Aug-22",
      "Price": "7.292",
      "Open": "7.32",
      "High": "7.32",
      "Low": "7.292",
      "Change %": "0.08%"
  },
  {
      "Date": "24-Aug-22",
      "Price": "7.286",
      "Open": "7.311",
      "High": "7.311",
      "Low": "7.273",
      "Change %": "0.07%"
  },
  {
      "Date": "23-Aug-22",
      "Price": "7.281",
      "Open": "7.305",
      "High": "7.31",
      "Low": "7.272",
      "Change %": "0.15%"
  },
  {
      "Date": "22-Aug-22",
      "Price": "7.27",
      "Open": "7.295",
      "High": "7.306",
      "Low": "7.265",
      "Change %": "0.08%"
  },
  {
      "Date": "19-Aug-22",
      "Price": "7.264",
      "Open": "7.264",
      "High": "7.295",
      "Low": "7.256",
      "Change %": "0.30%"
  },
  {
      "Date": "18-Aug-22",
      "Price": "7.242",
      "Open": "7.202",
      "High": "7.262",
      "Low": "7.196",
      "Change %": "0.84%"
  },
  {
      "Date": "17-Aug-22",
      "Price": "7.182",
      "Open": "7.251",
      "High": "7.251",
      "Low": "7.164",
      "Change %": "-1.47%"
  },
  {
      "Date": "12-Aug-22",
      "Price": "7.289",
      "Open": "7.331",
      "High": "7.331",
      "Low": "7.286",
      "Change %": "0.30%"
  },
  {
      "Date": "11-Aug-22",
      "Price": "7.267",
      "Open": "7.339",
      "High": "7.339",
      "Low": "7.241",
      "Change %": "-0.59%"
  },
  {
      "Date": "10-Aug-22",
      "Price": "7.31",
      "Open": "7.366",
      "High": "7.366",
      "Low": "7.307",
      "Change %": "-0.52%"
  },
  {
      "Date": "8-Aug-22",
      "Price": "7.348",
      "Open": "7.365",
      "High": "7.365",
      "Low": "7.338",
      "Change %": "0.66%"
  },
  {
      "Date": "5-Aug-22",
      "Price": "7.3",
      "Open": "7.136",
      "High": "7.322",
      "Low": "7.11",
      "Change %": "2.00%"
  },
  {
      "Date": "4-Aug-22",
      "Price": "7.157",
      "Open": "7.219",
      "High": "7.236",
      "Low": "7.151",
      "Change %": "-1.17%"
  },
  {
      "Date": "3-Aug-22",
      "Price": "7.242",
      "Open": "7.25",
      "High": "7.253",
      "Low": "7.227",
      "Change %": "0.64%"
  },
  {
      "Date": "2-Aug-22",
      "Price": "7.196",
      "Open": "7.204",
      "High": "7.228",
      "Low": "7.185",
      "Change %": "-0.61%"
  },
  {
      "Date": "1-Aug-22",
      "Price": "7.24",
      "Open": "7.357",
      "High": "7.357",
      "Low": "7.24",
      "Change %": "-1.09%"
  },
  {
      "Date": "29-Jul-22",
      "Price": "7.32",
      "Open": "7.301",
      "High": "7.33",
      "Low": "7.255",
      "Change %": "-0.16%"
  },
  {
      "Date": "28-Jul-22",
      "Price": "7.332",
      "Open": "7.372",
      "High": "7.372",
      "Low": "7.309",
      "Change %": "-0.08%"
  },
  {
      "Date": "27-Jul-22",
      "Price": "7.338",
      "Open": "7.385",
      "High": "7.385",
      "Low": "7.336",
      "Change %": "-0.39%"
  },
  {
      "Date": "26-Jul-22",
      "Price": "7.367",
      "Open": "7.41",
      "High": "7.414",
      "Low": "7.367",
      "Change %": "-0.32%"
  },
  {
      "Date": "25-Jul-22",
      "Price": "7.391",
      "Open": "7.399",
      "High": "7.399",
      "Low": "7.36",
      "Change %": "-0.31%"
  },
  {
      "Date": "22-Jul-22",
      "Price": "7.414",
      "Open": "7.417",
      "High": "7.432",
      "Low": "7.402",
      "Change %": "-0.35%"
  },
  {
      "Date": "21-Jul-22",
      "Price": "7.44",
      "Open": "7.471",
      "High": "7.471",
      "Low": "7.44",
      "Change %": "-0.12%"
  },
  {
      "Date": "20-Jul-22",
      "Price": "7.449",
      "Open": "7.471",
      "High": "7.471",
      "Low": "7.434",
      "Change %": "0.20%"
  },
  {
      "Date": "19-Jul-22",
      "Price": "7.434",
      "Open": "7.471",
      "High": "7.471",
      "Low": "7.432",
      "Change %": "-0.04%"
  },
  {
      "Date": "18-Jul-22",
      "Price": "7.437",
      "Open": "7.429",
      "High": "7.443",
      "Low": "7.4",
      "Change %": "-0.01%"
  },
  {
      "Date": "15-Jul-22",
      "Price": "7.438",
      "Open": "7.416",
      "High": "7.447",
      "Low": "7.401",
      "Change %": "0.73%"
  },
  {
      "Date": "14-Jul-22",
      "Price": "7.384",
      "Open": "7.362",
      "High": "7.407",
      "Low": "7.362",
      "Change %": "0.64%"
  },
  {
      "Date": "13-Jul-22",
      "Price": "7.337",
      "Open": "7.383",
      "High": "7.383",
      "Low": "7.333",
      "Change %": "-0.73%"
  },
  {
      "Date": "12-Jul-22",
      "Price": "7.391",
      "Open": "7.4",
      "High": "7.447",
      "Low": "7.391",
      "Change %": "-0.48%"
  },
  {
      "Date": "11-Jul-22",
      "Price": "7.427",
      "Open": "7.453",
      "High": "7.475",
      "Low": "7.418",
      "Change %": "0.16%"
  },
  {
      "Date": "8-Jul-22",
      "Price": "7.415",
      "Open": "7.392",
      "High": "7.419",
      "Low": "7.372",
      "Change %": "0.83%"
  },
  {
      "Date": "7-Jul-22",
      "Price": "7.354",
      "Open": "7.305",
      "High": "7.36",
      "Low": "7.291",
      "Change %": "0.82%"
  },
  {
      "Date": "6-Jul-22",
      "Price": "7.294",
      "Open": "7.33",
      "High": "7.33",
      "Low": "7.281",
      "Change %": "-1.31%"
  },
  {
      "Date": "5-Jul-22",
      "Price": "7.391",
      "Open": "7.406",
      "High": "7.42",
      "Low": "7.382",
      "Change %": "0.23%"
  },
  {
      "Date": "4-Jul-22",
      "Price": "7.374",
      "Open": "7.398",
      "High": "7.398",
      "Low": "7.355",
      "Change %": "-0.67%"
  },
  {
      "Date": "1-Jul-22",
      "Price": "7.424",
      "Open": "7.427",
      "High": "7.427",
      "Low": "7.385",
      "Change %": "-0.35%"
  },
  {
      "Date": "30-Jun-22",
      "Price": "7.45",
      "Open": "7.443",
      "High": "7.455",
      "Low": "7.417",
      "Change %": "-0.12%"
  },
  {
      "Date": "29-Jun-22",
      "Price": "7.459",
      "Open": "7.457",
      "High": "7.478",
      "Low": "7.437",
      "Change %": "-0.09%"
  },
  {
      "Date": "28-Jun-22",
      "Price": "7.466",
      "Open": "7.428",
      "High": "7.468",
      "Low": "7.428",
      "Change %": "0.80%"
  },
  {
      "Date": "27-Jun-22",
      "Price": "7.407",
      "Open": "7.481",
      "High": "7.481",
      "Low": "7.407",
      "Change %": "-0.50%"
  },
  {
      "Date": "24-Jun-22",
      "Price": "7.444",
      "Open": "7.458",
      "High": "7.47",
      "Low": "7.42",
      "Change %": "0.34%"
  },
  {
      "Date": "23-Jun-22",
      "Price": "7.419",
      "Open": "7.37",
      "High": "7.443",
      "Low": "7.365",
      "Change %": "0.31%"
  },
  {
      "Date": "22-Jun-22",
      "Price": "7.396",
      "Open": "7.448",
      "High": "7.448",
      "Low": "7.382",
      "Change %": "-1.15%"
  },
  {
      "Date": "21-Jun-22",
      "Price": "7.482",
      "Open": "7.457",
      "High": "7.484",
      "Low": "7.457",
      "Change %": "0.74%"
  },
  {
      "Date": "20-Jun-22",
      "Price": "7.427",
      "Open": "7.503",
      "High": "7.503",
      "Low": "7.427",
      "Change %": "-1.58%"
  },
  {
      "Date": "17-Jun-22",
      "Price": "7.546",
      "Open": "7.596",
      "High": "7.611",
      "Low": "7.544",
      "Change %": "-0.93%"
  },
  {
      "Date": "16-Jun-22",
      "Price": "7.617",
      "Open": "7.572",
      "High": "7.617",
      "Low": "7.551",
      "Change %": "0.33%"
  },
  {
      "Date": "15-Jun-22",
      "Price": "7.592",
      "Open": "7.61",
      "High": "7.614",
      "Low": "7.578",
      "Change %": "0.15%"
  },
  {
      "Date": "14-Jun-22",
      "Price": "7.581",
      "Open": "7.604",
      "High": "7.614",
      "Low": "7.574",
      "Change %": "-0.30%"
  },
  {
      "Date": "13-Jun-22",
      "Price": "7.604",
      "Open": "7.594",
      "High": "7.615",
      "Low": "7.582",
      "Change %": "1.13%"
  },
  {
      "Date": "10-Jun-22",
      "Price": "7.519",
      "Open": "7.512",
      "High": "7.527",
      "Low": "7.503",
      "Change %": "0.31%"
  },
  {
      "Date": "9-Jun-22",
      "Price": "7.496",
      "Open": "7.528",
      "High": "7.533",
      "Low": "7.496",
      "Change %": "0.03%"
  },
  {
      "Date": "8-Jun-22",
      "Price": "7.494",
      "Open": "7.523",
      "High": "7.562",
      "Low": "7.438",
      "Change %": "-0.32%"
  },
  {
      "Date": "7-Jun-22",
      "Price": "7.518",
      "Open": "7.538",
      "High": "7.538",
      "Low": "7.51",
      "Change %": "0.24%"
  },
  {
      "Date": "6-Jun-22",
      "Price": "7.5",
      "Open": "7.5",
      "High": "7.512",
      "Low": "7.49",
      "Change %": "0.59%"
  },
  {
      "Date": "3-Jun-22",
      "Price": "7.456",
      "Open": "7.484",
      "High": "7.484",
      "Low": "7.442",
      "Change %": "0.30%"
  },
  {
      "Date": "2-Jun-22",
      "Price": "7.434",
      "Open": "7.42",
      "High": "7.441",
      "Low": "7.409",
      "Change %": "0.27%"
  },
  {
      "Date": "1-Jun-22",
      "Price": "7.414",
      "Open": "7.437",
      "High": "7.438",
      "Low": "7.397",
      "Change %": "-0.01%"
  },
  {
      "Date": "31-May-22",
      "Price": "7.415",
      "Open": "7.444",
      "High": "7.46",
      "Low": "7.412",
      "Change %": "0.08%"
  },
  {
      "Date": "30-May-22",
      "Price": "7.409",
      "Open": "7.376",
      "High": "7.413",
      "Low": "7.364",
      "Change %": "0.80%"
  },
  {
      "Date": "27-May-22",
      "Price": "7.35",
      "Open": "7.328",
      "High": "7.376",
      "Low": "7.319",
      "Change %": "0.77%"
  },
  {
      "Date": "26-May-22",
      "Price": "7.294",
      "Open": "7.314",
      "High": "7.314",
      "Low": "7.272",
      "Change %": "-0.15%"
  },
  {
      "Date": "25-May-22",
      "Price": "7.305",
      "Open": "7.367",
      "High": "7.367",
      "Low": "7.297",
      "Change %": "-0.73%"
  },
  {
      "Date": "24-May-22",
      "Price": "7.359",
      "Open": "7.397",
      "High": "7.397",
      "Low": "7.344",
      "Change %": "-0.37%"
  },
  {
      "Date": "23-May-22",
      "Price": "7.386",
      "Open": "7.42",
      "High": "7.42",
      "Low": "7.348",
      "Change %": "0.37%"
  },
  {
      "Date": "20-May-22",
      "Price": "7.359",
      "Open": "7.336",
      "High": "7.366",
      "Low": "7.335",
      "Change %": "0.53%"
  },
  {
      "Date": "19-May-22",
      "Price": "7.32",
      "Open": "7.343",
      "High": "7.363",
      "Low": "7.319",
      "Change %": "-0.48%"
  },
  {
      "Date": "18-May-22",
      "Price": "7.355",
      "Open": "7.389",
      "High": "7.389",
      "Low": "7.341",
      "Change %": "-0.15%"
  },
  {
      "Date": "17-May-22",
      "Price": "7.366",
      "Open": "7.34",
      "High": "7.381",
      "Low": "7.34",
      "Change %": "0.66%"
  },
  {
      "Date": "13-May-22",
      "Price": "7.318",
      "Open": "7.308",
      "High": "7.34",
      "Low": "7.297",
      "Change %": "1.02%"
  },
  {
      "Date": "12-May-22",
      "Price": "7.244",
      "Open": "7.235",
      "High": "7.293",
      "Low": "7.192",
      "Change %": "0.40%"
  },
  {
      "Date": "11-May-22",
      "Price": "7.215",
      "Open": "7.296",
      "High": "7.334",
      "Low": "7.208",
      "Change %": "-1.18%"
  },
  {
      "Date": "10-May-22",
      "Price": "7.301",
      "Open": "7.402",
      "High": "7.402",
      "Low": "7.296",
      "Change %": "-2.20%"
  },
  {
      "Date": "9-May-22",
      "Price": "7.465",
      "Open": "7.491",
      "High": "7.491",
      "Low": "7.421",
      "Change %": "0.19%"
  },
  {
      "Date": "6-May-22",
      "Price": "7.451",
      "Open": "7.448",
      "High": "7.47",
      "Low": "7.411",
      "Change %": "0.65%"
  },
  {
      "Date": "5-May-22",
      "Price": "7.403",
      "Open": "7.409",
      "High": "7.452",
      "Low": "7.38",
      "Change %": "0.34%"
  },
  {
      "Date": "4-May-22",
      "Price": "7.378",
      "Open": "7.159",
      "High": "7.409",
      "Low": "7.129",
      "Change %": "3.64%"
  },
  {
      "Date": "2-May-22",
      "Price": "7.119",
      "Open": "7.138",
      "High": "7.15",
      "Low": "7.116",
      "Change %": "-0.28%"
  },
  {
      "Date": "29-Apr-22",
      "Price": "7.139",
      "Open": "7.179",
      "High": "7.182",
      "Low": "7.126",
      "Change %": "-0.22%"
  },
  {
      "Date": "28-Apr-22",
      "Price": "7.155",
      "Open": "7.1",
      "High": "7.157",
      "Low": "7.1",
      "Change %": "1.02%"
  },
  {
      "Date": "27-Apr-22",
      "Price": "7.083",
      "Open": "7.071",
      "High": "7.085",
      "Low": "7.053",
      "Change %": "0.43%"
  },
  {
      "Date": "26-Apr-22",
      "Price": "7.053",
      "Open": "7.082",
      "High": "7.098",
      "Low": "7.038",
      "Change %": "0.14%"
  },
  {
      "Date": "25-Apr-22",
      "Price": "7.043",
      "Open": "7.219",
      "High": "7.219",
      "Low": "7.038",
      "Change %": "-1.73%"
  },
  {
      "Date": "22-Apr-22",
      "Price": "7.167",
      "Open": "7.173",
      "High": "7.192",
      "Low": "7.158",
      "Change %": "0.32%"
  },
  {
      "Date": "21-Apr-22",
      "Price": "7.144",
      "Open": "7.114",
      "High": "7.16",
      "Low": "7.101",
      "Change %": "0.55%"
  },
  {
      "Date": "20-Apr-22",
      "Price": "7.105",
      "Open": "7.179",
      "High": "7.179",
      "Low": "7.103",
      "Change %": "-0.64%"
  },
  {
      "Date": "19-Apr-22",
      "Price": "7.151",
      "Open": "7.181",
      "High": "7.181",
      "Low": "7.128",
      "Change %": "-0.03%"
  },
  {
      "Date": "18-Apr-22",
      "Price": "7.153",
      "Open": "7.248",
      "High": "7.263",
      "Low": "7.153",
      "Change %": "-0.86%"
  },
  {
      "Date": "13-Apr-22",
      "Price": "7.215",
      "Open": "7.285",
      "High": "7.285",
      "Low": "7.213",
      "Change %": "0.36%"
  },
  {
      "Date": "12-Apr-22",
      "Price": "7.189",
      "Open": "7.184",
      "High": "7.195",
      "Low": "7.153",
      "Change %": "0.56%"
  },
  {
      "Date": "11-Apr-22",
      "Price": "7.149",
      "Open": "7.187",
      "High": "7.192",
      "Low": "7.132",
      "Change %": "0.42%"
  },
  {
      "Date": "8-Apr-22",
      "Price": "7.119",
      "Open": "6.996",
      "High": "7.121",
      "Low": "6.916",
      "Change %": "2.96%"
  },
  {
      "Date": "7-Apr-22",
      "Price": "6.914",
      "Open": "6.916",
      "High": "6.921",
      "Low": "6.901",
      "Change %": "-0.04%"
  },
  {
      "Date": "6-Apr-22",
      "Price": "6.917",
      "Open": "6.93",
      "High": "6.93",
      "Low": "6.911",
      "Change %": "0.26%"
  },
  {
      "Date": "5-Apr-22",
      "Price": "6.899",
      "Open": "6.915",
      "High": "6.915",
      "Low": "6.891",
      "Change %": "0.01%"
  },
  {
      "Date": "4-Apr-22",
      "Price": "6.898",
      "Open": "6.951",
      "High": "6.951",
      "Low": "6.895",
      "Change %": "0.80%"
  },
  {
      "Date": "31-Mar-22",
      "Price": "6.843",
      "Open": "6.779",
      "High": "6.843",
      "Low": "6.773",
      "Change %": "0.87%"
  },
  {
      "Date": "30-Mar-22",
      "Price": "6.784",
      "Open": "6.85",
      "High": "6.85",
      "Low": "6.781",
      "Change %": "-0.54%"
  },
  {
      "Date": "29-Mar-22",
      "Price": "6.821",
      "Open": "6.889",
      "High": "6.889",
      "Low": "6.817",
      "Change %": "-0.22%"
  },
  {
      "Date": "28-Mar-22",
      "Price": "6.836",
      "Open": "6.863",
      "High": "6.863",
      "Low": "6.828",
      "Change %": "0.37%"
  },
  {
      "Date": "25-Mar-22",
      "Price": "6.811",
      "Open": "6.828",
      "High": "6.831",
      "Low": "6.811",
      "Change %": "-0.34%"
  },
  {
      "Date": "24-Mar-22",
      "Price": "6.834",
      "Open": "6.848",
      "High": "6.848",
      "Low": "6.821",
      "Change %": "0.04%"
  },
  {
      "Date": "23-Mar-22",
      "Price": "6.831",
      "Open": "6.849",
      "High": "6.849",
      "Low": "6.816",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Mar-22",
      "Price": "6.831",
      "Open": "6.835",
      "High": "6.844",
      "Low": "6.819",
      "Change %": "0.74%"
  },
  {
      "Date": "21-Mar-22",
      "Price": "6.781",
      "Open": "6.821",
      "High": "6.821",
      "Low": "6.781",
      "Change %": "0.04%"
  },
  {
      "Date": "17-Mar-22",
      "Price": "6.778",
      "Open": "6.804",
      "High": "6.821",
      "Low": "6.774",
      "Change %": "-0.15%"
  },
  {
      "Date": "16-Mar-22",
      "Price": "6.788",
      "Open": "6.825",
      "High": "6.832",
      "Low": "6.788",
      "Change %": "-0.48%"
  },
  {
      "Date": "15-Mar-22",
      "Price": "6.821",
      "Open": "6.871",
      "High": "6.871",
      "Low": "6.816",
      "Change %": "-0.48%"
  },
  {
      "Date": "14-Mar-22",
      "Price": "6.854",
      "Open": "6.892",
      "High": "6.892",
      "Low": "6.829",
      "Change %": "-0.01%"
  },
  {
      "Date": "11-Mar-22",
      "Price": "6.855",
      "Open": "6.806",
      "High": "6.864",
      "Low": "6.792",
      "Change %": "0.68%"
  },
  {
      "Date": "10-Mar-22",
      "Price": "6.809",
      "Open": "6.842",
      "High": "6.842",
      "Low": "6.789",
      "Change %": "-0.53%"
  },
  {
      "Date": "9-Mar-22",
      "Price": "6.845",
      "Open": "6.919",
      "High": "6.919",
      "Low": "6.842",
      "Change %": "-0.73%"
  },
  {
      "Date": "8-Mar-22",
      "Price": "6.895",
      "Open": "6.892",
      "High": "6.904",
      "Low": "6.881",
      "Change %": "0.10%"
  },
  {
      "Date": "7-Mar-22",
      "Price": "6.888",
      "Open": "6.856",
      "High": "6.896",
      "Low": "6.856",
      "Change %": "1.10%"
  },
  {
      "Date": "4-Mar-22",
      "Price": "6.813",
      "Open": "6.813",
      "High": "6.824",
      "Low": "6.802",
      "Change %": "-0.19%"
  },
  {
      "Date": "3-Mar-22",
      "Price": "6.826",
      "Open": "6.856",
      "High": "6.856",
      "Low": "6.823",
      "Change %": "0.18%"
  },
  {
      "Date": "2-Mar-22",
      "Price": "6.814",
      "Open": "6.799",
      "High": "6.817",
      "Low": "6.797",
      "Change %": "0.65%"
  },
  {
      "Date": "28-Feb-22",
      "Price": "6.77",
      "Open": "6.777",
      "High": "6.777",
      "Low": "6.749",
      "Change %": "0.31%"
  },
  {
      "Date": "25-Feb-22",
      "Price": "6.749",
      "Open": "6.77",
      "High": "6.77",
      "Low": "6.738",
      "Change %": "-0.15%"
  },
  {
      "Date": "24-Feb-22",
      "Price": "6.759",
      "Open": "6.777",
      "High": "6.784",
      "Low": "6.749",
      "Change %": "0.30%"
  },
  {
      "Date": "23-Feb-22",
      "Price": "6.739",
      "Open": "6.792",
      "High": "6.792",
      "Low": "6.739",
      "Change %": "-0.15%"
  },
  {
      "Date": "22-Feb-22",
      "Price": "6.749",
      "Open": "6.783",
      "High": "6.783",
      "Low": "6.723",
      "Change %": "0.84%"
  },
  {
      "Date": "21-Feb-22",
      "Price": "6.693",
      "Open": "6.681",
      "High": "6.699",
      "Low": "6.667",
      "Change %": "0.45%"
  },
  {
      "Date": "18-Feb-22",
      "Price": "6.663",
      "Open": "6.686",
      "High": "6.686",
      "Low": "6.656",
      "Change %": "-0.16%"
  },
  {
      "Date": "17-Feb-22",
      "Price": "6.674",
      "Open": "6.714",
      "High": "6.714",
      "Low": "6.667",
      "Change %": "-0.18%"
  },
  {
      "Date": "16-Feb-22",
      "Price": "6.686",
      "Open": "6.707",
      "High": "6.707",
      "Low": "6.656",
      "Change %": "0.19%"
  },
  {
      "Date": "15-Feb-22",
      "Price": "6.673",
      "Open": "6.664",
      "High": "6.697",
      "Low": "6.615",
      "Change %": "0.09%"
  },
  {
      "Date": "14-Feb-22",
      "Price": "6.667",
      "Open": "6.725",
      "High": "6.726",
      "Low": "6.66",
      "Change %": "-0.46%"
  },
  {
      "Date": "11-Feb-22",
      "Price": "6.698",
      "Open": "6.763",
      "High": "6.776",
      "Low": "6.698",
      "Change %": "-0.40%"
  },
  {
      "Date": "10-Feb-22",
      "Price": "6.725",
      "Open": "6.818",
      "High": "6.818",
      "Low": "6.688",
      "Change %": "-1.07%"
  },
  {
      "Date": "9-Feb-22",
      "Price": "6.798",
      "Open": "6.817",
      "High": "6.823",
      "Low": "6.784",
      "Change %": "-0.18%"
  },
  {
      "Date": "8-Feb-22",
      "Price": "6.81",
      "Open": "6.861",
      "High": "6.861",
      "Low": "6.804",
      "Change %": "-1.00%"
  },
  {
      "Date": "4-Feb-22",
      "Price": "6.879",
      "Open": "6.955",
      "High": "6.955",
      "Low": "6.869",
      "Change %": "-0.15%"
  },
  {
      "Date": "3-Feb-22",
      "Price": "6.889",
      "Open": "6.891",
      "High": "6.891",
      "Low": "6.859",
      "Change %": "0.13%"
  },
  {
      "Date": "2-Feb-22",
      "Price": "6.88",
      "Open": "6.848",
      "High": "6.922",
      "Low": "6.848",
      "Change %": "0.76%"
  },
  {
      "Date": "1-Feb-22",
      "Price": "6.828",
      "Open": "6.728",
      "High": "6.883",
      "Low": "6.65",
      "Change %": "2.15%"
  },
  {
      "Date": "31-Jan-22",
      "Price": "6.684",
      "Open": "6.783",
      "High": "6.783",
      "Low": "6.684",
      "Change %": "-1.24%"
  },
  {
      "Date": "28-Jan-22",
      "Price": "6.768",
      "Open": "6.749",
      "High": "6.802",
      "Low": "6.738",
      "Change %": "0.33%"
  },
  {
      "Date": "27-Jan-22",
      "Price": "6.746",
      "Open": "6.701",
      "High": "6.749",
      "Low": "6.701",
      "Change %": "1.26%"
  },
  {
      "Date": "25-Jan-22",
      "Price": "6.662",
      "Open": "6.665",
      "High": "6.666",
      "Low": "6.642",
      "Change %": "0.15%"
  },
  {
      "Date": "24-Jan-22",
      "Price": "6.652",
      "Open": "6.63",
      "High": "6.655",
      "Low": "6.627",
      "Change %": "0.48%"
  },
  {
      "Date": "21-Jan-22",
      "Price": "6.62",
      "Open": "6.612",
      "High": "6.624",
      "Low": "6.595",
      "Change %": "0.18%"
  },
  {
      "Date": "20-Jan-22",
      "Price": "6.608",
      "Open": "6.599",
      "High": "6.609",
      "Low": "6.588",
      "Change %": "0.15%"
  },
  {
      "Date": "19-Jan-22",
      "Price": "6.598",
      "Open": "6.658",
      "High": "6.658",
      "Low": "6.598",
      "Change %": "-0.44%"
  },
  {
      "Date": "18-Jan-22",
      "Price": "6.627",
      "Open": "6.68",
      "High": "6.68",
      "Low": "6.617",
      "Change %": "-0.14%"
  },
  {
      "Date": "17-Jan-22",
      "Price": "6.636",
      "Open": "6.617",
      "High": "6.645",
      "Low": "6.616",
      "Change %": "0.82%"
  },
  {
      "Date": "14-Jan-22",
      "Price": "6.582",
      "Open": "6.576",
      "High": "6.597",
      "Low": "6.562",
      "Change %": "0.32%"
  },
  {
      "Date": "13-Jan-22",
      "Price": "6.561",
      "Open": "6.602",
      "High": "6.602",
      "Low": "6.561",
      "Change %": "-0.49%"
  },
  {
      "Date": "12-Jan-22",
      "Price": "6.593",
      "Open": "6.58",
      "High": "6.605",
      "Low": "6.562",
      "Change %": "0.35%"
  },
  {
      "Date": "11-Jan-22",
      "Price": "6.57",
      "Open": "6.602",
      "High": "6.602",
      "Low": "6.568",
      "Change %": "-0.30%"
  },
  {
      "Date": "10-Jan-22",
      "Price": "6.59",
      "Open": "6.558",
      "High": "6.593",
      "Low": "6.551",
      "Change %": "0.73%"
  },
  {
      "Date": "7-Jan-22",
      "Price": "6.542",
      "Open": "6.543",
      "High": "6.544",
      "Low": "6.514",
      "Change %": "0.26%"
  },
  {
      "Date": "6-Jan-22",
      "Price": "6.525",
      "Open": "6.54",
      "High": "6.547",
      "Low": "6.524",
      "Change %": "0.26%"
  },
  {
      "Date": "5-Jan-22",
      "Price": "6.508",
      "Open": "6.543",
      "High": "6.543",
      "Low": "6.508",
      "Change %": "-0.15%"
  },
  {
      "Date": "4-Jan-22",
      "Price": "6.518",
      "Open": "6.497",
      "High": "6.524",
      "Low": "6.484",
      "Change %": "0.90%"
  },
  {
      "Date": "3-Jan-22",
      "Price": "6.46",
      "Open": "6.463",
      "High": "6.469",
      "Low": "6.455",
      "Change %": "0.09%"
  },
  {
      "Date": "31-Dec-21",
      "Price": "6.454",
      "Open": "6.481",
      "High": "6.49",
      "Low": "6.451",
      "Change %": "-0.23%"
  },
  {
      "Date": "30-Dec-21",
      "Price": "6.469",
      "Open": "6.484",
      "High": "6.484",
      "Low": "6.469",
      "Change %": "0.12%"
  },
  {
      "Date": "29-Dec-21",
      "Price": "6.461",
      "Open": "6.484",
      "High": "6.484",
      "Low": "6.461",
      "Change %": "-0.26%"
  },
  {
      "Date": "28-Dec-21",
      "Price": "6.478",
      "Open": "6.491",
      "High": "6.497",
      "Low": "6.476",
      "Change %": "0.20%"
  },
  {
      "Date": "27-Dec-21",
      "Price": "6.465",
      "Open": "6.469",
      "High": "6.47",
      "Low": "6.447",
      "Change %": "0.05%"
  },
  {
      "Date": "24-Dec-21",
      "Price": "6.462",
      "Open": "6.479",
      "High": "6.479",
      "Low": "6.45",
      "Change %": "0.03%"
  },
  {
      "Date": "23-Dec-21",
      "Price": "6.46",
      "Open": "6.486",
      "High": "6.486",
      "Low": "6.457",
      "Change %": "-0.06%"
  },
  {
      "Date": "22-Dec-21",
      "Price": "6.464",
      "Open": "6.483",
      "High": "6.486",
      "Low": "6.462",
      "Change %": "-0.06%"
  },
  {
      "Date": "21-Dec-21",
      "Price": "6.468",
      "Open": "6.445",
      "High": "6.475",
      "Low": "6.445",
      "Change %": "0.51%"
  },
  {
      "Date": "20-Dec-21",
      "Price": "6.435",
      "Open": "6.401",
      "High": "6.468",
      "Low": "6.401",
      "Change %": "0.37%"
  },
  {
      "Date": "17-Dec-21",
      "Price": "6.411",
      "Open": "6.381",
      "High": "6.415",
      "Low": "6.381",
      "Change %": "0.58%"
  },
  {
      "Date": "16-Dec-21",
      "Price": "6.374",
      "Open": "6.374",
      "High": "6.377",
      "Low": "6.369",
      "Change %": "0.16%"
  },
  {
      "Date": "15-Dec-21",
      "Price": "6.364",
      "Open": "6.357",
      "High": "6.365",
      "Low": "6.353",
      "Change %": "0.13%"
  },
  {
      "Date": "14-Dec-21",
      "Price": "6.356",
      "Open": "6.366",
      "High": "6.369",
      "Low": "6.356",
      "Change %": "-0.22%"
  },
  {
      "Date": "13-Dec-21",
      "Price": "6.37",
      "Open": "6.37",
      "High": "6.379",
      "Low": "6.356",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Dec-21",
      "Price": "6.37",
      "Open": "6.356",
      "High": "6.371",
      "Low": "6.347",
      "Change %": "0.35%"
  },
  {
      "Date": "9-Dec-21",
      "Price": "6.348",
      "Open": "6.366",
      "High": "6.366",
      "Low": "6.347",
      "Change %": "0.02%"
  },
  {
      "Date": "8-Dec-21",
      "Price": "6.347",
      "Open": "6.402",
      "High": "6.402",
      "Low": "6.343",
      "Change %": "-0.69%"
  },
  {
      "Date": "7-Dec-21",
      "Price": "6.391",
      "Open": "6.375",
      "High": "6.393",
      "Low": "6.372",
      "Change %": "0.50%"
  },
  {
      "Date": "6-Dec-21",
      "Price": "6.359",
      "Open": "6.364",
      "High": "6.375",
      "Low": "6.356",
      "Change %": "-0.16%"
  },
  {
      "Date": "3-Dec-21",
      "Price": "6.369",
      "Open": "6.352",
      "High": "6.372",
      "Low": "6.352",
      "Change %": "0.27%"
  },
  {
      "Date": "2-Dec-21",
      "Price": "6.352",
      "Open": "6.348",
      "High": "6.355",
      "Low": "6.34",
      "Change %": "-0.02%"
  },
  {
      "Date": "1-Dec-21",
      "Price": "6.353",
      "Open": "6.36",
      "High": "6.36",
      "Low": "6.345",
      "Change %": "0.43%"
  },
  {
      "Date": "30-Nov-21",
      "Price": "6.326",
      "Open": "6.331",
      "High": "6.342",
      "Low": "6.307",
      "Change %": "-0.14%"
  },
  {
      "Date": "29-Nov-21",
      "Price": "6.335",
      "Open": "6.375",
      "High": "6.375",
      "Low": "6.329",
      "Change %": "0.08%"
  },
  {
      "Date": "26-Nov-21",
      "Price": "6.33",
      "Open": "6.338",
      "High": "6.338",
      "Low": "6.319",
      "Change %": "-0.58%"
  },
  {
      "Date": "25-Nov-21",
      "Price": "6.367",
      "Open": "6.376",
      "High": "6.376",
      "Low": "6.366",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Nov-21",
      "Price": "6.367",
      "Open": "6.39",
      "High": "6.39",
      "Low": "6.363",
      "Change %": "0.05%"
  },
  {
      "Date": "23-Nov-21",
      "Price": "6.364",
      "Open": "6.372",
      "High": "6.377",
      "Low": "6.361",
      "Change %": "0.20%"
  },
  {
      "Date": "22-Nov-21",
      "Price": "6.351",
      "Open": "6.339",
      "High": "6.353",
      "Low": "6.321",
      "Change %": "0.09%"
  },
  {
      "Date": "18-Nov-21",
      "Price": "6.345",
      "Open": "6.351",
      "High": "6.351",
      "Low": "6.335",
      "Change %": "-0.25%"
  },
  {
      "Date": "17-Nov-21",
      "Price": "6.361",
      "Open": "6.379",
      "High": "6.385",
      "Low": "6.358",
      "Change %": "-0.05%"
  },
  {
      "Date": "16-Nov-21",
      "Price": "6.364",
      "Open": "6.393",
      "High": "6.393",
      "Low": "6.355",
      "Change %": "0.35%"
  },
  {
      "Date": "15-Nov-21",
      "Price": "6.342",
      "Open": "6.361",
      "High": "6.361",
      "Low": "6.342",
      "Change %": "-0.41%"
  },
  {
      "Date": "12-Nov-21",
      "Price": "6.368",
      "Open": "6.386",
      "High": "6.386",
      "Low": "6.349",
      "Change %": "0.02%"
  },
  {
      "Date": "11-Nov-21",
      "Price": "6.367",
      "Open": "6.367",
      "High": "6.378",
      "Low": "6.356",
      "Change %": "0.49%"
  },
  {
      "Date": "10-Nov-21",
      "Price": "6.336",
      "Open": "6.3",
      "High": "6.338",
      "Low": "6.296",
      "Change %": "0.68%"
  },
  {
      "Date": "9-Nov-21",
      "Price": "6.293",
      "Open": "6.294",
      "High": "6.304",
      "Low": "6.287",
      "Change %": "-0.13%"
  },
  {
      "Date": "8-Nov-21",
      "Price": "6.301",
      "Open": "6.336",
      "High": "6.336",
      "Low": "6.301",
      "Change %": "-0.88%"
  },
  {
      "Date": "3-Nov-21",
      "Price": "6.357",
      "Open": "6.364",
      "High": "6.364",
      "Low": "6.354",
      "Change %": "-0.05%"
  },
  {
      "Date": "2-Nov-21",
      "Price": "6.36",
      "Open": "6.391",
      "High": "6.391",
      "Low": "6.353",
      "Change %": "-0.42%"
  },
  {
      "Date": "1-Nov-21",
      "Price": "6.387",
      "Open": "6.391",
      "High": "6.391",
      "Low": "6.369",
      "Change %": "-0.02%"
  },
  {
      "Date": "29-Oct-21",
      "Price": "6.388",
      "Open": "6.378",
      "High": "6.394",
      "Low": "6.365",
      "Change %": "0.33%"
  },
  {
      "Date": "28-Oct-21",
      "Price": "6.367",
      "Open": "6.327",
      "High": "6.368",
      "Low": "6.307",
      "Change %": "0.49%"
  },
  {
      "Date": "27-Oct-21",
      "Price": "6.336",
      "Open": "6.385",
      "High": "6.385",
      "Low": "6.333",
      "Change %": "-0.41%"
  },
  {
      "Date": "26-Oct-21",
      "Price": "6.362",
      "Open": "6.357",
      "High": "6.369",
      "Low": "6.343",
      "Change %": "0.19%"
  },
  {
      "Date": "25-Oct-21",
      "Price": "6.35",
      "Open": "6.378",
      "High": "6.378",
      "Low": "6.334",
      "Change %": "-0.22%"
  },
  {
      "Date": "22-Oct-21",
      "Price": "6.364",
      "Open": "6.356",
      "High": "6.366",
      "Low": "6.34",
      "Change %": "0.41%"
  },
  {
      "Date": "21-Oct-21",
      "Price": "6.338",
      "Open": "6.399",
      "High": "6.399",
      "Low": "6.337",
      "Change %": "-0.55%"
  },
  {
      "Date": "20-Oct-21",
      "Price": "6.373",
      "Open": "6.429",
      "High": "6.429",
      "Low": "6.366",
      "Change %": "-0.22%"
  },
  {
      "Date": "18-Oct-21",
      "Price": "6.387",
      "Open": "6.356",
      "High": "6.389",
      "Low": "6.356",
      "Change %": "0.93%"
  },
  {
      "Date": "14-Oct-21",
      "Price": "6.328",
      "Open": "6.32",
      "High": "6.332",
      "Low": "6.318",
      "Change %": "0.22%"
  },
  {
      "Date": "13-Oct-21",
      "Price": "6.314",
      "Open": "6.324",
      "High": "6.324",
      "Low": "6.312",
      "Change %": "-0.19%"
  },
  {
      "Date": "12-Oct-21",
      "Price": "6.326",
      "Open": "6.349",
      "High": "6.349",
      "Low": "6.324",
      "Change %": "-0.22%"
  },
  {
      "Date": "11-Oct-21",
      "Price": "6.34",
      "Open": "6.34",
      "High": "6.344",
      "Low": "6.326",
      "Change %": "0.35%"
  },
  {
      "Date": "8-Oct-21",
      "Price": "6.318",
      "Open": "6.301",
      "High": "6.322",
      "Low": "6.269",
      "Change %": "0.81%"
  },
  {
      "Date": "7-Oct-21",
      "Price": "6.267",
      "Open": "6.272",
      "High": "6.277",
      "Low": "6.259",
      "Change %": "-0.14%"
  },
  {
      "Date": "6-Oct-21",
      "Price": "6.276",
      "Open": "6.271",
      "High": "6.286",
      "Low": "6.269",
      "Change %": "0.26%"
  },
  {
      "Date": "5-Oct-21",
      "Price": "6.26",
      "Open": "6.27",
      "High": "6.283",
      "Low": "6.256",
      "Change %": "0.19%"
  },
  {
      "Date": "4-Oct-21",
      "Price": "6.248",
      "Open": "6.246",
      "High": "6.253",
      "Low": "6.235",
      "Change %": "0.06%"
  },
  {
      "Date": "1-Oct-21",
      "Price": "6.244",
      "Open": "6.23",
      "High": "6.253",
      "Low": "6.226",
      "Change %": "0.34%"
  },
  {
      "Date": "30-Sep-21",
      "Price": "6.223",
      "Open": "6.207",
      "High": "6.224",
      "Low": "6.198",
      "Change %": "0.27%"
  },
  {
      "Date": "29-Sep-21",
      "Price": "6.206",
      "Open": "6.223",
      "High": "6.223",
      "Low": "6.204",
      "Change %": "-0.35%"
  },
  {
      "Date": "28-Sep-21",
      "Price": "6.228",
      "Open": "6.209",
      "High": "6.229",
      "Low": "6.183",
      "Change %": "0.31%"
  },
  {
      "Date": "27-Sep-21",
      "Price": "6.209",
      "Open": "6.207",
      "High": "6.214",
      "Low": "6.191",
      "Change %": "0.45%"
  },
  {
      "Date": "24-Sep-21",
      "Price": "6.181",
      "Open": "6.16",
      "High": "6.188",
      "Low": "6.16",
      "Change %": "0.67%"
  },
  {
      "Date": "23-Sep-21",
      "Price": "6.14",
      "Open": "6.16",
      "High": "6.16",
      "Low": "6.123",
      "Change %": "0.03%"
  },
  {
      "Date": "22-Sep-21",
      "Price": "6.138",
      "Open": "6.126",
      "High": "6.14",
      "Low": "6.116",
      "Change %": "0.26%"
  },
  {
      "Date": "21-Sep-21",
      "Price": "6.122",
      "Open": "6.126",
      "High": "6.145",
      "Low": "6.116",
      "Change %": "-0.26%"
  },
  {
      "Date": "20-Sep-21",
      "Price": "6.138",
      "Open": "6.178",
      "High": "6.178",
      "Low": "6.138",
      "Change %": "-0.47%"
  },
  {
      "Date": "17-Sep-21",
      "Price": "6.167",
      "Open": "6.188",
      "High": "6.188",
      "Low": "6.167",
      "Change %": "-0.03%"
  },
  {
      "Date": "16-Sep-21",
      "Price": "6.169",
      "Open": "6.172",
      "High": "6.176",
      "Low": "6.155",
      "Change %": "0.10%"
  },
  {
      "Date": "15-Sep-21",
      "Price": "6.163",
      "Open": "6.201",
      "High": "6.201",
      "Low": "6.163",
      "Change %": "-0.56%"
  },
  {
      "Date": "14-Sep-21",
      "Price": "6.198",
      "Open": "6.181",
      "High": "6.2",
      "Low": "6.181",
      "Change %": "0.10%"
  },
  {
      "Date": "13-Sep-21",
      "Price": "6.192",
      "Open": "6.195",
      "High": "6.202",
      "Low": "6.18",
      "Change %": "0.23%"
  },
  {
      "Date": "9-Sep-21",
      "Price": "6.178",
      "Open": "6.181",
      "High": "6.183",
      "Low": "6.168",
      "Change %": "-0.16%"
  },
  {
      "Date": "8-Sep-21",
      "Price": "6.188",
      "Open": "6.193",
      "High": "6.2",
      "Low": "6.182",
      "Change %": "-0.11%"
  },
  {
      "Date": "7-Sep-21",
      "Price": "6.195",
      "Open": "6.192",
      "High": "6.199",
      "Low": "6.171",
      "Change %": "0.36%"
  },
  {
      "Date": "6-Sep-21",
      "Price": "6.173",
      "Open": "6.162",
      "High": "6.177",
      "Low": "6.137",
      "Change %": "0.28%"
  },
  {
      "Date": "3-Sep-21",
      "Price": "6.156",
      "Open": "6.193",
      "High": "6.193",
      "Low": "6.153",
      "Change %": "-0.24%"
  },
  {
      "Date": "2-Sep-21",
      "Price": "6.171",
      "Open": "6.215",
      "High": "6.215",
      "Low": "6.171",
      "Change %": "-0.45%"
  },
  {
      "Date": "1-Sep-21",
      "Price": "6.199",
      "Open": "6.235",
      "High": "6.235",
      "Low": "6.199",
      "Change %": "-0.26%"
  },
  {
      "Date": "31-Aug-21",
      "Price": "6.215",
      "Open": "6.224",
      "High": "6.233",
      "Low": "6.213",
      "Change %": "-0.16%"
  },
  {
      "Date": "30-Aug-21",
      "Price": "6.225",
      "Open": "6.255",
      "High": "6.255",
      "Low": "6.224",
      "Change %": "-0.46%"
  },
  {
      "Date": "27-Aug-21",
      "Price": "6.254",
      "Open": "6.284",
      "High": "6.284",
      "Low": "6.249",
      "Change %": "0.05%"
  },
  {
      "Date": "26-Aug-21",
      "Price": "6.251",
      "Open": "6.261",
      "High": "6.261",
      "Low": "6.243",
      "Change %": "0.16%"
  },
  {
      "Date": "25-Aug-21",
      "Price": "6.241",
      "Open": "6.264",
      "High": "6.266",
      "Low": "6.241",
      "Change %": "-0.14%"
  },
  {
      "Date": "24-Aug-21",
      "Price": "6.25",
      "Open": "6.253",
      "High": "6.259",
      "Low": "6.246",
      "Change %": "0.11%"
  },
  {
      "Date": "23-Aug-21",
      "Price": "6.243",
      "Open": "6.244",
      "High": "6.246",
      "Low": "6.234",
      "Change %": "0.14%"
  },
  {
      "Date": "20-Aug-21",
      "Price": "6.234",
      "Open": "6.236",
      "High": "6.24",
      "Low": "6.219",
      "Change %": "0.10%"
  },
  {
      "Date": "18-Aug-21",
      "Price": "6.228",
      "Open": "6.248",
      "High": "6.248",
      "Low": "6.226",
      "Change %": "-0.10%"
  },
  {
      "Date": "17-Aug-21",
      "Price": "6.234",
      "Open": "6.257",
      "High": "6.257",
      "Low": "6.229",
      "Change %": "-0.11%"
  },
  {
      "Date": "13-Aug-21",
      "Price": "6.241",
      "Open": "6.237",
      "High": "6.243",
      "Low": "6.228",
      "Change %": "0.24%"
  },
  {
      "Date": "12-Aug-21",
      "Price": "6.226",
      "Open": "6.243",
      "High": "6.243",
      "Low": "6.223",
      "Change %": "-0.16%"
  },
  {
      "Date": "11-Aug-21",
      "Price": "6.236",
      "Open": "6.248",
      "High": "6.256",
      "Low": "6.23",
      "Change %": "0.05%"
  },
  {
      "Date": "10-Aug-21",
      "Price": "6.233",
      "Open": "6.234",
      "High": "6.237",
      "Low": "6.229",
      "Change %": "0.19%"
  },
  {
      "Date": "9-Aug-21",
      "Price": "6.221",
      "Open": "6.261",
      "High": "6.261",
      "Low": "6.22",
      "Change %": "-0.21%"
  },
  {
      "Date": "6-Aug-21",
      "Price": "6.234",
      "Open": "6.252",
      "High": "6.262",
      "Low": "6.214",
      "Change %": "0.43%"
  },
  {
      "Date": "5-Aug-21",
      "Price": "6.207",
      "Open": "6.197",
      "High": "6.215",
      "Low": "6.195",
      "Change %": "0.13%"
  },
  {
      "Date": "4-Aug-21",
      "Price": "6.199",
      "Open": "6.207",
      "High": "6.207",
      "Low": "6.193",
      "Change %": "-0.02%"
  },
  {
      "Date": "3-Aug-21",
      "Price": "6.2",
      "Open": "6.201",
      "High": "6.201",
      "Low": "6.188",
      "Change %": "0.05%"
  },
  {
      "Date": "2-Aug-21",
      "Price": "6.197",
      "Open": "6.204",
      "High": "6.204",
      "Low": "6.192",
      "Change %": "-0.11%"
  },
  {
      "Date": "30-Jul-21",
      "Price": "6.204",
      "Open": "6.225",
      "High": "6.225",
      "Low": "6.199",
      "Change %": "0.10%"
  },
  {
      "Date": "29-Jul-21",
      "Price": "6.198",
      "Open": "6.2",
      "High": "6.203",
      "Low": "6.188",
      "Change %": "0.16%"
  },
  {
      "Date": "28-Jul-21",
      "Price": "6.188",
      "Open": "6.187",
      "High": "6.192",
      "Low": "6.174",
      "Change %": "0.16%"
  },
  {
      "Date": "27-Jul-21",
      "Price": "6.178",
      "Open": "6.189",
      "High": "6.189",
      "Low": "6.174",
      "Change %": "0.15%"
  },
  {
      "Date": "26-Jul-21",
      "Price": "6.169",
      "Open": "6.176",
      "High": "6.178",
      "Low": "6.161",
      "Change %": "0.06%"
  },
  {
      "Date": "23-Jul-21",
      "Price": "6.165",
      "Open": "6.147",
      "High": "6.165",
      "Low": "6.142",
      "Change %": "0.44%"
  },
  {
      "Date": "22-Jul-21",
      "Price": "6.138",
      "Open": "6.158",
      "High": "6.158",
      "Low": "6.132",
      "Change %": "0.31%"
  },
  {
      "Date": "20-Jul-21",
      "Price": "6.119",
      "Open": "6.129",
      "High": "6.129",
      "Low": "6.116",
      "Change %": "-0.13%"
  },
  {
      "Date": "19-Jul-21",
      "Price": "6.127",
      "Open": "6.139",
      "High": "6.139",
      "Low": "6.127",
      "Change %": "-0.05%"
  },
  {
      "Date": "16-Jul-21",
      "Price": "6.13",
      "Open": "6.146",
      "High": "6.146",
      "Low": "6.113",
      "Change %": "0.21%"
  },
  {
      "Date": "15-Jul-21",
      "Price": "6.117",
      "Open": "6.109",
      "High": "6.12",
      "Low": "6.108",
      "Change %": "0.13%"
  },
  {
      "Date": "14-Jul-21",
      "Price": "6.109",
      "Open": "6.122",
      "High": "6.122",
      "Low": "6.109",
      "Change %": "0.10%"
  },
  {
      "Date": "13-Jul-21",
      "Price": "6.103",
      "Open": "6.1",
      "High": "6.107",
      "Low": "6.1",
      "Change %": "-1.88%"
  },
  {
      "Date": "12-Jul-21",
      "Price": "6.22",
      "Open": "6.277",
      "High": "6.277",
      "Low": "6.204",
      "Change %": "0.55%"
  },
  {
      "Date": "9-Jul-21",
      "Price": "6.186",
      "Open": "6.153",
      "High": "6.197",
      "Low": "6.14",
      "Change %": "1.01%"
  },
  {
      "Date": "8-Jul-21",
      "Price": "6.124",
      "Open": "6.161",
      "High": "6.161",
      "Low": "6.124",
      "Change %": "-0.65%"
  },
  {
      "Date": "7-Jul-21",
      "Price": "6.164",
      "Open": "6.161",
      "High": "6.19",
      "Low": "6.161",
      "Change %": "-0.18%"
  },
  {
      "Date": "6-Jul-21",
      "Price": "6.175",
      "Open": "6.154",
      "High": "6.19",
      "Low": "6.138",
      "Change %": "1.43%"
  },
  {
      "Date": "5-Jul-21",
      "Price": "6.088",
      "Open": "6.061",
      "High": "6.093",
      "Low": "6.061",
      "Change %": "0.38%"
  },
  {
      "Date": "2-Jul-21",
      "Price": "6.065",
      "Open": "6.061",
      "High": "6.075",
      "Low": "6.055",
      "Change %": "0.43%"
  },
  {
      "Date": "1-Jul-21",
      "Price": "6.039",
      "Open": "6.082",
      "High": "6.082",
      "Low": "6.035",
      "Change %": "-0.20%"
  },
  {
      "Date": "30-Jun-21",
      "Price": "6.051",
      "Open": "6.089",
      "High": "6.089",
      "Low": "6.049",
      "Change %": "0.25%"
  },
  {
      "Date": "29-Jun-21",
      "Price": "6.036",
      "Open": "6.082",
      "High": "6.082",
      "Low": "6.036",
      "Change %": "-0.33%"
  },
  {
      "Date": "28-Jun-21",
      "Price": "6.056",
      "Open": "6.046",
      "High": "6.086",
      "Low": "6.039",
      "Change %": "0.46%"
  },
  {
      "Date": "25-Jun-21",
      "Price": "6.028",
      "Open": "6.063",
      "High": "6.063",
      "Low": "6.014",
      "Change %": "0.27%"
  },
  {
      "Date": "24-Jun-21",
      "Price": "6.012",
      "Open": "6.025",
      "High": "6.029",
      "Low": "6.008",
      "Change %": "-0.10%"
  },
  {
      "Date": "23-Jun-21",
      "Price": "6.018",
      "Open": "6.038",
      "High": "6.038",
      "Low": "6.015",
      "Change %": "-0.12%"
  },
  {
      "Date": "22-Jun-21",
      "Price": "6.025",
      "Open": "6.043",
      "High": "6.043",
      "Low": "6.021",
      "Change %": "-0.07%"
  },
  {
      "Date": "21-Jun-21",
      "Price": "6.029",
      "Open": "6.011",
      "High": "6.036",
      "Low": "6.006",
      "Change %": "0.38%"
  },
  {
      "Date": "18-Jun-21",
      "Price": "6.006",
      "Open": "6.057",
      "High": "6.057",
      "Low": "6.004",
      "Change %": "-0.22%"
  },
  {
      "Date": "17-Jun-21",
      "Price": "6.019",
      "Open": "6.074",
      "High": "6.074",
      "Low": "6.015",
      "Change %": "-0.43%"
  },
  {
      "Date": "16-Jun-21",
      "Price": "6.045",
      "Open": "6.052",
      "High": "6.067",
      "Low": "6.044",
      "Change %": "0.08%"
  },
  {
      "Date": "15-Jun-21",
      "Price": "6.04",
      "Open": "6.038",
      "High": "6.05",
      "Low": "6.028",
      "Change %": "0.60%"
  },
  {
      "Date": "14-Jun-21",
      "Price": "6.004",
      "Open": "6.018",
      "High": "6.018",
      "Low": "6.004",
      "Change %": "-0.03%"
  },
  {
      "Date": "11-Jun-21",
      "Price": "6.006",
      "Open": "6.025",
      "High": "6.025",
      "Low": "6.004",
      "Change %": "-0.22%"
  },
  {
      "Date": "10-Jun-21",
      "Price": "6.019",
      "Open": "6.029",
      "High": "6.029",
      "Low": "6.009",
      "Change %": "0.05%"
  },
  {
      "Date": "9-Jun-21",
      "Price": "6.016",
      "Open": "6.045",
      "High": "6.045",
      "Low": "6.011",
      "Change %": "0.17%"
  },
  {
      "Date": "8-Jun-21",
      "Price": "6.006",
      "Open": "6.037",
      "High": "6.037",
      "Low": "6.005",
      "Change %": "-0.27%"
  },
  {
      "Date": "7-Jun-21",
      "Price": "6.022",
      "Open": "6.04",
      "High": "6.04",
      "Low": "6.015",
      "Change %": "-0.10%"
  },
  {
      "Date": "4-Jun-21",
      "Price": "6.028",
      "Open": "5.99",
      "High": "6.032",
      "Low": "5.984",
      "Change %": "0.52%"
  },
  {
      "Date": "3-Jun-21",
      "Price": "5.997",
      "Open": "6.017",
      "High": "6.017",
      "Low": "5.997",
      "Change %": "-0.13%"
  },
  {
      "Date": "2-Jun-21",
      "Price": "6.005",
      "Open": "6.032",
      "High": "6.032",
      "Low": "6.005",
      "Change %": "-0.33%"
  },
  {
      "Date": "1-Jun-21",
      "Price": "6.025",
      "Open": "6.032",
      "High": "6.032",
      "Low": "6.019",
      "Change %": "0.05%"
  },
  {
      "Date": "31-May-21",
      "Price": "6.022",
      "Open": "6.039",
      "High": "6.039",
      "Low": "6.019",
      "Change %": "0.32%"
  },
  {
      "Date": "28-May-21",
      "Price": "6.003",
      "Open": "5.997",
      "High": "6.003",
      "Low": "5.979",
      "Change %": "0.27%"
  },
  {
      "Date": "27-May-21",
      "Price": "5.987",
      "Open": "6",
      "High": "6",
      "Low": "5.978",
      "Change %": "0.25%"
  },
  {
      "Date": "25-May-21",
      "Price": "5.972",
      "Open": "5.989",
      "High": "5.989",
      "Low": "5.972",
      "Change %": "0.00%"
  },
  {
      "Date": "24-May-21",
      "Price": "5.972",
      "Open": "5.985",
      "High": "5.985",
      "Low": "5.971",
      "Change %": "-0.08%"
  },
  {
      "Date": "21-May-21",
      "Price": "5.977",
      "Open": "5.967",
      "High": "5.978",
      "Low": "5.958",
      "Change %": "0.15%"
  },
  {
      "Date": "20-May-21",
      "Price": "5.968",
      "Open": "5.986",
      "High": "5.986",
      "Low": "5.966",
      "Change %": "-0.12%"
  },
  {
      "Date": "19-May-21",
      "Price": "5.975",
      "Open": "5.979",
      "High": "5.986",
      "Low": "5.968",
      "Change %": "-0.02%"
  },
  {
      "Date": "18-May-21",
      "Price": "5.976",
      "Open": "5.978",
      "High": "5.982",
      "Low": "5.974",
      "Change %": "0.07%"
  },
  {
      "Date": "17-May-21",
      "Price": "5.972",
      "Open": "5.989",
      "High": "5.989",
      "Low": "5.966",
      "Change %": "-0.23%"
  },
  {
      "Date": "14-May-21",
      "Price": "5.986",
      "Open": "6.045",
      "High": "6.045",
      "Low": "5.985",
      "Change %": "-0.40%"
  },
  {
      "Date": "12-May-21",
      "Price": "6.01",
      "Open": "6.029",
      "High": "6.029",
      "Low": "6.002",
      "Change %": "0.03%"
  },
  {
      "Date": "11-May-21",
      "Price": "6.008",
      "Open": "6.03",
      "High": "6.03",
      "Low": "6.008",
      "Change %": "-0.10%"
  },
  {
      "Date": "10-May-21",
      "Price": "6.014",
      "Open": "6.03",
      "High": "6.03",
      "Low": "6.006",
      "Change %": "-0.02%"
  },
  {
      "Date": "7-May-21",
      "Price": "6.015",
      "Open": "6.005",
      "High": "6.016",
      "Low": "5.979",
      "Change %": "0.69%"
  },
  {
      "Date": "6-May-21",
      "Price": "5.974",
      "Open": "5.988",
      "High": "5.995",
      "Low": "5.974",
      "Change %": "-0.07%"
  },
  {
      "Date": "5-May-21",
      "Price": "5.978",
      "Open": "6.009",
      "High": "6.009",
      "Low": "5.976",
      "Change %": "-0.62%"
  },
  {
      "Date": "4-May-21",
      "Price": "6.015",
      "Open": "6.048",
      "High": "6.048",
      "Low": "5.997",
      "Change %": "0.23%"
  },
  {
      "Date": "3-May-21",
      "Price": "6.001",
      "Open": "6.029",
      "High": "6.029",
      "Low": "6.001",
      "Change %": "-0.48%"
  },
  {
      "Date": "30-Apr-21",
      "Price": "6.03",
      "Open": "6.065",
      "High": "6.071",
      "Low": "6.028",
      "Change %": "-0.48%"
  },
  {
      "Date": "29-Apr-21",
      "Price": "6.059",
      "Open": "6.072",
      "High": "6.072",
      "Low": "6.051",
      "Change %": "0.10%"
  },
  {
      "Date": "28-Apr-21",
      "Price": "6.053",
      "Open": "6.077",
      "High": "6.077",
      "Low": "6.049",
      "Change %": "-0.03%"
  },
  {
      "Date": "27-Apr-21",
      "Price": "6.055",
      "Open": "6.051",
      "High": "6.067",
      "Low": "6.043",
      "Change %": "0.28%"
  },
  {
      "Date": "26-Apr-21",
      "Price": "6.038",
      "Open": "6.049",
      "High": "6.049",
      "Low": "6.026",
      "Change %": "0.05%"
  },
  {
      "Date": "23-Apr-21",
      "Price": "6.035",
      "Open": "6.1",
      "High": "6.1",
      "Low": "6.035",
      "Change %": "-0.25%"
  },
  {
      "Date": "22-Apr-21",
      "Price": "6.05",
      "Open": "6.069",
      "High": "6.069",
      "Low": "6.044",
      "Change %": "-0.40%"
  },
  {
      "Date": "20-Apr-21",
      "Price": "6.074",
      "Open": "6.105",
      "High": "6.105",
      "Low": "6.074",
      "Change %": "-0.12%"
  },
  {
      "Date": "19-Apr-21",
      "Price": "6.081",
      "Open": "6.109",
      "High": "6.109",
      "Low": "6.07",
      "Change %": "-0.11%"
  },
  {
      "Date": "16-Apr-21",
      "Price": "6.088",
      "Open": "6.168",
      "High": "6.176",
      "Low": "6.076",
      "Change %": "-0.62%"
  },
  {
      "Date": "15-Apr-21",
      "Price": "6.126",
      "Open": "6.033",
      "High": "6.128",
      "Low": "6.026",
      "Change %": "1.91%"
  },
  {
      "Date": "12-Apr-21",
      "Price": "6.011",
      "Open": "6.022",
      "High": "6.022",
      "Low": "6.005",
      "Change %": "-0.10%"
  },
  {
      "Date": "9-Apr-21",
      "Price": "6.017",
      "Open": "6.002",
      "High": "6.034",
      "Low": "5.99",
      "Change %": "-0.23%"
  },
  {
      "Date": "8-Apr-21",
      "Price": "6.031",
      "Open": "6.084",
      "High": "6.091",
      "Low": "6.031",
      "Change %": "-0.84%"
  },
  {
      "Date": "7-Apr-21",
      "Price": "6.082",
      "Open": "6.119",
      "High": "6.191",
      "Low": "6.059",
      "Change %": "-0.65%"
  },
  {
      "Date": "6-Apr-21",
      "Price": "6.122",
      "Open": "6.126",
      "High": "6.135",
      "Low": "6.108",
      "Change %": "-0.02%"
  },
  {
      "Date": "5-Apr-21",
      "Price": "6.123",
      "Open": "6.202",
      "High": "6.202",
      "Low": "6.123",
      "Change %": "-0.87%"
  },
  {
      "Date": "31-Mar-21",
      "Price": "6.177",
      "Open": "6.161",
      "High": "6.185",
      "Low": "6.14",
      "Change %": "0.55%"
  },
  {
      "Date": "30-Mar-21",
      "Price": "6.143",
      "Open": "6.147",
      "High": "6.152",
      "Low": "6.131",
      "Change %": "0.33%"
  },
  {
      "Date": "26-Mar-21",
      "Price": "6.123",
      "Open": "6.153",
      "High": "6.153",
      "Low": "6.119",
      "Change %": "-0.13%"
  },
  {
      "Date": "25-Mar-21",
      "Price": "6.131",
      "Open": "6.158",
      "High": "6.164",
      "Low": "6.131",
      "Change %": "-0.34%"
  },
  {
      "Date": "24-Mar-21",
      "Price": "6.152",
      "Open": "6.131",
      "High": "6.155",
      "Low": "6.114",
      "Change %": "0.13%"
  },
  {
      "Date": "23-Mar-21",
      "Price": "6.144",
      "Open": "6.153",
      "High": "6.155",
      "Low": "6.138",
      "Change %": "-0.58%"
  },
  {
      "Date": "22-Mar-21",
      "Price": "6.18",
      "Open": "6.208",
      "High": "6.208",
      "Low": "6.165",
      "Change %": "-0.21%"
  },
  {
      "Date": "19-Mar-21",
      "Price": "6.193",
      "Open": "6.198",
      "High": "6.21",
      "Low": "6.165",
      "Change %": "-0.15%"
  },
  {
      "Date": "18-Mar-21",
      "Price": "6.202",
      "Open": "6.221",
      "High": "6.24",
      "Low": "6.187",
      "Change %": "0.27%"
  },
  {
      "Date": "17-Mar-21",
      "Price": "6.185",
      "Open": "6.204",
      "High": "6.204",
      "Low": "6.167",
      "Change %": "0.08%"
  },
  {
      "Date": "16-Mar-21",
      "Price": "6.18",
      "Open": "6.213",
      "High": "6.213",
      "Low": "6.179",
      "Change %": "-0.34%"
  },
  {
      "Date": "15-Mar-21",
      "Price": "6.201",
      "Open": "6.246",
      "High": "6.247",
      "Low": "6.201",
      "Change %": "-0.50%"
  },
  {
      "Date": "12-Mar-21",
      "Price": "6.232",
      "Open": "6.233",
      "High": "6.258",
      "Low": "6.227",
      "Change %": "-0.24%"
  },
  {
      "Date": "10-Mar-21",
      "Price": "6.247",
      "Open": "6.209",
      "High": "6.274",
      "Low": "6.2",
      "Change %": "0.66%"
  },
  {
      "Date": "9-Mar-21",
      "Price": "6.206",
      "Open": "6.223",
      "High": "6.229",
      "Low": "6.206",
      "Change %": "-0.27%"
  },
  {
      "Date": "8-Mar-21",
      "Price": "6.223",
      "Open": "6.244",
      "High": "6.245",
      "Low": "6.208",
      "Change %": "-0.14%"
  },
  {
      "Date": "5-Mar-21",
      "Price": "6.232",
      "Open": "6.214",
      "High": "6.254",
      "Low": "6.204",
      "Change %": "0.32%"
  },
  {
      "Date": "4-Mar-21",
      "Price": "6.212",
      "Open": "6.258",
      "High": "6.258",
      "Low": "6.212",
      "Change %": "-0.42%"
  },
  {
      "Date": "3-Mar-21",
      "Price": "6.238",
      "Open": "6.242",
      "High": "6.246",
      "Low": "6.219",
      "Change %": "0.03%"
  },
  {
      "Date": "2-Mar-21",
      "Price": "6.236",
      "Open": "6.208",
      "High": "6.236",
      "Low": "6.188",
      "Change %": "0.43%"
  },
  {
      "Date": "1-Mar-21",
      "Price": "6.209",
      "Open": "6.236",
      "High": "6.236",
      "Low": "6.195",
      "Change %": "-0.32%"
  },
  {
      "Date": "26-Feb-21",
      "Price": "6.229",
      "Open": "6.215",
      "High": "6.242",
      "Low": "6.201",
      "Change %": "0.76%"
  },
  {
      "Date": "25-Feb-21",
      "Price": "6.182",
      "Open": "6.173",
      "High": "6.185",
      "Low": "6.149",
      "Change %": "0.57%"
  },
  {
      "Date": "24-Feb-21",
      "Price": "6.147",
      "Open": "6.172",
      "High": "6.172",
      "Low": "6.141",
      "Change %": "-0.41%"
  },
  {
      "Date": "23-Feb-21",
      "Price": "6.172",
      "Open": "6.207",
      "High": "6.207",
      "Low": "6.16",
      "Change %": "-0.48%"
  },
  {
      "Date": "22-Feb-21",
      "Price": "6.202",
      "Open": "6.192",
      "High": "6.212",
      "Low": "6.182",
      "Change %": "1.14%"
  },
  {
      "Date": "18-Feb-21",
      "Price": "6.132",
      "Open": "6.033",
      "High": "6.137",
      "Low": "6.028",
      "Change %": "1.69%"
  },
  {
      "Date": "17-Feb-21",
      "Price": "6.03",
      "Open": "6.047",
      "High": "6.047",
      "Low": "6.018",
      "Change %": "0.13%"
  },
  {
      "Date": "16-Feb-21",
      "Price": "6.022",
      "Open": "6.033",
      "High": "6.04",
      "Low": "6.018",
      "Change %": "0.00%"
  },
  {
      "Date": "15-Feb-21",
      "Price": "6.022",
      "Open": "6.019",
      "High": "6.026",
      "Low": "6.002",
      "Change %": "0.53%"
  },
  {
      "Date": "12-Feb-21",
      "Price": "5.99",
      "Open": "5.952",
      "High": "5.995",
      "Low": "5.951",
      "Change %": "0.47%"
  },
  {
      "Date": "11-Feb-21",
      "Price": "5.962",
      "Open": "6.015",
      "High": "6.023",
      "Low": "5.944",
      "Change %": "-0.80%"
  },
  {
      "Date": "10-Feb-21",
      "Price": "6.01",
      "Open": "6.073",
      "High": "6.073",
      "Low": "6.01",
      "Change %": "-1.05%"
  },
  {
      "Date": "9-Feb-21",
      "Price": "6.074",
      "Open": "6.095",
      "High": "6.099",
      "Low": "6.072",
      "Change %": "0.56%"
  },
  {
      "Date": "8-Feb-21",
      "Price": "6.04",
      "Open": "6.078",
      "High": "6.078",
      "Low": "6.015",
      "Change %": "-0.51%"
  },
  {
      "Date": "5-Feb-21",
      "Price": "6.071",
      "Open": "6.088",
      "High": "6.153",
      "Low": "6.054",
      "Change %": "-0.05%"
  },
  {
      "Date": "4-Feb-21",
      "Price": "6.074",
      "Open": "6.094",
      "High": "6.094",
      "Low": "6.058",
      "Change %": "-0.16%"
  },
  {
      "Date": "3-Feb-21",
      "Price": "6.084",
      "Open": "6.136",
      "High": "6.136",
      "Low": "6.082",
      "Change %": "-0.70%"
  },
  {
      "Date": "2-Feb-21",
      "Price": "6.127",
      "Open": "6.088",
      "High": "6.134",
      "Low": "6.08",
      "Change %": "1.11%"
  },
  {
      "Date": "1-Feb-21",
      "Price": "6.06",
      "Open": "5.917",
      "High": "6.071",
      "Low": "5.888",
      "Change %": "1.87%"
  },
  {
      "Date": "29-Jan-21",
      "Price": "5.949",
      "Open": "5.946",
      "High": "5.951",
      "Low": "5.936",
      "Change %": "0.27%"
  },
  {
      "Date": "28-Jan-21",
      "Price": "5.933",
      "Open": "5.955",
      "High": "5.955",
      "Low": "5.933",
      "Change %": "-0.35%"
  },
  {
      "Date": "27-Jan-21",
      "Price": "5.954",
      "Open": "5.961",
      "High": "5.961",
      "Low": "5.943",
      "Change %": "0.00%"
  },
  {
      "Date": "25-Jan-21",
      "Price": "5.954",
      "Open": "5.963",
      "High": "5.963",
      "Low": "5.934",
      "Change %": "0.25%"
  },
  {
      "Date": "22-Jan-21",
      "Price": "5.939",
      "Open": "5.97",
      "High": "5.973",
      "Low": "5.936",
      "Change %": "-0.29%"
  },
  {
      "Date": "21-Jan-21",
      "Price": "5.956",
      "Open": "5.942",
      "High": "5.958",
      "Low": "5.931",
      "Change %": "0.24%"
  },
  {
      "Date": "20-Jan-21",
      "Price": "5.942",
      "Open": "5.942",
      "High": "5.945",
      "Low": "5.931",
      "Change %": "0.10%"
  },
  {
      "Date": "19-Jan-21",
      "Price": "5.936",
      "Open": "5.963",
      "High": "5.963",
      "Low": "5.933",
      "Change %": "-0.35%"
  },
  {
      "Date": "18-Jan-21",
      "Price": "5.957",
      "Open": "5.965",
      "High": "5.986",
      "Low": "5.953",
      "Change %": "-0.52%"
  },
  {
      "Date": "15-Jan-21",
      "Price": "5.988",
      "Open": "5.959",
      "High": "5.988",
      "Low": "5.942",
      "Change %": "1.08%"
  },
  {
      "Date": "14-Jan-21",
      "Price": "5.924",
      "Open": "5.955",
      "High": "5.955",
      "Low": "5.924",
      "Change %": "-0.39%"
  },
  {
      "Date": "13-Jan-21",
      "Price": "5.947",
      "Open": "5.913",
      "High": "5.955",
      "Low": "5.908",
      "Change %": "0.27%"
  },
  {
      "Date": "12-Jan-21",
      "Price": "5.931",
      "Open": "5.937",
      "High": "5.937",
      "Low": "5.919",
      "Change %": "0.12%"
  },
  {
      "Date": "11-Jan-21",
      "Price": "5.924",
      "Open": "5.946",
      "High": "5.946",
      "Low": "5.917",
      "Change %": "0.54%"
  },
  {
      "Date": "8-Jan-21",
      "Price": "5.892",
      "Open": "5.878",
      "High": "5.901",
      "Low": "5.876",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Jan-21",
      "Price": "5.892",
      "Open": "5.906",
      "High": "5.912",
      "Low": "5.891",
      "Change %": "-0.03%"
  },
  {
      "Date": "6-Jan-21",
      "Price": "5.894",
      "Open": "5.877",
      "High": "5.912",
      "Low": "5.868",
      "Change %": "0.75%"
  },
  {
      "Date": "5-Jan-21",
      "Price": "5.85",
      "Open": "5.856",
      "High": "5.858",
      "Low": "5.839",
      "Change %": "-0.14%"
  },
  {
      "Date": "4-Jan-21",
      "Price": "5.858",
      "Open": "5.904",
      "High": "5.905",
      "Low": "5.858",
      "Change %": "-0.68%"
  },
  {
      "Date": "1-Jan-21",
      "Price": "5.898",
      "Open": "5.905",
      "High": "5.905",
      "Low": "5.888",
      "Change %": "0.07%"
  },
  {
      "Date": "31-Dec-20",
      "Price": "5.894",
      "Open": "5.905",
      "High": "5.905",
      "Low": "5.887",
      "Change %": "-0.08%"
  },
  {
      "Date": "30-Dec-20",
      "Price": "5.899",
      "Open": "5.933",
      "High": "5.933",
      "Low": "5.899",
      "Change %": "-0.47%"
  },
  {
      "Date": "29-Dec-20",
      "Price": "5.927",
      "Open": "5.935",
      "High": "5.935",
      "Low": "5.924",
      "Change %": "0.03%"
  },
  {
      "Date": "28-Dec-20",
      "Price": "5.925",
      "Open": "5.918",
      "High": "5.928",
      "Low": "5.918",
      "Change %": "-0.07%"
  },
  {
      "Date": "24-Dec-20",
      "Price": "5.929",
      "Open": "5.973",
      "High": "5.981",
      "Low": "5.929",
      "Change %": "-0.35%"
  },
  {
      "Date": "23-Dec-20",
      "Price": "5.95",
      "Open": "5.951",
      "High": "5.953",
      "Low": "5.944",
      "Change %": "-0.02%"
  },
  {
      "Date": "22-Dec-20",
      "Price": "5.951",
      "Open": "5.966",
      "High": "5.966",
      "Low": "5.939",
      "Change %": "-0.10%"
  },
  {
      "Date": "21-Dec-20",
      "Price": "5.957",
      "Open": "5.962",
      "High": "5.974",
      "Low": "5.952",
      "Change %": "-0.03%"
  },
  {
      "Date": "18-Dec-20",
      "Price": "5.959",
      "Open": "5.952",
      "High": "5.979",
      "Low": "5.95",
      "Change %": "0.12%"
  },
  {
      "Date": "17-Dec-20",
      "Price": "5.952",
      "Open": "5.955",
      "High": "5.955",
      "Low": "5.938",
      "Change %": "0.13%"
  },
  {
      "Date": "16-Dec-20",
      "Price": "5.944",
      "Open": "5.948",
      "High": "5.953",
      "Low": "5.94",
      "Change %": "0.05%"
  },
  {
      "Date": "15-Dec-20",
      "Price": "5.941",
      "Open": "5.926",
      "High": "5.949",
      "Low": "5.923",
      "Change %": "-0.08%"
  },
  {
      "Date": "14-Dec-20",
      "Price": "5.946",
      "Open": "5.97",
      "High": "5.97",
      "Low": "5.935",
      "Change %": "-0.17%"
  },
  {
      "Date": "11-Dec-20",
      "Price": "5.956",
      "Open": "5.943",
      "High": "5.96",
      "Low": "5.92",
      "Change %": "0.68%"
  },
  {
      "Date": "10-Dec-20",
      "Price": "5.916",
      "Open": "5.913",
      "High": "5.918",
      "Low": "5.898",
      "Change %": "-0.02%"
  },
  {
      "Date": "9-Dec-20",
      "Price": "5.917",
      "Open": "5.947",
      "High": "5.951",
      "Low": "5.907",
      "Change %": "-0.37%"
  },
  {
      "Date": "8-Dec-20",
      "Price": "5.939",
      "Open": "5.932",
      "High": "5.942",
      "Low": "5.918",
      "Change %": "0.25%"
  },
  {
      "Date": "7-Dec-20",
      "Price": "5.924",
      "Open": "5.908",
      "High": "5.929",
      "Low": "5.906",
      "Change %": "0.46%"
  },
  {
      "Date": "4-Dec-20",
      "Price": "5.897",
      "Open": "5.933",
      "High": "5.933",
      "Low": "5.888",
      "Change %": "-0.59%"
  },
  {
      "Date": "3-Dec-20",
      "Price": "5.932",
      "Open": "5.933",
      "High": "5.935",
      "Low": "5.909",
      "Change %": "0.20%"
  },
  {
      "Date": "2-Dec-20",
      "Price": "5.92",
      "Open": "5.951",
      "High": "5.951",
      "Low": "5.919",
      "Change %": "-0.17%"
  },
  {
      "Date": "1-Dec-20",
      "Price": "5.93",
      "Open": "5.919",
      "High": "5.93",
      "Low": "5.909",
      "Change %": "0.32%"
  },
  {
      "Date": "27-Nov-20",
      "Price": "5.911",
      "Open": "5.888",
      "High": "5.916",
      "Low": "5.884",
      "Change %": "0.66%"
  },
  {
      "Date": "26-Nov-20",
      "Price": "5.872",
      "Open": "5.895",
      "High": "5.895",
      "Low": "5.869",
      "Change %": "-0.22%"
  },
  {
      "Date": "25-Nov-20",
      "Price": "5.885",
      "Open": "5.902",
      "High": "5.902",
      "Low": "5.876",
      "Change %": "0.09%"
  },
  {
      "Date": "24-Nov-20",
      "Price": "5.88",
      "Open": "5.913",
      "High": "5.913",
      "Low": "5.879",
      "Change %": "-0.34%"
  },
  {
      "Date": "23-Nov-20",
      "Price": "5.9",
      "Open": "5.883",
      "High": "5.903",
      "Low": "5.874",
      "Change %": "0.39%"
  },
  {
      "Date": "20-Nov-20",
      "Price": "5.877",
      "Open": "5.877",
      "High": "5.887",
      "Low": "5.871",
      "Change %": "0.02%"
  },
  {
      "Date": "19-Nov-20",
      "Price": "5.876",
      "Open": "5.891",
      "High": "5.891",
      "Low": "5.873",
      "Change %": "-0.03%"
  },
  {
      "Date": "18-Nov-20",
      "Price": "5.878",
      "Open": "5.877",
      "High": "5.881",
      "Low": "5.86",
      "Change %": "0.02%"
  },
  {
      "Date": "17-Nov-20",
      "Price": "5.877",
      "Open": "5.888",
      "High": "5.888",
      "Low": "5.866",
      "Change %": "-0.02%"
  },
  {
      "Date": "13-Nov-20",
      "Price": "5.878",
      "Open": "5.915",
      "High": "5.915",
      "Low": "5.875",
      "Change %": "-0.42%"
  },
  {
      "Date": "12-Nov-20",
      "Price": "5.903",
      "Open": "5.923",
      "High": "5.923",
      "Low": "5.903",
      "Change %": "0.00%"
  },
  {
      "Date": "11-Nov-20",
      "Price": "5.903",
      "Open": "5.936",
      "High": "5.936",
      "Low": "5.901",
      "Change %": "-0.22%"
  },
  {
      "Date": "10-Nov-20",
      "Price": "5.916",
      "Open": "5.908",
      "High": "5.918",
      "Low": "5.899",
      "Change %": "0.68%"
  },
  {
      "Date": "9-Nov-20",
      "Price": "5.876",
      "Open": "5.883",
      "High": "5.886",
      "Low": "5.871",
      "Change %": "0.09%"
  },
  {
      "Date": "6-Nov-20",
      "Price": "5.871",
      "Open": "5.846",
      "High": "5.873",
      "Low": "5.837",
      "Change %": "0.19%"
  },
  {
      "Date": "5-Nov-20",
      "Price": "5.86",
      "Open": "5.883",
      "High": "5.883",
      "Low": "5.855",
      "Change %": "-0.44%"
  },
  {
      "Date": "4-Nov-20",
      "Price": "5.886",
      "Open": "5.896",
      "High": "5.896",
      "Low": "5.876",
      "Change %": "-0.20%"
  },
  {
      "Date": "3-Nov-20",
      "Price": "5.898",
      "Open": "5.888",
      "High": "5.914",
      "Low": "5.888",
      "Change %": "0.14%"
  },
  {
      "Date": "2-Nov-20",
      "Price": "5.89",
      "Open": "5.89",
      "High": "5.896",
      "Low": "5.877",
      "Change %": "0.15%"
  },
  {
      "Date": "29-Oct-20",
      "Price": "5.881",
      "Open": "5.874",
      "High": "5.886",
      "Low": "5.852",
      "Change %": "0.38%"
  },
  {
      "Date": "28-Oct-20",
      "Price": "5.859",
      "Open": "5.849",
      "High": "5.862",
      "Low": "5.83",
      "Change %": "0.22%"
  },
  {
      "Date": "27-Oct-20",
      "Price": "5.846",
      "Open": "5.849",
      "High": "5.86",
      "Low": "5.83",
      "Change %": "0.21%"
  },
  {
      "Date": "26-Oct-20",
      "Price": "5.834",
      "Open": "5.822",
      "High": "5.846",
      "Low": "5.791",
      "Change %": "-0.17%"
  },
  {
      "Date": "23-Oct-20",
      "Price": "5.844",
      "Open": "5.898",
      "High": "5.898",
      "Low": "5.844",
      "Change %": "-1.20%"
  },
  {
      "Date": "22-Oct-20",
      "Price": "5.915",
      "Open": "5.913",
      "High": "5.918",
      "Low": "5.903",
      "Change %": "0.05%"
  },
  {
      "Date": "21-Oct-20",
      "Price": "5.912",
      "Open": "5.947",
      "High": "5.947",
      "Low": "5.912",
      "Change %": "-0.32%"
  },
  {
      "Date": "20-Oct-20",
      "Price": "5.931",
      "Open": "5.95",
      "High": "5.95",
      "Low": "5.929",
      "Change %": "-0.15%"
  },
  {
      "Date": "19-Oct-20",
      "Price": "5.94",
      "Open": "5.932",
      "High": "5.944",
      "Low": "5.921",
      "Change %": "0.08%"
  },
  {
      "Date": "16-Oct-20",
      "Price": "5.935",
      "Open": "5.944",
      "High": "5.944",
      "Low": "5.91",
      "Change %": "0.63%"
  },
  {
      "Date": "15-Oct-20",
      "Price": "5.898",
      "Open": "5.911",
      "High": "5.911",
      "Low": "5.888",
      "Change %": "-0.03%"
  },
  {
      "Date": "14-Oct-20",
      "Price": "5.9",
      "Open": "5.918",
      "High": "5.918",
      "Low": "5.895",
      "Change %": "-0.07%"
  },
  {
      "Date": "13-Oct-20",
      "Price": "5.904",
      "Open": "5.925",
      "High": "5.925",
      "Low": "5.888",
      "Change %": "0.08%"
  },
  {
      "Date": "12-Oct-20",
      "Price": "5.899",
      "Open": "5.945",
      "High": "5.945",
      "Low": "5.889",
      "Change %": "-0.64%"
  },
  {
      "Date": "9-Oct-20",
      "Price": "5.937",
      "Open": "6.042",
      "High": "6.042",
      "Low": "5.92",
      "Change %": "-1.30%"
  },
  {
      "Date": "8-Oct-20",
      "Price": "6.015",
      "Open": "6.025",
      "High": "6.03",
      "Low": "6.012",
      "Change %": "0.02%"
  },
  {
      "Date": "7-Oct-20",
      "Price": "6.014",
      "Open": "6.032",
      "High": "6.032",
      "Low": "6.008",
      "Change %": "-0.20%"
  },
  {
      "Date": "6-Oct-20",
      "Price": "6.026",
      "Open": "6.026",
      "High": "6.036",
      "Low": "6.01",
      "Change %": "0.37%"
  },
  {
      "Date": "5-Oct-20",
      "Price": "6.004",
      "Open": "6.018",
      "High": "6.018",
      "Low": "5.989",
      "Change %": "0.13%"
  },
  {
      "Date": "1-Oct-20",
      "Price": "5.996",
      "Open": "5.965",
      "High": "6.006",
      "Low": "5.965",
      "Change %": "-0.32%"
  },
  {
      "Date": "30-Sep-20",
      "Price": "6.015",
      "Open": "6.057",
      "High": "6.057",
      "Low": "6.011",
      "Change %": "-0.36%"
  },
  {
      "Date": "29-Sep-20",
      "Price": "6.037",
      "Open": "6.069",
      "High": "6.073",
      "Low": "6.037",
      "Change %": "-0.31%"
  },
  {
      "Date": "28-Sep-20",
      "Price": "6.056",
      "Open": "6.068",
      "High": "6.079",
      "Low": "6.042",
      "Change %": "0.30%"
  },
  {
      "Date": "25-Sep-20",
      "Price": "6.038",
      "Open": "6.041",
      "High": "6.041",
      "Low": "6.003",
      "Change %": "0.77%"
  },
  {
      "Date": "24-Sep-20",
      "Price": "5.992",
      "Open": "6.027",
      "High": "6.027",
      "Low": "5.981",
      "Change %": "0.03%"
  },
  {
      "Date": "23-Sep-20",
      "Price": "5.99",
      "Open": "5.996",
      "High": "6.003",
      "Low": "5.989",
      "Change %": "-0.33%"
  },
  {
      "Date": "22-Sep-20",
      "Price": "6.01",
      "Open": "6.027",
      "High": "6.027",
      "Low": "5.991",
      "Change %": "-0.10%"
  },
  {
      "Date": "21-Sep-20",
      "Price": "6.016",
      "Open": "6.027",
      "High": "6.027",
      "Low": "6.005",
      "Change %": "0.03%"
  },
  {
      "Date": "18-Sep-20",
      "Price": "6.014",
      "Open": "6.028",
      "High": "6.028",
      "Low": "5.991",
      "Change %": "-0.31%"
  },
  {
      "Date": "17-Sep-20",
      "Price": "6.033",
      "Open": "5.999",
      "High": "6.037",
      "Low": "5.981",
      "Change %": "0.68%"
  },
  {
      "Date": "16-Sep-20",
      "Price": "5.992",
      "Open": "6.046",
      "High": "6.046",
      "Low": "5.992",
      "Change %": "-0.61%"
  },
  {
      "Date": "15-Sep-20",
      "Price": "6.029",
      "Open": "6.045",
      "High": "6.045",
      "Low": "6.014",
      "Change %": "-0.03%"
  },
  {
      "Date": "14-Sep-20",
      "Price": "6.031",
      "Open": "6.074",
      "High": "6.074",
      "Low": "6.026",
      "Change %": "-0.17%"
  },
  {
      "Date": "11-Sep-20",
      "Price": "6.041",
      "Open": "6.109",
      "High": "6.109",
      "Low": "6.017",
      "Change %": "-0.20%"
  },
  {
      "Date": "10-Sep-20",
      "Price": "6.053",
      "Open": "5.998",
      "High": "6.053",
      "Low": "5.985",
      "Change %": "1.07%"
  },
  {
      "Date": "9-Sep-20",
      "Price": "5.989",
      "Open": "6.081",
      "High": "6.081",
      "Low": "5.983",
      "Change %": "-1.11%"
  },
  {
      "Date": "8-Sep-20",
      "Price": "6.056",
      "Open": "6.039",
      "High": "6.06",
      "Low": "6.011",
      "Change %": "1.05%"
  },
  {
      "Date": "7-Sep-20",
      "Price": "5.993",
      "Open": "5.956",
      "High": "5.995",
      "Low": "5.938",
      "Change %": "1.06%"
  },
  {
      "Date": "4-Sep-20",
      "Price": "5.93",
      "Open": "5.91",
      "High": "5.944",
      "Low": "5.896",
      "Change %": "0.58%"
  },
  {
      "Date": "3-Sep-20",
      "Price": "5.896",
      "Open": "5.919",
      "High": "5.919",
      "Low": "5.878",
      "Change %": "-0.41%"
  },
  {
      "Date": "2-Sep-20",
      "Price": "5.92",
      "Open": "5.957",
      "High": "5.957",
      "Low": "5.918",
      "Change %": "-0.39%"
  },
  {
      "Date": "1-Sep-20",
      "Price": "5.943",
      "Open": "5.949",
      "High": "5.961",
      "Low": "5.934",
      "Change %": "-2.22%"
  },
  {
      "Date": "31-Aug-20",
      "Price": "6.078",
      "Open": "6.117",
      "High": "6.117",
      "Low": "6.071",
      "Change %": "-0.31%"
  },
  {
      "Date": "28-Aug-20",
      "Price": "6.097",
      "Open": "6.187",
      "High": "6.192",
      "Low": "6.096",
      "Change %": "-0.81%"
  },
  {
      "Date": "27-Aug-20",
      "Price": "6.147",
      "Open": "6.201",
      "High": "6.201",
      "Low": "6.143",
      "Change %": "-0.90%"
  },
  {
      "Date": "26-Aug-20",
      "Price": "6.203",
      "Open": "6.184",
      "High": "6.209",
      "Low": "6.136",
      "Change %": "0.75%"
  },
  {
      "Date": "25-Aug-20",
      "Price": "6.157",
      "Open": "6.14",
      "High": "6.172",
      "Low": "6.124",
      "Change %": "-1.03%"
  },
  {
      "Date": "24-Aug-20",
      "Price": "6.221",
      "Open": "6.182",
      "High": "6.231",
      "Low": "6.152",
      "Change %": "1.32%"
  },
  {
      "Date": "21-Aug-20",
      "Price": "6.14",
      "Open": "6.047",
      "High": "6.144",
      "Low": "6.034",
      "Change %": "2.33%"
  },
  {
      "Date": "20-Aug-20",
      "Price": "6",
      "Open": "6.013",
      "High": "6.013",
      "Low": "5.994",
      "Change %": "-0.13%"
  },
  {
      "Date": "19-Aug-20",
      "Price": "6.008",
      "Open": "5.993",
      "High": "6.01",
      "Low": "5.993",
      "Change %": "0.37%"
  },
  {
      "Date": "18-Aug-20",
      "Price": "5.986",
      "Open": "5.991",
      "High": "5.994",
      "Low": "5.975",
      "Change %": "0.18%"
  },
  {
      "Date": "17-Aug-20",
      "Price": "5.975",
      "Open": "6.004",
      "High": "6.004",
      "Low": "5.96",
      "Change %": "0.17%"
  },
  {
      "Date": "14-Aug-20",
      "Price": "5.965",
      "Open": "5.993",
      "High": "5.993",
      "Low": "5.934",
      "Change %": "1.19%"
  },
  {
      "Date": "13-Aug-20",
      "Price": "5.895",
      "Open": "5.885",
      "High": "5.898",
      "Low": "5.876",
      "Change %": "0.19%"
  },
  {
      "Date": "12-Aug-20",
      "Price": "5.884",
      "Open": "5.922",
      "High": "5.926",
      "Low": "5.88",
      "Change %": "-0.32%"
  },
  {
      "Date": "11-Aug-20",
      "Price": "5.903",
      "Open": "5.91",
      "High": "5.91",
      "Low": "5.885",
      "Change %": "0.17%"
  },
  {
      "Date": "10-Aug-20",
      "Price": "5.893",
      "Open": "5.91",
      "High": "5.916",
      "Low": "5.893",
      "Change %": "0.12%"
  },
  {
      "Date": "7-Aug-20",
      "Price": "5.886",
      "Open": "5.879",
      "High": "5.893",
      "Low": "5.868",
      "Change %": "0.41%"
  },
  {
      "Date": "6-Aug-20",
      "Price": "5.862",
      "Open": "5.834",
      "High": "5.887",
      "Low": "5.808",
      "Change %": "0.62%"
  },
  {
      "Date": "5-Aug-20",
      "Price": "5.826",
      "Open": "5.84",
      "High": "5.84",
      "Low": "5.824",
      "Change %": "-0.12%"
  },
  {
      "Date": "4-Aug-20",
      "Price": "5.833",
      "Open": "5.855",
      "High": "5.855",
      "Low": "5.823",
      "Change %": "-0.07%"
  },
  {
      "Date": "3-Aug-20",
      "Price": "5.837",
      "Open": "5.846",
      "High": "5.848",
      "Low": "5.836",
      "Change %": "0.00%"
  },
  {
      "Date": "31-Jul-20",
      "Price": "5.837",
      "Open": "5.833",
      "High": "5.845",
      "Low": "5.814",
      "Change %": "0.21%"
  },
  {
      "Date": "30-Jul-20",
      "Price": "5.825",
      "Open": "5.852",
      "High": "5.852",
      "Low": "5.819",
      "Change %": "-0.17%"
  },
  {
      "Date": "29-Jul-20",
      "Price": "5.835",
      "Open": "5.87",
      "High": "5.87",
      "Low": "5.832",
      "Change %": "-0.26%"
  },
  {
      "Date": "28-Jul-20",
      "Price": "5.85",
      "Open": "5.889",
      "High": "5.889",
      "Low": "5.85",
      "Change %": "-0.15%"
  },
  {
      "Date": "27-Jul-20",
      "Price": "5.859",
      "Open": "5.835",
      "High": "5.86",
      "Low": "5.813",
      "Change %": "0.72%"
  },
  {
      "Date": "24-Jul-20",
      "Price": "5.817",
      "Open": "5.815",
      "High": "5.825",
      "Low": "5.8",
      "Change %": "0.21%"
  },
  {
      "Date": "23-Jul-20",
      "Price": "5.805",
      "Open": "5.825",
      "High": "5.825",
      "Low": "5.805",
      "Change %": "-0.15%"
  },
  {
      "Date": "22-Jul-20",
      "Price": "5.814",
      "Open": "5.841",
      "High": "5.841",
      "Low": "5.814",
      "Change %": "-0.26%"
  },
  {
      "Date": "21-Jul-20",
      "Price": "5.829",
      "Open": "5.814",
      "High": "5.833",
      "Low": "5.795",
      "Change %": "0.52%"
  },
  {
      "Date": "20-Jul-20",
      "Price": "5.799",
      "Open": "5.812",
      "High": "5.818",
      "Low": "5.794",
      "Change %": "-0.10%"
  },
  {
      "Date": "17-Jul-20",
      "Price": "5.805",
      "Open": "5.822",
      "High": "5.829",
      "Low": "5.795",
      "Change %": "-0.14%"
  },
  {
      "Date": "16-Jul-20",
      "Price": "5.813",
      "Open": "5.828",
      "High": "5.828",
      "Low": "5.808",
      "Change %": "-0.03%"
  },
  {
      "Date": "15-Jul-20",
      "Price": "5.815",
      "Open": "5.836",
      "High": "5.836",
      "Low": "5.814",
      "Change %": "-0.17%"
  },
  {
      "Date": "14-Jul-20",
      "Price": "5.825",
      "Open": "5.822",
      "High": "5.847",
      "Low": "5.796",
      "Change %": "0.67%"
  },
  {
      "Date": "13-Jul-20",
      "Price": "5.786",
      "Open": "5.78",
      "High": "5.787",
      "Low": "5.77",
      "Change %": "0.45%"
  },
  {
      "Date": "10-Jul-20",
      "Price": "5.76",
      "Open": "5.775",
      "High": "5.775",
      "Low": "5.747",
      "Change %": "-0.21%"
  },
  {
      "Date": "9-Jul-20",
      "Price": "5.772",
      "Open": "5.764",
      "High": "5.794",
      "Low": "5.764",
      "Change %": "-0.10%"
  },
  {
      "Date": "8-Jul-20",
      "Price": "5.778",
      "Open": "5.802",
      "High": "5.802",
      "Low": "5.76",
      "Change %": "-0.24%"
  },
  {
      "Date": "7-Jul-20",
      "Price": "5.792",
      "Open": "5.836",
      "High": "5.846",
      "Low": "5.792",
      "Change %": "-0.75%"
  },
  {
      "Date": "6-Jul-20",
      "Price": "5.836",
      "Open": "5.851",
      "High": "5.851",
      "Low": "5.825",
      "Change %": "-0.15%"
  },
  {
      "Date": "3-Jul-20",
      "Price": "5.845",
      "Open": "5.853",
      "High": "5.873",
      "Low": "5.845",
      "Change %": "0.07%"
  },
  {
      "Date": "2-Jul-20",
      "Price": "5.841",
      "Open": "5.848",
      "High": "5.848",
      "Low": "5.821",
      "Change %": "0.05%"
  },
  {
      "Date": "1-Jul-20",
      "Price": "5.838",
      "Open": "5.907",
      "High": "5.907",
      "Low": "5.822",
      "Change %": "-0.85%"
  },
  {
      "Date": "30-Jun-20",
      "Price": "5.888",
      "Open": "5.924",
      "High": "5.924",
      "Low": "5.875",
      "Change %": "-0.25%"
  },
  {
      "Date": "29-Jun-20",
      "Price": "5.903",
      "Open": "5.938",
      "High": "5.938",
      "Low": "5.892",
      "Change %": "-0.17%"
  },
  {
      "Date": "26-Jun-20",
      "Price": "5.913",
      "Open": "5.917",
      "High": "5.921",
      "Low": "5.883",
      "Change %": "0.37%"
  },
  {
      "Date": "25-Jun-20",
      "Price": "5.891",
      "Open": "5.936",
      "High": "5.936",
      "Low": "5.891",
      "Change %": "-0.46%"
  },
  {
      "Date": "24-Jun-20",
      "Price": "5.918",
      "Open": "5.937",
      "High": "5.937",
      "Low": "5.885",
      "Change %": "0.25%"
  },
  {
      "Date": "23-Jun-20",
      "Price": "5.903",
      "Open": "5.915",
      "High": "5.915",
      "Low": "5.88",
      "Change %": "0.44%"
  },
  {
      "Date": "22-Jun-20",
      "Price": "5.877",
      "Open": "5.862",
      "High": "5.886",
      "Low": "5.835",
      "Change %": "0.48%"
  },
  {
      "Date": "19-Jun-20",
      "Price": "5.849",
      "Open": "5.842",
      "High": "5.855",
      "Low": "5.828",
      "Change %": "0.46%"
  },
  {
      "Date": "18-Jun-20",
      "Price": "5.822",
      "Open": "5.855",
      "High": "5.855",
      "Low": "5.818",
      "Change %": "-0.22%"
  },
  {
      "Date": "17-Jun-20",
      "Price": "5.835",
      "Open": "5.861",
      "High": "5.861",
      "Low": "5.834",
      "Change %": "-0.19%"
  },
  {
      "Date": "16-Jun-20",
      "Price": "5.846",
      "Open": "5.828",
      "High": "5.862",
      "Low": "5.799",
      "Change %": "0.93%"
  },
  {
      "Date": "15-Jun-20",
      "Price": "5.792",
      "Open": "5.835",
      "High": "5.835",
      "Low": "5.784",
      "Change %": "-0.07%"
  },
  {
      "Date": "12-Jun-20",
      "Price": "5.796",
      "Open": "5.784",
      "High": "5.801",
      "Low": "5.772",
      "Change %": "0.26%"
  },
  {
      "Date": "11-Jun-20",
      "Price": "5.781",
      "Open": "5.775",
      "High": "5.788",
      "Low": "5.755",
      "Change %": "0.16%"
  },
  {
      "Date": "10-Jun-20",
      "Price": "5.772",
      "Open": "5.779",
      "High": "5.779",
      "Low": "5.768",
      "Change %": "-0.05%"
  },
  {
      "Date": "9-Jun-20",
      "Price": "5.775",
      "Open": "5.787",
      "High": "5.791",
      "Low": "5.775",
      "Change %": "-0.55%"
  },
  {
      "Date": "8-Jun-20",
      "Price": "5.807",
      "Open": "5.855",
      "High": "5.855",
      "Low": "5.804",
      "Change %": "-0.24%"
  },
  {
      "Date": "5-Jun-20",
      "Price": "5.821",
      "Open": "5.827",
      "High": "5.846",
      "Low": "5.821",
      "Change %": "0.43%"
  },
  {
      "Date": "4-Jun-20",
      "Price": "5.796",
      "Open": "5.839",
      "High": "5.839",
      "Low": "5.796",
      "Change %": "-3.90%"
  },
  {
      "Date": "3-Jun-20",
      "Price": "6.031",
      "Open": "6.01",
      "High": "6.031",
      "Low": "5.998",
      "Change %": "0.55%"
  },
  {
      "Date": "2-Jun-20",
      "Price": "5.998",
      "Open": "6.089",
      "High": "6.089",
      "Low": "5.998",
      "Change %": "-0.73%"
  },
  {
      "Date": "1-Jun-20",
      "Price": "6.042",
      "Open": "6.055",
      "High": "6.055",
      "Low": "6.021",
      "Change %": "0.48%"
  },
  {
      "Date": "29-May-20",
      "Price": "6.013",
      "Open": "5.99",
      "High": "6.027",
      "Low": "5.978",
      "Change %": "0.38%"
  },
  {
      "Date": "28-May-20",
      "Price": "5.99",
      "Open": "5.995",
      "High": "5.995",
      "Low": "5.98",
      "Change %": "0.10%"
  },
  {
      "Date": "27-May-20",
      "Price": "5.984",
      "Open": "6.001",
      "High": "6.001",
      "Low": "5.966",
      "Change %": "0.15%"
  },
  {
      "Date": "26-May-20",
      "Price": "5.975",
      "Open": "5.99",
      "High": "5.994",
      "Low": "5.949",
      "Change %": "0.23%"
  },
  {
      "Date": "22-May-20",
      "Price": "5.961",
      "Open": "5.99",
      "High": "5.99",
      "Low": "5.879",
      "Change %": "-1.21%"
  },
  {
      "Date": "21-May-20",
      "Price": "6.034",
      "Open": "6.07",
      "High": "6.07",
      "Low": "6.029",
      "Change %": "-0.12%"
  },
  {
      "Date": "20-May-20",
      "Price": "6.041",
      "Open": "6.038",
      "High": "6.047",
      "Low": "6.024",
      "Change %": "0.10%"
  },
  {
      "Date": "19-May-20",
      "Price": "6.035",
      "Open": "6.067",
      "High": "6.067",
      "Low": "6.026",
      "Change %": "-0.25%"
  },
  {
      "Date": "18-May-20",
      "Price": "6.05",
      "Open": "6.102",
      "High": "6.102",
      "Low": "6.045",
      "Change %": "-0.51%"
  },
  {
      "Date": "15-May-20",
      "Price": "6.081",
      "Open": "6.123",
      "High": "6.123",
      "Low": "6.031",
      "Change %": "0.41%"
  },
  {
      "Date": "14-May-20",
      "Price": "6.056",
      "Open": "6.08",
      "High": "6.08",
      "Low": "5.993",
      "Change %": "-0.64%"
  },
  {
      "Date": "13-May-20",
      "Price": "6.095",
      "Open": "6.243",
      "High": "6.243",
      "Low": "6.089",
      "Change %": "-1.10%"
  },
  {
      "Date": "12-May-20",
      "Price": "6.163",
      "Open": "6.207",
      "High": "6.207",
      "Low": "6.144",
      "Change %": "-0.06%"
  },
  {
      "Date": "11-May-20",
      "Price": "6.167",
      "Open": "6.234",
      "High": "6.25",
      "Low": "6.156",
      "Change %": "3.35%"
  },
  {
      "Date": "8-May-20",
      "Price": "5.967",
      "Open": "6.013",
      "High": "6.047",
      "Low": "5.966",
      "Change %": "-1.01%"
  },
  {
      "Date": "6-May-20",
      "Price": "6.028",
      "Open": "6.061",
      "High": "6.061",
      "Low": "6.028",
      "Change %": "-0.74%"
  },
  {
      "Date": "5-May-20",
      "Price": "6.073",
      "Open": "6.11",
      "High": "6.11",
      "Low": "6.067",
      "Change %": "-0.18%"
  },
  {
      "Date": "4-May-20",
      "Price": "6.084",
      "Open": "6.138",
      "High": "6.14",
      "Low": "6.067",
      "Change %": "-0.43%"
  },
  {
      "Date": "30-Apr-20",
      "Price": "6.11",
      "Open": "6.141",
      "High": "6.141",
      "Low": "6.11",
      "Change %": "-0.24%"
  },
  {
      "Date": "29-Apr-20",
      "Price": "6.125",
      "Open": "6.125",
      "High": "6.147",
      "Low": "6.113",
      "Change %": "-0.20%"
  },
  {
      "Date": "28-Apr-20",
      "Price": "6.137",
      "Open": "6.165",
      "High": "6.165",
      "Low": "6.104",
      "Change %": "-0.26%"
  },
  {
      "Date": "27-Apr-20",
      "Price": "6.153",
      "Open": "6.202",
      "High": "6.202",
      "Low": "6.126",
      "Change %": "-0.23%"
  },
  {
      "Date": "24-Apr-20",
      "Price": "6.167",
      "Open": "6.096",
      "High": "6.172",
      "Low": "6.083",
      "Change %": "1.77%"
  },
  {
      "Date": "23-Apr-20",
      "Price": "6.06",
      "Open": "6.251",
      "High": "6.251",
      "Low": "6.015",
      "Change %": "-2.63%"
  },
  {
      "Date": "22-Apr-20",
      "Price": "6.224",
      "Open": "6.21",
      "High": "6.243",
      "Low": "6.201",
      "Change %": "0.19%"
  },
  {
      "Date": "21-Apr-20",
      "Price": "6.212",
      "Open": "6.237",
      "High": "6.237",
      "Low": "6.183",
      "Change %": "0.05%"
  },
  {
      "Date": "20-Apr-20",
      "Price": "6.209",
      "Open": "6.293",
      "High": "6.293",
      "Low": "6.181",
      "Change %": "-2.17%"
  },
  {
      "Date": "17-Apr-20",
      "Price": "6.347",
      "Open": "6.376",
      "High": "6.419",
      "Low": "6.298",
      "Change %": "-1.44%"
  },
  {
      "Date": "16-Apr-20",
      "Price": "6.44",
      "Open": "6.447",
      "High": "6.447",
      "Low": "6.427",
      "Change %": "0.23%"
  },
  {
      "Date": "15-Apr-20",
      "Price": "6.425",
      "Open": "6.49",
      "High": "6.49",
      "Low": "6.425",
      "Change %": "-1.08%"
  },
  {
      "Date": "13-Apr-20",
      "Price": "6.495",
      "Open": "6.471",
      "High": "6.5",
      "Low": "6.471",
      "Change %": "0.08%"
  },
  {
      "Date": "9-Apr-20",
      "Price": "6.49",
      "Open": "6.471",
      "High": "6.504",
      "Low": "6.466",
      "Change %": "0.76%"
  },
  {
      "Date": "8-Apr-20",
      "Price": "6.441",
      "Open": "6.45",
      "High": "6.456",
      "Low": "6.42",
      "Change %": "0.42%"
  },
  {
      "Date": "7-Apr-20",
      "Price": "6.414",
      "Open": "6.358",
      "High": "6.42",
      "Low": "6.356",
      "Change %": "1.71%"
  },
  {
      "Date": "3-Apr-20",
      "Price": "6.306",
      "Open": "6.287",
      "High": "6.315",
      "Low": "6.266",
      "Change %": "2.74%"
  },
  {
      "Date": "31-Mar-20",
      "Price": "6.138",
      "Open": "6.21",
      "High": "6.21",
      "Low": "6.079",
      "Change %": "-1.14%"
  },
  {
      "Date": "30-Mar-20",
      "Price": "6.209",
      "Open": "6.19",
      "High": "6.213",
      "Low": "6.139",
      "Change %": "1.06%"
  },
  {
      "Date": "27-Mar-20",
      "Price": "6.144",
      "Open": "6.183",
      "High": "6.183",
      "Low": "6.004",
      "Change %": "-1.30%"
  },
  {
      "Date": "26-Mar-20",
      "Price": "6.225",
      "Open": "6.379",
      "High": "6.379",
      "Low": "6.215",
      "Change %": "-1.25%"
  },
  {
      "Date": "24-Mar-20",
      "Price": "6.304",
      "Open": "6.377",
      "High": "6.377",
      "Low": "6.294",
      "Change %": "-1.18%"
  },
  {
      "Date": "23-Mar-20",
      "Price": "6.379",
      "Open": "6.301",
      "High": "6.391",
      "Low": "6.252",
      "Change %": "1.92%"
  },
  {
      "Date": "20-Mar-20",
      "Price": "6.259",
      "Open": "6.407",
      "High": "6.407",
      "Low": "6.216",
      "Change %": "-2.36%"
  },
  {
      "Date": "19-Mar-20",
      "Price": "6.41",
      "Open": "6.372",
      "High": "6.449",
      "Low": "6.336",
      "Change %": "1.81%"
  },
  {
      "Date": "18-Mar-20",
      "Price": "6.296",
      "Open": "6.271",
      "High": "6.343",
      "Low": "6.232",
      "Change %": "0.49%"
  },
  {
      "Date": "17-Mar-20",
      "Price": "6.265",
      "Open": "6.299",
      "High": "6.299",
      "Low": "6.209",
      "Change %": "0.92%"
  },
  {
      "Date": "16-Mar-20",
      "Price": "6.208",
      "Open": "6.239",
      "High": "6.252",
      "Low": "6.123",
      "Change %": "-1.80%"
  },
  {
      "Date": "13-Mar-20",
      "Price": "6.322",
      "Open": "6.273",
      "High": "6.355",
      "Low": "6.273",
      "Change %": "1.38%"
  },
  {
      "Date": "12-Mar-20",
      "Price": "6.236",
      "Open": "6.135",
      "High": "6.236",
      "Low": "6.135",
      "Change %": "1.81%"
  },
  {
      "Date": "11-Mar-20",
      "Price": "6.125",
      "Open": "6.1",
      "High": "6.159",
      "Low": "6.1",
      "Change %": "0.97%"
  },
  {
      "Date": "9-Mar-20",
      "Price": "6.066",
      "Open": "6.094",
      "High": "6.094",
      "Low": "5.995",
      "Change %": "-1.92%"
  },
  {
      "Date": "6-Mar-20",
      "Price": "6.185",
      "Open": "6.183",
      "High": "6.189",
      "Low": "6.148",
      "Change %": "-0.87%"
  },
  {
      "Date": "5-Mar-20",
      "Price": "6.239",
      "Open": "6.239",
      "High": "6.263",
      "Low": "6.227",
      "Change %": "0.21%"
  },
  {
      "Date": "4-Mar-20",
      "Price": "6.226",
      "Open": "6.267",
      "High": "6.294",
      "Low": "6.214",
      "Change %": "-1.83%"
  },
  {
      "Date": "3-Mar-20",
      "Price": "6.342",
      "Open": "6.369",
      "High": "6.38",
      "Low": "6.342",
      "Change %": "-0.06%"
  },
  {
      "Date": "2-Mar-20",
      "Price": "6.346",
      "Open": "6.343",
      "High": "6.362",
      "Low": "6.334",
      "Change %": "-0.39%"
  },
  {
      "Date": "28-Feb-20",
      "Price": "6.371",
      "Open": "6.356",
      "High": "6.404",
      "Low": "6.356",
      "Change %": "-0.11%"
  },
  {
      "Date": "27-Feb-20",
      "Price": "6.378",
      "Open": "6.331",
      "High": "6.389",
      "Low": "6.33",
      "Change %": "0.50%"
  },
  {
      "Date": "26-Feb-20",
      "Price": "6.346",
      "Open": "6.339",
      "High": "6.348",
      "Low": "6.326",
      "Change %": "-0.33%"
  },
  {
      "Date": "25-Feb-20",
      "Price": "6.367",
      "Open": "6.372",
      "High": "6.388",
      "Low": "6.35",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Feb-20",
      "Price": "6.367",
      "Open": "6.384",
      "High": "6.387",
      "Low": "6.339",
      "Change %": "-0.86%"
  },
  {
      "Date": "20-Feb-20",
      "Price": "6.422",
      "Open": "6.392",
      "High": "6.423",
      "Low": "6.38",
      "Change %": "0.60%"
  },
  {
      "Date": "18-Feb-20",
      "Price": "6.384",
      "Open": "6.385",
      "High": "6.408",
      "Low": "6.379",
      "Change %": "-0.05%"
  },
  {
      "Date": "17-Feb-20",
      "Price": "6.387",
      "Open": "6.346",
      "High": "6.391",
      "Low": "6.346",
      "Change %": "0.28%"
  },
  {
      "Date": "14-Feb-20",
      "Price": "6.369",
      "Open": "6.43",
      "High": "6.431",
      "Low": "6.367",
      "Change %": "-0.84%"
  },
  {
      "Date": "13-Feb-20",
      "Price": "6.423",
      "Open": "6.488",
      "High": "6.488",
      "Low": "6.423",
      "Change %": "-0.77%"
  },
  {
      "Date": "12-Feb-20",
      "Price": "6.473",
      "Open": "6.478",
      "High": "6.484",
      "Low": "6.471",
      "Change %": "0.14%"
  },
  {
      "Date": "11-Feb-20",
      "Price": "6.464",
      "Open": "6.447",
      "High": "6.469",
      "Low": "6.433",
      "Change %": "0.37%"
  },
  {
      "Date": "10-Feb-20",
      "Price": "6.44",
      "Open": "6.432",
      "High": "6.446",
      "Low": "6.421",
      "Change %": "0.02%"
  },
  {
      "Date": "7-Feb-20",
      "Price": "6.439",
      "Open": "6.433",
      "High": "6.443",
      "Low": "6.409",
      "Change %": "-0.11%"
  },
  {
      "Date": "6-Feb-20",
      "Price": "6.446",
      "Open": "6.513",
      "High": "6.513",
      "Low": "6.446",
      "Change %": "-0.91%"
  },
  {
      "Date": "5-Feb-20",
      "Price": "6.505",
      "Open": "6.512",
      "High": "6.519",
      "Low": "6.497",
      "Change %": "0.02%"
  },
  {
      "Date": "4-Feb-20",
      "Price": "6.504",
      "Open": "6.494",
      "High": "6.51",
      "Low": "6.488",
      "Change %": "0.02%"
  },
  {
      "Date": "3-Feb-20",
      "Price": "6.503",
      "Open": "6.509",
      "High": "6.52",
      "Low": "6.497",
      "Change %": "-1.45%"
  },
  {
      "Date": "31-Jan-20",
      "Price": "6.599",
      "Open": "6.577",
      "High": "6.6",
      "Low": "6.575",
      "Change %": "0.63%"
  },
  {
      "Date": "30-Jan-20",
      "Price": "6.558",
      "Open": "6.553",
      "High": "6.565",
      "Low": "6.553",
      "Change %": "-0.15%"
  },
  {
      "Date": "29-Jan-20",
      "Price": "6.568",
      "Open": "6.59",
      "High": "6.594",
      "Low": "6.563",
      "Change %": "-0.11%"
  },
  {
      "Date": "28-Jan-20",
      "Price": "6.575",
      "Open": "6.562",
      "High": "6.581",
      "Low": "6.549",
      "Change %": "0.37%"
  },
  {
      "Date": "27-Jan-20",
      "Price": "6.551",
      "Open": "6.549",
      "High": "6.572",
      "Low": "6.547",
      "Change %": "-0.41%"
  },
  {
      "Date": "24-Jan-20",
      "Price": "6.578",
      "Open": "6.599",
      "High": "6.602",
      "Low": "6.569",
      "Change %": "-0.27%"
  },
  {
      "Date": "23-Jan-20",
      "Price": "6.596",
      "Open": "6.623",
      "High": "6.632",
      "Low": "6.594",
      "Change %": "-0.59%"
  },
  {
      "Date": "22-Jan-20",
      "Price": "6.635",
      "Open": "6.629",
      "High": "6.643",
      "Low": "6.627",
      "Change %": "0.03%"
  },
  {
      "Date": "21-Jan-20",
      "Price": "6.633",
      "Open": "6.635",
      "High": "6.644",
      "Low": "6.618",
      "Change %": "-0.09%"
  },
  {
      "Date": "20-Jan-20",
      "Price": "6.639",
      "Open": "6.639",
      "High": "6.647",
      "Low": "6.625",
      "Change %": "0.26%"
  },
  {
      "Date": "17-Jan-20",
      "Price": "6.622",
      "Open": "6.601",
      "High": "6.626",
      "Low": "6.601",
      "Change %": "0.36%"
  },
  {
      "Date": "16-Jan-20",
      "Price": "6.598",
      "Open": "6.612",
      "High": "6.643",
      "Low": "6.598",
      "Change %": "-0.38%"
  },
  {
      "Date": "15-Jan-20",
      "Price": "6.623",
      "Open": "6.66",
      "High": "6.665",
      "Low": "6.623",
      "Change %": "-0.59%"
  },
  {
      "Date": "14-Jan-20",
      "Price": "6.662",
      "Open": "6.692",
      "High": "6.692",
      "Low": "6.648",
      "Change %": "1.06%"
  },
  {
      "Date": "13-Jan-20",
      "Price": "6.592",
      "Open": "6.588",
      "High": "6.621",
      "Low": "6.581",
      "Change %": "0.11%"
  },
  {
      "Date": "10-Jan-20",
      "Price": "6.585",
      "Open": "6.583",
      "High": "6.595",
      "Low": "6.576",
      "Change %": "0.94%"
  },
  {
      "Date": "9-Jan-20",
      "Price": "6.524",
      "Open": "6.526",
      "High": "6.554",
      "Low": "6.52",
      "Change %": "-0.50%"
  },
  {
      "Date": "8-Jan-20",
      "Price": "6.557",
      "Open": "6.578",
      "High": "6.578",
      "Low": "6.544",
      "Change %": "0.11%"
  },
  {
      "Date": "7-Jan-20",
      "Price": "6.55",
      "Open": "6.547",
      "High": "6.563",
      "Low": "6.528",
      "Change %": "-0.23%"
  },
  {
      "Date": "6-Jan-20",
      "Price": "6.565",
      "Open": "6.534",
      "High": "6.567",
      "Low": "6.531",
      "Change %": "0.84%"
  },
  {
      "Date": "3-Jan-20",
      "Price": "6.51",
      "Open": "6.494",
      "High": "6.52",
      "Low": "6.485",
      "Change %": "0.12%"
  },
  {
      "Date": "2-Jan-20",
      "Price": "6.502",
      "Open": "6.498",
      "High": "6.512",
      "Low": "6.489",
      "Change %": "0.02%"
  },
  {
      "Date": "1-Jan-20",
      "Price": "6.501",
      "Open": "6.548",
      "High": "6.548",
      "Low": "6.501",
      "Change %": "-0.81%"
  },
  {
      "Date": "31-Dec-19",
      "Price": "6.554",
      "Open": "6.543",
      "High": "6.559",
      "Low": "6.532",
      "Change %": "0.15%"
  },
  {
      "Date": "30-Dec-19",
      "Price": "6.544",
      "Open": "6.501",
      "High": "6.547",
      "Low": "6.501",
      "Change %": "0.68%"
  },
  {
      "Date": "27-Dec-19",
      "Price": "6.5",
      "Open": "6.509",
      "High": "6.516",
      "Low": "6.5",
      "Change %": "-1.16%"
  },
  {
      "Date": "26-Dec-19",
      "Price": "6.576",
      "Open": "6.593",
      "High": "6.595",
      "Low": "6.572",
      "Change %": "0.08%"
  },
  {
      "Date": "24-Dec-19",
      "Price": "6.571",
      "Open": "6.557",
      "High": "6.593",
      "Low": "6.554",
      "Change %": "0.14%"
  },
  {
      "Date": "23-Dec-19",
      "Price": "6.562",
      "Open": "6.609",
      "High": "6.609",
      "Low": "6.552",
      "Change %": "-0.55%"
  },
  {
      "Date": "20-Dec-19",
      "Price": "6.598",
      "Open": "6.621",
      "High": "6.652",
      "Low": "6.555",
      "Change %": "-2.19%"
  },
  {
      "Date": "19-Dec-19",
      "Price": "6.746",
      "Open": "6.731",
      "High": "6.748",
      "Low": "6.704",
      "Change %": "0.61%"
  },
  {
      "Date": "18-Dec-19",
      "Price": "6.705",
      "Open": "6.742",
      "High": "6.759",
      "Low": "6.693",
      "Change %": "-0.58%"
  },
  {
      "Date": "17-Dec-19",
      "Price": "6.744",
      "Open": "6.801",
      "High": "6.801",
      "Low": "6.744",
      "Change %": "-0.74%"
  },
  {
      "Date": "16-Dec-19",
      "Price": "6.794",
      "Open": "6.767",
      "High": "6.798",
      "Low": "6.759",
      "Change %": "0.18%"
  },
  {
      "Date": "13-Dec-19",
      "Price": "6.782",
      "Open": "6.822",
      "High": "6.837",
      "Low": "6.764",
      "Change %": "0.18%"
  },
  {
      "Date": "12-Dec-19",
      "Price": "6.77",
      "Open": "6.753",
      "High": "6.791",
      "Low": "6.741",
      "Change %": "0.15%"
  },
  {
      "Date": "11-Dec-19",
      "Price": "6.76",
      "Open": "6.71",
      "High": "6.76",
      "Low": "6.687",
      "Change %": "0.85%"
  },
  {
      "Date": "10-Dec-19",
      "Price": "6.703",
      "Open": "6.665",
      "High": "6.723",
      "Low": "6.643",
      "Change %": "0.66%"
  },
  {
      "Date": "9-Dec-19",
      "Price": "6.659",
      "Open": "6.689",
      "High": "6.69",
      "Low": "6.658",
      "Change %": "-0.03%"
  },
  {
      "Date": "6-Dec-19",
      "Price": "6.661",
      "Open": "6.611",
      "High": "6.668",
      "Low": "6.602",
      "Change %": "0.80%"
  },
  {
      "Date": "5-Dec-19",
      "Price": "6.608",
      "Open": "6.464",
      "High": "6.614",
      "Low": "6.454",
      "Change %": "2.26%"
  },
  {
      "Date": "4-Dec-19",
      "Price": "6.462",
      "Open": "6.45",
      "High": "6.468",
      "Low": "6.448",
      "Change %": "0.00%"
  },
  {
      "Date": "3-Dec-19",
      "Price": "6.462",
      "Open": "6.485",
      "High": "6.495",
      "Low": "6.462",
      "Change %": "-0.34%"
  },
  {
      "Date": "2-Dec-19",
      "Price": "6.484",
      "Open": "6.463",
      "High": "6.494",
      "Low": "6.452",
      "Change %": "0.37%"
  },
  {
      "Date": "29-Nov-19",
      "Price": "6.46",
      "Open": "6.455",
      "High": "6.474",
      "Low": "6.449",
      "Change %": "0.11%"
  },
  {
      "Date": "28-Nov-19",
      "Price": "6.453",
      "Open": "6.464",
      "High": "6.464",
      "Low": "6.442",
      "Change %": "-0.22%"
  },
  {
      "Date": "27-Nov-19",
      "Price": "6.467",
      "Open": "6.482",
      "High": "6.494",
      "Low": "6.467",
      "Change %": "-0.17%"
  },
  {
      "Date": "26-Nov-19",
      "Price": "6.478",
      "Open": "6.47",
      "High": "6.489",
      "Low": "6.463",
      "Change %": "0.15%"
  },
  {
      "Date": "25-Nov-19",
      "Price": "6.468",
      "Open": "6.506",
      "High": "6.506",
      "Low": "6.461",
      "Change %": "-0.42%"
  },
  {
      "Date": "22-Nov-19",
      "Price": "6.495",
      "Open": "6.513",
      "High": "6.524",
      "Low": "6.489",
      "Change %": "-0.15%"
  },
  {
      "Date": "21-Nov-19",
      "Price": "6.505",
      "Open": "6.463",
      "High": "6.506",
      "Low": "6.449",
      "Change %": "0.73%"
  },
  {
      "Date": "20-Nov-19",
      "Price": "6.458",
      "Open": "6.464",
      "High": "6.473",
      "Low": "6.454",
      "Change %": "-0.23%"
  },
  {
      "Date": "19-Nov-19",
      "Price": "6.473",
      "Open": "6.476",
      "High": "6.481",
      "Low": "6.466",
      "Change %": "-0.06%"
  },
  {
      "Date": "18-Nov-19",
      "Price": "6.477",
      "Open": "6.526",
      "High": "6.53",
      "Low": "6.476",
      "Change %": "-0.64%"
  },
  {
      "Date": "15-Nov-19",
      "Price": "6.519",
      "Open": "6.526",
      "High": "6.526",
      "Low": "6.507",
      "Change %": "0.06%"
  },
  {
      "Date": "14-Nov-19",
      "Price": "6.515",
      "Open": "6.528",
      "High": "6.528",
      "Low": "6.505",
      "Change %": "-0.20%"
  },
  {
      "Date": "13-Nov-19",
      "Price": "6.528",
      "Open": "6.554",
      "High": "6.554",
      "Low": "6.526",
      "Change %": "-0.50%"
  },
  {
      "Date": "11-Nov-19",
      "Price": "6.561",
      "Open": "6.544",
      "High": "6.574",
      "Low": "6.544",
      "Change %": "0.11%"
  },
  {
      "Date": "8-Nov-19",
      "Price": "6.554",
      "Open": "6.539",
      "High": "6.554",
      "Low": "6.524",
      "Change %": "0.80%"
  },
  {
      "Date": "7-Nov-19",
      "Price": "6.502",
      "Open": "6.473",
      "High": "6.506",
      "Low": "6.473",
      "Change %": "0.25%"
  },
  {
      "Date": "6-Nov-19",
      "Price": "6.486",
      "Open": "6.521",
      "High": "6.521",
      "Low": "6.484",
      "Change %": "-0.43%"
  },
  {
      "Date": "5-Nov-19",
      "Price": "6.514",
      "Open": "6.482",
      "High": "6.528",
      "Low": "6.482",
      "Change %": "0.66%"
  },
  {
      "Date": "4-Nov-19",
      "Price": "6.471",
      "Open": "6.457",
      "High": "6.473",
      "Low": "6.449",
      "Change %": "0.40%"
  },
  {
      "Date": "1-Nov-19",
      "Price": "6.445",
      "Open": "6.431",
      "High": "6.446",
      "Low": "6.415",
      "Change %": "-2.98%"
  },
  {
      "Date": "31-Oct-19",
      "Price": "6.643",
      "Open": "6.651",
      "High": "6.667",
      "Low": "6.641",
      "Change %": "-0.32%"
  },
  {
      "Date": "30-Oct-19",
      "Price": "6.664",
      "Open": "6.67",
      "High": "6.673",
      "Low": "6.657",
      "Change %": "-0.22%"
  },
  {
      "Date": "29-Oct-19",
      "Price": "6.679",
      "Open": "6.697",
      "High": "6.7",
      "Low": "6.674",
      "Change %": "0.13%"
  },
  {
      "Date": "25-Oct-19",
      "Price": "6.67",
      "Open": "6.68",
      "High": "6.686",
      "Low": "6.66",
      "Change %": "-0.12%"
  },
  {
      "Date": "24-Oct-19",
      "Price": "6.678",
      "Open": "6.698",
      "High": "6.708",
      "Low": "6.674",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Oct-19",
      "Price": "6.678",
      "Open": "6.695",
      "High": "6.695",
      "Low": "6.671",
      "Change %": "-0.33%"
  },
  {
      "Date": "22-Oct-19",
      "Price": "6.7",
      "Open": "6.711",
      "High": "6.711",
      "Low": "6.689",
      "Change %": "0.12%"
  },
  {
      "Date": "18-Oct-19",
      "Price": "6.692",
      "Open": "6.699",
      "High": "6.704",
      "Low": "6.688",
      "Change %": "-0.10%"
  },
  {
      "Date": "17-Oct-19",
      "Price": "6.699",
      "Open": "6.656",
      "High": "6.718",
      "Low": "6.636",
      "Change %": "0.65%"
  },
  {
      "Date": "16-Oct-19",
      "Price": "6.656",
      "Open": "6.669",
      "High": "6.673",
      "Low": "6.642",
      "Change %": "-0.06%"
  },
  {
      "Date": "15-Oct-19",
      "Price": "6.66",
      "Open": "6.697",
      "High": "6.697",
      "Low": "6.651",
      "Change %": "-0.15%"
  },
  {
      "Date": "14-Oct-19",
      "Price": "6.67",
      "Open": "6.737",
      "High": "6.739",
      "Low": "6.67",
      "Change %": "-0.85%"
  },
  {
      "Date": "11-Oct-19",
      "Price": "6.727",
      "Open": "6.703",
      "High": "6.731",
      "Low": "6.698",
      "Change %": "0.72%"
  },
  {
      "Date": "10-Oct-19",
      "Price": "6.679",
      "Open": "6.662",
      "High": "6.684",
      "Low": "6.66",
      "Change %": "0.36%"
  },
  {
      "Date": "9-Oct-19",
      "Price": "6.655",
      "Open": "6.659",
      "High": "6.666",
      "Low": "6.636",
      "Change %": "-0.21%"
  },
  {
      "Date": "7-Oct-19",
      "Price": "6.669",
      "Open": "6.693",
      "High": "6.693",
      "Low": "6.66",
      "Change %": "-0.16%"
  },
  {
      "Date": "4-Oct-19",
      "Price": "6.68",
      "Open": "6.6",
      "High": "6.684",
      "Low": "6.582",
      "Change %": "1.12%"
  },
  {
      "Date": "3-Oct-19",
      "Price": "6.606",
      "Open": "6.609",
      "High": "6.62",
      "Low": "6.599",
      "Change %": "-0.77%"
  },
  {
      "Date": "1-Oct-19",
      "Price": "6.657",
      "Open": "6.68",
      "High": "6.702",
      "Low": "6.652",
      "Change %": "-0.57%"
  },
  {
      "Date": "30-Sep-19",
      "Price": "6.695",
      "Open": "6.706",
      "High": "6.71",
      "Low": "6.681",
      "Change %": "-0.51%"
  },
  {
      "Date": "27-Sep-19",
      "Price": "6.729",
      "Open": "6.774",
      "High": "6.774",
      "Low": "6.708",
      "Change %": "0.28%"
  },
  {
      "Date": "26-Sep-19",
      "Price": "6.71",
      "Open": "6.762",
      "High": "6.771",
      "Low": "6.697",
      "Change %": "-0.65%"
  },
  {
      "Date": "25-Sep-19",
      "Price": "6.754",
      "Open": "6.774",
      "High": "6.777",
      "Low": "6.744",
      "Change %": "-0.31%"
  },
  {
      "Date": "24-Sep-19",
      "Price": "6.775",
      "Open": "6.741",
      "High": "6.782",
      "Low": "6.733",
      "Change %": "0.53%"
  },
  {
      "Date": "23-Sep-19",
      "Price": "6.739",
      "Open": "6.767",
      "High": "6.801",
      "Low": "6.73",
      "Change %": "-0.63%"
  },
  {
      "Date": "20-Sep-19",
      "Price": "6.782",
      "Open": "6.587",
      "High": "6.874",
      "Low": "6.552",
      "Change %": "2.23%"
  },
  {
      "Date": "19-Sep-19",
      "Price": "6.634",
      "Open": "6.611",
      "High": "6.642",
      "Low": "6.573",
      "Change %": "0.26%"
  },
  {
      "Date": "18-Sep-19",
      "Price": "6.617",
      "Open": "6.66",
      "High": "6.68",
      "Low": "6.602",
      "Change %": "-1.65%"
  },
  {
      "Date": "17-Sep-19",
      "Price": "6.728",
      "Open": "6.728",
      "High": "6.737",
      "Low": "6.7",
      "Change %": "0.27%"
  },
  {
      "Date": "16-Sep-19",
      "Price": "6.71",
      "Open": "6.723",
      "High": "6.746",
      "Low": "6.683",
      "Change %": "1.19%"
  },
  {
      "Date": "13-Sep-19",
      "Price": "6.631",
      "Open": "6.666",
      "High": "6.674",
      "Low": "6.607",
      "Change %": "-0.41%"
  },
  {
      "Date": "12-Sep-19",
      "Price": "6.658",
      "Open": "6.669",
      "High": "6.705",
      "Low": "6.631",
      "Change %": "-0.24%"
  },
  {
      "Date": "11-Sep-19",
      "Price": "6.674",
      "Open": "6.606",
      "High": "6.675",
      "Low": "6.595",
      "Change %": "1.58%"
  },
  {
      "Date": "9-Sep-19",
      "Price": "6.57",
      "Open": "6.606",
      "High": "6.606",
      "Low": "6.563",
      "Change %": "-0.41%"
  },
  {
      "Date": "6-Sep-19",
      "Price": "6.597",
      "Open": "6.595",
      "High": "6.603",
      "Low": "6.574",
      "Change %": "0.40%"
  },
  {
      "Date": "5-Sep-19",
      "Price": "6.571",
      "Open": "6.597",
      "High": "6.597",
      "Low": "6.555",
      "Change %": "0.40%"
  },
  {
      "Date": "4-Sep-19",
      "Price": "6.545",
      "Open": "6.502",
      "High": "6.547",
      "Low": "6.499",
      "Change %": "0.45%"
  },
  {
      "Date": "3-Sep-19",
      "Price": "6.516",
      "Open": "6.498",
      "High": "6.529",
      "Low": "6.488",
      "Change %": "-0.61%"
  },
  {
      "Date": "30-Aug-19",
      "Price": "6.556",
      "Open": "6.562",
      "High": "6.582",
      "Low": "6.553",
      "Change %": "0.20%"
  },
  {
      "Date": "29-Aug-19",
      "Price": "6.543",
      "Open": "6.571",
      "High": "6.624",
      "Low": "6.537",
      "Change %": "-0.32%"
  },
  {
      "Date": "28-Aug-19",
      "Price": "6.564",
      "Open": "6.518",
      "High": "6.576",
      "Low": "6.501",
      "Change %": "0.66%"
  },
  {
      "Date": "27-Aug-19",
      "Price": "6.521",
      "Open": "6.393",
      "High": "6.53",
      "Low": "6.391",
      "Change %": "0.76%"
  },
  {
      "Date": "26-Aug-19",
      "Price": "6.472",
      "Open": "6.501",
      "High": "6.526",
      "Low": "6.472",
      "Change %": "-1.40%"
  },
  {
      "Date": "23-Aug-19",
      "Price": "6.564",
      "Open": "6.567",
      "High": "6.578",
      "Low": "6.551",
      "Change %": "0.15%"
  },
  {
      "Date": "22-Aug-19",
      "Price": "6.554",
      "Open": "6.585",
      "High": "6.586",
      "Low": "6.531",
      "Change %": "-0.09%"
  },
  {
      "Date": "21-Aug-19",
      "Price": "6.56",
      "Open": "6.583",
      "High": "6.583",
      "Low": "6.534",
      "Change %": "-0.27%"
  },
  {
      "Date": "20-Aug-19",
      "Price": "6.578",
      "Open": "6.584",
      "High": "6.627",
      "Low": "6.572",
      "Change %": "-0.05%"
  },
  {
      "Date": "19-Aug-19",
      "Price": "6.581",
      "Open": "6.553",
      "High": "6.596",
      "Low": "6.549",
      "Change %": "0.70%"
  },
  {
      "Date": "16-Aug-19",
      "Price": "6.535",
      "Open": "6.584",
      "High": "6.596",
      "Low": "6.527",
      "Change %": "-1.28%"
  },
  {
      "Date": "14-Aug-19",
      "Price": "6.62",
      "Open": "6.541",
      "High": "6.639",
      "Low": "6.523",
      "Change %": "1.56%"
  },
  {
      "Date": "13-Aug-19",
      "Price": "6.518",
      "Open": "6.503",
      "High": "6.538",
      "Low": "6.496",
      "Change %": "0.37%"
  },
  {
      "Date": "9-Aug-19",
      "Price": "6.494",
      "Open": "6.404",
      "High": "6.523",
      "Low": "6.399",
      "Change %": "1.44%"
  },
  {
      "Date": "8-Aug-19",
      "Price": "6.402",
      "Open": "6.381",
      "High": "6.404",
      "Low": "6.364",
      "Change %": "0.52%"
  },
  {
      "Date": "7-Aug-19",
      "Price": "6.369",
      "Open": "6.314",
      "High": "6.398",
      "Low": "6.296",
      "Change %": "0.49%"
  },
  {
      "Date": "6-Aug-19",
      "Price": "6.338",
      "Open": "6.361",
      "High": "6.381",
      "Low": "6.325",
      "Change %": "-0.81%"
  },
  {
      "Date": "5-Aug-19",
      "Price": "6.39",
      "Open": "6.367",
      "High": "6.423",
      "Low": "6.361",
      "Change %": "0.60%"
  },
  {
      "Date": "2-Aug-19",
      "Price": "6.352",
      "Open": "6.333",
      "High": "6.361",
      "Low": "6.324",
      "Change %": "-1.11%"
  },
  {
      "Date": "1-Aug-19",
      "Price": "6.423",
      "Open": "6.394",
      "High": "6.432",
      "Low": "6.384",
      "Change %": "0.85%"
  },
  {
      "Date": "31-Jul-19",
      "Price": "6.369",
      "Open": "6.403",
      "High": "6.403",
      "Low": "6.359",
      "Change %": "-0.28%"
  },
  {
      "Date": "30-Jul-19",
      "Price": "6.387",
      "Open": "6.416",
      "High": "6.437",
      "Low": "6.379",
      "Change %": "-0.39%"
  },
  {
      "Date": "29-Jul-19",
      "Price": "6.412",
      "Open": "6.452",
      "High": "6.46",
      "Low": "6.412",
      "Change %": "-1.73%"
  },
  {
      "Date": "26-Jul-19",
      "Price": "6.525",
      "Open": "6.538",
      "High": "6.556",
      "Low": "6.506",
      "Change %": "0.17%"
  },
  {
      "Date": "25-Jul-19",
      "Price": "6.514",
      "Open": "6.441",
      "High": "6.551",
      "Low": "6.422",
      "Change %": "1.18%"
  },
  {
      "Date": "24-Jul-19",
      "Price": "6.438",
      "Open": "6.476",
      "High": "6.479",
      "Low": "6.425",
      "Change %": "-0.40%"
  },
  {
      "Date": "23-Jul-19",
      "Price": "6.464",
      "Open": "6.452",
      "High": "6.477",
      "Low": "6.408",
      "Change %": "0.72%"
  },
  {
      "Date": "22-Jul-19",
      "Price": "6.418",
      "Open": "6.424",
      "High": "6.453",
      "Low": "6.406",
      "Change %": "0.90%"
  },
  {
      "Date": "19-Jul-19",
      "Price": "6.361",
      "Open": "6.383",
      "High": "6.385",
      "Low": "6.348",
      "Change %": "-0.41%"
  },
  {
      "Date": "18-Jul-19",
      "Price": "6.387",
      "Open": "6.31",
      "High": "6.402",
      "Low": "6.31",
      "Change %": "0.66%"
  },
  {
      "Date": "17-Jul-19",
      "Price": "6.345",
      "Open": "6.281",
      "High": "6.413",
      "Low": "6.266",
      "Change %": "0.22%"
  },
  {
      "Date": "16-Jul-19",
      "Price": "6.331",
      "Open": "6.413",
      "High": "6.413",
      "Low": "6.317",
      "Change %": "-1.59%"
  },
  {
      "Date": "15-Jul-19",
      "Price": "6.433",
      "Open": "6.459",
      "High": "6.467",
      "Low": "6.43",
      "Change %": "-0.85%"
  },
  {
      "Date": "12-Jul-19",
      "Price": "6.488",
      "Open": "6.528",
      "High": "6.541",
      "Low": "6.457",
      "Change %": "-0.08%"
  },
  {
      "Date": "11-Jul-19",
      "Price": "6.493",
      "Open": "6.558",
      "High": "6.562",
      "Low": "6.487",
      "Change %": "-0.76%"
  },
  {
      "Date": "10-Jul-19",
      "Price": "6.543",
      "Open": "6.588",
      "High": "6.61",
      "Low": "6.516",
      "Change %": "-0.68%"
  },
  {
      "Date": "9-Jul-19",
      "Price": "6.588",
      "Open": "6.555",
      "High": "6.61",
      "Low": "6.555",
      "Change %": "0.40%"
  },
  {
      "Date": "8-Jul-19",
      "Price": "6.562",
      "Open": "6.713",
      "High": "6.713",
      "Low": "6.562",
      "Change %": "-1.99%"
  },
  {
      "Date": "5-Jul-19",
      "Price": "6.695",
      "Open": "6.755",
      "High": "6.766",
      "Low": "6.576",
      "Change %": "-0.83%"
  },
  {
      "Date": "4-Jul-19",
      "Price": "6.751",
      "Open": "6.837",
      "High": "6.849",
      "Low": "6.751",
      "Change %": "-1.20%"
  },
  {
      "Date": "3-Jul-19",
      "Price": "6.833",
      "Open": "6.809",
      "High": "6.835",
      "Low": "6.804",
      "Change %": "-0.19%"
  },
  {
      "Date": "2-Jul-19",
      "Price": "6.846",
      "Open": "6.86",
      "High": "6.876",
      "Low": "6.829",
      "Change %": "-0.52%"
  },
  {
      "Date": "1-Jul-19",
      "Price": "6.882",
      "Open": "6.906",
      "High": "6.93",
      "Low": "6.882",
      "Change %": "0.04%"
  },
  {
      "Date": "28-Jun-19",
      "Price": "6.879",
      "Open": "6.888",
      "High": "6.9",
      "Low": "6.87",
      "Change %": "-0.26%"
  },
  {
      "Date": "27-Jun-19",
      "Price": "6.897",
      "Open": "6.957",
      "High": "6.957",
      "Low": "6.88",
      "Change %": "-0.52%"
  },
  {
      "Date": "26-Jun-19",
      "Price": "6.933",
      "Open": "6.917",
      "High": "6.942",
      "Low": "6.917",
      "Change %": "0.74%"
  },
  {
      "Date": "25-Jun-19",
      "Price": "6.882",
      "Open": "6.838",
      "High": "6.896",
      "Low": "6.837",
      "Change %": "0.45%"
  },
  {
      "Date": "24-Jun-19",
      "Price": "6.851",
      "Open": "6.84",
      "High": "6.883",
      "Low": "6.811",
      "Change %": "-0.15%"
  },
  {
      "Date": "21-Jun-19",
      "Price": "6.861",
      "Open": "6.825",
      "High": "6.893",
      "Low": "6.825",
      "Change %": "1.03%"
  },
  {
      "Date": "20-Jun-19",
      "Price": "6.791",
      "Open": "6.769",
      "High": "6.791",
      "Low": "6.734",
      "Change %": "-0.73%"
  },
  {
      "Date": "19-Jun-19",
      "Price": "6.841",
      "Open": "6.831",
      "High": "6.849",
      "Low": "6.817",
      "Change %": "0.48%"
  },
  {
      "Date": "18-Jun-19",
      "Price": "6.808",
      "Open": "6.912",
      "High": "6.92",
      "Low": "6.808",
      "Change %": "-1.77%"
  },
  {
      "Date": "17-Jun-19",
      "Price": "6.931",
      "Open": "6.933",
      "High": "6.947",
      "Low": "6.915",
      "Change %": "0.17%"
  },
  {
      "Date": "14-Jun-19",
      "Price": "6.919",
      "Open": "6.993",
      "High": "6.997",
      "Low": "6.914",
      "Change %": "-1.28%"
  },
  {
      "Date": "13-Jun-19",
      "Price": "7.009",
      "Open": "6.989",
      "High": "7.028",
      "Low": "6.965",
      "Change %": "-0.04%"
  },
  {
      "Date": "12-Jun-19",
      "Price": "7.012",
      "Open": "7.022",
      "High": "7.025",
      "Low": "6.995",
      "Change %": "-0.36%"
  },
  {
      "Date": "11-Jun-19",
      "Price": "7.037",
      "Open": "7.083",
      "High": "7.097",
      "Low": "7.036",
      "Change %": "-0.57%"
  },
  {
      "Date": "10-Jun-19",
      "Price": "7.077",
      "Open": "6.984",
      "High": "7.087",
      "Low": "6.983",
      "Change %": "1.48%"
  },
  {
      "Date": "7-Jun-19",
      "Price": "6.974",
      "Open": "6.951",
      "High": "6.984",
      "Low": "6.948",
      "Change %": "0.62%"
  },
  {
      "Date": "6-Jun-19",
      "Price": "6.931",
      "Open": "7.012",
      "High": "7.012",
      "Low": "6.876",
      "Change %": "-1.30%"
  },
  {
      "Date": "4-Jun-19",
      "Price": "7.022",
      "Open": "6.979",
      "High": "7.024",
      "Low": "6.977",
      "Change %": "0.57%"
  },
  {
      "Date": "3-Jun-19",
      "Price": "6.982",
      "Open": "6.949",
      "High": "7.001",
      "Low": "6.941",
      "Change %": "-0.71%"
  },
  {
      "Date": "31-May-19",
      "Price": "7.032",
      "Open": "7.075",
      "High": "7.077",
      "Low": "7.03",
      "Change %": "-1.42%"
  },
  {
      "Date": "30-May-19",
      "Price": "7.133",
      "Open": "7.135",
      "High": "7.139",
      "Low": "7.127",
      "Change %": "0.11%"
  },
  {
      "Date": "29-May-19",
      "Price": "7.125",
      "Open": "7.127",
      "High": "7.143",
      "Low": "7.116",
      "Change %": "-0.34%"
  },
  {
      "Date": "28-May-19",
      "Price": "7.149",
      "Open": "7.177",
      "High": "7.179",
      "Low": "7.135",
      "Change %": "-0.25%"
  },
  {
      "Date": "27-May-19",
      "Price": "7.167",
      "Open": "7.226",
      "High": "7.228",
      "Low": "7.162",
      "Change %": "-0.82%"
  },
  {
      "Date": "24-May-19",
      "Price": "7.226",
      "Open": "7.22",
      "High": "7.239",
      "Low": "7.203",
      "Change %": "-0.18%"
  },
  {
      "Date": "23-May-19",
      "Price": "7.239",
      "Open": "7.232",
      "High": "7.244",
      "Low": "7.191",
      "Change %": "-0.29%"
  },
  {
      "Date": "22-May-19",
      "Price": "7.26",
      "Open": "7.302",
      "High": "7.308",
      "Low": "7.26",
      "Change %": "-0.59%"
  },
  {
      "Date": "21-May-19",
      "Price": "7.303",
      "Open": "7.293",
      "High": "7.312",
      "Low": "7.286",
      "Change %": "0.23%"
  },
  {
      "Date": "20-May-19",
      "Price": "7.286",
      "Open": "7.287",
      "High": "7.309",
      "Low": "7.275",
      "Change %": "-1.03%"
  },
  {
      "Date": "17-May-19",
      "Price": "7.362",
      "Open": "7.397",
      "High": "7.397",
      "Low": "7.36",
      "Change %": "-0.20%"
  },
  {
      "Date": "16-May-19",
      "Price": "7.377",
      "Open": "7.394",
      "High": "7.402",
      "Low": "7.375",
      "Change %": "-0.03%"
  },
  {
      "Date": "15-May-19",
      "Price": "7.379",
      "Open": "7.379",
      "High": "7.384",
      "Low": "7.36",
      "Change %": "0.01%"
  },
  {
      "Date": "14-May-19",
      "Price": "7.378",
      "Open": "7.388",
      "High": "7.388",
      "Low": "7.348",
      "Change %": "-0.14%"
  },
  {
      "Date": "13-May-19",
      "Price": "7.388",
      "Open": "7.418",
      "High": "7.419",
      "Low": "7.385",
      "Change %": "-0.34%"
  },
  {
      "Date": "10-May-19",
      "Price": "7.413",
      "Open": "7.407",
      "High": "7.426",
      "Low": "7.403",
      "Change %": "0.22%"
  },
  {
      "Date": "9-May-19",
      "Price": "7.397",
      "Open": "7.387",
      "High": "7.4",
      "Low": "7.371",
      "Change %": "0.30%"
  },
  {
      "Date": "8-May-19",
      "Price": "7.375",
      "Open": "7.392",
      "High": "7.392",
      "Low": "7.37",
      "Change %": "-0.09%"
  },
  {
      "Date": "7-May-19",
      "Price": "7.382",
      "Open": "7.417",
      "High": "7.424",
      "Low": "7.381",
      "Change %": "-0.16%"
  },
  {
      "Date": "6-May-19",
      "Price": "7.394",
      "Open": "7.403",
      "High": "7.403",
      "Low": "7.365",
      "Change %": "-0.04%"
  },
  {
      "Date": "3-May-19",
      "Price": "7.397",
      "Open": "7.372",
      "High": "7.401",
      "Low": "7.369",
      "Change %": "0.15%"
  },
  {
      "Date": "2-May-19",
      "Price": "7.386",
      "Open": "7.411",
      "High": "7.411",
      "Low": "7.386",
      "Change %": "-0.38%"
  },
  {
      "Date": "30-Apr-19",
      "Price": "7.414",
      "Open": "7.395",
      "High": "7.421",
      "Low": "7.387",
      "Change %": "0.08%"
  },
  {
      "Date": "26-Apr-19",
      "Price": "7.408",
      "Open": "7.436",
      "High": "7.446",
      "Low": "7.405",
      "Change %": "-0.58%"
  },
  {
      "Date": "25-Apr-19",
      "Price": "7.451",
      "Open": "7.441",
      "High": "7.477",
      "Low": "7.441",
      "Change %": "0.36%"
  },
  {
      "Date": "24-Apr-19",
      "Price": "7.424",
      "Open": "7.429",
      "High": "7.436",
      "Low": "7.421",
      "Change %": "-0.66%"
  },
  {
      "Date": "23-Apr-19",
      "Price": "7.473",
      "Open": "7.489",
      "High": "7.495",
      "Low": "7.46",
      "Change %": "-0.01%"
  },
  {
      "Date": "22-Apr-19",
      "Price": "7.474",
      "Open": "7.455",
      "High": "7.497",
      "Low": "7.455",
      "Change %": "0.73%"
  },
  {
      "Date": "18-Apr-19",
      "Price": "7.42",
      "Open": "7.412",
      "High": "7.43",
      "Low": "7.408",
      "Change %": "0.41%"
  },
  {
      "Date": "16-Apr-19",
      "Price": "7.39",
      "Open": "7.39",
      "High": "7.404",
      "Low": "7.376",
      "Change %": "-0.03%"
  },
  {
      "Date": "15-Apr-19",
      "Price": "7.392",
      "Open": "7.411",
      "High": "7.414",
      "Low": "7.387",
      "Change %": "-0.23%"
  },
  {
      "Date": "12-Apr-19",
      "Price": "7.409",
      "Open": "7.383",
      "High": "7.41",
      "Low": "7.38",
      "Change %": "0.52%"
  },
  {
      "Date": "11-Apr-19",
      "Price": "7.371",
      "Open": "7.381",
      "High": "7.381",
      "Low": "7.362",
      "Change %": "0.05%"
  },
  {
      "Date": "10-Apr-19",
      "Price": "7.367",
      "Open": "7.363",
      "High": "7.379",
      "Low": "7.356",
      "Change %": "-0.09%"
  },
  {
      "Date": "9-Apr-19",
      "Price": "7.374",
      "Open": "7.411",
      "High": "7.418",
      "Low": "7.373",
      "Change %": "-0.35%"
  },
  {
      "Date": "8-Apr-19",
      "Price": "7.4",
      "Open": "7.374",
      "High": "7.411",
      "Low": "7.374",
      "Change %": "0.64%"
  },
  {
      "Date": "5-Apr-19",
      "Price": "7.353",
      "Open": "7.352",
      "High": "7.362",
      "Low": "7.316",
      "Change %": "0.07%"
  },
  {
      "Date": "4-Apr-19",
      "Price": "7.348",
      "Open": "7.271",
      "High": "7.348",
      "Low": "7.229",
      "Change %": "1.06%"
  },
  {
      "Date": "3-Apr-19",
      "Price": "7.271",
      "Open": "7.28",
      "High": "7.288",
      "Low": "7.255",
      "Change %": "-0.01%"
  },
  {
      "Date": "2-Apr-19",
      "Price": "7.272",
      "Open": "7.356",
      "High": "7.356",
      "Low": "7.271",
      "Change %": "-1.01%"
  },
  {
      "Date": "29-Mar-19",
      "Price": "7.346",
      "Open": "7.329",
      "High": "7.351",
      "Low": "7.31",
      "Change %": "0.31%"
  },
  {
      "Date": "28-Mar-19",
      "Price": "7.323",
      "Open": "7.319",
      "High": "7.334",
      "Low": "7.301",
      "Change %": "-0.05%"
  },
  {
      "Date": "27-Mar-19",
      "Price": "7.327",
      "Open": "7.344",
      "High": "7.357",
      "Low": "7.323",
      "Change %": "-0.20%"
  },
  {
      "Date": "26-Mar-19",
      "Price": "7.342",
      "Open": "7.329",
      "High": "7.343",
      "Low": "7.328",
      "Change %": "-1.73%"
  },
  {
      "Date": "25-Mar-19",
      "Price": "7.471",
      "Open": "7.471",
      "High": "7.474",
      "Low": "7.456",
      "Change %": "-0.44%"
  },
  {
      "Date": "22-Mar-19",
      "Price": "7.504",
      "Open": "7.477",
      "High": "7.506",
      "Low": "7.474",
      "Change %": "-0.17%"
  },
  {
      "Date": "20-Mar-19",
      "Price": "7.517",
      "Open": "7.535",
      "High": "7.544",
      "Low": "7.513",
      "Change %": "-0.25%"
  },
  {
      "Date": "19-Mar-19",
      "Price": "7.536",
      "Open": "7.494",
      "High": "7.545",
      "Low": "7.479",
      "Change %": "0.75%"
  },
  {
      "Date": "18-Mar-19",
      "Price": "7.48",
      "Open": "7.477",
      "High": "7.486",
      "Low": "7.452",
      "Change %": "-0.29%"
  },
  {
      "Date": "15-Mar-19",
      "Price": "7.502",
      "Open": "7.558",
      "High": "7.561",
      "Low": "7.5",
      "Change %": "-0.68%"
  },
  {
      "Date": "14-Mar-19",
      "Price": "7.553",
      "Open": "7.59",
      "High": "7.59",
      "Low": "7.536",
      "Change %": "0.07%"
  },
  {
      "Date": "13-Mar-19",
      "Price": "7.548",
      "Open": "7.534",
      "High": "7.553",
      "Low": "7.523",
      "Change %": "0.47%"
  },
  {
      "Date": "12-Mar-19",
      "Price": "7.513",
      "Open": "7.527",
      "High": "7.536",
      "Low": "7.493",
      "Change %": "0.01%"
  },
  {
      "Date": "11-Mar-19",
      "Price": "7.512",
      "Open": "7.54",
      "High": "7.542",
      "Low": "7.503",
      "Change %": "-0.27%"
  },
  {
      "Date": "8-Mar-19",
      "Price": "7.532",
      "Open": "7.565",
      "High": "7.571",
      "Low": "7.524",
      "Change %": "-0.63%"
  },
  {
      "Date": "7-Mar-19",
      "Price": "7.58",
      "Open": "7.575",
      "High": "7.58",
      "Low": "7.561",
      "Change %": "0.11%"
  },
  {
      "Date": "6-Mar-19",
      "Price": "7.572",
      "Open": "7.554",
      "High": "7.587",
      "Low": "7.548",
      "Change %": "0.21%"
  },
  {
      "Date": "5-Mar-19",
      "Price": "7.556",
      "Open": "7.55",
      "High": "7.561",
      "Low": "7.53",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Mar-19",
      "Price": "7.556",
      "Open": "7.588",
      "High": "7.589",
      "Low": "7.545",
      "Change %": "-0.46%"
  },
  {
      "Date": "28-Feb-19",
      "Price": "7.591",
      "Open": "7.679",
      "High": "7.679",
      "Low": "7.591",
      "Change %": "-1.06%"
  },
  {
      "Date": "27-Feb-19",
      "Price": "7.672",
      "Open": "7.608",
      "High": "7.699",
      "Low": "7.591",
      "Change %": "1.12%"
  },
  {
      "Date": "26-Feb-19",
      "Price": "7.587",
      "Open": "7.624",
      "High": "7.624",
      "Low": "7.578",
      "Change %": "0.04%"
  },
  {
      "Date": "25-Feb-19",
      "Price": "7.584",
      "Open": "7.591",
      "High": "7.611",
      "Low": "7.582",
      "Change %": "-0.28%"
  },
  {
      "Date": "22-Feb-19",
      "Price": "7.605",
      "Open": "7.544",
      "High": "7.61",
      "Low": "7.544",
      "Change %": "0.81%"
  },
  {
      "Date": "21-Feb-19",
      "Price": "7.544",
      "Open": "7.573",
      "High": "7.573",
      "Low": "7.543",
      "Change %": "-0.01%"
  },
  {
      "Date": "20-Feb-19",
      "Price": "7.545",
      "Open": "7.571",
      "High": "7.572",
      "Low": "7.541",
      "Change %": "-0.46%"
  },
  {
      "Date": "18-Feb-19",
      "Price": "7.58",
      "Open": "7.601",
      "High": "7.601",
      "Low": "7.565",
      "Change %": "0.04%"
  },
  {
      "Date": "15-Feb-19",
      "Price": "7.577",
      "Open": "7.539",
      "High": "7.584",
      "Low": "7.534",
      "Change %": "0.74%"
  },
  {
      "Date": "14-Feb-19",
      "Price": "7.521",
      "Open": "7.485",
      "High": "7.524",
      "Low": "7.483",
      "Change %": "0.74%"
  },
  {
      "Date": "13-Feb-19",
      "Price": "7.466",
      "Open": "7.469",
      "High": "7.479",
      "Low": "7.436",
      "Change %": "-0.90%"
  },
  {
      "Date": "12-Feb-19",
      "Price": "7.534",
      "Open": "7.54",
      "High": "7.54",
      "Low": "7.51",
      "Change %": "0.07%"
  },
  {
      "Date": "11-Feb-19",
      "Price": "7.529",
      "Open": "7.524",
      "High": "7.548",
      "Low": "7.509",
      "Change %": "0.07%"
  },
  {
      "Date": "8-Feb-19",
      "Price": "7.524",
      "Open": "7.488",
      "High": "7.531",
      "Low": "7.459",
      "Change %": "0.31%"
  },
  {
      "Date": "7-Feb-19",
      "Price": "7.501",
      "Open": "7.573",
      "High": "7.588",
      "Low": "7.47",
      "Change %": "-0.85%"
  },
  {
      "Date": "6-Feb-19",
      "Price": "7.565",
      "Open": "7.602",
      "High": "7.602",
      "Low": "7.565",
      "Change %": "-0.66%"
  },
  {
      "Date": "5-Feb-19",
      "Price": "7.615",
      "Open": "7.669",
      "High": "7.672",
      "Low": "7.609",
      "Change %": "-0.74%"
  },
  {
      "Date": "4-Feb-19",
      "Price": "7.672",
      "Open": "7.65",
      "High": "7.674",
      "Low": "7.632",
      "Change %": "0.81%"
  },
  {
      "Date": "1-Feb-19",
      "Price": "7.61",
      "Open": "7.47",
      "High": "7.651",
      "Low": "7.425",
      "Change %": "1.70%"
  },
  {
      "Date": "31-Jan-19",
      "Price": "7.483",
      "Open": "7.532",
      "High": "7.551",
      "Low": "7.482",
      "Change %": "-0.90%"
  },
  {
      "Date": "30-Jan-19",
      "Price": "7.551",
      "Open": "7.541",
      "High": "7.556",
      "Low": "7.533",
      "Change %": "0.35%"
  },
  {
      "Date": "29-Jan-19",
      "Price": "7.525",
      "Open": "7.533",
      "High": "7.551",
      "Low": "7.522",
      "Change %": "-0.21%"
  },
  {
      "Date": "28-Jan-19",
      "Price": "7.541",
      "Open": "7.558",
      "High": "7.562",
      "Low": "7.535",
      "Change %": "-0.07%"
  },
  {
      "Date": "25-Jan-19",
      "Price": "7.546",
      "Open": "7.578",
      "High": "7.578",
      "Low": "7.524",
      "Change %": "-0.15%"
  },
  {
      "Date": "24-Jan-19",
      "Price": "7.557",
      "Open": "7.569",
      "High": "7.604",
      "Low": "7.555",
      "Change %": "-0.26%"
  },
  {
      "Date": "23-Jan-19",
      "Price": "7.577",
      "Open": "7.52",
      "High": "7.585",
      "Low": "7.511",
      "Change %": "0.58%"
  },
  {
      "Date": "22-Jan-19",
      "Price": "7.533",
      "Open": "7.552",
      "High": "7.555",
      "Low": "7.516",
      "Change %": "-0.48%"
  },
  {
      "Date": "21-Jan-19",
      "Price": "7.569",
      "Open": "7.628",
      "High": "7.65",
      "Low": "7.545",
      "Change %": "-0.36%"
  },
  {
      "Date": "18-Jan-19",
      "Price": "7.596",
      "Open": "7.593",
      "High": "7.609",
      "Low": "7.567",
      "Change %": "0.62%"
  },
  {
      "Date": "17-Jan-19",
      "Price": "7.549",
      "Open": "7.573",
      "High": "7.587",
      "Low": "7.533",
      "Change %": "-0.19%"
  },
  {
      "Date": "16-Jan-19",
      "Price": "7.563",
      "Open": "7.479",
      "High": "7.577",
      "Low": "7.465",
      "Change %": "1.20%"
  },
  {
      "Date": "15-Jan-19",
      "Price": "7.473",
      "Open": "7.472",
      "High": "7.49",
      "Low": "7.457",
      "Change %": "0.57%"
  },
  {
      "Date": "14-Jan-19",
      "Price": "7.431",
      "Open": "7.474",
      "High": "7.474",
      "Low": "7.423",
      "Change %": "-0.96%"
  },
  {
      "Date": "11-Jan-19",
      "Price": "7.503",
      "Open": "7.479",
      "High": "7.519",
      "Low": "7.449",
      "Change %": "0.36%"
  },
  {
      "Date": "10-Jan-19",
      "Price": "7.476",
      "Open": "7.488",
      "High": "7.516",
      "Low": "7.466",
      "Change %": "0.08%"
  },
  {
      "Date": "9-Jan-19",
      "Price": "7.47",
      "Open": "7.476",
      "High": "7.482",
      "Low": "7.444",
      "Change %": "0.21%"
  },
  {
      "Date": "8-Jan-19",
      "Price": "7.454",
      "Open": "7.503",
      "High": "7.534",
      "Low": "7.439",
      "Change %": "-0.72%"
  },
  {
      "Date": "7-Jan-19",
      "Price": "7.508",
      "Open": "7.467",
      "High": "7.519",
      "Low": "7.439",
      "Change %": "0.81%"
  },
  {
      "Date": "4-Jan-19",
      "Price": "7.448",
      "Open": "7.407",
      "High": "7.475",
      "Low": "7.406",
      "Change %": "0.28%"
  },
  {
      "Date": "3-Jan-19",
      "Price": "7.427",
      "Open": "7.397",
      "High": "7.433",
      "Low": "7.381",
      "Change %": "0.99%"
  },
  {
      "Date": "2-Jan-19",
      "Price": "7.354",
      "Open": "7.409",
      "High": "7.415",
      "Low": "7.35",
      "Change %": "-0.86%"
  },
  {
      "Date": "1-Jan-19",
      "Price": "7.418",
      "Open": "7.401",
      "High": "7.447",
      "Low": "7.385",
      "Change %": "0.65%"
  },
  {
      "Date": "31-Dec-18",
      "Price": "7.37",
      "Open": "7.368",
      "High": "7.389",
      "Low": "7.339",
      "Change %": "-0.28%"
  },
  {
      "Date": "28-Dec-18",
      "Price": "7.391",
      "Open": "7.274",
      "High": "7.393",
      "Low": "7.266",
      "Change %": "1.57%"
  },
  {
      "Date": "27-Dec-18",
      "Price": "7.277",
      "Open": "7.307",
      "High": "7.307",
      "Low": "7.235",
      "Change %": "0.19%"
  },
  {
      "Date": "26-Dec-18",
      "Price": "7.263",
      "Open": "7.249",
      "High": "7.267",
      "Low": "7.228",
      "Change %": "-0.33%"
  },
  {
      "Date": "24-Dec-18",
      "Price": "7.287",
      "Open": "7.298",
      "High": "7.306",
      "Low": "7.278",
      "Change %": "0.15%"
  },
  {
      "Date": "21-Dec-18",
      "Price": "7.276",
      "Open": "7.269",
      "High": "7.316",
      "Low": "7.259",
      "Change %": "0.06%"
  },
  {
      "Date": "20-Dec-18",
      "Price": "7.272",
      "Open": "7.253",
      "High": "7.275",
      "Low": "7.233",
      "Change %": "0.73%"
  },
  {
      "Date": "19-Dec-18",
      "Price": "7.219",
      "Open": "7.241",
      "High": "7.288",
      "Low": "7.218",
      "Change %": "-1.72%"
  },
  {
      "Date": "18-Dec-18",
      "Price": "7.345",
      "Open": "7.427",
      "High": "7.438",
      "Low": "7.345",
      "Change %": "-1.55%"
  },
  {
      "Date": "17-Dec-18",
      "Price": "7.461",
      "Open": "7.443",
      "High": "7.463",
      "Low": "7.423",
      "Change %": "0.27%"
  },
  {
      "Date": "14-Dec-18",
      "Price": "7.441",
      "Open": "7.429",
      "High": "7.464",
      "Low": "7.425",
      "Change %": "0.45%"
  },
  {
      "Date": "13-Dec-18",
      "Price": "7.408",
      "Open": "7.389",
      "High": "7.437",
      "Low": "7.382",
      "Change %": "-0.04%"
  },
  {
      "Date": "12-Dec-18",
      "Price": "7.411",
      "Open": "7.476",
      "High": "7.479",
      "Low": "7.41",
      "Change %": "-1.55%"
  },
  {
      "Date": "11-Dec-18",
      "Price": "7.528",
      "Open": "7.695",
      "High": "7.703",
      "Low": "7.512",
      "Change %": "-0.78%"
  },
  {
      "Date": "10-Dec-18",
      "Price": "7.587",
      "Open": "7.505",
      "High": "7.598",
      "Low": "7.493",
      "Change %": "1.65%"
  },
  {
      "Date": "7-Dec-18",
      "Price": "7.464",
      "Open": "7.401",
      "High": "7.48",
      "Low": "7.389",
      "Change %": "0.55%"
  },
  {
      "Date": "6-Dec-18",
      "Price": "7.423",
      "Open": "7.412",
      "High": "7.443",
      "Low": "7.371",
      "Change %": "-0.23%"
  },
  {
      "Date": "5-Dec-18",
      "Price": "7.44",
      "Open": "7.562",
      "High": "7.573",
      "Low": "7.435",
      "Change %": "-1.77%"
  },
  {
      "Date": "4-Dec-18",
      "Price": "7.574",
      "Open": "7.618",
      "High": "7.618",
      "Low": "7.566",
      "Change %": "-0.68%"
  },
  {
      "Date": "3-Dec-18",
      "Price": "7.626",
      "Open": "7.646",
      "High": "7.653",
      "Low": "7.616",
      "Change %": "0.25%"
  },
  {
      "Date": "30-Nov-18",
      "Price": "7.607",
      "Open": "7.627",
      "High": "7.63",
      "Low": "7.596",
      "Change %": "-0.01%"
  },
  {
      "Date": "29-Nov-18",
      "Price": "7.608",
      "Open": "7.62",
      "High": "7.62",
      "Low": "7.576",
      "Change %": "-0.47%"
  },
  {
      "Date": "28-Nov-18",
      "Price": "7.644",
      "Open": "7.688",
      "High": "7.698",
      "Low": "7.644",
      "Change %": "-1.16%"
  },
  {
      "Date": "27-Nov-18",
      "Price": "7.734",
      "Open": "7.747",
      "High": "7.75",
      "Low": "7.713",
      "Change %": "0.10%"
  },
  {
      "Date": "26-Nov-18",
      "Price": "7.726",
      "Open": "7.677",
      "High": "7.738",
      "Low": "7.677",
      "Change %": "0.21%"
  },
  {
      "Date": "22-Nov-18",
      "Price": "7.71",
      "Open": "7.754",
      "High": "7.767",
      "Low": "7.704",
      "Change %": "-1.05%"
  },
  {
      "Date": "20-Nov-18",
      "Price": "7.792",
      "Open": "7.767",
      "High": "7.795",
      "Low": "7.756",
      "Change %": "0.01%"
  },
  {
      "Date": "19-Nov-18",
      "Price": "7.791",
      "Open": "7.82",
      "High": "7.836",
      "Low": "7.789",
      "Change %": "-0.31%"
  },
  {
      "Date": "16-Nov-18",
      "Price": "7.815",
      "Open": "7.775",
      "High": "7.816",
      "Low": "7.768",
      "Change %": "0.76%"
  },
  {
      "Date": "15-Nov-18",
      "Price": "7.756",
      "Open": "7.736",
      "High": "7.76",
      "Low": "7.734",
      "Change %": "0.30%"
  },
  {
      "Date": "14-Nov-18",
      "Price": "7.733",
      "Open": "7.724",
      "High": "7.748",
      "Low": "7.707",
      "Change %": "-0.37%"
  },
  {
      "Date": "13-Nov-18",
      "Price": "7.762",
      "Open": "7.777",
      "High": "7.783",
      "Low": "7.757",
      "Change %": "-0.54%"
  },
  {
      "Date": "12-Nov-18",
      "Price": "7.804",
      "Open": "7.789",
      "High": "7.821",
      "Low": "7.788",
      "Change %": "0.50%"
  },
  {
      "Date": "9-Nov-18",
      "Price": "7.765",
      "Open": "7.766",
      "High": "7.781",
      "Low": "7.757",
      "Change %": "-0.40%"
  },
  {
      "Date": "6-Nov-18",
      "Price": "7.796",
      "Open": "7.801",
      "High": "7.811",
      "Low": "7.791",
      "Change %": "-0.15%"
  },
  {
      "Date": "5-Nov-18",
      "Price": "7.808",
      "Open": "7.799",
      "High": "7.812",
      "Low": "7.791",
      "Change %": "0.35%"
  },
  {
      "Date": "2-Nov-18",
      "Price": "7.781",
      "Open": "7.785",
      "High": "7.793",
      "Low": "7.752",
      "Change %": "-0.54%"
  },
  {
      "Date": "1-Nov-18",
      "Price": "7.823",
      "Open": "7.831",
      "High": "7.84",
      "Low": "7.82",
      "Change %": "-0.38%"
  },
  {
      "Date": "31-Oct-18",
      "Price": "7.853",
      "Open": "7.881",
      "High": "7.893",
      "Low": "7.846",
      "Change %": "0.24%"
  },
  {
      "Date": "30-Oct-18",
      "Price": "7.834",
      "Open": "7.825",
      "High": "7.84",
      "Low": "7.818",
      "Change %": "0.31%"
  },
  {
      "Date": "29-Oct-18",
      "Price": "7.81",
      "Open": "7.836",
      "High": "7.836",
      "Low": "7.797",
      "Change %": "-0.85%"
  },
  {
      "Date": "26-Oct-18",
      "Price": "7.877",
      "Open": "7.885",
      "High": "7.891",
      "Low": "7.861",
      "Change %": "0.13%"
  },
  {
      "Date": "25-Oct-18",
      "Price": "7.867",
      "Open": "7.864",
      "High": "7.885",
      "Low": "7.845",
      "Change %": "-0.06%"
  },
  {
      "Date": "24-Oct-18",
      "Price": "7.872",
      "Open": "7.852",
      "High": "7.878",
      "Low": "7.83",
      "Change %": "-0.28%"
  },
  {
      "Date": "23-Oct-18",
      "Price": "7.894",
      "Open": "7.937",
      "High": "7.938",
      "Low": "7.88",
      "Change %": "-0.45%"
  },
  {
      "Date": "22-Oct-18",
      "Price": "7.93",
      "Open": "7.935",
      "High": "7.935",
      "Low": "7.892",
      "Change %": "0.11%"
  },
  {
      "Date": "19-Oct-18",
      "Price": "7.921",
      "Open": "7.891",
      "High": "7.927",
      "Low": "7.88",
      "Change %": "0.15%"
  },
  {
      "Date": "17-Oct-18",
      "Price": "7.909",
      "Open": "7.885",
      "High": "7.926",
      "Low": "7.863",
      "Change %": "0.47%"
  },
  {
      "Date": "16-Oct-18",
      "Price": "7.872",
      "Open": "7.946",
      "High": "7.954",
      "Low": "7.87",
      "Change %": "-0.62%"
  },
  {
      "Date": "15-Oct-18",
      "Price": "7.921",
      "Open": "7.962",
      "High": "7.983",
      "Low": "7.921",
      "Change %": "-0.79%"
  },
  {
      "Date": "12-Oct-18",
      "Price": "7.984",
      "Open": "7.971",
      "High": "8.008",
      "Low": "7.963",
      "Change %": "-0.03%"
  },
  {
      "Date": "11-Oct-18",
      "Price": "7.986",
      "Open": "8.001",
      "High": "8.023",
      "Low": "7.978",
      "Change %": "-0.56%"
  },
  {
      "Date": "10-Oct-18",
      "Price": "8.031",
      "Open": "8.049",
      "High": "8.071",
      "Low": "8.027",
      "Change %": "-0.56%"
  },
  {
      "Date": "9-Oct-18",
      "Price": "8.076",
      "Open": "7.989",
      "High": "8.092",
      "Low": "7.989",
      "Change %": "1.28%"
  },
  {
      "Date": "8-Oct-18",
      "Price": "7.974",
      "Open": "7.989",
      "High": "8.017",
      "Low": "7.968",
      "Change %": "-0.62%"
  },
  {
      "Date": "5-Oct-18",
      "Price": "8.024",
      "Open": "8.158",
      "High": "8.165",
      "Low": "8.024",
      "Change %": "-1.64%"
  },
  {
      "Date": "4-Oct-18",
      "Price": "8.158",
      "Open": "8.193",
      "High": "8.21",
      "Low": "8.155",
      "Change %": "0.57%"
  },
  {
      "Date": "3-Oct-18",
      "Price": "8.112",
      "Open": "8.057",
      "High": "8.115",
      "Low": "8.031",
      "Change %": "1.55%"
  },
  {
      "Date": "1-Oct-18",
      "Price": "7.988",
      "Open": "7.923",
      "High": "7.997",
      "Low": "7.923",
      "Change %": "-0.45%"
  },
  {
      "Date": "28-Sep-18",
      "Price": "8.024",
      "Open": "8.042",
      "High": "8.053",
      "Low": "8.015",
      "Change %": "-0.04%"
  },
  {
      "Date": "27-Sep-18",
      "Price": "8.027",
      "Open": "8.043",
      "High": "8.073",
      "Low": "8.024",
      "Change %": "-0.56%"
  },
  {
      "Date": "26-Sep-18",
      "Price": "8.072",
      "Open": "8.124",
      "High": "8.124",
      "Low": "8.063",
      "Change %": "-0.65%"
  },
  {
      "Date": "25-Sep-18",
      "Price": "8.125",
      "Open": "8.131",
      "High": "8.141",
      "Low": "8.113",
      "Change %": "0.04%"
  },
  {
      "Date": "24-Sep-18",
      "Price": "8.122",
      "Open": "8.094",
      "High": "8.13",
      "Low": "8.074",
      "Change %": "0.57%"
  },
  {
      "Date": "21-Sep-18",
      "Price": "8.076",
      "Open": "8.031",
      "High": "8.135",
      "Low": "8.017",
      "Change %": "0.02%"
  },
  {
      "Date": "19-Sep-18",
      "Price": "8.074",
      "Open": "8.124",
      "High": "8.134",
      "Low": "8.067",
      "Change %": "-0.81%"
  },
  {
      "Date": "18-Sep-18",
      "Price": "8.14",
      "Open": "8.106",
      "High": "8.143",
      "Low": "8.043",
      "Change %": "0.52%"
  },
  {
      "Date": "17-Sep-18",
      "Price": "8.098",
      "Open": "8.164",
      "High": "8.184",
      "Low": "8.09",
      "Change %": "-0.32%"
  },
  {
      "Date": "16-Sep-18",
      "Price": "8.124",
      "Open": "8.143",
      "High": "8.148",
      "Low": "8.121",
      "Change %": "-0.04%"
  },
  {
      "Date": "14-Sep-18",
      "Price": "8.127",
      "Open": "8.093",
      "High": "8.131",
      "Low": "8.091",
      "Change %": "-0.09%"
  },
  {
      "Date": "12-Sep-18",
      "Price": "8.134",
      "Open": "8.218",
      "High": "8.231",
      "Low": "8.121",
      "Change %": "-0.59%"
  },
  {
      "Date": "11-Sep-18",
      "Price": "8.182",
      "Open": "8.155",
      "High": "8.189",
      "Low": "8.134",
      "Change %": "0.29%"
  },
  {
      "Date": "10-Sep-18",
      "Price": "8.158",
      "Open": "8.069",
      "High": "8.158",
      "Low": "8.069",
      "Change %": "1.58%"
  },
  {
      "Date": "7-Sep-18",
      "Price": "8.031",
      "Open": "8.05",
      "High": "8.05",
      "Low": "8.011",
      "Change %": "-0.31%"
  },
  {
      "Date": "6-Sep-18",
      "Price": "8.056",
      "Open": "8.038",
      "High": "8.085",
      "Low": "8.033",
      "Change %": "0.09%"
  },
  {
      "Date": "5-Sep-18",
      "Price": "8.049",
      "Open": "8.043",
      "High": "8.11",
      "Low": "8.033",
      "Change %": "-0.16%"
  },
  {
      "Date": "4-Sep-18",
      "Price": "8.062",
      "Open": "8.006",
      "High": "8.069",
      "Low": "7.986",
      "Change %": "0.79%"
  },
  {
      "Date": "3-Sep-18",
      "Price": "7.999",
      "Open": "7.94",
      "High": "8",
      "Low": "7.917",
      "Change %": "0.60%"
  },
  {
      "Date": "31-Aug-18",
      "Price": "7.951",
      "Open": "7.951",
      "High": "7.958",
      "Low": "7.937",
      "Change %": "0.26%"
  },
  {
      "Date": "30-Aug-18",
      "Price": "7.93",
      "Open": "7.93",
      "High": "7.941",
      "Low": "7.914",
      "Change %": "0.15%"
  },
  {
      "Date": "29-Aug-18",
      "Price": "7.918",
      "Open": "7.914",
      "High": "7.92",
      "Low": "7.895",
      "Change %": "0.29%"
  },
  {
      "Date": "28-Aug-18",
      "Price": "7.895",
      "Open": "7.892",
      "High": "7.921",
      "Low": "7.892",
      "Change %": "0.01%"
  },
  {
      "Date": "27-Aug-18",
      "Price": "7.894",
      "Open": "7.865",
      "High": "7.898",
      "Low": "7.86",
      "Change %": "0.29%"
  },
  {
      "Date": "24-Aug-18",
      "Price": "7.871",
      "Open": "7.895",
      "High": "7.9",
      "Low": "7.871",
      "Change %": "-0.10%"
  },
  {
      "Date": "23-Aug-18",
      "Price": "7.879",
      "Open": "7.878",
      "High": "7.879",
      "Low": "7.861",
      "Change %": "0.66%"
  },
  {
      "Date": "21-Aug-18",
      "Price": "7.827",
      "Open": "7.834",
      "High": "7.834",
      "Low": "7.814",
      "Change %": "-0.14%"
  },
  {
      "Date": "20-Aug-18",
      "Price": "7.838",
      "Open": "7.836",
      "High": "7.842",
      "Low": "7.826",
      "Change %": "-0.29%"
  },
  {
      "Date": "16-Aug-18",
      "Price": "7.861",
      "Open": "7.849",
      "High": "7.876",
      "Low": "7.839",
      "Change %": "0.55%"
  },
  {
      "Date": "14-Aug-18",
      "Price": "7.818",
      "Open": "7.8",
      "High": "7.824",
      "Low": "7.795",
      "Change %": "-0.06%"
  },
  {
      "Date": "13-Aug-18",
      "Price": "7.823",
      "Open": "7.807",
      "High": "7.825",
      "Low": "7.789",
      "Change %": "0.89%"
  },
  {
      "Date": "10-Aug-18",
      "Price": "7.754",
      "Open": "7.743",
      "High": "7.759",
      "Low": "7.737",
      "Change %": "0.12%"
  },
  {
      "Date": "9-Aug-18",
      "Price": "7.745",
      "Open": "7.754",
      "High": "7.762",
      "Low": "7.74",
      "Change %": "-0.48%"
  },
  {
      "Date": "8-Aug-18",
      "Price": "7.782",
      "Open": "7.792",
      "High": "7.793",
      "Low": "7.776",
      "Change %": "-0.05%"
  },
  {
      "Date": "7-Aug-18",
      "Price": "7.786",
      "Open": "7.772",
      "High": "7.787",
      "Low": "7.757",
      "Change %": "0.23%"
  },
  {
      "Date": "6-Aug-18",
      "Price": "7.768",
      "Open": "7.754",
      "High": "7.773",
      "Low": "7.747",
      "Change %": "0.06%"
  },
  {
      "Date": "3-Aug-18",
      "Price": "7.763",
      "Open": "7.742",
      "High": "7.771",
      "Low": "7.739",
      "Change %": "0.54%"
  },
  {
      "Date": "2-Aug-18",
      "Price": "7.721",
      "Open": "7.698",
      "High": "7.732",
      "Low": "7.681",
      "Change %": "0.26%"
  },
  {
      "Date": "1-Aug-18",
      "Price": "7.701",
      "Open": "7.767",
      "High": "7.841",
      "Low": "7.701",
      "Change %": "-0.91%"
  },
  {
      "Date": "31-Jul-18",
      "Price": "7.772",
      "Open": "7.797",
      "High": "7.797",
      "Low": "7.769",
      "Change %": "-0.23%"
  },
  {
      "Date": "30-Jul-18",
      "Price": "7.79",
      "Open": "7.78",
      "High": "7.799",
      "Low": "7.776",
      "Change %": "0.13%"
  },
  {
      "Date": "27-Jul-18",
      "Price": "7.78",
      "Open": "7.766",
      "High": "7.788",
      "Low": "7.762",
      "Change %": "0.27%"
  },
  {
      "Date": "26-Jul-18",
      "Price": "7.759",
      "Open": "7.806",
      "High": "7.806",
      "Low": "7.756",
      "Change %": "-0.33%"
  },
  {
      "Date": "25-Jul-18",
      "Price": "7.785",
      "Open": "7.807",
      "High": "7.811",
      "Low": "7.782",
      "Change %": "0.01%"
  },
  {
      "Date": "24-Jul-18",
      "Price": "7.784",
      "Open": "7.806",
      "High": "7.815",
      "Low": "7.778",
      "Change %": "-0.33%"
  },
  {
      "Date": "23-Jul-18",
      "Price": "7.81",
      "Open": "7.788",
      "High": "7.819",
      "Low": "7.774",
      "Change %": "0.32%"
  },
  {
      "Date": "20-Jul-18",
      "Price": "7.785",
      "Open": "7.787",
      "High": "7.797",
      "Low": "7.772",
      "Change %": "0.00%"
  },
  {
      "Date": "19-Jul-18",
      "Price": "7.785",
      "Open": "7.782",
      "High": "7.785",
      "Low": "7.764",
      "Change %": "0.43%"
  },
  {
      "Date": "18-Jul-18",
      "Price": "7.752",
      "Open": "7.725",
      "High": "7.759",
      "Low": "7.723",
      "Change %": "0.08%"
  },
  {
      "Date": "17-Jul-18",
      "Price": "7.746",
      "Open": "7.763",
      "High": "7.764",
      "Low": "7.729",
      "Change %": "-0.69%"
  },
  {
      "Date": "16-Jul-18",
      "Price": "7.8",
      "Open": "7.82",
      "High": "7.848",
      "Low": "7.797",
      "Change %": "0.12%"
  },
  {
      "Date": "13-Jul-18",
      "Price": "7.791",
      "Open": "7.791",
      "High": "7.802",
      "Low": "7.77",
      "Change %": "0.14%"
  },
  {
      "Date": "12-Jul-18",
      "Price": "7.78",
      "Open": "7.826",
      "High": "7.842",
      "Low": "7.778",
      "Change %": "-1.17%"
  },
  {
      "Date": "11-Jul-18",
      "Price": "7.872",
      "Open": "7.897",
      "High": "7.914",
      "Low": "7.864",
      "Change %": "-0.35%"
  },
  {
      "Date": "10-Jul-18",
      "Price": "7.9",
      "Open": "7.907",
      "High": "7.912",
      "Low": "7.893",
      "Change %": "0.13%"
  },
  {
      "Date": "9-Jul-18",
      "Price": "7.89",
      "Open": "7.871",
      "High": "7.892",
      "Low": "7.866",
      "Change %": "0.25%"
  },
  {
      "Date": "6-Jul-18",
      "Price": "7.87",
      "Open": "7.89",
      "High": "7.901",
      "Low": "7.869",
      "Change %": "-0.34%"
  },
  {
      "Date": "5-Jul-18",
      "Price": "7.897",
      "Open": "7.849",
      "High": "7.9",
      "Low": "7.841",
      "Change %": "0.57%"
  },
  {
      "Date": "4-Jul-18",
      "Price": "7.852",
      "Open": "7.878",
      "High": "7.916",
      "Low": "7.836",
      "Change %": "-0.39%"
  },
  {
      "Date": "3-Jul-18",
      "Price": "7.883",
      "Open": "7.905",
      "High": "7.906",
      "Low": "7.877",
      "Change %": "-0.37%"
  },
  {
      "Date": "2-Jul-18",
      "Price": "7.912",
      "Open": "7.889",
      "High": "7.917",
      "Low": "7.867",
      "Change %": "0.11%"
  },
  {
      "Date": "29-Jun-18",
      "Price": "7.903",
      "Open": "7.922",
      "High": "7.922",
      "Low": "7.869",
      "Change %": "-0.40%"
  },
  {
      "Date": "28-Jun-18",
      "Price": "7.935",
      "Open": "7.904",
      "High": "7.942",
      "Low": "7.9",
      "Change %": "0.81%"
  },
  {
      "Date": "27-Jun-18",
      "Price": "7.871",
      "Open": "7.87",
      "High": "7.878",
      "Low": "7.857",
      "Change %": "0.54%"
  },
  {
      "Date": "26-Jun-18",
      "Price": "7.829",
      "Open": "7.839",
      "High": "7.849",
      "Low": "7.807",
      "Change %": "0.06%"
  },
  {
      "Date": "25-Jun-18",
      "Price": "7.824",
      "Open": "7.814",
      "High": "7.859",
      "Low": "7.805",
      "Change %": "0.06%"
  },
  {
      "Date": "22-Jun-18",
      "Price": "7.819",
      "Open": "7.789",
      "High": "7.841",
      "Low": "7.771",
      "Change %": "0.60%"
  },
  {
      "Date": "21-Jun-18",
      "Price": "7.772",
      "Open": "7.798",
      "High": "7.812",
      "Low": "7.769",
      "Change %": "-0.70%"
  },
  {
      "Date": "20-Jun-18",
      "Price": "7.827",
      "Open": "7.805",
      "High": "7.85",
      "Low": "7.804",
      "Change %": "-0.46%"
  },
  {
      "Date": "19-Jun-18",
      "Price": "7.863",
      "Open": "7.921",
      "High": "7.921",
      "Low": "7.855",
      "Change %": "-0.18%"
  },
  {
      "Date": "18-Jun-18",
      "Price": "7.877",
      "Open": "7.832",
      "High": "7.882",
      "Low": "7.818",
      "Change %": "-0.15%"
  },
  {
      "Date": "15-Jun-18",
      "Price": "7.889",
      "Open": "7.93",
      "High": "7.943",
      "Low": "7.88",
      "Change %": "-0.70%"
  },
  {
      "Date": "14-Jun-18",
      "Price": "7.945",
      "Open": "7.946",
      "High": "7.955",
      "Low": "7.9",
      "Change %": "0.19%"
  },
  {
      "Date": "13-Jun-18",
      "Price": "7.93",
      "Open": "7.966",
      "High": "7.968",
      "Low": "7.919",
      "Change %": "-0.45%"
  },
  {
      "Date": "12-Jun-18",
      "Price": "7.966",
      "Open": "7.972",
      "High": "7.992",
      "Low": "7.965",
      "Change %": "0.04%"
  },
  {
      "Date": "11-Jun-18",
      "Price": "7.963",
      "Open": "7.977",
      "High": "8",
      "Low": "7.946",
      "Change %": "0.20%"
  },
  {
      "Date": "8-Jun-18",
      "Price": "7.947",
      "Open": "7.997",
      "High": "7.997",
      "Low": "7.918",
      "Change %": "-0.58%"
  },
  {
      "Date": "7-Jun-18",
      "Price": "7.993",
      "Open": "7.939",
      "High": "7.993",
      "Low": "7.937",
      "Change %": "0.96%"
  },
  {
      "Date": "6-Jun-18",
      "Price": "7.917",
      "Open": "7.852",
      "High": "7.934",
      "Low": "7.827",
      "Change %": "1.06%"
  },
  {
      "Date": "5-Jun-18",
      "Price": "7.834",
      "Open": "7.86",
      "High": "7.874",
      "Low": "7.823",
      "Change %": "-0.53%"
  },
  {
      "Date": "4-Jun-18",
      "Price": "7.876",
      "Open": "7.868",
      "High": "7.902",
      "Low": "7.852",
      "Change %": "0.36%"
  },
  {
      "Date": "1-Jun-18",
      "Price": "7.848",
      "Open": "7.86",
      "High": "7.877",
      "Low": "7.848",
      "Change %": "0.28%"
  },
  {
      "Date": "31-May-18",
      "Price": "7.826",
      "Open": "7.823",
      "High": "7.851",
      "Low": "7.816",
      "Change %": "0.57%"
  },
  {
      "Date": "30-May-18",
      "Price": "7.782",
      "Open": "7.742",
      "High": "7.785",
      "Low": "7.721",
      "Change %": "0.34%"
  },
  {
      "Date": "29-May-18",
      "Price": "7.756",
      "Open": "7.761",
      "High": "7.792",
      "Low": "7.753",
      "Change %": "0.22%"
  },
  {
      "Date": "28-May-18",
      "Price": "7.739",
      "Open": "7.733",
      "High": "7.774",
      "Low": "7.721",
      "Change %": "-0.71%"
  },
  {
      "Date": "25-May-18",
      "Price": "7.794",
      "Open": "7.864",
      "High": "7.888",
      "Low": "7.793",
      "Change %": "-0.98%"
  },
  {
      "Date": "24-May-18",
      "Price": "7.871",
      "Open": "7.845",
      "High": "7.884",
      "Low": "7.838",
      "Change %": "0.28%"
  },
  {
      "Date": "23-May-18",
      "Price": "7.849",
      "Open": "7.835",
      "High": "7.868",
      "Low": "7.827",
      "Change %": "0.51%"
  },
  {
      "Date": "22-May-18",
      "Price": "7.809",
      "Open": "7.818",
      "High": "7.847",
      "Low": "7.787",
      "Change %": "0.00%"
  },
  {
      "Date": "21-May-18",
      "Price": "7.809",
      "Open": "7.901",
      "High": "7.901",
      "Low": "7.802",
      "Change %": "-0.33%"
  },
  {
      "Date": "20-May-18",
      "Price": "7.835",
      "Open": "7.835",
      "High": "7.835",
      "Low": "7.835",
      "Change %": "0.00%"
  },
  {
      "Date": "18-May-18",
      "Price": "7.835",
      "Open": "7.903",
      "High": "7.927",
      "Low": "7.76",
      "Change %": "-0.57%"
  },
  {
      "Date": "17-May-18",
      "Price": "7.88",
      "Open": "7.922",
      "High": "7.944",
      "Low": "7.879",
      "Change %": "-0.28%"
  },
  {
      "Date": "16-May-18",
      "Price": "7.902",
      "Open": "7.919",
      "High": "7.945",
      "Low": "7.871",
      "Change %": "-0.01%"
  },
  {
      "Date": "15-May-18",
      "Price": "7.903",
      "Open": "7.906",
      "High": "7.913",
      "Low": "7.814",
      "Change %": "0.98%"
  },
  {
      "Date": "14-May-18",
      "Price": "7.826",
      "Open": "7.72",
      "High": "7.829",
      "Low": "7.708",
      "Change %": "1.29%"
  },
  {
      "Date": "11-May-18",
      "Price": "7.726",
      "Open": "7.711",
      "High": "7.757",
      "Low": "7.701",
      "Change %": "0.14%"
  },
  {
      "Date": "10-May-18",
      "Price": "7.715",
      "Open": "7.715",
      "High": "7.761",
      "Low": "7.715",
      "Change %": "0.08%"
  },
  {
      "Date": "9-May-18",
      "Price": "7.709",
      "Open": "7.652",
      "High": "7.721",
      "Low": "7.648",
      "Change %": "1.70%"
  },
  {
      "Date": "8-May-18",
      "Price": "7.58",
      "Open": "7.636",
      "High": "7.655",
      "Low": "7.553",
      "Change %": "-0.55%"
  },
  {
      "Date": "7-May-18",
      "Price": "7.622",
      "Open": "7.601",
      "High": "7.655",
      "Low": "7.601",
      "Change %": "-1.37%"
  },
  {
      "Date": "4-May-18",
      "Price": "7.728",
      "Open": "7.748",
      "High": "7.759",
      "Low": "7.723",
      "Change %": "-0.08%"
  },
  {
      "Date": "3-May-18",
      "Price": "7.734",
      "Open": "7.736",
      "High": "7.746",
      "Low": "7.683",
      "Change %": "0.01%"
  },
  {
      "Date": "2-May-18",
      "Price": "7.733",
      "Open": "7.731",
      "High": "7.742",
      "Low": "7.702",
      "Change %": "-0.44%"
  },
  {
      "Date": "27-Apr-18",
      "Price": "7.767",
      "Open": "7.746",
      "High": "7.773",
      "Low": "7.74",
      "Change %": "0.15%"
  },
  {
      "Date": "26-Apr-18",
      "Price": "7.755",
      "Open": "7.749",
      "High": "7.785",
      "Low": "7.723",
      "Change %": "0.22%"
  },
  {
      "Date": "25-Apr-18",
      "Price": "7.738",
      "Open": "7.714",
      "High": "7.779",
      "Low": "7.701",
      "Change %": "0.72%"
  },
  {
      "Date": "24-Apr-18",
      "Price": "7.683",
      "Open": "7.738",
      "High": "7.741",
      "Low": "7.642",
      "Change %": "-0.74%"
  },
  {
      "Date": "23-Apr-18",
      "Price": "7.74",
      "Open": "7.731",
      "High": "7.769",
      "Low": "7.672",
      "Change %": "0.29%"
  },
  {
      "Date": "20-Apr-18",
      "Price": "7.718",
      "Open": "7.785",
      "High": "7.797",
      "Low": "7.655",
      "Change %": "1.14%"
  },
  {
      "Date": "19-Apr-18",
      "Price": "7.631",
      "Open": "7.602",
      "High": "7.651",
      "Low": "7.588",
      "Change %": "1.26%"
  },
  {
      "Date": "18-Apr-18",
      "Price": "7.536",
      "Open": "7.492",
      "High": "7.536",
      "Low": "7.478",
      "Change %": "0.63%"
  },
  {
      "Date": "17-Apr-18",
      "Price": "7.489",
      "Open": "7.483",
      "High": "7.509",
      "Low": "7.471",
      "Change %": "0.04%"
  },
  {
      "Date": "16-Apr-18",
      "Price": "7.486",
      "Open": "7.404",
      "High": "7.49",
      "Low": "7.404",
      "Change %": "0.74%"
  },
  {
      "Date": "13-Apr-18",
      "Price": "7.431",
      "Open": "7.503",
      "High": "7.522",
      "Low": "7.423",
      "Change %": "-0.50%"
  },
  {
      "Date": "12-Apr-18",
      "Price": "7.468",
      "Open": "7.512",
      "High": "7.527",
      "Low": "7.443",
      "Change %": "-0.90%"
  },
  {
      "Date": "11-Apr-18",
      "Price": "7.536",
      "Open": "7.423",
      "High": "7.542",
      "Low": "7.386",
      "Change %": "2.13%"
  },
  {
      "Date": "10-Apr-18",
      "Price": "7.379",
      "Open": "7.247",
      "High": "7.379",
      "Low": "7.247",
      "Change %": "2.12%"
  },
  {
      "Date": "9-Apr-18",
      "Price": "7.226",
      "Open": "7.204",
      "High": "7.239",
      "Low": "7.153",
      "Change %": "0.71%"
  },
  {
      "Date": "6-Apr-18",
      "Price": "7.175",
      "Open": "7.146",
      "High": "7.179",
      "Low": "7.139",
      "Change %": "0.67%"
  },
  {
      "Date": "5-Apr-18",
      "Price": "7.127",
      "Open": "7.306",
      "High": "7.306",
      "Low": "7.123",
      "Change %": "-2.29%"
  },
  {
      "Date": "4-Apr-18",
      "Price": "7.294",
      "Open": "7.339",
      "High": "7.345",
      "Low": "7.291",
      "Change %": "-0.52%"
  },
  {
      "Date": "3-Apr-18",
      "Price": "7.332",
      "Open": "7.333",
      "High": "7.367",
      "Low": "7.312",
      "Change %": "-0.89%"
  },
  {
      "Date": "28-Mar-18",
      "Price": "7.398",
      "Open": "7.313",
      "High": "7.412",
      "Low": "7.286",
      "Change %": "0.89%"
  },
  {
      "Date": "27-Mar-18",
      "Price": "7.333",
      "Open": "7.371",
      "High": "7.398",
      "Low": "7.302",
      "Change %": "-3.80%"
  },
  {
      "Date": "26-Mar-18",
      "Price": "7.623",
      "Open": "7.577",
      "High": "7.63",
      "Low": "7.574",
      "Change %": "0.87%"
  },
  {
      "Date": "23-Mar-18",
      "Price": "7.557",
      "Open": "7.533",
      "High": "7.562",
      "Low": "7.521",
      "Change %": "-0.04%"
  },
  {
      "Date": "22-Mar-18",
      "Price": "7.56",
      "Open": "7.581",
      "High": "7.615",
      "Low": "7.548",
      "Change %": "-0.28%"
  },
  {
      "Date": "21-Mar-18",
      "Price": "7.581",
      "Open": "7.66",
      "High": "7.677",
      "Low": "7.579",
      "Change %": "-0.45%"
  },
  {
      "Date": "20-Mar-18",
      "Price": "7.615",
      "Open": "7.604",
      "High": "7.628",
      "Low": "7.595",
      "Change %": "0.09%"
  },
  {
      "Date": "19-Mar-18",
      "Price": "7.608",
      "Open": "7.585",
      "High": "7.613",
      "Low": "7.542",
      "Change %": "0.67%"
  },
  {
      "Date": "16-Mar-18",
      "Price": "7.557",
      "Open": "7.64",
      "High": "7.648",
      "Low": "7.557",
      "Change %": "-1.00%"
  },
  {
      "Date": "15-Mar-18",
      "Price": "7.633",
      "Open": "7.672",
      "High": "7.711",
      "Low": "7.625",
      "Change %": "-0.68%"
  },
  {
      "Date": "14-Mar-18",
      "Price": "7.685",
      "Open": "7.646",
      "High": "7.693",
      "Low": "7.643",
      "Change %": "0.42%"
  },
  {
      "Date": "13-Mar-18",
      "Price": "7.653",
      "Open": "7.607",
      "High": "7.665",
      "Low": "7.604",
      "Change %": "0.31%"
  },
  {
      "Date": "12-Mar-18",
      "Price": "7.629",
      "Open": "7.697",
      "High": "7.7",
      "Low": "7.62",
      "Change %": "-0.55%"
  },
  {
      "Date": "9-Mar-18",
      "Price": "7.671",
      "Open": "7.652",
      "High": "7.729",
      "Low": "7.634",
      "Change %": "0.12%"
  },
  {
      "Date": "8-Mar-18",
      "Price": "7.662",
      "Open": "7.679",
      "High": "7.713",
      "Low": "7.652",
      "Change %": "-0.21%"
  },
  {
      "Date": "7-Mar-18",
      "Price": "7.678",
      "Open": "7.769",
      "High": "7.769",
      "Low": "7.678",
      "Change %": "-1.25%"
  },
  {
      "Date": "6-Mar-18",
      "Price": "7.775",
      "Open": "7.8",
      "High": "7.8",
      "Low": "7.758",
      "Change %": "-0.01%"
  },
  {
      "Date": "5-Mar-18",
      "Price": "7.776",
      "Open": "7.755",
      "High": "7.783",
      "Low": "7.741",
      "Change %": "0.49%"
  },
  {
      "Date": "1-Mar-18",
      "Price": "7.738",
      "Open": "7.726",
      "High": "7.776",
      "Low": "7.696",
      "Change %": "0.16%"
  },
  {
      "Date": "28-Feb-18",
      "Price": "7.726",
      "Open": "7.696",
      "High": "7.726",
      "Low": "7.673",
      "Change %": "0.78%"
  },
  {
      "Date": "27-Feb-18",
      "Price": "7.666",
      "Open": "7.708",
      "High": "7.717",
      "Low": "7.657",
      "Change %": "-0.29%"
  },
  {
      "Date": "26-Feb-18",
      "Price": "7.688",
      "Open": "7.657",
      "High": "7.708",
      "Low": "7.657",
      "Change %": "0.27%"
  },
  {
      "Date": "23-Feb-18",
      "Price": "7.667",
      "Open": "7.76",
      "High": "7.76",
      "Low": "7.667",
      "Change %": "-1.02%"
  },
  {
      "Date": "22-Feb-18",
      "Price": "7.746",
      "Open": "7.816",
      "High": "7.816",
      "Low": "7.724",
      "Change %": "0.47%"
  },
  {
      "Date": "21-Feb-18",
      "Price": "7.71",
      "Open": "7.677",
      "High": "7.71",
      "Low": "7.657",
      "Change %": "0.55%"
  },
  {
      "Date": "20-Feb-18",
      "Price": "7.668",
      "Open": "7.606",
      "High": "7.694",
      "Low": "7.603",
      "Change %": "1.12%"
  },
  {
      "Date": "18-Feb-18",
      "Price": "7.583",
      "Open": "7.583",
      "High": "7.583",
      "Low": "7.583",
      "Change %": "0.08%"
  },
  {
      "Date": "16-Feb-18",
      "Price": "7.577",
      "Open": "7.576",
      "High": "7.597",
      "Low": "7.571",
      "Change %": "0.12%"
  },
  {
      "Date": "15-Feb-18",
      "Price": "7.568",
      "Open": "7.567",
      "High": "7.611",
      "Low": "7.547",
      "Change %": "1.03%"
  },
  {
      "Date": "14-Feb-18",
      "Price": "7.491",
      "Open": "7.453",
      "High": "7.509",
      "Low": "7.449",
      "Change %": "-0.07%"
  },
  {
      "Date": "12-Feb-18",
      "Price": "7.496",
      "Open": "7.47",
      "High": "7.525",
      "Low": "7.449",
      "Change %": "0.08%"
  },
  {
      "Date": "9-Feb-18",
      "Price": "7.49",
      "Open": "7.458",
      "High": "7.524",
      "Low": "7.452",
      "Change %": "0.32%"
  },
  {
      "Date": "8-Feb-18",
      "Price": "7.466",
      "Open": "7.506",
      "High": "7.522",
      "Low": "7.466",
      "Change %": "-0.86%"
  },
  {
      "Date": "7-Feb-18",
      "Price": "7.531",
      "Open": "7.583",
      "High": "7.62",
      "Low": "7.496",
      "Change %": "-0.49%"
  },
  {
      "Date": "6-Feb-18",
      "Price": "7.568",
      "Open": "7.562",
      "High": "7.616",
      "Low": "7.538",
      "Change %": "-0.49%"
  },
  {
      "Date": "5-Feb-18",
      "Price": "7.605",
      "Open": "7.62",
      "High": "7.62",
      "Low": "7.572",
      "Change %": "0.57%"
  },
  {
      "Date": "2-Feb-18",
      "Price": "7.562",
      "Open": "7.679",
      "High": "7.689",
      "Low": "7.509",
      "Change %": "-0.57%"
  },
  {
      "Date": "1-Feb-18",
      "Price": "7.605",
      "Open": "7.446",
      "High": "7.625",
      "Low": "7.399",
      "Change %": "2.36%"
  },
  {
      "Date": "31-Jan-18",
      "Price": "7.43",
      "Open": "7.423",
      "High": "7.439",
      "Low": "7.402",
      "Change %": "-0.05%"
  },
  {
      "Date": "30-Jan-18",
      "Price": "7.434",
      "Open": "7.44",
      "High": "7.478",
      "Low": "7.418",
      "Change %": "-0.09%"
  },
  {
      "Date": "29-Jan-18",
      "Price": "7.441",
      "Open": "7.322",
      "High": "7.444",
      "Low": "7.295",
      "Change %": "1.83%"
  },
  {
      "Date": "25-Jan-18",
      "Price": "7.307",
      "Open": "7.312",
      "High": "7.341",
      "Low": "7.299",
      "Change %": "0.43%"
  },
  {
      "Date": "24-Jan-18",
      "Price": "7.276",
      "Open": "7.265",
      "High": "7.282",
      "Low": "7.243",
      "Change %": "-1.85%"
  },
  {
      "Date": "23-Jan-18",
      "Price": "7.413",
      "Open": "7.49",
      "High": "7.49",
      "Low": "7.407",
      "Change %": "-0.66%"
  },
  {
      "Date": "22-Jan-18",
      "Price": "7.462",
      "Open": "7.498",
      "High": "7.509",
      "Low": "7.453",
      "Change %": "-0.23%"
  },
  {
      "Date": "19-Jan-18",
      "Price": "7.479",
      "Open": "7.479",
      "High": "7.486",
      "Low": "7.451",
      "Change %": "0.11%"
  },
  {
      "Date": "18-Jan-18",
      "Price": "7.471",
      "Open": "7.447",
      "High": "7.479",
      "Low": "7.436",
      "Change %": "0.76%"
  },
  {
      "Date": "17-Jan-18",
      "Price": "7.415",
      "Open": "7.547",
      "High": "7.552",
      "Low": "7.374",
      "Change %": "-1.81%"
  },
  {
      "Date": "16-Jan-18",
      "Price": "7.552",
      "Open": "7.504",
      "High": "7.587",
      "Low": "7.482",
      "Change %": "1.46%"
  },
  {
      "Date": "15-Jan-18",
      "Price": "7.443",
      "Open": "7.471",
      "High": "7.471",
      "Low": "7.438",
      "Change %": "-0.16%"
  },
  {
      "Date": "12-Jan-18",
      "Price": "7.455",
      "Open": "7.443",
      "High": "7.482",
      "Low": "7.404",
      "Change %": "0.26%"
  },
  {
      "Date": "11-Jan-18",
      "Price": "7.436",
      "Open": "7.419",
      "High": "7.456",
      "Low": "7.406",
      "Change %": "-0.07%"
  },
  {
      "Date": "10-Jan-18",
      "Price": "7.441",
      "Open": "7.411",
      "High": "7.454",
      "Low": "7.411",
      "Change %": "1.00%"
  },
  {
      "Date": "9-Jan-18",
      "Price": "7.367",
      "Open": "7.36",
      "High": "7.377",
      "Low": "7.341",
      "Change %": "0.31%"
  },
  {
      "Date": "8-Jan-18",
      "Price": "7.344",
      "Open": "7.285",
      "High": "7.348",
      "Low": "7.279",
      "Change %": "0.77%"
  },
  {
      "Date": "5-Jan-18",
      "Price": "7.288",
      "Open": "7.331",
      "High": "7.343",
      "Low": "7.281",
      "Change %": "-0.59%"
  },
  {
      "Date": "4-Jan-18",
      "Price": "7.331",
      "Open": "7.346",
      "High": "7.365",
      "Low": "7.328",
      "Change %": "0.12%"
  },
  {
      "Date": "3-Jan-18",
      "Price": "7.322",
      "Open": "7.405",
      "High": "7.423",
      "Low": "7.32",
      "Change %": "-0.84%"
  },
  {
      "Date": "2-Jan-18",
      "Price": "7.384",
      "Open": "7.341",
      "High": "7.399",
      "Low": "7.341",
      "Change %": "0.64%"
  },
  {
      "Date": "1-Jan-18",
      "Price": "7.337",
      "Open": "7.349",
      "High": "7.363",
      "Low": "7.315",
      "Change %": "0.15%"
  },
  {
      "Date": "29-Dec-17",
      "Price": "7.326",
      "Open": "7.41",
      "High": "7.41",
      "Low": "7.27",
      "Change %": "-0.95%"
  },
  {
      "Date": "28-Dec-17",
      "Price": "7.396",
      "Open": "7.303",
      "High": "7.399",
      "Low": "7.302",
      "Change %": "2.45%"
  },
  {
      "Date": "27-Dec-17",
      "Price": "7.219",
      "Open": "7.304",
      "High": "7.304",
      "Low": "7.218",
      "Change %": "-0.77%"
  },
  {
      "Date": "26-Dec-17",
      "Price": "7.275",
      "Open": "7.289",
      "High": "7.307",
      "Low": "7.261",
      "Change %": "0.06%"
  },
  {
      "Date": "22-Dec-17",
      "Price": "7.271",
      "Open": "7.21",
      "High": "7.293",
      "Low": "7.206",
      "Change %": "0.79%"
  },
  {
      "Date": "21-Dec-17",
      "Price": "7.214",
      "Open": "7.249",
      "High": "7.293",
      "Low": "7.207",
      "Change %": "-0.07%"
  },
  {
      "Date": "20-Dec-17",
      "Price": "7.219",
      "Open": "7.204",
      "High": "7.223",
      "Low": "7.195",
      "Change %": "0.57%"
  },
  {
      "Date": "19-Dec-17",
      "Price": "7.178",
      "Open": "7.198",
      "High": "7.198",
      "Low": "7.164",
      "Change %": "-0.04%"
  },
  {
      "Date": "18-Dec-17",
      "Price": "7.181",
      "Open": "7.17",
      "High": "7.223",
      "Low": "7.143",
      "Change %": "0.67%"
  },
  {
      "Date": "16-Dec-17",
      "Price": "7.133",
      "Open": "7.133",
      "High": "7.133",
      "Low": "7.133",
      "Change %": "-0.01%"
  },
  {
      "Date": "15-Dec-17",
      "Price": "7.134",
      "Open": "7.113",
      "High": "7.14",
      "Low": "7.112",
      "Change %": "0.06%"
  },
  {
      "Date": "14-Dec-17",
      "Price": "7.13",
      "Open": "7.143",
      "High": "7.169",
      "Low": "7.126",
      "Change %": "-0.60%"
  },
  {
      "Date": "13-Dec-17",
      "Price": "7.173",
      "Open": "7.246",
      "High": "7.255",
      "Low": "7.173",
      "Change %": "-0.25%"
  },
  {
      "Date": "12-Dec-17",
      "Price": "7.191",
      "Open": "7.22",
      "High": "7.235",
      "Low": "7.169",
      "Change %": "0.26%"
  },
  {
      "Date": "11-Dec-17",
      "Price": "7.172",
      "Open": "7.094",
      "High": "7.177",
      "Low": "7.089",
      "Change %": "1.17%"
  },
  {
      "Date": "8-Dec-17",
      "Price": "7.089",
      "Open": "7.08",
      "High": "7.109",
      "Low": "7.073",
      "Change %": "0.50%"
  },
  {
      "Date": "7-Dec-17",
      "Price": "7.054",
      "Open": "7.015",
      "High": "7.061",
      "Low": "7.012",
      "Change %": "0.33%"
  },
  {
      "Date": "6-Dec-17",
      "Price": "7.031",
      "Open": "7.06",
      "High": "7.084",
      "Low": "7.029",
      "Change %": "-0.40%"
  },
  {
      "Date": "5-Dec-17",
      "Price": "7.059",
      "Open": "7.074",
      "High": "7.08",
      "Low": "7.054",
      "Change %": "-0.34%"
  },
  {
      "Date": "4-Dec-17",
      "Price": "7.083",
      "Open": "7.088",
      "High": "7.107",
      "Low": "7.071",
      "Change %": "0.35%"
  },
  {
      "Date": "30-Nov-17",
      "Price": "7.058",
      "Open": "7.05",
      "High": "7.071",
      "Low": "7.032",
      "Change %": "0.47%"
  },
  {
      "Date": "29-Nov-17",
      "Price": "7.025",
      "Open": "7.037",
      "High": "7.045",
      "Low": "7.017",
      "Change %": "-0.07%"
  },
  {
      "Date": "28-Nov-17",
      "Price": "7.03",
      "Open": "7.074",
      "High": "7.079",
      "Low": "7.028",
      "Change %": "-0.37%"
  },
  {
      "Date": "27-Nov-17",
      "Price": "7.056",
      "Open": "7.012",
      "High": "7.06",
      "Low": "7.012",
      "Change %": "0.77%"
  },
  {
      "Date": "24-Nov-17",
      "Price": "7.002",
      "Open": "7.009",
      "High": "7.043",
      "Low": "6.996",
      "Change %": "0.24%"
  },
  {
      "Date": "23-Nov-17",
      "Price": "6.985",
      "Open": "6.947",
      "High": "7.022",
      "Low": "6.946",
      "Change %": "0.37%"
  },
  {
      "Date": "22-Nov-17",
      "Price": "6.959",
      "Open": "6.899",
      "High": "6.979",
      "Low": "6.899",
      "Change %": "0.91%"
  },
  {
      "Date": "21-Nov-17",
      "Price": "6.896",
      "Open": "6.891",
      "High": "6.918",
      "Low": "6.886",
      "Change %": "0.10%"
  },
  {
      "Date": "20-Nov-17",
      "Price": "6.889",
      "Open": "6.979",
      "High": "6.979",
      "Low": "6.887",
      "Change %": "-2.27%"
  },
  {
      "Date": "17-Nov-17",
      "Price": "7.049",
      "Open": "6.953",
      "High": "7.059",
      "Low": "6.953",
      "Change %": "-0.18%"
  },
  {
      "Date": "16-Nov-17",
      "Price": "7.062",
      "Open": "7.023",
      "High": "7.084",
      "Low": "7.02",
      "Change %": "0.66%"
  },
  {
      "Date": "15-Nov-17",
      "Price": "7.016",
      "Open": "7.031",
      "High": "7.038",
      "Low": "7.016",
      "Change %": "-0.48%"
  },
  {
      "Date": "14-Nov-17",
      "Price": "7.05",
      "Open": "6.988",
      "High": "7.066",
      "Low": "6.982",
      "Change %": "1.12%"
  },
  {
      "Date": "13-Nov-17",
      "Price": "6.972",
      "Open": "6.976",
      "High": "6.984",
      "Low": "6.962",
      "Change %": "0.23%"
  },
  {
      "Date": "10-Nov-17",
      "Price": "6.956",
      "Open": "6.935",
      "High": "6.97",
      "Low": "6.923",
      "Change %": "0.36%"
  },
  {
      "Date": "9-Nov-17",
      "Price": "6.931",
      "Open": "6.942",
      "High": "6.944",
      "Low": "6.918",
      "Change %": "-0.12%"
  },
  {
      "Date": "8-Nov-17",
      "Price": "6.939",
      "Open": "6.925",
      "High": "6.949",
      "Low": "6.918",
      "Change %": "0.16%"
  },
  {
      "Date": "7-Nov-17",
      "Price": "6.928",
      "Open": "6.923",
      "High": "6.942",
      "Low": "6.919",
      "Change %": "0.49%"
  },
  {
      "Date": "6-Nov-17",
      "Price": "6.894",
      "Open": "6.871",
      "High": "6.901",
      "Low": "6.87",
      "Change %": "0.52%"
  },
  {
      "Date": "3-Nov-17",
      "Price": "6.858",
      "Open": "6.859",
      "High": "6.876",
      "Low": "6.847",
      "Change %": "-0.03%"
  },
  {
      "Date": "2-Nov-17",
      "Price": "6.86",
      "Open": "6.884",
      "High": "6.889",
      "Low": "6.858",
      "Change %": "-0.46%"
  },
  {
      "Date": "1-Nov-17",
      "Price": "6.892",
      "Open": "6.879",
      "High": "6.896",
      "Low": "6.872",
      "Change %": "0.44%"
  },
  {
      "Date": "31-Oct-17",
      "Price": "6.862",
      "Open": "6.87",
      "High": "6.898",
      "Low": "6.856",
      "Change %": "-0.29%"
  },
  {
      "Date": "30-Oct-17",
      "Price": "6.882",
      "Open": "6.821",
      "High": "6.891",
      "Low": "6.818",
      "Change %": "1.09%"
  },
  {
      "Date": "29-Oct-17",
      "Price": "6.808",
      "Open": "6.808",
      "High": "6.808",
      "Low": "6.808",
      "Change %": "0.01%"
  },
  {
      "Date": "27-Oct-17",
      "Price": "6.807",
      "Open": "6.814",
      "High": "6.843",
      "Low": "6.798",
      "Change %": "0.13%"
  },
  {
      "Date": "26-Oct-17",
      "Price": "6.798",
      "Open": "6.805",
      "High": "6.808",
      "Low": "6.798",
      "Change %": "-0.23%"
  },
  {
      "Date": "25-Oct-17",
      "Price": "6.814",
      "Open": "6.809",
      "High": "6.816",
      "Low": "6.795",
      "Change %": "0.47%"
  },
  {
      "Date": "24-Oct-17",
      "Price": "6.782",
      "Open": "6.798",
      "High": "6.799",
      "Low": "6.768",
      "Change %": "-0.25%"
  },
  {
      "Date": "23-Oct-17",
      "Price": "6.799",
      "Open": "6.791",
      "High": "6.806",
      "Low": "6.786",
      "Change %": "0.55%"
  },
  {
      "Date": "22-Oct-17",
      "Price": "6.762",
      "Open": "6.762",
      "High": "6.762",
      "Low": "6.762",
      "Change %": "0.03%"
  },
  {
      "Date": "18-Oct-17",
      "Price": "6.76",
      "Open": "6.764",
      "High": "6.773",
      "Low": "6.758",
      "Change %": "-0.04%"
  },
  {
      "Date": "17-Oct-17",
      "Price": "6.763",
      "Open": "6.745",
      "High": "6.77",
      "Low": "6.742",
      "Change %": "0.43%"
  },
  {
      "Date": "16-Oct-17",
      "Price": "6.734",
      "Open": "6.732",
      "High": "6.746",
      "Low": "6.73",
      "Change %": "0.00%"
  },
  {
      "Date": "13-Oct-17",
      "Price": "6.734",
      "Open": "6.723",
      "High": "6.749",
      "Low": "6.72",
      "Change %": "-0.28%"
  },
  {
      "Date": "12-Oct-17",
      "Price": "6.753",
      "Open": "6.734",
      "High": "6.756",
      "Low": "6.729",
      "Change %": "0.19%"
  },
  {
      "Date": "11-Oct-17",
      "Price": "6.74",
      "Open": "6.76",
      "High": "6.76",
      "Low": "6.73",
      "Change %": "-0.01%"
  },
  {
      "Date": "10-Oct-17",
      "Price": "6.741",
      "Open": "6.786",
      "High": "6.786",
      "Low": "6.739",
      "Change %": "-0.58%"
  },
  {
      "Date": "9-Oct-17",
      "Price": "6.78",
      "Open": "6.76",
      "High": "6.785",
      "Low": "6.752",
      "Change %": "0.34%"
  },
  {
      "Date": "6-Oct-17",
      "Price": "6.757",
      "Open": "6.729",
      "High": "6.764",
      "Low": "6.729",
      "Change %": "0.42%"
  },
  {
      "Date": "5-Oct-17",
      "Price": "6.729",
      "Open": "6.729",
      "High": "6.729",
      "Low": "6.729",
      "Change %": "0.39%"
  },
  {
      "Date": "4-Oct-17",
      "Price": "6.703",
      "Open": "6.703",
      "High": "6.703",
      "Low": "6.703",
      "Change %": "0.83%"
  },
  {
      "Date": "3-Oct-17",
      "Price": "6.648",
      "Open": "6.648",
      "High": "6.648",
      "Low": "6.648",
      "Change %": "-0.23%"
  },
  {
      "Date": "29-Sep-17",
      "Price": "6.663",
      "Open": "6.663",
      "High": "6.663",
      "Low": "6.663",
      "Change %": "0.33%"
  },
  {
      "Date": "28-Sep-17",
      "Price": "6.641",
      "Open": "6.641",
      "High": "6.641",
      "Low": "6.641",
      "Change %": "-0.38%"
  },
  {
      "Date": "27-Sep-17",
      "Price": "6.666",
      "Open": "6.666",
      "High": "6.666",
      "Low": "6.666",
      "Change %": "-0.04%"
  },
  {
      "Date": "26-Sep-17",
      "Price": "6.669",
      "Open": "6.669",
      "High": "6.669",
      "Low": "6.669",
      "Change %": "0.77%"
  },
  {
      "Date": "25-Sep-17",
      "Price": "6.618",
      "Open": "6.618",
      "High": "6.618",
      "Low": "6.618",
      "Change %": "-0.68%"
  },
  {
      "Date": "22-Sep-17",
      "Price": "6.663",
      "Open": "6.663",
      "High": "6.663",
      "Low": "6.663",
      "Change %": "-0.16%"
  },
  {
      "Date": "21-Sep-17",
      "Price": "6.674",
      "Open": "6.674",
      "High": "6.674",
      "Low": "6.674",
      "Change %": "1.47%"
  },
  {
      "Date": "20-Sep-17",
      "Price": "6.577",
      "Open": "6.577",
      "High": "6.577",
      "Low": "6.577",
      "Change %": "-0.21%"
  },
  {
      "Date": "19-Sep-17",
      "Price": "6.591",
      "Open": "6.591",
      "High": "6.591",
      "Low": "6.591",
      "Change %": "-0.29%"
  },
  {
      "Date": "18-Sep-17",
      "Price": "6.61",
      "Open": "6.61",
      "High": "6.61",
      "Low": "6.61",
      "Change %": "0.18%"
  },
  {
      "Date": "15-Sep-17",
      "Price": "6.598",
      "Open": "6.598",
      "High": "6.598",
      "Low": "6.598",
      "Change %": "0.12%"
  },
  {
      "Date": "14-Sep-17",
      "Price": "6.59",
      "Open": "6.59",
      "High": "6.59",
      "Low": "6.59",
      "Change %": "0.06%"
  },
  {
      "Date": "13-Sep-17",
      "Price": "6.586",
      "Open": "6.586",
      "High": "6.586",
      "Low": "6.586",
      "Change %": "0.44%"
  },
  {
      "Date": "12-Sep-17",
      "Price": "6.557",
      "Open": "6.557",
      "High": "6.557",
      "Low": "6.557",
      "Change %": "-0.08%"
  },
  {
      "Date": "11-Sep-17",
      "Price": "6.562",
      "Open": "6.562",
      "High": "6.562",
      "Low": "6.562",
      "Change %": "0.29%"
  },
  {
      "Date": "8-Sep-17",
      "Price": "6.543",
      "Open": "6.543",
      "High": "6.543",
      "Low": "6.543",
      "Change %": "0.43%"
  },
  {
      "Date": "7-Sep-17",
      "Price": "6.515",
      "Open": "6.515",
      "High": "6.515",
      "Low": "6.515",
      "Change %": "0.12%"
  },
  {
      "Date": "6-Sep-17",
      "Price": "6.507",
      "Open": "6.507",
      "High": "6.507",
      "Low": "6.507",
      "Change %": "0.15%"
  },
  {
      "Date": "5-Sep-17",
      "Price": "6.497",
      "Open": "6.497",
      "High": "6.497",
      "Low": "6.497",
      "Change %": "0.02%"
  },
  {
      "Date": "4-Sep-17",
      "Price": "6.496",
      "Open": "6.496",
      "High": "6.496",
      "Low": "6.496",
      "Change %": "0.20%"
  },
  {
      "Date": "1-Sep-17",
      "Price": "6.483",
      "Open": "6.483",
      "High": "6.483",
      "Low": "6.483",
      "Change %": "-0.64%"
  },
  {
      "Date": "31-Aug-17",
      "Price": "6.525",
      "Open": "6.525",
      "High": "6.525",
      "Low": "6.525",
      "Change %": "-0.17%"
  },
  {
      "Date": "30-Aug-17",
      "Price": "6.536",
      "Open": "6.536",
      "High": "6.536",
      "Low": "6.536",
      "Change %": "0.05%"
  },
  {
      "Date": "29-Aug-17",
      "Price": "6.533",
      "Open": "6.533",
      "High": "6.533",
      "Low": "6.533",
      "Change %": "-0.53%"
  },
  {
      "Date": "28-Aug-17",
      "Price": "6.568",
      "Open": "6.568",
      "High": "6.568",
      "Low": "6.568",
      "Change %": "0.46%"
  },
  {
      "Date": "24-Aug-17",
      "Price": "6.538",
      "Open": "6.538",
      "High": "6.538",
      "Low": "6.538",
      "Change %": "0.05%"
  },
  {
      "Date": "23-Aug-17",
      "Price": "6.535",
      "Open": "6.535",
      "High": "6.535",
      "Low": "6.535",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Aug-17",
      "Price": "6.535",
      "Open": "6.535",
      "High": "6.535",
      "Low": "6.535",
      "Change %": "0.38%"
  },
  {
      "Date": "21-Aug-17",
      "Price": "6.51",
      "Open": "6.51",
      "High": "6.51",
      "Low": "6.51",
      "Change %": "0.02%"
  },
  {
      "Date": "18-Aug-17",
      "Price": "6.509",
      "Open": "6.509",
      "High": "6.509",
      "Low": "6.509",
      "Change %": "-0.37%"
  },
  {
      "Date": "16-Aug-17",
      "Price": "6.533",
      "Open": "6.533",
      "High": "6.533",
      "Low": "6.533",
      "Change %": "0.20%"
  },
  {
      "Date": "14-Aug-17",
      "Price": "6.52",
      "Open": "6.52",
      "High": "6.52",
      "Low": "6.52",
      "Change %": "0.28%"
  },
  {
      "Date": "11-Aug-17",
      "Price": "6.502",
      "Open": "6.502",
      "High": "6.502",
      "Low": "6.502",
      "Change %": "0.12%"
  },
  {
      "Date": "10-Aug-17",
      "Price": "6.494",
      "Open": "6.494",
      "High": "6.494",
      "Low": "6.494",
      "Change %": "0.45%"
  },
  {
      "Date": "9-Aug-17",
      "Price": "6.465",
      "Open": "6.465",
      "High": "6.465",
      "Low": "6.465",
      "Change %": "0.12%"
  },
  {
      "Date": "8-Aug-17",
      "Price": "6.457",
      "Open": "6.457",
      "High": "6.457",
      "Low": "6.457",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Aug-17",
      "Price": "6.457",
      "Open": "6.457",
      "High": "6.457",
      "Low": "6.457",
      "Change %": "0.25%"
  },
  {
      "Date": "6-Aug-17",
      "Price": "6.441",
      "Open": "6.441",
      "High": "6.441",
      "Low": "6.441",
      "Change %": "0.05%"
  },
  {
      "Date": "4-Aug-17",
      "Price": "6.438",
      "Open": "6.438",
      "High": "6.438",
      "Low": "6.438",
      "Change %": "0.14%"
  },
  {
      "Date": "3-Aug-17",
      "Price": "6.429",
      "Open": "6.429",
      "High": "6.429",
      "Low": "6.429",
      "Change %": "-0.48%"
  },
  {
      "Date": "2-Aug-17",
      "Price": "6.46",
      "Open": "6.46",
      "High": "6.46",
      "Low": "6.46",
      "Change %": "0.31%"
  },
  {
      "Date": "1-Aug-17",
      "Price": "6.44",
      "Open": "6.44",
      "High": "6.44",
      "Low": "6.44",
      "Change %": "-0.39%"
  },
  {
      "Date": "31-Jul-17",
      "Price": "6.465",
      "Open": "6.465",
      "High": "6.465",
      "Low": "6.465",
      "Change %": "0.05%"
  },
  {
      "Date": "28-Jul-17",
      "Price": "6.462",
      "Open": "6.462",
      "High": "6.462",
      "Low": "6.462",
      "Change %": "0.34%"
  },
  {
      "Date": "27-Jul-17",
      "Price": "6.44",
      "Open": "6.44",
      "High": "6.44",
      "Low": "6.44",
      "Change %": "-0.09%"
  },
  {
      "Date": "26-Jul-17",
      "Price": "6.446",
      "Open": "6.446",
      "High": "6.446",
      "Low": "6.446",
      "Change %": "0.23%"
  },
  {
      "Date": "25-Jul-17",
      "Price": "6.431",
      "Open": "6.431",
      "High": "6.431",
      "Low": "6.431",
      "Change %": "0.27%"
  },
  {
      "Date": "24-Jul-17",
      "Price": "6.414",
      "Open": "6.414",
      "High": "6.414",
      "Low": "6.414",
      "Change %": "-0.34%"
  },
  {
      "Date": "21-Jul-17",
      "Price": "6.436",
      "Open": "6.436",
      "High": "6.436",
      "Low": "6.436",
      "Change %": "-0.26%"
  },
  {
      "Date": "20-Jul-17",
      "Price": "6.453",
      "Open": "6.453",
      "High": "6.453",
      "Low": "6.453",
      "Change %": "0.06%"
  },
  {
      "Date": "19-Jul-17",
      "Price": "6.449",
      "Open": "6.449",
      "High": "6.449",
      "Low": "6.449",
      "Change %": "-0.09%"
  },
  {
      "Date": "18-Jul-17",
      "Price": "6.455",
      "Open": "6.455",
      "High": "6.455",
      "Low": "6.455",
      "Change %": "-0.02%"
  },
  {
      "Date": "17-Jul-17",
      "Price": "6.456",
      "Open": "6.456",
      "High": "6.456",
      "Low": "6.456",
      "Change %": "-0.11%"
  },
  {
      "Date": "16-Jul-17",
      "Price": "6.463",
      "Open": "6.463",
      "High": "6.463",
      "Low": "6.463",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Jul-17",
      "Price": "6.463",
      "Open": "6.463",
      "High": "6.463",
      "Low": "6.463",
      "Change %": "0.11%"
  },
  {
      "Date": "13-Jul-17",
      "Price": "6.456",
      "Open": "6.456",
      "High": "6.456",
      "Low": "6.456",
      "Change %": "-0.05%"
  },
  {
      "Date": "12-Jul-17",
      "Price": "6.459",
      "Open": "6.459",
      "High": "6.459",
      "Low": "6.459",
      "Change %": "-0.39%"
  },
  {
      "Date": "11-Jul-17",
      "Price": "6.484",
      "Open": "6.484",
      "High": "6.484",
      "Low": "6.484",
      "Change %": "0.25%"
  },
  {
      "Date": "10-Jul-17",
      "Price": "6.468",
      "Open": "6.468",
      "High": "6.468",
      "Low": "6.468",
      "Change %": "-0.93%"
  },
  {
      "Date": "7-Jul-17",
      "Price": "6.529",
      "Open": "6.529",
      "High": "6.529",
      "Low": "6.529",
      "Change %": "-0.11%"
  },
  {
      "Date": "6-Jul-17",
      "Price": "6.536",
      "Open": "6.536",
      "High": "6.536",
      "Low": "6.536",
      "Change %": "-0.21%"
  },
  {
      "Date": "5-Jul-17",
      "Price": "6.55",
      "Open": "6.55",
      "High": "6.55",
      "Low": "6.55",
      "Change %": "0.03%"
  },
  {
      "Date": "4-Jul-17",
      "Price": "6.548",
      "Open": "6.548",
      "High": "6.548",
      "Low": "6.548",
      "Change %": "-0.06%"
  },
  {
      "Date": "3-Jul-17",
      "Price": "6.552",
      "Open": "6.552",
      "High": "6.552",
      "Low": "6.552",
      "Change %": "0.63%"
  },
  {
      "Date": "2-Jul-17",
      "Price": "6.511",
      "Open": "6.511",
      "High": "6.511",
      "Low": "6.511",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Jul-17",
      "Price": "6.511",
      "Open": "6.511",
      "High": "6.511",
      "Low": "6.511",
      "Change %": "0.00%"
  },
  {
      "Date": "30-Jun-17",
      "Price": "6.511",
      "Open": "6.511",
      "High": "6.511",
      "Low": "6.511",
      "Change %": "0.06%"
  },
  {
      "Date": "29-Jun-17",
      "Price": "6.507",
      "Open": "6.507",
      "High": "6.507",
      "Low": "6.507",
      "Change %": "0.15%"
  },
  {
      "Date": "28-Jun-17",
      "Price": "6.497",
      "Open": "6.497",
      "High": "6.497",
      "Low": "6.497",
      "Change %": "0.53%"
  },
  {
      "Date": "27-Jun-17",
      "Price": "6.463",
      "Open": "6.463",
      "High": "6.463",
      "Low": "6.463",
      "Change %": "0.05%"
  },
  {
      "Date": "25-Jun-17",
      "Price": "6.46",
      "Open": "6.46",
      "High": "6.46",
      "Low": "6.46",
      "Change %": "0.02%"
  },
  {
      "Date": "23-Jun-17",
      "Price": "6.459",
      "Open": "6.459",
      "High": "6.459",
      "Low": "6.459",
      "Change %": "0.09%"
  },
  {
      "Date": "22-Jun-17",
      "Price": "6.453",
      "Open": "6.453",
      "High": "6.453",
      "Low": "6.453",
      "Change %": "0.36%"
  },
  {
      "Date": "21-Jun-17",
      "Price": "6.43",
      "Open": "6.43",
      "High": "6.43",
      "Low": "6.43",
      "Change %": "-0.23%"
  },
  {
      "Date": "20-Jun-17",
      "Price": "6.445",
      "Open": "6.445",
      "High": "6.445",
      "Low": "6.445",
      "Change %": "-0.31%"
  },
  {
      "Date": "19-Jun-17",
      "Price": "6.465",
      "Open": "6.465",
      "High": "6.465",
      "Low": "6.465",
      "Change %": "-0.39%"
  },
  {
      "Date": "16-Jun-17",
      "Price": "6.49",
      "Open": "6.49",
      "High": "6.49",
      "Low": "6.49",
      "Change %": "0.17%"
  },
  {
      "Date": "15-Jun-17",
      "Price": "6.479",
      "Open": "6.479",
      "High": "6.479",
      "Low": "6.479",
      "Change %": "0.19%"
  },
  {
      "Date": "14-Jun-17",
      "Price": "6.467",
      "Open": "6.467",
      "High": "6.467",
      "Low": "6.467",
      "Change %": "-0.39%"
  },
  {
      "Date": "13-Jun-17",
      "Price": "6.492",
      "Open": "6.492",
      "High": "6.492",
      "Low": "6.492",
      "Change %": "-0.35%"
  },
  {
      "Date": "12-Jun-17",
      "Price": "6.515",
      "Open": "6.515",
      "High": "6.515",
      "Low": "6.515",
      "Change %": "0.22%"
  },
  {
      "Date": "9-Jun-17",
      "Price": "6.501",
      "Open": "6.501",
      "High": "6.501",
      "Low": "6.501",
      "Change %": "-0.47%"
  },
  {
      "Date": "8-Jun-17",
      "Price": "6.532",
      "Open": "6.532",
      "High": "6.532",
      "Low": "6.532",
      "Change %": "-0.46%"
  },
  {
      "Date": "7-Jun-17",
      "Price": "6.562",
      "Open": "6.562",
      "High": "6.562",
      "Low": "6.562",
      "Change %": "-1.10%"
  },
  {
      "Date": "6-Jun-17",
      "Price": "6.635",
      "Open": "6.635",
      "High": "6.635",
      "Low": "6.635",
      "Change %": "-0.17%"
  },
  {
      "Date": "5-Jun-17",
      "Price": "6.646",
      "Open": "6.646",
      "High": "6.646",
      "Low": "6.646",
      "Change %": "0.33%"
  },
  {
      "Date": "2-Jun-17",
      "Price": "6.624",
      "Open": "6.624",
      "High": "6.624",
      "Low": "6.624",
      "Change %": "0.02%"
  },
  {
      "Date": "1-Jun-17",
      "Price": "6.623",
      "Open": "6.623",
      "High": "6.623",
      "Low": "6.623",
      "Change %": "-0.57%"
  },
  {
      "Date": "31-May-17",
      "Price": "6.661",
      "Open": "6.661",
      "High": "6.661",
      "Low": "6.661",
      "Change %": "0.00%"
  },
  {
      "Date": "30-May-17",
      "Price": "6.661",
      "Open": "6.661",
      "High": "6.661",
      "Low": "6.661",
      "Change %": "-0.10%"
  },
  {
      "Date": "29-May-17",
      "Price": "6.668",
      "Open": "6.668",
      "High": "6.668",
      "Low": "6.668",
      "Change %": "-1.75%"
  },
  {
      "Date": "26-May-17",
      "Price": "6.787",
      "Open": "6.787",
      "High": "6.787",
      "Low": "6.787",
      "Change %": "-0.06%"
  },
  {
      "Date": "25-May-17",
      "Price": "6.791",
      "Open": "6.791",
      "High": "6.791",
      "Low": "6.791",
      "Change %": "-0.09%"
  },
  {
      "Date": "24-May-17",
      "Price": "6.797",
      "Open": "6.797",
      "High": "6.797",
      "Low": "6.797",
      "Change %": "0.12%"
  },
  {
      "Date": "23-May-17",
      "Price": "6.789",
      "Open": "6.789",
      "High": "6.789",
      "Low": "6.789",
      "Change %": "0.33%"
  },
  {
      "Date": "22-May-17",
      "Price": "6.767",
      "Open": "6.767",
      "High": "6.767",
      "Low": "6.767",
      "Change %": "-1.27%"
  },
  {
      "Date": "19-May-17",
      "Price": "6.854",
      "Open": "6.854",
      "High": "6.854",
      "Low": "6.854",
      "Change %": "0.19%"
  },
  {
      "Date": "18-May-17",
      "Price": "6.841",
      "Open": "6.841",
      "High": "6.841",
      "Low": "6.841",
      "Change %": "-0.03%"
  },
  {
      "Date": "17-May-17",
      "Price": "6.843",
      "Open": "6.843",
      "High": "6.843",
      "Low": "6.843",
      "Change %": "0.03%"
  },
  {
      "Date": "16-May-17",
      "Price": "6.841",
      "Open": "6.841",
      "High": "6.841",
      "Low": "6.841",
      "Change %": "0.51%"
  },
  {
      "Date": "15-May-17",
      "Price": "6.806",
      "Open": "6.806",
      "High": "6.806",
      "Low": "6.806",
      "Change %": "-1.43%"
  },
  {
      "Date": "12-May-17",
      "Price": "6.905",
      "Open": "6.905",
      "High": "6.905",
      "Low": "6.905",
      "Change %": "-0.52%"
  },
  {
      "Date": "11-May-17",
      "Price": "6.941",
      "Open": "6.941",
      "High": "6.941",
      "Low": "6.941",
      "Change %": "0.01%"
  },
  {
      "Date": "9-May-17",
      "Price": "6.94",
      "Open": "6.94",
      "High": "6.94",
      "Low": "6.94",
      "Change %": "0.17%"
  },
  {
      "Date": "8-May-17",
      "Price": "6.928",
      "Open": "6.928",
      "High": "6.928",
      "Low": "6.928",
      "Change %": "-0.22%"
  },
  {
      "Date": "7-May-17",
      "Price": "6.943",
      "Open": "6.943",
      "High": "6.943",
      "Low": "6.943",
      "Change %": "0.01%"
  },
  {
      "Date": "5-May-17",
      "Price": "6.942",
      "Open": "6.942",
      "High": "6.942",
      "Low": "6.942",
      "Change %": "-0.40%"
  },
  {
      "Date": "4-May-17",
      "Price": "6.97",
      "Open": "6.97",
      "High": "6.97",
      "Low": "6.97",
      "Change %": "0.22%"
  },
  {
      "Date": "3-May-17",
      "Price": "6.955",
      "Open": "6.955",
      "High": "6.955",
      "Low": "6.955",
      "Change %": "-0.53%"
  },
  {
      "Date": "2-May-17",
      "Price": "6.992",
      "Open": "6.992",
      "High": "6.992",
      "Low": "6.992",
      "Change %": "0.45%"
  },
  {
      "Date": "28-Apr-17",
      "Price": "6.961",
      "Open": "6.961",
      "High": "6.961",
      "Low": "6.961",
      "Change %": "0.26%"
  },
  {
      "Date": "27-Apr-17",
      "Price": "6.943",
      "Open": "6.943",
      "High": "6.943",
      "Low": "6.943",
      "Change %": "-0.20%"
  },
  {
      "Date": "26-Apr-17",
      "Price": "6.957",
      "Open": "6.957",
      "High": "6.957",
      "Low": "6.957",
      "Change %": "0.33%"
  },
  {
      "Date": "25-Apr-17",
      "Price": "6.934",
      "Open": "6.934",
      "High": "6.934",
      "Low": "6.934",
      "Change %": "-0.13%"
  },
  {
      "Date": "24-Apr-17",
      "Price": "6.943",
      "Open": "6.943",
      "High": "6.943",
      "Low": "6.943",
      "Change %": "0.29%"
  },
  {
      "Date": "22-Apr-17",
      "Price": "6.923",
      "Open": "6.923",
      "High": "6.923",
      "Low": "6.923",
      "Change %": "0.07%"
  },
  {
      "Date": "21-Apr-17",
      "Price": "6.918",
      "Open": "6.918",
      "High": "6.918",
      "Low": "6.918",
      "Change %": "0.58%"
  },
  {
      "Date": "20-Apr-17",
      "Price": "6.878",
      "Open": "6.878",
      "High": "6.878",
      "Low": "6.878",
      "Change %": "0.36%"
  },
  {
      "Date": "19-Apr-17",
      "Price": "6.853",
      "Open": "6.853",
      "High": "6.853",
      "Low": "6.853",
      "Change %": "-0.15%"
  },
  {
      "Date": "18-Apr-17",
      "Price": "6.863",
      "Open": "6.863",
      "High": "6.863",
      "Low": "6.863",
      "Change %": "0.23%"
  },
  {
      "Date": "17-Apr-17",
      "Price": "6.847",
      "Open": "6.847",
      "High": "6.847",
      "Low": "6.847",
      "Change %": "0.38%"
  },
  {
      "Date": "13-Apr-17",
      "Price": "6.821",
      "Open": "6.821",
      "High": "6.821",
      "Low": "6.821",
      "Change %": "0.62%"
  },
  {
      "Date": "12-Apr-17",
      "Price": "6.779",
      "Open": "6.779",
      "High": "6.779",
      "Low": "6.779",
      "Change %": "-0.47%"
  },
  {
      "Date": "11-Apr-17",
      "Price": "6.811",
      "Open": "6.811",
      "High": "6.811",
      "Low": "6.811",
      "Change %": "-0.79%"
  },
  {
      "Date": "10-Apr-17",
      "Price": "6.865",
      "Open": "6.865",
      "High": "6.865",
      "Low": "6.865",
      "Change %": "0.67%"
  },
  {
      "Date": "7-Apr-17",
      "Price": "6.819",
      "Open": "6.819",
      "High": "6.819",
      "Low": "6.819",
      "Change %": "0.75%"
  },
  {
      "Date": "6-Apr-17",
      "Price": "6.768",
      "Open": "6.768",
      "High": "6.768",
      "Low": "6.768",
      "Change %": "1.76%"
  },
  {
      "Date": "5-Apr-17",
      "Price": "6.651",
      "Open": "6.651",
      "High": "6.651",
      "Low": "6.651",
      "Change %": "0.05%"
  },
  {
      "Date": "3-Apr-17",
      "Price": "6.648",
      "Open": "6.648",
      "High": "6.648",
      "Low": "6.648",
      "Change %": "-0.15%"
  },
  {
      "Date": "31-Mar-17",
      "Price": "6.658",
      "Open": "6.658",
      "High": "6.658",
      "Low": "6.658",
      "Change %": "-0.43%"
  },
  {
      "Date": "30-Mar-17",
      "Price": "6.687",
      "Open": "6.687",
      "High": "6.687",
      "Low": "6.687",
      "Change %": "-0.92%"
  },
  {
      "Date": "29-Mar-17",
      "Price": "6.749",
      "Open": "6.749",
      "High": "6.749",
      "Low": "6.749",
      "Change %": "0.61%"
  },
  {
      "Date": "27-Mar-17",
      "Price": "6.708",
      "Open": "6.708",
      "High": "6.708",
      "Low": "6.708",
      "Change %": "-1.80%"
  },
  {
      "Date": "26-Mar-17",
      "Price": "6.831",
      "Open": "6.831",
      "High": "6.831",
      "Low": "6.831",
      "Change %": "0.04%"
  },
  {
      "Date": "24-Mar-17",
      "Price": "6.828",
      "Open": "6.828",
      "High": "6.828",
      "Low": "6.828",
      "Change %": "-0.03%"
  },
  {
      "Date": "23-Mar-17",
      "Price": "6.83",
      "Open": "6.83",
      "High": "6.83",
      "Low": "6.83",
      "Change %": "0.28%"
  },
  {
      "Date": "22-Mar-17",
      "Price": "6.811",
      "Open": "6.811",
      "High": "6.811",
      "Low": "6.811",
      "Change %": "-1.09%"
  },
  {
      "Date": "21-Mar-17",
      "Price": "6.886",
      "Open": "6.886",
      "High": "6.886",
      "Low": "6.886",
      "Change %": "-0.13%"
  },
  {
      "Date": "20-Mar-17",
      "Price": "6.895",
      "Open": "6.895",
      "High": "6.895",
      "Low": "6.895",
      "Change %": "0.52%"
  },
  {
      "Date": "18-Mar-17",
      "Price": "6.859",
      "Open": "6.859",
      "High": "6.859",
      "Low": "6.859",
      "Change %": "-0.01%"
  },
  {
      "Date": "17-Mar-17",
      "Price": "6.86",
      "Open": "6.86",
      "High": "6.86",
      "Low": "6.86",
      "Change %": "0.26%"
  },
  {
      "Date": "16-Mar-17",
      "Price": "6.842",
      "Open": "6.842",
      "High": "6.842",
      "Low": "6.842",
      "Change %": "0.23%"
  },
  {
      "Date": "15-Mar-17",
      "Price": "6.826",
      "Open": "6.826",
      "High": "6.826",
      "Low": "6.826",
      "Change %": "-1.07%"
  },
  {
      "Date": "14-Mar-17",
      "Price": "6.9",
      "Open": "6.9",
      "High": "6.9",
      "Low": "6.9",
      "Change %": "-0.06%"
  },
  {
      "Date": "10-Mar-17",
      "Price": "6.904",
      "Open": "6.904",
      "High": "6.904",
      "Low": "6.904",
      "Change %": "0.73%"
  },
  {
      "Date": "9-Mar-17",
      "Price": "6.854",
      "Open": "6.854",
      "High": "6.854",
      "Low": "6.854",
      "Change %": "-0.15%"
  },
  {
      "Date": "8-Mar-17",
      "Price": "6.864",
      "Open": "6.864",
      "High": "6.864",
      "Low": "6.864",
      "Change %": "0.47%"
  },
  {
      "Date": "7-Mar-17",
      "Price": "6.832",
      "Open": "6.832",
      "High": "6.832",
      "Low": "6.832",
      "Change %": "-0.51%"
  },
  {
      "Date": "6-Mar-17",
      "Price": "6.867",
      "Open": "6.867",
      "High": "6.867",
      "Low": "6.867",
      "Change %": "1.42%"
  },
  {
      "Date": "3-Mar-17",
      "Price": "6.771",
      "Open": "6.771",
      "High": "6.771",
      "Low": "6.771",
      "Change %": "-0.88%"
  },
  {
      "Date": "2-Mar-17",
      "Price": "6.831",
      "Open": "6.831",
      "High": "6.831",
      "Low": "6.831",
      "Change %": "-1.40%"
  },
  {
      "Date": "1-Mar-17",
      "Price": "6.928",
      "Open": "6.928",
      "High": "6.928",
      "Low": "6.928",
      "Change %": "0.84%"
  },
  {
      "Date": "28-Feb-17",
      "Price": "6.87",
      "Open": "6.87",
      "High": "6.87",
      "Low": "6.87",
      "Change %": "-0.15%"
  },
  {
      "Date": "27-Feb-17",
      "Price": "6.88",
      "Open": "6.88",
      "High": "6.88",
      "Low": "6.88",
      "Change %": "-0.51%"
  },
  {
      "Date": "23-Feb-17",
      "Price": "6.915",
      "Open": "6.915",
      "High": "6.915",
      "Low": "6.915",
      "Change %": "-0.29%"
  },
  {
      "Date": "22-Feb-17",
      "Price": "6.935",
      "Open": "6.935",
      "High": "6.935",
      "Low": "6.935",
      "Change %": "0.49%"
  },
  {
      "Date": "20-Feb-17",
      "Price": "6.901",
      "Open": "6.901",
      "High": "6.901",
      "Low": "6.901",
      "Change %": "0.76%"
  },
  {
      "Date": "17-Feb-17",
      "Price": "6.849",
      "Open": "6.849",
      "High": "6.849",
      "Low": "6.849",
      "Change %": "0.10%"
  },
  {
      "Date": "16-Feb-17",
      "Price": "6.842",
      "Open": "6.842",
      "High": "6.842",
      "Low": "6.842",
      "Change %": "-0.29%"
  },
  {
      "Date": "15-Feb-17",
      "Price": "6.862",
      "Open": "6.862",
      "High": "6.862",
      "Low": "6.862",
      "Change %": "-0.19%"
  },
  {
      "Date": "14-Feb-17",
      "Price": "6.875",
      "Open": "6.875",
      "High": "6.875",
      "Low": "6.875",
      "Change %": "0.69%"
  },
  {
      "Date": "13-Feb-17",
      "Price": "6.828",
      "Open": "6.828",
      "High": "6.828",
      "Low": "6.828",
      "Change %": "0.34%"
  },
  {
      "Date": "10-Feb-17",
      "Price": "6.805",
      "Open": "6.805",
      "High": "6.805",
      "Low": "6.805",
      "Change %": "-0.73%"
  },
  {
      "Date": "9-Feb-17",
      "Price": "6.855",
      "Open": "6.855",
      "High": "6.855",
      "Low": "6.855",
      "Change %": "1.60%"
  },
  {
      "Date": "8-Feb-17",
      "Price": "6.747",
      "Open": "6.747",
      "High": "6.747",
      "Low": "6.747",
      "Change %": "4.91%"
  },
  {
      "Date": "7-Feb-17",
      "Price": "6.431",
      "Open": "6.431",
      "High": "6.431",
      "Low": "6.431",
      "Change %": "0.30%"
  },
  {
      "Date": "6-Feb-17",
      "Price": "6.412",
      "Open": "6.412",
      "High": "6.412",
      "Low": "6.412",
      "Change %": "0.06%"
  },
  {
      "Date": "3-Feb-17",
      "Price": "6.408",
      "Open": "6.408",
      "High": "6.408",
      "Low": "6.408",
      "Change %": "0.12%"
  },
  {
      "Date": "2-Feb-17",
      "Price": "6.4",
      "Open": "6.4",
      "High": "6.4",
      "Low": "6.4",
      "Change %": "-0.40%"
  },
  {
      "Date": "1-Feb-17",
      "Price": "6.426",
      "Open": "6.426",
      "High": "6.426",
      "Low": "6.426",
      "Change %": "0.30%"
  },
  {
      "Date": "31-Jan-17",
      "Price": "6.407",
      "Open": "6.407",
      "High": "6.407",
      "Low": "6.407",
      "Change %": "0.03%"
  },
  {
      "Date": "30-Jan-17",
      "Price": "6.405",
      "Open": "6.405",
      "High": "6.405",
      "Low": "6.405",
      "Change %": "0.11%"
  },
  {
      "Date": "27-Jan-17",
      "Price": "6.398",
      "Open": "6.398",
      "High": "6.398",
      "Low": "6.398",
      "Change %": "-0.39%"
  },
  {
      "Date": "25-Jan-17",
      "Price": "6.423",
      "Open": "6.423",
      "High": "6.423",
      "Low": "6.423",
      "Change %": "-0.22%"
  },
  {
      "Date": "24-Jan-17",
      "Price": "6.437",
      "Open": "6.437",
      "High": "6.437",
      "Low": "6.437",
      "Change %": "-0.22%"
  },
  {
      "Date": "23-Jan-17",
      "Price": "6.451",
      "Open": "6.451",
      "High": "6.451",
      "Low": "6.451",
      "Change %": "-0.17%"
  },
  {
      "Date": "20-Jan-17",
      "Price": "6.462",
      "Open": "6.462",
      "High": "6.462",
      "Low": "6.462",
      "Change %": "-0.19%"
  },
  {
      "Date": "19-Jan-17",
      "Price": "6.474",
      "Open": "6.474",
      "High": "6.474",
      "Low": "6.474",
      "Change %": "0.34%"
  },
  {
      "Date": "18-Jan-17",
      "Price": "6.452",
      "Open": "6.452",
      "High": "6.452",
      "Low": "6.452",
      "Change %": "0.51%"
  },
  {
      "Date": "17-Jan-17",
      "Price": "6.419",
      "Open": "6.419",
      "High": "6.419",
      "Low": "6.419",
      "Change %": "-0.33%"
  },
  {
      "Date": "16-Jan-17",
      "Price": "6.44",
      "Open": "6.44",
      "High": "6.44",
      "Low": "6.44",
      "Change %": "0.36%"
  },
  {
      "Date": "13-Jan-17",
      "Price": "6.417",
      "Open": "6.417",
      "High": "6.417",
      "Low": "6.417",
      "Change %": "0.71%"
  },
  {
      "Date": "12-Jan-17",
      "Price": "6.372",
      "Open": "6.372",
      "High": "6.372",
      "Low": "6.372",
      "Change %": "-0.30%"
  },
  {
      "Date": "11-Jan-17",
      "Price": "6.391",
      "Open": "6.391",
      "High": "6.391",
      "Low": "6.391",
      "Change %": "-0.11%"
  },
  {
      "Date": "10-Jan-17",
      "Price": "6.398",
      "Open": "6.398",
      "High": "6.398",
      "Low": "6.398",
      "Change %": "0.06%"
  },
  {
      "Date": "9-Jan-17",
      "Price": "6.394",
      "Open": "6.394",
      "High": "6.394",
      "Low": "6.394",
      "Change %": "0.14%"
  },
  {
      "Date": "6-Jan-17",
      "Price": "6.385",
      "Open": "6.385",
      "High": "6.385",
      "Low": "6.385",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Jan-17",
      "Price": "6.385",
      "Open": "6.385",
      "High": "6.385",
      "Low": "6.385",
      "Change %": "0.39%"
  },
  {
      "Date": "4-Jan-17",
      "Price": "6.36",
      "Open": "6.36",
      "High": "6.36",
      "Low": "6.36",
      "Change %": "-1.24%"
  },
  {
      "Date": "3-Jan-17",
      "Price": "6.44",
      "Open": "6.44",
      "High": "6.44",
      "Low": "6.44",
      "Change %": "0.59%"
  },
  {
      "Date": "2-Jan-17",
      "Price": "6.402",
      "Open": "6.402",
      "High": "6.402",
      "Low": "6.402",
      "Change %": "-1.69%"
  },
  {
      "Date": "30-Dec-16",
      "Price": "6.512",
      "Open": "6.512",
      "High": "6.512",
      "Low": "6.512",
      "Change %": "-0.17%"
  },
  {
      "Date": "29-Dec-16",
      "Price": "6.523",
      "Open": "6.523",
      "High": "6.523",
      "Low": "6.523",
      "Change %": "-0.88%"
  },
  {
      "Date": "28-Dec-16",
      "Price": "6.581",
      "Open": "6.581",
      "High": "6.581",
      "Low": "6.581",
      "Change %": "-0.09%"
  },
  {
      "Date": "27-Dec-16",
      "Price": "6.587",
      "Open": "6.587",
      "High": "6.587",
      "Low": "6.587",
      "Change %": "0.26%"
  },
  {
      "Date": "26-Dec-16",
      "Price": "6.57",
      "Open": "6.57",
      "High": "6.57",
      "Low": "6.57",
      "Change %": "0.40%"
  },
  {
      "Date": "23-Dec-16",
      "Price": "6.544",
      "Open": "6.544",
      "High": "6.544",
      "Low": "6.544",
      "Change %": "0.40%"
  },
  {
      "Date": "22-Dec-16",
      "Price": "6.518",
      "Open": "6.518",
      "High": "6.518",
      "Low": "6.518",
      "Change %": "0.90%"
  },
  {
      "Date": "21-Dec-16",
      "Price": "6.46",
      "Open": "6.46",
      "High": "6.46",
      "Low": "6.46",
      "Change %": "-0.31%"
  },
  {
      "Date": "20-Dec-16",
      "Price": "6.48",
      "Open": "6.48",
      "High": "6.48",
      "Low": "6.48",
      "Change %": "-0.46%"
  },
  {
      "Date": "19-Dec-16",
      "Price": "6.51",
      "Open": "6.51",
      "High": "6.51",
      "Low": "6.51",
      "Change %": "0.09%"
  },
  {
      "Date": "16-Dec-16",
      "Price": "6.504",
      "Open": "6.504",
      "High": "6.504",
      "Low": "6.504",
      "Change %": "-0.52%"
  },
  {
      "Date": "15-Dec-16",
      "Price": "6.538",
      "Open": "6.538",
      "High": "6.538",
      "Low": "6.538",
      "Change %": "2.09%"
  },
  {
      "Date": "14-Dec-16",
      "Price": "6.404",
      "Open": "6.404",
      "High": "6.404",
      "Low": "6.404",
      "Change %": "-0.30%"
  },
  {
      "Date": "13-Dec-16",
      "Price": "6.423",
      "Open": "6.423",
      "High": "6.423",
      "Low": "6.423",
      "Change %": "-0.22%"
  },
  {
      "Date": "9-Dec-16",
      "Price": "6.437",
      "Open": "6.437",
      "High": "6.437",
      "Low": "6.437",
      "Change %": "0.58%"
  },
  {
      "Date": "8-Dec-16",
      "Price": "6.4",
      "Open": "6.4",
      "High": "6.4",
      "Low": "6.4",
      "Change %": "-0.16%"
  },
  {
      "Date": "7-Dec-16",
      "Price": "6.41",
      "Open": "6.41",
      "High": "6.41",
      "Low": "6.41",
      "Change %": "3.37%"
  },
  {
      "Date": "6-Dec-16",
      "Price": "6.201",
      "Open": "6.201",
      "High": "6.201",
      "Low": "6.201",
      "Change %": "-0.24%"
  },
  {
      "Date": "5-Dec-16",
      "Price": "6.216",
      "Open": "6.216",
      "High": "6.216",
      "Low": "6.216",
      "Change %": "-0.40%"
  },
  {
      "Date": "2-Dec-16",
      "Price": "6.241",
      "Open": "6.241",
      "High": "6.241",
      "Low": "6.241",
      "Change %": "0.47%"
  },
  {
      "Date": "1-Dec-16",
      "Price": "6.212",
      "Open": "6.212",
      "High": "6.212",
      "Low": "6.212",
      "Change %": "-0.50%"
  },
  {
      "Date": "30-Nov-16",
      "Price": "6.243",
      "Open": "6.243",
      "High": "6.243",
      "Low": "6.243",
      "Change %": "-1.17%"
  },
  {
      "Date": "29-Nov-16",
      "Price": "6.317",
      "Open": "6.317",
      "High": "6.317",
      "Low": "6.317",
      "Change %": "-0.09%"
  },
  {
      "Date": "28-Nov-16",
      "Price": "6.323",
      "Open": "6.323",
      "High": "6.323",
      "Low": "6.323",
      "Change %": "1.51%"
  },
  {
      "Date": "25-Nov-16",
      "Price": "6.229",
      "Open": "6.229",
      "High": "6.229",
      "Low": "6.229",
      "Change %": "0.74%"
  },
  {
      "Date": "24-Nov-16",
      "Price": "6.183",
      "Open": "6.183",
      "High": "6.183",
      "Low": "6.183",
      "Change %": "-1.51%"
  },
  {
      "Date": "23-Nov-16",
      "Price": "6.278",
      "Open": "6.278",
      "High": "6.278",
      "Low": "6.278",
      "Change %": "-0.46%"
  },
  {
      "Date": "22-Nov-16",
      "Price": "6.307",
      "Open": "6.307",
      "High": "6.307",
      "Low": "6.307",
      "Change %": "0.05%"
  },
  {
      "Date": "21-Nov-16",
      "Price": "6.304",
      "Open": "6.304",
      "High": "6.304",
      "Low": "6.304",
      "Change %": "-1.93%"
  },
  {
      "Date": "18-Nov-16",
      "Price": "6.428",
      "Open": "6.428",
      "High": "6.428",
      "Low": "6.428",
      "Change %": "0.14%"
  },
  {
      "Date": "17-Nov-16",
      "Price": "6.419",
      "Open": "6.419",
      "High": "6.419",
      "Low": "6.419",
      "Change %": "-0.39%"
  },
  {
      "Date": "16-Nov-16",
      "Price": "6.444",
      "Open": "6.444",
      "High": "6.444",
      "Low": "6.444",
      "Change %": "-1.32%"
  },
  {
      "Date": "15-Nov-16",
      "Price": "6.53",
      "Open": "6.53",
      "High": "6.53",
      "Low": "6.53",
      "Change %": "-2.86%"
  },
  {
      "Date": "11-Nov-16",
      "Price": "6.722",
      "Open": "6.722",
      "High": "6.722",
      "Low": "6.722",
      "Change %": "0.98%"
  },
  {
      "Date": "10-Nov-16",
      "Price": "6.657",
      "Open": "6.657",
      "High": "6.657",
      "Low": "6.657",
      "Change %": "-0.14%"
  },
  {
      "Date": "9-Nov-16",
      "Price": "6.666",
      "Open": "6.666",
      "High": "6.666",
      "Low": "6.666",
      "Change %": "-1.91%"
  },
  {
      "Date": "8-Nov-16",
      "Price": "6.796",
      "Open": "6.796",
      "High": "6.796",
      "Low": "6.796",
      "Change %": "-0.56%"
  },
  {
      "Date": "7-Nov-16",
      "Price": "6.834",
      "Open": "6.834",
      "High": "6.834",
      "Low": "6.834",
      "Change %": "-0.06%"
  },
  {
      "Date": "4-Nov-16",
      "Price": "6.838",
      "Open": "6.838",
      "High": "6.838",
      "Low": "6.838",
      "Change %": "0.22%"
  },
  {
      "Date": "3-Nov-16",
      "Price": "6.823",
      "Open": "6.823",
      "High": "6.823",
      "Low": "6.823",
      "Change %": "-1.12%"
  },
  {
      "Date": "2-Nov-16",
      "Price": "6.9",
      "Open": "6.9",
      "High": "6.9",
      "Low": "6.9",
      "Change %": "-0.12%"
  },
  {
      "Date": "1-Nov-16",
      "Price": "6.908",
      "Open": "6.908",
      "High": "6.908",
      "Low": "6.908",
      "Change %": "0.33%"
  },
  {
      "Date": "28-Oct-16",
      "Price": "6.885",
      "Open": "6.885",
      "High": "6.885",
      "Low": "6.885",
      "Change %": "-0.04%"
  },
  {
      "Date": "27-Oct-16",
      "Price": "6.888",
      "Open": "6.888",
      "High": "6.888",
      "Low": "6.888",
      "Change %": "0.25%"
  },
  {
      "Date": "26-Oct-16",
      "Price": "6.871",
      "Open": "6.871",
      "High": "6.871",
      "Low": "6.871",
      "Change %": "0.01%"
  },
  {
      "Date": "25-Oct-16",
      "Price": "6.87",
      "Open": "6.87",
      "High": "6.87",
      "Low": "6.87",
      "Change %": "0.20%"
  },
  {
      "Date": "24-Oct-16",
      "Price": "6.856",
      "Open": "6.856",
      "High": "6.856",
      "Low": "6.856",
      "Change %": "0.12%"
  },
  {
      "Date": "21-Oct-16",
      "Price": "6.848",
      "Open": "6.848",
      "High": "6.848",
      "Low": "6.848",
      "Change %": "0.01%"
  },
  {
      "Date": "20-Oct-16",
      "Price": "6.847",
      "Open": "6.847",
      "High": "6.847",
      "Low": "6.847",
      "Change %": "0.41%"
  },
  {
      "Date": "19-Oct-16",
      "Price": "6.819",
      "Open": "6.819",
      "High": "6.819",
      "Low": "6.819",
      "Change %": "0.10%"
  },
  {
      "Date": "18-Oct-16",
      "Price": "6.812",
      "Open": "6.812",
      "High": "6.812",
      "Low": "6.812",
      "Change %": "-0.47%"
  },
  {
      "Date": "17-Oct-16",
      "Price": "6.844",
      "Open": "6.844",
      "High": "6.844",
      "Low": "6.844",
      "Change %": "-0.15%"
  },
  {
      "Date": "15-Oct-16",
      "Price": "6.854",
      "Open": "6.854",
      "High": "6.854",
      "Low": "6.854",
      "Change %": "0.03%"
  },
  {
      "Date": "14-Oct-16",
      "Price": "6.852",
      "Open": "6.852",
      "High": "6.852",
      "Low": "6.852",
      "Change %": "0.35%"
  },
  {
      "Date": "13-Oct-16",
      "Price": "6.828",
      "Open": "6.828",
      "High": "6.828",
      "Low": "6.828",
      "Change %": "0.13%"
  },
  {
      "Date": "10-Oct-16",
      "Price": "6.819",
      "Open": "6.819",
      "High": "6.819",
      "Low": "6.819",
      "Change %": "-0.42%"
  },
  {
      "Date": "7-Oct-16",
      "Price": "6.848",
      "Open": "6.848",
      "High": "6.848",
      "Low": "6.848",
      "Change %": "0.41%"
  },
  {
      "Date": "6-Oct-16",
      "Price": "6.82",
      "Open": "6.82",
      "High": "6.82",
      "Low": "6.82",
      "Change %": "0.34%"
  },
  {
      "Date": "5-Oct-16",
      "Price": "6.797",
      "Open": "6.797",
      "High": "6.797",
      "Low": "6.797",
      "Change %": "-0.59%"
  },
  {
      "Date": "4-Oct-16",
      "Price": "6.837",
      "Open": "6.837",
      "High": "6.837",
      "Low": "6.837",
      "Change %": "-0.90%"
  },
  {
      "Date": "3-Oct-16",
      "Price": "6.899",
      "Open": "6.899",
      "High": "6.899",
      "Low": "6.899",
      "Change %": "-0.85%"
  },
  {
      "Date": "30-Sep-16",
      "Price": "6.958",
      "Open": "6.958",
      "High": "6.958",
      "Low": "6.958",
      "Change %": "-0.78%"
  },
  {
      "Date": "29-Sep-16",
      "Price": "7.013",
      "Open": "7.013",
      "High": "7.013",
      "Low": "7.013",
      "Change %": "1.33%"
  },
  {
      "Date": "28-Sep-16",
      "Price": "6.921",
      "Open": "6.921",
      "High": "6.921",
      "Low": "6.921",
      "Change %": "-0.17%"
  },
  {
      "Date": "27-Sep-16",
      "Price": "6.933",
      "Open": "6.933",
      "High": "6.933",
      "Low": "6.933",
      "Change %": "-0.01%"
  },
  {
      "Date": "26-Sep-16",
      "Price": "6.934",
      "Open": "6.934",
      "High": "6.934",
      "Low": "6.934",
      "Change %": "-0.46%"
  },
  {
      "Date": "25-Sep-16",
      "Price": "6.966",
      "Open": "6.966",
      "High": "6.966",
      "Low": "6.966",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Sep-16",
      "Price": "6.966",
      "Open": "6.966",
      "High": "6.966",
      "Low": "6.966",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Sep-16",
      "Price": "6.966",
      "Open": "6.966",
      "High": "6.966",
      "Low": "6.966",
      "Change %": "-0.16%"
  },
  {
      "Date": "22-Sep-16",
      "Price": "6.977",
      "Open": "6.977",
      "High": "6.977",
      "Low": "6.977",
      "Change %": "-0.88%"
  },
  {
      "Date": "21-Sep-16",
      "Price": "7.039",
      "Open": "7.039",
      "High": "7.039",
      "Low": "7.039",
      "Change %": "-0.37%"
  },
  {
      "Date": "20-Sep-16",
      "Price": "7.065",
      "Open": "7.065",
      "High": "7.065",
      "Low": "7.065",
      "Change %": "0.18%"
  },
  {
      "Date": "19-Sep-16",
      "Price": "7.052",
      "Open": "7.052",
      "High": "7.052",
      "Low": "7.052",
      "Change %": "0.00%"
  },
  {
      "Date": "17-Sep-16",
      "Price": "7.052",
      "Open": "7.052",
      "High": "7.052",
      "Low": "7.052",
      "Change %": "0.01%"
  },
  {
      "Date": "16-Sep-16",
      "Price": "7.051",
      "Open": "7.051",
      "High": "7.051",
      "Low": "7.051",
      "Change %": "-0.23%"
  },
  {
      "Date": "15-Sep-16",
      "Price": "7.067",
      "Open": "7.067",
      "High": "7.067",
      "Low": "7.067",
      "Change %": "-0.18%"
  },
  {
      "Date": "14-Sep-16",
      "Price": "7.08",
      "Open": "7.08",
      "High": "7.08",
      "Low": "7.08",
      "Change %": "-0.04%"
  },
  {
      "Date": "12-Sep-16",
      "Price": "7.083",
      "Open": "7.083",
      "High": "7.083",
      "Low": "7.083",
      "Change %": "0.37%"
  },
  {
      "Date": "9-Sep-16",
      "Price": "7.057",
      "Open": "7.057",
      "High": "7.057",
      "Low": "7.057",
      "Change %": "0.21%"
  },
  {
      "Date": "8-Sep-16",
      "Price": "7.042",
      "Open": "7.042",
      "High": "7.042",
      "Low": "7.042",
      "Change %": "-0.18%"
  },
  {
      "Date": "7-Sep-16",
      "Price": "7.055",
      "Open": "7.055",
      "High": "7.055",
      "Low": "7.055",
      "Change %": "-0.59%"
  },
  {
      "Date": "6-Sep-16",
      "Price": "7.097",
      "Open": "7.097",
      "High": "7.097",
      "Low": "7.097",
      "Change %": "-0.31%"
  },
  {
      "Date": "2-Sep-16",
      "Price": "7.119",
      "Open": "7.119",
      "High": "7.119",
      "Low": "7.119",
      "Change %": "0.01%"
  },
  {
      "Date": "1-Sep-16",
      "Price": "7.118",
      "Open": "7.118",
      "High": "7.118",
      "Low": "7.118",
      "Change %": "0.11%"
  },
  {
      "Date": "31-Aug-16",
      "Price": "7.11",
      "Open": "7.11",
      "High": "7.11",
      "Low": "7.11",
      "Change %": "0.04%"
  },
  {
      "Date": "30-Aug-16",
      "Price": "7.107",
      "Open": "7.107",
      "High": "7.107",
      "Low": "7.107",
      "Change %": "-0.20%"
  },
  {
      "Date": "29-Aug-16",
      "Price": "7.121",
      "Open": "7.121",
      "High": "7.121",
      "Low": "7.121",
      "Change %": "-0.08%"
  },
  {
      "Date": "26-Aug-16",
      "Price": "7.127",
      "Open": "7.127",
      "High": "7.127",
      "Low": "7.127",
      "Change %": "0.07%"
  },
  {
      "Date": "25-Aug-16",
      "Price": "7.122",
      "Open": "7.122",
      "High": "7.122",
      "Low": "7.122",
      "Change %": "-0.17%"
  },
  {
      "Date": "24-Aug-16",
      "Price": "7.134",
      "Open": "7.134",
      "High": "7.134",
      "Low": "7.134",
      "Change %": "-0.34%"
  },
  {
      "Date": "23-Aug-16",
      "Price": "7.158",
      "Open": "7.158",
      "High": "7.158",
      "Low": "7.158",
      "Change %": "0.03%"
  },
  {
      "Date": "22-Aug-16",
      "Price": "7.156",
      "Open": "7.156",
      "High": "7.156",
      "Low": "7.156",
      "Change %": "0.80%"
  },
  {
      "Date": "19-Aug-16",
      "Price": "7.099",
      "Open": "7.099",
      "High": "7.099",
      "Low": "7.099",
      "Change %": "-0.57%"
  },
  {
      "Date": "18-Aug-16",
      "Price": "7.14",
      "Open": "7.14",
      "High": "7.14",
      "Low": "7.14",
      "Change %": "0.51%"
  },
  {
      "Date": "16-Aug-16",
      "Price": "7.104",
      "Open": "7.104",
      "High": "7.104",
      "Low": "7.104",
      "Change %": "0.01%"
  },
  {
      "Date": "14-Aug-16",
      "Price": "7.103",
      "Open": "7.103",
      "High": "7.103",
      "Low": "7.103",
      "Change %": "0.00%"
  },
  {
      "Date": "13-Aug-16",
      "Price": "7.103",
      "Open": "7.103",
      "High": "7.103",
      "Low": "7.103",
      "Change %": "0.03%"
  },
  {
      "Date": "12-Aug-16",
      "Price": "7.101",
      "Open": "7.101",
      "High": "7.101",
      "Low": "7.101",
      "Change %": "0.25%"
  },
  {
      "Date": "11-Aug-16",
      "Price": "7.083",
      "Open": "7.083",
      "High": "7.083",
      "Low": "7.083",
      "Change %": "-0.25%"
  },
  {
      "Date": "10-Aug-16",
      "Price": "7.101",
      "Open": "7.101",
      "High": "7.101",
      "Low": "7.101",
      "Change %": "-0.32%"
  },
  {
      "Date": "9-Aug-16",
      "Price": "7.124",
      "Open": "7.124",
      "High": "7.124",
      "Low": "7.124",
      "Change %": "-0.68%"
  },
  {
      "Date": "8-Aug-16",
      "Price": "7.173",
      "Open": "7.173",
      "High": "7.173",
      "Low": "7.173",
      "Change %": "0.07%"
  },
  {
      "Date": "6-Aug-16",
      "Price": "7.168",
      "Open": "7.168",
      "High": "7.168",
      "Low": "7.168",
      "Change %": "0.03%"
  },
  {
      "Date": "5-Aug-16",
      "Price": "7.166",
      "Open": "7.166",
      "High": "7.166",
      "Low": "7.166",
      "Change %": "-0.04%"
  },
  {
      "Date": "4-Aug-16",
      "Price": "7.169",
      "Open": "7.169",
      "High": "7.169",
      "Low": "7.169",
      "Change %": "-0.35%"
  },
  {
      "Date": "3-Aug-16",
      "Price": "7.194",
      "Open": "7.194",
      "High": "7.194",
      "Low": "7.194",
      "Change %": "0.28%"
  },
  {
      "Date": "2-Aug-16",
      "Price": "7.174",
      "Open": "7.174",
      "High": "7.174",
      "Low": "7.174",
      "Change %": "0.49%"
  },
  {
      "Date": "1-Aug-16",
      "Price": "7.139",
      "Open": "7.139",
      "High": "7.139",
      "Low": "7.139",
      "Change %": "-0.34%"
  },
  {
      "Date": "29-Jul-16",
      "Price": "7.163",
      "Open": "7.163",
      "High": "7.163",
      "Low": "7.163",
      "Change %": "-0.31%"
  },
  {
      "Date": "28-Jul-16",
      "Price": "7.185",
      "Open": "7.185",
      "High": "7.185",
      "Low": "7.185",
      "Change %": "-0.90%"
  },
  {
      "Date": "27-Jul-16",
      "Price": "7.25",
      "Open": "7.25",
      "High": "7.25",
      "Low": "7.25",
      "Change %": "-0.03%"
  },
  {
      "Date": "26-Jul-16",
      "Price": "7.252",
      "Open": "7.252",
      "High": "7.252",
      "Low": "7.252",
      "Change %": "0.06%"
  },
  {
      "Date": "25-Jul-16",
      "Price": "7.248",
      "Open": "7.248",
      "High": "7.248",
      "Low": "7.248",
      "Change %": "-0.01%"
  },
  {
      "Date": "22-Jul-16",
      "Price": "7.249",
      "Open": "7.249",
      "High": "7.249",
      "Low": "7.249",
      "Change %": "-0.17%"
  },
  {
      "Date": "21-Jul-16",
      "Price": "7.261",
      "Open": "7.261",
      "High": "7.261",
      "Low": "7.261",
      "Change %": "-0.12%"
  },
  {
      "Date": "20-Jul-16",
      "Price": "7.27",
      "Open": "7.27",
      "High": "7.27",
      "Low": "7.27",
      "Change %": "-0.14%"
  },
  {
      "Date": "19-Jul-16",
      "Price": "7.28",
      "Open": "7.28",
      "High": "7.28",
      "Low": "7.28",
      "Change %": "-0.19%"
  },
  {
      "Date": "18-Jul-16",
      "Price": "7.294",
      "Open": "7.294",
      "High": "7.294",
      "Low": "7.294",
      "Change %": "0.30%"
  },
  {
      "Date": "15-Jul-16",
      "Price": "7.272",
      "Open": "7.272",
      "High": "7.272",
      "Low": "7.272",
      "Change %": "-0.18%"
  },
  {
      "Date": "14-Jul-16",
      "Price": "7.285",
      "Open": "7.285",
      "High": "7.285",
      "Low": "7.285",
      "Change %": "0.01%"
  },
  {
      "Date": "13-Jul-16",
      "Price": "7.284",
      "Open": "7.284",
      "High": "7.284",
      "Low": "7.284",
      "Change %": "-0.72%"
  },
  {
      "Date": "12-Jul-16",
      "Price": "7.337",
      "Open": "7.337",
      "High": "7.337",
      "Low": "7.337",
      "Change %": "-0.64%"
  },
  {
      "Date": "11-Jul-16",
      "Price": "7.384",
      "Open": "7.384",
      "High": "7.384",
      "Low": "7.384",
      "Change %": "0.00%"
  },
  {
      "Date": "8-Jul-16",
      "Price": "7.384",
      "Open": "7.384",
      "High": "7.384",
      "Low": "7.384",
      "Change %": "0.04%"
  },
  {
      "Date": "7-Jul-16",
      "Price": "7.381",
      "Open": "7.381",
      "High": "7.381",
      "Low": "7.381",
      "Change %": "-0.18%"
  },
  {
      "Date": "5-Jul-16",
      "Price": "7.394",
      "Open": "7.394",
      "High": "7.394",
      "Low": "7.394",
      "Change %": "-0.42%"
  },
  {
      "Date": "4-Jul-16",
      "Price": "7.425",
      "Open": "7.425",
      "High": "7.425",
      "Low": "7.425",
      "Change %": "0.08%"
  },
  {
      "Date": "1-Jul-16",
      "Price": "7.419",
      "Open": "7.419",
      "High": "7.419",
      "Low": "7.419",
      "Change %": "-0.39%"
  },
  {
      "Date": "30-Jun-16",
      "Price": "7.448",
      "Open": "7.448",
      "High": "7.448",
      "Low": "7.448",
      "Change %": "0.05%"
  },
  {
      "Date": "29-Jun-16",
      "Price": "7.444",
      "Open": "7.444",
      "High": "7.444",
      "Low": "7.444",
      "Change %": "-0.12%"
  },
  {
      "Date": "28-Jun-16",
      "Price": "7.453",
      "Open": "7.453",
      "High": "7.453",
      "Low": "7.453",
      "Change %": "-0.08%"
  },
  {
      "Date": "27-Jun-16",
      "Price": "7.459",
      "Open": "7.459",
      "High": "7.459",
      "Low": "7.459",
      "Change %": "-0.19%"
  },
  {
      "Date": "24-Jun-16",
      "Price": "7.473",
      "Open": "7.473",
      "High": "7.473",
      "Low": "7.473",
      "Change %": "-0.08%"
  },
  {
      "Date": "23-Jun-16",
      "Price": "7.479",
      "Open": "7.479",
      "High": "7.479",
      "Low": "7.479",
      "Change %": "0.04%"
  },
  {
      "Date": "22-Jun-16",
      "Price": "7.476",
      "Open": "7.476",
      "High": "7.476",
      "Low": "7.476",
      "Change %": "-0.29%"
  },
  {
      "Date": "21-Jun-16",
      "Price": "7.498",
      "Open": "7.498",
      "High": "7.498",
      "Low": "7.498",
      "Change %": "0.05%"
  },
  {
      "Date": "20-Jun-16",
      "Price": "7.494",
      "Open": "7.494",
      "High": "7.494",
      "Low": "7.494",
      "Change %": "-0.09%"
  },
  {
      "Date": "17-Jun-16",
      "Price": "7.501",
      "Open": "7.501",
      "High": "7.501",
      "Low": "7.501",
      "Change %": "-0.04%"
  },
  {
      "Date": "16-Jun-16",
      "Price": "7.504",
      "Open": "7.504",
      "High": "7.504",
      "Low": "7.504",
      "Change %": "-0.17%"
  },
  {
      "Date": "15-Jun-16",
      "Price": "7.517",
      "Open": "7.517",
      "High": "7.517",
      "Low": "7.517",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Jun-16",
      "Price": "7.517",
      "Open": "7.517",
      "High": "7.517",
      "Low": "7.517",
      "Change %": "-0.11%"
  },
  {
      "Date": "13-Jun-16",
      "Price": "7.525",
      "Open": "7.525",
      "High": "7.525",
      "Low": "7.525",
      "Change %": "0.45%"
  },
  {
      "Date": "10-Jun-16",
      "Price": "7.491",
      "Open": "7.491",
      "High": "7.491",
      "Low": "7.491",
      "Change %": "0.07%"
  },
  {
      "Date": "9-Jun-16",
      "Price": "7.486",
      "Open": "7.486",
      "High": "7.486",
      "Low": "7.486",
      "Change %": "-0.03%"
  },
  {
      "Date": "8-Jun-16",
      "Price": "7.488",
      "Open": "7.488",
      "High": "7.488",
      "Low": "7.488",
      "Change %": "0.08%"
  },
  {
      "Date": "7-Jun-16",
      "Price": "7.482",
      "Open": "7.482",
      "High": "7.482",
      "Low": "7.482",
      "Change %": "0.09%"
  },
  {
      "Date": "6-Jun-16",
      "Price": "7.475",
      "Open": "7.475",
      "High": "7.475",
      "Low": "7.475",
      "Change %": "-0.17%"
  },
  {
      "Date": "3-Jun-16",
      "Price": "7.488",
      "Open": "7.488",
      "High": "7.488",
      "Low": "7.488",
      "Change %": "0.03%"
  },
  {
      "Date": "2-Jun-16",
      "Price": "7.486",
      "Open": "7.486",
      "High": "7.486",
      "Low": "7.486",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Jun-16",
      "Price": "7.486",
      "Open": "7.486",
      "High": "7.486",
      "Low": "7.486",
      "Change %": "0.20%"
  },
  {
      "Date": "31-May-16",
      "Price": "7.471",
      "Open": "7.471",
      "High": "7.471",
      "Low": "7.471",
      "Change %": "0.15%"
  },
  {
      "Date": "30-May-16",
      "Price": "7.46",
      "Open": "7.46",
      "High": "7.46",
      "Low": "7.46",
      "Change %": "-0.15%"
  },
  {
      "Date": "28-May-16",
      "Price": "7.471",
      "Open": "7.471",
      "High": "7.471",
      "Low": "7.471",
      "Change %": "0.01%"
  },
  {
      "Date": "27-May-16",
      "Price": "7.47",
      "Open": "7.47",
      "High": "7.47",
      "Low": "7.47",
      "Change %": "0.01%"
  },
  {
      "Date": "26-May-16",
      "Price": "7.469",
      "Open": "7.469",
      "High": "7.469",
      "Low": "7.469",
      "Change %": "0.08%"
  },
  {
      "Date": "25-May-16",
      "Price": "7.463",
      "Open": "7.463",
      "High": "7.463",
      "Low": "7.463",
      "Change %": "-0.04%"
  },
  {
      "Date": "24-May-16",
      "Price": "7.466",
      "Open": "7.466",
      "High": "7.466",
      "Low": "7.466",
      "Change %": "0.03%"
  },
  {
      "Date": "23-May-16",
      "Price": "7.464",
      "Open": "7.464",
      "High": "7.464",
      "Low": "7.464",
      "Change %": "-0.16%"
  },
  {
      "Date": "20-May-16",
      "Price": "7.476",
      "Open": "7.476",
      "High": "7.476",
      "Low": "7.476",
      "Change %": "0.08%"
  },
  {
      "Date": "19-May-16",
      "Price": "7.47",
      "Open": "7.47",
      "High": "7.47",
      "Low": "7.47",
      "Change %": "-0.05%"
  },
  {
      "Date": "18-May-16",
      "Price": "7.474",
      "Open": "7.474",
      "High": "7.474",
      "Low": "7.474",
      "Change %": "0.30%"
  },
  {
      "Date": "17-May-16",
      "Price": "7.452",
      "Open": "7.452",
      "High": "7.452",
      "Low": "7.452",
      "Change %": "0.00%"
  },
  {
      "Date": "16-May-16",
      "Price": "7.452",
      "Open": "7.452",
      "High": "7.452",
      "Low": "7.452",
      "Change %": "0.05%"
  },
  {
      "Date": "13-May-16",
      "Price": "7.448",
      "Open": "7.448",
      "High": "7.448",
      "Low": "7.448",
      "Change %": "0.35%"
  },
  {
      "Date": "12-May-16",
      "Price": "7.422",
      "Open": "7.422",
      "High": "7.422",
      "Low": "7.422",
      "Change %": "-0.08%"
  },
  {
      "Date": "11-May-16",
      "Price": "7.428",
      "Open": "7.428",
      "High": "7.428",
      "Low": "7.428",
      "Change %": "0.00%"
  },
  {
      "Date": "10-May-16",
      "Price": "7.428",
      "Open": "7.428",
      "High": "7.428",
      "Low": "7.428",
      "Change %": "0.04%"
  },
  {
      "Date": "9-May-16",
      "Price": "7.425",
      "Open": "7.425",
      "High": "7.425",
      "Low": "7.425",
      "Change %": "-0.13%"
  },
  {
      "Date": "6-May-16",
      "Price": "7.435",
      "Open": "7.435",
      "High": "7.435",
      "Low": "7.435",
      "Change %": "0.00%"
  },
  {
      "Date": "5-May-16",
      "Price": "7.435",
      "Open": "7.435",
      "High": "7.435",
      "Low": "7.435",
      "Change %": "0.04%"
  },
  {
      "Date": "4-May-16",
      "Price": "7.432",
      "Open": "7.432",
      "High": "7.432",
      "Low": "7.432",
      "Change %": "-0.13%"
  },
  {
      "Date": "3-May-16",
      "Price": "7.442",
      "Open": "7.442",
      "High": "7.442",
      "Low": "7.442",
      "Change %": "0.04%"
  },
  {
      "Date": "2-May-16",
      "Price": "7.439",
      "Open": "7.439",
      "High": "7.439",
      "Low": "7.439",
      "Change %": "0.05%"
  },
  {
      "Date": "29-Apr-16",
      "Price": "7.435",
      "Open": "7.435",
      "High": "7.435",
      "Low": "7.435",
      "Change %": "-0.11%"
  },
  {
      "Date": "28-Apr-16",
      "Price": "7.443",
      "Open": "7.443",
      "High": "7.443",
      "Low": "7.443",
      "Change %": "-0.23%"
  },
  {
      "Date": "27-Apr-16",
      "Price": "7.46",
      "Open": "7.46",
      "High": "7.46",
      "Low": "7.46",
      "Change %": "-0.12%"
  },
  {
      "Date": "26-Apr-16",
      "Price": "7.469",
      "Open": "7.469",
      "High": "7.469",
      "Low": "7.469",
      "Change %": "-0.01%"
  },
  {
      "Date": "25-Apr-16",
      "Price": "7.47",
      "Open": "7.47",
      "High": "7.47",
      "Low": "7.47",
      "Change %": "0.19%"
  },
  {
      "Date": "22-Apr-16",
      "Price": "7.456",
      "Open": "7.456",
      "High": "7.456",
      "Low": "7.456",
      "Change %": "-0.23%"
  },
  {
      "Date": "21-Apr-16",
      "Price": "7.473",
      "Open": "7.473",
      "High": "7.473",
      "Low": "7.473",
      "Change %": "0.48%"
  },
  {
      "Date": "20-Apr-16",
      "Price": "7.437",
      "Open": "7.437",
      "High": "7.437",
      "Low": "7.437",
      "Change %": "0.27%"
  },
  {
      "Date": "18-Apr-16",
      "Price": "7.417",
      "Open": "7.417",
      "High": "7.417",
      "Low": "7.417",
      "Change %": "-0.24%"
  },
  {
      "Date": "13-Apr-16",
      "Price": "7.435",
      "Open": "7.435",
      "High": "7.435",
      "Low": "7.435",
      "Change %": "0.24%"
  },
  {
      "Date": "12-Apr-16",
      "Price": "7.417",
      "Open": "7.417",
      "High": "7.417",
      "Low": "7.417",
      "Change %": "0.03%"
  },
  {
      "Date": "11-Apr-16",
      "Price": "7.415",
      "Open": "7.415",
      "High": "7.415",
      "Low": "7.415",
      "Change %": "-0.43%"
  },
  {
      "Date": "7-Apr-16",
      "Price": "7.447",
      "Open": "7.447",
      "High": "7.447",
      "Low": "7.447",
      "Change %": "-0.17%"
  },
  {
      "Date": "6-Apr-16",
      "Price": "7.46",
      "Open": "7.46",
      "High": "7.46",
      "Low": "7.46",
      "Change %": "-0.03%"
  },
  {
      "Date": "5-Apr-16",
      "Price": "7.462",
      "Open": "7.462",
      "High": "7.462",
      "Low": "7.462",
      "Change %": "0.73%"
  },
  {
      "Date": "4-Apr-16",
      "Price": "7.408",
      "Open": "7.408",
      "High": "7.408",
      "Low": "7.408",
      "Change %": "-0.68%"
  },
  {
      "Date": "31-Mar-16",
      "Price": "7.459",
      "Open": "7.459",
      "High": "7.459",
      "Low": "7.459",
      "Change %": "-0.49%"
  },
  {
      "Date": "30-Mar-16",
      "Price": "7.496",
      "Open": "7.496",
      "High": "7.496",
      "Low": "7.496",
      "Change %": "-0.24%"
  },
  {
      "Date": "29-Mar-16",
      "Price": "7.514",
      "Open": "7.514",
      "High": "7.514",
      "Low": "7.514",
      "Change %": "0.21%"
  },
  {
      "Date": "28-Mar-16",
      "Price": "7.498",
      "Open": "7.498",
      "High": "7.498",
      "Low": "7.498",
      "Change %": "-0.12%"
  },
  {
      "Date": "23-Mar-16",
      "Price": "7.507",
      "Open": "7.507",
      "High": "7.507",
      "Low": "7.507",
      "Change %": "0.04%"
  },
  {
      "Date": "22-Mar-16",
      "Price": "7.504",
      "Open": "7.504",
      "High": "7.504",
      "Low": "7.504",
      "Change %": "0.00%"
  },
  {
      "Date": "21-Mar-16",
      "Price": "7.504",
      "Open": "7.504",
      "High": "7.504",
      "Low": "7.504",
      "Change %": "-0.21%"
  },
  {
      "Date": "18-Mar-16",
      "Price": "7.52",
      "Open": "7.52",
      "High": "7.52",
      "Low": "7.52",
      "Change %": "0.11%"
  },
  {
      "Date": "17-Mar-16",
      "Price": "7.512",
      "Open": "7.512",
      "High": "7.512",
      "Low": "7.512",
      "Change %": "-0.78%"
  },
  {
      "Date": "16-Mar-16",
      "Price": "7.571",
      "Open": "7.571",
      "High": "7.571",
      "Low": "7.571",
      "Change %": "-0.12%"
  },
  {
      "Date": "15-Mar-16",
      "Price": "7.58",
      "Open": "7.58",
      "High": "7.58",
      "Low": "7.58",
      "Change %": "-0.25%"
  },
  {
      "Date": "14-Mar-16",
      "Price": "7.599",
      "Open": "7.599",
      "High": "7.599",
      "Low": "7.599",
      "Change %": "-0.37%"
  },
  {
      "Date": "11-Mar-16",
      "Price": "7.627",
      "Open": "7.627",
      "High": "7.627",
      "Low": "7.627",
      "Change %": "-0.10%"
  },
  {
      "Date": "10-Mar-16",
      "Price": "7.635",
      "Open": "7.635",
      "High": "7.635",
      "Low": "7.635",
      "Change %": "-0.25%"
  },
  {
      "Date": "9-Mar-16",
      "Price": "7.654",
      "Open": "7.654",
      "High": "7.654",
      "Low": "7.654",
      "Change %": "0.16%"
  },
  {
      "Date": "8-Mar-16",
      "Price": "7.642",
      "Open": "7.642",
      "High": "7.642",
      "Low": "7.642",
      "Change %": "0.12%"
  },
  {
      "Date": "4-Mar-16",
      "Price": "7.633",
      "Open": "7.633",
      "High": "7.633",
      "Low": "7.633",
      "Change %": "-0.33%"
  },
  {
      "Date": "3-Mar-16",
      "Price": "7.658",
      "Open": "7.658",
      "High": "7.658",
      "Low": "7.658",
      "Change %": "0.46%"
  },
  {
      "Date": "2-Mar-16",
      "Price": "7.623",
      "Open": "7.623",
      "High": "7.623",
      "Low": "7.623",
      "Change %": "0.25%"
  },
  {
      "Date": "1-Mar-16",
      "Price": "7.604",
      "Open": "7.604",
      "High": "7.604",
      "Low": "7.604",
      "Change %": "-0.25%"
  },
  {
      "Date": "29-Feb-16",
      "Price": "7.623",
      "Open": "7.623",
      "High": "7.623",
      "Low": "7.623",
      "Change %": "-2.01%"
  },
  {
      "Date": "26-Feb-16",
      "Price": "7.779",
      "Open": "7.779",
      "High": "7.779",
      "Low": "7.779",
      "Change %": "-1.04%"
  },
  {
      "Date": "25-Feb-16",
      "Price": "7.861",
      "Open": "7.861",
      "High": "7.861",
      "Low": "7.861",
      "Change %": "0.37%"
  },
  {
      "Date": "24-Feb-16",
      "Price": "7.832",
      "Open": "7.832",
      "High": "7.832",
      "Low": "7.832",
      "Change %": "0.13%"
  },
  {
      "Date": "23-Feb-16",
      "Price": "7.822",
      "Open": "7.822",
      "High": "7.822",
      "Low": "7.822",
      "Change %": "0.63%"
  },
  {
      "Date": "22-Feb-16",
      "Price": "7.773",
      "Open": "7.773",
      "High": "7.773",
      "Low": "7.773",
      "Change %": "0.45%"
  },
  {
      "Date": "18-Feb-16",
      "Price": "7.738",
      "Open": "7.738",
      "High": "7.738",
      "Low": "7.738",
      "Change %": "-0.62%"
  },
  {
      "Date": "17-Feb-16",
      "Price": "7.786",
      "Open": "7.786",
      "High": "7.786",
      "Low": "7.786",
      "Change %": "0.08%"
  },
  {
      "Date": "16-Feb-16",
      "Price": "7.78",
      "Open": "7.78",
      "High": "7.78",
      "Low": "7.78",
      "Change %": "0.34%"
  },
  {
      "Date": "15-Feb-16",
      "Price": "7.754",
      "Open": "7.754",
      "High": "7.754",
      "Low": "7.754",
      "Change %": "0.40%"
  },
  {
      "Date": "14-Feb-16",
      "Price": "7.723",
      "Open": "7.723",
      "High": "7.723",
      "Low": "7.723",
      "Change %": "0.00%"
  },
  {
      "Date": "12-Feb-16",
      "Price": "7.723",
      "Open": "7.723",
      "High": "7.723",
      "Low": "7.723",
      "Change %": "0.09%"
  },
  {
      "Date": "11-Feb-16",
      "Price": "7.716",
      "Open": "7.716",
      "High": "7.716",
      "Low": "7.716",
      "Change %": "-0.08%"
  },
  {
      "Date": "10-Feb-16",
      "Price": "7.722",
      "Open": "7.722",
      "High": "7.722",
      "Low": "7.722",
      "Change %": "-0.21%"
  },
  {
      "Date": "9-Feb-16",
      "Price": "7.738",
      "Open": "7.738",
      "High": "7.738",
      "Low": "7.738",
      "Change %": "0.17%"
  },
  {
      "Date": "8-Feb-16",
      "Price": "7.725",
      "Open": "7.725",
      "High": "7.725",
      "Low": "7.725",
      "Change %": "-1.24%"
  },
  {
      "Date": "5-Feb-16",
      "Price": "7.822",
      "Open": "7.822",
      "High": "7.822",
      "Low": "7.822",
      "Change %": "-0.17%"
  },
  {
      "Date": "4-Feb-16",
      "Price": "7.835",
      "Open": "7.835",
      "High": "7.835",
      "Low": "7.835",
      "Change %": "-0.18%"
  },
  {
      "Date": "3-Feb-16",
      "Price": "7.849",
      "Open": "7.849",
      "High": "7.849",
      "Low": "7.849",
      "Change %": "0.00%"
  },
  {
      "Date": "2-Feb-16",
      "Price": "7.849",
      "Open": "7.849",
      "High": "7.849",
      "Low": "7.849",
      "Change %": "0.73%"
  },
  {
      "Date": "1-Feb-16",
      "Price": "7.792",
      "Open": "7.792",
      "High": "7.792",
      "Low": "7.792",
      "Change %": "0.17%"
  },
  {
      "Date": "29-Jan-16",
      "Price": "7.779",
      "Open": "7.779",
      "High": "7.779",
      "Low": "7.779",
      "Change %": "-0.33%"
  },
  {
      "Date": "28-Jan-16",
      "Price": "7.805",
      "Open": "7.805",
      "High": "7.805",
      "Low": "7.805",
      "Change %": "0.22%"
  },
  {
      "Date": "27-Jan-16",
      "Price": "7.788",
      "Open": "7.788",
      "High": "7.788",
      "Low": "7.788",
      "Change %": "-0.22%"
  },
  {
      "Date": "25-Jan-16",
      "Price": "7.805",
      "Open": "7.805",
      "High": "7.805",
      "Low": "7.805",
      "Change %": "0.39%"
  },
  {
      "Date": "22-Jan-16",
      "Price": "7.775",
      "Open": "7.775",
      "High": "7.775",
      "Low": "7.775",
      "Change %": "0.35%"
  },
  {
      "Date": "21-Jan-16",
      "Price": "7.748",
      "Open": "7.748",
      "High": "7.748",
      "Low": "7.748",
      "Change %": "-0.08%"
  },
  {
      "Date": "20-Jan-16",
      "Price": "7.754",
      "Open": "7.754",
      "High": "7.754",
      "Low": "7.754",
      "Change %": "-0.33%"
  },
  {
      "Date": "19-Jan-16",
      "Price": "7.78",
      "Open": "7.78",
      "High": "7.78",
      "Low": "7.78",
      "Change %": "-0.40%"
  },
  {
      "Date": "18-Jan-16",
      "Price": "7.811",
      "Open": "7.811",
      "High": "7.811",
      "Low": "7.811",
      "Change %": "0.06%"
  },
  {
      "Date": "15-Jan-16",
      "Price": "7.806",
      "Open": "7.806",
      "High": "7.806",
      "Low": "7.806",
      "Change %": "0.26%"
  },
  {
      "Date": "14-Jan-16",
      "Price": "7.786",
      "Open": "7.786",
      "High": "7.786",
      "Low": "7.786",
      "Change %": "0.27%"
  },
  {
      "Date": "13-Jan-16",
      "Price": "7.765",
      "Open": "7.765",
      "High": "7.765",
      "Low": "7.765",
      "Change %": "0.08%"
  },
  {
      "Date": "12-Jan-16",
      "Price": "7.759",
      "Open": "7.759",
      "High": "7.759",
      "Low": "7.759",
      "Change %": "0.06%"
  },
  {
      "Date": "11-Jan-16",
      "Price": "7.754",
      "Open": "7.754",
      "High": "7.754",
      "Low": "7.754",
      "Change %": "0.17%"
  },
  {
      "Date": "10-Jan-16",
      "Price": "7.741",
      "Open": "7.741",
      "High": "7.741",
      "Low": "7.741",
      "Change %": "0.00%"
  },
  {
      "Date": "8-Jan-16",
      "Price": "7.741",
      "Open": "7.741",
      "High": "7.741",
      "Low": "7.741",
      "Change %": "0.06%"
  },
  {
      "Date": "7-Jan-16",
      "Price": "7.736",
      "Open": "7.736",
      "High": "7.736",
      "Low": "7.736",
      "Change %": "0.00%"
  },
  {
      "Date": "6-Jan-16",
      "Price": "7.736",
      "Open": "7.736",
      "High": "7.736",
      "Low": "7.736",
      "Change %": "-0.04%"
  },
  {
      "Date": "5-Jan-16",
      "Price": "7.739",
      "Open": "7.739",
      "High": "7.739",
      "Low": "7.739",
      "Change %": "0.21%"
  },
  {
      "Date": "4-Jan-16",
      "Price": "7.723",
      "Open": "7.723",
      "High": "7.723",
      "Low": "7.723",
      "Change %": "-0.05%"
  },
  {
      "Date": "1-Jan-16",
      "Price": "7.727",
      "Open": "7.727",
      "High": "7.727",
      "Low": "7.727",
      "Change %": "-0.40%"
  },
  {
      "Date": "31-Dec-15",
      "Price": "7.758",
      "Open": "7.758",
      "High": "7.758",
      "Low": "7.758",
      "Change %": "0.12%"
  },
  {
      "Date": "30-Dec-15",
      "Price": "7.749",
      "Open": "7.749",
      "High": "7.749",
      "Low": "7.749",
      "Change %": "-0.08%"
  },
  {
      "Date": "29-Dec-15",
      "Price": "7.755",
      "Open": "7.755",
      "High": "7.755",
      "Low": "7.755",
      "Change %": "-0.01%"
  },
  {
      "Date": "28-Dec-15",
      "Price": "7.756",
      "Open": "7.756",
      "High": "7.756",
      "Low": "7.756",
      "Change %": "0.09%"
  },
  {
      "Date": "23-Dec-15",
      "Price": "7.749",
      "Open": "7.749",
      "High": "7.749",
      "Low": "7.749",
      "Change %": "-0.09%"
  },
  {
      "Date": "22-Dec-15",
      "Price": "7.756",
      "Open": "7.756",
      "High": "7.756",
      "Low": "7.756",
      "Change %": "-0.22%"
  },
  {
      "Date": "21-Dec-15",
      "Price": "7.773",
      "Open": "7.773",
      "High": "7.773",
      "Low": "7.773",
      "Change %": "0.62%"
  },
  {
      "Date": "18-Dec-15",
      "Price": "7.725",
      "Open": "7.725",
      "High": "7.725",
      "Low": "7.725",
      "Change %": "0.22%"
  },
  {
      "Date": "17-Dec-15",
      "Price": "7.708",
      "Open": "7.708",
      "High": "7.708",
      "Low": "7.708",
      "Change %": "-0.34%"
  },
  {
      "Date": "16-Dec-15",
      "Price": "7.734",
      "Open": "7.734",
      "High": "7.734",
      "Low": "7.734",
      "Change %": "-0.68%"
  },
  {
      "Date": "15-Dec-15",
      "Price": "7.787",
      "Open": "7.787",
      "High": "7.787",
      "Low": "7.787",
      "Change %": "-0.37%"
  },
  {
      "Date": "14-Dec-15",
      "Price": "7.816",
      "Open": "7.816",
      "High": "7.816",
      "Low": "7.816",
      "Change %": "0.55%"
  },
  {
      "Date": "11-Dec-15",
      "Price": "7.773",
      "Open": "7.773",
      "High": "7.773",
      "Low": "7.773",
      "Change %": "-0.09%"
  },
  {
      "Date": "10-Dec-15",
      "Price": "7.78",
      "Open": "7.78",
      "High": "7.78",
      "Low": "7.78",
      "Change %": "0.04%"
  },
  {
      "Date": "9-Dec-15",
      "Price": "7.777",
      "Open": "7.777",
      "High": "7.777",
      "Low": "7.777",
      "Change %": "-0.23%"
  },
  {
      "Date": "8-Dec-15",
      "Price": "7.795",
      "Open": "7.795",
      "High": "7.795",
      "Low": "7.795",
      "Change %": "0.53%"
  },
  {
      "Date": "7-Dec-15",
      "Price": "7.754",
      "Open": "7.754",
      "High": "7.754",
      "Low": "7.754",
      "Change %": "-0.04%"
  },
  {
      "Date": "4-Dec-15",
      "Price": "7.757",
      "Open": "7.757",
      "High": "7.757",
      "Low": "7.757",
      "Change %": "0.58%"
  },
  {
      "Date": "3-Dec-15",
      "Price": "7.712",
      "Open": "7.712",
      "High": "7.712",
      "Low": "7.712",
      "Change %": "-0.39%"
  },
  {
      "Date": "2-Dec-15",
      "Price": "7.742",
      "Open": "7.742",
      "High": "7.742",
      "Low": "7.742",
      "Change %": "0.28%"
  },
  {
      "Date": "1-Dec-15",
      "Price": "7.72",
      "Open": "7.72",
      "High": "7.72",
      "Low": "7.72",
      "Change %": "-0.85%"
  },
  {
      "Date": "30-Nov-15",
      "Price": "7.786",
      "Open": "7.786",
      "High": "7.786",
      "Low": "7.786",
      "Change %": "0.26%"
  },
  {
      "Date": "27-Nov-15",
      "Price": "7.766",
      "Open": "7.766",
      "High": "7.766",
      "Low": "7.766",
      "Change %": "0.60%"
  },
  {
      "Date": "26-Nov-15",
      "Price": "7.72",
      "Open": "7.72",
      "High": "7.72",
      "Low": "7.72",
      "Change %": "0.22%"
  },
  {
      "Date": "24-Nov-15",
      "Price": "7.703",
      "Open": "7.703",
      "High": "7.703",
      "Low": "7.703",
      "Change %": "-0.16%"
  },
  {
      "Date": "23-Nov-15",
      "Price": "7.715",
      "Open": "7.715",
      "High": "7.715",
      "Low": "7.715",
      "Change %": "0.23%"
  },
  {
      "Date": "22-Nov-15",
      "Price": "7.697",
      "Open": "7.697",
      "High": "7.697",
      "Low": "7.697",
      "Change %": "0.00%"
  },
  {
      "Date": "20-Nov-15",
      "Price": "7.697",
      "Open": "7.697",
      "High": "7.697",
      "Low": "7.697",
      "Change %": "0.33%"
  },
  {
      "Date": "19-Nov-15",
      "Price": "7.672",
      "Open": "7.672",
      "High": "7.672",
      "Low": "7.672",
      "Change %": "-0.09%"
  },
  {
      "Date": "18-Nov-15",
      "Price": "7.679",
      "Open": "7.679",
      "High": "7.679",
      "Low": "7.679",
      "Change %": "0.16%"
  },
  {
      "Date": "17-Nov-15",
      "Price": "7.667",
      "Open": "7.667",
      "High": "7.667",
      "Low": "7.667",
      "Change %": "0.20%"
  },
  {
      "Date": "16-Nov-15",
      "Price": "7.652",
      "Open": "7.652",
      "High": "7.652",
      "Low": "7.652",
      "Change %": "0.08%"
  },
  {
      "Date": "13-Nov-15",
      "Price": "7.646",
      "Open": "7.646",
      "High": "7.646",
      "Low": "7.646",
      "Change %": "-0.47%"
  },
  {
      "Date": "10-Nov-15",
      "Price": "7.682",
      "Open": "7.682",
      "High": "7.682",
      "Low": "7.682",
      "Change %": "-0.56%"
  },
  {
      "Date": "9-Nov-15",
      "Price": "7.725",
      "Open": "7.725",
      "High": "7.725",
      "Low": "7.725",
      "Change %": "0.52%"
  },
  {
      "Date": "6-Nov-15",
      "Price": "7.685",
      "Open": "7.685",
      "High": "7.685",
      "Low": "7.685",
      "Change %": "0.08%"
  },
  {
      "Date": "5-Nov-15",
      "Price": "7.679",
      "Open": "7.679",
      "High": "7.679",
      "Low": "7.679",
      "Change %": "0.35%"
  },
  {
      "Date": "4-Nov-15",
      "Price": "7.652",
      "Open": "7.652",
      "High": "7.652",
      "Low": "7.652",
      "Change %": "0.08%"
  },
  {
      "Date": "3-Nov-15",
      "Price": "7.646",
      "Open": "7.646",
      "High": "7.646",
      "Low": "7.646",
      "Change %": "0.22%"
  },
  {
      "Date": "2-Nov-15",
      "Price": "7.629",
      "Open": "7.629",
      "High": "7.629",
      "Low": "7.629",
      "Change %": "-0.14%"
  },
  {
      "Date": "30-Oct-15",
      "Price": "7.64",
      "Open": "7.64",
      "High": "7.64",
      "Low": "7.64",
      "Change %": "0.14%"
  },
  {
      "Date": "29-Oct-15",
      "Price": "7.629",
      "Open": "7.629",
      "High": "7.629",
      "Low": "7.629",
      "Change %": "0.50%"
  },
  {
      "Date": "28-Oct-15",
      "Price": "7.591",
      "Open": "7.591",
      "High": "7.591",
      "Low": "7.591",
      "Change %": "-0.17%"
  },
  {
      "Date": "27-Oct-15",
      "Price": "7.604",
      "Open": "7.604",
      "High": "7.604",
      "Low": "7.604",
      "Change %": "-0.09%"
  },
  {
      "Date": "26-Oct-15",
      "Price": "7.611",
      "Open": "7.611",
      "High": "7.611",
      "Low": "7.611",
      "Change %": "0.34%"
  },
  {
      "Date": "23-Oct-15",
      "Price": "7.585",
      "Open": "7.585",
      "High": "7.585",
      "Low": "7.585",
      "Change %": "0.03%"
  },
  {
      "Date": "21-Oct-15",
      "Price": "7.583",
      "Open": "7.583",
      "High": "7.583",
      "Low": "7.583",
      "Change %": "0.05%"
  },
  {
      "Date": "20-Oct-15",
      "Price": "7.579",
      "Open": "7.579",
      "High": "7.579",
      "Low": "7.579",
      "Change %": "0.11%"
  },
  {
      "Date": "19-Oct-15",
      "Price": "7.571",
      "Open": "7.571",
      "High": "7.571",
      "Low": "7.571",
      "Change %": "0.08%"
  },
  {
      "Date": "18-Oct-15",
      "Price": "7.565",
      "Open": "7.565",
      "High": "7.565",
      "Low": "7.565",
      "Change %": "0.01%"
  },
  {
      "Date": "16-Oct-15",
      "Price": "7.564",
      "Open": "7.564",
      "High": "7.564",
      "Low": "7.564",
      "Change %": "0.21%"
  },
  {
      "Date": "15-Oct-15",
      "Price": "7.548",
      "Open": "7.548",
      "High": "7.548",
      "Low": "7.548",
      "Change %": "-0.08%"
  },
  {
      "Date": "14-Oct-15",
      "Price": "7.554",
      "Open": "7.554",
      "High": "7.554",
      "Low": "7.554",
      "Change %": "-0.08%"
  },
  {
      "Date": "13-Oct-15",
      "Price": "7.56",
      "Open": "7.56",
      "High": "7.56",
      "Low": "7.56",
      "Change %": "-0.26%"
  },
  {
      "Date": "12-Oct-15",
      "Price": "7.58",
      "Open": "7.58",
      "High": "7.58",
      "Low": "7.58",
      "Change %": "0.46%"
  },
  {
      "Date": "9-Oct-15",
      "Price": "7.545",
      "Open": "7.545",
      "High": "7.545",
      "Low": "7.545",
      "Change %": "0.12%"
  },
  {
      "Date": "8-Oct-15",
      "Price": "7.536",
      "Open": "7.536",
      "High": "7.536",
      "Low": "7.536",
      "Change %": "-0.05%"
  },
  {
      "Date": "7-Oct-15",
      "Price": "7.54",
      "Open": "7.54",
      "High": "7.54",
      "Low": "7.54",
      "Change %": "0.17%"
  },
  {
      "Date": "6-Oct-15",
      "Price": "7.527",
      "Open": "7.527",
      "High": "7.527",
      "Low": "7.527",
      "Change %": "0.19%"
  },
  {
      "Date": "5-Oct-15",
      "Price": "7.513",
      "Open": "7.513",
      "High": "7.513",
      "Low": "7.513",
      "Change %": "-0.66%"
  },
  {
      "Date": "3-Oct-15",
      "Price": "7.563",
      "Open": "7.563",
      "High": "7.563",
      "Low": "7.563",
      "Change %": "0.03%"
  },
  {
      "Date": "1-Oct-15",
      "Price": "7.561",
      "Open": "7.561",
      "High": "7.561",
      "Low": "7.561",
      "Change %": "0.29%"
  },
  {
      "Date": "30-Sep-15",
      "Price": "7.539",
      "Open": "7.539",
      "High": "7.539",
      "Low": "7.539",
      "Change %": "-0.95%"
  },
  {
      "Date": "29-Sep-15",
      "Price": "7.611",
      "Open": "7.611",
      "High": "7.611",
      "Low": "7.611",
      "Change %": "-1.48%"
  },
  {
      "Date": "28-Sep-15",
      "Price": "7.725",
      "Open": "7.725",
      "High": "7.725",
      "Low": "7.725",
      "Change %": "0.12%"
  },
  {
      "Date": "27-Sep-15",
      "Price": "7.716",
      "Open": "7.716",
      "High": "7.716",
      "Low": "7.716",
      "Change %": "0.01%"
  },
  {
      "Date": "24-Sep-15",
      "Price": "7.715",
      "Open": "7.715",
      "High": "7.715",
      "Low": "7.715",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Sep-15",
      "Price": "7.715",
      "Open": "7.715",
      "High": "7.715",
      "Low": "7.715",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Sep-15",
      "Price": "7.715",
      "Open": "7.715",
      "High": "7.715",
      "Low": "7.715",
      "Change %": "0.16%"
  },
  {
      "Date": "21-Sep-15",
      "Price": "7.703",
      "Open": "7.703",
      "High": "7.703",
      "Low": "7.703",
      "Change %": "0.08%"
  },
  {
      "Date": "18-Sep-15",
      "Price": "7.697",
      "Open": "7.697",
      "High": "7.697",
      "Low": "7.697",
      "Change %": "-0.72%"
  },
  {
      "Date": "16-Sep-15",
      "Price": "7.753",
      "Open": "7.753",
      "High": "7.753",
      "Low": "7.753",
      "Change %": "-0.04%"
  },
  {
      "Date": "15-Sep-15",
      "Price": "7.756",
      "Open": "7.756",
      "High": "7.756",
      "Low": "7.756",
      "Change %": "0.01%"
  },
  {
      "Date": "14-Sep-15",
      "Price": "7.755",
      "Open": "7.755",
      "High": "7.755",
      "Low": "7.755",
      "Change %": "-0.21%"
  },
  {
      "Date": "11-Sep-15",
      "Price": "7.771",
      "Open": "7.771",
      "High": "7.771",
      "Low": "7.771",
      "Change %": "-0.01%"
  },
  {
      "Date": "10-Sep-15",
      "Price": "7.772",
      "Open": "7.772",
      "High": "7.772",
      "Low": "7.772",
      "Change %": "0.08%"
  },
  {
      "Date": "9-Sep-15",
      "Price": "7.766",
      "Open": "7.766",
      "High": "7.766",
      "Low": "7.766",
      "Change %": "-0.06%"
  },
  {
      "Date": "8-Sep-15",
      "Price": "7.771",
      "Open": "7.771",
      "High": "7.771",
      "Low": "7.771",
      "Change %": "-0.38%"
  },
  {
      "Date": "7-Sep-15",
      "Price": "7.801",
      "Open": "7.801",
      "High": "7.801",
      "Low": "7.801",
      "Change %": "0.62%"
  },
  {
      "Date": "4-Sep-15",
      "Price": "7.753",
      "Open": "7.753",
      "High": "7.753",
      "Low": "7.753",
      "Change %": "0.09%"
  },
  {
      "Date": "3-Sep-15",
      "Price": "7.746",
      "Open": "7.746",
      "High": "7.746",
      "Low": "7.746",
      "Change %": "-0.05%"
  },
  {
      "Date": "2-Sep-15",
      "Price": "7.75",
      "Open": "7.75",
      "High": "7.75",
      "Low": "7.75",
      "Change %": "-0.01%"
  },
  {
      "Date": "1-Sep-15",
      "Price": "7.751",
      "Open": "7.751",
      "High": "7.751",
      "Low": "7.751",
      "Change %": "-0.42%"
  },
  {
      "Date": "31-Aug-15",
      "Price": "7.784",
      "Open": "7.784",
      "High": "7.784",
      "Low": "7.784",
      "Change %": "0.09%"
  },
  {
      "Date": "28-Aug-15",
      "Price": "7.777",
      "Open": "7.777",
      "High": "7.777",
      "Low": "7.777",
      "Change %": "0.14%"
  },
  {
      "Date": "27-Aug-15",
      "Price": "7.766",
      "Open": "7.766",
      "High": "7.766",
      "Low": "7.766",
      "Change %": "-0.32%"
  },
  {
      "Date": "26-Aug-15",
      "Price": "7.791",
      "Open": "7.791",
      "High": "7.791",
      "Low": "7.791",
      "Change %": "-0.29%"
  },
  {
      "Date": "25-Aug-15",
      "Price": "7.814",
      "Open": "7.814",
      "High": "7.814",
      "Low": "7.814",
      "Change %": "-1.00%"
  },
  {
      "Date": "24-Aug-15",
      "Price": "7.893",
      "Open": "7.893",
      "High": "7.893",
      "Low": "7.893",
      "Change %": "1.40%"
  },
  {
      "Date": "21-Aug-15",
      "Price": "7.784",
      "Open": "7.784",
      "High": "7.784",
      "Low": "7.784",
      "Change %": "0.48%"
  },
  {
      "Date": "20-Aug-15",
      "Price": "7.747",
      "Open": "7.747",
      "High": "7.747",
      "Low": "7.747",
      "Change %": "0.08%"
  },
  {
      "Date": "19-Aug-15",
      "Price": "7.741",
      "Open": "7.741",
      "High": "7.741",
      "Low": "7.741",
      "Change %": "-0.04%"
  },
  {
      "Date": "17-Aug-15",
      "Price": "7.744",
      "Open": "7.744",
      "High": "7.744",
      "Low": "7.744",
      "Change %": "-0.01%"
  },
  {
      "Date": "14-Aug-15",
      "Price": "7.745",
      "Open": "7.745",
      "High": "7.745",
      "Low": "7.745",
      "Change %": "0.01%"
  },
  {
      "Date": "13-Aug-15",
      "Price": "7.744",
      "Open": "7.744",
      "High": "7.744",
      "Low": "7.744",
      "Change %": "-0.67%"
  },
  {
      "Date": "12-Aug-15",
      "Price": "7.796",
      "Open": "7.796",
      "High": "7.796",
      "Low": "7.796",
      "Change %": "0.00%"
  },
  {
      "Date": "11-Aug-15",
      "Price": "7.796",
      "Open": "7.796",
      "High": "7.796",
      "Low": "7.796",
      "Change %": "0.10%"
  },
  {
      "Date": "10-Aug-15",
      "Price": "7.788",
      "Open": "7.788",
      "High": "7.788",
      "Low": "7.788",
      "Change %": "-0.27%"
  },
  {
      "Date": "7-Aug-15",
      "Price": "7.809",
      "Open": "7.809",
      "High": "7.809",
      "Low": "7.809",
      "Change %": "0.04%"
  },
  {
      "Date": "6-Aug-15",
      "Price": "7.806",
      "Open": "7.806",
      "High": "7.806",
      "Low": "7.806",
      "Change %": "-0.38%"
  },
  {
      "Date": "5-Aug-15",
      "Price": "7.836",
      "Open": "7.836",
      "High": "7.836",
      "Low": "7.836",
      "Change %": "-0.05%"
  },
  {
      "Date": "4-Aug-15",
      "Price": "7.84",
      "Open": "7.84",
      "High": "7.84",
      "Low": "7.84",
      "Change %": "0.35%"
  },
  {
      "Date": "3-Aug-15",
      "Price": "7.813",
      "Open": "7.813",
      "High": "7.813",
      "Low": "7.813",
      "Change %": "0.09%"
  },
  {
      "Date": "31-Jul-15",
      "Price": "7.806",
      "Open": "7.806",
      "High": "7.806",
      "Low": "7.806",
      "Change %": "-0.19%"
  },
  {
      "Date": "30-Jul-15",
      "Price": "7.821",
      "Open": "7.821",
      "High": "7.821",
      "Low": "7.821",
      "Change %": "0.15%"
  },
  {
      "Date": "29-Jul-15",
      "Price": "7.809",
      "Open": "7.809",
      "High": "7.809",
      "Low": "7.809",
      "Change %": "0.05%"
  },
  {
      "Date": "28-Jul-15",
      "Price": "7.805",
      "Open": "7.805",
      "High": "7.805",
      "Low": "7.805",
      "Change %": "-0.29%"
  },
  {
      "Date": "27-Jul-15",
      "Price": "7.828",
      "Open": "7.828",
      "High": "7.828",
      "Low": "7.828",
      "Change %": "-0.03%"
  },
  {
      "Date": "24-Jul-15",
      "Price": "7.83",
      "Open": "7.83",
      "High": "7.83",
      "Low": "7.83",
      "Change %": "0.35%"
  },
  {
      "Date": "23-Jul-15",
      "Price": "7.803",
      "Open": "7.803",
      "High": "7.803",
      "Low": "7.803",
      "Change %": "-0.32%"
  },
  {
      "Date": "22-Jul-15",
      "Price": "7.828",
      "Open": "7.828",
      "High": "7.828",
      "Low": "7.828",
      "Change %": "-0.10%"
  },
  {
      "Date": "21-Jul-15",
      "Price": "7.836",
      "Open": "7.836",
      "High": "7.836",
      "Low": "7.836",
      "Change %": "-0.05%"
  },
  {
      "Date": "20-Jul-15",
      "Price": "7.84",
      "Open": "7.84",
      "High": "7.84",
      "Low": "7.84",
      "Change %": "0.11%"
  },
  {
      "Date": "18-Jul-15",
      "Price": "7.831",
      "Open": "7.831",
      "High": "7.831",
      "Low": "7.831",
      "Change %": "0.01%"
  },
  {
      "Date": "17-Jul-15",
      "Price": "7.83",
      "Open": "7.83",
      "High": "7.83",
      "Low": "7.83",
      "Change %": "-0.19%"
  },
  {
      "Date": "16-Jul-15",
      "Price": "7.845",
      "Open": "7.845",
      "High": "7.845",
      "Low": "7.845",
      "Change %": "0.08%"
  },
  {
      "Date": "15-Jul-15",
      "Price": "7.839",
      "Open": "7.839",
      "High": "7.839",
      "Low": "7.839",
      "Change %": "0.20%"
  },
  {
      "Date": "14-Jul-15",
      "Price": "7.823",
      "Open": "7.823",
      "High": "7.823",
      "Low": "7.823",
      "Change %": "-0.56%"
  },
  {
      "Date": "13-Jul-15",
      "Price": "7.867",
      "Open": "7.867",
      "High": "7.867",
      "Low": "7.867",
      "Change %": "0.83%"
  },
  {
      "Date": "10-Jul-15",
      "Price": "7.802",
      "Open": "7.802",
      "High": "7.802",
      "Low": "7.802",
      "Change %": "0.13%"
  },
  {
      "Date": "9-Jul-15",
      "Price": "7.792",
      "Open": "7.792",
      "High": "7.792",
      "Low": "7.792",
      "Change %": "0.14%"
  },
  {
      "Date": "8-Jul-15",
      "Price": "7.781",
      "Open": "7.781",
      "High": "7.781",
      "Low": "7.781",
      "Change %": "0.01%"
  },
  {
      "Date": "7-Jul-15",
      "Price": "7.78",
      "Open": "7.78",
      "High": "7.78",
      "Low": "7.78",
      "Change %": "0.35%"
  },
  {
      "Date": "6-Jul-15",
      "Price": "7.753",
      "Open": "7.753",
      "High": "7.753",
      "Low": "7.753",
      "Change %": "-0.59%"
  },
  {
      "Date": "3-Jul-15",
      "Price": "7.799",
      "Open": "7.799",
      "High": "7.799",
      "Low": "7.799",
      "Change %": "-0.15%"
  },
  {
      "Date": "2-Jul-15",
      "Price": "7.811",
      "Open": "7.811",
      "High": "7.811",
      "Low": "7.811",
      "Change %": "-0.08%"
  },
  {
      "Date": "1-Jul-15",
      "Price": "7.817",
      "Open": "7.817",
      "High": "7.817",
      "Low": "7.817",
      "Change %": "-0.56%"
  },
  {
      "Date": "30-Jun-15",
      "Price": "7.861",
      "Open": "7.861",
      "High": "7.861",
      "Low": "7.861",
      "Change %": "-0.32%"
  },
  {
      "Date": "29-Jun-15",
      "Price": "7.886",
      "Open": "7.886",
      "High": "7.886",
      "Low": "7.886",
      "Change %": "0.83%"
  },
  {
      "Date": "26-Jun-15",
      "Price": "7.821",
      "Open": "7.821",
      "High": "7.821",
      "Low": "7.821",
      "Change %": "-0.03%"
  },
  {
      "Date": "25-Jun-15",
      "Price": "7.823",
      "Open": "7.823",
      "High": "7.823",
      "Low": "7.823",
      "Change %": "0.24%"
  },
  {
      "Date": "24-Jun-15",
      "Price": "7.804",
      "Open": "7.804",
      "High": "7.804",
      "Low": "7.804",
      "Change %": "0.72%"
  },
  {
      "Date": "23-Jun-15",
      "Price": "7.748",
      "Open": "7.748",
      "High": "7.748",
      "Low": "7.748",
      "Change %": "0.16%"
  },
  {
      "Date": "22-Jun-15",
      "Price": "7.736",
      "Open": "7.736",
      "High": "7.736",
      "Low": "7.736",
      "Change %": "0.36%"
  },
  {
      "Date": "19-Jun-15",
      "Price": "7.708",
      "Open": "7.708",
      "High": "7.708",
      "Low": "7.708",
      "Change %": "-0.71%"
  },
  {
      "Date": "18-Jun-15",
      "Price": "7.763",
      "Open": "7.763",
      "High": "7.763",
      "Low": "7.763",
      "Change %": "-1.06%"
  },
  {
      "Date": "17-Jun-15",
      "Price": "7.846",
      "Open": "7.846",
      "High": "7.846",
      "Low": "7.846",
      "Change %": "-0.46%"
  },
  {
      "Date": "16-Jun-15",
      "Price": "7.882",
      "Open": "7.882",
      "High": "7.882",
      "Low": "7.882",
      "Change %": "0.24%"
  },
  {
      "Date": "15-Jun-15",
      "Price": "7.863",
      "Open": "7.863",
      "High": "7.863",
      "Low": "7.863",
      "Change %": "-0.29%"
  },
  {
      "Date": "12-Jun-15",
      "Price": "7.886",
      "Open": "7.886",
      "High": "7.886",
      "Low": "7.886",
      "Change %": "0.09%"
  },
  {
      "Date": "11-Jun-15",
      "Price": "7.879",
      "Open": "7.879",
      "High": "7.879",
      "Low": "7.879",
      "Change %": "0.69%"
  },
  {
      "Date": "10-Jun-15",
      "Price": "7.825",
      "Open": "7.825",
      "High": "7.825",
      "Low": "7.825",
      "Change %": "0.46%"
  },
  {
      "Date": "9-Jun-15",
      "Price": "7.789",
      "Open": "7.789",
      "High": "7.789",
      "Low": "7.789",
      "Change %": "-0.15%"
  },
  {
      "Date": "8-Jun-15",
      "Price": "7.801",
      "Open": "7.801",
      "High": "7.801",
      "Low": "7.801",
      "Change %": "-2.28%"
  },
  {
      "Date": "7-Jun-15",
      "Price": "7.983",
      "Open": "7.983",
      "High": "7.983",
      "Low": "7.983",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Jun-15",
      "Price": "7.983",
      "Open": "7.983",
      "High": "7.983",
      "Low": "7.983",
      "Change %": "-0.34%"
  },
  {
      "Date": "4-Jun-15",
      "Price": "8.01",
      "Open": "8.01",
      "High": "8.01",
      "Low": "8.01",
      "Change %": "0.74%"
  },
  {
      "Date": "3-Jun-15",
      "Price": "7.951",
      "Open": "7.951",
      "High": "7.951",
      "Low": "7.951",
      "Change %": "0.23%"
  },
  {
      "Date": "2-Jun-15",
      "Price": "7.933",
      "Open": "7.933",
      "High": "7.933",
      "Low": "7.933",
      "Change %": "1.42%"
  },
  {
      "Date": "1-Jun-15",
      "Price": "7.822",
      "Open": "7.822",
      "High": "7.822",
      "Low": "7.822",
      "Change %": "0.08%"
  },
  {
      "Date": "30-May-15",
      "Price": "7.816",
      "Open": "7.816",
      "High": "7.816",
      "Low": "7.816",
      "Change %": "0.01%"
  },
  {
      "Date": "29-May-15",
      "Price": "7.815",
      "Open": "7.815",
      "High": "7.815",
      "Low": "7.815",
      "Change %": "-0.50%"
  },
  {
      "Date": "28-May-15",
      "Price": "7.854",
      "Open": "7.854",
      "High": "7.854",
      "Low": "7.854",
      "Change %": "-0.18%"
  },
  {
      "Date": "27-May-15",
      "Price": "7.868",
      "Open": "7.868",
      "High": "7.868",
      "Low": "7.868",
      "Change %": "-0.29%"
  },
  {
      "Date": "26-May-15",
      "Price": "7.891",
      "Open": "7.891",
      "High": "7.891",
      "Low": "7.891",
      "Change %": "0.34%"
  },
  {
      "Date": "25-May-15",
      "Price": "7.864",
      "Open": "7.864",
      "High": "7.864",
      "Low": "7.864",
      "Change %": "0.08%"
  },
  {
      "Date": "22-May-15",
      "Price": "7.858",
      "Open": "7.858",
      "High": "7.858",
      "Low": "7.858",
      "Change %": "-0.23%"
  },
  {
      "Date": "21-May-15",
      "Price": "7.876",
      "Open": "7.876",
      "High": "7.876",
      "Low": "7.876",
      "Change %": "0.27%"
  },
  {
      "Date": "20-May-15",
      "Price": "7.855",
      "Open": "7.855",
      "High": "7.855",
      "Low": "7.855",
      "Change %": "-0.04%"
  },
  {
      "Date": "19-May-15",
      "Price": "7.858",
      "Open": "7.858",
      "High": "7.858",
      "Low": "7.858",
      "Change %": "-0.51%"
  },
  {
      "Date": "18-May-15",
      "Price": "7.898",
      "Open": "7.898",
      "High": "7.898",
      "Low": "7.898",
      "Change %": "-0.60%"
  },
  {
      "Date": "15-May-15",
      "Price": "7.946",
      "Open": "7.946",
      "High": "7.946",
      "Low": "7.946",
      "Change %": "0.09%"
  },
  {
      "Date": "14-May-15",
      "Price": "7.939",
      "Open": "7.939",
      "High": "7.939",
      "Low": "7.939",
      "Change %": "-0.23%"
  },
  {
      "Date": "13-May-15",
      "Price": "7.957",
      "Open": "7.957",
      "High": "7.957",
      "Low": "7.957",
      "Change %": "0.09%"
  },
  {
      "Date": "12-May-15",
      "Price": "7.95",
      "Open": "7.95",
      "High": "7.95",
      "Low": "7.95",
      "Change %": "0.81%"
  },
  {
      "Date": "11-May-15",
      "Price": "7.886",
      "Open": "7.886",
      "High": "7.886",
      "Low": "7.886",
      "Change %": "-1.14%"
  },
  {
      "Date": "8-May-15",
      "Price": "7.977",
      "Open": "7.977",
      "High": "7.977",
      "Low": "7.977",
      "Change %": "-0.20%"
  },
  {
      "Date": "7-May-15",
      "Price": "7.993",
      "Open": "7.993",
      "High": "7.993",
      "Low": "7.993",
      "Change %": "1.29%"
  },
  {
      "Date": "6-May-15",
      "Price": "7.891",
      "Open": "7.891",
      "High": "7.891",
      "Low": "7.891",
      "Change %": "0.54%"
  },
  {
      "Date": "5-May-15",
      "Price": "7.849",
      "Open": "7.849",
      "High": "7.849",
      "Low": "7.849",
      "Change %": "-0.14%"
  },
  {
      "Date": "30-Apr-15",
      "Price": "7.86",
      "Open": "7.86",
      "High": "7.86",
      "Low": "7.86",
      "Change %": "0.58%"
  },
  {
      "Date": "29-Apr-15",
      "Price": "7.815",
      "Open": "7.815",
      "High": "7.815",
      "Low": "7.815",
      "Change %": "0.70%"
  },
  {
      "Date": "28-Apr-15",
      "Price": "7.761",
      "Open": "7.761",
      "High": "7.761",
      "Low": "7.761",
      "Change %": "-0.22%"
  },
  {
      "Date": "27-Apr-15",
      "Price": "7.778",
      "Open": "7.778",
      "High": "7.778",
      "Low": "7.778",
      "Change %": "-0.12%"
  },
  {
      "Date": "24-Apr-15",
      "Price": "7.787",
      "Open": "7.787",
      "High": "7.787",
      "Low": "7.787",
      "Change %": "0.39%"
  },
  {
      "Date": "23-Apr-15",
      "Price": "7.757",
      "Open": "7.757",
      "High": "7.757",
      "Low": "7.757",
      "Change %": "0.05%"
  },
  {
      "Date": "22-Apr-15",
      "Price": "7.753",
      "Open": "7.753",
      "High": "7.753",
      "Low": "7.753",
      "Change %": "-0.26%"
  },
  {
      "Date": "21-Apr-15",
      "Price": "7.773",
      "Open": "7.773",
      "High": "7.773",
      "Low": "7.773",
      "Change %": "-0.27%"
  },
  {
      "Date": "20-Apr-15",
      "Price": "7.794",
      "Open": "7.794",
      "High": "7.794",
      "Low": "7.794",
      "Change %": "0.04%"
  },
  {
      "Date": "17-Apr-15",
      "Price": "7.791",
      "Open": "7.791",
      "High": "7.791",
      "Low": "7.791",
      "Change %": "-0.10%"
  },
  {
      "Date": "16-Apr-15",
      "Price": "7.799",
      "Open": "7.799",
      "High": "7.799",
      "Low": "7.799",
      "Change %": "0.22%"
  },
  {
      "Date": "15-Apr-15",
      "Price": "7.782",
      "Open": "7.782",
      "High": "7.782",
      "Low": "7.782",
      "Change %": "-0.18%"
  },
  {
      "Date": "13-Apr-15",
      "Price": "7.796",
      "Open": "7.796",
      "High": "7.796",
      "Low": "7.796",
      "Change %": "-0.06%"
  },
  {
      "Date": "10-Apr-15",
      "Price": "7.801",
      "Open": "7.801",
      "High": "7.801",
      "Low": "7.801",
      "Change %": "0.35%"
  },
  {
      "Date": "9-Apr-15",
      "Price": "7.774",
      "Open": "7.774",
      "High": "7.774",
      "Low": "7.774",
      "Change %": "-0.15%"
  },
  {
      "Date": "8-Apr-15",
      "Price": "7.786",
      "Open": "7.786",
      "High": "7.786",
      "Low": "7.786",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Apr-15",
      "Price": "7.786",
      "Open": "7.786",
      "High": "7.786",
      "Low": "7.786",
      "Change %": "0.84%"
  },
  {
      "Date": "6-Apr-15",
      "Price": "7.721",
      "Open": "7.721",
      "High": "7.721",
      "Low": "7.721",
      "Change %": "-0.22%"
  },
  {
      "Date": "31-Mar-15",
      "Price": "7.738",
      "Open": "7.738",
      "High": "7.738",
      "Low": "7.738",
      "Change %": "-0.30%"
  },
  {
      "Date": "30-Mar-15",
      "Price": "7.761",
      "Open": "7.761",
      "High": "7.761",
      "Low": "7.761",
      "Change %": "-0.19%"
  },
  {
      "Date": "27-Mar-15",
      "Price": "7.776",
      "Open": "7.776",
      "High": "7.776",
      "Low": "7.776",
      "Change %": "-0.27%"
  },
  {
      "Date": "26-Mar-15",
      "Price": "7.797",
      "Open": "7.797",
      "High": "7.797",
      "Low": "7.797",
      "Change %": "0.32%"
  },
  {
      "Date": "25-Mar-15",
      "Price": "7.772",
      "Open": "7.772",
      "High": "7.772",
      "Low": "7.772",
      "Change %": "0.28%"
  },
  {
      "Date": "24-Mar-15",
      "Price": "7.75",
      "Open": "7.75",
      "High": "7.75",
      "Low": "7.75",
      "Change %": "-0.08%"
  },
  {
      "Date": "23-Mar-15",
      "Price": "7.756",
      "Open": "7.756",
      "High": "7.756",
      "Low": "7.756",
      "Change %": "0.13%"
  },
  {
      "Date": "20-Mar-15",
      "Price": "7.746",
      "Open": "7.746",
      "High": "7.746",
      "Low": "7.746",
      "Change %": "-0.13%"
  },
  {
      "Date": "19-Mar-15",
      "Price": "7.756",
      "Open": "7.756",
      "High": "7.756",
      "Low": "7.756",
      "Change %": "-0.46%"
  },
  {
      "Date": "18-Mar-15",
      "Price": "7.792",
      "Open": "7.792",
      "High": "7.792",
      "Low": "7.792",
      "Change %": "0.13%"
  },
  {
      "Date": "17-Mar-15",
      "Price": "7.782",
      "Open": "7.782",
      "High": "7.782",
      "Low": "7.782",
      "Change %": "-0.28%"
  },
  {
      "Date": "16-Mar-15",
      "Price": "7.804",
      "Open": "7.804",
      "High": "7.804",
      "Low": "7.804",
      "Change %": "0.10%"
  },
  {
      "Date": "13-Mar-15",
      "Price": "7.796",
      "Open": "7.796",
      "High": "7.796",
      "Low": "7.796",
      "Change %": "0.96%"
  },
  {
      "Date": "12-Mar-15",
      "Price": "7.722",
      "Open": "7.722",
      "High": "7.722",
      "Low": "7.722",
      "Change %": "-0.48%"
  },
  {
      "Date": "11-Mar-15",
      "Price": "7.759",
      "Open": "7.759",
      "High": "7.759",
      "Low": "7.759",
      "Change %": "0.15%"
  },
  {
      "Date": "10-Mar-15",
      "Price": "7.747",
      "Open": "7.747",
      "High": "7.747",
      "Low": "7.747",
      "Change %": "0.09%"
  },
  {
      "Date": "9-Mar-15",
      "Price": "7.74",
      "Open": "7.74",
      "High": "7.74",
      "Low": "7.74",
      "Change %": "0.45%"
  },
  {
      "Date": "5-Mar-15",
      "Price": "7.705",
      "Open": "7.705",
      "High": "7.705",
      "Low": "7.705",
      "Change %": "0.26%"
  },
  {
      "Date": "4-Mar-15",
      "Price": "7.685",
      "Open": "7.685",
      "High": "7.685",
      "Low": "7.685",
      "Change %": "-0.89%"
  },
  {
      "Date": "3-Mar-15",
      "Price": "7.754",
      "Open": "7.754",
      "High": "7.754",
      "Low": "7.754",
      "Change %": "0.17%"
  },
  {
      "Date": "2-Mar-15",
      "Price": "7.741",
      "Open": "7.741",
      "High": "7.741",
      "Low": "7.741",
      "Change %": "0.22%"
  },
  {
      "Date": "27-Feb-15",
      "Price": "7.724",
      "Open": "7.724",
      "High": "7.724",
      "Low": "7.724",
      "Change %": "-0.18%"
  },
  {
      "Date": "26-Feb-15",
      "Price": "7.738",
      "Open": "7.738",
      "High": "7.738",
      "Low": "7.738",
      "Change %": "0.42%"
  },
  {
      "Date": "25-Feb-15",
      "Price": "7.706",
      "Open": "7.706",
      "High": "7.706",
      "Low": "7.706",
      "Change %": "-0.13%"
  },
  {
      "Date": "24-Feb-15",
      "Price": "7.716",
      "Open": "7.716",
      "High": "7.716",
      "Low": "7.716",
      "Change %": "0.18%"
  },
  {
      "Date": "23-Feb-15",
      "Price": "7.702",
      "Open": "7.702",
      "High": "7.702",
      "Low": "7.702",
      "Change %": "0.09%"
  },
  {
      "Date": "20-Feb-15",
      "Price": "7.695",
      "Open": "7.695",
      "High": "7.695",
      "Low": "7.695",
      "Change %": "-0.19%"
  },
  {
      "Date": "19-Feb-15",
      "Price": "7.71",
      "Open": "7.71",
      "High": "7.71",
      "Low": "7.71",
      "Change %": "0.03%"
  },
  {
      "Date": "18-Feb-15",
      "Price": "7.708",
      "Open": "7.708",
      "High": "7.708",
      "Low": "7.708",
      "Change %": "0.01%"
  },
  {
      "Date": "16-Feb-15",
      "Price": "7.707",
      "Open": "7.707",
      "High": "7.707",
      "Low": "7.707",
      "Change %": "0.13%"
  },
  {
      "Date": "13-Feb-15",
      "Price": "7.697",
      "Open": "7.697",
      "High": "7.697",
      "Low": "7.697",
      "Change %": "-0.59%"
  },
  {
      "Date": "12-Feb-15",
      "Price": "7.743",
      "Open": "7.743",
      "High": "7.743",
      "Low": "7.743",
      "Change %": "0.14%"
  },
  {
      "Date": "11-Feb-15",
      "Price": "7.732",
      "Open": "7.732",
      "High": "7.732",
      "Low": "7.732",
      "Change %": "0.05%"
  },
  {
      "Date": "10-Feb-15",
      "Price": "7.728",
      "Open": "7.728",
      "High": "7.728",
      "Low": "7.728",
      "Change %": "0.00%"
  },
  {
      "Date": "9-Feb-15",
      "Price": "7.728",
      "Open": "7.728",
      "High": "7.728",
      "Low": "7.728",
      "Change %": "0.36%"
  },
  {
      "Date": "6-Feb-15",
      "Price": "7.7",
      "Open": "7.7",
      "High": "7.7",
      "Low": "7.7",
      "Change %": "0.01%"
  },
  {
      "Date": "5-Feb-15",
      "Price": "7.699",
      "Open": "7.699",
      "High": "7.699",
      "Low": "7.699",
      "Change %": "-0.23%"
  },
  {
      "Date": "4-Feb-15",
      "Price": "7.717",
      "Open": "7.717",
      "High": "7.717",
      "Low": "7.717",
      "Change %": "-0.13%"
  },
  {
      "Date": "3-Feb-15",
      "Price": "7.727",
      "Open": "7.727",
      "High": "7.727",
      "Low": "7.727",
      "Change %": "0.98%"
  },
  {
      "Date": "2-Feb-15",
      "Price": "7.652",
      "Open": "7.652",
      "High": "7.652",
      "Low": "7.652",
      "Change %": "-0.53%"
  },
  {
      "Date": "1-Feb-15",
      "Price": "7.693",
      "Open": "7.693",
      "High": "7.693",
      "Low": "7.693",
      "Change %": "0.03%"
  },
  {
      "Date": "30-Jan-15",
      "Price": "7.691",
      "Open": "7.691",
      "High": "7.691",
      "Low": "7.691",
      "Change %": "-0.26%"
  },
  {
      "Date": "29-Jan-15",
      "Price": "7.711",
      "Open": "7.711",
      "High": "7.711",
      "Low": "7.711",
      "Change %": "0.08%"
  },
  {
      "Date": "28-Jan-15",
      "Price": "7.705",
      "Open": "7.705",
      "High": "7.705",
      "Low": "7.705",
      "Change %": "0.01%"
  },
  {
      "Date": "27-Jan-15",
      "Price": "7.704",
      "Open": "7.704",
      "High": "7.704",
      "Low": "7.704",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Jan-15",
      "Price": "7.704",
      "Open": "7.704",
      "High": "7.704",
      "Low": "7.704",
      "Change %": "-0.10%"
  },
  {
      "Date": "22-Jan-15",
      "Price": "7.712",
      "Open": "7.712",
      "High": "7.712",
      "Low": "7.712",
      "Change %": "0.29%"
  },
  {
      "Date": "21-Jan-15",
      "Price": "7.69",
      "Open": "7.69",
      "High": "7.69",
      "Low": "7.69",
      "Change %": "-0.43%"
  },
  {
      "Date": "20-Jan-15",
      "Price": "7.723",
      "Open": "7.723",
      "High": "7.723",
      "Low": "7.723",
      "Change %": "0.00%"
  },
  {
      "Date": "19-Jan-15",
      "Price": "7.723",
      "Open": "7.723",
      "High": "7.723",
      "Low": "7.723",
      "Change %": "0.22%"
  },
  {
      "Date": "16-Jan-15",
      "Price": "7.706",
      "Open": "7.706",
      "High": "7.706",
      "Low": "7.706",
      "Change %": "0.16%"
  },
  {
      "Date": "15-Jan-15",
      "Price": "7.694",
      "Open": "7.694",
      "High": "7.694",
      "Low": "7.694",
      "Change %": "-1.00%"
  },
  {
      "Date": "14-Jan-15",
      "Price": "7.772",
      "Open": "7.772",
      "High": "7.772",
      "Low": "7.772",
      "Change %": "0.05%"
  },
  {
      "Date": "13-Jan-15",
      "Price": "7.768",
      "Open": "7.768",
      "High": "7.768",
      "Low": "7.768",
      "Change %": "-0.56%"
  },
  {
      "Date": "12-Jan-15",
      "Price": "7.812",
      "Open": "7.812",
      "High": "7.812",
      "Low": "7.812",
      "Change %": "-0.41%"
  },
  {
      "Date": "9-Jan-15",
      "Price": "7.844",
      "Open": "7.844",
      "High": "7.844",
      "Low": "7.844",
      "Change %": "-0.20%"
  },
  {
      "Date": "8-Jan-15",
      "Price": "7.86",
      "Open": "7.86",
      "High": "7.86",
      "Low": "7.86",
      "Change %": "0.05%"
  },
  {
      "Date": "7-Jan-15",
      "Price": "7.856",
      "Open": "7.856",
      "High": "7.856",
      "Low": "7.856",
      "Change %": "-0.54%"
  },
  {
      "Date": "6-Jan-15",
      "Price": "7.899",
      "Open": "7.899",
      "High": "7.899",
      "Low": "7.899",
      "Change %": "0.09%"
  },
  {
      "Date": "5-Jan-15",
      "Price": "7.892",
      "Open": "7.892",
      "High": "7.892",
      "Low": "7.892",
      "Change %": "0.25%"
  },
  {
      "Date": "2-Jan-15",
      "Price": "7.872",
      "Open": "7.872",
      "High": "7.872",
      "Low": "7.872",
      "Change %": "-0.10%"
  },
  {
      "Date": "1-Jan-15",
      "Price": "7.88",
      "Open": "7.88",
      "High": "7.88",
      "Low": "7.88",
      "Change %": "0.32%"
  },
  {
      "Date": "31-Dec-14",
      "Price": "7.855",
      "Open": "7.855",
      "High": "7.855",
      "Low": "7.855",
      "Change %": "-0.23%"
  },
  {
      "Date": "30-Dec-14",
      "Price": "7.873",
      "Open": "7.873",
      "High": "7.873",
      "Low": "7.873",
      "Change %": "-0.68%"
  },
  {
      "Date": "29-Dec-14",
      "Price": "7.927",
      "Open": "7.927",
      "High": "7.927",
      "Low": "7.927",
      "Change %": "-0.68%"
  },
  {
      "Date": "28-Dec-14",
      "Price": "7.981",
      "Open": "7.981",
      "High": "7.981",
      "Low": "7.981",
      "Change %": "0.04%"
  },
  {
      "Date": "26-Dec-14",
      "Price": "7.978",
      "Open": "7.978",
      "High": "7.978",
      "Low": "7.978",
      "Change %": "0.23%"
  },
  {
      "Date": "24-Dec-14",
      "Price": "7.96",
      "Open": "7.96",
      "High": "7.96",
      "Low": "7.96",
      "Change %": "0.48%"
  },
  {
      "Date": "23-Dec-14",
      "Price": "7.922",
      "Open": "7.922",
      "High": "7.922",
      "Low": "7.922",
      "Change %": "-0.40%"
  },
  {
      "Date": "22-Dec-14",
      "Price": "7.954",
      "Open": "7.954",
      "High": "7.954",
      "Low": "7.954",
      "Change %": "-0.08%"
  },
  {
      "Date": "19-Dec-14",
      "Price": "7.96",
      "Open": "7.96",
      "High": "7.96",
      "Low": "7.96",
      "Change %": "0.40%"
  },
  {
      "Date": "18-Dec-14",
      "Price": "7.928",
      "Open": "7.928",
      "High": "7.928",
      "Low": "7.928",
      "Change %": "-0.51%"
  },
  {
      "Date": "17-Dec-14",
      "Price": "7.969",
      "Open": "7.969",
      "High": "7.969",
      "Low": "7.969",
      "Change %": "-0.23%"
  },
  {
      "Date": "16-Dec-14",
      "Price": "7.987",
      "Open": "7.987",
      "High": "7.987",
      "Low": "7.987",
      "Change %": "1.98%"
  },
  {
      "Date": "15-Dec-14",
      "Price": "7.832",
      "Open": "7.832",
      "High": "7.832",
      "Low": "7.832",
      "Change %": "0.01%"
  },
  {
      "Date": "14-Dec-14",
      "Price": "7.831",
      "Open": "7.831",
      "High": "7.831",
      "Low": "7.831",
      "Change %": "0.01%"
  },
  {
      "Date": "12-Dec-14",
      "Price": "7.83",
      "Open": "7.83",
      "High": "7.83",
      "Low": "7.83",
      "Change %": "-0.46%"
  },
  {
      "Date": "11-Dec-14",
      "Price": "7.866",
      "Open": "7.866",
      "High": "7.866",
      "Low": "7.866",
      "Change %": "-0.53%"
  },
  {
      "Date": "10-Dec-14",
      "Price": "7.908",
      "Open": "7.908",
      "High": "7.908",
      "Low": "7.908",
      "Change %": "0.08%"
  },
  {
      "Date": "9-Dec-14",
      "Price": "7.902",
      "Open": "7.902",
      "High": "7.902",
      "Low": "7.902",
      "Change %": "-0.19%"
  },
  {
      "Date": "8-Dec-14",
      "Price": "7.917",
      "Open": "7.917",
      "High": "7.917",
      "Low": "7.917",
      "Change %": "-0.24%"
  },
  {
      "Date": "5-Dec-14",
      "Price": "7.936",
      "Open": "7.936",
      "High": "7.936",
      "Low": "7.936",
      "Change %": "-0.39%"
  },
  {
      "Date": "4-Dec-14",
      "Price": "7.967",
      "Open": "7.967",
      "High": "7.967",
      "Low": "7.967",
      "Change %": "-0.05%"
  },
  {
      "Date": "3-Dec-14",
      "Price": "7.971",
      "Open": "7.971",
      "High": "7.971",
      "Low": "7.971",
      "Change %": "0.03%"
  },
  {
      "Date": "2-Dec-14",
      "Price": "7.969",
      "Open": "7.969",
      "High": "7.969",
      "Low": "7.969",
      "Change %": "-1.12%"
  },
  {
      "Date": "1-Dec-14",
      "Price": "8.059",
      "Open": "8.059",
      "High": "8.059",
      "Low": "8.059",
      "Change %": "-0.35%"
  },
  {
      "Date": "30-Nov-14",
      "Price": "8.087",
      "Open": "8.087",
      "High": "8.087",
      "Low": "8.087",
      "Change %": "0.00%"
  },
  {
      "Date": "28-Nov-14",
      "Price": "8.087",
      "Open": "8.087",
      "High": "8.087",
      "Low": "8.087",
      "Change %": "-0.74%"
  },
  {
      "Date": "27-Nov-14",
      "Price": "8.147",
      "Open": "8.147",
      "High": "8.147",
      "Low": "8.147",
      "Change %": "0.05%"
  },
  {
      "Date": "26-Nov-14",
      "Price": "8.143",
      "Open": "8.143",
      "High": "8.143",
      "Low": "8.143",
      "Change %": "-0.18%"
  },
  {
      "Date": "25-Nov-14",
      "Price": "8.158",
      "Open": "8.158",
      "High": "8.158",
      "Low": "8.158",
      "Change %": "-0.05%"
  },
  {
      "Date": "24-Nov-14",
      "Price": "8.162",
      "Open": "8.162",
      "High": "8.162",
      "Low": "8.162",
      "Change %": "-0.07%"
  },
  {
      "Date": "21-Nov-14",
      "Price": "8.168",
      "Open": "8.168",
      "High": "8.168",
      "Low": "8.168",
      "Change %": "0.12%"
  },
  {
      "Date": "20-Nov-14",
      "Price": "8.158",
      "Open": "8.158",
      "High": "8.158",
      "Low": "8.158",
      "Change %": "-0.07%"
  },
  {
      "Date": "19-Nov-14",
      "Price": "8.164",
      "Open": "8.164",
      "High": "8.164",
      "Low": "8.164",
      "Change %": "0.13%"
  },
  {
      "Date": "18-Nov-14",
      "Price": "8.153",
      "Open": "8.153",
      "High": "8.153",
      "Low": "8.153",
      "Change %": "-0.34%"
  },
  {
      "Date": "17-Nov-14",
      "Price": "8.181",
      "Open": "8.181",
      "High": "8.181",
      "Low": "8.181",
      "Change %": "-0.49%"
  },
  {
      "Date": "16-Nov-14",
      "Price": "8.221",
      "Open": "8.221",
      "High": "8.221",
      "Low": "8.221",
      "Change %": "0.07%"
  },
  {
      "Date": "14-Nov-14",
      "Price": "8.215",
      "Open": "8.215",
      "High": "8.215",
      "Low": "8.215",
      "Change %": "-0.07%"
  },
  {
      "Date": "13-Nov-14",
      "Price": "8.221",
      "Open": "8.221",
      "High": "8.221",
      "Low": "8.221",
      "Change %": "0.77%"
  },
  {
      "Date": "12-Nov-14",
      "Price": "8.158",
      "Open": "8.158",
      "High": "8.158",
      "Low": "8.158",
      "Change %": "-0.33%"
  },
  {
      "Date": "11-Nov-14",
      "Price": "8.185",
      "Open": "8.185",
      "High": "8.185",
      "Low": "8.185",
      "Change %": "0.09%"
  },
  {
      "Date": "10-Nov-14",
      "Price": "8.178",
      "Open": "8.178",
      "High": "8.178",
      "Low": "8.178",
      "Change %": "-0.38%"
  },
  {
      "Date": "7-Nov-14",
      "Price": "8.209",
      "Open": "8.209",
      "High": "8.209",
      "Low": "8.209",
      "Change %": "0.22%"
  },
  {
      "Date": "5-Nov-14",
      "Price": "8.191",
      "Open": "8.191",
      "High": "8.191",
      "Low": "8.191",
      "Change %": "-0.82%"
  },
  {
      "Date": "3-Nov-14",
      "Price": "8.259",
      "Open": "8.259",
      "High": "8.259",
      "Low": "8.259",
      "Change %": "-0.23%"
  },
  {
      "Date": "31-Oct-14",
      "Price": "8.278",
      "Open": "8.278",
      "High": "8.278",
      "Low": "8.278",
      "Change %": "-0.13%"
  },
  {
      "Date": "30-Oct-14",
      "Price": "8.289",
      "Open": "8.289",
      "High": "8.289",
      "Low": "8.289",
      "Change %": "-0.35%"
  },
  {
      "Date": "29-Oct-14",
      "Price": "8.318",
      "Open": "8.318",
      "High": "8.318",
      "Low": "8.318",
      "Change %": "-0.05%"
  },
  {
      "Date": "28-Oct-14",
      "Price": "8.322",
      "Open": "8.322",
      "High": "8.322",
      "Low": "8.322",
      "Change %": "0.07%"
  },
  {
      "Date": "27-Oct-14",
      "Price": "8.316",
      "Open": "8.316",
      "High": "8.316",
      "Low": "8.316",
      "Change %": "-0.48%"
  },
  {
      "Date": "22-Oct-14",
      "Price": "8.356",
      "Open": "8.356",
      "High": "8.356",
      "Low": "8.356",
      "Change %": "-0.18%"
  },
  {
      "Date": "21-Oct-14",
      "Price": "8.371",
      "Open": "8.371",
      "High": "8.371",
      "Low": "8.371",
      "Change %": "0.14%"
  },
  {
      "Date": "20-Oct-14",
      "Price": "8.359",
      "Open": "8.359",
      "High": "8.359",
      "Low": "8.359",
      "Change %": "-0.36%"
  },
  {
      "Date": "17-Oct-14",
      "Price": "8.389",
      "Open": "8.389",
      "High": "8.389",
      "Low": "8.389",
      "Change %": "0.23%"
  },
  {
      "Date": "16-Oct-14",
      "Price": "8.37",
      "Open": "8.37",
      "High": "8.37",
      "Low": "8.37",
      "Change %": "-0.33%"
  },
  {
      "Date": "15-Oct-14",
      "Price": "8.398",
      "Open": "8.398",
      "High": "8.398",
      "Low": "8.398",
      "Change %": "0.01%"
  },
  {
      "Date": "14-Oct-14",
      "Price": "8.397",
      "Open": "8.397",
      "High": "8.397",
      "Low": "8.397",
      "Change %": "-0.25%"
  },
  {
      "Date": "13-Oct-14",
      "Price": "8.418",
      "Open": "8.418",
      "High": "8.418",
      "Low": "8.418",
      "Change %": "-0.45%"
  },
  {
      "Date": "10-Oct-14",
      "Price": "8.456",
      "Open": "8.456",
      "High": "8.456",
      "Low": "8.456",
      "Change %": "-0.11%"
  },
  {
      "Date": "9-Oct-14",
      "Price": "8.465",
      "Open": "8.465",
      "High": "8.465",
      "Low": "8.465",
      "Change %": "0.68%"
  },
  {
      "Date": "8-Oct-14",
      "Price": "8.408",
      "Open": "8.408",
      "High": "8.408",
      "Low": "8.408",
      "Change %": "-0.53%"
  },
  {
      "Date": "7-Oct-14",
      "Price": "8.453",
      "Open": "8.453",
      "High": "8.453",
      "Low": "8.453",
      "Change %": "-0.28%"
  },
  {
      "Date": "1-Oct-14",
      "Price": "8.477",
      "Open": "8.477",
      "High": "8.477",
      "Low": "8.477",
      "Change %": "-0.40%"
  },
  {
      "Date": "30-Sep-14",
      "Price": "8.511",
      "Open": "8.511",
      "High": "8.511",
      "Low": "8.511",
      "Change %": "0.25%"
  },
  {
      "Date": "29-Sep-14",
      "Price": "8.49",
      "Open": "8.49",
      "High": "8.49",
      "Low": "8.49",
      "Change %": "0.56%"
  },
  {
      "Date": "27-Sep-14",
      "Price": "8.443",
      "Open": "8.443",
      "High": "8.443",
      "Low": "8.443",
      "Change %": "0.02%"
  },
  {
      "Date": "26-Sep-14",
      "Price": "8.441",
      "Open": "8.441",
      "High": "8.441",
      "Low": "8.441",
      "Change %": "-0.54%"
  },
  {
      "Date": "25-Sep-14",
      "Price": "8.487",
      "Open": "8.487",
      "High": "8.487",
      "Low": "8.487",
      "Change %": "0.07%"
  },
  {
      "Date": "24-Sep-14",
      "Price": "8.481",
      "Open": "8.481",
      "High": "8.481",
      "Low": "8.481",
      "Change %": "0.17%"
  },
  {
      "Date": "23-Sep-14",
      "Price": "8.467",
      "Open": "8.467",
      "High": "8.467",
      "Low": "8.467",
      "Change %": "0.38%"
  },
  {
      "Date": "22-Sep-14",
      "Price": "8.435",
      "Open": "8.435",
      "High": "8.435",
      "Low": "8.435",
      "Change %": "-0.24%"
  },
  {
      "Date": "19-Sep-14",
      "Price": "8.455",
      "Open": "8.455",
      "High": "8.455",
      "Low": "8.455",
      "Change %": "0.02%"
  },
  {
      "Date": "18-Sep-14",
      "Price": "8.453",
      "Open": "8.453",
      "High": "8.453",
      "Low": "8.453",
      "Change %": "-0.53%"
  },
  {
      "Date": "17-Sep-14",
      "Price": "8.498",
      "Open": "8.498",
      "High": "8.498",
      "Low": "8.498",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Sep-14",
      "Price": "8.498",
      "Open": "8.498",
      "High": "8.498",
      "Low": "8.498",
      "Change %": "-0.07%"
  },
  {
      "Date": "15-Sep-14",
      "Price": "8.504",
      "Open": "8.504",
      "High": "8.504",
      "Low": "8.504",
      "Change %": "-0.01%"
  },
  {
      "Date": "13-Sep-14",
      "Price": "8.505",
      "Open": "8.505",
      "High": "8.505",
      "Low": "8.505",
      "Change %": "0.05%"
  },
  {
      "Date": "12-Sep-14",
      "Price": "8.501",
      "Open": "8.501",
      "High": "8.501",
      "Low": "8.501",
      "Change %": "-0.05%"
  },
  {
      "Date": "11-Sep-14",
      "Price": "8.505",
      "Open": "8.505",
      "High": "8.505",
      "Low": "8.505",
      "Change %": "-0.44%"
  },
  {
      "Date": "10-Sep-14",
      "Price": "8.543",
      "Open": "8.543",
      "High": "8.543",
      "Low": "8.543",
      "Change %": "0.25%"
  },
  {
      "Date": "9-Sep-14",
      "Price": "8.522",
      "Open": "8.522",
      "High": "8.522",
      "Low": "8.522",
      "Change %": "0.24%"
  },
  {
      "Date": "8-Sep-14",
      "Price": "8.502",
      "Open": "8.502",
      "High": "8.502",
      "Low": "8.502",
      "Change %": "-0.23%"
  },
  {
      "Date": "5-Sep-14",
      "Price": "8.522",
      "Open": "8.522",
      "High": "8.522",
      "Low": "8.522",
      "Change %": "0.00%"
  },
  {
      "Date": "4-Sep-14",
      "Price": "8.522",
      "Open": "8.522",
      "High": "8.522",
      "Low": "8.522",
      "Change %": "0.00%"
  },
  {
      "Date": "3-Sep-14",
      "Price": "8.522",
      "Open": "8.522",
      "High": "8.522",
      "Low": "8.522",
      "Change %": "0.04%"
  },
  {
      "Date": "2-Sep-14",
      "Price": "8.519",
      "Open": "8.519",
      "High": "8.519",
      "Low": "8.519",
      "Change %": "-0.36%"
  },
  {
      "Date": "1-Sep-14",
      "Price": "8.55",
      "Open": "8.55",
      "High": "8.55",
      "Low": "8.55",
      "Change %": "-0.18%"
  },
  {
      "Date": "31-Aug-14",
      "Price": "8.565",
      "Open": "8.565",
      "High": "8.565",
      "Low": "8.565",
      "Change %": "0.07%"
  },
  {
      "Date": "28-Aug-14",
      "Price": "8.559",
      "Open": "8.559",
      "High": "8.559",
      "Low": "8.559",
      "Change %": "0.04%"
  },
  {
      "Date": "27-Aug-14",
      "Price": "8.556",
      "Open": "8.556",
      "High": "8.556",
      "Low": "8.556",
      "Change %": "0.02%"
  },
  {
      "Date": "26-Aug-14",
      "Price": "8.554",
      "Open": "8.554",
      "High": "8.554",
      "Low": "8.554",
      "Change %": "-0.02%"
  },
  {
      "Date": "25-Aug-14",
      "Price": "8.556",
      "Open": "8.556",
      "High": "8.556",
      "Low": "8.556",
      "Change %": "0.47%"
  },
  {
      "Date": "22-Aug-14",
      "Price": "8.516",
      "Open": "8.516",
      "High": "8.516",
      "Low": "8.516",
      "Change %": "0.05%"
  },
  {
      "Date": "21-Aug-14",
      "Price": "8.512",
      "Open": "8.512",
      "High": "8.512",
      "Low": "8.512",
      "Change %": "0.39%"
  },
  {
      "Date": "20-Aug-14",
      "Price": "8.479",
      "Open": "8.479",
      "High": "8.479",
      "Low": "8.479",
      "Change %": "-0.62%"
  },
  {
      "Date": "19-Aug-14",
      "Price": "8.532",
      "Open": "8.532",
      "High": "8.532",
      "Low": "8.532",
      "Change %": "-2.08%"
  },
  {
      "Date": "14-Aug-14",
      "Price": "8.713",
      "Open": "8.713",
      "High": "8.713",
      "Low": "8.713",
      "Change %": "-0.58%"
  },
  {
      "Date": "13-Aug-14",
      "Price": "8.764",
      "Open": "8.764",
      "High": "8.764",
      "Low": "8.764",
      "Change %": "-0.92%"
  },
  {
      "Date": "12-Aug-14",
      "Price": "8.845",
      "Open": "8.845",
      "High": "8.845",
      "Low": "8.845",
      "Change %": "0.32%"
  },
  {
      "Date": "11-Aug-14",
      "Price": "8.817",
      "Open": "8.817",
      "High": "8.817",
      "Low": "8.817",
      "Change %": "-0.43%"
  },
  {
      "Date": "10-Aug-14",
      "Price": "8.855",
      "Open": "8.855",
      "High": "8.855",
      "Low": "8.855",
      "Change %": "0.03%"
  },
  {
      "Date": "8-Aug-14",
      "Price": "8.852",
      "Open": "8.852",
      "High": "8.852",
      "Low": "8.852",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Aug-14",
      "Price": "8.852",
      "Open": "8.852",
      "High": "8.852",
      "Low": "8.852",
      "Change %": "-0.15%"
  },
  {
      "Date": "6-Aug-14",
      "Price": "8.865",
      "Open": "8.865",
      "High": "8.865",
      "Low": "8.865",
      "Change %": "0.35%"
  },
  {
      "Date": "5-Aug-14",
      "Price": "8.834",
      "Open": "8.834",
      "High": "8.834",
      "Low": "8.834",
      "Change %": "1.18%"
  },
  {
      "Date": "4-Aug-14",
      "Price": "8.731",
      "Open": "8.731",
      "High": "8.731",
      "Low": "8.731",
      "Change %": "-0.27%"
  },
  {
      "Date": "2-Aug-14",
      "Price": "8.755",
      "Open": "8.755",
      "High": "8.755",
      "Low": "8.755",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Aug-14",
      "Price": "8.755",
      "Open": "8.755",
      "High": "8.755",
      "Low": "8.755",
      "Change %": "0.42%"
  },
  {
      "Date": "31-Jul-14",
      "Price": "8.718",
      "Open": "8.718",
      "High": "8.718",
      "Low": "8.718",
      "Change %": "-0.11%"
  },
  {
      "Date": "30-Jul-14",
      "Price": "8.728",
      "Open": "8.728",
      "High": "8.728",
      "Low": "8.728",
      "Change %": "0.24%"
  },
  {
      "Date": "28-Jul-14",
      "Price": "8.707",
      "Open": "8.707",
      "High": "8.707",
      "Low": "8.707",
      "Change %": "0.42%"
  },
  {
      "Date": "27-Jul-14",
      "Price": "8.671",
      "Open": "8.671",
      "High": "8.671",
      "Low": "8.671",
      "Change %": "0.00%"
  },
  {
      "Date": "25-Jul-14",
      "Price": "8.671",
      "Open": "8.671",
      "High": "8.671",
      "Low": "8.671",
      "Change %": "0.27%"
  },
  {
      "Date": "24-Jul-14",
      "Price": "8.648",
      "Open": "8.648",
      "High": "8.648",
      "Low": "8.648",
      "Change %": "-0.14%"
  },
  {
      "Date": "23-Jul-14",
      "Price": "8.66",
      "Open": "8.66",
      "High": "8.66",
      "Low": "8.66",
      "Change %": "-0.35%"
  },
  {
      "Date": "22-Jul-14",
      "Price": "8.69",
      "Open": "8.69",
      "High": "8.69",
      "Low": "8.69",
      "Change %": "-0.02%"
  },
  {
      "Date": "21-Jul-14",
      "Price": "8.692",
      "Open": "8.692",
      "High": "8.692",
      "Low": "8.692",
      "Change %": "-0.90%"
  },
  {
      "Date": "20-Jul-14",
      "Price": "8.771",
      "Open": "8.771",
      "High": "8.771",
      "Low": "8.771",
      "Change %": "0.02%"
  },
  {
      "Date": "18-Jul-14",
      "Price": "8.769",
      "Open": "8.769",
      "High": "8.769",
      "Low": "8.769",
      "Change %": "0.38%"
  },
  {
      "Date": "17-Jul-14",
      "Price": "8.736",
      "Open": "8.736",
      "High": "8.736",
      "Low": "8.736",
      "Change %": "0.14%"
  },
  {
      "Date": "16-Jul-14",
      "Price": "8.724",
      "Open": "8.724",
      "High": "8.724",
      "Low": "8.724",
      "Change %": "-0.13%"
  },
  {
      "Date": "15-Jul-14",
      "Price": "8.735",
      "Open": "8.735",
      "High": "8.735",
      "Low": "8.735",
      "Change %": "-0.52%"
  },
  {
      "Date": "14-Jul-14",
      "Price": "8.781",
      "Open": "8.781",
      "High": "8.781",
      "Low": "8.781",
      "Change %": "0.11%"
  },
  {
      "Date": "12-Jul-14",
      "Price": "8.771",
      "Open": "8.771",
      "High": "8.771",
      "Low": "8.771",
      "Change %": "0.05%"
  },
  {
      "Date": "11-Jul-14",
      "Price": "8.767",
      "Open": "8.767",
      "High": "8.767",
      "Low": "8.767",
      "Change %": "0.02%"
  },
  {
      "Date": "10-Jul-14",
      "Price": "8.765",
      "Open": "8.765",
      "High": "8.765",
      "Low": "8.765",
      "Change %": "0.44%"
  },
  {
      "Date": "9-Jul-14",
      "Price": "8.727",
      "Open": "8.727",
      "High": "8.727",
      "Low": "8.727",
      "Change %": "0.00%"
  },
  {
      "Date": "8-Jul-14",
      "Price": "8.727",
      "Open": "8.727",
      "High": "8.727",
      "Low": "8.727",
      "Change %": "0.36%"
  },
  {
      "Date": "7-Jul-14",
      "Price": "8.696",
      "Open": "8.696",
      "High": "8.696",
      "Low": "8.696",
      "Change %": "0.38%"
  },
  {
      "Date": "4-Jul-14",
      "Price": "8.663",
      "Open": "8.663",
      "High": "8.663",
      "Low": "8.663",
      "Change %": "0.07%"
  },
  {
      "Date": "3-Jul-14",
      "Price": "8.657",
      "Open": "8.657",
      "High": "8.657",
      "Low": "8.657",
      "Change %": "0.00%"
  },
  {
      "Date": "2-Jul-14",
      "Price": "8.657",
      "Open": "8.657",
      "High": "8.657",
      "Low": "8.657",
      "Change %": "-0.92%"
  },
  {
      "Date": "1-Jul-14",
      "Price": "8.737",
      "Open": "8.737",
      "High": "8.737",
      "Low": "8.737",
      "Change %": "-0.08%"
  },
  {
      "Date": "30-Jun-14",
      "Price": "8.744",
      "Open": "8.744",
      "High": "8.744",
      "Low": "8.744",
      "Change %": "-0.03%"
  },
  {
      "Date": "27-Jun-14",
      "Price": "8.747",
      "Open": "8.747",
      "High": "8.747",
      "Low": "8.747",
      "Change %": "0.18%"
  },
  {
      "Date": "26-Jun-14",
      "Price": "8.731",
      "Open": "8.731",
      "High": "8.731",
      "Low": "8.731",
      "Change %": "0.33%"
  },
  {
      "Date": "25-Jun-14",
      "Price": "8.702",
      "Open": "8.702",
      "High": "8.702",
      "Low": "8.702",
      "Change %": "-0.17%"
  },
  {
      "Date": "24-Jun-14",
      "Price": "8.717",
      "Open": "8.717",
      "High": "8.717",
      "Low": "8.717",
      "Change %": "-0.64%"
  },
  {
      "Date": "23-Jun-14",
      "Price": "8.773",
      "Open": "8.773",
      "High": "8.773",
      "Low": "8.773",
      "Change %": "0.56%"
  },
  {
      "Date": "20-Jun-14",
      "Price": "8.724",
      "Open": "8.724",
      "High": "8.724",
      "Low": "8.724",
      "Change %": "0.41%"
  },
  {
      "Date": "19-Jun-14",
      "Price": "8.688",
      "Open": "8.688",
      "High": "8.688",
      "Low": "8.688",
      "Change %": "0.20%"
  },
  {
      "Date": "18-Jun-14",
      "Price": "8.671",
      "Open": "8.671",
      "High": "8.671",
      "Low": "8.671",
      "Change %": "0.85%"
  },
  {
      "Date": "17-Jun-14",
      "Price": "8.598",
      "Open": "8.598",
      "High": "8.598",
      "Low": "8.598",
      "Change %": "-0.59%"
  },
  {
      "Date": "16-Jun-14",
      "Price": "8.649",
      "Open": "8.649",
      "High": "8.649",
      "Low": "8.649",
      "Change %": "0.63%"
  },
  {
      "Date": "13-Jun-14",
      "Price": "8.595",
      "Open": "8.595",
      "High": "8.595",
      "Low": "8.595",
      "Change %": "0.56%"
  },
  {
      "Date": "12-Jun-14",
      "Price": "8.547",
      "Open": "8.547",
      "High": "8.547",
      "Low": "8.547",
      "Change %": "0.14%"
  },
  {
      "Date": "11-Jun-14",
      "Price": "8.535",
      "Open": "8.535",
      "High": "8.535",
      "Low": "8.535",
      "Change %": "-0.30%"
  },
  {
      "Date": "10-Jun-14",
      "Price": "8.561",
      "Open": "8.561",
      "High": "8.561",
      "Low": "8.561",
      "Change %": "0.15%"
  },
  {
      "Date": "9-Jun-14",
      "Price": "8.548",
      "Open": "8.548",
      "High": "8.548",
      "Low": "8.548",
      "Change %": "0.42%"
  },
  {
      "Date": "6-Jun-14",
      "Price": "8.512",
      "Open": "8.512",
      "High": "8.512",
      "Low": "8.512",
      "Change %": "-0.16%"
  },
  {
      "Date": "5-Jun-14",
      "Price": "8.526",
      "Open": "8.526",
      "High": "8.526",
      "Low": "8.526",
      "Change %": "-0.71%"
  },
  {
      "Date": "4-Jun-14",
      "Price": "8.587",
      "Open": "8.587",
      "High": "8.587",
      "Low": "8.587",
      "Change %": "-0.13%"
  },
  {
      "Date": "3-Jun-14",
      "Price": "8.598",
      "Open": "8.598",
      "High": "8.598",
      "Low": "8.598",
      "Change %": "-0.72%"
  },
  {
      "Date": "2-Jun-14",
      "Price": "8.66",
      "Open": "8.66",
      "High": "8.66",
      "Low": "8.66",
      "Change %": "0.17%"
  },
  {
      "Date": "30-May-14",
      "Price": "8.645",
      "Open": "8.645",
      "High": "8.645",
      "Low": "8.645",
      "Change %": "-0.30%"
  },
  {
      "Date": "29-May-14",
      "Price": "8.671",
      "Open": "8.671",
      "High": "8.671",
      "Low": "8.671",
      "Change %": "-0.37%"
  },
  {
      "Date": "28-May-14",
      "Price": "8.703",
      "Open": "8.703",
      "High": "8.703",
      "Low": "8.703",
      "Change %": "0.35%"
  },
  {
      "Date": "27-May-14",
      "Price": "8.673",
      "Open": "8.673",
      "High": "8.673",
      "Low": "8.673",
      "Change %": "-0.02%"
  },
  {
      "Date": "26-May-14",
      "Price": "8.675",
      "Open": "8.675",
      "High": "8.675",
      "Low": "8.675",
      "Change %": "0.44%"
  },
  {
      "Date": "25-May-14",
      "Price": "8.637",
      "Open": "8.637",
      "High": "8.637",
      "Low": "8.637",
      "Change %": "0.00%"
  },
  {
      "Date": "23-May-14",
      "Price": "8.637",
      "Open": "8.637",
      "High": "8.637",
      "Low": "8.637",
      "Change %": "-0.85%"
  },
  {
      "Date": "22-May-14",
      "Price": "8.711",
      "Open": "8.711",
      "High": "8.711",
      "Low": "8.711",
      "Change %": "-0.65%"
  },
  {
      "Date": "21-May-14",
      "Price": "8.768",
      "Open": "8.768",
      "High": "8.768",
      "Low": "8.768",
      "Change %": "-0.98%"
  },
  {
      "Date": "20-May-14",
      "Price": "8.855",
      "Open": "8.855",
      "High": "8.855",
      "Low": "8.855",
      "Change %": "-0.09%"
  },
  {
      "Date": "19-May-14",
      "Price": "8.863",
      "Open": "8.863",
      "High": "8.863",
      "Low": "8.863",
      "Change %": "0.33%"
  },
  {
      "Date": "16-May-14",
      "Price": "8.834",
      "Open": "8.834",
      "High": "8.834",
      "Low": "8.834",
      "Change %": "0.60%"
  },
  {
      "Date": "15-May-14",
      "Price": "8.781",
      "Open": "8.781",
      "High": "8.781",
      "Low": "8.781",
      "Change %": "-0.03%"
  },
  {
      "Date": "13-May-14",
      "Price": "8.784",
      "Open": "8.784",
      "High": "8.784",
      "Low": "8.784",
      "Change %": "0.65%"
  },
  {
      "Date": "12-May-14",
      "Price": "8.727",
      "Open": "8.727",
      "High": "8.727",
      "Low": "8.727",
      "Change %": "-0.22%"
  },
  {
      "Date": "9-May-14",
      "Price": "8.746",
      "Open": "8.746",
      "High": "8.746",
      "Low": "8.746",
      "Change %": "-0.14%"
  },
  {
      "Date": "8-May-14",
      "Price": "8.758",
      "Open": "8.758",
      "High": "8.758",
      "Low": "8.758",
      "Change %": "-0.50%"
  },
  {
      "Date": "7-May-14",
      "Price": "8.802",
      "Open": "8.802",
      "High": "8.802",
      "Low": "8.802",
      "Change %": "0.27%"
  },
  {
      "Date": "6-May-14",
      "Price": "8.778",
      "Open": "8.778",
      "High": "8.778",
      "Low": "8.778",
      "Change %": "0.49%"
  },
  {
      "Date": "5-May-14",
      "Price": "8.735",
      "Open": "8.735",
      "High": "8.735",
      "Low": "8.735",
      "Change %": "-0.83%"
  },
  {
      "Date": "2-May-14",
      "Price": "8.808",
      "Open": "8.808",
      "High": "8.808",
      "Low": "8.808",
      "Change %": "-0.23%"
  },
  {
      "Date": "30-Apr-14",
      "Price": "8.828",
      "Open": "8.828",
      "High": "8.828",
      "Low": "8.828",
      "Change %": "0.00%"
  },
  {
      "Date": "29-Apr-14",
      "Price": "8.828",
      "Open": "8.828",
      "High": "8.828",
      "Low": "8.828",
      "Change %": "-0.28%"
  },
  {
      "Date": "28-Apr-14",
      "Price": "8.853",
      "Open": "8.853",
      "High": "8.853",
      "Low": "8.853",
      "Change %": "-0.34%"
  },
  {
      "Date": "25-Apr-14",
      "Price": "8.883",
      "Open": "8.883",
      "High": "8.883",
      "Low": "8.883",
      "Change %": "0.36%"
  },
  {
      "Date": "23-Apr-14",
      "Price": "8.851",
      "Open": "8.851",
      "High": "8.851",
      "Low": "8.851",
      "Change %": "-0.03%"
  },
  {
      "Date": "22-Apr-14",
      "Price": "8.854",
      "Open": "8.854",
      "High": "8.854",
      "Low": "8.854",
      "Change %": "-0.09%"
  },
  {
      "Date": "21-Apr-14",
      "Price": "8.862",
      "Open": "8.862",
      "High": "8.862",
      "Low": "8.862",
      "Change %": "0.14%"
  },
  {
      "Date": "17-Apr-14",
      "Price": "8.85",
      "Open": "8.85",
      "High": "8.85",
      "Low": "8.85",
      "Change %": "-1.27%"
  },
  {
      "Date": "16-Apr-14",
      "Price": "8.964",
      "Open": "8.964",
      "High": "8.964",
      "Low": "8.964",
      "Change %": "0.12%"
  },
  {
      "Date": "15-Apr-14",
      "Price": "8.953",
      "Open": "8.953",
      "High": "8.953",
      "Low": "8.953",
      "Change %": "0.13%"
  },
  {
      "Date": "11-Apr-14",
      "Price": "8.941",
      "Open": "8.941",
      "High": "8.941",
      "Low": "8.941",
      "Change %": "-0.68%"
  },
  {
      "Date": "10-Apr-14",
      "Price": "9.002",
      "Open": "9.002",
      "High": "9.002",
      "Low": "9.002",
      "Change %": "-0.33%"
  },
  {
      "Date": "9-Apr-14",
      "Price": "9.032",
      "Open": "9.032",
      "High": "9.032",
      "Low": "9.032",
      "Change %": "-0.76%"
  },
  {
      "Date": "7-Apr-14",
      "Price": "9.101",
      "Open": "9.101",
      "High": "9.101",
      "Low": "9.101",
      "Change %": "0.40%"
  },
  {
      "Date": "4-Apr-14",
      "Price": "9.065",
      "Open": "9.065",
      "High": "9.065",
      "Low": "9.065",
      "Change %": "0.63%"
  },
  {
      "Date": "3-Apr-14",
      "Price": "9.008",
      "Open": "9.008",
      "High": "9.008",
      "Low": "9.008",
      "Change %": "0.51%"
  },
  {
      "Date": "2-Apr-14",
      "Price": "8.962",
      "Open": "8.962",
      "High": "8.962",
      "Low": "8.962",
      "Change %": "1.73%"
  },
  {
      "Date": "1-Apr-14",
      "Price": "8.81",
      "Open": "8.81",
      "High": "8.81",
      "Low": "8.81",
      "Change %": "0.07%"
  },
  {
      "Date": "28-Mar-14",
      "Price": "8.804",
      "Open": "8.804",
      "High": "8.804",
      "Low": "8.804",
      "Change %": "-0.14%"
  },
  {
      "Date": "27-Mar-14",
      "Price": "8.816",
      "Open": "8.816",
      "High": "8.816",
      "Low": "8.816",
      "Change %": "0.38%"
  },
  {
      "Date": "26-Mar-14",
      "Price": "8.783",
      "Open": "8.783",
      "High": "8.783",
      "Low": "8.783",
      "Change %": "-0.06%"
  },
  {
      "Date": "25-Mar-14",
      "Price": "8.788",
      "Open": "8.788",
      "High": "8.788",
      "Low": "8.788",
      "Change %": "0.06%"
  },
  {
      "Date": "24-Mar-14",
      "Price": "8.783",
      "Open": "8.783",
      "High": "8.783",
      "Low": "8.783",
      "Change %": "-0.14%"
  },
  {
      "Date": "21-Mar-14",
      "Price": "8.795",
      "Open": "8.795",
      "High": "8.795",
      "Low": "8.795",
      "Change %": "-0.27%"
  },
  {
      "Date": "20-Mar-14",
      "Price": "8.819",
      "Open": "8.819",
      "High": "8.819",
      "Low": "8.819",
      "Change %": "0.48%"
  },
  {
      "Date": "19-Mar-14",
      "Price": "8.777",
      "Open": "8.777",
      "High": "8.777",
      "Low": "8.777",
      "Change %": "-0.26%"
  },
  {
      "Date": "18-Mar-14",
      "Price": "8.8",
      "Open": "8.8",
      "High": "8.8",
      "Low": "8.8",
      "Change %": "0.10%"
  },
  {
      "Date": "14-Mar-14",
      "Price": "8.791",
      "Open": "8.791",
      "High": "8.791",
      "Low": "8.791",
      "Change %": "0.64%"
  },
  {
      "Date": "13-Mar-14",
      "Price": "8.735",
      "Open": "8.735",
      "High": "8.735",
      "Low": "8.735",
      "Change %": "0.23%"
  },
  {
      "Date": "12-Mar-14",
      "Price": "8.715",
      "Open": "8.715",
      "High": "8.715",
      "Low": "8.715",
      "Change %": "-0.21%"
  },
  {
      "Date": "11-Mar-14",
      "Price": "8.733",
      "Open": "8.733",
      "High": "8.733",
      "Low": "8.733",
      "Change %": "-0.22%"
  },
  {
      "Date": "10-Mar-14",
      "Price": "8.752",
      "Open": "8.752",
      "High": "8.752",
      "Low": "8.752",
      "Change %": "-0.68%"
  },
  {
      "Date": "9-Mar-14",
      "Price": "8.812",
      "Open": "8.812",
      "High": "8.812",
      "Low": "8.812",
      "Change %": "0.01%"
  },
  {
      "Date": "7-Mar-14",
      "Price": "8.811",
      "Open": "8.811",
      "High": "8.811",
      "Low": "8.811",
      "Change %": "0.22%"
  },
  {
      "Date": "6-Mar-14",
      "Price": "8.792",
      "Open": "8.792",
      "High": "8.792",
      "Low": "8.792",
      "Change %": "-0.57%"
  },
  {
      "Date": "5-Mar-14",
      "Price": "8.842",
      "Open": "8.842",
      "High": "8.842",
      "Low": "8.842",
      "Change %": "0.07%"
  },
  {
      "Date": "4-Mar-14",
      "Price": "8.836",
      "Open": "8.836",
      "High": "8.836",
      "Low": "8.836",
      "Change %": "-0.76%"
  },
  {
      "Date": "3-Mar-14",
      "Price": "8.904",
      "Open": "8.904",
      "High": "8.904",
      "Low": "8.904",
      "Change %": "0.47%"
  },
  {
      "Date": "28-Feb-14",
      "Price": "8.862",
      "Open": "8.862",
      "High": "8.862",
      "Low": "8.862",
      "Change %": "-0.59%"
  },
  {
      "Date": "26-Feb-14",
      "Price": "8.915",
      "Open": "8.915",
      "High": "8.915",
      "Low": "8.915",
      "Change %": "0.45%"
  },
  {
      "Date": "25-Feb-14",
      "Price": "8.875",
      "Open": "8.875",
      "High": "8.875",
      "Low": "8.875",
      "Change %": "-0.11%"
  },
  {
      "Date": "24-Feb-14",
      "Price": "8.885",
      "Open": "8.885",
      "High": "8.885",
      "Low": "8.885",
      "Change %": "1.02%"
  },
  {
      "Date": "21-Feb-14",
      "Price": "8.795",
      "Open": "8.795",
      "High": "8.795",
      "Low": "8.795",
      "Change %": "0.08%"
  },
  {
      "Date": "20-Feb-14",
      "Price": "8.788",
      "Open": "8.788",
      "High": "8.788",
      "Low": "8.788",
      "Change %": "0.23%"
  },
  {
      "Date": "18-Feb-14",
      "Price": "8.768",
      "Open": "8.768",
      "High": "8.768",
      "Low": "8.768",
      "Change %": "-0.42%"
  },
  {
      "Date": "17-Feb-14",
      "Price": "8.805",
      "Open": "8.805",
      "High": "8.805",
      "Low": "8.805",
      "Change %": "-0.03%"
  },
  {
      "Date": "14-Feb-14",
      "Price": "8.808",
      "Open": "8.808",
      "High": "8.808",
      "Low": "8.808",
      "Change %": "-0.64%"
  },
  {
      "Date": "13-Feb-14",
      "Price": "8.865",
      "Open": "8.865",
      "High": "8.865",
      "Low": "8.865",
      "Change %": "0.61%"
  },
  {
      "Date": "12-Feb-14",
      "Price": "8.811",
      "Open": "8.811",
      "High": "8.811",
      "Low": "8.811",
      "Change %": "0.81%"
  },
  {
      "Date": "11-Feb-14",
      "Price": "8.74",
      "Open": "8.74",
      "High": "8.74",
      "Low": "8.74",
      "Change %": "0.58%"
  },
  {
      "Date": "10-Feb-14",
      "Price": "8.69",
      "Open": "8.69",
      "High": "8.69",
      "Low": "8.69",
      "Change %": "-0.53%"
  },
  {
      "Date": "7-Feb-14",
      "Price": "8.736",
      "Open": "8.736",
      "High": "8.736",
      "Low": "8.736",
      "Change %": "0.22%"
  },
  {
      "Date": "6-Feb-14",
      "Price": "8.717",
      "Open": "8.717",
      "High": "8.717",
      "Low": "8.717",
      "Change %": "0.11%"
  },
  {
      "Date": "5-Feb-14",
      "Price": "8.707",
      "Open": "8.707",
      "High": "8.707",
      "Low": "8.707",
      "Change %": "0.32%"
  },
  {
      "Date": "4-Feb-14",
      "Price": "8.679",
      "Open": "8.679",
      "High": "8.679",
      "Low": "8.679",
      "Change %": "-0.63%"
  },
  {
      "Date": "3-Feb-14",
      "Price": "8.734",
      "Open": "8.734",
      "High": "8.734",
      "Low": "8.734",
      "Change %": "-0.41%"
  },
  {
      "Date": "31-Jan-14",
      "Price": "8.77",
      "Open": "8.77",
      "High": "8.77",
      "Low": "8.77",
      "Change %": "-0.57%"
  },
  {
      "Date": "30-Jan-14",
      "Price": "8.82",
      "Open": "8.82",
      "High": "8.82",
      "Low": "8.82",
      "Change %": "0.52%"
  },
  {
      "Date": "29-Jan-14",
      "Price": "8.774",
      "Open": "8.774",
      "High": "8.774",
      "Low": "8.774",
      "Change %": "0.30%"
  },
  {
      "Date": "28-Jan-14",
      "Price": "8.748",
      "Open": "8.748",
      "High": "8.748",
      "Low": "8.748",
      "Change %": "-0.19%"
  },
  {
      "Date": "27-Jan-14",
      "Price": "8.765",
      "Open": "8.765",
      "High": "8.765",
      "Low": "8.765",
      "Change %": "0.31%"
  },
  {
      "Date": "24-Jan-14",
      "Price": "8.738",
      "Open": "8.738",
      "High": "8.738",
      "Low": "8.738",
      "Change %": "0.84%"
  },
  {
      "Date": "23-Jan-14",
      "Price": "8.665",
      "Open": "8.665",
      "High": "8.665",
      "Low": "8.665",
      "Change %": "0.66%"
  },
  {
      "Date": "22-Jan-14",
      "Price": "8.608",
      "Open": "8.608",
      "High": "8.608",
      "Low": "8.608",
      "Change %": "0.64%"
  },
  {
      "Date": "21-Jan-14",
      "Price": "8.553",
      "Open": "8.553",
      "High": "8.553",
      "Low": "8.553",
      "Change %": "0.42%"
  },
  {
      "Date": "20-Jan-14",
      "Price": "8.517",
      "Open": "8.517",
      "High": "8.517",
      "Low": "8.517",
      "Change %": "-1.28%"
  },
  {
      "Date": "17-Jan-14",
      "Price": "8.627",
      "Open": "8.627",
      "High": "8.627",
      "Low": "8.627",
      "Change %": "0.14%"
  },
  {
      "Date": "16-Jan-14",
      "Price": "8.615",
      "Open": "8.615",
      "High": "8.615",
      "Low": "8.615",
      "Change %": "-0.28%"
  },
  {
      "Date": "15-Jan-14",
      "Price": "8.639",
      "Open": "8.639",
      "High": "8.639",
      "Low": "8.639",
      "Change %": "-0.83%"
  },
  {
      "Date": "13-Jan-14",
      "Price": "8.711",
      "Open": "8.711",
      "High": "8.711",
      "Low": "8.711",
      "Change %": "-0.54%"
  },
  {
      "Date": "10-Jan-14",
      "Price": "8.758",
      "Open": "8.758",
      "High": "8.758",
      "Low": "8.758",
      "Change %": "-0.36%"
  },
  {
      "Date": "9-Jan-14",
      "Price": "8.79",
      "Open": "8.79",
      "High": "8.79",
      "Low": "8.79",
      "Change %": "-0.12%"
  },
  {
      "Date": "8-Jan-14",
      "Price": "8.801",
      "Open": "8.801",
      "High": "8.801",
      "Low": "8.801",
      "Change %": "0.14%"
  },
  {
      "Date": "7-Jan-14",
      "Price": "8.789",
      "Open": "8.789",
      "High": "8.789",
      "Low": "8.789",
      "Change %": "0.07%"
  },
  {
      "Date": "6-Jan-14",
      "Price": "8.783",
      "Open": "8.783",
      "High": "8.783",
      "Low": "8.783",
      "Change %": "-0.58%"
  },
  {
      "Date": "3-Jan-14",
      "Price": "8.834",
      "Open": "8.834",
      "High": "8.834",
      "Low": "8.834",
      "Change %": "-0.07%"
  },
  {
      "Date": "2-Jan-14",
      "Price": "8.84",
      "Open": "8.84",
      "High": "8.84",
      "Low": "8.84",
      "Change %": "-0.05%"
  },
  {
      "Date": "1-Jan-14",
      "Price": "8.844",
      "Open": "8.844",
      "High": "8.844",
      "Low": "8.844",
      "Change %": "0.27%"
  },
  {
      "Date": "31-Dec-13",
      "Price": "8.82",
      "Open": "8.82",
      "High": "8.82",
      "Low": "8.82",
      "Change %": "-0.43%"
  },
  {
      "Date": "30-Dec-13",
      "Price": "8.858",
      "Open": "8.858",
      "High": "8.858",
      "Low": "8.858",
      "Change %": "-1.13%"
  },
  {
      "Date": "27-Dec-13",
      "Price": "8.959",
      "Open": "8.959",
      "High": "8.959",
      "Low": "8.959",
      "Change %": "0.79%"
  },
  {
      "Date": "26-Dec-13",
      "Price": "8.889",
      "Open": "8.889",
      "High": "8.889",
      "Low": "8.889",
      "Change %": "0.23%"
  },
  {
      "Date": "25-Dec-13",
      "Price": "8.869",
      "Open": "8.869",
      "High": "8.869",
      "Low": "8.869",
      "Change %": "0.03%"
  },
  {
      "Date": "24-Dec-13",
      "Price": "8.866",
      "Open": "8.866",
      "High": "8.866",
      "Low": "8.866",
      "Change %": "0.56%"
  },
  {
      "Date": "23-Dec-13",
      "Price": "8.817",
      "Open": "8.817",
      "High": "8.817",
      "Low": "8.817",
      "Change %": "0.23%"
  },
  {
      "Date": "20-Dec-13",
      "Price": "8.797",
      "Open": "8.797",
      "High": "8.797",
      "Low": "8.797",
      "Change %": "0.62%"
  },
  {
      "Date": "19-Dec-13",
      "Price": "8.743",
      "Open": "8.743",
      "High": "8.743",
      "Low": "8.743",
      "Change %": "-0.47%"
  },
  {
      "Date": "18-Dec-13",
      "Price": "8.784",
      "Open": "8.784",
      "High": "8.784",
      "Low": "8.784",
      "Change %": "-1.47%"
  },
  {
      "Date": "17-Dec-13",
      "Price": "8.915",
      "Open": "8.915",
      "High": "8.915",
      "Low": "8.915",
      "Change %": "0.56%"
  },
  {
      "Date": "16-Dec-13",
      "Price": "8.865",
      "Open": "8.865",
      "High": "8.865",
      "Low": "8.865",
      "Change %": "-0.52%"
  },
  {
      "Date": "13-Dec-13",
      "Price": "8.911",
      "Open": "8.911",
      "High": "8.911",
      "Low": "8.911",
      "Change %": "0.69%"
  },
  {
      "Date": "12-Dec-13",
      "Price": "8.85",
      "Open": "8.85",
      "High": "8.85",
      "Low": "8.85",
      "Change %": "0.27%"
  },
  {
      "Date": "11-Dec-13",
      "Price": "8.826",
      "Open": "8.826",
      "High": "8.826",
      "Low": "8.826",
      "Change %": "-0.10%"
  },
  {
      "Date": "10-Dec-13",
      "Price": "8.835",
      "Open": "8.835",
      "High": "8.835",
      "Low": "8.835",
      "Change %": "-0.77%"
  },
  {
      "Date": "9-Dec-13",
      "Price": "8.904",
      "Open": "8.904",
      "High": "8.904",
      "Low": "8.904",
      "Change %": "-2.85%"
  },
  {
      "Date": "6-Dec-13",
      "Price": "9.165",
      "Open": "9.165",
      "High": "9.165",
      "Low": "9.165",
      "Change %": "0.63%"
  },
  {
      "Date": "5-Dec-13",
      "Price": "9.108",
      "Open": "9.108",
      "High": "9.108",
      "Low": "9.108",
      "Change %": "0.20%"
  },
  {
      "Date": "4-Dec-13",
      "Price": "9.09",
      "Open": "9.09",
      "High": "9.09",
      "Low": "9.09",
      "Change %": "0.23%"
  },
  {
      "Date": "3-Dec-13",
      "Price": "9.069",
      "Open": "9.069",
      "High": "9.069",
      "Low": "9.069",
      "Change %": "0.23%"
  },
  {
      "Date": "2-Dec-13",
      "Price": "9.048",
      "Open": "9.048",
      "High": "9.048",
      "Low": "9.048",
      "Change %": "0.04%"
  },
  {
      "Date": "29-Nov-13",
      "Price": "9.044",
      "Open": "9.044",
      "High": "9.044",
      "Low": "9.044",
      "Change %": "0.33%"
  },
  {
      "Date": "28-Nov-13",
      "Price": "9.014",
      "Open": "9.014",
      "High": "9.014",
      "Low": "9.014",
      "Change %": "0.20%"
  },
  {
      "Date": "27-Nov-13",
      "Price": "8.996",
      "Open": "8.996",
      "High": "8.996",
      "Low": "8.996",
      "Change %": "-0.51%"
  },
  {
      "Date": "26-Nov-13",
      "Price": "9.042",
      "Open": "9.042",
      "High": "9.042",
      "Low": "9.042",
      "Change %": "-0.50%"
  },
  {
      "Date": "25-Nov-13",
      "Price": "9.087",
      "Open": "9.087",
      "High": "9.087",
      "Low": "9.087",
      "Change %": "-0.13%"
  },
  {
      "Date": "22-Nov-13",
      "Price": "9.099",
      "Open": "9.099",
      "High": "9.099",
      "Low": "9.099",
      "Change %": "0.24%"
  },
  {
      "Date": "21-Nov-13",
      "Price": "9.077",
      "Open": "9.077",
      "High": "9.077",
      "Low": "9.077",
      "Change %": "0.46%"
  },
  {
      "Date": "20-Nov-13",
      "Price": "9.035",
      "Open": "9.035",
      "High": "9.035",
      "Low": "9.035",
      "Change %": "0.29%"
  },
  {
      "Date": "19-Nov-13",
      "Price": "9.009",
      "Open": "9.009",
      "High": "9.009",
      "Low": "9.009",
      "Change %": "0.10%"
  },
  {
      "Date": "18-Nov-13",
      "Price": "9",
      "Open": "9",
      "High": "9",
      "Low": "9",
      "Change %": "-0.27%"
  },
  {
      "Date": "17-Nov-13",
      "Price": "9.024",
      "Open": "9.024",
      "High": "9.024",
      "Low": "9.024",
      "Change %": "0.00%"
  },
  {
      "Date": "15-Nov-13",
      "Price": "9.024",
      "Open": "9.024",
      "High": "9.024",
      "Low": "9.024",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Nov-13",
      "Price": "9.024",
      "Open": "9.024",
      "High": "9.024",
      "Low": "9.024",
      "Change %": "1.19%"
  },
  {
      "Date": "13-Nov-13",
      "Price": "8.918",
      "Open": "8.918",
      "High": "8.918",
      "Low": "8.918",
      "Change %": "-1.49%"
  },
  {
      "Date": "12-Nov-13",
      "Price": "9.053",
      "Open": "9.053",
      "High": "9.053",
      "Low": "9.053",
      "Change %": "1.11%"
  },
  {
      "Date": "11-Nov-13",
      "Price": "8.954",
      "Open": "8.954",
      "High": "8.954",
      "Low": "8.954",
      "Change %": "-0.40%"
  },
  {
      "Date": "8-Nov-13",
      "Price": "8.99",
      "Open": "8.99",
      "High": "8.99",
      "Low": "8.99",
      "Change %": "1.59%"
  },
  {
      "Date": "7-Nov-13",
      "Price": "8.849",
      "Open": "8.849",
      "High": "8.849",
      "Low": "8.849",
      "Change %": "0.33%"
  },
  {
      "Date": "6-Nov-13",
      "Price": "8.82",
      "Open": "8.82",
      "High": "8.82",
      "Low": "8.82",
      "Change %": "0.95%"
  },
  {
      "Date": "5-Nov-13",
      "Price": "8.737",
      "Open": "8.737",
      "High": "8.737",
      "Low": "8.737",
      "Change %": "0.37%"
  },
  {
      "Date": "4-Nov-13",
      "Price": "8.705",
      "Open": "8.705",
      "High": "8.705",
      "Low": "8.705",
      "Change %": "0.00%"
  },
  {
      "Date": "3-Nov-13",
      "Price": "8.705",
      "Open": "8.705",
      "High": "8.705",
      "Low": "8.705",
      "Change %": "0.06%"
  },
  {
      "Date": "1-Nov-13",
      "Price": "8.7",
      "Open": "8.7",
      "High": "8.7",
      "Low": "8.7",
      "Change %": "0.87%"
  },
  {
      "Date": "31-Oct-13",
      "Price": "8.625",
      "Open": "8.625",
      "High": "8.625",
      "Low": "8.625",
      "Change %": "0.61%"
  },
  {
      "Date": "30-Oct-13",
      "Price": "8.573",
      "Open": "8.573",
      "High": "8.573",
      "Low": "8.573",
      "Change %": "0.45%"
  },
  {
      "Date": "29-Oct-13",
      "Price": "8.535",
      "Open": "8.535",
      "High": "8.535",
      "Low": "8.535",
      "Change %": "-1.40%"
  },
  {
      "Date": "28-Oct-13",
      "Price": "8.656",
      "Open": "8.656",
      "High": "8.656",
      "Low": "8.656",
      "Change %": "0.90%"
  },
  {
      "Date": "27-Oct-13",
      "Price": "8.579",
      "Open": "8.579",
      "High": "8.579",
      "Low": "8.579",
      "Change %": "0.00%"
  },
  {
      "Date": "25-Oct-13",
      "Price": "8.579",
      "Open": "8.579",
      "High": "8.579",
      "Low": "8.579",
      "Change %": "-0.05%"
  },
  {
      "Date": "24-Oct-13",
      "Price": "8.583",
      "Open": "8.583",
      "High": "8.583",
      "Low": "8.583",
      "Change %": "-0.56%"
  },
  {
      "Date": "23-Oct-13",
      "Price": "8.631",
      "Open": "8.631",
      "High": "8.631",
      "Low": "8.631",
      "Change %": "0.29%"
  },
  {
      "Date": "22-Oct-13",
      "Price": "8.606",
      "Open": "8.606",
      "High": "8.606",
      "Low": "8.606",
      "Change %": "0.02%"
  },
  {
      "Date": "21-Oct-13",
      "Price": "8.604",
      "Open": "8.604",
      "High": "8.604",
      "Low": "8.604",
      "Change %": "0.61%"
  },
  {
      "Date": "18-Oct-13",
      "Price": "8.552",
      "Open": "8.552",
      "High": "8.552",
      "Low": "8.552",
      "Change %": "-0.58%"
  },
  {
      "Date": "17-Oct-13",
      "Price": "8.602",
      "Open": "8.602",
      "High": "8.602",
      "Low": "8.602",
      "Change %": "-0.70%"
  },
  {
      "Date": "16-Oct-13",
      "Price": "8.663",
      "Open": "8.663",
      "High": "8.663",
      "Low": "8.663",
      "Change %": "0.03%"
  },
  {
      "Date": "15-Oct-13",
      "Price": "8.66",
      "Open": "8.66",
      "High": "8.66",
      "Low": "8.66",
      "Change %": "0.99%"
  },
  {
      "Date": "14-Oct-13",
      "Price": "8.575",
      "Open": "8.575",
      "High": "8.575",
      "Low": "8.575",
      "Change %": "1.01%"
  },
  {
      "Date": "11-Oct-13",
      "Price": "8.489",
      "Open": "8.489",
      "High": "8.489",
      "Low": "8.489",
      "Change %": "0.81%"
  },
  {
      "Date": "10-Oct-13",
      "Price": "8.421",
      "Open": "8.421",
      "High": "8.421",
      "Low": "8.421",
      "Change %": "-0.40%"
  },
  {
      "Date": "9-Oct-13",
      "Price": "8.455",
      "Open": "8.455",
      "High": "8.455",
      "Low": "8.455",
      "Change %": "-0.48%"
  },
  {
      "Date": "8-Oct-13",
      "Price": "8.496",
      "Open": "8.496",
      "High": "8.496",
      "Low": "8.496",
      "Change %": "-2.15%"
  },
  {
      "Date": "7-Oct-13",
      "Price": "8.683",
      "Open": "8.683",
      "High": "8.683",
      "Low": "8.683",
      "Change %": "0.81%"
  },
  {
      "Date": "4-Oct-13",
      "Price": "8.613",
      "Open": "8.613",
      "High": "8.613",
      "Low": "8.613",
      "Change %": "-0.25%"
  },
  {
      "Date": "3-Oct-13",
      "Price": "8.635",
      "Open": "8.635",
      "High": "8.635",
      "Low": "8.635",
      "Change %": "-1.14%"
  },
  {
      "Date": "2-Oct-13",
      "Price": "8.735",
      "Open": "8.735",
      "High": "8.735",
      "Low": "8.735",
      "Change %": "0.08%"
  },
  {
      "Date": "1-Oct-13",
      "Price": "8.728",
      "Open": "8.728",
      "High": "8.728",
      "Low": "8.728",
      "Change %": "-0.43%"
  },
  {
      "Date": "30-Sep-13",
      "Price": "8.766",
      "Open": "8.766",
      "High": "8.766",
      "Low": "8.766",
      "Change %": "0.64%"
  },
  {
      "Date": "29-Sep-13",
      "Price": "8.71",
      "Open": "8.71",
      "High": "8.71",
      "Low": "8.71",
      "Change %": "0.00%"
  },
  {
      "Date": "27-Sep-13",
      "Price": "8.71",
      "Open": "8.71",
      "High": "8.71",
      "Low": "8.71",
      "Change %": "-0.09%"
  },
  {
      "Date": "26-Sep-13",
      "Price": "8.718",
      "Open": "8.718",
      "High": "8.718",
      "Low": "8.718",
      "Change %": "-0.77%"
  },
  {
      "Date": "25-Sep-13",
      "Price": "8.786",
      "Open": "8.786",
      "High": "8.786",
      "Low": "8.786",
      "Change %": "-0.60%"
  },
  {
      "Date": "24-Sep-13",
      "Price": "8.839",
      "Open": "8.839",
      "High": "8.839",
      "Low": "8.839",
      "Change %": "-0.14%"
  },
  {
      "Date": "23-Sep-13",
      "Price": "8.851",
      "Open": "8.851",
      "High": "8.851",
      "Low": "8.851",
      "Change %": "3.21%"
  },
  {
      "Date": "22-Sep-13",
      "Price": "8.576",
      "Open": "8.576",
      "High": "8.576",
      "Low": "8.576",
      "Change %": "-0.02%"
  },
  {
      "Date": "20-Sep-13",
      "Price": "8.578",
      "Open": "8.578",
      "High": "8.578",
      "Low": "8.578",
      "Change %": "4.70%"
  },
  {
      "Date": "19-Sep-13",
      "Price": "8.193",
      "Open": "8.193",
      "High": "8.193",
      "Low": "8.193",
      "Change %": "-2.14%"
  },
  {
      "Date": "18-Sep-13",
      "Price": "8.372",
      "Open": "8.372",
      "High": "8.372",
      "Low": "8.372",
      "Change %": "-0.78%"
  },
  {
      "Date": "17-Sep-13",
      "Price": "8.438",
      "Open": "8.438",
      "High": "8.438",
      "Low": "8.438",
      "Change %": "0.11%"
  },
  {
      "Date": "16-Sep-13",
      "Price": "8.429",
      "Open": "8.429",
      "High": "8.429",
      "Low": "8.429",
      "Change %": "-0.74%"
  },
  {
      "Date": "15-Sep-13",
      "Price": "8.492",
      "Open": "8.492",
      "High": "8.492",
      "Low": "8.492",
      "Change %": "0.06%"
  },
  {
      "Date": "13-Sep-13",
      "Price": "8.487",
      "Open": "8.487",
      "High": "8.487",
      "Low": "8.487",
      "Change %": "-0.15%"
  },
  {
      "Date": "12-Sep-13",
      "Price": "8.5",
      "Open": "8.5",
      "High": "8.5",
      "Low": "8.5",
      "Change %": "0.43%"
  },
  {
      "Date": "11-Sep-13",
      "Price": "8.464",
      "Open": "8.464",
      "High": "8.464",
      "Low": "8.464",
      "Change %": "-0.11%"
  },
  {
      "Date": "10-Sep-13",
      "Price": "8.473",
      "Open": "8.473",
      "High": "8.473",
      "Low": "8.473",
      "Change %": "-1.83%"
  },
  {
      "Date": "9-Sep-13",
      "Price": "8.631",
      "Open": "8.631",
      "High": "8.631",
      "Low": "8.631",
      "Change %": "0.00%"
  },
  {
      "Date": "8-Sep-13",
      "Price": "8.631",
      "Open": "8.631",
      "High": "8.631",
      "Low": "8.631",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Sep-13",
      "Price": "8.631",
      "Open": "8.631",
      "High": "8.631",
      "Low": "8.631",
      "Change %": "0.00%"
  },
  {
      "Date": "6-Sep-13",
      "Price": "8.631",
      "Open": "8.631",
      "High": "8.631",
      "Low": "8.631",
      "Change %": "2.53%"
  },
  {
      "Date": "5-Sep-13",
      "Price": "8.418",
      "Open": "8.418",
      "High": "8.418",
      "Low": "8.418",
      "Change %": "0.38%"
  },
  {
      "Date": "4-Sep-13",
      "Price": "8.386",
      "Open": "8.386",
      "High": "8.386",
      "Low": "8.386",
      "Change %": "-2.26%"
  },
  {
      "Date": "3-Sep-13",
      "Price": "8.58",
      "Open": "8.58",
      "High": "8.58",
      "Low": "8.58",
      "Change %": "1.38%"
  },
  {
      "Date": "2-Sep-13",
      "Price": "8.463",
      "Open": "8.463",
      "High": "8.463",
      "Low": "8.463",
      "Change %": "-1.62%"
  },
  {
      "Date": "1-Sep-13",
      "Price": "8.602",
      "Open": "8.602",
      "High": "8.602",
      "Low": "8.602",
      "Change %": "0.07%"
  },
  {
      "Date": "30-Aug-13",
      "Price": "8.596",
      "Open": "8.596",
      "High": "8.596",
      "Low": "8.596",
      "Change %": "-1.97%"
  },
  {
      "Date": "29-Aug-13",
      "Price": "8.769",
      "Open": "8.769",
      "High": "8.769",
      "Low": "8.769",
      "Change %": "-2.14%"
  },
  {
      "Date": "28-Aug-13",
      "Price": "8.961",
      "Open": "8.961",
      "High": "8.961",
      "Low": "8.961",
      "Change %": "2.00%"
  },
  {
      "Date": "27-Aug-13",
      "Price": "8.785",
      "Open": "8.785",
      "High": "8.785",
      "Low": "8.785",
      "Change %": "5.35%"
  },
  {
      "Date": "26-Aug-13",
      "Price": "8.339",
      "Open": "8.339",
      "High": "8.339",
      "Low": "8.339",
      "Change %": "0.91%"
  },
  {
      "Date": "23-Aug-13",
      "Price": "8.264",
      "Open": "8.264",
      "High": "8.264",
      "Low": "8.264",
      "Change %": "0.36%"
  },
  {
      "Date": "22-Aug-13",
      "Price": "8.234",
      "Open": "8.234",
      "High": "8.234",
      "Low": "8.234",
      "Change %": "-2.07%"
  },
  {
      "Date": "21-Aug-13",
      "Price": "8.408",
      "Open": "8.408",
      "High": "8.408",
      "Low": "8.408",
      "Change %": "-5.56%"
  },
  {
      "Date": "20-Aug-13",
      "Price": "8.903",
      "Open": "8.903",
      "High": "8.903",
      "Low": "8.903",
      "Change %": "-3.52%"
  },
  {
      "Date": "19-Aug-13",
      "Price": "9.228",
      "Open": "9.228",
      "High": "9.228",
      "Low": "9.228",
      "Change %": "3.87%"
  },
  {
      "Date": "16-Aug-13",
      "Price": "8.884",
      "Open": "8.884",
      "High": "8.884",
      "Low": "8.884",
      "Change %": "4.49%"
  },
  {
      "Date": "15-Aug-13",
      "Price": "8.502",
      "Open": "8.502",
      "High": "8.502",
      "Low": "8.502",
      "Change %": "0.04%"
  },
  {
      "Date": "14-Aug-13",
      "Price": "8.499",
      "Open": "8.499",
      "High": "8.499",
      "Low": "8.499",
      "Change %": "1.20%"
  },
  {
      "Date": "13-Aug-13",
      "Price": "8.398",
      "Open": "8.398",
      "High": "8.398",
      "Low": "8.398",
      "Change %": "1.22%"
  },
  {
      "Date": "12-Aug-13",
      "Price": "8.297",
      "Open": "8.297",
      "High": "8.297",
      "Low": "8.297",
      "Change %": "2.10%"
  },
  {
      "Date": "11-Aug-13",
      "Price": "8.126",
      "Open": "8.126",
      "High": "8.126",
      "Low": "8.126",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Aug-13",
      "Price": "8.126",
      "Open": "8.126",
      "High": "8.126",
      "Low": "8.126",
      "Change %": "0.00%"
  },
  {
      "Date": "9-Aug-13",
      "Price": "8.126",
      "Open": "8.126",
      "High": "8.126",
      "Low": "8.126",
      "Change %": "0.01%"
  },
  {
      "Date": "8-Aug-13",
      "Price": "8.125",
      "Open": "8.125",
      "High": "8.125",
      "Low": "8.125",
      "Change %": "-0.16%"
  },
  {
      "Date": "7-Aug-13",
      "Price": "8.138",
      "Open": "8.138",
      "High": "8.138",
      "Low": "8.138",
      "Change %": "-0.73%"
  },
  {
      "Date": "6-Aug-13",
      "Price": "8.198",
      "Open": "8.198",
      "High": "8.198",
      "Low": "8.198",
      "Change %": "-0.04%"
  },
  {
      "Date": "5-Aug-13",
      "Price": "8.201",
      "Open": "8.201",
      "High": "8.201",
      "Low": "8.201",
      "Change %": "-0.99%"
  },
  {
      "Date": "2-Aug-13",
      "Price": "8.283",
      "Open": "8.283",
      "High": "8.283",
      "Low": "8.283",
      "Change %": "2.70%"
  },
  {
      "Date": "1-Aug-13",
      "Price": "8.065",
      "Open": "8.065",
      "High": "8.065",
      "Low": "8.065",
      "Change %": "-1.31%"
  },
  {
      "Date": "31-Jul-13",
      "Price": "8.172",
      "Open": "8.172",
      "High": "8.172",
      "Low": "8.172",
      "Change %": "-1.01%"
  },
  {
      "Date": "30-Jul-13",
      "Price": "8.255",
      "Open": "8.255",
      "High": "8.255",
      "Low": "8.255",
      "Change %": "1.55%"
  },
  {
      "Date": "29-Jul-13",
      "Price": "8.129",
      "Open": "8.129",
      "High": "8.129",
      "Low": "8.129",
      "Change %": "-0.40%"
  },
  {
      "Date": "26-Jul-13",
      "Price": "8.162",
      "Open": "8.162",
      "High": "8.162",
      "Low": "8.162",
      "Change %": "-0.38%"
  },
  {
      "Date": "25-Jul-13",
      "Price": "8.193",
      "Open": "8.193",
      "High": "8.193",
      "Low": "8.193",
      "Change %": "-2.74%"
  },
  {
      "Date": "24-Jul-13",
      "Price": "8.424",
      "Open": "8.424",
      "High": "8.424",
      "Low": "8.424",
      "Change %": "3.05%"
  },
  {
      "Date": "23-Jul-13",
      "Price": "8.175",
      "Open": "8.175",
      "High": "8.175",
      "Low": "8.175",
      "Change %": "1.09%"
  },
  {
      "Date": "22-Jul-13",
      "Price": "8.087",
      "Open": "8.087",
      "High": "8.087",
      "Low": "8.087",
      "Change %": "1.86%"
  },
  {
      "Date": "19-Jul-13",
      "Price": "7.939",
      "Open": "7.939",
      "High": "7.939",
      "Low": "7.939",
      "Change %": "-0.60%"
  },
  {
      "Date": "18-Jul-13",
      "Price": "7.987",
      "Open": "7.987",
      "High": "7.987",
      "Low": "7.987",
      "Change %": "-0.81%"
  },
  {
      "Date": "17-Jul-13",
      "Price": "8.052",
      "Open": "8.052",
      "High": "8.052",
      "Low": "8.052",
      "Change %": "-0.20%"
  },
  {
      "Date": "16-Jul-13",
      "Price": "8.068",
      "Open": "8.068",
      "High": "8.068",
      "Low": "8.068",
      "Change %": "6.86%"
  },
  {
      "Date": "15-Jul-13",
      "Price": "7.55",
      "Open": "7.55",
      "High": "7.55",
      "Low": "7.55",
      "Change %": "0.20%"
  },
  {
      "Date": "12-Jul-13",
      "Price": "7.535",
      "Open": "7.535",
      "High": "7.535",
      "Low": "7.535",
      "Change %": "0.87%"
  },
  {
      "Date": "11-Jul-13",
      "Price": "7.47",
      "Open": "7.47",
      "High": "7.47",
      "Low": "7.47",
      "Change %": "-0.69%"
  },
  {
      "Date": "10-Jul-13",
      "Price": "7.522",
      "Open": "7.522",
      "High": "7.522",
      "Low": "7.522",
      "Change %": "-0.34%"
  },
  {
      "Date": "9-Jul-13",
      "Price": "7.548",
      "Open": "7.548",
      "High": "7.548",
      "Low": "7.548",
      "Change %": "-0.32%"
  },
  {
      "Date": "8-Jul-13",
      "Price": "7.572",
      "Open": "7.572",
      "High": "7.572",
      "Low": "7.572",
      "Change %": "1.00%"
  },
  {
      "Date": "5-Jul-13",
      "Price": "7.497",
      "Open": "7.497",
      "High": "7.497",
      "Low": "7.497",
      "Change %": "1.05%"
  },
  {
      "Date": "4-Jul-13",
      "Price": "7.419",
      "Open": "7.419",
      "High": "7.419",
      "Low": "7.419",
      "Change %": "-1.11%"
  },
  {
      "Date": "3-Jul-13",
      "Price": "7.502",
      "Open": "7.502",
      "High": "7.502",
      "Low": "7.502",
      "Change %": "0.51%"
  },
  {
      "Date": "2-Jul-13",
      "Price": "7.464",
      "Open": "7.464",
      "High": "7.464",
      "Low": "7.464",
      "Change %": "0.12%"
  },
  {
      "Date": "1-Jul-13",
      "Price": "7.455",
      "Open": "7.455",
      "High": "7.455",
      "Low": "7.455",
      "Change %": "0.22%"
  },
  {
      "Date": "28-Jun-13",
      "Price": "7.439",
      "Open": "7.439",
      "High": "7.439",
      "Low": "7.439",
      "Change %": "-1.56%"
  },
  {
      "Date": "27-Jun-13",
      "Price": "7.557",
      "Open": "7.557",
      "High": "7.557",
      "Low": "7.557",
      "Change %": "-0.29%"
  },
  {
      "Date": "26-Jun-13",
      "Price": "7.579",
      "Open": "7.579",
      "High": "7.579",
      "Low": "7.579",
      "Change %": "1.09%"
  },
  {
      "Date": "25-Jun-13",
      "Price": "7.497",
      "Open": "7.497",
      "High": "7.497",
      "Low": "7.497",
      "Change %": "-0.28%"
  },
  {
      "Date": "24-Jun-13",
      "Price": "7.518",
      "Open": "7.518",
      "High": "7.518",
      "Low": "7.518",
      "Change %": "1.10%"
  },
  {
      "Date": "23-Jun-13",
      "Price": "7.436",
      "Open": "7.436",
      "High": "7.436",
      "Low": "7.436",
      "Change %": "0.04%"
  },
  {
      "Date": "21-Jun-13",
      "Price": "7.433",
      "Open": "7.433",
      "High": "7.433",
      "Low": "7.433",
      "Change %": "0.54%"
  },
  {
      "Date": "20-Jun-13",
      "Price": "7.393",
      "Open": "7.393",
      "High": "7.393",
      "Low": "7.393",
      "Change %": "1.79%"
  },
  {
      "Date": "19-Jun-13",
      "Price": "7.263",
      "Open": "7.263",
      "High": "7.263",
      "Low": "7.263",
      "Change %": "-0.22%"
  },
  {
      "Date": "18-Jun-13",
      "Price": "7.279",
      "Open": "7.279",
      "High": "7.279",
      "Low": "7.279",
      "Change %": "0.33%"
  },
  {
      "Date": "17-Jun-13",
      "Price": "7.255",
      "Open": "7.255",
      "High": "7.255",
      "Low": "7.255",
      "Change %": "-0.85%"
  },
  {
      "Date": "16-Jun-13",
      "Price": "7.317",
      "Open": "7.317",
      "High": "7.317",
      "Low": "7.317",
      "Change %": "0.04%"
  },
  {
      "Date": "14-Jun-13",
      "Price": "7.314",
      "Open": "7.314",
      "High": "7.314",
      "Low": "7.314",
      "Change %": "-0.23%"
  },
  {
      "Date": "13-Jun-13",
      "Price": "7.331",
      "Open": "7.331",
      "High": "7.331",
      "Low": "7.331",
      "Change %": "0.55%"
  },
  {
      "Date": "12-Jun-13",
      "Price": "7.291",
      "Open": "7.291",
      "High": "7.291",
      "Low": "7.291",
      "Change %": "-0.15%"
  },
  {
      "Date": "11-Jun-13",
      "Price": "7.302",
      "Open": "7.302",
      "High": "7.302",
      "Low": "7.302",
      "Change %": "0.33%"
  },
  {
      "Date": "10-Jun-13",
      "Price": "7.278",
      "Open": "7.278",
      "High": "7.278",
      "Low": "7.278",
      "Change %": "0.50%"
  },
  {
      "Date": "8-Jun-13",
      "Price": "7.242",
      "Open": "7.242",
      "High": "7.242",
      "Low": "7.242",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Jun-13",
      "Price": "7.242",
      "Open": "7.242",
      "High": "7.242",
      "Low": "7.242",
      "Change %": "0.42%"
  },
  {
      "Date": "6-Jun-13",
      "Price": "7.212",
      "Open": "7.212",
      "High": "7.212",
      "Low": "7.212",
      "Change %": "0.06%"
  },
  {
      "Date": "5-Jun-13",
      "Price": "7.208",
      "Open": "7.208",
      "High": "7.208",
      "Low": "7.208",
      "Change %": "0.22%"
  },
  {
      "Date": "4-Jun-13",
      "Price": "7.192",
      "Open": "7.192",
      "High": "7.192",
      "Low": "7.192",
      "Change %": "-0.72%"
  },
  {
      "Date": "3-Jun-13",
      "Price": "7.244",
      "Open": "7.244",
      "High": "7.244",
      "Low": "7.244",
      "Change %": "-2.67%"
  },
  {
      "Date": "31-May-13",
      "Price": "7.443",
      "Open": "7.443",
      "High": "7.443",
      "Low": "7.443",
      "Change %": "0.03%"
  },
  {
      "Date": "30-May-13",
      "Price": "7.441",
      "Open": "7.441",
      "High": "7.441",
      "Low": "7.441",
      "Change %": "0.69%"
  },
  {
      "Date": "29-May-13",
      "Price": "7.39",
      "Open": "7.39",
      "High": "7.39",
      "Low": "7.39",
      "Change %": "0.46%"
  },
  {
      "Date": "28-May-13",
      "Price": "7.356",
      "Open": "7.356",
      "High": "7.356",
      "Low": "7.356",
      "Change %": "0.29%"
  },
  {
      "Date": "27-May-13",
      "Price": "7.335",
      "Open": "7.335",
      "High": "7.335",
      "Low": "7.335",
      "Change %": "-0.08%"
  },
  {
      "Date": "25-May-13",
      "Price": "7.341",
      "Open": "7.341",
      "High": "7.341",
      "Low": "7.341",
      "Change %": "0.00%"
  },
  {
      "Date": "24-May-13",
      "Price": "7.341",
      "Open": "7.341",
      "High": "7.341",
      "Low": "7.341",
      "Change %": "-0.56%"
  },
  {
      "Date": "23-May-13",
      "Price": "7.382",
      "Open": "7.382",
      "High": "7.382",
      "Low": "7.382",
      "Change %": "0.23%"
  },
  {
      "Date": "22-May-13",
      "Price": "7.365",
      "Open": "7.365",
      "High": "7.365",
      "Low": "7.365",
      "Change %": "0.19%"
  },
  {
      "Date": "21-May-13",
      "Price": "7.351",
      "Open": "7.351",
      "High": "7.351",
      "Low": "7.351",
      "Change %": "0.18%"
  },
  {
      "Date": "20-May-13",
      "Price": "7.338",
      "Open": "7.338",
      "High": "7.338",
      "Low": "7.338",
      "Change %": "-0.89%"
  },
  {
      "Date": "17-May-13",
      "Price": "7.404",
      "Open": "7.404",
      "High": "7.404",
      "Low": "7.404",
      "Change %": "0.18%"
  },
  {
      "Date": "16-May-13",
      "Price": "7.391",
      "Open": "7.391",
      "High": "7.391",
      "Low": "7.391",
      "Change %": "-0.89%"
  },
  {
      "Date": "15-May-13",
      "Price": "7.457",
      "Open": "7.457",
      "High": "7.457",
      "Low": "7.457",
      "Change %": "-0.20%"
  },
  {
      "Date": "14-May-13",
      "Price": "7.472",
      "Open": "7.472",
      "High": "7.472",
      "Low": "7.472",
      "Change %": "-1.49%"
  },
  {
      "Date": "13-May-13",
      "Price": "7.585",
      "Open": "7.585",
      "High": "7.585",
      "Low": "7.585",
      "Change %": "-0.01%"
  },
  {
      "Date": "10-May-13",
      "Price": "7.586",
      "Open": "7.586",
      "High": "7.586",
      "Low": "7.586",
      "Change %": "-0.25%"
  },
  {
      "Date": "9-May-13",
      "Price": "7.605",
      "Open": "7.605",
      "High": "7.605",
      "Low": "7.605",
      "Change %": "-1.16%"
  },
  {
      "Date": "8-May-13",
      "Price": "7.694",
      "Open": "7.694",
      "High": "7.694",
      "Low": "7.694",
      "Change %": "-0.63%"
  },
  {
      "Date": "7-May-13",
      "Price": "7.743",
      "Open": "7.743",
      "High": "7.743",
      "Low": "7.743",
      "Change %": "-0.08%"
  },
  {
      "Date": "6-May-13",
      "Price": "7.749",
      "Open": "7.749",
      "High": "7.749",
      "Low": "7.749",
      "Change %": "0.18%"
  },
  {
      "Date": "3-May-13",
      "Price": "7.735",
      "Open": "7.735",
      "High": "7.735",
      "Low": "7.735",
      "Change %": "0.16%"
  },
  {
      "Date": "2-May-13",
      "Price": "7.723",
      "Open": "7.723",
      "High": "7.723",
      "Low": "7.723",
      "Change %": "-0.10%"
  },
  {
      "Date": "1-May-13",
      "Price": "7.731",
      "Open": "7.731",
      "High": "7.731",
      "Low": "7.731",
      "Change %": "0.00%"
  },
  {
      "Date": "30-Apr-13",
      "Price": "7.731",
      "Open": "7.731",
      "High": "7.731",
      "Low": "7.731",
      "Change %": "-0.40%"
  },
  {
      "Date": "29-Apr-13",
      "Price": "7.762",
      "Open": "7.762",
      "High": "7.762",
      "Low": "7.762",
      "Change %": "0.25%"
  },
  {
      "Date": "26-Apr-13",
      "Price": "7.743",
      "Open": "7.743",
      "High": "7.743",
      "Low": "7.743",
      "Change %": "-0.39%"
  },
  {
      "Date": "25-Apr-13",
      "Price": "7.773",
      "Open": "7.773",
      "High": "7.773",
      "Low": "7.773",
      "Change %": "0.44%"
  },
  {
      "Date": "24-Apr-13",
      "Price": "7.739",
      "Open": "7.739",
      "High": "7.739",
      "Low": "7.739",
      "Change %": "0.06%"
  },
  {
      "Date": "23-Apr-13",
      "Price": "7.734",
      "Open": "7.734",
      "High": "7.734",
      "Low": "7.734",
      "Change %": "-0.25%"
  },
  {
      "Date": "22-Apr-13",
      "Price": "7.753",
      "Open": "7.753",
      "High": "7.753",
      "Low": "7.753",
      "Change %": "-0.37%"
  },
  {
      "Date": "19-Apr-13",
      "Price": "7.782",
      "Open": "7.782",
      "High": "7.782",
      "Low": "7.782",
      "Change %": "0.06%"
  },
  {
      "Date": "18-Apr-13",
      "Price": "7.777",
      "Open": "7.777",
      "High": "7.777",
      "Low": "7.777",
      "Change %": "-0.33%"
  },
  {
      "Date": "17-Apr-13",
      "Price": "7.803",
      "Open": "7.803",
      "High": "7.803",
      "Low": "7.803",
      "Change %": "-0.26%"
  },
  {
      "Date": "16-Apr-13",
      "Price": "7.823",
      "Open": "7.823",
      "High": "7.823",
      "Low": "7.823",
      "Change %": "-0.10%"
  },
  {
      "Date": "15-Apr-13",
      "Price": "7.831",
      "Open": "7.831",
      "High": "7.831",
      "Low": "7.831",
      "Change %": "-0.48%"
  },
  {
      "Date": "12-Apr-13",
      "Price": "7.869",
      "Open": "7.869",
      "High": "7.869",
      "Low": "7.869",
      "Change %": "-0.39%"
  },
  {
      "Date": "11-Apr-13",
      "Price": "7.9",
      "Open": "7.9",
      "High": "7.9",
      "Low": "7.9",
      "Change %": "0.01%"
  },
  {
      "Date": "10-Apr-13",
      "Price": "7.899",
      "Open": "7.899",
      "High": "7.899",
      "Low": "7.899",
      "Change %": "0.22%"
  },
  {
      "Date": "9-Apr-13",
      "Price": "7.882",
      "Open": "7.882",
      "High": "7.882",
      "Low": "7.882",
      "Change %": "-0.35%"
  },
  {
      "Date": "8-Apr-13",
      "Price": "7.91",
      "Open": "7.91",
      "High": "7.91",
      "Low": "7.91",
      "Change %": "-0.26%"
  },
  {
      "Date": "5-Apr-13",
      "Price": "7.931",
      "Open": "7.931",
      "High": "7.931",
      "Low": "7.931",
      "Change %": "-0.39%"
  },
  {
      "Date": "4-Apr-13",
      "Price": "7.962",
      "Open": "7.962",
      "High": "7.962",
      "Low": "7.962",
      "Change %": "-0.03%"
  },
  {
      "Date": "3-Apr-13",
      "Price": "7.964",
      "Open": "7.964",
      "High": "7.964",
      "Low": "7.964",
      "Change %": "-0.36%"
  },
  {
      "Date": "2-Apr-13",
      "Price": "7.993",
      "Open": "7.993",
      "High": "7.993",
      "Low": "7.993",
      "Change %": "0.41%"
  },
  {
      "Date": "29-Mar-13",
      "Price": "7.96",
      "Open": "7.96",
      "High": "7.96",
      "Low": "7.96",
      "Change %": "0.11%"
  },
  {
      "Date": "28-Mar-13",
      "Price": "7.951",
      "Open": "7.951",
      "High": "7.951",
      "Low": "7.951",
      "Change %": "-0.49%"
  },
  {
      "Date": "27-Mar-13",
      "Price": "7.99",
      "Open": "7.99",
      "High": "7.99",
      "Low": "7.99",
      "Change %": "0.00%"
  },
  {
      "Date": "26-Mar-13",
      "Price": "7.99",
      "Open": "7.99",
      "High": "7.99",
      "Low": "7.99",
      "Change %": "0.53%"
  },
  {
      "Date": "25-Mar-13",
      "Price": "7.948",
      "Open": "7.948",
      "High": "7.948",
      "Low": "7.948",
      "Change %": "-0.16%"
  },
  {
      "Date": "22-Mar-13",
      "Price": "7.961",
      "Open": "7.961",
      "High": "7.961",
      "Low": "7.961",
      "Change %": "0.35%"
  },
  {
      "Date": "21-Mar-13",
      "Price": "7.933",
      "Open": "7.933",
      "High": "7.933",
      "Low": "7.933",
      "Change %": "0.14%"
  },
  {
      "Date": "20-Mar-13",
      "Price": "7.922",
      "Open": "7.922",
      "High": "7.922",
      "Low": "7.922",
      "Change %": "0.24%"
  },
  {
      "Date": "19-Mar-13",
      "Price": "7.903",
      "Open": "7.903",
      "High": "7.903",
      "Low": "7.903",
      "Change %": "0.24%"
  },
  {
      "Date": "18-Mar-13",
      "Price": "7.884",
      "Open": "7.884",
      "High": "7.884",
      "Low": "7.884",
      "Change %": "0.27%"
  },
  {
      "Date": "17-Mar-13",
      "Price": "7.863",
      "Open": "7.863",
      "High": "7.863",
      "Low": "7.863",
      "Change %": "0.03%"
  },
  {
      "Date": "15-Mar-13",
      "Price": "7.861",
      "Open": "7.861",
      "High": "7.861",
      "Low": "7.861",
      "Change %": "-0.05%"
  },
  {
      "Date": "14-Mar-13",
      "Price": "7.865",
      "Open": "7.865",
      "High": "7.865",
      "Low": "7.865",
      "Change %": "-0.48%"
  },
  {
      "Date": "13-Mar-13",
      "Price": "7.903",
      "Open": "7.903",
      "High": "7.903",
      "Low": "7.903",
      "Change %": "0.19%"
  },
  {
      "Date": "12-Mar-13",
      "Price": "7.888",
      "Open": "7.888",
      "High": "7.888",
      "Low": "7.888",
      "Change %": "0.61%"
  },
  {
      "Date": "11-Mar-13",
      "Price": "7.84",
      "Open": "7.84",
      "High": "7.84",
      "Low": "7.84",
      "Change %": "0.01%"
  },
  {
      "Date": "8-Mar-13",
      "Price": "7.839",
      "Open": "7.839",
      "High": "7.839",
      "Low": "7.839",
      "Change %": "-0.29%"
  },
  {
      "Date": "7-Mar-13",
      "Price": "7.862",
      "Open": "7.862",
      "High": "7.862",
      "Low": "7.862",
      "Change %": "0.03%"
  },
  {
      "Date": "6-Mar-13",
      "Price": "7.86",
      "Open": "7.86",
      "High": "7.86",
      "Low": "7.86",
      "Change %": "-0.14%"
  },
  {
      "Date": "5-Mar-13",
      "Price": "7.871",
      "Open": "7.871",
      "High": "7.871",
      "Low": "7.871",
      "Change %": "-0.18%"
  },
  {
      "Date": "4-Mar-13",
      "Price": "7.885",
      "Open": "7.885",
      "High": "7.885",
      "Low": "7.885",
      "Change %": "-0.29%"
  },
  {
      "Date": "1-Mar-13",
      "Price": "7.908",
      "Open": "7.908",
      "High": "7.908",
      "Low": "7.908",
      "Change %": "0.46%"
  },
  {
      "Date": "28-Feb-13",
      "Price": "7.872",
      "Open": "7.872",
      "High": "7.872",
      "Low": "7.872",
      "Change %": "0.95%"
  },
  {
      "Date": "27-Feb-13",
      "Price": "7.798",
      "Open": "7.798",
      "High": "7.798",
      "Low": "7.798",
      "Change %": "-0.23%"
  },
  {
      "Date": "26-Feb-13",
      "Price": "7.816",
      "Open": "7.816",
      "High": "7.816",
      "Low": "7.816",
      "Change %": "0.23%"
  },
  {
      "Date": "25-Feb-13",
      "Price": "7.798",
      "Open": "7.798",
      "High": "7.798",
      "Low": "7.798",
      "Change %": "-0.01%"
  },
  {
      "Date": "22-Feb-13",
      "Price": "7.799",
      "Open": "7.799",
      "High": "7.799",
      "Low": "7.799",
      "Change %": "-0.17%"
  },
  {
      "Date": "21-Feb-13",
      "Price": "7.812",
      "Open": "7.812",
      "High": "7.812",
      "Low": "7.812",
      "Change %": "0.10%"
  },
  {
      "Date": "20-Feb-13",
      "Price": "7.804",
      "Open": "7.804",
      "High": "7.804",
      "Low": "7.804",
      "Change %": "-0.29%"
  },
  {
      "Date": "19-Feb-13",
      "Price": "7.827",
      "Open": "7.827",
      "High": "7.827",
      "Low": "7.827",
      "Change %": "0.04%"
  },
  {
      "Date": "18-Feb-13",
      "Price": "7.824",
      "Open": "7.824",
      "High": "7.824",
      "Low": "7.824",
      "Change %": "-0.10%"
  },
  {
      "Date": "17-Feb-13",
      "Price": "7.832",
      "Open": "7.832",
      "High": "7.832",
      "Low": "7.832",
      "Change %": "0.00%"
  },
  {
      "Date": "15-Feb-13",
      "Price": "7.832",
      "Open": "7.832",
      "High": "7.832",
      "Low": "7.832",
      "Change %": "0.18%"
  },
  {
      "Date": "14-Feb-13",
      "Price": "7.818",
      "Open": "7.818",
      "High": "7.818",
      "Low": "7.818",
      "Change %": "-0.33%"
  },
  {
      "Date": "13-Feb-13",
      "Price": "7.844",
      "Open": "7.844",
      "High": "7.844",
      "Low": "7.844",
      "Change %": "-0.29%"
  },
  {
      "Date": "12-Feb-13",
      "Price": "7.867",
      "Open": "7.867",
      "High": "7.867",
      "Low": "7.867",
      "Change %": "0.11%"
  },
  {
      "Date": "11-Feb-13",
      "Price": "7.858",
      "Open": "7.858",
      "High": "7.858",
      "Low": "7.858",
      "Change %": "0.18%"
  },
  {
      "Date": "9-Feb-13",
      "Price": "7.844",
      "Open": "7.844",
      "High": "7.844",
      "Low": "7.844",
      "Change %": "0.01%"
  },
  {
      "Date": "8-Feb-13",
      "Price": "7.843",
      "Open": "7.843",
      "High": "7.843",
      "Low": "7.843",
      "Change %": "-0.44%"
  },
  {
      "Date": "7-Feb-13",
      "Price": "7.878",
      "Open": "7.878",
      "High": "7.878",
      "Low": "7.878",
      "Change %": "-0.37%"
  },
  {
      "Date": "6-Feb-13",
      "Price": "7.907",
      "Open": "7.907",
      "High": "7.907",
      "Low": "7.907",
      "Change %": "-0.20%"
  },
  {
      "Date": "5-Feb-13",
      "Price": "7.923",
      "Open": "7.923",
      "High": "7.923",
      "Low": "7.923",
      "Change %": "-0.18%"
  },
  {
      "Date": "4-Feb-13",
      "Price": "7.937",
      "Open": "7.937",
      "High": "7.937",
      "Low": "7.937",
      "Change %": "0.40%"
  },
  {
      "Date": "1-Feb-13",
      "Price": "7.905",
      "Open": "7.905",
      "High": "7.905",
      "Low": "7.905",
      "Change %": "-0.09%"
  },
  {
      "Date": "31-Jan-13",
      "Price": "7.912",
      "Open": "7.912",
      "High": "7.912",
      "Low": "7.912",
      "Change %": "0.27%"
  },
  {
      "Date": "30-Jan-13",
      "Price": "7.891",
      "Open": "7.891",
      "High": "7.891",
      "Low": "7.891",
      "Change %": "0.56%"
  },
  {
      "Date": "29-Jan-13",
      "Price": "7.847",
      "Open": "7.847",
      "High": "7.847",
      "Low": "7.847",
      "Change %": "-0.20%"
  },
  {
      "Date": "28-Jan-13",
      "Price": "7.863",
      "Open": "7.863",
      "High": "7.863",
      "Low": "7.863",
      "Change %": "-0.28%"
  },
  {
      "Date": "25-Jan-13",
      "Price": "7.885",
      "Open": "7.885",
      "High": "7.885",
      "Low": "7.885",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Jan-13",
      "Price": "7.885",
      "Open": "7.885",
      "High": "7.885",
      "Low": "7.885",
      "Change %": "0.27%"
  },
  {
      "Date": "23-Jan-13",
      "Price": "7.864",
      "Open": "7.864",
      "High": "7.864",
      "Low": "7.864",
      "Change %": "0.19%"
  },
  {
      "Date": "22-Jan-13",
      "Price": "7.849",
      "Open": "7.849",
      "High": "7.849",
      "Low": "7.849",
      "Change %": "-0.15%"
  },
  {
      "Date": "21-Jan-13",
      "Price": "7.861",
      "Open": "7.861",
      "High": "7.861",
      "Low": "7.861",
      "Change %": "0.00%"
  },
  {
      "Date": "18-Jan-13",
      "Price": "7.861",
      "Open": "7.861",
      "High": "7.861",
      "Low": "7.861",
      "Change %": "0.31%"
  },
  {
      "Date": "17-Jan-13",
      "Price": "7.837",
      "Open": "7.837",
      "High": "7.837",
      "Low": "7.837",
      "Change %": "-0.56%"
  },
  {
      "Date": "16-Jan-13",
      "Price": "7.881",
      "Open": "7.881",
      "High": "7.881",
      "Low": "7.881",
      "Change %": "0.63%"
  },
  {
      "Date": "15-Jan-13",
      "Price": "7.832",
      "Open": "7.832",
      "High": "7.832",
      "Low": "7.832",
      "Change %": "0.41%"
  },
  {
      "Date": "14-Jan-13",
      "Price": "7.8",
      "Open": "7.8",
      "High": "7.8",
      "Low": "7.8",
      "Change %": "-0.88%"
  },
  {
      "Date": "13-Jan-13",
      "Price": "7.869",
      "Open": "7.869",
      "High": "7.869",
      "Low": "7.869",
      "Change %": "0.04%"
  },
  {
      "Date": "11-Jan-13",
      "Price": "7.866",
      "Open": "7.866",
      "High": "7.866",
      "Low": "7.866",
      "Change %": "-0.14%"
  },
  {
      "Date": "10-Jan-13",
      "Price": "7.877",
      "Open": "7.877",
      "High": "7.877",
      "Low": "7.877",
      "Change %": "-0.25%"
  },
  {
      "Date": "9-Jan-13",
      "Price": "7.897",
      "Open": "7.897",
      "High": "7.897",
      "Low": "7.897",
      "Change %": "-0.11%"
  },
  {
      "Date": "8-Jan-13",
      "Price": "7.906",
      "Open": "7.906",
      "High": "7.906",
      "Low": "7.906",
      "Change %": "0.08%"
  },
  {
      "Date": "7-Jan-13",
      "Price": "7.9",
      "Open": "7.9",
      "High": "7.9",
      "Low": "7.9",
      "Change %": "-0.42%"
  },
  {
      "Date": "5-Jan-13",
      "Price": "7.933",
      "Open": "7.933",
      "High": "7.933",
      "Low": "7.933",
      "Change %": "0.04%"
  },
  {
      "Date": "4-Jan-13",
      "Price": "7.93",
      "Open": "7.93",
      "High": "7.93",
      "Low": "7.93",
      "Change %": "-0.46%"
  },
  {
      "Date": "3-Jan-13",
      "Price": "7.967",
      "Open": "7.967",
      "High": "7.967",
      "Low": "7.967",
      "Change %": "-0.33%"
  },
  {
      "Date": "2-Jan-13",
      "Price": "7.993",
      "Open": "7.993",
      "High": "7.993",
      "Low": "7.993",
      "Change %": "-0.01%"
  },
  {
      "Date": "1-Jan-13",
      "Price": "7.994",
      "Open": "7.994",
      "High": "7.994",
      "Low": "7.994",
      "Change %": "-0.68%"
  },
  {
      "Date": "31-Dec-12",
      "Price": "8.049",
      "Open": "8.049",
      "High": "8.049",
      "Low": "8.049",
      "Change %": "-0.72%"
  },
  {
      "Date": "30-Dec-12",
      "Price": "8.107",
      "Open": "8.107",
      "High": "8.107",
      "Low": "8.107",
      "Change %": "0.00%"
  },
  {
      "Date": "29-Dec-12",
      "Price": "8.107",
      "Open": "8.107",
      "High": "8.107",
      "Low": "8.107",
      "Change %": "0.01%"
  },
  {
      "Date": "28-Dec-12",
      "Price": "8.106",
      "Open": "8.106",
      "High": "8.106",
      "Low": "8.106",
      "Change %": "-0.06%"
  },
  {
      "Date": "27-Dec-12",
      "Price": "8.111",
      "Open": "8.111",
      "High": "8.111",
      "Low": "8.111",
      "Change %": "-0.01%"
  },
  {
      "Date": "26-Dec-12",
      "Price": "8.112",
      "Open": "8.112",
      "High": "8.112",
      "Low": "8.112",
      "Change %": "-0.23%"
  },
  {
      "Date": "25-Dec-12",
      "Price": "8.131",
      "Open": "8.131",
      "High": "8.131",
      "Low": "8.131",
      "Change %": "0.02%"
  },
  {
      "Date": "24-Dec-12",
      "Price": "8.129",
      "Open": "8.129",
      "High": "8.129",
      "Low": "8.129",
      "Change %": "-0.17%"
  },
  {
      "Date": "23-Dec-12",
      "Price": "8.143",
      "Open": "8.143",
      "High": "8.143",
      "Low": "8.143",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Dec-12",
      "Price": "8.143",
      "Open": "8.143",
      "High": "8.143",
      "Low": "8.143",
      "Change %": "0.00%"
  },
  {
      "Date": "21-Dec-12",
      "Price": "8.143",
      "Open": "8.143",
      "High": "8.143",
      "Low": "8.143",
      "Change %": "-0.13%"
  },
  {
      "Date": "20-Dec-12",
      "Price": "8.154",
      "Open": "8.154",
      "High": "8.154",
      "Low": "8.154",
      "Change %": "0.00%"
  },
  {
      "Date": "19-Dec-12",
      "Price": "8.154",
      "Open": "8.154",
      "High": "8.154",
      "Low": "8.154",
      "Change %": "0.07%"
  },
  {
      "Date": "18-Dec-12",
      "Price": "8.148",
      "Open": "8.148",
      "High": "8.148",
      "Low": "8.148",
      "Change %": "0.10%"
  },
  {
      "Date": "17-Dec-12",
      "Price": "8.14",
      "Open": "8.14",
      "High": "8.14",
      "Low": "8.14",
      "Change %": "-0.02%"
  },
  {
      "Date": "15-Dec-12",
      "Price": "8.142",
      "Open": "8.142",
      "High": "8.142",
      "Low": "8.142",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Dec-12",
      "Price": "8.142",
      "Open": "8.142",
      "High": "8.142",
      "Low": "8.142",
      "Change %": "-0.20%"
  },
  {
      "Date": "13-Dec-12",
      "Price": "8.158",
      "Open": "8.158",
      "High": "8.158",
      "Low": "8.158",
      "Change %": "-0.23%"
  },
  {
      "Date": "12-Dec-12",
      "Price": "8.177",
      "Open": "8.177",
      "High": "8.177",
      "Low": "8.177",
      "Change %": "-0.01%"
  },
  {
      "Date": "11-Dec-12",
      "Price": "8.178",
      "Open": "8.178",
      "High": "8.178",
      "Low": "8.178",
      "Change %": "0.07%"
  },
  {
      "Date": "10-Dec-12",
      "Price": "8.172",
      "Open": "8.172",
      "High": "8.172",
      "Low": "8.172",
      "Change %": "0.16%"
  },
  {
      "Date": "7-Dec-12",
      "Price": "8.159",
      "Open": "8.159",
      "High": "8.159",
      "Low": "8.159",
      "Change %": "-0.13%"
  },
  {
      "Date": "6-Dec-12",
      "Price": "8.17",
      "Open": "8.17",
      "High": "8.17",
      "Low": "8.17",
      "Change %": "-0.01%"
  },
  {
      "Date": "5-Dec-12",
      "Price": "8.171",
      "Open": "8.171",
      "High": "8.171",
      "Low": "8.171",
      "Change %": "0.00%"
  },
  {
      "Date": "4-Dec-12",
      "Price": "8.171",
      "Open": "8.171",
      "High": "8.171",
      "Low": "8.171",
      "Change %": "-0.04%"
  },
  {
      "Date": "3-Dec-12",
      "Price": "8.174",
      "Open": "8.174",
      "High": "8.174",
      "Low": "8.174",
      "Change %": "-0.04%"
  },
  {
      "Date": "1-Dec-12",
      "Price": "8.177",
      "Open": "8.177",
      "High": "8.177",
      "Low": "8.177",
      "Change %": "0.02%"
  },
  {
      "Date": "30-Nov-12",
      "Price": "8.175",
      "Open": "8.175",
      "High": "8.175",
      "Low": "8.175",
      "Change %": "-0.38%"
  },
  {
      "Date": "29-Nov-12",
      "Price": "8.206",
      "Open": "8.206",
      "High": "8.206",
      "Low": "8.206",
      "Change %": "0.18%"
  },
  {
      "Date": "28-Nov-12",
      "Price": "8.191",
      "Open": "8.191",
      "High": "8.191",
      "Low": "8.191",
      "Change %": "0.00%"
  },
  {
      "Date": "27-Nov-12",
      "Price": "8.191",
      "Open": "8.191",
      "High": "8.191",
      "Low": "8.191",
      "Change %": "-0.16%"
  },
  {
      "Date": "26-Nov-12",
      "Price": "8.204",
      "Open": "8.204",
      "High": "8.204",
      "Low": "8.204",
      "Change %": "-0.32%"
  },
  {
      "Date": "24-Nov-12",
      "Price": "8.23",
      "Open": "8.23",
      "High": "8.23",
      "Low": "8.23",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Nov-12",
      "Price": "8.23",
      "Open": "8.23",
      "High": "8.23",
      "Low": "8.23",
      "Change %": "0.05%"
  },
  {
      "Date": "22-Nov-12",
      "Price": "8.226",
      "Open": "8.226",
      "High": "8.226",
      "Low": "8.226",
      "Change %": "0.22%"
  },
  {
      "Date": "21-Nov-12",
      "Price": "8.208",
      "Open": "8.208",
      "High": "8.208",
      "Low": "8.208",
      "Change %": "0.09%"
  },
  {
      "Date": "20-Nov-12",
      "Price": "8.201",
      "Open": "8.201",
      "High": "8.201",
      "Low": "8.201",
      "Change %": "0.12%"
  },
  {
      "Date": "19-Nov-12",
      "Price": "8.191",
      "Open": "8.191",
      "High": "8.191",
      "Low": "8.191",
      "Change %": "-0.02%"
  },
  {
      "Date": "17-Nov-12",
      "Price": "8.193",
      "Open": "8.193",
      "High": "8.193",
      "Low": "8.193",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Nov-12",
      "Price": "8.193",
      "Open": "8.193",
      "High": "8.193",
      "Low": "8.193",
      "Change %": "0.04%"
  },
  {
      "Date": "15-Nov-12",
      "Price": "8.19",
      "Open": "8.19",
      "High": "8.19",
      "Low": "8.19",
      "Change %": "-0.28%"
  },
  {
      "Date": "13-Nov-12",
      "Price": "8.213",
      "Open": "8.213",
      "High": "8.213",
      "Low": "8.213",
      "Change %": "0.02%"
  },
  {
      "Date": "12-Nov-12",
      "Price": "8.211",
      "Open": "8.211",
      "High": "8.211",
      "Low": "8.211",
      "Change %": "-0.05%"
  },
  {
      "Date": "10-Nov-12",
      "Price": "8.215",
      "Open": "8.215",
      "High": "8.215",
      "Low": "8.215",
      "Change %": "0.00%"
  },
  {
      "Date": "9-Nov-12",
      "Price": "8.215",
      "Open": "8.215",
      "High": "8.215",
      "Low": "8.215",
      "Change %": "0.29%"
  },
  {
      "Date": "8-Nov-12",
      "Price": "8.191",
      "Open": "8.191",
      "High": "8.191",
      "Low": "8.191",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Nov-12",
      "Price": "8.191",
      "Open": "8.191",
      "High": "8.191",
      "Low": "8.191",
      "Change %": "0.10%"
  },
  {
      "Date": "6-Nov-12",
      "Price": "8.183",
      "Open": "8.183",
      "High": "8.183",
      "Low": "8.183",
      "Change %": "-0.15%"
  },
  {
      "Date": "5-Nov-12",
      "Price": "8.195",
      "Open": "8.195",
      "High": "8.195",
      "Low": "8.195",
      "Change %": "-0.04%"
  },
  {
      "Date": "3-Nov-12",
      "Price": "8.198",
      "Open": "8.198",
      "High": "8.198",
      "Low": "8.198",
      "Change %": "0.01%"
  },
  {
      "Date": "2-Nov-12",
      "Price": "8.197",
      "Open": "8.197",
      "High": "8.197",
      "Low": "8.197",
      "Change %": "0.07%"
  },
  {
      "Date": "1-Nov-12",
      "Price": "8.191",
      "Open": "8.191",
      "High": "8.191",
      "Low": "8.191",
      "Change %": "-0.29%"
  },
  {
      "Date": "31-Oct-12",
      "Price": "8.215",
      "Open": "8.215",
      "High": "8.215",
      "Low": "8.215",
      "Change %": "0.42%"
  },
  {
      "Date": "30-Oct-12",
      "Price": "8.181",
      "Open": "8.181",
      "High": "8.181",
      "Low": "8.181",
      "Change %": "0.59%"
  },
  {
      "Date": "29-Oct-12",
      "Price": "8.133",
      "Open": "8.133",
      "High": "8.133",
      "Low": "8.133",
      "Change %": "0.04%"
  },
  {
      "Date": "26-Oct-12",
      "Price": "8.13",
      "Open": "8.13",
      "High": "8.13",
      "Low": "8.13",
      "Change %": "0.02%"
  },
  {
      "Date": "25-Oct-12",
      "Price": "8.128",
      "Open": "8.128",
      "High": "8.128",
      "Low": "8.128",
      "Change %": "-0.11%"
  },
  {
      "Date": "24-Oct-12",
      "Price": "8.137",
      "Open": "8.137",
      "High": "8.137",
      "Low": "8.137",
      "Change %": "0.02%"
  },
  {
      "Date": "23-Oct-12",
      "Price": "8.135",
      "Open": "8.135",
      "High": "8.135",
      "Low": "8.135",
      "Change %": "0.04%"
  },
  {
      "Date": "22-Oct-12",
      "Price": "8.132",
      "Open": "8.132",
      "High": "8.132",
      "Low": "8.132",
      "Change %": "0.04%"
  },
  {
      "Date": "20-Oct-12",
      "Price": "8.129",
      "Open": "8.129",
      "High": "8.129",
      "Low": "8.129",
      "Change %": "0.01%"
  },
  {
      "Date": "19-Oct-12",
      "Price": "8.128",
      "Open": "8.128",
      "High": "8.128",
      "Low": "8.128",
      "Change %": "-0.09%"
  },
  {
      "Date": "18-Oct-12",
      "Price": "8.135",
      "Open": "8.135",
      "High": "8.135",
      "Low": "8.135",
      "Change %": "-0.21%"
  },
  {
      "Date": "17-Oct-12",
      "Price": "8.152",
      "Open": "8.152",
      "High": "8.152",
      "Low": "8.152",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Oct-12",
      "Price": "8.152",
      "Open": "8.152",
      "High": "8.152",
      "Low": "8.152",
      "Change %": "-0.21%"
  },
  {
      "Date": "15-Oct-12",
      "Price": "8.169",
      "Open": "8.169",
      "High": "8.169",
      "Low": "8.169",
      "Change %": "-0.01%"
  },
  {
      "Date": "13-Oct-12",
      "Price": "8.17",
      "Open": "8.17",
      "High": "8.17",
      "Low": "8.17",
      "Change %": "0.01%"
  },
  {
      "Date": "12-Oct-12",
      "Price": "8.169",
      "Open": "8.169",
      "High": "8.169",
      "Low": "8.169",
      "Change %": "0.09%"
  },
  {
      "Date": "11-Oct-12",
      "Price": "8.162",
      "Open": "8.162",
      "High": "8.162",
      "Low": "8.162",
      "Change %": "0.09%"
  },
  {
      "Date": "10-Oct-12",
      "Price": "8.155",
      "Open": "8.155",
      "High": "8.155",
      "Low": "8.155",
      "Change %": "0.15%"
  },
  {
      "Date": "9-Oct-12",
      "Price": "8.143",
      "Open": "8.143",
      "High": "8.143",
      "Low": "8.143",
      "Change %": "-0.07%"
  },
  {
      "Date": "8-Oct-12",
      "Price": "8.149",
      "Open": "8.149",
      "High": "8.149",
      "Low": "8.149",
      "Change %": "-0.16%"
  },
  {
      "Date": "6-Oct-12",
      "Price": "8.162",
      "Open": "8.162",
      "High": "8.162",
      "Low": "8.162",
      "Change %": "0.04%"
  },
  {
      "Date": "5-Oct-12",
      "Price": "8.159",
      "Open": "8.159",
      "High": "8.159",
      "Low": "8.159",
      "Change %": "0.12%"
  },
  {
      "Date": "4-Oct-12",
      "Price": "8.149",
      "Open": "8.149",
      "High": "8.149",
      "Low": "8.149",
      "Change %": "0.02%"
  },
  {
      "Date": "3-Oct-12",
      "Price": "8.147",
      "Open": "8.147",
      "High": "8.147",
      "Low": "8.147",
      "Change %": "-0.17%"
  },
  {
      "Date": "2-Oct-12",
      "Price": "8.161",
      "Open": "8.161",
      "High": "8.161",
      "Low": "8.161",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Oct-12",
      "Price": "8.161",
      "Open": "8.161",
      "High": "8.161",
      "Low": "8.161",
      "Change %": "0.11%"
  },
  {
      "Date": "29-Sep-12",
      "Price": "8.152",
      "Open": "8.152",
      "High": "8.152",
      "Low": "8.152",
      "Change %": "0.04%"
  },
  {
      "Date": "28-Sep-12",
      "Price": "8.149",
      "Open": "8.149",
      "High": "8.149",
      "Low": "8.149",
      "Change %": "-0.16%"
  },
  {
      "Date": "27-Sep-12",
      "Price": "8.162",
      "Open": "8.162",
      "High": "8.162",
      "Low": "8.162",
      "Change %": "-0.07%"
  },
  {
      "Date": "26-Sep-12",
      "Price": "8.168",
      "Open": "8.168",
      "High": "8.168",
      "Low": "8.168",
      "Change %": "0.00%"
  },
  {
      "Date": "25-Sep-12",
      "Price": "8.168",
      "Open": "8.168",
      "High": "8.168",
      "Low": "8.168",
      "Change %": "0.16%"
  },
  {
      "Date": "24-Sep-12",
      "Price": "8.155",
      "Open": "8.155",
      "High": "8.155",
      "Low": "8.155",
      "Change %": "-0.15%"
  },
  {
      "Date": "23-Sep-12",
      "Price": "8.167",
      "Open": "8.167",
      "High": "8.167",
      "Low": "8.167",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Sep-12",
      "Price": "8.167",
      "Open": "8.167",
      "High": "8.167",
      "Low": "8.167",
      "Change %": "0.02%"
  },
  {
      "Date": "21-Sep-12",
      "Price": "8.165",
      "Open": "8.165",
      "High": "8.165",
      "Low": "8.165",
      "Change %": "0.06%"
  },
  {
      "Date": "20-Sep-12",
      "Price": "8.16",
      "Open": "8.16",
      "High": "8.16",
      "Low": "8.16",
      "Change %": "-0.10%"
  },
  {
      "Date": "19-Sep-12",
      "Price": "8.168",
      "Open": "8.168",
      "High": "8.168",
      "Low": "8.168",
      "Change %": "0.04%"
  },
  {
      "Date": "18-Sep-12",
      "Price": "8.165",
      "Open": "8.165",
      "High": "8.165",
      "Low": "8.165",
      "Change %": "-0.18%"
  },
  {
      "Date": "17-Sep-12",
      "Price": "8.18",
      "Open": "8.18",
      "High": "8.18",
      "Low": "8.18",
      "Change %": "0.00%"
  },
  {
      "Date": "15-Sep-12",
      "Price": "8.18",
      "Open": "8.18",
      "High": "8.18",
      "Low": "8.18",
      "Change %": "0.04%"
  },
  {
      "Date": "14-Sep-12",
      "Price": "8.177",
      "Open": "8.177",
      "High": "8.177",
      "Low": "8.177",
      "Change %": "0.04%"
  },
  {
      "Date": "13-Sep-12",
      "Price": "8.174",
      "Open": "8.174",
      "High": "8.174",
      "Low": "8.174",
      "Change %": "-0.29%"
  },
  {
      "Date": "12-Sep-12",
      "Price": "8.198",
      "Open": "8.198",
      "High": "8.198",
      "Low": "8.198",
      "Change %": "0.18%"
  },
  {
      "Date": "11-Sep-12",
      "Price": "8.183",
      "Open": "8.183",
      "High": "8.183",
      "Low": "8.183",
      "Change %": "0.07%"
  },
  {
      "Date": "10-Sep-12",
      "Price": "8.177",
      "Open": "8.177",
      "High": "8.177",
      "Low": "8.177",
      "Change %": "-0.29%"
  },
  {
      "Date": "8-Sep-12",
      "Price": "8.201",
      "Open": "8.201",
      "High": "8.201",
      "Low": "8.201",
      "Change %": "0.01%"
  },
  {
      "Date": "7-Sep-12",
      "Price": "8.2",
      "Open": "8.2",
      "High": "8.2",
      "Low": "8.2",
      "Change %": "0.50%"
  },
  {
      "Date": "6-Sep-12",
      "Price": "8.159",
      "Open": "8.159",
      "High": "8.159",
      "Low": "8.159",
      "Change %": "-0.24%"
  },
  {
      "Date": "5-Sep-12",
      "Price": "8.179",
      "Open": "8.179",
      "High": "8.179",
      "Low": "8.179",
      "Change %": "-0.40%"
  },
  {
      "Date": "4-Sep-12",
      "Price": "8.212",
      "Open": "8.212",
      "High": "8.212",
      "Low": "8.212",
      "Change %": "-0.04%"
  },
  {
      "Date": "3-Sep-12",
      "Price": "8.215",
      "Open": "8.215",
      "High": "8.215",
      "Low": "8.215",
      "Change %": "-0.32%"
  },
  {
      "Date": "1-Sep-12",
      "Price": "8.241",
      "Open": "8.241",
      "High": "8.241",
      "Low": "8.241",
      "Change %": "0.00%"
  },
  {
      "Date": "31-Aug-12",
      "Price": "8.241",
      "Open": "8.241",
      "High": "8.241",
      "Low": "8.241",
      "Change %": "0.57%"
  },
  {
      "Date": "30-Aug-12",
      "Price": "8.194",
      "Open": "8.194",
      "High": "8.194",
      "Low": "8.194",
      "Change %": "0.21%"
  },
  {
      "Date": "29-Aug-12",
      "Price": "8.177",
      "Open": "8.177",
      "High": "8.177",
      "Low": "8.177",
      "Change %": "0.04%"
  },
  {
      "Date": "28-Aug-12",
      "Price": "8.174",
      "Open": "8.174",
      "High": "8.174",
      "Low": "8.174",
      "Change %": "-0.15%"
  },
  {
      "Date": "27-Aug-12",
      "Price": "8.186",
      "Open": "8.186",
      "High": "8.186",
      "Low": "8.186",
      "Change %": "-0.29%"
  },
  {
      "Date": "25-Aug-12",
      "Price": "8.21",
      "Open": "8.21",
      "High": "8.21",
      "Low": "8.21",
      "Change %": "0.04%"
  },
  {
      "Date": "24-Aug-12",
      "Price": "8.207",
      "Open": "8.207",
      "High": "8.207",
      "Low": "8.207",
      "Change %": "-0.26%"
  },
  {
      "Date": "23-Aug-12",
      "Price": "8.228",
      "Open": "8.228",
      "High": "8.228",
      "Low": "8.228",
      "Change %": "0.09%"
  },
  {
      "Date": "22-Aug-12",
      "Price": "8.221",
      "Open": "8.221",
      "High": "8.221",
      "Low": "8.221",
      "Change %": "-0.29%"
  },
  {
      "Date": "21-Aug-12",
      "Price": "8.245",
      "Open": "8.245",
      "High": "8.245",
      "Low": "8.245",
      "Change %": "0.10%"
  },
  {
      "Date": "18-Aug-12",
      "Price": "8.237",
      "Open": "8.237",
      "High": "8.237",
      "Low": "8.237",
      "Change %": "0.00%"
  },
  {
      "Date": "17-Aug-12",
      "Price": "8.237",
      "Open": "8.237",
      "High": "8.237",
      "Low": "8.237",
      "Change %": "-0.34%"
  },
  {
      "Date": "16-Aug-12",
      "Price": "8.265",
      "Open": "8.265",
      "High": "8.265",
      "Low": "8.265",
      "Change %": "0.54%"
  },
  {
      "Date": "15-Aug-12",
      "Price": "8.221",
      "Open": "8.221",
      "High": "8.221",
      "Low": "8.221",
      "Change %": "0.02%"
  },
  {
      "Date": "14-Aug-12",
      "Price": "8.219",
      "Open": "8.219",
      "High": "8.219",
      "Low": "8.219",
      "Change %": "0.18%"
  },
  {
      "Date": "13-Aug-12",
      "Price": "8.204",
      "Open": "8.204",
      "High": "8.204",
      "Low": "8.204",
      "Change %": "0.42%"
  },
  {
      "Date": "11-Aug-12",
      "Price": "8.17",
      "Open": "8.17",
      "High": "8.17",
      "Low": "8.17",
      "Change %": "0.07%"
  },
  {
      "Date": "10-Aug-12",
      "Price": "8.164",
      "Open": "8.164",
      "High": "8.164",
      "Low": "8.164",
      "Change %": "0.28%"
  },
  {
      "Date": "9-Aug-12",
      "Price": "8.141",
      "Open": "8.141",
      "High": "8.141",
      "Low": "8.141",
      "Change %": "0.04%"
  },
  {
      "Date": "8-Aug-12",
      "Price": "8.138",
      "Open": "8.138",
      "High": "8.138",
      "Low": "8.138",
      "Change %": "-0.13%"
  },
  {
      "Date": "7-Aug-12",
      "Price": "8.149",
      "Open": "8.149",
      "High": "8.149",
      "Low": "8.149",
      "Change %": "-0.89%"
  },
  {
      "Date": "6-Aug-12",
      "Price": "8.222",
      "Open": "8.222",
      "High": "8.222",
      "Low": "8.222",
      "Change %": "-0.41%"
  },
  {
      "Date": "5-Aug-12",
      "Price": "8.256",
      "Open": "8.256",
      "High": "8.256",
      "Low": "8.256",
      "Change %": "0.00%"
  },
  {
      "Date": "4-Aug-12",
      "Price": "8.256",
      "Open": "8.256",
      "High": "8.256",
      "Low": "8.256",
      "Change %": "0.00%"
  },
  {
      "Date": "3-Aug-12",
      "Price": "8.256",
      "Open": "8.256",
      "High": "8.256",
      "Low": "8.256",
      "Change %": "0.45%"
  },
  {
      "Date": "2-Aug-12",
      "Price": "8.219",
      "Open": "8.219",
      "High": "8.219",
      "Low": "8.219",
      "Change %": "-0.13%"
  },
  {
      "Date": "1-Aug-12",
      "Price": "8.23",
      "Open": "8.23",
      "High": "8.23",
      "Low": "8.23",
      "Change %": "-0.18%"
  },
  {
      "Date": "31-Jul-12",
      "Price": "8.245",
      "Open": "8.245",
      "High": "8.245",
      "Low": "8.245",
      "Change %": "1.19%"
  },
  {
      "Date": "30-Jul-12",
      "Price": "8.148",
      "Open": "8.148",
      "High": "8.148",
      "Low": "8.148",
      "Change %": "0.36%"
  },
  {
      "Date": "28-Jul-12",
      "Price": "8.119",
      "Open": "8.119",
      "High": "8.119",
      "Low": "8.119",
      "Change %": "0.00%"
  },
  {
      "Date": "27-Jul-12",
      "Price": "8.119",
      "Open": "8.119",
      "High": "8.119",
      "Low": "8.119",
      "Change %": "0.07%"
  },
  {
      "Date": "26-Jul-12",
      "Price": "8.113",
      "Open": "8.113",
      "High": "8.113",
      "Low": "8.113",
      "Change %": "0.15%"
  },
  {
      "Date": "25-Jul-12",
      "Price": "8.101",
      "Open": "8.101",
      "High": "8.101",
      "Low": "8.101",
      "Change %": "0.38%"
  },
  {
      "Date": "24-Jul-12",
      "Price": "8.07",
      "Open": "8.07",
      "High": "8.07",
      "Low": "8.07",
      "Change %": "0.05%"
  },
  {
      "Date": "23-Jul-12",
      "Price": "8.066",
      "Open": "8.066",
      "High": "8.066",
      "Low": "8.066",
      "Change %": "-0.16%"
  },
  {
      "Date": "21-Jul-12",
      "Price": "8.079",
      "Open": "8.079",
      "High": "8.079",
      "Low": "8.079",
      "Change %": "0.05%"
  },
  {
      "Date": "20-Jul-12",
      "Price": "8.075",
      "Open": "8.075",
      "High": "8.075",
      "Low": "8.075",
      "Change %": "-0.12%"
  },
  {
      "Date": "19-Jul-12",
      "Price": "8.085",
      "Open": "8.085",
      "High": "8.085",
      "Low": "8.085",
      "Change %": "0.24%"
  },
  {
      "Date": "18-Jul-12",
      "Price": "8.066",
      "Open": "8.066",
      "High": "8.066",
      "Low": "8.066",
      "Change %": "-0.30%"
  },
  {
      "Date": "17-Jul-12",
      "Price": "8.09",
      "Open": "8.09",
      "High": "8.09",
      "Low": "8.09",
      "Change %": "0.51%"
  },
  {
      "Date": "16-Jul-12",
      "Price": "8.049",
      "Open": "8.049",
      "High": "8.049",
      "Low": "8.049",
      "Change %": "-0.64%"
  },
  {
      "Date": "14-Jul-12",
      "Price": "8.101",
      "Open": "8.101",
      "High": "8.101",
      "Low": "8.101",
      "Change %": "0.06%"
  },
  {
      "Date": "13-Jul-12",
      "Price": "8.096",
      "Open": "8.096",
      "High": "8.096",
      "Low": "8.096",
      "Change %": "-0.10%"
  },
  {
      "Date": "12-Jul-12",
      "Price": "8.104",
      "Open": "8.104",
      "High": "8.104",
      "Low": "8.104",
      "Change %": "-0.47%"
  },
  {
      "Date": "11-Jul-12",
      "Price": "8.142",
      "Open": "8.142",
      "High": "8.142",
      "Low": "8.142",
      "Change %": "-0.11%"
  },
  {
      "Date": "10-Jul-12",
      "Price": "8.151",
      "Open": "8.151",
      "High": "8.151",
      "Low": "8.151",
      "Change %": "0.04%"
  },
  {
      "Date": "9-Jul-12",
      "Price": "8.148",
      "Open": "8.148",
      "High": "8.148",
      "Low": "8.148",
      "Change %": "-0.11%"
  },
  {
      "Date": "8-Jul-12",
      "Price": "8.157",
      "Open": "8.157",
      "High": "8.157",
      "Low": "8.157",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Jul-12",
      "Price": "8.157",
      "Open": "8.157",
      "High": "8.157",
      "Low": "8.157",
      "Change %": "0.04%"
  },
  {
      "Date": "6-Jul-12",
      "Price": "8.154",
      "Open": "8.154",
      "High": "8.154",
      "Low": "8.154",
      "Change %": "-0.26%"
  },
  {
      "Date": "5-Jul-12",
      "Price": "8.175",
      "Open": "8.175",
      "High": "8.175",
      "Low": "8.175",
      "Change %": "0.20%"
  },
  {
      "Date": "4-Jul-12",
      "Price": "8.159",
      "Open": "8.159",
      "High": "8.159",
      "Low": "8.159",
      "Change %": "-0.23%"
  },
  {
      "Date": "3-Jul-12",
      "Price": "8.178",
      "Open": "8.178",
      "High": "8.178",
      "Low": "8.178",
      "Change %": "-2.45%"
  },
  {
      "Date": "2-Jul-12",
      "Price": "8.383",
      "Open": "8.383",
      "High": "8.383",
      "Low": "8.383",
      "Change %": "-0.08%"
  },
  {
      "Date": "1-Jul-12",
      "Price": "8.39",
      "Open": "8.39",
      "High": "8.39",
      "Low": "8.39",
      "Change %": "0.00%"
  },
  {
      "Date": "30-Jun-12",
      "Price": "8.39",
      "Open": "8.39",
      "High": "8.39",
      "Low": "8.39",
      "Change %": "0.12%"
  },
  {
      "Date": "29-Jun-12",
      "Price": "8.38",
      "Open": "8.38",
      "High": "8.38",
      "Low": "8.38",
      "Change %": "0.19%"
  },
  {
      "Date": "28-Jun-12",
      "Price": "8.364",
      "Open": "8.364",
      "High": "8.364",
      "Low": "8.364",
      "Change %": "0.11%"
  },
  {
      "Date": "27-Jun-12",
      "Price": "8.355",
      "Open": "8.355",
      "High": "8.355",
      "Low": "8.355",
      "Change %": "0.14%"
  },
  {
      "Date": "26-Jun-12",
      "Price": "8.343",
      "Open": "8.343",
      "High": "8.343",
      "Low": "8.343",
      "Change %": "0.17%"
  },
  {
      "Date": "25-Jun-12",
      "Price": "8.329",
      "Open": "8.329",
      "High": "8.329",
      "Low": "8.329",
      "Change %": "-0.24%"
  },
  {
      "Date": "23-Jun-12",
      "Price": "8.349",
      "Open": "8.349",
      "High": "8.349",
      "Low": "8.349",
      "Change %": "0.04%"
  },
  {
      "Date": "22-Jun-12",
      "Price": "8.346",
      "Open": "8.346",
      "High": "8.346",
      "Low": "8.346",
      "Change %": "0.04%"
  },
  {
      "Date": "21-Jun-12",
      "Price": "8.343",
      "Open": "8.343",
      "High": "8.343",
      "Low": "8.343",
      "Change %": "-0.54%"
  },
  {
      "Date": "20-Jun-12",
      "Price": "8.388",
      "Open": "8.388",
      "High": "8.388",
      "Low": "8.388",
      "Change %": "0.00%"
  },
  {
      "Date": "19-Jun-12",
      "Price": "8.388",
      "Open": "8.388",
      "High": "8.388",
      "Low": "8.388",
      "Change %": "-0.47%"
  },
  {
      "Date": "18-Jun-12",
      "Price": "8.428",
      "Open": "8.428",
      "High": "8.428",
      "Low": "8.428",
      "Change %": "1.08%"
  },
  {
      "Date": "16-Jun-12",
      "Price": "8.338",
      "Open": "8.338",
      "High": "8.338",
      "Low": "8.338",
      "Change %": "0.02%"
  },
  {
      "Date": "15-Jun-12",
      "Price": "8.336",
      "Open": "8.336",
      "High": "8.336",
      "Low": "8.336",
      "Change %": "0.12%"
  },
  {
      "Date": "14-Jun-12",
      "Price": "8.326",
      "Open": "8.326",
      "High": "8.326",
      "Low": "8.326",
      "Change %": "0.41%"
  },
  {
      "Date": "13-Jun-12",
      "Price": "8.292",
      "Open": "8.292",
      "High": "8.292",
      "Low": "8.292",
      "Change %": "-0.02%"
  },
  {
      "Date": "12-Jun-12",
      "Price": "8.294",
      "Open": "8.294",
      "High": "8.294",
      "Low": "8.294",
      "Change %": "-0.42%"
  },
  {
      "Date": "11-Jun-12",
      "Price": "8.329",
      "Open": "8.329",
      "High": "8.329",
      "Low": "8.329",
      "Change %": "-0.37%"
  },
  {
      "Date": "9-Jun-12",
      "Price": "8.36",
      "Open": "8.36",
      "High": "8.36",
      "Low": "8.36",
      "Change %": "0.07%"
  },
  {
      "Date": "8-Jun-12",
      "Price": "8.354",
      "Open": "8.354",
      "High": "8.354",
      "Low": "8.354",
      "Change %": "0.08%"
  },
  {
      "Date": "7-Jun-12",
      "Price": "8.347",
      "Open": "8.347",
      "High": "8.347",
      "Low": "8.347",
      "Change %": "-0.29%"
  },
  {
      "Date": "6-Jun-12",
      "Price": "8.371",
      "Open": "8.371",
      "High": "8.371",
      "Low": "8.371",
      "Change %": "0.47%"
  },
  {
      "Date": "5-Jun-12",
      "Price": "8.332",
      "Open": "8.332",
      "High": "8.332",
      "Low": "8.332",
      "Change %": "-0.12%"
  },
  {
      "Date": "4-Jun-12",
      "Price": "8.342",
      "Open": "8.342",
      "High": "8.342",
      "Low": "8.342",
      "Change %": "-0.33%"
  },
  {
      "Date": "2-Jun-12",
      "Price": "8.37",
      "Open": "8.37",
      "High": "8.37",
      "Low": "8.37",
      "Change %": "0.01%"
  },
  {
      "Date": "1-Jun-12",
      "Price": "8.369",
      "Open": "8.369",
      "High": "8.369",
      "Low": "8.369",
      "Change %": "-0.10%"
  },
  {
      "Date": "31-May-12",
      "Price": "8.377",
      "Open": "8.377",
      "High": "8.377",
      "Low": "8.377",
      "Change %": "-1.71%"
  },
  {
      "Date": "30-May-12",
      "Price": "8.523",
      "Open": "8.523",
      "High": "8.523",
      "Low": "8.523",
      "Change %": "0.06%"
  },
  {
      "Date": "29-May-12",
      "Price": "8.518",
      "Open": "8.518",
      "High": "8.518",
      "Low": "8.518",
      "Change %": "0.07%"
  },
  {
      "Date": "28-May-12",
      "Price": "8.512",
      "Open": "8.512",
      "High": "8.512",
      "Low": "8.512",
      "Change %": "-0.02%"
  },
  {
      "Date": "26-May-12",
      "Price": "8.514",
      "Open": "8.514",
      "High": "8.514",
      "Low": "8.514",
      "Change %": "0.06%"
  },
  {
      "Date": "25-May-12",
      "Price": "8.509",
      "Open": "8.509",
      "High": "8.509",
      "Low": "8.509",
      "Change %": "0.14%"
  },
  {
      "Date": "24-May-12",
      "Price": "8.497",
      "Open": "8.497",
      "High": "8.497",
      "Low": "8.497",
      "Change %": "-0.19%"
  },
  {
      "Date": "23-May-12",
      "Price": "8.513",
      "Open": "8.513",
      "High": "8.513",
      "Low": "8.513",
      "Change %": "-0.08%"
  },
  {
      "Date": "22-May-12",
      "Price": "8.52",
      "Open": "8.52",
      "High": "8.52",
      "Low": "8.52",
      "Change %": "-0.26%"
  },
  {
      "Date": "21-May-12",
      "Price": "8.542",
      "Open": "8.542",
      "High": "8.542",
      "Low": "8.542",
      "Change %": "0.20%"
  },
  {
      "Date": "19-May-12",
      "Price": "8.525",
      "Open": "8.525",
      "High": "8.525",
      "Low": "8.525",
      "Change %": "0.00%"
  },
  {
      "Date": "18-May-12",
      "Price": "8.525",
      "Open": "8.525",
      "High": "8.525",
      "Low": "8.525",
      "Change %": "0.35%"
  },
  {
      "Date": "17-May-12",
      "Price": "8.495",
      "Open": "8.495",
      "High": "8.495",
      "Low": "8.495",
      "Change %": "-0.26%"
  },
  {
      "Date": "16-May-12",
      "Price": "8.517",
      "Open": "8.517",
      "High": "8.517",
      "Low": "8.517",
      "Change %": "0.26%"
  },
  {
      "Date": "15-May-12",
      "Price": "8.495",
      "Open": "8.495",
      "High": "8.495",
      "Low": "8.495",
      "Change %": "-0.27%"
  },
  {
      "Date": "14-May-12",
      "Price": "8.518",
      "Open": "8.518",
      "High": "8.518",
      "Low": "8.518",
      "Change %": "-0.60%"
  },
  {
      "Date": "12-May-12",
      "Price": "8.569",
      "Open": "8.569",
      "High": "8.569",
      "Low": "8.569",
      "Change %": "0.05%"
  },
  {
      "Date": "11-May-12",
      "Price": "8.565",
      "Open": "8.565",
      "High": "8.565",
      "Low": "8.565",
      "Change %": "0.04%"
  },
  {
      "Date": "10-May-12",
      "Price": "8.562",
      "Open": "8.562",
      "High": "8.562",
      "Low": "8.562",
      "Change %": "0.01%"
  },
  {
      "Date": "9-May-12",
      "Price": "8.561",
      "Open": "8.561",
      "High": "8.561",
      "Low": "8.561",
      "Change %": "0.23%"
  },
  {
      "Date": "8-May-12",
      "Price": "8.541",
      "Open": "8.541",
      "High": "8.541",
      "Low": "8.541",
      "Change %": "-1.71%"
  },
  {
      "Date": "7-May-12",
      "Price": "8.69",
      "Open": "8.69",
      "High": "8.69",
      "Low": "8.69",
      "Change %": "0.45%"
  },
  {
      "Date": "6-May-12",
      "Price": "8.651",
      "Open": "8.651",
      "High": "8.651",
      "Low": "8.651",
      "Change %": "0.00%"
  },
  {
      "Date": "5-May-12",
      "Price": "8.651",
      "Open": "8.651",
      "High": "8.651",
      "Low": "8.651",
      "Change %": "0.24%"
  },
  {
      "Date": "4-May-12",
      "Price": "8.63",
      "Open": "8.63",
      "High": "8.63",
      "Low": "8.63",
      "Change %": "0.13%"
  },
  {
      "Date": "3-May-12",
      "Price": "8.619",
      "Open": "8.619",
      "High": "8.619",
      "Low": "8.619",
      "Change %": "-0.19%"
  },
  {
      "Date": "2-May-12",
      "Price": "8.635",
      "Open": "8.635",
      "High": "8.635",
      "Low": "8.635",
      "Change %": "-0.46%"
  },
  {
      "Date": "1-May-12",
      "Price": "8.675",
      "Open": "8.675",
      "High": "8.675",
      "Low": "8.675",
      "Change %": "0.05%"
  },
  {
      "Date": "30-Apr-12",
      "Price": "8.671",
      "Open": "8.671",
      "High": "8.671",
      "Low": "8.671",
      "Change %": "0.22%"
  },
  {
      "Date": "28-Apr-12",
      "Price": "8.652",
      "Open": "8.652",
      "High": "8.652",
      "Low": "8.652",
      "Change %": "0.06%"
  },
  {
      "Date": "27-Apr-12",
      "Price": "8.647",
      "Open": "8.647",
      "High": "8.647",
      "Low": "8.647",
      "Change %": "-0.09%"
  },
  {
      "Date": "26-Apr-12",
      "Price": "8.655",
      "Open": "8.655",
      "High": "8.655",
      "Low": "8.655",
      "Change %": "0.31%"
  },
  {
      "Date": "25-Apr-12",
      "Price": "8.628",
      "Open": "8.628",
      "High": "8.628",
      "Low": "8.628",
      "Change %": "0.71%"
  },
  {
      "Date": "24-Apr-12",
      "Price": "8.567",
      "Open": "8.567",
      "High": "8.567",
      "Low": "8.567",
      "Change %": "-0.01%"
  },
  {
      "Date": "23-Apr-12",
      "Price": "8.568",
      "Open": "8.568",
      "High": "8.568",
      "Low": "8.568",
      "Change %": "0.23%"
  },
  {
      "Date": "21-Apr-12",
      "Price": "8.548",
      "Open": "8.548",
      "High": "8.548",
      "Low": "8.548",
      "Change %": "0.06%"
  },
  {
      "Date": "20-Apr-12",
      "Price": "8.543",
      "Open": "8.543",
      "High": "8.543",
      "Low": "8.543",
      "Change %": "1.59%"
  },
  {
      "Date": "19-Apr-12",
      "Price": "8.409",
      "Open": "8.409",
      "High": "8.409",
      "Low": "8.409",
      "Change %": "0.60%"
  },
  {
      "Date": "18-Apr-12",
      "Price": "8.359",
      "Open": "8.359",
      "High": "8.359",
      "Low": "8.359",
      "Change %": "0.22%"
  },
  {
      "Date": "17-Apr-12",
      "Price": "8.341",
      "Open": "8.341",
      "High": "8.341",
      "Low": "8.341",
      "Change %": "-1.35%"
  },
  {
      "Date": "16-Apr-12",
      "Price": "8.455",
      "Open": "8.455",
      "High": "8.455",
      "Low": "8.455",
      "Change %": "-0.19%"
  },
  {
      "Date": "14-Apr-12",
      "Price": "8.471",
      "Open": "8.471",
      "High": "8.471",
      "Low": "8.471",
      "Change %": "0.04%"
  },
  {
      "Date": "13-Apr-12",
      "Price": "8.468",
      "Open": "8.468",
      "High": "8.468",
      "Low": "8.468",
      "Change %": "0.34%"
  },
  {
      "Date": "12-Apr-12",
      "Price": "8.439",
      "Open": "8.439",
      "High": "8.439",
      "Low": "8.439",
      "Change %": "-1.24%"
  },
  {
      "Date": "11-Apr-12",
      "Price": "8.545",
      "Open": "8.545",
      "High": "8.545",
      "Low": "8.545",
      "Change %": "-0.54%"
  },
  {
      "Date": "10-Apr-12",
      "Price": "8.591",
      "Open": "8.591",
      "High": "8.591",
      "Low": "8.591",
      "Change %": "-0.41%"
  },
  {
      "Date": "9-Apr-12",
      "Price": "8.626",
      "Open": "8.626",
      "High": "8.626",
      "Low": "8.626",
      "Change %": "-0.77%"
  },
  {
      "Date": "8-Apr-12",
      "Price": "8.693",
      "Open": "8.693",
      "High": "8.693",
      "Low": "8.693",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Apr-12",
      "Price": "8.693",
      "Open": "8.693",
      "High": "8.693",
      "Low": "8.693",
      "Change %": "0.06%"
  },
  {
      "Date": "4-Apr-12",
      "Price": "8.688",
      "Open": "8.688",
      "High": "8.688",
      "Low": "8.688",
      "Change %": "-0.55%"
  },
  {
      "Date": "3-Apr-12",
      "Price": "8.736",
      "Open": "8.736",
      "High": "8.736",
      "Low": "8.736",
      "Change %": "1.72%"
  },
  {
      "Date": "31-Mar-12",
      "Price": "8.588",
      "Open": "8.588",
      "High": "8.588",
      "Low": "8.588",
      "Change %": "0.19%"
  },
  {
      "Date": "30-Mar-12",
      "Price": "8.572",
      "Open": "8.572",
      "High": "8.572",
      "Low": "8.572",
      "Change %": "-0.40%"
  },
  {
      "Date": "29-Mar-12",
      "Price": "8.606",
      "Open": "8.606",
      "High": "8.606",
      "Low": "8.606",
      "Change %": "-0.16%"
  },
  {
      "Date": "28-Mar-12",
      "Price": "8.62",
      "Open": "8.62",
      "High": "8.62",
      "Low": "8.62",
      "Change %": "1.39%"
  },
  {
      "Date": "27-Mar-12",
      "Price": "8.502",
      "Open": "8.502",
      "High": "8.502",
      "Low": "8.502",
      "Change %": "0.35%"
  },
  {
      "Date": "26-Mar-12",
      "Price": "8.472",
      "Open": "8.472",
      "High": "8.472",
      "Low": "8.472",
      "Change %": "1.05%"
  },
  {
      "Date": "23-Mar-12",
      "Price": "8.384",
      "Open": "8.384",
      "High": "8.384",
      "Low": "8.384",
      "Change %": "0.10%"
  },
  {
      "Date": "22-Mar-12",
      "Price": "8.376",
      "Open": "8.376",
      "High": "8.376",
      "Low": "8.376",
      "Change %": "-0.18%"
  },
  {
      "Date": "21-Mar-12",
      "Price": "8.391",
      "Open": "8.391",
      "High": "8.391",
      "Low": "8.391",
      "Change %": "-0.24%"
  },
  {
      "Date": "20-Mar-12",
      "Price": "8.411",
      "Open": "8.411",
      "High": "8.411",
      "Low": "8.411",
      "Change %": "-0.06%"
  },
  {
      "Date": "19-Mar-12",
      "Price": "8.416",
      "Open": "8.416",
      "High": "8.416",
      "Low": "8.416",
      "Change %": "-0.14%"
  },
  {
      "Date": "17-Mar-12",
      "Price": "8.428",
      "Open": "8.428",
      "High": "8.428",
      "Low": "8.428",
      "Change %": "0.07%"
  },
  {
      "Date": "16-Mar-12",
      "Price": "8.422",
      "Open": "8.422",
      "High": "8.422",
      "Low": "8.422",
      "Change %": "0.74%"
  },
  {
      "Date": "15-Mar-12",
      "Price": "8.36",
      "Open": "8.36",
      "High": "8.36",
      "Low": "8.36",
      "Change %": "0.95%"
  },
  {
      "Date": "14-Mar-12",
      "Price": "8.281",
      "Open": "8.281",
      "High": "8.281",
      "Low": "8.281",
      "Change %": "-0.48%"
  },
  {
      "Date": "13-Mar-12",
      "Price": "8.321",
      "Open": "8.321",
      "High": "8.321",
      "Low": "8.321",
      "Change %": "0.33%"
  },
  {
      "Date": "12-Mar-12",
      "Price": "8.294",
      "Open": "8.294",
      "High": "8.294",
      "Low": "8.294",
      "Change %": "0.11%"
  },
  {
      "Date": "10-Mar-12",
      "Price": "8.285",
      "Open": "8.285",
      "High": "8.285",
      "Low": "8.285",
      "Change %": "0.05%"
  },
  {
      "Date": "9-Mar-12",
      "Price": "8.281",
      "Open": "8.281",
      "High": "8.281",
      "Low": "8.281",
      "Change %": "0.47%"
  },
  {
      "Date": "8-Mar-12",
      "Price": "8.242",
      "Open": "8.242",
      "High": "8.242",
      "Low": "8.242",
      "Change %": "0.01%"
  },
  {
      "Date": "7-Mar-12",
      "Price": "8.241",
      "Open": "8.241",
      "High": "8.241",
      "Low": "8.241",
      "Change %": "0.11%"
  },
  {
      "Date": "6-Mar-12",
      "Price": "8.232",
      "Open": "8.232",
      "High": "8.232",
      "Low": "8.232",
      "Change %": "0.05%"
  },
  {
      "Date": "5-Mar-12",
      "Price": "8.228",
      "Open": "8.228",
      "High": "8.228",
      "Low": "8.228",
      "Change %": "0.04%"
  },
  {
      "Date": "3-Mar-12",
      "Price": "8.225",
      "Open": "8.225",
      "High": "8.225",
      "Low": "8.225",
      "Change %": "0.01%"
  },
  {
      "Date": "2-Mar-12",
      "Price": "8.224",
      "Open": "8.224",
      "High": "8.224",
      "Low": "8.224",
      "Change %": "-0.16%"
  },
  {
      "Date": "1-Mar-12",
      "Price": "8.237",
      "Open": "8.237",
      "High": "8.237",
      "Low": "8.237",
      "Change %": "0.46%"
  },
  {
      "Date": "29-Feb-12",
      "Price": "8.199",
      "Open": "8.199",
      "High": "8.199",
      "Low": "8.199",
      "Change %": "-0.16%"
  },
  {
      "Date": "28-Feb-12",
      "Price": "8.212",
      "Open": "8.212",
      "High": "8.212",
      "Low": "8.212",
      "Change %": "-0.21%"
  },
  {
      "Date": "27-Feb-12",
      "Price": "8.229",
      "Open": "8.229",
      "High": "8.229",
      "Low": "8.229",
      "Change %": "0.02%"
  },
  {
      "Date": "25-Feb-12",
      "Price": "8.227",
      "Open": "8.227",
      "High": "8.227",
      "Low": "8.227",
      "Change %": "0.01%"
  },
  {
      "Date": "24-Feb-12",
      "Price": "8.226",
      "Open": "8.226",
      "High": "8.226",
      "Low": "8.226",
      "Change %": "0.37%"
  },
  {
      "Date": "23-Feb-12",
      "Price": "8.196",
      "Open": "8.196",
      "High": "8.196",
      "Low": "8.196",
      "Change %": "-0.32%"
  },
  {
      "Date": "22-Feb-12",
      "Price": "8.222",
      "Open": "8.222",
      "High": "8.222",
      "Low": "8.222",
      "Change %": "0.60%"
  },
  {
      "Date": "21-Feb-12",
      "Price": "8.173",
      "Open": "8.173",
      "High": "8.173",
      "Low": "8.173",
      "Change %": "-0.18%"
  },
  {
      "Date": "19-Feb-12",
      "Price": "8.188",
      "Open": "8.188",
      "High": "8.188",
      "Low": "8.188",
      "Change %": "0.00%"
  },
  {
      "Date": "18-Feb-12",
      "Price": "8.188",
      "Open": "8.188",
      "High": "8.188",
      "Low": "8.188",
      "Change %": "0.04%"
  },
  {
      "Date": "17-Feb-12",
      "Price": "8.185",
      "Open": "8.185",
      "High": "8.185",
      "Low": "8.185",
      "Change %": "-0.15%"
  },
  {
      "Date": "16-Feb-12",
      "Price": "8.197",
      "Open": "8.197",
      "High": "8.197",
      "Low": "8.197",
      "Change %": "0.00%"
  },
  {
      "Date": "15-Feb-12",
      "Price": "8.197",
      "Open": "8.197",
      "High": "8.197",
      "Low": "8.197",
      "Change %": "0.21%"
  },
  {
      "Date": "14-Feb-12",
      "Price": "8.18",
      "Open": "8.18",
      "High": "8.18",
      "Low": "8.18",
      "Change %": "-0.35%"
  },
  {
      "Date": "13-Feb-12",
      "Price": "8.209",
      "Open": "8.209",
      "High": "8.209",
      "Low": "8.209",
      "Change %": "0.09%"
  },
  {
      "Date": "11-Feb-12",
      "Price": "8.202",
      "Open": "8.202",
      "High": "8.202",
      "Low": "8.202",
      "Change %": "0.10%"
  },
  {
      "Date": "10-Feb-12",
      "Price": "8.194",
      "Open": "8.194",
      "High": "8.194",
      "Low": "8.194",
      "Change %": "-0.94%"
  },
  {
      "Date": "9-Feb-12",
      "Price": "8.272",
      "Open": "8.272",
      "High": "8.272",
      "Low": "8.272",
      "Change %": "0.49%"
  },
  {
      "Date": "8-Feb-12",
      "Price": "8.232",
      "Open": "8.232",
      "High": "8.232",
      "Low": "8.232",
      "Change %": "0.54%"
  },
  {
      "Date": "7-Feb-12",
      "Price": "8.188",
      "Open": "8.188",
      "High": "8.188",
      "Low": "8.188",
      "Change %": "0.06%"
  },
  {
      "Date": "6-Feb-12",
      "Price": "8.183",
      "Open": "8.183",
      "High": "8.183",
      "Low": "8.183",
      "Change %": "0.23%"
  },
  {
      "Date": "3-Feb-12",
      "Price": "8.164",
      "Open": "8.164",
      "High": "8.164",
      "Low": "8.164",
      "Change %": "0.41%"
  },
  {
      "Date": "2-Feb-12",
      "Price": "8.131",
      "Open": "8.131",
      "High": "8.131",
      "Low": "8.131",
      "Change %": "-0.15%"
  },
  {
      "Date": "1-Feb-12",
      "Price": "8.143",
      "Open": "8.143",
      "High": "8.143",
      "Low": "8.143",
      "Change %": "-1.50%"
  },
  {
      "Date": "31-Jan-12",
      "Price": "8.267",
      "Open": "8.267",
      "High": "8.267",
      "Low": "8.267",
      "Change %": "-0.21%"
  },
  {
      "Date": "30-Jan-12",
      "Price": "8.284",
      "Open": "8.284",
      "High": "8.284",
      "Low": "8.284",
      "Change %": "-0.84%"
  },
  {
      "Date": "27-Jan-12",
      "Price": "8.354",
      "Open": "8.354",
      "High": "8.354",
      "Low": "8.354",
      "Change %": "0.53%"
  },
  {
      "Date": "25-Jan-12",
      "Price": "8.31",
      "Open": "8.31",
      "High": "8.31",
      "Low": "8.31",
      "Change %": "-0.54%"
  },
  {
      "Date": "24-Jan-12",
      "Price": "8.355",
      "Open": "8.355",
      "High": "8.355",
      "Low": "8.355",
      "Change %": "2.26%"
  },
  {
      "Date": "23-Jan-12",
      "Price": "8.17",
      "Open": "8.17",
      "High": "8.17",
      "Low": "8.17",
      "Change %": "-0.13%"
  },
  {
      "Date": "20-Jan-12",
      "Price": "8.181",
      "Open": "8.181",
      "High": "8.181",
      "Low": "8.181",
      "Change %": "0.15%"
  },
  {
      "Date": "19-Jan-12",
      "Price": "8.169",
      "Open": "8.169",
      "High": "8.169",
      "Low": "8.169",
      "Change %": "-0.26%"
  },
  {
      "Date": "18-Jan-12",
      "Price": "8.19",
      "Open": "8.19",
      "High": "8.19",
      "Low": "8.19",
      "Change %": "-0.34%"
  },
  {
      "Date": "17-Jan-12",
      "Price": "8.218",
      "Open": "8.218",
      "High": "8.218",
      "Low": "8.218",
      "Change %": "-0.05%"
  },
  {
      "Date": "16-Jan-12",
      "Price": "8.222",
      "Open": "8.222",
      "High": "8.222",
      "Low": "8.222",
      "Change %": "0.37%"
  },
  {
      "Date": "13-Jan-12",
      "Price": "8.192",
      "Open": "8.192",
      "High": "8.192",
      "Low": "8.192",
      "Change %": "-0.72%"
  },
  {
      "Date": "12-Jan-12",
      "Price": "8.251",
      "Open": "8.251",
      "High": "8.251",
      "Low": "8.251",
      "Change %": "0.23%"
  },
  {
      "Date": "11-Jan-12",
      "Price": "8.232",
      "Open": "8.232",
      "High": "8.232",
      "Low": "8.232",
      "Change %": "0.05%"
  },
  {
      "Date": "10-Jan-12",
      "Price": "8.228",
      "Open": "8.228",
      "High": "8.228",
      "Low": "8.228",
      "Change %": "0.33%"
  },
  {
      "Date": "9-Jan-12",
      "Price": "8.201",
      "Open": "8.201",
      "High": "8.201",
      "Low": "8.201",
      "Change %": "-0.22%"
  },
  {
      "Date": "6-Jan-12",
      "Price": "8.219",
      "Open": "8.219",
      "High": "8.219",
      "Low": "8.219",
      "Change %": "-1.31%"
  },
  {
      "Date": "5-Jan-12",
      "Price": "8.328",
      "Open": "8.328",
      "High": "8.328",
      "Low": "8.328",
      "Change %": "-0.43%"
  },
  {
      "Date": "4-Jan-12",
      "Price": "8.364",
      "Open": "8.364",
      "High": "8.364",
      "Low": "8.364",
      "Change %": "0.08%"
  },
  {
      "Date": "3-Jan-12",
      "Price": "8.357",
      "Open": "8.357",
      "High": "8.357",
      "Low": "8.357",
      "Change %": "-0.62%"
  },
  {
      "Date": "2-Jan-12",
      "Price": "8.409",
      "Open": "8.409",
      "High": "8.409",
      "Low": "8.409",
      "Change %": "-1.76%"
  },
  {
      "Date": "30-Dec-11",
      "Price": "8.56",
      "Open": "8.56",
      "High": "8.56",
      "Low": "8.56",
      "Change %": "0.28%"
  },
  {
      "Date": "29-Dec-11",
      "Price": "8.536",
      "Open": "8.536",
      "High": "8.536",
      "Low": "8.536",
      "Change %": "0.68%"
  },
  {
      "Date": "28-Dec-11",
      "Price": "8.478",
      "Open": "8.478",
      "High": "8.478",
      "Low": "8.478",
      "Change %": "0.02%"
  },
  {
      "Date": "27-Dec-11",
      "Price": "8.476",
      "Open": "8.476",
      "High": "8.476",
      "Low": "8.476",
      "Change %": "-0.16%"
  },
  {
      "Date": "26-Dec-11",
      "Price": "8.49",
      "Open": "8.49",
      "High": "8.49",
      "Low": "8.49",
      "Change %": "1.49%"
  },
  {
      "Date": "23-Dec-11",
      "Price": "8.365",
      "Open": "8.365",
      "High": "8.365",
      "Low": "8.365",
      "Change %": "0.38%"
  },
  {
      "Date": "22-Dec-11",
      "Price": "8.333",
      "Open": "8.333",
      "High": "8.333",
      "Low": "8.333",
      "Change %": "-0.08%"
  },
  {
      "Date": "21-Dec-11",
      "Price": "8.34",
      "Open": "8.34",
      "High": "8.34",
      "Low": "8.34",
      "Change %": "0.77%"
  },
  {
      "Date": "20-Dec-11",
      "Price": "8.276",
      "Open": "8.276",
      "High": "8.276",
      "Low": "8.276",
      "Change %": "-0.64%"
  },
  {
      "Date": "19-Dec-11",
      "Price": "8.329",
      "Open": "8.329",
      "High": "8.329",
      "Low": "8.329",
      "Change %": "-0.60%"
  },
  {
      "Date": "16-Dec-11",
      "Price": "8.379",
      "Open": "8.379",
      "High": "8.379",
      "Low": "8.379",
      "Change %": "-1.26%"
  },
  {
      "Date": "15-Dec-11",
      "Price": "8.486",
      "Open": "8.486",
      "High": "8.486",
      "Low": "8.486",
      "Change %": "-0.06%"
  },
  {
      "Date": "14-Dec-11",
      "Price": "8.491",
      "Open": "8.491",
      "High": "8.491",
      "Low": "8.491",
      "Change %": "1.05%"
  },
  {
      "Date": "13-Dec-11",
      "Price": "8.403",
      "Open": "8.403",
      "High": "8.403",
      "Low": "8.403",
      "Change %": "-0.51%"
  },
  {
      "Date": "12-Dec-11",
      "Price": "8.446",
      "Open": "8.446",
      "High": "8.446",
      "Low": "8.446",
      "Change %": "-1.02%"
  },
  {
      "Date": "9-Dec-11",
      "Price": "8.533",
      "Open": "8.533",
      "High": "8.533",
      "Low": "8.533",
      "Change %": "0.16%"
  },
  {
      "Date": "8-Dec-11",
      "Price": "8.519",
      "Open": "8.519",
      "High": "8.519",
      "Low": "8.519",
      "Change %": "-0.87%"
  },
  {
      "Date": "7-Dec-11",
      "Price": "8.594",
      "Open": "8.594",
      "High": "8.594",
      "Low": "8.594",
      "Change %": "0.20%"
  },
  {
      "Date": "5-Dec-11",
      "Price": "8.577",
      "Open": "8.577",
      "High": "8.577",
      "Low": "8.577",
      "Change %": "-0.81%"
  },
  {
      "Date": "2-Dec-11",
      "Price": "8.647",
      "Open": "8.647",
      "High": "8.647",
      "Low": "8.647",
      "Change %": "-0.57%"
  },
  {
      "Date": "1-Dec-11",
      "Price": "8.697",
      "Open": "8.697",
      "High": "8.697",
      "Low": "8.697",
      "Change %": "-0.47%"
  },
  {
      "Date": "30-Nov-11",
      "Price": "8.738",
      "Open": "8.738",
      "High": "8.738",
      "Low": "8.738",
      "Change %": "-0.99%"
  },
  {
      "Date": "29-Nov-11",
      "Price": "8.825",
      "Open": "8.825",
      "High": "8.825",
      "Low": "8.825",
      "Change %": "-0.02%"
  },
  {
      "Date": "28-Nov-11",
      "Price": "8.827",
      "Open": "8.827",
      "High": "8.827",
      "Low": "8.827",
      "Change %": "0.18%"
  },
  {
      "Date": "25-Nov-11",
      "Price": "8.811",
      "Open": "8.811",
      "High": "8.811",
      "Low": "8.811",
      "Change %": "0.20%"
  },
  {
      "Date": "24-Nov-11",
      "Price": "8.793",
      "Open": "8.793",
      "High": "8.793",
      "Low": "8.793",
      "Change %": "-0.22%"
  },
  {
      "Date": "23-Nov-11",
      "Price": "8.812",
      "Open": "8.812",
      "High": "8.812",
      "Low": "8.812",
      "Change %": "-0.36%"
  },
  {
      "Date": "22-Nov-11",
      "Price": "8.844",
      "Open": "8.844",
      "High": "8.844",
      "Low": "8.844",
      "Change %": "0.08%"
  },
  {
      "Date": "21-Nov-11",
      "Price": "8.837",
      "Open": "8.837",
      "High": "8.837",
      "Low": "8.837",
      "Change %": "-0.21%"
  },
  {
      "Date": "18-Nov-11",
      "Price": "8.856",
      "Open": "8.856",
      "High": "8.856",
      "Low": "8.856",
      "Change %": "0.20%"
  },
  {
      "Date": "17-Nov-11",
      "Price": "8.838",
      "Open": "8.838",
      "High": "8.838",
      "Low": "8.838",
      "Change %": "-1.55%"
  },
  {
      "Date": "16-Nov-11",
      "Price": "8.977",
      "Open": "8.977",
      "High": "8.977",
      "Low": "8.977",
      "Change %": "0.08%"
  },
  {
      "Date": "15-Nov-11",
      "Price": "8.97",
      "Open": "8.97",
      "High": "8.97",
      "Low": "8.97",
      "Change %": "-0.74%"
  },
  {
      "Date": "14-Nov-11",
      "Price": "9.037",
      "Open": "9.037",
      "High": "9.037",
      "Low": "9.037",
      "Change %": "-0.19%"
  },
  {
      "Date": "11-Nov-11",
      "Price": "9.054",
      "Open": "9.054",
      "High": "9.054",
      "Low": "9.054",
      "Change %": "-0.08%"
  },
  {
      "Date": "9-Nov-11",
      "Price": "9.061",
      "Open": "9.061",
      "High": "9.061",
      "Low": "9.061",
      "Change %": "0.30%"
  },
  {
      "Date": "8-Nov-11",
      "Price": "9.034",
      "Open": "9.034",
      "High": "9.034",
      "Low": "9.034",
      "Change %": "0.74%"
  },
  {
      "Date": "4-Nov-11",
      "Price": "8.968",
      "Open": "8.968",
      "High": "8.968",
      "Low": "8.968",
      "Change %": "0.75%"
  },
  {
      "Date": "3-Nov-11",
      "Price": "8.901",
      "Open": "8.901",
      "High": "8.901",
      "Low": "8.901",
      "Change %": "-0.03%"
  },
  {
      "Date": "2-Nov-11",
      "Price": "8.904",
      "Open": "8.904",
      "High": "8.904",
      "Low": "8.904",
      "Change %": "-0.48%"
  },
  {
      "Date": "1-Nov-11",
      "Price": "8.947",
      "Open": "8.947",
      "High": "8.947",
      "Low": "8.947",
      "Change %": "0.77%"
  },
  {
      "Date": "31-Oct-11",
      "Price": "8.879",
      "Open": "8.879",
      "High": "8.879",
      "Low": "8.879",
      "Change %": "0.31%"
  },
  {
      "Date": "28-Oct-11",
      "Price": "8.852",
      "Open": "8.852",
      "High": "8.852",
      "Low": "8.852",
      "Change %": "1.02%"
  },
  {
      "Date": "25-Oct-11",
      "Price": "8.763",
      "Open": "8.763",
      "High": "8.763",
      "Low": "8.763",
      "Change %": "-0.61%"
  },
  {
      "Date": "24-Oct-11",
      "Price": "8.817",
      "Open": "8.817",
      "High": "8.817",
      "Low": "8.817",
      "Change %": "-0.03%"
  },
  {
      "Date": "21-Oct-11",
      "Price": "8.82",
      "Open": "8.82",
      "High": "8.82",
      "Low": "8.82",
      "Change %": "0.34%"
  },
  {
      "Date": "20-Oct-11",
      "Price": "8.79",
      "Open": "8.79",
      "High": "8.79",
      "Low": "8.79",
      "Change %": "0.17%"
  },
  {
      "Date": "19-Oct-11",
      "Price": "8.775",
      "Open": "8.775",
      "High": "8.775",
      "Low": "8.775",
      "Change %": "0.31%"
  },
  {
      "Date": "18-Oct-11",
      "Price": "8.748",
      "Open": "8.748",
      "High": "8.748",
      "Low": "8.748",
      "Change %": "-0.10%"
  },
  {
      "Date": "17-Oct-11",
      "Price": "8.757",
      "Open": "8.757",
      "High": "8.757",
      "Low": "8.757",
      "Change %": "-0.31%"
  },
  {
      "Date": "14-Oct-11",
      "Price": "8.784",
      "Open": "8.784",
      "High": "8.784",
      "Low": "8.784",
      "Change %": "0.68%"
  },
  {
      "Date": "13-Oct-11",
      "Price": "8.725",
      "Open": "8.725",
      "High": "8.725",
      "Low": "8.725",
      "Change %": "-0.15%"
  },
  {
      "Date": "12-Oct-11",
      "Price": "8.738",
      "Open": "8.738",
      "High": "8.738",
      "Low": "8.738",
      "Change %": "0.32%"
  },
  {
      "Date": "11-Oct-11",
      "Price": "8.71",
      "Open": "8.71",
      "High": "8.71",
      "Low": "8.71",
      "Change %": "-0.47%"
  },
  {
      "Date": "10-Oct-11",
      "Price": "8.751",
      "Open": "8.751",
      "High": "8.751",
      "Low": "8.751",
      "Change %": "0.98%"
  },
  {
      "Date": "7-Oct-11",
      "Price": "8.666",
      "Open": "8.666",
      "High": "8.666",
      "Low": "8.666",
      "Change %": "1.03%"
  },
  {
      "Date": "5-Oct-11",
      "Price": "8.578",
      "Open": "8.578",
      "High": "8.578",
      "Low": "8.578",
      "Change %": "0.39%"
  },
  {
      "Date": "4-Oct-11",
      "Price": "8.545",
      "Open": "8.545",
      "High": "8.545",
      "Low": "8.545",
      "Change %": "0.09%"
  },
  {
      "Date": "3-Oct-11",
      "Price": "8.537",
      "Open": "8.537",
      "High": "8.537",
      "Low": "8.537",
      "Change %": "1.13%"
  },
  {
      "Date": "29-Sep-11",
      "Price": "8.442",
      "Open": "8.442",
      "High": "8.442",
      "Low": "8.442",
      "Change %": "1.14%"
  },
  {
      "Date": "28-Sep-11",
      "Price": "8.347",
      "Open": "8.347",
      "High": "8.347",
      "Low": "8.347",
      "Change %": "0.25%"
  },
  {
      "Date": "27-Sep-11",
      "Price": "8.326",
      "Open": "8.326",
      "High": "8.326",
      "Low": "8.326",
      "Change %": "0.18%"
  },
  {
      "Date": "26-Sep-11",
      "Price": "8.311",
      "Open": "8.311",
      "High": "8.311",
      "Low": "8.311",
      "Change %": "0.11%"
  },
  {
      "Date": "23-Sep-11",
      "Price": "8.302",
      "Open": "8.302",
      "High": "8.302",
      "Low": "8.302",
      "Change %": "0.02%"
  },
  {
      "Date": "22-Sep-11",
      "Price": "8.3",
      "Open": "8.3",
      "High": "8.3",
      "Low": "8.3",
      "Change %": "-0.34%"
  },
  {
      "Date": "21-Sep-11",
      "Price": "8.328",
      "Open": "8.328",
      "High": "8.328",
      "Low": "8.328",
      "Change %": "-0.11%"
  },
  {
      "Date": "20-Sep-11",
      "Price": "8.337",
      "Open": "8.337",
      "High": "8.337",
      "Low": "8.337",
      "Change %": "0.10%"
  },
  {
      "Date": "19-Sep-11",
      "Price": "8.329",
      "Open": "8.329",
      "High": "8.329",
      "Low": "8.329",
      "Change %": "-0.39%"
  },
  {
      "Date": "16-Sep-11",
      "Price": "8.362",
      "Open": "8.362",
      "High": "8.362",
      "Low": "8.362",
      "Change %": "0.40%"
  },
  {
      "Date": "15-Sep-11",
      "Price": "8.329",
      "Open": "8.329",
      "High": "8.329",
      "Low": "8.329",
      "Change %": "-0.22%"
  },
  {
      "Date": "14-Sep-11",
      "Price": "8.347",
      "Open": "8.347",
      "High": "8.347",
      "Low": "8.347",
      "Change %": "0.26%"
  },
  {
      "Date": "13-Sep-11",
      "Price": "8.325",
      "Open": "8.325",
      "High": "8.325",
      "Low": "8.325",
      "Change %": "0.40%"
  },
  {
      "Date": "12-Sep-11",
      "Price": "8.292",
      "Open": "8.292",
      "High": "8.292",
      "Low": "8.292",
      "Change %": "-0.26%"
  },
  {
      "Date": "9-Sep-11",
      "Price": "8.314",
      "Open": "8.314",
      "High": "8.314",
      "Low": "8.314",
      "Change %": "0.22%"
  },
  {
      "Date": "8-Sep-11",
      "Price": "8.296",
      "Open": "8.296",
      "High": "8.296",
      "Low": "8.296",
      "Change %": "-0.17%"
  },
  {
      "Date": "7-Sep-11",
      "Price": "8.31",
      "Open": "8.31",
      "High": "8.31",
      "Low": "8.31",
      "Change %": "0.07%"
  },
  {
      "Date": "6-Sep-11",
      "Price": "8.304",
      "Open": "8.304",
      "High": "8.304",
      "Low": "8.304",
      "Change %": "0.19%"
  },
  {
      "Date": "5-Sep-11",
      "Price": "8.288",
      "Open": "8.288",
      "High": "8.288",
      "Low": "8.288",
      "Change %": "-0.73%"
  },
  {
      "Date": "2-Sep-11",
      "Price": "8.349",
      "Open": "8.349",
      "High": "8.349",
      "Low": "8.349",
      "Change %": "0.36%"
  },
  {
      "Date": "30-Aug-11",
      "Price": "8.319",
      "Open": "8.319",
      "High": "8.319",
      "Low": "8.319",
      "Change %": "-0.08%"
  },
  {
      "Date": "29-Aug-11",
      "Price": "8.326",
      "Open": "8.326",
      "High": "8.326",
      "Low": "8.326",
      "Change %": "0.28%"
  },
  {
      "Date": "26-Aug-11",
      "Price": "8.303",
      "Open": "8.303",
      "High": "8.303",
      "Low": "8.303",
      "Change %": "0.70%"
  },
  {
      "Date": "25-Aug-11",
      "Price": "8.245",
      "Open": "8.245",
      "High": "8.245",
      "Low": "8.245",
      "Change %": "0.33%"
  },
  {
      "Date": "24-Aug-11",
      "Price": "8.218",
      "Open": "8.218",
      "High": "8.218",
      "Low": "8.218",
      "Change %": "-0.57%"
  },
  {
      "Date": "23-Aug-11",
      "Price": "8.265",
      "Open": "8.265",
      "High": "8.265",
      "Low": "8.265",
      "Change %": "-0.05%"
  },
  {
      "Date": "22-Aug-11",
      "Price": "8.269",
      "Open": "8.269",
      "High": "8.269",
      "Low": "8.269",
      "Change %": "0.15%"
  },
  {
      "Date": "18-Aug-11",
      "Price": "8.257",
      "Open": "8.257",
      "High": "8.257",
      "Low": "8.257",
      "Change %": "-0.78%"
  },
  {
      "Date": "17-Aug-11",
      "Price": "8.322",
      "Open": "8.322",
      "High": "8.322",
      "Low": "8.322",
      "Change %": "0.39%"
  },
  {
      "Date": "16-Aug-11",
      "Price": "8.29",
      "Open": "8.29",
      "High": "8.29",
      "Low": "8.29",
      "Change %": "-0.12%"
  },
  {
      "Date": "12-Aug-11",
      "Price": "8.3",
      "Open": "8.3",
      "High": "8.3",
      "Low": "8.3",
      "Change %": "1.11%"
  },
  {
      "Date": "11-Aug-11",
      "Price": "8.209",
      "Open": "8.209",
      "High": "8.209",
      "Low": "8.209",
      "Change %": "0.21%"
  },
  {
      "Date": "10-Aug-11",
      "Price": "8.192",
      "Open": "8.192",
      "High": "8.192",
      "Low": "8.192",
      "Change %": "-0.43%"
  },
  {
      "Date": "9-Aug-11",
      "Price": "8.227",
      "Open": "8.227",
      "High": "8.227",
      "Low": "8.227",
      "Change %": "-0.41%"
  },
  {
      "Date": "8-Aug-11",
      "Price": "8.261",
      "Open": "8.261",
      "High": "8.261",
      "Low": "8.261",
      "Change %": "-0.66%"
  },
  {
      "Date": "5-Aug-11",
      "Price": "8.316",
      "Open": "8.316",
      "High": "8.316",
      "Low": "8.316",
      "Change %": "-1.04%"
  },
  {
      "Date": "4-Aug-11",
      "Price": "8.403",
      "Open": "8.403",
      "High": "8.403",
      "Low": "8.403",
      "Change %": "-0.24%"
  },
  {
      "Date": "3-Aug-11",
      "Price": "8.423",
      "Open": "8.423",
      "High": "8.423",
      "Low": "8.423",
      "Change %": "-0.15%"
  },
  {
      "Date": "2-Aug-11",
      "Price": "8.436",
      "Open": "8.436",
      "High": "8.436",
      "Low": "8.436",
      "Change %": "-0.30%"
  },
  {
      "Date": "1-Aug-11",
      "Price": "8.461",
      "Open": "8.461",
      "High": "8.461",
      "Low": "8.461",
      "Change %": "0.08%"
  },
  {
      "Date": "29-Jul-11",
      "Price": "8.454",
      "Open": "8.454",
      "High": "8.454",
      "Low": "8.454",
      "Change %": "-0.13%"
  },
  {
      "Date": "28-Jul-11",
      "Price": "8.465",
      "Open": "8.465",
      "High": "8.465",
      "Low": "8.465",
      "Change %": "0.17%"
  },
  {
      "Date": "27-Jul-11",
      "Price": "8.451",
      "Open": "8.451",
      "High": "8.451",
      "Low": "8.451",
      "Change %": "0.12%"
  },
  {
      "Date": "26-Jul-11",
      "Price": "8.441",
      "Open": "8.441",
      "High": "8.441",
      "Low": "8.441",
      "Change %": "1.77%"
  },
  {
      "Date": "25-Jul-11",
      "Price": "8.294",
      "Open": "8.294",
      "High": "8.294",
      "Low": "8.294",
      "Change %": "-0.24%"
  },
  {
      "Date": "22-Jul-11",
      "Price": "8.314",
      "Open": "8.314",
      "High": "8.314",
      "Low": "8.314",
      "Change %": "0.48%"
  },
  {
      "Date": "21-Jul-11",
      "Price": "8.274",
      "Open": "8.274",
      "High": "8.274",
      "Low": "8.274",
      "Change %": "-0.07%"
  },
  {
      "Date": "20-Jul-11",
      "Price": "8.28",
      "Open": "8.28",
      "High": "8.28",
      "Low": "8.28",
      "Change %": "0.25%"
  },
  {
      "Date": "19-Jul-11",
      "Price": "8.259",
      "Open": "8.259",
      "High": "8.259",
      "Low": "8.259",
      "Change %": "0.11%"
  },
  {
      "Date": "18-Jul-11",
      "Price": "8.25",
      "Open": "8.25",
      "High": "8.25",
      "Low": "8.25",
      "Change %": "-0.34%"
  },
  {
      "Date": "15-Jul-11",
      "Price": "8.278",
      "Open": "8.278",
      "High": "8.278",
      "Low": "8.278",
      "Change %": "0.38%"
  },
  {
      "Date": "14-Jul-11",
      "Price": "8.247",
      "Open": "8.247",
      "High": "8.247",
      "Low": "8.247",
      "Change %": "-0.70%"
  },
  {
      "Date": "13-Jul-11",
      "Price": "8.305",
      "Open": "8.305",
      "High": "8.305",
      "Low": "8.305",
      "Change %": "0.30%"
  },
  {
      "Date": "12-Jul-11",
      "Price": "8.28",
      "Open": "8.28",
      "High": "8.28",
      "Low": "8.28",
      "Change %": "-0.49%"
  },
  {
      "Date": "11-Jul-11",
      "Price": "8.321",
      "Open": "8.321",
      "High": "8.321",
      "Low": "8.321",
      "Change %": "-0.41%"
  },
  {
      "Date": "8-Jul-11",
      "Price": "8.355",
      "Open": "8.355",
      "High": "8.355",
      "Low": "8.355",
      "Change %": "-0.05%"
  },
  {
      "Date": "7-Jul-11",
      "Price": "8.359",
      "Open": "8.359",
      "High": "8.359",
      "Low": "8.359",
      "Change %": "0.31%"
  },
  {
      "Date": "6-Jul-11",
      "Price": "8.333",
      "Open": "8.333",
      "High": "8.333",
      "Low": "8.333",
      "Change %": "-0.12%"
  },
  {
      "Date": "5-Jul-11",
      "Price": "8.343",
      "Open": "8.343",
      "High": "8.343",
      "Low": "8.343",
      "Change %": "-0.17%"
  },
  {
      "Date": "4-Jul-11",
      "Price": "8.357",
      "Open": "8.357",
      "High": "8.357",
      "Low": "8.357",
      "Change %": "0.04%"
  },
  {
      "Date": "1-Jul-11",
      "Price": "8.354",
      "Open": "8.354",
      "High": "8.354",
      "Low": "8.354",
      "Change %": "0.34%"
  },
  {
      "Date": "30-Jun-11",
      "Price": "8.326",
      "Open": "8.326",
      "High": "8.326",
      "Low": "8.326",
      "Change %": "0.08%"
  },
  {
      "Date": "29-Jun-11",
      "Price": "8.319",
      "Open": "8.319",
      "High": "8.319",
      "Low": "8.319",
      "Change %": "0.37%"
  },
  {
      "Date": "28-Jun-11",
      "Price": "8.288",
      "Open": "8.288",
      "High": "8.288",
      "Low": "8.288",
      "Change %": "0.31%"
  },
  {
      "Date": "27-Jun-11",
      "Price": "8.262",
      "Open": "8.262",
      "High": "8.262",
      "Low": "8.262",
      "Change %": "0.18%"
  },
  {
      "Date": "24-Jun-11",
      "Price": "8.247",
      "Open": "8.247",
      "High": "8.247",
      "Low": "8.247",
      "Change %": "-0.25%"
  },
  {
      "Date": "23-Jun-11",
      "Price": "8.268",
      "Open": "8.268",
      "High": "8.268",
      "Low": "8.268",
      "Change %": "0.21%"
  },
  {
      "Date": "22-Jun-11",
      "Price": "8.251",
      "Open": "8.251",
      "High": "8.251",
      "Low": "8.251",
      "Change %": "0.32%"
  },
  {
      "Date": "21-Jun-11",
      "Price": "8.225",
      "Open": "8.225",
      "High": "8.225",
      "Low": "8.225",
      "Change %": "0.35%"
  },
  {
      "Date": "20-Jun-11",
      "Price": "8.196",
      "Open": "8.196",
      "High": "8.196",
      "Low": "8.196",
      "Change %": "-0.95%"
  },
  {
      "Date": "17-Jun-11",
      "Price": "8.275",
      "Open": "8.275",
      "High": "8.275",
      "Low": "8.275",
      "Change %": "-0.30%"
  },
  {
      "Date": "16-Jun-11",
      "Price": "8.3",
      "Open": "8.3",
      "High": "8.3",
      "Low": "8.3",
      "Change %": "-1.16%"
  },
  {
      "Date": "15-Jun-11",
      "Price": "8.397",
      "Open": "8.397",
      "High": "8.397",
      "Low": "8.397",
      "Change %": "0.74%"
  },
  {
      "Date": "14-Jun-11",
      "Price": "8.335",
      "Open": "8.335",
      "High": "8.335",
      "Low": "8.335",
      "Change %": "1.08%"
  },
  {
      "Date": "13-Jun-11",
      "Price": "8.246",
      "Open": "8.246",
      "High": "8.246",
      "Low": "8.246",
      "Change %": "0.13%"
  },
  {
      "Date": "10-Jun-11",
      "Price": "8.235",
      "Open": "8.235",
      "High": "8.235",
      "Low": "8.235",
      "Change %": "-0.41%"
  },
  {
      "Date": "9-Jun-11",
      "Price": "8.269",
      "Open": "8.269",
      "High": "8.269",
      "Low": "8.269",
      "Change %": "0.25%"
  },
  {
      "Date": "8-Jun-11",
      "Price": "8.248",
      "Open": "8.248",
      "High": "8.248",
      "Low": "8.248",
      "Change %": "-0.43%"
  },
  {
      "Date": "7-Jun-11",
      "Price": "8.284",
      "Open": "8.284",
      "High": "8.284",
      "Low": "8.284",
      "Change %": "0.28%"
  },
  {
      "Date": "6-Jun-11",
      "Price": "8.261",
      "Open": "8.261",
      "High": "8.261",
      "Low": "8.261",
      "Change %": "-0.08%"
  },
  {
      "Date": "3-Jun-11",
      "Price": "8.268",
      "Open": "8.268",
      "High": "8.268",
      "Low": "8.268",
      "Change %": "-0.13%"
  },
  {
      "Date": "2-Jun-11",
      "Price": "8.279",
      "Open": "8.279",
      "High": "8.279",
      "Low": "8.279",
      "Change %": "-0.59%"
  },
  {
      "Date": "1-Jun-11",
      "Price": "8.328",
      "Open": "8.328",
      "High": "8.328",
      "Low": "8.328",
      "Change %": "-0.99%"
  },
  {
      "Date": "31-May-11",
      "Price": "8.411",
      "Open": "8.411",
      "High": "8.411",
      "Low": "8.411",
      "Change %": "-0.25%"
  },
  {
      "Date": "30-May-11",
      "Price": "8.432",
      "Open": "8.432",
      "High": "8.432",
      "Low": "8.432",
      "Change %": "-0.30%"
  },
  {
      "Date": "27-May-11",
      "Price": "8.457",
      "Open": "8.457",
      "High": "8.457",
      "Low": "8.457",
      "Change %": "0.71%"
  },
  {
      "Date": "26-May-11",
      "Price": "8.397",
      "Open": "8.397",
      "High": "8.397",
      "Low": "8.397",
      "Change %": "0.27%"
  },
  {
      "Date": "25-May-11",
      "Price": "8.374",
      "Open": "8.374",
      "High": "8.374",
      "Low": "8.374",
      "Change %": "0.00%"
  },
  {
      "Date": "24-May-11",
      "Price": "8.374",
      "Open": "8.374",
      "High": "8.374",
      "Low": "8.374",
      "Change %": "0.36%"
  },
  {
      "Date": "23-May-11",
      "Price": "8.344",
      "Open": "8.344",
      "High": "8.344",
      "Low": "8.344",
      "Change %": "-0.12%"
  },
  {
      "Date": "20-May-11",
      "Price": "8.354",
      "Open": "8.354",
      "High": "8.354",
      "Low": "8.354",
      "Change %": "0.40%"
  },
  {
      "Date": "19-May-11",
      "Price": "8.321",
      "Open": "8.321",
      "High": "8.321",
      "Low": "8.321",
      "Change %": "0.35%"
  },
  {
      "Date": "18-May-11",
      "Price": "8.292",
      "Open": "8.292",
      "High": "8.292",
      "Low": "8.292",
      "Change %": "0.11%"
  },
  {
      "Date": "16-May-11",
      "Price": "8.283",
      "Open": "8.283",
      "High": "8.283",
      "Low": "8.283",
      "Change %": "0.08%"
  },
  {
      "Date": "13-May-11",
      "Price": "8.276",
      "Open": "8.276",
      "High": "8.276",
      "Low": "8.276",
      "Change %": "0.78%"
  },
  {
      "Date": "12-May-11",
      "Price": "8.212",
      "Open": "8.212",
      "High": "8.212",
      "Low": "8.212",
      "Change %": "-0.96%"
  },
  {
      "Date": "11-May-11",
      "Price": "8.292",
      "Open": "8.292",
      "High": "8.292",
      "Low": "8.292",
      "Change %": "0.10%"
  },
  {
      "Date": "10-May-11",
      "Price": "8.284",
      "Open": "8.284",
      "High": "8.284",
      "Low": "8.284",
      "Change %": "0.27%"
  },
  {
      "Date": "9-May-11",
      "Price": "8.262",
      "Open": "8.262",
      "High": "8.262",
      "Low": "8.262",
      "Change %": "0.97%"
  },
  {
      "Date": "6-May-11",
      "Price": "8.183",
      "Open": "8.183",
      "High": "8.183",
      "Low": "8.183",
      "Change %": "-1.03%"
  },
  {
      "Date": "5-May-11",
      "Price": "8.268",
      "Open": "8.268",
      "High": "8.268",
      "Low": "8.268",
      "Change %": "-0.31%"
  },
  {
      "Date": "4-May-11",
      "Price": "8.294",
      "Open": "8.294",
      "High": "8.294",
      "Low": "8.294",
      "Change %": "0.78%"
  },
  {
      "Date": "3-May-11",
      "Price": "8.23",
      "Open": "8.23",
      "High": "8.23",
      "Low": "8.23",
      "Change %": "1.12%"
  },
  {
      "Date": "2-May-11",
      "Price": "8.139",
      "Open": "8.139",
      "High": "8.139",
      "Low": "8.139",
      "Change %": "0.05%"
  },
  {
      "Date": "29-Apr-11",
      "Price": "8.135",
      "Open": "8.135",
      "High": "8.135",
      "Low": "8.135",
      "Change %": "0.30%"
  },
  {
      "Date": "28-Apr-11",
      "Price": "8.111",
      "Open": "8.111",
      "High": "8.111",
      "Low": "8.111",
      "Change %": "0.25%"
  },
  {
      "Date": "27-Apr-11",
      "Price": "8.091",
      "Open": "8.091",
      "High": "8.091",
      "Low": "8.091",
      "Change %": "0.00%"
  },
  {
      "Date": "26-Apr-11",
      "Price": "8.091",
      "Open": "8.091",
      "High": "8.091",
      "Low": "8.091",
      "Change %": "-0.53%"
  },
  {
      "Date": "25-Apr-11",
      "Price": "8.134",
      "Open": "8.134",
      "High": "8.134",
      "Low": "8.134",
      "Change %": "0.30%"
  },
  {
      "Date": "21-Apr-11",
      "Price": "8.11",
      "Open": "8.11",
      "High": "8.11",
      "Low": "8.11",
      "Change %": "0.22%"
  },
  {
      "Date": "20-Apr-11",
      "Price": "8.092",
      "Open": "8.092",
      "High": "8.092",
      "Low": "8.092",
      "Change %": "0.15%"
  },
  {
      "Date": "19-Apr-11",
      "Price": "8.08",
      "Open": "8.08",
      "High": "8.08",
      "Low": "8.08",
      "Change %": "-0.02%"
  },
  {
      "Date": "18-Apr-11",
      "Price": "8.082",
      "Open": "8.082",
      "High": "8.082",
      "Low": "8.082",
      "Change %": "0.22%"
  },
  {
      "Date": "15-Apr-11",
      "Price": "8.064",
      "Open": "8.064",
      "High": "8.064",
      "Low": "8.064",
      "Change %": "0.50%"
  },
  {
      "Date": "13-Apr-11",
      "Price": "8.024",
      "Open": "8.024",
      "High": "8.024",
      "Low": "8.024",
      "Change %": "0.06%"
  },
  {
      "Date": "11-Apr-11",
      "Price": "8.019",
      "Open": "8.019",
      "High": "8.019",
      "Low": "8.019",
      "Change %": "0.68%"
  },
  {
      "Date": "8-Apr-11",
      "Price": "7.965",
      "Open": "7.965",
      "High": "7.965",
      "Low": "7.965",
      "Change %": "-0.11%"
  },
  {
      "Date": "7-Apr-11",
      "Price": "7.974",
      "Open": "7.974",
      "High": "7.974",
      "Low": "7.974",
      "Change %": "-0.20%"
  },
  {
      "Date": "6-Apr-11",
      "Price": "7.99",
      "Open": "7.99",
      "High": "7.99",
      "Low": "7.99",
      "Change %": "0.16%"
  },
  {
      "Date": "5-Apr-11",
      "Price": "7.977",
      "Open": "7.977",
      "High": "7.977",
      "Low": "7.977",
      "Change %": "-0.10%"
  },
  {
      "Date": "31-Mar-11",
      "Price": "7.985",
      "Open": "7.985",
      "High": "7.985",
      "Low": "7.985",
      "Change %": "-0.39%"
  },
  {
      "Date": "30-Mar-11",
      "Price": "8.016",
      "Open": "8.016",
      "High": "8.016",
      "Low": "8.016",
      "Change %": "0.17%"
  },
  {
      "Date": "29-Mar-11",
      "Price": "8.002",
      "Open": "8.002",
      "High": "8.002",
      "Low": "8.002",
      "Change %": "0.25%"
  },
  {
      "Date": "28-Mar-11",
      "Price": "7.982",
      "Open": "7.982",
      "High": "7.982",
      "Low": "7.982",
      "Change %": "-0.13%"
  },
  {
      "Date": "25-Mar-11",
      "Price": "7.992",
      "Open": "7.992",
      "High": "7.992",
      "Low": "7.992",
      "Change %": "-0.26%"
  },
  {
      "Date": "24-Mar-11",
      "Price": "8.013",
      "Open": "8.013",
      "High": "8.013",
      "Low": "8.013",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Mar-11",
      "Price": "8.013",
      "Open": "8.013",
      "High": "8.013",
      "Low": "8.013",
      "Change %": "-0.04%"
  },
  {
      "Date": "22-Mar-11",
      "Price": "8.016",
      "Open": "8.016",
      "High": "8.016",
      "Low": "8.016",
      "Change %": "-0.01%"
  },
  {
      "Date": "21-Mar-11",
      "Price": "8.017",
      "Open": "8.017",
      "High": "8.017",
      "Low": "8.017",
      "Change %": "0.15%"
  },
  {
      "Date": "18-Mar-11",
      "Price": "8.005",
      "Open": "8.005",
      "High": "8.005",
      "Low": "8.005",
      "Change %": "0.20%"
  },
  {
      "Date": "17-Mar-11",
      "Price": "7.989",
      "Open": "7.989",
      "High": "7.989",
      "Low": "7.989",
      "Change %": "0.30%"
  },
  {
      "Date": "16-Mar-11",
      "Price": "7.965",
      "Open": "7.965",
      "High": "7.965",
      "Low": "7.965",
      "Change %": "0.03%"
  },
  {
      "Date": "15-Mar-11",
      "Price": "7.963",
      "Open": "7.963",
      "High": "7.963",
      "Low": "7.963",
      "Change %": "0.01%"
  },
  {
      "Date": "14-Mar-11",
      "Price": "7.962",
      "Open": "7.962",
      "High": "7.962",
      "Low": "7.962",
      "Change %": "-0.04%"
  },
  {
      "Date": "11-Mar-11",
      "Price": "7.965",
      "Open": "7.965",
      "High": "7.965",
      "Low": "7.965",
      "Change %": "0.05%"
  },
  {
      "Date": "10-Mar-11",
      "Price": "7.961",
      "Open": "7.961",
      "High": "7.961",
      "Low": "7.961",
      "Change %": "0.00%"
  },
  {
      "Date": "9-Mar-11",
      "Price": "7.961",
      "Open": "7.961",
      "High": "7.961",
      "Low": "7.961",
      "Change %": "-0.04%"
  },
  {
      "Date": "8-Mar-11",
      "Price": "7.964",
      "Open": "7.964",
      "High": "7.964",
      "Low": "7.964",
      "Change %": "-0.20%"
  },
  {
      "Date": "7-Mar-11",
      "Price": "7.98",
      "Open": "7.98",
      "High": "7.98",
      "Low": "7.98",
      "Change %": "0.48%"
  },
  {
      "Date": "4-Mar-11",
      "Price": "7.942",
      "Open": "7.942",
      "High": "7.942",
      "Low": "7.942",
      "Change %": "0.08%"
  },
  {
      "Date": "3-Mar-11",
      "Price": "7.936",
      "Open": "7.936",
      "High": "7.936",
      "Low": "7.936",
      "Change %": "-0.73%"
  },
  {
      "Date": "1-Mar-11",
      "Price": "7.994",
      "Open": "7.994",
      "High": "7.994",
      "Low": "7.994",
      "Change %": "0.03%"
  },
  {
      "Date": "28-Feb-11",
      "Price": "7.992",
      "Open": "7.992",
      "High": "7.992",
      "Low": "7.992",
      "Change %": "-1.08%"
  },
  {
      "Date": "25-Feb-11",
      "Price": "8.079",
      "Open": "8.079",
      "High": "8.079",
      "Low": "8.079",
      "Change %": "0.05%"
  },
  {
      "Date": "24-Feb-11",
      "Price": "8.075",
      "Open": "8.075",
      "High": "8.075",
      "Low": "8.075",
      "Change %": "0.45%"
  },
  {
      "Date": "23-Feb-11",
      "Price": "8.039",
      "Open": "8.039",
      "High": "8.039",
      "Low": "8.039",
      "Change %": "-0.30%"
  },
  {
      "Date": "22-Feb-11",
      "Price": "8.063",
      "Open": "8.063",
      "High": "8.063",
      "Low": "8.063",
      "Change %": "-0.19%"
  },
  {
      "Date": "21-Feb-11",
      "Price": "8.078",
      "Open": "8.078",
      "High": "8.078",
      "Low": "8.078",
      "Change %": "-0.26%"
  },
  {
      "Date": "18-Feb-11",
      "Price": "8.099",
      "Open": "8.099",
      "High": "8.099",
      "Low": "8.099",
      "Change %": "0.20%"
  },
  {
      "Date": "17-Feb-11",
      "Price": "8.083",
      "Open": "8.083",
      "High": "8.083",
      "Low": "8.083",
      "Change %": "-0.27%"
  },
  {
      "Date": "15-Feb-11",
      "Price": "8.105",
      "Open": "8.105",
      "High": "8.105",
      "Low": "8.105",
      "Change %": "0.19%"
  },
  {
      "Date": "14-Feb-11",
      "Price": "8.09",
      "Open": "8.09",
      "High": "8.09",
      "Low": "8.09",
      "Change %": "-0.46%"
  },
  {
      "Date": "11-Feb-11",
      "Price": "8.127",
      "Open": "8.127",
      "High": "8.127",
      "Low": "8.127",
      "Change %": "-0.05%"
  },
  {
      "Date": "10-Feb-11",
      "Price": "8.131",
      "Open": "8.131",
      "High": "8.131",
      "Low": "8.131",
      "Change %": "-0.43%"
  },
  {
      "Date": "9-Feb-11",
      "Price": "8.166",
      "Open": "8.166",
      "High": "8.166",
      "Low": "8.166",
      "Change %": "-0.16%"
  },
  {
      "Date": "8-Feb-11",
      "Price": "8.179",
      "Open": "8.179",
      "High": "8.179",
      "Low": "8.179",
      "Change %": "-0.34%"
  },
  {
      "Date": "7-Feb-11",
      "Price": "8.207",
      "Open": "8.207",
      "High": "8.207",
      "Low": "8.207",
      "Change %": "0.13%"
  },
  {
      "Date": "4-Feb-11",
      "Price": "8.196",
      "Open": "8.196",
      "High": "8.196",
      "Low": "8.196",
      "Change %": "0.42%"
  },
  {
      "Date": "3-Feb-11",
      "Price": "8.162",
      "Open": "8.162",
      "High": "8.162",
      "Low": "8.162",
      "Change %": "0.15%"
  },
  {
      "Date": "2-Feb-11",
      "Price": "8.15",
      "Open": "8.15",
      "High": "8.15",
      "Low": "8.15",
      "Change %": "-0.05%"
  },
  {
      "Date": "1-Feb-11",
      "Price": "8.154",
      "Open": "8.154",
      "High": "8.154",
      "Low": "8.154",
      "Change %": "0.07%"
  },
  {
      "Date": "31-Jan-11",
      "Price": "8.148",
      "Open": "8.148",
      "High": "8.148",
      "Low": "8.148",
      "Change %": "0.20%"
  },
  {
      "Date": "28-Jan-11",
      "Price": "8.132",
      "Open": "8.132",
      "High": "8.132",
      "Low": "8.132",
      "Change %": "-0.20%"
  },
  {
      "Date": "27-Jan-11",
      "Price": "8.148",
      "Open": "8.148",
      "High": "8.148",
      "Low": "8.148",
      "Change %": "-0.07%"
  },
  {
      "Date": "25-Jan-11",
      "Price": "8.154",
      "Open": "8.154",
      "High": "8.154",
      "Low": "8.154",
      "Change %": "-0.38%"
  },
  {
      "Date": "24-Jan-11",
      "Price": "8.185",
      "Open": "8.185",
      "High": "8.185",
      "Low": "8.185",
      "Change %": "0.17%"
  },
  {
      "Date": "21-Jan-11",
      "Price": "8.171",
      "Open": "8.171",
      "High": "8.171",
      "Low": "8.171",
      "Change %": "0.50%"
  },
  {
      "Date": "20-Jan-11",
      "Price": "8.13",
      "Open": "8.13",
      "High": "8.13",
      "Low": "8.13",
      "Change %": "-0.66%"
  },
  {
      "Date": "19-Jan-11",
      "Price": "8.184",
      "Open": "8.184",
      "High": "8.184",
      "Low": "8.184",
      "Change %": "-0.12%"
  },
  {
      "Date": "18-Jan-11",
      "Price": "8.194",
      "Open": "8.194",
      "High": "8.194",
      "Low": "8.194",
      "Change %": "-0.49%"
  },
  {
      "Date": "17-Jan-11",
      "Price": "8.234",
      "Open": "8.234",
      "High": "8.234",
      "Low": "8.234",
      "Change %": "0.52%"
  },
  {
      "Date": "14-Jan-11",
      "Price": "8.191",
      "Open": "8.191",
      "High": "8.191",
      "Low": "8.191",
      "Change %": "0.60%"
  },
  {
      "Date": "13-Jan-11",
      "Price": "8.142",
      "Open": "8.142",
      "High": "8.142",
      "Low": "8.142",
      "Change %": "-0.44%"
  },
  {
      "Date": "12-Jan-11",
      "Price": "8.178",
      "Open": "8.178",
      "High": "8.178",
      "Low": "8.178",
      "Change %": "-0.37%"
  },
  {
      "Date": "11-Jan-11",
      "Price": "8.208",
      "Open": "8.208",
      "High": "8.208",
      "Low": "8.208",
      "Change %": "-0.22%"
  },
  {
      "Date": "10-Jan-11",
      "Price": "8.226",
      "Open": "8.226",
      "High": "8.226",
      "Low": "8.226",
      "Change %": "0.33%"
  },
  {
      "Date": "7-Jan-11",
      "Price": "8.199",
      "Open": "8.199",
      "High": "8.199",
      "Low": "8.199",
      "Change %": "0.90%"
  },
  {
      "Date": "6-Jan-11",
      "Price": "8.126",
      "Open": "8.126",
      "High": "8.126",
      "Low": "8.126",
      "Change %": "0.72%"
  },
  {
      "Date": "5-Jan-11",
      "Price": "8.068",
      "Open": "8.068",
      "High": "8.068",
      "Low": "8.068",
      "Change %": "0.00%"
  },
  {
      "Date": "4-Jan-11",
      "Price": "8.068",
      "Open": "8.068",
      "High": "8.068",
      "Low": "8.068",
      "Change %": "1.36%"
  },
  {
      "Date": "3-Jan-11",
      "Price": "7.96",
      "Open": "7.96",
      "High": "7.96",
      "Low": "7.96",
      "Change %": "0.59%"
  },
  {
      "Date": "31-Dec-10",
      "Price": "7.913",
      "Open": "7.913",
      "High": "7.913",
      "Low": "7.913",
      "Change %": "-0.09%"
  },
  {
      "Date": "30-Dec-10",
      "Price": "7.92",
      "Open": "7.92",
      "High": "7.92",
      "Low": "7.92",
      "Change %": "0.34%"
  },
  {
      "Date": "29-Dec-10",
      "Price": "7.893",
      "Open": "7.893",
      "High": "7.893",
      "Low": "7.893",
      "Change %": "-0.13%"
  },
  {
      "Date": "28-Dec-10",
      "Price": "7.903",
      "Open": "7.903",
      "High": "7.903",
      "Low": "7.903",
      "Change %": "-0.20%"
  },
  {
      "Date": "27-Dec-10",
      "Price": "7.919",
      "Open": "7.919",
      "High": "7.919",
      "Low": "7.919",
      "Change %": "0.04%"
  },
  {
      "Date": "24-Dec-10",
      "Price": "7.916",
      "Open": "7.916",
      "High": "7.916",
      "Low": "7.916",
      "Change %": "-0.21%"
  },
  {
      "Date": "23-Dec-10",
      "Price": "7.933",
      "Open": "7.933",
      "High": "7.933",
      "Low": "7.933",
      "Change %": "-0.30%"
  },
  {
      "Date": "22-Dec-10",
      "Price": "7.957",
      "Open": "7.957",
      "High": "7.957",
      "Low": "7.957",
      "Change %": "0.29%"
  },
  {
      "Date": "21-Dec-10",
      "Price": "7.934",
      "Open": "7.934",
      "High": "7.934",
      "Low": "7.934",
      "Change %": "-0.41%"
  },
  {
      "Date": "20-Dec-10",
      "Price": "7.967",
      "Open": "7.967",
      "High": "7.967",
      "Low": "7.967",
      "Change %": "0.10%"
  },
  {
      "Date": "16-Dec-10",
      "Price": "7.959",
      "Open": "7.959",
      "High": "7.959",
      "Low": "7.959",
      "Change %": "-1.47%"
  },
  {
      "Date": "15-Dec-10",
      "Price": "8.078",
      "Open": "8.078",
      "High": "8.078",
      "Low": "8.078",
      "Change %": "-0.26%"
  },
  {
      "Date": "14-Dec-10",
      "Price": "8.099",
      "Open": "8.099",
      "High": "8.099",
      "Low": "8.099",
      "Change %": "-0.18%"
  },
  {
      "Date": "13-Dec-10",
      "Price": "8.114",
      "Open": "8.114",
      "High": "8.114",
      "Low": "8.114",
      "Change %": "0.21%"
  },
  {
      "Date": "10-Dec-10",
      "Price": "8.097",
      "Open": "8.097",
      "High": "8.097",
      "Low": "8.097",
      "Change %": "-0.34%"
  },
  {
      "Date": "9-Dec-10",
      "Price": "8.125",
      "Open": "8.125",
      "High": "8.125",
      "Low": "8.125",
      "Change %": "0.14%"
  },
  {
      "Date": "8-Dec-10",
      "Price": "8.114",
      "Open": "8.114",
      "High": "8.114",
      "Low": "8.114",
      "Change %": "0.21%"
  },
  {
      "Date": "7-Dec-10",
      "Price": "8.097",
      "Open": "8.097",
      "High": "8.097",
      "Low": "8.097",
      "Change %": "-1.48%"
  },
  {
      "Date": "6-Dec-10",
      "Price": "8.219",
      "Open": "8.219",
      "High": "8.219",
      "Low": "8.219",
      "Change %": "0.50%"
  },
  {
      "Date": "3-Dec-10",
      "Price": "8.178",
      "Open": "8.178",
      "High": "8.178",
      "Low": "8.178",
      "Change %": "1.13%"
  },
  {
      "Date": "2-Dec-10",
      "Price": "8.087",
      "Open": "8.087",
      "High": "8.087",
      "Low": "8.087",
      "Change %": "-0.33%"
  },
  {
      "Date": "1-Dec-10",
      "Price": "8.114",
      "Open": "8.114",
      "High": "8.114",
      "Low": "8.114",
      "Change %": "0.60%"
  },
  {
      "Date": "30-Nov-10",
      "Price": "8.066",
      "Open": "8.066",
      "High": "8.066",
      "Low": "8.066",
      "Change %": "0.62%"
  },
  {
      "Date": "29-Nov-10",
      "Price": "8.016",
      "Open": "8.016",
      "High": "8.016",
      "Low": "8.016",
      "Change %": "0.60%"
  },
  {
      "Date": "26-Nov-10",
      "Price": "7.968",
      "Open": "7.968",
      "High": "7.968",
      "Low": "7.968",
      "Change %": "-0.64%"
  },
  {
      "Date": "25-Nov-10",
      "Price": "8.019",
      "Open": "8.019",
      "High": "8.019",
      "Low": "8.019",
      "Change %": "-0.17%"
  },
  {
      "Date": "24-Nov-10",
      "Price": "8.033",
      "Open": "8.033",
      "High": "8.033",
      "Low": "8.033",
      "Change %": "0.46%"
  },
  {
      "Date": "23-Nov-10",
      "Price": "7.996",
      "Open": "7.996",
      "High": "7.996",
      "Low": "7.996",
      "Change %": "-0.31%"
  },
  {
      "Date": "22-Nov-10",
      "Price": "8.021",
      "Open": "8.021",
      "High": "8.021",
      "Low": "8.021",
      "Change %": "-0.11%"
  },
  {
      "Date": "19-Nov-10",
      "Price": "8.03",
      "Open": "8.03",
      "High": "8.03",
      "Low": "8.03",
      "Change %": "0.06%"
  },
  {
      "Date": "18-Nov-10",
      "Price": "8.025",
      "Open": "8.025",
      "High": "8.025",
      "Low": "8.025",
      "Change %": "-0.55%"
  },
  {
      "Date": "16-Nov-10",
      "Price": "8.069",
      "Open": "8.069",
      "High": "8.069",
      "Low": "8.069",
      "Change %": "0.04%"
  },
  {
      "Date": "15-Nov-10",
      "Price": "8.066",
      "Open": "8.066",
      "High": "8.066",
      "Low": "8.066",
      "Change %": "-0.28%"
  },
  {
      "Date": "12-Nov-10",
      "Price": "8.089",
      "Open": "8.089",
      "High": "8.089",
      "Low": "8.089",
      "Change %": "-0.01%"
  },
  {
      "Date": "11-Nov-10",
      "Price": "8.09",
      "Open": "8.09",
      "High": "8.09",
      "Low": "8.09",
      "Change %": "0.15%"
  },
  {
      "Date": "10-Nov-10",
      "Price": "8.078",
      "Open": "8.078",
      "High": "8.078",
      "Low": "8.078",
      "Change %": "0.57%"
  },
  {
      "Date": "9-Nov-10",
      "Price": "8.032",
      "Open": "8.032",
      "High": "8.032",
      "Low": "8.032",
      "Change %": "0.25%"
  },
  {
      "Date": "8-Nov-10",
      "Price": "8.012",
      "Open": "8.012",
      "High": "8.012",
      "Low": "8.012",
      "Change %": "0.34%"
  },
  {
      "Date": "4-Nov-10",
      "Price": "7.985",
      "Open": "7.985",
      "High": "7.985",
      "Low": "7.985",
      "Change %": "0.38%"
  },
  {
      "Date": "3-Nov-10",
      "Price": "7.955",
      "Open": "7.955",
      "High": "7.955",
      "Low": "7.955",
      "Change %": "-0.23%"
  },
  {
      "Date": "2-Nov-10",
      "Price": "7.973",
      "Open": "7.973",
      "High": "7.973",
      "Low": "7.973",
      "Change %": "-1.69%"
  },
  {
      "Date": "1-Nov-10",
      "Price": "8.11",
      "Open": "8.11",
      "High": "8.11",
      "Low": "8.11",
      "Change %": "-0.14%"
  },
  {
      "Date": "29-Oct-10",
      "Price": "8.121",
      "Open": "8.121",
      "High": "8.121",
      "Low": "8.121",
      "Change %": "-0.17%"
  },
  {
      "Date": "28-Oct-10",
      "Price": "8.135",
      "Open": "8.135",
      "High": "8.135",
      "Low": "8.135",
      "Change %": "0.06%"
  },
  {
      "Date": "27-Oct-10",
      "Price": "8.13",
      "Open": "8.13",
      "High": "8.13",
      "Low": "8.13",
      "Change %": "-0.57%"
  },
  {
      "Date": "26-Oct-10",
      "Price": "8.177",
      "Open": "8.177",
      "High": "8.177",
      "Low": "8.177",
      "Change %": "0.37%"
  },
  {
      "Date": "25-Oct-10",
      "Price": "8.147",
      "Open": "8.147",
      "High": "8.147",
      "Low": "8.147",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Oct-10",
      "Price": "8.147",
      "Open": "8.147",
      "High": "8.147",
      "Low": "8.147",
      "Change %": "0.09%"
  },
  {
      "Date": "21-Oct-10",
      "Price": "8.14",
      "Open": "8.14",
      "High": "8.14",
      "Low": "8.14",
      "Change %": "-0.21%"
  },
  {
      "Date": "20-Oct-10",
      "Price": "8.157",
      "Open": "8.157",
      "High": "8.157",
      "Low": "8.157",
      "Change %": "0.88%"
  },
  {
      "Date": "19-Oct-10",
      "Price": "8.086",
      "Open": "8.086",
      "High": "8.086",
      "Low": "8.086",
      "Change %": "-0.11%"
  },
  {
      "Date": "18-Oct-10",
      "Price": "8.095",
      "Open": "8.095",
      "High": "8.095",
      "Low": "8.095",
      "Change %": "0.31%"
  },
  {
      "Date": "15-Oct-10",
      "Price": "8.07",
      "Open": "8.07",
      "High": "8.07",
      "Low": "8.07",
      "Change %": "0.56%"
  },
  {
      "Date": "14-Oct-10",
      "Price": "8.025",
      "Open": "8.025",
      "High": "8.025",
      "Low": "8.025",
      "Change %": "0.04%"
  },
  {
      "Date": "13-Oct-10",
      "Price": "8.022",
      "Open": "8.022",
      "High": "8.022",
      "Low": "8.022",
      "Change %": "0.01%"
  },
  {
      "Date": "12-Oct-10",
      "Price": "8.021",
      "Open": "8.021",
      "High": "8.021",
      "Low": "8.021",
      "Change %": "0.26%"
  },
  {
      "Date": "11-Oct-10",
      "Price": "8",
      "Open": "8",
      "High": "8",
      "Low": "8",
      "Change %": "0.11%"
  },
  {
      "Date": "8-Oct-10",
      "Price": "7.991",
      "Open": "7.991",
      "High": "7.991",
      "Low": "7.991",
      "Change %": "0.55%"
  },
  {
      "Date": "7-Oct-10",
      "Price": "7.947",
      "Open": "7.947",
      "High": "7.947",
      "Low": "7.947",
      "Change %": "0.21%"
  },
  {
      "Date": "6-Oct-10",
      "Price": "7.93",
      "Open": "7.93",
      "High": "7.93",
      "Low": "7.93",
      "Change %": "-0.33%"
  },
  {
      "Date": "5-Oct-10",
      "Price": "7.956",
      "Open": "7.956",
      "High": "7.956",
      "Low": "7.956",
      "Change %": "0.25%"
  },
  {
      "Date": "4-Oct-10",
      "Price": "7.936",
      "Open": "7.936",
      "High": "7.936",
      "Low": "7.936",
      "Change %": "0.30%"
  },
  {
      "Date": "1-Oct-10",
      "Price": "7.912",
      "Open": "7.912",
      "High": "7.912",
      "Low": "7.912",
      "Change %": "0.76%"
  },
  {
      "Date": "29-Sep-10",
      "Price": "7.852",
      "Open": "7.852",
      "High": "7.852",
      "Low": "7.852",
      "Change %": "0.04%"
  },
  {
      "Date": "28-Sep-10",
      "Price": "7.849",
      "Open": "7.849",
      "High": "7.849",
      "Low": "7.849",
      "Change %": "-0.19%"
  },
  {
      "Date": "27-Sep-10",
      "Price": "7.864",
      "Open": "7.864",
      "High": "7.864",
      "Low": "7.864",
      "Change %": "-0.04%"
  },
  {
      "Date": "24-Sep-10",
      "Price": "7.867",
      "Open": "7.867",
      "High": "7.867",
      "Low": "7.867",
      "Change %": "-0.73%"
  },
  {
      "Date": "23-Sep-10",
      "Price": "7.925",
      "Open": "7.925",
      "High": "7.925",
      "Low": "7.925",
      "Change %": "-0.35%"
  },
  {
      "Date": "22-Sep-10",
      "Price": "7.953",
      "Open": "7.953",
      "High": "7.953",
      "Low": "7.953",
      "Change %": "-0.19%"
  },
  {
      "Date": "21-Sep-10",
      "Price": "7.968",
      "Open": "7.968",
      "High": "7.968",
      "Low": "7.968",
      "Change %": "-0.16%"
  },
  {
      "Date": "20-Sep-10",
      "Price": "7.981",
      "Open": "7.981",
      "High": "7.981",
      "Low": "7.981",
      "Change %": "-0.05%"
  },
  {
      "Date": "17-Sep-10",
      "Price": "7.985",
      "Open": "7.985",
      "High": "7.985",
      "Low": "7.985",
      "Change %": "0.15%"
  },
  {
      "Date": "16-Sep-10",
      "Price": "7.973",
      "Open": "7.973",
      "High": "7.973",
      "Low": "7.973",
      "Change %": "0.31%"
  },
  {
      "Date": "15-Sep-10",
      "Price": "7.948",
      "Open": "7.948",
      "High": "7.948",
      "Low": "7.948",
      "Change %": "0.05%"
  },
  {
      "Date": "14-Sep-10",
      "Price": "7.944",
      "Open": "7.944",
      "High": "7.944",
      "Low": "7.944",
      "Change %": "-0.41%"
  },
  {
      "Date": "13-Sep-10",
      "Price": "7.977",
      "Open": "7.977",
      "High": "7.977",
      "Low": "7.977",
      "Change %": "0.81%"
  },
  {
      "Date": "9-Sep-10",
      "Price": "7.913",
      "Open": "7.913",
      "High": "7.913",
      "Low": "7.913",
      "Change %": "-0.29%"
  },
  {
      "Date": "8-Sep-10",
      "Price": "7.936",
      "Open": "7.936",
      "High": "7.936",
      "Low": "7.936",
      "Change %": "-0.16%"
  },
  {
      "Date": "7-Sep-10",
      "Price": "7.949",
      "Open": "7.949",
      "High": "7.949",
      "Low": "7.949",
      "Change %": "-0.51%"
  },
  {
      "Date": "6-Sep-10",
      "Price": "7.99",
      "Open": "7.99",
      "High": "7.99",
      "Low": "7.99",
      "Change %": "0.25%"
  },
  {
      "Date": "3-Sep-10",
      "Price": "7.97",
      "Open": "7.97",
      "High": "7.97",
      "Low": "7.97",
      "Change %": "-0.14%"
  },
  {
      "Date": "2-Sep-10",
      "Price": "7.981",
      "Open": "7.981",
      "High": "7.981",
      "Low": "7.981",
      "Change %": "0.25%"
  },
  {
      "Date": "1-Sep-10",
      "Price": "7.961",
      "Open": "7.961",
      "High": "7.961",
      "Low": "7.961",
      "Change %": "0.32%"
  },
  {
      "Date": "31-Aug-10",
      "Price": "7.936",
      "Open": "7.936",
      "High": "7.936",
      "Low": "7.936",
      "Change %": "-0.71%"
  },
  {
      "Date": "30-Aug-10",
      "Price": "7.993",
      "Open": "7.993",
      "High": "7.993",
      "Low": "7.993",
      "Change %": "0.21%"
  },
  {
      "Date": "27-Aug-10",
      "Price": "7.976",
      "Open": "7.976",
      "High": "7.976",
      "Low": "7.976",
      "Change %": "-0.68%"
  },
  {
      "Date": "26-Aug-10",
      "Price": "8.031",
      "Open": "8.031",
      "High": "8.031",
      "Low": "8.031",
      "Change %": "-0.45%"
  },
  {
      "Date": "25-Aug-10",
      "Price": "8.067",
      "Open": "8.067",
      "High": "8.067",
      "Low": "8.067",
      "Change %": "0.69%"
  },
  {
      "Date": "24-Aug-10",
      "Price": "8.012",
      "Open": "8.012",
      "High": "8.012",
      "Low": "8.012",
      "Change %": "-0.04%"
  },
  {
      "Date": "23-Aug-10",
      "Price": "8.015",
      "Open": "8.015",
      "High": "8.015",
      "Low": "8.015",
      "Change %": "0.48%"
  },
  {
      "Date": "20-Aug-10",
      "Price": "7.977",
      "Open": "7.977",
      "High": "7.977",
      "Low": "7.977",
      "Change %": "0.33%"
  },
  {
      "Date": "18-Aug-10",
      "Price": "7.951",
      "Open": "7.951",
      "High": "7.951",
      "Low": "7.951",
      "Change %": "0.95%"
  },
  {
      "Date": "17-Aug-10",
      "Price": "7.876",
      "Open": "7.876",
      "High": "7.876",
      "Low": "7.876",
      "Change %": "0.66%"
  },
  {
      "Date": "16-Aug-10",
      "Price": "7.824",
      "Open": "7.824",
      "High": "7.824",
      "Low": "7.824",
      "Change %": "-0.04%"
  },
  {
      "Date": "13-Aug-10",
      "Price": "7.827",
      "Open": "7.827",
      "High": "7.827",
      "Low": "7.827",
      "Change %": "-0.08%"
  },
  {
      "Date": "12-Aug-10",
      "Price": "7.833",
      "Open": "7.833",
      "High": "7.833",
      "Low": "7.833",
      "Change %": "0.12%"
  },
  {
      "Date": "11-Aug-10",
      "Price": "7.824",
      "Open": "7.824",
      "High": "7.824",
      "Low": "7.824",
      "Change %": "-0.20%"
  },
  {
      "Date": "10-Aug-10",
      "Price": "7.84",
      "Open": "7.84",
      "High": "7.84",
      "Low": "7.84",
      "Change %": "0.29%"
  },
  {
      "Date": "9-Aug-10",
      "Price": "7.817",
      "Open": "7.817",
      "High": "7.817",
      "Low": "7.817",
      "Change %": "-0.20%"
  },
  {
      "Date": "6-Aug-10",
      "Price": "7.833",
      "Open": "7.833",
      "High": "7.833",
      "Low": "7.833",
      "Change %": "-0.84%"
  },
  {
      "Date": "5-Aug-10",
      "Price": "7.899",
      "Open": "7.899",
      "High": "7.899",
      "Low": "7.899",
      "Change %": "0.79%"
  },
  {
      "Date": "4-Aug-10",
      "Price": "7.837",
      "Open": "7.837",
      "High": "7.837",
      "Low": "7.837",
      "Change %": "0.18%"
  },
  {
      "Date": "3-Aug-10",
      "Price": "7.823",
      "Open": "7.823",
      "High": "7.823",
      "Low": "7.823",
      "Change %": "-0.52%"
  },
  {
      "Date": "2-Aug-10",
      "Price": "7.864",
      "Open": "7.864",
      "High": "7.864",
      "Low": "7.864",
      "Change %": "0.78%"
  },
  {
      "Date": "30-Jul-10",
      "Price": "7.803",
      "Open": "7.803",
      "High": "7.803",
      "Low": "7.803",
      "Change %": "0.21%"
  },
  {
      "Date": "29-Jul-10",
      "Price": "7.787",
      "Open": "7.787",
      "High": "7.787",
      "Low": "7.787",
      "Change %": "0.44%"
  },
  {
      "Date": "28-Jul-10",
      "Price": "7.753",
      "Open": "7.753",
      "High": "7.753",
      "Low": "7.753",
      "Change %": "0.52%"
  },
  {
      "Date": "27-Jul-10",
      "Price": "7.713",
      "Open": "7.713",
      "High": "7.713",
      "Low": "7.713",
      "Change %": "0.42%"
  },
  {
      "Date": "26-Jul-10",
      "Price": "7.681",
      "Open": "7.681",
      "High": "7.681",
      "Low": "7.681",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Jul-10",
      "Price": "7.681",
      "Open": "7.681",
      "High": "7.681",
      "Low": "7.681",
      "Change %": "0.04%"
  },
  {
      "Date": "22-Jul-10",
      "Price": "7.678",
      "Open": "7.678",
      "High": "7.678",
      "Low": "7.678",
      "Change %": "-0.05%"
  },
  {
      "Date": "21-Jul-10",
      "Price": "7.682",
      "Open": "7.682",
      "High": "7.682",
      "Low": "7.682",
      "Change %": "0.42%"
  },
  {
      "Date": "20-Jul-10",
      "Price": "7.65",
      "Open": "7.65",
      "High": "7.65",
      "Low": "7.65",
      "Change %": "0.39%"
  },
  {
      "Date": "19-Jul-10",
      "Price": "7.62",
      "Open": "7.62",
      "High": "7.62",
      "Low": "7.62",
      "Change %": "-0.22%"
  },
  {
      "Date": "16-Jul-10",
      "Price": "7.637",
      "Open": "7.637",
      "High": "7.637",
      "Low": "7.637",
      "Change %": "0.01%"
  },
  {
      "Date": "15-Jul-10",
      "Price": "7.636",
      "Open": "7.636",
      "High": "7.636",
      "Low": "7.636",
      "Change %": "0.13%"
  },
  {
      "Date": "14-Jul-10",
      "Price": "7.626",
      "Open": "7.626",
      "High": "7.626",
      "Low": "7.626",
      "Change %": "0.04%"
  },
  {
      "Date": "13-Jul-10",
      "Price": "7.623",
      "Open": "7.623",
      "High": "7.623",
      "Low": "7.623",
      "Change %": "0.12%"
  },
  {
      "Date": "12-Jul-10",
      "Price": "7.614",
      "Open": "7.614",
      "High": "7.614",
      "Low": "7.614",
      "Change %": "-0.52%"
  },
  {
      "Date": "9-Jul-10",
      "Price": "7.654",
      "Open": "7.654",
      "High": "7.654",
      "Low": "7.654",
      "Change %": "0.70%"
  },
  {
      "Date": "8-Jul-10",
      "Price": "7.601",
      "Open": "7.601",
      "High": "7.601",
      "Low": "7.601",
      "Change %": "0.18%"
  },
  {
      "Date": "7-Jul-10",
      "Price": "7.587",
      "Open": "7.587",
      "High": "7.587",
      "Low": "7.587",
      "Change %": "-0.38%"
  },
  {
      "Date": "6-Jul-10",
      "Price": "7.616",
      "Open": "7.616",
      "High": "7.616",
      "Low": "7.616",
      "Change %": "0.04%"
  },
  {
      "Date": "5-Jul-10",
      "Price": "7.613",
      "Open": "7.613",
      "High": "7.613",
      "Low": "7.613",
      "Change %": "0.82%"
  },
  {
      "Date": "2-Jul-10",
      "Price": "7.551",
      "Open": "7.551",
      "High": "7.551",
      "Low": "7.551",
      "Change %": "0.31%"
  },
  {
      "Date": "1-Jul-10",
      "Price": "7.528",
      "Open": "7.528",
      "High": "7.528",
      "Low": "7.528",
      "Change %": "-0.44%"
  },
  {
      "Date": "30-Jun-10",
      "Price": "7.561",
      "Open": "7.561",
      "High": "7.561",
      "Low": "7.561",
      "Change %": "-0.12%"
  },
  {
      "Date": "29-Jun-10",
      "Price": "7.57",
      "Open": "7.57",
      "High": "7.57",
      "Low": "7.57",
      "Change %": "-0.37%"
  },
  {
      "Date": "28-Jun-10",
      "Price": "7.598",
      "Open": "7.598",
      "High": "7.598",
      "Low": "7.598",
      "Change %": "-0.80%"
  },
  {
      "Date": "25-Jun-10",
      "Price": "7.659",
      "Open": "7.659",
      "High": "7.659",
      "Low": "7.659",
      "Change %": "1.03%"
  },
  {
      "Date": "24-Jun-10",
      "Price": "7.581",
      "Open": "7.581",
      "High": "7.581",
      "Low": "7.581",
      "Change %": "-0.05%"
  },
  {
      "Date": "23-Jun-10",
      "Price": "7.585",
      "Open": "7.585",
      "High": "7.585",
      "Low": "7.585",
      "Change %": "0.17%"
  },
  {
      "Date": "22-Jun-10",
      "Price": "7.572",
      "Open": "7.572",
      "High": "7.572",
      "Low": "7.572",
      "Change %": "0.05%"
  },
  {
      "Date": "21-Jun-10",
      "Price": "7.568",
      "Open": "7.568",
      "High": "7.568",
      "Low": "7.568",
      "Change %": "-0.05%"
  },
  {
      "Date": "18-Jun-10",
      "Price": "7.572",
      "Open": "7.572",
      "High": "7.572",
      "Low": "7.572",
      "Change %": "-0.63%"
  },
  {
      "Date": "17-Jun-10",
      "Price": "7.62",
      "Open": "7.62",
      "High": "7.62",
      "Low": "7.62",
      "Change %": "0.36%"
  },
  {
      "Date": "16-Jun-10",
      "Price": "7.593",
      "Open": "7.593",
      "High": "7.593",
      "Low": "7.593",
      "Change %": "-0.86%"
  },
  {
      "Date": "15-Jun-10",
      "Price": "7.659",
      "Open": "7.659",
      "High": "7.659",
      "Low": "7.659",
      "Change %": "-0.35%"
  },
  {
      "Date": "14-Jun-10",
      "Price": "7.686",
      "Open": "7.686",
      "High": "7.686",
      "Low": "7.686",
      "Change %": "0.63%"
  },
  {
      "Date": "11-Jun-10",
      "Price": "7.638",
      "Open": "7.638",
      "High": "7.638",
      "Low": "7.638",
      "Change %": "1.43%"
  },
  {
      "Date": "10-Jun-10",
      "Price": "7.53",
      "Open": "7.53",
      "High": "7.53",
      "Low": "7.53",
      "Change %": "0.60%"
  },
  {
      "Date": "9-Jun-10",
      "Price": "7.485",
      "Open": "7.485",
      "High": "7.485",
      "Low": "7.485",
      "Change %": "0.29%"
  },
  {
      "Date": "8-Jun-10",
      "Price": "7.463",
      "Open": "7.463",
      "High": "7.463",
      "Low": "7.463",
      "Change %": "-0.36%"
  },
  {
      "Date": "7-Jun-10",
      "Price": "7.49",
      "Open": "7.49",
      "High": "7.49",
      "Low": "7.49",
      "Change %": "-0.62%"
  },
  {
      "Date": "4-Jun-10",
      "Price": "7.537",
      "Open": "7.537",
      "High": "7.537",
      "Low": "7.537",
      "Change %": "-0.30%"
  },
  {
      "Date": "3-Jun-10",
      "Price": "7.56",
      "Open": "7.56",
      "High": "7.56",
      "Low": "7.56",
      "Change %": "0.76%"
  },
  {
      "Date": "2-Jun-10",
      "Price": "7.503",
      "Open": "7.503",
      "High": "7.503",
      "Low": "7.503",
      "Change %": "-0.81%"
  },
  {
      "Date": "1-Jun-10",
      "Price": "7.564",
      "Open": "7.564",
      "High": "7.564",
      "Low": "7.564",
      "Change %": "0.00%"
  },
  {
      "Date": "31-May-10",
      "Price": "7.564",
      "Open": "7.564",
      "High": "7.564",
      "Low": "7.564",
      "Change %": "0.33%"
  },
  {
      "Date": "28-May-10",
      "Price": "7.539",
      "Open": "7.539",
      "High": "7.539",
      "Low": "7.539",
      "Change %": "0.20%"
  },
  {
      "Date": "26-May-10",
      "Price": "7.524",
      "Open": "7.524",
      "High": "7.524",
      "Low": "7.524",
      "Change %": "1.91%"
  },
  {
      "Date": "25-May-10",
      "Price": "7.383",
      "Open": "7.383",
      "High": "7.383",
      "Low": "7.383",
      "Change %": "-0.16%"
  },
  {
      "Date": "24-May-10",
      "Price": "7.395",
      "Open": "7.395",
      "High": "7.395",
      "Low": "7.395",
      "Change %": "0.18%"
  },
  {
      "Date": "21-May-10",
      "Price": "7.382",
      "Open": "7.382",
      "High": "7.382",
      "Low": "7.382",
      "Change %": "-0.46%"
  },
  {
      "Date": "20-May-10",
      "Price": "7.416",
      "Open": "7.416",
      "High": "7.416",
      "Low": "7.416",
      "Change %": "-0.30%"
  },
  {
      "Date": "19-May-10",
      "Price": "7.438",
      "Open": "7.438",
      "High": "7.438",
      "Low": "7.438",
      "Change %": "-0.83%"
  },
  {
      "Date": "18-May-10",
      "Price": "7.5",
      "Open": "7.5",
      "High": "7.5",
      "Low": "7.5",
      "Change %": "0.46%"
  },
  {
      "Date": "17-May-10",
      "Price": "7.466",
      "Open": "7.466",
      "High": "7.466",
      "Low": "7.466",
      "Change %": "-0.48%"
  },
  {
      "Date": "14-May-10",
      "Price": "7.502",
      "Open": "7.502",
      "High": "7.502",
      "Low": "7.502",
      "Change %": "0.04%"
  },
  {
      "Date": "13-May-10",
      "Price": "7.499",
      "Open": "7.499",
      "High": "7.499",
      "Low": "7.499",
      "Change %": "-5.44%"
  },
  {
      "Date": "12-May-10",
      "Price": "7.93",
      "Open": "7.93",
      "High": "7.93",
      "Low": "7.93",
      "Change %": "-0.56%"
  },
  {
      "Date": "11-May-10",
      "Price": "7.975",
      "Open": "7.975",
      "High": "7.975",
      "Low": "7.975",
      "Change %": "-0.60%"
  },
  {
      "Date": "10-May-10",
      "Price": "8.023",
      "Open": "8.023",
      "High": "8.023",
      "Low": "8.023",
      "Change %": "0.72%"
  },
  {
      "Date": "7-May-10",
      "Price": "7.966",
      "Open": "7.966",
      "High": "7.966",
      "Low": "7.966",
      "Change %": "-0.15%"
  },
  {
      "Date": "6-May-10",
      "Price": "7.978",
      "Open": "7.978",
      "High": "7.978",
      "Low": "7.978",
      "Change %": "0.06%"
  },
  {
      "Date": "5-May-10",
      "Price": "7.973",
      "Open": "7.973",
      "High": "7.973",
      "Low": "7.973",
      "Change %": "0.06%"
  },
  {
      "Date": "4-May-10",
      "Price": "7.968",
      "Open": "7.968",
      "High": "7.968",
      "Low": "7.968",
      "Change %": "-0.96%"
  },
  {
      "Date": "3-May-10",
      "Price": "8.045",
      "Open": "8.045",
      "High": "8.045",
      "Low": "8.045",
      "Change %": "-0.20%"
  },
  {
      "Date": "30-Apr-10",
      "Price": "8.061",
      "Open": "8.061",
      "High": "8.061",
      "Low": "8.061",
      "Change %": "-0.38%"
  },
  {
      "Date": "29-Apr-10",
      "Price": "8.092",
      "Open": "8.092",
      "High": "8.092",
      "Low": "8.092",
      "Change %": "-0.20%"
  },
  {
      "Date": "28-Apr-10",
      "Price": "8.108",
      "Open": "8.108",
      "High": "8.108",
      "Low": "8.108",
      "Change %": "0.00%"
  },
  {
      "Date": "27-Apr-10",
      "Price": "8.108",
      "Open": "8.108",
      "High": "8.108",
      "Low": "8.108",
      "Change %": "0.47%"
  },
  {
      "Date": "26-Apr-10",
      "Price": "8.07",
      "Open": "8.07",
      "High": "8.07",
      "Low": "8.07",
      "Change %": "0.15%"
  },
  {
      "Date": "23-Apr-10",
      "Price": "8.058",
      "Open": "8.058",
      "High": "8.058",
      "Low": "8.058",
      "Change %": "0.83%"
  },
  {
      "Date": "22-Apr-10",
      "Price": "7.992",
      "Open": "7.992",
      "High": "7.992",
      "Low": "7.992",
      "Change %": "-0.20%"
  },
  {
      "Date": "21-Apr-10",
      "Price": "8.008",
      "Open": "8.008",
      "High": "8.008",
      "Low": "8.008",
      "Change %": "0.30%"
  },
  {
      "Date": "20-Apr-10",
      "Price": "7.984",
      "Open": "7.984",
      "High": "7.984",
      "Low": "7.984",
      "Change %": "-1.15%"
  },
  {
      "Date": "19-Apr-10",
      "Price": "8.077",
      "Open": "8.077",
      "High": "8.077",
      "Low": "8.077",
      "Change %": "-0.14%"
  },
  {
      "Date": "16-Apr-10",
      "Price": "8.088",
      "Open": "8.088",
      "High": "8.088",
      "Low": "8.088",
      "Change %": "0.11%"
  },
  {
      "Date": "15-Apr-10",
      "Price": "8.079",
      "Open": "8.079",
      "High": "8.079",
      "Low": "8.079",
      "Change %": "0.27%"
  },
  {
      "Date": "13-Apr-10",
      "Price": "8.057",
      "Open": "8.057",
      "High": "8.057",
      "Low": "8.057",
      "Change %": "0.35%"
  },
  {
      "Date": "12-Apr-10",
      "Price": "8.029",
      "Open": "8.029",
      "High": "8.029",
      "Low": "8.029",
      "Change %": "0.20%"
  },
  {
      "Date": "9-Apr-10",
      "Price": "8.013",
      "Open": "8.013",
      "High": "8.013",
      "Low": "8.013",
      "Change %": "1.91%"
  },
  {
      "Date": "8-Apr-10",
      "Price": "7.863",
      "Open": "7.863",
      "High": "7.863",
      "Low": "7.863",
      "Change %": "0.86%"
  },
  {
      "Date": "7-Apr-10",
      "Price": "7.796",
      "Open": "7.796",
      "High": "7.796",
      "Low": "7.796",
      "Change %": "0.01%"
  },
  {
      "Date": "6-Apr-10",
      "Price": "7.795",
      "Open": "7.795",
      "High": "7.795",
      "Low": "7.795",
      "Change %": "-0.20%"
  },
  {
      "Date": "5-Apr-10",
      "Price": "7.811",
      "Open": "7.811",
      "High": "7.811",
      "Low": "7.811",
      "Change %": "-0.50%"
  },
  {
      "Date": "31-Mar-10",
      "Price": "7.85",
      "Open": "7.85",
      "High": "7.85",
      "Low": "7.85",
      "Change %": "1.04%"
  },
  {
      "Date": "30-Mar-10",
      "Price": "7.769",
      "Open": "7.769",
      "High": "7.769",
      "Low": "7.769",
      "Change %": "0.09%"
  },
  {
      "Date": "29-Mar-10",
      "Price": "7.762",
      "Open": "7.762",
      "High": "7.762",
      "Low": "7.762",
      "Change %": "-1.20%"
  },
  {
      "Date": "26-Mar-10",
      "Price": "7.856",
      "Open": "7.856",
      "High": "7.856",
      "Low": "7.856",
      "Change %": "-0.24%"
  },
  {
      "Date": "25-Mar-10",
      "Price": "7.875",
      "Open": "7.875",
      "High": "7.875",
      "Low": "7.875",
      "Change %": "0.15%"
  },
  {
      "Date": "23-Mar-10",
      "Price": "7.863",
      "Open": "7.863",
      "High": "7.863",
      "Low": "7.863",
      "Change %": "0.10%"
  },
  {
      "Date": "22-Mar-10",
      "Price": "7.855",
      "Open": "7.855",
      "High": "7.855",
      "Low": "7.855",
      "Change %": "0.31%"
  },
  {
      "Date": "19-Mar-10",
      "Price": "7.831",
      "Open": "7.831",
      "High": "7.831",
      "Low": "7.831",
      "Change %": "-1.02%"
  },
  {
      "Date": "18-Mar-10",
      "Price": "7.912",
      "Open": "7.912",
      "High": "7.912",
      "Low": "7.912",
      "Change %": "-0.67%"
  },
  {
      "Date": "17-Mar-10",
      "Price": "7.965",
      "Open": "7.965",
      "High": "7.965",
      "Low": "7.965",
      "Change %": "-0.50%"
  },
  {
      "Date": "15-Mar-10",
      "Price": "8.005",
      "Open": "8.005",
      "High": "8.005",
      "Low": "8.005",
      "Change %": "-0.12%"
  },
  {
      "Date": "12-Mar-10",
      "Price": "8.015",
      "Open": "8.015",
      "High": "8.015",
      "Low": "8.015",
      "Change %": "0.19%"
  },
  {
      "Date": "11-Mar-10",
      "Price": "8",
      "Open": "8",
      "High": "8",
      "Low": "8",
      "Change %": "0.28%"
  },
  {
      "Date": "10-Mar-10",
      "Price": "7.978",
      "Open": "7.978",
      "High": "7.978",
      "Low": "7.978",
      "Change %": "0.10%"
  },
  {
      "Date": "9-Mar-10",
      "Price": "7.97",
      "Open": "7.97",
      "High": "7.97",
      "Low": "7.97",
      "Change %": "-0.50%"
  },
  {
      "Date": "8-Mar-10",
      "Price": "8.01",
      "Open": "8.01",
      "High": "8.01",
      "Low": "8.01",
      "Change %": "0.46%"
  },
  {
      "Date": "5-Mar-10",
      "Price": "7.973",
      "Open": "7.973",
      "High": "7.973",
      "Low": "7.973",
      "Change %": "0.35%"
  },
  {
      "Date": "4-Mar-10",
      "Price": "7.945",
      "Open": "7.945",
      "High": "7.945",
      "Low": "7.945",
      "Change %": "-0.09%"
  },
  {
      "Date": "3-Mar-10",
      "Price": "7.952",
      "Open": "7.952",
      "High": "7.952",
      "Low": "7.952",
      "Change %": "0.10%"
  },
  {
      "Date": "2-Mar-10",
      "Price": "7.944",
      "Open": "7.944",
      "High": "7.944",
      "Low": "7.944",
      "Change %": "1.02%"
  },
  {
      "Date": "26-Feb-10",
      "Price": "7.864",
      "Open": "7.864",
      "High": "7.864",
      "Low": "7.864",
      "Change %": "0.52%"
  },
  {
      "Date": "25-Feb-10",
      "Price": "7.823",
      "Open": "7.823",
      "High": "7.823",
      "Low": "7.823",
      "Change %": "0.31%"
  },
  {
      "Date": "24-Feb-10",
      "Price": "7.799",
      "Open": "7.799",
      "High": "7.799",
      "Low": "7.799",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Feb-10",
      "Price": "7.799",
      "Open": "7.799",
      "High": "7.799",
      "Low": "7.799",
      "Change %": "-0.60%"
  },
  {
      "Date": "22-Feb-10",
      "Price": "7.846",
      "Open": "7.846",
      "High": "7.846",
      "Low": "7.846",
      "Change %": "-0.49%"
  },
  {
      "Date": "19-Feb-10",
      "Price": "7.885",
      "Open": "7.885",
      "High": "7.885",
      "Low": "7.885",
      "Change %": "0.20%"
  },
  {
      "Date": "18-Feb-10",
      "Price": "7.869",
      "Open": "7.869",
      "High": "7.869",
      "Low": "7.869",
      "Change %": "-0.29%"
  },
  {
      "Date": "17-Feb-10",
      "Price": "7.892",
      "Open": "7.892",
      "High": "7.892",
      "Low": "7.892",
      "Change %": "0.31%"
  },
  {
      "Date": "16-Feb-10",
      "Price": "7.868",
      "Open": "7.868",
      "High": "7.868",
      "Low": "7.868",
      "Change %": "-0.29%"
  },
  {
      "Date": "15-Feb-10",
      "Price": "7.891",
      "Open": "7.891",
      "High": "7.891",
      "Low": "7.891",
      "Change %": "0.41%"
  },
  {
      "Date": "11-Feb-10",
      "Price": "7.859",
      "Open": "7.859",
      "High": "7.859",
      "Low": "7.859",
      "Change %": "0.91%"
  },
  {
      "Date": "10-Feb-10",
      "Price": "7.788",
      "Open": "7.788",
      "High": "7.788",
      "Low": "7.788",
      "Change %": "1.13%"
  },
  {
      "Date": "9-Feb-10",
      "Price": "7.701",
      "Open": "7.701",
      "High": "7.701",
      "Low": "7.701",
      "Change %": "0.30%"
  },
  {
      "Date": "8-Feb-10",
      "Price": "7.678",
      "Open": "7.678",
      "High": "7.678",
      "Low": "7.678",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Feb-10",
      "Price": "7.678",
      "Open": "7.678",
      "High": "7.678",
      "Low": "7.678",
      "Change %": "-0.19%"
  },
  {
      "Date": "4-Feb-10",
      "Price": "7.693",
      "Open": "7.693",
      "High": "7.693",
      "Low": "7.693",
      "Change %": "0.31%"
  },
  {
      "Date": "3-Feb-10",
      "Price": "7.669",
      "Open": "7.669",
      "High": "7.669",
      "Low": "7.669",
      "Change %": "-0.09%"
  },
  {
      "Date": "2-Feb-10",
      "Price": "7.676",
      "Open": "7.676",
      "High": "7.676",
      "Low": "7.676",
      "Change %": "0.50%"
  },
  {
      "Date": "1-Feb-10",
      "Price": "7.638",
      "Open": "7.638",
      "High": "7.638",
      "Low": "7.638",
      "Change %": "0.62%"
  },
  {
      "Date": "29-Jan-10",
      "Price": "7.591",
      "Open": "7.591",
      "High": "7.591",
      "Low": "7.591",
      "Change %": "0.56%"
  },
  {
      "Date": "28-Jan-10",
      "Price": "7.549",
      "Open": "7.549",
      "High": "7.549",
      "Low": "7.549",
      "Change %": "0.07%"
  },
  {
      "Date": "27-Jan-10",
      "Price": "7.544",
      "Open": "7.544",
      "High": "7.544",
      "Low": "7.544",
      "Change %": "-0.51%"
  },
  {
      "Date": "25-Jan-10",
      "Price": "7.583",
      "Open": "7.583",
      "High": "7.583",
      "Low": "7.583",
      "Change %": "0.21%"
  },
  {
      "Date": "22-Jan-10",
      "Price": "7.567",
      "Open": "7.567",
      "High": "7.567",
      "Low": "7.567",
      "Change %": "0.01%"
  },
  {
      "Date": "21-Jan-10",
      "Price": "7.566",
      "Open": "7.566",
      "High": "7.566",
      "Low": "7.566",
      "Change %": "-0.81%"
  },
  {
      "Date": "20-Jan-10",
      "Price": "7.628",
      "Open": "7.628",
      "High": "7.628",
      "Low": "7.628",
      "Change %": "-0.09%"
  },
  {
      "Date": "19-Jan-10",
      "Price": "7.635",
      "Open": "7.635",
      "High": "7.635",
      "Low": "7.635",
      "Change %": "0.29%"
  },
  {
      "Date": "18-Jan-10",
      "Price": "7.613",
      "Open": "7.613",
      "High": "7.613",
      "Low": "7.613",
      "Change %": "-1.59%"
  },
  {
      "Date": "15-Jan-10",
      "Price": "7.736",
      "Open": "7.736",
      "High": "7.736",
      "Low": "7.736",
      "Change %": "0.47%"
  },
  {
      "Date": "14-Jan-10",
      "Price": "7.7",
      "Open": "7.7",
      "High": "7.7",
      "Low": "7.7",
      "Change %": "-0.86%"
  },
  {
      "Date": "13-Jan-10",
      "Price": "7.767",
      "Open": "7.767",
      "High": "7.767",
      "Low": "7.767",
      "Change %": "-0.40%"
  },
  {
      "Date": "12-Jan-10",
      "Price": "7.798",
      "Open": "7.798",
      "High": "7.798",
      "Low": "7.798",
      "Change %": "0.61%"
  },
  {
      "Date": "11-Jan-10",
      "Price": "7.751",
      "Open": "7.751",
      "High": "7.751",
      "Low": "7.751",
      "Change %": "-0.28%"
  },
  {
      "Date": "8-Jan-10",
      "Price": "7.773",
      "Open": "7.773",
      "High": "7.773",
      "Low": "7.773",
      "Change %": "-0.06%"
  },
  {
      "Date": "7-Jan-10",
      "Price": "7.778",
      "Open": "7.778",
      "High": "7.778",
      "Low": "7.778",
      "Change %": "0.57%"
  },
  {
      "Date": "6-Jan-10",
      "Price": "7.734",
      "Open": "7.734",
      "High": "7.734",
      "Low": "7.734",
      "Change %": "0.43%"
  },
  {
      "Date": "5-Jan-10",
      "Price": "7.701",
      "Open": "7.701",
      "High": "7.701",
      "Low": "7.701",
      "Change %": "0.33%"
  },
  {
      "Date": "4-Jan-10",
      "Price": "7.676",
      "Open": "7.676",
      "High": "7.676",
      "Low": "7.676",
      "Change %": "-0.25%"
  },
  {
      "Date": "1-Jan-10",
      "Price": "7.695",
      "Open": "7.695",
      "High": "7.695",
      "Low": "7.695",
      "Change %": "0.21%"
  },
  {
      "Date": "31-Dec-09",
      "Price": "7.679",
      "Open": "7.679",
      "High": "7.679",
      "Low": "7.679",
      "Change %": "-0.09%"
  },
  {
      "Date": "30-Dec-09",
      "Price": "7.686",
      "Open": "7.686",
      "High": "7.686",
      "Low": "7.686",
      "Change %": "-0.40%"
  },
  {
      "Date": "29-Dec-09",
      "Price": "7.717",
      "Open": "7.717",
      "High": "7.717",
      "Low": "7.717",
      "Change %": "0.40%"
  },
  {
      "Date": "24-Dec-09",
      "Price": "7.686",
      "Open": "7.686",
      "High": "7.686",
      "Low": "7.686",
      "Change %": "-0.29%"
  },
  {
      "Date": "23-Dec-09",
      "Price": "7.708",
      "Open": "7.708",
      "High": "7.708",
      "Low": "7.708",
      "Change %": "-0.04%"
  },
  {
      "Date": "22-Dec-09",
      "Price": "7.711",
      "Open": "7.711",
      "High": "7.711",
      "Low": "7.711",
      "Change %": "-0.36%"
  },
  {
      "Date": "21-Dec-09",
      "Price": "7.739",
      "Open": "7.739",
      "High": "7.739",
      "Low": "7.739",
      "Change %": "0.10%"
  },
  {
      "Date": "18-Dec-09",
      "Price": "7.731",
      "Open": "7.731",
      "High": "7.731",
      "Low": "7.731",
      "Change %": "1.10%"
  },
  {
      "Date": "17-Dec-09",
      "Price": "7.647",
      "Open": "7.647",
      "High": "7.647",
      "Low": "7.647",
      "Change %": "-0.57%"
  },
  {
      "Date": "16-Dec-09",
      "Price": "7.691",
      "Open": "7.691",
      "High": "7.691",
      "Low": "7.691",
      "Change %": "1.13%"
  },
  {
      "Date": "15-Dec-09",
      "Price": "7.605",
      "Open": "7.605",
      "High": "7.605",
      "Low": "7.605",
      "Change %": "0.49%"
  },
  {
      "Date": "14-Dec-09",
      "Price": "7.568",
      "Open": "7.568",
      "High": "7.568",
      "Low": "7.568",
      "Change %": "-0.09%"
  },
  {
      "Date": "11-Dec-09",
      "Price": "7.575",
      "Open": "7.575",
      "High": "7.575",
      "Low": "7.575",
      "Change %": "0.57%"
  },
  {
      "Date": "10-Dec-09",
      "Price": "7.532",
      "Open": "7.532",
      "High": "7.532",
      "Low": "7.532",
      "Change %": "0.88%"
  },
  {
      "Date": "9-Dec-09",
      "Price": "7.466",
      "Open": "7.466",
      "High": "7.466",
      "Low": "7.466",
      "Change %": "0.17%"
  },
  {
      "Date": "8-Dec-09",
      "Price": "7.453",
      "Open": "7.453",
      "High": "7.453",
      "Low": "7.453",
      "Change %": "-0.40%"
  },
  {
      "Date": "7-Dec-09",
      "Price": "7.483",
      "Open": "7.483",
      "High": "7.483",
      "Low": "7.483",
      "Change %": "0.11%"
  },
  {
      "Date": "4-Dec-09",
      "Price": "7.475",
      "Open": "7.475",
      "High": "7.475",
      "Low": "7.475",
      "Change %": "1.03%"
  },
  {
      "Date": "3-Dec-09",
      "Price": "7.399",
      "Open": "7.399",
      "High": "7.399",
      "Low": "7.399",
      "Change %": "0.90%"
  },
  {
      "Date": "2-Dec-09",
      "Price": "7.333",
      "Open": "7.333",
      "High": "7.333",
      "Low": "7.333",
      "Change %": "0.12%"
  },
  {
      "Date": "1-Dec-09",
      "Price": "7.324",
      "Open": "7.324",
      "High": "7.324",
      "Low": "7.324",
      "Change %": "0.92%"
  },
  {
      "Date": "30-Nov-09",
      "Price": "7.257",
      "Open": "7.257",
      "High": "7.257",
      "Low": "7.257",
      "Change %": "0.83%"
  },
  {
      "Date": "27-Nov-09",
      "Price": "7.197",
      "Open": "7.197",
      "High": "7.197",
      "Low": "7.197",
      "Change %": "0.40%"
  },
  {
      "Date": "26-Nov-09",
      "Price": "7.168",
      "Open": "7.168",
      "High": "7.168",
      "Low": "7.168",
      "Change %": "-0.31%"
  },
  {
      "Date": "25-Nov-09",
      "Price": "7.19",
      "Open": "7.19",
      "High": "7.19",
      "Low": "7.19",
      "Change %": "-0.15%"
  },
  {
      "Date": "24-Nov-09",
      "Price": "7.201",
      "Open": "7.201",
      "High": "7.201",
      "Low": "7.201",
      "Change %": "-0.35%"
  },
  {
      "Date": "23-Nov-09",
      "Price": "7.226",
      "Open": "7.226",
      "High": "7.226",
      "Low": "7.226",
      "Change %": "0.53%"
  },
  {
      "Date": "20-Nov-09",
      "Price": "7.188",
      "Open": "7.188",
      "High": "7.188",
      "Low": "7.188",
      "Change %": "-1.03%"
  },
  {
      "Date": "19-Nov-09",
      "Price": "7.263",
      "Open": "7.263",
      "High": "7.263",
      "Low": "7.263",
      "Change %": "-0.06%"
  },
  {
      "Date": "18-Nov-09",
      "Price": "7.267",
      "Open": "7.267",
      "High": "7.267",
      "Low": "7.267",
      "Change %": "-0.15%"
  },
  {
      "Date": "17-Nov-09",
      "Price": "7.278",
      "Open": "7.278",
      "High": "7.278",
      "Low": "7.278",
      "Change %": "-0.51%"
  },
  {
      "Date": "16-Nov-09",
      "Price": "7.315",
      "Open": "7.315",
      "High": "7.315",
      "Low": "7.315",
      "Change %": "-0.22%"
  },
  {
      "Date": "13-Nov-09",
      "Price": "7.331",
      "Open": "7.331",
      "High": "7.331",
      "Low": "7.331",
      "Change %": "-0.38%"
  },
  {
      "Date": "12-Nov-09",
      "Price": "7.359",
      "Open": "7.359",
      "High": "7.359",
      "Low": "7.359",
      "Change %": "0.30%"
  },
  {
      "Date": "11-Nov-09",
      "Price": "7.337",
      "Open": "7.337",
      "High": "7.337",
      "Low": "7.337",
      "Change %": "0.41%"
  },
  {
      "Date": "10-Nov-09",
      "Price": "7.307",
      "Open": "7.307",
      "High": "7.307",
      "Low": "7.307",
      "Change %": "0.00%"
  },
  {
      "Date": "9-Nov-09",
      "Price": "7.307",
      "Open": "7.307",
      "High": "7.307",
      "Low": "7.307",
      "Change %": "-0.19%"
  },
  {
      "Date": "6-Nov-09",
      "Price": "7.321",
      "Open": "7.321",
      "High": "7.321",
      "Low": "7.321",
      "Change %": "0.51%"
  },
  {
      "Date": "5-Nov-09",
      "Price": "7.284",
      "Open": "7.284",
      "High": "7.284",
      "Low": "7.284",
      "Change %": "0.10%"
  },
  {
      "Date": "4-Nov-09",
      "Price": "7.277",
      "Open": "7.277",
      "High": "7.277",
      "Low": "7.277",
      "Change %": "0.48%"
  },
  {
      "Date": "3-Nov-09",
      "Price": "7.242",
      "Open": "7.242",
      "High": "7.242",
      "Low": "7.242",
      "Change %": "-0.88%"
  },
  {
      "Date": "30-Oct-09",
      "Price": "7.306",
      "Open": "7.306",
      "High": "7.306",
      "Low": "7.306",
      "Change %": "0.55%"
  },
  {
      "Date": "29-Oct-09",
      "Price": "7.266",
      "Open": "7.266",
      "High": "7.266",
      "Low": "7.266",
      "Change %": "0.00%"
  },
  {
      "Date": "28-Oct-09",
      "Price": "7.266",
      "Open": "7.266",
      "High": "7.266",
      "Low": "7.266",
      "Change %": "-0.94%"
  },
  {
      "Date": "27-Oct-09",
      "Price": "7.335",
      "Open": "7.335",
      "High": "7.335",
      "Low": "7.335",
      "Change %": "-1.25%"
  },
  {
      "Date": "26-Oct-09",
      "Price": "7.428",
      "Open": "7.428",
      "High": "7.428",
      "Low": "7.428",
      "Change %": "-0.46%"
  },
  {
      "Date": "23-Oct-09",
      "Price": "7.462",
      "Open": "7.462",
      "High": "7.462",
      "Low": "7.462",
      "Change %": "1.06%"
  },
  {
      "Date": "22-Oct-09",
      "Price": "7.384",
      "Open": "7.384",
      "High": "7.384",
      "Low": "7.384",
      "Change %": "0.46%"
  },
  {
      "Date": "21-Oct-09",
      "Price": "7.35",
      "Open": "7.35",
      "High": "7.35",
      "Low": "7.35",
      "Change %": "-0.74%"
  },
  {
      "Date": "20-Oct-09",
      "Price": "7.405",
      "Open": "7.405",
      "High": "7.405",
      "Low": "7.405",
      "Change %": "-0.05%"
  },
  {
      "Date": "16-Oct-09",
      "Price": "7.409",
      "Open": "7.409",
      "High": "7.409",
      "Low": "7.409",
      "Change %": "0.52%"
  },
  {
      "Date": "15-Oct-09",
      "Price": "7.371",
      "Open": "7.371",
      "High": "7.371",
      "Low": "7.371",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Oct-09",
      "Price": "7.371",
      "Open": "7.371",
      "High": "7.371",
      "Low": "7.371",
      "Change %": "0.20%"
  },
  {
      "Date": "12-Oct-09",
      "Price": "7.356",
      "Open": "7.356",
      "High": "7.356",
      "Low": "7.356",
      "Change %": "0.41%"
  },
  {
      "Date": "9-Oct-09",
      "Price": "7.326",
      "Open": "7.326",
      "High": "7.326",
      "Low": "7.326",
      "Change %": "1.52%"
  },
  {
      "Date": "8-Oct-09",
      "Price": "7.216",
      "Open": "7.216",
      "High": "7.216",
      "Low": "7.216",
      "Change %": "-0.61%"
  },
  {
      "Date": "7-Oct-09",
      "Price": "7.26",
      "Open": "7.26",
      "High": "7.26",
      "Low": "7.26",
      "Change %": "0.51%"
  },
  {
      "Date": "6-Oct-09",
      "Price": "7.223",
      "Open": "7.223",
      "High": "7.223",
      "Low": "7.223",
      "Change %": "0.82%"
  },
  {
      "Date": "5-Oct-09",
      "Price": "7.164",
      "Open": "7.164",
      "High": "7.164",
      "Low": "7.164",
      "Change %": "-0.82%"
  },
  {
      "Date": "1-Oct-09",
      "Price": "7.223",
      "Open": "7.223",
      "High": "7.223",
      "Low": "7.223",
      "Change %": "0.11%"
  },
  {
      "Date": "29-Sep-09",
      "Price": "7.215",
      "Open": "7.215",
      "High": "7.215",
      "Low": "7.215",
      "Change %": "0.50%"
  },
  {
      "Date": "25-Sep-09",
      "Price": "7.179",
      "Open": "7.179",
      "High": "7.179",
      "Low": "7.179",
      "Change %": "1.03%"
  },
  {
      "Date": "24-Sep-09",
      "Price": "7.106",
      "Open": "7.106",
      "High": "7.106",
      "Low": "7.106",
      "Change %": "0.21%"
  },
  {
      "Date": "23-Sep-09",
      "Price": "7.091",
      "Open": "7.091",
      "High": "7.091",
      "Low": "7.091",
      "Change %": "-0.62%"
  },
  {
      "Date": "22-Sep-09",
      "Price": "7.135",
      "Open": "7.135",
      "High": "7.135",
      "Low": "7.135",
      "Change %": "0.31%"
  },
  {
      "Date": "18-Sep-09",
      "Price": "7.113",
      "Open": "7.113",
      "High": "7.113",
      "Low": "7.113",
      "Change %": "-0.50%"
  },
  {
      "Date": "17-Sep-09",
      "Price": "7.149",
      "Open": "7.149",
      "High": "7.149",
      "Low": "7.149",
      "Change %": "0.92%"
  },
  {
      "Date": "16-Sep-09",
      "Price": "7.084",
      "Open": "7.084",
      "High": "7.084",
      "Low": "7.084",
      "Change %": "-0.31%"
  },
  {
      "Date": "15-Sep-09",
      "Price": "7.106",
      "Open": "7.106",
      "High": "7.106",
      "Low": "7.106",
      "Change %": "-1.76%"
  },
  {
      "Date": "14-Sep-09",
      "Price": "7.233",
      "Open": "7.233",
      "High": "7.233",
      "Low": "7.233",
      "Change %": "-1.83%"
  },
  {
      "Date": "11-Sep-09",
      "Price": "7.368",
      "Open": "7.368",
      "High": "7.368",
      "Low": "7.368",
      "Change %": "-0.70%"
  },
  {
      "Date": "10-Sep-09",
      "Price": "7.42",
      "Open": "7.42",
      "High": "7.42",
      "Low": "7.42",
      "Change %": "1.05%"
  },
  {
      "Date": "9-Sep-09",
      "Price": "7.343",
      "Open": "7.343",
      "High": "7.343",
      "Low": "7.343",
      "Change %": "-0.45%"
  },
  {
      "Date": "8-Sep-09",
      "Price": "7.376",
      "Open": "7.376",
      "High": "7.376",
      "Low": "7.376",
      "Change %": "1.08%"
  },
  {
      "Date": "7-Sep-09",
      "Price": "7.297",
      "Open": "7.297",
      "High": "7.297",
      "Low": "7.297",
      "Change %": "-2.20%"
  },
  {
      "Date": "4-Sep-09",
      "Price": "7.461",
      "Open": "7.461",
      "High": "7.461",
      "Low": "7.461",
      "Change %": "0.09%"
  },
  {
      "Date": "3-Sep-09",
      "Price": "7.454",
      "Open": "7.454",
      "High": "7.454",
      "Low": "7.454",
      "Change %": "0.77%"
  },
  {
      "Date": "2-Sep-09",
      "Price": "7.397",
      "Open": "7.397",
      "High": "7.397",
      "Low": "7.397",
      "Change %": "-0.36%"
  },
  {
      "Date": "1-Sep-09",
      "Price": "7.424",
      "Open": "7.424",
      "High": "7.424",
      "Low": "7.424",
      "Change %": "-0.13%"
  },
  {
      "Date": "31-Aug-09",
      "Price": "7.434",
      "Open": "7.434",
      "High": "7.434",
      "Low": "7.434",
      "Change %": "1.24%"
  },
  {
      "Date": "28-Aug-09",
      "Price": "7.343",
      "Open": "7.343",
      "High": "7.343",
      "Low": "7.343",
      "Change %": "0.98%"
  },
  {
      "Date": "27-Aug-09",
      "Price": "7.272",
      "Open": "7.272",
      "High": "7.272",
      "Low": "7.272",
      "Change %": "0.61%"
  },
  {
      "Date": "26-Aug-09",
      "Price": "7.228",
      "Open": "7.228",
      "High": "7.228",
      "Low": "7.228",
      "Change %": "-1.20%"
  },
  {
      "Date": "25-Aug-09",
      "Price": "7.316",
      "Open": "7.316",
      "High": "7.316",
      "Low": "7.316",
      "Change %": "-0.34%"
  },
  {
      "Date": "24-Aug-09",
      "Price": "7.341",
      "Open": "7.341",
      "High": "7.341",
      "Low": "7.341",
      "Change %": "0.15%"
  },
  {
      "Date": "21-Aug-09",
      "Price": "7.33",
      "Open": "7.33",
      "High": "7.33",
      "Low": "7.33",
      "Change %": "2.03%"
  },
  {
      "Date": "20-Aug-09",
      "Price": "7.184",
      "Open": "7.184",
      "High": "7.184",
      "Low": "7.184",
      "Change %": "0.70%"
  },
  {
      "Date": "18-Aug-09",
      "Price": "7.134",
      "Open": "7.134",
      "High": "7.134",
      "Low": "7.134",
      "Change %": "0.51%"
  },
  {
      "Date": "17-Aug-09",
      "Price": "7.098",
      "Open": "7.098",
      "High": "7.098",
      "Low": "7.098",
      "Change %": "-0.20%"
  },
  {
      "Date": "14-Aug-09",
      "Price": "7.112",
      "Open": "7.112",
      "High": "7.112",
      "Low": "7.112",
      "Change %": "1.08%"
  },
  {
      "Date": "13-Aug-09",
      "Price": "7.036",
      "Open": "7.036",
      "High": "7.036",
      "Low": "7.036",
      "Change %": "-0.07%"
  },
  {
      "Date": "12-Aug-09",
      "Price": "7.041",
      "Open": "7.041",
      "High": "7.041",
      "Low": "7.041",
      "Change %": "-0.20%"
  },
  {
      "Date": "11-Aug-09",
      "Price": "7.055",
      "Open": "7.055",
      "High": "7.055",
      "Low": "7.055",
      "Change %": "-0.56%"
  },
  {
      "Date": "10-Aug-09",
      "Price": "7.095",
      "Open": "7.095",
      "High": "7.095",
      "Low": "7.095",
      "Change %": "0.77%"
  },
  {
      "Date": "7-Aug-09",
      "Price": "7.041",
      "Open": "7.041",
      "High": "7.041",
      "Low": "7.041",
      "Change %": "-0.71%"
  },
  {
      "Date": "6-Aug-09",
      "Price": "7.091",
      "Open": "7.091",
      "High": "7.091",
      "Low": "7.091",
      "Change %": "0.41%"
  },
  {
      "Date": "5-Aug-09",
      "Price": "7.062",
      "Open": "7.062",
      "High": "7.062",
      "Low": "7.062",
      "Change %": "0.77%"
  },
  {
      "Date": "4-Aug-09",
      "Price": "7.008",
      "Open": "7.008",
      "High": "7.008",
      "Low": "7.008",
      "Change %": "-0.06%"
  },
  {
      "Date": "3-Aug-09",
      "Price": "7.012",
      "Open": "7.012",
      "High": "7.012",
      "Low": "7.012",
      "Change %": "0.20%"
  },
  {
      "Date": "31-Jul-09",
      "Price": "6.998",
      "Open": "6.998",
      "High": "6.998",
      "Low": "6.998",
      "Change %": "0.55%"
  },
  {
      "Date": "30-Jul-09",
      "Price": "6.96",
      "Open": "6.96",
      "High": "6.96",
      "Low": "6.96",
      "Change %": "0.78%"
  },
  {
      "Date": "29-Jul-09",
      "Price": "6.906",
      "Open": "6.906",
      "High": "6.906",
      "Low": "6.906",
      "Change %": "0.00%"
  },
  {
      "Date": "28-Jul-09",
      "Price": "6.906",
      "Open": "6.906",
      "High": "6.906",
      "Low": "6.906",
      "Change %": "-0.62%"
  },
  {
      "Date": "27-Jul-09",
      "Price": "6.949",
      "Open": "6.949",
      "High": "6.949",
      "Low": "6.949",
      "Change %": "-1.33%"
  },
  {
      "Date": "24-Jul-09",
      "Price": "7.043",
      "Open": "7.043",
      "High": "7.043",
      "Low": "7.043",
      "Change %": "0.56%"
  },
  {
      "Date": "23-Jul-09",
      "Price": "7.004",
      "Open": "7.004",
      "High": "7.004",
      "Low": "7.004",
      "Change %": "0.23%"
  },
  {
      "Date": "22-Jul-09",
      "Price": "6.988",
      "Open": "6.988",
      "High": "6.988",
      "Low": "6.988",
      "Change %": "-0.98%"
  },
  {
      "Date": "21-Jul-09",
      "Price": "7.057",
      "Open": "7.057",
      "High": "7.057",
      "Low": "7.057",
      "Change %": "0.01%"
  },
  {
      "Date": "20-Jul-09",
      "Price": "7.056",
      "Open": "7.056",
      "High": "7.056",
      "Low": "7.056",
      "Change %": "0.66%"
  },
  {
      "Date": "17-Jul-09",
      "Price": "7.01",
      "Open": "7.01",
      "High": "7.01",
      "Low": "7.01",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Jul-09",
      "Price": "7.01",
      "Open": "7.01",
      "High": "7.01",
      "Low": "7.01",
      "Change %": "-0.31%"
  },
  {
      "Date": "15-Jul-09",
      "Price": "7.032",
      "Open": "7.032",
      "High": "7.032",
      "Low": "7.032",
      "Change %": "0.21%"
  },
  {
      "Date": "14-Jul-09",
      "Price": "7.017",
      "Open": "7.017",
      "High": "7.017",
      "Low": "7.017",
      "Change %": "0.33%"
  },
  {
      "Date": "13-Jul-09",
      "Price": "6.994",
      "Open": "6.994",
      "High": "6.994",
      "Low": "6.994",
      "Change %": "-0.43%"
  },
  {
      "Date": "10-Jul-09",
      "Price": "7.024",
      "Open": "7.024",
      "High": "7.024",
      "Low": "7.024",
      "Change %": "0.56%"
  },
  {
      "Date": "9-Jul-09",
      "Price": "6.985",
      "Open": "6.985",
      "High": "6.985",
      "Low": "6.985",
      "Change %": "-0.33%"
  },
  {
      "Date": "8-Jul-09",
      "Price": "7.008",
      "Open": "7.008",
      "High": "7.008",
      "Low": "7.008",
      "Change %": "-0.96%"
  },
  {
      "Date": "7-Jul-09",
      "Price": "7.076",
      "Open": "7.076",
      "High": "7.076",
      "Low": "7.076",
      "Change %": "0.77%"
  },
  {
      "Date": "6-Jul-09",
      "Price": "7.022",
      "Open": "7.022",
      "High": "7.022",
      "Low": "7.022",
      "Change %": "2.75%"
  },
  {
      "Date": "3-Jul-09",
      "Price": "6.834",
      "Open": "6.834",
      "High": "6.834",
      "Low": "6.834",
      "Change %": "-0.64%"
  },
  {
      "Date": "2-Jul-09",
      "Price": "6.878",
      "Open": "6.878",
      "High": "6.878",
      "Low": "6.878",
      "Change %": "-1.49%"
  },
  {
      "Date": "1-Jul-09",
      "Price": "6.982",
      "Open": "6.982",
      "High": "6.982",
      "Low": "6.982",
      "Change %": "-0.44%"
  },
  {
      "Date": "30-Jun-09",
      "Price": "7.013",
      "Open": "7.013",
      "High": "7.013",
      "Low": "7.013",
      "Change %": "0.21%"
  },
  {
      "Date": "29-Jun-09",
      "Price": "6.998",
      "Open": "6.998",
      "High": "6.998",
      "Low": "6.998",
      "Change %": "0.00%"
  },
  {
      "Date": "26-Jun-09",
      "Price": "6.998",
      "Open": "6.998",
      "High": "6.998",
      "Low": "6.998",
      "Change %": "-0.06%"
  },
  {
      "Date": "25-Jun-09",
      "Price": "7.002",
      "Open": "7.002",
      "High": "7.002",
      "Low": "7.002",
      "Change %": "-0.03%"
  },
  {
      "Date": "24-Jun-09",
      "Price": "7.004",
      "Open": "7.004",
      "High": "7.004",
      "Low": "7.004",
      "Change %": "-0.11%"
  },
  {
      "Date": "23-Jun-09",
      "Price": "7.012",
      "Open": "7.012",
      "High": "7.012",
      "Low": "7.012",
      "Change %": "0.88%"
  },
  {
      "Date": "22-Jun-09",
      "Price": "6.951",
      "Open": "6.951",
      "High": "6.951",
      "Low": "6.951",
      "Change %": "0.43%"
  },
  {
      "Date": "19-Jun-09",
      "Price": "6.921",
      "Open": "6.921",
      "High": "6.921",
      "Low": "6.921",
      "Change %": "0.23%"
  },
  {
      "Date": "18-Jun-09",
      "Price": "6.905",
      "Open": "6.905",
      "High": "6.905",
      "Low": "6.905",
      "Change %": "0.10%"
  },
  {
      "Date": "17-Jun-09",
      "Price": "6.898",
      "Open": "6.898",
      "High": "6.898",
      "Low": "6.898",
      "Change %": "-0.20%"
  },
  {
      "Date": "16-Jun-09",
      "Price": "6.912",
      "Open": "6.912",
      "High": "6.912",
      "Low": "6.912",
      "Change %": "0.10%"
  },
  {
      "Date": "15-Jun-09",
      "Price": "6.905",
      "Open": "6.905",
      "High": "6.905",
      "Low": "6.905",
      "Change %": "-0.10%"
  },
  {
      "Date": "12-Jun-09",
      "Price": "6.912",
      "Open": "6.912",
      "High": "6.912",
      "Low": "6.912",
      "Change %": "0.22%"
  },
  {
      "Date": "11-Jun-09",
      "Price": "6.897",
      "Open": "6.897",
      "High": "6.897",
      "Low": "6.897",
      "Change %": "1.65%"
  },
  {
      "Date": "10-Jun-09",
      "Price": "6.785",
      "Open": "6.785",
      "High": "6.785",
      "Low": "6.785",
      "Change %": "1.98%"
  },
  {
      "Date": "9-Jun-09",
      "Price": "6.653",
      "Open": "6.653",
      "High": "6.653",
      "Low": "6.653",
      "Change %": "0.89%"
  },
  {
      "Date": "8-Jun-09",
      "Price": "6.594",
      "Open": "6.594",
      "High": "6.594",
      "Low": "6.594",
      "Change %": "0.59%"
  },
  {
      "Date": "5-Jun-09",
      "Price": "6.555",
      "Open": "6.555",
      "High": "6.555",
      "Low": "6.555",
      "Change %": "0.18%"
  },
  {
      "Date": "4-Jun-09",
      "Price": "6.543",
      "Open": "6.543",
      "High": "6.543",
      "Low": "6.543",
      "Change %": "-0.55%"
  },
  {
      "Date": "3-Jun-09",
      "Price": "6.579",
      "Open": "6.579",
      "High": "6.579",
      "Low": "6.579",
      "Change %": "-1.05%"
  },
  {
      "Date": "2-Jun-09",
      "Price": "6.649",
      "Open": "6.649",
      "High": "6.649",
      "Low": "6.649",
      "Change %": "-0.48%"
  },
  {
      "Date": "1-Jun-09",
      "Price": "6.681",
      "Open": "6.681",
      "High": "6.681",
      "Low": "6.681",
      "Change %": "-0.43%"
  },
  {
      "Date": "29-May-09",
      "Price": "6.71",
      "Open": "6.71",
      "High": "6.71",
      "Low": "6.71",
      "Change %": "0.16%"
  },
  {
      "Date": "28-May-09",
      "Price": "6.699",
      "Open": "6.699",
      "High": "6.699",
      "Low": "6.699",
      "Change %": "1.16%"
  },
  {
      "Date": "27-May-09",
      "Price": "6.622",
      "Open": "6.622",
      "High": "6.622",
      "Low": "6.622",
      "Change %": "0.88%"
  },
  {
      "Date": "26-May-09",
      "Price": "6.564",
      "Open": "6.564",
      "High": "6.564",
      "Low": "6.564",
      "Change %": "0.34%"
  },
  {
      "Date": "25-May-09",
      "Price": "6.542",
      "Open": "6.542",
      "High": "6.542",
      "Low": "6.542",
      "Change %": "1.00%"
  },
  {
      "Date": "22-May-09",
      "Price": "6.477",
      "Open": "6.477",
      "High": "6.477",
      "Low": "6.477",
      "Change %": "0.84%"
  },
  {
      "Date": "21-May-09",
      "Price": "6.423",
      "Open": "6.423",
      "High": "6.423",
      "Low": "6.423",
      "Change %": "0.88%"
  },
  {
      "Date": "20-May-09",
      "Price": "6.367",
      "Open": "6.367",
      "High": "6.367",
      "Low": "6.367",
      "Change %": "-0.48%"
  },
  {
      "Date": "19-May-09",
      "Price": "6.398",
      "Open": "6.398",
      "High": "6.398",
      "Low": "6.398",
      "Change %": "1.57%"
  },
  {
      "Date": "18-May-09",
      "Price": "6.299",
      "Open": "6.299",
      "High": "6.299",
      "Low": "6.299",
      "Change %": "-1.87%"
  },
  {
      "Date": "15-May-09",
      "Price": "6.419",
      "Open": "6.419",
      "High": "6.419",
      "Low": "6.419",
      "Change %": "0.22%"
  },
  {
      "Date": "14-May-09",
      "Price": "6.405",
      "Open": "6.405",
      "High": "6.405",
      "Low": "6.405",
      "Change %": "0.79%"
  },
  {
      "Date": "13-May-09",
      "Price": "6.355",
      "Open": "6.355",
      "High": "6.355",
      "Low": "6.355",
      "Change %": "-0.78%"
  },
  {
      "Date": "12-May-09",
      "Price": "6.405",
      "Open": "6.405",
      "High": "6.405",
      "Low": "6.405",
      "Change %": "0.68%"
  },
  {
      "Date": "11-May-09",
      "Price": "6.362",
      "Open": "6.362",
      "High": "6.362",
      "Low": "6.362",
      "Change %": "-0.30%"
  },
  {
      "Date": "8-May-09",
      "Price": "6.381",
      "Open": "6.381",
      "High": "6.381",
      "Low": "6.381",
      "Change %": "2.34%"
  },
  {
      "Date": "7-May-09",
      "Price": "6.235",
      "Open": "6.235",
      "High": "6.235",
      "Low": "6.235",
      "Change %": "0.78%"
  },
  {
      "Date": "6-May-09",
      "Price": "6.187",
      "Open": "6.187",
      "High": "6.187",
      "Low": "6.187",
      "Change %": "-1.78%"
  },
  {
      "Date": "5-May-09",
      "Price": "6.299",
      "Open": "6.299",
      "High": "6.299",
      "Low": "6.299",
      "Change %": "-0.88%"
  },
  {
      "Date": "4-May-09",
      "Price": "6.355",
      "Open": "6.355",
      "High": "6.355",
      "Low": "6.355",
      "Change %": "2.06%"
  },
  {
      "Date": "2-May-09",
      "Price": "6.227",
      "Open": "6.227",
      "High": "6.227",
      "Low": "6.227",
      "Change %": "-0.24%"
  },
  {
      "Date": "29-Apr-09",
      "Price": "6.242",
      "Open": "6.242",
      "High": "6.242",
      "Low": "6.242",
      "Change %": "0.71%"
  },
  {
      "Date": "28-Apr-09",
      "Price": "6.198",
      "Open": "6.198",
      "High": "6.198",
      "Low": "6.198",
      "Change %": "1.09%"
  },
  {
      "Date": "27-Apr-09",
      "Price": "6.131",
      "Open": "6.131",
      "High": "6.131",
      "Low": "6.131",
      "Change %": "0.18%"
  },
  {
      "Date": "25-Apr-09",
      "Price": "6.12",
      "Open": "6.12",
      "High": "6.12",
      "Low": "6.12",
      "Change %": "-0.07%"
  },
  {
      "Date": "24-Apr-09",
      "Price": "6.124",
      "Open": "6.124",
      "High": "6.124",
      "Low": "6.124",
      "Change %": "-1.34%"
  },
  {
      "Date": "23-Apr-09",
      "Price": "6.207",
      "Open": "6.207",
      "High": "6.207",
      "Low": "6.207",
      "Change %": "0.57%"
  },
  {
      "Date": "22-Apr-09",
      "Price": "6.172",
      "Open": "6.172",
      "High": "6.172",
      "Low": "6.172",
      "Change %": "0.00%"
  },
  {
      "Date": "21-Apr-09",
      "Price": "6.172",
      "Open": "6.172",
      "High": "6.172",
      "Low": "6.172",
      "Change %": "-3.50%"
  },
  {
      "Date": "20-Apr-09",
      "Price": "6.396",
      "Open": "6.396",
      "High": "6.396",
      "Low": "6.396",
      "Change %": "-0.20%"
  },
  {
      "Date": "18-Apr-09",
      "Price": "6.409",
      "Open": "6.409",
      "High": "6.409",
      "Low": "6.409",
      "Change %": "-0.02%"
  },
  {
      "Date": "17-Apr-09",
      "Price": "6.41",
      "Open": "6.41",
      "High": "6.41",
      "Low": "6.41",
      "Change %": "-0.54%"
  },
  {
      "Date": "16-Apr-09",
      "Price": "6.445",
      "Open": "6.445",
      "High": "6.445",
      "Low": "6.445",
      "Change %": "-2.60%"
  },
  {
      "Date": "15-Apr-09",
      "Price": "6.617",
      "Open": "6.617",
      "High": "6.617",
      "Low": "6.617",
      "Change %": "-1.82%"
  },
  {
      "Date": "13-Apr-09",
      "Price": "6.74",
      "Open": "6.74",
      "High": "6.74",
      "Low": "6.74",
      "Change %": "0.66%"
  },
  {
      "Date": "11-Apr-09",
      "Price": "6.696",
      "Open": "6.696",
      "High": "6.696",
      "Low": "6.696",
      "Change %": "-0.33%"
  },
  {
      "Date": "9-Apr-09",
      "Price": "6.718",
      "Open": "6.718",
      "High": "6.718",
      "Low": "6.718",
      "Change %": "-2.11%"
  },
  {
      "Date": "8-Apr-09",
      "Price": "6.863",
      "Open": "6.863",
      "High": "6.863",
      "Low": "6.863",
      "Change %": "-2.17%"
  },
  {
      "Date": "6-Apr-09",
      "Price": "7.015",
      "Open": "7.015",
      "High": "7.015",
      "Low": "7.015",
      "Change %": "0.66%"
  },
  {
      "Date": "4-Apr-09",
      "Price": "6.969",
      "Open": "6.969",
      "High": "6.969",
      "Low": "6.969",
      "Change %": "0.26%"
  },
  {
      "Date": "2-Apr-09",
      "Price": "6.951",
      "Open": "6.951",
      "High": "6.951",
      "Low": "6.951",
      "Change %": "-0.90%"
  },
  {
      "Date": "31-Mar-09",
      "Price": "7.014",
      "Open": "7.014",
      "High": "7.014",
      "Low": "7.014",
      "Change %": "-0.85%"
  },
  {
      "Date": "30-Mar-09",
      "Price": "7.074",
      "Open": "7.074",
      "High": "7.074",
      "Low": "7.074",
      "Change %": "1.16%"
  },
  {
      "Date": "28-Mar-09",
      "Price": "6.993",
      "Open": "6.993",
      "High": "6.993",
      "Low": "6.993",
      "Change %": "-0.54%"
  },
  {
      "Date": "26-Mar-09",
      "Price": "7.031",
      "Open": "7.031",
      "High": "7.031",
      "Low": "7.031",
      "Change %": "3.58%"
  },
  {
      "Date": "25-Mar-09",
      "Price": "6.788",
      "Open": "6.788",
      "High": "6.788",
      "Low": "6.788",
      "Change %": "1.30%"
  },
  {
      "Date": "24-Mar-09",
      "Price": "6.701",
      "Open": "6.701",
      "High": "6.701",
      "Low": "6.701",
      "Change %": "0.80%"
  },
  {
      "Date": "23-Mar-09",
      "Price": "6.648",
      "Open": "6.648",
      "High": "6.648",
      "Low": "6.648",
      "Change %": "-2.99%"
  },
  {
      "Date": "20-Mar-09",
      "Price": "6.853",
      "Open": "6.853",
      "High": "6.853",
      "Low": "6.853",
      "Change %": "0.60%"
  },
  {
      "Date": "19-Mar-09",
      "Price": "6.812",
      "Open": "6.812",
      "High": "6.812",
      "Low": "6.812",
      "Change %": "1.67%"
  },
  {
      "Date": "18-Mar-09",
      "Price": "6.7",
      "Open": "6.7",
      "High": "6.7",
      "Low": "6.7",
      "Change %": "-0.83%"
  },
  {
      "Date": "17-Mar-09",
      "Price": "6.756",
      "Open": "6.756",
      "High": "6.756",
      "Low": "6.756",
      "Change %": "1.99%"
  },
  {
      "Date": "16-Mar-09",
      "Price": "6.624",
      "Open": "6.624",
      "High": "6.624",
      "Low": "6.624",
      "Change %": "-2.87%"
  },
  {
      "Date": "13-Mar-09",
      "Price": "6.82",
      "Open": "6.82",
      "High": "6.82",
      "Low": "6.82",
      "Change %": "-3.63%"
  },
  {
      "Date": "12-Mar-09",
      "Price": "7.077",
      "Open": "7.077",
      "High": "7.077",
      "Low": "7.077",
      "Change %": "4.38%"
  },
  {
      "Date": "9-Mar-09",
      "Price": "6.78",
      "Open": "6.78",
      "High": "6.78",
      "Low": "6.78",
      "Change %": "0.41%"
  },
  {
      "Date": "6-Mar-09",
      "Price": "6.752",
      "Open": "6.752",
      "High": "6.752",
      "Low": "6.752",
      "Change %": "5.02%"
  },
  {
      "Date": "5-Mar-09",
      "Price": "6.429",
      "Open": "6.429",
      "High": "6.429",
      "Low": "6.429",
      "Change %": "-0.22%"
  },
  {
      "Date": "4-Mar-09",
      "Price": "6.443",
      "Open": "6.443",
      "High": "6.443",
      "Low": "6.443",
      "Change %": "1.18%"
  },
  {
      "Date": "3-Mar-09",
      "Price": "6.368",
      "Open": "6.368",
      "High": "6.368",
      "Low": "6.368",
      "Change %": "1.06%"
  },
  {
      "Date": "2-Mar-09",
      "Price": "6.301",
      "Open": "6.301",
      "High": "6.301",
      "Low": "6.301",
      "Change %": "-0.43%"
  },
  {
      "Date": "27-Feb-09",
      "Price": "6.328",
      "Open": "6.328",
      "High": "6.328",
      "Low": "6.328",
      "Change %": "-2.75%"
  },
  {
      "Date": "26-Feb-09",
      "Price": "6.507",
      "Open": "6.507",
      "High": "6.507",
      "Low": "6.507",
      "Change %": "-2.37%"
  },
  {
      "Date": "25-Feb-09",
      "Price": "6.665",
      "Open": "6.665",
      "High": "6.665",
      "Low": "6.665",
      "Change %": "1.05%"
  },
  {
      "Date": "24-Feb-09",
      "Price": "6.596",
      "Open": "6.596",
      "High": "6.596",
      "Low": "6.596",
      "Change %": "4.48%"
  },
  {
      "Date": "19-Feb-09",
      "Price": "6.313",
      "Open": "6.313",
      "High": "6.313",
      "Low": "6.313",
      "Change %": "-0.85%"
  },
  {
      "Date": "18-Feb-09",
      "Price": "6.367",
      "Open": "6.367",
      "High": "6.367",
      "Low": "6.367",
      "Change %": "-1.88%"
  },
  {
      "Date": "17-Feb-09",
      "Price": "6.489",
      "Open": "6.489",
      "High": "6.489",
      "Low": "6.489",
      "Change %": "1.15%"
  },
  {
      "Date": "16-Feb-09",
      "Price": "6.415",
      "Open": "6.415",
      "High": "6.415",
      "Low": "6.415",
      "Change %": "4.22%"
  },
  {
      "Date": "13-Feb-09",
      "Price": "6.155",
      "Open": "6.155",
      "High": "6.155",
      "Low": "6.155",
      "Change %": "-0.44%"
  },
  {
      "Date": "12-Feb-09",
      "Price": "6.182",
      "Open": "6.182",
      "High": "6.182",
      "Low": "6.182",
      "Change %": "-2.12%"
  },
  {
      "Date": "11-Feb-09",
      "Price": "6.316",
      "Open": "6.316",
      "High": "6.316",
      "Low": "6.316",
      "Change %": "-2.14%"
  },
  {
      "Date": "10-Feb-09",
      "Price": "6.454",
      "Open": "6.454",
      "High": "6.454",
      "Low": "6.454",
      "Change %": "2.17%"
  },
  {
      "Date": "9-Feb-09",
      "Price": "6.317",
      "Open": "6.317",
      "High": "6.317",
      "Low": "6.317",
      "Change %": "-0.11%"
  },
  {
      "Date": "6-Feb-09",
      "Price": "6.324",
      "Open": "6.324",
      "High": "6.324",
      "Low": "6.324",
      "Change %": "-2.71%"
  },
  {
      "Date": "5-Feb-09",
      "Price": "6.5",
      "Open": "6.5",
      "High": "6.5",
      "Low": "6.5",
      "Change %": "1.36%"
  },
  {
      "Date": "4-Feb-09",
      "Price": "6.413",
      "Open": "6.413",
      "High": "6.413",
      "Low": "6.413",
      "Change %": "0.85%"
  },
  {
      "Date": "3-Feb-09",
      "Price": "6.359",
      "Open": "6.359",
      "High": "6.359",
      "Low": "6.359",
      "Change %": "3.08%"
  },
  {
      "Date": "2-Feb-09",
      "Price": "6.169",
      "Open": "6.169",
      "High": "6.169",
      "Low": "6.169",
      "Change %": "-0.29%"
  },
  {
      "Date": "30-Jan-09",
      "Price": "6.187",
      "Open": "6.187",
      "High": "6.187",
      "Low": "6.187",
      "Change %": "1.28%"
  },
  {
      "Date": "29-Jan-09",
      "Price": "6.109",
      "Open": "6.109",
      "High": "6.109",
      "Low": "6.109",
      "Change %": "0.86%"
  },
  {
      "Date": "28-Jan-09",
      "Price": "6.057",
      "Open": "6.057",
      "High": "6.057",
      "Low": "6.057",
      "Change %": "1.53%"
  },
  {
      "Date": "27-Jan-09",
      "Price": "5.966",
      "Open": "5.966",
      "High": "5.966",
      "Low": "5.966",
      "Change %": "3.90%"
  },
  {
      "Date": "24-Jan-09",
      "Price": "5.742",
      "Open": "5.742",
      "High": "5.742",
      "Low": "5.742",
      "Change %": "-0.10%"
  },
  {
      "Date": "23-Jan-09",
      "Price": "5.748",
      "Open": "5.748",
      "High": "5.748",
      "Low": "5.748",
      "Change %": "-1.14%"
  },
  {
      "Date": "22-Jan-09",
      "Price": "5.814",
      "Open": "5.814",
      "High": "5.814",
      "Low": "5.814",
      "Change %": "-1.11%"
  },
  {
      "Date": "21-Jan-09",
      "Price": "5.879",
      "Open": "5.879",
      "High": "5.879",
      "Low": "5.879",
      "Change %": "3.47%"
  },
  {
      "Date": "20-Jan-09",
      "Price": "5.682",
      "Open": "5.682",
      "High": "5.682",
      "Low": "5.682",
      "Change %": "1.46%"
  },
  {
      "Date": "19-Jan-09",
      "Price": "5.6",
      "Open": "5.6",
      "High": "5.6",
      "Low": "5.6",
      "Change %": "0.21%"
  },
  {
      "Date": "16-Jan-09",
      "Price": "5.588",
      "Open": "5.588",
      "High": "5.588",
      "Low": "5.588",
      "Change %": "0.52%"
  },
  {
      "Date": "15-Jan-09",
      "Price": "5.559",
      "Open": "5.559",
      "High": "5.559",
      "Low": "5.559",
      "Change %": "-0.30%"
  },
  {
      "Date": "14-Jan-09",
      "Price": "5.576",
      "Open": "5.576",
      "High": "5.576",
      "Low": "5.576",
      "Change %": "-1.81%"
  },
  {
      "Date": "13-Jan-09",
      "Price": "5.679",
      "Open": "5.679",
      "High": "5.679",
      "Low": "5.679",
      "Change %": "-2.84%"
  },
  {
      "Date": "12-Jan-09",
      "Price": "5.845",
      "Open": "5.845",
      "High": "5.845",
      "Low": "5.845",
      "Change %": "-3.36%"
  },
  {
      "Date": "9-Jan-09",
      "Price": "6.048",
      "Open": "6.048",
      "High": "6.048",
      "Low": "6.048",
      "Change %": "1.10%"
  },
  {
      "Date": "7-Jan-09",
      "Price": "5.982",
      "Open": "5.982",
      "High": "5.982",
      "Low": "5.982",
      "Change %": "13.02%"
  },
  {
      "Date": "6-Jan-09",
      "Price": "5.293",
      "Open": "5.293",
      "High": "5.293",
      "Low": "5.293",
      "Change %": "2.34%"
  },
  {
      "Date": "5-Jan-09",
      "Price": "5.172",
      "Open": "5.172",
      "High": "5.172",
      "Low": "5.172",
      "Change %": "-3.31%"
  },
  {
      "Date": "2-Jan-09",
      "Price": "5.349",
      "Open": "5.349",
      "High": "5.349",
      "Low": "5.349",
      "Change %": "1.40%"
  },
  {
      "Date": "1-Jan-09",
      "Price": "5.275",
      "Open": "5.275",
      "High": "5.275",
      "Low": "5.275",
      "Change %": "0.29%"
  },
  {
      "Date": "31-Dec-08",
      "Price": "5.26",
      "Open": "5.26",
      "High": "5.26",
      "Low": "5.26",
      "Change %": "-1.44%"
  },
  {
      "Date": "30-Dec-08",
      "Price": "5.337",
      "Open": "5.337",
      "High": "5.337",
      "Low": "5.337",
      "Change %": "-3.82%"
  },
  {
      "Date": "29-Dec-08",
      "Price": "5.549",
      "Open": "5.549",
      "High": "5.549",
      "Low": "5.549",
      "Change %": "-1.23%"
  },
  {
      "Date": "26-Dec-08",
      "Price": "5.618",
      "Open": "5.618",
      "High": "5.618",
      "Low": "5.618",
      "Change %": "0.27%"
  },
  {
      "Date": "24-Dec-08",
      "Price": "5.603",
      "Open": "5.603",
      "High": "5.603",
      "Low": "5.603",
      "Change %": "-2.49%"
  },
  {
      "Date": "23-Dec-08",
      "Price": "5.746",
      "Open": "5.746",
      "High": "5.746",
      "Low": "5.746",
      "Change %": "0.42%"
  },
  {
      "Date": "22-Dec-08",
      "Price": "5.722",
      "Open": "5.722",
      "High": "5.722",
      "Low": "5.722",
      "Change %": "2.69%"
  },
  {
      "Date": "19-Dec-08",
      "Price": "5.572",
      "Open": "5.572",
      "High": "5.572",
      "Low": "5.572",
      "Change %": "-0.04%"
  },
  {
      "Date": "18-Dec-08",
      "Price": "5.574",
      "Open": "5.574",
      "High": "5.574",
      "Low": "5.574",
      "Change %": "-4.11%"
  },
  {
      "Date": "17-Dec-08",
      "Price": "5.813",
      "Open": "5.813",
      "High": "5.813",
      "Low": "5.813",
      "Change %": "-3.29%"
  },
  {
      "Date": "16-Dec-08",
      "Price": "6.011",
      "Open": "6.011",
      "High": "6.011",
      "Low": "6.011",
      "Change %": "-2.70%"
  },
  {
      "Date": "15-Dec-08",
      "Price": "6.178",
      "Open": "6.178",
      "High": "6.178",
      "Low": "6.178",
      "Change %": "-0.83%"
  },
  {
      "Date": "12-Dec-08",
      "Price": "6.23",
      "Open": "6.23",
      "High": "6.23",
      "Low": "6.23",
      "Change %": "-3.75%"
  },
  {
      "Date": "11-Dec-08",
      "Price": "6.473",
      "Open": "6.473",
      "High": "6.473",
      "Low": "6.473",
      "Change %": "-2.29%"
  },
  {
      "Date": "10-Dec-08",
      "Price": "6.625",
      "Open": "6.625",
      "High": "6.625",
      "Low": "6.625",
      "Change %": "-1.00%"
  },
  {
      "Date": "8-Dec-08",
      "Price": "6.692",
      "Open": "6.692",
      "High": "6.692",
      "Low": "6.692",
      "Change %": "-1.17%"
  },
  {
      "Date": "5-Dec-08",
      "Price": "6.771",
      "Open": "6.771",
      "High": "6.771",
      "Low": "6.771",
      "Change %": "-1.37%"
  },
  {
      "Date": "4-Dec-08",
      "Price": "6.865",
      "Open": "6.865",
      "High": "6.865",
      "Low": "6.865",
      "Change %": "1.15%"
  },
  {
      "Date": "3-Dec-08",
      "Price": "6.787",
      "Open": "6.787",
      "High": "6.787",
      "Low": "6.787",
      "Change %": "-3.26%"
  },
  {
      "Date": "2-Dec-08",
      "Price": "7.016",
      "Open": "7.016",
      "High": "7.016",
      "Low": "7.016",
      "Change %": "-0.69%"
  },
  {
      "Date": "1-Dec-08",
      "Price": "7.065",
      "Open": "7.065",
      "High": "7.065",
      "Low": "7.065",
      "Change %": "-0.21%"
  },
  {
      "Date": "28-Nov-08",
      "Price": "7.08",
      "Open": "7.08",
      "High": "7.08",
      "Low": "7.08",
      "Change %": "-0.45%"
  },
  {
      "Date": "26-Nov-08",
      "Price": "7.112",
      "Open": "7.112",
      "High": "7.112",
      "Low": "7.112",
      "Change %": "-1.43%"
  },
  {
      "Date": "25-Nov-08",
      "Price": "7.215",
      "Open": "7.215",
      "High": "7.215",
      "Low": "7.215",
      "Change %": "0.19%"
  },
  {
      "Date": "24-Nov-08",
      "Price": "7.201",
      "Open": "7.201",
      "High": "7.201",
      "Low": "7.201",
      "Change %": "-0.30%"
  },
  {
      "Date": "21-Nov-08",
      "Price": "7.223",
      "Open": "7.223",
      "High": "7.223",
      "Low": "7.223",
      "Change %": "-0.40%"
  },
  {
      "Date": "20-Nov-08",
      "Price": "7.252",
      "Open": "7.252",
      "High": "7.252",
      "Low": "7.252",
      "Change %": "-2.04%"
  },
  {
      "Date": "19-Nov-08",
      "Price": "7.403",
      "Open": "7.403",
      "High": "7.403",
      "Low": "7.403",
      "Change %": "-0.94%"
  },
  {
      "Date": "18-Nov-08",
      "Price": "7.473",
      "Open": "7.473",
      "High": "7.473",
      "Low": "7.473",
      "Change %": "-0.48%"
  },
  {
      "Date": "17-Nov-08",
      "Price": "7.509",
      "Open": "7.509",
      "High": "7.509",
      "Low": "7.509",
      "Change %": "0.55%"
  },
  {
      "Date": "14-Nov-08",
      "Price": "7.468",
      "Open": "7.468",
      "High": "7.468",
      "Low": "7.468",
      "Change %": "-2.15%"
  },
  {
      "Date": "12-Nov-08",
      "Price": "7.632",
      "Open": "7.632",
      "High": "7.632",
      "Low": "7.632",
      "Change %": "-0.65%"
  },
  {
      "Date": "11-Nov-08",
      "Price": "7.682",
      "Open": "7.682",
      "High": "7.682",
      "Low": "7.682",
      "Change %": "-0.43%"
  },
  {
      "Date": "10-Nov-08",
      "Price": "7.715",
      "Open": "7.715",
      "High": "7.715",
      "Low": "7.715",
      "Change %": "0.05%"
  },
  {
      "Date": "7-Nov-08",
      "Price": "7.711",
      "Open": "7.711",
      "High": "7.711",
      "Low": "7.711",
      "Change %": "-0.04%"
  },
  {
      "Date": "6-Nov-08",
      "Price": "7.714",
      "Open": "7.714",
      "High": "7.714",
      "Low": "7.714",
      "Change %": "-0.63%"
  },
  {
      "Date": "5-Nov-08",
      "Price": "7.763",
      "Open": "7.763",
      "High": "7.763",
      "Low": "7.763",
      "Change %": "1.73%"
  },
  {
      "Date": "4-Nov-08",
      "Price": "7.631",
      "Open": "7.631",
      "High": "7.631",
      "Low": "7.631",
      "Change %": "0.93%"
  },
  {
      "Date": "3-Nov-08",
      "Price": "7.561",
      "Open": "7.561",
      "High": "7.561",
      "Low": "7.561",
      "Change %": "1.11%"
  },
  {
      "Date": "31-Oct-08",
      "Price": "7.478",
      "Open": "7.478",
      "High": "7.478",
      "Low": "7.478",
      "Change %": "-0.45%"
  },
  {
      "Date": "29-Oct-08",
      "Price": "7.512",
      "Open": "7.512",
      "High": "7.512",
      "Low": "7.512",
      "Change %": "-1.29%"
  },
  {
      "Date": "27-Oct-08",
      "Price": "7.61",
      "Open": "7.61",
      "High": "7.61",
      "Low": "7.61",
      "Change %": "-0.95%"
  },
  {
      "Date": "24-Oct-08",
      "Price": "7.683",
      "Open": "7.683",
      "High": "7.683",
      "Low": "7.683",
      "Change %": "1.53%"
  },
  {
      "Date": "23-Oct-08",
      "Price": "7.567",
      "Open": "7.567",
      "High": "7.567",
      "Low": "7.567",
      "Change %": "-0.29%"
  },
  {
      "Date": "22-Oct-08",
      "Price": "7.589",
      "Open": "7.589",
      "High": "7.589",
      "Low": "7.589",
      "Change %": "0.96%"
  },
  {
      "Date": "20-Oct-08",
      "Price": "7.517",
      "Open": "7.517",
      "High": "7.517",
      "Low": "7.517",
      "Change %": "-2.44%"
  },
  {
      "Date": "17-Oct-08",
      "Price": "7.705",
      "Open": "7.705",
      "High": "7.705",
      "Low": "7.705",
      "Change %": "-0.12%"
  },
  {
      "Date": "16-Oct-08",
      "Price": "7.714",
      "Open": "7.714",
      "High": "7.714",
      "Low": "7.714",
      "Change %": "-2.71%"
  },
  {
      "Date": "15-Oct-08",
      "Price": "7.929",
      "Open": "7.929",
      "High": "7.929",
      "Low": "7.929",
      "Change %": "-0.56%"
  },
  {
      "Date": "14-Oct-08",
      "Price": "7.974",
      "Open": "7.974",
      "High": "7.974",
      "Low": "7.974",
      "Change %": "2.43%"
  },
  {
      "Date": "13-Oct-08",
      "Price": "7.785",
      "Open": "7.785",
      "High": "7.785",
      "Low": "7.785",
      "Change %": "-0.37%"
  },
  {
      "Date": "10-Oct-08",
      "Price": "7.814",
      "Open": "7.814",
      "High": "7.814",
      "Low": "7.814",
      "Change %": "-2.04%"
  },
  {
      "Date": "8-Oct-08",
      "Price": "7.977",
      "Open": "7.977",
      "High": "7.977",
      "Low": "7.977",
      "Change %": "-1.45%"
  },
  {
      "Date": "7-Oct-08",
      "Price": "8.094",
      "Open": "8.094",
      "High": "8.094",
      "Low": "8.094",
      "Change %": "-0.10%"
  },
  {
      "Date": "6-Oct-08",
      "Price": "8.102",
      "Open": "8.102",
      "High": "8.102",
      "Low": "8.102",
      "Change %": "-2.28%"
  },
  {
      "Date": "3-Oct-08",
      "Price": "8.291",
      "Open": "8.291",
      "High": "8.291",
      "Low": "8.291",
      "Change %": "-2.00%"
  },
  {
      "Date": "1-Oct-08",
      "Price": "8.46",
      "Open": "8.46",
      "High": "8.46",
      "Low": "8.46",
      "Change %": "-1.82%"
  },
  {
      "Date": "29-Sep-08",
      "Price": "8.617",
      "Open": "8.617",
      "High": "8.617",
      "Low": "8.617",
      "Change %": "0.43%"
  },
  {
      "Date": "26-Sep-08",
      "Price": "8.58",
      "Open": "8.58",
      "High": "8.58",
      "Low": "8.58",
      "Change %": "0.09%"
  },
  {
      "Date": "25-Sep-08",
      "Price": "8.572",
      "Open": "8.572",
      "High": "8.572",
      "Low": "8.572",
      "Change %": "-0.70%"
  },
  {
      "Date": "24-Sep-08",
      "Price": "8.632",
      "Open": "8.632",
      "High": "8.632",
      "Low": "8.632",
      "Change %": "2.44%"
  },
  {
      "Date": "23-Sep-08",
      "Price": "8.426",
      "Open": "8.426",
      "High": "8.426",
      "Low": "8.426",
      "Change %": "-0.45%"
  },
  {
      "Date": "22-Sep-08",
      "Price": "8.464",
      "Open": "8.464",
      "High": "8.464",
      "Low": "8.464",
      "Change %": "0.58%"
  },
  {
      "Date": "19-Sep-08",
      "Price": "8.415",
      "Open": "8.415",
      "High": "8.415",
      "Low": "8.415",
      "Change %": "0.37%"
  },
  {
      "Date": "18-Sep-08",
      "Price": "8.384",
      "Open": "8.384",
      "High": "8.384",
      "Low": "8.384",
      "Change %": "2.43%"
  },
  {
      "Date": "17-Sep-08",
      "Price": "8.185",
      "Open": "8.185",
      "High": "8.185",
      "Low": "8.185",
      "Change %": "1.09%"
  },
  {
      "Date": "16-Sep-08",
      "Price": "8.097",
      "Open": "8.097",
      "High": "8.097",
      "Low": "8.097",
      "Change %": "-0.65%"
  },
  {
      "Date": "15-Sep-08",
      "Price": "8.15",
      "Open": "8.15",
      "High": "8.15",
      "Low": "8.15",
      "Change %": "-2.37%"
  },
  {
      "Date": "12-Sep-08",
      "Price": "8.348",
      "Open": "8.348",
      "High": "8.348",
      "Low": "8.348",
      "Change %": "0.72%"
  },
  {
      "Date": "11-Sep-08",
      "Price": "8.288",
      "Open": "8.288",
      "High": "8.288",
      "Low": "8.288",
      "Change %": "-0.83%"
  },
  {
      "Date": "10-Sep-08",
      "Price": "8.357",
      "Open": "8.357",
      "High": "8.357",
      "Low": "8.357",
      "Change %": "-0.63%"
  },
  {
      "Date": "9-Sep-08",
      "Price": "8.41",
      "Open": "8.41",
      "High": "8.41",
      "Low": "8.41",
      "Change %": "-0.99%"
  },
  {
      "Date": "8-Sep-08",
      "Price": "8.494",
      "Open": "8.494",
      "High": "8.494",
      "Low": "8.494",
      "Change %": "0.12%"
  },
  {
      "Date": "5-Sep-08",
      "Price": "8.484",
      "Open": "8.484",
      "High": "8.484",
      "Low": "8.484",
      "Change %": "0.09%"
  },
  {
      "Date": "4-Sep-08",
      "Price": "8.476",
      "Open": "8.476",
      "High": "8.476",
      "Low": "8.476",
      "Change %": "-1.54%"
  },
  {
      "Date": "2-Sep-08",
      "Price": "8.609",
      "Open": "8.609",
      "High": "8.609",
      "Low": "8.609",
      "Change %": "-0.32%"
  },
  {
      "Date": "1-Sep-08",
      "Price": "8.637",
      "Open": "8.637",
      "High": "8.637",
      "Low": "8.637",
      "Change %": "-0.72%"
  },
  {
      "Date": "29-Aug-08",
      "Price": "8.7",
      "Open": "8.7",
      "High": "8.7",
      "Low": "8.7",
      "Change %": "-1.13%"
  },
  {
      "Date": "28-Aug-08",
      "Price": "8.799",
      "Open": "8.799",
      "High": "8.799",
      "Low": "8.799",
      "Change %": "-0.97%"
  },
  {
      "Date": "27-Aug-08",
      "Price": "8.885",
      "Open": "8.885",
      "High": "8.885",
      "Low": "8.885",
      "Change %": "-0.07%"
  },
  {
      "Date": "26-Aug-08",
      "Price": "8.891",
      "Open": "8.891",
      "High": "8.891",
      "Low": "8.891",
      "Change %": "-1.11%"
  },
  {
      "Date": "25-Aug-08",
      "Price": "8.991",
      "Open": "8.991",
      "High": "8.991",
      "Low": "8.991",
      "Change %": "-1.03%"
  },
  {
      "Date": "22-Aug-08",
      "Price": "9.085",
      "Open": "9.085",
      "High": "9.085",
      "Low": "9.085",
      "Change %": "-1.31%"
  },
  {
      "Date": "21-Aug-08",
      "Price": "9.206",
      "Open": "9.206",
      "High": "9.206",
      "Low": "9.206",
      "Change %": "0.81%"
  },
  {
      "Date": "20-Aug-08",
      "Price": "9.132",
      "Open": "9.132",
      "High": "9.132",
      "Low": "9.132",
      "Change %": "0.42%"
  },
  {
      "Date": "18-Aug-08",
      "Price": "9.094",
      "Open": "9.094",
      "High": "9.094",
      "Low": "9.094",
      "Change %": "-0.73%"
  },
  {
      "Date": "14-Aug-08",
      "Price": "9.161",
      "Open": "9.161",
      "High": "9.161",
      "Low": "9.161",
      "Change %": "0.91%"
  },
  {
      "Date": "13-Aug-08",
      "Price": "9.078",
      "Open": "9.078",
      "High": "9.078",
      "Low": "9.078",
      "Change %": "1.26%"
  },
  {
      "Date": "12-Aug-08",
      "Price": "8.965",
      "Open": "8.965",
      "High": "8.965",
      "Low": "8.965",
      "Change %": "0.64%"
  },
  {
      "Date": "11-Aug-08",
      "Price": "8.908",
      "Open": "8.908",
      "High": "8.908",
      "Low": "8.908",
      "Change %": "-1.34%"
  },
  {
      "Date": "8-Aug-08",
      "Price": "9.029",
      "Open": "9.029",
      "High": "9.029",
      "Low": "9.029",
      "Change %": "-0.75%"
  },
  {
      "Date": "7-Aug-08",
      "Price": "9.097",
      "Open": "9.097",
      "High": "9.097",
      "Low": "9.097",
      "Change %": "0.80%"
  },
  {
      "Date": "6-Aug-08",
      "Price": "9.025",
      "Open": "9.025",
      "High": "9.025",
      "Low": "9.025",
      "Change %": "0.41%"
  },
  {
      "Date": "5-Aug-08",
      "Price": "8.988",
      "Open": "8.988",
      "High": "8.988",
      "Low": "8.988",
      "Change %": "-2.30%"
  },
  {
      "Date": "4-Aug-08",
      "Price": "9.2",
      "Open": "9.2",
      "High": "9.2",
      "Low": "9.2",
      "Change %": "-0.80%"
  },
  {
      "Date": "1-Aug-08",
      "Price": "9.274",
      "Open": "9.274",
      "High": "9.274",
      "Low": "9.274",
      "Change %": "-0.45%"
  },
  {
      "Date": "31-Jul-08",
      "Price": "9.316",
      "Open": "9.316",
      "High": "9.316",
      "Low": "9.316",
      "Change %": "-0.05%"
  },
  {
      "Date": "30-Jul-08",
      "Price": "9.321",
      "Open": "9.321",
      "High": "9.321",
      "Low": "9.321",
      "Change %": "-0.77%"
  },
  {
      "Date": "29-Jul-08",
      "Price": "9.393",
      "Open": "9.393",
      "High": "9.393",
      "Low": "9.393",
      "Change %": "3.42%"
  },
  {
      "Date": "28-Jul-08",
      "Price": "9.082",
      "Open": "9.082",
      "High": "9.082",
      "Low": "9.082",
      "Change %": "-0.82%"
  },
  {
      "Date": "25-Jul-08",
      "Price": "9.157",
      "Open": "9.157",
      "High": "9.157",
      "Low": "9.157",
      "Change %": "0.96%"
  },
  {
      "Date": "24-Jul-08",
      "Price": "9.07",
      "Open": "9.07",
      "High": "9.07",
      "Low": "9.07",
      "Change %": "0.35%"
  },
  {
      "Date": "23-Jul-08",
      "Price": "9.038",
      "Open": "9.038",
      "High": "9.038",
      "Low": "9.038",
      "Change %": "-1.03%"
  },
  {
      "Date": "22-Jul-08",
      "Price": "9.132",
      "Open": "9.132",
      "High": "9.132",
      "Low": "9.132",
      "Change %": "0.88%"
  },
  {
      "Date": "21-Jul-08",
      "Price": "9.052",
      "Open": "9.052",
      "High": "9.052",
      "Low": "9.052",
      "Change %": "-0.48%"
  },
  {
      "Date": "18-Jul-08",
      "Price": "9.096",
      "Open": "9.096",
      "High": "9.096",
      "Low": "9.096",
      "Change %": "-1.62%"
  },
  {
      "Date": "17-Jul-08",
      "Price": "9.246",
      "Open": "9.246",
      "High": "9.246",
      "Low": "9.246",
      "Change %": "-1.46%"
  },
  {
      "Date": "16-Jul-08",
      "Price": "9.383",
      "Open": "9.383",
      "High": "9.383",
      "Low": "9.383",
      "Change %": "-1.00%"
  },
  {
      "Date": "15-Jul-08",
      "Price": "9.478",
      "Open": "9.478",
      "High": "9.478",
      "Low": "9.478",
      "Change %": "1.01%"
  },
  {
      "Date": "14-Jul-08",
      "Price": "9.383",
      "Open": "9.383",
      "High": "9.383",
      "Low": "9.383",
      "Change %": "-0.91%"
  },
  {
      "Date": "11-Jul-08",
      "Price": "9.469",
      "Open": "9.469",
      "High": "9.469",
      "Low": "9.469",
      "Change %": "0.53%"
  },
  {
      "Date": "10-Jul-08",
      "Price": "9.419",
      "Open": "9.419",
      "High": "9.419",
      "Low": "9.419",
      "Change %": "1.07%"
  },
  {
      "Date": "9-Jul-08",
      "Price": "9.319",
      "Open": "9.319",
      "High": "9.319",
      "Low": "9.319",
      "Change %": "0.27%"
  },
  {
      "Date": "8-Jul-08",
      "Price": "9.294",
      "Open": "9.294",
      "High": "9.294",
      "Low": "9.294",
      "Change %": "1.47%"
  },
  {
      "Date": "7-Jul-08",
      "Price": "9.159",
      "Open": "9.159",
      "High": "9.159",
      "Low": "9.159",
      "Change %": "0.05%"
  },
  {
      "Date": "4-Jul-08",
      "Price": "9.154",
      "Open": "9.154",
      "High": "9.154",
      "Low": "9.154",
      "Change %": "3.55%"
  },
  {
      "Date": "3-Jul-08",
      "Price": "8.84",
      "Open": "8.84",
      "High": "8.84",
      "Low": "8.84",
      "Change %": "0.36%"
  },
  {
      "Date": "2-Jul-08",
      "Price": "8.808",
      "Open": "8.808",
      "High": "8.808",
      "Low": "8.808",
      "Change %": "0.18%"
  },
  {
      "Date": "1-Jul-08",
      "Price": "8.792",
      "Open": "8.792",
      "High": "8.792",
      "Low": "8.792",
      "Change %": "0.91%"
  },
  {
      "Date": "30-Jun-08",
      "Price": "8.713",
      "Open": "8.713",
      "High": "8.713",
      "Low": "8.713",
      "Change %": "1.14%"
  },
  {
      "Date": "27-Jun-08",
      "Price": "8.615",
      "Open": "8.615",
      "High": "8.615",
      "Low": "8.615",
      "Change %": "0.03%"
  },
  {
      "Date": "26-Jun-08",
      "Price": "8.612",
      "Open": "8.612",
      "High": "8.612",
      "Low": "8.612",
      "Change %": "-0.62%"
  },
  {
      "Date": "25-Jun-08",
      "Price": "8.666",
      "Open": "8.666",
      "High": "8.666",
      "Low": "8.666",
      "Change %": "1.16%"
  },
  {
      "Date": "24-Jun-08",
      "Price": "8.567",
      "Open": "8.567",
      "High": "8.567",
      "Low": "8.567",
      "Change %": "-0.75%"
  },
  {
      "Date": "23-Jun-08",
      "Price": "8.632",
      "Open": "8.632",
      "High": "8.632",
      "Low": "8.632",
      "Change %": "0.06%"
  },
  {
      "Date": "20-Jun-08",
      "Price": "8.627",
      "Open": "8.627",
      "High": "8.627",
      "Low": "8.627",
      "Change %": "1.77%"
  },
  {
      "Date": "19-Jun-08",
      "Price": "8.477",
      "Open": "8.477",
      "High": "8.477",
      "Low": "8.477",
      "Change %": "1.33%"
  },
  {
      "Date": "18-Jun-08",
      "Price": "8.366",
      "Open": "8.366",
      "High": "8.366",
      "Low": "8.366",
      "Change %": "0.37%"
  },
  {
      "Date": "17-Jun-08",
      "Price": "8.335",
      "Open": "8.335",
      "High": "8.335",
      "Low": "8.335",
      "Change %": "-0.28%"
  },
  {
      "Date": "16-Jun-08",
      "Price": "8.358",
      "Open": "8.358",
      "High": "8.358",
      "Low": "8.358",
      "Change %": "-0.42%"
  },
  {
      "Date": "13-Jun-08",
      "Price": "8.393",
      "Open": "8.393",
      "High": "8.393",
      "Low": "8.393",
      "Change %": "0.80%"
  },
  {
      "Date": "12-Jun-08",
      "Price": "8.326",
      "Open": "8.326",
      "High": "8.326",
      "Low": "8.326",
      "Change %": "0.76%"
  },
  {
      "Date": "11-Jun-08",
      "Price": "8.263",
      "Open": "8.263",
      "High": "8.263",
      "Low": "8.263",
      "Change %": "-0.31%"
  },
  {
      "Date": "10-Jun-08",
      "Price": "8.289",
      "Open": "8.289",
      "High": "8.289",
      "Low": "8.289",
      "Change %": "0.02%"
  },
  {
      "Date": "9-Jun-08",
      "Price": "8.287",
      "Open": "8.287",
      "High": "8.287",
      "Low": "8.287",
      "Change %": "0.59%"
  },
  {
      "Date": "6-Jun-08",
      "Price": "8.238",
      "Open": "8.238",
      "High": "8.238",
      "Low": "8.238",
      "Change %": "0.65%"
  },
  {
      "Date": "5-Jun-08",
      "Price": "8.185",
      "Open": "8.185",
      "High": "8.185",
      "Low": "8.185",
      "Change %": "0.54%"
  },
  {
      "Date": "4-Jun-08",
      "Price": "8.141",
      "Open": "8.141",
      "High": "8.141",
      "Low": "8.141",
      "Change %": "0.17%"
  },
  {
      "Date": "3-Jun-08",
      "Price": "8.127",
      "Open": "8.127",
      "High": "8.127",
      "Low": "8.127",
      "Change %": "0.52%"
  },
  {
      "Date": "2-Jun-08",
      "Price": "8.085",
      "Open": "8.085",
      "High": "8.085",
      "Low": "8.085",
      "Change %": "-0.20%"
  },
  {
      "Date": "30-May-08",
      "Price": "8.101",
      "Open": "8.101",
      "High": "8.101",
      "Low": "8.101",
      "Change %": "-0.04%"
  },
  {
      "Date": "29-May-08",
      "Price": "8.104",
      "Open": "8.104",
      "High": "8.104",
      "Low": "8.104",
      "Change %": "0.55%"
  },
  {
      "Date": "28-May-08",
      "Price": "8.06",
      "Open": "8.06",
      "High": "8.06",
      "Low": "8.06",
      "Change %": "-0.73%"
  },
  {
      "Date": "27-May-08",
      "Price": "8.119",
      "Open": "8.119",
      "High": "8.119",
      "Low": "8.119",
      "Change %": "0.06%"
  },
  {
      "Date": "26-May-08",
      "Price": "8.114",
      "Open": "8.114",
      "High": "8.114",
      "Low": "8.114",
      "Change %": "0.32%"
  },
  {
      "Date": "23-May-08",
      "Price": "8.088",
      "Open": "8.088",
      "High": "8.088",
      "Low": "8.088",
      "Change %": "-0.31%"
  },
  {
      "Date": "22-May-08",
      "Price": "8.113",
      "Open": "8.113",
      "High": "8.113",
      "Low": "8.113",
      "Change %": "0.68%"
  },
  {
      "Date": "21-May-08",
      "Price": "8.058",
      "Open": "8.058",
      "High": "8.058",
      "Low": "8.058",
      "Change %": "0.69%"
  },
  {
      "Date": "20-May-08",
      "Price": "8.003",
      "Open": "8.003",
      "High": "8.003",
      "Low": "8.003",
      "Change %": "-0.10%"
  },
  {
      "Date": "16-May-08",
      "Price": "8.011",
      "Open": "8.011",
      "High": "8.011",
      "Low": "8.011",
      "Change %": "0.39%"
  },
  {
      "Date": "15-May-08",
      "Price": "7.98",
      "Open": "7.98",
      "High": "7.98",
      "Low": "7.98",
      "Change %": "0.53%"
  },
  {
      "Date": "14-May-08",
      "Price": "7.938",
      "Open": "7.938",
      "High": "7.938",
      "Low": "7.938",
      "Change %": "0.30%"
  },
  {
      "Date": "13-May-08",
      "Price": "7.914",
      "Open": "7.914",
      "High": "7.914",
      "Low": "7.914",
      "Change %": "0.22%"
  },
  {
      "Date": "12-May-08",
      "Price": "7.897",
      "Open": "7.897",
      "High": "7.897",
      "Low": "7.897",
      "Change %": "-0.25%"
  },
  {
      "Date": "9-May-08",
      "Price": "7.917",
      "Open": "7.917",
      "High": "7.917",
      "Low": "7.917",
      "Change %": "-0.34%"
  },
  {
      "Date": "8-May-08",
      "Price": "7.944",
      "Open": "7.944",
      "High": "7.944",
      "Low": "7.944",
      "Change %": "0.24%"
  },
  {
      "Date": "7-May-08",
      "Price": "7.925",
      "Open": "7.925",
      "High": "7.925",
      "Low": "7.925",
      "Change %": "0.44%"
  },
  {
      "Date": "6-May-08",
      "Price": "7.89",
      "Open": "7.89",
      "High": "7.89",
      "Low": "7.89",
      "Change %": "0.18%"
  },
  {
      "Date": "5-May-08",
      "Price": "7.876",
      "Open": "7.876",
      "High": "7.876",
      "Low": "7.876",
      "Change %": "-0.34%"
  },
  {
      "Date": "2-May-08",
      "Price": "7.903",
      "Open": "7.903",
      "High": "7.903",
      "Low": "7.903",
      "Change %": "-0.67%"
  },
  {
      "Date": "30-Apr-08",
      "Price": "7.956",
      "Open": "7.956",
      "High": "7.956",
      "Low": "7.956",
      "Change %": "-0.60%"
  },
  {
      "Date": "29-Apr-08",
      "Price": "8.004",
      "Open": "8.004",
      "High": "8.004",
      "Low": "8.004",
      "Change %": "-1.68%"
  },
  {
      "Date": "28-Apr-08",
      "Price": "8.141",
      "Open": "8.141",
      "High": "8.141",
      "Low": "8.141",
      "Change %": "-0.42%"
  },
  {
      "Date": "25-Apr-08",
      "Price": "8.175",
      "Open": "8.175",
      "High": "8.175",
      "Low": "8.175",
      "Change %": "-0.23%"
  },
  {
      "Date": "24-Apr-08",
      "Price": "8.194",
      "Open": "8.194",
      "High": "8.194",
      "Low": "8.194",
      "Change %": "0.10%"
  },
  {
      "Date": "23-Apr-08",
      "Price": "8.186",
      "Open": "8.186",
      "High": "8.186",
      "Low": "8.186",
      "Change %": "0.26%"
  },
  {
      "Date": "22-Apr-08",
      "Price": "8.165",
      "Open": "8.165",
      "High": "8.165",
      "Low": "8.165",
      "Change %": "-0.16%"
  },
  {
      "Date": "21-Apr-08",
      "Price": "8.178",
      "Open": "8.178",
      "High": "8.178",
      "Low": "8.178",
      "Change %": "0.74%"
  },
  {
      "Date": "17-Apr-08",
      "Price": "8.118",
      "Open": "8.118",
      "High": "8.118",
      "Low": "8.118",
      "Change %": "0.47%"
  },
  {
      "Date": "16-Apr-08",
      "Price": "8.08",
      "Open": "8.08",
      "High": "8.08",
      "Low": "8.08",
      "Change %": "0.77%"
  },
  {
      "Date": "15-Apr-08",
      "Price": "8.018",
      "Open": "8.018",
      "High": "8.018",
      "Low": "8.018",
      "Change %": "-0.20%"
  },
  {
      "Date": "11-Apr-08",
      "Price": "8.034",
      "Open": "8.034",
      "High": "8.034",
      "Low": "8.034",
      "Change %": "0.88%"
  },
  {
      "Date": "10-Apr-08",
      "Price": "7.964",
      "Open": "7.964",
      "High": "7.964",
      "Low": "7.964",
      "Change %": "0.49%"
  },
  {
      "Date": "9-Apr-08",
      "Price": "7.925",
      "Open": "7.925",
      "High": "7.925",
      "Low": "7.925",
      "Change %": "-0.25%"
  },
  {
      "Date": "8-Apr-08",
      "Price": "7.945",
      "Open": "7.945",
      "High": "7.945",
      "Low": "7.945",
      "Change %": "-0.56%"
  },
  {
      "Date": "7-Apr-08",
      "Price": "7.99",
      "Open": "7.99",
      "High": "7.99",
      "Low": "7.99",
      "Change %": "0.06%"
  },
  {
      "Date": "4-Apr-08",
      "Price": "7.985",
      "Open": "7.985",
      "High": "7.985",
      "Low": "7.985",
      "Change %": "1.24%"
  },
  {
      "Date": "3-Apr-08",
      "Price": "7.887",
      "Open": "7.887",
      "High": "7.887",
      "Low": "7.887",
      "Change %": "-0.55%"
  },
  {
      "Date": "2-Apr-08",
      "Price": "7.931",
      "Open": "7.931",
      "High": "7.931",
      "Low": "7.931",
      "Change %": "-0.09%"
  },
  {
      "Date": "31-Mar-08",
      "Price": "7.938",
      "Open": "7.938",
      "High": "7.938",
      "Low": "7.938",
      "Change %": "0.81%"
  },
  {
      "Date": "28-Mar-08",
      "Price": "7.874",
      "Open": "7.874",
      "High": "7.874",
      "Low": "7.874",
      "Change %": "1.30%"
  },
  {
      "Date": "27-Mar-08",
      "Price": "7.773",
      "Open": "7.773",
      "High": "7.773",
      "Low": "7.773",
      "Change %": "0.06%"
  },
  {
      "Date": "26-Mar-08",
      "Price": "7.768",
      "Open": "7.768",
      "High": "7.768",
      "Low": "7.768",
      "Change %": "0.58%"
  },
  {
      "Date": "25-Mar-08",
      "Price": "7.723",
      "Open": "7.723",
      "High": "7.723",
      "Low": "7.723",
      "Change %": "0.04%"
  },
  {
      "Date": "24-Mar-08",
      "Price": "7.72",
      "Open": "7.72",
      "High": "7.72",
      "Low": "7.72",
      "Change %": "1.30%"
  },
  {
      "Date": "19-Mar-08",
      "Price": "7.621",
      "Open": "7.621",
      "High": "7.621",
      "Low": "7.621",
      "Change %": "0.37%"
  },
  {
      "Date": "18-Mar-08",
      "Price": "7.593",
      "Open": "7.593",
      "High": "7.593",
      "Low": "7.593",
      "Change %": "-0.52%"
  },
  {
      "Date": "17-Mar-08",
      "Price": "7.633",
      "Open": "7.633",
      "High": "7.633",
      "Low": "7.633",
      "Change %": "0.12%"
  },
  {
      "Date": "15-Mar-08",
      "Price": "7.624",
      "Open": "7.624",
      "High": "7.624",
      "Low": "7.624",
      "Change %": "-0.08%"
  },
  {
      "Date": "14-Mar-08",
      "Price": "7.63",
      "Open": "7.63",
      "High": "7.63",
      "Low": "7.63",
      "Change %": "0.81%"
  },
  {
      "Date": "13-Mar-08",
      "Price": "7.569",
      "Open": "7.569",
      "High": "7.569",
      "Low": "7.569",
      "Change %": "0.13%"
  },
  {
      "Date": "12-Mar-08",
      "Price": "7.559",
      "Open": "7.559",
      "High": "7.559",
      "Low": "7.559",
      "Change %": "-0.59%"
  },
  {
      "Date": "11-Mar-08",
      "Price": "7.604",
      "Open": "7.604",
      "High": "7.604",
      "Low": "7.604",
      "Change %": "-0.21%"
  },
  {
      "Date": "10-Mar-08",
      "Price": "7.62",
      "Open": "7.62",
      "High": "7.62",
      "Low": "7.62",
      "Change %": "0.46%"
  },
  {
      "Date": "8-Mar-08",
      "Price": "7.585",
      "Open": "7.585",
      "High": "7.585",
      "Low": "7.585",
      "Change %": "-0.07%"
  },
  {
      "Date": "7-Mar-08",
      "Price": "7.59",
      "Open": "7.59",
      "High": "7.59",
      "Low": "7.59",
      "Change %": "0.52%"
  },
  {
      "Date": "5-Mar-08",
      "Price": "7.551",
      "Open": "7.551",
      "High": "7.551",
      "Low": "7.551",
      "Change %": "-0.41%"
  },
  {
      "Date": "4-Mar-08",
      "Price": "7.582",
      "Open": "7.582",
      "High": "7.582",
      "Low": "7.582",
      "Change %": "0.77%"
  },
  {
      "Date": "3-Mar-08",
      "Price": "7.524",
      "Open": "7.524",
      "High": "7.524",
      "Low": "7.524",
      "Change %": "-0.58%"
  },
  {
      "Date": "29-Feb-08",
      "Price": "7.568",
      "Open": "7.568",
      "High": "7.568",
      "Low": "7.568",
      "Change %": "-0.45%"
  },
  {
      "Date": "28-Feb-08",
      "Price": "7.602",
      "Open": "7.602",
      "High": "7.602",
      "Low": "7.602",
      "Change %": "-0.35%"
  },
  {
      "Date": "27-Feb-08",
      "Price": "7.629",
      "Open": "7.629",
      "High": "7.629",
      "Low": "7.629",
      "Change %": "-0.03%"
  },
  {
      "Date": "26-Feb-08",
      "Price": "7.631",
      "Open": "7.631",
      "High": "7.631",
      "Low": "7.631",
      "Change %": "-0.12%"
  },
  {
      "Date": "25-Feb-08",
      "Price": "7.64",
      "Open": "7.64",
      "High": "7.64",
      "Low": "7.64",
      "Change %": "-0.68%"
  },
  {
      "Date": "23-Feb-08",
      "Price": "7.692",
      "Open": "7.692",
      "High": "7.692",
      "Low": "7.692",
      "Change %": "0.17%"
  },
  {
      "Date": "22-Feb-08",
      "Price": "7.679",
      "Open": "7.679",
      "High": "7.679",
      "Low": "7.679",
      "Change %": "1.27%"
  },
  {
      "Date": "21-Feb-08",
      "Price": "7.583",
      "Open": "7.583",
      "High": "7.583",
      "Low": "7.583",
      "Change %": "-0.81%"
  },
  {
      "Date": "20-Feb-08",
      "Price": "7.645",
      "Open": "7.645",
      "High": "7.645",
      "Low": "7.645",
      "Change %": "1.10%"
  },
  {
      "Date": "19-Feb-08",
      "Price": "7.562",
      "Open": "7.562",
      "High": "7.562",
      "Low": "7.562",
      "Change %": "0.25%"
  },
  {
      "Date": "18-Feb-08",
      "Price": "7.543",
      "Open": "7.543",
      "High": "7.543",
      "Low": "7.543",
      "Change %": "0.41%"
  },
  {
      "Date": "16-Feb-08",
      "Price": "7.512",
      "Open": "7.512",
      "High": "7.512",
      "Low": "7.512",
      "Change %": "0.12%"
  },
  {
      "Date": "15-Feb-08",
      "Price": "7.503",
      "Open": "7.503",
      "High": "7.503",
      "Low": "7.503",
      "Change %": "0.52%"
  },
  {
      "Date": "14-Feb-08",
      "Price": "7.464",
      "Open": "7.464",
      "High": "7.464",
      "Low": "7.464",
      "Change %": "0.12%"
  },
  {
      "Date": "13-Feb-08",
      "Price": "7.455",
      "Open": "7.455",
      "High": "7.455",
      "Low": "7.455",
      "Change %": "0.03%"
  },
  {
      "Date": "12-Feb-08",
      "Price": "7.453",
      "Open": "7.453",
      "High": "7.453",
      "Low": "7.453",
      "Change %": "0.19%"
  },
  {
      "Date": "11-Feb-08",
      "Price": "7.439",
      "Open": "7.439",
      "High": "7.439",
      "Low": "7.439",
      "Change %": "-0.33%"
  },
  {
      "Date": "9-Feb-08",
      "Price": "7.464",
      "Open": "7.464",
      "High": "7.464",
      "Low": "7.464",
      "Change %": "-0.08%"
  },
  {
      "Date": "8-Feb-08",
      "Price": "7.47",
      "Open": "7.47",
      "High": "7.47",
      "Low": "7.47",
      "Change %": "-0.04%"
  },
  {
      "Date": "7-Feb-08",
      "Price": "7.473",
      "Open": "7.473",
      "High": "7.473",
      "Low": "7.473",
      "Change %": "-0.20%"
  },
  {
      "Date": "6-Feb-08",
      "Price": "7.488",
      "Open": "7.488",
      "High": "7.488",
      "Low": "7.488",
      "Change %": "-0.40%"
  },
  {
      "Date": "5-Feb-08",
      "Price": "7.518",
      "Open": "7.518",
      "High": "7.518",
      "Low": "7.518",
      "Change %": "0.04%"
  },
  {
      "Date": "4-Feb-08",
      "Price": "7.515",
      "Open": "7.515",
      "High": "7.515",
      "Low": "7.515",
      "Change %": "0.25%"
  },
  {
      "Date": "2-Feb-08",
      "Price": "7.496",
      "Open": "7.496",
      "High": "7.496",
      "Low": "7.496",
      "Change %": "0.12%"
  },
  {
      "Date": "1-Feb-08",
      "Price": "7.487",
      "Open": "7.487",
      "High": "7.487",
      "Low": "7.487",
      "Change %": "-0.56%"
  },
  {
      "Date": "31-Jan-08",
      "Price": "7.529",
      "Open": "7.529",
      "High": "7.529",
      "Low": "7.529",
      "Change %": "-0.40%"
  },
  {
      "Date": "30-Jan-08",
      "Price": "7.559",
      "Open": "7.559",
      "High": "7.559",
      "Low": "7.559",
      "Change %": "0.23%"
  },
  {
      "Date": "29-Jan-08",
      "Price": "7.542",
      "Open": "7.542",
      "High": "7.542",
      "Low": "7.542",
      "Change %": "1.40%"
  },
  {
      "Date": "28-Jan-08",
      "Price": "7.438",
      "Open": "7.438",
      "High": "7.438",
      "Low": "7.438",
      "Change %": "0.09%"
  },
  {
      "Date": "25-Jan-08",
      "Price": "7.431",
      "Open": "7.431",
      "High": "7.431",
      "Low": "7.431",
      "Change %": "0.70%"
  },
  {
      "Date": "24-Jan-08",
      "Price": "7.379",
      "Open": "7.379",
      "High": "7.379",
      "Low": "7.379",
      "Change %": "-0.08%"
  },
  {
      "Date": "23-Jan-08",
      "Price": "7.385",
      "Open": "7.385",
      "High": "7.385",
      "Low": "7.385",
      "Change %": "-1.10%"
  },
  {
      "Date": "22-Jan-08",
      "Price": "7.467",
      "Open": "7.467",
      "High": "7.467",
      "Low": "7.467",
      "Change %": "-0.97%"
  },
  {
      "Date": "21-Jan-08",
      "Price": "7.54",
      "Open": "7.54",
      "High": "7.54",
      "Low": "7.54",
      "Change %": "-0.15%"
  },
  {
      "Date": "18-Jan-08",
      "Price": "7.551",
      "Open": "7.551",
      "High": "7.551",
      "Low": "7.551",
      "Change %": "-0.04%"
  },
  {
      "Date": "17-Jan-08",
      "Price": "7.554",
      "Open": "7.554",
      "High": "7.554",
      "Low": "7.554",
      "Change %": "-0.12%"
  },
  {
      "Date": "16-Jan-08",
      "Price": "7.563",
      "Open": "7.563",
      "High": "7.563",
      "Low": "7.563",
      "Change %": "0.09%"
  },
  {
      "Date": "15-Jan-08",
      "Price": "7.556",
      "Open": "7.556",
      "High": "7.556",
      "Low": "7.556",
      "Change %": "0.16%"
  },
  {
      "Date": "14-Jan-08",
      "Price": "7.544",
      "Open": "7.544",
      "High": "7.544",
      "Low": "7.544",
      "Change %": "-0.25%"
  },
  {
      "Date": "12-Jan-08",
      "Price": "7.563",
      "Open": "7.563",
      "High": "7.563",
      "Low": "7.563",
      "Change %": "0.00%"
  },
  {
      "Date": "11-Jan-08",
      "Price": "7.563",
      "Open": "7.563",
      "High": "7.563",
      "Low": "7.563",
      "Change %": "-0.30%"
  },
  {
      "Date": "10-Jan-08",
      "Price": "7.586",
      "Open": "7.586",
      "High": "7.586",
      "Low": "7.586",
      "Change %": "-0.89%"
  },
  {
      "Date": "9-Jan-08",
      "Price": "7.654",
      "Open": "7.654",
      "High": "7.654",
      "Low": "7.654",
      "Change %": "-0.25%"
  },
  {
      "Date": "8-Jan-08",
      "Price": "7.673",
      "Open": "7.673",
      "High": "7.673",
      "Low": "7.673",
      "Change %": "0.17%"
  },
  {
      "Date": "7-Jan-08",
      "Price": "7.66",
      "Open": "7.66",
      "High": "7.66",
      "Low": "7.66",
      "Change %": "-0.61%"
  },
  {
      "Date": "5-Jan-08",
      "Price": "7.707",
      "Open": "7.707",
      "High": "7.707",
      "Low": "7.707",
      "Change %": "0.01%"
  },
  {
      "Date": "4-Jan-08",
      "Price": "7.706",
      "Open": "7.706",
      "High": "7.706",
      "Low": "7.706",
      "Change %": "-0.63%"
  },
  {
      "Date": "3-Jan-08",
      "Price": "7.755",
      "Open": "7.755",
      "High": "7.755",
      "Low": "7.755",
      "Change %": "0.12%"
  },
  {
      "Date": "2-Jan-08",
      "Price": "7.746",
      "Open": "7.746",
      "High": "7.746",
      "Low": "7.746",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Jan-08",
      "Price": "7.746",
      "Open": "7.746",
      "High": "7.746",
      "Low": "7.746",
      "Change %": "-0.58%"
  },
  {
      "Date": "31-Dec-07",
      "Price": "7.791",
      "Open": "7.791",
      "High": "7.791",
      "Low": "7.791",
      "Change %": "-0.24%"
  },
  {
      "Date": "29-Dec-07",
      "Price": "7.81",
      "Open": "7.81",
      "High": "7.81",
      "Low": "7.81",
      "Change %": "-0.01%"
  },
  {
      "Date": "28-Dec-07",
      "Price": "7.811",
      "Open": "7.811",
      "High": "7.811",
      "Low": "7.811",
      "Change %": "-0.57%"
  },
  {
      "Date": "27-Dec-07",
      "Price": "7.856",
      "Open": "7.856",
      "High": "7.856",
      "Low": "7.856",
      "Change %": "-0.18%"
  },
  {
      "Date": "26-Dec-07",
      "Price": "7.87",
      "Open": "7.87",
      "High": "7.87",
      "Low": "7.87",
      "Change %": "0.08%"
  },
  {
      "Date": "24-Dec-07",
      "Price": "7.864",
      "Open": "7.864",
      "High": "7.864",
      "Low": "7.864",
      "Change %": "-0.05%"
  },
  {
      "Date": "22-Dec-07",
      "Price": "7.868",
      "Open": "7.868",
      "High": "7.868",
      "Low": "7.868",
      "Change %": "-0.04%"
  },
  {
      "Date": "20-Dec-07",
      "Price": "7.871",
      "Open": "7.871",
      "High": "7.871",
      "Low": "7.871",
      "Change %": "-0.14%"
  },
  {
      "Date": "19-Dec-07",
      "Price": "7.882",
      "Open": "7.882",
      "High": "7.882",
      "Low": "7.882",
      "Change %": "-0.13%"
  },
  {
      "Date": "18-Dec-07",
      "Price": "7.892",
      "Open": "7.892",
      "High": "7.892",
      "Low": "7.892",
      "Change %": "-0.08%"
  },
  {
      "Date": "17-Dec-07",
      "Price": "7.898",
      "Open": "7.898",
      "High": "7.898",
      "Low": "7.898",
      "Change %": "0.01%"
  },
  {
      "Date": "15-Dec-07",
      "Price": "7.897",
      "Open": "7.897",
      "High": "7.897",
      "Low": "7.897",
      "Change %": "-0.04%"
  },
  {
      "Date": "14-Dec-07",
      "Price": "7.9",
      "Open": "7.9",
      "High": "7.9",
      "Low": "7.9",
      "Change %": "0.19%"
  },
  {
      "Date": "13-Dec-07",
      "Price": "7.885",
      "Open": "7.885",
      "High": "7.885",
      "Low": "7.885",
      "Change %": "0.09%"
  },
  {
      "Date": "12-Dec-07",
      "Price": "7.878",
      "Open": "7.878",
      "High": "7.878",
      "Low": "7.878",
      "Change %": "-0.06%"
  },
  {
      "Date": "11-Dec-07",
      "Price": "7.883",
      "Open": "7.883",
      "High": "7.883",
      "Low": "7.883",
      "Change %": "0.08%"
  },
  {
      "Date": "10-Dec-07",
      "Price": "7.877",
      "Open": "7.877",
      "High": "7.877",
      "Low": "7.877",
      "Change %": "0.10%"
  },
  {
      "Date": "8-Dec-07",
      "Price": "7.869",
      "Open": "7.869",
      "High": "7.869",
      "Low": "7.869",
      "Change %": "-0.06%"
  },
  {
      "Date": "7-Dec-07",
      "Price": "7.874",
      "Open": "7.874",
      "High": "7.874",
      "Low": "7.874",
      "Change %": "-0.05%"
  },
  {
      "Date": "6-Dec-07",
      "Price": "7.878",
      "Open": "7.878",
      "High": "7.878",
      "Low": "7.878",
      "Change %": "0.11%"
  },
  {
      "Date": "5-Dec-07",
      "Price": "7.869",
      "Open": "7.869",
      "High": "7.869",
      "Low": "7.869",
      "Change %": "-0.18%"
  },
  {
      "Date": "4-Dec-07",
      "Price": "7.883",
      "Open": "7.883",
      "High": "7.883",
      "Low": "7.883",
      "Change %": "0.03%"
  },
  {
      "Date": "3-Dec-07",
      "Price": "7.881",
      "Open": "7.881",
      "High": "7.881",
      "Low": "7.881",
      "Change %": "-0.34%"
  },
  {
      "Date": "1-Dec-07",
      "Price": "7.908",
      "Open": "7.908",
      "High": "7.908",
      "Low": "7.908",
      "Change %": "0.04%"
  },
  {
      "Date": "30-Nov-07",
      "Price": "7.905",
      "Open": "7.905",
      "High": "7.905",
      "Low": "7.905",
      "Change %": "-0.23%"
  },
  {
      "Date": "29-Nov-07",
      "Price": "7.923",
      "Open": "7.923",
      "High": "7.923",
      "Low": "7.923",
      "Change %": "0.01%"
  },
  {
      "Date": "28-Nov-07",
      "Price": "7.922",
      "Open": "7.922",
      "High": "7.922",
      "Low": "7.922",
      "Change %": "0.33%"
  },
  {
      "Date": "27-Nov-07",
      "Price": "7.896",
      "Open": "7.896",
      "High": "7.896",
      "Low": "7.896",
      "Change %": "0.08%"
  },
  {
      "Date": "26-Nov-07",
      "Price": "7.89",
      "Open": "7.89",
      "High": "7.89",
      "Low": "7.89",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Nov-07",
      "Price": "7.89",
      "Open": "7.89",
      "High": "7.89",
      "Low": "7.89",
      "Change %": "0.08%"
  },
  {
      "Date": "22-Nov-07",
      "Price": "7.884",
      "Open": "7.884",
      "High": "7.884",
      "Low": "7.884",
      "Change %": "-0.03%"
  },
  {
      "Date": "21-Nov-07",
      "Price": "7.886",
      "Open": "7.886",
      "High": "7.886",
      "Low": "7.886",
      "Change %": "0.14%"
  },
  {
      "Date": "20-Nov-07",
      "Price": "7.875",
      "Open": "7.875",
      "High": "7.875",
      "Low": "7.875",
      "Change %": "0.00%"
  },
  {
      "Date": "19-Nov-07",
      "Price": "7.875",
      "Open": "7.875",
      "High": "7.875",
      "Low": "7.875",
      "Change %": "-0.08%"
  },
  {
      "Date": "17-Nov-07",
      "Price": "7.881",
      "Open": "7.881",
      "High": "7.881",
      "Low": "7.881",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Nov-07",
      "Price": "7.881",
      "Open": "7.881",
      "High": "7.881",
      "Low": "7.881",
      "Change %": "-0.03%"
  },
  {
      "Date": "15-Nov-07",
      "Price": "7.883",
      "Open": "7.883",
      "High": "7.883",
      "Low": "7.883",
      "Change %": "0.10%"
  },
  {
      "Date": "14-Nov-07",
      "Price": "7.875",
      "Open": "7.875",
      "High": "7.875",
      "Low": "7.875",
      "Change %": "-0.15%"
  },
  {
      "Date": "13-Nov-07",
      "Price": "7.887",
      "Open": "7.887",
      "High": "7.887",
      "Low": "7.887",
      "Change %": "-0.45%"
  },
  {
      "Date": "12-Nov-07",
      "Price": "7.923",
      "Open": "7.923",
      "High": "7.923",
      "Low": "7.923",
      "Change %": "-0.15%"
  },
  {
      "Date": "10-Nov-07",
      "Price": "7.935",
      "Open": "7.935",
      "High": "7.935",
      "Low": "7.935",
      "Change %": "0.27%"
  },
  {
      "Date": "8-Nov-07",
      "Price": "7.914",
      "Open": "7.914",
      "High": "7.914",
      "Low": "7.914",
      "Change %": "0.23%"
  },
  {
      "Date": "7-Nov-07",
      "Price": "7.896",
      "Open": "7.896",
      "High": "7.896",
      "Low": "7.896",
      "Change %": "0.00%"
  },
  {
      "Date": "6-Nov-07",
      "Price": "7.896",
      "Open": "7.896",
      "High": "7.896",
      "Low": "7.896",
      "Change %": "0.36%"
  },
  {
      "Date": "5-Nov-07",
      "Price": "7.868",
      "Open": "7.868",
      "High": "7.868",
      "Low": "7.868",
      "Change %": "0.14%"
  },
  {
      "Date": "3-Nov-07",
      "Price": "7.857",
      "Open": "7.857",
      "High": "7.857",
      "Low": "7.857",
      "Change %": "-0.03%"
  },
  {
      "Date": "2-Nov-07",
      "Price": "7.859",
      "Open": "7.859",
      "High": "7.859",
      "Low": "7.859",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Nov-07",
      "Price": "7.859",
      "Open": "7.859",
      "High": "7.859",
      "Low": "7.859",
      "Change %": "0.26%"
  },
  {
      "Date": "31-Oct-07",
      "Price": "7.839",
      "Open": "7.839",
      "High": "7.839",
      "Low": "7.839",
      "Change %": "-0.27%"
  },
  {
      "Date": "30-Oct-07",
      "Price": "7.86",
      "Open": "7.86",
      "High": "7.86",
      "Low": "7.86",
      "Change %": "0.56%"
  },
  {
      "Date": "29-Oct-07",
      "Price": "7.816",
      "Open": "7.816",
      "High": "7.816",
      "Low": "7.816",
      "Change %": "-0.09%"
  },
  {
      "Date": "27-Oct-07",
      "Price": "7.823",
      "Open": "7.823",
      "High": "7.823",
      "Low": "7.823",
      "Change %": "0.01%"
  },
  {
      "Date": "26-Oct-07",
      "Price": "7.822",
      "Open": "7.822",
      "High": "7.822",
      "Low": "7.822",
      "Change %": "0.04%"
  },
  {
      "Date": "25-Oct-07",
      "Price": "7.819",
      "Open": "7.819",
      "High": "7.819",
      "Low": "7.819",
      "Change %": "-0.15%"
  },
  {
      "Date": "24-Oct-07",
      "Price": "7.831",
      "Open": "7.831",
      "High": "7.831",
      "Low": "7.831",
      "Change %": "0.10%"
  },
  {
      "Date": "23-Oct-07",
      "Price": "7.823",
      "Open": "7.823",
      "High": "7.823",
      "Low": "7.823",
      "Change %": "-0.51%"
  },
  {
      "Date": "22-Oct-07",
      "Price": "7.863",
      "Open": "7.863",
      "High": "7.863",
      "Low": "7.863",
      "Change %": "-0.44%"
  },
  {
      "Date": "20-Oct-07",
      "Price": "7.898",
      "Open": "7.898",
      "High": "7.898",
      "Low": "7.898",
      "Change %": "0.00%"
  },
  {
      "Date": "19-Oct-07",
      "Price": "7.898",
      "Open": "7.898",
      "High": "7.898",
      "Low": "7.898",
      "Change %": "-0.20%"
  },
  {
      "Date": "18-Oct-07",
      "Price": "7.914",
      "Open": "7.914",
      "High": "7.914",
      "Low": "7.914",
      "Change %": "-0.16%"
  },
  {
      "Date": "17-Oct-07",
      "Price": "7.927",
      "Open": "7.927",
      "High": "7.927",
      "Low": "7.927",
      "Change %": "0.19%"
  },
  {
      "Date": "16-Oct-07",
      "Price": "7.912",
      "Open": "7.912",
      "High": "7.912",
      "Low": "7.912",
      "Change %": "0.05%"
  },
  {
      "Date": "15-Oct-07",
      "Price": "7.908",
      "Open": "7.908",
      "High": "7.908",
      "Low": "7.908",
      "Change %": "0.08%"
  },
  {
      "Date": "13-Oct-07",
      "Price": "7.902",
      "Open": "7.902",
      "High": "7.902",
      "Low": "7.902",
      "Change %": "-0.03%"
  },
  {
      "Date": "12-Oct-07",
      "Price": "7.904",
      "Open": "7.904",
      "High": "7.904",
      "Low": "7.904",
      "Change %": "0.14%"
  },
  {
      "Date": "11-Oct-07",
      "Price": "7.893",
      "Open": "7.893",
      "High": "7.893",
      "Low": "7.893",
      "Change %": "-0.10%"
  },
  {
      "Date": "10-Oct-07",
      "Price": "7.901",
      "Open": "7.901",
      "High": "7.901",
      "Low": "7.901",
      "Change %": "-0.32%"
  },
  {
      "Date": "9-Oct-07",
      "Price": "7.926",
      "Open": "7.926",
      "High": "7.926",
      "Low": "7.926",
      "Change %": "-0.19%"
  },
  {
      "Date": "8-Oct-07",
      "Price": "7.941",
      "Open": "7.941",
      "High": "7.941",
      "Low": "7.941",
      "Change %": "0.35%"
  },
  {
      "Date": "6-Oct-07",
      "Price": "7.913",
      "Open": "7.913",
      "High": "7.913",
      "Low": "7.913",
      "Change %": "0.06%"
  },
  {
      "Date": "5-Oct-07",
      "Price": "7.908",
      "Open": "7.908",
      "High": "7.908",
      "Low": "7.908",
      "Change %": "0.19%"
  },
  {
      "Date": "4-Oct-07",
      "Price": "7.893",
      "Open": "7.893",
      "High": "7.893",
      "Low": "7.893",
      "Change %": "-0.06%"
  },
  {
      "Date": "3-Oct-07",
      "Price": "7.898",
      "Open": "7.898",
      "High": "7.898",
      "Low": "7.898",
      "Change %": "-0.27%"
  },
  {
      "Date": "1-Oct-07",
      "Price": "7.919",
      "Open": "7.919",
      "High": "7.919",
      "Low": "7.919",
      "Change %": "-0.08%"
  },
  {
      "Date": "28-Sep-07",
      "Price": "7.925",
      "Open": "7.925",
      "High": "7.925",
      "Low": "7.925",
      "Change %": "-0.04%"
  },
  {
      "Date": "27-Sep-07",
      "Price": "7.928",
      "Open": "7.928",
      "High": "7.928",
      "Low": "7.928",
      "Change %": "0.06%"
  },
  {
      "Date": "26-Sep-07",
      "Price": "7.923",
      "Open": "7.923",
      "High": "7.923",
      "Low": "7.923",
      "Change %": "-0.05%"
  },
  {
      "Date": "25-Sep-07",
      "Price": "7.927",
      "Open": "7.927",
      "High": "7.927",
      "Low": "7.927",
      "Change %": "0.51%"
  },
  {
      "Date": "24-Sep-07",
      "Price": "7.887",
      "Open": "7.887",
      "High": "7.887",
      "Low": "7.887",
      "Change %": "0.36%"
  },
  {
      "Date": "21-Sep-07",
      "Price": "7.859",
      "Open": "7.859",
      "High": "7.859",
      "Low": "7.859",
      "Change %": "0.19%"
  },
  {
      "Date": "20-Sep-07",
      "Price": "7.844",
      "Open": "7.844",
      "High": "7.844",
      "Low": "7.844",
      "Change %": "-0.23%"
  },
  {
      "Date": "19-Sep-07",
      "Price": "7.862",
      "Open": "7.862",
      "High": "7.862",
      "Low": "7.862",
      "Change %": "-0.30%"
  },
  {
      "Date": "18-Sep-07",
      "Price": "7.886",
      "Open": "7.886",
      "High": "7.886",
      "Low": "7.886",
      "Change %": "-0.08%"
  },
  {
      "Date": "17-Sep-07",
      "Price": "7.892",
      "Open": "7.892",
      "High": "7.892",
      "Low": "7.892",
      "Change %": "0.08%"
  },
  {
      "Date": "14-Sep-07",
      "Price": "7.886",
      "Open": "7.886",
      "High": "7.886",
      "Low": "7.886",
      "Change %": "-0.15%"
  },
  {
      "Date": "13-Sep-07",
      "Price": "7.898",
      "Open": "7.898",
      "High": "7.898",
      "Low": "7.898",
      "Change %": "0.03%"
  },
  {
      "Date": "12-Sep-07",
      "Price": "7.896",
      "Open": "7.896",
      "High": "7.896",
      "Low": "7.896",
      "Change %": "0.00%"
  },
  {
      "Date": "11-Sep-07",
      "Price": "7.896",
      "Open": "7.896",
      "High": "7.896",
      "Low": "7.896",
      "Change %": "0.41%"
  },
  {
      "Date": "10-Sep-07",
      "Price": "7.864",
      "Open": "7.864",
      "High": "7.864",
      "Low": "7.864",
      "Change %": "-0.41%"
  },
  {
      "Date": "7-Sep-07",
      "Price": "7.896",
      "Open": "7.896",
      "High": "7.896",
      "Low": "7.896",
      "Change %": "-0.30%"
  },
  {
      "Date": "6-Sep-07",
      "Price": "7.92",
      "Open": "7.92",
      "High": "7.92",
      "Low": "7.92",
      "Change %": "-0.10%"
  },
  {
      "Date": "5-Sep-07",
      "Price": "7.928",
      "Open": "7.928",
      "High": "7.928",
      "Low": "7.928",
      "Change %": "0.14%"
  },
  {
      "Date": "4-Sep-07",
      "Price": "7.917",
      "Open": "7.917",
      "High": "7.917",
      "Low": "7.917",
      "Change %": "0.19%"
  },
  {
      "Date": "3-Sep-07",
      "Price": "7.902",
      "Open": "7.902",
      "High": "7.902",
      "Low": "7.902",
      "Change %": "-0.35%"
  },
  {
      "Date": "31-Aug-07",
      "Price": "7.93",
      "Open": "7.93",
      "High": "7.93",
      "Low": "7.93",
      "Change %": "-0.50%"
  },
  {
      "Date": "30-Aug-07",
      "Price": "7.97",
      "Open": "7.97",
      "High": "7.97",
      "Low": "7.97",
      "Change %": "0.23%"
  },
  {
      "Date": "29-Aug-07",
      "Price": "7.952",
      "Open": "7.952",
      "High": "7.952",
      "Low": "7.952",
      "Change %": "0.16%"
  },
  {
      "Date": "28-Aug-07",
      "Price": "7.939",
      "Open": "7.939",
      "High": "7.939",
      "Low": "7.939",
      "Change %": "0.14%"
  },
  {
      "Date": "27-Aug-07",
      "Price": "7.928",
      "Open": "7.928",
      "High": "7.928",
      "Low": "7.928",
      "Change %": "-0.11%"
  },
  {
      "Date": "24-Aug-07",
      "Price": "7.937",
      "Open": "7.937",
      "High": "7.937",
      "Low": "7.937",
      "Change %": "0.08%"
  },
  {
      "Date": "23-Aug-07",
      "Price": "7.931",
      "Open": "7.931",
      "High": "7.931",
      "Low": "7.931",
      "Change %": "0.24%"
  },
  {
      "Date": "22-Aug-07",
      "Price": "7.912",
      "Open": "7.912",
      "High": "7.912",
      "Low": "7.912",
      "Change %": "-0.58%"
  },
  {
      "Date": "21-Aug-07",
      "Price": "7.958",
      "Open": "7.958",
      "High": "7.958",
      "Low": "7.958",
      "Change %": "-0.51%"
  },
  {
      "Date": "17-Aug-07",
      "Price": "7.999",
      "Open": "7.999",
      "High": "7.999",
      "Low": "7.999",
      "Change %": "-0.46%"
  },
  {
      "Date": "16-Aug-07",
      "Price": "8.036",
      "Open": "8.036",
      "High": "8.036",
      "Low": "8.036",
      "Change %": "0.60%"
  },
  {
      "Date": "14-Aug-07",
      "Price": "7.988",
      "Open": "7.988",
      "High": "7.988",
      "Low": "7.988",
      "Change %": "0.14%"
  },
  {
      "Date": "13-Aug-07",
      "Price": "7.977",
      "Open": "7.977",
      "High": "7.977",
      "Low": "7.977",
      "Change %": "-0.08%"
  },
  {
      "Date": "10-Aug-07",
      "Price": "7.983",
      "Open": "7.983",
      "High": "7.983",
      "Low": "7.983",
      "Change %": "-0.10%"
  },
  {
      "Date": "9-Aug-07",
      "Price": "7.991",
      "Open": "7.991",
      "High": "7.991",
      "Low": "7.991",
      "Change %": "1.08%"
  },
  {
      "Date": "8-Aug-07",
      "Price": "7.906",
      "Open": "7.906",
      "High": "7.906",
      "Low": "7.906",
      "Change %": "1.02%"
  },
  {
      "Date": "7-Aug-07",
      "Price": "7.826",
      "Open": "7.826",
      "High": "7.826",
      "Low": "7.826",
      "Change %": "-0.33%"
  },
  {
      "Date": "6-Aug-07",
      "Price": "7.852",
      "Open": "7.852",
      "High": "7.852",
      "Low": "7.852",
      "Change %": "-0.10%"
  },
  {
      "Date": "3-Aug-07",
      "Price": "7.86",
      "Open": "7.86",
      "High": "7.86",
      "Low": "7.86",
      "Change %": "-0.39%"
  },
  {
      "Date": "2-Aug-07",
      "Price": "7.891",
      "Open": "7.891",
      "High": "7.891",
      "Low": "7.891",
      "Change %": "-0.08%"
  },
  {
      "Date": "1-Aug-07",
      "Price": "7.897",
      "Open": "7.897",
      "High": "7.897",
      "Low": "7.897",
      "Change %": "0.66%"
  },
  {
      "Date": "31-Jul-07",
      "Price": "7.845",
      "Open": "7.845",
      "High": "7.845",
      "Low": "7.845",
      "Change %": "0.89%"
  },
  {
      "Date": "30-Jul-07",
      "Price": "7.776",
      "Open": "7.776",
      "High": "7.776",
      "Low": "7.776",
      "Change %": "-0.74%"
  },
  {
      "Date": "27-Jul-07",
      "Price": "7.834",
      "Open": "7.834",
      "High": "7.834",
      "Low": "7.834",
      "Change %": "0.38%"
  },
  {
      "Date": "26-Jul-07",
      "Price": "7.804",
      "Open": "7.804",
      "High": "7.804",
      "Low": "7.804",
      "Change %": "-0.27%"
  },
  {
      "Date": "25-Jul-07",
      "Price": "7.825",
      "Open": "7.825",
      "High": "7.825",
      "Low": "7.825",
      "Change %": "0.64%"
  },
  {
      "Date": "24-Jul-07",
      "Price": "7.775",
      "Open": "7.775",
      "High": "7.775",
      "Low": "7.775",
      "Change %": "0.36%"
  },
  {
      "Date": "23-Jul-07",
      "Price": "7.747",
      "Open": "7.747",
      "High": "7.747",
      "Low": "7.747",
      "Change %": "-0.78%"
  },
  {
      "Date": "20-Jul-07",
      "Price": "7.808",
      "Open": "7.808",
      "High": "7.808",
      "Low": "7.808",
      "Change %": "-0.29%"
  },
  {
      "Date": "19-Jul-07",
      "Price": "7.831",
      "Open": "7.831",
      "High": "7.831",
      "Low": "7.831",
      "Change %": "0.60%"
  },
  {
      "Date": "18-Jul-07",
      "Price": "7.784",
      "Open": "7.784",
      "High": "7.784",
      "Low": "7.784",
      "Change %": "-0.75%"
  },
  {
      "Date": "17-Jul-07",
      "Price": "7.843",
      "Open": "7.843",
      "High": "7.843",
      "Low": "7.843",
      "Change %": "-0.90%"
  },
  {
      "Date": "16-Jul-07",
      "Price": "7.914",
      "Open": "7.914",
      "High": "7.914",
      "Low": "7.914",
      "Change %": "-0.31%"
  },
  {
      "Date": "13-Jul-07",
      "Price": "7.939",
      "Open": "7.939",
      "High": "7.939",
      "Low": "7.939",
      "Change %": "0.29%"
  },
  {
      "Date": "12-Jul-07",
      "Price": "7.916",
      "Open": "7.916",
      "High": "7.916",
      "Low": "7.916",
      "Change %": "-0.11%"
  },
  {
      "Date": "11-Jul-07",
      "Price": "7.925",
      "Open": "7.925",
      "High": "7.925",
      "Low": "7.925",
      "Change %": "-0.18%"
  },
  {
      "Date": "10-Jul-07",
      "Price": "7.939",
      "Open": "7.939",
      "High": "7.939",
      "Low": "7.939",
      "Change %": "0.18%"
  },
  {
      "Date": "9-Jul-07",
      "Price": "7.925",
      "Open": "7.925",
      "High": "7.925",
      "Low": "7.925",
      "Change %": "-1.06%"
  },
  {
      "Date": "6-Jul-07",
      "Price": "8.01",
      "Open": "8.01",
      "High": "8.01",
      "Low": "8.01",
      "Change %": "0.04%"
  },
  {
      "Date": "5-Jul-07",
      "Price": "8.007",
      "Open": "8.007",
      "High": "8.007",
      "Low": "8.007",
      "Change %": "-0.26%"
  },
  {
      "Date": "4-Jul-07",
      "Price": "8.028",
      "Open": "8.028",
      "High": "8.028",
      "Low": "8.028",
      "Change %": "-0.85%"
  },
  {
      "Date": "3-Jul-07",
      "Price": "8.097",
      "Open": "8.097",
      "High": "8.097",
      "Low": "8.097",
      "Change %": "-0.17%"
  },
  {
      "Date": "2-Jul-07",
      "Price": "8.111",
      "Open": "8.111",
      "High": "8.111",
      "Low": "8.111",
      "Change %": "-0.93%"
  },
  {
      "Date": "29-Jun-07",
      "Price": "8.187",
      "Open": "8.187",
      "High": "8.187",
      "Low": "8.187",
      "Change %": "-0.09%"
  },
  {
      "Date": "28-Jun-07",
      "Price": "8.194",
      "Open": "8.194",
      "High": "8.194",
      "Low": "8.194",
      "Change %": "-0.53%"
  },
  {
      "Date": "27-Jun-07",
      "Price": "8.238",
      "Open": "8.238",
      "High": "8.238",
      "Low": "8.238",
      "Change %": "0.39%"
  },
  {
      "Date": "26-Jun-07",
      "Price": "8.206",
      "Open": "8.206",
      "High": "8.206",
      "Low": "8.206",
      "Change %": "0.28%"
  },
  {
      "Date": "25-Jun-07",
      "Price": "8.183",
      "Open": "8.183",
      "High": "8.183",
      "Low": "8.183",
      "Change %": "-0.56%"
  },
  {
      "Date": "22-Jun-07",
      "Price": "8.229",
      "Open": "8.229",
      "High": "8.229",
      "Low": "8.229",
      "Change %": "-0.07%"
  },
  {
      "Date": "21-Jun-07",
      "Price": "8.235",
      "Open": "8.235",
      "High": "8.235",
      "Low": "8.235",
      "Change %": "-0.17%"
  },
  {
      "Date": "20-Jun-07",
      "Price": "8.249",
      "Open": "8.249",
      "High": "8.249",
      "Low": "8.249",
      "Change %": "0.34%"
  },
  {
      "Date": "19-Jun-07",
      "Price": "8.221",
      "Open": "8.221",
      "High": "8.221",
      "Low": "8.221",
      "Change %": "0.23%"
  },
  {
      "Date": "18-Jun-07",
      "Price": "8.202",
      "Open": "8.202",
      "High": "8.202",
      "Low": "8.202",
      "Change %": "-1.04%"
  },
  {
      "Date": "15-Jun-07",
      "Price": "8.288",
      "Open": "8.288",
      "High": "8.288",
      "Low": "8.288",
      "Change %": "-0.52%"
  },
  {
      "Date": "14-Jun-07",
      "Price": "8.331",
      "Open": "8.331",
      "High": "8.331",
      "Low": "8.331",
      "Change %": "-0.95%"
  },
  {
      "Date": "13-Jun-07",
      "Price": "8.411",
      "Open": "8.411",
      "High": "8.411",
      "Low": "8.411",
      "Change %": "0.38%"
  },
  {
      "Date": "12-Jun-07",
      "Price": "8.379",
      "Open": "8.379",
      "High": "8.379",
      "Low": "8.379",
      "Change %": "0.41%"
  },
  {
      "Date": "11-Jun-07",
      "Price": "8.345",
      "Open": "8.345",
      "High": "8.345",
      "Low": "8.345",
      "Change %": "2.54%"
  },
  {
      "Date": "8-Jun-07",
      "Price": "8.138",
      "Open": "8.138",
      "High": "8.138",
      "Low": "8.138",
      "Change %": "0.43%"
  },
  {
      "Date": "7-Jun-07",
      "Price": "8.103",
      "Open": "8.103",
      "High": "8.103",
      "Low": "8.103",
      "Change %": "-0.41%"
  },
  {
      "Date": "6-Jun-07",
      "Price": "8.136",
      "Open": "8.136",
      "High": "8.136",
      "Low": "8.136",
      "Change %": "0.54%"
  },
  {
      "Date": "5-Jun-07",
      "Price": "8.092",
      "Open": "8.092",
      "High": "8.092",
      "Low": "8.092",
      "Change %": "0.07%"
  },
  {
      "Date": "4-Jun-07",
      "Price": "8.086",
      "Open": "8.086",
      "High": "8.086",
      "Low": "8.086",
      "Change %": "0.11%"
  },
  {
      "Date": "1-Jun-07",
      "Price": "8.077",
      "Open": "8.077",
      "High": "8.077",
      "Low": "8.077",
      "Change %": "-0.04%"
  },
  {
      "Date": "31-May-07",
      "Price": "8.08",
      "Open": "8.08",
      "High": "8.08",
      "Low": "8.08",
      "Change %": "-0.26%"
  },
  {
      "Date": "30-May-07",
      "Price": "8.101",
      "Open": "8.101",
      "High": "8.101",
      "Low": "8.101",
      "Change %": "-0.04%"
  },
  {
      "Date": "29-May-07",
      "Price": "8.104",
      "Open": "8.104",
      "High": "8.104",
      "Low": "8.104",
      "Change %": "0.41%"
  },
  {
      "Date": "28-May-07",
      "Price": "8.071",
      "Open": "8.071",
      "High": "8.071",
      "Low": "8.071",
      "Change %": "-0.80%"
  },
  {
      "Date": "25-May-07",
      "Price": "8.136",
      "Open": "8.136",
      "High": "8.136",
      "Low": "8.136",
      "Change %": "-0.25%"
  },
  {
      "Date": "24-May-07",
      "Price": "8.156",
      "Open": "8.156",
      "High": "8.156",
      "Low": "8.156",
      "Change %": "0.16%"
  },
  {
      "Date": "23-May-07",
      "Price": "8.143",
      "Open": "8.143",
      "High": "8.143",
      "Low": "8.143",
      "Change %": "0.37%"
  },
  {
      "Date": "22-May-07",
      "Price": "8.113",
      "Open": "8.113",
      "High": "8.113",
      "Low": "8.113",
      "Change %": "0.28%"
  },
  {
      "Date": "21-May-07",
      "Price": "8.09",
      "Open": "8.09",
      "High": "8.09",
      "Low": "8.09",
      "Change %": "-0.78%"
  },
  {
      "Date": "18-May-07",
      "Price": "8.154",
      "Open": "8.154",
      "High": "8.154",
      "Low": "8.154",
      "Change %": "0.38%"
  },
  {
      "Date": "17-May-07",
      "Price": "8.123",
      "Open": "8.123",
      "High": "8.123",
      "Low": "8.123",
      "Change %": "0.23%"
  },
  {
      "Date": "16-May-07",
      "Price": "8.104",
      "Open": "8.104",
      "High": "8.104",
      "Low": "8.104",
      "Change %": "-0.22%"
  },
  {
      "Date": "15-May-07",
      "Price": "8.122",
      "Open": "8.122",
      "High": "8.122",
      "Low": "8.122",
      "Change %": "-0.36%"
  },
  {
      "Date": "14-May-07",
      "Price": "8.151",
      "Open": "8.151",
      "High": "8.151",
      "Low": "8.151",
      "Change %": "-0.20%"
  },
  {
      "Date": "11-May-07",
      "Price": "8.167",
      "Open": "8.167",
      "High": "8.167",
      "Low": "8.167",
      "Change %": "0.55%"
  },
  {
      "Date": "10-May-07",
      "Price": "8.122",
      "Open": "8.122",
      "High": "8.122",
      "Low": "8.122",
      "Change %": "0.49%"
  },
  {
      "Date": "9-May-07",
      "Price": "8.082",
      "Open": "8.082",
      "High": "8.082",
      "Low": "8.082",
      "Change %": "-0.39%"
  },
  {
      "Date": "8-May-07",
      "Price": "8.114",
      "Open": "8.114",
      "High": "8.114",
      "Low": "8.114",
      "Change %": "-0.55%"
  },
  {
      "Date": "7-May-07",
      "Price": "8.159",
      "Open": "8.159",
      "High": "8.159",
      "Low": "8.159",
      "Change %": "0.00%"
  },
  {
      "Date": "4-May-07",
      "Price": "8.159",
      "Open": "8.159",
      "High": "8.159",
      "Low": "8.159",
      "Change %": "0.11%"
  },
  {
      "Date": "3-May-07",
      "Price": "8.15",
      "Open": "8.15",
      "High": "8.15",
      "Low": "8.15",
      "Change %": "-0.28%"
  },
  {
      "Date": "30-Apr-07",
      "Price": "8.173",
      "Open": "8.173",
      "High": "8.173",
      "Low": "8.173",
      "Change %": "0.38%"
  },
  {
      "Date": "27-Apr-07",
      "Price": "8.142",
      "Open": "8.142",
      "High": "8.142",
      "Low": "8.142",
      "Change %": "1.12%"
  },
  {
      "Date": "26-Apr-07",
      "Price": "8.052",
      "Open": "8.052",
      "High": "8.052",
      "Low": "8.052",
      "Change %": "0.90%"
  },
  {
      "Date": "25-Apr-07",
      "Price": "7.98",
      "Open": "7.98",
      "High": "7.98",
      "Low": "7.98",
      "Change %": "-0.29%"
  },
  {
      "Date": "24-Apr-07",
      "Price": "8.003",
      "Open": "8.003",
      "High": "8.003",
      "Low": "8.003",
      "Change %": "-1.20%"
  },
  {
      "Date": "23-Apr-07",
      "Price": "8.1",
      "Open": "8.1",
      "High": "8.1",
      "Low": "8.1",
      "Change %": "0.41%"
  },
  {
      "Date": "20-Apr-07",
      "Price": "8.067",
      "Open": "8.067",
      "High": "8.067",
      "Low": "8.067",
      "Change %": "-0.36%"
  },
  {
      "Date": "19-Apr-07",
      "Price": "8.096",
      "Open": "8.096",
      "High": "8.096",
      "Low": "8.096",
      "Change %": "0.26%"
  },
  {
      "Date": "18-Apr-07",
      "Price": "8.075",
      "Open": "8.075",
      "High": "8.075",
      "Low": "8.075",
      "Change %": "-0.41%"
  },
  {
      "Date": "17-Apr-07",
      "Price": "8.108",
      "Open": "8.108",
      "High": "8.108",
      "Low": "8.108",
      "Change %": "0.04%"
  },
  {
      "Date": "16-Apr-07",
      "Price": "8.105",
      "Open": "8.105",
      "High": "8.105",
      "Low": "8.105",
      "Change %": "0.00%"
  },
  {
      "Date": "13-Apr-07",
      "Price": "8.105",
      "Open": "8.105",
      "High": "8.105",
      "Low": "8.105",
      "Change %": "0.33%"
  },
  {
      "Date": "12-Apr-07",
      "Price": "8.078",
      "Open": "8.078",
      "High": "8.078",
      "Low": "8.078",
      "Change %": "0.39%"
  },
  {
      "Date": "11-Apr-07",
      "Price": "8.047",
      "Open": "8.047",
      "High": "8.047",
      "Low": "8.047",
      "Change %": "0.29%"
  },
  {
      "Date": "10-Apr-07",
      "Price": "8.024",
      "Open": "8.024",
      "High": "8.024",
      "Low": "8.024",
      "Change %": "-1.18%"
  },
  {
      "Date": "9-Apr-07",
      "Price": "8.12",
      "Open": "8.12",
      "High": "8.12",
      "Low": "8.12",
      "Change %": "-0.77%"
  },
  {
      "Date": "5-Apr-07",
      "Price": "8.183",
      "Open": "8.183",
      "High": "8.183",
      "Low": "8.183",
      "Change %": "0.28%"
  },
  {
      "Date": "4-Apr-07",
      "Price": "8.16",
      "Open": "8.16",
      "High": "8.16",
      "Low": "8.16",
      "Change %": "-0.28%"
  },
  {
      "Date": "3-Apr-07",
      "Price": "8.183",
      "Open": "8.183",
      "High": "8.183",
      "Low": "8.183",
      "Change %": "2.01%"
  },
  {
      "Date": "30-Mar-07",
      "Price": "8.022",
      "Open": "8.022",
      "High": "8.022",
      "Low": "8.022",
      "Change %": "0.65%"
  },
  {
      "Date": "29-Mar-07",
      "Price": "7.97",
      "Open": "7.97",
      "High": "7.97",
      "Low": "7.97",
      "Change %": "-0.15%"
  },
  {
      "Date": "28-Mar-07",
      "Price": "7.982",
      "Open": "7.982",
      "High": "7.982",
      "Low": "7.982",
      "Change %": "-0.18%"
  },
  {
      "Date": "26-Mar-07",
      "Price": "7.996",
      "Open": "7.996",
      "High": "7.996",
      "Low": "7.996",
      "Change %": "0.57%"
  },
  {
      "Date": "23-Mar-07",
      "Price": "7.951",
      "Open": "7.951",
      "High": "7.951",
      "Low": "7.951",
      "Change %": "0.04%"
  },
  {
      "Date": "22-Mar-07",
      "Price": "7.948",
      "Open": "7.948",
      "High": "7.948",
      "Low": "7.948",
      "Change %": "-1.29%"
  },
  {
      "Date": "21-Mar-07",
      "Price": "8.052",
      "Open": "8.052",
      "High": "8.052",
      "Low": "8.052",
      "Change %": "-0.19%"
  },
  {
      "Date": "20-Mar-07",
      "Price": "8.067",
      "Open": "8.067",
      "High": "8.067",
      "Low": "8.067",
      "Change %": "0.55%"
  },
  {
      "Date": "16-Mar-07",
      "Price": "8.023",
      "Open": "8.023",
      "High": "8.023",
      "Low": "8.023",
      "Change %": "0.38%"
  },
  {
      "Date": "15-Mar-07",
      "Price": "7.993",
      "Open": "7.993",
      "High": "7.993",
      "Low": "7.993",
      "Change %": "0.30%"
  },
  {
      "Date": "14-Mar-07",
      "Price": "7.969",
      "Open": "7.969",
      "High": "7.969",
      "Low": "7.969",
      "Change %": "-0.03%"
  },
  {
      "Date": "13-Mar-07",
      "Price": "7.971",
      "Open": "7.971",
      "High": "7.971",
      "Low": "7.971",
      "Change %": "0.28%"
  },
  {
      "Date": "12-Mar-07",
      "Price": "7.949",
      "Open": "7.949",
      "High": "7.949",
      "Low": "7.949",
      "Change %": "-0.65%"
  },
  {
      "Date": "9-Mar-07",
      "Price": "8.001",
      "Open": "8.001",
      "High": "8.001",
      "Low": "8.001",
      "Change %": "-0.04%"
  },
  {
      "Date": "8-Mar-07",
      "Price": "8.004",
      "Open": "8.004",
      "High": "8.004",
      "Low": "8.004",
      "Change %": "0.64%"
  },
  {
      "Date": "7-Mar-07",
      "Price": "7.953",
      "Open": "7.953",
      "High": "7.953",
      "Low": "7.953",
      "Change %": "-0.04%"
  },
  {
      "Date": "6-Mar-07",
      "Price": "7.956",
      "Open": "7.956",
      "High": "7.956",
      "Low": "7.956",
      "Change %": "-0.29%"
  },
  {
      "Date": "5-Mar-07",
      "Price": "7.979",
      "Open": "7.979",
      "High": "7.979",
      "Low": "7.979",
      "Change %": "0.38%"
  },
  {
      "Date": "2-Mar-07",
      "Price": "7.949",
      "Open": "7.949",
      "High": "7.949",
      "Low": "7.949",
      "Change %": "0.18%"
  },
  {
      "Date": "1-Mar-07",
      "Price": "7.935",
      "Open": "7.935",
      "High": "7.935",
      "Low": "7.935",
      "Change %": "-0.81%"
  },
  {
      "Date": "28-Feb-07",
      "Price": "8",
      "Open": "8",
      "High": "8",
      "Low": "8",
      "Change %": "1.29%"
  },
  {
      "Date": "27-Feb-07",
      "Price": "7.898",
      "Open": "7.898",
      "High": "7.898",
      "Low": "7.898",
      "Change %": "0.08%"
  },
  {
      "Date": "26-Feb-07",
      "Price": "7.892",
      "Open": "7.892",
      "High": "7.892",
      "Low": "7.892",
      "Change %": "-0.54%"
  },
  {
      "Date": "23-Feb-07",
      "Price": "7.935",
      "Open": "7.935",
      "High": "7.935",
      "Low": "7.935",
      "Change %": "-0.28%"
  },
  {
      "Date": "22-Feb-07",
      "Price": "7.957",
      "Open": "7.957",
      "High": "7.957",
      "Low": "7.957",
      "Change %": "0.40%"
  },
  {
      "Date": "21-Feb-07",
      "Price": "7.925",
      "Open": "7.925",
      "High": "7.925",
      "Low": "7.925",
      "Change %": "-0.97%"
  },
  {
      "Date": "20-Feb-07",
      "Price": "8.003",
      "Open": "8.003",
      "High": "8.003",
      "Low": "8.003",
      "Change %": "-0.16%"
  },
  {
      "Date": "19-Feb-07",
      "Price": "8.016",
      "Open": "8.016",
      "High": "8.016",
      "Low": "8.016",
      "Change %": "-0.71%"
  },
  {
      "Date": "15-Feb-07",
      "Price": "8.073",
      "Open": "8.073",
      "High": "8.073",
      "Low": "8.073",
      "Change %": "-0.12%"
  },
  {
      "Date": "14-Feb-07",
      "Price": "8.083",
      "Open": "8.083",
      "High": "8.083",
      "Low": "8.083",
      "Change %": "2.05%"
  },
  {
      "Date": "13-Feb-07",
      "Price": "7.921",
      "Open": "7.921",
      "High": "7.921",
      "Low": "7.921",
      "Change %": "1.21%"
  },
  {
      "Date": "12-Feb-07",
      "Price": "7.826",
      "Open": "7.826",
      "High": "7.826",
      "Low": "7.826",
      "Change %": "0.23%"
  },
  {
      "Date": "9-Feb-07",
      "Price": "7.808",
      "Open": "7.808",
      "High": "7.808",
      "Low": "7.808",
      "Change %": "0.22%"
  },
  {
      "Date": "8-Feb-07",
      "Price": "7.791",
      "Open": "7.791",
      "High": "7.791",
      "Low": "7.791",
      "Change %": "0.54%"
  },
  {
      "Date": "7-Feb-07",
      "Price": "7.749",
      "Open": "7.749",
      "High": "7.749",
      "Low": "7.749",
      "Change %": "-0.04%"
  },
  {
      "Date": "6-Feb-07",
      "Price": "7.752",
      "Open": "7.752",
      "High": "7.752",
      "Low": "7.752",
      "Change %": "0.47%"
  },
  {
      "Date": "5-Feb-07",
      "Price": "7.716",
      "Open": "7.716",
      "High": "7.716",
      "Low": "7.716",
      "Change %": "0.22%"
  },
  {
      "Date": "2-Feb-07",
      "Price": "7.699",
      "Open": "7.699",
      "High": "7.699",
      "Low": "7.699",
      "Change %": "-0.50%"
  },
  {
      "Date": "31-Jan-07",
      "Price": "7.738",
      "Open": "7.738",
      "High": "7.738",
      "Low": "7.738",
      "Change %": "-2.05%"
  },
  {
      "Date": "29-Jan-07",
      "Price": "7.9",
      "Open": "7.9",
      "High": "7.9",
      "Low": "7.9",
      "Change %": "0.22%"
  },
  {
      "Date": "25-Jan-07",
      "Price": "7.883",
      "Open": "7.883",
      "High": "7.883",
      "Low": "7.883",
      "Change %": "-0.16%"
  },
  {
      "Date": "24-Jan-07",
      "Price": "7.896",
      "Open": "7.896",
      "High": "7.896",
      "Low": "7.896",
      "Change %": "0.29%"
  },
  {
      "Date": "23-Jan-07",
      "Price": "7.873",
      "Open": "7.873",
      "High": "7.873",
      "Low": "7.873",
      "Change %": "-0.44%"
  },
  {
      "Date": "22-Jan-07",
      "Price": "7.908",
      "Open": "7.908",
      "High": "7.908",
      "Low": "7.908",
      "Change %": "1.29%"
  },
  {
      "Date": "19-Jan-07",
      "Price": "7.807",
      "Open": "7.807",
      "High": "7.807",
      "Low": "7.807",
      "Change %": "-0.04%"
  },
  {
      "Date": "18-Jan-07",
      "Price": "7.81",
      "Open": "7.81",
      "High": "7.81",
      "Low": "7.81",
      "Change %": "0.00%"
  },
  {
      "Date": "17-Jan-07",
      "Price": "7.81",
      "Open": "7.81",
      "High": "7.81",
      "Low": "7.81",
      "Change %": "0.57%"
  },
  {
      "Date": "16-Jan-07",
      "Price": "7.766",
      "Open": "7.766",
      "High": "7.766",
      "Low": "7.766",
      "Change %": "-0.33%"
  },
  {
      "Date": "15-Jan-07",
      "Price": "7.792",
      "Open": "7.792",
      "High": "7.792",
      "Low": "7.792",
      "Change %": "-1.70%"
  },
  {
      "Date": "12-Jan-07",
      "Price": "7.927",
      "Open": "7.927",
      "High": "7.927",
      "Low": "7.927",
      "Change %": "5.17%"
  },
  {
      "Date": "11-Jan-07",
      "Price": "7.537",
      "Open": "7.537",
      "High": "7.537",
      "Low": "7.537",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Jan-07",
      "Price": "7.537",
      "Open": "7.537",
      "High": "7.537",
      "Low": "7.537",
      "Change %": "0.36%"
  },
  {
      "Date": "9-Jan-07",
      "Price": "7.51",
      "Open": "7.51",
      "High": "7.51",
      "Low": "7.51",
      "Change %": "0.27%"
  },
  {
      "Date": "8-Jan-07",
      "Price": "7.49",
      "Open": "7.49",
      "High": "7.49",
      "Low": "7.49",
      "Change %": "-0.86%"
  },
  {
      "Date": "5-Jan-07",
      "Price": "7.555",
      "Open": "7.555",
      "High": "7.555",
      "Low": "7.555",
      "Change %": "-0.38%"
  },
  {
      "Date": "4-Jan-07",
      "Price": "7.584",
      "Open": "7.584",
      "High": "7.584",
      "Low": "7.584",
      "Change %": "0.58%"
  },
  {
      "Date": "3-Jan-07",
      "Price": "7.54",
      "Open": "7.54",
      "High": "7.54",
      "Low": "7.54",
      "Change %": "0.05%"
  },
  {
      "Date": "2-Jan-07",
      "Price": "7.536",
      "Open": "7.536",
      "High": "7.536",
      "Low": "7.536",
      "Change %": "-1.09%"
  },
  {
      "Date": "29-Dec-06",
      "Price": "7.619",
      "Open": "7.619",
      "High": "7.619",
      "Low": "7.619",
      "Change %": "-0.14%"
  },
  {
      "Date": "28-Dec-06",
      "Price": "7.63",
      "Open": "7.63",
      "High": "7.63",
      "Low": "7.63",
      "Change %": "0.12%"
  },
  {
      "Date": "27-Dec-06",
      "Price": "7.621",
      "Open": "7.621",
      "High": "7.621",
      "Low": "7.621",
      "Change %": "-0.05%"
  },
  {
      "Date": "26-Dec-06",
      "Price": "7.625",
      "Open": "7.625",
      "High": "7.625",
      "Low": "7.625",
      "Change %": "0.32%"
  },
  {
      "Date": "22-Dec-06",
      "Price": "7.601",
      "Open": "7.601",
      "High": "7.601",
      "Low": "7.601",
      "Change %": "-0.03%"
  },
  {
      "Date": "21-Dec-06",
      "Price": "7.603",
      "Open": "7.603",
      "High": "7.603",
      "Low": "7.603",
      "Change %": "0.22%"
  },
  {
      "Date": "20-Dec-06",
      "Price": "7.586",
      "Open": "7.586",
      "High": "7.586",
      "Low": "7.586",
      "Change %": "0.04%"
  },
  {
      "Date": "19-Dec-06",
      "Price": "7.583",
      "Open": "7.583",
      "High": "7.583",
      "Low": "7.583",
      "Change %": "-0.39%"
  },
  {
      "Date": "18-Dec-06",
      "Price": "7.613",
      "Open": "7.613",
      "High": "7.613",
      "Low": "7.613",
      "Change %": "-0.37%"
  },
  {
      "Date": "15-Dec-06",
      "Price": "7.641",
      "Open": "7.641",
      "High": "7.641",
      "Low": "7.641",
      "Change %": "-0.55%"
  },
  {
      "Date": "14-Dec-06",
      "Price": "7.683",
      "Open": "7.683",
      "High": "7.683",
      "Low": "7.683",
      "Change %": "0.80%"
  },
  {
      "Date": "13-Dec-06",
      "Price": "7.622",
      "Open": "7.622",
      "High": "7.622",
      "Low": "7.622",
      "Change %": "-0.29%"
  },
  {
      "Date": "12-Dec-06",
      "Price": "7.644",
      "Open": "7.644",
      "High": "7.644",
      "Low": "7.644",
      "Change %": "1.41%"
  },
  {
      "Date": "11-Dec-06",
      "Price": "7.538",
      "Open": "7.538",
      "High": "7.538",
      "Low": "7.538",
      "Change %": "2.00%"
  },
  {
      "Date": "8-Dec-06",
      "Price": "7.39",
      "Open": "7.39",
      "High": "7.39",
      "Low": "7.39",
      "Change %": "-0.04%"
  },
  {
      "Date": "7-Dec-06",
      "Price": "7.393",
      "Open": "7.393",
      "High": "7.393",
      "Low": "7.393",
      "Change %": "0.04%"
  },
  {
      "Date": "6-Dec-06",
      "Price": "7.39",
      "Open": "7.39",
      "High": "7.39",
      "Low": "7.39",
      "Change %": "0.15%"
  },
  {
      "Date": "5-Dec-06",
      "Price": "7.379",
      "Open": "7.379",
      "High": "7.379",
      "Low": "7.379",
      "Change %": "-0.03%"
  },
  {
      "Date": "4-Dec-06",
      "Price": "7.381",
      "Open": "7.381",
      "High": "7.381",
      "Low": "7.381",
      "Change %": "-0.61%"
  },
  {
      "Date": "1-Dec-06",
      "Price": "7.426",
      "Open": "7.426",
      "High": "7.426",
      "Low": "7.426",
      "Change %": "0.03%"
  },
  {
      "Date": "30-Nov-06",
      "Price": "7.424",
      "Open": "7.424",
      "High": "7.424",
      "Low": "7.424",
      "Change %": "0.23%"
  },
  {
      "Date": "29-Nov-06",
      "Price": "7.407",
      "Open": "7.407",
      "High": "7.407",
      "Low": "7.407",
      "Change %": "0.41%"
  },
  {
      "Date": "28-Nov-06",
      "Price": "7.377",
      "Open": "7.377",
      "High": "7.377",
      "Low": "7.377",
      "Change %": "-0.08%"
  },
  {
      "Date": "27-Nov-06",
      "Price": "7.383",
      "Open": "7.383",
      "High": "7.383",
      "Low": "7.383",
      "Change %": "-0.65%"
  },
  {
      "Date": "24-Nov-06",
      "Price": "7.431",
      "Open": "7.431",
      "High": "7.431",
      "Low": "7.431",
      "Change %": "-0.34%"
  },
  {
      "Date": "23-Nov-06",
      "Price": "7.456",
      "Open": "7.456",
      "High": "7.456",
      "Low": "7.456",
      "Change %": "-0.15%"
  },
  {
      "Date": "22-Nov-06",
      "Price": "7.467",
      "Open": "7.467",
      "High": "7.467",
      "Low": "7.467",
      "Change %": "-0.04%"
  },
  {
      "Date": "21-Nov-06",
      "Price": "7.47",
      "Open": "7.47",
      "High": "7.47",
      "Low": "7.47",
      "Change %": "-0.17%"
  },
  {
      "Date": "20-Nov-06",
      "Price": "7.483",
      "Open": "7.483",
      "High": "7.483",
      "Low": "7.483",
      "Change %": "-0.62%"
  },
  {
      "Date": "17-Nov-06",
      "Price": "7.53",
      "Open": "7.53",
      "High": "7.53",
      "Low": "7.53",
      "Change %": "-0.12%"
  },
  {
      "Date": "16-Nov-06",
      "Price": "7.539",
      "Open": "7.539",
      "High": "7.539",
      "Low": "7.539",
      "Change %": "0.07%"
  },
  {
      "Date": "15-Nov-06",
      "Price": "7.534",
      "Open": "7.534",
      "High": "7.534",
      "Low": "7.534",
      "Change %": "-0.34%"
  },
  {
      "Date": "14-Nov-06",
      "Price": "7.56",
      "Open": "7.56",
      "High": "7.56",
      "Low": "7.56",
      "Change %": "-0.32%"
  },
  {
      "Date": "13-Nov-06",
      "Price": "7.584",
      "Open": "7.584",
      "High": "7.584",
      "Low": "7.584",
      "Change %": "-0.26%"
  },
  {
      "Date": "10-Nov-06",
      "Price": "7.604",
      "Open": "7.604",
      "High": "7.604",
      "Low": "7.604",
      "Change %": "-0.09%"
  },
  {
      "Date": "9-Nov-06",
      "Price": "7.611",
      "Open": "7.611",
      "High": "7.611",
      "Low": "7.611",
      "Change %": "0.29%"
  },
  {
      "Date": "8-Nov-06",
      "Price": "7.589",
      "Open": "7.589",
      "High": "7.589",
      "Low": "7.589",
      "Change %": "-0.37%"
  },
  {
      "Date": "7-Nov-06",
      "Price": "7.617",
      "Open": "7.617",
      "High": "7.617",
      "Low": "7.617",
      "Change %": "-0.57%"
  },
  {
      "Date": "6-Nov-06",
      "Price": "7.661",
      "Open": "7.661",
      "High": "7.661",
      "Low": "7.661",
      "Change %": "0.25%"
  },
  {
      "Date": "3-Nov-06",
      "Price": "7.642",
      "Open": "7.642",
      "High": "7.642",
      "Low": "7.642",
      "Change %": "0.55%"
  },
  {
      "Date": "2-Nov-06",
      "Price": "7.6",
      "Open": "7.6",
      "High": "7.6",
      "Low": "7.6",
      "Change %": "-0.17%"
  },
  {
      "Date": "1-Nov-06",
      "Price": "7.613",
      "Open": "7.613",
      "High": "7.613",
      "Low": "7.613",
      "Change %": "-0.16%"
  },
  {
      "Date": "31-Oct-06",
      "Price": "7.625",
      "Open": "7.625",
      "High": "7.625",
      "Low": "7.625",
      "Change %": "0.13%"
  },
  {
      "Date": "30-Oct-06",
      "Price": "7.615",
      "Open": "7.615",
      "High": "7.615",
      "Low": "7.615",
      "Change %": "0.11%"
  },
  {
      "Date": "27-Oct-06",
      "Price": "7.607",
      "Open": "7.607",
      "High": "7.607",
      "Low": "7.607",
      "Change %": "-0.38%"
  },
  {
      "Date": "26-Oct-06",
      "Price": "7.636",
      "Open": "7.636",
      "High": "7.636",
      "Low": "7.636",
      "Change %": "-0.30%"
  },
  {
      "Date": "23-Oct-06",
      "Price": "7.659",
      "Open": "7.659",
      "High": "7.659",
      "Low": "7.659",
      "Change %": "0.07%"
  },
  {
      "Date": "20-Oct-06",
      "Price": "7.654",
      "Open": "7.654",
      "High": "7.654",
      "Low": "7.654",
      "Change %": "-0.27%"
  },
  {
      "Date": "19-Oct-06",
      "Price": "7.675",
      "Open": "7.675",
      "High": "7.675",
      "Low": "7.675",
      "Change %": "-0.12%"
  },
  {
      "Date": "18-Oct-06",
      "Price": "7.684",
      "Open": "7.684",
      "High": "7.684",
      "Low": "7.684",
      "Change %": "0.01%"
  },
  {
      "Date": "17-Oct-06",
      "Price": "7.683",
      "Open": "7.683",
      "High": "7.683",
      "Low": "7.683",
      "Change %": "0.08%"
  },
  {
      "Date": "16-Oct-06",
      "Price": "7.677",
      "Open": "7.677",
      "High": "7.677",
      "Low": "7.677",
      "Change %": "0.79%"
  },
  {
      "Date": "13-Oct-06",
      "Price": "7.617",
      "Open": "7.617",
      "High": "7.617",
      "Low": "7.617",
      "Change %": "0.03%"
  },
  {
      "Date": "12-Oct-06",
      "Price": "7.615",
      "Open": "7.615",
      "High": "7.615",
      "Low": "7.615",
      "Change %": "-0.30%"
  },
  {
      "Date": "11-Oct-06",
      "Price": "7.638",
      "Open": "7.638",
      "High": "7.638",
      "Low": "7.638",
      "Change %": "0.34%"
  },
  {
      "Date": "10-Oct-06",
      "Price": "7.612",
      "Open": "7.612",
      "High": "7.612",
      "Low": "7.612",
      "Change %": "-0.39%"
  },
  {
      "Date": "9-Oct-06",
      "Price": "7.642",
      "Open": "7.642",
      "High": "7.642",
      "Low": "7.642",
      "Change %": "0.61%"
  },
  {
      "Date": "6-Oct-06",
      "Price": "7.596",
      "Open": "7.596",
      "High": "7.596",
      "Low": "7.596",
      "Change %": "0.08%"
  },
  {
      "Date": "5-Oct-06",
      "Price": "7.59",
      "Open": "7.59",
      "High": "7.59",
      "Low": "7.59",
      "Change %": "0.04%"
  },
  {
      "Date": "4-Oct-06",
      "Price": "7.587",
      "Open": "7.587",
      "High": "7.587",
      "Low": "7.587",
      "Change %": "-0.34%"
  },
  {
      "Date": "3-Oct-06",
      "Price": "7.613",
      "Open": "7.613",
      "High": "7.613",
      "Low": "7.613",
      "Change %": "-0.38%"
  },
  {
      "Date": "29-Sep-06",
      "Price": "7.642",
      "Open": "7.642",
      "High": "7.642",
      "Low": "7.642",
      "Change %": "0.24%"
  },
  {
      "Date": "28-Sep-06",
      "Price": "7.624",
      "Open": "7.624",
      "High": "7.624",
      "Low": "7.624",
      "Change %": "0.42%"
  },
  {
      "Date": "27-Sep-06",
      "Price": "7.592",
      "Open": "7.592",
      "High": "7.592",
      "Low": "7.592",
      "Change %": "0.40%"
  },
  {
      "Date": "26-Sep-06",
      "Price": "7.562",
      "Open": "7.562",
      "High": "7.562",
      "Low": "7.562",
      "Change %": "-0.83%"
  },
  {
      "Date": "25-Sep-06",
      "Price": "7.625",
      "Open": "7.625",
      "High": "7.625",
      "Low": "7.625",
      "Change %": "0.08%"
  },
  {
      "Date": "22-Sep-06",
      "Price": "7.619",
      "Open": "7.619",
      "High": "7.619",
      "Low": "7.619",
      "Change %": "-0.79%"
  },
  {
      "Date": "21-Sep-06",
      "Price": "7.68",
      "Open": "7.68",
      "High": "7.68",
      "Low": "7.68",
      "Change %": "0.80%"
  },
  {
      "Date": "20-Sep-06",
      "Price": "7.619",
      "Open": "7.619",
      "High": "7.619",
      "Low": "7.619",
      "Change %": "-1.47%"
  },
  {
      "Date": "19-Sep-06",
      "Price": "7.733",
      "Open": "7.733",
      "High": "7.733",
      "Low": "7.733",
      "Change %": "-0.68%"
  },
  {
      "Date": "18-Sep-06",
      "Price": "7.786",
      "Open": "7.786",
      "High": "7.786",
      "Low": "7.786",
      "Change %": "-0.55%"
  },
  {
      "Date": "15-Sep-06",
      "Price": "7.829",
      "Open": "7.829",
      "High": "7.829",
      "Low": "7.829",
      "Change %": "0.27%"
  },
  {
      "Date": "14-Sep-06",
      "Price": "7.808",
      "Open": "7.808",
      "High": "7.808",
      "Low": "7.808",
      "Change %": "0.90%"
  },
  {
      "Date": "13-Sep-06",
      "Price": "7.738",
      "Open": "7.738",
      "High": "7.738",
      "Low": "7.738",
      "Change %": "-0.42%"
  },
  {
      "Date": "12-Sep-06",
      "Price": "7.771",
      "Open": "7.771",
      "High": "7.771",
      "Low": "7.771",
      "Change %": "0.83%"
  },
  {
      "Date": "11-Sep-06",
      "Price": "7.707",
      "Open": "7.707",
      "High": "7.707",
      "Low": "7.707",
      "Change %": "0.03%"
  },
  {
      "Date": "8-Sep-06",
      "Price": "7.705",
      "Open": "7.705",
      "High": "7.705",
      "Low": "7.705",
      "Change %": "-0.96%"
  },
  {
      "Date": "7-Sep-06",
      "Price": "7.78",
      "Open": "7.78",
      "High": "7.78",
      "Low": "7.78",
      "Change %": "0.14%"
  },
  {
      "Date": "6-Sep-06",
      "Price": "7.769",
      "Open": "7.769",
      "High": "7.769",
      "Low": "7.769",
      "Change %": "0.19%"
  },
  {
      "Date": "5-Sep-06",
      "Price": "7.754",
      "Open": "7.754",
      "High": "7.754",
      "Low": "7.754",
      "Change %": "0.40%"
  },
  {
      "Date": "4-Sep-06",
      "Price": "7.723",
      "Open": "7.723",
      "High": "7.723",
      "Low": "7.723",
      "Change %": "-1.96%"
  },
  {
      "Date": "1-Sep-06",
      "Price": "7.877",
      "Open": "7.877",
      "High": "7.877",
      "Low": "7.877",
      "Change %": "-0.18%"
  },
  {
      "Date": "31-Aug-06",
      "Price": "7.891",
      "Open": "7.891",
      "High": "7.891",
      "Low": "7.891",
      "Change %": "-0.53%"
  },
  {
      "Date": "30-Aug-06",
      "Price": "7.933",
      "Open": "7.933",
      "High": "7.933",
      "Low": "7.933",
      "Change %": "-0.16%"
  },
  {
      "Date": "29-Aug-06",
      "Price": "7.946",
      "Open": "7.946",
      "High": "7.946",
      "Low": "7.946",
      "Change %": "-0.05%"
  },
  {
      "Date": "28-Aug-06",
      "Price": "7.95",
      "Open": "7.95",
      "High": "7.95",
      "Low": "7.95",
      "Change %": "-0.38%"
  },
  {
      "Date": "25-Aug-06",
      "Price": "7.98",
      "Open": "7.98",
      "High": "7.98",
      "Low": "7.98",
      "Change %": "0.64%"
  },
  {
      "Date": "24-Aug-06",
      "Price": "7.929",
      "Open": "7.929",
      "High": "7.929",
      "Low": "7.929",
      "Change %": "-0.89%"
  },
  {
      "Date": "23-Aug-06",
      "Price": "8",
      "Open": "8",
      "High": "8",
      "Low": "8",
      "Change %": "-0.34%"
  },
  {
      "Date": "22-Aug-06",
      "Price": "8.027",
      "Open": "8.027",
      "High": "8.027",
      "Low": "8.027",
      "Change %": "-0.10%"
  },
  {
      "Date": "21-Aug-06",
      "Price": "8.035",
      "Open": "8.035",
      "High": "8.035",
      "Low": "8.035",
      "Change %": "0.20%"
  },
  {
      "Date": "18-Aug-06",
      "Price": "8.019",
      "Open": "8.019",
      "High": "8.019",
      "Low": "8.019",
      "Change %": "-0.10%"
  },
  {
      "Date": "17-Aug-06",
      "Price": "8.027",
      "Open": "8.027",
      "High": "8.027",
      "Low": "8.027",
      "Change %": "-0.82%"
  },
  {
      "Date": "16-Aug-06",
      "Price": "8.093",
      "Open": "8.093",
      "High": "8.093",
      "Low": "8.093",
      "Change %": "-0.57%"
  },
  {
      "Date": "14-Aug-06",
      "Price": "8.139",
      "Open": "8.139",
      "High": "8.139",
      "Low": "8.139",
      "Change %": "0.22%"
  },
  {
      "Date": "11-Aug-06",
      "Price": "8.121",
      "Open": "8.121",
      "High": "8.121",
      "Low": "8.121",
      "Change %": "0.42%"
  },
  {
      "Date": "10-Aug-06",
      "Price": "8.087",
      "Open": "8.087",
      "High": "8.087",
      "Low": "8.087",
      "Change %": "-0.41%"
  },
  {
      "Date": "9-Aug-06",
      "Price": "8.12",
      "Open": "8.12",
      "High": "8.12",
      "Low": "8.12",
      "Change %": "-1.73%"
  },
  {
      "Date": "8-Aug-06",
      "Price": "8.263",
      "Open": "8.263",
      "High": "8.263",
      "Low": "8.263",
      "Change %": "0.10%"
  },
  {
      "Date": "7-Aug-06",
      "Price": "8.255",
      "Open": "8.255",
      "High": "8.255",
      "Low": "8.255",
      "Change %": "-0.39%"
  },
  {
      "Date": "4-Aug-06",
      "Price": "8.287",
      "Open": "8.287",
      "High": "8.287",
      "Low": "8.287",
      "Change %": "-0.04%"
  },
  {
      "Date": "3-Aug-06",
      "Price": "8.29",
      "Open": "8.29",
      "High": "8.29",
      "Low": "8.29",
      "Change %": "0.00%"
  },
  {
      "Date": "2-Aug-06",
      "Price": "8.29",
      "Open": "8.29",
      "High": "8.29",
      "Low": "8.29",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Aug-06",
      "Price": "8.29",
      "Open": "8.29",
      "High": "8.29",
      "Low": "8.29",
      "Change %": "0.63%"
  },
  {
      "Date": "31-Jul-06",
      "Price": "8.238",
      "Open": "8.238",
      "High": "8.238",
      "Low": "8.238",
      "Change %": "-0.15%"
  },
  {
      "Date": "28-Jul-06",
      "Price": "8.25",
      "Open": "8.25",
      "High": "8.25",
      "Low": "8.25",
      "Change %": "0.35%"
  },
  {
      "Date": "27-Jul-06",
      "Price": "8.221",
      "Open": "8.221",
      "High": "8.221",
      "Low": "8.221",
      "Change %": "-0.22%"
  },
  {
      "Date": "26-Jul-06",
      "Price": "8.239",
      "Open": "8.239",
      "High": "8.239",
      "Low": "8.239",
      "Change %": "0.16%"
  },
  {
      "Date": "25-Jul-06",
      "Price": "8.226",
      "Open": "8.226",
      "High": "8.226",
      "Low": "8.226",
      "Change %": "0.09%"
  },
  {
      "Date": "24-Jul-06",
      "Price": "8.219",
      "Open": "8.219",
      "High": "8.219",
      "Low": "8.219",
      "Change %": "-0.28%"
  },
  {
      "Date": "21-Jul-06",
      "Price": "8.242",
      "Open": "8.242",
      "High": "8.242",
      "Low": "8.242",
      "Change %": "-0.72%"
  },
  {
      "Date": "20-Jul-06",
      "Price": "8.302",
      "Open": "8.302",
      "High": "8.302",
      "Low": "8.302",
      "Change %": "-0.46%"
  },
  {
      "Date": "19-Jul-06",
      "Price": "8.34",
      "Open": "8.34",
      "High": "8.34",
      "Low": "8.34",
      "Change %": "-0.39%"
  },
  {
      "Date": "18-Jul-06",
      "Price": "8.373",
      "Open": "8.373",
      "High": "8.373",
      "Low": "8.373",
      "Change %": "0.02%"
  },
  {
      "Date": "17-Jul-06",
      "Price": "8.371",
      "Open": "8.371",
      "High": "8.371",
      "Low": "8.371",
      "Change %": "0.32%"
  },
  {
      "Date": "14-Jul-06",
      "Price": "8.344",
      "Open": "8.344",
      "High": "8.344",
      "Low": "8.344",
      "Change %": "-0.13%"
  },
  {
      "Date": "13-Jul-06",
      "Price": "8.355",
      "Open": "8.355",
      "High": "8.355",
      "Low": "8.355",
      "Change %": "-0.25%"
  },
  {
      "Date": "12-Jul-06",
      "Price": "8.376",
      "Open": "8.376",
      "High": "8.376",
      "Low": "8.376",
      "Change %": "1.01%"
  },
  {
      "Date": "11-Jul-06",
      "Price": "8.292",
      "Open": "8.292",
      "High": "8.292",
      "Low": "8.292",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Jul-06",
      "Price": "8.292",
      "Open": "8.292",
      "High": "8.292",
      "Low": "8.292",
      "Change %": "0.96%"
  },
  {
      "Date": "7-Jul-06",
      "Price": "8.213",
      "Open": "8.213",
      "High": "8.213",
      "Low": "8.213",
      "Change %": "0.38%"
  },
  {
      "Date": "6-Jul-06",
      "Price": "8.182",
      "Open": "8.182",
      "High": "8.182",
      "Low": "8.182",
      "Change %": "0.28%"
  },
  {
      "Date": "5-Jul-06",
      "Price": "8.159",
      "Open": "8.159",
      "High": "8.159",
      "Low": "8.159",
      "Change %": "0.00%"
  },
  {
      "Date": "4-Jul-06",
      "Price": "8.159",
      "Open": "8.159",
      "High": "8.159",
      "Low": "8.159",
      "Change %": "0.10%"
  },
  {
      "Date": "3-Jul-06",
      "Price": "8.151",
      "Open": "8.151",
      "High": "8.151",
      "Low": "8.151",
      "Change %": "0.00%"
  },
  {
      "Date": "30-Jun-06",
      "Price": "8.151",
      "Open": "8.151",
      "High": "8.151",
      "Low": "8.151",
      "Change %": "0.28%"
  },
  {
      "Date": "29-Jun-06",
      "Price": "8.128",
      "Open": "8.128",
      "High": "8.128",
      "Low": "8.128",
      "Change %": "0.10%"
  },
  {
      "Date": "28-Jun-06",
      "Price": "8.12",
      "Open": "8.12",
      "High": "8.12",
      "Low": "8.12",
      "Change %": "-0.18%"
  },
  {
      "Date": "27-Jun-06",
      "Price": "8.135",
      "Open": "8.135",
      "High": "8.135",
      "Low": "8.135",
      "Change %": "0.15%"
  },
  {
      "Date": "26-Jun-06",
      "Price": "8.123",
      "Open": "8.123",
      "High": "8.123",
      "Low": "8.123",
      "Change %": "0.42%"
  },
  {
      "Date": "23-Jun-06",
      "Price": "8.089",
      "Open": "8.089",
      "High": "8.089",
      "Low": "8.089",
      "Change %": "1.20%"
  },
  {
      "Date": "22-Jun-06",
      "Price": "7.993",
      "Open": "7.993",
      "High": "7.993",
      "Low": "7.993",
      "Change %": "1.01%"
  },
  {
      "Date": "21-Jun-06",
      "Price": "7.913",
      "Open": "7.913",
      "High": "7.913",
      "Low": "7.913",
      "Change %": "0.00%"
  },
  {
      "Date": "20-Jun-06",
      "Price": "7.913",
      "Open": "7.913",
      "High": "7.913",
      "Low": "7.913",
      "Change %": "0.08%"
  },
  {
      "Date": "19-Jun-06",
      "Price": "7.907",
      "Open": "7.907",
      "High": "7.907",
      "Low": "7.907",
      "Change %": "1.33%"
  },
  {
      "Date": "16-Jun-06",
      "Price": "7.803",
      "Open": "7.803",
      "High": "7.803",
      "Low": "7.803",
      "Change %": "0.04%"
  },
  {
      "Date": "15-Jun-06",
      "Price": "7.8",
      "Open": "7.8",
      "High": "7.8",
      "Low": "7.8",
      "Change %": "0.08%"
  },
  {
      "Date": "14-Jun-06",
      "Price": "7.794",
      "Open": "7.794",
      "High": "7.794",
      "Low": "7.794",
      "Change %": "-0.23%"
  },
  {
      "Date": "13-Jun-06",
      "Price": "7.812",
      "Open": "7.812",
      "High": "7.812",
      "Low": "7.812",
      "Change %": "0.12%"
  },
  {
      "Date": "12-Jun-06",
      "Price": "7.803",
      "Open": "7.803",
      "High": "7.803",
      "Low": "7.803",
      "Change %": "0.15%"
  },
  {
      "Date": "9-Jun-06",
      "Price": "7.791",
      "Open": "7.791",
      "High": "7.791",
      "Low": "7.791",
      "Change %": "1.54%"
  },
  {
      "Date": "8-Jun-06",
      "Price": "7.673",
      "Open": "7.673",
      "High": "7.673",
      "Low": "7.673",
      "Change %": "0.07%"
  },
  {
      "Date": "7-Jun-06",
      "Price": "7.668",
      "Open": "7.668",
      "High": "7.668",
      "Low": "7.668",
      "Change %": "0.37%"
  },
  {
      "Date": "6-Jun-06",
      "Price": "7.64",
      "Open": "7.64",
      "High": "7.64",
      "Low": "7.64",
      "Change %": "-0.16%"
  },
  {
      "Date": "5-Jun-06",
      "Price": "7.652",
      "Open": "7.652",
      "High": "7.652",
      "Low": "7.652",
      "Change %": "-0.25%"
  },
  {
      "Date": "2-Jun-06",
      "Price": "7.671",
      "Open": "7.671",
      "High": "7.671",
      "Low": "7.671",
      "Change %": "-0.07%"
  },
  {
      "Date": "1-Jun-06",
      "Price": "7.676",
      "Open": "7.676",
      "High": "7.676",
      "Low": "7.676",
      "Change %": "0.22%"
  },
  {
      "Date": "31-May-06",
      "Price": "7.659",
      "Open": "7.659",
      "High": "7.659",
      "Low": "7.659",
      "Change %": "0.05%"
  },
  {
      "Date": "30-May-06",
      "Price": "7.655",
      "Open": "7.655",
      "High": "7.655",
      "Low": "7.655",
      "Change %": "0.14%"
  },
  {
      "Date": "29-May-06",
      "Price": "7.644",
      "Open": "7.644",
      "High": "7.644",
      "Low": "7.644",
      "Change %": "0.43%"
  },
  {
      "Date": "26-May-06",
      "Price": "7.611",
      "Open": "7.611",
      "High": "7.611",
      "Low": "7.611",
      "Change %": "0.05%"
  },
  {
      "Date": "25-May-06",
      "Price": "7.607",
      "Open": "7.607",
      "High": "7.607",
      "Low": "7.607",
      "Change %": "0.00%"
  },
  {
      "Date": "24-May-06",
      "Price": "7.607",
      "Open": "7.607",
      "High": "7.607",
      "Low": "7.607",
      "Change %": "0.17%"
  },
  {
      "Date": "23-May-06",
      "Price": "7.594",
      "Open": "7.594",
      "High": "7.594",
      "Low": "7.594",
      "Change %": "-0.04%"
  },
  {
      "Date": "22-May-06",
      "Price": "7.597",
      "Open": "7.597",
      "High": "7.597",
      "Low": "7.597",
      "Change %": "-0.20%"
  },
  {
      "Date": "19-May-06",
      "Price": "7.612",
      "Open": "7.612",
      "High": "7.612",
      "Low": "7.612",
      "Change %": "-0.18%"
  },
  {
      "Date": "18-May-06",
      "Price": "7.626",
      "Open": "7.626",
      "High": "7.626",
      "Low": "7.626",
      "Change %": "0.55%"
  },
  {
      "Date": "17-May-06",
      "Price": "7.584",
      "Open": "7.584",
      "High": "7.584",
      "Low": "7.584",
      "Change %": "0.17%"
  },
  {
      "Date": "16-May-06",
      "Price": "7.571",
      "Open": "7.571",
      "High": "7.571",
      "Low": "7.571",
      "Change %": "-0.26%"
  },
  {
      "Date": "15-May-06",
      "Price": "7.591",
      "Open": "7.591",
      "High": "7.591",
      "Low": "7.591",
      "Change %": "0.61%"
  },
  {
      "Date": "12-May-06",
      "Price": "7.545",
      "Open": "7.545",
      "High": "7.545",
      "Low": "7.545",
      "Change %": "0.17%"
  },
  {
      "Date": "11-May-06",
      "Price": "7.532",
      "Open": "7.532",
      "High": "7.532",
      "Low": "7.532",
      "Change %": "0.43%"
  },
  {
      "Date": "10-May-06",
      "Price": "7.5",
      "Open": "7.5",
      "High": "7.5",
      "Low": "7.5",
      "Change %": "0.13%"
  },
  {
      "Date": "9-May-06",
      "Price": "7.49",
      "Open": "7.49",
      "High": "7.49",
      "Low": "7.49",
      "Change %": "-0.43%"
  },
  {
      "Date": "8-May-06",
      "Price": "7.522",
      "Open": "7.522",
      "High": "7.522",
      "Low": "7.522",
      "Change %": "-0.20%"
  },
  {
      "Date": "5-May-06",
      "Price": "7.537",
      "Open": "7.537",
      "High": "7.537",
      "Low": "7.537",
      "Change %": "0.03%"
  },
  {
      "Date": "4-May-06",
      "Price": "7.535",
      "Open": "7.535",
      "High": "7.535",
      "Low": "7.535",
      "Change %": "0.37%"
  },
  {
      "Date": "3-May-06",
      "Price": "7.507",
      "Open": "7.507",
      "High": "7.507",
      "Low": "7.507",
      "Change %": "0.47%"
  },
  {
      "Date": "2-May-06",
      "Price": "7.472",
      "Open": "7.472",
      "High": "7.472",
      "Low": "7.472",
      "Change %": "1.11%"
  },
  {
      "Date": "28-Apr-06",
      "Price": "7.39",
      "Open": "7.39",
      "High": "7.39",
      "Low": "7.39",
      "Change %": "-0.40%"
  },
  {
      "Date": "27-Apr-06",
      "Price": "7.42",
      "Open": "7.42",
      "High": "7.42",
      "Low": "7.42",
      "Change %": "0.20%"
  },
  {
      "Date": "26-Apr-06",
      "Price": "7.405",
      "Open": "7.405",
      "High": "7.405",
      "Low": "7.405",
      "Change %": "0.75%"
  },
  {
      "Date": "25-Apr-06",
      "Price": "7.35",
      "Open": "7.35",
      "High": "7.35",
      "Low": "7.35",
      "Change %": "-0.10%"
  },
  {
      "Date": "24-Apr-06",
      "Price": "7.357",
      "Open": "7.357",
      "High": "7.357",
      "Low": "7.357",
      "Change %": "0.26%"
  },
  {
      "Date": "21-Apr-06",
      "Price": "7.338",
      "Open": "7.338",
      "High": "7.338",
      "Low": "7.338",
      "Change %": "-0.27%"
  },
  {
      "Date": "20-Apr-06",
      "Price": "7.358",
      "Open": "7.358",
      "High": "7.358",
      "Low": "7.358",
      "Change %": "0.55%"
  },
  {
      "Date": "19-Apr-06",
      "Price": "7.318",
      "Open": "7.318",
      "High": "7.318",
      "Low": "7.318",
      "Change %": "-2.91%"
  },
  {
      "Date": "18-Apr-06",
      "Price": "7.537",
      "Open": "7.537",
      "High": "7.537",
      "Low": "7.537",
      "Change %": "-1.24%"
  },
  {
      "Date": "17-Apr-06",
      "Price": "7.632",
      "Open": "7.632",
      "High": "7.632",
      "Low": "7.632",
      "Change %": "0.28%"
  },
  {
      "Date": "13-Apr-06",
      "Price": "7.611",
      "Open": "7.611",
      "High": "7.611",
      "Low": "7.611",
      "Change %": "0.48%"
  },
  {
      "Date": "12-Apr-06",
      "Price": "7.575",
      "Open": "7.575",
      "High": "7.575",
      "Low": "7.575",
      "Change %": "0.41%"
  },
  {
      "Date": "10-Apr-06",
      "Price": "7.544",
      "Open": "7.544",
      "High": "7.544",
      "Low": "7.544",
      "Change %": "-0.03%"
  },
  {
      "Date": "7-Apr-06",
      "Price": "7.546",
      "Open": "7.546",
      "High": "7.546",
      "Low": "7.546",
      "Change %": "0.11%"
  },
  {
      "Date": "5-Apr-06",
      "Price": "7.538",
      "Open": "7.538",
      "High": "7.538",
      "Low": "7.538",
      "Change %": "-0.15%"
  },
  {
      "Date": "4-Apr-06",
      "Price": "7.549",
      "Open": "7.549",
      "High": "7.549",
      "Low": "7.549",
      "Change %": "-0.11%"
  },
  {
      "Date": "3-Apr-06",
      "Price": "7.557",
      "Open": "7.557",
      "High": "7.557",
      "Low": "7.557",
      "Change %": "0.09%"
  },
  {
      "Date": "31-Mar-06",
      "Price": "7.55",
      "Open": "7.55",
      "High": "7.55",
      "Low": "7.55",
      "Change %": "0.76%"
  },
  {
      "Date": "29-Mar-06",
      "Price": "7.493",
      "Open": "7.493",
      "High": "7.493",
      "Low": "7.493",
      "Change %": "0.85%"
  },
  {
      "Date": "28-Mar-06",
      "Price": "7.43",
      "Open": "7.43",
      "High": "7.43",
      "Low": "7.43",
      "Change %": "-0.05%"
  },
  {
      "Date": "27-Mar-06",
      "Price": "7.434",
      "Open": "7.434",
      "High": "7.434",
      "Low": "7.434",
      "Change %": "0.66%"
  },
  {
      "Date": "24-Mar-06",
      "Price": "7.385",
      "Open": "7.385",
      "High": "7.385",
      "Low": "7.385",
      "Change %": "-0.11%"
  },
  {
      "Date": "23-Mar-06",
      "Price": "7.393",
      "Open": "7.393",
      "High": "7.393",
      "Low": "7.393",
      "Change %": "0.04%"
  },
  {
      "Date": "22-Mar-06",
      "Price": "7.39",
      "Open": "7.39",
      "High": "7.39",
      "Low": "7.39",
      "Change %": "0.20%"
  },
  {
      "Date": "21-Mar-06",
      "Price": "7.375",
      "Open": "7.375",
      "High": "7.375",
      "Low": "7.375",
      "Change %": "-0.14%"
  },
  {
      "Date": "20-Mar-06",
      "Price": "7.385",
      "Open": "7.385",
      "High": "7.385",
      "Low": "7.385",
      "Change %": "-0.08%"
  },
  {
      "Date": "17-Mar-06",
      "Price": "7.391",
      "Open": "7.391",
      "High": "7.391",
      "Low": "7.391",
      "Change %": "-0.26%"
  },
  {
      "Date": "16-Mar-06",
      "Price": "7.41",
      "Open": "7.41",
      "High": "7.41",
      "Low": "7.41",
      "Change %": "-0.08%"
  },
  {
      "Date": "14-Mar-06",
      "Price": "7.416",
      "Open": "7.416",
      "High": "7.416",
      "Low": "7.416",
      "Change %": "0.04%"
  },
  {
      "Date": "13-Mar-06",
      "Price": "7.413",
      "Open": "7.413",
      "High": "7.413",
      "Low": "7.413",
      "Change %": "0.05%"
  },
  {
      "Date": "10-Mar-06",
      "Price": "7.409",
      "Open": "7.409",
      "High": "7.409",
      "Low": "7.409",
      "Change %": "0.27%"
  },
  {
      "Date": "9-Mar-06",
      "Price": "7.389",
      "Open": "7.389",
      "High": "7.389",
      "Low": "7.389",
      "Change %": "-0.28%"
  },
  {
      "Date": "8-Mar-06",
      "Price": "7.41",
      "Open": "7.41",
      "High": "7.41",
      "Low": "7.41",
      "Change %": "-0.34%"
  },
  {
      "Date": "7-Mar-06",
      "Price": "7.435",
      "Open": "7.435",
      "High": "7.435",
      "Low": "7.435",
      "Change %": "-0.56%"
  },
  {
      "Date": "6-Mar-06",
      "Price": "7.477",
      "Open": "7.477",
      "High": "7.477",
      "Low": "7.477",
      "Change %": "0.40%"
  },
  {
      "Date": "3-Mar-06",
      "Price": "7.447",
      "Open": "7.447",
      "High": "7.447",
      "Low": "7.447",
      "Change %": "0.49%"
  },
  {
      "Date": "2-Mar-06",
      "Price": "7.411",
      "Open": "7.411",
      "High": "7.411",
      "Low": "7.411",
      "Change %": "0.04%"
  },
  {
      "Date": "1-Mar-06",
      "Price": "7.408",
      "Open": "7.408",
      "High": "7.408",
      "Low": "7.408",
      "Change %": "0.20%"
  },
  {
      "Date": "28-Feb-06",
      "Price": "7.393",
      "Open": "7.393",
      "High": "7.393",
      "Low": "7.393",
      "Change %": "0.30%"
  },
  {
      "Date": "27-Feb-06",
      "Price": "7.371",
      "Open": "7.371",
      "High": "7.371",
      "Low": "7.371",
      "Change %": "-0.24%"
  },
  {
      "Date": "24-Feb-06",
      "Price": "7.389",
      "Open": "7.389",
      "High": "7.389",
      "Low": "7.389",
      "Change %": "0.14%"
  },
  {
      "Date": "23-Feb-06",
      "Price": "7.379",
      "Open": "7.379",
      "High": "7.379",
      "Low": "7.379",
      "Change %": "0.18%"
  },
  {
      "Date": "22-Feb-06",
      "Price": "7.366",
      "Open": "7.366",
      "High": "7.366",
      "Low": "7.366",
      "Change %": "0.31%"
  },
  {
      "Date": "21-Feb-06",
      "Price": "7.343",
      "Open": "7.343",
      "High": "7.343",
      "Low": "7.343",
      "Change %": "-0.07%"
  },
  {
      "Date": "20-Feb-06",
      "Price": "7.348",
      "Open": "7.348",
      "High": "7.348",
      "Low": "7.348",
      "Change %": "0.08%"
  },
  {
      "Date": "17-Feb-06",
      "Price": "7.342",
      "Open": "7.342",
      "High": "7.342",
      "Low": "7.342",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Feb-06",
      "Price": "7.342",
      "Open": "7.342",
      "High": "7.342",
      "Low": "7.342",
      "Change %": "-0.43%"
  },
  {
      "Date": "15-Feb-06",
      "Price": "7.374",
      "Open": "7.374",
      "High": "7.374",
      "Low": "7.374",
      "Change %": "0.20%"
  },
  {
      "Date": "14-Feb-06",
      "Price": "7.359",
      "Open": "7.359",
      "High": "7.359",
      "Low": "7.359",
      "Change %": "0.19%"
  },
  {
      "Date": "13-Feb-06",
      "Price": "7.345",
      "Open": "7.345",
      "High": "7.345",
      "Low": "7.345",
      "Change %": "-0.10%"
  },
  {
      "Date": "10-Feb-06",
      "Price": "7.352",
      "Open": "7.352",
      "High": "7.352",
      "Low": "7.352",
      "Change %": "-0.16%"
  },
  {
      "Date": "8-Feb-06",
      "Price": "7.364",
      "Open": "7.364",
      "High": "7.364",
      "Low": "7.364",
      "Change %": "0.12%"
  },
  {
      "Date": "7-Feb-06",
      "Price": "7.355",
      "Open": "7.355",
      "High": "7.355",
      "Low": "7.355",
      "Change %": "0.40%"
  },
  {
      "Date": "6-Feb-06",
      "Price": "7.326",
      "Open": "7.326",
      "High": "7.326",
      "Low": "7.326",
      "Change %": "-0.31%"
  },
  {
      "Date": "3-Feb-06",
      "Price": "7.349",
      "Open": "7.349",
      "High": "7.349",
      "Low": "7.349",
      "Change %": "-0.62%"
  },
  {
      "Date": "2-Feb-06",
      "Price": "7.395",
      "Open": "7.395",
      "High": "7.395",
      "Low": "7.395",
      "Change %": "0.37%"
  },
  {
      "Date": "1-Feb-06",
      "Price": "7.368",
      "Open": "7.368",
      "High": "7.368",
      "Low": "7.368",
      "Change %": "-0.05%"
  },
  {
      "Date": "31-Jan-06",
      "Price": "7.372",
      "Open": "7.372",
      "High": "7.372",
      "Low": "7.372",
      "Change %": "-0.71%"
  },
  {
      "Date": "30-Jan-06",
      "Price": "7.425",
      "Open": "7.425",
      "High": "7.425",
      "Low": "7.425",
      "Change %": "-0.80%"
  },
  {
      "Date": "27-Jan-06",
      "Price": "7.485",
      "Open": "7.485",
      "High": "7.485",
      "Low": "7.485",
      "Change %": "0.50%"
  },
  {
      "Date": "25-Jan-06",
      "Price": "7.448",
      "Open": "7.448",
      "High": "7.448",
      "Low": "7.448",
      "Change %": "1.68%"
  },
  {
      "Date": "24-Jan-06",
      "Price": "7.325",
      "Open": "7.325",
      "High": "7.325",
      "Low": "7.325",
      "Change %": "1.67%"
  },
  {
      "Date": "23-Jan-06",
      "Price": "7.205",
      "Open": "7.205",
      "High": "7.205",
      "Low": "7.205",
      "Change %": "0.03%"
  },
  {
      "Date": "20-Jan-06",
      "Price": "7.203",
      "Open": "7.203",
      "High": "7.203",
      "Low": "7.203",
      "Change %": "-0.17%"
  },
  {
      "Date": "19-Jan-06",
      "Price": "7.215",
      "Open": "7.215",
      "High": "7.215",
      "Low": "7.215",
      "Change %": "0.19%"
  },
  {
      "Date": "18-Jan-06",
      "Price": "7.201",
      "Open": "7.201",
      "High": "7.201",
      "Low": "7.201",
      "Change %": "0.15%"
  },
  {
      "Date": "17-Jan-06",
      "Price": "7.19",
      "Open": "7.19",
      "High": "7.19",
      "Low": "7.19",
      "Change %": "-0.17%"
  },
  {
      "Date": "16-Jan-06",
      "Price": "7.202",
      "Open": "7.202",
      "High": "7.202",
      "Low": "7.202",
      "Change %": "-0.15%"
  },
  {
      "Date": "13-Jan-06",
      "Price": "7.213",
      "Open": "7.213",
      "High": "7.213",
      "Low": "7.213",
      "Change %": "0.24%"
  },
  {
      "Date": "12-Jan-06",
      "Price": "7.196",
      "Open": "7.196",
      "High": "7.196",
      "Low": "7.196",
      "Change %": "0.11%"
  },
  {
      "Date": "10-Jan-06",
      "Price": "7.188",
      "Open": "7.188",
      "High": "7.188",
      "Low": "7.188",
      "Change %": "0.01%"
  },
  {
      "Date": "9-Jan-06",
      "Price": "7.187",
      "Open": "7.187",
      "High": "7.187",
      "Low": "7.187",
      "Change %": "0.06%"
  },
  {
      "Date": "6-Jan-06",
      "Price": "7.183",
      "Open": "7.183",
      "High": "7.183",
      "Low": "7.183",
      "Change %": "0.10%"
  },
  {
      "Date": "5-Jan-06",
      "Price": "7.176",
      "Open": "7.176",
      "High": "7.176",
      "Low": "7.176",
      "Change %": "-0.18%"
  },
  {
      "Date": "4-Jan-06",
      "Price": "7.189",
      "Open": "7.189",
      "High": "7.189",
      "Low": "7.189",
      "Change %": "0.13%"
  },
  {
      "Date": "3-Jan-06",
      "Price": "7.18",
      "Open": "7.18",
      "High": "7.18",
      "Low": "7.18",
      "Change %": "0.00%"
  },
  {
      "Date": "2-Jan-06",
      "Price": "7.18",
      "Open": "7.18",
      "High": "7.18",
      "Low": "7.18",
      "Change %": "0.98%"
  },
  {
      "Date": "30-Dec-05",
      "Price": "7.11",
      "Open": "7.11",
      "High": "7.11",
      "Low": "7.11",
      "Change %": "0.00%"
  },
  {
      "Date": "29-Dec-05",
      "Price": "7.11",
      "Open": "7.11",
      "High": "7.11",
      "Low": "7.11",
      "Change %": "-0.10%"
  },
  {
      "Date": "28-Dec-05",
      "Price": "7.117",
      "Open": "7.117",
      "High": "7.117",
      "Low": "7.117",
      "Change %": "0.00%"
  },
  {
      "Date": "27-Dec-05",
      "Price": "7.117",
      "Open": "7.117",
      "High": "7.117",
      "Low": "7.117",
      "Change %": "-0.11%"
  },
  {
      "Date": "26-Dec-05",
      "Price": "7.125",
      "Open": "7.125",
      "High": "7.125",
      "Low": "7.125",
      "Change %": "0.04%"
  },
  {
      "Date": "23-Dec-05",
      "Price": "7.122",
      "Open": "7.122",
      "High": "7.122",
      "Low": "7.122",
      "Change %": "-0.10%"
  },
  {
      "Date": "22-Dec-05",
      "Price": "7.129",
      "Open": "7.129",
      "High": "7.129",
      "Low": "7.129",
      "Change %": "0.15%"
  },
  {
      "Date": "21-Dec-05",
      "Price": "7.118",
      "Open": "7.118",
      "High": "7.118",
      "Low": "7.118",
      "Change %": "0.00%"
  },
  {
      "Date": "20-Dec-05",
      "Price": "7.118",
      "Open": "7.118",
      "High": "7.118",
      "Low": "7.118",
      "Change %": "0.04%"
  },
  {
      "Date": "19-Dec-05",
      "Price": "7.115",
      "Open": "7.115",
      "High": "7.115",
      "Low": "7.115",
      "Change %": "0.15%"
  },
  {
      "Date": "16-Dec-05",
      "Price": "7.104",
      "Open": "7.104",
      "High": "7.104",
      "Low": "7.104",
      "Change %": "-0.10%"
  },
  {
      "Date": "15-Dec-05",
      "Price": "7.111",
      "Open": "7.111",
      "High": "7.111",
      "Low": "7.111",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Dec-05",
      "Price": "7.111",
      "Open": "7.111",
      "High": "7.111",
      "Low": "7.111",
      "Change %": "0.00%"
  },
  {
      "Date": "13-Dec-05",
      "Price": "7.111",
      "Open": "7.111",
      "High": "7.111",
      "Low": "7.111",
      "Change %": "0.00%"
  },
  {
      "Date": "12-Dec-05",
      "Price": "7.111",
      "Open": "7.111",
      "High": "7.111",
      "Low": "7.111",
      "Change %": "0.10%"
  },
  {
      "Date": "9-Dec-05",
      "Price": "7.104",
      "Open": "7.104",
      "High": "7.104",
      "Low": "7.104",
      "Change %": "0.10%"
  },
  {
      "Date": "8-Dec-05",
      "Price": "7.097",
      "Open": "7.097",
      "High": "7.097",
      "Low": "7.097",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Dec-05",
      "Price": "7.097",
      "Open": "7.097",
      "High": "7.097",
      "Low": "7.097",
      "Change %": "0.00%"
  },
  {
      "Date": "6-Dec-05",
      "Price": "7.097",
      "Open": "7.097",
      "High": "7.097",
      "Low": "7.097",
      "Change %": "0.10%"
  },
  {
      "Date": "5-Dec-05",
      "Price": "7.09",
      "Open": "7.09",
      "High": "7.09",
      "Low": "7.09",
      "Change %": "-0.10%"
  },
  {
      "Date": "2-Dec-05",
      "Price": "7.097",
      "Open": "7.097",
      "High": "7.097",
      "Low": "7.097",
      "Change %": "0.25%"
  },
  {
      "Date": "1-Dec-05",
      "Price": "7.079",
      "Open": "7.079",
      "High": "7.079",
      "Low": "7.079",
      "Change %": "-0.06%"
  },
  {
      "Date": "30-Nov-05",
      "Price": "7.083",
      "Open": "7.083",
      "High": "7.083",
      "Low": "7.083",
      "Change %": "0.10%"
  },
  {
      "Date": "29-Nov-05",
      "Price": "7.076",
      "Open": "7.076",
      "High": "7.076",
      "Low": "7.076",
      "Change %": "-0.20%"
  },
  {
      "Date": "28-Nov-05",
      "Price": "7.09",
      "Open": "7.09",
      "High": "7.09",
      "Low": "7.09",
      "Change %": "-0.01%"
  },
  {
      "Date": "25-Nov-05",
      "Price": "7.091",
      "Open": "7.091",
      "High": "7.091",
      "Low": "7.091",
      "Change %": "0.31%"
  },
  {
      "Date": "24-Nov-05",
      "Price": "7.069",
      "Open": "7.069",
      "High": "7.069",
      "Low": "7.069",
      "Change %": "0.06%"
  },
  {
      "Date": "23-Nov-05",
      "Price": "7.065",
      "Open": "7.065",
      "High": "7.065",
      "Low": "7.065",
      "Change %": "-0.17%"
  },
  {
      "Date": "22-Nov-05",
      "Price": "7.077",
      "Open": "7.077",
      "High": "7.077",
      "Low": "7.077",
      "Change %": "-0.20%"
  },
  {
      "Date": "21-Nov-05",
      "Price": "7.091",
      "Open": "7.091",
      "High": "7.091",
      "Low": "7.091",
      "Change %": "-0.04%"
  },
  {
      "Date": "18-Nov-05",
      "Price": "7.094",
      "Open": "7.094",
      "High": "7.094",
      "Low": "7.094",
      "Change %": "-0.06%"
  },
  {
      "Date": "17-Nov-05",
      "Price": "7.098",
      "Open": "7.098",
      "High": "7.098",
      "Low": "7.098",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Nov-05",
      "Price": "7.098",
      "Open": "7.098",
      "High": "7.098",
      "Low": "7.098",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Nov-05",
      "Price": "7.098",
      "Open": "7.098",
      "High": "7.098",
      "Low": "7.098",
      "Change %": "-0.31%"
  },
  {
      "Date": "11-Nov-05",
      "Price": "7.12",
      "Open": "7.12",
      "High": "7.12",
      "Low": "7.12",
      "Change %": "-0.04%"
  },
  {
      "Date": "10-Nov-05",
      "Price": "7.123",
      "Open": "7.123",
      "High": "7.123",
      "Low": "7.123",
      "Change %": "-0.06%"
  },
  {
      "Date": "9-Nov-05",
      "Price": "7.127",
      "Open": "7.127",
      "High": "7.127",
      "Low": "7.127",
      "Change %": "0.30%"
  },
  {
      "Date": "8-Nov-05",
      "Price": "7.106",
      "Open": "7.106",
      "High": "7.106",
      "Low": "7.106",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Nov-05",
      "Price": "7.106",
      "Open": "7.106",
      "High": "7.106",
      "Low": "7.106",
      "Change %": "0.04%"
  },
  {
      "Date": "4-Nov-05",
      "Price": "7.103",
      "Open": "7.103",
      "High": "7.103",
      "Low": "7.103",
      "Change %": "0.10%"
  },
  {
      "Date": "3-Nov-05",
      "Price": "7.096",
      "Open": "7.096",
      "High": "7.096",
      "Low": "7.096",
      "Change %": "-0.04%"
  },
  {
      "Date": "2-Nov-05",
      "Price": "7.099",
      "Open": "7.099",
      "High": "7.099",
      "Low": "7.099",
      "Change %": "0.00%"
  },
  {
      "Date": "31-Oct-05",
      "Price": "7.099",
      "Open": "7.099",
      "High": "7.099",
      "Low": "7.099",
      "Change %": "0.30%"
  },
  {
      "Date": "28-Oct-05",
      "Price": "7.078",
      "Open": "7.078",
      "High": "7.078",
      "Low": "7.078",
      "Change %": "-0.10%"
  },
  {
      "Date": "27-Oct-05",
      "Price": "7.085",
      "Open": "7.085",
      "High": "7.085",
      "Low": "7.085",
      "Change %": "-0.11%"
  },
  {
      "Date": "26-Oct-05",
      "Price": "7.093",
      "Open": "7.093",
      "High": "7.093",
      "Low": "7.093",
      "Change %": "-0.49%"
  },
  {
      "Date": "25-Oct-05",
      "Price": "7.128",
      "Open": "7.128",
      "High": "7.128",
      "Low": "7.128",
      "Change %": "0.28%"
  },
  {
      "Date": "24-Oct-05",
      "Price": "7.108",
      "Open": "7.108",
      "High": "7.108",
      "Low": "7.108",
      "Change %": "-0.88%"
  },
  {
      "Date": "21-Oct-05",
      "Price": "7.171",
      "Open": "7.171",
      "High": "7.171",
      "Low": "7.171",
      "Change %": "-0.10%"
  },
  {
      "Date": "20-Oct-05",
      "Price": "7.178",
      "Open": "7.178",
      "High": "7.178",
      "Low": "7.178",
      "Change %": "0.10%"
  },
  {
      "Date": "19-Oct-05",
      "Price": "7.171",
      "Open": "7.171",
      "High": "7.171",
      "Low": "7.171",
      "Change %": "-0.10%"
  },
  {
      "Date": "18-Oct-05",
      "Price": "7.178",
      "Open": "7.178",
      "High": "7.178",
      "Low": "7.178",
      "Change %": "0.59%"
  },
  {
      "Date": "17-Oct-05",
      "Price": "7.136",
      "Open": "7.136",
      "High": "7.136",
      "Low": "7.136",
      "Change %": "0.10%"
  },
  {
      "Date": "14-Oct-05",
      "Price": "7.129",
      "Open": "7.129",
      "High": "7.129",
      "Low": "7.129",
      "Change %": "-0.20%"
  },
  {
      "Date": "13-Oct-05",
      "Price": "7.143",
      "Open": "7.143",
      "High": "7.143",
      "Low": "7.143",
      "Change %": "-0.20%"
  },
  {
      "Date": "11-Oct-05",
      "Price": "7.157",
      "Open": "7.157",
      "High": "7.157",
      "Low": "7.157",
      "Change %": "-0.29%"
  },
  {
      "Date": "10-Oct-05",
      "Price": "7.178",
      "Open": "7.178",
      "High": "7.178",
      "Low": "7.178",
      "Change %": "-0.01%"
  },
  {
      "Date": "7-Oct-05",
      "Price": "7.179",
      "Open": "7.179",
      "High": "7.179",
      "Low": "7.179",
      "Change %": "0.00%"
  },
  {
      "Date": "6-Oct-05",
      "Price": "7.179",
      "Open": "7.179",
      "High": "7.179",
      "Low": "7.179",
      "Change %": "0.49%"
  },
  {
      "Date": "5-Oct-05",
      "Price": "7.144",
      "Open": "7.144",
      "High": "7.144",
      "Low": "7.144",
      "Change %": "0.00%"
  },
  {
      "Date": "4-Oct-05",
      "Price": "7.144",
      "Open": "7.144",
      "High": "7.144",
      "Low": "7.144",
      "Change %": "-0.15%"
  },
  {
      "Date": "3-Oct-05",
      "Price": "7.155",
      "Open": "7.155",
      "High": "7.155",
      "Low": "7.155",
      "Change %": "0.76%"
  },
  {
      "Date": "1-Oct-05",
      "Price": "7.101",
      "Open": "7.101",
      "High": "7.101",
      "Low": "7.101",
      "Change %": "0.00%"
  },
  {
      "Date": "30-Sep-05",
      "Price": "7.101",
      "Open": "7.101",
      "High": "7.101",
      "Low": "7.101",
      "Change %": "0.08%"
  },
  {
      "Date": "29-Sep-05",
      "Price": "7.095",
      "Open": "7.095",
      "High": "7.095",
      "Low": "7.095",
      "Change %": "0.30%"
  },
  {
      "Date": "28-Sep-05",
      "Price": "7.074",
      "Open": "7.074",
      "High": "7.074",
      "Low": "7.074",
      "Change %": "-0.20%"
  },
  {
      "Date": "27-Sep-05",
      "Price": "7.088",
      "Open": "7.088",
      "High": "7.088",
      "Low": "7.088",
      "Change %": "0.70%"
  },
  {
      "Date": "26-Sep-05",
      "Price": "7.039",
      "Open": "7.039",
      "High": "7.039",
      "Low": "7.039",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Sep-05",
      "Price": "7.039",
      "Open": "7.039",
      "High": "7.039",
      "Low": "7.039",
      "Change %": "-0.30%"
  },
  {
      "Date": "23-Sep-05",
      "Price": "7.06",
      "Open": "7.06",
      "High": "7.06",
      "Low": "7.06",
      "Change %": "0.21%"
  },
  {
      "Date": "22-Sep-05",
      "Price": "7.045",
      "Open": "7.045",
      "High": "7.045",
      "Low": "7.045",
      "Change %": "0.57%"
  },
  {
      "Date": "21-Sep-05",
      "Price": "7.005",
      "Open": "7.005",
      "High": "7.005",
      "Low": "7.005",
      "Change %": "0.00%"
  },
  {
      "Date": "20-Sep-05",
      "Price": "7.005",
      "Open": "7.005",
      "High": "7.005",
      "Low": "7.005",
      "Change %": "0.10%"
  },
  {
      "Date": "19-Sep-05",
      "Price": "6.998",
      "Open": "6.998",
      "High": "6.998",
      "Low": "6.998",
      "Change %": "-0.20%"
  },
  {
      "Date": "17-Sep-05",
      "Price": "7.012",
      "Open": "7.012",
      "High": "7.012",
      "Low": "7.012",
      "Change %": "-0.10%"
  },
  {
      "Date": "16-Sep-05",
      "Price": "7.019",
      "Open": "7.019",
      "High": "7.019",
      "Low": "7.019",
      "Change %": "0.29%"
  },
  {
      "Date": "15-Sep-05",
      "Price": "6.999",
      "Open": "6.999",
      "High": "6.999",
      "Low": "6.999",
      "Change %": "-0.20%"
  },
  {
      "Date": "14-Sep-05",
      "Price": "7.013",
      "Open": "7.013",
      "High": "7.013",
      "Low": "7.013",
      "Change %": "-0.07%"
  },
  {
      "Date": "13-Sep-05",
      "Price": "7.018",
      "Open": "7.018",
      "High": "7.018",
      "Low": "7.018",
      "Change %": "-0.18%"
  },
  {
      "Date": "12-Sep-05",
      "Price": "7.031",
      "Open": "7.031",
      "High": "7.031",
      "Low": "7.031",
      "Change %": "-0.04%"
  },
  {
      "Date": "10-Sep-05",
      "Price": "7.034",
      "Open": "7.034",
      "High": "7.034",
      "Low": "7.034",
      "Change %": "0.00%"
  },
  {
      "Date": "9-Sep-05",
      "Price": "7.034",
      "Open": "7.034",
      "High": "7.034",
      "Low": "7.034",
      "Change %": "0.00%"
  },
  {
      "Date": "8-Sep-05",
      "Price": "7.034",
      "Open": "7.034",
      "High": "7.034",
      "Low": "7.034",
      "Change %": "0.13%"
  },
  {
      "Date": "6-Sep-05",
      "Price": "7.025",
      "Open": "7.025",
      "High": "7.025",
      "Low": "7.025",
      "Change %": "-0.13%"
  },
  {
      "Date": "5-Sep-05",
      "Price": "7.034",
      "Open": "7.034",
      "High": "7.034",
      "Low": "7.034",
      "Change %": "0.01%"
  },
  {
      "Date": "3-Sep-05",
      "Price": "7.033",
      "Open": "7.033",
      "High": "7.033",
      "Low": "7.033",
      "Change %": "-0.14%"
  },
  {
      "Date": "2-Sep-05",
      "Price": "7.043",
      "Open": "7.043",
      "High": "7.043",
      "Low": "7.043",
      "Change %": "-0.56%"
  },
  {
      "Date": "1-Sep-05",
      "Price": "7.083",
      "Open": "7.083",
      "High": "7.083",
      "Low": "7.083",
      "Change %": "-0.16%"
  },
  {
      "Date": "31-Aug-05",
      "Price": "7.094",
      "Open": "7.094",
      "High": "7.094",
      "Low": "7.094",
      "Change %": "0.11%"
  },
  {
      "Date": "30-Aug-05",
      "Price": "7.086",
      "Open": "7.086",
      "High": "7.086",
      "Low": "7.086",
      "Change %": "-0.03%"
  },
  {
      "Date": "29-Aug-05",
      "Price": "7.088",
      "Open": "7.088",
      "High": "7.088",
      "Low": "7.088",
      "Change %": "0.27%"
  },
  {
      "Date": "27-Aug-05",
      "Price": "7.069",
      "Open": "7.069",
      "High": "7.069",
      "Low": "7.069",
      "Change %": "-0.17%"
  },
  {
      "Date": "26-Aug-05",
      "Price": "7.081",
      "Open": "7.081",
      "High": "7.081",
      "Low": "7.081",
      "Change %": "-0.13%"
  },
  {
      "Date": "25-Aug-05",
      "Price": "7.09",
      "Open": "7.09",
      "High": "7.09",
      "Low": "7.09",
      "Change %": "-0.10%"
  },
  {
      "Date": "24-Aug-05",
      "Price": "7.097",
      "Open": "7.097",
      "High": "7.097",
      "Low": "7.097",
      "Change %": "0.17%"
  },
  {
      "Date": "23-Aug-05",
      "Price": "7.085",
      "Open": "7.085",
      "High": "7.085",
      "Low": "7.085",
      "Change %": "0.16%"
  },
  {
      "Date": "22-Aug-05",
      "Price": "7.074",
      "Open": "7.074",
      "High": "7.074",
      "Low": "7.074",
      "Change %": "0.03%"
  },
  {
      "Date": "19-Aug-05",
      "Price": "7.072",
      "Open": "7.072",
      "High": "7.072",
      "Low": "7.072",
      "Change %": "0.03%"
  },
  {
      "Date": "18-Aug-05",
      "Price": "7.07",
      "Open": "7.07",
      "High": "7.07",
      "Low": "7.07",
      "Change %": "-0.10%"
  },
  {
      "Date": "17-Aug-05",
      "Price": "7.077",
      "Open": "7.077",
      "High": "7.077",
      "Low": "7.077",
      "Change %": "-0.03%"
  },
  {
      "Date": "16-Aug-05",
      "Price": "7.079",
      "Open": "7.079",
      "High": "7.079",
      "Low": "7.079",
      "Change %": "0.28%"
  },
  {
      "Date": "13-Aug-05",
      "Price": "7.059",
      "Open": "7.059",
      "High": "7.059",
      "Low": "7.059",
      "Change %": "0.44%"
  },
  {
      "Date": "12-Aug-05",
      "Price": "7.028",
      "Open": "7.028",
      "High": "7.028",
      "Low": "7.028",
      "Change %": "0.16%"
  },
  {
      "Date": "11-Aug-05",
      "Price": "7.017",
      "Open": "7.017",
      "High": "7.017",
      "Low": "7.017",
      "Change %": "-0.06%"
  },
  {
      "Date": "10-Aug-05",
      "Price": "7.021",
      "Open": "7.021",
      "High": "7.021",
      "Low": "7.021",
      "Change %": "-0.44%"
  },
  {
      "Date": "9-Aug-05",
      "Price": "7.052",
      "Open": "7.052",
      "High": "7.052",
      "Low": "7.052",
      "Change %": "0.61%"
  },
  {
      "Date": "8-Aug-05",
      "Price": "7.009",
      "Open": "7.009",
      "High": "7.009",
      "Low": "7.009",
      "Change %": "0.11%"
  },
  {
      "Date": "6-Aug-05",
      "Price": "7.001",
      "Open": "7.001",
      "High": "7.001",
      "Low": "7.001",
      "Change %": "0.20%"
  },
  {
      "Date": "5-Aug-05",
      "Price": "6.987",
      "Open": "6.987",
      "High": "6.987",
      "Low": "6.987",
      "Change %": "0.10%"
  },
  {
      "Date": "4-Aug-05",
      "Price": "6.98",
      "Open": "6.98",
      "High": "6.98",
      "Low": "6.98",
      "Change %": "0.09%"
  },
  {
      "Date": "3-Aug-05",
      "Price": "6.974",
      "Open": "6.974",
      "High": "6.974",
      "Low": "6.974",
      "Change %": "-0.09%"
  },
  {
      "Date": "2-Aug-05",
      "Price": "6.98",
      "Open": "6.98",
      "High": "6.98",
      "Low": "6.98",
      "Change %": "-0.50%"
  },
  {
      "Date": "1-Aug-05",
      "Price": "7.015",
      "Open": "7.015",
      "High": "7.015",
      "Low": "7.015",
      "Change %": "0.30%"
  },
  {
      "Date": "30-Jul-05",
      "Price": "6.994",
      "Open": "6.994",
      "High": "6.994",
      "Low": "6.994",
      "Change %": "0.49%"
  },
  {
      "Date": "29-Jul-05",
      "Price": "6.96",
      "Open": "6.96",
      "High": "6.96",
      "Low": "6.96",
      "Change %": "-0.56%"
  },
  {
      "Date": "26-Jul-05",
      "Price": "6.999",
      "Open": "6.999",
      "High": "6.999",
      "Low": "6.999",
      "Change %": "-2.32%"
  },
  {
      "Date": "25-Jul-05",
      "Price": "7.165",
      "Open": "7.165",
      "High": "7.165",
      "Low": "7.165",
      "Change %": "-0.13%"
  },
  {
      "Date": "23-Jul-05",
      "Price": "7.174",
      "Open": "7.174",
      "High": "7.174",
      "Low": "7.174",
      "Change %": "0.15%"
  },
  {
      "Date": "22-Jul-05",
      "Price": "7.163",
      "Open": "7.163",
      "High": "7.163",
      "Low": "7.163",
      "Change %": "0.07%"
  },
  {
      "Date": "21-Jul-05",
      "Price": "7.158",
      "Open": "7.158",
      "High": "7.158",
      "Low": "7.158",
      "Change %": "-0.22%"
  },
  {
      "Date": "20-Jul-05",
      "Price": "7.174",
      "Open": "7.174",
      "High": "7.174",
      "Low": "7.174",
      "Change %": "-0.10%"
  },
  {
      "Date": "19-Jul-05",
      "Price": "7.181",
      "Open": "7.181",
      "High": "7.181",
      "Low": "7.181",
      "Change %": "0.29%"
  },
  {
      "Date": "18-Jul-05",
      "Price": "7.16",
      "Open": "7.16",
      "High": "7.16",
      "Low": "7.16",
      "Change %": "-0.58%"
  },
  {
      "Date": "16-Jul-05",
      "Price": "7.202",
      "Open": "7.202",
      "High": "7.202",
      "Low": "7.202",
      "Change %": "0.45%"
  },
  {
      "Date": "15-Jul-05",
      "Price": "7.17",
      "Open": "7.17",
      "High": "7.17",
      "Low": "7.17",
      "Change %": "-0.53%"
  },
  {
      "Date": "14-Jul-05",
      "Price": "7.208",
      "Open": "7.208",
      "High": "7.208",
      "Low": "7.208",
      "Change %": "-0.26%"
  },
  {
      "Date": "13-Jul-05",
      "Price": "7.227",
      "Open": "7.227",
      "High": "7.227",
      "Low": "7.227",
      "Change %": "0.00%"
  },
  {
      "Date": "12-Jul-05",
      "Price": "7.227",
      "Open": "7.227",
      "High": "7.227",
      "Low": "7.227",
      "Change %": "-0.14%"
  },
  {
      "Date": "11-Jul-05",
      "Price": "7.237",
      "Open": "7.237",
      "High": "7.237",
      "Low": "7.237",
      "Change %": "0.68%"
  },
  {
      "Date": "9-Jul-05",
      "Price": "7.188",
      "Open": "7.188",
      "High": "7.188",
      "Low": "7.188",
      "Change %": "-0.07%"
  },
  {
      "Date": "8-Jul-05",
      "Price": "7.193",
      "Open": "7.193",
      "High": "7.193",
      "Low": "7.193",
      "Change %": "0.49%"
  },
  {
      "Date": "7-Jul-05",
      "Price": "7.158",
      "Open": "7.158",
      "High": "7.158",
      "Low": "7.158",
      "Change %": "0.55%"
  },
  {
      "Date": "6-Jul-05",
      "Price": "7.119",
      "Open": "7.119",
      "High": "7.119",
      "Low": "7.119",
      "Change %": "0.98%"
  },
  {
      "Date": "5-Jul-05",
      "Price": "7.05",
      "Open": "7.05",
      "High": "7.05",
      "Low": "7.05",
      "Change %": "-0.18%"
  },
  {
      "Date": "4-Jul-05",
      "Price": "7.063",
      "Open": "7.063",
      "High": "7.063",
      "Low": "7.063",
      "Change %": "-0.11%"
  },
  {
      "Date": "2-Jul-05",
      "Price": "7.071",
      "Open": "7.071",
      "High": "7.071",
      "Low": "7.071",
      "Change %": "1.57%"
  },
  {
      "Date": "1-Jul-05",
      "Price": "6.962",
      "Open": "6.962",
      "High": "6.962",
      "Low": "6.962",
      "Change %": "0.80%"
  },
  {
      "Date": "30-Jun-05",
      "Price": "6.907",
      "Open": "6.907",
      "High": "6.907",
      "Low": "6.907",
      "Change %": "0.19%"
  },
  {
      "Date": "29-Jun-05",
      "Price": "6.894",
      "Open": "6.894",
      "High": "6.894",
      "Low": "6.894",
      "Change %": "-0.10%"
  },
  {
      "Date": "28-Jun-05",
      "Price": "6.901",
      "Open": "6.901",
      "High": "6.901",
      "Low": "6.901",
      "Change %": "-0.49%"
  },
  {
      "Date": "27-Jun-05",
      "Price": "6.935",
      "Open": "6.935",
      "High": "6.935",
      "Low": "6.935",
      "Change %": "1.03%"
  },
  {
      "Date": "25-Jun-05",
      "Price": "6.864",
      "Open": "6.864",
      "High": "6.864",
      "Low": "6.864",
      "Change %": "-0.04%"
  },
  {
      "Date": "24-Jun-05",
      "Price": "6.867",
      "Open": "6.867",
      "High": "6.867",
      "Low": "6.867",
      "Change %": "0.69%"
  },
  {
      "Date": "23-Jun-05",
      "Price": "6.82",
      "Open": "6.82",
      "High": "6.82",
      "Low": "6.82",
      "Change %": "-0.20%"
  },
  {
      "Date": "22-Jun-05",
      "Price": "6.834",
      "Open": "6.834",
      "High": "6.834",
      "Low": "6.834",
      "Change %": "-0.58%"
  },
  {
      "Date": "21-Jun-05",
      "Price": "6.874",
      "Open": "6.874",
      "High": "6.874",
      "Low": "6.874",
      "Change %": "-0.88%"
  },
  {
      "Date": "20-Jun-05",
      "Price": "6.935",
      "Open": "6.935",
      "High": "6.935",
      "Low": "6.935",
      "Change %": "-0.20%"
  },
  {
      "Date": "18-Jun-05",
      "Price": "6.949",
      "Open": "6.949",
      "High": "6.949",
      "Low": "6.949",
      "Change %": "-0.19%"
  },
  {
      "Date": "17-Jun-05",
      "Price": "6.962",
      "Open": "6.962",
      "High": "6.962",
      "Low": "6.962",
      "Change %": "0.19%"
  },
  {
      "Date": "16-Jun-05",
      "Price": "6.949",
      "Open": "6.949",
      "High": "6.949",
      "Low": "6.949",
      "Change %": "0.68%"
  },
  {
      "Date": "15-Jun-05",
      "Price": "6.902",
      "Open": "6.902",
      "High": "6.902",
      "Low": "6.902",
      "Change %": "-0.19%"
  },
  {
      "Date": "14-Jun-05",
      "Price": "6.915",
      "Open": "6.915",
      "High": "6.915",
      "Low": "6.915",
      "Change %": "0.39%"
  },
  {
      "Date": "13-Jun-05",
      "Price": "6.888",
      "Open": "6.888",
      "High": "6.888",
      "Low": "6.888",
      "Change %": "0.19%"
  },
  {
      "Date": "11-Jun-05",
      "Price": "6.875",
      "Open": "6.875",
      "High": "6.875",
      "Low": "6.875",
      "Change %": "0.39%"
  },
  {
      "Date": "10-Jun-05",
      "Price": "6.848",
      "Open": "6.848",
      "High": "6.848",
      "Low": "6.848",
      "Change %": "0.29%"
  },
  {
      "Date": "9-Jun-05",
      "Price": "6.828",
      "Open": "6.828",
      "High": "6.828",
      "Low": "6.828",
      "Change %": "0.35%"
  },
  {
      "Date": "8-Jun-05",
      "Price": "6.804",
      "Open": "6.804",
      "High": "6.804",
      "Low": "6.804",
      "Change %": "-0.74%"
  },
  {
      "Date": "7-Jun-05",
      "Price": "6.855",
      "Open": "6.855",
      "High": "6.855",
      "Low": "6.855",
      "Change %": "-0.59%"
  },
  {
      "Date": "6-Jun-05",
      "Price": "6.896",
      "Open": "6.896",
      "High": "6.896",
      "Low": "6.896",
      "Change %": "-0.09%"
  },
  {
      "Date": "4-Jun-05",
      "Price": "6.902",
      "Open": "6.902",
      "High": "6.902",
      "Low": "6.902",
      "Change %": "0.09%"
  },
  {
      "Date": "3-Jun-05",
      "Price": "6.896",
      "Open": "6.896",
      "High": "6.896",
      "Low": "6.896",
      "Change %": "-0.35%"
  },
  {
      "Date": "2-Jun-05",
      "Price": "6.92",
      "Open": "6.92",
      "High": "6.92",
      "Low": "6.92",
      "Change %": "-0.04%"
  },
  {
      "Date": "1-Jun-05",
      "Price": "6.923",
      "Open": "6.923",
      "High": "6.923",
      "Low": "6.923",
      "Change %": "-0.80%"
  },
  {
      "Date": "31-May-05",
      "Price": "6.979",
      "Open": "6.979",
      "High": "6.979",
      "Low": "6.979",
      "Change %": "-0.33%"
  },
  {
      "Date": "30-May-05",
      "Price": "7.002",
      "Open": "7.002",
      "High": "7.002",
      "Low": "7.002",
      "Change %": "0.10%"
  },
  {
      "Date": "28-May-05",
      "Price": "6.995",
      "Open": "6.995",
      "High": "6.995",
      "Low": "6.995",
      "Change %": "0.34%"
  },
  {
      "Date": "27-May-05",
      "Price": "6.971",
      "Open": "6.971",
      "High": "6.971",
      "Low": "6.971",
      "Change %": "0.00%"
  },
  {
      "Date": "26-May-05",
      "Price": "6.971",
      "Open": "6.971",
      "High": "6.971",
      "Low": "6.971",
      "Change %": "0.68%"
  },
  {
      "Date": "25-May-05",
      "Price": "6.924",
      "Open": "6.924",
      "High": "6.924",
      "Low": "6.924",
      "Change %": "0.10%"
  },
  {
      "Date": "24-May-05",
      "Price": "6.917",
      "Open": "6.917",
      "High": "6.917",
      "Low": "6.917",
      "Change %": "-1.64%"
  },
  {
      "Date": "21-May-05",
      "Price": "7.032",
      "Open": "7.032",
      "High": "7.032",
      "Low": "7.032",
      "Change %": "0.39%"
  },
  {
      "Date": "20-May-05",
      "Price": "7.005",
      "Open": "7.005",
      "High": "7.005",
      "Low": "7.005",
      "Change %": "-0.86%"
  },
  {
      "Date": "19-May-05",
      "Price": "7.066",
      "Open": "7.066",
      "High": "7.066",
      "Low": "7.066",
      "Change %": "-1.34%"
  },
  {
      "Date": "18-May-05",
      "Price": "7.162",
      "Open": "7.162",
      "High": "7.162",
      "Low": "7.162",
      "Change %": "0.01%"
  },
  {
      "Date": "17-May-05",
      "Price": "7.161",
      "Open": "7.161",
      "High": "7.161",
      "Low": "7.161",
      "Change %": "-0.50%"
  },
  {
      "Date": "16-May-05",
      "Price": "7.197",
      "Open": "7.197",
      "High": "7.197",
      "Low": "7.197",
      "Change %": "0.39%"
  },
  {
      "Date": "14-May-05",
      "Price": "7.169",
      "Open": "7.169",
      "High": "7.169",
      "Low": "7.169",
      "Change %": "0.00%"
  },
  {
      "Date": "13-May-05",
      "Price": "7.169",
      "Open": "7.169",
      "High": "7.169",
      "Low": "7.169",
      "Change %": "-0.35%"
  },
  {
      "Date": "12-May-05",
      "Price": "7.194",
      "Open": "7.194",
      "High": "7.194",
      "Low": "7.194",
      "Change %": "-0.14%"
  },
  {
      "Date": "11-May-05",
      "Price": "7.204",
      "Open": "7.204",
      "High": "7.204",
      "Low": "7.204",
      "Change %": "0.00%"
  },
  {
      "Date": "10-May-05",
      "Price": "7.204",
      "Open": "7.204",
      "High": "7.204",
      "Low": "7.204",
      "Change %": "-0.66%"
  },
  {
      "Date": "9-May-05",
      "Price": "7.252",
      "Open": "7.252",
      "High": "7.252",
      "Low": "7.252",
      "Change %": "0.00%"
  },
  {
      "Date": "7-May-05",
      "Price": "7.252",
      "Open": "7.252",
      "High": "7.252",
      "Low": "7.252",
      "Change %": "-0.11%"
  },
  {
      "Date": "6-May-05",
      "Price": "7.26",
      "Open": "7.26",
      "High": "7.26",
      "Low": "7.26",
      "Change %": "0.29%"
  },
  {
      "Date": "5-May-05",
      "Price": "7.239",
      "Open": "7.239",
      "High": "7.239",
      "Low": "7.239",
      "Change %": "0.10%"
  },
  {
      "Date": "4-May-05",
      "Price": "7.232",
      "Open": "7.232",
      "High": "7.232",
      "Low": "7.232",
      "Change %": "-0.10%"
  },
  {
      "Date": "3-May-05",
      "Price": "7.239",
      "Open": "7.239",
      "High": "7.239",
      "Low": "7.239",
      "Change %": "-0.85%"
  },
  {
      "Date": "2-May-05",
      "Price": "7.301",
      "Open": "7.301",
      "High": "7.301",
      "Low": "7.301",
      "Change %": "-0.76%"
  },
  {
      "Date": "30-Apr-05",
      "Price": "7.357",
      "Open": "7.357",
      "High": "7.357",
      "Low": "7.357",
      "Change %": "1.63%"
  },
  {
      "Date": "29-Apr-05",
      "Price": "7.239",
      "Open": "7.239",
      "High": "7.239",
      "Low": "7.239",
      "Change %": "0.19%"
  },
  {
      "Date": "28-Apr-05",
      "Price": "7.225",
      "Open": "7.225",
      "High": "7.225",
      "Low": "7.225",
      "Change %": "1.62%"
  },
  {
      "Date": "27-Apr-05",
      "Price": "7.11",
      "Open": "7.11",
      "High": "7.11",
      "Low": "7.11",
      "Change %": "-0.18%"
  },
  {
      "Date": "26-Apr-05",
      "Price": "7.123",
      "Open": "7.123",
      "High": "7.123",
      "Low": "7.123",
      "Change %": "0.00%"
  },
  {
      "Date": "25-Apr-05",
      "Price": "7.123",
      "Open": "7.123",
      "High": "7.123",
      "Low": "7.123",
      "Change %": "0.30%"
  },
  {
      "Date": "23-Apr-05",
      "Price": "7.102",
      "Open": "7.102",
      "High": "7.102",
      "Low": "7.102",
      "Change %": "0.28%"
  },
  {
      "Date": "21-Apr-05",
      "Price": "7.082",
      "Open": "7.082",
      "High": "7.082",
      "Low": "7.082",
      "Change %": "0.00%"
  },
  {
      "Date": "20-Apr-05",
      "Price": "7.082",
      "Open": "7.082",
      "High": "7.082",
      "Low": "7.082",
      "Change %": "0.16%"
  },
  {
      "Date": "19-Apr-05",
      "Price": "7.071",
      "Open": "7.071",
      "High": "7.071",
      "Low": "7.071",
      "Change %": "-0.25%"
  },
  {
      "Date": "16-Apr-05",
      "Price": "7.089",
      "Open": "7.089",
      "High": "7.089",
      "Low": "7.089",
      "Change %": "1.39%"
  },
  {
      "Date": "15-Apr-05",
      "Price": "6.992",
      "Open": "6.992",
      "High": "6.992",
      "Low": "6.992",
      "Change %": "-0.14%"
  },
  {
      "Date": "13-Apr-05",
      "Price": "7.002",
      "Open": "7.002",
      "High": "7.002",
      "Low": "7.002",
      "Change %": "0.00%"
  },
  {
      "Date": "12-Apr-05",
      "Price": "7.002",
      "Open": "7.002",
      "High": "7.002",
      "Low": "7.002",
      "Change %": "0.00%"
  },
  {
      "Date": "11-Apr-05",
      "Price": "7.002",
      "Open": "7.002",
      "High": "7.002",
      "Low": "7.002",
      "Change %": "-0.19%"
  },
  {
      "Date": "8-Apr-05",
      "Price": "7.015",
      "Open": "7.015",
      "High": "7.015",
      "Low": "7.015",
      "Change %": "0.57%"
  },
  {
      "Date": "7-Apr-05",
      "Price": "6.975",
      "Open": "6.975",
      "High": "6.975",
      "Low": "6.975",
      "Change %": "2.24%"
  },
  {
      "Date": "6-Apr-05",
      "Price": "6.822",
      "Open": "6.822",
      "High": "6.822",
      "Low": "6.822",
      "Change %": "1.37%"
  },
  {
      "Date": "5-Apr-05",
      "Price": "6.73",
      "Open": "6.73",
      "High": "6.73",
      "Low": "6.73",
      "Change %": "-0.30%"
  },
  {
      "Date": "4-Apr-05",
      "Price": "6.75",
      "Open": "6.75",
      "High": "6.75",
      "Low": "6.75",
      "Change %": "0.30%"
  },
  {
      "Date": "2-Apr-05",
      "Price": "6.73",
      "Open": "6.73",
      "High": "6.73",
      "Low": "6.73",
      "Change %": "0.78%"
  },
  {
      "Date": "1-Apr-05",
      "Price": "6.678",
      "Open": "6.678",
      "High": "6.678",
      "Low": "6.678",
      "Change %": "0.03%"
  },
  {
      "Date": "31-Mar-05",
      "Price": "6.676",
      "Open": "6.676",
      "High": "6.676",
      "Low": "6.676",
      "Change %": "0.12%"
  },
  {
      "Date": "30-Mar-05",
      "Price": "6.668",
      "Open": "6.668",
      "High": "6.668",
      "Low": "6.668",
      "Change %": "0.03%"
  },
  {
      "Date": "29-Mar-05",
      "Price": "6.666",
      "Open": "6.666",
      "High": "6.666",
      "Low": "6.666",
      "Change %": "0.20%"
  },
  {
      "Date": "28-Mar-05",
      "Price": "6.653",
      "Open": "6.653",
      "High": "6.653",
      "Low": "6.653",
      "Change %": "-0.30%"
  },
  {
      "Date": "24-Mar-05",
      "Price": "6.673",
      "Open": "6.673",
      "High": "6.673",
      "Low": "6.673",
      "Change %": "-0.82%"
  },
  {
      "Date": "23-Mar-05",
      "Price": "6.728",
      "Open": "6.728",
      "High": "6.728",
      "Low": "6.728",
      "Change %": "0.19%"
  },
  {
      "Date": "22-Mar-05",
      "Price": "6.715",
      "Open": "6.715",
      "High": "6.715",
      "Low": "6.715",
      "Change %": "0.09%"
  },
  {
      "Date": "21-Mar-05",
      "Price": "6.709",
      "Open": "6.709",
      "High": "6.709",
      "Low": "6.709",
      "Change %": "0.68%"
  },
  {
      "Date": "19-Mar-05",
      "Price": "6.664",
      "Open": "6.664",
      "High": "6.664",
      "Low": "6.664",
      "Change %": "0.05%"
  },
  {
      "Date": "18-Mar-05",
      "Price": "6.661",
      "Open": "6.661",
      "High": "6.661",
      "Low": "6.661",
      "Change %": "-0.49%"
  },
  {
      "Date": "17-Mar-05",
      "Price": "6.694",
      "Open": "6.694",
      "High": "6.694",
      "Low": "6.694",
      "Change %": "1.03%"
  },
  {
      "Date": "16-Mar-05",
      "Price": "6.626",
      "Open": "6.626",
      "High": "6.626",
      "Low": "6.626",
      "Change %": "0.05%"
  },
  {
      "Date": "15-Mar-05",
      "Price": "6.623",
      "Open": "6.623",
      "High": "6.623",
      "Low": "6.623",
      "Change %": "0.75%"
  },
  {
      "Date": "14-Mar-05",
      "Price": "6.574",
      "Open": "6.574",
      "High": "6.574",
      "Low": "6.574",
      "Change %": "0.18%"
  },
  {
      "Date": "12-Mar-05",
      "Price": "6.562",
      "Open": "6.562",
      "High": "6.562",
      "Low": "6.562",
      "Change %": "0.05%"
  },
  {
      "Date": "11-Mar-05",
      "Price": "6.559",
      "Open": "6.559",
      "High": "6.559",
      "Low": "6.559",
      "Change %": "-0.29%"
  },
  {
      "Date": "10-Mar-05",
      "Price": "6.578",
      "Open": "6.578",
      "High": "6.578",
      "Low": "6.578",
      "Change %": "0.43%"
  },
  {
      "Date": "9-Mar-05",
      "Price": "6.55",
      "Open": "6.55",
      "High": "6.55",
      "Low": "6.55",
      "Change %": "0.26%"
  },
  {
      "Date": "7-Mar-05",
      "Price": "6.533",
      "Open": "6.533",
      "High": "6.533",
      "Low": "6.533",
      "Change %": "-0.68%"
  },
  {
      "Date": "5-Mar-05",
      "Price": "6.578",
      "Open": "6.578",
      "High": "6.578",
      "Low": "6.578",
      "Change %": "-0.71%"
  },
  {
      "Date": "4-Mar-05",
      "Price": "6.625",
      "Open": "6.625",
      "High": "6.625",
      "Low": "6.625",
      "Change %": "0.08%"
  },
  {
      "Date": "3-Mar-05",
      "Price": "6.62",
      "Open": "6.62",
      "High": "6.62",
      "Low": "6.62",
      "Change %": "-0.21%"
  },
  {
      "Date": "2-Mar-05",
      "Price": "6.634",
      "Open": "6.634",
      "High": "6.634",
      "Low": "6.634",
      "Change %": "-0.26%"
  },
  {
      "Date": "1-Mar-05",
      "Price": "6.651",
      "Open": "6.651",
      "High": "6.651",
      "Low": "6.651",
      "Change %": "1.56%"
  },
  {
      "Date": "28-Feb-05",
      "Price": "6.549",
      "Open": "6.549",
      "High": "6.549",
      "Low": "6.549",
      "Change %": "1.17%"
  },
  {
      "Date": "26-Feb-05",
      "Price": "6.473",
      "Open": "6.473",
      "High": "6.473",
      "Low": "6.473",
      "Change %": "-0.34%"
  },
  {
      "Date": "25-Feb-05",
      "Price": "6.495",
      "Open": "6.495",
      "High": "6.495",
      "Low": "6.495",
      "Change %": "-0.17%"
  },
  {
      "Date": "24-Feb-05",
      "Price": "6.506",
      "Open": "6.506",
      "High": "6.506",
      "Low": "6.506",
      "Change %": "-0.02%"
  },
  {
      "Date": "23-Feb-05",
      "Price": "6.507",
      "Open": "6.507",
      "High": "6.507",
      "Low": "6.507",
      "Change %": "0.08%"
  },
  {
      "Date": "22-Feb-05",
      "Price": "6.502",
      "Open": "6.502",
      "High": "6.502",
      "Low": "6.502",
      "Change %": "-0.09%"
  },
  {
      "Date": "21-Feb-05",
      "Price": "6.508",
      "Open": "6.508",
      "High": "6.508",
      "Low": "6.508",
      "Change %": "-0.06%"
  },
  {
      "Date": "19-Feb-05",
      "Price": "6.512",
      "Open": "6.512",
      "High": "6.512",
      "Low": "6.512",
      "Change %": "0.22%"
  },
  {
      "Date": "18-Feb-05",
      "Price": "6.498",
      "Open": "6.498",
      "High": "6.498",
      "Low": "6.498",
      "Change %": "-0.14%"
  },
  {
      "Date": "17-Feb-05",
      "Price": "6.507",
      "Open": "6.507",
      "High": "6.507",
      "Low": "6.507",
      "Change %": "-0.38%"
  },
  {
      "Date": "16-Feb-05",
      "Price": "6.532",
      "Open": "6.532",
      "High": "6.532",
      "Low": "6.532",
      "Change %": "0.25%"
  },
  {
      "Date": "15-Feb-05",
      "Price": "6.516",
      "Open": "6.516",
      "High": "6.516",
      "Low": "6.516",
      "Change %": "0.26%"
  },
  {
      "Date": "14-Feb-05",
      "Price": "6.499",
      "Open": "6.499",
      "High": "6.499",
      "Low": "6.499",
      "Change %": "0.70%"
  },
  {
      "Date": "12-Feb-05",
      "Price": "6.454",
      "Open": "6.454",
      "High": "6.454",
      "Low": "6.454",
      "Change %": "0.03%"
  },
  {
      "Date": "11-Feb-05",
      "Price": "6.452",
      "Open": "6.452",
      "High": "6.452",
      "Low": "6.452",
      "Change %": "-0.43%"
  },
  {
      "Date": "10-Feb-05",
      "Price": "6.48",
      "Open": "6.48",
      "High": "6.48",
      "Low": "6.48",
      "Change %": "-0.83%"
  },
  {
      "Date": "9-Feb-05",
      "Price": "6.534",
      "Open": "6.534",
      "High": "6.534",
      "Low": "6.534",
      "Change %": "-0.15%"
  },
  {
      "Date": "8-Feb-05",
      "Price": "6.544",
      "Open": "6.544",
      "High": "6.544",
      "Low": "6.544",
      "Change %": "-0.58%"
  },
  {
      "Date": "7-Feb-05",
      "Price": "6.582",
      "Open": "6.582",
      "High": "6.582",
      "Low": "6.582",
      "Change %": "-1.97%"
  },
  {
      "Date": "5-Feb-05",
      "Price": "6.714",
      "Open": "6.714",
      "High": "6.714",
      "Low": "6.714",
      "Change %": "-0.25%"
  },
  {
      "Date": "4-Feb-05",
      "Price": "6.731",
      "Open": "6.731",
      "High": "6.731",
      "Low": "6.731",
      "Change %": "0.06%"
  },
  {
      "Date": "3-Feb-05",
      "Price": "6.727",
      "Open": "6.727",
      "High": "6.727",
      "Low": "6.727",
      "Change %": "0.72%"
  },
  {
      "Date": "2-Feb-05",
      "Price": "6.679",
      "Open": "6.679",
      "High": "6.679",
      "Low": "6.679",
      "Change %": "-0.65%"
  },
  {
      "Date": "1-Feb-05",
      "Price": "6.723",
      "Open": "6.723",
      "High": "6.723",
      "Low": "6.723",
      "Change %": "0.30%"
  },
  {
      "Date": "31-Jan-05",
      "Price": "6.703",
      "Open": "6.703",
      "High": "6.703",
      "Low": "6.703",
      "Change %": "-0.13%"
  },
  {
      "Date": "29-Jan-05",
      "Price": "6.712",
      "Open": "6.712",
      "High": "6.712",
      "Low": "6.712",
      "Change %": "-0.36%"
  },
  {
      "Date": "28-Jan-05",
      "Price": "6.736",
      "Open": "6.736",
      "High": "6.736",
      "Low": "6.736",
      "Change %": "-0.30%"
  },
  {
      "Date": "27-Jan-05",
      "Price": "6.756",
      "Open": "6.756",
      "High": "6.756",
      "Low": "6.756",
      "Change %": "0.01%"
  },
  {
      "Date": "25-Jan-05",
      "Price": "6.755",
      "Open": "6.755",
      "High": "6.755",
      "Low": "6.755",
      "Change %": "0.10%"
  },
  {
      "Date": "24-Jan-05",
      "Price": "6.748",
      "Open": "6.748",
      "High": "6.748",
      "Low": "6.748",
      "Change %": "0.15%"
  },
  {
      "Date": "22-Jan-05",
      "Price": "6.738",
      "Open": "6.738",
      "High": "6.738",
      "Low": "6.738",
      "Change %": "0.28%"
  },
  {
      "Date": "20-Jan-05",
      "Price": "6.719",
      "Open": "6.719",
      "High": "6.719",
      "Low": "6.719",
      "Change %": "-0.58%"
  },
  {
      "Date": "19-Jan-05",
      "Price": "6.758",
      "Open": "6.758",
      "High": "6.758",
      "Low": "6.758",
      "Change %": "0.61%"
  },
  {
      "Date": "18-Jan-05",
      "Price": "6.717",
      "Open": "6.717",
      "High": "6.717",
      "Low": "6.717",
      "Change %": "0.40%"
  },
  {
      "Date": "17-Jan-05",
      "Price": "6.69",
      "Open": "6.69",
      "High": "6.69",
      "Low": "6.69",
      "Change %": "-0.54%"
  },
  {
      "Date": "15-Jan-05",
      "Price": "6.726",
      "Open": "6.726",
      "High": "6.726",
      "Low": "6.726",
      "Change %": "1.17%"
  },
  {
      "Date": "14-Jan-05",
      "Price": "6.648",
      "Open": "6.648",
      "High": "6.648",
      "Low": "6.648",
      "Change %": "1.25%"
  },
  {
      "Date": "13-Jan-05",
      "Price": "6.566",
      "Open": "6.566",
      "High": "6.566",
      "Low": "6.566",
      "Change %": "-0.23%"
  },
  {
      "Date": "12-Jan-05",
      "Price": "6.581",
      "Open": "6.581",
      "High": "6.581",
      "Low": "6.581",
      "Change %": "0.41%"
  },
  {
      "Date": "11-Jan-05",
      "Price": "6.554",
      "Open": "6.554",
      "High": "6.554",
      "Low": "6.554",
      "Change %": "-0.52%"
  },
  {
      "Date": "10-Jan-05",
      "Price": "6.588",
      "Open": "6.588",
      "High": "6.588",
      "Low": "6.588",
      "Change %": "0.34%"
  },
  {
      "Date": "8-Jan-05",
      "Price": "6.566",
      "Open": "6.566",
      "High": "6.566",
      "Low": "6.566",
      "Change %": "0.27%"
  },
  {
      "Date": "7-Jan-05",
      "Price": "6.548",
      "Open": "6.548",
      "High": "6.548",
      "Low": "6.548",
      "Change %": "-0.86%"
  },
  {
      "Date": "6-Jan-05",
      "Price": "6.605",
      "Open": "6.605",
      "High": "6.605",
      "Low": "6.605",
      "Change %": "0.58%"
  },
  {
      "Date": "5-Jan-05",
      "Price": "6.567",
      "Open": "6.567",
      "High": "6.567",
      "Low": "6.567",
      "Change %": "1.26%"
  },
  {
      "Date": "4-Jan-05",
      "Price": "6.485",
      "Open": "6.485",
      "High": "6.485",
      "Low": "6.485",
      "Change %": "-0.81%"
  },
  {
      "Date": "3-Jan-05",
      "Price": "6.538",
      "Open": "6.538",
      "High": "6.538",
      "Low": "6.538",
      "Change %": "-3.78%"
  },
  {
      "Date": "1-Jan-05",
      "Price": "6.795",
      "Open": "6.795",
      "High": "6.795",
      "Low": "6.795",
      "Change %": "1.33%"
  },
  {
      "Date": "31-Dec-04",
      "Price": "6.706",
      "Open": "6.706",
      "High": "6.706",
      "Low": "6.706",
      "Change %": "1.15%"
  },
  {
      "Date": "30-Dec-04",
      "Price": "6.63",
      "Open": "6.63",
      "High": "6.63",
      "Low": "6.63",
      "Change %": "0.53%"
  },
  {
      "Date": "29-Dec-04",
      "Price": "6.595",
      "Open": "6.595",
      "High": "6.595",
      "Low": "6.595",
      "Change %": "-0.02%"
  },
  {
      "Date": "28-Dec-04",
      "Price": "6.596",
      "Open": "6.596",
      "High": "6.596",
      "Low": "6.596",
      "Change %": "-1.05%"
  },
  {
      "Date": "27-Dec-04",
      "Price": "6.666",
      "Open": "6.666",
      "High": "6.666",
      "Low": "6.666",
      "Change %": "-0.01%"
  },
  {
      "Date": "25-Dec-04",
      "Price": "6.667",
      "Open": "6.667",
      "High": "6.667",
      "Low": "6.667",
      "Change %": "0.08%"
  },
  {
      "Date": "24-Dec-04",
      "Price": "6.662",
      "Open": "6.662",
      "High": "6.662",
      "Low": "6.662",
      "Change %": "-0.09%"
  },
  {
      "Date": "23-Dec-04",
      "Price": "6.668",
      "Open": "6.668",
      "High": "6.668",
      "Low": "6.668",
      "Change %": "-0.64%"
  },
  {
      "Date": "22-Dec-04",
      "Price": "6.711",
      "Open": "6.711",
      "High": "6.711",
      "Low": "6.711",
      "Change %": "-0.61%"
  },
  {
      "Date": "21-Dec-04",
      "Price": "6.752",
      "Open": "6.752",
      "High": "6.752",
      "Low": "6.752",
      "Change %": "-0.63%"
  },
  {
      "Date": "20-Dec-04",
      "Price": "6.795",
      "Open": "6.795",
      "High": "6.795",
      "Low": "6.795",
      "Change %": "-0.12%"
  },
  {
      "Date": "18-Dec-04",
      "Price": "6.803",
      "Open": "6.803",
      "High": "6.803",
      "Low": "6.803",
      "Change %": "0.09%"
  },
  {
      "Date": "17-Dec-04",
      "Price": "6.797",
      "Open": "6.797",
      "High": "6.797",
      "Low": "6.797",
      "Change %": "-0.21%"
  },
  {
      "Date": "16-Dec-04",
      "Price": "6.811",
      "Open": "6.811",
      "High": "6.811",
      "Low": "6.811",
      "Change %": "0.04%"
  },
  {
      "Date": "15-Dec-04",
      "Price": "6.808",
      "Open": "6.808",
      "High": "6.808",
      "Low": "6.808",
      "Change %": "0.37%"
  },
  {
      "Date": "14-Dec-04",
      "Price": "6.783",
      "Open": "6.783",
      "High": "6.783",
      "Low": "6.783",
      "Change %": "0.43%"
  },
  {
      "Date": "13-Dec-04",
      "Price": "6.754",
      "Open": "6.754",
      "High": "6.754",
      "Low": "6.754",
      "Change %": "1.49%"
  },
  {
      "Date": "11-Dec-04",
      "Price": "6.655",
      "Open": "6.655",
      "High": "6.655",
      "Low": "6.655",
      "Change %": "-0.43%"
  },
  {
      "Date": "10-Dec-04",
      "Price": "6.684",
      "Open": "6.684",
      "High": "6.684",
      "Low": "6.684",
      "Change %": "-0.80%"
  },
  {
      "Date": "9-Dec-04",
      "Price": "6.738",
      "Open": "6.738",
      "High": "6.738",
      "Low": "6.738",
      "Change %": "-0.18%"
  },
  {
      "Date": "8-Dec-04",
      "Price": "6.75",
      "Open": "6.75",
      "High": "6.75",
      "Low": "6.75",
      "Change %": "-0.16%"
  },
  {
      "Date": "7-Dec-04",
      "Price": "6.761",
      "Open": "6.761",
      "High": "6.761",
      "Low": "6.761",
      "Change %": "0.07%"
  },
  {
      "Date": "6-Dec-04",
      "Price": "6.756",
      "Open": "6.756",
      "High": "6.756",
      "Low": "6.756",
      "Change %": "0.54%"
  },
  {
      "Date": "4-Dec-04",
      "Price": "6.72",
      "Open": "6.72",
      "High": "6.72",
      "Low": "6.72",
      "Change %": "-1.32%"
  },
  {
      "Date": "3-Dec-04",
      "Price": "6.81",
      "Open": "6.81",
      "High": "6.81",
      "Low": "6.81",
      "Change %": "-0.03%"
  },
  {
      "Date": "2-Dec-04",
      "Price": "6.812",
      "Open": "6.812",
      "High": "6.812",
      "Low": "6.812",
      "Change %": "-1.06%"
  },
  {
      "Date": "1-Dec-04",
      "Price": "6.885",
      "Open": "6.885",
      "High": "6.885",
      "Low": "6.885",
      "Change %": "-4.34%"
  },
  {
      "Date": "30-Nov-04",
      "Price": "7.197",
      "Open": "7.197",
      "High": "7.197",
      "Low": "7.197",
      "Change %": "-0.17%"
  },
  {
      "Date": "29-Nov-04",
      "Price": "7.209",
      "Open": "7.209",
      "High": "7.209",
      "Low": "7.209",
      "Change %": "0.36%"
  },
  {
      "Date": "27-Nov-04",
      "Price": "7.183",
      "Open": "7.183",
      "High": "7.183",
      "Low": "7.183",
      "Change %": "-0.44%"
  },
  {
      "Date": "25-Nov-04",
      "Price": "7.215",
      "Open": "7.215",
      "High": "7.215",
      "Low": "7.215",
      "Change %": "-0.04%"
  },
  {
      "Date": "24-Nov-04",
      "Price": "7.218",
      "Open": "7.218",
      "High": "7.218",
      "Low": "7.218",
      "Change %": "1.11%"
  },
  {
      "Date": "23-Nov-04",
      "Price": "7.139",
      "Open": "7.139",
      "High": "7.139",
      "Low": "7.139",
      "Change %": "0.92%"
  },
  {
      "Date": "22-Nov-04",
      "Price": "7.074",
      "Open": "7.074",
      "High": "7.074",
      "Low": "7.074",
      "Change %": "-0.20%"
  },
  {
      "Date": "20-Nov-04",
      "Price": "7.088",
      "Open": "7.088",
      "High": "7.088",
      "Low": "7.088",
      "Change %": "-0.42%"
  },
  {
      "Date": "19-Nov-04",
      "Price": "7.118",
      "Open": "7.118",
      "High": "7.118",
      "Low": "7.118",
      "Change %": "0.00%"
  },
  {
      "Date": "18-Nov-04",
      "Price": "7.118",
      "Open": "7.118",
      "High": "7.118",
      "Low": "7.118",
      "Change %": "-0.61%"
  },
  {
      "Date": "17-Nov-04",
      "Price": "7.162",
      "Open": "7.162",
      "High": "7.162",
      "Low": "7.162",
      "Change %": "0.21%"
  },
  {
      "Date": "16-Nov-04",
      "Price": "7.147",
      "Open": "7.147",
      "High": "7.147",
      "Low": "7.147",
      "Change %": "-0.40%"
  },
  {
      "Date": "13-Nov-04",
      "Price": "7.176",
      "Open": "7.176",
      "High": "7.176",
      "Low": "7.176",
      "Change %": "-0.01%"
  },
  {
      "Date": "11-Nov-04",
      "Price": "7.177",
      "Open": "7.177",
      "High": "7.177",
      "Low": "7.177",
      "Change %": "0.31%"
  },
  {
      "Date": "10-Nov-04",
      "Price": "7.155",
      "Open": "7.155",
      "High": "7.155",
      "Low": "7.155",
      "Change %": "-0.31%"
  },
  {
      "Date": "9-Nov-04",
      "Price": "7.177",
      "Open": "7.177",
      "High": "7.177",
      "Low": "7.177",
      "Change %": "-1.21%"
  },
  {
      "Date": "8-Nov-04",
      "Price": "7.265",
      "Open": "7.265",
      "High": "7.265",
      "Low": "7.265",
      "Change %": "0.61%"
  },
  {
      "Date": "6-Nov-04",
      "Price": "7.221",
      "Open": "7.221",
      "High": "7.221",
      "Low": "7.221",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Nov-04",
      "Price": "7.221",
      "Open": "7.221",
      "High": "7.221",
      "Low": "7.221",
      "Change %": "3.96%"
  },
  {
      "Date": "4-Nov-04",
      "Price": "6.946",
      "Open": "6.946",
      "High": "6.946",
      "Low": "6.946",
      "Change %": "0.32%"
  },
  {
      "Date": "3-Nov-04",
      "Price": "6.924",
      "Open": "6.924",
      "High": "6.924",
      "Low": "6.924",
      "Change %": "-0.10%"
  },
  {
      "Date": "2-Nov-04",
      "Price": "6.931",
      "Open": "6.931",
      "High": "6.931",
      "Low": "6.931",
      "Change %": "-0.22%"
  },
  {
      "Date": "1-Nov-04",
      "Price": "6.946",
      "Open": "6.946",
      "High": "6.946",
      "Low": "6.946",
      "Change %": "0.20%"
  },
  {
      "Date": "30-Oct-04",
      "Price": "6.932",
      "Open": "6.932",
      "High": "6.932",
      "Low": "6.932",
      "Change %": "1.03%"
  },
  {
      "Date": "29-Oct-04",
      "Price": "6.861",
      "Open": "6.861",
      "High": "6.861",
      "Low": "6.861",
      "Change %": "-1.02%"
  },
  {
      "Date": "28-Oct-04",
      "Price": "6.932",
      "Open": "6.932",
      "High": "6.932",
      "Low": "6.932",
      "Change %": "0.73%"
  },
  {
      "Date": "27-Oct-04",
      "Price": "6.882",
      "Open": "6.882",
      "High": "6.882",
      "Low": "6.882",
      "Change %": "0.61%"
  },
  {
      "Date": "26-Oct-04",
      "Price": "6.84",
      "Open": "6.84",
      "High": "6.84",
      "Low": "6.84",
      "Change %": "1.89%"
  },
  {
      "Date": "25-Oct-04",
      "Price": "6.713",
      "Open": "6.713",
      "High": "6.713",
      "Low": "6.713",
      "Change %": "1.27%"
  },
  {
      "Date": "23-Oct-04",
      "Price": "6.629",
      "Open": "6.629",
      "High": "6.629",
      "Low": "6.629",
      "Change %": "-1.47%"
  },
  {
      "Date": "21-Oct-04",
      "Price": "6.728",
      "Open": "6.728",
      "High": "6.728",
      "Low": "6.728",
      "Change %": "-0.52%"
  },
  {
      "Date": "20-Oct-04",
      "Price": "6.763",
      "Open": "6.763",
      "High": "6.763",
      "Low": "6.763",
      "Change %": "-0.21%"
  },
  {
      "Date": "19-Oct-04",
      "Price": "6.777",
      "Open": "6.777",
      "High": "6.777",
      "Low": "6.777",
      "Change %": "0.21%"
  },
  {
      "Date": "18-Oct-04",
      "Price": "6.763",
      "Open": "6.763",
      "High": "6.763",
      "Low": "6.763",
      "Change %": "-0.41%"
  },
  {
      "Date": "16-Oct-04",
      "Price": "6.791",
      "Open": "6.791",
      "High": "6.791",
      "Low": "6.791",
      "Change %": "0.40%"
  },
  {
      "Date": "15-Oct-04",
      "Price": "6.764",
      "Open": "6.764",
      "High": "6.764",
      "Low": "6.764",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Oct-04",
      "Price": "6.764",
      "Open": "6.764",
      "High": "6.764",
      "Low": "6.764",
      "Change %": "-0.76%"
  },
  {
      "Date": "12-Oct-04",
      "Price": "6.816",
      "Open": "6.816",
      "High": "6.816",
      "Low": "6.816",
      "Change %": "0.35%"
  },
  {
      "Date": "11-Oct-04",
      "Price": "6.792",
      "Open": "6.792",
      "High": "6.792",
      "Low": "6.792",
      "Change %": "1.68%"
  },
  {
      "Date": "9-Oct-04",
      "Price": "6.68",
      "Open": "6.68",
      "High": "6.68",
      "Low": "6.68",
      "Change %": "0.10%"
  },
  {
      "Date": "8-Oct-04",
      "Price": "6.673",
      "Open": "6.673",
      "High": "6.673",
      "Low": "6.673",
      "Change %": "1.15%"
  },
  {
      "Date": "7-Oct-04",
      "Price": "6.597",
      "Open": "6.597",
      "High": "6.597",
      "Low": "6.597",
      "Change %": "1.27%"
  },
  {
      "Date": "6-Oct-04",
      "Price": "6.514",
      "Open": "6.514",
      "High": "6.514",
      "Low": "6.514",
      "Change %": "0.25%"
  },
  {
      "Date": "5-Oct-04",
      "Price": "6.498",
      "Open": "6.498",
      "High": "6.498",
      "Low": "6.498",
      "Change %": "0.81%"
  },
  {
      "Date": "4-Oct-04",
      "Price": "6.446",
      "Open": "6.446",
      "High": "6.446",
      "Low": "6.446",
      "Change %": "0.84%"
  },
  {
      "Date": "1-Oct-04",
      "Price": "6.392",
      "Open": "6.392",
      "High": "6.392",
      "Low": "6.392",
      "Change %": "2.37%"
  },
  {
      "Date": "30-Sep-04",
      "Price": "6.244",
      "Open": "6.244",
      "High": "6.244",
      "Low": "6.244",
      "Change %": "0.21%"
  },
  {
      "Date": "29-Sep-04",
      "Price": "6.231",
      "Open": "6.231",
      "High": "6.231",
      "Low": "6.231",
      "Change %": "0.44%"
  },
  {
      "Date": "28-Sep-04",
      "Price": "6.204",
      "Open": "6.204",
      "High": "6.204",
      "Low": "6.204",
      "Change %": "1.74%"
  },
  {
      "Date": "27-Sep-04",
      "Price": "6.098",
      "Open": "6.098",
      "High": "6.098",
      "Low": "6.098",
      "Change %": "0.61%"
  },
  {
      "Date": "25-Sep-04",
      "Price": "6.061",
      "Open": "6.061",
      "High": "6.061",
      "Low": "6.061",
      "Change %": "0.25%"
  },
  {
      "Date": "24-Sep-04",
      "Price": "6.046",
      "Open": "6.046",
      "High": "6.046",
      "Low": "6.046",
      "Change %": "-0.23%"
  },
  {
      "Date": "23-Sep-04",
      "Price": "6.06",
      "Open": "6.06",
      "High": "6.06",
      "Low": "6.06",
      "Change %": "-1.40%"
  },
  {
      "Date": "22-Sep-04",
      "Price": "6.146",
      "Open": "6.146",
      "High": "6.146",
      "Low": "6.146",
      "Change %": "0.33%"
  },
  {
      "Date": "21-Sep-04",
      "Price": "6.126",
      "Open": "6.126",
      "High": "6.126",
      "Low": "6.126",
      "Change %": "-0.23%"
  },
  {
      "Date": "20-Sep-04",
      "Price": "6.14",
      "Open": "6.14",
      "High": "6.14",
      "Low": "6.14",
      "Change %": "0.21%"
  },
  {
      "Date": "17-Sep-04",
      "Price": "6.127",
      "Open": "6.127",
      "High": "6.127",
      "Low": "6.127",
      "Change %": "-0.21%"
  },
  {
      "Date": "16-Sep-04",
      "Price": "6.14",
      "Open": "6.14",
      "High": "6.14",
      "Low": "6.14",
      "Change %": "0.24%"
  },
  {
      "Date": "15-Sep-04",
      "Price": "6.125",
      "Open": "6.125",
      "High": "6.125",
      "Low": "6.125",
      "Change %": "0.54%"
  },
  {
      "Date": "14-Sep-04",
      "Price": "6.092",
      "Open": "6.092",
      "High": "6.092",
      "Low": "6.092",
      "Change %": "-0.70%"
  },
  {
      "Date": "13-Sep-04",
      "Price": "6.135",
      "Open": "6.135",
      "High": "6.135",
      "Low": "6.135",
      "Change %": "3.21%"
  },
  {
      "Date": "11-Sep-04",
      "Price": "5.944",
      "Open": "5.944",
      "High": "5.944",
      "Low": "5.944",
      "Change %": "0.08%"
  },
  {
      "Date": "10-Sep-04",
      "Price": "5.939",
      "Open": "5.939",
      "High": "5.939",
      "Low": "5.939",
      "Change %": "-0.17%"
  },
  {
      "Date": "9-Sep-04",
      "Price": "5.949",
      "Open": "5.949",
      "High": "5.949",
      "Low": "5.949",
      "Change %": "0.05%"
  },
  {
      "Date": "8-Sep-04",
      "Price": "5.946",
      "Open": "5.946",
      "High": "5.946",
      "Low": "5.946",
      "Change %": "0.10%"
  },
  {
      "Date": "7-Sep-04",
      "Price": "5.94",
      "Open": "5.94",
      "High": "5.94",
      "Low": "5.94",
      "Change %": "0.66%"
  },
  {
      "Date": "6-Sep-04",
      "Price": "5.901",
      "Open": "5.901",
      "High": "5.901",
      "Low": "5.901",
      "Change %": "0.32%"
  },
  {
      "Date": "4-Sep-04",
      "Price": "5.882",
      "Open": "5.882",
      "High": "5.882",
      "Low": "5.882",
      "Change %": "-0.12%"
  },
  {
      "Date": "3-Sep-04",
      "Price": "5.889",
      "Open": "5.889",
      "High": "5.889",
      "Low": "5.889",
      "Change %": "-2.58%"
  },
  {
      "Date": "2-Sep-04",
      "Price": "6.045",
      "Open": "6.045",
      "High": "6.045",
      "Low": "6.045",
      "Change %": "1.41%"
  },
  {
      "Date": "1-Sep-04",
      "Price": "5.961",
      "Open": "5.961",
      "High": "5.961",
      "Low": "5.961",
      "Change %": "-2.10%"
  },
  {
      "Date": "31-Aug-04",
      "Price": "6.089",
      "Open": "6.089",
      "High": "6.089",
      "Low": "6.089",
      "Change %": "-1.10%"
  },
  {
      "Date": "30-Aug-04",
      "Price": "6.157",
      "Open": "6.157",
      "High": "6.157",
      "Low": "6.157",
      "Change %": "1.95%"
  },
  {
      "Date": "28-Aug-04",
      "Price": "6.039",
      "Open": "6.039",
      "High": "6.039",
      "Low": "6.039",
      "Change %": "-0.87%"
  },
  {
      "Date": "27-Aug-04",
      "Price": "6.092",
      "Open": "6.092",
      "High": "6.092",
      "Low": "6.092",
      "Change %": "-2.65%"
  },
  {
      "Date": "26-Aug-04",
      "Price": "6.258",
      "Open": "6.258",
      "High": "6.258",
      "Low": "6.258",
      "Change %": "0.85%"
  },
  {
      "Date": "25-Aug-04",
      "Price": "6.205",
      "Open": "6.205",
      "High": "6.205",
      "Low": "6.205",
      "Change %": "-1.27%"
  },
  {
      "Date": "24-Aug-04",
      "Price": "6.285",
      "Open": "6.285",
      "High": "6.285",
      "Low": "6.285",
      "Change %": "-1.15%"
  },
  {
      "Date": "23-Aug-04",
      "Price": "6.358",
      "Open": "6.358",
      "High": "6.358",
      "Low": "6.358",
      "Change %": "-3.01%"
  },
  {
      "Date": "21-Aug-04",
      "Price": "6.555",
      "Open": "6.555",
      "High": "6.555",
      "Low": "6.555",
      "Change %": "0.94%"
  },
  {
      "Date": "19-Aug-04",
      "Price": "6.494",
      "Open": "6.494",
      "High": "6.494",
      "Low": "6.494",
      "Change %": "0.20%"
  },
  {
      "Date": "18-Aug-04",
      "Price": "6.481",
      "Open": "6.481",
      "High": "6.481",
      "Low": "6.481",
      "Change %": "-0.52%"
  },
  {
      "Date": "17-Aug-04",
      "Price": "6.515",
      "Open": "6.515",
      "High": "6.515",
      "Low": "6.515",
      "Change %": "-0.72%"
  },
  {
      "Date": "16-Aug-04",
      "Price": "6.562",
      "Open": "6.562",
      "High": "6.562",
      "Low": "6.562",
      "Change %": "-1.04%"
  },
  {
      "Date": "14-Aug-04",
      "Price": "6.631",
      "Open": "6.631",
      "High": "6.631",
      "Low": "6.631",
      "Change %": "1.36%"
  },
  {
      "Date": "13-Aug-04",
      "Price": "6.542",
      "Open": "6.542",
      "High": "6.542",
      "Low": "6.542",
      "Change %": "-1.65%"
  },
  {
      "Date": "12-Aug-04",
      "Price": "6.652",
      "Open": "6.652",
      "High": "6.652",
      "Low": "6.652",
      "Change %": "1.56%"
  },
  {
      "Date": "11-Aug-04",
      "Price": "6.55",
      "Open": "6.55",
      "High": "6.55",
      "Low": "6.55",
      "Change %": "0.21%"
  },
  {
      "Date": "10-Aug-04",
      "Price": "6.536",
      "Open": "6.536",
      "High": "6.536",
      "Low": "6.536",
      "Change %": "0.94%"
  },
  {
      "Date": "9-Aug-04",
      "Price": "6.475",
      "Open": "6.475",
      "High": "6.475",
      "Low": "6.475",
      "Change %": "3.53%"
  },
  {
      "Date": "7-Aug-04",
      "Price": "6.254",
      "Open": "6.254",
      "High": "6.254",
      "Low": "6.254",
      "Change %": "-0.45%"
  },
  {
      "Date": "6-Aug-04",
      "Price": "6.282",
      "Open": "6.282",
      "High": "6.282",
      "Low": "6.282",
      "Change %": "2.36%"
  },
  {
      "Date": "5-Aug-04",
      "Price": "6.137",
      "Open": "6.137",
      "High": "6.137",
      "Low": "6.137",
      "Change %": "-0.39%"
  },
  {
      "Date": "4-Aug-04",
      "Price": "6.161",
      "Open": "6.161",
      "High": "6.161",
      "Low": "6.161",
      "Change %": "-1.19%"
  },
  {
      "Date": "3-Aug-04",
      "Price": "6.235",
      "Open": "6.235",
      "High": "6.235",
      "Low": "6.235",
      "Change %": "-0.02%"
  },
  {
      "Date": "2-Aug-04",
      "Price": "6.236",
      "Open": "6.236",
      "High": "6.236",
      "Low": "6.236",
      "Change %": "1.61%"
  },
  {
      "Date": "31-Jul-04",
      "Price": "6.137",
      "Open": "6.137",
      "High": "6.137",
      "Low": "6.137",
      "Change %": "-0.44%"
  },
  {
      "Date": "30-Jul-04",
      "Price": "6.164",
      "Open": "6.164",
      "High": "6.164",
      "Low": "6.164",
      "Change %": "-1.89%"
  },
  {
      "Date": "29-Jul-04",
      "Price": "6.283",
      "Open": "6.283",
      "High": "6.283",
      "Low": "6.283",
      "Change %": "3.02%"
  },
  {
      "Date": "28-Jul-04",
      "Price": "6.099",
      "Open": "6.099",
      "High": "6.099",
      "Low": "6.099",
      "Change %": "2.54%"
  },
  {
      "Date": "27-Jul-04",
      "Price": "5.948",
      "Open": "5.948",
      "High": "5.948",
      "Low": "5.948",
      "Change %": "0.39%"
  },
  {
      "Date": "26-Jul-04",
      "Price": "5.925",
      "Open": "5.925",
      "High": "5.925",
      "Low": "5.925",
      "Change %": "0.10%"
  },
  {
      "Date": "24-Jul-04",
      "Price": "5.919",
      "Open": "5.919",
      "High": "5.919",
      "Low": "5.919",
      "Change %": "-0.55%"
  },
  {
      "Date": "23-Jul-04",
      "Price": "5.952",
      "Open": "5.952",
      "High": "5.952",
      "Low": "5.952",
      "Change %": "0.44%"
  },
  {
      "Date": "22-Jul-04",
      "Price": "5.926",
      "Open": "5.926",
      "High": "5.926",
      "Low": "5.926",
      "Change %": "1.42%"
  },
  {
      "Date": "21-Jul-04",
      "Price": "5.843",
      "Open": "5.843",
      "High": "5.843",
      "Low": "5.843",
      "Change %": "-0.12%"
  },
  {
      "Date": "20-Jul-04",
      "Price": "5.85",
      "Open": "5.85",
      "High": "5.85",
      "Low": "5.85",
      "Change %": "0.33%"
  },
  {
      "Date": "19-Jul-04",
      "Price": "5.831",
      "Open": "5.831",
      "High": "5.831",
      "Low": "5.831",
      "Change %": "-0.22%"
  },
  {
      "Date": "17-Jul-04",
      "Price": "5.844",
      "Open": "5.844",
      "High": "5.844",
      "Low": "5.844",
      "Change %": "-1.73%"
  },
  {
      "Date": "16-Jul-04",
      "Price": "5.947",
      "Open": "5.947",
      "High": "5.947",
      "Low": "5.947",
      "Change %": "0.00%"
  },
  {
      "Date": "15-Jul-04",
      "Price": "5.947",
      "Open": "5.947",
      "High": "5.947",
      "Low": "5.947",
      "Change %": "1.40%"
  },
  {
      "Date": "14-Jul-04",
      "Price": "5.865",
      "Open": "5.865",
      "High": "5.865",
      "Low": "5.865",
      "Change %": "0.77%"
  },
  {
      "Date": "13-Jul-04",
      "Price": "5.82",
      "Open": "5.82",
      "High": "5.82",
      "Low": "5.82",
      "Change %": "-0.99%"
  },
  {
      "Date": "12-Jul-04",
      "Price": "5.878",
      "Open": "5.878",
      "High": "5.878",
      "Low": "5.878",
      "Change %": "1.43%"
  },
  {
      "Date": "10-Jul-04",
      "Price": "5.795",
      "Open": "5.795",
      "High": "5.795",
      "Low": "5.795",
      "Change %": "-0.94%"
  },
  {
      "Date": "9-Jul-04",
      "Price": "5.85",
      "Open": "5.85",
      "High": "5.85",
      "Low": "5.85",
      "Change %": "0.38%"
  },
  {
      "Date": "8-Jul-04",
      "Price": "5.828",
      "Open": "5.828",
      "High": "5.828",
      "Low": "5.828",
      "Change %": "1.57%"
  },
  {
      "Date": "7-Jul-04",
      "Price": "5.738",
      "Open": "5.738",
      "High": "5.738",
      "Low": "5.738",
      "Change %": "0.79%"
  },
  {
      "Date": "6-Jul-04",
      "Price": "5.693",
      "Open": "5.693",
      "High": "5.693",
      "Low": "5.693",
      "Change %": "-0.07%"
  },
  {
      "Date": "5-Jul-04",
      "Price": "5.697",
      "Open": "5.697",
      "High": "5.697",
      "Low": "5.697",
      "Change %": "-0.23%"
  },
  {
      "Date": "3-Jul-04",
      "Price": "5.71",
      "Open": "5.71",
      "High": "5.71",
      "Low": "5.71",
      "Change %": "-1.26%"
  },
  {
      "Date": "2-Jul-04",
      "Price": "5.783",
      "Open": "5.783",
      "High": "5.783",
      "Low": "5.783",
      "Change %": "-1.03%"
  },
  {
      "Date": "1-Jul-04",
      "Price": "5.843",
      "Open": "5.843",
      "High": "5.843",
      "Low": "5.843",
      "Change %": "-0.12%"
  },
  {
      "Date": "30-Jun-04",
      "Price": "5.85",
      "Open": "5.85",
      "High": "5.85",
      "Low": "5.85",
      "Change %": "0.00%"
  },
  {
      "Date": "29-Jun-04",
      "Price": "5.85",
      "Open": "5.85",
      "High": "5.85",
      "Low": "5.85",
      "Change %": "0.76%"
  },
  {
      "Date": "28-Jun-04",
      "Price": "5.806",
      "Open": "5.806",
      "High": "5.806",
      "Low": "5.806",
      "Change %": "-0.97%"
  },
  {
      "Date": "26-Jun-04",
      "Price": "5.863",
      "Open": "5.863",
      "High": "5.863",
      "Low": "5.863",
      "Change %": "0.21%"
  },
  {
      "Date": "25-Jun-04",
      "Price": "5.851",
      "Open": "5.851",
      "High": "5.851",
      "Low": "5.851",
      "Change %": "1.19%"
  },
  {
      "Date": "24-Jun-04",
      "Price": "5.782",
      "Open": "5.782",
      "High": "5.782",
      "Low": "5.782",
      "Change %": "-0.22%"
  },
  {
      "Date": "23-Jun-04",
      "Price": "5.795",
      "Open": "5.795",
      "High": "5.795",
      "Low": "5.795",
      "Change %": "0.98%"
  },
  {
      "Date": "22-Jun-04",
      "Price": "5.739",
      "Open": "5.739",
      "High": "5.739",
      "Low": "5.739",
      "Change %": "2.56%"
  },
  {
      "Date": "21-Jun-04",
      "Price": "5.596",
      "Open": "5.596",
      "High": "5.596",
      "Low": "5.596",
      "Change %": "2.25%"
  },
  {
      "Date": "19-Jun-04",
      "Price": "5.473",
      "Open": "5.473",
      "High": "5.473",
      "Low": "5.473",
      "Change %": "0.09%"
  },
  {
      "Date": "18-Jun-04",
      "Price": "5.468",
      "Open": "5.468",
      "High": "5.468",
      "Low": "5.468",
      "Change %": "1.56%"
  },
  {
      "Date": "17-Jun-04",
      "Price": "5.384",
      "Open": "5.384",
      "High": "5.384",
      "Low": "5.384",
      "Change %": "0.22%"
  },
  {
      "Date": "16-Jun-04",
      "Price": "5.372",
      "Open": "5.372",
      "High": "5.372",
      "Low": "5.372",
      "Change %": "-0.90%"
  },
  {
      "Date": "15-Jun-04",
      "Price": "5.421",
      "Open": "5.421",
      "High": "5.421",
      "Low": "5.421",
      "Change %": "0.30%"
  },
  {
      "Date": "14-Jun-04",
      "Price": "5.405",
      "Open": "5.405",
      "High": "5.405",
      "Low": "5.405",
      "Change %": "1.46%"
  },
  {
      "Date": "12-Jun-04",
      "Price": "5.327",
      "Open": "5.327",
      "High": "5.327",
      "Low": "5.327",
      "Change %": "0.08%"
  },
  {
      "Date": "11-Jun-04",
      "Price": "5.323",
      "Open": "5.323",
      "High": "5.323",
      "Low": "5.323",
      "Change %": "-0.17%"
  },
  {
      "Date": "10-Jun-04",
      "Price": "5.332",
      "Open": "5.332",
      "High": "5.332",
      "Low": "5.332",
      "Change %": "0.83%"
  },
  {
      "Date": "9-Jun-04",
      "Price": "5.288",
      "Open": "5.288",
      "High": "5.288",
      "Low": "5.288",
      "Change %": "0.30%"
  },
  {
      "Date": "8-Jun-04",
      "Price": "5.272",
      "Open": "5.272",
      "High": "5.272",
      "Low": "5.272",
      "Change %": "-0.08%"
  },
  {
      "Date": "7-Jun-04",
      "Price": "5.276",
      "Open": "5.276",
      "High": "5.276",
      "Low": "5.276",
      "Change %": "0.92%"
  },
  {
      "Date": "5-Jun-04",
      "Price": "5.228",
      "Open": "5.228",
      "High": "5.228",
      "Low": "5.228",
      "Change %": "-0.95%"
  },
  {
      "Date": "4-Jun-04",
      "Price": "5.278",
      "Open": "5.278",
      "High": "5.278",
      "Low": "5.278",
      "Change %": "-0.11%"
  },
  {
      "Date": "3-Jun-04",
      "Price": "5.284",
      "Open": "5.284",
      "High": "5.284",
      "Low": "5.284",
      "Change %": "-0.17%"
  },
  {
      "Date": "2-Jun-04",
      "Price": "5.293",
      "Open": "5.293",
      "High": "5.293",
      "Low": "5.293",
      "Change %": "0.15%"
  },
  {
      "Date": "1-Jun-04",
      "Price": "5.285",
      "Open": "5.285",
      "High": "5.285",
      "Low": "5.285",
      "Change %": "0.21%"
  },
  {
      "Date": "31-May-04",
      "Price": "5.274",
      "Open": "5.274",
      "High": "5.274",
      "Low": "5.274",
      "Change %": "0.34%"
  },
  {
      "Date": "29-May-04",
      "Price": "5.256",
      "Open": "5.256",
      "High": "5.256",
      "Low": "5.256",
      "Change %": "0.23%"
  },
  {
      "Date": "28-May-04",
      "Price": "5.244",
      "Open": "5.244",
      "High": "5.244",
      "Low": "5.244",
      "Change %": "0.61%"
  },
  {
      "Date": "27-May-04",
      "Price": "5.212",
      "Open": "5.212",
      "High": "5.212",
      "Low": "5.212",
      "Change %": "0.40%"
  },
  {
      "Date": "26-May-04",
      "Price": "5.191",
      "Open": "5.191",
      "High": "5.191",
      "Low": "5.191",
      "Change %": "0.14%"
  },
  {
      "Date": "25-May-04",
      "Price": "5.184",
      "Open": "5.184",
      "High": "5.184",
      "Low": "5.184",
      "Change %": "0.12%"
  },
  {
      "Date": "24-May-04",
      "Price": "5.178",
      "Open": "5.178",
      "High": "5.178",
      "Low": "5.178",
      "Change %": "0.02%"
  },
  {
      "Date": "22-May-04",
      "Price": "5.177",
      "Open": "5.177",
      "High": "5.177",
      "Low": "5.177",
      "Change %": "-0.27%"
  },
  {
      "Date": "21-May-04",
      "Price": "5.191",
      "Open": "5.191",
      "High": "5.191",
      "Low": "5.191",
      "Change %": "0.12%"
  },
  {
      "Date": "20-May-04",
      "Price": "5.185",
      "Open": "5.185",
      "High": "5.185",
      "Low": "5.185",
      "Change %": "0.04%"
  },
  {
      "Date": "19-May-04",
      "Price": "5.183",
      "Open": "5.183",
      "High": "5.183",
      "Low": "5.183",
      "Change %": "-0.08%"
  },
  {
      "Date": "18-May-04",
      "Price": "5.187",
      "Open": "5.187",
      "High": "5.187",
      "Low": "5.187",
      "Change %": "-1.33%"
  },
  {
      "Date": "17-May-04",
      "Price": "5.257",
      "Open": "5.257",
      "High": "5.257",
      "Low": "5.257",
      "Change %": "0.57%"
  },
  {
      "Date": "15-May-04",
      "Price": "5.227",
      "Open": "5.227",
      "High": "5.227",
      "Low": "5.227",
      "Change %": "-0.04%"
  },
  {
      "Date": "14-May-04",
      "Price": "5.229",
      "Open": "5.229",
      "High": "5.229",
      "Low": "5.229",
      "Change %": "0.87%"
  },
  {
      "Date": "13-May-04",
      "Price": "5.184",
      "Open": "5.184",
      "High": "5.184",
      "Low": "5.184",
      "Change %": "-0.06%"
  },
  {
      "Date": "12-May-04",
      "Price": "5.187",
      "Open": "5.187",
      "High": "5.187",
      "Low": "5.187",
      "Change %": "-0.15%"
  },
  {
      "Date": "11-May-04",
      "Price": "5.195",
      "Open": "5.195",
      "High": "5.195",
      "Low": "5.195",
      "Change %": "0.12%"
  },
  {
      "Date": "10-May-04",
      "Price": "5.189",
      "Open": "5.189",
      "High": "5.189",
      "Low": "5.189",
      "Change %": "0.46%"
  },
  {
      "Date": "8-May-04",
      "Price": "5.165",
      "Open": "5.165",
      "High": "5.165",
      "Low": "5.165",
      "Change %": "0.72%"
  },
  {
      "Date": "7-May-04",
      "Price": "5.128",
      "Open": "5.128",
      "High": "5.128",
      "Low": "5.128",
      "Change %": "0.31%"
  },
  {
      "Date": "6-May-04",
      "Price": "5.112",
      "Open": "5.112",
      "High": "5.112",
      "Low": "5.112",
      "Change %": "-0.02%"
  },
  {
      "Date": "5-May-04",
      "Price": "5.113",
      "Open": "5.113",
      "High": "5.113",
      "Low": "5.113",
      "Change %": "-0.21%"
  },
  {
      "Date": "3-May-04",
      "Price": "5.124",
      "Open": "5.124",
      "High": "5.124",
      "Low": "5.124",
      "Change %": "0.14%"
  },
  {
      "Date": "30-Apr-04",
      "Price": "5.117",
      "Open": "5.117",
      "High": "5.117",
      "Low": "5.117",
      "Change %": "-0.35%"
  },
  {
      "Date": "29-Apr-04",
      "Price": "5.135",
      "Open": "5.135",
      "High": "5.135",
      "Low": "5.135",
      "Change %": "0.23%"
  },
  {
      "Date": "28-Apr-04",
      "Price": "5.123",
      "Open": "5.123",
      "High": "5.123",
      "Low": "5.123",
      "Change %": "0.22%"
  },
  {
      "Date": "27-Apr-04",
      "Price": "5.112",
      "Open": "5.112",
      "High": "5.112",
      "Low": "5.112",
      "Change %": "0.65%"
  },
  {
      "Date": "24-Apr-04",
      "Price": "5.079",
      "Open": "5.079",
      "High": "5.079",
      "Low": "5.079",
      "Change %": "0.16%"
  },
  {
      "Date": "23-Apr-04",
      "Price": "5.071",
      "Open": "5.071",
      "High": "5.071",
      "Low": "5.071",
      "Change %": "0.08%"
  },
  {
      "Date": "22-Apr-04",
      "Price": "5.067",
      "Open": "5.067",
      "High": "5.067",
      "Low": "5.067",
      "Change %": "-0.06%"
  },
  {
      "Date": "21-Apr-04",
      "Price": "5.07",
      "Open": "5.07",
      "High": "5.07",
      "Low": "5.07",
      "Change %": "0.16%"
  },
  {
      "Date": "20-Apr-04",
      "Price": "5.062",
      "Open": "5.062",
      "High": "5.062",
      "Low": "5.062",
      "Change %": "-0.14%"
  },
  {
      "Date": "19-Apr-04",
      "Price": "5.069",
      "Open": "5.069",
      "High": "5.069",
      "Low": "5.069",
      "Change %": "0.16%"
  },
  {
      "Date": "17-Apr-04",
      "Price": "5.061",
      "Open": "5.061",
      "High": "5.061",
      "Low": "5.061",
      "Change %": "-0.35%"
  },
  {
      "Date": "16-Apr-04",
      "Price": "5.079",
      "Open": "5.079",
      "High": "5.079",
      "Low": "5.079",
      "Change %": "0.02%"
  },
  {
      "Date": "15-Apr-04",
      "Price": "5.078",
      "Open": "5.078",
      "High": "5.078",
      "Low": "5.078",
      "Change %": "-0.04%"
  },
  {
      "Date": "13-Apr-04",
      "Price": "5.08",
      "Open": "5.08",
      "High": "5.08",
      "Low": "5.08",
      "Change %": "-0.02%"
  },
  {
      "Date": "12-Apr-04",
      "Price": "5.081",
      "Open": "5.081",
      "High": "5.081",
      "Low": "5.081",
      "Change %": "-0.63%"
  },
  {
      "Date": "10-Apr-04",
      "Price": "5.113",
      "Open": "5.113",
      "High": "5.113",
      "Low": "5.113",
      "Change %": "-0.04%"
  },
  {
      "Date": "8-Apr-04",
      "Price": "5.115",
      "Open": "5.115",
      "High": "5.115",
      "Low": "5.115",
      "Change %": "-0.12%"
  },
  {
      "Date": "7-Apr-04",
      "Price": "5.121",
      "Open": "5.121",
      "High": "5.121",
      "Low": "5.121",
      "Change %": "-0.19%"
  },
  {
      "Date": "6-Apr-04",
      "Price": "5.131",
      "Open": "5.131",
      "High": "5.131",
      "Low": "5.131",
      "Change %": "0.06%"
  },
  {
      "Date": "5-Apr-04",
      "Price": "5.128",
      "Open": "5.128",
      "High": "5.128",
      "Low": "5.128",
      "Change %": "0.33%"
  },
  {
      "Date": "2-Apr-04",
      "Price": "5.111",
      "Open": "5.111",
      "High": "5.111",
      "Low": "5.111",
      "Change %": "-0.31%"
  },
  {
      "Date": "1-Apr-04",
      "Price": "5.127",
      "Open": "5.127",
      "High": "5.127",
      "Low": "5.127",
      "Change %": "-0.39%"
  },
  {
      "Date": "31-Mar-04",
      "Price": "5.147",
      "Open": "5.147",
      "High": "5.147",
      "Low": "5.147",
      "Change %": "-0.21%"
  },
  {
      "Date": "29-Mar-04",
      "Price": "5.158",
      "Open": "5.158",
      "High": "5.158",
      "Low": "5.158",
      "Change %": "-0.04%"
  },
  {
      "Date": "27-Mar-04",
      "Price": "5.16",
      "Open": "5.16",
      "High": "5.16",
      "Low": "5.16",
      "Change %": "-0.02%"
  },
  {
      "Date": "26-Mar-04",
      "Price": "5.161",
      "Open": "5.161",
      "High": "5.161",
      "Low": "5.161",
      "Change %": "-0.12%"
  },
  {
      "Date": "25-Mar-04",
      "Price": "5.167",
      "Open": "5.167",
      "High": "5.167",
      "Low": "5.167",
      "Change %": "-0.12%"
  },
  {
      "Date": "24-Mar-04",
      "Price": "5.173",
      "Open": "5.173",
      "High": "5.173",
      "Low": "5.173",
      "Change %": "-0.17%"
  },
  {
      "Date": "23-Mar-04",
      "Price": "5.182",
      "Open": "5.182",
      "High": "5.182",
      "Low": "5.182",
      "Change %": "0.27%"
  },
  {
      "Date": "22-Mar-04",
      "Price": "5.168",
      "Open": "5.168",
      "High": "5.168",
      "Low": "5.168",
      "Change %": "0.10%"
  },
  {
      "Date": "20-Mar-04",
      "Price": "5.163",
      "Open": "5.163",
      "High": "5.163",
      "Low": "5.163",
      "Change %": "0.08%"
  },
  {
      "Date": "19-Mar-04",
      "Price": "5.159",
      "Open": "5.159",
      "High": "5.159",
      "Low": "5.159",
      "Change %": "-0.35%"
  },
  {
      "Date": "18-Mar-04",
      "Price": "5.177",
      "Open": "5.177",
      "High": "5.177",
      "Low": "5.177",
      "Change %": "0.15%"
  },
  {
      "Date": "17-Mar-04",
      "Price": "5.169",
      "Open": "5.169",
      "High": "5.169",
      "Low": "5.169",
      "Change %": "-0.50%"
  },
  {
      "Date": "16-Mar-04",
      "Price": "5.195",
      "Open": "5.195",
      "High": "5.195",
      "Low": "5.195",
      "Change %": "-0.15%"
  },
  {
      "Date": "15-Mar-04",
      "Price": "5.203",
      "Open": "5.203",
      "High": "5.203",
      "Low": "5.203",
      "Change %": "0.33%"
  },
  {
      "Date": "13-Mar-04",
      "Price": "5.186",
      "Open": "5.186",
      "High": "5.186",
      "Low": "5.186",
      "Change %": "0.02%"
  },
  {
      "Date": "12-Mar-04",
      "Price": "5.185",
      "Open": "5.185",
      "High": "5.185",
      "Low": "5.185",
      "Change %": "-0.50%"
  },
  {
      "Date": "11-Mar-04",
      "Price": "5.211",
      "Open": "5.211",
      "High": "5.211",
      "Low": "5.211",
      "Change %": "-0.08%"
  },
  {
      "Date": "10-Mar-04",
      "Price": "5.215",
      "Open": "5.215",
      "High": "5.215",
      "Low": "5.215",
      "Change %": "-0.15%"
  },
  {
      "Date": "9-Mar-04",
      "Price": "5.223",
      "Open": "5.223",
      "High": "5.223",
      "Low": "5.223",
      "Change %": "-0.11%"
  },
  {
      "Date": "8-Mar-04",
      "Price": "5.229",
      "Open": "5.229",
      "High": "5.229",
      "Low": "5.229",
      "Change %": "-0.08%"
  },
  {
      "Date": "6-Mar-04",
      "Price": "5.233",
      "Open": "5.233",
      "High": "5.233",
      "Low": "5.233",
      "Change %": "-0.19%"
  },
  {
      "Date": "5-Mar-04",
      "Price": "5.243",
      "Open": "5.243",
      "High": "5.243",
      "Low": "5.243",
      "Change %": "0.00%"
  },
  {
      "Date": "4-Mar-04",
      "Price": "5.243",
      "Open": "5.243",
      "High": "5.243",
      "Low": "5.243",
      "Change %": "-0.11%"
  },
  {
      "Date": "3-Mar-04",
      "Price": "5.249",
      "Open": "5.249",
      "High": "5.249",
      "Low": "5.249",
      "Change %": "-0.15%"
  },
  {
      "Date": "1-Mar-04",
      "Price": "5.257",
      "Open": "5.257",
      "High": "5.257",
      "Low": "5.257",
      "Change %": "-0.08%"
  },
  {
      "Date": "28-Feb-04",
      "Price": "5.261",
      "Open": "5.261",
      "High": "5.261",
      "Low": "5.261",
      "Change %": "-0.08%"
  },
  {
      "Date": "27-Feb-04",
      "Price": "5.265",
      "Open": "5.265",
      "High": "5.265",
      "Low": "5.265",
      "Change %": "0.19%"
  },
  {
      "Date": "26-Feb-04",
      "Price": "5.255",
      "Open": "5.255",
      "High": "5.255",
      "Low": "5.255",
      "Change %": "0.44%"
  },
  {
      "Date": "25-Feb-04",
      "Price": "5.232",
      "Open": "5.232",
      "High": "5.232",
      "Low": "5.232",
      "Change %": "-0.34%"
  },
  {
      "Date": "24-Feb-04",
      "Price": "5.25",
      "Open": "5.25",
      "High": "5.25",
      "Low": "5.25",
      "Change %": "-0.32%"
  },
  {
      "Date": "23-Feb-04",
      "Price": "5.267",
      "Open": "5.267",
      "High": "5.267",
      "Low": "5.267",
      "Change %": "-0.02%"
  },
  {
      "Date": "21-Feb-04",
      "Price": "5.268",
      "Open": "5.268",
      "High": "5.268",
      "Low": "5.268",
      "Change %": "-0.57%"
  },
  {
      "Date": "20-Feb-04",
      "Price": "5.298",
      "Open": "5.298",
      "High": "5.298",
      "Low": "5.298",
      "Change %": "-0.56%"
  },
  {
      "Date": "19-Feb-04",
      "Price": "5.328",
      "Open": "5.328",
      "High": "5.328",
      "Low": "5.328",
      "Change %": "0.32%"
  },
  {
      "Date": "17-Feb-04",
      "Price": "5.311",
      "Open": "5.311",
      "High": "5.311",
      "Low": "5.311",
      "Change %": "-0.45%"
  },
  {
      "Date": "16-Feb-04",
      "Price": "5.335",
      "Open": "5.335",
      "High": "5.335",
      "Low": "5.335",
      "Change %": "0.79%"
  },
  {
      "Date": "14-Feb-04",
      "Price": "5.293",
      "Open": "5.293",
      "High": "5.293",
      "Low": "5.293",
      "Change %": "0.19%"
  },
  {
      "Date": "13-Feb-04",
      "Price": "5.283",
      "Open": "5.283",
      "High": "5.283",
      "Low": "5.283",
      "Change %": "0.09%"
  },
  {
      "Date": "12-Feb-04",
      "Price": "5.278",
      "Open": "5.278",
      "High": "5.278",
      "Low": "5.278",
      "Change %": "-0.17%"
  },
  {
      "Date": "11-Feb-04",
      "Price": "5.287",
      "Open": "5.287",
      "High": "5.287",
      "Low": "5.287",
      "Change %": "1.05%"
  },
  {
      "Date": "10-Feb-04",
      "Price": "5.232",
      "Open": "5.232",
      "High": "5.232",
      "Low": "5.232",
      "Change %": "0.35%"
  },
  {
      "Date": "9-Feb-04",
      "Price": "5.214",
      "Open": "5.214",
      "High": "5.214",
      "Low": "5.214",
      "Change %": "-0.10%"
  },
  {
      "Date": "7-Feb-04",
      "Price": "5.219",
      "Open": "5.219",
      "High": "5.219",
      "Low": "5.219",
      "Change %": "0.06%"
  },
  {
      "Date": "6-Feb-04",
      "Price": "5.216",
      "Open": "5.216",
      "High": "5.216",
      "Low": "5.216",
      "Change %": "0.42%"
  },
  {
      "Date": "5-Feb-04",
      "Price": "5.194",
      "Open": "5.194",
      "High": "5.194",
      "Low": "5.194",
      "Change %": "0.48%"
  },
  {
      "Date": "4-Feb-04",
      "Price": "5.169",
      "Open": "5.169",
      "High": "5.169",
      "Low": "5.169",
      "Change %": "-0.52%"
  },
  {
      "Date": "3-Feb-04",
      "Price": "5.196",
      "Open": "5.196",
      "High": "5.196",
      "Low": "5.196",
      "Change %": "-0.52%"
  },
  {
      "Date": "31-Jan-04",
      "Price": "5.223",
      "Open": "5.223",
      "High": "5.223",
      "Low": "5.223",
      "Change %": "0.29%"
  },
  {
      "Date": "30-Jan-04",
      "Price": "5.208",
      "Open": "5.208",
      "High": "5.208",
      "Low": "5.208",
      "Change %": "0.35%"
  },
  {
      "Date": "29-Jan-04",
      "Price": "5.19",
      "Open": "5.19",
      "High": "5.19",
      "Low": "5.19",
      "Change %": "0.80%"
  },
  {
      "Date": "28-Jan-04",
      "Price": "5.149",
      "Open": "5.149",
      "High": "5.149",
      "Low": "5.149",
      "Change %": "0.12%"
  },
  {
      "Date": "27-Jan-04",
      "Price": "5.143",
      "Open": "5.143",
      "High": "5.143",
      "Low": "5.143",
      "Change %": "0.18%"
  },
  {
      "Date": "24-Jan-04",
      "Price": "5.134",
      "Open": "5.134",
      "High": "5.134",
      "Low": "5.134",
      "Change %": "-0.02%"
  },
  {
      "Date": "23-Jan-04",
      "Price": "5.135",
      "Open": "5.135",
      "High": "5.135",
      "Low": "5.135",
      "Change %": "-0.06%"
  },
  {
      "Date": "22-Jan-04",
      "Price": "5.138",
      "Open": "5.138",
      "High": "5.138",
      "Low": "5.138",
      "Change %": "-0.14%"
  },
  {
      "Date": "21-Jan-04",
      "Price": "5.145",
      "Open": "5.145",
      "High": "5.145",
      "Low": "5.145",
      "Change %": "0.06%"
  },
  {
      "Date": "20-Jan-04",
      "Price": "5.142",
      "Open": "5.142",
      "High": "5.142",
      "Low": "5.142",
      "Change %": "-0.02%"
  },
  {
      "Date": "19-Jan-04",
      "Price": "5.143",
      "Open": "5.143",
      "High": "5.143",
      "Low": "5.143",
      "Change %": "0.12%"
  },
  {
      "Date": "17-Jan-04",
      "Price": "5.137",
      "Open": "5.137",
      "High": "5.137",
      "Low": "5.137",
      "Change %": "-0.02%"
  },
  {
      "Date": "16-Jan-04",
      "Price": "5.138",
      "Open": "5.138",
      "High": "5.138",
      "Low": "5.138",
      "Change %": "-0.02%"
  },
  {
      "Date": "15-Jan-04",
      "Price": "5.139",
      "Open": "5.139",
      "High": "5.139",
      "Low": "5.139",
      "Change %": "0.29%"
  },
  {
      "Date": "14-Jan-04",
      "Price": "5.124",
      "Open": "5.124",
      "High": "5.124",
      "Low": "5.124",
      "Change %": "-0.08%"
  },
  {
      "Date": "13-Jan-04",
      "Price": "5.128",
      "Open": "5.128",
      "High": "5.128",
      "Low": "5.128",
      "Change %": "-0.23%"
  },
  {
      "Date": "12-Jan-04",
      "Price": "5.14",
      "Open": "5.14",
      "High": "5.14",
      "Low": "5.14",
      "Change %": "0.18%"
  },
  {
      "Date": "10-Jan-04",
      "Price": "5.131",
      "Open": "5.131",
      "High": "5.131",
      "Low": "5.131",
      "Change %": "0.25%"
  },
  {
      "Date": "9-Jan-04",
      "Price": "5.118",
      "Open": "5.118",
      "High": "5.118",
      "Low": "5.118",
      "Change %": "0.10%"
  },
  {
      "Date": "8-Jan-04",
      "Price": "5.113",
      "Open": "5.113",
      "High": "5.113",
      "Low": "5.113",
      "Change %": "-0.12%"
  },
  {
      "Date": "7-Jan-04",
      "Price": "5.119",
      "Open": "5.119",
      "High": "5.119",
      "Low": "5.119",
      "Change %": "0.06%"
  },
  {
      "Date": "6-Jan-04",
      "Price": "5.116",
      "Open": "5.116",
      "High": "5.116",
      "Low": "5.116",
      "Change %": "-0.08%"
  },
  {
      "Date": "5-Jan-04",
      "Price": "5.12",
      "Open": "5.12",
      "High": "5.12",
      "Low": "5.12",
      "Change %": "0.55%"
  },
  {
      "Date": "3-Jan-04",
      "Price": "5.092",
      "Open": "5.092",
      "High": "5.092",
      "Low": "5.092",
      "Change %": "-0.57%"
  },
  {
      "Date": "2-Jan-04",
      "Price": "5.121",
      "Open": "5.121",
      "High": "5.121",
      "Low": "5.121",
      "Change %": "-0.12%"
  },
  {
      "Date": "1-Jan-04",
      "Price": "5.127",
      "Open": "5.127",
      "High": "5.127",
      "Low": "5.127",
      "Change %": "0.10%"
  },
  {
      "Date": "31-Dec-03",
      "Price": "5.122",
      "Open": "5.122",
      "High": "5.122",
      "Low": "5.122",
      "Change %": "0.31%"
  },
  {
      "Date": "30-Dec-03",
      "Price": "5.106",
      "Open": "5.106",
      "High": "5.106",
      "Low": "5.106",
      "Change %": "-0.23%"
  },
  {
      "Date": "29-Dec-03",
      "Price": "5.118",
      "Open": "5.118",
      "High": "5.118",
      "Low": "5.118",
      "Change %": "0.20%"
  },
  {
      "Date": "27-Dec-03",
      "Price": "5.108",
      "Open": "5.108",
      "High": "5.108",
      "Low": "5.108",
      "Change %": "-0.04%"
  },
  {
      "Date": "26-Dec-03",
      "Price": "5.11",
      "Open": "5.11",
      "High": "5.11",
      "Low": "5.11",
      "Change %": "-0.68%"
  },
  {
      "Date": "24-Dec-03",
      "Price": "5.145",
      "Open": "5.145",
      "High": "5.145",
      "Low": "5.145",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Dec-03",
      "Price": "5.145",
      "Open": "5.145",
      "High": "5.145",
      "Low": "5.145",
      "Change %": "-0.14%"
  },
  {
      "Date": "22-Dec-03",
      "Price": "5.152",
      "Open": "5.152",
      "High": "5.152",
      "Low": "5.152",
      "Change %": "-0.12%"
  },
  {
      "Date": "20-Dec-03",
      "Price": "5.158",
      "Open": "5.158",
      "High": "5.158",
      "Low": "5.158",
      "Change %": "0.10%"
  },
  {
      "Date": "19-Dec-03",
      "Price": "5.153",
      "Open": "5.153",
      "High": "5.153",
      "Low": "5.153",
      "Change %": "0.00%"
  },
  {
      "Date": "18-Dec-03",
      "Price": "5.153",
      "Open": "5.153",
      "High": "5.153",
      "Low": "5.153",
      "Change %": "-0.77%"
  },
  {
      "Date": "17-Dec-03",
      "Price": "5.193",
      "Open": "5.193",
      "High": "5.193",
      "Low": "5.193",
      "Change %": "-0.29%"
  },
  {
      "Date": "16-Dec-03",
      "Price": "5.208",
      "Open": "5.208",
      "High": "5.208",
      "Low": "5.208",
      "Change %": "0.10%"
  },
  {
      "Date": "15-Dec-03",
      "Price": "5.203",
      "Open": "5.203",
      "High": "5.203",
      "Low": "5.203",
      "Change %": "0.19%"
  },
  {
      "Date": "13-Dec-03",
      "Price": "5.193",
      "Open": "5.193",
      "High": "5.193",
      "Low": "5.193",
      "Change %": "0.25%"
  },
  {
      "Date": "12-Dec-03",
      "Price": "5.18",
      "Open": "5.18",
      "High": "5.18",
      "Low": "5.18",
      "Change %": "-0.65%"
  },
  {
      "Date": "11-Dec-03",
      "Price": "5.214",
      "Open": "5.214",
      "High": "5.214",
      "Low": "5.214",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Dec-03",
      "Price": "5.214",
      "Open": "5.214",
      "High": "5.214",
      "Low": "5.214",
      "Change %": "0.64%"
  },
  {
      "Date": "9-Dec-03",
      "Price": "5.181",
      "Open": "5.181",
      "High": "5.181",
      "Low": "5.181",
      "Change %": "0.14%"
  },
  {
      "Date": "8-Dec-03",
      "Price": "5.174",
      "Open": "5.174",
      "High": "5.174",
      "Low": "5.174",
      "Change %": "-0.04%"
  },
  {
      "Date": "6-Dec-03",
      "Price": "5.176",
      "Open": "5.176",
      "High": "5.176",
      "Low": "5.176",
      "Change %": "-0.25%"
  },
  {
      "Date": "5-Dec-03",
      "Price": "5.189",
      "Open": "5.189",
      "High": "5.189",
      "Low": "5.189",
      "Change %": "0.46%"
  },
  {
      "Date": "4-Dec-03",
      "Price": "5.165",
      "Open": "5.165",
      "High": "5.165",
      "Low": "5.165",
      "Change %": "-0.02%"
  },
  {
      "Date": "3-Dec-03",
      "Price": "5.166",
      "Open": "5.166",
      "High": "5.166",
      "Low": "5.166",
      "Change %": "-0.81%"
  },
  {
      "Date": "2-Dec-03",
      "Price": "5.208",
      "Open": "5.208",
      "High": "5.208",
      "Low": "5.208",
      "Change %": "-0.13%"
  },
  {
      "Date": "1-Dec-03",
      "Price": "5.215",
      "Open": "5.215",
      "High": "5.215",
      "Low": "5.215",
      "Change %": "1.11%"
  },
  {
      "Date": "29-Nov-03",
      "Price": "5.158",
      "Open": "5.158",
      "High": "5.158",
      "Low": "5.158",
      "Change %": "0.21%"
  },
  {
      "Date": "28-Nov-03",
      "Price": "5.147",
      "Open": "5.147",
      "High": "5.147",
      "Low": "5.147",
      "Change %": "0.57%"
  },
  {
      "Date": "27-Nov-03",
      "Price": "5.118",
      "Open": "5.118",
      "High": "5.118",
      "Low": "5.118",
      "Change %": "-0.25%"
  },
  {
      "Date": "25-Nov-03",
      "Price": "5.131",
      "Open": "5.131",
      "High": "5.131",
      "Low": "5.131",
      "Change %": "0.41%"
  },
  {
      "Date": "24-Nov-03",
      "Price": "5.11",
      "Open": "5.11",
      "High": "5.11",
      "Low": "5.11",
      "Change %": "0.12%"
  },
  {
      "Date": "22-Nov-03",
      "Price": "5.104",
      "Open": "5.104",
      "High": "5.104",
      "Low": "5.104",
      "Change %": "-0.02%"
  },
  {
      "Date": "21-Nov-03",
      "Price": "5.105",
      "Open": "5.105",
      "High": "5.105",
      "Low": "5.105",
      "Change %": "-0.12%"
  },
  {
      "Date": "20-Nov-03",
      "Price": "5.111",
      "Open": "5.111",
      "High": "5.111",
      "Low": "5.111",
      "Change %": "-0.37%"
  },
  {
      "Date": "19-Nov-03",
      "Price": "5.13",
      "Open": "5.13",
      "High": "5.13",
      "Low": "5.13",
      "Change %": "-0.23%"
  },
  {
      "Date": "18-Nov-03",
      "Price": "5.142",
      "Open": "5.142",
      "High": "5.142",
      "Low": "5.142",
      "Change %": "-0.70%"
  },
  {
      "Date": "17-Nov-03",
      "Price": "5.178",
      "Open": "5.178",
      "High": "5.178",
      "Low": "5.178",
      "Change %": "1.63%"
  },
  {
      "Date": "15-Nov-03",
      "Price": "5.095",
      "Open": "5.095",
      "High": "5.095",
      "Low": "5.095",
      "Change %": "-0.14%"
  },
  {
      "Date": "14-Nov-03",
      "Price": "5.102",
      "Open": "5.102",
      "High": "5.102",
      "Low": "5.102",
      "Change %": "0.10%"
  },
  {
      "Date": "13-Nov-03",
      "Price": "5.097",
      "Open": "5.097",
      "High": "5.097",
      "Low": "5.097",
      "Change %": "0.35%"
  },
  {
      "Date": "12-Nov-03",
      "Price": "5.079",
      "Open": "5.079",
      "High": "5.079",
      "Low": "5.079",
      "Change %": "0.34%"
  },
  {
      "Date": "11-Nov-03",
      "Price": "5.062",
      "Open": "5.062",
      "High": "5.062",
      "Low": "5.062",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Nov-03",
      "Price": "5.062",
      "Open": "5.062",
      "High": "5.062",
      "Low": "5.062",
      "Change %": "-0.04%"
  },
  {
      "Date": "7-Nov-03",
      "Price": "5.064",
      "Open": "5.064",
      "High": "5.064",
      "Low": "5.064",
      "Change %": "0.12%"
  },
  {
      "Date": "6-Nov-03",
      "Price": "5.058",
      "Open": "5.058",
      "High": "5.058",
      "Low": "5.058",
      "Change %": "-0.12%"
  },
  {
      "Date": "5-Nov-03",
      "Price": "5.064",
      "Open": "5.064",
      "High": "5.064",
      "Low": "5.064",
      "Change %": "-0.26%"
  },
  {
      "Date": "4-Nov-03",
      "Price": "5.077",
      "Open": "5.077",
      "High": "5.077",
      "Low": "5.077",
      "Change %": "-0.24%"
  },
  {
      "Date": "3-Nov-03",
      "Price": "5.089",
      "Open": "5.089",
      "High": "5.089",
      "Low": "5.089",
      "Change %": "0.35%"
  },
  {
      "Date": "1-Nov-03",
      "Price": "5.071",
      "Open": "5.071",
      "High": "5.071",
      "Low": "5.071",
      "Change %": "-0.57%"
  },
  {
      "Date": "31-Oct-03",
      "Price": "5.1",
      "Open": "5.1",
      "High": "5.1",
      "Low": "5.1",
      "Change %": "-0.04%"
  },
  {
      "Date": "30-Oct-03",
      "Price": "5.102",
      "Open": "5.102",
      "High": "5.102",
      "Low": "5.102",
      "Change %": "-0.02%"
  },
  {
      "Date": "29-Oct-03",
      "Price": "5.103",
      "Open": "5.103",
      "High": "5.103",
      "Low": "5.103",
      "Change %": "-1.60%"
  },
  {
      "Date": "28-Oct-03",
      "Price": "5.186",
      "Open": "5.186",
      "High": "5.186",
      "Low": "5.186",
      "Change %": "1.39%"
  },
  {
      "Date": "27-Oct-03",
      "Price": "5.115",
      "Open": "5.115",
      "High": "5.115",
      "Low": "5.115",
      "Change %": "0.43%"
  },
  {
      "Date": "24-Oct-03",
      "Price": "5.093",
      "Open": "5.093",
      "High": "5.093",
      "Low": "5.093",
      "Change %": "-0.70%"
  },
  {
      "Date": "23-Oct-03",
      "Price": "5.129",
      "Open": "5.129",
      "High": "5.129",
      "Low": "5.129",
      "Change %": "1.04%"
  },
  {
      "Date": "22-Oct-03",
      "Price": "5.076",
      "Open": "5.076",
      "High": "5.076",
      "Low": "5.076",
      "Change %": "0.69%"
  },
  {
      "Date": "21-Oct-03",
      "Price": "5.041",
      "Open": "5.041",
      "High": "5.041",
      "Low": "5.041",
      "Change %": "0.68%"
  },
  {
      "Date": "20-Oct-03",
      "Price": "5.007",
      "Open": "5.007",
      "High": "5.007",
      "Low": "5.007",
      "Change %": "0.72%"
  },
  {
      "Date": "18-Oct-03",
      "Price": "4.971",
      "Open": "4.971",
      "High": "4.971",
      "Low": "4.971",
      "Change %": "-0.04%"
  },
  {
      "Date": "17-Oct-03",
      "Price": "4.973",
      "Open": "4.973",
      "High": "4.973",
      "Low": "4.973",
      "Change %": "0.22%"
  },
  {
      "Date": "16-Oct-03",
      "Price": "4.962",
      "Open": "4.962",
      "High": "4.962",
      "Low": "4.962",
      "Change %": "-0.46%"
  },
  {
      "Date": "15-Oct-03",
      "Price": "4.985",
      "Open": "4.985",
      "High": "4.985",
      "Low": "4.985",
      "Change %": "-0.68%"
  },
  {
      "Date": "14-Oct-03",
      "Price": "5.019",
      "Open": "5.019",
      "High": "5.019",
      "Low": "5.019",
      "Change %": "-0.28%"
  },
  {
      "Date": "13-Oct-03",
      "Price": "5.033",
      "Open": "5.033",
      "High": "5.033",
      "Low": "5.033",
      "Change %": "-0.12%"
  },
  {
      "Date": "11-Oct-03",
      "Price": "5.039",
      "Open": "5.039",
      "High": "5.039",
      "Low": "5.039",
      "Change %": "-0.38%"
  },
  {
      "Date": "10-Oct-03",
      "Price": "5.058",
      "Open": "5.058",
      "High": "5.058",
      "Low": "5.058",
      "Change %": "-0.02%"
  },
  {
      "Date": "9-Oct-03",
      "Price": "5.059",
      "Open": "5.059",
      "High": "5.059",
      "Low": "5.059",
      "Change %": "-0.12%"
  },
  {
      "Date": "8-Oct-03",
      "Price": "5.065",
      "Open": "5.065",
      "High": "5.065",
      "Low": "5.065",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Oct-03",
      "Price": "5.065",
      "Open": "5.065",
      "High": "5.065",
      "Low": "5.065",
      "Change %": "-1.07%"
  },
  {
      "Date": "6-Oct-03",
      "Price": "5.12",
      "Open": "5.12",
      "High": "5.12",
      "Low": "5.12",
      "Change %": "-0.76%"
  },
  {
      "Date": "3-Oct-03",
      "Price": "5.159",
      "Open": "5.159",
      "High": "5.159",
      "Low": "5.159",
      "Change %": "0.06%"
  },
  {
      "Date": "1-Oct-03",
      "Price": "5.156",
      "Open": "5.156",
      "High": "5.156",
      "Low": "5.156",
      "Change %": "-0.35%"
  },
  {
      "Date": "30-Sep-03",
      "Price": "5.174",
      "Open": "5.174",
      "High": "5.174",
      "Low": "5.174",
      "Change %": "-1.33%"
  },
  {
      "Date": "29-Sep-03",
      "Price": "5.244",
      "Open": "5.244",
      "High": "5.244",
      "Low": "5.244",
      "Change %": "-0.27%"
  },
  {
      "Date": "27-Sep-03",
      "Price": "5.258",
      "Open": "5.258",
      "High": "5.258",
      "Low": "5.258",
      "Change %": "-0.02%"
  },
  {
      "Date": "26-Sep-03",
      "Price": "5.259",
      "Open": "5.259",
      "High": "5.259",
      "Low": "5.259",
      "Change %": "0.10%"
  },
  {
      "Date": "25-Sep-03",
      "Price": "5.254",
      "Open": "5.254",
      "High": "5.254",
      "Low": "5.254",
      "Change %": "-0.34%"
  },
  {
      "Date": "24-Sep-03",
      "Price": "5.272",
      "Open": "5.272",
      "High": "5.272",
      "Low": "5.272",
      "Change %": "-0.70%"
  },
  {
      "Date": "23-Sep-03",
      "Price": "5.309",
      "Open": "5.309",
      "High": "5.309",
      "Low": "5.309",
      "Change %": "-0.11%"
  },
  {
      "Date": "22-Sep-03",
      "Price": "5.315",
      "Open": "5.315",
      "High": "5.315",
      "Low": "5.315",
      "Change %": "0.08%"
  },
  {
      "Date": "20-Sep-03",
      "Price": "5.311",
      "Open": "5.311",
      "High": "5.311",
      "Low": "5.311",
      "Change %": "-0.04%"
  },
  {
      "Date": "19-Sep-03",
      "Price": "5.313",
      "Open": "5.313",
      "High": "5.313",
      "Low": "5.313",
      "Change %": "-0.02%"
  },
  {
      "Date": "18-Sep-03",
      "Price": "5.314",
      "Open": "5.314",
      "High": "5.314",
      "Low": "5.314",
      "Change %": "0.43%"
  },
  {
      "Date": "17-Sep-03",
      "Price": "5.291",
      "Open": "5.291",
      "High": "5.291",
      "Low": "5.291",
      "Change %": "-0.23%"
  },
  {
      "Date": "16-Sep-03",
      "Price": "5.303",
      "Open": "5.303",
      "High": "5.303",
      "Low": "5.303",
      "Change %": "-0.51%"
  },
  {
      "Date": "15-Sep-03",
      "Price": "5.33",
      "Open": "5.33",
      "High": "5.33",
      "Low": "5.33",
      "Change %": "0.08%"
  },
  {
      "Date": "13-Sep-03",
      "Price": "5.326",
      "Open": "5.326",
      "High": "5.326",
      "Low": "5.326",
      "Change %": "-0.02%"
  },
  {
      "Date": "12-Sep-03",
      "Price": "5.327",
      "Open": "5.327",
      "High": "5.327",
      "Low": "5.327",
      "Change %": "-0.08%"
  },
  {
      "Date": "11-Sep-03",
      "Price": "5.331",
      "Open": "5.331",
      "High": "5.331",
      "Low": "5.331",
      "Change %": "0.09%"
  },
  {
      "Date": "10-Sep-03",
      "Price": "5.326",
      "Open": "5.326",
      "High": "5.326",
      "Low": "5.326",
      "Change %": "0.68%"
  },
  {
      "Date": "9-Sep-03",
      "Price": "5.29",
      "Open": "5.29",
      "High": "5.29",
      "Low": "5.29",
      "Change %": "0.09%"
  },
  {
      "Date": "8-Sep-03",
      "Price": "5.285",
      "Open": "5.285",
      "High": "5.285",
      "Low": "5.285",
      "Change %": "0.17%"
  },
  {
      "Date": "6-Sep-03",
      "Price": "5.276",
      "Open": "5.276",
      "High": "5.276",
      "Low": "5.276",
      "Change %": "-0.08%"
  },
  {
      "Date": "5-Sep-03",
      "Price": "5.28",
      "Open": "5.28",
      "High": "5.28",
      "Low": "5.28",
      "Change %": "0.09%"
  },
  {
      "Date": "4-Sep-03",
      "Price": "5.275",
      "Open": "5.275",
      "High": "5.275",
      "Low": "5.275",
      "Change %": "-0.04%"
  },
  {
      "Date": "3-Sep-03",
      "Price": "5.277",
      "Open": "5.277",
      "High": "5.277",
      "Low": "5.277",
      "Change %": "0.04%"
  },
  {
      "Date": "2-Sep-03",
      "Price": "5.275",
      "Open": "5.275",
      "High": "5.275",
      "Low": "5.275",
      "Change %": "0.09%"
  },
  {
      "Date": "1-Sep-03",
      "Price": "5.27",
      "Open": "5.27",
      "High": "5.27",
      "Low": "5.27",
      "Change %": "0.11%"
  },
  {
      "Date": "30-Aug-03",
      "Price": "5.264",
      "Open": "5.264",
      "High": "5.264",
      "Low": "5.264",
      "Change %": "-0.34%"
  },
  {
      "Date": "29-Aug-03",
      "Price": "5.282",
      "Open": "5.282",
      "High": "5.282",
      "Low": "5.282",
      "Change %": "0.36%"
  },
  {
      "Date": "28-Aug-03",
      "Price": "5.263",
      "Open": "5.263",
      "High": "5.263",
      "Low": "5.263",
      "Change %": "-0.15%"
  },
  {
      "Date": "27-Aug-03",
      "Price": "5.271",
      "Open": "5.271",
      "High": "5.271",
      "Low": "5.271",
      "Change %": "0.55%"
  },
  {
      "Date": "26-Aug-03",
      "Price": "5.242",
      "Open": "5.242",
      "High": "5.242",
      "Low": "5.242",
      "Change %": "-1.13%"
  },
  {
      "Date": "25-Aug-03",
      "Price": "5.302",
      "Open": "5.302",
      "High": "5.302",
      "Low": "5.302",
      "Change %": "-4.64%"
  },
  {
      "Date": "23-Aug-03",
      "Price": "5.56",
      "Open": "5.56",
      "High": "5.56",
      "Low": "5.56",
      "Change %": "-0.23%"
  },
  {
      "Date": "22-Aug-03",
      "Price": "5.573",
      "Open": "5.573",
      "High": "5.573",
      "Low": "5.573",
      "Change %": "0.58%"
  },
  {
      "Date": "21-Aug-03",
      "Price": "5.541",
      "Open": "5.541",
      "High": "5.541",
      "Low": "5.541",
      "Change %": "0.11%"
  },
  {
      "Date": "20-Aug-03",
      "Price": "5.535",
      "Open": "5.535",
      "High": "5.535",
      "Low": "5.535",
      "Change %": "0.04%"
  },
  {
      "Date": "19-Aug-03",
      "Price": "5.533",
      "Open": "5.533",
      "High": "5.533",
      "Low": "5.533",
      "Change %": "0.02%"
  },
  {
      "Date": "18-Aug-03",
      "Price": "5.532",
      "Open": "5.532",
      "High": "5.532",
      "Low": "5.532",
      "Change %": "-0.11%"
  },
  {
      "Date": "16-Aug-03",
      "Price": "5.538",
      "Open": "5.538",
      "High": "5.538",
      "Low": "5.538",
      "Change %": "-0.02%"
  },
  {
      "Date": "14-Aug-03",
      "Price": "5.539",
      "Open": "5.539",
      "High": "5.539",
      "Low": "5.539",
      "Change %": "0.33%"
  },
  {
      "Date": "13-Aug-03",
      "Price": "5.521",
      "Open": "5.521",
      "High": "5.521",
      "Low": "5.521",
      "Change %": "-0.07%"
  },
  {
      "Date": "12-Aug-03",
      "Price": "5.525",
      "Open": "5.525",
      "High": "5.525",
      "Low": "5.525",
      "Change %": "-0.05%"
  },
  {
      "Date": "11-Aug-03",
      "Price": "5.528",
      "Open": "5.528",
      "High": "5.528",
      "Low": "5.528",
      "Change %": "0.09%"
  },
  {
      "Date": "9-Aug-03",
      "Price": "5.523",
      "Open": "5.523",
      "High": "5.523",
      "Low": "5.523",
      "Change %": "-0.54%"
  },
  {
      "Date": "8-Aug-03",
      "Price": "5.553",
      "Open": "5.553",
      "High": "5.553",
      "Low": "5.553",
      "Change %": "-0.13%"
  },
  {
      "Date": "7-Aug-03",
      "Price": "5.56",
      "Open": "5.56",
      "High": "5.56",
      "Low": "5.56",
      "Change %": "-0.07%"
  },
  {
      "Date": "6-Aug-03",
      "Price": "5.564",
      "Open": "5.564",
      "High": "5.564",
      "Low": "5.564",
      "Change %": "-0.14%"
  },
  {
      "Date": "5-Aug-03",
      "Price": "5.572",
      "Open": "5.572",
      "High": "5.572",
      "Low": "5.572",
      "Change %": "-0.02%"
  },
  {
      "Date": "4-Aug-03",
      "Price": "5.573",
      "Open": "5.573",
      "High": "5.573",
      "Low": "5.573",
      "Change %": "0.45%"
  },
  {
      "Date": "2-Aug-03",
      "Price": "5.548",
      "Open": "5.548",
      "High": "5.548",
      "Low": "5.548",
      "Change %": "-0.89%"
  },
  {
      "Date": "1-Aug-03",
      "Price": "5.598",
      "Open": "5.598",
      "High": "5.598",
      "Low": "5.598",
      "Change %": "-0.32%"
  },
  {
      "Date": "31-Jul-03",
      "Price": "5.616",
      "Open": "5.616",
      "High": "5.616",
      "Low": "5.616",
      "Change %": "-0.37%"
  },
  {
      "Date": "30-Jul-03",
      "Price": "5.637",
      "Open": "5.637",
      "High": "5.637",
      "Low": "5.637",
      "Change %": "0.00%"
  },
  {
      "Date": "29-Jul-03",
      "Price": "5.637",
      "Open": "5.637",
      "High": "5.637",
      "Low": "5.637",
      "Change %": "-0.02%"
  },
  {
      "Date": "28-Jul-03",
      "Price": "5.638",
      "Open": "5.638",
      "High": "5.638",
      "Low": "5.638",
      "Change %": "0.05%"
  },
  {
      "Date": "26-Jul-03",
      "Price": "5.635",
      "Open": "5.635",
      "High": "5.635",
      "Low": "5.635",
      "Change %": "0.09%"
  },
  {
      "Date": "25-Jul-03",
      "Price": "5.63",
      "Open": "5.63",
      "High": "5.63",
      "Low": "5.63",
      "Change %": "-0.65%"
  },
  {
      "Date": "24-Jul-03",
      "Price": "5.667",
      "Open": "5.667",
      "High": "5.667",
      "Low": "5.667",
      "Change %": "-0.39%"
  },
  {
      "Date": "23-Jul-03",
      "Price": "5.689",
      "Open": "5.689",
      "High": "5.689",
      "Low": "5.689",
      "Change %": "-0.23%"
  },
  {
      "Date": "22-Jul-03",
      "Price": "5.702",
      "Open": "5.702",
      "High": "5.702",
      "Low": "5.702",
      "Change %": "0.07%"
  },
  {
      "Date": "21-Jul-03",
      "Price": "5.698",
      "Open": "5.698",
      "High": "5.698",
      "Low": "5.698",
      "Change %": "-0.09%"
  },
  {
      "Date": "19-Jul-03",
      "Price": "5.703",
      "Open": "5.703",
      "High": "5.703",
      "Low": "5.703",
      "Change %": "-0.33%"
  },
  {
      "Date": "18-Jul-03",
      "Price": "5.722",
      "Open": "5.722",
      "High": "5.722",
      "Low": "5.722",
      "Change %": "0.02%"
  },
  {
      "Date": "17-Jul-03",
      "Price": "5.721",
      "Open": "5.721",
      "High": "5.721",
      "Low": "5.721",
      "Change %": "0.23%"
  },
  {
      "Date": "16-Jul-03",
      "Price": "5.708",
      "Open": "5.708",
      "High": "5.708",
      "Low": "5.708",
      "Change %": "0.11%"
  },
  {
      "Date": "15-Jul-03",
      "Price": "5.702",
      "Open": "5.702",
      "High": "5.702",
      "Low": "5.702",
      "Change %": "-0.05%"
  },
  {
      "Date": "14-Jul-03",
      "Price": "5.705",
      "Open": "5.705",
      "High": "5.705",
      "Low": "5.705",
      "Change %": "-0.07%"
  },
  {
      "Date": "12-Jul-03",
      "Price": "5.709",
      "Open": "5.709",
      "High": "5.709",
      "Low": "5.709",
      "Change %": "0.05%"
  },
  {
      "Date": "11-Jul-03",
      "Price": "5.706",
      "Open": "5.706",
      "High": "5.706",
      "Low": "5.706",
      "Change %": "-0.02%"
  },
  {
      "Date": "10-Jul-03",
      "Price": "5.707",
      "Open": "5.707",
      "High": "5.707",
      "Low": "5.707",
      "Change %": "0.07%"
  },
  {
      "Date": "9-Jul-03",
      "Price": "5.703",
      "Open": "5.703",
      "High": "5.703",
      "Low": "5.703",
      "Change %": "-0.40%"
  },
  {
      "Date": "8-Jul-03",
      "Price": "5.726",
      "Open": "5.726",
      "High": "5.726",
      "Low": "5.726",
      "Change %": "-0.02%"
  },
  {
      "Date": "7-Jul-03",
      "Price": "5.727",
      "Open": "5.727",
      "High": "5.727",
      "Low": "5.727",
      "Change %": "0.25%"
  },
  {
      "Date": "5-Jul-03",
      "Price": "5.713",
      "Open": "5.713",
      "High": "5.713",
      "Low": "5.713",
      "Change %": "0.07%"
  },
  {
      "Date": "4-Jul-03",
      "Price": "5.709",
      "Open": "5.709",
      "High": "5.709",
      "Low": "5.709",
      "Change %": "-0.24%"
  },
  {
      "Date": "3-Jul-03",
      "Price": "5.723",
      "Open": "5.723",
      "High": "5.723",
      "Low": "5.723",
      "Change %": "0.00%"
  },
  {
      "Date": "2-Jul-03",
      "Price": "5.723",
      "Open": "5.723",
      "High": "5.723",
      "Low": "5.723",
      "Change %": "-0.07%"
  },
  {
      "Date": "1-Jul-03",
      "Price": "5.727",
      "Open": "5.727",
      "High": "5.727",
      "Low": "5.727",
      "Change %": "-0.02%"
  },
  {
      "Date": "28-Jun-03",
      "Price": "5.728",
      "Open": "5.728",
      "High": "5.728",
      "Low": "5.728",
      "Change %": "-0.03%"
  },
  {
      "Date": "27-Jun-03",
      "Price": "5.73",
      "Open": "5.73",
      "High": "5.73",
      "Low": "5.73",
      "Change %": "-0.07%"
  },
  {
      "Date": "26-Jun-03",
      "Price": "5.734",
      "Open": "5.734",
      "High": "5.734",
      "Low": "5.734",
      "Change %": "0.44%"
  },
  {
      "Date": "25-Jun-03",
      "Price": "5.709",
      "Open": "5.709",
      "High": "5.709",
      "Low": "5.709",
      "Change %": "-0.16%"
  },
  {
      "Date": "24-Jun-03",
      "Price": "5.718",
      "Open": "5.718",
      "High": "5.718",
      "Low": "5.718",
      "Change %": "-0.03%"
  },
  {
      "Date": "23-Jun-03",
      "Price": "5.72",
      "Open": "5.72",
      "High": "5.72",
      "Low": "5.72",
      "Change %": "0.00%"
  },
  {
      "Date": "21-Jun-03",
      "Price": "5.72",
      "Open": "5.72",
      "High": "5.72",
      "Low": "5.72",
      "Change %": "-0.21%"
  },
  {
      "Date": "20-Jun-03",
      "Price": "5.732",
      "Open": "5.732",
      "High": "5.732",
      "Low": "5.732",
      "Change %": "-0.21%"
  },
  {
      "Date": "19-Jun-03",
      "Price": "5.744",
      "Open": "5.744",
      "High": "5.744",
      "Low": "5.744",
      "Change %": "-0.02%"
  },
  {
      "Date": "18-Jun-03",
      "Price": "5.745",
      "Open": "5.745",
      "High": "5.745",
      "Low": "5.745",
      "Change %": "0.03%"
  },
  {
      "Date": "17-Jun-03",
      "Price": "5.743",
      "Open": "5.743",
      "High": "5.743",
      "Low": "5.743",
      "Change %": "-0.07%"
  },
  {
      "Date": "16-Jun-03",
      "Price": "5.747",
      "Open": "5.747",
      "High": "5.747",
      "Low": "5.747",
      "Change %": "-0.28%"
  },
  {
      "Date": "14-Jun-03",
      "Price": "5.763",
      "Open": "5.763",
      "High": "5.763",
      "Low": "5.763",
      "Change %": "-0.10%"
  },
  {
      "Date": "13-Jun-03",
      "Price": "5.769",
      "Open": "5.769",
      "High": "5.769",
      "Low": "5.769",
      "Change %": "0.09%"
  },
  {
      "Date": "12-Jun-03",
      "Price": "5.764",
      "Open": "5.764",
      "High": "5.764",
      "Low": "5.764",
      "Change %": "0.02%"
  },
  {
      "Date": "11-Jun-03",
      "Price": "5.763",
      "Open": "5.763",
      "High": "5.763",
      "Low": "5.763",
      "Change %": "-0.21%"
  },
  {
      "Date": "10-Jun-03",
      "Price": "5.775",
      "Open": "5.775",
      "High": "5.775",
      "Low": "5.775",
      "Change %": "0.26%"
  },
  {
      "Date": "9-Jun-03",
      "Price": "5.76",
      "Open": "5.76",
      "High": "5.76",
      "Low": "5.76",
      "Change %": "-0.07%"
  },
  {
      "Date": "7-Jun-03",
      "Price": "5.764",
      "Open": "5.764",
      "High": "5.764",
      "Low": "5.764",
      "Change %": "-0.07%"
  },
  {
      "Date": "6-Jun-03",
      "Price": "5.768",
      "Open": "5.768",
      "High": "5.768",
      "Low": "5.768",
      "Change %": "-0.16%"
  },
  {
      "Date": "5-Jun-03",
      "Price": "5.777",
      "Open": "5.777",
      "High": "5.777",
      "Low": "5.777",
      "Change %": "-0.29%"
  },
  {
      "Date": "4-Jun-03",
      "Price": "5.794",
      "Open": "5.794",
      "High": "5.794",
      "Low": "5.794",
      "Change %": "-0.28%"
  },
  {
      "Date": "3-Jun-03",
      "Price": "5.81",
      "Open": "5.81",
      "High": "5.81",
      "Low": "5.81",
      "Change %": "-0.05%"
  },
  {
      "Date": "2-Jun-03",
      "Price": "5.813",
      "Open": "5.813",
      "High": "5.813",
      "Low": "5.813",
      "Change %": "0.28%"
  },
  {
      "Date": "31-May-03",
      "Price": "5.797",
      "Open": "5.797",
      "High": "5.797",
      "Low": "5.797",
      "Change %": "0.07%"
  },
  {
      "Date": "30-May-03",
      "Price": "5.793",
      "Open": "5.793",
      "High": "5.793",
      "Low": "5.793",
      "Change %": "-0.12%"
  },
  {
      "Date": "29-May-03",
      "Price": "5.8",
      "Open": "5.8",
      "High": "5.8",
      "Low": "5.8",
      "Change %": "0.26%"
  },
  {
      "Date": "28-May-03",
      "Price": "5.785",
      "Open": "5.785",
      "High": "5.785",
      "Low": "5.785",
      "Change %": "-0.21%"
  },
  {
      "Date": "27-May-03",
      "Price": "5.797",
      "Open": "5.797",
      "High": "5.797",
      "Low": "5.797",
      "Change %": "0.17%"
  },
  {
      "Date": "26-May-03",
      "Price": "5.787",
      "Open": "5.787",
      "High": "5.787",
      "Low": "5.787",
      "Change %": "-0.12%"
  },
  {
      "Date": "24-May-03",
      "Price": "5.794",
      "Open": "5.794",
      "High": "5.794",
      "Low": "5.794",
      "Change %": "-0.19%"
  },
  {
      "Date": "23-May-03",
      "Price": "5.805",
      "Open": "5.805",
      "High": "5.805",
      "Low": "5.805",
      "Change %": "-0.50%"
  },
  {
      "Date": "22-May-03",
      "Price": "5.834",
      "Open": "5.834",
      "High": "5.834",
      "Low": "5.834",
      "Change %": "0.00%"
  },
  {
      "Date": "21-May-03",
      "Price": "5.834",
      "Open": "5.834",
      "High": "5.834",
      "Low": "5.834",
      "Change %": "-0.44%"
  },
  {
      "Date": "20-May-03",
      "Price": "5.86",
      "Open": "5.86",
      "High": "5.86",
      "Low": "5.86",
      "Change %": "-0.05%"
  },
  {
      "Date": "19-May-03",
      "Price": "5.863",
      "Open": "5.863",
      "High": "5.863",
      "Low": "5.863",
      "Change %": "-0.12%"
  },
  {
      "Date": "17-May-03",
      "Price": "5.87",
      "Open": "5.87",
      "High": "5.87",
      "Low": "5.87",
      "Change %": "-0.46%"
  },
  {
      "Date": "14-May-03",
      "Price": "5.897",
      "Open": "5.897",
      "High": "5.897",
      "Low": "5.897",
      "Change %": "-0.05%"
  },
  {
      "Date": "13-May-03",
      "Price": "5.9",
      "Open": "5.9",
      "High": "5.9",
      "Low": "5.9",
      "Change %": "-0.32%"
  },
  {
      "Date": "12-May-03",
      "Price": "5.919",
      "Open": "5.919",
      "High": "5.919",
      "Low": "5.919",
      "Change %": "0.03%"
  },
  {
      "Date": "10-May-03",
      "Price": "5.917",
      "Open": "5.917",
      "High": "5.917",
      "Low": "5.917",
      "Change %": "0.07%"
  },
  {
      "Date": "9-May-03",
      "Price": "5.913",
      "Open": "5.913",
      "High": "5.913",
      "Low": "5.913",
      "Change %": "-0.02%"
  },
  {
      "Date": "8-May-03",
      "Price": "5.914",
      "Open": "5.914",
      "High": "5.914",
      "Low": "5.914",
      "Change %": "-0.39%"
  },
  {
      "Date": "7-May-03",
      "Price": "5.937",
      "Open": "5.937",
      "High": "5.937",
      "Low": "5.937",
      "Change %": "-0.15%"
  },
  {
      "Date": "6-May-03",
      "Price": "5.946",
      "Open": "5.946",
      "High": "5.946",
      "Low": "5.946",
      "Change %": "0.19%"
  },
  {
      "Date": "5-May-03",
      "Price": "5.935",
      "Open": "5.935",
      "High": "5.935",
      "Low": "5.935",
      "Change %": "-0.29%"
  },
  {
      "Date": "3-May-03",
      "Price": "5.952",
      "Open": "5.952",
      "High": "5.952",
      "Low": "5.952",
      "Change %": "0.25%"
  },
  {
      "Date": "2-May-03",
      "Price": "5.937",
      "Open": "5.937",
      "High": "5.937",
      "Low": "5.937",
      "Change %": "0.94%"
  },
  {
      "Date": "30-Apr-03",
      "Price": "5.882",
      "Open": "5.882",
      "High": "5.882",
      "Low": "5.882",
      "Change %": "-0.86%"
  },
  {
      "Date": "29-Apr-03",
      "Price": "5.933",
      "Open": "5.933",
      "High": "5.933",
      "Low": "5.933",
      "Change %": "0.75%"
  },
  {
      "Date": "28-Apr-03",
      "Price": "5.889",
      "Open": "5.889",
      "High": "5.889",
      "Low": "5.889",
      "Change %": "0.00%"
  },
  {
      "Date": "26-Apr-03",
      "Price": "5.889",
      "Open": "5.889",
      "High": "5.889",
      "Low": "5.889",
      "Change %": "0.15%"
  },
  {
      "Date": "25-Apr-03",
      "Price": "5.88",
      "Open": "5.88",
      "High": "5.88",
      "Low": "5.88",
      "Change %": "-0.61%"
  },
  {
      "Date": "24-Apr-03",
      "Price": "5.916",
      "Open": "5.916",
      "High": "5.916",
      "Low": "5.916",
      "Change %": "0.31%"
  },
  {
      "Date": "23-Apr-03",
      "Price": "5.898",
      "Open": "5.898",
      "High": "5.898",
      "Low": "5.898",
      "Change %": "0.08%"
  },
  {
      "Date": "22-Apr-03",
      "Price": "5.893",
      "Open": "5.893",
      "High": "5.893",
      "Low": "5.893",
      "Change %": "0.37%"
  },
  {
      "Date": "21-Apr-03",
      "Price": "5.871",
      "Open": "5.871",
      "High": "5.871",
      "Low": "5.871",
      "Change %": "0.17%"
  },
  {
      "Date": "19-Apr-03",
      "Price": "5.861",
      "Open": "5.861",
      "High": "5.861",
      "Low": "5.861",
      "Change %": "-0.12%"
  },
  {
      "Date": "17-Apr-03",
      "Price": "5.868",
      "Open": "5.868",
      "High": "5.868",
      "Low": "5.868",
      "Change %": "-0.10%"
  },
  {
      "Date": "16-Apr-03",
      "Price": "5.874",
      "Open": "5.874",
      "High": "5.874",
      "Low": "5.874",
      "Change %": "0.09%"
  },
  {
      "Date": "12-Apr-03",
      "Price": "5.869",
      "Open": "5.869",
      "High": "5.869",
      "Low": "5.869",
      "Change %": "-0.15%"
  },
  {
      "Date": "10-Apr-03",
      "Price": "5.878",
      "Open": "5.878",
      "High": "5.878",
      "Low": "5.878",
      "Change %": "-0.31%"
  },
  {
      "Date": "9-Apr-03",
      "Price": "5.896",
      "Open": "5.896",
      "High": "5.896",
      "Low": "5.896",
      "Change %": "-1.01%"
  },
  {
      "Date": "8-Apr-03",
      "Price": "5.956",
      "Open": "5.956",
      "High": "5.956",
      "Low": "5.956",
      "Change %": "-0.23%"
  },
  {
      "Date": "7-Apr-03",
      "Price": "5.97",
      "Open": "5.97",
      "High": "5.97",
      "Low": "5.97",
      "Change %": "-0.08%"
  },
  {
      "Date": "5-Apr-03",
      "Price": "5.975",
      "Open": "5.975",
      "High": "5.975",
      "Low": "5.975",
      "Change %": "-0.27%"
  },
  {
      "Date": "4-Apr-03",
      "Price": "5.991",
      "Open": "5.991",
      "High": "5.991",
      "Low": "5.991",
      "Change %": "0.13%"
  },
  {
      "Date": "3-Apr-03",
      "Price": "5.983",
      "Open": "5.983",
      "High": "5.983",
      "Low": "5.983",
      "Change %": "-1.45%"
  },
  {
      "Date": "1-Apr-03",
      "Price": "6.071",
      "Open": "6.071",
      "High": "6.071",
      "Low": "6.071",
      "Change %": "-0.91%"
  },
  {
      "Date": "31-Mar-03",
      "Price": "6.127",
      "Open": "6.127",
      "High": "6.127",
      "Low": "6.127",
      "Change %": "-2.19%"
  },
  {
      "Date": "29-Mar-03",
      "Price": "6.264",
      "Open": "6.264",
      "High": "6.264",
      "Low": "6.264",
      "Change %": "-0.76%"
  },
  {
      "Date": "28-Mar-03",
      "Price": "6.312",
      "Open": "6.312",
      "High": "6.312",
      "Low": "6.312",
      "Change %": "0.37%"
  },
  {
      "Date": "27-Mar-03",
      "Price": "6.289",
      "Open": "6.289",
      "High": "6.289",
      "Low": "6.289",
      "Change %": "-0.93%"
  },
  {
      "Date": "26-Mar-03",
      "Price": "6.348",
      "Open": "6.348",
      "High": "6.348",
      "Low": "6.348",
      "Change %": "-0.19%"
  },
  {
      "Date": "25-Mar-03",
      "Price": "6.36",
      "Open": "6.36",
      "High": "6.36",
      "Low": "6.36",
      "Change %": "-0.41%"
  },
  {
      "Date": "24-Mar-03",
      "Price": "6.386",
      "Open": "6.386",
      "High": "6.386",
      "Low": "6.386",
      "Change %": "2.36%"
  },
  {
      "Date": "22-Mar-03",
      "Price": "6.239",
      "Open": "6.239",
      "High": "6.239",
      "Low": "6.239",
      "Change %": "-0.67%"
  },
  {
      "Date": "21-Mar-03",
      "Price": "6.281",
      "Open": "6.281",
      "High": "6.281",
      "Low": "6.281",
      "Change %": "-2.62%"
  },
  {
      "Date": "20-Mar-03",
      "Price": "6.45",
      "Open": "6.45",
      "High": "6.45",
      "Low": "6.45",
      "Change %": "-0.11%"
  },
  {
      "Date": "19-Mar-03",
      "Price": "6.457",
      "Open": "6.457",
      "High": "6.457",
      "Low": "6.457",
      "Change %": "-0.11%"
  },
  {
      "Date": "17-Mar-03",
      "Price": "6.464",
      "Open": "6.464",
      "High": "6.464",
      "Low": "6.464",
      "Change %": "2.12%"
  },
  {
      "Date": "15-Mar-03",
      "Price": "6.33",
      "Open": "6.33",
      "High": "6.33",
      "Low": "6.33",
      "Change %": "-0.67%"
  },
  {
      "Date": "13-Mar-03",
      "Price": "6.373",
      "Open": "6.373",
      "High": "6.373",
      "Low": "6.373",
      "Change %": "0.17%"
  },
  {
      "Date": "12-Mar-03",
      "Price": "6.362",
      "Open": "6.362",
      "High": "6.362",
      "Low": "6.362",
      "Change %": "-0.81%"
  },
  {
      "Date": "11-Mar-03",
      "Price": "6.414",
      "Open": "6.414",
      "High": "6.414",
      "Low": "6.414",
      "Change %": "-1.00%"
  },
  {
      "Date": "10-Mar-03",
      "Price": "6.479",
      "Open": "6.479",
      "High": "6.479",
      "Low": "6.479",
      "Change %": "0.54%"
  },
  {
      "Date": "8-Mar-03",
      "Price": "6.444",
      "Open": "6.444",
      "High": "6.444",
      "Low": "6.444",
      "Change %": "0.30%"
  },
  {
      "Date": "7-Mar-03",
      "Price": "6.425",
      "Open": "6.425",
      "High": "6.425",
      "Low": "6.425",
      "Change %": "2.95%"
  },
  {
      "Date": "6-Mar-03",
      "Price": "6.241",
      "Open": "6.241",
      "High": "6.241",
      "Low": "6.241",
      "Change %": "-0.79%"
  },
  {
      "Date": "5-Mar-03",
      "Price": "6.291",
      "Open": "6.291",
      "High": "6.291",
      "Low": "6.291",
      "Change %": "1.39%"
  },
  {
      "Date": "4-Mar-03",
      "Price": "6.205",
      "Open": "6.205",
      "High": "6.205",
      "Low": "6.205",
      "Change %": "1.47%"
  },
  {
      "Date": "3-Mar-03",
      "Price": "6.115",
      "Open": "6.115",
      "High": "6.115",
      "Low": "6.115",
      "Change %": "3.64%"
  },
  {
      "Date": "1-Mar-03",
      "Price": "5.9",
      "Open": "5.9",
      "High": "5.9",
      "Low": "5.9",
      "Change %": "-1.11%"
  },
  {
      "Date": "28-Feb-03",
      "Price": "5.966",
      "Open": "5.966",
      "High": "5.966",
      "Low": "5.966",
      "Change %": "-7.80%"
  },
  {
      "Date": "27-Feb-03",
      "Price": "6.471",
      "Open": "6.471",
      "High": "6.471",
      "Low": "6.471",
      "Change %": "0.65%"
  },
  {
      "Date": "26-Feb-03",
      "Price": "6.429",
      "Open": "6.429",
      "High": "6.429",
      "Low": "6.429",
      "Change %": "-1.35%"
  },
  {
      "Date": "25-Feb-03",
      "Price": "6.517",
      "Open": "6.517",
      "High": "6.517",
      "Low": "6.517",
      "Change %": "-1.99%"
  },
  {
      "Date": "24-Feb-03",
      "Price": "6.649",
      "Open": "6.649",
      "High": "6.649",
      "Low": "6.649",
      "Change %": "0.30%"
  },
  {
      "Date": "22-Feb-03",
      "Price": "6.629",
      "Open": "6.629",
      "High": "6.629",
      "Low": "6.629",
      "Change %": "0.24%"
  },
  {
      "Date": "21-Feb-03",
      "Price": "6.613",
      "Open": "6.613",
      "High": "6.613",
      "Low": "6.613",
      "Change %": "1.07%"
  },
  {
      "Date": "20-Feb-03",
      "Price": "6.543",
      "Open": "6.543",
      "High": "6.543",
      "Low": "6.543",
      "Change %": "0.35%"
  },
  {
      "Date": "19-Feb-03",
      "Price": "6.52",
      "Open": "6.52",
      "High": "6.52",
      "Low": "6.52",
      "Change %": "1.62%"
  },
  {
      "Date": "18-Feb-03",
      "Price": "6.416",
      "Open": "6.416",
      "High": "6.416",
      "Low": "6.416",
      "Change %": "1.45%"
  },
  {
      "Date": "17-Feb-03",
      "Price": "6.324",
      "Open": "6.324",
      "High": "6.324",
      "Low": "6.324",
      "Change %": "-3.29%"
  },
  {
      "Date": "15-Feb-03",
      "Price": "6.539",
      "Open": "6.539",
      "High": "6.539",
      "Low": "6.539",
      "Change %": "-2.39%"
  },
  {
      "Date": "14-Feb-03",
      "Price": "6.699",
      "Open": "6.699",
      "High": "6.699",
      "Low": "6.699",
      "Change %": "0.69%"
  },
  {
      "Date": "12-Feb-03",
      "Price": "6.653",
      "Open": "6.653",
      "High": "6.653",
      "Low": "6.653",
      "Change %": "1.88%"
  },
  {
      "Date": "11-Feb-03",
      "Price": "6.53",
      "Open": "6.53",
      "High": "6.53",
      "Low": "6.53",
      "Change %": "1.43%"
  },
  {
      "Date": "10-Feb-03",
      "Price": "6.438",
      "Open": "6.438",
      "High": "6.438",
      "Low": "6.438",
      "Change %": "-1.95%"
  },
  {
      "Date": "8-Feb-03",
      "Price": "6.566",
      "Open": "6.566",
      "High": "6.566",
      "Low": "6.566",
      "Change %": "2.42%"
  },
  {
      "Date": "7-Feb-03",
      "Price": "6.411",
      "Open": "6.411",
      "High": "6.411",
      "Low": "6.411",
      "Change %": "2.10%"
  },
  {
      "Date": "6-Feb-03",
      "Price": "6.279",
      "Open": "6.279",
      "High": "6.279",
      "Low": "6.279",
      "Change %": "0.45%"
  },
  {
      "Date": "5-Feb-03",
      "Price": "6.251",
      "Open": "6.251",
      "High": "6.251",
      "Low": "6.251",
      "Change %": "0.53%"
  },
  {
      "Date": "4-Feb-03",
      "Price": "6.218",
      "Open": "6.218",
      "High": "6.218",
      "Low": "6.218",
      "Change %": "0.55%"
  },
  {
      "Date": "3-Feb-03",
      "Price": "6.184",
      "Open": "6.184",
      "High": "6.184",
      "Low": "6.184",
      "Change %": "0.63%"
  },
  {
      "Date": "1-Feb-03",
      "Price": "6.145",
      "Open": "6.145",
      "High": "6.145",
      "Low": "6.145",
      "Change %": "-2.82%"
  },
  {
      "Date": "31-Jan-03",
      "Price": "6.323",
      "Open": "6.323",
      "High": "6.323",
      "Low": "6.323",
      "Change %": "0.46%"
  },
  {
      "Date": "30-Jan-03",
      "Price": "6.294",
      "Open": "6.294",
      "High": "6.294",
      "Low": "6.294",
      "Change %": "3.42%"
  },
  {
      "Date": "29-Jan-03",
      "Price": "6.086",
      "Open": "6.086",
      "High": "6.086",
      "Low": "6.086",
      "Change %": "-1.82%"
  },
  {
      "Date": "28-Jan-03",
      "Price": "6.199",
      "Open": "6.199",
      "High": "6.199",
      "Low": "6.199",
      "Change %": "2.02%"
  },
  {
      "Date": "27-Jan-03",
      "Price": "6.076",
      "Open": "6.076",
      "High": "6.076",
      "Low": "6.076",
      "Change %": "1.69%"
  },
  {
      "Date": "25-Jan-03",
      "Price": "5.975",
      "Open": "5.975",
      "High": "5.975",
      "Low": "5.975",
      "Change %": "0.34%"
  },
  {
      "Date": "24-Jan-03",
      "Price": "5.955",
      "Open": "5.955",
      "High": "5.955",
      "Low": "5.955",
      "Change %": "1.16%"
  },
  {
      "Date": "23-Jan-03",
      "Price": "5.887",
      "Open": "5.887",
      "High": "5.887",
      "Low": "5.887",
      "Change %": "0.50%"
  },
  {
      "Date": "22-Jan-03",
      "Price": "5.858",
      "Open": "5.858",
      "High": "5.858",
      "Low": "5.858",
      "Change %": "-0.14%"
  },
  {
      "Date": "21-Jan-03",
      "Price": "5.866",
      "Open": "5.866",
      "High": "5.866",
      "Low": "5.866",
      "Change %": "-0.10%"
  },
  {
      "Date": "20-Jan-03",
      "Price": "5.872",
      "Open": "5.872",
      "High": "5.872",
      "Low": "5.872",
      "Change %": "0.14%"
  },
  {
      "Date": "18-Jan-03",
      "Price": "5.864",
      "Open": "5.864",
      "High": "5.864",
      "Low": "5.864",
      "Change %": "-0.37%"
  },
  {
      "Date": "17-Jan-03",
      "Price": "5.886",
      "Open": "5.886",
      "High": "5.886",
      "Low": "5.886",
      "Change %": "-0.79%"
  },
  {
      "Date": "16-Jan-03",
      "Price": "5.933",
      "Open": "5.933",
      "High": "5.933",
      "Low": "5.933",
      "Change %": "-0.20%"
  },
  {
      "Date": "15-Jan-03",
      "Price": "5.945",
      "Open": "5.945",
      "High": "5.945",
      "Low": "5.945",
      "Change %": "0.19%"
  },
  {
      "Date": "14-Jan-03",
      "Price": "5.934",
      "Open": "5.934",
      "High": "5.934",
      "Low": "5.934",
      "Change %": "-0.97%"
  },
  {
      "Date": "13-Jan-03",
      "Price": "5.992",
      "Open": "5.992",
      "High": "5.992",
      "Low": "5.992",
      "Change %": "-0.28%"
  },
  {
      "Date": "11-Jan-03",
      "Price": "6.009",
      "Open": "6.009",
      "High": "6.009",
      "Low": "6.009",
      "Change %": "-0.08%"
  },
  {
      "Date": "10-Jan-03",
      "Price": "6.014",
      "Open": "6.014",
      "High": "6.014",
      "Low": "6.014",
      "Change %": "-0.48%"
  },
  {
      "Date": "9-Jan-03",
      "Price": "6.043",
      "Open": "6.043",
      "High": "6.043",
      "Low": "6.043",
      "Change %": "0.32%"
  },
  {
      "Date": "8-Jan-03",
      "Price": "6.024",
      "Open": "6.024",
      "High": "6.024",
      "Low": "6.024",
      "Change %": "0.13%"
  },
  {
      "Date": "7-Jan-03",
      "Price": "6.016",
      "Open": "6.016",
      "High": "6.016",
      "Low": "6.016",
      "Change %": "-0.02%"
  },
  {
      "Date": "6-Jan-03",
      "Price": "6.017",
      "Open": "6.017",
      "High": "6.017",
      "Low": "6.017",
      "Change %": "-0.64%"
  },
  {
      "Date": "4-Jan-03",
      "Price": "6.056",
      "Open": "6.056",
      "High": "6.056",
      "Low": "6.056",
      "Change %": "0.17%"
  },
  {
      "Date": "3-Jan-03",
      "Price": "6.046",
      "Open": "6.046",
      "High": "6.046",
      "Low": "6.046",
      "Change %": "0.67%"
  },
  {
      "Date": "2-Jan-03",
      "Price": "6.006",
      "Open": "6.006",
      "High": "6.006",
      "Low": "6.006",
      "Change %": "-0.43%"
  },
  {
      "Date": "1-Jan-03",
      "Price": "6.032",
      "Open": "6.032",
      "High": "6.032",
      "Low": "6.032",
      "Change %": "-0.84%"
  },
  {
      "Date": "31-Dec-02",
      "Price": "6.083",
      "Open": "6.083",
      "High": "6.083",
      "Low": "6.083",
      "Change %": "-0.47%"
  },
  {
      "Date": "30-Dec-02",
      "Price": "6.112",
      "Open": "6.112",
      "High": "6.112",
      "Low": "6.112",
      "Change %": "-1.53%"
  },
  {
      "Date": "28-Dec-02",
      "Price": "6.207",
      "Open": "6.207",
      "High": "6.207",
      "Low": "6.207",
      "Change %": "-0.13%"
  },
  {
      "Date": "27-Dec-02",
      "Price": "6.215",
      "Open": "6.215",
      "High": "6.215",
      "Low": "6.215",
      "Change %": "-0.61%"
  },
  {
      "Date": "26-Dec-02",
      "Price": "6.253",
      "Open": "6.253",
      "High": "6.253",
      "Low": "6.253",
      "Change %": "-0.45%"
  },
  {
      "Date": "24-Dec-02",
      "Price": "6.281",
      "Open": "6.281",
      "High": "6.281",
      "Low": "6.281",
      "Change %": "-0.25%"
  },
  {
      "Date": "23-Dec-02",
      "Price": "6.297",
      "Open": "6.297",
      "High": "6.297",
      "Low": "6.297",
      "Change %": "0.32%"
  },
  {
      "Date": "21-Dec-02",
      "Price": "6.277",
      "Open": "6.277",
      "High": "6.277",
      "Low": "6.277",
      "Change %": "-0.55%"
  },
  {
      "Date": "20-Dec-02",
      "Price": "6.312",
      "Open": "6.312",
      "High": "6.312",
      "Low": "6.312",
      "Change %": "-0.28%"
  },
  {
      "Date": "19-Dec-02",
      "Price": "6.33",
      "Open": "6.33",
      "High": "6.33",
      "Low": "6.33",
      "Change %": "-0.05%"
  },
  {
      "Date": "18-Dec-02",
      "Price": "6.333",
      "Open": "6.333",
      "High": "6.333",
      "Low": "6.333",
      "Change %": "0.16%"
  },
  {
      "Date": "17-Dec-02",
      "Price": "6.323",
      "Open": "6.323",
      "High": "6.323",
      "Low": "6.323",
      "Change %": "-0.27%"
  },
  {
      "Date": "16-Dec-02",
      "Price": "6.34",
      "Open": "6.34",
      "High": "6.34",
      "Low": "6.34",
      "Change %": "-0.31%"
  },
  {
      "Date": "14-Dec-02",
      "Price": "6.36",
      "Open": "6.36",
      "High": "6.36",
      "Low": "6.36",
      "Change %": "-0.03%"
  },
  {
      "Date": "13-Dec-02",
      "Price": "6.362",
      "Open": "6.362",
      "High": "6.362",
      "Low": "6.362",
      "Change %": "-0.22%"
  },
  {
      "Date": "12-Dec-02",
      "Price": "6.376",
      "Open": "6.376",
      "High": "6.376",
      "Low": "6.376",
      "Change %": "-0.50%"
  },
  {
      "Date": "11-Dec-02",
      "Price": "6.408",
      "Open": "6.408",
      "High": "6.408",
      "Low": "6.408",
      "Change %": "-0.11%"
  },
  {
      "Date": "10-Dec-02",
      "Price": "6.415",
      "Open": "6.415",
      "High": "6.415",
      "Low": "6.415",
      "Change %": "0.30%"
  },
  {
      "Date": "9-Dec-02",
      "Price": "6.396",
      "Open": "6.396",
      "High": "6.396",
      "Low": "6.396",
      "Change %": "0.31%"
  },
  {
      "Date": "6-Dec-02",
      "Price": "6.376",
      "Open": "6.376",
      "High": "6.376",
      "Low": "6.376",
      "Change %": "0.09%"
  },
  {
      "Date": "5-Dec-02",
      "Price": "6.37",
      "Open": "6.37",
      "High": "6.37",
      "Low": "6.37",
      "Change %": "0.05%"
  },
  {
      "Date": "4-Dec-02",
      "Price": "6.367",
      "Open": "6.367",
      "High": "6.367",
      "Low": "6.367",
      "Change %": "-0.33%"
  },
  {
      "Date": "3-Dec-02",
      "Price": "6.388",
      "Open": "6.388",
      "High": "6.388",
      "Low": "6.388",
      "Change %": "-0.37%"
  },
  {
      "Date": "2-Dec-02",
      "Price": "6.412",
      "Open": "6.412",
      "High": "6.412",
      "Low": "6.412",
      "Change %": "-0.20%"
  },
  {
      "Date": "30-Nov-02",
      "Price": "6.425",
      "Open": "6.425",
      "High": "6.425",
      "Low": "6.425",
      "Change %": "-0.45%"
  },
  {
      "Date": "29-Nov-02",
      "Price": "6.454",
      "Open": "6.454",
      "High": "6.454",
      "Low": "6.454",
      "Change %": "1.33%"
  },
  {
      "Date": "28-Nov-02",
      "Price": "6.369",
      "Open": "6.369",
      "High": "6.369",
      "Low": "6.369",
      "Change %": "-0.05%"
  },
  {
      "Date": "27-Nov-02",
      "Price": "6.372",
      "Open": "6.372",
      "High": "6.372",
      "Low": "6.372",
      "Change %": "-0.58%"
  },
  {
      "Date": "26-Nov-02",
      "Price": "6.409",
      "Open": "6.409",
      "High": "6.409",
      "Low": "6.409",
      "Change %": "-0.23%"
  },
  {
      "Date": "25-Nov-02",
      "Price": "6.424",
      "Open": "6.424",
      "High": "6.424",
      "Low": "6.424",
      "Change %": "0.31%"
  },
  {
      "Date": "23-Nov-02",
      "Price": "6.404",
      "Open": "6.404",
      "High": "6.404",
      "Low": "6.404",
      "Change %": "-0.57%"
  },
  {
      "Date": "22-Nov-02",
      "Price": "6.441",
      "Open": "6.441",
      "High": "6.441",
      "Low": "6.441",
      "Change %": "-1.38%"
  },
  {
      "Date": "21-Nov-02",
      "Price": "6.531",
      "Open": "6.531",
      "High": "6.531",
      "Low": "6.531",
      "Change %": "-0.85%"
  },
  {
      "Date": "20-Nov-02",
      "Price": "6.587",
      "Open": "6.587",
      "High": "6.587",
      "Low": "6.587",
      "Change %": "-0.15%"
  },
  {
      "Date": "18-Nov-02",
      "Price": "6.597",
      "Open": "6.597",
      "High": "6.597",
      "Low": "6.597",
      "Change %": "-0.32%"
  },
  {
      "Date": "16-Nov-02",
      "Price": "6.618",
      "Open": "6.618",
      "High": "6.618",
      "Low": "6.618",
      "Change %": "-0.08%"
  },
  {
      "Date": "15-Nov-02",
      "Price": "6.623",
      "Open": "6.623",
      "High": "6.623",
      "Low": "6.623",
      "Change %": "-0.87%"
  },
  {
      "Date": "14-Nov-02",
      "Price": "6.681",
      "Open": "6.681",
      "High": "6.681",
      "Low": "6.681",
      "Change %": "-1.21%"
  },
  {
      "Date": "13-Nov-02",
      "Price": "6.763",
      "Open": "6.763",
      "High": "6.763",
      "Low": "6.763",
      "Change %": "-0.46%"
  },
  {
      "Date": "12-Nov-02",
      "Price": "6.794",
      "Open": "6.794",
      "High": "6.794",
      "Low": "6.794",
      "Change %": "-0.13%"
  },
  {
      "Date": "11-Nov-02",
      "Price": "6.803",
      "Open": "6.803",
      "High": "6.803",
      "Low": "6.803",
      "Change %": "-0.42%"
  },
  {
      "Date": "9-Nov-02",
      "Price": "6.832",
      "Open": "6.832",
      "High": "6.832",
      "Low": "6.832",
      "Change %": "0.03%"
  },
  {
      "Date": "8-Nov-02",
      "Price": "6.83",
      "Open": "6.83",
      "High": "6.83",
      "Low": "6.83",
      "Change %": "0.25%"
  },
  {
      "Date": "7-Nov-02",
      "Price": "6.813",
      "Open": "6.813",
      "High": "6.813",
      "Low": "6.813",
      "Change %": "-0.73%"
  },
  {
      "Date": "6-Nov-02",
      "Price": "6.863",
      "Open": "6.863",
      "High": "6.863",
      "Low": "6.863",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Nov-02",
      "Price": "6.863",
      "Open": "6.863",
      "High": "6.863",
      "Low": "6.863",
      "Change %": "-0.10%"
  },
  {
      "Date": "2-Nov-02",
      "Price": "6.87",
      "Open": "6.87",
      "High": "6.87",
      "Low": "6.87",
      "Change %": "-0.72%"
  },
  {
      "Date": "1-Nov-02",
      "Price": "6.92",
      "Open": "6.92",
      "High": "6.92",
      "Low": "6.92",
      "Change %": "-0.07%"
  },
  {
      "Date": "31-Oct-02",
      "Price": "6.925",
      "Open": "6.925",
      "High": "6.925",
      "Low": "6.925",
      "Change %": "-0.10%"
  },
  {
      "Date": "30-Oct-02",
      "Price": "6.932",
      "Open": "6.932",
      "High": "6.932",
      "Low": "6.932",
      "Change %": "-0.66%"
  },
  {
      "Date": "29-Oct-02",
      "Price": "6.978",
      "Open": "6.978",
      "High": "6.978",
      "Low": "6.978",
      "Change %": "-1.11%"
  },
  {
      "Date": "28-Oct-02",
      "Price": "7.056",
      "Open": "7.056",
      "High": "7.056",
      "Low": "7.056",
      "Change %": "0.03%"
  },
  {
      "Date": "26-Oct-02",
      "Price": "7.054",
      "Open": "7.054",
      "High": "7.054",
      "Low": "7.054",
      "Change %": "-0.07%"
  },
  {
      "Date": "25-Oct-02",
      "Price": "7.059",
      "Open": "7.059",
      "High": "7.059",
      "Low": "7.059",
      "Change %": "0.11%"
  },
  {
      "Date": "24-Oct-02",
      "Price": "7.051",
      "Open": "7.051",
      "High": "7.051",
      "Low": "7.051",
      "Change %": "0.03%"
  },
  {
      "Date": "23-Oct-02",
      "Price": "7.049",
      "Open": "7.049",
      "High": "7.049",
      "Low": "7.049",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Oct-02",
      "Price": "7.049",
      "Open": "7.049",
      "High": "7.049",
      "Low": "7.049",
      "Change %": "0.14%"
  },
  {
      "Date": "21-Oct-02",
      "Price": "7.039",
      "Open": "7.039",
      "High": "7.039",
      "Low": "7.039",
      "Change %": "0.07%"
  },
  {
      "Date": "19-Oct-02",
      "Price": "7.034",
      "Open": "7.034",
      "High": "7.034",
      "Low": "7.034",
      "Change %": "-0.01%"
  },
  {
      "Date": "18-Oct-02",
      "Price": "7.035",
      "Open": "7.035",
      "High": "7.035",
      "Low": "7.035",
      "Change %": "-0.51%"
  },
  {
      "Date": "17-Oct-02",
      "Price": "7.071",
      "Open": "7.071",
      "High": "7.071",
      "Low": "7.071",
      "Change %": "-0.20%"
  },
  {
      "Date": "16-Oct-02",
      "Price": "7.085",
      "Open": "7.085",
      "High": "7.085",
      "Low": "7.085",
      "Change %": "0.03%"
  },
  {
      "Date": "14-Oct-02",
      "Price": "7.083",
      "Open": "7.083",
      "High": "7.083",
      "Low": "7.083",
      "Change %": "0.07%"
  },
  {
      "Date": "12-Oct-02",
      "Price": "7.078",
      "Open": "7.078",
      "High": "7.078",
      "Low": "7.078",
      "Change %": "-0.13%"
  },
  {
      "Date": "11-Oct-02",
      "Price": "7.087",
      "Open": "7.087",
      "High": "7.087",
      "Low": "7.087",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Oct-02",
      "Price": "7.087",
      "Open": "7.087",
      "High": "7.087",
      "Low": "7.087",
      "Change %": "-0.10%"
  },
  {
      "Date": "9-Oct-02",
      "Price": "7.094",
      "Open": "7.094",
      "High": "7.094",
      "Low": "7.094",
      "Change %": "0.06%"
  },
  {
      "Date": "8-Oct-02",
      "Price": "7.09",
      "Open": "7.09",
      "High": "7.09",
      "Low": "7.09",
      "Change %": "-0.24%"
  },
  {
      "Date": "7-Oct-02",
      "Price": "7.107",
      "Open": "7.107",
      "High": "7.107",
      "Low": "7.107",
      "Change %": "-0.28%"
  },
  {
      "Date": "5-Oct-02",
      "Price": "7.127",
      "Open": "7.127",
      "High": "7.127",
      "Low": "7.127",
      "Change %": "0.03%"
  },
  {
      "Date": "4-Oct-02",
      "Price": "7.125",
      "Open": "7.125",
      "High": "7.125",
      "Low": "7.125",
      "Change %": "-0.14%"
  },
  {
      "Date": "3-Oct-02",
      "Price": "7.135",
      "Open": "7.135",
      "High": "7.135",
      "Low": "7.135",
      "Change %": "-0.11%"
  },
  {
      "Date": "1-Oct-02",
      "Price": "7.143",
      "Open": "7.143",
      "High": "7.143",
      "Low": "7.143",
      "Change %": "-0.35%"
  },
  {
      "Date": "30-Sep-02",
      "Price": "7.168",
      "Open": "7.168",
      "High": "7.168",
      "Low": "7.168",
      "Change %": "0.00%"
  },
  {
      "Date": "28-Sep-02",
      "Price": "7.168",
      "Open": "7.168",
      "High": "7.168",
      "Low": "7.168",
      "Change %": "0.04%"
  },
  {
      "Date": "27-Sep-02",
      "Price": "7.165",
      "Open": "7.165",
      "High": "7.165",
      "Low": "7.165",
      "Change %": "-0.21%"
  },
  {
      "Date": "26-Sep-02",
      "Price": "7.18",
      "Open": "7.18",
      "High": "7.18",
      "Low": "7.18",
      "Change %": "-0.10%"
  },
  {
      "Date": "25-Sep-02",
      "Price": "7.187",
      "Open": "7.187",
      "High": "7.187",
      "Low": "7.187",
      "Change %": "0.14%"
  },
  {
      "Date": "24-Sep-02",
      "Price": "7.177",
      "Open": "7.177",
      "High": "7.177",
      "Low": "7.177",
      "Change %": "0.08%"
  },
  {
      "Date": "23-Sep-02",
      "Price": "7.171",
      "Open": "7.171",
      "High": "7.171",
      "Low": "7.171",
      "Change %": "0.04%"
  },
  {
      "Date": "21-Sep-02",
      "Price": "7.168",
      "Open": "7.168",
      "High": "7.168",
      "Low": "7.168",
      "Change %": "-0.10%"
  },
  {
      "Date": "20-Sep-02",
      "Price": "7.175",
      "Open": "7.175",
      "High": "7.175",
      "Low": "7.175",
      "Change %": "0.15%"
  },
  {
      "Date": "19-Sep-02",
      "Price": "7.164",
      "Open": "7.164",
      "High": "7.164",
      "Low": "7.164",
      "Change %": "0.00%"
  },
  {
      "Date": "18-Sep-02",
      "Price": "7.164",
      "Open": "7.164",
      "High": "7.164",
      "Low": "7.164",
      "Change %": "-0.04%"
  },
  {
      "Date": "17-Sep-02",
      "Price": "7.167",
      "Open": "7.167",
      "High": "7.167",
      "Low": "7.167",
      "Change %": "-0.33%"
  },
  {
      "Date": "16-Sep-02",
      "Price": "7.191",
      "Open": "7.191",
      "High": "7.191",
      "Low": "7.191",
      "Change %": "0.22%"
  },
  {
      "Date": "14-Sep-02",
      "Price": "7.175",
      "Open": "7.175",
      "High": "7.175",
      "Low": "7.175",
      "Change %": "0.11%"
  },
  {
      "Date": "13-Sep-02",
      "Price": "7.167",
      "Open": "7.167",
      "High": "7.167",
      "Low": "7.167",
      "Change %": "0.06%"
  },
  {
      "Date": "12-Sep-02",
      "Price": "7.163",
      "Open": "7.163",
      "High": "7.163",
      "Low": "7.163",
      "Change %": "-0.10%"
  },
  {
      "Date": "11-Sep-02",
      "Price": "7.17",
      "Open": "7.17",
      "High": "7.17",
      "Low": "7.17",
      "Change %": "-0.18%"
  },
  {
      "Date": "9-Sep-02",
      "Price": "7.183",
      "Open": "7.183",
      "High": "7.183",
      "Low": "7.183",
      "Change %": "0.08%"
  },
  {
      "Date": "7-Sep-02",
      "Price": "7.177",
      "Open": "7.177",
      "High": "7.177",
      "Low": "7.177",
      "Change %": "0.00%"
  },
  {
      "Date": "6-Sep-02",
      "Price": "7.177",
      "Open": "7.177",
      "High": "7.177",
      "Low": "7.177",
      "Change %": "0.66%"
  },
  {
      "Date": "5-Sep-02",
      "Price": "7.13",
      "Open": "7.13",
      "High": "7.13",
      "Low": "7.13",
      "Change %": "-0.14%"
  },
  {
      "Date": "4-Sep-02",
      "Price": "7.14",
      "Open": "7.14",
      "High": "7.14",
      "Low": "7.14",
      "Change %": "-0.29%"
  },
  {
      "Date": "3-Sep-02",
      "Price": "7.161",
      "Open": "7.161",
      "High": "7.161",
      "Low": "7.161",
      "Change %": "-0.17%"
  },
  {
      "Date": "2-Sep-02",
      "Price": "7.173",
      "Open": "7.173",
      "High": "7.173",
      "Low": "7.173",
      "Change %": "0.17%"
  },
  {
      "Date": "31-Aug-02",
      "Price": "7.161",
      "Open": "7.161",
      "High": "7.161",
      "Low": "7.161",
      "Change %": "0.03%"
  },
  {
      "Date": "30-Aug-02",
      "Price": "7.159",
      "Open": "7.159",
      "High": "7.159",
      "Low": "7.159",
      "Change %": "0.11%"
  },
  {
      "Date": "29-Aug-02",
      "Price": "7.151",
      "Open": "7.151",
      "High": "7.151",
      "Low": "7.151",
      "Change %": "-0.29%"
  },
  {
      "Date": "28-Aug-02",
      "Price": "7.172",
      "Open": "7.172",
      "High": "7.172",
      "Low": "7.172",
      "Change %": "-0.03%"
  },
  {
      "Date": "27-Aug-02",
      "Price": "7.174",
      "Open": "7.174",
      "High": "7.174",
      "Low": "7.174",
      "Change %": "-0.03%"
  },
  {
      "Date": "26-Aug-02",
      "Price": "7.176",
      "Open": "7.176",
      "High": "7.176",
      "Low": "7.176",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Aug-02",
      "Price": "7.176",
      "Open": "7.176",
      "High": "7.176",
      "Low": "7.176",
      "Change %": "-0.06%"
  },
  {
      "Date": "23-Aug-02",
      "Price": "7.18",
      "Open": "7.18",
      "High": "7.18",
      "Low": "7.18",
      "Change %": "0.04%"
  },
  {
      "Date": "22-Aug-02",
      "Price": "7.177",
      "Open": "7.177",
      "High": "7.177",
      "Low": "7.177",
      "Change %": "-0.01%"
  },
  {
      "Date": "21-Aug-02",
      "Price": "7.178",
      "Open": "7.178",
      "High": "7.178",
      "Low": "7.178",
      "Change %": "0.14%"
  },
  {
      "Date": "20-Aug-02",
      "Price": "7.168",
      "Open": "7.168",
      "High": "7.168",
      "Low": "7.168",
      "Change %": "-0.11%"
  },
  {
      "Date": "19-Aug-02",
      "Price": "7.176",
      "Open": "7.176",
      "High": "7.176",
      "Low": "7.176",
      "Change %": "-0.22%"
  },
  {
      "Date": "17-Aug-02",
      "Price": "7.192",
      "Open": "7.192",
      "High": "7.192",
      "Low": "7.192",
      "Change %": "-0.14%"
  },
  {
      "Date": "16-Aug-02",
      "Price": "7.202",
      "Open": "7.202",
      "High": "7.202",
      "Low": "7.202",
      "Change %": "-0.18%"
  },
  {
      "Date": "14-Aug-02",
      "Price": "7.215",
      "Open": "7.215",
      "High": "7.215",
      "Low": "7.215",
      "Change %": "0.22%"
  },
  {
      "Date": "13-Aug-02",
      "Price": "7.199",
      "Open": "7.199",
      "High": "7.199",
      "Low": "7.199",
      "Change %": "0.06%"
  },
  {
      "Date": "12-Aug-02",
      "Price": "7.195",
      "Open": "7.195",
      "High": "7.195",
      "Low": "7.195",
      "Change %": "-0.36%"
  },
  {
      "Date": "10-Aug-02",
      "Price": "7.221",
      "Open": "7.221",
      "High": "7.221",
      "Low": "7.221",
      "Change %": "-0.10%"
  },
  {
      "Date": "9-Aug-02",
      "Price": "7.228",
      "Open": "7.228",
      "High": "7.228",
      "Low": "7.228",
      "Change %": "0.61%"
  },
  {
      "Date": "8-Aug-02",
      "Price": "7.184",
      "Open": "7.184",
      "High": "7.184",
      "Low": "7.184",
      "Change %": "0.24%"
  },
  {
      "Date": "7-Aug-02",
      "Price": "7.167",
      "Open": "7.167",
      "High": "7.167",
      "Low": "7.167",
      "Change %": "-0.47%"
  },
  {
      "Date": "6-Aug-02",
      "Price": "7.201",
      "Open": "7.201",
      "High": "7.201",
      "Low": "7.201",
      "Change %": "0.08%"
  },
  {
      "Date": "5-Aug-02",
      "Price": "7.195",
      "Open": "7.195",
      "High": "7.195",
      "Low": "7.195",
      "Change %": "-0.40%"
  },
  {
      "Date": "3-Aug-02",
      "Price": "7.224",
      "Open": "7.224",
      "High": "7.224",
      "Low": "7.224",
      "Change %": "-0.55%"
  },
  {
      "Date": "2-Aug-02",
      "Price": "7.264",
      "Open": "7.264",
      "High": "7.264",
      "Low": "7.264",
      "Change %": "-0.22%"
  },
  {
      "Date": "1-Aug-02",
      "Price": "7.28",
      "Open": "7.28",
      "High": "7.28",
      "Low": "7.28",
      "Change %": "-0.38%"
  },
  {
      "Date": "31-Jul-02",
      "Price": "7.308",
      "Open": "7.308",
      "High": "7.308",
      "Low": "7.308",
      "Change %": "-0.14%"
  },
  {
      "Date": "30-Jul-02",
      "Price": "7.318",
      "Open": "7.318",
      "High": "7.318",
      "Low": "7.318",
      "Change %": "-0.05%"
  },
  {
      "Date": "29-Jul-02",
      "Price": "7.322",
      "Open": "7.322",
      "High": "7.322",
      "Low": "7.322",
      "Change %": "0.69%"
  },
  {
      "Date": "27-Jul-02",
      "Price": "7.272",
      "Open": "7.272",
      "High": "7.272",
      "Low": "7.272",
      "Change %": "-0.38%"
  },
  {
      "Date": "26-Jul-02",
      "Price": "7.3",
      "Open": "7.3",
      "High": "7.3",
      "Low": "7.3",
      "Change %": "-0.44%"
  },
  {
      "Date": "25-Jul-02",
      "Price": "7.332",
      "Open": "7.332",
      "High": "7.332",
      "Low": "7.332",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Jul-02",
      "Price": "7.332",
      "Open": "7.332",
      "High": "7.332",
      "Low": "7.332",
      "Change %": "-0.08%"
  },
  {
      "Date": "23-Jul-02",
      "Price": "7.338",
      "Open": "7.338",
      "High": "7.338",
      "Low": "7.338",
      "Change %": "0.04%"
  },
  {
      "Date": "22-Jul-02",
      "Price": "7.335",
      "Open": "7.335",
      "High": "7.335",
      "Low": "7.335",
      "Change %": "0.04%"
  },
  {
      "Date": "20-Jul-02",
      "Price": "7.332",
      "Open": "7.332",
      "High": "7.332",
      "Low": "7.332",
      "Change %": "0.03%"
  },
  {
      "Date": "19-Jul-02",
      "Price": "7.33",
      "Open": "7.33",
      "High": "7.33",
      "Low": "7.33",
      "Change %": "-0.01%"
  },
  {
      "Date": "18-Jul-02",
      "Price": "7.331",
      "Open": "7.331",
      "High": "7.331",
      "Low": "7.331",
      "Change %": "-0.31%"
  },
  {
      "Date": "17-Jul-02",
      "Price": "7.354",
      "Open": "7.354",
      "High": "7.354",
      "Low": "7.354",
      "Change %": "-0.31%"
  },
  {
      "Date": "16-Jul-02",
      "Price": "7.377",
      "Open": "7.377",
      "High": "7.377",
      "Low": "7.377",
      "Change %": "0.01%"
  },
  {
      "Date": "15-Jul-02",
      "Price": "7.376",
      "Open": "7.376",
      "High": "7.376",
      "Low": "7.376",
      "Change %": "-0.14%"
  },
  {
      "Date": "13-Jul-02",
      "Price": "7.386",
      "Open": "7.386",
      "High": "7.386",
      "Low": "7.386",
      "Change %": "-0.05%"
  },
  {
      "Date": "12-Jul-02",
      "Price": "7.39",
      "Open": "7.39",
      "High": "7.39",
      "Low": "7.39",
      "Change %": "0.64%"
  },
  {
      "Date": "11-Jul-02",
      "Price": "7.343",
      "Open": "7.343",
      "High": "7.343",
      "Low": "7.343",
      "Change %": "-0.26%"
  },
  {
      "Date": "10-Jul-02",
      "Price": "7.362",
      "Open": "7.362",
      "High": "7.362",
      "Low": "7.362",
      "Change %": "0.30%"
  },
  {
      "Date": "9-Jul-02",
      "Price": "7.34",
      "Open": "7.34",
      "High": "7.34",
      "Low": "7.34",
      "Change %": "0.04%"
  },
  {
      "Date": "8-Jul-02",
      "Price": "7.337",
      "Open": "7.337",
      "High": "7.337",
      "Low": "7.337",
      "Change %": "-0.86%"
  },
  {
      "Date": "6-Jul-02",
      "Price": "7.401",
      "Open": "7.401",
      "High": "7.401",
      "Low": "7.401",
      "Change %": "-0.28%"
  },
  {
      "Date": "5-Jul-02",
      "Price": "7.422",
      "Open": "7.422",
      "High": "7.422",
      "Low": "7.422",
      "Change %": "-0.16%"
  },
  {
      "Date": "4-Jul-02",
      "Price": "7.434",
      "Open": "7.434",
      "High": "7.434",
      "Low": "7.434",
      "Change %": "0.18%"
  },
  {
      "Date": "3-Jul-02",
      "Price": "7.421",
      "Open": "7.421",
      "High": "7.421",
      "Low": "7.421",
      "Change %": "-0.08%"
  },
  {
      "Date": "2-Jul-02",
      "Price": "7.427",
      "Open": "7.427",
      "High": "7.427",
      "Low": "7.427",
      "Change %": "-0.43%"
  },
  {
      "Date": "1-Jul-02",
      "Price": "7.459",
      "Open": "7.459",
      "High": "7.459",
      "Low": "7.459",
      "Change %": "-0.35%"
  },
  {
      "Date": "28-Jun-02",
      "Price": "7.485",
      "Open": "7.485",
      "High": "7.485",
      "Low": "7.485",
      "Change %": "0.98%"
  },
  {
      "Date": "27-Jun-02",
      "Price": "7.412",
      "Open": "7.412",
      "High": "7.412",
      "Low": "7.412",
      "Change %": "-2.46%"
  },
  {
      "Date": "26-Jun-02",
      "Price": "7.599",
      "Open": "7.599",
      "High": "7.599",
      "Low": "7.599",
      "Change %": "-0.63%"
  },
  {
      "Date": "25-Jun-02",
      "Price": "7.647",
      "Open": "7.647",
      "High": "7.647",
      "Low": "7.647",
      "Change %": "-0.09%"
  },
  {
      "Date": "24-Jun-02",
      "Price": "7.654",
      "Open": "7.654",
      "High": "7.654",
      "Low": "7.654",
      "Change %": "0.25%"
  },
  {
      "Date": "22-Jun-02",
      "Price": "7.635",
      "Open": "7.635",
      "High": "7.635",
      "Low": "7.635",
      "Change %": "-0.21%"
  },
  {
      "Date": "21-Jun-02",
      "Price": "7.651",
      "Open": "7.651",
      "High": "7.651",
      "Low": "7.651",
      "Change %": "0.35%"
  },
  {
      "Date": "20-Jun-02",
      "Price": "7.624",
      "Open": "7.624",
      "High": "7.624",
      "Low": "7.624",
      "Change %": "-0.26%"
  },
  {
      "Date": "19-Jun-02",
      "Price": "7.644",
      "Open": "7.644",
      "High": "7.644",
      "Low": "7.644",
      "Change %": "-0.55%"
  },
  {
      "Date": "18-Jun-02",
      "Price": "7.686",
      "Open": "7.686",
      "High": "7.686",
      "Low": "7.686",
      "Change %": "0.22%"
  },
  {
      "Date": "17-Jun-02",
      "Price": "7.669",
      "Open": "7.669",
      "High": "7.669",
      "Low": "7.669",
      "Change %": "0.55%"
  },
  {
      "Date": "15-Jun-02",
      "Price": "7.627",
      "Open": "7.627",
      "High": "7.627",
      "Low": "7.627",
      "Change %": "-0.16%"
  },
  {
      "Date": "14-Jun-02",
      "Price": "7.639",
      "Open": "7.639",
      "High": "7.639",
      "Low": "7.639",
      "Change %": "-0.13%"
  },
  {
      "Date": "13-Jun-02",
      "Price": "7.649",
      "Open": "7.649",
      "High": "7.649",
      "Low": "7.649",
      "Change %": "0.42%"
  },
  {
      "Date": "12-Jun-02",
      "Price": "7.617",
      "Open": "7.617",
      "High": "7.617",
      "Low": "7.617",
      "Change %": "0.36%"
  },
  {
      "Date": "11-Jun-02",
      "Price": "7.59",
      "Open": "7.59",
      "High": "7.59",
      "Low": "7.59",
      "Change %": "-0.73%"
  },
  {
      "Date": "10-Jun-02",
      "Price": "7.646",
      "Open": "7.646",
      "High": "7.646",
      "Low": "7.646",
      "Change %": "-0.91%"
  },
  {
      "Date": "8-Jun-02",
      "Price": "7.716",
      "Open": "7.716",
      "High": "7.716",
      "Low": "7.716",
      "Change %": "-0.44%"
  },
  {
      "Date": "7-Jun-02",
      "Price": "7.75",
      "Open": "7.75",
      "High": "7.75",
      "Low": "7.75",
      "Change %": "0.58%"
  },
  {
      "Date": "6-Jun-02",
      "Price": "7.705",
      "Open": "7.705",
      "High": "7.705",
      "Low": "7.705",
      "Change %": "0.18%"
  },
  {
      "Date": "5-Jun-02",
      "Price": "7.691",
      "Open": "7.691",
      "High": "7.691",
      "Low": "7.691",
      "Change %": "-1.08%"
  },
  {
      "Date": "4-Jun-02",
      "Price": "7.775",
      "Open": "7.775",
      "High": "7.775",
      "Low": "7.775",
      "Change %": "0.80%"
  },
  {
      "Date": "3-Jun-02",
      "Price": "7.713",
      "Open": "7.713",
      "High": "7.713",
      "Low": "7.713",
      "Change %": "-0.32%"
  },
  {
      "Date": "1-Jun-02",
      "Price": "7.738",
      "Open": "7.738",
      "High": "7.738",
      "Low": "7.738",
      "Change %": "0.19%"
  },
  {
      "Date": "31-May-02",
      "Price": "7.723",
      "Open": "7.723",
      "High": "7.723",
      "Low": "7.723",
      "Change %": "-0.21%"
  },
  {
      "Date": "30-May-02",
      "Price": "7.739",
      "Open": "7.739",
      "High": "7.739",
      "Low": "7.739",
      "Change %": "0.81%"
  },
  {
      "Date": "29-May-02",
      "Price": "7.677",
      "Open": "7.677",
      "High": "7.677",
      "Low": "7.677",
      "Change %": "-0.49%"
  },
  {
      "Date": "28-May-02",
      "Price": "7.715",
      "Open": "7.715",
      "High": "7.715",
      "Low": "7.715",
      "Change %": "-1.69%"
  },
  {
      "Date": "27-May-02",
      "Price": "7.848",
      "Open": "7.848",
      "High": "7.848",
      "Low": "7.848",
      "Change %": "0.87%"
  },
  {
      "Date": "24-May-02",
      "Price": "7.78",
      "Open": "7.78",
      "High": "7.78",
      "Low": "7.78",
      "Change %": "-1.84%"
  },
  {
      "Date": "23-May-02",
      "Price": "7.926",
      "Open": "7.926",
      "High": "7.926",
      "Low": "7.926",
      "Change %": "-1.36%"
  },
  {
      "Date": "22-May-02",
      "Price": "8.035",
      "Open": "8.035",
      "High": "8.035",
      "Low": "8.035",
      "Change %": "-1.99%"
  },
  {
      "Date": "21-May-02",
      "Price": "8.198",
      "Open": "8.198",
      "High": "8.198",
      "Low": "8.198",
      "Change %": "0.39%"
  },
  {
      "Date": "20-May-02",
      "Price": "8.166",
      "Open": "8.166",
      "High": "8.166",
      "Low": "8.166",
      "Change %": "0.89%"
  },
  {
      "Date": "18-May-02",
      "Price": "8.094",
      "Open": "8.094",
      "High": "8.094",
      "Low": "8.094",
      "Change %": "1.19%"
  },
  {
      "Date": "17-May-02",
      "Price": "7.999",
      "Open": "7.999",
      "High": "7.999",
      "Low": "7.999",
      "Change %": "1.61%"
  },
  {
      "Date": "16-May-02",
      "Price": "7.872",
      "Open": "7.872",
      "High": "7.872",
      "Low": "7.872",
      "Change %": "0.40%"
  },
  {
      "Date": "15-May-02",
      "Price": "7.841",
      "Open": "7.841",
      "High": "7.841",
      "Low": "7.841",
      "Change %": "0.72%"
  },
  {
      "Date": "14-May-02",
      "Price": "7.785",
      "Open": "7.785",
      "High": "7.785",
      "Low": "7.785",
      "Change %": "1.62%"
  },
  {
      "Date": "13-May-02",
      "Price": "7.661",
      "Open": "7.661",
      "High": "7.661",
      "Low": "7.661",
      "Change %": "-0.56%"
  },
  {
      "Date": "11-May-02",
      "Price": "7.704",
      "Open": "7.704",
      "High": "7.704",
      "Low": "7.704",
      "Change %": "-0.18%"
  },
  {
      "Date": "10-May-02",
      "Price": "7.718",
      "Open": "7.718",
      "High": "7.718",
      "Low": "7.718",
      "Change %": "1.87%"
  },
  {
      "Date": "9-May-02",
      "Price": "7.576",
      "Open": "7.576",
      "High": "7.576",
      "Low": "7.576",
      "Change %": "0.81%"
  },
  {
      "Date": "8-May-02",
      "Price": "7.515",
      "Open": "7.515",
      "High": "7.515",
      "Low": "7.515",
      "Change %": "1.06%"
  },
  {
      "Date": "7-May-02",
      "Price": "7.436",
      "Open": "7.436",
      "High": "7.436",
      "Low": "7.436",
      "Change %": "-0.42%"
  },
  {
      "Date": "6-May-02",
      "Price": "7.467",
      "Open": "7.467",
      "High": "7.467",
      "Low": "7.467",
      "Change %": "-1.54%"
  },
  {
      "Date": "4-May-02",
      "Price": "7.584",
      "Open": "7.584",
      "High": "7.584",
      "Low": "7.584",
      "Change %": "0.57%"
  },
  {
      "Date": "3-May-02",
      "Price": "7.541",
      "Open": "7.541",
      "High": "7.541",
      "Low": "7.541",
      "Change %": "1.32%"
  },
  {
      "Date": "2-May-02",
      "Price": "7.443",
      "Open": "7.443",
      "High": "7.443",
      "Low": "7.443",
      "Change %": "0.45%"
  },
  {
      "Date": "30-Apr-02",
      "Price": "7.41",
      "Open": "7.41",
      "High": "7.41",
      "Low": "7.41",
      "Change %": "0.98%"
  },
  {
      "Date": "29-Apr-02",
      "Price": "7.338",
      "Open": "7.338",
      "High": "7.338",
      "Low": "7.338",
      "Change %": "0.96%"
  },
  {
      "Date": "27-Apr-02",
      "Price": "7.268",
      "Open": "7.268",
      "High": "7.268",
      "Low": "7.268",
      "Change %": "-0.36%"
  },
  {
      "Date": "26-Apr-02",
      "Price": "7.294",
      "Open": "7.294",
      "High": "7.294",
      "Low": "7.294",
      "Change %": "0.07%"
  },
  {
      "Date": "24-Apr-02",
      "Price": "7.289",
      "Open": "7.289",
      "High": "7.289",
      "Low": "7.289",
      "Change %": "-0.22%"
  },
  {
      "Date": "23-Apr-02",
      "Price": "7.305",
      "Open": "7.305",
      "High": "7.305",
      "Low": "7.305",
      "Change %": "-0.59%"
  },
  {
      "Date": "22-Apr-02",
      "Price": "7.348",
      "Open": "7.348",
      "High": "7.348",
      "Low": "7.348",
      "Change %": "0.33%"
  },
  {
      "Date": "20-Apr-02",
      "Price": "7.324",
      "Open": "7.324",
      "High": "7.324",
      "Low": "7.324",
      "Change %": "-0.15%"
  },
  {
      "Date": "19-Apr-02",
      "Price": "7.335",
      "Open": "7.335",
      "High": "7.335",
      "Low": "7.335",
      "Change %": "0.45%"
  },
  {
      "Date": "18-Apr-02",
      "Price": "7.302",
      "Open": "7.302",
      "High": "7.302",
      "Low": "7.302",
      "Change %": "0.04%"
  },
  {
      "Date": "17-Apr-02",
      "Price": "7.299",
      "Open": "7.299",
      "High": "7.299",
      "Low": "7.299",
      "Change %": "0.27%"
  },
  {
      "Date": "16-Apr-02",
      "Price": "7.279",
      "Open": "7.279",
      "High": "7.279",
      "Low": "7.279",
      "Change %": "-0.31%"
  },
  {
      "Date": "15-Apr-02",
      "Price": "7.302",
      "Open": "7.302",
      "High": "7.302",
      "Low": "7.302",
      "Change %": "0.55%"
  },
  {
      "Date": "13-Apr-02",
      "Price": "7.262",
      "Open": "7.262",
      "High": "7.262",
      "Low": "7.262",
      "Change %": "-0.06%"
  },
  {
      "Date": "12-Apr-02",
      "Price": "7.266",
      "Open": "7.266",
      "High": "7.266",
      "Low": "7.266",
      "Change %": "0.14%"
  },
  {
      "Date": "11-Apr-02",
      "Price": "7.256",
      "Open": "7.256",
      "High": "7.256",
      "Low": "7.256",
      "Change %": "-0.59%"
  },
  {
      "Date": "10-Apr-02",
      "Price": "7.299",
      "Open": "7.299",
      "High": "7.299",
      "Low": "7.299",
      "Change %": "0.62%"
  },
  {
      "Date": "9-Apr-02",
      "Price": "7.254",
      "Open": "7.254",
      "High": "7.254",
      "Low": "7.254",
      "Change %": "0.74%"
  },
  {
      "Date": "8-Apr-02",
      "Price": "7.201",
      "Open": "7.201",
      "High": "7.201",
      "Low": "7.201",
      "Change %": "0.66%"
  },
  {
      "Date": "6-Apr-02",
      "Price": "7.154",
      "Open": "7.154",
      "High": "7.154",
      "Low": "7.154",
      "Change %": "-0.10%"
  },
  {
      "Date": "5-Apr-02",
      "Price": "7.161",
      "Open": "7.161",
      "High": "7.161",
      "Low": "7.161",
      "Change %": "0.25%"
  },
  {
      "Date": "4-Apr-02",
      "Price": "7.143",
      "Open": "7.143",
      "High": "7.143",
      "Low": "7.143",
      "Change %": "-0.93%"
  },
  {
      "Date": "3-Apr-02",
      "Price": "7.21",
      "Open": "7.21",
      "High": "7.21",
      "Low": "7.21",
      "Change %": "-0.46%"
  },
  {
      "Date": "2-Apr-02",
      "Price": "7.243",
      "Open": "7.243",
      "High": "7.243",
      "Low": "7.243",
      "Change %": "0.03%"
  },
  {
      "Date": "1-Apr-02",
      "Price": "7.241",
      "Open": "7.241",
      "High": "7.241",
      "Low": "7.241",
      "Change %": "-1.60%"
  },
  {
      "Date": "30-Mar-02",
      "Price": "7.359",
      "Open": "7.359",
      "High": "7.359",
      "Low": "7.359",
      "Change %": "-0.93%"
  },
  {
      "Date": "28-Mar-02",
      "Price": "7.428",
      "Open": "7.428",
      "High": "7.428",
      "Low": "7.428",
      "Change %": "-0.42%"
  },
  {
      "Date": "27-Mar-02",
      "Price": "7.459",
      "Open": "7.459",
      "High": "7.459",
      "Low": "7.459",
      "Change %": "0.08%"
  },
  {
      "Date": "26-Mar-02",
      "Price": "7.453",
      "Open": "7.453",
      "High": "7.453",
      "Low": "7.453",
      "Change %": "0.04%"
  },
  {
      "Date": "23-Mar-02",
      "Price": "7.45",
      "Open": "7.45",
      "High": "7.45",
      "Low": "7.45",
      "Change %": "-0.16%"
  },
  {
      "Date": "22-Mar-02",
      "Price": "7.462",
      "Open": "7.462",
      "High": "7.462",
      "Low": "7.462",
      "Change %": "0.00%"
  },
  {
      "Date": "21-Mar-02",
      "Price": "7.462",
      "Open": "7.462",
      "High": "7.462",
      "Low": "7.462",
      "Change %": "-0.41%"
  },
  {
      "Date": "20-Mar-02",
      "Price": "7.493",
      "Open": "7.493",
      "High": "7.493",
      "Low": "7.493",
      "Change %": "0.24%"
  },
  {
      "Date": "19-Mar-02",
      "Price": "7.475",
      "Open": "7.475",
      "High": "7.475",
      "Low": "7.475",
      "Change %": "-0.17%"
  },
  {
      "Date": "18-Mar-02",
      "Price": "7.488",
      "Open": "7.488",
      "High": "7.488",
      "Low": "7.488",
      "Change %": "1.13%"
  },
  {
      "Date": "16-Mar-02",
      "Price": "7.404",
      "Open": "7.404",
      "High": "7.404",
      "Low": "7.404",
      "Change %": "0.07%"
  },
  {
      "Date": "15-Mar-02",
      "Price": "7.399",
      "Open": "7.399",
      "High": "7.399",
      "Low": "7.399",
      "Change %": "-2.80%"
  },
  {
      "Date": "14-Mar-02",
      "Price": "7.612",
      "Open": "7.612",
      "High": "7.612",
      "Low": "7.612",
      "Change %": "-0.08%"
  },
  {
      "Date": "13-Mar-02",
      "Price": "7.618",
      "Open": "7.618",
      "High": "7.618",
      "Low": "7.618",
      "Change %": "0.79%"
  },
  {
      "Date": "12-Mar-02",
      "Price": "7.558",
      "Open": "7.558",
      "High": "7.558",
      "Low": "7.558",
      "Change %": "-0.09%"
  },
  {
      "Date": "11-Mar-02",
      "Price": "7.565",
      "Open": "7.565",
      "High": "7.565",
      "Low": "7.565",
      "Change %": "-0.18%"
  },
  {
      "Date": "9-Mar-02",
      "Price": "7.579",
      "Open": "7.579",
      "High": "7.579",
      "Low": "7.579",
      "Change %": "-0.55%"
  },
  {
      "Date": "8-Mar-02",
      "Price": "7.621",
      "Open": "7.621",
      "High": "7.621",
      "Low": "7.621",
      "Change %": "1.28%"
  },
  {
      "Date": "7-Mar-02",
      "Price": "7.525",
      "Open": "7.525",
      "High": "7.525",
      "Low": "7.525",
      "Change %": "0.56%"
  },
  {
      "Date": "6-Mar-02",
      "Price": "7.483",
      "Open": "7.483",
      "High": "7.483",
      "Low": "7.483",
      "Change %": "1.80%"
  },
  {
      "Date": "5-Mar-02",
      "Price": "7.351",
      "Open": "7.351",
      "High": "7.351",
      "Low": "7.351",
      "Change %": "0.56%"
  },
  {
      "Date": "4-Mar-02",
      "Price": "7.31",
      "Open": "7.31",
      "High": "7.31",
      "Low": "7.31",
      "Change %": "-1.85%"
  },
  {
      "Date": "2-Mar-02",
      "Price": "7.448",
      "Open": "7.448",
      "High": "7.448",
      "Low": "7.448",
      "Change %": "-1.86%"
  },
  {
      "Date": "1-Mar-02",
      "Price": "7.589",
      "Open": "7.589",
      "High": "7.589",
      "Low": "7.589",
      "Change %": "-0.50%"
  },
  {
      "Date": "28-Feb-02",
      "Price": "7.627",
      "Open": "7.627",
      "High": "7.627",
      "Low": "7.627",
      "Change %": "4.52%"
  },
  {
      "Date": "27-Feb-02",
      "Price": "7.297",
      "Open": "7.297",
      "High": "7.297",
      "Low": "7.297",
      "Change %": "0.76%"
  },
  {
      "Date": "26-Feb-02",
      "Price": "7.242",
      "Open": "7.242",
      "High": "7.242",
      "Low": "7.242",
      "Change %": "-2.99%"
  },
  {
      "Date": "25-Feb-02",
      "Price": "7.465",
      "Open": "7.465",
      "High": "7.465",
      "Low": "7.465",
      "Change %": "1.11%"
  },
  {
      "Date": "22-Feb-02",
      "Price": "7.383",
      "Open": "7.383",
      "High": "7.383",
      "Low": "7.383",
      "Change %": "-0.73%"
  },
  {
      "Date": "21-Feb-02",
      "Price": "7.437",
      "Open": "7.437",
      "High": "7.437",
      "Low": "7.437",
      "Change %": "1.70%"
  },
  {
      "Date": "20-Feb-02",
      "Price": "7.313",
      "Open": "7.313",
      "High": "7.313",
      "Low": "7.313",
      "Change %": "1.06%"
  },
  {
      "Date": "19-Feb-02",
      "Price": "7.236",
      "Open": "7.236",
      "High": "7.236",
      "Low": "7.236",
      "Change %": "0.22%"
  },
  {
      "Date": "18-Feb-02",
      "Price": "7.22",
      "Open": "7.22",
      "High": "7.22",
      "Low": "7.22",
      "Change %": "-0.25%"
  },
  {
      "Date": "16-Feb-02",
      "Price": "7.238",
      "Open": "7.238",
      "High": "7.238",
      "Low": "7.238",
      "Change %": "-0.43%"
  },
  {
      "Date": "15-Feb-02",
      "Price": "7.269",
      "Open": "7.269",
      "High": "7.269",
      "Low": "7.269",
      "Change %": "0.79%"
  },
  {
      "Date": "14-Feb-02",
      "Price": "7.212",
      "Open": "7.212",
      "High": "7.212",
      "Low": "7.212",
      "Change %": "-0.03%"
  },
  {
      "Date": "13-Feb-02",
      "Price": "7.214",
      "Open": "7.214",
      "High": "7.214",
      "Low": "7.214",
      "Change %": "-0.77%"
  },
  {
      "Date": "12-Feb-02",
      "Price": "7.27",
      "Open": "7.27",
      "High": "7.27",
      "Low": "7.27",
      "Change %": "-0.59%"
  },
  {
      "Date": "11-Feb-02",
      "Price": "7.313",
      "Open": "7.313",
      "High": "7.313",
      "Low": "7.313",
      "Change %": "0.08%"
  },
  {
      "Date": "9-Feb-02",
      "Price": "7.307",
      "Open": "7.307",
      "High": "7.307",
      "Low": "7.307",
      "Change %": "-1.10%"
  },
  {
      "Date": "8-Feb-02",
      "Price": "7.388",
      "Open": "7.388",
      "High": "7.388",
      "Low": "7.388",
      "Change %": "-0.98%"
  },
  {
      "Date": "7-Feb-02",
      "Price": "7.461",
      "Open": "7.461",
      "High": "7.461",
      "Low": "7.461",
      "Change %": "-1.36%"
  },
  {
      "Date": "6-Feb-02",
      "Price": "7.564",
      "Open": "7.564",
      "High": "7.564",
      "Low": "7.564",
      "Change %": "-0.20%"
  },
  {
      "Date": "5-Feb-02",
      "Price": "7.579",
      "Open": "7.579",
      "High": "7.579",
      "Low": "7.579",
      "Change %": "0.12%"
  },
  {
      "Date": "4-Feb-02",
      "Price": "7.57",
      "Open": "7.57",
      "High": "7.57",
      "Low": "7.57",
      "Change %": "-0.84%"
  },
  {
      "Date": "2-Feb-02",
      "Price": "7.634",
      "Open": "7.634",
      "High": "7.634",
      "Low": "7.634",
      "Change %": "-0.24%"
  },
  {
      "Date": "1-Feb-02",
      "Price": "7.652",
      "Open": "7.652",
      "High": "7.652",
      "Low": "7.652",
      "Change %": "-0.22%"
  },
  {
      "Date": "31-Jan-02",
      "Price": "7.669",
      "Open": "7.669",
      "High": "7.669",
      "Low": "7.669",
      "Change %": "-0.05%"
  },
  {
      "Date": "30-Jan-02",
      "Price": "7.673",
      "Open": "7.673",
      "High": "7.673",
      "Low": "7.673",
      "Change %": "-0.38%"
  },
  {
      "Date": "29-Jan-02",
      "Price": "7.702",
      "Open": "7.702",
      "High": "7.702",
      "Low": "7.702",
      "Change %": "-0.40%"
  },
  {
      "Date": "28-Jan-02",
      "Price": "7.733",
      "Open": "7.733",
      "High": "7.733",
      "Low": "7.733",
      "Change %": "-0.46%"
  },
  {
      "Date": "25-Jan-02",
      "Price": "7.769",
      "Open": "7.769",
      "High": "7.769",
      "Low": "7.769",
      "Change %": "-0.06%"
  },
  {
      "Date": "24-Jan-02",
      "Price": "7.774",
      "Open": "7.774",
      "High": "7.774",
      "Low": "7.774",
      "Change %": "0.04%"
  },
  {
      "Date": "23-Jan-02",
      "Price": "7.771",
      "Open": "7.771",
      "High": "7.771",
      "Low": "7.771",
      "Change %": "0.10%"
  },
  {
      "Date": "22-Jan-02",
      "Price": "7.763",
      "Open": "7.763",
      "High": "7.763",
      "Low": "7.763",
      "Change %": "-0.32%"
  },
  {
      "Date": "21-Jan-02",
      "Price": "7.788",
      "Open": "7.788",
      "High": "7.788",
      "Low": "7.788",
      "Change %": "-0.29%"
  },
  {
      "Date": "19-Jan-02",
      "Price": "7.811",
      "Open": "7.811",
      "High": "7.811",
      "Low": "7.811",
      "Change %": "-0.46%"
  },
  {
      "Date": "18-Jan-02",
      "Price": "7.847",
      "Open": "7.847",
      "High": "7.847",
      "Low": "7.847",
      "Change %": "-0.61%"
  },
  {
      "Date": "17-Jan-02",
      "Price": "7.895",
      "Open": "7.895",
      "High": "7.895",
      "Low": "7.895",
      "Change %": "0.08%"
  },
  {
      "Date": "16-Jan-02",
      "Price": "7.889",
      "Open": "7.889",
      "High": "7.889",
      "Low": "7.889",
      "Change %": "-0.25%"
  },
  {
      "Date": "15-Jan-02",
      "Price": "7.909",
      "Open": "7.909",
      "High": "7.909",
      "Low": "7.909",
      "Change %": "0.01%"
  },
  {
      "Date": "14-Jan-02",
      "Price": "7.908",
      "Open": "7.908",
      "High": "7.908",
      "Low": "7.908",
      "Change %": "-0.58%"
  },
  {
      "Date": "12-Jan-02",
      "Price": "7.954",
      "Open": "7.954",
      "High": "7.954",
      "Low": "7.954",
      "Change %": "-0.03%"
  },
  {
      "Date": "11-Jan-02",
      "Price": "7.956",
      "Open": "7.956",
      "High": "7.956",
      "Low": "7.956",
      "Change %": "0.61%"
  },
  {
      "Date": "10-Jan-02",
      "Price": "7.908",
      "Open": "7.908",
      "High": "7.908",
      "Low": "7.908",
      "Change %": "-0.03%"
  },
  {
      "Date": "9-Jan-02",
      "Price": "7.91",
      "Open": "7.91",
      "High": "7.91",
      "Low": "7.91",
      "Change %": "0.20%"
  },
  {
      "Date": "8-Jan-02",
      "Price": "7.894",
      "Open": "7.894",
      "High": "7.894",
      "Low": "7.894",
      "Change %": "-0.16%"
  },
  {
      "Date": "7-Jan-02",
      "Price": "7.907",
      "Open": "7.907",
      "High": "7.907",
      "Low": "7.907",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Jan-02",
      "Price": "7.907",
      "Open": "7.907",
      "High": "7.907",
      "Low": "7.907",
      "Change %": "-0.06%"
  },
  {
      "Date": "4-Jan-02",
      "Price": "7.912",
      "Open": "7.912",
      "High": "7.912",
      "Low": "7.912",
      "Change %": "0.08%"
  },
  {
      "Date": "3-Jan-02",
      "Price": "7.906",
      "Open": "7.906",
      "High": "7.906",
      "Low": "7.906",
      "Change %": "0.20%"
  },
  {
      "Date": "2-Jan-02",
      "Price": "7.89",
      "Open": "7.89",
      "High": "7.89",
      "Low": "7.89",
      "Change %": "-0.27%"
  },
  {
      "Date": "1-Jan-02",
      "Price": "7.911",
      "Open": "7.911",
      "High": "7.911",
      "Low": "7.911",
      "Change %": "-0.35%"
  },
  {
      "Date": "31-Dec-01",
      "Price": "7.939",
      "Open": "7.939",
      "High": "7.939",
      "Low": "7.939",
      "Change %": "-1.35%"
  },
  {
      "Date": "29-Dec-01",
      "Price": "8.048",
      "Open": "8.048",
      "High": "8.048",
      "Low": "8.048",
      "Change %": "-1.89%"
  },
  {
      "Date": "28-Dec-01",
      "Price": "8.203",
      "Open": "8.203",
      "High": "8.203",
      "Low": "8.203",
      "Change %": "-0.76%"
  },
  {
      "Date": "27-Dec-01",
      "Price": "8.266",
      "Open": "8.266",
      "High": "8.266",
      "Low": "8.266",
      "Change %": "-0.91%"
  },
  {
      "Date": "26-Dec-01",
      "Price": "8.342",
      "Open": "8.342",
      "High": "8.342",
      "Low": "8.342",
      "Change %": "1.74%"
  },
  {
      "Date": "24-Dec-01",
      "Price": "8.199",
      "Open": "8.199",
      "High": "8.199",
      "Low": "8.199",
      "Change %": "0.05%"
  },
  {
      "Date": "22-Dec-01",
      "Price": "8.195",
      "Open": "8.195",
      "High": "8.195",
      "Low": "8.195",
      "Change %": "1.70%"
  },
  {
      "Date": "21-Dec-01",
      "Price": "8.058",
      "Open": "8.058",
      "High": "8.058",
      "Low": "8.058",
      "Change %": "0.59%"
  },
  {
      "Date": "20-Dec-01",
      "Price": "8.011",
      "Open": "8.011",
      "High": "8.011",
      "Low": "8.011",
      "Change %": "0.04%"
  },
  {
      "Date": "19-Dec-01",
      "Price": "8.008",
      "Open": "8.008",
      "High": "8.008",
      "Low": "8.008",
      "Change %": "-0.81%"
  },
  {
      "Date": "18-Dec-01",
      "Price": "8.073",
      "Open": "8.073",
      "High": "8.073",
      "Low": "8.073",
      "Change %": "1.05%"
  },
  {
      "Date": "15-Dec-01",
      "Price": "7.989",
      "Open": "7.989",
      "High": "7.989",
      "Low": "7.989",
      "Change %": "0.64%"
  },
  {
      "Date": "14-Dec-01",
      "Price": "7.938",
      "Open": "7.938",
      "High": "7.938",
      "Low": "7.938",
      "Change %": "-1.00%"
  },
  {
      "Date": "13-Dec-01",
      "Price": "8.018",
      "Open": "8.018",
      "High": "8.018",
      "Low": "8.018",
      "Change %": "0.15%"
  },
  {
      "Date": "12-Dec-01",
      "Price": "8.006",
      "Open": "8.006",
      "High": "8.006",
      "Low": "8.006",
      "Change %": "-2.93%"
  },
  {
      "Date": "11-Dec-01",
      "Price": "8.248",
      "Open": "8.248",
      "High": "8.248",
      "Low": "8.248",
      "Change %": "2.19%"
  },
  {
      "Date": "10-Dec-01",
      "Price": "8.071",
      "Open": "8.071",
      "High": "8.071",
      "Low": "8.071",
      "Change %": "-0.16%"
  },
  {
      "Date": "8-Dec-01",
      "Price": "8.084",
      "Open": "8.084",
      "High": "8.084",
      "Low": "8.084",
      "Change %": "-0.89%"
  },
  {
      "Date": "7-Dec-01",
      "Price": "8.157",
      "Open": "8.157",
      "High": "8.157",
      "Low": "8.157",
      "Change %": "4.66%"
  },
  {
      "Date": "6-Dec-01",
      "Price": "7.794",
      "Open": "7.794",
      "High": "7.794",
      "Low": "7.794",
      "Change %": "0.04%"
  },
  {
      "Date": "5-Dec-01",
      "Price": "7.791",
      "Open": "7.791",
      "High": "7.791",
      "Low": "7.791",
      "Change %": "0.21%"
  },
  {
      "Date": "4-Dec-01",
      "Price": "7.775",
      "Open": "7.775",
      "High": "7.775",
      "Low": "7.775",
      "Change %": "-1.33%"
  },
  {
      "Date": "3-Dec-01",
      "Price": "7.88",
      "Open": "7.88",
      "High": "7.88",
      "Low": "7.88",
      "Change %": "0.04%"
  },
  {
      "Date": "1-Dec-01",
      "Price": "7.877",
      "Open": "7.877",
      "High": "7.877",
      "Low": "7.877",
      "Change %": "-0.04%"
  },
  {
      "Date": "29-Nov-01",
      "Price": "7.88",
      "Open": "7.88",
      "High": "7.88",
      "Low": "7.88",
      "Change %": "-0.01%"
  },
  {
      "Date": "28-Nov-01",
      "Price": "7.881",
      "Open": "7.881",
      "High": "7.881",
      "Low": "7.881",
      "Change %": "-1.44%"
  },
  {
      "Date": "27-Nov-01",
      "Price": "7.996",
      "Open": "7.996",
      "High": "7.996",
      "Low": "7.996",
      "Change %": "-1.43%"
  },
  {
      "Date": "26-Nov-01",
      "Price": "8.112",
      "Open": "8.112",
      "High": "8.112",
      "Low": "8.112",
      "Change %": "-0.32%"
  },
  {
      "Date": "24-Nov-01",
      "Price": "8.138",
      "Open": "8.138",
      "High": "8.138",
      "Low": "8.138",
      "Change %": "-0.26%"
  },
  {
      "Date": "23-Nov-01",
      "Price": "8.159",
      "Open": "8.159",
      "High": "8.159",
      "Low": "8.159",
      "Change %": "-0.39%"
  },
  {
      "Date": "22-Nov-01",
      "Price": "8.191",
      "Open": "8.191",
      "High": "8.191",
      "Low": "8.191",
      "Change %": "-1.49%"
  },
  {
      "Date": "21-Nov-01",
      "Price": "8.315",
      "Open": "8.315",
      "High": "8.315",
      "Low": "8.315",
      "Change %": "-0.63%"
  },
  {
      "Date": "20-Nov-01",
      "Price": "8.368",
      "Open": "8.368",
      "High": "8.368",
      "Low": "8.368",
      "Change %": "-1.02%"
  },
  {
      "Date": "19-Nov-01",
      "Price": "8.454",
      "Open": "8.454",
      "High": "8.454",
      "Low": "8.454",
      "Change %": "-1.09%"
  },
  {
      "Date": "17-Nov-01",
      "Price": "8.547",
      "Open": "8.547",
      "High": "8.547",
      "Low": "8.547",
      "Change %": "-1.03%"
  },
  {
      "Date": "15-Nov-01",
      "Price": "8.636",
      "Open": "8.636",
      "High": "8.636",
      "Low": "8.636",
      "Change %": "-0.01%"
  },
  {
      "Date": "13-Nov-01",
      "Price": "8.637",
      "Open": "8.637",
      "High": "8.637",
      "Low": "8.637",
      "Change %": "0.15%"
  },
  {
      "Date": "12-Nov-01",
      "Price": "8.624",
      "Open": "8.624",
      "High": "8.624",
      "Low": "8.624",
      "Change %": "-0.56%"
  },
  {
      "Date": "10-Nov-01",
      "Price": "8.673",
      "Open": "8.673",
      "High": "8.673",
      "Low": "8.673",
      "Change %": "-0.37%"
  },
  {
      "Date": "9-Nov-01",
      "Price": "8.705",
      "Open": "8.705",
      "High": "8.705",
      "Low": "8.705",
      "Change %": "-0.34%"
  },
  {
      "Date": "8-Nov-01",
      "Price": "8.735",
      "Open": "8.735",
      "High": "8.735",
      "Low": "8.735",
      "Change %": "0.16%"
  },
  {
      "Date": "7-Nov-01",
      "Price": "8.721",
      "Open": "8.721",
      "High": "8.721",
      "Low": "8.721",
      "Change %": "-0.40%"
  },
  {
      "Date": "6-Nov-01",
      "Price": "8.756",
      "Open": "8.756",
      "High": "8.756",
      "Low": "8.756",
      "Change %": "-0.44%"
  },
  {
      "Date": "5-Nov-01",
      "Price": "8.795",
      "Open": "8.795",
      "High": "8.795",
      "Low": "8.795",
      "Change %": "0.39%"
  },
  {
      "Date": "3-Nov-01",
      "Price": "8.761",
      "Open": "8.761",
      "High": "8.761",
      "Low": "8.761",
      "Change %": "-0.09%"
  },
  {
      "Date": "2-Nov-01",
      "Price": "8.769",
      "Open": "8.769",
      "High": "8.769",
      "Low": "8.769",
      "Change %": "-0.69%"
  },
  {
      "Date": "1-Nov-01",
      "Price": "8.83",
      "Open": "8.83",
      "High": "8.83",
      "Low": "8.83",
      "Change %": "0.34%"
  },
  {
      "Date": "31-Oct-01",
      "Price": "8.8",
      "Open": "8.8",
      "High": "8.8",
      "Low": "8.8",
      "Change %": "-0.42%"
  },
  {
      "Date": "30-Oct-01",
      "Price": "8.837",
      "Open": "8.837",
      "High": "8.837",
      "Low": "8.837",
      "Change %": "-0.37%"
  },
  {
      "Date": "29-Oct-01",
      "Price": "8.87",
      "Open": "8.87",
      "High": "8.87",
      "Low": "8.87",
      "Change %": "-0.15%"
  },
  {
      "Date": "27-Oct-01",
      "Price": "8.883",
      "Open": "8.883",
      "High": "8.883",
      "Low": "8.883",
      "Change %": "-0.24%"
  },
  {
      "Date": "25-Oct-01",
      "Price": "8.904",
      "Open": "8.904",
      "High": "8.904",
      "Low": "8.904",
      "Change %": "0.03%"
  },
  {
      "Date": "24-Oct-01",
      "Price": "8.901",
      "Open": "8.901",
      "High": "8.901",
      "Low": "8.901",
      "Change %": "0.16%"
  },
  {
      "Date": "23-Oct-01",
      "Price": "8.887",
      "Open": "8.887",
      "High": "8.887",
      "Low": "8.887",
      "Change %": "-0.77%"
  },
  {
      "Date": "22-Oct-01",
      "Price": "8.956",
      "Open": "8.956",
      "High": "8.956",
      "Low": "8.956",
      "Change %": "-1.77%"
  },
  {
      "Date": "20-Oct-01",
      "Price": "9.117",
      "Open": "9.117",
      "High": "9.117",
      "Low": "9.117",
      "Change %": "-0.02%"
  },
  {
      "Date": "19-Oct-01",
      "Price": "9.119",
      "Open": "9.119",
      "High": "9.119",
      "Low": "9.119",
      "Change %": "-0.04%"
  },
  {
      "Date": "18-Oct-01",
      "Price": "9.123",
      "Open": "9.123",
      "High": "9.123",
      "Low": "9.123",
      "Change %": "0.14%"
  },
  {
      "Date": "17-Oct-01",
      "Price": "9.11",
      "Open": "9.11",
      "High": "9.11",
      "Low": "9.11",
      "Change %": "-0.15%"
  },
  {
      "Date": "16-Oct-01",
      "Price": "9.124",
      "Open": "9.124",
      "High": "9.124",
      "Low": "9.124",
      "Change %": "-0.30%"
  },
  {
      "Date": "15-Oct-01",
      "Price": "9.151",
      "Open": "9.151",
      "High": "9.151",
      "Low": "9.151",
      "Change %": "0.25%"
  },
  {
      "Date": "13-Oct-01",
      "Price": "9.128",
      "Open": "9.128",
      "High": "9.128",
      "Low": "9.128",
      "Change %": "-0.12%"
  },
  {
      "Date": "12-Oct-01",
      "Price": "9.139",
      "Open": "9.139",
      "High": "9.139",
      "Low": "9.139",
      "Change %": "0.33%"
  },
  {
      "Date": "11-Oct-01",
      "Price": "9.109",
      "Open": "9.109",
      "High": "9.109",
      "Low": "9.109",
      "Change %": "-0.11%"
  },
  {
      "Date": "10-Oct-01",
      "Price": "9.119",
      "Open": "9.119",
      "High": "9.119",
      "Low": "9.119",
      "Change %": "0.08%"
  },
  {
      "Date": "9-Oct-01",
      "Price": "9.112",
      "Open": "9.112",
      "High": "9.112",
      "Low": "9.112",
      "Change %": "-0.68%"
  },
  {
      "Date": "8-Oct-01",
      "Price": "9.174",
      "Open": "9.174",
      "High": "9.174",
      "Low": "9.174",
      "Change %": "0.63%"
  },
  {
      "Date": "6-Oct-01",
      "Price": "9.117",
      "Open": "9.117",
      "High": "9.117",
      "Low": "9.117",
      "Change %": "0.04%"
  },
  {
      "Date": "5-Oct-01",
      "Price": "9.113",
      "Open": "9.113",
      "High": "9.113",
      "Low": "9.113",
      "Change %": "0.34%"
  },
  {
      "Date": "4-Oct-01",
      "Price": "9.082",
      "Open": "9.082",
      "High": "9.082",
      "Low": "9.082",
      "Change %": "-0.35%"
  },
  {
      "Date": "3-Oct-01",
      "Price": "9.114",
      "Open": "9.114",
      "High": "9.114",
      "Low": "9.114",
      "Change %": "-0.98%"
  },
  {
      "Date": "1-Oct-01",
      "Price": "9.204",
      "Open": "9.204",
      "High": "9.204",
      "Low": "9.204",
      "Change %": "0.90%"
  },
  {
      "Date": "29-Sep-01",
      "Price": "9.122",
      "Open": "9.122",
      "High": "9.122",
      "Low": "9.122",
      "Change %": "0.07%"
  },
  {
      "Date": "28-Sep-01",
      "Price": "9.116",
      "Open": "9.116",
      "High": "9.116",
      "Low": "9.116",
      "Change %": "-1.13%"
  },
  {
      "Date": "27-Sep-01",
      "Price": "9.22",
      "Open": "9.22",
      "High": "9.22",
      "Low": "9.22",
      "Change %": "-0.88%"
  },
  {
      "Date": "26-Sep-01",
      "Price": "9.302",
      "Open": "9.302",
      "High": "9.302",
      "Low": "9.302",
      "Change %": "-0.15%"
  },
  {
      "Date": "25-Sep-01",
      "Price": "9.316",
      "Open": "9.316",
      "High": "9.316",
      "Low": "9.316",
      "Change %": "-0.27%"
  },
  {
      "Date": "24-Sep-01",
      "Price": "9.341",
      "Open": "9.341",
      "High": "9.341",
      "Low": "9.341",
      "Change %": "-1.34%"
  },
  {
      "Date": "22-Sep-01",
      "Price": "9.468",
      "Open": "9.468",
      "High": "9.468",
      "Low": "9.468",
      "Change %": "-2.53%"
  },
  {
      "Date": "21-Sep-01",
      "Price": "9.714",
      "Open": "9.714",
      "High": "9.714",
      "Low": "9.714",
      "Change %": "2.05%"
  },
  {
      "Date": "20-Sep-01",
      "Price": "9.519",
      "Open": "9.519",
      "High": "9.519",
      "Low": "9.519",
      "Change %": "1.51%"
  },
  {
      "Date": "19-Sep-01",
      "Price": "9.377",
      "Open": "9.377",
      "High": "9.377",
      "Low": "9.377",
      "Change %": "-0.69%"
  },
  {
      "Date": "18-Sep-01",
      "Price": "9.442",
      "Open": "9.442",
      "High": "9.442",
      "Low": "9.442",
      "Change %": "-1.93%"
  },
  {
      "Date": "17-Sep-01",
      "Price": "9.628",
      "Open": "9.628",
      "High": "9.628",
      "Low": "9.628",
      "Change %": "-2.01%"
  },
  {
      "Date": "15-Sep-01",
      "Price": "9.825",
      "Open": "9.825",
      "High": "9.825",
      "Low": "9.825",
      "Change %": "3.81%"
  },
  {
      "Date": "14-Sep-01",
      "Price": "9.464",
      "Open": "9.464",
      "High": "9.464",
      "Low": "9.464",
      "Change %": "2.36%"
  },
  {
      "Date": "13-Sep-01",
      "Price": "9.246",
      "Open": "9.246",
      "High": "9.246",
      "Low": "9.246",
      "Change %": "0.73%"
  },
  {
      "Date": "12-Sep-01",
      "Price": "9.179",
      "Open": "9.179",
      "High": "9.179",
      "Low": "9.179",
      "Change %": "0.56%"
  },
  {
      "Date": "11-Sep-01",
      "Price": "9.128",
      "Open": "9.128",
      "High": "9.128",
      "Low": "9.128",
      "Change %": "-0.58%"
  },
  {
      "Date": "10-Sep-01",
      "Price": "9.181",
      "Open": "9.181",
      "High": "9.181",
      "Low": "9.181",
      "Change %": "0.17%"
  },
  {
      "Date": "8-Sep-01",
      "Price": "9.165",
      "Open": "9.165",
      "High": "9.165",
      "Low": "9.165",
      "Change %": "-0.11%"
  },
  {
      "Date": "7-Sep-01",
      "Price": "9.175",
      "Open": "9.175",
      "High": "9.175",
      "Low": "9.175",
      "Change %": "0.43%"
  },
  {
      "Date": "6-Sep-01",
      "Price": "9.136",
      "Open": "9.136",
      "High": "9.136",
      "Low": "9.136",
      "Change %": "0.07%"
  },
  {
      "Date": "5-Sep-01",
      "Price": "9.13",
      "Open": "9.13",
      "High": "9.13",
      "Low": "9.13",
      "Change %": "0.35%"
  },
  {
      "Date": "4-Sep-01",
      "Price": "9.098",
      "Open": "9.098",
      "High": "9.098",
      "Low": "9.098",
      "Change %": "-0.24%"
  },
  {
      "Date": "3-Sep-01",
      "Price": "9.12",
      "Open": "9.12",
      "High": "9.12",
      "Low": "9.12",
      "Change %": "-0.27%"
  },
  {
      "Date": "1-Sep-01",
      "Price": "9.145",
      "Open": "9.145",
      "High": "9.145",
      "Low": "9.145",
      "Change %": "0.34%"
  },
  {
      "Date": "31-Aug-01",
      "Price": "9.114",
      "Open": "9.114",
      "High": "9.114",
      "Low": "9.114",
      "Change %": "0.13%"
  },
  {
      "Date": "30-Aug-01",
      "Price": "9.102",
      "Open": "9.102",
      "High": "9.102",
      "Low": "9.102",
      "Change %": "0.00%"
  },
  {
      "Date": "29-Aug-01",
      "Price": "9.102",
      "Open": "9.102",
      "High": "9.102",
      "Low": "9.102",
      "Change %": "0.35%"
  },
  {
      "Date": "28-Aug-01",
      "Price": "9.07",
      "Open": "9.07",
      "High": "9.07",
      "Low": "9.07",
      "Change %": "-0.09%"
  },
  {
      "Date": "27-Aug-01",
      "Price": "9.078",
      "Open": "9.078",
      "High": "9.078",
      "Low": "9.078",
      "Change %": "0.15%"
  },
  {
      "Date": "25-Aug-01",
      "Price": "9.064",
      "Open": "9.064",
      "High": "9.064",
      "Low": "9.064",
      "Change %": "-0.25%"
  },
  {
      "Date": "24-Aug-01",
      "Price": "9.087",
      "Open": "9.087",
      "High": "9.087",
      "Low": "9.087",
      "Change %": "-0.79%"
  },
  {
      "Date": "23-Aug-01",
      "Price": "9.159",
      "Open": "9.159",
      "High": "9.159",
      "Low": "9.159",
      "Change %": "-0.33%"
  },
  {
      "Date": "20-Aug-01",
      "Price": "9.189",
      "Open": "9.189",
      "High": "9.189",
      "Low": "9.189",
      "Change %": "-0.10%"
  },
  {
      "Date": "18-Aug-01",
      "Price": "9.198",
      "Open": "9.198",
      "High": "9.198",
      "Low": "9.198",
      "Change %": "-0.26%"
  },
  {
      "Date": "17-Aug-01",
      "Price": "9.222",
      "Open": "9.222",
      "High": "9.222",
      "Low": "9.222",
      "Change %": "-0.31%"
  },
  {
      "Date": "16-Aug-01",
      "Price": "9.251",
      "Open": "9.251",
      "High": "9.251",
      "Low": "9.251",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Aug-01",
      "Price": "9.251",
      "Open": "9.251",
      "High": "9.251",
      "Low": "9.251",
      "Change %": "0.05%"
  },
  {
      "Date": "13-Aug-01",
      "Price": "9.246",
      "Open": "9.246",
      "High": "9.246",
      "Low": "9.246",
      "Change %": "0.04%"
  },
  {
      "Date": "11-Aug-01",
      "Price": "9.242",
      "Open": "9.242",
      "High": "9.242",
      "Low": "9.242",
      "Change %": "-0.27%"
  },
  {
      "Date": "10-Aug-01",
      "Price": "9.267",
      "Open": "9.267",
      "High": "9.267",
      "Low": "9.267",
      "Change %": "-0.45%"
  },
  {
      "Date": "9-Aug-01",
      "Price": "9.309",
      "Open": "9.309",
      "High": "9.309",
      "Low": "9.309",
      "Change %": "-0.23%"
  },
  {
      "Date": "8-Aug-01",
      "Price": "9.33",
      "Open": "9.33",
      "High": "9.33",
      "Low": "9.33",
      "Change %": "0.16%"
  },
  {
      "Date": "7-Aug-01",
      "Price": "9.315",
      "Open": "9.315",
      "High": "9.315",
      "Low": "9.315",
      "Change %": "0.12%"
  },
  {
      "Date": "6-Aug-01",
      "Price": "9.304",
      "Open": "9.304",
      "High": "9.304",
      "Low": "9.304",
      "Change %": "-0.26%"
  },
  {
      "Date": "4-Aug-01",
      "Price": "9.328",
      "Open": "9.328",
      "High": "9.328",
      "Low": "9.328",
      "Change %": "-0.21%"
  },
  {
      "Date": "3-Aug-01",
      "Price": "9.348",
      "Open": "9.348",
      "High": "9.348",
      "Low": "9.348",
      "Change %": "-0.09%"
  },
  {
      "Date": "2-Aug-01",
      "Price": "9.356",
      "Open": "9.356",
      "High": "9.356",
      "Low": "9.356",
      "Change %": "-0.02%"
  },
  {
      "Date": "1-Aug-01",
      "Price": "9.358",
      "Open": "9.358",
      "High": "9.358",
      "Low": "9.358",
      "Change %": "-0.05%"
  },
  {
      "Date": "31-Jul-01",
      "Price": "9.363",
      "Open": "9.363",
      "High": "9.363",
      "Low": "9.363",
      "Change %": "0.34%"
  },
  {
      "Date": "30-Jul-01",
      "Price": "9.331",
      "Open": "9.331",
      "High": "9.331",
      "Low": "9.331",
      "Change %": "0.00%"
  },
  {
      "Date": "28-Jul-01",
      "Price": "9.331",
      "Open": "9.331",
      "High": "9.331",
      "Low": "9.331",
      "Change %": "-0.07%"
  },
  {
      "Date": "27-Jul-01",
      "Price": "9.338",
      "Open": "9.338",
      "High": "9.338",
      "Low": "9.338",
      "Change %": "0.08%"
  },
  {
      "Date": "26-Jul-01",
      "Price": "9.331",
      "Open": "9.331",
      "High": "9.331",
      "Low": "9.331",
      "Change %": "-0.24%"
  },
  {
      "Date": "25-Jul-01",
      "Price": "9.353",
      "Open": "9.353",
      "High": "9.353",
      "Low": "9.353",
      "Change %": "0.39%"
  },
  {
      "Date": "24-Jul-01",
      "Price": "9.317",
      "Open": "9.317",
      "High": "9.317",
      "Low": "9.317",
      "Change %": "-0.30%"
  },
  {
      "Date": "23-Jul-01",
      "Price": "9.345",
      "Open": "9.345",
      "High": "9.345",
      "Low": "9.345",
      "Change %": "0.07%"
  },
  {
      "Date": "21-Jul-01",
      "Price": "9.338",
      "Open": "9.338",
      "High": "9.338",
      "Low": "9.338",
      "Change %": "-0.05%"
  },
  {
      "Date": "20-Jul-01",
      "Price": "9.343",
      "Open": "9.343",
      "High": "9.343",
      "Low": "9.343",
      "Change %": "-0.31%"
  },
  {
      "Date": "19-Jul-01",
      "Price": "9.372",
      "Open": "9.372",
      "High": "9.372",
      "Low": "9.372",
      "Change %": "-0.51%"
  },
  {
      "Date": "18-Jul-01",
      "Price": "9.42",
      "Open": "9.42",
      "High": "9.42",
      "Low": "9.42",
      "Change %": "-0.29%"
  },
  {
      "Date": "17-Jul-01",
      "Price": "9.447",
      "Open": "9.447",
      "High": "9.447",
      "Low": "9.447",
      "Change %": "0.07%"
  },
  {
      "Date": "16-Jul-01",
      "Price": "9.44",
      "Open": "9.44",
      "High": "9.44",
      "Low": "9.44",
      "Change %": "0.48%"
  },
  {
      "Date": "14-Jul-01",
      "Price": "9.395",
      "Open": "9.395",
      "High": "9.395",
      "Low": "9.395",
      "Change %": "-0.93%"
  },
  {
      "Date": "13-Jul-01",
      "Price": "9.483",
      "Open": "9.483",
      "High": "9.483",
      "Low": "9.483",
      "Change %": "0.30%"
  },
  {
      "Date": "12-Jul-01",
      "Price": "9.455",
      "Open": "9.455",
      "High": "9.455",
      "Low": "9.455",
      "Change %": "2.13%"
  },
  {
      "Date": "11-Jul-01",
      "Price": "9.258",
      "Open": "9.258",
      "High": "9.258",
      "Low": "9.258",
      "Change %": "-0.76%"
  },
  {
      "Date": "10-Jul-01",
      "Price": "9.329",
      "Open": "9.329",
      "High": "9.329",
      "Low": "9.329",
      "Change %": "-0.60%"
  },
  {
      "Date": "9-Jul-01",
      "Price": "9.385",
      "Open": "9.385",
      "High": "9.385",
      "Low": "9.385",
      "Change %": "-0.09%"
  },
  {
      "Date": "7-Jul-01",
      "Price": "9.393",
      "Open": "9.393",
      "High": "9.393",
      "Low": "9.393",
      "Change %": "-0.15%"
  },
  {
      "Date": "6-Jul-01",
      "Price": "9.407",
      "Open": "9.407",
      "High": "9.407",
      "Low": "9.407",
      "Change %": "-0.33%"
  },
  {
      "Date": "5-Jul-01",
      "Price": "9.438",
      "Open": "9.438",
      "High": "9.438",
      "Low": "9.438",
      "Change %": "-0.68%"
  },
  {
      "Date": "4-Jul-01",
      "Price": "9.503",
      "Open": "9.503",
      "High": "9.503",
      "Low": "9.503",
      "Change %": "-0.57%"
  },
  {
      "Date": "3-Jul-01",
      "Price": "9.557",
      "Open": "9.557",
      "High": "9.557",
      "Low": "9.557",
      "Change %": "0.15%"
  },
  {
      "Date": "2-Jul-01",
      "Price": "9.543",
      "Open": "9.543",
      "High": "9.543",
      "Low": "9.543",
      "Change %": "0.44%"
  },
  {
      "Date": "30-Jun-01",
      "Price": "9.501",
      "Open": "9.501",
      "High": "9.501",
      "Low": "9.501",
      "Change %": "-0.22%"
  },
  {
      "Date": "29-Jun-01",
      "Price": "9.522",
      "Open": "9.522",
      "High": "9.522",
      "Low": "9.522",
      "Change %": "0.11%"
  },
  {
      "Date": "28-Jun-01",
      "Price": "9.512",
      "Open": "9.512",
      "High": "9.512",
      "Low": "9.512",
      "Change %": "-0.14%"
  },
  {
      "Date": "27-Jun-01",
      "Price": "9.525",
      "Open": "9.525",
      "High": "9.525",
      "Low": "9.525",
      "Change %": "0.17%"
  },
  {
      "Date": "26-Jun-01",
      "Price": "9.509",
      "Open": "9.509",
      "High": "9.509",
      "Low": "9.509",
      "Change %": "-0.68%"
  },
  {
      "Date": "25-Jun-01",
      "Price": "9.574",
      "Open": "9.574",
      "High": "9.574",
      "Low": "9.574",
      "Change %": "-0.41%"
  },
  {
      "Date": "23-Jun-01",
      "Price": "9.613",
      "Open": "9.613",
      "High": "9.613",
      "Low": "9.613",
      "Change %": "-0.20%"
  },
  {
      "Date": "22-Jun-01",
      "Price": "9.632",
      "Open": "9.632",
      "High": "9.632",
      "Low": "9.632",
      "Change %": "0.08%"
  },
  {
      "Date": "21-Jun-01",
      "Price": "9.624",
      "Open": "9.624",
      "High": "9.624",
      "Low": "9.624",
      "Change %": "-0.15%"
  },
  {
      "Date": "20-Jun-01",
      "Price": "9.638",
      "Open": "9.638",
      "High": "9.638",
      "Low": "9.638",
      "Change %": "-0.32%"
  },
  {
      "Date": "19-Jun-01",
      "Price": "9.669",
      "Open": "9.669",
      "High": "9.669",
      "Low": "9.669",
      "Change %": "-0.55%"
  },
  {
      "Date": "18-Jun-01",
      "Price": "9.722",
      "Open": "9.722",
      "High": "9.722",
      "Low": "9.722",
      "Change %": "-0.30%"
  },
  {
      "Date": "16-Jun-01",
      "Price": "9.751",
      "Open": "9.751",
      "High": "9.751",
      "Low": "9.751",
      "Change %": "0.01%"
  },
  {
      "Date": "15-Jun-01",
      "Price": "9.75",
      "Open": "9.75",
      "High": "9.75",
      "Low": "9.75",
      "Change %": "-0.08%"
  },
  {
      "Date": "14-Jun-01",
      "Price": "9.758",
      "Open": "9.758",
      "High": "9.758",
      "Low": "9.758",
      "Change %": "-0.02%"
  },
  {
      "Date": "13-Jun-01",
      "Price": "9.76",
      "Open": "9.76",
      "High": "9.76",
      "Low": "9.76",
      "Change %": "-0.63%"
  },
  {
      "Date": "12-Jun-01",
      "Price": "9.822",
      "Open": "9.822",
      "High": "9.822",
      "Low": "9.822",
      "Change %": "0.03%"
  },
  {
      "Date": "11-Jun-01",
      "Price": "9.819",
      "Open": "9.819",
      "High": "9.819",
      "Low": "9.819",
      "Change %": "-0.03%"
  },
  {
      "Date": "9-Jun-01",
      "Price": "9.822",
      "Open": "9.822",
      "High": "9.822",
      "Low": "9.822",
      "Change %": "-0.69%"
  },
  {
      "Date": "8-Jun-01",
      "Price": "9.89",
      "Open": "9.89",
      "High": "9.89",
      "Low": "9.89",
      "Change %": "-0.28%"
  },
  {
      "Date": "7-Jun-01",
      "Price": "9.918",
      "Open": "9.918",
      "High": "9.918",
      "Low": "9.918",
      "Change %": "0.33%"
  },
  {
      "Date": "6-Jun-01",
      "Price": "9.885",
      "Open": "9.885",
      "High": "9.885",
      "Low": "9.885",
      "Change %": "-0.46%"
  },
  {
      "Date": "5-Jun-01",
      "Price": "9.931",
      "Open": "9.931",
      "High": "9.931",
      "Low": "9.931",
      "Change %": "0.02%"
  },
  {
      "Date": "4-Jun-01",
      "Price": "9.929",
      "Open": "9.929",
      "High": "9.929",
      "Low": "9.929",
      "Change %": "0.77%"
  },
  {
      "Date": "2-Jun-01",
      "Price": "9.853",
      "Open": "9.853",
      "High": "9.853",
      "Low": "9.853",
      "Change %": "0.50%"
  },
  {
      "Date": "1-Jun-01",
      "Price": "9.804",
      "Open": "9.804",
      "High": "9.804",
      "Low": "9.804",
      "Change %": "0.43%"
  },
  {
      "Date": "31-May-01",
      "Price": "9.762",
      "Open": "9.762",
      "High": "9.762",
      "Low": "9.762",
      "Change %": "-0.25%"
  },
  {
      "Date": "30-May-01",
      "Price": "9.786",
      "Open": "9.786",
      "High": "9.786",
      "Low": "9.786",
      "Change %": "-0.26%"
  },
  {
      "Date": "29-May-01",
      "Price": "9.812",
      "Open": "9.812",
      "High": "9.812",
      "Low": "9.812",
      "Change %": "0.00%"
  },
  {
      "Date": "28-May-01",
      "Price": "9.812",
      "Open": "9.812",
      "High": "9.812",
      "Low": "9.812",
      "Change %": "-0.66%"
  },
  {
      "Date": "26-May-01",
      "Price": "9.877",
      "Open": "9.877",
      "High": "9.877",
      "Low": "9.877",
      "Change %": "-0.40%"
  },
  {
      "Date": "25-May-01",
      "Price": "9.917",
      "Open": "9.917",
      "High": "9.917",
      "Low": "9.917",
      "Change %": "-0.30%"
  },
  {
      "Date": "24-May-01",
      "Price": "9.947",
      "Open": "9.947",
      "High": "9.947",
      "Low": "9.947",
      "Change %": "-0.20%"
  },
  {
      "Date": "23-May-01",
      "Price": "9.967",
      "Open": "9.967",
      "High": "9.967",
      "Low": "9.967",
      "Change %": "-0.09%"
  },
  {
      "Date": "22-May-01",
      "Price": "9.976",
      "Open": "9.976",
      "High": "9.976",
      "Low": "9.976",
      "Change %": "0.10%"
  },
  {
      "Date": "21-May-01",
      "Price": "9.966",
      "Open": "9.966",
      "High": "9.966",
      "Low": "9.966",
      "Change %": "0.09%"
  },
  {
      "Date": "19-May-01",
      "Price": "9.957",
      "Open": "9.957",
      "High": "9.957",
      "Low": "9.957",
      "Change %": "0.02%"
  },
  {
      "Date": "18-May-01",
      "Price": "9.955",
      "Open": "9.955",
      "High": "9.955",
      "Low": "9.955",
      "Change %": "0.10%"
  },
  {
      "Date": "17-May-01",
      "Price": "9.945",
      "Open": "9.945",
      "High": "9.945",
      "Low": "9.945",
      "Change %": "-0.35%"
  },
  {
      "Date": "16-May-01",
      "Price": "9.98",
      "Open": "9.98",
      "High": "9.98",
      "Low": "9.98",
      "Change %": "-0.51%"
  },
  {
      "Date": "15-May-01",
      "Price": "10.031",
      "Open": "10.031",
      "High": "10.031",
      "Low": "10.031",
      "Change %": "-0.16%"
  },
  {
      "Date": "14-May-01",
      "Price": "10.047",
      "Open": "10.047",
      "High": "10.047",
      "Low": "10.047",
      "Change %": "0.03%"
  },
  {
      "Date": "12-May-01",
      "Price": "10.044",
      "Open": "10.044",
      "High": "10.044",
      "Low": "10.044",
      "Change %": "0.11%"
  },
  {
      "Date": "11-May-01",
      "Price": "10.033",
      "Open": "10.033",
      "High": "10.033",
      "Low": "10.033",
      "Change %": "-0.31%"
  },
  {
      "Date": "10-May-01",
      "Price": "10.064",
      "Open": "10.064",
      "High": "10.064",
      "Low": "10.064",
      "Change %": "-0.32%"
  },
  {
      "Date": "9-May-01",
      "Price": "10.096",
      "Open": "10.096",
      "High": "10.096",
      "Low": "10.096",
      "Change %": "-0.11%"
  },
  {
      "Date": "8-May-01",
      "Price": "10.107",
      "Open": "10.107",
      "High": "10.107",
      "Low": "10.107",
      "Change %": "0.22%"
  },
  {
      "Date": "5-May-01",
      "Price": "10.085",
      "Open": "10.085",
      "High": "10.085",
      "Low": "10.085",
      "Change %": "0.09%"
  },
  {
      "Date": "4-May-01",
      "Price": "10.076",
      "Open": "10.076",
      "High": "10.076",
      "Low": "10.076",
      "Change %": "-0.27%"
  },
  {
      "Date": "3-May-01",
      "Price": "10.103",
      "Open": "10.103",
      "High": "10.103",
      "Low": "10.103",
      "Change %": "-0.12%"
  },
  {
      "Date": "2-May-01",
      "Price": "10.115",
      "Open": "10.115",
      "High": "10.115",
      "Low": "10.115",
      "Change %": "-0.10%"
  },
  {
      "Date": "30-Apr-01",
      "Price": "10.125",
      "Open": "10.125",
      "High": "10.125",
      "Low": "10.125",
      "Change %": "-0.05%"
  },
  {
      "Date": "28-Apr-01",
      "Price": "10.13",
      "Open": "10.13",
      "High": "10.13",
      "Low": "10.13",
      "Change %": "0.30%"
  },
  {
      "Date": "27-Apr-01",
      "Price": "10.1",
      "Open": "10.1",
      "High": "10.1",
      "Low": "10.1",
      "Change %": "-0.59%"
  },
  {
      "Date": "26-Apr-01",
      "Price": "10.16",
      "Open": "10.16",
      "High": "10.16",
      "Low": "10.16",
      "Change %": "-0.47%"
  },
  {
      "Date": "25-Apr-01",
      "Price": "10.208",
      "Open": "10.208",
      "High": "10.208",
      "Low": "10.208",
      "Change %": "-0.04%"
  },
  {
      "Date": "24-Apr-01",
      "Price": "10.212",
      "Open": "10.212",
      "High": "10.212",
      "Low": "10.212",
      "Change %": "-0.57%"
  },
  {
      "Date": "23-Apr-01",
      "Price": "10.271",
      "Open": "10.271",
      "High": "10.271",
      "Low": "10.271",
      "Change %": "-0.21%"
  },
  {
      "Date": "21-Apr-01",
      "Price": "10.293",
      "Open": "10.293",
      "High": "10.293",
      "Low": "10.293",
      "Change %": "-0.22%"
  },
  {
      "Date": "20-Apr-01",
      "Price": "10.316",
      "Open": "10.316",
      "High": "10.316",
      "Low": "10.316",
      "Change %": "-0.15%"
  },
  {
      "Date": "19-Apr-01",
      "Price": "10.331",
      "Open": "10.331",
      "High": "10.331",
      "Low": "10.331",
      "Change %": "0.00%"
  },
  {
      "Date": "18-Apr-01",
      "Price": "10.331",
      "Open": "10.331",
      "High": "10.331",
      "Low": "10.331",
      "Change %": "-0.37%"
  },
  {
      "Date": "17-Apr-01",
      "Price": "10.369",
      "Open": "10.369",
      "High": "10.369",
      "Low": "10.369",
      "Change %": "-0.14%"
  },
  {
      "Date": "16-Apr-01",
      "Price": "10.384",
      "Open": "10.384",
      "High": "10.384",
      "Low": "10.384",
      "Change %": "0.24%"
  },
  {
      "Date": "12-Apr-01",
      "Price": "10.359",
      "Open": "10.359",
      "High": "10.359",
      "Low": "10.359",
      "Change %": "0.12%"
  },
  {
      "Date": "11-Apr-01",
      "Price": "10.347",
      "Open": "10.347",
      "High": "10.347",
      "Low": "10.347",
      "Change %": "0.29%"
  },
  {
      "Date": "10-Apr-01",
      "Price": "10.317",
      "Open": "10.317",
      "High": "10.317",
      "Low": "10.317",
      "Change %": "-1.00%"
  },
  {
      "Date": "9-Apr-01",
      "Price": "10.421",
      "Open": "10.421",
      "High": "10.421",
      "Low": "10.421",
      "Change %": "0.06%"
  },
  {
      "Date": "7-Apr-01",
      "Price": "10.415",
      "Open": "10.415",
      "High": "10.415",
      "Low": "10.415",
      "Change %": "-0.22%"
  },
  {
      "Date": "4-Apr-01",
      "Price": "10.438",
      "Open": "10.438",
      "High": "10.438",
      "Low": "10.438",
      "Change %": "0.29%"
  },
  {
      "Date": "3-Apr-01",
      "Price": "10.408",
      "Open": "10.408",
      "High": "10.408",
      "Low": "10.408",
      "Change %": "0.73%"
  },
  {
      "Date": "31-Mar-01",
      "Price": "10.333",
      "Open": "10.333",
      "High": "10.333",
      "Low": "10.333",
      "Change %": "-0.36%"
  },
  {
      "Date": "30-Mar-01",
      "Price": "10.37",
      "Open": "10.37",
      "High": "10.37",
      "Low": "10.37",
      "Change %": "-1.15%"
  },
  {
      "Date": "29-Mar-01",
      "Price": "10.491",
      "Open": "10.491",
      "High": "10.491",
      "Low": "10.491",
      "Change %": "-0.14%"
  },
  {
      "Date": "28-Mar-01",
      "Price": "10.506",
      "Open": "10.506",
      "High": "10.506",
      "Low": "10.506",
      "Change %": "0.94%"
  },
  {
      "Date": "27-Mar-01",
      "Price": "10.408",
      "Open": "10.408",
      "High": "10.408",
      "Low": "10.408",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Mar-01",
      "Price": "10.408",
      "Open": "10.408",
      "High": "10.408",
      "Low": "10.408",
      "Change %": "0.21%"
  },
  {
      "Date": "23-Mar-01",
      "Price": "10.386",
      "Open": "10.386",
      "High": "10.386",
      "Low": "10.386",
      "Change %": "-0.51%"
  },
  {
      "Date": "22-Mar-01",
      "Price": "10.439",
      "Open": "10.439",
      "High": "10.439",
      "Low": "10.439",
      "Change %": "0.22%"
  },
  {
      "Date": "21-Mar-01",
      "Price": "10.416",
      "Open": "10.416",
      "High": "10.416",
      "Low": "10.416",
      "Change %": "0.00%"
  },
  {
      "Date": "20-Mar-01",
      "Price": "10.416",
      "Open": "10.416",
      "High": "10.416",
      "Low": "10.416",
      "Change %": "-0.22%"
  },
  {
      "Date": "19-Mar-01",
      "Price": "10.439",
      "Open": "10.439",
      "High": "10.439",
      "Low": "10.439",
      "Change %": "0.14%"
  },
  {
      "Date": "17-Mar-01",
      "Price": "10.424",
      "Open": "10.424",
      "High": "10.424",
      "Low": "10.424",
      "Change %": "-0.15%"
  },
  {
      "Date": "16-Mar-01",
      "Price": "10.44",
      "Open": "10.44",
      "High": "10.44",
      "Low": "10.44",
      "Change %": "0.16%"
  },
  {
      "Date": "15-Mar-01",
      "Price": "10.423",
      "Open": "10.423",
      "High": "10.423",
      "Low": "10.423",
      "Change %": "0.06%"
  },
  {
      "Date": "14-Mar-01",
      "Price": "10.417",
      "Open": "10.417",
      "High": "10.417",
      "Low": "10.417",
      "Change %": "0.66%"
  },
  {
      "Date": "13-Mar-01",
      "Price": "10.349",
      "Open": "10.349",
      "High": "10.349",
      "Low": "10.349",
      "Change %": "1.47%"
  },
  {
      "Date": "12-Mar-01",
      "Price": "10.199",
      "Open": "10.199",
      "High": "10.199",
      "Low": "10.199",
      "Change %": "0.07%"
  },
  {
      "Date": "9-Mar-01",
      "Price": "10.192",
      "Open": "10.192",
      "High": "10.192",
      "Low": "10.192",
      "Change %": "0.36%"
  },
  {
      "Date": "8-Mar-01",
      "Price": "10.155",
      "Open": "10.155",
      "High": "10.155",
      "Low": "10.155",
      "Change %": "0.22%"
  },
  {
      "Date": "7-Mar-01",
      "Price": "10.133",
      "Open": "10.133",
      "High": "10.133",
      "Low": "10.133",
      "Change %": "-1.71%"
  },
  {
      "Date": "5-Mar-01",
      "Price": "10.309",
      "Open": "10.309",
      "High": "10.309",
      "Low": "10.309",
      "Change %": "1.81%"
  },
  {
      "Date": "3-Mar-01",
      "Price": "10.126",
      "Open": "10.126",
      "High": "10.126",
      "Low": "10.126",
      "Change %": "0.66%"
  },
  {
      "Date": "2-Mar-01",
      "Price": "10.06",
      "Open": "10.06",
      "High": "10.06",
      "Low": "10.06",
      "Change %": "0.66%"
  },
  {
      "Date": "1-Mar-01",
      "Price": "9.994",
      "Open": "9.994",
      "High": "9.994",
      "Low": "9.994",
      "Change %": "-0.83%"
  },
  {
      "Date": "28-Feb-01",
      "Price": "10.078",
      "Open": "10.078",
      "High": "10.078",
      "Low": "10.078",
      "Change %": "-1.77%"
  },
  {
      "Date": "27-Feb-01",
      "Price": "10.26",
      "Open": "10.26",
      "High": "10.26",
      "Low": "10.26",
      "Change %": "-0.49%"
  },
  {
      "Date": "26-Feb-01",
      "Price": "10.311",
      "Open": "10.311",
      "High": "10.311",
      "Low": "10.311",
      "Change %": "-0.05%"
  },
  {
      "Date": "24-Feb-01",
      "Price": "10.316",
      "Open": "10.316",
      "High": "10.316",
      "Low": "10.316",
      "Change %": "0.13%"
  },
  {
      "Date": "23-Feb-01",
      "Price": "10.303",
      "Open": "10.303",
      "High": "10.303",
      "Low": "10.303",
      "Change %": "0.20%"
  },
  {
      "Date": "22-Feb-01",
      "Price": "10.282",
      "Open": "10.282",
      "High": "10.282",
      "Low": "10.282",
      "Change %": "0.14%"
  },
  {
      "Date": "20-Feb-01",
      "Price": "10.268",
      "Open": "10.268",
      "High": "10.268",
      "Low": "10.268",
      "Change %": "-0.04%"
  },
  {
      "Date": "17-Feb-01",
      "Price": "10.272",
      "Open": "10.272",
      "High": "10.272",
      "Low": "10.272",
      "Change %": "-0.52%"
  },
  {
      "Date": "16-Feb-01",
      "Price": "10.326",
      "Open": "10.326",
      "High": "10.326",
      "Low": "10.326",
      "Change %": "-1.67%"
  },
  {
      "Date": "15-Feb-01",
      "Price": "10.501",
      "Open": "10.501",
      "High": "10.501",
      "Low": "10.501",
      "Change %": "1.47%"
  },
  {
      "Date": "14-Feb-01",
      "Price": "10.349",
      "Open": "10.349",
      "High": "10.349",
      "Low": "10.349",
      "Change %": "0.27%"
  },
  {
      "Date": "13-Feb-01",
      "Price": "10.321",
      "Open": "10.321",
      "High": "10.321",
      "Low": "10.321",
      "Change %": "0.09%"
  },
  {
      "Date": "12-Feb-01",
      "Price": "10.312",
      "Open": "10.312",
      "High": "10.312",
      "Low": "10.312",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Feb-01",
      "Price": "10.312",
      "Open": "10.312",
      "High": "10.312",
      "Low": "10.312",
      "Change %": "0.14%"
  },
  {
      "Date": "9-Feb-01",
      "Price": "10.298",
      "Open": "10.298",
      "High": "10.298",
      "Low": "10.298",
      "Change %": "-0.37%"
  },
  {
      "Date": "8-Feb-01",
      "Price": "10.336",
      "Open": "10.336",
      "High": "10.336",
      "Low": "10.336",
      "Change %": "-0.94%"
  },
  {
      "Date": "7-Feb-01",
      "Price": "10.434",
      "Open": "10.434",
      "High": "10.434",
      "Low": "10.434",
      "Change %": "-0.73%"
  },
  {
      "Date": "6-Feb-01",
      "Price": "10.511",
      "Open": "10.511",
      "High": "10.511",
      "Low": "10.511",
      "Change %": "-0.55%"
  },
  {
      "Date": "5-Feb-01",
      "Price": "10.569",
      "Open": "10.569",
      "High": "10.569",
      "Low": "10.569",
      "Change %": "1.07%"
  },
  {
      "Date": "3-Feb-01",
      "Price": "10.457",
      "Open": "10.457",
      "High": "10.457",
      "Low": "10.457",
      "Change %": "-0.07%"
  },
  {
      "Date": "2-Feb-01",
      "Price": "10.464",
      "Open": "10.464",
      "High": "10.464",
      "Low": "10.464",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Feb-01",
      "Price": "10.464",
      "Open": "10.464",
      "High": "10.464",
      "Low": "10.464",
      "Change %": "0.38%"
  },
  {
      "Date": "31-Jan-01",
      "Price": "10.424",
      "Open": "10.424",
      "High": "10.424",
      "Low": "10.424",
      "Change %": "-0.54%"
  },
  {
      "Date": "30-Jan-01",
      "Price": "10.481",
      "Open": "10.481",
      "High": "10.481",
      "Low": "10.481",
      "Change %": "-0.47%"
  },
  {
      "Date": "29-Jan-01",
      "Price": "10.53",
      "Open": "10.53",
      "High": "10.53",
      "Low": "10.53",
      "Change %": "0.85%"
  },
  {
      "Date": "27-Jan-01",
      "Price": "10.441",
      "Open": "10.441",
      "High": "10.441",
      "Low": "10.441",
      "Change %": "-0.31%"
  },
  {
      "Date": "25-Jan-01",
      "Price": "10.473",
      "Open": "10.473",
      "High": "10.473",
      "Low": "10.473",
      "Change %": "0.50%"
  },
  {
      "Date": "24-Jan-01",
      "Price": "10.421",
      "Open": "10.421",
      "High": "10.421",
      "Low": "10.421",
      "Change %": "-0.04%"
  },
  {
      "Date": "23-Jan-01",
      "Price": "10.425",
      "Open": "10.425",
      "High": "10.425",
      "Low": "10.425",
      "Change %": "-0.31%"
  },
  {
      "Date": "22-Jan-01",
      "Price": "10.457",
      "Open": "10.457",
      "High": "10.457",
      "Low": "10.457",
      "Change %": "-0.24%"
  },
  {
      "Date": "20-Jan-01",
      "Price": "10.482",
      "Open": "10.482",
      "High": "10.482",
      "Low": "10.482",
      "Change %": "-0.47%"
  },
  {
      "Date": "19-Jan-01",
      "Price": "10.531",
      "Open": "10.531",
      "High": "10.531",
      "Low": "10.531",
      "Change %": "-0.32%"
  },
  {
      "Date": "18-Jan-01",
      "Price": "10.565",
      "Open": "10.565",
      "High": "10.565",
      "Low": "10.565",
      "Change %": "-0.46%"
  },
  {
      "Date": "17-Jan-01",
      "Price": "10.614",
      "Open": "10.614",
      "High": "10.614",
      "Low": "10.614",
      "Change %": "-0.14%"
  },
  {
      "Date": "16-Jan-01",
      "Price": "10.629",
      "Open": "10.629",
      "High": "10.629",
      "Low": "10.629",
      "Change %": "0.12%"
  },
  {
      "Date": "15-Jan-01",
      "Price": "10.616",
      "Open": "10.616",
      "High": "10.616",
      "Low": "10.616",
      "Change %": "-0.16%"
  },
  {
      "Date": "13-Jan-01",
      "Price": "10.633",
      "Open": "10.633",
      "High": "10.633",
      "Low": "10.633",
      "Change %": "-0.18%"
  },
  {
      "Date": "12-Jan-01",
      "Price": "10.652",
      "Open": "10.652",
      "High": "10.652",
      "Low": "10.652",
      "Change %": "-0.44%"
  },
  {
      "Date": "11-Jan-01",
      "Price": "10.699",
      "Open": "10.699",
      "High": "10.699",
      "Low": "10.699",
      "Change %": "0.04%"
  },
  {
      "Date": "10-Jan-01",
      "Price": "10.695",
      "Open": "10.695",
      "High": "10.695",
      "Low": "10.695",
      "Change %": "0.14%"
  },
  {
      "Date": "9-Jan-01",
      "Price": "10.68",
      "Open": "10.68",
      "High": "10.68",
      "Low": "10.68",
      "Change %": "0.17%"
  },
  {
      "Date": "8-Jan-01",
      "Price": "10.662",
      "Open": "10.662",
      "High": "10.662",
      "Low": "10.662",
      "Change %": "-0.19%"
  },
  {
      "Date": "6-Jan-01",
      "Price": "10.682",
      "Open": "10.682",
      "High": "10.682",
      "Low": "10.682",
      "Change %": "0.07%"
  },
  {
      "Date": "5-Jan-01",
      "Price": "10.674",
      "Open": "10.674",
      "High": "10.674",
      "Low": "10.674",
      "Change %": "-0.12%"
  },
  {
      "Date": "4-Jan-01",
      "Price": "10.687",
      "Open": "10.687",
      "High": "10.687",
      "Low": "10.687",
      "Change %": "-1.00%"
  },
  {
      "Date": "3-Jan-01",
      "Price": "10.795",
      "Open": "10.795",
      "High": "10.795",
      "Low": "10.795",
      "Change %": "-0.21%"
  },
  {
      "Date": "2-Jan-01",
      "Price": "10.818",
      "Open": "10.818",
      "High": "10.818",
      "Low": "10.818",
      "Change %": "0.14%"
  },
  {
      "Date": "1-Jan-01",
      "Price": "10.803",
      "Open": "10.803",
      "High": "10.803",
      "Low": "10.803",
      "Change %": "-0.74%"
  },
  {
      "Date": "30-Dec-00",
      "Price": "10.884",
      "Open": "10.884",
      "High": "10.884",
      "Low": "10.884",
      "Change %": "-0.09%"
  },
  {
      "Date": "29-Dec-00",
      "Price": "10.894",
      "Open": "10.894",
      "High": "10.894",
      "Low": "10.894",
      "Change %": "-0.24%"
  },
  {
      "Date": "27-Dec-00",
      "Price": "10.92",
      "Open": "10.92",
      "High": "10.92",
      "Low": "10.92",
      "Change %": "-0.55%"
  },
  {
      "Date": "26-Dec-00",
      "Price": "10.98",
      "Open": "10.98",
      "High": "10.98",
      "Low": "10.98",
      "Change %": "0.13%"
  },
  {
      "Date": "23-Dec-00",
      "Price": "10.966",
      "Open": "10.966",
      "High": "10.966",
      "Low": "10.966",
      "Change %": "-0.05%"
  },
  {
      "Date": "22-Dec-00",
      "Price": "10.971",
      "Open": "10.971",
      "High": "10.971",
      "Low": "10.971",
      "Change %": "-0.25%"
  },
  {
      "Date": "21-Dec-00",
      "Price": "10.998",
      "Open": "10.998",
      "High": "10.998",
      "Low": "10.998",
      "Change %": "0.40%"
  },
  {
      "Date": "20-Dec-00",
      "Price": "10.954",
      "Open": "10.954",
      "High": "10.954",
      "Low": "10.954",
      "Change %": "0.20%"
  },
  {
      "Date": "19-Dec-00",
      "Price": "10.932",
      "Open": "10.932",
      "High": "10.932",
      "Low": "10.932",
      "Change %": "-0.36%"
  },
  {
      "Date": "18-Dec-00",
      "Price": "10.971",
      "Open": "10.971",
      "High": "10.971",
      "Low": "10.971",
      "Change %": "-0.82%"
  },
  {
      "Date": "16-Dec-00",
      "Price": "11.062",
      "Open": "11.062",
      "High": "11.062",
      "Low": "11.062",
      "Change %": "-0.14%"
  },
  {
      "Date": "15-Dec-00",
      "Price": "11.077",
      "Open": "11.077",
      "High": "11.077",
      "Low": "11.077",
      "Change %": "-0.17%"
  },
  {
      "Date": "14-Dec-00",
      "Price": "11.096",
      "Open": "11.096",
      "High": "11.096",
      "Low": "11.096",
      "Change %": "-0.06%"
  },
  {
      "Date": "13-Dec-00",
      "Price": "11.103",
      "Open": "11.103",
      "High": "11.103",
      "Low": "11.103",
      "Change %": "0.05%"
  },
  {
      "Date": "12-Dec-00",
      "Price": "11.098",
      "Open": "11.098",
      "High": "11.098",
      "Low": "11.098",
      "Change %": "0.00%"
  },
  {
      "Date": "11-Dec-00",
      "Price": "11.098",
      "Open": "11.098",
      "High": "11.098",
      "Low": "11.098",
      "Change %": "0.04%"
  },
  {
      "Date": "9-Dec-00",
      "Price": "11.094",
      "Open": "11.094",
      "High": "11.094",
      "Low": "11.094",
      "Change %": "-0.80%"
  },
  {
      "Date": "8-Dec-00",
      "Price": "11.183",
      "Open": "11.183",
      "High": "11.183",
      "Low": "11.183",
      "Change %": "-0.29%"
  },
  {
      "Date": "7-Dec-00",
      "Price": "11.215",
      "Open": "11.215",
      "High": "11.215",
      "Low": "11.215",
      "Change %": "-0.49%"
  },
  {
      "Date": "6-Dec-00",
      "Price": "11.27",
      "Open": "11.27",
      "High": "11.27",
      "Low": "11.27",
      "Change %": "-0.48%"
  },
  {
      "Date": "5-Dec-00",
      "Price": "11.324",
      "Open": "11.324",
      "High": "11.324",
      "Low": "11.324",
      "Change %": "-0.09%"
  },
  {
      "Date": "4-Dec-00",
      "Price": "11.334",
      "Open": "11.334",
      "High": "11.334",
      "Low": "11.334",
      "Change %": "0.02%"
  },
  {
      "Date": "2-Dec-00",
      "Price": "11.332",
      "Open": "11.332",
      "High": "11.332",
      "Low": "11.332",
      "Change %": "0.03%"
  },
  {
      "Date": "1-Dec-00",
      "Price": "11.329",
      "Open": "11.329",
      "High": "11.329",
      "Low": "11.329",
      "Change %": "-0.17%"
  },
  {
      "Date": "30-Nov-00",
      "Price": "11.348",
      "Open": "11.348",
      "High": "11.348",
      "Low": "11.348",
      "Change %": "-0.13%"
  },
  {
      "Date": "29-Nov-00",
      "Price": "11.363",
      "Open": "11.363",
      "High": "11.363",
      "Low": "11.363",
      "Change %": "-0.11%"
  },
  {
      "Date": "28-Nov-00",
      "Price": "11.375",
      "Open": "11.375",
      "High": "11.375",
      "Low": "11.375",
      "Change %": "-0.12%"
  },
  {
      "Date": "27-Nov-00",
      "Price": "11.389",
      "Open": "11.389",
      "High": "11.389",
      "Low": "11.389",
      "Change %": "0.23%"
  },
  {
      "Date": "25-Nov-00",
      "Price": "11.363",
      "Open": "11.363",
      "High": "11.363",
      "Low": "11.363",
      "Change %": "0.08%"
  },
  {
      "Date": "24-Nov-00",
      "Price": "11.354",
      "Open": "11.354",
      "High": "11.354",
      "Low": "11.354",
      "Change %": "0.17%"
  },
  {
      "Date": "23-Nov-00",
      "Price": "11.335",
      "Open": "11.335",
      "High": "11.335",
      "Low": "11.335",
      "Change %": "0.21%"
  },
  {
      "Date": "22-Nov-00",
      "Price": "11.311",
      "Open": "11.311",
      "High": "11.311",
      "Low": "11.311",
      "Change %": "0.12%"
  },
  {
      "Date": "21-Nov-00",
      "Price": "11.297",
      "Open": "11.297",
      "High": "11.297",
      "Low": "11.297",
      "Change %": "0.06%"
  },
  {
      "Date": "20-Nov-00",
      "Price": "11.29",
      "Open": "11.29",
      "High": "11.29",
      "Low": "11.29",
      "Change %": "0.18%"
  },
  {
      "Date": "18-Nov-00",
      "Price": "11.27",
      "Open": "11.27",
      "High": "11.27",
      "Low": "11.27",
      "Change %": "-0.01%"
  },
  {
      "Date": "17-Nov-00",
      "Price": "11.271",
      "Open": "11.271",
      "High": "11.271",
      "Low": "11.271",
      "Change %": "0.34%"
  },
  {
      "Date": "16-Nov-00",
      "Price": "11.233",
      "Open": "11.233",
      "High": "11.233",
      "Low": "11.233",
      "Change %": "-0.16%"
  },
  {
      "Date": "15-Nov-00",
      "Price": "11.251",
      "Open": "11.251",
      "High": "11.251",
      "Low": "11.251",
      "Change %": "-0.11%"
  },
  {
      "Date": "14-Nov-00",
      "Price": "11.263",
      "Open": "11.263",
      "High": "11.263",
      "Low": "11.263",
      "Change %": "0.01%"
  },
  {
      "Date": "13-Nov-00",
      "Price": "11.262",
      "Open": "11.262",
      "High": "11.262",
      "Low": "11.262",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Nov-00",
      "Price": "11.262",
      "Open": "11.262",
      "High": "11.262",
      "Low": "11.262",
      "Change %": "-0.06%"
  },
  {
      "Date": "9-Nov-00",
      "Price": "11.269",
      "Open": "11.269",
      "High": "11.269",
      "Low": "11.269",
      "Change %": "0.08%"
  },
  {
      "Date": "8-Nov-00",
      "Price": "11.26",
      "Open": "11.26",
      "High": "11.26",
      "Low": "11.26",
      "Change %": "-0.19%"
  },
  {
      "Date": "7-Nov-00",
      "Price": "11.282",
      "Open": "11.282",
      "High": "11.282",
      "Low": "11.282",
      "Change %": "-0.77%"
  },
  {
      "Date": "6-Nov-00",
      "Price": "11.37",
      "Open": "11.37",
      "High": "11.37",
      "Low": "11.37",
      "Change %": "-0.43%"
  },
  {
      "Date": "4-Nov-00",
      "Price": "11.419",
      "Open": "11.419",
      "High": "11.419",
      "Low": "11.419",
      "Change %": "-0.08%"
  },
  {
      "Date": "3-Nov-00",
      "Price": "11.428",
      "Open": "11.428",
      "High": "11.428",
      "Low": "11.428",
      "Change %": "-0.94%"
  },
  {
      "Date": "2-Nov-00",
      "Price": "11.537",
      "Open": "11.537",
      "High": "11.537",
      "Low": "11.537",
      "Change %": "-0.44%"
  },
  {
      "Date": "1-Nov-00",
      "Price": "11.588",
      "Open": "11.588",
      "High": "11.588",
      "Low": "11.588",
      "Change %": "-0.10%"
  },
  {
      "Date": "31-Oct-00",
      "Price": "11.6",
      "Open": "11.6",
      "High": "11.6",
      "Low": "11.6",
      "Change %": "-0.03%"
  },
  {
      "Date": "30-Oct-00",
      "Price": "11.604",
      "Open": "11.604",
      "High": "11.604",
      "Low": "11.604",
      "Change %": "-0.16%"
  },
  {
      "Date": "27-Oct-00",
      "Price": "11.623",
      "Open": "11.623",
      "High": "11.623",
      "Low": "11.623",
      "Change %": "0.10%"
  },
  {
      "Date": "25-Oct-00",
      "Price": "11.611",
      "Open": "11.611",
      "High": "11.611",
      "Low": "11.611",
      "Change %": "-0.17%"
  },
  {
      "Date": "24-Oct-00",
      "Price": "11.631",
      "Open": "11.631",
      "High": "11.631",
      "Low": "11.631",
      "Change %": "-0.14%"
  },
  {
      "Date": "23-Oct-00",
      "Price": "11.647",
      "Open": "11.647",
      "High": "11.647",
      "Low": "11.647",
      "Change %": "-0.01%"
  },
  {
      "Date": "21-Oct-00",
      "Price": "11.648",
      "Open": "11.648",
      "High": "11.648",
      "Low": "11.648",
      "Change %": "-0.03%"
  },
  {
      "Date": "20-Oct-00",
      "Price": "11.652",
      "Open": "11.652",
      "High": "11.652",
      "Low": "11.652",
      "Change %": "-0.10%"
  },
  {
      "Date": "19-Oct-00",
      "Price": "11.664",
      "Open": "11.664",
      "High": "11.664",
      "Low": "11.664",
      "Change %": "-0.02%"
  },
  {
      "Date": "18-Oct-00",
      "Price": "11.666",
      "Open": "11.666",
      "High": "11.666",
      "Low": "11.666",
      "Change %": "0.03%"
  },
  {
      "Date": "17-Oct-00",
      "Price": "11.662",
      "Open": "11.662",
      "High": "11.662",
      "Low": "11.662",
      "Change %": "-0.16%"
  },
  {
      "Date": "16-Oct-00",
      "Price": "11.681",
      "Open": "11.681",
      "High": "11.681",
      "Low": "11.681",
      "Change %": "-0.16%"
  },
  {
      "Date": "14-Oct-00",
      "Price": "11.7",
      "Open": "11.7",
      "High": "11.7",
      "Low": "11.7",
      "Change %": "-0.15%"
  },
  {
      "Date": "13-Oct-00",
      "Price": "11.717",
      "Open": "11.717",
      "High": "11.717",
      "Low": "11.717",
      "Change %": "0.58%"
  },
  {
      "Date": "12-Oct-00",
      "Price": "11.649",
      "Open": "11.649",
      "High": "11.649",
      "Low": "11.649",
      "Change %": "-0.22%"
  },
  {
      "Date": "11-Oct-00",
      "Price": "11.675",
      "Open": "11.675",
      "High": "11.675",
      "Low": "11.675",
      "Change %": "0.59%"
  },
  {
      "Date": "10-Oct-00",
      "Price": "11.607",
      "Open": "11.607",
      "High": "11.607",
      "Low": "11.607",
      "Change %": "0.15%"
  },
  {
      "Date": "9-Oct-00",
      "Price": "11.59",
      "Open": "11.59",
      "High": "11.59",
      "Low": "11.59",
      "Change %": "-0.08%"
  },
  {
      "Date": "6-Oct-00",
      "Price": "11.599",
      "Open": "11.599",
      "High": "11.599",
      "Low": "11.599",
      "Change %": "-0.36%"
  },
  {
      "Date": "5-Oct-00",
      "Price": "11.641",
      "Open": "11.641",
      "High": "11.641",
      "Low": "11.641",
      "Change %": "0.66%"
  },
  {
      "Date": "4-Oct-00",
      "Price": "11.565",
      "Open": "11.565",
      "High": "11.565",
      "Low": "11.565",
      "Change %": "0.15%"
  },
  {
      "Date": "3-Oct-00",
      "Price": "11.548",
      "Open": "11.548",
      "High": "11.548",
      "Low": "11.548",
      "Change %": "0.07%"
  },
  {
      "Date": "29-Sep-00",
      "Price": "11.54",
      "Open": "11.54",
      "High": "11.54",
      "Low": "11.54",
      "Change %": "0.00%"
  },
  {
      "Date": "28-Sep-00",
      "Price": "11.54",
      "Open": "11.54",
      "High": "11.54",
      "Low": "11.54",
      "Change %": "-0.08%"
  },
  {
      "Date": "27-Sep-00",
      "Price": "11.549",
      "Open": "11.549",
      "High": "11.549",
      "Low": "11.549",
      "Change %": "0.36%"
  },
  {
      "Date": "26-Sep-00",
      "Price": "11.507",
      "Open": "11.507",
      "High": "11.507",
      "Low": "11.507",
      "Change %": "0.00%"
  },
  {
      "Date": "25-Sep-00",
      "Price": "11.507",
      "Open": "11.507",
      "High": "11.507",
      "Low": "11.507",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Sep-00",
      "Price": "11.507",
      "Open": "11.507",
      "High": "11.507",
      "Low": "11.507",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Sep-00",
      "Price": "11.507",
      "Open": "11.507",
      "High": "11.507",
      "Low": "11.507",
      "Change %": "-0.37%"
  },
  {
      "Date": "21-Sep-00",
      "Price": "11.55",
      "Open": "11.55",
      "High": "11.55",
      "Low": "11.55",
      "Change %": "-0.80%"
  },
  {
      "Date": "20-Sep-00",
      "Price": "11.643",
      "Open": "11.643",
      "High": "11.643",
      "Low": "11.643",
      "Change %": "-0.29%"
  },
  {
      "Date": "19-Sep-00",
      "Price": "11.677",
      "Open": "11.677",
      "High": "11.677",
      "Low": "11.677",
      "Change %": "0.29%"
  },
  {
      "Date": "18-Sep-00",
      "Price": "11.643",
      "Open": "11.643",
      "High": "11.643",
      "Low": "11.643",
      "Change %": "0.44%"
  },
  {
      "Date": "16-Sep-00",
      "Price": "11.592",
      "Open": "11.592",
      "High": "11.592",
      "Low": "11.592",
      "Change %": "0.22%"
  },
  {
      "Date": "15-Sep-00",
      "Price": "11.567",
      "Open": "11.567",
      "High": "11.567",
      "Low": "11.567",
      "Change %": "0.58%"
  },
  {
      "Date": "14-Sep-00",
      "Price": "11.5",
      "Open": "11.5",
      "High": "11.5",
      "Low": "11.5",
      "Change %": "0.14%"
  },
  {
      "Date": "13-Sep-00",
      "Price": "11.484",
      "Open": "11.484",
      "High": "11.484",
      "Low": "11.484",
      "Change %": "0.17%"
  },
  {
      "Date": "12-Sep-00",
      "Price": "11.464",
      "Open": "11.464",
      "High": "11.464",
      "Low": "11.464",
      "Change %": "-0.17%"
  },
  {
      "Date": "11-Sep-00",
      "Price": "11.484",
      "Open": "11.484",
      "High": "11.484",
      "Low": "11.484",
      "Change %": "-0.11%"
  },
  {
      "Date": "9-Sep-00",
      "Price": "11.497",
      "Open": "11.497",
      "High": "11.497",
      "Low": "11.497",
      "Change %": "0.03%"
  },
  {
      "Date": "8-Sep-00",
      "Price": "11.493",
      "Open": "11.493",
      "High": "11.493",
      "Low": "11.493",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Sep-00",
      "Price": "11.493",
      "Open": "11.493",
      "High": "11.493",
      "Low": "11.493",
      "Change %": "-0.24%"
  },
  {
      "Date": "6-Sep-00",
      "Price": "11.521",
      "Open": "11.521",
      "High": "11.521",
      "Low": "11.521",
      "Change %": "0.17%"
  },
  {
      "Date": "5-Sep-00",
      "Price": "11.501",
      "Open": "11.501",
      "High": "11.501",
      "Low": "11.501",
      "Change %": "0.29%"
  },
  {
      "Date": "4-Sep-00",
      "Price": "11.468",
      "Open": "11.468",
      "High": "11.468",
      "Low": "11.468",
      "Change %": "-0.22%"
  },
  {
      "Date": "2-Sep-00",
      "Price": "11.493",
      "Open": "11.493",
      "High": "11.493",
      "Low": "11.493",
      "Change %": "-0.10%"
  },
  {
      "Date": "31-Aug-00",
      "Price": "11.505",
      "Open": "11.505",
      "High": "11.505",
      "Low": "11.505",
      "Change %": "0.10%"
  },
  {
      "Date": "30-Aug-00",
      "Price": "11.494",
      "Open": "11.494",
      "High": "11.494",
      "Low": "11.494",
      "Change %": "0.29%"
  },
  {
      "Date": "29-Aug-00",
      "Price": "11.461",
      "Open": "11.461",
      "High": "11.461",
      "Low": "11.461",
      "Change %": "-0.07%"
  },
  {
      "Date": "28-Aug-00",
      "Price": "11.469",
      "Open": "11.469",
      "High": "11.469",
      "Low": "11.469",
      "Change %": "-0.08%"
  },
  {
      "Date": "26-Aug-00",
      "Price": "11.478",
      "Open": "11.478",
      "High": "11.478",
      "Low": "11.478",
      "Change %": "0.00%"
  },
  {
      "Date": "25-Aug-00",
      "Price": "11.478",
      "Open": "11.478",
      "High": "11.478",
      "Low": "11.478",
      "Change %": "-0.22%"
  },
  {
      "Date": "24-Aug-00",
      "Price": "11.503",
      "Open": "11.503",
      "High": "11.503",
      "Low": "11.503",
      "Change %": "-0.15%"
  },
  {
      "Date": "23-Aug-00",
      "Price": "11.52",
      "Open": "11.52",
      "High": "11.52",
      "Low": "11.52",
      "Change %": "0.14%"
  },
  {
      "Date": "22-Aug-00",
      "Price": "11.504",
      "Open": "11.504",
      "High": "11.504",
      "Low": "11.504",
      "Change %": "0.44%"
  },
  {
      "Date": "19-Aug-00",
      "Price": "11.454",
      "Open": "11.454",
      "High": "11.454",
      "Low": "11.454",
      "Change %": "-0.37%"
  },
  {
      "Date": "18-Aug-00",
      "Price": "11.496",
      "Open": "11.496",
      "High": "11.496",
      "Low": "11.496",
      "Change %": "0.14%"
  },
  {
      "Date": "17-Aug-00",
      "Price": "11.48",
      "Open": "11.48",
      "High": "11.48",
      "Low": "11.48",
      "Change %": "0.37%"
  },
  {
      "Date": "16-Aug-00",
      "Price": "11.438",
      "Open": "11.438",
      "High": "11.438",
      "Low": "11.438",
      "Change %": "0.28%"
  },
  {
      "Date": "14-Aug-00",
      "Price": "11.406",
      "Open": "11.406",
      "High": "11.406",
      "Low": "11.406",
      "Change %": "-1.43%"
  },
  {
      "Date": "12-Aug-00",
      "Price": "11.572",
      "Open": "11.572",
      "High": "11.572",
      "Low": "11.572",
      "Change %": "0.21%"
  },
  {
      "Date": "11-Aug-00",
      "Price": "11.548",
      "Open": "11.548",
      "High": "11.548",
      "Low": "11.548",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Aug-00",
      "Price": "11.548",
      "Open": "11.548",
      "High": "11.548",
      "Low": "11.548",
      "Change %": "0.50%"
  },
  {
      "Date": "9-Aug-00",
      "Price": "11.49",
      "Open": "11.49",
      "High": "11.49",
      "Low": "11.49",
      "Change %": "-0.14%"
  },
  {
      "Date": "8-Aug-00",
      "Price": "11.506",
      "Open": "11.506",
      "High": "11.506",
      "Low": "11.506",
      "Change %": "0.07%"
  },
  {
      "Date": "7-Aug-00",
      "Price": "11.498",
      "Open": "11.498",
      "High": "11.498",
      "Low": "11.498",
      "Change %": "0.29%"
  },
  {
      "Date": "5-Aug-00",
      "Price": "11.465",
      "Open": "11.465",
      "High": "11.465",
      "Low": "11.465",
      "Change %": "0.21%"
  },
  {
      "Date": "4-Aug-00",
      "Price": "11.441",
      "Open": "11.441",
      "High": "11.441",
      "Low": "11.441",
      "Change %": "0.58%"
  },
  {
      "Date": "3-Aug-00",
      "Price": "11.375",
      "Open": "11.375",
      "High": "11.375",
      "Low": "11.375",
      "Change %": "0.07%"
  },
  {
      "Date": "2-Aug-00",
      "Price": "11.367",
      "Open": "11.367",
      "High": "11.367",
      "Low": "11.367",
      "Change %": "0.07%"
  },
  {
      "Date": "1-Aug-00",
      "Price": "11.359",
      "Open": "11.359",
      "High": "11.359",
      "Low": "11.359",
      "Change %": "0.04%"
  },
  {
      "Date": "31-Jul-00",
      "Price": "11.354",
      "Open": "11.354",
      "High": "11.354",
      "Low": "11.354",
      "Change %": "0.44%"
  },
  {
      "Date": "29-Jul-00",
      "Price": "11.304",
      "Open": "11.304",
      "High": "11.304",
      "Low": "11.304",
      "Change %": "-0.05%"
  },
  {
      "Date": "28-Jul-00",
      "Price": "11.31",
      "Open": "11.31",
      "High": "11.31",
      "Low": "11.31",
      "Change %": "0.14%"
  },
  {
      "Date": "27-Jul-00",
      "Price": "11.294",
      "Open": "11.294",
      "High": "11.294",
      "Low": "11.294",
      "Change %": "-0.08%"
  },
  {
      "Date": "26-Jul-00",
      "Price": "11.303",
      "Open": "11.303",
      "High": "11.303",
      "Low": "11.303",
      "Change %": "-0.94%"
  },
  {
      "Date": "25-Jul-00",
      "Price": "11.41",
      "Open": "11.41",
      "High": "11.41",
      "Low": "11.41",
      "Change %": "-0.79%"
  },
  {
      "Date": "24-Jul-00",
      "Price": "11.501",
      "Open": "11.501",
      "High": "11.501",
      "Low": "11.501",
      "Change %": "1.09%"
  },
  {
      "Date": "22-Jul-00",
      "Price": "11.377",
      "Open": "11.377",
      "High": "11.377",
      "Low": "11.377",
      "Change %": "-0.87%"
  },
  {
      "Date": "21-Jul-00",
      "Price": "11.477",
      "Open": "11.477",
      "High": "11.477",
      "Low": "11.477",
      "Change %": "5.12%"
  },
  {
      "Date": "20-Jul-00",
      "Price": "10.918",
      "Open": "10.918",
      "High": "10.918",
      "Low": "10.918",
      "Change %": "0.15%"
  },
  {
      "Date": "19-Jul-00",
      "Price": "10.902",
      "Open": "10.902",
      "High": "10.902",
      "Low": "10.902",
      "Change %": "0.14%"
  },
  {
      "Date": "18-Jul-00",
      "Price": "10.887",
      "Open": "10.887",
      "High": "10.887",
      "Low": "10.887",
      "Change %": "-0.37%"
  },
  {
      "Date": "17-Jul-00",
      "Price": "10.927",
      "Open": "10.927",
      "High": "10.927",
      "Low": "10.927",
      "Change %": "-0.15%"
  },
  {
      "Date": "15-Jul-00",
      "Price": "10.943",
      "Open": "10.943",
      "High": "10.943",
      "Low": "10.943",
      "Change %": "-0.01%"
  },
  {
      "Date": "14-Jul-00",
      "Price": "10.944",
      "Open": "10.944",
      "High": "10.944",
      "Low": "10.944",
      "Change %": "-0.22%"
  },
  {
      "Date": "12-Jul-00",
      "Price": "10.968",
      "Open": "10.968",
      "High": "10.968",
      "Low": "10.968",
      "Change %": "0.07%"
  },
  {
      "Date": "11-Jul-00",
      "Price": "10.96",
      "Open": "10.96",
      "High": "10.96",
      "Low": "10.96",
      "Change %": "-0.01%"
  },
  {
      "Date": "10-Jul-00",
      "Price": "10.961",
      "Open": "10.961",
      "High": "10.961",
      "Low": "10.961",
      "Change %": "-0.15%"
  },
  {
      "Date": "8-Jul-00",
      "Price": "10.977",
      "Open": "10.977",
      "High": "10.977",
      "Low": "10.977",
      "Change %": "-0.01%"
  },
  {
      "Date": "7-Jul-00",
      "Price": "10.978",
      "Open": "10.978",
      "High": "10.978",
      "Low": "10.978",
      "Change %": "0.00%"
  },
  {
      "Date": "6-Jul-00",
      "Price": "10.978",
      "Open": "10.978",
      "High": "10.978",
      "Low": "10.978",
      "Change %": "0.15%"
  },
  {
      "Date": "5-Jul-00",
      "Price": "10.962",
      "Open": "10.962",
      "High": "10.962",
      "Low": "10.962",
      "Change %": "-0.08%"
  },
  {
      "Date": "4-Jul-00",
      "Price": "10.971",
      "Open": "10.971",
      "High": "10.971",
      "Low": "10.971",
      "Change %": "-0.65%"
  },
  {
      "Date": "3-Jul-00",
      "Price": "11.043",
      "Open": "11.043",
      "High": "11.043",
      "Low": "11.043",
      "Change %": "-0.03%"
  },
  {
      "Date": "1-Jul-00",
      "Price": "11.046",
      "Open": "11.046",
      "High": "11.046",
      "Low": "11.046",
      "Change %": "-0.12%"
  },
  {
      "Date": "30-Jun-00",
      "Price": "11.059",
      "Open": "11.059",
      "High": "11.059",
      "Low": "11.059",
      "Change %": "0.00%"
  },
  {
      "Date": "29-Jun-00",
      "Price": "11.059",
      "Open": "11.059",
      "High": "11.059",
      "Low": "11.059",
      "Change %": "-0.07%"
  },
  {
      "Date": "28-Jun-00",
      "Price": "11.067",
      "Open": "11.067",
      "High": "11.067",
      "Low": "11.067",
      "Change %": "-0.07%"
  },
  {
      "Date": "27-Jun-00",
      "Price": "11.075",
      "Open": "11.075",
      "High": "11.075",
      "Low": "11.075",
      "Change %": "0.07%"
  },
  {
      "Date": "26-Jun-00",
      "Price": "11.067",
      "Open": "11.067",
      "High": "11.067",
      "Low": "11.067",
      "Change %": "0.03%"
  },
  {
      "Date": "24-Jun-00",
      "Price": "11.064",
      "Open": "11.064",
      "High": "11.064",
      "Low": "11.064",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Jun-00",
      "Price": "11.064",
      "Open": "11.064",
      "High": "11.064",
      "Low": "11.064",
      "Change %": "0.07%"
  },
  {
      "Date": "22-Jun-00",
      "Price": "11.056",
      "Open": "11.056",
      "High": "11.056",
      "Low": "11.056",
      "Change %": "-0.17%"
  },
  {
      "Date": "21-Jun-00",
      "Price": "11.075",
      "Open": "11.075",
      "High": "11.075",
      "Low": "11.075",
      "Change %": "-0.14%"
  },
  {
      "Date": "20-Jun-00",
      "Price": "11.091",
      "Open": "11.091",
      "High": "11.091",
      "Low": "11.091",
      "Change %": "-0.14%"
  },
  {
      "Date": "19-Jun-00",
      "Price": "11.107",
      "Open": "11.107",
      "High": "11.107",
      "Low": "11.107",
      "Change %": "0.00%"
  },
  {
      "Date": "17-Jun-00",
      "Price": "11.107",
      "Open": "11.107",
      "High": "11.107",
      "Low": "11.107",
      "Change %": "-0.37%"
  },
  {
      "Date": "16-Jun-00",
      "Price": "11.148",
      "Open": "11.148",
      "High": "11.148",
      "Low": "11.148",
      "Change %": "0.07%"
  },
  {
      "Date": "14-Jun-00",
      "Price": "11.14",
      "Open": "11.14",
      "High": "11.14",
      "Low": "11.14",
      "Change %": "0.14%"
  },
  {
      "Date": "13-Jun-00",
      "Price": "11.124",
      "Open": "11.124",
      "High": "11.124",
      "Low": "11.124",
      "Change %": "0.29%"
  },
  {
      "Date": "12-Jun-00",
      "Price": "11.092",
      "Open": "11.092",
      "High": "11.092",
      "Low": "11.092",
      "Change %": "-0.14%"
  },
  {
      "Date": "10-Jun-00",
      "Price": "11.108",
      "Open": "11.108",
      "High": "11.108",
      "Low": "11.108",
      "Change %": "-0.14%"
  },
  {
      "Date": "9-Jun-00",
      "Price": "11.124",
      "Open": "11.124",
      "High": "11.124",
      "Low": "11.124",
      "Change %": "0.65%"
  },
  {
      "Date": "8-Jun-00",
      "Price": "11.052",
      "Open": "11.052",
      "High": "11.052",
      "Low": "11.052",
      "Change %": "0.36%"
  },
  {
      "Date": "7-Jun-00",
      "Price": "11.012",
      "Open": "11.012",
      "High": "11.012",
      "Low": "11.012",
      "Change %": "1.30%"
  },
  {
      "Date": "6-Jun-00",
      "Price": "10.871",
      "Open": "10.871",
      "High": "10.871",
      "Low": "10.871",
      "Change %": "0.30%"
  },
  {
      "Date": "5-Jun-00",
      "Price": "10.839",
      "Open": "10.839",
      "High": "10.839",
      "Low": "10.839",
      "Change %": "0.00%"
  },
  {
      "Date": "3-Jun-00",
      "Price": "10.839",
      "Open": "10.839",
      "High": "10.839",
      "Low": "10.839",
      "Change %": "0.35%"
  },
  {
      "Date": "2-Jun-00",
      "Price": "10.801",
      "Open": "10.801",
      "High": "10.801",
      "Low": "10.801",
      "Change %": "0.07%"
  },
  {
      "Date": "1-Jun-00",
      "Price": "10.793",
      "Open": "10.793",
      "High": "10.793",
      "Low": "10.793",
      "Change %": "-0.31%"
  },
  {
      "Date": "31-May-00",
      "Price": "10.827",
      "Open": "10.827",
      "High": "10.827",
      "Low": "10.827",
      "Change %": "-0.48%"
  },
  {
      "Date": "30-May-00",
      "Price": "10.879",
      "Open": "10.879",
      "High": "10.879",
      "Low": "10.879",
      "Change %": "0.14%"
  },
  {
      "Date": "29-May-00",
      "Price": "10.864",
      "Open": "10.864",
      "High": "10.864",
      "Low": "10.864",
      "Change %": "-0.31%"
  },
  {
      "Date": "27-May-00",
      "Price": "10.898",
      "Open": "10.898",
      "High": "10.898",
      "Low": "10.898",
      "Change %": "0.03%"
  },
  {
      "Date": "26-May-00",
      "Price": "10.895",
      "Open": "10.895",
      "High": "10.895",
      "Low": "10.895",
      "Change %": "1.09%"
  },
  {
      "Date": "25-May-00",
      "Price": "10.778",
      "Open": "10.778",
      "High": "10.778",
      "Low": "10.778",
      "Change %": "0.86%"
  },
  {
      "Date": "24-May-00",
      "Price": "10.686",
      "Open": "10.686",
      "High": "10.686",
      "Low": "10.686",
      "Change %": "1.09%"
  },
  {
      "Date": "23-May-00",
      "Price": "10.571",
      "Open": "10.571",
      "High": "10.571",
      "Low": "10.571",
      "Change %": "0.14%"
  },
  {
      "Date": "22-May-00",
      "Price": "10.556",
      "Open": "10.556",
      "High": "10.556",
      "Low": "10.556",
      "Change %": "0.14%"
  },
  {
      "Date": "20-May-00",
      "Price": "10.541",
      "Open": "10.541",
      "High": "10.541",
      "Low": "10.541",
      "Change %": "0.00%"
  },
  {
      "Date": "19-May-00",
      "Price": "10.541",
      "Open": "10.541",
      "High": "10.541",
      "Low": "10.541",
      "Change %": "0.14%"
  },
  {
      "Date": "17-May-00",
      "Price": "10.526",
      "Open": "10.526",
      "High": "10.526",
      "Low": "10.526",
      "Change %": "-0.01%"
  },
  {
      "Date": "16-May-00",
      "Price": "10.527",
      "Open": "10.527",
      "High": "10.527",
      "Low": "10.527",
      "Change %": "0.03%"
  },
  {
      "Date": "15-May-00",
      "Price": "10.524",
      "Open": "10.524",
      "High": "10.524",
      "Low": "10.524",
      "Change %": "0.05%"
  },
  {
      "Date": "13-May-00",
      "Price": "10.519",
      "Open": "10.519",
      "High": "10.519",
      "Low": "10.519",
      "Change %": "-0.01%"
  },
  {
      "Date": "12-May-00",
      "Price": "10.52",
      "Open": "10.52",
      "High": "10.52",
      "Low": "10.52",
      "Change %": "-0.07%"
  },
  {
      "Date": "11-May-00",
      "Price": "10.527",
      "Open": "10.527",
      "High": "10.527",
      "Low": "10.527",
      "Change %": "-0.08%"
  },
  {
      "Date": "10-May-00",
      "Price": "10.535",
      "Open": "10.535",
      "High": "10.535",
      "Low": "10.535",
      "Change %": "0.20%"
  },
  {
      "Date": "9-May-00",
      "Price": "10.514",
      "Open": "10.514",
      "High": "10.514",
      "Low": "10.514",
      "Change %": "0.09%"
  },
  {
      "Date": "8-May-00",
      "Price": "10.505",
      "Open": "10.505",
      "High": "10.505",
      "Low": "10.505",
      "Change %": "0.93%"
  },
  {
      "Date": "6-May-00",
      "Price": "10.408",
      "Open": "10.408",
      "High": "10.408",
      "Low": "10.408",
      "Change %": "-0.23%"
  },
  {
      "Date": "5-May-00",
      "Price": "10.432",
      "Open": "10.432",
      "High": "10.432",
      "Low": "10.432",
      "Change %": "-0.59%"
  },
  {
      "Date": "4-May-00",
      "Price": "10.494",
      "Open": "10.494",
      "High": "10.494",
      "Low": "10.494",
      "Change %": "0.56%"
  },
  {
      "Date": "3-May-00",
      "Price": "10.436",
      "Open": "10.436",
      "High": "10.436",
      "Low": "10.436",
      "Change %": "0.25%"
  },
  {
      "Date": "2-May-00",
      "Price": "10.41",
      "Open": "10.41",
      "High": "10.41",
      "Low": "10.41",
      "Change %": "0.44%"
  },
  {
      "Date": "29-Apr-00",
      "Price": "10.364",
      "Open": "10.364",
      "High": "10.364",
      "Low": "10.364",
      "Change %": "0.21%"
  },
  {
      "Date": "28-Apr-00",
      "Price": "10.342",
      "Open": "10.342",
      "High": "10.342",
      "Low": "10.342",
      "Change %": "-0.02%"
  },
  {
      "Date": "27-Apr-00",
      "Price": "10.344",
      "Open": "10.344",
      "High": "10.344",
      "Low": "10.344",
      "Change %": "0.01%"
  },
  {
      "Date": "26-Apr-00",
      "Price": "10.343",
      "Open": "10.343",
      "High": "10.343",
      "Low": "10.343",
      "Change %": "-0.03%"
  },
  {
      "Date": "25-Apr-00",
      "Price": "10.346",
      "Open": "10.346",
      "High": "10.346",
      "Low": "10.346",
      "Change %": "0.10%"
  },
  {
      "Date": "24-Apr-00",
      "Price": "10.336",
      "Open": "10.336",
      "High": "10.336",
      "Low": "10.336",
      "Change %": "0.22%"
  },
  {
      "Date": "22-Apr-00",
      "Price": "10.313",
      "Open": "10.313",
      "High": "10.313",
      "Low": "10.313",
      "Change %": "-0.01%"
  },
  {
      "Date": "20-Apr-00",
      "Price": "10.314",
      "Open": "10.314",
      "High": "10.314",
      "Low": "10.314",
      "Change %": "-0.01%"
  },
  {
      "Date": "19-Apr-00",
      "Price": "10.315",
      "Open": "10.315",
      "High": "10.315",
      "Low": "10.315",
      "Change %": "0.00%"
  },
  {
      "Date": "18-Apr-00",
      "Price": "10.315",
      "Open": "10.315",
      "High": "10.315",
      "Low": "10.315",
      "Change %": "0.22%"
  },
  {
      "Date": "17-Apr-00",
      "Price": "10.292",
      "Open": "10.292",
      "High": "10.292",
      "Low": "10.292",
      "Change %": "0.20%"
  },
  {
      "Date": "13-Apr-00",
      "Price": "10.271",
      "Open": "10.271",
      "High": "10.271",
      "Low": "10.271",
      "Change %": "0.14%"
  },
  {
      "Date": "11-Apr-00",
      "Price": "10.257",
      "Open": "10.257",
      "High": "10.257",
      "Low": "10.257",
      "Change %": "-0.51%"
  },
  {
      "Date": "10-Apr-00",
      "Price": "10.31",
      "Open": "10.31",
      "High": "10.31",
      "Low": "10.31",
      "Change %": "-0.73%"
  },
  {
      "Date": "8-Apr-00",
      "Price": "10.386",
      "Open": "10.386",
      "High": "10.386",
      "Low": "10.386",
      "Change %": "-0.59%"
  },
  {
      "Date": "7-Apr-00",
      "Price": "10.448",
      "Open": "10.448",
      "High": "10.448",
      "Low": "10.448",
      "Change %": "-0.23%"
  },
  {
      "Date": "6-Apr-00",
      "Price": "10.472",
      "Open": "10.472",
      "High": "10.472",
      "Low": "10.472",
      "Change %": "0.22%"
  },
  {
      "Date": "4-Apr-00",
      "Price": "10.449",
      "Open": "10.449",
      "High": "10.449",
      "Low": "10.449",
      "Change %": "-0.08%"
  },
  {
      "Date": "3-Apr-00",
      "Price": "10.457",
      "Open": "10.457",
      "High": "10.457",
      "Low": "10.457",
      "Change %": "-2.78%"
  },
  {
      "Date": "31-Mar-00",
      "Price": "10.756",
      "Open": "10.756",
      "High": "10.756",
      "Low": "10.756",
      "Change %": "-1.43%"
  },
  {
      "Date": "30-Mar-00",
      "Price": "10.912",
      "Open": "10.912",
      "High": "10.912",
      "Low": "10.912",
      "Change %": "-0.29%"
  },
  {
      "Date": "29-Mar-00",
      "Price": "10.944",
      "Open": "10.944",
      "High": "10.944",
      "Low": "10.944",
      "Change %": "-1.08%"
  },
  {
      "Date": "28-Mar-00",
      "Price": "11.064",
      "Open": "11.064",
      "High": "11.064",
      "Low": "11.064",
      "Change %": "0.44%"
  },
  {
      "Date": "27-Mar-00",
      "Price": "11.016",
      "Open": "11.016",
      "High": "11.016",
      "Low": "11.016",
      "Change %": "1.09%"
  },
  {
      "Date": "25-Mar-00",
      "Price": "10.897",
      "Open": "10.897",
      "High": "10.897",
      "Low": "10.897",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Mar-00",
      "Price": "10.897",
      "Open": "10.897",
      "High": "10.897",
      "Low": "10.897",
      "Change %": "0.06%"
  },
  {
      "Date": "23-Mar-00",
      "Price": "10.89",
      "Open": "10.89",
      "High": "10.89",
      "Low": "10.89",
      "Change %": "0.44%"
  },
  {
      "Date": "22-Mar-00",
      "Price": "10.842",
      "Open": "10.842",
      "High": "10.842",
      "Low": "10.842",
      "Change %": "0.14%"
  },
  {
      "Date": "21-Mar-00",
      "Price": "10.827",
      "Open": "10.827",
      "High": "10.827",
      "Low": "10.827",
      "Change %": "0.51%"
  },
  {
      "Date": "18-Mar-00",
      "Price": "10.772",
      "Open": "10.772",
      "High": "10.772",
      "Low": "10.772",
      "Change %": "-0.08%"
  },
  {
      "Date": "16-Mar-00",
      "Price": "10.781",
      "Open": "10.781",
      "High": "10.781",
      "Low": "10.781",
      "Change %": "-0.15%"
  },
  {
      "Date": "15-Mar-00",
      "Price": "10.797",
      "Open": "10.797",
      "High": "10.797",
      "Low": "10.797",
      "Change %": "-0.07%"
  },
  {
      "Date": "14-Mar-00",
      "Price": "10.805",
      "Open": "10.805",
      "High": "10.805",
      "Low": "10.805",
      "Change %": "0.73%"
  },
  {
      "Date": "13-Mar-00",
      "Price": "10.727",
      "Open": "10.727",
      "High": "10.727",
      "Low": "10.727",
      "Change %": "0.87%"
  },
  {
      "Date": "11-Mar-00",
      "Price": "10.634",
      "Open": "10.634",
      "High": "10.634",
      "Low": "10.634",
      "Change %": "-0.15%"
  },
  {
      "Date": "10-Mar-00",
      "Price": "10.65",
      "Open": "10.65",
      "High": "10.65",
      "Low": "10.65",
      "Change %": "-0.08%"
  },
  {
      "Date": "9-Mar-00",
      "Price": "10.658",
      "Open": "10.658",
      "High": "10.658",
      "Low": "10.658",
      "Change %": "-1.02%"
  },
  {
      "Date": "8-Mar-00",
      "Price": "10.768",
      "Open": "10.768",
      "High": "10.768",
      "Low": "10.768",
      "Change %": "-0.43%"
  },
  {
      "Date": "7-Mar-00",
      "Price": "10.815",
      "Open": "10.815",
      "High": "10.815",
      "Low": "10.815",
      "Change %": "-0.15%"
  },
  {
      "Date": "6-Mar-00",
      "Price": "10.831",
      "Open": "10.831",
      "High": "10.831",
      "Low": "10.831",
      "Change %": "0.64%"
  },
  {
      "Date": "3-Mar-00",
      "Price": "10.762",
      "Open": "10.762",
      "High": "10.762",
      "Low": "10.762",
      "Change %": "1.02%"
  },
  {
      "Date": "2-Mar-00",
      "Price": "10.653",
      "Open": "10.653",
      "High": "10.653",
      "Low": "10.653",
      "Change %": "1.99%"
  },
  {
      "Date": "1-Mar-00",
      "Price": "10.445",
      "Open": "10.445",
      "High": "10.445",
      "Low": "10.445",
      "Change %": "0.38%"
  },
  {
      "Date": "29-Feb-00",
      "Price": "10.405",
      "Open": "10.405",
      "High": "10.405",
      "Low": "10.405",
      "Change %": "-0.31%"
  },
  {
      "Date": "28-Feb-00",
      "Price": "10.437",
      "Open": "10.437",
      "High": "10.437",
      "Low": "10.437",
      "Change %": "-0.06%"
  },
  {
      "Date": "25-Feb-00",
      "Price": "10.443",
      "Open": "10.443",
      "High": "10.443",
      "Low": "10.443",
      "Change %": "-0.02%"
  },
  {
      "Date": "24-Feb-00",
      "Price": "10.445",
      "Open": "10.445",
      "High": "10.445",
      "Low": "10.445",
      "Change %": "0.09%"
  },
  {
      "Date": "23-Feb-00",
      "Price": "10.436",
      "Open": "10.436",
      "High": "10.436",
      "Low": "10.436",
      "Change %": "-0.05%"
  },
  {
      "Date": "22-Feb-00",
      "Price": "10.441",
      "Open": "10.441",
      "High": "10.441",
      "Low": "10.441",
      "Change %": "0.04%"
  },
  {
      "Date": "21-Feb-00",
      "Price": "10.437",
      "Open": "10.437",
      "High": "10.437",
      "Low": "10.437",
      "Change %": "-0.42%"
  },
  {
      "Date": "18-Feb-00",
      "Price": "10.481",
      "Open": "10.481",
      "High": "10.481",
      "Low": "10.481",
      "Change %": "-0.36%"
  },
  {
      "Date": "17-Feb-00",
      "Price": "10.519",
      "Open": "10.519",
      "High": "10.519",
      "Low": "10.519",
      "Change %": "0.43%"
  },
  {
      "Date": "16-Feb-00",
      "Price": "10.474",
      "Open": "10.474",
      "High": "10.474",
      "Low": "10.474",
      "Change %": "0.22%"
  },
  {
      "Date": "15-Feb-00",
      "Price": "10.451",
      "Open": "10.451",
      "High": "10.451",
      "Low": "10.451",
      "Change %": "2.50%"
  },
  {
      "Date": "14-Feb-00",
      "Price": "10.196",
      "Open": "10.196",
      "High": "10.196",
      "Low": "10.196",
      "Change %": "-1.78%"
  },
  {
      "Date": "11-Feb-00",
      "Price": "10.381",
      "Open": "10.381",
      "High": "10.381",
      "Low": "10.381",
      "Change %": "-0.83%"
  },
  {
      "Date": "10-Feb-00",
      "Price": "10.468",
      "Open": "10.468",
      "High": "10.468",
      "Low": "10.468",
      "Change %": "-0.08%"
  },
  {
      "Date": "9-Feb-00",
      "Price": "10.476",
      "Open": "10.476",
      "High": "10.476",
      "Low": "10.476",
      "Change %": "-1.10%"
  },
  {
      "Date": "8-Feb-00",
      "Price": "10.592",
      "Open": "10.592",
      "High": "10.592",
      "Low": "10.592",
      "Change %": "-0.22%"
  },
  {
      "Date": "7-Feb-00",
      "Price": "10.615",
      "Open": "10.615",
      "High": "10.615",
      "Low": "10.615",
      "Change %": "-0.30%"
  },
  {
      "Date": "4-Feb-00",
      "Price": "10.647",
      "Open": "10.647",
      "High": "10.647",
      "Low": "10.647",
      "Change %": "-0.51%"
  },
  {
      "Date": "3-Feb-00",
      "Price": "10.702",
      "Open": "10.702",
      "High": "10.702",
      "Low": "10.702",
      "Change %": "-0.81%"
  },
  {
      "Date": "2-Feb-00",
      "Price": "10.789",
      "Open": "10.789",
      "High": "10.789",
      "Low": "10.789",
      "Change %": "-0.67%"
  },
  {
      "Date": "1-Feb-00",
      "Price": "10.862",
      "Open": "10.862",
      "High": "10.862",
      "Low": "10.862",
      "Change %": "-0.32%"
  },
  {
      "Date": "31-Jan-00",
      "Price": "10.897",
      "Open": "10.897",
      "High": "10.897",
      "Low": "10.897",
      "Change %": "-0.20%"
  },
  {
      "Date": "28-Jan-00",
      "Price": "10.919",
      "Open": "10.919",
      "High": "10.919",
      "Low": "10.919",
      "Change %": "-0.54%"
  },
  {
      "Date": "27-Jan-00",
      "Price": "10.978",
      "Open": "10.978",
      "High": "10.978",
      "Low": "10.978",
      "Change %": "-0.04%"
  },
  {
      "Date": "25-Jan-00",
      "Price": "10.982",
      "Open": "10.982",
      "High": "10.982",
      "Low": "10.982",
      "Change %": "0.32%"
  },
  {
      "Date": "24-Jan-00",
      "Price": "10.947",
      "Open": "10.947",
      "High": "10.947",
      "Low": "10.947",
      "Change %": "0.14%"
  },
  {
      "Date": "21-Jan-00",
      "Price": "10.932",
      "Open": "10.932",
      "High": "10.932",
      "Low": "10.932",
      "Change %": "-0.07%"
  },
  {
      "Date": "20-Jan-00",
      "Price": "10.94",
      "Open": "10.94",
      "High": "10.94",
      "Low": "10.94",
      "Change %": "-0.85%"
  },
  {
      "Date": "19-Jan-00",
      "Price": "11.034",
      "Open": "11.034",
      "High": "11.034",
      "Low": "11.034",
      "Change %": "-0.03%"
  },
  {
      "Date": "18-Jan-00",
      "Price": "11.037",
      "Open": "11.037",
      "High": "11.037",
      "Low": "11.037",
      "Change %": "-0.25%"
  },
  {
      "Date": "17-Jan-00",
      "Price": "11.065",
      "Open": "11.065",
      "High": "11.065",
      "Low": "11.065",
      "Change %": "-1.86%"
  },
  {
      "Date": "14-Jan-00",
      "Price": "11.275",
      "Open": "11.275",
      "High": "11.275",
      "Low": "11.275",
      "Change %": "-0.10%"
  },
  {
      "Date": "13-Jan-00",
      "Price": "11.286",
      "Open": "11.286",
      "High": "11.286",
      "Low": "11.286",
      "Change %": "0.03%"
  },
  {
      "Date": "12-Jan-00",
      "Price": "11.283",
      "Open": "11.283",
      "High": "11.283",
      "Low": "11.283",
      "Change %": "0.11%"
  },
  {
      "Date": "11-Jan-00",
      "Price": "11.271",
      "Open": "11.271",
      "High": "11.271",
      "Low": "11.271",
      "Change %": "-0.04%"
  },
  {
      "Date": "10-Jan-00",
      "Price": "11.275",
      "Open": "11.275",
      "High": "11.275",
      "Low": "11.275",
      "Change %": "-0.57%"
  },
  {
      "Date": "7-Jan-00",
      "Price": "11.34",
      "Open": "11.34",
      "High": "11.34",
      "Low": "11.34",
      "Change %": "0.08%"
  },
  {
      "Date": "6-Jan-00",
      "Price": "11.331",
      "Open": "11.331",
      "High": "11.331",
      "Low": "11.331",
      "Change %": "0.87%"
  },
  {
      "Date": "5-Jan-00",
      "Price": "11.233",
      "Open": "11.233",
      "High": "11.233",
      "Low": "11.233",
      "Change %": "0.34%"
  },
  {
      "Date": "4-Jan-00",
      "Price": "11.195",
      "Open": "11.195",
      "High": "11.195",
      "Low": "11.195",
      "Change %": "0.21%"
  },
  {
      "Date": "3-Jan-00",
      "Price": "11.171",
      "Open": "11.171",
      "High": "11.171",
      "Low": "11.171",
      "Change %": "-0.23%"
  },
  {
      "Date": "30-Dec-99",
      "Price": "11.197",
      "Open": "11.197",
      "High": "11.197",
      "Low": "11.197",
      "Change %": "-0.12%"
  },
  {
      "Date": "29-Dec-99",
      "Price": "11.211",
      "Open": "11.211",
      "High": "11.211",
      "Low": "11.211",
      "Change %": "-0.46%"
  },
  {
      "Date": "28-Dec-99",
      "Price": "11.263",
      "Open": "11.263",
      "High": "11.263",
      "Low": "11.263",
      "Change %": "-0.13%"
  },
  {
      "Date": "27-Dec-99",
      "Price": "11.278",
      "Open": "11.278",
      "High": "11.278",
      "Low": "11.278",
      "Change %": "0.04%"
  },
  {
      "Date": "24-Dec-99",
      "Price": "11.274",
      "Open": "11.274",
      "High": "11.274",
      "Low": "11.274",
      "Change %": "-0.18%"
  },
  {
      "Date": "23-Dec-99",
      "Price": "11.294",
      "Open": "11.294",
      "High": "11.294",
      "Low": "11.294",
      "Change %": "-0.04%"
  },
  {
      "Date": "22-Dec-99",
      "Price": "11.298",
      "Open": "11.298",
      "High": "11.298",
      "Low": "11.298",
      "Change %": "-0.04%"
  },
  {
      "Date": "21-Dec-99",
      "Price": "11.303",
      "Open": "11.303",
      "High": "11.303",
      "Low": "11.303",
      "Change %": "-0.14%"
  },
  {
      "Date": "20-Dec-99",
      "Price": "11.319",
      "Open": "11.319",
      "High": "11.319",
      "Low": "11.319",
      "Change %": "-0.27%"
  },
  {
      "Date": "17-Dec-99",
      "Price": "11.35",
      "Open": "11.35",
      "High": "11.35",
      "Low": "11.35",
      "Change %": "-0.16%"
  },
  {
      "Date": "16-Dec-99",
      "Price": "11.368",
      "Open": "11.368",
      "High": "11.368",
      "Low": "11.368",
      "Change %": "0.00%"
  },
  {
      "Date": "15-Dec-99",
      "Price": "11.368",
      "Open": "11.368",
      "High": "11.368",
      "Low": "11.368",
      "Change %": "0.08%"
  },
  {
      "Date": "14-Dec-99",
      "Price": "11.359",
      "Open": "11.359",
      "High": "11.359",
      "Low": "11.359",
      "Change %": "0.06%"
  },
  {
      "Date": "13-Dec-99",
      "Price": "11.352",
      "Open": "11.352",
      "High": "11.352",
      "Low": "11.352",
      "Change %": "-0.72%"
  },
  {
      "Date": "10-Dec-99",
      "Price": "11.434",
      "Open": "11.434",
      "High": "11.434",
      "Low": "11.434",
      "Change %": "0.38%"
  },
  {
      "Date": "9-Dec-99",
      "Price": "11.391",
      "Open": "11.391",
      "High": "11.391",
      "Low": "11.391",
      "Change %": "0.07%"
  },
  {
      "Date": "8-Dec-99",
      "Price": "11.383",
      "Open": "11.383",
      "High": "11.383",
      "Low": "11.383",
      "Change %": "0.99%"
  },
  {
      "Date": "7-Dec-99",
      "Price": "11.271",
      "Open": "11.271",
      "High": "11.271",
      "Low": "11.271",
      "Change %": "0.69%"
  },
  {
      "Date": "6-Dec-99",
      "Price": "11.194",
      "Open": "11.194",
      "High": "11.194",
      "Low": "11.194",
      "Change %": "-0.66%"
  },
  {
      "Date": "3-Dec-99",
      "Price": "11.268",
      "Open": "11.268",
      "High": "11.268",
      "Low": "11.268",
      "Change %": "-0.49%"
  },
  {
      "Date": "2-Dec-99",
      "Price": "11.323",
      "Open": "11.323",
      "High": "11.323",
      "Low": "11.323",
      "Change %": "-0.37%"
  },
  {
      "Date": "1-Dec-99",
      "Price": "11.365",
      "Open": "11.365",
      "High": "11.365",
      "Low": "11.365",
      "Change %": "-0.17%"
  },
  {
      "Date": "30-Nov-99",
      "Price": "11.384",
      "Open": "11.384",
      "High": "11.384",
      "Low": "11.384",
      "Change %": "0.00%"
  },
  {
      "Date": "29-Nov-99",
      "Price": "11.384",
      "Open": "11.384",
      "High": "11.384",
      "Low": "11.384",
      "Change %": "-0.02%"
  },
  {
      "Date": "26-Nov-99",
      "Price": "11.386",
      "Open": "11.386",
      "High": "11.386",
      "Low": "11.386",
      "Change %": "-0.05%"
  },
  {
      "Date": "25-Nov-99",
      "Price": "11.392",
      "Open": "11.392",
      "High": "11.392",
      "Low": "11.392",
      "Change %": "-0.13%"
  },
  {
      "Date": "24-Nov-99",
      "Price": "11.407",
      "Open": "11.407",
      "High": "11.407",
      "Low": "11.407",
      "Change %": "-0.18%"
  },
  {
      "Date": "22-Nov-99",
      "Price": "11.428",
      "Open": "11.428",
      "High": "11.428",
      "Low": "11.428",
      "Change %": "0.03%"
  },
  {
      "Date": "19-Nov-99",
      "Price": "11.425",
      "Open": "11.425",
      "High": "11.425",
      "Low": "11.425",
      "Change %": "0.09%"
  },
  {
      "Date": "18-Nov-99",
      "Price": "11.415",
      "Open": "11.415",
      "High": "11.415",
      "Low": "11.415",
      "Change %": "0.03%"
  },
  {
      "Date": "17-Nov-99",
      "Price": "11.412",
      "Open": "11.412",
      "High": "11.412",
      "Low": "11.412",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Nov-99",
      "Price": "11.412",
      "Open": "11.412",
      "High": "11.412",
      "Low": "11.412",
      "Change %": "-0.14%"
  },
  {
      "Date": "15-Nov-99",
      "Price": "11.428",
      "Open": "11.428",
      "High": "11.428",
      "Low": "11.428",
      "Change %": "-0.12%"
  },
  {
      "Date": "12-Nov-99",
      "Price": "11.442",
      "Open": "11.442",
      "High": "11.442",
      "Low": "11.442",
      "Change %": "-0.16%"
  },
  {
      "Date": "11-Nov-99",
      "Price": "11.46",
      "Open": "11.46",
      "High": "11.46",
      "Low": "11.46",
      "Change %": "-0.32%"
  },
  {
      "Date": "5-Nov-99",
      "Price": "11.497",
      "Open": "11.497",
      "High": "11.497",
      "Low": "11.497",
      "Change %": "-0.02%"
  },
  {
      "Date": "4-Nov-99",
      "Price": "11.499",
      "Open": "11.499",
      "High": "11.499",
      "Low": "11.499",
      "Change %": "-0.04%"
  },
  {
      "Date": "3-Nov-99",
      "Price": "11.504",
      "Open": "11.504",
      "High": "11.504",
      "Low": "11.504",
      "Change %": "-0.05%"
  },
  {
      "Date": "2-Nov-99",
      "Price": "11.51",
      "Open": "11.51",
      "High": "11.51",
      "Low": "11.51",
      "Change %": "-0.03%"
  },
  {
      "Date": "1-Nov-99",
      "Price": "11.513",
      "Open": "11.513",
      "High": "11.513",
      "Low": "11.513",
      "Change %": "-0.07%"
  },
  {
      "Date": "29-Oct-99",
      "Price": "11.521",
      "Open": "11.521",
      "High": "11.521",
      "Low": "11.521",
      "Change %": "-0.10%"
  },
  {
      "Date": "28-Oct-99",
      "Price": "11.533",
      "Open": "11.533",
      "High": "11.533",
      "Low": "11.533",
      "Change %": "0.00%"
  },
  {
      "Date": "27-Oct-99",
      "Price": "11.533",
      "Open": "11.533",
      "High": "11.533",
      "Low": "11.533",
      "Change %": "0.01%"
  },
  {
      "Date": "26-Oct-99",
      "Price": "11.532",
      "Open": "11.532",
      "High": "11.532",
      "Low": "11.532",
      "Change %": "0.03%"
  },
  {
      "Date": "25-Oct-99",
      "Price": "11.529",
      "Open": "11.529",
      "High": "11.529",
      "Low": "11.529",
      "Change %": "-0.10%"
  },
  {
      "Date": "22-Oct-99",
      "Price": "11.54",
      "Open": "11.54",
      "High": "11.54",
      "Low": "11.54",
      "Change %": "-0.15%"
  },
  {
      "Date": "21-Oct-99",
      "Price": "11.557",
      "Open": "11.557",
      "High": "11.557",
      "Low": "11.557",
      "Change %": "-0.03%"
  },
  {
      "Date": "20-Oct-99",
      "Price": "11.561",
      "Open": "11.561",
      "High": "11.561",
      "Low": "11.561",
      "Change %": "-0.03%"
  },
  {
      "Date": "18-Oct-99",
      "Price": "11.565",
      "Open": "11.565",
      "High": "11.565",
      "Low": "11.565",
      "Change %": "-0.22%"
  },
  {
      "Date": "15-Oct-99",
      "Price": "11.591",
      "Open": "11.591",
      "High": "11.591",
      "Low": "11.591",
      "Change %": "0.22%"
  },
  {
      "Date": "14-Oct-99",
      "Price": "11.566",
      "Open": "11.566",
      "High": "11.566",
      "Low": "11.566",
      "Change %": "-0.07%"
  },
  {
      "Date": "13-Oct-99",
      "Price": "11.574",
      "Open": "11.574",
      "High": "11.574",
      "Low": "11.574",
      "Change %": "0.28%"
  },
  {
      "Date": "12-Oct-99",
      "Price": "11.542",
      "Open": "11.542",
      "High": "11.542",
      "Low": "11.542",
      "Change %": "-0.02%"
  },
  {
      "Date": "11-Oct-99",
      "Price": "11.544",
      "Open": "11.544",
      "High": "11.544",
      "Low": "11.544",
      "Change %": "-0.20%"
  },
  {
      "Date": "8-Oct-99",
      "Price": "11.567",
      "Open": "11.567",
      "High": "11.567",
      "Low": "11.567",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Oct-99",
      "Price": "11.567",
      "Open": "11.567",
      "High": "11.567",
      "Low": "11.567",
      "Change %": "-0.61%"
  },
  {
      "Date": "6-Oct-99",
      "Price": "11.638",
      "Open": "11.638",
      "High": "11.638",
      "Low": "11.638",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Oct-99",
      "Price": "11.638",
      "Open": "11.638",
      "High": "11.638",
      "Low": "11.638",
      "Change %": "-0.03%"
  },
  {
      "Date": "4-Oct-99",
      "Price": "11.641",
      "Open": "11.641",
      "High": "11.641",
      "Low": "11.641",
      "Change %": "0.12%"
  },
  {
      "Date": "1-Oct-99",
      "Price": "11.627",
      "Open": "11.627",
      "High": "11.627",
      "Low": "11.627",
      "Change %": "-0.06%"
  },
  {
      "Date": "29-Sep-99",
      "Price": "11.634",
      "Open": "11.634",
      "High": "11.634",
      "Low": "11.634",
      "Change %": "0.71%"
  },
  {
      "Date": "28-Sep-99",
      "Price": "11.552",
      "Open": "11.552",
      "High": "11.552",
      "Low": "11.552",
      "Change %": "-0.03%"
  },
  {
      "Date": "27-Sep-99",
      "Price": "11.556",
      "Open": "11.556",
      "High": "11.556",
      "Low": "11.556",
      "Change %": "0.03%"
  },
  {
      "Date": "24-Sep-99",
      "Price": "11.552",
      "Open": "11.552",
      "High": "11.552",
      "Low": "11.552",
      "Change %": "-0.09%"
  },
  {
      "Date": "23-Sep-99",
      "Price": "11.562",
      "Open": "11.562",
      "High": "11.562",
      "Low": "11.562",
      "Change %": "0.01%"
  },
  {
      "Date": "22-Sep-99",
      "Price": "11.561",
      "Open": "11.561",
      "High": "11.561",
      "Low": "11.561",
      "Change %": "0.10%"
  },
  {
      "Date": "21-Sep-99",
      "Price": "11.549",
      "Open": "11.549",
      "High": "11.549",
      "Low": "11.549",
      "Change %": "0.08%"
  },
  {
      "Date": "20-Sep-99",
      "Price": "11.54",
      "Open": "11.54",
      "High": "11.54",
      "Low": "11.54",
      "Change %": "-0.29%"
  },
  {
      "Date": "17-Sep-99",
      "Price": "11.574",
      "Open": "11.574",
      "High": "11.574",
      "Low": "11.574",
      "Change %": "-0.21%"
  },
  {
      "Date": "16-Sep-99",
      "Price": "11.598",
      "Open": "11.598",
      "High": "11.598",
      "Low": "11.598",
      "Change %": "-0.14%"
  },
  {
      "Date": "15-Sep-99",
      "Price": "11.614",
      "Open": "11.614",
      "High": "11.614",
      "Low": "11.614",
      "Change %": "0.02%"
  },
  {
      "Date": "14-Sep-99",
      "Price": "11.612",
      "Open": "11.612",
      "High": "11.612",
      "Low": "11.612",
      "Change %": "0.02%"
  },
  {
      "Date": "10-Sep-99",
      "Price": "11.61",
      "Open": "11.61",
      "High": "11.61",
      "Low": "11.61",
      "Change %": "-0.06%"
  },
  {
      "Date": "9-Sep-99",
      "Price": "11.617",
      "Open": "11.617",
      "High": "11.617",
      "Low": "11.617",
      "Change %": "-0.10%"
  },
  {
      "Date": "8-Sep-99",
      "Price": "11.629",
      "Open": "11.629",
      "High": "11.629",
      "Low": "11.629",
      "Change %": "-0.31%"
  },
  {
      "Date": "7-Sep-99",
      "Price": "11.665",
      "Open": "11.665",
      "High": "11.665",
      "Low": "11.665",
      "Change %": "0.04%"
  },
  {
      "Date": "6-Sep-99",
      "Price": "11.66",
      "Open": "11.66",
      "High": "11.66",
      "Low": "11.66",
      "Change %": "0.34%"
  },
  {
      "Date": "3-Sep-99",
      "Price": "11.62",
      "Open": "11.62",
      "High": "11.62",
      "Low": "11.62",
      "Change %": "-0.20%"
  },
  {
      "Date": "2-Sep-99",
      "Price": "11.643",
      "Open": "11.643",
      "High": "11.643",
      "Low": "11.643",
      "Change %": "0.22%"
  },
  {
      "Date": "1-Sep-99",
      "Price": "11.617",
      "Open": "11.617",
      "High": "11.617",
      "Low": "11.617",
      "Change %": "0.25%"
  },
  {
      "Date": "31-Aug-99",
      "Price": "11.588",
      "Open": "11.588",
      "High": "11.588",
      "Low": "11.588",
      "Change %": "-0.07%"
  },
  {
      "Date": "30-Aug-99",
      "Price": "11.596",
      "Open": "11.596",
      "High": "11.596",
      "Low": "11.596",
      "Change %": "0.29%"
  },
  {
      "Date": "27-Aug-99",
      "Price": "11.563",
      "Open": "11.563",
      "High": "11.563",
      "Low": "11.563",
      "Change %": "0.02%"
  },
  {
      "Date": "26-Aug-99",
      "Price": "11.561",
      "Open": "11.561",
      "High": "11.561",
      "Low": "11.561",
      "Change %": "0.01%"
  },
  {
      "Date": "25-Aug-99",
      "Price": "11.56",
      "Open": "11.56",
      "High": "11.56",
      "Low": "11.56",
      "Change %": "0.16%"
  },
  {
      "Date": "24-Aug-99",
      "Price": "11.542",
      "Open": "11.542",
      "High": "11.542",
      "Low": "11.542",
      "Change %": "0.11%"
  },
  {
      "Date": "23-Aug-99",
      "Price": "11.529",
      "Open": "11.529",
      "High": "11.529",
      "Low": "11.529",
      "Change %": "-0.04%"
  },
  {
      "Date": "20-Aug-99",
      "Price": "11.534",
      "Open": "11.534",
      "High": "11.534",
      "Low": "11.534",
      "Change %": "0.12%"
  },
  {
      "Date": "19-Aug-99",
      "Price": "11.52",
      "Open": "11.52",
      "High": "11.52",
      "Low": "11.52",
      "Change %": "-0.02%"
  },
  {
      "Date": "18-Aug-99",
      "Price": "11.522",
      "Open": "11.522",
      "High": "11.522",
      "Low": "11.522",
      "Change %": "-0.03%"
  },
  {
      "Date": "17-Aug-99",
      "Price": "11.525",
      "Open": "11.525",
      "High": "11.525",
      "Low": "11.525",
      "Change %": "0.04%"
  },
  {
      "Date": "16-Aug-99",
      "Price": "11.52",
      "Open": "11.52",
      "High": "11.52",
      "Low": "11.52",
      "Change %": "-0.31%"
  },
  {
      "Date": "13-Aug-99",
      "Price": "11.556",
      "Open": "11.556",
      "High": "11.556",
      "Low": "11.556",
      "Change %": "-0.66%"
  },
  {
      "Date": "12-Aug-99",
      "Price": "11.633",
      "Open": "11.633",
      "High": "11.633",
      "Low": "11.633",
      "Change %": "0.44%"
  },
  {
      "Date": "11-Aug-99",
      "Price": "11.582",
      "Open": "11.582",
      "High": "11.582",
      "Low": "11.582",
      "Change %": "0.30%"
  },
  {
      "Date": "10-Aug-99",
      "Price": "11.547",
      "Open": "11.547",
      "High": "11.547",
      "Low": "11.547",
      "Change %": "0.40%"
  },
  {
      "Date": "9-Aug-99",
      "Price": "11.501",
      "Open": "11.501",
      "High": "11.501",
      "Low": "11.501",
      "Change %": "0.11%"
  },
  {
      "Date": "6-Aug-99",
      "Price": "11.488",
      "Open": "11.488",
      "High": "11.488",
      "Low": "11.488",
      "Change %": "0.18%"
  },
  {
      "Date": "5-Aug-99",
      "Price": "11.467",
      "Open": "11.467",
      "High": "11.467",
      "Low": "11.467",
      "Change %": "-0.43%"
  },
  {
      "Date": "4-Aug-99",
      "Price": "11.517",
      "Open": "11.517",
      "High": "11.517",
      "Low": "11.517",
      "Change %": "-0.59%"
  },
  {
      "Date": "3-Aug-99",
      "Price": "11.585",
      "Open": "11.585",
      "High": "11.585",
      "Low": "11.585",
      "Change %": "-0.27%"
  },
  {
      "Date": "2-Aug-99",
      "Price": "11.616",
      "Open": "11.616",
      "High": "11.616",
      "Low": "11.616",
      "Change %": "-0.49%"
  },
  {
      "Date": "30-Jul-99",
      "Price": "11.673",
      "Open": "11.673",
      "High": "11.673",
      "Low": "11.673",
      "Change %": "-0.20%"
  },
  {
      "Date": "29-Jul-99",
      "Price": "11.696",
      "Open": "11.696",
      "High": "11.696",
      "Low": "11.696",
      "Change %": "-0.13%"
  },
  {
      "Date": "28-Jul-99",
      "Price": "11.711",
      "Open": "11.711",
      "High": "11.711",
      "Low": "11.711",
      "Change %": "-0.10%"
  },
  {
      "Date": "27-Jul-99",
      "Price": "11.723",
      "Open": "11.723",
      "High": "11.723",
      "Low": "11.723",
      "Change %": "-0.06%"
  },
  {
      "Date": "26-Jul-99",
      "Price": "11.73",
      "Open": "11.73",
      "High": "11.73",
      "Low": "11.73",
      "Change %": "0.01%"
  },
  {
      "Date": "23-Jul-99",
      "Price": "11.729",
      "Open": "11.729",
      "High": "11.729",
      "Low": "11.729",
      "Change %": "0.03%"
  },
  {
      "Date": "22-Jul-99",
      "Price": "11.725",
      "Open": "11.725",
      "High": "11.725",
      "Low": "11.725",
      "Change %": "-1.08%"
  },
  {
      "Date": "21-Jul-99",
      "Price": "11.853",
      "Open": "11.853",
      "High": "11.853",
      "Low": "11.853",
      "Change %": "0.00%"
  },
  {
      "Date": "20-Jul-99",
      "Price": "11.853",
      "Open": "11.853",
      "High": "11.853",
      "Low": "11.853",
      "Change %": "-0.03%"
  },
  {
      "Date": "19-Jul-99",
      "Price": "11.856",
      "Open": "11.856",
      "High": "11.856",
      "Low": "11.856",
      "Change %": "-0.19%"
  },
  {
      "Date": "16-Jul-99",
      "Price": "11.878",
      "Open": "11.878",
      "High": "11.878",
      "Low": "11.878",
      "Change %": "-0.04%"
  },
  {
      "Date": "15-Jul-99",
      "Price": "11.883",
      "Open": "11.883",
      "High": "11.883",
      "Low": "11.883",
      "Change %": "0.99%"
  },
  {
      "Date": "14-Jul-99",
      "Price": "11.767",
      "Open": "11.767",
      "High": "11.767",
      "Low": "11.767",
      "Change %": "-0.04%"
  },
  {
      "Date": "13-Jul-99",
      "Price": "11.772",
      "Open": "11.772",
      "High": "11.772",
      "Low": "11.772",
      "Change %": "0.04%"
  },
  {
      "Date": "12-Jul-99",
      "Price": "11.767",
      "Open": "11.767",
      "High": "11.767",
      "Low": "11.767",
      "Change %": "-0.34%"
  },
  {
      "Date": "9-Jul-99",
      "Price": "11.807",
      "Open": "11.807",
      "High": "11.807",
      "Low": "11.807",
      "Change %": "-0.10%"
  },
  {
      "Date": "8-Jul-99",
      "Price": "11.819",
      "Open": "11.819",
      "High": "11.819",
      "Low": "11.819",
      "Change %": "0.03%"
  },
  {
      "Date": "7-Jul-99",
      "Price": "11.816",
      "Open": "11.816",
      "High": "11.816",
      "Low": "11.816",
      "Change %": "0.00%"
  },
  {
      "Date": "6-Jul-99",
      "Price": "11.816",
      "Open": "11.816",
      "High": "11.816",
      "Low": "11.816",
      "Change %": "-0.02%"
  },
  {
      "Date": "5-Jul-99",
      "Price": "11.818",
      "Open": "11.818",
      "High": "11.818",
      "Low": "11.818",
      "Change %": "-0.42%"
  },
  {
      "Date": "2-Jul-99",
      "Price": "11.868",
      "Open": "11.868",
      "High": "11.868",
      "Low": "11.868",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Jul-99",
      "Price": "11.868",
      "Open": "11.868",
      "High": "11.868",
      "Low": "11.868",
      "Change %": "0.14%"
  },
  {
      "Date": "29-Jun-99",
      "Price": "11.851",
      "Open": "11.851",
      "High": "11.851",
      "Low": "11.851",
      "Change %": "0.30%"
  },
  {
      "Date": "28-Jun-99",
      "Price": "11.816",
      "Open": "11.816",
      "High": "11.816",
      "Low": "11.816",
      "Change %": "0.22%"
  },
  {
      "Date": "25-Jun-99",
      "Price": "11.79",
      "Open": "11.79",
      "High": "11.79",
      "Low": "11.79",
      "Change %": "0.20%"
  },
  {
      "Date": "24-Jun-99",
      "Price": "11.766",
      "Open": "11.766",
      "High": "11.766",
      "Low": "11.766",
      "Change %": "0.04%"
  },
  {
      "Date": "23-Jun-99",
      "Price": "11.761",
      "Open": "11.761",
      "High": "11.761",
      "Low": "11.761",
      "Change %": "-0.02%"
  },
  {
      "Date": "22-Jun-99",
      "Price": "11.763",
      "Open": "11.763",
      "High": "11.763",
      "Low": "11.763",
      "Change %": "0.00%"
  },
  {
      "Date": "21-Jun-99",
      "Price": "11.763",
      "Open": "11.763",
      "High": "11.763",
      "Low": "11.763",
      "Change %": "-0.02%"
  },
  {
      "Date": "18-Jun-99",
      "Price": "11.765",
      "Open": "11.765",
      "High": "11.765",
      "Low": "11.765",
      "Change %": "0.04%"
  },
  {
      "Date": "17-Jun-99",
      "Price": "11.76",
      "Open": "11.76",
      "High": "11.76",
      "Low": "11.76",
      "Change %": "-0.03%"
  },
  {
      "Date": "16-Jun-99",
      "Price": "11.763",
      "Open": "11.763",
      "High": "11.763",
      "Low": "11.763",
      "Change %": "-0.02%"
  },
  {
      "Date": "15-Jun-99",
      "Price": "11.765",
      "Open": "11.765",
      "High": "11.765",
      "Low": "11.765",
      "Change %": "0.12%"
  },
  {
      "Date": "14-Jun-99",
      "Price": "11.751",
      "Open": "11.751",
      "High": "11.751",
      "Low": "11.751",
      "Change %": "0.10%"
  },
  {
      "Date": "11-Jun-99",
      "Price": "11.739",
      "Open": "11.739",
      "High": "11.739",
      "Low": "11.739",
      "Change %": "0.15%"
  },
  {
      "Date": "10-Jun-99",
      "Price": "11.722",
      "Open": "11.722",
      "High": "11.722",
      "Low": "11.722",
      "Change %": "-0.02%"
  },
  {
      "Date": "9-Jun-99",
      "Price": "11.724",
      "Open": "11.724",
      "High": "11.724",
      "Low": "11.724",
      "Change %": "0.00%"
  },
  {
      "Date": "8-Jun-99",
      "Price": "11.724",
      "Open": "11.724",
      "High": "11.724",
      "Low": "11.724",
      "Change %": "0.03%"
  },
  {
      "Date": "7-Jun-99",
      "Price": "11.721",
      "Open": "11.721",
      "High": "11.721",
      "Low": "11.721",
      "Change %": "0.03%"
  },
  {
      "Date": "4-Jun-99",
      "Price": "11.718",
      "Open": "11.718",
      "High": "11.718",
      "Low": "11.718",
      "Change %": "0.12%"
  },
  {
      "Date": "3-Jun-99",
      "Price": "11.704",
      "Open": "11.704",
      "High": "11.704",
      "Low": "11.704",
      "Change %": "-0.02%"
  },
  {
      "Date": "2-Jun-99",
      "Price": "11.706",
      "Open": "11.706",
      "High": "11.706",
      "Low": "11.706",
      "Change %": "-0.07%"
  },
  {
      "Date": "1-Jun-99",
      "Price": "11.714",
      "Open": "11.714",
      "High": "11.714",
      "Low": "11.714",
      "Change %": "0.12%"
  },
  {
      "Date": "31-May-99",
      "Price": "11.7",
      "Open": "11.7",
      "High": "11.7",
      "Low": "11.7",
      "Change %": "-0.61%"
  },
  {
      "Date": "28-May-99",
      "Price": "11.772",
      "Open": "11.772",
      "High": "11.772",
      "Low": "11.772",
      "Change %": "-0.17%"
  },
  {
      "Date": "27-May-99",
      "Price": "11.792",
      "Open": "11.792",
      "High": "11.792",
      "Low": "11.792",
      "Change %": "0.63%"
  },
  {
      "Date": "26-May-99",
      "Price": "11.718",
      "Open": "11.718",
      "High": "11.718",
      "Low": "11.718",
      "Change %": "-0.01%"
  },
  {
      "Date": "25-May-99",
      "Price": "11.719",
      "Open": "11.719",
      "High": "11.719",
      "Low": "11.719",
      "Change %": "-0.32%"
  },
  {
      "Date": "24-May-99",
      "Price": "11.757",
      "Open": "11.757",
      "High": "11.757",
      "Low": "11.757",
      "Change %": "-0.23%"
  },
  {
      "Date": "21-May-99",
      "Price": "11.784",
      "Open": "11.784",
      "High": "11.784",
      "Low": "11.784",
      "Change %": "-0.37%"
  },
  {
      "Date": "20-May-99",
      "Price": "11.828",
      "Open": "11.828",
      "High": "11.828",
      "Low": "11.828",
      "Change %": "-0.06%"
  },
  {
      "Date": "19-May-99",
      "Price": "11.835",
      "Open": "11.835",
      "High": "11.835",
      "Low": "11.835",
      "Change %": "-0.10%"
  },
  {
      "Date": "18-May-99",
      "Price": "11.847",
      "Open": "11.847",
      "High": "11.847",
      "Low": "11.847",
      "Change %": "-0.06%"
  },
  {
      "Date": "17-May-99",
      "Price": "11.854",
      "Open": "11.854",
      "High": "11.854",
      "Low": "11.854",
      "Change %": "-0.05%"
  },
  {
      "Date": "14-May-99",
      "Price": "11.86",
      "Open": "11.86",
      "High": "11.86",
      "Low": "11.86",
      "Change %": "0.10%"
  },
  {
      "Date": "13-May-99",
      "Price": "11.848",
      "Open": "11.848",
      "High": "11.848",
      "Low": "11.848",
      "Change %": "-0.03%"
  },
  {
      "Date": "12-May-99",
      "Price": "11.851",
      "Open": "11.851",
      "High": "11.851",
      "Low": "11.851",
      "Change %": "-0.10%"
  },
  {
      "Date": "11-May-99",
      "Price": "11.863",
      "Open": "11.863",
      "High": "11.863",
      "Low": "11.863",
      "Change %": "0.04%"
  },
  {
      "Date": "10-May-99",
      "Price": "11.858",
      "Open": "11.858",
      "High": "11.858",
      "Low": "11.858",
      "Change %": "-0.05%"
  },
  {
      "Date": "7-May-99",
      "Price": "11.864",
      "Open": "11.864",
      "High": "11.864",
      "Low": "11.864",
      "Change %": "0.08%"
  },
  {
      "Date": "6-May-99",
      "Price": "11.855",
      "Open": "11.855",
      "High": "11.855",
      "Low": "11.855",
      "Change %": "-0.15%"
  },
  {
      "Date": "5-May-99",
      "Price": "11.873",
      "Open": "11.873",
      "High": "11.873",
      "Low": "11.873",
      "Change %": "-0.02%"
  },
  {
      "Date": "4-May-99",
      "Price": "11.875",
      "Open": "11.875",
      "High": "11.875",
      "Low": "11.875",
      "Change %": "-0.08%"
  },
  {
      "Date": "3-May-99",
      "Price": "11.885",
      "Open": "11.885",
      "High": "11.885",
      "Low": "11.885",
      "Change %": "-0.01%"
  },
  {
      "Date": "29-Apr-99",
      "Price": "11.886",
      "Open": "11.886",
      "High": "11.886",
      "Low": "11.886",
      "Change %": "-0.26%"
  },
  {
      "Date": "28-Apr-99",
      "Price": "11.917",
      "Open": "11.917",
      "High": "11.917",
      "Low": "11.917",
      "Change %": "-0.08%"
  },
  {
      "Date": "26-Apr-99",
      "Price": "11.926",
      "Open": "11.926",
      "High": "11.926",
      "Low": "11.926",
      "Change %": "-0.11%"
  },
  {
      "Date": "23-Apr-99",
      "Price": "11.939",
      "Open": "11.939",
      "High": "11.939",
      "Low": "11.939",
      "Change %": "0.06%"
  },
  {
      "Date": "22-Apr-99",
      "Price": "11.932",
      "Open": "11.932",
      "High": "11.932",
      "Low": "11.932",
      "Change %": "-0.03%"
  },
  {
      "Date": "21-Apr-99",
      "Price": "11.936",
      "Open": "11.936",
      "High": "11.936",
      "Low": "11.936",
      "Change %": "-0.04%"
  },
  {
      "Date": "20-Apr-99",
      "Price": "11.941",
      "Open": "11.941",
      "High": "11.941",
      "Low": "11.941",
      "Change %": "0.00%"
  },
  {
      "Date": "19-Apr-99",
      "Price": "11.941",
      "Open": "11.941",
      "High": "11.941",
      "Low": "11.941",
      "Change %": "0.08%"
  },
  {
      "Date": "16-Apr-99",
      "Price": "11.931",
      "Open": "11.931",
      "High": "11.931",
      "Low": "11.931",
      "Change %": "0.00%"
  },
  {
      "Date": "15-Apr-99",
      "Price": "11.931",
      "Open": "11.931",
      "High": "11.931",
      "Low": "11.931",
      "Change %": "-0.01%"
  },
  {
      "Date": "13-Apr-99",
      "Price": "11.932",
      "Open": "11.932",
      "High": "11.932",
      "Low": "11.932",
      "Change %": "0.02%"
  },
  {
      "Date": "12-Apr-99",
      "Price": "11.93",
      "Open": "11.93",
      "High": "11.93",
      "Low": "11.93",
      "Change %": "-0.17%"
  },
  {
      "Date": "9-Apr-99",
      "Price": "11.95",
      "Open": "11.95",
      "High": "11.95",
      "Low": "11.95",
      "Change %": "-0.13%"
  },
  {
      "Date": "8-Apr-99",
      "Price": "11.965",
      "Open": "11.965",
      "High": "11.965",
      "Low": "11.965",
      "Change %": "0.66%"
  },
  {
      "Date": "7-Apr-99",
      "Price": "11.887",
      "Open": "11.887",
      "High": "11.887",
      "Low": "11.887",
      "Change %": "-0.13%"
  },
  {
      "Date": "6-Apr-99",
      "Price": "11.903",
      "Open": "11.903",
      "High": "11.903",
      "Low": "11.903",
      "Change %": "-0.32%"
  },
  {
      "Date": "5-Apr-99",
      "Price": "11.941",
      "Open": "11.941",
      "High": "11.941",
      "Low": "11.941",
      "Change %": "-0.44%"
  },
  {
      "Date": "31-Mar-99",
      "Price": "11.994",
      "Open": "11.994",
      "High": "11.994",
      "Low": "11.994",
      "Change %": "-0.08%"
  },
  {
      "Date": "30-Mar-99",
      "Price": "12.004",
      "Open": "12.004",
      "High": "12.004",
      "Low": "12.004",
      "Change %": "0.00%"
  },
  {
      "Date": "26-Mar-99",
      "Price": "12.004",
      "Open": "12.004",
      "High": "12.004",
      "Low": "12.004",
      "Change %": "0.16%"
  },
  {
      "Date": "24-Mar-99",
      "Price": "11.985",
      "Open": "11.985",
      "High": "11.985",
      "Low": "11.985",
      "Change %": "-0.02%"
  },
  {
      "Date": "23-Mar-99",
      "Price": "11.987",
      "Open": "11.987",
      "High": "11.987",
      "Low": "11.987",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Mar-99",
      "Price": "11.987",
      "Open": "11.987",
      "High": "11.987",
      "Low": "11.987",
      "Change %": "-0.07%"
  },
  {
      "Date": "19-Mar-99",
      "Price": "11.995",
      "Open": "11.995",
      "High": "11.995",
      "Low": "11.995",
      "Change %": "-0.05%"
  },
  {
      "Date": "17-Mar-99",
      "Price": "12.001",
      "Open": "12.001",
      "High": "12.001",
      "Low": "12.001",
      "Change %": "-0.07%"
  },
  {
      "Date": "16-Mar-99",
      "Price": "12.01",
      "Open": "12.01",
      "High": "12.01",
      "Low": "12.01",
      "Change %": "-0.04%"
  },
  {
      "Date": "15-Mar-99",
      "Price": "12.015",
      "Open": "12.015",
      "High": "12.015",
      "Low": "12.015",
      "Change %": "0.14%"
  },
  {
      "Date": "12-Mar-99",
      "Price": "11.998",
      "Open": "11.998",
      "High": "11.998",
      "Low": "11.998",
      "Change %": "0.04%"
  },
  {
      "Date": "11-Mar-99",
      "Price": "11.993",
      "Open": "11.993",
      "High": "11.993",
      "Low": "11.993",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Mar-99",
      "Price": "11.993",
      "Open": "11.993",
      "High": "11.993",
      "Low": "11.993",
      "Change %": "-0.05%"
  },
  {
      "Date": "9-Mar-99",
      "Price": "11.999",
      "Open": "11.999",
      "High": "11.999",
      "Low": "11.999",
      "Change %": "0.08%"
  },
  {
      "Date": "8-Mar-99",
      "Price": "11.989",
      "Open": "11.989",
      "High": "11.989",
      "Low": "11.989",
      "Change %": "-0.60%"
  },
  {
      "Date": "5-Mar-99",
      "Price": "12.061",
      "Open": "12.061",
      "High": "12.061",
      "Low": "12.061",
      "Change %": "0.07%"
  },
  {
      "Date": "4-Mar-99",
      "Price": "12.052",
      "Open": "12.052",
      "High": "12.052",
      "Low": "12.052",
      "Change %": "0.15%"
  },
  {
      "Date": "3-Mar-99",
      "Price": "12.034",
      "Open": "12.034",
      "High": "12.034",
      "Low": "12.034",
      "Change %": "-1.58%"
  },
  {
      "Date": "1-Mar-99",
      "Price": "12.227",
      "Open": "12.227",
      "High": "12.227",
      "Low": "12.227",
      "Change %": "0.03%"
  },
  {
      "Date": "24-Feb-99",
      "Price": "12.223",
      "Open": "12.223",
      "High": "12.223",
      "Low": "12.223",
      "Change %": "-0.01%"
  },
  {
      "Date": "23-Feb-99",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "-0.02%"
  },
  {
      "Date": "22-Feb-99",
      "Price": "12.226",
      "Open": "12.226",
      "High": "12.226",
      "Low": "12.226",
      "Change %": "0.01%"
  },
  {
      "Date": "19-Feb-99",
      "Price": "12.225",
      "Open": "12.225",
      "High": "12.225",
      "Low": "12.225",
      "Change %": "0.02%"
  },
  {
      "Date": "18-Feb-99",
      "Price": "12.223",
      "Open": "12.223",
      "High": "12.223",
      "Low": "12.223",
      "Change %": "0.00%"
  },
  {
      "Date": "17-Feb-99",
      "Price": "12.223",
      "Open": "12.223",
      "High": "12.223",
      "Low": "12.223",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Feb-99",
      "Price": "12.223",
      "Open": "12.223",
      "High": "12.223",
      "Low": "12.223",
      "Change %": "0.01%"
  },
  {
      "Date": "15-Feb-99",
      "Price": "12.222",
      "Open": "12.222",
      "High": "12.222",
      "Low": "12.222",
      "Change %": "-0.01%"
  },
  {
      "Date": "12-Feb-99",
      "Price": "12.223",
      "Open": "12.223",
      "High": "12.223",
      "Low": "12.223",
      "Change %": "0.01%"
  },
  {
      "Date": "11-Feb-99",
      "Price": "12.222",
      "Open": "12.222",
      "High": "12.222",
      "Low": "12.222",
      "Change %": "0.02%"
  },
  {
      "Date": "10-Feb-99",
      "Price": "12.22",
      "Open": "12.22",
      "High": "12.22",
      "Low": "12.22",
      "Change %": "0.00%"
  },
  {
      "Date": "9-Feb-99",
      "Price": "12.22",
      "Open": "12.22",
      "High": "12.22",
      "Low": "12.22",
      "Change %": "0.00%"
  },
  {
      "Date": "8-Feb-99",
      "Price": "12.22",
      "Open": "12.22",
      "High": "12.22",
      "Low": "12.22",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Feb-99",
      "Price": "12.22",
      "Open": "12.22",
      "High": "12.22",
      "Low": "12.22",
      "Change %": "-0.01%"
  },
  {
      "Date": "4-Feb-99",
      "Price": "12.221",
      "Open": "12.221",
      "High": "12.221",
      "Low": "12.221",
      "Change %": "0.01%"
  },
  {
      "Date": "3-Feb-99",
      "Price": "12.22",
      "Open": "12.22",
      "High": "12.22",
      "Low": "12.22",
      "Change %": "-0.01%"
  },
  {
      "Date": "2-Feb-99",
      "Price": "12.221",
      "Open": "12.221",
      "High": "12.221",
      "Low": "12.221",
      "Change %": "0.02%"
  },
  {
      "Date": "1-Feb-99",
      "Price": "12.219",
      "Open": "12.219",
      "High": "12.219",
      "Low": "12.219",
      "Change %": "0.00%"
  },
  {
      "Date": "29-Jan-99",
      "Price": "12.219",
      "Open": "12.219",
      "High": "12.219",
      "Low": "12.219",
      "Change %": "0.00%"
  },
  {
      "Date": "28-Jan-99",
      "Price": "12.219",
      "Open": "12.219",
      "High": "12.219",
      "Low": "12.219",
      "Change %": "0.00%"
  },
  {
      "Date": "27-Jan-99",
      "Price": "12.219",
      "Open": "12.219",
      "High": "12.219",
      "Low": "12.219",
      "Change %": "0.00%"
  },
  {
      "Date": "25-Jan-99",
      "Price": "12.219",
      "Open": "12.219",
      "High": "12.219",
      "Low": "12.219",
      "Change %": "-0.01%"
  },
  {
      "Date": "22-Jan-99",
      "Price": "12.22",
      "Open": "12.22",
      "High": "12.22",
      "Low": "12.22",
      "Change %": "0.01%"
  },
  {
      "Date": "21-Jan-99",
      "Price": "12.219",
      "Open": "12.219",
      "High": "12.219",
      "Low": "12.219",
      "Change %": "-0.01%"
  },
  {
      "Date": "19-Jan-99",
      "Price": "12.22",
      "Open": "12.22",
      "High": "12.22",
      "Low": "12.22",
      "Change %": "0.02%"
  },
  {
      "Date": "18-Jan-99",
      "Price": "12.218",
      "Open": "12.218",
      "High": "12.218",
      "Low": "12.218",
      "Change %": "-0.06%"
  },
  {
      "Date": "15-Jan-99",
      "Price": "12.225",
      "Open": "12.225",
      "High": "12.225",
      "Low": "12.225",
      "Change %": "-0.07%"
  },
  {
      "Date": "14-Jan-99",
      "Price": "12.234",
      "Open": "12.234",
      "High": "12.234",
      "Low": "12.234",
      "Change %": "0.11%"
  },
  {
      "Date": "13-Jan-99",
      "Price": "12.22",
      "Open": "12.22",
      "High": "12.22",
      "Low": "12.22",
      "Change %": "0.00%"
  },
  {
      "Date": "11-Jan-99",
      "Price": "12.22",
      "Open": "12.22",
      "High": "12.22",
      "Low": "12.22",
      "Change %": "0.01%"
  },
  {
      "Date": "8-Jan-99",
      "Price": "12.219",
      "Open": "12.219",
      "High": "12.219",
      "Low": "12.219",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Jan-99",
      "Price": "12.219",
      "Open": "12.219",
      "High": "12.219",
      "Low": "12.219",
      "Change %": "0.01%"
  },
  {
      "Date": "6-Jan-99",
      "Price": "12.218",
      "Open": "12.218",
      "High": "12.218",
      "Low": "12.218",
      "Change %": "-0.02%"
  },
  {
      "Date": "5-Jan-99",
      "Price": "12.221",
      "Open": "12.221",
      "High": "12.221",
      "Low": "12.221",
      "Change %": "-0.03%"
  },
  {
      "Date": "4-Jan-99",
      "Price": "12.225",
      "Open": "12.225",
      "High": "12.225",
      "Low": "12.225",
      "Change %": "0.01%"
  },
  {
      "Date": "1-Jan-99",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.09%"
  },
  {
      "Date": "31-Dec-98",
      "Price": "12.213",
      "Open": "12.213",
      "High": "12.213",
      "Low": "12.213",
      "Change %": "-0.09%"
  },
  {
      "Date": "30-Dec-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.01%"
  },
  {
      "Date": "29-Dec-98",
      "Price": "12.223",
      "Open": "12.223",
      "High": "12.223",
      "Low": "12.223",
      "Change %": "-0.01%"
  },
  {
      "Date": "28-Dec-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Dec-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Dec-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Dec-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.02%"
  },
  {
      "Date": "21-Dec-98",
      "Price": "12.222",
      "Open": "12.222",
      "High": "12.222",
      "Low": "12.222",
      "Change %": "0.01%"
  },
  {
      "Date": "18-Dec-98",
      "Price": "12.221",
      "Open": "12.221",
      "High": "12.221",
      "Low": "12.221",
      "Change %": "-0.02%"
  },
  {
      "Date": "17-Dec-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Dec-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.04%"
  },
  {
      "Date": "15-Dec-98",
      "Price": "12.219",
      "Open": "12.219",
      "High": "12.219",
      "Low": "12.219",
      "Change %": "0.02%"
  },
  {
      "Date": "14-Dec-98",
      "Price": "12.217",
      "Open": "12.217",
      "High": "12.217",
      "Low": "12.217",
      "Change %": "-0.03%"
  },
  {
      "Date": "10-Dec-98",
      "Price": "12.221",
      "Open": "12.221",
      "High": "12.221",
      "Low": "12.221",
      "Change %": "-0.02%"
  },
  {
      "Date": "9-Dec-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.00%"
  },
  {
      "Date": "8-Dec-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.02%"
  },
  {
      "Date": "7-Dec-98",
      "Price": "12.221",
      "Open": "12.221",
      "High": "12.221",
      "Low": "12.221",
      "Change %": "-0.02%"
  },
  {
      "Date": "4-Dec-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.02%"
  },
  {
      "Date": "3-Dec-98",
      "Price": "12.221",
      "Open": "12.221",
      "High": "12.221",
      "Low": "12.221",
      "Change %": "0.00%"
  },
  {
      "Date": "2-Dec-98",
      "Price": "12.221",
      "Open": "12.221",
      "High": "12.221",
      "Low": "12.221",
      "Change %": "-0.01%"
  },
  {
      "Date": "1-Dec-98",
      "Price": "12.222",
      "Open": "12.222",
      "High": "12.222",
      "Low": "12.222",
      "Change %": "0.01%"
  },
  {
      "Date": "30-Nov-98",
      "Price": "12.221",
      "Open": "12.221",
      "High": "12.221",
      "Low": "12.221",
      "Change %": "-0.02%"
  },
  {
      "Date": "27-Nov-98",
      "Price": "12.224",
      "Open": "12.224",
      "High": "12.224",
      "Low": "12.224",
      "Change %": "0.02%"
  },
  {
      "Date": "26-Nov-98",
      "Price": "12.221",
      "Open": "12.221",
      "High": "12.221",
      "Low": "12.221",
      "Change %": "0.05%"
  },
  {
      "Date": "25-Nov-98",
      "Price": "12.215",
      "Open": "12.215",
      "High": "12.215",
      "Low": "12.215",
      "Change %": "0.07%"
  },
  {
      "Date": "24-Nov-98",
      "Price": "12.207",
      "Open": "12.207",
      "High": "12.207",
      "Low": "12.207",
      "Change %": "-0.07%"
  },
  {
      "Date": "23-Nov-98",
      "Price": "12.215",
      "Open": "12.215",
      "High": "12.215",
      "Low": "12.215",
      "Change %": "-0.01%"
  },
  {
      "Date": "20-Nov-98",
      "Price": "12.216",
      "Open": "12.216",
      "High": "12.216",
      "Low": "12.216",
      "Change %": "0.00%"
  },
  {
      "Date": "19-Nov-98",
      "Price": "12.216",
      "Open": "12.216",
      "High": "12.216",
      "Low": "12.216",
      "Change %": "0.00%"
  },
  {
      "Date": "18-Nov-98",
      "Price": "12.216",
      "Open": "12.216",
      "High": "12.216",
      "Low": "12.216",
      "Change %": "0.00%"
  },
  {
      "Date": "17-Nov-98",
      "Price": "12.216",
      "Open": "12.216",
      "High": "12.216",
      "Low": "12.216",
      "Change %": "-0.24%"
  },
  {
      "Date": "16-Nov-98",
      "Price": "12.246",
      "Open": "12.246",
      "High": "12.246",
      "Low": "12.246",
      "Change %": "0.02%"
  },
  {
      "Date": "13-Nov-98",
      "Price": "12.244",
      "Open": "12.244",
      "High": "12.244",
      "Low": "12.244",
      "Change %": "-0.02%"
  },
  {
      "Date": "12-Nov-98",
      "Price": "12.246",
      "Open": "12.246",
      "High": "12.246",
      "Low": "12.246",
      "Change %": "-0.05%"
  },
  {
      "Date": "11-Nov-98",
      "Price": "12.252",
      "Open": "12.252",
      "High": "12.252",
      "Low": "12.252",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Nov-98",
      "Price": "12.252",
      "Open": "12.252",
      "High": "12.252",
      "Low": "12.252",
      "Change %": "-0.14%"
  },
  {
      "Date": "9-Nov-98",
      "Price": "12.269",
      "Open": "12.269",
      "High": "12.269",
      "Low": "12.269",
      "Change %": "-0.08%"
  },
  {
      "Date": "6-Nov-98",
      "Price": "12.279",
      "Open": "12.279",
      "High": "12.279",
      "Low": "12.279",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Nov-98",
      "Price": "12.279",
      "Open": "12.279",
      "High": "12.279",
      "Low": "12.279",
      "Change %": "-0.07%"
  },
  {
      "Date": "3-Nov-98",
      "Price": "12.288",
      "Open": "12.288",
      "High": "12.288",
      "Low": "12.288",
      "Change %": "-0.07%"
  },
  {
      "Date": "2-Nov-98",
      "Price": "12.297",
      "Open": "12.297",
      "High": "12.297",
      "Low": "12.297",
      "Change %": "-0.04%"
  },
  {
      "Date": "30-Oct-98",
      "Price": "12.302",
      "Open": "12.302",
      "High": "12.302",
      "Low": "12.302",
      "Change %": "0.11%"
  },
  {
      "Date": "29-Oct-98",
      "Price": "12.288",
      "Open": "12.288",
      "High": "12.288",
      "Low": "12.288",
      "Change %": "-0.07%"
  },
  {
      "Date": "28-Oct-98",
      "Price": "12.297",
      "Open": "12.297",
      "High": "12.297",
      "Low": "12.297",
      "Change %": "0.03%"
  },
  {
      "Date": "27-Oct-98",
      "Price": "12.293",
      "Open": "12.293",
      "High": "12.293",
      "Low": "12.293",
      "Change %": "-0.07%"
  },
  {
      "Date": "26-Oct-98",
      "Price": "12.302",
      "Open": "12.302",
      "High": "12.302",
      "Low": "12.302",
      "Change %": "-0.03%"
  },
  {
      "Date": "23-Oct-98",
      "Price": "12.306",
      "Open": "12.306",
      "High": "12.306",
      "Low": "12.306",
      "Change %": "0.00%"
  },
  {
      "Date": "20-Oct-98",
      "Price": "12.306",
      "Open": "12.306",
      "High": "12.306",
      "Low": "12.306",
      "Change %": "-0.01%"
  },
  {
      "Date": "16-Oct-98",
      "Price": "12.307",
      "Open": "12.307",
      "High": "12.307",
      "Low": "12.307",
      "Change %": "0.07%"
  },
  {
      "Date": "15-Oct-98",
      "Price": "12.298",
      "Open": "12.298",
      "High": "12.298",
      "Low": "12.298",
      "Change %": "-0.07%"
  },
  {
      "Date": "14-Oct-98",
      "Price": "12.307",
      "Open": "12.307",
      "High": "12.307",
      "Low": "12.307",
      "Change %": "0.00%"
  },
  {
      "Date": "13-Oct-98",
      "Price": "12.307",
      "Open": "12.307",
      "High": "12.307",
      "Low": "12.307",
      "Change %": "0.00%"
  },
  {
      "Date": "12-Oct-98",
      "Price": "12.307",
      "Open": "12.307",
      "High": "12.307",
      "Low": "12.307",
      "Change %": "0.00%"
  },
  {
      "Date": "9-Oct-98",
      "Price": "12.307",
      "Open": "12.307",
      "High": "12.307",
      "Low": "12.307",
      "Change %": "0.07%"
  },
  {
      "Date": "8-Oct-98",
      "Price": "12.299",
      "Open": "12.299",
      "High": "12.299",
      "Low": "12.299",
      "Change %": "0.00%"
  },
  {
      "Date": "7-Oct-98",
      "Price": "12.299",
      "Open": "12.299",
      "High": "12.299",
      "Low": "12.299",
      "Change %": "0.00%"
  },
  {
      "Date": "6-Oct-98",
      "Price": "12.299",
      "Open": "12.299",
      "High": "12.299",
      "Low": "12.299",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Oct-98",
      "Price": "12.299",
      "Open": "12.299",
      "High": "12.299",
      "Low": "12.299",
      "Change %": "0.10%"
  },
  {
      "Date": "30-Sep-98",
      "Price": "12.287",
      "Open": "12.287",
      "High": "12.287",
      "Low": "12.287",
      "Change %": "0.04%"
  },
  {
      "Date": "29-Sep-98",
      "Price": "12.282",
      "Open": "12.282",
      "High": "12.282",
      "Low": "12.282",
      "Change %": "0.00%"
  },
  {
      "Date": "28-Sep-98",
      "Price": "12.282",
      "Open": "12.282",
      "High": "12.282",
      "Low": "12.282",
      "Change %": "-0.01%"
  },
  {
      "Date": "25-Sep-98",
      "Price": "12.283",
      "Open": "12.283",
      "High": "12.283",
      "Low": "12.283",
      "Change %": "0.07%"
  },
  {
      "Date": "24-Sep-98",
      "Price": "12.274",
      "Open": "12.274",
      "High": "12.274",
      "Low": "12.274",
      "Change %": "0.00%"
  },
  {
      "Date": "23-Sep-98",
      "Price": "12.274",
      "Open": "12.274",
      "High": "12.274",
      "Low": "12.274",
      "Change %": "0.07%"
  },
  {
      "Date": "22-Sep-98",
      "Price": "12.265",
      "Open": "12.265",
      "High": "12.265",
      "Low": "12.265",
      "Change %": "-0.01%"
  },
  {
      "Date": "21-Sep-98",
      "Price": "12.266",
      "Open": "12.266",
      "High": "12.266",
      "Low": "12.266",
      "Change %": "0.00%"
  },
  {
      "Date": "18-Sep-98",
      "Price": "12.266",
      "Open": "12.266",
      "High": "12.266",
      "Low": "12.266",
      "Change %": "0.07%"
  },
  {
      "Date": "17-Sep-98",
      "Price": "12.257",
      "Open": "12.257",
      "High": "12.257",
      "Low": "12.257",
      "Change %": "0.00%"
  },
  {
      "Date": "16-Sep-98",
      "Price": "12.257",
      "Open": "12.257",
      "High": "12.257",
      "Low": "12.257",
      "Change %": "0.07%"
  },
  {
      "Date": "15-Sep-98",
      "Price": "12.249",
      "Open": "12.249",
      "High": "12.249",
      "Low": "12.249",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Sep-98",
      "Price": "12.249",
      "Open": "12.249",
      "High": "12.249",
      "Low": "12.249",
      "Change %": "-0.07%"
  },
  {
      "Date": "11-Sep-98",
      "Price": "12.258",
      "Open": "12.258",
      "High": "12.258",
      "Low": "12.258",
      "Change %": "0.15%"
  },
  {
      "Date": "10-Sep-98",
      "Price": "12.24",
      "Open": "12.24",
      "High": "12.24",
      "Low": "12.24",
      "Change %": "-0.07%"
  },
  {
      "Date": "9-Sep-98",
      "Price": "12.249",
      "Open": "12.249",
      "High": "12.249",
      "Low": "12.249",
      "Change %": "0.07%"
  },
  {
      "Date": "8-Sep-98",
      "Price": "12.241",
      "Open": "12.241",
      "High": "12.241",
      "Low": "12.241",
      "Change %": "0.58%"
  },
  {
      "Date": "7-Sep-98",
      "Price": "12.17",
      "Open": "12.17",
      "High": "12.17",
      "Low": "12.17",
      "Change %": "0.00%"
  },
  {
      "Date": "4-Sep-98",
      "Price": "12.17",
      "Open": "12.17",
      "High": "12.17",
      "Low": "12.17",
      "Change %": "-0.07%"
  },
  {
      "Date": "3-Sep-98",
      "Price": "12.179",
      "Open": "12.179",
      "High": "12.179",
      "Low": "12.179",
      "Change %": "-0.07%"
  },
  {
      "Date": "2-Sep-98",
      "Price": "12.188",
      "Open": "12.188",
      "High": "12.188",
      "Low": "12.188",
      "Change %": "-0.36%"
  },
  {
      "Date": "1-Sep-98",
      "Price": "12.232",
      "Open": "12.232",
      "High": "12.232",
      "Low": "12.232",
      "Change %": "-0.07%"
  },
  {
      "Date": "31-Aug-98",
      "Price": "12.241",
      "Open": "12.241",
      "High": "12.241",
      "Low": "12.241",
      "Change %": "-0.01%"
  },
  {
      "Date": "28-Aug-98",
      "Price": "12.242",
      "Open": "12.242",
      "High": "12.242",
      "Low": "12.242",
      "Change %": "-0.50%"
  },
  {
      "Date": "27-Aug-98",
      "Price": "12.304",
      "Open": "12.304",
      "High": "12.304",
      "Low": "12.304",
      "Change %": "-0.22%"
  },
  {
      "Date": "25-Aug-98",
      "Price": "12.331",
      "Open": "12.331",
      "High": "12.331",
      "Low": "12.331",
      "Change %": "-0.15%"
  },
  {
      "Date": "24-Aug-98",
      "Price": "12.349",
      "Open": "12.349",
      "High": "12.349",
      "Low": "12.349",
      "Change %": "0.00%"
  },
  {
      "Date": "21-Aug-98",
      "Price": "12.349",
      "Open": "12.349",
      "High": "12.349",
      "Low": "12.349",
      "Change %": "-0.72%"
  },
  {
      "Date": "20-Aug-98",
      "Price": "12.439",
      "Open": "12.439",
      "High": "12.439",
      "Low": "12.439",
      "Change %": "2.04%"
  },
  {
      "Date": "19-Aug-98",
      "Price": "12.19",
      "Open": "12.19",
      "High": "12.19",
      "Low": "12.19",
      "Change %": "0.00%"
  },
  {
      "Date": "18-Aug-98",
      "Price": "12.19",
      "Open": "12.19",
      "High": "12.19",
      "Low": "12.19",
      "Change %": "-0.07%"
  },
  {
      "Date": "17-Aug-98",
      "Price": "12.199",
      "Open": "12.199",
      "High": "12.199",
      "Low": "12.199",
      "Change %": "0.07%"
  },
  {
      "Date": "14-Aug-98",
      "Price": "12.19",
      "Open": "12.19",
      "High": "12.19",
      "Low": "12.19",
      "Change %": "-0.07%"
  },
  {
      "Date": "13-Aug-98",
      "Price": "12.199",
      "Open": "12.199",
      "High": "12.199",
      "Low": "12.199",
      "Change %": "-0.01%"
  },
  {
      "Date": "12-Aug-98",
      "Price": "12.2",
      "Open": "12.2",
      "High": "12.2",
      "Low": "12.2",
      "Change %": "0.15%"
  },
  {
      "Date": "11-Aug-98",
      "Price": "12.182",
      "Open": "12.182",
      "High": "12.182",
      "Low": "12.182",
      "Change %": "0.00%"
  },
  {
      "Date": "10-Aug-98",
      "Price": "12.182",
      "Open": "12.182",
      "High": "12.182",
      "Low": "12.182",
      "Change %": "-0.01%"
  },
  {
      "Date": "7-Aug-98",
      "Price": "12.183",
      "Open": "12.183",
      "High": "12.183",
      "Low": "12.183",
      "Change %": "-0.07%"
  },
  {
      "Date": "6-Aug-98",
      "Price": "12.192",
      "Open": "12.192",
      "High": "12.192",
      "Low": "12.192",
      "Change %": "0.00%"
  },
  {
      "Date": "5-Aug-98",
      "Price": "12.192",
      "Open": "12.192",
      "High": "12.192",
      "Low": "12.192",
      "Change %": "-0.02%"
  },
  {
      "Date": "4-Aug-98",
      "Price": "12.195",
      "Open": "12.195",
      "High": "12.195",
      "Low": "12.195",
      "Change %": "-0.01%"
  },
  {
      "Date": "3-Aug-98",
      "Price": "12.196",
      "Open": "12.196",
      "High": "12.196",
      "Low": "12.196",
      "Change %": "-0.03%"
  },
  {
      "Date": "31-Jul-98",
      "Price": "12.2",
      "Open": "12.2",
      "High": "12.2",
      "Low": "12.2",
      "Change %": "0.03%"
  },
  {
      "Date": "30-Jul-98",
      "Price": "12.196",
      "Open": "12.196",
      "High": "12.196",
      "Low": "12.196",
      "Change %": "-0.02%"
  },
  {
      "Date": "29-Jul-98",
      "Price": "12.198",
      "Open": "12.198",
      "High": "12.198",
      "Low": "12.198",
      "Change %": "-0.02%"
  },
  {
      "Date": "28-Jul-98",
      "Price": "12.201",
      "Open": "12.201",
      "High": "12.201",
      "Low": "12.201",
      "Change %": "-0.07%"
  },
  {
      "Date": "27-Jul-98",
      "Price": "12.21",
      "Open": "12.21",
      "High": "12.21",
      "Low": "12.21",
      "Change %": "-0.07%"
  },
  {
      "Date": "24-Jul-98",
      "Price": "12.219",
      "Open": "12.219",
      "High": "12.219",
      "Low": "12.219",
      "Change %": "0.58%"
  },
  {
      "Date": "23-Jul-98",
      "Price": "12.148",
      "Open": "12.148",
      "High": "12.148",
      "Low": "12.148",
      "Change %": "0.36%"
  },
  {
      "Date": "22-Jul-98",
      "Price": "12.104",
      "Open": "12.104",
      "High": "12.104",
      "Low": "12.104",
      "Change %": "0.14%"
  },
  {
      "Date": "21-Jul-98",
      "Price": "12.087",
      "Open": "12.087",
      "High": "12.087",
      "Low": "12.087",
      "Change %": "-0.07%"
  },
  {
      "Date": "20-Jul-98",
      "Price": "12.096",
      "Open": "12.096",
      "High": "12.096",
      "Low": "12.096",
      "Change %": "-0.07%"
  },
  {
      "Date": "17-Jul-98",
      "Price": "12.105",
      "Open": "12.105",
      "High": "12.105",
      "Low": "12.105",
      "Change %": "-0.07%"
  },
  {
      "Date": "16-Jul-98",
      "Price": "12.114",
      "Open": "12.114",
      "High": "12.114",
      "Low": "12.114",
      "Change %": "0.00%"
  },
  {
      "Date": "15-Jul-98",
      "Price": "12.114",
      "Open": "12.114",
      "High": "12.114",
      "Low": "12.114",
      "Change %": "0.00%"
  },
  {
      "Date": "14-Jul-98",
      "Price": "12.114",
      "Open": "12.114",
      "High": "12.114",
      "Low": "12.114",
      "Change %": "0.00%"
  },
  {
      "Date": "13-Jul-98",
      "Price": "12.114",
      "Open": "12.114",
      "High": "12.114",
      "Low": "12.114",
      "Change %": "0.22%"
  },
  {
      "Date": "10-Jul-98",
      "Price": "12.088",
      "Open": "12.088",
      "High": "12.088",
      "Low": "12.088",
      "Change %": "0.00%"
  },
  {
      "Date": "9-Jul-98",
      "Price": "12.088",
      "Open": "12.088",
      "High": "12.088",
      "Low": "12.088",
      "Change %": "-0.11%"
  },
  {
      "Date": "8-Jul-98",
      "Price": "12.101",
      "Open": "12.101",
      "High": "12.101",
      "Low": "12.101",
      "Change %": "-0.06%"
  },
  {
      "Date": "6-Jul-98",
      "Price": "12.108",
      "Open": "12.108",
      "High": "12.108",
      "Low": "12.108",
      "Change %": "-0.07%"
  },
  {
      "Date": "3-Jul-98",
      "Price": "12.117",
      "Open": "12.117",
      "High": "12.117",
      "Low": "12.117",
      "Change %": "0.00%"
  },
  {
      "Date": "2-Jul-98",
      "Price": "12.117",
      "Open": "12.117",
      "High": "12.117",
      "Low": "12.117",
      "Change %": "0.00%"
  },
  {
      "Date": "1-Jul-98",
      "Price": "12.117",
      "Open": "12.117",
      "High": "12.117",
      "Low": "12.117",
      "Change %": "0.00%"
  },
  {
      "Date": "29-Jun-98",
      "Price": "12.117",
      "Open": "12.117",
      "High": "12.117",
      "Low": "12.117",
      "Change %": "0.00%"
  },
  {
      "Date": "26-Jun-98",
      "Price": "12.117",
      "Open": "12.117",
      "High": "12.117",
      "Low": "12.117",
      "Change %": "0.00%"
  },
  {
      "Date": "25-Jun-98",
      "Price": "12.117",
      "Open": "12.117",
      "High": "12.117",
      "Low": "12.117",
      "Change %": "0.00%"
  },
  {
      "Date": "24-Jun-98",
      "Price": "12.117",
      "Open": "12.117",
      "High": "12.117",
      "Low": "12.117",
      "Change %": "-0.22%"
  },
  {
      "Date": "23-Jun-98",
      "Price": "12.144",
      "Open": "12.144",
      "High": "12.144",
      "Low": "12.144",
      "Change %": "0.00%"
  },
  {
      "Date": "22-Jun-98",
      "Price": "12.144",
      "Open": "12.144",
      "High": "12.144",
      "Low": "12.144",
      "Change %": "0.15%"
  },
  {
      "Date": "19-Jun-98",
      "Price": "12.126",
      "Open": "12.126",
      "High": "12.126",
      "Low": "12.126",
      "Change %": "0.07%"
  },
  {
      "Date": "18-Jun-98",
      "Price": "12.118",
      "Open": "12.118",
      "High": "12.118",
      "Low": "12.118",
      "Change %": "0.07%"
  },
  {
      "Date": "17-Jun-98",
      "Price": "12.109",
      "Open": "12.109",
      "High": "12.109",
      "Low": "12.109",
      "Change %": "0.81%"
  },
  {
      "Date": "16-Jun-98",
      "Price": "12.012",
      "Open": "12.012",
      "High": "12.012",
      "Low": "12.012",
      "Change %": "-0.15%"
  },
  {
      "Date": "15-Jun-98",
      "Price": "12.03",
      "Open": "12.03",
      "High": "12.03",
      "Low": "12.03",
      "Change %": "0.22%"
  },
  {
      "Date": "12-Jun-98",
      "Price": "12.003",
      "Open": "12.003",
      "High": "12.003",
      "Low": "12.003",
      "Change %": "-0.37%"
  },
  {
      "Date": "11-Jun-98",
      "Price": "12.048",
      "Open": "12.048",
      "High": "12.048",
      "Low": "12.048",
      "Change %": "-0.14%"
  },
  {
      "Date": "10-Jun-98",
      "Price": "12.065",
      "Open": "12.065",
      "High": "12.065",
      "Low": "12.065",
      "Change %": "0.29%"
  },
  {
      "Date": "9-Jun-98",
      "Price": "12.03",
      "Open": "12.03",
      "High": "12.03",
      "Low": "12.03",
      "Change %": "0.14%"
  },
  {
      "Date": "8-Jun-98",
      "Price": "12.013",
      "Open": "12.013",
      "High": "12.013",
      "Low": "12.013",
      "Change %": "0.15%"
  },
  {
      "Date": "5-Jun-98",
      "Price": "11.995",
      "Open": "11.995",
      "High": "11.995",
      "Low": "11.995",
      "Change %": "-0.07%"
  },
  {
      "Date": "4-Jun-98",
      "Price": "12.004",
      "Open": "12.004",
      "High": "12.004",
      "Low": "12.004",
      "Change %": "-0.15%"
  },
  {
      "Date": "3-Jun-98",
      "Price": "12.022",
      "Open": "12.022",
      "High": "12.022",
      "Low": "12.022",
      "Change %": "0.07%"
  },
  {
      "Date": "2-Jun-98",
      "Price": "12.013",
      "Open": "12.013",
      "High": "12.013",
      "Low": "12.013",
      "Change %": "0.07%"
  },
  {
      "Date": "1-Jun-98",
      "Price": "12.005",
      "Open": "12.005",
      "High": "12.005",
      "Low": "12.005",
      "Change %": "-0.37%"
  },
  {
      "Date": "29-May-98",
      "Price": "12.049",
      "Open": "12.049",
      "High": "12.049",
      "Low": "12.049",
      "Change %": "0.29%"
  },
  {
      "Date": "28-May-98",
      "Price": "12.014",
      "Open": "12.014",
      "High": "12.014",
      "Low": "12.014",
      "Change %": "-1.01%"
  },
  {
      "Date": "27-May-98",
      "Price": "12.137",
      "Open": "12.137",
      "High": "12.137",
      "Low": "12.137",
      "Change %": "0.14%"
  },
  {
      "Date": "26-May-98",
      "Price": "12.12",
      "Open": "12.12",
      "High": "12.12",
      "Low": "12.12",
      "Change %": "0.07%"
  },
  {
      "Date": "25-May-98",
      "Price": "12.111",
      "Open": "12.111",
      "High": "12.111",
      "Low": "12.111",
      "Change %": "0.36%"
  },
  {
      "Date": "22-May-98",
      "Price": "12.067",
      "Open": "12.067",
      "High": "12.067",
      "Low": "12.067",
      "Change %": "-0.15%"
  },
  {
      "Date": "21-May-98",
      "Price": "12.085",
      "Open": "12.085",
      "High": "12.085",
      "Low": "12.085",
      "Change %": "0.44%"
  },
  {
      "Date": "20-May-98",
      "Price": "12.032",
      "Open": "12.032",
      "High": "12.032",
      "Low": "12.032",
      "Change %": "0.14%"
  },
  {
      "Date": "19-May-98",
      "Price": "12.015",
      "Open": "12.015",
      "High": "12.015",
      "Low": "12.015",
      "Change %": "-0.29%"
  },
  {
      "Date": "18-May-98",
      "Price": "12.05",
      "Open": "12.05",
      "High": "12.05",
      "Low": "12.05",
      "Change %": "-1.02%"
  },
  {
      "Date": "15-May-98",
      "Price": "12.174",
      "Open": "12.174",
      "High": "12.174",
      "Low": "12.174",
      "Change %": "-1.71%"
  },
  {
      "Date": "14-May-98",
      "Price": "12.386",
      "Open": "12.386",
      "High": "12.386",
      "Low": "12.386",
      "Change %": "2.95%"
  },
  {
      "Date": "13-May-98",
      "Price": "12.031",
      "Open": "12.031",
      "High": "12.031",
      "Low": "12.031",
      "Change %": "0.29%"
  },
  {
      "Date": "12-May-98",
      "Price": "11.996",
      "Open": "11.996",
      "High": "11.996",
      "Low": "11.996",
      "Change %": "38.96%"
  },
  {
      "Date": "8-May-98",
      "Price": "8.633",
      "Open": "8.633",
      "High": "8.633",
      "Low": "8.633",
      "Change %": "0.08%"
  },
  {
      "Date": "6-May-98",
      "Price": "8.626",
      "Open": "8.626",
      "High": "8.626",
      "Low": "8.626",
      "Change %": "0.08%"
  },
  {
      "Date": "5-May-98",
      "Price": "8.619",
      "Open": "8.619",
      "High": "8.619",
      "Low": "8.619",
      "Change %": "-26.84%"
  },
  {
      "Date": "30-Apr-98",
      "Price": "11.781",
      "Open": "11.781",
      "High": "11.781",
      "Low": "11.781",
      "Change %": "-0.31%"
  },
  {
      "Date": "29-Apr-98",
      "Price": "11.818",
      "Open": "11.818",
      "High": "11.818",
      "Low": "11.818",
      "Change %": "1.04%"
  },
  {
      "Date": "27-Apr-98",
      "Price": "11.696",
      "Open": "11.696",
      "High": "11.696",
      "Low": "11.696",
      "Change %": "-0.59%"
  },
  {
      "Date": "24-Apr-98",
      "Price": "11.766",
      "Open": "11.766",
      "High": "11.766",
      "Low": "11.766",
      "Change %": "0.14%"
  },
  {
      "Date": "23-Apr-98",
      "Price": "11.75",
      "Open": "11.75",
      "High": "11.75",
      "Low": "11.75",
      "Change %": "-0.15%"
  },
  {
      "Date": "22-Apr-98",
      "Price": "11.768",
      "Open": "11.768",
      "High": "11.768",
      "Low": "11.768",
      "Change %": "-0.30%"
  },
  {
      "Date": "21-Apr-98",
      "Price": "11.803",
      "Open": "11.803",
      "High": "11.803",
      "Low": "11.803",
      "Change %": "-0.52%"
  },
  {
      "Date": "20-Apr-98",
      "Price": "11.865",
      "Open": "11.865",
      "High": "11.865",
      "Low": "11.865",
      "Change %": "0.15%"
  },
  {
      "Date": "17-Apr-98",
      "Price": "11.847",
      "Open": "11.847",
      "High": "11.847",
      "Low": "11.847",
      "Change %": "-0.23%"
  },
  {
      "Date": "16-Apr-98",
      "Price": "11.874",
      "Open": "11.874",
      "High": "11.874",
      "Low": "11.874",
      "Change %": "0.52%"
  },
  {
      "Date": "15-Apr-98",
      "Price": "11.812",
      "Open": "11.812",
      "High": "11.812",
      "Low": "11.812",
      "Change %": "-0.08%"
  },
  {
      "Date": "13-Apr-98",
      "Price": "11.821",
      "Open": "11.821",
      "High": "11.821",
      "Low": "11.821",
      "Change %": "0.90%"
  },
  {
      "Date": "7-Apr-98",
      "Price": "11.716",
      "Open": "11.716",
      "High": "11.716",
      "Low": "11.716",
      "Change %": "-0.15%"
  },
  {
      "Date": "6-Apr-98",
      "Price": "11.734",
      "Open": "11.734",
      "High": "11.734",
      "Low": "11.734",
      "Change %": "-1.32%"
  },
  {
      "Date": "3-Apr-98",
      "Price": "11.891",
      "Open": "11.891",
      "High": "11.891",
      "Low": "11.891",
      "Change %": "-1.54%"
  },
  {
      "Date": "2-Apr-98",
      "Price": "12.077",
      "Open": "12.077",
      "High": "12.077",
      "Low": "12.077",
      "Change %": "-0.07%"
  },
  {
      "Date": "31-Mar-98",
      "Price": "12.086",
      "Open": "12.086",
      "High": "12.086",
      "Low": "12.086",
      "Change %": "-0.15%"
  },
  {
      "Date": "30-Mar-98",
      "Price": "12.104",
      "Open": "12.104",
      "High": "12.104",
      "Low": "12.104",
      "Change %": "0.15%"
  },
  {
      "Date": "27-Mar-98",
      "Price": "12.086",
      "Open": "12.086",
      "High": "12.086",
      "Low": "12.086",
      "Change %": "-5.27%"
  },
  {
      "Date": "26-Feb-98",
      "Price": "12.759",
      "Open": "12.759",
      "High": "12.759",
      "Low": "12.759",
      "Change %": "0.36%"
  },
  {
      "Date": "24-Feb-98",
      "Price": "12.713",
      "Open": "12.713",
      "High": "12.713",
      "Low": "12.713",
      "Change %": "1.84%"
  },
  {
      "Date": "23-Feb-98",
      "Price": "12.483",
      "Open": "12.483",
      "High": "12.483",
      "Low": "12.483",
      "Change %": "1.25%"
  },
  {
      "Date": "20-Feb-98",
      "Price": "12.329",
      "Open": "12.329",
      "High": "12.329",
      "Low": "12.329",
      "Change %": "0.22%"
  },
  {
      "Date": "19-Feb-98",
      "Price": "12.302",
      "Open": "12.302",
      "High": "12.302",
      "Low": "12.302",
      "Change %": "-0.15%"
  },
  {
      "Date": "18-Feb-98",
      "Price": "12.32",
      "Open": "12.32",
      "High": "12.32",
      "Low": "12.32",
      "Change %": "0.14%"
  },
  {
      "Date": "17-Feb-98",
      "Price": "12.303",
      "Open": "12.303",
      "High": "12.303",
      "Low": "12.303",
      "Change %": "-0.44%"
  },
  {
      "Date": "16-Feb-98",
      "Price": "12.357",
      "Open": "12.357",
      "High": "12.357",
      "Low": "12.357",
      "Change %": "0.44%"
  },
  {
      "Date": "13-Feb-98",
      "Price": "12.303",
      "Open": "12.303",
      "High": "12.303",
      "Low": "12.303",
      "Change %": "0.15%"
  },
  {
      "Date": "12-Feb-98",
      "Price": "12.285",
      "Open": "12.285",
      "High": "12.285",
      "Low": "12.285",
      "Change %": "-0.51%"
  },
  {
      "Date": "11-Feb-98",
      "Price": "12.348",
      "Open": "12.348",
      "High": "12.348",
      "Low": "12.348",
      "Change %": "0.37%"
  },
  {
      "Date": "10-Feb-98",
      "Price": "12.303",
      "Open": "12.303",
      "High": "12.303",
      "Low": "12.303",
      "Change %": "-1.87%"
  },
  {
      "Date": "9-Feb-98",
      "Price": "12.538",
      "Open": "12.538",
      "High": "12.538",
      "Low": "12.538",
      "Change %": "-1.38%"
  },
  {
      "Date": "7-Feb-98",
      "Price": "12.713",
      "Open": "12.713",
      "High": "12.713",
      "Low": "12.713",
      "Change %": "-1.44%"
  },
  {
      "Date": "5-Feb-98",
      "Price": "12.899",
      "Open": "12.899",
      "High": "12.899",
      "Low": "12.899",
      "Change %": "-0.72%"
  },
  {
      "Date": "4-Feb-98",
      "Price": "12.993",
      "Open": "12.993",
      "High": "12.993",
      "Low": "12.993",
      "Change %": "1.10%"
  },
  {
      "Date": "3-Feb-98",
      "Price": "12.852",
      "Open": "12.852",
      "High": "12.852",
      "Low": "12.852",
      "Change %": "1.09%"
  }
];

export const gold = [
  {
      "Date": "1/31/24",
      "Rate": "59664.43853"
  },
  {
      "Date": "1/30/24",
      "Rate": "59689.70881"
  },
  {
      "Date": "1/29/24",
      "Rate": "59460.54075"
  },
  {
      "Date": "1/26/24",
      "Rate": "59254.06445"
  },
  {
      "Date": "1/25/24",
      "Rate": "59166.2957"
  },
  {
      "Date": "1/24/24",
      "Rate": "59541.54042"
  },
  {
      "Date": "1/23/24",
      "Rate": "59426.71652"
  },
  {
      "Date": "1/22/24",
      "Rate": "59324.16092"
  },
  {
      "Date": "1/19/24",
      "Rate": "59488.30844"
  },
  {
      "Date": "1/18/24",
      "Rate": "59033.5244"
  },
  {
      "Date": "1/17/24",
      "Rate": "59482.14254"
  },
  {
      "Date": "1/16/24",
      "Rate": "59761.77358"
  },
  {
      "Date": "1/15/24",
      "Rate": "60003.90483"
  },
  {
      "Date": "1/12/24",
      "Rate": "59602.74432"
  },
  {
      "Date": "1/11/24",
      "Rate": "59523.14503"
  },
  {
      "Date": "1/10/24",
      "Rate": "59643.35173"
  },
  {
      "Date": "1/9/24",
      "Rate": "59755.15618"
  },
  {
      "Date": "1/8/24",
      "Rate": "59398.48675"
  },
  {
      "Date": "1/5/24",
      "Rate": "59751.23371"
  },
  {
      "Date": "1/4/24",
      "Rate": "60135.43801"
  },
  {
      "Date": "1/3/24",
      "Rate": "60348.11196"
  },
  {
      "Date": "1/2/24",
      "Rate": "60908.72149"
  },
  {
      "Date": "12/29/23",
      "Rate": "60543.74857"
  },
  {
      "Date": "12/28/23",
      "Rate": "60961.67481"
  },
  {
      "Date": "12/27/23",
      "Rate": "60558.17916"
  },
  {
      "Date": "12/22/23",
      "Rate": "60307.58214"
  },
  {
      "Date": "12/21/23",
      "Rate": "59719.77636"
  },
  {
      "Date": "12/20/23",
      "Rate": "59712.43937"
  },
  {
      "Date": "12/19/23",
      "Rate": "59375.42461"
  },
  {
      "Date": "12/18/23",
      "Rate": "59225.21738"
  },
  {
      "Date": "12/15/23",
      "Rate": "59847.20013"
  },
  {
      "Date": "12/14/23",
      "Rate": "59758.46488"
  },
  {
      "Date": "12/13/23",
      "Rate": "58259.994"
  },
  {
      "Date": "12/12/23",
      "Rate": "58416.59289"
  },
  {
      "Date": "12/11/23",
      "Rate": "58585.08968"
  },
  {
      "Date": "12/8/23",
      "Rate": "59755.37487"
  },
  {
      "Date": "12/7/23",
      "Rate": "59776.05954"
  },
  {
      "Date": "12/6/23",
      "Rate": "59419.36895"
  },
  {
      "Date": "12/5/23",
      "Rate": "59486.91159"
  },
  {
      "Date": "12/4/23",
      "Rate": "60770.12998"
  },
  {
      "Date": "12/1/23",
      "Rate": "60049.81746"
  },
  {
      "Date": "11/30/23",
      "Rate": "59946.55285"
  },
  {
      "Date": "11/29/23",
      "Rate": "59898.23101"
  },
  {
      "Date": "11/28/23",
      "Rate": "59215.20309"
  },
  {
      "Date": "11/27/23",
      "Rate": "59103.15526"
  },
  {
      "Date": "11/24/23",
      "Rate": "58618.39186"
  },
  {
      "Date": "11/23/23",
      "Rate": "58534.97593"
  },
  {
      "Date": "11/22/23",
      "Rate": "58771.43159"
  },
  {
      "Date": "11/21/23",
      "Rate": "58415.30186"
  },
  {
      "Date": "11/20/23",
      "Rate": "58099.82187"
  },
  {
      "Date": "11/17/23",
      "Rate": "58507.14122"
  },
  {
      "Date": "11/16/23",
      "Rate": "57722.26671"
  },
  {
      "Date": "11/15/23",
      "Rate": "57893.68419"
  },
  {
      "Date": "11/14/23",
      "Rate": "57146.42939"
  },
  {
      "Date": "11/13/23",
      "Rate": "56883.92035"
  },
  {
      "Date": "11/10/23",
      "Rate": "57394.13041"
  },
  {
      "Date": "11/9/23",
      "Rate": "57193.36496"
  },
  {
      "Date": "11/8/23",
      "Rate": "57540.42576"
  },
  {
      "Date": "11/7/23",
      "Rate": "57774.51807"
  },
  {
      "Date": "11/6/23",
      "Rate": "58327.1451"
  },
  {
      "Date": "11/3/23",
      "Rate": "58281.26422"
  },
  {
      "Date": "11/2/23",
      "Rate": "58350.16138"
  },
  {
      "Date": "11/1/23",
      "Rate": "58236.10646"
  },
  {
      "Date": "10/31/23",
      "Rate": "58682.28011"
  },
  {
      "Date": "10/30/23",
      "Rate": "58566.38389"
  },
  {
      "Date": "10/27/23",
      "Rate": "58409.12891"
  },
  {
      "Date": "10/26/23",
      "Rate": "58487.84635"
  },
  {
      "Date": "10/25/23",
      "Rate": "57782.14783"
  },
  {
      "Date": "10/24/23",
      "Rate": "57636.39923"
  },
  {
      "Date": "10/23/23",
      "Rate": "58043.55632"
  },
  {
      "Date": "10/20/23",
      "Rate": "58207.02658"
  },
  {
      "Date": "10/19/23",
      "Rate": "57085.79693"
  },
  {
      "Date": "10/18/23",
      "Rate": "57104.12177"
  },
  {
      "Date": "10/17/23",
      "Rate": "56459.51075"
  },
  {
      "Date": "10/16/23",
      "Rate": "56190.6665"
  },
  {
      "Date": "10/13/23",
      "Rate": "55399.56966"
  },
  {
      "Date": "10/12/23",
      "Rate": "55241.42577"
  },
  {
      "Date": "10/11/23",
      "Rate": "54844.47345"
  },
  {
      "Date": "10/10/23",
      "Rate": "54576.63098"
  },
  {
      "Date": "10/9/23",
      "Rate": "54443.35526"
  },
  {
      "Date": "10/6/23",
      "Rate": "53425.86289"
  },
  {
      "Date": "10/5/23",
      "Rate": "53512.64043"
  },
  {
      "Date": "10/4/23",
      "Rate": "53522.49599"
  },
  {
      "Date": "10/3/23",
      "Rate": "53676.93257"
  },
  {
      "Date": "10/2/23",
      "Rate": "53698.96471"
  },
  {
      "Date": "9/29/23",
      "Rate": "54864.19866"
  },
  {
      "Date": "9/28/23",
      "Rate": "54960.68714"
  },
  {
      "Date": "9/27/23",
      "Rate": "55657.67297"
  },
  {
      "Date": "9/26/23",
      "Rate": "56191.245"
  },
  {
      "Date": "9/25/23",
      "Rate": "56368.66964"
  },
  {
      "Date": "9/22/23",
      "Rate": "56375.67153"
  },
  {
      "Date": "9/21/23",
      "Rate": "56402.6138"
  },
  {
      "Date": "9/20/23",
      "Rate": "56428.09573"
  },
  {
      "Date": "9/19/23",
      "Rate": "56793.49195"
  },
  {
      "Date": "9/18/23",
      "Rate": "56585.5165"
  },
  {
      "Date": "9/15/23",
      "Rate": "56262.42085"
  },
  {
      "Date": "9/14/23",
      "Rate": "55846.11722"
  },
  {
      "Date": "9/13/23",
      "Rate": "55915.35653"
  },
  {
      "Date": "9/12/23",
      "Rate": "56092.7706"
  },
  {
      "Date": "9/11/23",
      "Rate": "56303.92423"
  },
  {
      "Date": "9/8/23",
      "Rate": "56375.80204"
  },
  {
      "Date": "9/7/23",
      "Rate": "56317.53999"
  },
  {
      "Date": "9/6/23",
      "Rate": "56478.17069"
  },
  {
      "Date": "9/5/23",
      "Rate": "56593.87996"
  },
  {
      "Date": "9/4/23",
      "Rate": "56671.93778"
  },
  {
      "Date": "9/1/23",
      "Rate": "56707.28232"
  },
  {
      "Date": "8/31/23",
      "Rate": "56712.25242"
  },
  {
      "Date": "8/30/23",
      "Rate": "56484.99268"
  },
  {
      "Date": "8/29/23",
      "Rate": "56047.03081"
  },
  {
      "Date": "8/25/23",
      "Rate": "55863.86356"
  },
  {
      "Date": "8/24/23",
      "Rate": "55958.05923"
  },
  {
      "Date": "8/23/23",
      "Rate": "55461.67657"
  },
  {
      "Date": "8/22/23",
      "Rate": "55716.13256"
  },
  {
      "Date": "8/21/23",
      "Rate": "55363.90413"
  },
  {
      "Date": "8/18/23",
      "Rate": "55465.6202"
  },
  {
      "Date": "8/17/23",
      "Rate": "55536.87014"
  },
  {
      "Date": "8/16/23",
      "Rate": "55946.29535"
  },
  {
      "Date": "8/15/23",
      "Rate": "55867.07702"
  },
  {
      "Date": "8/14/23",
      "Rate": "56123.57537"
  },
  {
      "Date": "8/11/23",
      "Rate": "56093.83587"
  },
  {
      "Date": "8/10/23",
      "Rate": "56015.75689"
  },
  {
      "Date": "8/9/23",
      "Rate": "56361.2233"
  },
  {
      "Date": "8/8/23",
      "Rate": "56570.1829"
  },
  {
      "Date": "8/7/23",
      "Rate": "56555.81227"
  },
  {
      "Date": "8/4/23",
      "Rate": "56385.79164"
  },
  {
      "Date": "8/3/23",
      "Rate": "56536.61617"
  },
  {
      "Date": "8/2/23",
      "Rate": "56868.97123"
  },
  {
      "Date": "8/1/23",
      "Rate": "56829.92293"
  },
  {
      "Date": "7/31/23",
      "Rate": "56739.26524"
  },
  {
      "Date": "7/28/23",
      "Rate": "56554.31665"
  },
  {
      "Date": "7/27/23",
      "Rate": "57258.12095"
  },
  {
      "Date": "7/26/23",
      "Rate": "57053.01681"
  },
  {
      "Date": "7/25/23",
      "Rate": "56703.4304"
  },
  {
      "Date": "7/24/23",
      "Rate": "56690.44957"
  },
  {
      "Date": "7/21/23",
      "Rate": "56809.19946"
  },
  {
      "Date": "7/20/23",
      "Rate": "57356.38371"
  },
  {
      "Date": "7/19/23",
      "Rate": "57274.90785"
  },
  {
      "Date": "7/18/23",
      "Rate": "56789.79876"
  },
  {
      "Date": "7/17/23",
      "Rate": "56575.04012"
  },
  {
      "Date": "7/14/23",
      "Rate": "56657.58126"
  },
  {
      "Date": "7/13/23",
      "Rate": "56695.64895"
  },
  {
      "Date": "7/12/23",
      "Rate": "55999.12873"
  },
  {
      "Date": "7/11/23",
      "Rate": "56274.20237"
  },
  {
      "Date": "7/10/23",
      "Rate": "56050.49825"
  },
  {
      "Date": "7/7/23",
      "Rate": "55823.56655"
  },
  {
      "Date": "7/6/23",
      "Rate": "56025.54542"
  },
  {
      "Date": "7/5/23",
      "Rate": "56013.10429"
  },
  {
      "Date": "7/4/23",
      "Rate": "55819.49593"
  },
  {
      "Date": "7/3/23",
      "Rate": "55307.94547"
  },
  {
      "Date": "6/30/23",
      "Rate": "55091.45488"
  },
  {
      "Date": "6/29/23",
      "Rate": "55101.62789"
  },
  {
      "Date": "6/28/23",
      "Rate": "55267.45445"
  },
  {
      "Date": "6/27/23",
      "Rate": "55602.11644"
  },
  {
      "Date": "6/26/23",
      "Rate": "55839.8455"
  },
  {
      "Date": "6/23/23",
      "Rate": "55507.70208"
  },
  {
      "Date": "6/22/23",
      "Rate": "55721.79756"
  },
  {
      "Date": "6/21/23",
      "Rate": "55957.01864"
  },
  {
      "Date": "6/20/23",
      "Rate": "56513.09194"
  },
  {
      "Date": "6/19/23",
      "Rate": "56475.5075"
  },
  {
      "Date": "6/16/23",
      "Rate": "56754.13676"
  },
  {
      "Date": "6/15/23",
      "Rate": "55909.29999"
  },
  {
      "Date": "6/14/23",
      "Rate": "56389.21674"
  },
  {
      "Date": "6/13/23",
      "Rate": "56985.96448"
  },
  {
      "Date": "6/12/23",
      "Rate": "57112.39352"
  },
  {
      "Date": "6/9/23",
      "Rate": "57058.23736"
  },
  {
      "Date": "6/8/23",
      "Rate": "56649.48941"
  },
  {
      "Date": "6/7/23",
      "Rate": "57197.77774"
  },
  {
      "Date": "6/6/23",
      "Rate": "57110.4993"
  },
  {
      "Date": "6/5/23",
      "Rate": "56545.90381"
  },
  {
      "Date": "6/2/23",
      "Rate": "57574.74382"
  },
  {
      "Date": "6/1/23",
      "Rate": "56879.85679"
  },
  {
      "Date": "5/31/23",
      "Rate": "57146.79977"
  },
  {
      "Date": "5/30/23",
      "Rate": "56863.82476"
  },
  {
      "Date": "5/26/23",
      "Rate": "56915.38122"
  },
  {
      "Date": "5/25/23",
      "Rate": "57278.16011"
  },
  {
      "Date": "5/24/23",
      "Rate": "57649.66578"
  },
  {
      "Date": "5/23/23",
      "Rate": "57281.54641"
  },
  {
      "Date": "5/22/23",
      "Rate": "57903.91365"
  },
  {
      "Date": "5/19/23",
      "Rate": "57425.92638"
  },
  {
      "Date": "5/18/23",
      "Rate": "57711.22242"
  },
  {
      "Date": "5/17/23",
      "Rate": "57682.32597"
  },
  {
      "Date": "5/16/23",
      "Rate": "58342.00956"
  },
  {
      "Date": "5/15/23",
      "Rate": "58451.69756"
  },
  {
      "Date": "5/12/23",
      "Rate": "58180.78273"
  },
  {
      "Date": "5/11/23",
      "Rate": "58693.83587"
  },
  {
      "Date": "5/10/23",
      "Rate": "58665.35212"
  },
  {
      "Date": "5/9/23",
      "Rate": "58635.90187"
  },
  {
      "Date": "5/5/23",
      "Rate": "58744.15422"
  },
  {
      "Date": "5/4/23",
      "Rate": "58676.0472"
  },
  {
      "Date": "5/3/23",
      "Rate": "58124.73941"
  },
  {
      "Date": "5/2/23",
      "Rate": "57118.35482"
  },
  {
      "Date": "4/28/23",
      "Rate": "57164.17221"
  },
  {
      "Date": "4/27/23",
      "Rate": "57610.21182"
  },
  {
      "Date": "4/26/23",
      "Rate": "57659.04866"
  },
  {
      "Date": "4/25/23",
      "Rate": "57587.64705"
  },
  {
      "Date": "4/24/23",
      "Rate": "57409.1536"
  },
  {
      "Date": "4/21/23",
      "Rate": "57493.88173"
  },
  {
      "Date": "4/20/23",
      "Rate": "57733.51205"
  },
  {
      "Date": "4/19/23",
      "Rate": "57287.34898"
  },
  {
      "Date": "4/18/23",
      "Rate": "57901.45505"
  },
  {
      "Date": "4/17/23",
      "Rate": "58151.94624"
  },
  {
      "Date": "4/14/23",
      "Rate": "58535.84367"
  },
  {
      "Date": "4/13/23",
      "Rate": "58406.82199"
  },
  {
      "Date": "4/12/23",
      "Rate": "58095.79005"
  },
  {
      "Date": "4/11/23",
      "Rate": "57962.62721"
  },
  {
      "Date": "4/6/23",
      "Rate": "58238.07475"
  },
  {
      "Date": "4/5/23",
      "Rate": "58454.06092"
  },
  {
      "Date": "4/4/23",
      "Rate": "57451.30249"
  },
  {
      "Date": "4/3/23",
      "Rate": "56943.36761"
  },
  {
      "Date": "3/31/23",
      "Rate": "57323.04273"
  },
  {
      "Date": "3/30/23",
      "Rate": "57094.85882"
  },
  {
      "Date": "3/29/23",
      "Rate": "57038.39221"
  },
  {
      "Date": "3/28/23",
      "Rate": "56504.00536"
  },
  {
      "Date": "3/27/23",
      "Rate": "56789.47777"
  },
  {
      "Date": "3/24/23",
      "Rate": "58006.39517"
  },
  {
      "Date": "3/23/23",
      "Rate": "57304.61913"
  },
  {
      "Date": "3/22/23",
      "Rate": "56598.90651"
  },
  {
      "Date": "3/21/23",
      "Rate": "57301.3457"
  },
  {
      "Date": "3/20/23",
      "Rate": "57705.05653"
  },
  {
      "Date": "3/17/23",
      "Rate": "56221.64765"
  },
  {
      "Date": "3/16/23",
      "Rate": "55986.87102"
  },
  {
      "Date": "3/15/23",
      "Rate": "55716.28777"
  },
  {
      "Date": "3/14/23",
      "Rate": "55203.72141"
  },
  {
      "Date": "3/13/23",
      "Rate": "54672.15295"
  },
  {
      "Date": "3/10/23",
      "Rate": "53045.81386"
  },
  {
      "Date": "3/9/23",
      "Rate": "52594.8888"
  },
  {
      "Date": "3/8/23",
      "Rate": "52410.25768"
  },
  {
      "Date": "3/7/23",
      "Rate": "53170.49331"
  },
  {
      "Date": "3/6/23",
      "Rate": "53418.73049"
  },
  {
      "Date": "3/3/23",
      "Rate": "53177.64687"
  },
  {
      "Date": "3/2/23",
      "Rate": "53231.52789"
  },
  {
      "Date": "3/1/23",
      "Rate": "53276.56572"
  },
  {
      "Date": "2/28/23",
      "Rate": "52763.63252"
  },
  {
      "Date": "2/27/23",
      "Rate": "52773.50218"
  },
  {
      "Date": "2/24/23",
      "Rate": "53398.04229"
  },
  {
      "Date": "2/23/23",
      "Rate": "53276.67155"
  },
  {
      "Date": "2/22/23",
      "Rate": "53560.18977"
  },
  {
      "Date": "2/21/23",
      "Rate": "53584.89568"
  },
  {
      "Date": "2/20/23",
      "Rate": "53811.58398"
  },
  {
      "Date": "2/17/23",
      "Rate": "53261.15099"
  },
  {
      "Date": "2/16/23",
      "Rate": "53593.39671"
  },
  {
      "Date": "2/15/23",
      "Rate": "53584.43006"
  },
  {
      "Date": "2/14/23",
      "Rate": "54439.12591"
  },
  {
      "Date": "2/13/23",
      "Rate": "54171.8055"
  },
  {
      "Date": "2/10/23",
      "Rate": "54271.58151"
  },
  {
      "Date": "2/9/23",
      "Rate": "54747.50525"
  },
  {
      "Date": "2/8/23",
      "Rate": "54826.85409"
  },
  {
      "Date": "2/7/23",
      "Rate": "54628.67775"
  },
  {
      "Date": "2/6/23",
      "Rate": "54735.25106"
  },
  {
      "Date": "2/3/23",
      "Rate": "55458.48428"
  },
  {
      "Date": "2/2/23",
      "Rate": "56596.00698"
  },
  {
      "Date": "2/1/23",
      "Rate": "55704.48156"
  },
  {
      "Date": "1/31/23",
      "Rate": "54944.65158"
  },
  {
      "Date": "1/30/23",
      "Rate": "55423.41488"
  },
  {
      "Date": "1/27/23",
      "Rate": "55467.9236"
  },
  {
      "Date": "1/26/23",
      "Rate": "55654.96393"
  },
  {
      "Date": "1/25/23",
      "Rate": "55351.33247"
  },
  {
      "Date": "1/24/23",
      "Rate": "55734.06938"
  },
  {
      "Date": "1/23/23",
      "Rate": "55370.41923"
  },
  {
      "Date": "1/20/23",
      "Rate": "55132.26688"
  },
  {
      "Date": "1/19/23",
      "Rate": "54672.87606"
  },
  {
      "Date": "1/18/23",
      "Rate": "54927.1839"
  },
  {
      "Date": "1/17/23",
      "Rate": "54808.42696"
  },
  {
      "Date": "1/16/23",
      "Rate": "55165.91827"
  },
  {
      "Date": "1/13/23",
      "Rate": "54587.42482"
  },
  {
      "Date": "1/12/23",
      "Rate": "53916.52763"
  },
  {
      "Date": "1/11/23",
      "Rate": "54265.96236"
  },
  {
      "Date": "1/10/23",
      "Rate": "54012.86442"
  },
  {
      "Date": "1/9/23",
      "Rate": "54292.57659"
  },
  {
      "Date": "1/6/23",
      "Rate": "53248.02907"
  },
  {
      "Date": "1/5/23",
      "Rate": "53906.53098"
  },
  {
      "Date": "1/4/23",
      "Rate": "54176.54985"
  },
  {
      "Date": "1/3/23",
      "Rate": "53611.91555"
  },
  {
      "Date": "12/30/22",
      "Rate": "52870.08942"
  },
  {
      "Date": "12/29/22",
      "Rate": "52750.13316"
  },
  {
      "Date": "12/28/22",
      "Rate": "52629.02344"
  },
  {
      "Date": "12/23/22",
      "Rate": "52506.56273"
  },
  {
      "Date": "12/22/22",
      "Rate": "53119.13085"
  },
  {
      "Date": "12/21/22",
      "Rate": "53030.74481"
  },
  {
      "Date": "12/20/22",
      "Rate": "52649.18253"
  },
  {
      "Date": "12/19/22",
      "Rate": "52355.74878"
  },
  {
      "Date": "12/16/22",
      "Rate": "51982.86742"
  },
  {
      "Date": "12/15/22",
      "Rate": "51919.10616"
  },
  {
      "Date": "12/14/22",
      "Rate": "52626.24385"
  },
  {
      "Date": "12/13/22",
      "Rate": "51968.80721"
  },
  {
      "Date": "12/12/22",
      "Rate": "52225.49251"
  },
  {
      "Date": "12/9/22",
      "Rate": "52136.74315"
  },
  {
      "Date": "12/8/22",
      "Rate": "51747.91795"
  },
  {
      "Date": "12/7/22",
      "Rate": "51464.86887"
  },
  {
      "Date": "12/6/22",
      "Rate": "51596.01051"
  },
  {
      "Date": "12/5/22",
      "Rate": "51795.22037"
  },
  {
      "Date": "12/2/22",
      "Rate": "51702.76372"
  },
  {
      "Date": "12/1/22",
      "Rate": "50918.48533"
  },
  {
      "Date": "11/30/22",
      "Rate": "50584.77927"
  },
  {
      "Date": "11/29/22",
      "Rate": "50535.34278"
  },
  {
      "Date": "11/28/22",
      "Rate": "50765.44913"
  },
  {
      "Date": "11/25/22",
      "Rate": "50528.79945"
  },
  {
      "Date": "11/24/22",
      "Rate": "50583.60465"
  },
  {
      "Date": "11/23/22",
      "Rate": "50056.0539"
  },
  {
      "Date": "11/22/22",
      "Rate": "50343.51223"
  },
  {
      "Date": "11/21/22",
      "Rate": "50141.33935"
  },
  {
      "Date": "11/18/22",
      "Rate": "50793.32616"
  },
  {
      "Date": "11/17/22",
      "Rate": "50812.46583"
  },
  {
      "Date": "11/16/22",
      "Rate": "51231.51731"
  },
  {
      "Date": "11/15/22",
      "Rate": "50670.76315"
  },
  {
      "Date": "11/14/22",
      "Rate": "50181.13194"
  },
  {
      "Date": "11/11/22",
      "Rate": "50123.82934"
  },
  {
      "Date": "11/10/22",
      "Rate": "48627.10453"
  },
  {
      "Date": "11/9/22",
      "Rate": "48892.65419"
  },
  {
      "Date": "11/8/22",
      "Rate": "48245.24595"
  },
  {
      "Date": "11/7/22",
      "Rate": "48507.41988"
  },
  {
      "Date": "11/4/22",
      "Rate": "47719.79047"
  },
  {
      "Date": "11/3/22",
      "Rate": "47306.66502"
  },
  {
      "Date": "11/2/22",
      "Rate": "48332.7325"
  },
  {
      "Date": "11/1/22",
      "Rate": "48155.63237"
  },
  {
      "Date": "10/31/22",
      "Rate": "47883.87802"
  },
  {
      "Date": "10/28/22",
      "Rate": "47903.55738"
  },
  {
      "Date": "10/27/22",
      "Rate": "48325.55424"
  },
  {
      "Date": "10/26/22",
      "Rate": "48627.03046"
  },
  {
      "Date": "10/25/22",
      "Rate": "47785.46712"
  },
  {
      "Date": "10/24/22",
      "Rate": "48119.56472"
  },
  {
      "Date": "10/21/22",
      "Rate": "47350.306"
  },
  {
      "Date": "10/20/22",
      "Rate": "47662.39969"
  },
  {
      "Date": "10/19/22",
      "Rate": "48046.15602"
  },
  {
      "Date": "10/18/22",
      "Rate": "47952.2884"
  },
  {
      "Date": "10/17/22",
      "Rate": "48026.64597"
  },
  {
      "Date": "10/14/22",
      "Rate": "48100.61553"
  },
  {
      "Date": "10/13/22",
      "Rate": "48629.23156"
  },
  {
      "Date": "10/12/22",
      "Rate": "48523.03921"
  },
  {
      "Date": "10/11/22",
      "Rate": "48249.9903"
  },
  {
      "Date": "10/10/22",
      "Rate": "48844.98139"
  },
  {
      "Date": "10/7/22",
      "Rate": "49877.97316"
  },
  {
      "Date": "10/6/22",
      "Rate": "49770.32399"
  },
  {
      "Date": "10/5/22",
      "Rate": "49177.86204"
  },
  {
      "Date": "10/4/22",
      "Rate": "49102.60498"
  },
  {
      "Date": "10/3/22",
      "Rate": "47787.5377"
  },
  {
      "Date": "9/30/22",
      "Rate": "48025.58775"
  },
  {
      "Date": "9/29/22",
      "Rate": "47365.59022"
  },
  {
      "Date": "9/28/22",
      "Rate": "46557.31847"
  },
  {
      "Date": "9/27/22",
      "Rate": "47041.59156"
  },
  {
      "Date": "9/26/22",
      "Rate": "47405.73555"
  },
  {
      "Date": "9/23/22",
      "Rate": "47552.29898"
  },
  {
      "Date": "9/22/22",
      "Rate": "47753.88984"
  },
  {
      "Date": "9/21/22",
      "Rate": "47248.08198"
  },
  {
      "Date": "9/20/22",
      "Rate": "46913.70571"
  },
  {
      "Date": "9/16/22",
      "Rate": "46785.85513"
  },
  {
      "Date": "9/15/22",
      "Rate": "47550.627"
  },
  {
      "Date": "9/14/22",
      "Rate": "47761.21272"
  },
  {
      "Date": "9/13/22",
      "Rate": "48415.88388"
  },
  {
      "Date": "9/12/22",
      "Rate": "48308.19944"
  },
  {
      "Date": "9/9/22",
      "Rate": "48505.30697"
  },
  {
      "Date": "9/8/22",
      "Rate": "48379.86561"
  },
  {
      "Date": "9/7/22",
      "Rate": "47970.18642"
  },
  {
      "Date": "9/6/22",
      "Rate": "48248.58992"
  },
  {
      "Date": "9/5/22",
      "Rate": "48177.90084"
  },
  {
      "Date": "9/2/22",
      "Rate": "47984.42301"
  },
  {
      "Date": "9/1/22",
      "Rate": "47953.28665"
  },
  {
      "Date": "8/31/22",
      "Rate": "48086.67172"
  },
  {
      "Date": "8/30/22",
      "Rate": "48724.48544"
  },
  {
      "Date": "8/26/22",
      "Rate": "49410.39877"
  },
  {
      "Date": "8/25/22",
      "Rate": "49671.77199"
  },
  {
      "Date": "8/24/22",
      "Rate": "49365.86536"
  },
  {
      "Date": "8/23/22",
      "Rate": "48978.07016"
  },
  {
      "Date": "8/22/22",
      "Rate": "48800.1164"
  },
  {
      "Date": "8/19/22",
      "Rate": "49419.66878"
  },
  {
      "Date": "8/18/22",
      "Rate": "49685.54648"
  },
  {
      "Date": "8/17/22",
      "Rate": "49661.82472"
  },
  {
      "Date": "8/16/22",
      "Rate": "49889.76878"
  },
  {
      "Date": "8/15/22",
      "Rate": "50038.63913"
  },
  {
      "Date": "8/12/22",
      "Rate": "50222.26494"
  },
  {
      "Date": "8/11/22",
      "Rate": "50228.95995"
  },
  {
      "Date": "8/10/22",
      "Rate": "50023.52775"
  },
  {
      "Date": "8/9/22",
      "Rate": "50258.32202"
  },
  {
      "Date": "8/8/22",
      "Rate": "49848.25482"
  },
  {
      "Date": "8/5/22",
      "Rate": "50034.29337"
  },
  {
      "Date": "8/4/22",
      "Rate": "49665.74366"
  },
  {
      "Date": "8/3/22",
      "Rate": "49282.15312"
  },
  {
      "Date": "8/2/22",
      "Rate": "49089.43368"
  },
  {
      "Date": "8/1/22",
      "Rate": "49204.13764"
  },
  {
      "Date": "7/29/22",
      "Rate": "49266.1987"
  },
  {
      "Date": "7/28/22",
      "Rate": "49076.01192"
  },
  {
      "Date": "7/27/22",
      "Rate": "48620.38131"
  },
  {
      "Date": "7/26/22",
      "Rate": "48430.37443"
  },
  {
      "Date": "7/25/22",
      "Rate": "48745.75213"
  },
  {
      "Date": "7/22/22",
      "Rate": "48580.38766"
  },
  {
      "Date": "7/21/22",
      "Rate": "47512.82386"
  },
  {
      "Date": "7/20/22",
      "Rate": "48319.69171"
  },
  {
      "Date": "7/19/22",
      "Rate": "48266.46678"
  },
  {
      "Date": "7/18/22",
      "Rate": "48654.60414"
  },
  {
      "Date": "7/15/22",
      "Rate": "47906.15708"
  },
  {
      "Date": "7/14/22",
      "Rate": "48380.54287"
  },
  {
      "Date": "7/13/22",
      "Rate": "48568.55324"
  },
  {
      "Date": "7/12/22",
      "Rate": "48601.80956"
  },
  {
      "Date": "7/11/22",
      "Rate": "48711.12013"
  },
  {
      "Date": "7/8/22",
      "Rate": "48578.39468"
  },
  {
      "Date": "7/7/22",
      "Rate": "48664.88298"
  },
  {
      "Date": "7/6/22",
      "Rate": "49259.24619"
  },
  {
      "Date": "7/5/22",
      "Rate": "50405.7038"
  },
  {
      "Date": "7/4/22",
      "Rate": "50326.1151"
  },
  {
      "Date": "7/1/22",
      "Rate": "49992.15859"
  },
  {
      "Date": "6/30/22",
      "Rate": "50500.85539"
  },
  {
      "Date": "6/29/22",
      "Rate": "50460.24092"
  },
  {
      "Date": "6/28/22",
      "Rate": "50908.01249"
  },
  {
      "Date": "6/27/22",
      "Rate": "50862.56195"
  },
  {
      "Date": "6/24/22",
      "Rate": "50417.95799"
  },
  {
      "Date": "6/23/22",
      "Rate": "50485.45124"
  },
  {
      "Date": "6/22/22",
      "Rate": "50425.08686"
  },
  {
      "Date": "6/21/22",
      "Rate": "50606.86432"
  },
  {
      "Date": "6/20/22",
      "Rate": "50612.00727"
  },
  {
      "Date": "6/17/22",
      "Rate": "50912.74978"
  },
  {
      "Date": "6/16/22",
      "Rate": "50355.91457"
  },
  {
      "Date": "6/15/22",
      "Rate": "50249.92328"
  },
  {
      "Date": "6/14/22",
      "Rate": "50187.98921"
  },
  {
      "Date": "6/13/22",
      "Rate": "51119.72345"
  },
  {
      "Date": "6/10/22",
      "Rate": "50779.62927"
  },
  {
      "Date": "6/9/22",
      "Rate": "50774.45105"
  },
  {
      "Date": "6/8/22",
      "Rate": "50640.27232"
  },
  {
      "Date": "6/7/22",
      "Rate": "50583.28013"
  },
  {
      "Date": "6/6/22",
      "Rate": "50799.26277"
  },
  {
      "Date": "6/1/22",
      "Rate": "50070.8337"
  },
  {
      "Date": "5/31/22",
      "Rate": "50775.87259"
  },
  {
      "Date": "5/30/22",
      "Rate": "50799.20634"
  },
  {
      "Date": "5/27/22",
      "Rate": "50904.98245"
  },
  {
      "Date": "5/26/22",
      "Rate": "50537.82606"
  },
  {
      "Date": "5/25/22",
      "Rate": "50855.39075"
  },
  {
      "Date": "5/24/22",
      "Rate": "50832.45913"
  },
  {
      "Date": "5/23/22",
      "Rate": "50987.56592"
  },
  {
      "Date": "5/20/22",
      "Rate": "50713.44821"
  },
  {
      "Date": "5/19/22",
      "Rate": "49878.51285"
  },
  {
      "Date": "5/18/22",
      "Rate": "49860.1739"
  },
  {
      "Date": "5/17/22",
      "Rate": "49969.77372"
  },
  {
      "Date": "5/16/22",
      "Rate": "49340.29524"
  },
  {
      "Date": "5/13/22",
      "Rate": "49822.51539"
  },
  {
      "Date": "5/12/22",
      "Rate": "50510.11129"
  },
  {
      "Date": "5/11/22",
      "Rate": "50492.26618"
  },
  {
      "Date": "5/10/22",
      "Rate": "50769.63968"
  },
  {
      "Date": "5/9/22",
      "Rate": "50931.91062"
  },
  {
      "Date": "5/6/22",
      "Rate": "51150.04145"
  },
  {
      "Date": "5/5/22",
      "Rate": "51159.40316"
  },
  {
      "Date": "5/4/22",
      "Rate": "50265.6202"
  },
  {
      "Date": "5/3/22",
      "Rate": "50148.78217"
  },
  {
      "Date": "4/29/22",
      "Rate": "51719.07441"
  },
  {
      "Date": "4/28/22",
      "Rate": "51104.90132"
  },
  {
      "Date": "4/27/22",
      "Rate": "51220.02857"
  },
  {
      "Date": "4/26/22",
      "Rate": "51487.70878"
  },
  {
      "Date": "4/25/22",
      "Rate": "51897.99467"
  },
  {
      "Date": "4/22/22",
      "Rate": "52419.16789"
  },
  {
      "Date": "4/21/22",
      "Rate": "52381.98557"
  },
  {
      "Date": "4/20/22",
      "Rate": "52414.61401"
  },
  {
      "Date": "4/19/22",
      "Rate": "53242.51221"
  },
  {
      "Date": "4/14/22",
      "Rate": "52887.45833"
  },
  {
      "Date": "4/13/22",
      "Rate": "53001.38627"
  },
  {
      "Date": "4/12/22",
      "Rate": "52371.61149"
  },
  {
      "Date": "4/11/22",
      "Rate": "52382.45119"
  },
  {
      "Date": "4/8/22",
      "Rate": "51724.51013"
  },
  {
      "Date": "4/7/22",
      "Rate": "51592.34907"
  },
  {
      "Date": "4/6/22",
      "Rate": "51682.25895"
  },
  {
      "Date": "4/5/22",
      "Rate": "51323.25438"
  },
  {
      "Date": "4/4/22",
      "Rate": "51282.36124"
  },
  {
      "Date": "4/1/22",
      "Rate": "51794.91702"
  },
  {
      "Date": "3/31/22",
      "Rate": "51522.74291"
  },
  {
      "Date": "3/30/22",
      "Rate": "51289.77583"
  },
  {
      "Date": "3/29/22",
      "Rate": "50938.63737"
  },
  {
      "Date": "3/28/22",
      "Rate": "51732.19634"
  },
  {
      "Date": "3/25/22",
      "Rate": "52654.48421"
  },
  {
      "Date": "3/24/22",
      "Rate": "52362.45084"
  },
  {
      "Date": "3/23/22",
      "Rate": "52175.10362"
  },
  {
      "Date": "3/22/22",
      "Rate": "51796.98407"
  },
  {
      "Date": "3/21/22",
      "Rate": "51779.66454"
  },
  {
      "Date": "3/18/22",
      "Rate": "51786.95215"
  },
  {
      "Date": "3/17/22",
      "Rate": "52014.34593"
  },
  {
      "Date": "3/16/22",
      "Rate": "51580.56403"
  },
  {
      "Date": "3/15/22",
      "Rate": "51986.13732"
  },
  {
      "Date": "3/14/22",
      "Rate": "52955.82991"
  },
  {
      "Date": "3/11/22",
      "Rate": "53850.45944"
  },
  {
      "Date": "3/10/22",
      "Rate": "53826.51899"
  },
  {
      "Date": "3/9/22",
      "Rate": "54283.42299"
  },
  {
      "Date": "3/8/22",
      "Rate": "54507.75499"
  },
  {
      "Date": "3/7/22",
      "Rate": "54259.47195"
  },
  {
      "Date": "3/4/22",
      "Rate": "52381.70691"
  },
  {
      "Date": "3/3/22",
      "Rate": "51845.73978"
  },
  {
      "Date": "3/2/22",
      "Rate": "51343.24062"
  },
  {
      "Date": "3/1/22",
      "Rate": "51185.46712"
  },
  {
      "Date": "2/28/22",
      "Rate": "50718.30191"
  },
  {
      "Date": "2/25/22",
      "Rate": "50661.40144"
  },
  {
      "Date": "2/24/22",
      "Rate": "52551.49826"
  },
  {
      "Date": "2/23/22",
      "Rate": "49939.70264"
  },
  {
      "Date": "2/22/22",
      "Rate": "49931.6249"
  },
  {
      "Date": "2/21/22",
      "Rate": "49820.12381"
  },
  {
      "Date": "2/18/22",
      "Rate": "49703.53974"
  },
  {
      "Date": "2/17/22",
      "Rate": "49933.90007"
  },
  {
      "Date": "2/16/22",
      "Rate": "49075.41932"
  },
  {
      "Date": "2/15/22",
      "Rate": "49213.04079"
  },
  {
      "Date": "2/14/22",
      "Rate": "49491.16563"
  },
  {
      "Date": "2/11/22",
      "Rate": "48541.551"
  },
  {
      "Date": "2/10/22",
      "Rate": "48584.14434"
  },
  {
      "Date": "2/9/22",
      "Rate": "48234.90009"
  },
  {
      "Date": "2/8/22",
      "Rate": "47997.52377"
  },
  {
      "Date": "2/7/22",
      "Rate": "47695.67012"
  },
  {
      "Date": "2/4/22",
      "Rate": "47766.90241"
  },
  {
      "Date": "2/3/22",
      "Rate": "47539.6074"
  },
  {
      "Date": "2/2/22",
      "Rate": "47533.84716"
  },
  {
      "Date": "2/1/22",
      "Rate": "47642.73797"
  },
  {
      "Date": "1/31/22",
      "Rate": "47117.61054"
  },
  {
      "Date": "1/28/22",
      "Rate": "47372.93779"
  },
  {
      "Date": "1/27/22",
      "Rate": "48184.28544"
  },
  {
      "Date": "1/26/22",
      "Rate": "48661.74359"
  },
  {
      "Date": "1/25/22",
      "Rate": "48409.56631"
  },
  {
      "Date": "1/24/22",
      "Rate": "48423.21029"
  },
  {
      "Date": "1/21/22",
      "Rate": "48076.8973"
  },
  {
      "Date": "1/20/22",
      "Rate": "48152.51415"
  },
  {
      "Date": "1/19/22",
      "Rate": "47691.08097"
  },
  {
      "Date": "1/18/22",
      "Rate": "47634.58968"
  },
  {
      "Date": "1/17/22",
      "Rate": "47687.69114"
  },
  {
      "Date": "1/14/22",
      "Rate": "47632.65666"
  },
  {
      "Date": "1/13/22",
      "Rate": "47500.23104"
  },
  {
      "Date": "1/12/22",
      "Rate": "47263.43322"
  },
  {
      "Date": "1/11/22",
      "Rate": "47025.17505"
  },
  {
      "Date": "1/10/22",
      "Rate": "47020.58943"
  },
  {
      "Date": "1/7/22",
      "Rate": "46939.08182"
  },
  {
      "Date": "1/6/22",
      "Rate": "47364.38032"
  },
  {
      "Date": "1/5/22",
      "Rate": "47649.06965"
  },
  {
      "Date": "1/4/22",
      "Rate": "47566.72957"
  },
  {
      "Date": "12/31/21",
      "Rate": "47827.937"
  },
  {
      "Date": "12/30/21",
      "Rate": "47265.26041"
  },
  {
      "Date": "12/29/21",
      "Rate": "47246.73804"
  },
  {
      "Date": "12/24/21",
      "Rate": "47304.32283"
  },
  {
      "Date": "12/23/21",
      "Rate": "47781.17427"
  },
  {
      "Date": "12/22/21",
      "Rate": "47651.95506"
  },
  {
      "Date": "12/21/21",
      "Rate": "47955.17029"
  },
  {
      "Date": "12/20/21",
      "Rate": "48025.66183"
  },
  {
      "Date": "12/17/21",
      "Rate": "48454.67116"
  },
  {
      "Date": "12/16/21",
      "Rate": "48010.67744"
  },
  {
      "Date": "12/15/21",
      "Rate": "47711.60338"
  },
  {
      "Date": "12/14/21",
      "Rate": "47814.97381"
  },
  {
      "Date": "12/13/21",
      "Rate": "47743.21946"
  },
  {
      "Date": "12/10/21",
      "Rate": "47296.56255"
  },
  {
      "Date": "12/9/21",
      "Rate": "47557.54422"
  },
  {
      "Date": "12/8/21",
      "Rate": "47610.59278"
  },
  {
      "Date": "12/7/21",
      "Rate": "47361.24447"
  },
  {
      "Date": "12/6/21",
      "Rate": "47348.15782"
  },
  {
      "Date": "12/3/21",
      "Rate": "47071.20761"
  },
  {
      "Date": "12/2/21",
      "Rate": "46956.97631"
  },
  {
      "Date": "12/1/21",
      "Rate": "47265.00291"
  },
  {
      "Date": "11/30/21",
      "Rate": "47645.79975"
  },
  {
      "Date": "11/29/21",
      "Rate": "47563.84416"
  },
  {
      "Date": "11/26/21",
      "Rate": "47852.97801"
  },
  {
      "Date": "11/25/21",
      "Rate": "47059.23914"
  },
  {
      "Date": "11/24/21",
      "Rate": "47065.83185"
  },
  {
      "Date": "11/23/21",
      "Rate": "47224.81173"
  },
  {
      "Date": "11/22/21",
      "Rate": "48349.37124"
  },
  {
      "Date": "11/19/21",
      "Rate": "48703.22581"
  },
  {
      "Date": "11/18/21",
      "Rate": "48691.49015"
  },
  {
      "Date": "11/17/21",
      "Rate": "48728.89469"
  },
  {
      "Date": "11/16/21",
      "Rate": "49151.88628"
  },
  {
      "Date": "11/15/21",
      "Rate": "48911.31413"
  },
  {
      "Date": "11/12/21",
      "Rate": "48546.22833"
  },
  {
      "Date": "11/11/21",
      "Rate": "48721.07797"
  },
  {
      "Date": "11/10/21",
      "Rate": "47820.90689"
  },
  {
      "Date": "11/9/21",
      "Rate": "47702.94714"
  },
  {
      "Date": "11/8/21",
      "Rate": "47375.47752"
  },
  {
      "Date": "11/5/21",
      "Rate": "47124.79938"
  },
  {
      "Date": "11/4/21",
      "Rate": "46727.97404"
  },
  {
      "Date": "11/3/21",
      "Rate": "46863.60606"
  },
  {
      "Date": "11/2/21",
      "Rate": "47149.19487"
  },
  {
      "Date": "11/1/21",
      "Rate": "47176.46519"
  },
  {
      "Date": "10/29/21",
      "Rate": "47508.06187"
  },
  {
      "Date": "10/28/21",
      "Rate": "47441.72913"
  },
  {
      "Date": "10/27/21",
      "Rate": "47170.60971"
  },
  {
      "Date": "10/26/21",
      "Rate": "47646.00081"
  },
  {
      "Date": "10/25/21",
      "Rate": "47618.71285"
  },
  {
      "Date": "10/22/21",
      "Rate": "47414.5999"
  },
  {
      "Date": "10/21/21",
      "Rate": "47122.86284"
  },
  {
      "Date": "10/20/21",
      "Rate": "46918.89804"
  },
  {
      "Date": "10/19/21",
      "Rate": "47237.46803"
  },
  {
      "Date": "10/18/21",
      "Rate": "46820.9457"
  },
  {
      "Date": "10/15/21",
      "Rate": "47144.84912"
  },
  {
      "Date": "10/14/21",
      "Rate": "47558.11919"
  },
  {
      "Date": "10/13/21",
      "Rate": "46961.38203"
  },
  {
      "Date": "10/12/21",
      "Rate": "46824.21912"
  },
  {
      "Date": "10/11/21",
      "Rate": "46604.43394"
  },
  {
      "Date": "10/8/21",
      "Rate": "46610.4023"
  },
  {
      "Date": "10/7/21",
      "Rate": "46416.86097"
  },
  {
      "Date": "10/6/21",
      "Rate": "46150.60583"
  },
  {
      "Date": "10/5/21",
      "Rate": "46216.06025"
  },
  {
      "Date": "10/4/21",
      "Rate": "46059.02044"
  },
  {
      "Date": "10/1/21",
      "Rate": "45932.94062"
  },
  {
      "Date": "9/30/21",
      "Rate": "45334.59497"
  },
  {
      "Date": "9/29/21",
      "Rate": "45686.40364"
  },
  {
      "Date": "9/28/21",
      "Rate": "45543.42757"
  },
  {
      "Date": "9/27/21",
      "Rate": "45567.3645"
  },
  {
      "Date": "9/24/21",
      "Rate": "45690.42488"
  },
  {
      "Date": "9/23/21",
      "Rate": "46113.70924"
  },
  {
      "Date": "9/22/21",
      "Rate": "46232.37094"
  },
  {
      "Date": "9/21/21",
      "Rate": "45942.59158"
  },
  {
      "Date": "9/20/21",
      "Rate": "45672.44925"
  },
  {
      "Date": "9/17/21",
      "Rate": "45882.53056"
  },
  {
      "Date": "9/16/21",
      "Rate": "46234.62848"
  },
  {
      "Date": "9/15/21",
      "Rate": "46713.3177"
  },
  {
      "Date": "9/14/21",
      "Rate": "46464.39267"
  },
  {
      "Date": "9/13/21",
      "Rate": "46422.48717"
  },
  {
      "Date": "9/10/21",
      "Rate": "46684.89744"
  },
  {
      "Date": "9/9/21",
      "Rate": "46575.43167"
  },
  {
      "Date": "9/8/21",
      "Rate": "46806.50452"
  },
  {
      "Date": "9/7/21",
      "Rate": "46897.01758"
  },
  {
      "Date": "9/6/21",
      "Rate": "47024.18738"
  },
  {
      "Date": "9/3/21",
      "Rate": "46657.11565"
  },
  {
      "Date": "9/2/21",
      "Rate": "46753.26196"
  },
  {
      "Date": "9/1/21",
      "Rate": "46673.07713"
  },
  {
      "Date": "8/31/21",
      "Rate": "46701.93125"
  },
  {
      "Date": "8/27/21",
      "Rate": "46520.10441"
  },
  {
      "Date": "8/26/21",
      "Rate": "46647.47526"
  },
  {
      "Date": "8/25/21",
      "Rate": "46958.03101"
  },
  {
      "Date": "8/24/21",
      "Rate": "47149.85097"
  },
  {
      "Date": "8/23/21",
      "Rate": "46730.30918"
  },
  {
      "Date": "8/20/21",
      "Rate": "46722.91928"
  },
  {
      "Date": "8/19/21",
      "Rate": "46864.59726"
  },
  {
      "Date": "8/18/21",
      "Rate": "46854.09266"
  },
  {
      "Date": "8/17/21",
      "Rate": "47074.29761"
  },
  {
      "Date": "8/16/21",
      "Rate": "46452.18434"
  },
  {
      "Date": "8/13/21",
      "Rate": "45978.70156"
  },
  {
      "Date": "8/12/21",
      "Rate": "45997.69661"
  },
  {
      "Date": "8/11/21",
      "Rate": "45417.61936"
  },
  {
      "Date": "8/10/21",
      "Rate": "45409.57336"
  },
  {
      "Date": "8/9/21",
      "Rate": "45742.67271"
  },
  {
      "Date": "8/6/21",
      "Rate": "47100.84834"
  },
  {
      "Date": "8/5/21",
      "Rate": "47341.19473"
  },
  {
      "Date": "8/4/21",
      "Rate": "47433.64433"
  },
  {
      "Date": "8/3/21",
      "Rate": "47390.71941"
  },
  {
      "Date": "8/2/21",
      "Rate": "47394.34911"
  },
  {
      "Date": "7/30/21",
      "Rate": "47948.65165"
  },
  {
      "Date": "7/29/21",
      "Rate": "47659.52133"
  },
  {
      "Date": "7/28/21",
      "Rate": "47278.31531"
  },
  {
      "Date": "7/27/21",
      "Rate": "47234.94242"
  },
  {
      "Date": "7/26/21",
      "Rate": "47411.99668"
  },
  {
      "Date": "7/23/21",
      "Rate": "47351.02559"
  },
  {
      "Date": "7/22/21",
      "Rate": "47203.60147"
  },
  {
      "Date": "7/21/21",
      "Rate": "47488.68234"
  },
  {
      "Date": "7/20/21",
      "Rate": "47726.26325"
  },
  {
      "Date": "7/19/21",
      "Rate": "47702.68611"
  },
  {
      "Date": "7/16/21",
      "Rate": "47940.33404"
  },
  {
      "Date": "7/15/21",
      "Rate": "48174.69444"
  },
  {
      "Date": "7/14/21",
      "Rate": "47673.05596"
  },
  {
      "Date": "7/13/21",
      "Rate": "47547.2936"
  },
  {
      "Date": "7/12/21",
      "Rate": "47396.79712"
  },
  {
      "Date": "7/9/21",
      "Rate": "47416.51528"
  },
  {
      "Date": "7/8/21",
      "Rate": "47739.59329"
  },
  {
      "Date": "7/7/21",
      "Rate": "47608.53631"
  },
  {
      "Date": "7/6/21",
      "Rate": "47675.49339"
  },
  {
      "Date": "7/5/21",
      "Rate": "46932.56318"
  },
  {
      "Date": "7/2/21",
      "Rate": "46881.33477"
  },
  {
      "Date": "7/1/21",
      "Rate": "46658.02571"
  },
  {
      "Date": "6/30/21",
      "Rate": "46104.77433"
  },
  {
      "Date": "6/29/21",
      "Rate": "46342.80322"
  },
  {
      "Date": "6/28/21",
      "Rate": "46493.61717"
  },
  {
      "Date": "6/25/21",
      "Rate": "46647.30948"
  },
  {
      "Date": "6/24/21",
      "Rate": "46595.90822"
  },
  {
      "Date": "6/23/21",
      "Rate": "46651.79986"
  },
  {
      "Date": "6/22/21",
      "Rate": "46652.40304"
  },
  {
      "Date": "6/21/21",
      "Rate": "46635.94067"
  },
  {
      "Date": "6/18/21",
      "Rate": "46869.35572"
  },
  {
      "Date": "6/17/21",
      "Rate": "47368.27457"
  },
  {
      "Date": "6/16/21",
      "Rate": "48048.6252"
  },
  {
      "Date": "6/15/21",
      "Rate": "48229.85943"
  },
  {
      "Date": "6/14/21",
      "Rate": "48004.04593"
  },
  {
      "Date": "6/11/21",
      "Rate": "48888.58357"
  },
  {
      "Date": "6/10/21",
      "Rate": "48506.60153"
  },
  {
      "Date": "6/9/21",
      "Rate": "48671.84254"
  },
  {
      "Date": "6/8/21",
      "Rate": "48690.28025"
  },
  {
      "Date": "6/7/21",
      "Rate": "48338.33754"
  },
  {
      "Date": "6/4/21",
      "Rate": "48062.32914"
  },
  {
      "Date": "6/3/21",
      "Rate": "48758.59539"
  },
  {
      "Date": "6/2/21",
      "Rate": "48804.40219"
  },
  {
      "Date": "6/1/21",
      "Rate": "49024.71649"
  },
  {
      "Date": "5/28/21",
      "Rate": "48349.98148"
  },
  {
      "Date": "5/27/21",
      "Rate": "48576.23591"
  },
  {
      "Date": "5/26/21",
      "Rate": "48884.27662"
  },
  {
      "Date": "5/25/21",
      "Rate": "48336.34808"
  },
  {
      "Date": "5/24/21",
      "Rate": "48257.74352"
  },
  {
      "Date": "5/21/21",
      "Rate": "48259.03808"
  },
  {
      "Date": "5/20/21",
      "Rate": "48162.16159"
  },
  {
      "Date": "5/19/21",
      "Rate": "48022.37429"
  },
  {
      "Date": "5/18/21",
      "Rate": "48116.20311"
  },
  {
      "Date": "5/17/21",
      "Rate": "47838.20879"
  },
  {
      "Date": "5/14/21",
      "Rate": "47396.64897"
  },
  {
      "Date": "5/13/21",
      "Rate": "47124.63359"
  },
  {
      "Date": "5/12/21",
      "Rate": "47629.81358"
  },
  {
      "Date": "5/11/21",
      "Rate": "47558.07333"
  },
  {
      "Date": "5/10/21",
      "Rate": "47497.49378"
  },
  {
      "Date": "5/7/21",
      "Rate": "47058.34671"
  },
  {
      "Date": "5/6/21",
      "Rate": "46547.89679"
  },
  {
      "Date": "5/5/21",
      "Rate": "46267.62024"
  },
  {
      "Date": "5/4/21",
      "Rate": "46455.16499"
  },
  {
      "Date": "4/30/21",
      "Rate": "46169.36454"
  },
  {
      "Date": "4/29/21",
      "Rate": "46373.70677"
  },
  {
      "Date": "4/28/21",
      "Rate": "46349.30069"
  },
  {
      "Date": "4/27/21",
      "Rate": "46857.96928"
  },
  {
      "Date": "4/26/21",
      "Rate": "46902.92245"
  },
  {
      "Date": "4/23/21",
      "Rate": "47208.0072"
  },
  {
      "Date": "4/22/21",
      "Rate": "47317.28249"
  },
  {
      "Date": "4/21/21",
      "Rate": "47390.27143"
  },
  {
      "Date": "4/20/21",
      "Rate": "46962.81769"
  },
  {
      "Date": "4/19/21",
      "Rate": "47226.89289"
  },
  {
      "Date": "4/16/21",
      "Rate": "46464.68544"
  },
  {
      "Date": "4/15/21",
      "Rate": "46088.51302"
  },
  {
      "Date": "4/14/21",
      "Rate": "46125.70239"
  },
  {
      "Date": "4/13/21",
      "Rate": "45722.00568"
  },
  {
      "Date": "4/12/21",
      "Rate": "46085.4195"
  },
  {
      "Date": "4/9/21",
      "Rate": "46078.19186"
  },
  {
      "Date": "4/8/21",
      "Rate": "45871.6838"
  },
  {
      "Date": "4/7/21",
      "Rate": "45532.74661"
  },
  {
      "Date": "4/6/21",
      "Rate": "44879.86737"
  },
  {
      "Date": "4/1/21",
      "Rate": "44293.92053"
  },
  {
      "Date": "3/31/21",
      "Rate": "43493.79354"
  },
  {
      "Date": "3/30/21",
      "Rate": "43956.31669"
  },
  {
      "Date": "3/29/21",
      "Rate": "44158.6483"
  },
  {
      "Date": "3/26/21",
      "Rate": "44258.54777"
  },
  {
      "Date": "3/25/21",
      "Rate": "44326.67243"
  },
  {
      "Date": "3/24/21",
      "Rate": "44411.36175"
  },
  {
      "Date": "3/23/21",
      "Rate": "44488.22378"
  },
  {
      "Date": "3/22/21",
      "Rate": "44247.17544"
  },
  {
      "Date": "3/19/21",
      "Rate": "44395.83414"
  },
  {
      "Date": "3/18/21",
      "Rate": "44449.1014"
  },
  {
      "Date": "3/17/21",
      "Rate": "44478.65042"
  },
  {
      "Date": "3/16/21",
      "Rate": "44302.2593"
  },
  {
      "Date": "3/15/21",
      "Rate": "44210.09189"
  },
  {
      "Date": "3/12/21",
      "Rate": "43695.47964"
  },
  {
      "Date": "3/11/21",
      "Rate": "44546.46114"
  },
  {
      "Date": "3/10/21",
      "Rate": "43924.44664"
  },
  {
      "Date": "3/9/21",
      "Rate": "43759.51251"
  },
  {
      "Date": "3/8/21",
      "Rate": "43843.81382"
  },
  {
      "Date": "3/5/21",
      "Rate": "43795.47435"
  },
  {
      "Date": "3/4/21",
      "Rate": "43929.45555"
  },
  {
      "Date": "3/3/21",
      "Rate": "44449.93386"
  },
  {
      "Date": "3/2/21",
      "Rate": "44685.77576"
  },
  {
      "Date": "3/1/21",
      "Rate": "45213.28066"
  },
  {
      "Date": "2/26/21",
      "Rate": "46007.97192"
  },
  {
      "Date": "2/25/21",
      "Rate": "46022.8258"
  },
  {
      "Date": "2/24/21",
      "Rate": "46138.78904"
  },
  {
      "Date": "2/23/21",
      "Rate": "46239.12591"
  },
  {
      "Date": "2/22/21",
      "Rate": "45974.14064"
  },
  {
      "Date": "2/19/21",
      "Rate": "45449.71869"
  },
  {
      "Date": "2/18/21",
      "Rate": "45680.85857"
  },
  {
      "Date": "2/17/21",
      "Rate": "45937.15233"
  },
  {
      "Date": "2/16/21",
      "Rate": "46870.24463"
  },
  {
      "Date": "2/15/21",
      "Rate": "46567.92889"
  },
  {
      "Date": "2/12/21",
      "Rate": "46546.5599"
  },
  {
      "Date": "2/11/21",
      "Rate": "47236.05355"
  },
  {
      "Date": "2/10/21",
      "Rate": "47363.65015"
  },
  {
      "Date": "2/9/21",
      "Rate": "47463.55668"
  },
  {
      "Date": "2/8/21",
      "Rate": "46592.37024"
  },
  {
      "Date": "2/5/21",
      "Rate": "46451.01677"
  },
  {
      "Date": "2/4/21",
      "Rate": "46632.8683"
  },
  {
      "Date": "2/3/21",
      "Rate": "47133.52969"
  },
  {
      "Date": "2/2/21",
      "Rate": "47543.36761"
  },
  {
      "Date": "2/1/21",
      "Rate": "47960.5919"
  },
  {
      "Date": "1/29/21",
      "Rate": "47631.37269"
  },
  {
      "Date": "1/28/21",
      "Rate": "47325.7165"
  },
  {
      "Date": "1/27/21",
      "Rate": "47567.42447"
  },
  {
      "Date": "1/26/21",
      "Rate": "47718.42184"
  },
  {
      "Date": "1/25/21",
      "Rate": "47780.21835"
  },
  {
      "Date": "1/22/21",
      "Rate": "47727.41318"
  },
  {
      "Date": "1/21/21",
      "Rate": "48075.74031"
  },
  {
      "Date": "1/20/21",
      "Rate": "47707.89256"
  },
  {
      "Date": "1/19/21",
      "Rate": "47561.92173"
  },
  {
      "Date": "1/18/21",
      "Rate": "47349.54408"
  },
  {
      "Date": "1/15/21",
      "Rate": "47823.96162"
  },
  {
      "Date": "1/14/21",
      "Rate": "47405.28757"
  },
  {
      "Date": "1/13/21",
      "Rate": "47748.06963"
  },
  {
      "Date": "1/12/21",
      "Rate": "48115.38828"
  },
  {
      "Date": "1/11/21",
      "Rate": "47899.40563"
  },
  {
      "Date": "1/8/21",
      "Rate": "48944.50343"
  },
  {
      "Date": "1/7/21",
      "Rate": "49518.77458"
  },
  {
      "Date": "1/6/21",
      "Rate": "50500.44622"
  },
  {
      "Date": "1/5/21",
      "Rate": "50228.46611"
  },
  {
      "Date": "1/4/21",
      "Rate": "49789.56948"
  },
  {
      "Date": "12/31/20",
      "Rate": "48732.73955"
  },
  {
      "Date": "12/30/20",
      "Rate": "48459.72592"
  },
  {
      "Date": "12/29/20",
      "Rate": "48530.5702"
  },
  {
      "Date": "12/24/20",
      "Rate": "48558.15799"
  },
  {
      "Date": "12/23/20",
      "Rate": "48598.14459"
  },
  {
      "Date": "12/22/20",
      "Rate": "48816.01792"
  },
  {
      "Date": "12/21/20",
      "Rate": "48765.53731"
  },
  {
      "Date": "12/18/20",
      "Rate": "48738.90192"
  },
  {
      "Date": "12/17/20",
      "Rate": "48550.72223"
  },
  {
      "Date": "12/16/20",
      "Rate": "48347.03963"
  },
  {
      "Date": "12/15/20",
      "Rate": "47829.04108"
  },
  {
      "Date": "12/14/20",
      "Rate": "47301.65259"
  },
  {
      "Date": "12/11/20",
      "Rate": "47695.46906"
  },
  {
      "Date": "12/10/20",
      "Rate": "47719.15554"
  },
  {
      "Date": "12/9/20",
      "Rate": "48412.07076"
  },
  {
      "Date": "12/8/20",
      "Rate": "48467.79308"
  },
  {
      "Date": "12/7/20",
      "Rate": "47663.78948"
  },
  {
      "Date": "12/4/20",
      "Rate": "47852.87571"
  },
  {
      "Date": "12/3/20",
      "Rate": "47794.6313"
  },
  {
      "Date": "12/2/20",
      "Rate": "47666.39976"
  },
  {
      "Date": "12/1/20",
      "Rate": "46568.0206"
  },
  {
      "Date": "11/30/20",
      "Rate": "46250.21253"
  },
  {
      "Date": "11/27/20",
      "Rate": "47192.469"
  },
  {
      "Date": "11/26/20",
      "Rate": "47256.19852"
  },
  {
      "Date": "11/25/20",
      "Rate": "47040.35345"
  },
  {
      "Date": "11/24/20",
      "Rate": "47491.0845"
  },
  {
      "Date": "11/23/20",
      "Rate": "48796.45849"
  },
  {
      "Date": "11/20/20",
      "Rate": "48810.23298"
  },
  {
      "Date": "11/19/20",
      "Rate": "48562.53197"
  },
  {
      "Date": "11/18/20",
      "Rate": "49091.79703"
  },
  {
      "Date": "11/17/20",
      "Rate": "49498.11108"
  },
  {
      "Date": "11/16/20",
      "Rate": "49673.17942"
  },
  {
      "Date": "11/13/20",
      "Rate": "49379.3118"
  },
  {
      "Date": "11/12/20",
      "Rate": "49158.58833"
  },
  {
      "Date": "11/11/20",
      "Rate": "49292.51662"
  },
  {
      "Date": "11/10/20",
      "Rate": "49123.24732"
  },
  {
      "Date": "11/9/20",
      "Rate": "51136.95127"
  },
  {
      "Date": "11/6/20",
      "Rate": "50826.24385"
  },
  {
      "Date": "11/5/20",
      "Rate": "50007.38637"
  },
  {
      "Date": "11/4/20",
      "Rate": "49438.91427"
  },
  {
      "Date": "11/3/20",
      "Rate": "50040.98838"
  },
  {
      "Date": "11/2/20",
      "Rate": "49587.79167"
  },
  {
      "Date": "10/30/20",
      "Rate": "49352.60587"
  },
  {
      "Date": "10/29/20",
      "Rate": "49251.13318"
  },
  {
      "Date": "10/28/20",
      "Rate": "49655.18969"
  },
  {
      "Date": "10/27/20",
      "Rate": "49384.99797"
  },
  {
      "Date": "10/26/20",
      "Rate": "49613.18189"
  },
  {
      "Date": "10/23/20",
      "Rate": "49766.11228"
  },
  {
      "Date": "10/22/20",
      "Rate": "49808.55394"
  },
  {
      "Date": "10/21/20",
      "Rate": "49809.64391"
  },
  {
      "Date": "10/20/20",
      "Rate": "49406.26819"
  },
  {
      "Date": "10/19/20",
      "Rate": "49420.35309"
  },
  {
      "Date": "10/16/20",
      "Rate": "49428.63543"
  },
  {
      "Date": "10/15/20",
      "Rate": "48999.89771"
  },
  {
      "Date": "10/14/20",
      "Rate": "49016.8398"
  },
  {
      "Date": "10/13/20",
      "Rate": "49741.67093"
  },
  {
      "Date": "10/12/20",
      "Rate": "49679.00316"
  },
  {
      "Date": "10/9/20",
      "Rate": "49234.67786"
  },
  {
      "Date": "10/8/20",
      "Rate": "48922.11503"
  },
  {
      "Date": "10/7/20",
      "Rate": "48841.12242"
  },
  {
      "Date": "10/6/20",
      "Rate": "49553.1773"
  },
  {
      "Date": "10/5/20",
      "Rate": "48984.35951"
  },
  {
      "Date": "10/2/20",
      "Rate": "49292.90816"
  },
  {
      "Date": "10/1/20",
      "Rate": "48965.562"
  },
  {
      "Date": "9/30/20",
      "Rate": "48876.18829"
  },
  {
      "Date": "9/29/20",
      "Rate": "48976.79677"
  },
  {
      "Date": "9/28/20",
      "Rate": "48184.63818"
  },
  {
      "Date": "9/25/20",
      "Rate": "48634.09584"
  },
  {
      "Date": "9/24/20",
      "Rate": "48124.59126"
  },
  {
      "Date": "9/23/20",
      "Rate": "48985.17081"
  },
  {
      "Date": "9/22/20",
      "Rate": "49362.3168"
  },
  {
      "Date": "9/21/20",
      "Rate": "50116.08318"
  },
  {
      "Date": "9/18/20",
      "Rate": "50704.07591"
  },
  {
      "Date": "9/17/20",
      "Rate": "50275.70151"
  },
  {
      "Date": "9/16/20",
      "Rate": "50907.23646"
  },
  {
      "Date": "9/15/20",
      "Rate": "50978.39821"
  },
  {
      "Date": "9/14/20",
      "Rate": "50283.50412"
  },
  {
      "Date": "9/11/20",
      "Rate": "50369.88659"
  },
  {
      "Date": "9/10/20",
      "Rate": "50450.78396"
  },
  {
      "Date": "9/9/20",
      "Rate": "49877.73682"
  },
  {
      "Date": "9/8/20",
      "Rate": "49888.37898"
  },
  {
      "Date": "9/7/20",
      "Rate": "49959.36436"
  },
  {
      "Date": "9/4/20",
      "Rate": "50058.62537"
  },
  {
      "Date": "9/3/20",
      "Rate": "50113.1766"
  },
  {
      "Date": "9/2/20",
      "Rate": "50885.8322"
  },
  {
      "Date": "9/1/20",
      "Rate": "51238.47334"
  },
  {
      "Date": "8/28/20",
      "Rate": "50498.38621"
  },
  {
      "Date": "8/27/20",
      "Rate": "50494.83412"
  },
  {
      "Date": "8/26/20",
      "Rate": "50237.50331"
  },
  {
      "Date": "8/25/20",
      "Rate": "50420.85046"
  },
  {
      "Date": "8/24/20",
      "Rate": "50952.172"
  },
  {
      "Date": "8/21/20",
      "Rate": "51112.34061"
  },
  {
      "Date": "8/20/20",
      "Rate": "51026.21563"
  },
  {
      "Date": "8/19/20",
      "Rate": "52613.76038"
  },
  {
      "Date": "8/18/20",
      "Rate": "52780.65222"
  },
  {
      "Date": "8/17/20",
      "Rate": "51420.55063"
  },
  {
      "Date": "8/14/20",
      "Rate": "51419.53474"
  },
  {
      "Date": "8/13/20",
      "Rate": "50971.46687"
  },
  {
      "Date": "8/12/20",
      "Rate": "50937.61442"
  },
  {
      "Date": "8/11/20",
      "Rate": "52535.45212"
  },
  {
      "Date": "8/10/20",
      "Rate": "53646.48406"
  },
  {
      "Date": "8/7/20",
      "Rate": "54564.32389"
  },
  {
      "Date": "8/6/20",
      "Rate": "54153.44891"
  },
  {
      "Date": "8/5/20",
      "Rate": "53714.37591"
  },
  {
      "Date": "8/4/20",
      "Rate": "52218.86453"
  },
  {
      "Date": "8/3/20",
      "Rate": "52349.51586"
  },
  {
      "Date": "7/31/20",
      "Rate": "52187.12147"
  },
  {
      "Date": "7/30/20",
      "Rate": "51547.25127"
  },
  {
      "Date": "7/29/20",
      "Rate": "51614.01788"
  },
  {
      "Date": "7/28/20",
      "Rate": "50995.43907"
  },
  {
      "Date": "7/27/20",
      "Rate": "51194.39144"
  },
  {
      "Date": "7/24/20",
      "Rate": "49956.90224"
  },
  {
      "Date": "7/23/20",
      "Rate": "49665.36976"
  },
  {
      "Date": "7/22/20",
      "Rate": "48695.21861"
  },
  {
      "Date": "7/21/20",
      "Rate": "47921.42366"
  },
  {
      "Date": "7/20/20",
      "Rate": "47754.76816"
  },
  {
      "Date": "7/17/20",
      "Rate": "47655.96924"
  },
  {
      "Date": "7/16/20",
      "Rate": "47849.79629"
  },
  {
      "Date": "7/15/20",
      "Rate": "47950.29542"
  },
  {
      "Date": "7/14/20",
      "Rate": "47830.17337"
  },
  {
      "Date": "7/13/20",
      "Rate": "47939.36048"
  },
  {
      "Date": "7/10/20",
      "Rate": "47890.67179"
  },
  {
      "Date": "7/9/20",
      "Rate": "48045.15776"
  },
  {
      "Date": "7/8/20",
      "Rate": "47539.22291"
  },
  {
      "Date": "7/7/20",
      "Rate": "46873.08771"
  },
  {
      "Date": "7/6/20",
      "Rate": "46712.0443"
  },
  {
      "Date": "7/3/20",
      "Rate": "46723.7588"
  },
  {
      "Date": "7/2/20",
      "Rate": "46737.6638"
  },
  {
      "Date": "7/1/20",
      "Rate": "47606.52216"
  },
  {
      "Date": "6/30/20",
      "Rate": "47189.83051"
  },
  {
      "Date": "6/29/20",
      "Rate": "47109.87143"
  },
  {
      "Date": "6/26/20",
      "Rate": "46985.49886"
  },
  {
      "Date": "6/25/20",
      "Rate": "46868.19873"
  },
  {
      "Date": "6/24/20",
      "Rate": "47391.35787"
  },
  {
      "Date": "6/23/20",
      "Rate": "46824.90344"
  },
  {
      "Date": "6/22/20",
      "Rate": "46613.88032"
  },
  {
      "Date": "6/19/20",
      "Rate": "46501.49738"
  },
  {
      "Date": "6/18/20",
      "Rate": "46699.86772"
  },
  {
      "Date": "6/17/20",
      "Rate": "46158.32378"
  },
  {
      "Date": "6/16/20",
      "Rate": "46470.7561"
  },
  {
      "Date": "6/15/20",
      "Rate": "45835.60557"
  },
  {
      "Date": "6/12/20",
      "Rate": "46540.94781"
  },
  {
      "Date": "6/11/20",
      "Rate": "46549.22309"
  },
  {
      "Date": "6/10/20",
      "Rate": "45855.70116"
  },
  {
      "Date": "6/9/20",
      "Rate": "45463.12633"
  },
  {
      "Date": "6/8/20",
      "Rate": "45026.85409"
  },
  {
      "Date": "6/5/20",
      "Rate": "45582.77218"
  },
  {
      "Date": "6/4/20",
      "Rate": "45418.91744"
  },
  {
      "Date": "6/3/20",
      "Rate": "45641.16122"
  },
  {
      "Date": "6/2/20",
      "Rate": "46117.4377"
  },
  {
      "Date": "6/1/20",
      "Rate": "46243.17889"
  },
  {
      "Date": "5/29/20",
      "Rate": "46006.88548"
  },
  {
      "Date": "5/28/20",
      "Rate": "45979.30475"
  },
  {
      "Date": "5/27/20",
      "Rate": "45651.60938"
  },
  {
      "Date": "5/26/20",
      "Rate": "45855.24965"
  },
  {
      "Date": "5/22/20",
      "Rate": "46439.0342"
  },
  {
      "Date": "5/21/20",
      "Rate": "46230.20865"
  },
  {
      "Date": "5/20/20",
      "Rate": "46641.66211"
  },
  {
      "Date": "5/19/20",
      "Rate": "46308.85554"
  },
  {
      "Date": "5/18/20",
      "Rate": "46919.66349"
  },
  {
      "Date": "5/15/20",
      "Rate": "46402.70199"
  },
  {
      "Date": "5/14/20",
      "Rate": "45717.34598"
  },
  {
      "Date": "5/13/20",
      "Rate": "45194.80061"
  },
  {
      "Date": "5/12/20",
      "Rate": "45163.25156"
  },
  {
      "Date": "5/11/20",
      "Rate": "45473.75439"
  },
  {
      "Date": "5/7/20",
      "Rate": "45086.55885"
  },
  {
      "Date": "5/6/20",
      "Rate": "45577.7668"
  },
  {
      "Date": "5/5/20",
      "Rate": "45282.73515"
  },
  {
      "Date": "5/4/20",
      "Rate": "45468.73843"
  },
  {
      "Date": "5/1/20",
      "Rate": "44772.72968"
  },
  {
      "Date": "4/30/20",
      "Rate": "45550.69402"
  },
  {
      "Date": "4/29/20",
      "Rate": "45344.29531"
  },
  {
      "Date": "4/28/20",
      "Rate": "45818.79751"
  },
  {
      "Date": "4/27/20",
      "Rate": "46143.59336"
  },
  {
      "Date": "4/24/20",
      "Rate": "46539.39223"
  },
  {
      "Date": "4/23/20",
      "Rate": "46234.86834"
  },
  {
      "Date": "4/22/20",
      "Rate": "45913.08136"
  },
  {
      "Date": "4/21/20",
      "Rate": "45610.76562"
  },
  {
      "Date": "4/20/20",
      "Rate": "45511.39174"
  },
  {
      "Date": "4/17/20",
      "Rate": "45727.50137"
  },
  {
      "Date": "4/16/20",
      "Rate": "46542.13302"
  },
  {
      "Date": "4/15/20",
      "Rate": "46207.10418"
  },
  {
      "Date": "4/14/20",
      "Rate": "45999.81305"
  },
  {
      "Date": "4/9/20",
      "Rate": "44506.23115"
  },
  {
      "Date": "4/8/20",
      "Rate": "44184.55705"
  },
  {
      "Date": "4/7/20",
      "Rate": "44096.44967"
  },
  {
      "Date": "4/6/20",
      "Rate": "43871.75788"
  },
  {
      "Date": "4/3/20",
      "Rate": "43318.0303"
  },
  {
      "Date": "4/2/20",
      "Rate": "42711.17656"
  },
  {
      "Date": "4/1/20",
      "Rate": "43062.09986"
  },
  {
      "Date": "3/31/20",
      "Rate": "42663.33092"
  },
  {
      "Date": "3/30/20",
      "Rate": "43180.47585"
  },
  {
      "Date": "3/27/20",
      "Rate": "43025.46429"
  },
  {
      "Date": "3/26/20",
      "Rate": "42742.53514"
  },
  {
      "Date": "3/25/20",
      "Rate": "43383.04732"
  },
  {
      "Date": "3/24/20",
      "Rate": "42940.42223"
  },
  {
      "Date": "3/23/20",
      "Rate": "40235.70786"
  },
  {
      "Date": "3/20/20",
      "Rate": "40081.16545"
  },
  {
      "Date": "3/19/20",
      "Rate": "39167.6855"
  },
  {
      "Date": "3/18/20",
      "Rate": "39873.04891"
  },
  {
      "Date": "3/17/20",
      "Rate": "38469.00298"
  },
  {
      "Date": "3/16/20",
      "Rate": "39334.15404"
  },
  {
      "Date": "3/13/20",
      "Rate": "41463.21099"
  },
  {
      "Date": "3/12/20",
      "Rate": "42859.04866"
  },
  {
      "Date": "3/11/20",
      "Rate": "43406.97014"
  },
  {
      "Date": "3/10/20",
      "Rate": "43179.68571"
  },
  {
      "Date": "3/9/20",
      "Rate": "44058.39609"
  },
  {
      "Date": "3/6/20",
      "Rate": "44148.40826"
  },
  {
      "Date": "3/5/20",
      "Rate": "43016.34597"
  },
  {
      "Date": "3/4/20",
      "Rate": "42710.43581"
  },
  {
      "Date": "3/3/20",
      "Rate": "41190.22558"
  },
  {
      "Date": "3/2/20",
      "Rate": "41250.78044"
  },
  {
      "Date": "2/28/20",
      "Rate": "41572.18999"
  },
  {
      "Date": "2/27/20",
      "Rate": "41525.54366"
  },
  {
      "Date": "2/26/20",
      "Rate": "41652.59705"
  },
  {
      "Date": "2/25/20",
      "Rate": "41963.69954"
  },
  {
      "Date": "2/24/20",
      "Rate": "42802.76548"
  },
  {
      "Date": "2/21/20",
      "Rate": "41382.60992"
  },
  {
      "Date": "2/20/20",
      "Rate": "40870.9501"
  },
  {
      "Date": "2/19/20",
      "Rate": "40630.79772"
  },
  {
      "Date": "2/18/20",
      "Rate": "40096.95762"
  },
  {
      "Date": "2/17/20",
      "Rate": "39769.35396"
  },
  {
      "Date": "2/14/20",
      "Rate": "39776.71211"
  },
  {
      "Date": "2/13/20",
      "Rate": "39607.08654"
  },
  {
      "Date": "2/12/20",
      "Rate": "39404.26463"
  },
  {
      "Date": "2/11/20",
      "Rate": "39418.25782"
  },
  {
      "Date": "2/10/20",
      "Rate": "39589.58359"
  },
  {
      "Date": "2/7/20",
      "Rate": "39556.43309"
  },
  {
      "Date": "2/6/20",
      "Rate": "39292.47782"
  },
  {
      "Date": "2/5/20",
      "Rate": "38978.70156"
  },
  {
      "Date": "2/4/20",
      "Rate": "39411.7674"
  },
  {
      "Date": "2/3/20",
      "Rate": "39703.0706"
  },
  {
      "Date": "1/31/20",
      "Rate": "39906.71088"
  },
  {
      "Date": "1/30/20",
      "Rate": "39919.32133"
  },
  {
      "Date": "1/29/20",
      "Rate": "39543.94963"
  },
  {
      "Date": "1/28/20",
      "Rate": "39672.61856"
  },
  {
      "Date": "1/27/20",
      "Rate": "39896.93998"
  },
  {
      "Date": "1/24/20",
      "Rate": "39284.93977"
  },
  {
      "Date": "1/23/20",
      "Rate": "39138.35517"
  },
  {
      "Date": "1/22/20",
      "Rate": "39068.87952"
  },
  {
      "Date": "1/21/20",
      "Rate": "39070.79843"
  },
  {
      "Date": "1/20/20",
      "Rate": "39072.68911"
  },
  {
      "Date": "1/17/20",
      "Rate": "39019.53474"
  },
  {
      "Date": "1/16/20",
      "Rate": "38937.02534"
  },
  {
      "Date": "1/15/20",
      "Rate": "38704.30166"
  },
  {
      "Date": "1/14/20",
      "Rate": "38612.71275"
  },
  {
      "Date": "1/13/20",
      "Rate": "38680.68573"
  },
  {
      "Date": "1/10/20",
      "Rate": "38750.18607"
  },
  {
      "Date": "1/9/20",
      "Rate": "38871.64853"
  },
  {
      "Date": "1/8/20",
      "Rate": "39844.13482"
  },
  {
      "Date": "1/7/20",
      "Rate": "39703.59971"
  },
  {
      "Date": "1/6/20",
      "Rate": "39971.60091"
  },
  {
      "Date": "1/3/20",
      "Rate": "39154.8246"
  },
  {
      "Date": "1/2/20",
      "Rate": "38269.45802"
  },
  {
      "Date": "12/31/19",
      "Rate": "38263.42969"
  },
  {
      "Date": "12/30/19",
      "Rate": "38005.43219"
  },
  {
      "Date": "12/27/19",
      "Rate": "38097.89591"
  },
  {
      "Date": "12/24/19",
      "Rate": "37484.89039"
  },
  {
      "Date": "12/23/19",
      "Rate": "37287.53241"
  },
  {
      "Date": "12/20/19",
      "Rate": "37037.76433"
  },
  {
      "Date": "12/19/19",
      "Rate": "37039.29875"
  },
  {
      "Date": "12/18/19",
      "Rate": "37049.10845"
  },
  {
      "Date": "12/17/19",
      "Rate": "37037.0518"
  },
  {
      "Date": "12/16/19",
      "Rate": "36966.398"
  },
  {
      "Date": "12/13/19",
      "Rate": "36681.61343"
  },
  {
      "Date": "12/12/19",
      "Rate": "36724.73589"
  },
  {
      "Date": "12/11/19",
      "Rate": "36642.0854"
  },
  {
      "Date": "12/10/19",
      "Rate": "36594.28209"
  },
  {
      "Date": "12/9/19",
      "Rate": "36656.72763"
  },
  {
      "Date": "12/6/19",
      "Rate": "37086.23785"
  },
  {
      "Date": "12/5/19",
      "Rate": "37049.00263"
  },
  {
      "Date": "12/4/19",
      "Rate": "37218.10614"
  },
  {
      "Date": "12/3/19",
      "Rate": "37206.18353"
  },
  {
      "Date": "12/2/19",
      "Rate": "36838.38516"
  },
  {
      "Date": "11/29/19",
      "Rate": "36869.16524"
  },
  {
      "Date": "11/28/19",
      "Rate": "36774.65917"
  },
  {
      "Date": "11/27/19",
      "Rate": "36692.7071"
  },
  {
      "Date": "11/26/19",
      "Rate": "36760.52488"
  },
  {
      "Date": "11/25/19",
      "Rate": "36906.46043"
  },
  {
      "Date": "11/22/19",
      "Rate": "37240.48043"
  },
  {
      "Date": "11/21/19",
      "Rate": "37176.83204"
  },
  {
      "Date": "11/20/19",
      "Rate": "37380.63458"
  },
  {
      "Date": "11/19/19",
      "Rate": "37119.72345"
  },
  {
      "Date": "11/18/19",
      "Rate": "37003.62969"
  },
  {
      "Date": "11/15/19",
      "Rate": "37028.12043"
  },
  {
      "Date": "11/14/19",
      "Rate": "37276.48107"
  },
  {
      "Date": "11/13/19",
      "Rate": "37235.00591"
  },
  {
      "Date": "11/12/19",
      "Rate": "36838.32519"
  },
  {
      "Date": "11/11/19",
      "Rate": "36996.64544"
  },
  {
      "Date": "11/8/19",
      "Rate": "36891.28203"
  },
  {
      "Date": "11/7/19",
      "Rate": "37140.58449"
  },
  {
      "Date": "11/6/19",
      "Rate": "37319.94568"
  },
  {
      "Date": "11/5/19",
      "Rate": "37571.87957"
  },
  {
      "Date": "11/4/19",
      "Rate": "37699.25748"
  },
  {
      "Date": "11/1/19",
      "Rate": "37572.81434"
  },
  {
      "Date": "10/31/19",
      "Rate": "37762.15454"
  },
  {
      "Date": "10/30/19",
      "Rate": "37309.86084"
  },
  {
      "Date": "10/29/19",
      "Rate": "37316.51352"
  },
  {
      "Date": "10/28/19",
      "Rate": "37545.42055"
  },
  {
      "Date": "10/25/19",
      "Rate": "37588.25376"
  },
  {
      "Date": "10/24/19",
      "Rate": "37264.71014"
  },
  {
      "Date": "10/23/19",
      "Rate": "37269.41216"
  },
  {
      "Date": "10/22/19",
      "Rate": "37135.74843"
  },
  {
      "Date": "10/21/19",
      "Rate": "37288.47422"
  },
  {
      "Date": "10/18/19",
      "Rate": "37299.65608"
  },
  {
      "Date": "10/17/19",
      "Rate": "37252.49828"
  },
  {
      "Date": "10/16/19",
      "Rate": "37331.37092"
  },
  {
      "Date": "10/15/19",
      "Rate": "37698.68252"
  },
  {
      "Date": "10/14/19",
      "Rate": "37528.79945"
  },
  {
      "Date": "10/11/19",
      "Rate": "37473.1512"
  },
  {
      "Date": "10/10/19",
      "Rate": "37709.38817"
  },
  {
      "Date": "10/9/19",
      "Rate": "37706.30875"
  },
  {
      "Date": "10/8/19",
      "Rate": "37686.37895"
  },
  {
      "Date": "10/7/19",
      "Rate": "37648.99557"
  },
  {
      "Date": "10/4/19",
      "Rate": "37716.06907"
  },
  {
      "Date": "10/3/19",
      "Rate": "37683.15843"
  },
  {
      "Date": "10/2/19",
      "Rate": "37276.22357"
  },
  {
      "Date": "10/1/19",
      "Rate": "36756.57772"
  },
  {
      "Date": "9/30/19",
      "Rate": "37082.29775"
  },
  {
      "Date": "9/27/19",
      "Rate": "37286.98566"
  },
  {
      "Date": "9/26/19",
      "Rate": "37736.18582"
  },
  {
      "Date": "9/25/19",
      "Rate": "38355.8934"
  },
  {
      "Date": "9/24/19",
      "Rate": "38050.08907"
  },
  {
      "Date": "9/23/19",
      "Rate": "37949.20193"
  },
  {
      "Date": "9/20/19",
      "Rate": "37686.72463"
  },
  {
      "Date": "9/19/19",
      "Rate": "37715.26129"
  },
  {
      "Date": "9/18/19",
      "Rate": "37689.72292"
  },
  {
      "Date": "9/17/19",
      "Rate": "37824.96693"
  },
  {
      "Date": "9/16/19",
      "Rate": "37961.63248"
  },
  {
      "Date": "9/13/19",
      "Rate": "37718.31249"
  },
  {
      "Date": "9/12/19",
      "Rate": "37629.54902"
  },
  {
      "Date": "9/11/19",
      "Rate": "37688.53772"
  },
  {
      "Date": "9/10/19",
      "Rate": "37884.69285"
  },
  {
      "Date": "9/9/19",
      "Rate": "38200.01058"
  },
  {
      "Date": "9/6/19",
      "Rate": "38003.97538"
  },
  {
      "Date": "9/5/19",
      "Rate": "39086.9645"
  },
  {
      "Date": "9/4/19",
      "Rate": "39078.01196"
  },
  {
      "Date": "9/3/19",
      "Rate": "39018.97388"
  },
  {
      "Date": "9/2/19",
      "Rate": "38686.24843"
  },
  {
      "Date": "8/30/19",
      "Rate": "38510.66156"
  },
  {
      "Date": "8/29/19",
      "Rate": "38842.15595"
  },
  {
      "Date": "8/28/19",
      "Rate": "39034.58968"
  },
  {
      "Date": "8/27/19",
      "Rate": "38649.22838"
  },
  {
      "Date": "8/23/19",
      "Rate": "37825.73943"
  },
  {
      "Date": "8/22/19",
      "Rate": "38029.9582"
  },
  {
      "Date": "8/21/19",
      "Rate": "37781.31889"
  },
  {
      "Date": "8/20/19",
      "Rate": "37903.54327"
  },
  {
      "Date": "8/19/19",
      "Rate": "37832.94238"
  },
  {
      "Date": "8/16/19",
      "Rate": "37923.38842"
  },
  {
      "Date": "8/15/19",
      "Rate": "38287.4795"
  },
  {
      "Date": "8/14/19",
      "Rate": "37881.1972"
  },
  {
      "Date": "8/13/19",
      "Rate": "38276.5622"
  },
  {
      "Date": "8/12/19",
      "Rate": "37748.08727"
  },
  {
      "Date": "8/9/19",
      "Rate": "37669.94127"
  },
  {
      "Date": "8/8/19",
      "Rate": "37207.48161"
  },
  {
      "Date": "8/7/19",
      "Rate": "37307.87492"
  },
  {
      "Date": "8/6/19",
      "Rate": "36632.7519"
  },
  {
      "Date": "8/5/19",
      "Rate": "36474.16357"
  },
  {
      "Date": "8/2/19",
      "Rate": "35264.63959"
  },
  {
      "Date": "8/1/19",
      "Rate": "34158.76118"
  },
  {
      "Date": "7/31/19",
      "Rate": "34754.40131"
  },
  {
      "Date": "7/30/19",
      "Rate": "34715.72691"
  },
  {
      "Date": "7/29/19",
      "Rate": "34402.01062"
  },
  {
      "Date": "7/26/19",
      "Rate": "34454.07856"
  },
  {
      "Date": "7/25/19",
      "Rate": "34737.03945"
  },
  {
      "Date": "7/24/19",
      "Rate": "34671.69791"
  },
  {
      "Date": "7/23/19",
      "Rate": "34517.82571"
  },
  {
      "Date": "7/22/19",
      "Rate": "34621.78169"
  },
  {
      "Date": "7/19/19",
      "Rate": "34913.8327"
  },
  {
      "Date": "7/18/19",
      "Rate": "34509.43403"
  },
  {
      "Date": "7/17/19",
      "Rate": "34018.05323"
  },
  {
      "Date": "7/16/19",
      "Rate": "34320.52417"
  },
  {
      "Date": "7/15/19",
      "Rate": "34236.63557"
  },
  {
      "Date": "7/12/19",
      "Rate": "33998.76541"
  },
  {
      "Date": "7/11/19",
      "Rate": "34356.04861"
  },
  {
      "Date": "7/10/19",
      "Rate": "33662.04695"
  },
  {
      "Date": "7/9/19",
      "Rate": "33575.87612"
  },
  {
      "Date": "7/8/19",
      "Rate": "33994.9664"
  },
  {
      "Date": "7/5/19",
      "Rate": "34204.63853"
  },
  {
      "Date": "7/4/19",
      "Rate": "34226.19094"
  },
  {
      "Date": "7/3/19",
      "Rate": "34582.28187"
  },
  {
      "Date": "7/2/19",
      "Rate": "33851.20725"
  },
  {
      "Date": "7/1/19",
      "Rate": "33786.16554"
  },
  {
      "Date": "6/28/19",
      "Rate": "34359.51251"
  },
  {
      "Date": "6/27/19",
      "Rate": "34145.51227"
  },
  {
      "Date": "6/26/19",
      "Rate": "34332.00586"
  },
  {
      "Date": "6/25/19",
      "Rate": "34933.87538"
  },
  {
      "Date": "6/24/19",
      "Rate": "34345.54049"
  },
  {
      "Date": "6/21/19",
      "Rate": "34069.04531"
  },
  {
      "Date": "6/20/19",
      "Rate": "33896.39676"
  },
  {
      "Date": "6/19/19",
      "Rate": "33006.81141"
  },
  {
      "Date": "6/18/19",
      "Rate": "32982.6064"
  },
  {
      "Date": "6/17/19",
      "Rate": "32851.61643"
  },
  {
      "Date": "6/14/19",
      "Rate": "33314.98615"
  },
  {
      "Date": "6/13/19",
      "Rate": "32771.49862"
  },
  {
      "Date": "6/12/19",
      "Rate": "32709.1342"
  },
  {
      "Date": "6/11/19",
      "Rate": "32387.99979"
  },
  {
      "Date": "6/10/19",
      "Rate": "32571.28697"
  },
  {
      "Date": "6/7/19",
      "Rate": "32635.15406"
  },
  {
      "Date": "6/6/19",
      "Rate": "32623.32316"
  },
  {
      "Date": "6/5/19",
      "Rate": "32745.20538"
  },
  {
      "Date": "6/4/19",
      "Rate": "32401.92596"
  },
  {
      "Date": "6/3/19",
      "Rate": "32062.93938"
  },
  {
      "Date": "5/31/19",
      "Rate": "31812.58223"
  },
  {
      "Date": "5/30/19",
      "Rate": "31425.98635"
  },
  {
      "Date": "5/29/19",
      "Rate": "31634.42036"
  },
  {
      "Date": "5/28/19",
      "Rate": "31523.81171"
  },
  {
      "Date": "5/24/19",
      "Rate": "31365.88652"
  },
  {
      "Date": "5/23/19",
      "Rate": "31362.81769"
  },
  {
      "Date": "5/22/19",
      "Rate": "31325.89993"
  },
  {
      "Date": "5/21/19",
      "Rate": "31348.47528"
  },
  {
      "Date": "5/20/19",
      "Rate": "31390.59595"
  },
  {
      "Date": "5/17/19",
      "Rate": "31896.79183"
  },
  {
      "Date": "5/16/19",
      "Rate": "32078.56576"
  },
  {
      "Date": "5/15/19",
      "Rate": "32183.50588"
  },
  {
      "Date": "5/14/19",
      "Rate": "32183.6117"
  },
  {
      "Date": "5/13/19",
      "Rate": "31928.59486"
  },
  {
      "Date": "5/10/19",
      "Rate": "31746.08723"
  },
  {
      "Date": "5/9/19",
      "Rate": "31741.61096"
  },
  {
      "Date": "5/8/19",
      "Rate": "31663.99055"
  },
  {
      "Date": "5/7/19",
      "Rate": "31453.87044"
  },
  {
      "Date": "5/3/19",
      "Rate": "30975.96783"
  },
  {
      "Date": "5/2/19",
      "Rate": "31130.39736"
  },
  {
      "Date": "5/1/19",
      "Rate": "31451.6764"
  },
  {
      "Date": "4/30/19",
      "Rate": "31548.15429"
  },
  {
      "Date": "4/29/19",
      "Rate": "31586.22198"
  },
  {
      "Date": "4/26/19",
      "Rate": "31552.8034"
  },
  {
      "Date": "4/25/19",
      "Rate": "31669.60264"
  },
  {
      "Date": "4/24/19",
      "Rate": "31454.65352"
  },
  {
      "Date": "4/23/19",
      "Rate": "31335.06058"
  },
  {
      "Date": "4/18/19",
      "Rate": "31260.61835"
  },
  {
      "Date": "4/17/19",
      "Rate": "31250.14551"
  },
  {
      "Date": "4/16/19",
      "Rate": "31484.57997"
  },
  {
      "Date": "4/15/19",
      "Rate": "31494.6119"
  },
  {
      "Date": "4/12/19",
      "Rate": "31646.96379"
  },
  {
      "Date": "4/11/19",
      "Rate": "31765.45265"
  },
  {
      "Date": "4/10/19",
      "Rate": "31821.11148"
  },
  {
      "Date": "4/9/19",
      "Rate": "31812.76918"
  },
  {
      "Date": "4/8/19",
      "Rate": "31841.27762"
  },
  {
      "Date": "4/5/19",
      "Rate": "31451.98681"
  },
  {
      "Date": "4/4/19",
      "Rate": "31416.04614"
  },
  {
      "Date": "4/3/19",
      "Rate": "31197.01935"
  },
  {
      "Date": "4/2/19",
      "Rate": "31292.67888"
  },
  {
      "Date": "4/1/19",
      "Rate": "31539.77672"
  },
  {
      "Date": "3/29/19",
      "Rate": "31572.61327"
  },
  {
      "Date": "3/28/19",
      "Rate": "31883.62052"
  },
  {
      "Date": "3/27/19",
      "Rate": "32121.78698"
  },
  {
      "Date": "3/26/19",
      "Rate": "32004.88898"
  },
  {
      "Date": "3/25/19",
      "Rate": "32074.73148"
  },
  {
      "Date": "3/22/19",
      "Rate": "32011.75329"
  },
  {
      "Date": "3/21/19",
      "Rate": "31963.09282"
  },
  {
      "Date": "3/20/19",
      "Rate": "31619.01268"
  },
  {
      "Date": "3/19/19",
      "Rate": "31862.23037"
  },
  {
      "Date": "3/18/19",
      "Rate": "31612.1272"
  },
  {
      "Date": "3/15/19",
      "Rate": "31685.92391"
  },
  {
      "Date": "3/14/19",
      "Rate": "31782.15489"
  },
  {
      "Date": "3/13/19",
      "Rate": "32106.53451"
  },
  {
      "Date": "3/12/19",
      "Rate": "31845.23537"
  },
  {
      "Date": "3/11/19",
      "Rate": "31912.03725"
  },
  {
      "Date": "3/8/19",
      "Rate": "31950.45415"
  },
  {
      "Date": "3/7/19",
      "Rate": "31819.3372"
  },
  {
      "Date": "3/6/19",
      "Rate": "31785.84102"
  },
  {
      "Date": "3/5/19",
      "Rate": "32000.19048"
  },
  {
      "Date": "3/4/19",
      "Rate": "32207.02658"
  },
  {
      "Date": "3/1/19",
      "Rate": "32790.91342"
  },
  {
      "Date": "2/28/19",
      "Rate": "33117.19783"
  },
  {
      "Date": "2/27/19",
      "Rate": "33281.38062"
  },
  {
      "Date": "2/26/19",
      "Rate": "33241.3129"
  },
  {
      "Date": "2/25/19",
      "Rate": "33215.27011"
  },
  {
      "Date": "2/22/19",
      "Rate": "33119.79753"
  },
  {
      "Date": "2/21/19",
      "Rate": "33539.07124"
  },
  {
      "Date": "2/20/19",
      "Rate": "33690.86227"
  },
  {
      "Date": "2/19/19",
      "Rate": "33410.28237"
  },
  {
      "Date": "2/18/19",
      "Rate": "33342.88083"
  },
  {
      "Date": "2/15/19",
      "Rate": "33183.03674"
  },
  {
      "Date": "2/14/19",
      "Rate": "32714.73218"
  },
  {
      "Date": "2/13/19",
      "Rate": "32774.47221"
  },
  {
      "Date": "2/12/19",
      "Rate": "32652.93921"
  },
  {
      "Date": "2/11/19",
      "Rate": "32807.83788"
  },
  {
      "Date": "2/8/19",
      "Rate": "32932.77836"
  },
  {
      "Date": "2/7/19",
      "Rate": "32870.67144"
  },
  {
      "Date": "2/6/19",
      "Rate": "33150.51059"
  },
  {
      "Date": "2/5/19",
      "Rate": "33161.12453"
  },
  {
      "Date": "2/4/19",
      "Rate": "33142.06247"
  },
  {
      "Date": "2/1/19",
      "Rate": "33282.55525"
  },
  {
      "Date": "1/31/19",
      "Rate": "33104.36868"
  },
  {
      "Date": "1/30/19",
      "Rate": "32993.6013"
  },
  {
      "Date": "1/29/19",
      "Rate": "32955.07857"
  },
  {
      "Date": "1/28/19",
      "Rate": "32614.37415"
  },
  {
      "Date": "1/25/19",
      "Rate": "32079.06665"
  },
  {
      "Date": "1/24/19",
      "Rate": "32080.08254"
  },
  {
      "Date": "1/23/19",
      "Rate": "32283.60289"
  },
  {
      "Date": "1/22/19",
      "Rate": "32276.3435"
  },
  {
      "Date": "1/21/19",
      "Rate": "32113.52581"
  },
  {
      "Date": "1/18/19",
      "Rate": "32288.64001"
  },
  {
      "Date": "1/17/19",
      "Rate": "32450.66756"
  },
  {
      "Date": "1/16/19",
      "Rate": "32319.74109"
  },
  {
      "Date": "1/15/19",
      "Rate": "32348.02025"
  },
  {
      "Date": "1/14/19",
      "Rate": "32288.53772"
  },
  {
      "Date": "1/11/19",
      "Rate": "32092.78823"
  },
  {
      "Date": "1/10/19",
      "Rate": "32095.48669"
  },
  {
      "Date": "1/9/19",
      "Rate": "31900.86598"
  },
  {
      "Date": "1/8/19",
      "Rate": "31766.33098"
  },
  {
      "Date": "1/7/19",
      "Rate": "31781.93972"
  },
  {
      "Date": "1/4/19",
      "Rate": "31656.2338"
  },
  {
      "Date": "1/3/19",
      "Rate": "31859.71887"
  },
  {
      "Date": "1/2/19",
      "Rate": "31790.7688"
  },
  {
      "Date": "12/31/18",
      "Rate": "31506.09005"
  },
  {
      "Date": "12/28/18",
      "Rate": "31494.7918"
  },
  {
      "Date": "12/27/18",
      "Rate": "31447.39061"
  },
  {
      "Date": "12/24/18",
      "Rate": "31140.29877"
  },
  {
      "Date": "12/21/18",
      "Rate": "31102.30516"
  },
  {
      "Date": "12/20/18",
      "Rate": "30964.87063"
  },
  {
      "Date": "12/19/18",
      "Rate": "30961.20214"
  },
  {
      "Date": "12/18/18",
      "Rate": "31052.1914"
  },
  {
      "Date": "12/17/18",
      "Rate": "31296.89765"
  },
  {
      "Date": "12/14/18",
      "Rate": "31425.13625"
  },
  {
      "Date": "12/13/18",
      "Rate": "31403.93305"
  },
  {
      "Date": "12/12/18",
      "Rate": "31517.75869"
  },
  {
      "Date": "12/11/18",
      "Rate": "31854.71349"
  },
  {
      "Date": "12/10/18",
      "Rate": "31919.74109"
  },
  {
      "Date": "12/7/18",
      "Rate": "31207.25233"
  },
  {
      "Date": "12/6/18",
      "Rate": "30785.31191"
  },
  {
      "Date": "12/5/18",
      "Rate": "30801.82719"
  },
  {
      "Date": "12/4/18",
      "Rate": "30862.11044"
  },
  {
      "Date": "12/3/18",
      "Rate": "30613.73569"
  },
  {
      "Date": "11/30/18",
      "Rate": "30022.95279"
  },
  {
      "Date": "11/29/18",
      "Rate": "30187.77051"
  },
  {
      "Date": "11/28/18",
      "Rate": "30157.21618"
  },
  {
      "Date": "11/27/18",
      "Rate": "30635.43272"
  },
  {
      "Date": "11/26/18",
      "Rate": "30606.86432"
  },
  {
      "Date": "11/23/18",
      "Rate": "30416.33539"
  },
  {
      "Date": "11/22/18",
      "Rate": "30758.82467"
  },
  {
      "Date": "11/21/18",
      "Rate": "30693.36673"
  },
  {
      "Date": "11/20/18",
      "Rate": "30823.26672"
  },
  {
      "Date": "11/19/18",
      "Rate": "30809.9649"
  },
  {
      "Date": "11/16/18",
      "Rate": "30792.57482"
  },
  {
      "Date": "11/15/18",
      "Rate": "30685.00326"
  },
  {
      "Date": "11/14/18",
      "Rate": "30589.83756"
  },
  {
      "Date": "11/13/18",
      "Rate": "30585.79869"
  },
  {
      "Date": "11/12/18",
      "Rate": "31041.10478"
  },
  {
      "Date": "11/9/18",
      "Rate": "31233.602"
  },
  {
      "Date": "11/8/18",
      "Rate": "31337.48743"
  },
  {
      "Date": "11/7/18",
      "Rate": "31508.48869"
  },
  {
      "Date": "11/6/18",
      "Rate": "31810.25415"
  },
  {
      "Date": "11/5/18",
      "Rate": "31700.68255"
  },
  {
      "Date": "11/2/18",
      "Rate": "31801.0688"
  },
  {
      "Date": "11/1/18",
      "Rate": "31556.99748"
  },
  {
      "Date": "10/31/18",
      "Rate": "31811.09367"
  },
  {
      "Date": "10/30/18",
      "Rate": "31696.8906"
  },
  {
      "Date": "10/29/18",
      "Rate": "31924.09037"
  },
  {
      "Date": "10/26/18",
      "Rate": "31891.08803"
  },
  {
      "Date": "10/25/18",
      "Rate": "31834.36392"
  },
  {
      "Date": "10/24/18",
      "Rate": "31827.96169"
  },
  {
      "Date": "10/23/18",
      "Rate": "31987.56945"
  },
  {
      "Date": "10/22/18",
      "Rate": "31775.68564"
  },
  {
      "Date": "10/19/18",
      "Rate": "31815.70045"
  },
  {
      "Date": "10/18/18",
      "Rate": "31827.84882"
  },
  {
      "Date": "10/17/18",
      "Rate": "31748.28833"
  },
  {
      "Date": "10/16/18",
      "Rate": "31755.60416"
  },
  {
      "Date": "10/15/18",
      "Rate": "32067.48267"
  },
  {
      "Date": "10/12/18",
      "Rate": "31668.71373"
  },
  {
      "Date": "10/11/18",
      "Rate": "31277.87439"
  },
  {
      "Date": "10/10/18",
      "Rate": "31063.51435"
  },
  {
      "Date": "10/9/18",
      "Rate": "31046.76626"
  },
  {
      "Date": "10/8/18",
      "Rate": "31189.6753"
  },
  {
      "Date": "10/5/18",
      "Rate": "31430.43793"
  },
  {
      "Date": "10/4/18",
      "Rate": "31251.82807"
  },
  {
      "Date": "10/3/18",
      "Rate": "31191.78821"
  },
  {
      "Date": "10/2/18",
      "Rate": "30855.89164"
  },
  {
      "Date": "10/1/18",
      "Rate": "30607.20295"
  },
  {
      "Date": "9/28/18",
      "Rate": "30287.07032"
  },
  {
      "Date": "9/27/18",
      "Rate": "30621.93689"
  },
  {
      "Date": "9/26/18",
      "Rate": "30688.04388"
  },
  {
      "Date": "9/25/18",
      "Rate": "30768.44389"
  },
  {
      "Date": "9/24/18",
      "Rate": "30804.40219"
  },
  {
      "Date": "9/21/18",
      "Rate": "30795.40733"
  },
  {
      "Date": "9/20/18",
      "Rate": "30461.26034"
  },
  {
      "Date": "9/19/18",
      "Rate": "30609.35819"
  },
  {
      "Date": "9/18/18",
      "Rate": "30769.27635"
  },
  {
      "Date": "9/17/18",
      "Rate": "30583.95033"
  },
  {
      "Date": "9/14/18",
      "Rate": "30610.79031"
  },
  {
      "Date": "9/13/18",
      "Rate": "30506.01245"
  },
  {
      "Date": "9/12/18",
      "Rate": "30406.78319"
  },
  {
      "Date": "9/11/18",
      "Rate": "30572.8355"
  },
  {
      "Date": "9/10/18",
      "Rate": "30614.70573"
  },
  {
      "Date": "9/7/18",
      "Rate": "30493.6595"
  },
  {
      "Date": "9/6/18",
      "Rate": "30573.19177"
  },
  {
      "Date": "9/5/18",
      "Rate": "30151.2196"
  },
  {
      "Date": "9/4/18",
      "Rate": "30100.53087"
  },
  {
      "Date": "9/3/18",
      "Rate": "30170.19701"
  },
  {
      "Date": "8/31/18",
      "Rate": "30167.52676"
  },
  {
      "Date": "8/30/18",
      "Rate": "30106.99307"
  },
  {
      "Date": "8/29/18",
      "Rate": "29963.59724"
  },
  {
      "Date": "8/28/18",
      "Rate": "29987.75287"
  },
  {
      "Date": "8/24/18",
      "Rate": "29302.24872"
  },
  {
      "Date": "8/23/18",
      "Rate": "29389.01921"
  },
  {
      "Date": "8/22/18",
      "Rate": "29480.6046"
  },
  {
      "Date": "8/21/18",
      "Rate": "29403.47096"
  },
  {
      "Date": "8/20/18",
      "Rate": "29278.98905"
  },
  {
      "Date": "8/17/18",
      "Rate": "29065.21103"
  },
  {
      "Date": "8/16/18",
      "Rate": "29146.39764"
  },
  {
      "Date": "8/15/18",
      "Rate": "29460.19154"
  },
  {
      "Date": "8/14/18",
      "Rate": "29534.12935"
  },
  {
      "Date": "8/13/18",
      "Rate": "29802.52209"
  },
  {
      "Date": "8/10/18",
      "Rate": "29539.56507"
  },
  {
      "Date": "8/9/18",
      "Rate": "29484.17785"
  },
  {
      "Date": "8/8/18",
      "Rate": "29361.75241"
  },
  {
      "Date": "8/7/18",
      "Rate": "29423.70412"
  },
  {
      "Date": "8/6/18",
      "Rate": "29423.69001"
  },
  {
      "Date": "8/3/18",
      "Rate": "29200.31394"
  },
  {
      "Date": "8/2/18",
      "Rate": "29450.54763"
  },
  {
      "Date": "8/1/18",
      "Rate": "29467.28514"
  },
  {
      "Date": "7/31/18",
      "Rate": "29440.13475"
  },
  {
      "Date": "7/30/18",
      "Rate": "29581.02965"
  },
  {
      "Date": "7/27/18",
      "Rate": "29510.61571"
  },
  {
      "Date": "7/26/18",
      "Rate": "29760.78238"
  },
  {
      "Date": "7/25/18",
      "Rate": "29819.1185"
  },
  {
      "Date": "7/24/18",
      "Rate": "29742.81028"
  },
  {
      "Date": "7/23/18",
      "Rate": "29902.61557"
  },
  {
      "Date": "7/20/18",
      "Rate": "29703.02827"
  },
  {
      "Date": "7/19/18",
      "Rate": "29643.54574"
  },
  {
      "Date": "7/18/18",
      "Rate": "29535.94243"
  },
  {
      "Date": "7/17/18",
      "Rate": "30010.4411"
  },
  {
      "Date": "7/16/18",
      "Rate": "30090.44604"
  },
  {
      "Date": "7/13/18",
      "Rate": "29963.26214"
  },
  {
      "Date": "7/12/18",
      "Rate": "29991.09332"
  },
  {
      "Date": "7/11/18",
      "Rate": "30329.01815"
  },
  {
      "Date": "7/10/18",
      "Rate": "30360.85998"
  },
  {
      "Date": "7/9/18",
      "Rate": "30576.16889"
  },
  {
      "Date": "7/6/18",
      "Rate": "30421.36898"
  },
  {
      "Date": "7/5/18",
      "Rate": "30407.34757"
  },
  {
      "Date": "7/4/18",
      "Rate": "30585.10732"
  },
  {
      "Date": "7/3/18",
      "Rate": "30139.685"
  },
  {
      "Date": "7/2/18",
      "Rate": "30284.92919"
  },
  {
      "Date": "6/29/18",
      "Rate": "30205.61562"
  },
  {
      "Date": "6/28/18",
      "Rate": "30361.00108"
  },
  {
      "Date": "6/27/18",
      "Rate": "30530.36209"
  },
  {
      "Date": "6/26/18",
      "Rate": "30301.83954"
  },
  {
      "Date": "6/25/18",
      "Rate": "30504.85194"
  },
  {
      "Date": "6/22/18",
      "Rate": "30398.31743"
  },
  {
      "Date": "6/21/18",
      "Rate": "30241.52101"
  },
  {
      "Date": "6/20/18",
      "Rate": "30570.66615"
  },
  {
      "Date": "6/19/18",
      "Rate": "30726.76767"
  },
  {
      "Date": "6/18/18",
      "Rate": "30781.27657"
  },
  {
      "Date": "6/15/18",
      "Rate": "31274.03305"
  },
  {
      "Date": "6/14/18",
      "Rate": "31196.99113"
  },
  {
      "Date": "6/13/18",
      "Rate": "30793.33674"
  },
  {
      "Date": "6/12/18",
      "Rate": "30915.88564"
  },
  {
      "Date": "6/11/18",
      "Rate": "30861.1651"
  },
  {
      "Date": "6/8/18",
      "Rate": "30960.50371"
  },
  {
      "Date": "6/7/18",
      "Rate": "30895.50433"
  },
  {
      "Date": "6/6/18",
      "Rate": "30539.97425"
  },
  {
      "Date": "6/5/18",
      "Rate": "30594.95582"
  },
  {
      "Date": "6/4/18",
      "Rate": "30624.7447"
  },
  {
      "Date": "6/1/18",
      "Rate": "30687.01388"
  },
  {
      "Date": "5/31/18",
      "Rate": "30999.47794"
  },
  {
      "Date": "5/30/18",
      "Rate": "30908.18533"
  },
  {
      "Date": "5/29/18",
      "Rate": "31138.00596"
  },
  {
      "Date": "5/25/18",
      "Rate": "31184.9627"
  },
  {
      "Date": "5/24/18",
      "Rate": "31235.27046"
  },
  {
      "Date": "5/23/18",
      "Rate": "31207.17473"
  },
  {
      "Date": "5/22/18",
      "Rate": "31047.7116"
  },
  {
      "Date": "5/21/18",
      "Rate": "30899.49735"
  },
  {
      "Date": "5/18/18",
      "Rate": "30872.50569"
  },
  {
      "Date": "5/17/18",
      "Rate": "30830.6531"
  },
  {
      "Date": "5/16/18",
      "Rate": "30842.61451"
  },
  {
      "Date": "5/15/18",
      "Rate": "31520.31253"
  },
  {
      "Date": "5/14/18",
      "Rate": "31483.76162"
  },
  {
      "Date": "5/11/18",
      "Rate": "31478.13189"
  },
  {
      "Date": "5/10/18",
      "Rate": "31093.13392"
  },
  {
      "Date": "5/9/18",
      "Rate": "31064.16339"
  },
  {
      "Date": "5/8/18",
      "Rate": "30995.82003"
  },
  {
      "Date": "5/4/18",
      "Rate": "30858.48428"
  },
  {
      "Date": "5/3/18",
      "Rate": "30894.36498"
  },
  {
      "Date": "5/2/18",
      "Rate": "30863.64486"
  },
  {
      "Date": "5/1/18",
      "Rate": "30774.73324"
  },
  {
      "Date": "4/30/18",
      "Rate": "30940.45398"
  },
  {
      "Date": "4/27/18",
      "Rate": "30974.53571"
  },
  {
      "Date": "4/26/18",
      "Rate": "31182.00321"
  },
  {
      "Date": "4/25/18",
      "Rate": "31309.9843"
  },
  {
      "Date": "4/24/18",
      "Rate": "31091.39138"
  },
  {
      "Date": "4/23/18",
      "Rate": "31169.01533"
  },
  {
      "Date": "4/20/18",
      "Rate": "31284.90097"
  },
  {
      "Date": "4/19/18",
      "Rate": "31458.73472"
  },
  {
      "Date": "4/18/18",
      "Rate": "31227.92289"
  },
  {
      "Date": "4/17/18",
      "Rate": "31112.46054"
  },
  {
      "Date": "4/16/18",
      "Rate": "31047.17896"
  },
  {
      "Date": "4/13/18",
      "Rate": "30849.6164"
  },
  {
      "Date": "4/12/18",
      "Rate": "30996.64897"
  },
  {
      "Date": "4/11/18",
      "Rate": "30933.0288"
  },
  {
      "Date": "4/10/18",
      "Rate": "30611.89086"
  },
  {
      "Date": "4/9/18",
      "Rate": "30392.02455"
  },
  {
      "Date": "4/6/18",
      "Rate": "30359.34673"
  },
  {
      "Date": "4/5/18",
      "Rate": "30350.65874"
  },
  {
      "Date": "4/4/18",
      "Rate": "30798.23983"
  },
  {
      "Date": "4/3/18",
      "Rate": "30651.20725"
  },
  {
      "Date": "3/29/18",
      "Rate": "30419.88395"
  },
  {
      "Date": "3/28/18",
      "Rate": "30815.12901"
  },
  {
      "Date": "3/27/18",
      "Rate": "30896.36502"
  },
  {
      "Date": "3/26/18",
      "Rate": "30774.93077"
  },
  {
      "Date": "3/23/18",
      "Rate": "30777.52694"
  },
  {
      "Date": "3/22/18",
      "Rate": "30521.90338"
  },
  {
      "Date": "3/21/18",
      "Rate": "30264.97469"
  },
  {
      "Date": "3/20/18",
      "Rate": "30226.89289"
  },
  {
      "Date": "3/19/18",
      "Rate": "30211.27357"
  },
  {
      "Date": "3/16/18",
      "Rate": "30305.10944"
  },
  {
      "Date": "3/15/18",
      "Rate": "30344.4611"
  },
  {
      "Date": "3/14/18",
      "Rate": "30333.01116"
  },
  {
      "Date": "3/13/18",
      "Rate": "30125.26852"
  },
  {
      "Date": "3/12/18",
      "Rate": "30180.42646"
  },
  {
      "Date": "3/9/18",
      "Rate": "30233.89125"
  },
  {
      "Date": "3/8/18",
      "Rate": "30448.13136"
  },
  {
      "Date": "3/7/18",
      "Rate": "30532.88418"
  },
  {
      "Date": "3/6/18",
      "Rate": "30295.38793"
  },
  {
      "Date": "3/5/18",
      "Rate": "30426.70241"
  },
  {
      "Date": "3/2/18",
      "Rate": "30297.16221"
  },
  {
      "Date": "3/1/18",
      "Rate": "30169.68906"
  },
  {
      "Date": "2/28/18",
      "Rate": "30354.85987"
  },
  {
      "Date": "2/27/18",
      "Rate": "30606.77966"
  },
  {
      "Date": "2/26/18",
      "Rate": "30626.99871"
  },
  {
      "Date": "2/23/18",
      "Rate": "30419.92628"
  },
  {
      "Date": "2/22/18",
      "Rate": "30341.82966"
  },
  {
      "Date": "2/21/18",
      "Rate": "30366.19341"
  },
  {
      "Date": "2/20/18",
      "Rate": "30579.1178"
  },
  {
      "Date": "2/19/18",
      "Rate": "30604.81843"
  },
  {
      "Date": "2/16/18",
      "Rate": "30859.21445"
  },
  {
      "Date": "2/15/18",
      "Rate": "30535.61791"
  },
  {
      "Date": "2/14/18",
      "Rate": "30052.71345"
  },
  {
      "Date": "2/13/18",
      "Rate": "30133.22281"
  },
  {
      "Date": "2/12/18",
      "Rate": "29958.6871"
  },
  {
      "Date": "2/9/18",
      "Rate": "29930.79948"
  },
  {
      "Date": "2/8/18",
      "Rate": "29824.02159"
  },
  {
      "Date": "2/7/18",
      "Rate": "30143.65685"
  },
  {
      "Date": "2/6/18",
      "Rate": "30415.14312"
  },
  {
      "Date": "2/5/18",
      "Rate": "30206.73028"
  },
  {
      "Date": "2/2/18",
      "Rate": "30429.08693"
  },
  {
      "Date": "2/1/18",
      "Rate": "30260.9076"
  },
  {
      "Date": "1/31/18",
      "Rate": "30179.47054"
  },
  {
      "Date": "1/30/18",
      "Rate": "30274.03658"
  },
  {
      "Date": "1/29/18",
      "Rate": "30289.59241"
  },
  {
      "Date": "1/26/18",
      "Rate": "30317.91037"
  },
  {
      "Date": "1/25/18",
      "Rate": "30449.98677"
  },
  {
      "Date": "1/24/18",
      "Rate": "30301.75135"
  },
  {
      "Date": "1/23/18",
      "Rate": "30083.86391"
  },
  {
      "Date": "1/22/18",
      "Rate": "30078.1072"
  },
  {
      "Date": "1/19/18",
      "Rate": "30068.04706"
  },
  {
      "Date": "1/18/18",
      "Rate": "29975.57982"
  },
  {
      "Date": "1/17/18",
      "Rate": "30114.96499"
  },
  {
      "Date": "1/16/18",
      "Rate": "30147.37473"
  },
  {
      "Date": "1/15/18",
      "Rate": "30100.78485"
  },
  {
      "Date": "1/12/18",
      "Rate": "29883.81453"
  },
  {
      "Date": "1/11/18",
      "Rate": "29668.05058"
  },
  {
      "Date": "1/10/18",
      "Rate": "29738.81374"
  },
  {
      "Date": "1/9/18",
      "Rate": "29524.04804"
  },
  {
      "Date": "1/8/18",
      "Rate": "29521.87164"
  },
  {
      "Date": "1/5/18",
      "Rate": "29428.97053"
  },
  {
      "Date": "1/4/18",
      "Rate": "29366.46149"
  },
  {
      "Date": "1/3/18",
      "Rate": "29398.17281"
  },
  {
      "Date": "1/2/18",
      "Rate": "29379.54814"
  },
  {
      "Date": "12/29/17",
      "Rate": "29201.03353"
  },
  {
      "Date": "12/28/17",
      "Rate": "29183.15667"
  },
  {
      "Date": "12/27/17",
      "Rate": "29085.2396"
  },
  {
      "Date": "12/22/17",
      "Rate": "28634.14875"
  },
  {
      "Date": "12/21/17",
      "Rate": "28581.47763"
  },
  {
      "Date": "12/20/17",
      "Rate": "28572.57094"
  },
  {
      "Date": "12/19/17",
      "Rate": "28540.99367"
  },
  {
      "Date": "12/18/17",
      "Rate": "28481.06669"
  },
  {
      "Date": "12/15/17",
      "Rate": "28391.73178"
  },
  {
      "Date": "12/14/17",
      "Rate": "28474.05422"
  },
  {
      "Date": "12/13/17",
      "Rate": "28188.53948"
  },
  {
      "Date": "12/12/17",
      "Rate": "28319.3178"
  },
  {
      "Date": "12/11/17",
      "Rate": "28413.46055"
  },
  {
      "Date": "12/8/17",
      "Rate": "28354.02388"
  },
  {
      "Date": "12/7/17",
      "Rate": "28598.80068"
  },
  {
      "Date": "12/6/17",
      "Rate": "28887.43011"
  },
  {
      "Date": "12/5/17",
      "Rate": "28970.14762"
  },
  {
      "Date": "12/4/17",
      "Rate": "29054.03975"
  },
  {
      "Date": "12/1/17",
      "Rate": "29088.92573"
  },
  {
      "Date": "11/30/17",
      "Rate": "29202.78312"
  },
  {
      "Date": "11/29/17",
      "Rate": "29439.51745"
  },
  {
      "Date": "11/28/17",
      "Rate": "29407.19237"
  },
  {
      "Date": "11/27/17",
      "Rate": "29456.88636"
  },
  {
      "Date": "11/24/17",
      "Rate": "29363.35385"
  },
  {
      "Date": "11/23/17",
      "Rate": "29384.99444"
  },
  {
      "Date": "11/22/17",
      "Rate": "29330.55609"
  },
  {
      "Date": "11/21/17",
      "Rate": "29263.6625"
  },
  {
      "Date": "11/20/17",
      "Rate": "29671.01007"
  },
  {
      "Date": "11/17/17",
      "Rate": "29410.19418"
  },
  {
      "Date": "11/16/17",
      "Rate": "29385.36482"
  },
  {
      "Date": "11/15/17",
      "Rate": "29646.45232"
  },
  {
      "Date": "11/14/17",
      "Rate": "29385.69992"
  },
  {
      "Date": "11/13/17",
      "Rate": "29520.9651"
  },
  {
      "Date": "11/10/17",
      "Rate": "29613.09723"
  },
  {
      "Date": "11/9/17",
      "Rate": "29503.08118"
  },
  {
      "Date": "11/8/17",
      "Rate": "29373.08947"
  },
  {
      "Date": "11/7/17",
      "Rate": "29351.84042"
  },
  {
      "Date": "11/6/17",
      "Rate": "28970.74375"
  },
  {
      "Date": "11/3/17",
      "Rate": "29087.25022"
  },
  {
      "Date": "11/2/17",
      "Rate": "29058.31143"
  },
  {
      "Date": "11/1/17",
      "Rate": "29117.95975"
  },
  {
      "Date": "10/31/17",
      "Rate": "29066.94651"
  },
  {
      "Date": "10/30/17",
      "Rate": "29128.97935"
  },
  {
      "Date": "10/27/17",
      "Rate": "29055.04506"
  },
  {
      "Date": "10/26/17",
      "Rate": "29277.35586"
  },
  {
      "Date": "10/25/17",
      "Rate": "29158.27792"
  },
  {
      "Date": "10/24/17",
      "Rate": "29401.01236"
  },
  {
      "Date": "10/23/17",
      "Rate": "29248.0467"
  },
  {
      "Date": "10/20/17",
      "Rate": "29380.78978"
  },
  {
      "Date": "10/19/17",
      "Rate": "29453.07677"
  },
  {
      "Date": "10/18/17",
      "Rate": "29394.26092"
  },
  {
      "Date": "10/17/17",
      "Rate": "29583.96444"
  },
  {
      "Date": "10/16/17",
      "Rate": "29786.53592"
  },
  {
      "Date": "10/13/17",
      "Rate": "29529.69188"
  },
  {
      "Date": "10/12/17",
      "Rate": "29704.34046"
  },
  {
      "Date": "10/11/17",
      "Rate": "29659.19328"
  },
  {
      "Date": "10/10/17",
      "Rate": "29666.32568"
  },
  {
      "Date": "10/9/17",
      "Rate": "29560.29912"
  },
  {
      "Date": "10/6/17",
      "Rate": "29293.0281"
  },
  {
      "Date": "10/5/17",
      "Rate": "29372.15118"
  },
  {
      "Date": "10/4/17",
      "Rate": "29279.67689"
  },
  {
      "Date": "10/3/17",
      "Rate": "29354.31665"
  },
  {
      "Date": "10/2/17",
      "Rate": "29329.10281"
  },
  {
      "Date": "9/29/17",
      "Rate": "29666.19517"
  },
  {
      "Date": "9/28/17",
      "Rate": "29644.74858"
  },
  {
      "Date": "9/27/17",
      "Rate": "29844.36057"
  },
  {
      "Date": "9/26/17",
      "Rate": "30165.72074"
  },
  {
      "Date": "9/25/17",
      "Rate": "29820.10265"
  },
  {
      "Date": "9/22/17",
      "Rate": "29675.97665"
  },
  {
      "Date": "9/21/17",
      "Rate": "29708.51338"
  },
  {
      "Date": "9/20/17",
      "Rate": "29844.39584"
  },
  {
      "Date": "9/19/17",
      "Rate": "29677.2324"
  },
  {
      "Date": "9/18/17",
      "Rate": "29749.91799"
  },
  {
      "Date": "9/15/17",
      "Rate": "29971.23406"
  },
  {
      "Date": "9/14/17",
      "Rate": "29964.72954"
  },
  {
      "Date": "9/13/17",
      "Rate": "30138.98658"
  },
  {
      "Date": "9/12/17",
      "Rate": "29954.87751"
  },
  {
      "Date": "9/11/17",
      "Rate": "30190.409"
  },
  {
      "Date": "9/8/17",
      "Rate": "30468.45623"
  },
  {
      "Date": "9/7/17",
      "Rate": "30246.94968"
  },
  {
      "Date": "9/6/17",
      "Rate": "30286.74227"
  },
  {
      "Date": "9/5/17",
      "Rate": "30100.48502"
  },
  {
      "Date": "9/4/17",
      "Rate": "30133.77661"
  },
  {
      "Date": "9/1/17",
      "Rate": "29678.47405"
  },
  {
      "Date": "8/31/17",
      "Rate": "29469.68377"
  },
  {
      "Date": "8/30/17",
      "Rate": "29582.63461"
  },
  {
      "Date": "8/29/17",
      "Rate": "29846.5793"
  },
  {
      "Date": "8/25/17",
      "Rate": "29068.7737"
  },
  {
      "Date": "8/24/17",
      "Rate": "29045.06958"
  },
  {
      "Date": "8/23/17",
      "Rate": "29062.48435"
  },
  {
      "Date": "8/22/17",
      "Rate": "29044.49814"
  },
  {
      "Date": "8/21/17",
      "Rate": "29147.50877"
  },
  {
      "Date": "8/18/17",
      "Rate": "29279.78977"
  },
  {
      "Date": "8/17/17",
      "Rate": "29094.87293"
  },
  {
      "Date": "8/16/17",
      "Rate": "28756.97279"
  },
  {
      "Date": "8/15/17",
      "Rate": "28824.00395"
  },
  {
      "Date": "8/14/17",
      "Rate": "28952.9198"
  },
  {
      "Date": "8/11/17",
      "Rate": "29114.5029"
  },
  {
      "Date": "8/10/17",
      "Rate": "28923.45544"
  },
  {
      "Date": "8/9/17",
      "Rate": "28586.40188"
  },
  {
      "Date": "8/8/17",
      "Rate": "28340.40812"
  },
  {
      "Date": "8/7/17",
      "Rate": "28319.77284"
  },
  {
      "Date": "8/4/17",
      "Rate": "28541.84377"
  },
  {
      "Date": "8/3/17",
      "Rate": "28354.54946"
  },
  {
      "Date": "8/2/17",
      "Rate": "28431.98998"
  },
  {
      "Date": "8/1/17",
      "Rate": "28646.56167"
  },
  {
      "Date": "7/31/17",
      "Rate": "28666.24808"
  },
  {
      "Date": "7/28/17",
      "Rate": "28487.05621"
  },
  {
      "Date": "7/27/17",
      "Rate": "28566.90594"
  },
  {
      "Date": "7/26/17",
      "Rate": "28260.36085"
  },
  {
      "Date": "7/25/17",
      "Rate": "28458.66065"
  },
  {
      "Date": "7/24/17",
      "Rate": "28526.28441"
  },
  {
      "Date": "7/21/17",
      "Rate": "28328.69363"
  },
  {
      "Date": "7/20/17",
      "Rate": "28070.39983"
  },
  {
      "Date": "7/19/17",
      "Rate": "28105.99129"
  },
  {
      "Date": "7/18/17",
      "Rate": "28060.62894"
  },
  {
      "Date": "7/17/17",
      "Rate": "27917.65287"
  },
  {
      "Date": "7/14/17",
      "Rate": "27640.98485"
  },
  {
      "Date": "7/13/17",
      "Rate": "27771.63618"
  },
  {
      "Date": "7/12/17",
      "Rate": "27706.89077"
  },
  {
      "Date": "7/11/17",
      "Rate": "27591.36845"
  },
  {
      "Date": "7/10/17",
      "Rate": "27463.15455"
  },
  {
      "Date": "7/7/17",
      "Rate": "27824.31789"
  },
  {
      "Date": "7/6/17",
      "Rate": "27952.10498"
  },
  {
      "Date": "7/5/17",
      "Rate": "27917.56821"
  },
  {
      "Date": "7/4/17",
      "Rate": "27963.48436"
  },
  {
      "Date": "7/3/17",
      "Rate": "28253.33427"
  },
  {
      "Date": "6/30/17",
      "Rate": "28338.66206"
  },
  {
      "Date": "6/29/17",
      "Rate": "28493.64539"
  },
  {
      "Date": "6/28/17",
      "Rate": "28478.22007"
  },
  {
      "Date": "6/27/17",
      "Rate": "28437.06591"
  },
  {
      "Date": "6/26/17",
      "Rate": "28232.48382"
  },
  {
      "Date": "6/23/17",
      "Rate": "28571.86899"
  },
  {
      "Date": "6/22/17",
      "Rate": "28519.9739"
  },
  {
      "Date": "6/21/17",
      "Rate": "28410.14833"
  },
  {
      "Date": "6/20/17",
      "Rate": "28426.85762"
  },
  {
      "Date": "6/19/17",
      "Rate": "28455.85636"
  },
  {
      "Date": "6/16/17",
      "Rate": "28570.53564"
  },
  {
      "Date": "6/15/17",
      "Rate": "28723.49424"
  },
  {
      "Date": "6/14/17",
      "Rate": "28664.74541"
  },
  {
      "Date": "6/13/17",
      "Rate": "28639.75731"
  },
  {
      "Date": "6/12/17",
      "Rate": "28872.69969"
  },
  {
      "Date": "6/9/17",
      "Rate": "28930.70425"
  },
  {
      "Date": "6/8/17",
      "Rate": "29123.14503"
  },
  {
      "Date": "6/7/17",
      "Rate": "29387.62588"
  },
  {
      "Date": "6/6/17",
      "Rate": "29272.50569"
  },
  {
      "Date": "6/5/17",
      "Rate": "29073.53216"
  },
  {
      "Date": "6/2/17",
      "Rate": "28588.7088"
  },
  {
      "Date": "6/1/17",
      "Rate": "28760.24621"
  },
  {
      "Date": "5/31/17",
      "Rate": "28759.86878"
  },
  {
      "Date": "5/30/17",
      "Rate": "28758.94107"
  },
  {
      "Date": "5/26/17",
      "Rate": "28814.39884"
  },
  {
      "Date": "5/25/17",
      "Rate": "28629.57019"
  },
  {
      "Date": "5/24/17",
      "Rate": "28591.71061"
  },
  {
      "Date": "5/23/17",
      "Rate": "28842.17358"
  },
  {
      "Date": "5/22/17",
      "Rate": "28574.64153"
  },
  {
      "Date": "5/19/17",
      "Rate": "28508.24177"
  },
  {
      "Date": "5/18/17",
      "Rate": "28902.5556"
  },
  {
      "Date": "5/17/17",
      "Rate": "28152.20727"
  },
  {
      "Date": "5/16/17",
      "Rate": "27878.661"
  },
  {
      "Date": "5/15/17",
      "Rate": "27812.45172"
  },
  {
      "Date": "5/12/17",
      "Rate": "27786.76167"
  },
  {
      "Date": "5/11/17",
      "Rate": "27735.83661"
  },
  {
      "Date": "5/10/17",
      "Rate": "27901.5256"
  },
  {
      "Date": "5/9/17",
      "Rate": "27949.47001"
  },
  {
      "Date": "5/8/17",
      "Rate": "27923.18383"
  },
  {
      "Date": "5/5/17",
      "Rate": "28126.35143"
  },
  {
      "Date": "5/4/17",
      "Rate": "28000.23281"
  },
  {
      "Date": "5/3/17",
      "Rate": "28383.47061"
  },
  {
      "Date": "5/2/17",
      "Rate": "28427.60543"
  },
  {
      "Date": "4/28/17",
      "Rate": "28692.95755"
  },
  {
      "Date": "4/27/17",
      "Rate": "28588.84636"
  },
  {
      "Date": "4/26/17",
      "Rate": "28618.40244"
  },
  {
      "Date": "4/25/17",
      "Rate": "28830.77656"
  },
  {
      "Date": "4/24/17",
      "Rate": "28937.16644"
  },
  {
      "Date": "4/21/17",
      "Rate": "29227.7571"
  },
  {
      "Date": "4/20/17",
      "Rate": "29221.51361"
  },
  {
      "Date": "4/19/17",
      "Rate": "29234.51207"
  },
  {
      "Date": "4/18/17",
      "Rate": "29281.70162"
  },
  {
      "Date": "4/13/17",
      "Rate": "29251.91979"
  },
  {
      "Date": "4/12/17",
      "Rate": "29063.70483"
  },
  {
      "Date": "4/11/17",
      "Rate": "28640.20882"
  },
  {
      "Date": "4/10/17",
      "Rate": "28552.56001"
  },
  {
      "Date": "4/7/17",
      "Rate": "28671.48627"
  },
  {
      "Date": "4/6/17",
      "Rate": "28569.28694"
  },
  {
      "Date": "4/5/17",
      "Rate": "28727.60013"
  },
  {
      "Date": "4/4/17",
      "Rate": "28864.57257"
  },
  {
      "Date": "4/3/17",
      "Rate": "28592.20445"
  },
  {
      "Date": "3/31/17",
      "Rate": "28410.24709"
  },
  {
      "Date": "3/30/17",
      "Rate": "28585.88688"
  },
  {
      "Date": "3/29/17",
      "Rate": "28663.41205"
  },
  {
      "Date": "3/28/17",
      "Rate": "28760.12275"
  },
  {
      "Date": "3/27/17",
      "Rate": "28851.5741"
  },
  {
      "Date": "3/24/17",
      "Rate": "28700.26632"
  },
  {
      "Date": "3/23/17",
      "Rate": "28802.47976"
  },
  {
      "Date": "3/22/17",
      "Rate": "28752.14378"
  },
  {
      "Date": "3/21/17",
      "Rate": "28469.9836"
  },
  {
      "Date": "3/20/17",
      "Rate": "28402.99476"
  },
  {
      "Date": "3/17/17",
      "Rate": "28398.27863"
  },
  {
      "Date": "3/16/17",
      "Rate": "28269.83897"
  },
  {
      "Date": "3/15/17",
      "Rate": "27794.30678"
  },
  {
      "Date": "3/14/17",
      "Rate": "27917.12023"
  },
  {
      "Date": "3/13/17",
      "Rate": "28355.21261"
  },
  {
      "Date": "3/10/17",
      "Rate": "28091.09861"
  },
  {
      "Date": "3/9/17",
      "Rate": "28322.4078"
  },
  {
      "Date": "3/8/17",
      "Rate": "28604.0424"
  },
  {
      "Date": "3/7/17",
      "Rate": "28755.73114"
  },
  {
      "Date": "3/6/17",
      "Rate": "28949.90741"
  },
  {
      "Date": "3/3/17",
      "Rate": "28941.1912"
  },
  {
      "Date": "3/2/17",
      "Rate": "29305.99834"
  },
  {
      "Date": "3/1/17",
      "Rate": "29325.54719"
  },
  {
      "Date": "2/28/17",
      "Rate": "29451.52119"
  },
  {
      "Date": "2/27/17",
      "Rate": "29564.27097"
  },
  {
      "Date": "2/24/17",
      "Rate": "29502.26283"
  },
  {
      "Date": "2/23/17",
      "Rate": "29079.24302"
  },
  {
      "Date": "2/22/17",
      "Rate": "29242.22649"
  },
  {
      "Date": "2/21/17",
      "Rate": "29004.98421"
  },
  {
      "Date": "2/20/17",
      "Rate": "29173.94663"
  },
  {
      "Date": "2/17/17",
      "Rate": "29377.0331"
  },
  {
      "Date": "2/16/17",
      "Rate": "29296.00522"
  },
  {
      "Date": "2/15/17",
      "Rate": "28904.62971"
  },
  {
      "Date": "2/14/17",
      "Rate": "29015.13254"
  },
  {
      "Date": "2/13/17",
      "Rate": "29056.19852"
  },
  {
      "Date": "2/10/17",
      "Rate": "28910.48167"
  },
  {
      "Date": "2/9/17",
      "Rate": "29232.31803"
  },
  {
      "Date": "2/8/17",
      "Rate": "29208.18004"
  },
  {
      "Date": "2/7/17",
      "Rate": "29263.36267"
  },
  {
      "Date": "2/6/17",
      "Rate": "28969.81957"
  },
  {
      "Date": "2/3/17",
      "Rate": "28721.68116"
  },
  {
      "Date": "2/2/17",
      "Rate": "29021.07621"
  },
  {
      "Date": "2/1/17",
      "Rate": "28763.55139"
  },
  {
      "Date": "1/31/17",
      "Rate": "28550.29542"
  },
  {
      "Date": "1/30/17",
      "Rate": "28452.31133"
  },
  {
      "Date": "1/27/17",
      "Rate": "28456.63945"
  },
  {
      "Date": "1/26/17",
      "Rate": "28614.51525"
  },
  {
      "Date": "1/25/17",
      "Rate": "28901.49033"
  },
  {
      "Date": "1/24/17",
      "Rate": "29164.65193"
  },
  {
      "Date": "1/23/17",
      "Rate": "29160.48255"
  },
  {
      "Date": "1/20/17",
      "Rate": "28802.29634"
  },
  {
      "Date": "1/19/17",
      "Rate": "28921.87164"
  },
  {
      "Date": "1/18/17",
      "Rate": "29154.27433"
  },
  {
      "Date": "1/17/17",
      "Rate": "29184.09848"
  },
  {
      "Date": "1/16/17",
      "Rate": "28903.8431"
  },
  {
      "Date": "1/13/17",
      "Rate": "28761.43495"
  },
  {
      "Date": "1/12/17",
      "Rate": "28955.8546"
  },
  {
      "Date": "1/11/17",
      "Rate": "28581.58698"
  },
  {
      "Date": "1/10/17",
      "Rate": "28506.06536"
  },
  {
      "Date": "1/9/17",
      "Rate": "28274.81261"
  },
  {
      "Date": "1/6/17",
      "Rate": "28321.36369"
  },
  {
      "Date": "1/5/17",
      "Rate": "28033.65844"
  },
  {
      "Date": "1/4/17",
      "Rate": "27918.34777"
  },
  {
      "Date": "1/3/17",
      "Rate": "27710.91201"
  },
  {
      "Date": "12/30/16",
      "Rate": "27792.88876"
  },
  {
      "Date": "12/29/16",
      "Rate": "27488.2908"
  },
  {
      "Date": "12/28/16",
      "Rate": "27424.83994"
  },
  {
      "Date": "12/23/16",
      "Rate": "27064.68897"
  },
  {
      "Date": "12/22/16",
      "Rate": "27075.056"
  },
  {
      "Date": "12/21/16",
      "Rate": "27139.04654"
  },
  {
      "Date": "12/20/16",
      "Rate": "27148.52114"
  },
  {
      "Date": "12/19/16",
      "Rate": "27228.12395"
  },
  {
      "Date": "12/16/16",
      "Rate": "27158.22149"
  },
  {
      "Date": "12/15/16",
      "Rate": "27128.78887"
  },
  {
      "Date": "12/14/16",
      "Rate": "27631.09755"
  },
  {
      "Date": "12/13/16",
      "Rate": "27542.15066"
  },
  {
      "Date": "12/12/16",
      "Rate": "27496.62252"
  },
  {
      "Date": "12/9/16",
      "Rate": "27841.99721"
  },
  {
      "Date": "12/8/16",
      "Rate": "27938.70086"
  },
  {
      "Date": "12/7/16",
      "Rate": "27900.90478"
  },
  {
      "Date": "12/6/16",
      "Rate": "27980.52523"
  },
  {
      "Date": "12/5/16",
      "Rate": "27942.72562"
  },
  {
      "Date": "12/2/16",
      "Rate": "28113.60694"
  },
  {
      "Date": "12/1/16",
      "Rate": "28134.72195"
  },
  {
      "Date": "11/30/16",
      "Rate": "28715.73396"
  },
  {
      "Date": "11/29/16",
      "Rate": "28747.32182"
  },
  {
      "Date": "11/28/16",
      "Rate": "28770.85663"
  },
  {
      "Date": "11/25/16",
      "Rate": "28738.21761"
  },
  {
      "Date": "11/24/16",
      "Rate": "28801.30867"
  },
  {
      "Date": "11/23/16",
      "Rate": "29469.44391"
  },
  {
      "Date": "11/22/16",
      "Rate": "29369.58324"
  },
  {
      "Date": "11/21/16",
      "Rate": "29247.16838"
  },
  {
      "Date": "11/18/16",
      "Rate": "28989.71058"
  },
  {
      "Date": "11/17/16",
      "Rate": "29521.15205"
  },
  {
      "Date": "11/16/16",
      "Rate": "29432.45207"
  },
  {
      "Date": "11/15/16",
      "Rate": "29409.24531"
  },
  {
      "Date": "11/14/16",
      "Rate": "29178.52167"
  },
  {
      "Date": "11/11/16",
      "Rate": "29967.29043"
  },
  {
      "Date": "11/10/16",
      "Rate": "30297.1869"
  },
  {
      "Date": "11/9/16",
      "Rate": "30592.81116"
  },
  {
      "Date": "11/8/16",
      "Rate": "30053.73992"
  },
  {
      "Date": "11/7/16",
      "Rate": "30293.66655"
  },
  {
      "Date": "11/4/16",
      "Rate": "30675.66271"
  },
  {
      "Date": "11/3/16",
      "Rate": "30413.40059"
  },
  {
      "Date": "11/2/16",
      "Rate": "30509.92081"
  },
  {
      "Date": "11/1/16",
      "Rate": "30232.86125"
  },
  {
      "Date": "10/31/16",
      "Rate": "30023.3796"
  },
  {
      "Date": "10/28/16",
      "Rate": "29827.81001"
  },
  {
      "Date": "10/27/16",
      "Rate": "29937.33928"
  },
  {
      "Date": "10/26/16",
      "Rate": "30046.81917"
  },
  {
      "Date": "10/25/16",
      "Rate": "29914.8627"
  },
  {
      "Date": "10/24/16",
      "Rate": "29893.28207"
  },
  {
      "Date": "10/21/16",
      "Rate": "29845.30591"
  },
  {
      "Date": "10/20/16",
      "Rate": "29922.93691"
  },
  {
      "Date": "10/19/16",
      "Rate": "29846.50523"
  },
  {
      "Date": "10/18/16",
      "Rate": "29695.49375"
  },
  {
      "Date": "10/17/16",
      "Rate": "29519.12732"
  },
  {
      "Date": "10/14/16",
      "Rate": "29554.3872"
  },
  {
      "Date": "10/13/16",
      "Rate": "29659.85996"
  },
  {
      "Date": "10/12/16",
      "Rate": "29446.52992"
  },
  {
      "Date": "10/11/16",
      "Rate": "29462.94291"
  },
  {
      "Date": "10/10/16",
      "Rate": "29593.98578"
  },
  {
      "Date": "10/7/16",
      "Rate": "29507.628"
  },
  {
      "Date": "10/6/16",
      "Rate": "29772.58505"
  },
  {
      "Date": "10/5/16",
      "Rate": "29952.78576"
  },
  {
      "Date": "10/4/16",
      "Rate": "30759.84056"
  },
  {
      "Date": "10/3/16",
      "Rate": "30941.61096"
  },
  {
      "Date": "9/30/16",
      "Rate": "31209.85555"
  },
  {
      "Date": "9/29/16",
      "Rate": "31118.09732"
  },
  {
      "Date": "9/28/16",
      "Rate": "31067.74017"
  },
  {
      "Date": "9/27/16",
      "Rate": "31300.76721"
  },
  {
      "Date": "9/26/16",
      "Rate": "31388.09503"
  },
  {
      "Date": "9/23/16",
      "Rate": "31420.82929"
  },
  {
      "Date": "9/22/16",
      "Rate": "31305.51156"
  },
  {
      "Date": "9/21/16",
      "Rate": "31171.03653"
  },
  {
      "Date": "9/20/16",
      "Rate": "31085.74754"
  },
  {
      "Date": "9/19/16",
      "Rate": "31067.27103"
  },
  {
      "Date": "9/16/16",
      "Rate": "31109.70211"
  },
  {
      "Date": "9/15/16",
      "Rate": "31137.21582"
  },
  {
      "Date": "9/14/16",
      "Rate": "31195.11808"
  },
  {
      "Date": "9/13/16",
      "Rate": "31283.75104"
  },
  {
      "Date": "9/12/16",
      "Rate": "31272.05065"
  },
  {
      "Date": "9/9/16",
      "Rate": "31508.13947"
  },
  {
      "Date": "9/8/16",
      "Rate": "31690.58714"
  },
  {
      "Date": "9/7/16",
      "Rate": "31596.89236"
  },
  {
      "Date": "9/6/16",
      "Rate": "31098.38269"
  },
  {
      "Date": "9/5/16",
      "Rate": "31196.99818"
  },
  {
      "Date": "9/2/16",
      "Rate": "30802.05647"
  },
  {
      "Date": "9/1/16",
      "Rate": "30781.16369"
  },
  {
      "Date": "8/31/16",
      "Rate": "31042.66389"
  },
  {
      "Date": "8/30/16",
      "Rate": "31238.59327"
  },
  {
      "Date": "8/26/16",
      "Rate": "31316.09376"
  },
  {
      "Date": "8/25/16",
      "Rate": "31312.85208"
  },
  {
      "Date": "8/24/16",
      "Rate": "31667.06644"
  },
  {
      "Date": "8/23/16",
      "Rate": "31665.21808"
  },
  {
      "Date": "8/22/16",
      "Rate": "31625.9158"
  },
  {
      "Date": "8/19/16",
      "Rate": "31890.26614"
  },
  {
      "Date": "8/18/16",
      "Rate": "31770.35927"
  },
  {
      "Date": "8/17/16",
      "Rate": "31671.2711"
  },
  {
      "Date": "8/16/16",
      "Rate": "31809.15007"
  },
  {
      "Date": "8/15/16",
      "Rate": "31603.31576"
  },
  {
      "Date": "8/12/16",
      "Rate": "31544.32001"
  },
  {
      "Date": "8/11/16",
      "Rate": "31667.86363"
  },
  {
      "Date": "8/10/16",
      "Rate": "31832.88594"
  },
  {
      "Date": "8/9/16",
      "Rate": "31390.84287"
  },
  {
      "Date": "8/8/16",
      "Rate": "31340.09065"
  },
  {
      "Date": "8/5/16",
      "Rate": "32131.10637"
  },
  {
      "Date": "8/4/16",
      "Rate": "31830.9882"
  },
  {
      "Date": "8/3/16",
      "Rate": "32151.50884"
  },
  {
      "Date": "8/2/16",
      "Rate": "31937.49802"
  },
  {
      "Date": "8/1/16",
      "Rate": "31743.87555"
  },
  {
      "Date": "7/29/16",
      "Rate": "31397.49555"
  },
  {
      "Date": "7/28/16",
      "Rate": "31693.27854"
  },
  {
      "Date": "7/27/16",
      "Rate": "31276.1636"
  },
  {
      "Date": "7/26/16",
      "Rate": "31401.42154"
  },
  {
      "Date": "7/25/16",
      "Rate": "31277.74388"
  },
  {
      "Date": "7/22/16",
      "Rate": "31362.02755"
  },
  {
      "Date": "7/21/16",
      "Rate": "31321.92808"
  },
  {
      "Date": "7/20/16",
      "Rate": "31412.03549"
  },
  {
      "Date": "7/19/16",
      "Rate": "31568.38745"
  },
  {
      "Date": "7/18/16",
      "Rate": "31409.72504"
  },
  {
      "Date": "7/15/16",
      "Rate": "31498.81656"
  },
  {
      "Date": "7/14/16",
      "Rate": "31251.06616"
  },
  {
      "Date": "7/13/16",
      "Rate": "31689.08094"
  },
  {
      "Date": "7/12/16",
      "Rate": "31966.90594"
  },
  {
      "Date": "7/11/16",
      "Rate": "32167.13875"
  },
  {
      "Date": "7/8/16",
      "Rate": "32120.28431"
  },
  {
      "Date": "7/7/16",
      "Rate": "32542.47165"
  },
  {
      "Date": "7/6/16",
      "Rate": "32581.44588"
  },
  {
      "Date": "7/5/16",
      "Rate": "31976.72622"
  },
  {
      "Date": "7/4/16",
      "Rate": "32007.56627"
  },
  {
      "Date": "7/1/16",
      "Rate": "31576.79677"
  },
  {
      "Date": "6/30/16",
      "Rate": "31370.47214"
  },
  {
      "Date": "6/29/16",
      "Rate": "31341.94607"
  },
  {
      "Date": "6/28/16",
      "Rate": "31374.5216"
  },
  {
      "Date": "6/27/16",
      "Rate": "31743.76973"
  },
  {
      "Date": "6/24/16",
      "Rate": "31441.28468"
  },
  {
      "Date": "6/23/16",
      "Rate": "30051.26722"
  },
  {
      "Date": "6/22/16",
      "Rate": "30107.03892"
  },
  {
      "Date": "6/21/16",
      "Rate": "30601.35805"
  },
  {
      "Date": "6/20/16",
      "Rate": "30580.68044"
  },
  {
      "Date": "6/17/16",
      "Rate": "30399.0088"
  },
  {
      "Date": "6/16/16",
      "Rate": "31052.24078"
  },
  {
      "Date": "6/15/16",
      "Rate": "30357.02922"
  },
  {
      "Date": "6/14/16",
      "Rate": "30395.7389"
  },
  {
      "Date": "6/13/16",
      "Rate": "30435.11173"
  },
  {
      "Date": "6/10/16",
      "Rate": "29891.83936"
  },
  {
      "Date": "6/9/16",
      "Rate": "29614.49056"
  },
  {
      "Date": "6/8/16",
      "Rate": "29368.96594"
  },
  {
      "Date": "6/7/16",
      "Rate": "29185.62585"
  },
  {
      "Date": "6/6/16",
      "Rate": "29246.16307"
  },
  {
      "Date": "6/3/16",
      "Rate": "28568.3557"
  },
  {
      "Date": "6/2/16",
      "Rate": "28846.87561"
  },
  {
      "Date": "6/1/16",
      "Rate": "28944.98668"
  },
  {
      "Date": "5/31/16",
      "Rate": "28662.4138"
  },
  {
      "Date": "5/27/16",
      "Rate": "28842.94608"
  },
  {
      "Date": "5/26/16",
      "Rate": "28961.2621"
  },
  {
      "Date": "5/25/16",
      "Rate": "28971.04005"
  },
  {
      "Date": "5/24/16",
      "Rate": "29687.8922"
  },
  {
      "Date": "5/23/16",
      "Rate": "29767.10348"
  },
  {
      "Date": "5/20/16",
      "Rate": "29868.2199"
  },
  {
      "Date": "5/19/16",
      "Rate": "29882.36477"
  },
  {
      "Date": "5/18/16",
      "Rate": "30039.58447"
  },
  {
      "Date": "5/17/16",
      "Rate": "29920.13969"
  },
  {
      "Date": "5/16/16",
      "Rate": "30182.74396"
  },
  {
      "Date": "5/13/16",
      "Rate": "30147.37121"
  },
  {
      "Date": "5/12/16",
      "Rate": "29837.11529"
  },
  {
      "Date": "5/11/16",
      "Rate": "29851.48944"
  },
  {
      "Date": "5/10/16",
      "Rate": "29756.40135"
  },
  {
      "Date": "5/9/16",
      "Rate": "30066.37154"
  },
  {
      "Date": "5/6/16",
      "Rate": "30055.15441"
  },
  {
      "Date": "5/5/16",
      "Rate": "29963.01169"
  },
  {
      "Date": "5/4/16",
      "Rate": "30108.80615"
  },
  {
      "Date": "5/3/16",
      "Rate": "30420.08501"
  },
  {
      "Date": "4/29/16",
      "Rate": "29863.7013"
  },
  {
      "Date": "4/28/16",
      "Rate": "29419.45361"
  },
  {
      "Date": "4/27/16",
      "Rate": "29170.03474"
  },
  {
      "Date": "4/26/16",
      "Rate": "28945.8121"
  },
  {
      "Date": "4/25/16",
      "Rate": "28948.88799"
  },
  {
      "Date": "4/22/16",
      "Rate": "29299.70546"
  },
  {
      "Date": "4/21/16",
      "Rate": "29488.92926"
  },
  {
      "Date": "4/20/16",
      "Rate": "29093.28912"
  },
  {
      "Date": "4/19/16",
      "Rate": "29114.37239"
  },
  {
      "Date": "4/18/16",
      "Rate": "29027.43611"
  },
  {
      "Date": "4/15/16",
      "Rate": "28869.70493"
  },
  {
      "Date": "4/14/16",
      "Rate": "29117.37773"
  },
  {
      "Date": "4/13/16",
      "Rate": "29234.64259"
  },
  {
      "Date": "4/12/16",
      "Rate": "29460.33616"
  },
  {
      "Date": "4/11/16",
      "Rate": "29179.69629"
  },
  {
      "Date": "4/8/16",
      "Rate": "29104.60502"
  },
  {
      "Date": "4/7/16",
      "Rate": "29127.55428"
  },
  {
      "Date": "4/6/16",
      "Rate": "28747.9109"
  },
  {
      "Date": "4/5/16",
      "Rate": "28877.55692"
  },
  {
      "Date": "4/4/16",
      "Rate": "28310.85557"
  },
  {
      "Date": "4/1/16",
      "Rate": "28777.71389"
  },
  {
      "Date": "3/31/16",
      "Rate": "28783.33304"
  },
  {
      "Date": "3/30/16",
      "Rate": "28927.13452"
  },
  {
      "Date": "3/29/16",
      "Rate": "28472.42456"
  },
  {
      "Date": "3/24/16",
      "Rate": "28686.97155"
  },
  {
      "Date": "3/23/16",
      "Rate": "29058.39609"
  },
  {
      "Date": "3/22/16",
      "Rate": "29453.14379"
  },
  {
      "Date": "3/21/16",
      "Rate": "29214.49408"
  },
  {
      "Date": "3/18/16",
      "Rate": "29382.81098"
  },
  {
      "Date": "3/17/16",
      "Rate": "29759.54426"
  },
  {
      "Date": "3/16/16",
      "Rate": "29288.27316"
  },
  {
      "Date": "3/15/16",
      "Rate": "29313.3318"
  },
  {
      "Date": "3/14/16",
      "Rate": "29753.90042"
  },
  {
      "Date": "3/11/16",
      "Rate": "29807.5698"
  },
  {
      "Date": "3/10/16",
      "Rate": "29605.00185"
  },
  {
      "Date": "3/9/16",
      "Rate": "29778.22184"
  },
  {
      "Date": "3/8/16",
      "Rate": "30243.44345"
  },
  {
      "Date": "3/7/16",
      "Rate": "29915.66342"
  },
  {
      "Date": "3/4/16",
      "Rate": "30007.70384"
  },
  {
      "Date": "3/3/16",
      "Rate": "29432.76248"
  },
  {
      "Date": "3/2/16",
      "Rate": "29204.83959"
  },
  {
      "Date": "3/1/16",
      "Rate": "29579.35413"
  },
  {
      "Date": "2/29/16",
      "Rate": "29698.13577"
  },
  {
      "Date": "2/26/16",
      "Rate": "29902.10409"
  },
  {
      "Date": "2/25/16",
      "Rate": "30035.78546"
  },
  {
      "Date": "2/24/16",
      "Rate": "29784.68051"
  },
  {
      "Date": "2/23/16",
      "Rate": "29472.51627"
  },
  {
      "Date": "2/22/16",
      "Rate": "29092.96813"
  },
  {
      "Date": "2/19/16",
      "Rate": "29527.55781"
  },
  {
      "Date": "2/18/16",
      "Rate": "29117.51177"
  },
  {
      "Date": "2/17/16",
      "Rate": "28984.20431"
  },
  {
      "Date": "2/16/16",
      "Rate": "29264.99233"
  },
  {
      "Date": "2/15/16",
      "Rate": "29048.49468"
  },
  {
      "Date": "2/12/16",
      "Rate": "29801.4286"
  },
  {
      "Date": "2/11/16",
      "Rate": "29547.42412"
  },
  {
      "Date": "2/10/16",
      "Rate": "28330.42911"
  },
  {
      "Date": "2/9/16",
      "Rate": "28487.13381"
  },
  {
      "Date": "2/8/16",
      "Rate": "28145.49816"
  },
  {
      "Date": "2/5/16",
      "Rate": "27714.34064"
  },
  {
      "Date": "2/4/16",
      "Rate": "27294.85529"
  },
  {
      "Date": "2/3/16",
      "Rate": "27061.0875"
  },
  {
      "Date": "2/2/16",
      "Rate": "26922.68294"
  },
  {
      "Date": "2/1/16",
      "Rate": "26870.45274"
  },
  {
      "Date": "1/29/16",
      "Rate": "26645.45406"
  },
  {
      "Date": "1/28/16",
      "Rate": "26858.56541"
  },
  {
      "Date": "1/27/16",
      "Rate": "26810.41288"
  },
  {
      "Date": "1/26/16",
      "Rate": "26630.08166"
  },
  {
      "Date": "1/25/16",
      "Rate": "26366.3592"
  },
  {
      "Date": "1/22/16",
      "Rate": "26168.01002"
  },
  {
      "Date": "1/21/16",
      "Rate": "26251.1614"
  },
  {
      "Date": "1/20/16",
      "Rate": "26270.92541"
  },
  {
      "Date": "1/19/16",
      "Rate": "25973.43163"
  },
  {
      "Date": "1/18/16",
      "Rate": "26026.36731"
  },
  {
      "Date": "1/15/16",
      "Rate": "25890.30494"
  },
  {
      "Date": "1/14/16",
      "Rate": "25922.10092"
  },
  {
      "Date": "1/13/16",
      "Rate": "25486.09323"
  },
  {
      "Date": "1/12/16",
      "Rate": "25820.727"
  },
  {
      "Date": "1/11/16",
      "Rate": "26030.30741"
  },
  {
      "Date": "1/8/16",
      "Rate": "25904.14646"
  },
  {
      "Date": "1/7/16",
      "Rate": "25785.44948"
  },
  {
      "Date": "1/6/16",
      "Rate": "25560.19683"
  },
  {
      "Date": "1/5/16",
      "Rate": "25271.35223"
  },
  {
      "Date": "1/4/16",
      "Rate": "25191.56599"
  },
  {
      "Date": "12/31/15",
      "Rate": "24817.55939"
  },
  {
      "Date": "12/30/15",
      "Rate": "25028.95642"
  },
  {
      "Date": "12/29/15",
      "Rate": "24951.69227"
  },
  {
      "Date": "12/24/15",
      "Rate": "24979.37883"
  },
  {
      "Date": "12/23/15",
      "Rate": "24958.83173"
  },
  {
      "Date": "12/22/15",
      "Rate": "25154.79638"
  },
  {
      "Date": "12/21/15",
      "Rate": "25019.33015"
  },
  {
      "Date": "12/18/15",
      "Rate": "24666.37507"
  },
  {
      "Date": "12/17/15",
      "Rate": "24965.99235"
  },
  {
      "Date": "12/16/15",
      "Rate": "25023.79936"
  },
  {
      "Date": "12/15/15",
      "Rate": "25214.5858"
  },
  {
      "Date": "12/14/15",
      "Rate": "25257.83524"
  },
  {
      "Date": "12/11/15",
      "Rate": "25295.90998"
  },
  {
      "Date": "12/10/15",
      "Rate": "25229.61604"
  },
  {
      "Date": "12/9/15",
      "Rate": "25434.37098"
  },
  {
      "Date": "12/8/15",
      "Rate": "25265.5814"
  },
  {
      "Date": "12/7/15",
      "Rate": "25500.23104"
  },
  {
      "Date": "12/4/15",
      "Rate": "25018.43771"
  },
  {
      "Date": "12/3/15",
      "Rate": "24767.39625"
  },
  {
      "Date": "12/2/15",
      "Rate": "25049.10845"
  },
  {
      "Date": "12/1/15",
      "Rate": "25072.82668"
  },
  {
      "Date": "11/30/15",
      "Rate": "24788.47599"
  },
  {
      "Date": "11/27/15",
      "Rate": "25111.61043"
  },
  {
      "Date": "11/26/15",
      "Rate": "25162.82121"
  },
  {
      "Date": "11/25/15",
      "Rate": "25075.28528"
  },
  {
      "Date": "11/24/15",
      "Rate": "25121.88927"
  },
  {
      "Date": "11/23/15",
      "Rate": "25003.07236"
  },
  {
      "Date": "11/20/15",
      "Rate": "25304.15351"
  },
  {
      "Date": "11/19/15",
      "Rate": "25009.91552"
  },
  {
      "Date": "11/18/15",
      "Rate": "24978.09485"
  },
  {
      "Date": "11/17/15",
      "Rate": "25154.83518"
  },
  {
      "Date": "11/16/15",
      "Rate": "25469.83192"
  },
  {
      "Date": "11/13/15",
      "Rate": "25263.47907"
  },
  {
      "Date": "11/12/15",
      "Rate": "25405.28757"
  },
  {
      "Date": "11/11/15",
      "Rate": "25428.64601"
  },
  {
      "Date": "11/10/15",
      "Rate": "25521.40602"
  },
  {
      "Date": "11/9/15",
      "Rate": "25693.72299"
  },
  {
      "Date": "11/6/15",
      "Rate": "25826.58953"
  },
  {
      "Date": "11/5/15",
      "Rate": "25726.80294"
  },
  {
      "Date": "11/4/15",
      "Rate": "25857.21441"
  },
  {
      "Date": "11/3/15",
      "Rate": "26138.13295"
  },
  {
      "Date": "11/2/15",
      "Rate": "26265.1687"
  },
  {
      "Date": "10/30/15",
      "Rate": "26482.11785"
  },
  {
      "Date": "10/29/15",
      "Rate": "26692.01573"
  },
  {
      "Date": "10/28/15",
      "Rate": "26808.8573"
  },
  {
      "Date": "10/27/15",
      "Rate": "26746.39412"
  },
  {
      "Date": "10/26/15",
      "Rate": "26713.45174"
  },
  {
      "Date": "10/23/15",
      "Rate": "26873.39107"
  },
  {
      "Date": "10/22/15",
      "Rate": "26799.23808"
  },
  {
      "Date": "10/21/15",
      "Rate": "27004.635"
  },
  {
      "Date": "10/20/15",
      "Rate": "26902.09351"
  },
  {
      "Date": "10/19/15",
      "Rate": "26830.63899"
  },
  {
      "Date": "10/16/15",
      "Rate": "26881.21131"
  },
  {
      "Date": "10/15/15",
      "Rate": "27040.29701"
  },
  {
      "Date": "10/14/15",
      "Rate": "26874.89021"
  },
  {
      "Date": "10/13/15",
      "Rate": "26500.2381"
  },
  {
      "Date": "10/12/15",
      "Rate": "26594.74065"
  },
  {
      "Date": "10/9/15",
      "Rate": "26295.72656"
  },
  {
      "Date": "10/8/15",
      "Rate": "26192.11274"
  },
  {
      "Date": "10/7/15",
      "Rate": "26273.40517"
  },
  {
      "Date": "10/6/15",
      "Rate": "26142.94432"
  },
  {
      "Date": "10/5/15",
      "Rate": "26059.74003"
  },
  {
      "Date": "10/2/15",
      "Rate": "25598.7513"
  },
  {
      "Date": "10/1/15",
      "Rate": "25796.44438"
  },
  {
      "Date": "9/30/15",
      "Rate": "25945.64984"
  },
  {
      "Date": "9/29/15",
      "Rate": "26147.5511"
  },
  {
      "Date": "9/28/15",
      "Rate": "26571.30461"
  },
  {
      "Date": "9/25/15",
      "Rate": "26754.7858"
  },
  {
      "Date": "9/24/15",
      "Rate": "26517.38479"
  },
  {
      "Date": "9/23/15",
      "Rate": "26268.10349"
  },
  {
      "Date": "9/22/15",
      "Rate": "26247.22835"
  },
  {
      "Date": "9/21/15",
      "Rate": "26316.39711"
  },
  {
      "Date": "9/18/15",
      "Rate": "26351.85806"
  },
  {
      "Date": "9/17/15",
      "Rate": "26130.06931"
  },
  {
      "Date": "9/16/15",
      "Rate": "25933.76956"
  },
  {
      "Date": "9/15/15",
      "Rate": "25885.26076"
  },
  {
      "Date": "9/14/15",
      "Rate": "25912.72862"
  },
  {
      "Date": "9/11/15",
      "Rate": "25899.19399"
  },
  {
      "Date": "9/10/15",
      "Rate": "25974.01012"
  },
  {
      "Date": "9/9/15",
      "Rate": "26317.31071"
  },
  {
      "Date": "9/8/15",
      "Rate": "26242.90375"
  },
  {
      "Date": "9/7/15",
      "Rate": "26456.14561"
  },
  {
      "Date": "9/4/15",
      "Rate": "26488.60121"
  },
  {
      "Date": "9/3/15",
      "Rate": "26348.44706"
  },
  {
      "Date": "9/2/15",
      "Rate": "26607.86963"
  },
  {
      "Date": "9/1/15",
      "Rate": "26739.9037"
  },
  {
      "Date": "8/28/15",
      "Rate": "26287.93453"
  },
  {
      "Date": "8/27/15",
      "Rate": "26279.30651"
  },
  {
      "Date": "8/26/15",
      "Rate": "26461.55664"
  },
  {
      "Date": "8/25/15",
      "Rate": "26915.67047"
  },
  {
      "Date": "8/24/15",
      "Rate": "27255.22496"
  },
  {
      "Date": "8/21/15",
      "Rate": "26844.18067"
  },
  {
      "Date": "8/20/15",
      "Rate": "26282.49175"
  },
  {
      "Date": "8/19/15",
      "Rate": "25834.21577"
  },
  {
      "Date": "8/18/15",
      "Rate": "25821.47481"
  },
  {
      "Date": "8/17/15",
      "Rate": "25778.12307"
  },
  {
      "Date": "8/14/15",
      "Rate": "25616.76573"
  },
  {
      "Date": "8/13/15",
      "Rate": "25752.75049"
  },
  {
      "Date": "8/12/15",
      "Rate": "25570.52153"
  },
  {
      "Date": "8/11/15",
      "Rate": "25272.59387"
  },
  {
      "Date": "8/10/15",
      "Rate": "24618.91391"
  },
  {
      "Date": "8/7/15",
      "Rate": "24537.02182"
  },
  {
      "Date": "8/6/15",
      "Rate": "24380.62752"
  },
  {
      "Date": "8/5/15",
      "Rate": "24425.86994"
  },
  {
      "Date": "8/4/15",
      "Rate": "24552.79282"
  },
  {
      "Date": "8/3/15",
      "Rate": "24693.02104"
  },
  {
      "Date": "7/31/15",
      "Rate": "24374.13006"
  },
  {
      "Date": "7/30/15",
      "Rate": "24549.14196"
  },
  {
      "Date": "7/29/15",
      "Rate": "24692.97871"
  },
  {
      "Date": "7/28/15",
      "Rate": "24647.03081"
  },
  {
      "Date": "7/27/15",
      "Rate": "24888.51302"
  },
  {
      "Date": "7/24/15",
      "Rate": "24501.7831"
  },
  {
      "Date": "7/23/15",
      "Rate": "24807.03011"
  },
  {
      "Date": "7/22/15",
      "Rate": "24629.76419"
  },
  {
      "Date": "7/21/15",
      "Rate": "24846.68513"
  },
  {
      "Date": "7/20/15",
      "Rate": "25025.7606"
  },
  {
      "Date": "7/17/15",
      "Rate": "25588.61356"
  },
  {
      "Date": "7/16/15",
      "Rate": "25626.05337"
  },
  {
      "Date": "7/15/15",
      "Rate": "25858.99575"
  },
  {
      "Date": "7/14/15",
      "Rate": "25751.06439"
  },
  {
      "Date": "7/13/15",
      "Rate": "25844.3253"
  },
  {
      "Date": "7/10/15",
      "Rate": "25965.5232"
  },
  {
      "Date": "7/9/15",
      "Rate": "25962.0981"
  },
  {
      "Date": "7/8/15",
      "Rate": "25846.78037"
  },
  {
      "Date": "7/7/15",
      "Rate": "26129.72716"
  },
  {
      "Date": "7/6/15",
      "Rate": "25975.47047"
  },
  {
      "Date": "7/3/15",
      "Rate": "26143.1348"
  },
  {
      "Date": "7/2/15",
      "Rate": "26020.77638"
  },
  {
      "Date": "7/1/15",
      "Rate": "26280.22716"
  },
  {
      "Date": "6/30/15",
      "Rate": "26365.43502"
  },
  {
      "Date": "6/29/15",
      "Rate": "26452.04677"
  },
  {
      "Date": "6/26/15",
      "Rate": "26329.6425"
  },
  {
      "Date": "6/25/15",
      "Rate": "26321.1591"
  },
  {
      "Date": "6/24/15",
      "Rate": "26348.29538"
  },
  {
      "Date": "6/23/15",
      "Rate": "26536.26695"
  },
  {
      "Date": "6/22/15",
      "Rate": "26721.16263"
  },
  {
      "Date": "6/19/15",
      "Rate": "26811.60514"
  },
  {
      "Date": "6/18/15",
      "Rate": "26882.83391"
  },
  {
      "Date": "6/17/15",
      "Rate": "26669.32397"
  },
  {
      "Date": "6/16/15",
      "Rate": "26776.64156"
  },
  {
      "Date": "6/15/15",
      "Rate": "26616.94915"
  },
  {
      "Date": "6/12/15",
      "Rate": "26634.75546"
  },
  {
      "Date": "6/11/15",
      "Rate": "26646.87561"
  },
  {
      "Date": "6/10/15",
      "Rate": "26691.62066"
  },
  {
      "Date": "6/9/15",
      "Rate": "26654.57945"
  },
  {
      "Date": "6/8/15",
      "Rate": "26518.27369"
  },
  {
      "Date": "6/5/15",
      "Rate": "26579.45996"
  },
  {
      "Date": "6/4/15",
      "Rate": "26671.99774"
  },
  {
      "Date": "6/3/15",
      "Rate": "26833.95827"
  },
  {
      "Date": "6/2/15",
      "Rate": "26698.49556"
  },
  {
      "Date": "6/1/15",
      "Rate": "26636.10998"
  },
  {
      "Date": "5/29/15",
      "Rate": "26743.11364"
  },
  {
      "Date": "5/28/15",
      "Rate": "26780.92735"
  },
  {
      "Date": "5/27/15",
      "Rate": "26788.64883"
  },
  {
      "Date": "5/26/15",
      "Rate": "26917.02499"
  },
  {
      "Date": "5/22/15",
      "Rate": "27112.35472"
  },
  {
      "Date": "5/21/15",
      "Rate": "27102.17464"
  },
  {
      "Date": "5/20/15",
      "Rate": "27112.12896"
  },
  {
      "Date": "5/19/15",
      "Rate": "27453.19318"
  },
  {
      "Date": "5/18/15",
      "Rate": "27585.47064"
  },
  {
      "Date": "5/15/15",
      "Rate": "27182.96266"
  },
  {
      "Date": "5/14/15",
      "Rate": "27155.17734"
  },
  {
      "Date": "5/13/15",
      "Rate": "26909.67389"
  },
  {
      "Date": "5/12/15",
      "Rate": "26859.70476"
  },
  {
      "Date": "5/11/15",
      "Rate": "26726.51017"
  },
  {
      "Date": "5/8/15",
      "Rate": "26640.16649"
  },
  {
      "Date": "5/7/15",
      "Rate": "26755.34313"
  },
  {
      "Date": "5/6/15",
      "Rate": "26744.76799"
  },
  {
      "Date": "5/5/15",
      "Rate": "26541.89316"
  },
  {
      "Date": "5/1/15",
      "Rate": "26420.5859"
  },
  {
      "Date": "4/30/15",
      "Rate": "27025.01279"
  },
  {
      "Date": "4/29/15",
      "Rate": "26945.92144"
  },
  {
      "Date": "4/28/15",
      "Rate": "26721.81167"
  },
  {
      "Date": "4/27/15",
      "Rate": "26391.68592"
  },
  {
      "Date": "4/24/15",
      "Rate": "26774.98369"
  },
  {
      "Date": "4/23/15",
      "Rate": "26492.94697"
  },
  {
      "Date": "4/22/15",
      "Rate": "26701.55382"
  },
  {
      "Date": "4/21/15",
      "Rate": "26578.17245"
  },
  {
      "Date": "4/20/15",
      "Rate": "26766.05584"
  },
  {
      "Date": "4/17/15",
      "Rate": "26562.1757"
  },
  {
      "Date": "4/16/15",
      "Rate": "26480.29419"
  },
  {
      "Date": "4/15/15",
      "Rate": "26169.31163"
  },
  {
      "Date": "4/14/15",
      "Rate": "26232.8295"
  },
  {
      "Date": "4/13/15",
      "Rate": "26369.81252"
  },
  {
      "Date": "4/10/15",
      "Rate": "26383.42122"
  },
  {
      "Date": "4/9/15",
      "Rate": "26266.39623"
  },
  {
      "Date": "4/8/15",
      "Rate": "26592.7653"
  },
  {
      "Date": "4/7/15",
      "Rate": "26539.51216"
  },
  {
      "Date": "4/2/15",
      "Rate": "26408.03894"
  },
  {
      "Date": "4/1/15",
      "Rate": "25962.95878"
  },
  {
      "Date": "3/31/15",
      "Rate": "25918.54177"
  },
  {
      "Date": "3/30/15",
      "Rate": "26180.97674"
  },
  {
      "Date": "3/27/15",
      "Rate": "26452.60057"
  },
  {
      "Date": "3/26/15",
      "Rate": "26829.10457"
  },
  {
      "Date": "3/25/15",
      "Rate": "26269.37336"
  },
  {
      "Date": "3/24/15",
      "Rate": "26270.27284"
  },
  {
      "Date": "3/23/15",
      "Rate": "25913.53992"
  },
  {
      "Date": "3/20/15",
      "Rate": "25738.86312"
  },
  {
      "Date": "3/19/15",
      "Rate": "25658.33965"
  },
  {
      "Date": "3/18/15",
      "Rate": "25395.98229"
  },
  {
      "Date": "3/17/15",
      "Rate": "25546.29182"
  },
  {
      "Date": "3/16/15",
      "Rate": "25632.43091"
  },
  {
      "Date": "3/13/15",
      "Rate": "25760.21799"
  },
  {
      "Date": "3/12/15",
      "Rate": "25595.75301"
  },
  {
      "Date": "3/11/15",
      "Rate": "25651.99033"
  },
  {
      "Date": "3/10/15",
      "Rate": "25747.62518"
  },
  {
      "Date": "3/9/15",
      "Rate": "25971.17762"
  },
  {
      "Date": "3/6/15",
      "Rate": "26335.07117"
  },
  {
      "Date": "3/5/15",
      "Rate": "26403.85192"
  },
  {
      "Date": "3/4/15",
      "Rate": "26451.36246"
  },
  {
      "Date": "3/3/15",
      "Rate": "26319.48712"
  },
  {
      "Date": "3/2/15",
      "Rate": "26555.87577"
  },
  {
      "Date": "2/27/15",
      "Rate": "26217.0973"
  },
  {
      "Date": "2/26/15",
      "Rate": "26574.82143"
  },
  {
      "Date": "2/25/15",
      "Rate": "26327.06044"
  },
  {
      "Date": "2/24/15",
      "Rate": "26236.52269"
  },
  {
      "Date": "2/23/15",
      "Rate": "26195.15688"
  },
  {
      "Date": "2/20/15",
      "Rate": "26420.15556"
  },
  {
      "Date": "2/19/15",
      "Rate": "26722.20321"
  },
  {
      "Date": "2/18/15",
      "Rate": "26482.90799"
  },
  {
      "Date": "2/17/15",
      "Rate": "26801.18521"
  },
  {
      "Date": "2/16/15",
      "Rate": "27020.08854"
  },
  {
      "Date": "2/13/15",
      "Rate": "26851.96564"
  },
  {
      "Date": "2/12/15",
      "Rate": "26830.59666"
  },
  {
      "Date": "2/11/15",
      "Rate": "27228.54724"
  },
  {
      "Date": "2/10/15",
      "Rate": "27200.25045"
  },
  {
      "Date": "2/9/15",
      "Rate": "27203.60853"
  },
  {
      "Date": "2/6/15",
      "Rate": "27654.22318"
  },
  {
      "Date": "2/5/15",
      "Rate": "27498.83772"
  },
  {
      "Date": "2/4/15",
      "Rate": "27674.39285"
  },
  {
      "Date": "2/3/15",
      "Rate": "27860.54781"
  },
  {
      "Date": "2/2/15",
      "Rate": "27715.00026"
  },
  {
      "Date": "1/30/15",
      "Rate": "27668.10702"
  },
  {
      "Date": "1/29/15",
      "Rate": "27868.70315"
  },
  {
      "Date": "1/28/15",
      "Rate": "27840.09595"
  },
  {
      "Date": "1/27/15",
      "Rate": "27700.1993"
  },
  {
      "Date": "1/26/15",
      "Rate": "27794.53959"
  },
  {
      "Date": "1/23/15",
      "Rate": "28027.46786"
  },
  {
      "Date": "1/22/15",
      "Rate": "27843.49989"
  },
  {
      "Date": "1/21/15",
      "Rate": "28183.35068"
  },
  {
      "Date": "1/20/15",
      "Rate": "28142.60216"
  },
  {
      "Date": "1/19/15",
      "Rate": "27746.55285"
  },
  {
      "Date": "1/16/15",
      "Rate": "27326.91935"
  },
  {
      "Date": "1/15/15",
      "Rate": "26948.75042"
  },
  {
      "Date": "1/14/15",
      "Rate": "26937.62147"
  },
  {
      "Date": "1/13/15",
      "Rate": "27083.66285"
  },
  {
      "Date": "1/12/15",
      "Rate": "26756.8846"
  },
  {
      "Date": "1/9/15",
      "Rate": "26539.01832"
  },
  {
      "Date": "1/8/15",
      "Rate": "26592.19739"
  },
  {
      "Date": "1/7/15",
      "Rate": "27072.88312"
  },
  {
      "Date": "1/6/15",
      "Rate": "27065.15106"
  },
  {
      "Date": "1/5/15",
      "Rate": "26678.98552"
  },
  {
      "Date": "1/2/15",
      "Rate": "26423.86285"
  },
  {
      "Date": "12/31/14",
      "Rate": "26745.64984"
  },
  {
      "Date": "12/30/14",
      "Rate": "26486.4107"
  },
  {
      "Date": "12/29/14",
      "Rate": "26822.7235"
  },
  {
      "Date": "12/24/14",
      "Rate": "26375.02249"
  },
  {
      "Date": "12/23/14",
      "Rate": "26399.62962"
  },
  {
      "Date": "12/22/14",
      "Rate": "26692.06864"
  },
  {
      "Date": "12/19/14",
      "Rate": "26781.59756"
  },
  {
      "Date": "12/18/14",
      "Rate": "26924.39373"
  },
  {
      "Date": "12/17/14",
      "Rate": "26820.22258"
  },
  {
      "Date": "12/16/14",
      "Rate": "27020.40248"
  },
  {
      "Date": "12/15/14",
      "Rate": "27200.50089"
  },
  {
      "Date": "12/12/14",
      "Rate": "27007.45692"
  },
  {
      "Date": "12/11/14",
      "Rate": "26881.30655"
  },
  {
      "Date": "12/10/14",
      "Rate": "26934.6761"
  },
  {
      "Date": "12/9/14",
      "Rate": "26393.44962"
  },
  {
      "Date": "12/8/14",
      "Rate": "26066.81599"
  },
  {
      "Date": "12/5/14",
      "Rate": "26311.45876"
  },
  {
      "Date": "12/4/14",
      "Rate": "26241.08009"
  },
  {
      "Date": "12/3/14",
      "Rate": "26270.14939"
  },
  {
      "Date": "12/2/14",
      "Rate": "26100.54851"
  },
  {
      "Date": "12/1/14",
      "Rate": "25743.77326"
  },
  {
      "Date": "11/28/14",
      "Rate": "26017.05145"
  },
  {
      "Date": "11/27/14",
      "Rate": "26093.02457"
  },
  {
      "Date": "11/26/14",
      "Rate": "26055.15441"
  },
  {
      "Date": "11/25/14",
      "Rate": "26231.77481"
  },
  {
      "Date": "11/24/14",
      "Rate": "26100.47091"
  },
  {
      "Date": "11/21/14",
      "Rate": "25938.59856"
  },
  {
      "Date": "11/20/14",
      "Rate": "26083.14785"
  },
  {
      "Date": "11/19/14",
      "Rate": "26254.1103"
  },
  {
      "Date": "11/18/14",
      "Rate": "26181.5905"
  },
  {
      "Date": "11/17/14",
      "Rate": "25869.52151"
  },
  {
      "Date": "11/14/14",
      "Rate": "25101.46916"
  },
  {
      "Date": "11/13/14",
      "Rate": "25213.39353"
  },
  {
      "Date": "11/12/14",
      "Rate": "25203.58031"
  },
  {
      "Date": "11/11/14",
      "Rate": "24977.89732"
  },
  {
      "Date": "11/10/14",
      "Rate": "25457.85993"
  },
  {
      "Date": "11/7/14",
      "Rate": "24788.37369"
  },
  {
      "Date": "11/6/14",
      "Rate": "24797.93647"
  },
  {
      "Date": "11/5/14",
      "Rate": "24810.75504"
  },
  {
      "Date": "11/4/14",
      "Rate": "25337.7026"
  },
  {
      "Date": "11/3/14",
      "Rate": "25368.76135"
  },
  {
      "Date": "10/31/14",
      "Rate": "25415.07258"
  },
  {
      "Date": "10/30/14",
      "Rate": "26098.037"
  },
  {
      "Date": "10/29/14",
      "Rate": "26545.84384"
  },
  {
      "Date": "10/28/14",
      "Rate": "26508.13947"
  },
  {
      "Date": "10/27/14",
      "Rate": "26627.01282"
  },
  {
      "Date": "10/24/14",
      "Rate": "26584.10907"
  },
  {
      "Date": "10/23/14",
      "Rate": "26772.95543"
  },
  {
      "Date": "10/22/14",
      "Rate": "26917.30013"
  },
  {
      "Date": "10/21/14",
      "Rate": "26979.77389"
  },
  {
      "Date": "10/20/14",
      "Rate": "26792.28205"
  },
  {
      "Date": "10/17/14",
      "Rate": "26738.65148"
  },
  {
      "Date": "10/16/14",
      "Rate": "26973.51276"
  },
  {
      "Date": "10/15/14",
      "Rate": "26509.63509"
  },
  {
      "Date": "10/14/14",
      "Rate": "26694.37556"
  },
  {
      "Date": "10/13/14",
      "Rate": "26411.56281"
  },
  {
      "Date": "10/10/14",
      "Rate": "26368.29926"
  },
  {
      "Date": "10/9/14",
      "Rate": "26471.1688"
  },
  {
      "Date": "10/8/14",
      "Rate": "26450.79807"
  },
  {
      "Date": "10/7/14",
      "Rate": "26120.36897"
  },
  {
      "Date": "10/6/14",
      "Rate": "26018.99152"
  },
  {
      "Date": "10/3/14",
      "Rate": "26329.71305"
  },
  {
      "Date": "10/2/14",
      "Rate": "26482.35066"
  },
  {
      "Date": "10/1/14",
      "Rate": "26310.17126"
  },
  {
      "Date": "9/30/14",
      "Rate": "26426.48371"
  },
  {
      "Date": "9/29/14",
      "Rate": "26456.13856"
  },
  {
      "Date": "9/26/14",
      "Rate": "26511.22242"
  },
  {
      "Date": "9/25/14",
      "Rate": "26266.39271"
  },
  {
      "Date": "9/24/14",
      "Rate": "26309.98783"
  },
  {
      "Date": "9/23/14",
      "Rate": "26385.71403"
  },
  {
      "Date": "9/22/14",
      "Rate": "26118.37599"
  },
  {
      "Date": "9/19/14",
      "Rate": "26250.3642"
  },
  {
      "Date": "9/18/14",
      "Rate": "26214.93854"
  },
  {
      "Date": "9/17/14",
      "Rate": "26550.32717"
  },
  {
      "Date": "9/16/14",
      "Rate": "26628.7871"
  },
  {
      "Date": "9/15/14",
      "Rate": "26608.78675"
  },
  {
      "Date": "9/12/14",
      "Rate": "26596.76185"
  },
  {
      "Date": "9/11/14",
      "Rate": "26750.48943"
  },
  {
      "Date": "9/10/14",
      "Rate": "26921.72349"
  },
  {
      "Date": "9/9/14",
      "Rate": "26941.78733"
  },
  {
      "Date": "9/8/14",
      "Rate": "26978.37352"
  },
  {
      "Date": "9/5/14",
      "Rate": "26861.70126"
  },
  {
      "Date": "9/4/14",
      "Rate": "27079.27829"
  },
  {
      "Date": "9/3/14",
      "Rate": "27038.99187"
  },
  {
      "Date": "9/2/14",
      "Rate": "27322.9228"
  },
  {
      "Date": "9/1/14",
      "Rate": "27480.66103"
  },
  {
      "Date": "8/29/14",
      "Rate": "27501.70197"
  },
  {
      "Date": "8/28/14",
      "Rate": "27535.28986"
  },
  {
      "Date": "8/27/14",
      "Rate": "27350.35891"
  },
  {
      "Date": "8/26/14",
      "Rate": "27444.23006"
  },
  {
      "Date": "8/22/14",
      "Rate": "27299.11286"
  },
  {
      "Date": "8/21/14",
      "Rate": "27349.43121"
  },
  {
      "Date": "8/20/14",
      "Rate": "27673.79319"
  },
  {
      "Date": "8/19/14",
      "Rate": "27867.57791"
  },
  {
      "Date": "8/18/14",
      "Rate": "27970.09824"
  },
  {
      "Date": "8/15/14",
      "Rate": "28203.04767"
  },
  {
      "Date": "8/14/14",
      "Rate": "28250.96739"
  },
  {
      "Date": "8/13/14",
      "Rate": "28216.1061"
  },
  {
      "Date": "8/12/14",
      "Rate": "28367.11759"
  },
  {
      "Date": "8/11/14",
      "Rate": "28216.70576"
  },
  {
      "Date": "8/8/14",
      "Rate": "28434.80132"
  },
  {
      "Date": "8/7/14",
      "Rate": "28239.19999"
  },
  {
      "Date": "8/6/14",
      "Rate": "27849.13314"
  },
  {
      "Date": "8/5/14",
      "Rate": "27837.47862"
  },
  {
      "Date": "8/4/14",
      "Rate": "27834.23694"
  },
  {
      "Date": "8/1/14",
      "Rate": "27641.70444"
  },
  {
      "Date": "7/31/14",
      "Rate": "27798.22572"
  },
  {
      "Date": "7/30/14",
      "Rate": "27655.31667"
  },
  {
      "Date": "7/29/14",
      "Rate": "27705.88194"
  },
  {
      "Date": "7/28/14",
      "Rate": "27662.11397"
  },
  {
      "Date": "7/25/14",
      "Rate": "27381.19544"
  },
  {
      "Date": "7/24/14",
      "Rate": "27550.85628"
  },
  {
      "Date": "7/23/14",
      "Rate": "27659.30263"
  },
  {
      "Date": "7/22/14",
      "Rate": "27715.80451"
  },
  {
      "Date": "7/21/14",
      "Rate": "27924.09743"
  },
  {
      "Date": "7/18/14",
      "Rate": "27882.93621"
  },
  {
      "Date": "7/17/14",
      "Rate": "27707.47632"
  },
  {
      "Date": "7/16/14",
      "Rate": "27486.89042"
  },
  {
      "Date": "7/15/14",
      "Rate": "27847.51759"
  },
  {
      "Date": "7/14/14",
      "Rate": "27998.40914"
  },
  {
      "Date": "7/11/14",
      "Rate": "28346.08723"
  },
  {
      "Date": "7/10/14",
      "Rate": "28457.74"
  },
  {
      "Date": "7/9/14",
      "Rate": "27864.19161"
  },
  {
      "Date": "7/8/14",
      "Rate": "27836.72375"
  },
  {
      "Date": "7/7/14",
      "Rate": "27752.67994"
  },
  {
      "Date": "7/4/14",
      "Rate": "27869.45802"
  },
  {
      "Date": "7/3/14",
      "Rate": "27785.81986"
  },
  {
      "Date": "7/2/14",
      "Rate": "27878.6222"
  },
  {
      "Date": "7/1/14",
      "Rate": "28093.76885"
  },
  {
      "Date": "6/30/14",
      "Rate": "27809.69329"
  },
  {
      "Date": "6/27/14",
      "Rate": "27880.54463"
  },
  {
      "Date": "6/26/14",
      "Rate": "27845.23184"
  },
  {
      "Date": "6/25/14",
      "Rate": "27830.16279"
  },
  {
      "Date": "6/24/14",
      "Rate": "28096.62957"
  },
  {
      "Date": "6/23/14",
      "Rate": "27864.53024"
  },
  {
      "Date": "6/20/14",
      "Rate": "27837.87721"
  },
  {
      "Date": "6/19/14",
      "Rate": "27198.32449"
  },
  {
      "Date": "6/18/14",
      "Rate": "26928.34089"
  },
  {
      "Date": "6/17/14",
      "Rate": "26905.33166"
  },
  {
      "Date": "6/16/14",
      "Rate": "27195.96113"
  },
  {
      "Date": "6/13/14",
      "Rate": "26823.28083"
  },
  {
      "Date": "6/12/14",
      "Rate": "26378.16187"
  },
  {
      "Date": "6/11/14",
      "Rate": "26416.1061"
  },
  {
      "Date": "6/10/14",
      "Rate": "26231.99351"
  },
  {
      "Date": "6/9/14",
      "Rate": "26214.24716"
  },
  {
      "Date": "6/6/14",
      "Rate": "26082.33302"
  },
  {
      "Date": "6/5/14",
      "Rate": "25955.80169"
  },
  {
      "Date": "6/4/14",
      "Rate": "26043.17889"
  },
  {
      "Date": "6/3/14",
      "Rate": "26026.34967"
  },
  {
      "Date": "6/2/14",
      "Rate": "25995.31561"
  },
  {
      "Date": "5/30/14",
      "Rate": "26217.46768"
  },
  {
      "Date": "5/29/14",
      "Rate": "26060.87938"
  },
  {
      "Date": "5/28/14",
      "Rate": "26305.16588"
  },
  {
      "Date": "5/27/14",
      "Rate": "26659.03808"
  },
  {
      "Date": "5/23/14",
      "Rate": "26599.94709"
  },
  {
      "Date": "5/22/14",
      "Rate": "26652.78753"
  },
  {
      "Date": "5/21/14",
      "Rate": "26735.52973"
  },
  {
      "Date": "5/20/14",
      "Rate": "26689.64885"
  },
  {
      "Date": "5/19/14",
      "Rate": "26804.28579"
  },
  {
      "Date": "5/16/14",
      "Rate": "26716.97914"
  },
  {
      "Date": "5/15/14",
      "Rate": "27374.63447"
  },
  {
      "Date": "5/14/14",
      "Rate": "27273.85668"
  },
  {
      "Date": "5/13/14",
      "Rate": "27085.07381"
  },
  {
      "Date": "5/12/14",
      "Rate": "27172.16882"
  },
  {
      "Date": "5/9/14",
      "Rate": "27218.3848"
  },
  {
      "Date": "5/8/14",
      "Rate": "27285.25371"
  },
  {
      "Date": "5/7/14",
      "Rate": "27705.13413"
  },
  {
      "Date": "5/6/14",
      "Rate": "27646.30064"
  },
  {
      "Date": "5/2/14",
      "Rate": "27197.60842"
  },
  {
      "Date": "5/1/14",
      "Rate": "27305.5292"
  },
  {
      "Date": "4/30/14",
      "Rate": "27470.18113"
  },
  {
      "Date": "4/29/14",
      "Rate": "27388.44777"
  },
  {
      "Date": "4/28/14",
      "Rate": "27868.11408"
  },
  {
      "Date": "4/25/14",
      "Rate": "27700.40741"
  },
  {
      "Date": "4/24/14",
      "Rate": "27682.6646"
  },
  {
      "Date": "4/23/14",
      "Rate": "27672.25172"
  },
  {
      "Date": "4/22/14",
      "Rate": "27701.98769"
  },
  {
      "Date": "4/17/14",
      "Rate": "27651.59879"
  },
  {
      "Date": "4/16/14",
      "Rate": "27601.37216"
  },
  {
      "Date": "4/15/14",
      "Rate": "27972.68382"
  },
  {
      "Date": "4/14/14",
      "Rate": "28198.79716"
  },
  {
      "Date": "4/11/14",
      "Rate": "28044.44523"
  },
  {
      "Date": "4/10/14",
      "Rate": "28103.22933"
  },
  {
      "Date": "4/9/14",
      "Rate": "27780.58872"
  },
  {
      "Date": "4/8/14",
      "Rate": "27937.42394"
  },
  {
      "Date": "4/7/14",
      "Rate": "27581.21307"
  },
  {
      "Date": "4/4/14",
      "Rate": "27308.84495"
  },
  {
      "Date": "4/3/14",
      "Rate": "27404.3775"
  },
  {
      "Date": "4/2/14",
      "Rate": "27105.10591"
  },
  {
      "Date": "4/1/14",
      "Rate": "27178.75448"
  },
  {
      "Date": "3/31/14",
      "Rate": "27337.20171"
  },
  {
      "Date": "3/28/14",
      "Rate": "27374.17238"
  },
  {
      "Date": "3/27/14",
      "Rate": "27507.47279"
  },
  {
      "Date": "3/26/14",
      "Rate": "27870.44216"
  },
  {
      "Date": "3/25/14",
      "Rate": "27928.6125"
  },
  {
      "Date": "3/24/14",
      "Rate": "28277.54281"
  },
  {
      "Date": "3/21/14",
      "Rate": "28761.2621"
  },
  {
      "Date": "3/20/14",
      "Rate": "28618.76929"
  },
  {
      "Date": "3/19/14",
      "Rate": "28960.40142"
  },
  {
      "Date": "3/18/14",
      "Rate": "29316.13256"
  },
  {
      "Date": "3/17/14",
      "Rate": "29815.38299"
  },
  {
      "Date": "3/14/14",
      "Rate": "29620.79402"
  },
  {
      "Date": "3/13/14",
      "Rate": "29690.29083"
  },
  {
      "Date": "3/12/14",
      "Rate": "29179.98554"
  },
  {
      "Date": "3/11/14",
      "Rate": "29034.81543"
  },
  {
      "Date": "3/10/14",
      "Rate": "28721.0039"
  },
  {
      "Date": "3/7/14",
      "Rate": "29121.52595"
  },
  {
      "Date": "3/6/14",
      "Rate": "28673.23233"
  },
  {
      "Date": "3/5/14",
      "Rate": "28876.54809"
  },
  {
      "Date": "3/4/14",
      "Rate": "29251.46475"
  },
  {
      "Date": "3/3/14",
      "Rate": "29475.63096"
  },
  {
      "Date": "2/28/14",
      "Rate": "28944.28826"
  },
  {
      "Date": "2/27/14",
      "Rate": "29170.07355"
  },
  {
      "Date": "2/26/14",
      "Rate": "29363.29741"
  },
  {
      "Date": "2/25/14",
      "Rate": "29058.92873"
  },
  {
      "Date": "2/24/14",
      "Rate": "29088.83049"
  },
  {
      "Date": "2/21/14",
      "Rate": "28954.51772"
  },
  {
      "Date": "2/20/14",
      "Rate": "28801.05822"
  },
  {
      "Date": "2/19/14",
      "Rate": "28917.41653"
  },
  {
      "Date": "2/18/14",
      "Rate": "28809.5522"
  },
  {
      "Date": "2/17/14",
      "Rate": "28907.31053"
  },
  {
      "Date": "2/14/14",
      "Rate": "28550.49648"
  },
  {
      "Date": "2/13/14",
      "Rate": "28316.13961"
  },
  {
      "Date": "2/12/14",
      "Rate": "28072.28699"
  },
  {
      "Date": "2/11/14",
      "Rate": "28094.07926"
  },
  {
      "Date": "2/10/14",
      "Rate": "28001.32277"
  },
  {
      "Date": "2/7/14",
      "Rate": "27586.04208"
  },
  {
      "Date": "2/6/14",
      "Rate": "27585.60821"
  },
  {
      "Date": "2/5/14",
      "Rate": "27707.02834"
  },
  {
      "Date": "2/4/14",
      "Rate": "27527.59308"
  },
  {
      "Date": "2/3/14",
      "Rate": "27578.91321"
  },
  {
      "Date": "1/31/14",
      "Rate": "27515.81862"
  },
  {
      "Date": "1/30/14",
      "Rate": "27639.13297"
  },
  {
      "Date": "1/29/14",
      "Rate": "27845.32002"
  },
  {
      "Date": "1/28/14",
      "Rate": "27743.51576"
  },
  {
      "Date": "1/27/14",
      "Rate": "28439.7679"
  },
  {
      "Date": "1/24/14",
      "Rate": "27851.4577"
  },
  {
      "Date": "1/23/14",
      "Rate": "27318.24194"
  },
  {
      "Date": "1/22/14",
      "Rate": "27055.2426"
  },
  {
      "Date": "1/21/14",
      "Rate": "27273.39107"
  },
  {
      "Date": "1/20/14",
      "Rate": "27240.94958"
  },
  {
      "Date": "1/17/14",
      "Rate": "26987.52006"
  },
  {
      "Date": "1/16/14",
      "Rate": "26835.70433"
  },
  {
      "Date": "1/15/14",
      "Rate": "26893.67714"
  },
  {
      "Date": "1/14/14",
      "Rate": "27083.15491"
  },
  {
      "Date": "1/13/14",
      "Rate": "27012.74449"
  },
  {
      "Date": "1/10/14",
      "Rate": "26733.55791"
  },
  {
      "Date": "1/9/14",
      "Rate": "26838.41338"
  },
  {
      "Date": "1/8/14",
      "Rate": "26881.80744"
  },
  {
      "Date": "1/7/14",
      "Rate": "27135.12055"
  },
  {
      "Date": "1/6/14",
      "Rate": "27225.56306"
  },
  {
      "Date": "1/3/14",
      "Rate": "27044.78033"
  },
  {
      "Date": "1/2/14",
      "Rate": "26811.30884"
  },
  {
      "Date": "12/31/13",
      "Rate": "26215.19956"
  },
  {
      "Date": "12/30/13",
      "Rate": "26200.15521"
  },
  {
      "Date": "12/27/13",
      "Rate": "26439.31992"
  },
  {
      "Date": "12/24/13",
      "Rate": "26084.79162"
  },
  {
      "Date": "12/23/13",
      "Rate": "25988.52537"
  },
  {
      "Date": "12/20/13",
      "Rate": "26110.68273"
  },
  {
      "Date": "12/19/13",
      "Rate": "26545.30062"
  },
  {
      "Date": "12/18/13",
      "Rate": "26994.94876"
  },
  {
      "Date": "12/17/13",
      "Rate": "26924.73589"
  },
  {
      "Date": "12/16/13",
      "Rate": "26796.41616"
  },
  {
      "Date": "12/13/13",
      "Rate": "26816.43768"
  },
  {
      "Date": "12/12/13",
      "Rate": "27274.3611"
  },
  {
      "Date": "12/11/13",
      "Rate": "27221.25258"
  },
  {
      "Date": "12/10/13",
      "Rate": "26831.33741"
  },
  {
      "Date": "12/9/13",
      "Rate": "26389.8164"
  },
  {
      "Date": "12/6/13",
      "Rate": "26567.30454"
  },
  {
      "Date": "12/5/13",
      "Rate": "26786.54297"
  },
  {
      "Date": "12/4/13",
      "Rate": "26434.24046"
  },
  {
      "Date": "12/3/13",
      "Rate": "26852.44184"
  },
  {
      "Date": "12/2/13",
      "Rate": "27223.29847"
  },
  {
      "Date": "11/29/13",
      "Rate": "27381.92561"
  },
  {
      "Date": "11/28/13",
      "Rate": "27297.29978"
  },
  {
      "Date": "11/27/13",
      "Rate": "27572.57447"
  },
  {
      "Date": "11/26/13",
      "Rate": "27545.66394"
  },
  {
      "Date": "11/25/13",
      "Rate": "27057.91989"
  },
  {
      "Date": "11/22/13",
      "Rate": "27441.62684"
  },
  {
      "Date": "11/21/13",
      "Rate": "27740.53158"
  },
  {
      "Date": "11/20/13",
      "Rate": "27966.9906"
  },
  {
      "Date": "11/19/13",
      "Rate": "27947.8086"
  },
  {
      "Date": "11/18/13",
      "Rate": "28115.93855"
  },
  {
      "Date": "11/15/13",
      "Rate": "28508.38639"
  },
  {
      "Date": "11/14/13",
      "Rate": "28543.6498"
  },
  {
      "Date": "11/13/13",
      "Rate": "28485.9098"
  },
  {
      "Date": "11/12/13",
      "Rate": "28934.16462"
  },
  {
      "Date": "11/11/13",
      "Rate": "28761.48786"
  },
  {
      "Date": "11/8/13",
      "Rate": "29251.70109"
  },
  {
      "Date": "11/7/13",
      "Rate": "29138.19291"
  },
  {
      "Date": "11/6/13",
      "Rate": "29036.90012"
  },
  {
      "Date": "11/5/13",
      "Rate": "28774.00307"
  },
  {
      "Date": "11/4/13",
      "Rate": "28842.15242"
  },
  {
      "Date": "11/1/13",
      "Rate": "28853.12616"
  },
  {
      "Date": "10/31/13",
      "Rate": "29093.43375"
  },
  {
      "Date": "10/30/13",
      "Rate": "29170.65909"
  },
  {
      "Date": "10/29/13",
      "Rate": "29208.26822"
  },
  {
      "Date": "10/28/13",
      "Rate": "29314.35828"
  },
  {
      "Date": "10/25/13",
      "Rate": "29095.09515"
  },
  {
      "Date": "10/24/13",
      "Rate": "28971.44923"
  },
  {
      "Date": "10/23/13",
      "Rate": "28922.14325"
  },
  {
      "Date": "10/22/13",
      "Rate": "28329.90353"
  },
  {
      "Date": "10/21/13",
      "Rate": "28597.62606"
  },
  {
      "Date": "10/18/13",
      "Rate": "28407.9825"
  },
  {
      "Date": "10/17/13",
      "Rate": "28187.24845"
  },
  {
      "Date": "10/16/13",
      "Rate": "27850.11023"
  },
  {
      "Date": "10/15/13",
      "Rate": "27354.44011"
  },
  {
      "Date": "10/14/13",
      "Rate": "27633.19988"
  },
  {
      "Date": "10/11/13",
      "Rate": "27667.60613"
  },
  {
      "Date": "10/10/13",
      "Rate": "27961.06104"
  },
  {
      "Date": "10/9/13",
      "Rate": "28594.39496"
  },
  {
      "Date": "10/8/13",
      "Rate": "28785.259"
  },
  {
      "Date": "10/7/13",
      "Rate": "28509.78324"
  },
  {
      "Date": "10/4/13",
      "Rate": "28495.96289"
  },
  {
      "Date": "10/3/13",
      "Rate": "28635.28457"
  },
  {
      "Date": "10/2/13",
      "Rate": "28564.08402"
  },
  {
      "Date": "10/1/13",
      "Rate": "29400.24339"
  },
  {
      "Date": "9/30/13",
      "Rate": "29521.53654"
  },
  {
      "Date": "9/27/13",
      "Rate": "29321.03564"
  },
  {
      "Date": "9/26/13",
      "Rate": "29009.76384"
  },
  {
      "Date": "9/25/13",
      "Rate": "28986.40188"
  },
  {
      "Date": "9/24/13",
      "Rate": "29144.61983"
  },
  {
      "Date": "9/23/13",
      "Rate": "29186.24667"
  },
  {
      "Date": "9/20/13",
      "Rate": "29997.68603"
  },
  {
      "Date": "9/19/13",
      "Rate": "29992.71945"
  },
  {
      "Date": "9/18/13",
      "Rate": "29181.85153"
  },
  {
      "Date": "9/17/13",
      "Rate": "29319.20492"
  },
  {
      "Date": "9/16/13",
      "Rate": "29193.99284"
  },
  {
      "Date": "9/13/13",
      "Rate": "29215.79217"
  },
  {
      "Date": "9/12/13",
      "Rate": "30046.24067"
  },
  {
      "Date": "9/11/13",
      "Rate": "30534.94418"
  },
  {
      "Date": "9/10/13",
      "Rate": "30841.46458"
  },
  {
      "Date": "9/9/13",
      "Rate": "31909.85026"
  },
  {
      "Date": "9/6/13",
      "Rate": "31501.19402"
  },
  {
      "Date": "9/5/13",
      "Rate": "32534.13993"
  },
  {
      "Date": "9/4/13",
      "Rate": "32779.50228"
  },
  {
      "Date": "9/3/13",
      "Rate": "33554.99039"
  },
  {
      "Date": "9/2/13",
      "Rate": "32394.02459"
  },
  {
      "Date": "8/30/13",
      "Rate": "32728.97582"
  },
  {
      "Date": "8/29/13",
      "Rate": "33309.12009"
  },
  {
      "Date": "8/28/13",
      "Rate": "33880.73511"
  },
  {
      "Date": "8/27/13",
      "Rate": "33436.56149"
  },
  {
      "Date": "8/23/13",
      "Rate": "31138.9125"
  },
  {
      "Date": "8/22/13",
      "Rate": "31123.22616"
  },
  {
      "Date": "8/21/13",
      "Rate": "31019.10087"
  },
  {
      "Date": "8/20/13",
      "Rate": "30499.5432"
  },
  {
      "Date": "8/19/13",
      "Rate": "30913.35297"
  },
  {
      "Date": "8/16/13",
      "Rate": "29877.02781"
  },
  {
      "Date": "8/15/13",
      "Rate": "28973.39988"
  },
  {
      "Date": "8/14/13",
      "Rate": "28568.23577"
  },
  {
      "Date": "8/13/13",
      "Rate": "28885.0209"
  },
  {
      "Date": "8/12/13",
      "Rate": "28598.82538"
  },
  {
      "Date": "8/9/13",
      "Rate": "27899.51146"
  },
  {
      "Date": "8/8/13",
      "Rate": "27506.55214"
  },
  {
      "Date": "8/7/13",
      "Rate": "27582.33126"
  },
  {
      "Date": "8/6/13",
      "Rate": "27697.59608"
  },
  {
      "Date": "8/5/13",
      "Rate": "28155.78405"
  },
  {
      "Date": "8/2/13",
      "Rate": "27713.27537"
  },
  {
      "Date": "8/1/13",
      "Rate": "28219.48535"
  },
  {
      "Date": "7/31/13",
      "Rate": "28614.83977"
  },
  {
      "Date": "7/30/13",
      "Rate": "28369.4104"
  },
  {
      "Date": "7/29/13",
      "Rate": "27835.93009"
  },
  {
      "Date": "7/26/13",
      "Rate": "27714.63694"
  },
  {
      "Date": "7/25/13",
      "Rate": "27288.69292"
  },
  {
      "Date": "7/24/13",
      "Rate": "27998.69486"
  },
  {
      "Date": "7/23/13",
      "Rate": "27799.06171"
  },
  {
      "Date": "7/22/13",
      "Rate": "27517.40948"
  },
  {
      "Date": "7/19/13",
      "Rate": "26947.50525"
  },
  {
      "Date": "7/18/13",
      "Rate": "26888.767"
  },
  {
      "Date": "7/17/13",
      "Rate": "26854.20907"
  },
  {
      "Date": "7/16/13",
      "Rate": "26698.01231"
  },
  {
      "Date": "7/15/13",
      "Rate": "26805.03713"
  },
  {
      "Date": "7/12/13",
      "Rate": "26923.88931"
  },
  {
      "Date": "7/11/13",
      "Rate": "27079.19364"
  },
  {
      "Date": "7/10/13",
      "Rate": "26489.86049"
  },
  {
      "Date": "7/9/13",
      "Rate": "26542.86672"
  },
  {
      "Date": "7/8/13",
      "Rate": "26241.7256"
  },
  {
      "Date": "7/5/13",
      "Rate": "26401.31925"
  },
  {
      "Date": "7/4/13",
      "Rate": "26495.59604"
  },
  {
      "Date": "7/3/13",
      "Rate": "26474.12124"
  },
  {
      "Date": "7/2/13",
      "Rate": "26467.32394"
  },
  {
      "Date": "7/1/13",
      "Rate": "26052.99212"
  },
  {
      "Date": "6/28/13",
      "Rate": "25311.13776"
  },
  {
      "Date": "6/27/13",
      "Rate": "26141.89316"
  },
  {
      "Date": "6/26/13",
      "Rate": "26327.94229"
  },
  {
      "Date": "6/25/13",
      "Rate": "27062.53373"
  },
  {
      "Date": "6/24/13",
      "Rate": "27147.89326"
  },
  {
      "Date": "6/21/13",
      "Rate": "26982.40181"
  },
  {
      "Date": "6/20/13",
      "Rate": "27529.63192"
  },
  {
      "Date": "6/19/13",
      "Rate": "28299.1058"
  },
  {
      "Date": "6/18/13",
      "Rate": "28618.84689"
  },
  {
      "Date": "6/17/13",
      "Rate": "28336.99007"
  },
  {
      "Date": "6/14/13",
      "Rate": "28051.03088"
  },
  {
      "Date": "6/13/13",
      "Rate": "28364.10519"
  },
  {
      "Date": "6/12/13",
      "Rate": "28102.22403"
  },
  {
      "Date": "6/11/13",
      "Rate": "28175.48458"
  },
  {
      "Date": "6/10/13",
      "Rate": "28246.9391"
  },
  {
      "Date": "6/7/13",
      "Rate": "28464.0999"
  },
  {
      "Date": "6/6/13",
      "Rate": "28042.32879"
  },
  {
      "Date": "6/5/13",
      "Rate": "28051.17903"
  },
  {
      "Date": "6/4/13",
      "Rate": "27998.92414"
  },
  {
      "Date": "6/3/13",
      "Rate": "27814.78333"
  },
  {
      "Date": "5/31/13",
      "Rate": "28173.16355"
  },
  {
      "Date": "5/30/13",
      "Rate": "28038.68851"
  },
  {
      "Date": "5/29/13",
      "Rate": "27478.04723"
  },
  {
      "Date": "5/28/13",
      "Rate": "27266.74192"
  },
  {
      "Date": "5/24/13",
      "Rate": "27243.72917"
  },
  {
      "Date": "5/23/13",
      "Rate": "27268.2587"
  },
  {
      "Date": "5/22/13",
      "Rate": "27160.66245"
  },
  {
      "Date": "5/21/13",
      "Rate": "26931.0852"
  },
  {
      "Date": "5/20/13",
      "Rate": "26242.2018"
  },
  {
      "Date": "5/17/13",
      "Rate": "26794.89233"
  },
  {
      "Date": "5/16/13",
      "Rate": "26556.89518"
  },
  {
      "Date": "5/15/13",
      "Rate": "27311.45523"
  },
  {
      "Date": "5/14/13",
      "Rate": "27709.48341"
  },
  {
      "Date": "5/13/13",
      "Rate": "27659.97284"
  },
  {
      "Date": "5/10/13",
      "Rate": "28088.34018"
  },
  {
      "Date": "5/9/13",
      "Rate": "28164.61666"
  },
  {
      "Date": "5/8/13",
      "Rate": "27729.064"
  },
  {
      "Date": "5/7/13",
      "Rate": "27905.8643"
  },
  {
      "Date": "5/3/13",
      "Rate": "27991.49544"
  },
  {
      "Date": "5/2/13",
      "Rate": "27659.35202"
  },
  {
      "Date": "5/1/13",
      "Rate": "27806.95603"
  },
  {
      "Date": "4/30/13",
      "Rate": "27868.45271"
  },
  {
      "Date": "4/29/13",
      "Rate": "28183.1602"
  },
  {
      "Date": "4/26/13",
      "Rate": "28061.76829"
  },
  {
      "Date": "4/25/13",
      "Rate": "27606.44103"
  },
  {
      "Date": "4/24/13",
      "Rate": "27322.89458"
  },
  {
      "Date": "4/23/13",
      "Rate": "27183.83393"
  },
  {
      "Date": "4/22/13",
      "Rate": "27246.38177"
  },
  {
      "Date": "4/19/13",
      "Rate": "26901.38803"
  },
  {
      "Date": "4/18/13",
      "Rate": "26577.96434"
  },
  {
      "Date": "4/17/13",
      "Rate": "26352.25665"
  },
  {
      "Date": "4/16/13",
      "Rate": "26284.53765"
  },
  {
      "Date": "4/15/13",
      "Rate": "27179.18835"
  },
  {
      "Date": "4/12/13",
      "Rate": "29822.04977"
  },
  {
      "Date": "4/11/13",
      "Rate": "29916.47472"
  },
  {
      "Date": "4/10/13",
      "Rate": "30411.63689"
  },
  {
      "Date": "4/9/13",
      "Rate": "30172.01009"
  },
  {
      "Date": "4/8/13",
      "Rate": "30352.16494"
  },
  {
      "Date": "4/5/13",
      "Rate": "29995.71421"
  },
  {
      "Date": "4/4/13",
      "Rate": "29928.77829"
  },
  {
      "Date": "4/3/13",
      "Rate": "30139.52274"
  },
  {
      "Date": "4/2/13",
      "Rate": "30594.49373"
  },
  {
      "Date": "3/28/13",
      "Rate": "30798.50086"
  },
  {
      "Date": "3/27/13",
      "Rate": "30566.26043"
  },
  {
      "Date": "3/26/13",
      "Rate": "30686.33309"
  },
  {
      "Date": "3/25/13",
      "Rate": "30703.26814"
  },
  {
      "Date": "3/22/13",
      "Rate": "30880.52347"
  },
  {
      "Date": "3/21/13",
      "Rate": "30867.54969"
  },
  {
      "Date": "3/20/13",
      "Rate": "30914.62989"
  },
  {
      "Date": "3/19/13",
      "Rate": "30809.80617"
  },
  {
      "Date": "3/18/13",
      "Rate": "30582.86742"
  },
  {
      "Date": "3/15/13",
      "Rate": "30373.44574"
  },
  {
      "Date": "3/14/13",
      "Rate": "30255.30609"
  },
  {
      "Date": "3/13/13",
      "Rate": "30559.0222"
  },
  {
      "Date": "3/12/13",
      "Rate": "30241.07656"
  },
  {
      "Date": "3/11/13",
      "Rate": "30234.55793"
  },
  {
      "Date": "3/8/13",
      "Rate": "30213.84857"
  },
  {
      "Date": "3/7/13",
      "Rate": "30367.32217"
  },
  {
      "Date": "3/6/13",
      "Rate": "30470.06826"
  },
  {
      "Date": "3/5/13",
      "Rate": "30685.25724"
  },
  {
      "Date": "3/4/13",
      "Rate": "30544.7186"
  },
  {
      "Date": "3/1/13",
      "Rate": "30500.62611"
  },
  {
      "Date": "2/28/13",
      "Rate": "30512.94379"
  },
  {
      "Date": "2/27/13",
      "Rate": "30479.7686"
  },
  {
      "Date": "2/26/13",
      "Rate": "30497.58902"
  },
  {
      "Date": "2/25/13",
      "Rate": "30317.01794"
  },
  {
      "Date": "2/22/13",
      "Rate": "30262.9676"
  },
  {
      "Date": "2/21/13",
      "Rate": "30222.51186"
  },
  {
      "Date": "2/20/13",
      "Rate": "30641.96899"
  },
  {
      "Date": "2/19/13",
      "Rate": "30930.23157"
  },
  {
      "Date": "2/18/13",
      "Rate": "30887.09854"
  },
  {
      "Date": "2/15/13",
      "Rate": "31214.91032"
  },
  {
      "Date": "2/14/13",
      "Rate": "31300.34039"
  },
  {
      "Date": "2/13/13",
      "Rate": "31271.86017"
  },
  {
      "Date": "2/12/13",
      "Rate": "31101.14111"
  },
  {
      "Date": "2/11/13",
      "Rate": "31642.2653"
  },
  {
      "Date": "2/8/13",
      "Rate": "31584.45122"
  },
  {
      "Date": "2/7/13",
      "Rate": "31609.28059"
  },
  {
      "Date": "2/6/13",
      "Rate": "31276.97138"
  },
  {
      "Date": "2/5/13",
      "Rate": "31379.44937"
  },
  {
      "Date": "2/4/13",
      "Rate": "31298.43207"
  },
  {
      "Date": "2/1/13",
      "Rate": "31165.71015"
  },
  {
      "Date": "1/31/13",
      "Rate": "31508.9155"
  },
  {
      "Date": "1/30/13",
      "Rate": "31271.39103"
  },
  {
      "Date": "1/29/13",
      "Rate": "31368.48269"
  },
  {
      "Date": "1/28/13",
      "Rate": "31554.77874"
  },
  {
      "Date": "1/25/13",
      "Rate": "31697.01406"
  },
  {
      "Date": "1/24/13",
      "Rate": "31751.17021"
  },
  {
      "Date": "1/23/13",
      "Rate": "32045.8703"
  },
  {
      "Date": "1/22/13",
      "Rate": "32002.84308"
  },
  {
      "Date": "1/21/13",
      "Rate": "31911.80091"
  },
  {
      "Date": "1/18/13",
      "Rate": "32050.95328"
  },
  {
      "Date": "1/17/13",
      "Rate": "32160.44022"
  },
  {
      "Date": "1/16/13",
      "Rate": "32389.82345"
  },
  {
      "Date": "1/15/13",
      "Rate": "32408.00014"
  },
  {
      "Date": "1/14/13",
      "Rate": "32093.72299"
  },
  {
      "Date": "1/11/13",
      "Rate": "32280.51288"
  },
  {
      "Date": "1/10/13",
      "Rate": "32002.3175"
  },
  {
      "Date": "1/9/13",
      "Rate": "32182.1055"
  },
  {
      "Date": "1/8/13",
      "Rate": "32069.31692"
  },
  {
      "Date": "1/7/13",
      "Rate": "32156.81758"
  },
  {
      "Date": "1/4/13",
      "Rate": "31594.81472"
  },
  {
      "Date": "1/3/13",
      "Rate": "32374.46163"
  },
  {
      "Date": "1/2/13",
      "Rate": "32198.17986"
  },
  {
      "Date": "12/31/12",
      "Rate": "32159.49488"
  },
  {
      "Date": "12/28/12",
      "Rate": "32069.73315"
  },
  {
      "Date": "12/27/12",
      "Rate": "32057.53188"
  },
  {
      "Date": "12/24/12",
      "Rate": "32344.55281"
  },
  {
      "Date": "12/21/12",
      "Rate": "32038.24406"
  },
  {
      "Date": "12/20/12",
      "Rate": "32285.0985"
  },
  {
      "Date": "12/19/12",
      "Rate": "32270.8725"
  },
  {
      "Date": "12/18/12",
      "Rate": "32935.51209"
  },
  {
      "Date": "12/17/12",
      "Rate": "32623.23851"
  },
  {
      "Date": "12/14/12",
      "Rate": "32593.12157"
  },
  {
      "Date": "12/13/12",
      "Rate": "32565.48087"
  },
  {
      "Date": "12/12/12",
      "Rate": "32761.57957"
  },
  {
      "Date": "12/11/12",
      "Rate": "32745.156"
  },
  {
      "Date": "12/10/12",
      "Rate": "32763.39971"
  },
  {
      "Date": "12/7/12",
      "Rate": "32488.99628"
  },
  {
      "Date": "12/6/12",
      "Rate": "32436.30399"
  },
  {
      "Date": "12/5/12",
      "Rate": "32784.08085"
  },
  {
      "Date": "12/4/12",
      "Rate": "32895.40204"
  },
  {
      "Date": "12/3/12",
      "Rate": "33091.02101"
  },
  {
      "Date": "11/30/12",
      "Rate": "33160.42964"
  },
  {
      "Date": "11/29/12",
      "Rate": "33331.79774"
  },
  {
      "Date": "11/28/12",
      "Rate": "34062.17746"
  },
  {
      "Date": "11/27/12",
      "Rate": "34184.45475"
  },
  {
      "Date": "11/26/12",
      "Rate": "34264.57609"
  },
  {
      "Date": "11/23/12",
      "Rate": "33903.17995"
  },
  {
      "Date": "11/22/12",
      "Rate": "33695.63484"
  },
  {
      "Date": "11/21/12",
      "Rate": "33588.46893"
  },
  {
      "Date": "11/20/12",
      "Rate": "33711.14482"
  },
  {
      "Date": "11/19/12",
      "Rate": "33338.03065"
  },
  {
      "Date": "11/16/12",
      "Rate": "33298.84125"
  },
  {
      "Date": "11/15/12",
      "Rate": "33309.4305"
  },
  {
      "Date": "11/14/12",
      "Rate": "33438.25112"
  },
  {
      "Date": "11/13/12",
      "Rate": "33443.09776"
  },
  {
      "Date": "11/12/12",
      "Rate": "33656.389"
  },
  {
      "Date": "11/9/12",
      "Rate": "33424.02159"
  },
  {
      "Date": "11/8/12",
      "Rate": "32930.39736"
  },
  {
      "Date": "11/7/12",
      "Rate": "33191.39315"
  },
  {
      "Date": "11/6/12",
      "Rate": "32483.96268"
  },
  {
      "Date": "11/5/12",
      "Rate": "32322.06212"
  },
  {
      "Date": "11/2/12",
      "Rate": "32445.27064"
  },
  {
      "Date": "11/1/12",
      "Rate": "32632.91416"
  },
  {
      "Date": "10/31/12",
      "Rate": "32636.51564"
  },
  {
      "Date": "10/30/12",
      "Rate": "32647.73629"
  },
  {
      "Date": "10/29/12",
      "Rate": "32721.81873"
  },
  {
      "Date": "10/26/12",
      "Rate": "32316.46414"
  },
  {
      "Date": "10/25/12",
      "Rate": "32525.08157"
  },
  {
      "Date": "10/24/12",
      "Rate": "32498.23101"
  },
  {
      "Date": "10/23/12",
      "Rate": "32659.9164"
  },
  {
      "Date": "10/22/12",
      "Rate": "32586.95215"
  },
  {
      "Date": "10/19/12",
      "Rate": "33063.40853"
  },
  {
      "Date": "10/18/12",
      "Rate": "32972.11591"
  },
  {
      "Date": "10/17/12",
      "Rate": "32603.66497"
  },
  {
      "Date": "10/16/12",
      "Rate": "32406.32463"
  },
  {
      "Date": "10/15/12",
      "Rate": "32717.60349"
  },
  {
      "Date": "10/12/12",
      "Rate": "32969.00122"
  },
  {
      "Date": "10/11/12",
      "Rate": "32836.51916"
  },
  {
      "Date": "10/10/12",
      "Rate": "32990.75821"
  },
  {
      "Date": "10/9/12",
      "Rate": "32957.76645"
  },
  {
      "Date": "10/8/12",
      "Rate": "32850.31835"
  },
  {
      "Date": "10/5/12",
      "Rate": "32735.16288"
  },
  {
      "Date": "10/4/12",
      "Rate": "32488.4072"
  },
  {
      "Date": "10/3/12",
      "Rate": "32759.02573"
  },
  {
      "Date": "10/2/12",
      "Rate": "32913.808"
  },
  {
      "Date": "10/1/12",
      "Rate": "32728.28445"
  },
  {
      "Date": "9/28/12",
      "Rate": "33205.08298"
  },
  {
      "Date": "9/27/12",
      "Rate": "32824.06392"
  },
  {
      "Date": "9/26/12",
      "Rate": "33350.08378"
  },
  {
      "Date": "9/25/12",
      "Rate": "33219.84867"
  },
  {
      "Date": "9/24/12",
      "Rate": "33170.17584"
  },
  {
      "Date": "9/21/12",
      "Rate": "33338.95483"
  },
  {
      "Date": "9/20/12",
      "Rate": "33664.08579"
  },
  {
      "Date": "9/19/12",
      "Rate": "33816.24367"
  },
  {
      "Date": "9/18/12",
      "Rate": "33626.70947"
  },
  {
      "Date": "9/17/12",
      "Rate": "33609.50987"
  },
  {
      "Date": "9/14/12",
      "Rate": "33734.36569"
  },
  {
      "Date": "9/13/12",
      "Rate": "33630.89296"
  },
  {
      "Date": "9/12/12",
      "Rate": "33998.0564"
  },
  {
      "Date": "9/11/12",
      "Rate": "33707.77615"
  },
  {
      "Date": "9/10/12",
      "Rate": "33839.64444"
  },
  {
      "Date": "9/7/12",
      "Rate": "33002.28928"
  },
  {
      "Date": "9/6/12",
      "Rate": "33589.04037"
  },
  {
      "Date": "9/5/12",
      "Rate": "33304.89427"
  },
  {
      "Date": "9/4/12",
      "Rate": "33242.88612"
  },
  {
      "Date": "9/3/12",
      "Rate": "32980.16896"
  },
  {
      "Date": "8/31/12",
      "Rate": "32404.17644"
  },
  {
      "Date": "8/30/12",
      "Rate": "32599.93298"
  },
  {
      "Date": "8/29/12",
      "Rate": "32654.51243"
  },
  {
      "Date": "8/28/12",
      "Rate": "32651.53177"
  },
  {
      "Date": "8/24/12",
      "Rate": "32551.69932"
  },
  {
      "Date": "8/23/12",
      "Rate": "32338.68675"
  },
  {
      "Date": "8/22/12",
      "Rate": "32038.05358"
  },
  {
      "Date": "8/21/12",
      "Rate": "31647.07667"
  },
  {
      "Date": "8/20/12",
      "Rate": "31727.2615"
  },
  {
      "Date": "8/17/12",
      "Rate": "31751.8122"
  },
  {
      "Date": "8/16/12",
      "Rate": "31451.21431"
  },
  {
      "Date": "8/15/12",
      "Rate": "31420.22258"
  },
  {
      "Date": "8/14/12",
      "Rate": "31809.34408"
  },
  {
      "Date": "8/13/12",
      "Rate": "31784.64523"
  },
  {
      "Date": "8/10/12",
      "Rate": "31310.98256"
  },
  {
      "Date": "8/9/12",
      "Rate": "31382.33479"
  },
  {
      "Date": "8/8/12",
      "Rate": "31236.43803"
  },
  {
      "Date": "8/7/12",
      "Rate": "31267.71901"
  },
  {
      "Date": "8/6/12",
      "Rate": "31316.59112"
  },
  {
      "Date": "8/3/12",
      "Rate": "31194.47609"
  },
  {
      "Date": "8/2/12",
      "Rate": "31816.07083"
  },
  {
      "Date": "8/1/12",
      "Rate": "31751.61467"
  },
  {
      "Date": "7/31/12",
      "Rate": "31886.02621"
  },
  {
      "Date": "7/30/12",
      "Rate": "31614.96323"
  },
  {
      "Date": "7/27/12",
      "Rate": "31599.01586"
  },
  {
      "Date": "7/26/12",
      "Rate": "31486.6435"
  },
  {
      "Date": "7/25/12",
      "Rate": "31417.37597"
  },
  {
      "Date": "7/24/12",
      "Rate": "31208.09538"
  },
  {
      "Date": "7/23/12",
      "Rate": "31089.64179"
  },
  {
      "Date": "7/20/12",
      "Rate": "30864.85829"
  },
  {
      "Date": "7/19/12",
      "Rate": "30678.17775"
  },
  {
      "Date": "7/18/12",
      "Rate": "30824.47309"
  },
  {
      "Date": "7/17/12",
      "Rate": "31014.43764"
  },
  {
      "Date": "7/16/12",
      "Rate": "30711.14482"
  },
  {
      "Date": "7/13/12",
      "Rate": "30630.91413"
  },
  {
      "Date": "7/12/12",
      "Rate": "30772.14413"
  },
  {
      "Date": "7/11/12",
      "Rate": "30777.04721"
  },
  {
      "Date": "7/10/12",
      "Rate": "31195.94349"
  },
  {
      "Date": "7/9/12",
      "Rate": "31127.01106"
  },
  {
      "Date": "7/6/12",
      "Rate": "31344.27062"
  },
  {
      "Date": "7/5/12",
      "Rate": "31537.16291"
  },
  {
      "Date": "7/4/12",
      "Rate": "31151.32895"
  },
  {
      "Date": "7/3/12",
      "Rate": "30777.64334"
  },
  {
      "Date": "7/2/12",
      "Rate": "31210.47638"
  },
  {
      "Date": "6/29/12",
      "Rate": "30740.0448"
  },
  {
      "Date": "6/28/12",
      "Rate": "31518.68992"
  },
  {
      "Date": "6/27/12",
      "Rate": "31486.01915"
  },
  {
      "Date": "6/26/12",
      "Rate": "31858.23383"
  },
  {
      "Date": "6/25/12",
      "Rate": "31604.70202"
  },
  {
      "Date": "6/22/12",
      "Rate": "31670.92541"
  },
  {
      "Date": "6/21/12",
      "Rate": "32082.40004"
  },
  {
      "Date": "6/20/12",
      "Rate": "32127.18743"
  },
  {
      "Date": "6/19/12",
      "Rate": "32079.42997"
  },
  {
      "Date": "6/18/12",
      "Rate": "32038.20526"
  },
  {
      "Date": "6/15/12",
      "Rate": "31813.25597"
  },
  {
      "Date": "6/14/12",
      "Rate": "31818.05323"
  },
  {
      "Date": "6/13/12",
      "Rate": "31621.26669"
  },
  {
      "Date": "6/12/12",
      "Rate": "31205.34754"
  },
  {
      "Date": "6/11/12",
      "Rate": "31363.26919"
  },
  {
      "Date": "6/8/12",
      "Rate": "30856.2338"
  },
  {
      "Date": "6/7/12",
      "Rate": "31423.66532"
  },
  {
      "Date": "6/6/12",
      "Rate": "31729.39205"
  },
  {
      "Date": "6/1/12",
      "Rate": "30385.17787"
  },
  {
      "Date": "5/31/12",
      "Rate": "31176.38406"
  },
  {
      "Date": "5/30/12",
      "Rate": "30776.1442"
  },
  {
      "Date": "5/29/12",
      "Rate": "30989.81993"
  },
  {
      "Date": "5/28/12",
      "Rate": "30842.56512"
  },
  {
      "Date": "5/25/12",
      "Rate": "30470.19524"
  },
  {
      "Date": "5/24/12",
      "Rate": "30656.53715"
  },
  {
      "Date": "5/23/12",
      "Rate": "30922.28434"
  },
  {
      "Date": "5/22/12",
      "Rate": "30784.63818"
  },
  {
      "Date": "5/21/12",
      "Rate": "30826.71299"
  },
  {
      "Date": "5/18/12",
      "Rate": "30514.22424"
  },
  {
      "Date": "5/17/12",
      "Rate": "29666.36449"
  },
  {
      "Date": "5/16/12",
      "Rate": "29489.60652"
  },
  {
      "Date": "5/15/12",
      "Rate": "29665.51791"
  },
  {
      "Date": "5/14/12",
      "Rate": "29736.1188"
  },
  {
      "Date": "5/11/12",
      "Rate": "29817.31953"
  },
  {
      "Date": "5/10/12",
      "Rate": "29874.01894"
  },
  {
      "Date": "5/9/12",
      "Rate": "30154.67292"
  },
  {
      "Date": "5/8/12",
      "Rate": "30712.67924"
  },
  {
      "Date": "5/4/12",
      "Rate": "30719.63527"
  },
  {
      "Date": "5/3/12",
      "Rate": "31092.17447"
  },
  {
      "Date": "5/2/12",
      "Rate": "30885.0985"
  },
  {
      "Date": "5/1/12",
      "Rate": "30872.84079"
  },
  {
      "Date": "4/30/12",
      "Rate": "30896.06871"
  },
  {
      "Date": "4/27/12",
      "Rate": "30633.087"
  },
  {
      "Date": "4/26/12",
      "Rate": "30514.96499"
  },
  {
      "Date": "4/25/12",
      "Rate": "30449.05201"
  },
  {
      "Date": "4/24/12",
      "Rate": "30443.13656"
  },
  {
      "Date": "4/23/12",
      "Rate": "30323.49777"
  },
  {
      "Date": "4/20/12",
      "Rate": "30130.83123"
  },
  {
      "Date": "4/19/12",
      "Rate": "30190.7441"
  },
  {
      "Date": "4/18/12",
      "Rate": "30099.2469"
  },
  {
      "Date": "4/17/12",
      "Rate": "29984.18314"
  },
  {
      "Date": "4/16/12",
      "Rate": "30009.14302"
  },
  {
      "Date": "4/13/12",
      "Rate": "30361.20919"
  },
  {
      "Date": "4/12/12",
      "Rate": "29954.27433"
  },
  {
      "Date": "4/11/12",
      "Rate": "30061.32383"
  },
  {
      "Date": "4/10/12",
      "Rate": "29944.60925"
  },
  {
      "Date": "4/5/12",
      "Rate": "29288.50244"
  },
  {
      "Date": "4/4/12",
      "Rate": "29455.47893"
  },
  {
      "Date": "4/3/12",
      "Rate": "29877.23946"
  },
  {
      "Date": "4/2/12",
      "Rate": "29864.48438"
  },
  {
      "Date": "3/30/12",
      "Rate": "29844.23358"
  },
  {
      "Date": "3/29/12",
      "Rate": "29982.16194"
  },
  {
      "Date": "3/28/12",
      "Rate": "30112.59105"
  },
  {
      "Date": "3/27/12",
      "Rate": "30393.94346"
  },
  {
      "Date": "3/26/12",
      "Rate": "29859.14743"
  },
  {
      "Date": "3/23/12",
      "Rate": "29861.20743"
  },
  {
      "Date": "3/22/12",
      "Rate": "29589.90458"
  },
  {
      "Date": "3/21/12",
      "Rate": "29589.47424"
  },
  {
      "Date": "3/20/12",
      "Rate": "29379.86913"
  },
  {
      "Date": "3/19/12",
      "Rate": "29297.02817"
  },
  {
      "Date": "3/16/12",
      "Rate": "29173.56567"
  },
  {
      "Date": "3/15/12",
      "Rate": "29249.93386"
  },
  {
      "Date": "3/14/12",
      "Rate": "29327.34263"
  },
  {
      "Date": "3/13/12",
      "Rate": "29923.17678"
  },
  {
      "Date": "3/12/12",
      "Rate": "30090.52364"
  },
  {
      "Date": "3/9/12",
      "Rate": "29851.17903"
  },
  {
      "Date": "3/8/12",
      "Rate": "30177.39995"
  },
  {
      "Date": "3/7/12",
      "Rate": "29840.42047"
  },
  {
      "Date": "3/6/12",
      "Rate": "29956.05566"
  },
  {
      "Date": "3/5/12",
      "Rate": "29845.79622"
  },
  {
      "Date": "3/2/12",
      "Rate": "29912.05136"
  },
  {
      "Date": "3/1/12",
      "Rate": "29861.54606"
  },
  {
      "Date": "2/29/12",
      "Rate": "30973.62564"
  },
  {
      "Date": "2/28/12",
      "Rate": "30694.01224"
  },
  {
      "Date": "2/27/12",
      "Rate": "30590.76174"
  },
  {
      "Date": "2/24/12",
      "Rate": "30736.91247"
  },
  {
      "Date": "2/23/12",
      "Rate": "30833.94063"
  },
  {
      "Date": "2/22/12",
      "Rate": "30415.46059"
  },
  {
      "Date": "2/21/12",
      "Rate": "30160.61306"
  },
  {
      "Date": "2/20/12",
      "Rate": "30106.64386"
  },
  {
      "Date": "2/17/12",
      "Rate": "30150.16138"
  },
  {
      "Date": "2/16/12",
      "Rate": "29898.87652"
  },
  {
      "Date": "2/15/12",
      "Rate": "30052.22667"
  },
  {
      "Date": "2/14/12",
      "Rate": "30004.20466"
  },
  {
      "Date": "2/13/12",
      "Rate": "29941.28644"
  },
  {
      "Date": "2/10/12",
      "Rate": "29959.7559"
  },
  {
      "Date": "2/9/12",
      "Rate": "30170.62735"
  },
  {
      "Date": "2/8/12",
      "Rate": "30252.49828"
  },
  {
      "Date": "2/7/12",
      "Rate": "29822.95984"
  },
  {
      "Date": "2/6/12",
      "Rate": "29713.40588"
  },
  {
      "Date": "2/3/12",
      "Rate": "30303.03533"
  },
  {
      "Date": "2/2/12",
      "Rate": "30231.97587"
  },
  {
      "Date": "2/1/12",
      "Rate": "30205.25935"
  },
  {
      "Date": "1/31/12",
      "Rate": "30374.15475"
  },
  {
      "Date": "1/30/12",
      "Rate": "30217.00559"
  },
  {
      "Date": "1/27/12",
      "Rate": "30003.41805"
  },
  {
      "Date": "1/26/12",
      "Rate": "30360.21447"
  },
  {
      "Date": "1/25/12",
      "Rate": "29403.14997"
  },
  {
      "Date": "1/24/12",
      "Rate": "29453.80695"
  },
  {
      "Date": "1/23/12",
      "Rate": "29527.19801"
  },
  {
      "Date": "1/20/12",
      "Rate": "29168.38745"
  },
  {
      "Date": "1/19/12",
      "Rate": "29529.91764"
  },
  {
      "Date": "1/18/12",
      "Rate": "29464.14222"
  },
  {
      "Date": "1/17/12",
      "Rate": "29723.0639"
  },
  {
      "Date": "1/16/12",
      "Rate": "29824.10977"
  },
  {
      "Date": "1/13/12",
      "Rate": "29798.3386"
  },
  {
      "Date": "1/12/12",
      "Rate": "30031.14693"
  },
  {
      "Date": "1/11/12",
      "Rate": "29979.8903"
  },
  {
      "Date": "1/10/12",
      "Rate": "29725.55777"
  },
  {
      "Date": "1/9/12",
      "Rate": "29906.4181"
  },
  {
      "Date": "1/6/12",
      "Rate": "30147.70278"
  },
  {
      "Date": "1/5/12",
      "Rate": "30123.62828"
  },
  {
      "Date": "1/4/12",
      "Rate": "30016.49412"
  },
  {
      "Date": "1/3/12",
      "Rate": "29792.69476"
  },
  {
      "Date": "12/30/11",
      "Rate": "29493.93111"
  },
  {
      "Date": "12/29/11",
      "Rate": "28746.60576"
  },
  {
      "Date": "12/28/11",
      "Rate": "29555.94984"
  },
  {
      "Date": "12/23/11",
      "Rate": "29933.48384"
  },
  {
      "Date": "12/22/11",
      "Rate": "29837.96892"
  },
  {
      "Date": "12/21/11",
      "Rate": "30466.12462"
  },
  {
      "Date": "12/20/11",
      "Rate": "29991.66828"
  },
  {
      "Date": "12/19/11",
      "Rate": "29612.90323"
  },
  {
      "Date": "12/16/11",
      "Rate": "29439.90899"
  },
  {
      "Date": "12/15/11",
      "Rate": "29879.62751"
  },
  {
      "Date": "12/14/11",
      "Rate": "31059.77883"
  },
  {
      "Date": "12/13/11",
      "Rate": "31186.26431"
  },
  {
      "Date": "12/12/11",
      "Rate": "31173.88314"
  },
  {
      "Date": "12/9/11",
      "Rate": "31326.83116"
  },
  {
      "Date": "12/8/11",
      "Rate": "31722.74291"
  },
  {
      "Date": "12/7/11",
      "Rate": "31529.48377"
  },
  {
      "Date": "12/6/11",
      "Rate": "31133.45914"
  },
  {
      "Date": "12/5/11",
      "Rate": "31567.8795"
  },
  {
      "Date": "12/2/11",
      "Rate": "31564.88121"
  },
  {
      "Date": "12/1/11",
      "Rate": "31710.43581"
  },
  {
      "Date": "11/30/11",
      "Rate": "31408.85024"
  },
  {
      "Date": "11/29/11",
      "Rate": "31527.34263"
  },
  {
      "Date": "11/28/11",
      "Rate": "31460.16332"
  },
  {
      "Date": "11/25/11",
      "Rate": "30883.8745"
  },
  {
      "Date": "11/24/11",
      "Rate": "31184.84277"
  },
  {
      "Date": "11/23/11",
      "Rate": "31207.9049"
  },
  {
      "Date": "11/22/11",
      "Rate": "31408.79381"
  },
  {
      "Date": "11/21/11",
      "Rate": "31450.92506"
  },
  {
      "Date": "11/18/11",
      "Rate": "31277.85322"
  },
  {
      "Date": "11/17/11",
      "Rate": "31555.90751"
  },
  {
      "Date": "11/16/11",
      "Rate": "31742.57747"
  },
  {
      "Date": "11/15/11",
      "Rate": "31552.65525"
  },
  {
      "Date": "11/14/11",
      "Rate": "31638.19115"
  },
  {
      "Date": "11/11/11",
      "Rate": "31187.88338"
  },
  {
      "Date": "11/10/11",
      "Rate": "31268.40685"
  },
  {
      "Date": "11/9/11",
      "Rate": "31516.28777"
  },
  {
      "Date": "11/8/11",
      "Rate": "31283.22898"
  },
  {
      "Date": "11/7/11",
      "Rate": "30554.76111"
  },
  {
      "Date": "11/4/11",
      "Rate": "30416.19076"
  },
  {
      "Date": "11/3/11",
      "Rate": "30033.58437"
  },
  {
      "Date": "11/2/11",
      "Rate": "30032.00762"
  },
  {
      "Date": "11/1/11",
      "Rate": "29585.90451"
  },
  {
      "Date": "10/31/11",
      "Rate": "29509.51869"
  },
  {
      "Date": "10/28/11",
      "Rate": "29841.30232"
  },
  {
      "Date": "10/27/11",
      "Rate": "29828.77299"
  },
  {
      "Date": "10/26/11",
      "Rate": "29916.09376"
  },
  {
      "Date": "10/25/11",
      "Rate": "28925.00397"
  },
  {
      "Date": "10/24/11",
      "Rate": "29016.76573"
  },
  {
      "Date": "10/21/11",
      "Rate": "28639.15766"
  },
  {
      "Date": "10/20/11",
      "Rate": "28620.05326"
  },
  {
      "Date": "10/19/11",
      "Rate": "28632.39563"
  },
  {
      "Date": "10/18/11",
      "Rate": "28832.74837"
  },
  {
      "Date": "10/17/11",
      "Rate": "29166.29923"
  },
  {
      "Date": "10/14/11",
      "Rate": "28980.23598"
  },
  {
      "Date": "10/13/11",
      "Rate": "28993.27678"
  },
  {
      "Date": "10/12/11",
      "Rate": "29134.73606"
  },
  {
      "Date": "10/11/11",
      "Rate": "28916.96503"
  },
  {
      "Date": "10/10/11",
      "Rate": "28746.3271"
  },
  {
      "Date": "10/7/11",
      "Rate": "28629.48553"
  },
  {
      "Date": "10/6/11",
      "Rate": "28714.0267"
  },
  {
      "Date": "10/5/11",
      "Rate": "27852.34307"
  },
  {
      "Date": "10/4/11",
      "Rate": "29139.61093"
  },
  {
      "Date": "10/3/11",
      "Rate": "28785.55177"
  },
  {
      "Date": "9/30/11",
      "Rate": "28136.68671"
  },
  {
      "Date": "9/29/11",
      "Rate": "27981.23776"
  },
  {
      "Date": "9/28/11",
      "Rate": "28465.33449"
  },
  {
      "Date": "9/27/11",
      "Rate": "28926.19976"
  },
  {
      "Date": "9/26/11",
      "Rate": "28169.00122"
  },
  {
      "Date": "9/23/11",
      "Rate": "30165.69252"
  },
  {
      "Date": "9/22/11",
      "Rate": "30874.99956"
  },
  {
      "Date": "9/21/11",
      "Rate": "30860.99578"
  },
  {
      "Date": "9/20/11",
      "Rate": "30376.04191"
  },
  {
      "Date": "9/19/11",
      "Rate": "30644.38879"
  },
  {
      "Date": "9/16/11",
      "Rate": "29636.99183"
  },
  {
      "Date": "9/15/11",
      "Rate": "30294.8306"
  },
  {
      "Date": "9/14/11",
      "Rate": "30741.93548"
  },
  {
      "Date": "9/13/11",
      "Rate": "30320.31253"
  },
  {
      "Date": "9/12/11",
      "Rate": "30704.20642"
  },
  {
      "Date": "9/9/11",
      "Rate": "30869.75784"
  },
  {
      "Date": "9/8/11",
      "Rate": "29770.63793"
  },
  {
      "Date": "9/7/11",
      "Rate": "30031.3868"
  },
  {
      "Date": "9/6/11",
      "Rate": "30760.14392"
  },
  {
      "Date": "9/5/11",
      "Rate": "30771.33988"
  },
  {
      "Date": "9/2/11",
      "Rate": "29949.00439"
  },
  {
      "Date": "9/1/11",
      "Rate": "29514.40413"
  },
  {
      "Date": "8/31/11",
      "Rate": "29685.10203"
  },
  {
      "Date": "8/30/11",
      "Rate": "29116.10787"
  },
  {
      "Date": "8/26/11",
      "Rate": "29093.63128"
  },
  {
      "Date": "8/25/11",
      "Rate": "27879.23597"
  },
  {
      "Date": "8/24/11",
      "Rate": "30021.42895"
  },
  {
      "Date": "8/23/11",
      "Rate": "30360.87409"
  },
  {
      "Date": "8/22/11",
      "Rate": "30246.54756"
  },
  {
      "Date": "8/19/11",
      "Rate": "30048.67811"
  },
  {
      "Date": "8/18/11",
      "Rate": "28959.37494"
  },
  {
      "Date": "8/17/11",
      "Rate": "28700.95063"
  },
  {
      "Date": "8/16/11",
      "Rate": "28483.32775"
  },
  {
      "Date": "8/15/11",
      "Rate": "27799.29452"
  },
  {
      "Date": "8/12/11",
      "Rate": "28071.20761"
  },
  {
      "Date": "8/11/11",
      "Rate": "28608.00367"
  },
  {
      "Date": "8/10/11",
      "Rate": "27998.63137"
  },
  {
      "Date": "8/9/11",
      "Rate": "28226.84703"
  },
  {
      "Date": "8/8/11",
      "Rate": "27121.27551"
  },
  {
      "Date": "8/5/11",
      "Rate": "26279.27477"
  },
  {
      "Date": "8/4/11",
      "Rate": "26151.49474"
  },
  {
      "Date": "8/3/11",
      "Rate": "26068.74901"
  },
  {
      "Date": "8/2/11",
      "Rate": "25364.34858"
  },
  {
      "Date": "8/1/11",
      "Rate": "25070.8725"
  },
  {
      "Date": "7/29/11",
      "Rate": "25157.29731"
  },
  {
      "Date": "7/28/11",
      "Rate": "25152.99741"
  },
  {
      "Date": "7/27/11",
      "Rate": "25207.42518"
  },
  {
      "Date": "7/26/11",
      "Rate": "25093.15861"
  },
  {
      "Date": "7/25/11",
      "Rate": "25351.23724"
  },
  {
      "Date": "7/22/11",
      "Rate": "24842.69564"
  },
  {
      "Date": "7/21/11",
      "Rate": "25072.1212"
  },
  {
      "Date": "7/20/11",
      "Rate": "24839.91252"
  },
  {
      "Date": "7/19/11",
      "Rate": "25146.47525"
  },
  {
      "Date": "7/18/11",
      "Rate": "25129.89294"
  },
  {
      "Date": "7/15/11",
      "Rate": "24785.95037"
  },
  {
      "Date": "7/14/11",
      "Rate": "24995.95055"
  },
  {
      "Date": "7/13/11",
      "Rate": "24680.19189"
  },
  {
      "Date": "7/12/11",
      "Rate": "24361.03635"
  },
  {
      "Date": "7/11/11",
      "Rate": "24220.03915"
  },
  {
      "Date": "7/8/11",
      "Rate": "23860.65715"
  },
  {
      "Date": "7/7/11",
      "Rate": "23922.43955"
  },
  {
      "Date": "7/6/11",
      "Rate": "23788.0527"
  },
  {
      "Date": "7/5/11",
      "Rate": "23449.11198"
  },
  {
      "Date": "7/4/11",
      "Rate": "23433.90889"
  },
  {
      "Date": "7/1/11",
      "Rate": "23500.03704"
  },
  {
      "Date": "6/30/11",
      "Rate": "23702.88012"
  },
  {
      "Date": "6/29/11",
      "Rate": "23834.79779"
  },
  {
      "Date": "6/28/11",
      "Rate": "23870.82665"
  },
  {
      "Date": "6/27/11",
      "Rate": "23845.67276"
  },
  {
      "Date": "6/24/11",
      "Rate": "24140.5986"
  },
  {
      "Date": "6/23/11",
      "Rate": "24441.49985"
  },
  {
      "Date": "6/22/11",
      "Rate": "24482.85508"
  },
  {
      "Date": "6/21/11",
      "Rate": "24409.49223"
  },
  {
      "Date": "6/20/11",
      "Rate": "24401.32277"
  },
  {
      "Date": "6/17/11",
      "Rate": "24155.28316"
  },
  {
      "Date": "6/16/11",
      "Rate": "24155.6747"
  },
  {
      "Date": "6/15/11",
      "Rate": "23963.2057"
  },
  {
      "Date": "6/14/11",
      "Rate": "23970.88485"
  },
  {
      "Date": "6/13/11",
      "Rate": "24129.40969"
  },
  {
      "Date": "6/10/11",
      "Rate": "24305.83255"
  },
  {
      "Date": "6/9/11",
      "Rate": "24206.2435"
  },
  {
      "Date": "6/8/11",
      "Rate": "24212.31062"
  },
  {
      "Date": "6/7/11",
      "Rate": "24403.43216"
  },
  {
      "Date": "6/6/11",
      "Rate": "24357.92166"
  },
  {
      "Date": "6/3/11",
      "Rate": "24199.4074"
  },
  {
      "Date": "6/2/11",
      "Rate": "24361.67128"
  },
  {
      "Date": "6/1/11",
      "Rate": "24235.37981"
  },
  {
      "Date": "5/31/11",
      "Rate": "24428.43084"
  },
  {
      "Date": "5/27/11",
      "Rate": "24295.53608"
  },
  {
      "Date": "5/26/11",
      "Rate": "24314.91208"
  },
  {
      "Date": "5/25/11",
      "Rate": "24416.27189"
  },
  {
      "Date": "5/24/11",
      "Rate": "24249.28129"
  },
  {
      "Date": "5/23/11",
      "Rate": "24071.24288"
  },
  {
      "Date": "5/20/11",
      "Rate": "23860.21976"
  },
  {
      "Date": "5/19/11",
      "Rate": "23620.86457"
  },
  {
      "Date": "5/18/11",
      "Rate": "23699.98413"
  },
  {
      "Date": "5/17/11",
      "Rate": "23822.91751"
  },
  {
      "Date": "5/16/11",
      "Rate": "23814.95264"
  },
  {
      "Date": "5/13/11",
      "Rate": "23913.92794"
  },
  {
      "Date": "5/12/11",
      "Rate": "23622.12032"
  },
  {
      "Date": "5/11/11",
      "Rate": "24034.82601"
  },
  {
      "Date": "5/10/11",
      "Rate": "23952.63409"
  },
  {
      "Date": "5/9/11",
      "Rate": "23740.66562"
  },
  {
      "Date": "5/6/11",
      "Rate": "23507.91372"
  },
  {
      "Date": "5/5/11",
      "Rate": "23911.89263"
  },
  {
      "Date": "5/4/11",
      "Rate": "24094.22388"
  },
  {
      "Date": "5/3/11",
      "Rate": "24284.841"
  },
  {
      "Date": "4/28/11",
      "Rate": "23994.19037"
  },
  {
      "Date": "4/27/11",
      "Rate": "23639.04831"
  },
  {
      "Date": "4/26/11",
      "Rate": "23634.49091"
  },
  {
      "Date": "4/21/11",
      "Rate": "23584.83218"
  },
  {
      "Date": "4/20/11",
      "Rate": "23533.62493"
  },
  {
      "Date": "4/19/11",
      "Rate": "23458.99575"
  },
  {
      "Date": "4/18/11",
      "Rate": "23275.9061"
  },
  {
      "Date": "4/15/11",
      "Rate": "23026.72358"
  },
  {
      "Date": "4/14/11",
      "Rate": "22880.84093"
  },
  {
      "Date": "4/13/11",
      "Rate": "22888.68939"
  },
  {
      "Date": "4/12/11",
      "Rate": "22879.92028"
  },
  {
      "Date": "4/11/11",
      "Rate": "23009.09716"
  },
  {
      "Date": "4/8/11",
      "Rate": "22861.88116"
  },
  {
      "Date": "4/7/11",
      "Rate": "22711.01078"
  },
  {
      "Date": "4/6/11",
      "Rate": "22700.82012"
  },
  {
      "Date": "4/5/11",
      "Rate": "22475.49339"
  },
  {
      "Date": "4/4/11",
      "Rate": "22530.05873"
  },
  {
      "Date": "4/1/11",
      "Rate": "22561.51608"
  },
  {
      "Date": "3/31/11",
      "Rate": "22506.46749"
  },
  {
      "Date": "3/30/11",
      "Rate": "22400.32452"
  },
  {
      "Date": "3/29/11",
      "Rate": "22336.35867"
  },
  {
      "Date": "3/28/11",
      "Rate": "22459.93757"
  },
  {
      "Date": "3/25/11",
      "Rate": "22597.91178"
  },
  {
      "Date": "3/24/11",
      "Rate": "22755.37487"
  },
  {
      "Date": "3/23/11",
      "Rate": "22668.08233"
  },
  {
      "Date": "3/22/11",
      "Rate": "22604.75493"
  },
  {
      "Date": "3/21/11",
      "Rate": "22668.13524"
  },
  {
      "Date": "3/18/11",
      "Rate": "22531.06404"
  },
  {
      "Date": "3/17/11",
      "Rate": "22369.75961"
  },
  {
      "Date": "3/16/11",
      "Rate": "22256.76643"
  },
  {
      "Date": "3/15/11",
      "Rate": "22455.96571"
  },
  {
      "Date": "3/14/11",
      "Rate": "22670.55151"
  },
  {
      "Date": "3/11/11",
      "Rate": "22496.72481"
  },
  {
      "Date": "3/10/11",
      "Rate": "22699.22926"
  },
  {
      "Date": "3/9/11",
      "Rate": "22725.14859"
  },
  {
      "Date": "3/8/11",
      "Rate": "22822.97748"
  },
  {
      "Date": "3/7/11",
      "Rate": "22836.5368"
  },
  {
      "Date": "3/4/11",
      "Rate": "22502.0794"
  },
  {
      "Date": "3/3/11",
      "Rate": "22728.19626"
  },
  {
      "Date": "3/2/11",
      "Rate": "22681.52172"
  },
  {
      "Date": "3/1/11",
      "Rate": "22427.83118"
  },
  {
      "Date": "2/28/11",
      "Rate": "22512.88735"
  },
  {
      "Date": "2/25/11",
      "Rate": "22463.05226"
  },
  {
      "Date": "2/24/11",
      "Rate": "22691.02453"
  },
  {
      "Date": "2/23/11",
      "Rate": "22307.94899"
  },
  {
      "Date": "2/22/11",
      "Rate": "22265.66253"
  },
  {
      "Date": "2/21/11",
      "Rate": "22208.50809"
  },
  {
      "Date": "2/18/11",
      "Rate": "22090.19559"
  },
  {
      "Date": "2/17/11",
      "Rate": "22025.10097"
  },
  {
      "Date": "2/16/11",
      "Rate": "22069.96243"
  },
  {
      "Date": "2/15/11",
      "Rate": "22033.83481"
  },
  {
      "Date": "2/14/11",
      "Rate": "21775.38581"
  },
  {
      "Date": "2/11/11",
      "Rate": "21901.37745"
  },
  {
      "Date": "2/10/11",
      "Rate": "21916.52057"
  },
  {
      "Date": "2/9/11",
      "Rate": "21860.45962"
  },
  {
      "Date": "2/8/11",
      "Rate": "21634.53324"
  },
  {
      "Date": "2/7/11",
      "Rate": "21535.75195"
  },
  {
      "Date": "2/4/11",
      "Rate": "21673.26761"
  },
  {
      "Date": "2/3/11",
      "Rate": "21451.98328"
  },
  {
      "Date": "2/2/11",
      "Rate": "21451.33071"
  },
  {
      "Date": "2/1/11",
      "Rate": "21449.91622"
  },
  {
      "Date": "1/31/11",
      "Rate": "21593.90818"
  },
  {
      "Date": "1/28/11",
      "Rate": "21244.37468"
  },
  {
      "Date": "1/27/11",
      "Rate": "21558.42607"
  },
  {
      "Date": "1/26/11",
      "Rate": "21528.54548"
  },
  {
      "Date": "1/25/11",
      "Rate": "21375.40345"
  },
  {
      "Date": "1/24/11",
      "Rate": "21615.04083"
  },
  {
      "Date": "1/21/11",
      "Rate": "21627.6407"
  },
  {
      "Date": "1/20/11",
      "Rate": "21916.61581"
  },
  {
      "Date": "1/19/11",
      "Rate": "22050.64992"
  },
  {
      "Date": "1/18/11",
      "Rate": "21951.08203"
  },
  {
      "Date": "1/17/11",
      "Rate": "21765.87241"
  },
  {
      "Date": "1/14/11",
      "Rate": "21910.80266"
  },
  {
      "Date": "1/13/11",
      "Rate": "21938.96894"
  },
  {
      "Date": "1/12/11",
      "Rate": "21970.46509"
  },
  {
      "Date": "1/11/11",
      "Rate": "21969.73492"
  },
  {
      "Date": "1/10/11",
      "Rate": "21889.97337"
  },
  {
      "Date": "1/7/11",
      "Rate": "21740.35874"
  },
  {
      "Date": "1/6/11",
      "Rate": "22028.52255"
  },
  {
      "Date": "1/5/11",
      "Rate": "22085.37011"
  },
  {
      "Date": "1/4/11",
      "Rate": "22272.73497"
  },
  {
      "Date": "12/31/10",
      "Rate": "22238.56858"
  },
  {
      "Date": "12/30/10",
      "Rate": "22325.49428"
  },
  {
      "Date": "12/29/10",
      "Rate": "22258.36787"
  },
  {
      "Date": "12/24/10",
      "Rate": "21969.08587"
  },
  {
      "Date": "12/23/10",
      "Rate": "22007.69678"
  },
  {
      "Date": "12/22/10",
      "Rate": "22048.0079"
  },
  {
      "Date": "12/21/10",
      "Rate": "22098.16752"
  },
  {
      "Date": "12/20/10",
      "Rate": "22121.96335"
  },
  {
      "Date": "12/17/10",
      "Rate": "21989.11445"
  },
  {
      "Date": "12/16/10",
      "Rate": "22137.06767"
  },
  {
      "Date": "12/15/10",
      "Rate": "22234.42741"
  },
  {
      "Date": "12/14/10",
      "Rate": "22279.67689"
  },
  {
      "Date": "12/13/10",
      "Rate": "22097.31389"
  },
  {
      "Date": "12/10/10",
      "Rate": "22090.84816"
  },
  {
      "Date": "12/9/10",
      "Rate": "22041.70444"
  },
  {
      "Date": "12/8/10",
      "Rate": "22185.07557"
  },
  {
      "Date": "12/7/10",
      "Rate": "22456.75232"
  },
  {
      "Date": "12/6/10",
      "Rate": "22377.77386"
  },
  {
      "Date": "12/3/10",
      "Rate": "22143.20535"
  },
  {
      "Date": "12/2/10",
      "Rate": "22206.07065"
  },
  {
      "Date": "12/1/10",
      "Rate": "22271.75435"
  },
  {
      "Date": "11/30/10",
      "Rate": "22256.2338"
  },
  {
      "Date": "11/29/10",
      "Rate": "22041.02365"
  },
  {
      "Date": "11/26/10",
      "Rate": "22098.16399"
  },
  {
      "Date": "11/25/10",
      "Rate": "22005.73555"
  },
  {
      "Date": "11/24/10",
      "Rate": "22187.8728"
  },
  {
      "Date": "11/23/10",
      "Rate": "21884.40361"
  },
  {
      "Date": "11/22/10",
      "Rate": "21744.32706"
  },
  {
      "Date": "11/19/10",
      "Rate": "21689.25731"
  },
  {
      "Date": "11/18/10",
      "Rate": "21643.77502"
  },
  {
      "Date": "11/17/10",
      "Rate": "21391.44958"
  },
  {
      "Date": "11/16/10",
      "Rate": "21819.59823"
  },
  {
      "Date": "11/15/10",
      "Rate": "21812.11309"
  },
  {
      "Date": "11/12/10",
      "Rate": "21930.64428"
  },
  {
      "Date": "11/11/10",
      "Rate": "22085.05617"
  },
  {
      "Date": "11/10/10",
      "Rate": "21910.65451"
  },
  {
      "Date": "11/9/10",
      "Rate": "22113.37413"
  },
  {
      "Date": "11/8/10",
      "Rate": "21767.24457"
  },
  {
      "Date": "11/5/10",
      "Rate": "21594.1939"
  },
  {
      "Date": "11/4/10",
      "Rate": "21231.49615"
  },
  {
      "Date": "11/3/10",
      "Rate": "21259.5813"
  },
  {
      "Date": "11/2/10",
      "Rate": "21256.54773"
  },
  {
      "Date": "11/1/10",
      "Rate": "21359.35731"
  },
  {
      "Date": "10/29/10",
      "Rate": "20952.21785"
  },
  {
      "Date": "10/28/10",
      "Rate": "20843.02721"
  },
  {
      "Date": "10/27/10",
      "Rate": "20900.48149"
  },
  {
      "Date": "10/26/10",
      "Rate": "20913.81859"
  },
  {
      "Date": "10/25/10",
      "Rate": "21048.31831"
  },
  {
      "Date": "10/22/10",
      "Rate": "20748.44706"
  },
  {
      "Date": "10/21/10",
      "Rate": "21033.24926"
  },
  {
      "Date": "10/20/10",
      "Rate": "20982.62403"
  },
  {
      "Date": "10/19/10",
      "Rate": "21404.3387"
  },
  {
      "Date": "10/18/10",
      "Rate": "21283.94152"
  },
  {
      "Date": "10/15/10",
      "Rate": "21424.26851"
  },
  {
      "Date": "10/14/10",
      "Rate": "21490.88696"
  },
  {
      "Date": "10/13/10",
      "Rate": "21333.85774"
  },
  {
      "Date": "10/12/10",
      "Rate": "21167.01529"
  },
  {
      "Date": "10/11/10",
      "Rate": "21131.62842"
  },
  {
      "Date": "10/8/10",
      "Rate": "20854.25493"
  },
  {
      "Date": "10/7/10",
      "Rate": "21203.29812"
  },
  {
      "Date": "10/6/10",
      "Rate": "21150.88802"
  },
  {
      "Date": "10/5/10",
      "Rate": "20863.97996"
  },
  {
      "Date": "10/4/10",
      "Rate": "20696.61193"
  },
  {
      "Date": "10/1/10",
      "Rate": "20593.85527"
  },
  {
      "Date": "9/30/10",
      "Rate": "20793.70712"
  },
  {
      "Date": "9/29/10",
      "Rate": "20726.66185"
  },
  {
      "Date": "9/28/10",
      "Rate": "20531.15575"
  },
  {
      "Date": "9/27/10",
      "Rate": "20614.37415"
  },
  {
      "Date": "9/24/10",
      "Rate": "20720.29136"
  },
  {
      "Date": "9/23/10",
      "Rate": "20801.03353"
  },
  {
      "Date": "9/22/10",
      "Rate": "20773.16355"
  },
  {
      "Date": "9/21/10",
      "Rate": "20600.19048"
  },
  {
      "Date": "9/20/10",
      "Rate": "20640.16297"
  },
  {
      "Date": "9/17/10",
      "Rate": "20723.60006"
  },
  {
      "Date": "9/16/10",
      "Rate": "20694.6119"
  },
  {
      "Date": "9/15/10",
      "Rate": "20778.23948"
  },
  {
      "Date": "9/14/10",
      "Rate": "20535.31808"
  },
  {
      "Date": "9/13/10",
      "Rate": "20344.3447"
  },
  {
      "Date": "9/10/10",
      "Rate": "20469.2887"
  },
  {
      "Date": "9/9/10",
      "Rate": "20547.15251"
  },
  {
      "Date": "9/8/10",
      "Rate": "20700.78837"
  },
  {
      "Date": "9/7/10",
      "Rate": "20607.48514"
  },
  {
      "Date": "9/6/10",
      "Rate": "20514.64047"
  },
  {
      "Date": "9/3/10",
      "Rate": "20597.64017"
  },
  {
      "Date": "9/2/10",
      "Rate": "20562.93056"
  },
  {
      "Date": "9/1/10",
      "Rate": "20639.69382"
  },
  {
      "Date": "8/31/10",
      "Rate": "20480.37884"
  },
  {
      "Date": "8/27/10",
      "Rate": "20417.51001"
  },
  {
      "Date": "8/26/10",
      "Rate": "20496.20275"
  },
  {
      "Date": "8/25/10",
      "Rate": "20474.76675"
  },
  {
      "Date": "8/24/10",
      "Rate": "20156.9869"
  },
  {
      "Date": "8/23/10",
      "Rate": "20190.67356"
  },
  {
      "Date": "8/20/10",
      "Rate": "20256.94986"
  },
  {
      "Date": "8/19/10",
      "Rate": "20175.29057"
  },
  {
      "Date": "8/18/10",
      "Rate": "20093.1445"
  },
  {
      "Date": "8/17/10",
      "Rate": "20182.65931"
  },
  {
      "Date": "8/16/10",
      "Rate": "20173.0542"
  },
  {
      "Date": "8/13/10",
      "Rate": "20042.4981"
  },
  {
      "Date": "8/12/10",
      "Rate": "19797.17455"
  },
  {
      "Date": "8/11/10",
      "Rate": "19734.59849"
  },
  {
      "Date": "8/10/10",
      "Rate": "19585.25547"
  },
  {
      "Date": "8/9/10",
      "Rate": "19622.83991"
  },
  {
      "Date": "8/6/10",
      "Rate": "19445.20362"
  },
  {
      "Date": "8/5/10",
      "Rate": "19477.2959"
  },
  {
      "Date": "8/4/10",
      "Rate": "19403.06884"
  },
  {
      "Date": "8/3/10",
      "Rate": "19274.27291"
  },
  {
      "Date": "8/2/10",
      "Rate": "19176.59923"
  },
  {
      "Date": "7/30/10",
      "Rate": "19149.76984"
  },
  {
      "Date": "7/29/10",
      "Rate": "19166.3345"
  },
  {
      "Date": "7/28/10",
      "Rate": "19207.36521"
  },
  {
      "Date": "7/27/10",
      "Rate": "19508.15358"
  },
  {
      "Date": "7/26/10",
      "Rate": "19678.61162"
  },
  {
      "Date": "7/23/10",
      "Rate": "19865.3486"
  },
  {
      "Date": "7/22/10",
      "Rate": "19660.16685"
  },
  {
      "Date": "7/21/10",
      "Rate": "19822.7482"
  },
  {
      "Date": "7/20/10",
      "Rate": "19737.85781"
  },
  {
      "Date": "7/19/10",
      "Rate": "19782.21838"
  },
  {
      "Date": "7/16/10",
      "Rate": "19878.3012"
  },
  {
      "Date": "7/15/10",
      "Rate": "19922.63356"
  },
  {
      "Date": "7/14/10",
      "Rate": "19968.26752"
  },
  {
      "Date": "7/13/10",
      "Rate": "19908.66506"
  },
  {
      "Date": "7/12/10",
      "Rate": "19862.22685"
  },
  {
      "Date": "7/9/10",
      "Rate": "19691.00337"
  },
  {
      "Date": "7/8/10",
      "Rate": "19859.10863"
  },
  {
      "Date": "7/7/10",
      "Rate": "19674.97839"
  },
  {
      "Date": "7/6/10",
      "Rate": "20032.18046"
  },
  {
      "Date": "7/5/10",
      "Rate": "19941.6674"
  },
  {
      "Date": "7/2/10",
      "Rate": "19978.94143"
  },
  {
      "Date": "7/1/10",
      "Rate": "20373.97485"
  },
  {
      "Date": "6/30/10",
      "Rate": "20326.40082"
  },
  {
      "Date": "6/29/10",
      "Rate": "20264.6537"
  },
  {
      "Date": "6/28/10",
      "Rate": "20470.72435"
  },
  {
      "Date": "6/25/10",
      "Rate": "20286.41775"
  },
  {
      "Date": "6/24/10",
      "Rate": "20223.91577"
  },
  {
      "Date": "6/23/10",
      "Rate": "20252.269"
  },
  {
      "Date": "6/22/10",
      "Rate": "20147.78391"
  },
  {
      "Date": "6/21/10",
      "Rate": "20326.73239"
  },
  {
      "Date": "6/18/10",
      "Rate": "20263.64839"
  },
  {
      "Date": "6/17/10",
      "Rate": "20166.03467"
  },
  {
      "Date": "6/16/10",
      "Rate": "20289.1409"
  },
  {
      "Date": "6/15/10",
      "Rate": "20088.18498"
  },
  {
      "Date": "6/14/10",
      "Rate": "20163.50553"
  },
  {
      "Date": "6/11/10",
      "Rate": "20166.58848"
  },
  {
      "Date": "6/10/10",
      "Rate": "20211.04429"
  },
  {
      "Date": "6/9/10",
      "Rate": "20486.24491"
  },
  {
      "Date": "6/8/10",
      "Rate": "20666.09993"
  },
  {
      "Date": "6/7/10",
      "Rate": "20139.66737"
  },
  {
      "Date": "6/4/10",
      "Rate": "19884.63289"
  },
  {
      "Date": "6/3/10",
      "Rate": "20089.65943"
  },
  {
      "Date": "6/2/10",
      "Rate": "20231.91943"
  },
  {
      "Date": "6/1/10",
      "Rate": "20288.65059"
  },
  {
      "Date": "5/28/10",
      "Rate": "19848.28657"
  },
  {
      "Date": "5/27/10",
      "Rate": "20198.74072"
  },
  {
      "Date": "5/26/10",
      "Rate": "20227.937"
  },
  {
      "Date": "5/25/10",
      "Rate": "20016.26131"
  },
  {
      "Date": "5/24/10",
      "Rate": "19616.77631"
  },
  {
      "Date": "5/21/10",
      "Rate": "19589.67178"
  },
  {
      "Date": "5/20/10",
      "Rate": "19603.58031"
  },
  {
      "Date": "5/19/10",
      "Rate": "19781.11431"
  },
  {
      "Date": "5/18/10",
      "Rate": "19537.8437"
  },
  {
      "Date": "5/17/10",
      "Rate": "19801.51678"
  },
  {
      "Date": "5/14/10",
      "Rate": "19759.17741"
  },
  {
      "Date": "5/13/10",
      "Rate": "19637.28108"
  },
  {
      "Date": "5/12/10",
      "Rate": "19750.89155"
  },
  {
      "Date": "5/11/10",
      "Rate": "19319.39188"
  },
  {
      "Date": "5/10/10",
      "Rate": "18802.76548"
  },
  {
      "Date": "5/7/10",
      "Rate": "19244.71684"
  },
  {
      "Date": "5/6/10",
      "Rate": "18827.55604"
  },
  {
      "Date": "5/5/10",
      "Rate": "18578.20067"
  },
  {
      "Date": "5/4/10",
      "Rate": "18639.2141"
  },
  {
      "Date": "4/30/10",
      "Rate": "18388.73701"
  },
  {
      "Date": "4/29/10",
      "Rate": "18370.56385"
  },
  {
      "Date": "4/28/10",
      "Rate": "18332.64079"
  },
  {
      "Date": "4/27/10",
      "Rate": "18064.42794"
  },
  {
      "Date": "4/26/10",
      "Rate": "18072.04007"
  },
  {
      "Date": "4/23/10",
      "Rate": "17868.35747"
  },
  {
      "Date": "4/22/10",
      "Rate": "17981.38944"
  },
  {
      "Date": "4/21/10",
      "Rate": "17977.56574"
  },
  {
      "Date": "4/20/10",
      "Rate": "17937.28284"
  },
  {
      "Date": "4/19/10",
      "Rate": "17792.74061"
  },
  {
      "Date": "4/16/10",
      "Rate": "18091.96282"
  },
  {
      "Date": "4/15/10",
      "Rate": "18047.73629"
  },
  {
      "Date": "4/14/10",
      "Rate": "18182.51821"
  },
  {
      "Date": "4/13/10",
      "Rate": "18029.55608"
  },
  {
      "Date": "4/12/10",
      "Rate": "18240.80143"
  },
  {
      "Date": "4/9/10",
      "Rate": "18057.97633"
  },
  {
      "Date": "4/8/10",
      "Rate": "17943.94963"
  },
  {
      "Date": "4/7/10",
      "Rate": "17806.52922"
  },
  {
      "Date": "4/6/10",
      "Rate": "17631.45029"
  },
  {
      "Date": "4/1/10",
      "Rate": "17694.91525"
  },
  {
      "Date": "3/31/10",
      "Rate": "17591.85524"
  },
  {
      "Date": "3/30/10",
      "Rate": "17650.6217"
  },
  {
      "Date": "3/29/10",
      "Rate": "17598.04229"
  },
  {
      "Date": "3/26/10",
      "Rate": "17519.89629"
  },
  {
      "Date": "3/25/10",
      "Rate": "17532.9512"
  },
  {
      "Date": "3/24/10",
      "Rate": "17533.25103"
  },
  {
      "Date": "3/23/10",
      "Rate": "17641.43283"
  },
  {
      "Date": "3/22/10",
      "Rate": "17757.90049"
  },
  {
      "Date": "3/19/10",
      "Rate": "18001.67904"
  },
  {
      "Date": "3/18/10",
      "Rate": "18017.78162"
  },
  {
      "Date": "3/17/10",
      "Rate": "18106.30523"
  },
  {
      "Date": "3/16/10",
      "Rate": "17900.66844"
  },
  {
      "Date": "3/15/10",
      "Rate": "17751.93213"
  },
  {
      "Date": "3/12/10",
      "Rate": "17929.91411"
  },
  {
      "Date": "3/11/10",
      "Rate": "17795.79534"
  },
  {
      "Date": "3/10/10",
      "Rate": "18000.25045"
  },
  {
      "Date": "3/9/10",
      "Rate": "18011.18186"
  },
  {
      "Date": "3/8/10",
      "Rate": "18174.32053"
  },
  {
      "Date": "3/5/10",
      "Rate": "18258.4102"
  },
  {
      "Date": "3/4/10",
      "Rate": "18382.72633"
  },
  {
      "Date": "3/3/10",
      "Rate": "18314.58756"
  },
  {
      "Date": "3/2/10",
      "Rate": "18070.85839"
  },
  {
      "Date": "3/1/10",
      "Rate": "18169.91834"
  },
  {
      "Date": "2/26/10",
      "Rate": "18092.6683"
  },
  {
      "Date": "2/25/10",
      "Rate": "17912.16424"
  },
  {
      "Date": "2/24/10",
      "Rate": "17864.2163"
  },
  {
      "Date": "2/23/10",
      "Rate": "18135.52973"
  },
  {
      "Date": "2/22/10",
      "Rate": "18220.45186"
  },
  {
      "Date": "2/19/10",
      "Rate": "18081.32066"
  },
  {
      "Date": "2/18/10",
      "Rate": "18041.22119"
  },
  {
      "Date": "2/17/10",
      "Rate": "18186.18318"
  },
  {
      "Date": "2/16/10",
      "Rate": "18178.69804"
  },
  {
      "Date": "2/15/10",
      "Rate": "17966.57437"
  },
  {
      "Date": "2/12/10",
      "Rate": "17655.46835"
  },
  {
      "Date": "2/11/10",
      "Rate": "17675.93432"
  },
  {
      "Date": "2/10/10",
      "Rate": "17636.99536"
  },
  {
      "Date": "2/9/10",
      "Rate": "17576.16184"
  },
  {
      "Date": "2/8/10",
      "Rate": "17665.69075"
  },
  {
      "Date": "2/5/10",
      "Rate": "17342.94432"
  },
  {
      "Date": "2/4/10",
      "Rate": "17978.65218"
  },
  {
      "Date": "2/3/10",
      "Rate": "18135.0112"
  },
  {
      "Date": "2/2/10",
      "Rate": "18138.67617"
  },
  {
      "Date": "2/1/10",
      "Rate": "17703.51153"
  },
  {
      "Date": "1/29/10",
      "Rate": "17636.53327"
  },
  {
      "Date": "1/28/10",
      "Rate": "17853.41188"
  },
  {
      "Date": "1/27/10",
      "Rate": "17908.26293"
  },
  {
      "Date": "1/26/10",
      "Rate": "17735.09938"
  },
  {
      "Date": "1/25/10",
      "Rate": "17942.40816"
  },
  {
      "Date": "1/22/10",
      "Rate": "17859.53544"
  },
  {
      "Date": "1/21/10",
      "Rate": "17909.6492"
  },
  {
      "Date": "1/20/10",
      "Rate": "18323.17678"
  },
  {
      "Date": "1/19/10",
      "Rate": "18336.32339"
  },
  {
      "Date": "1/18/10",
      "Rate": "18234.41683"
  },
  {
      "Date": "1/15/10",
      "Rate": "18196.17277"
  },
  {
      "Date": "1/14/10",
      "Rate": "18332.7325"
  },
  {
      "Date": "1/13/10",
      "Rate": "18238.19468"
  },
  {
      "Date": "1/12/10",
      "Rate": "18564.27803"
  },
  {
      "Date": "1/11/10",
      "Rate": "18522.19969"
  },
  {
      "Date": "1/8/10",
      "Rate": "18108.64742"
  },
  {
      "Date": "1/7/10",
      "Rate": "18225.93697"
  },
  {
      "Date": "1/6/10",
      "Rate": "18197.34387"
  },
  {
      "Date": "1/5/10",
      "Rate": "18355.59357"
  },
  {
      "Date": "1/4/10",
      "Rate": "18183.24485"
  },
  {
      "Date": "12/31/09",
      "Rate": "18118.96506"
  },
  {
      "Date": "12/30/09",
      "Rate": "18017.89802"
  },
  {
      "Date": "12/29/09",
      "Rate": "18157.99573"
  },
  {
      "Date": "12/24/09",
      "Rate": "18176.84615"
  },
  {
      "Date": "12/23/09",
      "Rate": "17869.53562"
  },
  {
      "Date": "12/22/09",
      "Rate": "18069.53209"
  },
  {
      "Date": "12/21/09",
      "Rate": "18403.3087"
  },
  {
      "Date": "12/18/09",
      "Rate": "18240.98485"
  },
  {
      "Date": "12/17/09",
      "Rate": "18551.55823"
  },
  {
      "Date": "12/16/09",
      "Rate": "18678.32942"
  },
  {
      "Date": "12/15/09",
      "Rate": "18372.26053"
  },
  {
      "Date": "12/14/09",
      "Rate": "18459.58483"
  },
  {
      "Date": "12/11/09",
      "Rate": "18724.88051"
  },
  {
      "Date": "12/10/09",
      "Rate": "18534.49973"
  },
  {
      "Date": "12/9/09",
      "Rate": "18757.80878"
  },
  {
      "Date": "12/8/09",
      "Rate": "19176.5816"
  },
  {
      "Date": "12/7/09",
      "Rate": "18852.11732"
  },
  {
      "Date": "12/4/09",
      "Rate": "19649.18605"
  },
  {
      "Date": "12/3/09",
      "Rate": "19808.19062"
  },
  {
      "Date": "12/2/09",
      "Rate": "19802.07058"
  },
  {
      "Date": "12/1/09",
      "Rate": "19506.80612"
  },
  {
      "Date": "11/30/09",
      "Rate": "19242.22297"
  },
  {
      "Date": "11/27/09",
      "Rate": "19172.48629"
  },
  {
      "Date": "11/26/09",
      "Rate": "19381.09667"
  },
  {
      "Date": "11/25/09",
      "Rate": "19183.30482"
  },
  {
      "Date": "11/24/09",
      "Rate": "19155.6994"
  },
  {
      "Date": "11/23/09",
      "Rate": "19118.00914"
  },
  {
      "Date": "11/20/09",
      "Rate": "18798.18339"
  },
  {
      "Date": "11/19/09",
      "Rate": "18711.2718"
  },
  {
      "Date": "11/18/09",
      "Rate": "18683.96973"
  },
  {
      "Date": "11/17/09",
      "Rate": "18491.56423"
  },
  {
      "Date": "11/16/09",
      "Rate": "18405.24172"
  },
  {
      "Date": "11/13/09",
      "Rate": "18105.11296"
  },
  {
      "Date": "11/12/09",
      "Rate": "18362.16512"
  },
  {
      "Date": "11/11/09",
      "Rate": "18211.83795"
  },
  {
      "Date": "11/10/09",
      "Rate": "18040.48396"
  },
  {
      "Date": "11/9/09",
      "Rate": "18168.38039"
  },
  {
      "Date": "11/6/09",
      "Rate": "18082.3048"
  },
  {
      "Date": "11/5/09",
      "Rate": "18054.97804"
  },
  {
      "Date": "11/4/09",
      "Rate": "18124.90873"
  },
  {
      "Date": "11/3/09",
      "Rate": "17695.22214"
  },
  {
      "Date": "11/2/09",
      "Rate": "17516.94386"
  },
  {
      "Date": "10/30/09",
      "Rate": "17303.63498"
  },
  {
      "Date": "10/29/09",
      "Rate": "17217.22429"
  },
  {
      "Date": "10/28/09",
      "Rate": "17239.46101"
  },
  {
      "Date": "10/27/09",
      "Rate": "17223.15737"
  },
  {
      "Date": "10/26/09",
      "Rate": "17350.1261"
  },
  {
      "Date": "10/23/09",
      "Rate": "17393.01575"
  },
  {
      "Date": "10/22/09",
      "Rate": "17389.73174"
  },
  {
      "Date": "10/21/09",
      "Rate": "17249.27777"
  },
  {
      "Date": "10/20/09",
      "Rate": "17307.66327"
  },
  {
      "Date": "10/19/09",
      "Rate": "17260.99931"
  },
  {
      "Date": "10/16/09",
      "Rate": "17150.51059"
  },
  {
      "Date": "10/15/09",
      "Rate": "17098.32272"
  },
  {
      "Date": "10/14/09",
      "Rate": "17347.71689"
  },
  {
      "Date": "10/13/09",
      "Rate": "17456.6077"
  },
  {
      "Date": "10/12/09",
      "Rate": "17251.61996"
  },
  {
      "Date": "10/9/09",
      "Rate": "17134.13993"
  },
  {
      "Date": "10/8/09",
      "Rate": "17242.76971"
  },
  {
      "Date": "10/7/09",
      "Rate": "17241.64447"
  },
  {
      "Date": "10/6/09",
      "Rate": "16874.90785"
  },
  {
      "Date": "10/5/09",
      "Rate": "16833.43974"
  },
  {
      "Date": "10/2/09",
      "Rate": "16807.88374"
  },
  {
      "Date": "10/1/09",
      "Rate": "16938.40456"
  },
  {
      "Date": "9/30/09",
      "Rate": "16989.76349"
  },
  {
      "Date": "9/29/09",
      "Rate": "16828.56135"
  },
  {
      "Date": "9/28/09",
      "Rate": "16789.88342"
  },
  {
      "Date": "9/25/09",
      "Rate": "16900.06526"
  },
  {
      "Date": "9/24/09",
      "Rate": "17159.61481"
  },
  {
      "Date": "9/23/09",
      "Rate": "17179.46701"
  },
  {
      "Date": "9/22/09",
      "Rate": "17185.64701"
  },
  {
      "Date": "9/21/09",
      "Rate": "16966.40152"
  },
  {
      "Date": "9/18/09",
      "Rate": "17216.84333"
  },
  {
      "Date": "9/17/09",
      "Rate": "17334.40801"
  },
  {
      "Date": "9/16/09",
      "Rate": "17303.65615"
  },
  {
      "Date": "9/15/09",
      "Rate": "17114.37591"
  },
  {
      "Date": "9/14/09",
      "Rate": "17091.93107"
  },
  {
      "Date": "9/11/09",
      "Rate": "17069.14408"
  },
  {
      "Date": "9/10/09",
      "Rate": "16958.21796"
  },
  {
      "Date": "9/9/09",
      "Rate": "17036.93187"
  },
  {
      "Date": "9/8/09",
      "Rate": "17172.46865"
  },
  {
      "Date": "9/7/09",
      "Rate": "17038.13118"
  },
  {
      "Date": "9/4/09",
      "Rate": "17023.83464"
  },
  {
      "Date": "9/3/09",
      "Rate": "16952.32367"
  },
  {
      "Date": "9/2/09",
      "Rate": "16496.35796"
  },
  {
      "Date": "9/1/09",
      "Rate": "16429.12221"
  },
  {
      "Date": "8/28/09",
      "Rate": "16317.74105"
  },
  {
      "Date": "8/27/09",
      "Rate": "16277.74388"
  },
  {
      "Date": "8/26/09",
      "Rate": "16386.28195"
  },
  {
      "Date": "8/25/09",
      "Rate": "16271.55329"
  },
  {
      "Date": "8/24/09",
      "Rate": "16355.33607"
  },
  {
      "Date": "8/21/09",
      "Rate": "16145.26535"
  },
  {
      "Date": "8/20/09",
      "Rate": "16199.53086"
  },
  {
      "Date": "8/19/09",
      "Rate": "16081.90621"
  },
  {
      "Date": "8/18/09",
      "Rate": "16150.76104"
  },
  {
      "Date": "8/17/09",
      "Rate": "16191.58715"
  },
  {
      "Date": "8/14/09",
      "Rate": "16294.67539"
  },
  {
      "Date": "8/13/09",
      "Rate": "16223.62299"
  },
  {
      "Date": "8/12/09",
      "Rate": "16096.36149"
  },
  {
      "Date": "8/11/09",
      "Rate": "16005.53449"
  },
  {
      "Date": "8/10/09",
      "Rate": "16080.29771"
  },
  {
      "Date": "8/7/09",
      "Rate": "16211.90144"
  },
  {
      "Date": "8/6/09",
      "Rate": "16160.20036"
  },
  {
      "Date": "8/5/09",
      "Rate": "16170.54622"
  },
  {
      "Date": "8/4/09",
      "Rate": "16058.43489"
  },
  {
      "Date": "8/3/09",
      "Rate": "16034.0394"
  },
  {
      "Date": "7/31/09",
      "Rate": "15877.01018"
  },
  {
      "Date": "7/30/09",
      "Rate": "15896.88002"
  },
  {
      "Date": "7/29/09",
      "Rate": "15978.02783"
  },
  {
      "Date": "7/28/09",
      "Rate": "16242.02543"
  },
  {
      "Date": "7/27/09",
      "Rate": "16242.17006"
  },
  {
      "Date": "7/24/09",
      "Rate": "16157.7594"
  },
  {
      "Date": "7/23/09",
      "Rate": "16325.459"
  },
  {
      "Date": "7/22/09",
      "Rate": "16182.17605"
  },
  {
      "Date": "7/21/09",
      "Rate": "16188.92397"
  },
  {
      "Date": "7/20/09",
      "Rate": "16190.21499"
  },
  {
      "Date": "7/17/09",
      "Rate": "16063.13692"
  },
  {
      "Date": "7/16/09",
      "Rate": "16056.2338"
  },
  {
      "Date": "7/15/09",
      "Rate": "15954.62001"
  },
  {
      "Date": "7/14/09",
      "Rate": "15922.00921"
  },
  {
      "Date": "7/13/09",
      "Rate": "15731.58962"
  },
  {
      "Date": "7/10/09",
      "Rate": "15728.67246"
  },
  {
      "Date": "7/9/09",
      "Rate": "15717.19783"
  },
  {
      "Date": "7/8/09",
      "Rate": "15878.75271"
  },
  {
      "Date": "7/7/09",
      "Rate": "15863.75068"
  },
  {
      "Date": "7/6/09",
      "Rate": "15789.29434"
  },
  {
      "Date": "7/3/09",
      "Rate": "15755.74525"
  },
  {
      "Date": "7/2/09",
      "Rate": "15833.04115"
  },
  {
      "Date": "7/1/09",
      "Rate": "15735.565"
  },
  {
      "Date": "6/30/09",
      "Rate": "15901.02471"
  },
  {
      "Date": "6/29/09",
      "Rate": "15946.19658"
  },
  {
      "Date": "6/26/09",
      "Rate": "15998.02113"
  },
  {
      "Date": "6/25/09",
      "Rate": "16014.34946"
  },
  {
      "Date": "6/24/09",
      "Rate": "15908.60509"
  },
  {
      "Date": "6/23/09",
      "Rate": "15758.14036"
  },
  {
      "Date": "6/22/09",
      "Rate": "15848.42766"
  },
  {
      "Date": "6/19/09",
      "Rate": "15836.15231"
  },
  {
      "Date": "6/18/09",
      "Rate": "15926.68301"
  },
  {
      "Date": "6/17/09",
      "Rate": "15850.97444"
  },
  {
      "Date": "6/16/09",
      "Rate": "15792.86054"
  },
  {
      "Date": "6/15/09",
      "Rate": "15684.83395"
  },
  {
      "Date": "6/12/09",
      "Rate": "15954.24963"
  },
  {
      "Date": "6/11/09",
      "Rate": "16002.94891"
  },
  {
      "Date": "6/10/09",
      "Rate": "16023.65827"
  },
  {
      "Date": "6/9/09",
      "Rate": "15955.91809"
  },
  {
      "Date": "6/8/09",
      "Rate": "15878.7774"
  },
  {
      "Date": "6/5/09",
      "Rate": "16247.83506"
  },
  {
      "Date": "6/4/09",
      "Rate": "16106.61564"
  },
  {
      "Date": "6/3/09",
      "Rate": "16256.52304"
  },
  {
      "Date": "6/2/09",
      "Rate": "16148.02377"
  },
  {
      "Date": "6/1/09",
      "Rate": "16351.06792"
  },
  {
      "Date": "5/29/09",
      "Rate": "16153.99919"
  },
  {
      "Date": "5/28/09",
      "Rate": "15948.37651"
  },
  {
      "Date": "5/27/09",
      "Rate": "15974.32053"
  },
  {
      "Date": "5/26/09",
      "Rate": "15917.92095"
  },
  {
      "Date": "5/22/09",
      "Rate": "15828.24388"
  },
  {
      "Date": "5/21/09",
      "Rate": "15709.21886"
  },
  {
      "Date": "5/20/09",
      "Rate": "15554.69761"
  },
  {
      "Date": "5/19/09",
      "Rate": "15530.88062"
  },
  {
      "Date": "5/18/09",
      "Rate": "15706.00187"
  },
  {
      "Date": "5/15/09",
      "Rate": "16111.92437"
  },
  {
      "Date": "5/14/09",
      "Rate": "16239.39047"
  },
  {
      "Date": "5/13/09",
      "Rate": "16232.74837"
  },
  {
      "Date": "5/12/09",
      "Rate": "16005.36165"
  },
  {
      "Date": "5/11/09",
      "Rate": "15938.45394"
  },
  {
      "Date": "5/8/09",
      "Rate": "15948.92326"
  },
  {
      "Date": "5/7/09",
      "Rate": "15866.3539"
  },
  {
      "Date": "5/6/09",
      "Rate": "15813.91912"
  },
  {
      "Date": "5/5/09",
      "Rate": "15703.23992"
  },
  {
      "Date": "5/1/09",
      "Rate": "15595.20626"
  },
  {
      "Date": "4/30/09",
      "Rate": "15727.89644"
  },
  {
      "Date": "4/29/09",
      "Rate": "15825.19974"
  },
  {
      "Date": "4/28/09",
      "Rate": "15983.33657"
  },
  {
      "Date": "4/27/09",
      "Rate": "16160.93406"
  },
  {
      "Date": "4/24/09",
      "Rate": "15971.10707"
  },
  {
      "Date": "4/23/09",
      "Rate": "15751.05028"
  },
  {
      "Date": "4/22/09",
      "Rate": "15711.75858"
  },
  {
      "Date": "4/21/09",
      "Rate": "15822.22261"
  },
  {
      "Date": "4/20/09",
      "Rate": "15440.85434"
  },
  {
      "Date": "4/17/09",
      "Rate": "15336.39747"
  },
  {
      "Date": "4/16/09",
      "Rate": "15604.02829"
  },
  {
      "Date": "4/15/09",
      "Rate": "15634.32159"
  },
  {
      "Date": "4/14/09",
      "Rate": "15751.96388"
  },
  {
      "Date": "4/9/09",
      "Rate": "15589.81287"
  },
  {
      "Date": "4/8/09",
      "Rate": "15708.67211"
  },
  {
      "Date": "4/7/09",
      "Rate": "15522.83462"
  },
  {
      "Date": "4/6/09",
      "Rate": "15527.25092"
  },
  {
      "Date": "4/3/09",
      "Rate": "16025.35847"
  },
  {
      "Date": "4/2/09",
      "Rate": "16260.89702"
  },
  {
      "Date": "4/1/09",
      "Rate": "16455.60945"
  },
  {
      "Date": "3/31/09",
      "Rate": "16437.71495"
  },
  {
      "Date": "3/30/09",
      "Rate": "16669.64144"
  },
  {
      "Date": "3/27/09",
      "Rate": "16537.51565"
  },
  {
      "Date": "3/26/09",
      "Rate": "16689.15148"
  },
  {
      "Date": "3/25/09",
      "Rate": "16491.80409"
  },
  {
      "Date": "3/24/09",
      "Rate": "16616.23662"
  },
  {
      "Date": "3/23/09",
      "Rate": "16961.60073"
  },
  {
      "Date": "3/20/09",
      "Rate": "17098.02642"
  },
  {
      "Date": "3/19/09",
      "Rate": "16662.51609"
  },
  {
      "Date": "3/18/09",
      "Rate": "16477.31706"
  },
  {
      "Date": "3/17/09",
      "Rate": "16706.32639"
  },
  {
      "Date": "3/16/09",
      "Rate": "16710.34057"
  },
  {
      "Date": "3/13/09",
      "Rate": "16707.94899"
  },
  {
      "Date": "3/12/09",
      "Rate": "16738.71144"
  },
  {
      "Date": "3/11/09",
      "Rate": "16463.78243"
  },
  {
      "Date": "3/10/09",
      "Rate": "16674.15298"
  },
  {
      "Date": "3/9/09",
      "Rate": "17099.46913"
  },
  {
      "Date": "3/6/09",
      "Rate": "17098.11461"
  },
  {
      "Date": "3/5/09",
      "Rate": "16675.56394"
  },
  {
      "Date": "3/4/09",
      "Rate": "16562.1757"
  },
  {
      "Date": "3/3/09",
      "Rate": "16952.41891"
  },
  {
      "Date": "3/2/09",
      "Rate": "17387.71054"
  },
  {
      "Date": "2/27/09",
      "Rate": "17024.42018"
  },
  {
      "Date": "2/26/09",
      "Rate": "16818.63172"
  },
  {
      "Date": "2/25/09",
      "Rate": "16845.14013"
  },
  {
      "Date": "2/24/09",
      "Rate": "17409.08305"
  },
  {
      "Date": "2/23/09",
      "Rate": "17313.71276"
  },
  {
      "Date": "2/20/09",
      "Rate": "17208.46223"
  },
  {
      "Date": "2/19/09",
      "Rate": "17045.99376"
  },
  {
      "Date": "2/18/09",
      "Rate": "16989.76702"
  },
  {
      "Date": "2/17/09",
      "Rate": "16852.39951"
  },
  {
      "Date": "2/16/09",
      "Rate": "16247.49643"
  },
  {
      "Date": "2/13/09",
      "Rate": "16101.71255"
  },
  {
      "Date": "2/12/09",
      "Rate": "16249.73985"
  },
  {
      "Date": "2/11/09",
      "Rate": "15822.25436"
  },
  {
      "Date": "2/10/09",
      "Rate": "15414.00025"
  },
  {
      "Date": "2/9/09",
      "Rate": "15430.09577"
  },
  {
      "Date": "2/6/09",
      "Rate": "15693.0281"
  },
  {
      "Date": "2/5/09",
      "Rate": "15736.55973"
  },
  {
      "Date": "2/4/09",
      "Rate": "15508.78851"
  },
  {
      "Date": "2/3/09",
      "Rate": "15533.12757"
  },
  {
      "Date": "2/2/09",
      "Rate": "15738.6938"
  },
  {
      "Date": "1/30/09",
      "Rate": "15865.87065"
  },
  {
      "Date": "1/29/09",
      "Rate": "15181.12136"
  },
  {
      "Date": "1/28/09",
      "Rate": "15379.44232"
  },
  {
      "Date": "1/27/09",
      "Rate": "15469.30986"
  },
  {
      "Date": "1/26/09",
      "Rate": "15732.12931"
  },
  {
      "Date": "1/23/09",
      "Rate": "15150.7434"
  },
  {
      "Date": "1/22/09",
      "Rate": "14685.62056"
  },
  {
      "Date": "1/21/09",
      "Rate": "14906.49218"
  },
  {
      "Date": "1/20/09",
      "Rate": "14494.20625"
  },
  {
      "Date": "1/19/09",
      "Rate": "14454.99921"
  },
  {
      "Date": "1/16/09",
      "Rate": "14165.13871"
  },
  {
      "Date": "1/15/09",
      "Rate": "14060.70301"
  },
  {
      "Date": "1/14/09",
      "Rate": "14242.95667"
  },
  {
      "Date": "1/13/09",
      "Rate": "14128.39027"
  },
  {
      "Date": "1/12/09",
      "Rate": "14619.2984"
  },
  {
      "Date": "1/9/09",
      "Rate": "14537.83665"
  },
  {
      "Date": "1/8/09",
      "Rate": "14507.00718"
  },
  {
      "Date": "1/7/09",
      "Rate": "14877.21477"
  },
  {
      "Date": "1/6/09",
      "Rate": "14510.50636"
  },
  {
      "Date": "1/5/09",
      "Rate": "14746.15073"
  },
  {
      "Date": "1/2/09",
      "Rate": "14901.05998"
  },
  {
      "Date": "12/31/08",
      "Rate": "14851.71873"
  },
  {
      "Date": "12/30/08",
      "Rate": "14873.11946"
  },
  {
      "Date": "12/29/08",
      "Rate": "15040.97074"
  },
  {
      "Date": "12/24/08",
      "Rate": "14185.1567"
  },
  {
      "Date": "12/23/08",
      "Rate": "14501.57498"
  },
  {
      "Date": "12/22/08",
      "Rate": "14321.07797"
  },
  {
      "Date": "12/19/08",
      "Rate": "13985.06146"
  },
  {
      "Date": "12/18/08",
      "Rate": "14431.1293"
  },
  {
      "Date": "12/17/08",
      "Rate": "14364.93765"
  },
  {
      "Date": "12/16/08",
      "Rate": "14091.83936"
  },
  {
      "Date": "12/15/08",
      "Rate": "14025.42549"
  },
  {
      "Date": "12/12/08",
      "Rate": "13907.90667"
  },
  {
      "Date": "12/11/08",
      "Rate": "14031.09402"
  },
  {
      "Date": "12/10/08",
      "Rate": "13567.24457"
  },
  {
      "Date": "12/9/08",
      "Rate": "13481.17956"
  },
  {
      "Date": "12/8/08",
      "Rate": "13503.03533"
  },
  {
      "Date": "12/5/08",
      "Rate": "13495.65601"
  },
  {
      "Date": "12/4/08",
      "Rate": "13582.64872"
  },
  {
      "Date": "12/3/08",
      "Rate": "13628.57546"
  },
  {
      "Date": "12/2/08",
      "Rate": "13665.45442"
  },
  {
      "Date": "12/1/08",
      "Rate": "14128.44318"
  },
  {
      "Date": "11/28/08",
      "Rate": "14376.39112"
  },
  {
      "Date": "11/27/08",
      "Rate": "14197.04404"
  },
  {
      "Date": "11/26/08",
      "Rate": "14258.12801"
  },
  {
      "Date": "11/25/08",
      "Rate": "14125.3814"
  },
  {
      "Date": "11/24/08",
      "Rate": "14376.20417"
  },
  {
      "Date": "11/21/08",
      "Rate": "13391.03688"
  },
  {
      "Date": "11/20/08",
      "Rate": "13189.97513"
  },
  {
      "Date": "11/19/08",
      "Rate": "13014.29655"
  },
  {
      "Date": "11/18/08",
      "Rate": "12898.72132"
  },
  {
      "Date": "11/17/08",
      "Rate": "12968.74724"
  },
  {
      "Date": "11/14/08",
      "Rate": "12603.71788"
  },
  {
      "Date": "11/13/08",
      "Rate": "12391.32965"
  },
  {
      "Date": "11/12/08",
      "Rate": "12695.03871"
  },
  {
      "Date": "11/11/08",
      "Rate": "12592.96637"
  },
  {
      "Date": "11/10/08",
      "Rate": "12555.90398"
  },
  {
      "Date": "11/7/08",
      "Rate": "12510.83793"
  },
  {
      "Date": "11/6/08",
      "Rate": "12442.0078"
  },
  {
      "Date": "11/5/08",
      "Rate": "12608.8573"
  },
  {
      "Date": "11/4/08",
      "Rate": "12348.11901"
  },
  {
      "Date": "11/3/08",
      "Rate": "12597.31918"
  },
  {
      "Date": "10/31/08",
      "Rate": "12707.21882"
  },
  {
      "Date": "10/30/08",
      "Rate": "13540.49278"
  },
  {
      "Date": "10/29/08",
      "Rate": "13137.2123"
  },
  {
      "Date": "10/28/08",
      "Rate": "13102.11468"
  },
  {
      "Date": "10/27/08",
      "Rate": "12671.23935"
  },
  {
      "Date": "10/24/08",
      "Rate": "12205.06887"
  },
  {
      "Date": "10/23/08",
      "Rate": "12756.44368"
  },
  {
      "Date": "10/22/08",
      "Rate": "13162.29563"
  },
  {
      "Date": "10/21/08",
      "Rate": "13490.73881"
  },
  {
      "Date": "10/20/08",
      "Rate": "13870.75963"
  },
  {
      "Date": "10/17/08",
      "Rate": "13812.19775"
  },
  {
      "Date": "10/16/08",
      "Rate": "14386.91688"
  },
  {
      "Date": "10/15/08",
      "Rate": "14525.76941"
  },
  {
      "Date": "10/14/08",
      "Rate": "14405.79552"
  },
  {
      "Date": "10/13/08",
      "Rate": "14725.09215"
  },
  {
      "Date": "10/10/08",
      "Rate": "15690.4672"
  },
  {
      "Date": "10/9/08",
      "Rate": "15014.02141"
  },
  {
      "Date": "10/8/08",
      "Rate": "15458.47369"
  },
  {
      "Date": "10/7/08",
      "Rate": "14912.25595"
  },
  {
      "Date": "10/6/08",
      "Rate": "14107.1518"
  },
  {
      "Date": "10/3/08",
      "Rate": "13988.42308"
  },
  {
      "Date": "10/2/08",
      "Rate": "14248.30773"
  },
  {
      "Date": "10/1/08",
      "Rate": "14408.6774"
  },
  {
      "Date": "9/30/08",
      "Rate": "14926.53486"
  },
  {
      "Date": "9/29/08",
      "Rate": "14585.40362"
  },
  {
      "Date": "9/26/08",
      "Rate": "14252.15965"
  },
  {
      "Date": "9/25/08",
      "Rate": "14443.76091"
  },
  {
      "Date": "9/24/08",
      "Rate": "14379.22362"
  },
  {
      "Date": "9/23/08",
      "Rate": "14358.95871"
  },
  {
      "Date": "9/22/08",
      "Rate": "13988.26082"
  },
  {
      "Date": "9/19/08",
      "Rate": "13496.24861"
  },
  {
      "Date": "9/18/08",
      "Rate": "14145.29357"
  },
  {
      "Date": "9/17/08",
      "Rate": "12886.85867"
  },
  {
      "Date": "9/16/08",
      "Rate": "12770.52153"
  },
  {
      "Date": "9/15/08",
      "Rate": "12624.89638"
  },
  {
      "Date": "9/12/08",
      "Rate": "12169.64673"
  },
  {
      "Date": "9/11/08",
      "Rate": "11987.69996"
  },
  {
      "Date": "9/10/08",
      "Rate": "12370.46509"
  },
  {
      "Date": "9/9/08",
      "Rate": "12636.83663"
  },
  {
      "Date": "9/8/08",
      "Rate": "12726.42551"
  },
  {
      "Date": "9/5/08",
      "Rate": "12522.55243"
  },
  {
      "Date": "9/4/08",
      "Rate": "12682.33302"
  },
  {
      "Date": "9/3/08",
      "Rate": "12427.76769"
  },
  {
      "Date": "9/2/08",
      "Rate": "12552.90569"
  },
  {
      "Date": "9/1/08",
      "Rate": "12958.59186"
  },
  {
      "Date": "8/29/08",
      "Rate": "12966.71546"
  },
  {
      "Date": "8/28/08",
      "Rate": "12841.20002"
  },
  {
      "Date": "8/27/08",
      "Rate": "12867.29219"
  },
  {
      "Date": "8/26/08",
      "Rate": "12529.20863"
  },
  {
      "Date": "8/22/08",
      "Rate": "12722.49951"
  },
  {
      "Date": "8/21/08",
      "Rate": "12599.02644"
  },
  {
      "Date": "8/20/08",
      "Rate": "12414.49408"
  },
  {
      "Date": "8/19/08",
      "Rate": "12147.01847"
  },
  {
      "Date": "8/18/08",
      "Rate": "12246.83328"
  },
  {
      "Date": "8/15/08",
      "Rate": "11905.71262"
  },
  {
      "Date": "8/14/08",
      "Rate": "12641.52101"
  },
  {
      "Date": "8/13/08",
      "Rate": "12416.89624"
  },
  {
      "Date": "8/12/08",
      "Rate": "12088.67176"
  },
  {
      "Date": "8/11/08",
      "Rate": "12846.79448"
  },
  {
      "Date": "8/8/08",
      "Rate": "12877.77209"
  },
  {
      "Date": "8/7/08",
      "Rate": "13130.3374"
  },
  {
      "Date": "8/6/08",
      "Rate": "13109.19769"
  },
  {
      "Date": "8/5/08",
      "Rate": "13158.89169"
  },
  {
      "Date": "8/4/08",
      "Rate": "13600.48678"
  },
  {
      "Date": "8/1/08",
      "Rate": "13599.43209"
  },
  {
      "Date": "7/31/08",
      "Rate": "13668.98182"
  },
  {
      "Date": "7/30/08",
      "Rate": "13668.21284"
  },
  {
      "Date": "7/29/08",
      "Rate": "13920.03034"
  },
  {
      "Date": "7/28/08",
      "Rate": "13930.80301"
  },
  {
      "Date": "7/25/08",
      "Rate": "13911.65276"
  },
  {
      "Date": "7/24/08",
      "Rate": "13747.96028"
  },
  {
      "Date": "7/23/08",
      "Rate": "13892.90464"
  },
  {
      "Date": "7/22/08",
      "Rate": "14636.44509"
  },
  {
      "Date": "7/21/08",
      "Rate": "14563.87591"
  },
  {
      "Date": "7/18/08",
      "Rate": "14421.05857"
  },
  {
      "Date": "7/17/08",
      "Rate": "14614.04963"
  },
  {
      "Date": "7/16/08",
      "Rate": "14802.65613"
  },
  {
      "Date": "7/15/08",
      "Rate": "14925.63537"
  },
  {
      "Date": "7/14/08",
      "Rate": "14476.30117"
  },
  {
      "Date": "7/11/08",
      "Rate": "14355.76289"
  },
  {
      "Date": "7/10/08",
      "Rate": "14105.95249"
  },
  {
      "Date": "7/9/08",
      "Rate": "14039.5104"
  },
  {
      "Date": "7/8/08",
      "Rate": "14175.00132"
  },
  {
      "Date": "7/7/08",
      "Rate": "14050.77691"
  },
  {
      "Date": "7/4/08",
      "Rate": "14166.29923"
  },
  {
      "Date": "7/3/08",
      "Rate": "14320.25256"
  },
  {
      "Date": "7/2/08",
      "Rate": "14259.80705"
  },
  {
      "Date": "7/1/08",
      "Rate": "14116.51705"
  },
  {
      "Date": "6/30/08",
      "Rate": "14165.87594"
  },
  {
      "Date": "6/27/08",
      "Rate": "13961.14923"
  },
  {
      "Date": "6/26/08",
      "Rate": "13505.79375"
  },
  {
      "Date": "6/25/08",
      "Rate": "13374.70855"
  },
  {
      "Date": "6/24/08",
      "Rate": "13426.45902"
  },
  {
      "Date": "6/23/08",
      "Rate": "13743.43816"
  },
  {
      "Date": "6/20/08",
      "Rate": "13682.781"
  },
  {
      "Date": "6/19/08",
      "Rate": "13548.65165"
  },
  {
      "Date": "6/18/08",
      "Rate": "13402.11291"
  },
  {
      "Date": "6/17/08",
      "Rate": "13349.57583"
  },
  {
      "Date": "6/16/08",
      "Rate": "13234.02529"
  },
  {
      "Date": "6/13/08",
      "Rate": "13068.42096"
  },
  {
      "Date": "6/12/08",
      "Rate": "13116.61581"
  },
  {
      "Date": "6/11/08",
      "Rate": "13195.70363"
  },
  {
      "Date": "6/10/08",
      "Rate": "13437.77492"
  },
  {
      "Date": "6/9/08",
      "Rate": "13705.92779"
  },
  {
      "Date": "6/6/08",
      "Rate": "13392.97342"
  },
  {
      "Date": "6/5/08",
      "Rate": "13238.42396"
  },
  {
      "Date": "6/4/08",
      "Rate": "13275.02425"
  },
  {
      "Date": "6/3/08",
      "Rate": "13450.11023"
  },
  {
      "Date": "6/2/08",
      "Rate": "13354.83871"
  },
  {
      "Date": "5/30/08",
      "Rate": "13095.01049"
  },
  {
      "Date": "5/29/08",
      "Rate": "13374.76146"
  },
  {
      "Date": "5/28/08",
      "Rate": "13460.4702"
  },
  {
      "Date": "5/27/08",
      "Rate": "13990.09154"
  },
  {
      "Date": "5/23/08",
      "Rate": "13907.1906"
  },
  {
      "Date": "5/22/08",
      "Rate": "14040.23351"
  },
  {
      "Date": "5/21/08",
      "Rate": "13995.97171"
  },
  {
      "Date": "5/20/08",
      "Rate": "13653.23198"
  },
  {
      "Date": "5/19/08",
      "Rate": "13636.30046"
  },
  {
      "Date": "5/16/08",
      "Rate": "13279.91675"
  },
  {
      "Date": "5/15/08",
      "Rate": "13013.84151"
  },
  {
      "Date": "5/14/08",
      "Rate": "12913.26478"
  },
  {
      "Date": "5/13/08",
      "Rate": "13076.34703"
  },
  {
      "Date": "5/12/08",
      "Rate": "13068.0788"
  },
  {
      "Date": "5/9/08",
      "Rate": "13016.36008"
  },
  {
      "Date": "5/8/08",
      "Rate": "12843.99372"
  },
  {
      "Date": "5/7/08",
      "Rate": "12794.22918"
  },
  {
      "Date": "5/6/08",
      "Rate": "12677.80031"
  },
  {
      "Date": "5/2/08",
      "Rate": "12253.50712"
  },
  {
      "Date": "5/1/08",
      "Rate": "12363.3468"
  },
  {
      "Date": "4/30/08",
      "Rate": "12424.19443"
  },
  {
      "Date": "4/29/08",
      "Rate": "12640.17708"
  },
  {
      "Date": "4/28/08",
      "Rate": "12656.95339"
  },
  {
      "Date": "4/25/08",
      "Rate": "12504.78492"
  },
  {
      "Date": "4/24/08",
      "Rate": "12764.82125"
  },
  {
      "Date": "4/23/08",
      "Rate": "12952.15789"
  },
  {
      "Date": "4/22/08",
      "Rate": "12973.54803"
  },
  {
      "Date": "4/21/08",
      "Rate": "12880.48466"
  },
  {
      "Date": "4/18/08",
      "Rate": "13223.30905"
  },
  {
      "Date": "4/17/08",
      "Rate": "13353.12087"
  },
  {
      "Date": "4/16/08",
      "Rate": "13145.92144"
  },
  {
      "Date": "4/15/08",
      "Rate": "13148.26011"
  },
  {
      "Date": "4/14/08",
      "Rate": "12939.37106"
  },
  {
      "Date": "4/11/08",
      "Rate": "13048.63931"
  },
  {
      "Date": "4/10/08",
      "Rate": "13152.23196"
  },
  {
      "Date": "4/9/08",
      "Rate": "12798.6737"
  },
  {
      "Date": "4/8/08",
      "Rate": "13001.43565"
  },
  {
      "Date": "4/7/08",
      "Rate": "12886.68936"
  },
  {
      "Date": "4/4/08",
      "Rate": "12763.13162"
  },
  {
      "Date": "4/3/08",
      "Rate": "12683.45121"
  },
  {
      "Date": "4/2/08",
      "Rate": "12588.0139"
  },
  {
      "Date": "4/1/08",
      "Rate": "12691.11272"
  },
  {
      "Date": "3/31/08",
      "Rate": "13260.58661"
  },
  {
      "Date": "3/28/08",
      "Rate": "13296.52728"
  },
  {
      "Date": "3/27/08",
      "Rate": "13411.19949"
  },
  {
      "Date": "3/26/08",
      "Rate": "13391.68945"
  },
  {
      "Date": "3/25/08",
      "Rate": "13155.71703"
  },
  {
      "Date": "3/20/08",
      "Rate": "13027.67597"
  },
  {
      "Date": "3/19/08",
      "Rate": "14193.53428"
  },
  {
      "Date": "3/18/08",
      "Rate": "14371.65735"
  },
  {
      "Date": "3/17/08",
      "Rate": "14706.52745"
  },
  {
      "Date": "3/14/08",
      "Rate": "14234.31807"
  },
  {
      "Date": "3/13/08",
      "Rate": "14105.90663"
  },
  {
      "Date": "3/12/08",
      "Rate": "13881.02083"
  },
  {
      "Date": "3/11/08",
      "Rate": "13993.55544"
  },
  {
      "Date": "3/10/08",
      "Rate": "13904.96129"
  },
  {
      "Date": "3/7/08",
      "Rate": "13983.99619"
  },
  {
      "Date": "3/6/08",
      "Rate": "14021.69703"
  },
  {
      "Date": "3/5/08",
      "Rate": "13737.35339"
  },
  {
      "Date": "3/4/08",
      "Rate": "13961.18097"
  },
  {
      "Date": "3/3/08",
      "Rate": "13937.2899"
  },
  {
      "Date": "2/29/08",
      "Rate": "13679.88853"
  },
  {
      "Date": "2/28/08",
      "Rate": "13462.375"
  },
  {
      "Date": "2/27/08",
      "Rate": "13451.48239"
  },
  {
      "Date": "2/26/08",
      "Rate": "13147.06432"
  },
  {
      "Date": "2/25/08",
      "Rate": "13357.14563"
  },
  {
      "Date": "2/22/08",
      "Rate": "13360.83176"
  },
  {
      "Date": "2/21/08",
      "Rate": "13281.23247"
  },
  {
      "Date": "2/20/08",
      "Rate": "13114.50643"
  },
  {
      "Date": "2/19/08",
      "Rate": "12908.81673"
  },
  {
      "Date": "2/18/08",
      "Rate": "12700.54851"
  },
  {
      "Date": "2/15/08",
      "Rate": "12730.30565"
  },
  {
      "Date": "2/14/08",
      "Rate": "12724.81349"
  },
  {
      "Date": "2/13/08",
      "Rate": "12699.09522"
  },
  {
      "Date": "2/12/08",
      "Rate": "12883.72634"
  },
  {
      "Date": "2/11/08",
      "Rate": "12958.85994"
  },
  {
      "Date": "2/8/08",
      "Rate": "12778.49345"
  },
  {
      "Date": "2/7/08",
      "Rate": "12666.06466"
  },
  {
      "Date": "2/6/08",
      "Rate": "12430.79772"
  },
  {
      "Date": "2/5/08",
      "Rate": "12414.34946"
  },
  {
      "Date": "2/4/08",
      "Rate": "12515.48705"
  },
  {
      "Date": "2/1/08",
      "Rate": "12953.62528"
  },
  {
      "Date": "1/31/08",
      "Rate": "12831.32683"
  },
  {
      "Date": "1/30/08",
      "Rate": "12835.39745"
  },
  {
      "Date": "1/29/08",
      "Rate": "12883.80747"
  },
  {
      "Date": "1/28/08",
      "Rate": "12732.62315"
  },
  {
      "Date": "1/25/08",
      "Rate": "12792.11626"
  },
  {
      "Date": "1/24/08",
      "Rate": "12412.03549"
  },
  {
      "Date": "1/23/08",
      "Rate": "12396.53609"
  },
  {
      "Date": "1/22/08",
      "Rate": "12005.88017"
  },
  {
      "Date": "1/21/08",
      "Rate": "12198.08462"
  },
  {
      "Date": "1/18/08",
      "Rate": "12098.65077"
  },
  {
      "Date": "1/17/08",
      "Rate": "12223.06214"
  },
  {
      "Date": "1/16/08",
      "Rate": "12211.09367"
  },
  {
      "Date": "1/15/08",
      "Rate": "12531.08873"
  },
  {
      "Date": "1/14/08",
      "Rate": "12632.61786"
  },
  {
      "Date": "1/11/08",
      "Rate": "12388.1938"
  },
  {
      "Date": "1/10/08",
      "Rate": "12114.82037"
  },
  {
      "Date": "1/9/08",
      "Rate": "12302.90481"
  },
  {
      "Date": "1/8/08",
      "Rate": "12097.8818"
  },
  {
      "Date": "1/7/08",
      "Rate": "11885.73343"
  },
  {
      "Date": "1/4/08",
      "Rate": "11910.63334"
  },
  {
      "Date": "1/3/08",
      "Rate": "12028.12043"
  },
  {
      "Date": "1/2/08",
      "Rate": "11693.24327"
  },
  {
      "Date": "12/31/07",
      "Rate": "11630.06402"
  },
  {
      "Date": "12/28/07",
      "Rate": "11524.68298"
  },
  {
      "Date": "12/27/07",
      "Rate": "11436.51916"
  },
  {
      "Date": "12/24/07",
      "Rate": "11275.10538"
  },
  {
      "Date": "12/21/07",
      "Rate": "11201.12877"
  },
  {
      "Date": "12/20/07",
      "Rate": "11152.30604"
  },
  {
      "Date": "12/19/07",
      "Rate": "11186.22551"
  },
  {
      "Date": "12/18/07",
      "Rate": "11112.58752"
  },
  {
      "Date": "12/17/07",
      "Rate": "10977.94317"
  },
  {
      "Date": "12/14/07",
      "Rate": "11050.79807"
  },
  {
      "Date": "12/13/07",
      "Rate": "11244.87204"
  },
  {
      "Date": "12/12/07",
      "Rate": "11215.4747"
  },
  {
      "Date": "12/11/07",
      "Rate": "11195.80945"
  },
  {
      "Date": "12/10/07",
      "Rate": "11119.77284"
  },
  {
      "Date": "12/7/07",
      "Rate": "11116.29835"
  },
  {
      "Date": "12/6/07",
      "Rate": "10985.6576"
  },
  {
      "Date": "12/5/07",
      "Rate": "11167.08937"
  },
  {
      "Date": "12/4/07",
      "Rate": "10987.03681"
  },
  {
      "Date": "12/3/07",
      "Rate": "10918.78516"
  },
  {
      "Date": "11/30/07",
      "Rate": "11104.26992"
  },
  {
      "Date": "11/29/07",
      "Rate": "11321.63177"
  },
  {
      "Date": "11/28/07",
      "Rate": "11179.99612"
  },
  {
      "Date": "11/27/07",
      "Rate": "11562.00286"
  },
  {
      "Date": "11/26/07",
      "Rate": "11737.20524"
  },
  {
      "Date": "11/23/07",
      "Rate": "11354.3484"
  },
  {
      "Date": "11/22/07",
      "Rate": "11185.00503"
  },
  {
      "Date": "11/21/07",
      "Rate": "11064.84065"
  },
  {
      "Date": "11/20/07",
      "Rate": "10964.43324"
  },
  {
      "Date": "11/19/07",
      "Rate": "10941.8473"
  },
  {
      "Date": "11/16/07",
      "Rate": "10961.94995"
  },
  {
      "Date": "11/15/07",
      "Rate": "11170.47567"
  },
  {
      "Date": "11/14/07",
      "Rate": "11205.93661"
  },
  {
      "Date": "11/13/07",
      "Rate": "11176.24297"
  },
  {
      "Date": "11/12/07",
      "Rate": "11338.77846"
  },
  {
      "Date": "11/9/07",
      "Rate": "11583.30129"
  },
  {
      "Date": "11/8/07",
      "Rate": "11531.3427"
  },
  {
      "Date": "11/7/07",
      "Rate": "11677.81795"
  },
  {
      "Date": "11/6/07",
      "Rate": "11327.66363"
  },
  {
      "Date": "11/5/07",
      "Rate": "11138.95483"
  },
  {
      "Date": "11/2/07",
      "Rate": "10999.56966"
  },
  {
      "Date": "11/1/07",
      "Rate": "11022.54361"
  },
  {
      "Date": "10/31/07",
      "Rate": "10868.28692"
  },
  {
      "Date": "10/30/07",
      "Rate": "10900.14286"
  },
  {
      "Date": "10/29/07",
      "Rate": "10981.97852"
  },
  {
      "Date": "10/26/07",
      "Rate": "10794.29972"
  },
  {
      "Date": "10/25/07",
      "Rate": "10668.36452"
  },
  {
      "Date": "10/24/07",
      "Rate": "10589.72821"
  },
  {
      "Date": "10/23/07",
      "Rate": "10586.48301"
  },
  {
      "Date": "10/22/07",
      "Rate": "10633.22457"
  },
  {
      "Date": "10/19/07",
      "Rate": "10767.88303"
  },
  {
      "Date": "10/18/07",
      "Rate": "10635.81368"
  },
  {
      "Date": "10/17/07",
      "Rate": "10585.77047"
  },
  {
      "Date": "10/16/07",
      "Rate": "10574.35228"
  },
  {
      "Date": "10/15/07",
      "Rate": "10480.77038"
  },
  {
      "Date": "10/12/07",
      "Rate": "10382.81451"
  },
  {
      "Date": "10/11/07",
      "Rate": "10349.17018"
  },
  {
      "Date": "10/10/07",
      "Rate": "10273.1512"
  },
  {
      "Date": "10/9/07",
      "Rate": "10136.53856"
  },
  {
      "Date": "10/8/07",
      "Rate": "10250.1314"
  },
  {
      "Date": "10/5/07",
      "Rate": "10239.78553"
  },
  {
      "Date": "10/4/07",
      "Rate": "10119.56472"
  },
  {
      "Date": "10/3/07",
      "Rate": "10233.24926"
  },
  {
      "Date": "10/2/07",
      "Rate": "10339.61093"
  },
  {
      "Date": "10/1/07",
      "Rate": "10473.11593"
  },
  {
      "Date": "9/28/07",
      "Rate": "10349.50175"
  },
  {
      "Date": "9/27/07",
      "Rate": "10229.55255"
  },
  {
      "Date": "9/26/07",
      "Rate": "10227.88056"
  },
  {
      "Date": "9/25/07",
      "Rate": "10200.81483"
  },
  {
      "Date": "9/24/07",
      "Rate": "10265.68017"
  },
  {
      "Date": "9/21/07",
      "Rate": "10333.98825"
  },
  {
      "Date": "9/20/07",
      "Rate": "10201.51678"
  },
  {
      "Date": "9/19/07",
      "Rate": "10243.73269"
  },
  {
      "Date": "9/18/07",
      "Rate": "10253.03797"
  },
  {
      "Date": "9/17/07",
      "Rate": "10161.01166"
  },
  {
      "Date": "9/14/07",
      "Rate": "10082.89388"
  },
  {
      "Date": "9/13/07",
      "Rate": "10100.90478"
  },
  {
      "Date": "9/12/07",
      "Rate": "10149.20545"
  },
  {
      "Date": "9/11/07",
      "Rate": "10074.55511"
  },
  {
      "Date": "9/10/07",
      "Rate": "10094.56957"
  },
  {
      "Date": "9/7/07",
      "Rate": "9972.874301"
  },
  {
      "Date": "9/6/07",
      "Rate": "9834.378031"
  },
  {
      "Date": "9/5/07",
      "Rate": "9817.569975"
  },
  {
      "Date": "9/4/07",
      "Rate": "9716.626396"
  },
  {
      "Date": "9/3/07",
      "Rate": "9696.315632"
  },
  {
      "Date": "8/31/07",
      "Rate": "9637.277553"
  },
  {
      "Date": "8/30/07",
      "Rate": "9663.281539"
  },
  {
      "Date": "8/29/07",
      "Rate": "9639.04478"
  },
  {
      "Date": "8/28/07",
      "Rate": "9689.906348"
  },
  {
      "Date": "8/24/07",
      "Rate": "9562.193337"
  },
  {
      "Date": "8/23/07",
      "Rate": "9587.026226"
  },
  {
      "Date": "8/22/07",
      "Rate": "9485.380695"
  },
  {
      "Date": "8/21/07",
      "Rate": "9510.121872"
  },
  {
      "Date": "8/20/07",
      "Rate": "9603.583837"
  },
  {
      "Date": "8/17/07",
      "Rate": "9523.37431"
  },
  {
      "Date": "8/16/07",
      "Rate": "9684.812783"
  },
  {
      "Date": "8/15/07",
      "Rate": "9547.081959"
  },
  {
      "Date": "8/14/07",
      "Rate": "9580.821531"
  },
  {
      "Date": "8/13/07",
      "Rate": "9583.632868"
  },
  {
      "Date": "8/10/07",
      "Rate": "9521.152049"
  },
  {
      "Date": "8/9/07",
      "Rate": "9600.303356"
  },
  {
      "Date": "8/8/07",
      "Rate": "9583.816293"
  },
  {
      "Date": "8/7/07",
      "Rate": "9547.900316"
  },
  {
      "Date": "8/6/07",
      "Rate": "9602.941851"
  },
  {
      "Date": "8/3/07",
      "Rate": "9491.246759"
  },
  {
      "Date": "8/2/07",
      "Rate": "9486.978606"
  },
  {
      "Date": "8/1/07",
      "Rate": "9422.130902"
  },
  {
      "Date": "7/31/07",
      "Rate": "9511.056632"
  },
  {
      "Date": "7/30/07",
      "Rate": "9457.168557"
  },
  {
      "Date": "7/27/07",
      "Rate": "9498.633133"
  },
  {
      "Date": "7/26/07",
      "Rate": "9614.793912"
  },
  {
      "Date": "7/25/07",
      "Rate": "9634.557929"
  },
  {
      "Date": "7/24/07",
      "Rate": "9690.523642"
  },
  {
      "Date": "7/23/07",
      "Rate": "9713.829168"
  },
  {
      "Date": "7/20/07",
      "Rate": "9614.867987"
  },
  {
      "Date": "7/19/07",
      "Rate": "9584.497081"
  },
  {
      "Date": "7/18/07",
      "Rate": "9500.001764"
  },
  {
      "Date": "7/17/07",
      "Rate": "9439.820808"
  },
  {
      "Date": "7/16/07",
      "Rate": "9497.007002"
  },
  {
      "Date": "7/13/07",
      "Rate": "9517.046156"
  },
  {
      "Date": "7/12/07",
      "Rate": "9472.181167"
  },
  {
      "Date": "7/11/07",
      "Rate": "9456.604173"
  },
  {
      "Date": "7/10/07",
      "Rate": "9426.508404"
  },
  {
      "Date": "7/9/07",
      "Rate": "9358.069807"
  },
  {
      "Date": "7/6/07",
      "Rate": "9243.454029"
  },
  {
      "Date": "7/5/07",
      "Rate": "9356.284943"
  },
  {
      "Date": "7/4/07",
      "Rate": "9331.487328"
  },
  {
      "Date": "7/3/07",
      "Rate": "9400.462089"
  },
  {
      "Date": "7/2/07",
      "Rate": "9329.73421"
  },
  {
      "Date": "6/29/07",
      "Rate": "9310.199474"
  },
  {
      "Date": "6/28/07",
      "Rate": "9306.442794"
  },
  {
      "Date": "6/27/07",
      "Rate": "9298.81303"
  },
  {
      "Date": "6/26/07",
      "Rate": "9380.264202"
  },
  {
      "Date": "6/25/07",
      "Rate": "9387.583555"
  },
  {
      "Date": "6/22/07",
      "Rate": "9374.246459"
  },
  {
      "Date": "6/21/07",
      "Rate": "9405.573291"
  },
  {
      "Date": "6/20/07",
      "Rate": "9469.556782"
  },
  {
      "Date": "6/19/07",
      "Rate": "9411.883807"
  },
  {
      "Date": "6/18/07",
      "Rate": "9440.783788"
  },
  {
      "Date": "6/15/07",
      "Rate": "9377.068379"
  },
  {
      "Date": "6/14/07",
      "Rate": "9389.558899"
  },
  {
      "Date": "6/13/07",
      "Rate": "9304.968342"
  },
  {
      "Date": "6/12/07",
      "Rate": "9392.655955"
  },
  {
      "Date": "6/11/07",
      "Rate": "9397.118115"
  },
  {
      "Date": "6/8/07",
      "Rate": "9534.04822"
  },
  {
      "Date": "6/7/07",
      "Rate": "9636.984779"
  },
  {
      "Date": "6/6/07",
      "Rate": "9608.906683"
  },
  {
      "Date": "6/5/07",
      "Rate": "9594.906436"
  },
  {
      "Date": "6/4/07",
      "Rate": "9587.043863"
  },
  {
      "Date": "6/1/07",
      "Rate": "9472.632674"
  },
  {
      "Date": "5/31/07",
      "Rate": "9396.377361"
  },
  {
      "Date": "5/30/07",
      "Rate": "9464.957054"
  },
  {
      "Date": "5/29/07",
      "Rate": "9395.029895"
  },
  {
      "Date": "5/25/07",
      "Rate": "9374.638001"
  },
  {
      "Date": "5/24/07",
      "Rate": "9472.332845"
  },
  {
      "Date": "5/23/07",
      "Rate": "9432.723681"
  },
  {
      "Date": "5/22/07",
      "Rate": "9500.971798"
  },
  {
      "Date": "5/21/07",
      "Rate": "9501.578511"
  },
  {
      "Date": "5/18/07",
      "Rate": "9489.56419"
  },
  {
      "Date": "5/17/07",
      "Rate": "9553.992134"
  },
  {
      "Date": "5/16/07",
      "Rate": "9649.535265"
  },
  {
      "Date": "5/15/07",
      "Rate": "9638.145294"
  },
  {
      "Date": "5/14/07",
      "Rate": "9719.589411"
  },
  {
      "Date": "5/11/07",
      "Rate": "9676.339971"
  },
  {
      "Date": "5/10/07",
      "Rate": "9914.478209"
  },
  {
      "Date": "5/9/07",
      "Rate": "9906.598"
  },
  {
      "Date": "5/8/07",
      "Rate": "9882.216618"
  },
  {
      "Date": "5/4/07",
      "Rate": "9812.338842"
  },
  {
      "Date": "5/3/07",
      "Rate": "9820.391894"
  },
  {
      "Date": "5/2/07",
      "Rate": "9781.890333"
  },
  {
      "Date": "5/1/07",
      "Rate": "9843.640981"
  },
  {
      "Date": "4/30/07",
      "Rate": "9850.903896"
  },
  {
      "Date": "4/27/07",
      "Rate": "9747.272439"
  },
  {
      "Date": "4/26/07",
      "Rate": "9883.70518"
  },
  {
      "Date": "4/25/07",
      "Rate": "9920.425404"
  },
  {
      "Date": "4/24/07",
      "Rate": "10008.73384"
  },
  {
      "Date": "4/23/07",
      "Rate": "10158.6095"
  },
  {
      "Date": "4/20/07",
      "Rate": "10132.27394"
  },
  {
      "Date": "4/19/07",
      "Rate": "10200.44798"
  },
  {
      "Date": "4/18/07",
      "Rate": "10230.76245"
  },
  {
      "Date": "4/17/07",
      "Rate": "10189.13208"
  },
  {
      "Date": "4/16/07",
      "Rate": "10158.63066"
  },
  {
      "Date": "4/13/07",
      "Rate": "10159.35731"
  },
  {
      "Date": "4/12/07",
      "Rate": "10256.06095"
  },
  {
      "Date": "4/11/07",
      "Rate": "10263.33445"
  },
  {
      "Date": "4/10/07",
      "Rate": "10229.33032"
  },
  {
      "Date": "4/5/07",
      "Rate": "10184.67345"
  },
  {
      "Date": "4/4/07",
      "Rate": "10120.21376"
  },
  {
      "Date": "4/3/07",
      "Rate": "10079.0596"
  },
  {
      "Date": "4/2/07",
      "Rate": "10171.36457"
  },
  {
      "Date": "3/30/07",
      "Rate": "10174.42988"
  },
  {
      "Date": "3/29/07",
      "Rate": "10260.39613"
  },
  {
      "Date": "3/28/07",
      "Rate": "10106.2629"
  },
  {
      "Date": "3/27/07",
      "Rate": "10158.08744"
  },
  {
      "Date": "3/26/07",
      "Rate": "10074.09302"
  },
  {
      "Date": "3/23/07",
      "Rate": "10180.0067"
  },
  {
      "Date": "3/22/07",
      "Rate": "10245.92321"
  },
  {
      "Date": "3/21/07",
      "Rate": "10122.01979"
  },
  {
      "Date": "3/20/07",
      "Rate": "10098.94354"
  },
  {
      "Date": "3/19/07",
      "Rate": "10176.67331"
  },
  {
      "Date": "3/16/07",
      "Rate": "10115.66342"
  },
  {
      "Date": "3/15/07",
      "Rate": "10073.13709"
  },
  {
      "Date": "3/14/07",
      "Rate": "10001.86599"
  },
  {
      "Date": "3/13/07",
      "Rate": "10146.60223"
  },
  {
      "Date": "3/12/07",
      "Rate": "10178.94848"
  },
  {
      "Date": "3/9/07",
      "Rate": "10188.47599"
  },
  {
      "Date": "3/8/07",
      "Rate": "10222.66354"
  },
  {
      "Date": "3/7/07",
      "Rate": "10145.43466"
  },
  {
      "Date": "3/6/07",
      "Rate": "10110.2806"
  },
  {
      "Date": "3/5/07",
      "Rate": "10064.60431"
  },
  {
      "Date": "3/2/07",
      "Rate": "10374.46163"
  },
  {
      "Date": "3/1/07",
      "Rate": "10517.01794"
  },
  {
      "Date": "2/28/07",
      "Rate": "10572.48981"
  },
  {
      "Date": "2/27/07",
      "Rate": "10675.73326"
  },
  {
      "Date": "2/26/07",
      "Rate": "10674.52689"
  },
  {
      "Date": "2/23/07",
      "Rate": "10561.7771"
  },
  {
      "Date": "2/22/07",
      "Rate": "10556.87049"
  },
  {
      "Date": "2/21/07",
      "Rate": "10266.55849"
  },
  {
      "Date": "2/20/07",
      "Rate": "10420.39189"
  },
  {
      "Date": "2/19/07",
      "Rate": "10437.87016"
  },
  {
      "Date": "2/16/07",
      "Rate": "10348.82097"
  },
  {
      "Date": "2/15/07",
      "Rate": "10422.71292"
  },
  {
      "Date": "2/14/07",
      "Rate": "10399.89771"
  },
  {
      "Date": "2/13/07",
      "Rate": "10364.48967"
  },
  {
      "Date": "2/12/07",
      "Rate": "10333.00058"
  },
  {
      "Date": "2/9/07",
      "Rate": "10250.80513"
  },
  {
      "Date": "2/8/07",
      "Rate": "10126.72887"
  },
  {
      "Date": "2/7/07",
      "Rate": "10198.45853"
  },
  {
      "Date": "2/6/07",
      "Rate": "10162.7295"
  },
  {
      "Date": "2/5/07",
      "Rate": "10083.03497"
  },
  {
      "Date": "2/2/07",
      "Rate": "10184.02441"
  },
  {
      "Date": "2/1/07",
      "Rate": "10153.8334"
  },
  {
      "Date": "1/31/07",
      "Rate": "10058.84407"
  },
  {
      "Date": "1/30/07",
      "Rate": "10022.44837"
  },
  {
      "Date": "1/29/07",
      "Rate": "10036.99183"
  },
  {
      "Date": "1/26/07",
      "Rate": "10095.64543"
  },
  {
      "Date": "1/25/07",
      "Rate": "10122.73938"
  },
  {
      "Date": "1/24/07",
      "Rate": "10027.34793"
  },
  {
      "Date": "1/23/07",
      "Rate": "9942.700929"
  },
  {
      "Date": "1/22/07",
      "Rate": "9895.190391"
  },
  {
      "Date": "1/19/07",
      "Rate": "9823.732341"
  },
  {
      "Date": "1/18/07",
      "Rate": "9890.604773"
  },
  {
      "Date": "1/17/07",
      "Rate": "9709.462248"
  },
  {
      "Date": "1/16/07",
      "Rate": "9769.24108"
  },
  {
      "Date": "1/15/07",
      "Rate": "9815.054939"
  },
  {
      "Date": "1/12/07",
      "Rate": "9624.317889"
  },
  {
      "Date": "1/11/07",
      "Rate": "9645.214201"
  },
  {
      "Date": "1/10/07",
      "Rate": "9599.82363"
  },
  {
      "Date": "1/9/07",
      "Rate": "9582.232491"
  },
  {
      "Date": "1/8/07",
      "Rate": "9547.367678"
  },
  {
      "Date": "1/5/07",
      "Rate": "9783.65756"
  },
  {
      "Date": "1/4/07",
      "Rate": "9767.085839"
  },
  {
      "Date": "1/3/07",
      "Rate": "9966.941216"
  },
  {
      "Date": "1/2/07",
      "Rate": "9998.476164"
  },
  {
      "Date": "12/29/06",
      "Rate": "9924.718249"
  },
  {
      "Date": "12/28/06",
      "Rate": "9826.772959"
  },
  {
      "Date": "12/27/06",
      "Rate": "9825.809979"
  },
  {
      "Date": "12/22/06",
      "Rate": "9745.942609"
  },
  {
      "Date": "12/21/06",
      "Rate": "9805.174694"
  },
  {
      "Date": "12/20/06",
      "Rate": "9833.841867"
  },
  {
      "Date": "12/19/06",
      "Rate": "9722.541844"
  },
  {
      "Date": "12/18/06",
      "Rate": "9723.346091"
  },
  {
      "Date": "12/15/06",
      "Rate": "9826.275596"
  },
  {
      "Date": "12/14/06",
      "Rate": "9906.32639"
  },
  {
      "Date": "12/13/06",
      "Rate": "9936.602056"
  },
  {
      "Date": "12/12/06",
      "Rate": "9955.889875"
  },
  {
      "Date": "12/11/06",
      "Rate": "9893.444329"
  },
  {
      "Date": "12/8/06",
      "Rate": "9954.37662"
  },
  {
      "Date": "12/7/06",
      "Rate": "9929.159244"
  },
  {
      "Date": "12/6/06",
      "Rate": "10040.5016"
  },
  {
      "Date": "12/5/06",
      "Rate": "10099.08817"
  },
  {
      "Date": "12/4/06",
      "Rate": "10147.82977"
  },
  {
      "Date": "12/1/06",
      "Rate": "10151.46652"
  },
  {
      "Date": "11/30/06",
      "Rate": "10074.3999"
  },
  {
      "Date": "11/29/06",
      "Rate": "10061.33794"
  },
  {
      "Date": "11/28/06",
      "Rate": "10054.58297"
  },
  {
      "Date": "11/27/06",
      "Rate": "10041.64447"
  },
  {
      "Date": "11/24/06",
      "Rate": "10028.67423"
  },
  {
      "Date": "11/23/06",
      "Rate": "9943.434628"
  },
  {
      "Date": "11/22/06",
      "Rate": "9920.464206"
  },
  {
      "Date": "11/21/06",
      "Rate": "9891.049225"
  },
  {
      "Date": "11/20/06",
      "Rate": "9900.534401"
  },
  {
      "Date": "11/17/06",
      "Rate": "9794.038696"
  },
  {
      "Date": "11/16/06",
      "Rate": "9900.756627"
  },
  {
      "Date": "11/15/06",
      "Rate": "9920.894548"
  },
  {
      "Date": "11/14/06",
      "Rate": "9933.893014"
  },
  {
      "Date": "11/13/06",
      "Rate": "9971.932486"
  },
  {
      "Date": "11/10/06",
      "Rate": "9971.216424"
  },
  {
      "Date": "11/9/06",
      "Rate": "9712.428791"
  },
  {
      "Date": "11/8/06",
      "Rate": "9860.346743"
  },
  {
      "Date": "11/7/06",
      "Rate": "9872.565654"
  },
  {
      "Date": "11/6/06",
      "Rate": "9911.47992"
  },
  {
      "Date": "11/3/06",
      "Rate": "9892.506041"
  },
  {
      "Date": "11/2/06",
      "Rate": "9751.177269"
  },
  {
      "Date": "11/1/06",
      "Rate": "9674.262333"
  },
  {
      "Date": "10/31/06",
      "Rate": "9544.090725"
  },
  {
      "Date": "10/30/06",
      "Rate": "9593.78825"
  },
  {
      "Date": "10/27/06",
      "Rate": "9465.609623"
  },
  {
      "Date": "10/26/06",
      "Rate": "9426.695356"
  },
  {
      "Date": "10/25/06",
      "Rate": "9306.277007"
  },
  {
      "Date": "10/24/06",
      "Rate": "9256.604173"
  },
  {
      "Date": "10/23/06",
      "Rate": "9418.243708"
  },
  {
      "Date": "10/20/06",
      "Rate": "9560.785905"
  },
  {
      "Date": "10/19/06",
      "Rate": "9426.804706"
  },
  {
      "Date": "10/18/06",
      "Rate": "9527.589552"
  },
  {
      "Date": "10/17/06",
      "Rate": "9501.779573"
  },
  {
      "Date": "10/16/06",
      "Rate": "9504.379266"
  },
  {
      "Date": "10/13/06",
      "Rate": "9262.625443"
  },
  {
      "Date": "10/12/06",
      "Rate": "9266.364486"
  },
  {
      "Date": "10/11/06",
      "Rate": "9242.879063"
  },
  {
      "Date": "10/10/06",
      "Rate": "9327.282668"
  },
  {
      "Date": "10/9/06",
      "Rate": "9327.631881"
  },
  {
      "Date": "10/6/06",
      "Rate": "9173.79848"
  },
  {
      "Date": "10/5/06",
      "Rate": "9150.549392"
  },
  {
      "Date": "10/4/06",
      "Rate": "9331.663698"
  },
  {
      "Date": "10/3/06",
      "Rate": "9559.28676"
  },
  {
      "Date": "10/2/06",
      "Rate": "9762.020494"
  },
  {
      "Date": "9/29/06",
      "Rate": "9748.09785"
  },
  {
      "Date": "9/28/06",
      "Rate": "9776.447556"
  },
  {
      "Date": "9/27/06",
      "Rate": "9607.834353"
  },
  {
      "Date": "9/26/06",
      "Rate": "9547.762747"
  },
  {
      "Date": "9/25/06",
      "Rate": "9550.694016"
  },
  {
      "Date": "9/22/06",
      "Rate": "9585.975061"
  },
  {
      "Date": "9/21/06",
      "Rate": "9391.058043"
  },
  {
      "Date": "9/20/06",
      "Rate": "9339.847264"
  },
  {
      "Date": "9/19/06",
      "Rate": "9467.341576"
  },
  {
      "Date": "9/18/06",
      "Rate": "9464.93589"
  },
  {
      "Date": "9/15/06",
      "Rate": "9412.631616"
  },
  {
      "Date": "9/14/06",
      "Rate": "9656.787598"
  },
  {
      "Date": "9/13/06",
      "Rate": "9474.925484"
  },
  {
      "Date": "9/12/06",
      "Rate": "9726.95462"
  },
  {
      "Date": "9/11/06",
      "Rate": "9752.806928"
  },
  {
      "Date": "9/8/06",
      "Rate": "9971.830191"
  },
  {
      "Date": "9/7/06",
      "Rate": "10336.17171"
  },
  {
      "Date": "9/6/06",
      "Rate": "10365.368"
  },
  {
      "Date": "9/5/06",
      "Rate": "10281.65576"
  },
  {
      "Date": "9/4/06",
      "Rate": "10279.39117"
  },
  {
      "Date": "9/1/06",
      "Rate": "10240.33934"
  },
  {
      "Date": "8/31/06",
      "Rate": "10207.25233"
  },
  {
      "Date": "8/30/06",
      "Rate": "10086.09323"
  },
  {
      "Date": "8/29/06",
      "Rate": "10118.65112"
  },
  {
      "Date": "8/25/06",
      "Rate": "10216.01086"
  },
  {
      "Date": "8/24/06",
      "Rate": "10234.59673"
  },
  {
      "Date": "8/23/06",
      "Rate": "10233.49971"
  },
  {
      "Date": "8/22/06",
      "Rate": "10276.10716"
  },
  {
      "Date": "8/21/06",
      "Rate": "10186.15849"
  },
  {
      "Date": "8/18/06",
      "Rate": "10063.86356"
  },
  {
      "Date": "8/17/06",
      "Rate": "10333.57908"
  },
  {
      "Date": "8/16/06",
      "Rate": "10285.85689"
  },
  {
      "Date": "8/15/06",
      "Rate": "10229.77478"
  },
  {
      "Date": "8/14/06",
      "Rate": "10327.0287"
  },
  {
      "Date": "8/11/06",
      "Rate": "10527.49078"
  },
  {
      "Date": "8/10/06",
      "Rate": "10649.68694"
  },
  {
      "Date": "8/9/06",
      "Rate": "10497.83241"
  },
  {
      "Date": "8/8/06",
      "Rate": "10596.3562"
  },
  {
      "Date": "8/7/06",
      "Rate": "10638.14529"
  },
  {
      "Date": "8/4/06",
      "Rate": "10583.28718"
  },
  {
      "Date": "8/3/06",
      "Rate": "10614.5999"
  },
  {
      "Date": "8/2/06",
      "Rate": "10679.16542"
  },
  {
      "Date": "8/1/06",
      "Rate": "10444.46287"
  },
  {
      "Date": "7/31/06",
      "Rate": "10462.375"
  },
  {
      "Date": "7/28/06",
      "Rate": "10395.21332"
  },
  {
      "Date": "7/27/06",
      "Rate": "10427.01282"
  },
  {
      "Date": "7/26/06",
      "Rate": "10197.95764"
  },
  {
      "Date": "7/25/06",
      "Rate": "10219.66172"
  },
  {
      "Date": "7/24/06",
      "Rate": "10198.50086"
  },
  {
      "Date": "7/21/06",
      "Rate": "10339.50158"
  },
  {
      "Date": "7/20/06",
      "Rate": "10612.00727"
  },
  {
      "Date": "7/19/06",
      "Rate": "10377.20947"
  },
  {
      "Date": "7/18/06",
      "Rate": "10668.52325"
  },
  {
      "Date": "7/17/06",
      "Rate": "11074.61507"
  },
  {
      "Date": "7/14/06",
      "Rate": "10801.58733"
  },
  {
      "Date": "7/13/06",
      "Rate": "10603.27343"
  },
  {
      "Date": "7/12/06",
      "Rate": "10471.1935"
  },
  {
      "Date": "7/11/06",
      "Rate": "10234.41683"
  },
  {
      "Date": "7/10/06",
      "Rate": "10165.09991"
  },
  {
      "Date": "7/7/06",
      "Rate": "10215.25953"
  },
  {
      "Date": "7/6/06",
      "Rate": "10155.09268"
  },
  {
      "Date": "7/5/06",
      "Rate": "10169.99594"
  },
  {
      "Date": "7/4/06",
      "Rate": "10077.48285"
  },
  {
      "Date": "7/3/06",
      "Rate": "10067.32747"
  },
  {
      "Date": "6/30/06",
      "Rate": "9750.584666"
  },
  {
      "Date": "6/29/06",
      "Rate": "9501.059983"
  },
  {
      "Date": "6/28/06",
      "Rate": "9556.344909"
  },
  {
      "Date": "6/27/06",
      "Rate": "9610.670382"
  },
  {
      "Date": "6/26/06",
      "Rate": "9593.163901"
  },
  {
      "Date": "6/23/06",
      "Rate": "9477.948465"
  },
  {
      "Date": "6/22/06",
      "Rate": "9674.63271"
  },
  {
      "Date": "6/21/06",
      "Rate": "9347.191308"
  },
  {
      "Date": "6/20/06",
      "Rate": "9255.500097"
  },
  {
      "Date": "6/19/06",
      "Rate": "9229.711282"
  },
  {
      "Date": "6/16/06",
      "Rate": "9402.620858"
  },
  {
      "Date": "6/15/06",
      "Rate": "9314.763929"
  },
  {
      "Date": "6/14/06",
      "Rate": "9200.716062"
  },
  {
      "Date": "6/13/06",
      "Rate": "9593.343798"
  },
  {
      "Date": "6/12/06",
      "Rate": "9843.581016"
  },
  {
      "Date": "6/9/06",
      "Rate": "9925.751777"
  },
  {
      "Date": "6/8/06",
      "Rate": "10040.09595"
  },
  {
      "Date": "6/7/06",
      "Rate": "10109.23649"
  },
  {
      "Date": "6/6/06",
      "Rate": "10281.37357"
  },
  {
      "Date": "6/5/06",
      "Rate": "10405.39339"
  },
  {
      "Date": "6/2/06",
      "Rate": "10213.68984"
  },
  {
      "Date": "6/1/06",
      "Rate": "10353.87573"
  },
  {
      "Date": "5/31/06",
      "Rate": "10697.18337"
  },
  {
      "Date": "5/30/06",
      "Rate": "10651.30249"
  },
  {
      "Date": "5/26/06",
      "Rate": "10553.22669"
  },
  {
      "Date": "5/25/06",
      "Rate": "10421.66881"
  },
  {
      "Date": "5/24/06",
      "Rate": "10772.01714"
  },
  {
      "Date": "5/23/06",
      "Rate": "10627.06573"
  },
  {
      "Date": "5/22/06",
      "Rate": "10373.72088"
  },
  {
      "Date": "5/19/06",
      "Rate": "10961.50902"
  },
  {
      "Date": "5/18/06",
      "Rate": "11031.24923"
  },
  {
      "Date": "5/17/06",
      "Rate": "11361.67481"
  },
  {
      "Date": "5/16/06",
      "Rate": "10933.4274"
  },
  {
      "Date": "5/15/06",
      "Rate": "11109.58571"
  },
  {
      "Date": "5/12/06",
      "Rate": "11494.44611"
  },
  {
      "Date": "5/11/06",
      "Rate": "11243.61982"
  },
  {
      "Date": "5/10/06",
      "Rate": "11164.65546"
  },
  {
      "Date": "5/9/06",
      "Rate": "10770.69084"
  },
  {
      "Date": "5/8/06",
      "Rate": "10784.69109"
  },
  {
      "Date": "5/5/06",
      "Rate": "10787.09677"
  },
  {
      "Date": "5/4/06",
      "Rate": "10515.0179"
  },
  {
      "Date": "5/3/06",
      "Rate": "10675.571"
  },
  {
      "Date": "5/2/06",
      "Rate": "10401.21695"
  },
  {
      "Date": "4/28/06",
      "Rate": "10104.11471"
  },
  {
      "Date": "4/27/06",
      "Rate": "10108.96488"
  },
  {
      "Date": "4/26/06",
      "Rate": "10025.43255"
  },
  {
      "Date": "4/25/06",
      "Rate": "9963.353851"
  },
  {
      "Date": "4/24/06",
      "Rate": "10045.77859"
  },
  {
      "Date": "4/21/06",
      "Rate": "9928.891162"
  },
  {
      "Date": "4/20/06",
      "Rate": "10256.78054"
  },
  {
      "Date": "4/19/06",
      "Rate": "9923.518933"
  },
  {
      "Date": "4/18/06",
      "Rate": "9830.102824"
  },
  {
      "Date": "4/13/06",
      "Rate": "9509.000159"
  },
  {
      "Date": "4/12/06",
      "Rate": "9512.382934"
  },
  {
      "Date": "4/11/06",
      "Rate": "9481.147816"
  },
  {
      "Date": "4/10/06",
      "Rate": "9449.517628"
  },
  {
      "Date": "4/7/06",
      "Rate": "9410.550451"
  },
  {
      "Date": "4/6/06",
      "Rate": "9385.85513"
  },
  {
      "Date": "4/5/06",
      "Rate": "9208.927847"
  },
  {
      "Date": "4/4/06",
      "Rate": "9226.818815"
  },
  {
      "Date": "4/3/06",
      "Rate": "9211.471102"
  },
  {
      "Date": "3/31/06",
      "Rate": "9192.239722"
  },
  {
      "Date": "3/30/06",
      "Rate": "9121.483624"
  },
  {
      "Date": "3/29/06",
      "Rate": "8866.015274"
  },
  {
      "Date": "3/28/06",
      "Rate": "8878.97141"
  },
  {
      "Date": "3/27/06",
      "Rate": "8886.647031"
  },
  {
      "Date": "3/24/06",
      "Rate": "8659.443729"
  },
  {
      "Date": "3/23/06",
      "Rate": "8610.613944"
  },
  {
      "Date": "3/22/06",
      "Rate": "8610.49754"
  },
  {
      "Date": "3/21/06",
      "Rate": "8667.722535"
  },
  {
      "Date": "3/20/06",
      "Rate": "8680.470555"
  },
  {
      "Date": "3/17/06",
      "Rate": "8710.333516"
  },
  {
      "Date": "3/16/06",
      "Rate": "8670.664386"
  },
  {
      "Date": "3/15/06",
      "Rate": "8636.889539"
  },
  {
      "Date": "3/14/06",
      "Rate": "8555.865183"
  },
  {
      "Date": "3/13/06",
      "Rate": "8561.403199"
  },
  {
      "Date": "3/10/06",
      "Rate": "8560.14039"
  },
  {
      "Date": "3/9/06",
      "Rate": "8587.248452"
  },
  {
      "Date": "3/8/06",
      "Rate": "8608.197675"
  },
  {
      "Date": "3/7/06",
      "Rate": "8687.574737"
  },
  {
      "Date": "3/6/06",
      "Rate": "8880.290658"
  },
  {
      "Date": "3/3/06",
      "Rate": "8892.939911"
  },
  {
      "Date": "3/2/06",
      "Rate": "8824.543643"
  },
  {
      "Date": "3/1/06",
      "Rate": "8805.291099"
  },
  {
      "Date": "2/28/06",
      "Rate": "8713.748038"
  },
  {
      "Date": "2/27/06",
      "Rate": "8728.07986"
  },
  {
      "Date": "2/24/06",
      "Rate": "8633.425634"
  },
  {
      "Date": "2/23/06",
      "Rate": "8661.831778"
  },
  {
      "Date": "2/22/06",
      "Rate": "8689.486587"
  },
  {
      "Date": "2/21/06",
      "Rate": "8669.108803"
  },
  {
      "Date": "2/20/06",
      "Rate": "8701.504436"
  },
  {
      "Date": "2/17/06",
      "Rate": "8560.341452"
  },
  {
      "Date": "2/16/06",
      "Rate": "8457.708249"
  },
  {
      "Date": "2/15/06",
      "Rate": "8490.07919"
  },
  {
      "Date": "2/14/06",
      "Rate": "8431.757174"
  },
  {
      "Date": "2/13/06",
      "Rate": "8534.146987"
  },
  {
      "Date": "2/10/06",
      "Rate": "8711.529304"
  },
  {
      "Date": "2/9/06",
      "Rate": "8713.455264"
  },
  {
      "Date": "2/8/06",
      "Rate": "8565.671352"
  },
  {
      "Date": "2/7/06",
      "Rate": "8861.648354"
  },
  {
      "Date": "2/6/06",
      "Rate": "8895.977001"
  },
  {
      "Date": "2/3/06",
      "Rate": "8915.268347"
  },
  {
      "Date": "2/2/06",
      "Rate": "8928.379689"
  },
  {
      "Date": "2/1/06",
      "Rate": "8845.274167"
  },
  {
      "Date": "1/31/06",
      "Rate": "8867.228699"
  },
  {
      "Date": "1/30/06",
      "Rate": "8720.139685"
  },
  {
      "Date": "1/27/06",
      "Rate": "8716.259546"
  },
  {
      "Date": "1/26/06",
      "Rate": "8761.138644"
  },
  {
      "Date": "1/25/06",
      "Rate": "8761.138644"
  },
  {
      "Date": "1/24/06",
      "Rate": "8691.550116"
  },
  {
      "Date": "1/23/06",
      "Rate": "8704.185259"
  },
  {
      "Date": "1/20/06",
      "Rate": "8729.187464"
  },
  {
      "Date": "1/19/06",
      "Rate": "8567.745463"
  },
  {
      "Date": "1/18/06",
      "Rate": "8544.655109"
  },
  {
      "Date": "1/17/06",
      "Rate": "8732.859486"
  },
  {
      "Date": "1/16/06",
      "Rate": "8733.011164"
  },
  {
      "Date": "1/13/06",
      "Rate": "8539.953086"
  },
  {
      "Date": "1/12/06",
      "Rate": "8526.474894"
  },
  {
      "Date": "1/11/06",
      "Rate": "8478.664527"
  },
  {
      "Date": "1/10/06",
      "Rate": "8525.57188"
  },
  {
      "Date": "1/9/06",
      "Rate": "8454.826364"
  },
  {
      "Date": "1/6/06",
      "Rate": "8327.399072"
  },
  {
      "Date": "1/5/06",
      "Rate": "8386.677014"
  },
  {
      "Date": "1/4/06",
      "Rate": "8436.660259"
  },
  {
      "Date": "1/3/06",
      "Rate": "8274.981922"
  },
  {
      "Date": "12/30/05",
      "Rate": "8152.048537"
  },
  {
      "Date": "12/29/05",
      "Rate": "8236.127621"
  },
  {
      "Date": "12/28/05",
      "Rate": "8106.135911"
  },
  {
      "Date": "12/23/05",
      "Rate": "8023.545389"
  },
  {
      "Date": "12/22/05",
      "Rate": "7888.654121"
  },
  {
      "Date": "12/21/05",
      "Rate": "7888.590628"
  },
  {
      "Date": "12/20/05",
      "Rate": "8070.734934"
  },
  {
      "Date": "12/19/05",
      "Rate": "8062.064587"
  },
  {
      "Date": "12/16/05",
      "Rate": "8033.051729"
  },
  {
      "Date": "12/15/05",
      "Rate": "8052.720507"
  },
  {
      "Date": "12/14/05",
      "Rate": "8287.648812"
  },
  {
      "Date": "12/13/05",
      "Rate": "8500.492072"
  },
  {
      "Date": "12/12/05",
      "Rate": "8738.559763"
  },
  {
      "Date": "12/9/05",
      "Rate": "8554.408367"
  },
  {
      "Date": "12/8/05",
      "Rate": "8397.55904"
  },
  {
      "Date": "12/7/05",
      "Rate": "8350.545865"
  },
  {
      "Date": "12/6/05",
      "Rate": "8280.050795"
  },
  {
      "Date": "12/5/05",
      "Rate": "8249.369477"
  },
  {
      "Date": "12/2/05",
      "Rate": "8220.360147"
  },
  {
      "Date": "12/1/05",
      "Rate": "8015.174871"
  },
  {
      "Date": "11/30/05",
      "Rate": "8003.707296"
  },
  {
      "Date": "11/29/05",
      "Rate": "8048.427662"
  },
  {
      "Date": "11/28/05",
      "Rate": "8046.402935"
  },
  {
      "Date": "11/25/05",
      "Rate": "8013.520521"
  },
  {
      "Date": "11/24/05",
      "Rate": "7943.896718"
  },
  {
      "Date": "11/23/05",
      "Rate": "7892.435493"
  },
  {
      "Date": "11/22/05",
      "Rate": "7959.438438"
  },
  {
      "Date": "11/21/05",
      "Rate": "7876.131854"
  },
  {
      "Date": "11/18/05",
      "Rate": "7873.659147"
  },
  {
      "Date": "11/17/05",
      "Rate": "7774.380501"
  },
  {
      "Date": "11/16/05",
      "Rate": "7611.88028"
  },
  {
      "Date": "11/15/05",
      "Rate": "7545.251239"
  },
  {
      "Date": "11/14/05",
      "Rate": "7589.040371"
  },
  {
      "Date": "11/11/05",
      "Rate": "7507.941939"
  },
  {
      "Date": "11/10/05",
      "Rate": "7544.61278"
  },
  {
      "Date": "11/9/05",
      "Rate": "7461.292086"
  },
  {
      "Date": "11/8/05",
      "Rate": "7395.156881"
  },
  {
      "Date": "11/7/05",
      "Rate": "7398.402088"
  },
  {
      "Date": "11/4/05",
      "Rate": "7405.326373"
  },
  {
      "Date": "11/3/05",
      "Rate": "7420.536517"
  },
  {
      "Date": "11/2/05",
      "Rate": "7361.290323"
  },
  {
      "Date": "11/1/05",
      "Rate": "7407.054798"
  },
  {
      "Date": "10/31/05",
      "Rate": "7514.185435"
  },
  {
      "Date": "10/28/05",
      "Rate": "7532.171643"
  },
  {
      "Date": "10/27/05",
      "Rate": "7526.644209"
  },
  {
      "Date": "10/26/05",
      "Rate": "7496.696591"
  },
  {
      "Date": "10/25/05",
      "Rate": "7425.185629"
  },
  {
      "Date": "10/24/05",
      "Rate": "7387.216706"
  },
  {
      "Date": "10/21/05",
      "Rate": "7363.971146"
  },
  {
      "Date": "10/20/05",
      "Rate": "7402.035309"
  },
  {
      "Date": "10/19/05",
      "Rate": "7462.526676"
  },
  {
      "Date": "10/18/05",
      "Rate": "7536.23168"
  },
  {
      "Date": "10/17/05",
      "Rate": "7477.341752"
  },
  {
      "Date": "10/14/05",
      "Rate": "7446.039613"
  },
  {
      "Date": "10/13/05",
      "Rate": "7471.779044"
  },
  {
      "Date": "10/12/05",
      "Rate": "7574.274679"
  },
  {
      "Date": "10/11/05",
      "Rate": "7526.785305"
  },
  {
      "Date": "10/10/05",
      "Rate": "7538.231715"
  },
  {
      "Date": "10/7/05",
      "Rate": "7391.467222"
  },
  {
      "Date": "10/6/05",
      "Rate": "7283.091413"
  },
  {
      "Date": "10/5/05",
      "Rate": "7268.121131"
  },
  {
      "Date": "10/4/05",
      "Rate": "7292.139191"
  },
  {
      "Date": "10/3/05",
      "Rate": "7237.972451"
  },
  {
      "Date": "9/30/05",
      "Rate": "7349.935625"
  },
  {
      "Date": "9/29/05",
      "Rate": "7336.178769"
  },
  {
      "Date": "9/28/05",
      "Rate": "7176.412282"
  },
  {
      "Date": "9/27/05",
      "Rate": "7235.358648"
  },
  {
      "Date": "9/26/05",
      "Rate": "7150.059084"
  },
  {
      "Date": "9/23/05",
      "Rate": "7194.356161"
  },
  {
      "Date": "9/22/05",
      "Rate": "7325.670647"
  },
  {
      "Date": "9/21/05",
      "Rate": "7228.134535"
  },
  {
      "Date": "9/20/05",
      "Rate": "7273.937812"
  },
  {
      "Date": "9/19/05",
      "Rate": "7155.053881"
  },
  {
      "Date": "9/16/05",
      "Rate": "7089.976896"
  },
  {
      "Date": "9/15/05",
      "Rate": "6953.060901"
  },
  {
      "Date": "9/14/05",
      "Rate": "6935.476816"
  },
  {
      "Date": "9/13/05",
      "Rate": "6950.034392"
  },
  {
      "Date": "9/12/05",
      "Rate": "6944.521067"
  },
  {
      "Date": "9/9/05",
      "Rate": "6908.213549"
  },
  {
      "Date": "9/8/05",
      "Rate": "6875.694457"
  },
  {
      "Date": "9/7/05",
      "Rate": "6873.800243"
  },
  {
      "Date": "9/6/05",
      "Rate": "6866.939452"
  },
  {
      "Date": "9/5/05",
      "Rate": "6886.879839"
  },
  {
      "Date": "9/2/05",
      "Rate": "6906.358137"
  },
  {
      "Date": "9/1/05",
      "Rate": "6763.04344"
  },
  {
      "Date": "8/31/05",
      "Rate": "6743.31117"
  },
  {
      "Date": "8/30/05",
      "Rate": "6775.258823"
  },
  {
      "Date": "8/26/05",
      "Rate": "6758.972821"
  },
  {
      "Date": "8/25/05",
      "Rate": "6768.61673"
  },
  {
      "Date": "8/24/05",
      "Rate": "6761.88998"
  },
  {
      "Date": "8/23/05",
      "Rate": "6773.389301"
  },
  {
      "Date": "8/22/05",
      "Rate": "6732.573767"
  },
  {
      "Date": "8/19/05",
      "Rate": "6752.715215"
  },
  {
      "Date": "8/18/05",
      "Rate": "6746.937336"
  },
  {
      "Date": "8/17/05",
      "Rate": "6822.529498"
  },
  {
      "Date": "8/16/05",
      "Rate": "6769.741971"
  },
  {
      "Date": "8/15/05",
      "Rate": "6797.393252"
  },
  {
      "Date": "8/12/05",
      "Rate": "6868.808974"
  },
  {
      "Date": "8/11/05",
      "Rate": "6732.355068"
  },
  {
      "Date": "8/10/05",
      "Rate": "6703.716115"
  },
  {
      "Date": "8/9/05",
      "Rate": "6677.401718"
  },
  {
      "Date": "8/8/05",
      "Rate": "6710.813242"
  },
  {
      "Date": "8/5/05",
      "Rate": "6710.580434"
  },
  {
      "Date": "8/4/05",
      "Rate": "6687.384257"
  },
  {
      "Date": "8/3/05",
      "Rate": "6644.642763"
  },
  {
      "Date": "8/2/05",
      "Rate": "6632.480291"
  },
  {
      "Date": "8/1/05",
      "Rate": "6607.301716"
  },
  {
      "Date": "7/29/05",
      "Rate": "6551.254872"
  },
  {
      "Date": "7/28/05",
      "Rate": "6520.629993"
  },
  {
      "Date": "7/27/05",
      "Rate": "6470.724351"
  },
  {
      "Date": "7/26/05",
      "Rate": "6509.490467"
  },
  {
      "Date": "7/25/05",
      "Rate": "6526.665373"
  },
  {
      "Date": "7/22/05",
      "Rate": "6539.737914"
  },
  {
      "Date": "7/21/05",
      "Rate": "6451.99739"
  },
  {
      "Date": "7/20/05",
      "Rate": "6457.690612"
  },
  {
      "Date": "7/19/05",
      "Rate": "6451.224889"
  },
  {
      "Date": "7/18/05",
      "Rate": "6475.800279"
  },
  {
      "Date": "7/15/05",
      "Rate": "6441.379919"
  },
  {
      "Date": "7/14/05",
      "Rate": "6488.135593"
  },
  {
      "Date": "7/13/05",
      "Rate": "6531.219245"
  },
  {
      "Date": "7/12/05",
      "Rate": "6556.665902"
  },
  {
      "Date": "7/11/05",
      "Rate": "6530.647807"
  },
  {
      "Date": "7/8/05",
      "Rate": "6510.319406"
  },
  {
      "Date": "7/7/05",
      "Rate": "6578.592215"
  },
  {
      "Date": "7/6/05",
      "Rate": "6513.352969"
  },
  {
      "Date": "7/5/05",
      "Rate": "6550.147269"
  },
  {
      "Date": "7/4/05",
      "Rate": "6567.960634"
  },
  {
      "Date": "7/1/05",
      "Rate": "6665.507328"
  },
  {
      "Date": "6/30/05",
      "Rate": "6706.192349"
  },
  {
      "Date": "6/29/05",
      "Rate": "6678.555177"
  },
  {
      "Date": "6/28/05",
      "Rate": "6710.767386"
  },
  {
      "Date": "6/27/05",
      "Rate": "6739.141784"
  },
  {
      "Date": "6/24/05",
      "Rate": "6800.613767"
  },
  {
      "Date": "6/23/05",
      "Rate": "6716.192525"
  },
  {
      "Date": "6/22/05",
      "Rate": "6712.308859"
  },
  {
      "Date": "6/21/05",
      "Rate": "6668.576165"
  },
  {
      "Date": "6/20/05",
      "Rate": "6769.212861"
  },
  {
      "Date": "6/17/05",
      "Rate": "6694.678918"
  },
  {
      "Date": "6/16/05",
      "Rate": "6630.878851"
  },
  {
      "Date": "6/15/05",
      "Rate": "6569.925396"
  },
  {
      "Date": "6/14/05",
      "Rate": "6567.58673"
  },
  {
      "Date": "6/13/05",
      "Rate": "6554.507134"
  },
  {
      "Date": "6/10/05",
      "Rate": "6488.992751"
  },
  {
      "Date": "6/9/05",
      "Rate": "6500.523819"
  },
  {
      "Date": "6/8/05",
      "Rate": "6517.497663"
  },
  {
      "Date": "6/7/05",
      "Rate": "6553.353675"
  },
  {
      "Date": "6/6/05",
      "Rate": "6529.448491"
  },
  {
      "Date": "6/3/05",
      "Rate": "6510.827351"
  },
  {
      "Date": "6/2/05",
      "Rate": "6429.556077"
  },
  {
      "Date": "6/1/05",
      "Rate": "6425.079807"
  },
  {
      "Date": "5/31/05",
      "Rate": "6419.785181"
  },
  {
      "Date": "5/27/05",
      "Rate": "6428.437891"
  },
  {
      "Date": "5/26/05",
      "Rate": "6429.81005"
  },
  {
      "Date": "5/25/05",
      "Rate": "6426.709466"
  },
  {
      "Date": "5/24/05",
      "Rate": "6418.635249"
  },
  {
      "Date": "5/23/05",
      "Rate": "6401.294555"
  },
  {
      "Date": "5/20/05",
      "Rate": "6448.815676"
  },
  {
      "Date": "5/19/05",
      "Rate": "6468.255172"
  },
  {
      "Date": "5/18/05",
      "Rate": "6431.654879"
  },
  {
      "Date": "5/17/05",
      "Rate": "6448.639306"
  },
  {
      "Date": "5/16/05",
      "Rate": "6442.65331"
  },
  {
      "Date": "5/13/05",
      "Rate": "6456.399584"
  },
  {
      "Date": "5/12/05",
      "Rate": "6520.912185"
  },
  {
      "Date": "5/11/05",
      "Rate": "6540.95134"
  },
  {
      "Date": "5/10/05",
      "Rate": "6540.644456"
  },
  {
      "Date": "5/9/05",
      "Rate": "6530.54904"
  },
  {
      "Date": "5/6/05",
      "Rate": "6583.544683"
  },
  {
      "Date": "5/5/05",
      "Rate": "6592.765305"
  },
  {
      "Date": "5/4/05",
      "Rate": "6589.32609"
  },
  {
      "Date": "5/3/05",
      "Rate": "6599.350959"
  },
  {
      "Date": "4/29/05",
      "Rate": "6652.448897"
  },
  {
      "Date": "4/28/05",
      "Rate": "6671.489797"
  },
  {
      "Date": "4/27/05",
      "Rate": "6694.79885"
  },
  {
      "Date": "4/26/05",
      "Rate": "6696.185118"
  },
  {
      "Date": "4/25/05",
      "Rate": "6675.345244"
  },
  {
      "Date": "4/22/05",
      "Rate": "6686.096757"
  },
  {
      "Date": "4/21/05",
      "Rate": "6689.155012"
  },
  {
      "Date": "4/20/05",
      "Rate": "6673.719113"
  },
  {
      "Date": "4/19/05",
      "Rate": "6590.338454"
  },
  {
      "Date": "4/18/05",
      "Rate": "6580.800367"
  },
  {
      "Date": "4/15/05",
      "Rate": "6549.879187"
  },
  {
      "Date": "4/14/05",
      "Rate": "6592.708866"
  },
  {
      "Date": "4/13/05",
      "Rate": "6618.17316"
  },
  {
      "Date": "4/12/05",
      "Rate": "6620.152031"
  },
  {
      "Date": "4/11/05",
      "Rate": "6601.509727"
  },
  {
      "Date": "4/8/05",
      "Rate": "6567.375086"
  },
  {
      "Date": "4/7/05",
      "Rate": "6576.980194"
  },
  {
      "Date": "4/6/05",
      "Rate": "6584.020882"
  },
  {
      "Date": "4/5/05",
      "Rate": "6543.55456"
  },
  {
      "Date": "4/4/05",
      "Rate": "6578.302968"
  },
  {
      "Date": "4/1/05",
      "Rate": "6596.567841"
  },
  {
      "Date": "3/31/05",
      "Rate": "6596.585478"
  },
  {
      "Date": "3/30/05",
      "Rate": "6592.437256"
  },
  {
      "Date": "3/29/05",
      "Rate": "6599.393287"
  },
  {
      "Date": "3/24/05",
      "Rate": "6558.157992"
  },
  {
      "Date": "3/23/05",
      "Rate": "6598.003492"
  },
  {
      "Date": "3/22/05",
      "Rate": "6643.291769"
  },
  {
      "Date": "3/21/05",
      "Rate": "6728.908799"
  },
  {
      "Date": "3/18/05",
      "Rate": "6723.914002"
  },
  {
      "Date": "3/17/05",
      "Rate": "6817.086721"
  },
  {
      "Date": "3/16/05",
      "Rate": "6790.476023"
  },
  {
      "Date": "3/15/05",
      "Rate": "6795.774176"
  },
  {
      "Date": "3/14/05",
      "Rate": "6802.61733"
  },
  {
      "Date": "3/11/05",
      "Rate": "6776.13009"
  },
  {
      "Date": "3/10/05",
      "Rate": "6766.955325"
  },
  {
      "Date": "3/9/05",
      "Rate": "6772.408684"
  },
  {
      "Date": "3/8/05",
      "Rate": "6702.911868"
  },
  {
      "Date": "3/7/05",
      "Rate": "6687.65234"
  },
  {
      "Date": "3/4/05",
      "Rate": "6627.485494"
  },
  {
      "Date": "3/3/05",
      "Rate": "6680.724528"
  },
  {
      "Date": "3/2/05",
      "Rate": "6616.748091"
  },
  {
      "Date": "3/1/05",
      "Rate": "6703.081183"
  },
  {
      "Date": "2/28/05",
      "Rate": "6725.451948"
  },
  {
      "Date": "2/25/05",
      "Rate": "6665.330958"
  },
  {
      "Date": "2/24/05",
      "Rate": "6701.557347"
  },
  {
      "Date": "2/23/05",
      "Rate": "6680.724528"
  },
  {
      "Date": "2/22/05",
      "Rate": "6648.452354"
  },
  {
      "Date": "2/21/05",
      "Rate": "6603.037091"
  },
  {
      "Date": "2/18/05",
      "Rate": "6597.54493"
  },
  {
      "Date": "2/17/05",
      "Rate": "6592.479585"
  },
  {
      "Date": "2/16/05",
      "Rate": "6547.882679"
  },
  {
      "Date": "2/15/05",
      "Rate": "6563.068132"
  },
  {
      "Date": "2/14/05",
      "Rate": "6525.550715"
  },
  {
      "Date": "2/11/05",
      "Rate": "6447.104887"
  },
  {
      "Date": "2/10/05",
      "Rate": "6385.661123"
  },
  {
      "Date": "2/9/05",
      "Rate": "6370.824882"
  },
  {
      "Date": "2/8/05",
      "Rate": "6343.163019"
  },
  {
      "Date": "2/7/05",
      "Rate": "6358.704739"
  },
  {
      "Date": "2/4/05",
      "Rate": "6387.181432"
  },
  {
      "Date": "2/3/05",
      "Rate": "6429.644262"
  },
  {
      "Date": "2/2/05",
      "Rate": "6461.807087"
  },
  {
      "Date": "2/1/05",
      "Rate": "6492.657719"
  },
  {
      "Date": "1/31/05",
      "Rate": "6535.755481"
  },
  {
      "Date": "1/28/05",
      "Rate": "6576.602762"
  },
  {
      "Date": "1/27/05",
      "Rate": "6584.084375"
  },
  {
      "Date": "1/26/05",
      "Rate": "6545.420554"
  },
  {
      "Date": "1/25/05",
      "Rate": "6584.042047"
  },
  {
      "Date": "1/24/05",
      "Rate": "6601.142877"
  },
  {
      "Date": "1/21/05",
      "Rate": "6541.480449"
  },
  {
      "Date": "1/20/05",
      "Rate": "6516.756909"
  },
  {
      "Date": "1/19/05",
      "Rate": "6541.127709"
  },
  {
      "Date": "1/18/05",
      "Rate": "6517.222526"
  },
  {
      "Date": "1/17/05",
      "Rate": "6511.409372"
  },
  {
      "Date": "1/14/05",
      "Rate": "6507.458685"
  },
  {
      "Date": "1/13/05",
      "Rate": "6551.110249"
  },
  {
      "Date": "1/12/05",
      "Rate": "6516.756909"
  },
  {
      "Date": "1/11/05",
      "Rate": "6528.774758"
  },
  {
      "Date": "1/10/05",
      "Rate": "6527.631881"
  },
  {
      "Date": "1/7/05",
      "Rate": "6540.655038"
  },
  {
      "Date": "1/6/05",
      "Rate": "6595.135717"
  },
  {
      "Date": "1/5/05",
      "Rate": "6578.482866"
  },
  {
      "Date": "1/4/05",
      "Rate": "6551.910968"
  },
  {
      "Date": "12/31/04",
      "Rate": "6714.573449"
  },
  {
      "Date": "12/30/04",
      "Rate": "6711.557523"
  },
  {
      "Date": "12/29/04",
      "Rate": "6836.434505"
  },
  {
      "Date": "12/24/04",
      "Rate": "6829.654844"
  },
  {
      "Date": "12/23/04",
      "Rate": "6831.182208"
  },
  {
      "Date": "12/22/04",
      "Rate": "6846.745093"
  },
  {
      "Date": "12/21/04",
      "Rate": "6829.704228"
  },
  {
      "Date": "12/20/04",
      "Rate": "6845.298859"
  },
  {
      "Date": "12/17/04",
      "Rate": "6808.105963"
  },
  {
      "Date": "12/16/04",
      "Rate": "6821.986278"
  },
  {
      "Date": "12/15/04",
      "Rate": "6801.075857"
  },
  {
      "Date": "12/14/04",
      "Rate": "6825.108027"
  },
  {
      "Date": "12/13/04",
      "Rate": "6824.864636"
  },
  {
      "Date": "12/10/04",
      "Rate": "6846.099578"
  },
  {
      "Date": "12/9/04",
      "Rate": "6820.698778"
  },
  {
      "Date": "12/8/04",
      "Rate": "6930.079896"
  },
  {
      "Date": "12/7/04",
      "Rate": "6998.437362"
  },
  {
      "Date": "12/6/04",
      "Rate": "7014.010829"
  },
  {
      "Date": "12/3/04",
      "Rate": "6992.447839"
  },
  {
      "Date": "12/2/04",
      "Rate": "7068.593802"
  },
  {
      "Date": "12/1/04",
      "Rate": "7073.722641"
  },
  {
      "Date": "11/30/04",
      "Rate": "7116.534683"
  },
  {
      "Date": "11/29/04",
      "Rate": "7118.351294"
  },
  {
      "Date": "11/26/04",
      "Rate": "7173.558617"
  },
  {
      "Date": "11/25/04",
      "Rate": "7174.348754"
  },
  {
      "Date": "11/24/04",
      "Rate": "7134.422124"
  },
  {
      "Date": "11/23/04",
      "Rate": "7106.400466"
  },
  {
      "Date": "11/22/04",
      "Rate": "7101.670223"
  },
  {
      "Date": "11/19/04",
      "Rate": "7057.849345"
  },
  {
      "Date": "11/18/04",
      "Rate": "7072.879592"
  },
  {
      "Date": "11/17/04",
      "Rate": "7065.292157"
  },
  {
      "Date": "11/16/04",
      "Rate": "6962.524912"
  },
  {
      "Date": "11/15/04",
      "Rate": "7000.342158"
  },
  {
      "Date": "11/12/04",
      "Rate": "6958.930493"
  },
  {
      "Date": "11/11/04",
      "Rate": "6902.386285"
  },
  {
      "Date": "11/10/04",
      "Rate": "6934.097603"
  },
  {
      "Date": "11/9/04",
      "Rate": "6886.424805"
  },
  {
      "Date": "11/8/04",
      "Rate": "6898.686044"
  },
  {
      "Date": "11/5/04",
      "Rate": "6832.14166"
  },
  {
      "Date": "11/4/04",
      "Rate": "6798.363287"
  },
  {
      "Date": "11/3/04",
      "Rate": "6757.554807"
  },
  {
      "Date": "11/2/04",
      "Rate": "6824.212067"
  },
  {
      "Date": "11/1/04",
      "Rate": "6858.403146"
  },
  {
      "Date": "10/29/04",
      "Rate": "6823.453676"
  },
  {
      "Date": "10/28/04",
      "Rate": "6812.370589"
  },
  {
      "Date": "10/27/04",
      "Rate": "6855.027426"
  },
  {
      "Date": "10/26/04",
      "Rate": "6881.983809"
  },
  {
      "Date": "10/25/04",
      "Rate": "6908.005432"
  },
  {
      "Date": "10/22/04",
      "Rate": "6807.68973"
  },
  {
      "Date": "10/21/04",
      "Rate": "6864.780684"
  },
  {
      "Date": "10/20/04",
      "Rate": "6808.307025"
  },
  {
      "Date": "10/19/04",
      "Rate": "6725.088626"
  },
  {
      "Date": "10/18/04",
      "Rate": "6768.084093"
  },
  {
      "Date": "10/15/04",
      "Rate": "6758.605972"
  },
  {
      "Date": "10/14/04",
      "Rate": "6707.649165"
  },
  {
      "Date": "10/13/04",
      "Rate": "6668.424487"
  },
  {
      "Date": "10/12/04",
      "Rate": "6766.920052"
  },
  {
      "Date": "10/11/04",
      "Rate": "6819.898058"
  },
  {
      "Date": "10/8/04",
      "Rate": "6756.013334"
  },
  {
      "Date": "10/7/04",
      "Rate": "6759.678301"
  },
  {
      "Date": "10/6/04",
      "Rate": "6760.662446"
  },
  {
      "Date": "10/5/04",
      "Rate": "6701.910087"
  },
  {
      "Date": "10/4/04",
      "Rate": "6742.133018"
  },
  {
      "Date": "10/1/04",
      "Rate": "6770.168786"
  },
  {
      "Date": "9/30/04",
      "Rate": "6683.532337"
  },
  {
      "Date": "9/29/04",
      "Rate": "6679.278294"
  },
  {
      "Date": "9/28/04",
      "Rate": "6673.461613"
  },
  {
      "Date": "9/27/04",
      "Rate": "6631.400907"
  },
  {
      "Date": "9/24/04",
      "Rate": "6630.751865"
  },
  {
      "Date": "9/23/04",
      "Rate": "6610.24004"
  },
  {
      "Date": "9/22/04",
      "Rate": "6591.985749"
  },
  {
      "Date": "9/21/04",
      "Rate": "6556.680012"
  },
  {
      "Date": "9/20/04",
      "Rate": "6540.288189"
  },
  {
      "Date": "9/17/04",
      "Rate": "6547.216"
  },
  {
      "Date": "9/16/04",
      "Rate": "6542.552779"
  },
  {
      "Date": "9/15/04",
      "Rate": "6547.593432"
  },
  {
      "Date": "9/14/04",
      "Rate": "6533.684897"
  },
  {
      "Date": "9/13/04",
      "Rate": "6526.96873"
  },
  {
      "Date": "9/10/04",
      "Rate": "6539.473359"
  },
  {
      "Date": "9/9/04",
      "Rate": "6519.437733"
  },
  {
      "Date": "9/8/04",
      "Rate": "6504.873102"
  },
  {
      "Date": "9/7/04",
      "Rate": "6570.644985"
  },
  {
      "Date": "9/6/04",
      "Rate": "6565.787756"
  },
  {
      "Date": "9/3/04",
      "Rate": "6626.751795"
  },
  {
      "Date": "9/2/04",
      "Rate": "6663.00993"
  },
  {
      "Date": "9/1/04",
      "Rate": "6668.075275"
  },
  {
      "Date": "8/31/04",
      "Rate": "6672.777298"
  },
  {
      "Date": "8/27/04",
      "Rate": "6645.334133"
  },
  {
      "Date": "8/26/04",
      "Rate": "6634.928306"
  },
  {
      "Date": "8/25/04",
      "Rate": "6631.150461"
  },
  {
      "Date": "8/24/04",
      "Rate": "6647.584613"
  },
  {
      "Date": "8/23/04",
      "Rate": "6691.267924"
  },
  {
      "Date": "8/20/04",
      "Rate": "6621.742888"
  },
  {
      "Date": "8/19/04",
      "Rate": "6638.857828"
  },
  {
      "Date": "8/18/04",
      "Rate": "6593.894072"
  },
  {
      "Date": "8/17/04",
      "Rate": "6564.211009"
  },
  {
      "Date": "8/16/04",
      "Rate": "6536.101166"
  },
  {
      "Date": "8/13/04",
      "Rate": "6414.33535"
  },
  {
      "Date": "8/12/04",
      "Rate": "6460.667737"
  },
  {
      "Date": "8/11/04",
      "Rate": "6473.944867"
  },
  {
      "Date": "8/10/04",
      "Rate": "6524.968694"
  },
  {
      "Date": "8/9/04",
      "Rate": "6527.635408"
  },
  {
      "Date": "8/6/04",
      "Rate": "6415.509974"
  },
  {
      "Date": "8/5/04",
      "Rate": "6408.151819"
  },
  {
      "Date": "8/4/04",
      "Rate": "6422.52597"
  },
  {
      "Date": "8/3/04",
      "Rate": "6391.622427"
  },
  {
      "Date": "8/2/04",
      "Rate": "6428.744775"
  },
  {
      "Date": "7/30/04",
      "Rate": "6386.253726"
  },
  {
      "Date": "7/29/04",
      "Rate": "6372.500397"
  },
  {
      "Date": "7/28/04",
      "Rate": "6313.494065"
  },
  {
      "Date": "7/27/04",
      "Rate": "6395.79534"
  },
  {
      "Date": "7/26/04",
      "Rate": "6393.608353"
  },
  {
      "Date": "7/23/04",
      "Rate": "6419.086756"
  },
  {
      "Date": "7/22/04",
      "Rate": "6444.815605"
  },
  {
      "Date": "7/21/04",
      "Rate": "6525.240304"
  },
  {
      "Date": "7/20/04",
      "Rate": "6595.393217"
  },
  {
      "Date": "7/19/04",
      "Rate": "6616.106104"
  },
  {
      "Date": "7/16/04",
      "Rate": "6544.041341"
  },
  {
      "Date": "7/15/04",
      "Rate": "6570.080601"
  },
  {
      "Date": "7/14/04",
      "Rate": "6523.688248"
  },
  {
      "Date": "7/13/04",
      "Rate": "6542.263532"
  },
  {
      "Date": "7/12/04",
      "Rate": "6584.472389"
  },
  {
      "Date": "7/9/04",
      "Rate": "6549.459426"
  },
  {
      "Date": "7/8/04",
      "Rate": "6502.703751"
  },
  {
      "Date": "7/7/04",
      "Rate": "6426.392"
  },
  {
      "Date": "7/6/04",
      "Rate": "6470.777262"
  },
  {
      "Date": "7/5/04",
      "Rate": "6441.263514"
  },
  {
      "Date": "7/2/04",
      "Rate": "6387.710542"
  },
  {
      "Date": "7/1/04",
      "Rate": "6385.167287"
  },
  {
      "Date": "6/30/04",
      "Rate": "6397.336814"
  },
  {
      "Date": "6/29/04",
      "Rate": "6437.679677"
  },
  {
      "Date": "6/28/04",
      "Rate": "6508.788515"
  },
  {
      "Date": "6/25/04",
      "Rate": "6485.37364"
  },
  {
      "Date": "6/24/04",
      "Rate": "6405.49216"
  },
  {
      "Date": "6/23/04",
      "Rate": "6372.909575"
  },
  {
      "Date": "6/22/04",
      "Rate": "6437.591492"
  },
  {
      "Date": "6/21/04",
      "Rate": "6381.594032"
  },
  {
      "Date": "6/18/04",
      "Rate": "6261.814141"
  },
  {
      "Date": "6/17/04",
      "Rate": "6193.809415"
  },
  {
      "Date": "6/16/04",
      "Rate": "6210.949047"
  },
  {
      "Date": "6/15/04",
      "Rate": "6121.628247"
  },
  {
      "Date": "6/14/04",
      "Rate": "6124.481913"
  },
  {
      "Date": "6/11/04",
      "Rate": "6125.102735"
  },
  {
      "Date": "6/10/04",
      "Rate": "6108.463994"
  },
  {
      "Date": "6/9/04",
      "Rate": "6176.726221"
  },
  {
      "Date": "6/8/04",
      "Rate": "6272.639729"
  },
  {
      "Date": "6/7/04",
      "Rate": "6252.635849"
  },
  {
      "Date": "6/4/04",
      "Rate": "6176.168892"
  },
  {
      "Date": "6/3/04",
      "Rate": "6247.323586"
  },
  {
      "Date": "6/2/04",
      "Rate": "6337.385139"
  },
  {
      "Date": "6/1/04",
      "Rate": "6343.487539"
  },
  {
      "Date": "5/28/04",
      "Rate": "6317.688143"
  },
  {
      "Date": "5/27/04",
      "Rate": "6270.904249"
  },
  {
      "Date": "5/26/04",
      "Rate": "6252.93215"
  },
  {
      "Date": "5/25/04",
      "Rate": "6201.710789"
  },
  {
      "Date": "5/24/04",
      "Rate": "6138.662058"
  },
  {
      "Date": "5/21/04",
      "Rate": "6105.994815"
  },
  {
      "Date": "5/20/04",
      "Rate": "6085.296037"
  },
  {
      "Date": "5/19/04",
      "Rate": "6067.884795"
  },
  {
      "Date": "5/18/04",
      "Rate": "6080.149562"
  },
  {
      "Date": "5/17/04",
      "Rate": "6115.070813"
  },
  {
      "Date": "5/14/04",
      "Rate": "6011.679218"
  },
  {
      "Date": "5/13/04",
      "Rate": "5996.186882"
  },
  {
      "Date": "5/12/04",
      "Rate": "6100.523819"
  },
  {
      "Date": "5/11/04",
      "Rate": "6054.773453"
  },
  {
      "Date": "5/10/04",
      "Rate": "5942.15771"
  },
  {
      "Date": "5/7/04",
      "Rate": "6094.18861"
  },
  {
      "Date": "5/6/04",
      "Rate": "6178.08427"
  },
  {
      "Date": "5/5/04",
      "Rate": "6215.030247"
  },
  {
      "Date": "5/4/04",
      "Rate": "6146.059013"
  },
  {
      "Date": "4/30/04",
      "Rate": "6079.41939"
  },
  {
      "Date": "4/29/04",
      "Rate": "5997.199245"
  },
  {
      "Date": "4/28/04",
      "Rate": "6181.572867"
  },
  {
      "Date": "4/27/04",
      "Rate": "6196.208046"
  },
  {
      "Date": "4/26/04",
      "Rate": "6165.787756"
  },
  {
      "Date": "4/23/04",
      "Rate": "6154.21436"
  },
  {
      "Date": "4/22/04",
      "Rate": "6093.296178"
  },
  {
      "Date": "4/21/04",
      "Rate": "6118.859239"
  },
  {
      "Date": "4/20/04",
      "Rate": "6182.564066"
  },
  {
      "Date": "4/19/04",
      "Rate": "6275.828498"
  },
  {
      "Date": "4/16/04",
      "Rate": "6187.421295"
  },
  {
      "Date": "4/15/04",
      "Rate": "6175.777351"
  },
  {
      "Date": "4/14/04",
      "Rate": "6268.84072"
  },
  {
      "Date": "4/13/04",
      "Rate": "6449.605813"
  },
  {
      "Date": "4/8/04",
      "Rate": "6493.109226"
  },
  {
      "Date": "4/7/04",
      "Rate": "6438.596801"
  },
  {
      "Date": "4/6/04",
      "Rate": "6432.321558"
  },
  {
      "Date": "4/5/04",
      "Rate": "6480.812713"
  },
  {
      "Date": "4/2/04",
      "Rate": "6573.477486"
  },
  {
      "Date": "4/1/04",
      "Rate": "6540.884319"
  },
  {
      "Date": "3/31/04",
      "Rate": "6505.511561"
  },
  {
      "Date": "3/30/04",
      "Rate": "6514.312422"
  },
  {
      "Date": "3/29/04",
      "Rate": "6542.993704"
  },
  {
      "Date": "3/26/04",
      "Rate": "6550.521173"
  },
  {
      "Date": "3/25/04",
      "Rate": "6574.239405"
  },
  {
      "Date": "3/24/04",
      "Rate": "6591.139174"
  },
  {
      "Date": "3/23/04",
      "Rate": "6571.639711"
  },
  {
      "Date": "3/22/04",
      "Rate": "6587.008589"
  },
  {
      "Date": "3/19/04",
      "Rate": "6548.584631"
  },
  {
      "Date": "3/18/04",
      "Rate": "6496.922344"
  },
  {
      "Date": "3/17/04",
      "Rate": "6415.9897"
  },
  {
      "Date": "3/16/04",
      "Rate": "6377.149509"
  },
  {
      "Date": "3/15/04",
      "Rate": "6367.92536"
  },
  {
      "Date": "3/12/04",
      "Rate": "6397.9788"
  },
  {
      "Date": "3/11/04",
      "Rate": "6347.999083"
  },
  {
      "Date": "3/10/04",
      "Rate": "6391.707085"
  },
  {
      "Date": "3/9/04",
      "Rate": "6398.084622"
  },
  {
      "Date": "3/8/04",
      "Rate": "6373.957213"
  },
  {
      "Date": "3/5/04",
      "Rate": "6283.620522"
  },
  {
      "Date": "3/4/04",
      "Rate": "6293.99813"
  },
  {
      "Date": "3/3/04",
      "Rate": "6225.746486"
  },
  {
      "Date": "3/2/04",
      "Rate": "6360.489603"
  },
  {
      "Date": "3/1/04",
      "Rate": "6358.891691"
  },
  {
      "Date": "2/27/04",
      "Rate": "6258.815852"
  },
  {
      "Date": "2/26/04",
      "Rate": "6303.493889"
  },
  {
      "Date": "2/25/04",
      "Rate": "6431.746592"
  },
  {
      "Date": "2/24/04",
      "Rate": "6404.903085"
  },
  {
      "Date": "2/23/04",
      "Rate": "6387.135576"
  },
  {
      "Date": "2/20/04",
      "Rate": "6517.166088"
  },
  {
      "Date": "2/19/04",
      "Rate": "6545.953191"
  },
  {
      "Date": "2/18/04",
      "Rate": "6642.046597"
  },
  {
      "Date": "2/17/04",
      "Rate": "6607.830826"
  },
  {
      "Date": "2/16/04",
      "Rate": "6562.260357"
  },
  {
      "Date": "2/13/04",
      "Rate": "6575.544542"
  },
  {
      "Date": "2/12/04",
      "Rate": "6561.473747"
  },
  {
      "Date": "2/11/04",
      "Rate": "6488.537717"
  },
  {
      "Date": "2/10/04",
      "Rate": "6514.270093"
  },
  {
      "Date": "2/9/04",
      "Rate": "6487.948641"
  },
  {
      "Date": "2/6/04",
      "Rate": "6331.325067"
  },
  {
      "Date": "2/5/04",
      "Rate": "6366.376832"
  },
  {
      "Date": "2/4/04",
      "Rate": "6378.913208"
  },
  {
      "Date": "2/3/04",
      "Rate": "6416.067303"
  },
  {
      "Date": "2/2/04",
      "Rate": "6419.083229"
  },
  {
      "Date": "1/30/04",
      "Rate": "6403.925995"
  },
  {
      "Date": "1/29/04",
      "Rate": "6548.771583"
  },
  {
      "Date": "1/28/04",
      "Rate": "6564.796557"
  },
  {
      "Date": "1/27/04",
      "Rate": "6461.976402"
  },
  {
      "Date": "1/26/04",
      "Rate": "6490.717649"
  },
  {
      "Date": "1/23/04",
      "Rate": "6581.064922"
  },
  {
      "Date": "1/22/04",
      "Rate": "6594.296196"
  },
  {
      "Date": "1/21/04",
      "Rate": "6583.742218"
  },
  {
      "Date": "1/20/04",
      "Rate": "6539.395757"
  },
  {
      "Date": "1/19/04",
      "Rate": "6522.3302"
  },
  {
      "Date": "1/16/04",
      "Rate": "6570.704951"
  },
  {
      "Date": "1/15/04",
      "Rate": "6657.644756"
  },
  {
      "Date": "1/14/04",
      "Rate": "6745.773294"
  },
  {
      "Date": "1/13/04",
      "Rate": "6797.361505"
  },
  {
      "Date": "1/12/04",
      "Rate": "6859.627154"
  },
  {
      "Date": "1/9/04",
      "Rate": "6768.648477"
  },
  {
      "Date": "1/8/04",
      "Rate": "6708.153583"
  },
  {
      "Date": "1/7/04",
      "Rate": "6757.226759"
  },
  {
      "Date": "1/6/04",
      "Rate": "6885.109085"
  },
  {
      "Date": "1/5/04",
      "Rate": "6712.114852"
  },
  {
      "Date": "1/2/04",
      "Rate": "6682.862132"
  },
  {
      "Date": "12/31/03",
      "Rate": "6715.120196"
  },
  {
      "Date": "12/30/03",
      "Rate": "6675.373463"
  },
  {
      "Date": "12/29/03",
      "Rate": "6649.665779"
  },
  {
      "Date": "12/24/03",
      "Rate": "6589.580063"
  },
  {
      "Date": "12/23/03",
      "Rate": "6579.001393"
  },
  {
      "Date": "12/22/03",
      "Rate": "6598.275102"
  },
  {
      "Date": "12/19/03",
      "Rate": "6561.74183"
  },
  {
      "Date": "12/18/03",
      "Rate": "6595.297977"
  },
  {
      "Date": "12/17/03",
      "Rate": "6540.958394"
  },
  {
      "Date": "12/16/03",
      "Rate": "6568.323956"
  },
  {
      "Date": "12/15/03",
      "Rate": "6497.084605"
  },
  {
      "Date": "12/12/03",
      "Rate": "6529.381471"
  },
  {
      "Date": "12/11/03",
      "Rate": "6489.052717"
  },
  {
      "Date": "12/10/03",
      "Rate": "6539.184113"
  },
  {
      "Date": "12/9/03",
      "Rate": "6555.156176"
  },
  {
      "Date": "12/8/03",
      "Rate": "6568.951833"
  },
  {
      "Date": "12/5/03",
      "Rate": "6465.43678"
  },
  {
      "Date": "12/4/03",
      "Rate": "6471.870756"
  },
  {
      "Date": "12/3/03",
      "Rate": "6490.611827"
  },
  {
      "Date": "12/2/03",
      "Rate": "6456.523043"
  },
  {
      "Date": "12/1/03",
      "Rate": "6427.231521"
  },
  {
      "Date": "11/28/03",
      "Rate": "6427.287959"
  },
  {
      "Date": "11/27/03",
      "Rate": "6414.889151"
  },
  {
      "Date": "11/26/03",
      "Rate": "6344.013122"
  },
  {
      "Date": "11/25/03",
      "Rate": "6342.136546"
  },
  {
      "Date": "11/24/03",
      "Rate": "6377.646872"
  },
  {
      "Date": "11/21/03",
      "Rate": "6355.893402"
  },
  {
      "Date": "11/20/03",
      "Rate": "6384.461807"
  },
  {
      "Date": "11/19/03",
      "Rate": "6391.636537"
  },
  {
      "Date": "11/18/03",
      "Rate": "6315.882114"
  },
  {
      "Date": "11/17/03",
      "Rate": "6387.76698"
  },
  {
      "Date": "11/14/03",
      "Rate": "6334.079966"
  },
  {
      "Date": "11/13/03",
      "Rate": "6327.413182"
  },
  {
      "Date": "11/12/03",
      "Rate": "6204.804318"
  },
  {
      "Date": "11/11/03",
      "Rate": "6172.969541"
  },
  {
      "Date": "11/10/03",
      "Rate": "6105.451595"
  },
  {
      "Date": "11/7/03",
      "Rate": "6088.043881"
  },
  {
      "Date": "11/6/03",
      "Rate": "6103.09882"
  },
  {
      "Date": "11/5/03",
      "Rate": "6106.418103"
  },
  {
      "Date": "11/4/03",
      "Rate": "6039.563308"
  },
  {
      "Date": "11/3/03",
      "Rate": "6120.615884"
  },
  {
      "Date": "10/31/03",
      "Rate": "6141.498086"
  },
  {
      "Date": "10/30/03",
      "Rate": "6241.630364"
  },
  {
      "Date": "10/29/03",
      "Rate": "6118.584102"
  },
  {
      "Date": "10/28/03",
      "Rate": "6176.729748"
  },
  {
      "Date": "10/27/03",
      "Rate": "6192.885236"
  },
  {
      "Date": "10/24/03",
      "Rate": "6146.926754"
  },
  {
      "Date": "10/23/03",
      "Rate": "6175.830262"
  },
  {
      "Date": "10/22/03",
      "Rate": "6097.504365"
  },
  {
      "Date": "10/21/03",
      "Rate": "5988.973351"
  },
  {
      "Date": "10/20/03",
      "Rate": "5938.0624"
  },
  {
      "Date": "10/17/03",
      "Rate": "5942.34819"
  },
  {
      "Date": "10/16/03",
      "Rate": "5932.757191"
  },
  {
      "Date": "10/15/03",
      "Rate": "6035.700806"
  },
  {
      "Date": "10/14/03",
      "Rate": "6017.38655"
  },
  {
      "Date": "10/13/03",
      "Rate": "5939.44514"
  },
  {
      "Date": "10/10/03",
      "Rate": "5927.011058"
  },
  {
      "Date": "10/9/03",
      "Rate": "6007.372264"
  },
  {
      "Date": "10/8/03",
      "Rate": "6029.690118"
  },
  {
      "Date": "10/7/03",
      "Rate": "6008.691511"
  },
  {
      "Date": "10/6/03",
      "Rate": "5974.623891"
  },
  {
      "Date": "10/3/03",
      "Rate": "6146.016685"
  },
  {
      "Date": "10/2/03",
      "Rate": "6181.530538"
  },
  {
      "Date": "10/1/03",
      "Rate": "6185.911568"
  },
  {
      "Date": "9/30/03",
      "Rate": "6206.359901"
  },
  {
      "Date": "9/29/03",
      "Rate": "6136.662022"
  },
  {
      "Date": "9/26/03",
      "Rate": "6214.130761"
  },
  {
      "Date": "9/25/03",
      "Rate": "6340.203531"
  },
  {
      "Date": "9/24/03",
      "Rate": "6219.823983"
  },
  {
      "Date": "9/23/03",
      "Rate": "6237.834882"
  },
  {
      "Date": "9/22/03",
      "Rate": "6231.757174"
  },
  {
      "Date": "9/19/03",
      "Rate": "6096.566077"
  },
  {
      "Date": "9/18/03",
      "Rate": "6114.411189"
  },
  {
      "Date": "9/17/03",
      "Rate": "6044.441701"
  },
  {
      "Date": "9/16/03",
      "Rate": "6063.306231"
  },
  {
      "Date": "9/15/03",
      "Rate": "6061.831778"
  },
  {
      "Date": "9/12/03",
      "Rate": "6092.040424"
  },
  {
      "Date": "9/11/03",
      "Rate": "6109.698584"
  },
  {
      "Date": "9/10/03",
      "Rate": "6145.565178"
  },
  {
      "Date": "9/9/03",
      "Rate": "6110.005467"
  },
  {
      "Date": "9/8/03",
      "Rate": "6080.92559"
  },
  {
      "Date": "9/5/03",
      "Rate": "6002.194042"
  },
  {
      "Date": "9/4/03",
      "Rate": "6033.89125"
  },
  {
      "Date": "9/3/03",
      "Rate": "6024.889328"
  },
  {
      "Date": "9/2/03",
      "Rate": "6075.655655"
  },
  {
      "Date": "9/1/03",
      "Rate": "6084.100249"
  },
  {
      "Date": "8/29/03",
      "Rate": "5998.920616"
  },
  {
      "Date": "8/28/03",
      "Rate": "6000.574966"
  },
  {
      "Date": "8/27/03",
      "Rate": "5889.299635"
  },
  {
      "Date": "8/26/03",
      "Rate": "5836.998889"
  },
  {
      "Date": "8/22/03",
      "Rate": "5834.455634"
  },
  {
      "Date": "8/21/03",
      "Rate": "5885.733434"
  },
  {
      "Date": "8/20/03",
      "Rate": "5853.433041"
  },
  {
      "Date": "8/19/03",
      "Rate": "5788.511261"
  },
  {
      "Date": "8/18/03",
      "Rate": "5842.074816"
  },
  {
      "Date": "8/15/03",
      "Rate": "5878.900862"
  },
  {
      "Date": "8/14/03",
      "Rate": "5864.463218"
  },
  {
      "Date": "8/13/03",
      "Rate": "5763.91118"
  },
  {
      "Date": "8/12/03",
      "Rate": "5826.1345"
  },
  {
      "Date": "8/11/03",
      "Rate": "5786.398349"
  },
  {
      "Date": "8/8/03",
      "Rate": "5730.915889"
  },
  {
      "Date": "8/7/03",
      "Rate": "5672.900757"
  },
  {
      "Date": "8/6/03",
      "Rate": "5727.709483"
  },
  {
      "Date": "8/5/03",
      "Rate": "5683.945043"
  },
  {
      "Date": "8/4/03",
      "Rate": "5678.692746"
  },
  {
      "Date": "8/1/03",
      "Rate": "5748.528193"
  },
  {
      "Date": "7/31/03",
      "Rate": "5790.232632"
  },
  {
      "Date": "7/30/03",
      "Rate": "5842.222967"
  },
  {
      "Date": "7/29/03",
      "Rate": "5921.363692"
  },
  {
      "Date": "7/28/03",
      "Rate": "5890.248505"
  },
  {
      "Date": "7/25/03",
      "Rate": "5873.232332"
  },
  {
      "Date": "7/24/03",
      "Rate": "5858.434893"
  },
  {
      "Date": "7/23/03",
      "Rate": "5734.686679"
  },
  {
      "Date": "7/22/03",
      "Rate": "5737.988324"
  },
  {
      "Date": "7/21/03",
      "Rate": "5670.406885"
  },
  {
      "Date": "7/18/03",
      "Rate": "5610.271786"
  },
  {
      "Date": "7/17/03",
      "Rate": "5618.233126"
  },
  {
      "Date": "7/16/03",
      "Rate": "5589.424858"
  },
  {
      "Date": "7/15/03",
      "Rate": "5670.756098"
  },
  {
      "Date": "7/14/03",
      "Rate": "5594.730066"
  },
  {
      "Date": "7/11/03",
      "Rate": "5598.052876"
  },
  {
      "Date": "7/10/03",
      "Rate": "5595.03695"
  },
  {
      "Date": "7/9/03",
      "Rate": "5633.309229"
  },
  {
      "Date": "7/8/03",
      "Rate": "5635.73608"
  },
  {
      "Date": "7/7/03",
      "Rate": "5723.938694"
  },
  {
      "Date": "7/4/03",
      "Rate": "5737.829591"
  },
  {
      "Date": "7/3/03",
      "Rate": "5721.949241"
  },
  {
      "Date": "7/2/03",
      "Rate": "5750.612886"
  },
  {
      "Date": "7/1/03",
      "Rate": "5709.067179"
  },
  {
      "Date": "6/30/03",
      "Rate": "5659.76825"
  },
  {
      "Date": "6/27/03",
      "Rate": "5622.413094"
  },
  {
      "Date": "6/26/03",
      "Rate": "5682.2272"
  },
  {
      "Date": "6/25/03",
      "Rate": "5716.619341"
  },
  {
      "Date": "6/24/03",
      "Rate": "5822.815217"
  },
  {
      "Date": "6/23/03",
      "Rate": "5859.542496"
  },
  {
      "Date": "6/20/03",
      "Rate": "5903.377485"
  },
  {
      "Date": "6/19/03",
      "Rate": "5856.194995"
  },
  {
      "Date": "6/18/03",
      "Rate": "5936.683187"
  },
  {
      "Date": "6/17/03",
      "Rate": "5912.1854"
  },
  {
      "Date": "6/16/03",
      "Rate": "5909.673892"
  },
  {
      "Date": "6/13/03",
      "Rate": "5834.970634"
  },
  {
      "Date": "6/12/03",
      "Rate": "5832.84714"
  },
  {
      "Date": "6/11/03",
      "Rate": "5832.960017"
  },
  {
      "Date": "6/10/03",
      "Rate": "5931.473218"
  },
  {
      "Date": "6/9/03",
      "Rate": "6000.063493"
  },
  {
      "Date": "6/6/03",
      "Rate": "6076.608053"
  },
  {
      "Date": "6/5/03",
      "Rate": "5999.63315"
  },
  {
      "Date": "6/4/03",
      "Rate": "6041.408138"
  },
  {
      "Date": "6/3/03",
      "Rate": "6049.785711"
  },
  {
      "Date": "6/2/03",
      "Rate": "6005.841373"
  },
  {
      "Date": "5/30/03",
      "Rate": "6074.837299"
  },
  {
      "Date": "5/29/03",
      "Rate": "5993.918764"
  },
  {
      "Date": "5/28/03",
      "Rate": "6041.221186"
  },
  {
      "Date": "5/27/03",
      "Rate": "6160.630699"
  },
  {
      "Date": "5/23/03",
      "Rate": "6116.626396"
  },
  {
      "Date": "5/22/03",
      "Rate": "6115.130778"
  },
  {
      "Date": "5/21/03",
      "Rate": "6031.89827"
  },
  {
      "Date": "5/20/03",
      "Rate": "6089.955731"
  },
  {
      "Date": "5/19/03",
      "Rate": "5963.389125"
  },
  {
      "Date": "5/16/03",
      "Rate": "5861.634244"
  },
  {
      "Date": "5/15/03",
      "Rate": "5882.001446"
  },
  {
      "Date": "5/14/03",
      "Rate": "5803.104111"
  },
  {
      "Date": "5/13/03",
      "Rate": "5834.653168"
  },
  {
      "Date": "5/12/03",
      "Rate": "5828.702446"
  },
  {
      "Date": "5/9/03",
      "Rate": "5803.774317"
  },
  {
      "Date": "5/8/03",
      "Rate": "5686.184942"
  },
  {
      "Date": "5/7/03",
      "Rate": "5717.494136"
  },
  {
      "Date": "5/6/03",
      "Rate": "5713.472901"
  },
  {
      "Date": "5/2/03",
      "Rate": "5698.855359"
  },
  {
      "Date": "5/1/03",
      "Rate": "5659.129791"
  },
  {
      "Date": "4/30/03",
      "Rate": "5608.987813"
  },
  {
      "Date": "4/29/03",
      "Rate": "5558.683575"
  },
  {
      "Date": "4/28/03",
      "Rate": "5575.1142"
  },
  {
      "Date": "4/25/03",
      "Rate": "5569.114094"
  },
  {
      "Date": "4/24/03",
      "Rate": "5543.501649"
  },
  {
      "Date": "4/23/03",
      "Rate": "5574.616836"
  },
  {
      "Date": "4/22/03",
      "Rate": "5565.724263"
  },
  {
      "Date": "4/17/03",
      "Rate": "5411.873225"
  },
  {
      "Date": "4/16/03",
      "Rate": "5409.418156"
  },
  {
      "Date": "4/15/03",
      "Rate": "5405.770825"
  },
  {
      "Date": "4/14/03",
      "Rate": "5438.822554"
  },
  {
      "Date": "4/11/03",
      "Rate": "5427.915836"
  },
  {
      "Date": "4/10/03",
      "Rate": "5435.055292"
  },
  {
      "Date": "4/9/03",
      "Rate": "5427.771213"
  },
  {
      "Date": "4/8/03",
      "Rate": "5389.382529"
  },
  {
      "Date": "4/7/03",
      "Rate": "5348.43648"
  },
  {
      "Date": "4/4/03",
      "Rate": "5401.41096"
  },
  {
      "Date": "4/3/03",
      "Rate": "5417.488845"
  },
  {
      "Date": "4/2/03",
      "Rate": "5519.991534"
  },
  {
      "Date": "4/1/03",
      "Rate": "5618.536482"
  },
  {
      "Date": "3/31/03",
      "Rate": "5615.287748"
  },
  {
      "Date": "3/28/03",
      "Rate": "5537.720242"
  },
  {
      "Date": "3/27/03",
      "Rate": "5589.615337"
  },
  {
      "Date": "3/26/03",
      "Rate": "5533.540274"
  },
  {
      "Date": "3/25/03",
      "Rate": "5592.384345"
  },
  {
      "Date": "3/24/03",
      "Rate": "5550.052029"
  },
  {
      "Date": "3/21/03",
      "Rate": "5592.451366"
  },
  {
      "Date": "3/20/03",
      "Rate": "5649.753964"
  },
  {
      "Date": "3/19/03",
      "Rate": "5704.365156"
  },
  {
      "Date": "3/18/03",
      "Rate": "5630.790667"
  },
  {
      "Date": "3/17/03",
      "Rate": "5725.243831"
  },
  {
      "Date": "3/14/03",
      "Rate": "5639.693822"
  },
  {
      "Date": "3/13/03",
      "Rate": "5764.690735"
  },
  {
      "Date": "3/12/03",
      "Rate": "5866.629041"
  },
  {
      "Date": "3/11/03",
      "Rate": "5937.632057"
  },
  {
      "Date": "3/10/03",
      "Rate": "5934.302192"
  },
  {
      "Date": "3/7/03",
      "Rate": "5994.066915"
  },
  {
      "Date": "3/6/03",
      "Rate": "5934.330411"
  },
  {
      "Date": "3/5/03",
      "Rate": "5956.581245"
  },
  {
      "Date": "3/4/03",
      "Rate": "5923.63181"
  },
  {
      "Date": "3/3/03",
      "Rate": "5856.766433"
  },
  {
      "Date": "2/28/03",
      "Rate": "5847.612833"
  },
  {
      "Date": "2/27/03",
      "Rate": "5954.422477"
  },
  {
      "Date": "2/26/03",
      "Rate": "5931.275684"
  },
  {
      "Date": "2/25/03",
      "Rate": "6044.639235"
  },
  {
      "Date": "2/24/03",
      "Rate": "5942.76795"
  },
  {
      "Date": "2/21/03",
      "Rate": "5959.417274"
  },
  {
      "Date": "2/20/03",
      "Rate": "5908.121836"
  },
  {
      "Date": "2/19/03",
      "Rate": "5841.880809"
  },
  {
      "Date": "2/18/03",
      "Rate": "5841.577453"
  },
  {
      "Date": "2/17/03",
      "Rate": "5837.055327"
  },
  {
      "Date": "2/14/03",
      "Rate": "6014.261274"
  },
  {
      "Date": "2/13/03",
      "Rate": "5982.370059"
  },
  {
      "Date": "2/12/03",
      "Rate": "6132.02702"
  },
  {
      "Date": "2/11/03",
      "Rate": "6096.618988"
  },
  {
      "Date": "2/10/03",
      "Rate": "6254.297254"
  },
  {
      "Date": "2/7/03",
      "Rate": "6234.762518"
  },
  {
      "Date": "2/6/03",
      "Rate": "6271.514489"
  },
  {
      "Date": "2/5/03",
      "Rate": "6485.363058"
  },
  {
      "Date": "2/4/03",
      "Rate": "6317.028519"
  },
  {
      "Date": "2/3/03",
      "Rate": "6221.04799"
  },
  {
      "Date": "1/31/03",
      "Rate": "6243.806769"
  },
  {
      "Date": "1/30/03",
      "Rate": "6121.173213"
  },
  {
      "Date": "1/29/03",
      "Rate": "6236.681423"
  },
  {
      "Date": "1/28/03",
      "Rate": "6192.274996"
  },
  {
      "Date": "1/27/03",
      "Rate": "6265.778938"
  },
  {
      "Date": "1/24/03",
      "Rate": "6146.785658"
  },
  {
      "Date": "1/23/03",
      "Rate": "6141.607436"
  },
  {
      "Date": "1/22/03",
      "Rate": "6070.607947"
  },
  {
      "Date": "1/21/03",
      "Rate": "6010.592779"
  },
  {
      "Date": "1/20/03",
      "Rate": "6017.957989"
  },
  {
      "Date": "1/17/03",
      "Rate": "6056.339618"
  },
  {
      "Date": "1/16/03",
      "Rate": "5932.376232"
  },
  {
      "Date": "1/15/03",
      "Rate": "5951.374804"
  },
  {
      "Date": "1/14/03",
      "Rate": "5977.668036"
  },
  {
      "Date": "1/13/03",
      "Rate": "5956.496587"
  },
  {
      "Date": "1/10/03",
      "Rate": "5975.00485"
  },
  {
      "Date": "1/9/03",
      "Rate": "5994.536059"
  },
  {
      "Date": "1/8/03",
      "Rate": "5866.720753"
  },
  {
      "Date": "1/7/03",
      "Rate": "5900.943579"
  },
  {
      "Date": "1/6/03",
      "Rate": "6028.056932"
  },
  {
      "Date": "1/3/03",
      "Rate": "5825.048061"
  },
  {
      "Date": "1/2/03",
      "Rate": "5796.984074"
  },
  {
      "Date": "12/31/02",
      "Rate": "5800.253973"
  },
  {
      "Date": "12/30/02",
      "Rate": "5892.643609"
  },
  {
      "Date": "12/27/02",
      "Rate": "5894.1745"
  },
  {
      "Date": "12/24/02",
      "Rate": "5843.200056"
  },
  {
      "Date": "12/23/02",
      "Rate": "5824.568335"
  },
  {
      "Date": "12/20/02",
      "Rate": "5808.596272"
  },
  {
      "Date": "12/19/02",
      "Rate": "5864.438526"
  },
  {
      "Date": "12/18/02",
      "Rate": "5720.439514"
  },
  {
      "Date": "12/17/02",
      "Rate": "5788.684104"
  },
  {
      "Date": "12/16/02",
      "Rate": "5657.052153"
  },
  {
      "Date": "12/13/02",
      "Rate": "5668.424487"
  },
  {
      "Date": "12/12/02",
      "Rate": "5543.498122"
  },
  {
      "Date": "12/11/02",
      "Rate": "5499.278647"
  },
  {
      "Date": "12/10/02",
      "Rate": "5541.247641"
  },
  {
      "Date": "12/9/02",
      "Rate": "5531.346232"
  },
  {
      "Date": "12/6/02",
      "Rate": "5533.639041"
  },
  {
      "Date": "12/5/02",
      "Rate": "5480.340041"
  },
  {
      "Date": "12/4/02",
      "Rate": "5453.126157"
  },
  {
      "Date": "12/3/02",
      "Rate": "5407.717949"
  },
  {
      "Date": "12/2/02",
      "Rate": "5404.268153"
  },
  {
      "Date": "11/29/02",
      "Rate": "5420.448332"
  },
  {
      "Date": "11/28/02",
      "Rate": "5403.149967"
  },
  {
      "Date": "11/27/02",
      "Rate": "5413.986137"
  },
  {
      "Date": "11/26/02",
      "Rate": "5407.217059"
  },
  {
      "Date": "11/25/02",
      "Rate": "5432.159297"
  },
  {
      "Date": "11/22/02",
      "Rate": "5409.157128"
  },
  {
      "Date": "11/21/02",
      "Rate": "5411.770931"
  },
  {
      "Date": "11/20/02",
      "Rate": "5425.34789"
  },
  {
      "Date": "11/19/02",
      "Rate": "5436.695533"
  },
  {
      "Date": "11/18/02",
      "Rate": "5437.8243"
  },
  {
      "Date": "11/15/02",
      "Rate": "5415.439426"
  },
  {
      "Date": "11/14/02",
      "Rate": "5423.037443"
  },
  {
      "Date": "11/13/02",
      "Rate": "5504.188786"
  },
  {
      "Date": "11/12/02",
      "Rate": "5481.479391"
  },
  {
      "Date": "11/11/02",
      "Rate": "5462.1951"
  },
  {
      "Date": "11/8/02",
      "Rate": "5491.980458"
  },
  {
      "Date": "11/7/02",
      "Rate": "5445.708037"
  },
  {
      "Date": "11/6/02",
      "Rate": "5412.716274"
  },
  {
      "Date": "11/5/02",
      "Rate": "5436.018272"
  },
  {
      "Date": "11/4/02",
      "Rate": "5408.158874"
  },
  {
      "Date": "11/1/02",
      "Rate": "5435.150532"
  },
  {
      "Date": "10/31/02",
      "Rate": "5396.458491"
  },
  {
      "Date": "10/30/02",
      "Rate": "5405.809626"
  },
  {
      "Date": "10/29/02",
      "Rate": "5397.573149"
  },
  {
      "Date": "10/28/02",
      "Rate": "5352.164941"
  },
  {
      "Date": "10/25/02",
      "Rate": "5327.370853"
  },
  {
      "Date": "10/24/02",
      "Rate": "5310.19242"
  },
  {
      "Date": "10/23/02",
      "Rate": "5349.452371"
  },
  {
      "Date": "10/22/02",
      "Rate": "5317.381259"
  },
  {
      "Date": "10/21/02",
      "Rate": "5345.029013"
  },
  {
      "Date": "10/18/02",
      "Rate": "5332.683116"
  },
  {
      "Date": "10/17/02",
      "Rate": "5339.762606"
  },
  {
      "Date": "10/16/02",
      "Rate": "5359.692411"
  },
  {
      "Date": "10/15/02",
      "Rate": "5411.033704"
  },
  {
      "Date": "10/14/02",
      "Rate": "5421.799326"
  },
  {
      "Date": "10/11/02",
      "Rate": "5405.894284"
  },
  {
      "Date": "10/10/02",
      "Rate": "5449.066121"
  },
  {
      "Date": "10/9/02",
      "Rate": "5433.189298"
  },
  {
      "Date": "10/8/02",
      "Rate": "5470.660858"
  },
  {
      "Date": "10/7/02",
      "Rate": "5499.377414"
  },
  {
      "Date": "10/4/02",
      "Rate": "5486.86573"
  },
  {
      "Date": "10/3/02",
      "Rate": "5504.777862"
  },
  {
      "Date": "10/2/02",
      "Rate": "5463.849451"
  },
  {
      "Date": "10/1/02",
      "Rate": "5501.934778"
  },
  {
      "Date": "9/30/02",
      "Rate": "5501.366867"
  },
  {
      "Date": "9/27/02",
      "Rate": "5462.974656"
  },
  {
      "Date": "9/26/02",
      "Rate": "5502.220498"
  },
  {
      "Date": "9/25/02",
      "Rate": "5553.44186"
  },
  {
      "Date": "9/24/02",
      "Rate": "5518.435951"
  },
  {
      "Date": "9/23/02",
      "Rate": "5520.996843"
  },
  {
      "Date": "9/20/02",
      "Rate": "5490.266142"
  },
  {
      "Date": "9/19/02",
      "Rate": "5467.489726"
  },
  {
      "Date": "9/18/02",
      "Rate": "5422.822272"
  },
  {
      "Date": "9/17/02",
      "Rate": "5383.551738"
  },
  {
      "Date": "9/16/02",
      "Rate": "5405.453359"
  },
  {
      "Date": "9/13/02",
      "Rate": "5439.873719"
  },
  {
      "Date": "9/12/02",
      "Rate": "5435.259881"
  },
  {
      "Date": "9/11/02",
      "Rate": "5420.173195"
  },
  {
      "Date": "9/10/02",
      "Rate": "5444.611016"
  },
  {
      "Date": "9/9/02",
      "Rate": "5511.592797"
  },
  {
      "Date": "9/6/02",
      "Rate": "5448.127833"
  },
  {
      "Date": "9/5/02",
      "Rate": "5406.72675"
  },
  {
      "Date": "9/4/02",
      "Rate": "5344.122471"
  },
  {
      "Date": "9/3/02",
      "Rate": "5360.006349"
  },
  {
      "Date": "9/2/02",
      "Rate": "5339.184113"
  },
  {
      "Date": "8/30/02",
      "Rate": "5345.053705"
  },
  {
      "Date": "8/29/02",
      "Rate": "5316.206635"
  },
  {
      "Date": "8/28/02",
      "Rate": "5318.051465"
  },
  {
      "Date": "8/27/02",
      "Rate": "5293.726521"
  },
  {
      "Date": "8/23/02",
      "Rate": "5236.445087"
  },
  {
      "Date": "8/22/02",
      "Rate": "5247.577559"
  },
  {
      "Date": "8/21/02",
      "Rate": "5279.024321"
  },
  {
      "Date": "8/20/02",
      "Rate": "5271.066509"
  },
  {
      "Date": "8/19/02",
      "Rate": "5365.286866"
  },
  {
      "Date": "8/16/02",
      "Rate": "5379.622216"
  },
  {
      "Date": "8/15/02",
      "Rate": "5361.216247"
  },
  {
      "Date": "8/14/02",
      "Rate": "5421.524189"
  },
  {
      "Date": "8/13/02",
      "Rate": "5395.506094"
  },
  {
      "Date": "8/12/02",
      "Rate": "5423.196176"
  },
  {
      "Date": "8/9/02",
      "Rate": "5312.435845"
  },
  {
      "Date": "8/8/02",
      "Rate": "5371.576218"
  },
  {
      "Date": "8/7/02",
      "Rate": "5283.179598"
  },
  {
      "Date": "8/6/02",
      "Rate": "5270.565618"
  },
  {
      "Date": "8/5/02",
      "Rate": "5277.57456"
  },
  {
      "Date": "8/2/02",
      "Rate": "5260.325579"
  },
  {
      "Date": "8/1/02",
      "Rate": "5159.925219"
  },
  {
      "Date": "7/31/02",
      "Rate": "5243.700947"
  },
  {
      "Date": "7/30/02",
      "Rate": "5231.584331"
  },
  {
      "Date": "7/29/02",
      "Rate": "5211.27004"
  },
  {
      "Date": "7/26/02",
      "Rate": "5299.631387"
  },
  {
      "Date": "7/25/02",
      "Rate": "5325.779996"
  },
  {
      "Date": "7/24/02",
      "Rate": "5385.498862"
  },
  {
      "Date": "7/23/02",
      "Rate": "5501.123477"
  },
  {
      "Date": "7/22/02",
      "Rate": "5565.24101"
  },
  {
      "Date": "7/19/02",
      "Rate": "5486.438914"
  },
  {
      "Date": "7/18/02",
      "Rate": "5447.069613"
  },
  {
      "Date": "7/17/02",
      "Rate": "5466.854795"
  },
  {
      "Date": "7/16/02",
      "Rate": "5514.975573"
  },
  {
      "Date": "7/15/02",
      "Rate": "5449.46119"
  },
  {
      "Date": "7/12/02",
      "Rate": "5445.348242"
  },
  {
      "Date": "7/11/02",
      "Rate": "5410.938465"
  },
  {
      "Date": "7/10/02",
      "Rate": "5438.670876"
  },
  {
      "Date": "7/9/02",
      "Rate": "5377.872626"
  },
  {
      "Date": "7/8/02",
      "Rate": "5389.548317"
  },
  {
      "Date": "7/5/02",
      "Rate": "5350.803365"
  },
  {
      "Date": "7/4/02",
      "Rate": "5352.838674"
  },
  {
      "Date": "7/3/02",
      "Rate": "5385.858657"
  },
  {
      "Date": "7/2/02",
      "Rate": "5390.585372"
  },
  {
      "Date": "7/1/02",
      "Rate": "5380.796839"
  },
  {
      "Date": "6/28/02",
      "Rate": "5501.035292"
  },
  {
      "Date": "6/27/02",
      "Rate": "5504.552109"
  },
  {
      "Date": "6/26/02",
      "Rate": "5591.576571"
  },
  {
      "Date": "6/25/02",
      "Rate": "5573.71735"
  },
  {
      "Date": "6/24/02",
      "Rate": "5627.478439"
  },
  {
      "Date": "6/21/02",
      "Rate": "5582.899169"
  },
  {
      "Date": "6/20/02",
      "Rate": "5537.16644"
  },
  {
      "Date": "6/19/02",
      "Rate": "5554.422477"
  },
  {
      "Date": "6/18/02",
      "Rate": "5493.892309"
  },
  {
      "Date": "6/17/02",
      "Rate": "5494.968165"
  },
  {
      "Date": "6/14/02",
      "Rate": "5525.776469"
  },
  {
      "Date": "6/13/02",
      "Rate": "5488.357819"
  },
  {
      "Date": "6/12/02",
      "Rate": "5519.240198"
  },
  {
      "Date": "6/11/02",
      "Rate": "5510.866153"
  },
  {
      "Date": "6/10/02",
      "Rate": "5581.692799"
  },
  {
      "Date": "6/7/02",
      "Rate": "5647.051976"
  },
  {
      "Date": "6/6/02",
      "Rate": "5577.668036"
  },
  {
      "Date": "6/5/02",
      "Rate": "5609.312334"
  },
  {
      "Date": "5/31/02",
      "Rate": "5661.468456"
  },
  {
      "Date": "5/30/02",
      "Rate": "5610.762095"
  },
  {
      "Date": "5/29/02",
      "Rate": "5617.972098"
  },
  {
      "Date": "5/28/02",
      "Rate": "5552.323674"
  },
  {
      "Date": "5/27/02",
      "Rate": "5541.395792"
  },
  {
      "Date": "5/24/02",
      "Rate": "5545.953191"
  },
  {
      "Date": "5/23/02",
      "Rate": "5471.722605"
  },
  {
      "Date": "5/22/02",
      "Rate": "5498.467345"
  },
  {
      "Date": "5/21/02",
      "Rate": "5443.489303"
  },
  {
      "Date": "5/20/02",
      "Rate": "5393.079243"
  },
  {
      "Date": "5/17/02",
      "Rate": "5369.900704"
  },
  {
      "Date": "5/16/02",
      "Rate": "5345.515794"
  },
  {
      "Date": "5/15/02",
      "Rate": "5317.621122"
  },
  {
      "Date": "5/14/02",
      "Rate": "5356.708231"
  },
  {
      "Date": "5/13/02",
      "Rate": "5386.874548"
  },
  {
      "Date": "5/10/02",
      "Rate": "5368.80721"
  },
  {
      "Date": "5/9/02",
      "Rate": "5332.700753"
  },
  {
      "Date": "5/8/02",
      "Rate": "5368.193443"
  },
  {
      "Date": "5/7/02",
      "Rate": "5400.063493"
  },
  {
      "Date": "5/3/02",
      "Rate": "5336.062364"
  },
  {
      "Date": "5/2/02",
      "Rate": "5318.213725"
  },
  {
      "Date": "5/1/02",
      "Rate": "5321.667049"
  },
  {
      "Date": "4/30/02",
      "Rate": "5341.974285"
  },
  {
      "Date": "4/29/02",
      "Rate": "5366.613168"
  },
  {
      "Date": "4/26/02",
      "Rate": "5314.788621"
  },
  {
      "Date": "4/25/02",
      "Rate": "5270.734934"
  },
  {
      "Date": "4/24/02",
      "Rate": "5262.519621"
  },
  {
      "Date": "4/23/02",
      "Rate": "5241.450466"
  },
  {
      "Date": "4/22/02",
      "Rate": "5234.617894"
  },
  {
      "Date": "4/19/02",
      "Rate": "5239.051835"
  },
  {
      "Date": "4/18/02",
      "Rate": "5214.899734"
  },
  {
      "Date": "4/17/02",
      "Rate": "5179.929099"
  },
  {
      "Date": "4/16/02",
      "Rate": "5166.122859"
  },
  {
      "Date": "4/15/02",
      "Rate": "5210.532814"
  },
  {
      "Date": "4/12/02",
      "Rate": "5203.686132"
  },
  {
      "Date": "4/11/02",
      "Rate": "5206.275243"
  },
  {
      "Date": "4/10/02",
      "Rate": "5136.221097"
  },
  {
      "Date": "4/9/02",
      "Rate": "5163.149262"
  },
  {
      "Date": "4/8/02",
      "Rate": "5149.346549"
  },
  {
      "Date": "4/5/02",
      "Rate": "5181.597559"
  },
  {
      "Date": "4/4/02",
      "Rate": "5196.313868"
  },
  {
      "Date": "4/3/02",
      "Rate": "5260.075134"
  },
  {
      "Date": "4/2/02",
      "Rate": "5211.862643"
  },
  {
      "Date": "3/28/02",
      "Rate": "5213.61576"
  },
  {
      "Date": "3/27/02",
      "Rate": "5124.749996"
  },
  {
      "Date": "3/26/02",
      "Rate": "5107.381083"
  },
  {
      "Date": "3/25/02",
      "Rate": "5119.271945"
  },
  {
      "Date": "3/22/02",
      "Rate": "5052.216088"
  },
  {
      "Date": "3/21/02",
      "Rate": "5025.809979"
  },
  {
      "Date": "3/20/02",
      "Rate": "5030.709536"
  },
  {
      "Date": "3/19/02",
      "Rate": "5039.30228"
  },
  {
      "Date": "3/18/02",
      "Rate": "5000.465617"
  },
  {
      "Date": "3/15/02",
      "Rate": "5000.465617"
  },
  {
      "Date": "3/14/02",
      "Rate": "5030.709536"
  },
  {
      "Date": "3/13/02",
      "Rate": "5049.274238"
  },
  {
      "Date": "3/12/02",
      "Rate": "5018.677578"
  },
  {
      "Date": "3/11/02",
      "Rate": "5014.381206"
  },
  {
      "Date": "3/8/02",
      "Rate": "4972.271116"
  },
  {
      "Date": "3/7/02",
      "Rate": "5021.256107"
  },
  {
      "Date": "3/6/02",
      "Rate": "5054.914549"
  },
  {
      "Date": "3/5/02",
      "Rate": "5095.645426"
  },
  {
      "Date": "3/4/02",
      "Rate": "5145.801513"
  },
  {
      "Date": "3/1/02",
      "Rate": "5083.990899"
  },
  {
      "Date": "2/28/02",
      "Rate": "5098.449708"
  },
  {
      "Date": "2/27/02",
      "Rate": "5144.672746"
  },
  {
      "Date": "2/26/02",
      "Rate": "5035.866594"
  },
  {
      "Date": "2/25/02",
      "Rate": "5025.534842"
  },
  {
      "Date": "2/22/02",
      "Rate": "5046.794476"
  },
  {
      "Date": "2/21/02",
      "Rate": "4998.934725"
  },
  {
      "Date": "2/20/02",
      "Rate": "5045.073105"
  },
  {
      "Date": "2/19/02",
      "Rate": "5101.571456"
  },
  {
      "Date": "2/18/02",
      "Rate": "5125.130955"
  },
  {
      "Date": "2/15/02",
      "Rate": "5157.752341"
  },
  {
      "Date": "2/14/02",
      "Rate": "5120.365439"
  },
  {
      "Date": "2/13/02",
      "Rate": "5127.899963"
  },
  {
      "Date": "2/12/02",
      "Rate": "5131.998801"
  },
  {
      "Date": "2/11/02",
      "Rate": "5181.957354"
  },
  {
      "Date": "2/8/02",
      "Rate": "5241.679747"
  },
  {
      "Date": "2/7/02",
      "Rate": "5159.011623"
  },
  {
      "Date": "2/6/02",
      "Rate": "5108.150056"
  },
  {
      "Date": "2/5/02",
      "Rate": "4980.07725"
  },
  {
      "Date": "2/4/02",
      "Rate": "4901.028237"
  },
  {
      "Date": "2/1/02",
      "Rate": "4826.910528"
  },
  {
      "Date": "1/31/02",
      "Rate": "4817.855694"
  },
  {
      "Date": "1/30/02",
      "Rate": "4808.938429"
  },
  {
      "Date": "1/29/02",
      "Rate": "4746.200109"
  },
  {
      "Date": "1/28/02",
      "Rate": "4764.800085"
  },
  {
      "Date": "1/25/02",
      "Rate": "4759.81587"
  },
  {
      "Date": "1/24/02",
      "Rate": "4758.697684"
  },
  {
      "Date": "1/23/02",
      "Rate": "4795.230956"
  },
  {
      "Date": "1/22/02",
      "Rate": "4825.030424"
  },
  {
      "Date": "1/21/02",
      "Rate": "4810.120108"
  },
  {
      "Date": "1/18/02",
      "Rate": "4828.490802"
  },
  {
      "Date": "1/17/02",
      "Rate": "4857.242632"
  },
  {
      "Date": "1/16/02",
      "Rate": "4905.395157"
  },
  {
      "Date": "1/15/02",
      "Rate": "4834.547347"
  },
  {
      "Date": "1/14/02",
      "Rate": "4884.544701"
  },
  {
      "Date": "1/11/02",
      "Rate": "4894.52724"
  },
  {
      "Date": "1/10/02",
      "Rate": "4896.297995"
  },
  {
      "Date": "1/9/02",
      "Rate": "4760.85998"
  },
  {
      "Date": "1/8/02",
      "Rate": "4737.335755"
  },
  {
      "Date": "1/7/02",
      "Rate": "4760.052206"
  },
  {
      "Date": "1/4/02",
      "Rate": "4749.120796"
  },
  {
      "Date": "1/3/02",
      "Rate": "4744.013122"
  },
  {
      "Date": "1/2/02",
      "Rate": "4733.670788"
  },
  {
      "Date": "12/31/01",
      "Rate": "4705.45865"
  },
  {
      "Date": "12/28/01",
      "Rate": "4726.136263"
  },
  {
      "Date": "12/27/01",
      "Rate": "4724.972222"
  },
  {
      "Date": "12/24/01",
      "Rate": "4693.557206"
  },
  {
      "Date": "12/21/01",
      "Rate": "4685.938024"
  },
  {
      "Date": "12/20/01",
      "Rate": "4657.355509"
  },
  {
      "Date": "12/19/01",
      "Rate": "4700.065257"
  },
  {
      "Date": "12/18/01",
      "Rate": "4704.139403"
  },
  {
      "Date": "12/17/01",
      "Rate": "4686.428332"
  },
  {
      "Date": "12/14/01",
      "Rate": "4635.20697"
  },
  {
      "Date": "12/13/01",
      "Rate": "4624.377855"
  },
  {
      "Date": "12/12/01",
      "Rate": "4609.188875"
  },
  {
      "Date": "12/11/01",
      "Rate": "4596.299758"
  },
  {
      "Date": "12/10/01",
      "Rate": "4612.321205"
  },
  {
      "Date": "12/7/01",
      "Rate": "4633.520873"
  },
  {
      "Date": "12/6/01",
      "Rate": "4626.427274"
  },
  {
      "Date": "12/5/01",
      "Rate": "4658.166811"
  },
  {
      "Date": "12/4/01",
      "Rate": "4670.703187"
  },
  {
      "Date": "12/3/01",
      "Rate": "4648.748655"
  },
  {
      "Date": "11/30/01",
      "Rate": "4644.304132"
  },
  {
      "Date": "11/29/01",
      "Rate": "4647.817422"
  },
  {
      "Date": "11/28/01",
      "Rate": "4648.910916"
  },
  {
      "Date": "11/27/01",
      "Rate": "4630.413235"
  },
  {
      "Date": "11/26/01",
      "Rate": "4623.15032"
  },
  {
      "Date": "11/23/01",
      "Rate": "4616.127269"
  },
  {
      "Date": "11/22/01",
      "Rate": "4628.099261"
  },
  {
      "Date": "11/21/01",
      "Rate": "4622.917512"
  },
  {
      "Date": "11/20/01",
      "Rate": "4633.072894"
  },
  {
      "Date": "11/19/01",
      "Rate": "4638.268753"
  },
  {
      "Date": "11/16/01",
      "Rate": "4662.688936"
  },
  {
      "Date": "11/15/01",
      "Rate": "4671.997742"
  },
  {
      "Date": "11/14/01",
      "Rate": "4705.21526"
  },
  {
      "Date": "11/13/01",
      "Rate": "4706.911938"
  },
  {
      "Date": "11/12/01",
      "Rate": "4700.90125"
  },
  {
      "Date": "11/9/01",
      "Rate": "4695.109261"
  },
  {
      "Date": "11/8/01",
      "Rate": "4745.692164"
  },
  {
      "Date": "11/7/01",
      "Rate": "4748.302439"
  },
  {
      "Date": "11/6/01",
      "Rate": "4721.215542"
  },
  {
      "Date": "11/5/01",
      "Rate": "4720.587665"
  },
  {
      "Date": "11/2/01",
      "Rate": "4743.222985"
  },
  {
      "Date": "11/1/01",
      "Rate": "4731.51202"
  },
  {
      "Date": "10/31/01",
      "Rate": "4756.415457"
  },
  {
      "Date": "10/30/01",
      "Rate": "4742.235313"
  },
  {
      "Date": "10/29/01",
      "Rate": "4698.943544"
  },
  {
      "Date": "10/26/01",
      "Rate": "4689.670012"
  },
  {
      "Date": "10/25/01",
      "Rate": "4663.062841"
  },
  {
      "Date": "10/24/01",
      "Rate": "4678.558705"
  },
  {
      "Date": "10/23/01",
      "Rate": "4653.99037"
  },
  {
      "Date": "10/22/01",
      "Rate": "4699.34214"
  },
  {
      "Date": "10/19/01",
      "Rate": "4722.746433"
  },
  {
      "Date": "10/18/01",
      "Rate": "4782.553484"
  },
  {
      "Date": "10/17/01",
      "Rate": "4769.258717"
  },
  {
      "Date": "10/16/01",
      "Rate": "4761.988748"
  },
  {
      "Date": "10/15/01",
      "Rate": "4807.319353"
  },
  {
      "Date": "10/12/01",
      "Rate": "4792.073934"
  },
  {
      "Date": "10/11/01",
      "Rate": "4785.096739"
  },
  {
      "Date": "10/10/01",
      "Rate": "4854.921604"
  },
  {
      "Date": "10/9/01",
      "Rate": "4941.600381"
  },
  {
      "Date": "10/8/01",
      "Rate": "4971.823136"
  },
  {
      "Date": "10/5/01",
      "Rate": "4937.229934"
  },
  {
      "Date": "10/4/01",
      "Rate": "4893.197411"
  },
  {
      "Date": "10/3/01",
      "Rate": "4900.597894"
  },
  {
      "Date": "10/2/01",
      "Rate": "4926.711229"
  },
  {
      "Date": "10/1/01",
      "Rate": "4936.535036"
  },
  {
      "Date": "9/28/01",
      "Rate": "4910.167728"
  },
  {
      "Date": "9/27/01",
      "Rate": "4916.407697"
  },
  {
      "Date": "9/26/01",
      "Rate": "4924.372564"
  },
  {
      "Date": "9/25/01",
      "Rate": "4862.734793"
  },
  {
      "Date": "9/24/01",
      "Rate": "4893.994603"
  },
  {
      "Date": "9/21/01",
      "Rate": "4933.691952"
  },
  {
      "Date": "9/20/01",
      "Rate": "4878.138944"
  },
  {
      "Date": "9/19/01",
      "Rate": "4900.664915"
  },
  {
      "Date": "9/18/01",
      "Rate": "4892.372"
  },
  {
      "Date": "9/17/01",
      "Rate": "4908.083035"
  },
  {
      "Date": "9/14/01",
      "Rate": "4798.451472"
  },
  {
      "Date": "9/13/01",
      "Rate": "4672.202332"
  },
  {
      "Date": "9/12/01",
      "Rate": "4676.653909"
  },
  {
      "Date": "9/11/01",
      "Rate": "4538.729078"
  },
  {
      "Date": "9/10/01",
      "Rate": "4561.124535"
  },
  {
      "Date": "9/7/01",
      "Rate": "4549.688707"
  },
  {
      "Date": "9/6/01",
      "Rate": "4527.176846"
  },
  {
      "Date": "9/5/01",
      "Rate": "4518.83102"
  },
  {
      "Date": "9/4/01",
      "Rate": "4526.53486"
  },
  {
      "Date": "9/3/01",
      "Rate": "4549.596995"
  },
  {
      "Date": "8/31/01",
      "Rate": "4576.620399"
  },
  {
      "Date": "8/30/01",
      "Rate": "4546.61987"
  },
  {
      "Date": "8/29/01",
      "Rate": "4535.550892"
  },
  {
      "Date": "8/28/01",
      "Rate": "4516.686361"
  },
  {
      "Date": "8/24/01",
      "Rate": "4573.780843"
  },
  {
      "Date": "8/23/01",
      "Rate": "4559.653609"
  },
  {
      "Date": "8/22/01",
      "Rate": "4609.178292"
  },
  {
      "Date": "8/21/01",
      "Rate": "4602.236371"
  },
  {
      "Date": "8/20/01",
      "Rate": "4617.492372"
  },
  {
      "Date": "8/17/01",
      "Rate": "4592.066879"
  },
  {
      "Date": "8/16/01",
      "Rate": "4571.981869"
  },
  {
      "Date": "8/15/01",
      "Rate": "4593.728284"
  },
  {
      "Date": "8/14/01",
      "Rate": "4555.360765"
  },
  {
      "Date": "8/13/01",
      "Rate": "4572.951904"
  },
  {
      "Date": "8/10/01",
      "Rate": "4544.344697"
  },
  {
      "Date": "8/9/01",
      "Rate": "4472.498633"
  },
  {
      "Date": "8/8/01",
      "Rate": "4429.993474"
  },
  {
      "Date": "8/7/01",
      "Rate": "4432.377996"
  },
  {
      "Date": "8/6/01",
      "Rate": "4451.376567"
  },
  {
      "Date": "8/3/01",
      "Rate": "4446.145435"
  },
  {
      "Date": "8/2/01",
      "Rate": "4456.604173"
  },
  {
      "Date": "8/1/01",
      "Rate": "4448.999101"
  },
  {
      "Date": "7/31/01",
      "Rate": "4415.139597"
  },
  {
      "Date": "7/30/01",
      "Rate": "4430.212173"
  },
  {
      "Date": "7/27/01",
      "Rate": "4420.127339"
  },
  {
      "Date": "7/26/01",
      "Rate": "4443.626872"
  },
  {
      "Date": "7/25/01",
      "Rate": "4476.657437"
  },
  {
      "Date": "7/24/01",
      "Rate": "4467.990617"
  },
  {
      "Date": "7/23/01",
      "Rate": "4473.807298"
  },
  {
      "Date": "7/20/01",
      "Rate": "4490.311998"
  },
  {
      "Date": "7/19/01",
      "Rate": "4513.462319"
  },
  {
      "Date": "7/18/01",
      "Rate": "4458.008078"
  },
  {
      "Date": "7/17/01",
      "Rate": "4435.813683"
  },
  {
      "Date": "7/16/01",
      "Rate": "4456.819344"
  },
  {
      "Date": "7/13/01",
      "Rate": "4432.092277"
  },
  {
      "Date": "7/12/01",
      "Rate": "4450.279546"
  },
  {
      "Date": "7/11/01",
      "Rate": "4433.64786"
  },
  {
      "Date": "7/10/01",
      "Rate": "4443.044851"
  },
  {
      "Date": "7/9/01",
      "Rate": "4437.224642"
  },
  {
      "Date": "7/6/01",
      "Rate": "4409.319388"
  },
  {
      "Date": "7/5/01",
      "Rate": "4447.919716"
  },
  {
      "Date": "7/4/01",
      "Rate": "4446.865024"
  },
  {
      "Date": "7/3/01",
      "Rate": "4464.427944"
  },
  {
      "Date": "7/2/01",
      "Rate": "4473.687367"
  },
  {
      "Date": "6/29/01",
      "Rate": "4478.421136"
  },
  {
      "Date": "6/28/01",
      "Rate": "4501.264573"
  },
  {
      "Date": "6/27/01",
      "Rate": "4574.768514"
  },
  {
      "Date": "6/26/01",
      "Rate": "4542.030724"
  },
  {
      "Date": "6/25/01",
      "Rate": "4528.626607"
  },
  {
      "Date": "6/22/01",
      "Rate": "4529.321505"
  },
  {
      "Date": "6/21/01",
      "Rate": "4516.838039"
  },
  {
      "Date": "6/20/01",
      "Rate": "4512.213619"
  },
  {
      "Date": "6/19/01",
      "Rate": "4536.92305"
  },
  {
      "Date": "6/18/01",
      "Rate": "4498.266283"
  },
  {
      "Date": "6/15/01",
      "Rate": "4548.387097"
  },
  {
      "Date": "6/14/01",
      "Rate": "4510.344098"
  },
  {
      "Date": "6/13/01",
      "Rate": "4509.945502"
  },
  {
      "Date": "6/12/01",
      "Rate": "4444.290023"
  },
  {
      "Date": "6/11/01",
      "Rate": "4521.861056"
  },
  {
      "Date": "6/8/01",
      "Rate": "4428.794159"
  },
  {
      "Date": "6/7/01",
      "Rate": "4411.280622"
  },
  {
      "Date": "6/6/01",
      "Rate": "4423.351382"
  },
  {
      "Date": "6/5/01",
      "Rate": "4413.379425"
  },
  {
      "Date": "6/4/01",
      "Rate": "4415.506446"
  },
  {
      "Date": "6/1/01",
      "Rate": "4405.555654"
  },
  {
      "Date": "5/31/01",
      "Rate": "4411.612198"
  },
  {
      "Date": "5/30/01",
      "Rate": "4533.402706"
  },
  {
      "Date": "5/29/01",
      "Rate": "4582.61345"
  },
  {
      "Date": "5/25/01",
      "Rate": "4649.369477"
  },
  {
      "Date": "5/24/01",
      "Rate": "4696.753029"
  },
  {
      "Date": "5/23/01",
      "Rate": "4702.880121"
  },
  {
      "Date": "5/22/01",
      "Rate": "4688.647066"
  },
  {
      "Date": "5/21/01",
      "Rate": "4777.946701"
  },
  {
      "Date": "5/18/01",
      "Rate": "4504.365156"
  },
  {
      "Date": "5/17/01",
      "Rate": "4500.968271"
  },
  {
      "Date": "5/16/01",
      "Rate": "4458.974585"
  },
  {
      "Date": "5/15/01",
      "Rate": "4442.995467"
  },
  {
      "Date": "5/14/01",
      "Rate": "4433.418579"
  },
  {
      "Date": "5/11/01",
      "Rate": "4447.951463"
  },
  {
      "Date": "5/10/01",
      "Rate": "4452.544137"
  },
  {
      "Date": "5/9/01",
      "Rate": "4380.320641"
  },
  {
      "Date": "5/8/01",
      "Rate": "4386.927459"
  },
  {
      "Date": "5/4/01",
      "Rate": "4390.232632"
  },
  {
      "Date": "5/3/01",
      "Rate": "4381.971463"
  },
  {
      "Date": "5/2/01",
      "Rate": "4377.018995"
  },
  {
      "Date": "5/1/01",
      "Rate": "4356.732923"
  },
  {
      "Date": "4/30/01",
      "Rate": "4351.879222"
  },
  {
      "Date": "4/27/01",
      "Rate": "4370.264026"
  },
  {
      "Date": "4/26/01",
      "Rate": "4334.46798"
  },
  {
      "Date": "4/25/01",
      "Rate": "4361.537241"
  },
  {
      "Date": "4/24/01",
      "Rate": "4353.325455"
  },
  {
      "Date": "4/23/01",
      "Rate": "4339.550962"
  },
  {
      "Date": "4/20/01",
      "Rate": "4362.404981"
  },
  {
      "Date": "4/19/01",
      "Rate": "4319.035609"
  },
  {
      "Date": "4/18/01",
      "Rate": "4299.296284"
  },
  {
      "Date": "4/17/01",
      "Rate": "4332.71839"
  },
  {
      "Date": "4/12/01",
      "Rate": "4287.722888"
  },
  {
      "Date": "4/11/01",
      "Rate": "4256.978077"
  },
  {
      "Date": "4/10/01",
      "Rate": "4221.22436"
  },
  {
      "Date": "4/9/01",
      "Rate": "4284.449461"
  },
  {
      "Date": "4/6/01",
      "Rate": "4238.469814"
  },
  {
      "Date": "4/5/01",
      "Rate": "4241.475158"
  },
  {
      "Date": "4/4/01",
      "Rate": "4260.18801"
  },
  {
      "Date": "4/3/01",
      "Rate": "4226.751795"
  },
  {
      "Date": "4/2/01",
      "Rate": "4236.702587"
  },
  {
      "Date": "3/30/01",
      "Rate": "4259.553079"
  },
  {
      "Date": "3/29/01",
      "Rate": "4279.60987"
  },
  {
      "Date": "3/28/01",
      "Rate": "4294.904672"
  },
  {
      "Date": "3/27/01",
      "Rate": "4321.550645"
  },
  {
      "Date": "3/26/01",
      "Rate": "4299.77601"
  },
  {
      "Date": "3/23/01",
      "Rate": "4320.393658"
  },
  {
      "Date": "3/22/01",
      "Rate": "4320.033863"
  },
  {
      "Date": "3/21/01",
      "Rate": "4301.814847"
  },
  {
      "Date": "3/20/01",
      "Rate": "4325.564825"
  },
  {
      "Date": "3/19/01",
      "Rate": "4290.745869"
  },
  {
      "Date": "3/16/01",
      "Rate": "4286.721106"
  },
  {
      "Date": "3/15/01",
      "Rate": "4321.885748"
  },
  {
      "Date": "3/14/01",
      "Rate": "4401.446234"
  },
  {
      "Date": "3/13/01",
      "Rate": "4432.586113"
  },
  {
      "Date": "3/12/01",
      "Rate": "4443.051906"
  },
  {
      "Date": "3/9/01",
      "Rate": "4411.795622"
  },
  {
      "Date": "3/8/01",
      "Rate": "4325.998695"
  },
  {
      "Date": "3/7/01",
      "Rate": "4292.816452"
  },
  {
      "Date": "3/6/01",
      "Rate": "4308.661528"
  },
  {
      "Date": "3/5/01",
      "Rate": "4298.20279"
  },
  {
      "Date": "3/2/01",
      "Rate": "4324.217358"
  },
  {
      "Date": "3/1/01",
      "Rate": "4378.754475"
  },
  {
      "Date": "2/28/01",
      "Rate": "4351.311311"
  },
  {
      "Date": "2/27/01",
      "Rate": "4411.195965"
  },
  {
      "Date": "2/26/01",
      "Rate": "4323.067426"
  },
  {
      "Date": "2/23/01",
      "Rate": "4273.433394"
  },
  {
      "Date": "2/22/01",
      "Rate": "4238.646184"
  },
  {
      "Date": "2/21/01",
      "Rate": "4230.776557"
  },
  {
      "Date": "2/20/01",
      "Rate": "4244.028995"
  },
  {
      "Date": "2/19/01",
      "Rate": "4251.147287"
  },
  {
      "Date": "2/16/01",
      "Rate": "4213.665144"
  },
  {
      "Date": "2/15/01",
      "Rate": "4261.457874"
  },
  {
      "Date": "2/14/01",
      "Rate": "4291.811143"
  },
  {
      "Date": "2/13/01",
      "Rate": "4294.301487"
  },
  {
      "Date": "2/12/01",
      "Rate": "4277.436992"
  },
  {
      "Date": "2/9/01",
      "Rate": "4269.380412"
  },
  {
      "Date": "2/8/01",
      "Rate": "4312.030195"
  },
  {
      "Date": "2/7/01",
      "Rate": "4305.236424"
  },
  {
      "Date": "2/6/01",
      "Rate": "4328.058696"
  },
  {
      "Date": "2/5/01",
      "Rate": "4358.076862"
  },
  {
      "Date": "2/2/01",
      "Rate": "4387.336637"
  },
  {
      "Date": "2/1/01",
      "Rate": "4367.569093"
  },
  {
      "Date": "1/31/01",
      "Rate": "4356.930457"
  },
  {
      "Date": "1/30/01",
      "Rate": "4313.423517"
  },
  {
      "Date": "1/29/01",
      "Rate": "4315.007319"
  },
  {
      "Date": "1/26/01",
      "Rate": "4336.57031"
  },
  {
      "Date": "1/25/01",
      "Rate": "4322.742906"
  },
  {
      "Date": "1/24/01",
      "Rate": "4345.46641"
  },
  {
      "Date": "1/23/01",
      "Rate": "4366.479127"
  },
  {
      "Date": "1/22/01",
      "Rate": "4352.009736"
  },
  {
      "Date": "1/19/01",
      "Rate": "4347.223055"
  },
  {
      "Date": "1/18/01",
      "Rate": "4323.025097"
  },
  {
      "Date": "1/17/01",
      "Rate": "4309.903173"
  },
  {
      "Date": "1/16/01",
      "Rate": "4337.970687"
  },
  {
      "Date": "1/15/01",
      "Rate": "4324.217358"
  },
  {
      "Date": "1/12/01",
      "Rate": "4329.145135"
  },
  {
      "Date": "1/11/01",
      "Rate": "4355.032717"
  },
  {
      "Date": "1/10/01",
      "Rate": "4385.809274"
  },
  {
      "Date": "1/9/01",
      "Rate": "4403.548563"
  },
  {
      "Date": "1/8/01",
      "Rate": "4422.864601"
  },
  {
      "Date": "1/5/01",
      "Rate": "4416.169597"
  },
  {
      "Date": "1/4/01",
      "Rate": "4429.474947"
  },
  {
      "Date": "1/3/01",
      "Rate": "4432.173407"
  },
  {
      "Date": "1/2/01",
      "Rate": "4493.342034"
  },
  {
      "Date": "12/29/00",
      "Rate": "4489.426621"
  },
  {
      "Date": "12/28/00",
      "Rate": "4522.989823"
  },
  {
      "Date": "12/27/00",
      "Rate": "4528.026949"
  },
  {
      "Date": "12/22/00",
      "Rate": "4509.384645"
  },
  {
      "Date": "12/21/00",
      "Rate": "4496.393234"
  },
  {
      "Date": "12/20/00",
      "Rate": "4451.944479"
  },
  {
      "Date": "12/19/00",
      "Rate": "4437.210533"
  },
  {
      "Date": "12/18/00",
      "Rate": "4462.614861"
  },
  {
      "Date": "12/15/00",
      "Rate": "4457.884619"
  },
  {
      "Date": "12/14/00",
      "Rate": "4447.986737"
  },
  {
      "Date": "12/13/00",
      "Rate": "4453.062664"
  },
  {
      "Date": "12/12/00",
      "Rate": "4469.429796"
  },
  {
      "Date": "12/11/00",
      "Rate": "4495.126898"
  },
  {
      "Date": "12/8/00",
      "Rate": "4508.322898"
  },
  {
      "Date": "12/7/00",
      "Rate": "4538.016544"
  },
  {
      "Date": "12/6/00",
      "Rate": "4466.600822"
  },
  {
      "Date": "12/5/00",
      "Rate": "4477.694492"
  },
  {
      "Date": "12/4/00",
      "Rate": "4478.452883"
  },
  {
      "Date": "12/1/00",
      "Rate": "4482.770419"
  },
  {
      "Date": "11/30/00",
      "Rate": "4413.82035"
  },
  {
      "Date": "11/29/00",
      "Rate": "4447.355333"
  },
  {
      "Date": "11/28/00",
      "Rate": "4459.517805"
  },
  {
      "Date": "11/27/00",
      "Rate": "4398.246883"
  },
  {
      "Date": "11/24/00",
      "Rate": "4394.003422"
  },
  {
      "Date": "11/23/00",
      "Rate": "4391.742359"
  },
  {
      "Date": "11/22/00",
      "Rate": "4400.497363"
  },
  {
      "Date": "11/21/00",
      "Rate": "4410.882026"
  },
  {
      "Date": "11/20/00",
      "Rate": "4384.719307"
  },
  {
      "Date": "11/17/00",
      "Rate": "4396.952327"
  },
  {
      "Date": "11/16/00",
      "Rate": "4385.029718"
  },
  {
      "Date": "11/15/00",
      "Rate": "4374.130055"
  },
  {
      "Date": "11/14/00",
      "Rate": "4363.628988"
  },
  {
      "Date": "11/13/00",
      "Rate": "4361.258576"
  },
  {
      "Date": "11/10/00",
      "Rate": "4370.609711"
  },
  {
      "Date": "11/9/00",
      "Rate": "4356.133265"
  },
  {
      "Date": "11/8/00",
      "Rate": "4362.52844"
  },
  {
      "Date": "11/7/00",
      "Rate": "4347.247747"
  },
  {
      "Date": "11/6/00",
      "Rate": "4368.313374"
  },
  {
      "Date": "11/3/00",
      "Rate": "4381.699854"
  },
  {
      "Date": "11/2/00",
      "Rate": "4380.031394"
  },
  {
      "Date": "11/1/00",
      "Rate": "4391.894037"
  },
  {
      "Date": "10/31/00",
      "Rate": "4340.912538"
  },
  {
      "Date": "10/30/00",
      "Rate": "4373.696185"
  },
  {
      "Date": "10/27/00",
      "Rate": "4387.322528"
  },
  {
      "Date": "10/26/00",
      "Rate": "4388.514789"
  },
  {
      "Date": "10/25/00",
      "Rate": "4426.903473"
  },
  {
      "Date": "10/24/00",
      "Rate": "4427.104534"
  },
  {
      "Date": "10/23/00",
      "Rate": "4438.628547"
  },
  {
      "Date": "10/20/00",
      "Rate": "4439.365774"
  },
  {
      "Date": "10/19/00",
      "Rate": "4421.344292"
  },
  {
      "Date": "10/18/00",
      "Rate": "4433.559675"
  },
  {
      "Date": "10/17/00",
      "Rate": "4418.585866"
  },
  {
      "Date": "10/16/00",
      "Rate": "4446.441736"
  },
  {
      "Date": "10/13/00",
      "Rate": "4511.469338"
  },
  {
      "Date": "10/12/00",
      "Rate": "4417.174906"
  },
  {
      "Date": "10/11/00",
      "Rate": "4460.043387"
  },
  {
      "Date": "10/10/00",
      "Rate": "4396.479656"
  },
  {
      "Date": "10/9/00",
      "Rate": "4393.428456"
  },
  {
      "Date": "10/6/00",
      "Rate": "4403.086474"
  },
  {
      "Date": "10/5/00",
      "Rate": "4410.352916"
  },
  {
      "Date": "10/4/00",
      "Rate": "4407.386374"
  },
  {
      "Date": "10/3/00",
      "Rate": "4422.374292"
  },
  {
      "Date": "10/2/00",
      "Rate": "4453.221397"
  },
  {
      "Date": "9/29/00",
      "Rate": "4450.93917"
  },
  {
      "Date": "9/28/00",
      "Rate": "4504.700259"
  },
  {
      "Date": "9/27/00",
      "Rate": "4465.373287"
  },
  {
      "Date": "9/26/00",
      "Rate": "4455.443659"
  },
  {
      "Date": "9/25/00",
      "Rate": "4440.078308"
  },
  {
      "Date": "9/22/00",
      "Rate": "4438.374575"
  },
  {
      "Date": "9/21/00",
      "Rate": "4365.83714"
  },
  {
      "Date": "9/20/00",
      "Rate": "4426.610699"
  },
  {
      "Date": "9/19/00",
      "Rate": "4449.46119"
  },
  {
      "Date": "9/18/00",
      "Rate": "4420.783435"
  },
  {
      "Date": "9/15/00",
      "Rate": "4408.024833"
  },
  {
      "Date": "9/14/00",
      "Rate": "4393.745921"
  },
  {
      "Date": "9/13/00",
      "Rate": "4391.946948"
  },
  {
      "Date": "9/12/00",
      "Rate": "4390.856982"
  },
  {
      "Date": "9/11/00",
      "Rate": "4386.804"
  },
  {
      "Date": "9/8/00",
      "Rate": "4414.441172"
  },
  {
      "Date": "9/7/00",
      "Rate": "4390.38431"
  },
  {
      "Date": "9/6/00",
      "Rate": "4431.340941"
  },
  {
      "Date": "9/5/00",
      "Rate": "4454.840473"
  },
  {
      "Date": "9/4/00",
      "Rate": "4466.76661"
  },
  {
      "Date": "9/1/00",
      "Rate": "4465.19339"
  },
  {
      "Date": "8/31/00",
      "Rate": "4442.434611"
  },
  {
      "Date": "8/30/00",
      "Rate": "4403.985961"
  },
  {
      "Date": "8/29/00",
      "Rate": "4426.603644"
  },
  {
      "Date": "8/25/00",
      "Rate": "4430.564913"
  },
  {
      "Date": "8/24/00",
      "Rate": "4404.36692"
  },
  {
      "Date": "8/23/00",
      "Rate": "4400.119932"
  },
  {
      "Date": "8/22/00",
      "Rate": "4417.905078"
  },
  {
      "Date": "8/21/00",
      "Rate": "4467.084076"
  },
  {
      "Date": "8/18/00",
      "Rate": "4479.204219"
  },
  {
      "Date": "8/17/00",
      "Rate": "4489.22556"
  },
  {
      "Date": "8/16/00",
      "Rate": "4443.549269"
  },
  {
      "Date": "8/15/00",
      "Rate": "4430.646043"
  },
  {
      "Date": "8/14/00",
      "Rate": "4444.19831"
  },
  {
      "Date": "8/11/00",
      "Rate": "4412.871479"
  },
  {
      "Date": "8/10/00",
      "Rate": "4406.366955"
  },
  {
      "Date": "8/9/00",
      "Rate": "4381.851532"
  },
  {
      "Date": "8/8/00",
      "Rate": "4420.564737"
  },
  {
      "Date": "8/7/00",
      "Rate": "4396.33856"
  },
  {
      "Date": "8/4/00",
      "Rate": "4379.625743"
  },
  {
      "Date": "8/3/00",
      "Rate": "4425.989876"
  },
  {
      "Date": "8/2/00",
      "Rate": "4434.476799"
  },
  {
      "Date": "8/1/00",
      "Rate": "4416.825694"
  },
  {
      "Date": "7/31/00",
      "Rate": "4407.167675"
  },
  {
      "Date": "7/28/00",
      "Rate": "4407.887264"
  },
  {
      "Date": "7/27/00",
      "Rate": "4433.365668"
  },
  {
      "Date": "7/26/00",
      "Rate": "4432.57553"
  },
  {
      "Date": "7/25/00",
      "Rate": "4415.093741"
  },
  {
      "Date": "7/24/00",
      "Rate": "4420.127339"
  },
  {
      "Date": "7/21/00",
      "Rate": "4451.066156"
  },
  {
      "Date": "7/20/00",
      "Rate": "4403.590892"
  },
  {
      "Date": "7/19/00",
      "Rate": "4450.731053"
  },
  {
      "Date": "7/18/00",
      "Rate": "4461.045168"
  },
  {
      "Date": "7/17/00",
      "Rate": "4454.230233"
  },
  {
      "Date": "7/14/00",
      "Rate": "4425.143301"
  },
  {
      "Date": "7/13/00",
      "Rate": "4425.637136"
  },
  {
      "Date": "7/12/00",
      "Rate": "4459.080407"
  },
  {
      "Date": "7/11/00",
      "Rate": "4469.253426"
  },
  {
      "Date": "7/10/00",
      "Rate": "4478.396444"
  },
  {
      "Date": "7/7/00",
      "Rate": "4482.833912"
  },
  {
      "Date": "7/6/00",
      "Rate": "4505.575054"
  },
  {
      "Date": "7/5/00",
      "Rate": "4535.032364"
  },
  {
      "Date": "7/4/00",
      "Rate": "4534.326884"
  },
  {
      "Date": "7/3/00",
      "Rate": "4538.771407"
  },
  {
      "Date": "6/30/00",
      "Rate": "4557.635937"
  },
  {
      "Date": "6/29/00",
      "Rate": "4582.736909"
  },
  {
      "Date": "6/28/00",
      "Rate": "4510.975502"
  },
  {
      "Date": "6/27/00",
      "Rate": "4477.105416"
  },
  {
      "Date": "6/26/00",
      "Rate": "4458.202085"
  },
  {
      "Date": "6/23/00",
      "Rate": "4496.830632"
  },
  {
      "Date": "6/22/00",
      "Rate": "4507.038925"
  },
  {
      "Date": "6/21/00",
      "Rate": "4515.420025"
  },
  {
      "Date": "6/20/00",
      "Rate": "4509.74444"
  },
  {
      "Date": "6/19/00",
      "Rate": "4538.037708"
  },
  {
      "Date": "6/16/00",
      "Rate": "4577.29766"
  },
  {
      "Date": "6/15/00",
      "Rate": "4559.166828"
  },
  {
      "Date": "6/14/00",
      "Rate": "4512.305332"
  },
  {
      "Date": "6/13/00",
      "Rate": "4625.037479"
  },
  {
      "Date": "6/12/00",
      "Rate": "4491.31378"
  },
  {
      "Date": "6/9/00",
      "Rate": "4502.633203"
  },
  {
      "Date": "6/8/00",
      "Rate": "4502.848375"
  },
  {
      "Date": "6/7/00",
      "Rate": "4530.044622"
  },
  {
      "Date": "6/6/00",
      "Rate": "4488.065045"
  },
  {
      "Date": "6/5/00",
      "Rate": "4436.730807"
  },
  {
      "Date": "6/2/00",
      "Rate": "4296.992892"
  },
  {
      "Date": "6/1/00",
      "Rate": "4290.301416"
  },
  {
      "Date": "5/31/00",
      "Rate": "4280.689254"
  },
  {
      "Date": "5/30/00",
      "Rate": "4315.462354"
  },
  {
      "Date": "5/26/00",
      "Rate": "4235.693751"
  },
  {
      "Date": "5/25/00",
      "Rate": "4243.372899"
  },
  {
      "Date": "5/24/00",
      "Rate": "4266.537329"
  },
  {
      "Date": "5/23/00",
      "Rate": "4274.364627"
  },
  {
      "Date": "5/22/00",
      "Rate": "4251.665814"
  },
  {
      "Date": "5/19/00",
      "Rate": "4246.822695"
  },
  {
      "Date": "5/18/00",
      "Rate": "4227.429055"
  },
  {
      "Date": "5/17/00",
      "Rate": "4259.521332"
  },
  {
      "Date": "5/16/00",
      "Rate": "4275.613327"
  },
  {
      "Date": "5/15/00",
      "Rate": "4297.201009"
  },
  {
      "Date": "5/12/00",
      "Rate": "4279.472301"
  },
  {
      "Date": "5/11/00",
      "Rate": "4290.643574"
  },
  {
      "Date": "5/10/00",
      "Rate": "4316.509991"
  },
  {
      "Date": "5/9/00",
      "Rate": "4267.853049"
  },
  {
      "Date": "5/8/00",
      "Rate": "4271.045345"
  },
  {
      "Date": "5/5/00",
      "Rate": "4318.143177"
  },
  {
      "Date": "5/4/00",
      "Rate": "4270.593838"
  },
  {
      "Date": "5/3/00",
      "Rate": "4275.701511"
  },
  {
      "Date": "5/2/00",
      "Rate": "4236.385121"
  },
  {
      "Date": "4/28/00",
      "Rate": "4248.018484"
  },
  {
      "Date": "4/27/00",
      "Rate": "4251.507081"
  },
  {
      "Date": "4/26/00",
      "Rate": "4277.733293"
  },
  {
      "Date": "4/25/00",
      "Rate": "4311.187146"
  },
  {
      "Date": "4/20/00",
      "Rate": "4320.319582"
  },
  {
      "Date": "4/19/00",
      "Rate": "4333.836576"
  },
  {
      "Date": "4/18/00",
      "Rate": "4340.492778"
  },
  {
      "Date": "4/17/00",
      "Rate": "4366.676661"
  },
  {
      "Date": "4/14/00",
      "Rate": "4322.23496"
  },
  {
      "Date": "4/13/00",
      "Rate": "4332.245719"
  },
  {
      "Date": "4/12/00",
      "Rate": "4314.047867"
  },
  {
      "Date": "4/11/00",
      "Rate": "4329.663662"
  },
  {
      "Date": "4/10/00",
      "Rate": "4333.289829"
  },
  {
      "Date": "4/7/00",
      "Rate": "4307.948994"
  },
  {
      "Date": "4/6/00",
      "Rate": "4293.116281"
  },
  {
      "Date": "4/5/00",
      "Rate": "4356.422512"
  },
  {
      "Date": "4/4/00",
      "Rate": "4258.706503"
  },
  {
      "Date": "4/3/00",
      "Rate": "4288.975114"
  },
  {
      "Date": "3/31/00",
      "Rate": "4243.679783"
  },
  {
      "Date": "3/30/00",
      "Rate": "4255.73996"
  },
  {
      "Date": "3/29/00",
      "Rate": "4291.670047"
  },
  {
      "Date": "3/28/00",
      "Rate": "4313.215401"
  },
  {
      "Date": "3/27/00",
      "Rate": "4374.15122"
  },
  {
      "Date": "3/24/00",
      "Rate": "4385.005027"
  },
  {
      "Date": "3/23/00",
      "Rate": "4404.698496"
  },
  {
      "Date": "3/22/00",
      "Rate": "4452.858075"
  },
  {
      "Date": "3/21/00",
      "Rate": "4396.543149"
  },
  {
      "Date": "3/20/00",
      "Rate": "4378.32766"
  },
  {
      "Date": "3/17/00",
      "Rate": "4386.807527"
  },
  {
      "Date": "3/16/00",
      "Rate": "4432.892996"
  },
  {
      "Date": "3/15/00",
      "Rate": "4440.050089"
  },
  {
      "Date": "3/14/00",
      "Rate": "4464.022293"
  },
  {
      "Date": "3/13/00",
      "Rate": "4455.426022"
  },
  {
      "Date": "3/10/00",
      "Rate": "4490.382546"
  },
  {
      "Date": "3/9/00",
      "Rate": "4442.371118"
  },
  {
      "Date": "3/8/00",
      "Rate": "4502.336902"
  },
  {
      "Date": "3/7/00",
      "Rate": "4435.683169"
  },
  {
      "Date": "3/6/00",
      "Rate": "4448.762765"
  },
  {
      "Date": "3/3/00",
      "Rate": "4449.789238"
  },
  {
      "Date": "3/2/00",
      "Rate": "4445.051941"
  },
  {
      "Date": "3/1/00",
      "Rate": "4517.197834"
  },
  {
      "Date": "2/29/00",
      "Rate": "4525.709448"
  },
  {
      "Date": "2/28/00",
      "Rate": "4503.62793"
  },
  {
      "Date": "2/25/00",
      "Rate": "4572.04889"
  },
  {
      "Date": "2/24/00",
      "Rate": "4613.88737"
  },
  {
      "Date": "2/23/00",
      "Rate": "4697.811249"
  },
  {
      "Date": "2/22/00",
      "Rate": "4699.582003"
  },
  {
      "Date": "2/21/00",
      "Rate": "4702.485053"
  },
  {
      "Date": "2/18/00",
      "Rate": "4653.771671"
  },
  {
      "Date": "2/17/00",
      "Rate": "4714.552285"
  },
  {
      "Date": "2/16/00",
      "Rate": "4624.709431"
  },
  {
      "Date": "2/15/00",
      "Rate": "4723.120337"
  },
  {
      "Date": "2/14/00",
      "Rate": "4749.769837"
  },
  {
      "Date": "2/11/00",
      "Rate": "4874.576271"
  },
  {
      "Date": "2/10/00",
      "Rate": "4793.446093"
  },
  {
      "Date": "2/9/00",
      "Rate": "4708.813207"
  },
  {
      "Date": "2/8/00",
      "Rate": "4662.551368"
  },
  {
      "Date": "2/7/00",
      "Rate": "4873.04538"
  },
  {
      "Date": "2/4/00",
      "Rate": "4425.143301"
  },
  {
      "Date": "2/3/00",
      "Rate": "4390.864036"
  },
  {
      "Date": "2/2/00",
      "Rate": "4354.923367"
  },
  {
      "Date": "2/1/00",
      "Rate": "4361.63248"
  },
  {
      "Date": "1/31/00",
      "Rate": "4360.023986"
  },
  {
      "Date": "1/28/00",
      "Rate": "4404.871338"
  },
  {
      "Date": "1/27/00",
      "Rate": "4407.227641"
  },
  {
      "Date": "1/26/00",
      "Rate": "4382.109032"
  },
  {
      "Date": "1/25/00",
      "Rate": "4442.085398"
  },
  {
      "Date": "1/24/00",
      "Rate": "4424.882273"
  },
  {
      "Date": "1/21/00",
      "Rate": "4421.827545"
  },
  {
      "Date": "1/20/00",
      "Rate": "4436.039436"
  },
  {
      "Date": "1/19/00",
      "Rate": "4436.744916"
  },
  {
      "Date": "1/18/00",
      "Rate": "4412.610452"
  },
  {
      "Date": "1/17/00",
      "Rate": "4375.579816"
  },
  {
      "Date": "1/14/00",
      "Rate": "4362.055768"
  },
  {
      "Date": "1/13/00",
      "Rate": "4336.700824"
  },
  {
      "Date": "1/12/00",
      "Rate": "4334.379795"
  },
  {
      "Date": "1/11/00",
      "Rate": "4325.19092"
  },
  {
      "Date": "1/10/00",
      "Rate": "4324.443112"
  },
  {
      "Date": "1/7/00",
      "Rate": "4332.531438"
  },
  {
      "Date": "1/6/00",
      "Rate": "4306.291116"
  },
  {
      "Date": "1/5/00",
      "Rate": "4330.337396"
  },
  {
      "Date": "1/4/00",
      "Rate": "4333.049966"
  },
  {
      "Date": "12/30/99",
      "Rate": "4464.65017"
  },
  {
      "Date": "12/29/99",
      "Rate": "4466.071712"
  },
  {
      "Date": "12/24/99",
      "Rate": "4419.100866"
  },
  {
      "Date": "12/23/99",
      "Rate": "4400.130514"
  },
  {
      "Date": "12/22/99",
      "Rate": "4404.289317"
  },
  {
      "Date": "12/21/99",
      "Rate": "4370.71906"
  },
  {
      "Date": "12/20/99",
      "Rate": "4364.842413"
  },
  {
      "Date": "12/17/99",
      "Rate": "4342.623327"
  },
  {
      "Date": "12/16/99",
      "Rate": "4299.712517"
  },
  {
      "Date": "12/15/99",
      "Rate": "4299.994709"
  },
  {
      "Date": "12/14/99",
      "Rate": "4306.908411"
  },
  {
      "Date": "12/13/99",
      "Rate": "4295.991111"
  },
  {
      "Date": "12/10/99",
      "Rate": "4270.625584"
  },
  {
      "Date": "12/9/99",
      "Rate": "4286.043846"
  },
  {
      "Date": "12/8/99",
      "Rate": "4345.759184"
  },
  {
      "Date": "12/7/99",
      "Rate": "4296.223919"
  },
  {
      "Date": "12/6/99",
      "Rate": "4246.723928"
  },
  {
      "Date": "12/3/99",
      "Rate": "4304.213478"
  },
  {
      "Date": "12/2/99",
      "Rate": "4432.695462"
  },
  {
      "Date": "12/1/99",
      "Rate": "4465.489691"
  },
  {
      "Date": "11/30/99",
      "Rate": "4472.004797"
  },
  {
      "Date": "11/29/99",
      "Rate": "4486.523572"
  },
  {
      "Date": "11/26/99",
      "Rate": "4553.477134"
  },
  {
      "Date": "11/25/99",
      "Rate": "4569.184642"
  },
  {
      "Date": "11/24/99",
      "Rate": "4562.912926"
  },
  {
      "Date": "11/23/99",
      "Rate": "4543.723875"
  },
  {
      "Date": "11/22/99",
      "Rate": "4507.98074"
  },
  {
      "Date": "11/19/99",
      "Rate": "4494.777686"
  },
  {
      "Date": "11/18/99",
      "Rate": "4497.532584"
  },
  {
      "Date": "11/17/99",
      "Rate": "4523.540098"
  },
  {
      "Date": "11/16/99",
      "Rate": "4471.747297"
  },
  {
      "Date": "11/15/99",
      "Rate": "4483.377132"
  },
  {
      "Date": "11/12/99",
      "Rate": "4485.546482"
  },
  {
      "Date": "11/11/99",
      "Rate": "4534.743117"
  },
  {
      "Date": "11/10/99",
      "Rate": "4472.022434"
  },
  {
      "Date": "11/9/99",
      "Rate": "4462.830032"
  },
  {
      "Date": "11/8/99",
      "Rate": "4428.353234"
  },
  {
      "Date": "11/5/99",
      "Rate": "4475.454594"
  },
  {
      "Date": "11/4/99",
      "Rate": "4478.153054"
  },
  {
      "Date": "11/3/99",
      "Rate": "4469.461543"
  },
  {
      "Date": "11/2/99",
      "Rate": "4474.057744"
  },
  {
      "Date": "11/1/99",
      "Rate": "4496.587241"
  },
  {
      "Date": "10/29/99",
      "Rate": "4578.23242"
  },
  {
      "Date": "10/28/99",
      "Rate": "4605.527434"
  },
  {
      "Date": "10/27/99",
      "Rate": "4442.635673"
  },
  {
      "Date": "10/26/99",
      "Rate": "4564.443817"
  },
  {
      "Date": "10/25/99",
      "Rate": "4627.548987"
  },
  {
      "Date": "10/22/99",
      "Rate": "4642.427556"
  },
  {
      "Date": "10/21/99",
      "Rate": "4679.835623"
  },
  {
      "Date": "10/20/99",
      "Rate": "4743.028978"
  },
  {
      "Date": "10/19/99",
      "Rate": "4732.362123"
  },
  {
      "Date": "10/18/99",
      "Rate": "4862.315032"
  },
  {
      "Date": "10/15/99",
      "Rate": "4844.417009"
  },
  {
      "Date": "10/14/99",
      "Rate": "4945.110143"
  },
  {
      "Date": "10/13/99",
      "Rate": "4914.421771"
  },
  {
      "Date": "10/12/99",
      "Rate": "4960.990494"
  },
  {
      "Date": "10/11/99",
      "Rate": "4861.045168"
  },
  {
      "Date": "10/8/99",
      "Rate": "4947.212473"
  },
  {
      "Date": "10/7/99",
      "Rate": "4886.294291"
  },
  {
      "Date": "10/6/99",
      "Rate": "4996.031676"
  },
  {
      "Date": "10/5/99",
      "Rate": "5021.5771"
  },
  {
      "Date": "10/4/99",
      "Rate": "4796.747738"
  },
  {
      "Date": "10/1/99",
      "Rate": "4662.11397"
  },
  {
      "Date": "9/30/99",
      "Rate": "4674.45634"
  },
  {
      "Date": "9/29/99",
      "Rate": "4882.491755"
  },
  {
      "Date": "9/28/99",
      "Rate": "4434.145223"
  },
  {
      "Date": "9/27/99",
      "Rate": "4333.388596"
  },
  {
      "Date": "9/24/99",
      "Rate": "4085.140831"
  },
  {
      "Date": "9/23/99",
      "Rate": "4051.182561"
  },
  {
      "Date": "9/22/99",
      "Rate": "4038.508616"
  },
  {
      "Date": "9/21/99",
      "Rate": "3923.039207"
  },
  {
      "Date": "9/20/99",
      "Rate": "3923.674139"
  },
  {
      "Date": "9/17/99",
      "Rate": "3919.437733"
  },
  {
      "Date": "9/16/99",
      "Rate": "3929.822395"
  },
  {
      "Date": "9/15/99",
      "Rate": "3954.933949"
  },
  {
      "Date": "9/14/99",
      "Rate": "3943.607471"
  },
  {
      "Date": "9/13/99",
      "Rate": "3942.295279"
  },
  {
      "Date": "9/10/99",
      "Rate": "3932.986472"
  },
  {
      "Date": "9/9/99",
      "Rate": "3945.822678"
  },
  {
      "Date": "9/8/99",
      "Rate": "3931.272156"
  },
  {
      "Date": "9/7/99",
      "Rate": "3911.906736"
  },
  {
      "Date": "9/6/99",
      "Rate": "3908.986049"
  },
  {
      "Date": "9/3/99",
      "Rate": "3918.227835"
  },
  {
      "Date": "9/2/99",
      "Rate": "3893.708884"
  },
  {
      "Date": "9/1/99",
      "Rate": "3921.250816"
  },
  {
      "Date": "8/31/99",
      "Rate": "3907.751459"
  },
  {
      "Date": "8/27/99",
      "Rate": "3911.307078"
  },
  {
      "Date": "8/26/99",
      "Rate": "3885.719325"
  },
  {
      "Date": "8/25/99",
      "Rate": "3890.953985"
  },
  {
      "Date": "8/24/99",
      "Rate": "3901.836011"
  },
  {
      "Date": "8/23/99",
      "Rate": "3952.609393"
  },
  {
      "Date": "8/20/99",
      "Rate": "3974.849645"
  },
  {
      "Date": "8/19/99",
      "Rate": "3966.867141"
  },
  {
      "Date": "8/18/99",
      "Rate": "4005.816681"
  },
  {
      "Date": "8/17/99",
      "Rate": "3966.606113"
  },
  {
      "Date": "8/16/99",
      "Rate": "3970.666149"
  },
  {
      "Date": "8/13/99",
      "Rate": "3997.470855"
  },
  {
      "Date": "8/12/99",
      "Rate": "3991.640064"
  },
  {
      "Date": "8/11/99",
      "Rate": "3941.695621"
  },
  {
      "Date": "8/10/99",
      "Rate": "3937.110002"
  },
  {
      "Date": "8/9/99",
      "Rate": "3927.328524"
  },
  {
      "Date": "8/6/99",
      "Rate": "3914.668689"
  },
  {
      "Date": "8/5/99",
      "Rate": "3923.811707"
  },
  {
      "Date": "8/4/99",
      "Rate": "3939.321681"
  },
  {
      "Date": "8/3/99",
      "Rate": "3910.428755"
  },
  {
      "Date": "8/2/99",
      "Rate": "3901.476217"
  },
  {
      "Date": "7/30/99",
      "Rate": "3924.450167"
  },
  {
      "Date": "7/29/99",
      "Rate": "3887.140867"
  },
  {
      "Date": "7/28/99",
      "Rate": "3880.847987"
  },
  {
      "Date": "7/27/99",
      "Rate": "3883.006755"
  },
  {
      "Date": "7/26/99",
      "Rate": "3869.408632"
  },
  {
      "Date": "7/23/99",
      "Rate": "3891.003369"
  },
  {
      "Date": "7/22/99",
      "Rate": "3895.035186"
  },
  {
      "Date": "7/21/99",
      "Rate": "3856.01157"
  },
  {
      "Date": "7/20/99",
      "Rate": "3856.198522"
  },
  {
      "Date": "7/19/99",
      "Rate": "3860.494894"
  },
  {
      "Date": "7/16/99",
      "Rate": "3888.576518"
  },
  {
      "Date": "7/15/99",
      "Rate": "3872.329318"
  },
  {
      "Date": "7/14/99",
      "Rate": "3899.433852"
  },
  {
      "Date": "7/13/99",
      "Rate": "3877.045451"
  },
  {
      "Date": "7/12/99",
      "Rate": "3904.94365"
  },
  {
      "Date": "7/9/99",
      "Rate": "3928.044586"
  },
  {
      "Date": "7/8/99",
      "Rate": "3926.108044"
  },
  {
      "Date": "7/7/99",
      "Rate": "3906.329918"
  },
  {
      "Date": "7/6/99",
      "Rate": "3989.618865"
  },
  {
      "Date": "7/5/99",
      "Rate": "4022.557717"
  },
  {
      "Date": "7/2/99",
      "Rate": "4024.250869"
  },
  {
      "Date": "7/1/99",
      "Rate": "4023.926348"
  },
  {
      "Date": "6/30/99",
      "Rate": "3998.687808"
  },
  {
      "Date": "6/29/99",
      "Rate": "3992.564243"
  },
  {
      "Date": "6/28/99",
      "Rate": "3975.272933"
  },
  {
      "Date": "6/25/99",
      "Rate": "3990.553625"
  },
  {
      "Date": "6/24/99",
      "Rate": "3984.137286"
  },
  {
      "Date": "6/23/99",
      "Rate": "3947.184254"
  },
  {
      "Date": "6/22/99",
      "Rate": "3949.022029"
  },
  {
      "Date": "6/21/99",
      "Rate": "3945.364116"
  },
  {
      "Date": "6/18/99",
      "Rate": "3965.688989"
  },
  {
      "Date": "6/17/99",
      "Rate": "3943.54045"
  },
  {
      "Date": "6/16/99",
      "Rate": "3942.016614"
  },
  {
      "Date": "6/15/99",
      "Rate": "3947.484083"
  },
  {
      "Date": "6/14/99",
      "Rate": "3949.477063"
  },
  {
      "Date": "6/11/99",
      "Rate": "3937.896612"
  },
  {
      "Date": "6/10/99",
      "Rate": "3919.289582"
  },
  {
      "Date": "6/9/99",
      "Rate": "3982.285402"
  },
  {
      "Date": "6/8/99",
      "Rate": "4002.959488"
  },
  {
      "Date": "6/7/99",
      "Rate": "4038.304027"
  },
  {
      "Date": "6/4/99",
      "Rate": "4039.291698"
  },
  {
      "Date": "6/3/99",
      "Rate": "3997.841232"
  },
  {
      "Date": "6/2/99",
      "Rate": "4043.030741"
  },
  {
      "Date": "6/1/99",
      "Rate": "4023.309053"
  },
  {
      "Date": "5/28/99",
      "Rate": "4102.153477"
  },
  {
      "Date": "5/27/99",
      "Rate": "4066.39976"
  },
  {
      "Date": "5/26/99",
      "Rate": "4074.417538"
  },
  {
      "Date": "5/25/99",
      "Rate": "4114.347696"
  },
  {
      "Date": "5/24/99",
      "Rate": "4107.317589"
  },
  {
      "Date": "5/21/99",
      "Rate": "4148.030829"
  },
  {
      "Date": "5/20/99",
      "Rate": "4141.72384"
  },
  {
      "Date": "5/19/99",
      "Rate": "4116.883896"
  },
  {
      "Date": "5/18/99",
      "Rate": "4144.312951"
  },
  {
      "Date": "5/17/99",
      "Rate": "4161.537241"
  },
  {
      "Date": "5/14/99",
      "Rate": "4179.957318"
  },
  {
      "Date": "5/13/99",
      "Rate": "4196.941745"
  },
  {
      "Date": "5/12/99",
      "Rate": "4190.176194"
  },
  {
      "Date": "5/11/99",
      "Rate": "4194.451401"
  },
  {
      "Date": "5/10/99",
      "Rate": "4233.087003"
  },
  {
      "Date": "5/7/99",
      "Rate": "4272.946613"
  },
  {
      "Date": "5/6/99",
      "Rate": "4342.408155"
  },
  {
      "Date": "5/5/99",
      "Rate": "4315.307148"
  },
  {
      "Date": "5/4/99",
      "Rate": "4305.733787"
  },
  {
      "Date": "4/30/99",
      "Rate": "4315.554066"
  },
  {
      "Date": "4/29/99",
      "Rate": "4283.345385"
  },
  {
      "Date": "4/28/99",
      "Rate": "4270.505653"
  },
  {
      "Date": "4/27/99",
      "Rate": "4251.866876"
  },
  {
      "Date": "4/26/99",
      "Rate": "4276.033087"
  },
  {
      "Date": "4/23/99",
      "Rate": "4286.096757"
  },
  {
      "Date": "4/22/99",
      "Rate": "4267.817775"
  },
  {
      "Date": "4/21/99",
      "Rate": "4285.112612"
  },
  {
      "Date": "4/20/99",
      "Rate": "4291.913438"
  },
  {
      "Date": "4/19/99",
      "Rate": "4291.913438"
  },
  {
      "Date": "4/16/99",
      "Rate": "4278.10367"
  },
  {
      "Date": "4/15/99",
      "Rate": "4245.115434"
  },
  {
      "Date": "4/14/99",
      "Rate": "4280.354151"
  },
  {
      "Date": "4/13/99",
      "Rate": "4262.487875"
  },
  {
      "Date": "4/12/99",
      "Rate": "4255.03448"
  },
  {
      "Date": "4/9/99",
      "Rate": "4237.48567"
  },
  {
      "Date": "4/8/99",
      "Rate": "4221.404258"
  },
  {
      "Date": "4/7/99",
      "Rate": "4218.652886"
  },
  {
      "Date": "4/6/99",
      "Rate": "4187.597665"
  },
  {
      "Date": "4/1/99",
      "Rate": "4196.6913"
  },
  {
      "Date": "3/31/99",
      "Rate": "4187.696432"
  },
  {
      "Date": "3/30/99",
      "Rate": "4191.439002"
  },
  {
      "Date": "3/29/99",
      "Rate": "4193.950511"
  },
  {
      "Date": "3/26/99",
      "Rate": "4217.640523"
  },
  {
      "Date": "3/25/99",
      "Rate": "4243.813824"
  },
  {
      "Date": "3/24/99",
      "Rate": "4254.300781"
  },
  {
      "Date": "3/23/99",
      "Rate": "4262.999347"
  },
  {
      "Date": "3/22/99",
      "Rate": "4254.029172"
  },
  {
      "Date": "3/19/99",
      "Rate": "4239.326972"
  },
  {
      "Date": "3/18/99",
      "Rate": "4255.295508"
  },
  {
      "Date": "3/17/99",
      "Rate": "4248.060812"
  },
  {
      "Date": "3/16/99",
      "Rate": "4289.430149"
  },
  {
      "Date": "3/15/99",
      "Rate": "4359.579534"
  },
  {
      "Date": "3/12/99",
      "Rate": "4405.34401"
  },
  {
      "Date": "3/11/99",
      "Rate": "4407.59449"
  },
  {
      "Date": "3/10/99",
      "Rate": "4362.810632"
  },
  {
      "Date": "3/9/99",
      "Rate": "4381.149579"
  },
  {
      "Date": "3/8/99",
      "Rate": "4326.051606"
  },
  {
      "Date": "3/5/99",
      "Rate": "4317.053211"
  },
  {
      "Date": "3/4/99",
      "Rate": "4299.059948"
  },
  {
      "Date": "3/3/99",
      "Rate": "4300.329812"
  },
  {
      "Date": "3/2/99",
      "Rate": "4291.528951"
  },
  {
      "Date": "3/1/99",
      "Rate": "4301.2681"
  },
  {
      "Date": "2/26/99",
      "Rate": "4300.559093"
  },
  {
      "Date": "2/25/99",
      "Rate": "4309.109508"
  },
  {
      "Date": "2/24/99",
      "Rate": "4278.960828"
  },
  {
      "Date": "2/23/99",
      "Rate": "4290.220286"
  },
  {
      "Date": "2/22/99",
      "Rate": "4296.424981"
  },
  {
      "Date": "2/19/99",
      "Rate": "4295.193919"
  },
  {
      "Date": "2/18/99",
      "Rate": "4277.267677"
  },
  {
      "Date": "2/17/99",
      "Rate": "4277.306478"
  },
  {
      "Date": "2/16/99",
      "Rate": "4317.268382"
  },
  {
      "Date": "2/15/99",
      "Rate": "4343.152437"
  },
  {
      "Date": "2/12/99",
      "Rate": "4302.460361"
  },
  {
      "Date": "2/11/99",
      "Rate": "4304.971869"
  },
  {
      "Date": "2/10/99",
      "Rate": "4296.449673"
  },
  {
      "Date": "2/9/99",
      "Rate": "4306.202931"
  },
  {
      "Date": "2/8/99",
      "Rate": "4325.183866"
  },
  {
      "Date": "2/5/99",
      "Rate": "4338.662058"
  },
  {
      "Date": "2/4/99",
      "Rate": "4302.241662"
  },
  {
      "Date": "2/3/99",
      "Rate": "4305.719678"
  },
  {
      "Date": "2/2/99",
      "Rate": "4342.750313"
  },
  {
      "Date": "2/1/99",
      "Rate": "4303.0318"
  },
  {
      "Date": "1/29/99",
      "Rate": "4281.299494"
  },
  {
      "Date": "1/28/99",
      "Rate": "4232.085222"
  },
  {
      "Date": "1/27/99",
      "Rate": "4283.546447"
  },
  {
      "Date": "1/26/99",
      "Rate": "4308.054816"
  },
  {
      "Date": "1/25/99",
      "Rate": "4292.051006"
  },
  {
      "Date": "1/22/99",
      "Rate": "4313.790367"
  },
  {
      "Date": "1/21/99",
      "Rate": "4303.0318"
  },
  {
      "Date": "1/20/99",
      "Rate": "4286.054428"
  },
  {
      "Date": "1/19/99",
      "Rate": "4308.054816"
  },
  {
      "Date": "1/18/99",
      "Rate": "4301.79721"
  },
  {
      "Date": "1/15/99",
      "Rate": "4284.297783"
  },
  {
      "Date": "1/14/99",
      "Rate": "4283.056139"
  },
  {
      "Date": "1/13/99",
      "Rate": "4318.502972"
  },
  {
      "Date": "1/12/99",
      "Rate": "4373.646802"
  },
  {
      "Date": "1/11/99",
      "Rate": "4364.718954"
  },
  {
      "Date": "1/8/99",
      "Rate": "4362.549604"
  },
  {
      "Date": "1/7/99",
      "Rate": "4327.55075"
  },
  {
      "Date": "1/6/99",
      "Rate": "4304.791972"
  },
  {
      "Date": "1/5/99",
      "Rate": "4305.582109"
  },
  {
      "Date": "1/4/99",
      "Rate": "4326.369072"
  },
  {
      "Date": "12/31/98",
      "Rate": "4308.277042"
  },
  {
      "Date": "12/30/98",
      "Rate": "4302.580292"
  },
  {
      "Date": "12/29/98",
      "Rate": "4301.081148"
  },
  {
      "Date": "12/24/98",
      "Rate": "4300.837757"
  },
  {
      "Date": "12/23/98",
      "Rate": "4317.099067"
  },
  {
      "Date": "12/22/98",
      "Rate": "4331.085204"
  },
  {
      "Date": "12/21/98",
      "Rate": "4349.836858"
  },
  {
      "Date": "12/18/98",
      "Rate": "4386.874548"
  },
  {
      "Date": "12/17/98",
      "Rate": "4403.739043"
  },
  {
      "Date": "12/16/98",
      "Rate": "4389.357837"
  },
  {
      "Date": "12/15/98",
      "Rate": "4390.158557"
  },
  {
      "Date": "12/14/98",
      "Rate": "4373.964267"
  },
  {
      "Date": "12/11/98",
      "Rate": "4417.693434"
  },
  {
      "Date": "12/10/98",
      "Rate": "4426.698884"
  },
  {
      "Date": "12/9/98",
      "Rate": "4403.612057"
  },
  {
      "Date": "12/8/98",
      "Rate": "4420.69525"
  },
  {
      "Date": "12/7/98",
      "Rate": "4390.483077"
  },
  {
      "Date": "12/4/98",
      "Rate": "4391.234413"
  },
  {
      "Date": "12/3/98",
      "Rate": "4396.909998"
  },
  {
      "Date": "12/2/98",
      "Rate": "4412.204801"
  },
  {
      "Date": "12/1/98",
      "Rate": "4403.950687"
  },
  {
      "Date": "11/30/98",
      "Rate": "4441.545706"
  },
  {
      "Date": "11/27/98",
      "Rate": "4443.401118"
  },
  {
      "Date": "11/26/98",
      "Rate": "4444.872044"
  },
  {
      "Date": "11/25/98",
      "Rate": "4431.584331"
  },
  {
      "Date": "11/24/98",
      "Rate": "4428.367343"
  },
  {
      "Date": "11/23/98",
      "Rate": "4423.129156"
  },
  {
      "Date": "11/20/98",
      "Rate": "4425.164465"
  },
  {
      "Date": "11/19/98",
      "Rate": "4456.79818"
  },
  {
      "Date": "11/18/98",
      "Rate": "4410.367026"
  },
  {
      "Date": "11/17/98",
      "Rate": "4407.647401"
  },
  {
      "Date": "11/16/98",
      "Rate": "4412.095451"
  },
  {
      "Date": "11/13/98",
      "Rate": "4447.069613"
  },
  {
      "Date": "11/12/98",
      "Rate": "4373.890192"
  },
  {
      "Date": "11/11/98",
      "Rate": "4378.186564"
  },
  {
      "Date": "11/10/98",
      "Rate": "4351.113776"
  },
  {
      "Date": "11/9/98",
      "Rate": "4362.355597"
  },
  {
      "Date": "11/6/98",
      "Rate": "4380.257147"
  },
  {
      "Date": "11/5/98",
      "Rate": "4340.48925"
  },
  {
      "Date": "11/4/98",
      "Rate": "4325.995167"
  },
  {
      "Date": "11/3/98",
      "Rate": "4330.08695"
  },
  {
      "Date": "11/2/98",
      "Rate": "4367.061147"
  },
  {
      "Date": "10/30/98",
      "Rate": "4388.8252"
  },
  {
      "Date": "10/29/98",
      "Rate": "4380.320641"
  },
  {
      "Date": "10/28/98",
      "Rate": "4379.904407"
  },
  {
      "Date": "10/27/98",
      "Rate": "4367.689024"
  },
  {
      "Date": "10/26/98",
      "Rate": "4340.8561"
  },
  {
      "Date": "10/23/98",
      "Rate": "4369.068238"
  },
  {
      "Date": "10/22/98",
      "Rate": "4381.978518"
  },
  {
      "Date": "10/21/98",
      "Rate": "4428.967001"
  },
  {
      "Date": "10/20/98",
      "Rate": "4413.305349"
  },
  {
      "Date": "10/19/98",
      "Rate": "4451.651705"
  },
  {
      "Date": "10/16/98",
      "Rate": "4482.982063"
  },
  {
      "Date": "10/15/98",
      "Rate": "4416.58583"
  },
  {
      "Date": "10/14/98",
      "Rate": "4426.586007"
  },
  {
      "Date": "10/13/98",
      "Rate": "4432.187517"
  },
  {
      "Date": "10/12/98",
      "Rate": "4427.852343"
  },
  {
      "Date": "10/9/98",
      "Rate": "4474.745586"
  },
  {
      "Date": "10/8/98",
      "Rate": "4516.665197"
  },
  {
      "Date": "10/7/98",
      "Rate": "4464.382088"
  },
  {
      "Date": "10/6/98",
      "Rate": "4431.217482"
  },
  {
      "Date": "10/5/98",
      "Rate": "4466.050548"
  },
  {
      "Date": "10/2/98",
      "Rate": "4500.880086"
  },
  {
      "Date": "10/1/98",
      "Rate": "4471.369865"
  },
  {
      "Date": "9/30/98",
      "Rate": "4403.799009"
  },
  {
      "Date": "9/29/98",
      "Rate": "4443.404646"
  },
  {
      "Date": "9/28/98",
      "Rate": "4408.631546"
  },
  {
      "Date": "9/25/98",
      "Rate": "4439.577418"
  },
  {
      "Date": "9/24/98",
      "Rate": "4361.639535"
  },
  {
      "Date": "9/23/98",
      "Rate": "4321.600028"
  },
  {
      "Date": "9/22/98",
      "Rate": "4326.051606"
  },
  {
      "Date": "9/21/98",
      "Rate": "4389.304926"
  },
  {
      "Date": "9/18/98",
      "Rate": "4370.521526"
  },
  {
      "Date": "9/17/98",
      "Rate": "4314.319477"
  },
  {
      "Date": "9/16/98",
      "Rate": "4339.875483"
  },
  {
      "Date": "9/15/98",
      "Rate": "4357.886382"
  },
  {
      "Date": "9/14/98",
      "Rate": "4357.138574"
  },
  {
      "Date": "9/11/98",
      "Rate": "4402.701988"
  },
  {
      "Date": "9/10/98",
      "Rate": "4277.267677"
  },
  {
      "Date": "9/9/98",
      "Rate": "4263.327396"
  },
  {
      "Date": "9/8/98",
      "Rate": "4312.111325"
  },
  {
      "Date": "9/7/98",
      "Rate": "4328.376162"
  },
  {
      "Date": "9/4/98",
      "Rate": "4288.121484"
  },
  {
      "Date": "9/3/98",
      "Rate": "4191.865818"
  },
  {
      "Date": "9/2/98",
      "Rate": "4199.086404"
  },
  {
      "Date": "9/1/98",
      "Rate": "4164.105187"
  },
  {
      "Date": "8/28/98",
      "Rate": "4105.896048"
  },
  {
      "Date": "8/27/98",
      "Rate": "4212.582232"
  },
  {
      "Date": "8/26/98",
      "Rate": "4234.088785"
  },
  {
      "Date": "8/25/98",
      "Rate": "4255.069754"
  },
  {
      "Date": "8/24/98",
      "Rate": "4307.197658"
  },
  {
      "Date": "8/21/98",
      "Rate": "4271.341646"
  },
  {
      "Date": "8/20/98",
      "Rate": "4272.586818"
  },
  {
      "Date": "8/19/98",
      "Rate": "4383.594067"
  },
  {
      "Date": "8/18/98",
      "Rate": "4345.829732"
  },
  {
      "Date": "8/17/98",
      "Rate": "4312.033722"
  },
  {
      "Date": "8/14/98",
      "Rate": "4331.346232"
  },
  {
      "Date": "8/13/98",
      "Rate": "4317.857458"
  },
  {
      "Date": "8/12/98",
      "Rate": "4323.864618"
  },
  {
      "Date": "8/11/98",
      "Rate": "4285.137304"
  },
  {
      "Date": "8/10/98",
      "Rate": "4295.59957"
  },
  {
      "Date": "8/7/98",
      "Rate": "4300.14286"
  },
  {
      "Date": "8/6/98",
      "Rate": "4298.273338"
  },
  {
      "Date": "8/5/98",
      "Rate": "4327.222702"
  },
  {
      "Date": "8/4/98",
      "Rate": "4282.047302"
  },
  {
      "Date": "8/3/98",
      "Rate": "4300.329812"
  },
  {
      "Date": "7/31/98",
      "Rate": "4342.097744"
  },
  {
      "Date": "7/30/98",
      "Rate": "4337.836646"
  },
  {
      "Date": "7/29/98",
      "Rate": "4357.378437"
  },
  {
      "Date": "7/28/98",
      "Rate": "4356.863437"
  },
  {
      "Date": "7/27/98",
      "Rate": "4362.86707"
  },
  {
      "Date": "7/24/98",
      "Rate": "4404.772571"
  },
  {
      "Date": "7/23/98",
      "Rate": "4389.961022"
  },
  {
      "Date": "7/22/98",
      "Rate": "4406.21175"
  },
  {
      "Date": "7/21/98",
      "Rate": "4415.061994"
  },
  {
      "Date": "7/20/98",
      "Rate": "4416.709289"
  },
  {
      "Date": "7/17/98",
      "Rate": "4402.525618"
  },
  {
      "Date": "7/16/98",
      "Rate": "4417.922715"
  },
  {
      "Date": "7/15/98",
      "Rate": "4399.82363"
  },
  {
      "Date": "7/14/98",
      "Rate": "4391.837599"
  },
  {
      "Date": "7/13/98",
      "Rate": "4367.241045"
  },
  {
      "Date": "7/10/98",
      "Rate": "4362.620152"
  },
  {
      "Date": "7/9/98",
      "Rate": "4389.777597"
  },
  {
      "Date": "7/8/98",
      "Rate": "4421.037408"
  },
  {
      "Date": "7/7/98",
      "Rate": "4389.858728"
  },
  {
      "Date": "7/6/98",
      "Rate": "4390.606536"
  },
  {
      "Date": "7/3/98",
      "Rate": "4401.319247"
  },
  {
      "Date": "7/2/98",
      "Rate": "4444.152454"
  },
  {
      "Date": "7/1/98",
      "Rate": "4420.423641"
  },
  {
      "Date": "6/30/98",
      "Rate": "4424.331999"
  },
  {
      "Date": "6/29/98",
      "Rate": "4397.661334"
  },
  {
      "Date": "6/26/98",
      "Rate": "4439.393993"
  },
  {
      "Date": "6/25/98",
      "Rate": "4414.860932"
  },
  {
      "Date": "6/24/98",
      "Rate": "4423.608882"
  },
  {
      "Date": "6/23/98",
      "Rate": "4456.78407"
  },
  {
      "Date": "6/22/98",
      "Rate": "4441.602145"
  },
  {
      "Date": "6/19/98",
      "Rate": "4381.414134"
  },
  {
      "Date": "6/18/98",
      "Rate": "4359.646555"
  },
  {
      "Date": "6/17/98",
      "Rate": "4279.789767"
  },
  {
      "Date": "6/16/98",
      "Rate": "4267.285137"
  },
  {
      "Date": "6/15/98",
      "Rate": "4264.329177"
  },
  {
      "Date": "6/12/98",
      "Rate": "4271.779044"
  },
  {
      "Date": "6/11/98",
      "Rate": "4371.879575"
  },
  {
      "Date": "6/10/98",
      "Rate": "4388.461878"
  },
  {
      "Date": "6/9/98",
      "Rate": "4417.704016"
  },
  {
      "Date": "6/8/98",
      "Rate": "4279.313568"
  },
  {
      "Date": "6/5/98",
      "Rate": "4318.143177"
  },
  {
      "Date": "6/4/98",
      "Rate": "4303.564437"
  },
  {
      "Date": "6/3/98",
      "Rate": "4324.788797"
  },
  {
      "Date": "6/2/98",
      "Rate": "4260.036332"
  },
  {
      "Date": "6/1/98",
      "Rate": "4301.79721"
  },
  {
      "Date": "5/29/98",
      "Rate": "4298.92238"
  },
  {
      "Date": "5/28/98",
      "Rate": "4276.36819"
  },
  {
      "Date": "5/27/98",
      "Rate": "4263.676608"
  },
  {
      "Date": "5/26/98",
      "Rate": "4316.799238"
  },
  {
      "Date": "5/22/98",
      "Rate": "4311.702146"
  },
  {
      "Date": "5/21/98",
      "Rate": "4289.680594"
  },
  {
      "Date": "5/20/98",
      "Rate": "4260.748867"
  },
  {
      "Date": "5/19/98",
      "Rate": "4270.978324"
  },
  {
      "Date": "5/18/98",
      "Rate": "4280.445863"
  },
  {
      "Date": "5/15/98",
      "Rate": "4323.981023"
  },
  {
      "Date": "5/14/98",
      "Rate": "4245.168345"
  },
  {
      "Date": "5/13/98",
      "Rate": "4145.773294"
  },
  {
      "Date": "5/12/98",
      "Rate": "4207.665038"
  },
  {
      "Date": "5/11/98",
      "Rate": "4193.456675"
  },
  {
      "Date": "5/8/98",
      "Rate": "4195.559005"
  },
  {
      "Date": "5/7/98",
      "Rate": "4195.040477"
  },
  {
      "Date": "5/6/98",
      "Rate": "4258.173866"
  },
  {
      "Date": "5/5/98",
      "Rate": "4266.385651"
  },
  {
      "Date": "5/1/98",
      "Rate": "4290.333163"
  },
  {
      "Date": "4/30/98",
      "Rate": "4353.163195"
  },
  {
      "Date": "4/29/98",
      "Rate": "4311.201256"
  },
  {
      "Date": "4/28/98",
      "Rate": "4325.917565"
  },
  {
      "Date": "4/27/98",
      "Rate": "4366.267483"
  },
  {
      "Date": "4/24/98",
      "Rate": "4377.177728"
  },
  {
      "Date": "4/23/98",
      "Rate": "4407.252332"
  },
  {
      "Date": "4/22/98",
      "Rate": "4359.621863"
  },
  {
      "Date": "4/21/98",
      "Rate": "4299.250428"
  },
  {
      "Date": "4/20/98",
      "Rate": "4298.209845"
  },
  {
      "Date": "4/17/98",
      "Rate": "4314.876806"
  },
  {
      "Date": "4/16/98",
      "Rate": "4288.276689"
  },
  {
      "Date": "4/15/98",
      "Rate": "4315.34595"
  },
  {
      "Date": "4/14/98",
      "Rate": "4325.434311"
  },
  {
      "Date": "4/9/98",
      "Rate": "4305.01067"
  },
  {
      "Date": "4/8/98",
      "Rate": "4330.566677"
  },
  {
      "Date": "4/7/98",
      "Rate": "4335.808392"
  },
  {
      "Date": "4/6/98",
      "Rate": "4373.043616"
  },
  {
      "Date": "4/3/98",
      "Rate": "4261.792977"
  },
  {
      "Date": "4/2/98",
      "Rate": "4169.685532"
  },
  {
      "Date": "4/1/98",
      "Rate": "4201.728426"
  },
  {
      "Date": "3/31/98",
      "Rate": "4177.516358"
  },
  {
      "Date": "3/30/98",
      "Rate": "4209.555724"
  },
  {
      "Date": "3/27/98",
      "Rate": "4215.615796"
  },
  {
      "Date": "3/26/98",
      "Rate": "4168.29221"
  },
  {
      "Date": "3/25/98",
      "Rate": "4160.119226"
  },
  {
      "Date": "3/24/98",
      "Rate": "4172.376938"
  },
  {
      "Date": "3/23/98",
      "Rate": "4083.779255"
  },
  {
      "Date": "3/20/98",
      "Rate": "4056.988659"
  },
  {
      "Date": "3/19/98",
      "Rate": "4061.831778"
  },
  {
      "Date": "3/18/98",
      "Rate": "4031.153989"
  },
  {
      "Date": "3/17/98",
      "Rate": "4096.689536"
  },
  {
      "Date": "3/16/98",
      "Rate": "4101.571456"
  },
  {
      "Date": "3/13/98",
      "Rate": "4112.393517"
  },
  {
      "Date": "3/12/98",
      "Rate": "4096.368543"
  },
  {
      "Date": "3/11/98",
      "Rate": "4113.786839"
  },
  {
      "Date": "3/10/98",
      "Rate": "4099.511455"
  },
  {
      "Date": "3/9/98",
      "Rate": "4113.024921"
  },
  {
      "Date": "3/6/98",
      "Rate": "4101.927724"
  },
  {
      "Date": "3/5/98",
      "Rate": "4101.962998"
  },
  {
      "Date": "3/4/98",
      "Rate": "4119.014445"
  },
  {
      "Date": "3/3/98",
      "Rate": "4140.926648"
  },
  {
      "Date": "3/2/98",
      "Rate": "4172.257006"
  },
  {
      "Date": "2/27/98",
      "Rate": "4115.162525"
  },
  {
      "Date": "2/26/98",
      "Rate": "4057.630646"
  },
  {
      "Date": "2/25/98",
      "Rate": "4046.907353"
  },
  {
      "Date": "2/24/98",
      "Rate": "4042.441666"
  },
  {
      "Date": "2/23/98",
      "Rate": "4081.849768"
  },
  {
      "Date": "2/20/98",
      "Rate": "4095.9382"
  },
  {
      "Date": "2/19/98",
      "Rate": "4091.57128"
  },
  {
      "Date": "2/18/98",
      "Rate": "4078.932609"
  },
  {
      "Date": "2/17/98",
      "Rate": "4105.497451"
  },
  {
      "Date": "2/16/98",
      "Rate": "4084.915078"
  },
  {
      "Date": "2/13/98",
      "Rate": "4074.315244"
  },
  {
      "Date": "2/12/98",
      "Rate": "4090.096827"
  },
  {
      "Date": "2/11/98",
      "Rate": "4096.795358"
  },
  {
      "Date": "2/10/98",
      "Rate": "4115.095504"
  },
  {
      "Date": "2/9/98",
      "Rate": "4099.543202"
  },
  {
      "Date": "2/6/98",
      "Rate": "4112.897935"
  },
  {
      "Date": "2/5/98",
      "Rate": "4080.294185"
  },
  {
      "Date": "2/4/98",
      "Rate": "4033.951216"
  },
  {
      "Date": "2/3/98",
      "Rate": "4103.89954"
  },
  {
      "Date": "2/2/98",
      "Rate": "4141.787333"
  },
  {
      "Date": "1/30/98",
      "Rate": "4119.882185"
  },
  {
      "Date": "1/29/98",
      "Rate": "4157.621828"
  },
  {
      "Date": "1/28/98",
      "Rate": "4076.682128"
  },
  {
      "Date": "1/27/98",
      "Rate": "4072.029489"
  },
  {
      "Date": "1/26/98",
      "Rate": "4145.999048"
  },
  {
      "Date": "1/23/98",
      "Rate": "3978.595742"
  },
  {
      "Date": "1/22/98",
      "Rate": "4005.49216"
  },
  {
      "Date": "1/21/98",
      "Rate": "3974.440466"
  },
  {
      "Date": "1/20/98",
      "Rate": "3930.707773"
  },
  {
      "Date": "1/19/98",
      "Rate": "3934.54911"
  },
  {
      "Date": "1/16/98",
      "Rate": "4064.530239"
  },
  {
      "Date": "1/15/98",
      "Rate": "3990.998078"
  },
  {
      "Date": "1/14/98",
      "Rate": "4033.933579"
  },
  {
      "Date": "1/13/98",
      "Rate": "3912.675709"
  },
  {
      "Date": "1/12/98",
      "Rate": "3880.403534"
  },
  {
      "Date": "1/9/98",
      "Rate": "3947.66398"
  },
  {
      "Date": "1/8/98",
      "Rate": "3951.688742"
  },
  {
      "Date": "1/7/98",
      "Rate": "3945.491102"
  },
  {
      "Date": "1/6/98",
      "Rate": "3911.472865"
  },
  {
      "Date": "1/5/98",
      "Rate": "3978.373516"
  },
  {
      "Date": "1/2/98",
      "Rate": "3983.220163"
  },
  {
      "Date": "12/31/97",
      "Rate": "4000.416233"
  },
  {
      "Date": "12/30/97",
      "Rate": "4017.259564"
  },
  {
      "Date": "12/29/97",
      "Rate": "4065.948253"
  },
  {
      "Date": "12/24/97",
      "Rate": "4091.684157"
  },
  {
      "Date": "12/23/97",
      "Rate": "4032.639024"
  },
  {
      "Date": "12/22/97",
      "Rate": "4011.047814"
  },
  {
      "Date": "12/19/97",
      "Rate": "4001.619076"
  },
  {
      "Date": "12/18/97",
      "Rate": "3975.230604"
  },
  {
      "Date": "12/17/97",
      "Rate": "3975.19533"
  },
  {
      "Date": "12/16/97",
      "Rate": "3935.095857"
  },
  {
      "Date": "12/15/97",
      "Rate": "3952.881003"
  },
  {
      "Date": "12/12/97",
      "Rate": "3926.827634"
  },
  {
      "Date": "12/11/97",
      "Rate": "3980.937935"
  },
  {
      "Date": "12/10/97",
      "Rate": "3919.381294"
  },
  {
      "Date": "12/9/97",
      "Rate": "3922.548899"
  },
  {
      "Date": "12/8/97",
      "Rate": "3958.270869"
  },
  {
      "Date": "12/5/97",
      "Rate": "3928.654826"
  },
  {
      "Date": "12/4/97",
      "Rate": "3972.087691"
  },
  {
      "Date": "12/3/97",
      "Rate": "4035.425669"
  },
  {
      "Date": "12/2/97",
      "Rate": "4063.521402"
  },
  {
      "Date": "12/1/97",
      "Rate": "4054.198487"
  },
  {
      "Date": "11/28/97",
      "Rate": "4068.699624"
  },
  {
      "Date": "11/27/97",
      "Rate": "4031.263338"
  },
  {
      "Date": "11/26/97",
      "Rate": "4028.166282"
  },
  {
      "Date": "11/25/97",
      "Rate": "4059.986949"
  },
  {
      "Date": "11/24/97",
      "Rate": "4121.899857"
  },
  {
      "Date": "11/21/97",
      "Rate": "4040.053616"
  },
  {
      "Date": "11/20/97",
      "Rate": "4002.254008"
  },
  {
      "Date": "11/19/97",
      "Rate": "4036.769608"
  },
  {
      "Date": "11/18/97",
      "Rate": "3979.117797"
  },
  {
      "Date": "11/17/97",
      "Rate": "3927.847052"
  },
  {
      "Date": "11/14/97",
      "Rate": "3947.293603"
  },
  {
      "Date": "11/13/97",
      "Rate": "3997.199245"
  },
  {
      "Date": "11/12/97",
      "Rate": "4003.478015"
  },
  {
      "Date": "11/11/97",
      "Rate": "4009.933156"
  },
  {
      "Date": "11/10/97",
      "Rate": "3987.389548"
  },
  {
      "Date": "11/7/97",
      "Rate": "4022.046244"
  },
  {
      "Date": "11/6/97",
      "Rate": "4001.495617"
  },
  {
      "Date": "11/5/97",
      "Rate": "4022.734087"
  },
  {
      "Date": "11/4/97",
      "Rate": "4006.112983"
  },
  {
      "Date": "11/3/97",
      "Rate": "4008.670347"
  },
  {
      "Date": "10/31/97",
      "Rate": "3969.029436"
  },
  {
      "Date": "10/30/97",
      "Rate": "4003.985961"
  },
  {
      "Date": "10/29/97",
      "Rate": "4000.16226"
  },
  {
      "Date": "10/28/97",
      "Rate": "4000.797192"
  },
  {
      "Date": "10/27/97",
      "Rate": "3975.932556"
  },
  {
      "Date": "10/24/97",
      "Rate": "4104.724951"
  },
  {
      "Date": "10/23/97",
      "Rate": "4122.577118"
  },
  {
      "Date": "10/22/97",
      "Rate": "4114.926189"
  },
  {
      "Date": "10/21/97",
      "Rate": "4118.74989"
  },
  {
      "Date": "10/20/97",
      "Rate": "4134.690206"
  },
  {
      "Date": "10/17/97",
      "Rate": "4134.051747"
  },
  {
      "Date": "10/16/97",
      "Rate": "4173.583308"
  },
  {
      "Date": "10/15/97",
      "Rate": "4151.903914"
  },
  {
      "Date": "10/14/97",
      "Rate": "4183.784546"
  },
  {
      "Date": "10/13/97",
      "Rate": "4192.708866"
  },
  {
      "Date": "10/10/97",
      "Rate": "4181.234237"
  },
  {
      "Date": "10/9/97",
      "Rate": "4255.828145"
  },
  {
      "Date": "10/8/97",
      "Rate": "4232.240428"
  },
  {
      "Date": "10/7/97",
      "Rate": "4227.139808"
  },
  {
      "Date": "10/6/97",
      "Rate": "4260.928764"
  },
  {
      "Date": "10/3/97",
      "Rate": "4222.039189"
  },
  {
      "Date": "10/2/97",
      "Rate": "4230.32505"
  },
  {
      "Date": "10/1/97",
      "Rate": "4294.721247"
  },
  {
      "Date": "9/30/97",
      "Rate": "4192.070407"
  },
  {
      "Date": "9/29/97",
      "Rate": "4170.39454"
  },
  {
      "Date": "9/26/97",
      "Rate": "4164.916489"
  },
  {
      "Date": "9/25/97",
      "Rate": "4130.351505"
  },
  {
      "Date": "9/24/97",
      "Rate": "4123.03568"
  },
  {
      "Date": "9/23/97",
      "Rate": "4116.005573"
  },
  {
      "Date": "9/22/97",
      "Rate": "4117.17667"
  },
  {
      "Date": "9/19/97",
      "Rate": "4129.145135"
  },
  {
      "Date": "9/18/97",
      "Rate": "4117.060266"
  },
  {
      "Date": "9/17/97",
      "Rate": "4119.36013"
  },
  {
      "Date": "9/16/97",
      "Rate": "4147.716891"
  },
  {
      "Date": "9/15/97",
      "Rate": "4157.138574"
  },
  {
      "Date": "9/12/97",
      "Rate": "4167.547929"
  },
  {
      "Date": "9/11/97",
      "Rate": "4139.184113"
  },
  {
      "Date": "9/10/97",
      "Rate": "4133.445034"
  },
  {
      "Date": "9/9/97",
      "Rate": "4155.523025"
  },
  {
      "Date": "9/8/97",
      "Rate": "4169.117621"
  },
  {
      "Date": "9/5/97",
      "Rate": "4151.946242"
  },
  {
      "Date": "9/4/97",
      "Rate": "4148.852713"
  },
  {
      "Date": "9/3/97",
      "Rate": "4145.75213"
  },
  {
      "Date": "9/2/97",
      "Rate": "4176.796769"
  },
  {
      "Date": "9/1/97",
      "Rate": "4153.76638"
  },
  {
      "Date": "8/29/97",
      "Rate": "4189.527152"
  },
  {
      "Date": "8/28/97",
      "Rate": "4169.061183"
  },
  {
      "Date": "8/27/97",
      "Rate": "4176.765022"
  },
  {
      "Date": "8/26/97",
      "Rate": "4178.048996"
  },
  {
      "Date": "8/22/97",
      "Rate": "4075.070107"
  },
  {
      "Date": "8/21/97",
      "Rate": "4076.336443"
  },
  {
      "Date": "8/20/97",
      "Rate": "4066.244555"
  },
  {
      "Date": "8/19/97",
      "Rate": "4061.828251"
  },
  {
      "Date": "8/18/97",
      "Rate": "4107.038925"
  },
  {
      "Date": "8/15/97",
      "Rate": "4073.775552"
  },
  {
      "Date": "8/14/97",
      "Rate": "4130.288012"
  },
  {
      "Date": "8/13/97",
      "Rate": "4146.693945"
  },
  {
      "Date": "8/12/97",
      "Rate": "4124.249105"
  },
  {
      "Date": "8/11/97",
      "Rate": "4107.776151"
  },
  {
      "Date": "8/8/97",
      "Rate": "4056.657084"
  },
  {
      "Date": "8/7/97",
      "Rate": "4017.601721"
  },
  {
      "Date": "8/6/97",
      "Rate": "4023.090354"
  },
  {
      "Date": "8/5/97",
      "Rate": "4077.58867"
  },
  {
      "Date": "8/4/97",
      "Rate": "4086.41775"
  },
  {
      "Date": "8/1/97",
      "Rate": "4104.474506"
  },
  {
      "Date": "7/31/97",
      "Rate": "4111.007249"
  },
  {
      "Date": "7/30/97",
      "Rate": "4119.504753"
  },
  {
      "Date": "7/29/97",
      "Rate": "4127.614244"
  },
  {
      "Date": "7/28/97",
      "Rate": "4103.440978"
  },
  {
      "Date": "7/25/97",
      "Rate": "4092.093335"
  },
  {
      "Date": "7/24/97",
      "Rate": "4052.367767"
  },
  {
      "Date": "7/23/97",
      "Rate": "4090.199122"
  },
  {
      "Date": "7/22/97",
      "Rate": "4102.382758"
  },
  {
      "Date": "7/21/97",
      "Rate": "4136.859557"
  },
  {
      "Date": "7/18/97",
      "Rate": "4076.569252"
  },
  {
      "Date": "7/17/97",
      "Rate": "4019.580592"
  },
  {
      "Date": "7/16/97",
      "Rate": "4023.404293"
  },
  {
      "Date": "7/15/97",
      "Rate": "4025.548951"
  },
  {
      "Date": "7/14/97",
      "Rate": "4055.203795"
  },
  {
      "Date": "7/11/97",
      "Rate": "4045.171873"
  },
  {
      "Date": "7/10/97",
      "Rate": "4037.697314"
  },
  {
      "Date": "7/9/97",
      "Rate": "3989.548317"
  },
  {
      "Date": "7/8/97",
      "Rate": "4015.160761"
  },
  {
      "Date": "7/7/97",
      "Rate": "4028.57546"
  },
  {
      "Date": "7/4/97",
      "Rate": "4106.654438"
  },
  {
      "Date": "7/3/97",
      "Rate": "4196.313868"
  },
  {
      "Date": "7/2/97",
      "Rate": "4193.763558"
  },
  {
      "Date": "7/1/97",
      "Rate": "4222.734087"
  },
  {
      "Date": "6/30/97",
      "Rate": "4227.249158"
  },
  {
      "Date": "6/27/97",
      "Rate": "4259.454311"
  },
  {
      "Date": "6/26/97",
      "Rate": "4276.565724"
  },
  {
      "Date": "6/25/97",
      "Rate": "4277.133636"
  },
  {
      "Date": "6/24/97",
      "Rate": "4284.266036"
  },
  {
      "Date": "6/23/97",
      "Rate": "4260.978148"
  },
  {
      "Date": "6/20/97",
      "Rate": "4296.252138"
  },
  {
      "Date": "6/19/97",
      "Rate": "4301.44447"
  },
  {
      "Date": "6/18/97",
      "Rate": "4318.834547"
  },
  {
      "Date": "6/17/97",
      "Rate": "4313.035503"
  },
  {
      "Date": "6/16/97",
      "Rate": "4306.749678"
  },
  {
      "Date": "6/13/97",
      "Rate": "4318.333657"
  },
  {
      "Date": "6/12/97",
      "Rate": "4319.896294"
  },
  {
      "Date": "6/11/97",
      "Rate": "4339.667366"
  },
  {
      "Date": "6/10/97",
      "Rate": "4346.489356"
  },
  {
      "Date": "6/9/97",
      "Rate": "4338.528016"
  },
  {
      "Date": "6/6/97",
      "Rate": "4336.686714"
  },
  {
      "Date": "6/5/97",
      "Rate": "4296.98231"
  },
  {
      "Date": "6/4/97",
      "Rate": "4327.480202"
  },
  {
      "Date": "6/3/97",
      "Rate": "4347.600487"
  },
  {
      "Date": "6/2/97",
      "Rate": "4350.129632"
  },
  {
      "Date": "5/30/97",
      "Rate": "4374.697966"
  },
  {
      "Date": "5/29/97",
      "Rate": "4364.920016"
  },
  {
      "Date": "5/28/97",
      "Rate": "4358.983404"
  },
  {
      "Date": "5/27/97",
      "Rate": "4342.016614"
  },
  {
      "Date": "5/23/97",
      "Rate": "4308.3617"
  },
  {
      "Date": "5/22/97",
      "Rate": "4330.199827"
  },
  {
      "Date": "5/21/97",
      "Rate": "4320.75698"
  },
  {
      "Date": "5/20/97",
      "Rate": "4325.120372"
  },
  {
      "Date": "5/19/97",
      "Rate": "4341.910792"
  },
  {
      "Date": "5/16/97",
      "Rate": "4390.514824"
  },
  {
      "Date": "5/15/97",
      "Rate": "4401.978871"
  },
  {
      "Date": "5/14/97",
      "Rate": "4413.35826"
  },
  {
      "Date": "5/13/97",
      "Rate": "4391.86229"
  },
  {
      "Date": "5/12/97",
      "Rate": "4403.24168"
  },
  {
      "Date": "5/9/97",
      "Rate": "4363.893543"
  },
  {
      "Date": "5/8/97",
      "Rate": "4328.6901"
  },
  {
      "Date": "5/7/97",
      "Rate": "4310.171255"
  },
  {
      "Date": "5/6/97",
      "Rate": "4328.908799"
  },
  {
      "Date": "5/2/97",
      "Rate": "4285.031482"
  },
  {
      "Date": "5/1/97",
      "Rate": "4281.877987"
  },
  {
      "Date": "4/30/97",
      "Rate": "4293.229157"
  },
  {
      "Date": "4/29/97",
      "Rate": "4274.311716"
  },
  {
      "Date": "4/28/97",
      "Rate": "4303.317519"
  },
  {
      "Date": "4/25/97",
      "Rate": "4305.783171"
  },
  {
      "Date": "4/24/97",
      "Rate": "4314.090196"
  },
  {
      "Date": "4/23/97",
      "Rate": "4308.400501"
  },
  {
      "Date": "4/22/97",
      "Rate": "4312.774476"
  },
  {
      "Date": "4/21/97",
      "Rate": "4326.735921"
  },
  {
      "Date": "4/18/97",
      "Rate": "4327.250922"
  },
  {
      "Date": "4/17/97",
      "Rate": "4317.127286"
  },
  {
      "Date": "4/16/97",
      "Rate": "4311.860879"
  },
  {
      "Date": "4/15/97",
      "Rate": "4372.800226"
  },
  {
      "Date": "4/14/97",
      "Rate": "4383.639923"
  },
  {
      "Date": "4/11/97",
      "Rate": "4415.079631"
  },
  {
      "Date": "4/10/97",
      "Rate": "4405.026544"
  },
  {
      "Date": "4/9/97",
      "Rate": "4404.395139"
  },
  {
      "Date": "4/8/97",
      "Rate": "4418.317783"
  },
  {
      "Date": "4/7/97",
      "Rate": "4406.176476"
  },
  {
      "Date": "4/4/97",
      "Rate": "4399.844794"
  },
  {
      "Date": "4/3/97",
      "Rate": "4426.321452"
  },
  {
      "Date": "4/2/97",
      "Rate": "4437.330464"
  },
  {
      "Date": "4/1/97",
      "Rate": "4431.510256"
  },
  {
      "Date": "3/27/97",
      "Rate": "4424.614191"
  },
  {
      "Date": "3/26/97",
      "Rate": "4392.878181"
  },
  {
      "Date": "3/25/97",
      "Rate": "4444.812078"
  },
  {
      "Date": "3/24/97",
      "Rate": "4440.409884"
  },
  {
      "Date": "3/21/97",
      "Rate": "4453.704651"
  },
  {
      "Date": "3/20/97",
      "Rate": "4466.368014"
  },
  {
      "Date": "3/19/97",
      "Rate": "4418.87864"
  },
  {
      "Date": "3/18/97",
      "Rate": "4399.252191"
  },
  {
      "Date": "3/17/97",
      "Rate": "4458.138592"
  },
  {
      "Date": "3/14/97",
      "Rate": "4470.167022"
  },
  {
      "Date": "3/13/97",
      "Rate": "4450.537047"
  },
  {
      "Date": "3/12/97",
      "Rate": "4472.06829"
  },
  {
      "Date": "3/11/97",
      "Rate": "4465.101677"
  },
  {
      "Date": "3/10/97",
      "Rate": "4430.910598"
  },
  {
      "Date": "3/7/97",
      "Rate": "4453.704651"
  },
  {
      "Date": "3/6/97",
      "Rate": "4459.404928"
  },
  {
      "Date": "3/5/97",
      "Rate": "4504.993033"
  },
  {
      "Date": "3/4/97",
      "Rate": "4580.973209"
  },
  {
      "Date": "3/3/97",
      "Rate": "4575.223549"
  },
  {
      "Date": "2/28/97",
      "Rate": "4563.865324"
  },
  {
      "Date": "2/27/97",
      "Rate": "4515.755128"
  },
  {
      "Date": "2/26/97",
      "Rate": "4480.932644"
  },
  {
      "Date": "2/25/97",
      "Rate": "4477.112471"
  },
  {
      "Date": "2/24/97",
      "Rate": "4464.445581"
  },
  {
      "Date": "2/21/97",
      "Rate": "4441.67622"
  },
  {
      "Date": "2/20/97",
      "Rate": "4367.872449"
  },
  {
      "Date": "2/19/97",
      "Rate": "4384.0103"
  },
  {
      "Date": "2/18/97",
      "Rate": "4378.472283"
  },
  {
      "Date": "2/17/97",
      "Rate": "4352.041482"
  },
  {
      "Date": "2/14/97",
      "Rate": "4338.108256"
  },
  {
      "Date": "2/13/97",
      "Rate": "4344.439937"
  },
  {
      "Date": "2/12/97",
      "Rate": "4260.346743"
  },
  {
      "Date": "2/11/97",
      "Rate": "4291.878164"
  },
  {
      "Date": "2/10/97",
      "Rate": "4319.109684"
  },
  {
      "Date": "2/7/97",
      "Rate": "4317.843348"
  },
  {
      "Date": "2/6/97",
      "Rate": "4349.50881"
  },
  {
      "Date": "2/5/97",
      "Rate": "4346.644562"
  },
  {
      "Date": "2/4/97",
      "Rate": "4382.878005"
  },
  {
      "Date": "2/3/97",
      "Rate": "4350.605831"
  },
  {
      "Date": "1/31/97",
      "Rate": "4368.860121"
  },
  {
      "Date": "1/30/97",
      "Rate": "4423.31258"
  },
  {
      "Date": "1/29/97",
      "Rate": "4427.901727"
  },
  {
      "Date": "1/28/97",
      "Rate": "4517.684615"
  },
  {
      "Date": "1/27/97",
      "Rate": "4465.207499"
  },
  {
      "Date": "1/24/97",
      "Rate": "4466.420925"
  },
  {
      "Date": "1/23/97",
      "Rate": "4426.561315"
  },
  {
      "Date": "1/22/97",
      "Rate": "4430.356796"
  },
  {
      "Date": "1/21/97",
      "Rate": "4472.114147"
  },
  {
      "Date": "1/20/97",
      "Rate": "4482.241309"
  },
  {
      "Date": "1/17/97",
      "Rate": "4484.096721"
  },
  {
      "Date": "1/16/97",
      "Rate": "4479.708637"
  },
  {
      "Date": "1/15/97",
      "Rate": "4456.985132"
  },
  {
      "Date": "1/14/97",
      "Rate": "4518.319547"
  },
  {
      "Date": "1/13/97",
      "Rate": "4553.745216"
  },
  {
      "Date": "1/10/97",
      "Rate": "4536.654967"
  },
  {
      "Date": "1/9/97",
      "Rate": "4489.162066"
  },
  {
      "Date": "1/8/97",
      "Rate": "4505.624438"
  },
  {
      "Date": "1/7/97",
      "Rate": "4529.049895"
  },
  {
      "Date": "1/6/97",
      "Rate": "4546.778603"
  },
  {
      "Date": "1/3/97",
      "Rate": "4606.839627"
  },
  {
      "Date": "1/2/97",
      "Rate": "4657.58479"
  },
  {
      "Date": "12/31/96",
      "Rate": "4673.228805"
  },
  {
      "Date": "12/30/96",
      "Rate": "4673.860209"
  },
  {
      "Date": "12/27/96",
      "Rate": "4671.923667"
  },
  {
      "Date": "12/24/96",
      "Rate": "4665.009965"
  },
  {
      "Date": "12/23/96",
      "Rate": "4662.48082"
  },
  {
      "Date": "12/20/96",
      "Rate": "4668.801919"
  },
  {
      "Date": "12/19/96",
      "Rate": "4673.860209"
  },
  {
      "Date": "12/18/96",
      "Rate": "4666.272774"
  },
  {
      "Date": "12/17/96",
      "Rate": "4657.422529"
  },
  {
      "Date": "12/16/96",
      "Rate": "4663.814177"
  },
  {
      "Date": "12/13/96",
      "Rate": "4669.507399"
  },
  {
      "Date": "12/12/96",
      "Rate": "4661.214484"
  },
  {
      "Date": "12/11/96",
      "Rate": "4667.341576"
  },
  {
      "Date": "12/10/96",
      "Rate": "4657.239105"
  },
  {
      "Date": "12/9/96",
      "Rate": "4640.452213"
  },
  {
      "Date": "12/6/96",
      "Rate": "4683.96268"
  },
  {
      "Date": "12/5/96",
      "Rate": "4611.950828"
  },
  {
      "Date": "12/4/96",
      "Rate": "4668.297501"
  },
  {
      "Date": "12/3/96",
      "Rate": "4650.653451"
  },
  {
      "Date": "12/2/96",
      "Rate": "4678.706856"
  },
  {
      "Date": "11/29/96",
      "Rate": "4688.516552"
  },
  {
      "Date": "11/28/96",
      "Rate": "4693.610117"
  },
  {
      "Date": "11/27/96",
      "Rate": "4715.677525"
  },
  {
      "Date": "11/26/96",
      "Rate": "4725.257941"
  },
  {
      "Date": "11/25/96",
      "Rate": "4732.827739"
  },
  {
      "Date": "11/22/96",
      "Rate": "4751.551174"
  },
  {
      "Date": "11/21/96",
      "Rate": "4771.720842"
  },
  {
      "Date": "11/20/96",
      "Rate": "4772.980123"
  },
  {
      "Date": "11/19/96",
      "Rate": "4769.198751"
  },
  {
      "Date": "11/18/96",
      "Rate": "4786.849856"
  },
  {
      "Date": "11/15/96",
      "Rate": "4788.740542"
  },
  {
      "Date": "11/14/96",
      "Rate": "4821.340764"
  },
  {
      "Date": "11/13/96",
      "Rate": "4816.751618"
  },
  {
      "Date": "11/12/96",
      "Rate": "4805.418085"
  },
  {
      "Date": "11/11/96",
      "Rate": "4787.481261"
  },
  {
      "Date": "11/8/96",
      "Rate": "4769.830156"
  },
  {
      "Date": "11/7/96",
      "Rate": "4764.158098"
  },
  {
      "Date": "11/6/96",
      "Rate": "4764.158098"
  },
  {
      "Date": "11/5/96",
      "Rate": "4781.177799"
  },
  {
      "Date": "11/4/96",
      "Rate": "4773.611528"
  },
  {
      "Date": "11/1/96",
      "Rate": "4744.686855"
  },
  {
      "Date": "10/31/96",
      "Rate": "4769.766663"
  },
  {
      "Date": "10/30/96",
      "Rate": "4775.632727"
  },
  {
      "Date": "10/29/96",
      "Rate": "4801.375686"
  },
  {
      "Date": "10/28/96",
      "Rate": "4807.026579"
  },
  {
      "Date": "10/25/96",
      "Rate": "4799.492055"
  },
  {
      "Date": "10/24/96",
      "Rate": "4818.328366"
  },
  {
      "Date": "10/23/96",
      "Rate": "4835.012963"
  },
  {
      "Date": "10/22/96",
      "Rate": "4828.981111"
  },
  {
      "Date": "10/21/96",
      "Rate": "4782.588758"
  },
  {
      "Date": "10/18/96",
      "Rate": "4773.537452"
  },
  {
      "Date": "10/17/96",
      "Rate": "4806.963086"
  },
  {
      "Date": "10/16/96",
      "Rate": "4788.627665"
  },
  {
      "Date": "10/15/96",
      "Rate": "4812.645726"
  },
  {
      "Date": "10/14/96",
      "Rate": "4813.27713"
  },
  {
      "Date": "10/11/96",
      "Rate": "4801.83072"
  },
  {
      "Date": "10/10/96",
      "Rate": "4798.687808"
  },
  {
      "Date": "10/9/96",
      "Rate": "4789.886947"
  },
  {
      "Date": "10/8/96",
      "Rate": "4812.645726"
  },
  {
      "Date": "10/7/96",
      "Rate": "4769.519745"
  },
  {
      "Date": "10/4/96",
      "Rate": "4778.401735"
  },
  {
      "Date": "10/3/96",
      "Rate": "4793.700065"
  },
  {
      "Date": "10/2/96",
      "Rate": "4767.170497"
  },
  {
      "Date": "10/1/96",
      "Rate": "4762.750666"
  },
  {
      "Date": "9/30/96",
      "Rate": "4785.819856"
  },
  {
      "Date": "9/27/96",
      "Rate": "4797.096951"
  },
  {
      "Date": "9/26/96",
      "Rate": "4819.171414"
  },
  {
      "Date": "9/25/96",
      "Rate": "4845.122489"
  },
  {
      "Date": "9/24/96",
      "Rate": "4824.261451"
  },
  {
      "Date": "9/23/96",
      "Rate": "4813.153671"
  },
  {
      "Date": "9/20/96",
      "Rate": "4814.053158"
  },
  {
      "Date": "9/19/96",
      "Rate": "4830.388543"
  },
  {
      "Date": "9/18/96",
      "Rate": "4827.866453"
  },
  {
      "Date": "9/17/96",
      "Rate": "4833.587894"
  },
  {
      "Date": "9/16/96",
      "Rate": "4825.390219"
  },
  {
      "Date": "9/13/96",
      "Rate": "4823.499533"
  },
  {
      "Date": "9/12/96",
      "Rate": "4819.714633"
  },
  {
      "Date": "9/11/96",
      "Rate": "4831.693681"
  },
  {
      "Date": "9/10/96",
      "Rate": "4822.868128"
  },
  {
      "Date": "9/9/96",
      "Rate": "4859.098044"
  },
  {
      "Date": "9/6/96",
      "Rate": "4866.67137"
  },
  {
      "Date": "9/5/96",
      "Rate": "4873.080654"
  },
  {
      "Date": "9/4/96",
      "Rate": "4865.641369"
  },
  {
      "Date": "9/3/96",
      "Rate": "4881.401788"
  },
  {
      "Date": "9/2/96",
      "Rate": "4877.620417"
  },
  {
      "Date": "8/30/96",
      "Rate": "4871.10531"
  },
  {
      "Date": "8/29/96",
      "Rate": "4890.749396"
  },
  {
      "Date": "8/28/96",
      "Rate": "4898.308612"
  },
  {
      "Date": "8/27/96",
      "Rate": "4902.721388"
  },
  {
      "Date": "8/23/96",
      "Rate": "4883.814529"
  },
  {
      "Date": "8/22/96",
      "Rate": "4872.160003"
  },
  {
      "Date": "8/21/96",
      "Rate": "4872.251715"
  },
  {
      "Date": "8/20/96",
      "Rate": "4866.484418"
  },
  {
      "Date": "8/19/96",
      "Rate": "4863.976437"
  },
  {
      "Date": "8/16/96",
      "Rate": "4859.256777"
  },
  {
      "Date": "8/15/96",
      "Rate": "4883.341858"
  },
  {
      "Date": "8/14/96",
      "Rate": "4894.086315"
  },
  {
      "Date": "8/13/96",
      "Rate": "4896.594296"
  },
  {
      "Date": "8/12/96",
      "Rate": "4889.595936"
  },
  {
      "Date": "8/9/96",
      "Rate": "4908.859063"
  },
  {
      "Date": "8/8/96",
      "Rate": "4892.43902"
  },
  {
      "Date": "8/7/96",
      "Rate": "4895.66659"
  },
  {
      "Date": "8/6/96",
      "Rate": "4895.345597"
  },
  {
      "Date": "8/5/96",
      "Rate": "4877.172437"
  },
  {
      "Date": "8/2/96",
      "Rate": "4854.822836"
  },
  {
      "Date": "8/1/96",
      "Rate": "4848.554648"
  },
  {
      "Date": "7/31/96",
      "Rate": "4834.134641"
  },
  {
      "Date": "7/30/96",
      "Rate": "4826.607171"
  },
  {
      "Date": "7/29/96",
      "Rate": "4828.490802"
  },
  {
      "Date": "7/26/96",
      "Rate": "4826.607171"
  },
  {
      "Date": "7/25/96",
      "Rate": "4828.490802"
  },
  {
      "Date": "7/24/96",
      "Rate": "4813.559322"
  },
  {
      "Date": "7/23/96",
      "Rate": "4819.499462"
  },
  {
      "Date": "7/22/96",
      "Rate": "4794.469038"
  },
  {
      "Date": "7/19/96",
      "Rate": "4819.711106"
  },
  {
      "Date": "7/18/96",
      "Rate": "4833.753682"
  },
  {
      "Date": "7/17/96",
      "Rate": "4831.235119"
  },
  {
      "Date": "7/16/96",
      "Rate": "4856.036262"
  },
  {
      "Date": "7/15/96",
      "Rate": "4802.917159"
  },
  {
      "Date": "7/12/96",
      "Rate": "4788.232597"
  },
  {
      "Date": "7/11/96",
      "Rate": "4789.767015"
  },
  {
      "Date": "7/10/96",
      "Rate": "4780.750983"
  },
  {
      "Date": "7/9/96",
      "Rate": "4753.512408"
  },
  {
      "Date": "7/8/96",
      "Rate": "4733.321575"
  },
  {
      "Date": "7/5/96",
      "Rate": "4717.226053"
  },
  {
      "Date": "7/4/96",
      "Rate": "4715.987936"
  },
  {
      "Date": "7/3/96",
      "Rate": "4739.847264"
  },
  {
      "Date": "7/2/96",
      "Rate": "4713.046085"
  },
  {
      "Date": "7/1/96",
      "Rate": "4712.273585"
  },
  {
      "Date": "6/28/96",
      "Rate": "4720.940405"
  },
  {
      "Date": "6/27/96",
      "Rate": "4741.988395"
  },
  {
      "Date": "6/26/96",
      "Rate": "4754.369566"
  },
  {
      "Date": "6/25/96",
      "Rate": "4754.034463"
  },
  {
      "Date": "6/24/96",
      "Rate": "4734.415069"
  },
  {
      "Date": "6/21/96",
      "Rate": "4740.58449"
  },
  {
      "Date": "6/20/96",
      "Rate": "4737.751989"
  },
  {
      "Date": "6/19/96",
      "Rate": "4758.708266"
  },
  {
      "Date": "6/18/96",
      "Rate": "4745.148944"
  },
  {
      "Date": "6/17/96",
      "Rate": "4740.831408"
  },
  {
      "Date": "6/14/96",
      "Rate": "4718.781636"
  },
  {
      "Date": "6/13/96",
      "Rate": "4733.05702"
  },
  {
      "Date": "6/12/96",
      "Rate": "4729.367361"
  },
  {
      "Date": "6/11/96",
      "Rate": "4740.436339"
  },
  {
      "Date": "6/10/96",
      "Rate": "4742.281169"
  },
  {
      "Date": "6/7/96",
      "Rate": "4746.651616"
  },
  {
      "Date": "6/6/96",
      "Rate": "4741.406374"
  },
  {
      "Date": "6/5/96",
      "Rate": "4764.958818"
  },
  {
      "Date": "6/4/96",
      "Rate": "4798.567876"
  },
  {
      "Date": "6/3/96",
      "Rate": "4802.719625"
  },
  {
      "Date": "5/31/96",
      "Rate": "4828.578987"
  },
  {
      "Date": "5/30/96",
      "Rate": "4840.942521"
  },
  {
      "Date": "5/29/96",
      "Rate": "4854.611192"
  },
  {
      "Date": "5/28/96",
      "Rate": "4848.360641"
  },
  {
      "Date": "5/24/96",
      "Rate": "4831.19279"
  },
  {
      "Date": "5/23/96",
      "Rate": "4838.469814"
  },
  {
      "Date": "5/22/96",
      "Rate": "4834.141696"
  },
  {
      "Date": "5/21/96",
      "Rate": "4829.71481"
  },
  {
      "Date": "5/20/96",
      "Rate": "4807.640346"
  },
  {
      "Date": "5/17/96",
      "Rate": "4831.919434"
  },
  {
      "Date": "5/16/96",
      "Rate": "4835.443306"
  },
  {
      "Date": "5/15/96",
      "Rate": "4803.185241"
  },
  {
      "Date": "5/14/96",
      "Rate": "4816.532919"
  },
  {
      "Date": "5/13/96",
      "Rate": "4863.454382"
  },
  {
      "Date": "5/10/96",
      "Rate": "4870.25168"
  },
  {
      "Date": "5/9/96",
      "Rate": "4847.471737"
  },
  {
      "Date": "5/8/96",
      "Rate": "4808.585689"
  },
  {
      "Date": "5/7/96",
      "Rate": "4784.807492"
  },
  {
      "Date": "5/3/96",
      "Rate": "4787.844583"
  },
  {
      "Date": "5/2/96",
      "Rate": "4759.406691"
  },
  {
      "Date": "5/1/96",
      "Rate": "4785.869239"
  },
  {
      "Date": "4/30/96",
      "Rate": "4754.821073"
  },
  {
      "Date": "4/29/96",
      "Rate": "4720.785199"
  },
  {
      "Date": "4/26/96",
      "Rate": "4745.014903"
  },
  {
      "Date": "4/25/96",
      "Rate": "4711.822078"
  },
  {
      "Date": "4/24/96",
      "Rate": "4720.873384"
  },
  {
      "Date": "4/23/96",
      "Rate": "4725.099208"
  },
  {
      "Date": "4/22/96",
      "Rate": "4713.289476"
  },
  {
      "Date": "4/19/96",
      "Rate": "4703.638512"
  },
  {
      "Date": "4/18/96",
      "Rate": "4721.906912"
  },
  {
      "Date": "4/17/96",
      "Rate": "4721.734069"
  },
  {
      "Date": "4/16/96",
      "Rate": "4747.06785"
  },
  {
      "Date": "4/15/96",
      "Rate": "4750.083776"
  },
  {
      "Date": "4/12/96",
      "Rate": "4753.635867"
  },
  {
      "Date": "4/11/96",
      "Rate": "4753.036209"
  },
  {
      "Date": "4/10/96",
      "Rate": "4745.81915"
  },
  {
      "Date": "4/9/96",
      "Rate": "4782.504101"
  },
  {
      "Date": "4/4/96",
      "Rate": "4756.718813"
  },
  {
      "Date": "4/3/96",
      "Rate": "4743.741512"
  },
  {
      "Date": "4/2/96",
      "Rate": "4715.592868"
  },
  {
      "Date": "4/1/96",
      "Rate": "4765.163407"
  },
  {
      "Date": "3/29/96",
      "Rate": "4773.07889"
  },
  {
      "Date": "3/28/96",
      "Rate": "4798.751301"
  },
  {
      "Date": "3/27/96",
      "Rate": "4833.947689"
  },
  {
      "Date": "3/26/96",
      "Rate": "4790.666502"
  },
  {
      "Date": "3/25/96",
      "Rate": "4784.507663"
  },
  {
      "Date": "3/22/96",
      "Rate": "4834.952997"
  },
  {
      "Date": "3/21/96",
      "Rate": "4894.276795"
  },
  {
      "Date": "3/20/96",
      "Rate": "4914.693381"
  },
  {
      "Date": "3/19/96",
      "Rate": "4898.149879"
  },
  {
      "Date": "3/18/96",
      "Rate": "4868.237535"
  },
  {
      "Date": "3/15/96",
      "Rate": "4772.40163"
  },
  {
      "Date": "3/14/96",
      "Rate": "4757.477204"
  },
  {
      "Date": "3/13/96",
      "Rate": "4763.276248"
  },
  {
      "Date": "3/12/96",
      "Rate": "4749.290111"
  },
  {
      "Date": "3/11/96",
      "Rate": "4764.881215"
  },
  {
      "Date": "3/8/96",
      "Rate": "4739.677948"
  },
  {
      "Date": "3/7/96",
      "Rate": "4716.577012"
  },
  {
      "Date": "3/6/96",
      "Rate": "4727.448456"
  },
  {
      "Date": "3/5/96",
      "Rate": "4725.970476"
  },
  {
      "Date": "3/4/96",
      "Rate": "4809.195929"
  },
  {
      "Date": "3/1/96",
      "Rate": "4874.287024"
  },
  {
      "Date": "2/29/96",
      "Rate": "4900.315702"
  },
  {
      "Date": "2/28/96",
      "Rate": "4932.407979"
  },
  {
      "Date": "2/27/96",
      "Rate": "4998.881814"
  },
  {
      "Date": "2/26/96",
      "Rate": "5056.826399"
  },
  {
      "Date": "2/23/96",
      "Rate": "5099.987654"
  },
  {
      "Date": "2/22/96",
      "Rate": "5147.268911"
  },
  {
      "Date": "2/21/96",
      "Rate": "5133.501473"
  },
  {
      "Date": "2/20/96",
      "Rate": "5219.527681"
  },
  {
      "Date": "2/19/96",
      "Rate": "5240.282897"
  },
  {
      "Date": "2/16/96",
      "Rate": "5210.596307"
  },
  {
      "Date": "2/15/96",
      "Rate": "5222.854019"
  },
  {
      "Date": "2/14/96",
      "Rate": "5195.939964"
  },
  {
      "Date": "2/13/96",
      "Rate": "5224.166211"
  },
  {
      "Date": "2/12/96",
      "Rate": "5271.20055"
  },
  {
      "Date": "2/9/96",
      "Rate": "5320.234925"
  },
  {
      "Date": "2/8/96",
      "Rate": "5318.435951"
  },
  {
      "Date": "2/7/96",
      "Rate": "5456.949858"
  },
  {
      "Date": "2/6/96",
      "Rate": "5484.463571"
  },
  {
      "Date": "2/5/96",
      "Rate": "5514.792148"
  },
  {
      "Date": "2/2/96",
      "Rate": "5394.461983"
  },
  {
      "Date": "2/1/96",
      "Rate": "5230.674262"
  },
  {
      "Date": "1/31/96",
      "Rate": "5178.401735"
  },
  {
      "Date": "1/30/96",
      "Rate": "5171.706732"
  },
  {
      "Date": "1/29/96",
      "Rate": "5145.085451"
  },
  {
      "Date": "1/26/96",
      "Rate": "5176.299406"
  },
  {
      "Date": "1/25/96",
      "Rate": "5117.726944"
  },
  {
      "Date": "1/24/96",
      "Rate": "5117.550574"
  },
  {
      "Date": "1/23/96",
      "Rate": "5106.005397"
  },
  {
      "Date": "1/22/96",
      "Rate": "5077.511067"
  },
  {
      "Date": "1/19/96",
      "Rate": "5017.823948"
  },
  {
      "Date": "1/18/96",
      "Rate": "5026.677719"
  },
  {
      "Date": "1/17/96",
      "Rate": "5037.344574"
  },
  {
      "Date": "1/16/96",
      "Rate": "5009.421683"
  },
  {
      "Date": "1/15/96",
      "Rate": "4974.161802"
  },
  {
      "Date": "1/12/96",
      "Rate": "4998.850068"
  },
  {
      "Date": "1/11/96",
      "Rate": "5022.52597"
  },
  {
      "Date": "1/10/96",
      "Rate": "5004.596201"
  },
  {
      "Date": "1/9/96",
      "Rate": "4997.001711"
  },
  {
      "Date": "1/8/96",
      "Rate": "4989.548317"
  },
  {
      "Date": "1/5/96",
      "Rate": "4956.835218"
  },
  {
      "Date": "1/4/96",
      "Rate": "4910.714475"
  },
  {
      "Date": "1/3/96",
      "Rate": "4872.036544"
  },
  {
      "Date": "1/2/96",
      "Rate": "4806.405757"
  },
  {
      "Date": "12/29/95",
      "Rate": "4798.50791"
  },
  {
      "Date": "12/28/95",
      "Rate": "4791.703557"
  },
  {
      "Date": "12/27/95",
      "Rate": "4790.380783"
  },
  {
      "Date": "12/22/95",
      "Rate": "4776.9167"
  },
  {
      "Date": "12/21/95",
      "Rate": "4791.721194"
  },
  {
      "Date": "12/20/95",
      "Rate": "4772.951904"
  },
  {
      "Date": "12/19/95",
      "Rate": "4792.945202"
  },
  {
      "Date": "12/18/95",
      "Rate": "4777.862043"
  },
  {
      "Date": "12/15/95",
      "Rate": "4756.859909"
  },
  {
      "Date": "12/14/95",
      "Rate": "4755.011552"
  },
  {
      "Date": "12/13/95",
      "Rate": "4783.227217"
  },
  {
      "Date": "12/12/95",
      "Rate": "4777.950228"
  },
  {
      "Date": "12/11/95",
      "Rate": "4790.578317"
  },
  {
      "Date": "12/8/95",
      "Rate": "4794.514894"
  },
  {
      "Date": "12/7/95",
      "Rate": "4774.595672"
  },
  {
      "Date": "12/6/95",
      "Rate": "4762.983474"
  },
  {
      "Date": "12/5/95",
      "Rate": "4750.143742"
  },
  {
      "Date": "12/4/95",
      "Rate": "4768.602621"
  },
  {
      "Date": "12/1/95",
      "Rate": "4766.055839"
  },
  {
      "Date": "11/30/95",
      "Rate": "4779.463483"
  },
  {
      "Date": "11/29/95",
      "Rate": "4790.274961"
  },
  {
      "Date": "11/28/95",
      "Rate": "4772.003034"
  },
  {
      "Date": "11/27/95",
      "Rate": "4742.051888"
  },
  {
      "Date": "11/24/95",
      "Rate": "4710.301769"
  },
  {
      "Date": "11/23/95",
      "Rate": "4695.934673"
  },
  {
      "Date": "11/22/95",
      "Rate": "4732.411506"
  },
  {
      "Date": "11/21/95",
      "Rate": "4750.835112"
  },
  {
      "Date": "11/20/95",
      "Rate": "4745.036068"
  },
  {
      "Date": "11/17/95",
      "Rate": "4732.01291"
  },
  {
      "Date": "11/16/95",
      "Rate": "4719.000335"
  },
  {
      "Date": "11/15/95",
      "Rate": "4710.252385"
  },
  {
      "Date": "11/14/95",
      "Rate": "4709.550433"
  },
  {
      "Date": "11/13/95",
      "Rate": "4748.119014"
  },
  {
      "Date": "11/10/95",
      "Rate": "4712.167763"
  },
  {
      "Date": "11/9/95",
      "Rate": "4698.305085"
  },
  {
      "Date": "11/8/95",
      "Rate": "4701.97358"
  },
  {
      "Date": "11/7/95",
      "Rate": "4665.895342"
  },
  {
      "Date": "11/6/95",
      "Rate": "4654.844001"
  },
  {
      "Date": "11/3/95",
      "Rate": "4677.39819"
  },
  {
      "Date": "11/2/95",
      "Rate": "4647.147216"
  },
  {
      "Date": "11/1/95",
      "Rate": "4645.401153"
  },
  {
      "Date": "10/31/95",
      "Rate": "4640.138274"
  },
  {
      "Date": "10/30/95",
      "Rate": "4697.881797"
  },
  {
      "Date": "10/27/95",
      "Rate": "4776.899063"
  },
  {
      "Date": "10/26/95",
      "Rate": "4817.919187"
  },
  {
      "Date": "10/25/95",
      "Rate": "4788.514789"
  },
  {
      "Date": "10/24/95",
      "Rate": "4783.646978"
  },
  {
      "Date": "10/23/95",
      "Rate": "4756.658848"
  },
  {
      "Date": "10/20/95",
      "Rate": "4781.629306"
  },
  {
      "Date": "10/19/95",
      "Rate": "4728.005785"
  },
  {
      "Date": "10/18/95",
      "Rate": "4717.43417"
  },
  {
      "Date": "10/17/95",
      "Rate": "4699.095222"
  },
  {
      "Date": "10/16/95",
      "Rate": "4659.553079"
  },
  {
      "Date": "10/13/95",
      "Rate": "4605.372229"
  },
  {
      "Date": "10/12/95",
      "Rate": "4600.359795"
  },
  {
      "Date": "10/11/95",
      "Rate": "4593.682428"
  },
  {
      "Date": "10/10/95",
      "Rate": "4600.197534"
  },
  {
      "Date": "10/9/95",
      "Rate": "4613.167781"
  },
  {
      "Date": "10/6/95",
      "Rate": "4580.638106"
  },
  {
      "Date": "10/5/95",
      "Rate": "4582.701635"
  },
  {
      "Date": "10/4/95",
      "Rate": "4581.833895"
  },
  {
      "Date": "10/3/95",
      "Rate": "4577.643345"
  },
  {
      "Date": "10/2/95",
      "Rate": "4559.441965"
  },
  {
      "Date": "9/29/95",
      "Rate": "4594.25034"
  },
  {
      "Date": "9/28/95",
      "Rate": "4590.504242"
  },
  {
      "Date": "9/27/95",
      "Rate": "4574.824953"
  },
  {
      "Date": "9/26/95",
      "Rate": "4576.098344"
  },
  {
      "Date": "9/25/95",
      "Rate": "4577.043687"
  },
  {
      "Date": "9/22/95",
      "Rate": "4589.618865"
  },
  {
      "Date": "9/21/95",
      "Rate": "4563.061077"
  },
  {
      "Date": "9/20/95",
      "Rate": "4610.575142"
  },
  {
      "Date": "9/19/95",
      "Rate": "4618.360112"
  },
  {
      "Date": "9/18/95",
      "Rate": "4582.818039"
  },
  {
      "Date": "9/15/95",
      "Rate": "4559.212685"
  },
  {
      "Date": "9/14/95",
      "Rate": "4606.98425"
  },
  {
      "Date": "9/13/95",
      "Rate": "4459.027496"
  },
  {
      "Date": "9/12/95",
      "Rate": "4427.400836"
  },
  {
      "Date": "9/11/95",
      "Rate": "4370.038272"
  },
  {
      "Date": "9/8/95",
      "Rate": "4350.768091"
  },
  {
      "Date": "9/7/95",
      "Rate": "4322.372529"
  },
  {
      "Date": "9/6/95",
      "Rate": "4331.504965"
  },
  {
      "Date": "9/5/95",
      "Rate": "4289.236142"
  },
  {
      "Date": "9/4/95",
      "Rate": "4272.886647"
  },
  {
      "Date": "9/1/95",
      "Rate": "4289.433676"
  },
  {
      "Date": "8/31/95",
      "Rate": "4287.747579"
  },
  {
      "Date": "8/30/95",
      "Rate": "4277.087779"
  },
  {
      "Date": "8/29/95",
      "Rate": "4298.964708"
  },
  {
      "Date": "8/25/95",
      "Rate": "4274.128292"
  },
  {
      "Date": "8/24/95",
      "Rate": "4293.229157"
  },
  {
      "Date": "8/23/95",
      "Rate": "4278.21302"
  },
  {
      "Date": "8/22/95",
      "Rate": "4276.62569"
  },
  {
      "Date": "8/21/95",
      "Rate": "4296.883543"
  },
  {
      "Date": "8/18/95",
      "Rate": "4287.726415"
  },
  {
      "Date": "8/17/95",
      "Rate": "4265.087568"
  },
  {
      "Date": "8/16/95",
      "Rate": "4280.047267"
  },
  {
      "Date": "8/15/95",
      "Rate": "4270.94305"
  },
  {
      "Date": "8/14/95",
      "Rate": "4258.752359"
  },
  {
      "Date": "8/11/95",
      "Rate": "4256.152666"
  },
  {
      "Date": "8/10/95",
      "Rate": "4256.45955"
  },
  {
      "Date": "8/9/95",
      "Rate": "4262.80534"
  },
  {
      "Date": "8/8/95",
      "Rate": "4266.56202"
  },
  {
      "Date": "8/7/95",
      "Rate": "4253.13674"
  },
  {
      "Date": "8/4/95",
      "Rate": "4259.108626"
  },
  {
      "Date": "8/3/95",
      "Rate": "4240.702658"
  },
  {
      "Date": "8/2/95",
      "Rate": "4238.244061"
  },
  {
      "Date": "8/1/95",
      "Rate": "4228.275631"
  },
  {
      "Date": "7/31/95",
      "Rate": "4250.392423"
  },
  {
      "Date": "7/28/95",
      "Rate": "4245.686873"
  },
  {
      "Date": "7/27/95",
      "Rate": "4280.541103"
  },
  {
      "Date": "7/26/95",
      "Rate": "4275.856717"
  },
  {
      "Date": "7/25/95",
      "Rate": "4279.137198"
  },
  {
      "Date": "7/24/95",
      "Rate": "4280.332986"
  },
  {
      "Date": "7/21/95",
      "Rate": "4275.048943"
  },
  {
      "Date": "7/20/95",
      "Rate": "4291.73354"
  },
  {
      "Date": "7/19/95",
      "Rate": "4306.813171"
  },
  {
      "Date": "7/18/95",
      "Rate": "4307.779679"
  },
  {
      "Date": "7/17/95",
      "Rate": "4294.922309"
  },
  {
      "Date": "7/14/95",
      "Rate": "4295.197446"
  },
  {
      "Date": "7/13/95",
      "Rate": "4313.271839"
  },
  {
      "Date": "7/12/95",
      "Rate": "4275.387573"
  },
  {
      "Date": "7/11/95",
      "Rate": "4259.644791"
  },
  {
      "Date": "7/10/95",
      "Rate": "4268.498563"
  },
  {
      "Date": "7/7/95",
      "Rate": "4258.258523"
  },
  {
      "Date": "7/6/95",
      "Rate": "4259.535442"
  },
  {
      "Date": "7/5/95",
      "Rate": "4255.771707"
  },
  {
      "Date": "7/4/95",
      "Rate": "4263.108697"
  },
  {
      "Date": "7/3/95",
      "Rate": "4255.718796"
  },
  {
      "Date": "6/30/95",
      "Rate": "4292.661246"
  },
  {
      "Date": "6/29/95",
      "Rate": "4298.241592"
  },
  {
      "Date": "6/28/95",
      "Rate": "4308.467521"
  },
  {
      "Date": "6/27/95",
      "Rate": "4308.33348"
  },
  {
      "Date": "6/26/95",
      "Rate": "4307.779679"
  },
  {
      "Date": "6/23/95",
      "Rate": "4313.007284"
  },
  {
      "Date": "6/22/95",
      "Rate": "4312.626325"
  },
  {
      "Date": "6/21/95",
      "Rate": "4324.926366"
  },
  {
      "Date": "6/20/95",
      "Rate": "4331.808321"
  },
  {
      "Date": "6/19/95",
      "Rate": "4332.079931"
  },
  {
      "Date": "6/16/95",
      "Rate": "4340.954867"
  },
  {
      "Date": "6/15/95",
      "Rate": "4297.500838"
  },
  {
      "Date": "6/14/95",
      "Rate": "4284.481208"
  },
  {
      "Date": "6/13/95",
      "Rate": "4291.553643"
  },
  {
      "Date": "6/12/95",
      "Rate": "4293.75474"
  },
  {
      "Date": "6/9/95",
      "Rate": "4286.59412"
  },
  {
      "Date": "6/8/95",
      "Rate": "4275.475758"
  },
  {
      "Date": "6/7/95",
      "Rate": "4261.754176"
  },
  {
      "Date": "6/6/95",
      "Rate": "4254.057391"
  },
  {
      "Date": "6/5/95",
      "Rate": "4245.468174"
  },
  {
      "Date": "6/2/95",
      "Rate": "4259.165065"
  },
  {
      "Date": "6/1/95",
      "Rate": "4245.958483"
  },
  {
      "Date": "5/31/95",
      "Rate": "4255.965714"
  },
  {
      "Date": "5/30/95",
      "Rate": "4277.817951"
  },
  {
      "Date": "5/26/95",
      "Rate": "4273.133565"
  },
  {
      "Date": "5/25/95",
      "Rate": "4250.427697"
  },
  {
      "Date": "5/24/95",
      "Rate": "4238.311081"
  },
  {
      "Date": "5/23/95",
      "Rate": "4234.769573"
  },
  {
      "Date": "5/22/95",
      "Rate": "4234.247518"
  },
  {
      "Date": "5/19/95",
      "Rate": "4247.136634"
  },
  {
      "Date": "5/18/95",
      "Rate": "4244.025468"
  },
  {
      "Date": "5/17/95",
      "Rate": "4251.962116"
  },
  {
      "Date": "5/16/95",
      "Rate": "4255.65883"
  },
  {
      "Date": "5/15/95",
      "Rate": "4257.507187"
  },
  {
      "Date": "5/12/95",
      "Rate": "4254.304309"
  },
  {
      "Date": "5/11/95",
      "Rate": "4243.228276"
  },
  {
      "Date": "5/10/95",
      "Rate": "4248.57934"
  },
  {
      "Date": "5/9/95",
      "Rate": "4315.106087"
  },
  {
      "Date": "5/5/95",
      "Rate": "4337.367502"
  },
  {
      "Date": "5/4/95",
      "Rate": "4325.039242"
  },
  {
      "Date": "5/3/95",
      "Rate": "4308.693275"
  },
  {
      "Date": "5/2/95",
      "Rate": "4300.008818"
  },
  {
      "Date": "5/1/95",
      "Rate": "4279.525212"
  },
  {
      "Date": "4/28/95",
      "Rate": "4314.114887"
  },
  {
      "Date": "4/27/95",
      "Rate": "4280.629288"
  },
  {
      "Date": "4/26/95",
      "Rate": "4314.552285"
  },
  {
      "Date": "4/25/95",
      "Rate": "4318.428896"
  },
  {
      "Date": "4/24/95",
      "Rate": "4332.937089"
  },
  {
      "Date": "4/21/95",
      "Rate": "4325.596571"
  },
  {
      "Date": "4/20/95",
      "Rate": "4358.83878"
  },
  {
      "Date": "4/19/95",
      "Rate": "4394.532531"
  },
  {
      "Date": "4/18/95",
      "Rate": "4362.979947"
  },
  {
      "Date": "4/13/95",
      "Rate": "4317.857458"
  },
  {
      "Date": "4/12/95",
      "Rate": "4317.437697"
  },
  {
      "Date": "4/11/95",
      "Rate": "4308.996631"
  },
  {
      "Date": "4/10/95",
      "Rate": "4329.208628"
  },
  {
      "Date": "4/7/95",
      "Rate": "4342.080107"
  },
  {
      "Date": "4/6/95",
      "Rate": "4361.188028"
  },
  {
      "Date": "4/5/95",
      "Rate": "4350.803365"
  },
  {
      "Date": "4/4/95",
      "Rate": "4344.016649"
  },
  {
      "Date": "4/3/95",
      "Rate": "4347.342987"
  },
  {
      "Date": "3/31/95",
      "Rate": "4283.486481"
  },
  {
      "Date": "3/30/95",
      "Rate": "4240.914302"
  },
  {
      "Date": "3/29/95",
      "Rate": "4296.160426"
  },
  {
      "Date": "3/28/95",
      "Rate": "4295.613679"
  },
  {
      "Date": "3/27/95",
      "Rate": "4264.036403"
  },
  {
      "Date": "3/24/95",
      "Rate": "4270.576201"
  },
  {
      "Date": "3/23/95",
      "Rate": "4293.229157"
  },
  {
      "Date": "3/22/95",
      "Rate": "4245.923209"
  },
  {
      "Date": "3/21/95",
      "Rate": "4253.210815"
  },
  {
      "Date": "3/20/95",
      "Rate": "4246.145435"
  },
  {
      "Date": "3/17/95",
      "Rate": "4252.854548"
  },
  {
      "Date": "3/16/95",
      "Rate": "4283.595831"
  },
  {
      "Date": "3/15/95",
      "Rate": "4288.812854"
  },
  {
      "Date": "3/14/95",
      "Rate": "4322.196159"
  },
  {
      "Date": "3/13/95",
      "Rate": "4290.657684"
  },
  {
      "Date": "3/10/95",
      "Rate": "4294.329706"
  },
  {
      "Date": "3/9/95",
      "Rate": "4260.960511"
  },
  {
      "Date": "3/8/95",
      "Rate": "4293.902891"
  },
  {
      "Date": "3/7/95",
      "Rate": "4251.29191"
  },
  {
      "Date": "3/6/95",
      "Rate": "4221.714669"
  },
  {
      "Date": "3/3/95",
      "Rate": "4198.998219"
  },
  {
      "Date": "3/2/95",
      "Rate": "4157.988677"
  },
  {
      "Date": "3/1/95",
      "Rate": "4168.574402"
  },
  {
      "Date": "2/28/95",
      "Rate": "4163.914707"
  },
  {
      "Date": "2/27/95",
      "Rate": "4173.449267"
  },
  {
      "Date": "2/24/95",
      "Rate": "4206.46925"
  },
  {
      "Date": "2/23/95",
      "Rate": "4194.807669"
  },
  {
      "Date": "2/22/95",
      "Rate": "4194.14099"
  },
  {
      "Date": "2/21/95",
      "Rate": "4195.812977"
  },
  {
      "Date": "2/20/95",
      "Rate": "4197.795376"
  },
  {
      "Date": "2/17/95",
      "Rate": "4166.362722"
  },
  {
      "Date": "2/16/95",
      "Rate": "4161.385562"
  },
  {
      "Date": "2/15/95",
      "Rate": "4170.567382"
  },
  {
      "Date": "2/14/95",
      "Rate": "4161.272686"
  },
  {
      "Date": "2/13/95",
      "Rate": "4155.851073"
  },
  {
      "Date": "2/10/95",
      "Rate": "4164.373269"
  },
  {
      "Date": "2/9/95",
      "Rate": "4158.616554"
  },
  {
      "Date": "2/8/95",
      "Rate": "4147.992028"
  },
  {
      "Date": "2/7/95",
      "Rate": "4151.096139"
  },
  {
      "Date": "2/6/95",
      "Rate": "4152.972716"
  },
  {
      "Date": "2/3/95",
      "Rate": "4164.814194"
  },
  {
      "Date": "2/2/95",
      "Rate": "4152.972716"
  },
  {
      "Date": "2/1/95",
      "Rate": "4163.04344"
  },
  {
      "Date": "1/31/95",
      "Rate": "4158.616554"
  },
  {
      "Date": "1/30/95",
      "Rate": "4159.833507"
  },
  {
      "Date": "1/27/95",
      "Rate": "4206.21175"
  },
  {
      "Date": "1/26/95",
      "Rate": "4212.416445"
  },
  {
      "Date": "1/25/95",
      "Rate": "4224.691794"
  },
  {
      "Date": "1/24/95",
      "Rate": "4221.806381"
  },
  {
      "Date": "1/23/95",
      "Rate": "4261.644826"
  },
  {
      "Date": "1/20/95",
      "Rate": "4238.744951"
  },
  {
      "Date": "1/19/95",
      "Rate": "4238.498033"
  },
  {
      "Date": "1/18/95",
      "Rate": "4227.00224"
  },
  {
      "Date": "1/17/95",
      "Rate": "4182.96619"
  },
  {
      "Date": "1/16/95",
      "Rate": "4177.318824"
  },
  {
      "Date": "1/13/95",
      "Rate": "4201.319247"
  },
  {
      "Date": "1/12/95",
      "Rate": "4177.646872"
  },
  {
      "Date": "1/11/95",
      "Rate": "4150.207235"
  },
  {
      "Date": "1/10/95",
      "Rate": "4140.24586"
  },
  {
      "Date": "1/9/95",
      "Rate": "4121.00037"
  },
  {
      "Date": "1/6/95",
      "Rate": "4158.83878"
  },
  {
      "Date": "1/5/95",
      "Rate": "4150.207235"
  },
  {
      "Date": "1/4/95",
      "Rate": "4185.174342"
  },
  {
      "Date": "1/3/95",
      "Rate": "4220.025045"
  },
  {
      "Date": "12/30/94",
      "Rate": "4233.545565"
  },
  {
      "Date": "12/29/94",
      "Rate": "4241.753823"
  },
  {
      "Date": "12/28/94",
      "Rate": "4230.850632"
  },
  {
      "Date": "12/23/94",
      "Rate": "4223.291416"
  },
  {
      "Date": "12/22/94",
      "Rate": "4226.854089"
  },
  {
      "Date": "12/21/94",
      "Rate": "4236.921286"
  },
  {
      "Date": "12/20/94",
      "Rate": "4214.867987"
  },
  {
      "Date": "12/19/94",
      "Rate": "4197.81654"
  },
  {
      "Date": "12/16/94",
      "Rate": "4201.216953"
  },
  {
      "Date": "12/15/94",
      "Rate": "4205.643839"
  },
  {
      "Date": "12/14/94",
      "Rate": "4185.946842"
  },
  {
      "Date": "12/13/94",
      "Rate": "4190.151502"
  },
  {
      "Date": "12/12/94",
      "Rate": "4170.01358"
  },
  {
      "Date": "12/9/94",
      "Rate": "4161.385562"
  },
  {
      "Date": "12/8/94",
      "Rate": "4166.253373"
  },
  {
      "Date": "12/7/94",
      "Rate": "4171.34341"
  },
  {
      "Date": "12/6/94",
      "Rate": "4169.350429"
  },
  {
      "Date": "12/5/94",
      "Rate": "4175.992522"
  },
  {
      "Date": "12/2/94",
      "Rate": "4193.587188"
  },
  {
      "Date": "12/1/94",
      "Rate": "4221.51008"
  },
  {
      "Date": "11/30/94",
      "Rate": "4242.618036"
  },
  {
      "Date": "11/29/94",
      "Rate": "4247.16838"
  },
  {
      "Date": "11/28/94",
      "Rate": "4256.87931"
  },
  {
      "Date": "11/25/94",
      "Rate": "4254.879275"
  },
  {
      "Date": "11/24/94",
      "Rate": "4254.237288"
  },
  {
      "Date": "11/23/94",
      "Rate": "4255.344891"
  },
  {
      "Date": "11/22/94",
      "Rate": "4245.136599"
  },
  {
      "Date": "11/21/94",
      "Rate": "4245.383516"
  },
  {
      "Date": "11/18/94",
      "Rate": "4263.644861"
  },
  {
      "Date": "11/17/94",
      "Rate": "4275.010141"
  },
  {
      "Date": "11/16/94",
      "Rate": "4266.071712"
  },
  {
      "Date": "11/15/94",
      "Rate": "4276.244731"
  },
  {
      "Date": "11/14/94",
      "Rate": "4261.129826"
  },
  {
      "Date": "11/11/94",
      "Rate": "4271.11942"
  },
  {
      "Date": "11/10/94",
      "Rate": "4260.925237"
  },
  {
      "Date": "11/9/94",
      "Rate": "4260.463147"
  },
  {
      "Date": "11/8/94",
      "Rate": "4245.591633"
  },
  {
      "Date": "11/7/94",
      "Rate": "4247.376497"
  },
  {
      "Date": "11/4/94",
      "Rate": "4252.089102"
  },
  {
      "Date": "11/3/94",
      "Rate": "4252.089102"
  },
  {
      "Date": "11/2/94",
      "Rate": "4251.5353"
  },
  {
      "Date": "11/1/94",
      "Rate": "4247.782148"
  },
  {
      "Date": "10/31/94",
      "Rate": "4287.779326"
  },
  {
      "Date": "10/28/94",
      "Rate": "4298.506146"
  },
  {
      "Date": "10/27/94",
      "Rate": "4314.076086"
  },
  {
      "Date": "10/26/94",
      "Rate": "4301.903032"
  },
  {
      "Date": "10/25/94",
      "Rate": "4310.756803"
  },
  {
      "Date": "10/24/94",
      "Rate": "4306.329918"
  },
  {
      "Date": "10/21/94",
      "Rate": "4333.092294"
  },
  {
      "Date": "10/20/94",
      "Rate": "4322.301981"
  },
  {
      "Date": "10/19/94",
      "Rate": "4303.772553"
  },
  {
      "Date": "10/18/94",
      "Rate": "4314.838004"
  },
  {
      "Date": "10/17/94",
      "Rate": "4303.222279"
  },
  {
      "Date": "10/14/94",
      "Rate": "4283.09494"
  },
  {
      "Date": "10/13/94",
      "Rate": "4302.114676"
  },
  {
      "Date": "10/12/94",
      "Rate": "4294.922309"
  },
  {
      "Date": "10/11/94",
      "Rate": "4308.753241"
  },
  {
      "Date": "10/10/94",
      "Rate": "4336.411577"
  },
  {
      "Date": "10/7/94",
      "Rate": "4346.369425"
  },
  {
      "Date": "10/6/94",
      "Rate": "4336.20346"
  },
  {
      "Date": "10/5/94",
      "Rate": "4338.972469"
  },
  {
      "Date": "10/4/94",
      "Rate": "4360.200356"
  },
  {
      "Date": "10/3/94",
      "Rate": "4352.313092"
  },
  {
      "Date": "9/30/94",
      "Rate": "4375.424611"
  },
  {
      "Date": "9/29/94",
      "Rate": "4363.928817"
  },
  {
      "Date": "9/28/94",
      "Rate": "4397.81654"
  },
  {
      "Date": "9/27/94",
      "Rate": "4359.360835"
  },
  {
      "Date": "9/26/94",
      "Rate": "4375.279987"
  },
  {
      "Date": "9/23/94",
      "Rate": "4386.751089"
  },
  {
      "Date": "9/22/94",
      "Rate": "4358.538951"
  },
  {
      "Date": "9/21/94",
      "Rate": "4367.597312"
  },
  {
      "Date": "9/20/94",
      "Rate": "4341.1136"
  },
  {
      "Date": "9/19/94",
      "Rate": "4316.495882"
  },
  {
      "Date": "9/16/94",
      "Rate": "4296.844742"
  },
  {
      "Date": "9/15/94",
      "Rate": "4290.841108"
  },
  {
      "Date": "9/14/94",
      "Rate": "4319.607048"
  },
  {
      "Date": "9/13/94",
      "Rate": "4320.160849"
  },
  {
      "Date": "9/12/94",
      "Rate": "4332.125787"
  },
  {
      "Date": "9/9/94",
      "Rate": "4310.679201"
  },
  {
      "Date": "9/8/94",
      "Rate": "4327.282668"
  },
  {
      "Date": "9/7/94",
      "Rate": "4298.506146"
  },
  {
      "Date": "9/6/94",
      "Rate": "4290.841108"
  },
  {
      "Date": "9/5/94",
      "Rate": "4281.906206"
  },
  {
      "Date": "9/2/94",
      "Rate": "4284.117886"
  },
  {
      "Date": "9/1/94",
      "Rate": "4271.944831"
  },
  {
      "Date": "8/31/94",
      "Rate": "4274.710312"
  },
  {
      "Date": "8/30/94",
      "Rate": "4272.925448"
  },
  {
      "Date": "8/26/94",
      "Rate": "4241.048343"
  },
  {
      "Date": "8/25/94",
      "Rate": "4243.171837"
  },
  {
      "Date": "8/24/94",
      "Rate": "4233.305702"
  },
  {
      "Date": "8/23/94",
      "Rate": "4218.032064"
  },
  {
      "Date": "8/22/94",
      "Rate": "4223.249087"
  },
  {
      "Date": "8/19/94",
      "Rate": "4217.160796"
  },
  {
      "Date": "8/18/94",
      "Rate": "4180.747456"
  },
  {
      "Date": "8/17/94",
      "Rate": "4181.195436"
  },
  {
      "Date": "8/16/94",
      "Rate": "4167.2481"
  },
  {
      "Date": "8/15/94",
      "Rate": "4165.921798"
  },
  {
      "Date": "8/12/94",
      "Rate": "4179.752729"
  },
  {
      "Date": "8/11/94",
      "Rate": "4168.024127"
  },
  {
      "Date": "8/10/94",
      "Rate": "4185.728143"
  },
  {
      "Date": "8/9/94",
      "Rate": "4175.766768"
  },
  {
      "Date": "8/8/94",
      "Rate": "4179.086051"
  },
  {
      "Date": "8/5/94",
      "Rate": "4192.049242"
  },
  {
      "Date": "8/4/94",
      "Rate": "4176.874372"
  },
  {
      "Date": "8/3/94",
      "Rate": "4189.047426"
  },
  {
      "Date": "8/2/94",
      "Rate": "4246.367661"
  },
  {
      "Date": "8/1/94",
      "Rate": "4251.842184"
  },
  {
      "Date": "7/29/94",
      "Rate": "4252.734616"
  },
  {
      "Date": "7/28/94",
      "Rate": "4290.301416"
  },
  {
      "Date": "7/27/94",
      "Rate": "4281.648706"
  },
  {
      "Date": "7/26/94",
      "Rate": "4272.325791"
  },
  {
      "Date": "7/25/94",
      "Rate": "4257.859927"
  },
  {
      "Date": "7/22/94",
      "Rate": "4252.949787"
  },
  {
      "Date": "7/21/94",
      "Rate": "4264.283321"
  },
  {
      "Date": "7/20/94",
      "Rate": "4278.417609"
  },
  {
      "Date": "7/19/94",
      "Rate": "4254.611192"
  },
  {
      "Date": "7/18/94",
      "Rate": "4275.348772"
  },
  {
      "Date": "7/15/94",
      "Rate": "4258.198557"
  },
  {
      "Date": "7/14/94",
      "Rate": "4248.794511"
  },
  {
      "Date": "7/13/94",
      "Rate": "4258.198557"
  },
  {
      "Date": "7/12/94",
      "Rate": "4267.817775"
  },
  {
      "Date": "7/11/94",
      "Rate": "4253.221397"
  },
  {
      "Date": "7/8/94",
      "Rate": "4245.200092"
  },
  {
      "Date": "7/7/94",
      "Rate": "4248.364169"
  },
  {
      "Date": "7/6/94",
      "Rate": "4270.198769"
  },
  {
      "Date": "7/5/94",
      "Rate": "4290.841108"
  },
  {
      "Date": "7/4/94",
      "Rate": "4288.382511"
  },
  {
      "Date": "7/1/94",
      "Rate": "4279.775657"
  },
  {
      "Date": "6/30/94",
      "Rate": "4263.94469"
  },
  {
      "Date": "6/29/94",
      "Rate": "4268.371576"
  },
  {
      "Date": "6/28/94",
      "Rate": "4269.81781"
  },
  {
      "Date": "6/27/94",
      "Rate": "4321.046227"
  },
  {
      "Date": "6/24/94",
      "Rate": "4326.026914"
  },
  {
      "Date": "6/23/94",
      "Rate": "4312.742729"
  },
  {
      "Date": "6/22/94",
      "Rate": "4352.602339"
  },
  {
      "Date": "6/21/94",
      "Rate": "4317.723417"
  },
  {
      "Date": "6/20/94",
      "Rate": "4326.245613"
  },
  {
      "Date": "6/17/94",
      "Rate": "4271.218187"
  },
  {
      "Date": "6/16/94",
      "Rate": "4286.167305"
  },
  {
      "Date": "6/15/94",
      "Rate": "4246.307695"
  },
  {
      "Date": "6/14/94",
      "Rate": "4246.307695"
  },
  {
      "Date": "6/13/94",
      "Rate": "4235.235189"
  },
  {
      "Date": "6/10/94",
      "Rate": "4244.092488"
  },
  {
      "Date": "6/9/94",
      "Rate": "4231.912379"
  },
  {
      "Date": "6/8/94",
      "Rate": "4228.589569"
  },
  {
      "Date": "6/7/94",
      "Rate": "4213.090178"
  },
  {
      "Date": "6/6/94",
      "Rate": "4210.32117"
  },
  {
      "Date": "6/3/94",
      "Rate": "4238.00067"
  },
  {
      "Date": "6/2/94",
      "Rate": "4247.750401"
  },
  {
      "Date": "6/1/94",
      "Rate": "4287.82871"
  },
  {
      "Date": "5/31/94",
      "Rate": "4276.756204"
  },
  {
      "Date": "5/30/94",
      "Rate": "4245.196564"
  },
  {
      "Date": "5/27/94",
      "Rate": "4245.200092"
  },
  {
      "Date": "5/26/94",
      "Rate": "4272.879592"
  },
  {
      "Date": "5/25/94",
      "Rate": "4278.417609"
  },
  {
      "Date": "5/24/94",
      "Rate": "4297.23981"
  },
  {
      "Date": "5/23/94",
      "Rate": "4282.290693"
  },
  {
      "Date": "5/20/94",
      "Rate": "4224.712958"
  },
  {
      "Date": "5/19/94",
      "Rate": "4240.498069"
  },
  {
      "Date": "5/18/94",
      "Rate": "4203.407468"
  },
  {
      "Date": "5/17/94",
      "Rate": "4235.785464"
  },
  {
      "Date": "5/16/94",
      "Rate": "4214.748056"
  },
  {
      "Date": "5/13/94",
      "Rate": "4223.608882"
  },
  {
      "Date": "5/12/94",
      "Rate": "4230.684845"
  },
  {
      "Date": "5/11/94",
      "Rate": "4190.945167"
  },
  {
      "Date": "5/10/94",
      "Rate": "4225.820561"
  },
  {
      "Date": "5/9/94",
      "Rate": "4223.05508"
  },
  {
      "Date": "5/6/94",
      "Rate": "4124.869927"
  },
  {
      "Date": "5/5/94",
      "Rate": "4146.655144"
  },
  {
      "Date": "5/4/94",
      "Rate": "4156.916348"
  },
  {
      "Date": "5/3/94",
      "Rate": "4171.191732"
  },
  {
      "Date": "4/29/94",
      "Rate": "4171.911321"
  },
  {
      "Date": "4/28/94",
      "Rate": "4132.915924"
  },
  {
      "Date": "4/27/94",
      "Rate": "4152.492989"
  },
  {
      "Date": "4/26/94",
      "Rate": "4153.388949"
  },
  {
      "Date": "4/25/94",
      "Rate": "4099.268065"
  },
  {
      "Date": "4/22/94",
      "Rate": "4138.0624"
  },
  {
      "Date": "4/21/94",
      "Rate": "4135.381576"
  },
  {
      "Date": "4/20/94",
      "Rate": "4131.977636"
  },
  {
      "Date": "4/19/94",
      "Rate": "4167.921833"
  },
  {
      "Date": "4/18/94",
      "Rate": "4185.798691"
  },
  {
      "Date": "4/15/94",
      "Rate": "4171.569163"
  },
  {
      "Date": "4/14/94",
      "Rate": "4190.162084"
  },
  {
      "Date": "4/13/94",
      "Rate": "4205.904866"
  },
  {
      "Date": "4/12/94",
      "Rate": "4197.178081"
  },
  {
      "Date": "4/11/94",
      "Rate": "4268.868939"
  },
  {
      "Date": "4/8/94",
      "Rate": "4269.902467"
  },
  {
      "Date": "4/7/94",
      "Rate": "4273.387538"
  },
  {
      "Date": "4/6/94",
      "Rate": "4264.022293"
  },
  {
      "Date": "4/5/94",
      "Rate": "4299.804229"
  },
  {
      "Date": "3/31/94",
      "Rate": "4324.111536"
  },
  {
      "Date": "3/30/94",
      "Rate": "4289.65943"
  },
  {
      "Date": "3/29/94",
      "Rate": "4302.414505"
  },
  {
      "Date": "3/28/94",
      "Rate": "4328.242121"
  },
  {
      "Date": "3/25/94",
      "Rate": "4346.969082"
  },
  {
      "Date": "3/24/94",
      "Rate": "4328.743011"
  },
  {
      "Date": "3/23/94",
      "Rate": "4308.206494"
  },
  {
      "Date": "3/22/94",
      "Rate": "4290.428403"
  },
  {
      "Date": "3/21/94",
      "Rate": "4282.830385"
  },
  {
      "Date": "3/18/94",
      "Rate": "4282.544666"
  },
  {
      "Date": "3/17/94",
      "Rate": "4251.023828"
  },
  {
      "Date": "3/16/94",
      "Rate": "4274.078908"
  },
  {
      "Date": "3/15/94",
      "Rate": "4286.67525"
  },
  {
      "Date": "3/14/94",
      "Rate": "4287.514771"
  },
  {
      "Date": "3/11/94",
      "Rate": "4298.266283"
  },
  {
      "Date": "3/10/94",
      "Rate": "4206.391647"
  },
  {
      "Date": "3/9/94",
      "Rate": "4175.600981"
  },
  {
      "Date": "3/8/94",
      "Rate": "4167.530292"
  },
  {
      "Date": "3/7/94",
      "Rate": "4174.334644"
  },
  {
      "Date": "3/4/94",
      "Rate": "4162.038131"
  },
  {
      "Date": "3/3/94",
      "Rate": "4195.742429"
  },
  {
      "Date": "3/2/94",
      "Rate": "4182.056121"
  },
  {
      "Date": "3/1/94",
      "Rate": "4227.891144"
  },
  {
      "Date": "2/28/94",
      "Rate": "4225.929911"
  },
  {
      "Date": "2/25/94",
      "Rate": "4186.878075"
  },
  {
      "Date": "2/24/94",
      "Rate": "4175.664474"
  },
  {
      "Date": "2/23/94",
      "Rate": "4189.565954"
  },
  {
      "Date": "2/22/94",
      "Rate": "4193.209757"
  },
  {
      "Date": "2/21/94",
      "Rate": "4202.105857"
  },
  {
      "Date": "2/18/94",
      "Rate": "4242.081871"
  },
  {
      "Date": "2/17/94",
      "Rate": "4257.433112"
  },
  {
      "Date": "2/16/94",
      "Rate": "4257.478968"
  },
  {
      "Date": "2/15/94",
      "Rate": "4270.262262"
  },
  {
      "Date": "2/14/94",
      "Rate": "4240.201767"
  },
  {
      "Date": "2/11/94",
      "Rate": "4232.127551"
  },
  {
      "Date": "2/10/94",
      "Rate": "4242.251186"
  },
  {
      "Date": "2/9/94",
      "Rate": "4219.185524"
  },
  {
      "Date": "2/8/94",
      "Rate": "4222.01097"
  },
  {
      "Date": "2/7/94",
      "Rate": "4261.337942"
  },
  {
      "Date": "2/4/94",
      "Rate": "4300.954161"
  },
  {
      "Date": "2/3/94",
      "Rate": "4263.680135"
  },
  {
      "Date": "2/2/94",
      "Rate": "4273.440449"
  },
  {
      "Date": "2/1/94",
      "Rate": "4236.639094"
  },
  {
      "Date": "1/31/94",
      "Rate": "4185.622321"
  },
  {
      "Date": "1/28/94",
      "Rate": "4199.714281"
  },
  {
      "Date": "1/27/94",
      "Rate": "4267.793083"
  },
  {
      "Date": "1/26/94",
      "Rate": "4228.945837"
  },
  {
      "Date": "1/25/94",
      "Rate": "4251.641122"
  },
  {
      "Date": "1/24/94",
      "Rate": "4222.141484"
  },
  {
      "Date": "1/21/94",
      "Rate": "4292.417856"
  },
  {
      "Date": "1/20/94",
      "Rate": "4354.140285"
  },
  {
      "Date": "1/19/94",
      "Rate": "4360.016932"
  },
  {
      "Date": "1/18/94",
      "Rate": "4343.882608"
  },
  {
      "Date": "1/17/94",
      "Rate": "4344.990211"
  },
  {
      "Date": "1/14/94",
      "Rate": "4323.046262"
  },
  {
      "Date": "1/13/94",
      "Rate": "4258.110372"
  },
  {
      "Date": "1/12/94",
      "Rate": "4295.13748"
  },
  {
      "Date": "1/11/94",
      "Rate": "4264.191608"
  },
  {
      "Date": "1/10/94",
      "Rate": "4229.213919"
  },
  {
      "Date": "1/7/94",
      "Rate": "4323.272015"
  },
  {
      "Date": "1/6/94",
      "Rate": "4322.164412"
  },
  {
      "Date": "1/5/94",
      "Rate": "4373.26937"
  },
  {
      "Date": "1/4/94",
      "Rate": "4370.853101"
  },
  {
      "Date": "12/31/93",
      "Rate": "4341.198257"
  },
  {
      "Date": "12/30/93",
      "Rate": "4347.963809"
  },
  {
      "Date": "12/29/93",
      "Rate": "4280.435281"
  },
  {
      "Date": "12/24/93",
      "Rate": "4277.902609"
  },
  {
      "Date": "12/23/93",
      "Rate": "4290.308471"
  },
  {
      "Date": "12/22/93",
      "Rate": "4286.022681"
  },
  {
      "Date": "12/21/93",
      "Rate": "4312.17129"
  },
  {
      "Date": "12/20/93",
      "Rate": "4325.310852"
  },
  {
      "Date": "12/17/93",
      "Rate": "4269.052364"
  },
  {
      "Date": "12/16/93",
      "Rate": "4289.518334"
  },
  {
      "Date": "12/15/93",
      "Rate": "4280.678672"
  },
  {
      "Date": "12/14/93",
      "Rate": "4303.010635"
  },
  {
      "Date": "12/13/93",
      "Rate": "4301.141114"
  },
  {
      "Date": "12/10/93",
      "Rate": "4242.970775"
  },
  {
      "Date": "12/9/93",
      "Rate": "4243.584543"
  },
  {
      "Date": "12/8/93",
      "Rate": "4181.251874"
  },
  {
      "Date": "12/7/93",
      "Rate": "4146.312986"
  },
  {
      "Date": "12/6/93",
      "Rate": "4178.521667"
  },
  {
      "Date": "12/3/93",
      "Rate": "4134.743117"
  },
  {
      "Date": "12/2/93",
      "Rate": "4156.362546"
  },
  {
      "Date": "12/1/93",
      "Rate": "4097.934708"
  }
];

export const other = [
  {
      "Date": "7-Feb-24",
      "PE": "22.66",
      "PB": "3.84",
      "Div": "1.21"
  },
  {
      "Date": "6-Feb-24",
      "PE": "22.66",
      "PB": "3.84",
      "Div": "1.21"
  },
  {
      "Date": "5-Feb-24",
      "PE": "22.39",
      "PB": "3.81",
      "Div": "1.21"
  },
  {
      "Date": "2-Feb-24",
      "PE": "22.51",
      "PB": "3.83",
      "Div": "1.22"
  },
  {
      "Date": "1-Feb-24",
      "PE": "22.38",
      "PB": "3.8",
      "Div": "1.23"
  },
  {
      "Date": "31-Jan-24",
      "PE": "22.46",
      "PB": "3.81",
      "Div": "1.23"
  },
  {
      "Date": "30-Jan-24",
      "PE": "22.31",
      "PB": "3.77",
      "Div": "1.24"
  },
  {
      "Date": "29-Jan-24",
      "PE": "22.58",
      "PB": "3.81",
      "Div": "1.23"
  },
  {
      "Date": "25-Jan-24",
      "PE": "22.27",
      "PB": "3.74",
      "Div": "1.25"
  },
  {
      "Date": "24-Jan-24",
      "PE": "22.39",
      "PB": "3.76",
      "Div": "1.25"
  },
  {
      "Date": "23-Jan-24",
      "PE": "22.31",
      "PB": "3.72",
      "Div": "1.26"
  },
  {
      "Date": "20-Jan-24",
      "PE": "22.73",
      "PB": "3.78",
      "Div": "1.24"
  },
  {
      "Date": "19-Jan-24",
      "PE": "22.79",
      "PB": "3.79",
      "Div": "1.24"
  },
  {
      "Date": "18-Jan-24",
      "PE": "22.64",
      "PB": "3.76",
      "Div": "1.21"
  },
  {
      "Date": "17-Jan-24",
      "PE": "23.03",
      "PB": "3.78",
      "Div": "1.21"
  },
  {
      "Date": "16-Jan-24",
      "PE": "23.52",
      "PB": "3.86",
      "Div": "1.18"
  },
  {
      "Date": "15-Jan-24",
      "PE": "23.59",
      "PB": "3.87",
      "Div": "1.26"
  },
  {
      "Date": "12-Jan-24",
      "PE": "23.34",
      "PB": "3.83",
      "Div": "1.27"
  },
  {
      "Date": "11-Jan-24",
      "PE": "23.08",
      "PB": "3.79",
      "Div": "1.29"
  },
  {
      "Date": "10-Jan-24",
      "PE": "23.05",
      "PB": "3.79",
      "Div": "1.29"
  },
  {
      "Date": "9-Jan-24",
      "PE": "22.97",
      "PB": "3.77",
      "Div": "1.29"
  },
  {
      "Date": "8-Jan-24",
      "PE": "22.94",
      "PB": "3.77",
      "Div": "1.29"
  },
  {
      "Date": "5-Jan-24",
      "PE": "23.15",
      "PB": "3.8",
      "Div": "1.28"
  },
  {
      "Date": "4-Jan-24",
      "PE": "23.09",
      "PB": "3.79",
      "Div": "1.28"
  },
  {
      "Date": "3-Jan-24",
      "PE": "22.94",
      "PB": "3.77",
      "Div": "1.29"
  },
  {
      "Date": "2-Jan-24",
      "PE": "23.1",
      "PB": "3.79",
      "Div": "1.28"
  },
  {
      "Date": "1-Jan-24",
      "PE": "23.18",
      "PB": "3.81",
      "Div": "1.28"
  },
  {
      "Date": "29-Dec-23",
      "PE": "23.17",
      "PB": "3.81",
      "Div": "1.28"
  },
  {
      "Date": "28-Dec-23",
      "PE": "23.27",
      "PB": "3.82",
      "Div": "1.27"
  },
  {
      "Date": "27-Dec-23",
      "PE": "23.14",
      "PB": "3.8",
      "Div": "1.28"
  },
  {
      "Date": "26-Dec-23",
      "PE": "22.91",
      "PB": "3.76",
      "Div": "1.29"
  },
  {
      "Date": "22-Dec-23",
      "PE": "22.81",
      "PB": "3.74",
      "Div": "1.3"
  },
  {
      "Date": "21-Dec-23",
      "PE": "22.71",
      "PB": "3.73",
      "Div": "1.3"
  },
  {
      "Date": "20-Dec-23",
      "PE": "22.6",
      "PB": "3.71",
      "Div": "1.31"
  },
  {
      "Date": "19-Dec-23",
      "PE": "22.92",
      "PB": "3.76",
      "Div": "1.29"
  },
  {
      "Date": "18-Dec-23",
      "PE": "22.88",
      "PB": "3.76",
      "Div": "1.29"
  },
  {
      "Date": "15-Dec-23",
      "PE": "22.92",
      "PB": "3.76",
      "Div": "1.29"
  },
  {
      "Date": "14-Dec-23",
      "PE": "22.63",
      "PB": "3.71",
      "Div": "1.31"
  },
  {
      "Date": "13-Dec-23",
      "PE": "22.36",
      "PB": "3.67",
      "Div": "1.32"
  },
  {
      "Date": "12-Dec-23",
      "PE": "22.34",
      "PB": "3.67",
      "Div": "1.33"
  },
  {
      "Date": "11-Dec-23",
      "PE": "22.44",
      "PB": "3.68",
      "Div": "1.3"
  },
  {
      "Date": "8-Dec-23",
      "PE": "22.41",
      "PB": "3.68",
      "Div": "1.3"
  },
  {
      "Date": "7-Dec-23",
      "PE": "22.34",
      "PB": "3.67",
      "Div": "1.3"
  },
  {
      "Date": "6-Dec-23",
      "PE": "22.38",
      "PB": "3.67",
      "Div": "1.3"
  },
  {
      "Date": "5-Dec-23",
      "PE": "22.29",
      "PB": "3.66",
      "Div": "1.31"
  },
  {
      "Date": "4-Dec-23",
      "PE": "22.11",
      "PB": "3.63",
      "Div": "1.32"
  },
  {
      "Date": "1-Dec-23",
      "PE": "21.66",
      "PB": "3.55",
      "Div": "1.34"
  },
  {
      "Date": "30-Nov-23",
      "PE": "21.52",
      "PB": "3.53",
      "Div": "1.35"
  },
  {
      "Date": "29-Nov-23",
      "PE": "21.48",
      "PB": "3.52",
      "Div": "1.36"
  },
  {
      "Date": "28-Nov-23",
      "PE": "21.26",
      "PB": "3.49",
      "Div": "1.37"
  },
  {
      "Date": "24-Nov-23",
      "PE": "21.15",
      "PB": "3.47",
      "Div": "1.38"
  },
  {
      "Date": "23-Nov-23",
      "PE": "21.16",
      "PB": "3.47",
      "Div": "1.38"
  },
  {
      "Date": "22-Nov-23",
      "PE": "21.17",
      "PB": "3.47",
      "Div": "1.38"
  },
  {
      "Date": "21-Nov-23",
      "PE": "21.14",
      "PB": "3.47",
      "Div": "1.38"
  },
  {
      "Date": "20-Nov-23",
      "PE": "21.06",
      "PB": "3.46",
      "Div": "1.35"
  },
  {
      "Date": "17-Nov-23",
      "PE": "21.1",
      "PB": "3.46",
      "Div": "1.34"
  },
  {
      "Date": "16-Nov-23",
      "PE": "21.13",
      "PB": "3.47",
      "Div": "1.34"
  },
  {
      "Date": "15-Nov-23",
      "PE": "21.05",
      "PB": "3.45",
      "Div": "1.33"
  },
  {
      "Date": "13-Nov-23",
      "PE": "20.96",
      "PB": "3.41",
      "Div": "1.4"
  },
  {
      "Date": "12-Nov-23",
      "PE": "21.05",
      "PB": "3.43",
      "Div": "1.4"
  },
  {
      "Date": "10-Nov-23",
      "PE": "20.95",
      "PB": "3.41",
      "Div": "1.4"
  },
  {
      "Date": "9-Nov-23",
      "PE": "20.91",
      "PB": "3.4",
      "Div": "1.41"
  },
  {
      "Date": "8-Nov-23",
      "PE": "20.97",
      "PB": "3.41",
      "Div": "1.42"
  },
  {
      "Date": "7-Nov-23",
      "PE": "20.92",
      "PB": "3.41",
      "Div": "1.42"
  },
  {
      "Date": "6-Nov-23",
      "PE": "20.96",
      "PB": "3.41",
      "Div": "1.42"
  },
  {
      "Date": "3-Nov-23",
      "PE": "20.88",
      "PB": "3.37",
      "Div": "1.43"
  },
  {
      "Date": "2-Nov-23",
      "PE": "20.77",
      "PB": "3.36",
      "Div": "1.42"
  },
  {
      "Date": "1-Nov-23",
      "PE": "20.4",
      "PB": "3.33",
      "Div": "1.41"
  },
  {
      "Date": "31-Oct-23",
      "PE": "20.45",
      "PB": "3.35",
      "Div": "1.41"
  },
  {
      "Date": "30-Oct-23",
      "PE": "20.9",
      "PB": "3.36",
      "Div": "1.41"
  },
  {
      "Date": "27-Oct-23",
      "PE": "20.84",
      "PB": "3.34",
      "Div": "1.42"
  },
  {
      "Date": "26-Oct-23",
      "PE": "20.63",
      "PB": "3.31",
      "Div": "1.51"
  },
  {
      "Date": "25-Oct-23",
      "PE": "20.92",
      "PB": "3.36",
      "Div": "1.48"
  },
  {
      "Date": "23-Oct-23",
      "PE": "21.36",
      "PB": "3.38",
      "Div": "1.4"
  },
  {
      "Date": "20-Oct-23",
      "PE": "21.68",
      "PB": "3.43",
      "Div": "1.38"
  },
  {
      "Date": "19-Oct-23",
      "PE": "21.77",
      "PB": "3.44",
      "Div": "1.36"
  },
  {
      "Date": "18-Oct-23",
      "PE": "21.87",
      "PB": "3.45",
      "Div": "1.36"
  },
  {
      "Date": "17-Oct-23",
      "PE": "22.38",
      "PB": "3.48",
      "Div": "1.35"
  },
  {
      "Date": "16-Oct-23",
      "PE": "22.29",
      "PB": "3.46",
      "Div": "1.36"
  },
  {
      "Date": "13-Oct-23",
      "PE": "22.33",
      "PB": "3.47",
      "Div": "1.36"
  },
  {
      "Date": "12-Oct-23",
      "PE": "22.39",
      "PB": "3.47",
      "Div": "1.36"
  },
  {
      "Date": "11-Oct-23",
      "PE": "22.41",
      "PB": "3.48",
      "Div": "1.36"
  },
  {
      "Date": "10-Oct-23",
      "PE": "22.27",
      "PB": "3.46",
      "Div": "1.36"
  },
  {
      "Date": "9-Oct-23",
      "PE": "22.07",
      "PB": "3.42",
      "Div": "1.38"
  },
  {
      "Date": "6-Oct-23",
      "PE": "22.23",
      "PB": "3.45",
      "Div": "1.37"
  },
  {
      "Date": "5-Oct-23",
      "PE": "22.11",
      "PB": "3.43",
      "Div": "1.37"
  },
  {
      "Date": "4-Oct-23",
      "PE": "21.98",
      "PB": "3.41",
      "Div": "1.38"
  },
  {
      "Date": "3-Oct-23",
      "PE": "22.09",
      "PB": "3.43",
      "Div": "1.38"
  },
  {
      "Date": "29-Sep-23",
      "PE": "22.21",
      "PB": "3.45",
      "Div": "1.37"
  },
  {
      "Date": "28-Sep-23",
      "PE": "22.05",
      "PB": "4.31",
      "Div": "1.38"
  },
  {
      "Date": "27-Sep-23",
      "PE": "22.27",
      "PB": "4.36",
      "Div": "1.36"
  },
  {
      "Date": "26-Sep-23",
      "PE": "22.21",
      "PB": "4.35",
      "Div": "1.37"
  },
  {
      "Date": "25-Sep-23",
      "PE": "22.22",
      "PB": "4.36",
      "Div": "1.37"
  },
  {
      "Date": "22-Sep-23",
      "PE": "22.22",
      "PB": "4.36",
      "Div": "1.37"
  },
  {
      "Date": "21-Sep-23",
      "PE": "22.3",
      "PB": "4.37",
      "Div": "1.36"
  },
  {
      "Date": "20-Sep-23",
      "PE": "22.48",
      "PB": "4.41",
      "Div": "1.35"
  },
  {
      "Date": "18-Sep-23",
      "PE": "22.74",
      "PB": "4.46",
      "Div": "1.34"
  },
  {
      "Date": "15-Sep-23",
      "PE": "22.81",
      "PB": "4.47",
      "Div": "1.33"
  },
  {
      "Date": "14-Sep-23",
      "PE": "22.71",
      "PB": "4.47",
      "Div": "1.34"
  },
  {
      "Date": "13-Sep-23",
      "PE": "22.67",
      "PB": "4.46",
      "Div": "1.34"
  },
  {
      "Date": "12-Sep-23",
      "PE": "22.58",
      "PB": "4.44",
      "Div": "1.35"
  },
  {
      "Date": "11-Sep-23",
      "PE": "22.59",
      "PB": "4.46",
      "Div": "1.35"
  },
  {
      "Date": "8-Sep-23",
      "PE": "22.39",
      "PB": "4.43",
      "Div": "1.36"
  },
  {
      "Date": "7-Sep-23",
      "PE": "22.28",
      "PB": "4.41",
      "Div": "1.36"
  },
  {
      "Date": "6-Sep-23",
      "PE": "22.37",
      "PB": "4.43",
      "Div": "1.36"
  },
  {
      "Date": "5-Sep-23",
      "PE": "22.33",
      "PB": "4.46",
      "Div": "1.36"
  },
  {
      "Date": "4-Sep-23",
      "PE": "22.28",
      "PB": "4.46",
      "Div": "1.36"
  },
  {
      "Date": "1-Sep-23",
      "PE": "22.17",
      "PB": "4.44",
      "Div": "1.37"
  },
  {
      "Date": "31-Aug-23",
      "PE": "21.97",
      "PB": "4.4",
      "Div": "1.38"
  },
  {
      "Date": "30-Aug-23",
      "PE": "22.07",
      "PB": "4.42",
      "Div": "1.38"
  },
  {
      "Date": "29-Aug-23",
      "PE": "22.07",
      "PB": "4.42",
      "Div": "1.38"
  },
  {
      "Date": "28-Aug-23",
      "PE": "22.03",
      "PB": "4.43",
      "Div": "1.38"
  },
  {
      "Date": "25-Aug-23",
      "PE": "21.98",
      "PB": "4.44",
      "Div": "1.38"
  },
  {
      "Date": "24-Aug-23",
      "PE": "22.12",
      "PB": "4.47",
      "Div": "1.37"
  },
  {
      "Date": "23-Aug-23",
      "PE": "22.19",
      "PB": "4.56",
      "Div": "1.37"
  },
  {
      "Date": "22-Aug-23",
      "PE": "22.13",
      "PB": "4.55",
      "Div": "1.37"
  },
  {
      "Date": "21-Aug-23",
      "PE": "22.42",
      "PB": "4.66",
      "Div": "1.37"
  },
  {
      "Date": "18-Aug-23",
      "PE": "22.32",
      "PB": "4.64",
      "Div": "1.36"
  },
  {
      "Date": "17-Aug-23",
      "PE": "22.39",
      "PB": "4.66",
      "Div": "1.39"
  },
  {
      "Date": "16-Aug-23",
      "PE": "22.52",
      "PB": "4.68",
      "Div": "1.39"
  },
  {
      "Date": "14-Aug-23",
      "PE": "22.64",
      "PB": "4.7",
      "Div": "1.39"
  },
  {
      "Date": "11-Aug-23",
      "PE": "22.63",
      "PB": "4.7",
      "Div": "1.38"
  },
  {
      "Date": "10-Aug-23",
      "PE": "22.77",
      "PB": "4.73",
      "Div": "1.36"
  },
  {
      "Date": "9-Aug-23",
      "PE": "22.81",
      "PB": "4.75",
      "Div": "1.37"
  },
  {
      "Date": "8-Aug-23",
      "PE": "22.73",
      "PB": "4.73",
      "Div": "1.31"
  },
  {
      "Date": "7-Aug-23",
      "PE": "22.83",
      "PB": "4.74",
      "Div": "1.34"
  },
  {
      "Date": "4-Aug-23",
      "PE": "22.71",
      "PB": "4.72",
      "Div": "1.34"
  },
  {
      "Date": "3-Aug-23",
      "PE": "22.55",
      "PB": "4.69",
      "Div": "1.35"
  },
  {
      "Date": "2-Aug-23",
      "PE": "22.72",
      "PB": "4.73",
      "Div": "1.33"
  },
  {
      "Date": "1-Aug-23",
      "PE": "22.96",
      "PB": "4.82",
      "Div": "1.28"
  },
  {
      "Date": "31-Jul-23",
      "PE": "23.01",
      "PB": "4.83",
      "Div": "1.28"
  },
  {
      "Date": "28-Jul-23",
      "PE": "22.91",
      "PB": "4.8",
      "Div": "1.29"
  },
  {
      "Date": "27-Jul-23",
      "PE": "23.83",
      "PB": "4.8",
      "Div": "1.28"
  },
  {
      "Date": "26-Jul-23",
      "PE": "23.97",
      "PB": "4.83",
      "Div": "1.27"
  },
  {
      "Date": "25-Jul-23",
      "PE": "23.55",
      "PB": "4.81",
      "Div": "1.28"
  },
  {
      "Date": "24-Jul-23",
      "PE": "23.83",
      "PB": "4.81",
      "Div": "1.28"
  },
  {
      "Date": "21-Jul-23",
      "PE": "23.95",
      "PB": "4.82",
      "Div": "1.27"
  },
  {
      "Date": "20-Jul-23",
      "PE": "24.24",
      "PB": "4.88",
      "Div": "1.28"
  },
  {
      "Date": "19-Jul-23",
      "PE": "24.09",
      "PB": "4.85",
      "Div": "1.27"
  },
  {
      "Date": "18-Jul-23",
      "PE": "24.18",
      "PB": "4.83",
      "Div": "1.28"
  },
  {
      "Date": "17-Jul-23",
      "PE": "24.14",
      "PB": "4.82",
      "Div": "1.29"
  },
  {
      "Date": "14-Jul-23",
      "PE": "23.96",
      "PB": "4.78",
      "Div": "1.3"
  },
  {
      "Date": "13-Jul-23",
      "PE": "23.81",
      "PB": "4.74",
      "Div": "1.32"
  },
  {
      "Date": "12-Jul-23",
      "PE": "22.58",
      "PB": "4.54",
      "Div": "1.38"
  },
  {
      "Date": "11-Jul-23",
      "PE": "22.65",
      "PB": "4.56",
      "Div": "1.38"
  },
  {
      "Date": "10-Jul-23",
      "PE": "22.55",
      "PB": "4.54",
      "Div": "1.38"
  },
  {
      "Date": "7-Jul-23",
      "PE": "22.52",
      "PB": "4.53",
      "Div": "1.39"
  },
  {
      "Date": "6-Jul-23",
      "PE": "22.72",
      "PB": "4.57",
      "Div": "1.37"
  },
  {
      "Date": "5-Jul-23",
      "PE": "22.6",
      "PB": "4.55",
      "Div": "1.38"
  },
  {
      "Date": "4-Jul-23",
      "PE": "22.59",
      "PB": "4.54",
      "Div": "1.38"
  },
  {
      "Date": "3-Jul-23",
      "PE": "22.51",
      "PB": "4.53",
      "Div": "1.4"
  },
  {
      "Date": "30-Jun-23",
      "PE": "22.36",
      "PB": "4.5",
      "Div": "1.41"
  },
  {
      "Date": "28-Jun-23",
      "PE": "22.08",
      "PB": "4.43",
      "Div": "1.43"
  },
  {
      "Date": "27-Jun-23",
      "PE": "21.9",
      "PB": "4.4",
      "Div": "1.44"
  },
  {
      "Date": "26-Jun-23",
      "PE": "21.76",
      "PB": "4.37",
      "Div": "1.45"
  },
  {
      "Date": "23-Jun-23",
      "PE": "21.73",
      "PB": "4.36",
      "Div": "1.45"
  },
  {
      "Date": "22-Jun-23",
      "PE": "21.85",
      "PB": "4.39",
      "Div": "1.44"
  },
  {
      "Date": "21-Jun-23",
      "PE": "21.96",
      "PB": "4.41",
      "Div": "1.4"
  },
  {
      "Date": "20-Jun-23",
      "PE": "21.91",
      "PB": "4.4",
      "Div": "1.4"
  },
  {
      "Date": "19-Jun-23",
      "PE": "21.84",
      "PB": "4.38",
      "Div": "1.42"
  },
  {
      "Date": "16-Jun-23",
      "PE": "21.92",
      "PB": "4.4",
      "Div": "1.39"
  },
  {
      "Date": "15-Jun-23",
      "PE": "21.76",
      "PB": "4.37",
      "Div": "1.39"
  },
  {
      "Date": "14-Jun-23",
      "PE": "21.84",
      "PB": "4.38",
      "Div": "1.43"
  },
  {
      "Date": "13-Jun-23",
      "PE": "21.79",
      "PB": "4.37",
      "Div": "1.43"
  },
  {
      "Date": "12-Jun-23",
      "PE": "21.66",
      "PB": "4.35",
      "Div": "1.44"
  },
  {
      "Date": "9-Jun-23",
      "PE": "21.62",
      "PB": "4.34",
      "Div": "1.45"
  },
  {
      "Date": "8-Jun-23",
      "PE": "21.7",
      "PB": "4.36",
      "Div": "1.44"
  },
  {
      "Date": "7-Jun-23",
      "PE": "21.81",
      "PB": "4.38",
      "Div": "1.43"
  },
  {
      "Date": "6-Jun-23",
      "PE": "21.66",
      "PB": "4.35",
      "Div": "1.44"
  },
  {
      "Date": "5-Jun-23",
      "PE": "21.65",
      "PB": "4.35",
      "Div": "1.44"
  },
  {
      "Date": "2-Jun-23",
      "PE": "21.58",
      "PB": "4.33",
      "Div": "1.45"
  },
  {
      "Date": "1-Jun-23",
      "PE": "21.53",
      "PB": "4.32",
      "Div": "1.36"
  },
  {
      "Date": "31-May-23",
      "PE": "21.59",
      "PB": "4.33",
      "Div": "1.36"
  },
  {
      "Date": "30-May-23",
      "PE": "21.7",
      "PB": "4.36",
      "Div": "1.44"
  },
  {
      "Date": "29-May-23",
      "PE": "21.65",
      "PB": "4.35",
      "Div": "1.34"
  },
  {
      "Date": "26-May-23",
      "PE": "21.54",
      "PB": "4.33",
      "Div": "1.34"
  },
  {
      "Date": "25-May-23",
      "PE": "21.28",
      "PB": "4.29",
      "Div": "1.43"
  },
  {
      "Date": "24-May-23",
      "PE": "21.24",
      "PB": "4.28",
      "Div": "1.49"
  },
  {
      "Date": "23-May-23",
      "PE": "21.41",
      "PB": "4.29",
      "Div": "1.49"
  },
  {
      "Date": "22-May-23",
      "PE": "21.36",
      "PB": "4.29",
      "Div": "1.49"
  },
  {
      "Date": "19-May-23",
      "PE": "21.48",
      "PB": "4.26",
      "Div": "1.5"
  },
  {
      "Date": "18-May-23",
      "PE": "21.39",
      "PB": "4.24",
      "Div": "1.5"
  },
  {
      "Date": "17-May-23",
      "PE": "21.47",
      "PB": "4.26",
      "Div": "1.49"
  },
  {
      "Date": "16-May-23",
      "PE": "21.59",
      "PB": "4.28",
      "Div": "1.48"
  },
  {
      "Date": "15-May-23",
      "PE": "21.92",
      "PB": "4.31",
      "Div": "1.27"
  },
  {
      "Date": "12-May-23",
      "PE": "21.82",
      "PB": "4.29",
      "Div": "1.28"
  },
  {
      "Date": "11-May-23",
      "PE": "21.86",
      "PB": "4.28",
      "Div": "1.36"
  },
  {
      "Date": "10-May-23",
      "PE": "21.88",
      "PB": "4.29",
      "Div": "1.36"
  },
  {
      "Date": "9-May-23",
      "PE": "21.77",
      "PB": "4.28",
      "Div": "1.37"
  },
  {
      "Date": "8-May-23",
      "PE": "21.77",
      "PB": "4.28",
      "Div": "1.37"
  },
  {
      "Date": "5-May-23",
      "PE": "21.62",
      "PB": "4.23",
      "Div": "1.38"
  },
  {
      "Date": "4-May-23",
      "PE": "21.85",
      "PB": "4.27",
      "Div": "1.37"
  },
  {
      "Date": "3-May-23",
      "PE": "21.33",
      "PB": "4.23",
      "Div": "1.38"
  },
  {
      "Date": "2-May-23",
      "PE": "20.92",
      "PB": "4.25",
      "Div": "1.38"
  },
  {
      "Date": "28-Apr-23",
      "PE": "20.87",
      "PB": "4.23",
      "Div": "1.38"
  },
  {
      "Date": "27-Apr-23",
      "PE": "20.7",
      "PB": "4.19",
      "Div": "1.39"
  },
  {
      "Date": "26-Apr-23",
      "PE": "20.59",
      "PB": "4.17",
      "Div": "1.4"
  },
  {
      "Date": "25-Apr-23",
      "PE": "20.56",
      "PB": "4.16",
      "Div": "1.41"
  },
  {
      "Date": "24-Apr-23",
      "PE": "20.74",
      "PB": "4.15",
      "Div": "1.41"
  },
  {
      "Date": "21-Apr-23",
      "PE": "20.61",
      "PB": "4.13",
      "Div": "1.42"
  },
  {
      "Date": "20-Apr-23",
      "PE": "20.61",
      "PB": "4.13",
      "Div": "1.42"
  },
  {
      "Date": "19-Apr-23",
      "PE": "20.6",
      "PB": "4.12",
      "Div": "1.42"
  },
  {
      "Date": "18-Apr-23",
      "PE": "20.65",
      "PB": "4.13",
      "Div": "1.41"
  },
  {
      "Date": "17-Apr-23",
      "PE": "20.82",
      "PB": "4.14",
      "Div": "1.41"
  },
  {
      "Date": "13-Apr-23",
      "PE": "20.99",
      "PB": "4.17",
      "Div": "1.4"
  },
  {
      "Date": "12-Apr-23",
      "PE": "20.97",
      "PB": "4.17",
      "Div": "1.39"
  },
  {
      "Date": "11-Apr-23",
      "PE": "20.86",
      "PB": "4.15",
      "Div": "1.4"
  },
  {
      "Date": "10-Apr-23",
      "PE": "20.75",
      "PB": "4.13",
      "Div": "1.41"
  },
  {
      "Date": "6-Apr-23",
      "PE": "20.72",
      "PB": "4.12",
      "Div": "1.41"
  },
  {
      "Date": "5-Apr-23",
      "PE": "20.67",
      "PB": "4.11",
      "Div": "1.41"
  },
  {
      "Date": "3-Apr-23",
      "PE": "20.48",
      "PB": "4.07",
      "Div": "1.43"
  },
  {
      "Date": "31-Mar-23",
      "PE": "20.44",
      "PB": "4.06",
      "Div": "1.44"
  },
  {
      "Date": "29-Mar-23",
      "PE": "20.13",
      "PB": "4",
      "Div": "1.46"
  },
  {
      "Date": "28-Mar-23",
      "PE": "19.97",
      "PB": "3.97",
      "Div": "1.47"
  },
  {
      "Date": "27-Mar-23",
      "PE": "20.01",
      "PB": "3.98",
      "Div": "1.47"
  },
  {
      "Date": "24-Mar-23",
      "PE": "19.97",
      "PB": "3.97",
      "Div": "1.47"
  },
  {
      "Date": "23-Mar-23",
      "PE": "20.12",
      "PB": "4",
      "Div": "1.46"
  },
  {
      "Date": "22-Mar-23",
      "PE": "20.21",
      "PB": "4.02",
      "Div": "1.45"
  },
  {
      "Date": "21-Mar-23",
      "PE": "20.16",
      "PB": "4.01",
      "Div": "1.46"
  },
  {
      "Date": "20-Mar-23",
      "PE": "20.02",
      "PB": "3.98",
      "Div": "1.47"
  },
  {
      "Date": "17-Mar-23",
      "PE": "20.15",
      "PB": "4",
      "Div": "1.46"
  },
  {
      "Date": "16-Mar-23",
      "PE": "20.01",
      "PB": "3.98",
      "Div": "1.47"
  },
  {
      "Date": "15-Mar-23",
      "PE": "20",
      "PB": "3.97",
      "Div": "1.47"
  },
  {
      "Date": "14-Mar-23",
      "PE": "20.08",
      "PB": "3.99",
      "Div": "1.46"
  },
  {
      "Date": "13-Mar-23",
      "PE": "20.21",
      "PB": "4.02",
      "Div": "1.45"
  },
  {
      "Date": "10-Mar-23",
      "PE": "20.52",
      "PB": "4.08",
      "Div": "1.43"
  },
  {
      "Date": "9-Mar-23",
      "PE": "20.73",
      "PB": "4.12",
      "Div": "1.41"
  },
  {
      "Date": "8-Mar-23",
      "PE": "20.92",
      "PB": "4.16",
      "Div": "1.4"
  },
  {
      "Date": "6-Mar-23",
      "PE": "20.87",
      "PB": "4.15",
      "Div": "1.41"
  },
  {
      "Date": "3-Mar-23",
      "PE": "20.73",
      "PB": "4.12",
      "Div": "1.41"
  },
  {
      "Date": "2-Mar-23",
      "PE": "20.41",
      "PB": "4.06",
      "Div": "1.44"
  },
  {
      "Date": "1-Mar-23",
      "PE": "20.56",
      "PB": "4.09",
      "Div": "1.43"
  },
  {
      "Date": "28-Feb-23",
      "PE": "20.39",
      "PB": "4.05",
      "Div": "1.44"
  },
  {
      "Date": "27-Feb-23",
      "PE": "20.49",
      "PB": "4.07",
      "Div": "1.43"
  },
  {
      "Date": "24-Feb-23",
      "PE": "20.58",
      "PB": "4.09",
      "Div": "1.42"
  },
  {
      "Date": "23-Feb-23",
      "PE": "20.64",
      "PB": "4.1",
      "Div": "1.4"
  },
  {
      "Date": "22-Feb-23",
      "PE": "20.69",
      "PB": "4.11",
      "Div": "1.4"
  },
  {
      "Date": "21-Feb-23",
      "PE": "21.01",
      "PB": "4.18",
      "Div": "1.38"
  },
  {
      "Date": "20-Feb-23",
      "PE": "21.04",
      "PB": "4.18",
      "Div": "1.41"
  },
  {
      "Date": "17-Feb-23",
      "PE": "21.15",
      "PB": "4.2",
      "Div": "1.4"
  },
  {
      "Date": "16-Feb-23",
      "PE": "21.26",
      "PB": "4.23",
      "Div": "1.38"
  },
  {
      "Date": "15-Feb-23",
      "PE": "21.31",
      "PB": "4.22",
      "Div": "1.41"
  },
  {
      "Date": "14-Feb-23",
      "PE": "21.21",
      "PB": "4.2",
      "Div": "1.35"
  },
  {
      "Date": "13-Feb-23",
      "PE": "21.05",
      "PB": "4.16",
      "Div": "1.42"
  },
  {
      "Date": "10-Feb-23",
      "PE": "21.06",
      "PB": "4.18",
      "Div": "1.41"
  },
  {
      "Date": "9-Feb-23",
      "PE": "21.11",
      "PB": "4.19",
      "Div": "1.41"
  },
  {
      "Date": "8-Feb-23",
      "PE": "21.1",
      "PB": "4.19",
      "Div": "1.42"
  },
  {
      "Date": "7-Feb-23",
      "PE": "20.5",
      "PB": "4.15",
      "Div": "1.39"
  },
  {
      "Date": "6-Feb-23",
      "PE": "20.71",
      "PB": "4.16",
      "Div": "1.39"
  },
  {
      "Date": "3-Feb-23",
      "PE": "20.86",
      "PB": "4.18",
      "Div": "1.38"
  },
  {
      "Date": "2-Feb-23",
      "PE": "20.59",
      "PB": "4.13",
      "Div": "1.4"
  },
  {
      "Date": "1-Feb-23",
      "PE": "20.68",
      "PB": "4.13",
      "Div": "1.4"
  },
  {
      "Date": "31-Jan-23",
      "PE": "20.73",
      "PB": "4.14",
      "Div": "1.39"
  },
  {
      "Date": "30-Jan-23",
      "PE": "20.73",
      "PB": "4.14",
      "Div": "1.39"
  },
  {
      "Date": "27-Jan-23",
      "PE": "20.7",
      "PB": "4.13",
      "Div": "1.4"
  },
  {
      "Date": "25-Jan-23",
      "PE": "21.19",
      "PB": "4.19",
      "Div": "1.37"
  },
  {
      "Date": "24-Jan-23",
      "PE": "21.49",
      "PB": "4.25",
      "Div": "1.36"
  },
  {
      "Date": "23-Jan-23",
      "PE": "21.6",
      "PB": "4.25",
      "Div": "1.35"
  },
  {
      "Date": "20-Jan-23",
      "PE": "21.46",
      "PB": "4.23",
      "Div": "1.36"
  },
  {
      "Date": "19-Jan-23",
      "PE": "21.56",
      "PB": "4.24",
      "Div": "1.37"
  },
  {
      "Date": "18-Jan-23",
      "PE": "21.67",
      "PB": "4.26",
      "Div": "1.36"
  },
  {
      "Date": "17-Jan-23",
      "PE": "21.53",
      "PB": "4.23",
      "Div": "1.37"
  },
  {
      "Date": "16-Jan-23",
      "PE": "21.34",
      "PB": "4.19",
      "Div": "1.38"
  },
  {
      "Date": "13-Jan-23",
      "PE": "21.54",
      "PB": "4.21",
      "Div": "1.28"
  },
  {
      "Date": "12-Jan-23",
      "PE": "21.47",
      "PB": "4.19",
      "Div": "1.29"
  },
  {
      "Date": "11-Jan-23",
      "PE": "21.52",
      "PB": "4.2",
      "Div": "1.28"
  },
  {
      "Date": "10-Jan-23",
      "PE": "21.56",
      "PB": "4.2",
      "Div": "1.28"
  },
  {
      "Date": "9-Jan-23",
      "PE": "21.78",
      "PB": "4.25",
      "Div": "1.27"
  },
  {
      "Date": "6-Jan-23",
      "PE": "21.49",
      "PB": "4.19",
      "Div": "1.29"
  },
  {
      "Date": "5-Jan-23",
      "PE": "21.65",
      "PB": "4.22",
      "Div": "1.28"
  },
  {
      "Date": "4-Jan-23",
      "PE": "21.71",
      "PB": "4.23",
      "Div": "1.27"
  },
  {
      "Date": "3-Jan-23",
      "PE": "21.94",
      "PB": "4.28",
      "Div": "1.26"
  },
  {
      "Date": "2-Jan-23",
      "PE": "21.9",
      "PB": "4.27",
      "Div": "1.26"
  },
  {
      "Date": "30-Dec-22",
      "PE": "21.79",
      "PB": "4.25",
      "Div": "1.27"
  },
  {
      "Date": "29-Dec-22",
      "PE": "21.86",
      "PB": "4.27",
      "Div": "1.27"
  },
  {
      "Date": "28-Dec-22",
      "PE": "21.78",
      "PB": "4.25",
      "Div": "1.27"
  },
  {
      "Date": "27-Dec-22",
      "PE": "21.79",
      "PB": "4.26",
      "Div": "1.27"
  },
  {
      "Date": "26-Dec-22",
      "PE": "21.65",
      "PB": "4.23",
      "Div": "1.28"
  },
  {
      "Date": "23-Dec-22",
      "PE": "21.4",
      "PB": "4.18",
      "Div": "1.29"
  },
  {
      "Date": "22-Dec-22",
      "PE": "21.79",
      "PB": "4.26",
      "Div": "1.27"
  },
  {
      "Date": "21-Dec-22",
      "PE": "21.87",
      "PB": "4.27",
      "Div": "1.29"
  },
  {
      "Date": "20-Dec-22",
      "PE": "22.1",
      "PB": "4.32",
      "Div": "1.28"
  },
  {
      "Date": "19-Dec-22",
      "PE": "22.14",
      "PB": "4.32",
      "Div": "1.28"
  },
  {
      "Date": "16-Dec-22",
      "PE": "21.96",
      "PB": "4.29",
      "Div": "1.29"
  },
  {
      "Date": "15-Dec-22",
      "PE": "22.13",
      "PB": "4.32",
      "Div": "1.28"
  },
  {
      "Date": "14-Dec-22",
      "PE": "22.43",
      "PB": "4.38",
      "Div": "1.26"
  },
  {
      "Date": "13-Dec-22",
      "PE": "22.36",
      "PB": "4.37",
      "Div": "1.27"
  },
  {
      "Date": "12-Dec-22",
      "PE": "22.23",
      "PB": "4.34",
      "Div": "1.27"
  },
  {
      "Date": "9-Dec-22",
      "PE": "22.23",
      "PB": "4.34",
      "Div": "1.27"
  },
  {
      "Date": "8-Dec-22",
      "PE": "22.36",
      "PB": "4.37",
      "Div": "1.27"
  },
  {
      "Date": "7-Dec-22",
      "PE": "22.31",
      "PB": "4.36",
      "Div": "1.27"
  },
  {
      "Date": "6-Dec-22",
      "PE": "22.4",
      "PB": "4.38",
      "Div": "1.26"
  },
  {
      "Date": "5-Dec-22",
      "PE": "22.47",
      "PB": "4.39",
      "Div": "1.28"
  },
  {
      "Date": "2-Dec-22",
      "PE": "22.47",
      "PB": "4.39",
      "Div": "1.28"
  },
  {
      "Date": "1-Dec-22",
      "PE": "22.61",
      "PB": "4.42",
      "Div": "1.28"
  },
  {
      "Date": "30-Nov-22",
      "PE": "22.54",
      "PB": "4.4",
      "Div": "1.28"
  },
  {
      "Date": "29-Nov-22",
      "PE": "22.37",
      "PB": "4.37",
      "Div": "1.29"
  },
  {
      "Date": "28-Nov-22",
      "PE": "22.31",
      "PB": "4.36",
      "Div": "1.29"
  },
  {
      "Date": "25-Nov-22",
      "PE": "22.25",
      "PB": "4.35",
      "Div": "1.3"
  },
  {
      "Date": "24-Nov-22",
      "PE": "22.21",
      "PB": "4.34",
      "Div": "1.3"
  },
  {
      "Date": "23-Nov-22",
      "PE": "21.95",
      "PB": "4.29",
      "Div": "1.31"
  },
  {
      "Date": "22-Nov-22",
      "PE": "21.93",
      "PB": "4.28",
      "Div": "1.32"
  },
  {
      "Date": "21-Nov-22",
      "PE": "21.82",
      "PB": "4.26",
      "Div": "1.35"
  },
  {
      "Date": "18-Nov-22",
      "PE": "21.79",
      "PB": "4.3",
      "Div": "1.3"
  },
  {
      "Date": "17-Nov-22",
      "PE": "21.83",
      "PB": "4.31",
      "Div": "1.3"
  },
  {
      "Date": "16-Nov-22",
      "PE": "21.91",
      "PB": "4.32",
      "Div": "1.3"
  },
  {
      "Date": "15-Nov-22",
      "PE": "21.89",
      "PB": "4.32",
      "Div": "1.3"
  },
  {
      "Date": "14-Nov-22",
      "PE": "21.79",
      "PB": "4.31",
      "Div": "1.26"
  },
  {
      "Date": "11-Nov-22",
      "PE": "21.92",
      "PB": "4.31",
      "Div": "1.24"
  },
  {
      "Date": "10-Nov-22",
      "PE": "21.53",
      "PB": "4.24",
      "Div": "1.27"
  },
  {
      "Date": "9-Nov-22",
      "PE": "21.66",
      "PB": "4.27",
      "Div": "1.26"
  },
  {
      "Date": "7-Nov-22",
      "PE": "21.71",
      "PB": "4.28",
      "Div": "1.24"
  },
  {
      "Date": "4-Nov-22",
      "PE": "21.77",
      "PB": "4.26",
      "Div": "1.25"
  },
  {
      "Date": "3-Nov-22",
      "PE": "21.79",
      "PB": "4.24",
      "Div": "1.25"
  },
  {
      "Date": "2-Nov-22",
      "PE": "21.82",
      "PB": "4.25",
      "Div": "1.25"
  },
  {
      "Date": "1-Nov-22",
      "PE": "21.98",
      "PB": "4.26",
      "Div": "1.26"
  },
  {
      "Date": "31-Oct-22",
      "PE": "21.42",
      "PB": "4.23",
      "Div": "1.25"
  },
  {
      "Date": "28-Oct-22",
      "PE": "21.15",
      "PB": "4.18",
      "Div": "1.26"
  },
  {
      "Date": "27-Oct-22",
      "PE": "21.1",
      "PB": "4.17",
      "Div": "1.26"
  },
  {
      "Date": "25-Oct-22",
      "PE": "21",
      "PB": "4.15",
      "Div": "1.28"
  },
  {
      "Date": "24-Oct-22",
      "PE": "21.26",
      "PB": "4.17",
      "Div": "1.28"
  },
  {
      "Date": "21-Oct-22",
      "PE": "21.07",
      "PB": "4.13",
      "Div": "1.29"
  },
  {
      "Date": "20-Oct-22",
      "PE": "21.06",
      "PB": "4.13",
      "Div": "1.3"
  },
  {
      "Date": "19-Oct-22",
      "PE": "21.02",
      "PB": "4.12",
      "Div": "1.31"
  },
  {
      "Date": "18-Oct-22",
      "PE": "20.99",
      "PB": "4.11",
      "Div": "1.3"
  },
  {
      "Date": "17-Oct-22",
      "PE": "20.86",
      "PB": "4.07",
      "Div": "1.31"
  },
  {
      "Date": "14-Oct-22",
      "PE": "20.74",
      "PB": "4.04",
      "Div": "1.31"
  },
  {
      "Date": "13-Oct-22",
      "PE": "20.53",
      "PB": "4",
      "Div": "1.33"
  },
  {
      "Date": "12-Oct-22",
      "PE": "20.67",
      "PB": "4.02",
      "Div": "1.32"
  },
  {
      "Date": "11-Oct-22",
      "PE": "20.51",
      "PB": "3.99",
      "Div": "1.33"
  },
  {
      "Date": "10-Oct-22",
      "PE": "20.82",
      "PB": "4.05",
      "Div": "1.31"
  },
  {
      "Date": "7-Oct-22",
      "PE": "20.9",
      "PB": "4.07",
      "Div": "1.31"
  },
  {
      "Date": "6-Oct-22",
      "PE": "20.93",
      "PB": "4.07",
      "Div": "1.31"
  },
  {
      "Date": "4-Oct-22",
      "PE": "20.86",
      "PB": "4.06",
      "Div": "1.31"
  },
  {
      "Date": "3-Oct-22",
      "PE": "20.39",
      "PB": "3.97",
      "Div": "1.34"
  },
  {
      "Date": "30-Sep-22",
      "PE": "20.64",
      "PB": "4.02",
      "Div": "1.32"
  },
  {
      "Date": "29-Sep-22",
      "PE": "20.08",
      "PB": "3.9",
      "Div": "1.36"
  },
  {
      "Date": "28-Sep-22",
      "PE": "20.13",
      "PB": "3.91",
      "Div": "1.36"
  },
  {
      "Date": "27-Sep-22",
      "PE": "20.3",
      "PB": "3.95",
      "Div": "1.35"
  },
  {
      "Date": "26-Sep-22",
      "PE": "20.31",
      "PB": "3.95",
      "Div": "1.35"
  },
  {
      "Date": "23-Sep-22",
      "PE": "20.69",
      "PB": "4.02",
      "Div": "1.32"
  },
  {
      "Date": "22-Sep-22",
      "PE": "21.05",
      "PB": "4.09",
      "Div": "1.3"
  },
  {
      "Date": "21-Sep-22",
      "PE": "21.15",
      "PB": "4.11",
      "Div": "1.29"
  },
  {
      "Date": "20-Sep-22",
      "PE": "21.27",
      "PB": "4.13",
      "Div": "1.28"
  },
  {
      "Date": "19-Sep-22",
      "PE": "21.04",
      "PB": "4.09",
      "Div": "1.3"
  },
  {
      "Date": "16-Sep-22",
      "PE": "20.93",
      "PB": "4.07",
      "Div": "1.31"
  },
  {
      "Date": "15-Sep-22",
      "PE": "21.34",
      "PB": "4.15",
      "Div": "1.35"
  },
  {
      "Date": "14-Sep-22",
      "PE": "21.49",
      "PB": "4.18",
      "Div": "1.34"
  },
  {
      "Date": "13-Sep-22",
      "PE": "21.57",
      "PB": "4.19",
      "Div": "1.34"
  },
  {
      "Date": "12-Sep-22",
      "PE": "21.41",
      "PB": "4.16",
      "Div": "1.35"
  },
  {
      "Date": "9-Sep-22",
      "PE": "21.29",
      "PB": "4.14",
      "Div": "1.35"
  },
  {
      "Date": "8-Sep-22",
      "PE": "21.25",
      "PB": "4.13",
      "Div": "1.36"
  },
  {
      "Date": "7-Sep-22",
      "PE": "21.04",
      "PB": "4.09",
      "Div": "1.41"
  },
  {
      "Date": "6-Sep-22",
      "PE": "21.08",
      "PB": "4.1",
      "Div": "1.41"
  },
  {
      "Date": "5-Sep-22",
      "PE": "21.09",
      "PB": "4.1",
      "Div": "1.41"
  },
  {
      "Date": "2-Sep-22",
      "PE": "20.94",
      "PB": "4.07",
      "Div": "1.42"
  },
  {
      "Date": "1-Sep-22",
      "PE": "20.94",
      "PB": "4.07",
      "Div": "1.43"
  },
  {
      "Date": "30-Aug-22",
      "PE": "21.2",
      "PB": "4.12",
      "Div": "1.41"
  },
  {
      "Date": "29-Aug-22",
      "PE": "20.67",
      "PB": "4.05",
      "Div": "1.45"
  },
  {
      "Date": "26-Aug-22",
      "PE": "20.96",
      "PB": "4.1",
      "Div": "1.43"
  },
  {
      "Date": "25-Aug-22",
      "PE": "20.92",
      "PB": "4.09",
      "Div": "1.43"
  },
  {
      "Date": "24-Aug-22",
      "PE": "21.02",
      "PB": "4.11",
      "Div": "1.42"
  },
  {
      "Date": "23-Aug-22",
      "PE": "20.98",
      "PB": "4.11",
      "Div": "1.43"
  },
  {
      "Date": "22-Aug-22",
      "PE": "20.88",
      "PB": "4.09",
      "Div": "1.44"
  },
  {
      "Date": "19-Aug-22",
      "PE": "21.2",
      "PB": "4.15",
      "Div": "1.41"
  },
  {
      "Date": "18-Aug-22",
      "PE": "21.44",
      "PB": "4.2",
      "Div": "1.38"
  },
  {
      "Date": "17-Aug-22",
      "PE": "21.42",
      "PB": "4.2",
      "Div": "1.33"
  },
  {
      "Date": "16-Aug-22",
      "PE": "21.34",
      "PB": "4.18",
      "Div": "1.34"
  },
  {
      "Date": "12-Aug-22",
      "PE": "21.18",
      "PB": "4.15",
      "Div": "1.35"
  },
  {
      "Date": "11-Aug-22",
      "PE": "21.29",
      "PB": "4.18",
      "Div": "1.36"
  },
  {
      "Date": "10-Aug-22",
      "PE": "21.11",
      "PB": "4.21",
      "Div": "1.34"
  },
  {
      "Date": "8-Aug-22",
      "PE": "21.02",
      "PB": "4.21",
      "Div": "1.33"
  },
  {
      "Date": "5-Aug-22",
      "PE": "20.86",
      "PB": "4.18",
      "Div": "1.29"
  },
  {
      "Date": "4-Aug-22",
      "PE": "20.85",
      "PB": "4.18",
      "Div": "1.3"
  },
  {
      "Date": "3-Aug-22",
      "PE": "20.85",
      "PB": "4.18",
      "Div": "1.3"
  },
  {
      "Date": "2-Aug-22",
      "PE": "20.86",
      "PB": "4.17",
      "Div": "1.29"
  },
  {
      "Date": "1-Aug-22",
      "PE": "20.9",
      "PB": "4.17",
      "Div": "1.29"
  },
  {
      "Date": "29-Jul-22",
      "PE": "20.73",
      "PB": "4.12",
      "Div": "1.29"
  },
  {
      "Date": "28-Jul-22",
      "PE": "20.49",
      "PB": "4.07",
      "Div": "1.33"
  },
  {
      "Date": "27-Jul-22",
      "PE": "20.19",
      "PB": "4",
      "Div": "1.38"
  },
  {
      "Date": "26-Jul-22",
      "PE": "20.17",
      "PB": "3.96",
      "Div": "1.39"
  },
  {
      "Date": "25-Jul-22",
      "PE": "20.44",
      "PB": "4.01",
      "Div": "1.38"
  },
  {
      "Date": "22-Jul-22",
      "PE": "20.55",
      "PB": "4.03",
      "Div": "1.4"
  },
  {
      "Date": "21-Jul-22",
      "PE": "20.43",
      "PB": "4",
      "Div": "1.41"
  },
  {
      "Date": "20-Jul-22",
      "PE": "20.33",
      "PB": "3.98",
      "Div": "1.36"
  },
  {
      "Date": "19-Jul-22",
      "PE": "20.18",
      "PB": "3.94",
      "Div": "1.37"
  },
  {
      "Date": "18-Jul-22",
      "PE": "20.11",
      "PB": "3.92",
      "Div": "1.36"
  },
  {
      "Date": "15-Jul-22",
      "PE": "19.82",
      "PB": "3.87",
      "Div": "1.38"
  },
  {
      "Date": "14-Jul-22",
      "PE": "19.69",
      "PB": "3.84",
      "Div": "1.42"
  },
  {
      "Date": "13-Jul-22",
      "PE": "19.72",
      "PB": "3.85",
      "Div": "1.39"
  },
  {
      "Date": "12-Jul-22",
      "PE": "19.83",
      "PB": "3.87",
      "Div": "1.38"
  },
  {
      "Date": "11-Jul-22",
      "PE": "20.03",
      "PB": "3.91",
      "Div": "1.37"
  },
  {
      "Date": "8-Jul-22",
      "PE": "20.04",
      "PB": "4",
      "Div": "1.37"
  },
  {
      "Date": "7-Jul-22",
      "PE": "19.93",
      "PB": "4.12",
      "Div": "1.4"
  },
  {
      "Date": "6-Jul-22",
      "PE": "19.76",
      "PB": "4.08",
      "Div": "1.41"
  },
  {
      "Date": "5-Jul-22",
      "PE": "19.54",
      "PB": "4.04",
      "Div": "1.42"
  },
  {
      "Date": "4-Jul-22",
      "PE": "19.57",
      "PB": "4.04",
      "Div": "1.43"
  },
  {
      "Date": "1-Jul-22",
      "PE": "19.46",
      "PB": "4.02",
      "Div": "1.41"
  },
  {
      "Date": "30-Jun-22",
      "PE": "19.5",
      "PB": "4.03",
      "Div": "1.41"
  },
  {
      "Date": "29-Jun-22",
      "PE": "19.55",
      "PB": "4.04",
      "Div": "1.38"
  },
  {
      "Date": "28-Jun-22",
      "PE": "19.61",
      "PB": "4.05",
      "Div": "1.41"
  },
  {
      "Date": "27-Jun-22",
      "PE": "19.59",
      "PB": "4.05",
      "Div": "1.41"
  },
  {
      "Date": "24-Jun-22",
      "PE": "19.43",
      "PB": "4.01",
      "Div": "1.43"
  },
  {
      "Date": "23-Jun-22",
      "PE": "19.25",
      "PB": "3.98",
      "Div": "1.44"
  },
  {
      "Date": "22-Jun-22",
      "PE": "19.07",
      "PB": "3.94",
      "Div": "1.46"
  },
  {
      "Date": "21-Jun-22",
      "PE": "19.35",
      "PB": "4",
      "Div": "1.44"
  },
  {
      "Date": "20-Jun-22",
      "PE": "18.99",
      "PB": "3.92",
      "Div": "1.46"
  },
  {
      "Date": "17-Jun-22",
      "PE": "18.92",
      "PB": "3.91",
      "Div": "1.46"
  },
  {
      "Date": "16-Jun-22",
      "PE": "19.01",
      "PB": "3.93",
      "Div": "1.48"
  },
  {
      "Date": "15-Jun-22",
      "PE": "19.42",
      "PB": "4.01",
      "Div": "1.45"
  },
  {
      "Date": "14-Jun-22",
      "PE": "19.47",
      "PB": "4.02",
      "Div": "1.36"
  },
  {
      "Date": "13-Jun-22",
      "PE": "19.52",
      "PB": "4.03",
      "Div": "1.38"
  },
  {
      "Date": "10-Jun-22",
      "PE": "20.05",
      "PB": "4.14",
      "Div": "1.38"
  },
  {
      "Date": "9-Jun-22",
      "PE": "20.39",
      "PB": "4.21",
      "Div": "1.43"
  },
  {
      "Date": "8-Jun-22",
      "PE": "20.24",
      "PB": "4.18",
      "Div": "1.43"
  },
  {
      "Date": "7-Jun-22",
      "PE": "20.31",
      "PB": "4.2",
      "Div": "1.43"
  },
  {
      "Date": "6-Jun-22",
      "PE": "20.5",
      "PB": "4.24",
      "Div": "1.41"
  },
  {
      "Date": "3-Jun-22",
      "PE": "20.52",
      "PB": "4.24",
      "Div": "1.41"
  },
  {
      "Date": "2-Jun-22",
      "PE": "20.58",
      "PB": "4.25",
      "Div": "1.43"
  },
  {
      "Date": "1-Jun-22",
      "PE": "20.45",
      "PB": "4.22",
      "Div": "1.44"
  },
  {
      "Date": "31-May-22",
      "PE": "20.44",
      "PB": "4.24",
      "Div": "1.43"
  },
  {
      "Date": "30-May-22",
      "PE": "20.65",
      "PB": "4.26",
      "Div": "1.4"
  },
  {
      "Date": "27-May-22",
      "PE": "20.27",
      "PB": "4.18",
      "Div": "1.43"
  },
  {
      "Date": "26-May-22",
      "PE": "19.88",
      "PB": "4.13",
      "Div": "1.44"
  },
  {
      "Date": "25-May-22",
      "PE": "19.74",
      "PB": "4.09",
      "Div": "1.38"
  },
  {
      "Date": "24-May-22",
      "PE": "19.87",
      "PB": "4.12",
      "Div": "1.32"
  },
  {
      "Date": "23-May-22",
      "PE": "20.01",
      "PB": "4.14",
      "Div": "1.32"
  },
  {
      "Date": "20-May-22",
      "PE": "20.05",
      "PB": "4.16",
      "Div": "1.31"
  },
  {
      "Date": "19-May-22",
      "PE": "19.51",
      "PB": "4.04",
      "Div": "1.35"
  },
  {
      "Date": "18-May-22",
      "PE": "20.09",
      "PB": "4.15",
      "Div": "1.31"
  },
  {
      "Date": "17-May-22",
      "PE": "20.12",
      "PB": "4.15",
      "Div": "1.31"
  },
  {
      "Date": "16-May-22",
      "PE": "19.72",
      "PB": "4.05",
      "Div": "1.35"
  },
  {
      "Date": "13-May-22",
      "PE": "19.83",
      "PB": "4.03",
      "Div": "1.35"
  },
  {
      "Date": "12-May-22",
      "PE": "19.87",
      "PB": "4.04",
      "Div": "1.35"
  },
  {
      "Date": "11-May-22",
      "PE": "20.32",
      "PB": "4.13",
      "Div": "1.22"
  },
  {
      "Date": "10-May-22",
      "PE": "20.42",
      "PB": "4.15",
      "Div": "1.22"
  },
  {
      "Date": "9-May-22",
      "PE": "20.75",
      "PB": "4.17",
      "Div": "1.21"
  },
  {
      "Date": "6-May-22",
      "PE": "20.89",
      "PB": "4.19",
      "Div": "1.21"
  },
  {
      "Date": "5-May-22",
      "PE": "21.24",
      "PB": "4.26",
      "Div": "1.19"
  },
  {
      "Date": "4-May-22",
      "PE": "21.31",
      "PB": "4.26",
      "Div": "1.19"
  },
  {
      "Date": "2-May-22",
      "PE": "21.87",
      "PB": "4.36",
      "Div": "1.16"
  },
  {
      "Date": "29-Apr-22",
      "PE": "22.01",
      "PB": "4.37",
      "Div": "1.16"
  },
  {
      "Date": "28-Apr-22",
      "PE": "22.2",
      "PB": "4.41",
      "Div": "1.17"
  },
  {
      "Date": "27-Apr-22",
      "PE": "21.96",
      "PB": "4.35",
      "Div": "1.16"
  },
  {
      "Date": "26-Apr-22",
      "PE": "22.17",
      "PB": "4.39",
      "Div": "1.15"
  },
  {
      "Date": "25-Apr-22",
      "PE": "22.03",
      "PB": "4.33",
      "Div": "1.17"
  },
  {
      "Date": "22-Apr-22",
      "PE": "22.38",
      "PB": "4.39",
      "Div": "1.15"
  },
  {
      "Date": "21-Apr-22",
      "PE": "22.67",
      "PB": "4.44",
      "Div": "1.14"
  },
  {
      "Date": "20-Apr-22",
      "PE": "22.33",
      "PB": "4.38",
      "Div": "1.15"
  },
  {
      "Date": "19-Apr-22",
      "PE": "22.1",
      "PB": "4.33",
      "Div": "1.16"
  },
  {
      "Date": "18-Apr-22",
      "PE": "22.53",
      "PB": "4.39",
      "Div": "1.15"
  },
  {
      "Date": "13-Apr-22",
      "PE": "22.92",
      "PB": "4.46",
      "Div": "1.13"
  },
  {
      "Date": "12-Apr-22",
      "PE": "23.01",
      "PB": "4.48",
      "Div": "1.13"
  },
  {
      "Date": "11-Apr-22",
      "PE": "23.2",
      "PB": "4.52",
      "Div": "1.12"
  },
  {
      "Date": "8-Apr-22",
      "PE": "23.34",
      "PB": "4.54",
      "Div": "1.11"
  },
  {
      "Date": "7-Apr-22",
      "PE": "23.15",
      "PB": "4.51",
      "Div": "1.13"
  },
  {
      "Date": "6-Apr-22",
      "PE": "23.37",
      "PB": "4.55",
      "Div": "1.12"
  },
  {
      "Date": "5-Apr-22",
      "PE": "23.57",
      "PB": "4.59",
      "Div": "1.11"
  },
  {
      "Date": "4-Apr-22",
      "PE": "23.69",
      "PB": "4.61",
      "Div": "1.1"
  },
  {
      "Date": "1-Apr-22",
      "PE": "23.19",
      "PB": "4.51",
      "Div": "1.12"
  },
  {
      "Date": "31-Mar-22",
      "PE": "22.92",
      "PB": "4.46",
      "Div": "1.13"
  },
  {
      "Date": "30-Mar-22",
      "PE": "22.5",
      "PB": "4.41",
      "Div": "1.17"
  },
  {
      "Date": "29-Mar-22",
      "PE": "22.27",
      "PB": "4.37",
      "Div": "1.18"
  },
  {
      "Date": "28-Mar-22",
      "PE": "22.14",
      "PB": "4.34",
      "Div": "1.19"
  },
  {
      "Date": "25-Mar-22",
      "PE": "22.05",
      "PB": "4.32",
      "Div": "1.19"
  },
  {
      "Date": "24-Mar-22",
      "PE": "22.14",
      "PB": "4.34",
      "Div": "1.19"
  },
  {
      "Date": "23-Mar-22",
      "PE": "22.17",
      "PB": "4.35",
      "Div": "1.19"
  },
  {
      "Date": "22-Mar-22",
      "PE": "22.26",
      "PB": "4.36",
      "Div": "1.2"
  },
  {
      "Date": "21-Mar-22",
      "PE": "22.01",
      "PB": "4.31",
      "Div": "1.21"
  },
  {
      "Date": "17-Mar-22",
      "PE": "22.23",
      "PB": "4.36",
      "Div": "1.2"
  },
  {
      "Date": "16-Mar-22",
      "PE": "21.82",
      "PB": "4.28",
      "Div": "1.22"
  },
  {
      "Date": "15-Mar-22",
      "PE": "21.42",
      "PB": "4.2",
      "Div": "1.24"
  },
  {
      "Date": "14-Mar-22",
      "PE": "21.69",
      "PB": "4.25",
      "Div": "1.24"
  },
  {
      "Date": "11-Mar-22",
      "PE": "21.38",
      "PB": "4.19",
      "Div": "1.26"
  },
  {
      "Date": "10-Mar-22",
      "PE": "21.34",
      "PB": "4.18",
      "Div": "1.26"
  },
  {
      "Date": "9-Mar-22",
      "PE": "21.01",
      "PB": "4.12",
      "Div": "1.28"
  },
  {
      "Date": "8-Mar-22",
      "PE": "20.59",
      "PB": "4.03",
      "Div": "1.31"
  },
  {
      "Date": "7-Mar-22",
      "PE": "20.39",
      "PB": "4",
      "Div": "1.33"
  },
  {
      "Date": "4-Mar-22",
      "PE": "20.89",
      "PB": "4.09",
      "Div": "1.3"
  },
  {
      "Date": "3-Mar-22",
      "PE": "21.21",
      "PB": "4.16",
      "Div": "1.28"
  },
  {
      "Date": "2-Mar-22",
      "PE": "21.35",
      "PB": "4.18",
      "Div": "1.27"
  },
  {
      "Date": "28-Feb-22",
      "PE": "21.59",
      "PB": "4.23",
      "Div": "1.26"
  },
  {
      "Date": "25-Feb-22",
      "PE": "21.42",
      "PB": "4.2",
      "Div": "1.27"
  },
  {
      "Date": "24-Feb-22",
      "PE": "20.89",
      "PB": "4.09",
      "Div": "1.3"
  },
  {
      "Date": "23-Feb-22",
      "PE": "21.94",
      "PB": "4.3",
      "Div": "1.24"
  },
  {
      "Date": "22-Feb-22",
      "PE": "21.97",
      "PB": "4.31",
      "Div": "1.24"
  },
  {
      "Date": "21-Feb-22",
      "PE": "22.12",
      "PB": "4.34",
      "Div": "1.29"
  },
  {
      "Date": "18-Feb-22",
      "PE": "22.21",
      "PB": "4.35",
      "Div": "1.25"
  },
  {
      "Date": "17-Feb-22",
      "PE": "22.25",
      "PB": "4.36",
      "Div": "1.25"
  },
  {
      "Date": "16-Feb-22",
      "PE": "22.27",
      "PB": "4.36",
      "Div": "1.28"
  },
  {
      "Date": "15-Feb-22",
      "PE": "22.36",
      "PB": "4.37",
      "Div": "1.25"
  },
  {
      "Date": "14-Feb-22",
      "PE": "21.85",
      "PB": "4.24",
      "Div": "1.3"
  },
  {
      "Date": "11-Feb-22",
      "PE": "22.68",
      "PB": "4.38",
      "Div": "1.2"
  },
  {
      "Date": "10-Feb-22",
      "PE": "22.98",
      "PB": "4.44",
      "Div": "1.2"
  },
  {
      "Date": "9-Feb-22",
      "PE": "23.09",
      "PB": "4.4",
      "Div": "1.2"
  },
  {
      "Date": "8-Feb-22",
      "PE": "22.83",
      "PB": "4.35",
      "Div": "1.23"
  },
  {
      "Date": "7-Feb-22",
      "PE": "23.11",
      "PB": "4.34",
      "Div": "1.23"
  },
  {
      "Date": "4-Feb-22",
      "PE": "23.56",
      "PB": "4.41",
      "Div": "1.21"
  },
  {
      "Date": "3-Feb-22",
      "PE": "23.65",
      "PB": "4.42",
      "Div": "1.21"
  },
  {
      "Date": "2-Feb-22",
      "PE": "23.95",
      "PB": "4.48",
      "Div": "1.17"
  },
  {
      "Date": "1-Feb-22",
      "PE": "23.62",
      "PB": "4.43",
      "Div": "1.18"
  },
  {
      "Date": "31-Jan-22",
      "PE": "23.42",
      "PB": "4.37",
      "Div": "1.2"
  },
  {
      "Date": "28-Jan-22",
      "PE": "23.1",
      "PB": "4.31",
      "Div": "1.21"
  },
  {
      "Date": "27-Jan-22",
      "PE": "23.09",
      "PB": "4.31",
      "Div": "1.21"
  },
  {
      "Date": "25-Jan-22",
      "PE": "23.47",
      "PB": "4.35",
      "Div": "1.2"
  },
  {
      "Date": "24-Jan-22",
      "PE": "23.63",
      "PB": "4.32",
      "Div": "1.21"
  },
  {
      "Date": "21-Jan-22",
      "PE": "24.29",
      "PB": "4.44",
      "Div": "1.18"
  },
  {
      "Date": "20-Jan-22",
      "PE": "24.47",
      "PB": "4.47",
      "Div": "1.18"
  },
  {
      "Date": "19-Jan-22",
      "PE": "24.76",
      "PB": "4.52",
      "Div": "1.15"
  },
  {
      "Date": "18-Jan-22",
      "PE": "25",
      "PB": "4.56",
      "Div": "1.13"
  },
  {
      "Date": "17-Jan-22",
      "PE": "25.37",
      "PB": "4.61",
      "Div": "1.12"
  },
  {
      "Date": "14-Jan-22",
      "PE": "25.3",
      "PB": "4.6",
      "Div": "1.12"
  },
  {
      "Date": "13-Jan-22",
      "PE": "25.37",
      "PB": "4.6",
      "Div": "1.13"
  },
  {
      "Date": "12-Jan-22",
      "PE": "25.3",
      "PB": "4.59",
      "Div": "1.13"
  },
  {
      "Date": "11-Jan-22",
      "PE": "25.09",
      "PB": "4.55",
      "Div": "1.14"
  },
  {
      "Date": "10-Jan-22",
      "PE": "25.01",
      "PB": "4.54",
      "Div": "1.14"
  },
  {
      "Date": "7-Jan-22",
      "PE": "24.75",
      "PB": "4.49",
      "Div": "1.16"
  },
  {
      "Date": "6-Jan-22",
      "PE": "24.66",
      "PB": "4.47",
      "Div": "1.16"
  },
  {
      "Date": "5-Jan-22",
      "PE": "24.91",
      "PB": "4.52",
      "Div": "1.15"
  },
  {
      "Date": "4-Jan-22",
      "PE": "24.74",
      "PB": "4.49",
      "Div": "1.16"
  },
  {
      "Date": "3-Jan-22",
      "PE": "24.49",
      "PB": "4.44",
      "Div": "1.17"
  },
  {
      "Date": "31-Dec-21",
      "PE": "24.11",
      "PB": "4.37",
      "Div": "1.19"
  },
  {
      "Date": "30-Dec-21",
      "PE": "23.73",
      "PB": "4.31",
      "Div": "1.21"
  },
  {
      "Date": "29-Dec-21",
      "PE": "23.74",
      "PB": "4.31",
      "Div": "1.21"
  },
  {
      "Date": "28-Dec-21",
      "PE": "23.77",
      "PB": "4.31",
      "Div": "1.2"
  },
  {
      "Date": "27-Dec-21",
      "PE": "23.57",
      "PB": "4.28",
      "Div": "1.21"
  },
  {
      "Date": "24-Dec-21",
      "PE": "23.45",
      "PB": "4.26",
      "Div": "1.22"
  },
  {
      "Date": "23-Dec-21",
      "PE": "23.55",
      "PB": "4.27",
      "Div": "1.22"
  },
  {
      "Date": "22-Dec-21",
      "PE": "23.39",
      "PB": "4.24",
      "Div": "1.22"
  },
  {
      "Date": "21-Dec-21",
      "PE": "23.13",
      "PB": "4.2",
      "Div": "1.2"
  },
  {
      "Date": "20-Dec-21",
      "PE": "22.92",
      "PB": "4.16",
      "Div": "1.22"
  },
  {
      "Date": "17-Dec-21",
      "PE": "23.43",
      "PB": "4.25",
      "Div": "1.19"
  },
  {
      "Date": "16-Dec-21",
      "PE": "23.79",
      "PB": "4.32",
      "Div": "1.19"
  },
  {
      "Date": "15-Dec-21",
      "PE": "23.75",
      "PB": "4.31",
      "Div": "1.2"
  },
  {
      "Date": "14-Dec-21",
      "PE": "23.9",
      "PB": "4.34",
      "Div": "1.19"
  },
  {
      "Date": "13-Dec-21",
      "PE": "23.96",
      "PB": "4.35",
      "Div": "1.18"
  },
  {
      "Date": "10-Dec-21",
      "PE": "24.15",
      "PB": "4.38",
      "Div": "1.18"
  },
  {
      "Date": "9-Dec-21",
      "PE": "24.16",
      "PB": "4.39",
      "Div": "1.17"
  },
  {
      "Date": "8-Dec-21",
      "PE": "24.1",
      "PB": "4.37",
      "Div": "1.18"
  },
  {
      "Date": "7-Dec-21",
      "PE": "23.69",
      "PB": "4.3",
      "Div": "1.2"
  },
  {
      "Date": "6-Dec-21",
      "PE": "23.33",
      "PB": "4.23",
      "Div": "1.22"
  },
  {
      "Date": "3-Dec-21",
      "PE": "23.73",
      "PB": "4.31",
      "Div": "1.17"
  },
  {
      "Date": "2-Dec-21",
      "PE": "24.01",
      "PB": "4.36",
      "Div": "1.16"
  },
  {
      "Date": "1-Dec-21",
      "PE": "23.68",
      "PB": "4.3",
      "Div": "1.17"
  },
  {
      "Date": "30-Nov-21",
      "PE": "23.43",
      "PB": "4.25",
      "Div": "1.19"
  },
  {
      "Date": "29-Nov-21",
      "PE": "23.53",
      "PB": "4.27",
      "Div": "1.18"
  },
  {
      "Date": "26-Nov-21",
      "PE": "23.49",
      "PB": "4.26",
      "Div": "1.18"
  },
  {
      "Date": "25-Nov-21",
      "PE": "24.19",
      "PB": "4.39",
      "Div": "1.15"
  },
  {
      "Date": "24-Nov-21",
      "PE": "24.03",
      "PB": "4.36",
      "Div": "1.16"
  },
  {
      "Date": "23-Nov-21",
      "PE": "24.15",
      "PB": "4.38",
      "Div": "1.15"
  },
  {
      "Date": "22-Nov-21",
      "PE": "24.03",
      "PB": "4.36",
      "Div": "1.16"
  },
  {
      "Date": "18-Nov-21",
      "PE": "24.51",
      "PB": "4.45",
      "Div": "1.13"
  },
  {
      "Date": "17-Nov-21",
      "PE": "24.69",
      "PB": "4.48",
      "Div": "1.12"
  },
  {
      "Date": "16-Nov-21",
      "PE": "24.83",
      "PB": "4.51",
      "Div": "1.11"
  },
  {
      "Date": "15-Nov-21",
      "PE": "26",
      "PB": "4.53",
      "Div": "1.11"
  },
  {
      "Date": "12-Nov-21",
      "PE": "25.98",
      "PB": "4.53",
      "Div": "1.11"
  },
  {
      "Date": "11-Nov-21",
      "PE": "25.65",
      "PB": "4.48",
      "Div": "1.12"
  },
  {
      "Date": "10-Nov-21",
      "PE": "26.03",
      "PB": "4.51",
      "Div": "1.09"
  },
  {
      "Date": "9-Nov-21",
      "PE": "26.06",
      "PB": "4.52",
      "Div": "1.09"
  },
  {
      "Date": "8-Nov-21",
      "PE": "26.25",
      "PB": "4.52",
      "Div": "1.09"
  },
  {
      "Date": "4-Nov-21",
      "PE": "26.11",
      "PB": "4.49",
      "Div": "1.1"
  },
  {
      "Date": "3-Nov-21",
      "PE": "25.98",
      "PB": "4.46",
      "Div": "1.13"
  },
  {
      "Date": "2-Nov-21",
      "PE": "25.95",
      "PB": "4.48",
      "Div": "1.13"
  },
  {
      "Date": "1-Nov-21",
      "PE": "26.07",
      "PB": "4.49",
      "Div": "1.11"
  },
  {
      "Date": "29-Oct-21",
      "PE": "25.71",
      "PB": "4.43",
      "Div": "1.13"
  },
  {
      "Date": "28-Oct-21",
      "PE": "26.39",
      "PB": "4.47",
      "Div": "1.13"
  },
  {
      "Date": "27-Oct-21",
      "PE": "27.06",
      "PB": "4.56",
      "Div": "1.13"
  },
  {
      "Date": "26-Oct-21",
      "PE": "27.16",
      "PB": "4.57",
      "Div": "1.12"
  },
  {
      "Date": "25-Oct-21",
      "PE": "27.29",
      "PB": "4.54",
      "Div": "1.04"
  },
  {
      "Date": "22-Oct-21",
      "PE": "27.48",
      "PB": "4.54",
      "Div": "1.1"
  },
  {
      "Date": "21-Oct-21",
      "PE": "27.58",
      "PB": "4.55",
      "Div": "1.1"
  },
  {
      "Date": "20-Oct-21",
      "PE": "27.72",
      "PB": "4.57",
      "Div": "1.08"
  },
  {
      "Date": "19-Oct-21",
      "PE": "27.97",
      "PB": "4.61",
      "Div": "1.07"
  },
  {
      "Date": "18-Oct-21",
      "PE": "28.17",
      "PB": "4.63",
      "Div": "1.11"
  },
  {
      "Date": "14-Oct-21",
      "PE": "28.02",
      "PB": "4.59",
      "Div": "1.12"
  },
  {
      "Date": "13-Oct-21",
      "PE": "27.75",
      "PB": "4.55",
      "Div": "1.13"
  },
  {
      "Date": "12-Oct-21",
      "PE": "27.49",
      "PB": "4.51",
      "Div": "1.14"
  },
  {
      "Date": "11-Oct-21",
      "PE": "27.48",
      "PB": "4.49",
      "Div": "1.15"
  },
  {
      "Date": "8-Oct-21",
      "PE": "27.4",
      "PB": "4.48",
      "Div": "1.15"
  },
  {
      "Date": "7-Oct-21",
      "PE": "27.24",
      "PB": "4.45",
      "Div": "1.16"
  },
  {
      "Date": "6-Oct-21",
      "PE": "27.02",
      "PB": "4.42",
      "Div": "1.17"
  },
  {
      "Date": "5-Oct-21",
      "PE": "27.29",
      "PB": "4.46",
      "Div": "1.16"
  },
  {
      "Date": "4-Oct-21",
      "PE": "27.09",
      "PB": "4.43",
      "Div": "1.16"
  },
  {
      "Date": "1-Oct-21",
      "PE": "26.84",
      "PB": "4.39",
      "Div": "1.17"
  },
  {
      "Date": "30-Sep-21",
      "PE": "26.98",
      "PB": "4.41",
      "Div": "1.17"
  },
  {
      "Date": "29-Sep-21",
      "PE": "27.11",
      "PB": "4.43",
      "Div": "1.16"
  },
  {
      "Date": "28-Sep-21",
      "PE": "27.16",
      "PB": "4.44",
      "Div": "1.16"
  },
  {
      "Date": "27-Sep-21",
      "PE": "27.33",
      "PB": "4.47",
      "Div": "1.15"
  },
  {
      "Date": "24-Sep-21",
      "PE": "27.34",
      "PB": "4.47",
      "Div": "1.11"
  },
  {
      "Date": "23-Sep-21",
      "PE": "27.29",
      "PB": "4.5",
      "Div": "1.11"
  },
  {
      "Date": "22-Sep-21",
      "PE": "26.87",
      "PB": "4.43",
      "Div": "1.13"
  },
  {
      "Date": "21-Sep-21",
      "PE": "26.89",
      "PB": "4.44",
      "Div": "1.13"
  },
  {
      "Date": "20-Sep-21",
      "PE": "26.64",
      "PB": "4.4",
      "Div": "1.14"
  },
  {
      "Date": "17-Sep-21",
      "PE": "26.93",
      "PB": "4.44",
      "Div": "1.13"
  },
  {
      "Date": "16-Sep-21",
      "PE": "26.99",
      "PB": "4.45",
      "Div": "1.12"
  },
  {
      "Date": "15-Sep-21",
      "PE": "26.84",
      "PB": "4.43",
      "Div": "1.06"
  },
  {
      "Date": "14-Sep-21",
      "PE": "26.63",
      "PB": "4.39",
      "Div": "1.07"
  },
  {
      "Date": "13-Sep-21",
      "PE": "26.59",
      "PB": "4.39",
      "Div": "1.07"
  },
  {
      "Date": "9-Sep-21",
      "PE": "26.61",
      "PB": "4.39",
      "Div": "1.07"
  },
  {
      "Date": "8-Sep-21",
      "PE": "26.59",
      "PB": "4.39",
      "Div": "1.07"
  },
  {
      "Date": "7-Sep-21",
      "PE": "26.6",
      "PB": "4.39",
      "Div": "1.03"
  },
  {
      "Date": "6-Sep-21",
      "PE": "26.63",
      "PB": "4.39",
      "Div": "1.03"
  },
  {
      "Date": "3-Sep-21",
      "PE": "26.54",
      "PB": "4.38",
      "Div": "1.03"
  },
  {
      "Date": "2-Sep-21",
      "PE": "26.41",
      "PB": "4.36",
      "Div": "1.04"
  },
  {
      "Date": "1-Sep-21",
      "PE": "26.17",
      "PB": "4.32",
      "Div": "1.05"
  },
  {
      "Date": "31-Aug-21",
      "PE": "26.25",
      "PB": "4.33",
      "Div": "1.05"
  },
  {
      "Date": "30-Aug-21",
      "PE": "25.94",
      "PB": "4.29",
      "Div": "1.06"
  },
  {
      "Date": "27-Aug-21",
      "PE": "25.6",
      "PB": "4.23",
      "Div": "1.08"
  },
  {
      "Date": "26-Aug-21",
      "PE": "25.49",
      "PB": "4.21",
      "Div": "1.08"
  },
  {
      "Date": "25-Aug-21",
      "PE": "25.49",
      "PB": "4.21",
      "Div": "1.1"
  },
  {
      "Date": "24-Aug-21",
      "PE": "25.47",
      "PB": "4.21",
      "Div": "1.1"
  },
  {
      "Date": "23-Aug-21",
      "PE": "25.28",
      "PB": "4.18",
      "Div": "1.11"
  },
  {
      "Date": "20-Aug-21",
      "PE": "25.21",
      "PB": "4.16",
      "Div": "1.11"
  },
  {
      "Date": "18-Aug-21",
      "PE": "25.39",
      "PB": "4.19",
      "Div": "1.1"
  },
  {
      "Date": "17-Aug-21",
      "PE": "25.46",
      "PB": "4.21",
      "Div": "1.1"
  },
  {
      "Date": "16-Aug-21",
      "PE": "25.62",
      "PB": "4.31",
      "Div": "1.1"
  },
  {
      "Date": "13-Aug-21",
      "PE": "26.51",
      "PB": "4.3",
      "Div": "1.1"
  },
  {
      "Date": "12-Aug-21",
      "PE": "26.25",
      "PB": "4.26",
      "Div": "1.14"
  },
  {
      "Date": "11-Aug-21",
      "PE": "26.34",
      "PB": "4.24",
      "Div": "1.13"
  },
  {
      "Date": "10-Aug-21",
      "PE": "26.35",
      "PB": "4.24",
      "Div": "1.13"
  },
  {
      "Date": "9-Aug-21",
      "PE": "26.53",
      "PB": "4.25",
      "Div": "1.13"
  },
  {
      "Date": "6-Aug-21",
      "PE": "26.51",
      "PB": "4.24",
      "Div": "1.13"
  },
  {
      "Date": "5-Aug-21",
      "PE": "26.72",
      "PB": "4.25",
      "Div": "1.14"
  },
  {
      "Date": "4-Aug-21",
      "PE": "27.39",
      "PB": "4.25",
      "Div": "1.14"
  },
  {
      "Date": "3-Aug-21",
      "PE": "27.31",
      "PB": "4.21",
      "Div": "1.15"
  },
  {
      "Date": "2-Aug-21",
      "PE": "27.21",
      "PB": "4.15",
      "Div": "1.17"
  },
  {
      "Date": "30-Jul-21",
      "PE": "27.01",
      "PB": "4.12",
      "Div": "1.18"
  },
  {
      "Date": "29-Jul-21",
      "PE": "27.08",
      "PB": "4.12",
      "Div": "1.19"
  },
  {
      "Date": "28-Jul-21",
      "PE": "26.98",
      "PB": "4.1",
      "Div": "1.18"
  },
  {
      "Date": "27-Jul-21",
      "PE": "27.45",
      "PB": "4.11",
      "Div": "1.14"
  },
  {
      "Date": "26-Jul-21",
      "PE": "28.13",
      "PB": "4.13",
      "Div": "1.13"
  },
  {
      "Date": "23-Jul-21",
      "PE": "28.28",
      "PB": "4.16",
      "Div": "1.13"
  },
  {
      "Date": "22-Jul-21",
      "PE": "28.22",
      "PB": "4.26",
      "Div": "1.11"
  },
  {
      "Date": "20-Jul-21",
      "PE": "27.89",
      "PB": "4.21",
      "Div": "1.11"
  },
  {
      "Date": "19-Jul-21",
      "PE": "28.23",
      "PB": "4.25",
      "Div": "1.11"
  },
  {
      "Date": "16-Jul-21",
      "PE": "28.53",
      "PB": "4.29",
      "Div": "1.09"
  },
  {
      "Date": "15-Jul-21",
      "PE": "28.63",
      "PB": "4.29",
      "Div": "1.1"
  },
  {
      "Date": "14-Jul-21",
      "PE": "28.5",
      "PB": "4.27",
      "Div": "1.09"
  },
  {
      "Date": "13-Jul-21",
      "PE": "28.43",
      "PB": "4.26",
      "Div": "1.08"
  },
  {
      "Date": "12-Jul-21",
      "PE": "28.21",
      "PB": "4.23",
      "Div": "1.09"
  },
  {
      "Date": "9-Jul-21",
      "PE": "28.27",
      "PB": "4.23",
      "Div": "1.09"
  },
  {
      "Date": "8-Jul-21",
      "PE": "28.34",
      "PB": "4.24",
      "Div": "1.14"
  },
  {
      "Date": "7-Jul-21",
      "PE": "28.62",
      "PB": "4.28",
      "Div": "1.1"
  },
  {
      "Date": "6-Jul-21",
      "PE": "28.5",
      "PB": "4.26",
      "Div": "1.1"
  },
  {
      "Date": "5-Jul-21",
      "PE": "28.53",
      "PB": "4.27",
      "Div": "1.23"
  },
  {
      "Date": "2-Jul-21",
      "PE": "28.33",
      "PB": "4.24",
      "Div": "1.23"
  },
  {
      "Date": "1-Jul-21",
      "PE": "28.26",
      "PB": "4.23",
      "Div": "1.27"
  },
  {
      "Date": "30-Jun-21",
      "PE": "28.33",
      "PB": "4.24",
      "Div": "1.26"
  },
  {
      "Date": "29-Jun-21",
      "PE": "29",
      "PB": "4.31",
      "Div": "1.26"
  },
  {
      "Date": "28-Jun-21",
      "PE": "29.12",
      "PB": "4.33",
      "Div": "1.22"
  },
  {
      "Date": "25-Jun-21",
      "PE": "29.21",
      "PB": "4.34",
      "Div": "1.21"
  },
  {
      "Date": "24-Jun-21",
      "PE": "29.08",
      "PB": "4.32",
      "Div": "1.22"
  },
  {
      "Date": "23-Jun-21",
      "PE": "28.89",
      "PB": "4.3",
      "Div": "1.22"
  },
  {
      "Date": "22-Jun-21",
      "PE": "29.05",
      "PB": "4.32",
      "Div": "1.21"
  },
  {
      "Date": "21-Jun-21",
      "PE": "29.19",
      "PB": "4.31",
      "Div": "1.22"
  },
  {
      "Date": "18-Jun-21",
      "PE": "29.08",
      "PB": "4.32",
      "Div": "1.24"
  },
  {
      "Date": "17-Jun-21",
      "PE": "29.1",
      "PB": "4.43",
      "Div": "1.24"
  },
  {
      "Date": "16-Jun-21",
      "PE": "29.24",
      "PB": "4.45",
      "Div": "1.2"
  },
  {
      "Date": "15-Jun-21",
      "PE": "29.43",
      "PB": "4.48",
      "Div": "1.2"
  },
  {
      "Date": "14-Jun-21",
      "PE": "29.32",
      "PB": "4.46",
      "Div": "1.2"
  },
  {
      "Date": "11-Jun-21",
      "PE": "29.3",
      "PB": "4.46",
      "Div": "1.18"
  },
  {
      "Date": "10-Jun-21",
      "PE": "29.18",
      "PB": "4.44",
      "Div": "1.15"
  },
  {
      "Date": "9-Jun-21",
      "PE": "28.99",
      "PB": "4.41",
      "Div": "1.07"
  },
  {
      "Date": "8-Jun-21",
      "PE": "29.19",
      "PB": "4.44",
      "Div": "1.07"
  },
  {
      "Date": "7-Jun-21",
      "PE": "29.21",
      "PB": "4.44",
      "Div": "1.07"
  },
  {
      "Date": "4-Jun-21",
      "PE": "29.06",
      "PB": "4.42",
      "Div": "1.07"
  },
  {
      "Date": "3-Jun-21",
      "PE": "29.1",
      "PB": "4.43",
      "Div": "1.07"
  },
  {
      "Date": "2-Jun-21",
      "PE": "28.88",
      "PB": "4.39",
      "Div": "1.06"
  },
  {
      "Date": "1-Jun-21",
      "PE": "28.87",
      "PB": "4.39",
      "Div": "1.07"
  },
  {
      "Date": "31-May-21",
      "PE": "29.22",
      "PB": "4.39",
      "Div": "1.06"
  },
  {
      "Date": "28-May-21",
      "PE": "28.98",
      "PB": "4.42",
      "Div": "0.98"
  },
  {
      "Date": "27-May-21",
      "PE": "29.48",
      "PB": "4.39",
      "Div": "0.99"
  },
  {
      "Date": "26-May-21",
      "PE": "29.41",
      "PB": "4.38",
      "Div": "0.99"
  },
  {
      "Date": "25-May-21",
      "PE": "29.25",
      "PB": "4.35",
      "Div": "1"
  },
  {
      "Date": "24-May-21",
      "PE": "29.52",
      "PB": "4.35",
      "Div": "0.97"
  },
  {
      "Date": "21-May-21",
      "PE": "29.48",
      "PB": "4.34",
      "Div": "0.97"
  },
  {
      "Date": "20-May-21",
      "PE": "29.59",
      "PB": "4.27",
      "Div": "0.99"
  },
  {
      "Date": "19-May-21",
      "PE": "29.98",
      "PB": "4.3",
      "Div": "0.98"
  },
  {
      "Date": "18-May-21",
      "PE": "30.53",
      "PB": "4.33",
      "Div": "0.97"
  },
  {
      "Date": "17-May-21",
      "PE": "30.2",
      "PB": "4.27",
      "Div": "0.98"
  },
  {
      "Date": "14-May-21",
      "PE": "29.79",
      "PB": "4.2",
      "Div": "1"
  },
  {
      "Date": "12-May-21",
      "PE": "29.83",
      "PB": "4.21",
      "Div": "1"
  },
  {
      "Date": "11-May-21",
      "PE": "30.14",
      "PB": "4.25",
      "Div": "0.99"
  },
  {
      "Date": "10-May-21",
      "PE": "30.43",
      "PB": "4.28",
      "Div": "0.98"
  },
  {
      "Date": "7-May-21",
      "PE": "30.23",
      "PB": "4.24",
      "Div": "0.99"
  },
  {
      "Date": "6-May-21",
      "PE": "30.82",
      "PB": "4.22",
      "Div": "1"
  },
  {
      "Date": "5-May-21",
      "PE": "30.65",
      "PB": "4.19",
      "Div": "1"
  },
  {
      "Date": "4-May-21",
      "PE": "30.47",
      "PB": "4.15",
      "Div": "1.01"
  },
  {
      "Date": "3-May-21",
      "PE": "31.54",
      "PB": "4.19",
      "Div": "1"
  },
  {
      "Date": "30-Apr-21",
      "PE": "32.21",
      "PB": "4.19",
      "Div": "1"
  },
  {
      "Date": "29-Apr-21",
      "PE": "32.79",
      "PB": "4.26",
      "Div": "0.99"
  },
  {
      "Date": "28-Apr-21",
      "PE": "32.72",
      "PB": "4.26",
      "Div": "0.96"
  },
  {
      "Date": "27-Apr-21",
      "PE": "32.28",
      "PB": "4.2",
      "Div": "0.98"
  },
  {
      "Date": "26-Apr-21",
      "PE": "32.39",
      "PB": "4.15",
      "Div": "0.99"
  },
  {
      "Date": "23-Apr-21",
      "PE": "32.07",
      "PB": "4.11",
      "Div": "1"
  },
  {
      "Date": "22-Apr-21",
      "PE": "32.22",
      "PB": "4.12",
      "Div": "0.99"
  },
  {
      "Date": "20-Apr-21",
      "PE": "31.97",
      "PB": "4.09",
      "Div": "1"
  },
  {
      "Date": "19-Apr-21",
      "PE": "32.12",
      "PB": "4.11",
      "Div": "1"
  },
  {
      "Date": "16-Apr-21",
      "PE": "32.84",
      "PB": "4.18",
      "Div": "0.98"
  },
  {
      "Date": "15-Apr-21",
      "PE": "32.78",
      "PB": "4.17",
      "Div": "0.98"
  },
  {
      "Date": "13-Apr-21",
      "PE": "32.72",
      "PB": "4.15",
      "Div": "0.99"
  },
  {
      "Date": "12-Apr-21",
      "PE": "32.34",
      "PB": "4.09",
      "Div": "1"
  },
  {
      "Date": "9-Apr-21",
      "PE": "33.53",
      "PB": "4.24",
      "Div": "0.97"
  },
  {
      "Date": "8-Apr-21",
      "PE": "33.61",
      "PB": "4.25",
      "Div": "0.96"
  },
  {
      "Date": "7-Apr-21",
      "PE": "33.49",
      "PB": "4.24",
      "Div": "0.95"
  },
  {
      "Date": "6-Apr-21",
      "PE": "33.18",
      "PB": "4.2",
      "Div": "0.96"
  },
  {
      "Date": "5-Apr-21",
      "PE": "33.08",
      "PB": "4.19",
      "Div": "0.97"
  },
  {
      "Date": "1-Apr-21",
      "PE": "33.6",
      "PB": "4.25",
      "Div": "0.95"
  },
  {
      "Date": "31-Mar-21",
      "PE": "33.2",
      "PB": "4.2",
      "Div": "0.96"
  },
  {
      "Date": "30-Mar-21",
      "PE": "40.43",
      "PB": "4.21",
      "Div": "1.07"
  },
  {
      "Date": "26-Mar-21",
      "PE": "39.51",
      "PB": "4.11",
      "Div": "1.09"
  },
  {
      "Date": "25-Mar-21",
      "PE": "39.02",
      "PB": "4.06",
      "Div": "1.11"
  },
  {
      "Date": "24-Mar-21",
      "PE": "39.63",
      "PB": "4.12",
      "Div": "1.09"
  },
  {
      "Date": "23-Mar-21",
      "PE": "40.35",
      "PB": "4.2",
      "Div": "1.07"
  },
  {
      "Date": "22-Mar-21",
      "PE": "40.14",
      "PB": "4.18",
      "Div": "1.08"
  },
  {
      "Date": "19-Mar-21",
      "PE": "40.16",
      "PB": "4.18",
      "Div": "1.08"
  },
  {
      "Date": "18-Mar-21",
      "PE": "39.65",
      "PB": "4.13",
      "Div": "1.09"
  },
  {
      "Date": "17-Mar-21",
      "PE": "40.1",
      "PB": "4.17",
      "Div": "1.08"
  },
  {
      "Date": "16-Mar-21",
      "PE": "40.61",
      "PB": "4.23",
      "Div": "1.07"
  },
  {
      "Date": "15-Mar-21",
      "PE": "40.66",
      "PB": "4.23",
      "Div": "1.06"
  },
  {
      "Date": "12-Mar-21",
      "PE": "40.94",
      "PB": "4.26",
      "Div": "1.06"
  },
  {
      "Date": "10-Mar-21",
      "PE": "41.33",
      "PB": "4.3",
      "Div": "1.05"
  },
  {
      "Date": "9-Mar-21",
      "PE": "41.12",
      "PB": "4.28",
      "Div": "1.05"
  },
  {
      "Date": "8-Mar-21",
      "PE": "40.74",
      "PB": "4.24",
      "Div": "-"
  },
  {
      "Date": "5-Mar-21",
      "PE": "40.76",
      "PB": "4.23",
      "Div": "1.06"
  },
  {
      "Date": "4-Mar-21",
      "PE": "41.15",
      "PB": "4.27",
      "Div": "1.05"
  },
  {
      "Date": "3-Mar-21",
      "PE": "41.6",
      "PB": "4.32",
      "Div": "1.04"
  },
  {
      "Date": "2-Mar-21",
      "PE": "40.71",
      "PB": "4.23",
      "Div": "1.06"
  },
  {
      "Date": "1-Mar-21",
      "PE": "40.28",
      "PB": "4.18",
      "Div": "1.08"
  },
  {
      "Date": "26-Feb-21",
      "PE": "39.65",
      "PB": "4.12",
      "Div": "1.09"
  },
  {
      "Date": "25-Feb-21",
      "PE": "41.2",
      "PB": "4.28",
      "Div": "1.05"
  },
  {
      "Date": "24-Feb-21",
      "PE": "40.88",
      "PB": "4.24",
      "Div": "1.06"
  },
  {
      "Date": "23-Feb-21",
      "PE": "40.14",
      "PB": "4.17",
      "Div": "1.08"
  },
  {
      "Date": "22-Feb-21",
      "PE": "40.05",
      "PB": "4.16",
      "Div": "1.08"
  },
  {
      "Date": "19-Feb-21",
      "PE": "40.88",
      "PB": "4.24",
      "Div": "1.06"
  },
  {
      "Date": "18-Feb-21",
      "PE": "41.26",
      "PB": "4.28",
      "Div": "1.05"
  },
  {
      "Date": "17-Feb-21",
      "PE": "41.49",
      "PB": "4.31",
      "Div": "1.04"
  },
  {
      "Date": "16-Feb-21",
      "PE": "41.78",
      "PB": "4.34",
      "Div": "1.04"
  },
  {
      "Date": "15-Feb-21",
      "PE": "41.59",
      "PB": "4.34",
      "Div": "1.04"
  },
  {
      "Date": "12-Feb-21",
      "PE": "41.58",
      "PB": "4.3",
      "Div": "1.05"
  },
  {
      "Date": "11-Feb-21",
      "PE": "41.65",
      "PB": "4.3",
      "Div": "1.05"
  },
  {
      "Date": "10-Feb-21",
      "PE": "41.81",
      "PB": "4.28",
      "Div": "1.05"
  },
  {
      "Date": "9-Feb-21",
      "PE": "41.97",
      "PB": "4.28",
      "Div": "1.05"
  },
  {
      "Date": "8-Feb-21",
      "PE": "42",
      "PB": "4.28",
      "Div": "1.05"
  },
  {
      "Date": "5-Feb-21",
      "PE": "41.46",
      "PB": "4.23",
      "Div": "1.06"
  },
  {
      "Date": "4-Feb-21",
      "PE": "40.81",
      "PB": "4.22",
      "Div": "1.07"
  },
  {
      "Date": "3-Feb-21",
      "PE": "39.16",
      "PB": "4.19",
      "Div": "1.07"
  },
  {
      "Date": "2-Feb-21",
      "PE": "38.79",
      "PB": "4.15",
      "Div": "1.08"
  },
  {
      "Date": "1-Feb-21",
      "PE": "38.26",
      "PB": "4.05",
      "Div": "1.11"
  },
  {
      "Date": "29-Jan-21",
      "PE": "36.57",
      "PB": "3.86",
      "Div": "1.16"
  },
  {
      "Date": "28-Jan-21",
      "PE": "36.96",
      "PB": "3.92",
      "Div": "1.15"
  },
  {
      "Date": "27-Jan-21",
      "PE": "37.52",
      "PB": "3.96",
      "Div": "1.14"
  },
  {
      "Date": "25-Jan-21",
      "PE": "38.42",
      "PB": "4.04",
      "Div": "1.11"
  },
  {
      "Date": "22-Jan-21",
      "PE": "38.86",
      "PB": "4.07",
      "Div": "1.1"
  },
  {
      "Date": "21-Jan-21",
      "PE": "39.41",
      "PB": "4.13",
      "Div": "1.09"
  },
  {
      "Date": "20-Jan-21",
      "PE": "39.55",
      "PB": "4.15",
      "Div": "1.08"
  },
  {
      "Date": "19-Jan-21",
      "PE": "39.22",
      "PB": "4.12",
      "Div": "1.09"
  },
  {
      "Date": "18-Jan-21",
      "PE": "38.92",
      "PB": "4.05",
      "Div": "1.11"
  },
  {
      "Date": "15-Jan-21",
      "PE": "39.34",
      "PB": "4.09",
      "Div": "1.1"
  },
  {
      "Date": "14-Jan-21",
      "PE": "40.03",
      "PB": "4.14",
      "Div": "1.09"
  },
  {
      "Date": "13-Jan-21",
      "PE": "39.94",
      "PB": "4.13",
      "Div": "1.09"
  },
  {
      "Date": "12-Jan-21",
      "PE": "39.94",
      "PB": "4.13",
      "Div": "1.09"
  },
  {
      "Date": "11-Jan-21",
      "PE": "39.72",
      "PB": "4.1",
      "Div": "1.1"
  },
  {
      "Date": "8-Jan-21",
      "PE": "39.45",
      "PB": "4.07",
      "Div": "1.11"
  },
  {
      "Date": "7-Jan-21",
      "PE": "38.88",
      "PB": "4.01",
      "Div": "1.12"
  },
  {
      "Date": "6-Jan-21",
      "PE": "38.9",
      "PB": "4.01",
      "Div": "1.12"
  },
  {
      "Date": "5-Jan-21",
      "PE": "39.05",
      "PB": "4.02",
      "Div": "1.12"
  },
  {
      "Date": "4-Jan-21",
      "PE": "38.87",
      "PB": "4.01",
      "Div": "1.12"
  },
  {
      "Date": "1-Jan-21",
      "PE": "38.55",
      "PB": "3.97",
      "Div": "1.13"
  },
  {
      "Date": "31-Dec-20",
      "PE": "38.45",
      "PB": "3.96",
      "Div": "1.14"
  },
  {
      "Date": "30-Dec-20",
      "PE": "38.45",
      "PB": "3.96",
      "Div": "1.14"
  },
  {
      "Date": "29-Dec-20",
      "PE": "38.31",
      "PB": "3.94",
      "Div": "1.14"
  },
  {
      "Date": "28-Dec-20",
      "PE": "38.15",
      "PB": "3.93",
      "Div": "1.14"
  },
  {
      "Date": "24-Dec-20",
      "PE": "37.81",
      "PB": "3.89",
      "Div": "1.15"
  },
  {
      "Date": "23-Dec-20",
      "PE": "37.4",
      "PB": "3.85",
      "Div": "1.17"
  },
  {
      "Date": "22-Dec-20",
      "PE": "37.03",
      "PB": "3.81",
      "Div": "1.18"
  },
  {
      "Date": "21-Dec-20",
      "PE": "36.65",
      "PB": "3.77",
      "Div": "1.19"
  },
  {
      "Date": "18-Dec-20",
      "PE": "37.84",
      "PB": "3.89",
      "Div": "1.15"
  },
  {
      "Date": "17-Dec-20",
      "PE": "37.79",
      "PB": "3.89",
      "Div": "1.16"
  },
  {
      "Date": "16-Dec-20",
      "PE": "37.63",
      "PB": "3.87",
      "Div": "1.16"
  },
  {
      "Date": "15-Dec-20",
      "PE": "37.31",
      "PB": "3.84",
      "Div": "1.17"
  },
  {
      "Date": "14-Dec-20",
      "PE": "37.28",
      "PB": "3.84",
      "Div": "1.17"
  },
  {
      "Date": "11-Dec-20",
      "PE": "37.16",
      "PB": "3.82",
      "Div": "1.18"
  },
  {
      "Date": "10-Dec-20",
      "PE": "37.06",
      "PB": "3.81",
      "Div": "1.18"
  },
  {
      "Date": "9-Dec-20",
      "PE": "37.2",
      "PB": "3.83",
      "Div": "1.17"
  },
  {
      "Date": "8-Dec-20",
      "PE": "36.83",
      "PB": "3.79",
      "Div": "1.19"
  },
  {
      "Date": "7-Dec-20",
      "PE": "36.73",
      "PB": "3.78",
      "Div": "1.19"
  },
  {
      "Date": "4-Dec-20",
      "PE": "36.46",
      "PB": "3.75",
      "Div": "1.2"
  },
  {
      "Date": "3-Dec-20",
      "PE": "36.12",
      "PB": "3.72",
      "Div": "1.21"
  },
  {
      "Date": "2-Dec-20",
      "PE": "36.06",
      "PB": "3.71",
      "Div": "1.21"
  },
  {
      "Date": "1-Dec-20",
      "PE": "36.05",
      "PB": "3.71",
      "Div": "1.21"
  },
  {
      "Date": "27-Nov-20",
      "PE": "35.66",
      "PB": "3.67",
      "Div": "1.22"
  },
  {
      "Date": "26-Nov-20",
      "PE": "35.71",
      "PB": "3.68",
      "Div": "1.22"
  },
  {
      "Date": "25-Nov-20",
      "PE": "35.36",
      "PB": "3.64",
      "Div": "1.23"
  },
  {
      "Date": "24-Nov-20",
      "PE": "35.9",
      "PB": "3.7",
      "Div": "1.22"
  },
  {
      "Date": "23-Nov-20",
      "PE": "35.55",
      "PB": "3.66",
      "Div": "1.23"
  },
  {
      "Date": "20-Nov-20",
      "PE": "35.36",
      "PB": "3.64",
      "Div": "1.23"
  },
  {
      "Date": "19-Nov-20",
      "PE": "35.12",
      "PB": "3.61",
      "Div": "1.24"
  },
  {
      "Date": "18-Nov-20",
      "PE": "35.59",
      "PB": "3.66",
      "Div": "1.23"
  },
  {
      "Date": "17-Nov-20",
      "PE": "34.93",
      "PB": "3.64",
      "Div": "1.23"
  },
  {
      "Date": "14-Nov-20",
      "PE": "34.73",
      "PB": "3.62",
      "Div": "1.24"
  },
  {
      "Date": "13-Nov-20",
      "PE": "34.57",
      "PB": "3.6",
      "Div": "1.25"
  },
  {
      "Date": "12-Nov-20",
      "PE": "34.48",
      "PB": "3.59",
      "Div": "1.25"
  },
  {
      "Date": "11-Nov-20",
      "PE": "34.5",
      "PB": "3.61",
      "Div": "1.25"
  },
  {
      "Date": "10-Nov-20",
      "PE": "34.18",
      "PB": "3.58",
      "Div": "1.26"
  },
  {
      "Date": "9-Nov-20",
      "PE": "33.62",
      "PB": "3.53",
      "Div": "1.27"
  },
  {
      "Date": "6-Nov-20",
      "PE": "33.09",
      "PB": "3.47",
      "Div": "1.29"
  },
  {
      "Date": "5-Nov-20",
      "PE": "32.84",
      "PB": "3.43",
      "Div": "1.31"
  },
  {
      "Date": "4-Nov-20",
      "PE": "32.27",
      "PB": "3.37",
      "Div": "1.33"
  },
  {
      "Date": "3-Nov-20",
      "PE": "31.82",
      "PB": "3.34",
      "Div": "1.34"
  },
  {
      "Date": "2-Nov-20",
      "PE": "31.43",
      "PB": "3.3",
      "Div": "1.36"
  },
  {
      "Date": "30-Oct-20",
      "PE": "31.9",
      "PB": "3.3",
      "Div": "1.36"
  },
  {
      "Date": "29-Oct-20",
      "PE": "31.56",
      "PB": "3.3",
      "Div": "1.36"
  },
  {
      "Date": "28-Oct-20",
      "PE": "33.94",
      "PB": "3.32",
      "Div": "1.35"
  },
  {
      "Date": "27-Oct-20",
      "PE": "34.49",
      "PB": "3.37",
      "Div": "1.34"
  },
  {
      "Date": "26-Oct-20",
      "PE": "33.95",
      "PB": "3.33",
      "Div": "1.35"
  },
  {
      "Date": "23-Oct-20",
      "PE": "34.37",
      "PB": "3.38",
      "Div": "1.33"
  },
  {
      "Date": "22-Oct-20",
      "PE": "34.27",
      "PB": "3.37",
      "Div": "1.33"
  },
  {
      "Date": "21-Oct-20",
      "PE": "34.4",
      "PB": "3.38",
      "Div": "1.33"
  },
  {
      "Date": "20-Oct-20",
      "PE": "34.28",
      "PB": "3.37",
      "Div": "1.33"
  },
  {
      "Date": "19-Oct-20",
      "PE": "34.43",
      "PB": "3.36",
      "Div": "1.34"
  },
  {
      "Date": "16-Oct-20",
      "PE": "34.13",
      "PB": "3.33",
      "Div": "1.35"
  },
  {
      "Date": "15-Oct-20",
      "PE": "34.03",
      "PB": "3.31",
      "Div": "1.36"
  },
  {
      "Date": "14-Oct-20",
      "PE": "34.87",
      "PB": "3.39",
      "Div": "1.33"
  },
  {
      "Date": "13-Oct-20",
      "PE": "34.77",
      "PB": "3.38",
      "Div": "1.33"
  },
  {
      "Date": "12-Oct-20",
      "PE": "34.76",
      "PB": "3.38",
      "Div": "1.33"
  },
  {
      "Date": "9-Oct-20",
      "PE": "34.71",
      "PB": "3.37",
      "Div": "1.33"
  },
  {
      "Date": "8-Oct-20",
      "PE": "34.4",
      "PB": "3.35",
      "Div": "1.34"
  },
  {
      "Date": "7-Oct-20",
      "PE": "34.12",
      "PB": "3.32",
      "Div": "1.35"
  },
  {
      "Date": "6-Oct-20",
      "PE": "33.9",
      "PB": "3.3",
      "Div": "1.36"
  },
  {
      "Date": "5-Oct-20",
      "PE": "33.43",
      "PB": "3.26",
      "Div": "1.38"
  },
  {
      "Date": "1-Oct-20",
      "PE": "33.18",
      "PB": "3.23",
      "Div": "1.39"
  },
  {
      "Date": "30-Sep-20",
      "PE": "32.69",
      "PB": "3.19",
      "Div": "1.41"
  },
  {
      "Date": "29-Sep-20",
      "PE": "32.62",
      "PB": "3.18",
      "Div": "1.42"
  },
  {
      "Date": "28-Sep-20",
      "PE": "32.63",
      "PB": "3.18",
      "Div": "1.41"
  },
  {
      "Date": "25-Sep-20",
      "PE": "32.12",
      "PB": "3.13",
      "Div": "1.44"
  },
  {
      "Date": "24-Sep-20",
      "PE": "30.97",
      "PB": "3.01",
      "Div": "1.51"
  },
  {
      "Date": "23-Sep-20",
      "PE": "31.91",
      "PB": "3.1",
      "Div": "1.47"
  },
  {
      "Date": "22-Sep-20",
      "PE": "31.97",
      "PB": "3.1",
      "Div": "1.47"
  },
  {
      "Date": "21-Sep-20",
      "PE": "32.25",
      "PB": "3.13",
      "Div": "1.45"
  },
  {
      "Date": "18-Sep-20",
      "PE": "32.98",
      "PB": "3.2",
      "Div": "1.42"
  },
  {
      "Date": "17-Sep-20",
      "PE": "33.01",
      "PB": "3.2",
      "Div": "1.42"
  },
  {
      "Date": "16-Sep-20",
      "PE": "33.26",
      "PB": "3.23",
      "Div": "1.41"
  },
  {
      "Date": "15-Sep-20",
      "PE": "33.03",
      "PB": "3.2",
      "Div": "1.42"
  },
  {
      "Date": "14-Sep-20",
      "PE": "32.79",
      "PB": "3.21",
      "Div": "1.47"
  },
  {
      "Date": "11-Sep-20",
      "PE": "32.86",
      "PB": "3.22",
      "Div": "1.46"
  },
  {
      "Date": "10-Sep-20",
      "PE": "32.82",
      "PB": "3.22",
      "Div": "1.46"
  },
  {
      "Date": "9-Sep-20",
      "PE": "32.33",
      "PB": "3.17",
      "Div": "1.49"
  },
  {
      "Date": "8-Sep-20",
      "PE": "32.44",
      "PB": "3.18",
      "Div": "1.48"
  },
  {
      "Date": "7-Sep-20",
      "PE": "32.55",
      "PB": "3.19",
      "Div": "1.48"
  },
  {
      "Date": "4-Sep-20",
      "PE": "32.49",
      "PB": "3.18",
      "Div": "1.48"
  },
  {
      "Date": "3-Sep-20",
      "PE": "33.04",
      "PB": "3.24",
      "Div": "1.45"
  },
  {
      "Date": "2-Sep-20",
      "PE": "32.71",
      "PB": "3.24",
      "Div": "1.44"
  },
  {
      "Date": "1-Sep-20",
      "PE": "32.53",
      "PB": "3.22",
      "Div": "1.45"
  },
  {
      "Date": "31-Aug-20",
      "PE": "32.29",
      "PB": "3.2",
      "Div": "1.46"
  },
  {
      "Date": "28-Aug-20",
      "PE": "32.92",
      "PB": "3.26",
      "Div": "1.43"
  },
  {
      "Date": "27-Aug-20",
      "PE": "32.67",
      "PB": "3.23",
      "Div": "1.45"
  },
  {
      "Date": "26-Aug-20",
      "PE": "32.64",
      "PB": "3.23",
      "Div": "1.45"
  },
  {
      "Date": "25-Aug-20",
      "PE": "32.43",
      "PB": "3.21",
      "Div": "1.46"
  },
  {
      "Date": "24-Aug-20",
      "PE": "32.41",
      "PB": "3.21",
      "Div": "1.46"
  },
  {
      "Date": "21-Aug-20",
      "PE": "32.08",
      "PB": "3.18",
      "Div": "1.47"
  },
  {
      "Date": "20-Aug-20",
      "PE": "31.91",
      "PB": "3.17",
      "Div": "1.49"
  },
  {
      "Date": "19-Aug-20",
      "PE": "32.09",
      "PB": "3.21",
      "Div": "1.44"
  },
  {
      "Date": "18-Aug-20",
      "PE": "32.03",
      "PB": "3.21",
      "Div": "1.45"
  },
  {
      "Date": "17-Aug-20",
      "PE": "31.64",
      "PB": "3.17",
      "Div": "1.46"
  },
  {
      "Date": "14-Aug-20",
      "PE": "31.09",
      "PB": "3.15",
      "Div": "1.47"
  },
  {
      "Date": "13-Aug-20",
      "PE": "31.42",
      "PB": "3.18",
      "Div": "1.46"
  },
  {
      "Date": "12-Aug-20",
      "PE": "31.33",
      "PB": "3.18",
      "Div": "1.46"
  },
  {
      "Date": "11-Aug-20",
      "PE": "31.37",
      "PB": "3.19",
      "Div": "1.46"
  },
  {
      "Date": "10-Aug-20",
      "PE": "31.23",
      "PB": "3.17",
      "Div": "1.46"
  },
  {
      "Date": "7-Aug-20",
      "PE": "30.72",
      "PB": "3.16",
      "Div": "1.47"
  },
  {
      "Date": "6-Aug-20",
      "PE": "30.68",
      "PB": "3.15",
      "Div": "1.47"
  },
  {
      "Date": "5-Aug-20",
      "PE": "30.41",
      "PB": "3.13",
      "Div": "1.48"
  },
  {
      "Date": "4-Aug-20",
      "PE": "30.39",
      "PB": "3.12",
      "Div": "1.49"
  },
  {
      "Date": "3-Aug-20",
      "PE": "29.7",
      "PB": "3.07",
      "Div": "1.51"
  },
  {
      "Date": "31-Jul-20",
      "PE": "30.2",
      "PB": "3.12",
      "Div": "1.49"
  },
  {
      "Date": "30-Jul-20",
      "PE": "29.46",
      "PB": "3.07",
      "Div": "1.56"
  },
  {
      "Date": "29-Jul-20",
      "PE": "29.59",
      "PB": "3.1",
      "Div": "1.54"
  },
  {
      "Date": "28-Jul-20",
      "PE": "29.87",
      "PB": "3.14",
      "Div": "1.49"
  },
  {
      "Date": "27-Jul-20",
      "PE": "29.18",
      "PB": "3.17",
      "Div": "1.44"
  },
  {
      "Date": "24-Jul-20",
      "PE": "29.35",
      "PB": "3.19",
      "Div": "1.43"
  },
  {
      "Date": "23-Jul-20",
      "PE": "29.2",
      "PB": "3.2",
      "Div": "1.43"
  },
  {
      "Date": "22-Jul-20",
      "PE": "28.93",
      "PB": "3.17",
      "Div": "1.44"
  },
  {
      "Date": "21-Jul-20",
      "PE": "29.01",
      "PB": "3.18",
      "Div": "1.44"
  },
  {
      "Date": "20-Jul-20",
      "PE": "28.86",
      "PB": "3.14",
      "Div": "1.46"
  },
  {
      "Date": "17-Jul-20",
      "PE": "28.55",
      "PB": "3.11",
      "Div": "1.47"
  },
  {
      "Date": "16-Jul-20",
      "PE": "28.19",
      "PB": "3.06",
      "Div": "1.49"
  },
  {
      "Date": "15-Jul-20",
      "PE": "27.88",
      "PB": "3.03",
      "Div": "1.51"
  },
  {
      "Date": "14-Jul-20",
      "PE": "27.85",
      "PB": "3.02",
      "Div": "1.51"
  },
  {
      "Date": "13-Jul-20",
      "PE": "28.36",
      "PB": "3.08",
      "Div": "1.49"
  },
  {
      "Date": "10-Jul-20",
      "PE": "28.27",
      "PB": "3.07",
      "Div": "1.49"
  },
  {
      "Date": "9-Jul-20",
      "PE": "28.26",
      "PB": "3.08",
      "Div": "1.48"
  },
  {
      "Date": "8-Jul-20",
      "PE": "27.98",
      "PB": "3.05",
      "Div": "1.5"
  },
  {
      "Date": "7-Jul-20",
      "PE": "28.22",
      "PB": "3.08",
      "Div": "1.49"
  },
  {
      "Date": "6-Jul-20",
      "PE": "28.13",
      "PB": "3.07",
      "Div": "1.49"
  },
  {
      "Date": "3-Jul-20",
      "PE": "27.78",
      "PB": "3.03",
      "Div": "1.51"
  },
  {
      "Date": "2-Jul-20",
      "PE": "27.63",
      "PB": "3.01",
      "Div": "1.52"
  },
  {
      "Date": "1-Jul-20",
      "PE": "26.97",
      "PB": "2.98",
      "Div": "1.54"
  },
  {
      "Date": "30-Jun-20",
      "PE": "26.32",
      "PB": "2.94",
      "Div": "1.55"
  },
  {
      "Date": "29-Jun-20",
      "PE": "26.49",
      "PB": "2.95",
      "Div": "1.55"
  },
  {
      "Date": "26-Jun-20",
      "PE": "26.67",
      "PB": "2.97",
      "Div": "1.54"
  },
  {
      "Date": "25-Jun-20",
      "PE": "25.59",
      "PB": "2.93",
      "Div": "1.48"
  },
  {
      "Date": "24-Jun-20",
      "PE": "25.63",
      "PB": "2.93",
      "Div": "1.48"
  },
  {
      "Date": "23-Jun-20",
      "PE": "26.05",
      "PB": "2.98",
      "Div": "1.45"
  },
  {
      "Date": "22-Jun-20",
      "PE": "25.66",
      "PB": "2.94",
      "Div": "1.48"
  },
  {
      "Date": "19-Jun-20",
      "PE": "25.49",
      "PB": "2.92",
      "Div": "1.49"
  },
  {
      "Date": "18-Jun-20",
      "PE": "25.11",
      "PB": "2.87",
      "Div": "1.51"
  },
  {
      "Date": "17-Jun-20",
      "PE": "24.59",
      "PB": "2.81",
      "Div": "1.54"
  },
  {
      "Date": "16-Jun-20",
      "PE": "24.31",
      "PB": "2.82",
      "Div": "1.54"
  },
  {
      "Date": "15-Jun-20",
      "PE": "23.69",
      "PB": "2.79",
      "Div": "1.55"
  },
  {
      "Date": "12-Jun-20",
      "PE": "24.08",
      "PB": "2.84",
      "Div": "1.53"
  },
  {
      "Date": "11-Jun-20",
      "PE": "23.9",
      "PB": "2.82",
      "Div": "1.54"
  },
  {
      "Date": "10-Jun-20",
      "PE": "24.41",
      "PB": "2.88",
      "Div": "1.51"
  },
  {
      "Date": "9-Jun-20",
      "PE": "24.25",
      "PB": "2.86",
      "Div": "1.52"
  },
  {
      "Date": "8-Jun-20",
      "PE": "23.97",
      "PB": "2.89",
      "Div": "1.5"
  },
  {
      "Date": "5-Jun-20",
      "PE": "23.91",
      "PB": "2.89",
      "Div": "1.5"
  },
  {
      "Date": "4-Jun-20",
      "PE": "23.42",
      "PB": "2.85",
      "Div": "1.52"
  },
  {
      "Date": "3-Jun-20",
      "PE": "23.5",
      "PB": "2.86",
      "Div": "1.51"
  },
  {
      "Date": "2-Jun-20",
      "PE": "23.31",
      "PB": "2.84",
      "Div": "1.53"
  },
  {
      "Date": "1-Jun-20",
      "PE": "22.96",
      "PB": "2.8",
      "Div": "1.55"
  },
  {
      "Date": "29-May-20",
      "PE": "22.38",
      "PB": "2.73",
      "Div": "1.59"
  },
  {
      "Date": "28-May-20",
      "PE": "22.17",
      "PB": "2.7",
      "Div": "1.61"
  },
  {
      "Date": "27-May-20",
      "PE": "21.77",
      "PB": "2.65",
      "Div": "1.64"
  },
  {
      "Date": "26-May-20",
      "PE": "21.1",
      "PB": "2.57",
      "Div": "1.69"
  },
  {
      "Date": "22-May-20",
      "PE": "20.97",
      "PB": "2.57",
      "Div": "1.69"
  },
  {
      "Date": "21-May-20",
      "PE": "21.14",
      "PB": "2.59",
      "Div": "1.67"
  },
  {
      "Date": "20-May-20",
      "PE": "21.16",
      "PB": "2.58",
      "Div": "1.68"
  },
  {
      "Date": "19-May-20",
      "PE": "20.39",
      "PB": "2.53",
      "Div": "1.72"
  },
  {
      "Date": "18-May-20",
      "PE": "20.26",
      "PB": "2.51",
      "Div": "1.73"
  },
  {
      "Date": "15-May-20",
      "PE": "20.98",
      "PB": "2.6",
      "Div": "1.67"
  },
  {
      "Date": "14-May-20",
      "PE": "21",
      "PB": "2.6",
      "Div": "1.67"
  },
  {
      "Date": "13-May-20",
      "PE": "21.51",
      "PB": "2.67",
      "Div": "1.62"
  },
  {
      "Date": "12-May-20",
      "PE": "21.11",
      "PB": "2.62",
      "Div": "1.65"
  },
  {
      "Date": "11-May-20",
      "PE": "21.21",
      "PB": "2.63",
      "Div": "1.65"
  },
  {
      "Date": "8-May-20",
      "PE": "21.28",
      "PB": "2.64",
      "Div": "1.64"
  },
  {
      "Date": "7-May-20",
      "PE": "21.18",
      "PB": "2.62",
      "Div": "1.65"
  },
  {
      "Date": "6-May-20",
      "PE": "21.34",
      "PB": "2.64",
      "Div": "1.64"
  },
  {
      "Date": "5-May-20",
      "PE": "21.19",
      "PB": "2.62",
      "Div": "1.65"
  },
  {
      "Date": "4-May-20",
      "PE": "21.39",
      "PB": "2.65",
      "Div": "1.64"
  },
  {
      "Date": "30-Apr-20",
      "PE": "22.35",
      "PB": "2.81",
      "Div": "1.54"
  },
  {
      "Date": "29-Apr-20",
      "PE": "21.65",
      "PB": "2.72",
      "Div": "1.59"
  },
  {
      "Date": "28-Apr-20",
      "PE": "21",
      "PB": "2.67",
      "Div": "1.62"
  },
  {
      "Date": "27-Apr-20",
      "PE": "20.77",
      "PB": "2.65",
      "Div": "1.64"
  },
  {
      "Date": "24-Apr-20",
      "PE": "20.48",
      "PB": "2.61",
      "Div": "1.66"
  },
  {
      "Date": "23-Apr-20",
      "PE": "20.84",
      "PB": "2.65",
      "Div": "1.63"
  },
  {
      "Date": "22-Apr-20",
      "PE": "20.56",
      "PB": "2.62",
      "Div": "1.66"
  },
  {
      "Date": "21-Apr-20",
      "PE": "20.1",
      "PB": "2.56",
      "Div": "1.69"
  },
  {
      "Date": "20-Apr-20",
      "PE": "20.75",
      "PB": "2.64",
      "Div": "1.64"
  },
  {
      "Date": "17-Apr-20",
      "PE": "20.85",
      "PB": "2.64",
      "Div": "1.64"
  },
  {
      "Date": "16-Apr-20",
      "PE": "20.25",
      "PB": "2.56",
      "Div": "1.69"
  },
  {
      "Date": "15-Apr-20",
      "PE": "20.11",
      "PB": "2.54",
      "Div": "1.7"
  },
  {
      "Date": "13-Apr-20",
      "PE": "20.27",
      "PB": "2.56",
      "Div": "1.69"
  },
  {
      "Date": "9-Apr-20",
      "PE": "20.53",
      "PB": "2.6",
      "Div": "1.67"
  },
  {
      "Date": "8-Apr-20",
      "PE": "19.72",
      "PB": "2.49",
      "Div": "1.74"
  },
  {
      "Date": "7-Apr-20",
      "PE": "19.81",
      "PB": "2.51",
      "Div": "1.73"
  },
  {
      "Date": "3-Apr-20",
      "PE": "18.22",
      "PB": "2.3",
      "Div": "1.88"
  },
  {
      "Date": "1-Apr-20",
      "PE": "18.6",
      "PB": "2.35",
      "Div": "1.84"
  },
  {
      "Date": "31-Mar-20",
      "PE": "19.38",
      "PB": "2.45",
      "Div": "1.77"
  },
  {
      "Date": "30-Mar-20",
      "PE": "18.66",
      "PB": "2.36",
      "Div": "1.84"
  },
  {
      "Date": "27-Mar-20",
      "PE": "19.52",
      "PB": "2.47",
      "Div": "1.76"
  },
  {
      "Date": "26-Mar-20",
      "PE": "19.47",
      "PB": "2.46",
      "Div": "1.76"
  },
  {
      "Date": "25-Mar-20",
      "PE": "18.74",
      "PB": "2.37",
      "Div": "1.83"
  },
  {
      "Date": "24-Mar-20",
      "PE": "17.58",
      "PB": "2.22",
      "Div": "1.95"
  },
  {
      "Date": "23-Mar-20",
      "PE": "17.15",
      "PB": "2.17",
      "Div": "2"
  },
  {
      "Date": "20-Mar-20",
      "PE": "19.72",
      "PB": "2.49",
      "Div": "1.74"
  },
  {
      "Date": "19-Mar-20",
      "PE": "18.63",
      "PB": "2.36",
      "Div": "1.84"
  },
  {
      "Date": "18-Mar-20",
      "PE": "19.27",
      "PB": "2.38",
      "Div": "1.8"
  },
  {
      "Date": "17-Mar-20",
      "PE": "20.41",
      "PB": "2.52",
      "Div": "1.7"
  },
  {
      "Date": "16-Mar-20",
      "PE": "20.93",
      "PB": "2.59",
      "Div": "1.66"
  },
  {
      "Date": "13-Mar-20",
      "PE": "22.66",
      "PB": "2.8",
      "Div": "1.53"
  },
  {
      "Date": "12-Mar-20",
      "PE": "21.83",
      "PB": "2.7",
      "Div": "1.59"
  },
  {
      "Date": "11-Mar-20",
      "PE": "23.8",
      "PB": "2.94",
      "Div": "1.46"
  },
  {
      "Date": "9-Mar-20",
      "PE": "23.79",
      "PB": "2.94",
      "Div": "1.46"
  },
  {
      "Date": "6-Mar-20",
      "PE": "25.01",
      "PB": "3.09",
      "Div": "1.39"
  },
  {
      "Date": "5-Mar-20",
      "PE": "25.65",
      "PB": "3.17",
      "Div": "1.35"
  },
  {
      "Date": "4-Mar-20",
      "PE": "25.61",
      "PB": "3.17",
      "Div": "1.35"
  },
  {
      "Date": "3-Mar-20",
      "PE": "25.73",
      "PB": "3.18",
      "Div": "1.35"
  },
  {
      "Date": "2-Mar-20",
      "PE": "25.34",
      "PB": "3.13",
      "Div": "1.37"
  },
  {
      "Date": "28-Feb-20",
      "PE": "25.49",
      "PB": "3.15",
      "Div": "1.36"
  },
  {
      "Date": "27-Feb-20",
      "PE": "26.48",
      "PB": "3.27",
      "Div": "1.31"
  },
  {
      "Date": "26-Feb-20",
      "PE": "26.58",
      "PB": "3.29",
      "Div": "1.3"
  },
  {
      "Date": "25-Feb-20",
      "PE": "26.85",
      "PB": "3.32",
      "Div": "1.29"
  },
  {
      "Date": "24-Feb-20",
      "PE": "26.92",
      "PB": "3.33",
      "Div": "1.29"
  },
  {
      "Date": "20-Feb-20",
      "PE": "27.5",
      "PB": "3.4",
      "Div": "1.26"
  },
  {
      "Date": "19-Feb-20",
      "PE": "27.6",
      "PB": "3.41",
      "Div": "1.26"
  },
  {
      "Date": "18-Feb-20",
      "PE": "27.29",
      "PB": "3.38",
      "Div": "1.27"
  },
  {
      "Date": "17-Feb-20",
      "PE": "27.42",
      "PB": "3.39",
      "Div": "1.27"
  },
  {
      "Date": "14-Feb-20",
      "PE": "27.45",
      "PB": "3.41",
      "Div": "1.26"
  },
  {
      "Date": "13-Feb-20",
      "PE": "27.59",
      "PB": "3.43",
      "Div": "1.25"
  },
  {
      "Date": "12-Feb-20",
      "PE": "27.65",
      "PB": "3.44",
      "Div": "1.25"
  },
  {
      "Date": "11-Feb-20",
      "PE": "27.21",
      "PB": "3.41",
      "Div": "1.26"
  },
  {
      "Date": "10-Feb-20",
      "PE": "26.89",
      "PB": "3.39",
      "Div": "1.27"
  },
  {
      "Date": "7-Feb-20",
      "PE": "27.03",
      "PB": "3.41",
      "Div": "1.26"
  },
  {
      "Date": "6-Feb-20",
      "PE": "27.16",
      "PB": "3.42",
      "Div": "1.26"
  },
  {
      "Date": "5-Feb-20",
      "PE": "27.05",
      "PB": "3.4",
      "Div": "1.26"
  },
  {
      "Date": "4-Feb-20",
      "PE": "26.57",
      "PB": "3.37",
      "Div": "1.27"
  },
  {
      "Date": "3-Feb-20",
      "PE": "25.9",
      "PB": "3.3",
      "Div": "1.3"
  },
  {
      "Date": "1-Feb-20",
      "PE": "25.75",
      "PB": "3.28",
      "Div": "1.31"
  },
  {
      "Date": "31-Jan-20",
      "PE": "26.41",
      "PB": "3.37",
      "Div": "1.27"
  },
  {
      "Date": "30-Jan-20",
      "PE": "26.57",
      "PB": "3.39",
      "Div": "1.27"
  },
  {
      "Date": "29-Jan-20",
      "PE": "26.81",
      "PB": "3.42",
      "Div": "1.26"
  },
  {
      "Date": "28-Jan-20",
      "PE": "26.65",
      "PB": "3.4",
      "Div": "1.26"
  },
  {
      "Date": "27-Jan-20",
      "PE": "27.73",
      "PB": "3.42",
      "Div": "1.25"
  },
  {
      "Date": "24-Jan-20",
      "PE": "28.1",
      "PB": "3.45",
      "Div": "1.24"
  },
  {
      "Date": "23-Jan-20",
      "PE": "27.88",
      "PB": "3.43",
      "Div": "1.25"
  },
  {
      "Date": "22-Jan-20",
      "PE": "27.73",
      "PB": "3.42",
      "Div": "1.26"
  },
  {
      "Date": "21-Jan-20",
      "PE": "27.84",
      "PB": "3.45",
      "Div": "1.25"
  },
  {
      "Date": "20-Jan-20",
      "PE": "28",
      "PB": "3.63",
      "Div": "1.25"
  },
  {
      "Date": "17-Jan-20",
      "PE": "28.61",
      "PB": "3.67",
      "Div": "1.24"
  },
  {
      "Date": "16-Jan-20",
      "PE": "28.62",
      "PB": "3.68",
      "Div": "1.24"
  },
  {
      "Date": "15-Jan-20",
      "PE": "28.63",
      "PB": "3.81",
      "Div": "1.22"
  },
  {
      "Date": "14-Jan-20",
      "PE": "28.67",
      "PB": "3.81",
      "Div": "1.22"
  },
  {
      "Date": "13-Jan-20",
      "PE": "28.67",
      "PB": "3.8",
      "Div": "1.22"
  },
  {
      "Date": "10-Jan-20",
      "PE": "28.51",
      "PB": "3.78",
      "Div": "1.23"
  },
  {
      "Date": "9-Jan-20",
      "PE": "28.41",
      "PB": "3.77",
      "Div": "1.23"
  },
  {
      "Date": "8-Jan-20",
      "PE": "27.97",
      "PB": "3.71",
      "Div": "1.25"
  },
  {
      "Date": "7-Jan-20",
      "PE": "28.03",
      "PB": "3.72",
      "Div": "1.25"
  },
  {
      "Date": "6-Jan-20",
      "PE": "27.89",
      "PB": "3.7",
      "Div": "1.26"
  },
  {
      "Date": "3-Jan-20",
      "PE": "28.44",
      "PB": "3.77",
      "Div": "1.23"
  },
  {
      "Date": "2-Jan-20",
      "PE": "28.56",
      "PB": "3.79",
      "Div": "1.23"
  },
  {
      "Date": "1-Jan-20",
      "PE": "28.33",
      "PB": "3.76",
      "Div": "1.24"
  },
  {
      "Date": "31-Dec-19",
      "PE": "28.3",
      "PB": "3.75",
      "Div": "1.24"
  },
  {
      "Date": "30-Dec-19",
      "PE": "28.5",
      "PB": "3.78",
      "Div": "1.23"
  },
  {
      "Date": "27-Dec-19",
      "PE": "28.48",
      "PB": "3.78",
      "Div": "1.23"
  },
  {
      "Date": "26-Dec-19",
      "PE": "28.26",
      "PB": "3.74",
      "Div": "1.23"
  },
  {
      "Date": "24-Dec-19",
      "PE": "28.47",
      "PB": "3.77",
      "Div": "1.22"
  },
  {
      "Date": "23-Dec-19",
      "PE": "28.58",
      "PB": "3.79",
      "Div": "1.22"
  },
  {
      "Date": "20-Dec-19",
      "PE": "28.6",
      "PB": "3.79",
      "Div": "1.22"
  },
  {
      "Date": "19-Dec-19",
      "PE": "28.57",
      "PB": "3.79",
      "Div": "1.22"
  },
  {
      "Date": "18-Dec-19",
      "PE": "28.48",
      "PB": "3.77",
      "Div": "1.22"
  },
  {
      "Date": "17-Dec-19",
      "PE": "28.35",
      "PB": "3.76",
      "Div": "1.23"
  },
  {
      "Date": "16-Dec-19",
      "PE": "28.09",
      "PB": "3.72",
      "Div": "1.24"
  },
  {
      "Date": "13-Dec-19",
      "PE": "28.17",
      "PB": "3.73",
      "Div": "1.24"
  },
  {
      "Date": "12-Dec-19",
      "PE": "27.9",
      "PB": "3.7",
      "Div": "1.25"
  },
  {
      "Date": "11-Dec-19",
      "PE": "27.76",
      "PB": "3.68",
      "Div": "1.25"
  },
  {
      "Date": "10-Dec-19",
      "PE": "27.63",
      "PB": "3.66",
      "Div": "1.26"
  },
  {
      "Date": "9-Dec-19",
      "PE": "27.82",
      "PB": "3.69",
      "Div": "1.25"
  },
  {
      "Date": "6-Dec-19",
      "PE": "27.78",
      "PB": "3.68",
      "Div": "1.25"
  },
  {
      "Date": "5-Dec-19",
      "PE": "28.01",
      "PB": "3.71",
      "Div": "1.24"
  },
  {
      "Date": "4-Dec-19",
      "PE": "28.07",
      "PB": "3.72",
      "Div": "1.24"
  },
  {
      "Date": "3-Dec-19",
      "PE": "27.95",
      "PB": "3.7",
      "Div": "1.25"
  },
  {
      "Date": "2-Dec-19",
      "PE": "28.08",
      "PB": "3.72",
      "Div": "1.24"
  },
  {
      "Date": "29-Nov-19",
      "PE": "28.1",
      "PB": "3.72",
      "Div": "1.24"
  },
  {
      "Date": "28-Nov-19",
      "PE": "28.32",
      "PB": "3.75",
      "Div": "1.23"
  },
  {
      "Date": "27-Nov-19",
      "PE": "28.2",
      "PB": "3.74",
      "Div": "1.23"
  },
  {
      "Date": "26-Nov-19",
      "PE": "28.05",
      "PB": "3.72",
      "Div": "1.24"
  },
  {
      "Date": "25-Nov-19",
      "PE": "28.14",
      "PB": "3.73",
      "Div": "1.24"
  },
  {
      "Date": "22-Nov-19",
      "PE": "27.77",
      "PB": "3.68",
      "Div": "1.25"
  },
  {
      "Date": "21-Nov-19",
      "PE": "27.89",
      "PB": "3.7",
      "Div": "1.25"
  },
  {
      "Date": "20-Nov-19",
      "PE": "27.23",
      "PB": "3.71",
      "Div": "1.24"
  },
  {
      "Date": "19-Nov-19",
      "PE": "27.31",
      "PB": "3.69",
      "Div": "1.25"
  },
  {
      "Date": "18-Nov-19",
      "PE": "27.29",
      "PB": "3.67",
      "Div": "1.26"
  },
  {
      "Date": "15-Nov-19",
      "PE": "27.32",
      "PB": "3.67",
      "Div": "1.26"
  },
  {
      "Date": "14-Nov-19",
      "PE": "27.38",
      "PB": "3.67",
      "Div": "1.26"
  },
  {
      "Date": "13-Nov-19",
      "PE": "27.31",
      "PB": "3.66",
      "Div": "1.26"
  },
  {
      "Date": "11-Nov-19",
      "PE": "27.52",
      "PB": "3.68",
      "Div": "1.25"
  },
  {
      "Date": "8-Nov-19",
      "PE": "27.51",
      "PB": "3.68",
      "Div": "1.25"
  },
  {
      "Date": "7-Nov-19",
      "PE": "27.75",
      "PB": "3.71",
      "Div": "1.24"
  },
  {
      "Date": "6-Nov-19",
      "PE": "27.71",
      "PB": "3.7",
      "Div": "1.25"
  },
  {
      "Date": "5-Nov-19",
      "PE": "27.59",
      "PB": "3.68",
      "Div": "1.25"
  },
  {
      "Date": "4-Nov-19",
      "PE": "27.59",
      "PB": "3.69",
      "Div": "1.25"
  },
  {
      "Date": "1-Nov-19",
      "PE": "27.47",
      "PB": "3.67",
      "Div": "1.26"
  },
  {
      "Date": "31-Oct-19",
      "PE": "27.38",
      "PB": "3.67",
      "Div": "1.26"
  },
  {
      "Date": "30-Oct-19",
      "PE": "27.3",
      "PB": "3.66",
      "Div": "1.26"
  },
  {
      "Date": "29-Oct-19",
      "PE": "27.17",
      "PB": "3.64",
      "Div": "1.27"
  },
  {
      "Date": "27-Oct-19",
      "PE": "26.8",
      "PB": "3.59",
      "Div": "1.29"
  },
  {
      "Date": "25-Oct-19",
      "PE": "26.7",
      "PB": "3.58",
      "Div": "1.29"
  },
  {
      "Date": "24-Oct-19",
      "PE": "26.7",
      "PB": "3.58",
      "Div": "1.29"
  },
  {
      "Date": "23-Oct-19",
      "PE": "26.75",
      "PB": "3.58",
      "Div": "1.29"
  },
  {
      "Date": "22-Oct-19",
      "PE": "26.71",
      "PB": "3.58",
      "Div": "1.29"
  },
  {
      "Date": "18-Oct-19",
      "PE": "26.94",
      "PB": "3.6",
      "Div": "1.28"
  },
  {
      "Date": "17-Oct-19",
      "PE": "26.76",
      "PB": "3.58",
      "Div": "1.29"
  },
  {
      "Date": "16-Oct-19",
      "PE": "26.48",
      "PB": "3.54",
      "Div": "1.3"
  },
  {
      "Date": "15-Oct-19",
      "PE": "26.4",
      "PB": "3.53",
      "Div": "1.31"
  },
  {
      "Date": "14-Oct-19",
      "PE": "26.2",
      "PB": "3.5",
      "Div": "1.32"
  },
  {
      "Date": "11-Oct-19",
      "PE": "26.11",
      "PB": "3.49",
      "Div": "1.32"
  },
  {
      "Date": "10-Oct-19",
      "PE": "25.95",
      "PB": "3.47",
      "Div": "1.33"
  },
  {
      "Date": "9-Oct-19",
      "PE": "26.13",
      "PB": "3.49",
      "Div": "1.32"
  },
  {
      "Date": "7-Oct-19",
      "PE": "25.64",
      "PB": "3.43",
      "Div": "1.35"
  },
  {
      "Date": "4-Oct-19",
      "PE": "25.75",
      "PB": "3.44",
      "Div": "1.34"
  },
  {
      "Date": "3-Oct-19",
      "PE": "26.07",
      "PB": "3.48",
      "Div": "1.32"
  },
  {
      "Date": "1-Oct-19",
      "PE": "26.17",
      "PB": "3.5",
      "Div": "1.32"
  },
  {
      "Date": "30-Sep-19",
      "PE": "26.44",
      "PB": "3.53",
      "Div": "1.31"
  },
  {
      "Date": "27-Sep-19",
      "PE": "26.52",
      "PB": "3.55",
      "Div": "1.3"
  },
  {
      "Date": "26-Sep-19",
      "PE": "26.22",
      "PB": "3.51",
      "Div": "1.33"
  },
  {
      "Date": "25-Sep-19",
      "PE": "25.92",
      "PB": "3.47",
      "Div": "1.34"
  },
  {
      "Date": "24-Sep-19",
      "PE": "28.49",
      "PB": "3.52",
      "Div": "1.32"
  },
  {
      "Date": "23-Sep-19",
      "PE": "28.52",
      "PB": "3.52",
      "Div": "1.32"
  },
  {
      "Date": "20-Sep-19",
      "PE": "27.72",
      "PB": "3.42",
      "Div": "1.36"
  },
  {
      "Date": "19-Sep-19",
      "PE": "26.32",
      "PB": "3.25",
      "Div": "1.43"
  },
  {
      "Date": "18-Sep-19",
      "PE": "26.66",
      "PB": "3.29",
      "Div": "1.42"
  },
  {
      "Date": "17-Sep-19",
      "PE": "26.6",
      "PB": "3.29",
      "Div": "1.42"
  },
  {
      "Date": "16-Sep-19",
      "PE": "27.06",
      "PB": "3.34",
      "Div": "1.39"
  },
  {
      "Date": "13-Sep-19",
      "PE": "27.23",
      "PB": "3.36",
      "Div": "1.39"
  },
  {
      "Date": "12-Sep-19",
      "PE": "27",
      "PB": "3.34",
      "Div": "1.4"
  },
  {
      "Date": "11-Sep-19",
      "PE": "27.13",
      "PB": "3.35",
      "Div": "1.39"
  },
  {
      "Date": "9-Sep-19",
      "PE": "27.05",
      "PB": "3.34",
      "Div": "1.39"
  },
  {
      "Date": "6-Sep-19",
      "PE": "26.91",
      "PB": "3.32",
      "Div": "1.4"
  },
  {
      "Date": "5-Sep-19",
      "PE": "26.67",
      "PB": "3.29",
      "Div": "1.41"
  },
  {
      "Date": "4-Sep-19",
      "PE": "26.61",
      "PB": "3.29",
      "Div": "1.42"
  },
  {
      "Date": "3-Sep-19",
      "PE": "26.54",
      "PB": "3.28",
      "Div": "1.42"
  },
  {
      "Date": "30-Aug-19",
      "PE": "27.27",
      "PB": "3.35",
      "Div": "1.39"
  },
  {
      "Date": "29-Aug-19",
      "PE": "27.09",
      "PB": "3.4",
      "Div": "1.35"
  },
  {
      "Date": "28-Aug-19",
      "PE": "27.33",
      "PB": "3.43",
      "Div": "1.34"
  },
  {
      "Date": "27-Aug-19",
      "PE": "27.47",
      "PB": "3.45",
      "Div": "1.33"
  },
  {
      "Date": "26-Aug-19",
      "PE": "27.36",
      "PB": "3.43",
      "Div": "1.34"
  },
  {
      "Date": "23-Aug-19",
      "PE": "26.79",
      "PB": "3.36",
      "Div": "1.36"
  },
  {
      "Date": "22-Aug-19",
      "PE": "26.57",
      "PB": "3.34",
      "Div": "1.38"
  },
  {
      "Date": "21-Aug-19",
      "PE": "27.01",
      "PB": "3.39",
      "Div": "1.35"
  },
  {
      "Date": "20-Aug-19",
      "PE": "27.26",
      "PB": "3.42",
      "Div": "1.34"
  },
  {
      "Date": "19-Aug-19",
      "PE": "27.35",
      "PB": "3.43",
      "Div": "1.34"
  },
  {
      "Date": "16-Aug-19",
      "PE": "27.33",
      "PB": "3.43",
      "Div": "1.34"
  },
  {
      "Date": "14-Aug-19",
      "PE": "27.24",
      "PB": "3.43",
      "Div": "1.34"
  },
  {
      "Date": "13-Aug-19",
      "PE": "27.03",
      "PB": "3.39",
      "Div": "1.35"
  },
  {
      "Date": "9-Aug-19",
      "PE": "27.41",
      "PB": "3.45",
      "Div": "1.33"
  },
  {
      "Date": "8-Aug-19",
      "PE": "27.2",
      "PB": "3.43",
      "Div": "1.34"
  },
  {
      "Date": "7-Aug-19",
      "PE": "26.74",
      "PB": "3.37",
      "Div": "1.36"
  },
  {
      "Date": "6-Aug-19",
      "PE": "26.97",
      "PB": "3.4",
      "Div": "1.35"
  },
  {
      "Date": "5-Aug-19",
      "PE": "26.76",
      "PB": "3.37",
      "Div": "1.36"
  },
  {
      "Date": "2-Aug-19",
      "PE": "27.09",
      "PB": "3.42",
      "Div": "1.34"
  },
  {
      "Date": "1-Aug-19",
      "PE": "27.05",
      "PB": "3.41",
      "Div": "1.35"
  },
  {
      "Date": "31-Jul-19",
      "PE": "27.42",
      "PB": "3.45",
      "Div": "1.33"
  },
  {
      "Date": "30-Jul-19",
      "PE": "27.34",
      "PB": "3.47",
      "Div": "1.34"
  },
  {
      "Date": "29-Jul-19",
      "PE": "27.6",
      "PB": "3.5",
      "Div": "1.33"
  },
  {
      "Date": "26-Jul-19",
      "PE": "27.73",
      "PB": "3.53",
      "Div": "1.32"
  },
  {
      "Date": "25-Jul-19",
      "PE": "27.57",
      "PB": "3.52",
      "Div": "1.32"
  },
  {
      "Date": "24-Jul-19",
      "PE": "27.62",
      "PB": "3.53",
      "Div": "1.32"
  },
  {
      "Date": "23-Jul-19",
      "PE": "27.77",
      "PB": "3.55",
      "Div": "1.31"
  },
  {
      "Date": "22-Jul-19",
      "PE": "27.81",
      "PB": "3.55",
      "Div": "1.31"
  },
  {
      "Date": "19-Jul-19",
      "PE": "27.92",
      "PB": "3.57",
      "Div": "1.31"
  },
  {
      "Date": "18-Jul-19",
      "PE": "28.35",
      "PB": "3.62",
      "Div": "1.29"
  },
  {
      "Date": "17-Jul-19",
      "PE": "28.58",
      "PB": "3.65",
      "Div": "1.28"
  },
  {
      "Date": "16-Jul-19",
      "PE": "28.51",
      "PB": "3.64",
      "Div": "1.28"
  },
  {
      "Date": "15-Jul-19",
      "PE": "28.33",
      "PB": "3.62",
      "Div": "1.29"
  },
  {
      "Date": "12-Jul-19",
      "PE": "28.33",
      "PB": "3.61",
      "Div": "1.29"
  },
  {
      "Date": "11-Jul-19",
      "PE": "28.4",
      "PB": "3.64",
      "Div": "1.26"
  },
  {
      "Date": "10-Jul-19",
      "PE": "28.2",
      "PB": "3.62",
      "Div": "1.27"
  },
  {
      "Date": "9-Jul-19",
      "PE": "28.41",
      "PB": "3.64",
      "Div": "1.27"
  },
  {
      "Date": "8-Jul-19",
      "PE": "28.42",
      "PB": "3.64",
      "Div": "1.27"
  },
  {
      "Date": "5-Jul-19",
      "PE": "29.04",
      "PB": "3.72",
      "Div": "1.24"
  },
  {
      "Date": "4-Jul-19",
      "PE": "29.37",
      "PB": "3.76",
      "Div": "1.23"
  },
  {
      "Date": "3-Jul-19",
      "PE": "29.3",
      "PB": "3.75",
      "Div": "1.23"
  },
  {
      "Date": "2-Jul-19",
      "PE": "29.28",
      "PB": "3.75",
      "Div": "1.23"
  },
  {
      "Date": "1-Jul-19",
      "PE": "29.17",
      "PB": "3.73",
      "Div": "1.23"
  },
  {
      "Date": "28-Jun-19",
      "PE": "28.98",
      "PB": "3.71",
      "Div": "1.24"
  },
  {
      "Date": "27-Jun-19",
      "PE": "29.07",
      "PB": "3.78",
      "Div": "1.23"
  },
  {
      "Date": "26-Jun-19",
      "PE": "29.09",
      "PB": "3.78",
      "Div": "1.23"
  },
  {
      "Date": "25-Jun-19",
      "PE": "29.17",
      "PB": "3.77",
      "Div": "1.24"
  },
  {
      "Date": "24-Jun-19",
      "PE": "28.93",
      "PB": "3.74",
      "Div": "1.25"
  },
  {
      "Date": "21-Jun-19",
      "PE": "28.99",
      "PB": "3.74",
      "Div": "1.24"
  },
  {
      "Date": "20-Jun-19",
      "PE": "29.26",
      "PB": "3.78",
      "Div": "1.23"
  },
  {
      "Date": "19-Jun-19",
      "PE": "28.91",
      "PB": "3.73",
      "Div": "1.25"
  },
  {
      "Date": "18-Jun-19",
      "PE": "28.91",
      "PB": "3.73",
      "Div": "1.25"
  },
  {
      "Date": "17-Jun-19",
      "PE": "28.87",
      "PB": "3.73",
      "Div": "1.25"
  },
  {
      "Date": "14-Jun-19",
      "PE": "29.24",
      "PB": "3.77",
      "Div": "1.23"
  },
  {
      "Date": "13-Jun-19",
      "PE": "29.46",
      "PB": "3.81",
      "Div": "1.22"
  },
  {
      "Date": "12-Jun-19",
      "PE": "29.45",
      "PB": "3.81",
      "Div": "1.22"
  },
  {
      "Date": "11-Jun-19",
      "PE": "29.59",
      "PB": "3.82",
      "Div": "1.22"
  },
  {
      "Date": "10-Jun-19",
      "PE": "29.49",
      "PB": "3.81",
      "Div": "1.22"
  },
  {
      "Date": "7-Jun-19",
      "PE": "29.36",
      "PB": "3.79",
      "Div": "1.23"
  },
  {
      "Date": "6-Jun-19",
      "PE": "29.29",
      "PB": "3.79",
      "Div": "1.23"
  },
  {
      "Date": "4-Jun-19",
      "PE": "29.73",
      "PB": "3.84",
      "Div": "1.21"
  },
  {
      "Date": "3-Jun-19",
      "PE": "29.9",
      "PB": "3.86",
      "Div": "1.2"
  },
  {
      "Date": "31-May-19",
      "PE": "29.49",
      "PB": "3.81",
      "Div": "1.22"
  },
  {
      "Date": "30-May-19",
      "PE": "29.65",
      "PB": "3.82",
      "Div": "1.22"
  },
  {
      "Date": "29-May-19",
      "PE": "29.44",
      "PB": "3.79",
      "Div": "1.23"
  },
  {
      "Date": "28-May-19",
      "PE": "29.69",
      "PB": "3.81",
      "Div": "1.22"
  },
  {
      "Date": "27-May-19",
      "PE": "29.68",
      "PB": "3.81",
      "Div": "1.22"
  },
  {
      "Date": "24-May-19",
      "PE": "29.44",
      "PB": "3.79",
      "Div": "1.11"
  },
  {
      "Date": "23-May-19",
      "PE": "29.02",
      "PB": "3.73",
      "Div": "1.13"
  },
  {
      "Date": "22-May-19",
      "PE": "29.22",
      "PB": "3.75",
      "Div": "1.12"
  },
  {
      "Date": "21-May-19",
      "PE": "29.18",
      "PB": "3.74",
      "Div": "1.12"
  },
  {
      "Date": "20-May-19",
      "PE": "29.48",
      "PB": "3.78",
      "Div": "1.11"
  },
  {
      "Date": "17-May-19",
      "PE": "28.44",
      "PB": "3.65",
      "Div": "1.15"
  },
  {
      "Date": "16-May-19",
      "PE": "28.07",
      "PB": "3.6",
      "Div": "1.17"
  },
  {
      "Date": "15-May-19",
      "PE": "27.82",
      "PB": "3.57",
      "Div": "1.18"
  },
  {
      "Date": "14-May-19",
      "PE": "27.98",
      "PB": "3.59",
      "Div": "1.17"
  },
  {
      "Date": "13-May-19",
      "PE": "27.8",
      "PB": "3.56",
      "Div": "1.18"
  },
  {
      "Date": "10-May-19",
      "PE": "28.14",
      "PB": "3.61",
      "Div": "1.17"
  },
  {
      "Date": "9-May-19",
      "PE": "28.24",
      "PB": "3.61",
      "Div": "1.16"
  },
  {
      "Date": "8-May-19",
      "PE": "28.38",
      "PB": "3.63",
      "Div": "1.16"
  },
  {
      "Date": "7-May-19",
      "PE": "28.73",
      "PB": "3.68",
      "Div": "1.14"
  },
  {
      "Date": "6-May-19",
      "PE": "28.95",
      "PB": "3.71",
      "Div": "1.13"
  },
  {
      "Date": "3-May-19",
      "PE": "29.24",
      "PB": "3.74",
      "Div": "1.12"
  },
  {
      "Date": "2-May-19",
      "PE": "29.27",
      "PB": "3.75",
      "Div": "1.12"
  },
  {
      "Date": "30-Apr-19",
      "PE": "29.33",
      "PB": "3.76",
      "Div": "1.12"
  },
  {
      "Date": "26-Apr-19",
      "PE": "29.34",
      "PB": "3.76",
      "Div": "1.12"
  },
  {
      "Date": "25-Apr-19",
      "PE": "29.06",
      "PB": "3.72",
      "Div": "1.13"
  },
  {
      "Date": "24-Apr-19",
      "PE": "29.27",
      "PB": "3.75",
      "Div": "1.12"
  },
  {
      "Date": "23-Apr-19",
      "PE": "28.94",
      "PB": "3.7",
      "Div": "1.14"
  },
  {
      "Date": "22-Apr-19",
      "PE": "28.94",
      "PB": "3.7",
      "Div": "1.14"
  },
  {
      "Date": "18-Apr-19",
      "PE": "29.33",
      "PB": "3.75",
      "Div": "1.12"
  },
  {
      "Date": "16-Apr-19",
      "PE": "29.42",
      "PB": "3.76",
      "Div": "1.12"
  },
  {
      "Date": "15-Apr-19",
      "PE": "29.18",
      "PB": "3.73",
      "Div": "1.13"
  },
  {
      "Date": "12-Apr-19",
      "PE": "29.06",
      "PB": "3.72",
      "Div": "1.13"
  },
  {
      "Date": "11-Apr-19",
      "PE": "28.94",
      "PB": "3.7",
      "Div": "1.14"
  },
  {
      "Date": "10-Apr-19",
      "PE": "28.91",
      "PB": "3.7",
      "Div": "1.14"
  },
  {
      "Date": "9-Apr-19",
      "PE": "29.13",
      "PB": "3.72",
      "Div": "1.13"
  },
  {
      "Date": "8-Apr-19",
      "PE": "28.96",
      "PB": "3.7",
      "Div": "1.14"
  },
  {
      "Date": "5-Apr-19",
      "PE": "29.12",
      "PB": "3.72",
      "Div": "1.13"
  },
  {
      "Date": "4-Apr-19",
      "PE": "28.95",
      "PB": "3.7",
      "Div": "1.14"
  },
  {
      "Date": "3-Apr-19",
      "PE": "29.06",
      "PB": "3.72",
      "Div": "1.13"
  },
  {
      "Date": "2-Apr-19",
      "PE": "29.24",
      "PB": "3.74",
      "Div": "1.12"
  },
  {
      "Date": "1-Apr-19",
      "PE": "29.13",
      "PB": "3.72",
      "Div": "1.13"
  },
  {
      "Date": "29-Mar-19",
      "PE": "29.01",
      "PB": "3.71",
      "Div": "1.13"
  },
  {
      "Date": "28-Mar-19",
      "PE": "28.36",
      "PB": "3.65",
      "Div": "1.17"
  },
  {
      "Date": "27-Mar-19",
      "PE": "28.05",
      "PB": "3.61",
      "Div": "1.18"
  },
  {
      "Date": "26-Mar-19",
      "PE": "28.15",
      "PB": "3.62",
      "Div": "1.18"
  },
  {
      "Date": "25-Mar-19",
      "PE": "27.83",
      "PB": "3.58",
      "Div": "1.19"
  },
  {
      "Date": "22-Mar-19",
      "PE": "28.08",
      "PB": "3.62",
      "Div": "1.18"
  },
  {
      "Date": "20-Mar-19",
      "PE": "28.24",
      "PB": "3.64",
      "Div": "1.17"
  },
  {
      "Date": "19-Mar-19",
      "PE": "28.27",
      "PB": "3.64",
      "Div": "1.17"
  },
  {
      "Date": "18-Mar-19",
      "PE": "28.1",
      "PB": "3.62",
      "Div": "1.18"
  },
  {
      "Date": "15-Mar-19",
      "PE": "28.01",
      "PB": "3.61",
      "Div": "1.18"
  },
  {
      "Date": "14-Mar-19",
      "PE": "27.81",
      "PB": "3.58",
      "Div": "1.19"
  },
  {
      "Date": "13-Mar-19",
      "PE": "27.8",
      "PB": "3.58",
      "Div": "1.19"
  },
  {
      "Date": "12-Mar-19",
      "PE": "27.7",
      "PB": "3.57",
      "Div": "1.19"
  },
  {
      "Date": "11-Mar-19",
      "PE": "27.38",
      "PB": "3.52",
      "Div": "1.21"
  },
  {
      "Date": "8-Mar-19",
      "PE": "27.05",
      "PB": "3.48",
      "Div": "1.22"
  },
  {
      "Date": "7-Mar-19",
      "PE": "27.11",
      "PB": "3.49",
      "Div": "1.22"
  },
  {
      "Date": "6-Mar-19",
      "PE": "27.09",
      "PB": "3.49",
      "Div": "1.22"
  },
  {
      "Date": "5-Mar-19",
      "PE": "26.93",
      "PB": "3.47",
      "Div": "1.23"
  },
  {
      "Date": "1-Mar-19",
      "PE": "26.49",
      "PB": "3.43",
      "Div": "1.24"
  },
  {
      "Date": "28-Feb-19",
      "PE": "26.32",
      "PB": "3.41",
      "Div": "1.25"
  },
  {
      "Date": "27-Feb-19",
      "PE": "26.36",
      "PB": "3.41",
      "Div": "1.25"
  },
  {
      "Date": "26-Feb-19",
      "PE": "26.43",
      "PB": "3.42",
      "Div": "1.25"
  },
  {
      "Date": "25-Feb-19",
      "PE": "26.53",
      "PB": "3.43",
      "Div": "1.24"
  },
  {
      "Date": "22-Feb-19",
      "PE": "26.32",
      "PB": "3.41",
      "Div": "1.25"
  },
  {
      "Date": "21-Feb-19",
      "PE": "26.31",
      "PB": "3.36",
      "Div": "1.25"
  },
  {
      "Date": "20-Feb-19",
      "PE": "26.5",
      "PB": "3.34",
      "Div": "1.26"
  },
  {
      "Date": "19-Feb-19",
      "PE": "26.23",
      "PB": "3.3",
      "Div": "1.27"
  },
  {
      "Date": "18-Feb-19",
      "PE": "26.32",
      "PB": "3.31",
      "Div": "1.27"
  },
  {
      "Date": "15-Feb-19",
      "PE": "26.53",
      "PB": "3.34",
      "Div": "1.26"
  },
  {
      "Date": "14-Feb-19",
      "PE": "26.58",
      "PB": "3.35",
      "Div": "1.26"
  },
  {
      "Date": "13-Feb-19",
      "PE": "26.6",
      "PB": "3.36",
      "Div": "1.25"
  },
  {
      "Date": "12-Feb-19",
      "PE": "26.84",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "11-Feb-19",
      "PE": "26.96",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "8-Feb-19",
      "PE": "27.1",
      "PB": "3.41",
      "Div": "1.23"
  },
  {
      "Date": "7-Feb-19",
      "PE": "27.41",
      "PB": "3.45",
      "Div": "1.22"
  },
  {
      "Date": "6-Feb-19",
      "PE": "27.39",
      "PB": "3.45",
      "Div": "1.22"
  },
  {
      "Date": "5-Feb-19",
      "PE": "27.07",
      "PB": "3.41",
      "Div": "1.23"
  },
  {
      "Date": "4-Feb-19",
      "PE": "27.02",
      "PB": "3.4",
      "Div": "1.24"
  },
  {
      "Date": "1-Feb-19",
      "PE": "26.4",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "31-Jan-19",
      "PE": "26.26",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "30-Jan-19",
      "PE": "25.84",
      "PB": "3.32",
      "Div": "1.27"
  },
  {
      "Date": "29-Jan-19",
      "PE": "25.84",
      "PB": "3.32",
      "Div": "1.27"
  },
  {
      "Date": "28-Jan-19",
      "PE": "25.85",
      "PB": "3.32",
      "Div": "1.27"
  },
  {
      "Date": "25-Jan-19",
      "PE": "26.14",
      "PB": "3.36",
      "Div": "1.25"
  },
  {
      "Date": "24-Jan-19",
      "PE": "26.31",
      "PB": "3.38",
      "Div": "1.24"
  },
  {
      "Date": "23-Jan-19",
      "PE": "26.32",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "22-Jan-19",
      "PE": "26.54",
      "PB": "3.4",
      "Div": "1.24"
  },
  {
      "Date": "21-Jan-19",
      "PE": "26.29",
      "PB": "3.41",
      "Div": "1.23"
  },
  {
      "Date": "18-Jan-19",
      "PE": "26.19",
      "PB": "3.4",
      "Div": "1.24"
  },
  {
      "Date": "17-Jan-19",
      "PE": "26.21",
      "PB": "3.4",
      "Div": "1.24"
  },
  {
      "Date": "16-Jan-19",
      "PE": "26.17",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "15-Jan-19",
      "PE": "26.16",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "14-Jan-19",
      "PE": "25.8",
      "PB": "3.34",
      "Div": "1.26"
  },
  {
      "Date": "11-Jan-19",
      "PE": "26",
      "PB": "3.36",
      "Div": "1.25"
  },
  {
      "Date": "10-Jan-19",
      "PE": "26.07",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "9-Jan-19",
      "PE": "26.15",
      "PB": "3.38",
      "Div": "1.24"
  },
  {
      "Date": "8-Jan-19",
      "PE": "26.02",
      "PB": "3.36",
      "Div": "1.25"
  },
  {
      "Date": "7-Jan-19",
      "PE": "25.95",
      "PB": "3.36",
      "Div": "1.25"
  },
  {
      "Date": "4-Jan-19",
      "PE": "25.84",
      "PB": "3.34",
      "Div": "1.26"
  },
  {
      "Date": "3-Jan-19",
      "PE": "25.71",
      "PB": "3.32",
      "Div": "1.26"
  },
  {
      "Date": "2-Jan-19",
      "PE": "26",
      "PB": "3.36",
      "Div": "1.25"
  },
  {
      "Date": "1-Jan-19",
      "PE": "26.28",
      "PB": "3.4",
      "Div": "1.24"
  },
  {
      "Date": "31-Dec-18",
      "PE": "26.17",
      "PB": "3.38",
      "Div": "1.24"
  },
  {
      "Date": "28-Dec-18",
      "PE": "26.16",
      "PB": "3.43",
      "Div": "1.24"
  },
  {
      "Date": "27-Dec-18",
      "PE": "26.08",
      "PB": "3.41",
      "Div": "1.23"
  },
  {
      "Date": "26-Dec-18",
      "PE": "25.96",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "24-Dec-18",
      "PE": "25.8",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "21-Dec-18",
      "PE": "26.02",
      "PB": "3.4",
      "Div": "1.24"
  },
  {
      "Date": "20-Dec-18",
      "PE": "26.5",
      "PB": "3.46",
      "Div": "1.21"
  },
  {
      "Date": "19-Dec-18",
      "PE": "26.53",
      "PB": "3.47",
      "Div": "1.21"
  },
  {
      "Date": "18-Dec-18",
      "PE": "26.39",
      "PB": "3.45",
      "Div": "1.22"
  },
  {
      "Date": "17-Dec-18",
      "PE": "26.34",
      "PB": "3.44",
      "Div": "1.22"
  },
  {
      "Date": "14-Dec-18",
      "PE": "26.14",
      "PB": "3.42",
      "Div": "1.23"
  },
  {
      "Date": "13-Dec-18",
      "PE": "26.11",
      "PB": "3.41",
      "Div": "1.23"
  },
  {
      "Date": "12-Dec-18",
      "PE": "25.98",
      "PB": "3.4",
      "Div": "1.24"
  },
  {
      "Date": "11-Dec-18",
      "PE": "25.52",
      "PB": "3.34",
      "Div": "1.26"
  },
  {
      "Date": "10-Dec-18",
      "PE": "25.37",
      "PB": "3.32",
      "Div": "1.27"
  },
  {
      "Date": "7-Dec-18",
      "PE": "25.87",
      "PB": "3.38",
      "Div": "1.24"
  },
  {
      "Date": "6-Dec-18",
      "PE": "25.65",
      "PB": "3.35",
      "Div": "1.25"
  },
  {
      "Date": "5-Dec-18",
      "PE": "26.09",
      "PB": "3.41",
      "Div": "1.23"
  },
  {
      "Date": "4-Dec-18",
      "PE": "26.3",
      "PB": "3.44",
      "Div": "1.22"
  },
  {
      "Date": "3-Dec-18",
      "PE": "26.33",
      "PB": "3.44",
      "Div": "1.22"
  },
  {
      "Date": "30-Nov-18",
      "PE": "26.31",
      "PB": "3.44",
      "Div": "1.22"
  },
  {
      "Date": "29-Nov-18",
      "PE": "26.27",
      "PB": "3.43",
      "Div": "1.22"
  },
  {
      "Date": "28-Nov-18",
      "PE": "25.96",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "27-Nov-18",
      "PE": "25.85",
      "PB": "3.38",
      "Div": "1.24"
  },
  {
      "Date": "26-Nov-18",
      "PE": "25.71",
      "PB": "3.36",
      "Div": "1.25"
  },
  {
      "Date": "22-Nov-18",
      "PE": "25.43",
      "PB": "3.33",
      "Div": "1.26"
  },
  {
      "Date": "21-Nov-18",
      "PE": "25.55",
      "PB": "3.35",
      "Div": "1.25"
  },
  {
      "Date": "20-Nov-18",
      "PE": "25.62",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "19-Nov-18",
      "PE": "25.88",
      "PB": "3.41",
      "Div": "1.23"
  },
  {
      "Date": "16-Nov-18",
      "PE": "25.69",
      "PB": "3.38",
      "Div": "1.24"
  },
  {
      "Date": "15-Nov-18",
      "PE": "25.53",
      "PB": "3.36",
      "Div": "1.25"
  },
  {
      "Date": "14-Nov-18",
      "PE": "25.43",
      "PB": "3.35",
      "Div": "1.25"
  },
  {
      "Date": "13-Nov-18",
      "PE": "25.45",
      "PB": "3.35",
      "Div": "1.25"
  },
  {
      "Date": "12-Nov-18",
      "PE": "25.21",
      "PB": "3.32",
      "Div": "1.26"
  },
  {
      "Date": "9-Nov-18",
      "PE": "25.46",
      "PB": "3.35",
      "Div": "1.25"
  },
  {
      "Date": "7-Nov-18",
      "PE": "25.49",
      "PB": "3.36",
      "Div": "1.25"
  },
  {
      "Date": "6-Nov-18",
      "PE": "25.32",
      "PB": "3.34",
      "Div": "1.26"
  },
  {
      "Date": "5-Nov-18",
      "PE": "25.33",
      "PB": "3.34",
      "Div": "1.26"
  },
  {
      "Date": "2-Nov-18",
      "PE": "25.4",
      "PB": "3.35",
      "Div": "1.25"
  },
  {
      "Date": "1-Nov-18",
      "PE": "24.98",
      "PB": "3.29",
      "Div": "1.27"
  },
  {
      "Date": "31-Oct-18",
      "PE": "25",
      "PB": "3.29",
      "Div": "1.27"
  },
  {
      "Date": "30-Oct-18",
      "PE": "24.55",
      "PB": "3.24",
      "Div": "1.32"
  },
  {
      "Date": "29-Oct-18",
      "PE": "24.65",
      "PB": "3.26",
      "Div": "1.31"
  },
  {
      "Date": "26-Oct-18",
      "PE": "24.12",
      "PB": "3.19",
      "Div": "1.34"
  },
  {
      "Date": "25-Oct-18",
      "PE": "24.36",
      "PB": "3.22",
      "Div": "1.33"
  },
  {
      "Date": "24-Oct-18",
      "PE": "24.6",
      "PB": "3.25",
      "Div": "1.31"
  },
  {
      "Date": "23-Oct-18",
      "PE": "24.41",
      "PB": "3.22",
      "Div": "1.32"
  },
  {
      "Date": "22-Oct-18",
      "PE": "24.65",
      "PB": "3.26",
      "Div": "1.31"
  },
  {
      "Date": "19-Oct-18",
      "PE": "24.83",
      "PB": "3.27",
      "Div": "1.3"
  },
  {
      "Date": "17-Oct-18",
      "PE": "25.19",
      "PB": "3.32",
      "Div": "1.29"
  },
  {
      "Date": "16-Oct-18",
      "PE": "25.52",
      "PB": "3.36",
      "Div": "1.27"
  },
  {
      "Date": "15-Oct-18",
      "PE": "25.42",
      "PB": "3.34",
      "Div": "1.28"
  },
  {
      "Date": "12-Oct-18",
      "PE": "25.33",
      "PB": "3.33",
      "Div": "1.28"
  },
  {
      "Date": "11-Oct-18",
      "PE": "24.75",
      "PB": "3.25",
      "Div": "1.31"
  },
  {
      "Date": "10-Oct-18",
      "PE": "25.3",
      "PB": "3.32",
      "Div": "1.28"
  },
  {
      "Date": "9-Oct-18",
      "PE": "24.91",
      "PB": "3.27",
      "Div": "1.3"
  },
  {
      "Date": "8-Oct-18",
      "PE": "25.03",
      "PB": "3.29",
      "Div": "1.3"
  },
  {
      "Date": "5-Oct-18",
      "PE": "24.95",
      "PB": "3.28",
      "Div": "1.3"
  },
  {
      "Date": "4-Oct-18",
      "PE": "25.63",
      "PB": "3.37",
      "Div": "1.27"
  },
  {
      "Date": "3-Oct-18",
      "PE": "26.26",
      "PB": "3.45",
      "Div": "1.24"
  },
  {
      "Date": "1-Oct-18",
      "PE": "26.62",
      "PB": "3.5",
      "Div": "1.22"
  },
  {
      "Date": "28-Sep-18",
      "PE": "26.44",
      "PB": "3.47",
      "Div": "1.23"
  },
  {
      "Date": "27-Sep-18",
      "PE": "26.69",
      "PB": "3.47",
      "Div": "1.23"
  },
  {
      "Date": "26-Sep-18",
      "PE": "26.87",
      "PB": "3.5",
      "Div": "1.22"
  },
  {
      "Date": "25-Sep-18",
      "PE": "26.91",
      "PB": "3.56",
      "Div": "1.21"
  },
  {
      "Date": "24-Sep-18",
      "PE": "26.66",
      "PB": "3.53",
      "Div": "1.22"
  },
  {
      "Date": "21-Sep-18",
      "PE": "27.09",
      "PB": "3.59",
      "Div": "1.2"
  },
  {
      "Date": "19-Sep-18",
      "PE": "27.31",
      "PB": "3.61",
      "Div": "1.19"
  },
  {
      "Date": "18-Sep-18",
      "PE": "27.42",
      "PB": "3.63",
      "Div": "1.19"
  },
  {
      "Date": "17-Sep-18",
      "PE": "27.66",
      "PB": "3.66",
      "Div": "1.18"
  },
  {
      "Date": "14-Sep-18",
      "PE": "28",
      "PB": "3.71",
      "Div": "1.16"
  },
  {
      "Date": "12-Sep-18",
      "PE": "27.64",
      "PB": "3.66",
      "Div": "1.18"
  },
  {
      "Date": "11-Sep-18",
      "PE": "27.44",
      "PB": "3.63",
      "Div": "1.19"
  },
  {
      "Date": "10-Sep-18",
      "PE": "27.81",
      "PB": "3.68",
      "Div": "1.17"
  },
  {
      "Date": "7-Sep-18",
      "PE": "28.17",
      "PB": "3.73",
      "Div": "1.15"
  },
  {
      "Date": "6-Sep-18",
      "PE": "28.05",
      "PB": "3.71",
      "Div": "1.16"
  },
  {
      "Date": "5-Sep-18",
      "PE": "27.9",
      "PB": "3.69",
      "Div": "1.17"
  },
  {
      "Date": "4-Sep-18",
      "PE": "28.01",
      "PB": "3.71",
      "Div": "1.16"
  },
  {
      "Date": "3-Sep-18",
      "PE": "28.16",
      "PB": "3.73",
      "Div": "1.16"
  },
  {
      "Date": "31-Aug-18",
      "PE": "28.4",
      "PB": "3.76",
      "Div": "1.15"
  },
  {
      "Date": "30-Aug-18",
      "PE": "28.51",
      "PB": "3.76",
      "Div": "1.15"
  },
  {
      "Date": "29-Aug-18",
      "PE": "28.55",
      "PB": "3.76",
      "Div": "1.14"
  },
  {
      "Date": "28-Aug-18",
      "PE": "28.66",
      "PB": "3.82",
      "Div": "1.15"
  },
  {
      "Date": "27-Aug-18",
      "PE": "28.72",
      "PB": "3.81",
      "Div": "1.15"
  },
  {
      "Date": "24-Aug-18",
      "PE": "28.06",
      "PB": "3.76",
      "Div": "1.16"
  },
  {
      "Date": "23-Aug-18",
      "PE": "28.32",
      "PB": "3.77",
      "Div": "1.16"
  },
  {
      "Date": "21-Aug-18",
      "PE": "28.29",
      "PB": "3.77",
      "Div": "1.16"
  },
  {
      "Date": "20-Aug-18",
      "PE": "28.25",
      "PB": "3.76",
      "Div": "1.16"
  },
  {
      "Date": "17-Aug-18",
      "PE": "28.11",
      "PB": "3.74",
      "Div": "1.17"
  },
  {
      "Date": "16-Aug-18",
      "PE": "27.9",
      "PB": "3.71",
      "Div": "1.18"
  },
  {
      "Date": "14-Aug-18",
      "PE": "28.02",
      "PB": "3.72",
      "Div": "1.18"
  },
  {
      "Date": "13-Aug-18",
      "PE": "27.8",
      "PB": "3.7",
      "Div": "1.18"
  },
  {
      "Date": "10-Aug-18",
      "PE": "27.83",
      "PB": "3.72",
      "Div": "1.18"
  },
  {
      "Date": "9-Aug-18",
      "PE": "28.11",
      "PB": "3.74",
      "Div": "1.17"
  },
  {
      "Date": "8-Aug-18",
      "PE": "28.23",
      "PB": "3.73",
      "Div": "1.17"
  },
  {
      "Date": "7-Aug-18",
      "PE": "28.26",
      "PB": "3.71",
      "Div": "1.18"
  },
  {
      "Date": "6-Aug-18",
      "PE": "28.26",
      "PB": "3.71",
      "Div": "1.18"
  },
  {
      "Date": "3-Aug-18",
      "PE": "28.23",
      "PB": "3.7",
      "Div": "1.18"
  },
  {
      "Date": "2-Aug-18",
      "PE": "27.89",
      "PB": "3.66",
      "Div": "1.2"
  },
  {
      "Date": "1-Aug-18",
      "PE": "28.14",
      "PB": "3.69",
      "Div": "1.19"
  },
  {
      "Date": "31-Jul-18",
      "PE": "28.22",
      "PB": "3.7",
      "Div": "1.18"
  },
  {
      "Date": "30-Jul-18",
      "PE": "28.12",
      "PB": "3.69",
      "Div": "1.19"
  },
  {
      "Date": "27-Jul-18",
      "PE": "28.02",
      "PB": "3.67",
      "Div": "1.19"
  },
  {
      "Date": "26-Jul-18",
      "PE": "27.75",
      "PB": "3.64",
      "Div": "1.2"
  },
  {
      "Date": "25-Jul-18",
      "PE": "27.66",
      "PB": "3.63",
      "Div": "1.2"
  },
  {
      "Date": "24-Jul-18",
      "PE": "27.66",
      "PB": "3.63",
      "Div": "1.2"
  },
  {
      "Date": "23-Jul-18",
      "PE": "27.54",
      "PB": "3.61",
      "Div": "1.21"
  },
  {
      "Date": "20-Jul-18",
      "PE": "27.36",
      "PB": "3.59",
      "Div": "1.22"
  },
  {
      "Date": "19-Jul-18",
      "PE": "27.22",
      "PB": "3.69",
      "Div": "1.19"
  },
  {
      "Date": "18-Jul-18",
      "PE": "27.28",
      "PB": "3.7",
      "Div": "1.19"
  },
  {
      "Date": "17-Jul-18",
      "PE": "27.35",
      "PB": "3.7",
      "Div": "1.19"
  },
  {
      "Date": "16-Jul-18",
      "PE": "27.17",
      "PB": "3.68",
      "Div": "1.19"
  },
  {
      "Date": "13-Jul-18",
      "PE": "27.38",
      "PB": "3.71",
      "Div": "1.18"
  },
  {
      "Date": "12-Jul-18",
      "PE": "27.39",
      "PB": "3.71",
      "Div": "1.18"
  },
  {
      "Date": "11-Jul-18",
      "PE": "27.2",
      "PB": "3.68",
      "Div": "1.19"
  },
  {
      "Date": "10-Jul-18",
      "PE": "27.05",
      "PB": "3.68",
      "Div": "1.19"
  },
  {
      "Date": "9-Jul-18",
      "PE": "26.82",
      "PB": "3.65",
      "Div": "1.2"
  },
  {
      "Date": "6-Jul-18",
      "PE": "26.62",
      "PB": "3.63",
      "Div": "1.21"
  },
  {
      "Date": "5-Jul-18",
      "PE": "26.69",
      "PB": "3.62",
      "Div": "1.21"
  },
  {
      "Date": "4-Jul-18",
      "PE": "26.02",
      "PB": "3.62",
      "Div": "1.21"
  },
  {
      "Date": "3-Jul-18",
      "PE": "25.85",
      "PB": "3.6",
      "Div": "1.22"
  },
  {
      "Date": "2-Jul-18",
      "PE": "25.76",
      "PB": "3.59",
      "Div": "1.23"
  },
  {
      "Date": "29-Jun-18",
      "PE": "25.9",
      "PB": "3.61",
      "Div": "1.22"
  },
  {
      "Date": "28-Jun-18",
      "PE": "25.57",
      "PB": "3.64",
      "Div": "1.25"
  },
  {
      "Date": "27-Jun-18",
      "PE": "25.73",
      "PB": "3.67",
      "Div": "1.24"
  },
  {
      "Date": "26-Jun-18",
      "PE": "26.02",
      "PB": "3.7",
      "Div": "1.23"
  },
  {
      "Date": "25-Jun-18",
      "PE": "26.48",
      "PB": "3.7",
      "Div": "1.23"
  },
  {
      "Date": "22-Jun-18",
      "PE": "26.63",
      "PB": "3.72",
      "Div": "1.22"
  },
  {
      "Date": "21-Jun-18",
      "PE": "26.43",
      "PB": "3.69",
      "Div": "1.23"
  },
  {
      "Date": "20-Jun-18",
      "PE": "26.52",
      "PB": "3.7",
      "Div": "1.22"
  },
  {
      "Date": "19-Jun-18",
      "PE": "26.38",
      "PB": "3.68",
      "Div": "1.23"
  },
  {
      "Date": "18-Jun-18",
      "PE": "26.6",
      "PB": "3.71",
      "Div": "1.22"
  },
  {
      "Date": "15-Jun-18",
      "PE": "27.44",
      "PB": "3.72",
      "Div": "1.22"
  },
  {
      "Date": "14-Jun-18",
      "PE": "27.42",
      "PB": "3.72",
      "Div": "1.22"
  },
  {
      "Date": "13-Jun-18",
      "PE": "27.54",
      "PB": "3.73",
      "Div": "1.22"
  },
  {
      "Date": "12-Jun-18",
      "PE": "27.6",
      "PB": "3.73",
      "Div": "1.22"
  },
  {
      "Date": "11-Jun-18",
      "PE": "27.47",
      "PB": "3.71",
      "Div": "1.22"
  },
  {
      "Date": "8-Jun-18",
      "PE": "27.27",
      "PB": "3.7",
      "Div": "1.23"
  },
  {
      "Date": "7-Jun-18",
      "PE": "27.27",
      "PB": "3.7",
      "Div": "1.23"
  },
  {
      "Date": "6-Jun-18",
      "PE": "27.06",
      "PB": "3.67",
      "Div": "1.23"
  },
  {
      "Date": "5-Jun-18",
      "PE": "26.83",
      "PB": "3.64",
      "Div": "1.25"
  },
  {
      "Date": "4-Jun-18",
      "PE": "26.92",
      "PB": "3.66",
      "Div": "1.24"
  },
  {
      "Date": "1-Jun-18",
      "PE": "27.09",
      "PB": "3.68",
      "Div": "1.23"
  },
  {
      "Date": "31-May-18",
      "PE": "27.19",
      "PB": "3.69",
      "Div": "1.23"
  },
  {
      "Date": "30-May-18",
      "PE": "27.01",
      "PB": "3.65",
      "Div": "1.21"
  },
  {
      "Date": "29-May-18",
      "PE": "27.06",
      "PB": "3.65",
      "Div": "1.21"
  },
  {
      "Date": "28-May-18",
      "PE": "27.23",
      "PB": "3.67",
      "Div": "1.2"
  },
  {
      "Date": "25-May-18",
      "PE": "26.24",
      "PB": "3.64",
      "Div": "1.21"
  },
  {
      "Date": "24-May-18",
      "PE": "26.07",
      "PB": "3.61",
      "Div": "1.22"
  },
  {
      "Date": "23-May-18",
      "PE": "25.86",
      "PB": "3.58",
      "Div": "1.23"
  },
  {
      "Date": "22-May-18",
      "PE": "26.13",
      "PB": "3.62",
      "Div": "1.22"
  },
  {
      "Date": "21-May-18",
      "PE": "26.08",
      "PB": "3.61",
      "Div": "1.22"
  },
  {
      "Date": "18-May-18",
      "PE": "26.27",
      "PB": "3.64",
      "Div": "1.21"
  },
  {
      "Date": "17-May-18",
      "PE": "26.52",
      "PB": "3.67",
      "Div": "1.2"
  },
  {
      "Date": "16-May-18",
      "PE": "26.66",
      "PB": "3.69",
      "Div": "1.19"
  },
  {
      "Date": "15-May-18",
      "PE": "26.81",
      "PB": "3.71",
      "Div": "1.19"
  },
  {
      "Date": "14-May-18",
      "PE": "26.82",
      "PB": "3.71",
      "Div": "1.19"
  },
  {
      "Date": "11-May-18",
      "PE": "26.82",
      "PB": "3.71",
      "Div": "1.19"
  },
  {
      "Date": "10-May-18",
      "PE": "26.6",
      "PB": "3.68",
      "Div": "1.2"
  },
  {
      "Date": "9-May-18",
      "PE": "26.66",
      "PB": "3.69",
      "Div": "1.19"
  },
  {
      "Date": "8-May-18",
      "PE": "26.6",
      "PB": "3.68",
      "Div": "1.2"
  },
  {
      "Date": "7-May-18",
      "PE": "26.6",
      "PB": "3.68",
      "Div": "1.2"
  },
  {
      "Date": "4-May-18",
      "PE": "26.36",
      "PB": "3.65",
      "Div": "1.21"
  },
  {
      "Date": "3-May-18",
      "PE": "26.51",
      "PB": "3.67",
      "Div": "1.2"
  },
  {
      "Date": "2-May-18",
      "PE": "26.6",
      "PB": "3.68",
      "Div": "1.2"
  },
  {
      "Date": "30-Apr-18",
      "PE": "26.66",
      "PB": "3.69",
      "Div": "1.19"
  },
  {
      "Date": "27-Apr-18",
      "PE": "26.54",
      "PB": "3.67",
      "Div": "1.2"
  },
  {
      "Date": "26-Apr-18",
      "PE": "26.36",
      "PB": "3.65",
      "Div": "1.21"
  },
  {
      "Date": "25-Apr-18",
      "PE": "26.24",
      "PB": "3.63",
      "Div": "1.21"
  },
  {
      "Date": "24-Apr-18",
      "PE": "26.35",
      "PB": "3.65",
      "Div": "1.21"
  },
  {
      "Date": "23-Apr-18",
      "PE": "26.27",
      "PB": "3.64",
      "Div": "1.21"
  },
  {
      "Date": "20-Apr-18",
      "PE": "26.22",
      "PB": "3.63",
      "Div": "1.21"
  },
  {
      "Date": "19-Apr-18",
      "PE": "26.23",
      "PB": "3.63",
      "Div": "1.21"
  },
  {
      "Date": "18-Apr-18",
      "PE": "26.13",
      "PB": "3.62",
      "Div": "1.22"
  },
  {
      "Date": "17-Apr-18",
      "PE": "26.18",
      "PB": "3.63",
      "Div": "1.22"
  },
  {
      "Date": "16-Apr-18",
      "PE": "26.13",
      "PB": "3.62",
      "Div": "1.22"
  },
  {
      "Date": "13-Apr-18",
      "PE": "26.01",
      "PB": "3.6",
      "Div": "1.22"
  },
  {
      "Date": "12-Apr-18",
      "PE": "25.96",
      "PB": "3.59",
      "Div": "1.23"
  },
  {
      "Date": "11-Apr-18",
      "PE": "25.86",
      "PB": "3.58",
      "Div": "1.23"
  },
  {
      "Date": "10-Apr-18",
      "PE": "25.82",
      "PB": "3.57",
      "Div": "1.23"
  },
  {
      "Date": "9-Apr-18",
      "PE": "25.76",
      "PB": "3.57",
      "Div": "1.23"
  },
  {
      "Date": "6-Apr-18",
      "PE": "25.65",
      "PB": "3.55",
      "Div": "1.24"
  },
  {
      "Date": "5-Apr-18",
      "PE": "25.63",
      "PB": "3.55",
      "Div": "1.24"
  },
  {
      "Date": "4-Apr-18",
      "PE": "25.14",
      "PB": "3.48",
      "Div": "1.27"
  },
  {
      "Date": "3-Apr-18",
      "PE": "25.43",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "2-Apr-18",
      "PE": "25.35",
      "PB": "3.51",
      "Div": "1.26"
  },
  {
      "Date": "28-Mar-18",
      "PE": "24.66",
      "PB": "3.42",
      "Div": "1.29"
  },
  {
      "Date": "27-Mar-18",
      "PE": "24.83",
      "PB": "3.44",
      "Div": "1.28"
  },
  {
      "Date": "26-Mar-18",
      "PE": "24.7",
      "PB": "3.42",
      "Div": "1.29"
  },
  {
      "Date": "23-Mar-18",
      "PE": "24.38",
      "PB": "3.38",
      "Div": "1.3"
  },
  {
      "Date": "22-Mar-18",
      "PE": "24.67",
      "PB": "3.42",
      "Div": "1.29"
  },
  {
      "Date": "21-Mar-18",
      "PE": "24.76",
      "PB": "3.43",
      "Div": "1.28"
  },
  {
      "Date": "20-Mar-18",
      "PE": "24.69",
      "PB": "3.42",
      "Div": "1.29"
  },
  {
      "Date": "19-Mar-18",
      "PE": "24.61",
      "PB": "3.41",
      "Div": "1.29"
  },
  {
      "Date": "16-Mar-18",
      "PE": "24.87",
      "PB": "3.44",
      "Div": "1.28"
  },
  {
      "Date": "15-Mar-18",
      "PE": "25.28",
      "PB": "3.5",
      "Div": "1.26"
  },
  {
      "Date": "14-Mar-18",
      "PE": "25.41",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "13-Mar-18",
      "PE": "25.45",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "12-Mar-18",
      "PE": "25.44",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "9-Mar-18",
      "PE": "24.97",
      "PB": "3.45",
      "Div": "1.28"
  },
  {
      "Date": "8-Mar-18",
      "PE": "25",
      "PB": "3.46",
      "Div": "1.27"
  },
  {
      "Date": "7-Mar-18",
      "PE": "24.79",
      "PB": "3.43",
      "Div": "1.28"
  },
  {
      "Date": "6-Mar-18",
      "PE": "25.02",
      "PB": "3.46",
      "Div": "1.21"
  },
  {
      "Date": "5-Mar-18",
      "PE": "25.4",
      "PB": "3.5",
      "Div": "1.2"
  },
  {
      "Date": "1-Mar-18",
      "PE": "25.59",
      "PB": "3.53",
      "Div": "1.19"
  },
  {
      "Date": "28-Feb-18",
      "PE": "25.68",
      "PB": "3.54",
      "Div": "1.13"
  },
  {
      "Date": "27-Feb-18",
      "PE": "25.87",
      "PB": "3.57",
      "Div": "1.12"
  },
  {
      "Date": "26-Feb-18",
      "PE": "25.95",
      "PB": "3.57",
      "Div": "1.12"
  },
  {
      "Date": "23-Feb-18",
      "PE": "25.75",
      "PB": "3.54",
      "Div": "1.13"
  },
  {
      "Date": "22-Feb-18",
      "PE": "25.49",
      "PB": "3.51",
      "Div": "1.09"
  },
  {
      "Date": "21-Feb-18",
      "PE": "25.18",
      "PB": "3.51",
      "Div": "1.09"
  },
  {
      "Date": "20-Feb-18",
      "PE": "25.09",
      "PB": "3.5",
      "Div": "1.09"
  },
  {
      "Date": "19-Feb-18",
      "PE": "25.14",
      "PB": "3.51",
      "Div": "1.09"
  },
  {
      "Date": "16-Feb-18",
      "PE": "25.32",
      "PB": "3.53",
      "Div": "1.08"
  },
  {
      "Date": "15-Feb-18",
      "PE": "25.45",
      "PB": "3.56",
      "Div": "1.07"
  },
  {
      "Date": "14-Feb-18",
      "PE": "25.37",
      "PB": "3.55",
      "Div": "1.08"
  },
  {
      "Date": "12-Feb-18",
      "PE": "25.48",
      "PB": "3.56",
      "Div": "1.07"
  },
  {
      "Date": "9-Feb-18",
      "PE": "25.27",
      "PB": "3.53",
      "Div": "1.08"
  },
  {
      "Date": "8-Feb-18",
      "PE": "25.56",
      "PB": "3.57",
      "Div": "1.07"
  },
  {
      "Date": "7-Feb-18",
      "PE": "25.32",
      "PB": "3.54",
      "Div": "1.08"
  },
  {
      "Date": "6-Feb-18",
      "PE": "25.37",
      "PB": "3.55",
      "Div": "1.08"
  },
  {
      "Date": "5-Feb-18",
      "PE": "25.78",
      "PB": "3.6",
      "Div": "1.06"
  },
  {
      "Date": "2-Feb-18",
      "PE": "26.04",
      "PB": "3.64",
      "Div": "1.05"
  },
  {
      "Date": "1-Feb-18",
      "PE": "27.48",
      "PB": "3.72",
      "Div": "1.03"
  },
  {
      "Date": "31-Jan-18",
      "PE": "27.5",
      "PB": "3.73",
      "Div": "1.03"
  },
  {
      "Date": "30-Jan-18",
      "PE": "27.51",
      "PB": "3.73",
      "Div": "1.03"
  },
  {
      "Date": "29-Jan-18",
      "PE": "27.71",
      "PB": "3.76",
      "Div": "1.02"
  },
  {
      "Date": "25-Jan-18",
      "PE": "27.61",
      "PB": "3.74",
      "Div": "1.02"
  },
  {
      "Date": "24-Jan-18",
      "PE": "27.66",
      "PB": "3.74",
      "Div": "1.02"
  },
  {
      "Date": "23-Jan-18",
      "PE": "27.81",
      "PB": "3.74",
      "Div": "1.02"
  },
  {
      "Date": "22-Jan-18",
      "PE": "27.62",
      "PB": "3.7",
      "Div": "1.03"
  },
  {
      "Date": "19-Jan-18",
      "PE": "27.44",
      "PB": "3.68",
      "Div": "1.04"
  },
  {
      "Date": "18-Jan-18",
      "PE": "27.25",
      "PB": "3.65",
      "Div": "1.05"
  },
  {
      "Date": "17-Jan-18",
      "PE": "27.18",
      "PB": "3.64",
      "Div": "1.05"
  },
  {
      "Date": "16-Jan-18",
      "PE": "26.96",
      "PB": "3.61",
      "Div": "1.06"
  },
  {
      "Date": "15-Jan-18",
      "PE": "27.06",
      "PB": "3.62",
      "Div": "1.06"
  },
  {
      "Date": "12-Jan-18",
      "PE": "27.28",
      "PB": "3.6",
      "Div": "1.06"
  },
  {
      "Date": "11-Jan-18",
      "PE": "27.23",
      "PB": "3.59",
      "Div": "1.06"
  },
  {
      "Date": "10-Jan-18",
      "PE": "27.18",
      "PB": "3.58",
      "Div": "1.07"
  },
  {
      "Date": "9-Jan-18",
      "PE": "27.19",
      "PB": "3.58",
      "Div": "1.07"
  },
  {
      "Date": "8-Jan-18",
      "PE": "27.16",
      "PB": "3.58",
      "Div": "1.07"
  },
  {
      "Date": "5-Jan-18",
      "PE": "26.99",
      "PB": "3.55",
      "Div": "1.07"
  },
  {
      "Date": "4-Jan-18",
      "PE": "26.86",
      "PB": "3.54",
      "Div": "1.08"
  },
  {
      "Date": "3-Jan-18",
      "PE": "26.7",
      "PB": "3.52",
      "Div": "1.09"
  },
  {
      "Date": "2-Jan-18",
      "PE": "26.7",
      "PB": "3.52",
      "Div": "1.09"
  },
  {
      "Date": "1-Jan-18",
      "PE": "26.68",
      "PB": "3.51",
      "Div": "1.09"
  },
  {
      "Date": "29-Dec-17",
      "PE": "26.92",
      "PB": "3.55",
      "Div": "1.08"
  },
  {
      "Date": "28-Dec-17",
      "PE": "26.82",
      "PB": "3.53",
      "Div": "1.08"
  },
  {
      "Date": "27-Dec-17",
      "PE": "26.85",
      "PB": "3.54",
      "Div": "1.08"
  },
  {
      "Date": "26-Dec-17",
      "PE": "26.96",
      "PB": "3.55",
      "Div": "1.08"
  },
  {
      "Date": "22-Dec-17",
      "PE": "26.86",
      "PB": "3.54",
      "Div": "1.08"
  },
  {
      "Date": "21-Dec-17",
      "PE": "26.74",
      "PB": "3.52",
      "Div": "1.09"
  },
  {
      "Date": "20-Dec-17",
      "PE": "26.75",
      "PB": "3.52",
      "Div": "1.09"
  },
  {
      "Date": "19-Dec-17",
      "PE": "26.79",
      "PB": "3.53",
      "Div": "1.09"
  },
  {
      "Date": "18-Dec-17",
      "PE": "26.6",
      "PB": "3.5",
      "Div": "1.09"
  },
  {
      "Date": "15-Dec-17",
      "PE": "26.46",
      "PB": "3.48",
      "Div": "1.1"
  },
  {
      "Date": "14-Dec-17",
      "PE": "26.25",
      "PB": "3.46",
      "Div": "1.11"
  },
  {
      "Date": "13-Dec-17",
      "PE": "26.1",
      "PB": "3.44",
      "Div": "1.11"
  },
  {
      "Date": "12-Dec-17",
      "PE": "26.22",
      "PB": "3.45",
      "Div": "1.11"
  },
  {
      "Date": "11-Dec-17",
      "PE": "26.43",
      "PB": "3.48",
      "Div": "1.1"
  },
  {
      "Date": "8-Dec-17",
      "PE": "26.26",
      "PB": "3.46",
      "Div": "1.11"
  },
  {
      "Date": "7-Dec-17",
      "PE": "26.01",
      "PB": "3.43",
      "Div": "1.12"
  },
  {
      "Date": "6-Dec-17",
      "PE": "25.7",
      "PB": "3.39",
      "Div": "1.13"
  },
  {
      "Date": "5-Dec-17",
      "PE": "25.89",
      "PB": "3.41",
      "Div": "1.12"
  },
  {
      "Date": "4-Dec-17",
      "PE": "25.91",
      "PB": "3.42",
      "Div": "1.12"
  },
  {
      "Date": "1-Dec-17",
      "PE": "25.9",
      "PB": "3.41",
      "Div": "1.12"
  },
  {
      "Date": "30-Nov-17",
      "PE": "26.16",
      "PB": "3.45",
      "Div": "1.11"
  },
  {
      "Date": "29-Nov-17",
      "PE": "26.51",
      "PB": "3.49",
      "Div": "1.07"
  },
  {
      "Date": "28-Nov-17",
      "PE": "26.53",
      "PB": "3.49",
      "Div": "1.07"
  },
  {
      "Date": "27-Nov-17",
      "PE": "26.61",
      "PB": "3.5",
      "Div": "1.07"
  },
  {
      "Date": "24-Nov-17",
      "PE": "26.59",
      "PB": "3.5",
      "Div": "1.07"
  },
  {
      "Date": "23-Nov-17",
      "PE": "26.48",
      "PB": "3.49",
      "Div": "1.08"
  },
  {
      "Date": "22-Nov-17",
      "PE": "26.4",
      "PB": "3.48",
      "Div": "1.08"
  },
  {
      "Date": "21-Nov-17",
      "PE": "26.2",
      "PB": "3.48",
      "Div": "1.08"
  },
  {
      "Date": "20-Nov-17",
      "PE": "26.18",
      "PB": "3.47",
      "Div": "1.08"
  },
  {
      "Date": "17-Nov-17",
      "PE": "26.14",
      "PB": "3.46",
      "Div": "1.08"
  },
  {
      "Date": "16-Nov-17",
      "PE": "25.96",
      "PB": "3.45",
      "Div": "1.09"
  },
  {
      "Date": "15-Nov-17",
      "PE": "25.72",
      "PB": "3.41",
      "Div": "1.1"
  },
  {
      "Date": "14-Nov-17",
      "PE": "25.89",
      "PB": "3.44",
      "Div": "1.09"
  },
  {
      "Date": "13-Nov-17",
      "PE": "25.99",
      "PB": "3.45",
      "Div": "1.09"
  },
  {
      "Date": "10-Nov-17",
      "PE": "26.35",
      "PB": "3.48",
      "Div": "1.08"
  },
  {
      "Date": "9-Nov-17",
      "PE": "26.31",
      "PB": "3.48",
      "Div": "1.08"
  },
  {
      "Date": "8-Nov-17",
      "PE": "26.37",
      "PB": "3.48",
      "Div": "1.08"
  },
  {
      "Date": "7-Nov-17",
      "PE": "26.48",
      "PB": "3.49",
      "Div": "1.08"
  },
  {
      "Date": "6-Nov-17",
      "PE": "26.74",
      "PB": "3.53",
      "Div": "1.07"
  },
  {
      "Date": "3-Nov-17",
      "PE": "26.87",
      "PB": "3.53",
      "Div": "1.07"
  },
  {
      "Date": "2-Nov-17",
      "PE": "26.63",
      "PB": "3.52",
      "Div": "1.07"
  },
  {
      "Date": "1-Nov-17",
      "PE": "26.49",
      "PB": "3.52",
      "Div": "1.07"
  },
  {
      "Date": "31-Oct-17",
      "PE": "26.38",
      "PB": "3.49",
      "Div": "1.08"
  },
  {
      "Date": "30-Oct-17",
      "PE": "26.77",
      "PB": "3.5",
      "Div": "1.1"
  },
  {
      "Date": "27-Oct-17",
      "PE": "26.67",
      "PB": "3.49",
      "Div": "1.11"
  },
  {
      "Date": "26-Oct-17",
      "PE": "26.73",
      "PB": "3.49",
      "Div": "1.1"
  },
  {
      "Date": "25-Oct-17",
      "PE": "26.63",
      "PB": "3.48",
      "Div": "1.11"
  },
  {
      "Date": "24-Oct-17",
      "PE": "26.42",
      "PB": "3.45",
      "Div": "1.12"
  },
  {
      "Date": "23-Oct-17",
      "PE": "26.37",
      "PB": "3.44",
      "Div": "1.12"
  },
  {
      "Date": "19-Oct-17",
      "PE": "26.28",
      "PB": "3.43",
      "Div": "1.12"
  },
  {
      "Date": "18-Oct-17",
      "PE": "26.44",
      "PB": "3.45",
      "Div": "1.12"
  },
  {
      "Date": "17-Oct-17",
      "PE": "26.52",
      "PB": "3.46",
      "Div": "1.12"
  },
  {
      "Date": "16-Oct-17",
      "PE": "26.55",
      "PB": "3.46",
      "Div": "1.12"
  },
  {
      "Date": "13-Oct-17",
      "PE": "26.41",
      "PB": "3.47",
      "Div": "1.12"
  },
  {
      "Date": "12-Oct-17",
      "PE": "26.22",
      "PB": "3.45",
      "Div": "1.13"
  },
  {
      "Date": "11-Oct-17",
      "PE": "25.94",
      "PB": "3.41",
      "Div": "1.14"
  },
  {
      "Date": "10-Oct-17",
      "PE": "26.02",
      "PB": "3.42",
      "Div": "1.14"
  },
  {
      "Date": "9-Oct-17",
      "PE": "25.95",
      "PB": "3.41",
      "Div": "1.14"
  },
  {
      "Date": "6-Oct-17",
      "PE": "25.92",
      "PB": "3.41",
      "Div": "1.14"
  },
  {
      "Date": "5-Oct-17",
      "PE": "25.69",
      "PB": "3.38",
      "Div": "1.15"
  },
  {
      "Date": "4-Oct-17",
      "PE": "25.75",
      "PB": "3.39",
      "Div": "1.15"
  },
  {
      "Date": "3-Oct-17",
      "PE": "25.61",
      "PB": "3.37",
      "Div": "1.16"
  },
  {
      "Date": "29-Sep-17",
      "PE": "25.43",
      "PB": "3.35",
      "Div": "1.17"
  },
  {
      "Date": "28-Sep-17",
      "PE": "25.44",
      "PB": "3.4",
      "Div": "0.97"
  },
  {
      "Date": "27-Sep-17",
      "PE": "25.36",
      "PB": "3.39",
      "Div": "0.98"
  },
  {
      "Date": "26-Sep-17",
      "PE": "25.71",
      "PB": "3.43",
      "Div": "0.96"
  },
  {
      "Date": "25-Sep-17",
      "PE": "25.71",
      "PB": "3.43",
      "Div": "0.96"
  },
  {
      "Date": "22-Sep-17",
      "PE": "25.95",
      "PB": "3.47",
      "Div": "0.95"
  },
  {
      "Date": "21-Sep-17",
      "PE": "26.34",
      "PB": "3.52",
      "Div": "0.94"
  },
  {
      "Date": "20-Sep-17",
      "PE": "26.39",
      "PB": "3.53",
      "Div": "0.94"
  },
  {
      "Date": "19-Sep-17",
      "PE": "26.4",
      "PB": "3.53",
      "Div": "0.94"
  },
  {
      "Date": "18-Sep-17",
      "PE": "26.42",
      "PB": "3.53",
      "Div": "0.94"
  },
  {
      "Date": "15-Sep-17",
      "PE": "26.24",
      "PB": "3.51",
      "Div": "0.94"
  },
  {
      "Date": "14-Sep-17",
      "PE": "26.25",
      "PB": "3.51",
      "Div": "0.93"
  },
  {
      "Date": "13-Sep-17",
      "PE": "26.23",
      "PB": "3.51",
      "Div": "0.93"
  },
  {
      "Date": "12-Sep-17",
      "PE": "26.26",
      "PB": "3.51",
      "Div": "0.93"
  },
  {
      "Date": "11-Sep-17",
      "PE": "26.11",
      "PB": "3.5",
      "Div": "0.93"
  },
  {
      "Date": "8-Sep-17",
      "PE": "25.9",
      "PB": "3.47",
      "Div": "0.94"
  },
  {
      "Date": "7-Sep-17",
      "PE": "25.89",
      "PB": "3.49",
      "Div": "0.94"
  },
  {
      "Date": "6-Sep-17",
      "PE": "25.88",
      "PB": "3.49",
      "Div": "0.94"
  },
  {
      "Date": "5-Sep-17",
      "PE": "25.97",
      "PB": "3.5",
      "Div": "0.94"
  },
  {
      "Date": "4-Sep-17",
      "PE": "25.83",
      "PB": "3.48",
      "Div": "0.94"
  },
  {
      "Date": "1-Sep-17",
      "PE": "25.99",
      "PB": "3.51",
      "Div": "0.93"
  },
  {
      "Date": "31-Aug-17",
      "PE": "25.62",
      "PB": "3.49",
      "Div": "0.94"
  },
  {
      "Date": "30-Aug-17",
      "PE": "25.54",
      "PB": "3.47",
      "Div": "0.94"
  },
  {
      "Date": "29-Aug-17",
      "PE": "25.31",
      "PB": "3.44",
      "Div": "0.95"
  },
  {
      "Date": "28-Aug-17",
      "PE": "25.61",
      "PB": "3.48",
      "Div": "0.94"
  },
  {
      "Date": "24-Aug-17",
      "PE": "25.47",
      "PB": "3.46",
      "Div": "0.95"
  },
  {
      "Date": "23-Aug-17",
      "PE": "25.45",
      "PB": "3.47",
      "Div": "0.96"
  },
  {
      "Date": "22-Aug-17",
      "PE": "25.25",
      "PB": "3.44",
      "Div": "0.97"
  },
  {
      "Date": "21-Aug-17",
      "PE": "24.99",
      "PB": "3.44",
      "Div": "0.97"
  },
  {
      "Date": "18-Aug-17",
      "PE": "25.1",
      "PB": "3.46",
      "Div": "0.97"
  },
  {
      "Date": "17-Aug-17",
      "PE": "25.3",
      "PB": "3.5",
      "Div": "0.97"
  },
  {
      "Date": "16-Aug-17",
      "PE": "25.28",
      "PB": "3.49",
      "Div": "0.97"
  },
  {
      "Date": "14-Aug-17",
      "PE": "25.02",
      "PB": "3.46",
      "Div": "0.98"
  },
  {
      "Date": "11-Aug-17",
      "PE": "24.8",
      "PB": "3.43",
      "Div": "0.99"
  },
  {
      "Date": "10-Aug-17",
      "PE": "25.06",
      "PB": "3.47",
      "Div": "0.98"
  },
  {
      "Date": "9-Aug-17",
      "PE": "25.31",
      "PB": "3.51",
      "Div": "0.97"
  },
  {
      "Date": "8-Aug-17",
      "PE": "25.5",
      "PB": "3.53",
      "Div": "0.97"
  },
  {
      "Date": "7-Aug-17",
      "PE": "25.67",
      "PB": "3.56",
      "Div": "0.96"
  },
  {
      "Date": "4-Aug-17",
      "PE": "25.61",
      "PB": "3.56",
      "Div": "0.96"
  },
  {
      "Date": "3-Aug-17",
      "PE": "25.5",
      "PB": "3.49",
      "Div": "0.96"
  },
  {
      "Date": "2-Aug-17",
      "PE": "25.63",
      "PB": "3.51",
      "Div": "0.95"
  },
  {
      "Date": "1-Aug-17",
      "PE": "25.75",
      "PB": "3.52",
      "Div": "0.95"
  },
  {
      "Date": "31-Jul-17",
      "PE": "25.69",
      "PB": "3.51",
      "Div": "0.95"
  },
  {
      "Date": "28-Jul-17",
      "PE": "25.56",
      "PB": "3.49",
      "Div": "0.96"
  },
  {
      "Date": "27-Jul-17",
      "PE": "25.56",
      "PB": "3.57",
      "Div": "1"
  },
  {
      "Date": "26-Jul-17",
      "PE": "25.57",
      "PB": "3.57",
      "Div": "1"
  },
  {
      "Date": "25-Jul-17",
      "PE": "25.45",
      "PB": "3.55",
      "Div": "1"
  },
  {
      "Date": "24-Jul-17",
      "PE": "25.45",
      "PB": "3.55",
      "Div": "1"
  },
  {
      "Date": "21-Jul-17",
      "PE": "25.32",
      "PB": "3.53",
      "Div": "1.01"
  },
  {
      "Date": "20-Jul-17",
      "PE": "25.22",
      "PB": "3.52",
      "Div": "1.01"
  },
  {
      "Date": "19-Jul-17",
      "PE": "25.28",
      "PB": "3.53",
      "Div": "1.01"
  },
  {
      "Date": "18-Jul-17",
      "PE": "25.1",
      "PB": "3.56",
      "Div": "1.06"
  },
  {
      "Date": "17-Jul-17",
      "PE": "25.17",
      "PB": "3.67",
      "Div": "1.08"
  },
  {
      "Date": "14-Jul-17",
      "PE": "25.12",
      "PB": "3.66",
      "Div": "1.08"
  },
  {
      "Date": "13-Jul-17",
      "PE": "25.17",
      "PB": "3.66",
      "Div": "1.08"
  },
  {
      "Date": "12-Jul-17",
      "PE": "24.98",
      "PB": "3.63",
      "Div": "1.09"
  },
  {
      "Date": "11-Jul-17",
      "PE": "24.91",
      "PB": "3.62",
      "Div": "1.09"
  },
  {
      "Date": "10-Jul-17",
      "PE": "24.87",
      "PB": "3.62",
      "Div": "1.09"
  },
  {
      "Date": "7-Jul-17",
      "PE": "24.6",
      "PB": "3.58",
      "Div": "1.1"
  },
  {
      "Date": "6-Jul-17",
      "PE": "24.62",
      "PB": "3.58",
      "Div": "1.1"
  },
  {
      "Date": "5-Jul-17",
      "PE": "24.53",
      "PB": "3.57",
      "Div": "1.11"
  },
  {
      "Date": "4-Jul-17",
      "PE": "24.47",
      "PB": "3.56",
      "Div": "1.11"
  },
  {
      "Date": "3-Jul-17",
      "PE": "24.47",
      "PB": "3.56",
      "Div": "1.11"
  },
  {
      "Date": "30-Jun-17",
      "PE": "24.23",
      "PB": "3.52",
      "Div": "1.12"
  },
  {
      "Date": "29-Jun-17",
      "PE": "24.19",
      "PB": "3.52",
      "Div": "1.12"
  },
  {
      "Date": "28-Jun-17",
      "PE": "24.15",
      "PB": "3.51",
      "Div": "1.12"
  },
  {
      "Date": "27-Jun-17",
      "PE": "24.21",
      "PB": "3.52",
      "Div": "1.12"
  },
  {
      "Date": "23-Jun-17",
      "PE": "24.37",
      "PB": "3.54",
      "Div": "1.11"
  },
  {
      "Date": "22-Jun-17",
      "PE": "24.49",
      "PB": "3.57",
      "Div": "1.11"
  },
  {
      "Date": "21-Jun-17",
      "PE": "24.31",
      "PB": "3.57",
      "Div": "1.11"
  },
  {
      "Date": "20-Jun-17",
      "PE": "24.36",
      "PB": "3.58",
      "Div": "1.1"
  },
  {
      "Date": "19-Jun-17",
      "PE": "24.37",
      "PB": "3.58",
      "Div": "1.1"
  },
  {
      "Date": "16-Jun-17",
      "PE": "24",
      "PB": "3.56",
      "Div": "1.11"
  },
  {
      "Date": "15-Jun-17",
      "PE": "24.19",
      "PB": "3.6",
      "Div": "1.19"
  },
  {
      "Date": "14-Jun-17",
      "PE": "24.29",
      "PB": "3.62",
      "Div": "1.19"
  },
  {
      "Date": "13-Jun-17",
      "PE": "24.27",
      "PB": "3.62",
      "Div": "1.19"
  },
  {
      "Date": "12-Jun-17",
      "PE": "24.25",
      "PB": "3.62",
      "Div": "1.19"
  },
  {
      "Date": "9-Jun-17",
      "PE": "24.38",
      "PB": "3.64",
      "Div": "1.18"
  },
  {
      "Date": "8-Jun-17",
      "PE": "24.43",
      "PB": "3.63",
      "Div": "1.18"
  },
  {
      "Date": "7-Jun-17",
      "PE": "24.48",
      "PB": "3.64",
      "Div": "1.18"
  },
  {
      "Date": "6-Jun-17",
      "PE": "24.35",
      "PB": "3.63",
      "Div": "1.19"
  },
  {
      "Date": "5-Jun-17",
      "PE": "24.45",
      "PB": "3.64",
      "Div": "1.18"
  },
  {
      "Date": "2-Jun-17",
      "PE": "24.43",
      "PB": "3.63",
      "Div": "1.18"
  },
  {
      "Date": "1-Jun-17",
      "PE": "24.34",
      "PB": "3.62",
      "Div": "1.19"
  },
  {
      "Date": "31-May-17",
      "PE": "24.35",
      "PB": "3.62",
      "Div": "1.19"
  },
  {
      "Date": "30-May-17",
      "PE": "24.36",
      "PB": "3.63",
      "Div": "1.19"
  },
  {
      "Date": "29-May-17",
      "PE": "24.31",
      "PB": "3.62",
      "Div": "1.19"
  },
  {
      "Date": "26-May-17",
      "PE": "24.32",
      "PB": "3.62",
      "Div": "1.19"
  },
  {
      "Date": "25-May-17",
      "PE": "24.88",
      "PB": "3.63",
      "Div": "1.21"
  },
  {
      "Date": "24-May-17",
      "PE": "24.73",
      "PB": "3.58",
      "Div": "1.23"
  },
  {
      "Date": "23-May-17",
      "PE": "24.8",
      "PB": "3.59",
      "Div": "1.22"
  },
  {
      "Date": "22-May-17",
      "PE": "24.94",
      "PB": "3.61",
      "Div": "1.22"
  },
  {
      "Date": "19-May-17",
      "PE": "24.91",
      "PB": "3.6",
      "Div": "1.22"
  },
  {
      "Date": "18-May-17",
      "PE": "24.91",
      "PB": "3.6",
      "Div": "1.22"
  },
  {
      "Date": "17-May-17",
      "PE": "25.23",
      "PB": "3.64",
      "Div": "1.2"
  },
  {
      "Date": "16-May-17",
      "PE": "24.16",
      "PB": "3.63",
      "Div": "1.21"
  },
  {
      "Date": "15-May-17",
      "PE": "23.99",
      "PB": "3.61",
      "Div": "1.22"
  },
  {
      "Date": "12-May-17",
      "PE": "23.88",
      "PB": "3.59",
      "Div": "1.22"
  },
  {
      "Date": "11-May-17",
      "PE": "23.93",
      "PB": "3.6",
      "Div": "1.22"
  },
  {
      "Date": "10-May-17",
      "PE": "23.89",
      "PB": "3.59",
      "Div": "1.22"
  },
  {
      "Date": "9-May-17",
      "PE": "23.67",
      "PB": "3.56",
      "Div": "1.23"
  },
  {
      "Date": "8-May-17",
      "PE": "23.66",
      "PB": "3.56",
      "Div": "1.23"
  },
  {
      "Date": "5-May-17",
      "PE": "23.59",
      "PB": "3.55",
      "Div": "1.24"
  },
  {
      "Date": "4-May-17",
      "PE": "23.77",
      "PB": "3.58",
      "Div": "1.23"
  },
  {
      "Date": "3-May-17",
      "PE": "23.65",
      "PB": "3.56",
      "Div": "1.23"
  },
  {
      "Date": "2-May-17",
      "PE": "23.66",
      "PB": "3.56",
      "Div": "1.23"
  },
  {
      "Date": "28-Apr-17",
      "PE": "23.63",
      "PB": "3.55",
      "Div": "1.23"
  },
  {
      "Date": "27-Apr-17",
      "PE": "23.73",
      "PB": "3.57",
      "Div": "1.23"
  },
  {
      "Date": "26-Apr-17",
      "PE": "23.75",
      "PB": "3.57",
      "Div": "1.23"
  },
  {
      "Date": "25-Apr-17",
      "PE": "23.64",
      "PB": "3.56",
      "Div": "1.23"
  },
  {
      "Date": "24-Apr-17",
      "PE": "23.41",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "21-Apr-17",
      "PE": "23.12",
      "PB": "3.48",
      "Div": "1.26"
  },
  {
      "Date": "20-Apr-17",
      "PE": "23.16",
      "PB": "3.49",
      "Div": "1.26"
  },
  {
      "Date": "19-Apr-17",
      "PE": "23.08",
      "PB": "3.47",
      "Div": "1.26"
  },
  {
      "Date": "18-Apr-17",
      "PE": "23.08",
      "PB": "3.47",
      "Div": "1.26"
  },
  {
      "Date": "17-Apr-17",
      "PE": "23.17",
      "PB": "3.49",
      "Div": "1.26"
  },
  {
      "Date": "13-Apr-17",
      "PE": "23.2",
      "PB": "3.49",
      "Div": "1.26"
  },
  {
      "Date": "12-Apr-17",
      "PE": "23.33",
      "PB": "3.51",
      "Div": "1.25"
  },
  {
      "Date": "11-Apr-17",
      "PE": "23.42",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "10-Apr-17",
      "PE": "23.28",
      "PB": "3.5",
      "Div": "1.25"
  },
  {
      "Date": "7-Apr-17",
      "PE": "23.32",
      "PB": "3.51",
      "Div": "1.25"
  },
  {
      "Date": "6-Apr-17",
      "PE": "23.48",
      "PB": "3.53",
      "Div": "1.24"
  },
  {
      "Date": "5-Apr-17",
      "PE": "23.49",
      "PB": "3.54",
      "Div": "1.24"
  },
  {
      "Date": "3-Apr-17",
      "PE": "23.42",
      "PB": "3.53",
      "Div": "1.25"
  },
  {
      "Date": "31-Mar-17",
      "PE": "23.26",
      "PB": "3.5",
      "Div": "1.25"
  },
  {
      "Date": "30-Mar-17",
      "PE": "23.82",
      "PB": "3.48",
      "Div": "1.21"
  },
  {
      "Date": "29-Mar-17",
      "PE": "23.74",
      "PB": "3.47",
      "Div": "1.21"
  },
  {
      "Date": "28-Mar-17",
      "PE": "23.63",
      "PB": "3.45",
      "Div": "1.22"
  },
  {
      "Date": "27-Mar-17",
      "PE": "23.49",
      "PB": "3.43",
      "Div": "1.23"
  },
  {
      "Date": "24-Mar-17",
      "PE": "23.65",
      "PB": "3.45",
      "Div": "1.22"
  },
  {
      "Date": "23-Mar-17",
      "PE": "23.59",
      "PB": "3.44",
      "Div": "1.22"
  },
  {
      "Date": "22-Mar-17",
      "PE": "23.45",
      "PB": "3.42",
      "Div": "1.23"
  },
  {
      "Date": "21-Mar-17",
      "PE": "23.68",
      "PB": "3.46",
      "Div": "1.22"
  },
  {
      "Date": "20-Mar-17",
      "PE": "23.7",
      "PB": "3.46",
      "Div": "1.22"
  },
  {
      "Date": "17-Mar-17",
      "PE": "23.78",
      "PB": "3.47",
      "Div": "1.21"
  },
  {
      "Date": "16-Mar-17",
      "PE": "23.77",
      "PB": "3.47",
      "Div": "1.21"
  },
  {
      "Date": "15-Mar-17",
      "PE": "23.59",
      "PB": "3.44",
      "Div": "1.22"
  },
  {
      "Date": "14-Mar-17",
      "PE": "23.59",
      "PB": "3.44",
      "Div": "1.22"
  },
  {
      "Date": "10-Mar-17",
      "PE": "23.2",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "9-Mar-17",
      "PE": "23.18",
      "PB": "3.38",
      "Div": "1.24"
  },
  {
      "Date": "8-Mar-17",
      "PE": "23.17",
      "PB": "3.38",
      "Div": "1.24"
  },
  {
      "Date": "7-Mar-17",
      "PE": "23.23",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "6-Mar-17",
      "PE": "23.27",
      "PB": "3.4",
      "Div": "1.24"
  },
  {
      "Date": "3-Mar-17",
      "PE": "23.1",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "2-Mar-17",
      "PE": "23.11",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "1-Mar-17",
      "PE": "23.3",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "28-Feb-17",
      "PE": "23.13",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "27-Feb-17",
      "PE": "23.21",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "23-Feb-17",
      "PE": "23.36",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "22-Feb-17",
      "PE": "23.32",
      "PB": "3.38",
      "Div": "1.24"
  },
  {
      "Date": "21-Feb-17",
      "PE": "23.27",
      "PB": "3.38",
      "Div": "1.24"
  },
  {
      "Date": "20-Feb-17",
      "PE": "23.19",
      "PB": "3.37",
      "Div": "1.24"
  },
  {
      "Date": "17-Feb-17",
      "PE": "23.1",
      "PB": "3.34",
      "Div": "1.25"
  },
  {
      "Date": "16-Feb-17",
      "PE": "23.08",
      "PB": "3.33",
      "Div": "1.26"
  },
  {
      "Date": "15-Feb-17",
      "PE": "22.84",
      "PB": "3.31",
      "Div": "1.27"
  },
  {
      "Date": "14-Feb-17",
      "PE": "23.05",
      "PB": "3.33",
      "Div": "1.26"
  },
  {
      "Date": "13-Feb-17",
      "PE": "23.15",
      "PB": "3.34",
      "Div": "1.25"
  },
  {
      "Date": "10-Feb-17",
      "PE": "23.34",
      "PB": "3.33",
      "Div": "1.26"
  },
  {
      "Date": "9-Feb-17",
      "PE": "23.31",
      "PB": "3.33",
      "Div": "1.26"
  },
  {
      "Date": "8-Feb-17",
      "PE": "23.28",
      "PB": "3.32",
      "Div": "1.26"
  },
  {
      "Date": "7-Feb-17",
      "PE": "23.31",
      "PB": "3.32",
      "Div": "1.26"
  },
  {
      "Date": "6-Feb-17",
      "PE": "23.43",
      "PB": "3.34",
      "Div": "1.25"
  },
  {
      "Date": "3-Feb-17",
      "PE": "23.29",
      "PB": "3.31",
      "Div": "1.26"
  },
  {
      "Date": "2-Feb-17",
      "PE": "23.38",
      "PB": "3.31",
      "Div": "1.26"
  },
  {
      "Date": "1-Feb-17",
      "PE": "23.27",
      "PB": "3.3",
      "Div": "1.27"
  },
  {
      "Date": "31-Jan-17",
      "PE": "22.86",
      "PB": "3.25",
      "Div": "1.29"
  },
  {
      "Date": "30-Jan-17",
      "PE": "22.98",
      "PB": "3.27",
      "Div": "1.28"
  },
  {
      "Date": "27-Jan-17",
      "PE": "23",
      "PB": "3.28",
      "Div": "1.28"
  },
  {
      "Date": "25-Jan-17",
      "PE": "22.98",
      "PB": "3.26",
      "Div": "1.28"
  },
  {
      "Date": "24-Jan-17",
      "PE": "22.64",
      "PB": "3.21",
      "Div": "1.3"
  },
  {
      "Date": "23-Jan-17",
      "PE": "22.48",
      "PB": "3.18",
      "Div": "1.32"
  },
  {
      "Date": "20-Jan-17",
      "PE": "22.37",
      "PB": "3.17",
      "Div": "1.32"
  },
  {
      "Date": "19-Jan-17",
      "PE": "22.44",
      "PB": "3.2",
      "Div": "1.31"
  },
  {
      "Date": "18-Jan-17",
      "PE": "22.39",
      "PB": "3.19",
      "Div": "1.31"
  },
  {
      "Date": "17-Jan-17",
      "PE": "22.4",
      "PB": "3.18",
      "Div": "1.32"
  },
  {
      "Date": "16-Jan-17",
      "PE": "22.5",
      "PB": "3.19",
      "Div": "1.31"
  },
  {
      "Date": "13-Jan-17",
      "PE": "22.47",
      "PB": "3.18",
      "Div": "1.31"
  },
  {
      "Date": "12-Jan-17",
      "PE": "22.5",
      "PB": "3.19",
      "Div": "1.31"
  },
  {
      "Date": "11-Jan-17",
      "PE": "22.42",
      "PB": "3.18",
      "Div": "1.32"
  },
  {
      "Date": "10-Jan-17",
      "PE": "22.39",
      "PB": "3.14",
      "Div": "1.33"
  },
  {
      "Date": "9-Jan-17",
      "PE": "22.07",
      "PB": "3.12",
      "Div": "1.34"
  },
  {
      "Date": "6-Jan-17",
      "PE": "22.09",
      "PB": "3.13",
      "Div": "1.34"
  },
  {
      "Date": "5-Jan-17",
      "PE": "22.17",
      "PB": "3.14",
      "Div": "1.33"
  },
  {
      "Date": "4-Jan-17",
      "PE": "21.94",
      "PB": "3.11",
      "Div": "1.35"
  },
  {
      "Date": "3-Jan-17",
      "PE": "22.12",
      "PB": "3.11",
      "Div": "1.35"
  },
  {
      "Date": "2-Jan-17",
      "PE": "22.08",
      "PB": "3.1",
      "Div": "1.35"
  },
  {
      "Date": "30-Dec-16",
      "PE": "21.93",
      "PB": "3.1",
      "Div": "1.35"
  },
  {
      "Date": "29-Dec-16",
      "PE": "21.89",
      "PB": "3.07",
      "Div": "1.36"
  },
  {
      "Date": "28-Dec-16",
      "PE": "21.71",
      "PB": "3.05",
      "Div": "1.37"
  },
  {
      "Date": "27-Dec-16",
      "PE": "21.7",
      "PB": "3.05",
      "Div": "1.37"
  },
  {
      "Date": "26-Dec-16",
      "PE": "21.36",
      "PB": "3",
      "Div": "1.4"
  },
  {
      "Date": "23-Dec-16",
      "PE": "21.39",
      "PB": "3.03",
      "Div": "1.38"
  },
  {
      "Date": "22-Dec-16",
      "PE": "21.18",
      "PB": "3.03",
      "Div": "1.38"
  },
  {
      "Date": "21-Dec-16",
      "PE": "21.16",
      "PB": "3.06",
      "Div": "1.37"
  },
  {
      "Date": "20-Dec-16",
      "PE": "21.24",
      "PB": "3.06",
      "Div": "1.37"
  },
  {
      "Date": "19-Dec-16",
      "PE": "21.3",
      "PB": "3.07",
      "Div": "1.36"
  },
  {
      "Date": "16-Dec-16",
      "PE": "21.37",
      "PB": "3.09",
      "Div": "1.36"
  },
  {
      "Date": "15-Dec-16",
      "PE": "21.41",
      "PB": "3.09",
      "Div": "1.35"
  },
  {
      "Date": "14-Dec-16",
      "PE": "21.48",
      "PB": "3.1",
      "Div": "1.35"
  },
  {
      "Date": "13-Dec-16",
      "PE": "21.59",
      "PB": "3.12",
      "Div": "1.34"
  },
  {
      "Date": "12-Dec-16",
      "PE": "21.45",
      "PB": "3.1",
      "Div": "1.35"
  },
  {
      "Date": "9-Dec-16",
      "PE": "21.87",
      "PB": "3.13",
      "Div": "1.34"
  },
  {
      "Date": "8-Dec-16",
      "PE": "21.83",
      "PB": "3.13",
      "Div": "1.34"
  },
  {
      "Date": "7-Dec-16",
      "PE": "21.28",
      "PB": "3.07",
      "Div": "1.36"
  },
  {
      "Date": "6-Dec-16",
      "PE": "21.56",
      "PB": "3.09",
      "Div": "1.36"
  },
  {
      "Date": "5-Dec-16",
      "PE": "21.34",
      "PB": "3.08",
      "Div": "1.36"
  },
  {
      "Date": "2-Dec-16",
      "PE": "21.25",
      "PB": "3.07",
      "Div": "1.37"
  },
  {
      "Date": "1-Dec-16",
      "PE": "21.53",
      "PB": "3.11",
      "Div": "1.35"
  },
  {
      "Date": "30-Nov-16",
      "PE": "21.61",
      "PB": "3.12",
      "Div": "1.34"
  },
  {
      "Date": "29-Nov-16",
      "PE": "21.43",
      "PB": "3.09",
      "Div": "1.36"
  },
  {
      "Date": "28-Nov-16",
      "PE": "21.55",
      "PB": "3.08",
      "Div": "1.36"
  },
  {
      "Date": "25-Nov-16",
      "PE": "21.52",
      "PB": "3.08",
      "Div": "1.36"
  },
  {
      "Date": "24-Nov-16",
      "PE": "21.29",
      "PB": "3.02",
      "Div": "1.39"
  },
  {
      "Date": "23-Nov-16",
      "PE": "21.47",
      "PB": "3.04",
      "Div": "1.38"
  },
  {
      "Date": "22-Nov-16",
      "PE": "21.38",
      "PB": "3.03",
      "Div": "1.38"
  },
  {
      "Date": "21-Nov-16",
      "PE": "21.19",
      "PB": "3",
      "Div": "1.4"
  },
  {
      "Date": "18-Nov-16",
      "PE": "21.41",
      "PB": "3.06",
      "Div": "1.37"
  },
  {
      "Date": "17-Nov-16",
      "PE": "21.49",
      "PB": "3.06",
      "Div": "1.37"
  },
  {
      "Date": "16-Nov-16",
      "PE": "21.62",
      "PB": "3.07",
      "Div": "1.36"
  },
  {
      "Date": "15-Nov-16",
      "PE": "21.37",
      "PB": "3.07",
      "Div": "1.36"
  },
  {
      "Date": "11-Nov-16",
      "PE": "22.02",
      "PB": "3.14",
      "Div": "1.33"
  },
  {
      "Date": "10-Nov-16",
      "PE": "22.7",
      "PB": "3.23",
      "Div": "1.3"
  },
  {
      "Date": "9-Nov-16",
      "PE": "22.48",
      "PB": "3.19",
      "Div": "1.31"
  },
  {
      "Date": "8-Nov-16",
      "PE": "22.57",
      "PB": "3.24",
      "Div": "1.3"
  },
  {
      "Date": "7-Nov-16",
      "PE": "22.67",
      "PB": "3.22",
      "Div": "1.3"
  },
  {
      "Date": "4-Nov-16",
      "PE": "22.73",
      "PB": "3.19",
      "Div": "1.31"
  },
  {
      "Date": "3-Nov-16",
      "PE": "22.93",
      "PB": "3.21",
      "Div": "1.3"
  },
  {
      "Date": "2-Nov-16",
      "PE": "23.01",
      "PB": "3.23",
      "Div": "1.3"
  },
  {
      "Date": "1-Nov-16",
      "PE": "23.31",
      "PB": "3.27",
      "Div": "1.28"
  },
  {
      "Date": "30-Oct-16",
      "PE": "23.31",
      "PB": "3.27",
      "Div": "1.28"
  },
  {
      "Date": "28-Oct-16",
      "PE": "23.34",
      "PB": "3.27",
      "Div": "1.28"
  },
  {
      "Date": "27-Oct-16",
      "PE": "23.28",
      "PB": "3.26",
      "Div": "1.28"
  },
  {
      "Date": "26-Oct-16",
      "PE": "23.3",
      "PB": "3.26",
      "Div": "1.28"
  },
  {
      "Date": "25-Oct-16",
      "PE": "23.23",
      "PB": "3.29",
      "Div": "1.27"
  },
  {
      "Date": "24-Oct-16",
      "PE": "23.27",
      "PB": "3.3",
      "Div": "1.27"
  },
  {
      "Date": "21-Oct-16",
      "PE": "23.23",
      "PB": "3.29",
      "Div": "1.27"
  },
  {
      "Date": "20-Oct-16",
      "PE": "23.46",
      "PB": "3.3",
      "Div": "1.27"
  },
  {
      "Date": "19-Oct-16",
      "PE": "23.35",
      "PB": "3.28",
      "Div": "1.28"
  },
  {
      "Date": "18-Oct-16",
      "PE": "23.22",
      "PB": "3.29",
      "Div": "1.28"
  },
  {
      "Date": "17-Oct-16",
      "PE": "23",
      "PB": "3.23",
      "Div": "1.3"
  },
  {
      "Date": "14-Oct-16",
      "PE": "22.98",
      "PB": "3.25",
      "Div": "1.29"
  },
  {
      "Date": "13-Oct-16",
      "PE": "23.16",
      "PB": "3.25",
      "Div": "1.29"
  },
  {
      "Date": "10-Oct-16",
      "PE": "23.34",
      "PB": "3.3",
      "Div": "1.27"
  },
  {
      "Date": "7-Oct-16",
      "PE": "23.49",
      "PB": "3.29",
      "Div": "1.27"
  },
  {
      "Date": "6-Oct-16",
      "PE": "23.52",
      "PB": "3.3",
      "Div": "1.27"
  },
  {
      "Date": "5-Oct-16",
      "PE": "23.43",
      "PB": "3.32",
      "Div": "1.27"
  },
  {
      "Date": "4-Oct-16",
      "PE": "23.95",
      "PB": "3.33",
      "Div": "1.26"
  },
  {
      "Date": "3-Oct-16",
      "PE": "23.8",
      "PB": "3.31",
      "Div": "1.27"
  },
  {
      "Date": "30-Sep-16",
      "PE": "23.4",
      "PB": "3.27",
      "Div": "1.29"
  },
  {
      "Date": "29-Sep-16",
      "PE": "23.51",
      "PB": "3.26",
      "Div": "1.29"
  },
  {
      "Date": "28-Sep-16",
      "PE": "23.97",
      "PB": "3.32",
      "Div": "1.27"
  },
  {
      "Date": "27-Sep-16",
      "PE": "23.9",
      "PB": "3.3",
      "Div": "1.27"
  },
  {
      "Date": "26-Sep-16",
      "PE": "23.94",
      "PB": "3.31",
      "Div": "1.27"
  },
  {
      "Date": "23-Sep-16",
      "PE": "24.24",
      "PB": "3.35",
      "Div": "1.25"
  },
  {
      "Date": "22-Sep-16",
      "PE": "24.34",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "21-Sep-16",
      "PE": "24.11",
      "PB": "3.33",
      "Div": "1.26"
  },
  {
      "Date": "20-Sep-16",
      "PE": "24.06",
      "PB": "3.33",
      "Div": "1.26"
  },
  {
      "Date": "19-Sep-16",
      "PE": "24.15",
      "PB": "3.34",
      "Div": "1.25"
  },
  {
      "Date": "16-Sep-16",
      "PE": "24.07",
      "PB": "3.34",
      "Div": "1.26"
  },
  {
      "Date": "15-Sep-16",
      "PE": "23.97",
      "PB": "3.33",
      "Div": "1.23"
  },
  {
      "Date": "14-Sep-16",
      "PE": "23.92",
      "PB": "3.33",
      "Div": "1.23"
  },
  {
      "Date": "12-Sep-16",
      "PE": "23.89",
      "PB": "3.32",
      "Div": "1.23"
  },
  {
      "Date": "9-Sep-16",
      "PE": "24.31",
      "PB": "3.38",
      "Div": "1.21"
  },
  {
      "Date": "8-Sep-16",
      "PE": "24.54",
      "PB": "3.41",
      "Div": "1.2"
  },
  {
      "Date": "7-Sep-16",
      "PE": "24.45",
      "PB": "3.4",
      "Div": "1.2"
  },
  {
      "Date": "6-Sep-16",
      "PE": "24.52",
      "PB": "3.42",
      "Div": "1.2"
  },
  {
      "Date": "2-Sep-16",
      "PE": "24.15",
      "PB": "3.36",
      "Div": "1.22"
  },
  {
      "Date": "1-Sep-16",
      "PE": "24.06",
      "PB": "3.35",
      "Div": "1.22"
  },
  {
      "Date": "31-Aug-16",
      "PE": "24.09",
      "PB": "3.36",
      "Div": "1.22"
  },
  {
      "Date": "30-Aug-16",
      "PE": "23.97",
      "PB": "3.34",
      "Div": "1.22"
  },
  {
      "Date": "29-Aug-16",
      "PE": "23.6",
      "PB": "3.29",
      "Div": "1.24"
  },
  {
      "Date": "26-Aug-16",
      "PE": "23.5",
      "PB": "3.27",
      "Div": "1.25"
  },
  {
      "Date": "25-Aug-16",
      "PE": "23.62",
      "PB": "3.29",
      "Div": "1.26"
  },
  {
      "Date": "24-Aug-16",
      "PE": "23.78",
      "PB": "3.31",
      "Div": "1.25"
  },
  {
      "Date": "23-Aug-16",
      "PE": "23.73",
      "PB": "3.3",
      "Div": "1.25"
  },
  {
      "Date": "22-Aug-16",
      "PE": "23.59",
      "PB": "3.3",
      "Div": "1.25"
  },
  {
      "Date": "19-Aug-16",
      "PE": "23.7",
      "PB": "3.31",
      "Div": "1.25"
  },
  {
      "Date": "18-Aug-16",
      "PE": "23.71",
      "PB": "3.36",
      "Div": "1.24"
  },
  {
      "Date": "17-Aug-16",
      "PE": "23.58",
      "PB": "3.34",
      "Div": "1.25"
  },
  {
      "Date": "16-Aug-16",
      "PE": "23.63",
      "PB": "3.35",
      "Div": "1.25"
  },
  {
      "Date": "12-Aug-16",
      "PE": "23.71",
      "PB": "3.36",
      "Div": "1.24"
  },
  {
      "Date": "11-Aug-16",
      "PE": "23.49",
      "PB": "3.34",
      "Div": "1.26"
  },
  {
      "Date": "10-Aug-16",
      "PE": "23.44",
      "PB": "3.36",
      "Div": "1.28"
  },
  {
      "Date": "9-Aug-16",
      "PE": "23.73",
      "PB": "3.4",
      "Div": "1.26"
  },
  {
      "Date": "8-Aug-16",
      "PE": "23.82",
      "PB": "3.41",
      "Div": "1.26"
  },
  {
      "Date": "5-Aug-16",
      "PE": "23.74",
      "PB": "3.4",
      "Div": "1.26"
  },
  {
      "Date": "4-Aug-16",
      "PE": "23.38",
      "PB": "3.35",
      "Div": "1.28"
  },
  {
      "Date": "3-Aug-16",
      "PE": "23.36",
      "PB": "3.35",
      "Div": "1.28"
  },
  {
      "Date": "2-Aug-16",
      "PE": "23.58",
      "PB": "3.38",
      "Div": "1.27"
  },
  {
      "Date": "1-Aug-16",
      "PE": "23.61",
      "PB": "3.38",
      "Div": "1.27"
  },
  {
      "Date": "29-Jul-16",
      "PE": "23.62",
      "PB": "3.38",
      "Div": "1.27"
  },
  {
      "Date": "28-Jul-16",
      "PE": "23.69",
      "PB": "3.42",
      "Div": "1.26"
  },
  {
      "Date": "27-Jul-16",
      "PE": "23.63",
      "PB": "3.4",
      "Div": "1.27"
  },
  {
      "Date": "26-Jul-16",
      "PE": "23.56",
      "PB": "3.39",
      "Div": "1.28"
  },
  {
      "Date": "25-Jul-16",
      "PE": "23.69",
      "PB": "3.44",
      "Div": "1.23"
  },
  {
      "Date": "22-Jul-16",
      "PE": "23.43",
      "PB": "3.47",
      "Div": "1.21"
  },
  {
      "Date": "21-Jul-16",
      "PE": "23.34",
      "PB": "3.46",
      "Div": "1.22"
  },
  {
      "Date": "20-Jul-16",
      "PE": "23.49",
      "PB": "3.48",
      "Div": "1.21"
  },
  {
      "Date": "19-Jul-16",
      "PE": "23.39",
      "PB": "3.46",
      "Div": "1.22"
  },
  {
      "Date": "18-Jul-16",
      "PE": "23.34",
      "PB": "3.46",
      "Div": "1.22"
  },
  {
      "Date": "15-Jul-16",
      "PE": "23.43",
      "PB": "3.47",
      "Div": "1.21"
  },
  {
      "Date": "14-Jul-16",
      "PE": "23.49",
      "PB": "3.48",
      "Div": "1.21"
  },
  {
      "Date": "13-Jul-16",
      "PE": "23.37",
      "PB": "3.46",
      "Div": "1.22"
  },
  {
      "Date": "12-Jul-16",
      "PE": "23.37",
      "PB": "3.46",
      "Div": "1.22"
  },
  {
      "Date": "11-Jul-16",
      "PE": "23.25",
      "PB": "3.44",
      "Div": "1.23"
  },
  {
      "Date": "8-Jul-16",
      "PE": "22.85",
      "PB": "3.38",
      "Div": "1.25"
  },
  {
      "Date": "7-Jul-16",
      "PE": "22.89",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "5-Jul-16",
      "PE": "22.88",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "4-Jul-16",
      "PE": "22.98",
      "PB": "3.4",
      "Div": "1.24"
  },
  {
      "Date": "1-Jul-16",
      "PE": "22.86",
      "PB": "3.38",
      "Div": "1.25"
  },
  {
      "Date": "30-Jun-16",
      "PE": "22.75",
      "PB": "3.37",
      "Div": "1.25"
  },
  {
      "Date": "29-Jun-16",
      "PE": "22.52",
      "PB": "3.33",
      "Div": "1.26"
  },
  {
      "Date": "28-Jun-16",
      "PE": "22.31",
      "PB": "3.3",
      "Div": "1.28"
  },
  {
      "Date": "27-Jun-16",
      "PE": "22.22",
      "PB": "3.29",
      "Div": "1.28"
  },
  {
      "Date": "24-Jun-16",
      "PE": "22.2",
      "PB": "3.29",
      "Div": "1.28"
  },
  {
      "Date": "23-Jun-16",
      "PE": "22.7",
      "PB": "3.4",
      "Div": "1.26"
  },
  {
      "Date": "22-Jun-16",
      "PE": "22.52",
      "PB": "3.37",
      "Div": "1.27"
  },
  {
      "Date": "21-Jun-16",
      "PE": "22.56",
      "PB": "3.38",
      "Div": "1.27"
  },
  {
      "Date": "20-Jun-16",
      "PE": "22.62",
      "PB": "3.39",
      "Div": "1.27"
  },
  {
      "Date": "17-Jun-16",
      "PE": "22.43",
      "PB": "3.36",
      "Div": "1.28"
  },
  {
      "Date": "16-Jun-16",
      "PE": "22.35",
      "PB": "3.37",
      "Div": "1.28"
  },
  {
      "Date": "15-Jun-16",
      "PE": "22.53",
      "PB": "3.4",
      "Div": "1.27"
  },
  {
      "Date": "14-Jun-16",
      "PE": "22.26",
      "PB": "3.36",
      "Div": "1.29"
  },
  {
      "Date": "13-Jun-16",
      "PE": "22.26",
      "PB": "3.36",
      "Div": "1.29"
  },
  {
      "Date": "10-Jun-16",
      "PE": "22.43",
      "PB": "3.38",
      "Div": "1.28"
  },
  {
      "Date": "9-Jun-16",
      "PE": "22.52",
      "PB": "3.42",
      "Div": "1.31"
  },
  {
      "Date": "8-Jun-16",
      "PE": "22.71",
      "PB": "3.45",
      "Div": "1.3"
  },
  {
      "Date": "7-Jun-16",
      "PE": "22.69",
      "PB": "3.44",
      "Div": "1.3"
  },
  {
      "Date": "6-Jun-16",
      "PE": "22.71",
      "PB": "3.42",
      "Div": "1.31"
  },
  {
      "Date": "3-Jun-16",
      "PE": "22.77",
      "PB": "3.43",
      "Div": "1.31"
  },
  {
      "Date": "2-Jun-16",
      "PE": "22.76",
      "PB": "3.43",
      "Div": "1.31"
  },
  {
      "Date": "1-Jun-16",
      "PE": "22.65",
      "PB": "3.41",
      "Div": "1.31"
  },
  {
      "Date": "31-May-16",
      "PE": "22.6",
      "PB": "3.4",
      "Div": "1.32"
  },
  {
      "Date": "30-May-16",
      "PE": "22.69",
      "PB": "3.41",
      "Div": "1.31"
  },
  {
      "Date": "27-May-16",
      "PE": "22.66",
      "PB": "3.4",
      "Div": "1.32"
  },
  {
      "Date": "26-May-16",
      "PE": "22.39",
      "PB": "3.36",
      "Div": "1.33"
  },
  {
      "Date": "25-May-16",
      "PE": "22.07",
      "PB": "3.3",
      "Div": "1.36"
  },
  {
      "Date": "24-May-16",
      "PE": "21.16",
      "PB": "3.22",
      "Div": "1.39"
  },
  {
      "Date": "23-May-16",
      "PE": "21.13",
      "PB": "3.22",
      "Div": "1.39"
  },
  {
      "Date": "20-May-16",
      "PE": "21.19",
      "PB": "3.22",
      "Div": "1.39"
  },
  {
      "Date": "19-May-16",
      "PE": "21.33",
      "PB": "3.24",
      "Div": "1.38"
  },
  {
      "Date": "18-May-16",
      "PE": "21.57",
      "PB": "3.28",
      "Div": "1.37"
  },
  {
      "Date": "17-May-16",
      "PE": "21.62",
      "PB": "3.29",
      "Div": "1.36"
  },
  {
      "Date": "16-May-16",
      "PE": "21.54",
      "PB": "3.28",
      "Div": "1.37"
  },
  {
      "Date": "13-May-16",
      "PE": "21.1",
      "PB": "3.26",
      "Div": "1.38"
  },
  {
      "Date": "12-May-16",
      "PE": "21.33",
      "PB": "3.29",
      "Div": "1.36"
  },
  {
      "Date": "11-May-16",
      "PE": "21.19",
      "PB": "3.27",
      "Div": "1.37"
  },
  {
      "Date": "10-May-16",
      "PE": "21.3",
      "PB": "3.29",
      "Div": "1.36"
  },
  {
      "Date": "9-May-16",
      "PE": "21.24",
      "PB": "3.28",
      "Div": "1.37"
  },
  {
      "Date": "6-May-16",
      "PE": "20.91",
      "PB": "3.22",
      "Div": "1.39"
  },
  {
      "Date": "5-May-16",
      "PE": "20.96",
      "PB": "3.22",
      "Div": "1.39"
  },
  {
      "Date": "4-May-16",
      "PE": "20.88",
      "PB": "3.21",
      "Div": "1.39"
  },
  {
      "Date": "3-May-16",
      "PE": "21.08",
      "PB": "3.23",
      "Div": "1.39"
  },
  {
      "Date": "2-May-16",
      "PE": "21.25",
      "PB": "3.25",
      "Div": "1.38"
  },
  {
      "Date": "29-Apr-16",
      "PE": "21.24",
      "PB": "3.27",
      "Div": "1.37"
  },
  {
      "Date": "28-Apr-16",
      "PE": "21.3",
      "PB": "3.27",
      "Div": "1.37"
  },
  {
      "Date": "27-Apr-16",
      "PE": "21.66",
      "PB": "3.33",
      "Div": "1.35"
  },
  {
      "Date": "26-Apr-16",
      "PE": "21.6",
      "PB": "3.32",
      "Div": "1.35"
  },
  {
      "Date": "25-Apr-16",
      "PE": "21.4",
      "PB": "3.27",
      "Div": "1.37"
  },
  {
      "Date": "22-Apr-16",
      "PE": "21.55",
      "PB": "3.29",
      "Div": "1.36"
  },
  {
      "Date": "21-Apr-16",
      "PE": "21.59",
      "PB": "3.34",
      "Div": "1.41"
  },
  {
      "Date": "20-Apr-16",
      "PE": "21.59",
      "PB": "3.34",
      "Div": "1.41"
  },
  {
      "Date": "18-Apr-16",
      "PE": "21.68",
      "PB": "3.34",
      "Div": "1.41"
  },
  {
      "Date": "13-Apr-16",
      "PE": "21.56",
      "PB": "3.32",
      "Div": "1.42"
  },
  {
      "Date": "12-Apr-16",
      "PE": "21.17",
      "PB": "3.26",
      "Div": "1.45"
  },
  {
      "Date": "11-Apr-16",
      "PE": "21.07",
      "PB": "3.24",
      "Div": "1.45"
  },
  {
      "Date": "8-Apr-16",
      "PE": "20.75",
      "PB": "3.19",
      "Div": "1.48"
  },
  {
      "Date": "7-Apr-16",
      "PE": "20.73",
      "PB": "3.19",
      "Div": "1.48"
  },
  {
      "Date": "6-Apr-16",
      "PE": "20.91",
      "PB": "3.22",
      "Div": "1.47"
  },
  {
      "Date": "5-Apr-16",
      "PE": "20.88",
      "PB": "3.21",
      "Div": "1.47"
  },
  {
      "Date": "4-Apr-16",
      "PE": "21.31",
      "PB": "3.28",
      "Div": "1.44"
  },
  {
      "Date": "1-Apr-16",
      "PE": "21.19",
      "PB": "3.26",
      "Div": "1.45"
  },
  {
      "Date": "31-Mar-16",
      "PE": "20.89",
      "PB": "3.1",
      "Div": "1.49"
  },
  {
      "Date": "30-Mar-16",
      "PE": "20.88",
      "PB": "3.1",
      "Div": "1.49"
  },
  {
      "Date": "29-Mar-16",
      "PE": "20.5",
      "PB": "3.04",
      "Div": "1.52"
  },
  {
      "Date": "28-Mar-16",
      "PE": "20.55",
      "PB": "3.05",
      "Div": "1.52"
  },
  {
      "Date": "23-Mar-16",
      "PE": "20.83",
      "PB": "3.09",
      "Div": "1.5"
  },
  {
      "Date": "22-Mar-16",
      "PE": "20.82",
      "PB": "3.09",
      "Div": "1.5"
  },
  {
      "Date": "21-Mar-16",
      "PE": "20.79",
      "PB": "3.09",
      "Div": "1.5"
  },
  {
      "Date": "18-Mar-16",
      "PE": "20.52",
      "PB": "3.05",
      "Div": "1.52"
  },
  {
      "Date": "17-Mar-16",
      "PE": "20.28",
      "PB": "3.01",
      "Div": "1.54"
  },
  {
      "Date": "16-Mar-16",
      "PE": "20.24",
      "PB": "3",
      "Div": "1.55"
  },
  {
      "Date": "15-Mar-16",
      "PE": "20.14",
      "PB": "2.99",
      "Div": "1.55"
  },
  {
      "Date": "14-Mar-16",
      "PE": "20.35",
      "PB": "3.02",
      "Div": "1.54"
  },
  {
      "Date": "11-Mar-16",
      "PE": "20.27",
      "PB": "3.01",
      "Div": "1.54"
  },
  {
      "Date": "10-Mar-16",
      "PE": "20.2",
      "PB": "3",
      "Div": "1.55"
  },
  {
      "Date": "9-Mar-16",
      "PE": "20.33",
      "PB": "3.02",
      "Div": "1.54"
  },
  {
      "Date": "8-Mar-16",
      "PE": "20.2",
      "PB": "3",
      "Div": "1.55"
  },
  {
      "Date": "4-Mar-16",
      "PE": "20.24",
      "PB": "3.01",
      "Div": "1.55"
  },
  {
      "Date": "3-Mar-16",
      "PE": "20.22",
      "PB": "3",
      "Div": "1.55"
  },
  {
      "Date": "2-Mar-16",
      "PE": "19.93",
      "PB": "2.96",
      "Div": "1.57"
  },
  {
      "Date": "1-Mar-16",
      "PE": "19.53",
      "PB": "2.9",
      "Div": "1.6"
  },
  {
      "Date": "29-Feb-16",
      "PE": "18.9",
      "PB": "2.81",
      "Div": "1.66"
  },
  {
      "Date": "26-Feb-16",
      "PE": "19.01",
      "PB": "2.82",
      "Div": "1.65"
  },
  {
      "Date": "25-Feb-16",
      "PE": "18.85",
      "PB": "2.8",
      "Div": "1.66"
  },
  {
      "Date": "24-Feb-16",
      "PE": "18.98",
      "PB": "2.82",
      "Div": "1.65"
  },
  {
      "Date": "23-Feb-16",
      "PE": "19.23",
      "PB": "2.85",
      "Div": "1.63"
  },
  {
      "Date": "22-Feb-16",
      "PE": "19.56",
      "PB": "2.9",
      "Div": "1.6"
  },
  {
      "Date": "19-Feb-16",
      "PE": "19.5",
      "PB": "2.89",
      "Div": "1.6"
  },
  {
      "Date": "18-Feb-16",
      "PE": "19.44",
      "PB": "2.89",
      "Div": "1.61"
  },
  {
      "Date": "17-Feb-16",
      "PE": "19.18",
      "PB": "2.85",
      "Div": "1.63"
  },
  {
      "Date": "16-Feb-16",
      "PE": "19.01",
      "PB": "2.83",
      "Div": "1.64"
  },
  {
      "Date": "15-Feb-16",
      "PE": "19.32",
      "PB": "2.87",
      "Div": "1.61"
  },
  {
      "Date": "12-Feb-16",
      "PE": "18.67",
      "PB": "2.8",
      "Div": "1.66"
  },
  {
      "Date": "11-Feb-16",
      "PE": "18.65",
      "PB": "2.8",
      "Div": "1.66"
  },
  {
      "Date": "10-Feb-16",
      "PE": "19.27",
      "PB": "2.89",
      "Div": "1.6"
  },
  {
      "Date": "9-Feb-16",
      "PE": "19.46",
      "PB": "2.93",
      "Div": "1.58"
  },
  {
      "Date": "8-Feb-16",
      "PE": "19.69",
      "PB": "2.96",
      "Div": "1.57"
  },
  {
      "Date": "5-Feb-16",
      "PE": "19.99",
      "PB": "3",
      "Div": "1.54"
  },
  {
      "Date": "4-Feb-16",
      "PE": "19.76",
      "PB": "2.97",
      "Div": "1.56"
  },
  {
      "Date": "3-Feb-16",
      "PE": "19.65",
      "PB": "2.95",
      "Div": "1.57"
  },
  {
      "Date": "2-Feb-16",
      "PE": "19.9",
      "PB": "2.99",
      "Div": "1.55"
  },
  {
      "Date": "1-Feb-16",
      "PE": "20.22",
      "PB": "3.03",
      "Div": "1.53"
  },
  {
      "Date": "29-Jan-16",
      "PE": "20.22",
      "PB": "3.03",
      "Div": "1.53"
  },
  {
      "Date": "28-Jan-16",
      "PE": "19.85",
      "PB": "2.98",
      "Div": "1.56"
  },
  {
      "Date": "27-Jan-16",
      "PE": "20.05",
      "PB": "2.98",
      "Div": "1.55"
  },
  {
      "Date": "25-Jan-16",
      "PE": "20.04",
      "PB": "2.98",
      "Div": "1.56"
  },
  {
      "Date": "22-Jan-16",
      "PE": "20.01",
      "PB": "2.98",
      "Div": "1.56"
  },
  {
      "Date": "21-Jan-16",
      "PE": "19.65",
      "PB": "2.92",
      "Div": "1.59"
  },
  {
      "Date": "20-Jan-16",
      "PE": "19.74",
      "PB": "2.93",
      "Div": "1.58"
  },
  {
      "Date": "19-Jan-16",
      "PE": "20.08",
      "PB": "2.98",
      "Div": "1.56"
  },
  {
      "Date": "18-Jan-16",
      "PE": "19.86",
      "PB": "2.95",
      "Div": "1.57"
  },
  {
      "Date": "15-Jan-16",
      "PE": "20.12",
      "PB": "2.98",
      "Div": "1.55"
  },
  {
      "Date": "14-Jan-16",
      "PE": "20.38",
      "PB": "3.02",
      "Div": "1.53"
  },
  {
      "Date": "13-Jan-16",
      "PE": "20.45",
      "PB": "3.03",
      "Div": "1.53"
  },
  {
      "Date": "12-Jan-16",
      "PE": "20.31",
      "PB": "3.01",
      "Div": "1.54"
  },
  {
      "Date": "11-Jan-16",
      "PE": "20.46",
      "PB": "3.03",
      "Div": "1.53"
  },
  {
      "Date": "8-Jan-16",
      "PE": "20.56",
      "PB": "3.05",
      "Div": "1.52"
  },
  {
      "Date": "7-Jan-16",
      "PE": "20.47",
      "PB": "3.04",
      "Div": "1.53"
  },
  {
      "Date": "6-Jan-16",
      "PE": "20.93",
      "PB": "3.1",
      "Div": "1.49"
  },
  {
      "Date": "5-Jan-16",
      "PE": "21.05",
      "PB": "3.12",
      "Div": "1.49"
  },
  {
      "Date": "4-Jan-16",
      "PE": "21.07",
      "PB": "3.13",
      "Div": "1.48"
  },
  {
      "Date": "1-Jan-16",
      "PE": "21.53",
      "PB": "3.19",
      "Div": "1.45"
  },
  {
      "Date": "31-Dec-15",
      "PE": "21.49",
      "PB": "3.19",
      "Div": "1.46"
  },
  {
      "Date": "30-Dec-15",
      "PE": "21.35",
      "PB": "3.17",
      "Div": "1.46"
  },
  {
      "Date": "29-Dec-15",
      "PE": "21.44",
      "PB": "3.18",
      "Div": "1.46"
  },
  {
      "Date": "28-Dec-15",
      "PE": "21.43",
      "PB": "3.18",
      "Div": "1.46"
  },
  {
      "Date": "24-Dec-15",
      "PE": "21.26",
      "PB": "3.15",
      "Div": "1.47"
  },
  {
      "Date": "23-Dec-15",
      "PE": "21.27",
      "PB": "3.15",
      "Div": "1.47"
  },
  {
      "Date": "22-Dec-15",
      "PE": "21.06",
      "PB": "3.12",
      "Div": "1.49"
  },
  {
      "Date": "21-Dec-15",
      "PE": "21.19",
      "PB": "3.14",
      "Div": "1.48"
  },
  {
      "Date": "18-Dec-15",
      "PE": "20.99",
      "PB": "3.11",
      "Div": "1.49"
  },
  {
      "Date": "17-Dec-15",
      "PE": "21.21",
      "PB": "3.15",
      "Div": "1.47"
  },
  {
      "Date": "16-Dec-15",
      "PE": "20.96",
      "PB": "3.11",
      "Div": "1.49"
  },
  {
      "Date": "15-Dec-15",
      "PE": "20.82",
      "PB": "3.09",
      "Div": "1.5"
  },
  {
      "Date": "14-Dec-15",
      "PE": "20.69",
      "PB": "3.07",
      "Div": "1.51"
  },
  {
      "Date": "11-Dec-15",
      "PE": "20.57",
      "PB": "3.05",
      "Div": "1.52"
  },
  {
      "Date": "10-Dec-15",
      "PE": "20.77",
      "PB": "3.09",
      "Div": "1.48"
  },
  {
      "Date": "9-Dec-15",
      "PE": "20.58",
      "PB": "3.06",
      "Div": "1.5"
  },
  {
      "Date": "8-Dec-15",
      "PE": "20.82",
      "PB": "3.09",
      "Div": "1.48"
  },
  {
      "Date": "7-Dec-15",
      "PE": "20.99",
      "PB": "3.12",
      "Div": "1.47"
  },
  {
      "Date": "4-Dec-15",
      "PE": "21.04",
      "PB": "3.13",
      "Div": "1.46"
  },
  {
      "Date": "3-Dec-15",
      "PE": "21.26",
      "PB": "3.16",
      "Div": "1.45"
  },
  {
      "Date": "2-Dec-15",
      "PE": "21.44",
      "PB": "3.19",
      "Div": "1.44"
  },
  {
      "Date": "1-Dec-15",
      "PE": "21.51",
      "PB": "3.19",
      "Div": "1.43"
  },
  {
      "Date": "30-Nov-15",
      "PE": "21.45",
      "PB": "3.19",
      "Div": "1.43"
  },
  {
      "Date": "27-Nov-15",
      "PE": "21.47",
      "PB": "3.19",
      "Div": "1.43"
  },
  {
      "Date": "26-Nov-15",
      "PE": "21.31",
      "PB": "3.17",
      "Div": "1.44"
  },
  {
      "Date": "24-Nov-15",
      "PE": "21.17",
      "PB": "3.15",
      "Div": "1.45"
  },
  {
      "Date": "23-Nov-15",
      "PE": "21.22",
      "PB": "3.15",
      "Div": "1.45"
  },
  {
      "Date": "20-Nov-15",
      "PE": "21.24",
      "PB": "3.16",
      "Div": "1.45"
  },
  {
      "Date": "19-Nov-15",
      "PE": "21.2",
      "PB": "3.15",
      "Div": "1.45"
  },
  {
      "Date": "18-Nov-15",
      "PE": "20.9",
      "PB": "3.11",
      "Div": "1.47"
  },
  {
      "Date": "17-Nov-15",
      "PE": "21.19",
      "PB": "3.15",
      "Div": "1.45"
  },
  {
      "Date": "16-Nov-15",
      "PE": "21.11",
      "PB": "3.14",
      "Div": "1.46"
  },
  {
      "Date": "13-Nov-15",
      "PE": "20.97",
      "PB": "3.12",
      "Div": "1.47"
  },
  {
      "Date": "11-Nov-15",
      "PE": "21.17",
      "PB": "3.14",
      "Div": "1.45"
  },
  {
      "Date": "10-Nov-15",
      "PE": "21.05",
      "PB": "3.13",
      "Div": "1.46"
  },
  {
      "Date": "9-Nov-15",
      "PE": "21.41",
      "PB": "3.18",
      "Div": "1.44"
  },
  {
      "Date": "6-Nov-15",
      "PE": "21.57",
      "PB": "3.19",
      "Div": "1.43"
  },
  {
      "Date": "5-Nov-15",
      "PE": "21.58",
      "PB": "3.19",
      "Div": "1.43"
  },
  {
      "Date": "4-Nov-15",
      "PE": "21.87",
      "PB": "3.22",
      "Div": "1.42"
  },
  {
      "Date": "3-Nov-15",
      "PE": "22.02",
      "PB": "3.23",
      "Div": "1.41"
  },
  {
      "Date": "2-Nov-15",
      "PE": "21.98",
      "PB": "3.22",
      "Div": "1.42"
  },
  {
      "Date": "30-Oct-15",
      "PE": "22.06",
      "PB": "3.23",
      "Div": "1.41"
  },
  {
      "Date": "29-Oct-15",
      "PE": "22.19",
      "PB": "3.25",
      "Div": "1.41"
  },
  {
      "Date": "28-Oct-15",
      "PE": "22.2",
      "PB": "3.27",
      "Div": "1.4"
  },
  {
      "Date": "27-Oct-15",
      "PE": "22.39",
      "PB": "3.3",
      "Div": "1.39"
  },
  {
      "Date": "26-Oct-15",
      "PE": "22.45",
      "PB": "3.3",
      "Div": "1.38"
  },
  {
      "Date": "23-Oct-15",
      "PE": "22.55",
      "PB": "3.32",
      "Div": "1.38"
  },
  {
      "Date": "21-Oct-15",
      "PE": "22.48",
      "PB": "3.3",
      "Div": "1.38"
  },
  {
      "Date": "20-Oct-15",
      "PE": "22.51",
      "PB": "3.31",
      "Div": "1.38"
  },
  {
      "Date": "19-Oct-15",
      "PE": "22.61",
      "PB": "3.31",
      "Div": "1.38"
  },
  {
      "Date": "16-Oct-15",
      "PE": "22.51",
      "PB": "3.3",
      "Div": "1.38"
  },
  {
      "Date": "15-Oct-15",
      "PE": "22.35",
      "PB": "3.3",
      "Div": "1.39"
  },
  {
      "Date": "14-Oct-15",
      "PE": "22.16",
      "PB": "3.27",
      "Div": "1.4"
  },
  {
      "Date": "13-Oct-15",
      "PE": "22.25",
      "PB": "3.28",
      "Div": "1.4"
  },
  {
      "Date": "12-Oct-15",
      "PE": "22.73",
      "PB": "3.28",
      "Div": "1.39"
  },
  {
      "Date": "9-Oct-15",
      "PE": "22.88",
      "PB": "3.3",
      "Div": "1.39"
  },
  {
      "Date": "8-Oct-15",
      "PE": "22.71",
      "PB": "3.28",
      "Div": "1.4"
  },
  {
      "Date": "7-Oct-15",
      "PE": "22.84",
      "PB": "3.3",
      "Div": "1.39"
  },
  {
      "Date": "6-Oct-15",
      "PE": "22.78",
      "PB": "3.29",
      "Div": "1.39"
  },
  {
      "Date": "5-Oct-15",
      "PE": "22.68",
      "PB": "3.27",
      "Div": "1.4"
  },
  {
      "Date": "1-Oct-15",
      "PE": "22.21",
      "PB": "3.2",
      "Div": "1.43"
  },
  {
      "Date": "30-Sep-15",
      "PE": "22.21",
      "PB": "3.22",
      "Div": "1.46"
  },
  {
      "Date": "29-Sep-15",
      "PE": "21.91",
      "PB": "3.18",
      "Div": "1.48"
  },
  {
      "Date": "28-Sep-15",
      "PE": "21.78",
      "PB": "3.16",
      "Div": "1.48"
  },
  {
      "Date": "24-Sep-15",
      "PE": "21.81",
      "PB": "3.17",
      "Div": "1.5"
  },
  {
      "Date": "23-Sep-15",
      "PE": "21.75",
      "PB": "3.16",
      "Div": "1.51"
  },
  {
      "Date": "22-Sep-15",
      "PE": "21.65",
      "PB": "3.15",
      "Div": "1.51"
  },
  {
      "Date": "21-Sep-15",
      "PE": "22.11",
      "PB": "3.21",
      "Div": "1.48"
  },
  {
      "Date": "18-Sep-15",
      "PE": "22.12",
      "PB": "3.21",
      "Div": "1.48"
  },
  {
      "Date": "16-Sep-15",
      "PE": "21.89",
      "PB": "3.18",
      "Div": "1.5"
  },
  {
      "Date": "15-Sep-15",
      "PE": "21.7",
      "PB": "3.15",
      "Div": "1.51"
  },
  {
      "Date": "14-Sep-15",
      "PE": "21.82",
      "PB": "3.17",
      "Div": "1.5"
  },
  {
      "Date": "11-Sep-15",
      "PE": "21.59",
      "PB": "3.14",
      "Div": "1.52"
  },
  {
      "Date": "10-Sep-15",
      "PE": "21.59",
      "PB": "3.14",
      "Div": "1.52"
  },
  {
      "Date": "9-Sep-15",
      "PE": "21.67",
      "PB": "3.15",
      "Div": "1.51"
  },
  {
      "Date": "8-Sep-15",
      "PE": "21.31",
      "PB": "3.1",
      "Div": "1.54"
  },
  {
      "Date": "7-Sep-15",
      "PE": "20.95",
      "PB": "3.04",
      "Div": "1.56"
  },
  {
      "Date": "4-Sep-15",
      "PE": "21.21",
      "PB": "3.08",
      "Div": "1.54"
  },
  {
      "Date": "3-Sep-15",
      "PE": "21.68",
      "PB": "3.15",
      "Div": "1.51"
  },
  {
      "Date": "2-Sep-15",
      "PE": "21.38",
      "PB": "3.11",
      "Div": "1.53"
  },
  {
      "Date": "1-Sep-15",
      "PE": "21.57",
      "PB": "3.13",
      "Div": "1.52"
  },
  {
      "Date": "31-Aug-15",
      "PE": "22.08",
      "PB": "3.21",
      "Div": "1.48"
  },
  {
      "Date": "28-Aug-15",
      "PE": "22.17",
      "PB": "3.22",
      "Div": "1.48"
  },
  {
      "Date": "27-Aug-15",
      "PE": "22.02",
      "PB": "3.22",
      "Div": "1.48"
  },
  {
      "Date": "26-Aug-15",
      "PE": "21.59",
      "PB": "3.15",
      "Div": "1.51"
  },
  {
      "Date": "25-Aug-15",
      "PE": "21.83",
      "PB": "3.19",
      "Div": "1.5"
  },
  {
      "Date": "24-Aug-15",
      "PE": "21.63",
      "PB": "3.16",
      "Div": "1.51"
  },
  {
      "Date": "21-Aug-15",
      "PE": "22.99",
      "PB": "3.36",
      "Div": "1.42"
  },
  {
      "Date": "20-Aug-15",
      "PE": "23.19",
      "PB": "3.39",
      "Div": "1.44"
  },
  {
      "Date": "19-Aug-15",
      "PE": "23.53",
      "PB": "3.44",
      "Div": "1.42"
  },
  {
      "Date": "18-Aug-15",
      "PE": "23.45",
      "PB": "3.43",
      "Div": "1.43"
  },
  {
      "Date": "17-Aug-15",
      "PE": "23.48",
      "PB": "3.44",
      "Div": "1.43"
  },
  {
      "Date": "14-Aug-15",
      "PE": "23.69",
      "PB": "3.45",
      "Div": "1.42"
  },
  {
      "Date": "13-Aug-15",
      "PE": "23.16",
      "PB": "3.41",
      "Div": "1.44"
  },
  {
      "Date": "12-Aug-15",
      "PE": "23.1",
      "PB": "3.4",
      "Div": "1.44"
  },
  {
      "Date": "11-Aug-15",
      "PE": "23.38",
      "PB": "3.45",
      "Div": "1.42"
  },
  {
      "Date": "10-Aug-15",
      "PE": "23.54",
      "PB": "3.48",
      "Div": "1.41"
  },
  {
      "Date": "7-Aug-15",
      "PE": "23.61",
      "PB": "3.49",
      "Div": "1.4"
  },
  {
      "Date": "6-Aug-15",
      "PE": "23.67",
      "PB": "3.52",
      "Div": "1.4"
  },
  {
      "Date": "5-Aug-15",
      "PE": "23.62",
      "PB": "3.51",
      "Div": "1.41"
  },
  {
      "Date": "4-Aug-15",
      "PE": "23.48",
      "PB": "3.49",
      "Div": "1.42"
  },
  {
      "Date": "3-Aug-15",
      "PE": "23.54",
      "PB": "3.5",
      "Div": "1.41"
  },
  {
      "Date": "31-Jul-15",
      "PE": "23.53",
      "PB": "3.5",
      "Div": "1.41"
  },
  {
      "Date": "30-Jul-15",
      "PE": "23.23",
      "PB": "3.44",
      "Div": "1.45"
  },
  {
      "Date": "29-Jul-15",
      "PE": "23.08",
      "PB": "3.42",
      "Div": "1.45"
  },
  {
      "Date": "28-Jul-15",
      "PE": "23.02",
      "PB": "3.41",
      "Div": "1.46"
  },
  {
      "Date": "27-Jul-15",
      "PE": "23.21",
      "PB": "3.42",
      "Div": "1.46"
  },
  {
      "Date": "24-Jul-15",
      "PE": "23.65",
      "PB": "3.48",
      "Div": "1.43"
  },
  {
      "Date": "23-Jul-15",
      "PE": "23.84",
      "PB": "3.52",
      "Div": "1.42"
  },
  {
      "Date": "22-Jul-15",
      "PE": "23.96",
      "PB": "3.54",
      "Div": "1.41"
  },
  {
      "Date": "21-Jul-15",
      "PE": "23.68",
      "PB": "3.5",
      "Div": "1.43"
  },
  {
      "Date": "20-Jul-15",
      "PE": "23.88",
      "PB": "3.53",
      "Div": "1.42"
  },
  {
      "Date": "17-Jul-15",
      "PE": "23.87",
      "PB": "3.52",
      "Div": "1.42"
  },
  {
      "Date": "16-Jul-15",
      "PE": "23.86",
      "PB": "3.55",
      "Div": "1.41"
  },
  {
      "Date": "15-Jul-15",
      "PE": "23.63",
      "PB": "3.51",
      "Div": "1.43"
  },
  {
      "Date": "14-Jul-15",
      "PE": "23.44",
      "PB": "3.48",
      "Div": "1.44"
  },
  {
      "Date": "13-Jul-15",
      "PE": "23.45",
      "PB": "3.49",
      "Div": "1.44"
  },
  {
      "Date": "10-Jul-15",
      "PE": "23.17",
      "PB": "3.44",
      "Div": "1.45"
  },
  {
      "Date": "9-Jul-15",
      "PE": "23.08",
      "PB": "3.46",
      "Div": "1.45"
  },
  {
      "Date": "8-Jul-15",
      "PE": "23.18",
      "PB": "3.47",
      "Div": "1.45"
  },
  {
      "Date": "7-Jul-15",
      "PE": "23.59",
      "PB": "3.54",
      "Div": "1.42"
  },
  {
      "Date": "6-Jul-15",
      "PE": "23.62",
      "PB": "3.54",
      "Div": "1.42"
  },
  {
      "Date": "3-Jul-15",
      "PE": "23.52",
      "PB": "3.52",
      "Div": "1.43"
  },
  {
      "Date": "2-Jul-15",
      "PE": "23.4",
      "PB": "3.51",
      "Div": "1.42"
  },
  {
      "Date": "1-Jul-15",
      "PE": "23.43",
      "PB": "3.52",
      "Div": "1.42"
  },
  {
      "Date": "30-Jun-15",
      "PE": "23.19",
      "PB": "3.48",
      "Div": "1.44"
  },
  {
      "Date": "29-Jun-15",
      "PE": "23.05",
      "PB": "3.53",
      "Div": "1.43"
  },
  {
      "Date": "26-Jun-15",
      "PE": "23.23",
      "PB": "3.56",
      "Div": "1.42"
  },
  {
      "Date": "25-Jun-15",
      "PE": "23.27",
      "PB": "3.58",
      "Div": "1.41"
  },
  {
      "Date": "24-Jun-15",
      "PE": "23.17",
      "PB": "3.56",
      "Div": "1.42"
  },
  {
      "Date": "23-Jun-15",
      "PE": "23.23",
      "PB": "3.57",
      "Div": "1.41"
  },
  {
      "Date": "22-Jun-15",
      "PE": "23.15",
      "PB": "3.56",
      "Div": "1.42"
  },
  {
      "Date": "19-Jun-15",
      "PE": "22.79",
      "PB": "3.5",
      "Div": "1.44"
  },
  {
      "Date": "18-Jun-15",
      "PE": "22.66",
      "PB": "3.48",
      "Div": "1.45"
  },
  {
      "Date": "17-Jun-15",
      "PE": "22.43",
      "PB": "3.44",
      "Div": "1.47"
  },
  {
      "Date": "16-Jun-15",
      "PE": "22.3",
      "PB": "3.42",
      "Div": "1.48"
  },
  {
      "Date": "15-Jun-15",
      "PE": "22.21",
      "PB": "3.41",
      "Div": "1.48"
  },
  {
      "Date": "12-Jun-15",
      "PE": "22.12",
      "PB": "3.4",
      "Div": "1.49"
  },
  {
      "Date": "11-Jun-15",
      "PE": "22.08",
      "PB": "3.41",
      "Div": "1.48"
  },
  {
      "Date": "10-Jun-15",
      "PE": "22.52",
      "PB": "3.48",
      "Div": "1.45"
  },
  {
      "Date": "9-Jun-15",
      "PE": "22.23",
      "PB": "3.44",
      "Div": "1.47"
  },
  {
      "Date": "8-Jun-15",
      "PE": "22.29",
      "PB": "3.44",
      "Div": "1.47"
  },
  {
      "Date": "5-Jun-15",
      "PE": "22.49",
      "PB": "3.47",
      "Div": "1.45"
  },
  {
      "Date": "4-Jun-15",
      "PE": "22.53",
      "PB": "3.52",
      "Div": "1.45"
  },
  {
      "Date": "3-Jun-15",
      "PE": "22.41",
      "PB": "3.53",
      "Div": "1.45"
  },
  {
      "Date": "2-Jun-15",
      "PE": "22.71",
      "PB": "3.57",
      "Div": "1.43"
  },
  {
      "Date": "1-Jun-15",
      "PE": "23.25",
      "PB": "3.65",
      "Div": "1.39"
  },
  {
      "Date": "29-May-15",
      "PE": "23.12",
      "PB": "3.66",
      "Div": "1.39"
  },
  {
      "Date": "28-May-15",
      "PE": "22.61",
      "PB": "3.56",
      "Div": "1.42"
  },
  {
      "Date": "27-May-15",
      "PE": "22.6",
      "PB": "3.57",
      "Div": "1.42"
  },
  {
      "Date": "26-May-15",
      "PE": "22.55",
      "PB": "3.57",
      "Div": "1.42"
  },
  {
      "Date": "25-May-15",
      "PE": "22.64",
      "PB": "3.58",
      "Div": "1.41"
  },
  {
      "Date": "22-May-15",
      "PE": "22.94",
      "PB": "3.62",
      "Div": "1.4"
  },
  {
      "Date": "21-May-15",
      "PE": "22.67",
      "PB": "3.65",
      "Div": "1.4"
  },
  {
      "Date": "20-May-15",
      "PE": "22.69",
      "PB": "3.65",
      "Div": "1.4"
  },
  {
      "Date": "19-May-15",
      "PE": "22.55",
      "PB": "3.62",
      "Div": "1.41"
  },
  {
      "Date": "18-May-15",
      "PE": "22.57",
      "PB": "3.63",
      "Div": "1.41"
  },
  {
      "Date": "15-May-15",
      "PE": "22.24",
      "PB": "3.58",
      "Div": "1.43"
  },
  {
      "Date": "14-May-15",
      "PE": "22.14",
      "PB": "3.56",
      "Div": "1.44"
  },
  {
      "Date": "13-May-15",
      "PE": "22.17",
      "PB": "3.58",
      "Div": "1.43"
  },
  {
      "Date": "12-May-15",
      "PE": "21.92",
      "PB": "3.53",
      "Div": "1.45"
  },
  {
      "Date": "11-May-15",
      "PE": "22.41",
      "PB": "3.62",
      "Div": "1.42"
  },
  {
      "Date": "8-May-15",
      "PE": "21.92",
      "PB": "3.53",
      "Div": "1.45"
  },
  {
      "Date": "7-May-15",
      "PE": "21.61",
      "PB": "3.47",
      "Div": "1.47"
  },
  {
      "Date": "6-May-15",
      "PE": "21.71",
      "PB": "3.49",
      "Div": "1.47"
  },
  {
      "Date": "5-May-15",
      "PE": "22.45",
      "PB": "3.59",
      "Div": "1.43"
  },
  {
      "Date": "4-May-15",
      "PE": "22.47",
      "PB": "3.59",
      "Div": "1.43"
  },
  {
      "Date": "30-Apr-15",
      "PE": "22.07",
      "PB": "3.53",
      "Div": "1.45"
  },
  {
      "Date": "29-Apr-15",
      "PE": "22.29",
      "PB": "3.57",
      "Div": "1.4"
  },
  {
      "Date": "28-Apr-15",
      "PE": "22.46",
      "PB": "3.59",
      "Div": "1.39"
  },
  {
      "Date": "27-Apr-15",
      "PE": "22.26",
      "PB": "3.56",
      "Div": "1.4"
  },
  {
      "Date": "24-Apr-15",
      "PE": "22.34",
      "PB": "3.58",
      "Div": "1.4"
  },
  {
      "Date": "23-Apr-15",
      "PE": "22.5",
      "PB": "3.62",
      "Div": "1.3"
  },
  {
      "Date": "22-Apr-15",
      "PE": "22.59",
      "PB": "3.63",
      "Div": "1.29"
  },
  {
      "Date": "21-Apr-15",
      "PE": "22.44",
      "PB": "3.61",
      "Div": "1.3"
  },
  {
      "Date": "20-Apr-15",
      "PE": "22.64",
      "PB": "3.64",
      "Div": "1.29"
  },
  {
      "Date": "17-Apr-15",
      "PE": "23.06",
      "PB": "3.71",
      "Div": "1.27"
  },
  {
      "Date": "16-Apr-15",
      "PE": "23.33",
      "PB": "3.75",
      "Div": "1.25"
  },
  {
      "Date": "15-Apr-15",
      "PE": "23.43",
      "PB": "3.77",
      "Div": "1.25"
  },
  {
      "Date": "13-Apr-15",
      "PE": "23.66",
      "PB": "3.81",
      "Div": "1.23"
  },
  {
      "Date": "10-Apr-15",
      "PE": "23.51",
      "PB": "3.78",
      "Div": "1.24"
  },
  {
      "Date": "9-Apr-15",
      "PE": "23.5",
      "PB": "3.78",
      "Div": "1.24"
  },
  {
      "Date": "8-Apr-15",
      "PE": "23.33",
      "PB": "3.75",
      "Div": "1.25"
  },
  {
      "Date": "7-Apr-15",
      "PE": "23.19",
      "PB": "3.73",
      "Div": "1.25"
  },
  {
      "Date": "6-Apr-15",
      "PE": "23.18",
      "PB": "3.73",
      "Div": "1.26"
  },
  {
      "Date": "1-Apr-15",
      "PE": "22.95",
      "PB": "3.69",
      "Div": "1.27"
  },
  {
      "Date": "31-Mar-15",
      "PE": "22.7",
      "PB": "3.65",
      "Div": "1.28"
  },
  {
      "Date": "30-Mar-15",
      "PE": "22.7",
      "PB": "3.65",
      "Div": "1.28"
  },
  {
      "Date": "27-Mar-15",
      "PE": "22.3",
      "PB": "3.59",
      "Div": "1.3"
  },
  {
      "Date": "26-Mar-15",
      "PE": "22.36",
      "PB": "3.55",
      "Div": "1.32"
  },
  {
      "Date": "25-Mar-15",
      "PE": "22.87",
      "PB": "3.63",
      "Div": "1.29"
  },
  {
      "Date": "24-Mar-15",
      "PE": "22.9",
      "PB": "3.64",
      "Div": "1.29"
  },
  {
      "Date": "23-Mar-15",
      "PE": "22.92",
      "PB": "3.64",
      "Div": "1.28"
  },
  {
      "Date": "20-Mar-15",
      "PE": "22.98",
      "PB": "3.65",
      "Div": "1.28"
  },
  {
      "Date": "19-Mar-15",
      "PE": "23.15",
      "PB": "3.68",
      "Div": "1.27"
  },
  {
      "Date": "18-Mar-15",
      "PE": "23.29",
      "PB": "3.7",
      "Div": "1.26"
  },
  {
      "Date": "17-Mar-15",
      "PE": "23.39",
      "PB": "3.71",
      "Div": "1.26"
  },
  {
      "Date": "16-Mar-15",
      "PE": "23.14",
      "PB": "3.68",
      "Div": "1.27"
  },
  {
      "Date": "13-Mar-15",
      "PE": "23.18",
      "PB": "3.68",
      "Div": "1.27"
  },
  {
      "Date": "12-Mar-15",
      "PE": "23.53",
      "PB": "3.74",
      "Div": "1.25"
  },
  {
      "Date": "11-Mar-15",
      "PE": "23.32",
      "PB": "3.7",
      "Div": "1.26"
  },
  {
      "Date": "10-Mar-15",
      "PE": "23.36",
      "PB": "3.71",
      "Div": "1.26"
  },
  {
      "Date": "9-Mar-15",
      "PE": "23.48",
      "PB": "3.73",
      "Div": "1.25"
  },
  {
      "Date": "5-Mar-15",
      "PE": "23.9",
      "PB": "3.8",
      "Div": "1.23"
  },
  {
      "Date": "4-Mar-15",
      "PE": "23.86",
      "PB": "3.79",
      "Div": "1.23"
  },
  {
      "Date": "3-Mar-15",
      "PE": "24.06",
      "PB": "3.82",
      "Div": "1.22"
  },
  {
      "Date": "2-Mar-15",
      "PE": "23.95",
      "PB": "3.8",
      "Div": "1.23"
  },
  {
      "Date": "28-Feb-15",
      "PE": "23.8",
      "PB": "3.78",
      "Div": "1.24"
  },
  {
      "Date": "27-Feb-15",
      "PE": "23.65",
      "PB": "3.76",
      "Div": "1.24"
  },
  {
      "Date": "26-Feb-15",
      "PE": "23.22",
      "PB": "3.69",
      "Div": "1.27"
  },
  {
      "Date": "25-Feb-15",
      "PE": "23.44",
      "PB": "3.72",
      "Div": "1.26"
  },
  {
      "Date": "24-Feb-15",
      "PE": "23.43",
      "PB": "3.72",
      "Div": "1.26"
  },
  {
      "Date": "23-Feb-15",
      "PE": "23.41",
      "PB": "3.72",
      "Div": "1.26"
  },
  {
      "Date": "20-Feb-15",
      "PE": "23.62",
      "PB": "3.75",
      "Div": "1.25"
  },
  {
      "Date": "19-Feb-15",
      "PE": "23.76",
      "PB": "3.78",
      "Div": "1.24"
  },
  {
      "Date": "18-Feb-15",
      "PE": "23.28",
      "PB": "3.77",
      "Div": "1.24"
  },
  {
      "Date": "16-Feb-15",
      "PE": "23.22",
      "PB": "3.74",
      "Div": "1.25"
  },
  {
      "Date": "13-Feb-15",
      "PE": "23.31",
      "PB": "3.71",
      "Div": "1.2"
  },
  {
      "Date": "12-Feb-15",
      "PE": "23.07",
      "PB": "3.67",
      "Div": "1.21"
  },
  {
      "Date": "11-Feb-15",
      "PE": "22.85",
      "PB": "3.64",
      "Div": "1.22"
  },
  {
      "Date": "10-Feb-15",
      "PE": "22.69",
      "PB": "3.61",
      "Div": "1.23"
  },
  {
      "Date": "9-Feb-15",
      "PE": "22.59",
      "PB": "3.59",
      "Div": "1.24"
  },
  {
      "Date": "6-Feb-15",
      "PE": "22.44",
      "PB": "3.65",
      "Div": "1.22"
  },
  {
      "Date": "5-Feb-15",
      "PE": "22.57",
      "PB": "3.67",
      "Div": "1.21"
  },
  {
      "Date": "4-Feb-15",
      "PE": "22.53",
      "PB": "3.68",
      "Div": "1.21"
  },
  {
      "Date": "3-Feb-15",
      "PE": "22.42",
      "PB": "3.69",
      "Div": "1.21"
  },
  {
      "Date": "2-Feb-15",
      "PE": "22.51",
      "PB": "3.71",
      "Div": "1.2"
  },
  {
      "Date": "30-Jan-15",
      "PE": "22.48",
      "PB": "3.71",
      "Div": "1.2"
  },
  {
      "Date": "29-Jan-15",
      "PE": "22.84",
      "PB": "3.77",
      "Div": "1.18"
  },
  {
      "Date": "28-Jan-15",
      "PE": "22.67",
      "PB": "3.76",
      "Div": "1.18"
  },
  {
      "Date": "27-Jan-15",
      "PE": "22.67",
      "PB": "3.76",
      "Div": "1.18"
  },
  {
      "Date": "23-Jan-15",
      "PE": "22.48",
      "PB": "3.73",
      "Div": "1.19"
  },
  {
      "Date": "22-Jan-15",
      "PE": "22.25",
      "PB": "3.69",
      "Div": "1.2"
  },
  {
      "Date": "21-Jan-15",
      "PE": "22.21",
      "PB": "3.68",
      "Div": "1.21"
  },
  {
      "Date": "20-Jan-15",
      "PE": "22.16",
      "PB": "3.67",
      "Div": "1.21"
  },
  {
      "Date": "19-Jan-15",
      "PE": "21.8",
      "PB": "3.6",
      "Div": "1.23"
  },
  {
      "Date": "16-Jan-15",
      "PE": "21.75",
      "PB": "3.59",
      "Div": "1.24"
  },
  {
      "Date": "15-Jan-15",
      "PE": "21.7",
      "PB": "3.58",
      "Div": "1.24"
  },
  {
      "Date": "14-Jan-15",
      "PE": "21.14",
      "PB": "3.49",
      "Div": "1.27"
  },
  {
      "Date": "13-Jan-15",
      "PE": "21.21",
      "PB": "3.5",
      "Div": "1.27"
  },
  {
      "Date": "12-Jan-15",
      "PE": "21.27",
      "PB": "3.51",
      "Div": "1.27"
  },
  {
      "Date": "9-Jan-15",
      "PE": "21.16",
      "PB": "3.49",
      "Div": "1.27"
  },
  {
      "Date": "8-Jan-15",
      "PE": "21.04",
      "PB": "3.47",
      "Div": "1.28"
  },
  {
      "Date": "7-Jan-15",
      "PE": "20.7",
      "PB": "3.41",
      "Div": "1.3"
  },
  {
      "Date": "6-Jan-15",
      "PE": "20.76",
      "PB": "3.42",
      "Div": "1.3"
  },
  {
      "Date": "5-Jan-15",
      "PE": "21.4",
      "PB": "3.53",
      "Div": "1.26"
  },
  {
      "Date": "2-Jan-15",
      "PE": "21.45",
      "PB": "3.54",
      "Div": "1.26"
  },
  {
      "Date": "1-Jan-15",
      "PE": "21.16",
      "PB": "3.49",
      "Div": "1.27"
  },
  {
      "Date": "31-Dec-14",
      "PE": "21.16",
      "PB": "3.49",
      "Div": "1.27"
  },
  {
      "Date": "30-Dec-14",
      "PE": "21.07",
      "PB": "3.48",
      "Div": "1.28"
  },
  {
      "Date": "29-Dec-14",
      "PE": "21.07",
      "PB": "3.47",
      "Div": "1.28"
  },
  {
      "Date": "26-Dec-14",
      "PE": "20.95",
      "PB": "3.46",
      "Div": "1.29"
  },
  {
      "Date": "24-Dec-14",
      "PE": "20.88",
      "PB": "3.45",
      "Div": "1.29"
  },
  {
      "Date": "23-Dec-14",
      "PE": "21.12",
      "PB": "3.49",
      "Div": "1.28"
  },
  {
      "Date": "22-Dec-14",
      "PE": "21.27",
      "PB": "3.52",
      "Div": "1.27"
  },
  {
      "Date": "19-Dec-14",
      "PE": "21.01",
      "PB": "3.48",
      "Div": "1.29"
  },
  {
      "Date": "18-Dec-14",
      "PE": "20.85",
      "PB": "3.45",
      "Div": "1.3"
  },
  {
      "Date": "17-Dec-14",
      "PE": "20.51",
      "PB": "3.39",
      "Div": "1.32"
  },
  {
      "Date": "16-Dec-14",
      "PE": "20.61",
      "PB": "3.41",
      "Div": "1.31"
  },
  {
      "Date": "15-Dec-14",
      "PE": "21",
      "PB": "3.47",
      "Div": "1.29"
  },
  {
      "Date": "12-Dec-14",
      "PE": "21.01",
      "PB": "3.47",
      "Div": "1.29"
  },
  {
      "Date": "11-Dec-14",
      "PE": "21.19",
      "PB": "3.5",
      "Div": "1.27"
  },
  {
      "Date": "10-Dec-14",
      "PE": "21.35",
      "PB": "3.53",
      "Div": "1.27"
  },
  {
      "Date": "9-Dec-14",
      "PE": "21.31",
      "PB": "3.52",
      "Div": "1.27"
  },
  {
      "Date": "8-Dec-14",
      "PE": "21.56",
      "PB": "3.57",
      "Div": "1.25"
  },
  {
      "Date": "5-Dec-14",
      "PE": "21.81",
      "PB": "3.61",
      "Div": "1.24"
  },
  {
      "Date": "4-Dec-14",
      "PE": "21.87",
      "PB": "3.62",
      "Div": "1.23"
  },
  {
      "Date": "3-Dec-14",
      "PE": "21.81",
      "PB": "3.61",
      "Div": "1.24"
  },
  {
      "Date": "2-Dec-14",
      "PE": "21.77",
      "PB": "3.6",
      "Div": "1.24"
  },
  {
      "Date": "1-Dec-14",
      "PE": "21.85",
      "PB": "3.61",
      "Div": "1.24"
  },
  {
      "Date": "28-Nov-14",
      "PE": "21.94",
      "PB": "3.63",
      "Div": "1.23"
  },
  {
      "Date": "27-Nov-14",
      "PE": "21.7",
      "PB": "3.59",
      "Div": "1.25"
  },
  {
      "Date": "26-Nov-14",
      "PE": "21.65",
      "PB": "3.58",
      "Div": "1.25"
  },
  {
      "Date": "25-Nov-14",
      "PE": "21.61",
      "PB": "3.57",
      "Div": "1.25"
  },
  {
      "Date": "24-Nov-14",
      "PE": "21.78",
      "PB": "3.6",
      "Div": "1.24"
  },
  {
      "Date": "21-Nov-14",
      "PE": "21.7",
      "PB": "3.58",
      "Div": "1.25"
  },
  {
      "Date": "20-Nov-14",
      "PE": "21.51",
      "PB": "3.55",
      "Div": "1.26"
  },
  {
      "Date": "19-Nov-14",
      "PE": "21.33",
      "PB": "3.54",
      "Div": "1.26"
  },
  {
      "Date": "18-Nov-14",
      "PE": "21.47",
      "PB": "3.56",
      "Div": "1.26"
  },
  {
      "Date": "17-Nov-14",
      "PE": "21.45",
      "PB": "3.56",
      "Div": "1.25"
  },
  {
      "Date": "14-Nov-14",
      "PE": "21.35",
      "PB": "3.54",
      "Div": "1.26"
  },
  {
      "Date": "13-Nov-14",
      "PE": "21.26",
      "PB": "3.53",
      "Div": "1.27"
  },
  {
      "Date": "12-Nov-14",
      "PE": "21.33",
      "PB": "3.54",
      "Div": "1.26"
  },
  {
      "Date": "11-Nov-14",
      "PE": "21.29",
      "PB": "3.53",
      "Div": "1.27"
  },
  {
      "Date": "10-Nov-14",
      "PE": "21.24",
      "PB": "3.52",
      "Div": "1.27"
  },
  {
      "Date": "7-Nov-14",
      "PE": "21.31",
      "PB": "3.52",
      "Div": "1.27"
  },
  {
      "Date": "5-Nov-14",
      "PE": "21.54",
      "PB": "3.52",
      "Div": "1.27"
  },
  {
      "Date": "3-Nov-14",
      "PE": "21.58",
      "PB": "3.51",
      "Div": "1.27"
  },
  {
      "Date": "31-Oct-14",
      "PE": "21.58",
      "PB": "3.51",
      "Div": "1.27"
  },
  {
      "Date": "30-Oct-14",
      "PE": "21.16",
      "PB": "3.46",
      "Div": "1.29"
  },
  {
      "Date": "29-Oct-14",
      "PE": "20.92",
      "PB": "3.43",
      "Div": "1.31"
  },
  {
      "Date": "28-Oct-14",
      "PE": "20.75",
      "PB": "3.4",
      "Div": "1.32"
  },
  {
      "Date": "27-Oct-14",
      "PE": "20.64",
      "PB": "3.39",
      "Div": "1.32"
  },
  {
      "Date": "23-Oct-14",
      "PE": "20.7",
      "PB": "3.4",
      "Div": "1.32"
  },
  {
      "Date": "22-Oct-14",
      "PE": "20.65",
      "PB": "3.39",
      "Div": "1.32"
  },
  {
      "Date": "21-Oct-14",
      "PE": "20.53",
      "PB": "3.36",
      "Div": "1.33"
  },
  {
      "Date": "20-Oct-14",
      "PE": "20.47",
      "PB": "3.34",
      "Div": "1.34"
  },
  {
      "Date": "17-Oct-14",
      "PE": "20.21",
      "PB": "3.3",
      "Div": "1.36"
  },
  {
      "Date": "16-Oct-14",
      "PE": "20.13",
      "PB": "3.28",
      "Div": "1.36"
  },
  {
      "Date": "14-Oct-14",
      "PE": "20.41",
      "PB": "3.33",
      "Div": "1.34"
  },
  {
      "Date": "13-Oct-14",
      "PE": "20.47",
      "PB": "3.34",
      "Div": "1.34"
  },
  {
      "Date": "10-Oct-14",
      "PE": "20.55",
      "PB": "3.33",
      "Div": "1.35"
  },
  {
      "Date": "9-Oct-14",
      "PE": "20.81",
      "PB": "3.37",
      "Div": "1.33"
  },
  {
      "Date": "8-Oct-14",
      "PE": "20.5",
      "PB": "3.34",
      "Div": "1.34"
  },
  {
      "Date": "7-Oct-14",
      "PE": "20.53",
      "PB": "3.36",
      "Div": "1.34"
  },
  {
      "Date": "1-Oct-14",
      "PE": "20.77",
      "PB": "3.4",
      "Div": "1.32"
  },
  {
      "Date": "30-Sep-14",
      "PE": "20.82",
      "PB": "3.43",
      "Div": "1.31"
  },
  {
      "Date": "29-Sep-14",
      "PE": "20.81",
      "PB": "3.44",
      "Div": "1.32"
  },
  {
      "Date": "26-Sep-14",
      "PE": "20.83",
      "PB": "3.45",
      "Div": "1.31"
  },
  {
      "Date": "25-Sep-14",
      "PE": "20.68",
      "PB": "3.43",
      "Div": "1.33"
  },
  {
      "Date": "24-Sep-14",
      "PE": "20.92",
      "PB": "3.48",
      "Div": "1.31"
  },
  {
      "Date": "23-Sep-14",
      "PE": "20.96",
      "PB": "3.5",
      "Div": "1.27"
  },
  {
      "Date": "22-Sep-14",
      "PE": "21.3",
      "PB": "3.56",
      "Div": "1.25"
  },
  {
      "Date": "19-Sep-14",
      "PE": "21.23",
      "PB": "3.55",
      "Div": "1.25"
  },
  {
      "Date": "18-Sep-14",
      "PE": "21.58",
      "PB": "3.53",
      "Div": "1.25"
  },
  {
      "Date": "17-Sep-14",
      "PE": "21.21",
      "PB": "3.47",
      "Div": "1.27"
  },
  {
      "Date": "16-Sep-14",
      "PE": "21.09",
      "PB": "3.45",
      "Div": "1.28"
  },
  {
      "Date": "15-Sep-14",
      "PE": "21.38",
      "PB": "3.5",
      "Div": "1.26"
  },
  {
      "Date": "12-Sep-14",
      "PE": "21.55",
      "PB": "3.53",
      "Div": "1.25"
  },
  {
      "Date": "11-Sep-14",
      "PE": "21.5",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "10-Sep-14",
      "PE": "21.52",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "9-Sep-14",
      "PE": "21.68",
      "PB": "3.55",
      "Div": "1.24"
  },
  {
      "Date": "8-Sep-14",
      "PE": "21.73",
      "PB": "3.56",
      "Div": "1.24"
  },
  {
      "Date": "5-Sep-14",
      "PE": "21.15",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "4-Sep-14",
      "PE": "21.17",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "3-Sep-14",
      "PE": "21.22",
      "PB": "3.53",
      "Div": "1.25"
  },
  {
      "Date": "2-Sep-14",
      "PE": "21.14",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "1-Sep-14",
      "PE": "20.99",
      "PB": "3.49",
      "Div": "1.26"
  },
  {
      "Date": "28-Aug-14",
      "PE": "20.8",
      "PB": "3.46",
      "Div": "1.27"
  },
  {
      "Date": "27-Aug-14",
      "PE": "20.75",
      "PB": "3.45",
      "Div": "1.28"
  },
  {
      "Date": "26-Aug-14",
      "PE": "20.67",
      "PB": "3.44",
      "Div": "1.28"
  },
  {
      "Date": "25-Aug-14",
      "PE": "20.67",
      "PB": "3.44",
      "Div": "1.28"
  },
  {
      "Date": "22-Aug-14",
      "PE": "20.69",
      "PB": "3.44",
      "Div": "1.28"
  },
  {
      "Date": "21-Aug-14",
      "PE": "20.63",
      "PB": "3.43",
      "Div": "1.29"
  },
  {
      "Date": "20-Aug-14",
      "PE": "20.58",
      "PB": "3.43",
      "Div": "1.29"
  },
  {
      "Date": "19-Aug-14",
      "PE": "20.63",
      "PB": "3.44",
      "Div": "1.28"
  },
  {
      "Date": "18-Aug-14",
      "PE": "20.57",
      "PB": "3.43",
      "Div": "1.29"
  },
  {
      "Date": "14-Aug-14",
      "PE": "20.45",
      "PB": "3.39",
      "Div": "1.3"
  },
  {
      "Date": "13-Aug-14",
      "PE": "20.31",
      "PB": "3.37",
      "Div": "1.31"
  },
  {
      "Date": "12-Aug-14",
      "PE": "20.26",
      "PB": "3.36",
      "Div": "1.31"
  },
  {
      "Date": "11-Aug-14",
      "PE": "20",
      "PB": "3.32",
      "Div": "1.33"
  },
  {
      "Date": "8-Aug-14",
      "PE": "19.93",
      "PB": "3.29",
      "Div": "1.34"
  },
  {
      "Date": "7-Aug-14",
      "PE": "20.14",
      "PB": "3.35",
      "Div": "1.32"
  },
  {
      "Date": "6-Aug-14",
      "PE": "20.2",
      "PB": "3.4",
      "Div": "1.32"
  },
  {
      "Date": "5-Aug-14",
      "PE": "20.43",
      "PB": "3.48",
      "Div": "1.3"
  },
  {
      "Date": "4-Aug-14",
      "PE": "20.26",
      "PB": "3.46",
      "Div": "1.31"
  },
  {
      "Date": "1-Aug-14",
      "PE": "20.05",
      "PB": "3.42",
      "Div": "1.32"
  },
  {
      "Date": "31-Jul-14",
      "PE": "20.56",
      "PB": "3.48",
      "Div": "1.3"
  },
  {
      "Date": "30-Jul-14",
      "PE": "20.78",
      "PB": "3.54",
      "Div": "1.27"
  },
  {
      "Date": "28-Jul-14",
      "PE": "20.66",
      "PB": "3.53",
      "Div": "1.27"
  },
  {
      "Date": "25-Jul-14",
      "PE": "20.79",
      "PB": "3.55",
      "Div": "1.26"
  },
  {
      "Date": "24-Jul-14",
      "PE": "20.92",
      "PB": "3.57",
      "Div": "1.25"
  },
  {
      "Date": "23-Jul-14",
      "PE": "20.82",
      "PB": "3.56",
      "Div": "1.26"
  },
  {
      "Date": "22-Jul-14",
      "PE": "20.78",
      "PB": "3.54",
      "Div": "1.26"
  },
  {
      "Date": "21-Jul-14",
      "PE": "20.58",
      "PB": "3.51",
      "Div": "1.28"
  },
  {
      "Date": "18-Jul-14",
      "PE": "20.6",
      "PB": "3.5",
      "Div": "1.28"
  },
  {
      "Date": "17-Jul-14",
      "PE": "20.62",
      "PB": "3.49",
      "Div": "1.28"
  },
  {
      "Date": "16-Jul-14",
      "PE": "20.57",
      "PB": "3.48",
      "Div": "1.29"
  },
  {
      "Date": "15-Jul-14",
      "PE": "20.31",
      "PB": "3.44",
      "Div": "1.3"
  },
  {
      "Date": "14-Jul-14",
      "PE": "20.12",
      "PB": "3.41",
      "Div": "1.31"
  },
  {
      "Date": "11-Jul-14",
      "PE": "20.12",
      "PB": "3.41",
      "Div": "1.31"
  },
  {
      "Date": "10-Jul-14",
      "PE": "20.48",
      "PB": "3.46",
      "Div": "1.3"
  },
  {
      "Date": "9-Jul-14",
      "PE": "20.54",
      "PB": "3.46",
      "Div": "1.29"
  },
  {
      "Date": "8-Jul-14",
      "PE": "20.64",
      "PB": "3.48",
      "Div": "1.29"
  },
  {
      "Date": "7-Jul-14",
      "PE": "21.08",
      "PB": "3.56",
      "Div": "1.26"
  },
  {
      "Date": "4-Jul-14",
      "PE": "20.99",
      "PB": "3.54",
      "Div": "1.26"
  },
  {
      "Date": "3-Jul-14",
      "PE": "20.93",
      "PB": "3.52",
      "Div": "1.27"
  },
  {
      "Date": "2-Jul-14",
      "PE": "20.96",
      "PB": "3.53",
      "Div": "1.27"
  },
  {
      "Date": "1-Jul-14",
      "PE": "20.71",
      "PB": "3.49",
      "Div": "1.28"
  },
  {
      "Date": "30-Jun-14",
      "PE": "20.65",
      "PB": "3.48",
      "Div": "1.29"
  },
  {
      "Date": "27-Jun-14",
      "PE": "20.37",
      "PB": "3.43",
      "Div": "1.31"
  },
  {
      "Date": "26-Jun-14",
      "PE": "20.33",
      "PB": "3.42",
      "Div": "1.31"
  },
  {
      "Date": "25-Jun-14",
      "PE": "20.53",
      "PB": "3.46",
      "Div": "1.29"
  },
  {
      "Date": "24-Jun-14",
      "PE": "20.56",
      "PB": "3.46",
      "Div": "1.29"
  },
  {
      "Date": "23-Jun-14",
      "PE": "20.33",
      "PB": "3.42",
      "Div": "1.31"
  },
  {
      "Date": "20-Jun-14",
      "PE": "20.38",
      "PB": "3.43",
      "Div": "1.3"
  },
  {
      "Date": "19-Jun-14",
      "PE": "20.52",
      "PB": "3.44",
      "Div": "1.3"
  },
  {
      "Date": "18-Jun-14",
      "PE": "20.56",
      "PB": "3.45",
      "Div": "1.3"
  },
  {
      "Date": "17-Jun-14",
      "PE": "20.76",
      "PB": "3.48",
      "Div": "1.28"
  },
  {
      "Date": "16-Jun-14",
      "PE": "20.53",
      "PB": "3.52",
      "Div": "1.28"
  },
  {
      "Date": "13-Jun-14",
      "PE": "20.55",
      "PB": "3.52",
      "Div": "1.28"
  },
  {
      "Date": "12-Jun-14",
      "PE": "20.84",
      "PB": "3.58",
      "Div": "1.28"
  },
  {
      "Date": "11-Jun-14",
      "PE": "20.78",
      "PB": "3.56",
      "Div": "1.28"
  },
  {
      "Date": "10-Jun-14",
      "PE": "20.86",
      "PB": "3.62",
      "Div": "1.26"
  },
  {
      "Date": "9-Jun-14",
      "PE": "20.85",
      "PB": "3.62",
      "Div": "1.26"
  },
  {
      "Date": "6-Jun-14",
      "PE": "20.75",
      "PB": "3.6",
      "Div": "1.28"
  },
  {
      "Date": "5-Jun-14",
      "PE": "20.6",
      "PB": "3.55",
      "Div": "1.29"
  },
  {
      "Date": "4-Jun-14",
      "PE": "20.42",
      "PB": "3.52",
      "Div": "1.31"
  },
  {
      "Date": "3-Jun-14",
      "PE": "20.46",
      "PB": "3.52",
      "Div": "1.3"
  },
  {
      "Date": "2-Jun-14",
      "PE": "20.27",
      "PB": "3.5",
      "Div": "1.31"
  },
  {
      "Date": "30-May-14",
      "PE": "19.82",
      "PB": "3.43",
      "Div": "1.34"
  },
  {
      "Date": "29-May-14",
      "PE": "19.79",
      "PB": "3.44",
      "Div": "1.34"
  },
  {
      "Date": "28-May-14",
      "PE": "20.22",
      "PB": "3.48",
      "Div": "1.32"
  },
  {
      "Date": "27-May-14",
      "PE": "20.21",
      "PB": "3.48",
      "Div": "1.32"
  },
  {
      "Date": "26-May-14",
      "PE": "20.32",
      "PB": "3.49",
      "Div": "1.31"
  },
  {
      "Date": "23-May-14",
      "PE": "20.39",
      "PB": "3.5",
      "Div": "1.31"
  },
  {
      "Date": "22-May-14",
      "PE": "20.14",
      "PB": "3.46",
      "Div": "1.33"
  },
  {
      "Date": "21-May-14",
      "PE": "20.07",
      "PB": "3.44",
      "Div": "1.33"
  },
  {
      "Date": "20-May-14",
      "PE": "20.21",
      "PB": "3.46",
      "Div": "1.33"
  },
  {
      "Date": "19-May-14",
      "PE": "20.15",
      "PB": "3.45",
      "Div": "1.33"
  },
  {
      "Date": "16-May-14",
      "PE": "19.93",
      "PB": "3.42",
      "Div": "1.34"
  },
  {
      "Date": "15-May-14",
      "PE": "19.71",
      "PB": "3.38",
      "Div": "1.36"
  },
  {
      "Date": "14-May-14",
      "PE": "19.67",
      "PB": "3.38",
      "Div": "1.36"
  },
  {
      "Date": "13-May-14",
      "PE": "19.67",
      "PB": "3.38",
      "Div": "1.36"
  },
  {
      "Date": "12-May-14",
      "PE": "19.47",
      "PB": "3.33",
      "Div": "1.38"
  },
  {
      "Date": "9-May-14",
      "PE": "19.1",
      "PB": "3.26",
      "Div": "1.41"
  },
  {
      "Date": "8-May-14",
      "PE": "18.52",
      "PB": "3.17",
      "Div": "1.45"
  },
  {
      "Date": "7-May-14",
      "PE": "18.56",
      "PB": "3.18",
      "Div": "1.43"
  },
  {
      "Date": "6-May-14",
      "PE": "18.74",
      "PB": "3.21",
      "Div": "1.41"
  },
  {
      "Date": "5-May-14",
      "PE": "18.69",
      "PB": "3.21",
      "Div": "1.42"
  },
  {
      "Date": "2-May-14",
      "PE": "18.72",
      "PB": "3.2",
      "Div": "1.42"
  },
  {
      "Date": "30-Apr-14",
      "PE": "18.79",
      "PB": "3.23",
      "Div": "1.37"
  },
  {
      "Date": "29-Apr-14",
      "PE": "18.88",
      "PB": "3.24",
      "Div": "1.37"
  },
  {
      "Date": "28-Apr-14",
      "PE": "18.98",
      "PB": "3.26",
      "Div": "1.36"
  },
  {
      "Date": "25-Apr-14",
      "PE": "19.04",
      "PB": "3.27",
      "Div": "1.36"
  },
  {
      "Date": "23-Apr-14",
      "PE": "19.2",
      "PB": "3.3",
      "Div": "1.34"
  },
  {
      "Date": "22-Apr-14",
      "PE": "19.13",
      "PB": "3.29",
      "Div": "1.35"
  },
  {
      "Date": "21-Apr-14",
      "PE": "19.14",
      "PB": "3.29",
      "Div": "1.35"
  },
  {
      "Date": "17-Apr-14",
      "PE": "19.04",
      "PB": "3.27",
      "Div": "1.36"
  },
  {
      "Date": "16-Apr-14",
      "PE": "18.79",
      "PB": "3.22",
      "Div": "1.38"
  },
  {
      "Date": "15-Apr-14",
      "PE": "18.95",
      "PB": "3.25",
      "Div": "1.37"
  },
  {
      "Date": "11-Apr-14",
      "PE": "19.07",
      "PB": "3.27",
      "Div": "1.36"
  },
  {
      "Date": "10-Apr-14",
      "PE": "19.12",
      "PB": "3.28",
      "Div": "1.35"
  },
  {
      "Date": "9-Apr-14",
      "PE": "19.12",
      "PB": "3.28",
      "Div": "1.35"
  },
  {
      "Date": "7-Apr-14",
      "PE": "18.84",
      "PB": "3.23",
      "Div": "1.37"
  },
  {
      "Date": "4-Apr-14",
      "PE": "18.84",
      "PB": "3.23",
      "Div": "1.37"
  },
  {
      "Date": "3-Apr-14",
      "PE": "18.95",
      "PB": "3.25",
      "Div": "1.37"
  },
  {
      "Date": "2-Apr-14",
      "PE": "19",
      "PB": "3.26",
      "Div": "1.36"
  },
  {
      "Date": "1-Apr-14",
      "PE": "18.91",
      "PB": "3.24",
      "Div": "1.37"
  },
  {
      "Date": "31-Mar-14",
      "PE": "18.86",
      "PB": "3.23",
      "Div": "1.37"
  },
  {
      "Date": "28-Mar-14",
      "PE": "18.84",
      "PB": "3.23",
      "Div": "1.37"
  },
  {
      "Date": "27-Mar-14",
      "PE": "18.71",
      "PB": "3.14",
      "Div": "1.41"
  },
  {
      "Date": "26-Mar-14",
      "PE": "18.59",
      "PB": "3.12",
      "Div": "1.42"
  },
  {
      "Date": "25-Mar-14",
      "PE": "18.56",
      "PB": "3.12",
      "Div": "1.42"
  },
  {
      "Date": "24-Mar-14",
      "PE": "18.54",
      "PB": "3.12",
      "Div": "1.42"
  },
  {
      "Date": "22-Mar-14",
      "PE": "18.29",
      "PB": "3.07",
      "Div": "1.44"
  },
  {
      "Date": "21-Mar-14",
      "PE": "18.29",
      "PB": "3.07",
      "Div": "1.44"
  },
  {
      "Date": "20-Mar-14",
      "PE": "18.26",
      "PB": "3.07",
      "Div": "1.44"
  },
  {
      "Date": "19-Mar-14",
      "PE": "18.38",
      "PB": "3.09",
      "Div": "1.43"
  },
  {
      "Date": "18-Mar-14",
      "PE": "18.35",
      "PB": "3.08",
      "Div": "1.44"
  },
  {
      "Date": "14-Mar-14",
      "PE": "18.31",
      "PB": "3.08",
      "Div": "1.44"
  },
  {
      "Date": "13-Mar-14",
      "PE": "18.28",
      "PB": "3.07",
      "Div": "1.44"
  },
  {
      "Date": "12-Mar-14",
      "PE": "18.35",
      "PB": "3.08",
      "Div": "1.44"
  },
  {
      "Date": "11-Mar-14",
      "PE": "18.34",
      "PB": "3.08",
      "Div": "1.44"
  },
  {
      "Date": "10-Mar-14",
      "PE": "18.41",
      "PB": "3.09",
      "Div": "1.43"
  },
  {
      "Date": "7-Mar-14",
      "PE": "18.38",
      "PB": "3.09",
      "Div": "1.43"
  },
  {
      "Date": "6-Mar-14",
      "PE": "18.02",
      "PB": "3.03",
      "Div": "1.46"
  },
  {
      "Date": "5-Mar-14",
      "PE": "17.82",
      "PB": "2.99",
      "Div": "1.48"
  },
  {
      "Date": "4-Mar-14",
      "PE": "17.73",
      "PB": "2.98",
      "Div": "1.49"
  },
  {
      "Date": "3-Mar-14",
      "PE": "17.52",
      "PB": "2.94",
      "Div": "1.5"
  },
  {
      "Date": "28-Feb-14",
      "PE": "17.67",
      "PB": "2.97",
      "Div": "1.49"
  },
  {
      "Date": "26-Feb-14",
      "PE": "17.57",
      "PB": "2.95",
      "Div": "1.5"
  },
  {
      "Date": "25-Feb-14",
      "PE": "17.46",
      "PB": "2.93",
      "Div": "1.51"
  },
  {
      "Date": "24-Feb-14",
      "PE": "17.42",
      "PB": "2.93",
      "Div": "1.51"
  },
  {
      "Date": "21-Feb-14",
      "PE": "17.33",
      "PB": "2.91",
      "Div": "1.52"
  },
  {
      "Date": "20-Feb-14",
      "PE": "17.42",
      "PB": "2.88",
      "Div": "1.54"
  },
  {
      "Date": "19-Feb-14",
      "PE": "17.59",
      "PB": "2.91",
      "Div": "1.52"
  },
  {
      "Date": "18-Feb-14",
      "PE": "17.59",
      "PB": "2.9",
      "Div": "1.53"
  },
  {
      "Date": "17-Feb-14",
      "PE": "17.28",
      "PB": "2.87",
      "Div": "1.54"
  },
  {
      "Date": "14-Feb-14",
      "PE": "17.22",
      "PB": "2.87",
      "Div": "1.55"
  },
  {
      "Date": "13-Feb-14",
      "PE": "17.09",
      "PB": "2.84",
      "Div": "1.56"
  },
  {
      "Date": "12-Feb-14",
      "PE": "17.32",
      "PB": "2.88",
      "Div": "1.54"
  },
  {
      "Date": "11-Feb-14",
      "PE": "17.26",
      "PB": "2.87",
      "Div": "1.54"
  },
  {
      "Date": "10-Feb-14",
      "PE": "17.24",
      "PB": "2.87",
      "Div": "1.55"
  },
  {
      "Date": "7-Feb-14",
      "PE": "17.25",
      "PB": "2.87",
      "Div": "1.54"
  },
  {
      "Date": "6-Feb-14",
      "PE": "17.16",
      "PB": "2.86",
      "Div": "1.55"
  },
  {
      "Date": "5-Feb-14",
      "PE": "17.41",
      "PB": "2.85",
      "Div": "1.56"
  },
  {
      "Date": "4-Feb-14",
      "PE": "17.35",
      "PB": "2.84",
      "Div": "1.56"
  },
  {
      "Date": "3-Feb-14",
      "PE": "17.34",
      "PB": "2.84",
      "Div": "1.56"
  },
  {
      "Date": "31-Jan-14",
      "PE": "17.7",
      "PB": "2.88",
      "Div": "1.54"
  },
  {
      "Date": "30-Jan-14",
      "PE": "17.65",
      "PB": "2.87",
      "Div": "1.54"
  },
  {
      "Date": "29-Jan-14",
      "PE": "17.79",
      "PB": "2.9",
      "Div": "1.53"
  },
  {
      "Date": "28-Jan-14",
      "PE": "17.82",
      "PB": "2.9",
      "Div": "1.53"
  },
  {
      "Date": "27-Jan-14",
      "PE": "17.85",
      "PB": "2.9",
      "Div": "1.53"
  },
  {
      "Date": "24-Jan-14",
      "PE": "18.25",
      "PB": "2.97",
      "Div": "1.49"
  },
  {
      "Date": "23-Jan-14",
      "PE": "18.49",
      "PB": "3",
      "Div": "1.48"
  },
  {
      "Date": "22-Jan-14",
      "PE": "18.45",
      "PB": "3",
      "Div": "1.48"
  },
  {
      "Date": "21-Jan-14",
      "PE": "18.38",
      "PB": "2.99",
      "Div": "1.48"
  },
  {
      "Date": "20-Jan-14",
      "PE": "18.35",
      "PB": "2.99",
      "Div": "1.49"
  },
  {
      "Date": "17-Jan-14",
      "PE": "18.45",
      "PB": "2.97",
      "Div": "1.5"
  },
  {
      "Date": "16-Jan-14",
      "PE": "18.63",
      "PB": "3",
      "Div": "1.48"
  },
  {
      "Date": "15-Jan-14",
      "PE": "18.68",
      "PB": "3",
      "Div": "1.48"
  },
  {
      "Date": "14-Jan-14",
      "PE": "18.44",
      "PB": "2.96",
      "Div": "1.5"
  },
  {
      "Date": "13-Jan-14",
      "PE": "18.55",
      "PB": "2.98",
      "Div": "1.49"
  },
  {
      "Date": "10-Jan-14",
      "PE": "18.24",
      "PB": "2.93",
      "Div": "1.52"
  },
  {
      "Date": "9-Jan-14",
      "PE": "18.3",
      "PB": "2.93",
      "Div": "1.52"
  },
  {
      "Date": "8-Jan-14",
      "PE": "18.32",
      "PB": "2.93",
      "Div": "1.52"
  },
  {
      "Date": "7-Jan-14",
      "PE": "18.28",
      "PB": "2.92",
      "Div": "1.52"
  },
  {
      "Date": "6-Jan-14",
      "PE": "18.37",
      "PB": "2.94",
      "Div": "1.51"
  },
  {
      "Date": "3-Jan-14",
      "PE": "18.43",
      "PB": "2.95",
      "Div": "1.51"
  },
  {
      "Date": "2-Jan-14",
      "PE": "18.46",
      "PB": "2.95",
      "Div": "1.5"
  },
  {
      "Date": "1-Jan-14",
      "PE": "18.69",
      "PB": "2.99",
      "Div": "1.49"
  },
  {
      "Date": "31-Dec-13",
      "PE": "18.7",
      "PB": "2.99",
      "Div": "1.48"
  },
  {
      "Date": "30-Dec-13",
      "PE": "18.66",
      "PB": "2.99",
      "Div": "1.49"
  },
  {
      "Date": "27-Dec-13",
      "PE": "18.75",
      "PB": "3",
      "Div": "1.48"
  },
  {
      "Date": "26-Dec-13",
      "PE": "18.64",
      "PB": "2.98",
      "Div": "1.49"
  },
  {
      "Date": "24-Dec-13",
      "PE": "18.61",
      "PB": "2.98",
      "Div": "1.49"
  },
  {
      "Date": "23-Dec-13",
      "PE": "18.66",
      "PB": "2.98",
      "Div": "1.49"
  },
  {
      "Date": "20-Dec-13",
      "PE": "18.63",
      "PB": "2.98",
      "Div": "1.49"
  },
  {
      "Date": "19-Dec-13",
      "PE": "18.31",
      "PB": "2.93",
      "Div": "1.51"
  },
  {
      "Date": "18-Dec-13",
      "PE": "18.46",
      "PB": "2.95",
      "Div": "1.5"
  },
  {
      "Date": "17-Dec-13",
      "PE": "18.23",
      "PB": "2.91",
      "Div": "1.52"
  },
  {
      "Date": "16-Dec-13",
      "PE": "18.27",
      "PB": "2.92",
      "Div": "1.52"
  },
  {
      "Date": "13-Dec-13",
      "PE": "18.32",
      "PB": "2.93",
      "Div": "1.51"
  },
  {
      "Date": "12-Dec-13",
      "PE": "18.52",
      "PB": "2.96",
      "Div": "1.5"
  },
  {
      "Date": "11-Dec-13",
      "PE": "18.73",
      "PB": "3",
      "Div": "1.48"
  },
  {
      "Date": "10-Dec-13",
      "PE": "18.8",
      "PB": "3.01",
      "Div": "1.47"
  },
  {
      "Date": "9-Dec-13",
      "PE": "18.9",
      "PB": "3.02",
      "Div": "1.47"
  },
  {
      "Date": "6-Dec-13",
      "PE": "18.58",
      "PB": "2.97",
      "Div": "1.49"
  },
  {
      "Date": "5-Dec-13",
      "PE": "18.58",
      "PB": "2.96",
      "Div": "1.5"
  },
  {
      "Date": "4-Dec-13",
      "PE": "18.34",
      "PB": "2.92",
      "Div": "1.52"
  },
  {
      "Date": "3-Dec-13",
      "PE": "18.46",
      "PB": "2.94",
      "Div": "1.51"
  },
  {
      "Date": "2-Dec-13",
      "PE": "18.51",
      "PB": "2.95",
      "Div": "1.5"
  },
  {
      "Date": "29-Nov-13",
      "PE": "18.38",
      "PB": "2.93",
      "Div": "1.51"
  },
  {
      "Date": "28-Nov-13",
      "PE": "18.13",
      "PB": "2.89",
      "Div": "1.53"
  },
  {
      "Date": "27-Nov-13",
      "PE": "18.03",
      "PB": "2.88",
      "Div": "1.54"
  },
  {
      "Date": "26-Nov-13",
      "PE": "17.94",
      "PB": "2.88",
      "Div": "1.53"
  },
  {
      "Date": "25-Nov-13",
      "PE": "18.06",
      "PB": "2.9",
      "Div": "1.52"
  },
  {
      "Date": "22-Nov-13",
      "PE": "17.71",
      "PB": "2.85",
      "Div": "1.55"
  },
  {
      "Date": "21-Nov-13",
      "PE": "17.26",
      "PB": "2.85",
      "Div": "1.54"
  },
  {
      "Date": "20-Nov-13",
      "PE": "17.61",
      "PB": "2.91",
      "Div": "1.51"
  },
  {
      "Date": "19-Nov-13",
      "PE": "17.85",
      "PB": "2.95",
      "Div": "1.49"
  },
  {
      "Date": "18-Nov-13",
      "PE": "17.76",
      "PB": "2.95",
      "Div": "1.49"
  },
  {
      "Date": "14-Nov-13",
      "PE": "17.42",
      "PB": "2.88",
      "Div": "1.52"
  },
  {
      "Date": "13-Nov-13",
      "PE": "17.23",
      "PB": "2.85",
      "Div": "1.54"
  },
  {
      "Date": "12-Nov-13",
      "PE": "17.31",
      "PB": "2.86",
      "Div": "1.53"
  },
  {
      "Date": "11-Nov-13",
      "PE": "17.48",
      "PB": "2.89",
      "Div": "1.52"
  },
  {
      "Date": "8-Nov-13",
      "PE": "17.65",
      "PB": "2.92",
      "Div": "1.5"
  },
  {
      "Date": "7-Nov-13",
      "PE": "17.78",
      "PB": "2.94",
      "Div": "1.49"
  },
  {
      "Date": "6-Nov-13",
      "PE": "17.92",
      "PB": "2.95",
      "Div": "1.48"
  },
  {
      "Date": "5-Nov-13",
      "PE": "18.06",
      "PB": "2.97",
      "Div": "1.47"
  },
  {
      "Date": "3-Nov-13",
      "PE": "18.23",
      "PB": "3",
      "Div": "1.46"
  },
  {
      "Date": "1-Nov-13",
      "PE": "18.2",
      "PB": "3",
      "Div": "1.46"
  },
  {
      "Date": "31-Oct-13",
      "PE": "18.18",
      "PB": "2.99",
      "Div": "1.46"
  },
  {
      "Date": "30-Oct-13",
      "PE": "18.04",
      "PB": "2.97",
      "Div": "1.47"
  },
  {
      "Date": "29-Oct-13",
      "PE": "17.95",
      "PB": "2.95",
      "Div": "1.48"
  },
  {
      "Date": "28-Oct-13",
      "PE": "17.61",
      "PB": "2.9",
      "Div": "1.51"
  },
  {
      "Date": "25-Oct-13",
      "PE": "17.73",
      "PB": "2.92",
      "Div": "1.5"
  },
  {
      "Date": "24-Oct-13",
      "PE": "17.79",
      "PB": "2.93",
      "Div": "1.5"
  },
  {
      "Date": "23-Oct-13",
      "PE": "17.83",
      "PB": "2.93",
      "Div": "1.49"
  },
  {
      "Date": "22-Oct-13",
      "PE": "18.01",
      "PB": "2.95",
      "Div": "1.49"
  },
  {
      "Date": "21-Oct-13",
      "PE": "18.07",
      "PB": "2.96",
      "Div": "1.49"
  },
  {
      "Date": "18-Oct-13",
      "PE": "18.02",
      "PB": "2.95",
      "Div": "1.49"
  },
  {
      "Date": "17-Oct-13",
      "PE": "17.63",
      "PB": "2.88",
      "Div": "1.53"
  },
  {
      "Date": "15-Oct-13",
      "PE": "17.85",
      "PB": "2.9",
      "Div": "1.52"
  },
  {
      "Date": "14-Oct-13",
      "PE": "17.94",
      "PB": "2.91",
      "Div": "1.51"
  },
  {
      "Date": "11-Oct-13",
      "PE": "17.88",
      "PB": "2.9",
      "Div": "1.52"
  },
  {
      "Date": "10-Oct-13",
      "PE": "17.66",
      "PB": "2.86",
      "Div": "1.53"
  },
  {
      "Date": "9-Oct-13",
      "PE": "17.62",
      "PB": "2.86",
      "Div": "1.54"
  },
  {
      "Date": "8-Oct-13",
      "PE": "17.38",
      "PB": "2.82",
      "Div": "1.56"
  },
  {
      "Date": "7-Oct-13",
      "PE": "17.32",
      "PB": "2.81",
      "Div": "1.56"
  },
  {
      "Date": "4-Oct-13",
      "PE": "17.32",
      "PB": "2.81",
      "Div": "1.56"
  },
  {
      "Date": "3-Oct-13",
      "PE": "17.33",
      "PB": "2.82",
      "Div": "1.54"
  },
  {
      "Date": "1-Oct-13",
      "PE": "16.95",
      "PB": "2.78",
      "Div": "1.56"
  },
  {
      "Date": "30-Sep-13",
      "PE": "16.82",
      "PB": "2.77",
      "Div": "1.56"
  },
  {
      "Date": "27-Sep-13",
      "PE": "17.11",
      "PB": "2.82",
      "Div": "1.56"
  },
  {
      "Date": "26-Sep-13",
      "PE": "17.1",
      "PB": "2.79",
      "Div": "1.55"
  },
  {
      "Date": "25-Sep-13",
      "PE": "17.07",
      "PB": "2.79",
      "Div": "1.55"
  },
  {
      "Date": "24-Sep-13",
      "PE": "17.13",
      "PB": "2.79",
      "Div": "1.51"
  },
  {
      "Date": "23-Sep-13",
      "PE": "17.12",
      "PB": "2.82",
      "Div": "1.5"
  },
  {
      "Date": "20-Sep-13",
      "PE": "17.47",
      "PB": "2.88",
      "Div": "1.47"
  },
  {
      "Date": "19-Sep-13",
      "PE": "17.77",
      "PB": "2.95",
      "Div": "1.42"
  },
  {
      "Date": "18-Sep-13",
      "PE": "17.15",
      "PB": "2.86",
      "Div": "1.49"
  },
  {
      "Date": "17-Sep-13",
      "PE": "17",
      "PB": "2.84",
      "Div": "1.45"
  },
  {
      "Date": "16-Sep-13",
      "PE": "16.98",
      "PB": "2.85",
      "Div": "1.45"
  },
  {
      "Date": "13-Sep-13",
      "PE": "17",
      "PB": "2.87",
      "Div": "1.44"
  },
  {
      "Date": "12-Sep-13",
      "PE": "17",
      "PB": "2.89",
      "Div": "1.44"
  },
  {
      "Date": "11-Sep-13",
      "PE": "17.19",
      "PB": "2.96",
      "Div": "1.41"
  },
  {
      "Date": "10-Sep-13",
      "PE": "17.14",
      "PB": "2.95",
      "Div": "1.42"
  },
  {
      "Date": "6-Sep-13",
      "PE": "16.37",
      "PB": "2.81",
      "Div": "1.48"
  },
  {
      "Date": "5-Sep-13",
      "PE": "16.12",
      "PB": "2.77",
      "Div": "1.51"
  },
  {
      "Date": "4-Sep-13",
      "PE": "15.7",
      "PB": "2.7",
      "Div": "1.55"
  },
  {
      "Date": "3-Sep-13",
      "PE": "15.4",
      "PB": "2.65",
      "Div": "1.58"
  },
  {
      "Date": "2-Sep-13",
      "PE": "16",
      "PB": "2.75",
      "Div": "1.52"
  },
  {
      "Date": "30-Aug-13",
      "PE": "15.77",
      "PB": "2.71",
      "Div": "1.54"
  },
  {
      "Date": "29-Aug-13",
      "PE": "15.59",
      "PB": "2.68",
      "Div": "1.56"
  },
  {
      "Date": "28-Aug-13",
      "PE": "15.23",
      "PB": "2.62",
      "Div": "1.6"
  },
  {
      "Date": "27-Aug-13",
      "PE": "15.24",
      "PB": "2.62",
      "Div": "1.59"
  },
  {
      "Date": "26-Aug-13",
      "PE": "15.8",
      "PB": "2.71",
      "Div": "1.54"
  },
  {
      "Date": "23-Aug-13",
      "PE": "15.79",
      "PB": "2.71",
      "Div": "1.54"
  },
  {
      "Date": "22-Aug-13",
      "PE": "15.6",
      "PB": "2.68",
      "Div": "1.56"
  },
  {
      "Date": "21-Aug-13",
      "PE": "15.3",
      "PB": "2.63",
      "Div": "1.59"
  },
  {
      "Date": "20-Aug-13",
      "PE": "15.58",
      "PB": "2.68",
      "Div": "1.56"
  },
  {
      "Date": "19-Aug-13",
      "PE": "15.62",
      "PB": "2.68",
      "Div": "1.56"
  },
  {
      "Date": "16-Aug-13",
      "PE": "16.35",
      "PB": "2.73",
      "Div": "1.53"
  },
  {
      "Date": "14-Aug-13",
      "PE": "16.97",
      "PB": "2.84",
      "Div": "1.47"
  },
  {
      "Date": "13-Aug-13",
      "PE": "16.84",
      "PB": "2.82",
      "Div": "1.48"
  },
  {
      "Date": "12-Aug-13",
      "PE": "16.59",
      "PB": "2.78",
      "Div": "1.5"
  },
  {
      "Date": "8-Aug-13",
      "PE": "16.5",
      "PB": "2.76",
      "Div": "1.51"
  },
  {
      "Date": "7-Aug-13",
      "PE": "16.36",
      "PB": "2.73",
      "Div": "1.53"
  },
  {
      "Date": "6-Aug-13",
      "PE": "16.51",
      "PB": "2.75",
      "Div": "1.52"
  },
  {
      "Date": "5-Aug-13",
      "PE": "16.94",
      "PB": "2.82",
      "Div": "1.48"
  },
  {
      "Date": "2-Aug-13",
      "PE": "16.88",
      "PB": "2.81",
      "Div": "1.48"
  },
  {
      "Date": "1-Aug-13",
      "PE": "17.03",
      "PB": "2.84",
      "Div": "1.47"
  },
  {
      "Date": "31-Jul-13",
      "PE": "17.05",
      "PB": "2.84",
      "Div": "1.47"
  },
  {
      "Date": "30-Jul-13",
      "PE": "17.11",
      "PB": "2.85",
      "Div": "1.46"
  },
  {
      "Date": "29-Jul-13",
      "PE": "17.31",
      "PB": "2.93",
      "Div": "1.44"
  },
  {
      "Date": "26-Jul-13",
      "PE": "17.58",
      "PB": "3.01",
      "Div": "1.4"
  },
  {
      "Date": "25-Jul-13",
      "PE": "17.68",
      "PB": "3.02",
      "Div": "1.4"
  },
  {
      "Date": "24-Jul-13",
      "PE": "18.03",
      "PB": "3.07",
      "Div": "1.38"
  },
  {
      "Date": "23-Jul-13",
      "PE": "18.32",
      "PB": "3.11",
      "Div": "1.36"
  },
  {
      "Date": "22-Jul-13",
      "PE": "18.23",
      "PB": "3.09",
      "Div": "1.37"
  },
  {
      "Date": "19-Jul-13",
      "PE": "18.3",
      "PB": "3.09",
      "Div": "1.37"
  },
  {
      "Date": "18-Jul-13",
      "PE": "18.32",
      "PB": "3.09",
      "Div": "1.37"
  },
  {
      "Date": "17-Jul-13",
      "PE": "18.18",
      "PB": "3.06",
      "Div": "1.38"
  },
  {
      "Date": "16-Jul-13",
      "PE": "18.13",
      "PB": "3.05",
      "Div": "1.39"
  },
  {
      "Date": "15-Jul-13",
      "PE": "18.36",
      "PB": "3.09",
      "Div": "1.37"
  },
  {
      "Date": "12-Jul-13",
      "PE": "18.31",
      "PB": "3.08",
      "Div": "1.38"
  },
  {
      "Date": "11-Jul-13",
      "PE": "18.09",
      "PB": "3.04",
      "Div": "1.39"
  },
  {
      "Date": "10-Jul-13",
      "PE": "17.73",
      "PB": "2.98",
      "Div": "1.42"
  },
  {
      "Date": "9-Jul-13",
      "PE": "17.85",
      "PB": "3",
      "Div": "1.41"
  },
  {
      "Date": "8-Jul-13",
      "PE": "17.71",
      "PB": "2.98",
      "Div": "1.42"
  },
  {
      "Date": "5-Jul-13",
      "PE": "17.87",
      "PB": "3",
      "Div": "1.41"
  },
  {
      "Date": "4-Jul-13",
      "PE": "17.77",
      "PB": "2.99",
      "Div": "1.42"
  },
  {
      "Date": "3-Jul-13",
      "PE": "17.57",
      "PB": "2.98",
      "Div": "1.42"
  },
  {
      "Date": "2-Jul-13",
      "PE": "17.84",
      "PB": "3.05",
      "Div": "1.41"
  },
  {
      "Date": "1-Jul-13",
      "PE": "17.96",
      "PB": "3.07",
      "Div": "1.4"
  },
  {
      "Date": "28-Jun-13",
      "PE": "17.76",
      "PB": "3.04",
      "Div": "1.43"
  },
  {
      "Date": "27-Jun-13",
      "PE": "17.28",
      "PB": "2.98",
      "Div": "1.46"
  },
  {
      "Date": "26-Jun-13",
      "PE": "16.96",
      "PB": "2.95",
      "Div": "1.48"
  },
  {
      "Date": "25-Jun-13",
      "PE": "17.03",
      "PB": "2.96",
      "Div": "1.48"
  },
  {
      "Date": "24-Jun-13",
      "PE": "16.97",
      "PB": "2.95",
      "Div": "1.48"
  },
  {
      "Date": "21-Jun-13",
      "PE": "17.2",
      "PB": "2.99",
      "Div": "1.46"
  },
  {
      "Date": "20-Jun-13",
      "PE": "17.17",
      "PB": "2.99",
      "Div": "1.47"
  },
  {
      "Date": "19-Jun-13",
      "PE": "17.68",
      "PB": "3.07",
      "Div": "1.42"
  },
  {
      "Date": "18-Jun-13",
      "PE": "17.64",
      "PB": "3.07",
      "Div": "1.43"
  },
  {
      "Date": "17-Jun-13",
      "PE": "17.75",
      "PB": "3.09",
      "Div": "1.42"
  },
  {
      "Date": "14-Jun-13",
      "PE": "17.53",
      "PB": "3.07",
      "Div": "1.43"
  },
  {
      "Date": "13-Jun-13",
      "PE": "17.2",
      "PB": "3.01",
      "Div": "1.45"
  },
  {
      "Date": "12-Jun-13",
      "PE": "17.38",
      "PB": "3.04",
      "Div": "1.44"
  },
  {
      "Date": "11-Jun-13",
      "PE": "17.47",
      "PB": "3.06",
      "Div": "1.43"
  },
  {
      "Date": "10-Jun-13",
      "PE": "17.73",
      "PB": "3.1",
      "Div": "1.41"
  },
  {
      "Date": "7-Jun-13",
      "PE": "17.76",
      "PB": "3.1",
      "Div": "1.41"
  },
  {
      "Date": "6-Jun-13",
      "PE": "17.89",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "5-Jun-13",
      "PE": "17.77",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "4-Jun-13",
      "PE": "17.75",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "3-Jun-13",
      "PE": "17.81",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "31-May-13",
      "PE": "17.95",
      "PB": "3.16",
      "Div": "1.39"
  },
  {
      "Date": "30-May-13",
      "PE": "18.37",
      "PB": "3.23",
      "Div": "1.36"
  },
  {
      "Date": "29-May-13",
      "PE": "18.31",
      "PB": "3.22",
      "Div": "1.36"
  },
  {
      "Date": "28-May-13",
      "PE": "18.25",
      "PB": "3.22",
      "Div": "1.36"
  },
  {
      "Date": "27-May-13",
      "PE": "18.19",
      "PB": "3.21",
      "Div": "1.36"
  },
  {
      "Date": "24-May-13",
      "PE": "17.88",
      "PB": "3.15",
      "Div": "1.39"
  },
  {
      "Date": "23-May-13",
      "PE": "17.77",
      "PB": "3.15",
      "Div": "1.39"
  },
  {
      "Date": "22-May-13",
      "PE": "18.15",
      "PB": "3.21",
      "Div": "1.36"
  },
  {
      "Date": "21-May-13",
      "PE": "18.24",
      "PB": "3.22",
      "Div": "1.36"
  },
  {
      "Date": "20-May-13",
      "PE": "18.37",
      "PB": "3.25",
      "Div": "1.35"
  },
  {
      "Date": "17-May-13",
      "PE": "18.38",
      "PB": "3.26",
      "Div": "1.34"
  },
  {
      "Date": "16-May-13",
      "PE": "18.33",
      "PB": "3.25",
      "Div": "1.35"
  },
  {
      "Date": "15-May-13",
      "PE": "18.33",
      "PB": "3.24",
      "Div": "1.35"
  },
  {
      "Date": "14-May-13",
      "PE": "17.88",
      "PB": "3.16",
      "Div": "1.38"
  },
  {
      "Date": "13-May-13",
      "PE": "17.79",
      "PB": "3.15",
      "Div": "1.39"
  },
  {
      "Date": "11-May-13",
      "PE": "18.26",
      "PB": "3.22",
      "Div": "1.36"
  },
  {
      "Date": "10-May-13",
      "PE": "18.22",
      "PB": "3.21",
      "Div": "1.36"
  },
  {
      "Date": "9-May-13",
      "PE": "18.11",
      "PB": "3.19",
      "Div": "1.37"
  },
  {
      "Date": "8-May-13",
      "PE": "18.2",
      "PB": "3.2",
      "Div": "1.37"
  },
  {
      "Date": "7-May-13",
      "PE": "18.19",
      "PB": "3.19",
      "Div": "1.37"
  },
  {
      "Date": "6-May-13",
      "PE": "17.97",
      "PB": "3.15",
      "Div": "1.39"
  },
  {
      "Date": "3-May-13",
      "PE": "17.89",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "2-May-13",
      "PE": "18.05",
      "PB": "3.16",
      "Div": "1.38"
  },
  {
      "Date": "30-Apr-13",
      "PE": "17.85",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "29-Apr-13",
      "PE": "17.78",
      "PB": "3.11",
      "Div": "1.41"
  },
  {
      "Date": "26-Apr-13",
      "PE": "17.73",
      "PB": "3.09",
      "Div": "1.41"
  },
  {
      "Date": "25-Apr-13",
      "PE": "18",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "23-Apr-13",
      "PE": "17.76",
      "PB": "3.08",
      "Div": "1.42"
  },
  {
      "Date": "22-Apr-13",
      "PE": "17.76",
      "PB": "3.07",
      "Div": "1.42"
  },
  {
      "Date": "18-Apr-13",
      "PE": "17.6",
      "PB": "3.05",
      "Div": "1.44"
  },
  {
      "Date": "17-Apr-13",
      "PE": "17.31",
      "PB": "3",
      "Div": "1.46"
  },
  {
      "Date": "16-Apr-13",
      "PE": "17.45",
      "PB": "3",
      "Div": "1.46"
  },
  {
      "Date": "15-Apr-13",
      "PE": "17.08",
      "PB": "2.93",
      "Div": "1.49"
  },
  {
      "Date": "12-Apr-13",
      "PE": "16.96",
      "PB": "2.91",
      "Div": "1.5"
  },
  {
      "Date": "11-Apr-13",
      "PE": "17.17",
      "PB": "2.95",
      "Div": "1.48"
  },
  {
      "Date": "10-Apr-13",
      "PE": "17.06",
      "PB": "2.93",
      "Div": "1.49"
  },
  {
      "Date": "9-Apr-13",
      "PE": "16.86",
      "PB": "2.9",
      "Div": "1.51"
  },
  {
      "Date": "8-Apr-13",
      "PE": "17.01",
      "PB": "2.92",
      "Div": "1.5"
  },
  {
      "Date": "5-Apr-13",
      "PE": "17.04",
      "PB": "2.93",
      "Div": "1.49"
  },
  {
      "Date": "4-Apr-13",
      "PE": "17.11",
      "PB": "2.94",
      "Div": "1.49"
  },
  {
      "Date": "3-Apr-13",
      "PE": "17.41",
      "PB": "2.99",
      "Div": "1.46"
  },
  {
      "Date": "2-Apr-13",
      "PE": "17.64",
      "PB": "3.03",
      "Div": "1.44"
  },
  {
      "Date": "1-Apr-13",
      "PE": "17.51",
      "PB": "3.01",
      "Div": "1.46"
  },
  {
      "Date": "28-Mar-13",
      "PE": "17.57",
      "PB": "3.01",
      "Div": "1.46"
  },
  {
      "Date": "26-Mar-13",
      "PE": "17.44",
      "PB": "2.98",
      "Div": "1.47"
  },
  {
      "Date": "25-Mar-13",
      "PE": "17.41",
      "PB": "2.98",
      "Div": "1.47"
  },
  {
      "Date": "22-Mar-13",
      "PE": "17.47",
      "PB": "2.99",
      "Div": "1.46"
  },
  {
      "Date": "21-Mar-13",
      "PE": "17.49",
      "PB": "3",
      "Div": "1.46"
  },
  {
      "Date": "20-Mar-13",
      "PE": "17.6",
      "PB": "3.01",
      "Div": "1.45"
  },
  {
      "Date": "19-Mar-13",
      "PE": "17.76",
      "PB": "3.04",
      "Div": "1.44"
  },
  {
      "Date": "18-Mar-13",
      "PE": "18.04",
      "PB": "3.09",
      "Div": "1.42"
  },
  {
      "Date": "15-Mar-13",
      "PE": "18.15",
      "PB": "3.11",
      "Div": "1.41"
  },
  {
      "Date": "14-Mar-13",
      "PE": "18.26",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "13-Mar-13",
      "PE": "18.09",
      "PB": "3.1",
      "Div": "1.41"
  },
  {
      "Date": "12-Mar-13",
      "PE": "18.28",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "11-Mar-13",
      "PE": "18.37",
      "PB": "3.15",
      "Div": "1.39"
  },
  {
      "Date": "8-Mar-13",
      "PE": "18.44",
      "PB": "3.16",
      "Div": "1.38"
  },
  {
      "Date": "7-Mar-13",
      "PE": "18.19",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "6-Mar-13",
      "PE": "18.05",
      "PB": "3.1",
      "Div": "1.41"
  },
  {
      "Date": "5-Mar-13",
      "PE": "17.94",
      "PB": "3.08",
      "Div": "1.42"
  },
  {
      "Date": "4-Mar-13",
      "PE": "17.67",
      "PB": "3.03",
      "Div": "1.44"
  },
  {
      "Date": "1-Mar-13",
      "PE": "17.74",
      "PB": "3.04",
      "Div": "1.44"
  },
  {
      "Date": "28-Feb-13",
      "PE": "17.66",
      "PB": "3.03",
      "Div": "1.44"
  },
  {
      "Date": "27-Feb-13",
      "PE": "17.98",
      "PB": "3.08",
      "Div": "1.42"
  },
  {
      "Date": "26-Feb-13",
      "PE": "18",
      "PB": "3.06",
      "Div": "1.43"
  },
  {
      "Date": "25-Feb-13",
      "PE": "18.29",
      "PB": "3.11",
      "Div": "1.4"
  },
  {
      "Date": "22-Feb-13",
      "PE": "18.28",
      "PB": "3.11",
      "Div": "1.41"
  },
  {
      "Date": "21-Feb-13",
      "PE": "18.32",
      "PB": "3.11",
      "Div": "1.4"
  },
  {
      "Date": "20-Feb-13",
      "PE": "18.61",
      "PB": "3.16",
      "Div": "1.38"
  },
  {
      "Date": "19-Feb-13",
      "PE": "18.52",
      "PB": "3.16",
      "Div": "1.38"
  },
  {
      "Date": "18-Feb-13",
      "PE": "18.27",
      "PB": "3.14",
      "Div": "1.39"
  },
  {
      "Date": "15-Feb-13",
      "PE": "18.21",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "14-Feb-13",
      "PE": "18.27",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "13-Feb-13",
      "PE": "18.33",
      "PB": "3.15",
      "Div": "1.39"
  },
  {
      "Date": "12-Feb-13",
      "PE": "18.29",
      "PB": "3.14",
      "Div": "1.39"
  },
  {
      "Date": "11-Feb-13",
      "PE": "18.17",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "8-Feb-13",
      "PE": "18.16",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "7-Feb-13",
      "PE": "18.27",
      "PB": "3.15",
      "Div": "1.39"
  },
  {
      "Date": "6-Feb-13",
      "PE": "18.34",
      "PB": "3.16",
      "Div": "1.38"
  },
  {
      "Date": "5-Feb-13",
      "PE": "18.33",
      "PB": "3.16",
      "Div": "1.38"
  },
  {
      "Date": "4-Feb-13",
      "PE": "18.43",
      "PB": "3.18",
      "Div": "1.38"
  },
  {
      "Date": "1-Feb-13",
      "PE": "18.42",
      "PB": "3.18",
      "Div": "1.37"
  },
  {
      "Date": "31-Jan-13",
      "PE": "18.5",
      "PB": "3.2",
      "Div": "1.37"
  },
  {
      "Date": "30-Jan-13",
      "PE": "18.66",
      "PB": "3.21",
      "Div": "1.36"
  },
  {
      "Date": "29-Jan-13",
      "PE": "18.67",
      "PB": "3.21",
      "Div": "1.36"
  },
  {
      "Date": "28-Jan-13",
      "PE": "18.75",
      "PB": "3.22",
      "Div": "1.36"
  },
  {
      "Date": "25-Jan-13",
      "PE": "18.77",
      "PB": "3.22",
      "Div": "1.36"
  },
  {
      "Date": "24-Jan-13",
      "PE": "18.6",
      "PB": "3.19",
      "Div": "1.37"
  },
  {
      "Date": "23-Jan-13",
      "PE": "18.76",
      "PB": "3.21",
      "Div": "1.36"
  },
  {
      "Date": "22-Jan-13",
      "PE": "18.75",
      "PB": "3.21",
      "Div": "1.36"
  },
  {
      "Date": "21-Jan-13",
      "PE": "18.97",
      "PB": "3.23",
      "Div": "1.36"
  },
  {
      "Date": "18-Jan-13",
      "PE": "19.01",
      "PB": "3.22",
      "Div": "1.36"
  },
  {
      "Date": "17-Jan-13",
      "PE": "19.07",
      "PB": "3.21",
      "Div": "1.36"
  },
  {
      "Date": "16-Jan-13",
      "PE": "18.95",
      "PB": "3.19",
      "Div": "1.37"
  },
  {
      "Date": "15-Jan-13",
      "PE": "19.12",
      "PB": "3.21",
      "Div": "1.36"
  },
  {
      "Date": "14-Jan-13",
      "PE": "19.05",
      "PB": "3.2",
      "Div": "1.37"
  },
  {
      "Date": "11-Jan-13",
      "PE": "18.82",
      "PB": "3.16",
      "Div": "1.39"
  },
  {
      "Date": "10-Jan-13",
      "PE": "18.88",
      "PB": "3.17",
      "Div": "1.38"
  },
  {
      "Date": "9-Jan-13",
      "PE": "18.89",
      "PB": "3.17",
      "Div": "1.38"
  },
  {
      "Date": "8-Jan-13",
      "PE": "18.98",
      "PB": "3.19",
      "Div": "1.37"
  },
  {
      "Date": "7-Jan-13",
      "PE": "18.94",
      "PB": "3.18",
      "Div": "1.38"
  },
  {
      "Date": "4-Jan-13",
      "PE": "19.03",
      "PB": "3.19",
      "Div": "1.37"
  },
  {
      "Date": "3-Jan-13",
      "PE": "19.01",
      "PB": "3.19",
      "Div": "1.37"
  },
  {
      "Date": "2-Jan-13",
      "PE": "18.96",
      "PB": "3.18",
      "Div": "1.38"
  },
  {
      "Date": "1-Jan-13",
      "PE": "18.82",
      "PB": "3.16",
      "Div": "1.39"
  },
  {
      "Date": "31-Dec-12",
      "PE": "18.68",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "28-Dec-12",
      "PE": "18.69",
      "PB": "3.14",
      "Div": "1.4"
  },
  {
      "Date": "27-Dec-12",
      "PE": "18.57",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "26-Dec-12",
      "PE": "18.68",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "24-Dec-12",
      "PE": "18.52",
      "PB": "3.11",
      "Div": "1.41"
  },
  {
      "Date": "21-Dec-12",
      "PE": "18.5",
      "PB": "3.1",
      "Div": "1.41"
  },
  {
      "Date": "20-Dec-12",
      "PE": "18.71",
      "PB": "3.14",
      "Div": "1.39"
  },
  {
      "Date": "19-Dec-12",
      "PE": "18.76",
      "PB": "3.15",
      "Div": "1.39"
  },
  {
      "Date": "18-Dec-12",
      "PE": "18.65",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "17-Dec-12",
      "PE": "18.53",
      "PB": "3.11",
      "Div": "1.41"
  },
  {
      "Date": "14-Dec-12",
      "PE": "18.6",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "13-Dec-12",
      "PE": "18.51",
      "PB": "3.11",
      "Div": "1.41"
  },
  {
      "Date": "12-Dec-12",
      "PE": "18.62",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "11-Dec-12",
      "PE": "18.66",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "10-Dec-12",
      "PE": "18.69",
      "PB": "3.14",
      "Div": "1.4"
  },
  {
      "Date": "7-Dec-12",
      "PE": "18.68",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "6-Dec-12",
      "PE": "18.75",
      "PB": "3.15",
      "Div": "1.39"
  },
  {
      "Date": "5-Dec-12",
      "PE": "18.66",
      "PB": "3.13",
      "Div": "1.4"
  },
  {
      "Date": "4-Dec-12",
      "PE": "18.62",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "3-Dec-12",
      "PE": "18.56",
      "PB": "3.11",
      "Div": "1.4"
  },
  {
      "Date": "30-Nov-12",
      "PE": "18.59",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "29-Nov-12",
      "PE": "18.42",
      "PB": "3.09",
      "Div": "1.41"
  },
  {
      "Date": "27-Nov-12",
      "PE": "18.11",
      "PB": "3.04",
      "Div": "1.43"
  },
  {
      "Date": "26-Nov-12",
      "PE": "17.82",
      "PB": "3",
      "Div": "1.45"
  },
  {
      "Date": "23-Nov-12",
      "PE": "17.78",
      "PB": "2.99",
      "Div": "1.46"
  },
  {
      "Date": "22-Nov-12",
      "PE": "17.79",
      "PB": "3",
      "Div": "1.46"
  },
  {
      "Date": "21-Nov-12",
      "PE": "17.74",
      "PB": "2.99",
      "Div": "1.46"
  },
  {
      "Date": "20-Nov-12",
      "PE": "17.61",
      "PB": "2.97",
      "Div": "1.47"
  },
  {
      "Date": "19-Nov-12",
      "PE": "17.61",
      "PB": "2.97",
      "Div": "1.47"
  },
  {
      "Date": "16-Nov-12",
      "PE": "17.62",
      "PB": "2.97",
      "Div": "1.47"
  },
  {
      "Date": "15-Nov-12",
      "PE": "17.78",
      "PB": "3",
      "Div": "1.45"
  },
  {
      "Date": "13-Nov-12",
      "PE": "17.9",
      "PB": "3.02",
      "Div": "1.45"
  },
  {
      "Date": "12-Nov-12",
      "PE": "17.95",
      "PB": "3.02",
      "Div": "1.44"
  },
  {
      "Date": "9-Nov-12",
      "PE": "18.43",
      "PB": "3.03",
      "Div": "1.45"
  },
  {
      "Date": "8-Nov-12",
      "PE": "18.61",
      "PB": "3.06",
      "Div": "1.43"
  },
  {
      "Date": "7-Nov-12",
      "PE": "18.78",
      "PB": "3.08",
      "Div": "1.43"
  },
  {
      "Date": "6-Nov-12",
      "PE": "18.66",
      "PB": "3.07",
      "Div": "1.43"
  },
  {
      "Date": "5-Nov-12",
      "PE": "18.62",
      "PB": "3.06",
      "Div": "1.43"
  },
  {
      "Date": "2-Nov-12",
      "PE": "18.6",
      "PB": "3.08",
      "Div": "1.42"
  },
  {
      "Date": "1-Nov-12",
      "PE": "18.46",
      "PB": "3.05",
      "Div": "1.43"
  },
  {
      "Date": "31-Oct-12",
      "PE": "18.4",
      "PB": "3.03",
      "Div": "1.44"
  },
  {
      "Date": "30-Oct-12",
      "PE": "18.36",
      "PB": "3.02",
      "Div": "1.44"
  },
  {
      "Date": "29-Oct-12",
      "PE": "18.58",
      "PB": "3.06",
      "Div": "1.42"
  },
  {
      "Date": "26-Oct-12",
      "PE": "18.57",
      "PB": "3.06",
      "Div": "1.42"
  },
  {
      "Date": "25-Oct-12",
      "PE": "18.81",
      "PB": "3.09",
      "Div": "1.41"
  },
  {
      "Date": "23-Oct-12",
      "PE": "18.77",
      "PB": "3.11",
      "Div": "1.42"
  },
  {
      "Date": "22-Oct-12",
      "PE": "18.88",
      "PB": "3.14",
      "Div": "1.39"
  },
  {
      "Date": "19-Oct-12",
      "PE": "18.94",
      "PB": "3.12",
      "Div": "1.4"
  },
  {
      "Date": "18-Oct-12",
      "PE": "19.07",
      "PB": "3.14",
      "Div": "1.39"
  },
  {
      "Date": "17-Oct-12",
      "PE": "18.88",
      "PB": "3.11",
      "Div": "1.4"
  },
  {
      "Date": "16-Oct-12",
      "PE": "18.86",
      "PB": "3.11",
      "Div": "1.41"
  },
  {
      "Date": "15-Oct-12",
      "PE": "19.04",
      "PB": "3.15",
      "Div": "1.42"
  },
  {
      "Date": "12-Oct-12",
      "PE": "19",
      "PB": "3.14",
      "Div": "1.43"
  },
  {
      "Date": "11-Oct-12",
      "PE": "19.19",
      "PB": "3.16",
      "Div": "1.42"
  },
  {
      "Date": "10-Oct-12",
      "PE": "19",
      "PB": "3.13",
      "Div": "1.43"
  },
  {
      "Date": "9-Oct-12",
      "PE": "19.18",
      "PB": "3.16",
      "Div": "1.42"
  },
  {
      "Date": "8-Oct-12",
      "PE": "19.08",
      "PB": "3.14",
      "Div": "1.43"
  },
  {
      "Date": "5-Oct-12",
      "PE": "19.32",
      "PB": "3.18",
      "Div": "1.41"
  },
  {
      "Date": "4-Oct-12",
      "PE": "19.45",
      "PB": "3.2",
      "Div": "1.4"
  },
  {
      "Date": "3-Oct-12",
      "PE": "19.27",
      "PB": "3.19",
      "Div": "1.41"
  },
  {
      "Date": "1-Oct-12",
      "PE": "19.22",
      "PB": "3.19",
      "Div": "1.41"
  },
  {
      "Date": "28-Sep-12",
      "PE": "19.17",
      "PB": "3.18",
      "Div": "1.42"
  },
  {
      "Date": "27-Sep-12",
      "PE": "18.98",
      "PB": "3.06",
      "Div": "1.46"
  },
  {
      "Date": "26-Sep-12",
      "PE": "19.02",
      "PB": "3.09",
      "Div": "1.46"
  },
  {
      "Date": "25-Sep-12",
      "PE": "19.06",
      "PB": "3.1",
      "Div": "1.46"
  },
  {
      "Date": "24-Sep-12",
      "PE": "19.05",
      "PB": "3.09",
      "Div": "1.46"
  },
  {
      "Date": "21-Sep-12",
      "PE": "19.12",
      "PB": "3.1",
      "Div": "1.45"
  },
  {
      "Date": "20-Sep-12",
      "PE": "18.66",
      "PB": "3.03",
      "Div": "1.49"
  },
  {
      "Date": "18-Sep-12",
      "PE": "18.81",
      "PB": "3.06",
      "Div": "1.48"
  },
  {
      "Date": "17-Sep-12",
      "PE": "18.85",
      "PB": "3.06",
      "Div": "1.47"
  },
  {
      "Date": "14-Sep-12",
      "PE": "18.74",
      "PB": "3.04",
      "Div": "1.48"
  },
  {
      "Date": "13-Sep-12",
      "PE": "18.26",
      "PB": "2.97",
      "Div": "1.52"
  },
  {
      "Date": "12-Sep-12",
      "PE": "18.24",
      "PB": "2.96",
      "Div": "1.52"
  },
  {
      "Date": "11-Sep-12",
      "PE": "18.11",
      "PB": "2.94",
      "Div": "1.53"
  },
  {
      "Date": "10-Sep-12",
      "PE": "17.99",
      "PB": "2.92",
      "Div": "1.54"
  },
  {
      "Date": "8-Sep-12",
      "PE": "17.98",
      "PB": "2.92",
      "Div": "1.54"
  },
  {
      "Date": "7-Sep-12",
      "PE": "17.92",
      "PB": "2.91",
      "Div": "1.55"
  },
  {
      "Date": "6-Sep-12",
      "PE": "17.58",
      "PB": "2.85",
      "Div": "1.58"
  },
  {
      "Date": "5-Sep-12",
      "PE": "17.53",
      "PB": "2.85",
      "Div": "1.58"
  },
  {
      "Date": "4-Sep-12",
      "PE": "17.69",
      "PB": "2.87",
      "Div": "1.57"
  },
  {
      "Date": "3-Sep-12",
      "PE": "17.63",
      "PB": "2.86",
      "Div": "1.58"
  },
  {
      "Date": "31-Aug-12",
      "PE": "17.64",
      "PB": "2.87",
      "Div": "1.57"
  },
  {
      "Date": "30-Aug-12",
      "PE": "17.83",
      "PB": "2.9",
      "Div": "1.56"
  },
  {
      "Date": "29-Aug-12",
      "PE": "17.74",
      "PB": "2.88",
      "Div": "1.56"
  },
  {
      "Date": "28-Aug-12",
      "PE": "17.9",
      "PB": "2.91",
      "Div": "1.55"
  },
  {
      "Date": "27-Aug-12",
      "PE": "17.95",
      "PB": "2.92",
      "Div": "1.55"
  },
  {
      "Date": "24-Aug-12",
      "PE": "18.07",
      "PB": "2.94",
      "Div": "1.54"
  },
  {
      "Date": "23-Aug-12",
      "PE": "18.17",
      "PB": "2.95",
      "Div": "1.53"
  },
  {
      "Date": "22-Aug-12",
      "PE": "18.16",
      "PB": "2.95",
      "Div": "1.53"
  },
  {
      "Date": "21-Aug-12",
      "PE": "18.19",
      "PB": "2.95",
      "Div": "1.53"
  },
  {
      "Date": "17-Aug-12",
      "PE": "17.97",
      "PB": "2.92",
      "Div": "1.54"
  },
  {
      "Date": "16-Aug-12",
      "PE": "17.97",
      "PB": "2.99",
      "Div": "1.51"
  },
  {
      "Date": "14-Aug-12",
      "PE": "17.88",
      "PB": "3.04",
      "Div": "1.52"
  },
  {
      "Date": "13-Aug-12",
      "PE": "17.51",
      "PB": "3.03",
      "Div": "1.5"
  },
  {
      "Date": "10-Aug-12",
      "PE": "17.34",
      "PB": "3.01",
      "Div": "1.51"
  },
  {
      "Date": "9-Aug-12",
      "PE": "17.35",
      "PB": "3.01",
      "Div": "1.51"
  },
  {
      "Date": "8-Aug-12",
      "PE": "17.43",
      "PB": "3.02",
      "Div": "1.51"
  },
  {
      "Date": "7-Aug-12",
      "PE": "17.43",
      "PB": "3.02",
      "Div": "1.51"
  },
  {
      "Date": "6-Aug-12",
      "PE": "17.25",
      "PB": "2.99",
      "Div": "1.52"
  },
  {
      "Date": "3-Aug-12",
      "PE": "17.03",
      "PB": "2.95",
      "Div": "1.54"
  },
  {
      "Date": "2-Aug-12",
      "PE": "17.07",
      "PB": "2.96",
      "Div": "1.54"
  },
  {
      "Date": "1-Aug-12",
      "PE": "17.13",
      "PB": "2.97",
      "Div": "1.54"
  },
  {
      "Date": "31-Jul-12",
      "PE": "17.09",
      "PB": "2.96",
      "Div": "1.54"
  },
  {
      "Date": "30-Jul-12",
      "PE": "17",
      "PB": "2.94",
      "Div": "1.55"
  },
  {
      "Date": "27-Jul-12",
      "PE": "16.68",
      "PB": "2.89",
      "Div": "1.58"
  },
  {
      "Date": "26-Jul-12",
      "PE": "16.62",
      "PB": "2.85",
      "Div": "1.6"
  },
  {
      "Date": "25-Jul-12",
      "PE": "16.8",
      "PB": "2.89",
      "Div": "1.58"
  },
  {
      "Date": "24-Jul-12",
      "PE": "16.86",
      "PB": "2.94",
      "Div": "1.56"
  },
  {
      "Date": "23-Jul-12",
      "PE": "16.9",
      "PB": "2.93",
      "Div": "1.56"
  },
  {
      "Date": "20-Jul-12",
      "PE": "17.08",
      "PB": "2.98",
      "Div": "1.53"
  },
  {
      "Date": "19-Jul-12",
      "PE": "17.18",
      "PB": "3",
      "Div": "1.52"
  },
  {
      "Date": "18-Jul-12",
      "PE": "17.11",
      "PB": "2.99",
      "Div": "1.53"
  },
  {
      "Date": "17-Jul-12",
      "PE": "17.04",
      "PB": "2.97",
      "Div": "1.54"
  },
  {
      "Date": "16-Jul-12",
      "PE": "17.05",
      "PB": "2.98",
      "Div": "1.54"
  },
  {
      "Date": "13-Jul-12",
      "PE": "17.2",
      "PB": "2.99",
      "Div": "1.53"
  },
  {
      "Date": "12-Jul-12",
      "PE": "17.37",
      "PB": "3",
      "Div": "1.53"
  },
  {
      "Date": "11-Jul-12",
      "PE": "17.61",
      "PB": "3.04",
      "Div": "1.51"
  },
  {
      "Date": "10-Jul-12",
      "PE": "17.74",
      "PB": "3.06",
      "Div": "1.49"
  },
  {
      "Date": "9-Jul-12",
      "PE": "17.5",
      "PB": "3.02",
      "Div": "1.51"
  },
  {
      "Date": "6-Jul-12",
      "PE": "17.64",
      "PB": "3.04",
      "Div": "1.5"
  },
  {
      "Date": "5-Jul-12",
      "PE": "17.67",
      "PB": "3.05",
      "Div": "1.5"
  },
  {
      "Date": "4-Jul-12",
      "PE": "17.59",
      "PB": "3.03",
      "Div": "1.51"
  },
  {
      "Date": "3-Jul-12",
      "PE": "17.54",
      "PB": "3.03",
      "Div": "1.51"
  },
  {
      "Date": "2-Jul-12",
      "PE": "17.51",
      "PB": "3.02",
      "Div": "1.51"
  },
  {
      "Date": "29-Jun-12",
      "PE": "17.51",
      "PB": "3.02",
      "Div": "1.51"
  },
  {
      "Date": "28-Jun-12",
      "PE": "17.08",
      "PB": "2.95",
      "Div": "1.55"
  },
  {
      "Date": "27-Jun-12",
      "PE": "17.06",
      "PB": "2.94",
      "Div": "1.55"
  },
  {
      "Date": "26-Jun-12",
      "PE": "16.99",
      "PB": "2.93",
      "Div": "1.56"
  },
  {
      "Date": "25-Jun-12",
      "PE": "16.96",
      "PB": "2.93",
      "Div": "1.56"
  },
  {
      "Date": "22-Jun-12",
      "PE": "17.07",
      "PB": "2.94",
      "Div": "1.55"
  },
  {
      "Date": "21-Jun-12",
      "PE": "17.13",
      "PB": "2.96",
      "Div": "1.55"
  },
  {
      "Date": "20-Jun-12",
      "PE": "16.98",
      "PB": "2.93",
      "Div": "1.56"
  },
  {
      "Date": "19-Jun-12",
      "PE": "16.93",
      "PB": "2.92",
      "Div": "1.57"
  },
  {
      "Date": "18-Jun-12",
      "PE": "16.8",
      "PB": "2.9",
      "Div": "1.58"
  },
  {
      "Date": "15-Jun-12",
      "PE": "17.05",
      "PB": "2.94",
      "Div": "1.55"
  },
  {
      "Date": "14-Jun-12",
      "PE": "16.77",
      "PB": "2.89",
      "Div": "1.58"
  },
  {
      "Date": "13-Jun-12",
      "PE": "16.99",
      "PB": "2.93",
      "Div": "1.56"
  },
  {
      "Date": "12-Jun-12",
      "PE": "16.99",
      "PB": "2.93",
      "Div": "1.56"
  },
  {
      "Date": "11-Jun-12",
      "PE": "16.79",
      "PB": "2.89",
      "Div": "1.58"
  },
  {
      "Date": "8-Jun-12",
      "PE": "16.83",
      "PB": "2.9",
      "Div": "1.58"
  },
  {
      "Date": "7-Jun-12",
      "PE": "16.96",
      "PB": "2.89",
      "Div": "1.58"
  },
  {
      "Date": "6-Jun-12",
      "PE": "16.78",
      "PB": "2.86",
      "Div": "1.6"
  },
  {
      "Date": "5-Jun-12",
      "PE": "16.33",
      "PB": "2.8",
      "Div": "1.68"
  },
  {
      "Date": "4-Jun-12",
      "PE": "16.28",
      "PB": "2.84",
      "Div": "1.68"
  },
  {
      "Date": "1-Jun-12",
      "PE": "16.36",
      "PB": "2.84",
      "Div": "1.69"
  },
  {
      "Date": "31-May-12",
      "PE": "16.66",
      "PB": "2.89",
      "Div": "1.66"
  },
  {
      "Date": "30-May-12",
      "PE": "16.75",
      "PB": "2.9",
      "Div": "1.65"
  },
  {
      "Date": "29-May-12",
      "PE": "16.9",
      "PB": "2.93",
      "Div": "1.6"
  },
  {
      "Date": "28-May-12",
      "PE": "16.9",
      "PB": "2.93",
      "Div": "1.6"
  },
  {
      "Date": "25-May-12",
      "PE": "16.68",
      "PB": "2.89",
      "Div": "1.62"
  },
  {
      "Date": "24-May-12",
      "PE": "16.69",
      "PB": "2.89",
      "Div": "1.62"
  },
  {
      "Date": "23-May-12",
      "PE": "16.38",
      "PB": "2.84",
      "Div": "1.65"
  },
  {
      "Date": "22-May-12",
      "PE": "16.46",
      "PB": "2.85",
      "Div": "1.64"
  },
  {
      "Date": "21-May-12",
      "PE": "16.62",
      "PB": "2.88",
      "Div": "1.63"
  },
  {
      "Date": "18-May-12",
      "PE": "16.85",
      "PB": "2.87",
      "Div": "1.63"
  },
  {
      "Date": "17-May-12",
      "PE": "16.78",
      "PB": "2.86",
      "Div": "1.64"
  },
  {
      "Date": "16-May-12",
      "PE": "16.74",
      "PB": "2.85",
      "Div": "1.64"
  },
  {
      "Date": "15-May-12",
      "PE": "17.04",
      "PB": "2.9",
      "Div": "1.62"
  },
  {
      "Date": "14-May-12",
      "PE": "16.95",
      "PB": "2.88",
      "Div": "1.63"
  },
  {
      "Date": "11-May-12",
      "PE": "16.87",
      "PB": "2.89",
      "Div": "1.62"
  },
  {
      "Date": "10-May-12",
      "PE": "17",
      "PB": "2.91",
      "Div": "1.61"
  },
  {
      "Date": "9-May-12",
      "PE": "17.03",
      "PB": "2.92",
      "Div": "1.61"
  },
  {
      "Date": "8-May-12",
      "PE": "17.15",
      "PB": "2.93",
      "Div": "1.6"
  },
  {
      "Date": "7-May-12",
      "PE": "17.54",
      "PB": "3",
      "Div": "1.56"
  },
  {
      "Date": "4-May-12",
      "PE": "17.45",
      "PB": "2.98",
      "Div": "1.57"
  },
  {
      "Date": "3-May-12",
      "PE": "17.81",
      "PB": "3.04",
      "Div": "1.54"
  },
  {
      "Date": "2-May-12",
      "PE": "17.99",
      "PB": "3.07",
      "Div": "1.53"
  },
  {
      "Date": "30-Apr-12",
      "PE": "18.12",
      "PB": "3.08",
      "Div": "1.52"
  },
  {
      "Date": "28-Apr-12",
      "PE": "17.99",
      "PB": "3.05",
      "Div": "1.54"
  },
  {
      "Date": "27-Apr-12",
      "PE": "17.93",
      "PB": "3.04",
      "Div": "1.54"
  },
  {
      "Date": "26-Apr-12",
      "PE": "18.22",
      "PB": "2.96",
      "Div": "1.53"
  },
  {
      "Date": "25-Apr-12",
      "PE": "18.27",
      "PB": "2.96",
      "Div": "1.53"
  },
  {
      "Date": "24-Apr-12",
      "PE": "18.34",
      "PB": "2.98",
      "Div": "1.52"
  },
  {
      "Date": "23-Apr-12",
      "PE": "18.26",
      "PB": "2.96",
      "Div": "1.53"
  },
  {
      "Date": "20-Apr-12",
      "PE": "18.58",
      "PB": "3.02",
      "Div": "1.5"
  },
  {
      "Date": "19-Apr-12",
      "PE": "18.72",
      "PB": "3.04",
      "Div": "1.49"
  },
  {
      "Date": "18-Apr-12",
      "PE": "18.67",
      "PB": "3.02",
      "Div": "1.5"
  },
  {
      "Date": "17-Apr-12",
      "PE": "18.63",
      "PB": "3.01",
      "Div": "1.5"
  },
  {
      "Date": "16-Apr-12",
      "PE": "18.41",
      "PB": "2.98",
      "Div": "1.52"
  },
  {
      "Date": "13-Apr-12",
      "PE": "18.3",
      "PB": "2.96",
      "Div": "1.53"
  },
  {
      "Date": "12-Apr-12",
      "PE": "18.65",
      "PB": "3",
      "Div": "1.51"
  },
  {
      "Date": "11-Apr-12",
      "PE": "18.47",
      "PB": "2.97",
      "Div": "1.52"
  },
  {
      "Date": "10-Apr-12",
      "PE": "18.53",
      "PB": "2.98",
      "Div": "1.52"
  },
  {
      "Date": "9-Apr-12",
      "PE": "18.5",
      "PB": "2.97",
      "Div": "1.52"
  },
  {
      "Date": "4-Apr-12",
      "PE": "18.81",
      "PB": "3.02",
      "Div": "1.5"
  },
  {
      "Date": "3-Apr-12",
      "PE": "18.93",
      "PB": "3.04",
      "Div": "1.49"
  },
  {
      "Date": "2-Apr-12",
      "PE": "18.79",
      "PB": "3.02",
      "Div": "1.5"
  },
  {
      "Date": "30-Mar-12",
      "PE": "18.71",
      "PB": "3.01",
      "Div": "1.5"
  },
  {
      "Date": "29-Mar-12",
      "PE": "18.3",
      "PB": "2.94",
      "Div": "1.54"
  },
  {
      "Date": "28-Mar-12",
      "PE": "18.36",
      "PB": "2.95",
      "Div": "1.53"
  },
  {
      "Date": "27-Mar-12",
      "PE": "18.53",
      "PB": "2.98",
      "Div": "1.52"
  },
  {
      "Date": "26-Mar-12",
      "PE": "18.32",
      "PB": "2.95",
      "Div": "1.54"
  },
  {
      "Date": "23-Mar-12",
      "PE": "18.65",
      "PB": "3",
      "Div": "1.51"
  },
  {
      "Date": "22-Mar-12",
      "PE": "18.47",
      "PB": "2.97",
      "Div": "1.52"
  },
  {
      "Date": "21-Mar-12",
      "PE": "18.96",
      "PB": "3.05",
      "Div": "1.48"
  },
  {
      "Date": "20-Mar-12",
      "PE": "18.64",
      "PB": "3",
      "Div": "1.51"
  },
  {
      "Date": "19-Mar-12",
      "PE": "18.58",
      "PB": "2.99",
      "Div": "1.51"
  },
  {
      "Date": "16-Mar-12",
      "PE": "18.79",
      "PB": "3.02",
      "Div": "1.5"
  },
  {
      "Date": "15-Mar-12",
      "PE": "19.01",
      "PB": "3.06",
      "Div": "1.48"
  },
  {
      "Date": "14-Mar-12",
      "PE": "19.31",
      "PB": "3.1",
      "Div": "1.46"
  },
  {
      "Date": "13-Mar-12",
      "PE": "19.18",
      "PB": "3.09",
      "Div": "1.47"
  },
  {
      "Date": "12-Mar-12",
      "PE": "18.94",
      "PB": "3.05",
      "Div": "1.49"
  },
  {
      "Date": "9-Mar-12",
      "PE": "18.9",
      "PB": "3.02",
      "Div": "1.48"
  },
  {
      "Date": "7-Mar-12",
      "PE": "18.5",
      "PB": "2.96",
      "Div": "1.52"
  },
  {
      "Date": "6-Mar-12",
      "PE": "18.51",
      "PB": "2.96",
      "Div": "1.52"
  },
  {
      "Date": "5-Mar-12",
      "PE": "18.71",
      "PB": "2.99",
      "Div": "1.5"
  },
  {
      "Date": "3-Mar-12",
      "PE": "18.99",
      "PB": "3.04",
      "Div": "1.48"
  },
  {
      "Date": "2-Mar-12",
      "PE": "18.99",
      "PB": "3.04",
      "Div": "1.48"
  },
  {
      "Date": "1-Mar-12",
      "PE": "18.92",
      "PB": "3.01",
      "Div": "1.48"
  },
  {
      "Date": "29-Feb-12",
      "PE": "19.09",
      "PB": "3.04",
      "Div": "1.47"
  },
  {
      "Date": "28-Feb-12",
      "PE": "19.05",
      "PB": "3.03",
      "Div": "1.47"
  },
  {
      "Date": "27-Feb-12",
      "PE": "18.5",
      "PB": "2.98",
      "Div": "1.5"
  },
  {
      "Date": "24-Feb-12",
      "PE": "19.02",
      "PB": "3.06",
      "Div": "1.46"
  },
  {
      "Date": "23-Feb-12",
      "PE": "19.21",
      "PB": "3.09",
      "Div": "1.44"
  },
  {
      "Date": "22-Feb-12",
      "PE": "19.29",
      "PB": "3.1",
      "Div": "1.44"
  },
  {
      "Date": "21-Feb-12",
      "PE": "19.64",
      "PB": "3.16",
      "Div": "1.41"
  },
  {
      "Date": "17-Feb-12",
      "PE": "19.49",
      "PB": "3.12",
      "Div": "1.42"
  },
  {
      "Date": "16-Feb-12",
      "PE": "19.34",
      "PB": "3.1",
      "Div": "1.43"
  },
  {
      "Date": "15-Feb-12",
      "PE": "19.35",
      "PB": "3.11",
      "Div": "1.43"
  },
  {
      "Date": "14-Feb-12",
      "PE": "18.95",
      "PB": "3.04",
      "Div": "1.46"
  },
  {
      "Date": "13-Feb-12",
      "PE": "18.94",
      "PB": "3.03",
      "Div": "1.47"
  },
  {
      "Date": "10-Feb-12",
      "PE": "19",
      "PB": "3.02",
      "Div": "1.47"
  },
  {
      "Date": "9-Feb-12",
      "PE": "19.3",
      "PB": "3.06",
      "Div": "1.45"
  },
  {
      "Date": "8-Feb-12",
      "PE": "19.14",
      "PB": "3.04",
      "Div": "1.48"
  },
  {
      "Date": "7-Feb-12",
      "PE": "19.02",
      "PB": "3.04",
      "Div": "1.47"
  },
  {
      "Date": "6-Feb-12",
      "PE": "19.13",
      "PB": "3.05",
      "Div": "1.47"
  },
  {
      "Date": "3-Feb-12",
      "PE": "18.97",
      "PB": "3.03",
      "Div": "1.48"
  },
  {
      "Date": "2-Feb-12",
      "PE": "18.77",
      "PB": "3.06",
      "Div": "1.47"
  },
  {
      "Date": "1-Feb-12",
      "PE": "18.66",
      "PB": "3.04",
      "Div": "1.48"
  },
  {
      "Date": "31-Jan-12",
      "PE": "18.53",
      "PB": "3.02",
      "Div": "1.49"
  },
  {
      "Date": "30-Jan-12",
      "PE": "18.13",
      "PB": "2.95",
      "Div": "1.53"
  },
  {
      "Date": "27-Jan-12",
      "PE": "18.55",
      "PB": "3.02",
      "Div": "1.49"
  },
  {
      "Date": "25-Jan-12",
      "PE": "18.38",
      "PB": "2.99",
      "Div": "1.51"
  },
  {
      "Date": "24-Jan-12",
      "PE": "18.28",
      "PB": "3.01",
      "Div": "1.49"
  },
  {
      "Date": "23-Jan-12",
      "PE": "18.14",
      "PB": "2.96",
      "Div": "1.52"
  },
  {
      "Date": "20-Jan-12",
      "PE": "18.14",
      "PB": "2.96",
      "Div": "1.52"
  },
  {
      "Date": "19-Jan-12",
      "PE": "18.03",
      "PB": "2.94",
      "Div": "1.53"
  },
  {
      "Date": "18-Jan-12",
      "PE": "17.81",
      "PB": "2.9",
      "Div": "1.54"
  },
  {
      "Date": "17-Jan-12",
      "PE": "17.85",
      "PB": "2.92",
      "Div": "1.53"
  },
  {
      "Date": "16-Jan-12",
      "PE": "17.53",
      "PB": "2.87",
      "Div": "1.56"
  },
  {
      "Date": "13-Jan-12",
      "PE": "17.5",
      "PB": "2.87",
      "Div": "1.56"
  },
  {
      "Date": "12-Jan-12",
      "PE": "17.5",
      "PB": "2.85",
      "Div": "1.58"
  },
  {
      "Date": "11-Jan-12",
      "PE": "17.61",
      "PB": "2.86",
      "Div": "1.57"
  },
  {
      "Date": "10-Jan-12",
      "PE": "17.56",
      "PB": "2.86",
      "Div": "1.57"
  },
  {
      "Date": "9-Jan-12",
      "PE": "17.18",
      "PB": "2.79",
      "Div": "1.6"
  },
  {
      "Date": "7-Jan-12",
      "PE": "17.19",
      "PB": "2.8",
      "Div": "1.6"
  },
  {
      "Date": "6-Jan-12",
      "PE": "17.22",
      "PB": "2.8",
      "Div": "1.6"
  },
  {
      "Date": "5-Jan-12",
      "PE": "17.2",
      "PB": "2.8",
      "Div": "1.6"
  },
  {
      "Date": "4-Jan-12",
      "PE": "17.2",
      "PB": "2.81",
      "Div": "1.6"
  },
  {
      "Date": "3-Jan-12",
      "PE": "17.26",
      "PB": "2.82",
      "Div": "1.6"
  },
  {
      "Date": "2-Jan-12",
      "PE": "16.79",
      "PB": "2.77",
      "Div": "1.63"
  },
  {
      "Date": "30-Dec-11",
      "PE": "16.75",
      "PB": "2.76",
      "Div": "1.63"
  },
  {
      "Date": "29-Dec-11",
      "PE": "16.83",
      "PB": "2.77",
      "Div": "1.62"
  },
  {
      "Date": "28-Dec-11",
      "PE": "17.04",
      "PB": "2.81",
      "Div": "1.6"
  },
  {
      "Date": "27-Dec-11",
      "PE": "17.2",
      "PB": "2.84",
      "Div": "1.59"
  },
  {
      "Date": "26-Dec-11",
      "PE": "17.31",
      "PB": "2.85",
      "Div": "1.58"
  },
  {
      "Date": "23-Dec-11",
      "PE": "17.07",
      "PB": "2.81",
      "Div": "1.6"
  },
  {
      "Date": "22-Dec-11",
      "PE": "17.14",
      "PB": "2.83",
      "Div": "1.59"
  },
  {
      "Date": "21-Dec-11",
      "PE": "17",
      "PB": "2.8",
      "Div": "1.61"
  },
  {
      "Date": "20-Dec-11",
      "PE": "16.46",
      "PB": "2.71",
      "Div": "1.66"
  },
  {
      "Date": "19-Dec-11",
      "PE": "16.71",
      "PB": "2.75",
      "Div": "1.64"
  },
  {
      "Date": "16-Dec-11",
      "PE": "16.84",
      "PB": "2.78",
      "Div": "1.62"
  },
  {
      "Date": "15-Dec-11",
      "PE": "17.19",
      "PB": "2.83",
      "Div": "1.59"
  },
  {
      "Date": "14-Dec-11",
      "PE": "17.25",
      "PB": "2.84",
      "Div": "1.58"
  },
  {
      "Date": "13-Dec-11",
      "PE": "17.38",
      "PB": "2.87",
      "Div": "1.57"
  },
  {
      "Date": "12-Dec-11",
      "PE": "17.25",
      "PB": "2.84",
      "Div": "1.58"
  },
  {
      "Date": "9-Dec-11",
      "PE": "17.62",
      "PB": "2.9",
      "Div": "1.55"
  },
  {
      "Date": "8-Dec-11",
      "PE": "17.9",
      "PB": "2.95",
      "Div": "1.53"
  },
  {
      "Date": "7-Dec-11",
      "PE": "18.33",
      "PB": "3.02",
      "Div": "1.49"
  },
  {
      "Date": "5-Dec-11",
      "PE": "18.24",
      "PB": "3.01",
      "Div": "1.5"
  },
  {
      "Date": "2-Dec-11",
      "PE": "18.28",
      "PB": "3.01",
      "Div": "1.49"
  },
  {
      "Date": "1-Dec-11",
      "PE": "17.87",
      "PB": "2.95",
      "Div": "1.53"
  },
  {
      "Date": "30-Nov-11",
      "PE": "17.49",
      "PB": "2.89",
      "Div": "1.56"
  },
  {
      "Date": "29-Nov-11",
      "PE": "17.4",
      "PB": "2.87",
      "Div": "1.57"
  },
  {
      "Date": "28-Nov-11",
      "PE": "17.56",
      "PB": "2.91",
      "Div": "1.55"
  },
  {
      "Date": "25-Nov-11",
      "PE": "17.05",
      "PB": "2.82",
      "Div": "1.6"
  },
  {
      "Date": "24-Nov-11",
      "PE": "17.22",
      "PB": "2.85",
      "Div": "1.59"
  },
  {
      "Date": "23-Nov-11",
      "PE": "17.04",
      "PB": "2.82",
      "Div": "1.6"
  },
  {
      "Date": "22-Nov-11",
      "PE": "17.42",
      "PB": "2.89",
      "Div": "1.57"
  },
  {
      "Date": "21-Nov-11",
      "PE": "17.3",
      "PB": "2.87",
      "Div": "1.58"
  },
  {
      "Date": "18-Nov-11",
      "PE": "17.76",
      "PB": "2.94",
      "Div": "1.54"
  },
  {
      "Date": "17-Nov-11",
      "PE": "17.87",
      "PB": "2.96",
      "Div": "1.53"
  },
  {
      "Date": "16-Nov-11",
      "PE": "18.21",
      "PB": "3.02",
      "Div": "1.5"
  },
  {
      "Date": "15-Nov-11",
      "PE": "18.35",
      "PB": "3.04",
      "Div": "1.49"
  },
  {
      "Date": "14-Nov-11",
      "PE": "18.59",
      "PB": "3.09",
      "Div": "1.47"
  },
  {
      "Date": "11-Nov-11",
      "PE": "18.61",
      "PB": "3.1",
      "Div": "1.46"
  },
  {
      "Date": "9-Nov-11",
      "PE": "18.78",
      "PB": "3.13",
      "Div": "1.44"
  },
  {
      "Date": "8-Nov-11",
      "PE": "19.11",
      "PB": "3.17",
      "Div": "1.43"
  },
  {
      "Date": "4-Nov-11",
      "PE": "19.21",
      "PB": "3.17",
      "Div": "1.43"
  },
  {
      "Date": "3-Nov-11",
      "PE": "19.06",
      "PB": "3.16",
      "Div": "1.43"
  },
  {
      "Date": "2-Nov-11",
      "PE": "19.04",
      "PB": "3.15",
      "Div": "1.43"
  },
  {
      "Date": "1-Nov-11",
      "PE": "19.04",
      "PB": "3.15",
      "Div": "1.43"
  },
  {
      "Date": "31-Oct-11",
      "PE": "18.9",
      "PB": "3.19",
      "Div": "1.42"
  },
  {
      "Date": "28-Oct-11",
      "PE": "19.03",
      "PB": "3.21",
      "Div": "1.41"
  },
  {
      "Date": "26-Oct-11",
      "PE": "18.43",
      "PB": "3.12",
      "Div": "1.45"
  },
  {
      "Date": "25-Oct-11",
      "PE": "18.39",
      "PB": "3.11",
      "Div": "1.45"
  },
  {
      "Date": "24-Oct-11",
      "PE": "18.1",
      "PB": "3.06",
      "Div": "1.48"
  },
  {
      "Date": "21-Oct-11",
      "PE": "17.99",
      "PB": "3.03",
      "Div": "1.49"
  },
  {
      "Date": "20-Oct-11",
      "PE": "18.14",
      "PB": "3.05",
      "Div": "1.48"
  },
  {
      "Date": "19-Oct-11",
      "PE": "18.36",
      "PB": "3.08",
      "Div": "1.47"
  },
  {
      "Date": "18-Oct-11",
      "PE": "18",
      "PB": "3.02",
      "Div": "1.5"
  },
  {
      "Date": "17-Oct-11",
      "PE": "18.5",
      "PB": "3.07",
      "Div": "1.47"
  },
  {
      "Date": "14-Oct-11",
      "PE": "18.55",
      "PB": "3.08",
      "Div": "1.47"
  },
  {
      "Date": "13-Oct-11",
      "PE": "18.35",
      "PB": "3.04",
      "Div": "1.49"
  },
  {
      "Date": "12-Oct-11",
      "PE": "18.43",
      "PB": "3.06",
      "Div": "1.48"
  },
  {
      "Date": "11-Oct-11",
      "PE": "18.01",
      "PB": "2.98",
      "Div": "1.52"
  },
  {
      "Date": "10-Oct-11",
      "PE": "18.03",
      "PB": "2.99",
      "Div": "1.51"
  },
  {
      "Date": "7-Oct-11",
      "PE": "17.65",
      "PB": "2.89",
      "Div": "1.55"
  },
  {
      "Date": "5-Oct-11",
      "PE": "17.16",
      "PB": "2.81",
      "Div": "1.6"
  },
  {
      "Date": "4-Oct-11",
      "PE": "17.23",
      "PB": "2.82",
      "Div": "1.59"
  },
  {
      "Date": "3-Oct-11",
      "PE": "17.51",
      "PB": "2.86",
      "Div": "1.56"
  },
  {
      "Date": "30-Sep-11",
      "PE": "17.85",
      "PB": "2.92",
      "Div": "1.53"
  },
  {
      "Date": "29-Sep-11",
      "PE": "18.11",
      "PB": "2.96",
      "Div": "1.51"
  },
  {
      "Date": "28-Sep-11",
      "PE": "17.86",
      "PB": "2.92",
      "Div": "1.53"
  },
  {
      "Date": "27-Sep-11",
      "PE": "17.95",
      "PB": "2.94",
      "Div": "1.53"
  },
  {
      "Date": "26-Sep-11",
      "PE": "17.46",
      "PB": "2.86",
      "Div": "1.57"
  },
  {
      "Date": "23-Sep-11",
      "PE": "17.58",
      "PB": "2.87",
      "Div": "1.56"
  },
  {
      "Date": "22-Sep-11",
      "PE": "17.78",
      "PB": "2.91",
      "Div": "1.54"
  },
  {
      "Date": "21-Sep-11",
      "PE": "18.54",
      "PB": "3.03",
      "Div": "1.48"
  },
  {
      "Date": "20-Sep-11",
      "PE": "18.56",
      "PB": "3.04",
      "Div": "1.48"
  },
  {
      "Date": "19-Sep-11",
      "PE": "18.17",
      "PB": "2.97",
      "Div": "1.51"
  },
  {
      "Date": "16-Sep-11",
      "PE": "18.36",
      "PB": "3",
      "Div": "1.49"
  },
  {
      "Date": "15-Sep-11",
      "PE": "18.33",
      "PB": "3",
      "Div": "1.49"
  },
  {
      "Date": "14-Sep-11",
      "PE": "18.1",
      "PB": "2.96",
      "Div": "1.51"
  },
  {
      "Date": "13-Sep-11",
      "PE": "17.84",
      "PB": "2.92",
      "Div": "1.54"
  },
  {
      "Date": "12-Sep-11",
      "PE": "17.86",
      "PB": "2.92",
      "Div": "1.53"
  },
  {
      "Date": "9-Sep-11",
      "PE": "18.26",
      "PB": "2.99",
      "Div": "1.5"
  },
  {
      "Date": "8-Sep-11",
      "PE": "18.6",
      "PB": "3.04",
      "Div": "1.47"
  },
  {
      "Date": "7-Sep-11",
      "PE": "18.5",
      "PB": "3.03",
      "Div": "1.48"
  },
  {
      "Date": "6-Sep-11",
      "PE": "18.28",
      "PB": "2.99",
      "Div": "1.5"
  },
  {
      "Date": "5-Sep-11",
      "PE": "18.11",
      "PB": "2.96",
      "Div": "1.51"
  },
  {
      "Date": "2-Sep-11",
      "PE": "18.19",
      "PB": "2.98",
      "Div": "1.51"
  },
  {
      "Date": "30-Aug-11",
      "PE": "18.05",
      "PB": "2.95",
      "Div": "1.52"
  },
  {
      "Date": "29-Aug-11",
      "PE": "17.76",
      "PB": "2.9",
      "Div": "1.54"
  },
  {
      "Date": "26-Aug-11",
      "PE": "17.14",
      "PB": "2.8",
      "Div": "1.6"
  },
  {
      "Date": "25-Aug-11",
      "PE": "17.47",
      "PB": "2.86",
      "Div": "1.57"
  },
  {
      "Date": "24-Aug-11",
      "PE": "17.65",
      "PB": "2.89",
      "Div": "1.55"
  },
  {
      "Date": "23-Aug-11",
      "PE": "17.87",
      "PB": "2.92",
      "Div": "1.53"
  },
  {
      "Date": "22-Aug-11",
      "PE": "17.68",
      "PB": "2.89",
      "Div": "1.55"
  },
  {
      "Date": "19-Aug-11",
      "PE": "17.49",
      "PB": "2.86",
      "Div": "1.57"
  },
  {
      "Date": "18-Aug-11",
      "PE": "17.85",
      "PB": "2.92",
      "Div": "1.53"
  },
  {
      "Date": "17-Aug-11",
      "PE": "18.25",
      "PB": "3.12",
      "Div": "1.36"
  },
  {
      "Date": "16-Aug-11",
      "PE": "18.11",
      "PB": "3.11",
      "Div": "1.36"
  },
  {
      "Date": "12-Aug-11",
      "PE": "18.24",
      "PB": "3.13",
      "Div": "1.35"
  },
  {
      "Date": "11-Aug-11",
      "PE": "18.47",
      "PB": "3.17",
      "Div": "1.34"
  },
  {
      "Date": "10-Aug-11",
      "PE": "18.56",
      "PB": "3.19",
      "Div": "1.33"
  },
  {
      "Date": "9-Aug-11",
      "PE": "18.24",
      "PB": "3.13",
      "Div": "1.35"
  },
  {
      "Date": "8-Aug-11",
      "PE": "18.44",
      "PB": "3.16",
      "Div": "1.34"
  },
  {
      "Date": "5-Aug-11",
      "PE": "18.76",
      "PB": "3.21",
      "Div": "1.32"
  },
  {
      "Date": "4-Aug-11",
      "PE": "19.19",
      "PB": "3.29",
      "Div": "1.29"
  },
  {
      "Date": "3-Aug-11",
      "PE": "19.42",
      "PB": "3.33",
      "Div": "1.27"
  },
  {
      "Date": "2-Aug-11",
      "PE": "19.6",
      "PB": "3.39",
      "Div": "1.3"
  },
  {
      "Date": "1-Aug-11",
      "PE": "19.81",
      "PB": "3.42",
      "Div": "1.28"
  },
  {
      "Date": "29-Jul-11",
      "PE": "19.76",
      "PB": "3.4",
      "Div": "1.29"
  },
  {
      "Date": "28-Jul-11",
      "PE": "19.86",
      "PB": "3.4",
      "Div": "1.29"
  },
  {
      "Date": "27-Jul-11",
      "PE": "20.09",
      "PB": "3.44",
      "Div": "1.28"
  },
  {
      "Date": "26-Jul-11",
      "PE": "20.22",
      "PB": "3.46",
      "Div": "1.27"
  },
  {
      "Date": "25-Jul-11",
      "PE": "20.71",
      "PB": "3.52",
      "Div": "1.25"
  },
  {
      "Date": "22-Jul-11",
      "PE": "20.57",
      "PB": "3.5",
      "Div": "1.26"
  },
  {
      "Date": "21-Jul-11",
      "PE": "20.25",
      "PB": "3.44",
      "Div": "1.28"
  },
  {
      "Date": "20-Jul-11",
      "PE": "20.34",
      "PB": "3.45",
      "Div": "1.27"
  },
  {
      "Date": "19-Jul-11",
      "PE": "20.57",
      "PB": "3.48",
      "Div": "1.26"
  },
  {
      "Date": "18-Jul-11",
      "PE": "20.4",
      "PB": "3.45",
      "Div": "1.27"
  },
  {
      "Date": "15-Jul-11",
      "PE": "20.45",
      "PB": "3.46",
      "Div": "1.27"
  },
  {
      "Date": "14-Jul-11",
      "PE": "20.55",
      "PB": "3.47",
      "Div": "1.27"
  },
  {
      "Date": "13-Jul-11",
      "PE": "20.51",
      "PB": "3.46",
      "Div": "1.27"
  },
  {
      "Date": "12-Jul-11",
      "PE": "20.29",
      "PB": "3.43",
      "Div": "1.28"
  },
  {
      "Date": "11-Jul-11",
      "PE": "20.67",
      "PB": "3.48",
      "Div": "1.26"
  },
  {
      "Date": "8-Jul-11",
      "PE": "20.87",
      "PB": "3.51",
      "Div": "1.25"
  },
  {
      "Date": "7-Jul-11",
      "PE": "21.12",
      "PB": "3.55",
      "Div": "1.24"
  },
  {
      "Date": "6-Jul-11",
      "PE": "20.74",
      "PB": "3.49",
      "Div": "1.26"
  },
  {
      "Date": "5-Jul-11",
      "PE": "20.76",
      "PB": "3.49",
      "Div": "1.26"
  },
  {
      "Date": "4-Jul-11",
      "PE": "20.83",
      "PB": "3.5",
      "Div": "1.25"
  },
  {
      "Date": "1-Jul-11",
      "PE": "20.75",
      "PB": "3.49",
      "Div": "1.26"
  },
  {
      "Date": "30-Jun-11",
      "PE": "20.82",
      "PB": "3.5",
      "Div": "1.25"
  },
  {
      "Date": "29-Jun-11",
      "PE": "20.65",
      "PB": "3.47",
      "Div": "1.27"
  },
  {
      "Date": "28-Jun-11",
      "PE": "20.44",
      "PB": "3.44",
      "Div": "1.28"
  },
  {
      "Date": "27-Jun-11",
      "PE": "20.38",
      "PB": "3.43",
      "Div": "1.28"
  },
  {
      "Date": "24-Jun-11",
      "PE": "20.17",
      "PB": "3.39",
      "Div": "1.3"
  },
  {
      "Date": "23-Jun-11",
      "PE": "19.61",
      "PB": "3.3",
      "Div": "1.33"
  },
  {
      "Date": "22-Jun-11",
      "PE": "19.46",
      "PB": "3.27",
      "Div": "1.34"
  },
  {
      "Date": "21-Jun-11",
      "PE": "19.48",
      "PB": "3.27",
      "Div": "1.34"
  },
  {
      "Date": "20-Jun-11",
      "PE": "19.42",
      "PB": "3.26",
      "Div": "1.35"
  },
  {
      "Date": "17-Jun-11",
      "PE": "19.82",
      "PB": "3.33",
      "Div": "1.32"
  },
  {
      "Date": "16-Jun-11",
      "PE": "19.93",
      "PB": "3.35",
      "Div": "1.31"
  },
  {
      "Date": "15-Jun-11",
      "PE": "20.12",
      "PB": "3.38",
      "Div": "1.3"
  },
  {
      "Date": "14-Jun-11",
      "PE": "20.31",
      "PB": "3.41",
      "Div": "1.29"
  },
  {
      "Date": "13-Jun-11",
      "PE": "20.25",
      "PB": "3.4",
      "Div": "1.29"
  },
  {
      "Date": "10-Jun-11",
      "PE": "20.26",
      "PB": "3.4",
      "Div": "1.29"
  },
  {
      "Date": "9-Jun-11",
      "PE": "20.39",
      "PB": "3.42",
      "Div": "1.28"
  },
  {
      "Date": "8-Jun-11",
      "PE": "20.41",
      "PB": "3.43",
      "Div": "1.28"
  },
  {
      "Date": "7-Jun-11",
      "PE": "20.52",
      "PB": "3.44",
      "Div": "1.22"
  },
  {
      "Date": "6-Jun-11",
      "PE": "20.43",
      "PB": "3.43",
      "Div": "1.23"
  },
  {
      "Date": "3-Jun-11",
      "PE": "20.37",
      "PB": "3.42",
      "Div": "1.23"
  },
  {
      "Date": "2-Jun-11",
      "PE": "20.49",
      "PB": "3.44",
      "Div": "1.23"
  },
  {
      "Date": "1-Jun-11",
      "PE": "20.65",
      "PB": "3.47",
      "Div": "1.22"
  },
  {
      "Date": "31-May-11",
      "PE": "20.53",
      "PB": "3.45",
      "Div": "1.22"
  },
  {
      "Date": "30-May-11",
      "PE": "20.25",
      "PB": "3.39",
      "Div": "1.24"
  },
  {
      "Date": "27-May-11",
      "PE": "20.26",
      "PB": "3.44",
      "Div": "1.23"
  },
  {
      "Date": "26-May-11",
      "PE": "19.95",
      "PB": "3.4",
      "Div": "1.25"
  },
  {
      "Date": "25-May-11",
      "PE": "19.72",
      "PB": "3.36",
      "Div": "1.26"
  },
  {
      "Date": "24-May-11",
      "PE": "19.9",
      "PB": "3.38",
      "Div": "1.25"
  },
  {
      "Date": "23-May-11",
      "PE": "19.93",
      "PB": "3.38",
      "Div": "1.25"
  },
  {
      "Date": "20-May-11",
      "PE": "20.34",
      "PB": "3.44",
      "Div": "1.23"
  },
  {
      "Date": "19-May-11",
      "PE": "20.2",
      "PB": "3.41",
      "Div": "1.24"
  },
  {
      "Date": "18-May-11",
      "PE": "20.18",
      "PB": "3.4",
      "Div": "1.25"
  },
  {
      "Date": "17-May-11",
      "PE": "20.16",
      "PB": "3.41",
      "Div": "1.24"
  },
  {
      "Date": "16-May-11",
      "PE": "20.39",
      "PB": "3.45",
      "Div": "1.23"
  },
  {
      "Date": "13-May-11",
      "PE": "20.6",
      "PB": "3.48",
      "Div": "1.22"
  },
  {
      "Date": "12-May-11",
      "PE": "20.4",
      "PB": "3.45",
      "Div": "1.23"
  },
  {
      "Date": "11-May-11",
      "PE": "20.71",
      "PB": "3.5",
      "Div": "1.21"
  },
  {
      "Date": "10-May-11",
      "PE": "20.61",
      "PB": "3.48",
      "Div": "1.22"
  },
  {
      "Date": "9-May-11",
      "PE": "20.66",
      "PB": "3.49",
      "Div": "1.21"
  },
  {
      "Date": "6-May-11",
      "PE": "20.67",
      "PB": "3.49",
      "Div": "1.21"
  },
  {
      "Date": "5-May-11",
      "PE": "20.32",
      "PB": "3.43",
      "Div": "1.23"
  },
  {
      "Date": "4-May-11",
      "PE": "20.58",
      "PB": "3.51",
      "Div": "1.13"
  },
  {
      "Date": "3-May-11",
      "PE": "20.68",
      "PB": "3.53",
      "Div": "1.12"
  },
  {
      "Date": "2-May-11",
      "PE": "21.19",
      "PB": "3.62",
      "Div": "1.09"
  },
  {
      "Date": "29-Apr-11",
      "PE": "21.36",
      "PB": "3.65",
      "Div": "1.09"
  },
  {
      "Date": "28-Apr-11",
      "PE": "21.61",
      "PB": "3.67",
      "Div": "1.08"
  },
  {
      "Date": "27-Apr-11",
      "PE": "21.79",
      "PB": "3.7",
      "Div": "1.07"
  },
  {
      "Date": "26-Apr-11",
      "PE": "21.91",
      "PB": "3.72",
      "Div": "1.06"
  },
  {
      "Date": "25-Apr-11",
      "PE": "21.94",
      "PB": "3.73",
      "Div": "1.06"
  },
  {
      "Date": "21-Apr-11",
      "PE": "22.18",
      "PB": "3.73",
      "Div": "1.06"
  },
  {
      "Date": "20-Apr-11",
      "PE": "22.06",
      "PB": "3.71",
      "Div": "1.07"
  },
  {
      "Date": "19-Apr-11",
      "PE": "21.65",
      "PB": "3.64",
      "Div": "1.09"
  },
  {
      "Date": "18-Apr-11",
      "PE": "21.66",
      "PB": "3.63",
      "Div": "1.09"
  },
  {
      "Date": "15-Apr-11",
      "PE": "22.02",
      "PB": "3.69",
      "Div": "1.07"
  },
  {
      "Date": "13-Apr-11",
      "PE": "22.43",
      "PB": "3.75",
      "Div": "1.06"
  },
  {
      "Date": "11-Apr-11",
      "PE": "21.96",
      "PB": "3.67",
      "Div": "1.08"
  },
  {
      "Date": "8-Apr-11",
      "PE": "22.18",
      "PB": "3.71",
      "Div": "1.07"
  },
  {
      "Date": "7-Apr-11",
      "PE": "22.34",
      "PB": "3.73",
      "Div": "1.06"
  },
  {
      "Date": "6-Apr-11",
      "PE": "22.36",
      "PB": "3.74",
      "Div": "1.06"
  },
  {
      "Date": "5-Apr-11",
      "PE": "22.43",
      "PB": "3.75",
      "Div": "1.06"
  },
  {
      "Date": "4-Apr-11",
      "PE": "22.43",
      "PB": "3.75",
      "Div": "1.06"
  },
  {
      "Date": "1-Apr-11",
      "PE": "22.11",
      "PB": "3.7",
      "Div": "1.07"
  },
  {
      "Date": "31-Mar-11",
      "PE": "22.14",
      "PB": "3.7",
      "Div": "1.07"
  },
  {
      "Date": "30-Mar-11",
      "PE": "21.97",
      "PB": "3.67",
      "Div": "1.08"
  },
  {
      "Date": "29-Mar-11",
      "PE": "21.77",
      "PB": "3.64",
      "Div": "1.09"
  },
  {
      "Date": "28-Mar-11",
      "PE": "21.59",
      "PB": "3.61",
      "Div": "1.1"
  },
  {
      "Date": "25-Mar-11",
      "PE": "21.46",
      "PB": "3.59",
      "Div": "1.1"
  },
  {
      "Date": "24-Mar-11",
      "PE": "21.15",
      "PB": "3.5",
      "Div": "1.13"
  },
  {
      "Date": "23-Mar-11",
      "PE": "20.99",
      "PB": "3.47",
      "Div": "1.13"
  },
  {
      "Date": "22-Mar-11",
      "PE": "20.73",
      "PB": "3.43",
      "Div": "1.15"
  },
  {
      "Date": "21-Mar-11",
      "PE": "20.54",
      "PB": "3.4",
      "Div": "1.16"
  },
  {
      "Date": "18-Mar-11",
      "PE": "20.58",
      "PB": "3.4",
      "Div": "1.16"
  },
  {
      "Date": "17-Mar-11",
      "PE": "20.86",
      "PB": "3.45",
      "Div": "1.14"
  },
  {
      "Date": "16-Mar-11",
      "PE": "21.1",
      "PB": "3.49",
      "Div": "1.13"
  },
  {
      "Date": "15-Mar-11",
      "PE": "20.87",
      "PB": "3.45",
      "Div": "1.14"
  },
  {
      "Date": "14-Mar-11",
      "PE": "21.18",
      "PB": "3.5",
      "Div": "1.12"
  },
  {
      "Date": "11-Mar-11",
      "PE": "20.85",
      "PB": "3.45",
      "Div": "1.14"
  },
  {
      "Date": "10-Mar-11",
      "PE": "21.04",
      "PB": "3.48",
      "Div": "1.13"
  },
  {
      "Date": "9-Mar-11",
      "PE": "21.18",
      "PB": "3.5",
      "Div": "1.12"
  },
  {
      "Date": "8-Mar-11",
      "PE": "21.14",
      "PB": "3.5",
      "Div": "1.13"
  },
  {
      "Date": "7-Mar-11",
      "PE": "20.92",
      "PB": "3.46",
      "Div": "1.14"
  },
  {
      "Date": "4-Mar-11",
      "PE": "21.21",
      "PB": "3.51",
      "Div": "1.12"
  },
  {
      "Date": "3-Mar-11",
      "PE": "21.2",
      "PB": "3.51",
      "Div": "1.12"
  },
  {
      "Date": "1-Mar-11",
      "PE": "21.14",
      "PB": "3.5",
      "Div": "1.13"
  },
  {
      "Date": "28-Feb-11",
      "PE": "20.42",
      "PB": "3.38",
      "Div": "1.17"
  },
  {
      "Date": "25-Feb-11",
      "PE": "20.31",
      "PB": "3.36",
      "Div": "1.17"
  },
  {
      "Date": "24-Feb-11",
      "PE": "20.15",
      "PB": "3.33",
      "Div": "1.18"
  },
  {
      "Date": "23-Feb-11",
      "PE": "20.82",
      "PB": "3.44",
      "Div": "1.14"
  },
  {
      "Date": "22-Feb-11",
      "PE": "20.9",
      "PB": "3.46",
      "Div": "1.14"
  },
  {
      "Date": "21-Feb-11",
      "PE": "21.09",
      "PB": "3.49",
      "Div": "1.13"
  },
  {
      "Date": "18-Feb-11",
      "PE": "20.86",
      "PB": "3.46",
      "Div": "1.14"
  },
  {
      "Date": "17-Feb-11",
      "PE": "21.19",
      "PB": "3.51",
      "Div": "1.12"
  },
  {
      "Date": "16-Feb-11",
      "PE": "20.95",
      "PB": "3.47",
      "Div": "1.13"
  },
  {
      "Date": "15-Feb-11",
      "PE": "20.96",
      "PB": "3.47",
      "Div": "1.13"
  },
  {
      "Date": "14-Feb-11",
      "PE": "20.86",
      "PB": "3.45",
      "Div": "1.14"
  },
  {
      "Date": "11-Feb-11",
      "PE": "20.34",
      "PB": "3.35",
      "Div": "1.17"
  },
  {
      "Date": "10-Feb-11",
      "PE": "20.01",
      "PB": "3.3",
      "Div": "1.19"
  },
  {
      "Date": "9-Feb-11",
      "PE": "20.12",
      "PB": "3.32",
      "Div": "1.19"
  },
  {
      "Date": "8-Feb-11",
      "PE": "20.35",
      "PB": "3.35",
      "Div": "1.17"
  },
  {
      "Date": "7-Feb-11",
      "PE": "20.69",
      "PB": "3.41",
      "Div": "1.15"
  },
  {
      "Date": "4-Feb-11",
      "PE": "20.67",
      "PB": "3.4",
      "Div": "1.15"
  },
  {
      "Date": "3-Feb-11",
      "PE": "21.16",
      "PB": "3.49",
      "Div": "1.13"
  },
  {
      "Date": "2-Feb-11",
      "PE": "20.8",
      "PB": "3.43",
      "Div": "1.15"
  },
  {
      "Date": "1-Feb-11",
      "PE": "20.7",
      "PB": "3.42",
      "Div": "1.15"
  },
  {
      "Date": "31-Jan-11",
      "PE": "21.06",
      "PB": "3.47",
      "Div": "1.13"
  },
  {
      "Date": "28-Jan-11",
      "PE": "21.23",
      "PB": "3.48",
      "Div": "1.13"
  },
  {
      "Date": "27-Jan-11",
      "PE": "21.64",
      "PB": "3.53",
      "Div": "1.11"
  },
  {
      "Date": "25-Jan-11",
      "PE": "21.96",
      "PB": "3.59",
      "Div": "1.1"
  },
  {
      "Date": "24-Jan-11",
      "PE": "22.26",
      "PB": "3.62",
      "Div": "1.08"
  },
  {
      "Date": "21-Jan-11",
      "PE": "22.41",
      "PB": "3.59",
      "Div": "1.09"
  },
  {
      "Date": "20-Jan-11",
      "PE": "22.59",
      "PB": "3.6",
      "Div": "1.09"
  },
  {
      "Date": "19-Jan-11",
      "PE": "22.51",
      "PB": "3.59",
      "Div": "1.09"
  },
  {
      "Date": "18-Jan-11",
      "PE": "22.66",
      "PB": "3.61",
      "Div": "1.09"
  },
  {
      "Date": "17-Jan-11",
      "PE": "22.5",
      "PB": "3.57",
      "Div": "1.1"
  },
  {
      "Date": "14-Jan-11",
      "PE": "22.5",
      "PB": "3.57",
      "Div": "1.1"
  },
  {
      "Date": "13-Jan-11",
      "PE": "22.92",
      "PB": "3.63",
      "Div": "1.08"
  },
  {
      "Date": "12-Jan-11",
      "PE": "23.41",
      "PB": "3.7",
      "Div": "1.06"
  },
  {
      "Date": "11-Jan-11",
      "PE": "22.98",
      "PB": "3.63",
      "Div": "1.08"
  },
  {
      "Date": "10-Jan-11",
      "PE": "23.01",
      "PB": "3.63",
      "Div": "1.08"
  },
  {
      "Date": "7-Jan-11",
      "PE": "23.56",
      "PB": "3.72",
      "Div": "1.06"
  },
  {
      "Date": "6-Jan-11",
      "PE": "24.14",
      "PB": "3.81",
      "Div": "1.03"
  },
  {
      "Date": "5-Jan-11",
      "PE": "24.26",
      "PB": "3.83",
      "Div": "1.03"
  },
  {
      "Date": "4-Jan-11",
      "PE": "24.53",
      "PB": "3.87",
      "Div": "1.01"
  },
  {
      "Date": "3-Jan-11",
      "PE": "24.57",
      "PB": "3.88",
      "Div": "1.01"
  },
  {
      "Date": "31-Dec-10",
      "PE": "24.48",
      "PB": "3.87",
      "Div": "1.02"
  },
  {
      "Date": "30-Dec-10",
      "PE": "24.35",
      "PB": "3.85",
      "Div": "1.02"
  },
  {
      "Date": "29-Dec-10",
      "PE": "24.19",
      "PB": "3.82",
      "Div": "1.03"
  },
  {
      "Date": "28-Dec-10",
      "PE": "23.93",
      "PB": "3.78",
      "Div": "1.04"
  },
  {
      "Date": "27-Dec-10",
      "PE": "23.94",
      "PB": "3.78",
      "Div": "1.04"
  },
  {
      "Date": "24-Dec-10",
      "PE": "23.99",
      "PB": "3.79",
      "Div": "1.04"
  },
  {
      "Date": "23-Dec-10",
      "PE": "23.86",
      "PB": "3.77",
      "Div": "1.04"
  },
  {
      "Date": "22-Dec-10",
      "PE": "23.88",
      "PB": "3.77",
      "Div": "1.04"
  },
  {
      "Date": "21-Dec-10",
      "PE": "23.95",
      "PB": "3.78",
      "Div": "1.04"
  },
  {
      "Date": "20-Dec-10",
      "PE": "23.73",
      "PB": "3.75",
      "Div": "1.05"
  },
  {
      "Date": "16-Dec-10",
      "PE": "23.74",
      "PB": "3.75",
      "Div": "1.05"
  },
  {
      "Date": "15-Dec-10",
      "PE": "23.51",
      "PB": "3.71",
      "Div": "1.06"
  },
  {
      "Date": "14-Dec-10",
      "PE": "23.72",
      "PB": "3.75",
      "Div": "1.05"
  },
  {
      "Date": "13-Dec-10",
      "PE": "23.58",
      "PB": "3.72",
      "Div": "1.06"
  },
  {
      "Date": "10-Dec-10",
      "PE": "23.35",
      "PB": "3.69",
      "Div": "1.07"
  },
  {
      "Date": "9-Dec-10",
      "PE": "22.98",
      "PB": "3.63",
      "Div": "1.08"
  },
  {
      "Date": "8-Dec-10",
      "PE": "23.53",
      "PB": "3.72",
      "Div": "1.06"
  },
  {
      "Date": "7-Dec-10",
      "PE": "23.82",
      "PB": "3.76",
      "Div": "1.04"
  },
  {
      "Date": "6-Dec-10",
      "PE": "23.88",
      "PB": "3.77",
      "Div": "1.04"
  },
  {
      "Date": "3-Dec-10",
      "PE": "23.89",
      "PB": "3.77",
      "Div": "1.04"
  },
  {
      "Date": "2-Dec-10",
      "PE": "23.96",
      "PB": "3.78",
      "Div": "1.04"
  },
  {
      "Date": "1-Dec-10",
      "PE": "23.78",
      "PB": "3.76",
      "Div": "1.05"
  },
  {
      "Date": "30-Nov-10",
      "PE": "23.39",
      "PB": "3.69",
      "Div": "1.06"
  },
  {
      "Date": "29-Nov-10",
      "PE": "23.26",
      "PB": "3.67",
      "Div": "1.07"
  },
  {
      "Date": "26-Nov-10",
      "PE": "22.96",
      "PB": "3.63",
      "Div": "1.08"
  },
  {
      "Date": "25-Nov-10",
      "PE": "23.15",
      "PB": "3.66",
      "Div": "1.08"
  },
  {
      "Date": "24-Nov-10",
      "PE": "23.41",
      "PB": "3.7",
      "Div": "1.06"
  },
  {
      "Date": "23-Nov-10",
      "PE": "23.69",
      "PB": "3.74",
      "Div": "1.05"
  },
  {
      "Date": "22-Nov-10",
      "PE": "23.98",
      "PB": "3.79",
      "Div": "1.04"
  },
  {
      "Date": "19-Nov-10",
      "PE": "23.47",
      "PB": "3.71",
      "Div": "1.06"
  },
  {
      "Date": "18-Nov-10",
      "PE": "23.9",
      "PB": "3.78",
      "Div": "1.04"
  },
  {
      "Date": "16-Nov-10",
      "PE": "23.86",
      "PB": "3.77",
      "Div": "1.04"
  },
  {
      "Date": "15-Nov-10",
      "PE": "24.39",
      "PB": "3.86",
      "Div": "1.02"
  },
  {
      "Date": "12-Nov-10",
      "PE": "24.62",
      "PB": "3.82",
      "Div": "1.03"
  },
  {
      "Date": "11-Nov-10",
      "PE": "25.11",
      "PB": "3.89",
      "Div": "1.01"
  },
  {
      "Date": "10-Nov-10",
      "PE": "25.44",
      "PB": "3.94",
      "Div": "1"
  },
  {
      "Date": "9-Nov-10",
      "PE": "25.55",
      "PB": "3.96",
      "Div": "0.99"
  },
  {
      "Date": "8-Nov-10",
      "PE": "25.43",
      "PB": "3.94",
      "Div": "1"
  },
  {
      "Date": "5-Nov-10",
      "PE": "25.59",
      "PB": "3.97",
      "Div": "0.99"
  },
  {
      "Date": "4-Nov-10",
      "PE": "25.47",
      "PB": "3.95",
      "Div": "0.99"
  },
  {
      "Date": "3-Nov-10",
      "PE": "25.3",
      "PB": "3.87",
      "Div": "1.01"
  },
  {
      "Date": "2-Nov-10",
      "PE": "25.13",
      "PB": "3.85",
      "Div": "1.02"
  },
  {
      "Date": "1-Nov-10",
      "PE": "25.12",
      "PB": "3.85",
      "Div": "1.02"
  },
  {
      "Date": "29-Oct-10",
      "PE": "24.71",
      "PB": "3.78",
      "Div": "1.04"
  },
  {
      "Date": "28-Oct-10",
      "PE": "24.59",
      "PB": "3.76",
      "Div": "1.04"
  },
  {
      "Date": "27-Oct-10",
      "PE": "24.69",
      "PB": "3.78",
      "Div": "1.04"
  },
  {
      "Date": "26-Oct-10",
      "PE": "24.98",
      "PB": "3.82",
      "Div": "1.03"
  },
  {
      "Date": "25-Oct-10",
      "PE": "25.2",
      "PB": "3.84",
      "Div": "1.03"
  },
  {
      "Date": "22-Oct-10",
      "PE": "25.03",
      "PB": "3.82",
      "Div": "1.03"
  },
  {
      "Date": "21-Oct-10",
      "PE": "25.18",
      "PB": "3.84",
      "Div": "1.03"
  },
  {
      "Date": "20-Oct-10",
      "PE": "24.69",
      "PB": "3.76",
      "Div": "1.05"
  },
  {
      "Date": "19-Oct-10",
      "PE": "24.87",
      "PB": "3.79",
      "Div": "1.04"
  },
  {
      "Date": "18-Oct-10",
      "PE": "25.07",
      "PB": "3.82",
      "Div": "1.03"
  },
  {
      "Date": "15-Oct-10",
      "PE": "25.2",
      "PB": "3.81",
      "Div": "1.03"
  },
  {
      "Date": "14-Oct-10",
      "PE": "25.68",
      "PB": "3.89",
      "Div": "1.02"
  },
  {
      "Date": "13-Oct-10",
      "PE": "25.91",
      "PB": "3.92",
      "Div": "1.01"
  },
  {
      "Date": "12-Oct-10",
      "PE": "25.32",
      "PB": "3.83",
      "Div": "1.03"
  },
  {
      "Date": "11-Oct-10",
      "PE": "25.51",
      "PB": "3.86",
      "Div": "1.02"
  },
  {
      "Date": "8-Oct-10",
      "PE": "25.37",
      "PB": "3.84",
      "Div": "1.03"
  },
  {
      "Date": "7-Oct-10",
      "PE": "25.44",
      "PB": "3.85",
      "Div": "1.02"
  },
  {
      "Date": "6-Oct-10",
      "PE": "25.72",
      "PB": "3.89",
      "Div": "1.01"
  },
  {
      "Date": "5-Oct-10",
      "PE": "25.55",
      "PB": "3.87",
      "Div": "1.02"
  },
  {
      "Date": "4-Oct-10",
      "PE": "25.61",
      "PB": "3.88",
      "Div": "1.02"
  },
  {
      "Date": "1-Oct-10",
      "PE": "25.54",
      "PB": "3.93",
      "Div": "1.02"
  },
  {
      "Date": "30-Sep-10",
      "PE": "25.46",
      "PB": "3.82",
      "Div": "1.03"
  },
  {
      "Date": "29-Sep-10",
      "PE": "25.29",
      "PB": "3.79",
      "Div": "1.03"
  },
  {
      "Date": "28-Sep-10",
      "PE": "25.45",
      "PB": "3.82",
      "Div": "1.03"
  },
  {
      "Date": "27-Sep-10",
      "PE": "25.48",
      "PB": "3.89",
      "Div": "1.01"
  },
  {
      "Date": "24-Sep-10",
      "PE": "25.41",
      "PB": "3.88",
      "Div": "1.02"
  },
  {
      "Date": "23-Sep-10",
      "PE": "25.16",
      "PB": "3.84",
      "Div": "1.03"
  },
  {
      "Date": "22-Sep-10",
      "PE": "25.29",
      "PB": "3.86",
      "Div": "1.02"
  },
  {
      "Date": "21-Sep-10",
      "PE": "25.37",
      "PB": "3.87",
      "Div": "1.02"
  },
  {
      "Date": "20-Sep-10",
      "PE": "25.25",
      "PB": "3.93",
      "Div": "1.01"
  },
  {
      "Date": "17-Sep-10",
      "PE": "24.84",
      "PB": "3.87",
      "Div": "1.03"
  },
  {
      "Date": "16-Sep-10",
      "PE": "24.61",
      "PB": "3.83",
      "Div": "1.04"
  },
  {
      "Date": "15-Sep-10",
      "PE": "24.74",
      "PB": "3.85",
      "Div": "1.03"
  },
  {
      "Date": "14-Sep-10",
      "PE": "24.47",
      "PB": "3.81",
      "Div": "1.04"
  },
  {
      "Date": "13-Sep-10",
      "PE": "24.32",
      "PB": "3.78",
      "Div": "1.05"
  },
  {
      "Date": "9-Sep-10",
      "PE": "23.73",
      "PB": "3.69",
      "Div": "1.07"
  },
  {
      "Date": "8-Sep-10",
      "PE": "23.59",
      "PB": "3.67",
      "Div": "1.08"
  },
  {
      "Date": "7-Sep-10",
      "PE": "23.58",
      "PB": "3.67",
      "Div": "1.08"
  },
  {
      "Date": "6-Sep-10",
      "PE": "23.46",
      "PB": "3.65",
      "Div": "1.09"
  },
  {
      "Date": "3-Sep-10",
      "PE": "23.05",
      "PB": "3.59",
      "Div": "1.1"
  },
  {
      "Date": "2-Sep-10",
      "PE": "23.08",
      "PB": "3.59",
      "Div": "1.1"
  },
  {
      "Date": "1-Sep-10",
      "PE": "23.02",
      "PB": "3.58",
      "Div": "1.11"
  },
  {
      "Date": "31-Aug-10",
      "PE": "22.73",
      "PB": "3.54",
      "Div": "1.12"
  },
  {
      "Date": "30-Aug-10",
      "PE": "22.78",
      "PB": "3.55",
      "Div": "1.12"
  },
  {
      "Date": "27-Aug-10",
      "PE": "22.76",
      "PB": "3.54",
      "Div": "1.12"
  },
  {
      "Date": "26-Aug-10",
      "PE": "23.05",
      "PB": "3.59",
      "Div": "1.11"
  },
  {
      "Date": "25-Aug-10",
      "PE": "22.98",
      "PB": "3.58",
      "Div": "1.11"
  },
  {
      "Date": "24-Aug-10",
      "PE": "23.13",
      "PB": "3.61",
      "Div": "1.1"
  },
  {
      "Date": "23-Aug-10",
      "PE": "23.29",
      "PB": "3.63",
      "Div": "1.09"
  },
  {
      "Date": "20-Aug-10",
      "PE": "23.24",
      "PB": "3.62",
      "Div": "1.09"
  },
  {
      "Date": "19-Aug-10",
      "PE": "23.28",
      "PB": "3.63",
      "Div": "1.09"
  },
  {
      "Date": "18-Aug-10",
      "PE": "23.02",
      "PB": "3.59",
      "Div": "1.1"
  },
  {
      "Date": "17-Aug-10",
      "PE": "22.75",
      "PB": "3.55",
      "Div": "1.12"
  },
  {
      "Date": "16-Aug-10",
      "PE": "22.76",
      "PB": "3.55",
      "Div": "1.12"
  },
  {
      "Date": "13-Aug-10",
      "PE": "22.86",
      "PB": "3.57",
      "Div": "1.11"
  },
  {
      "Date": "12-Aug-10",
      "PE": "22.71",
      "PB": "3.55",
      "Div": "1.12"
  },
  {
      "Date": "11-Aug-10",
      "PE": "22.72",
      "PB": "3.55",
      "Div": "1.12"
  },
  {
      "Date": "10-Aug-10",
      "PE": "22.89",
      "PB": "3.58",
      "Div": "1.11"
  },
  {
      "Date": "9-Aug-10",
      "PE": "23",
      "PB": "3.59",
      "Div": "1.1"
  },
  {
      "Date": "6-Aug-10",
      "PE": "22.95",
      "PB": "3.56",
      "Div": "1.11"
  },
  {
      "Date": "5-Aug-10",
      "PE": "22.98",
      "PB": "3.69",
      "Div": "1.04"
  },
  {
      "Date": "4-Aug-10",
      "PE": "23.07",
      "PB": "3.7",
      "Div": "1.03"
  },
  {
      "Date": "3-Aug-10",
      "PE": "22.95",
      "PB": "3.68",
      "Div": "1.04"
  },
  {
      "Date": "2-Aug-10",
      "PE": "22.91",
      "PB": "3.83",
      "Div": "1.01"
  },
  {
      "Date": "30-Jul-10",
      "PE": "22.31",
      "PB": "3.78",
      "Div": "1.02"
  },
  {
      "Date": "29-Jul-10",
      "PE": "22.49",
      "PB": "3.81",
      "Div": "1.01"
  },
  {
      "Date": "28-Jul-10",
      "PE": "22.44",
      "PB": "3.85",
      "Div": "0.91"
  },
  {
      "Date": "27-Jul-10",
      "PE": "22.58",
      "PB": "3.87",
      "Div": "0.9"
  },
  {
      "Date": "26-Jul-10",
      "PE": "22.53",
      "PB": "3.87",
      "Div": "0.91"
  },
  {
      "Date": "23-Jul-10",
      "PE": "22.78",
      "PB": "3.89",
      "Div": "0.9"
  },
  {
      "Date": "22-Jul-10",
      "PE": "22.75",
      "PB": "3.88",
      "Div": "0.9"
  },
  {
      "Date": "21-Jul-10",
      "PE": "22.66",
      "PB": "3.85",
      "Div": "0.91"
  },
  {
      "Date": "20-Jul-10",
      "PE": "22.53",
      "PB": "3.83",
      "Div": "0.91"
  },
  {
      "Date": "19-Jul-10",
      "PE": "22.61",
      "PB": "3.84",
      "Div": "0.91"
  },
  {
      "Date": "16-Jul-10",
      "PE": "22.61",
      "PB": "3.84",
      "Div": "0.91"
  },
  {
      "Date": "15-Jul-10",
      "PE": "22.54",
      "PB": "3.83",
      "Div": "0.91"
  },
  {
      "Date": "14-Jul-10",
      "PE": "22.57",
      "PB": "3.84",
      "Div": "0.91"
  },
  {
      "Date": "13-Jul-10",
      "PE": "22.63",
      "PB": "3.85",
      "Div": "0.91"
  },
  {
      "Date": "12-Jul-10",
      "PE": "22.56",
      "PB": "3.83",
      "Div": "0.91"
  },
  {
      "Date": "9-Jul-10",
      "PE": "22.42",
      "PB": "3.81",
      "Div": "0.92"
  },
  {
      "Date": "8-Jul-10",
      "PE": "22.18",
      "PB": "3.77",
      "Div": "0.93"
  },
  {
      "Date": "7-Jul-10",
      "PE": "21.95",
      "PB": "3.73",
      "Div": "0.94"
  },
  {
      "Date": "6-Jul-10",
      "PE": "22.15",
      "PB": "3.76",
      "Div": "0.93"
  },
  {
      "Date": "5-Jul-10",
      "PE": "21.93",
      "PB": "3.73",
      "Div": "0.94"
  },
  {
      "Date": "2-Jul-10",
      "PE": "21.93",
      "PB": "3.73",
      "Div": "0.94"
  },
  {
      "Date": "1-Jul-10",
      "PE": "21.99",
      "PB": "3.74",
      "Div": "0.94"
  },
  {
      "Date": "30-Jun-10",
      "PE": "22.25",
      "PB": "3.78",
      "Div": "0.93"
  },
  {
      "Date": "29-Jun-10",
      "PE": "22.01",
      "PB": "3.74",
      "Div": "0.94"
  },
  {
      "Date": "28-Jun-10",
      "PE": "22.34",
      "PB": "3.8",
      "Div": "0.92"
  },
  {
      "Date": "25-Jun-10",
      "PE": "22.07",
      "PB": "3.75",
      "Div": "0.93"
  },
  {
      "Date": "24-Jun-10",
      "PE": "22.28",
      "PB": "3.79",
      "Div": "0.92"
  },
  {
      "Date": "23-Jun-10",
      "PE": "22.29",
      "PB": "3.79",
      "Div": "0.92"
  },
  {
      "Date": "22-Jun-10",
      "PE": "22.27",
      "PB": "3.78",
      "Div": "0.93"
  },
  {
      "Date": "21-Jun-10",
      "PE": "22.42",
      "PB": "3.81",
      "Div": "0.92"
  },
  {
      "Date": "18-Jun-10",
      "PE": "22.04",
      "PB": "3.75",
      "Div": "0.94"
  },
  {
      "Date": "17-Jun-10",
      "PE": "22.09",
      "PB": "3.75",
      "Div": "0.93"
  },
  {
      "Date": "16-Jun-10",
      "PE": "21.92",
      "PB": "3.72",
      "Div": "0.94"
  },
  {
      "Date": "15-Jun-10",
      "PE": "21.87",
      "PB": "3.72",
      "Div": "0.94"
  },
  {
      "Date": "14-Jun-10",
      "PE": "21.77",
      "PB": "3.7",
      "Div": "0.95"
  },
  {
      "Date": "11-Jun-10",
      "PE": "21.44",
      "PB": "3.64",
      "Div": "0.96"
  },
  {
      "Date": "10-Jun-10",
      "PE": "21.3",
      "PB": "3.61",
      "Div": "0.97"
  },
  {
      "Date": "9-Jun-10",
      "PE": "20.97",
      "PB": "3.56",
      "Div": "0.98"
  },
  {
      "Date": "8-Jun-10",
      "PE": "20.91",
      "PB": "3.55",
      "Div": "0.99"
  },
  {
      "Date": "7-Jun-10",
      "PE": "21.11",
      "PB": "3.58",
      "Div": "0.98"
  },
  {
      "Date": "4-Jun-10",
      "PE": "21.5",
      "PB": "3.65",
      "Div": "0.96"
  },
  {
      "Date": "3-Jun-10",
      "PE": "21.4",
      "PB": "3.63",
      "Div": "0.96"
  },
  {
      "Date": "2-Jun-10",
      "PE": "21.02",
      "PB": "3.57",
      "Div": "0.98"
  },
  {
      "Date": "1-Jun-10",
      "PE": "20.81",
      "PB": "3.53",
      "Div": "0.99"
  },
  {
      "Date": "31-May-10",
      "PE": "21.3",
      "PB": "3.61",
      "Div": "0.97"
  },
  {
      "Date": "28-May-10",
      "PE": "21.22",
      "PB": "3.6",
      "Div": "0.97"
  },
  {
      "Date": "27-May-10",
      "PE": "20.88",
      "PB": "3.56",
      "Div": "0.98"
  },
  {
      "Date": "26-May-10",
      "PE": "20.52",
      "PB": "3.49",
      "Div": "1"
  },
  {
      "Date": "25-May-10",
      "PE": "20.06",
      "PB": "3.42",
      "Div": "1.03"
  },
  {
      "Date": "24-May-10",
      "PE": "20.63",
      "PB": "3.51",
      "Div": "1"
  },
  {
      "Date": "21-May-10",
      "PE": "20.58",
      "PB": "3.5",
      "Div": "1"
  },
  {
      "Date": "20-May-10",
      "PE": "20.65",
      "PB": "3.52",
      "Div": "1"
  },
  {
      "Date": "19-May-10",
      "PE": "20.53",
      "PB": "3.5",
      "Div": "1"
  },
  {
      "Date": "18-May-10",
      "PE": "21.14",
      "PB": "3.6",
      "Div": "0.97"
  },
  {
      "Date": "17-May-10",
      "PE": "21.12",
      "PB": "3.6",
      "Div": "0.97"
  },
  {
      "Date": "14-May-10",
      "PE": "21.26",
      "PB": "3.62",
      "Div": "0.97"
  },
  {
      "Date": "13-May-10",
      "PE": "21.86",
      "PB": "3.68",
      "Div": "0.95"
  },
  {
      "Date": "12-May-10",
      "PE": "21.77",
      "PB": "3.66",
      "Div": "0.96"
  },
  {
      "Date": "11-May-10",
      "PE": "21.68",
      "PB": "3.65",
      "Div": "0.96"
  },
  {
      "Date": "10-May-10",
      "PE": "21.92",
      "PB": "3.69",
      "Div": "0.95"
  },
  {
      "Date": "7-May-10",
      "PE": "21.19",
      "PB": "3.57",
      "Div": "0.97"
  },
  {
      "Date": "6-May-10",
      "PE": "21.5",
      "PB": "3.62",
      "Div": "0.96"
  },
  {
      "Date": "5-May-10",
      "PE": "21.64",
      "PB": "3.64",
      "Div": "0.95"
  },
  {
      "Date": "4-May-10",
      "PE": "21.74",
      "PB": "3.66",
      "Div": "0.95"
  },
  {
      "Date": "3-May-10",
      "PE": "22.06",
      "PB": "3.71",
      "Div": "0.93"
  },
  {
      "Date": "30-Apr-10",
      "PE": "22.29",
      "PB": "3.75",
      "Div": "0.92"
  },
  {
      "Date": "29-Apr-10",
      "PE": "22.22",
      "PB": "3.74",
      "Div": "0.93"
  },
  {
      "Date": "28-Apr-10",
      "PE": "22.05",
      "PB": "3.71",
      "Div": "0.94"
  },
  {
      "Date": "27-Apr-10",
      "PE": "22.45",
      "PB": "3.77",
      "Div": "0.92"
  },
  {
      "Date": "26-Apr-10",
      "PE": "23.11",
      "PB": "3.78",
      "Div": "0.92"
  },
  {
      "Date": "23-Apr-10",
      "PE": "23.03",
      "PB": "3.77",
      "Div": "0.92"
  },
  {
      "Date": "22-Apr-10",
      "PE": "22.88",
      "PB": "3.75",
      "Div": "0.93"
  },
  {
      "Date": "21-Apr-10",
      "PE": "22.78",
      "PB": "3.73",
      "Div": "0.93"
  },
  {
      "Date": "20-Apr-10",
      "PE": "22.71",
      "PB": "3.72",
      "Div": "0.93"
  },
  {
      "Date": "19-Apr-10",
      "PE": "22.58",
      "PB": "3.7",
      "Div": "0.94"
  },
  {
      "Date": "16-Apr-10",
      "PE": "22.78",
      "PB": "3.74",
      "Div": "0.93"
  },
  {
      "Date": "15-Apr-10",
      "PE": "22.83",
      "PB": "3.75",
      "Div": "0.93"
  },
  {
      "Date": "13-Apr-10",
      "PE": "23.04",
      "PB": "3.78",
      "Div": "0.92"
  },
  {
      "Date": "12-Apr-10",
      "PE": "23.11",
      "PB": "3.79",
      "Div": "0.91"
  },
  {
      "Date": "9-Apr-10",
      "PE": "23.21",
      "PB": "3.81",
      "Div": "0.91"
  },
  {
      "Date": "8-Apr-10",
      "PE": "22.96",
      "PB": "3.77",
      "Div": "0.92"
  },
  {
      "Date": "7-Apr-10",
      "PE": "22.88",
      "PB": "3.79",
      "Div": "0.92"
  },
  {
      "Date": "6-Apr-10",
      "PE": "22.84",
      "PB": "3.78",
      "Div": "0.92"
  },
  {
      "Date": "5-Apr-10",
      "PE": "22.85",
      "PB": "3.78",
      "Div": "0.92"
  },
  {
      "Date": "1-Apr-10",
      "PE": "22.52",
      "PB": "3.73",
      "Div": "0.93"
  },
  {
      "Date": "31-Mar-10",
      "PE": "22.33",
      "PB": "3.7",
      "Div": "0.94"
  },
  {
      "Date": "30-Mar-10",
      "PE": "22.39",
      "PB": "3.71",
      "Div": "0.94"
  },
  {
      "Date": "29-Mar-10",
      "PE": "22.56",
      "PB": "3.73",
      "Div": "0.93"
  },
  {
      "Date": "26-Mar-10",
      "PE": "22.47",
      "PB": "3.72",
      "Div": "0.93"
  },
  {
      "Date": "25-Mar-10",
      "PE": "22.38",
      "PB": "3.7",
      "Div": "0.94"
  },
  {
      "Date": "23-Mar-10",
      "PE": "22.23",
      "PB": "3.68",
      "Div": "0.94"
  },
  {
      "Date": "22-Mar-10",
      "PE": "22.15",
      "PB": "3.66",
      "Div": "0.95"
  },
  {
      "Date": "19-Mar-10",
      "PE": "22.39",
      "PB": "3.71",
      "Div": "0.94"
  },
  {
      "Date": "18-Mar-10",
      "PE": "22.32",
      "PB": "3.69",
      "Div": "0.94"
  },
  {
      "Date": "17-Mar-10",
      "PE": "22.26",
      "PB": "3.68",
      "Div": "0.94"
  },
  {
      "Date": "16-Mar-10",
      "PE": "22.12",
      "PB": "3.66",
      "Div": "0.95"
  },
  {
      "Date": "15-Mar-10",
      "PE": "21.82",
      "PB": "3.61",
      "Div": "0.96"
  },
  {
      "Date": "12-Mar-10",
      "PE": "21.86",
      "PB": "3.62",
      "Div": "0.96"
  },
  {
      "Date": "11-Mar-10",
      "PE": "21.84",
      "PB": "3.61",
      "Div": "0.96"
  },
  {
      "Date": "10-Mar-10",
      "PE": "21.77",
      "PB": "3.6",
      "Div": "0.96"
  },
  {
      "Date": "9-Mar-10",
      "PE": "21.7",
      "PB": "3.59",
      "Div": "0.97"
  },
  {
      "Date": "8-Mar-10",
      "PE": "21.8",
      "PB": "3.61",
      "Div": "0.96"
  },
  {
      "Date": "5-Mar-10",
      "PE": "21.63",
      "PB": "3.58",
      "Div": "0.97"
  },
  {
      "Date": "4-Mar-10",
      "PE": "21.59",
      "PB": "3.57",
      "Div": "0.97"
  },
  {
      "Date": "3-Mar-10",
      "PE": "21.63",
      "PB": "3.58",
      "Div": "0.97"
  },
  {
      "Date": "2-Mar-10",
      "PE": "21.33",
      "PB": "3.53",
      "Div": "0.98"
  },
  {
      "Date": "26-Feb-10",
      "PE": "20.92",
      "PB": "3.46",
      "Div": "1"
  },
  {
      "Date": "25-Feb-10",
      "PE": "20.79",
      "PB": "3.42",
      "Div": "1.02"
  },
  {
      "Date": "24-Feb-10",
      "PE": "20.78",
      "PB": "3.42",
      "Div": "1.02"
  },
  {
      "Date": "23-Feb-10",
      "PE": "20.83",
      "PB": "3.43",
      "Div": "1.01"
  },
  {
      "Date": "22-Feb-10",
      "PE": "20.77",
      "PB": "3.42",
      "Div": "1.02"
  },
  {
      "Date": "19-Feb-10",
      "PE": "20.72",
      "PB": "3.41",
      "Div": "1.02"
  },
  {
      "Date": "18-Feb-10",
      "PE": "20.91",
      "PB": "3.44",
      "Div": "1.01"
  },
  {
      "Date": "17-Feb-10",
      "PE": "21.02",
      "PB": "3.46",
      "Div": "1"
  },
  {
      "Date": "16-Feb-10",
      "PE": "20.77",
      "PB": "3.42",
      "Div": "1.02"
  },
  {
      "Date": "15-Feb-10",
      "PE": "20.54",
      "PB": "3.38",
      "Div": "1.03"
  },
  {
      "Date": "11-Feb-10",
      "PE": "20.65",
      "PB": "3.4",
      "Div": "1.02"
  },
  {
      "Date": "10-Feb-10",
      "PE": "20.35",
      "PB": "3.35",
      "Div": "1.04"
  },
  {
      "Date": "9-Feb-10",
      "PE": "20.5",
      "PB": "3.37",
      "Div": "1.03"
  },
  {
      "Date": "8-Feb-10",
      "PE": "20.36",
      "PB": "3.35",
      "Div": "1.04"
  },
  {
      "Date": "6-Feb-10",
      "PE": "20.35",
      "PB": "3.35",
      "Div": "1.04"
  },
  {
      "Date": "5-Feb-10",
      "PE": "20.18",
      "PB": "3.32",
      "Div": "1.05"
  },
  {
      "Date": "4-Feb-10",
      "PE": "20.84",
      "PB": "3.41",
      "Div": "1.02"
  },
  {
      "Date": "3-Feb-10",
      "PE": "21.21",
      "PB": "3.47",
      "Div": "1"
  },
  {
      "Date": "2-Feb-10",
      "PE": "20.77",
      "PB": "3.4",
      "Div": "1.02"
  },
  {
      "Date": "1-Feb-10",
      "PE": "21.07",
      "PB": "3.45",
      "Div": "1"
  },
  {
      "Date": "29-Jan-10",
      "PE": "21",
      "PB": "3.43",
      "Div": "1.01"
  },
  {
      "Date": "28-Jan-10",
      "PE": "21.09",
      "PB": "3.42",
      "Div": "1.01"
  },
  {
      "Date": "27-Jan-10",
      "PE": "21.03",
      "PB": "3.41",
      "Div": "1.01"
  },
  {
      "Date": "25-Jan-10",
      "PE": "21.7",
      "PB": "3.52",
      "Div": "0.98"
  },
  {
      "Date": "22-Jan-10",
      "PE": "22.24",
      "PB": "3.54",
      "Div": "0.98"
  },
  {
      "Date": "21-Jan-10",
      "PE": "22.5",
      "PB": "3.58",
      "Div": "0.97"
  },
  {
      "Date": "20-Jan-10",
      "PE": "23.06",
      "PB": "3.67",
      "Div": "0.94"
  },
  {
      "Date": "19-Jan-10",
      "PE": "23.35",
      "PB": "3.68",
      "Div": "0.94"
  },
  {
      "Date": "18-Jan-10",
      "PE": "23.57",
      "PB": "3.71",
      "Div": "0.93"
  },
  {
      "Date": "15-Jan-10",
      "PE": "23.46",
      "PB": "3.69",
      "Div": "0.94"
  },
  {
      "Date": "14-Jan-10",
      "PE": "23.5",
      "PB": "3.7",
      "Div": "0.93"
  },
  {
      "Date": "13-Jan-10",
      "PE": "23.38",
      "PB": "3.68",
      "Div": "0.94"
  },
  {
      "Date": "12-Jan-10",
      "PE": "23.28",
      "PB": "3.67",
      "Div": "0.94"
  },
  {
      "Date": "11-Jan-10",
      "PE": "23.45",
      "PB": "3.7",
      "Div": "0.94"
  },
  {
      "Date": "8-Jan-10",
      "PE": "23.43",
      "PB": "3.69",
      "Div": "0.94"
  },
  {
      "Date": "7-Jan-10",
      "PE": "23.51",
      "PB": "3.7",
      "Div": "0.93"
  },
  {
      "Date": "6-Jan-10",
      "PE": "23.59",
      "PB": "3.72",
      "Div": "0.93"
  },
  {
      "Date": "5-Jan-10",
      "PE": "23.57",
      "PB": "3.71",
      "Div": "0.93"
  },
  {
      "Date": "4-Jan-10",
      "PE": "23.31",
      "PB": "3.67",
      "Div": "0.94"
  },
  {
      "Date": "31-Dec-09",
      "PE": "23.17",
      "PB": "3.65",
      "Div": "0.95"
  },
  {
      "Date": "30-Dec-09",
      "PE": "23.03",
      "PB": "3.63",
      "Div": "0.95"
  },
  {
      "Date": "29-Dec-09",
      "PE": "23.11",
      "PB": "3.64",
      "Div": "0.95"
  },
  {
      "Date": "24-Dec-09",
      "PE": "23.07",
      "PB": "3.64",
      "Div": "0.95"
  },
  {
      "Date": "23-Dec-09",
      "PE": "22.91",
      "PB": "3.61",
      "Div": "0.96"
  },
  {
      "Date": "22-Dec-09",
      "PE": "22.2",
      "PB": "3.5",
      "Div": "0.99"
  },
  {
      "Date": "21-Dec-09",
      "PE": "22.05",
      "PB": "3.48",
      "Div": "0.99"
  },
  {
      "Date": "18-Dec-09",
      "PE": "22.21",
      "PB": "3.5",
      "Div": "0.99"
  },
  {
      "Date": "17-Dec-09",
      "PE": "22.45",
      "PB": "3.57",
      "Div": "0.98"
  },
  {
      "Date": "16-Dec-09",
      "PE": "22.45",
      "PB": "3.57",
      "Div": "0.98"
  },
  {
      "Date": "15-Dec-09",
      "PE": "22.41",
      "PB": "3.57",
      "Div": "0.98"
  },
  {
      "Date": "14-Dec-09",
      "PE": "22.74",
      "PB": "3.62",
      "Div": "0.97"
  },
  {
      "Date": "11-Dec-09",
      "PE": "22.79",
      "PB": "3.63",
      "Div": "0.96"
  },
  {
      "Date": "10-Dec-09",
      "PE": "22.86",
      "PB": "3.64",
      "Div": "0.96"
  },
  {
      "Date": "9-Dec-09",
      "PE": "22.76",
      "PB": "3.62",
      "Div": "0.97"
  },
  {
      "Date": "8-Dec-09",
      "PE": "22.92",
      "PB": "3.65",
      "Div": "0.96"
  },
  {
      "Date": "7-Dec-09",
      "PE": "22.52",
      "PB": "3.59",
      "Div": "0.98"
  },
  {
      "Date": "4-Dec-09",
      "PE": "22.71",
      "PB": "3.62",
      "Div": "0.97"
  },
  {
      "Date": "3-Dec-09",
      "PE": "22.81",
      "PB": "3.63",
      "Div": "0.96"
  },
  {
      "Date": "2-Dec-09",
      "PE": "22.77",
      "PB": "3.63",
      "Div": "0.97"
  },
  {
      "Date": "1-Dec-09",
      "PE": "22.77",
      "PB": "3.62",
      "Div": "0.97"
  },
  {
      "Date": "30-Nov-09",
      "PE": "22.37",
      "PB": "3.56",
      "Div": "0.98"
  },
  {
      "Date": "27-Nov-09",
      "PE": "21.97",
      "PB": "3.5",
      "Div": "1"
  },
  {
      "Date": "26-Nov-09",
      "PE": "22.25",
      "PB": "3.54",
      "Div": "0.99"
  },
  {
      "Date": "25-Nov-09",
      "PE": "22.71",
      "PB": "3.61",
      "Div": "0.97"
  },
  {
      "Date": "24-Nov-09",
      "PE": "22.63",
      "PB": "3.6",
      "Div": "0.97"
  },
  {
      "Date": "23-Nov-09",
      "PE": "22.69",
      "PB": "3.61",
      "Div": "0.97"
  },
  {
      "Date": "20-Nov-09",
      "PE": "22.46",
      "PB": "3.58",
      "Div": "0.98"
  },
  {
      "Date": "19-Nov-09",
      "PE": "22.18",
      "PB": "3.53",
      "Div": "0.99"
  },
  {
      "Date": "18-Nov-09",
      "PE": "22.47",
      "PB": "3.58",
      "Div": "0.98"
  },
  {
      "Date": "17-Nov-09",
      "PE": "22.5",
      "PB": "3.58",
      "Div": "0.98"
  },
  {
      "Date": "16-Nov-09",
      "PE": "22.48",
      "PB": "3.58",
      "Div": "0.98"
  },
  {
      "Date": "13-Nov-09",
      "PE": "22.22",
      "PB": "3.54",
      "Div": "0.99"
  },
  {
      "Date": "12-Nov-09",
      "PE": "22.02",
      "PB": "3.5",
      "Div": "1"
  },
  {
      "Date": "11-Nov-09",
      "PE": "22.24",
      "PB": "3.78",
      "Div": "0.98"
  },
  {
      "Date": "10-Nov-09",
      "PE": "21.43",
      "PB": "3.65",
      "Div": "1.02"
  },
  {
      "Date": "9-Nov-09",
      "PE": "21.5",
      "PB": "3.67",
      "Div": "1.02"
  },
  {
      "Date": "6-Nov-09",
      "PE": "21.05",
      "PB": "3.59",
      "Div": "1.04"
  },
  {
      "Date": "5-Nov-09",
      "PE": "20.68",
      "PB": "3.57",
      "Div": "1.05"
  },
  {
      "Date": "4-Nov-09",
      "PE": "20.45",
      "PB": "3.53",
      "Div": "1.06"
  },
  {
      "Date": "3-Nov-09",
      "PE": "19.81",
      "PB": "3.42",
      "Div": "1.1"
  },
  {
      "Date": "30-Oct-09",
      "PE": "20.45",
      "PB": "3.53",
      "Div": "1.06"
  },
  {
      "Date": "29-Oct-09",
      "PE": "21",
      "PB": "3.56",
      "Div": "1.05"
  },
  {
      "Date": "28-Oct-09",
      "PE": "21.33",
      "PB": "3.61",
      "Div": "1.04"
  },
  {
      "Date": "27-Oct-09",
      "PE": "21.42",
      "PB": "3.63",
      "Div": "1.03"
  },
  {
      "Date": "26-Oct-09",
      "PE": "21.96",
      "PB": "3.72",
      "Div": "1.01"
  },
  {
      "Date": "23-Oct-09",
      "PE": "22.19",
      "PB": "3.74",
      "Div": "1"
  },
  {
      "Date": "22-Oct-09",
      "PE": "22.44",
      "PB": "3.73",
      "Div": "1"
  },
  {
      "Date": "21-Oct-09",
      "PE": "22.71",
      "PB": "3.75",
      "Div": "1"
  },
  {
      "Date": "20-Oct-09",
      "PE": "22.94",
      "PB": "3.79",
      "Div": "0.99"
  },
  {
      "Date": "17-Oct-09",
      "PE": "23.08",
      "PB": "3.81",
      "Div": "0.98"
  },
  {
      "Date": "16-Oct-09",
      "PE": "23.08",
      "PB": "3.81",
      "Div": "0.98"
  },
  {
      "Date": "15-Oct-09",
      "PE": "22.93",
      "PB": "3.79",
      "Div": "0.99"
  },
  {
      "Date": "14-Oct-09",
      "PE": "22.97",
      "PB": "3.79",
      "Div": "0.99"
  },
  {
      "Date": "12-Oct-09",
      "PE": "22.8",
      "PB": "3.75",
      "Div": "1"
  },
  {
      "Date": "9-Oct-09",
      "PE": "22.31",
      "PB": "3.67",
      "Div": "1.02"
  },
  {
      "Date": "8-Oct-09",
      "PE": "22.58",
      "PB": "3.71",
      "Div": "1.01"
  },
  {
      "Date": "7-Oct-09",
      "PE": "22.48",
      "PB": "3.69",
      "Div": "1.02"
  },
  {
      "Date": "6-Oct-09",
      "PE": "22.67",
      "PB": "3.72",
      "Div": "1.01"
  },
  {
      "Date": "5-Oct-09",
      "PE": "22.53",
      "PB": "3.7",
      "Div": "1.01"
  },
  {
      "Date": "1-Oct-09",
      "PE": "22.89",
      "PB": "3.76",
      "Div": "1"
  },
  {
      "Date": "30-Sep-09",
      "PE": "22.9",
      "PB": "3.76",
      "Div": "1"
  },
  {
      "Date": "29-Sep-09",
      "PE": "22.55",
      "PB": "3.7",
      "Div": "1.01"
  },
  {
      "Date": "25-Sep-09",
      "PE": "22.33",
      "PB": "3.67",
      "Div": "1.02"
  },
  {
      "Date": "24-Sep-09",
      "PE": "22.46",
      "PB": "3.69",
      "Div": "1.02"
  },
  {
      "Date": "23-Sep-09",
      "PE": "22.38",
      "PB": "3.68",
      "Div": "1.02"
  },
  {
      "Date": "22-Sep-09",
      "PE": "22.61",
      "PB": "3.95",
      "Div": "1.01"
  },
  {
      "Date": "18-Sep-09",
      "PE": "22.41",
      "PB": "3.91",
      "Div": "1.02"
  },
  {
      "Date": "17-Sep-09",
      "PE": "22.36",
      "PB": "3.9",
      "Div": "1.02"
  },
  {
      "Date": "16-Sep-09",
      "PE": "22.33",
      "PB": "3.9",
      "Div": "1.03"
  },
  {
      "Date": "15-Sep-09",
      "PE": "22.03",
      "PB": "3.85",
      "Div": "1.04"
  },
  {
      "Date": "14-Sep-09",
      "PE": "21.66",
      "PB": "3.78",
      "Div": "1.06"
  },
  {
      "Date": "11-Sep-09",
      "PE": "21.7",
      "PB": "3.79",
      "Div": "1.06"
  },
  {
      "Date": "10-Sep-09",
      "PE": "21.66",
      "PB": "3.78",
      "Div": "1.06"
  },
  {
      "Date": "9-Sep-09",
      "PE": "21.63",
      "PB": "3.78",
      "Div": "1.06"
  },
  {
      "Date": "8-Sep-09",
      "PE": "21.59",
      "PB": "3.77",
      "Div": "1.06"
  },
  {
      "Date": "7-Sep-09",
      "PE": "21.49",
      "PB": "3.75",
      "Div": "1.07"
  },
  {
      "Date": "4-Sep-09",
      "PE": "21.02",
      "PB": "3.67",
      "Div": "1.09"
  },
  {
      "Date": "3-Sep-09",
      "PE": "20.63",
      "PB": "3.6",
      "Div": "1.11"
  },
  {
      "Date": "2-Sep-09",
      "PE": "20.7",
      "PB": "3.61",
      "Div": "1.11"
  },
  {
      "Date": "1-Sep-09",
      "PE": "20.78",
      "PB": "3.63",
      "Div": "1.1"
  },
  {
      "Date": "31-Aug-09",
      "PE": "20.94",
      "PB": "3.66",
      "Div": "1.09"
  },
  {
      "Date": "28-Aug-09",
      "PE": "21.26",
      "PB": "3.71",
      "Div": "1.08"
  },
  {
      "Date": "27-Aug-09",
      "PE": "21.01",
      "PB": "3.67",
      "Div": "1.09"
  },
  {
      "Date": "26-Aug-09",
      "PE": "20.97",
      "PB": "3.66",
      "Div": "1.09"
  },
  {
      "Date": "25-Aug-09",
      "PE": "20.88",
      "PB": "3.64",
      "Div": "1.1"
  },
  {
      "Date": "24-Aug-09",
      "PE": "20.82",
      "PB": "3.63",
      "Div": "1.1"
  },
  {
      "Date": "21-Aug-09",
      "PE": "20.31",
      "PB": "3.54",
      "Div": "1.13"
  },
  {
      "Date": "20-Aug-09",
      "PE": "19.98",
      "PB": "3.48",
      "Div": "1.15"
  },
  {
      "Date": "19-Aug-09",
      "PE": "19.71",
      "PB": "3.44",
      "Div": "1.16"
  },
  {
      "Date": "18-Aug-09",
      "PE": "20",
      "PB": "3.49",
      "Div": "1.15"
  },
  {
      "Date": "17-Aug-09",
      "PE": "19.68",
      "PB": "3.43",
      "Div": "1.17"
  },
  {
      "Date": "14-Aug-09",
      "PE": "20.54",
      "PB": "3.58",
      "Div": "1.12"
  },
  {
      "Date": "13-Aug-09",
      "PE": "20.66",
      "PB": "3.71",
      "Div": "1.11"
  },
  {
      "Date": "12-Aug-09",
      "PE": "19.99",
      "PB": "3.59",
      "Div": "1.15"
  },
  {
      "Date": "11-Aug-09",
      "PE": "20.06",
      "PB": "3.6",
      "Div": "1.15"
  },
  {
      "Date": "10-Aug-09",
      "PE": "19.9",
      "PB": "3.57",
      "Div": "1.15"
  },
  {
      "Date": "7-Aug-09",
      "PE": "20.1",
      "PB": "3.61",
      "Div": "1.14"
  },
  {
      "Date": "6-Aug-09",
      "PE": "20.56",
      "PB": "3.69",
      "Div": "1.12"
  },
  {
      "Date": "5-Aug-09",
      "PE": "21.05",
      "PB": "3.78",
      "Div": "1.09"
  },
  {
      "Date": "4-Aug-09",
      "PE": "20.99",
      "PB": "3.77",
      "Div": "1.09"
  },
  {
      "Date": "3-Aug-09",
      "PE": "21.09",
      "PB": "3.79",
      "Div": "1.09"
  },
  {
      "Date": "31-Jul-09",
      "PE": "20.68",
      "PB": "3.73",
      "Div": "1.11"
  },
  {
      "Date": "30-Jul-09",
      "PE": "20.31",
      "PB": "3.68",
      "Div": "1.12"
  },
  {
      "Date": "29-Jul-09",
      "PE": "20.08",
      "PB": "3.63",
      "Div": "1.14"
  },
  {
      "Date": "28-Jul-09",
      "PE": "20.3",
      "PB": "3.67",
      "Div": "1.12"
  },
  {
      "Date": "27-Jul-09",
      "PE": "20.59",
      "PB": "3.68",
      "Div": "1.12"
  },
  {
      "Date": "24-Jul-09",
      "PE": "20.6",
      "PB": "3.67",
      "Div": "1.12"
  },
  {
      "Date": "23-Jul-09",
      "PE": "20.27",
      "PB": "3.64",
      "Div": "1.13"
  },
  {
      "Date": "22-Jul-09",
      "PE": "19.89",
      "PB": "3.54",
      "Div": "1.17"
  },
  {
      "Date": "21-Jul-09",
      "PE": "20.29",
      "PB": "3.59",
      "Div": "1.15"
  },
  {
      "Date": "20-Jul-09",
      "PE": "20.44",
      "PB": "3.62",
      "Div": "1.14"
  },
  {
      "Date": "17-Jul-09",
      "PE": "19.87",
      "PB": "3.52",
      "Div": "1.17"
  },
  {
      "Date": "16-Jul-09",
      "PE": "19.21",
      "PB": "3.4",
      "Div": "1.21"
  },
  {
      "Date": "15-Jul-09",
      "PE": "19.58",
      "PB": "3.4",
      "Div": "1.21"
  },
  {
      "Date": "14-Jul-09",
      "PE": "19.02",
      "PB": "3.33",
      "Div": "1.23"
  },
  {
      "Date": "13-Jul-09",
      "PE": "18.42",
      "PB": "3.22",
      "Div": "1.28"
  },
  {
      "Date": "10-Jul-09",
      "PE": "18.58",
      "PB": "3.24",
      "Div": "1.27"
  },
  {
      "Date": "9-Jul-09",
      "PE": "18.99",
      "PB": "3.3",
      "Div": "1.25"
  },
  {
      "Date": "8-Jul-09",
      "PE": "18.98",
      "PB": "3.3",
      "Div": "1.25"
  },
  {
      "Date": "7-Jul-09",
      "PE": "19.55",
      "PB": "3.4",
      "Div": "1.21"
  },
  {
      "Date": "6-Jul-09",
      "PE": "19.38",
      "PB": "3.37",
      "Div": "1.22"
  },
  {
      "Date": "3-Jul-09",
      "PE": "20.58",
      "PB": "3.67",
      "Div": "1.13"
  },
  {
      "Date": "2-Jul-09",
      "PE": "20.23",
      "PB": "3.61",
      "Div": "1.15"
  },
  {
      "Date": "1-Jul-09",
      "PE": "20.2",
      "PB": "3.6",
      "Div": "1.15"
  },
  {
      "Date": "30-Jun-09",
      "PE": "19.97",
      "PB": "3.56",
      "Div": "1.16"
  },
  {
      "Date": "29-Jun-09",
      "PE": "20.25",
      "PB": "3.64",
      "Div": "1.13"
  },
  {
      "Date": "26-Jun-09",
      "PE": "20.08",
      "PB": "3.63",
      "Div": "1.14"
  },
  {
      "Date": "25-Jun-09",
      "PE": "19.07",
      "PB": "3.46",
      "Div": "1.3"
  },
  {
      "Date": "24-Jun-09",
      "PE": "19.22",
      "PB": "3.51",
      "Div": "1.28"
  },
  {
      "Date": "23-Jun-09",
      "PE": "19.01",
      "PB": "3.47",
      "Div": "1.29"
  },
  {
      "Date": "22-Jun-09",
      "PE": "18.96",
      "PB": "3.46",
      "Div": "1.3"
  },
  {
      "Date": "19-Jun-09",
      "PE": "19.31",
      "PB": "3.52",
      "Div": "1.27"
  },
  {
      "Date": "18-Jun-09",
      "PE": "19.03",
      "PB": "3.47",
      "Div": "1.29"
  },
  {
      "Date": "17-Jun-09",
      "PE": "19.5",
      "PB": "3.56",
      "Div": "1.26"
  },
  {
      "Date": "16-Jun-09",
      "PE": "20.63",
      "PB": "3.67",
      "Div": "1.21"
  },
  {
      "Date": "15-Jun-09",
      "PE": "20.47",
      "PB": "3.64",
      "Div": "1.22"
  },
  {
      "Date": "12-Jun-09",
      "PE": "20.91",
      "PB": "3.72",
      "Div": "1.19"
  },
  {
      "Date": "11-Jun-09",
      "PE": "21.16",
      "PB": "3.78",
      "Div": "1.19"
  },
  {
      "Date": "10-Jun-09",
      "PE": "21.24",
      "PB": "3.8",
      "Div": "1.18"
  },
  {
      "Date": "9-Jun-09",
      "PE": "20.77",
      "PB": "3.71",
      "Div": "1.21"
  },
  {
      "Date": "8-Jun-09",
      "PE": "20.21",
      "PB": "3.61",
      "Div": "1.24"
  },
  {
      "Date": "5-Jun-09",
      "PE": "20.93",
      "PB": "3.74",
      "Div": "1.2"
  },
  {
      "Date": "4-Jun-09",
      "PE": "20.87",
      "PB": "3.73",
      "Div": "1.2"
  },
  {
      "Date": "3-Jun-09",
      "PE": "20.62",
      "PB": "3.69",
      "Div": "1.21"
  },
  {
      "Date": "2-Jun-09",
      "PE": "20.6",
      "PB": "3.69",
      "Div": "1.22"
  },
  {
      "Date": "1-Jun-09",
      "PE": "20.62",
      "PB": "3.69",
      "Div": "1.21"
  },
  {
      "Date": "29-May-09",
      "PE": "20.82",
      "PB": "3.63",
      "Div": "1.24"
  },
  {
      "Date": "28-May-09",
      "PE": "20.32",
      "PB": "3.54",
      "Div": "1.27"
  },
  {
      "Date": "27-May-09",
      "PE": "20",
      "PB": "3.49",
      "Div": "1.29"
  },
  {
      "Date": "26-May-09",
      "PE": "19.29",
      "PB": "3.36",
      "Div": "1.34"
  },
  {
      "Date": "25-May-09",
      "PE": "19.85",
      "PB": "3.5",
      "Div": "1.31"
  },
  {
      "Date": "22-May-09",
      "PE": "19.87",
      "PB": "3.5",
      "Div": "1.31"
  },
  {
      "Date": "21-May-09",
      "PE": "19.85",
      "PB": "3.48",
      "Div": "1.32"
  },
  {
      "Date": "20-May-09",
      "PE": "20.18",
      "PB": "3.53",
      "Div": "1.3"
  },
  {
      "Date": "19-May-09",
      "PE": "20.39",
      "PB": "3.57",
      "Div": "1.29"
  },
  {
      "Date": "18-May-09",
      "PE": "20.41",
      "PB": "3.57",
      "Div": "1.29"
  },
  {
      "Date": "15-May-09",
      "PE": "17.34",
      "PB": "3.03",
      "Div": "1.51"
  },
  {
      "Date": "14-May-09",
      "PE": "16.97",
      "PB": "2.97",
      "Div": "1.55"
  },
  {
      "Date": "13-May-09",
      "PE": "17.17",
      "PB": "3",
      "Div": "1.53"
  },
  {
      "Date": "12-May-09",
      "PE": "17.38",
      "PB": "3.04",
      "Div": "1.51"
  },
  {
      "Date": "11-May-09",
      "PE": "16.78",
      "PB": "2.93",
      "Div": "1.56"
  },
  {
      "Date": "8-May-09",
      "PE": "17.21",
      "PB": "2.99",
      "Div": "1.54"
  },
  {
      "Date": "7-May-09",
      "PE": "17.51",
      "PB": "3.04",
      "Div": "1.51"
  },
  {
      "Date": "6-May-09",
      "PE": "17.23",
      "PB": "2.99",
      "Div": "1.53"
  },
  {
      "Date": "5-May-09",
      "PE": "17.4",
      "PB": "3.02",
      "Div": "1.52"
  },
  {
      "Date": "4-May-09",
      "PE": "17.37",
      "PB": "3.02",
      "Div": "1.52"
  },
  {
      "Date": "29-Apr-09",
      "PE": "16.53",
      "PB": "2.87",
      "Div": "1.6"
  },
  {
      "Date": "28-Apr-09",
      "PE": "16.02",
      "PB": "2.78",
      "Div": "1.65"
  },
  {
      "Date": "27-Apr-09",
      "PE": "16.53",
      "PB": "2.87",
      "Div": "1.6"
  },
  {
      "Date": "24-Apr-09",
      "PE": "16.43",
      "PB": "2.87",
      "Div": "1.6"
  },
  {
      "Date": "23-Apr-09",
      "PE": "16.12",
      "PB": "2.82",
      "Div": "1.63"
  },
  {
      "Date": "22-Apr-09",
      "PE": "15.69",
      "PB": "2.75",
      "Div": "1.67"
  },
  {
      "Date": "21-Apr-09",
      "PE": "15.86",
      "PB": "2.78",
      "Div": "1.65"
  },
  {
      "Date": "20-Apr-09",
      "PE": "15.94",
      "PB": "2.79",
      "Div": "1.66"
  },
  {
      "Date": "17-Apr-09",
      "PE": "15.97",
      "PB": "2.8",
      "Div": "1.65"
  },
  {
      "Date": "16-Apr-09",
      "PE": "15.9",
      "PB": "2.79",
      "Div": "1.66"
  },
  {
      "Date": "15-Apr-09",
      "PE": "16.45",
      "PB": "2.88",
      "Div": "1.61"
  },
  {
      "Date": "13-Apr-09",
      "PE": "16.01",
      "PB": "2.8",
      "Div": "1.65"
  },
  {
      "Date": "9-Apr-09",
      "PE": "15.82",
      "PB": "2.77",
      "Div": "1.67"
  },
  {
      "Date": "8-Apr-09",
      "PE": "15.82",
      "PB": "2.77",
      "Div": "1.67"
  },
  {
      "Date": "6-Apr-09",
      "PE": "15.42",
      "PB": "2.69",
      "Div": "1.72"
  },
  {
      "Date": "2-Apr-09",
      "PE": "15.2",
      "PB": "2.66",
      "Div": "1.74"
  },
  {
      "Date": "1-Apr-09",
      "PE": "14.49",
      "PB": "2.53",
      "Div": "1.83"
  },
  {
      "Date": "31-Mar-09",
      "PE": "14.29",
      "PB": "2.5",
      "Div": "1.85"
  },
  {
      "Date": "30-Mar-09",
      "PE": "14.08",
      "PB": "2.46",
      "Div": "1.88"
  },
  {
      "Date": "27-Mar-09",
      "PE": "14.7",
      "PB": "2.57",
      "Div": "1.8"
  },
  {
      "Date": "26-Mar-09",
      "PE": "14.64",
      "PB": "2.56",
      "Div": "1.82"
  },
  {
      "Date": "25-Mar-09",
      "PE": "14.17",
      "PB": "2.48",
      "Div": "1.88"
  },
  {
      "Date": "24-Mar-09",
      "PE": "13.96",
      "PB": "2.44",
      "Div": "1.91"
  },
  {
      "Date": "23-Mar-09",
      "PE": "13.96",
      "PB": "2.44",
      "Div": "1.9"
  },
  {
      "Date": "20-Mar-09",
      "PE": "13.33",
      "PB": "2.33",
      "Div": "2"
  },
  {
      "Date": "19-Mar-09",
      "PE": "13.33",
      "PB": "2.33",
      "Div": "2"
  },
  {
      "Date": "18-Mar-09",
      "PE": "13.27",
      "PB": "2.32",
      "Div": "2"
  },
  {
      "Date": "17-Mar-09",
      "PE": "13.09",
      "PB": "2.29",
      "Div": "2.03"
  },
  {
      "Date": "16-Mar-09",
      "PE": "13.18",
      "PB": "2.3",
      "Div": "2.02"
  },
  {
      "Date": "13-Mar-09",
      "PE": "12.91",
      "PB": "2.26",
      "Div": "2.06"
  },
  {
      "Date": "12-Mar-09",
      "PE": "12.42",
      "PB": "2.17",
      "Div": "2.14"
  },
  {
      "Date": "9-Mar-09",
      "PE": "12.21",
      "PB": "2.14",
      "Div": "2.18"
  },
  {
      "Date": "6-Mar-09",
      "PE": "12.44",
      "PB": "2.17",
      "Div": "2.14"
  },
  {
      "Date": "5-Mar-09",
      "PE": "12.23",
      "PB": "2.14",
      "Div": "2.17"
  },
  {
      "Date": "4-Mar-09",
      "PE": "12.56",
      "PB": "2.19",
      "Div": "2.12"
  },
  {
      "Date": "3-Mar-09",
      "PE": "12.45",
      "PB": "2.18",
      "Div": "2.14"
  },
  {
      "Date": "2-Mar-09",
      "PE": "12.7",
      "PB": "2.22",
      "Div": "2.09"
  },
  {
      "Date": "27-Feb-09",
      "PE": "13.12",
      "PB": "2.29",
      "Div": "2.03"
  },
  {
      "Date": "26-Feb-09",
      "PE": "13.22",
      "PB": "2.31",
      "Div": "2.01"
  },
  {
      "Date": "25-Feb-09",
      "PE": "13.11",
      "PB": "2.29",
      "Div": "2.03"
  },
  {
      "Date": "24-Feb-09",
      "PE": "12.98",
      "PB": "2.27",
      "Div": "2.05"
  },
  {
      "Date": "20-Feb-09",
      "PE": "12.99",
      "PB": "2.27",
      "Div": "2.05"
  },
  {
      "Date": "19-Feb-09",
      "PE": "13.24",
      "PB": "2.31",
      "Div": "2.01"
  },
  {
      "Date": "18-Feb-09",
      "PE": "13.18",
      "PB": "2.3",
      "Div": "2.02"
  },
  {
      "Date": "17-Feb-09",
      "PE": "13.15",
      "PB": "2.3",
      "Div": "2.02"
  },
  {
      "Date": "16-Feb-09",
      "PE": "13.52",
      "PB": "2.36",
      "Div": "1.97"
  },
  {
      "Date": "13-Feb-09",
      "PE": "14",
      "PB": "2.45",
      "Div": "1.9"
  },
  {
      "Date": "12-Feb-09",
      "PE": "13.73",
      "PB": "2.41",
      "Div": "1.94"
  },
  {
      "Date": "11-Feb-09",
      "PE": "13.89",
      "PB": "2.43",
      "Div": "1.91"
  },
  {
      "Date": "10-Feb-09",
      "PE": "13.93",
      "PB": "2.44",
      "Div": "1.91"
  },
  {
      "Date": "9-Feb-09",
      "PE": "13.86",
      "PB": "2.43",
      "Div": "1.92"
  },
  {
      "Date": "6-Feb-09",
      "PE": "13.49",
      "PB": "2.36",
      "Div": "1.97"
  },
  {
      "Date": "5-Feb-09",
      "PE": "13.19",
      "PB": "2.31",
      "Div": "2.02"
  },
  {
      "Date": "4-Feb-09",
      "PE": "13.29",
      "PB": "2.33",
      "Div": "2"
  },
  {
      "Date": "3-Feb-09",
      "PE": "13.2",
      "PB": "2.31",
      "Div": "2.01"
  },
  {
      "Date": "2-Feb-09",
      "PE": "13.12",
      "PB": "2.3",
      "Div": "2.02"
  },
  {
      "Date": "30-Jan-09",
      "PE": "13.4",
      "PB": "2.39",
      "Div": "1.95"
  },
  {
      "Date": "29-Jan-09",
      "PE": "13.17",
      "PB": "2.35",
      "Div": "1.98"
  },
  {
      "Date": "28-Jan-09",
      "PE": "13.1",
      "PB": "2.37",
      "Div": "1.97"
  },
  {
      "Date": "27-Jan-09",
      "PE": "12.67",
      "PB": "2.3",
      "Div": "2.02"
  },
  {
      "Date": "23-Jan-09",
      "PE": "12.27",
      "PB": "2.23",
      "Div": "2.09"
  },
  {
      "Date": "22-Jan-09",
      "PE": "11.96",
      "PB": "2.26",
      "Div": "2.06"
  },
  {
      "Date": "21-Jan-09",
      "PE": "11.97",
      "PB": "2.25",
      "Div": "2.07"
  },
  {
      "Date": "20-Jan-09",
      "PE": "12.36",
      "PB": "2.33",
      "Div": "2"
  },
  {
      "Date": "19-Jan-09",
      "PE": "12.58",
      "PB": "2.37",
      "Div": "1.97"
  },
  {
      "Date": "16-Jan-09",
      "PE": "12.51",
      "PB": "2.35",
      "Div": "1.98"
  },
  {
      "Date": "15-Jan-09",
      "PE": "12.1",
      "PB": "2.28",
      "Div": "2.05"
  },
  {
      "Date": "14-Jan-09",
      "PE": "12.56",
      "PB": "2.36",
      "Div": "1.98"
  },
  {
      "Date": "13-Jan-09",
      "PE": "12.16",
      "PB": "2.28",
      "Div": "2.04"
  },
  {
      "Date": "12-Jan-09",
      "PE": "12.32",
      "PB": "2.31",
      "Div": "2.02"
  },
  {
      "Date": "9-Jan-09",
      "PE": "12.59",
      "PB": "2.37",
      "Div": "1.97"
  },
  {
      "Date": "7-Jan-09",
      "PE": "12.8",
      "PB": "2.41",
      "Div": "1.93"
  },
  {
      "Date": "6-Jan-09",
      "PE": "13.64",
      "PB": "2.57",
      "Div": "1.82"
  },
  {
      "Date": "5-Jan-09",
      "PE": "13.68",
      "PB": "2.58",
      "Div": "1.81"
  },
  {
      "Date": "2-Jan-09",
      "PE": "13.36",
      "PB": "2.51",
      "Div": "1.85"
  },
  {
      "Date": "1-Jan-09",
      "PE": "13.3",
      "PB": "2.5",
      "Div": "1.86"
  },
  {
      "Date": "31-Dec-08",
      "PE": "12.97",
      "PB": "2.44",
      "Div": "1.91"
  },
  {
      "Date": "30-Dec-08",
      "PE": "13.06",
      "PB": "2.46",
      "Div": "1.9"
  },
  {
      "Date": "29-Dec-08",
      "PE": "12.81",
      "PB": "2.41",
      "Div": "1.93"
  },
  {
      "Date": "26-Dec-08",
      "PE": "12.52",
      "PB": "2.36",
      "Div": "1.98"
  },
  {
      "Date": "24-Dec-08",
      "PE": "12.79",
      "PB": "2.41",
      "Div": "1.94"
  },
  {
      "Date": "23-Dec-08",
      "PE": "13.01",
      "PB": "2.45",
      "Div": "1.9"
  },
  {
      "Date": "22-Dec-08",
      "PE": "13.32",
      "PB": "2.51",
      "Div": "1.86"
  },
  {
      "Date": "19-Dec-08",
      "PE": "13.49",
      "PB": "2.54",
      "Div": "1.84"
  },
  {
      "Date": "18-Dec-08",
      "PE": "13.42",
      "PB": "2.53",
      "Div": "1.85"
  },
  {
      "Date": "17-Dec-08",
      "PE": "12.95",
      "PB": "2.44",
      "Div": "1.91"
  },
  {
      "Date": "16-Dec-08",
      "PE": "13.33",
      "PB": "2.51",
      "Div": "1.86"
  },
  {
      "Date": "15-Dec-08",
      "PE": "13.07",
      "PB": "2.46",
      "Div": "1.9"
  },
  {
      "Date": "12-Dec-08",
      "PE": "12.81",
      "PB": "2.41",
      "Div": "1.93"
  },
  {
      "Date": "11-Dec-08",
      "PE": "12.8",
      "PB": "2.41",
      "Div": "1.94"
  },
  {
      "Date": "10-Dec-08",
      "PE": "12.84",
      "PB": "2.42",
      "Div": "1.93"
  },
  {
      "Date": "8-Dec-08",
      "PE": "12.2",
      "PB": "2.3",
      "Div": "2.03"
  },
  {
      "Date": "5-Dec-08",
      "PE": "11.9",
      "PB": "2.24",
      "Div": "2.08"
  },
  {
      "Date": "4-Dec-08",
      "PE": "12.22",
      "PB": "2.34",
      "Div": "2.03"
  },
  {
      "Date": "3-Dec-08",
      "PE": "11.64",
      "PB": "2.23",
      "Div": "2.13"
  },
  {
      "Date": "2-Dec-08",
      "PE": "11.65",
      "PB": "2.23",
      "Div": "2.13"
  },
  {
      "Date": "1-Dec-08",
      "PE": "11.76",
      "PB": "2.25",
      "Div": "2.11"
  },
  {
      "Date": "28-Nov-08",
      "PE": "12.08",
      "PB": "2.32",
      "Div": "2.05"
  },
  {
      "Date": "26-Nov-08",
      "PE": "12.06",
      "PB": "2.31",
      "Div": "2.05"
  },
  {
      "Date": "25-Nov-08",
      "PE": "11.63",
      "PB": "2.23",
      "Div": "2.13"
  },
  {
      "Date": "24-Nov-08",
      "PE": "11.86",
      "PB": "2.28",
      "Div": "2.09"
  },
  {
      "Date": "21-Nov-08",
      "PE": "11.8",
      "PB": "2.26",
      "Div": "2.1"
  },
  {
      "Date": "20-Nov-08",
      "PE": "11.18",
      "PB": "2.15",
      "Div": "2.21"
  },
  {
      "Date": "19-Nov-08",
      "PE": "11.54",
      "PB": "2.21",
      "Div": "2.14"
  },
  {
      "Date": "18-Nov-08",
      "PE": "11.75",
      "PB": "2.25",
      "Div": "2.11"
  },
  {
      "Date": "17-Nov-08",
      "PE": "12.26",
      "PB": "2.35",
      "Div": "2.01"
  },
  {
      "Date": "14-Nov-08",
      "PE": "12.31",
      "PB": "2.36",
      "Div": "2.01"
  },
  {
      "Date": "12-Nov-08",
      "PE": "12.48",
      "PB": "2.39",
      "Div": "1.98"
  },
  {
      "Date": "11-Nov-08",
      "PE": "12.87",
      "PB": "2.47",
      "Div": "1.92"
  },
  {
      "Date": "10-Nov-08",
      "PE": "13.78",
      "PB": "2.64",
      "Div": "1.79"
  },
  {
      "Date": "7-Nov-08",
      "PE": "13.02",
      "PB": "2.5",
      "Div": "1.9"
  },
  {
      "Date": "6-Nov-08",
      "PE": "12.66",
      "PB": "2.43",
      "Div": "1.95"
  },
  {
      "Date": "5-Nov-08",
      "PE": "13.11",
      "PB": "2.51",
      "Div": "1.88"
  },
  {
      "Date": "4-Nov-08",
      "PE": "13.76",
      "PB": "2.64",
      "Div": "1.8"
  },
  {
      "Date": "3-Nov-08",
      "PE": "13.33",
      "PB": "2.56",
      "Div": "1.85"
  },
  {
      "Date": "31-Oct-08",
      "PE": "12.57",
      "PB": "2.42",
      "Div": "1.96"
  },
  {
      "Date": "29-Oct-08",
      "PE": "11.42",
      "PB": "2.26",
      "Div": "2.09"
  },
  {
      "Date": "28-Oct-08",
      "PE": "11.36",
      "PB": "2.25",
      "Div": "2.1"
  },
  {
      "Date": "27-Oct-08",
      "PE": "10.68",
      "PB": "2.12",
      "Div": "2.24"
  },
  {
      "Date": "24-Oct-08",
      "PE": "10.99",
      "PB": "2.17",
      "Div": "2.18"
  },
  {
      "Date": "23-Oct-08",
      "PE": "12.66",
      "PB": "2.47",
      "Div": "1.92"
  },
  {
      "Date": "22-Oct-08",
      "PE": "13.18",
      "PB": "2.57",
      "Div": "1.84"
  },
  {
      "Date": "21-Oct-08",
      "PE": "13.92",
      "PB": "2.72",
      "Div": "1.74"
  },
  {
      "Date": "20-Oct-08",
      "PE": "13.47",
      "PB": "2.62",
      "Div": "1.81"
  },
  {
      "Date": "17-Oct-08",
      "PE": "13.15",
      "PB": "2.56",
      "Div": "1.85"
  },
  {
      "Date": "16-Oct-08",
      "PE": "14.01",
      "PB": "2.72",
      "Div": "1.74"
  },
  {
      "Date": "15-Oct-08",
      "PE": "14.31",
      "PB": "2.78",
      "Div": "1.7"
  },
  {
      "Date": "14-Oct-08",
      "PE": "15.09",
      "PB": "2.93",
      "Div": "1.62"
  },
  {
      "Date": "13-Oct-08",
      "PE": "14.97",
      "PB": "2.91",
      "Div": "1.63"
  },
  {
      "Date": "10-Oct-08",
      "PE": "14.07",
      "PB": "2.73",
      "Div": "1.73"
  },
  {
      "Date": "8-Oct-08",
      "PE": "15.1",
      "PB": "2.93",
      "Div": "1.62"
  },
  {
      "Date": "7-Oct-08",
      "PE": "15.5",
      "PB": "3",
      "Div": "1.58"
  },
  {
      "Date": "6-Oct-08",
      "PE": "15.48",
      "PB": "3",
      "Div": "1.58"
  },
  {
      "Date": "3-Oct-08",
      "PE": "16.41",
      "PB": "3.18",
      "Div": "1.49"
  },
  {
      "Date": "1-Oct-08",
      "PE": "16.98",
      "PB": "3.29",
      "Div": "1.44"
  },
  {
      "Date": "30-Sep-08",
      "PE": "16.85",
      "PB": "3.26",
      "Div": "1.45"
  },
  {
      "Date": "29-Sep-08",
      "PE": "16.54",
      "PB": "3.21",
      "Div": "1.48"
  },
  {
      "Date": "26-Sep-08",
      "PE": "17.12",
      "PB": "3.32",
      "Div": "1.43"
  },
  {
      "Date": "25-Sep-08",
      "PE": "17.66",
      "PB": "3.42",
      "Div": "1.38"
  },
  {
      "Date": "24-Sep-08",
      "PE": "17.88",
      "PB": "3.46",
      "Div": "1.37"
  },
  {
      "Date": "23-Sep-08",
      "PE": "17.73",
      "PB": "3.44",
      "Div": "1.38"
  },
  {
      "Date": "22-Sep-08",
      "PE": "18.15",
      "PB": "3.52",
      "Div": "1.35"
  },
  {
      "Date": "19-Sep-08",
      "PE": "18.24",
      "PB": "3.53",
      "Div": "1.34"
  },
  {
      "Date": "18-Sep-08",
      "PE": "17.35",
      "PB": "3.36",
      "Div": "1.41"
  },
  {
      "Date": "17-Sep-08",
      "PE": "17.22",
      "PB": "3.6",
      "Div": "1.38"
  },
  {
      "Date": "16-Sep-08",
      "PE": "17.51",
      "PB": "3.66",
      "Div": "1.35"
  },
  {
      "Date": "15-Sep-08",
      "PE": "17.5",
      "PB": "3.65",
      "Div": "1.36"
  },
  {
      "Date": "12-Sep-08",
      "PE": "18.14",
      "PB": "3.79",
      "Div": "1.31"
  },
  {
      "Date": "11-Sep-08",
      "PE": "18.41",
      "PB": "3.84",
      "Div": "1.29"
  },
  {
      "Date": "10-Sep-08",
      "PE": "18.88",
      "PB": "3.94",
      "Div": "1.26"
  },
  {
      "Date": "9-Sep-08",
      "PE": "18.9",
      "PB": "3.93",
      "Div": "1.25"
  },
  {
      "Date": "8-Sep-08",
      "PE": "18.94",
      "PB": "3.94",
      "Div": "1.25"
  },
  {
      "Date": "5-Sep-08",
      "PE": "18.39",
      "PB": "3.83",
      "Div": "1.29"
  },
  {
      "Date": "4-Sep-08",
      "PE": "18.8",
      "PB": "3.91",
      "Div": "1.26"
  },
  {
      "Date": "2-Sep-08",
      "PE": "19.03",
      "PB": "3.96",
      "Div": "1.24"
  },
  {
      "Date": "1-Sep-08",
      "PE": "18.38",
      "PB": "3.82",
      "Div": "1.29"
  },
  {
      "Date": "29-Aug-08",
      "PE": "18.43",
      "PB": "3.83",
      "Div": "1.29"
  },
  {
      "Date": "28-Aug-08",
      "PE": "17.81",
      "PB": "3.7",
      "Div": "1.33"
  },
  {
      "Date": "27-Aug-08",
      "PE": "18.1",
      "PB": "3.77",
      "Div": "1.31"
  },
  {
      "Date": "26-Aug-08",
      "PE": "18.3",
      "PB": "3.8",
      "Div": "1.3"
  },
  {
      "Date": "25-Aug-08",
      "PE": "18.29",
      "PB": "3.8",
      "Div": "1.3"
  },
  {
      "Date": "22-Aug-08",
      "PE": "18.25",
      "PB": "3.8",
      "Div": "1.3"
  },
  {
      "Date": "21-Aug-08",
      "PE": "18.07",
      "PB": "3.94",
      "Div": "1.29"
  },
  {
      "Date": "20-Aug-08",
      "PE": "18.63",
      "PB": "4.06",
      "Div": "1.25"
  },
  {
      "Date": "19-Aug-08",
      "PE": "18.43",
      "PB": "4.02",
      "Div": "1.27"
  },
  {
      "Date": "18-Aug-08",
      "PE": "18.53",
      "PB": "4.04",
      "Div": "1.26"
  },
  {
      "Date": "14-Aug-08",
      "PE": "18.69",
      "PB": "4.08",
      "Div": "1.25"
  },
  {
      "Date": "13-Aug-08",
      "PE": "19.1",
      "PB": "4.17",
      "Div": "1.22"
  },
  {
      "Date": "12-Aug-08",
      "PE": "19.2",
      "PB": "4.19",
      "Div": "1.22"
  },
  {
      "Date": "11-Aug-08",
      "PE": "19.49",
      "PB": "4.25",
      "Div": "1.2"
  },
  {
      "Date": "8-Aug-08",
      "PE": "19.1",
      "PB": "4.17",
      "Div": "1.22"
  },
  {
      "Date": "7-Aug-08",
      "PE": "19.08",
      "PB": "4.16",
      "Div": "1.22"
  },
  {
      "Date": "6-Aug-08",
      "PE": "19.05",
      "PB": "4.16",
      "Div": "1.23"
  },
  {
      "Date": "5-Aug-08",
      "PE": "18.99",
      "PB": "4.14",
      "Div": "1.23"
  },
  {
      "Date": "4-Aug-08",
      "PE": "18.54",
      "PB": "4.04",
      "Div": "1.26"
  },
  {
      "Date": "1-Aug-08",
      "PE": "18.56",
      "PB": "4.06",
      "Div": "1.25"
  },
  {
      "Date": "31-Jul-08",
      "PE": "18.22",
      "PB": "3.99",
      "Div": "1.28"
  },
  {
      "Date": "30-Jul-08",
      "PE": "18.08",
      "PB": "3.97",
      "Div": "1.28"
  },
  {
      "Date": "29-Jul-08",
      "PE": "17.49",
      "PB": "3.85",
      "Div": "1.32"
  },
  {
      "Date": "28-Jul-08",
      "PE": "18.2",
      "PB": "3.99",
      "Div": "1.28"
  },
  {
      "Date": "25-Jul-08",
      "PE": "18.19",
      "PB": "3.97",
      "Div": "1.28"
  },
  {
      "Date": "24-Jul-08",
      "PE": "18.79",
      "PB": "4.08",
      "Div": "1.25"
  },
  {
      "Date": "23-Jul-08",
      "PE": "19.06",
      "PB": "4.12",
      "Div": "1.24"
  },
  {
      "Date": "22-Jul-08",
      "PE": "18.06",
      "PB": "4.01",
      "Div": "1.3"
  },
  {
      "Date": "21-Jul-08",
      "PE": "17.72",
      "PB": "3.94",
      "Div": "1.32"
  },
  {
      "Date": "18-Jul-08",
      "PE": "17.49",
      "PB": "3.87",
      "Div": "1.34"
  },
  {
      "Date": "17-Jul-08",
      "PE": "16.87",
      "PB": "3.74",
      "Div": "1.39"
  },
  {
      "Date": "16-Jul-08",
      "PE": "16.33",
      "PB": "3.61",
      "Div": "1.44"
  },
  {
      "Date": "15-Jul-08",
      "PE": "16.53",
      "PB": "3.66",
      "Div": "1.42"
  },
  {
      "Date": "14-Jul-08",
      "PE": "17.3",
      "PB": "3.82",
      "Div": "1.36"
  },
  {
      "Date": "11-Jul-08",
      "PE": "17.34",
      "PB": "3.83",
      "Div": "1.36"
  },
  {
      "Date": "10-Jul-08",
      "PE": "17.85",
      "PB": "3.94",
      "Div": "1.32"
  },
  {
      "Date": "9-Jul-08",
      "PE": "17.83",
      "PB": "3.94",
      "Div": "1.32"
  },
  {
      "Date": "8-Jul-08",
      "PE": "17.11",
      "PB": "3.78",
      "Div": "1.38"
  },
  {
      "Date": "7-Jul-08",
      "PE": "17.28",
      "PB": "3.82",
      "Div": "1.37"
  },
  {
      "Date": "4-Jul-08",
      "PE": "17.22",
      "PB": "3.8",
      "Div": "1.37"
  },
  {
      "Date": "3-Jul-08",
      "PE": "16.79",
      "PB": "3.89",
      "Div": "1.39"
  },
  {
      "Date": "2-Jul-08",
      "PE": "17.5",
      "PB": "4.05",
      "Div": "1.33"
  },
  {
      "Date": "1-Jul-08",
      "PE": "16.66",
      "PB": "3.86",
      "Div": "1.4"
  },
  {
      "Date": "30-Jun-08",
      "PE": "17.28",
      "PB": "4",
      "Div": "1.35"
  },
  {
      "Date": "27-Jun-08",
      "PE": "17.67",
      "PB": "4.09",
      "Div": "1.31"
  },
  {
      "Date": "26-Jun-08",
      "PE": "18.45",
      "PB": "4.27",
      "Div": "1.26"
  },
  {
      "Date": "25-Jun-08",
      "PE": "18.18",
      "PB": "4.21",
      "Div": "1.28"
  },
  {
      "Date": "24-Jun-08",
      "PE": "17.91",
      "PB": "4.15",
      "Div": "1.3"
  },
  {
      "Date": "23-Jun-08",
      "PE": "18.25",
      "PB": "4.22",
      "Div": "1.27"
  },
  {
      "Date": "20-Jun-08",
      "PE": "18.59",
      "PB": "4.3",
      "Div": "1.25"
  },
  {
      "Date": "19-Jun-08",
      "PE": "19.26",
      "PB": "4.46",
      "Div": "1.21"
  },
  {
      "Date": "18-Jun-08",
      "PE": "19.6",
      "PB": "4.53",
      "Div": "1.19"
  },
  {
      "Date": "17-Jun-08",
      "PE": "19.89",
      "PB": "4.6",
      "Div": "1.17"
  },
  {
      "Date": "16-Jun-08",
      "PE": "19.46",
      "PB": "4.53",
      "Div": "1.19"
  },
  {
      "Date": "13-Jun-08",
      "PE": "19.23",
      "PB": "4.47",
      "Div": "1.2"
  },
  {
      "Date": "12-Jun-08",
      "PE": "19.32",
      "PB": "4.49",
      "Div": "1.2"
  },
  {
      "Date": "11-Jun-08",
      "PE": "19.25",
      "PB": "4.48",
      "Div": "1.2"
  },
  {
      "Date": "10-Jun-08",
      "PE": "18.94",
      "PB": "4.4",
      "Div": "1.22"
  },
  {
      "Date": "9-Jun-08",
      "PE": "19.16",
      "PB": "4.63",
      "Div": "1.18"
  },
  {
      "Date": "6-Jun-08",
      "PE": "19.7",
      "PB": "4.76",
      "Div": "1.14"
  },
  {
      "Date": "5-Jun-08",
      "PE": "19.91",
      "PB": "4.81",
      "Div": "1.13"
  },
  {
      "Date": "4-Jun-08",
      "PE": "19.52",
      "PB": "4.71",
      "Div": "1.15"
  },
  {
      "Date": "3-Jun-08",
      "PE": "20.07",
      "PB": "4.85",
      "Div": "1.12"
  },
  {
      "Date": "2-Jun-08",
      "PE": "20.17",
      "PB": "4.87",
      "Div": "1.12"
  },
  {
      "Date": "30-May-08",
      "PE": "20.74",
      "PB": "5.01",
      "Div": "1.09"
  },
  {
      "Date": "29-May-08",
      "PE": "20.59",
      "PB": "4.97",
      "Div": "1.1"
  },
  {
      "Date": "28-May-08",
      "PE": "20.92",
      "PB": "5.06",
      "Div": "1.08"
  },
  {
      "Date": "27-May-08",
      "PE": "20.67",
      "PB": "5",
      "Div": "1.09"
  },
  {
      "Date": "26-May-08",
      "PE": "20.73",
      "PB": "5.01",
      "Div": "1.09"
  },
  {
      "Date": "23-May-08",
      "PE": "21.03",
      "PB": "5.32",
      "Div": "1.01"
  },
  {
      "Date": "22-May-08",
      "PE": "21.38",
      "PB": "5.4",
      "Div": "1"
  },
  {
      "Date": "21-May-08",
      "PE": "21.77",
      "PB": "5.5",
      "Div": "0.98"
  },
  {
      "Date": "20-May-08",
      "PE": "21.72",
      "PB": "5.49",
      "Div": "0.98"
  },
  {
      "Date": "16-May-08",
      "PE": "21.94",
      "PB": "5.55",
      "Div": "0.97"
  },
  {
      "Date": "15-May-08",
      "PE": "21.84",
      "PB": "5.5",
      "Div": "0.98"
  },
  {
      "Date": "14-May-08",
      "PE": "21.44",
      "PB": "5.39",
      "Div": "1"
  },
  {
      "Date": "13-May-08",
      "PE": "21.21",
      "PB": "5.33",
      "Div": "1.01"
  },
  {
      "Date": "12-May-08",
      "PE": "21.45",
      "PB": "5.39",
      "Div": "1"
  },
  {
      "Date": "9-May-08",
      "PE": "21.32",
      "PB": "5.36",
      "Div": "1.01"
  },
  {
      "Date": "8-May-08",
      "PE": "21.75",
      "PB": "5.47",
      "Div": "0.99"
  },
  {
      "Date": "7-May-08",
      "PE": "21.98",
      "PB": "5.52",
      "Div": "0.98"
  },
  {
      "Date": "6-May-08",
      "PE": "22.02",
      "PB": "5.53",
      "Div": "0.97"
  },
  {
      "Date": "5-May-08",
      "PE": "22.2",
      "PB": "5.58",
      "Div": "0.97"
  },
  {
      "Date": "2-May-08",
      "PE": "22.42",
      "PB": "5.62",
      "Div": "0.96"
  },
  {
      "Date": "30-Apr-08",
      "PE": "22.17",
      "PB": "5.55",
      "Div": "0.97"
  },
  {
      "Date": "29-Apr-08",
      "PE": "22.31",
      "PB": "5.58",
      "Div": "0.97"
  },
  {
      "Date": "28-Apr-08",
      "PE": "21.85",
      "PB": "5.47",
      "Div": "0.99"
  },
  {
      "Date": "25-Apr-08",
      "PE": "21.96",
      "PB": "5.49",
      "Div": "0.98"
  },
  {
      "Date": "24-Apr-08",
      "PE": "21.57",
      "PB": "5.37",
      "Div": "1"
  },
  {
      "Date": "23-Apr-08",
      "PE": "21.69",
      "PB": "5.4",
      "Div": "1"
  },
  {
      "Date": "22-Apr-08",
      "PE": "21.8",
      "PB": "5.43",
      "Div": "0.99"
  },
  {
      "Date": "21-Apr-08",
      "PE": "21.92",
      "PB": "5.41",
      "Div": "1"
  },
  {
      "Date": "17-Apr-08",
      "PE": "21.6",
      "PB": "5.33",
      "Div": "1.01"
  },
  {
      "Date": "16-Apr-08",
      "PE": "21.29",
      "PB": "5.25",
      "Div": "1.03"
  },
  {
      "Date": "15-Apr-08",
      "PE": "21.26",
      "PB": "5.24",
      "Div": "1.03"
  },
  {
      "Date": "11-Apr-08",
      "PE": "20.82",
      "PB": "5.13",
      "Div": "1.05"
  },
  {
      "Date": "10-Apr-08",
      "PE": "20.63",
      "PB": "5.09",
      "Div": "1.06"
  },
  {
      "Date": "9-Apr-08",
      "PE": "20.69",
      "PB": "5.1",
      "Div": "1.06"
  },
  {
      "Date": "8-Apr-08",
      "PE": "20.53",
      "PB": "5.06",
      "Div": "1.07"
  },
  {
      "Date": "7-Apr-08",
      "PE": "20.75",
      "PB": "5.12",
      "Div": "1.05"
  },
  {
      "Date": "4-Apr-08",
      "PE": "20.25",
      "PB": "4.99",
      "Div": "1.08"
  },
  {
      "Date": "3-Apr-08",
      "PE": "20.8",
      "PB": "5.13",
      "Div": "1.05"
  },
  {
      "Date": "2-Apr-08",
      "PE": "20.72",
      "PB": "5.11",
      "Div": "1.06"
  },
  {
      "Date": "1-Apr-08",
      "PE": "20.66",
      "PB": "5.09",
      "Div": "1.06"
  },
  {
      "Date": "31-Mar-08",
      "PE": "20.63",
      "PB": "5.09",
      "Div": "1.06"
  },
  {
      "Date": "28-Mar-08",
      "PE": "21.54",
      "PB": "5.31",
      "Div": "1.02"
  },
  {
      "Date": "27-Mar-08",
      "PE": "21.05",
      "PB": "5.19",
      "Div": "1.04"
  },
  {
      "Date": "26-Mar-08",
      "PE": "21.05",
      "PB": "5.19",
      "Div": "1.04"
  },
  {
      "Date": "25-Mar-08",
      "PE": "21.25",
      "PB": "5.24",
      "Div": "1.03"
  },
  {
      "Date": "24-Mar-08",
      "PE": "20.09",
      "PB": "4.95",
      "Div": "1.09"
  },
  {
      "Date": "19-Mar-08",
      "PE": "19.93",
      "PB": "4.91",
      "Div": "1.1"
  },
  {
      "Date": "18-Mar-08",
      "PE": "19.75",
      "PB": "4.87",
      "Div": "1.11"
  },
  {
      "Date": "17-Mar-08",
      "PE": "19.62",
      "PB": "4.84",
      "Div": "1.12"
  },
  {
      "Date": "14-Mar-08",
      "PE": "20.68",
      "PB": "5.1",
      "Div": "1.06"
  },
  {
      "Date": "13-Mar-08",
      "PE": "19.72",
      "PB": "4.81",
      "Div": "1.13"
  },
  {
      "Date": "12-Mar-08",
      "PE": "20.78",
      "PB": "5.11",
      "Div": "1.05"
  },
  {
      "Date": "11-Mar-08",
      "PE": "20.75",
      "PB": "5.1",
      "Div": "1.05"
  },
  {
      "Date": "10-Mar-08",
      "PE": "20.47",
      "PB": "5.03",
      "Div": "1.06"
  },
  {
      "Date": "7-Mar-08",
      "PE": "20.35",
      "PB": "5",
      "Div": "1.07"
  },
  {
      "Date": "5-Mar-08",
      "PE": "20.98",
      "PB": "5.16",
      "Div": "1.04"
  },
  {
      "Date": "4-Mar-08",
      "PE": "20.74",
      "PB": "5.1",
      "Div": "1.05"
  },
  {
      "Date": "3-Mar-08",
      "PE": "21.12",
      "PB": "5.19",
      "Div": "1.03"
  },
  {
      "Date": "29-Feb-08",
      "PE": "22.27",
      "PB": "5.47",
      "Div": "0.98"
  },
  {
      "Date": "28-Feb-08",
      "PE": "22.53",
      "PB": "5.54",
      "Div": "0.97"
  },
  {
      "Date": "27-Feb-08",
      "PE": "22.46",
      "PB": "5.52",
      "Div": "0.97"
  },
  {
      "Date": "26-Feb-08",
      "PE": "22.47",
      "PB": "5.52",
      "Div": "0.97"
  },
  {
      "Date": "25-Feb-08",
      "PE": "22.17",
      "PB": "5.45",
      "Div": "0.98"
  },
  {
      "Date": "22-Feb-08",
      "PE": "21.79",
      "PB": "5.35",
      "Div": "1"
  },
  {
      "Date": "21-Feb-08",
      "PE": "22.14",
      "PB": "5.44",
      "Div": "0.98"
  },
  {
      "Date": "20-Feb-08",
      "PE": "21.98",
      "PB": "5.4",
      "Div": "0.99"
  },
  {
      "Date": "19-Feb-08",
      "PE": "22.52",
      "PB": "5.53",
      "Div": "0.97"
  },
  {
      "Date": "18-Feb-08",
      "PE": "22.51",
      "PB": "5.53",
      "Div": "0.97"
  },
  {
      "Date": "15-Feb-08",
      "PE": "22.62",
      "PB": "5.56",
      "Div": "0.96"
  },
  {
      "Date": "14-Feb-08",
      "PE": "22.19",
      "PB": "5.45",
      "Div": "0.98"
  },
  {
      "Date": "13-Feb-08",
      "PE": "21.03",
      "PB": "5.16",
      "Div": "1.04"
  },
  {
      "Date": "12-Feb-08",
      "PE": "20.64",
      "PB": "5.07",
      "Div": "1.06"
  },
  {
      "Date": "11-Feb-08",
      "PE": "20.72",
      "PB": "5.09",
      "Div": "1.05"
  },
  {
      "Date": "8-Feb-08",
      "PE": "21.85",
      "PB": "5.36",
      "Div": "1"
  },
  {
      "Date": "7-Feb-08",
      "PE": "21.9",
      "PB": "5.38",
      "Div": "1"
  },
  {
      "Date": "6-Feb-08",
      "PE": "22.71",
      "PB": "5.58",
      "Div": "0.96"
  },
  {
      "Date": "5-Feb-08",
      "PE": "23.4",
      "PB": "5.74",
      "Div": "0.93"
  },
  {
      "Date": "4-Feb-08",
      "PE": "23.31",
      "PB": "5.72",
      "Div": "0.94"
  },
  {
      "Date": "1-Feb-08",
      "PE": "22.68",
      "PB": "5.57",
      "Div": "0.96"
  },
  {
      "Date": "31-Jan-08",
      "PE": "21.97",
      "PB": "5.38",
      "Div": "0.99"
  },
  {
      "Date": "30-Jan-08",
      "PE": "22.02",
      "PB": "5.41",
      "Div": "0.99"
  },
  {
      "Date": "29-Jan-08",
      "PE": "22.55",
      "PB": "5.53",
      "Div": "0.97"
  },
  {
      "Date": "28-Jan-08",
      "PE": "22.6",
      "PB": "5.52",
      "Div": "0.97"
  },
  {
      "Date": "25-Jan-08",
      "PE": "22.88",
      "PB": "5.61",
      "Div": "0.95"
  },
  {
      "Date": "24-Jan-08",
      "PE": "21.52",
      "PB": "5.24",
      "Div": "1.02"
  },
  {
      "Date": "23-Jan-08",
      "PE": "22.26",
      "PB": "5.42",
      "Div": "0.99"
  },
  {
      "Date": "22-Jan-08",
      "PE": "20.96",
      "PB": "5.1",
      "Div": "1.05"
  },
  {
      "Date": "21-Jan-08",
      "PE": "22.33",
      "PB": "5.43",
      "Div": "0.99"
  },
  {
      "Date": "18-Jan-08",
      "PE": "24.49",
      "PB": "5.94",
      "Div": "0.9"
  },
  {
      "Date": "17-Jan-08",
      "PE": "25.47",
      "PB": "6.16",
      "Div": "0.87"
  },
  {
      "Date": "16-Jan-08",
      "PE": "26.67",
      "PB": "6.18",
      "Div": "0.87"
  },
  {
      "Date": "15-Jan-08",
      "PE": "27.3",
      "PB": "6.33",
      "Div": "0.85"
  },
  {
      "Date": "14-Jan-08",
      "PE": "27.89",
      "PB": "6.47",
      "Div": "0.83"
  },
  {
      "Date": "11-Jan-08",
      "PE": "27.86",
      "PB": "6.46",
      "Div": "0.83"
  },
  {
      "Date": "10-Jan-08",
      "PE": "27.7",
      "PB": "6.41",
      "Div": "0.83"
  },
  {
      "Date": "9-Jan-08",
      "PE": "28.22",
      "PB": "6.53",
      "Div": "0.82"
  },
  {
      "Date": "8-Jan-08",
      "PE": "28.29",
      "PB": "6.55",
      "Div": "0.82"
  },
  {
      "Date": "7-Jan-08",
      "PE": "28.25",
      "PB": "6.54",
      "Div": "0.82"
  },
  {
      "Date": "4-Jan-08",
      "PE": "28.23",
      "PB": "6.53",
      "Div": "0.82"
  },
  {
      "Date": "3-Jan-08",
      "PE": "27.8",
      "PB": "6.43",
      "Div": "0.83"
  },
  {
      "Date": "2-Jan-08",
      "PE": "27.8",
      "PB": "6.43",
      "Div": "0.83"
  },
  {
      "Date": "1-Jan-08",
      "PE": "27.64",
      "PB": "6.4",
      "Div": "0.84"
  },
  {
      "Date": "31-Dec-07",
      "PE": "27.62",
      "PB": "6.39",
      "Div": "0.84"
  },
  {
      "Date": "28-Dec-07",
      "PE": "27.35",
      "PB": "6.33",
      "Div": "0.85"
  },
  {
      "Date": "27-Dec-07",
      "PE": "27.36",
      "PB": "6.33",
      "Div": "0.85"
  },
  {
      "Date": "26-Dec-07",
      "PE": "27.29",
      "PB": "6.32",
      "Div": "0.85"
  },
  {
      "Date": "24-Dec-07",
      "PE": "26.91",
      "PB": "6.23",
      "Div": "0.86"
  },
  {
      "Date": "20-Dec-07",
      "PE": "25.92",
      "PB": "6",
      "Div": "0.89"
  },
  {
      "Date": "19-Dec-07",
      "PE": "25.85",
      "PB": "5.98",
      "Div": "0.89"
  },
  {
      "Date": "18-Dec-07",
      "PE": "25.81",
      "PB": "5.97",
      "Div": "0.9"
  },
  {
      "Date": "17-Dec-07",
      "PE": "25.97",
      "PB": "6.01",
      "Div": "0.89"
  },
  {
      "Date": "14-Dec-07",
      "PE": "27.19",
      "PB": "6.32",
      "Div": "0.85"
  },
  {
      "Date": "13-Dec-07",
      "PE": "27.23",
      "PB": "6.33",
      "Div": "0.85"
  },
  {
      "Date": "12-Dec-07",
      "PE": "27.69",
      "PB": "6.44",
      "Div": "0.83"
  },
  {
      "Date": "11-Dec-07",
      "PE": "26.68",
      "PB": "6.4",
      "Div": "0.88"
  },
  {
      "Date": "10-Dec-07",
      "PE": "26.08",
      "PB": "6.26",
      "Div": "0.9"
  },
  {
      "Date": "7-Dec-07",
      "PE": "26.14",
      "PB": "6.27",
      "Div": "0.9"
  },
  {
      "Date": "6-Dec-07",
      "PE": "26.05",
      "PB": "6.25",
      "Div": "0.9"
  },
  {
      "Date": "5-Dec-07",
      "PE": "25.98",
      "PB": "6.23",
      "Div": "0.9"
  },
  {
      "Date": "4-Dec-07",
      "PE": "25.63",
      "PB": "6.15",
      "Div": "0.92"
  },
  {
      "Date": "3-Dec-07",
      "PE": "25.66",
      "PB": "6.15",
      "Div": "0.91"
  },
  {
      "Date": "30-Nov-07",
      "PE": "25.21",
      "PB": "6.05",
      "Div": "0.93"
  },
  {
      "Date": "29-Nov-07",
      "PE": "24.65",
      "PB": "5.91",
      "Div": "0.95"
  },
  {
      "Date": "28-Nov-07",
      "PE": "24.57",
      "PB": "5.89",
      "Div": "0.96"
  },
  {
      "Date": "27-Nov-07",
      "PE": "24.92",
      "PB": "5.98",
      "Div": "0.94"
  },
  {
      "Date": "26-Nov-07",
      "PE": "25.07",
      "PB": "6.01",
      "Div": "0.94"
  },
  {
      "Date": "23-Nov-07",
      "PE": "24.53",
      "PB": "5.88",
      "Div": "0.96"
  },
  {
      "Date": "22-Nov-07",
      "PE": "24.17",
      "PB": "5.79",
      "Div": "0.97"
  },
  {
      "Date": "21-Nov-07",
      "PE": "24.35",
      "PB": "5.83",
      "Div": "0.97"
  },
  {
      "Date": "20-Nov-07",
      "PE": "25.32",
      "PB": "6.06",
      "Div": "0.93"
  },
  {
      "Date": "19-Nov-07",
      "PE": "25.87",
      "PB": "6.2",
      "Div": "0.91"
  },
  {
      "Date": "16-Nov-07",
      "PE": "25.86",
      "PB": "6.2",
      "Div": "0.91"
  },
  {
      "Date": "15-Nov-07",
      "PE": "25.87",
      "PB": "6.2",
      "Div": "0.91"
  },
  {
      "Date": "14-Nov-07",
      "PE": "25.98",
      "PB": "6.22",
      "Div": "0.9"
  },
  {
      "Date": "13-Nov-07",
      "PE": "24.92",
      "PB": "5.97",
      "Div": "0.94"
  },
  {
      "Date": "12-Nov-07",
      "PE": "24.57",
      "PB": "5.89",
      "Div": "0.96"
  },
  {
      "Date": "9-Nov-07",
      "PE": "24.77",
      "PB": "5.93",
      "Div": "0.95"
  },
  {
      "Date": "8-Nov-07",
      "PE": "24.92",
      "PB": "5.97",
      "Div": "0.94"
  },
  {
      "Date": "7-Nov-07",
      "PE": "25.29",
      "PB": "6.06",
      "Div": "0.93"
  },
  {
      "Date": "6-Nov-07",
      "PE": "25.31",
      "PB": "6.06",
      "Div": "0.93"
  },
  {
      "Date": "5-Nov-07",
      "PE": "25.57",
      "PB": "6.13",
      "Div": "0.92"
  },
  {
      "Date": "2-Nov-07",
      "PE": "25.94",
      "PB": "6.21",
      "Div": "0.91"
  },
  {
      "Date": "1-Nov-07",
      "PE": "25.65",
      "PB": "6.15",
      "Div": "0.92"
  },
  {
      "Date": "31-Oct-07",
      "PE": "25.74",
      "PB": "6.18",
      "Div": "0.91"
  },
  {
      "Date": "30-Oct-07",
      "PE": "26.01",
      "PB": "6.15",
      "Div": "0.92"
  },
  {
      "Date": "29-Oct-07",
      "PE": "26.35",
      "PB": "6.18",
      "Div": "0.91"
  },
  {
      "Date": "26-Oct-07",
      "PE": "25.57",
      "PB": "5.96",
      "Div": "0.94"
  },
  {
      "Date": "25-Oct-07",
      "PE": "25.01",
      "PB": "5.82",
      "Div": "0.97"
  },
  {
      "Date": "24-Oct-07",
      "PE": "24.66",
      "PB": "5.75",
      "Div": "0.98"
  },
  {
      "Date": "23-Oct-07",
      "PE": "24.53",
      "PB": "5.72",
      "Div": "0.98"
  },
  {
      "Date": "22-Oct-07",
      "PE": "23.28",
      "PB": "5.42",
      "Div": "1.04"
  },
  {
      "Date": "19-Oct-07",
      "PE": "23.46",
      "PB": "5.45",
      "Div": "1.03"
  },
  {
      "Date": "18-Oct-07",
      "PE": "24.33",
      "PB": "5.6",
      "Div": "1.01"
  },
  {
      "Date": "17-Oct-07",
      "PE": "25.2",
      "PB": "5.78",
      "Div": "0.97"
  },
  {
      "Date": "16-Oct-07",
      "PE": "25.71",
      "PB": "5.9",
      "Div": "0.95"
  },
  {
      "Date": "15-Oct-07",
      "PE": "25.77",
      "PB": "6.19",
      "Div": "0.93"
  },
  {
      "Date": "12-Oct-07",
      "PE": "24.67",
      "PB": "5.93",
      "Div": "0.97"
  },
  {
      "Date": "11-Oct-07",
      "PE": "25.13",
      "PB": "6.03",
      "Div": "0.95"
  },
  {
      "Date": "10-Oct-07",
      "PE": "24.78",
      "PB": "5.94",
      "Div": "0.97"
  },
  {
      "Date": "9-Oct-07",
      "PE": "24.26",
      "PB": "5.82",
      "Div": "0.99"
  },
  {
      "Date": "8-Oct-07",
      "PE": "23.16",
      "PB": "5.55",
      "Div": "1.03"
  },
  {
      "Date": "5-Oct-07",
      "PE": "23.62",
      "PB": "5.66",
      "Div": "1.01"
  },
  {
      "Date": "4-Oct-07",
      "PE": "23.42",
      "PB": "5.56",
      "Div": "1.03"
  },
  {
      "Date": "3-Oct-07",
      "PE": "23.43",
      "PB": "5.56",
      "Div": "1.03"
  },
  {
      "Date": "1-Oct-07",
      "PE": "22.79",
      "PB": "5.41",
      "Div": "1.05"
  },
  {
      "Date": "28-Sep-07",
      "PE": "22.58",
      "PB": "5.36",
      "Div": "1.06"
  },
  {
      "Date": "27-Sep-07",
      "PE": "22.49",
      "PB": "5.33",
      "Div": "1.07"
  },
  {
      "Date": "26-Sep-07",
      "PE": "22.21",
      "PB": "5.27",
      "Div": "1.08"
  },
  {
      "Date": "25-Sep-07",
      "PE": "22.21",
      "PB": "5.27",
      "Div": "1.08"
  },
  {
      "Date": "24-Sep-07",
      "PE": "22.18",
      "PB": "5.26",
      "Div": "1.08"
  },
  {
      "Date": "21-Sep-07",
      "PE": "21.89",
      "PB": "5.39",
      "Div": "1.07"
  },
  {
      "Date": "20-Sep-07",
      "PE": "21.49",
      "PB": "5.29",
      "Div": "1.09"
  },
  {
      "Date": "19-Sep-07",
      "PE": "21.42",
      "PB": "5.27",
      "Div": "1.09"
  },
  {
      "Date": "18-Sep-07",
      "PE": "20.57",
      "PB": "5.06",
      "Div": "1.14"
  },
  {
      "Date": "17-Sep-07",
      "PE": "20.34",
      "PB": "5.01",
      "Div": "1.15"
  },
  {
      "Date": "14-Sep-07",
      "PE": "20.45",
      "PB": "5.03",
      "Div": "1.14"
  },
  {
      "Date": "13-Sep-07",
      "PE": "20.5",
      "PB": "5.16",
      "Div": "1.08"
  },
  {
      "Date": "12-Sep-07",
      "PE": "20.35",
      "PB": "5.12",
      "Div": "1.09"
  },
  {
      "Date": "11-Sep-07",
      "PE": "20.35",
      "PB": "5.12",
      "Div": "1.09"
  },
  {
      "Date": "10-Sep-07",
      "PE": "20.4",
      "PB": "5.13",
      "Div": "1.09"
  },
  {
      "Date": "7-Sep-07",
      "PE": "20.41",
      "PB": "5.14",
      "Div": "1.09"
  },
  {
      "Date": "6-Sep-07",
      "PE": "20.45",
      "PB": "5.15",
      "Div": "1.09"
  },
  {
      "Date": "5-Sep-07",
      "PE": "20.26",
      "PB": "5.1",
      "Div": "1.1"
  },
  {
      "Date": "4-Sep-07",
      "PE": "20.27",
      "PB": "5.1",
      "Div": "1.1"
  },
  {
      "Date": "3-Sep-07",
      "PE": "20.25",
      "PB": "5.1",
      "Div": "1.1"
  },
  {
      "Date": "31-Aug-07",
      "PE": "20.2",
      "PB": "5.08",
      "Div": "1.1"
  },
  {
      "Date": "30-Aug-07",
      "PE": "19.96",
      "PB": "5.02",
      "Div": "1.11"
  },
  {
      "Date": "29-Aug-07",
      "PE": "19.72",
      "PB": "4.96",
      "Div": "1.13"
  },
  {
      "Date": "28-Aug-07",
      "PE": "19.55",
      "PB": "4.92",
      "Div": "1.14"
  },
  {
      "Date": "27-Aug-07",
      "PE": "19.47",
      "PB": "4.9",
      "Div": "1.14"
  },
  {
      "Date": "24-Aug-07",
      "PE": "18.96",
      "PB": "4.77",
      "Div": "1.17"
  },
  {
      "Date": "23-Aug-07",
      "PE": "18.62",
      "PB": "4.69",
      "Div": "1.19"
  },
  {
      "Date": "22-Aug-07",
      "PE": "18.79",
      "PB": "4.73",
      "Div": "1.18"
  },
  {
      "Date": "21-Aug-07",
      "PE": "18.43",
      "PB": "4.79",
      "Div": "1.19"
  },
  {
      "Date": "20-Aug-07",
      "PE": "19.04",
      "PB": "4.95",
      "Div": "1.15"
  },
  {
      "Date": "17-Aug-07",
      "PE": "18.58",
      "PB": "4.83",
      "Div": "1.18"
  },
  {
      "Date": "16-Aug-07",
      "PE": "18.9",
      "PB": "4.92",
      "Div": "1.16"
  },
  {
      "Date": "14-Aug-07",
      "PE": "19.77",
      "PB": "5.14",
      "Div": "1.11"
  },
  {
      "Date": "13-Aug-07",
      "PE": "19.81",
      "PB": "5.14",
      "Div": "1.11"
  },
  {
      "Date": "10-Aug-07",
      "PE": "19.63",
      "PB": "5.09",
      "Div": "1.12"
  },
  {
      "Date": "9-Aug-07",
      "PE": "19.94",
      "PB": "5.17",
      "Div": "1.1"
  },
  {
      "Date": "8-Aug-07",
      "PE": "20.2",
      "PB": "5.24",
      "Div": "1.09"
  },
  {
      "Date": "7-Aug-07",
      "PE": "19.72",
      "PB": "5.12",
      "Div": "1.11"
  },
  {
      "Date": "6-Aug-07",
      "PE": "19.65",
      "PB": "5.1",
      "Div": "1.12"
  },
  {
      "Date": "3-Aug-07",
      "PE": "19.93",
      "PB": "5.17",
      "Div": "1.1"
  },
  {
      "Date": "2-Aug-07",
      "PE": "19.72",
      "PB": "5.12",
      "Div": "1.11"
  },
  {
      "Date": "1-Aug-07",
      "PE": "19.65",
      "PB": "5.1",
      "Div": "1.12"
  },
  {
      "Date": "31-Jul-07",
      "PE": "20.49",
      "PB": "5.31",
      "Div": "1.07"
  },
  {
      "Date": "30-Jul-07",
      "PE": "20.34",
      "PB": "5.21",
      "Div": "1.1"
  },
  {
      "Date": "27-Jul-07",
      "PE": "20.87",
      "PB": "5.21",
      "Div": "1.09"
  },
  {
      "Date": "26-Jul-07",
      "PE": "21.71",
      "PB": "5.42",
      "Div": "1.05"
  },
  {
      "Date": "25-Jul-07",
      "PE": "21.71",
      "PB": "5.38",
      "Div": "1.06"
  },
  {
      "Date": "24-Jul-07",
      "PE": "22.01",
      "PB": "5.42",
      "Div": "1.05"
  },
  {
      "Date": "23-Jul-07",
      "PE": "22",
      "PB": "5.42",
      "Div": "1.05"
  },
  {
      "Date": "20-Jul-07",
      "PE": "21.76",
      "PB": "5.35",
      "Div": "1.07"
  },
  {
      "Date": "19-Jul-07",
      "PE": "21.86",
      "PB": "5.77",
      "Div": "1.04"
  },
  {
      "Date": "18-Jul-07",
      "PE": "21.64",
      "PB": "5.69",
      "Div": "1.05"
  },
  {
      "Date": "17-Jul-07",
      "PE": "21.65",
      "PB": "5.69",
      "Div": "1.05"
  },
  {
      "Date": "16-Jul-07",
      "PE": "21.78",
      "PB": "5.71",
      "Div": "1.05"
  },
  {
      "Date": "13-Jul-07",
      "PE": "21.63",
      "PB": "5.67",
      "Div": "1.06"
  },
  {
      "Date": "12-Jul-07",
      "PE": "21.35",
      "PB": "5.6",
      "Div": "1.07"
  },
  {
      "Date": "11-Jul-07",
      "PE": "21.06",
      "PB": "5.52",
      "Div": "1.09"
  },
  {
      "Date": "10-Jul-07",
      "PE": "21.2",
      "PB": "5.55",
      "Div": "1.08"
  },
  {
      "Date": "9-Jul-07",
      "PE": "21.27",
      "PB": "5.56",
      "Div": "1.08"
  },
  {
      "Date": "6-Jul-07",
      "PE": "21.11",
      "PB": "5.52",
      "Div": "1.09"
  },
  {
      "Date": "5-Jul-07",
      "PE": "20.81",
      "PB": "5.44",
      "Div": "1.1"
  },
  {
      "Date": "4-Jul-07",
      "PE": "20.84",
      "PB": "5.45",
      "Div": "1.1"
  },
  {
      "Date": "3-Jul-07",
      "PE": "20.83",
      "PB": "5.45",
      "Div": "1.1"
  },
  {
      "Date": "2-Jul-07",
      "PE": "20.62",
      "PB": "5.39",
      "Div": "1.11"
  },
  {
      "Date": "29-Jun-07",
      "PE": "20.6",
      "PB": "5.4",
      "Div": "1.11"
  },
  {
      "Date": "28-Jun-07",
      "PE": "20.42",
      "PB": "5.35",
      "Div": "1.12"
  },
  {
      "Date": "27-Jun-07",
      "PE": "20.34",
      "PB": "5.33",
      "Div": "1.12"
  },
  {
      "Date": "26-Jun-07",
      "PE": "20.44",
      "PB": "5.36",
      "Div": "1.12"
  },
  {
      "Date": "25-Jun-07",
      "PE": "20.32",
      "PB": "5.32",
      "Div": "1.13"
  },
  {
      "Date": "22-Jun-07",
      "PE": "20.2",
      "PB": "5.31",
      "Div": "1.13"
  },
  {
      "Date": "21-Jun-07",
      "PE": "20.28",
      "PB": "5.48",
      "Div": "1.09"
  },
  {
      "Date": "20-Jun-07",
      "PE": "20.19",
      "PB": "5.46",
      "Div": "1.09"
  },
  {
      "Date": "19-Jun-07",
      "PE": "20.02",
      "PB": "5.41",
      "Div": "1.1"
  },
  {
      "Date": "18-Jun-07",
      "PE": "19.7",
      "PB": "5.33",
      "Div": "1.12"
  },
  {
      "Date": "15-Jun-07",
      "PE": "19.82",
      "PB": "5.36",
      "Div": "1.11"
  },
  {
      "Date": "14-Jun-07",
      "PE": "19.81",
      "PB": "5.36",
      "Div": "1.11"
  },
  {
      "Date": "13-Jun-07",
      "PE": "19.54",
      "PB": "5.28",
      "Div": "1.13"
  },
  {
      "Date": "12-Jun-07",
      "PE": "19.74",
      "PB": "5.34",
      "Div": "1.11"
  },
  {
      "Date": "11-Jun-07",
      "PE": "19.69",
      "PB": "5.33",
      "Div": "1.12"
  },
  {
      "Date": "8-Jun-07",
      "PE": "19.69",
      "PB": "5.32",
      "Div": "1.12"
  },
  {
      "Date": "7-Jun-07",
      "PE": "19.85",
      "PB": "5.37",
      "Div": "1.11"
  },
  {
      "Date": "6-Jun-07",
      "PE": "19.94",
      "PB": "5.39",
      "Div": "1.1"
  },
  {
      "Date": "5-Jun-07",
      "PE": "20.35",
      "PB": "5.5",
      "Div": "1.08"
  },
  {
      "Date": "4-Jun-07",
      "PE": "20.27",
      "PB": "5.48",
      "Div": "1.09"
  },
  {
      "Date": "1-Jun-07",
      "PE": "20.41",
      "PB": "5.59",
      "Div": "1.1"
  },
  {
      "Date": "31-May-07",
      "PE": "20.41",
      "PB": "5.59",
      "Div": "1.1"
  },
  {
      "Date": "30-May-07",
      "PE": "20.17",
      "PB": "5.53",
      "Div": "1.11"
  },
  {
      "Date": "29-May-07",
      "PE": "20.11",
      "PB": "5.59",
      "Div": "1.1"
  },
  {
      "Date": "28-May-07",
      "PE": "20.02",
      "PB": "5.54",
      "Div": "1.11"
  },
  {
      "Date": "25-May-07",
      "PE": "19.98",
      "PB": "5.53",
      "Div": "1.12"
  },
  {
      "Date": "24-May-07",
      "PE": "19.59",
      "PB": "5.47",
      "Div": "1.13"
  },
  {
      "Date": "23-May-07",
      "PE": "19.78",
      "PB": "5.53",
      "Div": "1.12"
  },
  {
      "Date": "22-May-07",
      "PE": "19.91",
      "PB": "5.57",
      "Div": "1.11"
  },
  {
      "Date": "21-May-07",
      "PE": "19.83",
      "PB": "5.54",
      "Div": "1.11"
  },
  {
      "Date": "18-May-07",
      "PE": "19.78",
      "PB": "5.48",
      "Div": "1.12"
  },
  {
      "Date": "17-May-07",
      "PE": "19.93",
      "PB": "5.49",
      "Div": "1.12"
  },
  {
      "Date": "16-May-07",
      "PE": "19.7",
      "PB": "5.43",
      "Div": "1.14"
  },
  {
      "Date": "15-May-07",
      "PE": "19.46",
      "PB": "5.36",
      "Div": "1.15"
  },
  {
      "Date": "14-May-07",
      "PE": "19.54",
      "PB": "5.39",
      "Div": "1.13"
  },
  {
      "Date": "11-May-07",
      "PE": "19.35",
      "PB": "5.31",
      "Div": "1.15"
  },
  {
      "Date": "10-May-07",
      "PE": "19.31",
      "PB": "5.3",
      "Div": "1.15"
  },
  {
      "Date": "9-May-07",
      "PE": "19.36",
      "PB": "5.32",
      "Div": "1.15"
  },
  {
      "Date": "8-May-07",
      "PE": "19.39",
      "PB": "5.31",
      "Div": "1.15"
  },
  {
      "Date": "7-May-07",
      "PE": "19.56",
      "PB": "5.36",
      "Div": "1.14"
  },
  {
      "Date": "4-May-07",
      "PE": "19.59",
      "PB": "5.36",
      "Div": "1.14"
  },
  {
      "Date": "3-May-07",
      "PE": "19.76",
      "PB": "5.41",
      "Div": "1.13"
  },
  {
      "Date": "30-Apr-07",
      "PE": "19.48",
      "PB": "5.33",
      "Div": "1.15"
  },
  {
      "Date": "27-Apr-07",
      "PE": "19.58",
      "PB": "5.32",
      "Div": "1.15"
  },
  {
      "Date": "26-Apr-07",
      "PE": "20.25",
      "PB": "5.44",
      "Div": "1.12"
  },
  {
      "Date": "25-Apr-07",
      "PE": "20.24",
      "PB": "5.43",
      "Div": "1.13"
  },
  {
      "Date": "24-Apr-07",
      "PE": "20.13",
      "PB": "5.4",
      "Div": "1.13"
  },
  {
      "Date": "23-Apr-07",
      "PE": "19.88",
      "PB": "5.32",
      "Div": "1.15"
  },
  {
      "Date": "20-Apr-07",
      "PE": "19.88",
      "PB": "5.32",
      "Div": "1.15"
  },
  {
      "Date": "19-Apr-07",
      "PE": "19.57",
      "PB": "5.21",
      "Div": "1.17"
  },
  {
      "Date": "18-Apr-07",
      "PE": "19.66",
      "PB": "5.22",
      "Div": "1.17"
  },
  {
      "Date": "17-Apr-07",
      "PE": "19.55",
      "PB": "5.19",
      "Div": "1.18"
  },
  {
      "Date": "16-Apr-07",
      "PE": "19.75",
      "PB": "5.23",
      "Div": "1.17"
  },
  {
      "Date": "13-Apr-07",
      "PE": "19.28",
      "PB": "5.1",
      "Div": "1.2"
  },
  {
      "Date": "12-Apr-07",
      "PE": "18.93",
      "PB": "4.99",
      "Div": "1.23"
  },
  {
      "Date": "11-Apr-07",
      "PE": "19.09",
      "PB": "5.03",
      "Div": "1.22"
  },
  {
      "Date": "10-Apr-07",
      "PE": "19.02",
      "PB": "5.04",
      "Div": "1.2"
  },
  {
      "Date": "9-Apr-07",
      "PE": "19",
      "PB": "5.03",
      "Div": "1.2"
  },
  {
      "Date": "5-Apr-07",
      "PE": "18.54",
      "PB": "4.91",
      "Div": "1.23"
  },
  {
      "Date": "4-Apr-07",
      "PE": "18.43",
      "PB": "4.89",
      "Div": "1.24"
  },
  {
      "Date": "3-Apr-07",
      "PE": "17.77",
      "PB": "4.71",
      "Div": "1.29"
  },
  {
      "Date": "2-Apr-07",
      "PE": "17.49",
      "PB": "4.63",
      "Div": "1.31"
  },
  {
      "Date": "30-Mar-07",
      "PE": "18.4",
      "PB": "4.87",
      "Div": "1.25"
  },
  {
      "Date": "29-Mar-07",
      "PE": "18.29",
      "PB": "4.84",
      "Div": "1.25"
  },
  {
      "Date": "28-Mar-07",
      "PE": "18.1",
      "PB": "4.79",
      "Div": "1.27"
  },
  {
      "Date": "26-Mar-07",
      "PE": "18.38",
      "PB": "4.87",
      "Div": "1.25"
  },
  {
      "Date": "23-Mar-07",
      "PE": "18.58",
      "PB": "4.92",
      "Div": "1.23"
  },
  {
      "Date": "22-Mar-07",
      "PE": "18.65",
      "PB": "4.94",
      "Div": "1.23"
  },
  {
      "Date": "21-Mar-07",
      "PE": "18.11",
      "PB": "4.8",
      "Div": "1.26"
  },
  {
      "Date": "20-Mar-07",
      "PE": "17.79",
      "PB": "4.71",
      "Div": "1.29"
  },
  {
      "Date": "19-Mar-07",
      "PE": "17.7",
      "PB": "4.69",
      "Div": "1.29"
  },
  {
      "Date": "16-Mar-07",
      "PE": "17.36",
      "PB": "4.6",
      "Div": "1.32"
  },
  {
      "Date": "15-Mar-07",
      "PE": "17.53",
      "PB": "4.64",
      "Div": "1.31"
  },
  {
      "Date": "14-Mar-07",
      "PE": "17.51",
      "PB": "4.64",
      "Div": "1.31"
  },
  {
      "Date": "13-Mar-07",
      "PE": "18.14",
      "PB": "4.8",
      "Div": "1.26"
  },
  {
      "Date": "12-Mar-07",
      "PE": "17.96",
      "PB": "4.76",
      "Div": "1.28"
  },
  {
      "Date": "9-Mar-07",
      "PE": "17.88",
      "PB": "4.74",
      "Div": "1.28"
  },
  {
      "Date": "8-Mar-07",
      "PE": "18.09",
      "PB": "4.79",
      "Div": "1.27"
  },
  {
      "Date": "7-Mar-07",
      "PE": "17.44",
      "PB": "4.62",
      "Div": "1.31"
  },
  {
      "Date": "6-Mar-07",
      "PE": "17.58",
      "PB": "4.66",
      "Div": "1.3"
  },
  {
      "Date": "5-Mar-07",
      "PE": "17.2",
      "PB": "4.56",
      "Div": "1.33"
  },
  {
      "Date": "2-Mar-07",
      "PE": "17.92",
      "PB": "4.75",
      "Div": "1.28"
  },
  {
      "Date": "1-Mar-07",
      "PE": "18.33",
      "PB": "4.86",
      "Div": "1.25"
  },
  {
      "Date": "28-Feb-07",
      "PE": "18.01",
      "PB": "4.77",
      "Div": "1.27"
  },
  {
      "Date": "27-Feb-07",
      "PE": "18.72",
      "PB": "4.96",
      "Div": "1.22"
  },
  {
      "Date": "26-Feb-07",
      "PE": "18.95",
      "PB": "5.02",
      "Div": "1.21"
  },
  {
      "Date": "23-Feb-07",
      "PE": "18.94",
      "PB": "5.02",
      "Div": "1.21"
  },
  {
      "Date": "22-Feb-07",
      "PE": "19.42",
      "PB": "5.14",
      "Div": "1.18"
  },
  {
      "Date": "21-Feb-07",
      "PE": "19.69",
      "PB": "5.22",
      "Div": "1.16"
  },
  {
      "Date": "20-Feb-07",
      "PE": "19.74",
      "PB": "5.23",
      "Div": "1.16"
  },
  {
      "Date": "19-Feb-07",
      "PE": "20.02",
      "PB": "5.3",
      "Div": "1.14"
  },
  {
      "Date": "15-Feb-07",
      "PE": "19.94",
      "PB": "5.28",
      "Div": "1.15"
  },
  {
      "Date": "14-Feb-07",
      "PE": "19.47",
      "PB": "5.15",
      "Div": "1.18"
  },
  {
      "Date": "13-Feb-07",
      "PE": "19.45",
      "PB": "5.15",
      "Div": "1.18"
  },
  {
      "Date": "12-Feb-07",
      "PE": "19.52",
      "PB": "5.17",
      "Div": "1.17"
  },
  {
      "Date": "9-Feb-07",
      "PE": "20.14",
      "PB": "5.33",
      "Div": "1.14"
  },
  {
      "Date": "8-Feb-07",
      "PE": "20.31",
      "PB": "5.38",
      "Div": "1.13"
  },
  {
      "Date": "7-Feb-07",
      "PE": "20.32",
      "PB": "5.38",
      "Div": "1.13"
  },
  {
      "Date": "6-Feb-07",
      "PE": "20.18",
      "PB": "5.35",
      "Div": "1.14"
  },
  {
      "Date": "5-Feb-07",
      "PE": "20.27",
      "PB": "5.37",
      "Div": "1.13"
  },
  {
      "Date": "2-Feb-07",
      "PE": "20.12",
      "PB": "5.33",
      "Div": "1.14"
  },
  {
      "Date": "1-Feb-07",
      "PE": "19.95",
      "PB": "5.27",
      "Div": "1.15"
  },
  {
      "Date": "31-Jan-07",
      "PE": "19.85",
      "PB": "5.2",
      "Div": "1.17"
  },
  {
      "Date": "29-Jan-07",
      "PE": "20.88",
      "PB": "5.26",
      "Div": "1.15"
  },
  {
      "Date": "25-Jan-07",
      "PE": "21.35",
      "PB": "5.29",
      "Div": "1.15"
  },
  {
      "Date": "24-Jan-07",
      "PE": "21.07",
      "PB": "5.21",
      "Div": "1.16"
  },
  {
      "Date": "23-Jan-07",
      "PE": "20.96",
      "PB": "5.18",
      "Div": "1.17"
  },
  {
      "Date": "22-Jan-07",
      "PE": "21.42",
      "PB": "5.22",
      "Div": "1.16"
  },
  {
      "Date": "19-Jan-07",
      "PE": "21.43",
      "PB": "5.21",
      "Div": "1.17"
  },
  {
      "Date": "18-Jan-07",
      "PE": "21.74",
      "PB": "5.23",
      "Div": "1.16"
  },
  {
      "Date": "17-Jan-07",
      "PE": "21.59",
      "PB": "5.19",
      "Div": "1.17"
  },
  {
      "Date": "16-Jan-07",
      "PE": "21.67",
      "PB": "5.19",
      "Div": "1.17"
  },
  {
      "Date": "15-Jan-07",
      "PE": "21.77",
      "PB": "5.19",
      "Div": "1.17"
  },
  {
      "Date": "12-Jan-07",
      "PE": "21.63",
      "PB": "5.16",
      "Div": "1.18"
  },
  {
      "Date": "11-Jan-07",
      "PE": "21.04",
      "PB": "5.04",
      "Div": "1.22"
  },
  {
      "Date": "10-Jan-07",
      "PE": "20.64",
      "PB": "4.92",
      "Div": "1.25"
  },
  {
      "Date": "9-Jan-07",
      "PE": "20.97",
      "PB": "5",
      "Div": "1.23"
  },
  {
      "Date": "8-Jan-07",
      "PE": "21.08",
      "PB": "5.03",
      "Div": "1.23"
  },
  {
      "Date": "5-Jan-07",
      "PE": "21.35",
      "PB": "5.09",
      "Div": "1.21"
  },
  {
      "Date": "4-Jan-07",
      "PE": "21.38",
      "PB": "5.1",
      "Div": "1.21"
  },
  {
      "Date": "3-Jan-07",
      "PE": "21.57",
      "PB": "5.14",
      "Div": "1.2"
  },
  {
      "Date": "2-Jan-07",
      "PE": "21.48",
      "PB": "5.12",
      "Div": "1.2"
  },
  {
      "Date": "29-Dec-06",
      "PE": "21.26",
      "PB": "5.07",
      "Div": "1.22"
  },
  {
      "Date": "28-Dec-06",
      "PE": "21.28",
      "PB": "5.08",
      "Div": "1.22"
  },
  {
      "Date": "27-Dec-06",
      "PE": "21.3",
      "PB": "5.08",
      "Div": "1.21"
  },
  {
      "Date": "26-Dec-06",
      "PE": "21.12",
      "PB": "5.04",
      "Div": "1.22"
  },
  {
      "Date": "22-Dec-06",
      "PE": "20.74",
      "PB": "4.95",
      "Div": "1.25"
  },
  {
      "Date": "21-Dec-06",
      "PE": "20.54",
      "PB": "4.9",
      "Div": "1.26"
  },
  {
      "Date": "20-Dec-06",
      "PE": "20.45",
      "PB": "4.88",
      "Div": "1.26"
  },
  {
      "Date": "19-Dec-06",
      "PE": "20.53",
      "PB": "4.9",
      "Div": "1.26"
  },
  {
      "Date": "18-Dec-06",
      "PE": "21.05",
      "PB": "5.02",
      "Div": "1.23"
  },
  {
      "Date": "15-Dec-06",
      "PE": "20.83",
      "PB": "4.97",
      "Div": "1.24"
  },
  {
      "Date": "14-Dec-06",
      "PE": "20.59",
      "PB": "4.91",
      "Div": "1.26"
  },
  {
      "Date": "13-Dec-06",
      "PE": "20.17",
      "PB": "4.81",
      "Div": "1.28"
  },
  {
      "Date": "12-Dec-06",
      "PE": "19.91",
      "PB": "4.75",
      "Div": "1.3"
  },
  {
      "Date": "11-Dec-06",
      "PE": "20.62",
      "PB": "4.92",
      "Div": "1.25"
  },
  {
      "Date": "8-Dec-06",
      "PE": "21.23",
      "PB": "5.06",
      "Div": "1.22"
  },
  {
      "Date": "7-Dec-06",
      "PE": "21.54",
      "PB": "5.13",
      "Div": "1.2"
  },
  {
      "Date": "6-Dec-06",
      "PE": "21.51",
      "PB": "5.13",
      "Div": "1.2"
  },
  {
      "Date": "5-Dec-06",
      "PE": "21.51",
      "PB": "5.13",
      "Div": "1.2"
  },
  {
      "Date": "4-Dec-06",
      "PE": "21.43",
      "PB": "5.11",
      "Div": "1.21"
  },
  {
      "Date": "1-Dec-06",
      "PE": "21.41",
      "PB": "5.14",
      "Div": "1.21"
  },
  {
      "Date": "30-Nov-06",
      "PE": "21.18",
      "PB": "5.08",
      "Div": "1.22"
  },
  {
      "Date": "29-Nov-06",
      "PE": "21.03",
      "PB": "5.05",
      "Div": "1.23"
  },
  {
      "Date": "28-Nov-06",
      "PE": "21",
      "PB": "5.04",
      "Div": "1.23"
  },
  {
      "Date": "27-Nov-06",
      "PE": "21.25",
      "PB": "5.1",
      "Div": "1.22"
  },
  {
      "Date": "24-Nov-06",
      "PE": "21.17",
      "PB": "5.08",
      "Div": "1.22"
  },
  {
      "Date": "23-Nov-06",
      "PE": "21.14",
      "PB": "5.07",
      "Div": "1.22"
  },
  {
      "Date": "22-Nov-06",
      "PE": "21.2",
      "PB": "5.08",
      "Div": "1.22"
  },
  {
      "Date": "21-Nov-06",
      "PE": "21",
      "PB": "5.03",
      "Div": "1.23"
  },
  {
      "Date": "20-Nov-06",
      "PE": "20.67",
      "PB": "4.95",
      "Div": "1.25"
  },
  {
      "Date": "17-Nov-06",
      "PE": "20.65",
      "PB": "4.95",
      "Div": "1.25"
  },
  {
      "Date": "16-Nov-06",
      "PE": "20.78",
      "PB": "4.98",
      "Div": "1.25"
  },
  {
      "Date": "15-Nov-06",
      "PE": "20.78",
      "PB": "4.98",
      "Div": "1.25"
  },
  {
      "Date": "14-Nov-06",
      "PE": "20.7",
      "PB": "4.96",
      "Div": "1.25"
  },
  {
      "Date": "13-Nov-06",
      "PE": "20.66",
      "PB": "4.95",
      "Div": "1.25"
  },
  {
      "Date": "10-Nov-06",
      "PE": "20.53",
      "PB": "4.92",
      "Div": "1.26"
  },
  {
      "Date": "9-Nov-06",
      "PE": "20.32",
      "PB": "4.87",
      "Div": "1.27"
  },
  {
      "Date": "8-Nov-06",
      "PE": "20.22",
      "PB": "4.88",
      "Div": "1.31"
  },
  {
      "Date": "7-Nov-06",
      "PE": "20.34",
      "PB": "4.91",
      "Div": "1.3"
  },
  {
      "Date": "6-Nov-06",
      "PE": "20.39",
      "PB": "4.92",
      "Div": "1.3"
  },
  {
      "Date": "3-Nov-06",
      "PE": "20.37",
      "PB": "4.92",
      "Div": "1.3"
  },
  {
      "Date": "2-Nov-06",
      "PE": "20.29",
      "PB": "4.9",
      "Div": "1.31"
  },
  {
      "Date": "1-Nov-06",
      "PE": "20.16",
      "PB": "4.87",
      "Div": "1.32"
  },
  {
      "Date": "31-Oct-06",
      "PE": "20.37",
      "PB": "4.84",
      "Div": "1.32"
  },
  {
      "Date": "30-Oct-06",
      "PE": "20.48",
      "PB": "4.87",
      "Div": "1.31"
  },
  {
      "Date": "27-Oct-06",
      "PE": "21.04",
      "PB": "4.83",
      "Div": "1.32"
  },
  {
      "Date": "26-Oct-06",
      "PE": "20.79",
      "PB": "4.75",
      "Div": "1.35"
  },
  {
      "Date": "23-Oct-06",
      "PE": "20.79",
      "PB": "4.72",
      "Div": "1.35"
  },
  {
      "Date": "21-Oct-06",
      "PE": "20.94",
      "PB": "4.76",
      "Div": "1.35"
  },
  {
      "Date": "20-Oct-06",
      "PE": "20.91",
      "PB": "4.75",
      "Div": "1.35"
  },
  {
      "Date": "19-Oct-06",
      "PE": "21.06",
      "PB": "4.75",
      "Div": "1.35"
  },
  {
      "Date": "18-Oct-06",
      "PE": "21.33",
      "PB": "4.79",
      "Div": "1.34"
  },
  {
      "Date": "17-Oct-06",
      "PE": "21.47",
      "PB": "4.79",
      "Div": "1.33"
  },
  {
      "Date": "16-Oct-06",
      "PE": "21.64",
      "PB": "4.81",
      "Div": "1.33"
  },
  {
      "Date": "13-Oct-06",
      "PE": "21.36",
      "PB": "4.74",
      "Div": "1.35"
  },
  {
      "Date": "12-Oct-06",
      "PE": "21.04",
      "PB": "4.67",
      "Div": "1.37"
  },
  {
      "Date": "11-Oct-06",
      "PE": "20.68",
      "PB": "4.59",
      "Div": "1.39"
  },
  {
      "Date": "10-Oct-06",
      "PE": "20.83",
      "PB": "4.73",
      "Div": "1.36"
  },
  {
      "Date": "9-Oct-06",
      "PE": "20.82",
      "PB": "4.73",
      "Div": "1.36"
  },
  {
      "Date": "6-Oct-06",
      "PE": "20.82",
      "PB": "4.73",
      "Div": "1.33"
  },
  {
      "Date": "5-Oct-06",
      "PE": "20.79",
      "PB": "4.72",
      "Div": "1.34"
  },
  {
      "Date": "4-Oct-06",
      "PE": "20.5",
      "PB": "4.66",
      "Div": "1.36"
  },
  {
      "Date": "3-Oct-06",
      "PE": "20.82",
      "PB": "4.73",
      "Div": "1.33"
  },
  {
      "Date": "29-Sep-06",
      "PE": "20.92",
      "PB": "4.76",
      "Div": "1.33"
  },
  {
      "Date": "28-Sep-06",
      "PE": "20.83",
      "PB": "4.73",
      "Div": "1.33"
  },
  {
      "Date": "27-Sep-06",
      "PE": "20.87",
      "PB": "4.74",
      "Div": "1.33"
  },
  {
      "Date": "26-Sep-06",
      "PE": "20.83",
      "PB": "4.73",
      "Div": "1.33"
  },
  {
      "Date": "25-Sep-06",
      "PE": "20.21",
      "PB": "4.59",
      "Div": "1.37"
  },
  {
      "Date": "22-Sep-06",
      "PE": "20.33",
      "PB": "4.62",
      "Div": "1.37"
  },
  {
      "Date": "21-Sep-06",
      "PE": "20.38",
      "PB": "4.63",
      "Div": "1.36"
  },
  {
      "Date": "20-Sep-06",
      "PE": "20.09",
      "PB": "4.57",
      "Div": "1.38"
  },
  {
      "Date": "19-Sep-06",
      "PE": "19.83",
      "PB": "4.51",
      "Div": "1.4"
  },
  {
      "Date": "18-Sep-06",
      "PE": "20.03",
      "PB": "4.55",
      "Div": "1.39"
  },
  {
      "Date": "15-Sep-06",
      "PE": "19.95",
      "PB": "4.53",
      "Div": "1.39"
  },
  {
      "Date": "14-Sep-06",
      "PE": "19.91",
      "PB": "4.53",
      "Div": "1.4"
  },
  {
      "Date": "13-Sep-06",
      "PE": "19.81",
      "PB": "4.5",
      "Div": "1.4"
  },
  {
      "Date": "12-Sep-06",
      "PE": "19.42",
      "PB": "4.44",
      "Div": "1.45"
  },
  {
      "Date": "11-Sep-06",
      "PE": "19.29",
      "PB": "4.41",
      "Div": "1.46"
  },
  {
      "Date": "8-Sep-06",
      "PE": "19.89",
      "PB": "4.55",
      "Div": "1.42"
  },
  {
      "Date": "7-Sep-06",
      "PE": "19.79",
      "PB": "4.53",
      "Div": "1.42"
  },
  {
      "Date": "6-Sep-06",
      "PE": "19.92",
      "PB": "4.56",
      "Div": "1.41"
  },
  {
      "Date": "5-Sep-06",
      "PE": "19.9",
      "PB": "4.55",
      "Div": "1.42"
  },
  {
      "Date": "4-Sep-06",
      "PE": "19.92",
      "PB": "4.56",
      "Div": "1.41"
  },
  {
      "Date": "1-Sep-06",
      "PE": "19.68",
      "PB": "4.5",
      "Div": "1.43"
  },
  {
      "Date": "31-Aug-06",
      "PE": "19.15",
      "PB": "4.55",
      "Div": "1.47"
  },
  {
      "Date": "30-Aug-06",
      "PE": "19.25",
      "PB": "4.58",
      "Div": "1.47"
  },
  {
      "Date": "29-Aug-06",
      "PE": "19.22",
      "PB": "4.64",
      "Div": "1.47"
  },
  {
      "Date": "28-Aug-06",
      "PE": "19.08",
      "PB": "4.6",
      "Div": "1.48"
  },
  {
      "Date": "25-Aug-06",
      "PE": "19",
      "PB": "4.58",
      "Div": "1.48"
  },
  {
      "Date": "24-Aug-06",
      "PE": "18.91",
      "PB": "4.56",
      "Div": "1.49"
  },
  {
      "Date": "23-Aug-06",
      "PE": "18.71",
      "PB": "4.52",
      "Div": "1.51"
  },
  {
      "Date": "22-Aug-06",
      "PE": "18.88",
      "PB": "4.55",
      "Div": "1.49"
  },
  {
      "Date": "21-Aug-06",
      "PE": "18.88",
      "PB": "4.56",
      "Div": "1.49"
  },
  {
      "Date": "18-Aug-06",
      "PE": "18.84",
      "PB": "4.54",
      "Div": "1.5"
  },
  {
      "Date": "17-Aug-06",
      "PE": "18.83",
      "PB": "4.54",
      "Div": "1.5"
  },
  {
      "Date": "16-Aug-06",
      "PE": "18.84",
      "PB": "4.58",
      "Div": "1.48"
  },
  {
      "Date": "14-Aug-06",
      "PE": "18.6",
      "PB": "4.52",
      "Div": "1.5"
  },
  {
      "Date": "11-Aug-06",
      "PE": "18.38",
      "PB": "4.46",
      "Div": "1.52"
  },
  {
      "Date": "10-Aug-06",
      "PE": "18.3",
      "PB": "4.45",
      "Div": "1.52"
  },
  {
      "Date": "9-Aug-06",
      "PE": "18.27",
      "PB": "4.44",
      "Div": "1.53"
  },
  {
      "Date": "8-Aug-06",
      "PE": "18.03",
      "PB": "4.38",
      "Div": "1.55"
  },
  {
      "Date": "7-Aug-06",
      "PE": "17.69",
      "PB": "4.3",
      "Div": "1.58"
  },
  {
      "Date": "4-Aug-06",
      "PE": "17.83",
      "PB": "4.33",
      "Div": "1.56"
  },
  {
      "Date": "3-Aug-06",
      "PE": "17.9",
      "PB": "4.35",
      "Div": "1.56"
  },
  {
      "Date": "2-Aug-06",
      "PE": "17.86",
      "PB": "4.34",
      "Div": "1.56"
  },
  {
      "Date": "1-Aug-06",
      "PE": "17.67",
      "PB": "4.29",
      "Div": "1.58"
  },
  {
      "Date": "31-Jul-06",
      "PE": "17.64",
      "PB": "4.29",
      "Div": "1.58"
  },
  {
      "Date": "28-Jul-06",
      "PE": "17.57",
      "PB": "4.27",
      "Div": "1.59"
  },
  {
      "Date": "27-Jul-06",
      "PE": "17.83",
      "PB": "4.3",
      "Div": "1.57"
  },
  {
      "Date": "26-Jul-06",
      "PE": "17.58",
      "PB": "4.45",
      "Div": "1.53"
  },
  {
      "Date": "25-Jul-06",
      "PE": "17.44",
      "PB": "4.35",
      "Div": "1.57"
  },
  {
      "Date": "24-Jul-06",
      "PE": "17.13",
      "PB": "4.27",
      "Div": "1.6"
  },
  {
      "Date": "21-Jul-06",
      "PE": "16.93",
      "PB": "4.21",
      "Div": "1.62"
  },
  {
      "Date": "20-Jul-06",
      "PE": "17.43",
      "PB": "4.32",
      "Div": "1.58"
  },
  {
      "Date": "19-Jul-06",
      "PE": "17",
      "PB": "4.19",
      "Div": "1.63"
  },
  {
      "Date": "18-Jul-06",
      "PE": "17.52",
      "PB": "4.28",
      "Div": "1.59"
  },
  {
      "Date": "17-Jul-06",
      "PE": "17.63",
      "PB": "4.3",
      "Div": "1.59"
  },
  {
      "Date": "14-Jul-06",
      "PE": "18.33",
      "PB": "4.47",
      "Div": "1.54"
  },
  {
      "Date": "13-Jul-06",
      "PE": "18.61",
      "PB": "4.72",
      "Div": "1.47"
  },
  {
      "Date": "12-Jul-06",
      "PE": "18.76",
      "PB": "4.76",
      "Div": "1.46"
  },
  {
      "Date": "11-Jul-06",
      "PE": "18.36",
      "PB": "4.64",
      "Div": "1.5"
  },
  {
      "Date": "10-Jul-06",
      "PE": "18.52",
      "PB": "4.68",
      "Div": "1.49"
  },
  {
      "Date": "7-Jul-06",
      "PE": "18.13",
      "PB": "4.58",
      "Div": "1.52"
  },
  {
      "Date": "6-Jul-06",
      "PE": "18.6",
      "PB": "4.7",
      "Div": "1.48"
  },
  {
      "Date": "5-Jul-06",
      "PE": "18.84",
      "PB": "4.76",
      "Div": "1.46"
  },
  {
      "Date": "4-Jul-06",
      "PE": "18.5",
      "PB": "4.67",
      "Div": "1.49"
  },
  {
      "Date": "3-Jul-06",
      "PE": "18.57",
      "PB": "4.69",
      "Div": "1.48"
  },
  {
      "Date": "30-Jun-06",
      "PE": "18.44",
      "PB": "4.66",
      "Div": "1.49"
  },
  {
      "Date": "29-Jun-06",
      "PE": "17.67",
      "PB": "4.46",
      "Div": "1.56"
  },
  {
      "Date": "28-Jun-06",
      "PE": "17.57",
      "PB": "4.44",
      "Div": "1.57"
  },
  {
      "Date": "27-Jun-06",
      "PE": "17.36",
      "PB": "4.44",
      "Div": "1.57"
  },
  {
      "Date": "26-Jun-06",
      "PE": "16.73",
      "PB": "4.24",
      "Div": "1.64"
  },
  {
      "Date": "25-Jun-06",
      "PE": "17.34",
      "PB": "4.39",
      "Div": "1.58"
  },
  {
      "Date": "23-Jun-06",
      "PE": "17.3",
      "PB": "4.38",
      "Div": "1.58"
  },
  {
      "Date": "22-Jun-06",
      "PE": "17.03",
      "PB": "4.31",
      "Div": "1.61"
  },
  {
      "Date": "21-Jun-06",
      "PE": "16.62",
      "PB": "4.21",
      "Div": "1.65"
  },
  {
      "Date": "20-Jun-06",
      "PE": "16.27",
      "PB": "4.12",
      "Div": "1.68"
  },
  {
      "Date": "19-Jun-06",
      "PE": "16.58",
      "PB": "4.2",
      "Div": "1.65"
  },
  {
      "Date": "16-Jun-06",
      "PE": "16.43",
      "PB": "4.28",
      "Div": "1.64"
  },
  {
      "Date": "15-Jun-06",
      "PE": "15.91",
      "PB": "4.14",
      "Div": "1.69"
  },
  {
      "Date": "14-Jun-06",
      "PE": "14.92",
      "PB": "3.9",
      "Div": "1.8"
  },
  {
      "Date": "13-Jun-06",
      "PE": "15.09",
      "PB": "3.94",
      "Div": "1.78"
  },
  {
      "Date": "12-Jun-06",
      "PE": "15.73",
      "PB": "4.11",
      "Div": "1.71"
  },
  {
      "Date": "9-Jun-06",
      "PE": "16.24",
      "PB": "4.24",
      "Div": "1.65"
  },
  {
      "Date": "8-Jun-06",
      "PE": "15.44",
      "PB": "4.03",
      "Div": "1.74"
  },
  {
      "Date": "7-Jun-06",
      "PE": "16.21",
      "PB": "4.23",
      "Div": "1.66"
  },
  {
      "Date": "6-Jun-06",
      "PE": "16.64",
      "PB": "4.35",
      "Div": "1.62"
  },
  {
      "Date": "5-Jun-06",
      "PE": "17.09",
      "PB": "4.49",
      "Div": "1.51"
  },
  {
      "Date": "2-Jun-06",
      "PE": "17.51",
      "PB": "4.6",
      "Div": "1.47"
  },
  {
      "Date": "1-Jun-06",
      "PE": "16.78",
      "PB": "4.41",
      "Div": "1.53"
  },
  {
      "Date": "31-May-06",
      "PE": "17.46",
      "PB": "4.57",
      "Div": "1.48"
  },
  {
      "Date": "30-May-06",
      "PE": "18.11",
      "PB": "4.74",
      "Div": "1.43"
  },
  {
      "Date": "29-May-06",
      "PE": "18.27",
      "PB": "4.78",
      "Div": "1.41"
  },
  {
      "Date": "26-May-06",
      "PE": "18.28",
      "PB": "4.77",
      "Div": "1.42"
  },
  {
      "Date": "25-May-06",
      "PE": "18.39",
      "PB": "4.73",
      "Div": "1.43"
  },
  {
      "Date": "24-May-06",
      "PE": "17.71",
      "PB": "4.63",
      "Div": "1.46"
  },
  {
      "Date": "23-May-06",
      "PE": "18.19",
      "PB": "4.85",
      "Div": "1.41"
  },
  {
      "Date": "22-May-06",
      "PE": "17.52",
      "PB": "4.67",
      "Div": "1.46"
  },
  {
      "Date": "19-May-06",
      "PE": "18.51",
      "PB": "4.92",
      "Div": "1.39"
  },
  {
      "Date": "18-May-06",
      "PE": "19.26",
      "PB": "5.13",
      "Div": "1.33"
  },
  {
      "Date": "17-May-06",
      "PE": "20.62",
      "PB": "5.51",
      "Div": "1.24"
  },
  {
      "Date": "16-May-06",
      "PE": "19.97",
      "PB": "5.34",
      "Div": "1.28"
  },
  {
      "Date": "15-May-06",
      "PE": "19.86",
      "PB": "5.3",
      "Div": "1.29"
  },
  {
      "Date": "12-May-06",
      "PE": "20.69",
      "PB": "5.53",
      "Div": "1.24"
  },
  {
      "Date": "11-May-06",
      "PE": "20.98",
      "PB": "5.6",
      "Div": "1.22"
  },
  {
      "Date": "10-May-06",
      "PE": "21.28",
      "PB": "5.68",
      "Div": "1.2"
  },
  {
      "Date": "9-May-06",
      "PE": "21.09",
      "PB": "5.63",
      "Div": "1.21"
  },
  {
      "Date": "8-May-06",
      "PE": "20.93",
      "PB": "5.59",
      "Div": "1.22"
  },
  {
      "Date": "5-May-06",
      "PE": "20.77",
      "PB": "5.55",
      "Div": "1.23"
  },
  {
      "Date": "4-May-06",
      "PE": "20.7",
      "PB": "5.52",
      "Div": "1.24"
  },
  {
      "Date": "3-May-06",
      "PE": "20.62",
      "PB": "5.5",
      "Div": "1.24"
  },
  {
      "Date": "2-May-06",
      "PE": "20.46",
      "PB": "5.46",
      "Div": "1.25"
  },
  {
      "Date": "29-Apr-06",
      "PE": "20.31",
      "PB": "5.39",
      "Div": "1.27"
  },
  {
      "Date": "28-Apr-06",
      "PE": "20.08",
      "PB": "5.31",
      "Div": "1.29"
  },
  {
      "Date": "27-Apr-06",
      "PE": "20.58",
      "PB": "5.31",
      "Div": "1.29"
  },
  {
      "Date": "26-Apr-06",
      "PE": "20.93",
      "PB": "5.38",
      "Div": "1.27"
  },
  {
      "Date": "25-Apr-06",
      "PE": "20.37",
      "PB": "5.24",
      "Div": "1.3"
  },
  {
      "Date": "24-Apr-06",
      "PE": "20.85",
      "PB": "5.37",
      "Div": "1.27"
  },
  {
      "Date": "21-Apr-06",
      "PE": "21.01",
      "PB": "5.41",
      "Div": "1.26"
  },
  {
      "Date": "20-Apr-06",
      "PE": "21.01",
      "PB": "5.41",
      "Div": "1.26"
  },
  {
      "Date": "19-Apr-06",
      "PE": "20.79",
      "PB": "5.35",
      "Div": "1.28"
  },
  {
      "Date": "18-Apr-06",
      "PE": "20.76",
      "PB": "5.32",
      "Div": "1.28"
  },
  {
      "Date": "17-Apr-06",
      "PE": "20.34",
      "PB": "5.18",
      "Div": "1.32"
  },
  {
      "Date": "13-Apr-06",
      "PE": "19.91",
      "PB": "5.06",
      "Div": "1.35"
  },
  {
      "Date": "12-Apr-06",
      "PE": "20.13",
      "PB": "5.12",
      "Div": "1.34"
  },
  {
      "Date": "10-Apr-06",
      "PE": "20.71",
      "PB": "5.26",
      "Div": "1.3"
  },
  {
      "Date": "7-Apr-06",
      "PE": "20.57",
      "PB": "5.23",
      "Div": "1.31"
  },
  {
      "Date": "5-Apr-06",
      "PE": "20.9",
      "PB": "5.31",
      "Div": "1.29"
  },
  {
      "Date": "4-Apr-06",
      "PE": "20.74",
      "PB": "5.27",
      "Div": "1.3"
  },
  {
      "Date": "3-Apr-06",
      "PE": "20.68",
      "PB": "5.26",
      "Div": "1.3"
  },
  {
      "Date": "31-Mar-06",
      "PE": "20.26",
      "PB": "5.15",
      "Div": "1.33"
  },
  {
      "Date": "30-Mar-06",
      "PE": "20.35",
      "PB": "5.17",
      "Div": "1.32"
  },
  {
      "Date": "29-Mar-06",
      "PE": "19.97",
      "PB": "5.07",
      "Div": "1.35"
  },
  {
      "Date": "28-Mar-06",
      "PE": "19.79",
      "PB": "5.03",
      "Div": "1.36"
  },
  {
      "Date": "27-Mar-06",
      "PE": "19.77",
      "PB": "5.02",
      "Div": "1.36"
  },
  {
      "Date": "24-Mar-06",
      "PE": "19.52",
      "PB": "4.96",
      "Div": "1.38"
  },
  {
      "Date": "23-Mar-06",
      "PE": "19.33",
      "PB": "4.91",
      "Div": "1.39"
  },
  {
      "Date": "22-Mar-06",
      "PE": "19.28",
      "PB": "4.9",
      "Div": "1.39"
  },
  {
      "Date": "21-Mar-06",
      "PE": "19.42",
      "PB": "4.93",
      "Div": "1.38"
  },
  {
      "Date": "20-Mar-06",
      "PE": "19.44",
      "PB": "4.94",
      "Div": "1.38"
  },
  {
      "Date": "17-Mar-06",
      "PE": "19.25",
      "PB": "4.89",
      "Div": "1.4"
  },
  {
      "Date": "16-Mar-06",
      "PE": "19.18",
      "PB": "4.87",
      "Div": "1.4"
  },
  {
      "Date": "14-Mar-06",
      "PE": "19",
      "PB": "4.83",
      "Div": "1.42"
  },
  {
      "Date": "13-Mar-06",
      "PE": "19.04",
      "PB": "4.84",
      "Div": "1.41"
  },
  {
      "Date": "10-Mar-06",
      "PE": "18.93",
      "PB": "4.81",
      "Div": "1.42"
  },
  {
      "Date": "9-Mar-06",
      "PE": "18.6",
      "PB": "4.73",
      "Div": "1.45"
  },
  {
      "Date": "8-Mar-06",
      "PE": "18.53",
      "PB": "4.71",
      "Div": "1.45"
  },
  {
      "Date": "7-Mar-06",
      "PE": "18.92",
      "PB": "4.81",
      "Div": "1.42"
  },
  {
      "Date": "6-Mar-06",
      "PE": "18.96",
      "PB": "4.82",
      "Div": "1.42"
  },
  {
      "Date": "3-Mar-06",
      "PE": "18.71",
      "PB": "4.75",
      "Div": "1.44"
  },
  {
      "Date": "2-Mar-06",
      "PE": "18.73",
      "PB": "4.76",
      "Div": "1.44"
  },
  {
      "Date": "1-Mar-06",
      "PE": "18.56",
      "PB": "4.72",
      "Div": "1.45"
  },
  {
      "Date": "28-Feb-06",
      "PE": "18.27",
      "PB": "4.64",
      "Div": "1.47"
  },
  {
      "Date": "27-Feb-06",
      "PE": "18.22",
      "PB": "4.63",
      "Div": "1.48"
  },
  {
      "Date": "24-Feb-06",
      "PE": "18.12",
      "PB": "4.61",
      "Div": "1.48"
  },
  {
      "Date": "23-Feb-06",
      "PE": "18.19",
      "PB": "4.62",
      "Div": "1.48"
  },
  {
      "Date": "22-Feb-06",
      "PE": "18.13",
      "PB": "4.61",
      "Div": "1.48"
  },
  {
      "Date": "21-Feb-06",
      "PE": "18.03",
      "PB": "4.58",
      "Div": "1.49"
  },
  {
      "Date": "20-Feb-06",
      "PE": "17.86",
      "PB": "4.54",
      "Div": "1.51"
  },
  {
      "Date": "17-Feb-06",
      "PE": "17.71",
      "PB": "4.5",
      "Div": "1.52"
  },
  {
      "Date": "16-Feb-06",
      "PE": "17.95",
      "PB": "4.56",
      "Div": "1.5"
  },
  {
      "Date": "15-Feb-06",
      "PE": "17.96",
      "PB": "4.56",
      "Div": "1.5"
  },
  {
      "Date": "14-Feb-06",
      "PE": "17.93",
      "PB": "4.56",
      "Div": "1.5"
  },
  {
      "Date": "13-Feb-06",
      "PE": "18.11",
      "PB": "4.59",
      "Div": "1.49"
  },
  {
      "Date": "10-Feb-06",
      "PE": "18.03",
      "PB": "4.57",
      "Div": "1.49"
  },
  {
      "Date": "8-Feb-06",
      "PE": "17.95",
      "PB": "4.54",
      "Div": "1.5"
  },
  {
      "Date": "7-Feb-06",
      "PE": "18.02",
      "PB": "4.56",
      "Div": "1.5"
  },
  {
      "Date": "6-Feb-06",
      "PE": "17.9",
      "PB": "4.53",
      "Div": "1.51"
  },
  {
      "Date": "3-Feb-06",
      "PE": "17.54",
      "PB": "4.44",
      "Div": "1.54"
  },
  {
      "Date": "2-Feb-06",
      "PE": "17.7",
      "PB": "4.48",
      "Div": "1.53"
  },
  {
      "Date": "1-Feb-06",
      "PE": "17.73",
      "PB": "4.49",
      "Div": "1.52"
  },
  {
      "Date": "31-Jan-06",
      "PE": "17.9",
      "PB": "4.53",
      "Div": "1.51"
  },
  {
      "Date": "30-Jan-06",
      "PE": "17.91",
      "PB": "4.49",
      "Div": "1.52"
  },
  {
      "Date": "27-Jan-06",
      "PE": "17.42",
      "PB": "4.5",
      "Div": "1.52"
  },
  {
      "Date": "25-Jan-06",
      "PE": "17.02",
      "PB": "4.44",
      "Div": "1.54"
  },
  {
      "Date": "24-Jan-06",
      "PE": "16.86",
      "PB": "4.39",
      "Div": "1.56"
  },
  {
      "Date": "23-Jan-06",
      "PE": "16.83",
      "PB": "4.35",
      "Div": "1.57"
  },
  {
      "Date": "20-Jan-06",
      "PE": "16.91",
      "PB": "4.38",
      "Div": "1.56"
  },
  {
      "Date": "19-Jan-06",
      "PE": "16.87",
      "PB": "4.33",
      "Div": "1.58"
  },
  {
      "Date": "18-Jan-06",
      "PE": "16.53",
      "PB": "4.24",
      "Div": "1.61"
  },
  {
      "Date": "17-Jan-06",
      "PE": "17.12",
      "PB": "4.36",
      "Div": "1.57"
  },
  {
      "Date": "16-Jan-06",
      "PE": "17.14",
      "PB": "4.37",
      "Div": "1.56"
  },
  {
      "Date": "13-Jan-06",
      "PE": "17.25",
      "PB": "4.4",
      "Div": "1.55"
  },
  {
      "Date": "12-Jan-06",
      "PE": "17.28",
      "PB": "4.4",
      "Div": "1.55"
  },
  {
      "Date": "10-Jan-06",
      "PE": "17.37",
      "PB": "4.43",
      "Div": "1.54"
  },
  {
      "Date": "9-Jan-06",
      "PE": "17.62",
      "PB": "4.49",
      "Div": "1.52"
  },
  {
      "Date": "6-Jan-06",
      "PE": "17.64",
      "PB": "4.49",
      "Div": "1.52"
  },
  {
      "Date": "5-Jan-06",
      "PE": "17.55",
      "PB": "4.47",
      "Div": "1.53"
  },
  {
      "Date": "4-Jan-06",
      "PE": "17.58",
      "PB": "4.48",
      "Div": "1.53"
  },
  {
      "Date": "3-Jan-06",
      "PE": "17.45",
      "PB": "4.44",
      "Div": "1.54"
  },
  {
      "Date": "2-Jan-06",
      "PE": "17.16",
      "PB": "4.37",
      "Div": "1.56"
  },
  {
      "Date": "30-Dec-05",
      "PE": "17.16",
      "PB": "4.37",
      "Div": "1.56"
  },
  {
      "Date": "29-Dec-05",
      "PE": "17.07",
      "PB": "4.35",
      "Div": "1.57"
  },
  {
      "Date": "28-Dec-05",
      "PE": "16.91",
      "PB": "4.31",
      "Div": "1.59"
  },
  {
      "Date": "27-Dec-05",
      "PE": "16.98",
      "PB": "4.32",
      "Div": "1.58"
  },
  {
      "Date": "26-Dec-05",
      "PE": "16.64",
      "PB": "4.24",
      "Div": "1.61"
  },
  {
      "Date": "23-Dec-05",
      "PE": "16.97",
      "PB": "4.32",
      "Div": "1.58"
  },
  {
      "Date": "22-Dec-05",
      "PE": "17.15",
      "PB": "4.37",
      "Div": "1.56"
  },
  {
      "Date": "21-Dec-05",
      "PE": "17.08",
      "PB": "4.35",
      "Div": "1.57"
  },
  {
      "Date": "20-Dec-05",
      "PE": "17.1",
      "PB": "4.35",
      "Div": "1.57"
  },
  {
      "Date": "19-Dec-05",
      "PE": "17.2",
      "PB": "4.38",
      "Div": "1.56"
  },
  {
      "Date": "16-Dec-05",
      "PE": "16.9",
      "PB": "4.3",
      "Div": "1.59"
  },
  {
      "Date": "15-Dec-05",
      "PE": "16.71",
      "PB": "4.26",
      "Div": "1.61"
  },
  {
      "Date": "14-Dec-05",
      "PE": "16.87",
      "PB": "4.3",
      "Div": "1.59"
  },
  {
      "Date": "13-Dec-05",
      "PE": "16.91",
      "PB": "4.31",
      "Div": "1.59"
  },
  {
      "Date": "12-Dec-05",
      "PE": "16.7",
      "PB": "4.25",
      "Div": "1.61"
  },
  {
      "Date": "9-Dec-05",
      "PE": "16.58",
      "PB": "4.22",
      "Div": "1.62"
  },
  {
      "Date": "8-Dec-05",
      "PE": "16.28",
      "PB": "4.15",
      "Div": "1.65"
  },
  {
      "Date": "7-Dec-05",
      "PE": "16.2",
      "PB": "4.13",
      "Div": "1.66"
  },
  {
      "Date": "6-Dec-05",
      "PE": "16.01",
      "PB": "4.08",
      "Div": "1.68"
  },
  {
      "Date": "5-Dec-05",
      "PE": "16",
      "PB": "4.08",
      "Div": "1.68"
  },
  {
      "Date": "2-Dec-05",
      "PE": "16.22",
      "PB": "4.13",
      "Div": "1.65"
  },
  {
      "Date": "1-Dec-05",
      "PE": "16.23",
      "PB": "4.13",
      "Div": "1.65"
  },
  {
      "Date": "30-Nov-05",
      "PE": "15.95",
      "PB": "4.06",
      "Div": "1.68"
  },
  {
      "Date": "29-Nov-05",
      "PE": "16.22",
      "PB": "4.13",
      "Div": "1.65"
  },
  {
      "Date": "28-Nov-05",
      "PE": "16.31",
      "PB": "4.15",
      "Div": "1.64"
  },
  {
      "Date": "26-Nov-05",
      "PE": "16.13",
      "PB": "4.12",
      "Div": "1.65"
  },
  {
      "Date": "25-Nov-05",
      "PE": "16.02",
      "PB": "4.09",
      "Div": "1.66"
  },
  {
      "Date": "24-Nov-05",
      "PE": "15.84",
      "PB": "4.04",
      "Div": "1.68"
  },
  {
      "Date": "23-Nov-05",
      "PE": "15.68",
      "PB": "4",
      "Div": "1.69"
  },
  {
      "Date": "22-Nov-05",
      "PE": "15.47",
      "PB": "3.95",
      "Div": "1.72"
  },
  {
      "Date": "21-Nov-05",
      "PE": "15.65",
      "PB": "3.99",
      "Div": "1.7"
  },
  {
      "Date": "18-Nov-05",
      "PE": "15.73",
      "PB": "4.01",
      "Div": "1.69"
  },
  {
      "Date": "17-Nov-05",
      "PE": "15.63",
      "PB": "3.99",
      "Div": "1.7"
  },
  {
      "Date": "16-Nov-05",
      "PE": "15.5",
      "PB": "3.96",
      "Div": "1.71"
  },
  {
      "Date": "14-Nov-05",
      "PE": "15.36",
      "PB": "3.92",
      "Div": "1.73"
  },
  {
      "Date": "11-Nov-05",
      "PE": "15.29",
      "PB": "3.9",
      "Div": "1.74"
  },
  {
      "Date": "10-Nov-05",
      "PE": "15.01",
      "PB": "3.83",
      "Div": "1.77"
  },
  {
      "Date": "9-Nov-05",
      "PE": "14.94",
      "PB": "3.81",
      "Div": "1.78"
  },
  {
      "Date": "8-Nov-05",
      "PE": "14.96",
      "PB": "3.82",
      "Div": "1.78"
  },
  {
      "Date": "7-Nov-05",
      "PE": "14.77",
      "PB": "3.77",
      "Div": "1.8"
  },
  {
      "Date": "2-Nov-05",
      "PE": "14.52",
      "PB": "3.7",
      "Div": "1.83"
  },
  {
      "Date": "1-Nov-05",
      "PE": "14.32",
      "PB": "3.65",
      "Div": "1.85"
  },
  {
      "Date": "31-Oct-05",
      "PE": "14.23",
      "PB": "3.63",
      "Div": "1.87"
  },
  {
      "Date": "28-Oct-05",
      "PE": "13.74",
      "PB": "3.55",
      "Div": "1.91"
  },
  {
      "Date": "27-Oct-05",
      "PE": "14.01",
      "PB": "3.6",
      "Div": "1.88"
  },
  {
      "Date": "26-Oct-05",
      "PE": "14.58",
      "PB": "3.69",
      "Div": "1.84"
  },
  {
      "Date": "25-Oct-05",
      "PE": "14.81",
      "PB": "3.7",
      "Div": "1.83"
  },
  {
      "Date": "24-Oct-05",
      "PE": "14.72",
      "PB": "3.67",
      "Div": "1.85"
  },
  {
      "Date": "21-Oct-05",
      "PE": "15.02",
      "PB": "3.74",
      "Div": "1.81"
  },
  {
      "Date": "20-Oct-05",
      "PE": "14.69",
      "PB": "3.67",
      "Div": "1.85"
  },
  {
      "Date": "19-Oct-05",
      "PE": "14.81",
      "PB": "3.69",
      "Div": "1.83"
  },
  {
      "Date": "18-Oct-05",
      "PE": "15.17",
      "PB": "3.78",
      "Div": "1.79"
  },
  {
      "Date": "17-Oct-05",
      "PE": "15.27",
      "PB": "3.8",
      "Div": "1.78"
  },
  {
      "Date": "14-Oct-05",
      "PE": "15.3",
      "PB": "3.83",
      "Div": "1.78"
  },
  {
      "Date": "13-Oct-05",
      "PE": "15.67",
      "PB": "3.91",
      "Div": "1.74"
  },
  {
      "Date": "11-Oct-05",
      "PE": "16",
      "PB": "3.99",
      "Div": "1.71"
  },
  {
      "Date": "10-Oct-05",
      "PE": "15.94",
      "PB": "3.96",
      "Div": "1.72"
  },
  {
      "Date": "7-Oct-05",
      "PE": "15.99",
      "PB": "3.97",
      "Div": "1.72"
  },
  {
      "Date": "6-Oct-05",
      "PE": "16.02",
      "PB": "3.98",
      "Div": "1.71"
  },
  {
      "Date": "5-Oct-05",
      "PE": "16.42",
      "PB": "4.08",
      "Div": "1.67"
  },
  {
      "Date": "4-Oct-05",
      "PE": "16.54",
      "PB": "4.11",
      "Div": "1.66"
  },
  {
      "Date": "3-Oct-05",
      "PE": "16.33",
      "PB": "4.06",
      "Div": "1.68"
  },
  {
      "Date": "30-Sep-05",
      "PE": "16.15",
      "PB": "4.01",
      "Div": "1.7"
  },
  {
      "Date": "29-Sep-05",
      "PE": "16.21",
      "PB": "4.03",
      "Div": "1.69"
  },
  {
      "Date": "28-Sep-05",
      "PE": "16.13",
      "PB": "4",
      "Div": "1.7"
  },
  {
      "Date": "27-Sep-05",
      "PE": "15.99",
      "PB": "3.97",
      "Div": "1.72"
  },
  {
      "Date": "26-Sep-05",
      "PE": "15.88",
      "PB": "3.94",
      "Div": "1.73"
  },
  {
      "Date": "23-Sep-05",
      "PE": "15.37",
      "PB": "3.93",
      "Div": "1.63"
  },
  {
      "Date": "22-Sep-05",
      "PE": "15.36",
      "PB": "3.93",
      "Div": "1.63"
  },
  {
      "Date": "21-Sep-05",
      "PE": "15.93",
      "PB": "4.07",
      "Div": "1.57"
  },
  {
      "Date": "20-Sep-05",
      "PE": "15.99",
      "PB": "4.09",
      "Div": "1.56"
  },
  {
      "Date": "19-Sep-05",
      "PE": "15.92",
      "PB": "4.07",
      "Div": "1.57"
  },
  {
      "Date": "16-Sep-05",
      "PE": "15.83",
      "PB": "4.14",
      "Div": "1.47"
  },
  {
      "Date": "15-Sep-05",
      "PE": "15.66",
      "PB": "4.09",
      "Div": "1.49"
  },
  {
      "Date": "14-Sep-05",
      "PE": "15.46",
      "PB": "4.04",
      "Div": "1.51"
  },
  {
      "Date": "13-Sep-05",
      "PE": "15.51",
      "PB": "4.05",
      "Div": "1.5"
  },
  {
      "Date": "12-Sep-05",
      "PE": "15.41",
      "PB": "4.03",
      "Div": "1.51"
  },
  {
      "Date": "9-Sep-05",
      "PE": "15.23",
      "PB": "3.98",
      "Div": "1.53"
  },
  {
      "Date": "8-Sep-05",
      "PE": "15.22",
      "PB": "3.98",
      "Div": "1.53"
  },
  {
      "Date": "6-Sep-05",
      "PE": "15.06",
      "PB": "3.94",
      "Div": "1.55"
  },
  {
      "Date": "5-Sep-05",
      "PE": "15.02",
      "PB": "3.93",
      "Div": "1.55"
  },
  {
      "Date": "2-Sep-05",
      "PE": "14.98",
      "PB": "3.94",
      "Div": "1.57"
  },
  {
      "Date": "1-Sep-05",
      "PE": "14.92",
      "PB": "3.92",
      "Div": "1.58"
  },
  {
      "Date": "31-Aug-05",
      "PE": "14.78",
      "PB": "3.89",
      "Div": "1.59"
  },
  {
      "Date": "30-Aug-05",
      "PE": "14.67",
      "PB": "3.86",
      "Div": "1.61"
  },
  {
      "Date": "29-Aug-05",
      "PE": "14.48",
      "PB": "3.81",
      "Div": "1.63"
  },
  {
      "Date": "26-Aug-05",
      "PE": "14.6",
      "PB": "3.84",
      "Div": "1.61"
  },
  {
      "Date": "25-Aug-05",
      "PE": "14.58",
      "PB": "3.84",
      "Div": "1.62"
  },
  {
      "Date": "24-Aug-05",
      "PE": "14.39",
      "PB": "3.78",
      "Div": "1.64"
  },
  {
      "Date": "23-Aug-05",
      "PE": "14.41",
      "PB": "3.79",
      "Div": "1.64"
  },
  {
      "Date": "22-Aug-05",
      "PE": "14.67",
      "PB": "3.86",
      "Div": "1.61"
  },
  {
      "Date": "19-Aug-05",
      "PE": "14.77",
      "PB": "3.89",
      "Div": "1.59"
  },
  {
      "Date": "18-Aug-05",
      "PE": "14.8",
      "PB": "3.89",
      "Div": "1.59"
  },
  {
      "Date": "17-Aug-05",
      "PE": "14.89",
      "PB": "3.92",
      "Div": "1.58"
  },
  {
      "Date": "16-Aug-05",
      "PE": "14.68",
      "PB": "3.95",
      "Div": "1.58"
  },
  {
      "Date": "12-Aug-05",
      "PE": "14.63",
      "PB": "3.93",
      "Div": "1.59"
  },
  {
      "Date": "11-Aug-05",
      "PE": "14.75",
      "PB": "3.96",
      "Div": "1.57"
  },
  {
      "Date": "10-Aug-05",
      "PE": "14.62",
      "PB": "3.93",
      "Div": "1.59"
  },
  {
      "Date": "9-Aug-05",
      "PE": "14.36",
      "PB": "3.86",
      "Div": "1.62"
  },
  {
      "Date": "8-Aug-05",
      "PE": "14.4",
      "PB": "3.87",
      "Div": "1.61"
  },
  {
      "Date": "5-Aug-05",
      "PE": "14.63",
      "PB": "3.94",
      "Div": "1.58"
  },
  {
      "Date": "4-Aug-05",
      "PE": "14.67",
      "PB": "3.95",
      "Div": "1.58"
  },
  {
      "Date": "3-Aug-05",
      "PE": "14.6",
      "PB": "3.93",
      "Div": "1.59"
  },
  {
      "Date": "2-Aug-05",
      "PE": "14.58",
      "PB": "3.92",
      "Div": "1.59"
  },
  {
      "Date": "1-Aug-05",
      "PE": "14.36",
      "PB": "3.87",
      "Div": "1.61"
  },
  {
      "Date": "29-Jul-05",
      "PE": "14.1",
      "PB": "3.86",
      "Div": "1.62"
  },
  {
      "Date": "27-Jul-05",
      "PE": "14.69",
      "PB": "3.87",
      "Div": "1.61"
  },
  {
      "Date": "26-Jul-05",
      "PE": "14.58",
      "PB": "3.84",
      "Div": "1.62"
  },
  {
      "Date": "25-Jul-05",
      "PE": "14.62",
      "PB": "3.82",
      "Div": "1.63"
  },
  {
      "Date": "22-Jul-05",
      "PE": "14.47",
      "PB": "3.91",
      "Div": "1.62"
  },
  {
      "Date": "21-Jul-05",
      "PE": "14.27",
      "PB": "3.85",
      "Div": "1.65"
  },
  {
      "Date": "20-Jul-05",
      "PE": "14.36",
      "PB": "3.87",
      "Div": "1.64"
  },
  {
      "Date": "19-Jul-05",
      "PE": "14.33",
      "PB": "3.87",
      "Div": "1.64"
  },
  {
      "Date": "18-Jul-05",
      "PE": "14.31",
      "PB": "3.86",
      "Div": "1.64"
  },
  {
      "Date": "15-Jul-05",
      "PE": "14.21",
      "PB": "3.83",
      "Div": "1.65"
  },
  {
      "Date": "14-Jul-05",
      "PE": "14.04",
      "PB": "3.78",
      "Div": "1.68"
  },
  {
      "Date": "13-Jul-05",
      "PE": "14.17",
      "PB": "3.81",
      "Div": "1.66"
  },
  {
      "Date": "12-Jul-05",
      "PE": "14.29",
      "PB": "3.84",
      "Div": "1.65"
  },
  {
      "Date": "11-Jul-05",
      "PE": "14.3",
      "PB": "3.84",
      "Div": "1.65"
  },
  {
      "Date": "8-Jul-05",
      "PE": "14.16",
      "PB": "3.85",
      "Div": "1.66"
  },
  {
      "Date": "7-Jul-05",
      "PE": "14.05",
      "PB": "3.82",
      "Div": "1.67"
  },
  {
      "Date": "6-Jul-05",
      "PE": "14.36",
      "PB": "3.91",
      "Div": "1.63"
  },
  {
      "Date": "5-Jul-05",
      "PE": "14.25",
      "PB": "3.88",
      "Div": "1.64"
  },
  {
      "Date": "4-Jul-05",
      "PE": "14.38",
      "PB": "3.91",
      "Div": "1.63"
  },
  {
      "Date": "1-Jul-05",
      "PE": "14.26",
      "PB": "3.96",
      "Div": "1.56"
  },
  {
      "Date": "30-Jun-05",
      "PE": "14.31",
      "PB": "3.98",
      "Div": "1.55"
  },
  {
      "Date": "29-Jun-05",
      "PE": "14.13",
      "PB": "3.93",
      "Div": "1.57"
  },
  {
      "Date": "28-Jun-05",
      "PE": "13.99",
      "PB": "3.89",
      "Div": "1.59"
  },
  {
      "Date": "27-Jun-05",
      "PE": "14.18",
      "PB": "3.94",
      "Div": "1.57"
  },
  {
      "Date": "24-Jun-05",
      "PE": "14.14",
      "PB": "3.96",
      "Div": "1.54"
  },
  {
      "Date": "23-Jun-05",
      "PE": "14.08",
      "PB": "3.94",
      "Div": "1.55"
  },
  {
      "Date": "22-Jun-05",
      "PE": "14.1",
      "PB": "3.95",
      "Div": "1.55"
  },
  {
      "Date": "21-Jun-05",
      "PE": "13.99",
      "PB": "3.92",
      "Div": "1.56"
  },
  {
      "Date": "20-Jun-05",
      "PE": "13.82",
      "PB": "3.87",
      "Div": "1.58"
  },
  {
      "Date": "17-Jun-05",
      "PE": "14.04",
      "PB": "3.83",
      "Div": "1.59"
  },
  {
      "Date": "16-Jun-05",
      "PE": "14.04",
      "PB": "3.83",
      "Div": "1.59"
  },
  {
      "Date": "15-Jun-05",
      "PE": "14.07",
      "PB": "3.84",
      "Div": "1.59"
  },
  {
      "Date": "14-Jun-05",
      "PE": "13.96",
      "PB": "3.81",
      "Div": "1.6"
  },
  {
      "Date": "13-Jun-05",
      "PE": "13.9",
      "PB": "3.79",
      "Div": "1.61"
  },
  {
      "Date": "10-Jun-05",
      "PE": "13.89",
      "PB": "3.92",
      "Div": "1.65"
  },
  {
      "Date": "9-Jun-05",
      "PE": "13.97",
      "PB": "3.94",
      "Div": "1.64"
  },
  {
      "Date": "8-Jun-05",
      "PE": "14.09",
      "PB": "3.96",
      "Div": "1.64"
  },
  {
      "Date": "7-Jun-05",
      "PE": "14",
      "PB": "3.93",
      "Div": "1.65"
  },
  {
      "Date": "6-Jun-05",
      "PE": "13.96",
      "PB": "3.92",
      "Div": "1.65"
  },
  {
      "Date": "4-Jun-05",
      "PE": "13.96",
      "PB": "3.92",
      "Div": "1.97"
  },
  {
      "Date": "3-Jun-05",
      "PE": "13.97",
      "PB": "3.93",
      "Div": "1.96"
  },
  {
      "Date": "2-Jun-05",
      "PE": "13.77",
      "PB": "3.87",
      "Div": "1.99"
  },
  {
      "Date": "1-Jun-05",
      "PE": "13.93",
      "PB": "3.91",
      "Div": "1.97"
  },
  {
      "Date": "31-May-05",
      "PE": "13.94",
      "PB": "3.91",
      "Div": "1.97"
  },
  {
      "Date": "30-May-05",
      "PE": "13.86",
      "PB": "3.88",
      "Div": "1.99"
  },
  {
      "Date": "27-May-05",
      "PE": "13.97",
      "PB": "3.89",
      "Div": "1.98"
  },
  {
      "Date": "26-May-05",
      "PE": "13.95",
      "PB": "3.89",
      "Div": "1.98"
  },
  {
      "Date": "25-May-05",
      "PE": "13.75",
      "PB": "3.83",
      "Div": "2.01"
  },
  {
      "Date": "24-May-05",
      "PE": "13.98",
      "PB": "3.8",
      "Div": "2.02"
  },
  {
      "Date": "23-May-05",
      "PE": "13.88",
      "PB": "3.77",
      "Div": "2.04"
  },
  {
      "Date": "20-May-05",
      "PE": "13.73",
      "PB": "3.73",
      "Div": "2.06"
  },
  {
      "Date": "19-May-05",
      "PE": "13.74",
      "PB": "3.73",
      "Div": "2.06"
  },
  {
      "Date": "18-May-05",
      "PE": "13.74",
      "PB": "3.72",
      "Div": "2.07"
  },
  {
      "Date": "17-May-05",
      "PE": "13.8",
      "PB": "3.73",
      "Div": "2.06"
  },
  {
      "Date": "16-May-05",
      "PE": "13.97",
      "PB": "3.77",
      "Div": "2.04"
  },
  {
      "Date": "13-May-05",
      "PE": "13.78",
      "PB": "3.72",
      "Div": "2.03"
  },
  {
      "Date": "12-May-05",
      "PE": "13.81",
      "PB": "3.73",
      "Div": "2.03"
  },
  {
      "Date": "11-May-05",
      "PE": "13.76",
      "PB": "3.72",
      "Div": "2.03"
  },
  {
      "Date": "10-May-05",
      "PE": "13.82",
      "PB": "3.73",
      "Div": "2.02"
  },
  {
      "Date": "9-May-05",
      "PE": "13.87",
      "PB": "3.74",
      "Div": "2.02"
  },
  {
      "Date": "6-May-05",
      "PE": "13.71",
      "PB": "3.7",
      "Div": "2.04"
  },
  {
      "Date": "5-May-05",
      "PE": "13.64",
      "PB": "3.67",
      "Div": "2.06"
  },
  {
      "Date": "4-May-05",
      "PE": "13.5",
      "PB": "3.63",
      "Div": "2.08"
  },
  {
      "Date": "3-May-05",
      "PE": "13.35",
      "PB": "3.59",
      "Div": "2.1"
  },
  {
      "Date": "2-May-05",
      "PE": "13.32",
      "PB": "3.59",
      "Div": "2.11"
  },
  {
      "Date": "29-Apr-05",
      "PE": "13.27",
      "PB": "3.56",
      "Div": "2.11"
  },
  {
      "Date": "28-Apr-05",
      "PE": "13.55",
      "PB": "3.63",
      "Div": "2.07"
  },
  {
      "Date": "27-Apr-05",
      "PE": "13.51",
      "PB": "3.62",
      "Div": "2.07"
  },
  {
      "Date": "26-Apr-05",
      "PE": "13.85",
      "PB": "3.66",
      "Div": "2.05"
  },
  {
      "Date": "25-Apr-05",
      "PE": "13.99",
      "PB": "3.69",
      "Div": "2.03"
  },
  {
      "Date": "22-Apr-05",
      "PE": "13.97",
      "PB": "3.68",
      "Div": "2.04"
  },
  {
      "Date": "21-Apr-05",
      "PE": "13.87",
      "PB": "3.65",
      "Div": "2.06"
  },
  {
      "Date": "20-Apr-05",
      "PE": "13.78",
      "PB": "3.61",
      "Div": "2.08"
  },
  {
      "Date": "19-Apr-05",
      "PE": "13.64",
      "PB": "3.57",
      "Div": "2.1"
  },
  {
      "Date": "18-Apr-05",
      "PE": "13.75",
      "PB": "3.61",
      "Div": "2.08"
  },
  {
      "Date": "15-Apr-05",
      "PE": "13.95",
      "PB": "3.66",
      "Div": "2.05"
  },
  {
      "Date": "13-Apr-05",
      "PE": "14.52",
      "PB": "3.79",
      "Div": "1.98"
  },
  {
      "Date": "12-Apr-05",
      "PE": "14.65",
      "PB": "3.82",
      "Div": "1.96"
  },
  {
      "Date": "11-Apr-05",
      "PE": "14.39",
      "PB": "3.76",
      "Div": "2"
  },
  {
      "Date": "8-Apr-05",
      "PE": "14.56",
      "PB": "3.8",
      "Div": "1.97"
  },
  {
      "Date": "7-Apr-05",
      "PE": "14.71",
      "PB": "3.84",
      "Div": "1.95"
  },
  {
      "Date": "6-Apr-05",
      "PE": "14.83",
      "PB": "3.87",
      "Div": "1.94"
  },
  {
      "Date": "5-Apr-05",
      "PE": "14.71",
      "PB": "3.84",
      "Div": "1.95"
  },
  {
      "Date": "4-Apr-05",
      "PE": "14.81",
      "PB": "3.86",
      "Div": "1.94"
  },
  {
      "Date": "1-Apr-05",
      "PE": "14.84",
      "PB": "3.88",
      "Div": "1.94"
  },
  {
      "Date": "31-Mar-05",
      "PE": "14.6",
      "PB": "3.82",
      "Div": "1.97"
  },
  {
      "Date": "30-Mar-05",
      "PE": "14.3",
      "PB": "3.74",
      "Div": "2.01"
  },
  {
      "Date": "29-Mar-05",
      "PE": "14.21",
      "PB": "3.71",
      "Div": "2.02"
  },
  {
      "Date": "28-Mar-05",
      "PE": "14.54",
      "PB": "3.8",
      "Div": "1.98"
  },
  {
      "Date": "24-Mar-05",
      "PE": "14.39",
      "PB": "3.76",
      "Div": "2"
  },
  {
      "Date": "23-Mar-05",
      "PE": "14.47",
      "PB": "3.78",
      "Div": "1.99"
  },
  {
      "Date": "22-Mar-05",
      "PE": "14.72",
      "PB": "3.85",
      "Div": "1.95"
  },
  {
      "Date": "21-Mar-05",
      "PE": "14.97",
      "PB": "3.91",
      "Div": "1.92"
  },
  {
      "Date": "18-Mar-05",
      "PE": "15.06",
      "PB": "3.93",
      "Div": "1.9"
  },
  {
      "Date": "17-Mar-05",
      "PE": "14.98",
      "PB": "3.91",
      "Div": "1.91"
  },
  {
      "Date": "16-Mar-05",
      "PE": "15.17",
      "PB": "3.96",
      "Div": "1.88"
  },
  {
      "Date": "15-Mar-05",
      "PE": "15.2",
      "PB": "3.97",
      "Div": "1.88"
  },
  {
      "Date": "14-Mar-05",
      "PE": "15.32",
      "PB": "4",
      "Div": "1.86"
  },
  {
      "Date": "11-Mar-05",
      "PE": "15.38",
      "PB": "4.02",
      "Div": "1.86"
  },
  {
      "Date": "10-Mar-05",
      "PE": "15.47",
      "PB": "4.04",
      "Div": "1.84"
  },
  {
      "Date": "9-Mar-05",
      "PE": "15.43",
      "PB": "4.03",
      "Div": "1.85"
  },
  {
      "Date": "8-Mar-05",
      "PE": "15.48",
      "PB": "4.05",
      "Div": "1.84"
  },
  {
      "Date": "7-Mar-05",
      "PE": "15.42",
      "PB": "4.03",
      "Div": "1.85"
  },
  {
      "Date": "4-Mar-05",
      "PE": "15.34",
      "PB": "4.01",
      "Div": "1.86"
  },
  {
      "Date": "3-Mar-05",
      "PE": "15.2",
      "PB": "3.97",
      "Div": "1.88"
  },
  {
      "Date": "2-Mar-05",
      "PE": "14.94",
      "PB": "3.9",
      "Div": "1.91"
  },
  {
      "Date": "1-Mar-05",
      "PE": "14.88",
      "PB": "3.89",
      "Div": "1.92"
  },
  {
      "Date": "28-Feb-05",
      "PE": "15.02",
      "PB": "3.92",
      "Div": "1.9"
  },
  {
      "Date": "25-Feb-05",
      "PE": "14.71",
      "PB": "3.84",
      "Div": "1.93"
  },
  {
      "Date": "24-Feb-05",
      "PE": "14.29",
      "PB": "3.57",
      "Div": "2.04"
  },
  {
      "Date": "23-Feb-05",
      "PE": "14.3",
      "PB": "3.57",
      "Div": "2.03"
  },
  {
      "Date": "22-Feb-05",
      "PE": "14.31",
      "PB": "3.57",
      "Div": "2.03"
  },
  {
      "Date": "21-Feb-05",
      "PE": "14.21",
      "PB": "3.55",
      "Div": "2.05"
  },
  {
      "Date": "18-Feb-05",
      "PE": "14.29",
      "PB": "3.57",
      "Div": "2.04"
  },
  {
      "Date": "17-Feb-05",
      "PE": "14.34",
      "PB": "3.58",
      "Div": "2.03"
  },
  {
      "Date": "16-Feb-05",
      "PE": "14.39",
      "PB": "3.59",
      "Div": "2.02"
  },
  {
      "Date": "15-Feb-05",
      "PE": "14.53",
      "PB": "3.63",
      "Div": "2"
  },
  {
      "Date": "14-Feb-05",
      "PE": "14.59",
      "PB": "3.64",
      "Div": "1.99"
  },
  {
      "Date": "11-Feb-05",
      "PE": "14.48",
      "PB": "3.61",
      "Div": "2"
  },
  {
      "Date": "10-Feb-05",
      "PE": "14.31",
      "PB": "3.58",
      "Div": "2.01"
  },
  {
      "Date": "9-Feb-05",
      "PE": "14.36",
      "PB": "3.59",
      "Div": "2.01"
  },
  {
      "Date": "8-Feb-05",
      "PE": "14.26",
      "PB": "3.57",
      "Div": "2.02"
  },
  {
      "Date": "7-Feb-05",
      "PE": "14.26",
      "PB": "3.57",
      "Div": "2.02"
  },
  {
      "Date": "4-Feb-05",
      "PE": "14.41",
      "PB": "3.61",
      "Div": "1.92"
  },
  {
      "Date": "3-Feb-05",
      "PE": "14.42",
      "PB": "3.61",
      "Div": "1.92"
  },
  {
      "Date": "2-Feb-05",
      "PE": "14.24",
      "PB": "3.56",
      "Div": "1.95"
  },
  {
      "Date": "1-Feb-05",
      "PE": "14.34",
      "PB": "3.58",
      "Div": "1.94"
  },
  {
      "Date": "31-Jan-05",
      "PE": "14.68",
      "PB": "3.57",
      "Div": "1.94"
  },
  {
      "Date": "28-Jan-05",
      "PE": "14.34",
      "PB": "3.49",
      "Div": "1.99"
  },
  {
      "Date": "27-Jan-05",
      "PE": "13.84",
      "PB": "3.39",
      "Div": "2.04"
  },
  {
      "Date": "25-Jan-05",
      "PE": "13.74",
      "PB": "3.35",
      "Div": "2.07"
  },
  {
      "Date": "24-Jan-05",
      "PE": "13.6",
      "PB": "3.31",
      "Div": "2.09"
  },
  {
      "Date": "20-Jan-05",
      "PE": "14.09",
      "PB": "3.34",
      "Div": "2.08"
  },
  {
      "Date": "19-Jan-05",
      "PE": "14.12",
      "PB": "3.34",
      "Div": "2.07"
  },
  {
      "Date": "18-Jan-05",
      "PE": "14.19",
      "PB": "3.36",
      "Div": "2.07"
  },
  {
      "Date": "17-Jan-05",
      "PE": "14.17",
      "PB": "3.35",
      "Div": "2.07"
  },
  {
      "Date": "14-Jan-05",
      "PE": "14.18",
      "PB": "3.35",
      "Div": "2.07"
  },
  {
      "Date": "13-Jan-05",
      "PE": "14.35",
      "PB": "3.39",
      "Div": "2.05"
  },
  {
      "Date": "12-Jan-05",
      "PE": "14.05",
      "PB": "3.32",
      "Div": "2.09"
  },
  {
      "Date": "11-Jan-05",
      "PE": "14.37",
      "PB": "3.39",
      "Div": "2.05"
  },
  {
      "Date": "10-Jan-05",
      "PE": "14.58",
      "PB": "3.44",
      "Div": "2.02"
  },
  {
      "Date": "7-Jan-05",
      "PE": "14.84",
      "PB": "3.49",
      "Div": "1.98"
  },
  {
      "Date": "6-Jan-05",
      "PE": "14.71",
      "PB": "3.47",
      "Div": "2"
  },
  {
      "Date": "5-Jan-05",
      "PE": "14.96",
      "PB": "3.52",
      "Div": "1.97"
  },
  {
      "Date": "4-Jan-05",
      "PE": "15.49",
      "PB": "3.65",
      "Div": "1.9"
  },
  {
      "Date": "3-Jan-05",
      "PE": "15.57",
      "PB": "3.67",
      "Div": "1.89"
  },
  {
      "Date": "31-Dec-04",
      "PE": "15.32",
      "PB": "3.61",
      "Div": "1.83"
  },
  {
      "Date": "30-Dec-04",
      "PE": "15.17",
      "PB": "3.57",
      "Div": "1.85"
  },
  {
      "Date": "29-Dec-04",
      "PE": "15.24",
      "PB": "3.59",
      "Div": "1.84"
  },
  {
      "Date": "28-Dec-04",
      "PE": "15.25",
      "PB": "3.59",
      "Div": "1.84"
  },
  {
      "Date": "27-Dec-04",
      "PE": "15.19",
      "PB": "3.58",
      "Div": "1.84"
  },
  {
      "Date": "24-Dec-04",
      "PE": "15.19",
      "PB": "3.58",
      "Div": "1.84"
  },
  {
      "Date": "23-Dec-04",
      "PE": "15.06",
      "PB": "3.55",
      "Div": "1.85"
  },
  {
      "Date": "22-Dec-04",
      "PE": "14.98",
      "PB": "3.53",
      "Div": "1.86"
  },
  {
      "Date": "21-Dec-04",
      "PE": "15.05",
      "PB": "3.54",
      "Div": "1.85"
  },
  {
      "Date": "20-Dec-04",
      "PE": "14.92",
      "PB": "3.51",
      "Div": "1.87"
  },
  {
      "Date": "17-Dec-04",
      "PE": "16.91",
      "PB": "3.48",
      "Div": "1.89"
  },
  {
      "Date": "16-Dec-04",
      "PE": "17.08",
      "PB": "3.52",
      "Div": "1.87"
  },
  {
      "Date": "15-Dec-04",
      "PE": "17.04",
      "PB": "3.51",
      "Div": "1.87"
  },
  {
      "Date": "14-Dec-04",
      "PE": "16.86",
      "PB": "3.47",
      "Div": "1.89"
  },
  {
      "Date": "13-Dec-04",
      "PE": "16.68",
      "PB": "3.44",
      "Div": "1.91"
  },
  {
      "Date": "10-Dec-04",
      "PE": "16.54",
      "PB": "3.41",
      "Div": "1.92"
  },
  {
      "Date": "9-Dec-04",
      "PE": "16.66",
      "PB": "3.44",
      "Div": "1.88"
  },
  {
      "Date": "8-Dec-04",
      "PE": "16.56",
      "PB": "3.42",
      "Div": "1.9"
  },
  {
      "Date": "7-Dec-04",
      "PE": "16.68",
      "PB": "3.44",
      "Div": "1.88"
  },
  {
      "Date": "6-Dec-04",
      "PE": "16.68",
      "PB": "3.44",
      "Div": "1.88"
  },
  {
      "Date": "3-Dec-04",
      "PE": "16.71",
      "PB": "3.45",
      "Div": "1.88"
  },
  {
      "Date": "2-Dec-04",
      "PE": "16.73",
      "PB": "3.45",
      "Div": "1.88"
  },
  {
      "Date": "1-Dec-04",
      "PE": "16.42",
      "PB": "3.39",
      "Div": "1.91"
  },
  {
      "Date": "30-Nov-04",
      "PE": "16.39",
      "PB": "3.38",
      "Div": "1.92"
  },
  {
      "Date": "29-Nov-04",
      "PE": "16.23",
      "PB": "3.35",
      "Div": "1.93"
  },
  {
      "Date": "25-Nov-04",
      "PE": "15.91",
      "PB": "3.28",
      "Div": "1.97"
  },
  {
      "Date": "24-Nov-04",
      "PE": "15.93",
      "PB": "3.29",
      "Div": "1.97"
  },
  {
      "Date": "23-Nov-04",
      "PE": "15.84",
      "PB": "3.27",
      "Div": "1.98"
  },
  {
      "Date": "22-Nov-04",
      "PE": "15.66",
      "PB": "3.23",
      "Div": "2.01"
  },
  {
      "Date": "19-Nov-04",
      "PE": "15.65",
      "PB": "3.23",
      "Div": "2.01"
  },
  {
      "Date": "18-Nov-04",
      "PE": "15.82",
      "PB": "3.26",
      "Div": "1.99"
  },
  {
      "Date": "17-Nov-04",
      "PE": "15.79",
      "PB": "3.26",
      "Div": "1.99"
  },
  {
      "Date": "16-Nov-04",
      "PE": "15.71",
      "PB": "3.24",
      "Div": "2"
  },
  {
      "Date": "12-Nov-04",
      "PE": "15.66",
      "PB": "3.23",
      "Div": "2.01"
  },
  {
      "Date": "11-Nov-04",
      "PE": "15.64",
      "PB": "3.23",
      "Div": "2.01"
  },
  {
      "Date": "10-Nov-04",
      "PE": "15.68",
      "PB": "3.24",
      "Div": "2"
  },
  {
      "Date": "9-Nov-04",
      "PE": "15.54",
      "PB": "3.21",
      "Div": "2.02"
  },
  {
      "Date": "8-Nov-04",
      "PE": "15.57",
      "PB": "3.21",
      "Div": "2.02"
  },
  {
      "Date": "5-Nov-04",
      "PE": "15.48",
      "PB": "3.2",
      "Div": "2.07"
  },
  {
      "Date": "4-Nov-04",
      "PE": "15.34",
      "PB": "3.17",
      "Div": "2.09"
  },
  {
      "Date": "3-Nov-04",
      "PE": "15.36",
      "PB": "3.17",
      "Div": "2.09"
  },
  {
      "Date": "2-Nov-04",
      "PE": "15.16",
      "PB": "3.13",
      "Div": "2.11"
  },
  {
      "Date": "1-Nov-04",
      "PE": "15.03",
      "PB": "3.1",
      "Div": "2.13"
  },
  {
      "Date": "29-Oct-04",
      "PE": "14.93",
      "PB": "3.08",
      "Div": "2.14"
  },
  {
      "Date": "28-Oct-04",
      "PE": "15.04",
      "PB": "3.1",
      "Div": "2.12"
  },
  {
      "Date": "27-Oct-04",
      "PE": "14.91",
      "PB": "3.08",
      "Div": "2.14"
  },
  {
      "Date": "26-Oct-04",
      "PE": "14.89",
      "PB": "3.07",
      "Div": "2.14"
  },
  {
      "Date": "25-Oct-04",
      "PE": "14.69",
      "PB": "3.03",
      "Div": "2.17"
  },
  {
      "Date": "21-Oct-04",
      "PE": "14.87",
      "PB": "3.07",
      "Div": "2.14"
  },
  {
      "Date": "20-Oct-04",
      "PE": "14.96",
      "PB": "3.09",
      "Div": "2.13"
  },
  {
      "Date": "19-Oct-04",
      "PE": "15.11",
      "PB": "3.12",
      "Div": "2.11"
  },
  {
      "Date": "18-Oct-04",
      "PE": "14.93",
      "PB": "3.08",
      "Div": "2.13"
  },
  {
      "Date": "15-Oct-04",
      "PE": "15",
      "PB": "3.1",
      "Div": "2.12"
  },
  {
      "Date": "14-Oct-04",
      "PE": "15",
      "PB": "3.1",
      "Div": "2.12"
  },
  {
      "Date": "12-Oct-04",
      "PE": "14.93",
      "PB": "3.08",
      "Div": "2.13"
  },
  {
      "Date": "11-Oct-04",
      "PE": "15.11",
      "PB": "3.12",
      "Div": "2.11"
  },
  {
      "Date": "9-Oct-04",
      "PE": "15.19",
      "PB": "3.14",
      "Div": "2.1"
  },
  {
      "Date": "8-Oct-04",
      "PE": "15.21",
      "PB": "3.14",
      "Div": "2.09"
  },
  {
      "Date": "7-Oct-04",
      "PE": "15.17",
      "PB": "3.13",
      "Div": "2.1"
  },
  {
      "Date": "6-Oct-04",
      "PE": "15",
      "PB": "3.1",
      "Div": "2.12"
  },
  {
      "Date": "5-Oct-04",
      "PE": "15.15",
      "PB": "3.13",
      "Div": "2.1"
  },
  {
      "Date": "4-Oct-04",
      "PE": "15.09",
      "PB": "3.11",
      "Div": "2.11"
  },
  {
      "Date": "1-Oct-04",
      "PE": "14.84",
      "PB": "3.21",
      "Div": "2.15"
  },
  {
      "Date": "30-Sep-04",
      "PE": "14.59",
      "PB": "3.16",
      "Div": "2.18"
  },
  {
      "Date": "29-Sep-04",
      "PE": "14.44",
      "PB": "3.12",
      "Div": "2.21"
  },
  {
      "Date": "28-Sep-04",
      "PE": "14.21",
      "PB": "3.07",
      "Div": "2.24"
  },
  {
      "Date": "27-Sep-04",
      "PE": "14.35",
      "PB": "3.1",
      "Div": "2.22"
  },
  {
      "Date": "24-Sep-04",
      "PE": "14.39",
      "PB": "3.11",
      "Div": "2.23"
  },
  {
      "Date": "23-Sep-04",
      "PE": "14.42",
      "PB": "3.12",
      "Div": "2.22"
  },
  {
      "Date": "22-Sep-04",
      "PE": "14.66",
      "PB": "3.17",
      "Div": "2.19"
  },
  {
      "Date": "21-Sep-04",
      "PE": "14.63",
      "PB": "3.16",
      "Div": "2.19"
  },
  {
      "Date": "20-Sep-04",
      "PE": "14.45",
      "PB": "3.13",
      "Div": "2.22"
  },
  {
      "Date": "17-Sep-04",
      "PE": "14.49",
      "PB": "3.13",
      "Div": "2.21"
  },
  {
      "Date": "16-Sep-04",
      "PE": "14.25",
      "PB": "3.08",
      "Div": "2.25"
  },
  {
      "Date": "15-Sep-04",
      "PE": "14.07",
      "PB": "3.04",
      "Div": "2.28"
  },
  {
      "Date": "14-Sep-04",
      "PE": "14.09",
      "PB": "3.05",
      "Div": "2.28"
  },
  {
      "Date": "13-Sep-04",
      "PE": "14",
      "PB": "3.03",
      "Div": "2.29"
  },
  {
      "Date": "10-Sep-04",
      "PE": "13.95",
      "PB": "3.02",
      "Div": "2.3"
  },
  {
      "Date": "9-Sep-04",
      "PE": "13.78",
      "PB": "2.98",
      "Div": "2.33"
  },
  {
      "Date": "8-Sep-04",
      "PE": "13.84",
      "PB": "2.99",
      "Div": "2.32"
  },
  {
      "Date": "7-Sep-04",
      "PE": "13.79",
      "PB": "2.98",
      "Div": "2.33"
  },
  {
      "Date": "6-Sep-04",
      "PE": "13.74",
      "PB": "2.97",
      "Div": "2.34"
  },
  {
      "Date": "3-Sep-04",
      "PE": "13.66",
      "PB": "2.95",
      "Div": "2.4"
  },
  {
      "Date": "2-Sep-04",
      "PE": "13.62",
      "PB": "2.94",
      "Div": "2.41"
  },
  {
      "Date": "1-Sep-04",
      "PE": "13.67",
      "PB": "2.96",
      "Div": "2.4"
  },
  {
      "Date": "31-Aug-04",
      "PE": "13.64",
      "PB": "2.95",
      "Div": "2.4"
  },
  {
      "Date": "30-Aug-04",
      "PE": "13.61",
      "PB": "2.94",
      "Div": "2.41"
  },
  {
      "Date": "27-Aug-04",
      "PE": "13.45",
      "PB": "2.91",
      "Div": "2.45"
  },
  {
      "Date": "26-Aug-04",
      "PE": "13.46",
      "PB": "2.91",
      "Div": "2.44"
  },
  {
      "Date": "25-Aug-04",
      "PE": "13.34",
      "PB": "2.88",
      "Div": "2.47"
  },
  {
      "Date": "24-Aug-04",
      "PE": "13.3",
      "PB": "2.88",
      "Div": "2.47"
  },
  {
      "Date": "23-Aug-04",
      "PE": "13.19",
      "PB": "2.85",
      "Div": "2.49"
  },
  {
      "Date": "20-Aug-04",
      "PE": "13.29",
      "PB": "2.87",
      "Div": "2.47"
  },
  {
      "Date": "19-Aug-04",
      "PE": "13.45",
      "PB": "2.91",
      "Div": "2.44"
  },
  {
      "Date": "18-Aug-04",
      "PE": "13.22",
      "PB": "2.86",
      "Div": "2.48"
  },
  {
      "Date": "17-Aug-04",
      "PE": "13.41",
      "PB": "2.9",
      "Div": "2.45"
  },
  {
      "Date": "16-Aug-04",
      "PE": "13.36",
      "PB": "2.89",
      "Div": "2.46"
  },
  {
      "Date": "13-Aug-04",
      "PE": "13.36",
      "PB": "2.89",
      "Div": "2.47"
  },
  {
      "Date": "12-Aug-04",
      "PE": "13.43",
      "PB": "2.91",
      "Div": "2.46"
  },
  {
      "Date": "11-Aug-04",
      "PE": "13.55",
      "PB": "2.93",
      "Div": "2.44"
  },
  {
      "Date": "10-Aug-04",
      "PE": "13.8",
      "PB": "2.99",
      "Div": "2.4"
  },
  {
      "Date": "9-Aug-04",
      "PE": "13.72",
      "PB": "2.97",
      "Div": "2.41"
  },
  {
      "Date": "6-Aug-04",
      "PE": "13.64",
      "PB": "2.95",
      "Div": "2.42"
  },
  {
      "Date": "5-Aug-04",
      "PE": "13.82",
      "PB": "2.99",
      "Div": "2.39"
  },
  {
      "Date": "4-Aug-04",
      "PE": "13.58",
      "PB": "2.94",
      "Div": "2.43"
  },
  {
      "Date": "3-Aug-04",
      "PE": "13.62",
      "PB": "2.95",
      "Div": "2.43"
  },
  {
      "Date": "2-Aug-04",
      "PE": "13.69",
      "PB": "2.96",
      "Div": "2.41"
  },
  {
      "Date": "30-Jul-04",
      "PE": "13.63",
      "PB": "2.95",
      "Div": "2.45"
  },
  {
      "Date": "29-Jul-04",
      "PE": "13.52",
      "PB": "2.93",
      "Div": "2.47"
  },
  {
      "Date": "28-Jul-04",
      "PE": "13.31",
      "PB": "2.91",
      "Div": "2.51"
  },
  {
      "Date": "27-Jul-04",
      "PE": "13.37",
      "PB": "2.92",
      "Div": "2.5"
  },
  {
      "Date": "26-Jul-04",
      "PE": "13.51",
      "PB": "2.95",
      "Div": "2.47"
  },
  {
      "Date": "23-Jul-04",
      "PE": "13.34",
      "PB": "2.91",
      "Div": "2.49"
  },
  {
      "Date": "22-Jul-04",
      "PE": "13.31",
      "PB": "2.91",
      "Div": "2.49"
  },
  {
      "Date": "21-Jul-04",
      "PE": "13.17",
      "PB": "2.88",
      "Div": "2.52"
  },
  {
      "Date": "20-Jul-04",
      "PE": "13.05",
      "PB": "2.85",
      "Div": "2.54"
  },
  {
      "Date": "19-Jul-04",
      "PE": "13.09",
      "PB": "2.86",
      "Div": "2.53"
  },
  {
      "Date": "16-Jul-04",
      "PE": "12.98",
      "PB": "2.83",
      "Div": "2.53"
  },
  {
      "Date": "15-Jul-04",
      "PE": "12.82",
      "PB": "2.8",
      "Div": "2.56"
  },
  {
      "Date": "14-Jul-04",
      "PE": "12.68",
      "PB": "2.77",
      "Div": "2.59"
  },
  {
      "Date": "13-Jul-04",
      "PE": "12.82",
      "PB": "2.8",
      "Div": "2.56"
  },
  {
      "Date": "12-Jul-04",
      "PE": "12.97",
      "PB": "2.83",
      "Div": "2.53"
  },
  {
      "Date": "9-Jul-04",
      "PE": "12.94",
      "PB": "2.92",
      "Div": "2.53"
  },
  {
      "Date": "8-Jul-04",
      "PE": "12.64",
      "PB": "2.85",
      "Div": "2.58"
  },
  {
      "Date": "7-Jul-04",
      "PE": "13.05",
      "PB": "2.94",
      "Div": "2.5"
  },
  {
      "Date": "6-Jul-04",
      "PE": "12.98",
      "PB": "2.93",
      "Div": "2.52"
  },
  {
      "Date": "5-Jul-04",
      "PE": "12.72",
      "PB": "2.87",
      "Div": "2.57"
  },
  {
      "Date": "2-Jul-04",
      "PE": "12.9",
      "PB": "2.89",
      "Div": "2.54"
  },
  {
      "Date": "1-Jul-04",
      "PE": "12.9",
      "PB": "2.89",
      "Div": "2.54"
  },
  {
      "Date": "30-Jun-04",
      "PE": "12.64",
      "PB": "2.83",
      "Div": "2.59"
  },
  {
      "Date": "29-Jun-04",
      "PE": "12.74",
      "PB": "2.85",
      "Div": "2.57"
  },
  {
      "Date": "28-Jun-04",
      "PE": "12.71",
      "PB": "2.84",
      "Div": "2.57"
  },
  {
      "Date": "25-Jun-04",
      "PE": "11.96",
      "PB": "2.85",
      "Div": "2.6"
  },
  {
      "Date": "24-Jun-04",
      "PE": "11.82",
      "PB": "2.82",
      "Div": "2.64"
  },
  {
      "Date": "23-Jun-04",
      "PE": "11.62",
      "PB": "2.77",
      "Div": "2.68"
  },
  {
      "Date": "22-Jun-04",
      "PE": "11.85",
      "PB": "2.83",
      "Div": "2.63"
  },
  {
      "Date": "21-Jun-04",
      "PE": "11.91",
      "PB": "2.84",
      "Div": "2.62"
  },
  {
      "Date": "18-Jun-04",
      "PE": "11.98",
      "PB": "2.86",
      "Div": "2.6"
  },
  {
      "Date": "17-Jun-04",
      "PE": "12.15",
      "PB": "2.9",
      "Div": "2.56"
  },
  {
      "Date": "16-Jun-04",
      "PE": "12.01",
      "PB": "2.87",
      "Div": "2.59"
  },
  {
      "Date": "15-Jun-04",
      "PE": "12",
      "PB": "2.88",
      "Div": "2.58"
  },
  {
      "Date": "14-Jun-04",
      "PE": "11.84",
      "PB": "2.84",
      "Div": "2.62"
  },
  {
      "Date": "11-Jun-04",
      "PE": "12.14",
      "PB": "2.89",
      "Div": "2.55"
  },
  {
      "Date": "10-Jun-04",
      "PE": "12.44",
      "PB": "2.96",
      "Div": "2.49"
  },
  {
      "Date": "9-Jun-04",
      "PE": "12.47",
      "PB": "2.97",
      "Div": "2.48"
  },
  {
      "Date": "8-Jun-04",
      "PE": "12.48",
      "PB": "2.97",
      "Div": "2.48"
  },
  {
      "Date": "7-Jun-04",
      "PE": "12.42",
      "PB": "2.96",
      "Div": "2.49"
  },
  {
      "Date": "4-Jun-04",
      "PE": "12.25",
      "PB": "2.92",
      "Div": "2.53"
  },
  {
      "Date": "3-Jun-04",
      "PE": "12.04",
      "PB": "2.87",
      "Div": "2.57"
  },
  {
      "Date": "2-Jun-04",
      "PE": "12.36",
      "PB": "2.94",
      "Div": "2.51"
  },
  {
      "Date": "1-Jun-04",
      "PE": "12.14",
      "PB": "2.89",
      "Div": "2.55"
  },
  {
      "Date": "31-May-04",
      "PE": "11.94",
      "PB": "2.84",
      "Div": "2.59"
  },
  {
      "Date": "28-May-04",
      "PE": "12.98",
      "PB": "2.95",
      "Div": "2.55"
  },
  {
      "Date": "27-May-04",
      "PE": "13.65",
      "PB": "3.1",
      "Div": "2.42"
  },
  {
      "Date": "26-May-04",
      "PE": "13.75",
      "PB": "3.13",
      "Div": "2.41"
  },
  {
      "Date": "25-May-04",
      "PE": "13.88",
      "PB": "3.14",
      "Div": "2.3"
  },
  {
      "Date": "24-May-04",
      "PE": "13.9",
      "PB": "3.15",
      "Div": "2.3"
  },
  {
      "Date": "21-May-04",
      "PE": "14.14",
      "PB": "3.08",
      "Div": "2.35"
  },
  {
      "Date": "20-May-04",
      "PE": "13.99",
      "PB": "3.04",
      "Div": "2.38"
  },
  {
      "Date": "19-May-04",
      "PE": "14.41",
      "PB": "3.09",
      "Div": "2.34"
  },
  {
      "Date": "18-May-04",
      "PE": "13.94",
      "PB": "2.97",
      "Div": "2.44"
  },
  {
      "Date": "17-May-04",
      "PE": "12.87",
      "PB": "2.74",
      "Div": "2.64"
  },
  {
      "Date": "14-May-04",
      "PE": "14.66",
      "PB": "3.12",
      "Div": "2.32"
  },
  {
      "Date": "13-May-04",
      "PE": "15.91",
      "PB": "3.39",
      "Div": "2.14"
  },
  {
      "Date": "12-May-04",
      "PE": "15.86",
      "PB": "3.37",
      "Div": "2.14"
  },
  {
      "Date": "11-May-04",
      "PE": "15.74",
      "PB": "3.35",
      "Div": "2.16"
  },
  {
      "Date": "10-May-04",
      "PE": "16.38",
      "PB": "3.49",
      "Div": "2.07"
  },
  {
      "Date": "7-May-04",
      "PE": "16.77",
      "PB": "3.56",
      "Div": "2.03"
  },
  {
      "Date": "6-May-04",
      "PE": "17.04",
      "PB": "3.61",
      "Div": "2"
  },
  {
      "Date": "5-May-04",
      "PE": "16.86",
      "PB": "3.57",
      "Div": "1.94"
  },
  {
      "Date": "4-May-04",
      "PE": "16.87",
      "PB": "3.53",
      "Div": "1.96"
  },
  {
      "Date": "3-May-04",
      "PE": "16.62",
      "PB": "3.48",
      "Div": "2.02"
  },
  {
      "Date": "30-Apr-04",
      "PE": "18.68",
      "PB": "3.54",
      "Div": "1.98"
  },
  {
      "Date": "29-Apr-04",
      "PE": "18.82",
      "PB": "3.56",
      "Div": "1.97"
  },
  {
      "Date": "28-Apr-04",
      "PE": "18.89",
      "PB": "3.58",
      "Div": "1.96"
  },
  {
      "Date": "27-Apr-04",
      "PE": "18.9",
      "PB": "3.58",
      "Div": "1.96"
  },
  {
      "Date": "23-Apr-04",
      "PE": "19.68",
      "PB": "3.73",
      "Div": "1.88"
  },
  {
      "Date": "22-Apr-04",
      "PE": "19.58",
      "PB": "3.71",
      "Div": "1.89"
  },
  {
      "Date": "21-Apr-04",
      "PE": "19.41",
      "PB": "3.68",
      "Div": "1.91"
  },
  {
      "Date": "20-Apr-04",
      "PE": "19.11",
      "PB": "3.62",
      "Div": "1.94"
  },
  {
      "Date": "19-Apr-04",
      "PE": "19.1",
      "PB": "3.62",
      "Div": "1.94"
  },
  {
      "Date": "17-Apr-04",
      "PE": "19.35",
      "PB": "3.67",
      "Div": "1.91"
  },
  {
      "Date": "16-Apr-04",
      "PE": "19.36",
      "PB": "3.67",
      "Div": "1.91"
  },
  {
      "Date": "15-Apr-04",
      "PE": "19.29",
      "PB": "3.65",
      "Div": "1.92"
  },
  {
      "Date": "13-Apr-04",
      "PE": "19.46",
      "PB": "3.69",
      "Div": "1.9"
  },
  {
      "Date": "12-Apr-04",
      "PE": "19.04",
      "PB": "3.61",
      "Div": "1.95"
  },
  {
      "Date": "8-Apr-04",
      "PE": "21.67",
      "PB": "3.66",
      "Div": "1.69"
  },
  {
      "Date": "7-Apr-04",
      "PE": "21.61",
      "PB": "3.65",
      "Div": "1.69"
  },
  {
      "Date": "6-Apr-04",
      "PE": "21.64",
      "PB": "3.66",
      "Div": "1.69"
  },
  {
      "Date": "5-Apr-04",
      "PE": "21.71",
      "PB": "3.67",
      "Div": "1.69"
  },
  {
      "Date": "2-Apr-04",
      "PE": "21.53",
      "PB": "3.64",
      "Div": "1.7"
  },
  {
      "Date": "1-Apr-04",
      "PE": "21.27",
      "PB": "3.59",
      "Div": "1.72"
  },
  {
      "Date": "31-Mar-04",
      "PE": "20.7",
      "PB": "3.5",
      "Div": "1.77"
  },
  {
      "Date": "30-Mar-04",
      "PE": "20.44",
      "PB": "3.45",
      "Div": "1.79"
  },
  {
      "Date": "29-Mar-04",
      "PE": "20.58",
      "PB": "3.48",
      "Div": "1.78"
  },
  {
      "Date": "26-Mar-04",
      "PE": "20.41",
      "PB": "3.45",
      "Div": "1.79"
  },
  {
      "Date": "25-Mar-04",
      "PE": "19.91",
      "PB": "3.36",
      "Div": "1.84"
  },
  {
      "Date": "24-Mar-04",
      "PE": "19.76",
      "PB": "3.34",
      "Div": "1.85"
  },
  {
      "Date": "23-Mar-04",
      "PE": "19.81",
      "PB": "3.35",
      "Div": "1.85"
  },
  {
      "Date": "22-Mar-04",
      "PE": "19.68",
      "PB": "3.32",
      "Div": "1.86"
  },
  {
      "Date": "19-Mar-04",
      "PE": "20.15",
      "PB": "3.4",
      "Div": "1.82"
  },
  {
      "Date": "18-Mar-04",
      "PE": "20.05",
      "PB": "3.39",
      "Div": "1.82"
  },
  {
      "Date": "17-Mar-04",
      "PE": "20.44",
      "PB": "3.45",
      "Div": "1.79"
  },
  {
      "Date": "16-Mar-04",
      "PE": "20.43",
      "PB": "3.45",
      "Div": "1.79"
  },
  {
      "Date": "15-Mar-04",
      "PE": "20.59",
      "PB": "3.48",
      "Div": "1.46"
  },
  {
      "Date": "12-Mar-04",
      "PE": "21.16",
      "PB": "3.58",
      "Div": "1.42"
  },
  {
      "Date": "11-Mar-04",
      "PE": "21.08",
      "PB": "3.56",
      "Div": "1.42"
  },
  {
      "Date": "10-Mar-04",
      "PE": "21.54",
      "PB": "3.64",
      "Div": "1.39"
  },
  {
      "Date": "9-Mar-04",
      "PE": "21.79",
      "PB": "3.68",
      "Div": "1.38"
  },
  {
      "Date": "8-Mar-04",
      "PE": "22.01",
      "PB": "3.72",
      "Div": "1.36"
  },
  {
      "Date": "5-Mar-04",
      "PE": "21.81",
      "PB": "3.68",
      "Div": "1.37"
  },
  {
      "Date": "4-Mar-04",
      "PE": "21.53",
      "PB": "3.64",
      "Div": "1.39"
  },
  {
      "Date": "3-Mar-04",
      "PE": "21.72",
      "PB": "3.67",
      "Div": "1.38"
  },
  {
      "Date": "1-Mar-04",
      "PE": "21.57",
      "PB": "4.26",
      "Div": "1.39"
  },
  {
      "Date": "27-Feb-04",
      "PE": "19.74",
      "PB": "3.89",
      "Div": "1.52"
  },
  {
      "Date": "26-Feb-04",
      "PE": "19.37",
      "PB": "3.81",
      "Div": "1.55"
  },
  {
      "Date": "25-Feb-04",
      "PE": "19.6",
      "PB": "3.86",
      "Div": "1.54"
  },
  {
      "Date": "24-Feb-04",
      "PE": "19.97",
      "PB": "3.93",
      "Div": "1.51"
  },
  {
      "Date": "23-Feb-04",
      "PE": "19.83",
      "PB": "3.91",
      "Div": "1.52"
  },
  {
      "Date": "20-Feb-04",
      "PE": "20.31",
      "PB": "4",
      "Div": "1.48"
  },
  {
      "Date": "19-Feb-04",
      "PE": "20.38",
      "PB": "4.01",
      "Div": "1.48"
  },
  {
      "Date": "18-Feb-04",
      "PE": "21.01",
      "PB": "4.14",
      "Div": "1.43"
  },
  {
      "Date": "17-Feb-04",
      "PE": "21.05",
      "PB": "4.15",
      "Div": "1.43"
  },
  {
      "Date": "16-Feb-04",
      "PE": "20.98",
      "PB": "4.13",
      "Div": "1.43"
  },
  {
      "Date": "13-Feb-04",
      "PE": "20.95",
      "PB": "4.13",
      "Div": "1.44"
  },
  {
      "Date": "12-Feb-04",
      "PE": "20.64",
      "PB": "4.07",
      "Div": "1.46"
  },
  {
      "Date": "11-Feb-04",
      "PE": "20.71",
      "PB": "4.08",
      "Div": "1.45"
  },
  {
      "Date": "10-Feb-04",
      "PE": "20.79",
      "PB": "4.06",
      "Div": "1.46"
  },
  {
      "Date": "9-Feb-04",
      "PE": "20.78",
      "PB": "4.06",
      "Div": "1.46"
  },
  {
      "Date": "6-Feb-04",
      "PE": "20.27",
      "PB": "3.95",
      "Div": "1.5"
  },
  {
      "Date": "5-Feb-04",
      "PE": "19.94",
      "PB": "3.89",
      "Div": "1.52"
  },
  {
      "Date": "4-Feb-04",
      "PE": "20.14",
      "PB": "3.93",
      "Div": "1.51"
  },
  {
      "Date": "3-Feb-04",
      "PE": "19.55",
      "PB": "3.81",
      "Div": "1.55"
  },
  {
      "Date": "30-Jan-04",
      "PE": "19.96",
      "PB": "3.9",
      "Div": "1.52"
  },
  {
      "Date": "29-Jan-04",
      "PE": "20.34",
      "PB": "3.97",
      "Div": "1.49"
  },
  {
      "Date": "28-Jan-04",
      "PE": "20.55",
      "PB": "4.01",
      "Div": "1.48"
  },
  {
      "Date": "27-Jan-04",
      "PE": "21.01",
      "PB": "4.1",
      "Div": "1.45"
  },
  {
      "Date": "23-Jan-04",
      "PE": "20.38",
      "PB": "3.98",
      "Div": "1.49"
  },
  {
      "Date": "22-Jan-04",
      "PE": "19.53",
      "PB": "3.81",
      "Div": "1.56"
  },
  {
      "Date": "21-Jan-04",
      "PE": "20.13",
      "PB": "3.93",
      "Div": "1.51"
  },
  {
      "Date": "20-Jan-04",
      "PE": "20.88",
      "PB": "4.07",
      "Div": "1.46"
  },
  {
      "Date": "19-Jan-04",
      "PE": "21.35",
      "PB": "4.17",
      "Div": "1.42"
  },
  {
      "Date": "16-Jan-04",
      "PE": "20.96",
      "PB": "4.09",
      "Div": "1.45"
  },
  {
      "Date": "15-Jan-04",
      "PE": "21.44",
      "PB": "4.18",
      "Div": "1.42"
  },
  {
      "Date": "14-Jan-04",
      "PE": "21.86",
      "PB": "4.27",
      "Div": "1.39"
  },
  {
      "Date": "13-Jan-04",
      "PE": "21.66",
      "PB": "4.23",
      "Div": "1.4"
  },
  {
      "Date": "12-Jan-04",
      "PE": "21.46",
      "PB": "4.19",
      "Div": "1.42"
  },
  {
      "Date": "9-Jan-04",
      "PE": "21.75",
      "PB": "4.24",
      "Div": "1.4"
  },
  {
      "Date": "8-Jan-04",
      "PE": "21.71",
      "PB": "4.24",
      "Div": "1.4"
  },
  {
      "Date": "7-Jan-04",
      "PE": "21.14",
      "PB": "4.12",
      "Div": "1.44"
  },
  {
      "Date": "6-Jan-04",
      "PE": "21.25",
      "PB": "4.15",
      "Div": "1.43"
  },
  {
      "Date": "5-Jan-04",
      "PE": "21.56",
      "PB": "4.21",
      "Div": "1.41"
  },
  {
      "Date": "2-Jan-04",
      "PE": "21.46",
      "PB": "4.19",
      "Div": "1.42"
  },
  {
      "Date": "1-Jan-04",
      "PE": "21.09",
      "PB": "4.12",
      "Div": "1.44"
  },
  {
      "Date": "31-Dec-03",
      "PE": "20.73",
      "PB": "4.05",
      "Div": "1.47"
  },
  {
      "Date": "30-Dec-03",
      "PE": "20.66",
      "PB": "4.03",
      "Div": "1.47"
  },
  {
      "Date": "29-Dec-03",
      "PE": "20.67",
      "PB": "4.03",
      "Div": "1.47"
  },
  {
      "Date": "26-Dec-03",
      "PE": "20.26",
      "PB": "3.95",
      "Div": "1.5"
  },
  {
      "Date": "24-Dec-03",
      "PE": "19.95",
      "PB": "3.89",
      "Div": "1.52"
  },
  {
      "Date": "23-Dec-03",
      "PE": "19.63",
      "PB": "3.83",
      "Div": "1.55"
  },
  {
      "Date": "22-Dec-03",
      "PE": "19.73",
      "PB": "3.85",
      "Div": "1.54"
  },
  {
      "Date": "19-Dec-03",
      "PE": "19.61",
      "PB": "3.83",
      "Div": "1.55"
  },
  {
      "Date": "18-Dec-03",
      "PE": "19.37",
      "PB": "3.78",
      "Div": "1.57"
  },
  {
      "Date": "17-Dec-03",
      "PE": "19.11",
      "PB": "3.73",
      "Div": "1.59"
  },
  {
      "Date": "16-Dec-03",
      "PE": "19.15",
      "PB": "3.74",
      "Div": "1.59"
  },
  {
      "Date": "15-Dec-03",
      "PE": "19.01",
      "PB": "3.71",
      "Div": "1.6"
  },
  {
      "Date": "12-Dec-03",
      "PE": "18.73",
      "PB": "3.66",
      "Div": "1.62"
  },
  {
      "Date": "11-Dec-03",
      "PE": "18.69",
      "PB": "3.65",
      "Div": "1.63"
  },
  {
      "Date": "10-Dec-03",
      "PE": "18.6",
      "PB": "3.63",
      "Div": "1.63"
  },
  {
      "Date": "9-Dec-03",
      "PE": "18.48",
      "PB": "3.61",
      "Div": "1.64"
  },
  {
      "Date": "8-Dec-03",
      "PE": "18.15",
      "PB": "3.54",
      "Div": "1.67"
  },
  {
      "Date": "5-Dec-03",
      "PE": "18.15",
      "PB": "3.54",
      "Div": "1.67"
  },
  {
      "Date": "4-Dec-03",
      "PE": "18.47",
      "PB": "3.6",
      "Div": "1.65"
  },
  {
      "Date": "3-Dec-03",
      "PE": "18.42",
      "PB": "3.59",
      "Div": "1.65"
  },
  {
      "Date": "2-Dec-03",
      "PE": "18.29",
      "PB": "3.57",
      "Div": "1.66"
  },
  {
      "Date": "1-Dec-03",
      "PE": "18.27",
      "PB": "3.57",
      "Div": "1.66"
  },
  {
      "Date": "28-Nov-03",
      "PE": "17.81",
      "PB": "3.47",
      "Div": "1.71"
  },
  {
      "Date": "27-Nov-03",
      "PE": "17.62",
      "PB": "3.44",
      "Div": "1.72"
  },
  {
      "Date": "25-Nov-03",
      "PE": "17.29",
      "PB": "3.37",
      "Div": "1.76"
  },
  {
      "Date": "24-Nov-03",
      "PE": "17.02",
      "PB": "3.32",
      "Div": "1.79"
  },
  {
      "Date": "21-Nov-03",
      "PE": "16.98",
      "PB": "3.31",
      "Div": "1.79"
  },
  {
      "Date": "20-Nov-03",
      "PE": "16.78",
      "PB": "3.27",
      "Div": "1.81"
  },
  {
      "Date": "19-Nov-03",
      "PE": "16.98",
      "PB": "3.31",
      "Div": "1.79"
  },
  {
      "Date": "18-Nov-03",
      "PE": "17.24",
      "PB": "3.37",
      "Div": "1.76"
  },
  {
      "Date": "17-Nov-03",
      "PE": "17.42",
      "PB": "3.4",
      "Div": "1.74"
  },
  {
      "Date": "15-Nov-03",
      "PE": "17.23",
      "PB": "3.36",
      "Div": "1.76"
  },
  {
      "Date": "14-Nov-03",
      "PE": "17.09",
      "PB": "3.34",
      "Div": "1.78"
  },
  {
      "Date": "13-Nov-03",
      "PE": "17.42",
      "PB": "3.4",
      "Div": "1.74"
  },
  {
      "Date": "12-Nov-03",
      "PE": "17.68",
      "PB": "3.45",
      "Div": "1.72"
  },
  {
      "Date": "11-Nov-03",
      "PE": "17.65",
      "PB": "3.44",
      "Div": "1.72"
  },
  {
      "Date": "10-Nov-03",
      "PE": "17.58",
      "PB": "3.43",
      "Div": "1.73"
  },
  {
      "Date": "7-Nov-03",
      "PE": "17.55",
      "PB": "3.42",
      "Div": "1.73"
  },
  {
      "Date": "6-Nov-03",
      "PE": "17.77",
      "PB": "3.47",
      "Div": "1.71"
  },
  {
      "Date": "5-Nov-03",
      "PE": "17.74",
      "PB": "3.46",
      "Div": "1.71"
  },
  {
      "Date": "4-Nov-03",
      "PE": "17.84",
      "PB": "3.48",
      "Div": "1.7"
  },
  {
      "Date": "3-Nov-03",
      "PE": "17.65",
      "PB": "3.45",
      "Div": "1.72"
  },
  {
      "Date": "31-Oct-03",
      "PE": "17.15",
      "PB": "3.35",
      "Div": "1.77"
  },
  {
      "Date": "30-Oct-03",
      "PE": "16.72",
      "PB": "3.26",
      "Div": "1.82"
  },
  {
      "Date": "29-Oct-03",
      "PE": "16.52",
      "PB": "3.22",
      "Div": "1.84"
  },
  {
      "Date": "28-Oct-03",
      "PE": "16.33",
      "PB": "3.19",
      "Div": "1.86"
  },
  {
      "Date": "27-Oct-03",
      "PE": "16.37",
      "PB": "3.19",
      "Div": "1.86"
  },
  {
      "Date": "25-Oct-03",
      "PE": "16.78",
      "PB": "3.27",
      "Div": "1.81"
  },
  {
      "Date": "24-Oct-03",
      "PE": "16.6",
      "PB": "3.24",
      "Div": "1.83"
  },
  {
      "Date": "23-Oct-03",
      "PE": "16.21",
      "PB": "3.16",
      "Div": "1.87"
  },
  {
      "Date": "22-Oct-03",
      "PE": "16.46",
      "PB": "3.21",
      "Div": "1.85"
  },
  {
      "Date": "21-Oct-03",
      "PE": "16.6",
      "PB": "3.24",
      "Div": "1.83"
  },
  {
      "Date": "20-Oct-03",
      "PE": "17",
      "PB": "3.32",
      "Div": "1.79"
  },
  {
      "Date": "17-Oct-03",
      "PE": "17.29",
      "PB": "3.37",
      "Div": "1.76"
  },
  {
      "Date": "16-Oct-03",
      "PE": "17.14",
      "PB": "3.35",
      "Div": "1.77"
  },
  {
      "Date": "15-Oct-03",
      "PE": "16.94",
      "PB": "3.3",
      "Div": "1.79"
  },
  {
      "Date": "14-Oct-03",
      "PE": "16.76",
      "PB": "3.27",
      "Div": "1.81"
  },
  {
      "Date": "13-Oct-03",
      "PE": "17.04",
      "PB": "3.33",
      "Div": "1.78"
  },
  {
      "Date": "10-Oct-03",
      "PE": "16.78",
      "PB": "3.28",
      "Div": "1.81"
  },
  {
      "Date": "9-Oct-03",
      "PE": "16.55",
      "PB": "3.23",
      "Div": "1.84"
  },
  {
      "Date": "8-Oct-03",
      "PE": "16.29",
      "PB": "3.18",
      "Div": "1.77"
  },
  {
      "Date": "7-Oct-03",
      "PE": "16.28",
      "PB": "3.18",
      "Div": "1.77"
  },
  {
      "Date": "6-Oct-03",
      "PE": "16.3",
      "PB": "3.18",
      "Div": "1.77"
  },
  {
      "Date": "3-Oct-03",
      "PE": "15.97",
      "PB": "3.12",
      "Div": "1.8"
  },
  {
      "Date": "1-Oct-03",
      "PE": "15.66",
      "PB": "3.06",
      "Div": "1.84"
  },
  {
      "Date": "30-Sep-03",
      "PE": "15.61",
      "PB": "3.05",
      "Div": "1.85"
  },
  {
      "Date": "29-Sep-03",
      "PE": "15.43",
      "PB": "3.01",
      "Div": "1.87"
  },
  {
      "Date": "26-Sep-03",
      "PE": "15.28",
      "PB": "2.98",
      "Div": "1.89"
  },
  {
      "Date": "25-Sep-03",
      "PE": "14.95",
      "PB": "2.92",
      "Div": "1.93"
  },
  {
      "Date": "24-Sep-03",
      "PE": "15.12",
      "PB": "2.95",
      "Div": "1.91"
  },
  {
      "Date": "23-Sep-03",
      "PE": "14.64",
      "PB": "2.86",
      "Div": "1.97"
  },
  {
      "Date": "22-Sep-03",
      "PE": "14.36",
      "PB": "2.8",
      "Div": "2.01"
  },
  {
      "Date": "19-Sep-03",
      "PE": "14.57",
      "PB": "2.84",
      "Div": "1.98"
  },
  {
      "Date": "18-Sep-03",
      "PE": "14.35",
      "PB": "2.8",
      "Div": "2.01"
  },
  {
      "Date": "17-Sep-03",
      "PE": "14.78",
      "PB": "2.88",
      "Div": "1.95"
  },
  {
      "Date": "16-Sep-03",
      "PE": "14.96",
      "PB": "2.92",
      "Div": "1.93"
  },
  {
      "Date": "15-Sep-03",
      "PE": "14.65",
      "PB": "2.86",
      "Div": "1.97"
  },
  {
      "Date": "12-Sep-03",
      "PE": "15.12",
      "PB": "2.95",
      "Div": "1.91"
  },
  {
      "Date": "11-Sep-03",
      "PE": "15.46",
      "PB": "3.02",
      "Div": "1.86"
  },
  {
      "Date": "10-Sep-03",
      "PE": "15.53",
      "PB": "3.03",
      "Div": "1.86"
  },
  {
      "Date": "9-Sep-03",
      "PE": "15.5",
      "PB": "3.03",
      "Div": "1.86"
  },
  {
      "Date": "8-Sep-03",
      "PE": "15.62",
      "PB": "3.05",
      "Div": "1.85"
  },
  {
      "Date": "5-Sep-03",
      "PE": "15.41",
      "PB": "3.01",
      "Div": "1.87"
  },
  {
      "Date": "4-Sep-03",
      "PE": "15.13",
      "PB": "2.95",
      "Div": "1.91"
  },
  {
      "Date": "3-Sep-03",
      "PE": "14.98",
      "PB": "2.92",
      "Div": "1.92"
  },
  {
      "Date": "2-Sep-03",
      "PE": "15.27",
      "PB": "2.98",
      "Div": "1.89"
  },
  {
      "Date": "1-Sep-03",
      "PE": "15.16",
      "PB": "2.96",
      "Div": "1.9"
  },
  {
      "Date": "29-Aug-03",
      "PE": "14.95",
      "PB": "2.92",
      "Div": "1.93"
  },
  {
      "Date": "28-Aug-03",
      "PE": "14.78",
      "PB": "2.88",
      "Div": "1.95"
  },
  {
      "Date": "27-Aug-03",
      "PE": "14.77",
      "PB": "2.88",
      "Div": "1.95"
  },
  {
      "Date": "26-Aug-03",
      "PE": "14.52",
      "PB": "2.83",
      "Div": "1.98"
  },
  {
      "Date": "25-Aug-03",
      "PE": "14.01",
      "PB": "2.73",
      "Div": "2.06"
  },
  {
      "Date": "22-Aug-03",
      "PE": "14.45",
      "PB": "2.82",
      "Div": "1.99"
  },
  {
      "Date": "21-Aug-03",
      "PE": "14.33",
      "PB": "2.8",
      "Div": "2.01"
  },
  {
      "Date": "20-Aug-03",
      "PE": "14.19",
      "PB": "2.77",
      "Div": "2.03"
  },
  {
      "Date": "19-Aug-03",
      "PE": "14.08",
      "PB": "2.75",
      "Div": "2.05"
  },
  {
      "Date": "18-Aug-03",
      "PE": "14.12",
      "PB": "2.76",
      "Div": "2.04"
  },
  {
      "Date": "14-Aug-03",
      "PE": "13.75",
      "PB": "2.68",
      "Div": "2.1"
  },
  {
      "Date": "13-Aug-03",
      "PE": "13.74",
      "PB": "2.68",
      "Div": "2.1"
  },
  {
      "Date": "12-Aug-03",
      "PE": "13.61",
      "PB": "2.65",
      "Div": "2.12"
  },
  {
      "Date": "11-Aug-03",
      "PE": "13.58",
      "PB": "2.65",
      "Div": "2.12"
  },
  {
      "Date": "8-Aug-03",
      "PE": "13.47",
      "PB": "2.63",
      "Div": "2.14"
  },
  {
      "Date": "7-Aug-03",
      "PE": "13.19",
      "PB": "2.57",
      "Div": "2.19"
  },
  {
      "Date": "6-Aug-03",
      "PE": "12.9",
      "PB": "2.52",
      "Div": "2.23"
  },
  {
      "Date": "5-Aug-03",
      "PE": "13.05",
      "PB": "2.55",
      "Div": "2.21"
  },
  {
      "Date": "4-Aug-03",
      "PE": "13.26",
      "PB": "2.59",
      "Div": "2.17"
  },
  {
      "Date": "1-Aug-03",
      "PE": "12.95",
      "PB": "2.58",
      "Div": "2.21"
  },
  {
      "Date": "31-Jul-03",
      "PE": "12.84",
      "PB": "2.55",
      "Div": "2.23"
  },
  {
      "Date": "30-Jul-03",
      "PE": "12.81",
      "PB": "2.55",
      "Div": "2.23"
  },
  {
      "Date": "29-Jul-03",
      "PE": "12.71",
      "PB": "2.53",
      "Div": "2.25"
  },
  {
      "Date": "28-Jul-03",
      "PE": "12.65",
      "PB": "2.52",
      "Div": "2.26"
  },
  {
      "Date": "25-Jul-03",
      "PE": "12.58",
      "PB": "2.51",
      "Div": "2.27"
  },
  {
      "Date": "24-Jul-03",
      "PE": "12.32",
      "PB": "2.45",
      "Div": "2.32"
  },
  {
      "Date": "23-Jul-03",
      "PE": "12.1",
      "PB": "2.41",
      "Div": "2.36"
  },
  {
      "Date": "22-Jul-03",
      "PE": "12",
      "PB": "2.39",
      "Div": "2.38"
  },
  {
      "Date": "21-Jul-03",
      "PE": "12.16",
      "PB": "2.4",
      "Div": "2.36"
  },
  {
      "Date": "18-Jul-03",
      "PE": "12.42",
      "PB": "2.46",
      "Div": "2.31"
  },
  {
      "Date": "17-Jul-03",
      "PE": "12.55",
      "PB": "2.48",
      "Div": "2.29"
  },
  {
      "Date": "16-Jul-03",
      "PE": "12.73",
      "PB": "2.52",
      "Div": "2.26"
  },
  {
      "Date": "15-Jul-03",
      "PE": "12.64",
      "PB": "2.5",
      "Div": "2.27"
  },
  {
      "Date": "14-Jul-03",
      "PE": "12.76",
      "PB": "2.52",
      "Div": "2.25"
  },
  {
      "Date": "11-Jul-03",
      "PE": "12.66",
      "PB": "2.5",
      "Div": "2.27"
  },
  {
      "Date": "10-Jul-03",
      "PE": "12.66",
      "PB": "2.5",
      "Div": "2.27"
  },
  {
      "Date": "9-Jul-03",
      "PE": "12.43",
      "PB": "2.46",
      "Div": "2.31"
  },
  {
      "Date": "8-Jul-03",
      "PE": "12.49",
      "PB": "2.47",
      "Div": "2.3"
  },
  {
      "Date": "7-Jul-03",
      "PE": "12.43",
      "PB": "2.46",
      "Div": "2.31"
  },
  {
      "Date": "4-Jul-03",
      "PE": "12.4",
      "PB": "2.45",
      "Div": "2.32"
  },
  {
      "Date": "3-Jul-03",
      "PE": "12.47",
      "PB": "2.47",
      "Div": "2.31"
  },
  {
      "Date": "2-Jul-03",
      "PE": "12.35",
      "PB": "2.44",
      "Div": "2.33"
  },
  {
      "Date": "1-Jul-03",
      "PE": "12.32",
      "PB": "2.44",
      "Div": "2.33"
  },
  {
      "Date": "30-Jun-03",
      "PE": "12.95",
      "PB": "2.44",
      "Div": "2.62"
  },
  {
      "Date": "27-Jun-03",
      "PE": "12.85",
      "PB": "2.42",
      "Div": "2.64"
  },
  {
      "Date": "26-Jun-03",
      "PE": "12.75",
      "PB": "2.41",
      "Div": "2.66"
  },
  {
      "Date": "25-Jun-03",
      "PE": "12.64",
      "PB": "2.38",
      "Div": "2.69"
  },
  {
      "Date": "24-Jun-03",
      "PE": "12.39",
      "PB": "2.34",
      "Div": "2.74"
  },
  {
      "Date": "23-Jun-03",
      "PE": "12.44",
      "PB": "2.35",
      "Div": "2.73"
  },
  {
      "Date": "20-Jun-03",
      "PE": "12.56",
      "PB": "2.37",
      "Div": "2.7"
  },
  {
      "Date": "19-Jun-03",
      "PE": "12.47",
      "PB": "2.35",
      "Div": "2.72"
  },
  {
      "Date": "18-Jun-03",
      "PE": "12.41",
      "PB": "2.34",
      "Div": "2.74"
  },
  {
      "Date": "17-Jun-03",
      "PE": "12.35",
      "PB": "2.33",
      "Div": "2.75"
  },
  {
      "Date": "16-Jun-03",
      "PE": "12.01",
      "PB": "2.27",
      "Div": "2.83"
  },
  {
      "Date": "13-Jun-03",
      "PE": "12.06",
      "PB": "2.28",
      "Div": "2.82"
  },
  {
      "Date": "12-Jun-03",
      "PE": "12",
      "PB": "2.27",
      "Div": "2.83"
  },
  {
      "Date": "11-Jun-03",
      "PE": "11.92",
      "PB": "2.25",
      "Div": "2.85"
  },
  {
      "Date": "10-Jun-03",
      "PE": "11.85",
      "PB": "2.24",
      "Div": "2.87"
  },
  {
      "Date": "9-Jun-03",
      "PE": "12.01",
      "PB": "2.27",
      "Div": "2.83"
  },
  {
      "Date": "6-Jun-03",
      "PE": "11.95",
      "PB": "2.25",
      "Div": "2.84"
  },
  {
      "Date": "5-Jun-03",
      "PE": "11.82",
      "PB": "2.23",
      "Div": "2.87"
  },
  {
      "Date": "4-Jun-03",
      "PE": "11.66",
      "PB": "2.2",
      "Div": "2.91"
  },
  {
      "Date": "3-Jun-03",
      "PE": "11.54",
      "PB": "2.18",
      "Div": "2.94"
  },
  {
      "Date": "2-Jun-03",
      "PE": "11.59",
      "PB": "2.19",
      "Div": "2.93"
  },
  {
      "Date": "30-May-03",
      "PE": "11.65",
      "PB": "2.17",
      "Div": "2.95"
  },
  {
      "Date": "29-May-03",
      "PE": "11.61",
      "PB": "2.16",
      "Div": "2.97"
  },
  {
      "Date": "28-May-03",
      "PE": "11.31",
      "PB": "2.1",
      "Div": "3.04"
  },
  {
      "Date": "27-May-03",
      "PE": "11.47",
      "PB": "2.13",
      "Div": "3"
  },
  {
      "Date": "26-May-03",
      "PE": "11.37",
      "PB": "2.12",
      "Div": "3.03"
  },
  {
      "Date": "23-May-03",
      "PE": "11.2",
      "PB": "2.09",
      "Div": "3.07"
  },
  {
      "Date": "22-May-03",
      "PE": "11.15",
      "PB": "2.08",
      "Div": "3.09"
  },
  {
      "Date": "21-May-03",
      "PE": "11.21",
      "PB": "2.09",
      "Div": "3.07"
  },
  {
      "Date": "20-May-03",
      "PE": "11.25",
      "PB": "2.09",
      "Div": "3.06"
  },
  {
      "Date": "19-May-03",
      "PE": "11.19",
      "PB": "2.08",
      "Div": "3.08"
  },
  {
      "Date": "16-May-03",
      "PE": "11.26",
      "PB": "2.1",
      "Div": "3.06"
  },
  {
      "Date": "15-May-03",
      "PE": "11.11",
      "PB": "2.07",
      "Div": "3.1"
  },
  {
      "Date": "14-May-03",
      "PE": "11.02",
      "PB": "2.05",
      "Div": "3.12"
  },
  {
      "Date": "13-May-03",
      "PE": "10.93",
      "PB": "2.03",
      "Div": "3.15"
  },
  {
      "Date": "12-May-03",
      "PE": "10.84",
      "PB": "2.02",
      "Div": "3.18"
  },
  {
      "Date": "9-May-03",
      "PE": "10.86",
      "PB": "2.02",
      "Div": "3.17"
  },
  {
      "Date": "8-May-03",
      "PE": "10.9",
      "PB": "2.03",
      "Div": "3.16"
  },
  {
      "Date": "7-May-03",
      "PE": "11",
      "PB": "2.05",
      "Div": "3.13"
  },
  {
      "Date": "6-May-03",
      "PE": "11.02",
      "PB": "2.05",
      "Div": "3.12"
  },
  {
      "Date": "5-May-03",
      "PE": "10.94",
      "PB": "2.04",
      "Div": "3.15"
  },
  {
      "Date": "2-May-03",
      "PE": "10.86",
      "PB": "2.02",
      "Div": "3.17"
  },
  {
      "Date": "30-Apr-03",
      "PE": "13.14",
      "PB": "2.11",
      "Div": "2.98"
  },
  {
      "Date": "29-Apr-03",
      "PE": "12.73",
      "PB": "2.05",
      "Div": "3.07"
  },
  {
      "Date": "28-Apr-03",
      "PE": "12.7",
      "PB": "2.04",
      "Div": "3.08"
  },
  {
      "Date": "25-Apr-03",
      "PE": "12.63",
      "PB": "2.03",
      "Div": "3.1"
  },
  {
      "Date": "24-Apr-03",
      "PE": "12.7",
      "PB": "2.04",
      "Div": "3.08"
  },
  {
      "Date": "23-Apr-03",
      "PE": "12.76",
      "PB": "2.05",
      "Div": "3.07"
  },
  {
      "Date": "22-Apr-03",
      "PE": "12.89",
      "PB": "2.07",
      "Div": "3.04"
  },
  {
      "Date": "21-Apr-03",
      "PE": "12.94",
      "PB": "2.08",
      "Div": "3.02"
  },
  {
      "Date": "17-Apr-03",
      "PE": "12.85",
      "PB": "2.07",
      "Div": "3.04"
  },
  {
      "Date": "16-Apr-03",
      "PE": "13.09",
      "PB": "2.1",
      "Div": "2.99"
  },
  {
      "Date": "15-Apr-03",
      "PE": "12.99",
      "PB": "2.09",
      "Div": "3.01"
  },
  {
      "Date": "11-Apr-03",
      "PE": "12.97",
      "PB": "2.09",
      "Div": "3.02"
  },
  {
      "Date": "10-Apr-03",
      "PE": "13.14",
      "PB": "2.11",
      "Div": "2.98"
  },
  {
      "Date": "9-Apr-03",
      "PE": "13.73",
      "PB": "2.21",
      "Div": "2.85"
  },
  {
      "Date": "8-Apr-03",
      "PE": "13.91",
      "PB": "2.24",
      "Div": "2.81"
  },
  {
      "Date": "7-Apr-03",
      "PE": "14.09",
      "PB": "2.26",
      "Div": "2.78"
  },
  {
      "Date": "4-Apr-03",
      "PE": "13.89",
      "PB": "2.23",
      "Div": "2.82"
  },
  {
      "Date": "3-Apr-03",
      "PE": "13.78",
      "PB": "2.22",
      "Div": "2.84"
  },
  {
      "Date": "2-Apr-03",
      "PE": "13.65",
      "PB": "2.19",
      "Div": "2.87"
  },
  {
      "Date": "1-Apr-03",
      "PE": "13.44",
      "PB": "2.16",
      "Div": "2.91"
  },
  {
      "Date": "31-Mar-03",
      "PE": "13.36",
      "PB": "2.15",
      "Div": "2.93"
  },
  {
      "Date": "28-Mar-03",
      "PE": "13.67",
      "PB": "2.2",
      "Div": "2.86"
  },
  {
      "Date": "27-Mar-03",
      "PE": "13.69",
      "PB": "2.2",
      "Div": "2.86"
  },
  {
      "Date": "26-Mar-03",
      "PE": "13.85",
      "PB": "2.23",
      "Div": "2.83"
  },
  {
      "Date": "25-Mar-03",
      "PE": "13.81",
      "PB": "2.22",
      "Div": "2.83"
  },
  {
      "Date": "24-Mar-03",
      "PE": "13.85",
      "PB": "2.23",
      "Div": "2.83"
  },
  {
      "Date": "22-Mar-03",
      "PE": "14.17",
      "PB": "2.28",
      "Div": "2.76"
  },
  {
      "Date": "21-Mar-03",
      "PE": "14.08",
      "PB": "2.26",
      "Div": "2.78"
  },
  {
      "Date": "20-Mar-03",
      "PE": "14",
      "PB": "2.25",
      "Div": "2.79"
  },
  {
      "Date": "19-Mar-03",
      "PE": "13.71",
      "PB": "2.2",
      "Div": "2.85"
  },
  {
      "Date": "17-Mar-03",
      "PE": "13.51",
      "PB": "2.18",
      "Div": "2.63"
  },
  {
      "Date": "13-Mar-03",
      "PE": "13.6",
      "PB": "2.19",
      "Div": "2.61"
  },
  {
      "Date": "12-Mar-03",
      "PE": "13.63",
      "PB": "2.2",
      "Div": "2.61"
  },
  {
      "Date": "11-Mar-03",
      "PE": "13.81",
      "PB": "2.23",
      "Div": "2.57"
  },
  {
      "Date": "10-Mar-03",
      "PE": "13.7",
      "PB": "2.21",
      "Div": "2.59"
  },
  {
      "Date": "7-Mar-03",
      "PE": "13.84",
      "PB": "2.23",
      "Div": "2.57"
  },
  {
      "Date": "6-Mar-03",
      "PE": "14.03",
      "PB": "2.26",
      "Div": "2.53"
  },
  {
      "Date": "5-Mar-03",
      "PE": "14.16",
      "PB": "2.28",
      "Div": "2.51"
  },
  {
      "Date": "4-Mar-03",
      "PE": "14.24",
      "PB": "2.3",
      "Div": "2.5"
  },
  {
      "Date": "3-Mar-03",
      "PE": "14.36",
      "PB": "2.32",
      "Div": "2.47"
  },
  {
      "Date": "28-Feb-03",
      "PE": "14.42",
      "PB": "2.33",
      "Div": "2.46"
  },
  {
      "Date": "27-Feb-03",
      "PE": "14.28",
      "PB": "2.3",
      "Div": "2.49"
  },
  {
      "Date": "26-Feb-03",
      "PE": "14.24",
      "PB": "2.3",
      "Div": "2.5"
  },
  {
      "Date": "25-Feb-03",
      "PE": "14.32",
      "PB": "2.31",
      "Div": "2.48"
  },
  {
      "Date": "24-Feb-03",
      "PE": "14.51",
      "PB": "2.34",
      "Div": "2.45"
  },
  {
      "Date": "21-Feb-03",
      "PE": "14.46",
      "PB": "2.33",
      "Div": "2.46"
  },
  {
      "Date": "20-Feb-03",
      "PE": "14.45",
      "PB": "2.33",
      "Div": "2.46"
  },
  {
      "Date": "19-Feb-03",
      "PE": "14.43",
      "PB": "2.33",
      "Div": "2.46"
  },
  {
      "Date": "18-Feb-03",
      "PE": "14.37",
      "PB": "2.32",
      "Div": "2.47"
  },
  {
      "Date": "17-Feb-03",
      "PE": "14.35",
      "PB": "2.31",
      "Div": "2.48"
  },
  {
      "Date": "14-Feb-03",
      "PE": "14.05",
      "PB": "2.27",
      "Div": "2.53"
  },
  {
      "Date": "12-Feb-03",
      "PE": "14.17",
      "PB": "2.28",
      "Div": "2.51"
  },
  {
      "Date": "11-Feb-03",
      "PE": "14.21",
      "PB": "2.29",
      "Div": "2.5"
  },
  {
      "Date": "10-Feb-03",
      "PE": "14.22",
      "PB": "2.29",
      "Div": "2.5"
  },
  {
      "Date": "7-Feb-03",
      "PE": "14.34",
      "PB": "2.31",
      "Div": "2.48"
  },
  {
      "Date": "6-Feb-03",
      "PE": "14.42",
      "PB": "2.33",
      "Div": "2.46"
  },
  {
      "Date": "5-Feb-03",
      "PE": "14.21",
      "PB": "2.29",
      "Div": "2.5"
  },
  {
      "Date": "4-Feb-03",
      "PE": "14.31",
      "PB": "2.31",
      "Div": "2.48"
  },
  {
      "Date": "3-Feb-03",
      "PE": "14.31",
      "PB": "2.31",
      "Div": "2.48"
  },
  {
      "Date": "31-Jan-03",
      "PE": "14.13",
      "PB": "2.28",
      "Div": "2.51"
  },
  {
      "Date": "30-Jan-03",
      "PE": "14.03",
      "PB": "2.26",
      "Div": "2.53"
  },
  {
      "Date": "29-Jan-03",
      "PE": "14.07",
      "PB": "2.27",
      "Div": "2.53"
  },
  {
      "Date": "28-Jan-03",
      "PE": "14.19",
      "PB": "2.29",
      "Div": "2.5"
  },
  {
      "Date": "27-Jan-03",
      "PE": "14.07",
      "PB": "2.27",
      "Div": "2.52"
  },
  {
      "Date": "24-Jan-03",
      "PE": "14.32",
      "PB": "2.31",
      "Div": "2.48"
  },
  {
      "Date": "23-Jan-03",
      "PE": "14.52",
      "PB": "2.34",
      "Div": "2.45"
  },
  {
      "Date": "22-Jan-03",
      "PE": "14.69",
      "PB": "2.37",
      "Div": "2.42"
  },
  {
      "Date": "21-Jan-03",
      "PE": "14.62",
      "PB": "2.36",
      "Div": "2.43"
  },
  {
      "Date": "20-Jan-03",
      "PE": "14.6",
      "PB": "2.35",
      "Div": "2.43"
  },
  {
      "Date": "17-Jan-03",
      "PE": "14.74",
      "PB": "2.38",
      "Div": "2.41"
  },
  {
      "Date": "16-Jan-03",
      "PE": "14.76",
      "PB": "2.38",
      "Div": "2.41"
  },
  {
      "Date": "15-Jan-03",
      "PE": "14.72",
      "PB": "2.37",
      "Div": "2.41"
  },
  {
      "Date": "14-Jan-03",
      "PE": "14.63",
      "PB": "2.36",
      "Div": "2.43"
  },
  {
      "Date": "13-Jan-03",
      "PE": "14.56",
      "PB": "2.35",
      "Div": "2.44"
  },
  {
      "Date": "10-Jan-03",
      "PE": "14.65",
      "PB": "2.36",
      "Div": "2.43"
  },
  {
      "Date": "9-Jan-03",
      "PE": "14.88",
      "PB": "2.4",
      "Div": "2.39"
  },
  {
      "Date": "8-Jan-03",
      "PE": "14.77",
      "PB": "2.38",
      "Div": "2.41"
  },
  {
      "Date": "7-Jan-03",
      "PE": "14.67",
      "PB": "2.37",
      "Div": "2.42"
  },
  {
      "Date": "6-Jan-03",
      "PE": "14.71",
      "PB": "2.37",
      "Div": "2.42"
  },
  {
      "Date": "3-Jan-03",
      "PE": "14.78",
      "PB": "2.38",
      "Div": "2.4"
  },
  {
      "Date": "2-Jan-03",
      "PE": "14.82",
      "PB": "2.39",
      "Div": "2.4"
  },
  {
      "Date": "1-Jan-03",
      "PE": "14.92",
      "PB": "2.41",
      "Div": "2.38"
  },
  {
      "Date": "31-Dec-02",
      "PE": "14.83",
      "PB": "2.39",
      "Div": "2.4"
  },
  {
      "Date": "30-Dec-02",
      "PE": "14.81",
      "PB": "2.39",
      "Div": "2.4"
  },
  {
      "Date": "27-Dec-02",
      "PE": "14.9",
      "PB": "2.4",
      "Div": "2.39"
  },
  {
      "Date": "26-Dec-02",
      "PE": "14.85",
      "PB": "2.39",
      "Div": "2.39"
  },
  {
      "Date": "24-Dec-02",
      "PE": "14.72",
      "PB": "2.37",
      "Div": "2.41"
  },
  {
      "Date": "23-Dec-02",
      "PE": "14.59",
      "PB": "2.35",
      "Div": "2.43"
  },
  {
      "Date": "20-Dec-02",
      "PE": "14.64",
      "PB": "2.36",
      "Div": "2.43"
  },
  {
      "Date": "19-Dec-02",
      "PE": "14.59",
      "PB": "2.35",
      "Div": "2.43"
  },
  {
      "Date": "18-Dec-02",
      "PE": "14.62",
      "PB": "2.36",
      "Div": "2.43"
  },
  {
      "Date": "17-Dec-02",
      "PE": "14.56",
      "PB": "2.35",
      "Div": "2.44"
  },
  {
      "Date": "16-Dec-02",
      "PE": "14.63",
      "PB": "2.36",
      "Div": "2.43"
  },
  {
      "Date": "13-Dec-02",
      "PE": "14.73",
      "PB": "2.38",
      "Div": "2.41"
  },
  {
      "Date": "12-Dec-02",
      "PE": "14.61",
      "PB": "2.36",
      "Div": "2.43"
  },
  {
      "Date": "11-Dec-02",
      "PE": "14.51",
      "PB": "2.34",
      "Div": "2.45"
  },
  {
      "Date": "10-Dec-02",
      "PE": "14.43",
      "PB": "2.33",
      "Div": "2.46"
  },
  {
      "Date": "9-Dec-02",
      "PE": "14.36",
      "PB": "2.32",
      "Div": "2.47"
  },
  {
      "Date": "6-Dec-02",
      "PE": "14.51",
      "PB": "2.34",
      "Div": "2.45"
  },
  {
      "Date": "5-Dec-02",
      "PE": "14.19",
      "PB": "2.29",
      "Div": "2.5"
  },
  {
      "Date": "4-Dec-02",
      "PE": "14.06",
      "PB": "2.27",
      "Div": "2.53"
  },
  {
      "Date": "3-Dec-02",
      "PE": "14.31",
      "PB": "2.31",
      "Div": "2.48"
  },
  {
      "Date": "2-Dec-02",
      "PE": "14.48",
      "PB": "2.34",
      "Div": "2.45"
  },
  {
      "Date": "29-Nov-02",
      "PE": "14.24",
      "PB": "2.3",
      "Div": "2.49"
  },
  {
      "Date": "28-Nov-02",
      "PE": "14.24",
      "PB": "2.3",
      "Div": "2.5"
  },
  {
      "Date": "27-Nov-02",
      "PE": "13.98",
      "PB": "2.26",
      "Div": "2.54"
  },
  {
      "Date": "26-Nov-02",
      "PE": "14.05",
      "PB": "2.27",
      "Div": "2.53"
  },
  {
      "Date": "25-Nov-02",
      "PE": "13.92",
      "PB": "2.24",
      "Div": "2.55"
  },
  {
      "Date": "22-Nov-02",
      "PE": "13.84",
      "PB": "2.23",
      "Div": "2.57"
  },
  {
      "Date": "21-Nov-02",
      "PE": "15.34",
      "PB": "2.57",
      "Div": "2.08"
  },
  {
      "Date": "20-Nov-02",
      "PE": "15.23",
      "PB": "2.55",
      "Div": "2.1"
  },
  {
      "Date": "18-Nov-02",
      "PE": "15.16",
      "PB": "2.54",
      "Div": "2.11"
  },
  {
      "Date": "15-Nov-02",
      "PE": "15.06",
      "PB": "2.53",
      "Div": "2.12"
  },
  {
      "Date": "14-Nov-02",
      "PE": "14.78",
      "PB": "2.48",
      "Div": "2.16"
  },
  {
      "Date": "13-Nov-02",
      "PE": "14.64",
      "PB": "2.46",
      "Div": "2.18"
  },
  {
      "Date": "12-Nov-02",
      "PE": "14.6",
      "PB": "2.45",
      "Div": "2.19"
  },
  {
      "Date": "11-Nov-02",
      "PE": "14.51",
      "PB": "2.43",
      "Div": "2.2"
  },
  {
      "Date": "8-Nov-02",
      "PE": "14.55",
      "PB": "2.44",
      "Div": "2.2"
  },
  {
      "Date": "7-Nov-02",
      "PE": "14.61",
      "PB": "2.45",
      "Div": "2.19"
  },
  {
      "Date": "5-Nov-02",
      "PE": "14.63",
      "PB": "2.45",
      "Div": "2.19"
  },
  {
      "Date": "4-Nov-02",
      "PE": "14.63",
      "PB": "2.45",
      "Div": "2.19"
  },
  {
      "Date": "1-Nov-02",
      "PE": "14.47",
      "PB": "2.43",
      "Div": "2.21"
  },
  {
      "Date": "31-Oct-02",
      "PE": "14.47",
      "PB": "2.43",
      "Div": "2.21"
  },
  {
      "Date": "30-Oct-02",
      "PE": "14.26",
      "PB": "2.39",
      "Div": "2.24"
  },
  {
      "Date": "29-Oct-02",
      "PE": "14.25",
      "PB": "2.39",
      "Div": "2.24"
  },
  {
      "Date": "28-Oct-02",
      "PE": "14.03",
      "PB": "2.35",
      "Div": "2.28"
  },
  {
      "Date": "25-Oct-02",
      "PE": "13.83",
      "PB": "2.32",
      "Div": "2.31"
  },
  {
      "Date": "24-Oct-02",
      "PE": "14.05",
      "PB": "2.36",
      "Div": "2.28"
  },
  {
      "Date": "23-Oct-02",
      "PE": "14.2",
      "PB": "2.38",
      "Div": "2.25"
  },
  {
      "Date": "22-Oct-02",
      "PE": "14.28",
      "PB": "2.39",
      "Div": "2.24"
  },
  {
      "Date": "21-Oct-02",
      "PE": "14.35",
      "PB": "2.41",
      "Div": "2.23"
  },
  {
      "Date": "18-Oct-02",
      "PE": "14.41",
      "PB": "2.42",
      "Div": "2.22"
  },
  {
      "Date": "17-Oct-02",
      "PE": "14.44",
      "PB": "2.42",
      "Div": "2.21"
  },
  {
      "Date": "16-Oct-02",
      "PE": "14.44",
      "PB": "2.42",
      "Div": "2.21"
  },
  {
      "Date": "14-Oct-02",
      "PE": "14.42",
      "PB": "2.42",
      "Div": "2.22"
  },
  {
      "Date": "11-Oct-02",
      "PE": "14.4",
      "PB": "2.42",
      "Div": "2.22"
  },
  {
      "Date": "10-Oct-02",
      "PE": "14.22",
      "PB": "2.38",
      "Div": "2.25"
  },
  {
      "Date": "9-Oct-02",
      "PE": "14.21",
      "PB": "2.38",
      "Div": "2.25"
  },
  {
      "Date": "8-Oct-02",
      "PE": "14.3",
      "PB": "2.4",
      "Div": "2.24"
  },
  {
      "Date": "7-Oct-02",
      "PE": "14.21",
      "PB": "2.38",
      "Div": "2.25"
  },
  {
      "Date": "4-Oct-02",
      "PE": "14.11",
      "PB": "2.37",
      "Div": "2.27"
  },
  {
      "Date": "3-Oct-02",
      "PE": "14.11",
      "PB": "2.37",
      "Div": "2.27"
  },
  {
      "Date": "1-Oct-02",
      "PE": "14.21",
      "PB": "2.38",
      "Div": "2.25"
  },
  {
      "Date": "30-Sep-02",
      "PE": "14.33",
      "PB": "2.4",
      "Div": "2.23"
  },
  {
      "Date": "27-Sep-02",
      "PE": "14.53",
      "PB": "2.44",
      "Div": "2.2"
  },
  {
      "Date": "26-Sep-02",
      "PE": "14.43",
      "PB": "2.42",
      "Div": "2.22"
  },
  {
      "Date": "25-Sep-02",
      "PE": "14.43",
      "PB": "2.42",
      "Div": "2.22"
  },
  {
      "Date": "24-Sep-02",
      "PE": "14.38",
      "PB": "2.41",
      "Div": "2.22"
  },
  {
      "Date": "23-Sep-02",
      "PE": "14.44",
      "PB": "2.42",
      "Div": "2.21"
  },
  {
      "Date": "20-Sep-02",
      "PE": "14.43",
      "PB": "2.42",
      "Div": "2.22"
  },
  {
      "Date": "19-Sep-02",
      "PE": "14.52",
      "PB": "2.44",
      "Div": "2.2"
  },
  {
      "Date": "18-Sep-02",
      "PE": "14.63",
      "PB": "2.45",
      "Div": "2.18"
  },
  {
      "Date": "17-Sep-02",
      "PE": "14.8",
      "PB": "2.48",
      "Div": "2.16"
  },
  {
      "Date": "16-Sep-02",
      "PE": "14.67",
      "PB": "2.46",
      "Div": "2.18"
  },
  {
      "Date": "13-Sep-02",
      "PE": "14.76",
      "PB": "2.48",
      "Div": "2.17"
  },
  {
      "Date": "12-Sep-02",
      "PE": "14.9",
      "PB": "2.5",
      "Div": "2.15"
  },
  {
      "Date": "11-Sep-02",
      "PE": "14.86",
      "PB": "3.02",
      "Div": "1.84"
  },
  {
      "Date": "9-Sep-02",
      "PE": "14.86",
      "PB": "3.02",
      "Div": "1.84"
  },
  {
      "Date": "6-Sep-02",
      "PE": "14.81",
      "PB": "3.01",
      "Div": "1.96"
  },
  {
      "Date": "5-Sep-02",
      "PE": "15.01",
      "PB": "3.05",
      "Div": "1.97"
  },
  {
      "Date": "4-Sep-02",
      "PE": "14.98",
      "PB": "3.05",
      "Div": "1.94"
  },
  {
      "Date": "3-Sep-02",
      "PE": "14.9",
      "PB": "3.03",
      "Div": "1.97"
  },
  {
      "Date": "2-Sep-02",
      "PE": "15.08",
      "PB": "3.07",
      "Div": "1.95"
  },
  {
      "Date": "30-Aug-02",
      "PE": "15.04",
      "PB": "3.06",
      "Div": "1.95"
  },
  {
      "Date": "29-Aug-02",
      "PE": "14.69",
      "PB": "2.99",
      "Div": "2.02"
  },
  {
      "Date": "28-Aug-02",
      "PE": "14.67",
      "PB": "2.98",
      "Div": "2.01"
  },
  {
      "Date": "27-Aug-02",
      "PE": "14.7",
      "PB": "2.99",
      "Div": "2.01"
  },
  {
      "Date": "26-Aug-02",
      "PE": "14.86",
      "PB": "3.02",
      "Div": "1.98"
  },
  {
      "Date": "23-Aug-02",
      "PE": "14.81",
      "PB": "3.01",
      "Div": "2.02"
  },
  {
      "Date": "22-Aug-02",
      "PE": "14.66",
      "PB": "2.98",
      "Div": "2.04"
  },
  {
      "Date": "21-Aug-02",
      "PE": "14.71",
      "PB": "2.98",
      "Div": "2.14"
  },
  {
      "Date": "20-Aug-02",
      "PE": "14.71",
      "PB": "2.99",
      "Div": "2.14"
  },
  {
      "Date": "19-Aug-02",
      "PE": "14.58",
      "PB": "2.96",
      "Div": "2.16"
  },
  {
      "Date": "16-Aug-02",
      "PE": "14.57",
      "PB": "2.96",
      "Div": "2.16"
  },
  {
      "Date": "14-Aug-02",
      "PE": "14.43",
      "PB": "2.93",
      "Div": "2.16"
  },
  {
      "Date": "13-Aug-02",
      "PE": "14.52",
      "PB": "2.95",
      "Div": "2.12"
  },
  {
      "Date": "12-Aug-02",
      "PE": "14.43",
      "PB": "2.93",
      "Div": "2.13"
  },
  {
      "Date": "9-Aug-02",
      "PE": "14.31",
      "PB": "2.9",
      "Div": "2.15"
  },
  {
      "Date": "8-Aug-02",
      "PE": "14.19",
      "PB": "2.88",
      "Div": "2.17"
  },
  {
      "Date": "7-Aug-02",
      "PE": "14.41",
      "PB": "2.42",
      "Div": "2.22"
  },
  {
      "Date": "6-Aug-02",
      "PE": "14.38",
      "PB": "2.41",
      "Div": "2.22"
  },
  {
      "Date": "5-Aug-02",
      "PE": "14.33",
      "PB": "2.4",
      "Div": "2.23"
  },
  {
      "Date": "2-Aug-02",
      "PE": "14.2",
      "PB": "2.38",
      "Div": "2.25"
  },
  {
      "Date": "1-Aug-02",
      "PE": "14.24",
      "PB": "2.39",
      "Div": "2.25"
  },
  {
      "Date": "31-Jul-02",
      "PE": "14.26",
      "PB": "2.39",
      "Div": "2.24"
  },
  {
      "Date": "30-Jul-02",
      "PE": "14.29",
      "PB": "2.4",
      "Div": "2.24"
  },
  {
      "Date": "29-Jul-02",
      "PE": "14.45",
      "PB": "2.42",
      "Div": "2.21"
  },
  {
      "Date": "26-Jul-02",
      "PE": "14.48",
      "PB": "2.43",
      "Div": "2.21"
  },
  {
      "Date": "25-Jul-02",
      "PE": "14.9",
      "PB": "2.5",
      "Div": "2.15"
  },
  {
      "Date": "24-Jul-02",
      "PE": "14.93",
      "PB": "2.5",
      "Div": "2.14"
  },
  {
      "Date": "23-Jul-02",
      "PE": "15.2",
      "PB": "2.55",
      "Div": "2.1"
  },
  {
      "Date": "22-Jul-02",
      "PE": "15.05",
      "PB": "2.52",
      "Div": "2.12"
  },
  {
      "Date": "19-Jul-02",
      "PE": "15.41",
      "PB": "2.58",
      "Div": "2.08"
  },
  {
      "Date": "18-Jul-02",
      "PE": "15.49",
      "PB": "2.6",
      "Div": "2.06"
  },
  {
      "Date": "17-Jul-02",
      "PE": "15.36",
      "PB": "2.58",
      "Div": "2.08"
  },
  {
      "Date": "16-Jul-02",
      "PE": "15.41",
      "PB": "2.58",
      "Div": "2.08"
  },
  {
      "Date": "15-Jul-02",
      "PE": "15.59",
      "PB": "2.61",
      "Div": "2.05"
  },
  {
      "Date": "12-Jul-02",
      "PE": "15.74",
      "PB": "2.64",
      "Div": "2.03"
  },
  {
      "Date": "11-Jul-02",
      "PE": "15.71",
      "PB": "2.64",
      "Div": "2.03"
  },
  {
      "Date": "10-Jul-02",
      "PE": "15.94",
      "PB": "2.67",
      "Div": "2.01"
  },
  {
      "Date": "9-Jul-02",
      "PE": "16.07",
      "PB": "2.69",
      "Div": "1.99"
  },
  {
      "Date": "8-Jul-02",
      "PE": "16.09",
      "PB": "2.7",
      "Div": "1.99"
  },
  {
      "Date": "5-Jul-02",
      "PE": "15.97",
      "PB": "2.68",
      "Div": "2"
  },
  {
      "Date": "4-Jul-02",
      "PE": "15.92",
      "PB": "2.67",
      "Div": "2.01"
  },
  {
      "Date": "3-Jul-02",
      "PE": "15.91",
      "PB": "2.67",
      "Div": "2.01"
  },
  {
      "Date": "2-Jul-02",
      "PE": "15.88",
      "PB": "2.66",
      "Div": "2.01"
  },
  {
      "Date": "1-Jul-02",
      "PE": "15.9",
      "PB": "2.67",
      "Div": "2.01"
  },
  {
      "Date": "28-Jun-02",
      "PE": "15.73",
      "PB": "3.2",
      "Div": "1.34"
  },
  {
      "Date": "27-Jun-02",
      "PE": "15.56",
      "PB": "3.18",
      "Div": "1.35"
  },
  {
      "Date": "26-Jun-02",
      "PE": "15.48",
      "PB": "3.17",
      "Div": "1.35"
  },
  {
      "Date": "25-Jun-02",
      "PE": "15.64",
      "PB": "3.2",
      "Div": "1.32"
  },
  {
      "Date": "24-Jun-02",
      "PE": "15.74",
      "PB": "3.22",
      "Div": "1.32"
  },
  {
      "Date": "21-Jun-02",
      "PE": "15.75",
      "PB": "3.22",
      "Div": "1.31"
  },
  {
      "Date": "20-Jun-02",
      "PE": "15.86",
      "PB": "3.24",
      "Div": "1.36"
  },
  {
      "Date": "19-Jun-02",
      "PE": "16.4",
      "PB": "3.24",
      "Div": "1.35"
  },
  {
      "Date": "18-Jun-02",
      "PE": "16.58",
      "PB": "3.28",
      "Div": "1.33"
  },
  {
      "Date": "17-Jun-02",
      "PE": "16.8",
      "PB": "3.32",
      "Div": "1.31"
  },
  {
      "Date": "14-Jun-02",
      "PE": "16.74",
      "PB": "3.3",
      "Div": "1.31"
  },
  {
      "Date": "13-Jun-02",
      "PE": "16.72",
      "PB": "3.27",
      "Div": "1.4"
  },
  {
      "Date": "12-Jun-02",
      "PE": "16.87",
      "PB": "3.3",
      "Div": "1.39"
  },
  {
      "Date": "11-Jun-02",
      "PE": "16.93",
      "PB": "3.31",
      "Div": "1.35"
  },
  {
      "Date": "10-Jun-02",
      "PE": "16.51",
      "PB": "3.23",
      "Div": "1.35"
  },
  {
      "Date": "7-Jun-02",
      "PE": "16.18",
      "PB": "3.16",
      "Div": "1.38"
  },
  {
      "Date": "6-Jun-02",
      "PE": "16.42",
      "PB": "3.21",
      "Div": "1.36"
  },
  {
      "Date": "5-Jun-02",
      "PE": "16.78",
      "PB": "3.19",
      "Div": "1.36"
  },
  {
      "Date": "4-Jun-02",
      "PE": "16.48",
      "PB": "3.13",
      "Div": "1.38"
  },
  {
      "Date": "3-Jun-02",
      "PE": "16.39",
      "PB": "3.11",
      "Div": "1.39"
  },
  {
      "Date": "31-May-02",
      "PE": "16.19",
      "PB": "3.07",
      "Div": "1.39"
  },
  {
      "Date": "30-May-02",
      "PE": "16.72",
      "PB": "2.81",
      "Div": "1.48"
  },
  {
      "Date": "29-May-02",
      "PE": "16.54",
      "PB": "2.72",
      "Div": "1.44"
  },
  {
      "Date": "28-May-02",
      "PE": "16.49",
      "PB": "2.71",
      "Div": "1.41"
  },
  {
      "Date": "27-May-02",
      "PE": "16.88",
      "PB": "2.78",
      "Div": "1.33"
  },
  {
      "Date": "24-May-02",
      "PE": "16.95",
      "PB": "2.79",
      "Div": "1.32"
  },
  {
      "Date": "23-May-02",
      "PE": "16.31",
      "PB": "2.68",
      "Div": "1.5"
  },
  {
      "Date": "22-May-02",
      "PE": "16.6",
      "PB": "2.73",
      "Div": "1.47"
  },
  {
      "Date": "21-May-02",
      "PE": "16.82",
      "PB": "2.7",
      "Div": "1.47"
  },
  {
      "Date": "20-May-02",
      "PE": "17.23",
      "PB": "2.76",
      "Div": "1.41"
  },
  {
      "Date": "17-May-02",
      "PE": "17.49",
      "PB": "2.81",
      "Div": "1.39"
  },
  {
      "Date": "16-May-02",
      "PE": "17.63",
      "PB": "2.78",
      "Div": "1.39"
  },
  {
      "Date": "15-May-02",
      "PE": "17.87",
      "PB": "2.82",
      "Div": "1.37"
  },
  {
      "Date": "14-May-02",
      "PE": "17.99",
      "PB": "2.84",
      "Div": "1.36"
  },
  {
      "Date": "13-May-02",
      "PE": "18.16",
      "PB": "2.86",
      "Div": "1.36"
  },
  {
      "Date": "10-May-02",
      "PE": "18.36",
      "PB": "2.85",
      "Div": "1.36"
  },
  {
      "Date": "9-May-02",
      "PE": "18.54",
      "PB": "2.88",
      "Div": "1.37"
  },
  {
      "Date": "8-May-02",
      "PE": "18.38",
      "PB": "2.86",
      "Div": "1.38"
  },
  {
      "Date": "7-May-02",
      "PE": "18.26",
      "PB": "2.84",
      "Div": "1.39"
  },
  {
      "Date": "6-May-02",
      "PE": "18.1",
      "PB": "2.81",
      "Div": "1.38"
  },
  {
      "Date": "3-May-02",
      "PE": "18.04",
      "PB": "2.8",
      "Div": "1.38"
  },
  {
      "Date": "2-May-02",
      "PE": "18.08",
      "PB": "2.93",
      "Div": "1.41"
  },
  {
      "Date": "30-Apr-02",
      "PE": "17.86",
      "PB": "2.83",
      "Div": "1.42"
  },
  {
      "Date": "29-Apr-02",
      "PE": "17.69",
      "PB": "2.8",
      "Div": "1.44"
  },
  {
      "Date": "26-Apr-02",
      "PE": "17.9",
      "PB": "2.83",
      "Div": "1.41"
  },
  {
      "Date": "25-Apr-02",
      "PE": "17.85",
      "PB": "2.82",
      "Div": "1.42"
  },
  {
      "Date": "24-Apr-02",
      "PE": "18.16",
      "PB": "2.86",
      "Div": "1.4"
  },
  {
      "Date": "23-Apr-02",
      "PE": "18.05",
      "PB": "2.88",
      "Div": "1.41"
  },
  {
      "Date": "22-Apr-02",
      "PE": "18.02",
      "PB": "2.87",
      "Div": "1.41"
  },
  {
      "Date": "19-Apr-02",
      "PE": "18.02",
      "PB": "2.86",
      "Div": "1.42"
  },
  {
      "Date": "18-Apr-02",
      "PE": "18.49",
      "PB": "2.94",
      "Div": "1.38"
  },
  {
      "Date": "17-Apr-02",
      "PE": "17.8",
      "PB": "2.88",
      "Div": "1.38"
  },
  {
      "Date": "16-Apr-02",
      "PE": "17.7",
      "PB": "2.86",
      "Div": "1.39"
  },
  {
      "Date": "15-Apr-02",
      "PE": "17.94",
      "PB": "2.9",
      "Div": "1.37"
  },
  {
      "Date": "12-Apr-02",
      "PE": "18.14",
      "PB": "2.93",
      "Div": "1.36"
  },
  {
      "Date": "11-Apr-02",
      "PE": "18.09",
      "PB": "2.93",
      "Div": "1.36"
  },
  {
      "Date": "10-Apr-02",
      "PE": "18.01",
      "PB": "2.91",
      "Div": "1.37"
  },
  {
      "Date": "9-Apr-02",
      "PE": "17.78",
      "PB": "2.88",
      "Div": "1.39"
  },
  {
      "Date": "8-Apr-02",
      "PE": "17.91",
      "PB": "2.9",
      "Div": "1.38"
  },
  {
      "Date": "5-Apr-02",
      "PE": "18.3",
      "PB": "2.94",
      "Div": "1.21"
  },
  {
      "Date": "4-Apr-02",
      "PE": "18.36",
      "PB": "2.95",
      "Div": "1.33"
  },
  {
      "Date": "3-Apr-02",
      "PE": "18",
      "PB": "2.89",
      "Div": "1.35"
  },
  {
      "Date": "2-Apr-02",
      "PE": "18.22",
      "PB": "2.93",
      "Div": "1.33"
  },
  {
      "Date": "1-Apr-02",
      "PE": "18.25",
      "PB": "2.93",
      "Div": "1.33"
  },
  {
      "Date": "28-Mar-02",
      "PE": "18.1",
      "PB": "2.91",
      "Div": "1.34"
  },
  {
      "Date": "27-Mar-02",
      "PE": "18",
      "PB": "2.89",
      "Div": "1.35"
  },
  {
      "Date": "26-Mar-02",
      "PE": "17.99",
      "PB": "2.89",
      "Div": "1.35"
  },
  {
      "Date": "22-Mar-02",
      "PE": "18.24",
      "PB": "2.93",
      "Div": "1.33"
  },
  {
      "Date": "21-Mar-02",
      "PE": "18.33",
      "PB": "2.95",
      "Div": "1.32"
  },
  {
      "Date": "20-Mar-02",
      "PE": "18.51",
      "PB": "2.98",
      "Div": "1.31"
  },
  {
      "Date": "19-Mar-02",
      "PE": "18.46",
      "PB": "2.97",
      "Div": "1.31"
  },
  {
      "Date": "18-Mar-02",
      "PE": "18.73",
      "PB": "3.01",
      "Div": "1.29"
  },
  {
      "Date": "15-Mar-02",
      "PE": "18.74",
      "PB": "3.01",
      "Div": "1.29"
  },
  {
      "Date": "14-Mar-02",
      "PE": "18.58",
      "PB": "2.99",
      "Div": "1.31"
  },
  {
      "Date": "13-Mar-02",
      "PE": "18.54",
      "PB": "2.98",
      "Div": "1.31"
  },
  {
      "Date": "12-Mar-02",
      "PE": "18.43",
      "PB": "2.96",
      "Div": "1.32"
  },
  {
      "Date": "11-Mar-02",
      "PE": "18.71",
      "PB": "3.01",
      "Div": "1.3"
  },
  {
      "Date": "8-Mar-02",
      "PE": "19.03",
      "PB": "3.06",
      "Div": "1.27"
  },
  {
      "Date": "7-Mar-02",
      "PE": "19.11",
      "PB": "3.07",
      "Div": "1.27"
  },
  {
      "Date": "6-Mar-02",
      "PE": "18.79",
      "PB": "3.02",
      "Div": "1.29"
  },
  {
      "Date": "5-Mar-02",
      "PE": "18.99",
      "PB": "3.03",
      "Div": "1.28"
  },
  {
      "Date": "4-Mar-02",
      "PE": "18.97",
      "PB": "3.03",
      "Div": "1.29"
  },
  {
      "Date": "1-Mar-02",
      "PE": "18.96",
      "PB": "3.04",
      "Div": "1.29"
  },
  {
      "Date": "28-Feb-02",
      "PE": "18.38",
      "PB": "2.94",
      "Div": "1.33"
  },
  {
      "Date": "27-Feb-02",
      "PE": "19.14",
      "PB": "3.07",
      "Div": "1.27"
  },
  {
      "Date": "26-Feb-02",
      "PE": "19.14",
      "PB": "3.07",
      "Div": "1.27"
  },
  {
      "Date": "25-Feb-02",
      "PE": "18.76",
      "PB": "3",
      "Div": "1.3"
  },
  {
      "Date": "22-Feb-02",
      "PE": "18.72",
      "PB": "3",
      "Div": "1.29"
  },
  {
      "Date": "21-Feb-02",
      "PE": "18.5",
      "PB": "2.96",
      "Div": "1.3"
  },
  {
      "Date": "20-Feb-02",
      "PE": "18.44",
      "PB": "2.95",
      "Div": "1.31"
  },
  {
      "Date": "19-Feb-02",
      "PE": "18.66",
      "PB": "2.99",
      "Div": "1.29"
  },
  {
      "Date": "18-Feb-02",
      "PE": "18.88",
      "PB": "3.02",
      "Div": "1.28"
  },
  {
      "Date": "15-Feb-02",
      "PE": "18.68",
      "PB": "2.99",
      "Div": "1.29"
  },
  {
      "Date": "14-Feb-02",
      "PE": "18.52",
      "PB": "2.96",
      "Div": "1.3"
  },
  {
      "Date": "13-Feb-02",
      "PE": "18.28",
      "PB": "2.93",
      "Div": "1.32"
  },
  {
      "Date": "12-Feb-02",
      "PE": "18.19",
      "PB": "2.91",
      "Div": "1.33"
  },
  {
      "Date": "11-Feb-02",
      "PE": "18.22",
      "PB": "2.92",
      "Div": "1.33"
  },
  {
      "Date": "8-Feb-02",
      "PE": "18.09",
      "PB": "2.9",
      "Div": "1.33"
  },
  {
      "Date": "7-Feb-02",
      "PE": "17.88",
      "PB": "2.86",
      "Div": "1.35"
  },
  {
      "Date": "6-Feb-02",
      "PE": "17.92",
      "PB": "2.87",
      "Div": "1.35"
  },
  {
      "Date": "5-Feb-02",
      "PE": "17.3",
      "PB": "2.77",
      "Div": "1.27"
  },
  {
      "Date": "4-Feb-02",
      "PE": "17.34",
      "PB": "2.78",
      "Div": "1.26"
  },
  {
      "Date": "1-Feb-02",
      "PE": "17.42",
      "PB": "2.79",
      "Div": "1.26"
  },
  {
      "Date": "31-Jan-02",
      "PE": "16.99",
      "PB": "2.79",
      "Div": "1.26"
  },
  {
      "Date": "30-Jan-02",
      "PE": "16.87",
      "PB": "2.77",
      "Div": "1.26"
  },
  {
      "Date": "29-Jan-02",
      "PE": "16.93",
      "PB": "2.75",
      "Div": "1.27"
  },
  {
      "Date": "28-Jan-02",
      "PE": "16.93",
      "PB": "2.76",
      "Div": "1.27"
  },
  {
      "Date": "25-Jan-02",
      "PE": "17.06",
      "PB": "2.76",
      "Div": "1.27"
  },
  {
      "Date": "24-Jan-02",
      "PE": "17.19",
      "PB": "2.79",
      "Div": "1.26"
  },
  {
      "Date": "23-Jan-02",
      "PE": "17.26",
      "PB": "2.81",
      "Div": "1.26"
  },
  {
      "Date": "22-Jan-02",
      "PE": "17.31",
      "PB": "2.83",
      "Div": "1.25"
  },
  {
      "Date": "21-Jan-02",
      "PE": "17.29",
      "PB": "2.83",
      "Div": "1.25"
  },
  {
      "Date": "18-Jan-02",
      "PE": "17.32",
      "PB": "2.83",
      "Div": "1.25"
  },
  {
      "Date": "17-Jan-02",
      "PE": "17.57",
      "PB": "2.78",
      "Div": "1.25"
  },
  {
      "Date": "16-Jan-02",
      "PE": "15.56",
      "PB": "2.82",
      "Div": "1.23"
  },
  {
      "Date": "15-Jan-02",
      "PE": "15.61",
      "PB": "2.46",
      "Div": "1.43"
  },
  {
      "Date": "14-Jan-02",
      "PE": "16.08",
      "PB": "2.47",
      "Div": "1.43"
  },
  {
      "Date": "11-Jan-02",
      "PE": "15.78",
      "PB": "2.5",
      "Div": "1.41"
  },
  {
      "Date": "10-Jan-02",
      "PE": "15.91",
      "PB": "2.46",
      "Div": "1.44"
  },
  {
      "Date": "9-Jan-02",
      "PE": "15.98",
      "PB": "2.48",
      "Div": "1.42"
  },
  {
      "Date": "8-Jan-02",
      "PE": "16.09",
      "PB": "2.49",
      "Div": "1.41"
  },
  {
      "Date": "7-Jan-02",
      "PE": "15.94",
      "PB": "2.5",
      "Div": "1.4"
  },
  {
      "Date": "4-Jan-02",
      "PE": "15.89",
      "PB": "2.48",
      "Div": "1.41"
  },
  {
      "Date": "3-Jan-02",
      "PE": "15.54",
      "PB": "2.47",
      "Div": "1.42"
  },
  {
      "Date": "2-Jan-02",
      "PE": "15.37",
      "PB": "2.42",
      "Div": "1.46"
  },
  {
      "Date": "1-Jan-02",
      "PE": "15.29",
      "PB": "2.39",
      "Div": "1.47"
  },
  {
      "Date": "31-Dec-01",
      "PE": "15.35",
      "PB": "2.38",
      "Div": "1.48"
  },
  {
      "Date": "28-Dec-01",
      "PE": "14.98",
      "PB": "2.39",
      "Div": "1.48"
  },
  {
      "Date": "27-Dec-01",
      "PE": "14.78",
      "PB": "2.33",
      "Div": "1.51"
  },
  {
      "Date": "26-Dec-01",
      "PE": "14.99",
      "PB": "2.3",
      "Div": "1.53"
  },
  {
      "Date": "24-Dec-01",
      "PE": "15.19",
      "PB": "2.33",
      "Div": "1.51"
  },
  {
      "Date": "21-Dec-01",
      "PE": "15.23",
      "PB": "2.37",
      "Div": "1.49"
  },
  {
      "Date": "20-Dec-01",
      "PE": "15.39",
      "PB": "2.37",
      "Div": "1.49"
  },
  {
      "Date": "19-Dec-01",
      "PE": "15.37",
      "PB": "2.4",
      "Div": "1.47"
  },
  {
      "Date": "18-Dec-01",
      "PE": "15.68",
      "PB": "2.39",
      "Div": "1.47"
  },
  {
      "Date": "14-Dec-01",
      "PE": "15.76",
      "PB": "2.44",
      "Div": "1.44"
  },
  {
      "Date": "13-Dec-01",
      "PE": "15.92",
      "PB": "2.45",
      "Div": "1.44"
  },
  {
      "Date": "12-Dec-01",
      "PE": "16.05",
      "PB": "2.48",
      "Div": "1.43"
  },
  {
      "Date": "11-Dec-01",
      "PE": "16.09",
      "PB": "2.5",
      "Div": "1.42"
  },
  {
      "Date": "10-Dec-01",
      "PE": "16.16",
      "PB": "2.5",
      "Div": "1.41"
  },
  {
      "Date": "7-Dec-01",
      "PE": "16.12",
      "PB": "2.52",
      "Div": "1.41"
  },
  {
      "Date": "6-Dec-01",
      "PE": "16.09",
      "PB": "2.51",
      "Div": "1.41"
  },
  {
      "Date": "5-Dec-01",
      "PE": "16.01",
      "PB": "2.51",
      "Div": "1.41"
  },
  {
      "Date": "4-Dec-01",
      "PE": "15.62",
      "PB": "2.49",
      "Div": "1.42"
  },
  {
      "Date": "3-Dec-01",
      "PE": "15.44",
      "PB": "2.43",
      "Div": "1.46"
  },
  {
      "Date": "29-Nov-01",
      "PE": "15.46",
      "PB": "2.4",
      "Div": "1.47"
  },
  {
      "Date": "28-Nov-01",
      "PE": "15.52",
      "PB": "2.41",
      "Div": "1.47"
  },
  {
      "Date": "27-Nov-01",
      "PE": "15.59",
      "PB": "2.42",
      "Div": "1.46"
  },
  {
      "Date": "26-Nov-01",
      "PE": "15.66",
      "PB": "2.43",
      "Div": "1.46"
  },
  {
      "Date": "23-Nov-01",
      "PE": "15.35",
      "PB": "2.44",
      "Div": "1.45"
  },
  {
      "Date": "22-Nov-01",
      "PE": "15.4",
      "PB": "2.39",
      "Div": "1.48"
  },
  {
      "Date": "21-Nov-01",
      "PE": "15.31",
      "PB": "2.4",
      "Div": "1.48"
  },
  {
      "Date": "20-Nov-01",
      "PE": "15.22",
      "PB": "2.38",
      "Div": "1.49"
  },
  {
      "Date": "19-Nov-01",
      "PE": "15.48",
      "PB": "2.37",
      "Div": "1.5"
  },
  {
      "Date": "15-Nov-01",
      "PE": "15.01",
      "PB": "2.41",
      "Div": "1.47"
  },
  {
      "Date": "14-Nov-01",
      "PE": "14.72",
      "PB": "2.34",
      "Div": "1.52"
  },
  {
      "Date": "13-Nov-01",
      "PE": "14.57",
      "PB": "2.29",
      "Div": "1.55"
  },
  {
      "Date": "12-Nov-01",
      "PE": "14.65",
      "PB": "2.27",
      "Div": "1.56"
  },
  {
      "Date": "9-Nov-01",
      "PE": "14.55",
      "PB": "2.28",
      "Div": "1.56"
  },
  {
      "Date": "8-Nov-01",
      "PE": "14.16",
      "PB": "2.27",
      "Div": "1.55"
  },
  {
      "Date": "7-Nov-01",
      "PE": "14.01",
      "PB": "2.25",
      "Div": "1.56"
  },
  {
      "Date": "6-Nov-01",
      "PE": "14.22",
      "PB": "2.26",
      "Div": "1.56"
  },
  {
      "Date": "5-Nov-01",
      "PE": "14.06",
      "PB": "2.24",
      "Div": "1.57"
  },
  {
      "Date": "2-Nov-01",
      "PE": "14.16",
      "PB": "2.25",
      "Div": "1.56"
  },
  {
      "Date": "1-Nov-01",
      "PE": "14.11",
      "PB": "2.24",
      "Div": "1.57"
  },
  {
      "Date": "31-Oct-01",
      "PE": "13.79",
      "PB": "2.19",
      "Div": "1.61"
  },
  {
      "Date": "30-Oct-01",
      "PE": "13.99",
      "PB": "2.17",
      "Div": "1.62"
  },
  {
      "Date": "29-Oct-01",
      "PE": "14.18",
      "PB": "2.2",
      "Div": "1.59"
  },
  {
      "Date": "25-Oct-01",
      "PE": "14.26",
      "PB": "2.22",
      "Div": "1.59"
  },
  {
      "Date": "24-Oct-01",
      "PE": "14.38",
      "PB": "2.23",
      "Div": "1.54"
  },
  {
      "Date": "23-Oct-01",
      "PE": "14.4",
      "PB": "2.24",
      "Div": "1.54"
  },
  {
      "Date": "22-Oct-01",
      "PE": "14.15",
      "PB": "2.2",
      "Div": "1.56"
  },
  {
      "Date": "19-Oct-01",
      "PE": "14.16",
      "PB": "2.2",
      "Div": "1.54"
  },
  {
      "Date": "18-Oct-01",
      "PE": "13.99",
      "PB": "2.19",
      "Div": "1.55"
  },
  {
      "Date": "17-Oct-01",
      "PE": "14.2",
      "PB": "2.22",
      "Div": "1.53"
  },
  {
      "Date": "16-Oct-01",
      "PE": "13.98",
      "PB": "2.19",
      "Div": "1.55"
  },
  {
      "Date": "15-Oct-01",
      "PE": "13.87",
      "PB": "2.17",
      "Div": "1.57"
  },
  {
      "Date": "12-Oct-01",
      "PE": "13.83",
      "PB": "2.16",
      "Div": "1.57"
  },
  {
      "Date": "11-Oct-01",
      "PE": "13.75",
      "PB": "2.15",
      "Div": "1.58"
  },
  {
      "Date": "10-Oct-01",
      "PE": "13.54",
      "PB": "2.12",
      "Div": "1.6"
  },
  {
      "Date": "9-Oct-01",
      "PE": "13.14",
      "PB": "2.06",
      "Div": "1.65"
  },
  {
      "Date": "8-Oct-01",
      "PE": "12.98",
      "PB": "2.03",
      "Div": "1.67"
  },
  {
      "Date": "5-Oct-01",
      "PE": "13.17",
      "PB": "2.06",
      "Div": "1.64"
  },
  {
      "Date": "4-Oct-01",
      "PE": "13.13",
      "PB": "2.05",
      "Div": "1.64"
  },
  {
      "Date": "3-Oct-01",
      "PE": "12.95",
      "PB": "2.03",
      "Div": "1.67"
  },
  {
      "Date": "1-Oct-01",
      "PE": "13.1",
      "PB": "2.05",
      "Div": "1.65"
  },
  {
      "Date": "28-Sep-01",
      "PE": "13.16",
      "PB": "2.06",
      "Div": "1.64"
  },
  {
      "Date": "27-Sep-01",
      "PE": "12.81",
      "PB": "2.01",
      "Div": "1.68"
  },
  {
      "Date": "26-Sep-01",
      "PE": "12.58",
      "PB": "1.97",
      "Div": "1.72"
  },
  {
      "Date": "25-Sep-01",
      "PE": "12.4",
      "PB": "1.94",
      "Div": "1.74"
  },
  {
      "Date": "24-Sep-01",
      "PE": "12.51",
      "PB": "1.96",
      "Div": "1.72"
  },
  {
      "Date": "21-Sep-01",
      "PE": "12.3",
      "PB": "1.92",
      "Div": "1.75"
  },
  {
      "Date": "20-Sep-01",
      "PE": "12.94",
      "PB": "2.03",
      "Div": "1.67"
  },
  {
      "Date": "19-Sep-01",
      "PE": "13.13",
      "PB": "2.06",
      "Div": "1.65"
  },
  {
      "Date": "18-Sep-01",
      "PE": "12.96",
      "PB": "2.03",
      "Div": "1.66"
  },
  {
      "Date": "17-Sep-01",
      "PE": "12.56",
      "PB": "1.97",
      "Div": "1.71"
  },
  {
      "Date": "14-Sep-01",
      "PE": "13.24",
      "PB": "2.07",
      "Div": "1.62"
  },
  {
      "Date": "13-Sep-01",
      "PE": "13.99",
      "PB": "2.19",
      "Div": "1.54"
  },
  {
      "Date": "12-Sep-01",
      "PE": "14.14",
      "PB": "2.21",
      "Div": "1.52"
  },
  {
      "Date": "11-Sep-01",
      "PE": "14.67",
      "PB": "2.3",
      "Div": "1.47"
  },
  {
      "Date": "10-Sep-01",
      "PE": "14.81",
      "PB": "2.32",
      "Div": "1.45"
  },
  {
      "Date": "7-Sep-01",
      "PE": "14.84",
      "PB": "2.32",
      "Div": "1.45"
  },
  {
      "Date": "6-Sep-01",
      "PE": "14.85",
      "PB": "2.32",
      "Div": "1.54"
  },
  {
      "Date": "5-Sep-01",
      "PE": "14.98",
      "PB": "2.34",
      "Div": "1.42"
  },
  {
      "Date": "4-Sep-01",
      "PE": "15.02",
      "PB": "2.35",
      "Div": "1.38"
  },
  {
      "Date": "3-Sep-01",
      "PE": "15.02",
      "PB": "2.35",
      "Div": "1.38"
  },
  {
      "Date": "31-Aug-01",
      "PE": "15.1",
      "PB": "2.36",
      "Div": "1.35"
  },
  {
      "Date": "30-Aug-01",
      "PE": "15.25",
      "PB": "2.39",
      "Div": "1.37"
  },
  {
      "Date": "29-Aug-01",
      "PE": "15.3",
      "PB": "2.39",
      "Div": "1.37"
  },
  {
      "Date": "28-Aug-01",
      "PE": "15.35",
      "PB": "2.4",
      "Div": "1.34"
  },
  {
      "Date": "27-Aug-01",
      "PE": "15.37",
      "PB": "2.41",
      "Div": "1.33"
  },
  {
      "Date": "24-Aug-01",
      "PE": "15.28",
      "PB": "2.39",
      "Div": "1.34"
  },
  {
      "Date": "23-Aug-01",
      "PE": "15.32",
      "PB": "2.4",
      "Div": "1.32"
  },
  {
      "Date": "21-Aug-01",
      "PE": "15.28",
      "PB": "2.39",
      "Div": "1.32"
  },
  {
      "Date": "20-Aug-01",
      "PE": "15.21",
      "PB": "2.38",
      "Div": "1.2"
  },
  {
      "Date": "17-Aug-01",
      "PE": "15.28",
      "PB": "2.39",
      "Div": "1.19"
  },
  {
      "Date": "16-Aug-01",
      "PE": "15.42",
      "PB": "2.42",
      "Div": "1.19"
  },
  {
      "Date": "14-Aug-01",
      "PE": "15.37",
      "PB": "2.41",
      "Div": "1.19"
  },
  {
      "Date": "13-Aug-01",
      "PE": "15.19",
      "PB": "2.38",
      "Div": "1.21"
  },
  {
      "Date": "10-Aug-01",
      "PE": "15.31",
      "PB": "2.4",
      "Div": "1.2"
  },
  {
      "Date": "9-Aug-01",
      "PE": "15.12",
      "PB": "2.37",
      "Div": "1.23"
  },
  {
      "Date": "8-Aug-01",
      "PE": "15.08",
      "PB": "2.36",
      "Div": "1.23"
  },
  {
      "Date": "7-Aug-01",
      "PE": "15.14",
      "PB": "2.37",
      "Div": "1.23"
  },
  {
      "Date": "6-Aug-01",
      "PE": "15.18",
      "PB": "2.38",
      "Div": "1.21"
  },
  {
      "Date": "3-Aug-01",
      "PE": "15.18",
      "PB": "2.38",
      "Div": "1.19"
  },
  {
      "Date": "2-Aug-01",
      "PE": "15.05",
      "PB": "2.36",
      "Div": "1.2"
  },
  {
      "Date": "1-Aug-01",
      "PE": "15.01",
      "PB": "2.35",
      "Div": "1.18"
  },
  {
      "Date": "31-Jul-01",
      "PE": "15.15",
      "PB": "2.37",
      "Div": "1.17"
  },
  {
      "Date": "30-Jul-01",
      "PE": "15.04",
      "PB": "2.35",
      "Div": "1.18"
  },
  {
      "Date": "27-Jul-01",
      "PE": "14.9",
      "PB": "2.33",
      "Div": "1.15"
  },
  {
      "Date": "26-Jul-01",
      "PE": "14.93",
      "PB": "2.33",
      "Div": "1.19"
  },
  {
      "Date": "25-Jul-01",
      "PE": "15.13",
      "PB": "2.36",
      "Div": "1.18"
  },
  {
      "Date": "24-Jul-01",
      "PE": "15.25",
      "PB": "2.38",
      "Div": "1.17"
  },
  {
      "Date": "23-Jul-01",
      "PE": "15.23",
      "PB": "2.38",
      "Div": "1.17"
  },
  {
      "Date": "20-Jul-01",
      "PE": "15.33",
      "PB": "2.39",
      "Div": "1.16"
  },
  {
      "Date": "19-Jul-01",
      "PE": "15.44",
      "PB": "2.41",
      "Div": "1.13"
  },
  {
      "Date": "18-Jul-01",
      "PE": "15.53",
      "PB": "2.42",
      "Div": "1.13"
  },
  {
      "Date": "17-Jul-01",
      "PE": "15.69",
      "PB": "2.45",
      "Div": "1.12"
  },
  {
      "Date": "16-Jul-01",
      "PE": "15.72",
      "PB": "2.45",
      "Div": "1.11"
  },
  {
      "Date": "13-Jul-01",
      "PE": "15.79",
      "PB": "2.46",
      "Div": "1.11"
  },
  {
      "Date": "12-Jul-01",
      "PE": "15.72",
      "PB": "2.45",
      "Div": "1.11"
  },
  {
      "Date": "11-Jul-01",
      "PE": "15.41",
      "PB": "2.4",
      "Div": "1.14"
  },
  {
      "Date": "10-Jul-01",
      "PE": "15.25",
      "PB": "2.38",
      "Div": "1.15"
  },
  {
      "Date": "9-Jul-01",
      "PE": "15.07",
      "PB": "2.35",
      "Div": "1.16"
  },
  {
      "Date": "6-Jul-01",
      "PE": "15.15",
      "PB": "2.36",
      "Div": "1.12"
  },
  {
      "Date": "5-Jul-01",
      "PE": "15.21",
      "PB": "2.37",
      "Div": "1.08"
  },
  {
      "Date": "4-Jul-01",
      "PE": "15.19",
      "PB": "2.37",
      "Div": "1.08"
  },
  {
      "Date": "3-Jul-01",
      "PE": "15.21",
      "PB": "2.37",
      "Div": "1.05"
  },
  {
      "Date": "2-Jul-01",
      "PE": "15.65",
      "PB": "2.44",
      "Div": "1.02"
  },
  {
      "Date": "29-Jun-01",
      "PE": "15.76",
      "PB": "2.46",
      "Div": "0.89"
  },
  {
      "Date": "28-Jun-01",
      "PE": "15.56",
      "PB": "2.43",
      "Div": "0.97"
  },
  {
      "Date": "27-Jun-01",
      "PE": "15.59",
      "PB": "2.43",
      "Div": "0.97"
  },
  {
      "Date": "26-Jun-01",
      "PE": "15.61",
      "PB": "2.44",
      "Div": "0.97"
  },
  {
      "Date": "25-Jun-01",
      "PE": "15.19",
      "PB": "2.37",
      "Div": "0.99"
  },
  {
      "Date": "22-Jun-01",
      "PE": "15.48",
      "PB": "2.42",
      "Div": "0.97"
  },
  {
      "Date": "21-Jun-01",
      "PE": "15.59",
      "PB": "2.43",
      "Div": "0.97"
  },
  {
      "Date": "20-Jun-01",
      "PE": "15.62",
      "PB": "2.44",
      "Div": "0.97"
  },
  {
      "Date": "19-Jun-01",
      "PE": "15.35",
      "PB": "2.79",
      "Div": "0.91"
  },
  {
      "Date": "18-Jun-01",
      "PE": "15.07",
      "PB": "2.75",
      "Div": "0.92"
  },
  {
      "Date": "15-Jun-01",
      "PE": "15.19",
      "PB": "2.81",
      "Div": "0.92"
  },
  {
      "Date": "14-Jun-01",
      "PE": "15.54",
      "PB": "2.88",
      "Div": "0.92"
  },
  {
      "Date": "13-Jun-01",
      "PE": "15.3",
      "PB": "2.9",
      "Div": "0.91"
  },
  {
      "Date": "12-Jun-01",
      "PE": "15.27",
      "PB": "2.9",
      "Div": "0.81"
  },
  {
      "Date": "11-Jun-01",
      "PE": "15.32",
      "PB": "2.91",
      "Div": "0.81"
  },
  {
      "Date": "8-Jun-01",
      "PE": "15.26",
      "PB": "2.89",
      "Div": "0.81"
  },
  {
      "Date": "7-Jun-01",
      "PE": "15.07",
      "PB": "2.86",
      "Div": "0.85"
  },
  {
      "Date": "6-Jun-01",
      "PE": "15.12",
      "PB": "2.87",
      "Div": "0.85"
  },
  {
      "Date": "5-Jun-01",
      "PE": "15.08",
      "PB": "2.86",
      "Div": "0.85"
  },
  {
      "Date": "4-Jun-01",
      "PE": "15.25",
      "PB": "2.89",
      "Div": "0.84"
  },
  {
      "Date": "1-Jun-01",
      "PE": "15.47",
      "PB": "2.95",
      "Div": "0.82"
  },
  {
      "Date": "31-May-01",
      "PE": "15.8",
      "PB": "3.04",
      "Div": "0.81"
  },
  {
      "Date": "30-May-01",
      "PE": "15.98",
      "PB": "3.08",
      "Div": "0.8"
  },
  {
      "Date": "29-May-01",
      "PE": "16.47",
      "PB": "3.28",
      "Div": "0.72"
  },
  {
      "Date": "28-May-01",
      "PE": "16.4",
      "PB": "3.27",
      "Div": "0.72"
  },
  {
      "Date": "25-May-01",
      "PE": "16.15",
      "PB": "3.22",
      "Div": "0.73"
  },
  {
      "Date": "24-May-01",
      "PE": "16.13",
      "PB": "3.24",
      "Div": "0.73"
  },
  {
      "Date": "23-May-01",
      "PE": "16.11",
      "PB": "3.25",
      "Div": "0.73"
  },
  {
      "Date": "22-May-01",
      "PE": "15.96",
      "PB": "3.22",
      "Div": "0.6"
  },
  {
      "Date": "21-May-01",
      "PE": "15.98",
      "PB": "3.23",
      "Div": "0.6"
  },
  {
      "Date": "18-May-01",
      "PE": "15.92",
      "PB": "3.24",
      "Div": "0.6"
  },
  {
      "Date": "17-May-01",
      "PE": "15.95",
      "PB": "3.25",
      "Div": "0.6"
  },
  {
      "Date": "16-May-01",
      "PE": "15.63",
      "PB": "3.18",
      "Div": "0.61"
  },
  {
      "Date": "15-May-01",
      "PE": "15.55",
      "PB": "3.16",
      "Div": "0.61"
  },
  {
      "Date": "14-May-01",
      "PE": "15.49",
      "PB": "3.15",
      "Div": "0.61"
  },
  {
      "Date": "11-May-01",
      "PE": "15.49",
      "PB": "3.15",
      "Div": "0.61"
  },
  {
      "Date": "10-May-01",
      "PE": "15.55",
      "PB": "3.16",
      "Div": "0.61"
  },
  {
      "Date": "9-May-01",
      "PE": "15.6",
      "PB": "3.17",
      "Div": "0.61"
  },
  {
      "Date": "8-May-01",
      "PE": "15.6",
      "PB": "3.17",
      "Div": "0.59"
  },
  {
      "Date": "7-May-01",
      "PE": "15.46",
      "PB": "3.15",
      "Div": "0.59"
  },
  {
      "Date": "4-May-01",
      "PE": "15.4",
      "PB": "3.13",
      "Div": "0.6"
  },
  {
      "Date": "3-May-01",
      "PE": "15.01",
      "PB": "3.19",
      "Div": "0.63"
  },
  {
      "Date": "2-May-01",
      "PE": "14.63",
      "PB": "3.23",
      "Div": "0.62"
  },
  {
      "Date": "30-Apr-01",
      "PE": "14.57",
      "PB": "3.21",
      "Div": "0.6"
  },
  {
      "Date": "27-Apr-01",
      "PE": "14.44",
      "PB": "3.15",
      "Div": "0.61"
  },
  {
      "Date": "26-Apr-01",
      "PE": "16.38",
      "PB": "3.66",
      "Div": "0.64"
  },
  {
      "Date": "25-Apr-01",
      "PE": "16.61",
      "PB": "3.72",
      "Div": "0.63"
  },
  {
      "Date": "24-Apr-01",
      "PE": "16.48",
      "PB": "3.69",
      "Div": "0.62"
  },
  {
      "Date": "23-Apr-01",
      "PE": "16.53",
      "PB": "3.71",
      "Div": "0.62"
  },
  {
      "Date": "20-Apr-01",
      "PE": "16.45",
      "PB": "3.69",
      "Div": "0.62"
  },
  {
      "Date": "19-Apr-01",
      "PE": "16.45",
      "PB": "3.69",
      "Div": "0.69"
  },
  {
      "Date": "18-Apr-01",
      "PE": "15.86",
      "PB": "3.56",
      "Div": "0.72"
  },
  {
      "Date": "17-Apr-01",
      "PE": "15.34",
      "PB": "3.44",
      "Div": "0.74"
  },
  {
      "Date": "16-Apr-01",
      "PE": "15.02",
      "PB": "3.37",
      "Div": "0.76"
  },
  {
      "Date": "12-Apr-01",
      "PE": "14.73",
      "PB": "3.3",
      "Div": "0.94"
  },
  {
      "Date": "11-Apr-01",
      "PE": "15.41",
      "PB": "3.45",
      "Div": "0.9"
  },
  {
      "Date": "10-Apr-01",
      "PE": "15.94",
      "PB": "3.56",
      "Div": "0.87"
  },
  {
      "Date": "9-Apr-01",
      "PE": "16.94",
      "PB": "3.69",
      "Div": "0.85"
  },
  {
      "Date": "6-Apr-01",
      "PE": "17.11",
      "PB": "3.73",
      "Div": "0.84"
  },
  {
      "Date": "4-Apr-01",
      "PE": "17.07",
      "PB": "3.72",
      "Div": "1.29"
  },
  {
      "Date": "3-Apr-01",
      "PE": "17.22",
      "PB": "3.75",
      "Div": "1.13"
  },
  {
      "Date": "2-Apr-01",
      "PE": "17.05",
      "PB": "3.72",
      "Div": "1.14"
  },
  {
      "Date": "30-Mar-01",
      "PE": "17.21",
      "PB": "3.75",
      "Div": "1.13"
  },
  {
      "Date": "29-Mar-01",
      "PE": "17.91",
      "PB": "3.9",
      "Div": "1.09"
  },
  {
      "Date": "28-Mar-01",
      "PE": "18.07",
      "PB": "3.94",
      "Div": "1.08"
  },
  {
      "Date": "27-Mar-01",
      "PE": "17.65",
      "PB": "3.84",
      "Div": "1.1"
  },
  {
      "Date": "26-Mar-01",
      "PE": "17.41",
      "PB": "3.79",
      "Div": "1.12"
  },
  {
      "Date": "23-Mar-01",
      "PE": "17.41",
      "PB": "3.79",
      "Div": "1.12"
  },
  {
      "Date": "22-Mar-01",
      "PE": "17.8",
      "PB": "3.87",
      "Div": "1.14"
  },
  {
      "Date": "21-Mar-01",
      "PE": "18.1",
      "PB": "3.94",
      "Div": "1.12"
  },
  {
      "Date": "20-Mar-01",
      "PE": "17.55",
      "PB": "3.82",
      "Div": "1.16"
  },
  {
      "Date": "19-Mar-01",
      "PE": "17.79",
      "PB": "3.87",
      "Div": "1.14"
  },
  {
      "Date": "16-Mar-01",
      "PE": "17.89",
      "PB": "3.89",
      "Div": "1.14"
  },
  {
      "Date": "15-Mar-01",
      "PE": "18.25",
      "PB": "3.97",
      "Div": "1.11"
  },
  {
      "Date": "14-Mar-01",
      "PE": "17.9",
      "PB": "3.89",
      "Div": "1.13"
  },
  {
      "Date": "13-Mar-01",
      "PE": "16.86",
      "PB": "3.67",
      "Div": "1.2"
  },
  {
      "Date": "12-Mar-01",
      "PE": "17.96",
      "PB": "3.91",
      "Div": "1.13"
  },
  {
      "Date": "9-Mar-01",
      "PE": "18.81",
      "PB": "4.09",
      "Div": "1.08"
  },
  {
      "Date": "8-Mar-01",
      "PE": "19.38",
      "PB": "4.21",
      "Div": "1.17"
  },
  {
      "Date": "7-Mar-01",
      "PE": "19.35",
      "PB": "4.21",
      "Div": "1.17"
  },
  {
      "Date": "5-Mar-01",
      "PE": "19.04",
      "PB": "4.14",
      "Div": "1.19"
  },
  {
      "Date": "2-Mar-01",
      "PE": "19.57",
      "PB": "4.26",
      "Div": "1.16"
  },
  {
      "Date": "1-Mar-01",
      "PE": "20.34",
      "PB": "4.42",
      "Div": "1.11"
  },
  {
      "Date": "28-Feb-01",
      "PE": "20.24",
      "PB": "4.4",
      "Div": "1.12"
  },
  {
      "Date": "27-Feb-01",
      "PE": "19.41",
      "PB": "4.22",
      "Div": "1.17"
  },
  {
      "Date": "26-Feb-01",
      "PE": "19.66",
      "PB": "4.28",
      "Div": "1.15"
  },
  {
      "Date": "23-Feb-01",
      "PE": "19.78",
      "PB": "4.3",
      "Div": "1.15"
  },
  {
      "Date": "22-Feb-01",
      "PE": "20.3",
      "PB": "4.41",
      "Div": "1.12"
  },
  {
      "Date": "21-Feb-01",
      "PE": "20.52",
      "PB": "4.46",
      "Div": "1.1"
  },
  {
      "Date": "20-Feb-01",
      "PE": "20.73",
      "PB": "4.51",
      "Div": "1.09"
  },
  {
      "Date": "19-Feb-01",
      "PE": "20.76",
      "PB": "4.51",
      "Div": "1.09"
  },
  {
      "Date": "16-Feb-01",
      "PE": "20.71",
      "PB": "4.5",
      "Div": "1.09"
  },
  {
      "Date": "15-Feb-01",
      "PE": "21.24",
      "PB": "4.61",
      "Div": "1.07"
  },
  {
      "Date": "14-Feb-01",
      "PE": "20.89",
      "PB": "4.54",
      "Div": "1.09"
  },
  {
      "Date": "13-Feb-01",
      "PE": "22.55",
      "PB": "4.68",
      "Div": "1.09"
  },
  {
      "Date": "12-Feb-01",
      "PE": "22.73",
      "PB": "4.71",
      "Div": "1.08"
  },
  {
      "Date": "9-Feb-01",
      "PE": "22.78",
      "PB": "4.73",
      "Div": "1.12"
  },
  {
      "Date": "8-Feb-01",
      "PE": "22.62",
      "PB": "4.69",
      "Div": "1.13"
  },
  {
      "Date": "7-Feb-01",
      "PE": "22.22",
      "PB": "4.61",
      "Div": "1.15"
  },
  {
      "Date": "6-Feb-01",
      "PE": "22.47",
      "PB": "4.66",
      "Div": "1.13"
  },
  {
      "Date": "5-Feb-01",
      "PE": "22.4",
      "PB": "4.65",
      "Div": "1.14"
  },
  {
      "Date": "2-Feb-01",
      "PE": "22.34",
      "PB": "4.64",
      "Div": "1.14"
  },
  {
      "Date": "1-Feb-01",
      "PE": "22.02",
      "PB": "4.57",
      "Div": "1.16"
  },
  {
      "Date": "31-Jan-01",
      "PE": "22.22",
      "PB": "4.61",
      "Div": "1.15"
  },
  {
      "Date": "30-Jan-01",
      "PE": "22.35",
      "PB": "4.64",
      "Div": "1.14"
  },
  {
      "Date": "29-Jan-01",
      "PE": "21.74",
      "PB": "4.51",
      "Div": "1.17"
  },
  {
      "Date": "25-Jan-01",
      "PE": "22.2",
      "PB": "4.61",
      "Div": "1.15"
  },
  {
      "Date": "24-Jan-01",
      "PE": "22.13",
      "PB": "4.59",
      "Div": "1.15"
  },
  {
      "Date": "23-Jan-01",
      "PE": "21.96",
      "PB": "4.56",
      "Div": "1.16"
  },
  {
      "Date": "22-Jan-01",
      "PE": "21.84",
      "PB": "4.53",
      "Div": "1.17"
  },
  {
      "Date": "19-Jan-01",
      "PE": "21.53",
      "PB": "4.47",
      "Div": "1.18"
  },
  {
      "Date": "18-Jan-01",
      "PE": "21.16",
      "PB": "4.39",
      "Div": "1.2"
  },
  {
      "Date": "17-Jan-01",
      "PE": "21.03",
      "PB": "4.36",
      "Div": "1.21"
  },
  {
      "Date": "16-Jan-01",
      "PE": "20.95",
      "PB": "4.35",
      "Div": "1.21"
  },
  {
      "Date": "15-Jan-01",
      "PE": "20.85",
      "PB": "4.33",
      "Div": "1.22"
  },
  {
      "Date": "12-Jan-01",
      "PE": "19.55",
      "PB": "4.21",
      "Div": "1.22"
  },
  {
      "Date": "11-Jan-01",
      "PE": "19.46",
      "PB": "4.19",
      "Div": "1.23"
  },
  {
      "Date": "10-Jan-01",
      "PE": "19.56",
      "PB": "4.22",
      "Div": "1.22"
  },
  {
      "Date": "9-Jan-01",
      "PE": "19.93",
      "PB": "4.3",
      "Div": "1.2"
  },
  {
      "Date": "8-Jan-01",
      "PE": "19.9",
      "PB": "4.29",
      "Div": "1.2"
  },
  {
      "Date": "5-Jan-01",
      "PE": "20.17",
      "PB": "4.35",
      "Div": "1.18"
  },
  {
      "Date": "4-Jan-01",
      "PE": "19.87",
      "PB": "4.28",
      "Div": "1.2"
  },
  {
      "Date": "3-Jan-01",
      "PE": "19.62",
      "PB": "4.23",
      "Div": "1.22"
  },
  {
      "Date": "2-Jan-01",
      "PE": "19.33",
      "PB": "4.16",
      "Div": "1.23"
  },
  {
      "Date": "1-Jan-01",
      "PE": "19.06",
      "PB": "4.11",
      "Div": "1.25"
  },
  {
      "Date": "29-Dec-00",
      "PE": "19.2",
      "PB": "4.14",
      "Div": "1.24"
  },
  {
      "Date": "28-Dec-00",
      "PE": "18.98",
      "PB": "4.09",
      "Div": "1.25"
  },
  {
      "Date": "27-Dec-00",
      "PE": "18.67",
      "PB": "4.02",
      "Div": "1.27"
  },
  {
      "Date": "26-Dec-00",
      "PE": "18.42",
      "PB": "3.97",
      "Div": "1.29"
  },
  {
      "Date": "22-Dec-00",
      "PE": "18.87",
      "PB": "4.07",
      "Div": "1.26"
  },
  {
      "Date": "21-Dec-00",
      "PE": "19.41",
      "PB": "4.18",
      "Div": "1.23"
  },
  {
      "Date": "20-Dec-00",
      "PE": "19.68",
      "PB": "4.24",
      "Div": "1.21"
  },
  {
      "Date": "19-Dec-00",
      "PE": "19.88",
      "PB": "4.28",
      "Div": "1.2"
  },
  {
      "Date": "18-Dec-00",
      "PE": "19.98",
      "PB": "4.31",
      "Div": "1.19"
  },
  {
      "Date": "15-Dec-00",
      "PE": "19.91",
      "PB": "4.29",
      "Div": "1.21"
  },
  {
      "Date": "14-Dec-00",
      "PE": "20.47",
      "PB": "4.41",
      "Div": "1.18"
  },
  {
      "Date": "13-Dec-00",
      "PE": "20.54",
      "PB": "4.43",
      "Div": "1.17"
  },
  {
      "Date": "12-Dec-00",
      "PE": "20.21",
      "PB": "4.36",
      "Div": "1.19"
  },
  {
      "Date": "11-Dec-00",
      "PE": "20.19",
      "PB": "4.35",
      "Div": "1.19"
  },
  {
      "Date": "8-Dec-00",
      "PE": "19.91",
      "PB": "4.29",
      "Div": "1.21"
  },
  {
      "Date": "7-Dec-00",
      "PE": "19.74",
      "PB": "4.25",
      "Div": "1.22"
  },
  {
      "Date": "6-Dec-00",
      "PE": "19.68",
      "PB": "4.24",
      "Div": "1.22"
  },
  {
      "Date": "5-Dec-00",
      "PE": "19.47",
      "PB": "4.2",
      "Div": "1.23"
  },
  {
      "Date": "4-Dec-00",
      "PE": "19.34",
      "PB": "4.17",
      "Div": "1.24"
  },
  {
      "Date": "1-Dec-00",
      "PE": "19.34",
      "PB": "4.17",
      "Div": "1.24"
  },
  {
      "Date": "30-Nov-00",
      "PE": "19.22",
      "PB": "4.14",
      "Div": "1.25"
  },
  {
      "Date": "29-Nov-00",
      "PE": "19.17",
      "PB": "4.13",
      "Div": "1.25"
  },
  {
      "Date": "28-Nov-00",
      "PE": "19.26",
      "PB": "4.15",
      "Div": "1.25"
  },
  {
      "Date": "27-Nov-00",
      "PE": "18.96",
      "PB": "4.08",
      "Div": "1.27"
  },
  {
      "Date": "24-Nov-00",
      "PE": "18.54",
      "PB": "3.99",
      "Div": "1.3"
  },
  {
      "Date": "23-Nov-00",
      "PE": "18.4",
      "PB": "3.96",
      "Div": "1.31"
  },
  {
      "Date": "22-Nov-00",
      "PE": "18.49",
      "PB": "3.98",
      "Div": "1.3"
  },
  {
      "Date": "21-Nov-00",
      "PE": "18.68",
      "PB": "4.03",
      "Div": "1.28"
  },
  {
      "Date": "20-Nov-00",
      "PE": "18.72",
      "PB": "4.03",
      "Div": "1.28"
  },
  {
      "Date": "17-Nov-00",
      "PE": "18.7",
      "PB": "4.03",
      "Div": "1.28"
  },
  {
      "Date": "16-Nov-00",
      "PE": "18.66",
      "PB": "4.02",
      "Div": "1.29"
  },
  {
      "Date": "15-Nov-00",
      "PE": "18.87",
      "PB": "4.07",
      "Div": "1.27"
  },
  {
      "Date": "14-Nov-00",
      "PE": "18.8",
      "PB": "4.05",
      "Div": "1.27"
  },
  {
      "Date": "13-Nov-00",
      "PE": "18.28",
      "PB": "3.94",
      "Div": "1.31"
  },
  {
      "Date": "10-Nov-00",
      "PE": "18.75",
      "PB": "4.04",
      "Div": "1.28"
  },
  {
      "Date": "9-Nov-00",
      "PE": "19.21",
      "PB": "4.14",
      "Div": "1.25"
  },
  {
      "Date": "8-Nov-00",
      "PE": "19.17",
      "PB": "4.13",
      "Div": "1.25"
  },
  {
      "Date": "7-Nov-00",
      "PE": "18.85",
      "PB": "4.06",
      "Div": "1.27"
  },
  {
      "Date": "6-Nov-00",
      "PE": "18.76",
      "PB": "4.04",
      "Div": "1.27"
  },
  {
      "Date": "3-Nov-00",
      "PE": "18.78",
      "PB": "4.04",
      "Div": "1.29"
  },
  {
      "Date": "2-Nov-00",
      "PE": "18.52",
      "PB": "3.99",
      "Div": "1.31"
  },
  {
      "Date": "1-Nov-00",
      "PE": "18.16",
      "PB": "3.91",
      "Div": "1.34"
  },
  {
      "Date": "31-Oct-00",
      "PE": "17.74",
      "PB": "3.82",
      "Div": "1.37"
  },
  {
      "Date": "30-Oct-00",
      "PE": "17.65",
      "PB": "3.8",
      "Div": "1.37"
  },
  {
      "Date": "27-Oct-00",
      "PE": "17.83",
      "PB": "3.84",
      "Div": "1.36"
  },
  {
      "Date": "26-Oct-00",
      "PE": "17.94",
      "PB": "3.86",
      "Div": "1.36"
  },
  {
      "Date": "25-Oct-00",
      "PE": "17.9",
      "PB": "3.86",
      "Div": "1.36"
  },
  {
      "Date": "24-Oct-00",
      "PE": "17.43",
      "PB": "3.75",
      "Div": "1.39"
  },
  {
      "Date": "23-Oct-00",
      "PE": "17.3",
      "PB": "3.73",
      "Div": "1.4"
  },
  {
      "Date": "20-Oct-00",
      "PE": "17.72",
      "PB": "3.82",
      "Div": "1.37"
  },
  {
      "Date": "19-Oct-00",
      "PE": "17.64",
      "PB": "3.8",
      "Div": "1.37"
  },
  {
      "Date": "18-Oct-00",
      "PE": "17.18",
      "PB": "3.7",
      "Div": "1.41"
  },
  {
      "Date": "17-Oct-00",
      "PE": "17.51",
      "PB": "3.77",
      "Div": "1.38"
  },
  {
      "Date": "16-Oct-00",
      "PE": "17.78",
      "PB": "3.83",
      "Div": "1.36"
  },
  {
      "Date": "13-Oct-00",
      "PE": "18.01",
      "PB": "3.83",
      "Div": "1.36"
  },
  {
      "Date": "12-Oct-00",
      "PE": "18.33",
      "PB": "3.94",
      "Div": "1.33"
  },
  {
      "Date": "11-Oct-00",
      "PE": "18.26",
      "PB": "3.92",
      "Div": "1.33"
  },
  {
      "Date": "10-Oct-00",
      "PE": "18.83",
      "PB": "4.04",
      "Div": "1.29"
  },
  {
      "Date": "9-Oct-00",
      "PE": "19.26",
      "PB": "4.14",
      "Div": "1.26"
  },
  {
      "Date": "6-Oct-00",
      "PE": "19.52",
      "PB": "4.2",
      "Div": "1.25"
  },
  {
      "Date": "5-Oct-00",
      "PE": "19.52",
      "PB": "4.19",
      "Div": "1.25"
  },
  {
      "Date": "4-Oct-00",
      "PE": "19.72",
      "PB": "4.24",
      "Div": "1.23"
  },
  {
      "Date": "3-Oct-00",
      "PE": "19.48",
      "PB": "4.19",
      "Div": "1.25"
  },
  {
      "Date": "29-Sep-00",
      "PE": "19.32",
      "PB": "4.15",
      "Div": "1.26"
  },
  {
      "Date": "28-Sep-00",
      "PE": "19.24",
      "PB": "4.13",
      "Div": "1.26"
  },
  {
      "Date": "27-Sep-00",
      "PE": "19.64",
      "PB": "4.22",
      "Div": "1.24"
  },
  {
      "Date": "26-Sep-00",
      "PE": "19.26",
      "PB": "4.14",
      "Div": "1.26"
  },
  {
      "Date": "25-Sep-00",
      "PE": "19.64",
      "PB": "4.22",
      "Div": "1.24"
  },
  {
      "Date": "22-Sep-00",
      "PE": "19.24",
      "PB": "4.13",
      "Div": "1.27"
  },
  {
      "Date": "21-Sep-00",
      "PE": "20.21",
      "PB": "4.34",
      "Div": "1.21"
  },
  {
      "Date": "20-Sep-00",
      "PE": "20.4",
      "PB": "4.38",
      "Div": "1.2"
  },
  {
      "Date": "19-Sep-00",
      "PE": "20.01",
      "PB": "4.3",
      "Div": "1.22"
  },
  {
      "Date": "18-Sep-00",
      "PE": "20.58",
      "PB": "4.42",
      "Div": "1.18"
  },
  {
      "Date": "15-Sep-00",
      "PE": "21.53",
      "PB": "4.63",
      "Div": "1.13"
  },
  {
      "Date": "14-Sep-00",
      "PE": "21.96",
      "PB": "4.72",
      "Div": "1.11"
  },
  {
      "Date": "13-Sep-00",
      "PE": "22.13",
      "PB": "4.75",
      "Div": "1.1"
  },
  {
      "Date": "12-Sep-00",
      "PE": "22.3",
      "PB": "4.79",
      "Div": "1.09"
  },
  {
      "Date": "11-Sep-00",
      "PE": "22.13",
      "PB": "4.75",
      "Div": "1.1"
  },
  {
      "Date": "8-Sep-00",
      "PE": "22.03",
      "PB": "4.73",
      "Div": "1.11"
  },
  {
      "Date": "7-Sep-00",
      "PE": "21.88",
      "PB": "4.7",
      "Div": "1.11"
  },
  {
      "Date": "6-Sep-00",
      "PE": "21.81",
      "PB": "4.69",
      "Div": "1.12"
  },
  {
      "Date": "5-Sep-00",
      "PE": "21.7",
      "PB": "4.66",
      "Div": "1.04"
  },
  {
      "Date": "4-Sep-00",
      "PE": "21.69",
      "PB": "4.66",
      "Div": "1.04"
  },
  {
      "Date": "31-Aug-00",
      "PE": "20.97",
      "PB": "4.42",
      "Div": "1.06"
  },
  {
      "Date": "30-Aug-00",
      "PE": "20.7",
      "PB": "4.36",
      "Div": "1.08"
  },
  {
      "Date": "29-Aug-00",
      "PE": "20.58",
      "PB": "4.33",
      "Div": "1.06"
  },
  {
      "Date": "28-Aug-00",
      "PE": "20.63",
      "PB": "4.34",
      "Div": "1.06"
  },
  {
      "Date": "25-Aug-00",
      "PE": "20.8",
      "PB": "4.38",
      "Div": "1.14"
  },
  {
      "Date": "24-Aug-00",
      "PE": "20.89",
      "PB": "4.4",
      "Div": "1.13"
  },
  {
      "Date": "23-Aug-00",
      "PE": "20.87",
      "PB": "4.39",
      "Div": "1.13"
  },
  {
      "Date": "22-Aug-00",
      "PE": "20.78",
      "PB": "4.37",
      "Div": "1.14"
  },
  {
      "Date": "21-Aug-00",
      "PE": "20.62",
      "PB": "4.34",
      "Div": "1.15"
  },
  {
      "Date": "18-Aug-00",
      "PE": "20.45",
      "PB": "4.3",
      "Div": "1.16"
  },
  {
      "Date": "17-Aug-00",
      "PE": "20.2",
      "PB": "4.25",
      "Div": "1.17"
  },
  {
      "Date": "16-Aug-00",
      "PE": "20.35",
      "PB": "4.28",
      "Div": "1.16"
  },
  {
      "Date": "14-Aug-00",
      "PE": "19.85",
      "PB": "4.18",
      "Div": "1.18"
  },
  {
      "Date": "11-Aug-00",
      "PE": "19.74",
      "PB": "4.15",
      "Div": "1.21"
  },
  {
      "Date": "10-Aug-00",
      "PE": "20.09",
      "PB": "4.22",
      "Div": "1.2"
  },
  {
      "Date": "9-Aug-00",
      "PE": "20.86",
      "PB": "4.35",
      "Div": "1.18"
  },
  {
      "Date": "8-Aug-00",
      "PE": "20.87",
      "PB": "4.35",
      "Div": "1.17"
  },
  {
      "Date": "7-Aug-00",
      "PE": "20.33",
      "PB": "4.24",
      "Div": "1.2"
  },
  {
      "Date": "4-Aug-00",
      "PE": "20.45",
      "PB": "4.27",
      "Div": "1.19"
  },
  {
      "Date": "3-Aug-00",
      "PE": "20.49",
      "PB": "4.27",
      "Div": "1.19"
  },
  {
      "Date": "2-Aug-00",
      "PE": "20.66",
      "PB": "4.31",
      "Div": "1.18"
  },
  {
      "Date": "1-Aug-00",
      "PE": "20.58",
      "PB": "4.29",
      "Div": "1.19"
  },
  {
      "Date": "31-Jul-00",
      "PE": "20.71",
      "PB": "4.3",
      "Div": "1.18"
  },
  {
      "Date": "28-Jul-00",
      "PE": "20.72",
      "PB": "4.3",
      "Div": "1.18"
  },
  {
      "Date": "27-Jul-00",
      "PE": "20.79",
      "PB": "4.32",
      "Div": "1.18"
  },
  {
      "Date": "26-Jul-00",
      "PE": "20.48",
      "PB": "4.25",
      "Div": "1.19"
  },
  {
      "Date": "25-Jul-00",
      "PE": "20.96",
      "PB": "4.35",
      "Div": "1.14"
  },
  {
      "Date": "24-Jul-00",
      "PE": "20.47",
      "PB": "4.25",
      "Div": "1.17"
  },
  {
      "Date": "21-Jul-00",
      "PE": "21.51",
      "PB": "4.45",
      "Div": "1.12"
  },
  {
      "Date": "20-Jul-00",
      "PE": "21.93",
      "PB": "4.54",
      "Div": "1.1"
  },
  {
      "Date": "19-Jul-00",
      "PE": "22.11",
      "PB": "4.57",
      "Div": "1.09"
  },
  {
      "Date": "18-Jul-00",
      "PE": "22.53",
      "PB": "4.66",
      "Div": "1.07"
  },
  {
      "Date": "17-Jul-00",
      "PE": "22.78",
      "PB": "4.71",
      "Div": "1.06"
  },
  {
      "Date": "14-Jul-00",
      "PE": "23.25",
      "PB": "4.81",
      "Div": "1.09"
  },
  {
      "Date": "13-Jul-00",
      "PE": "23.44",
      "PB": "4.85",
      "Div": "1.08"
  },
  {
      "Date": "12-Jul-00",
      "PE": "23.61",
      "PB": "4.88",
      "Div": "1.07"
  },
  {
      "Date": "11-Jul-00",
      "PE": "23.38",
      "PB": "4.84",
      "Div": "1.08"
  },
  {
      "Date": "10-Jul-00",
      "PE": "23.24",
      "PB": "4.81",
      "Div": "1.08"
  },
  {
      "Date": "7-Jul-00",
      "PE": "23.37",
      "PB": "4.83",
      "Div": "1.11"
  },
  {
      "Date": "6-Jul-00",
      "PE": "23.35",
      "PB": "4.83",
      "Div": "1.11"
  },
  {
      "Date": "5-Jul-00",
      "PE": "23.5",
      "PB": "4.86",
      "Div": "1.11"
  },
  {
      "Date": "4-Jul-00",
      "PE": "23.56",
      "PB": "5.02",
      "Div": "1.08"
  },
  {
      "Date": "3-Jul-00",
      "PE": "23.31",
      "PB": "4.97",
      "Div": "1.09"
  },
  {
      "Date": "30-Jun-00",
      "PE": "22.94",
      "PB": "4.89",
      "Div": "1.26"
  },
  {
      "Date": "29-Jun-00",
      "PE": "23.26",
      "PB": "4.96",
      "Div": "1.25"
  },
  {
      "Date": "28-Jun-00",
      "PE": "22.91",
      "PB": "4.88",
      "Div": "1.27"
  },
  {
      "Date": "27-Jun-00",
      "PE": "22.67",
      "PB": "4.83",
      "Div": "1.23"
  },
  {
      "Date": "26-Jun-00",
      "PE": "22.63",
      "PB": "4.82",
      "Div": "1.23"
  },
  {
      "Date": "23-Jun-00",
      "PE": "22.95",
      "PB": "4.89",
      "Div": "1.29"
  },
  {
      "Date": "22-Jun-00",
      "PE": "24.67",
      "PB": "4.95",
      "Div": "1.28"
  },
  {
      "Date": "21-Jun-00",
      "PE": "24.45",
      "PB": "4.91",
      "Div": "1.29"
  },
  {
      "Date": "20-Jun-00",
      "PE": "24.98",
      "PB": "5.01",
      "Div": "1.26"
  },
  {
      "Date": "19-Jun-00",
      "PE": "24.84",
      "PB": "4.98",
      "Div": "1.27"
  },
  {
      "Date": "16-Jun-00",
      "PE": "24.48",
      "PB": "4.91",
      "Div": "1.34"
  },
  {
      "Date": "15-Jun-00",
      "PE": "23.95",
      "PB": "4.81",
      "Div": "1.37"
  },
  {
      "Date": "14-Jun-00",
      "PE": "23.79",
      "PB": "4.77",
      "Div": "1.38"
  },
  {
      "Date": "13-Jun-00",
      "PE": "23.74",
      "PB": "4.79",
      "Div": "1.36"
  },
  {
      "Date": "12-Jun-00",
      "PE": "23.87",
      "PB": "4.81",
      "Div": "1.36"
  },
  {
      "Date": "9-Jun-00",
      "PE": "24.31",
      "PB": "4.9",
      "Div": "1.36"
  },
  {
      "Date": "8-Jun-00",
      "PE": "24.25",
      "PB": "4.89",
      "Div": "1.36"
  },
  {
      "Date": "7-Jun-00",
      "PE": "23.7",
      "PB": "4.78",
      "Div": "1.39"
  },
  {
      "Date": "6-Jun-00",
      "PE": "23.67",
      "PB": "4.76",
      "Div": "1.38"
  },
  {
      "Date": "5-Jun-00",
      "PE": "23.38",
      "PB": "4.71",
      "Div": "1.39"
  },
  {
      "Date": "2-Jun-00",
      "PE": "23.13",
      "PB": "4.65",
      "Div": "1.42"
  },
  {
      "Date": "1-Jun-00",
      "PE": "22.46",
      "PB": "4.52",
      "Div": "1.46"
  },
  {
      "Date": "31-May-00",
      "PE": "22.96",
      "PB": "4.7",
      "Div": "1.43"
  },
  {
      "Date": "30-May-00",
      "PE": "22.34",
      "PB": "4.57",
      "Div": "1.47"
  },
  {
      "Date": "29-May-00",
      "PE": "21.95",
      "PB": "4.34",
      "Div": "1.5"
  },
  {
      "Date": "26-May-00",
      "PE": "21.35",
      "PB": "4.22",
      "Div": "1.75"
  },
  {
      "Date": "25-May-00",
      "PE": "20.89",
      "PB": "4.13",
      "Div": "1.79"
  },
  {
      "Date": "24-May-00",
      "PE": "20.69",
      "PB": "4.09",
      "Div": "1.8"
  },
  {
      "Date": "23-May-00",
      "PE": "18.4",
      "PB": "3.45",
      "Div": "1.91"
  },
  {
      "Date": "22-May-00",
      "PE": "18.53",
      "PB": "3.47",
      "Div": "1.89"
  },
  {
      "Date": "19-May-00",
      "PE": "19.05",
      "PB": "3.57",
      "Div": "1.85"
  },
  {
      "Date": "18-May-00",
      "PE": "19.44",
      "PB": "3.64",
      "Div": "1.81"
  },
  {
      "Date": "17-May-00",
      "PE": "19.88",
      "PB": "3.7",
      "Div": "1.79"
  },
  {
      "Date": "16-May-00",
      "PE": "19.66",
      "PB": "3.54",
      "Div": "1.81"
  },
  {
      "Date": "15-May-00",
      "PE": "19.54",
      "PB": "3.52",
      "Div": "1.82"
  },
  {
      "Date": "12-May-00",
      "PE": "19.36",
      "PB": "3.49",
      "Div": "1.84"
  },
  {
      "Date": "11-May-00",
      "PE": "19.68",
      "PB": "3.55",
      "Div": "1.81"
  },
  {
      "Date": "10-May-00",
      "PE": "20.57",
      "PB": "3.71",
      "Div": "1.74"
  },
  {
      "Date": "9-May-00",
      "PE": "20.68",
      "PB": "3.73",
      "Div": "1.72"
  },
  {
      "Date": "8-May-00",
      "PE": "20.47",
      "PB": "3.7",
      "Div": "1.74"
  },
  {
      "Date": "5-May-00",
      "PE": "21.33",
      "PB": "3.85",
      "Div": "1.77"
  },
  {
      "Date": "4-May-00",
      "PE": "20.71",
      "PB": "3.74",
      "Div": "1.82"
  },
  {
      "Date": "3-May-00",
      "PE": "19.74",
      "PB": "3.56",
      "Div": "1.91"
  },
  {
      "Date": "2-May-00",
      "PE": "20",
      "PB": "3.61",
      "Div": "1.86"
  },
  {
      "Date": "28-Apr-00",
      "PE": "21.1",
      "PB": "3.81",
      "Div": "1.77"
  },
  {
      "Date": "27-Apr-00",
      "PE": "21.25",
      "PB": "3.84",
      "Div": "1.75"
  },
  {
      "Date": "26-Apr-00",
      "PE": "21.54",
      "PB": "3.89",
      "Div": "1.73"
  },
  {
      "Date": "25-Apr-00",
      "PE": "20.39",
      "PB": "3.68",
      "Div": "1.78"
  },
  {
      "Date": "24-Apr-00",
      "PE": "20.82",
      "PB": "3.76",
      "Div": "1.75"
  },
  {
      "Date": "20-Apr-00",
      "PE": "21.23",
      "PB": "3.83",
      "Div": "1.71"
  },
  {
      "Date": "19-Apr-00",
      "PE": "21.07",
      "PB": "3.8",
      "Div": "1.73"
  },
  {
      "Date": "18-Apr-00",
      "PE": "21.31",
      "PB": "3.84",
      "Div": "1.66"
  },
  {
      "Date": "17-Apr-00",
      "PE": "21.74",
      "PB": "3.91",
      "Div": "1.62"
  },
  {
      "Date": "13-Apr-00",
      "PE": "23.98",
      "PB": "4.38",
      "Div": "1.54"
  },
  {
      "Date": "12-Apr-00",
      "PE": "25.21",
      "PB": "4.62",
      "Div": "1.47"
  },
  {
      "Date": "11-Apr-00",
      "PE": "25.72",
      "PB": "4.71",
      "Div": "1.33"
  },
  {
      "Date": "10-Apr-00",
      "PE": "25.96",
      "PB": "4.71",
      "Div": "1.34"
  },
  {
      "Date": "7-Apr-00",
      "PE": "25.06",
      "PB": "4.54",
      "Div": "1.39"
  },
  {
      "Date": "6-Apr-00",
      "PE": "23.39",
      "PB": "4.24",
      "Div": "1.49"
  },
  {
      "Date": "5-Apr-00",
      "PE": "23.09",
      "PB": "4.19",
      "Div": "1.51"
  },
  {
      "Date": "4-Apr-00",
      "PE": "22.99",
      "PB": "4.17",
      "Div": "1.14"
  },
  {
      "Date": "3-Apr-00",
      "PE": "24.7",
      "PB": "4.48",
      "Div": "1.06"
  },
  {
      "Date": "31-Mar-00",
      "PE": "24.6",
      "PB": "4.46",
      "Div": "1.06"
  },
  {
      "Date": "30-Mar-00",
      "PE": "24.94",
      "PB": "4.52",
      "Div": "1.05"
  },
  {
      "Date": "29-Mar-00",
      "PE": "25.08",
      "PB": "4.55",
      "Div": "1.04"
  },
  {
      "Date": "28-Mar-00",
      "PE": "25.08",
      "PB": "4.55",
      "Div": "1.04"
  },
  {
      "Date": "27-Mar-00",
      "PE": "24.99",
      "PB": "4.53",
      "Div": "1.05"
  },
  {
      "Date": "24-Mar-00",
      "PE": "25.09",
      "PB": "4.55",
      "Div": "1.05"
  },
  {
      "Date": "23-Mar-00",
      "PE": "24.83",
      "PB": "4.5",
      "Div": "1.06"
  },
  {
      "Date": "22-Mar-00",
      "PE": "25.41",
      "PB": "4.61",
      "Div": "1.03"
  },
  {
      "Date": "21-Mar-00",
      "PE": "24.89",
      "PB": "4.51",
      "Div": "1.02"
  },
  {
      "Date": "16-Mar-00",
      "PE": "24.97",
      "PB": "4.53",
      "Div": "1.09"
  },
  {
      "Date": "15-Mar-00",
      "PE": "25.9",
      "PB": "4.7",
      "Div": "1.06"
  },
  {
      "Date": "14-Mar-00",
      "PE": "25.05",
      "PB": "4.54",
      "Div": "1.09"
  },
  {
      "Date": "13-Mar-00",
      "PE": "24.95",
      "PB": "4.52",
      "Div": "1.1"
  },
  {
      "Date": "10-Mar-00",
      "PE": "25.62",
      "PB": "4.65",
      "Div": "1.07"
  },
  {
      "Date": "9-Mar-00",
      "PE": "26.32",
      "PB": "4.77",
      "Div": "1.04"
  },
  {
      "Date": "8-Mar-00",
      "PE": "26.64",
      "PB": "4.83",
      "Div": "1.03"
  },
  {
      "Date": "7-Mar-00",
      "PE": "27.22",
      "PB": "4.94",
      "Div": "0.91"
  },
  {
      "Date": "6-Mar-00",
      "PE": "26.99",
      "PB": "4.89",
      "Div": "0.92"
  },
  {
      "Date": "3-Mar-00",
      "PE": "26.47",
      "PB": "4.8",
      "Div": "0.94"
  },
  {
      "Date": "2-Mar-00",
      "PE": "27.09",
      "PB": "4.92",
      "Div": "0.91"
  },
  {
      "Date": "1-Mar-00",
      "PE": "27.35",
      "PB": "4.96",
      "Div": "0.91"
  },
  {
      "Date": "29-Feb-00",
      "PE": "26.36",
      "PB": "4.79",
      "Div": "0.94"
  },
  {
      "Date": "28-Feb-00",
      "PE": "27.49",
      "PB": "4.98",
      "Div": "0.9"
  },
  {
      "Date": "25-Feb-00",
      "PE": "27.29",
      "PB": "4.95",
      "Div": "0.91"
  },
  {
      "Date": "24-Feb-00",
      "PE": "27.66",
      "PB": "5.01",
      "Div": "0.9"
  },
  {
      "Date": "23-Feb-00",
      "PE": "27.09",
      "PB": "4.91",
      "Div": "0.92"
  },
  {
      "Date": "22-Feb-00",
      "PE": "27.77",
      "PB": "5.03",
      "Div": "0.89"
  },
  {
      "Date": "21-Feb-00",
      "PE": "28",
      "PB": "5.08",
      "Div": "0.89"
  },
  {
      "Date": "18-Feb-00",
      "PE": "27.43",
      "PB": "4.97",
      "Div": "0.9"
  },
  {
      "Date": "17-Feb-00",
      "PE": "27.82",
      "PB": "5.04",
      "Div": "0.9"
  },
  {
      "Date": "16-Feb-00",
      "PE": "27.32",
      "PB": "4.95",
      "Div": "0.92"
  },
  {
      "Date": "15-Feb-00",
      "PE": "27.19",
      "PB": "4.93",
      "Div": "0.92"
  },
  {
      "Date": "14-Feb-00",
      "PE": "28.29",
      "PB": "5.07",
      "Div": "0.9"
  },
  {
      "Date": "11-Feb-00",
      "PE": "28.47",
      "PB": "5.11",
      "Div": "0.9"
  },
  {
      "Date": "10-Feb-00",
      "PE": "27.75",
      "PB": "4.98",
      "Div": "0.92"
  },
  {
      "Date": "9-Feb-00",
      "PE": "27.4",
      "PB": "4.91",
      "Div": "0.93"
  },
  {
      "Date": "8-Feb-00",
      "PE": "26.96",
      "PB": "4.83",
      "Div": "0.91"
  },
  {
      "Date": "7-Feb-00",
      "PE": "26.54",
      "PB": "4.76",
      "Div": "0.92"
  },
  {
      "Date": "4-Feb-00",
      "PE": "25.94",
      "PB": "4.65",
      "Div": "0.94"
  },
  {
      "Date": "3-Feb-00",
      "PE": "25.91",
      "PB": "4.65",
      "Div": "0.95"
  },
  {
      "Date": "2-Feb-00",
      "PE": "25.75",
      "PB": "4.62",
      "Div": "0.95"
  },
  {
      "Date": "1-Feb-00",
      "PE": "25.13",
      "PB": "4.51",
      "Div": "0.98"
  },
  {
      "Date": "31-Jan-00",
      "PE": "25.16",
      "PB": "4.49",
      "Div": "0.98"
  },
  {
      "Date": "28-Jan-00",
      "PE": "26.02",
      "PB": "4.65",
      "Div": "0.95"
  },
  {
      "Date": "27-Jan-00",
      "PE": "26.1",
      "PB": "4.66",
      "Div": "0.94"
  },
  {
      "Date": "25-Jan-00",
      "PE": "25.81",
      "PB": "4.61",
      "Div": "0.95"
  },
  {
      "Date": "24-Jan-00",
      "PE": "26.26",
      "PB": "4.69",
      "Div": "0.94"
  },
  {
      "Date": "21-Jan-00",
      "PE": "26.37",
      "PB": "4.71",
      "Div": "0.93"
  },
  {
      "Date": "20-Jan-00",
      "PE": "26.05",
      "PB": "4.65",
      "Div": "0.95"
  },
  {
      "Date": "19-Jan-00",
      "PE": "26.6",
      "PB": "4.75",
      "Div": "0.93"
  },
  {
      "Date": "18-Jan-00",
      "PE": "26.15",
      "PB": "4.67",
      "Div": "0.94"
  },
  {
      "Date": "17-Jan-00",
      "PE": "26.22",
      "PB": "4.68",
      "Div": "0.94"
  },
  {
      "Date": "14-Jan-00",
      "PE": "26.41",
      "PB": "4.71",
      "Div": "0.93"
  },
  {
      "Date": "13-Jan-00",
      "PE": "26.38",
      "PB": "4.71",
      "Div": "0.93"
  },
  {
      "Date": "12-Jan-00",
      "PE": "26.44",
      "PB": "4.72",
      "Div": "0.93"
  },
  {
      "Date": "11-Jan-00",
      "PE": "25.59",
      "PB": "4.57",
      "Div": "0.96"
  },
  {
      "Date": "10-Jan-00",
      "PE": "26.57",
      "PB": "4.74",
      "Div": "0.93"
  },
  {
      "Date": "7-Jan-00",
      "PE": "26.25",
      "PB": "4.69",
      "Div": "0.94"
  },
  {
      "Date": "6-Jan-00",
      "PE": "26.32",
      "PB": "4.7",
      "Div": "0.94"
  },
  {
      "Date": "5-Jan-00",
      "PE": "25.97",
      "PB": "4.64",
      "Div": "0.95"
  },
  {
      "Date": "4-Jan-00",
      "PE": "26.67",
      "PB": "4.76",
      "Div": "0.92"
  },
  {
      "Date": "3-Jan-00",
      "PE": "25.91",
      "PB": "4.63",
      "Div": "0.95"
  },
  {
      "Date": "30-Dec-99",
      "PE": "24.09",
      "PB": "4.3",
      "Div": "1.02"
  },
  {
      "Date": "29-Dec-99",
      "PE": "24.02",
      "PB": "4.29",
      "Div": "1.03"
  },
  {
      "Date": "28-Dec-99",
      "PE": "23.47",
      "PB": "4.19",
      "Div": "1.05"
  },
  {
      "Date": "27-Dec-99",
      "PE": "23.3",
      "PB": "4.16",
      "Div": "1.06"
  },
  {
      "Date": "24-Dec-99",
      "PE": "23.74",
      "PB": "4.24",
      "Div": "1.04"
  },
  {
      "Date": "23-Dec-99",
      "PE": "24.22",
      "PB": "4.32",
      "Div": "1.02"
  },
  {
      "Date": "22-Dec-99",
      "PE": "24.11",
      "PB": "4.3",
      "Div": "1.02"
  },
  {
      "Date": "21-Dec-99",
      "PE": "23.13",
      "PB": "4.13",
      "Div": "1.06"
  },
  {
      "Date": "20-Dec-99",
      "PE": "23.15",
      "PB": "4.13",
      "Div": "1.06"
  },
  {
      "Date": "17-Dec-99",
      "PE": "23.1",
      "PB": "4.12",
      "Div": "1.06"
  },
  {
      "Date": "16-Dec-99",
      "PE": "23.1",
      "PB": "4.12",
      "Div": "1.06"
  },
  {
      "Date": "15-Dec-99",
      "PE": "22.8",
      "PB": "4.07",
      "Div": "1.08"
  },
  {
      "Date": "14-Dec-99",
      "PE": "22.75",
      "PB": "4.06",
      "Div": "1.07"
  },
  {
      "Date": "13-Dec-99",
      "PE": "23.23",
      "PB": "4.15",
      "Div": "1.04"
  },
  {
      "Date": "10-Dec-99",
      "PE": "23.43",
      "PB": "4.18",
      "Div": "1.03"
  },
  {
      "Date": "9-Dec-99",
      "PE": "23.56",
      "PB": "4.2",
      "Div": "1.03"
  },
  {
      "Date": "8-Dec-99",
      "PE": "23.41",
      "PB": "4.18",
      "Div": "1.04"
  },
  {
      "Date": "7-Dec-99",
      "PE": "22.58",
      "PB": "4.03",
      "Div": "1.07"
  },
  {
      "Date": "6-Dec-99",
      "PE": "22.54",
      "PB": "4.02",
      "Div": "1.08"
  },
  {
      "Date": "3-Dec-99",
      "PE": "22.15",
      "PB": "3.95",
      "Div": "1.09"
  },
  {
      "Date": "2-Dec-99",
      "PE": "22.01",
      "PB": "3.93",
      "Div": "1.1"
  },
  {
      "Date": "1-Dec-99",
      "PE": "21.69",
      "PB": "3.87",
      "Div": "1.12"
  },
  {
      "Date": "30-Nov-99",
      "PE": "21.47",
      "PB": "3.83",
      "Div": "1.13"
  },
  {
      "Date": "29-Nov-99",
      "PE": "21.6",
      "PB": "3.86",
      "Div": "1.12"
  },
  {
      "Date": "26-Nov-99",
      "PE": "21.84",
      "PB": "3.9",
      "Div": "1.11"
  },
  {
      "Date": "25-Nov-99",
      "PE": "21.98",
      "PB": "3.92",
      "Div": "1.11"
  },
  {
      "Date": "24-Nov-99",
      "PE": "21.76",
      "PB": "3.89",
      "Div": "1.12"
  },
  {
      "Date": "22-Nov-99",
      "PE": "21.46",
      "PB": "3.83",
      "Div": "1.13"
  },
  {
      "Date": "19-Nov-99",
      "PE": "21.25",
      "PB": "3.79",
      "Div": "1.14"
  },
  {
      "Date": "18-Nov-99",
      "PE": "21.28",
      "PB": "3.8",
      "Div": "1.14"
  },
  {
      "Date": "17-Nov-99",
      "PE": "21.1",
      "PB": "3.77",
      "Div": "1.15"
  },
  {
      "Date": "16-Nov-99",
      "PE": "21.16",
      "PB": "3.78",
      "Div": "1.15"
  },
  {
      "Date": "15-Nov-99",
      "PE": "21.24",
      "PB": "3.79",
      "Div": "1.14"
  },
  {
      "Date": "12-Nov-99",
      "PE": "21.41",
      "PB": "3.82",
      "Div": "1.14"
  },
  {
      "Date": "11-Nov-99",
      "PE": "21.65",
      "PB": "3.86",
      "Div": "1.12"
  },
  {
      "Date": "10-Nov-99",
      "PE": "21.64",
      "PB": "3.86",
      "Div": "1.12"
  },
  {
      "Date": "9-Nov-99",
      "PE": "21.36",
      "PB": "3.81",
      "Div": "1.14"
  },
  {
      "Date": "7-Nov-99",
      "PE": "21.34",
      "PB": "3.81",
      "Div": "1.14"
  },
  {
      "Date": "5-Nov-99",
      "PE": "21.26",
      "PB": "3.79",
      "Div": "1.14"
  },
  {
      "Date": "4-Nov-99",
      "PE": "20.83",
      "PB": "3.72",
      "Div": "1.16"
  },
  {
      "Date": "3-Nov-99",
      "PE": "20.66",
      "PB": "3.69",
      "Div": "1.17"
  },
  {
      "Date": "2-Nov-99",
      "PE": "20.75",
      "PB": "3.7",
      "Div": "1.15"
  },
  {
      "Date": "1-Nov-99",
      "PE": "19.78",
      "PB": "3.53",
      "Div": "1.2"
  },
  {
      "Date": "29-Oct-99",
      "PE": "20.65",
      "PB": "3.69",
      "Div": "1.15"
  },
  {
      "Date": "28-Oct-99",
      "PE": "21.31",
      "PB": "3.8",
      "Div": "1.12"
  },
  {
      "Date": "27-Oct-99",
      "PE": "21.99",
      "PB": "3.92",
      "Div": "1.08"
  },
  {
      "Date": "26-Oct-99",
      "PE": "22.02",
      "PB": "3.93",
      "Div": "1.08"
  },
  {
      "Date": "25-Oct-99",
      "PE": "22.21",
      "PB": "3.96",
      "Div": "1.07"
  },
  {
      "Date": "23-Oct-99",
      "PE": "22.57",
      "PB": "4.03",
      "Div": "1.05"
  },
  {
      "Date": "22-Oct-99",
      "PE": "22.27",
      "PB": "3.98",
      "Div": "1.07"
  },
  {
      "Date": "21-Oct-99",
      "PE": "22.63",
      "PB": "4.04",
      "Div": "1.05"
  },
  {
      "Date": "20-Oct-99",
      "PE": "23.01",
      "PB": "4.11",
      "Div": "1.03"
  },
  {
      "Date": "18-Oct-99",
      "PE": "22.48",
      "PB": "4.01",
      "Div": "1.06"
  },
  {
      "Date": "15-Oct-99",
      "PE": "22.65",
      "PB": "4.04",
      "Div": "1.05"
  },
  {
      "Date": "14-Oct-99",
      "PE": "23.49",
      "PB": "4.19",
      "Div": "1.01"
  },
  {
      "Date": "13-Oct-99",
      "PE": "23.35",
      "PB": "4.17",
      "Div": "1.02"
  },
  {
      "Date": "12-Oct-99",
      "PE": "23.16",
      "PB": "4.13",
      "Div": "1.03"
  },
  {
      "Date": "11-Oct-99",
      "PE": "23.16",
      "PB": "4.13",
      "Div": "1.03"
  },
  {
      "Date": "8-Oct-99",
      "PE": "23.08",
      "PB": "4.12",
      "Div": "1.03"
  },
  {
      "Date": "7-Oct-99",
      "PE": "22.94",
      "PB": "4.09",
      "Div": "1.04"
  },
  {
      "Date": "6-Oct-99",
      "PE": "21.73",
      "PB": "3.88",
      "Div": "1.1"
  },
  {
      "Date": "5-Oct-99",
      "PE": "21.42",
      "PB": "3.82",
      "Div": "1.11"
  },
  {
      "Date": "4-Oct-99",
      "PE": "21.33",
      "PB": "3.8",
      "Div": "0.93"
  },
  {
      "Date": "1-Oct-99",
      "PE": "21.81",
      "PB": "3.89",
      "Div": "1.09"
  },
  {
      "Date": "30-Sep-99",
      "PE": "21.96",
      "PB": "3.92",
      "Div": "1.04"
  },
  {
      "Date": "29-Sep-99",
      "PE": "21.99",
      "PB": "3.92",
      "Div": "1.04"
  },
  {
      "Date": "28-Sep-99",
      "PE": "21.39",
      "PB": "3.82",
      "Div": "1.06"
  },
  {
      "Date": "27-Sep-99",
      "PE": "21.55",
      "PB": "3.84",
      "Div": "1.06"
  },
  {
      "Date": "24-Sep-99",
      "PE": "21.91",
      "PB": "3.91",
      "Div": "1.04"
  },
  {
      "Date": "23-Sep-99",
      "PE": "21.68",
      "PB": "3.87",
      "Div": "1.05"
  },
  {
      "Date": "22-Sep-99",
      "PE": "21.51",
      "PB": "3.84",
      "Div": "1.06"
  },
  {
      "Date": "21-Sep-99",
      "PE": "20.95",
      "PB": "3.74",
      "Div": "1.08"
  },
  {
      "Date": "20-Sep-99",
      "PE": "21.06",
      "PB": "3.76",
      "Div": "1.08"
  },
  {
      "Date": "17-Sep-99",
      "PE": "21.11",
      "PB": "3.76",
      "Div": "1.08"
  },
  {
      "Date": "16-Sep-99",
      "PE": "20.98",
      "PB": "3.74",
      "Div": "1.08"
  },
  {
      "Date": "15-Sep-99",
      "PE": "21.34",
      "PB": "3.8",
      "Div": "1.06"
  },
  {
      "Date": "14-Sep-99",
      "PE": "21.28",
      "PB": "3.79",
      "Div": "1.07"
  },
  {
      "Date": "10-Sep-99",
      "PE": "21.6",
      "PB": "3.85",
      "Div": "1.05"
  },
  {
      "Date": "9-Sep-99",
      "PE": "21.69",
      "PB": "3.86",
      "Div": "1.05"
  },
  {
      "Date": "8-Sep-99",
      "PE": "21.73",
      "PB": "3.87",
      "Div": "1.04"
  },
  {
      "Date": "7-Sep-99",
      "PE": "20.96",
      "PB": "3.63",
      "Div": "1.08"
  },
  {
      "Date": "6-Sep-99",
      "PE": "21.07",
      "PB": "3.65",
      "Div": "1.07"
  },
  {
      "Date": "3-Sep-99",
      "PE": "20.84",
      "PB": "3.61",
      "Div": "1.08"
  },
  {
      "Date": "2-Sep-99",
      "PE": "20.85",
      "PB": "3.61",
      "Div": "1.09"
  },
  {
      "Date": "1-Sep-99",
      "PE": "21.38",
      "PB": "3.71",
      "Div": "1.06"
  },
  {
      "Date": "31-Aug-99",
      "PE": "21.4",
      "PB": "3.71",
      "Div": "1.06"
  },
  {
      "Date": "30-Aug-99",
      "PE": "21.56",
      "PB": "3.74",
      "Div": "1.05"
  },
  {
      "Date": "27-Aug-99",
      "PE": "21.48",
      "PB": "3.72",
      "Div": "1.06"
  },
  {
      "Date": "26-Aug-99",
      "PE": "21.25",
      "PB": "3.68",
      "Div": "1.07"
  },
  {
      "Date": "25-Aug-99",
      "PE": "20.79",
      "PB": "3.6",
      "Div": "1.09"
  },
  {
      "Date": "24-Aug-99",
      "PE": "20.94",
      "PB": "3.63",
      "Div": "0.98"
  },
  {
      "Date": "23-Aug-99",
      "PE": "20.74",
      "PB": "3.6",
      "Div": "0.99"
  },
  {
      "Date": "20-Aug-99",
      "PE": "20.4",
      "PB": "3.54",
      "Div": "1.01"
  },
  {
      "Date": "19-Aug-99",
      "PE": "20.37",
      "PB": "3.53",
      "Div": "1.07"
  },
  {
      "Date": "18-Aug-99",
      "PE": "20.64",
      "PB": "3.58",
      "Div": "1.06"
  },
  {
      "Date": "17-Aug-99",
      "PE": "20.03",
      "PB": "3.47",
      "Div": "1.09"
  },
  {
      "Date": "16-Aug-99",
      "PE": "19.83",
      "PB": "3.44",
      "Div": "1.1"
  },
  {
      "Date": "13-Aug-99",
      "PE": "19.56",
      "PB": "3.39",
      "Div": "1.12"
  },
  {
      "Date": "12-Aug-99",
      "PE": "19.83",
      "PB": "3.44",
      "Div": "1.1"
  },
  {
      "Date": "11-Aug-99",
      "PE": "19.92",
      "PB": "3.45",
      "Div": "1.1"
  },
  {
      "Date": "10-Aug-99",
      "PE": "19.83",
      "PB": "3.44",
      "Div": "1.07"
  },
  {
      "Date": "9-Aug-99",
      "PE": "20.2",
      "PB": "3.51",
      "Div": "1.06"
  },
  {
      "Date": "6-Aug-99",
      "PE": "20.03",
      "PB": "3.48",
      "Div": "1.07"
  },
  {
      "Date": "5-Aug-99",
      "PE": "20.13",
      "PB": "3.49",
      "Div": "1.07"
  },
  {
      "Date": "4-Aug-99",
      "PE": "20.18",
      "PB": "3.5",
      "Div": "1.07"
  },
  {
      "Date": "3-Aug-99",
      "PE": "19.53",
      "PB": "3.39",
      "Div": "1.1"
  },
  {
      "Date": "2-Aug-99",
      "PE": "19.63",
      "PB": "3.41",
      "Div": "1.1"
  },
  {
      "Date": "30-Jul-99",
      "PE": "19.94",
      "PB": "3.46",
      "Div": "1.08"
  },
  {
      "Date": "29-Jul-99",
      "PE": "20.18",
      "PB": "3.5",
      "Div": "1.07"
  },
  {
      "Date": "28-Jul-99",
      "PE": "20.09",
      "PB": "3.49",
      "Div": "1.07"
  },
  {
      "Date": "27-Jul-99",
      "PE": "19.94",
      "PB": "3.46",
      "Div": "1.08"
  },
  {
      "Date": "26-Jul-99",
      "PE": "20.18",
      "PB": "3.5",
      "Div": "1.07"
  },
  {
      "Date": "23-Jul-99",
      "PE": "20.46",
      "PB": "3.55",
      "Div": "1.06"
  },
  {
      "Date": "22-Jul-99",
      "PE": "20.39",
      "PB": "3.54",
      "Div": "1.13"
  },
  {
      "Date": "21-Jul-99",
      "PE": "20.26",
      "PB": "3.52",
      "Div": "1.14"
  },
  {
      "Date": "20-Jul-99",
      "PE": "19.87",
      "PB": "3.45",
      "Div": "1.14"
  },
  {
      "Date": "19-Jul-99",
      "PE": "20.4",
      "PB": "3.54",
      "Div": "1.11"
  },
  {
      "Date": "16-Jul-99",
      "PE": "20.02",
      "PB": "3.47",
      "Div": "1.13"
  },
  {
      "Date": "15-Jul-99",
      "PE": "19.92",
      "PB": "3.46",
      "Div": "1.19"
  },
  {
      "Date": "14-Jul-99",
      "PE": "20.18",
      "PB": "3.5",
      "Div": "1.18"
  },
  {
      "Date": "13-Jul-99",
      "PE": "19.92",
      "PB": "3.46",
      "Div": "1.12"
  },
  {
      "Date": "12-Jul-99",
      "PE": "19.83",
      "PB": "3.44",
      "Div": "1.13"
  },
  {
      "Date": "9-Jul-99",
      "PE": "18.91",
      "PB": "3.28",
      "Div": "1.18"
  },
  {
      "Date": "8-Jul-99",
      "PE": "18.76",
      "PB": "3.26",
      "Div": "1.2"
  },
  {
      "Date": "7-Jul-99",
      "PE": "18.75",
      "PB": "3.26",
      "Div": "1.2"
  },
  {
      "Date": "6-Jul-99",
      "PE": "18.72",
      "PB": "3.25",
      "Div": "1.16"
  },
  {
      "Date": "5-Jul-99",
      "PE": "18.55",
      "PB": "3.22",
      "Div": "1.17"
  },
  {
      "Date": "2-Jul-99",
      "PE": "18.06",
      "PB": "3.14",
      "Div": "1.2"
  },
  {
      "Date": "1-Jul-99",
      "PE": "17.84",
      "PB": "3.1",
      "Div": "1.22"
  },
  {
      "Date": "30-Jun-99",
      "PE": "17.91",
      "PB": "3.11",
      "Div": "1.22"
  },
  {
      "Date": "29-Jun-99",
      "PE": "18",
      "PB": "3.12",
      "Div": "0.95"
  },
  {
      "Date": "28-Jun-99",
      "PE": "18.02",
      "PB": "3.12",
      "Div": "0.95"
  },
  {
      "Date": "25-Jun-99",
      "PE": "17.79",
      "PB": "3.08",
      "Div": "0.97"
  },
  {
      "Date": "24-Jun-99",
      "PE": "17.85",
      "PB": "3.09",
      "Div": "0.98"
  },
  {
      "Date": "23-Jun-99",
      "PE": "18.03",
      "PB": "3.12",
      "Div": "0.97"
  },
  {
      "Date": "22-Jun-99",
      "PE": "18.35",
      "PB": "3.18",
      "Div": "0.85"
  },
  {
      "Date": "21-Jun-99",
      "PE": "18.17",
      "PB": "3.15",
      "Div": "0.89"
  },
  {
      "Date": "18-Jun-99",
      "PE": "17.76",
      "PB": "3.08",
      "Div": "0.88"
  },
  {
      "Date": "17-Jun-99",
      "PE": "17.78",
      "PB": "3.08",
      "Div": "1.19"
  },
  {
      "Date": "16-Jun-99",
      "PE": "17.35",
      "PB": "3.01",
      "Div": "1.22"
  },
  {
      "Date": "15-Jun-99",
      "PE": "16.93",
      "PB": "2.93",
      "Div": "1.17"
  },
  {
      "Date": "14-Jun-99",
      "PE": "17.03",
      "PB": "2.95",
      "Div": "1.16"
  },
  {
      "Date": "11-Jun-99",
      "PE": "17.25",
      "PB": "2.99",
      "Div": "1.14"
  },
  {
      "Date": "10-Jun-99",
      "PE": "17.57",
      "PB": "3.04",
      "Div": "1.17"
  },
  {
      "Date": "9-Jun-99",
      "PE": "17.58",
      "PB": "3.05",
      "Div": "1.2"
  },
  {
      "Date": "8-Jun-99",
      "PE": "17.82",
      "PB": "3.09",
      "Div": "1.15"
  },
  {
      "Date": "7-Jun-99",
      "PE": "17.48",
      "PB": "3.03",
      "Div": "1.17"
  },
  {
      "Date": "4-Jun-99",
      "PE": "17.45",
      "PB": "3.02",
      "Div": "1.18"
  },
  {
      "Date": "3-Jun-99",
      "PE": "18.75",
      "PB": "3.08",
      "Div": "1.23"
  },
  {
      "Date": "2-Jun-99",
      "PE": "18.27",
      "PB": "3.05",
      "Div": "1.24"
  },
  {
      "Date": "1-Jun-99",
      "PE": "18.25",
      "PB": "3.02",
      "Div": "1.24"
  },
  {
      "Date": "31-May-99",
      "PE": "18.01",
      "PB": "3.05",
      "Div": "1.23"
  },
  {
      "Date": "28-May-99",
      "PE": "17.2",
      "PB": "2.93",
      "Div": "1.28"
  },
  {
      "Date": "27-May-99",
      "PE": "17.32",
      "PB": "2.96",
      "Div": "1.35"
  },
  {
      "Date": "26-May-99",
      "PE": "18.28",
      "PB": "3.1",
      "Div": "1.3"
  },
  {
      "Date": "25-May-99",
      "PE": "18.16",
      "PB": "3.03",
      "Div": "1.03"
  },
  {
      "Date": "24-May-99",
      "PE": "18.08",
      "PB": "3.01",
      "Div": "1.04"
  },
  {
      "Date": "21-May-99",
      "PE": "17.69",
      "PB": "3.03",
      "Div": "1.05"
  },
  {
      "Date": "20-May-99",
      "PE": "18.01",
      "PB": "3.08",
      "Div": "1.06"
  },
  {
      "Date": "19-May-99",
      "PE": "18.22",
      "PB": "3.12",
      "Div": "1.05"
  },
  {
      "Date": "18-May-99",
      "PE": "17.9",
      "PB": "3.06",
      "Div": "1.06"
  },
  {
      "Date": "17-May-99",
      "PE": "17.77",
      "PB": "3.04",
      "Div": "1.07"
  },
  {
      "Date": "14-May-99",
      "PE": "17.98",
      "PB": "3.08",
      "Div": "1.05"
  },
  {
      "Date": "13-May-99",
      "PE": "17.71",
      "PB": "3.03",
      "Div": "1.2"
  },
  {
      "Date": "12-May-99",
      "PE": "17.14",
      "PB": "2.93",
      "Div": "1.24"
  },
  {
      "Date": "11-May-99",
      "PE": "17.21",
      "PB": "2.95",
      "Div": "1.23"
  },
  {
      "Date": "10-May-99",
      "PE": "16.88",
      "PB": "2.89",
      "Div": "1.26"
  },
  {
      "Date": "7-May-99",
      "PE": "16.4",
      "PB": "2.81",
      "Div": "1.29"
  },
  {
      "Date": "6-May-99",
      "PE": "16.15",
      "PB": "2.76",
      "Div": "1.31"
  },
  {
      "Date": "5-May-99",
      "PE": "15.73",
      "PB": "2.69",
      "Div": "1.35"
  },
  {
      "Date": "4-May-99",
      "PE": "15.53",
      "PB": "2.66",
      "Div": "1.21"
  },
  {
      "Date": "3-May-99",
      "PE": "14.95",
      "PB": "2.56",
      "Div": "1.25"
  },
  {
      "Date": "30-Apr-99",
      "PE": "15.02",
      "PB": "2.58",
      "Div": "1.25"
  },
  {
      "Date": "29-Apr-99",
      "PE": "14.84",
      "PB": "2.55",
      "Div": "1.48"
  },
  {
      "Date": "28-Apr-99",
      "PE": "14.49",
      "PB": "2.49",
      "Div": "1.52"
  },
  {
      "Date": "26-Apr-99",
      "PE": "14.3",
      "PB": "2.46",
      "Div": "1.54"
  },
  {
      "Date": "23-Apr-99",
      "PE": "15.08",
      "PB": "2.59",
      "Div": "1.46"
  },
  {
      "Date": "22-Apr-99",
      "PE": "15.08",
      "PB": "2.59",
      "Div": "1.47"
  },
  {
      "Date": "21-Apr-99",
      "PE": "15.31",
      "PB": "2.63",
      "Div": "1.45"
  },
  {
      "Date": "20-Apr-99",
      "PE": "15.29",
      "PB": "2.63",
      "Div": "1.45"
  },
  {
      "Date": "19-Apr-99",
      "PE": "15.26",
      "PB": "2.62",
      "Div": "1.45"
  },
  {
      "Date": "17-Apr-99",
      "PE": "14.85",
      "PB": "2.55",
      "Div": "1.49"
  },
  {
      "Date": "16-Apr-99",
      "PE": "16.04",
      "PB": "2.76",
      "Div": "1.38"
  },
  {
      "Date": "15-Apr-99",
      "PE": "14.87",
      "PB": "2.56",
      "Div": "1.63"
  },
  {
      "Date": "13-Apr-99",
      "PE": "15.25",
      "PB": "2.62",
      "Div": "1.59"
  },
  {
      "Date": "12-Apr-99",
      "PE": "15.31",
      "PB": "2.63",
      "Div": "1.59"
  },
  {
      "Date": "9-Apr-99",
      "PE": "15.24",
      "PB": "2.62",
      "Div": "1.6"
  },
  {
      "Date": "8-Apr-99",
      "PE": "15.65",
      "PB": "2.69",
      "Div": "1.55"
  },
  {
      "Date": "7-Apr-99",
      "PE": "15.81",
      "PB": "2.72",
      "Div": "1.54"
  },
  {
      "Date": "6-Apr-99",
      "PE": "15.71",
      "PB": "2.7",
      "Div": "1.54"
  },
  {
      "Date": "5-Apr-99",
      "PE": "15.51",
      "PB": "2.66",
      "Div": "1.56"
  },
  {
      "Date": "1-Apr-99",
      "PE": "16.31",
      "PB": "2.8",
      "Div": "1.5"
  },
  {
      "Date": "31-Mar-99",
      "PE": "16.53",
      "PB": "2.84",
      "Div": "1.48"
  },
  {
      "Date": "30-Mar-99",
      "PE": "14.02",
      "PB": "2.5",
      "Div": "1.51"
  },
  {
      "Date": "26-Mar-99",
      "PE": "13.85",
      "PB": "2.46",
      "Div": "1.53"
  },
  {
      "Date": "25-Mar-99",
      "PE": "14.03",
      "PB": "2.5",
      "Div": "1.52"
  },
  {
      "Date": "24-Mar-99",
      "PE": "14.13",
      "PB": "2.51",
      "Div": "1.51"
  },
  {
      "Date": "23-Mar-99",
      "PE": "14.27",
      "PB": "2.54",
      "Div": "1.49"
  },
  {
      "Date": "22-Mar-99",
      "PE": "14.13",
      "PB": "2.52",
      "Div": "1.5"
  },
  {
      "Date": "20-Mar-99",
      "PE": "14.1",
      "PB": "2.51",
      "Div": "1.5"
  },
  {
      "Date": "19-Mar-99",
      "PE": "14.13",
      "PB": "2.52",
      "Div": "1.5"
  },
  {
      "Date": "18-Mar-99",
      "PE": "14.29",
      "PB": "2.54",
      "Div": "1.49"
  },
  {
      "Date": "17-Mar-99",
      "PE": "14.1",
      "PB": "2.51",
      "Div": "1.51"
  },
  {
      "Date": "16-Mar-99",
      "PE": "14",
      "PB": "2.49",
      "Div": "1.39"
  },
  {
      "Date": "15-Mar-99",
      "PE": "14.28",
      "PB": "2.54",
      "Div": "1.37"
  },
  {
      "Date": "12-Mar-99",
      "PE": "14.26",
      "PB": "2.54",
      "Div": "1.37"
  },
  {
      "Date": "11-Mar-99",
      "PE": "14.09",
      "PB": "2.51",
      "Div": "1.48"
  },
  {
      "Date": "10-Mar-99",
      "PE": "14.35",
      "PB": "2.55",
      "Div": "1.46"
  },
  {
      "Date": "9-Mar-99",
      "PE": "14.33",
      "PB": "2.55",
      "Div": "1.45"
  },
  {
      "Date": "8-Mar-99",
      "PE": "14.25",
      "PB": "2.53",
      "Div": "1.46"
  },
  {
      "Date": "5-Mar-99",
      "PE": "14.01",
      "PB": "2.49",
      "Div": "1.48"
  },
  {
      "Date": "4-Mar-99",
      "PE": "13.83",
      "PB": "2.46",
      "Div": "1.5"
  },
  {
      "Date": "3-Mar-99",
      "PE": "13.98",
      "PB": "2.49",
      "Div": "1.49"
  },
  {
      "Date": "1-Mar-99",
      "PE": "13.5",
      "PB": "2.4",
      "Div": "1.54"
  },
  {
      "Date": "27-Feb-99",
      "PE": "13.04",
      "PB": "2.32",
      "Div": "1.59"
  },
  {
      "Date": "26-Feb-99",
      "PE": "12.51",
      "PB": "2.23",
      "Div": "1.66"
  },
  {
      "Date": "25-Feb-99",
      "PE": "12.68",
      "PB": "2.26",
      "Div": "1.66"
  },
  {
      "Date": "24-Feb-99",
      "PE": "12.72",
      "PB": "2.26",
      "Div": "1.66"
  },
  {
      "Date": "23-Feb-99",
      "PE": "12.61",
      "PB": "2.24",
      "Div": "1.67"
  },
  {
      "Date": "22-Feb-99",
      "PE": "12.66",
      "PB": "2.25",
      "Div": "1.66"
  },
  {
      "Date": "19-Feb-99",
      "PE": "12.96",
      "PB": "2.31",
      "Div": "1.63"
  },
  {
      "Date": "18-Feb-99",
      "PE": "12.92",
      "PB": "2.3",
      "Div": "1.63"
  },
  {
      "Date": "17-Feb-99",
      "PE": "12.87",
      "PB": "2.29",
      "Div": "1.64"
  },
  {
      "Date": "16-Feb-99",
      "PE": "12.73",
      "PB": "2.27",
      "Div": "1.63"
  },
  {
      "Date": "15-Feb-99",
      "PE": "12.67",
      "PB": "2.26",
      "Div": "1.64"
  },
  {
      "Date": "12-Feb-99",
      "PE": "12.88",
      "PB": "2.29",
      "Div": "1.62"
  },
  {
      "Date": "11-Feb-99",
      "PE": "12.75",
      "PB": "2.27",
      "Div": "1.67"
  },
  {
      "Date": "10-Feb-99",
      "PE": "12.74",
      "PB": "2.27",
      "Div": "1.67"
  },
  {
      "Date": "9-Feb-99",
      "PE": "12.28",
      "PB": "2.18",
      "Div": "1.73"
  },
  {
      "Date": "8-Feb-99",
      "PE": "12.17",
      "PB": "2.17",
      "Div": "1.75"
  },
  {
      "Date": "5-Feb-99",
      "PE": "12.43",
      "PB": "2.21",
      "Div": "1.71"
  },
  {
      "Date": "4-Feb-99",
      "PE": "12.48",
      "PB": "2.22",
      "Div": "1.71"
  },
  {
      "Date": "3-Feb-99",
      "PE": "12.64",
      "PB": "2.25",
      "Div": "1.69"
  },
  {
      "Date": "2-Feb-99",
      "PE": "12.36",
      "PB": "2.2",
      "Div": "1.72"
  },
  {
      "Date": "1-Feb-99",
      "PE": "12.48",
      "PB": "2.22",
      "Div": "1.7"
  },
  {
      "Date": "29-Jan-99",
      "PE": "12.83",
      "PB": "2.28",
      "Div": "1.66"
  },
  {
      "Date": "28-Jan-99",
      "PE": "12.68",
      "PB": "2.26",
      "Div": "1.68"
  },
  {
      "Date": "27-Jan-99",
      "PE": "12.9",
      "PB": "2.29",
      "Div": "1.65"
  },
  {
      "Date": "25-Jan-99",
      "PE": "12.61",
      "PB": "2.24",
      "Div": "1.68"
  },
  {
      "Date": "22-Jan-99",
      "PE": "12.71",
      "PB": "2.26",
      "Div": "1.67"
  },
  {
      "Date": "21-Jan-99",
      "PE": "12.84",
      "PB": "2.29",
      "Div": "1.65"
  },
  {
      "Date": "19-Jan-99",
      "PE": "12.06",
      "PB": "2.15",
      "Div": "1.76"
  },
  {
      "Date": "18-Jan-99",
      "PE": "12.33",
      "PB": "2.19",
      "Div": "1.72"
  },
  {
      "Date": "15-Jan-99",
      "PE": "12.19",
      "PB": "2.17",
      "Div": "1.74"
  },
  {
      "Date": "14-Jan-99",
      "PE": "12.46",
      "PB": "2.22",
      "Div": "1.7"
  },
  {
      "Date": "13-Jan-99",
      "PE": "12.54",
      "PB": "2.23",
      "Div": "1.69"
  },
  {
      "Date": "12-Jan-99",
      "PE": "12.59",
      "PB": "2.24",
      "Div": "1.69"
  },
  {
      "Date": "11-Jan-99",
      "PE": "12.86",
      "PB": "2.29",
      "Div": "1.65"
  },
  {
      "Date": "8-Jan-99",
      "PE": "12.92",
      "PB": "2.3",
      "Div": "1.64"
  },
  {
      "Date": "7-Jan-99",
      "PE": "12.46",
      "PB": "2.22",
      "Div": "1.71"
  },
  {
      "Date": "6-Jan-99",
      "PE": "12.11",
      "PB": "2.16",
      "Div": "1.75"
  },
  {
      "Date": "5-Jan-99",
      "PE": "11.84",
      "PB": "2.11",
      "Div": "1.79"
  },
  {
      "Date": "4-Jan-99",
      "PE": "11.72",
      "PB": "2.08",
      "Div": "1.81"
  },
  {
      "Date": "1-Jan-99",
      "PE": "11.62",
      "PB": "2.07",
      "Div": "1.83"
  }
];